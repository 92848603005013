import { compact, join } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'

import Avatar from 'global/Avatar'
import { Select, SelectOption, SelectProps } from 'global/Input'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import { GlobalPersonBlueprint } from 'utils/types'

interface Props extends Omit<SelectProps<GlobalPersonBlueprint>, 'options' | 'onChange'> {
  onChange: (person: GlobalPersonBlueprint | null) => void
  clearOnSelect?: boolean
}

const GlobalPersonSelect: React.FC<Props> = ({
  onChange,
  clearOnSelect = false,
  ...extraProps
}) => {
  const [query, setQuery] = useState('')
  const [rerender, setRerender] = useState(Math.random() * 10000)
  const deboundedQuery = useDebouncedValue(query, 500)

  const { isLoading: isLoadingQuery, data } = useQuery(
    ['getPeople', deboundedQuery],
    () => callApi(api.getSearchResults, deboundedQuery, ['global_people']),
    {
      keepPreviousData: true,
      enabled: !!deboundedQuery,
    },
  )
  const isLoading = isLoadingQuery || deboundedQuery !== query

  const results = useMemo(() => {
    return (data?.results?.filter((r) => r.type === 'GlobalPerson') ||
      []) as GlobalPersonBlueprint[]
  }, [data])

  let options = useMemo(() => {
    const _options = results.map<SelectOption<GlobalPersonBlueprint>>((person) => ({
      value: person,
      label: (
        <div className="flex">
          <Avatar
            size="24px"
            src={person.image_url}
            name={person.full_name}
            className="mr-2 flex-0"
          />
          <div className="flex-1">
            {join(compact([person.full_name, person.headline]), ' - ')}
            {person.verified && (
              <Typography color="purple" className="ml-2">
                <i className="fas fa-badge-check" />
              </Typography>
            )}
          </div>
        </div>
      ),
      toString: () => person.full_name,
    }))

    return _options
  }, [results])

  return (
    <Select
      key={'global-person-select-' + rerender}
      options={options}
      leftActions={<i className="far fa-search" />}
      noDropdownIndicator={true}
      onChange={(g) => {
        onChange(g)

        if (clearOnSelect) {
          setRerender(rerender + 1)
        }
      }}
      onInputChange={setQuery}
      isLoading={isLoading && options.length === 0}
      noOptionsMessage={() => (!isLoading ? (!query ? 'Search...' : 'No results') : 'Loading...')}
      filterOption={() => true}
      {...extraProps}
    />
  )
}

export default GlobalPersonSelect
