import React, { useState, useEffect } from 'react'
import CabalButton from 'global/CabalButton'
import { CheckBox } from 'global/Input'
import Typography from 'global/Typography'
import { CurrentUserProfile, DigestSchedule } from 'utils/types'
import api from 'utils/api'
import { cabalToast } from 'ui-components/Toast'

interface Props {
  user: CurrentUserProfile
}

const UserDigestSchedule: React.VFC<Props> = ({ user }) => {
  const [saving, setSaving] = useState(false)

  const [digestSchedule, setDigestSchedule] = useState<DigestSchedule>({
    Sunday: false,
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: false,
  })

  useEffect(() => {
    api.getDigestSchedule(user.uuid).then(({ data }) => {
      setDigestSchedule(data)
    })
  }, [setDigestSchedule, user])

  const onSubmit = () => {
    setSaving(true)
    api.setDigestSchedule(user.uuid, digestSchedule).then(({ data }) => {
      setDigestSchedule(data)
      setSaving(false)
      cabalToast({ style: 'success', content: 'Digest Email Schedule Updated.' })
    })
  }

  return (
    <>
      <label>
        <div>
          <CheckBox
            checked={digestSchedule.Sunday}
            onChange={(e) =>
              setDigestSchedule({ ...digestSchedule, Sunday: e.currentTarget.checked })
            }
            label="Sunday"
          />
        </div>
      </label>

      <label>
        <div>
          <CheckBox
            checked={digestSchedule.Monday}
            onChange={(e) =>
              setDigestSchedule({ ...digestSchedule, Monday: e.currentTarget.checked })
            }
            label="Monday"
          />
        </div>
      </label>
      <label>
        <div>
          <CheckBox
            checked={digestSchedule.Tuesday}
            onChange={(e) =>
              setDigestSchedule({ ...digestSchedule, Tuesday: e.currentTarget.checked })
            }
            label="Tuesday"
          />
        </div>
      </label>
      <label>
        <div>
          <CheckBox
            checked={digestSchedule.Wednesday}
            onChange={(e) =>
              setDigestSchedule({ ...digestSchedule, Wednesday: e.currentTarget.checked })
            }
            label="Wednesday"
          />
        </div>
      </label>
      <label>
        <div>
          <CheckBox
            checked={digestSchedule.Thursday}
            onChange={(e) =>
              setDigestSchedule({ ...digestSchedule, Thursday: e.currentTarget.checked })
            }
            label="Thursday"
          />
        </div>
      </label>
      <label>
        <div>
          <CheckBox
            checked={digestSchedule.Friday}
            onChange={(e) =>
              setDigestSchedule({ ...digestSchedule, Friday: e.currentTarget.checked })
            }
            label="Friday"
          />
        </div>
      </label>
      <label>
        <div>
          <CheckBox
            checked={digestSchedule.Saturday}
            onChange={(e) =>
              setDigestSchedule({ ...digestSchedule, Saturday: e.currentTarget.checked })
            }
            label="Saturday"
          />
        </div>
      </label>
      <div className="flex justify-end">
        <CabalButton variant="primary" disabled={saving} working={saving} onClick={onSubmit}>
          Save
        </CabalButton>
      </div>
    </>
  )
}

export default UserDigestSchedule
