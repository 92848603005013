import * as React from 'react'

import { motion } from 'framer-motion'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import NewButton from 'containers/NavHome/NewButton'
import UserDropDown from 'containers/NavHome/UserDropDown'
import { useAccessControl } from 'global/AccessControl'
import Typography from 'global/Typography'
import { useCurrentUser, useCurrentUserSettings, useTeam, useTeamSlug } from 'store/hooks'

import { useSlug } from 'utils/hooks/useSlug'

import CabalLogo from '../../images/logo-gray.png'
import GlobalSearch from './GlobalSearch'
import { LengthWrapper, TopNavWrapper } from './styles'

const Logo = styled.img`
  ${tw`mr-3 pl-2`}
  height: 24px;
  color: ${({ theme }) => theme.colors.rain};
`

const PillWrapper = styled(Link)<{ active: boolean }>`
  &:hover {
    * > i,
    p {
      color: ${({ theme }) => theme.colors.purple};
    }
  }
`

interface Props {
  title: string
  icon: string
  link: string
  active: boolean
}

const NavPill: React.FC<Props> = ({ title, icon, link, active }) => {
  return (
    <PillWrapper
      style={{ width: 70 }}
      className="flex flex-col items-center justify-center cursor-pointer rounded py-1 relative"
      to={link}
      active={active}
    >
      <Typography color={active ? 'purple' : 'fog_rain'} lineHeight={1} component="p">
        <i className={`${active ? 'fas ' : 'far '} fa-${icon} fa-sm`} />
      </Typography>
      <Typography
        fontSize="10"
        color={active ? 'purple' : 'fog_rain'}
        fontWeight={400}
        component="p"
      >
        {title}
      </Typography>
      {active && (
        <motion.span
          layoutId="pill-tab"
          transition={{ type: 'spring', duration: 0.5 }}
          className="absolute inset-0 z-0 bg-[#5c69d11f] rounded-md"
        ></motion.span>
      )}
    </PillWrapper>
  )
}

const GlobalNav: React.FC = (props) => {
  const { user } = useCurrentUser()
  const location = useLocation()
  const { settings, updateSetting, settingsLoaded } = useCurrentUserSettings()

  return (
    <TopNavWrapper>
      <LengthWrapper>
        <div className="flex flex-shrink-0 items-center">
          <Link to={'/home'} className="cursor-pointer">
            <Logo src={CabalLogo} />
          </Link>
          {user?.team && <GlobalSearch />}
        </div>

        <div className="flex space-x-2 items-center">
          <div className="space-x-1 flex items-baseline">
            <NavPill title="Home" icon="home" link="/home" active={location.pathname === '/home'} />
            {user.team?.slug && (
              <NavPill
                title="Team"
                icon="users"
                link={`/${user.team.slug}`}
                active={location.pathname.startsWith(`/${user.team.slug}`)}
              />
            )}
            {!user.team?.slug && (
              <div className="relative" onClick={() => updateSetting('hide_your_team_dot', true)}>
                <NavPill
                  title="Your Team"
                  icon="users"
                  link={`/onboarding_v2`}
                  active={location.pathname.startsWith(`/onboarding_v2`)}
                />
                {settingsLoaded && !settings.hide_your_team_dot && user.name && (
                  <span className="absolute flex h-2 w-2 top-1 right-3">
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
                  </span>
                )}
              </div>
            )}
            {/* <NavPill title="Connections" icon="user-group" onClick={() => null} active={false} /> */}
            {/* <NavPill
              title="Profile"
              icon="user-circle"
              link={`/${userSlug || 'profile'}`}
              active={location.pathname === `/${userSlug}` || location.pathname === '/profile'}
            /> */}
            {user.team?.features?.global_mail_tab && (
              <div className="relative" onClick={() => updateSetting('hide_global_mail_dot', true)}>
                <NavPill
                  title="Mail"
                  icon="envelope"
                  link={`/messages/inbox`}
                  active={location.pathname.startsWith(`/messages`)}
                />
                {settingsLoaded && user.team?.slug && !settings.hide_global_mail_dot && (
                  <span className="absolute flex h-2 w-2 top-1 right-3">
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
                  </span>
                )}
              </div>
            )}
            <NavPill
              title="Explore"
              icon="compass"
              link={`/explore`}
              active={location.pathname === `/explore`}
            />
            {/* <Notifications /> */}
          </div>
          <UserDropDown />
          {user.team && <NewButton slug={user.team.slug} />}
        </div>
      </LengthWrapper>
    </TopNavWrapper>
  )
}

export default GlobalNav
