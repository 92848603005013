import React, { useMemo } from 'react'
import styled from 'styled-components'
import TimeAgo from 'components/TimeAgo'
import moment from 'moment'

import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import { ActivityModel } from 'utils/types'
import Tooltip from 'global/Tooltip'
import tw from 'twin.macro'
import { isEqual } from 'lodash'

const ActivityContainer = styled.div`
  ${tw`p-3`}
`

interface Props {
  activity: ActivityModel
}

const getCopy = (activityName: ActivityModel['name'], userName: string) => {
  switch (activityName) {
    case 'draft_created':
      return (
        <>
          <Typography fontWeight={600}>{userName}</Typography> created this draft
        </>
      )
    case 'draft_viewed':
      return (
        <>
          <Typography fontWeight={600}>{userName}</Typography> viewed this draft
        </>
      )
    case 'draft_shared':
      return (
        <>
          Draft shared with <Typography fontWeight={600}>{userName}</Typography>
        </>
      )
    default:
      throw new Error('Unknown activity name')
  }
}

const Activity: React.VFC<Props> = React.memo(({ activity }) => {
  const timeAgo = useMemo(() => <TimeAgo datetime={activity.created_at} />, [activity.created_at])

  return (
    <ActivityContainer>
      <div className="flex items-center mb-2">
        <Avatar
          name={activity.user_name}
          src={activity.user_avatar_url}
          size="24"
          round
          className="mr-2.5 flex-shrink-0"
        />

        <Typography fontSize="12" component="p">
          <div>
            {getCopy(activity.name, activity.user_name)}{' '}
            <Typography fontSize="10" color="fog_rain" component="p">
              <Tooltip label={moment(activity.created_at).format('LT, MMM D')}>{timeAgo}</Tooltip>
            </Typography>
          </div>
        </Typography>
      </div>
    </ActivityContainer>
  )
}, isEqual)

export default Activity
