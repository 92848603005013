import { Consumer, Subscription, createConsumer } from '@rails/actioncable'
import { remove } from 'lodash'
import { Dispatch } from 'react'
import { v4 as uuid } from 'uuid'

import api, { API } from 'utils/api'

export type ObjectChange<ObjectType> = {
  user_id: string | null
  timestamp: number
  data: Partial<ObjectType>
  update_uuid: string
}

/**
 * these API {@link api} must accept the following parameters in order:
 *
 * teamSlug: string
 * objectId: string
 * change: Partial<ObjectType>
 * updateUuid?: string
 *
 * corresponding action cable channels must accept the following parameters:
 *
 * id: string
 * team_slug: string
 * data: Partial<ObjectType>
 */
const supportedApis: Record<keyof Pick<API, 'updateMessage' | 'updateDraftMessage'>, string> = {
  updateDraftMessage: 'UpdateDraftChannel',
  updateMessage: 'ComposeChannel',
}

type SupportedApi = keyof typeof supportedApis
type ObjectType<T extends SupportedApi> = Parameters<API[T]>[2]

export class AutoSaver<T extends SupportedApi> {
  readonly teamSlug: string
  readonly objectId: string

  readonly userId: string
  readonly apiName: SupportedApi
  private setObject: Dispatch<Partial<ObjectType<T>>>
  private onSave?: () => void

  private autoSaveInterval: number | undefined
  private change: Partial<ObjectType<T>> | null = null
  private sentUpdateUuids: string[] = []

  private actionCableConsumer?: Consumer
  private updateDraftChannelSubscription?: Subscription

  constructor(props: {
    teamSlug: string
    objectId: string
    userUuid: string
    setObject: Dispatch<Partial<ObjectType<T>>>
    apiName: T
    onSave?: () => void
  }) {
    this.objectId = props.objectId
    this.apiName = props.apiName
    this.userId = props.userUuid
    this.setObject = props.setObject
    this.teamSlug = props.teamSlug
    this.onSave = props.onSave

    this.autoSaveInterval = window.setInterval(this.sendToServer, 1000)
    this.setupActionCableConnection()
  }

  private setupActionCableConnection = () => {
    this.actionCableConsumer = createConsumer('/cable')
    this.updateDraftChannelSubscription = this.actionCableConsumer.subscriptions.create(
      {
        channel: this.channelName,
        id: this.objectId,
        team_slug: this.teamSlug,
      },
      {
        received: this.onSubscriptionReceived,
      },
    )
  }

  private onSubscriptionReceived = (message: ObjectChange<ObjectType<T>>) => {
    if (this.sentUpdateUuids.includes(message.update_uuid)) {
      remove(this.sentUpdateUuids, message.update_uuid)
      return
    }

    this.setObject(message.data)
  }

  sendChange = (draftChange: Partial<ObjectType<T>>) => {
    this.change = {
      ...this.change,
      ...draftChange,
    }
  }

  forceSendToServer = () => {
    this.sendToServer()
  }

  private sendToServer = (fallbackToApi = false) => {
    if (!this.change) return

    let actionCableConnected = false

    if (this.channelName) {
      actionCableConnected = this.actionCableConsumer!.connection.isActive()
      if (!actionCableConnected && !fallbackToApi) {
        this.actionCableConsumer!.ensureActiveConnection()

        window.setTimeout(() => this.sendToServer(true), 250)
        return
      }
    }

    const updateUuid = uuid()

    if (actionCableConnected) {
      const sent = this.updateDraftChannelSubscription!.send({
        update_uuid: updateUuid,
        user_id: this.userId,
        data: this.change,
        timestamp: new Date().getTime(),
      } as ObjectChange<ObjectType<T>>)

      if (sent) {
        this.change = null
        this.sentUpdateUuids.push(updateUuid)
        this.onSave?.()
        console.log('message auto saved via websockets')
      } else {
        this.actionCableConsumer!.ensureActiveConnection()
        window.setTimeout(() => this.sendToServer(true), 250)
      }
    } else if (!!this.change) {
      const apiMethod = api[this.apiName]
      apiMethod(this.teamSlug, this.objectId, this.change as any, updateUuid)?.then(() => {
        this.change = null
        this.sentUpdateUuids.push(updateUuid)
        this.onSave?.()
        console.log('message auto saved via xhr')
      })
    }
  }

  stop = () => {
    this.updateDraftChannelSubscription?.unsubscribe()
    this.actionCableConsumer?.disconnect()
    window.clearInterval(this.autoSaveInterval)
  }

  get actionCableConnected() {
    return this.actionCableConsumer?.connection?.isActive()
  }

  private get channelName() {
    return supportedApis[this.apiName]
  }
}
