import axios from 'axios'
import { CrmField } from 'utils/types'

export default {
  getSfdcFields: () => {
    return axios.get<{ sfdc_fields: CrmField[] }>('/api/sfdc_fields')
  },

  // we should fully abstract all the crm field filtering
  getHubspotFields: () => {
    return axios.get<{ hubspot_fields: CrmField[] }>('/api/hubspot_fields')
  },

  getAllStages: (team_slug: string) => {
    return axios.get<{ stages: { name: string; id: string }[] }>('/api/opps/stage_names', {
      params: {
        team_slug,
      },
    })
  },

  getAllOwnerEmails: (team_slug: string) => {
    return axios.get<{ emails: string[] }>('/api/opportunities/all_owner_emails', {
      params: {
        team_slug,
      },
    })
  },
}
