import * as React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { isEqual } from 'lodash'
import { cabalToast } from 'ui-components/Toast'
import { Contact } from 'utils/types'
import { gt } from 'utils/ui'
import Table, { Column, Row, TrProps } from 'global/Table'
import copyToClipboard from 'utils/copyToClipboard'

const TableHeading = styled.th`
  ${tw`whitespace-nowrap text-left p-3 py-2 sticky top-0`}
  background-color: ${({ theme }) => theme.table_header};
  color: ${({ theme }) => theme.colors.gray};

  &:first-of-type {
    ${tw`rounded-tl rounded-bl`}
  }

  &:last-of-type {
    ${tw`rounded-br rounded-tr`}
  }
`

const TableData = styled.td`
  ${tw`p-3`}

  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 14px;
`

const TableRow = styled.tr<TrProps>`
  ${tw`relative`}
  border-bottom: ${({ theme, isSelected }) => !isSelected && theme.border};
  transform: scale(1);

  &:after {
    content: ${({ isSelected }) => (isSelected ? '""' : 'none')};
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-color: ${({ theme }) => theme.colors.purple};
    border-width: 2px;
    border-left-width: 4px;
    border-style: outset;
    pointer-events: none;
    ${({ nextSelected }) => nextSelected && `border-bottom-width: 0px;`}
  }
`

interface Props {
  contacts: Contact[]
}

const ContactTable: React.FC<Props> = React.memo(
  ({ contacts }) => {
    const columns = React.useMemo<Column<Contact>[]>(() => {
      const cols: Column<Contact>[] = [
        {
          Header: 'Name',
          accessor: 'name',
          sortType: (a, b) => gt(a.original.data.name || '', b.original.data.name || ''),
        },
        {
          Header: 'Email',
          accessor: 'email',
          sortType: (a, b) => gt(a.original.data.email, b.original.data.email),
        },
      ]

      return cols
    }, [])

    const data = React.useMemo<Row<Contact>[]>(() => {
      return contacts.map((c) => {
        return {
          id: c.uuid,
          data: c,
          name: c.name ? (
            <span>
              <span>{c.name}</span>
              <span
                onClick={() => {
                  cabalToast({ style: 'success', content: 'Copied!' })
                  copyToClipboard(c.name || '')
                }}
                className="ml-3 cursor-pointer text-gray-500"
              >
                <i className="fa fa-clipboard" />
              </span>
            </span>
          ) : (
            'No name'
          ),
          email: c.email ? (
            <span>
              <span>{c.email}</span>
              <span
                onClick={() => {
                  cabalToast({ style: 'success', content: 'Copied!' })
                  copyToClipboard(c.email)
                }}
                className="ml-3 cursor-pointer text-gray-500"
              >
                <i className="fa fa-clipboard" />
              </span>
            </span>
          ) : (
            'No email'
          ),
        }
      })
    }, [contacts])

    return (
      <div className="flex-1 overflow-auto mt-2">
        <Table data={data} columns={columns} tr={TableRow} td={TableData} th={TableHeading} />
      </div>
    )
  },
  (prev, next) => {
    if (!isEqual(prev.contacts, next.contacts)) return false
    return true
  },
)

export default ContactTable
