import * as React from 'react'

import { HoverIconMenuProps } from '.'

const HoverIconMenu: React.VFC<HoverIconMenuProps> = ({ menu }) => {
  return (
    <div className="hover-icon-menu flex space-x-4 w-0 max-w-9 justify-end">
      {menu.map((item) => {
        return (
          <div className="flex items-center" onClick={item.handler}>
            {item.label}
          </div>
        )
      })}
    </div>
  )
}

export default HoverIconMenu
