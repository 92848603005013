import React, { useEffect } from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import { Divider } from 'components/SendMessage/styles'
import CabalButton from 'global/CabalButton'
import { CheckBox } from 'global/Input'
import Typography from 'global/Typography'

import LiExplainer from '../../../../assets/images/linkedin-1-2.png'
import Cabal from '../../../images/C.svg'
import LinkedinImage from '../../../images/linkedin.png'

const Container = styled.div`
  ${tw`flex flex-col`}
  height: 100vh;
  background: #f3f4f6;
`

const Spinner = styled.i`
  ${tw`text-3xl mb-2`}
  color: ${({ theme }) => theme.colors.fog_rain};
`

const InfoIcon = styled.i`
  ${tw`mr-1.5`}
  color: ${({ theme }) => theme.colors.fog_rain};
`

const ImgExplainer = styled.img`
  ${tw`mx-auto rounded-lg`}
  box-shadow: 0px 0px 18px #5C69D1;
  width: 50%;
`

const ImgWrapper = styled.div`
  ${tw`py-8`}
  background: #fff;
  border-top: ${({ theme }) => theme.border};
  border-bottom: ${({ theme }) => theme.border};
`

const Header = styled.div`
  background: #fff;
`

const UploadConnectionsModal = () => {
  // useEffect(() => {
  //   const interval = setTimeout(() => {
  //     window.location.assign('https://www.linkedin.com/mypreferences/d/download-my-data')
  //   }, 6000)
  //   return () => clearTimeout(interval)
  // }, [])

  return (
    <Container className="overflow-y-scroll">
      {/* <Header className="h-[51px] w-full px-6 py-2 sticky top-0 z-10">
        <div className="w-[16px] h-[16px]">
          <img src={Cabal} />
        </div>
      </Header> */}
      <Typography
        fontWeight={600}
        className="p-6 py-5"
        fontSize="24"
        color="black"
        lineHeight={1.2}
      >
        Download your LinkedIn Connections
      </Typography>
      <div className="flex flex-col px-6 mb-4">
        <Typography color="fog">
          Click <Typography fontWeight={600}>Connections</Typography> then{' '}
          <Typography fontWeight={600}>Request Archive</Typography>
        </Typography>
      </div>

      <ImgWrapper>
        <ImgExplainer src={LiExplainer} />
      </ImgWrapper>

      {/* <LIButton
        onClick={() => {
          window.analytics?.track('linkedin_modal_opened')
          window.location.assign('https://www.linkedin.com/mypreferences/d/download-my-data')
        }}
        className="w-[153px] h-8 flex items-center justify-center mt-8 bg-[#2D64BC] rounded-[20px] mx-6"
      >
        <Typography lineHeight={1} fontSize="14" fontWeight={600} color="white">
          Continue
        </Typography>
      </LIButton> */}

      <div className="p-6 flex flex-col gap-4 items-center">
        <CabalButton
          onClick={() => {
            const currentDate = new Date().toISOString()
            localStorage.setItem('lastAddedConnectionsDate', currentDate)
            window.analytics?.track('linkedin_modal_opened')
            window.location.assign('https://www.linkedin.com/mypreferences/d/download-my-data')
          }}
          className="w-2/4"
        >
          Continue
        </CabalButton>
      </div>
    </Container>
  )
}

export default UploadConnectionsModal
