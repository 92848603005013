import React from 'react'
import { cabalToast } from 'ui-components/Toast'
import { useMutation } from 'react-query'
import { useParams, useHistory } from 'react-router-dom'
import { DataCard } from 'ui-components/DataCard'
import api from 'utils/api'
import copyToClipboard from 'utils/copyToClipboard'
import { useModal } from 'global/Modal'
import { CompanySlugParam, EmailTemplate } from 'utils/types'
import { DropdownMenuItem } from 'global/DropDownMenu'
import BuildTemplateLinkModal from './BuildTemplateLinkModal'

interface CardViewProps {
  template: EmailTemplate
  reloadTemplates: () => void
}

const CardView: React.VFC<CardViewProps> = ({ template, reloadTemplates }) => {
  const { company_slug } = useParams<CompanySlugParam>()
  const history = useHistory()
  const { showModal } = useModal()

  const openBuildModal = () => {
    showModal(
      (resolve) => (
        <BuildTemplateLinkModal resolve={resolve} teamSlug={company_slug} template={template} />
      ),
      'template-link-build-modal',
    )
  }

  const { mutate: deleteTemplate, isLoading: deletingTemplate } = useMutation(
    (uuid: string) => api.deleteTemplate(uuid),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Template deleted' })
        history.replace(`/messages/templates`)
        reloadTemplates?.()
      },
    },
  )

  const menu: DropdownMenuItem[] = [
    {
      label: 'Copy template link',
      onSelect: () => {
        const url = new URL(window.location.href)
        url.pathname = `/${company_slug}/compose`
        url.searchParams.set('template_uuid', template.uuid)
        copyToClipboard(url.href)
        cabalToast({
          style: 'success',
          content: 'Copied! Use the link to share a pre drafted message',
        })
      },
      dataTestId: 'copy-temp-link-btn',
    },
    // {
    //   label: 'Build template link',
    //   onSelect: () => openBuildModal(),
    //   dataTestId: 'build-temp-link-btn',
    // },
    {
      label: 'Delete template',
      onSelect: () => {
        if (!confirm('Are you sure you want to delete this template?')) return
        deleteTemplate(template.uuid)
      },
      dataTestId: 'delete-temp-btn',
    },
  ]

  return (
    <>
      <DataCard
        title={template.name}
        tags={
          template.public_template
            ? ['Public template']
            : !template.public_template && template.public_to_team
            ? ['Public to team']
            : ['Private template']
        }
        iconCTA={[
          {
            icon: 'fa-pencil',
            onClick: () =>
              deletingTemplate ? null : history.push(`/messages/templates/${template.uuid}`),
            tooltipLabel: 'Edit template',
            dataTestId: 'open-edit-template-btn',
          },
        ]}
        menuItems={menu}
      />
    </>
  )
}

export default CardView
