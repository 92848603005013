import axios, { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { WINSITE_ENDPOINTS } from '../apiEndpoints'

const searchCompanyName = async (
  query: string,
  excludeBlankWebsites?: boolean,
): Promise<AxiosResponse> => {
  return await axios.get(WINSITE_ENDPOINTS.search, {
    params: { query, exclude_blank_websites: excludeBlankWebsites },
  })
}

export const useCompanySearch = (query: string, excludeBlankWebsites?: boolean) =>
  useQuery({
    queryKey: ['companySearch', query, excludeBlankWebsites],
    queryFn: () => searchCompanyName(query, excludeBlankWebsites),
    enabled: !!query,
    retry: 3,
  })
