import { useCallback } from 'react'

import { QueryParamConfigMap, useQueryParams } from 'use-query-params'

import { CurrentUserSettings } from 'utils/types'

export const useQueryParamsWithSettings = <K extends keyof CurrentUserSettings>(
  queryParamConfigMap: QueryParamConfigMap,
) => {
  const [params, setParams] = useQueryParams(queryParamConfigMap)
  // const [settingValue, setSettingValue] = useCurrentUserSetting(setting, defaultValue, suffix)

  const setParamsAndSetting = useCallback((updatedParams: any) => {
    const searchParams = new URLSearchParams(window.location.search)
    Object.keys(queryParamConfigMap).forEach((key) => {
      searchParams.delete(key)
    })
    setParams(updatedParams, 'push')
    // setSettingValue(updatedParams)
  }, [])

  // useEffect(() => {
  //   if (!isEqual(params, settingValue)) {
  //     setParams({ ...(settingValue as any), ...compactMap(params) }, 'replaceIn')
  //   }
  // }, [])

  return [params, setParamsAndSetting] as [
    NonNullable<CurrentUserSettings[K]>,
    (params: CurrentUserSettings[K]) => void,
  ]
}
