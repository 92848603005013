import React, { useState } from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { TextArea, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import {
  ModalInputLabel,
  ModalInputWrapper,
  ModalSectionHeader,
  ModalSectionWrapper,
} from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import copyToClipboard from 'utils/copyToClipboard'
import { MessageRecipient, SenderModel } from 'utils/types'

interface Props {
  onHide: () => void
  onSubmit: (note: string) => void
  sender: SenderModel
  subject: string
  uuid: string
  teamSlug: string
  to: MessageRecipient[] | undefined
}

const AccessorWrapper = styled.div`
  ${tw`rounded flex items-center justify-between px-3`}
  border: ${({ theme }) => theme.border};
  height: 42px;
`

const ShareDraft: React.VFC<Props> = ({
  onHide,
  onSubmit,
  sender,
  subject,
  uuid,
  teamSlug,
  to,
}) => {
  const host = window.location.origin
  const [note, setNote] = useState<string>('')

  return (
    <Modal
      show={true}
      onHide={onHide}
      header="Share Draft"
      rightActions={
        <CabalButton
          onClick={() => {
            onSubmit(note)
            onHide()
          }}
          data-testid="share-draft-modal-share-button"
        >
          Share
        </CabalButton>
      }
      leftActions={
        <CabalButton
          variant="secondary"
          onClick={() => {
            copyToClipboard(`${host}/${teamSlug}/compose/${uuid}`)
            cabalToast({ style: 'success', content: 'Copied to clipboard' })
          }}
          leftIcon={<i className="far fa-link"></i>}
        >
          Copy link
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>To</ModalInputLabel>
          <Typography fontSize="12">{to?.map((rec) => rec.label).join(', ')}</Typography>
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Subject line</ModalInputLabel>
          <Typography fontSize="12">{subject}</Typography>
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Share with</ModalInputLabel>
          <div className="w-full">
            <AccessorWrapper>
              <div>
                <Avatar
                  name={sender.name}
                  src={sender.avatar_url}
                  size="24"
                  round
                  className="mr-2"
                />
                <Typography fontSize="12">{sender.name}</Typography>
              </div>
            </AccessorWrapper>
          </div>
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Add note</ModalInputLabel>
          <TextArea
            value={note}
            className={'w-full'}
            onChange={(e) => setNote(e.target.value)}
            rows={6}
            placeholder="Add a note before sharing the draft"
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default ShareDraft
