import React from 'react'
import { HeaderRowParent } from 'containers/Portfolio/styles'
import { CheckBox } from 'global/Input'
import Typography from 'global/Typography'

interface HeaderSortProps {
  isSorted: boolean
  isSortedDesc: boolean
}

interface HeaderItem {
  label: string
  value: string
}

interface HeaderProps {
  onSortBy: (field: string) => void
  sortedBy: string | null
  isSortedDesc: boolean
  selectedAll: boolean
  onToggleSelectAll: (v: boolean) => void
  headers: HeaderItem[]
}

const HeaderSort: React.VFC<HeaderSortProps> = ({ isSorted, isSortedDesc }) => {
  return (
    <div className="inline ml-2">
      {isSorted && isSortedDesc && <i className="fas fa-sort-down" />}
      {isSorted && !isSortedDesc && <i className="fas fa-sort-up" />}
      {!isSorted && <i className="fas fa-sort" />}
    </div>
  )
}

const TableHeader: React.VFC<HeaderProps> = ({
  sortedBy,
  isSortedDesc,
  onSortBy,
  onToggleSelectAll,
  selectedAll,
  headers,
}) => {
  return (
    <Typography
      component="div"
      color="fog"
      textTransform="uppercase"
      fontSize="10"
      fontWeight={500}
      letterSpacing="0.05em"
      textAlign="left"
    >
      <HeaderRowParent>
        <div className="flex items-center ml-3.5">
          <CheckBox
            checked={selectedAll}
            onChange={(e) => onToggleSelectAll(e.target.checked)}
            data-testid="select-all-checkbox"
            className="ml-1 mr-3"
          />
        </div>
        {headers.map(({ label, value }) => {
          return (
            <div key={value}>
              <button onClick={() => onSortBy(value)}>
                {label}
                <HeaderSort isSorted={sortedBy === value} isSortedDesc={isSortedDesc} />
              </button>
            </div>
          )
        })}
      </HeaderRowParent>
    </Typography>
  )
}

export default TableHeader
