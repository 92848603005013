import React from 'react'

import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import ItemCardV2 from 'components/Block/ItemCard'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import people from 'utils/api/people'

import ShowMore from './ShowMore'
import Title from './Title'
import { HomeSectionWrapper } from './styles'

interface Props {
  teamSlug: string
}

const PortfolioSection: React.FC<Props> = ({ teamSlug }) => {
  const history = useHistory()
  const [companies, setCompanies] = React.useState([])
  const { team } = useTeam(teamSlug)

  const { isLoading, isFetching, refetch } = useQuery(
    ['getInvestorCompanies', teamSlug],
    () => callApi(api.getInvestorCompanies, teamSlug, null, 3),
    {
      onSuccess: ({ companies: newCompanies }) => {
        setCompanies(newCompanies)
      },
    },
  )

  if (isLoading) return <></>

  return (
    <>
      <HomeSectionWrapper id={`${teamSlug}-portfolio`}>
        <Title label={'Portfolio'} onClick={() => history.push(`/${teamSlug}/portfolio`)} />

        <div className="flex flex-col">
          {companies.map((company) => {
            return (
              <ItemCardV2
                queryKey={['getInvestorCompanies', teamSlug]}
                key={company.uuid}
                itemView={{
                  title: company.company_name,
                  avatar_url: company.logo_url,
                  avatar_name: company.company_name,
                  info_element: company.intro_request
                    ? {
                        text: 'Intro Requested',
                        icon: 'far fa-check-circle',
                      }
                    : {},
                  on_click: company.intro_request
                    ? {}
                    : {
                        type: 'modal',
                        identifier: 'RequestIntroModal',
                        label: 'Request Intro',
                        props: {
                          companyUuid: company.uuid,
                          companyLogo: company.logo_url,
                          companyName: company.company_name,
                        },
                      },
                }}
                teamSlug={teamSlug}
              />
            )
          })}
        </div>
        {!isFetching && team.investor_companies_count > 0 && (
          <ShowMore
            link={`/${teamSlug}/portfolio`}
            limit={0}
            objectArray={companies}
            title={`See all (${team.investor_companies_count})`}
            hideMore={team.investor_companies_count < 0}
          />
        )}
      </HomeSectionWrapper>
    </>
  )
}

export default PortfolioSection
