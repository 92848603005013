import React from 'react'

import styled from 'styled-components'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

const SectionWrapper = styled.div`
  border: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.layout.main_content_bg_color};
`

export interface IChartEmptyStateProps {
  title: string
  subtitle: string
  icon: React.ReactNode
  buttonText: string
  onClick: () => void
}

const ChartEmptyState = ({ title, subtitle, icon, buttonText, onClick }: IChartEmptyStateProps) => {
  return (
    <SectionWrapper className="flex items-center flex-col gap-2 p-4 rounded-md w-2/3">
      <Typography>{icon}</Typography>
      <Typography>{title}</Typography>
      <Typography color="fog" fontSize="14">
        {subtitle}
      </Typography>
      <CabalButton className="mt-4" onClick={onClick}>
        {buttonText}
      </CabalButton>
    </SectionWrapper>
  )
}

export default ChartEmptyState
