import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

interface Props {
  icon: React.ReactNode
  title: string
  titleContext?: string
  description?: string
  onClick: () => void
  dataIntercomTarget?: string
  buttonText?: string
  borderless?: boolean
}

const Wrapper = styled.div<{ borderless: boolean }>`
  ${tw`mb-4 flex items-center py-4 rounded-lg w-full`}
  ${({ borderless }) => (borderless ? '' : tw`px-5 cursor-pointer`)}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: ${({ theme, borderless }) =>
    borderless ? 'none' : `2px solid ${theme.layout.main_bg_color}`};

  &:hover {
    border: ${({ theme, borderless }) =>
      borderless ? 'none;' : `2px solid ${theme.colors.purple}`};
  }

  &:last-child {
    ${tw`mb-0`};
  }
`

const OptionSelector: React.VFC<Props> = ({
  icon,
  title,
  titleContext,
  description,
  dataIntercomTarget,
  onClick,
  buttonText,
  borderless = false,
}) => {
  return (
    <Wrapper
      data-intercom-target={dataIntercomTarget}
      onClick={borderless ? () => {} : onClick}
      borderless={borderless}
    >
      <div className="flex flex-col text-left w-full">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Typography color={'purple'} fontSize="24" className="mr-4">
              {icon}
            </Typography>
            <div className="flex flex-col text-left">
              <div className="flex space-x-1">
                <Typography fontSize={borderless ? '12' : '14'} fontWeight={600} component="h3">
                  {title}
                </Typography>
                <Typography fontSize="14" fontWeight={400} component="h3">
                  {titleContext}
                </Typography>
              </div>

              {description && (
                <Typography
                  lineHeight={1.2}
                  fontSize="12"
                  color="fog"
                  component="div"
                  className="max-w-sm"
                >
                  {description}
                </Typography>
              )}
            </div>
          </div>

          {buttonText && (
            <div className="ml-4">
              <CabalButton
                onClick={borderless ? onClick : () => {}}
                variant="secondary"
                rightIcon={<i className="far fa-arrow-right"></i>}
              >
                {buttonText}
              </CabalButton>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default OptionSelector
