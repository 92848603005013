import { orderBy, uniqBy } from 'lodash'
import CabalMessage from 'models/CabalMessage'
import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { FiltersValueType } from 'components/Filters'
import MessageFeedCard from 'components/MessageFeedCard'
import Banner from 'global/Banner'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import { useCurrentUser, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'

import G from '../../../images/google-icon.png'

const Inbox: React.FC = () => {
  const teamSlug = useTeamSlug()
  const { user } = useCurrentUser()
  const canFetchInbox = user.can_modify_inbox || user.can_read_inbox

  const [filters] = useState<FiltersValueType>({})

  const { data, fetchNextPage, isFetchingNextPage, isFetching, isLoading, refetch } =
    useInfiniteQuery(
      ['getInboxThreads'],
      ({ pageParam: page = 1 }) => callApi(api.getInboxThreads, filters, { page }),
      {
        getNextPageParam: (lastPage) => lastPage.pagination.next_page,
        getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
        enabled: canFetchInbox,
      },
    )

  const inboxThreads = orderBy(
    uniqBy(data?.pages?.flatMap((page) => page.threads) ?? [], (thread) => thread.uuid),
    (thread) => thread.last_message_at,
    'desc',
  )

  if (isLoading && canFetchInbox) {
    return <Loading className="mt-8" />
  }

  return (
    <>
      {user.features?.inbox && !user.can_modify_inbox && (
        <Banner className="text-center">
          <div className="w-full py-2">
            <div className="mb-2">
              Please provide Gmail read permission to access your full inbox inside of Cabal
            </div>
            <CabalButton
              variant="auth"
              leftIcon={<img src={G} width="18" />}
              component="a"
              href={`/auth/gmail?read_email=true&return_to=/${teamSlug}/inbox`}
            >
              Sign in with Google
            </CabalButton>
          </div>
        </Banner>
      )}
      {inboxThreads.map((thread) => (
        <div key={thread.uuid}>
          <MessageFeedCard
            message={
              new CabalMessage(
                {
                  subject: thread.first_inbox_message.subject || '',
                  uuid: thread.uuid,
                  created_at: thread.last_message_at!,
                  snippet:
                    thread.snippet ||
                    thread.first_inbox_message.snippet ||
                    thread.first_inbox_message.body,
                  body:
                    thread.snippet ||
                    thread.first_inbox_message.snippet ||
                    thread.first_inbox_message.body,
                  type: 'InboxMessage',
                },
                thread.first_inbox_message,
              )
            }
            reloadMessages={refetch}
            onClickUrl={`/${teamSlug}/inbox/${thread.uuid}`}
          />
        </div>
      ))}

      {canFetchInbox && (
        <div className="flex justify-center	my-6">
          <CabalButton
            variant="secondary"
            disabled={isFetching}
            working={isFetchingNextPage}
            onClick={() => fetchNextPage()}
          >
            Load more
          </CabalButton>
        </div>
      )}
    </>
  )
}

export default Inbox
