import * as React from 'react'
import { useRef } from 'react'

import styled from 'styled-components'

import Footer from '../NewSite/footer'
import Nav from '../NewSite/nav'
import {
  ColumnLayout,
  Divider,
  H1,
  LandingPageWrapper,
  SectionContainer,
  SignupContainer,
  SmallerColumnLayout,
  Subtitle,
} from '../NewSite/styles'
import DataSection from './DataSection'
import Advisor from './images/solutions/section-advisor.png'
import FounderPortal from './images/solutions/section-founderportal.png'
import Fundraising from './images/solutions/section-fundraising.png'
import Marketing from './images/solutions/section-marketing.png'
import Portfolio from './images/solutions/section-portfolio.png'
import Sales from './images/solutions/section-sales.png'
import Talent from './images/solutions/section-talent.png'
import Updates from './images/solutions/section-updates.png'

const CTAButton = styled.button`
  font-family: 'JetBrains Mono' !important;
  margin: 0px !important;
  height: 40px !important;
  margin-right: 16px !important;
`

const SectionTitle = styled.p`
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #718096 !important;
  margin-bottom: 32px;
`

const SolutionsPage = () => {
  const modernHealthRef = useRef()

  function scrollToComponent() {
    if (window.location.hash === '#modern-health') {
      modernHealthRef.current.scrollIntoView()
      modernHealthRef.current.focus()
    }
  }

  React.useEffect(() => scrollToComponent(), [])

  React.useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1)

      const element = document.getElementById(hash)
      if (element) {
        const overflowWrapper = document.getElementById('signup-container')
        overflowWrapper?.scrollBy({
          top: (element?.getBoundingClientRect().top || 0) + 200,
          behavior: 'smooth',
        })
      }
    }

    handleHashChange()
  }, [])

  return (
    <LandingPageWrapper>
      <SignupContainer id="signup-container" data-testid="solutions-page">
        <Nav />

        <SectionContainer id="for_companies">
          <div className="flex flex-col items-center p-5 overflow-hidden">
            <ColumnLayout>
              <section className="mx-auto mt-12 sm:mt-20">
                <div className="flex justify-center items-center flex-col">
                  <SectionTitle>Cabal for companies</SectionTitle>
                  <H1 className="text-center max-w-xl">
                    Get more out of your investors and advisors
                  </H1>
                  <Subtitle className="pt-4 text-center max-w-2xl">
                    Transform the potential of your cap table.
                  </Subtitle>
                  <div className="mt-12">
                    <a style={{ border: 0, outline: 'none' }} href="/login">
                      <CTAButton>Start for free</CTAButton>
                    </a>
                  </div>
                </div>
              </section>
            </ColumnLayout>
          </div>
        </SectionContainer>
        <SectionContainer className="mt-32">
          <SmallerColumnLayout>
            <div ref={modernHealthRef}>
              <DataSection
                label="Sales"
                title="Turn your investors into your best sellers"
                description={`Share your pipeline, surface mutual connections, ghostwrite intros, and track their impact. Sales automation built for your extended network.`}
                // img={Section1DK}
                img={Sales}
              />
            </div>
            <DataSection
              label="Marketing"
              title="Supercharge your distribution"
              description={`Word of mouth is powerful. Harness this channel by organizing your supporters to amplify your message.`}
              // img={Section1DK}
              img={Marketing}
            />
            <DataSection
              label="Advisor Program"
              title="Expand your network of contributors"
              description={`Create an alliance of supporters and reward their contributions with equity.`}
              // img={Section1DK}
              img={Advisor}
            />
            <DataSection
              label="Investor Updates"
              title="Send engaging, consistent investor updates"
              description={`Templates, collaborative editing, personalization, and mail merge delivery mean better updates that take less time.`}
              // img={Section1DK}
              img={Updates}
            />
          </SmallerColumnLayout>
        </SectionContainer>
        <SectionContainer>
          <SmallerColumnLayout className="">
            <Divider className="mb-8" />
          </SmallerColumnLayout>
        </SectionContainer>
        <SectionContainer id="for_investors">
          <div className="flex flex-col items-center p-5 overflow-hidden">
            <ColumnLayout>
              <section className="mx-auto mt-12 sm:mt-20">
                <div className="flex justify-center items-center flex-col">
                  <SectionTitle>Cabal for investors</SectionTitle>
                  <H1 className="text-center max-w-xl">10x your portfolio impact </H1>
                  <Subtitle className="pt-4 text-center max-w-2xl">
                    Finally, a VC Platform Operating System designed to make and measure your
                    impact.
                  </Subtitle>
                  <div className="mt-12">
                    <a style={{ border: 0, outline: 'none' }} href="/login">
                      <CTAButton>Start for free</CTAButton>
                    </a>
                  </div>
                </div>
              </section>
            </ColumnLayout>
          </div>
        </SectionContainer>
        <SectionContainer className="mt-32">
          <SmallerColumnLayout>
            <DataSection
              label="Portfolio Engagement"
              title="Deliver targeted comms in an instant"
              description={`Organize your audience of founders, execs, LPs, scouts and other partners. Then deliver key messages you know they’ll see.`}
              // img={Section1DK}
              img={Portfolio}
            />
            <DataSection
              label="Talent Management"
              title="Fill key roles faster"
              description={`Share exclusive access to key roles, then sit back and watch the matchmaking take care of itself.`}
              // img={Section1DK}
              img={Talent}
            />
            {/* <DataSection
            label="Business Development"
            title="Help your companies sell"
            description={`Your network is a goldmine for your portcos. Share your connections and become your portfolio’s best new revenue channel.`}
            // img={Section1DK}
            img={Placeholder}
          /> */}
            <DataSection
              label="Founder Portal"
              title="Professional and exclusive portal for portcos"
              description={`Share exclusive offers, deals, content and comms in a single, branded portal for your fund.`}
              // img={Section1DK}
              id={'founder_portal'}
              img={FounderPortal}
            />
            <DataSection
              label="Fundraising & SPVS"
              title="Fill your fund faster"
              description={`Raising a new fund or SPV can be faster than you think. Organize current and prospective LPs into groups, deliver targeted, personalized emails about the opportunity, and watch the commitments roll in.`}
              // img={Section1DK}
              img={Fundraising}
            />
          </SmallerColumnLayout>
        </SectionContainer>
        <SectionContainer className="mt-32">
          <div className="z-10 mb-16 -mt-16">
            <div>
              <Footer withForm={false} />
            </div>
          </div>
        </SectionContainer>
      </SignupContainer>
    </LandingPageWrapper>
  )
}

export default SolutionsPage
