import React from 'react'

import { TweetModel } from 'utils/types'
import Typography from 'global/Typography'
import Tweet from 'containers/Connections/Twitter/Tweet'
import styled from 'styled-components'

interface Props {
  teamSlug: string
  tweet: TweetModel
}

const RequestRetweetPreview: React.FC<Props> = ({ tweet, teamSlug }) => {
  return (
    <div className="mt-6">
      <Typography
        textTransform="uppercase"
        fontSize="10"
        letterSpacing={'0.05em'}
        fontWeight={600}
        color="widget_title"
      >
        Tweet preview
      </Typography>

      <StyledTweet className="mt-4 rounded-sm border-2" teamSlug={teamSlug} tweet={tweet} />
    </div>
  )
}

const StyledTweet = styled(Tweet)`
  border-color: ${({ theme }) => theme.colors.border};
`

export default RequestRetweetPreview
