import React, { useEffect } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useAdvisorship, useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'

interface Props {
  location: 'dashboard' | 'companyPage'
  setConnectionsShared?: (value: boolean) => void
}

const ConnectionsWidget: React.FC<Props> = ({ location, setConnectionsShared }) => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { advisor, reloadAdvisorship } = useAdvisorship(teamSlug)

  const memberUuid = advisor?.uuid || ''

  const {
    data: connectionStatus,
    refetch: refetchConnectionStatus,
    isLoading,
  } = useQuery(
    ['connectionStatus', memberUuid],
    () => callApi(api.checkConnectionStatus, memberUuid),
    {
      enabled: !!memberUuid,
    },
  )

  const shareConnectionsMutation = useMutation(
    () => callApi(api.updateConnectionSharing, { team_slug: teamSlug, share_connections: true }),
    {
      onSuccess: () => {
        cabalToast({
          style: 'success',
          content: `Your connections were shared with ${team?.name}!`,
        })

        refetchConnectionStatus()
        reloadAdvisorship()
      },
    },
  )

  const { connections_synced_at, shared_connections_at, uploaded_connections_at, linkedin_export } =
    connectionStatus || {}

  const connections_count = connectionStatus?.connections_count || linkedin_export?.count || 0

  const shared_connections = !!shared_connections_at

  const notSharedConnections =
    !!team && (!!connections_synced_at || !!uploaded_connections_at) && shared_connections !== true

  useEffect(() => {
    if (!!setConnectionsShared && connections_count > 0 && !notSharedConnections) {
      setConnectionsShared(true)
    }
  }, [connections_count, notSharedConnections, setConnectionsShared])

  if (connections_count > 0 && !notSharedConnections) return <></>

  return (
    <>
      {!isLoading && (
        <Widget title="Connections">
          {location === 'dashboard' && (
            <Typography component="p" fontSize="14" color="fog" lineHeight={1.2}>
              Add your connections to the {team!.name} Network
            </Typography>
          )}

          {location === 'companyPage' && (
            <Typography component="p" fontSize="14" color="fog" lineHeight={1.2}>
              Share your connections with {team!.name}
            </Typography>
          )}

          {notSharedConnections ? (
            <CabalButton className="mt-4" onClick={() => shareConnectionsMutation.mutate()}>
              Share Connections
            </CabalButton>
          ) : (
            <CabalButton
              className="mt-4"
              onClick={() =>
                window.open(`/${teamSlug}/add-connections`, '_blank', 'noopener noreferrer')
              }
            >
              Add Connections
            </CabalButton>
          )}
        </Widget>
      )}
    </>
  )
}

export default ConnectionsWidget
