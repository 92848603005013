import React from 'react'

import CabalButton from 'global/CabalButton'
import { CheckBox } from 'global/Input'
import { useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api from 'utils/api'
import { Team } from 'utils/types'

const Tracking = () => {
  const teamSlug = useTeamSlug()
  const { team, reloadTeam } = useTeam(teamSlug)

  const [updatedTeam, setUpdatedTeam] = React.useState<Team>()
  const [working, setWorking] = React.useState(false)

  React.useEffect(() => {
    if (team) setUpdatedTeam(team)
  }, [team])

  const handleSubmit = async () => {
    if (!updatedTeam) return
    setWorking(true)

    try {
      await api.updateTeam(updatedTeam.slug, {
        team: {
          track_message_clicks: updatedTeam.track_message_clicks,
          track_message_opens: updatedTeam.track_message_opens,
        },
      })
      await reloadTeam()

      cabalToast({ style: 'success', content: 'Successfully updated Company' })
    } catch (e) {}

    setWorking(false)
  }

  if (!updatedTeam) {
    return <></>
  }

  return (
    <div>
      <Widget title="Tracking">
        <label className="block cursor-pointer">
          <CheckBox
            checked={updatedTeam.track_message_clicks}
            onChange={(e) =>
              setUpdatedTeam({ ...updatedTeam, track_message_clicks: e.currentTarget.checked })
            }
            label="Track message clicks"
          />
        </label>
        <label className="block cursor-pointer">
          <CheckBox
            checked={updatedTeam.track_message_opens}
            onChange={(e) =>
              setUpdatedTeam({ ...updatedTeam, track_message_opens: e.currentTarget.checked })
            }
            label="Track message opens"
          />
        </label>
        <div className="flex justify-end mt-6">
          <CabalButton
            variant="primary"
            working={working}
            onClick={handleSubmit}
            data-testid="save-public-dir"
          >
            Save
          </CabalButton>
        </div>
      </Widget>
    </div>
  )
}

export default Tracking
