import axios from 'axios'

import api from 'utils/api'
import { isProduction, isTest } from 'utils/env'

const REFRESH_INTERVAL = 1000 * 60 * 5

class JwtManager {
  private token: string | null = null
  interval: number | null = null

  init() {
    this.getNewToken()

    axios.interceptors.request.use((config) => {
      if (this.token) {
        config.headers.Authorization = `Bearer ${this.token}`
      }
      return config
    })

    this.interval = window.setInterval(this.getNewToken, REFRESH_INTERVAL)
  }

  private sendJwtToExtension() {
    if (!window.chrome?.runtime) return
    const referrerExtensionId = localStorage.getItem('referrerExtensionId')

    // prod
    window.chrome.runtime.sendMessage(CHROME_EXTENSION_ID, {
      type: 'set_jwt_token',
      jwtToken: this.token,
    })

    // local
    if (referrerExtensionId === 'dkmnfbekhlegkmmmnfimnigekfhcabjm')
      window.chrome.runtime.sendMessage(referrerExtensionId, {
        type: 'set_jwt_token',
        jwtToken: this.token,
      })

    // 'pre-prod' prod build in testing, not listed on Google store yet
    if (referrerExtensionId === TEST_CHROME_EXTENSION_ID)
      window.chrome.runtime.sendMessage(referrerExtensionId, {
        type: 'set_jwt_token',
        jwtToken: this.token,
      })

    if (!isProduction()) {
      const elem = document.createElement('div')
      elem.innerHTML = `${this.token?.toString()}`
      elem.setAttribute('data-testid', 'jwt-sent-to-extension')
      elem.setAttribute('style', 'width: 2px; height: 2px; background: red;')
      document.body.appendChild(elem)
      console.log('jwt-sent-to-extension added to dom')
    }
  }

  private async getNewToken() {
    try {
      this.token = (await api.getJwt()).data.jwt

      if (!window.chrome?.runtime) {
        // retry after 5 secs
        setTimeout(this.sendJwtToExtension, 5000)
      } else {
        this.sendJwtToExtension()
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export const jwtManager = new JwtManager()
