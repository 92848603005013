import React from 'react'

import { useMutation } from 'react-query'

import BulkActionsIsland from 'containers/ListIndexView/Components/BulkActionsIsland'
import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import { useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

interface Props {
  selectedItems: any[]
  listUuid: string
  setSelectedItems: (items: any[]) => void
}

const ResourcesListBulkActions = ({ selectedItems, listUuid, setSelectedItems }: Props) => {
  const { adminView, refetchResourceList, view, setView } = useResourcesListContext()
  const teamSlug = useTeamSlug()
  const { mutate: deleteResourceItem } = useMutation(
    () =>
      api.removeListItems(
        teamSlug,
        listUuid,
        selectedItems.map((item) => item.uuid),
      ),
    {
      onSuccess: () => {
        refetchResourceList()
        setSelectedItems([])
        cabalToast({ style: 'success', content: 'Item has been removed from the list' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to delete item' })
      },
    },
  )

  const { mutate: exportPeople } = useMutation(
    () =>
      callApi(
        api.exportResourcePeopleList,
        selectedItems.map((item) => item.uuid),
        listUuid,
      ),
    {
      onSuccess: () => {
        cabalToast({
          style: 'success',
          content: 'Company data export initiated. Check your email for the file.',
        })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to export company data.' })
      },
    },
  )

  const handleOnSelect = (action: 'delete' | 'export') => {
    if (selectedItems.length === 0) {
      cabalToast({
        content: 'Select companies to perform bulk actions',
        style: 'error',
      })
      return
    }
    if (action === 'delete') {
      const confirmDelete = window.confirm('Are you sure you want to bulk delete?')
      if (confirmDelete) {
        deleteResourceItem()
      }
    }

    if (action === 'export') {
      exportPeople()
    }
  }

  const bulkMenuItems = [
    {
      title: 'Delete',
      onClick: () => handleOnSelect('delete'),
      icon: <i className="far fa-trash-alt" />,
    },
    {
      title: 'Export',
      onClick: () => handleOnSelect('export'),
      icon: <i className="far fa-file-export" />,
    },
  ]

  return (
    <div>
      {adminView && (
        <>
          <BulkActionsIsland
            isOpen={selectedItems.length > 0}
            setIsOpen={() => setSelectedItems([])}
            bulkMenuItems={bulkMenuItems}
            itemCount={selectedItems.length}
          />
        </>
      )}
    </div>
  )
}

export default ResourcesListBulkActions
