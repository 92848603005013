import React, { useState } from 'react'

import cx from 'classnames'
import { isEqual } from 'lodash'
import Expand from 'react-expand-animated'
import { useHotkeys } from 'react-hotkeys-hook'
import { UseQueryResult, useMutation } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { GetMessagePersonalizationsResponse, MessagePersonalizationBlueprint } from 'utils/types'

import { PersonalizationRecipient } from '.'
import TitleWrappers from './TitleWrappers'

const RecipientCard = styled.button<{ active: boolean }>`
  ${tw`flex w-full rounded-lg items-center text-left px-3 py-1.5 gap-3 justify-between`}

  background-color: ${({ theme, active }) =>
    active ? theme.buttons.primary.bg_color : theme.colors.light_shadow};
  color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.primary)};
`

const StyledLoading = styled(Loading)`
  ${tw`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
`

interface Props {
  getMessagePersonalizationQuery: UseQueryResult<GetMessagePersonalizationsResponse, unknown>
  recipients: PersonalizationRecipient[]
  setCurrentPersonalization: (p?: MessagePersonalizationBlueprint) => void
  currentPersonalization?: MessagePersonalizationBlueprint
  messagePersonalizations?: MessagePersonalizationBlueprint[]
  teamSlug: string
  messageUuid: string
}

const Personalizations: React.VFC<Props> = ({
  getMessagePersonalizationQuery,
  recipients,
  currentPersonalization,
  messagePersonalizations,
  setCurrentPersonalization,
  teamSlug,
  messageUuid,
}) => {
  const personalizations = getMessagePersonalizationQuery.data?.personalizations
  const [personalizing, setPersonalizing] = useState(false)

  useHotkeys(
    'p',
    (e) => {
      e.preventDefault()
      setPersonalizing(!personalizing)
    },
    [personalizing],
  )

  const createPersonalizationMutation = useMutation(
    ['createMessagePersonalization', teamSlug, messageUuid],
    (recipient: PersonalizationRecipient) =>
      callApi(api.createMessagePersonalization, teamSlug, messageUuid, { recipient }),
    {
      onSuccess: ({ personalization }) => {
        setCurrentPersonalization(personalization)
        getMessagePersonalizationQuery.refetch()
      },
    },
  )

  const deletePersonalizationMutation = useMutation(
    ['deleteMessagePersonalization', teamSlug, messageUuid],
    (personalization_uuid: string) =>
      callApi(api.deleteMessagePersonalization, teamSlug, messageUuid, personalization_uuid),
    {
      onSuccess: (_, personalization_uuid) => {
        if (currentPersonalization?.uuid === personalization_uuid)
          setCurrentPersonalization(undefined)
        getMessagePersonalizationQuery.refetch()
      },
    },
  )

  return (
    <div className="my-3">
      <TitleWrappers
        title={`personalize (${messagePersonalizations?.length || 0}/${recipients?.length || 0})`}
        onClick={() => {
          if (personalizing) {
            setCurrentPersonalization(undefined)
            setPersonalizing(false)
          } else {
            setPersonalizing(true)
          }
        }}
        active={personalizing}
        shortcut={'P'}
      />

      <Expand open={personalizing}>
        <div className="flex flex-col gap-2 my-2 custom-scrollbar overflow-auto">
          <RecipientCard
            active={!currentPersonalization}
            onClick={() => setCurrentPersonalization(undefined)}
          >
            <div className="flex items-center gap-3">
              <div className="w-6 text-center">
                <Typography fontSize="14">
                  <i className="far fa-envelope" />
                </Typography>
              </div>
              <Typography fontWeight={600} fontSize="12">
                Default message
              </Typography>
            </div>
          </RecipientCard>
          {recipients.map((recipient) => {
            const personalization = personalizations?.find(
              (p) => p.recipient.uuid === recipient.uuid,
            )
            const active = currentPersonalization?.recipient.uuid === recipient.uuid
            const working =
              (createPersonalizationMutation.variables?.uuid === recipient.uuid &&
                createPersonalizationMutation.isLoading) ||
              (deletePersonalizationMutation.variables === personalization?.uuid &&
                deletePersonalizationMutation.isLoading)

            return (
              <RecipientCard
                active={active}
                key={recipient.uuid}
                onClick={() =>
                  !personalization
                    ? createPersonalizationMutation.mutate(recipient)
                    : setCurrentPersonalization(personalization)
                }
                disabled={working}
                className={cx({ 'opacity-50 relative': working })}
              >
                {working && <StyledLoading size="24px" />}
                <div className="flex items-center gap-3">
                  <Avatar src={recipient.avatar_url} name={recipient.name} size="1.5rem" round />
                  <div className="flex flex-col">
                    <Typography fontWeight={600} fontSize="12">
                      {recipient.name}
                    </Typography>
                    <Typography fontSize="12" color={active ? undefined : 'fog_rain'}>
                      {recipient.email}
                    </Typography>
                  </div>
                </div>
                {!!personalization && (
                  <div>
                    <CabalButton
                      variant="tertiary"
                      padding="0"
                      leftIcon={
                        <Typography color={active ? 'white' : 'primary'}>
                          <i className="fas fa-star-christmas" />
                        </Typography>
                      }
                      color="inherit"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        confirm('Are you sure?') &&
                          deletePersonalizationMutation.mutate(personalization.uuid)
                      }}
                      disabled={
                        deletePersonalizationMutation.isLoading &&
                        deletePersonalizationMutation.variables === personalization.uuid
                      }
                      tooltip={
                        <>
                          Message personalized for this recipient <br />
                          <br /> Click to delete personalization
                        </>
                      }
                    />
                  </div>
                )}
              </RecipientCard>
            )
          })}
        </div>
      </Expand>
    </div>
  )
}

export default React.memo(Personalizations, isEqual)
