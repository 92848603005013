import React, { useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import useStateRef from 'react-usestateref'

import CompanySelector from 'components/CompanySelector'
import CabalButton from 'global/CabalButton'
import { Label, TextArea, TextInput } from 'global/Input'
import Typography from 'global/Typography'
import { useAdvisorship, useCurrentUser } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { emailToDomain, urlToDomain } from 'utils/string'
import { AutoCompleteCompany, Team } from 'utils/types'

interface Props {
  teamAdvised: Team
  nextStep: () => void
}

const NewTeam: React.FC<Props> = ({ teamAdvised, nextStep }) => {
  const { user, reloadUser, updateUser, updatingUser } = useCurrentUser()
  const { advisor } = useAdvisorship(teamAdvised.slug)
  const [companyData, setCompanyData, companyDataRef] = useStateRef<
    AutoCompleteCompany | undefined
  >()
  const [error, setError] = useState<React.ReactNode>('')
  const [name, setName] = useState('')

  const defaultCompanyName = user.company_name || advisor?.company_name || ''
  const createTeamMutation = useMutation(() => callApi(api.createTeam, companyData!), {
    onSuccess: () => {
      reloadUser().then(nextStep)
    },
  })

  const proceedEnabled = !!companyData && !!companyData.domain

  const hasError = useCallback(() => {
    const companyDomain = urlToDomain(companyDataRef.current?.domain)
    return !!companyDomain && companyDomain !== emailToDomain(user.email)
  }, [companyDataRef.current, user.email])

  return (
    <div className="flex flex-col items-center gap-3 w-96">
      {user && !user.name && (
        <Label label={<Typography fontWeight={600}>Your Name</Typography>} className="w-full">
          <TextInput
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            className="w-full"
            lightBg
            autoFocus
          />
        </Label>
      )}
      <Label label={<Typography fontWeight={600}>Company Name</Typography>} className="w-full">
        <CompanySelector
          className="w-full"
          defaultCompanyName={defaultCompanyName}
          companyData={companyData}
          autoFocus={!!user.name}
          onInputChange={() =>
            setTimeout(() => {
              if (hasError()) {
                setError(
                  'You are using a different email domain than your company domain. Please confirm that you are using the correct email address.',
                )
              } else {
                setError('')
              }
            }, 100)
          }
          onCompanySelected={(c) => setCompanyData(c)}
          selectProps={{
            lightBg: true,
          }}
        />
        {!!error && (
          <Typography className="w-full mt-2 px-2" fontSize="12" color="yellow" component="div">
            {error}
          </Typography>
        )}
      </Label>

      <CabalButton
        className="w-full mt-4"
        working={createTeamMutation.isLoading || updatingUser}
        onClick={async () => {
          if (!user.name) {
            await updateUser('name', name)
          }

          if (!!companyData!.team_slug) {
            setError(
              <>
                Seems that this team is already setup,{' '}
                <a className="underline" href={`/${companyData?.team_slug}`} target="_blank">
                  click here
                </a>{' '}
                to request to join
              </>,
            )
            return
          }

          createTeamMutation.mutate()
        }}
        disabled={!proceedEnabled || hasError() || !!error || (!user?.name && !name)}
      >
        Continue
      </CabalButton>
    </div>
  )
}

export default NewTeam
