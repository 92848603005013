import React, { useMemo, useState } from 'react'

import cx from 'classnames'
import compact from 'lodash/compact'
import last from 'lodash/last'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useAskContextModal } from 'components/AsksContextModal'
import { useComposer } from 'components/Composer'
import { useComposeDealBoardDraft } from 'components/Composer/hooks'
import CreateShoutOutModal from 'components/CreateShoutOutModal'
import TimeAgo from 'components/TimeAgo'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import DropDownMenu, { DropdownMenuItem } from 'global/DropDownMenu'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { VoteBlueprint, VoteTrackerStage } from 'utils/types/vote'

const Container = styled.div<{ archived: boolean }>`
  ${tw`p-2 flex gap-0.5 items-center`}
  ${({ archived }) => archived && tw`opacity-50`}
  border-bottom: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.colors.primary_bg};
`

const PersonNameHeadline = styled(Typography)`
  ${tw`flex-1`}

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

interface Props {
  refetch?: () => void
  teamSlug: string
  vote: VoteBlueprint
  stage: VoteTrackerStage
  dismissModal?: () => void
}

const Row: React.FC<Props> = ({ teamSlug, vote, refetch, stage, dismissModal }) => {
  const history = useHistory()
  const { showModal } = useModal()

  const [updating, setUpdating] = useState(false)
  const { composeDealBoardDraft } = useComposeDealBoardDraft()
  const { compose } = useComposer(teamSlug)
  const { isAdmin } = useAccessControl(teamSlug)

  const showPersonContextModal = useAskContextModal({
    teamSlug,
    source: vote.global_person!,
    sourceId: vote.global_person?.id || 0,
    sourceType: 'GlobalPerson',
  })

  const showCompanyContextModal = useAskContextModal({
    teamSlug,
    source: vote.company!,
    sourceId: vote.company?.id || 0,
    sourceType: 'Company',
  })

  const archiveMutation = useMutation(() => callApi(api.archiveVote, teamSlug, vote.uuid), {
    onSuccess: () => {
      setUpdating(false)
      refetch?.()
    },
  })
  const updateMutation = useMutation(
    () => callApi(api.updateVote, teamSlug, vote.uuid, { stage: 'message_sent' }),
    {
      onSuccess: () => {
        setUpdating(false)
        refetch?.()
      },
    },
  )

  const draftMessage =
    stage === 'draft_shared'
      ? vote.draft_messages.find((m) => m.shared_with_sender)
      : last(vote.draft_messages)

  const sentMessage = last(vote.sent_messages)

  const archive = () => {
    setUpdating(true)
    archiveMutation.mutate()
  }

  const markAsSent = () => {
    setUpdating(true)
    updateMutation.mutate()
  }

  const contextMenuItems = useMemo(() => {
    const cxMenuItems: (DropdownMenuItem | undefined)[] = []

    let defaultActionOnClick: undefined | (() => void)
    let defaultActionLabel = ''
    if (stage === 'liked') {
      if (!!draftMessage) {
        defaultActionLabel = 'Open draft'
        defaultActionOnClick = () => {
          compose({
            messageUuid: draftMessage.uuid,
          })
        }
      } else {
        defaultActionLabel = 'New draft'
        defaultActionOnClick = () => {
          composeDealBoardDraft({
            teamSlug,
            person: vote.global_person ? { id: vote.global_person?.id } : undefined,
            company_id: vote.company?.id,
            user_uuid: vote.user?.uuid,
          })
        }
      }
    } else if (stage === 'draft_shared') {
      defaultActionLabel = 'Open draft'
      defaultActionOnClick = () => {
        compose({
          messageUuid: draftMessage!.uuid,
        })
      }
    } else if (stage === 'message_sent' && !!sentMessage) {
      defaultActionLabel = 'Open message'
      defaultActionOnClick = () => {
        dismissModal?.()
        history.push(`/${teamSlug}/messages/${sentMessage.uuid}`)
      }
    }

    cxMenuItems.push(
      {
        label: defaultActionLabel,
        onSelect: defaultActionOnClick,
      },
      { divider: true },
    )

    if (vote.global_person) {
      cxMenuItems.push({
        label: `View “${vote.global_person.name}”`,
        onSelect: showPersonContextModal,
      })
    }

    if (vote.company) {
      cxMenuItems.push({
        label: `View “${vote.company.name}”`,
        onSelect: showCompanyContextModal,
      })
    }

    const archiveItem = {
      label: (
        <>
          <i className="far fa-box-archive mr-3" />
          Archive
        </>
      ),
      onSelect: archive,
    }

    // const createShoutOutItem = isAdmin
    //   ? {
    //       label: (
    //         <>
    //           <i className="far fa-megaphone mr-3" />
    //           Create Shout Out
    //         </>
    //       ),
    //       onSelect: createShoutOut,
    //     }
    //   : undefined
    const createShoutOutItem = undefined

    if (stage === 'liked') {
      cxMenuItems.push(createShoutOutItem, archiveItem, {
        label: (
          <>
            <i className="far fa-check-circle mr-3" />
            Mark as sent
          </>
        ),
        onSelect: markAsSent,
      })
    } else if (stage === 'draft_shared') {
      cxMenuItems.push(createShoutOutItem, archiveItem, {
        label: (
          <>
            <i className="far fa-check-circle mr-3" />
            Mark as sent
          </>
        ),
        onSelect: markAsSent,
      })
    } else {
      cxMenuItems.push(createShoutOutItem, archiveItem)
    }

    return compact(cxMenuItems)
  }, [vote, stage, isAdmin])

  const copy = useMemo(() => {
    const personOrCompany = vote.global_person
      ? [vote.global_person?.name, vote.global_person?.headline].join(' - ')
      : vote.company?.name

    if (stage === 'liked') {
      return (
        <>
          {vote.user.name} liked:
          <br /> {personOrCompany}
        </>
      )
    } else if (stage === 'draft_shared') {
      return `Draft Shared with ${vote.user.name} for ${personOrCompany}`
    } else {
      const subject = sentMessage?.subject
      if (subject) {
        return `${vote.user.name} sent: "${subject}" to ${personOrCompany}`
      } else {
        return `${vote.user.name} sent intro message to ${personOrCompany}`
      }
    }
  }, [vote, stage])

  let rightAction: React.ReactNode = <></>
  const menuTrigger = (
    <CabalButton
      variant="tertiary"
      leftIcon={
        <Typography fontSize="16">
          <i className="far fa-ellipsis-h" />
        </Typography>
      }
      padding="4px 7px"
    />
  )

  if (updating) {
    rightAction = <Loading size={20} />
  } else {
    rightAction = <DropDownMenu trigger={menuTrigger} menuItems={contextMenuItems} />
  }

  return (
    <Container archived={updating} data-testid="likes-tracker-row-container">
      <DropDownMenu
        className={cx('flex flex-1')}
        trigger={
          <div className="flex flex-1 text-left gap-2 items-center">
            <div style={{ width: '35px' }}>
              <Avatar size="35" src={vote.user.avatar_url} name={vote.user.name} />
            </div>
            <div className="flex flex-col flex-1">
              <Tooltip label={copy} delay={[500, 0]}>
                <PersonNameHeadline fontSize="12" lineHeight={1.25}>
                  {copy}
                </PersonNameHeadline>
              </Tooltip>
              <Typography color="rain" fontSize="10" className="h-1 mb-2" component="div">
                <TimeAgo datetime={vote.created_at} />
                {draftMessage && stage === 'liked' && ' · Draft started'}
              </Typography>
            </div>
          </div>
        }
        menuItems={contextMenuItems}
      />
      <div className="text-center">{rightAction}</div>
    </Container>
  )
}

export default Row
