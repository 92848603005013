import axios, { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import { IExportParams } from 'containers/RequestCenter/types/ITypes'

import { REQUEST_CENTER_ENDPOINTS } from '../apiEndpoints'

const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')

const exportRequestCenterData = async ({
  teamSlug,
  name,
  status,
}: IExportParams): Promise<AxiosResponse> => {
  return await axios.post(
    REQUEST_CENTER_ENDPOINTS.export,
    {
      teamSlug,
      name,
      status,
    },
    {
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      },
    },
  )
}

export const useExportRequestCenterData = ({ teamSlug, name, status }: IExportParams) =>
  useMutation({
    mutationKey: ['export', teamSlug, name, status],
    mutationFn: () => exportRequestCenterData({ teamSlug, name, status }),
    retry: 0,
  })
