import React from 'react'

import { faPartyHorn } from '@fortawesome/pro-regular-svg-icons/faPartyHorn'

import CabalButton from 'global/CabalButton'
import Modal, { ModalComponentProps } from 'global/Modal'
import Typography from 'global/Typography'
import Icon from 'ui-components/Icon'

import { cn } from 'utils/styles'

import extensionIcon from 'assets/images/chrome-extension.svg'
import pinIcon from 'assets/images/chrome-pin.svg'
import chromeSteps from 'assets/images/chrome-steps.png'

const ExtensionInstalledModal: React.FC<ModalComponentProps> = ({ resolve }) => {
  return (
    <Modal header={null} show onHide={resolve}>
      <div className="py-4">
        <Typography fontSize="18" fontWeight={600} component="h1">
          Pin the Cabal extension for easy access
        </Typography>
        <Typography component="div" className="flex w-full">
          <div className="flex-1">
            <Typography fontWeight={600} fontSize="14" className="mt-5 flex items-center">
              <Typography
                className={cn(
                  'border border-current rounded-full ',
                  'w-6 h-6 flex items-center justify-center mr-3',
                )}
              >
                1
              </Typography>{' '}
              Click the <img src={extensionIcon} className="w-4 h-4 ml-1.5" />
            </Typography>
            <Typography fontWeight={600} fontSize="14" className="mt-4 flex items-center">
              <Typography
                className={cn(
                  'border border-current rounded-full ',
                  'w-6 h-6 flex items-center justify-center mr-3',
                )}
              >
                2
              </Typography>{' '}
              Click the <img src={pinIcon} className="w-4 h-4 ml-1.5" />
            </Typography>
            <Typography fontSize="14" className="mt-4 flex items-center">
              <Icon icon={faPartyHorn} className="w-4 h-4 ml-1 mr-4" />
              <Typography fontWeight={600}>Done!</Typography>
            </Typography>
          </div>
          <div className="flex-initial">
            <img src={chromeSteps} className="max-w-sm" />
          </div>
        </Typography>
        <CabalButton className="w-full" onClick={resolve}>
          Got it
        </CabalButton>
      </div>
    </Modal>
  )
}

export default ExtensionInstalledModal
