import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import api from 'utils/api'
import { GroupModel } from 'utils/types'

import { RootState } from '..'

export interface GroupsState {
  groups: Record<string, GroupModel>
  loading: Record<string, boolean>
}

const initialState: GroupsState = {
  groups: {},
  loading: {},
}

export const loadGroup = createAsyncThunk(
  'teams/fetchGroup',
  async (props: { uuid: string; team_slug: string }, thunk) => {
    thunk.dispatch(setLoadingGroups(props.uuid))

    const response = await api.getGroup(props.team_slug, props.uuid)
    return response.data.group
  },
)

export const groupsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setGroup: (state, action: PayloadAction<GroupModel>) => {
      state.groups[action.payload.uuid] = action.payload
    },
    setGroups: (state, action: PayloadAction<GroupModel[]>) => {
      for (const group of action.payload) {
        state.groups[group.uuid] = group
      }
    },
    resetGroups: (state, action: PayloadAction<GroupModel[]>) => {
      state.groups = {}
      for (const group of action.payload) {
        state.groups[group.uuid] = group
      }
    },
    setLoadingGroups: (state, action: PayloadAction<string>) => {
      state.loading[action.payload] = true
    },
    unsetLoadingGroups: (state, action: PayloadAction<string>) => {
      state.loading[action.payload] = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadGroup.fulfilled, (state, action) => {
      state.groups[action.payload.uuid] = action.payload

      delete state.loading[action.meta.arg.uuid]
    })

    builder.addCase(loadGroup.rejected, (state, action) => {
      delete state.loading[action.meta.arg.uuid]
    })
  },
})

export const { setGroup, setGroups, resetGroups, setLoadingGroups, unsetLoadingGroups } =
  groupsSlice.actions

const selectAllGroups = (state: RootState) => state.groups.groups
const selectGroupsLoading = (state: RootState) => state.groups.loading

export const selectLoadingGroup = (uuid: string) =>
  createSelector(selectGroupsLoading, (loading) => !!loading[uuid])

export const selectGroup = (uuid?: string) =>
  createSelector(selectAllGroups, (groups) =>
    uuid && Object.keys(groups).includes(uuid) ? groups[uuid] : null,
  )

export const selectGroups = (team_slug: string | undefined) =>
  createSelector(selectAllGroups, (groups) => {
    return Object.values(groups).filter((a) => a.team_slug === team_slug)
  })

export default groupsSlice.reducer
