import React, { useState } from 'react'

import { useMutation } from 'react-query'

import { STAGE_OPTIONS } from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import { MultiSelect, Select } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { GetInvestorTagsResponse } from 'utils/types/investor'

interface Props {
  onHide: () => void
  selectedCompaniesUuids: string[]
  ownedCompanyTags?: GetInvestorTagsResponse
  refetch: () => void
  header: string
  companyListUuid: string
  kind: string
}

const PortfolioListBulkActionModal: React.FC<Props> = ({
  onHide,
  selectedCompaniesUuids,
  ownedCompanyTags,
  header,
  companyListUuid,
  kind,
  refetch,
}) => {
  const [values, setValues] = useState<any>()
  const allTags = ownedCompanyTags?.tags

  const { mutate: bulkActions } = useMutation(
    () =>
      callApi(api.portfolioBulkActions, companyListUuid, {
        selected_companies_uuids: selectedCompaniesUuids,
        bulk_action: 'add',
        kind: kind,
        values: typeof values === 'string' ? [values] : values,
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Successfully updated!' })
        refetch()
        onHide()
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content: 'Something went wrong, please try again or contact support',
        })
      },
    },
  )

  return (
    <Modal
      show
      onHide={onHide}
      header={`Bulk Actions - ${header}`}
      rightActions={
        <CabalButton onClick={() => bulkActions()} disabled={!values}>
          Save
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        {kind === 'tags' && (
          <ModalInputWrapper>
            <ModalInputLabel>Select Tags</ModalInputLabel>

            <MultiSelect
              value={values}
              options={allTags?.map((tag) => ({ label: tag, value: tag })) ?? []}
              creatable
              onChange={setValues}
            />
          </ModalInputWrapper>
        )}
        {kind === 'stage' && (
          <ModalInputWrapper>
            <ModalInputLabel>Select Stage</ModalInputLabel>
            <Select value={values} options={STAGE_OPTIONS} onChange={setValues} />
          </ModalInputWrapper>
        )}
      </ModalSectionWrapper>
    </Modal>
  )
}

export default PortfolioListBulkActionModal
