import React from 'react'
import Typography from 'global/Typography'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useParams } from 'react-router-dom'
import { CompanySlugParam } from 'utils/types'
import axios from 'axios'
import { OnUpload, UploadButton } from 'global/Input'
import { Link } from 'react-router-dom'
import { CardHeader } from 'containers/Connections/styles'
import { useAccessControl } from 'global/AccessControl'
import CabalTitle from 'global/Title'

export const Container = styled.div`
  ${tw`py-10 px-4 rounded-lg text-center`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  color: ${({ theme }) => theme.colors.primary};
  box-shadow: ${({ theme }) => theme.card_shadow};
`

const StyledCardHeader = styled(CardHeader)`
  ${tw`px-4 py-2.5 rounded-none`};
  background-color: ${({ theme }) => theme.table.bg_color};
`

const Documents = () => {
  const { company_slug: companySlug } = useParams<CompanySlugParam>()
  const { canEditDocuments } = useAccessControl(companySlug)

  const [documents, setDocuments] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    loadDocuments()
  }, [])

  const onUpload: OnUpload = (uuid, url, file) => {
    const params = {
      team: companySlug,
      upload: { uuid, url, file },
    }

    axios.post('/api/documents', params).then((response) => {
      const { document } = response.data
      loadDocuments()
    })
  }

  function loadDocuments() {
    setLoading(true)
    axios
      .get(`/api/documents`, {
        params: { team_slug: companySlug },
      })
      .then((response) => {
        const { new_documents } = response.data
        setDocuments(new_documents)
        setLoading(false)
      })
  }

  return (
    <div className="mx-8 mt-4">
      <CabalTitle title={'Documents'} />
      <div className="mb-4 flex flex-row">
        <Typography fontWeight={700} customFontSize={'1.5rem'} className="w-full">
          Documents
        </Typography>
        <div className="items-end">
          <UploadButton
            allowedExtensions={['pdf']}
            onUpload={(uuid, url, file) => onUpload(uuid, url, file)}
            text="Upload"
            triggerProps={{
              variant: 'primary',
            }}
          />
        </div>
      </div>
      <div className="my-2 w-full">
        {documents.map((document) => (
          <StyledCardHeader key={document.uuid}>
            <Typography fontWeight={600} color="primary">
              <Link to={`/${companySlug}/documents/${document.uuid}`}>{document.name}</Link>
            </Typography>
          </StyledCardHeader>
        ))}
      </div>
      {loading == false && documents.length == 0 && (
        <div>
          <Container className="flex flex-col items-center">
            <Typography className="far fa-cabinet-filing" fontSize="24" color="fog" />

            <Typography className="block mt-4" fontWeight={700} fontSize="16">
              Document Sharing
            </Typography>

            <Typography className="mt-4 block text-center max-w-md" fontSize="14">
              Control access and monitor activity on your investor & LP updates, pitch decks and
              other sensitive documents
            </Typography>

            <div className="mt-6">
              <UploadButton
                allowedExtensions={['pdf']}
                onUpload={(uuid, url, file) => onUpload(uuid, url, file)}
                text="Upload"
                triggerProps={{
                  variant: 'primary',
                }}
              />
            </div>
          </Container>
        </div>
      )}
    </div>
  )
}

export default Documents
