import React, { useMemo } from 'react'

import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'

import { useComposer } from 'components/Composer'
import { ReviewSalesListHandler } from 'containers/IntroRequestModal/components/Modals/Sales/ReviewSalesListHandler'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import AddNoteModal from 'containers/RequestCenter/IntroRequestDetails/AddNoteModal'
import ReminderModal from 'containers/RequestCenter/IntroRequestDetails/ReminderModal'
import { IIntroRequest, IIntroStateParams, StatusType } from 'containers/RequestCenter/types/ITypes'
import {
  mostRecentActivity,
  requestCenterStatusFunc,
  statusIconMappingFunc,
} from 'containers/RequestCenter/utils'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { cn } from 'utils/styles'

export const useIntroRequestButton = (introRequestData: IIntroRequest, refetch: () => void) => {
  const { user } = useCurrentUser()
  const history = useHistory()
  const teamSlug = useTeamSlug()
  const { compose } = useComposer()
  const { showModal } = useModal()

  const isRequestor = user?.advisors
    ?.map((a) => a.uuid)
    .includes(introRequestData.requestor.uuid || '')

  const isFacilitator = user?.advisors
    ?.map((a) => a.uuid)
    .includes(introRequestData.facilitator_advisor_uuid)

  const { message, request_category, intro_request } = introRequestData

  const archiveIntroMutation = useMutation({
    mutationFn: () => callApi(api.archiveIntroRequestV2, intro_request.secret_uuid),
    onSuccess: () => {
      cabalToast({ style: 'success', content: 'Request has been archived.' })
      history.push(`/${teamSlug}/intro-center`)
    },
  })

  const renderReminderModal = (resolve: () => void) => (
    <ReminderModal
      onHide={() => {
        refetch()
        resolve()
      }}
      introRequestData={introRequestData}
    />
  )

  const renderAddNoteModal = (resolve: () => void) => (
    <AddNoteModal onHide={() => resolve()} introRequestData={introRequestData} refetch={refetch} />
  )
  const reviewSalesListHandler = () => {
    showModal(
      () => <ReviewSalesListHandler introRequestUuid={intro_request.secret_uuid} />,
      'review-sales-list-handler',
    )
  }

  const openRequestIntroModal = () => {
    callApi(api.getIntroRequestV2, intro_request.secret_uuid)
      .then((data) => {
        showModal(
          (resolve) => (
            <FacilitateIntroModal
              requestable={data.requestable}
              companyList={data.company_list}
              resolve={resolve}
              gatekeeperId={data.gatekeeper_id}
              facilitator={data.facilitator}
              introRequest={data}
              includeInitialNote={false}
            />
          ),
          'facilitate-intro-modal',
        )
      })
      .catch((error) => {
        console.log('Error', error)
      })
  }

  const getButtonText = () => {
    if (request_category === 'sales' || request_category === 'fundraising') {
      switch (intro_request.status) {
        case 'initiated':
          return isRequestor ? 'Review' : 'Remind'
        case 'requested':
          return 'Remind'
        case 'declined':
          return 'Archive'
        case 'offered':
          return 'View Draft'
        case 'sent':
          if (message) {
            return 'View Message'
          }
          return 'Add Note'
        default:
          return 'Add Note'
      }
    }

    if (
      request_category === 'talent' ||
      request_category === 'resources' ||
      request_category === 'portfolio'
    ) {
      if (intro_request.status === 'initiated') return isFacilitator ? 'Review' : 'Add Note'
      if (intro_request.status === 'requested') return isFacilitator ? 'Remind' : 'Add Note'
      if (intro_request.status === 'accepted') return isFacilitator ? 'Draft Intro' : 'View Draft'
      if (intro_request.status === 'sent') return 'View Message'
      if (intro_request.status === 'declined') return 'Archive'
      if (intro_request.status === 'sent') return 'View Message'
      return 'Add Note'
    }
  }

  const handleButtonClick = () => {
    if (request_category === 'sales' || request_category === 'fundraising') {
      switch (intro_request.status) {
        case 'initiated':
          return isRequestor
            ? reviewSalesListHandler
            : () => showModal(renderReminderModal, 'render_reminder_modal')
        case 'requested':
          return isRequestor
            ? () => showModal(renderReminderModal, 'render_reminder_modal')
            : () => showModal(renderReminderModal, 'render_reminder_modal')
        case 'declined':
          return () => archiveIntroMutation.mutate()
        case 'offered':
          return () => compose({ messageUuid: message?.uuid })
        case 'sent':
          if (message) {
            return () => history.push(`/messages/sent#${message?.uuid}`)
          }
          return () => showModal(renderAddNoteModal, 'render_note_modal')
        default:
          return () => showModal(renderAddNoteModal, 'render_note_modal')
      }

      // return () => archiveIntroMutation.mutate()
    }

    if (
      request_category === 'talent' ||
      request_category === 'resources' ||
      request_category === 'portfolio'
    ) {
      if (intro_request.status === 'initiated') {
        return isFacilitator
          ? () => openRequestIntroModal()
          : () => showModal(renderAddNoteModal, 'render_note_modal')
      }
      if (intro_request.status === 'requested') {
        return isFacilitator
          ? () => showModal(renderReminderModal, 'render_reminder_modal')
          : () => showModal(renderAddNoteModal, 'render_note_modal')
      }
      if (intro_request.status === 'declined') return () => archiveIntroMutation.mutate()
      if (intro_request.status === 'accepted') return () => compose({ messageUuid: message?.uuid })
      if (intro_request.status === 'sent')
        return () => history.push(`/messages/sent#${message?.uuid}`)
      return () => showModal(renderAddNoteModal, 'render_note_modal')
    }
  }

  return {
    onClick: handleButtonClick(),
    buttonText: getButtonText(),
  }
}

interface Props {
  introRequestDetails: IIntroRequest
  refetch: () => void
}

const Header: React.FC<Props> = ({ introRequestDetails, refetch }) => {
  const teamSlug = useTeamSlug()
  const history = useHistory()
  const team = useTeam(teamSlug)
  const { state } = useLocation<IIntroStateParams>()
  const { intro_request: introRequest } = introRequestDetails
  const { timeline_text_arr: timelineTextKeyTimestampArr } = introRequestDetails
  const statusIconMapping: StatusType = statusIconMappingFunc(team!)
  const requestCenterStatuses = requestCenterStatusFunc(team!)
  const activityToDisplay = mostRecentActivity(timelineTextKeyTimestampArr)
  const { onClick: onClickCTA, buttonText: ctaText } = useIntroRequestButton(
    introRequestDetails,
    refetch,
  )

  const backToResourceCenter = () => {
    history.push({
      pathname: `/${teamSlug}/intro-center`,
      state: {
        introPage: state?.introPage,
        introName: state?.introName,
        introStatus: state?.introStatus,
        initialRedirectFetch: !!state,
      },
    })
  }

  const menuItems = useMemo(
    () =>
      Object.entries(statusIconMapping)
        .filter(([key]) => requestCenterStatuses.includes(key))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(([_key, value]) => ({
          label: (
            <span
              className={`flex w-fit items-center gap-2 ${value.bg_color} ${value.text_color} px-2 py-0.5 rounded`}
            >
              <span className="text-xs">{value.label}</span>
            </span>
          ),
          onSelect: () => handleStatusSubmit(value.label),
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const handleStatusSubmit = (status: string) => {
    const statusKey = Object.keys(statusIconMapping).find(
      (key) => statusIconMapping[key].label === status,
    )

    updateStatusMutation.mutate(statusKey!)
  }

  const updateStatusMutation = useMutation(
    ['updateIntroRequestStatus', introRequest.secret_uuid],
    (status: string) => {
      return api.introRequestUpdate(introRequest.secret_uuid, teamSlug, undefined, status)
    },
    {
      onSuccess: () => {
        refetch()
      },
    },
  )

  const currentStatus = statusIconMapping[introRequest.status!]

  return (
    <div className="flex justify-between items-top mt-4">
      <div className={cn('flex ')}>
        <Typography
          color={'fog'}
          fontSize="16"
          className="mr-3 cursor-pointer"
          onClick={() => backToResourceCenter()}
          component="button"
        >
          <i className="far fa-chevron-left" />
        </Typography>

        <div className="flex flex-col ml-3">
          <div className="flex items-center gap-4">
            <div className="flex items-baseline gap-2">
              <Typography fontSize="24" fontWeight={600}>
                {introRequestDetails.requestor?.name}
              </Typography>
              <Typography fontSize="24">to</Typography>
              <Typography fontSize="24" fontWeight={600}>
                {introRequestDetails.target?.name}
              </Typography>
            </div>
            <DropDownMenu
              menuItems={menuItems}
              trigger={
                <div
                  className={`flex text-xs w-fit items-center gap-2 ${currentStatus.bg_color} ${currentStatus.text_color} px-2 py-0.5 rounded group`}
                >
                  {currentStatus.label}
                  <div className="hidden group-hover:block transition-all">
                    <i className="far fa-chevron-down fa-sm"></i>
                  </div>
                </div>
              }
            />
          </div>

          <div>
            <Typography fontSize="14" color="fog_rain">
              Facilitated by
              <Typography
                className="cursor-pointer ml-1"
                color="purple"
                onClick={() =>
                  history.push(
                    `/${teamSlug}/members/${introRequestDetails.facilitator_advisor_uuid}`,
                  )
                }
              >
                {introRequestDetails.facilitator?.name}
              </Typography>
              <span className="mx-1">·</span>
              Last updated {activityToDisplay?.timeAgo}
            </Typography>
          </div>
        </div>
      </div>
      <div className="mr-3">
        <CabalButton variant="primary" onClick={onClickCTA}>
          {ctaText}
        </CabalButton>
      </div>
    </div>
  )
}

export default Header
