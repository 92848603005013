import * as React from 'react'
import { useParams } from 'react-router-dom'

import { CompanySlugParam, ConnectionModel, MessageRecipient } from 'utils/types'
import Modal from 'global/Modal'
import SendMessage from 'components/SendMessage'

interface Props {
  show: boolean
  onHide: () => void
  onSubmit: () => void
  connection: ConnectionModel
}

const RequestIntroModal: React.VFC<Props> = ({ connection, show, onHide, onSubmit }) => {
  const { company_slug } = useParams<CompanySlugParam>()

  const handleSubmit = () => {
    onSubmit()
    onHide()
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" header={'New message'}>
      {connection && (
        <SendMessage
          title=""
          companySlug={company_slug}
          connection={connection}
          advisor={connection.advisor}
          defaultRecipients={[
            {
              type: 'advisor',
              value: connection.advisor.uuid,
              label: connection.advisor.name,
            },
          ]}
          showTemplatePills={false}
          defaultSubject={`Intro to ${connection.name}?`}
          defaultBody={`
          Hi ${connection.advisor.first_name}, I noticed you're connected to ${connection.linkedin_url ? 
            `<a href="${connection.linkedin_url}" target="_blank" rel="nofollow noopener">${connection.name}</a>` : connection.name}. Do you know them well
          enough to make an intro?
        `}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  )
}

export default RequestIntroModal
