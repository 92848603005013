import React, { KeyboardEvent } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import cx from 'classnames'

import Popover from 'components/Popover'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import DropDownMenu, { DropdownMenuItem } from 'global/DropDownMenu'
import CKEditor from 'global/TextEditor/ckeditor/v2'
import Typography from 'global/Typography'
import {
  DraftMessageBlueprint,
  MessagePersonalizationBlueprint,
  MessageRecipient,
  SenderModel,
  Team,
} from 'utils/types'
import RecipientsField, { RecipientsFieldProps } from './RecipientsField'
import SubjectField from './SubjectField'
import { Select } from 'global/Input'
import { ClassicCKEditor } from 'global/TextEditor/ckeditor/base'
import { ModalContextValue, withModalContext } from 'global/Modal/Context'
import EditAttachmentsModal from 'components/Attachments/EditAttachmentsModal'
import VersionHistoryModal from '../Sidebar/VersionHistoryModal'
import { ComposerStateContextValue } from '../Context'
import { omit, pick } from 'lodash'
import moment from 'moment'

const personalizationKeys: (keyof MessagePersonalizationBlueprint)[] = [
  'attachments',
  'bcc',
  'body',
  'cc',
  'recipient',
  'to',
  'subject',
]

const ComposerBaseWrapper = styled.div`
  ${tw`flex flex-col gap-1 p-1 overflow-visible h-full w-full`}
`

export interface ComposerBaseRef {
  ckeditor: ClassicCKEditor
}

export interface ComposerBaseProps {
  team: Team
  mailMerge?: boolean
  toFieldProps?: Partial<RecipientsFieldProps>
  ccFieldProps?: Partial<RecipientsFieldProps>
  bccFieldProps?: Partial<RecipientsFieldProps>
  recipients: MessageRecipient[]
  senders: SenderModel[]
  submitExtraMenuItems?: DropdownMenuItem[]
  onChange?: (change: Partial<DraftMessageBlueprint>) => Promise<void>
  className?: string
  renderSubmitButton: (hasExtraButtons: boolean) => React.ReactNode
  setMessage: ComposerStateContextValue['setComposerMessage']
  setPersonalization: ComposerStateContextValue['setPersonalization']
  message: DraftMessageBlueprint
  ckeditorRef: ComposerStateContextValue['ckeditorRef']
  currentPersonalization: ComposerStateContextValue['currentPersonalization']
}

export interface ComposerBaseState {
  render_index: number
  showCc: boolean
  showBcc: boolean
  ckeditorLoaded: boolean
}

type Props = ComposerBaseProps & {
  modalContext?: ModalContextValue
}

class ComposerBase extends React.Component<Props, ComposerBaseState> {
  state: ComposerBaseState
  mainCkeditor?: ClassicCKEditor
  personalizedCkeditor?: ClassicCKEditor

  static defaultProps: Partial<Props> = {
    mailMerge: true,
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      render_index: 0,
      showBcc: false,
      showCc: false,
      ckeditorLoaded: false,
    }
  }

  handleChange = (change: Partial<DraftMessageBlueprint>) => {
    let totalChange = { ...change }

    if (!!this.message.schedule_at) {
      if (moment(this.message.schedule_at).isBefore(moment())) {
        alert('Cannot make changes because the scheduled message is sent now!')
      }
    }

    if (this.isPersonalizing) {
      const concernedChange = pick(totalChange, personalizationKeys)
      this.props.setPersonalization(this.currentPersonalization!.uuid, concernedChange)

      totalChange = omit(totalChange, personalizationKeys)
    }

    if (!!Object.keys(totalChange).length) {
      this.props.setMessage(totalChange)
    }
  }

  openAttachmentsModal = () => {
    this.props.modalContext?.showModal?.(
      (resolve) => (
        <EditAttachmentsModal
          value={this.message!.attachments || []}
          onChange={(v) => {
            this.handleChange({ attachments: v })
            resolve()
            setTimeout(this.openAttachmentsModal)
          }}
          onHide={resolve}
        />
      ),
      'attachments-modal',
    )
  }

  openVersionHistoryModal = () => {
    this.props.modalContext?.showModal?.(
      (resolve) => (
        <VersionHistoryModal
          setBody={(v) => this.handleChange({ body: v })}
          onHide={resolve}
          message={this.message!}
          team={this.props.team}
        />
      ),
      'version-history-modal',
    )
  }

  handleExtraButtonsClick = (command: string, args?: any[]) => {
    switch (command) {
      case 'attachments':
        this.openAttachmentsModal()
        break
      case 'version-history':
        this.openVersionHistoryModal()
        break
    }
  }

  keyDownListener = (_evt: Event): any => {
    const evt = _evt as any as KeyboardEvent
  }

  componentDidMount() {
    window.addEventListener('keydown', this.keyDownListener, false)
  }

  componentDidUpdate() {
    if (!this.state.showCc && !!this.message?.cc?.length) {
      this.setState({ showCc: true })
    }

    if (!this.state.showBcc && !!this.message?.bcc?.length) {
      this.setState({ showBcc: true })
    }

    if (!this.isPersonalizing) {
      if (this.mainCkeditor && this.ckeditorRef.current !== this.mainCkeditor) {
        this.ckeditorRef.current = this.mainCkeditor
      }
    } else {
      if (this.personalizedCkeditor && this.ckeditorRef.current !== this.personalizedCkeditor) {
        this.ckeditorRef.current = this.personalizedCkeditor
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyDownListener)
  }

  setMainCkeditor = (ckeditor: ClassicCKEditor) => {
    this.mainCkeditor = ckeditor

    if (!this.isPersonalizing) {
      this.ckeditorRef.current = ckeditor
    }
  }

  setPersonalizedCkeditor = (ckeditor: ClassicCKEditor) => {
    this.personalizedCkeditor = ckeditor

    if (!!this.isPersonalizing) {
      this.ckeditorRef.current = ckeditor
    }
  }

  renderMainCkeditor() {
    return (
      <CKEditor
        onInit={(ckeditor) => {
          this.setMainCkeditor(ckeditor)

          this.setState({ ckeditorLoaded: true })
        }}
        toolbarLeftButtons={[
          {
            label: 'Add attachments',
            icon: `
              <?xml version="1.0" encoding="utf-8"?>
              <svg viewBox="0 0 512 512" width="512" height="512" xmlns="http://www.w3.org/2000/svg">
                <path d="M 48.389 435.592 C 2.542 389.738 2.533 315.514 48.389 269.652 L 226.238 91.781 C 261.524 56.493 318.935 56.493 354.219 91.781 C 389.584 127.15 389.592 184.405 354.219 219.781 L 204.466 369.554 C 179.64 394.383 139.244 394.382 114.419 369.555 C 89.538 344.672 89.529 304.384 114.419 279.494 L 241.248 152.647 C 244.953 148.942 250.959 148.942 254.663 152.647 L 268.074 166.061 C 271.777 169.765 271.777 175.769 268.074 179.473 L 141.241 306.321 C 131.184 316.379 131.179 332.664 141.241 342.726 C 151.276 352.761 167.606 352.763 177.642 342.725 L 327.395 192.953 C 347.936 172.411 347.941 139.158 327.396 118.611 C 306.9 98.113 273.555 98.114 253.06 118.611 L 75.212 296.482 C 44.189 327.507 44.182 377.731 75.211 408.764 C 106.262 439.819 156.531 439.816 187.576 408.742 C 239.177 357.091 290.788 305.45 342.409 253.818 C 346.113 250.113 352.117 250.115 355.821 253.821 L 369.232 267.235 C 372.935 270.938 372.938 276.94 369.235 280.644 C 317.617 332.273 266.01 383.911 214.411 435.559 C 168.541 481.475 94.267 481.476 48.389 435.592 Z" style=""/>
                ${
                  !!this.message.attachments?.length
                    ? `<text style="font-family: Arial, sans-serif; font-size: 270.7px; font-weight: 700; white-space: pre;" x="372.094" y="195.748">${this.message.attachments.length}</text>`
                    : ``
                }
              </svg>
            `,
            command: 'attachments',
          },
        ]}
        toolbarRightButtons={[
          {
            label: 'Version History',
            icon: `              
              <?xml version="1.0" encoding="utf-8"?>
              <svg viewBox="0 0 512 512" width="512" height="512" xmlns="http://www.w3.org/2000/svg">
                <path d="M 446.206 255.643 C 446.399 360.023 361.274 445.376 256.893 445.449 C 207.992 445.484 163.411 426.991 129.783 396.604 C 125.89 393.085 125.734 387.022 129.445 383.311 L 142.401 370.355 C 145.861 366.897 151.397 366.797 155.047 370.055 C 182.053 394.148 217.682 408.782 256.757 408.782 C 341.207 408.782 409.539 340.437 409.539 256 C 409.539 171.55 341.194 103.218 256.757 103.218 C 214.345 103.218 176.005 120.462 148.341 148.322 L 185.841 185.305 C 191.679 191.061 187.603 200.999 179.405 200.999 L 81.372 200.999 C 76.31 200.999 72.206 196.895 72.206 191.832 L 72.206 95.154 C 72.206 87.017 82.016 82.913 87.809 88.627 L 122.259 122.6 C 156.582 87.991 204.162 66.551 256.757 66.551 C 361.268 66.551 446.014 151.177 446.206 255.643 Z M 318.59 324.526 L 329.373 309.7 C 332.35 305.606 331.445 299.872 327.35 296.894 L 275.091 258.886 L 275.091 149.053 C 275.091 143.99 270.986 139.885 265.924 139.885 L 247.591 139.885 C 242.528 139.885 238.423 143.99 238.423 149.053 L 238.423 277.56 L 305.784 326.549 C 309.878 329.526 315.612 328.621 318.59 324.526 Z" style=""/>
              </svg>
            `,
            command: 'version-history',
          },
        ]}
        documentId={this.message.uuid}
        companySlug={this.props.team.slug}
        onToolbarButtonClick={this.handleExtraButtonsClick}
        value={this.message.body}
        onChange={(v) => !this.isPersonalizing && this.handleChange({ body: v })}
      />
    )
  }

  renderPersonalizationCkeditor() {
    return (
      <CKEditor
        onInit={this.setPersonalizedCkeditor}
        toolbarLeftButtons={[
          {
            label: 'Add attachments',
            icon: `
              <?xml version="1.0" encoding="utf-8"?>
              <svg viewBox="0 0 512 512" width="512" height="512" xmlns="http://www.w3.org/2000/svg">
                <path d="M 48.389 435.592 C 2.542 389.738 2.533 315.514 48.389 269.652 L 226.238 91.781 C 261.524 56.493 318.935 56.493 354.219 91.781 C 389.584 127.15 389.592 184.405 354.219 219.781 L 204.466 369.554 C 179.64 394.383 139.244 394.382 114.419 369.555 C 89.538 344.672 89.529 304.384 114.419 279.494 L 241.248 152.647 C 244.953 148.942 250.959 148.942 254.663 152.647 L 268.074 166.061 C 271.777 169.765 271.777 175.769 268.074 179.473 L 141.241 306.321 C 131.184 316.379 131.179 332.664 141.241 342.726 C 151.276 352.761 167.606 352.763 177.642 342.725 L 327.395 192.953 C 347.936 172.411 347.941 139.158 327.396 118.611 C 306.9 98.113 273.555 98.114 253.06 118.611 L 75.212 296.482 C 44.189 327.507 44.182 377.731 75.211 408.764 C 106.262 439.819 156.531 439.816 187.576 408.742 C 239.177 357.091 290.788 305.45 342.409 253.818 C 346.113 250.113 352.117 250.115 355.821 253.821 L 369.232 267.235 C 372.935 270.938 372.938 276.94 369.235 280.644 C 317.617 332.273 266.01 383.911 214.411 435.559 C 168.541 481.475 94.267 481.476 48.389 435.592 Z" style=""/>
                ${
                  !!this.message.attachments?.length
                    ? `<text style="font-family: Arial, sans-serif; font-size: 270.7px; font-weight: 700; white-space: pre;" x="372.094" y="195.748">${this.message.attachments.length}</text>`
                    : ``
                }
              </svg>
            `,
            command: 'attachments',
          },
        ]}
        companySlug={this.props.team.slug}
        onToolbarButtonClick={this.handleExtraButtonsClick}
        value={this.currentPersonalization?.body}
        onChange={(v) => this.isPersonalizing && this.handleChange({ body: v })}
      />
    )
  }

  render() {
    const { sender_uuid } = this.message
    const { subject, to, cc, bcc } = this
    const { showBcc, showCc } = this.state
    const { mailMerge, recipients, submitExtraMenuItems, senders, className, renderSubmitButton } =
      this.props
    const sender = senders.find((s) => s.uuid === sender_uuid)

    return (
      <ComposerBaseWrapper className={className}>
        <div className="flex justify-between py-0.5">
          <Popover
            trigger={
              <button className="inline-flex items-center">
                <Avatar
                  src={sender?.avatar_url}
                  name={sender?.name}
                  size={`30px`}
                  className="mr-2"
                />
                <Typography fontSize="12" color="primary">
                  {sender?.email}
                  <i className="far fa-chevron-down ml-2" />
                </Typography>
              </button>
            }
          >
            <Select<SenderModel>
              options={senders.map((sender) => ({
                label: `${sender.name} - ${sender.email}`,
                value: sender,
              }))}
              value={sender}
              onChange={(v) => v && this.handleChange({ sender_uuid: v.uuid, from: v.email })}
            />
          </Popover>
          <div className="flex">
            {renderSubmitButton(!!submitExtraMenuItems?.length)}
            {!!submitExtraMenuItems && (
              <DropDownMenu
                menuItems={submitExtraMenuItems}
                disabled={!submitExtraMenuItems.length}
                trigger={
                  <CabalButton
                    className="rounded-tl-none rounded-bl-none"
                    style={{ marginLeft: '1px', paddingLeft: '6px', paddingRight: '6px' }}
                    leftIcon={<i className="far fa-chevron-down" />}
                  />
                }
              />
            )}
          </div>
        </div>
        <RecipientsField
          {...this.props.toFieldProps}
          willMailMerge={mailMerge}
          options={recipients}
          selected={to}
          label="To"
          type="to"
          isFixedFunc={(r) => {
            if (!this.currentPersonalization) return false

            if (r.type === 'email') {
              return r.value.email === this.currentPersonalization?.recipient.uuid
            } else {
              return r.value === this.currentPersonalization?.recipient.uuid
            }
          }}
          rightActions={
            <>
              {!showCc && (
                <Typography
                  fontSize="13"
                  className="mr-2"
                  component="button"
                  onClick={() => {
                    this.setState({ showCc: true })
                  }}
                >
                  Cc
                </Typography>
              )}
              {!showBcc && (
                <Typography
                  fontSize="13"
                  className="mr-2"
                  component="button"
                  onClick={() => {
                    this.setState({ showBcc: true })
                  }}
                >
                  Bcc
                </Typography>
              )}
            </>
          }
          onSelect={(s) => s && this.handleChange({ to: s })}
        />
        {showCc && (
          <RecipientsField
            {...this.props.ccFieldProps}
            willMailMerge={mailMerge}
            options={recipients}
            selected={cc}
            label="Cc"
            type="cc"
            onSelect={(s) => s && this.handleChange({ cc: s })}
          />
        )}
        {showBcc && (
          <RecipientsField
            {...this.props.bccFieldProps}
            willMailMerge={mailMerge}
            options={recipients}
            selected={bcc}
            label="Bcc"
            type="bcc"
            onSelect={(s) => s && this.handleChange({ bcc: s })}
          />
        )}
        <SubjectField value={subject} onChange={(v) => this.handleChange({ subject: v })} />
        <div className={cx({ hidden: this.isPersonalizing }, 'flex h-full overflow-y-auto')}>
          {this.renderMainCkeditor()}
        </div>
        <div className={cx({ hidden: !this.isPersonalizing }, 'flex h-full overflow-y-auto')}>
          {this.renderPersonalizationCkeditor()}
        </div>
      </ComposerBaseWrapper>
    )
  }

  get message() {
    return this.props.message!
  }

  get ckeditorRef() {
    return this.props.ckeditorRef!
  }

  get isPersonalizing() {
    return !!this.props.currentPersonalization
  }

  get currentPersonalization() {
    return this.props.currentPersonalization
  }

  get to() {
    if (!!this.currentPersonalization) {
      return this.currentPersonalization.to
    }

    return this.message.to
  }

  get cc() {
    if (!!this.currentPersonalization) {
      return this.currentPersonalization.cc
    }

    return this.message.cc
  }

  get bcc() {
    if (!!this.currentPersonalization) {
      return this.currentPersonalization.bcc
    }

    return this.message.bcc
  }

  get subject() {
    if (!!this.currentPersonalization) {
      return this.currentPersonalization.subject
    }

    return this.message.subject
  }
}

export default withModalContext(ComposerBase)
