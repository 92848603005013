import styled from 'styled-components'
import tw from 'twin.macro'

const WidgetWrap = styled.div`
  ${tw`w-full overflow-hidden my-3`}
`
const AddButton = styled.div`
  color: ${({ theme }) => theme.colors.message_sender};
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 600;
  :hover {
    color: ${({ theme }) => theme.colors.gray};
  }
`
const CardBg = styled.div`
  background: ${({ theme }) => theme.colors.shoutout_bg};
  padding: 0.25rem 0.5rem;
  margin-top: 0.75rem;
  border-radius: 0.25rem;
`

export { AddButton, WidgetWrap, CardBg }
