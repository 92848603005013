import React, { useEffect, useState } from 'react'

import { useQuery } from 'react-query'
import { useMutation } from 'react-query'
import { useSearchParam } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useComposer } from 'components/Composer'
import GlobalForYouWidget from 'containers/Home/GlobalForYouWidget'
import { ReviewSalesListHandler } from 'containers/IntroRequestModal/components/Modals/Sales/ReviewSalesListHandler'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import GridWrapper from 'ui-components/GridWrapper'
import SkeletonDataCard from 'ui-components/SkeletonDataCard'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { Team } from 'utils/types'

import AddCompanyEmptyState from './AddCompanyEmptyState'
import CompanyInfo from './CompanyInfo'
import { IntroRequestConfirmation } from './IntroRequest/IntroRequestConfirmation'

export const StickySubHeader = styled(Typography)<{ sticky: boolean }>`
  ${tw`pb-3 flex`};
  ${({ sticky }) => (sticky ? 'position: sticky; top: 0; z-index: 1;' : '')}
  display: grid;
  grid-template-columns: 1fr auto;
`

const HomeArea = styled.div`
  ${tw`max-w-5xl pt-6 pb-8 flex flex-col gap-4`};
  width: 100%;
`

export const ShowAllLink = styled(Typography)`
  font-size: 14px;
  transition: text-decoration 200ms linear;
  &:hover {
    text-decoration: underline;
  }
`

const Home: React.FC = () => {
  const { compose } = useComposer()
  const { user } = useCurrentUser()
  const [teams, setTeams] = useState([])
  const [view, setView] = useState<'list' | 'grid'>('grid')
  const companyIndex = teams.findIndex((team: Team) => team.slug === user.team?.slug)

  const introRequestAction = useSearchParam('action')
  const introRequestUuid = useSearchParam('intro')

  const introRequestAcceptedOrDeclined =
    introRequestAction === 'decline' || introRequestAction === 'accept'
  const introRequestReview = introRequestAction === 'requested'
  const introRequestOffer = introRequestAction === 'offer'

  const { showModal, closeModal } = useModal()

  const { data: introRequestData } = useQuery(
    ['getIntroRequest', introRequestUuid],
    () => callApi(api.getIntroRequestV2, introRequestUuid),
    {
      enabled: !!introRequestUuid,
    },
  )

  const cleanUrl = () => {
    const currentUrl = window.location.href
    const baseUrl = currentUrl.split('?')[0]

    history.replaceState(null, '', baseUrl)
  }

  const { mutate: offerIntroRequestMutate } = useMutation({
    mutationFn: (uuid: string) => callApi(api.offerIntroRequestV2, uuid),
  })

  const sentEmail = () => {
    offerIntroRequestMutate(introRequestUuid)
  }

  useEffect(() => {
    if (introRequestOffer && introRequestData) {
      compose({
        messageUuid: introRequestData.message_uuid,
        team_slug: introRequestData.team_slug,
        introRequestFlow: false,
        onHide: () => {},
        onAfterDelete: () => {},
      }) // Closing the compose function
      cleanUrl() // Ensure cleanUrl is called after compose
      sentEmail()
    } // Closing the if statement

    if (introRequestData && introRequestReview) {
      showModal(
        (resolve) => (
          <FacilitateIntroModal
            requestable={introRequestData.requestable}
            companyList={introRequestData.company_list}
            resolve={resolve}
            gatekeeperId={introRequestData.gatekeeper_id}
            facilitator={introRequestData.facilitator}
            introRequest={introRequestData}
            includeInitialNote={false}
          />
        ),
        'facilitate-intro-modal',
      )

      cleanUrl()
    }
  }, [introRequestData, showModal])

  if (introRequestUuid && introRequestAcceptedOrDeclined) {
    showModal(
      (onHide) => (
        <IntroRequestConfirmation
          introRequestUuid={introRequestUuid}
          action={introRequestAction}
          onHide={onHide}
        />
      ),
      'intro-request-confirmation',
    )

    cleanUrl()
  }

  useEffect(() => {
    if (introRequestAction === 'initiated') {
      showModal(
        () => <ReviewSalesListHandler introRequestUuid={introRequestUuid} />,
        'review-sales-list-handler',
      )
    }
  }, [introRequestAction, introRequestUuid, showModal])

  const advisors = user.advisors
  const defaultCount = 16
  const [viewCount, showViewCount] = React.useState(defaultCount)

  const { isFetching: loadingTeams } = useQuery(['getHomeTeams'], () => callApi(api.getHomeTeams), {
    onSuccess: ({ teams }) => {
      setTeams(teams)
    },
  })

  const today = new Date()
  const curHr = today.getHours()
  const welcomeTimeText = (() => {
    if (curHr < 12) {
      return 'Good morning'
    } else if (curHr < 18) {
      return 'Good afternoon'
    } else {
      return 'Good evening'
    }
  })()

  const welcomeText = () => {
    if (user.first_name) {
      return `${welcomeTimeText}, ${user.first_name}`
    } else {
      return `${welcomeTimeText}, Welcome to Cabal`
    }
  }

  const portfolio_teams = teams
    .filter((team: Team) => team.slug !== user.team?.slug)
    .sort((a, b) => {
      return (
        (b.enable_asks ? b.published_item_count || 0 : 0) -
        (a.enable_asks ? a.published_item_count || 0 : 0)
      )
    })

  const orderedTeams = [
    teams[companyIndex],
    ...portfolio_teams,
    ...user.pending_join_requests.map((ujr) => {
      const team = ujr.team
      return {
        ...team,
        pending: true,
      }
    }),
  ].filter(Boolean)

  const yourTeam = orderedTeams.find(
    (team: Team) => team.user_role === 'member' || team.user_role === 'admin',
  )

  const filteredTeams = orderedTeams.filter(
    (team: Team) => team.user_role !== 'member' && team.user_role !== 'admin',
  )

  const CompanyInfoWrapper = (
    <>
      {orderedTeams.slice(0, viewCount).map((team) => (
        <CompanyInfo
          team={team}
          key={team.slug}
          pending={team.pending}
          advisor={advisors?.filter((a) => a.team_slug === team.slug)[0]}
          view={view}
        />
      ))}
    </>
  )

  return (
    <PageWrapper title="Home" showNav={true} hideTitle>
      <HomeArea>
        <div className="flex items-center justify-between pb-6 pt-4">
          <Typography fontSize="28" fontWeight={600} component="h1">
            {welcomeText()}
          </Typography>
          {/* <div>
            <UserDropDown />
          </div> */}
        </div>

        <GlobalForYouWidget />

        {/* <Widget title="Your Team">
              {yourTeam && <CompanyInfo team={yourTeam} pending={false} view="list" />}
              {!user.team && <AddCompanyEmptyState />}
            </Widget> */}

        <Widget
          title="Your Companies"
          cta={
            <CabalButton
              variant="link"
              onClick={() => setView(view === 'list' ? 'grid' : 'list')}
              leftIcon={
                view === 'list' ? <i className="far fa-grid" /> : <i className="far fa-equals" />
              }
            >
              {view === 'list' ? 'Grid ' : 'List'}
            </CabalButton>
          }
        >
          {!loadingTeams && (
            <div className="mb-4">
              {view === 'list' && <>{CompanyInfoWrapper}</>}
              {view === 'grid' && (
                <GridWrapper className="grid-cols-4">{CompanyInfoWrapper}</GridWrapper>
              )}
            </div>
          )}
          {loadingTeams && <SkeletonDataCard view={view} itemCount={defaultCount} />}

          {orderedTeams.length > defaultCount && (
            <div className="mt-4 flex items-center justify-center w-full">
              {viewCount === defaultCount ? (
                <CabalButton variant="link" onClick={() => showViewCount(orderedTeams.length)}>
                  See all ({orderedTeams.length})
                </CabalButton>
              ) : (
                <CabalButton variant="link" onClick={() => showViewCount(defaultCount)}>
                  View less
                </CabalButton>
              )}
            </div>
          )}
        </Widget>
      </HomeArea>
    </PageWrapper>
  )
}

export default Home
