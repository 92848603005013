import axios from 'axios'
import { FeedResponse } from 'utils/types/feed'

export default {
  getFeed: (params: { filter: string; hard_refresh?: boolean }) => {
    return axios.get<FeedResponse>('/api/feed', {
      params,
    })
  },

  createFeedCardAction: (props: {
    actionable_type: string
    actionable_id: string | number
    action: string
    feed_card_type: string
    team_slug?: string
  }) => {
    return axios.post('/api/feed/action', props)
  },

  deleteFeedCardAction: (props: {
    actionable_type: string
    actionable_id: string | number
    action: string
    feed_card_type: string
    team_slug?: string
  }) => {
    return axios.delete('/api/feed/delete_action', { data: props })
  },
}
