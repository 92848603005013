import React from 'react'

import compact from 'lodash/compact'
import pluralize from 'pluralize'
import { createGlobalState } from 'react-use'

import { useComposer } from 'components/Composer'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useAdvisors } from 'store/hooks'

import { formatList } from 'utils/array'
import { CompaniesFiltersType, PersonBlueprint } from 'utils/types'

const useSelectedGlobalPeople = createGlobalState<Record<string, PersonBlueprint | undefined>>({})

type Props = {
  filters?: CompaniesFiltersType
  teamSlug: string
}

export const useBulkIntroRequest = () => {
  const [selectedGlobalPeople, setSelectedGlobalPeople] = useSelectedGlobalPeople()

  const selectGlobalPerson = (globalPerson: PersonBlueprint) => {
    setSelectedGlobalPeople((prev) => ({
      ...prev,
      [globalPerson.id]: globalPerson,
    }))
  }

  const deselectGlobalPerson = (globalPerson: PersonBlueprint) => {
    setSelectedGlobalPeople((prev) => ({
      ...prev,
      [globalPerson.id]: undefined,
    }))
  }

  const isGlobalPersonSelected = (globalPerson: PersonBlueprint) => {
    return !!selectedGlobalPeople?.[globalPerson.id]
  }

  const clearSelectedGlobalPeople = () => {
    setSelectedGlobalPeople({})
  }

  return {
    selectGlobalPerson,
    deselectGlobalPerson,
    isGlobalPersonSelected,
    selectedGlobalPeople: compact(Object.values(selectedGlobalPeople)),
    clearSelectedGlobalPeople,
  }
}

const BulkIntroRequest: React.FC<Props> = ({ filters, teamSlug }) => {
  const { selectedGlobalPeople, clearSelectedGlobalPeople } = useBulkIntroRequest()
  const { compose } = useComposer()
  const { advisorsMap } = useAdvisors({ teamSlug })

  const singleFilteredAdvisor = React.useMemo(() => {
    if (filters?.advisor_uuids?.length === 1) return advisorsMap[filters.advisor_uuids[0]]
  }, [advisorsMap, filters?.advisor_uuids])

  const bulkIntroBody = React.useMemo(() => {
    const intro = `
      Hi ${singleFilteredAdvisor?.first_name || '{{first_name}}'},
      </br></br>
      I saw you are connected to the following people. Do you know them well enough to make an introduction?
      <ul>
    `
    const userStrings = selectedGlobalPeople.map((person) => {
      return `
        <li>
          ${
            person.linkedin_url
              ? `<a href="${person.linkedin_url}" target="_blank" rel="noreferrer">${person.name}</a>`
              : `${person.name}`
          }${person.headline ? `: ${person.headline}` : ''}
        </li>
          `
    })

    return intro + userStrings?.join('') + '</ul>'
  }, [selectedGlobalPeople, singleFilteredAdvisor?.first_name])

  if (!selectedGlobalPeople.length) {
    return <></>
  }

  return (
    <div className="flex">
      <CabalButton
        onClick={() =>
          compose({
            subject: 'Help with intros?',
            body: bulkIntroBody,
            recipients: {
              advisor_uuids: singleFilteredAdvisor ? [singleFilteredAdvisor.uuid] : undefined,
            },
          })
        }
        data-testid="bulk-intro-button"
        size="medium"
        className="whitespace-nowrap rounded-r-none"
        disabled={selectedGlobalPeople.length === 0}
        tooltip={
          <>
            <Typography fontSize="12">
              Request {pluralize('intro', selectedGlobalPeople.length)} to:
              <br />
              {formatList(selectedGlobalPeople.map((p) => p.name))}
            </Typography>
          </>
        }
      >
        Bulk Request ({selectedGlobalPeople.length})
      </CabalButton>
      <CabalButton
        leftIcon={<i className="far fa-align-slash" />}
        variant="secondary"
        className="rounded-l-none"
        padding="5px"
        tooltip={'Clear Selection'}
        onClick={clearSelectedGlobalPeople}
      />
    </div>
  )
}

export default BulkIntroRequest
