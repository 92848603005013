import React, { useEffect, useState } from 'react'

import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import Card from 'global/Card'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { IntroRequest } from 'utils/types/investor'

import CardLogo from './CardLogo'
import { Container } from './styles'

const RequestAccepted: React.VFC = () => {
  const companyUuid = useSearchParam('company_uuid')
  const advisorUuid = useSearchParam('advisor_uuid')
  const teamSlug = useSearchParam('team_slug') || ''
  const showErrorToast = useSearchParam('show_error_toast')
  const { secret_uuid: secretUuid } = useParams<{ secret_uuid: string }>()
  const [introRequest, setIntroRequest] = useState<Partial<IntroRequest>>({
    investor_company_logo: null,
    investor_company_name: '',
    job_title: null,
    talent_lead: null,
  })

  const { isLoading } = useQuery(
    ['getIntroRequest', secretUuid],
    () => callApi(api.getIntroRequest, secretUuid, companyUuid || ''),
    {
      onSuccess: (data) => {
        setIntroRequest(data)
      },
      enabled: !!companyUuid,
    },
  )

  const { isLoading: isLoadingAdvisorIntroRequest } = useQuery(
    ['getAdvisorIntroRequest', secretUuid],
    () => callApi(api.getAdvisorIntroRequest, secretUuid, advisorUuid || '', teamSlug),
    {
      onSuccess: (data) => {
        setIntroRequest(data)
      },
      enabled: !!advisorUuid,
    },
  )

  useEffect(() => {
    if (showErrorToast) {
      cabalToast({
        style: 'error',
        content: 'Unable to decline because request was already accepted!',
      })
    }
  }, [showErrorToast])

  const {
    accepted_at: acceptedAt,
    investor_company_logo: logo,
    investor_company_name: companyName,
    job_title: jobTitle,
    talent_lead: talentLead,
    candidate_name: candidateName,
    advisor_avatar: advisorAvatar,
    advisor_name: advisorName,
    hiring_contact_name: hiringContactName,
    accepted_or_declined_by_user: acceptedOrDeclineBy,
  } = introRequest

  if (isLoading || isLoadingAdvisorIntroRequest)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <Card className="p-10">
        {companyUuid ? (
          <CardLogo logo={logo || ''} companyName={companyName || ''} />
        ) : (
          <CardLogo logo={advisorAvatar || advisorName || ''} companyName={advisorName || ''} />
        )}
        {acceptedAt ? (
          <div className="mt-5">
            <Typography color="fog_rain" component="div">
              <i className="fas fa-check-circle fa-fw"></i> Request accepted!
            </Typography>
            <Typography
              component="p"
              fontSize="12"
              lineHeight="1.2"
              fontWeight={600}
              className="mb-6 mt-3"
            >
              {companyUuid
                ? `${
                    acceptedOrDeclineBy.name || acceptedOrDeclineBy.email
                  } accepted an intro request by ${candidateName}`
                : `${
                    acceptedOrDeclineBy.name || acceptedOrDeclineBy.email
                  } accepted an intro request by ${hiringContactName}`}
              {jobTitle && ` regarding the ${jobTitle} position`}
              {`.`}
            </Typography>
            <Typography color="fog_rain" component="div" fontSize="12" className="mt-1">
              {talentLead
                ? `${talentLead} will make an introduction shortly.`
                : `Introduction will be made shortly.`}
            </Typography>
          </div>
        ) : (
          <div className="mt-5">
            <Typography fontSize="20" color="fog_rain" className="mb-5">
              <i className="fas fa-envelope fa-fw"></i>
            </Typography>
            <Typography color="fog_rain" component="div">
              Please check your email to accept/decline the request.
            </Typography>
          </div>
        )}
      </Card>
    </Container>
  )
}

export default RequestAccepted
