import React, { useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import CommentsAndActivity from 'containers/ItemDetailPage/CommentsAndActivity'
import Connections from 'containers/ItemDetailPage/Connections'
import AboutSection from 'containers/PortfolioProfile/AboutSection'
import JobsSection from 'containers/PortfolioProfile/JobsSection'
import PortfolioFilters from 'containers/PortfolioProfile/PortfolioFilters'
import { useAccessControl } from 'global/AccessControl'
import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import { useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import useTeamSetting from 'utils/hooks/useTeamSetting'
import { CompaniesFiltersType } from 'utils/types'

import PortfolioHeader from './PortfolioHeader'

interface Props {
  investor_company_uuids: string
  filters?: CompaniesFiltersType
  setFilters: React.Dispatch<React.SetStateAction<CompaniesFiltersType | undefined>>
  blockItem: any
}

const PortfolioProfile: React.FC<Props> = ({
  investor_company_uuids,
  filters,
  setFilters,
  blockItem,
}) => {
  const teamSlug = useTeamSlug()
  const [parent] = useAutoAnimate()
  const { item_id, list_id } = useParams<{ item_id: string; list_id: string }>()
  const params = new URLSearchParams(window.location.search)
  const listUuid = params.get('list_uuid') || undefined
  const [filter, setFilter] = useState('')
  const [enablePortfolioJobs] = useTeamSetting(teamSlug, 'enable_portfolio_jobs')
  const { isAdminOrEmployee } = useAccessControl(teamSlug)

  const {
    data: portfolio,
    isLoading: isLoadingCompany,
    refetch: refetchPortfolio,
  } = useQuery(
    ['getInvestorCompany', investor_company_uuids],
    () => callApi(api.getInvestorCompany, investor_company_uuids, teamSlug),
    {
      enabled: !!investor_company_uuids,
    },
  )

  const { data, isLoading: isLoadingList } = useQuery(
    ['company_list', teamSlug, list_id],
    () => api.getCompanyList(teamSlug, list_id!, false),
    { enabled: !!list_id },
  )

  const hideConnections = data?.data.company_list.cta_type === 'request_intro' && !isAdminOrEmployee
  const portfolioCompany = portfolio?.company

  const viewAbout = filter === 'About' || filter === ''
  const viewConnections = !hideConnections && (filter === '' || filter === 'Connections')
  const viewJobs = enablePortfolioJobs && (filter === '' || filter === 'Jobs')

  if (!portfolioCompany) return null
  if (isLoadingCompany || isLoadingList) return <Loading />

  return (
    <PageWrapper title={portfolioCompany.company_name}>
      <PortfolioHeader
        company={portfolioCompany}
        companyList={data?.data.company_list}
        companyUuid={portfolioCompany.uuid}
        refetch={refetchPortfolio}
        teamSlug={teamSlug}
      />
      <PortfolioFilters
        filter={filter}
        onSetFilter={setFilter}
        teamSlug={teamSlug}
        companyUuid={portfolioCompany.uuid}
        refetch={refetchPortfolio}
        hideConnections={hideConnections}
        company={portfolioCompany}
        companyList={data?.data.company_list}
      />
      <div className="mt-10 flex gap-4">
        <div ref={parent} className="flex flex-col flex-1 gap-8 max-w-[630px]">
          {viewAbout && <AboutSection company={portfolioCompany} />}
          {viewJobs && (
            <JobsSection
              teamSlug={teamSlug}
              company={portfolioCompany}
              companyList={data?.data.company_list}
            />
          )}
          {viewConnections && (
            <Connections
              teamSlug={teamSlug}
              filters={filters}
              blockItem={blockItem}
              setFilters={setFilters}
              companyListName={portfolioCompany.company_name}
            />
          )}
        </div>
        <div className="w-80 flex-shrink-0">
          <CommentsAndActivity
            attachableId={item_id}
            teamSlug={teamSlug}
            attachableType={'Company'}
          />
        </div>
      </div>
    </PageWrapper>
  )
}

export default PortfolioProfile
