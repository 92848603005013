import React from 'react'

import _ from 'lodash'
import pluralize from 'pluralize'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ActivityWrapper, ContributionCounterIcon } from 'components/CompanyHome/styles'
import { useComposer } from 'components/Composer'
import { useComposeDealBoardDraft } from 'components/Composer/hooks'
import TimeAgo from 'components/TimeAgo'
import CabalButton from 'global/CabalButton'
import DropDownMenu, { DropdownMenuItem } from 'global/DropDownMenu'
import Typography from 'global/Typography'
import { DataCard } from 'ui-components/DataCard'
import Tags from 'ui-components/DataCard/Tags'

import { Activity, StyleProps, Team } from 'utils/types'
import { VoteBlueprint } from 'utils/types/vote'

interface Props extends StyleProps {
  activity: Activity
  profileView: boolean
  advisorView: boolean
  refetchActivities?: () => void
  team: Team
  compact: boolean
  splitView?: boolean
}

export const TruncateText = styled(Typography)<{ no_box: boolean }>`
  ${({ no_box }) => (no_box ? '' : 'display: -webkit-box;')}
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LikeActivity: React.VFC<Props> = ({
  team,
  activity,
  refetchActivities,
  profileView,
  advisorView,
  compact,
  splitView,
  ...restProps
}) => {
  const { activity_type, created_at, team_slug } = activity
  const object = activity.object as VoteBlueprint
  const user = activity.user!

  const { composeDealBoardDraft } = useComposeDealBoardDraft()
  const { compose } = useComposer()
  const history = useHistory()

  const { global_person, company, sent_messages, draft_messages, notes } = object

  if (!['connection_liked', 'company_liked'].includes(activity_type)) return <></>

  const shareDealBoardDraft = () => {
    composeDealBoardDraft({
      teamSlug: team.slug,
      person: global_person ? { id: global_person.id } : undefined,
      company_id: company?.id,
      user_uuid: user.uuid,
      onSubmit: (m) => m && refetchActivities?.(),
    })
    setTimeout(() => {
      refetchActivities?.()
    }, 5000)
  }

  let title: React.ReactNode = ''
  let name: React.ReactNode

  if (advisorView) {
    name = 'You'
  } else if (splitView) {
    name = user.name || user.email
  } else {
    if (!profileView && user.advisor_uuid) {
      name = (
        <Typography
          component="link"
          color={'link'}
          className="hover:underline"
          to={`/${team_slug}/members/${user.advisor_uuid || user.uuid}`}
        >
          {user.name || user.email}
        </Typography>
      )
    } else {
      name = user.name || user.email
    }
  }

  if (activity_type === 'connection_liked') {
    title = splitView ? (
      <>
        Offered help with: {_.compact([global_person?.name, global_person?.headline]).join(' - ')}
      </>
    ) : (
      <>
        {advisorView ? 'You' : name} offered help with:{' '}
        {_.compact([global_person?.name, global_person?.headline]).join(' - ')}
      </>
    )
  } else if (activity_type === 'company_liked') {
    title = splitView ? (
      <>Offered help with: {company?.name}</>
    ) : (
      <>
        {advisorView ? 'You' : name} offered help with: {company?.name}
      </>
    )
  }

  let desc = <TimeAgo datetime={created_at!} />

  if (compact) {
    desc = (
      <>
        {desc}
        <>&nbsp;·&nbsp;{team.name}</>
      </>
    )
  }

  let ctaText = advisorView ? 'Send Message' : 'Share draft'
  let onClick
  let menuItems: undefined | DropdownMenuItem[] = []
  let splitViewMenuItems: undefined | DropdownMenuItem[] = []

  if (!advisorView) {
    menuItems.push({
      label: 'Share draft',
      onSelect: shareDealBoardDraft,
    })
  }
  const sentMessage = _.last(sent_messages)
  const hasDraftMessages = !!draft_messages.length && !sentMessage

  if (sentMessage) {
    desc = (
      <div className="flex items-center">
        <Tags
          tags={[
            <Typography fontSize="11" key="sent-at">
              <span className="mr-2">Sent </span>
              <TimeAgo datetime={sentMessage.created_at} />
            </Typography>,
          ]}
        />
        <Typography className="mx-1">·</Typography>
        {desc}
        {notes && (
          <>
            <Typography className="mx-1">·</Typography>
            <TruncateText className="mx-1" no_box>
              {notes}
            </TruncateText>
          </>
        )}
      </div>
    )

    onClick = () => {
      history.push(`/${team.slug}/messages/${sentMessage.uuid}`)
    }
    ctaText = 'View message'
  } else if (hasDraftMessages) {
    const sharedWithSender = draft_messages?.some((m) => m.shared_with_sender)
    desc = (
      <div className="flex items-center">
        <Tags tags={[sharedWithSender ? 'Draft shared' : 'Drafted']} />
        <Typography className="mx-1">·</Typography>
        {desc}
        {notes && (
          <>
            <Typography className="mx-1">·</Typography>
            <TruncateText className="mx-1" no_box>
              {notes}
            </TruncateText>
          </>
        )}
      </div>
    )
    ctaText = `View draft`
  } else {
    onClick = shareDealBoardDraft
    menuItems = undefined
    if (notes) {
      desc = (
        <>
          {desc}
          <Typography className="mx-1">·</Typography>
          <TruncateText className="mx-1" no_box>
            {notes}
          </TruncateText>
        </>
      )
    }
  }

  let cta = (
    <CabalButton
      onClick={onClick}
      data-testid="like-activity-cta-button"
      variant="tertiary"
      padding="5px 2px"
    >
      <Typography color="purple" fontSize="12">
        {ctaText}
      </Typography>
    </CabalButton>
  )

  if (hasDraftMessages) {
    cta = (
      <DropDownMenu
        menuItems={draft_messages.map((message) => ({
          label: (
            <>
              {message.subject}
              {message.shared_with_sender && ' (shared)'}{' '}
              <Typography fontSize="12" className="ml-3">
                <TimeAgo datetime={message.created_at} />
              </Typography>
            </>
          ),
          onSelect: () => {
            compose({
              team_slug: team.slug,
              messageUuid: message.uuid,
              onSubmit: (m) => m && refetchActivities?.(),
            })
          },
        }))}
        trigger={
          splitView ? (
            <Typography color="fog">
              <i className="far fa-ellipsis-h" />
            </Typography>
          ) : (
            cta
          )
        }
      />
    )
  }

  if (splitView) {
    if (!!menuItems && menuItems.length > 0) {
      splitViewMenuItems.push(...menuItems)
    }

    if (!hasDraftMessages) {
      splitViewMenuItems.push({
        label: ctaText,
        onSelect: onClick,
      })
    }
  }

  return (
    <>
      {splitView && (
        <DataCard
          className="h-auto min-h-[50px] py-2"
          title={
            <>
              <Typography fontSize="12">
                <Typography fontWeight={600}>{name}</Typography>{' '}
                <Typography color="fog_rain">
                  <TimeAgo datetime={created_at} short />
                </Typography>
              </Typography>
            </>
          }
          avatarUrl={user.avatar_url}
          avatarName={user.name}
          avatarAlign={'top'}
          cta={hasDraftMessages ? cta : undefined}
          menuItems={
            !!splitViewMenuItems?.length && !hasDraftMessages ? splitViewMenuItems : undefined
          }
          description={
            <TruncateText color="primary" component="div" className="ql-output">
              {title}
            </TruncateText>
          }
        />
      )}
      {compact && (
        <ActivityWrapper>
          <div className="flex">
            <Typography fontSize="12" color="fog_rain" className="mr-2 flex-shrink-0">
              <ContributionCounterIcon className="fa fa-trophy mr-1"></ContributionCounterIcon>
              {'1'}
            </Typography>

            <Typography className="truncate" fontSize="12">
              {title}
            </Typography>
          </div>
        </ActivityWrapper>
      )}
      {!splitView && !compact && (
        <DataCard
          avatarUrl={compact ? team.logo : user.avatar_url}
          avatarName={compact ? team.name : user.name}
          activityTitle={title}
          description={desc}
          cta={!profileView ? cta : undefined}
          menuItems={!!menuItems?.length ? menuItems : undefined}
          {...restProps}
        />
      )}
    </>
  )
}

export default LikeActivity
