import React from 'react'

import { useHistory } from 'react-router-dom'

import JobsDataTags from 'containers/CompanyPage/SharedListsSection/JobsDataTags'
import { useJobsListContext } from 'containers/ListIndexView/TalentList/JobsList'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import GridWrapper from 'ui-components/GridWrapper'

import { cn } from 'utils/styles'
import { CompanyListItemBlueprint } from 'utils/types'

const JobItem = ({
  listItem,
  view,
}: {
  listItem: CompanyListItemBlueprint
  view: 'grid' | 'list'
}) => {
  const teamSlug = useTeamSlug()
  const job = listItem.job

  const { companyListData: companyList } = useJobsListContext()

  const { showModal } = useModal()

  const openRequestIntroModal = () => {
    showModal(
      (resolve) => (
        <FacilitateIntroModal
          requestable={job}
          companyList={companyList}
          resolve={resolve}
          gatekeeperId={companyList?.gatekeeper_id}
          facilitator={companyList?.owning_team}
          includeInitialNote={false}
          networkingOnly={false}
        />
      ),
      'facilitate-intro-modal',
    )
  }

  const gridView = view === 'grid'

  const history = useHistory()

  return (
    <DataCardV2
      onClick={() => history.push(`/${teamSlug}/lists/${companyList.slug}/${listItem.uuid}`)}
      key={job.uuid}
      title={job.title}
      avatar={
        <Avatar
          src={
            job.investor_company
              ? `https://logo.clearbit.com/${job.investor_company.domain}`
              : undefined
          }
          name={job.title}
          size={gridView ? '72' : '32'}
        />
      }
      description={
        (!gridView &&
          job.description &&
          new DOMParser().parseFromString(job.description, 'text/html').body.textContent) ||
        ''
      }
      descriptionWithoutTruncate={
        <p className="line-clamp-3 mb-2">
          {(job.description &&
            new DOMParser().parseFromString(job.description, 'text/html').body.textContent) ||
            ''}
        </p>
      }
      cta={
        <CabalButton
          onClick={() => openRequestIntroModal()}
          variant={gridView ? 'primary' : 'link'}
          className="w-full"
        >
          Request Intro
        </CabalButton>
      }
      view={view}
      gridPillsSection={<JobsDataTags job={job} />}
      className={cn({
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded':
          !gridView,
      })}
    />
  )
}

const JobsListMemberView = () => {
  const { listItems, view } = useJobsListContext()

  const jobRow = (view: 'grid' | 'list') => {
    return (
      <>
        {listItems.map((listItem) => (
          <JobItem key={listItem.job.uuid} listItem={listItem} view={view} />
        ))}
      </>
    )
  }

  return (
    <div>
      {view === 'grid' && <GridWrapper>{jobRow('grid')}</GridWrapper>}
      {view === 'list' && <>{jobRow('list')}</>}
    </div>
  )
}

export default JobsListMemberView
