import * as React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { Select, TextInput } from 'global/Input'
import api from 'utils/api'
import CabalButton from 'global/CabalButton'
import { useQueryClient } from 'react-query'
import { cabalToast } from 'ui-components/Toast'

const StyledTextInput = styled(TextInput)`
  ${tw`w-full mb-2`}
`

interface Props {
  show: boolean
  onHide: () => void
  teamSlug: string
  reload?: () => void
}

const InviteTeammateModal: React.FC<Props> = ({ show, onHide, teamSlug, reload }) => {
  const [working, setWorking] = React.useState<boolean>(false)
  const [validations, setValidations] = React.useState({
    email: false,
    first_name: false,
    last_name: false,
  })
  const [user, setUser] = React.useState({
    email: '',
    first_name: '',
    last_name: '',
    title: '',
    team_role: 'member',
  })
  const queryClient = useQueryClient()

  const onSave = () => {
    setWorking(true)
    api
      .createTeamUser(teamSlug, { user })
      .then((response) => {
        queryClient.setQueriesData('getTeamUsers', response.data)
        cabalToast({ style: 'success', content: 'Teammate saved and invite sent' })
        setUser({
          email: '',
          first_name: '',
          last_name: '',
          title: '',
          team_role: 'member',
        })
        setValidations({
          email: false,
          first_name: false,
          last_name: false,
        })
        onHide()
        reload && reload()
      })
      .catch((e) => {
        if (e.response?.status === 400) {
          if (e.response?.data?.is_already_admin) {
            cabalToast({ style: 'error', content: `${user.email} is already a team member` })
          } else if (e.response?.data?.has_team) {
            cabalToast({
              style: 'error',
              content: `${user.email} is a team member of another team. Please contact support.`,
            })
          }
        }
      })
      .finally(() => setWorking(false))
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      header="Invite a teammate"
      rightActions={
        <CabalButton
          className="mt-2 float-right"
          variant="primary"
          working={working}
          disabled={!validations.email || !validations.first_name || !validations.last_name}
          onClick={onSave}
          data-testid="invite-employee"
        >
          Invite
        </CabalButton>
      }
    >
      <div className="flex mt-4">
        <div className="flex-1">
          <Typography
            fontSize="14"
            className="mb-2"
            component="h4"
            fontWeight={600}
            color="text_secondary"
          >
            Personal Info
          </Typography>
          <StyledTextInput
            placeholder="Primary email"
            value={user.email}
            valueType="email"
            onChange={(e, valid) => {
              setUser({ ...user, email: e.target.value })
              setValidations({ ...validations, email: !!valid })
            }}
            data-testid="primary-email"
            autoFocus
            required
          />
          <StyledTextInput
            placeholder="First Name"
            value={user.first_name}
            valueType="non-empty"
            onChange={(e, valid) => {
              setUser({ ...user, first_name: e.target.value })
              setValidations({ ...validations, first_name: !!valid })
            }}
            data-testid="first-name"
            required
          />
          <StyledTextInput
            placeholder="Last Name"
            value={user.last_name}
            valueType="non-empty"
            onChange={(e, valid) => {
              setUser({ ...user, last_name: e.target.value })
              setValidations({ ...validations, last_name: !!valid })
            }}
            data-testid="last-name"
            required
          />
          <Select
            className="mb-2"
            value={user.team_role}
            onChange={(option) => option && setUser({ ...user, team_role: option })}
            placeholder="Permission level"
            options={[
              { label: 'Admin', value: 'admin' },
              { label: 'Employee', value: 'member' },
            ]}
          />
          {/* <StyledTextInput
            placeholder="Title"
            value={user.title}
            onChange={(e) => {
              setUser({ ...user, title: e.target.value })
            }}
          /> */}
        </div>
      </div>
    </Modal>
  )
}

export default InviteTeammateModal
