import * as React from 'react'
import cx from 'classnames'

import { CheckBox, CheckBoxProps } from './Checkbox'
import Loading from 'global/Loading'
import CabalButton from 'global/CabalButton'

export interface ChecklistItem {
  label: string | React.ReactNode
  value: string
  checked: boolean
  item?: any
}

export interface ChecklistProps {
  label?: string | React.ReactNode
  isLoading?: boolean
  items: ChecklistItem[]
  onChange: (items: ChecklistItem[]) => void
  checkboxProps?: CheckBoxProps
  className?: string
  onDirectAdd?: (item: ChecklistItem[]) => void
  maxRenderCount?: number
}

export const Checklist: React.VFC<ChecklistProps> = ({
  label,
  items,
  onChange,
  checkboxProps,
  className,
  isLoading,
  onDirectAdd,
  maxRenderCount,
}) => {
  const handleChange = (checked: boolean, value: string) => {
    const updatedItems = items.map((item) => {
      if (item.value === value) {
        return { ...item, checked }
      }
      return { ...item }
    })

    onChange(updatedItems)
  }

  const handleAdd = (value: string) => {
    if (onDirectAdd) {
      const selectedItem = items.map((item) => {
        if (item.value === value) {
          return { ...item, checked: true }
        }
        return { ...item }
      })

      onDirectAdd(selectedItem)
    }
  }

  if (maxRenderCount) items = items.slice(0, maxRenderCount)

  if (isLoading) return <Loading className="py-6" />

  return (
    <div className={className}>
      {/* {label} */}
      <div>
        {items.map(({ label, value, checked }) => (
          <div key={value} className="flex items-center justify-between">
            <CheckBox
              checked={checked}
              onChange={(e) => handleChange(e.target.checked, value)}
              label={label}
              data-testid={`composer-${value}-checkbox`}
              {...checkboxProps}
            />
            {onDirectAdd && (
              <CabalButton
                variant="tertiary"
                size="small"
                rightIcon={<i className="far fa-plus"></i>}
                onClick={() => handleAdd(value)}
                data-testid={`composer-${value}-plus-btn`}
              ></CabalButton>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
