import * as React from 'react'

import EmptyState from 'global/EmptyState'
import Typography from 'global/Typography'

const NoResults: React.FC = () => {
  return (
    <EmptyState
      heading="Member not found"
      image={
        <Typography fontSize="24" color="fog">
          <i className="far fa-search"></i>
        </Typography>
      }
      body={
        <Typography textAlign="center" component="h5">
          We couldn't find any members matching your search.
        </Typography>
      }
    />
  )
}

export default NoResults
