import * as React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import { UploadButton } from 'global/Input'
import Typography from 'global/Typography'

const Border = styled.div`
  ${tw`py-4 flex justify-center`}
  border: ${({ theme }) => theme.border};
`
const Signature = ({ signature, loadInfo }: any) => {
  return (
    <>
      {signature ? (
        <div className="flex justify-between pb-4">
          <UploadButton
            canDrawSignature
            canUpload={false}
            onUpload={loadInfo}
            attachTo={'user_signature'}
            showPencil={true}
          />
        </div>
      ) : null}
      {signature ? (
        <Border>
          <div>
            {!!signature && (
              <img
                width={'300px'}
                height={'100px'}
                src={`/api/uploads/${signature}`}
                alt="Users signature"
              />
            )}
          </div>
        </Border>
      ) : (
        <div className="flex items-center flex-col">
          <Typography className={'pb-2'} color={'gray'} fontSize="12">
            {'Draw a signature for your documents'}
          </Typography>
          {/* <CabalButton variant="secondary" onClick={() => null}>
            <Typography>{'Draw my signature'}</Typography>
          </CabalButton> */}
          <UploadButton
            canDrawSignature
            canUpload={false}
            onUpload={loadInfo}
            attachTo={'user_signature'}
          />
        </div>
      )}
    </>
  )
}

export default Signature
