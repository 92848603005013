import { RenderModal, useModal } from 'global/Modal/Context'
import * as React from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import SearchModal from './SearchModal'
import ShortcutsModal from './ShortcutsModal'

const Shortcuts = () => {
  const { showModal } = useModal()

  const renderShortcutsModal: RenderModal = (resolve) => (
    <ShortcutsModal show onHide={() => resolve(false)} />
  )

  const renderSearchModal: RenderModal = (resolve) => (
    <SearchModal show onHide={() => resolve(false)} />
  )

  const keyMap = {
    SHORTCUTS: ['command+/'],
    SEARCH: ['cmd+k'],
  }

  const handlers = {
    SHORTCUTS: (e: React.KeyboardEvent) => {
      showModal(renderShortcutsModal, 'render_shortcuts_modal')
    },
    SEARCH: (e: React.KeyboardEvent) => {
      console.log('Hit search')
      showModal(renderSearchModal, 'render_shortcuts_modal')
    },
  }
  return <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
}

export default Shortcuts
