import React, { useEffect, useMemo, useState } from 'react'

import { useComposer } from 'components/Composer'
import { useComposeDealBoardDraft } from 'components/Composer/hooks'
import { Divider } from 'components/SendMessage/styles'
import Modal from 'global/Modal'
import { ModalSectionWrapper } from 'global/Modal/styles'
import { useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { AdvisorModel, CompanyBlueprint, PersonBlueprint, UserBlueprint } from 'utils/types'

import SelectAdvisors from './SelectAdvisors'
import SelectIntroType from './SelectIntroType'

interface Props {
  onHide?: () => void
  teamSlug: string
  person: PersonBlueprint
  advisors: AdvisorModel[]
  requestable?: Pick<CompanyBlueprint, 'id' | 'uuid'>
  modal?: boolean
}

export type IntroType = 'connection_check' | 'draft_on_behalf' | 'forwardable_email' | undefined

const GetIntroModal: React.FC<Props> = ({
  onHide = () => {},
  person,
  advisors,
  teamSlug,
  requestable,
  modal = true,
}) => {
  const { team } = useTeam(teamSlug)
  const { composeDealBoardDraft } = useComposeDealBoardDraft()
  const { compose } = useComposer(teamSlug)

  const [selectedAdvisorUuids, setSelectedAdvisorUuids] = useState<string[]>([])
  const selectedAdvisors = advisors.filter((user) => selectedAdvisorUuids.includes(user.uuid))

  const [creatingDraft, setCreatingDraft] = useState(false)

  const connectionCheckMsg = () => {
    setCreatingDraft(true)
    callApi(api.getDefaultTemplate, teamSlug, 'intro_template')
      .then((resp) => {
        const template = resp.template

        const recipientAdvisors = advisors
          .filter((advisor) => selectedAdvisorUuids.includes(advisor.uuid))
          .map((advisor) => {
            return { type: 'advisor', value: advisor.uuid, label: advisor.name }
          })

        callApi(api.createRequest, teamSlug, {
          company_id: requestable?.id,
          request_type: 'connection_intro_queue',
          global_person_id: person.id || person.uuid,
          data: {
            subject: template.subject,
            body: template.body,
            req_intros_to: recipientAdvisors,
          },
        })
          .then((request) => {
            compose({ messageUuid: request.message_uuid })
          })
          .finally(() => {
            setCreatingDraft(false)
            onHide()
          })
      })
      .catch(() => {
        setCreatingDraft(false)
        onHide()
      })
  }

  const draftOnBehalfMsg = () => {
    composeDealBoardDraft({
      user_uuid: selectedAdvisors[0].user_uuid || selectedAdvisors[0].uuid,
      company_id: requestable?.id,
      teamSlug: teamSlug,
      person: person,
      recipients: {
        emails: [],
      },
    })
    onHide()
  }

  const forwardableEmailMsg = () => {
    const sub = `${person.name} + ${team?.name}`
    const msg = `
      Hi {{first_name}}, <br><br>
      I'm hoping to connect with ${person.name}. Would you mind forwarding this message to ${person.first_name}.
      I've included more details on ${team?.name} below. <br><br>

      -------------------------- <br><br>
      {ENTER COMPANY BLURB HERE}
    `

    compose({
      recipients: {
        advisor_uuids: selectedAdvisorUuids,
      },

      subject: sub,
      body: msg,
    })
  }

  useEffect(() => {
    if (advisors.length === 1) {
      setSelectedAdvisorUuids([advisors[0].uuid])
    }
  }, [advisors])

  if (!team) return <></>

  const contents = (
    <>
      <SelectAdvisors
        teamSlug={team?.slug}
        teamName={team?.name}
        personName={person.first_name}
        advisors={advisors}
        selectedAdvisorUuids={selectedAdvisorUuids}
        onChangeAdvisorUuids={(uuids) => setSelectedAdvisorUuids(uuids)}
      />
      {selectedAdvisorUuids.length > 0 && (
        <>
          <div>
            <Divider />
          </div>
          <SelectIntroType
            personName={person.full_name}
            selectedUserCount={selectedAdvisors.length}
            checkConnections={() => connectionCheckMsg()}
            draftOnBehalf={() => draftOnBehalfMsg()}
            creatingConnectionDraft={creatingDraft}
          />
        </>
      )}
    </>
  )

  if (modal) {
    return (
      <Modal header={`Request intro to ${person.full_name}`} show={true} onHide={onHide}>
        <ModalSectionWrapper>{contents}</ModalSectionWrapper>
      </Modal>
    )
  } else {
    return contents
  }
}

export default GetIntroModal
