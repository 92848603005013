import React from 'react'
import { cabalToast } from 'ui-components/Toast'
import { useMount, useSearchParam } from 'react-use'

import { useCurrentUser } from 'store/hooks'

import IntegrationCard from './IntegrationCard'
import microsoftIcon from '../../../../assets/images/microsoft-200.png'
import { DataCard } from 'ui-components/DataCard'

const MicrosoftIntegrationCard: React.FC = () => {
  const { user: currentUser } = useCurrentUser()
  const provider = useSearchParam('provider')
  const success = useSearchParam('auth_success')
  const failure = useSearchParam('auth_failed')

  useMount(() => {
    if (provider === 'microsoft') {
      if (success) {
        cabalToast({
          style: 'success',
          content: 'Successfully connected Microsoft to your account.',
        })
      } else if (failure) {
        cabalToast({ style: 'error', content: failure })
      }
    }
  })

  return (
    <>
      <DataCard
        title="Microsoft"
        description={'Send messages directly from your Microsoft account.'}
        avatarUrl={microsoftIcon}
        secondaryCTA={{
          text: currentUser.connected_microsoft ? 'Reauthorize' : 'Authorize',
          onClick: () => {
            const url = new URL(`${window.location.origin}/auth/microsoft`)
            url.searchParams.append('return_to', window.location.pathname)
            window.location.href = url.toString()
          },
        }}
      />
    </>
  )
}

export default MicrosoftIntegrationCard
