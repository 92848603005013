import React, { useEffect, useState } from 'react'
import { Checklist, ChecklistItem } from 'global/Input/Checklist'
import { Container } from '../styles'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import EmptyState from 'global/EmptyState'
import { AdvisorModel } from 'utils/types'
import { useMutation } from 'react-query'
import api, { callApi } from 'utils/api'

interface Props {
  onInsertMember: (items: ChecklistItem[]) => void
}

const getAdvisorLabel = (a: AdvisorModel) => {
  return (
    <div className="flex items-center my-1">
      <Avatar name={a.name} src={a.avatar_url} round size="20" className="mr-2" />
      <Typography fontSize="12">{a.name}</Typography>
    </div>
  )
}

const getItems = (advisors: AdvisorModel[]) => {
  return (
    advisors.map((a: AdvisorModel) => ({
      label: getAdvisorLabel(a),
      value: a.uuid,
      checked: false,
    })) || []
  )
}

const MembersTab: React.VFC<Props> = ({ onInsertMember }) => {
  const [query, setQuery] = useState('')
  const [advisors, setAdvisors] = useState([])

  const [items, setItems] = useState<ChecklistItem[]>(getItems(advisors || []))

  const { mutate: getAdvisorsV2, isLoading } = useMutation(
    ({ query }: { query: string }) => callApi(api.getAdvisorsV2, query),
    {
      onSuccess: ({ advisors }) => {
        setAdvisors(advisors || [])
        setItems(getItems(advisors || []))
      },
    },
  )

  useEffect(() => {
    const lowercaseQuery = query.toLowerCase()

    if (lowercaseQuery.length > 1) {
      getAdvisorsV2({ query: lowercaseQuery })
    }
  }, [query])

  const handleInsert = () => {
    onInsertMember(items)
    const updatedItems = items.map((item) => {
      return { ...item, checked: false }
    })
    setItems(updatedItems)
  }

  const disabled = !items.find((i) => i.checked)

  return (
    <div className="px-1">
      <div className="mt-1">
        <SearchInput
          onChange={(e) => setQuery(e.target.value)}
          defaultValue={query}
          value={query}
          lightBg
          placeholder="Search members"
          maxLength={20}
          fontSize="12px"
          data-testid="composer-members-search-input"
        />
      </div>
      <Container className="absolute-custom-scrollbar">
        <Checklist
          className="my-1"
          items={items}
          onChange={setItems}
          maxRenderCount={25}
          isLoading={isLoading}
        />
      </Container>
      {!isLoading && advisors?.length === 0 && query.length > 1 && (
        <div className="mt-5" data-testid="composer-members-empty-state">
          <EmptyState body="No members found" />
        </div>
      )}
      <div>
        <CabalButton
          variant="primary"
          size="small"
          disabled={disabled}
          className="mr-2 mt-3"
          onClick={handleInsert}
          data-testid="composer-insert-member-btn"
        >
          Insert
        </CabalButton>
      </div>
    </div>
  )
}

export default React.memo(MembersTab)
