import React from 'react'
import { MessageThread as MessageThreadType } from 'utils/types/message'
import MessageContent from '../MessageContent'

import { MessageContainer } from '../styles'

interface Props {
  messageThread: MessageThreadType
  onSetMessageThread: (id: string) => void
}

const ThreadStarter: React.FC<Props> = ({ messageThread, onSetMessageThread }) => {
  const firstReplyMessage = messageThread.reply_messages[0]
  const lastReplyMessage = messageThread.reply_messages[messageThread.reply_messages.length - 1]

  return (
    <div className="cursor-pointer" onClick={() => onSetMessageThread(messageThread.uuid)}>
      {firstReplyMessage && (
        <MessageContainer>
          <MessageContent
            message={firstReplyMessage}
            snippet={`${lastReplyMessage.sender.name}: ${lastReplyMessage.snippet}`}
            replyCount={messageThread.reply_messages.length}
            date={messageThread.last_reply_at}
            isUnread={messageThread.is_unread}
            user={messageThread.recipient}
          />
        </MessageContainer>
      )}
    </div>
  )
}

export default ThreadStarter
