import Typography from 'global/Typography'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

interface CardProps {
  icon: React.ReactNode
  title: string
  description: string
  onClick: () => void
}

const Wrapper = styled.div`
  ${tw`flex flex-col justify-between rounded-lg p-4 cursor-pointer`}
  background-color: ${({ theme }) => theme.layout.main_bg_color};
  border: 2px solid ${({ theme }) => theme.layout.main_bg_color};

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.purple};
  }
`

const ListCard: React.VFC<CardProps> = ({ icon, title, description, cta, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      {icon}
      <div className="pt-20">
        <Typography fontWeight={600} lineHeight={1} fontSize="14" className="pb-1" component="p">
          {title}
        </Typography>
        <Typography fontSize="12" color={'fog'} component="p" lineHeight={1.4}>
          {description}
        </Typography>
      </div>
    </Wrapper>
  )
}

export default ListCard
