import * as React from 'react'
import { WidgetWrap } from 'containers/WidgetSidebar/styles'
import Typography from 'global/Typography'
import api, { callApi } from 'utils/api'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import Loading from 'global/Loading'
import { RenderModal, useModal } from 'global/Modal/Context'
import StatsModal from './StatsModal'
import CabalMessage from 'models/CabalMessage'
import { data } from 'msw/lib/types/context'
import message from 'utils/api/message'
import Expand from 'react-expand-animated'

interface Props {
  message: CabalMessage
  companySlug: string
}

const MessageEngagement: React.FC<Props> = ({ message, companySlug }) => {
  const [collapsed, setCollapsed] = React.useState(true)
  const { showModal } = useModal()
  const [nameOrEmail, setNameOrEmail] = React.useState('name')

  const { data: messageEngagementResponse } = useQuery(
    ['getMessageEngagement', companySlug, message.uuid],
    () => callApi(api.getMessageEngagement, companySlug, message.uuid),
    {
      refetchInterval: (data) => {
        let refetchDelay = 10000
        if (data && data.data.sent_count < data.data.total_recipient_count) {
          refetchDelay = 2000
        }

        if (data && data.data.opened_count >= data.data.total_recipient_count) {
          return false
        }

        return refetchDelay
      },
      enabled: message.type === 'Message',
    },
  )

  const data = messageEngagementResponse?.data

  const renderStatsModal: RenderModal = (resolve) => (
    <StatsModal show onHide={() => resolve(false)} data={data}></StatsModal>
  )

  if (message.type !== 'Message') return <></>
  if (!data) {
    return <Loading className="my-6" />
  }

  let openRate = 0,
    clickRate = 0,
    notEngagedRate = 0,
    repliedRate = 0,
    sendRate = 0

  if (data.sent_count > 0) {
    openRate = (data.opened_count / data.sent_count) * 100
    clickRate = (data.clicked_count / data.sent_count) * 100
    notEngagedRate = (data.not_engaged_count / data.sent_count) * 100
    repliedRate = (data.replied_count / data.sent_count) * 100
    sendRate = (data.sent_count / data.total_recipient_count) * 100
  }

  const name_or_email_index = nameOrEmail == 'name' ? 1 : 2

  const details = (
    <Typography component="div">
      {!message.disable_replies && (
        <div className="mt-2 mb-4">
          <div className="mb-1 font-bold">Replied</div>
          <div className="text-xs">
            {data.replied.map((reply) => (
              <div key={reply[0]}>
                <Link to={`/${companySlug}/people/${reply[0]}`}>{reply[1]}</Link>
              </div>
            ))}
            {data.replied.length == 0 && <div>No replies</div>}
          </div>
        </div>
      )}
      <div className="mb-4">
        {data.total_recipient_count > 0 &&
          data.sent_count > 0 &&
          data.total_recipient_count > data.sent_count && (
            <div className="mb-1 font-bold">
              Sent {data.sent_count} of {data.total_recipient_count} messages
            </div>
          )
        }
        <div className="mb-2">
          <Typography
            fontSize="12"
            color="purple"
            className="cursor-pointer mb-1"
            onClick={() => {
              showModal(renderStatsModal, 'render_stats_modal')
            }}
          >
            View engagement activity
          </Typography>
          <Typography
            fontSize="12"
            color="purple"
            className="cursor-pointer mb-1"
            onClick={() => 
              setNameOrEmail(nameOrEmail == 'name' ? 'email' : 'name')
            }
          > by {nameOrEmail}</Typography>
        </div>
        <div className="mb-1 font-bold">Opened {`(${openRate.toFixed(1)}%)`}</div>
        <div className="text-xs">
          {data.opened.map((reply) => (
            <div key={reply[0]}>
              <Link to={`/${companySlug}/people/${reply[0]}`}>{reply[name_or_email_index]}</Link>
            </div>
          ))}
          {data.opened.length == 0 && <div>No opens</div>}
        </div>
      </div>
      {data.clicked.length > 0 && (
        <div className="mb-4">
          <div className="mb-1 font-bold">Clicked {`(${clickRate.toFixed(1)}%)`}</div>
          <div className="text-xs">
            {data.clicked.map((reply) => (
              <div key={reply[0]}>
                <Link to={`/${companySlug}/people/${reply[0]}`}>{reply[name_or_email_index]}</Link>
              </div>
            ))}
            {data.clicked.length == 0 && <div>No clicks</div>}
          </div>
        </div>
      )}
      {data.not_engaged.length > 0 && (
        <div>
          <div className="mb-1 font-bold">Not Engaged {`(${notEngagedRate.toFixed(1)}%)`}</div>
          <div className="text-xs">
            {data.not_engaged.map((reply) => (
              <div key={reply[0]}>
                <Link to={`/${companySlug}/people/${reply[0]}`}>{reply[name_or_email_index]}</Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </Typography>
  )

  const summary = (
    <>
      {data.sent_count < data.total_recipient_count && (
        <Typography fontWeight={600} fontSize="14" component="div" className="mt-1.5">
          {`${sendRate.toFixed(1)}%`} Sent{' '}
          <Typography color="fog" fontSize="12" fontWeight={400}>
            {data.sent_count}/{data.total_recipient_count}
          </Typography>
        </Typography>
      )}
      <Typography fontWeight={600} fontSize="14" component="div" className="mt-1.5">
        {`${openRate.toFixed(1)}%`} Opened{' '}
        <Typography color="fog" fontSize="12" fontWeight={400}>
          {data.opened_count}/{data.sent_count}
        </Typography>
      </Typography>
      {!message.disable_replies && (
        <Typography fontWeight={600} fontSize="14" component="div" className="mt-1">
          {`${repliedRate.toFixed(1)}%`} Replied{' '}
          <Typography color="fog" fontSize="12" fontWeight={400}>
            {data.replied_count}/{data.sent_count}
          </Typography>
        </Typography>
      )}
    </>
  )

  return (
    <WidgetWrap className="pb-0">
      <div className="flex justify-between items-center">
        <Typography
          textTransform="uppercase"
          fontSize="12"
          letterSpacing={'0.05em'}
          fontWeight={500}
          color="fog_rain"
          lineHeight={2.25}
        >
          MESSAGE ENGAGEMENT
        </Typography>

        <Typography
          letterSpacing={'0.05em'}
          fontWeight={500}
          color="rain"
          fontSize="12"
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? (
            <i className="fas fa-chevron-down fa-fw"></i>
          ) : (
            <i className="fas fa-chevron-right fa-fw"></i>
          )}
        </Typography>
      </div>

      <Expand open={collapsed}>{details}</Expand>
    </WidgetWrap>
  )
}

export default MessageEngagement
