import styled from 'styled-components'
import tw from 'twin.macro'

const CompanyDetails = styled.div<{ expanded: boolean }>`
  ${tw`
  flex flex-col sm:flex-row gap-2 sm:gap-0 cursor-pointer
  p-3 rounded-none rounded-t-lg transition
`};

  ${({ expanded }) => !expanded && tw`rounded-b-lg`}

  background-color: ${({ theme }) => theme.table.bg_color};
`

const JobsContainer = styled.div`
  ${tw`rounded-b-lg flex -mt-0.5 pl-12`}
  background-color: ${({ theme }) => theme.table.bg_color};
`

const BorderDecore = styled.div`
  ${tw` ml-6 mr-6 my-3 hidden sm:block`}
  border-left: ${({ theme }) => theme.border};
`
const FilterWrapper = styled.div`
  ${tw`sticky pt-2 pb-1`}
  z-index: 10;
`
export { JobsContainer, CompanyDetails, BorderDecore, FilterWrapper }
