import React from 'react'

import cx from 'classnames'
import { Oval } from 'react-loader-spinner'
import { useTheme } from 'styled-components'

export interface Props {
  size?: number
  strokeWidth?: number
}

const OldLoading: React.VFC<Omit<React.HTMLProps<HTMLDivElement>, 'size'> & Props> = ({
  className,
  size,
  strokeWidth,
  color,
  ...props
}) => {
  const theme = useTheme()
  return (
    <div className={cx('flex items-center justify-center', className)} {...props}>
      <Oval
        strokeWidth={strokeWidth || 3}
        color={color || theme.colors.purple}
        secondaryColor={theme.buttons.secondary.bg_color}
        height={size || 48}
        width={size || 48}
      />
    </div>
  )
}

export default OldLoading
