import React from 'react'

import { useParams } from 'react-router-dom'

import { useAccessControl } from 'global/AccessControl'
import { useTeam } from 'store/hooks'
import SectionHeader from 'ui-components/SectionHeader'

import { CompanySlugParam, Team } from 'utils/types'

import { CardBg } from '../styles'
import AgreementTemplates from './AgreementTemplates'

export interface TeamSettingProps {
  team: Team
  reloadTeam: () => Promise<any>
}

const Company = () => {
  return (
    <div className="mt-4">
      <AgreementTemplates />
    </div>
  )
}

export default Company
