import React, { useState } from 'react'

import { useMutation } from 'react-query'

import FacilitatedBy from 'containers/IntroRequestModal/components/FacilitatedBy'
import { DraftEmailAttachment } from 'containers/IntroRequestModal/components/Modals/Sales/DraftEmailAttachment'
import UsersInfo from 'containers/IntroRequestModal/components/UsersInfo'
import {
  closeModals,
  createDraftEmailObject,
  createMessageDraft,
} from 'containers/IntroRequestModal/components/UtilityFunctions'
import {
  ICreateMessageDraftPayload,
  IUpdateMessageDraftPayload,
} from 'containers/IntroRequestModal/components/types'
import { IntroConnection } from 'containers/Portfolio/MemberView/types/IntroConnection'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import Modal, { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

import { IRCompanyListObject, IRPayload, IRequestor, ISalesTarget } from '../../../types'

interface IProps {
  requestable: ISalesTarget
  companyListObject: IRCompanyListObject
  facilitator: IntroConnection
  requestor: IRequestor
  networkingOnly?: boolean
  introRequestUuid?: string | null
  requestors?: any
  facilitators?: any
  connections?: any
  openDraft?: boolean
  draftUuid?: string | null | undefined
}

export function CreateSalesListModal({
  requestable,
  companyListObject,
  facilitator,
  requestor,
  networkingOnly = false,
  requestors,
  facilitators,
  connections,
  openDraft = false,
  draftUuid,
}: IProps) {
  const { user } = useCurrentUser()
  const [noteToRequestor, setNoteToRequestor] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(true)
  const [noteToFacilitator, setNoteToFacilitator] = useState<string>('')
  const listSlug = companyListObject?.slug
  const [messageDraftUuid, setMessageDraftUuid] = useState<string | null | undefined>(
    draftUuid || null,
  )
  const modalName = 'create-sales-list-handler'
  const teamSlug = user?.team?.slug
  const { team } = useTeam(teamSlug)
  const [openDraftMessage, setOpenDraftMessage] = useState(openDraft)

  const admins = team?.admins_and_members.filter((a) => a.team_role === 'admin')

  requestors = requestors || admins

  const [selectedRequestor, setSelectedRequestor] = useState<IRequestor>(user)
  const [selectedFacilitator, setSelectedFacilitator] = useState<IntroConnection>(
    facilitator || facilitators[0],
  )

  const setNoteText = (noteText: string) => {
    if (user.email == selectedRequestor.email) {
      setNoteToFacilitator(noteText)
    } else {
      setNoteToRequestor(noteText)
    }
  }

  const messageDraftCreate: ICreateMessageDraftPayload = createMessageDraft(
    companyListObject?.uuid,
    selectedFacilitator?.uuid,
    requestable.global_person_uuid || requestable.uuid || '',
    selectedRequestor?.uuid,
  )

  const draftEmailObject = createDraftEmailObject(
    selectedRequestor,
    selectedFacilitator,
    requestable,
    messageDraftUuid,
    setMessageDraftUuid,
    teamSlug || '',
    messageDraftCreate,
    null,
    setShowModal,
  )

  const createRequestPayload = () => {
    return {
      team_slug: teamSlug,
      company_list_id: listSlug,
      note: noteToFacilitator || noteToRequestor,
      message_draft_uuid: messageDraftUuid,
      requestable_id: requestable.global_person_uuid || requestable.uuid || '',
      facilitator_user_uuid: selectedFacilitator.uuid,
      requestable_type: requestable.item_type || requestable.type || '',
      additional_note: null,
      request_type: 'internal',
      note_to_gatekeeper: noteToRequestor || null,
      note_to_facilitator: noteToFacilitator || null,
      requestor_uuid: selectedRequestor.uuid,
      initiator_uuid: user.uuid,
    }
  }

  const { mutate: introRequestMutate } = useMutation({
    mutationFn: (payload: IRPayload) => callApi(api.createIntroRequestV2, payload),
  })

  const { mutate: updateMessageMutate } = useMutation({
    mutationFn: ({ messageUuid, sender_uuid }: IUpdateMessageDraftPayload) =>
      api.updateMessageDraft({
        messageUuid,
        sender_uuid,
      }),
  })

  const { closeModal: closeModalFunction } = useModal()

  const handleSubmit = () => {
    const payload: IRPayload = createRequestPayload()

    introRequestMutate(payload, {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Intro requested!', fadeOut: false })
        closeModalFunction('facilitate-intro-modal')
        closeModals({ closeModalFunction, modalName })
        handleClose
      },
      onError: () => {
        console.log('Error')
      },
    })
  }

  const requestRecipientName = () => {
    if (companyListObject?.owned) {
      if (user.uuid == selectedRequestor.uuid) {
        return selectedFacilitator.name
      } else {
        return selectedRequestor.name
      }
    } else if (user.uuid != selectedRequestor.uuid) {
      return selectedRequestor.name
    } else {
      return selectedFacilitator.name
    }
  }

  const handleClose = () => {
    closeModalFunction('render_sales_list_modal')
    closeModalFunction('request-intro-connections-modal')
    closeModalFunction('create-sales-list-handler')
  }

  const buttonText = (
    <div style={{ maxWidth: '300px' }} className="w-full block truncate">
      Send request to {requestRecipientName()}
    </div>
  )

  const handleSetSelectedRequestor = (e: any) => {
    requestor = admins.find((a) => a.uuid === e)
    requestor = { ...requestor, item_type: 'GlobalPerson' }
    setSelectedRequestor(requestor)
  }

  const handleSetFacilitator = (e: any) => {
    facilitator = facilitators.find((a) => a.uuid === e)
    setSelectedFacilitator(facilitator)
    updateMessageMutate({ messageUuid: messageDraftUuid, sender_uuid: facilitator.uuid })
  }

  return (
    <Modal
      show={showModal}
      className="max-w-lg"
      onHide={handleClose}
      header={
        <Typography fontSize="16" fontWeight={600} lineHeight="1.2" className="w-full text-center">
          {`Request intro to ${requestable.name}`}
        </Typography>
      }
      centerActions={
        <div className="pb-2">
          <CabalButton onClick={handleSubmit} className="mr-2">
            {buttonText}
          </CabalButton>
        </div>
      }
    >
      <UsersInfo
        requestor={selectedRequestor}
        target={requestable}
        requestors={requestors}
        selectedValue={handleSetSelectedRequestor}
      />
      <FacilitatedBy
        facilitator={selectedFacilitator}
        facilitators={facilitators}
        selectedFacilitator={handleSetFacilitator}
      />
      {networkingOnly && (
        <div className="mt-6 mb-6 text-center">
          <Typography fontSize={'16'} color={'rain'} fontWeight={600}>
            <i className="fa fa-info-circle mr-1" /> {requestable?.first_name || requestable.name}{' '}
            is only looking to network
          </Typography>
        </div>
      )}
      <div className="space-y-2 mb-2">
        <>
          <Typography fontSize="12" fontWeight={400} lineHeight="1" className=" text-center">
            Note for {requestRecipientName()}
          </Typography>
          <TextArea
            value={noteToRequestor || noteToFacilitator}
            className="w-full mt-1"
            rows={3}
            data-testid="request-talent-intro-modal-context"
            onChange={(e) => setNoteText(e.target.value)}
          />
        </>
      </div>
      <DraftEmailAttachment
        draftEmailObject={draftEmailObject}
        openDraft={openDraftMessage}
        onDraftOpen={(value) => setOpenDraftMessage(value)}
      />
    </Modal>
  )
}
