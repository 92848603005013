import React from 'react'

import { transparentize } from 'polished'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { MainSection } from 'containers/SignupScreen/MarketingSite'
import Footer from 'containers/SignupScreen/MarketingSite/Footer'
import Nav from 'containers/SignupScreen/MarketingSite/Nav'
import CabalButton from 'global/CabalButton'

import defaultLists from './defaultLists'
import BuyerPersonasIllustration from './images/ill-buyer-personas.svg'
import EmailTemplatesIllustration from './images/ill-email-templates.svg'
import PotentialInvestorsIllustration from './images/ill-potential-investors.svg'
import SalesProspectsIllustration from './images/ill-sales-prospects.svg'

export function ResourceCategory(props: {
  title: string
  categoryImage: string
  description: string
  category: (typeof defaultLists.lists)['potential_investors']
  categoryName: string
  suffix?: string
  onView?: (index: number) => void
}) {
  return (
    <div className="p-4 rounded-lg bg-slate-800 text-white">
      <div className="mb-2">
        <div className="flex">
          <img className="mr-4" src={props.categoryImage} />
          <div className="font-semibold text-2xl mb-1">{props.title}</div>
        </div>
        <p className="text-sm text-gray-400">{props.description}</p>
      </div>

      {props.category.lists.map((list, i) => {
        const clickHandler = () =>
          (window.location.href =
            props.category.listType === 'templates'
              ? '/login'
              : `/get-resource/${props.categoryName}/${i}`)
        return (
          <div
            key={i}
            className="flex items-center justify-between py-2 px-2.5 rounded cursor-pointer hover:bg-slate-700 transition-colors duration-100 ease-in-out"
            onClick={clickHandler}
          >
            <div className="text-md mb-0.5">{list.name}</div>

            <div className="flex-shrink-0 pl-2">
              <CabalButton
                variant="link"
                size="large"
                rightIcon={<i className="far fa-chevron-right" />}
                onClick={clickHandler}
                padding="0"
              >
                Use
              </CabalButton>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const ResourceV2 = () => {
  const history = useHistory()
  return (
    <div className="h-screen antialiased">
      <MainSection className="w-full h-full overflow-scroll bg-[#0E151F] ">
        <Nav />

        <section className="px-8 mt-10 mb-10">
          <div className="block max-w-6xl mx-auto">
            <h2 className="text-white text-base ">Resources</h2>
            <p className="text-white tracking-tighter font-light text-3xl sm:text-5xl mt-5">
              Free community resources to help you grow
            </p>
          </div>
        </section>

        {/* <div>
          <div className="flex flex-col justify-center items-center py-11 text-white">
            <div className="flex items-center justify-center pt-10 2xl:pt-12">
              <h2 className="text-3xl sm:text-6xl font-light leading-tight tracking-tighter ">
                Free community resources to help you grow
              </h2>
            </div>

            <p className="py-16 text-2xl  text-center max-w-2xl tracking-tight">
              Lists and templates to help you sell, recruit, fundraise, and win.
            </p>
          </div>
        </div> */}

        <div className="bg-[#0B1017] h-screen pt-20 px-4 sm:px-0">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-x-6 gap-y-9 max-w-6xl mx-auto">
            <ResourceCategory
              title="Sales Prospects"
              description="Uncover intro paths to prospects"
              categoryImage={SalesProspectsIllustration}
              category={defaultLists.lists.sales_prospects}
              categoryName="sales_prospects"
            />
            <ResourceCategory
              title="Email Templates"
              description="Deliver more engaging and personalized updates."
              categoryImage={EmailTemplatesIllustration}
              category={defaultLists.lists.email_templates}
              categoryName="email_templates"
            />
            <ResourceCategory
              title="Potential Investors"
              description="Get warm intros to potential investors."
              categoryImage={PotentialInvestorsIllustration}
              category={defaultLists.lists.potential_investors}
              categoryName="potential_investors"
            />

            <ResourceCategory
              title="Buyer Personas"
              description="Get intros to potential buyers."
              suffix="Personas"
              categoryImage={BuyerPersonasIllustration}
              category={defaultLists.lists.buyer_personas}
              categoryName="buyer_personas"
            />
          </div>

          <Footer />
        </div>
      </MainSection>
    </div>
  )
}

export default ResourceV2
