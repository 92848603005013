import * as React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { H2 } from './styles'
import { useAudio } from 'react-use'

const CardWrapper = styled.div`
  ${tw`flex justify-center items-center flex-col rounded-lg`}
  background-color: #f9fafb;

  height: 100%;

  /* @media (prefers-color-scheme: dark) {
    background-color: #1f2835;
  } */
`

const CardImg = styled.img`
  ${tw`rounded-t-lg `}
  width: 100%;
`

const Para = styled.p`
  font-family: 'JetBrains Mono';
  padding-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #6b7280 !important;

  /* @media (prefers-color-scheme: dark) {
    color: #f7fafc !important;
  } */
`

const CTA = styled.a`
  ${tw`cursor-pointer`}
  color: #5c69d1 !important;
  padding: 0px !important;
  font-family: 'JetBrains Mono' !important;
  font-size: 14px;
`

interface Props {
  img: string
  title: string
  body: string
  audioURL: string
}
const AudioCard: React.VFC<Props> = ({ img, title, body, audioURL }) => {
  const [audio, state, controls] = useAudio({
    src: audioURL,
  })

  return (
    <CardWrapper>
      {audio}
      <div className="flex flex-shrink-0">
        <CardImg src={img} />
      </div>

      <div className="p-8 flex flex-col justify-between flex-grow">
        <div>
          <H2 className="-mt-6">{title}</H2>
          <Para>{body}</Para>
        </div>
        <div>
          {state.playing ? (
            <CTA onClick={() => controls.pause()}>
              <i className="far fa-pause mr-2.5"></i>
              Pause
            </CTA>
          ) : (
            <CTA onClick={() => controls.play()}>
              <i className="far fa-play mr-2.5"></i>
              Play
            </CTA>
          )}
        </div>
      </div>
    </CardWrapper>
  )
}

export default AudioCard
