import axios from 'axios'
import {
  CustomFieldBlueprint,
  CustomFieldBlueprintWithValue,
  CustomFieldEntityType,
  MutableCustomField,
} from 'utils/types'

export default {
  getCustomFields: (
    team_slug: string,
    entity_type: CustomFieldEntityType,
    entity_uuid?: string,
  ) => {
    return axios.get<{
      fields: (CustomFieldBlueprint & { value?: string | number })[]
    }>('/api/custom_fields', {
      params: {
        team_slug,
        entity_type,
        entity_uuid,
      },
    })
  },

  createCustomField: (team_slug: string, params: MutableCustomField) => {
    return axios.post<{ field: CustomFieldBlueprint }>('/api/custom_fields', {
      team_slug,
      field: params,
    })
  },

  updateCustomField: (team_slug: string, params: CustomFieldBlueprint) => {
    return axios.put<{ field: CustomFieldBlueprint }>(`/api/custom_fields/${params.uuid}`, {
      team_slug,
      field: params,
    })
  },

  deleteCustomField: (team_slug: string, uuid: string) => {
    return axios.delete(`/api/custom_fields/${uuid}`, {
      params: {
        team_slug,
      },
    })
  },

  bulkUpdateCustomFieldValue: (
    team_slug: string,
    entity_type: string,
    entity_uuid: string,
    fields: Pick<CustomFieldBlueprintWithValue, 'uuid' | 'value'>[],
  ) => {
    return axios.put(`/api/custom_fields/bulk_update_values`, {
      team_slug,
      entity_type,
      entity_uuid,
      fields,
    })
  },
}
