import React from 'react'

import { useQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'

import ActivityRow from 'components/Activity/ActivityRow'
import Loading from 'global/Loading'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { Activity, AdvisorModel } from 'utils/types'

import ShowMore from '../ShowMore'
import Title from '../Title'
import { HomeSectionWrapper } from '../styles'

interface Props {
  teamSlug: string
  advisor: AdvisorModel
}

const ContributionSection: React.VFC<Props> = ({ teamSlug, advisor }) => {
  const advisorId = advisor.uuid
  const history = useHistory()
  const { data, refetch, isFetching } = useQuery([teamSlug, 'getMemberActivity'], () =>
    callApi(api.getMemberActivity, teamSlug, 'user_team_activities', advisorId),
  )

  const limit = 3

  if (isFetching) {
    return <></>
  }

  const link = `/${teamSlug}/activity?user=${advisor.user_uuid}`

  return (
    <HomeSectionWrapper>
      {isFetching ? (
        <div>{/* <Loading /> */}</div>
      ) : data?.activity && data?.activity.length > 0 ? (
        <div>
          <Title label={'Contributions'} onClick={() => history.push(link)} />
          <div className="-mt-2">
            {data?.activity?.slice(0, limit).map((activity: Activity) => (
              <div key={activity.uuid} className="my-2">
                <ActivityRow activity={activity} refetchActivities={refetch} compact />
              </div>
            ))}
          </div>
          {data?.activity && (
            <ShowMore
              link={link}
              limit={3}
              objectArray={data?.activity}
              title={`See all (${data?.activity?.length})`}
            />
          )}
        </div>
      ) : null}
    </HomeSectionWrapper>
  )
}

export default ContributionSection
