import * as React from 'react'
import Typography from 'global/Typography'
import { textCTAProps } from '.'

export interface Props {
  textCTA: textCTAProps
}

const CTA: React.VFC<Props> = ({ textCTA }) => {
  return (
    <>
      <Typography
        color="purple"
        className="float-right cursor-pointer"
        fontSize="12"
        fontWeight={500}
        onClick={textCTA?.onClick}
      >
        {textCTA?.label}
      </Typography>
    </>
  )
}

export default CTA
