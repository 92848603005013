import React from 'react'

import styled from 'styled-components'

import { cn } from 'utils/styles'

const StyledPill = styled.button<{ active: boolean; onClick?: () => void }>`
  border: 1px solid
    ${({ theme, active, onClick }) =>
      active || !onClick ? theme.colors.purple : theme.colors.border};
  background-color: ${({ theme, active, onClick }) =>
    active || !onClick ? theme.pill.purple_filled.primary_bg : theme.colors.cardBackground};
  color: ${({ theme, active, onClick }) =>
    active || !onClick ? theme.pill.purple_filled.primary_text : theme.colors.fog};

  &:hover {
    background-color: ${({ theme, onClick }) => onClick && theme.pill.purple_filled.primary_bg};
    color: ${({ theme, onClick }) => onClick && theme.pill.purple_filled.primary_text};
  }
`

interface Props {
  title: React.ReactNode
  active?: boolean
  onClick?: () => void
  className?: string
}

const PillV2: React.FC<Props> = ({ active = false, title, onClick, className }) => {
  return (
    <StyledPill
      onClick={onClick ?? undefined}
      active={active}
      className={cn(
        'flex items-center justify-center rounded-full px-3 py-2 text-xs leading-4 cursor-default',
        {
          'cursor-pointer': onClick,
        },
        className,
      )}
    >
      {title}
    </StyledPill>
  )
}

export default PillV2
