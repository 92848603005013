import React, { useEffect } from 'react'

import { useMutation, useQuery } from 'react-query'

import { Select } from 'global/Input'
import { useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import { DigestSchedule } from 'utils/types'

const determineSchedule = (schedule: DigestSchedule) => {
  const days = Object.values(schedule)
  const weekdays = days.slice(1, 6)
  const weekends = [days[0], days[6]]

  if (days.every((day) => day === true)) {
    return 'Everyday'
  } else if (weekdays.every((day) => day === true) && weekends.every((day) => day === false)) {
    return 'Weekdays'
  } else if (weekends.every((day) => day === true) && weekdays.every((day) => day === false)) {
    return 'Weekends'
  } else {
    return 'Never'
  }
}

const scheduleToDigestSchedule = (schedule: string): DigestSchedule => {
  switch (schedule) {
    case 'Everyday':
      return {
        Sunday: true,
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true,
      }
    case 'Weekdays':
      return {
        Sunday: false,
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: false,
      }
    case 'Weekends':
      return {
        Sunday: true,
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: true,
      }
    case 'Never':
    default:
      return {
        Sunday: false,
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
      }
  }
}

const Digest = () => {
  const { user } = useCurrentUser()
  const [schedule, setSchedule] = React.useState<string>('Never')

  const { data: digestSchedule, refetch } = useQuery(['digestSchedule', user.uuid], () =>
    api.getDigestSchedule(user.uuid),
  )

  const { mutate: updateDigestSchedule } = useMutation(
    (newData: DigestSchedule) => api.setDigestSchedule(user.uuid, newData),
    {
      onSuccess: () => {
        refetch()
        cabalToast({ style: 'success', content: 'Digest Email Schedule Updated.' })
      },
    },
  )

  useEffect(() => {
    if (digestSchedule?.data) {
      const schedule = determineSchedule(digestSchedule.data)
      setSchedule(schedule)
    }
  }, [digestSchedule?.data])

  const handleSubmit = (newSchedule: string) => {
    updateDigestSchedule(scheduleToDigestSchedule(newSchedule))
  }

  return (
    <div>
      <Select
        value={schedule}
        className="w-full"
        onChange={(option) => option && handleSubmit(option)}
        placeholder="Select user"
        options={[
          { value: 'Never', label: 'Never' },
          { value: 'Weekdays', label: 'Weekdays' },
          { value: 'Weekends', label: 'Weekends' },
          { value: 'Everyday', label: 'Everyday' },
        ]}
      />
    </div>
  )
}

export default Digest
