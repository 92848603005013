import React, { useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'

import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { UserEmail } from 'utils/types'

import MailRow from './MailRow'

const UserEmails: React.VFC = () => {
  const [editing, setEditing] = useState(false)
  const [newEmail, setNewEmail] = useState('')

  const emailsQuery = useQuery(['currentUser', 'emailSettings'], () => callApi(api.getUserEmails))

  const addEmailMutation = useMutation((email: string) => callApi(api.addUserEmail, email), {
    onSuccess: () => {
      emailsQuery.refetch()
      cabalToast({ style: 'success', content: 'Successfully added email' })
    },
  })

  const removeEmailMutation = useMutation(
    (emailId: number) => callApi(api.removeUserEmail, emailId),
    {
      onSuccess: () => {
        emailsQuery.refetch()
        cabalToast({ style: 'success', content: 'Successfully removed email' })
      },
    },
  )

  const resendEmailTokenMutation = useMutation(
    (emailId: number) => callApi(api.resendUserEmailToken, emailId),
    {
      onSuccess: () => {
        emailsQuery.refetch()
        cabalToast({ style: 'success', content: 'Email verification link sent' })
      },
    },
  )

  const handleSubmit = async () => {
    await addEmailMutation.mutateAsync(newEmail)
    setNewEmail('')
    setEditing(false)
  }

  const handleRemoveEmail = (email: UserEmail) => {
    if (!confirm('Are you sure you want to remove this email?')) return
    return removeEmailMutation.mutateAsync(email.id)
  }

  const handleResendEmailToken = (email: UserEmail) => {
    return resendEmailTokenMutation.mutateAsync(email.id)
  }

  const emails = emailsQuery.data

  return (
    <>
      <Typography fontSize="12">
        Add your email accounts to easily switch between accounts
      </Typography>

      <div className="mt-6 mb-2 px-2">
        <Typography fontSize="14" color="fog">
          Email
        </Typography>
      </div>

      {emails && emails.length > 0 && (
        <>
          <Divider />

          {emails.map((mail) => (
            <React.Fragment key={mail.id}>
              <MailRow
                email={mail}
                onRemoveEmail={handleRemoveEmail}
                onResendToken={handleResendEmailToken}
              />

              <Divider />
            </React.Fragment>
          ))}
        </>
      )}

      <div className="mt-4">
        {!editing ? (
          <CabalButton
            leftIcon={<i className="far fa-plus pr-1" />}
            variant="secondary"
            onClick={() => setEditing(!editing)}
          >
            Add Email
          </CabalButton>
        ) : (
          <>
            <TextInput
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="New Email"
              autoFocus
            />

            <CabalButton className="ml-4" variant="primary" onClick={handleSubmit}>
              Save
            </CabalButton>

            <CabalButton variant="tertiary" className="ml-2" onClick={() => setEditing(!editing)}>
              Cancel
            </CabalButton>
          </>
        )}
      </div>
    </>
  )
}

const Divider = styled.div`
  border-bottom: ${({ theme }) => theme.border};
`

export default UserEmails
