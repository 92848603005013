import React, { useState } from 'react'
import api, { callApi } from 'utils/api'
import { useMutation, useQuery } from 'react-query'
import { useSearchParam } from 'react-use'
import { Redirect, useParams } from 'react-router-dom'
import Card from 'global/Card'
import { CardWidth, Container } from './styles'
import { cabalToast } from 'ui-components/Toast'
import CabalButton from 'global/CabalButton'
import { useUniqueAccessLink } from 'store/hooks'
import Loading from 'global/Loading'
import { IntroRequest } from 'utils/types/investor'
import Typography from 'global/Typography'
import { TextArea } from 'global/Input'

const DeclineRequest: React.VFC = () => {
  const { uuid: uniqueAccessLinkUuid, isLoadingUniqueAccessLink: isLoadingUniqueAccessLink } =
    useUniqueAccessLink()

  const { secret_uuid: secretUuid } = useParams<{ secret_uuid: string }>()
  const teamSlug = useSearchParam('team_slug') || ''
  const companyUuid = useSearchParam('company_uuid') || ''
  const advisorUuid = useSearchParam('advisor_uuid') || ''
  const [introRequest, setIntroRequest] = useState<IntroRequest>()
  const [declineNote, setDeclineNote] = useState<string>()

  const { isLoading: isLoadingRequest } = useQuery(
    ['getIntroRequest', secretUuid],
    () => callApi(api.getIntroRequest, secretUuid, companyUuid || ''),
    {
      onSuccess: (data) => {
        setIntroRequest(data)
      },
      enabled: !!companyUuid,
    },
  )

  const { isLoading: isLoadingAdvisorIntroRequest } = useQuery(
    ['getAdvisorIntroRequest', secretUuid],
    () => callApi(api.getAdvisorIntroRequest, secretUuid, advisorUuid || '', teamSlug),
    {
      onSuccess: (data) => {
        setIntroRequest(data)
      },
      enabled: !!advisorUuid,
    },
  )

  const { mutate: declineIntro, isLoading: isDecliningIntro } = useMutation(
    ['declineIntro'],
    () => {
      return callApi(
        api.declineIntro,
        secretUuid,
        companyUuid,
        teamSlug,
        uniqueAccessLinkUuid,
        declineNote,
      )
    },
    {
      onSuccess: ({ intro_request }) => {
        cabalToast({ style: 'success', content: 'Successfully declined!' })
        setIntroRequest(intro_request)
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const { mutate: declineIntroToAdvisor, isLoading: isDecliningIntroToAdvisor } = useMutation(
    ['declineIntroToAdvisor'],
    () => {
      return callApi(
        api.declineIntroToAdvisor,
        secretUuid,
        advisorUuid,
        teamSlug,
        uniqueAccessLinkUuid,
        declineNote,
      )
    },
    {
      onSuccess: ({ intro_request }) => {
        cabalToast({ style: 'success', content: 'Successfully declined!' })
        setIntroRequest(intro_request)
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  if (isLoadingRequest || isLoadingAdvisorIntroRequest || isLoadingUniqueAccessLink)
    return <Loading />

  if (introRequest?.accepted_at)
    return (
      <Redirect
        to={`/intro-requests/${introRequest?.secret_uuid}/accepted?company_uuid=${companyUuid}&show_error_toast=true`}
      />
    )

  if (introRequest?.declined_at)
    return (
      <Redirect
        to={`/intro-requests/${introRequest?.secret_uuid}/declined?company_uuid=${companyUuid}`}
      />
    )

  const disabled = isDecliningIntroToAdvisor || isDecliningIntro

  return (
    <Container>
      <CardWidth>
        <Card className="p-5 w-full">
          <Typography
            fontSize="16"
            fontWeight={600}
            lineHeight="1.2"
            data-testid="decline-intro-title"
          >
            {advisorUuid
              ? `Decline intro to ${introRequest?.advisor_name}`
              : `Decline intro to ${introRequest?.candidate_name}`}
          </Typography>
          <TextArea
            value={declineNote}
            onChange={(e) => setDeclineNote(e.target.value)}
            className="w-full mt-3"
            rows={3}
            placeholder={
              advisorUuid
                ? `Add a note why ${introRequest?.advisorName} is not a fit`
                : `Add a note why ${introRequest?.candidate_name} is not a fit`
            }
            data-testid="decline-talent-intro-modal-context"
          />
          <CabalButton
            className="mt-3 w-full"
            onClick={() => (advisorUuid ? declineIntroToAdvisor() : declineIntro())}
            working={disabled}
            disabled={disabled}
            data-testid="decline-confirmation-send-btn"
          >
            Submit
          </CabalButton>
        </Card>
      </CardWidth>
    </Container>
  )
}

export default DeclineRequest
