import axios from 'axios'
import { Offer } from 'utils/types'

export default {
  getOwnerEmails: (team_slug: string) => {
    return axios.get<{ emails: string[] }>(`/api/opportunities/all_owner_emails`, {
      params: {
        team_slug,
      },
    })
  },

  getStageNames: (team_slug: string) => {
    return axios.get<{ stages: { id: string; name: string }[] }>(`/api/opps/stage_names`, {
      params: {
        team_slug,
      },
    })
  },

  getPipelineNames: (team_slug: string) => {
    return axios.get<{ pipelines: string[] }>(`/api/opps/pipeline_names`, {
      params: {
        team_slug,
      },
    })
  },
}
