import axios from 'axios'

import { FiltersValueType } from 'components/Filters'

import { GetInboxMessagesResponse, GetMessagesResponse } from 'utils/types'

export default {
  createGroupMessage: (
    group_uuid: string,
    params: {
      body: string
    },
  ) => {
    return axios.post(`/api/groups/${group_uuid}/messages`, params)
  },

  getGroupMessages: ({
    group_uuid,
    team_slug,
    filters,
    limit,
    page,
  }: {
    group_uuid?: string
    team_slug: string
    filters?: FiltersValueType
    limit?: number
    page?: number
  }) => {
    return axios.get<GetMessagesResponse>(`/api/groups/${group_uuid}/messages`, {
      params: {
        team_slug,
        filters: JSON.stringify(filters),
        page,
        limit,
      },
    })
  },

  getGlobalMessages: ({
    team_slug,
    filters,
    limit,
    page,
    drafts,
    tab,
  }: {
    team_slug: string
    filters?: FiltersValueType
    limit?: number
    page?: number
    drafts?: boolean
    tab?: string
  }) => {
    const params = {
      team_slug,
      drafts,
      filters: JSON.stringify(filters),
      page,
      limit,
      tab,
    }
    return axios.get<GetMessagesResponse>(`/api/messages/global_search`, {
      params,
    })
  },

  getInboxMessages: (params: { team_slug: string; page: number }) => {
    return axios.get<GetInboxMessagesResponse>(`/api/inbox_messages`, {
      params,
    })
  },

  getAdvisorMessages: (advisor_uuid: string, team_slug: string) => {
    return axios.get<GetMessagesResponse>(`/api/messages`, {
      params: {
        advisor_uuid,
        team_slug,
      },
    })
  },

  loadGroupMessage: (group_uuid: string, message_uuid: string) => {
    return axios.get(`/api/groups/${group_uuid}/messages/${message_uuid}`)
  },

  getMessageSendStats: (message_uuid: string) => {
    return axios.get(`/api/messages/${message_uuid}/sent_count`)
  },

  saveMessageDraft: (params: any) => {
    return axios.post(`/api/messages/save_draft`, params)
  },
}
