import React, { useEffect, useState } from 'react'

import Typography from 'global/Typography'

import useColorMode from 'utils/hooks/useColorMode'

import { Layout } from './Layout'

export function Spinner() {
  const { isMobile } = useColorMode()
  const easierText = 'Making your life easier'

  const [showText, setShowText] = useState<string>(easierText)
  const thanksText = "You'll thank us later"

  useEffect(() => {
    // Set a timeout to update the state after 3000 milliseconds (3 seconds)
    const timer = setTimeout(() => {
      setShowText(thanksText)
    }, 2500)

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer)
  }, []) // Empty dependency array ensures the effect runs only once after the initial render

  return (
    <Layout>
      <div className="flex flex-col items-center mt-[33vh] justify-center gap-12">
        <i className="fa-solid fa-spinner fa-spin text-green-100 fa-2xl"></i>
        <Typography
          className="mx-auto"
          fontSize={isMobile ? '18' : '20'}
          lineHeight="normal"
          fontWeight={500}
          fontFamily="mono"
          style={{ color: '#DEF2E6' }}
        >
          {showText}
        </Typography>
      </div>
    </Layout>
  )
}
