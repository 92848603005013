import axios, { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import {
  IOfferVoteMutationKey,
  IOfferVoteParams,
  IOfferVoteWithId,
} from 'components/Winsite/types/IOfferVote'

import { WINSITE_ENDPOINTS } from '../apiEndpoints'

const offerVote = async ({
  votableType,
  teamSlug,
  votableId,
  vote,
}: IOfferVoteParams): Promise<AxiosResponse> => {
  return await axios.post(WINSITE_ENDPOINTS.offerVote, {
    votable_type: votableType,
    vote: vote,
    votable_id: votableId,
    team_slug: teamSlug,
  })
}

export const useOfferVote = ({ teamSlug, votableType }: IOfferVoteMutationKey) =>
  useMutation({
    mutationKey: ['offerVote', teamSlug, votableType],
    mutationFn: ({ vote, teamSlug, votableId }: IOfferVoteWithId) =>
      offerVote({ votableType, teamSlug, votableId, vote }),
    retry: 0,
  })
