import React, { useEffect, useMemo, useState } from 'react'

import { useMutation } from 'react-query'

import CabalButton from 'global/CabalButton'
import { MultiSelect } from 'global/Input'
import RichTextInput from 'global/Input/RichTextInput'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import { useAdvisors, useGroups } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { AdvisorModel, GroupModel } from 'utils/types'

import BorderedBox from './BorderedBox'

interface Props {
  advisors?: AdvisorModel[]
  groups?: GroupModel[]
  onHide: (sent: boolean) => void
  teamSlug: string
}

type OptionType = {
  value: string
  label: string
}

const AdvisorInviteModal: React.VFC<Props> = ({
  teamSlug,
  advisors: _advisors,
  groups: _groups,
  onHide,
}) => {
  const { advisors: allAdvisors, reloadAdvisors } = useAdvisors({ teamSlug })
  const { groups: allGroups } = useGroups(teamSlug)

  const [value, setValue] = useState<string[]>([])

  const [note, setNote] = useState<string>(``)

  const sendInviteMutation = useMutation(
    () =>
      callApi(api.inviteAdvisors, {
        team_slug: teamSlug,
        advisor_uuids: value.filter((v) => v.startsWith('advisor:')).map((v) => v.split(':')[1]),
        group_uuids: value.filter((v) => v.startsWith('group:')).map((v) => v.split(':')[1]),
        note,
        steps: {
          welcome: true,
          connections: true,
          personal_info: false,
          talent_profile: false,
        },
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Invite sent successfully' })
        reloadAdvisors()
        onHide(true)
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content:
            "You don't have permission to send invites. Please contact an admin to invite this member.",
        })
        onHide(true)
      },
    },
  )

  useEffect(() => {
    setValue(
      (_advisors?.map((a) => `advisor:${a.uuid}`) || []).concat(
        _groups?.map((g) => `group:${g.uuid}`) || [],
      ),
    )
  }, [])

  const options: OptionType[] = useMemo(() => {
    const advisorsOptions =
      allAdvisors?.map<OptionType>((advisor) => ({
        value: `advisor:${advisor.uuid}`,
        label: advisor.name + ' - ' + advisor.email,
        group: 'Advisors',
      })) || []
    const groupsOptions = allGroups.map<OptionType>((group) => ({
      value: `group:${group.uuid}`,
      label: group.name,
      group: 'Groups',
    }))

    return [...advisorsOptions, ...groupsOptions]
  }, [allAdvisors, allGroups])

  return (
    <Modal
      onHide={() => onHide(false)}
      show
      header="Send Invite"
      rightActions={
        <CabalButton
          onClick={() => sendInviteMutation.mutate()}
          working={sendInviteMutation.isLoading}
        >
          Send Invite
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>People</ModalInputLabel>
          <div className="w-full">
            <MultiSelect<OptionType['value']>
              options={options}
              placeholder="Add members and groups"
              onChange={setValue}
              value={value}
            />
          </div>
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Message</ModalInputLabel>
          <div>
            <BorderedBox className="rounded overflow-clip">
              <RichTextInput
                value={note}
                onChange={setNote}
                minHeight={'75px'}
                placeholder="Add a message"
              />
            </BorderedBox>
          </div>
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default AdvisorInviteModal
