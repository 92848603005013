import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import Collapse from '@kunukn/react-collapse'
import cx from 'classnames'

import Typography from 'global/Typography'

const Wrapper = styled(Typography)`
  ${tw`flex-initial flex justify-between p-2 w-full`}
`

const TitleIcon = styled.div`
  ${tw`flex-1 flex items-center gap-2`}
`

const Content = styled.div<{ expanded: boolean }>`
  ${tw`flex-1 overflow-y-auto w-full`}

  ${({ expanded }) => expanded && tw`h-full`}
`

interface ComposerSidebarBaseProps {
  id: string
  title: React.ReactNode
  icon: React.ReactNode
  content: React.ReactNode
  expanded: boolean
  setExpanded: (s: string) => void
  sidebarCollapsed: boolean
  uncollpaseSidebar: () => void
}

const ComposerSidebarBaseSection: React.VFC<ComposerSidebarBaseProps> = ({
  id,
  title,
  icon,
  content,
  expanded,
  setExpanded,
  sidebarCollapsed,
  uncollpaseSidebar,
}) => {
  const toggleExpanded = () => {
    uncollpaseSidebar()
    setExpanded(expanded ? '' : id)
  }

  return (
    <>
      <Wrapper onClick={toggleExpanded} component="button" color="fog" fontSize="12">
        <TitleIcon>
          {icon}
          {!sidebarCollapsed && title}
        </TitleIcon>
        {!sidebarCollapsed && (
          <div>
            {expanded ? (
              <i className="far fa-chevron-down" />
            ) : (
              <i className="far fa-chevron-right" />
            )}
          </div>
        )}
      </Wrapper>
      {!sidebarCollapsed && (
        <Collapse
          isOpen={expanded}
          className={cx('w-full collapse-css-transition', { 'flex-1': expanded })}
        >
          {(collapseState) =>
            collapseState !== 'collapsed' ? (
              <Content expanded={collapseState === 'expanded'}>{content}</Content>
            ) : (
              <></>
            )
          }
        </Collapse>
      )}
    </>
  )
}

export default ComposerSidebarBaseSection
