import styled from 'styled-components'
import tw from 'twin.macro'

const NavWrapper = styled.div<{ expanded: boolean }>`
  ${tw`py-3 flex flex-col h-screen gap-0.5 sticky top-0`}
  grid-area: nav;
  border-right: ${({ theme }) => theme.border};
  ${({ expanded }) => (expanded ? `width: 235px;` : `width: 45px`)};
  ${({ expanded }) => (expanded ? tw`justify-self-end` : tw`justify-self-start`)};
`

const NavItemDivWrapper = styled.div<{ active: boolean }>`
  ${tw`flex items-center cursor-pointer`}

  &.active {
    background-color: ${({ theme, active }) => active && theme.side_nav.active_item_bg_color};
    border-radius: 0.5rem;
  }

  &:hover {
    color: ${({ theme }) => theme.side_nav.active_item_text_color};
  }
`

const Divider = styled.div`
  ${tw`flex`}

  padding-top: 1px;
  background: ${({ theme }) => theme.colors.border};
  width: 100%;
  height: 0;
`

export const Hamburger = styled.button`
  ${tw`block absolute z-50 top-0 left-0 p-3 pl-4 pt-4`}
`

export const ExpandButtonContainer = styled.div<{ expanded: boolean }>`
  ${tw`flex`}

  ${({ expanded }) => (expanded ? tw`justify-start` : tw`justify-center`)}

  button {
    color: ${({ theme }) => theme.colors.fog};

    &:hover {
      color: ${({ theme }) => theme.colors.purple};
    }
  }
`

export const CompanyNameContainer = styled.div`
  ${tw`flex justify-between items-center flex-1 gap-2 mt-0.5`}
`

interface CompanyContainerWrapperProps {
  expanded: boolean
  active: boolean
}

export const CompanyContainerWrapper = styled(NavItemDivWrapper)<CompanyContainerWrapperProps>`
  ${tw`flex gap-2 rounded-lg items-center`}

  ${({ active, theme, expanded }) =>
    active && expanded && `background-color: ${theme.colors.primary_bg};`}

${({ expanded }) => (expanded ? tw`mx-2 p-1` : tw`px-2 py-1`)}

  ${({ expanded, active, theme }) =>
    !expanded &&
    active &&
    `
    position: relative;

    &:before {
      content: '';
      background: ${theme.colors.purple};
      width: 4px;
      height: 18px;
      border-radius: 4px 0px 0px 4px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  `}

  .delete-icon {
    display: none;
  }
  &:hover .delete-icon {
    display: block;
  }
`

export { NavItemDivWrapper, NavWrapper, Divider }
