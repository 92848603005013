import React, { useMemo, useState } from 'react'

import { MultiSelect, SelectOption } from 'global/Input'
import { AdvisorModel } from 'utils/types'
import { useGroups } from 'store/hooks'
import api, { callApi } from 'utils/api'
import { useMutation } from 'react-query'
import { useDeepCompareEffect } from 'react-use'

interface Props {
  advisor: AdvisorModel
  onChange: (uuids: string[]) => void
  teamSlug: string
}

const AdvisorGroupsEdit: React.VFC<Props> = ({ advisor, teamSlug, onChange }) => {
  const { groups: allGroups, reloadGroups, isLoading: loadingGroups } = useGroups(teamSlug)

  const { mutate: createGroup, isLoading: creatingGroup } = useMutation(
    ['createGroup', teamSlug],
    (name: string) => callApi(api.createGroup, teamSlug, { name }),
    {
      onSuccess: ({ group }) => {
        reloadGroups().then(() => {
          setAdvisorGroupUUIDs([...advisorGroupUUIDs, group.uuid])
        })
      },
    },
  )

  const [advisorGroupUUIDs, setAdvisorGroupUUIDs] = useState<string[]>(
    advisor.groups.filter((g) => g.name !== 'All').map((g) => g.uuid),
  )
  const groups = useMemo(() => allGroups.filter((g) => g.name !== 'All'), [allGroups])

  useDeepCompareEffect(() => {
    onChange(advisorGroupUUIDs)
  }, [advisorGroupUUIDs])

  const options = groups.map<SelectOption<string>>((g) => ({
    label: g.name,
    value: g.uuid,
  }))

  const handleChange = (uuids: string[] | null) => {
    const newValue = uuids ?? []

    setAdvisorGroupUUIDs(newValue)
  }

  const handleCreate = (v: string) => {
    createGroup(v)
  }

  return (
    <MultiSelect
      portal
      isLoading={loadingGroups || creatingGroup}
      disabled={loadingGroups || creatingGroup}
      options={options}
      isClearable={false}
      creatable
      value={advisorGroupUUIDs}
      onChange={handleChange}
      onCreateOption={handleCreate}
      formatCreateLabel={(v) => `Create "${v}" group`}
      className="w-full"
    />
  )
}

export default AdvisorGroupsEdit
