import React, { useMemo } from 'react'

import flatten from 'lodash/flatten'
import { DropzoneRootProps, useDropzone } from 'react-dropzone'
import { useSet } from 'react-use'
import useStateRef from 'react-usestateref'
import styled, { useTheme } from 'styled-components'
import tw from 'twin.macro'
import { v4 as uuid } from 'uuid'

import ProgressBar from 'global/ProgressBar'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import useUploadFile from 'utils/hooks/useUploadFile'
import { UploadModel } from 'utils/types'

const Container = styled.div<DropzoneRootProps>`
  ${tw`flex flex-col items-center justify-center p-6 border rounded-lg relative`}
  ${tw`outline-none cursor-pointer`}
  border-width: 10px;
  border-color: transparent;

  &:before {
    ${tw`absolute w-full h-full top-0 left-0`}
    content: '';

    background-image: ${(props) => {
      let color = props.theme.colors.rain

      if (props.isDragAccept) {
        color = props.theme.colors.green
      }
      if (props.isDragReject) {
        color = props.theme.colors.red
      }
      if (props.isDragActive) {
        color = props.theme.colors.purple
      }

      color = encodeURIComponent(color)

      return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='${color}' stroke-width='2' stroke-dasharray='6%2c 7' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");`
    }}
    border-radius: 4px;

    border-color: ${(props) => {
      if (props.isDragAccept) {
        return props.theme.colors.green
      }
      if (props.isDragReject) {
        return props.theme.colors.red
      }
      if (props.isDragActive) {
        return props.theme.colors.purple
      }
      return props.theme.colors.border
    }};
    transition: border 0.24s ease-in-out;
  }
`

const FileNameContainer = styled.div`
  ${tw`flex items-center border rounded-full px-2`}
`

const FileName = styled.div`
  ${tw`max-w-xs inline-block overflow-ellipsis overflow-hidden whitespace-nowrap`}
`

export type AcceptableFiles = 'pdf' | 'doc' | 'png' | 'jpg' | 'zip' | 'csv' | 'video'

interface Props {
  onSelect?: (files: File[]) => void
  accept?: AcceptableFiles[]
  multiple?: boolean
  hideProgress?: boolean
  beforeUpload?: () => void
  /**
   * applicable only when `multiple` is true
   */
  hideDndOnSelect?: boolean
  uploadOnSelect?: boolean
  onUpload?: (uploadedFiles: UploadModel[]) => void
  prompt?: React.ReactNode
  hideFiles?: boolean
  attachTo?: string
}

type uploadProgressCallback = (p: number) => void

const fileKey = (f: File) => `${f.lastModified} ${f.size} ${f.name}`

export interface UploadZoneRef {
  uploadFiles: (onUploadProgress?: uploadProgressCallback) => Promise<UploadModel[]>
  deSelect: () => void
}

const UploadZone = React.forwardRef<UploadZoneRef, Props>(
  (
    {
      onSelect,
      accept,
      hideDndOnSelect = false,
      multiple = false,
      beforeUpload,
      onUpload,
      uploadOnSelect,
      prompt,
      hideFiles = false,
      hideProgress = false,
      attachTo,
      ...restProps
    },
    ref,
  ) => {
    const theme = useTheme()
    const [files, _setFiles, filesRef] = useStateRef<Record<string, File>>({})
    const [uploadedFiles, setUploadedFiles] = useSet<string>(new Set())
    const [progress, setProgress] = React.useState(-1)

    const setFiles = (files: Record<string, File>) => {
      _setFiles(files)
      onSelect?.(Object.values(files))

      if (uploadOnSelect && !!Object.keys(files).length) {
        uploadFiles()
      }
    }

    const acceptableFiles = useMemo(() => {
      const acceptable: Record<string, string[]> = {}

      accept?.forEach((a) => {
        switch (a) {
          case 'pdf':
            acceptable['application/pdf'] = ['.pdf']
            break
          case 'doc':
            acceptable['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] =
              ['.docx']
            acceptable['application/msword'] = ['.doc']
            break
          case 'png':
            acceptable['image/png'] = ['.png']
            break
          case 'jpg':
            acceptable['image/jpeg'] = ['.jpeg', '.jpg']
            break
          case 'zip':
            acceptable['application/zip'] = ['.zip']
            break
          case 'csv':
            acceptable['text/csv'] = ['.csv']
            break
          case 'video':
            acceptable['video/*'] = []
            break
          default:
            break
        }
      })

      return acceptable
    }, [accept])

    const onDropAccepted = (newfiles: File[]) => {
      const nonEmptyFiles = newfiles.filter((file) => file.size > 0)
      if (nonEmptyFiles.length !== newfiles.length) {
        cabalToast({
          style: 'error',
          content: 'This file appears to be blank, please try again',
        })
      }
      const updatedFiles = { ...files }
      for (const file of nonEmptyFiles) {
        updatedFiles[uuid()] = file
      }
      setFiles(updatedFiles)
    }

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
      accept: acceptableFiles,
      multiple,
      onDropAccepted: onDropAccepted,
      onDropRejected: () => {
        cabalToast({
          style: 'error',
          content: `Only ${flatten(accept).join(', ')} formats accepted`,
        })
      },
    })
    const { uploadFile } = useUploadFile()

    React.useImperativeHandle(ref, () => ({
      uploadFiles: (p) => uploadFiles(p),
      deSelect: () => setFiles({}),
    }))

    const uploadFiles = (progressCallback?: uploadProgressCallback) =>
      new Promise<UploadModel[]>((resolve, reject) => {
        const promises: Promise<UploadModel>[] = []
        beforeUpload?.()
        setProgress(1)
        for (const key in filesRef.current) {
          const file = filesRef.current[key]
          console.log(key, file)
          promises.push(
            uploadFile({
              file,
              attachTo,
              onUploadProgress: (p) => {
                setProgress((prev) => {
                  const newProgress = prev + p / Object.keys(filesRef.current).length
                  progressCallback?.(newProgress)
                  return newProgress
                })
              },
            }).then((u) => {
              setUploadedFiles.add(key)
              return u
            }),
          )
        }
        Promise.all(promises)
          .then((u) => {
            resolve(u)

            onUpload?.(u)
            setProgress(-1)
          })
          .catch(reject)
      })

    return (
      <div {...restProps}>
        {!(hideDndOnSelect && !multiple && Object.keys(files).length === 1) && (
          <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            <Typography color="gray" component="div" className="text-center" fontSize="12">
              {!prompt && (
                <>
                  <Typography fontWeight={600} color="primary" component="div">
                    Drop file{multiple && 's'} here
                  </Typography>
                  Drag and drop to upload or <Typography color="purple">click to browse</Typography>
                </>
              )}
              {prompt}
            </Typography>
          </Container>
        )}

        {!hideFiles && Object.keys(files).length > 0 && (
          <Typography fontSize="12" component="div" className="flex gap-2 flex-wrap my-2">
            {Object.entries(files).map(([key, file], i) => (
              <FileNameContainer
                id={file.name.replace(/\s/g, '-').replace(/\./g, '-')}
                key={fileKey(file)}
                style={{
                  borderColor: theme.colors.purple,
                  color: window.location.href.includes('//win.') ? 'black' : theme.colors.purple,
                }}
              >
                {uploadedFiles.has(key) && (
                  <Typography fontSize="14" color="green" className="mr-2">
                    <i className="fas fa-cloud-check" />
                  </Typography>
                )}
                <FileName>{file.name}</FileName>
                <button
                  onClick={() => {
                    const updatedFiles = { ...files }
                    delete updatedFiles[key]
                    setFiles(updatedFiles)
                  }}
                  className="ml-2"
                >
                  <Typography fontSize="14">
                    <i className="far fa-times" />
                  </Typography>
                </button>
              </FileNameContainer>
            ))}
          </Typography>
        )}
        {progress > -1 && !hideProgress && <ProgressBar percentage={progress} />}
      </div>
    )
  },
)

export default UploadZone
