import React, { useEffect, useState } from 'react'
import { Checklist, ChecklistItem } from 'global/Input/Checklist'
import { Container } from '../styles'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import EmptyState from 'global/EmptyState'
import { AdvisorModel } from 'utils/types'

interface Props {
  advisors: AdvisorModel[]
  isAdvisorLoaded: boolean
  onInsertMember: (items: ChecklistItem[]) => void
}

const getAdvisorLabel = (a: AdvisorModel) => {
  return (
    <div className="flex items-center my-1">
      <Avatar name={a.name} src={a.avatar_url} round size="20" className="mr-2" />
      <Typography fontSize="12">{a.name}</Typography>
    </div>
  )
}

const getItems = (advisors: AdvisorModel[]) => {
  return (
    advisors.map((a: AdvisorModel) => ({
      label: getAdvisorLabel(a),
      value: a.uuid,
      checked: false,
    })) || []
  )
}

const MembersTab: React.VFC<Props> = ({ onInsertMember, advisors, isAdvisorLoaded }) => {
  const [query, setQuery] = useState('')
  const [selectedAll, setSelectedAll] = useState(false)

  const [items, setItems] = useState<ChecklistItem[]>(getItems(advisors || []))

  useEffect(() => {
    const lowercaseQuery = query.toLowerCase()
    let filteredAdvisors = advisors || []

    if (lowercaseQuery) {
      filteredAdvisors = filteredAdvisors.filter(
        (a: AdvisorModel) =>
          a.name.toLowerCase().includes(lowercaseQuery) ||
          a.company_name?.toLowerCase().includes(lowercaseQuery),
      )
    }

    setItems(getItems(filteredAdvisors))
    setSelectedAll(false)
  }, [advisors, query])

  const handleInsert = () => {
    onInsertMember(items)
    const updatedItems = items.map((item) => {
      return { ...item, checked: false }
    })
    setItems(updatedItems)
    setSelectedAll(false)
  }

  const setAll = (value: boolean) => {
    const updatedItems = items.map((item) => {
      return { ...item, checked: value }
    })
    setItems(updatedItems)
    setSelectedAll(value)
  }

  const disabled = !items.find((i) => i.checked)

  if (isAdvisorLoaded && advisors?.length === 0) {
    return (
      <div className="mt-5" data-testid="composer-members-empty-state">
        <EmptyState body="No members found" />
      </div>
    )
  }

  return (
    <div className="px-1">
      <div className="mt-1">
        <SearchInput
          onChange={(e) => setQuery(e.target.value)}
          defaultValue={query}
          value={query}
          lightBg
          placeholder="Search members"
          maxLength={20}
          fontSize="12px"
          data-testid="composer-members-search-input"
        />
      </div>
      <Container className="absolute-custom-scrollbar">
        <Checklist
          className="my-1"
          items={items}
          onChange={setItems}
          maxRenderCount={25}
          isLoading={!isAdvisorLoaded}
        />
      </Container>
      <div>
        <CabalButton
          variant="primary"
          size="small"
          disabled={disabled}
          className="mr-2 mt-3"
          onClick={handleInsert}
          data-testid="composer-insert-member-btn"
        >
          Insert
        </CabalButton>
        <Typography
          fontSize="12"
          color="purple"
          onClick={() => setAll(!selectedAll)}
          className="cursor-pointer"
          data-testid="composer-member-select-all-btn"
        >
          {selectedAll ? 'Deselect All' : 'Select All'}
        </Typography>
      </div>
    </div>
  )
}

export default React.memo(MembersTab)
