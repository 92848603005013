import React, { useState } from 'react'

import { LinkedinShareButton, TwitterShareButton } from 'react-share'
import styled from 'styled-components'
import tw from 'twin.macro'
import validator from 'validator'

import CabalButton from 'global/CabalButton'
import { MultiSelect } from 'global/Input'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import copyToClipboard from 'utils/copyToClipboard'
import { ReferralModel } from 'utils/types'

import { Wrapper } from '../Home/styles'
import PreviewModal from './PreviewModal'

const Link = styled.div`
  ${tw`px-2 py-1 rounded border flex flex-grow justify-between items-center`}
  background-color: ${({ theme }) => theme.layout.main_bg_color};
  width: fit-content;
  border-color: ${({ theme }) => theme.layout.main_bg_color};
`

interface Props {
  referralCode: ReferralModel
  reloadData: () => void
}

const SendInvites = ({ referralCode, reloadData }: Props) => {
  const [emails, setEmails] = useState<string[]>([])
  const [preview, setPreview] = useState(false)

  const handleCopy = () => {
    copyToClipboard(referralCode?.url || '')
    cabalToast({ style: 'success', content: 'Link copied to clipboard' })
  }

  // if (!referralCodes) return <></>

  return (
    <>
      <Wrapper className="mt-3">
        <>
          <Typography fontSize="14" fontWeight={600} component="div" className="pb-2">
            Send invites
          </Typography>
          <div className="flex mb-4">
            <MultiSelect
              value={emails}
              options={[]}
              onChange={(v) => setEmails(v || [])}
              onCreateOption={(v) => {
                if (validator.isEmail(v)) {
                  if (emails.includes(v)) return
                  setEmails((p) => [...(p || []), v])
                } else {
                  cabalToast({ style: 'error', content: 'Please enter a valid email address' })
                }
              }}
              noOptionsMessage={() => 'Start typing email addresses'}
              placeholder={'Email Addresses'}
              creatable
              isClearable={false}
              className={'flex-grow'}
              removeBorder
              removeFocusRing
            />
            <CabalButton
              className="ml-2"
              disabled={emails === undefined || emails?.length === 0}
              onClick={() => setPreview(true)}
            >
              Preview Invite
            </CabalButton>
          </div>
        </>

        <Typography fontSize="14" fontWeight={600} component="div" className="pb-2">
          Share referral link
        </Typography>

        <div className="flex">
          <Link>
            <Typography color="rain_fog" className="pr-2">
              {referralCode?.url}
            </Typography>
            <Typography
              color="purple"
              className="cursor-pointer"
              fontSize="14"
              onClick={handleCopy}
            >
              Copy link
            </Typography>
          </Link>
          <TwitterShareButton
            url={referralCode?.url || ''}
            className="ml-2"
            title={'Referral code for Cabal'}
          >
            <CabalButton
              variant="secondary"
              leftIcon={<i className="fab fa-twitter"></i>}
              component="div"
            ></CabalButton>
          </TwitterShareButton>

          <LinkedinShareButton
            url={referralCode?.url || ''}
            title={'Referral code for Cabal'}
            summary={'Use this link to redeme the referal code'}
            className="ml-2"
          >
            <CabalButton
              variant="secondary"
              leftIcon={<i className="fab fa-linkedin"></i>}
              component="div"
            ></CabalButton>
          </LinkedinShareButton>
        </div>
      </Wrapper>

      {preview && (
        <PreviewModal
          onHide={() => setPreview(false)}
          show={preview}
          emails={emails}
          setEmails={(v) => setEmails(v)}
          referralCode={referralCode}
          reloadData={reloadData}
        />
      )}
    </>
  )
}

export default SendInvites
