import { useCallback } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectCurrentUserLoggedIn } from 'store/reducers/currentUserReducer'
import {
  loadTeam,
  loadTeams,
  selectAdvisorship,
  selectLoadingTeam,
  selectTeam,
  selectTeams,
  setLoadingTeam,
  setTeam,
  setTeams,
  unsetLoadingTeam,
} from 'store/reducers/teamsReducer'

import api, { callApi } from 'utils/api'
import { useSlug } from 'utils/hooks/useSlug'
import { CompanySlugParam, Team } from 'utils/types'

export const useLoadTeam = (slug: string) => {
  const dispatch = useAppDispatch()
  const loadingTeam = useAppSelector(selectLoadingTeam(slug))

  return async () => {
    if (loadingTeam) return

    await dispatch(
      loadTeam({
        slug,
      }),
    )
  }
}

export const useTeam = (suppliedSlug: string | null | undefined) => {
  const { teamSlug: slug } = useSlug(suppliedSlug)
  const team = useAppSelector(selectTeam(slug))
  const dispatch = useAppDispatch()

  const { refetch, isLoading } = useQuery(
    ['loadTeam', slug],
    () => {
      dispatch(setLoadingTeam(slug!))
      return callApi(api.getTeam, slug!)
    },
    {
      onSuccess: ({ company }) => {
        dispatch(setTeam(company))
        dispatch(unsetLoadingTeam(slug!))
      },
      staleTime: Infinity,
      enabled: !team && !!slug,
    },
  )

  return { team, reloadTeam: refetch, isLoading }
}

export const useUpdateTeam = (teamSlug: string) => {
  const dispatch = useAppDispatch()

  const {
    mutate: updateTeam,
    mutateAsync: updateTeamAsync,
    isLoading: isUpdatingTeam,
  } = useMutation(
    (params: { team: Partial<Team> }) =>
      callApi(api.updateTeam, teamSlug, {
        team: params.team,
      }),
    {
      onSuccess: ({ team }) => {
        dispatch(setTeam(team))
      },
    },
  )

  const handleUpdateTeam = useCallback(
    (team: Partial<Team>) => {
      return updateTeam({ team })
    },
    [updateTeam],
  )

  const handleUpdateTeamAsync = useCallback(
    (team: Partial<Team>) => {
      return updateTeamAsync({ team })
    },
    [updateTeamAsync],
  )

  return { updateTeam: handleUpdateTeam, updateTeamAsync: handleUpdateTeamAsync, isUpdatingTeam }
}

export const useLoadTeams = () => {
  const dispatch = useAppDispatch()
  const loadingTeams = useAppSelector(selectLoadingTeam('*'))

  return async () => {
    if (loadingTeams) return

    await dispatch(loadTeams())
  }
}

export const useTeams = () => {
  const loggedIn = useAppSelector(selectCurrentUserLoggedIn)
  const teams = useAppSelector(selectTeams)
  const dispatch = useAppDispatch()

  const { isLoading, refetch } = useQuery(
    ['loadTeams'],
    () => {
      dispatch(setLoadingTeam('*'))
      return callApi(api.getTeams)
    },
    {
      onSuccess: (data) => {
        dispatch(setTeams(data.companies))
        dispatch(unsetLoadingTeam('*'))
      },
      staleTime: Infinity,
      enabled: loggedIn,
    },
  )

  const hasAccessToTeams = teams.some((t) => t.permissions.canViewTeam)

  return { teams, reloadTeams: refetch, isLoading, hasAccessToTeams }
}

export const useAdvisorship = (teamSlug: string) => {
  const { reloadTeam } = useTeam(teamSlug)
  const advisor = useAppSelector(selectAdvisorship(teamSlug))

  return { advisor, reloadAdvisorship: reloadTeam }
}

export const useTeamSlug = () => {
  const { company_slug } = useParams<CompanySlugParam>()
  return company_slug
}
