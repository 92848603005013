import Loading from 'global/Loading'
import { useComposer } from 'components/Composer'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { useTeamSlug, useTeam } from 'store/hooks'
import api, { callApi } from 'utils/api'
import { AdvisorModel } from 'utils/types'

interface OfferType {
  uuid: string
  description: string
  title: string
}

const AdvisorOffer = () => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const history = useHistory()
  const { compose } = useComposer(teamSlug)
  const location = useLocation()
  const search = location.search

  const [advisorUuid, setAdvisorId] = React.useState<string | null>(null)
  const [offerUuid, setOfferId] = React.useState<string | null>(null)

  const { data: advisorOffers } = useQuery(
    [teamSlug, 'getAdvisorOffers'],
    () => callApi(api.getAdvisorOffers, teamSlug, advisorUuid),
    {
      enabled: !!advisorUuid,
    },
  )
  const getMessageBody = (offer: OfferType, advisor: AdvisorModel) => {
    return `
    Hi ${advisor.first_name},
    </br>
    Thanks for offering to help ${team?.name} with:
    </br>
    <p> ${offer.description}</p>
    </br>
    Would love to take you up on that!
    `
  }

  useEffect(() => {
    if (advisorOffers && team) {
      const offer = advisorOffers.offers.find((offer: OfferType) => offer.uuid === offerUuid)
      const advisor = advisorOffers.advisor

      const subject = 'Thanks for your offer!'
      const body = getMessageBody(offer, advisor)

      compose({
        body: body,
        subject: subject,
        recipients: {
          emails: [advisor.email],
        },
      })

      history.push(`/`)
    }
  }, [advisorOffers, offerUuid, team])

  useEffect(() => {
    const offerUuid = search?.split('&')[1]?.split('=').pop() || null
    const advisorUuid = search?.split('&')[0]?.split('=').pop() || null

    if (!offerUuid || !advisorUuid) {
      history.push(`/`)
    }

    setAdvisorId(advisorUuid)
    setOfferId(offerUuid)
  }, [search])

  return (
    <div className="my-10">
      <Loading />
    </div>
  )
}

export default AdvisorOffer
