import React, { useState } from 'react'

import { Field, FieldProps, Formik } from 'formik'
import { UseMutationResult, useMutation } from 'react-query'

import CabalButton from 'global/CabalButton'
import { CheckBoxField, MultiSelect, SelectField } from 'global/Input'
import {
  ModalInputLabel,
  ModalInputWrapper,
  ModalSectionHeader,
  ModalSectionWrapper,
} from 'global/Modal/styles'
import { useAdvisors, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { InvestorCompany } from 'utils/types/investor'

interface Props {
  onHide: () => void
  portfolioCompany: InvestorCompany
  refetchPortfolioList: () => void
  updateCompanyMutation: any
  teamSlug: string
}

const ContactsSettings: React.FC<Props> = ({
  onHide,
  portfolioCompany,
  teamSlug,
  refetchPortfolioList,
  updateCompanyMutation,
}) => {
  const { advisors } = useAdvisors({ teamSlug })
  const { team } = useTeam(teamSlug)
  const [initialValues] = useState(portfolioCompany)

  const [owners, setOwners] = useState<string[]>(portfolioCompany.owners?.map((o) => o.uuid) || [])

  const { mutate: deleteInvestorCompany, isLoading: isDeleting } = useMutation(
    ['deleteInvestorCompany', portfolioCompany.uuid],
    () =>
      callApi(api.deleteInvestorCompany, portfolioCompany.uuid, teamSlug).then(() => {
        refetchPortfolioList()
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Company successfully deleted' })
        onHide()
      },
    },
  )

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const ownerUuids: { [key: string]: boolean } = {}

          portfolioCompany.owners?.forEach((o) => {
            ownerUuids[o.uuid] = false
          })

          owners.forEach((o) => {
            ownerUuids[o] = true
          })

          updateCompanyMutation.mutate({ portfolioCompany: values, ownerUuids })
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <>
              <ModalSectionWrapper>
                <ModalSectionHeader>{team?.name}</ModalSectionHeader>
                {/* TODO: Needs backend */}

                <ModalInputWrapper>
                  <ModalInputLabel>Talent Coordinator</ModalInputLabel>
                  <Field name="talent_lead">
                    {(props: FieldProps) => (
                      <SelectField
                        placeholder="Select teammate"
                        fieldProps={props}
                        defaultValue={team?.talent_coordinator}
                        options={
                          team?.admins_and_members.map((f) => ({
                            label: f.name,
                            value: f.uuid,
                          })) || []
                        }
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
                <ModalInputWrapper>
                  <ModalInputLabel>Owners</ModalInputLabel>
                  <MultiSelect<string>
                    className="w-full"
                    placeholder="Owners"
                    data-testid="edit-company-owners-input"
                    options={
                      team?.admins_and_members.map((f) => ({
                        label: f.name,
                        value: f.uuid,
                      })) || []
                    }
                    value={owners}
                    onChange={(v) => setOwners(v)}
                    isLoading={!team?.admins_and_members}
                  />
                </ModalInputWrapper>
              </ModalSectionWrapper>
              <ModalSectionWrapper>
                <ModalSectionHeader>{portfolioCompany.company_name}</ModalSectionHeader>

                <ModalInputWrapper>
                  <ModalInputLabel>Talent Coordinator</ModalInputLabel>
                  <Field name="talent_poc.email">
                    {(props: FieldProps) => (
                      <SelectField
                        placeholder="Select talent coordinator"
                        fieldProps={props}
                        options={
                          advisors?.map((f) => ({
                            label: f.email,
                            value: f.email,
                          })) || []
                        }
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
              </ModalSectionWrapper>
            </>

            <div className="flex justify-between items-center mt-7 mb-6">
              <CabalButton
                variant="tertiary"
                disabled={isDeleting}
                padding="0"
                onClick={(e) => {
                  e.stopPropagation()
                  deleteInvestorCompany()
                }}
              >
                Delete account
              </CabalButton>
              <CabalButton type="submit" working={updateCompanyMutation.isLoading}>
                Save
              </CabalButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default ContactsSettings
