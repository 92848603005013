import axios from 'axios'

export default {
  introRequestUpdate: (
    introRequestUuid: string,
    teamSlug: string,
    amount?: number,
    status?: string,
  ) => {
    const params = {
      intro_request: {
        team_slug: teamSlug,
        ...(amount !== undefined && { amount }),
        ...(status !== undefined && { status }),
      },
    }
    return axios.put(`/api/v2/request_center/${introRequestUuid}`, params)
  },

  introRequestAddComment: (introRequestUuid: string, content: string) => {
    return axios.post(`/api/v2/intro_requests/${introRequestUuid}/comments`, { content })
  },
}
