import React, { useState } from 'react'

import { isArray } from 'lodash'
import { compose } from 'msw'
import { useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import ReactStars from 'react-stars'
import styled, { useTheme } from 'styled-components'
import tw from 'twin.macro'

import { useComposer } from 'components/Composer'
import MessageParsed from 'components/MessageParsed'
import { Divider } from 'components/SendMessage/styles'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import Typography from 'global/Typography'

import { BlockItemCardDescriptionPart, Offer, OfferButton } from 'utils/types'

import { getComposeParams } from './ResourceView'
import Review from './Review'
import VendorReviewModal from './VendorReviewModal'

const Wrapper = styled.div`
  ${tw`p-3 rounded-lg`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
`

interface Props {
  offer: Offer
  onEdit: (offer: Offer) => void
  teamSlug: string
}

const getOfferPriceLabel = (priceRange: 'expensive' | 'average' | 'low') => {
  switch (priceRange) {
    case 'expensive':
      return '$$$'
    case 'average':
      return '$$'
    case 'low':
      return '$'
  }
}

const Description = ({ description }: { description: string[] }) => {
  return (
    <>
      {description && isArray(description) && (
        <div className="flex truncate">
          {description.slice(0, -1).map((d, i) => (
            <Typography
              fontSize="12"
              lineHeight={'15px'}
              color={'fog'}
              className="truncate mr-0.5"
              key={i}
            >
              {d}
              <span className="mx-1">{'·'}</span>
            </Typography>
          ))}
          <Typography fontSize="12" lineHeight={'15px'} color={'fog'} className="truncate">
            {description.slice(-1)[0]}
          </Typography>
        </div>
      )}
    </>
  )
}

const VendorResourceView: React.FC<Props> = ({ offer, onEdit, teamSlug }) => {
  const { showModal } = useModal()
  const queryCache = useQueryClient()
  const avgRating = offer.reviews.reduce((acc, r) => acc + r.rating, 0) / offer.reviews.length
  const theme = useTheme()
  const { canEditOffers } = useAccessControl(teamSlug)
  const { compose } = useComposer(teamSlug)
  const history = useHistory()
  const location = useLocation<{ from: string }>()

  const renderButton = (b: OfferButton, i: number) => {
    if (b.hasOwnProperty('email')) {
      const params = getComposeParams(b)
      return (
        <CabalButton
          key={`offer-buttons-${offer.uuid}-${i}`}
          variant="secondary"
          size="small"
          className="mr-2"
          onClick={() => compose(params)}
        >
          {b.label}
        </CabalButton>
      )
    }
    return (
      <CabalButton
        component="a"
        href={b.url}
        key={`offer-buttons-${offer.uuid}-${i}`}
        variant="secondary"
        size="small"
        target="_blank"
        className="mr-2"
      >
        {b.label}
      </CabalButton>
    )
  }

  const renderReviewModal: RenderModal = (resolve) => (
    <VendorReviewModal
      show
      onHide={() => resolve(false)}
      reload={() => queryCache.refetchQueries('offers')}
      offer={offer}
    ></VendorReviewModal>
  )

  let description: string[] = []

  if (offer.categories) {
    description = [...offer.categories]
  }
  if (offer.price_range) {
    description.push(getOfferPriceLabel(offer.price_range))
  }
  if (offer.location) {
    description.push(offer.location)
  }

  const getBackFunc = () => {
    if (location?.state?.from === 'resources') {
      return () => history.goBack()
    } else {
      const path = location.pathname
      const formattedString = path.substring(0, path.lastIndexOf('/'))
      return () => history.push(formattedString)
    }
  }

  return (
    <div>
      <PageHeader
        data={{
          title: offer.title,
          description_parts: description.map((item) => ({ label: item })),
          avatar_url: offer.upload_uuid ? `/api/uploads/${offer.upload_uuid}` : undefined,
        }}
        onBack={getBackFunc()}
        actions={{
          others: (
            <>
              <CabalButton
                onClick={() => showModal(renderReviewModal, 'render_review_modal')}
                variant="link"
                leftIcon={<i className="far fa-plus" />}
              >
                Add Review
              </CabalButton>
              {canEditOffers && (
                <CabalButton
                  onClick={() => onEdit(offer)}
                  variant="link"
                  leftIcon={<i className="far fa-pencil" />}
                >
                  Edit
                </CabalButton>
              )}
            </>
          ),
        }}
      />
      <div className="my-6">
        <Typography fontSize="12" fontWeight={600} className="mb-1">
          Description
        </Typography>
        <Wrapper className="">
          <Typography
            className="whitespace-pre-wrap"
            fontSize="12"
            color={'fog_rain'}
            lineHeight={1.25}
            component="div"
          >
            <MessageParsed fontSize="12px" fontColor="fog_rain" body={offer.body} />
          </Typography>

          {offer.buttons.length !== 0 && (
            <div className="mt-3">{offer.buttons.map((b, i) => renderButton(b, i))}</div>
          )}
        </Wrapper>
      </div>
      <div className="my-6">
        <Typography fontSize="12" fontWeight={600} className="mb-1">
          Contact
        </Typography>
        <Wrapper>
          <Typography className="mb-1" fontSize="12" lineHeight={1.25} component="div">
            {offer.contact_name}
          </Typography>
          <Typography fontSize="12" color={'purple'} lineHeight={1.5} component="div">
            {offer.phone_number}
          </Typography>
          {offer.email && (
            <a href={`mailto:${offer.email}`} rel="noreferrer" target="_blank">
              <Typography fontSize="12" component="p" lineHeight={1.5} color={'purple'}>
                {offer.email}
              </Typography>
            </a>
          )}

          {offer.website && (
            <>
              <Typography fontSize="12" lineHeight={1.25} className="mt-4" component="div">
                Website
              </Typography>
              <a href={offer.website} rel="noreferrer" target="_blank">
                <Typography fontSize="12" color={'purple'} lineHeight={1.5} component="div">
                  {offer.website}
                </Typography>
              </a>
            </>
          )}
        </Wrapper>
      </div>
      <div className="my-6">
        <Typography fontSize="12" fontWeight={600} className="mb-1">
          Reviews
        </Typography>
        <Wrapper>
          <div className="flex items-center mb-3">
            <ReactStars
              edit={false}
              count={5}
              value={avgRating}
              color2={theme.colors.star_select}
              color1={theme.side_nav.bg_color}
            />
            <Typography fontSize="12" color={'fog'} className="pl-1">
              {`${offer.reviews.length} reviews`}
            </Typography>
          </div>

          {offer.reviews.map((r, idx) => (
            <>
              <Review
                key={idx}
                email={r.creator_user.email}
                rating={r.rating}
                created_at={r.created_at}
                description={r.description}
              />
              {idx !== offer.reviews.length - 1 && <Divider />}
            </>
          ))}
        </Wrapper>
      </div>
    </div>
  )
}

export default VendorResourceView
