import React, { useState } from 'react'

import { useMutation } from 'react-query'

import { IIntroRequest } from 'containers/RequestCenter/types/ITypes'
import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

interface Props {
  onHide: () => void
  introRequestData: IIntroRequest
  refetch: () => void
}

const AddNoteModal = ({ onHide, introRequestData, refetch }: Props) => {
  const { intro_request: introRequest } = introRequestData
  const [comment, setComment] = useState('')

  const addCommentMutation = useMutation(
    () => callApi(api.introRequestAddComment, introRequest.secret_uuid, comment),
    {
      onSuccess: () => {
        refetch()
        cabalToast({ content: 'Comment added', style: 'success' })
        onHide()
      },
    },
  )

  return (
    <Modal
      show
      header={`Add Comment`}
      onHide={onHide}
      rightActions={<CabalButton onClick={() => addCommentMutation.mutate()}>Add</CabalButton>}
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Comment</ModalInputLabel>
          <TextArea
            value={comment}
            className="w-full"
            rows={3}
            onChange={(e) => setComment(e.target.value)}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default AddNoteModal
