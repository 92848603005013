import * as React from 'react'
import Typography from 'global/Typography'
import { useParams } from 'react-router-dom'
import { UserProfile } from 'utils/types'
import api from 'utils/api'
import CabalTitle from 'global/Title'

const WildcardPath: React.FC = () => {
  const [profile, setProfile] = React.useState<UserProfile>()
  const { id } = useParams<{ id: string }>()

  React.useEffect(() => {
    api.getProfile(id).then(({ data: profile }) => setProfile(profile))
  }, [id])

  if (!profile) {
    return <div />
  }

  return (
    <div className="p-5 text-white m-auto mt-0 pt-10" style={{ width: 600 }}>
      <CabalTitle title={profile.name} />
      <div className="clear-both overflow-hidden mb-8">
        {profile.avatar_url && (
          <img
            src={profile.avatar_url}
            style={{
              float: 'left',
              width: 50,
              height: 50,
              marginTop: 3,
              borderRadius: 50,
              marginRight: 5,
            }}
            className="inline"
          />
        )}
        <div
          style={{
            marginTop: profile.avatar_url ? 12.5 : 0,
            marginLeft: 10,
            fontSize: 20,
            float: 'left',
          }}
        >
          {profile.first_name} {profile.last_name}
        </div>
      </div>
      {profile.companies.map((company) => (
        <div className="mb-4" key={company.slug}>
          <div className="overflow-hidden">
            {company.logo_url && (
              <img
                src={company.logo_url}
                style={{
                  float: 'left',
                  width: 25,
                  height: 25,
                  marginTop: 3,
                  borderRadius: 50,
                  marginRight: 5,
                }}
                className="inline"
              />
            )}
            <div
              style={{
                marginTop: company.logo_url ? 2.5 : 0,
                marginLeft: 10,
                fontSize: 18,
                float: 'left',
              }}
            >
              {company.name}
            </div>
          </div>
          <div className="p-3" style={{ paddingLeft: 42 }}>
            {company.jobs.map((job) => (
              <div key={job.uuid}>
                <div className="flex justify-between">
                  <div>
                    <a href={job.url} rel={'noreferrer'} target="_blank">
                      <Typography lineHeight={'0.9375rem'} fontWeight={700} color="message_sender">
                        {job.title}
                      </Typography>
                    </a>
                  </div>
                </div>
                {/* <Typography lineHeight={'1.3125rem'} fontSize="12" color="message_sender">
                  {job.description}
                </Typography> */}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default WildcardPath
