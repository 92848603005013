import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useAccessControl } from 'global/AccessControl'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  loadGroup,
  resetGroups,
  selectGroup,
  selectGroups,
  selectLoadingGroup,
  setGroup,
  setLoadingGroups,
  unsetLoadingGroups,
} from 'store/reducers/groupsReducer'

import api, { callApi } from 'utils/api'

export const useLoadGroup = (teamSlug: string, uuid: string) => {
  const dispatch = useAppDispatch()
  const loadingGroup = useAppSelector(selectLoadingGroup(uuid))

  return async () => {
    if (loadingGroup) return

    await dispatch(
      loadGroup({
        team_slug: teamSlug,
        uuid,
      }),
    )
  }
}

export const useGroup = (teamSlug: string, uuid?: string) => {
  const dispatch = useAppDispatch()
  const group = useAppSelector(selectGroup(uuid))
  const { canViewGroups } = useAccessControl(teamSlug)

  const { refetch } = useQuery(
    ['loadGroup', teamSlug, uuid],
    () => {
      dispatch(setLoadingGroups(uuid!))
      return callApi(api.getGroup, teamSlug, uuid!)
    },
    {
      onSuccess: ({ group }) => {
        dispatch(setGroup(group))
        dispatch(unsetLoadingGroups(uuid!))
      },
      staleTime: Infinity,
      enabled: !group && canViewGroups && !!uuid,
    },
  )

  return { group, reloadGroup: refetch }
}

export const useGroups = (teamSlug: string | undefined, includeAllGroup = true) => {
  const dispatch = useAppDispatch()
  const { canViewGroups } = useAccessControl(teamSlug)
  const groups = useAppSelector(selectGroups(teamSlug))

  const { refetch, isLoading } = useQuery(
    ['loadGroups', teamSlug],
    () => {
      if (!canViewGroups) throw 'cant see groups'

      dispatch(setLoadingGroups(teamSlug!))
      return callApi(api.getGroups, {
        team_slug: teamSlug,
      })
    },
    {
      onSuccess: ({ groups }) => {
        dispatch(resetGroups(groups))
        dispatch(unsetLoadingGroups(teamSlug!))
      },
      staleTime: Infinity,
      enabled: !!teamSlug && groups.length === 0 && !!canViewGroups,
    },
  )

  const filteredGroups = useMemo(() => {
    if (includeAllGroup) return groups
    return groups.filter((g) => g.name !== 'All')
  }, [groups])

  return { groups: filteredGroups, reloadGroups: refetch, isLoading }
}
