import React, { useState } from 'react'

import { sanitize } from 'dompurify'

import { SeeMore } from 'containers/CandidateProfile'
import { LEVEL_MAP, ROLE_FUNCTION_MAP } from 'containers/ListIndexView/constants'
import Pill from 'global/Pill'
import Typography from 'global/Typography'
import PillV2 from 'ui-components/PillV2'
import Widget from 'ui-components/Widget'

import { CandidateProfile } from 'utils/types'

interface Props {
  description?: string | React.ReactNode
}

const DetailPageAboutSection: React.FC<Props> = ({ description }) => {
  return (
    <Widget title="About">
      {description && (
        <Typography component="div" className="ql-output" color="fog" fontSize="12">
          {description}
        </Typography>
      )}
    </Widget>
  )
}

export default DetailPageAboutSection
