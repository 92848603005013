import { sortBy } from 'lodash'
import React, { useState } from 'react'
import Expand from 'react-expand-animated'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import TitleWrappers from 'components/Composer/Sidebar/TitleWrappers'
import TimeAgo from 'components/TimeAgo'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { EmailRecipientValue, MessageRecipient, Team } from 'utils/types'
import { formatNum } from 'utils/ui'

interface Props {
  request_uuid: string
  team: Team
  messageRecipients: MessageRecipient[]
}

const AdvisorData = styled.div`
  display: grid;
  grid-template-columns: 30px 130px 120px 30px;
  width: fit-content;
  @media (max-width: 1360px) {
    grid-template-columns: 30px 150px 30px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 30px 30px;
  }
`

const HideSmall = styled(Typography)`
  @media (max-width: 1360px) {
    display: none;
  }
`

const HideSmaller = styled(Typography)`
  @media (max-width: 1000px) {
    display: none;
  }
`

const AdvisorRow = styled(AdvisorData)`
  ${tw`border rounded-md p-1`}
  border-color: ${({ theme }) => theme.colors.border};
`

const RequestInfo: React.VFC<Props> = ({ request_uuid, team, messageRecipients }) => {
  const [toggle, setToggle] = useState<boolean>(false)

  const currentRecipientUuids = React.useMemo(
    () => messageRecipients?.map((r) => r.value),
    [messageRecipients],
  )

  const { data: getRequestData } = useQuery(['getRequest', team.slug, request_uuid], () =>
    callApi(api.getRequest, team.slug, request_uuid),
  )
  const request = getRequestData?.request

  if (!request || (!request?.company && !request.person)) {
    return <div />
  }

  const { from_user, company, person, advisors } = request
  const sortedAdvisors = sortBy(
    advisors?.filter((a) => {
      return currentRecipientUuids?.includes(a.uuid) || currentRecipientUuids?.includes(a.user_uuid)
    }),
    (a) => {
      return -a.request_count
    },
  )

  const totalCount = sortedAdvisors.reduce((acc, a) => (acc += parseInt(a.request_count)), 0)

  let itemType = 'Company'

  switch (company?.item_type) {
    case 'fundraise':
      itemType = 'Investor'
    case 'persona':
      itemType = 'Persona'
    case 'job':
      itemType = 'Job'
  }

  return (
    <div className="text-sm">
      <b>Intro request to:</b>
      {person && (
        <ul className="mt-4">
          <li>
            <a href={person.linkedin_url} target="_blank" rel="noreferrer">
              {person.name}
              <i className="fab fa-linkedin ml-2" />
            </a>
          </li>
          <li>{person.headline}</li>
        </ul>
      )}

      {company && (
        <>
          <ul className="mt-4 mb-4">
            <li>
              <span className="text-secondary">{`${itemType}: `}</span>
              {company.name}
            </li>
            {company.stage_name && (
              <li>
                <span className="text-secondary">Stage: </span>
                {company.stage_name}
              </li>
            )}
            {company.amount && (
              <li>
                <span className="text-secondary">Amount: </span>${formatNum(company.amount, 2)}
              </li>
            )}
            {company.owner_email && (
              <li>
                <span className="text-secondary">Owner: </span>
                {company.owner_email}
              </li>
            )}
          </ul>

          <TitleWrappers
            title={`Request History (${totalCount})`}
            onClick={() => setToggle(!toggle)}
            active={toggle}
          />
          <Expand open={toggle}>
            <AdvisorData>
              <div></div>
              <HideSmall className="ml-2" fontSize="12">
                Name
              </HideSmall>
              <HideSmaller className="ml-2" fontSize="12">
                Last Request
              </HideSmaller>
              <Typography className="ml-2" fontSize="12">
                Total
              </Typography>
            </AdvisorData>
            {sortedAdvisors?.map((a) => (
              <AdvisorRow key={a.uuid}>
                <Avatar name={a.name} src={a?.avatar_url} size="20" round className="mr-2" />
                <HideSmall className="ml-2" fontSize="12">
                  {a.name}
                </HideSmall>
                <HideSmaller className="ml-2" fontSize="12">
                  {a.last_request_at && <TimeAgo datetime={a.last_request_at} />}
                </HideSmaller>
                <Typography className="ml-2" fontSize="12">
                  {a.request_count}
                </Typography>
              </AdvisorRow>
            ))}
          </Expand>
        </>
      )}
    </div>
  )
}

export default React.memo(RequestInfo)
