import React, { JSXElementConstructor, ReactElement } from 'react'

import { Menu, MenuItem, MenuItemProps, MenuProps } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

import cx from 'classnames'
import styled from 'styled-components'
import tw from 'twin.macro'

const MenuItemWrapper = styled.div`
  ${tw`flex`}
`

const StyledMenuItem = styled(MenuItem)`
  ${tw`flex-1 rounded`}

  padding: 0.375em 0.75em !important;
  font-size: 14px;
  cursor: ${({ onClick }) => (onClick ? `pointer` : 'default')} !important;

  &:hover {
    background-color: ${({ theme, onClick }) =>
      onClick ? theme.buttons.primary.bg_color : theme.colors.shadow};
    color: ${({ theme }) => theme.colors.white};
  }
`

const StyledMenu = styled(Menu)`
  ${tw`shadow-md border rounded`}
  border: 0;
  z-index: 1001;

  ul {
    ${tw`p-1 overflow-auto`}

    max-height: 75vh;
    background-color: ${({ theme }) => theme.colors.shadow};
    color: ${({ theme }) => theme.colors.primary};
  }
`

const Divider = styled.div`
  border-bottom: ${({ theme }) => theme.border};
`

type SideAction = {
  icon: React.ReactNode
  onClick: () => void
}

export type DropdownMenuItem =
  | {
      label: React.ReactNode
      onSelect?: MenuItemProps['onClick']
      sideAction?: SideAction | React.ReactNode
      dataTestId?: string
      divider?: never
    }
  | {
      divider: boolean
    }

export interface DropDownProps extends Omit<MenuProps, 'menuButton'> {
  trigger: ReactElement<any, string | JSXElementConstructor<any>>
  menuItems: (DropdownMenuItem | null)[]
  menuLinks?: {
    label: React.ReactNode
    href: string
    target?: string
    dataTestId?: string
  }[]
  disabled?: boolean
  needScrollIntoView?: boolean
  fullWidth?: boolean
  dataTestId?: string
}

const DropDownMenu: React.FC<DropDownProps> = ({
  trigger,
  menuItems,
  menuLinks,
  disabled = false,
  fullWidth = false,
  portal = { target: document.body },
  dataTestId,
  ...restProps
}) => {
  if (process.env.NODE_ENV === 'test') {
    portal = false
  }

  menuItems = menuItems.filter((item) => !!item)

  return (
    <StyledMenu
      data-testid={dataTestId}
      menuButton={(props) => (
        <button
          className={cx({ 'w-full': fullWidth }, restProps.className)}
          {...props}
          onClick={(e) => {
            e.stopPropagation()
            props.onClick?.(e)
          }}
        >
          {React.cloneElement(trigger as React.ReactElement, {
            disabled: disabled,
            className: cx(trigger.props.className, { 'cursor-pointer': !disabled }),
            component: 'span',
          })}
        </button>
      )}
      portal={portal}
      unmountOnClose
      {...restProps}
      menuStyle={{ position: 'absolute' }}
    >
      {menuItems.map((m, i) => {
        if (!m) return <></>

        if (m.divider) {
          return <Divider className="my-1" key={`dropdown-menu-item-${i}`} />
        } else if ('label' in m) {
          return (
            <MenuItemWrapper key={`dropdown-menu-item-${i}`} data-testid={m?.dataTestId}>
              <StyledMenuItem onClick={m?.onSelect}>{m?.label}</StyledMenuItem>
              {m?.sideAction}
            </MenuItemWrapper>
          )
        }
      })}
      {menuLinks?.map((m, i) => (
        <StyledMenuItem key={`dropdown-menu-link-${i}`} data-testid={m?.dataTestId} href={m.href}>
          {m?.label}
        </StyledMenuItem>
      ))}
    </StyledMenu>
  )
}

export default DropDownMenu
