import axios from 'axios'

import { EmailTemplate, UpsertEmailTemplateRequest } from 'utils/types'

export default {
  getTemplates: (teamSlug: string, public_to_team?: boolean) => {
    return axios.get<{ templates: EmailTemplate[] }>(`/api/email_templates`, {
      params: { team_slug: teamSlug, public_to_team },
    })
  },

  getTemplate: (teamSlug: string, uuid: string) => {
    return axios.get<{ template: EmailTemplate }>(`/api/email_templates/${uuid}`, {
      params: { team_slug: teamSlug },
    })
  },

  createTemplate: (params: UpsertEmailTemplateRequest) => {
    return axios.post<{ template: EmailTemplate }>(`/api/email_templates`, {
      email_template: params,
    })
  },

  updateTemplate: (params: UpsertEmailTemplateRequest) => {
    return axios.put<{ template: EmailTemplate }>(`/api/email_templates/${params.uuid}`, {
      email_template: params,
    })
  },

  deleteTemplate: (uuid: string) => {
    return axios.delete(`/api/email_templates/${uuid}`)
  },

  getDefaultTemplate: (
    team_slug: string,
    default_for:
      | 'default_intro_template'
      | 'member_intro_template'
      | 'member_invite_template'
      | 'intro_template',
    extraParams?: {
      global_person_uuid?: string
      company_uuid?: string
    },
  ) => {
    return axios.get<{ template: EmailTemplate }>(`/api/email_templates/default_template`, {
      params: {
        team_slug,
        default_for,
        ...extraParams,
      },
    })
  },
}
