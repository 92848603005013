import * as React from 'react'
import { useState, useEffect } from 'react'
import { TableEl, Th, Tr } from 'global/Table'
import axios from 'axios'
import { cabalToast } from 'ui-components/Toast'
import styled from 'styled-components'
import { debounce } from 'lodash'
import { useParams } from 'react-router-dom'
import tw from 'twin.macro'

import Button from 'global/Button'
import { SearchInput } from 'global/Input'
import ConnectionsEmptyState from 'components/EmptyStates/ConnectionsEmptyState'
import { CompanySlugParam } from 'utils/types'

import Typography from 'global/Typography'
import { MessageListHeader } from 'components/MessageList/styles'
import { useSearchParam } from 'react-use'
import { useCurrentUser } from 'store/hooks'
import CabalTitle from 'global/Title'

interface Props {
  history: any
  location: any
}

interface PersonModel {
  name: string
  title: string
}

interface ConnectionModel {
  uuid: string
  advisor: any
  person: PersonModel
}

let apiCall = null

const Connections: React.FC<Props> = (props) => {
  const { user } = useCurrentUser()
  const query_string = useSearchParam('q')
  const defaultQuery = query_string && query_string != '' ? query_string : ''
  const { company_slug } = useParams<CompanySlugParam>()

  const [conns, setConns] = useState([])
  const [query, setQuery] = useState(defaultQuery)
  const [loading, setLoading] = useState(false)
  const [requestIntroConn, setRequestIntroConn] = useState(null)

  const [salesforceButtonDisabled, setSalesforceButtonDisabled] = useState(false)
  const [favoritesOnly, setFavoritesOnly] = useState(false)

  function requestIntro(conn) {
    setRequestIntroConn(conn)
  }

  apiCall = debounce(() => {
    axios
      .get('/api/connections', {
        params: {
          q: query,
          team_slug: company_slug,
          favorites_only: favoritesOnly,
        },
      })
      .then((response) => {
        const { connections } = response.data
        setConns(connections)
        setLoading(false)
      })
  }, 500)

  useEffect(() => {
    setLoading(true)

    if (apiCall) {
      apiCall.cancel()
    }

    apiCall()
  }, [query, favoritesOnly])

  function onSyncSalesforce(e: React.SyntheticEvent) {
    e.preventDefault()

    setSalesforceButtonDisabled(true)

    axios.post('/api/connections/sync_sfdc').then((response) => {
      const { status } = response.data

      if (status == 'started') {
        cabalToast({
          style: 'success',
          content: 'Salesforce is syncing. Please refresh in 30 seconds.',
        })

        setTimeout(() => setSalesforceButtonDisabled(false), 30000)
      } else {
        cabalToast({ style: 'error', content: 'There was an error.' })
      }
    })
  }

  function onToggleFavorite(conn: ConnectionModel) {
    const params = {
      type: 'Connection',
      id: conn.uuid,
    }

    axios.post('/api/favorites', params).then((response) => {
      console.log(response.data)
    })
  }

  return (
    <div className="mx-8">
      <CabalTitle title={'Apps'} />
      <MessageListHeader className="mb-3">
        <Typography fontSize="24" fontWeight={600}>
          Apps
        </Typography>
      </MessageListHeader>
      <div className="mx-3"></div>
    </div>
  )
}

export default Connections
