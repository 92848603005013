import React from 'react'

import { useHistory } from 'react-router-dom'

import PortfolioDataTags from 'containers/CompanyPage/SharedListsSection/PortfolioDataTags'
import { CreatePortfolioListModal } from 'containers/IntroRequestModal/components/Modals/Portfolio/CreatePortfolioListModal'
import { usePortfolioListContext } from 'containers/ListIndexView/PortfolioList'
import { IPortfolioCompany } from 'containers/ListIndexView/PortfolioList/types'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import { useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import GridWrapper from 'ui-components/GridWrapper'

import { cn } from 'utils/styles'

const PortfolioItem = ({
  company,
  view,
  mockMemberView,
}: {
  company: IPortfolioCompany
  view: 'grid' | 'list'
  mockMemberView: boolean
}) => {
  const history = useHistory()
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { user } = useCurrentUser()

  const { companyListData: companyList } = usePortfolioListContext()
  const companyListSlug = companyList.slug

  const { showModal } = useModal()

  const openRequestIntroModal = () => {
    showModal(
      (resolve) => (
        <CreatePortfolioListModal
          requestable={company}
          requestor={user}
          initiator={user}
          companyList={companyList}
          resolve={resolve}
          facilitator={companyList?.owning_team}
        />
      ),
      'create-portfolio-intro-modal',
    )
  }

  const requestCTA = companyList.cta_type === 'request_intro'

  const gridView = view === 'grid'

  return (
    <DataCardV2
      onClick={() => history.push(`/${teamSlug}/lists/${companyListSlug}/${company.uuid}`)}
      key={company.uuid}
      title={company.name}
      avatar={
        <Avatar
          src={
            company?.logo_url ? company?.logo_url : `https://logo.clearbit.com/${company.domain}`
          }
          name={company.name}
          size={gridView ? '72' : '32'}
        />
      }
      description={!gridView && company.description}
      descriptionWithoutTruncate={<p className="line-clamp-3 mb-2">{company.description}</p>}
      cta={
        requestCTA ? (
          <CabalButton
            onClick={() => openRequestIntroModal()}
            variant={gridView ? 'primary' : 'link'}
            className="w-full"
          >
            Request Intro
          </CabalButton>
        ) : (
          <CabalButton
            onClick={() => history.push(`/${teamSlug}/lists/${companyListSlug}/${company.uuid}`)}
            variant={gridView ? 'primary' : 'link'}
            className="w-full"
          >
            View
          </CabalButton>
        )
      }
      view={view}
      gridPillsSection={<PortfolioDataTags company={company} />}
      className={cn({
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded':
          !gridView,
      })}
    />
  )
}

const PortfolioListMemberView = ({ mockMemberView }: { mockMemberView: boolean }) => {
  const { portfolioCompaniesList, view } = usePortfolioListContext()

  const companyRow = (view: 'grid' | 'list') => {
    return (
      <>
        {portfolioCompaniesList.map((company) => (
          <PortfolioItem
            key={company.uuid}
            company={company}
            view={view}
            mockMemberView={mockMemberView}
          />
        ))}
      </>
    )
  }

  return (
    <div>
      {view === 'grid' && <GridWrapper>{companyRow('grid')}</GridWrapper>}
      {view === 'list' && <>{companyRow('list')}</>}
    </div>
  )
}

export default PortfolioListMemberView
