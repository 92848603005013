import * as React from 'react'

import Typography from 'global/Typography'

function Layout({ children, onHome }: React.PropsWithChildren<any>) {
  // const showHeader = window.MOBILE_APP

  return <>{children}</>
}

export default Layout
