import React from 'react'

import { Layout } from './Layout'

export function FaSpinner() {
  return (
    <Layout>
      <div className="flex flex-col items-center mt-[33vh] justify-center gap-12">
        <i className="fa-solid fa-spinner fa-spin text-green-100 fa-2xl"></i>
      </div>
    </Layout>
  )
}
