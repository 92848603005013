import React, { useState } from 'react'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Card from 'global/Card'
import { MenuItem } from 'global/DropDownMenu'
import Pill from 'global/Pill'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { DataCard } from 'ui-components/DataCard'

import { InvestorCompany } from 'utils/types/investor'

export type RequestIntroState = 'ready' | 'in_progress' | 'requested'

interface Props {
  company: InvestorCompany
  requestIntroState: { [key: string]: RequestIntroState }
  openRequestIntroModal: (companyUuid: string, companyName: string, companyLogo: string) => void
  companyUuid: string
}

const CompanyCard: React.VFC<Props> = ({
  company,
  requestIntroState,
  openRequestIntroModal,
  companyUuid,
}) => {
  const [viewMore, setViewMore] = useState(false)

  let descriptionText = <>{company.description}</>

  if (company.description?.length > 150) {
    if (viewMore) {
      descriptionText = (
        <>
          {company.description}&nbsp;
          <Typography
            fontSize="12"
            color="purple"
            className="underline"
            onClick={() => setViewMore(!viewMore)}
          >
            View less
          </Typography>
        </>
      )
    } else {
      descriptionText = (
        <>
          {company.description.slice(0, 150)}...
          <Typography
            fontSize="12"
            color="purple"
            className="underline"
            onClick={() => setViewMore(!viewMore)}
          >
            View more
          </Typography>
        </>
      )
    }
  }

  const menuItems: MenuItem[] = []

  if (company.data?.twitter_url) {
    menuItems.push({
      label: 'Twitter',
      onSelect: () => window.open(company.data?.twitter_url),
      dataTestId: 'portfolio-member-view-twitter-url',
    })
  }

  if (company.data?.linkedin_url || company.linkedin_url) {
    menuItems.push({
      onSelect: () =>
        window.open(company.data?.linkedin_url) ||
        window.open(`http://www.${company.linkedin_url}`),
      label: 'LinkedIn',
      dataTestId: 'portfolio-member-view-linkedin-url',
    })
  }

  if (company.domain) {
    menuItems.push({
      onSelect: () => window.open(`http://www.${company.domain}`),
      label: 'Company website',
      dataTestId: 'portfolio-member-view-company-links',
    })
  }

  return (
    <>
      {/* <Card key={company.uuid} className="flex justify-between items-center cursor-pointer">
        <div className="flex">
          <div data-testid="portfolio-member-view-company-logo">
            <Avatar
              name={company.company_name}
              src={company.logo_url}
              round
              size="32"
              className="mr-4"
            />
          </div>
          <div>
            <Typography fontWeight={600} fontSize="16" className="mr-8">
              {company.company_name}
            </Typography>
            {company.description && (
              <Typography color="fog_rain" component="div" fontSize="12" className="max-w-md">
                {descriptionText}
              </Typography>
            )}
            <div className="my-1" data-testid="portfolio-member-view-company-tags">
              {company.tags.map((t) => (
                <Pill variant="purple" border className="mr-2" key={t}>
                  {t}
                </Pill>
              ))}
            </div>
            <div data-testid="portfolio-member-view-company-links">
              {(company.data?.linkedin_url || company.linkedin_url) && (
                <Typography color={'fog_rain'} fontSize="14">
                  <a
                    href={company.data?.linkedin_url || `http://www.${company.linkedin_url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin mr-3"></i>
                  </a>
                </Typography>
              )}
              {company.data?.twitter_url && (
                <Typography color={'fog_rain'} fontSize="14">
                  <a href={company.data?.twitter_url} target="_blank" rel="noreferrer">
                    <i className="fab fa-twitter mr-3"></i>
                  </a>
                </Typography>
              )}
              {company.domain && (
                <Typography color={'fog_rain'} fontSize="14">
                  <a href={`http://www.${company.domain}`} target="_blank" rel="noreferrer">
                    <i className="fas fa-globe mr-3"></i>
                  </a>
                </Typography>
              )}
            </div>
          </div>
        </div>
        <div>
          {requestIntroState[company.uuid] === 'requested' ? (
            <CabalButton variant="secondary" disabled data-testid="talent-intro-requested-btn">
              Requested
            </CabalButton>
          ) : (
            <CabalButton
              onClick={() =>
                openRequestIntroModal(companyUuid, company.company_name, company.logo_url)
              }
              working={requestIntroState[company.uuid] === 'in_progress'}
              data-testid="open-request-talent-intro-modal-btn"
            >
              Request Intro
            </CabalButton>
          )}
        </div>
      </Card> */}
      <DataCard
        title={company.company_name}
        dataCardTestId={'portfolio-company-row'}
        avatarName={company.company_name}
        avatarUrl={company.logo_url}
        tags={company.tags}
        description={
          <Tooltip
            interactive
            label={company.description}
            placement="auto-start"
            className="truncate"
          >
            {company.description}
          </Tooltip>
        }
        secondaryCTA={
          requestIntroState[company.uuid] !== 'requested'
            ? {
                text: 'Request Intro',
                dataTestId: 'open-request-talent-intro-modal-btn',
                onClick: () =>
                  openRequestIntroModal(companyUuid, company.company_name, company.logo_url),
              }
            : undefined
        }
        status={
          requestIntroState[company.uuid] === 'requested'
            ? { text: 'Requested', color: 'yellow', dataTestId: 'talent-intro-requested-btn' }
            : undefined
        }
        menuItems={menuItems}
      />
    </>
  )
}

export default CompanyCard
