import { compact } from 'lodash'
import { SetRequired } from 'type-fest'

import { PersonBlueprint } from 'utils/types'

import { NewMessageProps, useComposer } from './'

export interface ComposeDealBoardDraftProps extends NewMessageProps {
  teamSlug: string
  user_uuid: string
  person?: SetRequired<Partial<PersonBlueprint>, 'id'>
  company_id?: number | string
}

export type ComposeDealBoardDraft = (props: ComposeDealBoardDraftProps) => void

export const useComposeDealBoardDraft = () => {
  const { compose } = useComposer()

  const composeDealBoardDraft: ComposeDealBoardDraft = ({
    teamSlug,
    user_uuid,
    person,
    company_id,
    ...props
  }) => {
    const recipients = props?.recipients
    compose({
      team_slug: teamSlug,
      sender_uuid: user_uuid,
      draft_on_behalf: true,
      person_id: person?.id || person?.uuid,
      company_id: company_id,
      ready: false,
      recipients: recipients || {
        emails: compact([person?.email]),
      },
      ...props,
    })
  }

  return { composeDealBoardDraft }
}
