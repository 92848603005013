import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import React from 'react'

const InfoTooltip: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <Tooltip interactive label={children}>
      <Typography color={'purple'} component="div" {...props}>
        <i className="far fa-info-circle"></i>
      </Typography>
    </Tooltip>
  )
}

export default InfoTooltip
