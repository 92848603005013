import React, { useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import MessageParsed from 'components/MessageParsed'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { AdvisorModel, CompanySlugParam } from 'utils/types'

import NotesModal from './NotesModal'

interface NoteModel {
  body: string
  created_at: Date
  id: number
  notable_id: number
  notable_type: string
  updated_at: Date
}

interface Props {
  advisor: AdvisorModel
}

const AdvisorNotes = ({ advisor }: Props) => {
  const { company_slug } = useParams<CompanySlugParam>()
  const [noteId, setNoteId] = useState(0)
  const [update, setUpdate] = useState(false)
  const [body, setBody] = useState('')
  const [showUpdateModal, setShowUpdateModal] = useState(false)

  const { data, refetch } = useQuery([company_slug, 'advisorNotes'], () =>
    callApi(api.getNotes, advisor.uuid),
  )

  const handleDelete = useMutation(
    ({ note_id }: { note_id: number }) =>
      callApi(api.deleteNote, advisor.uuid, note_id).then(() => refetch()),
    {
      onSuccess: () => {
        setBody('')
        setUpdate(false)
        setNoteId(0)
      },
    },
  )

  const handleUpdate = (id: number, body: string) => {
    setUpdate(true)
    setNoteId(id)
    setBody(body)
    setShowUpdateModal(true)
  }

  return (
    <>
      {showUpdateModal && (
        <NotesModal
          show
          onHide={() => setShowUpdateModal(false)}
          advisor={advisor}
          refetch={refetch}
          update={update}
          noteId={noteId}
          content={body}
        />
      )}
      {data?.notes?.length === 0 && (
        <div>
          <EmptyState
            icon={<i className="fa-solid fa-file-circle-xmark"></i>}
            heading="No Notes"
            body={
              <>
                <Typography textAlign="center" component="h5" className="px-2">
                  {`Member notes are only visible to your team`}
                </Typography>
              </>
            }
          />
          <div className="flex flex-col items-center">
            <CabalButton onClick={() => setShowUpdateModal(true)}>New Note</CabalButton>
          </div>
        </div>
      )}
      {data?.notes?.map((note: NoteModel) => (
        <>
          <div className="px-6 py-3  bg-primary text-primary" key={note.id}>
            <MessageParsed resources={true} body={note.body} />
            <div className="flex mt-4 justify-end">
              <CabalButton
                variant="destructive"
                working={handleDelete.isLoading}
                onClick={() => handleDelete.mutate({ note_id: note.id })}
                className="mr-2"
              >
                Delete
              </CabalButton>
              <CabalButton variant="secondary" onClick={() => handleUpdate(note.id, note.body)}>
                Update
              </CabalButton>
            </div>
          </div>
        </>
      ))}
    </>
  )
}

export default AdvisorNotes
