import { darken, transparentize } from 'polished'
import { createGlobalStyle, css } from 'styled-components'
import tw from 'twin.macro'

const SIZES = {
  '0': '0',
  xs: '.25',
  sm: '.5',
  sm05: '.75',
  base: '1',
  base005: '1.125',
  base05: '1.25',
  md: '1.5',
  md005: '1.75',
  md05: '2',
  md5: '2.25',
  lg: '2.5',
  lg005: '2.75',
  lg05: '3',
  xl: '3.5',
  xl05: '4',
  xxl: '5',
  xxl05: '5.5',
  xxl5: '5.75',
  xxxl: '6',
  auto: 'auto',
}

const DIRECTIONS = {
  t: 'top',
  l: 'left',
  b: 'bottom',
  r: 'right',
}

const ATTRS = {
  margin: 'm',
  padding: 'p',
}

/**
 * Component to generate a set of atomic helper classes, usable app-wide,
 * to apply pre-defined margin or padding to a component. This allows us to avoid
 * passing in-line style props to them, and lets us decouple our components from their
 * spacing.
 *
 * Basic usage (in pseudo-ts):
 *
 * .(m | p) (t | r | b | l)?-(xs | sm | base | medium | large | xl | xxl)
 *
 * Adding one or more of those to the className prop of our components will add the
 * specified amount of padding and/or margin.
 *
 * So, for example, adding clasName="mt-xs mb-lg" to a component will add .5rem of margin
 * to the top of the node, and 2.5 rem of margin to the bottom. Adding "m-base" will add
 * 1rem of margin to each side of the node, and so on.
 */

const CssHelperClasses = createGlobalStyle`
  ${Object.entries(SIZES).map(
    ([size, value]) => css`
      ${Object.entries(DIRECTIONS).map(
        ([dir, direction]) => css`
          ${Object.entries(ATTRS).map(([attr, className]) => {
            let cssString = ''
            if (ATTRS.margin === className) {
              cssString += `
                .${className}${dir}-${size}-neg {
                  ${attr}-${direction}: -${value}rem;
                }
              `
            }

            cssString += `
              .${className}${dir}-${size} {
                ${attr}-${direction}: ${value}rem;
              }`

            return css`
              ${cssString}
            `
          })}
        `,
      )}
      .m-${size} {
        margin: ${value}rem;
      }
      .p-${size} {
        padding: ${value}rem;
      }
      .px-${size} {
        padding-left: ${value}rem;
        padding-right: ${value}rem;
      }
      .py-${size} {
        padding-bottom: ${value}rem;
        padding-top: ${value}rem;
      }
      .mx-${size} {
        margin-left: ${value}rem;
        margin-right: ${value}rem;
      }
      .my-${size} {
        margin-bottom: ${value}rem;
        margin-top: ${value}rem;
      }

      .m-${size}-neg {
        margin: -${value}rem;
      }
      .mx-${size}-neg {
        margin-left: -${value}rem;
        margin-right: -${value}rem;
      }
      .my-${size}-neg {
        margin-bottom: -${value}rem;
        margin-top: -${value}rem;
      }
    `,
  )}
  }
  .input-h-md {
    height: 3.125rem !important;
  }

  .input-h-md {
    height: 3rem !important;
  }
  .row-h-lg {
    height: 4rem !important;
  }

  .input-h-lg {
    height: 4.125rem !important;
  }

  .text-primary {
    color: ${({ theme }) => theme.colors.primary}
  }
  .text-secondary {
    color: ${({ theme }) => theme.colors.secondary}
  }
  .bg-primary {
    background-color: ${({ theme }) => theme.colors.primary_bg}
  }
  .border-primary {
    border-color: ${({ theme }) => theme.colors.border};
  }
  .border-secondary {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
  .color-green {
    color: ${({ theme }) => theme.colors.green};
  }

  .ck-editor .variable {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.purple};
    background: ${({ theme }) => transparentize(0.8, theme.colors.purple)};
  }

  :root {
    --ck-color-base-background: ${({ theme }) => theme.layout.main_bg_color};
    --ck-color-base-foreground: ${({ theme }) => theme.layout.main_bg_color};
    --ck-color-base-border: ${({ theme }) => theme.colors.border};
    --ck-color-focus-border: transparent;
    --ck-color-base-text: ${({ theme }) => theme.colors.primary};
    --ck-border-radius: 8px;
    --ck-color-button-default-hover-background: ${({ theme }) => theme.colors.tint};
    --ck-color-button-default-active-background: ${({ theme }) => theme.colors.primary_bg};
    --ck-color-button-on-hover-background: ${({ theme }) => theme.colors.tint};
    --ck-color-button-on-active-background: ${({ theme }) => theme.colors.primary_bg};
    --ck-color-split-button-hover-background: ${({ theme }) => theme.colors.primary_bg};
    --ck-color-button-on-background: ${({ theme }) => theme.colors.tint};

    --ck-color-list-button-on-background: ${({ theme }) => theme.colors.tint};
    --ck-color-list-button-on-background-focus: ${({ theme }) => darken(0.1, theme.colors.tint)};
    --ck-color-list-button-on-text: ${({ theme }) => theme.colors.secondary};

    --ck-color-widget-editable-focus-background: ${({ theme }) => theme.colors.add_bg};

    --ck-color-comment-marker: ${({ theme }) => theme.colors.yellow};
    --ck-color-comment-marker-active: ${({ theme }) => theme.colors.yellow_bold};

    --ck-color-widget-blurred-border: ${({ theme }) => theme.colors.yellow};
    --ck-color-widget-type-around-button-blurred-editable: ${({ theme }) => theme.colors.yellow};

    --ck-color-widget-type-around-button-active: ${({ theme }) => theme.colors.yellow_bold};
    --ck-color-focus-border: ${({ theme }) => theme.colors.yellow_bold};

    --ck-font-size-small: 10px;
    --ck-font-size-normal: 13px;
    --ck-font-size-big: 18px;
    --ck-font-size-large: 32px;

    --ck-icon-size: 18px;

    --ck-inner-shadow: none;

    --cabal-background-color: ${({ theme }) => theme.layout.main_bg_color};

    color-scheme: ${({ theme }) => theme.name};
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: ${({ theme }) => theme.colors.placeholder};
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: ${({ theme }) => theme.colors.placeholder};
    opacity: 1;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: ${({ theme }) => theme.colors.placeholder};
  }
  :-moz-placeholder { /* Firefox 18- */
    color: ${({ theme }) => theme.colors.placeholder};
    opacity: 1;
  }

  #root {
    background: ${({ theme }) => theme.layout.main_bg_color};
  }

  .tippy-box {
    background-color: ${({ theme }) => theme.colors.cardBackground} !important;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15));
    color: ${({ theme }) => theme.colors.primary} !important;

    &.dark {
      filter: drop-shadow(0px 0px 6px #111);
    }
  
    .tippy-arrow {
      color: ${({ theme }) => theme.colors.cardBackground} !important;
    }
  }

  ${() => window.QA && `i { padding: 3px; }`}
`

export default CssHelperClasses
