import axios from 'axios'

import { Pagination, VoteType } from 'utils/types'
import { VoteBlueprint, VoteTrackerStage } from 'utils/types/vote'

export default {
  createVote: (params: {
    team_slug: string
    votable_type?: 'GlobalPerson' | 'Company'
    votable_id?: number | string
    vote: VoteType
    company_id?: number | null
    company_uuid?: string | null
    new_person?: {
      name: string
      linkedin_url: string
    }
    notes?: string
    ssid?: string
    list_uuid?: string
  }) => {
    return axios.post('/api/votes', {
      ...params,
    })
  },

  getVotes: (
    team_slug: string,
    stage: VoteTrackerStage,
    page: number,
    personId?: number,
    companyId?: number,
    filters?: { [key: string]: string | number | boolean },
  ) => {
    return axios.get<{ votes: VoteBlueprint[]; pagination: Pagination }>(`/api/votes`, {
      params: {
        team_slug,
        votable_type: personId ? 'GlobalPerson' : companyId ? 'Company' : null,
        votable_id: personId || companyId,
        stage,
        page,
        filters,
      },
    })
  },

  archiveVote: (team_slug: string, vote_uuid: string) => {
    return axios.delete(`/api/votes/${vote_uuid}`, {
      params: {
        team_slug,
      },
    })
  },

  updateVote: (team_slug: string, vote_uuid: string, update: { stage: VoteTrackerStage }) => {
    return axios.put(`/api/votes/${vote_uuid}`, {
      team_slug,
      ...update,
    })
  },
}
