import React from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { AppliedFilterPill, FilterPill, renderFilters } from 'components/Filters'
import { usePeopleListContext } from 'containers/ListIndexView/TalentList/PeopleList'
import {
  COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS,
  LEVEL_OPTIONS,
  OFFICE_WITH_OPEN_TO_ALL_OPTIONS,
  OPPORTUNITY_TYPE_OPTIONS,
  ROLE_FUNCTION_OPTIONS,
  STAGE_WITH_OPEN_TO_ALL_OPTIONS,
  STATUS_OPTIONS,
} from 'containers/ListIndexView/constants'
import { useAccessControl } from 'global/AccessControl'
import { CheckBox } from 'global/Input'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import Switch from 'ui-components/Switch'

import { cn } from 'utils/styles'

export const TALENT_FILTERS = [
  {
    key: 'query',
    type: 'search',
    icon: 'far fa-search',
    labelStr: 'Search',
    label: 'Search',
    skipLabelInAppliedPill: true,
    noPopover: true,
  },
  {
    key: 'function',
    type: 'dropdown[]',
    label: 'Function',
    options: ROLE_FUNCTION_OPTIONS,
  },
  {
    key: 'level',
    type: 'dropdown[]',
    label: 'Level',
    options: LEVEL_OPTIONS,
  },
  {
    key: 'location',
    type: 'location[]',
    label: 'Location',
  },
  {
    key: 'stage_preference',
    type: 'dropdown[]',
    label: 'Stage',
    options: STAGE_WITH_OPEN_TO_ALL_OPTIONS,
  },
  {
    key: 'opportunity_type',
    type: 'dropdown[]',
    label: 'Job type',
    options: OPPORTUNITY_TYPE_OPTIONS,
  },
  {
    key: 'ideal_company_size',
    type: 'dropdown[]',
    label: 'Size',
    options: COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS,
  },
  {
    key: 'office_preference',
    type: 'dropdown[]',
    label: 'Office',
    options: OFFICE_WITH_OPEN_TO_ALL_OPTIONS,
  },
]

const STATUS_FILTER = {
  key: 'status',
  type: 'dropdown',
  label: 'Status',
  options: STATUS_OPTIONS,
}

interface Props {
  disabled: boolean
  setSelectedItems: (items: any) => void
  talentFiltersValue: any
  setTalentFiltersValue: (value: any) => void
  selectedItems: any[]
}

const PeopleListFilters = ({
  disabled,
  setSelectedItems,
  talentFiltersValue,
  setTalentFiltersValue,
  selectedItems,
}: Props) => {
  const teamSlug = useTeamSlug()

  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const { talentPeople, adminView: peopleAdminView, view, setView } = usePeopleListContext()

  const adminView = isAdminOrEmployee

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: [...TALENT_FILTERS, adminView && STATUS_FILTER].filter(Boolean),
    value: talentFiltersValue as {
      query?: string
      function?: string[]
      level?: string[]
      location?: string[]
      function_preference?: string[]
      level_preference?: string[]
      stage_preference?: string[]
      opportunity_type?: string[]
      ideal_company_size?: string[]
      office_preference?: string[]
      member_view?: boolean
      status?: string
    },
    onChange: (newValues) => {
      setTalentFiltersValue(newValues)
    },
  })

  const clearFilters = () => {
    setTalentFiltersValue({
      query: undefined,
      function: undefined,
      level: undefined,
      location: undefined,
      function_preference: undefined,
      level_preference: undefined,
      stage_preference: undefined,
      opportunity_type: undefined,
      ideal_company_size: undefined,
      office_preference: undefined,
      member_view: undefined,
      status: undefined,
    })
  }

  return (
    <div
      ref={parent}
      className={cn('flex-col w-full my-3', {
        'pointer-events-none opacity-50': disabled,
        'px-2.5': adminView,
      })}
    >
      <div className="flex flex-col sm:flex-row justify-between items-start">
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-wrap gap-2.5">
            {peopleAdminView && (
              <CheckBox
                checked={selectedItems.length === talentPeople.length}
                onChange={(e) => {
                  setSelectedItems(e.target.checked ? talentPeople : [])
                  if (e.target.checked && view === 'grid') {
                    setView('list')
                  }
                }}
              />
            )}
            {renderedFilters}
          </div>
          {adminView && (
            <div className="flex items-center gap-2">
              <Typography fontSize="12" color="fog">
                Member View
              </Typography>
              <Switch
                checked={talentFiltersValue.member_view}
                onCheckedChange={(e) =>
                  setTalentFiltersValue({ ...talentFiltersValue, member_view: e })
                }
              />
            </div>
          )}
        </div>
      </div>
      {appliedFilters.length > 0 && (
        <div className="flex justify-between items-center">
          <div className="flex gap-1 flex-wrap mt-2">{appliedFilters}</div>

          <Typography
            fontSize="12"
            color="fog"
            className="cursor-pointer"
            onClick={() => clearFilters()}
          >
            <i className="far fa-filter-slash mr-1"></i>
            Clear all
          </Typography>
        </div>
      )}
    </div>
  )
}

export default PeopleListFilters
