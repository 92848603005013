import { useCallback } from 'react'

import axios, { AxiosError } from 'axios'
import jstz from 'jstz'
import isEqual from 'lodash/isEqual'
import { useMutation, useQuery } from 'react-query'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  loadCurrentUser,
  loadCurrentUserSettings,
  selectCurrentUser,
  selectCurrentUserGmailAuthorizedEmails,
  selectCurrentUserLoaded,
  selectCurrentUserLoading,
  selectCurrentUserLoggedIn,
  selectCurrentUserSettingLoading,
  selectCurrentUserSettings,
  selectCurrentUserSettingsLoaded,
  setCurrentUser,
  setCurrentUserSettings,
  setLoadingSettings,
  setLoadingUser,
  updateCurrentUser,
} from 'store/reducers/currentUserReducer'

import api, { callApi } from 'utils/api'
import { CurrentUserProfile, CurrentUserSettings, Params } from 'utils/types'

export const useLoadCurrentUser = () => {
  const dispatch = useAppDispatch()
  const loadingUser = useAppSelector(selectCurrentUserLoading)

  return async () => {
    if (loadingUser) return

    await dispatch(loadCurrentUser())
  }
}

type UpdateUserMutationProps =
  | { key: keyof CurrentUserProfile; value: any }
  | { profile: Partial<CurrentUserProfile>; params: Params }

type UpdateUser =
  | (<T extends keyof CurrentUserProfile>(key: T, value: CurrentUserProfile[T]) => Promise<any>)
  | ((profile: Partial<CurrentUserProfile>, params: Params) => Promise<any>)

export const useCurrentUser = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectCurrentUser)
  const userLoaded = useAppSelector(selectCurrentUserLoaded)
  const isLoggedIn = useAppSelector(selectCurrentUserLoggedIn)

  const updateUserMutation = useMutation(
    (props: UpdateUserMutationProps) => {
      if ('key' in props) {
        return callApi(api.updateCurrentProfile, { [props.key]: props.value })
      }

      return callApi(api.updateCurrentProfile, props.profile, props.params)
    },
    {
      onSuccess: (data, props) => {
        dispatch(updateCurrentUser(data.profile))
      },
    },
  )

  const { refetch, isLoading, isRefetching } = useQuery(
    ['getCurrentProfile'],
    async () => {
      if (!userLoaded) dispatch(setLoadingUser(true))

      // const windowUser = window.USER

      // if (!!windowUser) {
      //   // is logged in and is the first call of the hook
      //   window.USER = undefined
      //   return windowUser
      // }

      // if (windowUser === undefined) {
      //   // windowUser was there, removed by the block above
      return await callApi(api.getCurrentProfile)
      //}

      // windowUser === null; not logged in
      throw { response: { status: 404 } } as AxiosError
    },
    {
      onSuccess: (data) => {
        dispatch(setCurrentUser(data))
        const tz = jstz.determine().name()
        if (data.time_zone !== tz && !window.II) {
          updateUserMutation.mutate({ key: 'time_zone', value: tz })
        }
      },
      retry: (fc, err: AxiosError) => {
        if (
          err?.response?.status === 404 ||
          (err instanceof axios.Cancel && err.message.startsWith('NO_JWT'))
        ) {
          dispatch(setLoadingUser(false))
          dispatch(setLoadingSettings(false))

          return false
        }
        return fc < 3
      },
      staleTime: Infinity,
      enabled: !userLoaded,
    },
  )

  const updateUser = useCallback<UpdateUser>((a, b) => {
    if (typeof a !== 'object') {
      return updateUserMutation.mutateAsync({ key: a, value: b })
    }

    return updateUserMutation.mutateAsync({ profile: a, params: b })
  }, [])

  return {
    user,
    isUserLoaded: userLoaded,
    isLoading,
    isRefetching,
    isLoggedIn,
    reloadUser: refetch,
    updateUser,
    updatingUser: updateUserMutation.isLoading,
  }
}

export const useLoadCurrentUserSettings = () => {
  const dispatch = useAppDispatch()
  const loadingSettings = useAppSelector(selectCurrentUserSettingLoading)

  return async () => {
    if (loadingSettings) return

    await dispatch(loadCurrentUserSettings())
  }
}

export const useCurrentUserGmailAuthorizedEmails = () => {
  const gmailAuthorizedEmails = useAppSelector(selectCurrentUserGmailAuthorizedEmails)
  return { gmailAuthorizedEmails }
}

export const useCurrentUserSettings = () => {
  const { user } = useCurrentUser()
  const dispatch = useAppDispatch()
  const settings = useAppSelector(selectCurrentUserSettings)
  const settingsLoaded = useAppSelector(selectCurrentUserSettingsLoaded)

  const { refetch } = useQuery(
    ['getUserSettings'],
    () => {
      if (!settingsLoaded) dispatch(setLoadingSettings(true))
      return callApi(api.getUserSettings)
    },
    {
      onSuccess: (data) => {
        dispatch(setCurrentUserSettings(data.settings))
        dispatch(setLoadingSettings(false))
      },
      enabled: !settingsLoaded && !!user && !!Object.keys(user).length,
    },
  )

  const updateSetting = (name: keyof CurrentUserSettings, value: any) => {
    if (isEqual(settings[name], value)) return
    api.updateUserSetting(name, value).then(({ data }) => {
      dispatch(setCurrentUserSettings(data.settings))
    })
  }

  return { settings, updateSetting, settingsLoaded, reloadSettings: refetch }
}
