import Popover from 'components/Popover'
import Avatar from 'global/Avatar'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { capitalize, isEmpty } from 'lodash'
import React from 'react'
import { InboxMessageBlueprint } from 'utils/types'

const personKeys: (keyof Pick<InboxMessageBlueprint, 'to' | 'bcc' | 'cc'>)[] = ['to', 'cc', 'bcc']

interface Props {
  message: InboxMessageBlueprint
}

const InboxMessageHeader: React.VFC<Props> = ({ message }) => {
  const abbrRecipients: React.ReactNode[] = personKeys.flatMap((key) => {
    return message[key]?.flatMap((person) => {
      return (
        <span key={message.uuid + key + person.email}>
          <Tooltip label={person.email}>{person.name || person.email}</Tooltip>
        </span>
      )
    })
  })

  const verboseRecipients: React.ReactNode[] = personKeys.flatMap((key) => {
    if (isEmpty(message[key])) return

    return (
      <div key={message.uuid + key + 'verbose'}>
        {capitalize(key)}:{' '}
        {message[key]?.map((person) => `${person.name} (${person.email})`).join(', ')}
      </div>
    )
  })

  return (
    <Typography color="secondary" fontSize="14" component="div">
      <div>
        {message.from?.map((person) => {
          return (
            <div key={person.email}>
              <Avatar src={person.avatar_url} name={person.name} size={'22px'} className="mr-2" />
              {person.name} ({person.email})
            </div>
          )
        })}
      </div>
      <Popover
        trigger={
          <div className="mt-1.5">
            to: {abbrRecipients.reduce((prev, curr) => [prev, <>, </>, curr], [])}{' '}
            <button>
              <i className="fas fa-caret-down" />
            </button>
          </div>
        }
      >
        <Typography color="primary" component="div">
          {verboseRecipients}
        </Typography>
      </Popover>
    </Typography>
  )
}

export default InboxMessageHeader
