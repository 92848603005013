import React from 'react'

import cx from 'classnames'
import styled from 'styled-components'

import { CompanyListBlueprint, StyleProps } from 'utils/types'

const StyledIcon = styled.i<{ color: string }>`
  color: ${({ color }) => color};
`

interface Props extends StyleProps {
  crm: CompanyListBlueprint['provider']
}

const crmIconToAdd = (crm: string | null): string => {
  switch (crm) {
    case 'salesforce':
      return 'fab fa-salesforce'
    case 'hubspot':
      return 'fab fa-hubspot'
    case 'affinity':
      return 'affinity-icon-12'
    default:
      return ''
  }
}

const CrmIcon: React.FC<Props> = ({ crm, className, style }) => {
  if (!crm || !['salesforce', 'hubspot', 'affinity'].includes(crm)) return null

  return (
    <StyledIcon
      className={cx(crmIconToAdd(crm), className)}
      style={style}
      color={crm === 'salesforce' ? '#1798c1' : crm === 'hubspot' ? '#ff7a59' : ''}
    />
  )
}

export default CrmIcon
