import * as React from 'react'

import IntegrationCard from './IntegrationCard'
import gcontact from '../../../../assets/images/gcontact.png'
import { useCurrentUser } from 'store/hooks'
import GoogleAuthModal from 'components/GoogleAuthModal'
import { ModalContext, RenderModal } from 'global/Modal/Context'
import CabalButton from 'global/CabalButton'
import { useMutation } from 'react-query'
import api, { callApi } from 'utils/api'
import { DataCard } from 'ui-components/DataCard'

const GcontactIntegrationCard: React.FC = () => {
  const { showModal } = React.useContext(ModalContext)
  const {
    user: { has_synced_gcontacts },
  } = useCurrentUser()

  const { mutate: syncContacts, isLoading } = useMutation(['syncContacts'], () =>
    callApi(api.syncContacts),
  )

  const renderGcontactModal: RenderModal = (resolve) => (
    <GoogleAuthModal
      show
      service="gcontacts"
      onHide={() => resolve(false)}
      onAuthorize={() => resolve()}
    />
  )

  return (
    <>
      {/* <IntegrationCard
        connected={has_synced_gcontacts}
        img={gcontact}
        name={'Google Contacts'}
        description={
          <>Send messages to your contacts directly from Cabal or add them as Members.</>
        }
        ctaText={has_synced_gcontacts ? 'Reauthorize' : 'Authorize'}
        icon={has_synced_gcontacts ? 'reload' : 'add'}
        onCtaClick={() => showModal(renderGcontactModal, 'authorize-gcontact')}
        secondaryCta={
          has_synced_gcontacts ? (
            <CabalButton
              className="ml-2"
              size="small"
              leftIcon={<i className="fas fa-sync" />}
              variant="tertiary"
              tooltip="Sync Contacts"
              working={isLoading}
              onClick={() => syncContacts()}
            />
          ) : null
        }
      /> */}
      <DataCard
        title="Google Contacts"
        description={'Send messages to your contacts directly from Cabal or add them as Members'}
        avatarUrl={gcontact}
        secondaryCTA={{
          text: has_synced_gcontacts ? 'Reauthorize' : 'Authorize',
          onClick: () =>
            has_synced_gcontacts
              ? syncContacts()
              : showModal(renderGcontactModal, 'authorize-gcontact'),
        }}
      />
    </>
  )
}

export default GcontactIntegrationCard
