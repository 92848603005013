import React, { useMemo, useState } from 'react'

import Skeleton from 'react-loading-skeleton'
import { useMutation, useQuery } from 'react-query'

import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import AddPersonModal from 'containers/ListIndexView/SalesList/SalesCompanyList/SalesCompanyAddWidget/AddPersonModal'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import { useModal } from 'global/Modal'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import { GlobalCompanyBlueprint } from 'utils/types'

interface Props {
  setOnClose: () => void
}

function AddCompanyRow(props: {
  company: GlobalCompanyBlueprint
  logoUrl: string
  onClose: () => void
  refetchCompanyList: () => void
  companyListUuid: any
}) {
  const { mutate: addCompany, isLoading: isAdding } = useMutation(
    (company_uuid: string) =>
      callApi(api.addElementToSalesList, props.companyListUuid, { company_uuid }),
    {
      onSuccess: (data) => {
        props.refetchCompanyList()
        props.onClose()
        cabalToast({
          content: `${data.name} added successfully!`,
          style: 'success',
        })
      },
    },
  )

  return (
    <DataCardV2
      title={props.company.name}
      description={props.company.domain}
      avatar={<Avatar name={props.company.name} src={props.logoUrl + props.company.domain} />}
      cta={
        <CabalButton
          onClick={() => {
            addCompany(props.company.uuid)
          }}
          variant="link"
          working={isAdding}
        >
          Add
        </CabalButton>
      }
    />
  )
}

const AddIndividualSection: React.FC<Props> = ({ setOnClose }) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const { showModal } = useModal()
  const { companyListData: companyList, refetchCompanyList } = useSalesListContext()
  const logo_url = 'https://logo.clearbit.com/'
  const { isLoading: isLoadingQuery, data } = useQuery(
    ['getPeople', debouncedQuery],
    () => callApi(api.getSearchResults, debouncedQuery, ['global_companies']),
    {
      keepPreviousData: true,
      enabled: !!debouncedQuery,
    },
  )
  const isLoading = useMemo(
    () => isLoadingQuery || debouncedQuery !== query,
    [isLoadingQuery, debouncedQuery, query],
  )

  const results = useMemo(
    () =>
      (data?.results?.filter((r) => r.type === 'GlobalCompany') || []) as GlobalCompanyBlueprint[],
    [data],
  )

  return (
    <div className="mt-4">
      <SearchInput
        onChange={(c) => setQuery(c.target.value)}
        placeholder={`Search for companies`}
        value={query}
        autoFocus
      />

      {debouncedQuery !== '' && (
        <>
          <div className="my-4 px-3">
            <CabalButton
              variant="link"
              onClick={() => {
                showModal(
                  (resolve) => (
                    <AddPersonModal
                      onHide={resolve}
                      refetch={refetchCompanyList}
                      listUuid={companyList.uuid}
                      name={debouncedQuery}
                    />
                  ),
                  'edit-portfolio-company-modal',
                )
                setOnClose()
              }}
              leftIcon={<i className="far fa-plus fa-xs" />}
              padding="0"
              size="large"
            >
              <span>{`Add company "${debouncedQuery}"`}</span>
            </CabalButton>
          </div>
          {isLoading && (
            <div className="">
              {Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className="flex ml-1 gap-2 py-2">
                  <Skeleton className="w-8 h-8" circle={true} />
                  <div className="flex flex-col w-full">
                    <Skeleton count={2} containerClassName="flex-1" />
                  </div>
                </div>
              ))}
            </div>
          )}
          {results &&
            results.map((company, index) => (
              <AddCompanyRow
                key={index}
                company={company}
                logoUrl={logo_url}
                companyListUuid={companyList.uuid}
                refetchCompanyList={refetchCompanyList}
                onClose={setOnClose}
              />
            ))}
        </>
      )}
    </div>
  )
}

export default AddIndividualSection
