import React from 'react'

import compact from 'lodash/compact'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'
import { v4 as uuidv4 } from 'uuid'

import useCrmStatus from 'components/CrmStatus'
import { MultiSelect } from 'global/Input'
import Loading from 'global/Loading'
import { ModalInputLabel, ModalInputWrapper, ModalSectionHeader } from 'global/Modal/styles'
import Typography from 'global/Typography'
import InfoTooltip from 'ui-components/InfoTooltip'

import api, { callApi } from 'utils/api'
import { updateAt } from 'utils/array'
import { CompanyListFilter } from 'utils/types'

import { EditListType } from '..'
import FieldFilter from './FieldFilter'

interface Props {
  teamSlug: string
  list: EditListType
  setList: (list: Partial<EditListType>) => void
}

const Divider = styled.div`
  ${tw`my-2`}
  border-bottom: ${({ theme }) => theme.border};
`

const DynamicListFilters: React.VFC<Props> = ({ teamSlug, list, setList }) => {
  const { hubspotConnected, salesforceConnected } = useCrmStatus(teamSlug)

  const { data: salesPeopleEmailOptions } = useQuery(['owner_email', teamSlug], () =>
    callApi(api.getOwnerEmails, teamSlug),
  )

  const { data: stageNameOptions } = useQuery(['stage_name', teamSlug], () =>
    callApi(api.getStageNames, teamSlug),
  )

  const { data: pipelineOptions } = useQuery(
    ['pipeline_names', teamSlug],
    () => callApi(api.getPipelineNames, teamSlug),
    {
      enabled: !!hubspotConnected,
    },
  )

  const { isFetching: isLoadingSfdcFields, data: getSfdcFieldsData } = useQuery(
    ['getSfdcFields', teamSlug],
    () => callApi(api.getSfdcFields),
    {
      enabled: !!salesforceConnected,
    },
  )
  const sfdcFieldOptions = getSfdcFieldsData?.sfdc_fields || []

  const { isFetching: isLoadingHubspotFields, data: getHubspotFieldsData } = useQuery(
    ['getHubspotFields'],
    () => callApi(api.getHubspotFields),
    {
      enabled: !!hubspotConnected,
    },
  )
  const hubspotFieldOptions = getHubspotFieldsData?.hubspot_fields || []

  const showCrmFieldOptions = !!sfdcFieldOptions.length || !!hubspotFieldOptions.length
  const listFilters = list.company_list_filters

  const filteredStageOptions = () => {
    const pipelineFilterSet = (list?.filters?.pipeline_names?.length || 0) > 0
    const stagesLoaded = (stageNameOptions?.stages?.length || 0) > 0

    const stages =
      stagesLoaded && pipelineFilterSet
        ? stageNameOptions?.stages?.filter((stage) => {
            return list?.filters?.pipeline_names?.includes(stage.pipeline)
          }) || []
        : stageNameOptions?.stages || []

    return stages.map((stage) => ({
      label: stage.id,
      value: stage.name,
    }))
  }

  return (
    <>
      <ModalSectionHeader>
        <div>
          List Filters
          <InfoTooltip className="ml-2">
            <Typography fontWeight={400}>Filters are cumulative. They work with an AND.</Typography>
          </InfoTooltip>
        </div>
      </ModalSectionHeader>

      <ModalInputWrapper>
        <ModalInputLabel>Owner</ModalInputLabel>
        <MultiSelect
          value={list.filters?.owner_emails}
          onChange={(v) => setList({ filters: { ...list.filters, owner_emails: v } })}
          className="w-full"
          placeholder="Owner"
          isClearable
          data-testid="select-dynamic-list-owner-email"
          compactPlaceholder
          options={
            salesPeopleEmailOptions?.emails?.map((email) => ({
              label: email,
              value: email,
            })) || []
          }
        />
      </ModalInputWrapper>

      {hubspotConnected && (
        <ModalInputWrapper>
          <ModalInputLabel>Pipeline</ModalInputLabel>
          <MultiSelect
            value={list.filters?.pipeline_names}
            onChange={(v) => setList({ filters: { ...list.filters, pipeline_names: v } })}
            className="w-full"
            placeholder="Pipeline"
            isClearable
            compactPlaceholder
            options={
              pipelineOptions?.pipelines.map((pipeline) => ({
                label: pipeline,
                value: pipeline,
              })) || []
            }
          />
        </ModalInputWrapper>
      )}

      <ModalInputWrapper>
        <ModalInputLabel>Stage</ModalInputLabel>
        <MultiSelect
          value={list.filters?.stage_names}
          onChange={(v) => setList({ filters: { ...list.filters, stage_names: v } })}
          className="w-full"
          placeholder="Stage"
          isClearable
          compactPlaceholder
          options={filteredStageOptions()}
        />
      </ModalInputWrapper>

      {(isLoadingHubspotFields || isLoadingSfdcFields) && <Loading size={24} />}

      {showCrmFieldOptions && (
        <ModalInputWrapper className="py-1">
          <ModalInputLabel>Field filters</ModalInputLabel>
          <div className="w-full">
            {listFilters?.map((fieldFilter, index) => (
              <div key={`${fieldFilter.uuid}-${index}`} className="w-full">
                <FieldFilter
                  fieldFilter={fieldFilter}
                  setFieldFilter={(fieldFilter) =>
                    setList({ company_list_filters: updateAt(listFilters, index, fieldFilter) })
                  }
                  sfdcFieldOptions={sfdcFieldOptions}
                  hubspotFieldOptions={hubspotFieldOptions}
                  removeFieldFilter={() =>
                    setList({
                      company_list_filters: compact(updateAt(listFilters, index, undefined)),
                    })
                  }
                />
                {index < listFilters.length - 1 ? (
                  <div className="flex items-center justify-between my-2">
                    <Divider className="w-full" />
                    <Typography className="mx-3" fontSize="12" color={'fog'}>
                      AND
                    </Typography>
                    <Divider className="w-full" />
                  </div>
                ) : (
                  <Divider />
                )}
              </div>
            ))}
            <Typography
              component="p"
              color={'purple'}
              fontSize="12"
              className="cursor-pointer"
              onClick={() =>
                setList({
                  company_list_filters: [
                    ...(listFilters || []),
                    {
                      uuid: uuidv4(),
                      operator: '=',
                      filter_value: '',
                      filterable: undefined,
                    } as any as CompanyListFilter,
                  ],
                })
              }
            >
              + Add field filters
            </Typography>
          </div>
        </ModalInputWrapper>
      )}
    </>
  )
}

export default DynamicListFilters
