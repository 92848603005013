import React from 'react'

import { useQuery } from 'react-query'

import ListSharingSettings from 'components/EditList/EditListAccess/ListSharingSettings'
import CTAs from 'containers/ListIndexView/Components/CTAs'
import { useJobsListContext } from 'containers/ListIndexView/TalentList/JobsList'
import JobsEditOrAddModal from 'containers/ListIndexView/TalentList/JobsList/JobsEditOrAddModal'
import TalentSettingsModal from 'containers/ListIndexView/TalentList/TalentSettingsModal'
import { useAccessControl } from 'global/AccessControl'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { InvestorCompany } from 'utils/types/investor'

interface Props {
  onShowAddWidget: () => void
}

interface CTA_OPTION {
  label: React.ReactNode
  onClick: () => void
}

const JobsListCTAs: React.FC<Props> = ({ onShowAddWidget }) => {
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()
  const { companyListData, view, setView } = useJobsListContext()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const adminView = !!isAdminOrEmployee && !!companyListData.owned
  const memberView = !isAdminOrEmployee

  const { companyListData: companyList, refetchCompanyList } = useJobsListContext()

  const { data } = useQuery(
    ['memberPortfolioCompany', teamSlug],
    () => callApi(api.memberPortfolioCompany, teamSlug),
    {
      enabled: memberView,
    },
  )

  const memberPortfolioCompany = data?.investor_company

  const renderSettingsModal: RenderModal = (resolve) => (
    <TalentSettingsModal
      teamSlug={teamSlug}
      onHide={() => resolve(false)}
      companyList={companyList}
      reload={refetchCompanyList}
    />
  )

  const showAddJobModal = (memberPortfolioCompany: InvestorCompany) => {
    showModal(
      (resolve) => (
        <JobsEditOrAddModal
          company={memberPortfolioCompany}
          onHide={resolve}
          teamSlug={teamSlug}
          refetchListItems={() => {}}
          listUuids={[]}
          currentListUuid={companyList.uuid}
          showListDropdown={false}
          memberAdding={memberView}
        />
      ),
      'edit-job-modal',
    )
  }

  const renderShareListModal: RenderModal = (resolve) => (
    <ListSharingSettings
      teamSlug={teamSlug}
      resolve={() => resolve(false)}
      uuid={companyList?.uuid}
      reload={refetchCompanyList}
      header={`Share ${companyList?.name}`}
    />
  )

  const options = [
    memberView &&
      !!memberPortfolioCompany && {
        label: (
          <Typography className="flex gap-2 items-center">
            <i className="far fa-plus" />
            Submit a Job
          </Typography>
        ),
        onClick: () => showAddJobModal(memberPortfolioCompany),
      },
    adminView && {
      label: (
        <Typography className="flex gap-2 items-center">
          <i className="far fa-plus" />
          Add
        </Typography>
      ),
      onClick: () => onShowAddWidget(),
    },
    {
      label: (
        <Typography className="flex gap-2 items-center">
          {view === 'list' ? (
            <>
              <i className="far fa-grid" />
              Grid
            </>
          ) : (
            <>
              <i className="far fa-list" />
              List
            </>
          )}
        </Typography>
      ),
      onClick: () => setView(view === 'list' ? 'grid' : 'list'),
    },
    adminView && {
      label: (
        <Typography className="flex gap-2 items-center">
          <i className="far fa-arrow-up-from-bracket" />
          Share
        </Typography>
      ),
      onClick: () => showModal(renderShareListModal, 'render_share_list_modal'),
    },
    adminView && {
      label: (
        <Typography className="flex gap-2 items-center">
          <i className="far fa-gear" />
        </Typography>
      ),
      onClick: () => showModal(renderSettingsModal, 'render_talent_settings_modal'),
    },
  ]

  return <CTAs CTAOptions={options.filter(Boolean) as CTA_OPTION[]} />
}

export default JobsListCTAs
