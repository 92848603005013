import React, { useEffect, useMemo, useState } from 'react'

import compact from 'lodash/compact'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import { ArrayParam } from 'use-query-params'

import CompanyCard from 'components/CompanyHome/CompanyCard'
import { EditListModal } from 'components/EditList'
import ListSharingSettings from 'components/EditList/EditListAccess/ListSharingSettings'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import ListSharedModal from 'containers/MemberAddList/ListSharedModal'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import { RenderModal, useModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useCurrentUserSettings, useTeamSlug } from 'store/hooks'
import GridWrapper from 'ui-components/GridWrapper'

import api, { callApi } from 'utils/api'
import { useQueryParamsWithSettings } from 'utils/hooks/useQueryParamsWithSettings'
import { CompaniesFiltersType, CompanySlugParam, Team } from 'utils/types'

import ItemRow from './ItemRow'

interface Props {
  listUuid?: string
}

const ListViewOther: React.FC<Props> = (props) => {
  const v2_indexed_api = true
  const { listUuid } = props
  const { showModal } = useModal()
  const { state: locationState } = useLocation<{ shared_with: Team } | undefined>()
  const { company_slug, list_id: listSlug } = useParams<CompanySlugParam & { list_id: string }>()
  const companyParam = useSearchParam('company')
  const listParam = useSearchParam('list')
  const teamSlug = useTeamSlug()
  const { isAdminOrEmployee } = useAccessControl(company_slug)
  const [showResultsLoading, setShowResultsLoading] = useState(true)
  const history = useHistory()
  const { settings, updateSetting } = useCurrentUserSettings()

  const [view, setView] = useState<'list' | 'grid'>( 'list')

  const defaultFilter: CompaniesFiltersType = {}


  if (!!companyParam) {
    defaultFilter.search = [decodeURI(companyParam)]
  }

  const queryParamConfigMap = useMemo(() => {
    return {
      search: ArrayParam,
      company_list_uuids: ArrayParam,
      sources: ArrayParam,
    }
  }, [])

  const [filters, setFilters_] = useQueryParamsWithSettings(
    queryParamConfigMap,
    'list_filters',
    teamSlug + '_' + listSlug,
    defaultFilter,
  )

  const { shared_with: listSharedWith } = locationState || {}
  const [showListSharedModal, setShowListSharedModal] = useState<boolean>(!!listSharedWith)

  const {
    dataUpdatedAt: listDataUpdatedAt,
    data: companyListData,
    refetch: refetchList,
  } = useQuery(
    ['getCompanyList', company_slug, listSlug],
    () => callApi(api.getCompanyList, company_slug, listSlug!, false),
    {
      enabled: !!listSlug,
      keepPreviousData: true,
    },
  )

  const companyList = companyListData?.company_list

  const {
    isLoading: companyDataIsLoading,
    data,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    dataUpdatedAt: companyDataUpdatedAt,
  } = useInfiniteQuery(
    [
      company_slug,
      'opportunityData',
      companyList?.uuid,
      companyList?.status?.stale,
      companyList?.item_count,
      filters,
    ],
    ({ pageParam: page = 1 }) =>
      callApi(
        api.getCompanies,
        company_slug, // team slug
        page, // page
        {
          ...filters,
          sources: !!companyList ? [companyList?.uuid] : [],
        }, // filters
        {}, // extra params
      ),
    {
      cacheTime: 0,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      onSettled: () => setShowResultsLoading(false),
      keepPreviousData: true,
    },
  )

  // don't show companies from previous list if a new list is selected
  const items = useMemo(() => {
    if (data?.pages?.[0]?.sources?.includes(companyList?.uuid) || !companyList?.uuid) {
      if (v2_indexed_api) {
        return data?.pages?.flatMap((page) => {
          return page.company_ids.map((id) => page.company_records[id])
        })
      } else {
        return data?.pages?.flatMap((page) => page.companies)
      }
    } else {
      return []
    }
  }, [v2_indexed_api, data, companyList])

  const reload = () => {
    refetch()
    refetchList()
  }

  const renderEditListModal: RenderModal = (resolve) => (
    <EditListModal
      teamSlug={teamSlug}
      onHide={() => resolve(false)}
      reload={reload}
      listUuid={companyList?.uuid}
      onDelete={() => history.push(`/${teamSlug}/lists`)}
    />
  )

  const renderShareListModal: RenderModal = (resolve) => (
    <ListSharingSettings
      teamSlug={teamSlug}
      resolve={() => resolve(false)}
      uuid={companyList?.uuid}
      reload={reload}
      header={`Share ${companyList?.name}`}
    />
  )

  const itemsRendered = useMemo(() => {
    const itemRow = (view: 'list' | 'grid') =>
      items?.map((company) => {
        return (
          <ItemRow
            listSlug={listSlug}
            listUuid={listUuid}
            teamSlug={company_slug}
            key={`company-${company.uuid}`}
            item={company}
            refetchItems={refetch}
            canRemoveItem={isAdminOrEmployee}
            view={view}
          />
        )
      })

    return (
      <div>
        {view === 'grid' && <GridWrapper>{itemRow('grid')}</GridWrapper>}
        {view === 'list' && <div>{itemRow('list')}</div>}
      </div>
    )
  }, [items, listSlug, company_slug, listUuid, view])

  let child

  if (showResultsLoading || (!companyList && listParam)) {
    child = (
      <div className="flex justify-center items-center flex-col">
        <Loading />
      </div>
    )
  } else {
    child = (
      <div>
        <div className="flex flex-col">{itemsRendered}</div>

        {hasNextPage && (
          <div className="flex justify-center	my-6">
            <CabalButton disabled={isFetchingNextPage} onClick={() => fetchNextPage()}>
              Load more
            </CabalButton>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <PageHeader
        data={{
          title: companyList?.name,
          description_parts: compact([
            companyList?.description && (
              <Typography fontSize="14" fontWeight={400} color={'fog'}>
                {companyList?.description}
              </Typography>
            ),
          ]),
          avatar_url:
            companyList && !companyList.owned ? companyList.owning_team.logo_url : undefined,
        }}
        actions={{
          share: () => showModal(renderShareListModal, 'render_share_list_modal'),
          edit:
            companyList?.owned && isAdminOrEmployee
              ? () => showModal(renderEditListModal, 'render_edit_list_modal')
              : null,
          others: (
            <div className="inline-block w-[80px]">
              <CabalButton
                variant="link"
                leftIcon={
                  view === 'list' ? (
                    <i className="far fa-equals fa-fw" />
                  ) : (
                    <i className="far fa-grid fa-fw" />
                  )
                }
                onClick={() => setView(view === 'list' ? 'grid' : 'list')}
              >
                {view === 'list' ? 'List' : 'Grid'}
              </CabalButton>
            </div>
          ),
        }}
        backUrl={`/${teamSlug}/lists`}
      />

      {child}
      {showListSharedModal && (
        <ListSharedModal
          resolve={() => setShowListSharedModal(false)}
          list={companyList}
          sharedWith={listSharedWith!}
          currentTeamSlug={teamSlug}
        />
      )}
    </>
  )
}

export default ListViewOther
