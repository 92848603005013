import React from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import styled from 'styled-components'
import tw from 'twin.macro'

import SearchBlock from 'components/CompanyHome/SearchBlock'
import CabalButton from 'global/CabalButton'
import GridWrapper from 'ui-components/GridWrapper'

import { cn } from 'utils/styles'
import { CompanyListType } from 'utils/types'

import CompanyCard from './CompanyCard'

interface Props {
  list: CompanyListType
  teamSlug: string
  refetchLists?: () => void
  data: any
  people: any
  view: string
}

const Wrapper = styled.div`
  .title {
    &:hover {
      color: ${({ theme }) => theme.colors.purple};
    }
  }
`

const PaddedWidget = styled.div`
  ${tw`mb-2 mt-1`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  box-shadow: 0px 6px 18px -17px rgba(92, 105, 209, 0.75);
  border: 1px solid ${({ theme }) => theme.colors.widget_border};
  border-radius: 10px;
`

const PreviewableListSection: React.FC<Props> = ({ list, teamSlug, data, people, view }) => {
  const [limit, setLimit] = React.useState(100)
  const listConnectionType = list.default_type_identifier == 'connections'
  const sortedData = (
    data?.items_with_matches?.sort((a, b) => b.connected_count - a.connected_count) || []
  ).concat(data?.items_without_matches || [])
  const maxLimit = sortedData.length
  const companies = sortedData.slice(0, limit)
  const [parent] = useAutoAnimate()

  const renderMoreButton = () => {
    return (
      <div className="flex items-center justify-center">
        {maxLimit > limit && (
          <CabalButton
            variant="primary"
            onClick={() => {
              setLimit(limit + limit)
            }}
            className="my-4"
          >
            Show More
          </CabalButton>
        )}
      </div>
    )
  }

  const companyCard = (company) => {
    return (
      <CompanyCard
        listUuid={list.uuid}
        icps={list.icps}
        key={company.id}
        company={company}
        teamSlug={teamSlug}
        people={people}
        peopleVotes={data.people_votes}
        companyVotes={data.company_votes}
        gridView={view === 'grid'}
        listSlug={list.slug}
        advisorPreview={true}
        companyList={list}
        isTalent={list.is_talent}
        advisorListView={true}
      />
    )
  }

  const CompanyCardWrap = (
    <>
      {companies.map((company) =>
        view === 'list' ? (
          <PaddedWidget key={company.uuid} className={cn({ 'pr-1': list.list_type !== 'other' })}>
            {companyCard(company)}
          </PaddedWidget>
        ) : (
          companyCard(company)
        ),
      )}
    </>
  )

  return (
    <Wrapper id={list.slug} className="group">
      {list.slug && list.default_type_identifier && listConnectionType && (
        <div className="-mx-2">
          <SearchBlock
            teamSlug={teamSlug}
            listSlug={list.slug}
            itemCount={list.companies_count}
            className="-my-2"
          />
        </div>
      )}
      {!listConnectionType && (
        <div ref={parent}>
          {view === 'list' && <>{CompanyCardWrap}</>}
          {view === 'grid' && <GridWrapper>{CompanyCardWrap}</GridWrapper>}
          {renderMoreButton()}
        </div>
      )}
    </Wrapper>
  )
}

export default PreviewableListSection
