import axios from 'axios'
import { SetRequired } from 'type-fest'

import {
  GetGroupAdvisors,
  GetGroupResponse,
  GetGroupsRequest,
  GetGroupsResponse,
  GroupModel,
} from 'utils/types'

export default {
  createGroup: (team_slug: string, group: Partial<GroupModel>) => {
    return axios.post<{
      group: GroupModel
    }>('/api/groups', { ...group, team_slug })
  },

  createGroupAddAdvisors: (
    team_slug: string,
    group: Partial<GroupModel>,
    advisor_uuids: string[],
  ) => {
    return axios.post('/api/advisor_groups/add_advisor_groups_synchronous', {
      group: group,
      team_slug,
      advisor_uuids,
    })
  },

  getGroups: (params: GetGroupsRequest) => {
    return axios.get<GetGroupsResponse>('/api/groups', { params })
  },

  getGroup: (team_slug: string, uuid: string) => {
    return axios.get<GetGroupResponse>(`/api/groups/${uuid}`, { params: { team_slug: team_slug } })
  },

  saveGroup: (team_slug: string, group: SetRequired<Partial<GroupModel>, 'uuid'>) => {
    return axios.put(`/api/groups/${group.uuid}`, { ...group, team_slug })
  },

  deleteGroup: (team_slug: string, uuid: string) => {
    return axios.delete(`/api/groups/${uuid}`, { params: { team_slug } })
  },

  getGroupAdvisors: (team_slug: string, uuid: string) => {
    return axios.get<GetGroupAdvisors>(`/api/groups/${uuid}/advisor_uuids`, {
      params: { team_slug },
    })
  },
}
