import CabalButton from 'global/CabalButton'
import { DatePickerWithInput, Label, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { ShowModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import memoizeOne from 'memoize-one'
import moment from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
  getTomorrowAfternoonDate,
  getTomorrowMorningDate,
  SCHEDULE_DATE_FORMAT,
} from 'utils/templates'
import { DraftMessageBlueprint } from 'utils/types'
import { ComposerStateContextValue } from '../Context'

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.rain_fog};
`

export const scheduleSendDropwdownMenuItems = memoizeOne(
  (props: {
    message: DraftMessageBlueprint
    allowScheduledSend: boolean
    showModal: ShowModal
    isCurrentUserOwner: boolean
    setScheduleAt: (date: string) => void
    sendState: ComposerStateContextValue['sendState']
  }) => {
    const { allowScheduledSend, setScheduleAt, message, showModal, isCurrentUserOwner, sendState } =
      props
    if (
      !isCurrentUserOwner ||
      !allowScheduledSend ||
      !!message.schedule_at ||
      sendState === 'presend'
    ) {
      return undefined
    }

    if (!!sendState) {
      return []
    }

    return [
      {
        label: (
          <Typography color="rain_fog" fontSize="12">
            Schedule Send
          </Typography>
        ),
      },
      {
        label: `Tomorrow morning - ${getTomorrowMorningDate().format(SCHEDULE_DATE_FORMAT)}`,
        onSelect: () => {
          setScheduleAt(getTomorrowMorningDate().toString())
        },
      },
      {
        label: `Tomorrow afternoon - ${getTomorrowAfternoonDate().format(SCHEDULE_DATE_FORMAT)}`,
        onSelect: () => {
          setScheduleAt(getTomorrowAfternoonDate().toString())
        },
      },
      {
        label: <Divider />,
      },
      {
        label: 'Custom Time',
        onSelect: () =>
          showModal(
            (resolve) => (
              <ScheduleModal
                date={message.schedule_at || undefined}
                onSubmit={(date) => setScheduleAt(date)}
                onHide={resolve}
              />
            ),
            'render_schedule_modal',
          ),
      },
    ]
  },
)

interface Props {
  date?: string
  onHide: () => void
  onSubmit: (date: string) => void
}

export const ScheduleModal: React.VFC<Props> = ({ date: _dateProp, onSubmit, onHide }) => {
  const dateProp = moment(_dateProp).toDate()
  const [date, setDate] = useState<string | undefined>(
    moment(dateProp ? dateProp : moment()).format('YYYY-MM-DD'),
  )
  const [time, setTime] = useState<string | undefined>(
    dateProp ? moment(dateProp).format('HH:mm') : undefined,
  )

  const dateTime = moment(date + ' ' + time, 'YYYY-MM-DD HH:mm')
  const isValid =
    date && time && dateTime ? dateTime.isValid() && dateTime.isAfter(moment()) : false

  const handleSubmit = () => {
    if (!isValid) return

    onSubmit(dateTime.toString())
    onHide()
  }

  return (
    <Modal
      header="Schedule Message"
      show
      onHide={onHide}
      rightActions={
        <>
          {isValid && (
            <Typography className="mr-4" fontSize="13" color="fog_rain">
              Schedule for: {dateTime.format(SCHEDULE_DATE_FORMAT)}
            </Typography>
          )}

          <CabalButton
            disabled={!isValid}
            disabledMessage="Choose valid date and time"
            onClick={handleSubmit}
          >
            Schedule
          </CabalButton>
        </>
      }
      leftActions={
        <Typography fontSize="12" className="italic">
          The message will be scheduled for {Intl.DateTimeFormat().resolvedOptions().timeZone} time
          zone.
        </Typography>
      }
    >
      <div className="flex flex-row w-full">
        <div className="w-1/2">
          <Label>Date</Label>
          <DatePickerWithInput
            className="w-full mb-4"
            placeholder="Start date"
            id="start_date"
            date={date}
            defaultDate={moment().format('L')}
            minDate={moment().startOf('day').toDate()}
            maxDate={moment().add(3, 'months').toDate()}
            onDateChange={setDate}
          />
        </div>

        <div className="pl-4 w-1/2">
          <Label>Time</Label>

          <TextInput
            value={time}
            type="time"
            onChange={(e) => setTime(e.target.value)}
            placeholder="Time"
            className="w-full"
          />
        </div>
      </div>
    </Modal>
  )
}
