import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

import NewTeam from 'containers/MemberAddList/NewTeam'
import Card from 'global/Card'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'

import api, { callApi } from 'utils/api'

const ListInvite: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const history = useHistory()
  const { user: currentUser, isUserLoaded } = useCurrentUser()

  const { data, isLoading } = useQuery(
    ['getListInvite', uuid],
    () => callApi(api.getListInvite, uuid),
    {
      enabled: isUserLoaded,
    },
  )

  const { team, list } = data || {}

  useEffect(() => {
    if (currentUser.team && list) {
      api.acceptListInvite(currentUser.team!.slug, uuid).then(() => {
        history.push(`/${currentUser.team?.slug}/lists/${list.uuid}`)
      })
    }
  }, [currentUser, list])

  if (isLoading || !team) return <Loading className="py-6" />

  if (!currentUser.team)
    return (
      <div className="w-min mx-auto my-12">
        <Card>
          <Typography className="mb-6" component="div" fontSize="14" fontWeight={600}>
            {team.name} invited you to set up your company on Cabal
          </Typography>
          <NewTeam teamAdvised={team} nextStep={() => {}} />
        </Card>
      </div>
    )

  return <></>
}

export default ListInvite
