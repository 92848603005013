import { compact, join } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'

import Avatar from 'global/Avatar'
import { Select, SelectOption, SelectProps } from 'global/Input'

import api, { callApi } from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import { GlobalCompanyBlueprint } from 'utils/types'

interface Props extends Omit<SelectProps<GlobalCompanyBlueprint>, 'options' | 'onChange'> {
  onChange: (person: GlobalCompanyBlueprint | null) => void
}

const GlobalCompanySelect: React.FC<Props> = ({ onChange, ...extraProps }) => {
  const [query, setQuery] = useState('')
  const deboundedQuery = useDebouncedValue(query, 500)

  const { isLoading: isLoadingQuery, data } = useQuery(
    ['getPeople', deboundedQuery],
    () => callApi(api.getSearchResults, deboundedQuery, ['global_companies']),
    {
      keepPreviousData: true,
      enabled: !!deboundedQuery,
    },
  )
  const isLoading = useMemo(
    () => isLoadingQuery || deboundedQuery !== query,
    [isLoadingQuery, deboundedQuery, query],
  )

  const results = useMemo(
    () =>
      (data?.results?.filter((r) => r.type === 'GlobalCompany') || []) as GlobalCompanyBlueprint[],
    [data],
  )

  const options = useMemo(
    () =>
      results.map<SelectOption<GlobalCompanyBlueprint>>((company) => ({
        value: company,
        label: (
          <div className="flex items-center">
            <Avatar
              size="24px"
              src={company.logo_url}
              name={company.name}
              className="mr-2 flex-0"
            />
            <div className="flex-1">{join(compact([company.name, company.domain]), ' - ')}</div>
          </div>
        ),
        toString: () => company.name + company.domain,
      })),
    [results],
  )

  return (
    <Select
      options={options}
      leftActions={<i className="far fa-search" />}
      noDropdownIndicator={true}
      onChange={onChange}
      onInputChange={setQuery}
      isLoading={isLoading && options.length === 0}
      noOptionsMessage={() => (!query ? 'Search...' : 'No results')}
      filterOption={() => true}
      {...extraProps}
    />
  )
}

export default GlobalCompanySelect
