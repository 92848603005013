import moment from 'moment'
import React, { useState } from 'react'

import CabalButton from 'global/CabalButton'
import { DatePickerWithInput, Label, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import Typography from 'global/Typography'

import { SCHEDULE_DATE_FORMAT } from './ScheduleSend'

interface Props {
  show: boolean
  date?: Date
  onHide: () => void
  onSubmit: (date: Date) => void
}

const ScheduleModal: React.VFC<Props> = ({ show, date: dateProp, onSubmit, onHide }) => {
  const [date, setDate] = useState<string | undefined>(
    moment(dateProp ? dateProp : moment()).format('YYYY-MM-DD'),
  )
  const [time, setTime] = useState<string | undefined>(
    dateProp ? moment(dateProp).format('HH:mm') : undefined,
  )

  const dateTime = moment(date + ' ' + time, 'YYYY-MM-DD HH:mm')
  const isValid =
    date && time && dateTime ? dateTime.isValid() && dateTime.isAfter(moment()) : false

  const handleSubmit = () => {
    if (!isValid) return

    onSubmit(dateTime.toDate())
    onHide()
  }

  return (
    <Modal
      header="Schedule Message"
      show={show}
      onHide={onHide}
      rightActions={
        <>
          {isValid && (
            <Typography className="mr-4" fontSize="13" color="fog_rain">
              Schedule for: {dateTime.format(SCHEDULE_DATE_FORMAT)}
            </Typography>
          )}

          <CabalButton
            disabled={!isValid}
            disabledMessage="Choose valid date and time"
            onClick={handleSubmit}
          >
            Next
          </CabalButton>
        </>
      }
    >
      <div className="flex w-full">
        <div className="flex-1">
          <Label>Date</Label>
          <DatePickerWithInput
            className="mb-4 w-full"
            placeholder="Start date"
            id="start_date"
            date={date}
            defaultDate={moment().format('L')}
            minDate={moment().startOf('day').toDate()}
            maxDate={moment().add(3, 'months').toDate()}
            onDateChange={setDate}
          />
        </div>

        <div className="flex-1 pl-4">
          <Label>Time</Label>

          <TextInput
            value={time}
            type="time"
            onChange={(e) => setTime(e.target.value)}
            placeholder="Time"
            className="w-full flex"
          />
        </div>
      </div>
    </Modal>
  )
}

export default ScheduleModal
