import axios, { AxiosResponse } from 'axios'
import { useInfiniteQuery, useQuery } from 'react-query'

import { REQUEST_CENTER_ENDPOINTS } from '../apiEndpoints'
import { IRequestCenterResponse } from '../types/ITypes'
import { IFetchDataParams } from '../types/ITypes'

const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')

const fetchIntroRequests = async (
  page: number | null,
  name: string | null,
  status: string[] | null,
  order_by: string | null,
  direction: string | null,
): Promise<AxiosResponse<IRequestCenterResponse>> => {
  return await axios.get<IRequestCenterResponse>(REQUEST_CENTER_ENDPOINTS.index, {
    headers: {
      'X-CSRF-TOKEN': csrfToken,
    },
    params: {
      page: page,
      name: name,
      status: status?.join(','),
      order_by: order_by,
      direction: direction,
    },
  })
}

export const useFetchIntroRequests = ({
  enabled,
  page = null,
  name = null,
  status = null,
  order_by = null,
  direction = 'desc',
}: IFetchDataParams) =>
  useInfiniteQuery({
    queryKey: [
      'index',
      ...(page !== null ? [page] : []),
      ...(name !== null ? [name] : []),
      ...(status !== null ? [status] : []),
      ...(order_by !== null ? [order_by, direction] : []),
    ],
    queryFn: () => fetchIntroRequests(page, name, status, order_by, direction),
    enabled: enabled,
    retry: 2,
    cacheTime: 0,
    getNextPageParam: (lastPage) => lastPage.data.pagination.next_page,
    getPreviousPageParam: (firstPage) => firstPage.data.pagination.prev_page,
  })
