import * as React from 'react'

import cx from 'classnames'
import { compact } from 'lodash'
import CabalMessage from 'models/CabalMessage'
import moment from 'moment'
import pluralize from 'pluralize'
import emoji from 'react-easy-emoji'
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import ItemCardV2 from 'components/Block/ItemCard'
import TimeAgo from 'components/TimeAgo'
import CabalButton from 'global/CabalButton'
import Card from 'global/Card'
import Pill from 'global/Pill'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'

import api from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'
import { SCHEDULE_DATE_FORMAT } from 'utils/templates'
import { BlockItemCard, BlockItemCardDescriptionPart, CompanySlugParam } from 'utils/types'

import EditMessageModal from './EditMessageModal'
import MessageButtons from './MessageButtons'
import MessageOptions from './MessageOptions'
import { engagementText } from './utils'

interface Props {
  message: CabalMessage
  group?: any
  reloadMessages: () => void
  canDelete?: boolean
  showPrivacy?: boolean
  onClick?: (m: CabalMessage) => void
  company_slug?: string
  showSnippet?: boolean
  selected?: boolean
  minimalView?: boolean
  returnToGlobalMail?: boolean
  onClickUrl?: string
}

const MessageFeedCard: React.FC<Props> = (props) => {
  const {
    message,
    onClick,
    reloadMessages,
    canDelete = false,
    showSnippet = true,
    selected,
    minimalView = false,
    returnToGlobalMail = false,
    onClickUrl,
  } = props

  const { company_slug: company_slug_from_url } = useParams<CompanySlugParam>()
  const company_slug = props.company_slug || company_slug_from_url || message.rawMessage.team_slug
  const { isMobile } = useColorMode()

  const [showEdit, setShowEdit] = React.useState(false)

  const { user: currentUser } = useCurrentUser()

  const handleDelete = () => {
    api.deleteMessage(company_slug, message.uuid).then(() => {
      reloadMessages()
    })
  }

  const modal = showEdit && (
    <EditMessageModal
      message={message}
      show={showEdit}
      onHide={() => setShowEdit(false)}
      onSave={() => {
        reloadMessages()
        setShowEdit(false)
      }}
    />
  )

  let participantsText = ''

  if (!!message.recipients?.length && message.recipients[0].label) {
    participantsText = `To: ${message.recipients[0].label}`

    if (message.recipients.length > 2) {
      participantsText += ` and ${message.recipients.length - 1} ${pluralize(
        'other',
        message.recipients.length - 1,
      )}`
    }
  }

  const subject = (
    <div
      className={cx(
        'flex truncate gap-1.5',
        minimalView ? 'flex-col items-start' : 'flex-col sm:flex-row sm:items-center items-start',
      )}
    >
      <Typography
        component="h3"
        fontWeight={600}
        fontSize="14"
        lineHeight={'17px'}
        className={cx('w-full sm:w-auto', {
          italic: !message.subject,
          truncate: isMobile,
        })}
      >
        {emoji(message.subject || '(No Subject)')}
      </Typography>
      {showSnippet && message.snippet && !(message.draft && message.visual) && (
        <Typography
          className="truncate w-full"
          component="p"
          fontSize="12"
          lineHeight={'15px'}
          color={'fog'}
        >
          {message.snippet}
        </Typography>
      )}
    </div>
  )

  const messageEmbed = (
    <>
      <Typography fontSize="12" color="gray" component="div" lineHeight={'15px'}>
        {/* {!!message.unread_count && (
          <>
            <span className="inline-block mx-1 rounded-full w-2 h-2 bg-red-500" />
            <span className="text-red-500">{message.unread_count}</span>
            {` · `}
          </>
        )} */}
        {message.is_owner && message.requested_by ? `Drafted by ${message.requested_by} · ` : ''}
        {!message.is_owner && message.from_request ? `Drafted for ${message.owner_name} · ` : ''}
        {moment(message.last_reply_at || message.created_at).fromNow()}
        {participantsText || ''}
      </Typography>
    </>
  )

  let messageLink = onClickUrl

  if (message.draft) {
    messageLink = `/${company_slug}/compose/${message.uuid}?return_to_global_mail=${returnToGlobalMail}`
  } else {
    messageLink = message.detached
      ? `/${company_slug}/messages/${message.root_message_uuid}/${message.root_message_thread_uuid}`
      : `/${company_slug}/messages/${message.uuid}`
  }

  const itemView: BlockItemCard = {
    title: (
      <Typography className="truncate w-full" color={'fog'}>
        <Typography fontWeight={600} color="primary">
          {emoji(message.subject || '(No Subject)')}
        </Typography>
        <Typography className="ml-2" fontSize="12" lineHeight={'15px'}>
          {message.snippet}
        </Typography>
      </Typography>
    ),
    description_parts: compact([
      {
        id: 'date',
        type: 'string',
        label: <TimeAgo datetime={message.created_at} />,
      },
      !!participantsText && {
        id: 'to',
        type: 'string',
        label: participantsText,
      },
      ...(!message.draft
        ? engagementText(message).map<BlockItemCardDescriptionPart>((engagement, idx) => ({
            id: `engagement-${idx}`,
            type: 'string',
            label: engagement,
          }))
        : []),
      message.schedule_at && {
        id: 'scheduled',
        type: 'string',
        label: (
          <Pill variant="neon" border={false}>
            <i className="far fa-clock mr-2" />
            {moment(message.schedule_at).format(SCHEDULE_DATE_FORMAT)}
          </Pill>
        ),
      },
      message.draft &&
        message.creator_user &&
        message.sender &&
        currentUser.uuid !== message.creator_user.uuid && {
          id: 'drafted_for_by',
          type: 'string',
          label: (
            <Tooltip
              label={
                message.creator_user.uuid == message.sender.uuid ? (
                  <>{message.creator_user?.name} started this draft</>
                ) : (
                  <>
                    {message.creator_user.name} started this draft for{' '}
                    {currentUser.uuid == message.sender.uuid ? 'You' : message.sender.name}
                  </>
                )
              }
            >
              <Pill variant="purple_filled" border={false}>
                <i className="far fa-file mr-2" />
                {message.creator_user.uuid == message.sender.uuid ? (
                  message.creator_user.first_name
                ) : (
                  <>
                    {message.creator_user.first_name}
                    {' => '}
                    {currentUser.uuid == message.sender.uuid ? 'You' : message.sender.first_name}
                  </>
                )}
              </Pill>
            </Tooltip>
          ),
        },
      message.comment_count &&
        message.comment_count > 0 && {
          id: 'comment_count',
          type: 'string',
          label: (
            <Pill variant="neon" border={false} data-testid="message-card-comments-count">
              <i className="far fa-comment mr-2" />
              {message.comment_count}
            </Pill>
          ),
        },
    ]),
    title_url: !minimalView && !returnToGlobalMail ? messageLink : undefined,
    on_click:
      minimalView || returnToGlobalMail
        ? {
            type: 'func',
            handler: onClick,
          }
        : undefined,
    menu_items: compact(
      message.draft
        ? [
            {
              label: 'Edit draft',
              type: 'internal_url',
              url: messageLink,
            },
            canDelete && {
              label: 'Delete draft',
              type: 'action',
              onSelect: handleDelete,
            },
          ]
        : [
            {
              label: 'Use as draft',
              type: 'internal_url',
              url: `/${company_slug}/compose?draft_from=${message.uuid}`,
            },
          ],
    ),
  }

  return <ItemCardV2 itemView={itemView} />
}

export default MessageFeedCard
