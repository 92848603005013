import React from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useQuery } from 'react-query'
import { RouteChildrenProps } from 'react-router-dom'
import styled from 'styled-components'

import DummyCompanyPage from 'components/DummyCompanyPage'
import CandidateHeader from 'containers/CandidateProfile/CandidateHeader'
import Documents from 'containers/CandidateProfile/Documents'
import Education from 'containers/CandidateProfile/EducationV2'
import Experience from 'containers/CandidateProfile/ExperienceV2'
import FilterPills from 'containers/CandidateProfile/FilterPills'
import Notes from 'containers/CandidateProfile/NotesV2'
import Preferences from 'containers/CandidateProfile/Preferences'
import About from 'containers/CandidateProfile/about'
import { ActivityWidget } from 'containers/CandidateProfile/widgets'
import { useAccessControl } from 'global/AccessControl'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'

import CraftFAQ from './CraftFAQV2'

export const SeeMore = styled(Typography)`
  &:hover {
    color: ${({ theme }) => theme.colors.purple};
  }
`

interface Props extends Partial<RouteChildrenProps<{ uuid: string }>> {
  uuid?: string
  isCandidateUuid?: boolean
  listUuid?: string
}

const CandidateProfile: React.FC<Props> = ({ listUuid, uuid, match, isCandidateUuid = true }) => {
  const [parent] = useAutoAnimate()
  const teamSlug = useTeamSlug()
  const [filter, setFilter] = React.useState('')
  const { team } = useTeam(teamSlug)
  const { isAdminOrEmployee, canViewTeam } = useAccessControl(teamSlug)
  const receivedCandidateUuid = uuid || match?.params.uuid
  const { user } = useCurrentUser()

  const profileQuery = useQuery(
    ['profile', uuid],
    () => callApi(api.fetchProfile, receivedCandidateUuid!, isCandidateUuid, teamSlug),
    {
      enabled: !!receivedCandidateUuid,
    },
  )

  if (profileQuery.isLoading) {
    return null
  }

  const globalPerson = profileQuery.data?.global_person
  const candidateProfile = profileQuery.data?.candidate_profile
  const candidateUuid = candidateProfile?.uuid
  const advisorUuid = profileQuery.data?.advisor_uuid
  const notes = profileQuery.data?.notes ?? []
  const documents = profileQuery.data?.candidate_profile?.documents ?? []

  if (globalPerson === undefined) {
    return null
  }

  const viewAbout = (filter === '' || filter === 'About') && candidateProfile
  const viewExperience = (filter === '' || filter === 'Experience') && candidateProfile
  const viewEducation = (filter === '' || filter === 'Education') && candidateProfile
  const showNotes = notes.length > 0 && isAdminOrEmployee && candidateProfile && candidateUuid
  const viewNotes = showNotes && (filter === '' || filter === 'Notes')
  const showDocuments = documents.length > 0 && candidateProfile && candidateUuid
  const viewDocuments = showDocuments && (filter === '' || filter === 'Documents')
  const showPreferences =
    (candidateProfile?.what_youre_looking_for ||
      (candidateProfile?.function_preference && candidateProfile?.function_preference.length > 0) ||
      (candidateProfile?.level_preference && candidateProfile?.level_preference.length > 0) ||
      (candidateProfile?.stage_preference && candidateProfile?.stage_preference.length > 0) ||
      (candidateProfile?.opportunity_type && candidateProfile?.opportunity_type.length > 0) ||
      (candidateProfile?.ideal_company_size && candidateProfile?.ideal_company_size.length > 0)) &&
    candidateProfile
  const viewPreferences = showPreferences && (filter === '' || filter === 'Preferences')

  const isCandidate = team && team.advisor && team.advisor.role === 'candidate'
  const showCraftFaq =
    (isCandidate || isAdminOrEmployee) && teamSlug && teamSlug === 'craft-ventures'
  const isThisCandidate = candidateProfile?.user?.uuid === user.uuid

  if (!canViewTeam) {
    return (
      <>
        <CandidateHeader
          globalPerson={globalPerson}
          candidateProfile={candidateProfile}
          isCandidate={isCandidate || false}
        />
        <DummyCompanyPage team={team} />
      </>
    )
  }

  return (
    <PageWrapper title={globalPerson.name}>
      <CandidateHeader
        globalPerson={globalPerson}
        candidateProfile={candidateProfile}
        isCandidate={isCandidate || false}
      />
      {candidateProfile && candidateUuid && (
        <FilterPills
          filter={filter}
          onSetFilter={setFilter}
          teamSlug={teamSlug}
          candidateUuid={candidateUuid}
          reloadCandidateProfile={() => profileQuery.refetch()}
          isCandidate={isCandidate || false}
          isCandidateProfilePage={true}
          showCandidatePreferences={!!showPreferences}
          showCandidateNotes={!!showNotes}
          showCandidateDocuments={!!showDocuments}
          archived={candidateProfile.archived}
          listUuid={listUuid}
          isThisCandidate={isThisCandidate}
        />
      )}
      <div className="my-10 flex gap-4">
        <div ref={parent} className="flex flex-col flex-1 gap-8 max-w-[630px]">
          {viewAbout && (
            <About
              candidateProfile={candidateProfile}
              reloadCandidateProfile={() => profileQuery.refetch()}
              isThisCandidate={isThisCandidate}
              isAdminOrEmployee={isAdminOrEmployee || false}
            />
          )}
          {viewPreferences && (
            <Preferences
              candidateProfile={candidateProfile}
              reloadCandidateProfile={() => profileQuery.refetch()}
              isThisCandidate={isThisCandidate}
              isAdminOrEmployee={isAdminOrEmployee || false}
            />
          )}
          {viewExperience && (
            <Experience
              candidateProfile={candidateProfile}
              reloadCandidateProfile={() => profileQuery.refetch()}
              isThisCandidate={isThisCandidate}
              isAdminOrEmployee={isAdminOrEmployee || false}
            />
          )}
          {viewEducation && (
            <Education
              candidateProfile={candidateProfile}
              reloadCandidateProfile={() => profileQuery.refetch()}
              isThisCandidate={isThisCandidate}
              isAdminOrEmployee={isAdminOrEmployee || false}
            />
          )}
          {viewNotes && (
            <Notes
              candidateUuid={candidateUuid}
              notes={notes}
              teamSlug={teamSlug}
              reloadCandidateProfile={() => profileQuery.refetch()}
            />
          )}
          {viewDocuments && (
            <Documents
              candidateUuid={candidateUuid}
              uploads={documents}
              reloadCandidateProfile={() => profileQuery.refetch()}
              teamSlug={teamSlug}
              isCandidate={isCandidate || false}
              isThisCandidate={isThisCandidate}
            />
          )}
        </div>
        <div className="w-80 flex-shrink-0 flex flex-col gap-4">
          {advisorUuid && (
            <ActivityWidget advisorUuid={advisorUuid} isCandidateProfilePage={true} />
          )}
          {showCraftFaq && <CraftFAQ />}
        </div>
      </div>
    </PageWrapper>
  )
}

export default CandidateProfile
