import React, { useEffect, useState } from 'react'

import { ICompanyListData, ICompanyListItem } from 'components/Winsite/types/ICompanyPersonaList'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import { applicationUrl } from 'utils/url'

import { useOfferVote } from '../queries/OfferVote'

interface IProps {
  editClicked: boolean
  setEditClicked: (clicked: boolean) => void
  list: ICompanyListData
  listType: string
  teamSlug: string | undefined
  companyDomain: string | undefined
}
export function AccountsProfilesDivSubSection({
  editClicked,
  setEditClicked,
  list,
  listType,
  teamSlug,
  companyDomain,
}: IProps) {
  const initialDisplaycount = 7
  const initialIncrementCount = 25
  const [displayCount, setDisplayCount] = useState<number>(initialDisplaycount)
  const [previousDisplayCount, setPreviousDisplayCount] = useState<number>(0)
  const initialIntroOffered = list.company_list_items
    .slice(0, initialDisplaycount)
    .map((item) => item.intro_offered)
  const [introOffered, setIntroOffered] = useState<boolean[]>(initialIntroOffered)
  const toastSuccess = 'Success! Intro offer sent.'
  const { user } = useCurrentUser()
  const userPresent = !!Object(user)?.created_at
  const { mutate: offerVoteMutate } = useOfferVote({
    teamSlug: teamSlug,
    votableType: listType,
  })

  useEffect(() => {
    setOfferIntroArr()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayCount])

  const displayLoginMessage = (message?: string) => {
    const displayMessage =
      message || `Please log in with your ${companyDomain} email to make changes`
    cabalToast({
      style: 'error',
      content: `${displayMessage}`,
    })
  }

  const remainingItemsCount = () => {
    return list.company_list_items.length - displayCount
  }

  const incrementCount = () => {
    const itemsRemaining = remainingItemsCount()
    if (itemsRemaining >= initialIncrementCount) {
      return initialIncrementCount
    } else if (itemsRemaining > 0) {
      return itemsRemaining
    } else {
      return 0
    }
  }
  const editList = () => {
    window.location.href = `${applicationUrl()}/${teamSlug}/lists/${list.slug}`
  }

  if (editClicked) {
    setEditClicked(false)
    editList()
  }
  const openUrl = (url: string) => {
    window.open(url, '_blank', 'noopener noreferrer')
  }
  const companyList = (item: ICompanyListItem, _index: number) => {
    const logo_url = 'https://logo.clearbit.com/'

    return (
      <div className="flex cursor-pointer h-6">
        {item.domain && (
          <Avatar name={item.name} src={logo_url + item.domain} size="16px" className="mr-2" />
        )}
        <Typography
          fontSize={'11'}
          fontWeight={400}
          color="emerald_dark"
          onClick={() => openUrl(item.domain)}
        >
          {item.name}
        </Typography>
      </div>
    )
  }
  const displayToastForUpVote = (vote: number): void => {
    vote == 0
      ? null
      : cabalToast({
          content: `${toastSuccess}`,
          style: 'success',
        })
  }

  const handleOfferIntro = (item: ICompanyListItem, index: number) => {
    if (!userPresent) {
      displayLoginMessage('Please log in to offer intros')
      return
    }
    const vote = introOffered[index] === true ? 0 : 1
    offerVoteMutate(
      { teamSlug: teamSlug, votableId: item.company_id, vote: vote },
      {
        onSuccess: () => {
          if (index == 0) {
            const votedOffer = vote == 1 ? true : false
            setIntroOffered([votedOffer, ...introOffered.slice(1)])
            displayToastForUpVote(vote)
          } else {
            const votedOffer = vote == 1 ? true : false
            setIntroOffered([
              ...introOffered.slice(0, index),
              votedOffer,
              ...introOffered.slice(index + 1),
            ])
          }
        },
        onError: () => {
          cabalToast({
            content: 'Error occurred. Failed to Offer Intro',
            style: 'error',
          })
        },
      },
    )
  }
  const setOfferIntroArr = () => {
    if (displayCount == initialDisplaycount) {
      return
    }
    const arr = list.company_list_items
      .slice(previousDisplayCount, displayCount)
      .map((item) => item.intro_offered)
    setIntroOffered([...introOffered, ...arr])
  }

  const offerIntroDiv = (item: ICompanyListItem, index: number) => (
    <div
      className="flex flex-row gap-x-2 items-center cursor-pointer mr-4"
      onClick={() => {
        handleOfferIntro(item, index)
      }}
    >
      <Typography fontSize={'11'} fontWeight={400} color="emerald_dark">
        {introOffered[index] === true ? (
          <i className="mr-2 fa-solid fa-thumbs-up"></i>
        ) : (
          <i className="mr-2 fa-regular fa-thumbs-up"></i>
        )}
        {introOffered[index] === true ? 'Offered' : 'Offer Intro'}
      </Typography>
    </div>
  )

  const seeMoreDiv = (
    <div className="flex flex-row gap-x-1 mt-2 items-center mb-2">
      <div className="cursor-pointer">
        <Typography
          fontSize={'11'}
          fontWeight={400}
          color="done"
          onClick={() => updateDisplayCountOfferIntro()}
        >
          See {incrementCount()} more <i className="fa-solid fa-chevron-down"></i>
        </Typography>
      </div>
    </div>
  )
  const updateDisplayCountOfferIntro = () => {
    setPreviousDisplayCount(displayCount)
    setDisplayCount(displayCount + incrementCount())
  }
  const targetAccountsProfiles = () => {
    const items = list.company_list_items
    const itemsToDisplay = items.slice(0, displayCount)
    return (
      <div>
        <div className="flex flex-col w-full p-2 justify-between overflow-auto">
          {itemsToDisplay?.map((item: ICompanyListItem, index: number) => {
            return (
              <div key={`${index}${item.uuid}`} className="flex flex-row justify-between">
                {companyList(item, index)}
                {offerIntroDiv(item, index)}
              </div>
            )
          })}
          {incrementCount() > 0 && seeMoreDiv}
        </div>
      </div>
    )
  }
  return <div>{targetAccountsProfiles()}</div>
}
