import React, { useEffect, useRef, useState } from 'react'

import cx from 'classnames'
import { sanitize } from 'dompurify'
import { truncate } from 'lodash'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useComposer } from 'components/Composer'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Card from 'global/Card'
import Typography from 'global/Typography'

import { Offer, OfferButton } from 'utils/types'

import OfferOptions from '../OfferOptions'

const Wrapper = styled.div`
  ${tw`rounded`}
  background: ${({ theme }) => theme.colors.add_bg};
  padding: 3px 4px;
`

interface Props {
  offer: Offer
  teamSlug: string
  onEdit: (offer: Offer) => void
  noTitle?: boolean
  hasToTruncate?: boolean
}

export const getComposeParams = (b: OfferButton) => {
  const emails = b.email.split(',')
  return {
    recipients: { emails: [emails[0]] },
    cc: emails.length > 1 ? { emails: emails.slice(1) } : {},
    body: b.body,
    subject: b.subject,
  }
}

const ResourceView: React.FC<Props> = ({ offer, teamSlug, onEdit, hasToTruncate, noTitle }) => {
  const { canEditOffers } = useAccessControl(teamSlug)
  const { compose } = useComposer(teamSlug)

  const offerRef = useRef<HTMLDivElement>(null)
  const [truncated, setTruncated] = useState(false)
  const [originalText, setOriginalText] = useState('')
  const history = useHistory()
  const location = useLocation()

  const truncationLength = 150

  useEffect(() => {
    if (!hasToTruncate) return

    const contentEl = offerRef.current as HTMLDivElement

    if (!contentEl || !contentEl.innerText) return
    setOriginalText(contentEl.innerText)

    if (contentEl.innerText.length > truncationLength) {
      setTruncated(true)
    }

    contentEl.innerHTML = truncate(contentEl.innerText, {
      length: truncationLength,
      omission: '...',
    })
    contentEl.classList.remove('h-0', 'overflow-hidden')
  }, [offerRef])

  const handleToggle = () => {
    const contentEl = offerRef.current as HTMLDivElement

    if (truncated) {
      contentEl.innerHTML = originalText
      setTruncated(false)
    } else {
      contentEl.innerHTML = truncate(contentEl.innerText, {
        length: truncationLength,
        omission: '...',
      })
      setTruncated(true)
    }
  }

  const renderButton = (b: OfferButton, i: number) => {
    if (b.hasOwnProperty('email')) {
      return (
        <CabalButton
          key={`offer-buttons-${offer.uuid}-${i}`}
          variant="secondary"
          size="small"
          className="mr-2"
          onClick={() => compose(getComposeParams(b))}
        >
          {b.label}
        </CabalButton>
      )
    }
    return (
      <CabalButton
        component="a"
        href={b.url}
        key={`offer-buttons-${offer.uuid}-${i}`}
        variant="secondary"
        size="small"
        target="_blank"
        className="mr-2"
      >
        {b.label}
      </CabalButton>
    )
  }

  const getBackFunc = () => {
    if (location?.state?.from === 'resources') {
      return () => history.goBack()
    } else {
      const path = location.pathname
      const formattedString = path.substring(0, path.lastIndexOf('/'))
      return () => history.push(formattedString)
    }
  }

  return (
    <>
      <PageHeader
        data={{
          title: offer.title,
          avatar_url: offer.upload_uuid ? `/api/uploads/${offer.upload_uuid}` : undefined,
        }}
        onBack={getBackFunc()}
        actions={{
          others: (
            <>
              {canEditOffers && (
                <CabalButton
                  onClick={() => onEdit(offer)}
                  variant="link"
                  leftIcon={<i className="far fa-pencil" />}
                >
                  Edit
                </CabalButton>
              )}
            </>
          ),
        }}
      />
      <Card>
        <div className="flex">
          <div className="flex-1">
            <div className={cx(noTitle ? 'absolute right-4' : 'flex justify-between')}>
              {!noTitle && (
                <Link to={`/${teamSlug}/resources/${offer.uuid}`}>
                  <Typography lineHeight={1} color="primary" fontSize="16" fontWeight={700}>
                    {offer.title}
                  </Typography>
                </Link>
              )}
              {canEditOffers && <OfferOptions offer={offer} teamSlug={teamSlug} onEdit={onEdit} />}
            </div>

            {offer.website && offer.offer_type === 'people' && (
              <div className="flex items-center">
                <Typography
                  component="a"
                  href={offer.website}
                  rel="noreferrer"
                  target="_blank"
                  fontSize="12"
                  color={'purple'}
                >
                  <i className="fa-brands fa-linkedin pr-1"></i>
                  LinkedIn Profile
                </Typography>
              </div>
            )}

            {offer.location && offer.offer_type === 'people' && (
              <div className="flex items-center">
                <Typography
                  rel="noreferrer"
                  target="_blank"
                  fontSize="12"
                  component="p"
                  color="purple"
                >
                  <i className="fas fa-location-dot pr-1"></i>
                  {offer.location}
                </Typography>
              </div>
            )}

            <Typography fontSize="14" color="gray">
              <div
                ref={offerRef}
                dangerouslySetInnerHTML={{ __html: sanitize(offer.body) }}
                className={cx('ck-content', hasToTruncate && 'h-0 block overflow-hidden')}
              />
            </Typography>
            {originalText.length > 250 && (
              <Typography
                className="cursor-pointer"
                fontSize="14"
                color="purple"
                onClick={handleToggle}
              >
                {truncated ? 'show more' : ' show less'}
              </Typography>
            )}

            {offer.tags.length > 0 && (
              <div className="flex items-center space-x-1 mt-2">
                {offer.tags.map((tag, i) => (
                  <Wrapper key={i}>
                    <Typography
                      color={'purple'}
                      fontFamily={'mono'}
                      fontSize="12"
                      lineHeight={1.32}
                      component="p"
                    >
                      {tag}
                    </Typography>
                  </Wrapper>
                ))}
              </div>
            )}

            {offer.buttons.length !== 0 && (
              <div className="mt-3">{offer.buttons.map((b, i) => renderButton(b, i))}</div>
            )}
          </div>
        </div>
      </Card>
    </>
  )
}

export default ResourceView
