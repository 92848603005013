import React, { useEffect } from 'react'

import { useLocation, useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import DummyCompanyPage from 'components/DummyCompanyPage'
import LoginDropDown from 'containers/IntroRequestPublic/LoginDropDown'
import RequestIntroModal from 'containers/IntroRequestPublic/RequestIntroModal'
import IntroRequestPublicSuccess from 'containers/IntroRequestPublic/Success'
import { useTeam } from 'store/hooks'

import Logo from '../../images/logo.svg'
import { LengthWrapper, TopNavWrapper } from '../App/styles'
import { H3 } from '../SignupScreen/styles'

const RestrictedWidthContainer = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.breakpoints.restricted};
  width: ${({ theme }) => theme.breakpoints.restricted};
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  min-height: 100%;
`

const PageContainer = styled.div`
  height: 100vh; // Change to viewport height
  min-height: 100vh;
  overflow-y: auto; // Add scroll
`

const IntroRequestPublic = () => {
  const history = useHistory()
  const location = useLocation()

  const isSuccessPage = location.pathname.match(/\/intro-requests\/[^/]+\/[^/]+\/[^/]+\/success/)
  const { company_slug, list_id, item_id } = useParams<{
    company_slug: string
    list_id: string
    item_id: string
  }>()

  const { team } = useTeam(company_slug)

  const handleLogin = () => {
    const teamWorkspacePath = { pathname: `/${company_slug}` }
    const privateIntroRequestPath = {
      pathname: `/${company_slug}/lists/${list_id}/${item_id}`,
      search: '?intro=true',
    }
    history.push('/login', { from: isSuccessPage ? teamWorkspacePath : privateIntroRequestPath })
  }

  return (
    <PageContainer id="intro-request-public-page">
      <TopNavWrapper>
        <LengthWrapper>
          <a href="/" className="flex items-center cursor-pointer">
            <img src={Logo} alt="Cabal" height="32" width="27" />
            <H3>Cabal</H3>
          </a>
          <div className="flex items-center">
            <LoginDropDown onLogin={handleLogin} />
          </div>
        </LengthWrapper>
      </TopNavWrapper>

      <RestrictedWidthContainer>
        <div className="flex justify-center absolute top-8 left-0 right-0 z-20">
          <div className="flex h-5/6 justify-center items-center p-4 rounded-lg drop-shadow-lg">
            {isSuccessPage ? <IntroRequestPublicSuccess /> : <RequestIntroModal />}
          </div>
        </div>
        <div>
          <DummyCompanyPage team={team} />
        </div>
      </RestrictedWidthContainer>
    </PageContainer>
  )
}

export default IntroRequestPublic
