import React from 'react'

import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import PreviewableListSection from 'containers/CompanyPage/SharedListsSection/PreviewableListSection'
import EmptyState from 'global/EmptyState'
import Loading from 'global/Loading'
import { useAdvisorship, useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'

interface Props {
  teamSlug: string
  talentFilters: any
  view: string
  filters: any
}

const AdvisorListView: React.FC<Props> = ({ teamSlug, talentFilters, view, filters }) => {
  const { team } = useTeam(teamSlug)
  const { advisor } = useAdvisorship(teamSlug)
  const { list_id } = useParams<{ list_id?: string }>()

  const memberUuid = advisor?.uuid

  const {
    isLoading,
    refetch: refetchHome,
    data: getHomeListsData,
  } = useQuery(
    ['get_home_lists', teamSlug, list_id, talentFilters, filters],
    () => callApi(api.getHomeLists, teamSlug, list_id, talentFilters, filters),
    {
      enabled: true,
      refetchOnMount: 'always',
    },
  )

  const companyLists = Object.values(getHomeListsData?.lists || {})?.map((list) => list.list) || []

  const currentList = companyLists.find((list) => list.slug === list_id)

  if (isLoading || !memberUuid || !team || !list_id) {
    return (
      <>
        <Loading className="mt-4" />
      </>
    )
  } else if (!currentList) {
    return <>List not found</>
  }

  const noItems =
    getHomeListsData?.lists[currentList.slug]?.items_with_matches?.length === 0 &&
    getHomeListsData?.lists[currentList.slug]?.items_without_matches?.length === 0

  const renderEmptyState = () => {
    if (noItems) {
      if (currentList.list_type == 'companies') {
        return <EmptyState icon={<i className="fa-light fa-building" />} heading="No Companies" />
      } else if (currentList.list_type == 'people' && currentList.is_talent) {
        return (
          <EmptyState
            icon={<i className="fa-light fa-user-magnifying-glass" />}
            heading="No Candidates"
          />
        )
      } else if (currentList.list_type == 'people' && !currentList.is_talent) {
        return (
          <EmptyState
            icon={<i className="fa-light fa-user-magnifying-glass" />}
            heading="No People"
          />
        )
      } else if (currentList.list_type == 'personas') {
        return (
          <EmptyState icon={<i className="fa-light fa-address-card" />} heading="No Personas" />
        )
      }
    }
  }

  return (
    <div>
      <PreviewableListSection
        list={currentList}
        teamSlug={teamSlug}
        refetchLists={refetchHome}
        data={getHomeListsData?.lists[currentList.slug]}
        people={getHomeListsData?.people}
        view={view}
      />
      {renderEmptyState()}
    </div>
  )
}

export default AdvisorListView
