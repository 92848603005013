import React from 'react'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import { useAccessControl } from 'global/AccessControl'
interface Props {
  onClick?: () => void
  companySlug: string
  cta?: React.ReactNode
}

const PortfolioJobsEmptyState: React.VFC<Props> = ({ onClick, companySlug, cta }) => {
  const { canEditPortfolio } = useAccessControl(companySlug)

  const defaultCta = canEditPortfolio ? (
    <CabalButton
      leftIcon={<i className="far fa-plus" />}
      onClick={onClick}
      className="ml-auto order-2"
      data-testid="empty-state-add-jobs-btn"
    >
      Add Job
    </CabalButton>
  ) : null

  return (
    <EmptyState
      // image={<i className={`fas fa-folder fa-fw`} />}
      heading={'No jobs yet'}
      body={
        canEditPortfolio
          ? 'Start adding jobs to your portfolio companies'
          : 'Request admin to add jobs to portfolio companies'
      }
      cta={cta ? cta : defaultCta}
    />
  )
}

export default PortfolioJobsEmptyState
