import { ClassicCKEditor } from 'global/TextEditor/ckeditor/base'
import { isEqual } from 'lodash'
import { RefObject } from 'react'
import { cabalToast } from 'ui-components/Toast'
import ErrorLogger from 'utils/ErrorLogger'
import { replaceVariables } from 'utils/templates'
import { CurrentUserProfile, EmailSnippet, RequestBlueprintApiView } from 'utils/types'

export const selectSnippet = (
  snippet: EmailSnippet,
  user: CurrentUserProfile,
  ckeditorRef: RefObject<ClassicCKEditor>,
  request: RequestBlueprintApiView | null,
) => {
  let body = snippet.body
  const ckeditor = ckeditorRef.current

  if (!ckeditor) {
    const error = 'unable to set snippet because ckEditorRef is not defined'
    ErrorLogger.error(error)
    console.error(error)
    return
  }

  if (request) {
    body = replaceVariables(body, {
      request: request
        ? {
            company: request.company,
            data: request.data,
            person: request.person,
          }
        : undefined,
      user,
    })
  }

  // TODO maybe extract this to ckeditor

  const selection = ckeditor.model.document.selection
  const firstPos = selection.getFirstPosition()
  const lastPos = selection.getLastPosition()

  if (!firstPos) {
    ErrorLogger.error('Snippet insert position invalid!')
    cabalToast({ style: 'error', content: 'Error occurred! We have been notified' })
    return
  }

  if (lastPos && firstPos.root === lastPos.root && !isEqual(firstPos.path, lastPos.path)) {
    ckeditor?.execute('delete')
  }

  if (snippet.needsParaWrap) {
    body = `<p>${body}</p>`
  }

  const viewFragment = ckeditor.data.processor.toView(body)
  if (viewFragment) {
    const modelFragment = ckeditor.data.toModel(viewFragment as any)
    ckeditor.model.insertContent(modelFragment, firstPos)
  }
}
