import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { useQuery } from 'react-query'

import CabalButton from 'global/CabalButton'
import { CheckBox, MultiSelect, Select } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import { useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api from 'utils/api'
import { DigestSettings } from 'utils/types'

const TeamDigestSettings: React.VFC = () => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const admins = team?.admins_and_members.filter((a) => a.team_role === 'admin')

  const [digestSettings, setDigestSettings] = useState<DigestSettings>({
    enabled: false,
    new_connections: false,
    new_opps: false,
    connection_upvotes: false,
    member_updates: false,
    non_member_emails: false,
    helped_close: false,
    stage_changes: [],
    digest_admin_user_uuid: null,
  })
  const [saving, setSaving] = useState(false)
  const [stageNameOptions, setStageNameOptions] = useState([])

  useQuery(['stage_name', teamSlug], () =>
    axios
      .get('/api/opps/stage_names', { params: { team: teamSlug } })
      .then(({ data: { stages } }) => {
        setStageNameOptions(stages)
      }),
  )

  const onSubmit = () => {
    setSaving(true)
    api.setDigestSettings(teamSlug, digestSettings).then((response) => {
      const { digest_settings } = response.data
      setDigestSettings(digest_settings)
      setSaving(false)
      cabalToast({ style: 'success', content: 'Digest Settings Updated.' })
    })
  }

  useEffect(() => {
    api.getDigestSettings(teamSlug).then((response) => {
      const { digest_settings } = response.data
      setDigestSettings(digest_settings)
    })
  }, [teamSlug])

  const {
    enabled,
    new_opps,
    new_connections,
    stage_changes,
    connection_upvotes,
    member_updates,
    non_member_emails,
    digest_admin_user_uuid,
    helped_close,
  } = digestSettings || {}

  return (
    <Widget title="Digest Settings">
      {digestSettings && (
        <>
          <div className="flex flex-col gap-2">
            <CheckBox
              checked={enabled}
              onChange={(e) =>
                setDigestSettings({ ...digestSettings, enabled: e.currentTarget.checked })
              }
              label="Digests Enabled"
            />

            <CheckBox
              checked={enabled ? new_connections : false}
              disabled={!enabled}
              onChange={(e) =>
                setDigestSettings({ ...digestSettings, new_connections: e.currentTarget.checked })
              }
              label="Include new connections"
            />

            <CheckBox
              checked={enabled ? new_opps : false}
              disabled={!enabled}
              onChange={(e) =>
                setDigestSettings({ ...digestSettings, new_opps: e.currentTarget.checked })
              }
              label="Include new opportunites/deals"
            />

            <CheckBox
              checked={enabled ? connection_upvotes : false}
              disabled={!enabled}
              onChange={(e) =>
                setDigestSettings({
                  ...digestSettings,
                  connection_upvotes: e.currentTarget.checked,
                })
              }
              label="Include upvoted connections"
            />

            <CheckBox
              checked={enabled ? member_updates : false}
              disabled={!enabled}
              onChange={(e) =>
                setDigestSettings({
                  ...digestSettings,
                  member_updates: e.currentTarget.checked,
                })
              }
              label="Send member new dealboard connections"
            />

            <CheckBox
              checked={enabled ? non_member_emails : false}
              disabled={!enabled}
              label="Send digests to non-members"
              onChange={(e) =>
                setDigestSettings({
                  ...digestSettings,
                  non_member_emails: e.currentTarget.checked,
                })
              }
            />

            <CheckBox
              checked={helped_close}
              label="Send helped close celebration emails"
              onChange={(e) =>
                setDigestSettings({
                  ...digestSettings,
                  helped_close: e.currentTarget.checked,
                })
              }
            />

            <ModalInputWrapper className="mt-2">
              <ModalInputLabel>Stage names in digests</ModalInputLabel>
              <MultiSelect
                value={stage_changes}
                onChange={(stages) => {
                  setDigestSettings({ ...digestSettings, stage_changes: stages })
                }}
                placeholder="Opps/deals that move to these stages are added to digests"
                isClearable
                options={stageNameOptions.map((o) => ({
                  label: o.name,
                  value: o.name,
                }))}
                className="mb-2 mt-2"
              />
            </ModalInputWrapper>
            <ModalInputWrapper>
              <ModalInputLabel>Select which admin gets the digest</ModalInputLabel>
              <Select
                options={
                  admins?.map((adv) => ({
                    label: adv.name,
                    value: adv.uuid,
                  })) || []
                }
                value={digest_admin_user_uuid || ''}
                onChange={(v) =>
                  setDigestSettings({ ...digestSettings, digest_admin_user_uuid: v })
                }
                placeholder="Select admin receiving digest"
              />
            </ModalInputWrapper>
          </div>

          <div className="flex justify-end mt-6">
            <CabalButton variant="primary" disabled={saving} working={saving} onClick={onSubmit}>
              Save
            </CabalButton>
          </div>
        </>
      )}
    </Widget>
  )
}

export default TeamDigestSettings
