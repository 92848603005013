import axios, { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { REQUEST_CENTER_ENDPOINTS } from '../apiEndpoints'
import { IFetchIntroRequestDetails, IIntroRequestDetailsResponse } from '../types/ITypes'

const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')

const fetchIntroRequestDetails = async (
  uuid: string,
): Promise<AxiosResponse<IIntroRequestDetailsResponse>> => {
  return await axios.get<IIntroRequestDetailsResponse>(
    `${REQUEST_CENTER_ENDPOINTS.getIntroRequestDetails}/${uuid}`,
    {
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      },
    },
  )
}

export const useFetchIntroRequestDetails = ({ uuid, enabled }: IFetchIntroRequestDetails) =>
  useQuery({
    queryKey: ['fetchIntroRequestDetails', uuid],
    queryFn: () => fetchIntroRequestDetails(uuid),
    enabled: enabled && !!uuid,
    retry: 1,
  })
