import React from 'react'

import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import Defaults from 'containers/Settings/Company/Defaults'
import TeamDigestSettings from 'containers/Settings/Company/TeamDigestSettings'
import { useAccessControl } from 'global/AccessControl'
import { useTeam } from 'store/hooks'
import SectionHeader from 'ui-components/SectionHeader'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { CompanySlugParam, EmailTemplate, Team } from 'utils/types'

import DraftOnBehalf from './DraftOnBehalf'
import EmployeeViewSettings from './EmployeeView'

const AskSettings: React.VFC = () => {
  const { company_slug } = useParams<CompanySlugParam>()
  const { team, reloadTeam } = useTeam(company_slug)
  const { canViewMessageTemplates } = useAccessControl(company_slug)

  const updateTeamMutation = useMutation(
    ['updateTeam'],
    (val: Partial<Team>) => callApi(api.updateTeam, company_slug, { team: val }),
    {
      onSuccess: () => {
        reloadTeam()
        cabalToast({ style: 'success', content: 'Changes saved' })
      },
    },
  )

  const updateTemplateMutation = useMutation(
    ['upsertTemplate'],
    (template: EmailTemplate) => callApi(api.updateTemplate, template),
    {
      onSuccess: () => {
        getTemplatesQuery.refetch()
        cabalToast({ style: 'success', content: 'Changes saved' })
      },
    },
  )

  const getTemplatesQuery = useQuery(
    ['getTemplates', company_slug],
    () => callApi(api.getTemplates, company_slug),
    { enabled: canViewMessageTemplates },
  )

  return (
    <div className="flex flex-col gap-4 mt-4">
      <TeamDigestSettings />
      <Defaults
        getTemplatesQuery={getTemplatesQuery}
        updateTemplateMutation={updateTemplateMutation}
      />

      {/* <Widget title="Other Settings">
        <SectionHeader title="Employee View" />
        <EmployeeViewSettings
          updateTemplateMutation={updateTemplateMutation}
          getTemplatesQuery={getTemplatesQuery}
        />
        <SectionHeader title="Draft on Behalf" />
        <DraftOnBehalf updateTeamMutation={updateTeamMutation} />
      </Widget> */}

      {/* <Setup /> */}
    </div>
  )
}

export default AskSettings
