import React, { useState } from 'react'

import { filter, remove } from 'lodash'
import { useMutation } from 'react-query'

import GlobalCompanySelect from 'components/GlobalCompanySelect'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Modal, { ModalComponentProps } from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Pill from 'global/Pill'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { Activity, GlobalCompanyBlueprint } from 'utils/types'

import { getData } from './ActivityRow'

interface Props extends ModalComponentProps {
  teamSlug: string
  activity: Activity
  onSave: () => void
}

const ActivityCompanyAttributionModal: React.FC<Props> = ({
  teamSlug,
  activity,
  resolve,
  onSave,
}) => {
  const { title } = getData({ activity })
  const [companies, setCompanies] = useState<GlobalCompanyBlueprint[]>(
    activity.global_companies || [],
  )

  const updateActivityMutation = useMutation(
    () =>
      callApi(
        api.attachCompaniesToUserActivity,
        teamSlug,
        activity.uuid,
        companies.map((c) => c.uuid),
      ),
    {
      onSuccess: () => {
        onSave()
        resolve()
      },
    },
  )

  return (
    <Modal
      header="Add Company to activity"
      show
      onHide={resolve}
      rightActions={
        <CabalButton
          onClick={() => updateActivityMutation.mutate()}
          working={updateActivityMutation.isLoading}
        >
          Save
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Activity</ModalInputLabel>
          <Typography fontSize="13">{title}</Typography>
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Add Companies</ModalInputLabel>
          <div>
            <div>
              {companies.map((c) => (
                <Pill
                  variant="outline"
                  facePile={<Avatar size="20" src={c.logo_url} name={c.name} />}
                  rounded
                >
                  <div className="inline-block ml-1.5">{c.name}</div>
                  <button
                    className="ml-1.5"
                    onClick={() => setCompanies(filter(companies, (i) => i.uuid !== c.uuid))}
                  >
                    <i className="far fa-times" />
                  </button>
                </Pill>
              ))}
            </div>
            <div>
              <GlobalCompanySelect
                key={`global-company-select-${companies.length}`}
                onChange={(v) => v && setCompanies([...companies, v])}
                placeholder={'Search companies'}
              />
            </div>
          </div>
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default ActivityCompanyAttributionModal
