import React, { useCallback, useMemo, useState } from 'react'

import uniqBy from 'lodash/uniqBy'
import { useQuery } from 'react-query'

import CompanySelector from 'components/CompanySelector'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { CheckBox } from 'global/Input'
import Loading from 'global/Loading'
import Modal, { ModalableComponentProps } from 'global/Modal'
import { ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { AutoCompleteCompany, UserProfile, UserProfilePortfolio } from 'utils/types'

import { UpdateProfileMutation } from '.'

interface Props {
  profile: UserProfile
  updateProfileMutation: UpdateProfileMutation
}

const EditPublicPortfolio: React.VFC<Props & ModalableComponentProps> = ({
  resolve,
  profile,
  updateProfileMutation,
  modal,
  onSubmit,
}) => {
  const [publicPortfolio, setPublicPortfolio] = useState(profile.profile_data.portfolio || [])
  const [customCompanies, setCustomCompanies] = useState<UserProfilePortfolio[]>([])

  const toggleAll = () => {
    if (areAllSelected) {
      setPublicPortfolio([])
    } else {
      setPublicPortfolio(companies)
    }
  }

  const getPortfolioCompaniesQuery = useQuery(
    ['profileCompanies'],
    () => callApi(api.getProfileCompanies),
    {
      onSuccess: (data) => {
        if (!publicPortfolio.length) {
          setPublicPortfolio(data.companies)
        }
      },
    },
  )
  const companies = useMemo(() => {
    let companies = getPortfolioCompaniesQuery.data?.companies || []
    companies = customCompanies.concat(companies)

    return uniqBy(companies, (c) => (c.type === 'Team' ? c.slug : c.domain))
  }, [getPortfolioCompaniesQuery.data, customCompanies])

  const areAllSelected = useMemo(() => {
    return publicPortfolio.length === companies.length
  }, [publicPortfolio, companies])

  const onContinue = () => {
    updateProfileMutation
      .mutateAsync({
        ...profile,
        profile_data: { ...profile.profile_data, portfolio: publicPortfolio },
      })
      .then(() => {
        resolve?.()
        onSubmit?.()
      })
  }

  const renderTeamRow = (
    name: string,
    logo: string | null | undefined,
    checked: boolean,
    onCheck: () => void,
  ) => {
    return (
      <div key={name}>
        <CheckBox
          label={
            <span>
              <Avatar name={name} src={logo || undefined} round size="20" className="mr-2" />
              {name}
            </span>
          }
          checked={checked}
          onChange={onCheck}
          className="w-full pb-0.5"
        />
      </div>
    )
  }

  const isSelected = useCallback(
    (portCo: UserProfilePortfolio) => {
      return (
        publicPortfolio.findIndex((p) =>
          p.type === portCo.type && portCo.type === 'Team'
            ? p.slug === portCo.slug
            : portCo.domain === p.domain,
        ) > -1
      )
    },
    [publicPortfolio],
  )

  const onCheck = (portCo: UserProfilePortfolio) => {
    if (isSelected(portCo)) {
      if (portCo.type === 'Team') {
        setPublicPortfolio(publicPortfolio.filter((p) => p.slug !== portCo.slug))
      } else {
        setPublicPortfolio(publicPortfolio.filter((p) => p.domain !== portCo.domain))
      }
    } else {
      setPublicPortfolio([...publicPortfolio, portCo])
    }
  }

  const { isLoading: isSaving } = updateProfileMutation
  const { isFetching } = getPortfolioCompaniesQuery

  const children = (
    <>
      {!modal && (
        <Typography fontSize="14" fontWeight={600}>
          Add your portfolio companies
        </Typography>
      )}
      <CompanySelector
        onCompanySelected={(newCo) => {
          if (!newCo) return
          let co: UserProfilePortfolio

          if (newCo.has_access) {
            co = {
              type: 'Team',
              name: newCo.name,
              domain: newCo.domain,
              slug: newCo.team_slug!,
              logo_url: newCo.logo,
            }
          } else {
            co = {
              type: 'CustomTeam',
              name: newCo.name,
              domain: newCo.domain!,
              logo_url: newCo.logo,
            }
          }

          onCheck(co)
          setCustomCompanies([...customCompanies, co])
        }}
        apiParams={{ exclude_teams: true }}
        placeholder="Type to search for companies"
        showAddButtonIfCreate
        className="mb-4 mt-1"
      />

      <div className="space-y-3">
        {!isFetching && !!companies.length && (
          <Typography
            fontSize="12"
            component="button"
            color="fog_rain"
            className="hover:underline float-right"
            onClick={toggleAll}
          >
            {areAllSelected ? 'Deselect' : 'Select'} All
          </Typography>
        )}
        {isFetching && <Loading className="my-3" />}
        {!isFetching &&
          companies?.map((portCo) =>
            renderTeamRow(portCo.name, portCo.logo_url, isSelected(portCo), () => onCheck(portCo)),
          )}
      </div>
    </>
  )

  if (modal) {
    return (
      <Modal
        show
        onHide={() => resolve()}
        header={`Edit Portfolio`}
        rightActions={
          <CabalButton onClick={() => onContinue()} variant="primary" working={isSaving}>
            Save
          </CabalButton>
        }
      >
        <ModalSectionWrapper>{children}</ModalSectionWrapper>
      </Modal>
    )
  }

  return (
    <div>
      {children}
      <div className="flex justify-end mt-3">
        <CabalButton onClick={() => onContinue()} variant="primary" working={isSaving}>
          Save
        </CabalButton>
      </div>
    </div>
  )
}

export default EditPublicPortfolio
