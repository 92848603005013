import * as React from 'react'

import { EditListModal } from 'components/EditList'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import { RenderModal, useModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

interface Props {
  hasSyncedSales?: boolean
  reload: () => void
}

const SalesEmptyState: React.FC<Props> = ({ hasSyncedSales, reload }) => {
  const { showModal } = useModal()
  const teamSlug = useTeamSlug()

  const renderNewListModal: RenderModal = (resolve) => (
    <EditListModal dynamic={false} onHide={() => resolve(false)} teamSlug={teamSlug} />
  )

  return (
    <EmptyState
      heading={hasSyncedSales ? null : 'Connect your CRM'}
      image={
        <Typography color="fog_rain" fontSize="24">
          <i className="fal fa-funnel-dollar"></i>
        </Typography>
      }
      body={
        hasSyncedSales
          ? 'No connections found'
          : 'Cabal surfaces connections to companies in your sales pipeline.'
      }
      cta={
        !hasSyncedSales && (
          <div className="flex flex-col items-center space-y-2">
            <CabalButton
              component="a"
              leftIcon={<i className="fab fa-salesforce" />}
              href="/salesforce_auth"
              className="w-1/4"
            >
              Connect Salesforce
            </CabalButton>
            <CabalButton
              component="a"
              leftIcon={<i className="fab fa-hubspot ml-2" />}
              href={`/auth/${
                window.location.host === 'localhost:3000' ? 'hubspotdev' : 'hubspot'
              }?return_to=${window.location.href}`}
              className="w-1/4"
            >
              Connect HubSpot
            </CabalButton>
            <CabalButton
              variant="secondary"
              leftIcon={<i className="fas fa-list ml-2" />}
              onClick={() => showModal(renderNewListModal, 'render_new_list_modal')}
              className="w-1/4"
            >
              Add company list
            </CabalButton>
          </div>
        )
      }
    />
  )
}

export default SalesEmptyState
