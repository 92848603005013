import React, { useState } from 'react'

import { CompanyLandingPage } from 'components/Winsite/CompanyLandingPage'
import { FetchCompanyDomain } from 'components/Winsite/FetchCompanyDomain'
import { Layout } from 'components/Winsite/Layout'
import { WINSITE_ENDPOINTS } from 'components/Winsite/apiEndpoints'
import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Typography from 'global/Typography'

import { Spinner } from './Spinner'
import { useCompanyPage } from './queries/CompanyPage'
import { Card } from './styles'

interface IProps {
  companyName: string
}

export function FetchLinkedinUrl({ companyName }: IProps) {
  const linkedinUrlPage = `${WINSITE_ENDPOINTS.linkedinUrl}${companyName}`
  const [linkedinUrl, setLinkedinUrl] = useState<string>('')
  const [submission, setSubmission] = useState<boolean>(false)
  const {
    refetch,
    data: teamData,
    isLoading,
  } = useCompanyPage(
    { companyName: companyName, linkedinUrl: linkedinUrl, companySlug: null, companyDomain: null },
    { enabled: false, retry: 3 },
  )
  const handleSubmit = () => {
    setSubmission(true)
    refetch()
  }
  if (isLoading) {
    return <Spinner />
  }
  if (teamData && submission && !teamData.data.company_domain) {
    return <FetchCompanyDomain companyName={companyName} linkedinUrl={teamData.data.linkedin_url} />
  }
  if (teamData && submission) {
    return (
      <CompanyLandingPage
        teamData={teamData.data}
        companyName={companyName}
        linkedinUrl={linkedinUrl}
        spinnerDisplayProp={true}
      />
    )
  }

  return (
    <Layout>
      <div className="flex justify-center items-center min-h-[60vh]">
        <Card className="w-[368px] sm:min-w-[500px]">
          <div className="m-3 mb-2 sm:m-6 flex flex-col gap-y-4 sm:flex-row justify-between sm:items-center">
            <div className="flex flex-row sm:flex-col mr-5 flex-shrink-0 sm:items-start justify-between sm:justify-normal">
              <Typography color="black" fontSize="14" className="">
                LinkedIn URL
              </Typography>
              <CabalButton
                variant="link"
                type="button"
                rightIcon={<i className="far fa-copy"></i>}
                style={{ color: '#03C168' }}
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(linkedinUrlPage, '_blank', 'noopener noreferrer')
                }}
                padding="0"
                tabIndex={-1}
              >
                Get link
              </CabalButton>
            </div>
            <TextInput
              placeholder="LinkedIn URL"
              className="w-full"
              value={linkedinUrl}
              style={{ backgroundColor: 'rgb(243, 244, 246)', borderColor: 'rgb(227, 229, 232)' }}
              onChange={(e) => setLinkedinUrl(e.target.value)}
            />
          </div>
          <div className="pb-1 flex items-center justify-center w-full">
            <CabalButton
              className="mt-6 mb-2 self-end"
              variant="primary"
              type="submit"
              style={{ backgroundColor: '#03C168' }}
              onClick={handleSubmit}
            >
              Continue
            </CabalButton>
          </div>
        </Card>
      </div>
    </Layout>
  )
}
