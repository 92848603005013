import React, { useState } from 'react'

import { useMutation } from 'react-query'
import styled from 'styled-components'

import CabalButton from 'global/CabalButton'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { UserEmail } from 'utils/types'

const EmailWrapper = styled.div`
  border: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.layout.main_bg_color};
`
interface Props {
  email: UserEmail
  refetchEmails: () => void
}

const EmailRow: React.FC<Props> = ({ email, refetchEmails }) => {
  const [resent, setResent] = useState(false)

  const removeEmailMutation = useMutation(
    (emailId: number) => callApi(api.removeUserEmail, emailId),
    {
      onSuccess: () => {
        refetchEmails()
        cabalToast({ style: 'success', content: 'Successfully removed email' })
      },
    },
  )

  const resendEmailTokenMutation = useMutation(
    (emailId: number) => callApi(api.resendUserEmailToken, emailId),
    {
      onSuccess: () => {
        refetchEmails()
        cabalToast({ style: 'success', content: 'Email verification link sent' })
      },
    },
  )

  const handleRemoveEmail = () => {
    if (!confirm('Are you sure you want to remove this email?')) return
    return removeEmailMutation.mutateAsync(email.id)
  }

  const handleResendToken = () => {
    resendEmailTokenMutation.mutate(email.id)
    setResent(true)
  }

  return (
    <div>
      <EmailWrapper className="rounded px-2 py-1 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Typography fontSize="14" lineHeight={1} color="fog">
            {email.email}
          </Typography>
          {email.isValidated ? (
            <Tooltip label="Email is validated">
              <Typography
                component="i"
                color="green"
                fontSize="16"
                className="fas fa-check-circle"
              />
            </Tooltip>
          ) : resent ? (
            <Tooltip label="Unverified. Verification link was resent to this email">
              <Typography
                component="i"
                color="yellow"
                fontSize="16"
                className="fas fa-exclamation-circle"
              />
            </Tooltip>
          ) : (
            <Tooltip label="Unverified. Verification link was sent to this email">
              <Typography
                component="i"
                color="yellow"
                fontSize="16"
                className="fas fa-exclamation-circle"
              />
            </Tooltip>
          )}
        </div>
        <div>
          {!email.isUserEmail && (
            <div className="flex gap-2">
              {!email.isValidated && !resent && (
                <CabalButton variant="tertiary" size="small" onClick={() => handleResendToken()}>
                  Send verification link
                </CabalButton>
              )}

              <CabalButton variant="tertiary" size="small" onClick={() => handleRemoveEmail()}>
                Remove
              </CabalButton>
            </div>
          )}
        </div>
      </EmailWrapper>
    </div>
  )
}

export default EmailRow
