import * as React from 'react'

import Expand from 'react-expand-animated'
import { useInfiniteQuery, useQuery } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import ConnectionsTable from 'containers/ListView/People/ConnectionsTable'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import UploadZone from 'global/Input/UploadZone'
import Loading from 'global/Loading'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import team from 'utils/api/team'
import { AdvisorModel } from 'utils/types'

import ImportExplainer from './ImportExplainer'

interface Props {
  advisor: AdvisorModel
  teamSlug: string
}

const ProgressBarInner = styled.div`
  background-color: ${({ theme }) => theme.buttons.primary.bg_color};
`

const StyledCard = styled.div<{ border: boolean }>`
  ${tw`py-2`}
  border-radius: 8px;
  transition: background-color 200ms ease;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: ${({ theme, border }) => (border ? `1px solid ${theme.colors.border}` : 'none')};
`

const Summary = styled.div<{ skinny: boolean }>`
  ${tw`m-2 flex items-center justify-between`}
`

const Border = styled.div`
  height: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`

const LogoArea = styled.div`
  @media (max-width: 640px) {
    display: none;
  }
`

const LinkedInLogo = styled.i`
  color: #2d64bc;
  font-size: 16px;
`

function AdvisorConnections({ advisor, teamSlug }: Props) {
  const [expanded, setExpanded] = React.useState(false)
  const [showProgress, setShowProgress] = React.useState(false)

  const { data: statusData, isLoading: statusLoading } = useQuery(
    ['advisorConnectionImportStatus', advisor.uuid],
    () => api.advisorConnectionImportStatus(advisor.uuid),
    {
      refetchInterval: 3000,
    },
  )

  const advisorConnectionsQuery = useInfiniteQuery(
    ['getAdvisorConnections', teamSlug, advisor.uuid],
    ({ pageParam: page = 1 }) => callApi(api.getAdvisorConnections, advisor.uuid, teamSlug, page),
    {
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      enabled: !!advisor,
    },
  )

  React.useEffect(() => {
    if (
      !statusLoading &&
      statusData?.data?.shared_count === 0 &&
      statusData?.data?.second_party_count === 0
    ) {
      setExpanded(true)
    }
  }, [statusLoading, statusData?.data?.shared_count, statusData?.data?.second_party_count])

  const people = advisorConnectionsQuery.data?.pages?.flatMap((page) => page.people)

  if (!statusLoading) {
    // no import running or import already complete - stop polling
    if (
      statusData?.data?.import_total_rows === 0 ||
      (statusData?.data?.import_total_rows > 0 &&
        statusData?.data?.import_processed_rows === statusData?.data?.import_total_rows)
    ) {
      if (showProgress) {
        setShowProgress(false)
        // cabalToast({ style: 'success', content: 'Successfully imported connections' })
      }
    }

    // import running - show progress
    if (
      statusData?.data?.import_total_rows > 0 &&
      statusData?.data?.import_processed_rows < statusData?.data?.import_total_rows
    ) {
      if (!showProgress) {
        setShowProgress(true)
        setExpanded(true)
      }
    }
  }

  const renderDropZone = () => {
    return (
      <UploadZone
        uploadOnSelect
        accept={['csv', 'zip']}
        onUpload={(upload) => {
          if (!upload.length) return
          api.adminImportConnections(advisor.uuid, team?.slug, upload[0].uuid).then((response) => {
            setShowProgress(true)
          })
        }}
        prompt={
          <>
            <Typography
              className="py-1.5"
              fontSize="24"
              fontWeight={500}
              color="primary"
              component="div"
            >
              <i className="fab fa-linkedin"></i>
            </Typography>
            <Typography
              className="py-1.5"
              fontSize="16"
              fontWeight={500}
              color="primary"
              component="div"
            >
              Drop {advisor?.first_name}'s LinkedIn connections file here
            </Typography>
            <div className="py-1.5">
              Drag and drop to upload or <Typography color="purple">click to browse</Typography>
            </div>
          </>
        }
        hideFiles
        style={{ paddingBottom: '10px' }}
      />
    )
  }

  const ProgressBar = ({ percent }: any) => (
    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
      <ProgressBarInner
        className="bg-blue-600 h-2.5 rounded-full"
        style={{ width: `${percent}%` }}
      ></ProgressBarInner>
    </div>
  )

  const renderImportProgress = () => {
    return (
      <>
        Imported {statusData?.data?.import_processed_rows} of {statusData?.data?.import_total_rows}
        <ProgressBar
          percent={
            (statusData?.data?.import_processed_rows / statusData?.data?.import_total_rows) * 100
          }
        />
      </>
    )
  }

  return (
    <div>
      <>
        <StyledCard border={false}>
          <>
            <Summary skinny={false}>
              <div className="flex items-left space-x-2">
                {/* <div className="shrink-0">
                <Avatar size={'24'} name={advisor?.name} src={advisor?.avatar_url} />
              </div> */}
                <div>
                  <Typography fontSize="12" color="fog" fontWeight={300}>
                    Imported by admin:{' '}
                  </Typography>
                  <Typography fontSize="12" color="fog" fontWeight={300}>
                    {showProgress ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : (
                      statusData?.data?.second_party_count || 0
                    )}
                  </Typography>
                </div>
                <Typography fontSize="12" color="fog" fontWeight={300}>
                  ·
                </Typography>
                <div>
                  <Typography fontSize="12" color="fog" fontWeight={300}>
                    Shared by member:{' '}
                  </Typography>
                  <Typography fontSize="12" color="fog" fontWeight={300}>
                    {statusData?.data?.shared_count.toLocaleString() || 0}
                  </Typography>
                </div>
              </div>
              <div className="flex justify-end">
                <CabalButton variant="secondary" onClick={() => setExpanded(!expanded)}>
                  {expanded ? 'Hide Import' : 'Import Connections'}
                </CabalButton>
              </div>
            </Summary>
          </>
        </StyledCard>
        <Expand open={expanded}>
          <>
            {statusData?.data?.import_error && (
              <Typography color="red" fontSize="14" fontWeight={600}>
                {statusData?.data?.import_error}
              </Typography>
            )}
            {!showProgress && renderDropZone()}
            {showProgress && renderImportProgress()}
          </>
        </Expand>
      </>

      {advisorConnectionsQuery.isLoading ? (
        <div className="flex justify-center items-center mt-20">
          <Loading />
        </div>
      ) : (
        people &&
        people.length > 0 && (
          <div className="mt-4">
            <ConnectionsTable
              people={people}
              onRefetch={() => {
                advisorConnectionsQuery.refetch()
              }}
            />
          </div>
        )
      )}
      {advisorConnectionsQuery && advisorConnectionsQuery.hasNextPage && (
        <div className="flex justify-center mt-4">
          <CabalButton
            className="cursor-pointer"
            variant="secondary"
            disabled={advisorConnectionsQuery?.isFetchingNextPage}
            onClick={() => {
              advisorConnectionsQuery.fetchNextPage()
            }}
          >
            Load More
          </CabalButton>
        </div>
      )}
    </div>
  )
}

export default AdvisorConnections
