import React, { useEffect, useRef, useState } from 'react'

import { compact, concat, uniqBy, uniqWith } from 'lodash'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import Activity from 'components/CommentsAndActivity/Activity'
import Comment from 'components/CommentsAndActivity/Comment'
import CabalButton from 'global/CabalButton'
import RichTextInput, { RichTextInputRef } from 'global/Input/RichTextInput'
import Loading from 'global/Loading'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useAdvisors, useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { ActivityModel, Comment as CommentType, MessageModel } from 'utils/types'

const CommentsContainer = styled.div`
  max-height: 50vh;
  overflow: auto;
`

const InputContainer = styled.div`
  .ql-editor.ql-blank {
    height: auto;
  }
  .ql-mention-list {
    color: ${({ theme }) => theme.colors.primary};
  }

  .ql-mention-list-container {
    padding: 4px;
    background-color: ${({ theme }) => theme.colors.shadow};
    border: none;
  }

  .ql-mention-list-item {
    border-radius: 4px;
    padding: 8px 6px;
  }

  .ql-mention-list-item.selected {
    background-color: #5c69d1;
    color: #fff;
  }
`

const InputStyle = styled.div`
  ${tw`px-2 rounded mt-2`};
  border: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.colors.primary_bg};
`

interface Props {
  message?: MessageModel
  messageThreadId?: string | null
  externalId?: string
  externalType?: string
  teamSlug: string
  showReply?: boolean
}

const CalendarComments: React.VFC<Props> = ({
  message,
  externalId,
  externalType,
  teamSlug,
  messageThreadId: _messageThreadId = null,
  showReply,
}) => {
  const [comments, setComments] = useState<CommentType[]>([])
  const [body, setBody] = useState<string>('')
  const { team } = useTeam(teamSlug)
  const { advisors } = useAdvisors({ teamSlug })
  const [messageThreadUuid, setMessageThreadUuid] = useState<string | null>(null)
  const richTextInputRef = useRef<RichTextInputRef>(null)
  const commentsContainerRef = useRef<HTMLDivElement>(null)

  const afterTimestamp = comments.reduce<string | null>((greatest, curr) => {
    if (!greatest) return curr.updated_at

    if (greatest < curr.updated_at) return curr.updated_at
    return greatest
  }, null)

  const { isLoading } = useQuery(
    message?.uuid
      ? ['getComments', teamSlug, message.uuid, messageThreadUuid]
      : ['getComments', teamSlug, externalType, externalId],
    () =>
      callApi(api.getComments, {
        team_slug: teamSlug,
        message_uuid: message?.uuid,
        message_thread_uuid: messageThreadUuid,
        external_id: externalId,
        external_type: externalType,
        after_timestamp: afterTimestamp ? new Date(afterTimestamp).getTime() : null,
      }),
    {
      onSuccess: ({ comments }) => {
        setComments((p) => uniqWith([...p, ...comments], (a, b) => a.uuid === b.uuid))
      },
      refetchInterval: 60000,
    },
  )

  useEffect(() => {
    setComments([])
    setMessageThreadUuid(_messageThreadId)
  }, [_messageThreadId])

  const { mutate: createComment, isLoading: creatingComments } = useMutation(
    (mentionIds: string[]) =>
      callApi(api.createComment, {
        team_slug: teamSlug,
        message_uuid: message?.uuid,
        message_thread_uuid: messageThreadUuid,
        external_id: externalId,
        external_type: externalType,
        body: body || '',
        mention_ids: mentionIds,
      }),
    {
      onSuccess: ({ comment }) => {
        setComments((p) => [...p, comment])
        setBody('')
      },
    },
  )

  useEffect(() => {
    commentsContainerRef.current?.scrollTo({ top: commentsContainerRef.current.scrollHeight })
  }, [comments.length])

  let commentsAndActivity = [
    ...comments.map((c) => ({ ...c, type: 'comment' })),
    ...(message?.activity || []).map((a) => ({ ...a, type: 'activity' })),
  ].sort((a, b) => {
    return moment(a.created_at).isBefore(moment(b.created_at)) ? -1 : 1
  })

  commentsAndActivity = uniqBy(commentsAndActivity, 'uuid')

  const mentionables = compact(
    concat<{ uuid: string; name: string; avatar_url?: string } | undefined>(
      advisors,
      team?.admins_and_members,
    ),
  ).map((f) => ({
    id: f.uuid,
    value: f.name,
    avatar: f.avatar_url,
  }))

  return (
    <div>
      <CommentsContainer ref={commentsContainerRef}>
        {commentsAndActivity.map((c) =>
          c.type === 'comment' ? (
            <Comment
              key={c.type + ' ' + c.created_at}
              comment={c as CommentType}
              teamSlug={teamSlug}
            />
          ) : (
            <Activity key={c.type + ' ' + c.created_at} activity={c as ActivityModel} />
          ),
        )}
      </CommentsContainer>

      {showReply && (
        <>
          <InputStyle>
            <InputContainer className="-ml-3">
              <RichTextInput
                ref={richTextInputRef}
                placeholder="Add a comment"
                onChange={setBody}
                suggestions={mentionables}
              />
            </InputContainer>
          </InputStyle>

          <div className="flex justify-end pb-2 pt-3 space-x-1">
            {body && (
              <CabalButton
                variant="tertiary"
                onClick={() => setBody('')}
                disabled={!body}
                size="medium"
              >
                Cancel
              </CabalButton>
            )}
            <CabalButton
              variant="primary"
              onClick={() => {
                if (!richTextInputRef.current) return

                createComment(richTextInputRef.current.getMentions().map((m) => m.id as string))
              }}
              working={creatingComments}
              disabled={!body}
              size="medium"
            >
              Post
            </CabalButton>
          </div>
        </>
      )}
    </div>
  )
}

export default CalendarComments
