import React from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import CompanyOptions from 'containers/OnboardingV2/CompanyOptions'
import Invite from 'containers/OnboardingV2/Invite'
import LastStep from 'containers/OnboardingV2/LastStep'
import VCOptions from 'containers/OnboardingV2/VCOptions'
import Workspace from 'containers/OnboardingV2/Workspace/Workspace'
import { useAccessControl } from 'global/AccessControl'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'

import BgImage from '../../../../public/desktopnew-bg.png'
import NoiseDark from '../../../../public/noise-dark.png'
import Logo from '../../images/logo.svg'
import OrgDetails from './OrgDetails'
import ProfileSetup from './ProfileSetup'

const Background = styled.div`
  background-color: #0e151f;
  background-image: url(${BgImage}), url(${NoiseDark});
  background-repeat: no-repeat, repeat;
  background-size: 100% auto, auto;
`

const OnboardingV2 = () => {
  const { user } = useCurrentUser()
  const { team } = useTeam(user.team?.slug || null)
  const [selectedSlug, setSelectedSlug] = React.useState<string>('')

  const { isAdmin, isAdvisor } = useAccessControl(user.team?.slug)

  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'linear',
  })

  const [step, setStep] = React.useState(0)

  const VCFirm = team?.enable_portfolio || team?.enable_offers

  return (
    <Background className="w-full h-screen">
      <div ref={parent} className="sm:max-w-[990px] mx-auto mt-10">
        <div className="flex items-center px-1">
          <img src={Logo} alt="Cabal" height="32" width="27" />
          <Typography
            fontWeight={600}
            fontSize="20"
            color="white"
            className="ml-2.5 tracking-tighter"
          >
            Cabal
          </Typography>
        </div>
        {step === 0 && <ProfileSetup onContinue={() => setStep(1)} />}
        {step === 1 && (
          <Workspace
            onContinue={() => setStep(2)}
            onSkipTo={setStep} // Logic implemented in Workspace/CompanyRow.tsx
            selectSlug={setSelectedSlug}
          />
        )}
        {step === 2 && <OrgDetails onContinue={() => setStep(3)} />}
        {step === 3 && <Invite onContinue={() => setStep(4)} />}
        {step === 4 && !VCFirm && <CompanyOptions onContinue={() => setStep(5)} />}
        {step === 4 && VCFirm && <VCOptions onContinue={() => setStep(5)} />}

        {/* This always needs to be the last step for exit animation to work */}
        {step === 5 && <LastStep teamSlug={team?.slug} selectedSlug={selectedSlug} />}

        {/* {step === 5 && <ListsSetup onContinue={() => history.push(`/${team?.slug}`)} />} */}
        {/* {step === 2 && <Theme onContinue={() => setStep(3)} />} */}
        {/* {step === 3 && <SellerType onContinue={() => setStep(4)} />} */}
        {/* {step === 3 && <FirmFocus onContinue={() => setStep(4)} />} */}
      </div>
    </Background>
  )
}

export default OnboardingV2
