import * as React from 'react'

import moment from 'moment'
import styled from 'styled-components'
import tw from 'twin.macro'

import Modal from 'global/Modal'
import Typography from 'global/Typography'

import { GetMessageEngagementResponse } from 'utils/types'

interface Props {
  show: boolean
  onHide: () => void
  data: GetMessageEngagementResponse['data']
}

const NameCol = styled.div`
  ${tw`pr-1`}
  flex-grow: 1;
  line-height: 1;
`

const DateCol = styled.div`
  ${tw`pr-1`}
  flex-shrink: 0;
`

const DeviceCol = styled.div`
  ${tw`pr-1`}
  flex-shrink: 0;
  width: 8%;
  text-align: right;
`

const StatsModal: React.VFC<Props> = ({ show, data, onHide }) => {
  const events = data.events

  return (
    <Modal size="sm" show={show} onHide={onHide} header={`Engagement Activities`}>
      <div className="mb-6">
        {events.map((e) => (
          <div key={e.id} className="flex justify-between items-center mb-2">
            <NameCol>
              <Typography fontSize="14" lineHeight="1">
                {e.user_name}
              </Typography>
            </NameCol>
            <DateCol>
              <Typography fontSize="12" color="rain">
                {e.description} {moment(e.created_at).fromNow()}
              </Typography>
            </DateCol>
            <DeviceCol>
              <Typography color="rain">
                {e.device_type === 'Desktop' && <i className="far fa-laptop fa-fw text-xs"></i>}
                {e.device_type === 'Mobile' && <i className="far fa-mobile fa-fw text-xs"></i>}
                {e.device_type !== 'Mobile' && e.device_type !== 'Desktop' && (
                  <Typography>{`-`}</Typography>
                )}
              </Typography>
            </DeviceCol>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default StatsModal
