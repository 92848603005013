import * as React from 'react'

import classNames from 'classnames'
import styled from 'styled-components'

import CabalButton, { CabalButtonProps } from 'global/CabalButton'
import ProgressBar from 'global/ProgressBar'
import SignatureModal from 'global/SignatureModal'
import Typography from 'global/Typography'

import useUploadFile from 'utils/hooks/useUploadFile'

const UploadLabel = styled.label`
  cursor: pointer;
  input {
    opacity: 0;
  }
`

function fileCreationFromURL(dataURI: string): File {
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  const blob = new Blob([ab], { type: mimeString })
  const tempBlob: any = blob
  tempBlob.lastModifiedDate = new Date()
  tempBlob.name = 'signature-image'

  return tempBlob as File
}

export type FileExtension = 'pdf' | 'jpg' | 'png' | 'docx' | 'jpeg' | 'doc' | 'xls' | 'csv' | 'zip'

export type OnUpload = (uuid: string, url: string, file: File) => void

interface Props {
  onUpload: OnUpload
  allowedExtensions?: FileExtension[]
  attachTo?: string
  params?: any
  text?: string
  triggerProps?: CabalButtonProps
  canDrawSignature?: boolean
  canUpload?: boolean
  showPencil?: boolean
  drawSignatureButtonVariant?: CabalButtonProps['variant']
  /**
   * max file size in MB
   */
  maxFileSize?: number
}

export const UploadButton: React.FC<Props> = ({
  params,
  attachTo,
  onUpload,
  text = 'Upload New',
  triggerProps,
  allowedExtensions,
  canDrawSignature = false,
  canUpload = true,
  showPencil = false,
  drawSignatureButtonVariant = 'secondary',
  maxFileSize,
}) => {
  const [percent, setPercent] = React.useState<number>(0)
  const [showSignatureModal, toggleSignatureModal] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const { uploadFile, uploading } = useUploadFile()

  triggerProps = {
    variant: 'tertiary',
    leftIcon: <i className="far fa-upload" />,
    component: 'span',
    ...triggerProps,
  } as CabalButtonProps

  const setSignature = (d: string) => {
    handleFileUpload(fileCreationFromURL(d))
  }

  const handleFileUpload = async (file: File) => {
    const { api_url, uuid } = await uploadFile({
      file,
      attachTo,
      params,
      onUploadProgress: (p) => {
        setPercent(p)
      },
    })

    setPercent(0)
    onUpload(uuid, api_url, file)
  }

  const onFileSelect = () => {
    const selectedFile = inputRef.current?.files?.[0]
    if (!selectedFile) return

    if (maxFileSize && selectedFile.size / (1024 * 1024) > 5) {
      alert('Email attachments have a limit of 5MB, selected file exceeds 5MB')
      return
    }

    if (selectedFile) {
      const extension = selectedFile.name.split('.').reverse()[0]
      if (allowedExtensions && !allowedExtensions.includes(extension as FileExtension)) {
        alert('This file type is not supported')
        return
      }
      handleFileUpload(selectedFile)
    }

    inputRef.current.value = ''
  }

  return (
    <>
      <UploadLabel>
        {uploading ? (
          <div className="relative pt-1 mt-1" style={{ maxWidth: 300 }}>
            <ProgressBar percentage={percent} label="Uploading..." />
          </div>
        ) : (
          canUpload && <CabalButton {...triggerProps}>{text}</CabalButton>
        )}

        <input
          ref={inputRef}
          type="file"
          accept={allowedExtensions?.map((e) => `.${e}`).join(',')}
          onChange={onFileSelect}
          className={classNames({ hidden: !window.QA })}
          data-testid="upload-button"
        />
      </UploadLabel>

      {!uploading && canDrawSignature && !showPencil && (
        <CabalButton
          variant={drawSignatureButtonVariant}
          onClick={() => toggleSignatureModal(true)}
        >
          Draw Signature
        </CabalButton>
      )}

      {!uploading && canDrawSignature && showPencil && (
        <Typography
          onClick={() => toggleSignatureModal(true)}
          className={'cursor-pointer'}
          color={'gray'}
        >
          <i className="far fa-pen"></i>
        </Typography>
      )}

      {canDrawSignature && (
        <SignatureModal
          show={showSignatureModal}
          onHide={() => toggleSignatureModal(false)}
          onSubmit={setSignature.bind(this)}
        />
      )}
    </>
  )
}
