import { CompanyListBlueprint } from 'utils/types'

export const listTypeNames: Record<CompanyListBlueprint['list_type'], string> = {
  '': 'default',
  companies: 'companies',
  fundraise: 'fundraise',
  jobs: 'jobs',
  personas: 'personas',
  people: 'people',
  talent: 'talent',
  other: 'other',
  mail: 'mail',
  discounts: 'discounts',
}

export const listItemNames: Record<CompanyListBlueprint['list_type'], string> = {
  '': 'default',
  companies: 'company',
  fundraise: 'investor',
  jobs: 'job',
  personas: 'persona',
  people: 'person',
  talent: 'talent',
  other: 'other',
  mail: 'mail',
}
