export function compactMap<T extends Record<string, any>>(obj: T, valuesToRemove = [undefined]): T {
  Object.keys(obj).forEach((key) => {
    if (valuesToRemove.includes(obj[key])) {
      delete obj[key]
    }
  })

  return obj
}

export const pickFromObject = <T extends object, K extends keyof T>(
  obj: T,
  keys: K[],
): Pick<T, K> => {
  const newObj = {} as Pick<T, K>

  keys.forEach((key) => {
    newObj[key] = obj[key]
  })

  return newObj
}

export const omitFromObject = <T extends object, K extends keyof T>(
  obj: T,
  keys: K[],
): Omit<T, K> => {
  const newObj = { ...obj }

  keys.forEach((key) => {
    delete newObj[key]
  })

  return newObj
}
