import React from 'react'

interface Props {
  waitBeforeShow: number
}

class Delayed extends React.Component<Props, { hidden: boolean }> {
  constructor(props: Props) {
    super(props)
    this.state = { hidden: true }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ hidden: false })
    }, this.props.waitBeforeShow)
  }

  render() {
    return this.state.hidden ? '' : this.props.children
  }
}

export default Delayed
