import axios, { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import {
  IUpdateIRHook,
  IUpdateIRParams,
  IUpdateIRResponse,
} from 'containers/RequestCenter/types/ITypes'
import { cabalToast } from 'ui-components/Toast'

import { REQUEST_CENTER_ENDPOINTS } from '../apiEndpoints'

const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')

const updateIRDetails = async ({
  introRequestUuid,
  teamSlug,
  amount,
  status,
}: IUpdateIRParams): Promise<AxiosResponse<IUpdateIRResponse>> => {
  return await axios.put<IUpdateIRResponse>(
    REQUEST_CENTER_ENDPOINTS.updateIntroRequestDetails(introRequestUuid),
    {
      intro_request: {
        team_slug: teamSlug,
        ...(amount !== undefined && { amount }),
        ...(status !== undefined && { status }),
      },
    },
    {
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      },
    },
  )
}

const contentType = (attribute: string): string => {
  const attrType = attribute == 'amount' ? 'Amount' : attribute == 'status' ? 'Status' : ''
  return attrType
}
export const useUpdateIRDetails = ({
  handleAmountChange,
  cellKey,
  attribute,
  handleStatusChange,
  handleTdDisplay,
}: IUpdateIRHook) =>
  useMutation({
    mutationFn: ({ introRequestUuid, teamSlug, amount, status }: IUpdateIRParams) =>
      updateIRDetails({ introRequestUuid, teamSlug, amount, status }),
    retry: 0,
    onSuccess: (response) => {
      const attrType = contentType(attribute)
      if (handleAmountChange) {
        const amount =
          response.data?.data?.amount === null || response.data?.data?.amount === undefined
            ? ''
            : response.data?.data?.amount.toString()
        handleAmountChange(amount, cellKey)
      }
      if (handleStatusChange && response.data?.data?.status) {
        handleStatusChange(response.data?.data?.status, cellKey)
      }
      cabalToast({
        content: `${attrType} updated successfully`,
        style: 'success',
      })
    },
    onError: (error) => {
      const attrType = contentType(attribute)
      console.log('Error occurred in updating intro request', error)
      cabalToast({
        content: `${attrType} failed to update`,
        style: 'error',
      })
    },
    onSettled: () => {
      if (handleTdDisplay) {
        handleTdDisplay()
      }
    },
  })
