import * as React from 'react'

import cx from 'classnames'
import Expand from 'react-expand-animated'
import styled from 'styled-components'
import tw from 'twin.macro'

import { CabalButtonProps } from 'global/CabalButton'
import { DropdownMenuItem } from 'global/DropDownMenu'
import HoverIconMenu from 'ui-components/DataCard/HoverIconMenu'
import SecondaryContent from 'ui-components/DataCard/SecondaryContent'

import { StyleProps } from 'utils/types'

import { CheckBox } from '../../global/Input'
import CTA from './CTA'
import ExpandedDescription from './ExpandedDescription'
import Owner from './Owner'
import PrimaryInfo from './PrimaryInfo'
import Status from './Status'
import Tags from './Tags'

import './index.css'

export interface IconCtaProps {
  icon: string
  tooltipLabel: string
  onClick?: () => void
  link?: string
  dataTestId?: string
  showDefault?: boolean
}

export interface secondaryCTAProps {
  text: React.ReactNode
  onClick?: () => void
  dataTestId?: string
  link?: string
  variant?: CabalButtonProps['variant']
}

export interface StatusProps {
  text: string
  color: string
  dataTestId?: string
}

export interface HoverIconMenuProps {
  menu: {
    label: React.ReactNode
    handler?: () => void
  }[]
}

export interface SecondaryContentProps {
  content: any
}
export interface OwnerUserProps {
  name: string
  url: string
}

export interface TitleIconProps {
  icon: string | React.ReactNode
  url?: string
  tooltip?: string
  onClick?: (e?: any) => void
}

export interface Props extends StyleProps {
  title?: React.ReactNode
  titleUrl?: string
  titleIcons?: TitleIconProps[]
  mainIcon?: string
  activityTitle?: React.ReactNode
  onCardClick?: () => void
  checkbox?: {
    onCheck: (e?: any) => void
    checked?: boolean
    dataTestId?: string
  }
  avatarUrl?: string | React.ReactNode
  avatarName?: string
  description?: React.ReactNode
  status?: StatusProps
  tags?: string[]
  crm_tags?: string[]
  menuItems?: DropdownMenuItem[]
  secondaryContent?: React.ReactNode
  hoverIconMenu?: {
    label: React.ReactNode
    handler?: () => void
  }[]
  cta?: React.ReactNode
  secondaryCTA?: secondaryCTAProps
  iconCTA?: IconCtaProps[]
  ownerUser?: OwnerUserProps[]
  ownerCompany?: {
    name: string
    url: string
  }
  secondaryMultiselect?: {
    text: string
    menu: DropdownMenuItem[]
  }
  isExpandable?: boolean
  expandedDescription?: string
  expandedSection?: React.ReactNode
  placeholderIcon?: React.ReactNode
  dataCardTestId?: string
  info?: React.ReactNode
  connectionRow?: React.ReactNode
  titleOnClick?: (e?: any) => void
  avatarAlign?: 'top' | 'center'
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.cardBackground};
  &:hover .hover-icon-menu {
    visibility: visible;
    width: 4rem;
    max-width: 4rem;
  }
  &:hover .secondary-content {
    display: hidden;
    visibility: hidden;
    width: 0px;
    max-width: 0px;
    margin: 0px;
  }
`

const ExpandWrapper = styled.div`
  ${tw`flex flex-col px-6 py-3 pl-16 space-y-3 rounded-lg`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
`

export const DataCard = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      avatarAlign = 'center',
      title,
      titleUrl,
      titleIcons,
      mainIcon,
      activityTitle,
      avatarUrl,
      avatarName,
      description,
      status = undefined,
      tags,
      crm_tags,
      menuItems,
      secondaryContent,
      hoverIconMenu,
      secondaryCTA,
      iconCTA,
      ownerUser,
      ownerCompany,
      onCardClick,
      secondaryMultiselect,
      isExpandable = false,
      expandedDescription,
      expandedSection,
      placeholderIcon,
      checkbox = undefined,
      dataCardTestId,
      cta,
      info,
      className,
      connectionRow,
      titleOnClick,
      ...restProps
    },
    ref,
  ) => {
    const [open, setOpen] = React.useState(false)

    return (
      <>
        <Wrapper
          ref={ref}
          onClick={() => (onCardClick ? onCardClick() : isExpandable ? setOpen(!open) : null)}
          className={cx(
            { 'cursor-pointer': onCardClick || isExpandable },
            { 'h-[50px] min-h-[50px]': !connectionRow },
            { 'h-[82px]': connectionRow },
            ' flex justify-between px-2 rounded-lg py-1 text-left items-center w-full',
            className,
          )}
          data-testid={dataCardTestId || 'data-card-wrapper'}
          as={onCardClick ? 'button' : 'div'}
          {...restProps}
        >
          <div className="flex items-center space-x-3 truncate pr-2 pl-0.5">
            {checkbox && (
              <div
                className="flex cursor-pointer"
                onClick={(e) => onCardClick && e.stopPropagation()}
              >
                <CheckBox
                  onChange={checkbox.onCheck}
                  checked={checkbox.checked}
                  data-testid={checkbox.dataTestId}
                />
              </div>
            )}
            <PrimaryInfo
              title={title}
              titleIcons={titleIcons}
              mainIcon={mainIcon}
              activityTitle={activityTitle}
              avatarUrl={avatarUrl}
              avatarName={avatarName}
              description={description}
              placeholderIcon={placeholderIcon}
              titleUrl={titleUrl}
              connectionRow={connectionRow}
              titleOnClick={titleOnClick}
              avatarAlign={avatarAlign}
            />
          </div>
          <div className="flex space-x-3 flex-shrink-0">
            {tags && <Tags tags={tags} />}
            {crm_tags && <Tags tags={crm_tags} color={'green'} />}
            {status && <Status status={status} />}
            {(ownerUser || ownerCompany) && (
              <Owner ownerCompany={ownerCompany} ownerUser={ownerUser} />
            )}
            {info}
            {hoverIconMenu && <HoverIconMenu menu={hoverIconMenu} />}
            {secondaryContent && <SecondaryContent content={secondaryContent} />}
            <CTA
              cta={cta}
              secondaryMultiselect={secondaryMultiselect}
              menuItems={menuItems}
              secondaryCTA={secondaryCTA}
              iconCTA={iconCTA}
            />
          </div>
        </Wrapper>
        {isExpandable && (
          <Expand open={open}>
            {!expandedSection ? (
              <ExpandWrapper>
                {tags && <Tags tags={tags} />}
                {expandedDescription && <ExpandedDescription description={expandedDescription} />}
              </ExpandWrapper>
            ) : (
              expandedSection
            )}
          </Expand>
        )}
      </>
    )
  },
)
