import React from 'react'

import pluralize from 'pluralize'
import styled from 'styled-components'
import tw from 'twin.macro'

import AdvisorInviteModal from 'components/AdvisorInvite'
import { EditListModal } from 'components/EditList'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'

import ShareModal from '../AdminHomeSetup/ShareModal'
import WelcomeModal from '../WelcomeMsgSection/WelcomeModal'

const SectionWrapper = styled.div`
  ${tw`sticky top-0 flex justify-between py-4 px-2 z-20`}
  border-bottom: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.layout.main_bg_color};
`

const TextLink = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.purple};
  cursor: pointer;
`
interface Props {
  listCount: number
  editMode: boolean
  toggleEditMode: () => void
}

const HomeEditSection: React.FC<Props> = ({ listCount, editMode, toggleEditMode }) => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)

  const { showModal } = useModal()
  const renderWelcomeModal = (resolve) => (
    <WelcomeModal onHide={() => resolve()} teamSlug={teamSlug} />
  )

  const renderShareModal: RenderModal = (resolve) => (
    <ShareModal onHide={() => resolve()} teamSlug={teamSlug} />
  )

  const renderNewListModal: RenderModal = (resolve) => (
    <EditListModal onHide={() => resolve(false)} teamSlug={teamSlug} />
  )

  const renderInviteModal: RenderModal = (resolve) => (
    <AdvisorInviteModal onHide={resolve} teamSlug={teamSlug} />
  )

  return (
    <SectionWrapper>
      <div className="flex items-center space-x-4">
        <Typography component="p" fontSize="12">
          {`${listCount} shared ${pluralize('list', listCount)}`}
        </Typography>
        {/* <TextLink component="p">
          <i className="far fa-eye mx-1"></i>
          View as member
        </TextLink> */}
      </div>
      <div className="flex items-center space-x-4">
        <TextLink onClick={() => showModal(renderNewListModal, 'render_new_list_modal')}>
          <i className="far fa-plus mr-1"></i>
          Add List
        </TextLink>
        <TextLink onClick={() => toggleEditMode()}>
          <i className="far fa-reorder mr-1"></i>
          {editMode ? 'Stop Rearranging' : 'Rearrange Lists'}
        </TextLink>
        <TextLink
          component="button"
          onClick={() => showModal(renderWelcomeModal, 'render_welcome_modal')}
        >
          <i className="far fa-message-lines mr-1"></i>
          Edit Welcome Message
        </TextLink>
        <TextLink
          component="button"
          onClick={() =>
            team?.enable_asks
              ? showModal(renderInviteModal, 'render_invite_modal')
              : showModal(renderShareModal, 'render_share_modal')
          }
        >
          <i className="far fa-arrow-up-from-bracket mr-1"></i>
          Share
        </TextLink>
      </div>
    </SectionWrapper>
  )
}

export default HomeEditSection
