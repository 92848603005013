import React from 'react'

import { Footer, useCandidateProfile } from 'containers/CandidateProfileModal'
import { SelectWrapper } from 'containers/CandidateProfileModal/PersonalInfo'
import {
  COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS,
  LEVEL_OPTIONS,
  OFFICE_WITH_OPEN_TO_ALL_OPTIONS,
  OPPORTUNITY_TYPE_OPTIONS,
  ROLE_FUNCTION_OPTIONS,
  STAGE_WITH_OPEN_TO_ALL_OPTIONS,
} from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import { MultiSelect, Select, TextArea, TextInput } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'

export default function CareerInfo(props: {
  onBack: () => unknown
  working: boolean
  onSubmit: () => unknown
}) {
  const { candidateProfile, updateCandidateProfile } = useCandidateProfile()

  return (
    <div className="px-4 text-left">
      <div className="pt-2 flex justify-between items-center">
        <Typography fontSize="12" fontWeight={600}>
          Role Preferences
        </Typography>
      </div>
      <ModalInputWrapper className="py-2">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Type</div>
        </ModalInputLabel>
        <MultiSelect
          placeholder="Preferred role type(s)"
          options={OPPORTUNITY_TYPE_OPTIONS}
          value={candidateProfile.opportunityType}
          onChange={(opportunityType) =>
            updateCandidateProfile({ opportunityType: opportunityType })
          }
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Function</div>
        </ModalInputLabel>

        <MultiSelect
          placeholder="Preferred job function"
          options={ROLE_FUNCTION_OPTIONS}
          value={candidateProfile.functionPreference}
          onChange={(functionPreference) =>
            updateCandidateProfile({ functionPreference: functionPreference })
          }
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Level</div>
        </ModalInputLabel>

        <MultiSelect
          placeholder="Preferred job level"
          options={LEVEL_OPTIONS}
          value={candidateProfile.levelPreference}
          onChange={(levelPreference) =>
            updateCandidateProfile({ levelPreference: levelPreference })
          }
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Compensation</div>
        </ModalInputLabel>
        <TextInput
          value={candidateProfile.minimumSalary}
          placeholder="Compensation expectation"
          onChange={(e) => updateCandidateProfile({ minimumSalary: e.target.value })}
        />
      </ModalInputWrapper>

      <div className="pt-4 flex justify-between items-center">
        <Typography fontSize="12" fontWeight={600}>
          Company Preferences
        </Typography>
      </div>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Size</div>
        </ModalInputLabel>

        <MultiSelect
          placeholder="Preferred company size"
          options={COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS}
          value={candidateProfile.idealCompanySize}
          onChange={(idealCompanySize) =>
            updateCandidateProfile({ idealCompanySize: idealCompanySize })
          }
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Stage</div>
        </ModalInputLabel>

        <MultiSelect
          placeholder="Preferred company stage(s)"
          options={STAGE_WITH_OPEN_TO_ALL_OPTIONS}
          value={candidateProfile.stagePreference}
          onChange={(stagePreference) =>
            updateCandidateProfile({ stagePreference: stagePreference })
          }
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Location</div>
        </ModalInputLabel>

        <MultiSelect
          placeholder="Preferred location(s)"
          options={OFFICE_WITH_OPEN_TO_ALL_OPTIONS}
          value={candidateProfile.officePreference}
          onChange={(officePreference) =>
            updateCandidateProfile({ officePreference: officePreference })
          }
        />
      </ModalInputWrapper>

      <div className="pt-4 flex justify-between items-center">
        <Typography fontSize="12" fontWeight={600}>
          General
        </Typography>
      </div>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Work Status</div>
        </ModalInputLabel>
        <SelectWrapper>
          <Select
            placeholder="Work Status"
            options={[
              { value: 'legal_to_work_in_us', label: 'Legal to Work in US' },
              { value: 'visa_requirement', label: 'Visa Requirement' },
            ]}
            value={candidateProfile.workStatus}
            onChange={(workStatus) =>
              updateCandidateProfile({ workStatus: workStatus || undefined })
            }
          />
        </SelectWrapper>
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Looking for</div>
        </ModalInputLabel>
        <TextArea
          placeholder="Add detail about ideal role"
          value={candidateProfile.whatYoureLookingFor}
          onChange={(e) => updateCandidateProfile({ whatYoureLookingFor: e.target.value })}
        />
      </ModalInputWrapper>

      <Footer className="-mx-6 flex justify-between mt-4 pt-6 pb-5 px-6">
        <CabalButton
          type="button"
          variant="tertiary"
          leftIcon={<i className="far fa-chevron-left" />}
          onClick={props.onBack}
          padding="0px"
        >
          Back
        </CabalButton>

        <CabalButton
          type="button"
          variant="primary"
          disabled={props.working}
          working={props.working}
          onClick={props.onSubmit}
          className="w-1/4"
        >
          {props.working ? 'Submitting...' : 'Save'}
        </CabalButton>
      </Footer>
    </div>
  )
}
