import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

const ActivityWrapper = styled.div`
  ${tw`p-2 rounded-lg`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
`

const SectionWrapper = styled.div`
  padding-top: 4px;
  padding-bottom: 40px;
`

const CardWrapper = styled.div`
  ${tw`my-2 p-2 rounded-lg flex gap-2`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: ${({ theme }) => theme.border};
`

const GridCard = styled.div`
  ${tw`my-2 p-2 rounded-lg gap-2`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
`

const ContributionCounterIcon = styled.i`
  color: #fbba03;
  font-size: 12px;
`

const EmptyCardWrapper = styled.div`
  ${tw`my-2 p-2 rounded-lg`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: 1px dashed ${({ theme }) => theme.colors.border};
  border-width: 2px;
`

const HomeSectionWrapper = styled.div`
  ${tw`max-w-3xl mx-auto py-5 sm:px-0`}
`

const ViewMore = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.fog};
  cursor: pointer;
`

export {
  SectionWrapper,
  CardWrapper,
  ContributionCounterIcon,
  ActivityWrapper,
  EmptyCardWrapper,
  HomeSectionWrapper,
  ViewMore,
  GridCard,
}
