import React, { useState } from 'react'

import { useMutation } from 'react-query'

import CabalButton from 'global/CabalButton'
import { MultiSelect } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { GetInvestorTagsResponse } from 'utils/types/investor'

interface Props {
  onHide: () => void
  selectedJobsUuids: string[]
  jobTags?: GetInvestorTagsResponse
  refetch: () => void
  header: string
  companyListUuid: string
  kind: string
}

const JobsListBulkActionModal: React.FC<Props> = ({
  onHide,
  selectedJobsUuids,
  jobTags,
  header,
  companyListUuid,
  kind,
  refetch,
}) => {
  const [values, setValues] = useState<any>()
  const allTags = jobTags?.tags

  const { mutate: bulkActions } = useMutation(
    () =>
      callApi(api.jobsBulkActions, companyListUuid, {
        selected_jobs_uuids: selectedJobsUuids,
        bulk_action: 'add',
        kind: kind,
        values: typeof values === 'string' ? [values] : values,
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Successfully updated!' })
        refetch()
        onHide()
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content: 'Something went wrong, please try again or contact support',
        })
      },
    },
  )

  return (
    <Modal
      show
      onHide={onHide}
      header={`Bulk Actions - ${header}`}
      rightActions={
        <CabalButton onClick={() => bulkActions()} disabled={!values}>
          Save
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        {kind === 'tags' && (
          <ModalInputWrapper>
            <ModalInputLabel>Select Tags</ModalInputLabel>

            <MultiSelect
              value={values}
              options={allTags?.map((tag) => ({ label: tag, value: tag })) ?? []}
              creatable
              onChange={setValues}
            />
          </ModalInputWrapper>
        )}
      </ModalSectionWrapper>
    </Modal>
  )
}

export default JobsListBulkActionModal
