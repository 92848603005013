import React, { useState } from 'react'
import { useMutation } from 'react-query'

import { Label, MultiSelect, Select, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import CabalButton from 'global/CabalButton'
import api, { callApi } from 'utils/api'
import { cabalToast } from 'ui-components/Toast'
import { InvestorCompany, TalentPOC } from 'utils/types/investor'
import { useTeam } from 'store/hooks'
import { startCase, uniqBy } from 'lodash'
import { LimitedUser } from 'utils/types'
import { useSetState } from 'react-use'

interface Props {
  selectedCompanyUuids: Set<string>
  companies: InvestorCompany[]
  allTags: string[]
  onHide: () => void
  teamSlug: string
  setCompanies: (companies: InvestorCompany[]) => void
  setAllTags: (tags: string[]) => void
  field: string
  allStages: string[]
  setAllStages: (stages: string[]) => void
}

const BulkEditCompaniesModal: React.VFC<Props> = ({
  selectedCompanyUuids,
  onHide,
  teamSlug,
  allTags,
  companies,
  setCompanies,
  setAllTags,
  allStages,
  setAllStages,
  field,
}) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [selectedOwners, setSelectedOwners] = useState<string[]>([])
  const [selectedStage, setSelectedStage] = useState<string>()
  const [talentPOC, setTalentPOC] = useSetState<TalentPOC>()
  const { team } = useTeam(teamSlug)

  const { mutate: handleUpdate, isLoading } = useMutation(
    () => {
      const updatedCompanies = companies.reduce((updated, c: InvestorCompany) => {
        if (selectedCompanyUuids.has(c.uuid)) {
          const updatedCompany = { ...c }
          if (field === 'tags') {
            updatedCompany.tags = Array.from(new Set([...selectedTags, ...c.tags]))
          }
          if (field === 'owners') {
            updatedCompany.owner_uuids = [...selectedOwners]
            updatedCompany.owners?.forEach(({ uuid }) =>
              (updatedCompany.owner_uuids as string[]).push(uuid),
            )
          }
          if (field === 'stage') {
            updatedCompany.stage = selectedStage as string
          }
          if (field === 'talent_poc') {
            updatedCompany.talent_poc = talentPOC
          }
          updated.push(updatedCompany)
        }
        return updated
      }, [] as InvestorCompany[])
      return callApi(
        api.bulkUpdateFieldInvestorCompany,
        updatedCompanies,
        Array.from(selectedCompanyUuids),
        teamSlug,
        field,
      )
    },
    {
      onSuccess: () => {
        const updatedAllTags = Array.from(new Set([...selectedTags, ...allTags]))
        const updatedAllStages = Array.from(new Set([selectedStage?.toLowerCase(), ...allStages]))
        const updatedCompanies = companies.map((c: InvestorCompany) => {
          const updatedCompany = { ...c }
          if (selectedCompanyUuids.has(c.uuid)) {
            if (field === 'tags') {
              updatedCompany.tags = Array.from(new Set([...selectedTags, ...c.tags]))
            }
            if (field === 'owners') {
              const owners = selectedOwners.map(
                (uuid) => team?.admins_and_members.find((u) => u.uuid === uuid) as LimitedUser,
              )
              updatedCompany.owners = uniqBy([...(updatedCompany.owners || []), ...owners], 'uuid')
            }
            if (field === 'stage') {
              updatedCompany.stage = selectedStage as string
            }

            if (field === 'talent_poc') {
              updatedCompany.talent_poc = talentPOC
            }
          }
          return updatedCompany
        })
        setCompanies(updatedCompanies)
        setAllTags(updatedAllTags)
        setAllStages(updatedAllStages)
        cabalToast({ style: 'success', content: 'Updated successfully!' })
        onHide()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const getModalContent = (field: string) => {
    switch (field) {
      case 'tags':
        return (
          <Label label="Select tags">
            <MultiSelect<string>
              className="mb-3"
              options={allTags.map((t: string) => ({
                label: t,
                value: t,
              }))}
              value={selectedTags}
              onChange={(v) => setSelectedTags(v)}
              isLoading={!allTags}
              creatable
              data-testid="bulk-edit-company-modal-tags"
            />
          </Label>
        )
      case 'owners':
        return (
          <Label label="Add Owners">
            <MultiSelect<string>
              className="mb-3"
              options={
                team?.admins_and_members.map((u) => ({
                  label: u.name,
                  value: u.uuid,
                })) || []
              }
              value={selectedOwners}
              onChange={(v) => setSelectedOwners(v)}
              data-testid="bulk-edit-company-modal-owners"
            />
          </Label>
        )
      case 'stage':
        return (
          <Select
            className="w-full my-3"
            placeholder="Stage"
            options={allStages.map((s: string) => ({
              label: startCase(s),
              value: s,
            }))}
            creatable
            formatCreateLabel={() => (
              <>
                <i className="far fa-plus mr-1"></i>Create new stage
              </>
            )}
            onChange={(v) => v && setSelectedStage(v)}
            data-testid="bulk-edit-company-modal-stage"
          />
        )
      case 'talent_poc':
        return (
          <>
            <TextInput
              value={talentPOC.email}
              className="w-full mt-3"
              valueType="non-empty"
              onChange={(e) =>
                setTalentPOC({
                  email: e.target.value || undefined,
                })
              }
              placeholder="Email"
              data-testid="bulk-edit-company-modal-talent-poc-email"
            />
            <TextInput
              value={talentPOC.first_name}
              className="w-full mt-3"
              valueType="non-empty"
              onChange={(e) =>
                setTalentPOC({
                  first_name: e.target.value || undefined,
                })
              }
              placeholder="First Name"
              data-testid="bulk-edit-company-modal-talent-poc-first-name"
            />
            <TextInput
              value={talentPOC.last_name}
              className="w-full mt-3"
              valueType="non-empty"
              placeholder="Last Name"
              onChange={(e) =>
                setTalentPOC({
                  last_name: e.target.value || undefined,
                })
              }
              data-testid="bulk-edit-company-modal-talent-poc-last-name"
            />
            <TextInput
              value={talentPOC.linkedin_url}
              className="w-full mt-3"
              valueType="website"
              placeholder="LinkedIn Profile URL (Optional)"
              onChange={(e) =>
                setTalentPOC({
                  linkedin_url: e.target.value || undefined,
                })
              }
              data-testid="bulk-edit-company-modal-talent-poc-linkedin-url"
            />
          </>
        )
    }
  }

  const disabled =
    field === 'talent_poc' && (!talentPOC.first_name || !talentPOC.last_name || !talentPOC.email)

  return (
    <Modal
      show
      onHide={onHide}
      header="Bulk Update"
      rightActions={
        <CabalButton
          onClick={() => handleUpdate()}
          working={isLoading}
          disabled={isLoading || disabled}
          data-testid="bulk-edit-company-modal-save-btn"
        >
          Save
        </CabalButton>
      }
    >
      {getModalContent(field)}
    </Modal>
  )
}

export default BulkEditCompaniesModal
