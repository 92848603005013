import React from 'react'
import cx from 'classnames'

import Avatar, { AvatarProps } from 'global/Avatar'
import styled from 'styled-components'

interface FacesProps {
  className?: string
}

export const Faces: React.FC<FacesProps> = ({ children, className }) => {
  return <div className={cx('relative', 'flex flex-wrap', className)}>{children}</div>
}

export const Face: React.FC<AvatarProps> = ({ name, src, size = '24', className }) => {
  return <StyledAvatar className={className} name={name} src={src} size={size} />
}

const StyledAvatar = styled(Avatar)`
  position: relative;
  z-index: 0;
  &:hover {
    z-index: 1;
  }

  img {
    border: 2px solid ${({ theme }) => theme.colors.border};
  }
`
