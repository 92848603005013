import React from 'react'

import { el } from '@faker-js/faker'

import { list } from 'postcss'
import { Link, useHistory } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import { useAccessControl } from 'global/AccessControl'
import Typography from 'global/Typography'

import { formatCompact } from 'utils/ui'

import OptionPillBlock from './OptionPillBlock'
import Title from './Title'
import { HomeSectionWrapper } from './styles'

interface Props {
  teamSlug: string
  showPortfolioCard?: boolean
  showResourcesCard?: boolean
  portfolioCompanyCount?: number
  resourcesCount?: number
  team: any
}

const ListNavigation: React.FC<Props> = ({
  teamSlug,
  lists = [],
  showPortfolioCard,
  showResourcesCard,
  portfolioCompanyCount,
  resourcesCount,
  team,
}) => {
  const { isAdminOrEmployee, isAdvisor } = useAccessControl(teamSlug)
  const history = useHistory()

  if (lists.length == 0) return <></>

  let title = ''

  if (showPortfolioCard) {
    title = 'How can we help?'
  } else if (!isAdminOrEmployee && isAdvisor) {
    title = `${team.name} asks, offers, and updates`
  } else if (isAdminOrEmployee) {
    title = `${team.name} asks, offers, and updates`
  }

  const scrollWithOffset = (id: string) => {
    const element = document.getElementById(id)
    const overflowWrapper = document.getElementById('main-overflow-wrapper')

    overflowWrapper?.scrollBy({
      top: (element?.getBoundingClientRect().top || 0) - 160,
      behavior: 'smooth',
    })
  }

  return (
    <HomeSectionWrapper>
      <Title label={title} />
      <div className="flex flex-wrap gap-1.5">
        {lists.map((list) => {
          return (
            <HashLink
              smooth
              to={`#${list.slug}`}
              scroll={() => scrollWithOffset(list.slug)}
              key={list.id}
            >
              <OptionPillBlock
                key={list.id}
                logo={!list.owned && list.owning_team?.logo ? list.owning_team?.logo : undefined}
                title={
                  <div>{list.owned ? list.name : `${list.owning_team?.name} - ${list.name}`}</div>
                }
                count={list.companies_count ? formatCompact(list.companies_count) : null}
                // onClick={() => history.push(`/${teamSlug}/lists/${list.slug}`)}
                name={list.owning_team?.name}
              />
            </HashLink>
          )
        })}

        {showPortfolioCard && (
          <HashLink smooth to={`#${teamSlug}-portfolio`}>
            <OptionPillBlock
              key={'portfolio'}
              title={<div>{team?.name} Portfolio</div>}
              // onClick={() => history.push(`/${teamSlug}/portfolio`)}
              count={portfolioCompanyCount ? `${formatCompact(portfolioCompanyCount)}` : null}
            />
          </HashLink>
        )}
        {showResourcesCard && (
          <HashLink smooth to={`#${teamSlug}-resources`}>
            <OptionPillBlock
              key={'resources'}
              title={<div>{team?.name} Resources</div>}
              // onClick={() => history.push(`/${teamSlug}/resources`)}
              count={resourcesCount ? `${formatCompact(resourcesCount)}` : null}
            />
          </HashLink>
        )}
      </div>
      {/* <CardWrapper className="w-full">
        <TextInput
          value={link}
          onChange={(e) => setLink(e.currentTarget.value)}
          className="w-full"
          placeholder="Paste a link or type something"
        />
        <CabalButton
          className="mt-2"
          variant="secondary"
          onClick={() =>
            showModal((resolve) => renderNewListModal(resolve, 'feedback'), 'render_new_list_modal')
          }
        >
          Save
        </CabalButton>
      </CardWrapper> */}
    </HomeSectionWrapper>
  )
}

export default ListNavigation
