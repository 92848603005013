import React, { useEffect, useState } from 'react'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useMutation, useQuery } from 'react-query'
import UploadZone from 'global/Input/UploadZone'
import Pill from 'global/Pill'
import ProgressBar from 'global/ProgressBar'
import { cabalToast } from 'ui-components/Toast'
import { UploadModel } from 'utils/types'
import api, { callApi } from 'utils/api'

interface Props {
  setOnClose: () => void
  listUuid: string
  fetchListData: () => void
}
const ImportSection: React.FC<Props> = ({ setOnClose, listUuid, fetchListData }) => {
  const [syncStatus, setSyncStatus] = useState<
    'idle' | 'running' | 'completed' | 'error' | 'failed'
  >('idle')
  const [importJobId, setImportJobId] = useState<number | null>(null)
  const [progressPercent, setProgressPercent] = useState(10)

  const { mutate: importFile } = useMutation(
    (upload: UploadModel) => callApi(api.import, upload.url, upload.uuid, listUuid),
    {
      onSuccess: (data) => {
        setImportJobId(data.import_job_id)
      },
    },
  )

  const { data: importStatus } = useQuery(
    ['connectionStatus', importJobId],
    () => callApi(api.importStatus, importJobId!, listUuid),
    {
      refetchInterval: 1000 * 3,
      refetchIntervalInBackground: !!importJobId,
      enabled: !!importJobId,
    },
  )

  useEffect(() => {
    if (!importStatus) return
    const data = importStatus
    const { completed_steps, total_steps, status, unexpected_errors } = data

    const updateProgress = () => {
      let calc = 0
      if (completed_steps && total_steps) {
        calc = (completed_steps / total_steps) * 100
        setProgressPercent(`${Math.trunc(calc)}`)
      }
    }

    const handleCompletion = () => {
      setProgressPercent(100)
      setTimeout(() => {
        cabalToast({
          content: 'Import completed',
          style: 'success',
        })
        fetchListData()
        setSyncStatus('completed')
        setOnClose()
      }, 1500)
    }

    const handleError = () => {
      if (unexpected_errors) {
        cabalToast({
          style: 'error',
          content: 'An unexpected error occurred. Please contact support.',
        })
      }
      setSyncStatus('error')
    }

    switch (status) {
      case 'running':
        setSyncStatus('running')
        updateProgress()
        break
      case 'completed':
        handleCompletion()
        break
      case 'failed':
        handleError()
        break
      default:
        break
    }
  }, [importStatus])

  return (
    <div className="mt-4">
      <div className="flex px-2 gap-2">
        <CabalButton
          padding="0"
          variant="tertiary"
          onClick={() => {
            window.open('/Cabal Discount Import Format.csv')
          }}
        >
          Download CSV Template
        </CabalButton>

        <Typography color="fog">{' • '}</Typography>

        <CabalButton
          variant="tertiary"
          onClick={() => {
            window.open(
              'https://docs.google.com/spreadsheets/d/1S2NArNHf_WVHBBnEGPnwOlwbprv8IPTQ0P4NL9qoIkM/edit?usp=sharing',
              '_blank',
              'noreferrer',
            )
          }}
          padding="0"
        >
          Open in Google Sheets
        </CabalButton>
      </div>

      {(syncStatus === 'idle' || syncStatus === 'error') && (
        <UploadZone
          uploadOnSelect
          hideFiles={true}
          hideProgress={true}
          accept={['csv', 'zip']}
          onUpload={(upload) => {
            if (!upload.length) return
            importFile(upload[0])
          }}
          beforeUpload={() => {
            setImportJobId(null)
            setSyncStatus('running')
          }}
        />
      )}

      {syncStatus === 'running' && (
        <>
          <div className="mt-3">
            <ProgressBar percentage={progressPercent} />
          </div>
          <Typography color="fog" fontSize="12" className="mt-3 flex items-center justify-center">
            {`Adding Discounts to your list (${progressPercent}%)`}
          </Typography>
        </>
      )}

      {(syncStatus === 'failed' || syncStatus === 'error') &&
        importStatus?.import_errors &&
        importStatus.import_errors.length > 0 && (
          <div className="mt-3">
            <Typography color="red" fontSize="12">
              Errors:
            </Typography>
            <div className="flex flex-wrap gap-1">
              {importStatus.import_errors.map((error, index) =>
                error.error_messages.map((message, i) => (
                  <Pill
                    variant="gray"
                    key={`${index}${i}`}
                    title={`Line ${error.csv_line} : ${message}`}
                  >
                    <Typography>{`Line ${error.csv_line} : ${message}`}</Typography>
                  </Pill>
                )),
              )}
            </div>
          </div>
        )}
    </div>
  )
}

export default ImportSection
