import React from 'react'
import { useMutation } from 'react-query'
import ReactStars from 'react-stars'
import { useSetState } from 'react-use'
import { useTheme } from 'styled-components'

import CabalButton from 'global/CabalButton'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import CKEditor from 'global/TextEditor/ckeditor'

import api, { callApi } from 'utils/api'
import { absoluteHtmlContent } from 'utils/html'
import { Offer, ReviewProps } from 'utils/types'

interface Props {
  offer?: Offer
  show: boolean
  onHide: () => void
  reload: () => void
}

const VendorReviewModal: React.VFC<Props> = ({ offer, show, onHide, reload }) => {
  const [review, setReview] = useSetState<ReviewProps>()
  const theme = useTheme()

  const { mutate: createOfferReview } = useMutation(
    ['createOfferReview'],
    (offer: Offer) => callApi(api.createOfferReview, offer.uuid, review),
    {
      onSuccess: () => {
        reload()
        onHide()
      },
    },
  )

  const handleSubmit = () => {
    createOfferReview(offer, review)
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      header={`Add review for ${offer?.title}`}
      rightActions={<CabalButton onClick={handleSubmit}>Save</CabalButton>}
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Rating</ModalInputLabel>
          <ReactStars
            count={5}
            value={review.rating}
            color2={theme.colors.star_select}
            onChange={(r) => setReview({ rating: r })}
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Review</ModalInputLabel>
          <div className="overflow-hidden w-full">
            <CKEditor
              value={review.description}
              onChange={(v) => setReview({ description: absoluteHtmlContent(v) || '' })}
              height={100}
            />
          </div>
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default VendorReviewModal
