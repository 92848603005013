import * as React from 'react'
import { MessageModel, RichInput as RichInputModel } from 'utils/types/models'
import api from 'utils/api'
import { ComposerButtons } from 'global/Button'
import Typography from 'global/Typography'

interface Props {
  message: MessageModel
  markAsRead: any
  showReply?: boolean
}

const MessageButtons: React.FC<Props> = ({ markAsRead, message, showReply }) => {
  const buttons = message.buttons || []

  // function onLikeClick(e) {
  //   e.stopPropagation()

  //   api.addMessageReaction(message.uuid, 'like').then((response) => {
  //     const { current_user_reactions, all_reaction_counts } = response.data
  //     console.log({ current_user_reactions, all_reaction_counts })
  //   })
  // }

  return (
    <div className="flex flex-wrap">
      {buttons.map((input: RichInputModel, index) => (
        <span className="mr-3 mt-3" key={index}>
          <ComposerButtons
            primary={false}
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation()
              if (input.action == 'link') {
                window.open(input.url, '_blank', 'noopener')
                api.sendMessageAction(message.uuid, { clicked: input.text })
                api.markAsRead(message.uuid).then(() => markAsRead())
              }
            }}
          >
            {input.text}
          </ComposerButtons>
        </span>
      ))}
      {/* {showReply && (
        <span className={'flex'}>
          <ComposerButtons className={'mr-3 mt-3'} onClick={() => onOpen()}>
            <i className="fa fa-reply mr-2" />
            Reply
          </ComposerButtons>
          <ComposerButtons primary={true} onClick={onLikeClick}>
            <Typography>
              <i className="far fa-thumbs-up mr-2"></i>
              Like
            </Typography>
        </ComposerButtons>
        </span>
      )} */}
    </div>
  )
}

export default MessageButtons
