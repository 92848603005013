import * as React from 'react'

import { useHistory } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import OptionSelector from 'containers/Asks/ConnectionStatus/OptionSelector'
import UploadZone from 'global/Input/UploadZone'
import Loading from 'global/Loading'
import Typography from 'global/Typography'

import api from 'utils/api'

const ConnectionsEmptyState: React.FC = () => {
  const ready = useSearchParam('ready')
  const [showDropzone, setShowDropzone] = React.useState(ready === 'true')
  const [popup, setPopup] = React.useState<Window | null>(null)
  const [jobStatusUuid, setJobStatusUuid] = React.useState(null)
  const history = useHistory()

  const scheduleReminder = () => {
    api.scheduleUploadReadyReminder(undefined, true)
  }

  const onAddConnections = (first = true) => {
    if (popup && !popup.closed) {
      popup.focus()
    } else {
      const popupWindow = window.open(
        '/add_connections',
        '_blank',
        `height=${window.screen.availHeight || '1000'},width=480`,
      )
      setPopup(popupWindow)
      if (first) {
        scheduleReminder()
        setShowDropzone(true)
      }
    }
  }

  const goToInstallExtension = () => {
    history.push({
      pathname: window.location.pathname,
      search: '',
    })
    window.open(`/chrome`)
  }

  return (
    <>
      {!showDropzone && (
        <OptionSelector
          icon={<i className="fal fa-file-arrow-up"></i>}
          title={'Add Connections'}
          description={'Download a CSV of your LinkedIn connections, and then upload them to Cabal'}
          onClick={onAddConnections}
          buttonText="Add"
        />
      )}

      {showDropzone && !jobStatusUuid && (
        <UploadZone
          uploadOnSelect
          accept={['csv', 'zip']}
          onUpload={(upload) => {
            if (!upload.length) return

            api.importUserConnections(undefined, upload[0].uuid).then((response) => {
              const { job_status_uuid } = response.data
              setJobStatusUuid(job_status_uuid)
            })
          }}
          prompt={
            <>
              <Typography fontWeight={600} color="primary" component="div">
                Drop connections file here
              </Typography>
              <Typography fontSize="12" color="fog">
                Drag and drop to upload or <Typography color="purple">click to browse</Typography>
              </Typography>
            </>
          }
        />
      )}

      {!jobStatusUuid && showDropzone && (
        <div className="flex m-auto">
          <Typography
            fontSize="14"
            color="purple"
            className="cursor-pointer m-4"
            onClick={() => onAddConnections(false)}
          >
            Reopen LinkedIn
          </Typography>
          <Typography
            fontSize="14"
            className="cursor-pointer m-4"
            color="purple"
            onClick={goToInstallExtension}
          >
            Cabal Chrome Extension
          </Typography>
        </div>
      )}

      {jobStatusUuid && (
        <>
          <div className="flex items-center text-center w-full flex-col">
            <Loading />
            <div>{`We're processing your connections. This may take up to a minute`}</div>
          </div>
        </>
      )}
    </>
  )
}

export default ConnectionsEmptyState
