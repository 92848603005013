import axios from 'axios'

export default {
  createRevision: (data: {
    global_person_id: number
    name: string | undefined
    position: string | undefined
    company_name: string | undefined
    other_issue: string | undefined
    company_uuid: string | undefined
  }) => {
    return axios.post(`/api/revisions`, { revision: data })
  },
}
