import axios from 'axios'

export default {
  getForYou: () => {
    return axios.get('/api/for_yous')
  },

  dismissForYou: (item_uuid: string) => {
    return axios.put(`/api/for_yous/${item_uuid}/dismiss`)
  },
}
