import * as React from 'react'

import LogoLT from '../../images/c-light.svg'
import Logo from '../../images/c-dark.svg'

import styled from 'styled-components'

import useColorMode from 'utils/hooks/useColorMode'
import { Login } from 'containers/LoginPage'
import { H1 } from './NewSite/styles'

const CabalLogo = styled.img`
  margin-bottom: 10px;
  width: 13.5px;
  height: 16px;
`

const CabalText = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #4b5563;
  @media (prefers-color-scheme: light) {
    color: #9ca3af;
  }
`

const FooterLink = styled.a`
  font-size: 12px;
  line-height: 16px;
  color: #4b5563 !important;
  @media (prefers-color-scheme: light) {
    color: #9ca3af !important;
  }
`

const Container = styled.div`
  padding-top: 3rem;
  padding-bottom: 4rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;
  @media (min-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 6rem;
  }
`

const FormArea = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 420px;
  @media (min-width: 650px) {
    max-width: 320px;
  }
`

const FooterSubtitle = styled(H1)`
  font-size: 40px;
  padding-bottom: 24px;
`

const LinkWrapper = styled.div<{ active?: boolean }>`
  display: block;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 1.25rem;
  color: ${({ active }) => (active ? '#718096 !important' : '#a8bff5 !important')};
  text-decoration: ${({ active }) => (active ? 'inherit' : 'underline !important')};
  @media (prefers-color-scheme: light) {
    color: ${({ active }) => (active ? '#718096 !important' : '#5c69d1 !important')};
  }
`

interface Theme extends React.HTMLProps<HTMLDivElement> {
  withForm?: boolean
  withBlog?: boolean
  path?: string
}

const Footer = ({ withForm = false, withBlog = false, path, ...props }: Theme) => {
  const { isDarkMode } = useColorMode()

  return (
    <div {...props}>
      {withForm ? (
        <FormArea className="pb-12">
          <Login />
        </FormArea>
      ) : null}

      {withBlog ? (
        <Container>
          <div>
            <FooterSubtitle>Learn more</FooterSubtitle>
            <div>
              {window.posts_list?.map((post) => (
                <a key={post.slug} href={`/blog/${post.slug}`}>
                  <LinkWrapper active={path === `/blog/${post.slug}`}>{post.title}</LinkWrapper>
                </a>
              ))}
            </div>
          </div>
        </Container>
      ) : null}

      <div className="flex flex-col items-center">
        {isDarkMode ? <CabalLogo src={Logo} /> : <CabalLogo src={LogoLT} />}
        <div className="flex ">
          <CabalText className="pr-0.5">{`© Cabal · `}</CabalText>
          <FooterLink href="/privacy" className="pr-0.5">{` Privacy · `}</FooterLink>
          <FooterLink href="/terms" className="pr-0.5">{` Terms`}</FooterLink>
        </div>
      </div>
    </div>
  )
}

export default Footer
