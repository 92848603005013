const defaultLists = {
  lists: {
    potential_investors: {
      name: 'Potential Investors',
      listType: 'companies',
      lists: [
        {
          name: 'Series A US Investors',
          items: [
            {
              name: 'Techstars',
              domain: 'techstars.com',
            },
            {
              name: '500 Global',
              domain: '500.co',
            },
            {
              name: 'SOSV',
              domain: 'sosv.com',
            },
            {
              name: 'New Enterprise Associates',
              domain: 'nea.com',
            },
            {
              name: 'Accel',
              domain: 'accel.com',
            },
            {
              name: 'Sequoia Capital',
              domain: 'sequoiacapital.co.uk',
            },
            {
              name: 'Plug and Play',
              domain: 'plugandplaytechcenter.com',
            },
            {
              name: 'Intel Capital',
              domain: 'intelcapital.com',
            },
            {
              name: 'Andreessen Horowitz',
              domain: 'a16z.com',
            },
            {
              name: 'SVA',
              domain: 'svaltd.com',
            },
            {
              name: 'Kleiner Perkins',
              domain: 'kleinerperkins.com',
            },
            {
              name: 'Bessemer Venture Partners',
              domain: 'bvp.com',
            },
            {
              name: 'FJ Labs',
              domain: 'fjlabs.com',
            },
            {
              name: 'Lightspeed Venture Partners',
              domain: 'lsvp.com',
            },
            {
              name: 'General Catalyst',
              domain: 'generalcatalyst.com',
            },
            {
              name: 'Tiger Global Management',
              domain: 'tigerglobal.com',
            },
            {
              name: 'Advantage Capital',
              domain: 'advantagecap.com',
            },
            {
              name: 'Khosla Ventures',
              domain: 'khoslaventures.com',
            },
            {
              name: 'Index Ventures',
              domain: 'indexventures.com',
            },
            {
              name: 'Google Ventures',
              domain: null,
            },
            {
              name: 'Insight Partners',
              domain: 'insightpartners.com',
            },
            {
              name: 'Goldman Sachs',
              domain: 'goldmansachs.com',
            },
            {
              name: 'GGV Capital',
              domain: 'ggvc.com',
            },
            {
              name: 'Norwest Venture Partners',
              domain: 'nvp.com',
            },
            {
              name: 'First Round Capital',
              domain: 'firstround.com',
            },
            {
              name: 'Founders Fund',
              domain: 'foundersfund.com',
            },
            {
              name: 'Greylock',
              domain: 'greylock.com',
            },
            {
              name: 'Battery Ventures',
              domain: 'battery.com',
            },
            {
              name: 'Venrock',
              domain: 'venrock.com',
            },
            {
              name: 'Greycroft',
              domain: 'greycroft.com',
            },
            {
              name: 'Canaan Partners',
              domain: null,
            },
            {
              name: 'Menlo Ventures',
              domain: 'menlovc.com',
            },
            {
              name: 'Redpoint',
              domain: 'theredpointcenter.com',
            },
            {
              name: 'Goodwater Capital',
              domain: null,
            },
            {
              name: 'Foundation Capital',
              domain: 'foundationcapital.com',
            },
            {
              name: 'Gaingels',
              domain: 'gaingels.com',
            },
            {
              name: 'Benchmark',
              domain: 'benchmark-inc.com',
            },
            {
              name: 'Polaris Partners',
              domain: 'polarispartners.com',
            },
            {
              name: 'True Ventures',
              domain: 'trueventures.com',
            },
            {
              name: 'CRV',
              domain: 'crv.com',
            },
            {
              name: 'Matrix Partners',
              domain: 'matrix1.com',
            },
            {
              name: 'Mayfield Fund',
              domain: null,
            },
            {
              name: 'Lerer Hippeau',
              domain: 'lererhippeau.com',
            },
            {
              name: 'DCM Ventures',
              domain: 'dcm.com',
            },
            {
              name: 'BoxGroup',
              domain: 'boxgroup.com',
            },
            {
              name: 'Bain Capital Ventures',
              domain: 'baincapitalventures.com',
            },
            {
              name: 'Felicis',
              domain: 'felicis.com.ar',
            },
            {
              name: 'RRE Ventures',
              domain: 'rre.com',
            },
            {
              name: 'Atlas Venture',
              domain: 'atlasventure.com',
            },
            {
              name: 'U.S. Venture Partners',
              domain: 'usvp.com',
            },
            {
              name: 'Spark Capital',
              domain: 'sparkcapital.in',
            },
            {
              name: 'Initialized Capital',
              domain: 'initialized.com',
            },
            {
              name: 'Highland Capital Partners',
              domain: 'hcp.com',
            },
            {
              name: 'Madrona',
              domain: 'madrona.com',
            },
            {
              name: 'DCVC',
              domain: 'dcvc.com',
            },
            {
              name: 'Trinity Ventures',
              domain: 'trinityventures.com',
            },
            {
              name: 'Soma Capital',
              domain: 'somacapital.com',
            },
            {
              name: 'Headline',
              domain: 'headline.se',
            },
            {
              name: 'LAUNCH',
              domain: 'launch.co',
            },
            {
              name: 'Crosslink Capital',
              domain: 'crosslinkcapital.com',
            },
            {
              name: 'ARCH Venture Partners',
              domain: 'archventure.com',
            },
            {
              name: 'Lux Capital',
              domain: 'luxcapital.com',
            },
            {
              name: 'International Finance Corporation',
              domain: null,
            },
            {
              name: 'Social Starts',
              domain: 'socialstarts.com',
            },
            {
              name: '8VC',
              domain: '8vc.com',
            },
            {
              name: 'Summit Partners',
              domain: 'gosummitpartners.com',
            },
            {
              name: 'Pareto Holdings',
              domain: 'pareto20.com',
            },
            {
              name: 'Social Capital',
              domain: 'socialcapital.com',
            },
            {
              name: 'InterWest Partners',
              domain: 'interwest.com',
            },
            {
              name: 'F-Prime Capital',
              domain: 'fprimecapital.com',
            },
            {
              name: 'Union Square Ventures',
              domain: 'usv.com',
            },
            {
              name: 'North Bridge Venture Partners & Growth Equity',
              domain: null,
            },
            {
              name: 'Shasta Ventures',
              domain: 'shasta.vc',
            },
            {
              name: 'Flybridge',
              domain: 'flybridge.com',
            },
            {
              name: 'GSR Ventures',
              domain: 'gsrventures.com',
            },
            {
              name: 'Uncork Capital',
              domain: 'uncorkcapital.com',
            },
            {
              name: 'Oak Investment Partners',
              domain: 'oakvc.com',
            },
            {
              name: 'Upfront Ventures',
              domain: 'upfront.com',
            },
            {
              name: 'Sierra Ventures',
              domain: 'sierraventures.com',
            },
            {
              name: 'Precursor Ventures',
              domain: 'precursorvc.com',
            },
            {
              name: 'Western Technology Investment',
              domain: 'westerntech.com',
            },
            {
              name: 'StartUp Health',
              domain: 'startuphealth.com',
            },
            {
              name: 'Austin Ventures',
              domain: 'austinventures.com',
            },
            {
              name: 'Dreamit Ventures',
              domain: 'dreamit.com',
            },
            {
              name: 'Forum Ventures',
              domain: 'forumvc.com',
            },
            {
              name: 'IVP',
              domain: 'ivp.com',
            },
            {
              name: 'Liquid 2 Ventures',
              domain: 'liquid2.vc',
            },
            {
              name: 'Floodgate',
              domain: 'floodgate.com',
            },
            {
              name: 'Correlation Ventures',
              domain: 'correlationvc.com',
            },
            {
              name: 'Draper Associates',
              domain: 'draper.vc',
            },
            {
              name: 'Versant Ventures',
              domain: 'versantventures.com',
            },
            {
              name: 'NFX',
              domain: 'nfx.com',
            },
            {
              name: '(ERA) Entrepreneurs Roundtable Accelerator',
              domain: 'eranyc.net',
            },
            {
              name: 'ff Venture Capital',
              domain: 'ffvc.com',
            },
            {
              name: 'Comcast Ventures',
              domain: 'comcastventures.com',
            },
            {
              name: 'FirstMark',
              domain: 'firstmarkcorp.com',
            },
            {
              name: 'Foundry Group',
              domain: 'foundry.com.au',
            },
            {
              name: 'Morgenthaler Ventures',
              domain: null,
            },
            {
              name: 'Sigma Partners',
              domain: 'sigmapartners.com',
            },
            {
              name: 'Village Capital',
              domain: 'vilcap.com',
            },
            {
              name: 'Pear VC',
              domain: 'pear.vc',
            },
            {
              name: 'Accomplice',
              domain: 'accomplice.ai',
            },
            {
              name: 'AltaIR Capital',
              domain: 'altair.vc',
            },
            {
              name: 'RA Capital Management',
              domain: 'racap.com',
            },
            {
              name: 'Altos Ventures',
              domain: 'altos.vc',
            },
            {
              name: 'Quiet Capital',
              domain: 'quiet.com',
            },
            {
              name: 'Sapphire Ventures',
              domain: 'sapphireventures.com',
            },
            {
              name: 'Nexus Venture Partners',
              domain: 'nexusvp.com',
            },
            {
              name: 'Techstars Ventures',
              domain: null,
            },
            {
              name: 'Village Global',
              domain: 'villageglobal.vc',
            },
            {
              name: 'Thrive Capital',
              domain: 'thrivecap.com',
            },
            {
              name: 'Meritech Capital Partners',
              domain: null,
            },
            {
              name: 'Keiretsu Forum',
              domain: 'k4northwest.com',
            },
            {
              name: 'Sutter Hill Ventures',
              domain: 'shv.com',
            },
            {
              name: 'Innova Memphis',
              domain: 'innovamemphis.com',
            },
            {
              name: 'Endeavor Catalyst',
              domain: null,
            },
            {
              name: 'Ignition Partners',
              domain: 'ignition.vc',
            },
            {
              name: 'CSC Upshot',
              domain: null,
            },
            {
              name: 'Mohr Davidow Ventures',
              domain: 'mdv.com',
            },
            {
              name: 'Domain Associates',
              domain: 'domainvc.com',
            },
            {
              name: 'Digital Currency Group',
              domain: 'dcg.co',
            },
            {
              name: 'SV Health Investors',
              domain: 'svhealthinvestors.com',
            },
            {
              name: 'QED Investors',
              domain: 'qedinvestors.com',
            },
            {
              name: 'AME Cloud Ventures',
              domain: 'amecloudventures.com',
            },
            {
              name: 'Boost VC',
              domain: 'boost.vc',
            },
            {
              name: 'Scale Venture Partners',
              domain: 'scalevp.com',
            },
            {
              name: 'Maveron',
              domain: 'maveron.com',
            },
            {
              name: 'August Capital',
              domain: 'augustcap.com',
            },
            {
              name: 'MPM Capital',
              domain: 'mpmcapital.com',
            },
            {
              name: 'Sofinnova Investments',
              domain: null,
            },
            {
              name: 'Streamlined Ventures',
              domain: 'streamlined.vc',
            },
            {
              name: 'Red Cedar Ventures',
              domain: null,
            },
            {
              name: 'Strong Ventures',
              domain: 'strongvc.com',
            },
            {
              name: 'Bloomberg Beta',
              domain: 'bloombergbeta.com',
            },
            {
              name: 'DN Capital',
              domain: 'dncapital.com',
            },
            {
              name: 'Walden International',
              domain: 'waldeninternationalschool.com',
            },
            {
              name: 'Eniac Ventures',
              domain: 'eniac.vc',
            },
            {
              name: 'VantagePoint Capital Partners',
              domain: null,
            },
            {
              name: 'Kapor Capital',
              domain: 'kaporcapital.com',
            },
            {
              name: 'Flagship Pioneering',
              domain: 'flagshippioneering.com',
            },
            {
              name: 'Vulcan Capital',
              domain: null,
            },
            {
              name: 'Next47',
              domain: 'next47.com',
            },
            {
              name: 'Ulu Ventures',
              domain: 'uluventures.com',
            },
            {
              name: 'Samsung NEXT',
              domain: 'samsungnext.com',
            },
            {
              name: 'Revolution',
              domain: 'harakosan.co.jp',
            },
            {
              name: 'Storm Ventures',
              domain: 'stormventures.com',
            },
            {
              name: 'Alta Partners',
              domain: 'altapartners.com',
            },
            {
              name: 'DAG Ventures',
              domain: 'dagventures.com',
            },
            {
              name: 'Craft Ventures',
              domain: 'craftventures.com',
            },
            {
              name: 'UpHonest Capital',
              domain: 'uphonestcapital.com',
            },
            {
              name: 'TechNexus Venture Collaborative',
              domain: 'technexus.com',
            },
            {
              name: 'NextView Ventures',
              domain: 'nextview.vc',
            },
            {
              name: 'Advanced Technology Ventures',
              domain: null,
            },
            {
              name: 'Massachusetts Clean Energy Center',
              domain: 'masscec.com',
            },
            {
              name: 'Pegasus Tech Ventures',
              domain: 'pegasustechventures.com',
            },
            {
              name: 'Blumberg Capital',
              domain: 'blumbergcapital.com',
            },
            {
              name: 'Forerunner Ventures',
              domain: 'forerunnerventures.com',
            },
            {
              name: 'Wellington Management',
              domain: 'wellingtonmgt.com',
            },
            {
              name: 'Pritzker Group Venture Capital',
              domain: null,
            },
            {
              name: 'Innovation Endeavors',
              domain: 'innovationendeavors.com',
            },
            {
              name: 'Rev1 Ventures',
              domain: 'rev1ventures.com',
            },
            {
              name: 'Hummer Winblad Venture Partners',
              domain: 'hwvp.com',
            },
            {
              name: 'Emergence',
              domain: 'emergence.ie',
            },
            {
              name: 'Peterson Ventures',
              domain: 'petersonventures.com',
            },
            {
              name: 'Alexandria Venture Investments',
              domain: null,
            },
            {
              name: 'Susa Ventures',
              domain: 'susaventures.com',
            },
            {
              name: 'DNX Ventures',
              domain: 'dnx.vc',
            },
            {
              name: 'Pantera Capital',
              domain: 'panteracapital.com',
            },
            {
              name: '10X Capital',
              domain: '10xcapital.com',
            },
            {
              name: 'MaC Venture Capital',
              domain: 'macventurecapital.com',
            },
            {
              name: 'Cultivation Capital',
              domain: 'cultivationcapital.com',
            },
            {
              name: 'Greenspring Associates',
              domain: 'greenspringassociates.com',
            },
            {
              name: 'Ribbit Capital',
              domain: 'ribbitcap.com',
            },
            {
              name: 'Compound',
              domain: 'compound.finance',
            },
            {
              name: 'Sevin Rosen Funds',
              domain: 'srfunds.com',
            },
            {
              name: 'Trident Capital',
              domain: 'tridentcap.com',
            },
            {
              name: 'Kickstart',
              domain: 'kickstart.pk',
            },
            {
              name: 'ICONIQ Growth',
              domain: null,
            },
            {
              name: 'Freestyle Capital',
              domain: 'freestyle.vc',
            },
            {
              name: 'RiverPark Ventures',
              domain: null,
            },
            {
              name: 'Baseline Ventures',
              domain: 'baselinev.com',
            },
            {
              name: 'OUP (Osage University Partners)',
              domain: null,
            },
            {
              name: 'Second Century Ventures',
              domain: 'scv.vc',
            },
            {
              name: 'Asymmetry Ventures',
              domain: null,
            },
            {
              name: 'Polychain',
              domain: 'polychain.capital',
            },
            {
              name: 'TEDCO',
              domain: 'tedco.md',
            },
            {
              name: 'B Capital Group',
              domain: 'bcapgroup.com',
            },
            {
              name: 'Citi Ventures',
              domain: null,
            },
            {
              name: 'Pelion Venture Partners',
              domain: 'pelionvp.com',
            },
            {
              name: 'DHVC',
              domain: 'dh.vc',
            },
            {
              name: 'Grotech Ventures',
              domain: 'grotech.com',
            },
            {
              name: 'Invus',
              domain: 'invus.com',
            },
            {
              name: 'Globespan Capital Partners',
              domain: 'globespancapital.com',
            },
            {
              name: 'Tribe Capital',
              domain: 'tribecap.co',
            },
            {
              name: 'Vivo Capital',
              domain: 'vivocapital.com',
            },
            {
              name: 'Columbia Capital',
              domain: 'colcap.com',
            },
            {
              name: 'Valor Equity Partners',
              domain: null,
            },
            {
              name: 'Gradient Ventures',
              domain: 'gradient.com',
            },
            {
              name: 'SignalFire',
              domain: 'signalfire.com',
            },
            {
              name: 'Capital Factory',
              domain: 'capitalfactory.com',
            },
            {
              name: 'Two Sigma Ventures',
              domain: 'twosigmaventures.com',
            },
            {
              name: 'Homebrew',
              domain: 'brew.sh',
            },
            {
              name: 'Amplify Partners',
              domain: 'amplifypartners.com',
            },
            {
              name: 'Sound Ventures',
              domain: 'soundventures.com',
            },
            {
              name: 'Delphi Ventures',
              domain: null,
            },
            {
              name: 'Invest Nebraska',
              domain: 'investnebraska.com',
            },
            {
              name: 'Silverton Partners',
              domain: 'silvertonpartners.com',
            },
            {
              name: 'Icon Ventures',
              domain: 'iconventures.com',
            },
            {
              name: 'Bay Partners',
              domain: 'baypartners.com',
            },
            {
              name: 'Lightbank',
              domain: 'lightbank.com',
            },
            {
              name: 'WI Harper Group',
              domain: 'wiharper.com',
            },
            {
              name: 'Haystack',
              domain: 'haystack.vc',
            },
            {
              name: 'Franklin Templeton Investments',
              domain: 'franklintempleton.com',
            },
            {
              name: 'Costanoa Ventures',
              domain: 'costanoa.vc',
            },
            {
              name: 'ACME Capital',
              domain: 'acme.vc',
            },
            {
              name: 'Pioneer Fund',
              domain: 'pioneerfund.vc',
            },
            {
              name: 'boldstart ventures',
              domain: 'boldstart.vc',
            },
            {
              name: 'Cota Capital',
              domain: 'cotacapital.com',
            },
            {
              name: '.406 Ventures',
              domain: 'venturesquare.net',
            },
            {
              name: 'Tenaya Capital',
              domain: 'tenayacapital.com',
            },
            {
              name: 'Avalon Ventures',
              domain: 'avalonbioventures.com',
            },
            {
              name: 'Maverick Ventures',
              domain: 'maverickventures.com',
            },
            {
              name: 'Alloy Ventures',
              domain: 'alloyventures.com',
            },
            {
              name: 'Industry Ventures',
              domain: 'industryventures.com',
            },
            {
              name: 'Ridge Ventures',
              domain: 'ridge.vc',
            },
            {
              name: 'City Light Capital',
              domain: 'citylight.vc',
            },
            {
              name: 'EPIC Ventures',
              domain: 'epicvc.com',
            },
            {
              name: 'Dragoneer Investment Group',
              domain: 'dragoneer.com',
            },
            {
              name: 'Voyager Capital',
              domain: 'voyagercapital.com',
            },
            {
              name: 'Allen & Company',
              domain: 'alleninvestments.com',
            },
            {
              name: '5AM Ventures',
              domain: '5amventures.com',
            },
            {
              name: 'Baird Capital',
              domain: 'bairdcapital.com',
            },
            {
              name: 'BlueRun Ventures',
              domain: 'brv.com',
            },
            {
              name: 'New Leaf Venture Partners',
              domain: null,
            },
            {
              name: 'Elemental Excelerator',
              domain: 'elementalexcelerator.com',
            },
            {
              name: 'Contour Venture Partners',
              domain: null,
            },
            {
              name: 'Commerce Ventures',
              domain: 'commerce.vc',
            },
            {
              name: 'Resolute Ventures',
              domain: 'resolute.vc',
            },
            {
              name: 'Bullpen Capital',
              domain: 'bullpencap.com',
            },
            {
              name: 'Primary Venture Partners',
              domain: null,
            },
            {
              name: 'Valor Capital Group',
              domain: 'valorcapitalgroup.com',
            },
            {
              name: 'NGP Capital',
              domain: 'ngpcap.com',
            },
            {
              name: 'Azure Capital Partners',
              domain: 'azurecap.com',
            },
            {
              name: 'NC IDEA',
              domain: 'ncidea.org',
            },
            {
              name: 'Hyde Park Venture Partners',
              domain: 'hydeparkvp.com',
            },
            {
              name: 'Granite Ventures',
              domain: 'granitevc.com',
            },
            {
              name: 'SixThirty',
              domain: 'sixthirty.co',
            },
            {
              name: 'Launch NY',
              domain: 'launchny.org',
            },
            {
              name: 'OCA Ventures',
              domain: 'ocaventures.com',
            },
            {
              name: 'Beringea',
              domain: 'beringea.com',
            },
          ],
        },
        {
          name: 'Global Seed Investors',
          items: [
            {
              name: '91 Ventures',
              domain: 'venturesquare.net',
            },
            {
              name: '122WEST Ventures',
              domain: 'west.ventures',
            },
            {
              name: '1517 Fund',
              domain: 'fundrise.com',
            },
            {
              name: '2048 Ventures',
              domain: 'venturesquare.net',
            },
            {
              name: 'Afore',
              domain: 'loveme.com',
            },
            {
              name: 'Amplify',
              domain: 'amplifytalent.com',
            },
            {
              name: 'Angular Ventures',
              domain: 'angularventures.com',
            },
            {
              name: 'Array Ventures',
              domain: null,
            },
            {
              name: 'Ascend',
              domain: 'useascend.com',
            },
            {
              name: 'Atelier Ventures',
              domain: 'atelierventures.co',
            },
            {
              name: 'Atlantic Labs',
              domain: 'atlanticlabsfoodlabsplatform.com',
            },
            {
              name: 'Atypical Ventures',
              domain: null,
            },
            {
              name: 'Awesome People Ventures',
              domain: null,
            },
            {
              name: 'Basecamp/AVG',
              domain: null,
            },
            {
              name: 'Basis Set Ventures',
              domain: 'basissetventures.com',
            },
            {
              name: 'Behind Genius Ventures',
              domain: 'behindgeniusventures.com',
            },
            {
              name: 'Beta Boom',
              domain: 'betaboom.com',
            },
            {
              name: 'Betaworks',
              domain: 'betaworks.com',
            },
            {
              name: 'Better Ventures',
              domain: 'better.vc',
            },
            {
              name: 'Bloom',
              domain: 'bloom.sh',
            },
            {
              name: 'Blue 9 Capital',
              domain: 'blue9capital.com',
            },
            {
              name: 'Blue Collective',
              domain: 'bluecollective.com',
            },
            {
              name: 'Boost VC',
              domain: 'boost.vc',
            },
            {
              name: 'Box Group',
              domain: 'boxgroup.com',
            },
            {
              name: 'Brand New Matter',
              domain: 'brandnewmatter.com',
            },
            {
              name: 'Bread and Butter',
              domain: 'breadandbutter.ie',
            },
            {
              name: 'Brickyard',
              domain: 'justlaybrick.com',
            },
            {
              name: 'bValue',
              domain: 'b4value.net',
            },
            {
              name: 'Caffeinated Capital',
              domain: 'caffeinatedcapital.com',
            },
            {
              name: 'CapitalX',
              domain: 'capitalx.company',
            },
            {
              name: 'Cascade Seed Fund',
              domain: 'cascadeseedfund.com',
            },
            {
              name: 'Charge',
              domain: 'charge.us',
            },
            {
              name: 'Cherry',
              domain: 'downloadcherry.com',
            },
            {
              name: 'Chingona Ventures',
              domain: 'chingona.ventures',
            },
            {
              name: 'Cleo Capital',
              domain: 'cleocap.com',
            },
            {
              name: 'Coughdrop',
              domain: 'coughdrop.com',
            },
            {
              name: 'Cowboy',
              domain: 'cowboy.com',
            },
            {
              name: 'Darling VC',
              domain: null,
            },
            {
              name: 'DevX Venture Fund',
              domain: null,
            },
            {
              name: 'Engineering Capital',
              domain: 'engineeringcapital.com',
            },
            {
              name: 'First Star Ventures',
              domain: 'firststar.vc',
            },
            {
              name: 'Floodgate',
              domain: 'floodgate.com',
            },
            {
              name: 'Flybridge ',
              domain: 'flybridge.com',
            },
            {
              name: 'Form Capital',
              domain: 'formcapital.com',
            },
            {
              name: 'GFR Fund',
              domain: 'gfrfund.com',
            },
            {
              name: 'Global Founders Capital',
              domain: 'globalfounderscapital.com',
            },
            {
              name: 'Graph Ventures',
              domain: 'graphventures.com',
            },
            {
              name: 'Hive Hatch',
              domain: 'hivehatch.com',
            },
            {
              name: 'Homebrew',
              domain: 'brew.sh',
            },
            {
              name: 'Hustle Fund',
              domain: 'hustlefund.vc',
            },
            {
              name: 'Icebreaker.vc',
              domain: 'icebreaker.vc',
            },
            {
              name: 'Indicator Ventures',
              domain: 'indicatorventures.com',
            },
            {
              name: 'Intonation Ventures',
              domain: null,
            },
            {
              name: 'irrvrntVC',
              domain: 'irrvrnt.com',
            },
            {
              name: 'January Ventures',
              domain: 'january.ventures',
            },
            {
              name: 'Kima Ventures',
              domain: 'kimaventures.com',
            },
            {
              name: 'Kindred Ventures',
              domain: 'kindredventures.com',
            },
            {
              name: 'Kogito',
              domain: 'kogito-marketing.com',
            },
            {
              name: 'Laconia',
              domain: 'laconiacapitalgroup.com',
            },
            {
              name: 'Leadout Capital',
              domain: null,
            },
            {
              name: 'Lightbank VC',
              domain: null,
            },
            {
              name: 'LocalGlobe',
              domain: 'localglobe.vc',
            },
            {
              name: 'Market One Capital',
              domain: 'moc.vc',
            },
            {
              name: 'Maven Ventures',
              domain: 'maven-ventures.com',
            },
            {
              name: 'Moonfire',
              domain: 'moonfire.com',
            },
            {
              name: 'Movens',
              domain: 'movensee.com',
            },
            {
              name: 'Moxxie Ventures',
              domain: 'moxxie.vc',
            },
            {
              name: 'Mucker',
              domain: 'mucker.com',
            },
            {
              name: 'Narrative Fund',
              domain: 'narrative.fund',
            },
            {
              name: 'NeoTerra Capital',
              domain: 'neoterracapital.com',
            },
            {
              name: 'Newstack',
              domain: 'newstack.com',
            },
            {
              name: 'Notation Capital',
              domain: 'notation.vc',
            },
            {
              name: 'Oceans Ventures',
              domain: 'oceans.ventures',
            },
            {
              name: 'Otherwise Fund',
              domain: null,
            },
            {
              name: 'OVO Fund',
              domain: 'ovofund.com',
            },
            {
              name: 'Pear VC',
              domain: 'pear.vc',
            },
            {
              name: 'Pillar VC',
              domain: 'pillar.vc',
            },
            {
              name: 'Plug and Play',
              domain: 'plugandplaytechcenter.com',
            },
            {
              name: 'Polymath',
              domain: 'polymath3.digital',
            },
            {
              name: 'Precursor Ventures',
              domain: 'precursorvc.com',
            },
            {
              name: 'Quake Capital Partners',
              domain: null,
            },
            {
              name: 'RareBreed Ventures',
              domain: null,
            },
            {
              name: 'Remote First Capital',
              domain: 'remotefirstcapital.com',
            },
            {
              name: 'Reno Seed Fund',
              domain: 'renoseedfund.com',
            },
            {
              name: 'Resolute Ventures',
              domain: 'resolute.vc',
            },
            {
              name: 'Root & Shoot Ventures',
              domain: null,
            },
            {
              name: 'Scribble',
              domain: 'scribble-svg.com',
            },
            {
              name: 'Seedcamp',
              domain: 'seedcamp.at',
            },
            {
              name: 'Shrug',
              domain: 'shrug.vc',
            },
            {
              name: 'Slow',
              domain: 'slowtwitch.com',
            },
            {
              name: 'Soma Capital',
              domain: 'somacapital.com',
            },
            {
              name: 'Starttech Ventures',
              domain: 'starttech.vc',
            },
            {
              name: 'Sterling Road',
              domain: 'sterlingroad.com',
            },
            {
              name: 'Supernode',
              domain: 'supernode.cn',
            },
            {
              name: 'TBD Angels',
              domain: 'tbdangels.com',
            },
            {
              name: 'The Fund',
              domain: 'thefund.vc',
            },
            {
              name: 'The Helm',
              domain: 'thehelm.co',
            },
            {
              name: 'The Todd & Rahul Angel Fund',
              domain: null,
            },
            {
              name: 'Unbridled Ventures',
              domain: null,
            },
            {
              name: 'Unpopular Ventures',
              domain: 'unpopular.vc',
            },
            {
              name: 'Unshackled Ventures',
              domain: 'unshackledvc.com',
            },
            {
              name: 'Untapped Capital',
              domain: 'untapped.vc',
            },
            {
              name: 'Village Global',
              domain: 'villageglobal.vc',
            },
            {
              name: 'VitalizeVC',
              domain: 'vitalize.vc',
            },
            {
              name: 'VU Venture Partners',
              domain: 'vuventurepartners.com',
            },
            {
              name: 'XYZ Venture Capital',
              domain: 'xyz.vc',
            },
          ],
        },
        {
          name: 'Crypto Investors',
          items: [
            {
              name: 'Andreessen Horowitz',
              domain: 'a16z.com',
            },
            {
              name: 'Polychain Capital',
              domain: 'polychain.capital',
            },
            {
              name: 'Pantera Capital',
              domain: 'panteracapital.com',
            },
            {
              name: 'Blockchain Capital',
              domain: 'blockchaincapital.pro',
            },
            {
              name: 'Digital Currency Group',
              domain: 'dcg.co',
            },
            {
              name: 'CoinFund',
              domain: 'coinfund.io',
            },
            {
              name: 'Placeholder',
              domain: 'placeholder.vc',
            },
            {
              name: 'Galaxy Digital',
              domain: 'galaxydigital.com',
            },
            {
              name: 'Fenbushi Capital',
              domain: 'fenbushi.digital',
            },
            {
              name: 'ConsenSys Ventures',
              domain: null,
            },
            {
              name: 'Electric Capital',
              domain: 'electriccapital.com',
            },
            {
              name: 'Alameda Research',
              domain: 'alameda-research.com',
            },
            {
              name: 'Multicoin Capital',
              domain: 'multicoin.capital',
            },
            {
              name: 'Paradigm',
              domain: 'paradigm.xyz',
            },
            {
              name: 'Scalar Capital',
              domain: 'scalar.capital',
            },
            {
              name: '1confirmation',
              domain: '1confirmation.com',
            },
            {
              name: 'Winklevoss Capital',
              domain: 'winklevosscapital.com',
            },
            {
              name: 'Boost VC',
              domain: 'boost.vc',
            },
            {
              name: 'Initialized Capital',
              domain: 'initialized.com',
            },
            {
              name: 'Accomplice',
              domain: 'accomplice.ai',
            },
            {
              name: 'Dragonfly Capital Partners',
              domain: 'dragonflycapital.com',
            },
            {
              name: 'Ribbit Capital',
              domain: 'ribbitcap.com',
            },
            {
              name: 'Wavemaker Genesis',
              domain: null,
            },
            {
              name: 'Framework Ventures',
              domain: 'framework.ventures',
            },
            {
              name: 'NGC Ventures',
              domain: 'ngc.fund',
            },
            {
              name: 'NEO Global Capital',
              domain: null,
            },
            {
              name: 'Hashed',
              domain: 'hashed.com',
            },
            {
              name: 'GGV Capital',
              domain: 'ggvc.com',
            },
            {
              name: 'Lemniscap',
              domain: 'lemniscap.com',
            },
            {
              name: 'CMS Holdings',
              domain: null,
            },
            {
              name: 'Kenetic Capital',
              domain: 'kenetic.capital',
            },
            {
              name: 'Cumberland DRW',
              domain: null,
            },
            {
              name: 'Collaborative Fund',
              domain: 'collabfund.com',
            },
            {
              name: 'BlockTower Capital',
              domain: 'blocktower.com',
            },
            {
              name: 'IDG Capital',
              domain: 'idgcapital.com',
            },
            {
              name: 'Sino Global Capital',
              domain: 'sinoglobalcapital.com',
            },
            {
              name: 'Castle Island Ventures',
              domain: 'castleisland.vc',
            },
            {
              name: 'CoinShares',
              domain: 'coinshares.com',
            },
            {
              name: 'Morgan Creek Digital',
              domain: null,
            },
            {
              name: 'Blockchain Valley Ventures',
              domain: 'bvventures.ch',
            },
            {
              name: 'Cygni Capital',
              domain: null,
            },
            {
              name: 'Amber Group',
              domain: 'ambergroupindia.com',
            },
            {
              name: 'Spartan Group',
              domain: 'spartangroup.io',
            },
            {
              name: 'Blockchain.com Ventures',
              domain: null,
            },
            {
              name: 'Galaxy Interactive',
              domain: 'galaxyinteractiveonline.com',
            },
            {
              name: 'Draper Associates',
              domain: 'draper.vc',
            },
            {
              name: 'Galaxy Fund Management',
              domain: null,
            },
            {
              name: 'Avon Ventures',
              domain: 'avonventuresports.com',
            },
            {
              name: 'K2 Global',
              domain: 'k2globalvc.com',
            },
          ],
        },
        {
          name: 'Growth & Crossover Investors',
          items: [
            {
              name: 'Sequoia Capital',
              domain: 'sequoiacapital.co.uk',
            },
            {
              name: 'Tiger Global Management',
              domain: 'tigerglobal.com',
            },
            {
              name: 'Coatue Management',
              domain: 'coatue.com',
            },
            {
              name: 'General Atlantic',
              domain: 'generalatlantic.com',
            },
            {
              name: 'Dragoneer Investment Group',
              domain: 'dragoneer.com',
            },
            {
              name: 'Baillie Gifford',
              domain: 'bailliegifford.com',
            },
            {
              name: 'Fidelity Investments',
              domain: 'fidelityinvestments.ie',
            },
            {
              name: 'CapitalG (formerly Google Capital)',
              domain: null,
            },
            {
              name: 'Accel Partners',
              domain: null,
            },
            {
              name: 'Advent International',
              domain: 'adventure.uk.com',
            },
            {
              name: 'Viking Global Investors',
              domain: 'vikingglobal.com',
            },
            {
              name: 'IVP (Institutional Venture Partners)',
              domain: null,
            },
            {
              name: 'D1 Capital Partners',
              domain: null,
            },
            {
              name: 'Lone Pine Capital',
              domain: 'lonepinecapital.com',
            },
            {
              name: 'Wellington Management',
              domain: 'wellingtonmgt.com',
            },
            {
              name: 'Lightspeed Venture Partners',
              domain: 'lsvp.com',
            },
            {
              name: 'Silver Lake',
              domain: 'silverlakeny.com',
            },
            {
              name: 'Franklin Templeton',
              domain: 'franklintempletonindia.com',
            },
            {
              name: 'Valor Equity Partners',
              domain: null,
            },
            {
              name: 'SoftBank Vision Fund',
              domain: 'visionfund.com',
            },
            {
              name: 'Warburg Pincus',
              domain: 'warburgpincus.com',
            },
            {
              name: 'A16z (Andreessen Horowitz)',
              domain: null,
            },
            {
              name: 'Kleiner Perkins',
              domain: 'kleinerperkins.com',
            },
            {
              name: 'Bessemer Venture Partners',
              domain: 'bvp.com',
            },
            {
              name: 'New Enterprise Associates (NEA)',
              domain: null,
            },
            {
              name: 'T. Rowe Price',
              domain: 'moneyconfidentkids.com',
            },
            {
              name: 'TCV (Technology Crossover Ventures)',
              domain: null,
            },
            {
              name: 'GIC (Government of Singapore Investment Corporation)',
              domain: null,
            },
            {
              name: 'BlackRock',
              domain: 'blackrock.com',
            },
            {
              name: 'KKR (Kohlberg Kravis Roberts)',
              domain: null,
            },
            {
              name: 'Temasek Holdings',
              domain: null,
            },
            {
              name: 'Insight Partners',
              domain: 'insightpartners.com',
            },
            {
              name: 'Norwest Venture Partners',
              domain: 'nvp.com',
            },
            {
              name: 'Highland Capital Partners',
              domain: 'hcp.com',
            },
            {
              name: 'Redpoint Ventures',
              domain: 'redpoint.com',
            },
            {
              name: 'Summit Partners',
              domain: 'gosummitpartners.com',
            },
            {
              name: 'Goldman Sachs Growth Equity',
              domain: null,
            },
            {
              name: 'Accel-KKR',
              domain: 'accel-kkr.com',
            },
            {
              name: 'Altimeter Capital',
              domain: null,
            },
            {
              name: 'ICONIQ Capital',
              domain: 'iconiqcapital.com',
            },
            {
              name: 'Breyer Capital',
              domain: 'breyercapital.com',
            },
            {
              name: 'Silver Lake Partners',
              domain: 'silverlake.com',
            },
            {
              name: 'GGV Capital',
              domain: 'ggvc.com',
            },
            {
              name: 'Crosslink Capital',
              domain: 'crosslinkcapital.com',
            },
            {
              name: 'Battery Ventures',
              domain: 'battery.com',
            },
            {
              name: 'Index Ventures',
              domain: 'indexventures.com',
            },
            {
              name: 'Scale Venture Partners',
              domain: 'scalevp.com',
            },
            {
              name: 'Sapphire Ventures',
              domain: 'sapphireventures.com',
            },
            {
              name: 'TPG Growth',
              domain: null,
            },
            {
              name: 'Founders Fund',
              domain: 'foundersfund.com',
            },
            {
              name: 'Qiming Venture Partners',
              domain: 'qimingvc.com',
            },
            {
              name: 'Ribbit Capital',
              domain: 'ribbitcap.com',
            },
            {
              name: 'FirstMark Capital',
              domain: 'firstmark.com',
            },
            {
              name: 'Charles River Ventures (CRV)',
              domain: null,
            },
            {
              name: 'Thrive Capital',
              domain: 'thrivecap.com',
            },
            {
              name: 'NEA (New Enterprise Associates)',
              domain: null,
            },
            {
              name: 'Polaris Partners',
              domain: 'polarispartners.com',
            },
            {
              name: 'Northzone',
              domain: 'northzone.com',
            },
            {
              name: '8VC',
              domain: '8vc.com',
            },
            {
              name: 'Mithril Capital Management',
              domain: null,
            },
            {
              name: 'Khosla Ventures',
              domain: 'khoslaventures.com',
            },
            {
              name: 'Menlo Ventures',
              domain: 'menlovc.com',
            },
            {
              name: 'Andreessen Horowitz',
              domain: 'a16z.com',
            },
            {
              name: 'Benchmark',
              domain: 'benchmark-inc.com',
            },
            {
              name: 'General Catalyst',
              domain: 'generalcatalyst.com',
            },
            {
              name: 'Spark Capital',
              domain: 'sparkcapital.in',
            },
            {
              name: 'CRV (Charles River Ventures)',
              domain: null,
            },
            {
              name: 'Greylock Partners',
              domain: null,
            },
            {
              name: 'Sequoia Capital China',
              domain: null,
            },
            {
              name: '500 Startups',
              domain: 'startupschool.org',
            },
            {
              name: 'B Capital Group',
              domain: 'bcapgroup.com',
            },
            {
              name: 'Atomico',
              domain: 'atomico.com',
            },
          ],
        },
      ],
    },
    sales_prospects: {
      name: 'Sales Prospects',
      listType: 'companies',
      lists: [
        {
          name: 'Raised >$5M in last 6 months',
          items: [
            {
              name: 'Character.ai',
              domain: 'character.ai',
            },
            {
              name: 'Veza',
              domain: 'veza.com',
            },
            {
              name: 'GlossGenius',
              domain: 'glossgenius.com',
            },
            {
              name: 'TRACTIAN',
              domain: 'tractian.com',
            },
            {
              name: 'Adept AI',
              domain: 'adept.ai',
            },
            {
              name: 'Typeface',
              domain: 'typefaceapp.com',
            },
            {
              name: 'Endor Labs',
              domain: 'endorlabs.com',
            },
            {
              name: 'Runway',
              domain: 'runway.team',
            },
            {
              name: 'MindsDB',
              domain: 'mindsdb.com',
            },
            {
              name: 'Resilience',
              domain: 'cyberresilience.com',
            },
            {
              name: 'Hammerspace',
              domain: 'hammerspaced.com',
            },
            {
              name: 'Zluri',
              domain: 'zluri.com',
            },
            {
              name: 'Akkio',
              domain: 'akkio.com',
            },
            {
              name: 'Replit',
              domain: 'repl.it',
            },
            {
              name: 'AlphaSense',
              domain: 'alphasense.com',
            },
            {
              name: 'Humane',
              domain: 'hu.ma.ne',
            },
            {
              name: 'KoBold Metals',
              domain: 'koboldmetals.com',
            },
            {
              name: 'Arrcus',
              domain: 'arrcus.com',
            },
            {
              name: 'Socket',
              domain: 'socket.tech',
            },
            {
              name: 'Caden',
              domain: 'cadence.com',
            },
            {
              name: 'Osano',
              domain: 'osano.com',
            },
            {
              name: 'Cart.com',
              domain: 'cart.com',
            },
            {
              name: 'Pano',
              domain: 'panopto.com',
            },
            {
              name: 'Protect AI',
              domain: 'protectai.com',
            },
            {
              name: 'Covariant',
              domain: 'covariantcorp.com',
            },
            {
              name: 'Topline Pro',
              domain: 'tlpromo.com',
            },
            {
              name: 'Risc Zero.',
              domain: 'risczero.com',
            },
            {
              name: 'ConductorOne',
              domain: 'conductorone.com',
            },
            {
              name: 'Septerna',
              domain: 'septerna.com',
            },
            {
              name: 'Instawork',
              domain: 'instawork.com',
            },
            {
              name: 'Render',
              domain: 'render.com',
            },
            {
              name: 'Helm.ai',
              domain: 'helm.ai',
            },
            {
              name: 'Redpanda Data',
              domain: 'redpanda.com',
            },
            {
              name: 'Nexla',
              domain: 'nexla.com',
            },
            {
              name: 'Verifiable',
              domain: 'verifiable.com',
            },
            {
              name: 'Insider',
              domain: 'useinsider.com',
            },
            {
              name: 'Watershed',
              domain: 'watershed.com',
            },
            {
              name: 'Lambda',
              domain: 'lambdalabs.com',
            },
            {
              name: 'Zip',
              domain: 'zip.co',
            },
            {
              name: 'Celestial AI',
              domain: 'celestial.ai',
            },
            {
              name: 'Jirav',
              domain: 'jirav.com',
            },
            {
              name: 'Rootly',
              domain: 'rootly.com',
            },
            {
              name: 'Perplexity AI',
              domain: 'perplexity.ai',
            },
            {
              name: 'Simpplr',
              domain: 'simpplr.com',
            },
            {
              name: 'Parabola',
              domain: 'parabola.io',
            },
            {
              name: 'Tome',
              domain: 'tome.com',
            },
            {
              name: 'Symmetry Systems',
              domain: 'symmetry-systems.com',
            },
            {
              name: 'Stay Ai',
              domain: 'stay.ai',
            },
            {
              name: 'Wunderkind',
              domain: 'wunderkindsensory.com',
            },
            {
              name: 'Anduin',
              domain: 'anduintransact.com',
            },
            {
              name: 'Strangeworks',
              domain: 'strangeworks.com',
            },
            {
              name: 'Faro Health',
              domain: 'farohealth.com',
            },
            {
              name: 'HeadRace',
              domain: 'headrace.com',
            },
            {
              name: 'Relay',
              domain: 'relay.im',
            },
            {
              name: 'Riot',
              domain: 'riot.im',
            },
            {
              name: 'SapientAI',
              domain: 'sapientai.io',
            },
            {
              name: 'Engrain',
              domain: 'engrain.com',
            },
            {
              name: 'Finicast',
              domain: 'finicast.com',
            },
            {
              name: 'Blackpoint Cyber',
              domain: 'blackpointrisk.com',
            },
            {
              name: 'Linera',
              domain: 'linera.com.tr',
            },
            {
              name: 'Togal.AI',
              domain: 'togal.ai',
            },
            {
              name: 'On.Energy',
              domain: 'onenergyet.com',
            },
            {
              name: 'FireCompass',
              domain: 'firecompass.com',
            },
            {
              name: 'StructShare',
              domain: 'structshare.com',
            },
            {
              name: 'AudiencePlus',
              domain: 'audienceplus.com',
            },
            {
              name: 'Prophet Exchange',
              domain: 'prophetbettingexchange.com',
            },
            {
              name: 'Distyl AI',
              domain: 'distyl.ai',
            },
            {
              name: 'Hype',
              domain: 'tryhype.co',
            },
            {
              name: 'Hook Security',
              domain: 'hooksecurity.co',
            },
            {
              name: 'Temporal Technologies',
              domain: null,
            },
            {
              name: 'Middleware',
              domain: 'share.creditcard',
            },
            {
              name: 'Pivotal Commware',
              domain: 'pivotalcommware.com',
            },
            {
              name: 'Monument',
              domain: 'getmonument.com',
            },
            {
              name: 'Archway',
              domain: 'archwaysupport.com',
            },
            {
              name: 'Payabli',
              domain: 'payabli.com',
            },
            {
              name: 'CollX',
              domain: 'collx.app',
            },
            {
              name: 'HelloTeam',
              domain: 'helloteam.com',
            },
            {
              name: 'Alcion',
              domain: 'alcion.ai',
            },
            {
              name: 'Monarx',
              domain: 'monarx.com',
            },
            {
              name: 'Octane',
              domain: 'getoctane.io',
            },
            {
              name: 'ID.me',
              domain: 'id.me',
            },
            {
              name: 'Bowery Valuation',
              domain: 'boweryvaluation.com',
            },
            {
              name: 'Cognaize',
              domain: 'cognaize.com',
            },
            {
              name: 'Hawkin Dynamics',
              domain: 'hawkindynamics.com',
            },
            {
              name: 'Highlight',
              domain: 'letshighlight.com',
            },
            {
              name: 'Captions',
              domain: 'captions.ai',
            },
            {
              name: 'Tools For Humanity',
              domain: 'toolsforhumanity.com',
            },
            {
              name: 'Remedial Health',
              domain: 'remedialhealthcare.in',
            },
            {
              name: 'Tenant',
              domain: 'tenantcloud.com',
            },
            {
              name: 'FIGUR8',
              domain: 'figur8tech.com',
            },
            {
              name: 'Odeko',
              domain: 'odeko.com',
            },
            {
              name: 'Gooten',
              domain: 'gooten.com',
            },
            {
              name: 'Halcyon',
              domain: 'halcyon.ai',
            },
            {
              name: 'Span.IO',
              domain: 'span.io',
            },
            {
              name: 'Katmai Tech',
              domain: 'katmaitech.com',
            },
            {
              name: 'Blueprint',
              domain: 'blueprint.store',
            },
            {
              name: 'Earthly Technologies',
              domain: 'earthly.dev',
            },
            {
              name: 'Federato',
              domain: 'federato.ai',
            },
            {
              name: 'ShapesXR',
              domain: 'shapesxr.com',
            },
            {
              name: 'Nuvocargo',
              domain: 'nuvocargo.com',
            },
            {
              name: 'Ubicept',
              domain: 'ubicept.com',
            },
            {
              name: 'Bedrock Ocean Exploration',
              domain: 'bedrockocean.com',
            },
            {
              name: 'BusRight',
              domain: 'busright.com',
            },
            {
              name: 'Wing Cloud',
              domain: null,
            },
            {
              name: 'Ather Digital',
              domain: 'atherdigital.com',
            },
            {
              name: 'FarmTrace',
              domain: 'farmtrace.com',
            },
            {
              name: 'MedScout',
              domain: 'medscout.io',
            },
            {
              name: 'WireMock',
              domain: 'wiremock.io',
            },
            {
              name: 'Gryps',
              domain: 'gryps.io',
            },
            {
              name: 'Thread',
              domain: 'thread.org',
            },
            {
              name: 'Bloomfilter',
              domain: 'thebloomfilter.com',
            },
            {
              name: 'DexCare',
              domain: 'dexcare.com',
            },
            {
              name: 'Peaxy',
              domain: 'peaxy.net',
            },
            {
              name: 'Spiff',
              domain: 'spiff.com',
            },
            {
              name: 'Efficient Capital Labs',
              domain: 'ecaplabs.com',
            },
            {
              name: 'CompScience',
              domain: 'compscience.com',
            },
            {
              name: 'Groundlight AI',
              domain: 'groundlight.ai',
            },
            {
              name: 'Huntress',
              domain: 'huntress.com',
            },
            {
              name: 'Novata',
              domain: 'novata.com',
            },
            {
              name: 'Croissant',
              domain: 'getcroissant.com',
            },
            {
              name: 'Hathora',
              domain: 'hathora.dev',
            },
            {
              name: 'Theradaptive',
              domain: 'theradaptive.com',
            },
            {
              name: 'Flox',
              domain: 'flox.ai',
            },
            {
              name: 'Cargobot',
              domain: 'cargobot.io',
            },
            {
              name: 'Boombox',
              domain: 'boombox.io',
            },
            {
              name: 'Mosaic.tech',
              domain: 'mosaic.tech',
            },
            {
              name: 'Cortex',
              domain: 'wearecortex.com',
            },
            {
              name: 'Akooda',
              domain: 'akooda.co',
            },
            {
              name: 'Spearbit',
              domain: null,
            },
            {
              name: 'LeafLink',
              domain: 'leaflink.com',
            },
            {
              name: 'Lavender',
              domain: 'lavenderbridal.ca',
            },
            {
              name: 'Spline',
              domain: 'spline.design',
            },
            {
              name: 'Nimble Robotics',
              domain: 'nimble.ai',
            },
            {
              name: 'Ennabl',
              domain: 'ennabl.com',
            },
            {
              name: 'CloudZero',
              domain: 'cloudzero.com',
            },
            {
              name: 'Semgrep',
              domain: 'semgrep.dev',
            },
            {
              name: 'Polar Analytics',
              domain: 'polaranalytics.co',
            },
            {
              name: 'Tromzo',
              domain: 'tromzo.com',
            },
            {
              name: 'Warp',
              domain: 'warp.la',
            },
            {
              name: 'Nuqleous',
              domain: 'nuqleous.com',
            },
            {
              name: 'Dashworks',
              domain: 'dashworks.ai',
            },
            {
              name: 'R-Zero',
              domain: 'rzero.com',
            },
            {
              name: 'Skillit',
              domain: 'myskillit.com',
            },
            {
              name: 'Abbey Labs',
              domain: 'abbeylabs.com.au',
            },
            {
              name: 'EvenUp',
              domain: 'evenuplaw.com',
            },
            {
              name: 'Bitwise Industries',
              domain: 'bitwiseindustries.com',
            },
            {
              name: 'Treehouse',
              domain: 'thetreehouse.co',
            },
            {
              name: 'Reflex',
              domain: 'reflextdc.com',
            },
            {
              name: 'Shift5',
              domain: 'shift5.io',
            },
            {
              name: 'Dylibso',
              domain: null,
            },
            {
              name: 'Kolena',
              domain: 'kolena.io',
            },
            {
              name: 'Limble CMMS',
              domain: 'limblecmms.com',
            },
            {
              name: 'Elementl',
              domain: 'elementl.com',
            },
            {
              name: 'env0',
              domain: 'env0.com',
            },
            {
              name: 'Grit',
              domain: 'gritadv.com',
            },
            {
              name: 'Vitally',
              domain: 'vitally.com.br',
            },
            {
              name: 'Spendflo',
              domain: 'spendflo.com',
            },
            {
              name: 'ProsperOps',
              domain: 'prosperops.com',
            },
            {
              name: 'Zylo',
              domain: 'zylo.com',
            },
            {
              name: 'OpenEnvoy',
              domain: 'openenvoy.com',
            },
            {
              name: 'Bluedot',
              domain: 'blue-dot.blog',
            },
            {
              name: 'Trunk Tools',
              domain: 'trunk.tools',
            },
            {
              name: 'DataDome',
              domain: 'datadome.co',
            },
            {
              name: 'Flywheel.io',
              domain: 'flywheel.io',
            },
            {
              name: 'Datapeople',
              domain: 'datapeoplebd.com',
            },
            {
              name: 'Persefoni',
              domain: 'persefoni.com',
            },
            {
              name: 'Fitted',
              domain: 'fittedrunning.com',
            },
            {
              name: 'Predibase',
              domain: 'predibase.com',
            },
            {
              name: 'City Innovate',
              domain: 'startupinresidence.org',
            },
            {
              name: 'Manifest',
              domain: 'bemanifest.com',
            },
            {
              name: 'Xclaim',
              domain: 'x-claim.com',
            },
            {
              name: 'Disclo',
              domain: 'disclo.com',
            },
            {
              name: 'REPOWR',
              domain: 'repowr.com',
            },
            {
              name: 'Sending.Me',
              domain: 'sendingmedia.com',
            },
            {
              name: 'pgEdge',
              domain: 'pgedge.com',
            },
            {
              name: 'Tembo',
              domain: 'temboeducationgroup.com',
            },
            {
              name: 'Right-Hand Cybersecurity',
              domain: 'right-hand.ai',
            },
            {
              name: 'Lightmatter',
              domain: 'lightmatter.co',
            },
            {
              name: 'HILOS',
              domain: 'hilos.co',
            },
            {
              name: 'Sojo',
              domain: 'mysojo.co',
            },
            {
              name: 'Sec3',
              domain: 'sec.gov',
            },
            {
              name: 'SimpleRose',
              domain: 'simplerose.com',
            },
            {
              name: 'Glassdome',
              domain: 'glassdomes.com',
            },
            {
              name: 'Regie.ai',
              domain: 'regie.ai',
            },
            {
              name: 'Fixie',
              domain: 'fixieconsulting.com',
            },
            {
              name: 'Cubist',
              domain: 'cubist.eu',
            },
            {
              name: 'FLINTpro',
              domain: 'flintpro.com',
            },
            {
              name: 'YellowBird',
              domain: 'yellowbirdsdonthavewingsbuttheyflytomakeyouexperiencea3dreality.com',
            },
            {
              name: 'Sonet.io',
              domain: 'sonet.io',
            },
            {
              name: 'Affine',
              domain: 'affine.pro',
            },
            {
              name: 'Reka AI',
              domain: null,
            },
            {
              name: 'B GARAGE',
              domain: 'bgarage.ai',
            },
            {
              name: 'Descope',
              domain: 'descope.com',
            },
            {
              name: 'RightRev',
              domain: 'rightrev.com',
            },
            {
              name: 'Lexion',
              domain: 'lexion.ai',
            },
            {
              name: 'Lazarus',
              domain: 'lazarusclm.es',
            },
            {
              name: 'Transcend Software',
              domain: 'transcendsoftware.net',
            },
            {
              name: 'Vellum',
              domain: 'vellum.ink',
            },
            {
              name: 'Bonusly',
              domain: 'bonus.ly',
            },
            {
              name: 'Wherobots Inc.',
              domain: 'wherobots.ai',
            },
            {
              name: 'SideChef',
              domain: 'sidechef.com',
            },
            {
              name: 'Config',
              domain: 'config.fr',
            },
            {
              name: 'ESpace Networks',
              domain: 'espacenetworks.io',
            },
            {
              name: 'reef.ai',
              domain: null,
            },
            {
              name: 'Webb Protocol',
              domain: null,
            },
            {
              name: 'Istari',
              domain: 'istari-global.com',
            },
            {
              name: 'BlockJoy',
              domain: 'blockjoy.com',
            },
            {
              name: 'Sentio',
              domain: 'sentiommg.com',
            },
            {
              name: 'Revolear',
              domain: 'revolear.com',
            },
            {
              name: 'Renew',
              domain: 'renewedvision.com',
            },
            {
              name: 'Tavus',
              domain: 'tavus.io',
            },
            {
              name: 'Apprentice.io',
              domain: 'apprentice.io',
            },
            {
              name: 'Fly.io',
              domain: 'fly.io',
            },
            {
              name: 'Beehive AI',
              domain: 'beehive.ai',
            },
            {
              name: 'Instabase',
              domain: 'instabase.com',
            },
            {
              name: 'Jetpack',
              domain: 'jetpack.com',
            },
            {
              name: 'Digs',
              domain: 'digsshowroom.com',
            },
            {
              name: 'Strider',
              domain: 'strider.tech',
            },
            {
              name: 'Hudu',
              domain: 'usehudu.com',
            },
            {
              name: 'Zed Industries',
              domain: 'zedindustries.com',
            },
            {
              name: 'BaseCap Analytics',
              domain: 'basecapanalytics.com',
            },
            {
              name: 'Viirtue',
              domain: 'viirtue.com',
            },
            {
              name: 'Liquid AI',
              domain: 'liquidaider.com',
            },
            {
              name: 'Syntax Health',
              domain: null,
            },
            {
              name: 'Planera',
              domain: 'planerallianz.de',
            },
            {
              name: 'Opscura',
              domain: 'opscura.io',
            },
            {
              name: 'Infinant',
              domain: 'infinant.com',
            },
            {
              name: 'Plus One Robotics',
              domain: 'plusonerobotics.com',
            },
            {
              name: 'Heard Technologies',
              domain: null,
            },
            {
              name: 'Together',
              domain: 'together.fund',
            },
            {
              name: 'Bluesky',
              domain: 'whatisbluesky.com',
            },
            {
              name: 'unspun',
              domain: 'unspun.agency',
            },
            {
              name: 'Source',
              domain: 'source.ag',
            },
            {
              name: 'Phantom AI',
              domain: 'phantom.ai',
            },
            {
              name: 'Topkey',
              domain: 'topkey.io',
            },
            {
              name: 'Presidio Medical',
              domain: 'presidiomedical.com',
            },
            {
              name: 'LeapXpert',
              domain: 'leap.expert',
            },
            {
              name: 'Ushur',
              domain: 'ushur.com',
            },
            {
              name: 'Tso Chinese Takeout & Delivery',
              domain: 'tsodelivery.com',
            },
            {
              name: 'LineSlip Solutions',
              domain: 'lineslipsolutions.com',
            },
            {
              name: 'Measurabl',
              domain: 'measurabl.com',
            },
            {
              name: 'Chroma',
              domain: 'chromacolors.com',
            },
            {
              name: 'System Initiative',
              domain: 'systeminit.com',
            },
            {
              name: 'AptEdge',
              domain: 'aptedge.io',
            },
            {
              name: 'Fuse Oncology',
              domain: null,
            },
            {
              name: 'Fleetio',
              domain: 'fleetio.com',
            },
            {
              name: 'Honey Homes',
              domain: 'honeyhomes.com',
            },
            {
              name: 'Canoe',
              domain: 'en.canoe.com',
            },
            {
              name: 'martini.ai',
              domain: 'martini.ai',
            },
            {
              name: 'UtilizeCore',
              domain: 'utilizecore.com',
            },
            {
              name: 'Steg AI',
              domain: 'steg.ai',
            },
            {
              name: 'TiiCKER',
              domain: 'tiicker.com',
            },
            {
              name: 'Granica',
              domain: 'granica.io',
            },
            {
              name: 'Union.ai',
              domain: 'union.ai',
            },
            {
              name: 'BentoML',
              domain: 'bentoml.com',
            },
            {
              name: 'The Believer Company',
              domain: 'believer.gg',
            },
            {
              name: 'SpotHopper',
              domain: 'spothopperapp.com',
            },
            {
              name: 'Switchboard Software',
              domain: 'switchboard-software.com',
            },
            {
              name: 'Kognitos',
              domain: 'kognitos.com',
            },
            {
              name: 'Lightup',
              domain: 'lightup.io',
            },
            {
              name: 'Adonis',
              domain: 'adonis-groupe.com',
            },
            {
              name: 'Navix',
              domain: 'navix.fr',
            },
            {
              name: 'Synco',
              domain: 'syncoaudio.com',
            },
            {
              name: 'Streamdal',
              domain: 'streamdal.com',
            },
            {
              name: 'Impact Observatory',
              domain: 'impactobservatory.com',
            },
            {
              name: 'SPIRITT',
              domain: 'spiritt.io',
            },
            {
              name: 'Vantage',
              domain: 'gotvantage.com',
            },
            {
              name: 'Odyssey',
              domain: 'odysseycs.com',
            },
            {
              name: 'Vue Storefront',
              domain: 'vuestorefront.io',
            },
            {
              name: 'LlamaIndex',
              domain: null,
            },
            {
              name: 'Ox',
              domain: 'ox.work',
            },
            {
              name: 'Hawkin Dynamics',
              domain: 'hawkindynamics.com',
            },
            {
              name: 'Vital Interaction',
              domain: 'vitalinteraction.com',
            },
            {
              name: 'Hyro',
              domain: 'hyrodahero.com',
            },
            {
              name: 'FedML',
              domain: 'fedml.ai',
            },
            {
              name: 'Pietra',
              domain: 'pietrastudio.com',
            },
            {
              name: 'Fixie.ai',
              domain: 'fixie.ai',
            },
            {
              name: 'Dasera',
              domain: 'dasera.com',
            },
            {
              name: 'Vouched',
              domain: 'vouched.id',
            },
            {
              name: 'Thinking Engines',
              domain: null,
            },
            {
              name: 'Rinsed',
              domain: 'rinsed.net',
            },
            {
              name: 'Ditto',
              domain: 'dittotranscripts.com',
            },
            {
              name: 'Therma',
              domain: 'therma.com',
            },
            {
              name: 'Carbon Robotics',
              domain: 'carbon.ai',
            },
            {
              name: 'Outbound AI',
              domain: 'outbound.ai',
            },
            {
              name: 'Language I/O',
              domain: 'languageio.com',
            },
            {
              name: 'Acryl Data',
              domain: 'acryldata.io',
            },
            {
              name: 'Oso',
              domain: 'osohq.com',
            },
            {
              name: 'Trullion',
              domain: 'trullion.com',
            },
            {
              name: 'Moderne',
              domain: 'moderne.st',
            },
            {
              name: 'Thena',
              domain: 'thena.app',
            },
            {
              name: 'Mnemonic',
              domain: 'mnemonic.ai',
            },
            {
              name: 'ProjectDiscovery',
              domain: 'projectdiscovery.com',
            },
            {
              name: 'Gappify',
              domain: 'gappify.com',
            },
            {
              name: 'Light Field Lab',
              domain: 'lightfieldlab.com',
            },
            {
              name: 'Judy Security',
              domain: null,
            },
            {
              name: 'Onehouse',
              domain: 'onehouse.ai',
            },
            {
              name: 'LocalizeOS',
              domain: 'localizeos.com',
            },
            {
              name: 'Galvanick',
              domain: 'galvanick.com',
            },
            {
              name: 'Inbox Health',
              domain: 'inboxhealth.com',
            },
            {
              name: 'Exum Instruments',
              domain: 'exuminstruments.com',
            },
            {
              name: 'Motionworks',
              domain: 'motionworks.com.my',
            },
            {
              name: 'Flagship',
              domain: 'flagshipservicesgroup.com',
            },
            {
              name: 'GreenPlaces',
              domain: 'greenplaces.ch',
            },
            {
              name: 'Chaos Labs',
              domain: 'wikihuman.org',
            },
            {
              name: 'Zerocater',
              domain: 'zerocater.com',
            },
            {
              name: 'Humanly.io',
              domain: 'humanly.io',
            },
            {
              name: 'nZero',
              domain: 'nzero.com',
            },
            {
              name: 'Spiral',
              domain: 'spiralbinding.com',
            },
            {
              name: 'Essential AI',
              domain: 'essentialaids.com',
            },
            {
              name: 'Venn',
              domain: 'venn.city',
            },
            {
              name: 'NobleAI',
              domain: 'noble.ai',
            },
            {
              name: 'Speakeasy',
              domain: 'speakeasyapi.dev',
            },
            {
              name: 'Revelstoke',
              domain: 'seerevelstoke.com',
            },
            {
              name: 'Ethernovia',
              domain: 'ethernovia.com',
            },
            {
              name: 'Gan.ai',
              domain: 'gan.ai',
            },
            {
              name: 'Waterplan',
              domain: 'waterplan.com',
            },
            {
              name: 'Recurrency',
              domain: 'recurrency.us',
            },
            {
              name: 'Incentivio',
              domain: 'incentivio.com',
            },
            {
              name: 'Levitate',
              domain: 'levitatemedia.com',
            },
            {
              name: 'Ostrich Cyber-Risk',
              domain: 'ostrichcyber-risk.com',
            },
            {
              name: 'AvoMD',
              domain: 'avomd.io',
            },
            {
              name: 'Hawkin Dynamics',
              domain: 'hawkindynamics.com',
            },
            {
              name: 'Anvil',
              domain: 'anvilcorp.com',
            },
            {
              name: 'RangeForce',
              domain: 'rangeforce.com',
            },
            {
              name: 'Illumix',
              domain: 'illumix.com',
            },
            {
              name: 'Britive',
              domain: 'britive.com',
            },
            {
              name: 'Colossyan',
              domain: 'colossyan.com',
            },
            {
              name: 'Laudio',
              domain: 'laudio.com',
            },
            {
              name: 'Entitle',
              domain: 'entitlebooks.com',
            },
            {
              name: 'Logik.io',
              domain: 'logik.io',
            },
            {
              name: 'Orb',
              domain: 'thisisorb.com',
            },
            {
              name: 'Cybersyn',
              domain: 'cybersynergy.net',
            },
            {
              name: 'BlueCargo',
              domain: 'bluecargo.io',
            },
            {
              name: 'HealthSnap',
              domain: 'healthsnap.io',
            },
            {
              name: 'Rezolve AI',
              domain: 'rezolve.ai',
            },
            {
              name: 'Robust.AI',
              domain: 'robust.ai',
            },
            {
              name: 'Refuel.AI',
              domain: 'refuel.ai',
            },
            {
              name: 'Zenarate',
              domain: 'zenarate.com',
            },
            {
              name: 'Kaito',
              domain: 'kaitorishouten-co.jp',
            },
            {
              name: 'Fero Labs',
              domain: 'ferolabs.com',
            },
            {
              name: 'NetBox Labs',
              domain: 'netboxlabs.com',
            },
            {
              name: 'RevenueHero',
              domain: 'revenuehero.io',
            },
            {
              name: 'Elevate',
              domain: 'goelevate.com',
            },
            {
              name: 'Salsa',
              domain: 'getsalsa.com',
            },
            {
              name: 'Capsule',
              domain: 'capsulecrm.com',
            },
            {
              name: 'Jump',
              domain: 'jumplibrary.com',
            },
            {
              name: 'Trust Lab',
              domain: 'trustlab.com',
            },
            {
              name: 'Parrot',
              domain: 'prrt.co',
            },
            {
              name: 'Alvys',
              domain: 'alvys.com',
            },
            {
              name: 'Opus',
              domain: 'opuscompany.com',
            },
            {
              name: 'Gridware',
              domain: 'gridware.com.au',
            },
            {
              name: 'Florence',
              domain: 'florence.co.uk',
            },
            {
              name: 'Portside',
              domain: 'portside.co',
            },
            {
              name: 'Fileread AI',
              domain: null,
            },
            {
              name: 'Triumph',
              domain: 'triumphtransport.com',
            },
            {
              name: 'Macro',
              domain: '0xmacro.com',
            },
            {
              name: 'Utopia Labs',
              domain: 'utopialabs.co',
            },
            {
              name: 'uTu',
              domain: 'utu.ai',
            },
            {
              name: 'Careerist',
              domain: 'careerist.com',
            },
            {
              name: 'Rally',
              domain: 'rallyuxr.com',
            },
            {
              name: 'dope.security',
              domain: 'dope.security',
            },
            {
              name: 'RxLightning',
              domain: 'rxlightning.com',
            },
            {
              name: 'Votiro',
              domain: 'votiro.com',
            },
            {
              name: 'Cardinality.ai',
              domain: 'cardinality.ai',
            },
            {
              name: 'Lantern',
              domain: 'golantern.com',
            },
            {
              name: 'Stacklok',
              domain: null,
            },
            {
              name: 'Kanarys',
              domain: 'kanarys.com',
            },
            {
              name: 'NinjaTech AI',
              domain: null,
            },
            {
              name: 'Numbers Station',
              domain: 'numbersstation.ai',
            },
            {
              name: 'Growfin',
              domain: 'growfin.ai',
            },
            {
              name: 'PermitFlow',
              domain: 'permitflow.com',
            },
            {
              name: 'RevSure.AI',
              domain: 'revsure.ai',
            },
            {
              name: 'BeeKeeperAI',
              domain: 'beekeeperai.com',
            },
            {
              name: 'ChargerHelp!',
              domain: 'chargerhelp.com',
            },
            {
              name: 'BetterNight',
              domain: 'ninjasleep.com',
            },
            {
              name: 'Hawkin Dynamics',
              domain: 'hawkindynamics.com',
            },
            {
              name: 'Hippo Video',
              domain: 'hippovideo.io',
            },
            {
              name: 'Rivet Work',
              domain: 'rivet.work',
            },
            {
              name: 'Albert Invent',
              domain: 'albertinvent.com',
            },
            {
              name: 'nue.io',
              domain: 'nue.io',
            },
            {
              name: 'Automotus',
              domain: 'automotus.co',
            },
            {
              name: 'aiXplain',
              domain: 'aixplain.com',
            },
            {
              name: 'Passthrough',
              domain: 'passthrough.com',
            },
            {
              name: 'GLIDER.ai',
              domain: 'glider.ai',
            },
            {
              name: 'Asset-Map',
              domain: 'asset-map.store',
            },
            {
              name: 'Strivacity',
              domain: 'strivacity.com',
            },
            {
              name: 'Markaaz',
              domain: 'markaaz.com',
            },
            {
              name: 'Savant Labs',
              domain: 'savantlabs.io',
            },
            {
              name: 'Hakimo',
              domain: 'hakimo.ai',
            },
            {
              name: 'Standard Fleet',
              domain: 'standardfleet.com',
            },
          ],
        },
        {
          name: 'Fortune 500 companies',
          items: [
            {
              name: 'Tesla',
              domain: 'tesla.com',
            },
            {
              name: 'NVIDIA',
              domain: 'nvidia.com',
            },
            {
              name: 'Meta',
              domain: 'meta.org',
            },
            {
              name: 'Apple',
              domain: 'apple.com',
            },
            {
              name: 'Citi',
              domain: 'citi.org.za',
            },
            {
              name: 'Amazon',
              domain: 'aboutamazon.com',
            },
            {
              name: 'PayPal',
              domain: 'paypal.com',
            },
            {
              name: 'Intel',
              domain: 'Intel.com',
            },
            {
              name: 'Microsoft',
              domain: 'microsoft.com',
            },
            {
              name: 'Comcast',
              domain: 'comcast.com',
            },
            {
              name: 'Pfizer',
              domain: 'pfizer.com',
            },
            {
              name: 'Netflix',
              domain: 'netflixanimation.com',
            },
            {
              name: 'Berkshire Hathaway',
              domain: 'berkshirehathaway.com',
            },
            {
              name: 'Thermo Fisher Scientific',
              domain: 'thermofisher.com',
            },
            {
              name: 'BlackRock',
              domain: 'blackrock.com',
            },
            {
              name: 'Verizon',
              domain: 'buydoj.com',
            },
            {
              name: 'Goldman Sachs',
              domain: 'goldmansachs.com',
            },
            {
              name: 'eBay',
              domain: 'ebayinc.com',
            },
            {
              name: 'Starbucks',
              domain: 'starbucks.com',
            },
            {
              name: 'HP',
              domain: 'hpobchod.sk',
            },
            {
              name: 'Capital One',
              domain: 'capitalone.com',
            },
            {
              name: 'Humana',
              domain: 'humanagroup.com',
            },
            {
              name: 'Wayfair',
              domain: 'wayfair.co.uk',
            },
            {
              name: 'Walmart',
              domain: 'walmart.com',
            },
            {
              name: 'Bank of America',
              domain: 'bankofamerica.com',
            },
            {
              name: 'Activision Blizzard',
              domain: 'activisionblizzard.com',
            },
            {
              name: 'Bristol Myers Squibb',
              domain: 'bms-staging.com',
            },
            {
              name: 'Henry Schein',
              domain: 'henryschein.com',
            },
            {
              name: 'Cisco',
              domain: 'cisco.com',
            },
            {
              name: 'LabCorp',
              domain: 'labcorp.com',
            },
            {
              name: 'Walgreens Boots Alliance',
              domain: 'walgreensbootsalliance.com',
            },
            {
              name: 'Jacobs',
              domain: 'jacobs.com',
            },
            {
              name: 'Xerox',
              domain: 'xerox.com',
            },
            {
              name: 'Amgen',
              domain: 'amgen.com',
            },
            {
              name: 'The Boeing Company',
              domain: 'boeing.com',
            },
            {
              name: 'Mosaic',
              domain: 'mosaiccorp.com',
            },
            {
              name: 'Hanesbrands',
              domain: 'hanesbrands.com',
            },
            {
              name: 'Sysco',
              domain: 'sysco.ch',
            },
            {
              name: 'Harley-Davidson',
              domain: 'harley-davidson.com',
            },
            {
              name: 'Regions Bank',
              domain: 'regions.com',
            },
            {
              name: 'WestRock',
              domain: 'westrock.com',
            },
            {
              name: 'Arrow Electronics',
              domain: 'arrow.com',
            },
            {
              name: 'Avnet',
              domain: 'avnet.com',
            },
            {
              name: 'L3 Harris Technologies',
              domain: 'harris.com',
            },
            {
              name: 'Molson Coors',
              domain: 'molsoncoors.com',
            },
            {
              name: 'Ryder',
              domain: 'ryder.global',
            },
            {
              name: 'Halliburton',
              domain: 'halliburton.com',
            },
            {
              name: 'Applied Materials',
              domain: 'appliedmaterials.com',
            },
            {
              name: 'Cintas',
              domain: 'cintas.com',
            },
            {
              name: 'Western Union',
              domain: 'westernunion.com',
            },
            {
              name: 'Waste Management',
              domain: 'wm.com',
            },
            {
              name: 'KeyBank',
              domain: 'key.com',
            },
            {
              name: 'U.S. Bancorp',
              domain: null,
            },
            {
              name: 'Merck',
              domain: 'merck.de',
            },
            {
              name: 'Kroger',
              domain: 'thekrogerco.com',
            },
            {
              name: 'NCR',
              domain: 'ncr.com',
            },
            {
              name: 'Boston Scientific',
              domain: 'bostonscientific.com',
            },
            {
              name: 'Booz Allen Hamilton',
              domain: 'boozallen.com',
            },
            {
              name: 'Occidental Petroleum',
              domain: 'oxy.com',
            },
            {
              name: 'Regeneron',
              domain: 'regeneron.ie',
            },
            {
              name: 'Live Nation Entertainment',
              domain: 'livenationentertainment.com',
            },
            {
              name: 'ONEOK',
              domain: 'oneok.com',
            },
            {
              name: 'Voya Financial',
              domain: 'voya.com',
            },
            {
              name: 'PACCAR',
              domain: 'paccar.com',
            },
            {
              name: 'Energy Transfer Partners',
              domain: 'energytransfer.com',
            },
            {
              name: 'IntercontinentalExchange',
              domain: 'theice.com',
            },
            {
              name: 'Dish Network',
              domain: 'dishwireless.com',
            },
            {
              name: 'Citizens Bank',
              domain: 'citizensbank.com',
            },
            {
              name: 'Icahn Enterprises',
              domain: 'ielp.com',
            },
            {
              name: 'Fluor',
              domain: 'fluor.com',
            },
            {
              name: 'IQVIA',
              domain: 'iqvia.com',
            },
            {
              name: 'Bed Bath & Beyond',
              domain: 'bedbathandbeyond.com',
            },
            {
              name: 'Publix',
              domain: 'publix.com',
            },
            {
              name: 'Texas Instruments',
              domain: 'ti.com',
            },
            {
              name: 'Becton Dickinson India',
              domain: null,
            },
            {
              name: 'CenterPoint Energy',
              domain: 'centerpointenergy.com',
            },
            {
              name: 'Morgan Stanley',
              domain: 'morganstanley.com',
            },
            {
              name: 'Philip Morris International',
              domain: 'pmi.com',
            },
            {
              name: 'American Airlines',
              domain: 'aa.com',
            },
            {
              name: 'Estée Lauder',
              domain: 'esteelauder.com',
            },
            {
              name: 'General Electric',
              domain: 'generalelectriccompany.co.uk',
            },
            {
              name: 'Aflac',
              domain: 'aflac.com',
            },
            {
              name: 'Coty',
              domain: 'coty.com',
            },
            {
              name: 'NRG Energy',
              domain: 'nrg.com',
            },
            {
              name: 'New York Life Insurance',
              domain: 'newyorklifecolorado.com',
            },
            {
              name: 'State Street Corporation',
              domain: null,
            },
            {
              name: 'News Corp',
              domain: 'newscorp.com',
            },
            {
              name: 'Leidos holdings',
              domain: 'leidos.com',
            },
            {
              name: 'Hertz',
              domain: 'hertz.com',
            },
            {
              name: 'GameStop',
              domain: 'gamestop.com',
            },
            {
              name: 'AbbVie',
              domain: 'abbvie.com',
            },
            {
              name: 'Kinder Morgan',
              domain: 'kindermorgan.com',
            },
            {
              name: 'Mastercard',
              domain: 'mastercard.com',
            },
            {
              name: 'Campbell Soup Company',
              domain: 'campbellsoupcompany.com',
            },
            {
              name: 'IBM',
              domain: 'ibm.com',
            },
            {
              name: 'BNY Mellon',
              domain: 'bnymellon.com',
            },
            {
              name: 'Kohl’s',
              domain: 'kohls.com',
            },
            {
              name: 'Sherwin-Williams',
              domain: 'snapyourcolors.com',
            },
            {
              name: 'AECOM',
              domain: 'aecom.com',
            },
            {
              name: 'Parker Hannifin',
              domain: 'parker.com',
            },
            {
              name: 'Phillips 66',
              domain: 'phillips66.com',
            },
            {
              name: 'Conagra Brands',
              domain: 'conagrabrands.com',
            },
            {
              name: 'Farmers Insurance Group',
              domain: 'farmers.com',
            },
            {
              name: 'JetBlue Airways',
              domain: 'jetblue.com',
            },
            {
              name: 'Foot Locker',
              domain: 'footlocker-inc.com',
            },
            {
              name: 'Jabil',
              domain: 'jabil.com',
            },
            {
              name: 'Prudential Financial',
              domain: 'prudential.com',
            },
            {
              name: 'United Parcel Service',
              domain: null,
            },
            {
              name: 'Republic Services',
              domain: 'republicservices.com',
            },
            {
              name: 'Altice USA',
              domain: 'alticeusa.com',
            },
            {
              name: 'Western Digital',
              domain: 'westerndigital.com',
            },
            {
              name: 'Ford Motor',
              domain: 'ford.com',
            },
            {
              name: 'Northrop Grumman',
              domain: 'northropgrumman.com',
            },
            {
              name: 'Nextera Energy',
              domain: 'nexteraenergy.com',
            },
            {
              name: 'Freddie Mac',
              domain: 'freddiemac.com',
            },
            {
              name: 'Alphabet',
              domain: 'abc.xyz',
            },
            {
              name: 'Dollar General',
              domain: 'dollargeneral.com',
            },
            {
              name: 'Cognizant',
              domain: 'cognizant.com',
            },
            {
              name: 'Williams',
              domain: 'co.williams.com',
            },
            {
              name: 'CarMax',
              domain: 'carmax.com',
            },
            {
              name: "Macy's",
              domain: 'macys.com',
            },
            {
              name: 'Eli Lilly',
              domain: 'lilly.com',
            },
            {
              name: 'JP Morgan Chase',
              domain: 'jpmorganchase.com',
            },
            {
              name: 'Lowe’s',
              domain: 'lowes.com',
            },
            {
              name: 'Visa',
              domain: 'visa.com',
            },
            {
              name: 'Stanley Black & Decker',
              domain: 'stanleyblackanddecker.com',
            },
            {
              name: 'Ralph Lauren',
              domain: 'ralphlauren.com',
            },
            {
              name: 'Wells Fargo',
              domain: 'wellsfargojobs.com',
            },
            {
              name: 'Corning',
              domain: 'corning.com',
            },
            {
              name: 'Kraft Heinz',
              domain: 'kraftheinzcompany.com',
            },
            {
              name: 'Procter & Gamble Company',
              domain: 'us.pg.com',
            },
            {
              name: 'Adobe',
              domain: 'abodecorp.com',
            },
            {
              name: 'Oracle',
              domain: 'oraclecloud.com',
            },
            {
              name: 'Nordstrom',
              domain: 'nordstrom.com',
            },
            {
              name: 'Ally Financial',
              domain: 'ally.com',
            },
            {
              name: 'Fidelity National Financial',
              domain: 'fnf.com',
            },
            {
              name: 'Textron',
              domain: 'textron.com',
            },
            {
              name: 'Motorola Solutions',
              domain: 'motorolasolutions.com',
            },
            {
              name: 'Newell Brands',
              domain: 'newellbrands.com',
            },
            {
              name: 'Whirlpool',
              domain: 'whirlpoolcorp.com',
            },
            {
              name: 'Oshkosh',
              domain: 'oshkoshcorp.com',
            },
            {
              name: 'Frontier Communications',
              domain: 'frontier.com',
            },
            {
              name: 'Beacon Roofing Supply',
              domain: 'beaconroofingsupply.com',
            },
            {
              name: 'iHeartMedia',
              domain: 'iheartmedia.com',
            },
            {
              name: 'S&P Global',
              domain: 'spglobal.cn',
            },
            {
              name: 'AmerisourceBergen',
              domain: 'amerisourcebergenphaservices.com',
            },
            {
              name: 'PVH',
              domain: 'pvh.com',
            },
            {
              name: 'Owens Corning',
              domain: 'owenscorning.com',
            },
            {
              name: 'Zimmer Biomet',
              domain: 'zimmerbiomet.com',
            },
            {
              name: 'Arconic',
              domain: 'arconic.com',
            },
            {
              name: 'Lumen Technologies',
              domain: 'lumentechnologies.com',
            },
            {
              name: 'XPO Logistics',
              domain: 'xpocorporate.com',
            },
            {
              name: 'AES',
              domain: 'aes-corp.com',
            },
            {
              name: 'Charter Communications',
              domain: 'charter.com',
            },
            {
              name: 'ManpowerGroup',
              domain: 'manpowergroup.com',
            },
            {
              name: 'United Rentals',
              domain: 'unitedrentals.com',
            },
            {
              name: 'Alliance Data Systems',
              domain: 'alliancedatasystems.com',
            },
            {
              name: 'American Electric Power',
              domain: 'aep.com',
            },
            {
              name: 'Celgene',
              domain: 'celgene.com',
            },
            {
              name: 'Advance Auto Parts',
              domain: 'corp.advanceautoparts.com',
            },
            {
              name: 'The Home Depot',
              domain: 'homedepot.com',
            },
            {
              name: "Dillard's",
              domain: 'dillards.com',
            },
            {
              name: 'International Paper',
              domain: 'internationalpaper.com',
            },
            {
              name: "O'Reilly",
              domain: 'oreilly.com',
            },
            {
              name: 'Valero Energy',
              domain: 'valeroenergy.me',
            },
            {
              name: 'Tech Data',
              domain: 'techdata.ca',
            },
            {
              name: 'Steel Dynamics',
              domain: 'steeldynamics.com',
            },
            {
              name: 'Hormel Foods',
              domain: 'hormelfoods.com',
            },
            {
              name: 'TreeHouse Foods',
              domain: 'treehousefoods.com',
            },
            {
              name: 'American International Group',
              domain: 'aig.com',
            },
            {
              name: 'AGCO',
              domain: 'agcocorp.com',
            },
            {
              name: 'Lear Corporation',
              domain: null,
            },
            {
              name: 'Universal Health Services',
              domain: 'uhsinc.com',
            },
            {
              name: 'Franklin Resources',
              domain: 'franklinresources.com',
            },
            {
              name: 'Salesforce',
              domain: 'salesforce.com',
            },
            {
              name: 'First American',
              domain: 'firstam.com',
            },
            {
              name: 'AutoZone',
              domain: 'autozone.co.za',
            },
            {
              name: 'Enterprise Products Partners',
              domain: 'enterpriseproducts.com',
            },
            {
              name: 'DTE Energy',
              domain: 'dteenergy.com',
            },
            {
              name: 'Westlake Chemical Corporation',
              domain: null,
            },
            {
              name: 'BorgWarner',
              domain: 'borgwarner.com',
            },
            {
              name: 'Thrivent',
              domain: 'thrivent.com',
            },
            {
              name: 'Hilton Worldwide',
              domain: 'hilton.com',
            },
            {
              name: 'Caesars Entertainment',
              domain: 'caesars.com',
            },
            {
              name: 'Spirit Aerosystems',
              domain: 'spiritaero.com',
            },
            {
              name: 'Crown Holdings',
              domain: 'crowncork.com',
            },
            {
              name: 'Micron Technology',
              domain: 'automatic-vendingmachines.com',
            },
            {
              name: 'Assurant',
              domain: 'assurant.com',
            },
            {
              name: 'Rite Aid',
              domain: 'riteaid.com',
            },
            {
              name: 'Robert Half International',
              domain: 'roberthalf.com',
            },
            {
              name: 'Performance Food Group',
              domain: 'pfgc.com',
            },
            {
              name: 'General Dynamics',
              domain: 'gd.com',
            },
            {
              name: 'Exelon Corporation',
              domain: null,
            },
            {
              name: 'American Family Insurance',
              domain: 'amfam.com',
            },
            {
              name: 'National Oilwell Varco',
              domain: 'nov.com',
            },
            {
              name: 'Las Vegas Sands',
              domain: 'sands.com',
            },
            {
              name: 'C.H. Robinson',
              domain: 'chrobinson.com',
            },
            {
              name: 'Weyerhaeuser',
              domain: 'weyerhaeuser.com',
            },
            {
              name: 'CHS (Community Health Systems)',
              domain: null,
            },
            {
              name: 'Archer Daniels Midland Company',
              domain: 'adm.com',
            },
            {
              name: 'NVR',
              domain: 'nvrinc.com',
            },
            {
              name: 'Omnicom Group',
              domain: 'omnicomgroup.com',
            },
            {
              name: 'DaVita',
              domain: 'davita.com',
            },
            {
              name: 'Wynn Resorts',
              domain: 'wynnresorts.com',
            },
            {
              name: 'Avery Dennison',
              domain: 'averydennison.com',
            },
            {
              name: 'CBRE Group',
              domain: 'cbre.com',
            },
            {
              name: 'Southern Company',
              domain: 'southerncompany.com',
            },
            {
              name: 'Simon Property Group',
              domain: 'simonpropertygroup.com',
            },
            {
              name: 'Edward Jones',
              domain: 'edwardjones.ca',
            },
            {
              name: 'Celanese',
              domain: 'celanese.com',
            },
            {
              name: 'General Motors',
              domain: 'gm.com',
            },
            {
              name: 'Colgate Palmolive',
              domain: 'colgatepalmolive.com',
            },
            {
              name: 'JCPenney',
              domain: 'jcpenney.com',
            },
            {
              name: 'Autoliv',
              domain: 'autoliv.com',
            },
            {
              name: 'Blackstone Group',
              domain: 'blackstone.com',
            },
            {
              name: 'Cummins',
              domain: 'cummins.com',
            },
            {
              name: 'Windstream',
              domain: 'windstream.com',
            },
            {
              name: 'Jefferies Financial Group',
              domain: 'jefferies.com',
            },
            {
              name: 'The Walt Disney Company',
              domain: 'disneycareers.com',
            },
            {
              name: 'PBF Energy',
              domain: 'pbfenergy.com',
            },
            {
              name: 'J.B. Hunt Transport',
              domain: 'jbhunt.com',
            },
            {
              name: 'Toll Brothers',
              domain: 'tollbrothers.com',
            },
            {
              name: 'Tractor Supply Company',
              domain: 'tractorsupply.com',
            },
            {
              name: 'Air Products and Chemicals',
              domain: 'airproducts.com',
            },
            {
              name: 'VIACOM PARAMOUNT on contract at CBS PARAMOUNT+',
              domain: null,
            },
            {
              name: 'Travelers Insurance',
              domain: 'travelers.com',
            },
            {
              name: 'Freeport Mcmoran',
              domain: 'fcx.com',
            },
            {
              name: 'Devon Energy Corporation',
              domain: null,
            },
            {
              name: 'Union Pacific Corporation',
              domain: null,
            },
            {
              name: 'SYNNEX',
              domain: 'synnex.com',
            },
            {
              name: 'Sempra Energy',
              domain: 'sempra.com',
            },
            {
              name: 'Mohawk Industries',
              domain: 'mohawkind.com',
            },
            {
              name: 'Sonic Automotive',
              domain: 'sonicautomotive.com',
            },
            {
              name: 'Unum Group',
              domain: 'unum.com',
            },
            {
              name: 'The TJX Companies',
              domain: 'tjx.com',
            },
            {
              name: 'Nucor Corporation',
              domain: null,
            },
            {
              name: 'StoneX',
              domain: 'stonex.it',
            },
            {
              name: 'Quanta Services',
              domain: 'quantaservices.com',
            },
            {
              name: 'Office Depot',
              domain: 'officedepot.com',
            },
            {
              name: 'W.W. Grainger',
              domain: null,
            },
            {
              name: 'Edison International',
              domain: 'edison.com',
            },
            {
              name: 'Onsemi Intelligent Technology',
              domain: null,
            },
            {
              name: '21st Century Fox',
              domain: '21cf.com',
            },
            {
              name: 'Darden Restaurants',
              domain: 'darden.com',
            },
            {
              name: 'Genuine Parts Company',
              domain: 'genpt.com',
            },
            {
              name: 'MassMutual',
              domain: 'massmutual.com',
            },
            {
              name: 'ABM Industries',
              domain: 'abm.com',
            },
            {
              name: 'Sanmina Corporation',
              domain: null,
            },
            {
              name: 'Alcoa',
              domain: 'alcoa.com',
            },
            {
              name: 'Avis Budget Group',
              domain: 'avisbudgetgroup.com',
            },
            {
              name: 'VF',
              domain: 'vfc.com',
            },
            {
              name: 'Berry Global Inc.',
              domain: 'berryglobal.com',
            },
            {
              name: 'First Data Corporation',
              domain: null,
            },
            {
              name: 'Ameriprise Financial',
              domain: 'ameriprise.com',
            },
            {
              name: 'RR Donnelley & Sons',
              domain: 'rrdonnelley.com',
            },
            {
              name: 'Charles Schwab Corporation',
              domain: null,
            },
            {
              name: 'CVS Health',
              domain: 'cvs.co',
            },
            {
              name: 'Baxter International',
              domain: 'baxter.com',
            },
            {
              name: 'Xcel Energy',
              domain: 'xcelenergy.com',
            },
            {
              name: 'Dover',
              domain: 'dover.com',
            },
            {
              name: 'Navient Corporation',
              domain: null,
            },
            {
              name: 'Univar',
              domain: 'univar.com',
            },
            {
              name: 'Ingredion Incorporated',
              domain: 'ingredion.com',
            },
            {
              name: 'Emcor Group',
              domain: 'emcorgroup.com',
            },
            {
              name: 'Packaging Corporation of America',
              domain: 'packagingcorp.com',
            },
            {
              name: 'L brands',
              domain: 'lb.com',
            },
            {
              name: 'Magellan Health Services',
              domain: 'magellanhealth.com',
            },
            {
              name: 'Anadarko Petroleum Corporation',
              domain: null,
            },
            {
              name: 'Pacific Life',
              domain: 'pacificlife.com',
            },
            {
              name: 'W. R. Berkley',
              domain: 'wrberkley.es',
            },
            {
              name: 'Nationwide Mutual Insurance',
              domain: null,
            },
            {
              name: 'Ball Corporation',
              domain: null,
            },
            {
              name: 'Eastman Chemical Company',
              domain: 'therminol.com',
            },
            {
              name: 'Principal Financial Group',
              domain: 'principal.com',
            },
            {
              name: 'US Foods',
              domain: 'usfoods.com',
            },
            {
              name: 'GenWorth',
              domain: 'genworth.com',
            },
            {
              name: 'Liberty Media',
              domain: 'libertymedia.com',
            },
            {
              name: 'Qualcomm',
              domain: 'qualcomm.com',
            },
            {
              name: 'Builders FirstSource',
              domain: 'bldr.com',
            },
            {
              name: 'Molina Healthcare',
              domain: 'molinahealthcare.com',
            },
            {
              name: 'Lennar Corporation',
              domain: null,
            },
            {
              name: 'Cheniere Energy Inc',
              domain: null,
            },
            {
              name: 'HCA',
              domain: 'hcahealthcare.com',
            },
            {
              name: 'Jones Lang LaSalle',
              domain: 'jll.com',
            },
            {
              name: 'Chesapeake Energy',
              domain: 'chk.com',
            },
            {
              name: 'Lithia Motors',
              domain: 'lithia.com',
            },
            {
              name: 'AT&T',
              domain: 'att.com',
            },
            {
              name: 'Burlington Stores',
              domain: 'burlingtoninvestors.com',
            },
            {
              name: 'Alaska Air Group',
              domain: 'alaskaair.com',
            },
            {
              name: 'Group 1 Automotive',
              domain: 'group1auto.com',
            },
            {
              name: 'Dr Pepper Snapple Group',
              domain: 'drpeppersnapplegroup.com',
            },
            {
              name: 'American Financial Group',
              domain: 'afginc.com',
            },
            {
              name: 'WESCO International',
              domain: 'wesco.us.com',
            },
            {
              name: 'Kiewit Corporation',
              domain: null,
            },
            {
              name: 'LKQ Corporation',
              domain: null,
            },
            {
              name: 'Hess Corporation',
              domain: null,
            },
            {
              name: 'Polaris Industries',
              domain: 'polaris.com',
            },
            {
              name: 'Markel Corporation',
              domain: null,
            },
            {
              name: 'Biogen Idec',
              domain: 'biogen.com',
            },
            {
              name: 'MGM Resorts International',
              domain: 'mgmresorts.com',
            },
            {
              name: 'Target',
              domain: 'targetcorporation.com',
            },
            {
              name: 'Pioneer Natural Resources',
              domain: 'pxd.com',
            },
            {
              name: 'Gilead Sciences',
              domain: 'gilead.com',
            },
            {
              name: 'The Hershey Company',
              domain: 'hersheyland.com',
            },
            {
              name: 'Huntsman',
              domain: 'huntsman.com',
            },
            {
              name: 'Realogy Holdings',
              domain: 'realogy.com',
            },
            {
              name: 'Duke Energy Corporation',
              domain: null,
            },
            {
              name: 'Dana Holding Corporation',
              domain: null,
            },
            {
              name: 'Honeywell',
              domain: 'honeywellbuildings.in',
            },
            {
              name: 'Gap',
              domain: 'gapinc.com',
            },
            {
              name: 'ADP',
              domain: 'americandevelopmentpartners.com',
            },
            {
              name: 'Fortive',
              domain: 'fortive.com',
            },
            {
              name: 'Tenneco',
              domain: 'tenneco.com',
            },
            {
              name: 'Emerson',
              domain: 'emerson.com',
            },
            {
              name: 'United States Steel',
              domain: 'ussteel.com',
            },
            {
              name: 'CDW Corporation',
              domain: null,
            },
            {
              name: 'World Kinect Corporation',
              domain: null,
            },
            {
              name: 'FirstEnergy Corp.',
              domain: 'firstenergycorp.com',
            },
            {
              name: 'SpartanNash',
              domain: 'spartannash.com',
            },
            {
              name: 'TravelCenters of America',
              domain: 'ta-petro.com',
            },
            {
              name: 'Marsh & McLennan Companies',
              domain: 'mmc.com',
            },
            {
              name: 'Anixter International',
              domain: 'anixter.com',
            },
            {
              name: 'Graphic Packaging',
              domain: 'graphicpkg.com',
            },
            {
              name: 'Navistar',
              domain: 'navistarsatx.com',
            },
            {
              name: 'EOG Resources',
              domain: 'eogresources.com',
            },
            {
              name: "BJ's Wholesale Club",
              domain: 'bjs.com',
            },
            {
              name: "Casey's General Stores",
              domain: null,
            },
            {
              name: 'Huntington Ingalls Industries',
              domain: 'huntingtoningalls.com',
            },
            {
              name: 'Owens & Minor',
              domain: 'owens-minor.com',
            },
            {
              name: 'Tenet Healthcare Corporation',
              domain: null,
            },
            {
              name: 'Mastec',
              domain: 'mastec.com',
            },
            {
              name: 'M&T Bank',
              domain: 'mtb.com',
            },
            {
              name: 'Asbury Automotive Group',
              domain: 'asburyauto.com',
            },
            {
              name: 'Kimberly-Clark Corporation',
              domain: null,
            },
            {
              name: 'Yum! China',
              domain: 'yumchina.com',
            },
            {
              name: 'O-I Glass',
              domain: 'o-i.com',
            },
            {
              name: 'EnLink Midstream',
              domain: 'enlink.com',
            },
            {
              name: 'PepsiCo',
              domain: 'pepsico.com',
            },
            {
              name: 'Progressive Insurance',
              domain: 'progressive.com',
            },
            {
              name: 'American Express',
              domain: 'americanexpress.com',
            },
            {
              name: 'Southwest Airlines',
              domain: 'southwest.com',
            },
            {
              name: 'Nike',
              domain: 'nike.com',
            },
            {
              name: 'Supervalu',
              domain: 'supervalu.com',
            },
            {
              name: 'WEC Energy Group',
              domain: 'wecenergygroup.com',
            },
            {
              name: 'Marathon Oil Corporation',
              domain: null,
            },
            {
              name: 'Albertsons',
              domain: 'albertsons.com',
            },
            {
              name: 'Brighthouse Financial',
              domain: 'brighthousefinancial.com',
            },
            {
              name: 'Illinois Tool Works',
              domain: 'itw.com',
            },
            {
              name: 'Ascena Retail Group',
              domain: 'ascenaretail.com',
            },
            {
              name: 'Expeditors International',
              domain: 'expeditors.com',
            },
            {
              name: 'Erie Insurance Group',
              domain: 'erieinsurance.com',
            },
            {
              name: 'UGI Corporation',
              domain: null,
            },
            {
              name: 'Dean Foods',
              domain: 'deanfoods.com',
            },
            {
              name: 'SunTrust Bank',
              domain: 'suntrust.com',
            },
            {
              name: 'Mutual Of Omaha',
              domain: 'mutualofomaha.com',
            },
            {
              name: 'HD Supply Holdings',
              domain: 'hdsupply.com',
            },
            {
              name: 'Public Service Enterprise Group (PSEG)',
              domain: null,
            },
            {
              name: 'Ameren Services',
              domain: 'ameren.com',
            },
            {
              name: 'Global Partners',
              domain: 'globalp.com',
            },
            {
              name: 'Norfolk Southern Corporation',
              domain: null,
            },
            {
              name: 'Core-Mark International',
              domain: null,
            },
            {
              name: 'Broadcom',
              domain: 'broadcom.com',
            },
            {
              name: 'Chevron',
              domain: 'chevron.com',
            },
            {
              name: 'Entergy Corporation',
              domain: null,
            },
            {
              name: 'Fiserv',
              domain: 'fiserv.com',
            },
            {
              name: 'Johnson & Johnson',
              domain: 'jnj.com',
            },
            {
              name: 'Calpine',
              domain: 'calpine.com',
            },
            {
              name: 'Reinsurance Group Of America',
              domain: 'rgare.com',
            },
            {
              name: 'Alleghany Corporation',
              domain: null,
            },
            {
              name: 'Insight',
              domain: 'insight-sas.net',
            },
            {
              name: 'PG&E Corporation',
              domain: null,
            },
            {
              name: 'Lockheed Martin',
              domain: 'lmco.com',
            },
            {
              name: 'Masco Corporation',
              domain: null,
            },
            {
              name: 'Caterpillar',
              domain: 'caterpillar.com',
            },
            {
              name: 'NGL Energy Partners',
              domain: 'nglenergypartners.com',
            },
            {
              name: 'Williams-Sonoma',
              domain: 'williamssonoma.com',
            },
            {
              name: 'Intuit',
              domain: 'intuit.com',
            },
            {
              name: '3M',
              domain: '3mdeutschland.de',
            },
            {
              name: 'Collins Aerospace',
              domain: 'rockwellcollins.com',
            },
            {
              name: 'Targa Resources Partners',
              domain: 'targaresources.com',
            },
            {
              name: 'PNC',
              domain: 'pnconline.com',
            },
            {
              name: 'Costco',
              domain: 'costco.com',
            },
            {
              name: 'United Technologies',
              domain: 'united.com.hk',
            },
            {
              name: 'FedEx',
              domain: 'fedex.com',
            },
            {
              name: 'ExxonMobil',
              domain: 'exxonmobil.com',
            },
            {
              name: 'UnitedHealth Group',
              domain: 'unitedhealthgroup.com',
            },
            {
              name: 'Rockwell Automation',
              domain: 'rockwellautomation.dk',
            },
            {
              name: 'Abbott',
              domain: 'abbott.com',
            },
            {
              name: 'United Natural Foods',
              domain: 'unfi.com',
            },
            {
              name: 'Thor Industries',
              domain: 'thorindustries.com',
            },
            {
              name: 'Newmont Goldcorp',
              domain: 'newmontgoldcorp.com',
            },
            {
              name: 'PPG Industries',
              domain: 'ppg.com',
            },
            {
              name: 'Peabody Energy',
              domain: 'peabodyenergy.com',
            },
            {
              name: 'Graybar',
              domain: 'graybar.com',
            },
            {
              name: 'Best Buy',
              domain: 'bestbuy.com',
            },
            {
              name: 'CMS Energy Corporation',
              domain: null,
            },
            {
              name: 'American Axle & Manufacturing',
              domain: 'aam.com',
            },
            {
              name: 'NetApp',
              domain: 'netapp.com',
            },
            {
              name: 'Post Holdings',
              domain: 'postholdings.com',
            },
            {
              name: 'Tyson Foods',
              domain: 'tysonfoods.com',
            },
            {
              name: 'Apex Site Management',
              domain: null,
            },
            {
              name: 'Amphenol',
              domain: 'amphenol.com',
            },
            {
              name: 'Auto-Owners',
              domain: 'auto-owners.com',
            },
            {
              name: 'Qurate Retail',
              domain: 'libertyinteractive.com',
            },
            {
              name: 'Reliance Steel & Aluminum Co',
              domain: 'rsac.com',
            },
            {
              name: 'WellCare Health Plans',
              domain: 'wellcare.com',
            },
            {
              name: 'L3 Technologies',
              domain: 'l3tjobs.com',
            },
            {
              name: 'Raytheon',
              domain: 'raytheon.com',
            },
            {
              name: 'John Deere',
              domain: 'deere.com',
            },
            {
              name: 'Synchrony',
              domain: 'synchrony.com',
            },
            {
              name: 'ConocoPhillips',
              domain: 'conocophillips.com',
            },
            {
              name: 'Dell Technologies Capital',
              domain: 'delltechnologiescapital.com',
            },
            {
              name: 'Cigna',
              domain: 'cigna.com',
            },
            {
              name: 'AK Steel',
              domain: 'aksteel.com',
            },
            {
              name: 'USAA',
              domain: 'usaa.com',
            },
            {
              name: 'Dominion Energy Technologies',
              domain: 'dom.com',
            },
            {
              name: 'J. M. Smucker',
              domain: 'jmsmucker.com',
            },
            {
              name: 'Apache',
              domain: 'apache.co.uk',
            },
            {
              name: 'Coca-Cola Enterprises',
              domain: null,
            },
            {
              name: 'Murphy USA',
              domain: 'corporate.murphyusa.com',
            },
            {
              name: 'BB&T',
              domain: 'bbtheatres.com',
            },
            {
              name: 'Vistra Energy',
              domain: 'vistraenergy.com',
            },
            {
              name: 'Pulte Group',
              domain: 'pultegroupinc.com',
            },
            {
              name: 'CBS Entertainment',
              domain: null,
            },
            {
              name: 'DCP Midstream',
              domain: 'dcpmidstream.com',
            },
            {
              name: 'Fifth Third Bank',
              domain: '53.com',
            },
            {
              name: 'Warner Bros. Discovery',
              domain: 'discovery-italia.it',
            },
            {
              name: 'Dollar Tree',
              domain: 'dollartree.com',
            },
            {
              name: 'Fannie Mae',
              domain: 'fanniemae.com',
            },
            {
              name: 'DXC Technology',
              domain: 'dxc.technology',
            },
            {
              name: 'Danaher',
              domain: 'danaher.com',
            },
            {
              name: 'Centene',
              domain: 'centene.com',
            },
            {
              name: 'Delta Air Lines',
              domain: 'delta.com',
            },
            {
              name: 'A-Mark Precious Metals',
              domain: 'amark.com',
            },
            {
              name: 'Goodyear Rubber Products',
              domain: 'goodyearrubberproducts.com',
            },
            {
              name: 'Hewlett Packard Enterprise',
              domain: 'hpe.com',
            },
            {
              name: 'Discover',
              domain: 'dis-cover.jp',
            },
            {
              name: 'Raymond James',
              domain: 'midatlanticsecurities.com',
            },
            {
              name: 'Elevance Health',
              domain: 'elevancehealth.com',
            },
            {
              name: 'State Farm',
              domain: 'statefarm.com',
            },
            {
              name: 'Stryker',
              domain: 'stryker.com',
            },
            {
              name: 'Penske Automotive Group',
              domain: 'penskeautomotive.com',
            },
            {
              name: "Dick's Sporting Goods",
              domain: 'dickssportinggoods.jobs',
            },
            {
              name: "Kellogg's",
              domain: 'kelloggs.com',
            },
            {
              name: 'Veritiv',
              domain: 'bulkleydunton.com',
            },
            {
              name: 'Levi Strauss & Co.',
              domain: 'negativespacealphabet.com',
            },
            {
              name: 'Lam Research',
              domain: 'lamresearch.com',
            },
            {
              name: 'Booking Holdings',
              domain: 'bookingholdings.com',
            },
            {
              name: 'HollyFrontier Corporation',
              domain: null,
            },
            {
              name: 'Analog Devices',
              domain: 'analog.com',
            },
            {
              name: 'Interpublic Group',
              domain: 'interpublic.com',
            },
            {
              name: 'Old Republic International Corporation',
              domain: null,
            },
            {
              name: 'Dupont',
              domain: 'dupont.com',
            },
            {
              name: 'Community Health Network',
              domain: 'ecommunity.com',
            },
            {
              name: 'Loews Corporation',
              domain: null,
            },
            {
              name: 'Zoetis India Ltd',
              domain: null,
            },
            {
              name: 'United Continental Holdings',
              domain: 'hub.united.com',
            },
            {
              name: 'OLIN',
              domain: 'theolinstudio.com',
            },
            {
              name: 'Tapestry',
              domain: 'tapestrycompliance.com',
            },
            {
              name: 'Seaboard Marine',
              domain: 'sbmar.com',
            },
            {
              name: 'Goodyear Rubber Products',
              domain: 'goodyearrubberproducts.com',
            },
            {
              name: 'Allstate Life Insurance',
              domain: null,
            },
            {
              name: 'Western & Southern Open',
              domain: 'cincytennis.com',
            },
            {
              name: 'PPL',
              domain: 'progressivepowerlab.org',
            },
            {
              name: "Land O'Lakes International Development",
              domain: null,
            },
            {
              name: 'Advanced Micro Solutions',
              domain: '1099-etc.com',
            },
            {
              name: 'Plains Group Holdings',
              domain: ' L.P.',
            },
            {
              name: 'Clorox Professional',
              domain: 'cloroxprofessional.com',
            },
            {
              name: 'Chemours’ Clean & Disinfect business',
              domain: null,
            },
            {
              name: 'Genworth Financial - Term Life New Business',
              domain: null,
            },
            {
              name: 'Lincoln National Investments',
              domain: null,
            },
            {
              name: 'General Mills',
              domain: 'generalmills.com',
            },
            {
              name: 'Northwestern Mutual',
              domain: 'northwesternmutual-foundation.com',
            },
            {
              name: 'Marriott International',
              domain: 'marriott.com',
            },
            {
              name: 'Yum!',
              domain: 'yum.com',
            },
            {
              name: 'Constellation Brands',
              domain: 'cbrands.com',
            },
            {
              name: 'Marathon Petroleum',
              domain: 'marathonpetroleum.com',
            },
            {
              name: 'Cardinal Health',
              domain: 'cardinal.com',
            },
            {
              name: 'Ross Stores',
              domain: 'rossstores.com',
            },
            {
              name: 'Northern Trust',
              domain: 'northerntrust.com',
            },
            {
              name: 'Consolidation Group',
              domain: 'consolidationcorp.com',
            },
            {
              name: 'Expedia',
              domain: 'expediainc.com',
            },
            {
              name: 'Hartford Financial Products International acquired by Catalina Holdings',
              domain: null,
            },
            {
              name: 'Guardian Life Insurance Company - Dental Service Organization acquired by Western Dental Services',
              domain: null,
            },
            {
              name: 'Ulta Beauty',
              domain: 'ulta.com',
            },
            {
              name: "McDonald's",
              domain: 'mcdonalds.com',
            },
            {
              name: 'Liberty Mutual Insurance',
              domain: 'libertymutual.com',
            },
            {
              name: 'AutoNation',
              domain: 'autonation.com',
            },
            {
              name: 'Quest Diagnostics',
              domain: 'questdiagnostics.com',
            },
            {
              name: 'Ecolab',
              domain: 'ecolab.com',
            },
            {
              name: 'McKesson',
              domain: 'mckesson.com',
            },
            {
              name: 'Altria',
              domain: 'altria.com',
            },
            {
              name: 'MetLife',
              domain: 'metlife.com',
            },
            {
              name: 'D.R. Horton',
              domain: 'drhortoninsurance.com',
            },
            {
              name: 'TIAA',
              domain: 'retireinequality.com',
            },
            {
              name: 'Aramark',
              domain: 'aramark.com',
            },
            {
              name: 'CSX',
              domain: 'csx.com',
            },
            {
              name: 'Mondelez International',
              domain: 'mondelezinternational.com',
            },
          ],
        },
        {
          name: 'Enterprise Financial Services',
          items: [
            {
              name: 'JPMorgan Chase & Co.',
              domain: 'jobsandpaychecks.com',
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Morgan Stanley',
              domain: 'morganstanley.com',
            },
            {
              name: 'Bank of America Corporation',
              domain: null,
            },
            {
              name: 'Citigroup Inc.',
              domain: 'citigroup.com',
            },
            {
              name: 'Wells Fargo & Co.',
              domain: 'wellsfargo.com',
            },
            {
              name: 'HSBC Holdings plc',
              domain: 'hsbc.com.sg',
            },
            {
              name: 'Deutsche Bank AG',
              domain: null,
            },
            {
              name: 'Barclays PLC',
              domain: 'barclays.co.uk',
            },
            {
              name: 'UBS Group AG',
              domain: null,
            },
            {
              name: 'Credit Suisse Group AG',
              domain: null,
            },
            {
              name: 'BNP Paribas SA',
              domain: 'bnpparibasbank.ru',
            },
            {
              name: 'Société Générale Group',
              domain: null,
            },
            {
              name: 'Royal Bank of Canada (RBC)',
              domain: null,
            },
            {
              name: 'TD Bank Group',
              domain: null,
            },
            {
              name: 'Scotiabank',
              domain: 'scotiabank.com',
            },
            {
              name: 'BMO Financial Group',
              domain: null,
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Capital One Financial Corporation',
              domain: null,
            },
            {
              name: 'American Express Company',
              domain: null,
            },
            {
              name: 'Discover Financial Services',
              domain: 'discover.com',
            },
            {
              name: 'Mastercard Incorporated',
              domain: null,
            },
            {
              name: 'Visa Inc.',
              domain: 'usa.visa.com',
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Intuit Inc.',
              domain: 'intuit.com',
            },
            {
              name: 'Charles Schwab Corporation',
              domain: null,
            },
            {
              name: 'Fidelity Investments',
              domain: 'fidelityinvestments.ie',
            },
            {
              name: 'The Vanguard Group',
              domain: 'vanguardsells.com',
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'State Street Corporation',
              domain: null,
            },
            {
              name: 'Northern Trust Corporation',
              domain: null,
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'AIG (American International Group',
              domain: ' Inc.)',
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Manulife Financial Corporation',
              domain: null,
            },
            {
              name: 'Sun Life Financial Inc.',
              domain: 'sunlife.com',
            },
            {
              name: 'Allianz SE',
              domain: 'allianz.es',
            },
            {
              name: 'Zurich Insurance Group',
              domain: 'zurich.com',
            },
            {
              name: 'AXA Group',
              domain: 'axa.com',
            },
            {
              name: 'ING Group',
              domain: 'ing.com',
            },
            {
              name: 'Nationwide Mutual Insurance Company',
              domain: null,
            },
            {
              name: 'New York Life Insurance Company',
              domain: 'newyorklifecareers.com',
            },
            {
              name: 'Standard Chartered PLC',
              domain: null,
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'BBVA (Banco Bilbao Vizcaya Argentaria)',
              domain: null,
            },
            {
              name: 'Santander Group',
              domain: null,
            },
            {
              name: 'Danske Bank',
              domain: 'danskebank.com',
            },
            {
              name: 'Swedbank AB',
              domain: 'swedbank.se',
            },
            {
              name: 'U.S. Bancorp',
              domain: null,
            },
            {
              name: 'Regions Financial Corporation',
              domain: null,
            },
            {
              name: 'KeyCorp',
              domain: 'key.com',
            },
            {
              name: 'Huntington Bancshares Incorporated',
              domain: null,
            },
            {
              name: 'Ally Financial Inc.',
              domain: 'ally.com',
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Lincoln National Corporation',
              domain: null,
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Chubb Limited',
              domain: null,
            },
            {
              name: 'Cigna Corporation',
              domain: null,
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Humana Inc.',
              domain: 'humana.com',
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Willis Towers Watson',
              domain: 'willistowerswatson.com',
            },
            {
              name: 'Aon plc',
              domain: 'aon.com',
            },
            {
              name: 'S&P Global Inc.',
              domain: 'spglobal.cn',
            },
            {
              name: "Moody's Corporation",
              domain: null,
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Intercontinental Exchange',
              domain: 'theice.com',
            },
            {
              name: 'MarketAxess Holdings Inc.',
              domain: null,
            },
            {
              name: 'FactSet Research Systems Inc.',
              domain: null,
            },
            {
              name: 'MSCI Inc.',
              domain: 'msci.com',
            },
            {
              name: 'Western Union Company',
              domain: null,
            },
            {
              name: 'First Data Corporation',
              domain: null,
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Synchrony Financial',
              domain: 'synchronyfinancial.com',
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Comerica Incorporated',
              domain: 'comerica.com',
            },
            {
              name: 'SVB Financial Group',
              domain: null,
            },
            {
              name: 'American International Group',
              domain: 'aig.com',
            },
            {
              name: 'Progressive Corporation',
              domain: null,
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'AFLAC Incorporated',
              domain: 'aflacgroupinsurance.com',
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Unum Group',
              domain: 'unum.com',
            },
            {
              name: 'Arthur J. Gallagher & Co.',
              domain: null,
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Jones Lang LaSalle Incorporated',
              domain: null,
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'OneMain Holdings',
              domain: ' Inc.',
            },
            {
              name: 'Equifax Inc.',
              domain: 'equifax.com',
            },
            {
              name: 'Experian plc',
              domain: 'experianplc.com',
            },
            {
              name: 'TransUnion',
              domain: 'transunion.com',
            },
            {
              name: 'Sallie Mae',
              domain: 'salliemae.com',
            },
            {
              name: 'Navient Corporation',
              domain: null,
            },
            {
              name: 'Santander Consumer USA Holdings Inc.',
              domain: null,
            },
          ],
        },
        {
          name: 'YC Alum (Last 2 years)',
          items: [
            {
              name: 'Cyble',
              domain: 'cyble.io',
            },
            {
              name: 'Rippling',
              domain: 'rippling.com',
            },
            {
              name: 'TRACTIAN',
              domain: 'tractian.com',
            },
            {
              name: 'Jerry',
              domain: 'myjerry.co',
            },
            {
              name: 'MindsDB',
              domain: 'mindsdb.com',
            },
            {
              name: 'Karat Financial',
              domain: 'trykarat.com',
            },
            {
              name: 'Jasper',
              domain: 'jaspercard.com',
            },
            {
              name: 'Speak',
              domain: 'usespeak.com',
            },
            {
              name: 'Replit',
              domain: 'repl.it',
            },
            {
              name: 'Vanta',
              domain: 'vanta.com',
            },
            {
              name: 'Daybreak Health',
              domain: 'daybreakhealth.com',
            },
            {
              name: 'DynamoFL',
              domain: 'dynamofl.com',
            },
            {
              name: 'SiPhox Health',
              domain: 'siphoxhealth.com',
            },
            {
              name: 'Groww',
              domain: 'groww.in',
            },
            {
              name: 'Collectly',
              domain: 'collectly.co',
            },
            {
              name: 'Topline Pro',
              domain: 'tlpromo.com',
            },
            {
              name: 'Instawork',
              domain: 'instawork.com',
            },
            {
              name: 'Verifiable',
              domain: 'verifiable.com',
            },
            {
              name: 'Prolific',
              domain: 'prolific.co',
            },
            {
              name: 'Zip',
              domain: 'zip.co',
            },
            {
              name: 'Rootly',
              domain: 'rootly.com',
            },
            {
              name: 'Hudu',
              domain: 'usehudu.com',
            },
            {
              name: 'Twine',
              domain: 'twine.fm',
            },
            {
              name: 'GIMO',
              domain: 'linkkawi.com',
            },
            {
              name: 'InfluxData',
              domain: 'influxdata.com',
            },
            {
              name: 'TRM Labs',
              domain: 'trmlabs.com',
            },
            {
              name: 'Middleware',
              domain: 'share.creditcard',
            },
            {
              name: 'RADAR',
              domain: 'radartalentsearch.com',
            },
            {
              name: 'VenoStent',
              domain: 'venostent.com',
            },
            {
              name: 'Joy',
              domain: 'withjoy.com',
            },
            {
              name: 'Aspire',
              domain: 'aspire.is',
            },
            {
              name: 'Level',
              domain: 'checkthelevel.com',
            },
            {
              name: 'Shef',
              domain: 'shef.com',
            },
            {
              name: 'Remedial Health',
              domain: 'remedialhealthcare.in',
            },
            {
              name: 'Ivy',
              domain: 'ivygmbh.com',
            },
            {
              name: 'Credal.ai',
              domain: 'credal.ai',
            },
            {
              name: 'Starling Medical',
              domain: 'starlingmedical.com',
            },
            {
              name: 'Flower',
              domain: 'flowercompany.com',
            },
            {
              name: 'SmartHelio',
              domain: 'smarthelio.com',
            },
            {
              name: 'Whalesync',
              domain: 'whalesync.com',
            },
            {
              name: 'GuruHotel',
              domain: 'guruhotel.com',
            },
            {
              name: 'Bitstack',
              domain: 'bitstackd.com',
            },
            {
              name: 'Deepgram',
              domain: 'deepgram.com',
            },
            {
              name: 'Odeko',
              domain: 'odeko.com',
            },
            {
              name: 'QuickNode',
              domain: 'quicknode.com',
            },
            {
              name: 'celeri',
              domain: 'celeritastransporte.com',
            },
            {
              name: 'Shimmer',
              domain: 'shimmer.network',
            },
            {
              name: 'Almond',
              domain: 'almonds.ai',
            },
            {
              name: 'Omnistrate',
              domain: 'omnistrate.com',
            },
            {
              name: 'Kombo',
              domain: 'kombo.co',
            },
            {
              name: 'NuMind',
              domain: 'numind.ai',
            },
            {
              name: 'Yuma',
              domain: 'yumaregional.org',
            },
            {
              name: 'EzDubs',
              domain: 'ezdubs.ai',
            },
            {
              name: 'Blacktop Hoops',
              domain: 'blacktophoopsvr.com',
            },
            {
              name: 'Shape',
              domain: 'goshape.io',
            },
            {
              name: 'Legion Health',
              domain: 'legionhealth.co.za',
            },
            {
              name: 'Nuvocargo',
              domain: 'nuvocargo.com',
            },
            {
              name: 'Flo Recruit',
              domain: 'florecruit.com',
            },
            {
              name: 'Bright',
              domain: 'brightlive.ch',
            },
            {
              name: 'Persist AI',
              domain: null,
            },
            {
              name: 'Treasury Prime',
              domain: 'treasuryprime.com',
            },
            {
              name: 'Frubana',
              domain: 'frubana.com',
            },
            {
              name: 'OneSignal',
              domain: 'onesignal.com',
            },
            {
              name: 'Cortex',
              domain: 'wearecortex.com',
            },
            {
              name: 'Rose Rocket',
              domain: 'roserocket.com',
            },
            {
              name: 'Parallel Bio',
              domain: 'parallel.bio',
            },
            {
              name: 'Truewind',
              domain: 'djtruewind.com',
            },
            {
              name: 'Oneistox',
              domain: 'oneistox.in',
            },
            {
              name: 'Stack',
              domain: 'stackworks.com',
            },
            {
              name: 'Oven',
              domain: 'ovenspot.com',
            },
            {
              name: 'inBuild',
              domain: 'inbuildingcellular.com',
            },
            {
              name: 'Scanbase',
              domain: 'scanbase.com',
            },
            {
              name: 'Chima',
              domain: 'e-chima.com',
            },
            {
              name: 'Numida',
              domain: 'numida.com',
            },
            {
              name: 'Datasaur',
              domain: 'datasaur.ai',
            },
            {
              name: 'Wyndly',
              domain: 'wyndly.com',
            },
            {
              name: 'Requestly',
              domain: 'requestly.co',
            },
            {
              name: 'Pigeon',
              domain: 'studiopigeon.com',
            },
            {
              name: 'Harmonic Discovery',
              domain: 'harmonicdiscovery.com',
            },
            {
              name: 'Motion',
              domain: 'motioncorp.com.mx',
            },
            {
              name: 'Spline',
              domain: 'spline.design',
            },
            {
              name: 'Yassir',
              domain: 'yassir.com',
            },
            {
              name: 'Taktile',
              domain: 'taktile.com',
            },
            {
              name: 'Reshape Biotech',
              domain: 'reshapebiotech.com',
            },
            {
              name: 'Defog',
              domain: 'defog.in',
            },
            {
              name: 'Vendah',
              domain: 'vendah.com.br',
            },
            {
              name: 'SilkChart',
              domain: 'silkchart.com',
            },
            {
              name: 'Volantio',
              domain: 'volantio.com',
            },
            {
              name: 'Dashworks',
              domain: 'dashworks.ai',
            },
            {
              name: 'BillionToOne',
              domain: 'billiontoone.com',
            },
            {
              name: 'Kapital',
              domain: 'kapital.jp',
            },
            {
              name: 'Lokal',
              domain: 'getlokalapp.com',
            },
            {
              name: 'Milio',
              domain: 'milio.io',
            },
            {
              name: 'Trébol',
              domain: 'gotrebol.com',
            },
            {
              name: 'Canary Technologies',
              domain: 'canarytechnologies.com',
            },
            {
              name: 'AiFlow',
              domain: 'aiflow.ltd',
            },
            {
              name: 'Persana AI',
              domain: 'persana.ai',
            },
            {
              name: 'Resend',
              domain: 'resend.com',
            },
            {
              name: 'Reflex',
              domain: 'reflextdc.com',
            },
            {
              name: 'Ontop',
              domain: 'ontopstudios.com',
            },
            {
              name: 'Bluedot',
              domain: 'blue-dot.blog',
            },
            {
              name: 'GroMo',
              domain: 'gromo.in',
            },
            {
              name: 'Zeitview',
              domain: 'zeitview.com',
            },
            {
              name: 'MarketForce',
              domain: 'marketforce.com',
            },
            {
              name: 'Decentro',
              domain: 'decentro.tech',
            },
            {
              name: 'Curri',
              domain: 'curri.com',
            },
            {
              name: 'Vellum',
              domain: 'vellum.ink',
            },
            {
              name: 'Oxygen',
              domain: 'oxygen.us',
            },
            {
              name: 'IcePanel',
              domain: 'icepanel.io',
            },
            {
              name: 'Ciro',
              domain: 'ciro3d.com',
            },
            {
              name: 'Needl',
              domain: 'needl.co',
            },
            {
              name: 'AlphaWatch AI',
              domain: null,
            },
            {
              name: 'Landeed',
              domain: 'landeed.com',
            },
            {
              name: 'Sematic',
              domain: 'sematic.com',
            },
            {
              name: 'Meticulous',
              domain: 'meticulousltd.co.uk',
            },
            {
              name: 'CodeComplete',
              domain: 'codecomplete.jp',
            },
            {
              name: 'Positional',
              domain: 'prt-i.com',
            },
            {
              name: 'Vocode',
              domain: 'vocode.dev',
            },
            {
              name: 'Devyce',
              domain: 'devyce.com',
            },
            {
              name: 'Baselit',
              domain: 'baselite.com',
            },
            {
              name: 'Rejoy Health',
              domain: 'rejoyhealth.com',
            },
            {
              name: 'Pair AI',
              domain: 'pairai.com',
            },
            {
              name: 'Buildt',
              domain: 'buildtheearth.net',
            },
            {
              name: 'TrueBiz',
              domain: 'truebiz.io',
            },
            {
              name: 'Salient',
              domain: 'salient.org.nz',
            },
            {
              name: 'Fastgen',
              domain: 'fastgencorp.com',
            },
            {
              name: 'KURUKURU',
              domain: 'kurukuru-sai.com',
            },
            {
              name: 'Latent',
              domain: 'latentapp.com',
            },
            {
              name: 'Patterns',
              domain: 'patternshiring.com',
            },
            {
              name: 'Numeral',
              domain: 'numeral.io',
            },
            {
              name: 'Vooma',
              domain: 'vooma.co.ke',
            },
            {
              name: 'Monitaur',
              domain: 'monitaur.ai',
            },
            {
              name: 'RootFi',
              domain: 'rootfiftytwo.co.uk',
            },
            {
              name: 'Lume',
              domain: 'lumevr.com',
            },
            {
              name: 'Morada Uno',
              domain: 'moradauno.com.mx',
            },
            {
              name: 'Neptyne',
              domain: 'neptyne.com',
            },
            {
              name: 'Diffuse Bio',
              domain: null,
            },
            {
              name: 'Anarchy',
              domain: 'warmusic.net',
            },
            {
              name: 'Pirros',
              domain: 'pirros.io',
            },
            {
              name: 'Automat (Formerly Lasso)',
              domain: null,
            },
            {
              name: 'Suger',
              domain: 'suger.io',
            },
            {
              name: 'Clueso',
              domain: 'clueso.de',
            },
            {
              name: 'Tovala',
              domain: 'tovala.com',
            },
            {
              name: 'Tavus',
              domain: 'tavus.io',
            },
            {
              name: 'Frigade',
              domain: 'frigade.com',
            },
            {
              name: 'CorgiAI',
              domain: 'corgiaid.org',
            },
            {
              name: 'Apply Design',
              domain: 'apply-design.com',
            },
            {
              name: 'Apollo Group',
              domain: 'theapollogroup.co.uk',
            },
            {
              name: 'CarbonChain',
              domain: 'carbonchain.com',
            },
            {
              name: 'Topkey',
              domain: 'topkey.io',
            },
            {
              name: 'Metaplane',
              domain: 'metaplane.dev',
            },
            {
              name: 'Inscribe',
              domain: 'inscribe.com',
            },
            {
              name: 'Highlight',
              domain: 'letshighlight.com',
            },
            {
              name: 'CB Therapeutics',
              domain: 'cbthera.com',
            },
            {
              name: 'Bend',
              domain: 'bend.la',
            },
            {
              name: 'Streamdal',
              domain: 'streamdal.com',
            },
            {
              name: 'Fuell',
              domain: 'fuell.us',
            },
            {
              name: 'Helium Health',
              domain: 'heliumhealth.com',
            },
            {
              name: 'Angle Health',
              domain: 'anglehealth.com',
            },
            {
              name: 'GradientJ',
              domain: 'gradientj.com',
            },
            {
              name: 'Kyber',
              domain: 'kyberscorner.com',
            },
            {
              name: 'Pylon',
              domain: 'getpylon.com',
            },
            {
              name: 'Telmai',
              domain: 'telm.ai',
            },
            {
              name: 'Slash',
              domain: 'slashbed.com',
            },
            {
              name: 'Strive Education',
              domain: 'striveeducation.co.uk',
            },
            {
              name: 'doola',
              domain: 'doola.com',
            },
            {
              name: 'Vue Storefront',
              domain: 'vuestorefront.io',
            },
            {
              name: 'Living Carbon',
              domain: 'livingcarbon.com',
            },
            {
              name: 'Escape',
              domain: 'bisnow.com',
            },
            {
              name: 'Propify',
              domain: 'propify.es',
            },
            {
              name: 'Notebook Labs',
              domain: 'notebooklabs.xyz',
            },
            {
              name: 'Pierre',
              domain: 'woodmancastingx.com',
            },
            {
              name: 'WhiteLab Genomics',
              domain: 'whitelabgx.com',
            },
            {
              name: 'ZeroDev',
              domain: 'zero.dev',
            },
            {
              name: 'Complete',
              domain: 'webbuy.com',
            },
            {
              name: 'Solugen',
              domain: 'solugen.com',
            },
            {
              name: 'Breathe Well-being',
              domain: 'breathewellbeing.in',
            },
            {
              name: 'Memfault',
              domain: 'memfault.com',
            },
            {
              name: 'Infisical',
              domain: 'infisical.com',
            },
            {
              name: 'SINAI Technologies',
              domain: 'sinaitechnologies.com',
            },
            {
              name: 'GoJom',
              domain: 'gojom.pro',
            },
            {
              name: 'FlexDesk',
              domain: 'flexdesk.com',
            },
            {
              name: 'Pier',
              domain: 'pierup.com',
            },
            {
              name: 'LunaJoy',
              domain: 'hellolunajoy.com',
            },
            {
              name: 'Magicflow',
              domain: 'magicflow.co',
            },
            {
              name: 'Turntable',
              domain: 'turntable.fm',
            },
            {
              name: 'Cloudchipr',
              domain: 'cloudchipr.com',
            },
            {
              name: 'Zywa (YC W22)',
              domain: null,
            },
            {
              name: 'Intently',
              domain: 'intently.co',
            },
            {
              name: 'Jaldi',
              domain: 'getjaldi.com',
            },
            {
              name: 'EdenFarm Indonesia',
              domain: null,
            },
            {
              name: 'SaveIN',
              domain: 'savein.money',
            },
            {
              name: 'Obie',
              domain: 'obiecre.com',
            },
            {
              name: 'Hadrius',
              domain: 'hadrius.com',
            },
            {
              name: 'Sweetspot',
              domain: 'shipsweetspot.com',
            },
            {
              name: 'Type',
              domain: 'downloadtype.com',
            },
            {
              name: 'OneText',
              domain: 'onetext.com',
            },
            {
              name: 'Perfekto',
              domain: 'perfekto.mx',
            },
            {
              name: 'Tennr',
              domain: 'tennr.com',
            },
            {
              name: 'Outset',
              domain: 'joinoutset.com',
            },
            {
              name: 'GoodCourse',
              domain: 'goodcourse.com',
            },
            {
              name: 'C16 Biosciences',
              domain: 'c16bio.com',
            },
            {
              name: 'Segmed',
              domain: 'segmed.ai',
            },
            {
              name: 'Enode',
              domain: 'enode.com',
            },
            {
              name: 'LineLeap',
              domain: 'lineleaptickets.com',
            },
            {
              name: 'DolarApp',
              domain: 'dolarapp.dev',
            },
            {
              name: 'Zerocater',
              domain: 'zerocater.com',
            },
            {
              name: 'Netmaker',
              domain: 'netmaker.io',
            },
            {
              name: 'Portão 3',
              domain: 'portao3.com.br',
            },
            {
              name: 'Depot',
              domain: 'gries-deco-company.com',
            },
            {
              name: 'Humanly.io',
              domain: 'humanly.io',
            },
            {
              name: 'JustPaid',
              domain: 'justpaid.io',
            },
            {
              name: 'Mindset Health',
              domain: 'themindsetapp.com',
            },
            {
              name: 'Magik Labs',
              domain: 'magiklabs.io',
            },
            {
              name: 'Boostly',
              domain: 'boostly.com',
            },
            {
              name: 'Forfeit',
              domain: 'forfeiturewatch.org',
            },
            {
              name: 'Texel.ai',
              domain: 'texelair.com',
            },
            {
              name: 'clearspace',
              domain: 'clearspace.net',
            },
            {
              name: 'Speedy Brand',
              domain: 'speedybrand.io',
            },
            {
              name: 'Freshpaint',
              domain: 'freshpaint.io',
            },
            {
              name: 'Coast',
              domain: 'trycoast.com',
            },
            {
              name: 'Sherloq',
              domain: 'sherloq.app',
            },
            {
              name: 'Coperniq',
              domain: 'coperniq.io',
            },
            {
              name: 'Hammr',
              domain: 'hammr.com',
            },
            {
              name: 'Jenfi',
              domain: 'jenfi.com',
            },
            {
              name: 'Sameday',
              domain: 'sameday.com',
            },
            {
              name: 'Pando Bioscience',
              domain: null,
            },
            {
              name: 'Extend',
              domain: 'linkedin.com',
            },
            {
              name: 'ShortLoop',
              domain: 'shortloop.dev',
            },
            {
              name: 'Ochre Bio',
              domain: 'ochre-bio.com',
            },
            {
              name: 'Waza',
              domain: 'wazap.com',
            },
            {
              name: 'Alima',
              domain: 'alima.ngo',
            },
            {
              name: 'Lightski',
              domain: 'lightskinjokes.com',
            },
            {
              name: 'Fairway Health',
              domain: 'fairwayhealthcarepartners.com',
            },
            {
              name: 'Chainsight',
              domain: 'chainsight.dev',
            },
            {
              name: 'Reality Defender',
              domain: 'realitydefender.com',
            },
            {
              name: 'Nestor',
              domain: 'nestor.nl',
            },
            {
              name: 'Quantbase',
              domain: 'getquantbase.com',
            },
            {
              name: 'THEYA',
              domain: 'theyamazakihome.com',
            },
            {
              name: '1stCollab',
              domain: 'stcollab.com',
            },
            {
              name: 'Cone Software',
              domain: 'conesoftware.com',
            },
            {
              name: 'Trigo',
              domain: 'trigo-group.com',
            },
            {
              name: 'Palomma',
              domain: 'palomma.com',
            },
            {
              name: 'Waveline',
              domain: 'waveline.pro',
            },
            {
              name: 'Fintool.com',
              domain: null,
            },
            {
              name: 'Rubbrband',
              domain: 'rubbrband.com',
            },
            {
              name: 'Shyp',
              domain: 'shyp.com',
            },
            {
              name: 'Semantic Finance',
              domain: null,
            },
            {
              name: 'Firstbase.io',
              domain: 'firstbase.io',
            },
            {
              name: 'Berry',
              domain: 'berry.edu',
            },
            {
              name: 'Bloom',
              domain: 'bloom.sh',
            },
            {
              name: 'MedPiper',
              domain: 'medpiper.com',
            },
            {
              name: 'ECSA',
              domain: 'ecsa.sa.gov.au',
            },
            {
              name: 'UpTrain AI',
              domain: 'uptrain.ai',
            },
            {
              name: 'Fern',
              domain: 'fnp.com',
            },
            {
              name: 'BabylonAI',
              domain: 'babylonai.dev',
            },
            {
              name: 'PoloPay',
              domain: null,
            },
            {
              name: 'Zenfetch',
              domain: 'zenfetcher.com',
            },
            {
              name: 'Rollstack',
              domain: 'rollstack.io',
            },
            {
              name: 'Shekel Mobility',
              domain: 'shekelmobility.com',
            },
            {
              name: 'Shipmates',
              domain: 'shipmates.co',
            },
            {
              name: 'Second',
              domain: 'mysecond.com',
            },
            {
              name: 'Honeydew',
              domain: 'honeydew.nl',
            },
            {
              name: 'erad',
              domain: 'erad.com',
            },
            {
              name: 'Decoherence',
              domain: 'decoherence.co',
            },
            {
              name: 'Lancey',
              domain: 'lancey.io',
            },
            {
              name: 'Pivo',
              domain: 'pivo.fi',
            },
            {
              name: 'Narrative',
              domain: 'narrative.io',
            },
            {
              name: 'BidSight',
              domain: null,
            },
            {
              name: 'Rainmaker',
              domain: 'rainmaker.in.th',
            },
            {
              name: 'Quindar',
              domain: 'quindar.de',
            },
            {
              name: 'Spark Studio',
              domain: 'sparkstudio.pro',
            },
            {
              name: 'Avoca',
              domain: 'avoca.com',
            },
            {
              name: 'OneSchema',
              domain: 'oneschema.co',
            },
            {
              name: 'Bluebirds',
              domain: 'bluebirdstorage.ca',
            },
            {
              name: 'Invopop',
              domain: 'invopop.com',
            },
            {
              name: 'Tailfin',
              domain: 'tailfin.cloud',
            },
            {
              name: 'Outerbase',
              domain: 'outerbase.com',
            },
            {
              name: 'MagnaPlay',
              domain: null,
            },
            {
              name: 'Nango',
              domain: 'nango.dev',
            },
            {
              name: 'Martin',
              domain: 'martin.ai',
            },
            {
              name: 'Dr. Droid',
              domain: 'drdroid.io',
            },
            {
              name: 'Psychic',
              domain: 'ascotjsmith.com',
            },
            {
              name: 'Warp',
              domain: 'warp.la',
            },
            {
              name: 'Baraka',
              domain: 'barakatalan.com',
            },
            {
              name: 'Ruuf',
              domain: 'ruuf.li',
            },
            {
              name: 'Fabius',
              domain: 'fabius.co.jp',
            },
            {
              name: 'Astek Diagnostics',
              domain: null,
            },
            {
              name: 'Inri',
              domain: 'inria.fr',
            },
            {
              name: 'Snab',
              domain: 'snabi.jp',
            },
            {
              name: 'Ariglad',
              domain: 'ariglad.com',
            },
            {
              name: 'Gloo',
              domain: 'gloo.digital',
            },
            {
              name: 'Nucleus',
              domain: 'nucleus.ac',
            },
            {
              name: 'Method Financial',
              domain: 'methodfi.com',
            },
            {
              name: 'Altay Therapeutics',
              domain: null,
            },
            {
              name: 'Trigger.dev',
              domain: 'trigger.dev',
            },
            {
              name: 'Thread',
              domain: 'thread.org',
            },
            {
              name: 'Bujeti',
              domain: 'bujeti.com',
            },
            {
              name: 'PowerX',
              domain: 'lovepowerx.com',
            },
            {
              name: 'Waterplan',
              domain: 'waterplan.com',
            },
            {
              name: 'Recurrency',
              domain: 'recurrency.us',
            },
            {
              name: 'Gigs',
              domain: 'gigs.com',
            },
            {
              name: 'Keeling Labs',
              domain: 'keelinglabs.com',
            },
            {
              name: 'Backdrop (formerly Twig)',
              domain: null,
            },
            {
              name: 'Byterat',
              domain: 'byterat.io',
            },
            {
              name: 'Momentu',
              domain: 'momentumdash.com',
            },
            {
              name: 'Drift Bio',
              domain: 'driftbio.com',
            },
            {
              name: 'Iliad',
              domain: 'iliad.fr',
            },
            {
              name: 'Tailor',
              domain: 'tailoredbybigdata.com',
            },
            {
              name: 'Inito',
              domain: 'inito.com',
            },
            {
              name: 'Nourish',
              domain: 'nourishinc.com',
            },
            {
              name: 'Gullak.Money',
              domain: 'gullakmoney.com',
            },
            {
              name: 'Inflow',
              domain: 'goinflow.com',
            },
            {
              name: 'Mino Games',
              domain: 'minogames.com',
            },
            {
              name: 'Accord',
              domain: 'inaccord.com',
            },
            {
              name: 'Generally Intelligent',
              domain: 'generallyintelligent.com',
            },
            {
              name: 'SensorSurf',
              domain: 'sensorsurf.com',
            },
            {
              name: 'Ruby Card',
              domain: 'ruby.cards',
            },
            {
              name: 'Onu',
              domain: 'onu1.com',
            },
            {
              name: 'Untether Labs',
              domain: 'untetherlabs.com',
            },
            {
              name: 'OfOne',
              domain: 'ofone.pt',
            },
            {
              name: 'Mercoa',
              domain: 'mercoa.com',
            },
            {
              name: 'Orchid Exchange',
              domain: 'orchid.exchange',
            },
            {
              name: 'Future Fields',
              domain: 'futurefields.io',
            },
            {
              name: 'Gradia Health',
              domain: 'gradia.com',
            },
            {
              name: 'Roofr',
              domain: 'roofr.com',
            },
            {
              name: 'BlueCargo',
              domain: 'bluecargo.io',
            },
            {
              name: 'Shaktimaan',
              domain: null,
            },
            {
              name: 'Quazel',
              domain: null,
            },
            {
              name: 'Superb AI',
              domain: 'superb-ai.com',
            },
            {
              name: 'Humaans',
              domain: 'humaans.io',
            },
            {
              name: 'Linum',
              domain: 'linum.dk',
            },
            {
              name: 'Arroyo',
              domain: 'arroyofranco.mx',
            },
            {
              name: 'rift',
              domain: 'riftlabs.com',
            },
            {
              name: 'Finley Technologies',
              domain: 'finleycms.com',
            },
            {
              name: 'Draftwise',
              domain: 'draftwise.com',
            },
            {
              name: 'Albedo',
              domain: 'albedo.com',
            },
            {
              name: 'Triomics',
              domain: 'triomic.studio',
            },
            {
              name: 'Localyze',
              domain: 'localyze.de',
            },
            {
              name: 'Sirdab',
              domain: 'sirdab.co',
            },
            {
              name: 'Layup',
              domain: 'layup.fr',
            },
            {
              name: 'Fluid Markets',
              domain: 'fluidinvesting.com',
            },
            {
              name: '0pass',
              domain: '1password.com',
            },
            {
              name: 'Noya',
              domain: 'noya.co',
            },
            {
              name: 'Nomba',
              domain: 'nomba.one',
            },
            {
              name: 'Protex AI',
              domain: 'protex.ai',
            },
            {
              name: 'Explorex Technologies',
              domain: ' Inc.',
            },
            {
              name: 'Wild Moose',
              domain: 'wildmoose.pl',
            },
            {
              name: 'Cardinal',
              domain: 'cardinalapp.com',
            },
            {
              name: 'Double',
              domain: 'withdouble.com',
            },
            {
              name: 'Anchor',
              domain: 'anchoryou.com',
            },
            {
              name: 'Eden Care Medical',
              domain: 'edencaremedical.com',
            },
            {
              name: 'Finex',
              domain: 'finexusa.com',
            },
            {
              name: 'FoodCourt',
              domain: '360-foodcourt.com',
            },
            {
              name: 'Defer',
              domain: 'deferit.com',
            },
            {
              name: 'Roforco',
              domain: 'roforco.com',
            },
            {
              name: 'Decent',
              domain: 'decentcrypto.com',
            },
            {
              name: 'Upfront',
              domain: 'upfront-app.com',
            },
            {
              name: 'Coco',
              domain: 'cocomsp.com',
            },
            {
              name: 'Dream3D',
              domain: 'dreamdinners.com',
            },
            {
              name: 'DAGWorks',
              domain: 'dagworks.io',
            },
            {
              name: 'Procol',
              domain: 'procol.in',
            },
            {
              name: '91Squarefeet',
              domain: '91squarefeet.com',
            },
            {
              name: 'Meson',
              domain: 'meson.fi',
            },
            {
              name: 'Trackstar',
              domain: 'trackstar.ca',
            },
            {
              name: 'Concourse',
              domain: 'concourserealtypartners.com',
            },
            {
              name: 'Conduit',
              domain: 'getconduit.co',
            },
            {
              name: 'Sorted',
              domain: 'sortedlocal.com',
            },
            {
              name: 'Gridware',
              domain: 'gridware.com.au',
            },
            {
              name: 'Dill',
              domain: 'dillards.com',
            },
            {
              name: 'Lavo Life Sciences',
              domain: 'lavo.ai',
            },
            {
              name: 'CreatorML',
              domain: 'creatorml.com',
            },
            {
              name: 'OpenSight',
              domain: 'opensight.co.uk',
            },
            {
              name: 'EdgeBit',
              domain: 'edgebit.io',
            },
            {
              name: 'Lamar Health',
              domain: null,
            },
            {
              name: 'Traceloop',
              domain: null,
            },
            {
              name: 'Serial',
              domain: 'serialzone.cz',
            },
            {
              name: 'Adventris Pharmaceuticals',
              domain: null,
            },
            {
              name: 'PoplarML',
              domain: 'poplarml.com',
            },
            {
              name: 'Map3',
              domain: 'mapquest.com',
            },
            {
              name: 'pyq',
              domain: 'pyq.jp',
            },
            {
              name: 'Beek',
              domain: 'beekmovil.com',
            },
            {
              name: 'MedCrypt',
              domain: 'medcrypt.com',
            },
            {
              name: 'TableFlow',
              domain: 'tableflower.co.nz',
            },
            {
              name: 'UpCodes',
              domain: 'up.codes',
            },
            {
              name: 'Weltio',
              domain: 'weltio.com',
            },
            {
              name: 'Mirrorful',
              domain: 'mirrorful.com',
            },
            {
              name: 'Sanvivo',
              domain: 'sanvivo.eu',
            },
            {
              name: 'Craftwork',
              domain: 'craftwork.design',
            },
            {
              name: 'Clad',
              domain: 'cladwell.com',
            },
            {
              name: 'Metal',
              domain: 'metalinjection.net',
            },
            {
              name: 'Mirio',
              domain: 'mirio.info',
            },
            {
              name: 'Coldreach',
              domain: null,
            },
            {
              name: 'DailyPe',
              domain: 'dailypet.buzz',
            },
            {
              name: 'Pledge Health',
              domain: 'pledgehealth.org',
            },
            {
              name: 'LinkGrep',
              domain: 'linkgrep.com',
            },
            {
              name: 'Electric Air',
              domain: 'electricairstudios.com',
            },
            {
              name: 'Emerge Career',
              domain: 'emergecareer.com',
            },
            {
              name: 'Vaero',
              domain: 'vaerohq.com',
            },
            {
              name: 'Kidato',
              domain: 'kidato.com',
            },
            {
              name: 'coil',
              domain: 'coil.com',
            },
            {
              name: 'PhotoRoom',
              domain: 'photoroom.kz',
            },
            {
              name: 'Parabolic',
              domain: 'parabolic.io',
            },
            {
              name: 'Viddy',
              domain: 'viddy.com',
            },
            {
              name: 'Intrinsic',
              domain: 'intrinsic.eu',
            },
            {
              name: 'SPRX Technologies',
              domain: null,
            },
            {
              name: 'Orderli',
              domain: 'orderli.be',
            },
            {
              name: 'Unstatiq',
              domain: 'unstatiq.com',
            },
            {
              name: 'Simetrik',
              domain: 'simetrik.com',
            },
            {
              name: 'Eze',
              domain: 'ezewholesale.com',
            },
            {
              name: 'Poko',
              domain: 'poko.ad',
            },
            {
              name: 'Constant',
              domain: 'myconstant.com',
            },
            {
              name: 'Aleph Solutions',
              domain: null,
            },
            {
              name: 'Keyval',
              domain: 'keyvalues.com',
            },
            {
              name: 'Bloom Community',
              domain: 'bloomcommunity.com',
            },
            {
              name: 'Matano',
              domain: 'matano.dev',
            },
            {
              name: 'CodeParrot',
              domain: 'codeparrot.ai',
            },
            {
              name: 'Miracle',
              domain: 'miracle.dk',
            },
            {
              name: 'Bakool',
              domain: 'bakool.id',
            },
            {
              name: 'TorMagnus Pharmaceuticals',
              domain: null,
            },
            {
              name: 'Finto',
              domain: 'finto.ir',
            },
            {
              name: 'Gluetrail',
              domain: null,
            },
            {
              name: 'Invitris',
              domain: 'invitris.com',
            },
            {
              name: 'Storyboarder',
              domain: 'storyboarder.com',
            },
            {
              name: 'Mogara',
              domain: null,
            },
            {
              name: 'Rosettic',
              domain: 'rosettic.com',
            },
            {
              name: 'Feather',
              domain: 'feather.credit',
            },
            {
              name: 'BuildJet',
              domain: 'buildjet.com',
            },
            {
              name: 'Berilium',
              domain: 'berilium.es',
            },
            {
              name: 'Interlock',
              domain: 'interlock-design.com',
            },
            {
              name: 'Fello',
              domain: 'flyingconnected.com',
            },
            {
              name: 'Blyss',
              domain: 'blyss.co',
            },
            {
              name: 'LaunchFlow',
              domain: 'launchflows.com',
            },
            {
              name: 'Onnix.',
              domain: 'onnix.net',
            },
            {
              name: 'Vector',
              domain: 'vector.com.pl',
            },
            {
              name: 'WeReno',
              domain: 'werenotreallystrangers.com',
            },
            {
              name: 'Slauth.io',
              domain: 'slauth.io',
            },
            {
              name: 'Careerist',
              domain: 'careerist.com',
            },
            {
              name: 'TigerEye',
              domain: 'tigereye.media',
            },
            {
              name: 'Replo',
              domain: 'replo.com',
            },
            {
              name: 'Copilot',
              domain: 'copilot.com',
            },
            {
              name: 'Vendease',
              domain: 'vendease.com',
            },
            {
              name: 'Zingbus',
              domain: 'zingbus.com',
            },
            {
              name: 'Sandbox Banking',
              domain: 'sandboxbanking.com',
            },
            {
              name: 'Play.ht',
              domain: 'play.ht',
            },
            {
              name: 'PermitFlow',
              domain: 'permitflow.com',
            },
            {
              name: 'Squack',
              domain: 'squackle.com',
            },
            {
              name: 'Atmos',
              domain: 'buildatmos.com',
            },
            {
              name: 'PeerDB',
              domain: null,
            },
            {
              name: 'Index',
              domain: 'indexapp.com',
            },
            {
              name: 'Hona (formerly Milestones)',
              domain: null,
            },
            {
              name: 'Howdy',
              domain: 'howdy.com',
            },
            {
              name: 'Reach',
              domain: 'reach-usa.org',
            },
            {
              name: 'Syncly',
              domain: 'syncly.us',
            },
            {
              name: 'Openlayer',
              domain: 'openlayer.com',
            },
            {
              name: 'HelixNano',
              domain: 'helixnano.com',
            },
            {
              name: 'Blocksfabrik',
              domain: 'blocksfabrik.com',
            },
            {
              name: 'Fabra',
              domain: 'fabraloverseas.com',
            },
            {
              name: 'OutSail Shipping',
              domain: 'outsailshipping.com',
            },
            {
              name: 'Infinia',
              domain: 'infiniacorp.com',
            },
            {
              name: 'EthosX',
              domain: 'ethosx.com',
            },
            {
              name: 'Muffin Data',
              domain: 'muffindata.com',
            },
            {
              name: 'Nebula',
              domain: 'nebula.io',
            },
            {
              name: 'Planar',
              domain: 'planar.com',
            },
            {
              name: 'Rivet',
              domain: 'rivet.work',
            },
            {
              name: 'Members.Land',
              domain: null,
            },
            {
              name: 'Derisk',
              domain: 'derisk.ca',
            },
            {
              name: 'Laminate',
              domain: 'bestpricelaminate.com',
            },
            {
              name: 'Modulari-T Biosciences',
              domain: null,
            },
            {
              name: 'Chart',
              domain: 'charttransit.com',
            },
            {
              name: 'Noya Software',
              domain: 'noya.io',
            },
            {
              name: 'Supernova.io',
              domain: 'supernova.io',
            },
            {
              name: 'Reworkd',
              domain: 're-work.dev',
            },
            {
              name: 'Stralis Aircraft',
              domain: 'stralis.aero',
            },
            {
              name: 'Keep',
              domain: 'keepcompany.com.br',
            },
            {
              name: 'Uiflow',
              domain: 'uiflow.com',
            },
            {
              name: 'Inboxbooster',
              domain: 'inboxbooster.com',
            },
            {
              name: 'Temporary group',
              domain: null,
            },
            {
              name: 'moonrepo',
              domain: 'moonrepo.dev',
            },
            {
              name: 'CatX',
              domain: 'catxalot.se',
            },
            {
              name: 'Cakework',
              domain: 'cakework.com',
            },
            {
              name: 'FlexWash Technologies',
              domain: null,
            },
            {
              name: 'Argus',
              domain: 'argus.observer',
            },
            {
              name: 'Aerones',
              domain: 'aerones.com',
            },
            {
              name: 'Alfie Health',
              domain: null,
            },
            {
              name: 'HireSure',
              domain: 'hiresure.ai',
            },
            {
              name: 'Lion Pose',
              domain: 'lionpose.com',
            },
            {
              name: 'ModernLoop',
              domain: 'modernloop.io',
            },
            {
              name: 'Taro - Software Engineer Growth',
              domain: null,
            },
            {
              name: 'Radical',
              domain: 'radical.co',
            },
            {
              name: 'Tailorr',
              domain: 'thetailorretailored.com',
            },
            {
              name: 'Portalform',
              domain: null,
            },
            {
              name: 'Riot',
              domain: 'riot.im',
            },
            {
              name: 'Rinsed',
              domain: 'rinsed.net',
            },
            {
              name: 'Paul’s Table',
              domain: null,
            },
            {
              name: 'Daedalus',
              domain: 'daedalus.investments',
            },
            {
              name: 'Plutis',
              domain: 'plutis.com',
            },
            {
              name: 'Integrated Reasoning',
              domain: 'integrated-reasoning.com',
            },
            {
              name: 'AvenHQ',
              domain: null,
            },
            {
              name: 'AutoInfra',
              domain: null,
            },
            {
              name: 'Swishjam',
              domain: null,
            },
            {
              name: 'Teclada',
              domain: null,
            },
            {
              name: 'Homeflow',
              domain: 'homeflow.co.uk',
            },
            {
              name: 'SuperAPI',
              domain: 'superapid.com',
            },
            {
              name: 'Quell',
              domain: 'quellrelief.com',
            },
            {
              name: 'KoalaOps',
              domain: 'koalaops.com',
            },
            {
              name: 'Mahaana',
              domain: 'mahaana.com',
            },
            {
              name: 'Workpage',
              domain: null,
            },
            {
              name: 'Spade',
              domain: 'spade-group.com',
            },
            {
              name: 'Loops',
              domain: 'loops.so',
            },
            {
              name: 'Typewise',
              domain: 'typewise.app',
            },
            {
              name: 'Poly',
              domain: 'poly.com',
            },
            {
              name: 'Arintra',
              domain: 'arintra.com',
            },
            {
              name: 'Heru',
              domain: 'heru.app',
            },
            {
              name: 'Littio',
              domain: 'littio.com',
            },
            {
              name: 'Chaos Genius',
              domain: 'chaosgenius.io',
            },
            {
              name: 'Floworks',
              domain: 'floworks.ai',
            },
            {
              name: 'Seis',
              domain: 'seismic.com',
            },
            {
              name: 'Inkeep',
              domain: 'inkeep.com',
            },
            {
              name: 'Monterey AI',
              domain: 'monterey.ai',
            },
            {
              name: 'Retake',
              domain: 'ericporterfieldforhouse27.com',
            },
            {
              name: 'Fetii',
              domain: 'fetii.com',
            },
            {
              name: 'Findly (YC S22)',
              domain: null,
            },
            {
              name: 'Anneal',
              domain: 'annealsys.com',
            },
            {
              name: 'Aragorn AI',
              domain: 'aragorn.ai',
            },
            {
              name: 'Vitalize Care',
              domain: 'vitalizecare.co',
            },
            {
              name: 'Holacasa',
              domain: 'holacasa.mx',
            },
            {
              name: 'ShoprTV',
              domain: 'shopr.tv',
            },
            {
              name: 'Infra',
              domain: 'infra.fr',
            },
            {
              name: 'Penguin AI',
              domain: 'penguinai.app',
            },
            {
              name: 'Alterya',
              domain: 'alteryapi.com',
            },
            {
              name: 'AccessOwl',
              domain: 'accessowl.io',
            },
            {
              name: 'Paperplane',
              domain: 'travelpaperplane.com',
            },
            {
              name: 'Cerrion',
              domain: 'cerrion.com',
            },
            {
              name: 'SpecCheck',
              domain: 'speccheck.com',
            },
            {
              name: 'Quadrant Eye',
              domain: 'quadranteye.com',
            },
            {
              name: 'SimpleHash',
              domain: 'simplehash.com',
            },
            {
              name: 'Parse',
              domain: 'parseai.co',
            },
            {
              name: 'Dr. Treat',
              domain: 'drtreat.com',
            },
            {
              name: 'Jasmine Energy',
              domain: 'jasmine.energy',
            },
            {
              name: 'Airmyne',
              domain: 'airmyne.com',
            },
            {
              name: 'Coverage Cat',
              domain: 'coveragecat.com',
            },
            {
              name: 'Immuna',
              domain: 'immuna.xyz',
            },
            {
              name: 'FlyCode',
              domain: 'flycode.org',
            },
            {
              name: 'Vizzly',
              domain: 'vizzly.co',
            },
            {
              name: 'Eugit Therapeutics',
              domain: ' Inc',
            },
            {
              name: 'Amiloz',
              domain: 'amiloz.com',
            },
            {
              name: 'Delight',
              domain: 'delight-data.com',
            },
            {
              name: 'Quack AI',
              domain: 'quack-ai.com',
            },
            {
              name: 'Mintplex',
              domain: 'mintplex.xyz',
            },
            {
              name: 'BotBuilt',
              domain: 'botbuilt.com',
            },
            {
              name: 'Sunlight',
              domain: 'sunlightapi.com',
            },
            {
              name: 'Slingshot Bills',
              domain: 'slingshotbills.com',
            },
            {
              name: 'Levers',
              domain: 'leve.rs',
            },
            {
              name: 'Relay',
              domain: 'relay.im',
            },
            {
              name: 'JuziBot',
              domain: null,
            },
            {
              name: 'Nexu',
              domain: 'nexu.mx',
            },
            {
              name: 'Magic',
              domain: 'getmagic.com',
            },
            {
              name: 'Toku',
              domain: 'tokuworld.com',
            },
            {
              name: 'Hypeshot',
              domain: 'hypeshot.io',
            },
            {
              name: 'Blitz',
              domain: 'weeklyblitz.net',
            },
            {
              name: 'Foster',
              domain: 'fosterglobal.com',
            },
            {
              name: 'nsave',
              domain: null,
            },
            {
              name: 'Juicebox',
              domain: 'juiceboxit.com',
            },
            {
              name: 'Booth AI',
              domain: 'booth.ai',
            },
            {
              name: 'Wyvern',
              domain: 'wyvern.space',
            },
            {
              name: 'Onyx Private',
              domain: 'onyxprivate.com',
            },
            {
              name: 'Versori',
              domain: 'versori.com',
            },
            {
              name: 'Releaf',
              domain: 'releafapp.com',
            },
            {
              name: 'Abatable',
              domain: 'abatable.com',
            },
            {
              name: 'Hyper',
              domain: 'hyper-db.de',
            },
            {
              name: 'Speedscale',
              domain: 'speedscale.com',
            },
            {
              name: 'Recall.ai',
              domain: 'recall.ai',
            },
            {
              name: 'RedBrick AI',
              domain: 'redbrickai.com',
            },
            {
              name: 'Cinder Technologies',
              domain: null,
            },
            {
              name: 'Optery',
              domain: 'optery.com',
            },
            {
              name: 'Mighty Health',
              domain: 'mightyhealth.com',
            },
            {
              name: 'Termii',
              domain: 'termii.com',
            },
            {
              name: 'Axle',
              domain: 'axleapp.io',
            },
            {
              name: 'arketa',
              domain: 'arketa.co',
            },
            {
              name: 'Gilgamesh Pharmaceuticals',
              domain: null,
            },
            {
              name: 'Pesto',
              domain: 'pesto.tech',
            },
            {
              name: 'Alaan',
              domain: 'alaanpay.com',
            },
            {
              name: 'Lightdash',
              domain: 'lightdash.com',
            },
            {
              name: '222',
              domain: null,
            },
            {
              name: 'Haven',
              domain: 'havenshop.com',
            },
            {
              name: 'Finni Health',
              domain: 'finnihealth.com',
            },
            {
              name: 'Fair Square Medicare',
              domain: 'fairsquaremedicare.com',
            },
            {
              name: 'HockeyStack',
              domain: 'hockeystack.com',
            },
            {
              name: 'Ballerine',
              domain: 'ballerine.com',
            },
            {
              name: 'Journey',
              domain: 'withjourney.com',
            },
            {
              name: 'Passiv',
              domain: 'getpassiv.com',
            },
            {
              name: 'Redbird',
              domain: 'getredbird.com',
            },
            {
              name: 'Artifact',
              domain: 'artifact.lk',
            },
            {
              name: 'Ramani',
              domain: 'ramani.pl',
            },
            {
              name: 'Vance',
              domain: 'vance-global.com',
            },
            {
              name: 'Noble',
              domain: 'noblecss.io',
            },
            {
              name: 'Contraline',
              domain: 'contraline.com',
            },
            {
              name: 'Stepful',
              domain: 'stepful.com',
            },
            {
              name: 'Andi',
              domain: 'andiware.com',
            },
            {
              name: 'Rubber Ducky Labs',
              domain: 'rubberduckylabs.io',
            },
            {
              name: 'Krave Mart',
              domain: 'kravemart.com',
            },
            {
              name: 'JITX',
              domain: 'jitx.com',
            },
            {
              name: 'Curacel',
              domain: 'curacel.co',
            },
            {
              name: 'Plerk',
              domain: 'plerk.com',
            },
            {
              name: 'Hubble Network',
              domain: 'hubblenetwork.com',
            },
            {
              name: 'Prism Labs',
              domain: 'prism.io',
            },
            {
              name: 'Entocycle',
              domain: 'entocycle.com',
            },
            {
              name: 'Disclo',
              domain: 'disclo.com',
            },
            {
              name: 'Array Labs',
              domain: 'arraylabs.io',
            },
            {
              name: 'Arpeggio Bio',
              domain: 'arpeggiobiosciences.com',
            },
            {
              name: 'OlaClick (YC W21)',
              domain: null,
            },
            {
              name: 'SnapCalorie',
              domain: 'snapcalorie.com',
            },
            {
              name: 'Massdriver',
              domain: 'massdriver.cloud',
            },
            {
              name: 'Pulppo',
              domain: 'pulppo.com',
            },
            {
              name: 'Marathon Education',
              domain: 'marathon.edu.vn',
            },
            {
              name: 'Workpay',
              domain: 'workpay.co.ke',
            },
            {
              name: 'SigmaOS',
              domain: 'sigmaos.com',
            },
            {
              name: 'Mayan',
              domain: 'mayan.co',
            },
            {
              name: 'Duplo',
              domain: 'duplos.cl',
            },
            {
              name: 'Qoohoo',
              domain: 'qoohoo.in',
            },
            {
              name: 'Menten AI',
              domain: 'menten.ai',
            },
            {
              name: 'HoyTrabajas',
              domain: 'hoytrabajas.com',
            },
            {
              name: 'LifeAt',
              domain: 'lifeat.io',
            },
            {
              name: 'Awesomic',
              domain: 'awesomic.com',
            },
            {
              name: 'Berri AI',
              domain: 'berri.ai',
            },
            {
              name: 'Movley',
              domain: 'movley.com',
            },
            {
              name: 'Kaso',
              domain: 'kasookoo.com',
            },
            {
              name: 'Ready',
              domain: 'readygroup.co.il',
            },
            {
              name: 'Dumme',
              domain: 'dumme.com',
            },
            {
              name: 'Meru',
              domain: 'meru.in',
            },
            {
              name: 'Able Jobs',
              domain: 'ablejobs.co',
            },
            {
              name: 'Cityfurnish',
              domain: 'cityfurnish.com',
            },
            {
              name: 'Fresh Factory',
              domain: 'freshfactory.com',
            },
            {
              name: 'Payload',
              domain: 'payload.co',
            },
            {
              name: 'Nest Genomics (YC W22)',
              domain: null,
            },
            {
              name: 'Sidenote',
              domain: 'side-note.com',
            },
            {
              name: 'Opkit',
              domain: 'opkit.co',
            },
            {
              name: 'Hypotenuse',
              domain: 'hypotenuse.ai',
            },
            {
              name: 'Aviator',
              domain: 'aviatorapp.com',
            },
            {
              name: 'Novig',
              domain: 'noviglas.info',
            },
            {
              name: 'UTVATE',
              domain: 'utvate.com',
            },
            {
              name: 'Luca',
              domain: 'luca-d3.com',
            },
            {
              name: 'Pasito',
              domain: 'joinpasito.com',
            },
            {
              name: 'Mantys',
              domain: 'mantys.io',
            },
            {
              name: 'Baseplate',
              domain: 'baseplate.io',
            },
            {
              name: 'Cyberdontics',
              domain: 'cyberdontics.ca',
            },
            {
              name: 'Satsuma',
              domain: 'satsuma.xyz',
            },
            {
              name: 'Grey',
              domain: 'discovergrey.com',
            },
            {
              name: 'Helicone',
              domain: 'helicone.ai',
            },
            {
              name: 'Fly.io',
              domain: 'fly.io',
            },
            {
              name: 'Rownd',
              domain: 'rownd.io',
            },
            {
              name: 'Courtyard',
              domain: 'courtyard.io',
            },
            {
              name: 'Glass Health',
              domain: 'glass.health',
            },
            {
              name: 'Wallbit',
              domain: 'wallbit.io',
            },
            {
              name: 'Finta',
              domain: 'getfinta.com',
            },
            {
              name: 'Sensible Biotechnologies',
              domain: null,
            },
            {
              name: 'Metriport',
              domain: 'metriport.com',
            },
            {
              name: 'Koywe',
              domain: 'koywe.eco',
            },
            {
              name: 'BBy',
              domain: 'bbyworld.com',
            },
            {
              name: 'Alga Biosciences',
              domain: 'alga.bio',
            },
            {
              name: 'Synapticure',
              domain: 'synapticure.com',
            },
            {
              name: 'Crew.work',
              domain: 'crewworkspace.com',
            },
            {
              name: 'Durioo',
              domain: 'durioo.com',
            },
            {
              name: 'Basedash',
              domain: 'basedash.com',
            },
            {
              name: 'Structured',
              domain: 'structured-inc.com',
            },
            {
              name: 'ALPHS',
              domain: 'alphsoles.com',
            },
            {
              name: 'AiPrise',
              domain: 'aiprise.com',
            },
            {
              name: 'Ping',
              domain: 'letsping.co',
            },
          ],
        },
      ],
    },
    buyer_personas: {
      name: 'Buyer Personas',
      listType: 'personas',
      lists: [
        {
          name: 'Selling to founders',
          items: [
            {
              name: 'Founder',
              domain: null,
            },
            {
              name: 'Co-founder',
              domain: null,
            },
            {
              name: 'CEO',
              domain: null,
            },
            {
              name: 'Chief Executive Officer',
              domain: null,
            },
          ],
        },
        {
          name: 'Selling to Legal',
          items: [
            {
              name: 'General Counsel',
              domain: null,
            },
            {
              name: 'Chief Legal Officer (CLO)',
              domain: null,
            },
            {
              name: 'Legal Counsel',
              domain: null,
            },
            {
              name: 'Associate General Counsel',
              domain: null,
            },
            {
              name: 'Corporate Counsel',
              domain: null,
            },
            {
              name: 'Senior Legal Counsel',
              domain: null,
            },
            {
              name: 'Director of Legal Affairs',
              domain: null,
            },
            {
              name: 'Legal Operations Manager',
              domain: null,
            },
            {
              name: 'Compliance Officer',
              domain: null,
            },
            {
              name: 'Contracts Manager',
              domain: null,
            },
            {
              name: 'Regulatory Affairs Specialist',
              domain: null,
            },
            {
              name: 'Intellectual Property Counsel',
              domain: null,
            },
            {
              name: 'Privacy Counsel',
              domain: null,
            },
            {
              name: 'Employment Counsel',
              domain: null,
            },
            {
              name: 'Legal Analyst',
              domain: null,
            },
          ],
        },
        {
          name: 'Selling to Engineering',
          items: [
            {
              name: 'Chief Technology Officer (CTO)',
              domain: null,
            },
            {
              name: 'Vice President of Engineering',
              domain: null,
            },
            {
              name: 'Director of Engineering',
              domain: null,
            },
            {
              name: 'Engineering Manager',
              domain: null,
            },
            {
              name: 'Senior Software Engineer',
              domain: null,
            },
            {
              name: 'Software Engineer',
              domain: null,
            },
            {
              name: 'Frontend Engineer',
              domain: null,
            },
            {
              name: 'Backend Engineer',
              domain: null,
            },
            {
              name: 'Full Stack Engineer',
              domain: null,
            },
            {
              name: 'DevOps Engineer',
              domain: null,
            },
          ],
        },
        {
          name: 'Selling to GTM Team',
          items: [
            {
              name: 'Chief Revenue Officer (CRO)',
              domain: null,
            },
            {
              name: 'Chief Marketing Officer (CMO)',
              domain: null,
            },
            {
              name: 'Chief Sales Officer (CSO)',
              domain: null,
            },
            {
              name: 'Head of Growth',
              domain: null,
            },
            {
              name: 'Head of Sales',
              domain: null,
            },
            {
              name: 'Head of Marketing',
              domain: null,
            },
            {
              name: 'Director of Marketing',
              domain: null,
            },
            {
              name: 'Director of Sales',
              domain: null,
            },
            {
              name: 'Growth Marketing Manager',
              domain: null,
            },
            {
              name: 'Sales Manager',
              domain: null,
            },
            {
              name: 'Marketing Manager',
              domain: null,
            },
            {
              name: 'Demand Generation Manager',
              domain: null,
            },
            {
              name: 'Sales Development Representative (SDR)',
              domain: null,
            },
            {
              name: 'Marketing Coordinator',
              domain: null,
            },
            {
              name: 'Customer Success Manager',
              domain: null,
            },
            {
              name: 'Revenue Operations',
              domain: null,
            },
          ],
        },
      ],
    },
    email_templates: {
      name: 'Email Templates',
      listType: 'templates',
      lists: [
        {
          name: 'Investor Update',
          help: 'Used 22k+ times · Created by Mahyar Raissi',
          items: [],
        },
        {
          name: 'New Advisor Invitation',
          help: 'Used 1k+ times · Created by Kris Rudeegraap',
          items: [],
        },
        {
          name: 'New Customer Onboarding',
          help: 'Used 250+ times · Created by Suman Melville',
          items: [],
        },
        {
          name: 'New Portfolio Co Onboarding',
          help: 'Used 150+ times · Created by Sarah Blanchard',
          items: [],
        },
      ],
    },
  },
}

export default defaultLists
