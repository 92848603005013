import axios from 'axios'

export default {
  getAdvisorOffers: (team_slug: string, advisor_uuid: string) => {
    return axios.get(`/api/advisor_offers`, {
      params: {
        advisor_uuid,
        team_slug,
      },
    })
  },

  createAdvisorOffer: (team_slug: string, title: string, description: string) => {
    return axios.post(`/api/advisor_offers`, {
      team_slug,
      title,
      description,
    })
  },
}
