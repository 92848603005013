import React, { useEffect, useRef, useState } from 'react'

import { ThreeDots } from 'react-loader-spinner'
import { useMutation } from 'react-query'
import { useTheme } from 'styled-components'

import CandidateProfileModal from 'containers/CandidateProfileModal'
import { getLinkedInSlug } from 'containers/CandidateProfileModal/linkedInAutoPrefill'
import MemberConnections from 'containers/Home/YourConnections/MemberConnections'
import NewGlobalPersonModal from 'containers/NewGlobalPersonModal'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import { useModal } from 'global/Modal'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import { GlobalPersonBlueprint } from 'utils/types'

interface Props {
  teamSlug: string
  onHide?: () => void
  listUuid?: string
  reload: () => void
  onClose?: () => void
  isTalentList?: boolean
}

interface EnrichProfileProps {
  linkedInSlug: string
  linkedInUrl?: string
}

const AddTalentIndividually: React.FC<Props> = ({
  teamSlug,
  listUuid,
  onHide,
  reload,
  onClose,
  isTalentList,
}) => {
  const [query, setQuery] = useState('')
  const [addingMember, setAddingMember] = useState(false)
  const debouncedQuery = useDebouncedValue(query, 300)
  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus()
    }
  }, [])

  const { showModal } = useModal()

  const renderCandidateProfileModal = (resolve: () => void, linkedinUrl?: string) => (
    <CandidateProfileModal
      listUuid={listUuid || ''}
      teamSlug={teamSlug}
      onFinalize={() => {
        reload()
        resolve()
      }}
      linkedinUrl={linkedinUrl}
      onHide={() => resolve()}
      inPeopleList={!isTalentList}
    />
  )

  const renderNewGlobalPersonModal = (resolve: () => void, linkedinUrl?: string) => (
    <NewGlobalPersonModal
      teamSlug={teamSlug}
      onFinalize={() => {
        resolve()
        reload()
      }}
      onHide={() => resolve()}
      searchedName={debouncedQuery}
      addPersonToList={addPersonToList}
    />
  )

  const { mutate: addPersonToList } = useMutation(
    (globalPersonUuid: string) =>
      callApi(api.createListItem, {
        team: teamSlug,
        list_uuid: listUuid!,
        list_item: { global_person_uuid: globalPersonUuid },
      }),
    {
      onSuccess: () => {
        reload()
        cabalToast({ style: 'success', content: 'Person added to list' })
        onHide?.()
        onClose?.()
        setAddingMember(false)
      },
    },
  )

  const { mutate: enrichProfileMutation } = useMutation(
    ['enrichProfile'],
    (props: EnrichProfileProps) => callApi(api.enrichProfile, props.linkedInSlug),
    {
      onMutate: async (props: EnrichProfileProps) => {
        return props
      },
      onSuccess: (data, _variables, context) => {
        // talent lists require the global person to have a linked candidate profile
        showModal(
          (resolve) => renderCandidateProfileModal(resolve, context?.linkedInUrl),
          'render_candidate_profile',
        )
        onHide?.()
        onClose?.()
        setAddingMember(false)
      },
    },
  )

  const handleAddMember = (person: GlobalPersonBlueprint) => {
    if (!isTalentList || (listUuid && person.candidate_profile_exists && person.uuid)) {
      addPersonToList(person.uuid)
    } else {
      const linkedInUrl = person.linkedin_url
      if (linkedInUrl) {
        const linkedInSlug = getLinkedInSlug(linkedInUrl)
        enrichProfileMutation({ linkedInSlug, linkedInUrl })
      } else {
        showModal((resolve) => renderCandidateProfileModal(resolve), 'render_candidate_profile')
        onHide?.()
        onClose?.()
      }
    }
  }
  const theme = useTheme()

  return (
    <>
      <div className="">
        <SearchInput
          ref={searchInputRef}
          onChange={(c) => setQuery(c.target.value)}
          placeholder={`Search for people`}
          value={query}
        />

        <div>
          {debouncedQuery !== '' && (
            <>
              <div className="my-4 px-3">
                <CabalButton
                  variant="link"
                  onClick={() => {
                    if (isTalentList) {
                      showModal(renderCandidateProfileModal, 'render_candidate_profile')
                    } else {
                      showModal(renderNewGlobalPersonModal, 'render_new_global_person')
                    }
                    onHide?.()
                    onClose?.()
                  }}
                  leftIcon={<i className="far fa-plus fa-xs" />}
                  padding="0"
                  size="large"
                >
                  <span>{`Add person "${debouncedQuery}"`}</span>
                </CabalButton>
              </div>

              <MemberConnections
                debouncedQuery={[debouncedQuery]}
                isLoading={addingMember}
                personRowCta="Add"
                onPersonRowCtaClick={(p) => {
                  setAddingMember(true)
                  handleAddMember(p)
                }}
                searchAll
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AddTalentIndividually
