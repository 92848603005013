import * as React from 'react'

import { PopupModal } from 'react-calendly'
import styled from 'styled-components'

const NavLink = styled.div`
  color: #000 !important;
  font-family: 'JetBrains Mono' !important;
  font-size: 14px !important ;
  cursor: pointer;

  /* @media (prefers-color-scheme: dark) {
    color: #fff !important;
  } */

  &:hover {
    color: #5c69d1 !important;
  }
`

const Button = styled.button`
  background-color: rgba(92, 105, 209, 0.08) !important;
  color: #5c69d1 !important;
  font-size: 14px !important;

  &:hover {
    background-color: rgba(92, 105, 209, 0.16) !important;
  }
`

interface Props {
  buttonView?: boolean
}

const Calendly: React.VFC<Props> = ({ buttonView = false }) => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      {buttonView ? (
        <Button onClick={() => setOpen(true)}>Book a demo</Button>
      ) : (
        <NavLink onClick={() => setOpen(true)}>Demo</NavLink>
      )}
      <PopupModal
        url="https://calendly.com/d/zkh-f2t-tc8"
        onModalClose={() => setOpen(false)}
        open={open}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById('calendly-index')}
      />
    </>
  )
}

export default Calendly
