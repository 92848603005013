import CabalMessage from 'models/CabalMessage'

// null unless it's for founders
// assumes founder if certain keys are returned from API
export function engagementText(message: CabalMessage) {
  const responded: string[] = []

  const { sent_count, replied_count, opened_count } = message

  if (replied_count && sent_count) {
    if (replied_count == 0) {
      responded.push('No replies')
    } else {
      const replyPercent = (replied_count / sent_count) * 100
      responded.push(`${message.replied_count} replied (${replyPercent.toFixed(1)}%)`)
    }
  }

  if (opened_count && sent_count) {
    const openPercent = (opened_count / sent_count) * 100
    responded.push(`${message.opened_count} opened (${openPercent.toFixed(1)}%)`)
  }

  // if (message.clicked_count) {
  //   responded += ` · ${message.clicked_count} clicked`
  // }

  return responded
}
