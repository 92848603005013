import React from 'react'

import { useHistory } from 'react-router-dom'

import { Divider } from 'components/SendMessage/styles'
import { useCurrentUser, useTeams } from 'store/hooks'

import { Team } from 'utils/types'

import Avatar from './Avatar'
import DropDownMenu, { DropdownMenuItem } from './DropDownMenu'
import Typography from './Typography'

interface Props {
  trigger?: React.ReactNode
}

const CompanySwitcherDropDown: React.FC<Props> = ({ trigger }) => {
  const { teams } = useTeams()
  const { user } = useCurrentUser()
  const history = useHistory()
  const menu: DropdownMenuItem[] = []

  const yourTeam = teams.find((t) => t.slug === user.team?.slug)

  const sortedTeams = teams
    .sort((a: Team, b: Team) => (a.name > b.name ? 1 : -1))
    .filter((t) => t.slug !== yourTeam?.slug)

  if (yourTeam) {
    menu.push({
      label: (
        <div className="flex flex-shrink-0 items-center space-x-2">
          <Avatar size="16px" src={yourTeam.logo} name={yourTeam.name} />
          <Typography>{yourTeam.name}</Typography>
          <div></div>
        </div>
      ),
      onSelect: () => history.push(`/${yourTeam.slug}`),
    })
  }

  if (yourTeam && sortedTeams.length > 0) {
    menu.push({
      divider: true,
    })
  }

  sortedTeams.forEach((team) => {
    menu.push({
      label: (
        <div className="flex flex-shrink-0 items-center space-x-2">
          <Avatar size="16px" src={team.logo} name={team.name} />
          <Typography>{team.name}</Typography>
        </div>
      ),
      onSelect: () => history.push(`/${team.slug}`),
    })
  })

  return (
    <DropDownMenu
      trigger={
        trigger ? (
          trigger
        ) : (
          <Typography color="rain" fontSize="10">
            <i className="fas fa-chevron-down"></i>
          </Typography>
        )
      }
      menuItems={menu}
    ></DropDownMenu>
  )
}

export default CompanySwitcherDropDown
