import React, { useState } from 'react'

import { useParams } from 'react-router-dom'

import { useAccessControl } from 'global/AccessControl'
import GridWrapper from 'ui-components/GridWrapper'

import { CompanySlugParam, ConnectionModel } from 'utils/types'

import ConnectionsTableRow from './ConnectionsTableRow'
import RequestIntroModal from './RequestIntroModal'

interface Props {
  people: any[]
  onRefetch: () => void
  teamRequests?: any[]
  view?: 'list' | 'grid'
}

const ConnectionsTable: React.VFC<Props> = ({
  people,
  onRefetch,
  view = 'list',
  teamRequests = [],
}) => {
  const { company_slug: companySlug } = useParams<CompanySlugParam>()
  const { canRequestIntro } = useAccessControl(companySlug)
  const [requestIntroConn, setRequestIntroConn] = useState<ConnectionModel | null>(null)

  const connectionRow = (view: 'grid' | 'list') =>
    people.map((person) => (
      <>
        <ConnectionsTableRow
          key={(person.id || person.uuid) + person.name}
          person={person}
          advisors={person.advisors}
          companySlug={companySlug}
          refetch={onRefetch}
          teamRequests={teamRequests}
          view={view}
        />
      </>
    ))

  return (
    <>
      {canRequestIntro && !!requestIntroConn && (
        <RequestIntroModal
          show={canRequestIntro && !!requestIntroConn}
          connection={requestIntroConn}
          onHide={() => {
            setRequestIntroConn(null)
          }}
          onSubmit={() => {
            setRequestIntroConn(null)
            onRefetch?.()
          }}
        />
      )}

      <>
        {view === 'grid' && <GridWrapper>{connectionRow('grid')}</GridWrapper>}
        {view === 'list' && <>{connectionRow('list')}</>}
      </>
    </>
  )
}

export default ConnectionsTable
