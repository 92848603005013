import React, { useMemo } from 'react'

import compact from 'lodash/compact'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import { cn } from 'utils/styles'
import { CompanyBlueprint } from 'utils/types'

interface Props {
  item: CompanyBlueprint
  teamSlug: string
  listSlug: string
  listUuid: string
  refetchItems?: () => void
  canRemoveItem?: boolean
  view: 'grid' | 'list'
}

const ItemRow: React.FC<Props> = ({
  item,
  teamSlug,
  listSlug,
  listUuid,
  refetchItems,
  canRemoveItem = false,
  view,
}) => {
  const history = useHistory()
  const { name, logo_url, last_modified_at, updated_at } = item

  let avatarUrl = logo_url ? logo_url : undefined

  if (item.item_type === 'other' && item.avatar_upload_uuid) {
    avatarUrl = `/api/uploads/${item.avatar_upload_uuid}`
  }

  const onRemoveFromList = () => {
    api.removeListItems(teamSlug, listUuid, [item.uuid]).then((response) => {
      cabalToast({ style: 'success', content: 'Item removed' })
      refetchItems?.()
    })
  }

  const onClick = useMemo(() => {
    return () => {
      const urlParams = new URLSearchParams(window.location.search)
      urlParams.delete('search')
      if (!!listUuid) {
        urlParams.set('list_uuid', listUuid)
      }
      const route = `/${teamSlug}/lists/${listSlug || 'companies'}/${
        item.uuid
      }?${urlParams.toString()}`
      history.push(route)
    }
  }, [item.uuid, history, listSlug, listUuid, teamSlug])

  const getDescription = () => {
    const description = []

    if (item.description) {
      description.push(item.description)
    }

    if (last_modified_at) {
      description.push(moment(last_modified_at).fromNow())
    } else if (updated_at) {
      description.push(moment(updated_at).fromNow())
    }

    return description
  }

  const menuItems = compact([
    item.item_type === 'mail' &&
      canRemoveItem && {
        label: 'Remove from list',
        onSelect: () => onRemoveFromList(),
        dataTestId: 'list-item-remove-from-list',
      },
  ])

  const description = getDescription()

  return (
    <>
      <DataCardV2
        title={name}
        view={view}
        avatar={
          avatarUrl ? (
            <Avatar src={avatarUrl} name={name} size={view === 'grid' ? '72' : '32'} />
          ) : item.item_type === 'mail' ? (
            <i className="far fa-envelope" />
          ) : undefined
        }
        description={description.length > 0 ? description : null}
        onClick={() => (onClick ? onClick() : null)}
        className={cn({
          'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded':
            view === 'list',
        })}
        cta={
          <Typography
            onClick={() => (onClick ? onClick() : null)}
            className="flex items-center cursor-pointer"
            fontSize="12"
            color="purple"
          >
            {item.cta ? item.cta : 'View'}
            <i className="far fa-chevron-right ml-1" />
          </Typography>
        }
        dropDownMenu={menuItems.length > 0 ? { menuItems: menuItems } : undefined}
      />
    </>
  )
}

export default React.memo(ItemRow)
