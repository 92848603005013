import axios from 'axios'

export default {
  createAhoyEvent: (event_name: string, event_properties?: any) => {
    return axios.post('/api/ahoy', {
      event_name,
      event_properties,
    })
  },
}
