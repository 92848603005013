import React, { useState } from 'react'

import { useFetchUploadData } from 'components/Winsite/queries/FetchUploadData'
import { MaterialInfoDiv } from 'components/Winsite/styles'
import { ITeamData } from 'components/Winsite/types/ITeamData'
import { IUploadResponse } from 'components/Winsite/types/IUploadResponse'
import { loginWithDomainToast } from 'components/Winsite/utils'
import { useCurrentUser } from 'store/hooks'

import { useAuthenticateUser } from '../queries/AuthenticateUser'
import { InfoHeadlineDiv, StyledCabalButton } from '../styles'
import { FileUploadDisplay } from './FileUploadDisplay'
import { MaterialDivSubSection } from './MaterialDivSubSection'
import { Upload } from './Upload'

interface IProps {
  teamData: ITeamData | undefined
}
export function MaterialDivSection({ teamData }: IProps) {
  const [isMaterialDivVisible, setIsMaterialDivVisible] = useState<boolean>(true)
  const [isUploadVisible, setIsUploadVisible] = useState<boolean>(false)
  const [uploads, setUploads] = useState<IUploadResponse[]>(teamData?.uploads || [])

  const { data: authResponse, isError } = useAuthenticateUser(teamData?.company_domain)
  const userFailedToAuthenticate = () => {
    return isError || (authResponse && !authResponse.data.authenticated)
  }
  const { user } = useCurrentUser()
  const { refetch: fetchUploadData } = useFetchUploadData(
    teamData?.team_slug,
    teamData?.company_domain,
  )
  const uploadsPresent = uploads?.length > 0

  const internalKey = 'winsite_file'

  const arraysAreEqual = (arr1: IUploadResponse[], arr2: IUploadResponse[]) => {
    if (arr1.length !== arr2.length) {
      return false
    }
    arr1.sort((a, b) => (a.uuid < b.uuid ? -1 : 1))
    arr2.sort((a, b) => (a.uuid < b.uuid ? -1 : 1))

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].uuid !== arr2[i].uuid) {
        return false
      }
    }
    return true
  }
  const handleClick = () => {
    if (!Object(user)?.created_at || userFailedToAuthenticate()) {
      const content = `You must be a member of the ${teamData?.company_name.toLowerCase()} team to add files`
      loginWithDomainToast(teamData, content)
      return
    }
    setIsMaterialDivVisible(false)
    setIsUploadVisible(true)
  }

  const handleBack = () => {
    setIsMaterialDivVisible(true)
    setIsUploadVisible(false)
    fetchUploadData().then((data) => {
      const { data: responseData } = data
      const uploadData: IUploadResponse[] | null = responseData?.data?.uploads
      if (uploadData && !arraysAreEqual(uploadData, uploads)) {
        setUploads(uploadData)
      }
    })
  }

  return (
    <MaterialInfoDiv>
      <div className="flex flex-col gap-3 w-full p-5">
        <div className="flex flex-row justify-between">
          <InfoHeadlineDiv>Materials</InfoHeadlineDiv>
        </div>
        {isMaterialDivVisible && (
          <div className="flex flex-col gap-2">
            {!uploadsPresent && (
              <div className="flex flex-col gap-2">
                <MaterialDivSubSection
                  onClick={() => handleClick()}
                  leftIcon={<i className="fa-light fa-upload pb-1" />}
                  content="Add Sales Pitch Deck"
                />
                <MaterialDivSubSection
                  onClick={() => handleClick()}
                  leftIcon={<i className="fa-light fa-upload pb-1" />}
                  content="Add Case Study"
                />
              </div>
            )}
            {uploadsPresent && (
              <FileUploadDisplay
                uploads={uploads}
                teamSlug={teamData?.team_slug}
                companyDomain={teamData?.company_domain}
              />
            )}
            <MaterialDivSubSection
              onClick={() => handleClick()}
              leftIcon={<i className="fa-light fa-upload pb-1" />}
              content="Add Files"
            />
          </div>
        )}
        <Upload showUpload={isUploadVisible} internalKey={internalKey} handleBack={handleBack} />
        <div>
          {isMaterialDivVisible && (
            <StyledCabalButton style={{ marginTop: '0px' }} onClick={() => handleClick()}>
              Add Files
            </StyledCabalButton>
          )}
        </div>
      </div>
    </MaterialInfoDiv>
  )
}
