import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

interface Props {
  icon: React.ReactNode
  title: string
  count: string
  state: string
  buttonText: string
  onClick: () => void
  complete: boolean
  open?: boolean
}

const Source = styled.div`
  ${tw`pt-2 pb-2 flex items-center justify-between`}
`

const SourceIcon = styled(Typography)`
  ${tw`mr-5 float-left`}
  color: ${({ theme }) => theme.colors.fog};
  width: 16px;
  height: 16px;
`

const LastSync = styled(Typography)`
  ${tw`pt-4 pb-4 pr-4 justify-self-end`}
`

const Connected = styled.i`
  ${tw`mr-1 text-green-500`}
  font-size: 14px;
`

const ConnectionSource: React.VFC<Props> = ({
  icon,
  title,
  count,
  state,
  buttonText,
  onClick,
  complete,
  open = false,
  onOpenLinkedin,
  ...restProps
}) => {
  return (
    <Source {...restProps}>
      <div className="flex items-center">
        <div className="mr-4">{icon}</div>
        <Typography className="flex items-center" fontSize="12" fontWeight={600}>
          {complete && <Connected className="fas fa-check-circle" data-testid="source-sync-done" />}
          {title}
          <Typography fontWeight={300} className="pl-1" data-testid="source-sync-count">
            ({count})
          </Typography>
        </Typography>
      </div>
      <div className="flex items-center">
        <LastSync color="fog" fontSize={'12'} fontWeight={400}>
          {state}
        </LastSync>
        <div className="mt-auto mb-auto">
          <CabalButton variant={'secondary'} className="w-full" onClick={() => onClick()}>
            Open LinkedIn
          </CabalButton>
        </div>
      </div>
    </Source>
  )
}

export default ConnectionSource
