import React from 'react'

import { useMutation, useQuery } from 'react-query'

import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { UserEmail } from 'utils/types'

import EmailRow from './EmailRow'

const ConnectedAccounts = () => {
  const [editing, setEditing] = React.useState(false)
  const [newEmail, setNewEmail] = React.useState('')
  const emailsQuery = useQuery(['currentUser', 'emailSettings'], () => callApi(api.getUserEmails))

  const addEmailMutation = useMutation((email: string) => callApi(api.addUserEmail, email), {
    onSuccess: () => {
      emailsQuery.refetch()
      cabalToast({ style: 'success', content: 'Successfully added email' })
    },
  })

  const handleSubmit = async () => {
    await addEmailMutation.mutateAsync(newEmail)
    setNewEmail('')
    setEditing(false)
  }

  const emails = emailsQuery.data
  return (
    <div>
      <div className="flex flex-col gap-1">
        {emails &&
          emails.map((email: UserEmail) => (
            <EmailRow key={email.id} email={email} refetchEmails={() => emailsQuery.refetch()} />
          ))}
      </div>

      <div className="mt-2">
        {!editing ? (
          <CabalButton
            padding="0"
            variant="link"
            leftIcon={<i className="fas fa-plus"></i>}
            onClick={() => setEditing(!editing)}
          >
            Add Account
          </CabalButton>
        ) : (
          <div className="flex">
            <TextInput
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="New Email"
              className="w-full"
              autoFocus
            />

            <CabalButton className="flex-shrink-0" variant="link" onClick={handleSubmit}>
              Save
            </CabalButton>

            <CabalButton
              variant="tertiary"
              className="flex-shrink-0"
              onClick={() => setEditing(!editing)}
            >
              Cancel
            </CabalButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default ConnectedAccounts
