import axios from 'axios'

import { FiltersValueType } from 'components/Filters'

import {
  CreateMessageRequest,
  DraftMessageBlueprint,
  DraftPresendCheckReponse,
  GetDraftMessagesFilter,
  GetDraftMetadataResponse,
  GetIndivdualMessageRecipients,
  GetMessageEngagementResponse,
  GetMessageHistoryResponse,
  GetMessagePersonalizationsResponse, // GetMessageMetadataResponse,
  GetMessageResponse,
  GetMessagesResponse,
  MessageModel,
  PresendCheckParams,
  PresendCheckReponse,
  UpsertMessagePersonalizationResponse,
} from 'utils/types'

export default {
  getSearchMessage: (params: {
    team_slug: string
    query: string
    limit?: number
    page?: number
  }) => {
    return axios.get<GetMessagesResponse>(`/api/messages/search`, {
      params,
    })
  },

  createMessage: (companySlug: string, params: CreateMessageRequest) => {
    return axios.post<{
      message: MessageModel
    }>(`/api/messages`, {
      message: params,
      team_slug: companySlug,
    })
  },

  presendCheck: (
    team_slug: string,
    params: CreateMessageRequest,
    extra?: { skip_var_check: boolean },
  ) => {
    return axios.post<PresendCheckReponse>('/api/messages/presend_check', {
      team_slug,
      message: params,
      ...extra,
    })
  },

  getMessage: (team_slug: string, message_uuid: string) => {
    return axios.get<GetMessageResponse>(`/api/messages/${message_uuid}`, {
      params: {
        team_slug,
      },
    })
  },

  getDraftStats: (
    team_slug: string,
    filter?: GetDraftMessagesFilter,
    filters?: FiltersValueType,
  ) => {
    return axios.get<GetDraftMetadataResponse>(`/api/messages/draft_stats`, {
      params: {
        team_slug,
        drafts: true,
        ...filter,
        filters: JSON.stringify(filters),
      },
    })
  },

  getDraftMessages: ({
    team_slug,
    page = 1,
    tab,
    filter,
    filters,
    v2,
  }: {
    team_slug: string
    page: number
    tab: string
    filter?: GetDraftMessagesFilter
    filters?: FiltersValueType
    v2: boolean
  }) => {
    return axios.get<GetMessagesResponse>(v2 ? `/api/drafts` : `/api/messages`, {
      params: {
        team_slug,
        drafts: true,
        page,
        tab,
        ...filter,
        filters: JSON.stringify(filters),
      },
    })
  },

  createDraftMessage: (companySlug: string, params: CreateMessageRequest) => {
    return axios.post<{
      message: DraftMessageBlueprint
    }>(`/api/messages`, {
      message: params,
      team_slug: companySlug,
    })
  },

  draftPresendCheck: (
    team_slug: string,
    message: DraftMessageBlueprint,
    params?: PresendCheckParams,
  ) => {
    return axios.post<DraftPresendCheckReponse>(`/api/drafts/${message.uuid}/presend_check`, {
      team_slug,
      message,
      ...params,
    })
  },

  getDraftMessage: (team_slug: string, message_uuid: string) => {
    return axios.get<{ message: DraftMessageBlueprint }>(`/api/drafts/${message_uuid}`, {
      params: {
        team_slug,
      },
    })
  },

  getMessageEngagement: (team_slug: string, message_uuid: string) => {
    return axios.get<GetMessageEngagementResponse>(`/api/messages/${message_uuid}/engagement`, {
      params: {
        team_slug,
      },
    })
  },

  getMessageHistory: (team_slug: string, message_uuid: string, page: number) => {
    return axios.get<GetMessageHistoryResponse>(`/api/messages/${message_uuid}/history`, {
      params: {
        team_slug,
        page,
      },
    })
  },

  getIndividualMessageRecipients: (team_slug: string, message_uuid: string) => {
    return axios.get<GetIndivdualMessageRecipients>(
      `/api/messages/${message_uuid}/individual_recipients`,
      {
        params: {
          team_slug,
        },
      },
    )
  },

  getMessagePersonalizations: (team_slug: string, message_uuid: string) => {
    return axios.get<GetMessagePersonalizationsResponse>(
      `/api/messages/${message_uuid}/message_personalizations`,
      {
        params: {
          team_slug,
        },
      },
    )
  },

  createMessagePersonalization: (
    team_slug: string,
    message_uuid: string,
    params: { recipient: any },
  ) => {
    return axios.post<UpsertMessagePersonalizationResponse>(
      `/api/messages/${message_uuid}/message_personalizations`,
      {
        team_slug,
        ...params,
      },
    )
  },

  getMessagePersonalization: (
    team_slug: string,
    message_uuid: string,
    message_personalization_uuid: string,
  ) => {
    return axios.get<UpsertMessagePersonalizationResponse>(
      `/api/messages/${message_uuid}/message_personalizations/${message_personalization_uuid}`,
      {
        params: {
          team_slug,
        },
      },
    )
  },

  updateMessagePersonalization: (
    team_slug: string,
    message_uuid: string,
    message_personalization_uuid: string,
    params: { body: string; subject: string },
  ) => {
    return axios.put<UpsertMessagePersonalizationResponse>(
      `/api/messages/${message_uuid}/message_personalizations/${message_personalization_uuid}`,
      {
        team_slug,
        ...params,
      },
    )
  },

  deleteMessagePersonalization: (
    team_slug: string,
    message_uuid: string,
    message_personalization_uuid: string,
  ) => {
    return axios.delete(
      `/api/messages/${message_uuid}/message_personalizations/${message_personalization_uuid}`,
      {
        params: {
          team_slug,
        },
      },
    )
  },

  // getMessageThreadMetadata: (team_slug: string, messageUuid: string) => {
  //   return axios.get<GetMessageMetadataResponse>(`/api/messages/${messageUuid}/thread_metadata`, {
  //     params: {
  //       team_slug: team_slug,
  //     },
  //   })
  // },

  postMessageThreadMarkAsRead: (
    team_slug: string,
    messageUuid: string,
    messageThreadUuid: string,
  ) => {
    return axios.post(`/api/messages/${messageUuid}/thread_mark_as_read`, {
      team_slug: team_slug,
      message_thread_id: messageThreadUuid,
    })
  },

  deleteMessage: (team_slug: string, uuid: string) => {
    return axios.delete(`/api/messages/${uuid}`, {
      params: {
        team_slug,
      },
    })
  },

  updateMessage: (
    team_slug: string,
    uuid: string,
    message: Partial<MessageModel>,
    update_uuid?: string,
  ) => {
    return axios.put<{ message: MessageModel }>(`/api/messages/${uuid}`, {
      message,
      team_slug,
      update_uuid,
    })
  },

  updateDraftMessage: (
    team_slug: string,
    uuid: string,
    message: Partial<DraftMessageBlueprint>,
    update_uuid?: string,
  ) => {
    return axios.put<{ message: MessageModel }>(`/api/drafts/${uuid}`, {
      message,
      team_slug,
      update_uuid,
    })
  },

  markAsReady: (message_uuid: string) => {
    return axios.post(`/api/messages/${message_uuid}/mark_as_ready`)
  },

  addMessageReaction: (message_uuid: string, reaction: string) => {
    const params = { message_uuid, reaction }
    return axios.post(`/api/message_reactions`, params)
  },

  removeMessageReaction: (message_uuid: string, reaction: string) => {
    const params = { message_uuid, reaction }
    return axios.delete(`/api/message_reactions/${message_uuid}`, {
      params,
    })
  },

  markAsUnread: (message_uuid: string) => {
    return axios.post(`/api/messages/${message_uuid}/mark_as_unread`)
  },

  markAsRead: (message_uuid: string) => {
    return axios.post(`/api/messages/${message_uuid}/mark_as_read`)
  },

  markAsUnarchived: (message_uuid: string) => {
    return axios.post(`/api/messages/${message_uuid}/mark_as_unarchived`)
  },

  markAsArchived: (message_uuid: string) => {
    return axios.post(`/api/messages/${message_uuid}/mark_as_archived`)
  },

  markAsPinned: (message_uuid: string, team_slug: string) => {
    const params = { team_slug }
    return axios.post(`/api/messages/${message_uuid}/mark_as_pinned`, params)
  },

  markAsUnpinned: (message_uuid: string, team_slug: string) => {
    const params = { team_slug }
    return axios.post(`/api/messages/${message_uuid}/mark_as_unpinned`, params)
  },

  sendMessageAction: (message_uuid: string, params: any) => {
    return axios.post(`/api/messages/${message_uuid}/submit_action`, params)
  },

  getUnreadCounts: () => {
    return axios.get(`/api/messages/unread_counts`)
  },

  saveMessageRevision: (message_uuid: string, body: string) => {
    return axios.post(`/api/message_revisions`, {
      message_uuid,
      body,
    })
  },

  cancelSend: (messageUuid: string, team_slug: string) => {
    return axios.post(`/api/messages/${messageUuid}/cancel_send`, { team_slug })
  },

  draftCancelSend: (messageUuid: string, team_slug: string) => {
    return axios.post(`/api/drafts/${messageUuid}/cancel_send`, { team_slug })
  },

  draftScheduleSend: (messageUuid: string, team_slug: string, schedule_at: string) => {
    return axios.post(`/api/drafts/${messageUuid}/schedule_send`, { team_slug, schedule_at })
  },

  draftNotifySender: (
    messageUuid: string,
    team_slug: string,
    sender_uuid: string,
    note: string,
  ) => {
    return axios.post<{ notified_sender_uuids: string[] }>(
      `/api/drafts/${messageUuid}/notify_sender`,
      { team_slug, sender_uuid, note },
    )
  },

  detachMessage: (messageUuid: string, team_slug: string) => {
    return axios.post(`/api/messages/${messageUuid}/detach`, { team_slug })
  },

  messageNotifySender: (
    messageUuid: string,
    senderUuid: string,
    team_slug: string,
    notes: string,
  ) => {
    return axios.post(`/api/messages/${messageUuid}/notify_sender`, {
      team_slug,
      sender_uuid: senderUuid,
      notes,
    })
  },
}
