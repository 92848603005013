import React, { useEffect, useMemo, useState } from 'react'

import { compact, flatten } from 'lodash'
import last from 'lodash/last'
import CabalMessage from 'models/CabalMessage'
import objectHash from 'object-hash'
import { useInfiniteQuery, useQuery } from 'react-query'
import { Slide } from 'react-reveal'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import { useComposer } from 'components/Composer'
import { FiltersValueType, renderFilters } from 'components/Filters'
import SendMessage from 'components/SendMessage'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import { SelectOption } from 'global/Input'
import Loading from 'global/Loading'
import Tabs, { Tab } from 'global/Tabs'
import CabalTitle from 'global/Title'
import Typography from 'global/Typography'
import { useAdvisors, useCurrentUser, useGroups, useTeam, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import Template from 'utils/templates'
import { AdvisorModel, GetDraftMessagesFilter, MessageRecipient } from 'utils/types'

import { SendMessageWrap } from '../../ViewMessage/styles'
import Subtabs from '../Subtabs'
import DraftMessageList from './DraftMessageList'
import Filters from './Filters'

const Drafts: React.VFC = () => {
  const teamSlug = useTeamSlug()
  const location = useLocation<
    | {
        composerOpen?: true
        advisors?: AdvisorModel[]
      }
    | undefined
  >()
  const hash = location.hash
  const state = location.state
  const history = useHistory()
  const useUpdateTemplate = location.search.includes('update=')
  const match = useRouteMatch<{ tab: string }>(`/${teamSlug}/drafts/:tab?`)

  const { user } = useCurrentUser()
  const { team } = useTeam(teamSlug)
  const { advisors } = useAdvisors({ teamSlug })
  const { groups } = useGroups(teamSlug)
  const { compose } = useComposer(teamSlug)
  const updateMonth = useSearchParam('update')
  const messageUuid = useSearchParam('uuid')

  const [showPostMessage, setShowPostMessage] = useState(!!useUpdateTemplate)
  const [defaultBody, setDefaultBody] = useState<string>('')
  const [defaultSubject, setDefaultSubject] = useState<string>('')
  const [defaultRecipients, setDefaultRecipients] = useState<MessageRecipient[]>([])
  const [showFilters, setShowFilters] = useState(false)
  const [filter, setFilter] = useState<GetDraftMessagesFilter>({})
  const [filters, setFilters] = useState<FiltersValueType>({})

  const tab = match?.params.tab || 'recent'

  useEffect(() => {
    messageUuid && compose({ messageUuid, onHide: reloadMessages })
  }, [])

  useEffect(() => {
    if (state?.composerOpen) {
      setShowPostMessage(true)
    }
    if (state?.advisors) {
      setDefaultRecipients(
        state.advisors.map((a) => ({
          label: a.name,
          value: a.uuid,
          type: 'advisor',
        })),
      )
    }
  }, [state])

  useEffect(() => {
    if (hash === '#social') {
      setDefaultBody(Template.social.body)
      setDefaultSubject(Template.social.subject)
    } else if (hash === `#updates`) {
      setDefaultBody(Template.updates.body)
      setDefaultSubject(Template.updates.subject)
    }
  }, [hash])

  useEffect(() => {
    if (!updateMonth) return

    history.replace(`/${teamSlug}/compose${location.search}`)
  }, [teamSlug, history, location.search, updateMonth])

  const {
    isLoading,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: reloadMessages,
    data: messageData,
  } = useInfiniteQuery(
    [teamSlug, 'drafts', tab, filter, objectHash(filters)],
    ({ pageParam: page = 1 }) =>
      callApi(api.getDraftMessages, {
        team_slug: teamSlug,
        page,
        tab,
        filter,
        filters,
        v2: !!user.enable_v2_composer,
      }),
    {
      getNextPageParam: (lastPage) => lastPage?.pagination?.next_page,
      getPreviousPageParam: (firstPage) => firstPage?.pagination?.prev_page,
      onError: (err) => console.error(err),
    },
  )

  const { data: draftMetaDataData } = useQuery(
    [teamSlug, 'draftsStats', filter, objectHash(filters)],
    () => callApi(api.getDraftStats, teamSlug, filter, filters),
  )
  const draftMetaData = draftMetaDataData?.metadata

  const messages = useMemo(
    () => CabalMessage.fromArray(messageData?.pages?.flatMap((page) => page.messages)),
    [messageData?.pages],
  )

  useEffect(() => {
    if (messages?.length === 0 || hash != '') setShowPostMessage(false)
  }, [hash, messages])

  const handleClose = () => {
    setShowPostMessage(false)
    reloadMessages()
  }

  const handleSend = () => {
    setShowPostMessage(false)
    reloadMessages()
  }

  const messageListProps = {
    isLoading,
    isRefetching,
    isFetchingNextPage,
    onReloadMessages: reloadMessages,
    onCompose: compose,
    showPostMessage: showPostMessage,
  }

  const handleChangeTab = (index: number, tab?: string) => {
    history.push(`/${teamSlug}/drafts/${tab}`)
  }

  // const tabs: Tab[] = []

  // tabs.push({
  //   id: 'recent',
  //   component: () => <></>,
  //   label: `All (${draftMetaData?.all_drafts_count || 0})`,
  // })

  // tabs.push({
  //   id: 'shared-with-me',
  //   component: () => <></>,
  //   label: `Shared with me (${draftMetaData?.shared_with_me_count || 0})`,
  //   disabled: !draftMetaData?.shared_with_me_count,
  // })

  // tabs.push({
  //   id: 'drafted-for-others',
  //   component: () => <></>,
  //   label: `Drafted for others (${draftMetaData?.drafted_for_others_count || 0})`,
  //   disabled: !draftMetaData?.drafted_for_others_count,
  // })

  // const activeIndex = tabs.findIndex((t) => t?.id === tab)

  const usersAndMembers: SelectOption<string>[] = compact(
    flatten([
      advisors?.map((a) => ({
        label: `${a.name} - ${a.email}`,
        value: `advisor:${a.uuid}`,
        group: 'Members',
      })),
      team?.admins_and_members?.map((a) => ({
        label: `${a.name} - ${a.email}`,
        value: `user:${a.uuid}`,
        group: 'Employees',
      })),
    ]),
  )

  const usersAndMembersAndGroups: SelectOption<string>[] = compact(
    flatten([
      usersAndMembers,
      groups.map((g) => ({ label: g.name, value: `group:${g.uuid}`, group: 'Group' })),
    ]),
  )

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: [
      {
        key: 'senders',
        label: 'Sender',
        type: 'dropdown',
        options: usersAndMembers,
      },
      {
        key: 'recipients',
        label: 'Recipient',
        type: 'dropdown',
        options: usersAndMembersAndGroups,
      },
      {
        key: 'creators',
        label: 'Creator',
        type: 'dropdown',
        options: usersAndMembers,
      },
    ],
    value: filters,
    onChange: setFilters,
  })

  return (
    <div>
      <CabalTitle title="Drafts" />

      <div>
        <Subtabs rightActions={<></>} noPaddingAndHeight={false} />

        {isLoading ? (
          <Loading className="pt-8" />
        ) : !!draftMetaData?.all_drafts_count || !!messages.length ? (
          <div>
            <DraftMessageList messages={messages} {...messageListProps} />
            {messages?.length === 0 && (!!filter.recipient_uuids || !!filter.sender_uuids) && (
              <Typography>Couldn{`'`}t find drafts matching this query</Typography>
            )}
          </div>
        ) : (
          <EmptyState
            image={
              <Typography fontSize="24" color="fog">
                <i className="far fa-inbox"></i>
              </Typography>
            }
            heading="Create your first draft"
            body={
              <div className="text-center">
                <Typography textAlign="center" component="h5" className="mb-11">
                  {`When you're ready, click Compose to send an ask or update.`}
                </Typography>

                <div className="flex justify-center">
                  <CabalButton
                    variant="primary"
                    leftIcon={<i className="far fa-plus"></i>}
                    onClick={() => compose({ onHide: reloadMessages })}
                  >
                    Compose
                  </CabalButton>
                </div>
              </div>
            }
          />
        )}

        {((!isLoading && !isRefetching) || isFetchingNextPage) && hasNextPage && (
          <div className="flex justify-center	my-6">
            <CabalButton
              variant="secondary"
              disabled={isFetchingNextPage}
              onClick={() => fetchNextPage()}
            >
              Load more
            </CabalButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default Drafts
