import React from 'react'
import Pill from 'global/Pill'
import { AdvisorModel } from 'utils/types'
import styled from 'styled-components'
import tw from 'twin.macro'
import Typography from 'global/Typography'

interface Props {
  groups: AdvisorModel['groups']
  showCount?: boolean
}

const Wrapper = styled.div`
  ${tw`rounded`}
  background: ${({ theme }) => theme.colors.add_bg};
  padding: 3px 4px;
`

const AdvisorGroups: React.VFC<Props> = ({ groups, showCount = false }) => {
  return (
    <div className="flex items-center space-x-1">
      {groups.map((group) =>
        group.name === 'All' ? null : (
          <Wrapper key={group.uuid}>
            <Typography
              color={'purple'}
              fontFamily={'mono'}
              fontSize="12"
              lineHeight={1.32}
              component="p"
            >
              {group.name}
            </Typography>
          </Wrapper>
        ),
      )}
    </div>
  )
}

export default AdvisorGroups
