import { useMutation } from 'react-query'

import api, { callApi } from 'utils/api'

import { IUpdateCompany } from '../types/IUpdateCompany'

const updateCompanyFn = async ({ description, companySlug }: IUpdateCompany) => {
  return callApi(api.updateTeam, companySlug, {
    team: {
      description: description,
    },
  })
}

export const useUpdateTeamMutation = (teamSlug: string | undefined) =>
  useMutation({
    mutationKey: ['updateTeam', teamSlug],
    mutationFn: ({ description, companySlug }: IUpdateCompany) =>
      updateCompanyFn({ description, companySlug }),
    retry: 1,
  })
