import React, { useState } from 'react'

import {
  Active,
  CollisionDetection,
  DndContext,
  DraggableAttributes,
  DraggableSyntheticListeners,
  DropAnimation,
  KeyboardCoordinateGetter,
  MeasuringConfiguration,
  Modifiers,
  MouseSensor,
  PointerActivationConstraint,
  ScreenReaderInstructions,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import {
  AnimateLayoutChanges,
  NewIndexGetter,
  SortableContext,
  SortingStrategy,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import { Item } from './Item'

export interface SortableProps {
  activationConstraint?: PointerActivationConstraint
  animateLayoutChanges?: AnimateLayoutChanges
  adjustScale?: boolean
  collisionDetection?: CollisionDetection
  coordinateGetter?: KeyboardCoordinateGetter
  Container?: (args: any) => React.ReactElement // To-do: Fix me
  dropAnimation?: DropAnimation | null
  getNewIndex?: NewIndexGetter
  handle?: boolean
  itemCount?: number
  items: UniqueIdentifier[]
  setItems: (items: UniqueIdentifier[]) => void
  measuring?: MeasuringConfiguration
  modifiers?: Modifiers
  renderItem: (props: {
    id: UniqueIdentifier
    handleAttributes: DraggableAttributes & DraggableSyntheticListeners
  }) => React.ReactElement
  removable?: boolean
  reorderItems?: typeof arrayMove
  strategy?: SortingStrategy
  style?: React.CSSProperties
  useDragOverlay?: boolean
  getItemStyles?(args: {
    id: UniqueIdentifier
    index: number
    isSorting: boolean
    isDragOverlay: boolean
    overIndex: number
    isDragging: boolean
  }): React.CSSProperties
  wrapperStyle?(args: {
    active: Pick<Active, 'id'> | null
    index: number
    isDragging: boolean
    id: UniqueIdentifier
  }): React.CSSProperties
  isDisabled?(id: UniqueIdentifier): boolean
}

const defaultInitializer = (index: number) => index

export function createRange<T = number>(
  length: number,
  initializer: (index: number) => any = defaultInitializer,
): T[] {
  return [...new Array(length)].map((_, index) => initializer(index))
}

export function Sortable({
  activationConstraint,
  Container = (args) => <div {...args} />,
  collisionDetection = closestCenter,
  items,
  setItems,
  measuring,
  modifiers = [restrictToVerticalAxis, restrictToWindowEdges],
  removable,
  renderItem,
  reorderItems = arrayMove,
  strategy = verticalListSortingStrategy,
}: SortableProps) {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(TouchSensor, {
      activationConstraint,
    }),
  )
  const getIndex = (id: UniqueIdentifier) => items.indexOf(id)
  const activeIndex = activeId ? getIndex(activeId) : -1
  const handleRemove = removable
    ? (id: UniqueIdentifier) => setItems(items.filter((item) => item !== id))
    : undefined

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetection}
      onDragStart={({ active }) => {
        if (!active) {
          return
        }

        setActiveId(active.id)
      }}
      onDragEnd={({ over }) => {
        setActiveId(null)

        if (over) {
          const overIndex = getIndex(over.id)
          if (activeIndex !== overIndex) {
            setItems(reorderItems(items, activeIndex, overIndex))
          }
        }
      }}
      onDragCancel={() => setActiveId(null)}
      measuring={measuring}
      modifiers={modifiers}
    >
      <div>
        <SortableContext items={items} strategy={strategy}>
          <Container>
            {items.map((value) => (
              <Item id={value} key={value} renderItem={renderItem} />
            ))}
          </Container>
        </SortableContext>
      </div>
    </DndContext>
  )
}
