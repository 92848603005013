import React, { useState } from 'react'

import { SeeMore } from 'containers/CandidateProfile'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'

import { cn } from 'utils/styles'
import { UserProfile } from 'utils/types'

interface Props {
  profile: UserProfile
}

const ProfileHeader: React.FC<Props> = (props) => {
  const { name, title, avatar_url, linkedin_url, twitter_url } = props.profile
  let location: React.ReactNode = props.profile.location

  const headlineLength = title?.length || 0
  const [isExpanded, setIsExpanded] = useState(headlineLength < 165 || false)
  const headline = isExpanded ? title : `${title?.slice(0, 165)}`

  if (!!location) {
    location = (
      <Typography>
        <i className="far fa-location-dot mr-2" />
        {location}
      </Typography>
    )
  }

  return (
    <>
      <div className="mt-6 flex items-center justify-between">
        <div
          className={cn('flex ', {
            'items-end': headlineLength < 165,
            'items-start': headlineLength > 165,
          })}
        >
          <div className="flex flex-shrink-0">
            <Avatar src={avatar_url} name={name} size={'72px'} />
          </div>
          <div className="flex flex-col ml-3">
            <div className="flex items-baseline gap-2">
              <Typography lineHeight={1.25} fontSize="36" fontWeight={600}>
                {name}
              </Typography>
              <Typography fontSize="20" color="purple" lineHeight={1}>
                <i className="fa fa-badge-check fa-fw -mr-1" />
              </Typography>
              {linkedin_url && (
                <Typography
                  fontSize="20"
                  className="cursor-pointer"
                  color="fog"
                  onClick={() => window.open(linkedin_url)}
                >
                  <i className="fab fa-linkedin"></i>
                </Typography>
              )}
              {twitter_url && (
                <Typography
                  fontSize="20"
                  className="cursor-pointer"
                  color="fog"
                  onClick={() => window.open(twitter_url)}
                >
                  <i className="fab fa-square-x-twitter"></i>
                </Typography>
              )}
            </div>
            {title && (
              <p className="leading-5">
                <Typography fontSize="16" color="fog" lineHeight={1} className="mt-1">
                  {headline}
                </Typography>
                {title.length > 165 && (
                  <SeeMore
                    className="ml-1 cursor-pointer"
                    color="fog"
                    fontSize="16"
                    lineHeight={1}
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {isExpanded ? '...see less' : '...see more'}
                  </SeeMore>
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileHeader
