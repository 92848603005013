import React from 'react'

import { TippyProps } from '@tippyjs/react'

import cx from 'classnames'
import styled from 'styled-components'

import LazyTippy from './LazyTippy'

import 'tippy.js/dist/tippy.css'

interface StyledTippyProps {
  variant: 'info' | 'warning' | 'danger'
}

export interface Props {
  label: React.ReactNode
  variant?: StyledTippyProps['variant']
  className?: string
  children: React.ReactNode
  delay?: TippyProps['delay']
  interactive?: boolean
  zIndex?: number
  placement?: TippyProps['placement']
  trigger?: TippyProps['trigger']
  appendTo?: HTMLElement
  onShow?: TippyProps['onShow']
  onHide?: TippyProps['onHide']
}

const Tooltip: React.FC<Props> = ({
  children,
  label,
  variant = 'info',
  className = 'inline-flex',
  delay,
  interactive,
  zIndex,
  placement,
  trigger,
  onShow,
  onHide,
  appendTo = window.CABAL_EXTENSION_ROOT ?? undefined,
}) => {
  if (!label) return children

  return (
    <LazyTippy
      className={cx(variant)}
      content={<>{label}</>}
      delay={delay}
      interactive={interactive}
      zIndex={zIndex}
      onShow={onShow}
      onHide={onHide}
      placement={placement}
      trigger={trigger}
      appendTo={appendTo}
    >
      {/*
        need this in case `children`doesn't render a valid HTML element
        we can have a better solution for this in future (maybe check if HTML is rendered otherwise wrap in this div)
      */}
      <Container className={cx(className)}>{children}</Container>
    </LazyTippy>
  )
}

const Container = styled.div``

export default React.memo(Tooltip)
