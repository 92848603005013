import React, { useEffect, useMemo } from 'react'

import Cookies from 'js-cookie'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import CTAs from 'containers/AddConnectionsPage/CTAs'
import ImportUpload from 'containers/AddConnectionsPage/ImportUpload'
import SyncStatus from 'containers/AddConnectionsPage/SyncStatus'
import { Login } from 'containers/LoginPage'
import UserDropDown from 'containers/NavHome/UserDropDown'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { useCurrentUser, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { Team } from 'utils/types'

import BgImage from '../../../../public/desktopnew-bg.png'
import NoiseDark from '../../../../public/noise-dark.png'
import Logo from '../../images/logo.svg'
import Header from './Header'
import ImportSteps from './ImportSteps'
import ShareSection from './ShareSection'

const Background = styled.div<{ hideBg?: boolean }>`
  background-color: ${({ hideBg }) => !hideBg && '#0e151f'};
  background-image: ${({ hideBg }) => !hideBg && `url(${BgImage}), url(${NoiseDark})`};
  background-repeat: no-repeat, repeat;
  background-size: 100% auto, auto;
`
export const Card = styled.div`
  ${tw`mt-12 p-2 sm:p-4 mx-1 rounded-xl sm:w-[520px] sm:mx-auto`}
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 6px 18px -17px rgba(92, 105, 209, 0.75);
  border: 1px solid ${({ theme }) => theme.colors.widget_border};
`

interface Props {
  onContinue?: () => void
  onBack?: () => void
  memberOnboarding?: boolean
}

const AddConnectionsPage: React.FC<Props> = ({ onContinue, onBack, memberOnboarding }) => {
  const teamSlug = useTeamSlug()
  const history = useHistory()
  const { isLoggedIn, user } = useCurrentUser()
  const [showUpload, setShowUpload] = React.useState(false)
  const [popup, setPopup] = React.useState<Window | null>(null)
  const [syncStatus, setSyncStatus] = React.useState<'complete' | 'syncing' | 'normal'>('normal')
  const [showSteps, setShowSteps] = React.useState(false)
  const [teams, setTeams] = React.useState<Partial<Team>[]>([])
  const [reminderSent, setReminderSent] = React.useState(false)

  const { refetch: refetchTeams, isLoading: isLoadingTeams } = useQuery(
    ['getHomeTeams'],
    () => callApi(api.getHomeTeams),
    {
      onSuccess: ({ teams }) => {
        setTeams(teams)
      },
    },
  )

  const { data: connectionStatus, refetch: checkHomeConnectionStatus } = useQuery(
    ['connectionStatus'],
    () => callApi(api.getHomeConnectionsStats),
    {
      refetchInterval: 1000 * 3,
      refetchIntervalInBackground: !window.DISABLE_CONNECTION_STATUS_POLLING,
    },
  )

  const connections_count =
    connectionStatus?.connections_count || connectionStatus?.linkedin_export?.count || 0
  // const google_contacts_count = connectionStatus?.google_contacts?.count || 0

  useEffect(() => {
    if (!isLoggedIn) {
      Cookies.set('return_to', window.location.href)
    } else {
      Cookies.remove('return_to')
    }
  }, [isLoggedIn])

  const scheduleReminder = () => {
    if (reminderSent) return
    setReminderSent(true)
    api.scheduleUploadReadyReminder(teamSlug, !teamSlug)
  }

  const openPopup = () => {
    if (popup && !popup.closed) {
      popup.focus()
    } else {
      const popupWindow = window.open(
        '/add_connections',
        '_blank',
        `height=${window.screen.availHeight || '1000'},width=480,left=${
          window.screen.availWidth - 480
        }`,
      )
      setPopup(popupWindow)
      scheduleReminder()
    }
  }

  const showLoading = isLoadingTeams

  return (
    <Background className="overflow-auto w-full h-screen pb-16" hideBg={!!onContinue}>
      <div className="max-w-[990px] mx-auto flex flex-col gap-4">
        {!isLoggedIn && (
          <div>
            <div className="flex justify-between items-center w-full p-6 pb-0">
              <div className="flex items-center cursor-pointer" onClick={() => history.push('/')}>
                <img src={Logo} alt="Cabal" height="32" width="27" />
                <Typography
                  fontWeight={600}
                  fontSize="20"
                  color="white"
                  className="ml-2.5 tracking-tighter"
                >
                  Cabal
                </Typography>
              </div>
            </div>
            <div className="mt-10">
              <Header teamSlug={teamSlug} isLoggedIn={false} />

              <div className="w-96 mx-auto mt-12">
                <Login />
              </div>
            </div>
          </div>
        )}
        {isLoggedIn && (
          <>
            {!onContinue && (
              <div className="flex justify-between items-center w-full p-6 pb-0">
                <div className="flex items-center cursor-pointer" onClick={() => history.push('/')}>
                  <img src={Logo} alt="Cabal" height="32" width="27" />
                  <Typography
                    fontWeight={600}
                    fontSize="20"
                    color="white"
                    className="ml-2.5 tracking-tighter"
                  >
                    Cabal
                  </Typography>
                </div>
                <UserDropDown />
              </div>
            )}

            <div>
              {connectionStatus && (
                <>
                  {showLoading && (
                    <div className="h-10 mt-40 flex items-center justify-center transform">
                      <Loading />
                    </div>
                  )}

                  {!showLoading && (
                    <Card className="transition">
                      <Header
                        teamSlug={teamSlug}
                        isLoggedIn={true}
                        totalConnections={connections_count}
                        onBack={onBack}
                      />
                      <SyncStatus
                        connectionStatus={connectionStatus}
                        syncStatus={syncStatus}
                        onSyncComplete={() => setSyncStatus('complete')}
                        totalConnections={connections_count}
                        teamSlug={teamSlug}
                        teams={teams}
                        onShowUpload={() => {
                          openPopup()
                          setShowUpload(true)
                          setShowSteps(true)
                        }}
                      />
                      <ImportSteps
                        teamSlug={teamSlug}
                        showSteps={showSteps}
                        totalConnections={connections_count}
                      />
                      <ImportUpload
                        showUpload={showUpload}
                        syncStatus={syncStatus}
                        totalConnections={connections_count}
                        teamSlug={teamSlug}
                        teams={teams}
                        onStartSyncing={() => {
                          setSyncStatus('syncing')
                          setShowUpload(false)
                          checkHomeConnectionStatus()
                        }}
                      />
                      <CTAs
                        showUpload={showUpload}
                        teamSlug={teamSlug}
                        syncStatus={syncStatus}
                        totalConnections={connections_count}
                        teams={teams}
                        refetchTeams={refetchTeams}
                        onShowUpload={() => {
                          openPopup()
                          setShowUpload(true)
                        }}
                        onContinue={onContinue}
                      />
                    </Card>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </Background>
  )
}

export default AddConnectionsPage
