import React from 'react'

import ListHeader from 'containers/ListIndexView/Components/ListHeader'
import { usePortfolioListContext } from 'containers/ListIndexView/PortfolioList'
import PortfolioListCTAs from 'containers/ListIndexView/PortfolioList/PortfolioListCTAs'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'

import { joinReactElements } from 'utils/array'

const getDescriptionArray = (
  itemCount: number | undefined,
  description: string | undefined,
  owningTeam: any,
): React.ReactNode[] => {
  const descriptionArray: React.ReactNode[] = [
    <div className="flex items-center justify-center gap-1" key="team-owner-info">
      <Avatar src={owningTeam.logo} size="16" />
      <Typography fontSize="14" color="fog" className="truncate">
        {owningTeam.name}
      </Typography>
    </div>,
  ]

  if (itemCount) {
    descriptionArray.push(<Typography key="item-count">{itemCount} items</Typography>)
  }

  if (description) {
    descriptionArray.push(<Typography key="description">{description}</Typography>)
  }

  return descriptionArray
}

const PortfolioListHeader = () => {
  const { companyListData } = usePortfolioListContext()
  const {
    name: listName,
    owning_team: owningTeam,
    description,
    item_count: itemCount,
  } = companyListData
  const descriptionPart = getDescriptionArray(itemCount, description, owningTeam)

  return (
    <ListHeader
      title={listName}
      // logo={owningTeam.logo}

      // Should joinReactElements just be a part of the ListHeader component and pass the descriptionPart as a prop?
      description={joinReactElements(
        descriptionPart?.map((part, idx) => {
          return (
            <Typography className="truncate" key={idx}>
              {part}
            </Typography>
          )
        }),
        () => (
          <Typography className="mx-1.5">{'·'}</Typography>
        ),
      )}
    />
  )
}

export default PortfolioListHeader
