import React, { useEffect } from 'react'

import { useMutation } from 'react-query'
import styled from 'styled-components'

import { Sortable } from 'components/Sortable'
import CabalButton from 'global/CabalButton'
import { ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { List, Team } from 'utils/types'

const StyledBanner = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.cardBackground};
  color: ${({ theme }) => theme.colors.fog};

  &:hover {
    background-color: ${({ theme }) => theme.pill.purple_filled.primary_bg};
    color: ${({ theme }) => theme.pill.purple_filled.primary_text};
  }
`

interface Props {
  companyLists: List[]
  teamSlug: string
}

const PublishedLists: React.FC<Props> = ({ teamSlug, companyLists }) => {
  const { team, reloadTeam } = useTeam(teamSlug)
  const [lists, setLists] = React.useState(companyLists)
  const [listUuids, setListUuids] = React.useState<string[] | undefined>([])
  const listOrder = team?.home_list_uuids

  useEffect(() => {
    const desiredOrder = listOrder

    if (!desiredOrder || !lists) {
      setLists([])
    } else {
      const orderedLists = lists.sort(
        (a, b) => desiredOrder.indexOf(a.uuid) - desiredOrder.indexOf(b.uuid),
      )
      setLists(orderedLists)
    }
  }, [])

  const updateTeamMutation = useMutation(
    ['updateTeam'],
    (val: Partial<Team>) => callApi(api.updateTeam, teamSlug, { team: val }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'List order updated' })
        reloadTeam()
      },
    },
  )

  return (
    <div>
      <ModalSectionWrapper>
        <Typography fontSize="14">
          Drag and drop to rearrange the order of your published Lists
        </Typography>
      </ModalSectionWrapper>
      <div>
        <Sortable
          items={lists.map((l) => l.uuid)}
          renderItem={({ id, handleAttributes }) => {
            const l = lists.find((l) => l.uuid === id) as List
            return (
              <StyledBanner
                key={l.uuid}
                className="rounded-full px-2 py-1 my-1 flex items-center cursor-grab active:cursor-grabbing"
                {...handleAttributes}
              >
                {l.name}
              </StyledBanner>
            )
          }}
          setItems={(items) => {
            const newLists = items.map((id) => lists.find((l) => l.uuid === id)) as List[]
            setLists(newLists)
            const list_uuids = newLists.map((item) => item?.uuid) || ['']
            setListUuids(list_uuids)
          }}
        />
      </div>
      <div className="my-4 flex justify-end">
        <CabalButton
          working={updateTeamMutation.isLoading}
          onClick={() =>
            updateTeamMutation.mutateAsync({
              data: {
                home_list_uuids: listUuids,
              },
            })
          }
        >
          Save
        </CabalButton>
      </div>
    </div>
  )
}

export default PublishedLists
