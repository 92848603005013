import React, { useEffect, useMemo, useRef, useState } from 'react'
import Expand from 'react-expand-animated'
import { useHotkeys } from 'react-hotkeys-hook'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import AddJobModal from 'containers/Portfolio/AdminView/AddJobModal'
import EditCompanyModal from 'containers/Portfolio/AdminView/EditCompanyModal'
import { useAccessControl } from 'global/AccessControl'
import Card from 'global/Card'
import { ChecklistItem } from 'global/Input/Checklist'
import { useModal } from 'global/Modal'
import Pill from 'global/Pill'
import Typography from 'global/Typography'
import { useAdvisors, useCurrentUser } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { AdvisorModel, CompanyListType, EmailSnippet } from 'utils/types'
import { InvestorCompany, InvestorCompanyJob } from 'utils/types/investor'

import CompanyListTab from './CompanyListTab'
import InvestorCompaniesTab from './InvestorCompaniesTab'
import JobsTab from './JobsTab'
import MembersTab from './MembersTab'
import Snippets from './Snippets'

interface TabProps {
  title: string
  active: boolean
  tabComponent: React.ReactNode
  onClick: () => void
  shortcut: string[]
  icon: string
}

const Wrapper = styled.div`
  & .shortcut {
    display: none;
  }
  &:hover {
    & .shortcut {
      display: inline-block;
    }
  }
`
export const TabHeader: React.VFC<TabProps> = ({
  title,
  active,
  tabComponent,
  onClick,
  shortcut,
  icon,
}) => {
  return (
    <Card padding={1} data-testid={`composer-${title}-tab`}>
      <Wrapper className="flex justify-between items-center px-1 cursor-pointer" onClick={onClick}>
        <div className="flex items-center">
          <Typography fontSize="12">
            <i className={`far fa-${icon} mr-2`}></i>
          </Typography>
          <Typography fontSize="12" fontWeight={600} lineHeight={2.25}>
            {title}
          </Typography>
        </div>
        <div>
          {shortcut.map((c) => (
            <Pill className="mr-1 shortcut" key={c} variant="purple_filled">
              {c}
            </Pill>
          ))}
          {active ? (
            <Typography fontSize="12" color="rain">
              <i className="fas fa-chevron-down "></i>
            </Typography>
          ) : (
            <Typography fontSize="12" color="rain">
              <i className="fas fa-chevron-right "></i>
            </Typography>
          )}
        </div>
      </Wrapper>
      <Expand open={active}>
        <div className="my-2">{active ? tabComponent : null}</div>
      </Expand>
    </Card>
  )
}

interface Props {
  onSelectSnippet: (snippet: EmailSnippet) => void
  teamSlug: string
}

const InsertTabs: React.VFC<Props> = ({ onSelectSnippet, teamSlug }) => {
  const {
    user: { uuid: userUuid },
  } = useCurrentUser()

  const { showModal } = useModal()
  const { canViewPortfolio, canEditPortfolio, isAdminOrEmployee } = useAccessControl(teamSlug)

  const { advisors, isAdvisorLoaded } = useAdvisors({ teamSlug })

  const [jobs, setJobs] = useState<InvestorCompanyJob[]>([])
  const [companies, setCompanies] = useState<InvestorCompany[]>()
  const [allTags, setAllTags] = useState<string[]>([])
  const [tabTitle, setTabTitle] = useState('')
  const [toggle, setToggle] = useState(true)
  const [allJobTags, setAllJobTags] = useState<string[]>([])

  const { isFetching: isFetchingJobTags } = useQuery(
    ['getJobTags'],
    () => callApi(api.getJobTags, teamSlug),
    {
      onSuccess: ({ tags }) => {
        setAllJobTags(tags)
      },
    },
  )

  const refresh = (job: InvestorCompanyJob) => {
    if (job) {
      const updatedJobs = jobs.map((j) => {
        if (j.uuid === job.uuid) {
          return job
        }
        return j
      })
      setJobs(updatedJobs)
    }
  }

  const companiesRef = useRef(companies)

  useEffect(() => {
    companiesRef.current = companies
  }, [companies])

  useHotkeys(
    'i',
    (e) => {
      e.preventDefault()
      setToggle(!toggle)
    },
    [toggle],
  )

  useHotkeys(
    'shift+j',
    (e) => {
      e.preventDefault()
      setToggle(true)
      setTabTitle('jobs')
    },
    [toggle],
  )

  useHotkeys(
    'shift+p',
    (e) => {
      e.preventDefault()
      setToggle(true)
      setTabTitle('portfolio')
    },
    [toggle],
  )

  useHotkeys(
    'shift+m',
    (e) => {
      e.preventDefault()
      setToggle(true)
      setTabTitle('members')
    },
    [toggle],
  )

  useHotkeys(
    'shift+l',
    (e) => {
      e.preventDefault()
      setToggle(true)
      setTabTitle('lists')
    },
    [toggle],
  )

  const { isLoading: isLoadingCompanies, isFetching: isFetchingCompanies } = useQuery(
    ['getInvestorCompanies', teamSlug],
    () => callApi(api.getInvestorCompanies, teamSlug),
    {
      onSuccess: ({ companies, tags }) => {
        setCompanies(companies)
        setAllTags(tags)
      },
      enabled: canViewPortfolio,
    },
  )

  const { isLoading: isLoadingJobs, isFetching: isFetchingJobs } = useQuery(
    ['getAllInvestorCompanyJobs', teamSlug],
    () => callApi(api.getAllInvestorCompanyJobs, teamSlug),
    {
      onSuccess: ({ jobs }) => {
        setJobs(jobs)
      },
      enabled: canViewPortfolio,
    },
  )

  const { data: getCompanyListsData, isFetching: isFetchingLists } = useQuery(
    ['getCompanyLists', teamSlug],
    () => callApi(api.getCompanyLists, teamSlug, true),
  )
  const companyLists = getCompanyListsData?.company_lists

  const openAddCompanyModal = () => {
    showModal(
      (resolve) => (
        <EditCompanyModal
          teamSlug={teamSlug}
          allTags={allTags}
          setCompanies={setCompanies}
          companiesRef={companiesRef}
          setAllTags={setAllTags}
          resolve={(company) => {
            company && setCompanies([...(companies || []), company])
            resolve()
          }}
        />
      ),
      'add-investor-company-modal',
    )
  }

  const openAddJobModal = () => {
    showModal(
      (resolve) => (
        <AddJobModal
          companies={companies || []}
          teamSlug={teamSlug}
          resolve={resolve}
          onAdd={(job) => {
            job && setJobs([...(jobs || []), job])
            resolve()
          }}
          allTags={allJobTags}
          setAllTags={setAllJobTags}
          isFetchingJobTags={isFetchingJobTags}
          refresh={refresh}
        />
      ),
      'add-investor-company-job-modal',
    )
  }

  const onInsertCompany = (companyItems: ChecklistItem[]) => {
    let toInsert = ``
    companyItems
      .filter((c) => c.checked)
      .forEach((company, i, arr) => {
        const { company_name, uuid, description } = companies?.find(
          (c) => c.uuid === company.value,
        ) as InvestorCompany
        const requestUrl = `${window.location.origin}/investor-companies/${uuid}/intro-requests/confirm?team_slug=${teamSlug}`
        let descriptionElement = ''
        if (description) descriptionElement = `${description}<br>`
        toInsert += `
            <li>
              <b>${company_name}</b><br>
              ${descriptionElement}
              <a href="${requestUrl}">Request Intro</a>${i !== arr.length - 1 ? '<br>' : ''}
            </li>
        `
      })

    onSelectSnippet({
      body: toInsert,
      needsParaWrap: true,
    })
  }

  const onInsertCompanyLists = (companyLists: CompanyListType[]) => {
    let toInsert = ``
    companyLists.forEach((cl) => {
      toInsert += `
        <b>${cl.name}</b>
      `
      if (cl.company_list_items.length > 0) {
        toInsert += `
          – <a href="https://getcabal.com/${teamSlug}/asks?list_uuid=${cl.uuid}">see all</a>
        `
      }
      cl.company_list_items.slice(0, 5).forEach((cli) => {
        toInsert += `
          <li>
            ${!!cli.domain ? `<a href="${cli.domain}">` : ''}${cli.name}${
          !!cli.domain ? `</a>` : ''
        }
          </li>
        `
      })
    })

    onSelectSnippet({
      body: toInsert,
      needsParaWrap: true,
    })
  }

  const onInsertJob = (jobItems: ChecklistItem[]) => {
    let toInsert = ``
    jobItems
      .filter((j) => j.checked)
      .forEach((job: ChecklistItem, i, arr) => {
        const {
          title,
          description,
          uuid,
          url,
          investor_company: { company_name, uuid: companyUuid },
        } = jobs.find((j) => j.uuid === job.value) as InvestorCompanyJob

        const requestUrl = `${window.location.origin}/investor-companies/${companyUuid}/intro-requests/confirm?job_uuid=${uuid}&team_slug=${teamSlug}`
        const descriptionElement = description ? `${description}<br>` : ''
        const roleElement = url ? `<a href="${url}">${title}</a>` : title
        toInsert += `
              <li>
                <b>${company_name} - ${roleElement}</b><br>
                ${descriptionElement}
                <a href="${requestUrl}">Request Intro</a>${i !== arr.length - 1 ? '<br>' : ''}
              </li>
          `
      })

    onSelectSnippet({
      body: toInsert,
      needsParaWrap: true,
    })
  }

  const onInsertMember = (candidateItems: ChecklistItem[]) => {
    let toInsert = ``
    candidateItems
      .filter((a) => a.checked)
      .forEach((advisor: ChecklistItem, i, arr) => {
        const { name, linkedin_url, uuid } = advisors?.find(
          (a) => a.uuid === advisor.value,
        ) as AdvisorModel

        const requestUrl = `${window.location.origin}/advisors/${uuid}/intro-requests/confirm?team_slug=${teamSlug}&email_sent_by=${userUuid}`

        toInsert += `
          <li>
            <b>${name}</b><br>
            ${linkedin_url ? '<a href="' + linkedin_url + '">View LinkedIn</a><br>' : ''}
            <a href="${requestUrl}">Request Intro</a>${i !== arr.length - 1 ? '<br>' : ''}
          </li>
          `
      })

    onSelectSnippet({
      body: toInsert,
      needsParaWrap: true,
    })
  }

  const activeJobs = useMemo(() => jobs.filter((j) => !j.archived), [jobs])

  if (!canEditPortfolio && !companyLists?.length) return <></>

  return (
    <div className="my-2">
      {canEditPortfolio && (
        <>
          <TabHeader
            title="Jobs"
            active={tabTitle === 'jobs'}
            icon={'briefcase'}
            tabComponent={
              <JobsTab
                jobs={activeJobs}
                isLoadingJobs={isLoadingJobs}
                isFetchingJobs={isFetchingJobs}
                onInsertJob={onInsertJob}
                teamSlug={teamSlug}
                openAddJobModal={openAddJobModal}
              />
            }
            onClick={() => (tabTitle === 'jobs' ? setTabTitle('') : setTabTitle('jobs'))}
            shortcut={['SHIFT', 'J']}
          />

          <TabHeader
            title="Portfolio"
            active={tabTitle === 'portfolio'}
            icon={'folder-open'}
            tabComponent={
              <InvestorCompaniesTab
                companies={companies || []}
                isLoadingCompanies={isLoadingCompanies}
                onInsertCompany={onInsertCompany}
                isFetchingCompanies={isFetchingCompanies}
                teamSlug={teamSlug}
                openAddCompanyModal={openAddCompanyModal}
              />
            }
            onClick={() => (tabTitle === 'portfolio' ? setTabTitle('') : setTabTitle('portfolio'))}
            shortcut={['SHIFT', 'P']}
          />
          <TabHeader
            title="Members"
            active={tabTitle === 'members'}
            icon={'users'}
            tabComponent={
              <MembersTab
                advisors={advisors || []}
                isAdvisorLoaded={isAdvisorLoaded}
                onInsertMember={onInsertMember}
              />
            }
            onClick={() => (tabTitle === 'members' ? setTabTitle('') : setTabTitle('members'))}
            shortcut={['SHIFT', 'M']}
          />
        </>
      )}
      <TabHeader
        title="Snippets"
        active={tabTitle === 'snippets'}
        icon={'cubes'}
        tabComponent={<Snippets onSelectSnippet={onSelectSnippet} teamSlug={teamSlug} />}
        onClick={() => (tabTitle === 'snippets' ? setTabTitle('') : setTabTitle('snippets'))}
        shortcut={['SHIFT', 'J']}
      />
      {isAdminOrEmployee && (
        <TabHeader
          title="Lists"
          active={tabTitle === 'lists'}
          icon={'list-ul'}
          tabComponent={
            <CompanyListTab
              companyLists={companyLists || []}
              isFetching={isFetchingLists}
              onInsert={onInsertCompanyLists}
              teamSlug={teamSlug}
            />
          }
          onClick={() => (tabTitle === 'lists' ? setTabTitle('') : setTabTitle('lists'))}
          shortcut={['SHIFT', 'L']}
        />
      )}
    </div>
  )
}

export default React.memo(InsertTabs)
