import DOMPurify from 'dompurify'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { IterableElement } from 'type-fest'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

import { Team } from 'utils/types'

import Logo from '../../images/logo.svg'

export const ContentWrap = styled.div`
  ${tw`flex flex-col`}
  background: ${({ theme }) => theme.layout.main_bg_color};
`

const Container = styled.div`
  ${tw`font-medium  m-auto text-base`}
  max-width: 28rem;
  font-family: 'Inter', sans-serif;
  color: ${({ theme }) => theme.colors.primary};
`

const LogoContainer = styled.div`
  ${tw`text-center mb-6`}
`

const SignatureWrap = styled.span`
  display: block;
  overflow: hidden;
`

const Signature = styled.img`
  max-width: 186px;
  max-height: 65px;
  margin-bottom: 1rem;
  margin-top: 1rem;
`

const SignatureDummy = styled.span`
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: block;
`

const ButtonContainer = styled.div`
  ${tw`mt-16 leading-4 text-center w-full`}
`

const FooterContainer = styled.div`
  ${tw`inset-x-0 mx-auto mt-auto text-center w-32`}
`

const FooterLogo = styled.img`
  ${tw`inline-block mb-2`}

  width: 14px;
  height: 16px;
`

export interface AcceptContainerProps {
  invitePageMessage: string
  isPreview?: boolean
  agreementUuid?: string
  logo?: string
  companyName: string
  sender: IterableElement<Team['admins_and_members']>
}

const AcceptContent = (props: AcceptContainerProps) => {
  const { agreementUuid, isPreview = false, logo, companyName, invitePageMessage, sender } = props
  const history = useHistory()

  const handleEnter = () => {
    if (!isPreview) {
      history.push(`/sign/${agreementUuid}`)
    }
  }

  return (
    <ContentWrap>
      <Container>
        <LogoContainer>
          {logo && <Avatar round size="64px" src={logo} name={companyName} />}
        </LogoContainer>
        <div>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(invitePageMessage) }} />
          <br />
          {sender && (
            <p>
              Sincerely,
              {sender.signature ? (
                <SignatureWrap>
                  <Signature alt="Signature" src={`/api/uploads/${sender.signature}`} />
                </SignatureWrap>
              ) : (
                <SignatureDummy />
              )}
              {sender.avatar_url && (
                <Avatar
                  className="mr-2"
                  round
                  size="48px"
                  src={sender.avatar_url}
                  name={sender.name}
                />
              )}
              {sender.name}
              {!!sender.title && `, ${sender.title}`}
            </p>
          )}
        </div>
        <ButtonContainer>
          <CabalButton onClick={handleEnter} className="w-full" variant="primary">
            Sign and Enter
          </CabalButton>
          <Typography fontSize="12" component="div" className="mt-4">
            Cabal is a private workspace for founders and their backers
          </Typography>
        </ButtonContainer>
      </Container>
      <FooterContainer>
        <FooterLogo src={Logo} alt="Cabal" height="32" width="27" />
        <Typography color="primary" component="div" fontSize="14" letterSpacing="0.19em">
          CABAL
        </Typography>
      </FooterContainer>
    </ContentWrap>
  )
}

export default AcceptContent
