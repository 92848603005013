import axios from 'axios'

import { ResourcePropFilters } from 'containers/Resources/PageTemplate'

import { Offer, Tag } from 'utils/types'

export default {
  getOffers: (team_slug: string, offerTypeSlug?: string, filters?: ResourcePropFilters) => {
    return axios.get<{ offers: Offer[]; tags: string[]; categories: string[] }>(`/api/offers`, {
      params: {
        team_slug,
        offerTypeSlug,
        filters,
      },
    })
  },

  getOffer: (team_slug: string, uuid: string) => {
    return axios.get<{ offer: Offer }>(`/api/offers/${uuid}`, {
      params: {
        team_slug,
      },
    })
  },

  createOffer: (team_slug: string, offer: Offer) => {
    return axios.post<{ offer: Offer }>(`/api/offers`, {
      team_slug,
      offer,
    })
  },

  createOfferReview: (uuid: string, review) => {
    return axios.post(`/api/offers/${uuid}/create_review`, {
      review,
    })
  },

  updateOffer: (team_slug: string, uuid: string, offer: Offer) => {
    return axios.put<{ offer: Offer }>(`/api/offers/${uuid}`, {
      team_slug,
      offer,
    })
  },

  deleteOffer: (team_slug: string, uuid: string) => {
    return axios.delete(`/api/offers/${uuid}`, {
      params: {
        team_slug,
      },
    })
  },

  markOfferAsPinned: (team_slug: string, uuid: string) => {
    return axios.post(`/api/offers/${uuid}/mark_as_pinned`, {
      params: {
        team_slug,
      },
    })
  },

  markOfferAsUnpinned: (team_slug: string, uuid: string) => {
    return axios.post(`/api/offers/${uuid}/mark_as_unpinned`, {
      params: {
        team_slug,
      },
    })
  },

  proposeOffer: (team_slug: string, proposal: Offer) => {
    return axios.post(`/api/offers/propose`, {
      team_slug,
      proposal,
    })
  },

  getOfferTags: (team_slug: string) => {
    return axios.get<{ tags: string[] }>(`/api/offers/tags`, {
      params: {
        team_slug,
      },
    })
  },

  getOfferCategories: (team_slug: string) => {
    return axios.get<{ categories: string[] }>(`/api/offers/categories`, {
      params: {
        team_slug,
      },
    })
  },
}
