import React, { useEffect, useMemo, useState } from 'react'

import cx from 'classnames'
import compact from 'lodash/compact'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import { useMutation, useQuery } from 'react-query'
import { useSetState } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'

import useCrmStatus from 'components/CrmStatus'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import { MultiSelect, Select, SelectOption, TextInput } from 'global/Input'
import Loading from 'global/Loading'
import Modal, { ModalableComponentProps, useModal } from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useAdvisors, useGroups, useTeam } from 'store/hooks'
import InfoTooltip from 'ui-components/InfoTooltip'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import copyToClipboard from 'utils/copyToClipboard'
import {
  AdvisorModel,
  CompanyListAccessorBlueprint,
  CompanyListBlueprint,
  GroupModel,
  TeamListAccessorOption,
  TeamUser,
} from 'utils/types'

import AddAccessorModal from './AddAccessorModal'

const AccessorWrapper = styled.div`
  ${tw`rounded flex items-center justify-between px-3`}
  border: ${({ theme }) => theme.border};
  height: 42px;
`

interface Props {
  show?: boolean
  uuid: string
  reload?: () => void
  onDone?: () => void
  teamSlug: string
  header?: React.ReactNode
  animateOnMount?: boolean
}

export type AccessorEntityMap = Record<
  string,
  {
    type: string
    group: string
    value: GroupModel | AdvisorModel | TeamUser | TeamListAccessorOption
    label: string
    uuid: string
  } & (
    | {
        type: 'Group'
        group: 'Groups'
        value: GroupModel
      }
    | {
        group: 'Members'
        type: 'Advisor'
        value: AdvisorModel
      }
    | {
        group: 'Users'
        type: 'User'
        value: TeamUser
      }
    | {
        group: 'Teams'
        type: 'Team'
        value: TeamListAccessorOption
      }
  )
>

const ListSharingSettings: React.FC<Props & ModalableComponentProps> = ({
  teamSlug,
  resolve,
  uuid,
  reload,
  modal = true,
  header,
  onDone,
  animateOnMount,
}) => {
  const { showModal } = useModal()
  const { team } = useTeam(teamSlug)
  const { canSetupDealBoard } = useAccessControl(teamSlug)
  const { advisors } = useAdvisors({ teamSlug: teamSlug })
  const { groups: allGroups } = useGroups(teamSlug, true)
  const { data: users } = useQuery(['getTeamUsers', teamSlug], () =>
    callApi(api.getTeamUsers, teamSlug),
  )
  const [list, setList] = useSetState<CompanyListBlueprint>()
  const [shareTeams, setShareTeams] = useState<TeamListAccessorOption[]>([])
  const [permission, setPermission] = useState('')
  const [initialLoad, setInitialLoad] = useState(true)
  const { salesforceConnected, hubspotConnected } = useCrmStatus(teamSlug)
  const showListSubscriptions = list.list_category !== 'portfolio'

  const { isLoading: loadingList, refetch: refetchAccess } = useQuery(
    ['company_list_access', teamSlug, uuid],
    () =>
      api.getCompanyListAccess(teamSlug, uuid).then(({ data: company_list }) => {
        if (initialLoad) {
          setList({ unsaved: false, ...company_list })
          setInitialLoad(false)
        } else {
          setList({
            ...list,
            unsaved: false,
            company_list_accessors: company_list.company_list_accessors,
          })
        }
      }),
  )

  useQuery(['share_teams'], () =>
    api.getShareTeams(teamSlug).then((resp) => {
      setShareTeams(resp.data.teams)
    }),
  )

  const { mutate: updateCompanyListAccess, isLoading } = useMutation(
    ['updateCompanyListAccess'],
    () => callApi(api.updateCompanyListAccess, teamSlug, list),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Access updated' })
        reload?.()
      },
    },
  )

  useEffect(() => {
    const published = list?.published
    const linkAccess = list?.access_via_link
    if (published) {
      setPermission('all_members')
    }

    if (!published && linkAccess) {
      setPermission('with_link')
    }

    if (!published && !linkAccess) {
      setPermission('specific_selection')
    }

    if (list?.unsaved) {
      updateCompanyListAccess()
    }
  }, [list, updateCompanyListAccess])

  const accessorEntityMap = useMemo(() => {
    const map: AccessorEntityMap = {}

    if (list?.owned) {
      ;(shareTeams || []).forEach((team) => {
        map[`Team:${team.slug}`] = {
          group: 'Teams',
          type: 'Team',
          value: team,
          label: `${team.name} · Team`,
          uuid: team.slug,
        }
      })
    }

    ;(allGroups || []).forEach((group) => {
      map[`Group:${group.uuid}`] = {
        group: 'Groups',
        type: 'Group',
        value: group,
        label: `${group.name} · Group ${
          isNumber(group.advisor_groups_count) ? `(${group.advisor_groups_count})` : ''
        }`,
        uuid: group.uuid,
      }
    })
    ;(advisors || []).forEach((advisor) => {
      map[`Advisor:${advisor.uuid}`] = {
        type: 'Advisor',
        group: 'Members',
        value: advisor,
        label: compact([`${advisor.name}`, advisor.email]).join(' - ') + ' · Member',
        uuid: advisor.uuid,
      }
    })
    ;(users?.users || []).forEach((user) => {
      map[`User:${user.uuid}`] = {
        group: 'Users',
        type: 'User',
        value: user,
        label: compact([user.name, user.email]).join(' - ') + ' · User',
        uuid: user.uuid,
      }
    })

    return map
  }, [advisors, allGroups, users?.users, list?.owned, shareTeams])

  const options = useMemo(() => {
    const opts: SelectOption<string>[] = []

    for (const key in accessorEntityMap) {
      const option = accessorEntityMap[key]

      opts.push({
        value: key,
        label: option.label,
        group: option.group,
      })
    }

    return opts
  }, [accessorEntityMap])

  const baseSubscriberOptions = useMemo(() => {
    if (salesforceConnected) {
      return [
        {
          label: 'Account Owner',
          value: 'account_owner',
        },
        {
          label: 'Opportunity Owner',
          value: 'opportunity_owner',
        },
      ]
    } else if (hubspotConnected) {
      return [
        {
          label: 'Company Owner',
          value: 'account_owner',
        },
        {
          label: 'Deal Owner',
          value: 'opportunity_owner',
        },
      ]
    } else {
      return []
    }
  }, [salesforceConnected, hubspotConnected])

  const subscriberOptions = useMemo(() => {
    return (
      ['companies', 'fundraise'].includes(list.list_type) ? baseSubscriberOptions : []
    ).concat(
      advisors?.map((a) => ({
        label: a.name,
        value: a.uuid,
      })) || [],
    )
  }, [advisors, baseSubscriberOptions, list.list_type])

  if (!canSetupDealBoard) {
    return <></>
  }

  const listAccessors = list?.company_list_accessors || []

  const removeAccessor = (accessor: CompanyListAccessorBlueprint) => {
    const newAccessors = list?.company_list_accessors?.filter(
      (a) => a.accessor_uuid !== accessor.accessor_uuid,
    )

    setList({
      unsaved: true,
      company_list_accessors: newAccessors,
    })
  }

  const permissionOptions = [
    {
      label: (
        <Typography fontSize="12">
          <i className="far fa-users mr-2 fa-sm"></i>
          Members
        </Typography>
      ),
      value: 'all_members',
    },
    {
      label: (
        <Typography fontSize="12">
          <i className="far fa-link mr-2 fa-sm"></i>
          Any member with link
        </Typography>
      ),
      value: 'with_link',
    },
    {
      label: (
        <Typography fontSize="12">
          <i className="far fa-lock mr-2 fa-sm"></i>
          Specific groups, people, and teams
        </Typography>
      ),
      value: 'specific_selection',
    },
  ]

  const handleListPermission = (value: string) => {
    if (value === 'all_members') {
      setList({
        unsaved: true,
        published: true,
      })
      setPermission('all_members')
    } else if (value === 'with_link') {
      setList({
        unsaved: true,
        published: false,
        access_via_link: true,
      })
      setPermission('with_link')
    } else if (value === 'specific_selection') {
      setList({
        unsaved: true,
        published: false,
        access_via_link: false,
      })
    }
  }

  const listSharingSettings = (
    <>
      {loadingList && <Loading />}
      {!loadingList && list && !isEmpty(list) && (
        <>
          <Select<string | null>
            removeBorder
            leftActions={
              <Typography fontSize="13">
                <i className="far fa-search" />
              </Typography>
            }
            noDropdownIndicator
            autoFocus
            placeholder="Add people, groups or teams"
            data-testid="share-list-access-select"
            className={'w-full mt-3'}
            hideSelectedOptions={false}
            controlShouldRenderValue={false}
            onChange={(accessorKey) => {
              if (!accessorKey) return

              showModal(
                (resolve) => (
                  <AddAccessorModal
                    teamSlug={teamSlug}
                    onAdd={() => {
                      cabalToast({ style: 'success', content: 'Access updated' })
                      refetchAccess()
                    }}
                    accessorEntityMap={accessorEntityMap}
                    options={options}
                    onHide={resolve}
                    list={list}
                    accessorKey={accessorKey}
                  />
                ),
                'add-accessor-modal',
              )
            }}
            options={options}
          />
          <ModalSectionWrapper>
            <ModalInputWrapper>
              <ModalInputLabel>People with access</ModalInputLabel>
              <div className="w-full">
                <AccessorWrapper>
                  <div>
                    <Avatar
                      name={list.owned && list.user ? list.user.name : list.owning_team.name}
                      src={
                        list.owned && list.user ? list.user.avatar_url : list.owning_team.logo_url
                      }
                      size="24"
                      round
                      className="mr-2"
                    />
                    <Typography fontSize="12">
                      {list.owned ? list.user?.name : list.owning_team.name}
                    </Typography>
                  </div>
                  <div>
                    <Typography color={'fog'} fontSize="12">
                      Owner
                    </Typography>
                  </div>
                </AccessorWrapper>

                {listAccessors.map((accessor) => {
                  const accessorKey = `${accessor.accessor_type}:${accessor.accessor_uuid}`
                  const accessorEntity = accessorEntityMap[accessorKey]
                  const entity = accessorEntity?.value

                  if (!accessorEntity) return <></>

                  return (
                    <AccessorWrapper key={accessorKey} className="mt-2">
                      <div>
                        <Avatar
                          name={entity?.name}
                          src={get(entity, 'avatar_url', get(entity, 'logo', null))}
                          size="24"
                          round
                          className="mr-2"
                        />
                        <Typography fontSize="12">
                          {entity?.name} {'email' in entity && `(${entity.email})`}
                        </Typography>
                        <Tooltip label={accessorEntity.group}>
                          <Typography fontSize="12" className="ml-2" color="fog_rain">
                            <i
                              className={cx('fas', {
                                'fa-user-cowboy': accessorEntity.type === 'User',
                                'fa-user-vneck': accessorEntity.type === 'Advisor',
                                'fa-user-group': accessorEntity.type === 'Group',
                                'fa-building': accessorEntity.type === 'Team',
                              })}
                            />
                          </Typography>
                        </Tooltip>
                      </div>

                      <div>
                        <DropDownMenu
                          menuItems={[
                            {
                              label: 'Remove',
                              onSelect: () => removeAccessor(accessor),
                            },
                          ]}
                          trigger={
                            <Typography color={'fog'} fontSize={'12'}>
                              {accessor.accessor_type === 'Team' ? 'can view + share' : 'can view'}{' '}
                              <i className="fas fa-chevron-down fa-sm ml-1" />
                            </Typography>
                          }
                        />
                      </div>
                    </AccessorWrapper>
                  )
                })}
              </div>
            </ModalInputWrapper>

            <ModalInputWrapper>
              <ModalInputLabel>General access</ModalInputLabel>
              <div>
                <Select
                  options={permissionOptions}
                  value={permission}
                  onChange={(v) => v && handleListPermission(v)}
                  placeholder="Select list permissions"
                  className="w-full"
                  lightBg
                />
                <Typography fontSize="12" color="fog">
                  {permission === 'all_members' && `All ${team?.name} members can view this list`}
                  {permission === 'with_link' && `Anyone with the link can view this list`}
                  {permission === 'specific_selection' &&
                    `Only the people selected will see the list`}
                </Typography>
              </div>
            </ModalInputWrapper>

            {showListSubscriptions && (
              <ModalInputWrapper>
                <ModalInputLabel>
                  Notifications
                  <InfoTooltip className="ml-2">
                    <Typography lineHeight={1.2} fontSize="13" className="pb-1" component="p">
                      People who will receive notifications for Offers from this List.
                    </Typography>
                  </InfoTooltip>
                </ModalInputLabel>
                <MultiSelect
                  options={subscriberOptions}
                  value={list.list_subscriptions || []}
                  onChange={(v) => setList({ unsaved: true, list_subscriptions: v })}
                  placeholder="Add people and groups to notify"
                  data-testid="share-list-notifications-multi-select"
                />
              </ModalInputWrapper>
            )}

            {!modal && list?.list_url !== '' && (
              <ModalInputWrapper className="py-1">
                <ModalInputLabel>Copy link</ModalInputLabel>
                <div className="flex w-full space-x-2">
                  <CabalButton
                    className="flex-shrink-0"
                    variant="secondary"
                    leftIcon={<i className="far fa-link"></i>}
                    onClick={() => {
                      copyToClipboard(list?.list_url)
                      cabalToast({
                        content: 'Copied link to clipboard',
                        style: 'success',
                      })
                    }}
                  >
                    Copy link
                  </CabalButton>
                  <TextInput value={list?.list_url} className={'w-full'} />
                </div>
              </ModalInputWrapper>
            )}
          </ModalSectionWrapper>
        </>
      )}
    </>
  )

  if (modal) {
    return (
      <Modal
        animateOnMount={animateOnMount}
        show
        onHide={resolve!}
        header={header || `Share '${list?.name}'`}
        leftActions={
          <>
            {list && list.list_url !== '' && (
              <CabalButton
                className="flex-shrink-0"
                variant="secondary"
                leftIcon={<i className="far fa-link"></i>}
                onClick={() => {
                  copyToClipboard(list?.list_url)
                  cabalToast({
                    content: 'Copied link to clipboard',
                    style: 'success',
                  })
                }}
              >
                Copy link
              </CabalButton>
            )}
          </>
        }
        rightActions={
          <CabalButton
            onClick={() => {
              resolve?.()
              onDone?.()
            }}
          >
            Done
          </CabalButton>
        }
      >
        {listSharingSettings}
      </Modal>
    )
  } else {
    return <div className="pb-2">{listSharingSettings}</div>
  }
}

export default ListSharingSettings
