import axios from 'axios'

import { IImportData } from 'containers/ListIndexView/PortfolioList/PortfolioAddWidget/ImportSection'

import { CompaniesFiltersType } from 'utils/types'

export default {
  getJob: (currentlistUuid: string, uuid: string, teamSlug: string) => {
    return axios.get(`/api/v2/jobs/${uuid}`, {
      params: { team_slug: teamSlug, current_list_uuid: currentlistUuid },
    })
  },
  getJobsV2: (
    teamSlug: string,
    currentlistUuid: string,
    page = 1,
    filters?: CompaniesFiltersType,
    params = {},
  ) => {
    return axios.get('/api/v2/jobs', {
      params: {
        team_slug: teamSlug,
        page,
        filters: JSON.stringify(filters),
        current_list_uuid: currentlistUuid,
        ...params,
      },
    })
  },
  getJobsForAdd: (
    currentlistUuid: string,
    teamSlug: string,
    page = 1,
    filters?: CompaniesFiltersType,
  ) => {
    return axios.get(`/api/v2/jobs/jobs_for_add`, {
      params: {
        team_slug: teamSlug,
        page: page,
        current_list_uuid: currentlistUuid,
        filters: JSON.stringify(filters),
      },
    })
  },
  deleteJob: (uuid: string, teamSlug: string, currentlistUuid: string) => {
    return axios.delete(`/api/v2/jobs/${uuid}`, {
      params: { team_slug: teamSlug, current_list_uuid: currentlistUuid },
    })
  },
  jobsImport: (uploadUrl: string, uploadUuid: string, currentlistUuid: string) => {
    return axios.post(`/api/v2/jobs/import`, {
      upload_url: uploadUrl,
      upload_uuid: uploadUuid,
      current_list_uuid: currentlistUuid,
    })
  },
  addJob: (jobUuid: string, currentlistUuid: string) => {
    return axios.post(`/api/v2/jobs/add`, {
      job_uuid: jobUuid,
      current_list_uuid: currentlistUuid,
    })
  },
  jobsImportStatus: (importJobId: number, currentlistUuid: string) => {
    return axios.get<IImportData>(`/api/v2/jobs/import_status?import_job_id=${importJobId}`, {
      params: { current_list_uuid: currentlistUuid },
    })
  },
  createJob: (job: any, currentlistUuid: string | undefined, teamSlug: string) => {
    const params = { job: job, current_list_uuid: currentlistUuid, team_slug: teamSlug }
    return axios.post('/api/v2/jobs', params)
  },
  updateJob: (job: any, currentlistUuid: string | undefined, teamSlug: string) => {
    const params = { job: job, current_list_uuid: currentlistUuid, team_slug: teamSlug }
    return axios.put(`/api/v2/jobs/${job.uuid}`, params)
  },
  jobCompanies: (currentlistUuid: string, teamSlug: string) => {
    return axios.get(`/api/v2/jobs/companies`, {
      params: { team_slug: teamSlug, current_list_uuid: currentlistUuid },
    })
  },
  memberPortfolioCompany: (teamSlug: string) => {
    return axios.get(`/api/v2/jobs/member_portfolio_company`, {
      params: { team_slug: teamSlug },
    })
  },
  sharedJobs: (teamSlug: string) => {
    return axios.get(`/api/v2/jobs/shared_jobs`, {
      params: { team_slug: teamSlug },
    })
  },
  jobsBulkActions: (currentlistUuid: string, params: any) => {
    return axios.put(`/api/v2/jobs/bulk_actions`, {
      current_list_uuid: currentlistUuid,
      ...params,
    })
  },
  jobLists: (teamSlug: string) => {
    return axios.get(`/api/v2/jobs/lists`, {
      params: { team_slug: teamSlug },
    })
  },
}
