import React from 'react'

import { MainSection } from 'containers/SignupScreen/MarketingSite'

import AdvisorProgram from '../../SolutionsAssets/advisorprogram.png'
import CustomerPortal from '../../SolutionsAssets/customerportal.png'
import InverstorUpdater from '../../SolutionsAssets/investorupdates.png'
import WarmIntros from '../../SolutionsAssets/warmintros.png'
import Footer from '../Footer'
import LayoutSection from '../LayoutSection'
import Nav from '../Nav'
import { CompanyLink } from '../styles'

const CompaniesPage = () => {
  React.useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1)

      const element = document.getElementById(hash)
      if (element) {
        const overflowWrapper = document.getElementById('company-page-container')
        overflowWrapper?.scrollBy({
          top: (element?.getBoundingClientRect().top || 0) - 75,
          behavior: 'smooth',
        })
      }
    }

    handleHashChange()
  }, [])

  return (
    <div className="h-screen antialiased">
      <MainSection
        className="w-full h-full overflow-scroll bg-[#0E151F]"
        id="company-page-container"
      >
        <Nav />

        <section className="px-8 mt-10 mb-10">
          <div className="block max-w-6xl mx-auto">
            <h2 className="text-[#5c69d1] text-base ">For Companies</h2>
            <p className="text-white tracking-tighter font-light text-3xl sm:text-5xl mt-5">
              Turn your supporters into an extension of your team
            </p>
          </div>
        </section>
        <div className="bg-[#0B1017] px-8">
          <LayoutSection
            title="Warm intros"
            description="Get warm intros to prospects to accelerate deals"
            image={WarmIntros}
            id="warm-intros"
            cta={
              <a
                className=" text-[#5c69d1] hover:text-[#8A97FF] cursor-pointer tansition-all duration-300 ease-in-out"
                href="/resources"
                target="_blank"
                rel="noopener noreferrer"
              >
                Explore target lists
                <i className="far fa-arrow-right fa-sm ml-4"></i>
              </a>
            }
          />
          <LayoutSection
            title="Investor updates"
            description="Write investor updates that inspire action"
            image={InverstorUpdater}
            id="investor-updates"
            cta={
              <a
                className=" text-[#5c69d1] hover:text-[#8A97FF] cursor-pointer tansition-all duration-300 ease-in-out"
                href="/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Template
                <i className="far fa-arrow-right fa-sm ml-4"></i>
              </a>
            }
          />

          <LayoutSection
            title="Advisor program"
            description="Turn your company into a network of stakeholders"
            image={AdvisorProgram}
            id="advisor-program"
            cta={
              <a
                className=" text-[#5c69d1] hover:text-[#8A97FF] cursor-pointer tansition-all duration-300 ease-in-out"
                href="/blog/the-modern-advisor-program"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
                <i className="far fa-arrow-right fa-sm ml-4"></i>
              </a>
            }
          />
          <LayoutSection
            title="Company portal"
            description="Give your customers a private space to help and be heard"
            image={CustomerPortal}
            id="customer-portal"
            cta={
              <a
                className=" text-[#5c69d1] hover:text-[#8A97FF] cursor-pointer tansition-all duration-300 ease-in-out"
                href="/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                Build your portal
                <i className="far fa-arrow-right fa-sm ml-4"></i>
              </a>
            }
          />
          <Footer />
        </div>
      </MainSection>
    </div>
  )
}

export default CompaniesPage
