import styled from 'styled-components'

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${({ theme }) => theme.layout.nav_bg_color};
`

export { StickyHeader }
