import * as React from 'react'
import Avatar from 'global/Avatar'
import { OwnerUserProps } from '.'
import styled from 'styled-components'
import tw from 'twin.macro'
import Typography from 'global/Typography'

export interface Props {
  ownerUser?: OwnerUserProps[]
  ownerCompany?: {
    name: string
    url: string
  }
}

const Wrapper = styled.div`
  ${tw`rounded-full flex justify-center items-center flex-shrink-0`}
  background: ${({ theme }) => theme.colors.add_bg};
  width: 24px;
  height: 24px;
`

const Owners: React.VFC<Props> = ({ ownerUser, ownerCompany }) => {
  return (
    <div className="flex items-center space-x-1">
      {ownerUser && (
        <Avatar
          name={ownerUser[0].name}
          src={ownerUser[0].url}
          size="24"
          round
          className="flex-shrink-0"
        />
      )}

      {ownerUser && ownerUser.length === 2 && (
        <Avatar
          name={ownerUser[1].name}
          src={ownerUser[1].url}
          size="24"
          round
          className="flex-shrink-0"
        />
      )}

      {ownerUser && ownerUser.length > 2 && (
        <Wrapper>
          <Typography
            color={'purple'}
            fontFamily={'mono'}
            fontSize="12"
            lineHeight={1.32}
            component="p"
          >
            +{ownerUser?.length - 1}
          </Typography>
        </Wrapper>
      )}
      {ownerCompany && (
        <Avatar
          name={ownerCompany.name}
          src={ownerCompany.url}
          size="24"
          round
          className="flex-shrink-0"
        />
      )}
    </div>
  )
}

export default Owners
