import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

interface CardProps {
  icon: React.ReactNode
  title: string
  description: string
  onClick: () => void
  cta: string
  complete: boolean
  completeMsg: string
  disabled?: boolean
}

const Wrapper = styled.div<{ complete: boolean; disabled: boolean }>`
  ${tw`flex flex-col justify-between rounded-lg p-4`}
  background-color: ${({ theme, complete }) =>
    complete ? theme.layout.main_content_bg_color : theme.layout.main_bg_color};
  border: 2px solid ${({ theme }) => theme.layout.main_bg_color};

  ${({ complete, disabled, theme }) =>
    !complete &&
    !disabled &&
    `
    cursor: pointer;
    &:hover {
      border: 2px solid ${theme.colors.purple};
    }
  `}
`

const ActivityCard: React.VFC<CardProps> = ({
  icon,
  title,
  description,
  onClick,
  cta,
  complete,
  completeMsg,
  disabled = false,
}) => {
  return (
    <Wrapper
      complete={complete}
      disabled={disabled}
      onClick={!complete && !disabled ? onClick : undefined}
    >
      <Typography fontSize="24" color={complete ? 'gray100' : 'purple'}>
        {icon}
      </Typography>
      <div className="pt-7">
        <Typography
          color={complete ? 'fog' : 'inherit'}
          fontWeight={600}
          lineHeight={1}
          fontSize="14"
          className="pb-1"
          component="p"
        >
          {title}
        </Typography>
        <Typography fontSize="12" color={'fog'} component="p" lineHeight={1.4}>
          {description}
        </Typography>
        {complete && !disabled ? (
          <Typography className="mt-4" color={'green'} fontSize="12" component="p">
            <i className="far fa-check mr-1"></i>
            {completeMsg}
          </Typography>
        ) : !complete && !disabled ? (
          <Typography
            className="mt-4 flex items-center"
            color={'purple'}
            fontSize="12"
            component="button"
          >
            {cta}
            <i className="far fa-arrow-right fa-sm ml-1"></i>
          </Typography>
        ) : (
          <Typography className="mt-4" color={'fog'} fontSize="12" component="p">
            <i className="far fa-lock mr-1"></i>
            {'Request free trial'}
          </Typography>
        )}
      </div>
    </Wrapper>
  )
}

export default ActivityCard
