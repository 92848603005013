import { isEqual } from 'lodash'
import { useCallback, useState } from 'react'

function useDeepCompareState<S = undefined>(): [S | undefined, (v: S | undefined) => void]
function useDeepCompareState<S>(defaultValue: S): [S, (v: S) => void]

function useDeepCompareState<S>(defaultValue?: S) {
  const [state, _setState] = useState(defaultValue)

  const setState: (v: S) => void = useCallback(
    (v) => {
      if (!isEqual(v, state)) _setState(v)
    },
    [state, _setState],
  )

  return [state, setState]
}

export default useDeepCompareState
