import axios from 'axios'

import { IContactPointResponse, ICreateContactPointPayload } from 'utils/types/contactPoints'

export default {
  createContactPoint: (params: ICreateContactPointPayload) => {
    return axios.post<IContactPointResponse>(`/api/v2/contact_points`, params)
  },

  getContactPoints: (uuid: string, itemType: string) => {
    return axios.get<IContactPointResponse>(`/api/v2/contact_points`, {
      params: {
        uuid,
        contactable_type: itemType,
      },
    })
  },
  updateContactPoint: (uuid: string, params: ICreateContactPointPayload) => {
    return axios.put<IContactPointResponse>(`/api/v2/contact_points/${uuid}`, params)
  },
}
