import axios, { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { WINSITE_ENDPOINTS } from '../apiEndpoints'

const authenticateUser = async (companyDomain: string | undefined): Promise<AxiosResponse> => {
  return await axios.get(WINSITE_ENDPOINTS.authenticateUserForDomain, {
    params: { company_domain: companyDomain },
  })
}

export const useAuthenticateUser = (companyDomain: string | undefined) =>
  useQuery({
    queryKey: ['authenticateUser', companyDomain],
    queryFn: () => authenticateUser(companyDomain),
    retry: 0,
    enabled: !!companyDomain,
    onError: (error) => {
      console.log('Error occurred :: ', error)
    },
  })
