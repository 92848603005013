import React from 'react'

import Fade from 'react-reveal/Fade'
import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

export interface BannerWrapperProps {
  variant: 'primary' | 'secondary'
  rounded: boolean
  noMargin: boolean
}

const BannerWrapper = styled.div<BannerWrapperProps>`
  ${tw`flex justify-between px-3 py-2`}
  background: ${({ variant }) =>
    variant === 'primary' ? `linear-gradient(90deg, #5C69D1 0%, #8A97FF 100%);` : `transparent`};

  border: ${({ variant, theme }) =>
    variant === 'secondary' ? `1px dashed ` + theme.colors.banner_border : null};

  border-radius: ${({ rounded }) => rounded && '4px'};
  margin-bottom: ${({ noMargin }) => !noMargin && '16px'};
`

export interface Props extends Omit<React.HTMLProps<HTMLDivElement>, 'ref' | 'as'> {
  onDismiss?: () => void
  variant?: 'primary' | 'secondary'
  rounded?: boolean
  noMargin?: boolean
}

const Banner: React.FC<Props> = ({
  onDismiss,
  variant = 'primary',
  rounded = true,
  noMargin = false,
  children,
  ...props
}) => {
  return (
    <Fade duration={300}>
      <BannerWrapper noMargin={noMargin} rounded={rounded} variant={variant} {...props}>
        <div className="flex items-center flex-1">{children}</div>
        {onDismiss && (
          <div className="cursor-pointer flex items-center">
            <Typography
              fontSize="12"
              color={variant === 'primary' ? 'white' : 'banner_text'}
              onClick={onDismiss}
            >
              <i className="far fa-times"></i>
            </Typography>
          </div>
        )}
      </BannerWrapper>
    </Fade>
  )
}

export default Banner
