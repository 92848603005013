import React, { useState } from 'react'

import pick from 'lodash/pick'
import { UseQueryResult, useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useSetState } from 'react-use'

import CabalButton from 'global/CabalButton'
import { CheckBox, MultiSelect, Select } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug, useUpdateTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import team from 'utils/api/team'
import { EmailTemplate, MutationFunc, Team } from 'utils/types'

interface TeamParams {
  ideal_customer_profile?: string
  stage_changes_visible_to_advisors?: boolean
  default_facilitator_uuid?: string
  default_requestor_uuid?: string
}

interface Props {
  getTemplatesQuery: UseQueryResult<{
    templates: EmailTemplate[]
  }>
  updateTemplateMutation: MutationFunc<EmailTemplate>
}

const Defaults: React.FC<Props> = ({ getTemplatesQuery, updateTemplateMutation }) => {
  const teamSlug = useTeamSlug()
  const history = useHistory()
  const { team, reloadTeam } = useTeam(teamSlug)
  const [stageChangesVisibleToAdvisors, setStageChangesVisibleToAdvisors] = useState(
    team?.stage_changes_visible_to_advisors,
  )

  const ICPs = team?.ideal_customer_profile ? team?.ideal_customer_profile?.split(',') : undefined
  const templates = getTemplatesQuery.data?.templates
  const defaultTemplate = templates?.find((t) => t.default_for === 'member_intro_template')

  const handleTemplateSelection = (v: string) => {
    const selected: EmailTemplate | undefined = templates?.find((t) => t.uuid === v)
    if (selected) {
      selected.public_to_team = true
      selected.default_for = 'member_intro_template'
      updateTemplateMutation.mutate(selected)
    }
  }

  const { mutate: updateTeam, isLoading: teamUpdating } = useMutation(
    ['icpUpdate'],
    (teamParams: TeamParams) =>
      callApi(api.updateTeam, teamSlug, {
        team: teamParams,
      }).then(() => reloadTeam()),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Changes saved' })
      },
    },
  )

  return (
    <Widget title="Defaults">
      <div className="flex flex-col gap-4 pt-2 pb-4">
        {!team?.enable_portfolio && (
          <ModalInputWrapper>
            <ModalInputLabel>Requestor</ModalInputLabel>

            <Select
              value={team?.default_requestor_uuid || ''}
              onChange={(e) =>
                updateTeam({
                  default_requestor_uuid: e || '',
                })
              }
              isLoading={teamUpdating}
              options={
                team?.admins_and_members.map((user) => ({
                  label: `${user.name} - ${user.email}`,
                  value: user.uuid,
                })) || []
              }
            />
          </ModalInputWrapper>
        )}

        <ModalInputWrapper>
          <ModalInputLabel>Facilitator</ModalInputLabel>
          <Select
            value={team?.default_facilitator_uuid || ''}
            onChange={(e) =>
              updateTeam({
                default_facilitator_uuid: e || '',
              })
            }
            isLoading={teamUpdating}
            options={
              team?.admins_and_members.map((user) => ({
                label: `${user.name} - ${user.email}`,
                value: user.uuid,
              })) || []
            }
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel className="flex ">
            <span>Default Sales Intro Template</span>
            {defaultTemplate && (
              <CabalButton
                variant="link"
                onClick={() => {
                  history.push(`/${teamSlug}/templates/${defaultTemplate?.uuid}`)
                }}
                leftIcon={<i className="fa fa-pencil" />}
              ></CabalButton>
            )}
          </ModalInputLabel>
          <Select
            options={
              templates?.map((t) => ({
                label: t.name,
                value: t.uuid,
              })) || []
            }
            value={defaultTemplate?.uuid}
            onChange={(v) => v && handleTemplateSelection(v)}
            placeholder="Select default template "
            isLoading={updateTemplateMutation.isLoading}
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Default Target Personas</ModalInputLabel>

          <MultiSelect
            value={ICPs}
            onChange={(val) => {
              updateTeam({
                ideal_customer_profile: val.toString(),
              })
            }}
            className="text-sm"
            placeholder="Add ICPs like founder, advisor etc"
            formatCreateLabel={() => 'Add persona'}
            isClearable
            compactPlaceholder
            creatable
            options={[
              { label: 'Founder', value: 'Founder' },
              { label: 'Investor', value: 'Investor' },
              { label: 'Advisor', value: 'Advisor' },
              { label: 'VC', value: 'VC' },
              { label: 'Sales', value: 'Sales' },
              { label: 'Marketing', value: 'Marketing' },
            ]}
            isLoading={teamUpdating}
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>Default CRM stage visibility</ModalInputLabel>

          <CheckBox
            checked={stageChangesVisibleToAdvisors}
            onChange={(e) => {
              setStageChangesVisibleToAdvisors(e.currentTarget.checked)
              updateTeam({
                stage_changes_visible_to_advisors: e.currentTarget.checked,
              })
            }}
            label="Stage change activity visible to advisors"
            disabled={teamUpdating}
          />
        </ModalInputWrapper>
      </div>
    </Widget>
  )
}

export default Defaults
