import axios from 'axios'

import {
  CreateGlobalPersonRequest,
  GlobalCompanyBlueprint,
  GlobalPersonBlueprint,
} from 'utils/types'

export default {
  getPeople: (query: string) => {
    return axios.get<{ results: GlobalPersonBlueprint[] }>(`/api/people`, { params: { query } })
  },

  getPerson: (global_id: string, params = {}) => {
    return axios.get(`/api/people/${global_id}`, { params })
  },

  createPerson: (params: CreateGlobalPersonRequest) => {
    return axios.post<{ person: GlobalPersonBlueprint }>(`/api/people`, { global_person: params })
  },

  getSearchResults: (query: string, sources: ('global_people' | 'global_companies')[]) => {
    return axios.get<{ results: (GlobalPersonBlueprint | GlobalCompanyBlueprint)[] }>(
      `/api/search`,
      {
        params: { query, sources },
      },
    )
  },
}
