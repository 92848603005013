import React from 'react'

import { useHistory } from 'react-router'

import Activity from 'components/Activity'
import { useTeamSlug } from 'store/hooks'
import Widget from 'ui-components/Widget'

const ActivityWidget = () => {
  const history = useHistory()
  const teamSlug = useTeamSlug()
  return (
    <Widget
      title={<span className="hover:text-[#5C69D1]">Activity</span>}
      onTitleClick={() => history.push(`/${teamSlug}/activity`)}
    >
      <div className="-mx-4">
        <Activity />
      </div>
    </Widget>
  )
}

export default ActivityWidget
