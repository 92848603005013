import React, { useState, useEffect } from 'react'

import Modal from 'global/Modal'

import styled from 'styled-components'
import tw from 'twin.macro'
import { Divider } from 'components/SideNav/styles'
import { useTeams } from 'store/hooks'
import Typography from 'global/Typography'
import cx from 'classnames'
import { useKey } from 'react-use'
import { useHistory } from 'react-router-dom'
interface Props {
  show: boolean
  onHide: () => void
}

const SearchInput = styled.input`
  ${tw`w-full`}
  background-color: transparent;
  caret-color: ${({ theme }) => theme.colors.purple};
`

const ShortcutsModal: React.VFC<Props> = ({ show, onHide }) => {
  const history = useHistory()
  const { teams, isLoading } = useTeams()
  const [query, setQuery] = useState('')
  const [index, setIndex] = useState(0)
  useKey('ArrowDown', () => setIndex((index) => index + 1))
  useKey('ArrowUp', () => setIndex((index) => index - 1))

  console.log('Index is ', index)

  return (
    <Modal show={show} onHide={onHide} header="Control Pannel">
      <SearchInput
        value={query}
        placeholder="Type a command or search"
        onChange={(e) => setQuery(e.target.value)}
      />
      <Divider />
      {teams
        .filter((t) => t.name.includes(query))
        .map((team, i) => (
          <div
            key={team.slug}
            tabIndex={0}
            className={cx('cursor-pointer flex ', {
              'ring-sky-500 ring-1': index === i,
            })}
            onClick={() => history.push(`./${team.slug}`)}
            role="button"
          >
            <Typography>{team.name}</Typography>
          </div>
        ))}
    </Modal>
  )
}

export default ShortcutsModal
