// import Modal1 from './ModalV1'
import Modal, { useModal } from './Modal'

type OnSubmitType<T> = (value: T) => void
type ResolveType = () => void

export type ModalableComponentProps<T = unknown> = {
  modal?: boolean
  resolve?: ResolveType
  onSubmit?: OnSubmitType<T>
} & (
  | {
      modal?: true
      resolve: ResolveType
    }
  | {
      modal: false
      onSubmit?: OnSubmitType<T>
    }
)

export interface ModalComponentProps {
  resolve: () => void
}

export default Modal
export { useModal }
