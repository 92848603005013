import React from 'react'

import { useUnmount } from 'react-use'

import { PRESET_THEME } from 'containers/App/Providers/ColorModeProvider'
// import Lottie from 'react-lottie';
import Loading from 'global/Loading'

import useMobileApp from 'utils/hooks/useMobileApp'

// import animationData from './rocket-ship.json'

interface AppLoadingProps {
  theme?: 'light' | 'dark'
}

const AppLoading: React.VFC<AppLoadingProps> = ({ theme = PRESET_THEME }) => {
  const { isMobileApp, postMessage } = useMobileApp()
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice'
  //   }
  // };

  useUnmount(() => {
    if (isMobileApp) {
      postMessage({ type: 'pageLoaded' })
    }
  })

  const backgroundColor = theme === 'light' ? '#f3f4f6' : '#0E151F'

  return (
    <div className="w-full h-full flex justify-center items-center" style={{ backgroundColor }}>
      {/* <Lottie
        options={defaultOptions}
        height={200}
        width={200}
      /> */}
      <div className="w-full flex items-center justify-center h-[75vh]">
        <Loading size={60} />
      </div>
    </div>
  )
}

export default AppLoading
