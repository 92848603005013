import * as React from 'react'
import Typography from 'global/Typography'

export interface Props {
  description: string
}

const ExpandedDescription: React.VFC<Props> = ({ description }) => {
  return (
    <div className="">
      <Typography
        component="p"
        fontSize="12"
        lineHeight={'15px'}
        color={'fog'}
        // className="truncate"
      >
        {description}
      </Typography>
    </div>
  )
}

export default ExpandedDescription
