// process.env is weird
// if you need to use another ENV, add it to the switch statement below

const getProcessEnv = (name: 'EXTENSION' | 'NODE_ENV' | 'APPLICATION_HOST') => {
  let value: string | undefined
  try {
    switch (name) {
      case 'EXTENSION':
        value = process.env.EXTENSION
        break
      case 'NODE_ENV':
        value = process.env.NODE_ENV
        break
      case 'APPLICATION_HOST':
        value = process.env.APPLICATION_HOST
        break
      default:
        value = undefined
    }
  } catch (error) {}

  return value
}

export const isDevelopment = () => getProcessEnv('NODE_ENV') === 'development'
export const isProduction = () => getProcessEnv('NODE_ENV') === 'production'
export const isExtension = () => getProcessEnv('EXTENSION') === 'true'
export const isTest = () =>
  getProcessEnv('NODE_ENV') === 'test' || getProcessEnv('NODE_ENV') === 'none'
export const appUrl = () => getEnv('app_url')

const globalEnv: {
  useBackgroundScriptForApiCalls: boolean
  host: string
  app_url: string
  EXTENSION?: string
} = {
  useBackgroundScriptForApiCalls: false,
  host: isDevelopment() ? 'localhost' : 'getcabal.com',
  app_url:
    getProcessEnv('APPLICATION_HOST') ??
    (isDevelopment() ? `http://localhost:3000` : `https://getcabal.com`),
}

export const setEnv = <K extends keyof typeof globalEnv>(key: K, value: typeof globalEnv[K]) => {
  globalEnv[key] = value
}

export const getEnv = <K extends keyof typeof globalEnv>(key: K) => {
  return globalEnv[key]
}
