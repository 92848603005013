import React from 'react'

import cx from 'classnames'

import Typography, { TypographyProps } from '../Typography'

export interface LabelProps extends TypographyProps {
  label?: React.ReactNode
}

export const Label: React.FC<LabelProps> = ({ className, label, children, ...props }) => {
  if (label) {
    children = (
      <Typography
        fontSize="12"
        color="admin_label"
        component="label"
        className={cx('space-y-1.5 block', className)}
        {...props}
      >
        <span>{label}</span>
        {children}
      </Typography>
    )
  }

  return <>{children}</>
}

export * from './Checkbox'
export * from './SearchInput'
export * from './Select'
export * from './TextArea'
export * from './TextInput'
export * from './DatePicker'
export * from './DatePickerModal'
export * from './UploadButton'
export * from './AutoSuggest'
