import * as React from 'react'

import cx from 'classnames'
import { FieldProps } from 'formik'
import styled, { StyledComponentPropsWithRef } from 'styled-components'
import tw from 'twin.macro'

import Typography from '../Typography'

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const CheckboxContainer = styled.label`
  display: inline-flex;
  align-items: center;
  position: relative;

  input:checked + div {
    background: ${({ theme }) => theme.colors.purple};
    border-color: ${({ theme }) => theme.colors.purple};

    ${Icon} {
      visibility: visible;
    }
  }

  input:disabled + div {
    background: ${({ theme }) => theme.colors.gray};
  }
`

const HiddenCheckbox = styled.input<{ hide: boolean }>`
  ${({ hide }) =>
    hide &&
    `
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  `}
`

const StyledCheckbox = styled.div<{ checked?: boolean }>`
  ${tw`inline-block rounded`}
  width: 16px;
  height: 16px;
  background: ${({ checked, theme }) => (checked ? theme.colors.purple : theme.colors.primary_bg)};
  border-color: ${({ checked, theme }) => (checked ? theme.colors.purple : theme.colors.fog)};
  transition: all 150ms;
  border-width: 1.5px;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`

const RootCheckbox: React.VFC<
  StyledComponentPropsWithRef<'input'> & { wrappedInLabel: boolean }
> = ({ className, checked, wrappedInLabel, ...props }) => {
  const ref = React.useRef<HTMLInputElement>(null)

  return (
    <CheckboxContainer className={className} as={wrappedInLabel ? 'div' : 'label'}>
      <HiddenCheckbox ref={ref} checked={checked} {...props} hide={!window.QA} />

      {!window.QA && (
        <StyledCheckbox checked={checked}>
          <Icon viewBox="0 0 24 24">
            <polyline
              points="20 6 9 17 4 12"
              style={{ strokeWidth: '3px', fill: 'none', stroke: '#fff' }}
            />
          </Icon>
        </StyledCheckbox>
      )}
    </CheckboxContainer>
  )
}

export interface CheckBoxProps extends StyledComponentPropsWithRef<'input'> {
  label?: string | React.ReactNode
  comment?: string
}

export const CheckBox: React.VFC<CheckBoxProps> = ({
  label,
  comment,
  checked,
  className,
  ...extraProps
}) => {
  const checkbox = (
    <RootCheckbox
      className={cx(className && { [className]: !label })}
      type="checkbox"
      checked={checked}
      wrappedInLabel={!!label}
      {...extraProps}
    />
  )

  if (!label) return checkbox

  return (
    <label
      className={cx(
        'inline-flex items-center',
        className,
        extraProps.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      )}
    >
      {checkbox}
      {checked ? (
        <Typography color="primary" className="ml-2" fontSize="12">
          {label}
        </Typography>
      ) : (
        <Typography color="primary" fontSize="12" className="ml-2">
          {label}
        </Typography>
      )}
      <Typography color="secondary" fontSize="inherit" className="ml-2">
        {comment && <div className="text-sm mt-1">{comment}</div>}
      </Typography>
    </label>
  )
}

interface CheckBoxFieldProps extends CheckBoxProps {
  fieldProps: FieldProps
  customOnChange?: (checked: boolean) => void
}

export const CheckBoxField: React.FC<CheckBoxFieldProps> = ({
  fieldProps,
  customOnChange,
  ...props
}) => {
  return (
    <div className="flex flex-col items-stretch w-full">
      <CheckBox
        {...props}
        checked={fieldProps.field.value}
        onChange={(val) => {
          fieldProps.form.setFieldValue(fieldProps.field.name, val.currentTarget.checked)
          customOnChange && customOnChange(val.currentTarget.checked)
        }}
        onBlur={() => fieldProps.form.setFieldTouched(fieldProps.field.name, true)}
      />
      {fieldProps.meta.touched && fieldProps.meta.error && (
        <div className="flex justify-start pt-1">
          <Typography color="border_danger" fontSize="11">
            {fieldProps.meta.error}
          </Typography>
        </div>
      )}
    </div>
  )
}
