import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import AddAirtableAppModal from 'containers/Settings/Integration/AddExternalSourceModal'
import CabalButton from 'global/CabalButton'
import Card from 'global/Card'
import { Select } from 'global/Input'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import PageWrapper from 'global/PageWrapper'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { ExternalSource } from 'utils/types'

import RecentAudits from './RecentAudits'

const ViewExternalSource = () => {
  const { id, company_slug: teamSlug } = useParams<{ id: string } & CompanySlugParam>()
  const { showModal } = useModal()
  const [source, setSource] = useState<ExternalSource | null>(null)
  const [loading, setLoading] = useState(true)
  const [columnMap, setColumnMap] = useState({})
  const [fields, setFields] = useState([])

  const [columnMapLoading, setColumnMapLoading] = useState<string | null>(null)

  function onSync() {
    axios.post(`/api/external_sources/${id}/sync?team=${teamSlug}`).then((response) => {
      cabalToast({
        style: 'success',
        content: 'Syncing in background. Please give it a few minutes...',
      })
      loadData()
      setTimeout(loadData, 5000)
    })
  }

  function loadData() {
    axios.get(`/api/external_sources/${id}?team=${teamSlug}`).then((response) => {
      const { source: newSource, custom_fields } = response.data
      setSource(newSource)
      setColumnMap(newSource.column_map.column_map || {})

      let fieldOptions: any = [
        {
          label: '',
          value: '',
        },
      ]

      if (newSource.cabal_type == 'members') {
        fieldOptions = fieldOptions
          .concat([
            {
              label: 'First Name',
              value: 'first_name',
            },
            {
              label: 'Full Name',
              value: 'full_name',
            },
            {
              label: 'Last Name',
              value: 'last_name',
            },
            {
              label: 'Email Address',
              value: 'email',
            },
            {
              label: 'Groups',
              value: 'groups',
            },
          ])
          .concat(
            custom_fields.map((field) => ({
              label: `${field.name} (${field.value_type})`,
              value: `custom_${field.uuid}`,
            })),
          )
      } else if (newSource.cabal_type == 'portfolio_companies') {
        fieldOptions = fieldOptions.concat([
          {
            label: 'Company Name',
            value: 'company_name',
          },
        ])
      }

      setFields(fieldOptions)
      setLoading(false)
    })
  }

  useEffect(() => {
    loadData()
  }, [id])

  const { mutate: handleUpdate, isLoading: isUpdating } = useMutation(
    () =>
      callApi(
        api.updateExternalSource,
        { uuid: source?.uuid, column_map: { column_map: columnMap } },
        teamSlug,
      ),
    {
      onSuccess: ({ app }) => {
        cabalToast({ style: 'success', content: 'The external source was successfully updated!' })
        loadData()
      },
      onError: (error) => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  if (loading) {
    return (
      <div className="pt-24">
        <Loading />
      </div>
    )
  }

  if (!source) {
    return <div className="pt-24 text-center">Not found</div>
  }

  return (
    <PageWrapper title={'External Source: Airtable'}>
      <Card className="my-2 mb-8">
        {source.provider == 'airtable' && (
          <CabalButton
            className="float-right"
            leftIcon={<i className="fa fa-pencil pr-1" />}
            variant="secondary"
            onClick={() => {
              showModal(
                (resolve) => (
                  <AddAirtableAppModal
                    source={source}
                    teamSlug={teamSlug}
                    resolve={() => {
                      loadData()
                      resolve()
                    }}
                  />
                ),
                'edit-airtable-app-modal',
              )
            }}
          >
            Edit
          </CabalButton>
        )}
        {source.provider == 'airtable' && (
          <CabalButton
            className="float-right mr-2"
            leftIcon={<i className="fa fa-share pr-1" />}
            variant="secondary"
            component="a"
            onClick={() =>
              window.open(`https://airtable.com/${source.app_id}`, '_blank', 'noopener')
            }
          >
            Airtable
          </CabalButton>
        )}
        <CabalButton
          className="float-right mr-2"
          leftIcon={<i className="fa fa-refresh pr-1" />}
          variant="secondary"
          onClick={() => onSync()}
        >
          Sync
        </CabalButton>
        {source.app_id && (
          <div>
            App ID: {source.app_id} <br />
          </div>
        )}
        Table Name: {source.table_name} <br />
        Cabal Type: {source.cabal_type} <br />
        Enabled: {source.enabled_at || 'No'} <br />
        {source.queued_at && <div>Queued: {source.queued_at}</div>}
        {source.synced_at && <div>Synced: {source.synced_at}</div>}
      </Card>
      {source.provider == 'airtable' && (
        <Card className="my-2">
          {source.data?.headers && (
            <table className="table table-stripe w-full">
              <thead>
                <th className="text-left">Airtable Column</th>
                <th className="text-left">Cabal Field</th>
                <th className="text-left">Example Data</th>
              </thead>
              <tbody>
                {source.data?.headers.map((headerName) => {
                  const exampleData = (source.data?.example_data || {})[headerName]
                  const exampleCol = JSON.stringify(exampleData || '')

                  let notSupported
                  if (typeof exampleData === 'string' && exampleData.substring(0, 3) == 'rec') {
                    notSupported = true
                  } else if (
                    Array.isArray(exampleData) &&
                    typeof exampleData[0] === 'string' &&
                    exampleData[0].substring(0, 3) == 'rec'
                  ) {
                    notSupported = true
                  } else if (Array.isArray(exampleData) && typeof exampleData[0] !== 'string') {
                    notSupported = true
                  }

                  return (
                    <tr
                      key={headerName}
                      className="border-t border-1 border-gray-400"
                      style={{
                        color: notSupported ? '#ccc' : 'inherit',
                      }}
                    >
                      <td className="py-1" width="33%">
                        {headerName}
                        {notSupported && <span className="ml-2">(Not Supported)</span>}
                      </td>
                      <td className="py-1" width="33%">
                        <Select
                          style={{ maxWidth: 200 }}
                          value={columnMap[headerName] || null}
                          onChange={(value) => {
                            let newColumnMap: any = { ...columnMap }
                            newColumnMap[headerName] = value
                            setColumnMap(newColumnMap)
                          }}
                          placeholder="Select Field"
                          removeBorder
                          options={fields}
                          disabled={notSupported}
                          creatable
                          isLoading={columnMapLoading == headerName}
                          onCreateOption={(value) => {
                            setColumnMapLoading(headerName)

                            api
                              .createCustomField(teamSlug, {
                                name: value,
                                value_type: 'text',
                                entity_type: 'Advisor',
                              })
                              .then((response) => {
                                const { field: newField } = response.data
                                let newFields = fields.concat([
                                  {
                                    label: `${newField.name} (${newField.value_type})`,
                                    value: `custom_${newField.uuid}`,
                                  },
                                ])
                                setFields(newFields)

                                let newColumnMap: any = { ...columnMap }
                                newColumnMap[headerName] = `custom_${newField.uuid}`
                                setColumnMap(newColumnMap)
                                setColumnMapLoading(null)
                              })
                          }}
                        />
                      </td>
                      <td className="py-1" width="33%">
                        <div
                          className="overflow-hidden"
                          style={{ maxWidth: 300 }}
                          title={exampleCol}
                        >
                          {exampleCol}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
          <CabalButton
            className="mt-2"
            onClick={() => handleUpdate()}
            variant="primary"
            disabled={isUpdating}
          >
            Save
          </CabalButton>
        </Card>
      )}
    </PageWrapper>
  )
}

export default ViewExternalSource
