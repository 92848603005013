import axios, { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { ICompanyListData } from 'components/Winsite/types/ICompanyPersonaList'

import { WINSITE_ENDPOINTS } from '../apiEndpoints'

const fetchLists = async (teamSlug: string | undefined): Promise<AxiosResponse> => {
  return await axios.get<ICompanyListData>(WINSITE_ENDPOINTS.fetchLists, {
    params: { team_slug: teamSlug },
  })
}

export const useFetchLists = (teamSlug: string | undefined) =>
  useQuery({
    queryKey: ['fetch_lists', teamSlug],
    queryFn: () => fetchLists(teamSlug),
    enabled: !!teamSlug,
    retry: 1,
  })
