import * as React from 'react'

import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import { ModalContext, useModal } from 'global/Modal/Context'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'

import { TeamSettingProps } from '..'
import AddAgreementTemplateModal from './AddAgreementTemplateModal'

const Border = styled.div`
  ${tw`p-4`}
  border: ${({ theme }) => theme.border};
`

const AgreementTemplates: React.VFC = () => {
  const teamSlug = useTeamSlug()
  const { team, reloadTeam } = useTeam(teamSlug)
  const history = useHistory()

  const { showModal } = useModal()

  const {
    data,
    refetch: refetchTemplates,
    isFetching,
  } = useQuery(['getAgreementTemplates', team.slug], () =>
    callApi(api.getAgreementTemplates, team.slug),
  )
  const { agreement_templates: agreementTemplates } = data || {}

  const { mutate: deleteTemplate, isLoading: deletingTemplate } = useMutation(
    (id: string) => callApi(api.deleteAgreementTemplate, team.slug, id),
    {
      onSuccess: () => {
        refetchTemplates().finally(() => {
          cabalToast({ style: 'success', content: 'Successfully deleted the template!' })
        })
      },
    },
  )

  return (
    <>
      <Widget
        title="Agreement Templates"
        cta={
          <CabalButton
            variant="link"
            onClick={() =>
              showModal(
                (resolve) => (
                  <AddAgreementTemplateModal
                    companySlug={team.slug}
                    resolve={resolve}
                    fetchTemplates={refetchTemplates}
                  />
                ),
                'add_agreement_template',
              )
            }
          >
            Add Agreement Template
          </CabalButton>
        }
      >
        <div>
          {agreementTemplates
            ?.sort((a, b) => {
              if (a.default && !b.default) return -1
              if (!a.default && b.default) return 1
              return a.created_at < b.created_at ? -1 : 1
            })
            ?.map((agreementTemplate) => {
              return (
                <div className="" key={agreementTemplate.id}>
                  <DataCardV2
                    onClick={() =>
                      window.open(
                        `/api/agreement_templates/${agreementTemplate.id}.pdf`,
                        '_blank noopener noreferrer',
                      )
                    }
                    title={agreementTemplate.name || agreementTemplate.file}
                    className="border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded"
                    cta={
                      <div>
                        {!agreementTemplate.default ? (
                          <CabalButton
                            onClick={() =>
                              history.push(`/${team?.slug}/prepare/${agreementTemplate.id}`)
                            }
                            variant="primary"
                          >
                            Edit
                          </CabalButton>
                        ) : (
                          <Typography color="fog">(default)</Typography>
                        )}
                        {window.SUPERADMIN && agreementTemplate.default && (
                          <Tooltip label="visible to super admins only">
                            <CabalButton
                              className="ml-2"
                              variant="primary"
                              onClick={() =>
                                history.push(
                                  `/${team?.slug}/prepare/${agreementTemplate.id}?edit_default=1`,
                                )
                              }
                            >
                              Edit Default
                            </CabalButton>
                          </Tooltip>
                        )}
                        {!agreementTemplate.default && (
                          <CabalButton
                            variant="destructive"
                            onClick={() =>
                              confirm(`Are you sure you want to delete this agreement template?`) &&
                              deleteTemplate(agreementTemplate.id)
                            }
                            className="ml-3"
                            working={deletingTemplate}
                          >
                            <i className="fas fa-trash" />
                          </CabalButton>
                        )}
                      </div>
                    }
                  />
                </div>
              )
            })}
          {agreementTemplates?.length === 0 && (
            <div className="flex flex-col gap-4">
              <Typography fontSize="14" lineHeight={1} color="fog">
                No agreements available
              </Typography>
            </div>
          )}
        </div>
      </Widget>
    </>
  )
}

export default AgreementTemplates
