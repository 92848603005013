import DOMPurify from 'dompurify'
import CabalButton from 'global/CabalButton'
import Modal from 'global/Modal'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { inviteEmailVariables } from 'utils/constants/agreement'
import { AdvisorModel } from 'utils/types'

const MessageContent = styled.div`
  ${tw`border rounded-md p-4`}

  border-color: ${({ theme }) => theme.colors.border};
`

interface Props {
  inviteMessage: string | null
  advisor?: AdvisorModel
  resolve: () => void
  footer?: string
}

const EmailPreviewModal: React.VFC<Props> = ({
  inviteMessage: _inviteMessage,
  advisor,
  resolve,
  footer = `
  <p style="padding: 12px 0;">
    <a href="#">Get Started</a>
  </p>
  `,
}) => {
  let inviteMessage = _inviteMessage || ''
  if (advisor) {
    inviteMessage = inviteMessage.replace(inviteEmailVariables[0], advisor.first_name)
    inviteMessage = inviteMessage.replace(inviteEmailVariables[1], advisor.name)
  }

  return (
    <Modal
      show
      onHide={resolve}
      header="Invitation Email Preview"
      leftActions={
        <CabalButton onClick={() => resolve()} leftIcon={<i className="fas fa-caret-left" />}>
          Back
        </CabalButton>
      }
    >
      <MessageContent
        className="message-content"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(`
          ${inviteMessage}
          ${footer}
        `),
        }}
      />
    </Modal>
  )
}

export default EmailPreviewModal
