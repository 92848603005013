import React, { useMemo } from 'react'

import Expand from 'react-expand-animated'
import { useMutation, useQuery } from 'react-query'

import { useComposer } from 'components/Composer'
import { useAccessControl } from 'global/AccessControl'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import copyToClipboard from 'utils/copyToClipboard'

const Faq = ({ title, body }: { title: string; body: React.ReactNode }) => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <Typography color="fog" fontSize="14" lineHeight={'1.2'} className="hover:text-[#5C69D1]">
          {title}
        </Typography>
        <Typography color="fog" className="pl-2 hover:text-[#5C69D1]">
          {open ? (
            <i className="far fa-chevron-down fa-xs" />
          ) : (
            <i className="far fa-chevron-right fa-xs" />
          )}
        </Typography>
      </div>
      <Expand open={open}>
        <Typography color="rain" fontSize="14" className="py-2 pl-5  pr-2" component="p">
          {body}
        </Typography>
      </Expand>
    </>
  )
}

const Faqs = ({ teamSlug }: { teamSlug: string }) => {
  const { canViewPortfolio } = useAccessControl(teamSlug)
  const { compose } = useComposer(teamSlug)
  const { user } = useCurrentUser()
  const isVC = !!canViewPortfolio

  const { data } = useQuery(['getTemplates', teamSlug], () => callApi(api.getTemplates, teamSlug))

  const generateInviteLink = useMutation(
    () => {
      return callApi(
        api.copyCandidatesInviteLink,
        'all-candidates',
        [],
        user.uuid,
        [],
        {
          welcome: true,
          connections: false,
          personal_info: true,
          talent_profile: true,
        },
        '',
        teamSlug,
        true,
      )
    },
    {
      onSuccess: ({ link }) => {
        copyToClipboard(link)
        cabalToast({ style: 'success', content: 'Link copied to clipboard' })
      },
    },
  )

  const templates = useMemo(() => data?.templates ?? [], [data])
  const investorUpdateTemplate = templates.find(
    (t) => t.default_template_name === 'Investor Update',
  )
  const requestPipelineTemplate = templates.find(
    (t) => t.default_template_name === 'Request Pipeline',
  )

  return (
    <Widget title="FAQs" classNames="flex flex-col gap-3">
      {isVC && (
        <>
          <Faq
            title="How do I add my connections?"
            body={
              <>
                Follow the instructions{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/addconnections`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  here
                </Typography>
                . Cabal turns your network into a shareable asset.
              </>
            }
          />
          <Faq
            title="How do I invite my team?"
            body={
              <>
                Invite your team by clicking{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/import/emails`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  Add Members
                </Typography>{' '}
                from the{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/members`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  Members tab
                </Typography>
                .
              </>
            }
          />
          <Faq
            title="How do I use Cabal for mass comms?"
            body={
              <>
                Add your contacts as{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/members`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  Members
                </Typography>{' '}
                then organize Members into{' '}
                <Typography
                  className="underline"
                  href={`/messages/groups`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  Groups
                </Typography>
                .{' '}
                <Typography
                  className="underline cursor-pointer"
                  onClick={() => compose()}
                  color="purple"
                >
                  Compose a message
                </Typography>{' '}
                and add Groups. Emails from Cabal are delivered plaintext and sent individually
                (mail merge).
              </>
            }
          />
          <Faq
            title="How do I share resources with portcos?"
            body={
              <>
                Add resources to your{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/resources`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  Resources tab
                </Typography>
                . You can add discounts, offers, people, content and more. Share with portcos using{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/resources`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  this link.
                </Typography>
              </>
            }
          />
          <Faq
            title="How do I set up a talent marketplace? "
            body={
              <>
                Visit{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/lists/all-candidates`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  All Candidates
                </Typography>{' '}
                list and add candidates manually or invite candidates using your{' '}
                <Typography
                  className="underline cursor-pointer"
                  onClick={() => generateInviteLink.mutate()}
                  color="purple"
                >
                  candidate invite link
                </Typography>
                {'. Customize candidate lists for sharing (eg "Design leaders").'}
              </>
            }
          />
          <Faq
            title="How do I view my portco’s pipeline?"
            body={
              <>
                Invite portcos to share their pipeline with{' '}
                <Typography
                  className="underline"
                  href={`/share-list/${teamSlug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  this link
                </Typography>
                . Or use{' '}
                <Typography
                  className="underline cursor-pointer"
                  onClick={() => compose({ template_uuid: requestPipelineTemplate?.uuid })}
                  color="purple"
                >
                  this template
                </Typography>{' '}
                to send the invitation via email.
              </>
            }
          />
          <Faq
            title="How do supporters share connections?"
            body={
              <>
                Send{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/addconnections`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  this link
                </Typography>{' '}
                to your teammates and supporters. This will guide your supporters through the
                connection sharing process.
              </>
            }
          />
          <Typography
            className="hover:text-[#5C69D1]"
            color="fog"
            fontSize="14"
            href="https://getcabal.com/demo"
            target="_blank"
            rel="noopener noreferrer"
            component="a"
          >
            Schedule a demo
          </Typography>
          <div />
        </>
      )}

      {!isVC && (
        <>
          <Faq
            title="How do I add my connections?"
            body={
              <>
                Follow the instructions{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/addconnections`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  here
                </Typography>
                . Cabal turns your network into a shareable asset.
              </>
            }
          />
          <Faq
            title="How do I invite my team?"
            body={
              <>
                Invite your team by clicking{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/import/emails`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  Add Members
                </Typography>{' '}
                from the{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/members`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  Members tab
                </Typography>
                .
              </>
            }
          />
          <Faq
            title="How do supporters share connections?"
            body={
              <>
                Send{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/addconnections`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  this link
                </Typography>{' '}
                to your teammates and supporters. This will guide your supporters through the
                connection sharing process.
              </>
            }
          />
          <Faq
            title="How do I get referrals?"
            body={
              <>
                Create a{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/lists#new`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  new list
                </Typography>
                , add your target accounts or buyer personas, then share your list with your team
                and supporters to offer intros. Use existing lists from the{' '}
                <Typography
                  className="underline"
                  href={`/explore`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  Explore tab
                </Typography>
                .
              </>
            }
          />
          <Faq
            title="How do I send investor updates?"
            body={
              <>
                {' '}
                <Typography
                  className="underline"
                  href={`/messages/groups#new`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  Create a group
                </Typography>{' '}
                and add your investors. Use the{' '}
                <Typography
                  className="underline cursor-pointer"
                  color="purple"
                  onClick={() => compose({ template_uuid: investorUpdateTemplate?.uuid })}
                >
                  Investor Update template
                </Typography>{' '}
                as a starting point. Add the Investors group to the To: field. Use the “Send me a
                test” to confirm the message looks good. Emails from Cabal are delivered plaintext
                and sent individually (mail merge).
              </>
            }
          />
          <Faq
            title="How do I onboard advisors?"
            body={
              <>
                Review the default{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/admin/agreements`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  Advisor Agreement
                </Typography>
                , a standard doc used by thousands of companies.{' '}
                <Typography
                  className="underline"
                  href={`/${teamSlug}/import/emails`}
                  target="_blank"
                  rel="noopener noreferrer"
                  component="a"
                  color="purple"
                >
                  Add prospective advisors
                </Typography>{' '}
                as members. Once added, visit the advisor’s profile on Cabal and click Agreements:
                New Agreement. Your advisor will be invited to review and sign.
              </>
            }
          />
          <Typography
            className="hover:text-[#5C69D1]"
            color="fog"
            fontSize="14"
            href="https://getcabal.com/demo"
            target="_blank"
            rel="noopener noreferrer"
            component="a"
          >
            Schedule a demo
          </Typography>
          <div />
        </>
      )}
    </Widget>
  )
}

export default Faqs
