import React from 'react'
import ReactStars from 'react-stars'
import { useTheme } from 'styled-components'

import MessageParsed from 'components/MessageParsed'
import TimeAgo from 'components/TimeAgo'
import Typography from 'global/Typography'

import { htmlTextContent } from 'utils/html'

interface Props {
  email: string
  rating: number
  description: string
  created_at: string
}

const Review: React.VFC<Props> = ({ email, rating, description, created_at }) => {
  const theme = useTheme()
  return (
    <div>
      <Typography fontSize="12" lineHeight={1.25} className="pb-2">
        {email}
      </Typography>
      <ReactStars
        size={10}
        edit={false}
        count={5}
        value={rating}
        color2={theme.colors.rain}
        color1={theme.side_nav.bg_color}
      />
      {created_at && (
        <Typography fontSize="10" color={'fog'}>
          <TimeAgo datetime={created_at} />
        </Typography>
      )}
      <Typography fontSize="12" color={'fog'} lineHeight={1.25} className="pt-2" component="div">
        <MessageParsed fontColor="fog" fontSize="12px" body={description} />
      </Typography>
    </div>
  )
}

export default Review
