import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import Loading from 'global/Loading'

const LastStep = ({ teamSlug, selectedSlug }: { teamSlug?: string; selectedSlug: string }) => {
  const history = useHistory()

  useEffect(() => {
    let link = ''

    if (selectedSlug) {
      link = `/${selectedSlug}`
    } else {
      link = `/${teamSlug}`
    }

    setTimeout(() => {
      history.push(link)
    }, 1500)
  }, [])

  return (
    <div className="w-full flex items-center justify-center h-[75vh]">
      <Loading size={60} />
    </div>
  )
}

export default LastStep
