import React from 'react'

import cx from 'classnames'
import styled from 'styled-components'
import tw from 'twin.macro'

import TeamProfileHeader from 'containers/NavHome/TeamProfileHeader'
import UserDropDown from 'containers/NavHome/UserDropDown'
import CabalTitle from 'global/Title'
import { useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'

import useColorMode from 'utils/hooks/useColorMode'
import { StyleProps } from 'utils/types'

import { useAccessControl } from './AccessControl'
import { CrumbProps } from './Breadcrumb'
import CompanyNav from './CompanyNav'
import Typography from './Typography'

interface Props extends StyleProps {
  children: React.ReactNode
  title: string
  fullHeight?: boolean
  fullWidth?: boolean
  hideTitle?: boolean
  noPadding?: boolean
  showMemberSelector?: boolean
  crumbs?: CrumbProps[]
  showNav?: boolean
  showUserDropdown?: boolean
}

const StyleWrapper = styled.div<{
  hideBackground?: boolean
}>`
  ${tw`mx-0`}/* position: relative; */
  /* max-width: 100vw;
  min-height: 100%; */
  /* background-color: ${({ theme, hideBackground }) =>
    !hideBackground && theme.layout.main_content_bg_color}; */
`

const PageWrapper: React.VFC<Props & React.HTMLProps<HTMLDivElement>> = ({
  children,
  fullHeight = false,
  fullWidth = false,
  title,
  className,
  hideTitle = true,
  noPadding = false,
  showMemberSelector = false,
  crumbs = null,
  showNav = true,
  showUserDropdown = true,
  ...props
}) => {
  const { isMobile } = useColorMode()
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { user, isLoggedIn } = useCurrentUser()

  const { isAdminOrEmployee, isAdmin, isAdvisor } = useAccessControl(teamSlug)
  const isUsersPrimaryTeam = isAdminOrEmployee
  const hideBackground =
    location.pathname.includes('home') ||
    location.pathname.includes('offers') ||
    location.pathname === `/${teamSlug}`

  return (
    <>
      <StyleWrapper
        hideBackground={hideBackground}
        className={cx(
          'flex flex-col pb-6 mx-1 px-2',
          { 'h-screen overflow-y-scroll': !isLoggedIn },
          className,
        )}
      >
        <CabalTitle title={title || 'Cabal'} />
        {!isMobile && !hideTitle && (
          <Typography lineHeight={1} fontWeight={600} fontSize="24" className="mb-4" component="h1">
            {title}
          </Typography>
        )}

        {/* {!team && showUserDropdown && (
          <div className="flex justify-end px-6 py-3">
            <UserDropDown />
          </div>
        )} */}

        {children}
      </StyleWrapper>
    </>
  )
}

export default PageWrapper
