import React from 'react'
import Typography from 'global/Typography'
import Modal from 'global/Modal'
import CabalButton from 'global/CabalButton'

interface Props {
  show: boolean
  onHide: () => void
}

const TwitterAuthModal: React.VFC<Props> = ({ show, onHide }) => {
  return (
    <Modal
      onHide={onHide}
      show={show}
      header={
        <Typography fontWeight={600} fontSize="14" className="text-center flex-1">
          Connect your Twitter account to continue
        </Typography>
      }
      centerActions={
        <CabalButton
          component="a"
          leftIcon={<i className="fab fa-twitter" />}
          href={`/auth/twitter-user?return_to=${window.location.href}`}
          rel="noopener noreferrer"
        >
          Connect with Twitter
        </CabalButton>
      }
    />
  )
}

export default TwitterAuthModal
