import React from 'react'

import styled from 'styled-components'

import ContentCarousel from 'containers/SignupScreen/MarketingSite/ContentCarousel'
import ResourcesCarousel from 'containers/SignupScreen/MarketingSite/ResourcesCarousel'

import Agentsync from '../UpdatedLogos/agentsync.svg'
import Canvas from '../UpdatedLogos/canvas.svg'
import Clari from '../UpdatedLogos/clari.svg'
import Craft from '../UpdatedLogos/craft.svg'
import DCG from '../UpdatedLogos/dcg.svg'
import M13 from '../UpdatedLogos/m13.svg'
import Openphone from '../UpdatedLogos/openphone.svg'
import Republic from '../UpdatedLogos/republic2.svg'
import Scratchpad from '../UpdatedLogos/scratchpad.svg'
import Sendoso from '../UpdatedLogos/sendoso.svg'
import Superhuman from '../UpdatedLogos/superhuman.svg'
import Superplastic from '../UpdatedLogos/superplastic.svg'
import Vanta from '../UpdatedLogos/vanta.svg'
import YC from '../UpdatedLogos/yc.svg'
import Footer from './Footer'
import Nav from './Nav'
import { CompanyLink, InvestorLink, LogoImg } from './styles'

export const MainSection = styled.section`
  @media (min-width: 640px) {
    zoom: 0.8;
  }
`

const MarketingSite = () => {
  return (
    <div className="h-screen antialiased">
      <MainSection className="w-full h-full overflow-scroll bg-[#0E151F] ">
        <Nav />

        <section className="px-8">
          <div className="block max-w-6xl mx-auto">
            <div className="flex items-center justify-start pt-10 lg:pt-24">
              <h2 className="hidden md:block text-5xl sm:text-[80px] font-light leading-tight tracking-tighter text-white">
                The collaborative network <br /> for companies and supporters
              </h2>
              <h2 className="block md:hidden text-5xl sm:text-[80px] font-light leading-tight tracking-tighter text-white">
                The collaborative network for companies and supporters
              </h2>
            </div>
            <div className="grid sm:grid-cols-2 mt-16 2xl:mt-32 sm:mt-24 gap-20 sm:pb-28">
              <div>
                <h4
                  className="text-3xl font-semibold tracking-tight leading-tight text-[#5c69d1] hover:text-[#8A97FF] cursor-pointer tansition-all duration-300 ease-in-out"
                  onClick={() => (window.location.href = '/companies')}
                >
                  For Companies
                </h4>
                <p className="text-4xl tracking-tighter font-light leading-normal mt-12 mb-8 pb-3 flex flex-col gap-1 text-white w-fit">
                  <CompanyLink className="whitespace-nowrap" href="/companies#warm-intros">
                    Warm intros
                  </CompanyLink>
                  <CompanyLink href="/companies#investor-updates">Investor updates</CompanyLink>

                  <CompanyLink href="/companies#advisor-program">Advisor program</CompanyLink>

                  <CompanyLink className="whitespace-nowrap" href="/companies#customer-portal">
                    Company portal
                  </CompanyLink>
                </p>

                <a
                  className="text-[#5c69d1] hover:text-[#8A97FF] cursor-pointer tansition-all duration-300 ease-in-out"
                  href="/companies"
                >
                  Learn more
                  <i className="far fa-arrow-right fa-sm ml-4"></i>
                </a>
              </div>
              <div className="pb-24 sm:pb-0">
                <h4
                  className="text-3xl font-semibold tracking-tight leading-tight text-[#e4307c] hover:text-[#EA5D98] cursor-pointer tansition-all duration-300 ease-in-out"
                  onClick={() => (window.location.href = '/investors')}
                >
                  For VCs
                </h4>
                <p className="text-4xl tracking-tighter font-light leading-normal mt-12 mb-8 pb-3 flex flex-col gap-1 text-white w-fit">
                  <InvestorLink href="/investors#founder-portal">Founder portal</InvestorLink>

                  <InvestorLink href="investors#mass-comms">Collaborative email</InvestorLink>

                  <InvestorLink className="whitespace-nowrap" href="/investors#talent-marketplace">
                    Talent marketplace
                  </InvestorLink>

                  <InvestorLink href="investors#sales-assist">Sales/BD support</InvestorLink>
                </p>
                <a
                  className="text-[#e4307c] hover:text-[#EA5D98] cursor-pointer tansition-all duration-300 ease-in-out"
                  href="/investors"
                >
                  Learn more
                  <i className="far fa-arrow-right fa-sm ml-4"></i>
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-[#0B1017] px-8">
          <section>
            <div className="block max-w-6xl mx-auto">
              <div className="flex flex-col pt-16 sm:pt-32 pb-16">
                <h4 className="text-4xl text-white font-light tracking-tight leading-tight">
                  We’re honored to be working with customers, investors and friends like:
                </h4>
              </div>
              <div className="grid grid-cols-2 gap-10 sm:gap-8 sm:grid-cols-4 lg:grid-cols-7 sm:gap-y-10 sm:justify-items-center sm:items-center pb-20">
                <LogoImg src={YC} />
                <LogoImg src={Clari} />
                <LogoImg src={Superplastic} />
                <LogoImg src={M13} />

                <LogoImg src={Scratchpad} />
                <LogoImg src={DCG} />
                <LogoImg src={Sendoso} />

                <LogoImg src={Republic} className="lg:justify-self-start" />

                <LogoImg src={Craft} />
                <LogoImg src={Superhuman} />
                <LogoImg src={Vanta} />
                <LogoImg src={Openphone} />
                <LogoImg src={Agentsync} />
                <LogoImg src={Canvas} />
              </div>
            </div>
          </section>
          <section>
            <div className="block max-w-6xl mx-auto">
              <div className="flex flex-col pt-16 sm:pt-32 pb-8">
                <h4 className="text-4xl text-white font-light tracking-tight leading-tight">
                  Content
                </h4>
              </div>
              <ContentCarousel />
            </div>
          </section>
          <section>
            <div className="block max-w-6xl mx-auto">
              <div className="flex flex-col pt-16 sm:pt-8 pb-8">
                <h4 className="text-4xl text-white font-light tracking-tight leading-tight">
                  Resources
                </h4>
              </div>
              <ResourcesCarousel />
            </div>
          </section>
          <Footer />
        </div>
      </MainSection>
    </div>
  )
}

export default MarketingSite
