import React, { useRef, useState } from 'react'

import { Link, useHistory } from 'react-router-dom'
import { useSize, useTimeoutFn } from 'react-use'
import { CellMeasurer, CellMeasurerCache, Grid, GridCellRenderer } from 'react-virtualized'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useComposer } from 'components/Composer'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'

import useColorMode from 'utils/hooks/useColorMode'
import { AdvisorModel, Team } from 'utils/types'

const MemberGridWrapper = styled.div`
  & > div > div > div {
    ${tw`pr-2 pb-2`}
  }
`

const MemberCard = styled.div`
  ${tw`h-full rounded-xl flex justify-between p-4 sm:p-0 sm:items-center flex-col`}

  background-color: ${({ theme }) => theme.colors.light_shadow};
`

const MemberCardInfo = styled.div`
  ${tw`flex flex-col items-center p-5 pb-3 flex-1`}
`

const MemberCardActions = styled.div`
  ${tw` flex flex-col ml-14 sm:ml-0 sm:mb-4 sm:items-center`}
  color: ${({ theme }) => theme.colors.rain_fog};
`

const MemberMessageLink = styled(Link)`
  ${tw`px-1 py-0.5 rounded mr-1`}
`

interface Props {
  team: Team
  members: AdvisorModel[]
  companySlug: string
}

const cache = new CellMeasurerCache({
  defaultHeight: 100,
  minHeight: 75,
  fixedWidth: true,
})

const MemberGrid: React.VFC<Props> = ({ team, members, companySlug }) => {
  const { isMobile } = useColorMode()
  const { canMessageAdvisors, canViewMemberHome } = useAccessControl(team.slug)
  const { compose } = useComposer()
  const { user } = useCurrentUser()
  const history = useHistory()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState(0)

  const numberOfColumns = (w = width) => Math.floor(w / 225)
  const numberOfRows = (w = width) => Math.ceil(members.length / numberOfColumns(w))
  const columnWidth = (w = width) => w / numberOfColumns(w)

  useTimeoutFn(() => {
    if (!wrapperRef.current) return

    const { offsetTop } = wrapperRef.current
    const vh = window.innerHeight
    const tableHeight = vh - offsetTop - 40
    setTableHeight(tableHeight)

    wrapperRef.current.style.height = `${vh - offsetTop - 40}px`
  }, 300)

  const cellRenderer: GridCellRenderer = ({ columnIndex, key, parent, rowIndex, style }) => {
    const index = rowIndex * numberOfColumns() + columnIndex
    const m = members[index]
    if (!m) {
      return <></>
    }

    const memberUrl = `/${team.slug}/people/${m.uuid}`
    const SUBJECT = `Intro to ${m.name}`
    const BODY = `Hi {{first_name}},
        <br>
        <br>
        I'd like to chat with ${m.name} sometime. Can you connect us?
        <br><br>
        Thanks, ${user.first_name}
        <br><br>
        {{template::employee_intro_template::${m.uuid}}}`

    const content = (
      <MemberCard key={m.uuid}>
        {isMobile ? (
          <div className="flex" onClick={() => history.push(memberUrl)}>
            <Avatar
              size={isMobile ? '48px' : '88px'}
              src={m.avatar_url}
              name={m.name}
              className="rounded-full mr-3 flex-shrink-0"
            />
            <div>
              <Typography>{m.name}</Typography>
              <Typography fontSize="16" fontWeight={700} component="h3" className="sm:mb-1">
                {m.hide_from_directory && team.enable_public_directory && (
                  <Typography fontSize="12" className="ml-2">
                    <Tooltip label="Hidden from other advisors">
                      <i className="fad fa-eye-slash" />
                    </Tooltip>
                  </Typography>
                )}
              </Typography>
              <Typography fontSize="12" color="fog_rain" component="div" className="text-left">
                {m.title}
                {!!m.title && !!m.company_name && ` · `}
                {m.company_name}
                {!m.title && !m.company_name && ` - `}
              </Typography>
            </div>
          </div>
        ) : (
          <MemberCardInfo as={canMessageAdvisors ? Link : undefined} to={memberUrl}>
            <Avatar
              size={'88px'}
              src={m.avatar_url}
              name={m.name}
              className="rounded-full mb-4 flex-shrink-0"
            />
            <Typography fontSize="16" fontWeight={700} component="h3" className="sm:mb-1">
              {m.name}
              {m.hide_from_directory && team.enable_public_directory && (
                <Typography fontSize="12" className="ml-2">
                  <Tooltip label="Hidden from other advisors">
                    <i className="fad fa-eye-slash" />
                  </Tooltip>
                </Typography>
              )}
            </Typography>
            <Typography className="text-center" fontSize="12" color="fog_rain" component="div">
              {m.title}
              {!!m.title && !!m.company_name && ` · `}
              {m.company_name}
            </Typography>
            <Typography className="text-center" fontSize="12" color="fog_rain" component="div">
              {m.location && <>{m.location}</>}
            </Typography>
          </MemberCardInfo>
        )}

        <MemberCardActions>
          <div>
            {canMessageAdvisors && (
              <MemberMessageLink
                to={{
                  pathname: `${memberUrl}`,
                  state: { showComposer: true },
                }}
              >
                <i className="far fa-envelope" />
              </MemberMessageLink>
            )}
            {m.linkedin_url && (
              <a href={m.linkedin_url} target="_blank" rel="noreferrer">
                <i className="fab fa-linkedin" />
              </a>
            )}
          </div>
          {canViewMemberHome && (
            <CabalButton
              variant="secondary"
              leftIcon={<i className="far fa-envelope"></i>}
              onClick={() =>
                compose({
                  subject: SUBJECT,
                  body: BODY,
                  recipients: { user_uuids: [team.founder?.id] },
                  draft_from: user.email,
                })
              }
            >
              Request intro
            </CabalButton>
          )}
        </MemberCardActions>
      </MemberCard>
    )

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div
          style={{
            ...style,
            width: columnWidth(width),
          }}
        >
          {content}
        </div>
      </CellMeasurer>
    )
  }

  const [sized, { width }] = useSize(
    ({ width, height }) => {
      console.log(width, numberOfColumns(width), numberOfRows(width), columnWidth(width))
      return (
        <MemberGridWrapper ref={wrapperRef} style={{ height: tableHeight }}>
          {!!tableHeight && (
            <Grid
              height={height}
              width={width}
              deferredMeasurementCache={cache}
              columnCount={numberOfColumns(width)}
              rowCount={numberOfRows(width)}
              rowHeight={cache.rowHeight}
              columnWidth={columnWidth(width)}
              cellRenderer={cellRenderer}
            />
          )}
        </MemberGridWrapper>
      )
    },
    {
      width: 300,
      height: 300,
    },
  )

  return sized
}

export default MemberGrid
