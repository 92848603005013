import axios from 'axios'
import { LimitedUser, Params, RequestModel } from 'utils/types'

export default {
  getAllRequests: (companySlug: string) => {
    return axios.get(`/api/requests`, {
      params: {
        team_slug: companySlug,
      },
    })
  },

  getRequest: (team_slug: string, uuid: string) => {
    return axios.get<{ request: RequestModel }>(`/api/requests/${uuid}`, {
      params: {
        team_slug,
      },
    })
  },

  getPinnedRequests: (companySlug: string) => {
    return axios.get(`/api/requests/pinned`, {
      params: {
        team_slug: companySlug,
      },
    })
  },

  // TODO data typedef
  createRequest: (
    companySlug: string,
    data: {
      request_type:
        | 'retweet_queue'
        | 'portfolio_company_intro_queue'
        | 'connection_intro_queue'
        | 'deal_board_intro'
        | 'job_intro_queue'
      global_person_id?: string | number
      company_id?: string | number
      tweet_id?: string | number
      investor_id?: string | number
      job_id?: string | number
      data: Params
    },
  ) => {
    return axios.post<RequestModel>(`/api/requests`, data, {
      params: {
        team_slug: companySlug,
      },
    })
  },

  updateRequest: (
    companySlug: string,
    requestId: string,
    completedAt?: Date,
    cancelledAt?: Date,
    pinnedAt?: Date | null,
  ) => {
    return axios.put(`/api/requests/${requestId}`, {
      params: {
        team_slug: companySlug,
      },
      request: {
        completed_at: completedAt,
        cancelled_at: cancelledAt,
        pinned_at: pinnedAt,
      },
    })
  },

  requestsStats: (team_slug: string) => {
    return axios.get<{
      top_requesters: {
        user: LimitedUser
        requests_count: number
      }[]
    }>(`/api/requests/stats`, {
      params: {
        team_slug,
      },
    })
  },
}
