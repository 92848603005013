import Tweet from 'containers/Connections/Twitter/Tweet'
import React from 'react'
import { DraftMessageBlueprint, Team } from 'utils/types'
import RequestInfo from '../Sidebar/RequestInfo'

export const renderContextSection = (message: DraftMessageBlueprint, team: Team) => {
  if (!message.request) return <></>

  if (!!message.request.tweet) {
    return <Tweet teamSlug={message.team_slug} tweet={message.request.tweet!} />
  } else {
    return (
      <RequestInfo team={team} request_uuid={message.request.uuid} messageRecipients={message.to} />
    )
  }
}
