import React from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { useAccessControl } from 'global/AccessControl'
import { MenuItem } from 'global/DropDownMenu'
import Loading from 'global/Loading'
import { DataCard } from 'ui-components/DataCard'

import api, { callApi } from 'utils/api'
import { Offer } from 'utils/types'

interface Props {
  offer: Offer
  teamSlug: string
  onEdit: (offer: Offer) => void
  category?: string
}

const getOfferPriceLabel = (priceRange: 'expensive' | 'average' | 'low') => {
  switch (priceRange) {
    case 'expensive':
      return '$$$'
    case 'average':
      return '$$'
    case 'low':
      return '$'
  }
}

const ProposedOfferCard: React.FC<Props> = ({ offer, teamSlug, onEdit, category }) => {
  const { canEditOffers } = useAccessControl(teamSlug)
  const queryCache = useQueryClient()

  const { mutate: deleteOffer, isLoading } = useMutation(
    ['deleteOffer', offer.uuid],
    () => callApi(api.deleteOffer, teamSlug, offer.uuid),
    {
      onSuccess: () => {
        queryCache.refetchQueries('offers')
      },
    },
  )

  const items = []

  if (canEditOffers && !isLoading) {
    items.push(
      { label: 'Accept resource', onSelect: () => onEdit({ ...offer, proposed: false }) },
      {
        label: 'Delete resource',
        onSelect: () => deleteOffer(),
      },
    )
  }

  const menuOptions: MenuItem[] = []

  if (offer.buttons.length > 1) {
    offer.buttons.map((b) =>
      menuOptions.push({ label: b.label, onSelect: () => window.open(b.url, '_blank') }),
    )
  }

  const description = []

  if (category) {
    description.push(category)
  }
  if (offer.price_range) {
    description.push(getOfferPriceLabel(offer.price_range))
  }
  if (offer.location) {
    description.push(offer.location)
  }

  return (
    <DataCard
      title={offer.title}
      description={description.length > 0 ? description : undefined}
      tags={offer.tags}
      menuItems={items}
      avatarUrl={offer.upload_uuid ? `/api/uploads/${offer.upload_uuid}` : undefined}
      placeholderIcon={<i className="fas fa-store"></i>}
      info={isLoading && <Loading size={14} />}
    />
  )
}

export default ProposedOfferCard
