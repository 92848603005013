import React, { useCallback, useEffect, useRef } from 'react'

import compact from 'lodash/compact'
import omit from 'lodash/omit'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { useModal } from 'global/Modal/Context'
import { useAdvisors, useCurrentUser, useGroups, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'
import Templates from 'utils/templates'
import {
  AdvisorModel,
  ButtonModel,
  CompanySlugParam,
  Contact,
  CreateMessageRequest,
  GroupModel,
  LimitedUser,
  MessageModel,
  MessageRecipient,
  Params,
} from 'utils/types'

import { recipientValue } from '.'
import { useComposer as useComposerV2 } from '../ComposerV2'
import ComposeModal from './ComposeModal'

interface CommonProps {
  onHide?: () => void
  onSubmit?: (m: MessageModel) => void
  team_slug?: string
  onAfterDelete?: () => void
}

export interface ExistingMessageProps extends CommonProps {
  messageUuid: string
  introRequestFlow?: boolean
}

type Recipients = {
  advisors?: AdvisorModel[]
  /**
   * using this is not recommended, prefer `advisors`
   *
   * will fail if used when user dont have permission to access advisors
   */
  advisor_uuids?: string[]

  users?: LimitedUser[]
  /**
   * using this is not recommended, prefer `users`
   *
   * will fail if used when user dont have permission to access users
   */
  user_uuids?: string[]

  groups?: GroupModel[]
  /**
   * using this is not recommended, prefer `groups`
   *
   * will fail if used when user dont have permission to access groups
   */
  group_uuids?: string[]

  contacts?: Contact[]

  emails?: string[]
}

type GetComposerProps = {
  template?: keyof typeof Templates
  templateVariables?: Params
  onMessageLoad?: (m: MessageModel) => void
  introRequestFlow?: boolean
  onHide?: () => void
  onAfterDelete?: () => void
} & ComposeProps

export type ComposeType = (props?: ComposeProps, style?: 'tab' | 'modal') => void

export interface NewMessageProps
  extends Omit<Partial<MessageModel>, 'recipients' | 'cc' | 'template' | 'from_request'>,
    CommonProps {
  body?: string
  subject?: string
  template?: keyof typeof Templates
  template_uuid?: string | null
  template_key?: string
  drafter_uuid?: string | null
  draft_from?: string | null
  from_request?: string | null
  recipients?: Recipients
  cc?: Recipients
  templateVariables?: Params
  person_id?: any
  connection_id?: any
  company_id?: any
  sender_uuid?: string
  draft_on_behalf?: boolean
  introRequestFlow?: boolean
}

export type ComposeProps = ExistingMessageProps | NewMessageProps
export type Compose = (props: ComposeProps) => void

export const useComposer = (teamSlug?: string) => {
  const { user } = useCurrentUser()

  const c2 = useComposerV2(teamSlug)
  const c1 = useComposerV1(teamSlug)

  return !!user?.flags?.composer_v2 ? c2 : c1
}

/**
 * renders the composer modal
 */
const useComposerV1 = (teamSlug?: string) => {
  const { showModal } = useModal()
  const { isMobile } = useColorMode()
  const composerUuidRef = useRef(uuidv4())
  const composerUuid = composerUuidRef.current
  const queryClient = useQueryClient()
  const lastReceivedComposerPropsRef = useRef<ComposeProps>()

  const history = useHistory()
  const { company_slug } = useParams<CompanySlugParam>()
  if (!teamSlug) teamSlug = company_slug

  const { user } = useCurrentUser()
  const { advisors: allAdvisors } = useAdvisors({ teamSlug })
  const { groups: allGroups } = useGroups(teamSlug, true)
  const { team } = useTeam(teamSlug)
  const allUsers = team?.admins_and_members

  const createMessagePropsRef = useRef<CreateMessageRequest | null>(null)
  const getMessagePropsRef = useRef<string | null>(null)

  const createMessageQuery = useQuery(
    ['compose', 'create', composerUuid],
    () => {
      const props = createMessagePropsRef.current
      if (!props) return

      return callApi(api.createMessage, teamSlug!, props)
    },
    {
      enabled: false,
      onSuccess: (data) => {
        if (!data) return

        createMessagePropsRef.current = null
        getMessagePropsRef.current = data.message.uuid
      },
    },
  )

  const getMessageQuery = useQuery(
    ['compose', 'get', composerUuid],
    () => {
      const messageUuid = getMessagePropsRef.current
      if (!messageUuid) return

      return callApi(api.getMessage, teamSlug!, messageUuid)
    },
    {
      enabled: false,
    },
  )

  const createMessage = useCallback(
    (props: CreateMessageRequest) => {
      createMessagePropsRef.current = props
      createMessageQuery.refetch()
    },
    [createMessageQuery],
  )

  const getMessage = useCallback(
    (messageUuid: string) => {
      getMessagePropsRef.current = messageUuid
      createMessageQuery.remove()
      getMessageQuery.remove()
      getMessageQuery.refetch()
    },
    [createMessageQuery, getMessageQuery],
  )

  const refetchMessage = useCallback(() => {
    createMessageQuery.remove()
    getMessageQuery.remove()
    getMessageQuery.refetch()
  }, [createMessageQuery, getMessageQuery])

  const buildRecipients = useCallback(
    (rawRecipients?: Recipients) => {
      if (!rawRecipients) return []

      for (const key in rawRecipients) {
        const data = rawRecipients[key as keyof Recipients]
        if (data) {
          Object.assign(rawRecipients, {
            [key]: compact(data as any),
          })
        }
      }

      const recipients: MessageRecipient[] = []

      if (rawRecipients?.advisor_uuids) {
        recipients.push(
          ...rawRecipients.advisor_uuids.map((uuid) => {
            const advisor = allAdvisors!.find((a) => a.uuid === uuid)
            if (!advisor) {
              throw {
                name: 'Unknown advisor',
                value: {
                  advisor_uuid: uuid,
                  recipients: rawRecipients,
                },
              }
            }

            return recipientValue({ advisor })
          }),
        )
      }

      if (rawRecipients?.advisors) {
        recipients.push(...rawRecipients.advisors.map((a) => recipientValue({ advisor: a })))
      }

      if (rawRecipients?.group_uuids) {
        recipients.push(
          ...rawRecipients.group_uuids.map((uuid) => {
            const group = allGroups!.find((a) => a.uuid === uuid)
            if (!group) {
              throw {
                name: 'Unknown group',
                value: {
                  group_uuid: uuid,
                  recipients: rawRecipients,
                },
              }
            }

            return recipientValue({ group })
          }),
        )
      }

      if (rawRecipients?.groups) {
        recipients.push(...rawRecipients.groups.map((a) => recipientValue({ group: a })))
      }

      if (rawRecipients?.user_uuids) {
        recipients.push(
          ...rawRecipients.user_uuids.map((uuid) => {
            const user = allUsers!.find((a) => a.uuid === uuid)
            if (!user) {
              throw {
                name: 'Unknown user',
                value: {
                  user_uuid: uuid,
                  recipients: rawRecipients,
                },
              }
            }

            return recipientValue({ user })
          }),
        )
      }

      if (rawRecipients?.users) {
        recipients.push(...rawRecipients.users.map((a) => recipientValue({ user: a })))
      }

      if (rawRecipients?.emails) {
        recipients.push(...rawRecipients.emails.map((a) => recipientValue({ email: a })))
      }

      if (rawRecipients?.contacts) {
        recipients.push(...rawRecipients.contacts.map((a) => recipientValue({ contact: a })))
      }

      return recipients
    },
    [allAdvisors, allGroups, allUsers],
  )

  const defaultOnSubmit = (m: MessageModel) => {
    if (!m.schedule_at) {
      cabalToast({
        content: 'Message Sent!',
        style: 'success',
        cta: {
          variant: 'tertiary',
          content: 'view message',
          component: 'link',
          className: 'underline',
          to: `/messages/sent#${m.uuid}`,
        },
      })
    } else {
      cabalToast({
        content: 'Message Scheduled!',
        style: 'success',
      })
    }
    queryClient.refetchQueries([teamSlug, 'drafts'])
    queryClient.refetchQueries([teamSlug, 'getMessages'])
  }
  const openComposerModal = useCallback(
    (props: GetComposerProps) => {
      showModal((resolve) => {
        return (
          <ComposeModal
            onSubmit={(m) => {
              resolve()

              if (props?.onSubmit) {
                props.onSubmit(m)
              } else {
                defaultOnSubmit(m)
              }

              createMessagePropsRef.current = null
              getMessagePropsRef.current = null
              queryClient.invalidateQueries(['compose', 'get', composerUuid])
              queryClient.invalidateQueries(['compose', 'create', composerUuid])
              queryClient.removeQueries(['compose', 'get', composerUuid])
              queryClient.removeQueries(['compose', 'create', composerUuid])
            }}
            composerUuid={composerUuid}
            teamSlug={teamSlug!}
            onHide={() => {
              resolve()
              props?.onHide?.()
            }}
            onAfterDelete={props?.onAfterDelete}
            sendMessageProps={{
              defaultTemplate: props.template,
              templateVariables: props.templateVariables,
              allowScheduledSend: true,
              introRequestFlow: props.introRequestFlow,
            }}
          />
        )
      }, 'compose-modal')
    },
    [composerUuid, history, refetchMessage, showModal, teamSlug],
  )

  /**
   * add Tab for composer
   */
  const compose = useCallback<ComposeType>(
    async (props, style = 'modal') => {
      lastReceivedComposerPropsRef.current = props
      if (!props) props = {}

      if (!teamSlug) teamSlug = props.team_slug

      console.log(props)

      if (!teamSlug)
        throw "`teamSlug` was not passed as a prop and it couldn't be detected from params"

      openComposerModal(props)

      if (!isExistingMessage(props)) {
        createMessage({
          draft: true,
          ...omit(props, 'template', 'recipients', 'cc'),
          recipients: buildRecipients(props.recipients),
          cc: buildRecipients(props.cc),
        })
      } else {
        getMessage(props.messageUuid)
      }
    },
    [isMobile, openComposerModal, createMessage, buildRecipients, getMessage],
  )

  return { compose }
}

interface WithUseComposerProps extends Params {
  teamSlug?: string
}

export type composeMessage = ReturnType<typeof useComposer>['compose']

export const withUseComposer = (Component: React.ComponentType<WithUseComposerProps>) => {
  return (props: WithUseComposerProps) => {
    const { compose } = useComposer(props.teamSlug)

    return <Component compose={compose as composeMessage} {...props} />
  }
}

function isExistingMessage(
  props: ExistingMessageProps | NewMessageProps,
): props is ExistingMessageProps {
  return (props as ExistingMessageProps).messageUuid !== undefined
}
