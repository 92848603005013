import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import validator from 'validator'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { MultiSelect, SelectOption, TextArea } from 'global/Input'
import Modal, { ModalComponentProps } from 'global/Modal'
import Typography from 'global/Typography'
import { useAdvisors, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanyListAccessorBlueprint, CompanyListBlueprint, Team } from 'utils/types'

interface Props extends ModalComponentProps {
  list?: CompanyListBlueprint
  sharedWith: Team
  currentTeamSlug: string
}

const ListSharedModal: React.FC<Props> = ({ resolve, list, sharedWith, currentTeamSlug }) => {
  const { team: currentTeam } = useTeam(currentTeamSlug)
  const { advisors = [] } = useAdvisors({ teamSlug: currentTeamSlug })

  const [selectedAccessors, setSelectedAccessors] = useState<
    (CompanyListAccessorBlueprint | string)[]
  >([])
  const [inviteMessage, setInviteMessage] = useState<string>()

  const addAccessorMutation = useMutation(() =>
    callApi(api.addListAccessors, currentTeamSlug, list!.uuid, {
      accessors: selectedAccessors,
      notify: true,
      message: inviteMessage || '',
    }),
  )

  const { data: getShareTeamsData } = useQuery(['share_teams'], () =>
    callApi(api.getShareTeams, currentTeamSlug),
  )
  const shareTeams = getShareTeamsData?.teams || []

  const options: SelectOption<CompanyListAccessorBlueprint | string>[] = shareTeams
    .map((team) => ({
      value: { accessor_uuid: team.slug, accessor_type: 'Team' },
      label: (
        <>
          <Avatar size="14px" src={team.logo} name={team.name} /> {team.name}
        </>
      ),
      toString: () => team.name,
    }))
    .concat(
      advisors
        .filter((a) => !a.role)
        .map((advisor) => ({
          value: { accessor_uuid: advisor.uuid, accessor_type: 'Advisor' },
          label: <>{advisor.name}</>,
          toString: () => advisor.name,
        })),
    )
    .concat(
      (currentTeam?.admins_and_members || []).map((user) => ({
        value: { accessor_uuid: user.uuid, accessor_type: 'User' },
        label: <>{user.name}</>,
        toString: () => user.name,
      })),
    )

  if (!list) {
    return <></>
  }

  return (
    <Modal show header="" onHide={resolve}>
      <div className="p-6 py-12 flex flex-col gap-12">
        <div className="flex flex-col items-center gap-3">
          <Avatar src={sharedWith.logo} name={sharedWith.name} size={'64px'} />
          <Typography fontWeight={600} fontSize="16" className="mt-3" component="div">
            Success! You shared {list.name} with {sharedWith.name}
          </Typography>
          <Typography fontWeight={400} fontSize="14" color="fog_rain">
            The team at {sharedWith.name} will get back to you with intro offers to their
            connections.
          </Typography>
        </div>
        <div className="w-full border-t border-light dark:border-dark" />
        <div className="flex flex-col items-center gap-4">
          <Typography fontWeight={600} fontSize="14" color="fog_rain">
            <i className="far fa-arrow-up-from-square" />
          </Typography>
          <Typography fontSize="14" fontWeight={600}>
            Share this list with others
          </Typography>
          {!addAccessorMutation.isSuccess && (
            <div className="w-96">
              <div className="flex gap-3">
                <MultiSelect
                  value={selectedAccessors}
                  className="flex-1"
                  options={options}
                  onChange={setSelectedAccessors}
                  creatable
                  placeholder="Add emails, member or other teams"
                  noOptionsMessage={({ inputValue }) => {
                    return inputValue ? `Invite ${inputValue} to access this list` : 'No options'
                  }}
                  formatCreateLabel={(value) => value}
                  onCreateOption={(value) => {
                    if (!validator.isEmail(value)) {
                      cabalToast({ content: 'Please enter a valid email', style: 'error' })
                      return
                    }
                    setSelectedAccessors([...selectedAccessors, value])
                  }}
                />
                {!!selectedAccessors.length && (
                  <CabalButton
                    onClick={() => addAccessorMutation.mutate()}
                    working={addAccessorMutation.isLoading}
                  >
                    Share
                  </CabalButton>
                )}
              </div>
              {inviteMessage === undefined && (
                <Typography
                  fontSize="11"
                  color="fog_rain"
                  component="button"
                  onClick={() => setInviteMessage('')}
                >
                  Add Message (optional){' '}
                  <Typography fontSize="10">
                    <i className="far fa-chevron-right" />
                  </Typography>
                </Typography>
              )}
              {inviteMessage !== undefined && (
                <TextArea
                  className="w-full mt-3"
                  placeholder="Invite Message"
                  value={inviteMessage}
                  onChange={(e) => setInviteMessage(e.currentTarget.value)}
                />
              )}
            </div>
          )}
          {addAccessorMutation.isSuccess && (
            <Typography color="green" fontSize="13">
              <i className="far fa-check mr-2" />
              Shared
            </Typography>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ListSharedModal
