import React from 'react'

import GlobalPersonSelect from 'components/GlobalPersonSelect'
import { useModal } from 'global/Modal'

import { CompanyBlueprint, GlobalPersonBlueprint } from 'utils/types'

import AddPersonModal from './Modal'

interface Props {
  teamSlug: string
  company: CompanyBlueprint
  onAddPerson: (person: GlobalPersonBlueprint) => void
}

const AddPersonInput: React.FC<Props> = ({ company, onAddPerson }) => {
  const { showModal } = useModal()

  const renderAddPersonModal = (person: Partial<GlobalPersonBlueprint>) => {
    showModal(
      (resolve) => (
        <AddPersonModal
          show
          person={person}
          company={company}
          onHide={() => resolve(false)}
          onCreate={(p) => {
            onAddPerson(p)
            resolve(false)
          }}
        />
      ),
      'add-person-modal',
    )
  }

  return (
    <GlobalPersonSelect
      onChange={(g) => {
        g && renderAddPersonModal(g)
      }}
      clearOnSelect
      placeholder="Can't find someone? Add them"
      creatable
      formatCreateLabel={(name) => `+ '${name}'`}
      onCreateOption={(name) => renderAddPersonModal({ name })}
    />
  )
}

export default AddPersonInput
