import axios from 'axios'

import { TwitterResponse } from 'utils/types'

export default {
  getCompanyTweets: (teamSlug: string, page: number) => {
    return axios.get<TwitterResponse>(`/api/integrations/twitter/team_tweets`, {
      params: {
        team_slug: teamSlug,
        page: page,
      },
    })
  },
  getCompanyMentions: (teamSlug: string, page: number) => {
    return axios.get<TwitterResponse>(`/api/integrations/twitter/mentions`, {
      params: {
        team_slug: teamSlug,
        page: page,
      },
    })
  },
  getTwitterProfile: (
    teamSlug: string,
    tweetableId: string,
    tweetableType: string,
    page: number,
  ) => {
    return axios.get<TwitterResponse>(`/api/integrations/twitter/profile`, {
      params: {
        team_slug: teamSlug,
        tweetable_id: tweetableId,
        tweetable_type: tweetableType,
        page: page,
      },
    })
  },

  likeTweet: (tweetId: string | number) => {
    return axios.post(`/api/integrations/twitter/${tweetId}/like`)
  },

  unlikeTweet: (tweetId: string | number) => {
    return axios.post(`/api/integrations/twitter/${tweetId}/unlike`)
  },

  retweetTweet: (tweetId: string | number) => {
    return axios.post(`/api/integrations/twitter/${tweetId}/retweet`)
  },

  unretweetTweet: (tweetId: string | number) => {
    return axios.post(`/api/integrations/twitter/${tweetId}/unretweet`)
  },
}
