import { useEffect } from 'react'

import { H as highlight } from 'highlight.run'
import ReactGA from 'react-ga4'

import ErrorLogger from 'utils/ErrorLogger'
import { CurrentUserProfile } from 'utils/types'

import useColorMode from './useColorMode'

const useAnalyticsIdentify = (user: CurrentUserProfile, isLoggedIn: boolean) => {
  const { isMobile } = useColorMode()

  useEffect(() => {
    if (!isLoggedIn || !user?.uuid || !user?.email || window.II || window.ENV !== 'production')
      return

    ErrorLogger.identify({
      id: user.uuid,
      name: user.name,
      email: user.email,
    })

    try {
      ReactGA.gtag?.('config', window.GOOGLE_ANALYTICS_MEASUREMENT_ID, {
        user_id: user.uuid,
      })

      ReactGA.gtag?.('set', 'user_properties', {
        uuid: user.uuid,
        name: user.name,
        email: user.email,
        is_team_admin: user.team_role == 'admin',
      })
    } catch (e) {
      console.error(e)
      ErrorLogger.error(e)
    }

    highlight.identify?.(user.email, {
      id: user.uuid,
      name: user.name,
    })

    window.analytics?.identify(user.uuid, {
      name: user.name,
      email: user.email,
      uuid: user.uuid,
    })

    if (!isMobile) {
      window.Intercom?.('boot', {
        app_id: 'xmb4e040',
        name: user.name,
        user_id: user.uuid,
        email: user.email,
        is_team_admin: user.team_role == 'admin',
        alignment: 'right',
        vertical_padding: 44,
        horizontal_padding: 20,
        custom_launcher_selector: '#intercom-launcher',
      })
    }
  }, [user, isLoggedIn])
}

export default useAnalyticsIdentify
