import React from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import CabalButton from 'global/CabalButton'

interface Props {
  uuid: string
  teamSlug: string
  label?: string | null
  disabled?: boolean
  reloadTemplates?: () => void
}

const DeleteTemplate: React.VFC<Props> = ({
  uuid,
  teamSlug,
  reloadTemplates,
  disabled = false,
}) => {
  const history = useHistory()

  const { mutate: deleteTemplate } = useMutation((uuid: string) => api.deleteTemplate(uuid), {
    onSuccess: () => {
      cabalToast({ style: 'success', content: 'Template deleted' })
      history.replace(`/messages/templates`)
      reloadTemplates?.()
    },
  })

  return (
    <CabalButton
      disabled={disabled}
      variant="tertiary"
      size="small"
      tooltip="Delete template"
      onClick={(e) => {
        e.stopPropagation()
        if (!confirm('Are you sure you want to delete this template?')) return
        deleteTemplate(uuid)
      }}
      rightIcon={<i className="far fa-trash" />}
    />
  )
}

export default DeleteTemplate
