import React from 'react'

import { useHistory } from 'react-router-dom'

import InLineShareExistingList from 'components/CompanyHome/SharePipeline/InLineShareExistingList'
import useCrmStatus from 'components/CrmStatus'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import OptionCardBlock from '../OptionCardBlock'
import Title from '../Title'
import { HomeSectionWrapper } from '../styles'
import ShareExistingListModal from './ShareExistingListModal'

interface Props {
  teamSlug: string
}

const SharePipeline: React.FC<Props> = ({ teamSlug, hideHeader = false }) => {
  const { user } = useCurrentUser()
  const { salesforceConnected, hubspotConnected } = useCrmStatus(user.team?.slug)
  const history = useHistory()
  const { showModal } = useModal()
  const { team } = useTeam(teamSlug)

  const renderExistingListModal = (resolve) => (
    <ShareExistingListModal onHide={() => resolve()} teamSlug={teamSlug} />
  )

  return (
    <HomeSectionWrapper>
      {!hideHeader && (
        <Title
          label={`Share your top asks with ${team?.name}`}
          onClick={() => history.push(`/share-list/${teamSlug}`)}
        />
      )}

      <InLineShareExistingList teamSlug={teamSlug} />

      <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
        <OptionCardBlock
          title={
            <span>
              Share pipeline <br />
              from Salesforce
            </span>
          }
          icon={
            <Typography fontSize="18" style={{ color: '#1798c1' }}>
              <i className="fab fa-salesforce fa-fw" />
            </Typography>
          }
          onClick={() => {
            if (!user.team) {
              history.push(`./share-list/${teamSlug}/salesforce`)
            } else if (!salesforceConnected) {
              cabalToast({
                content: 'Redirecting you to Salesforce...',
                passive: true,
              })
              setTimeout(
                () =>
                  window.location.assign(
                    `/salesforce_auth?return_to=/share-list/${teamSlug}/salesforce`,
                  ),
                3000,
              )
            } else {
              history.push(`./share-list/${teamSlug}/salesforce`)
            }
          }}
        />

        <OptionCardBlock
          title={
            <span>
              Share pipeline <br />
              from Hubspot
            </span>
          }
          icon={
            <Typography fontSize="18" style={{ color: '#FF7A59' }}>
              <i className="fab fa-hubspot fa-fw" />
            </Typography>
          }
          onClick={() => {
            if (!user.team) {
              history.push(`./share-list/${teamSlug}/hubspot`)
            } else if (!hubspotConnected) {
              cabalToast({
                content: 'Redirecting you to HubSpot...',
                passive: true,
              })
              setTimeout(
                () =>
                  window.location.assign(`/auth/hubspot?return_to=/share-list/${teamSlug}/hubspot`),
                3000,
              )
            } else {
              history.push(`/share-list/${teamSlug}/hubspot`)
            }
          }}
        />
        <OptionCardBlock
          title="Add target Companies"
          icon={<i className="far fa-plus fa-fw" />}
          onClick={() => history.push(`/share-list/${teamSlug}/manual`)}
        />
        <OptionCardBlock
          title={
            <span>
              Add target <br />
              Personas
            </span>
          }
          icon={<i className="far fa-people fa-fw" />}
          onClick={() => history.push(`/share-list/${teamSlug}/personas`)}
        />

        {/* <OptionCardBlock
          title={
            <span>
              Choose from <br /> existing list
            </span>
          }
          icon={<i className="far fa-list fa-fw" />}
          onClick={() => showModal(renderExistingListModal, 'render_existing_list_modal')}
        /> */}
      </div>
    </HomeSectionWrapper>
  )
}

export default SharePipeline
