import { sanitize } from 'dompurify'
import * as React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

interface Props {
  body: string
  resources?: boolean
  visual?: boolean
  fontSize?: string
  fontColor?: string
}

const MessageParsedContainer = styled.div<{
  resources?: boolean
  fontSize?: string
  fontColor?: string
}>`
  ${tw`relative`}

  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};

  p {
    color: ${({ theme, resources, fontColor }) =>
      fontColor
        ? theme.colors[fontColor]
        : resources
        ? theme.colors.message_sender
        : theme.colors.primary};
  }

  a {
    color: ${({ theme }) => theme.colors.link};
  }

  div {
    color: ${({ theme, resources }) =>
      resources ? theme.colors.message_sender : theme.colors.primary};
  }

  td.message-wrapper {
    color: #fff !important;
    background: transparent !important;
  }

  .message-content-signature,
  .message-content-quote {
    ${tw`mr-2 mt-2 px-3 border-gray-300 border-l-2`}
  }

  .message-content-signature > .signature-content {
    ${tw`hidden`}
  }

  .message-content-signature.show > .signature-content {
    ${tw`block`}
  }

  .message-content-quote > .quote-content {
    ${tw`hidden p-2 opacity-70`}
    font-size: 75%;
  }

  .message-content-quote.show > .quote-content {
    ${tw`block`}
  }

  .gmail_attr {
    ${tw`hidden`}
  }

  .quote-content-trigger,
  .signature-content-trigger {
    ${tw`text-sm`}
    color: ${({ theme }) => theme.buttons.secondary.text_color};
  }
`

class Quote {
  el: HTMLElement
  children: Quote[] = []
  hasQuotedReply = false
  constructor(el: HTMLElement) {
    this.el = el
  }

  insert(el: HTMLElement) {
    const q = new Quote(el)
    this.children.push(q)
    return q
  }
}

const isQuoteElement = (e: HTMLElement) => {
  return (
    e.classList.contains('gmail_quote') ||
    e.classList.contains('missive_quote') ||
    e.classList.contains('yahoo_quoted') ||
    e.id === 'replyBlockquote' ||
    (e.tagName === 'BLOCKQUOTE' && e.getAttribute('type') === 'cite') ||
    e.classList.contains('gmail_extra')
  )
}

const discoverChildQuotes = (prev: Quote, curr: Quote, el: HTMLElement, layer = 0) => {
  const children = el.children

  if (layer === 2) {
    return
  }

  for (let i = 0; i < children.length; i++) {
    const child = children[i] as HTMLElement
    if (child.classList.contains('gmail_attr')) {
      continue
    }

    if (isQuoteElement(child)) {
      discoverChildQuotes(curr, curr.insert(child), child, layer + 1)
    } else {
      discoverChildQuotes(prev, curr, child, layer)
    }
  }
}

const ignoreQuoteAttributesText = (s: string) => {
  return s
    .replaceAll(
      /on \w{3}, \w{3} \d{2}, \d{4} at \d{1,2}:\d{1,2} \w{2}, [\w\s]+? <.*?> wrote:/gi,
      '',
    )
    .replaceAll(/sent via cabal/gi, '')
    .replaceAll(/[\s\n]+/g, '')
}

const addTargetToAnchorLinks = (el: HTMLElement) => {
  const links = el.getElementsByTagName('a')
  for (let i = 0; i < links.length; i++) {
    links[i].setAttribute('target', '_blank')
    links[i].setAttribute('rel', 'noreferrer')
  }
}

const MessageParsed = ({ body, resources, visual = false, fontSize, fontColor }: Props) => {
  const messageContentRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!messageContentRef.current) return
    messageContentRef.current.innerHTML = ''
    const dummyHTML = document.createElement('html')
    dummyHTML.innerHTML = body
    const htmlBody = document.createElement('div')
    htmlBody.innerHTML = dummyHTML.querySelector('body')?.innerHTML || ''
    messageContentRef.current.id = 'message-' + Date.now().toString()
    addTargetToAnchorLinks(htmlBody)

    const styleSheetEls = htmlBody.getElementsByTagName('style')
    for (let i = 0; i < styleSheetEls.length; i++) {
      if (styleSheetEls[i].sheet) {
        for (let j = 0; j < styleSheetEls[i].sheet!.cssRules.length; j++) {
          const selectors = (
            styleSheetEls[i].sheet!.cssRules[j] as CSSStyleRule
          ).selectorText.split(',')
          for (let k = 0; k < selectors.length; k++) {
            selectors[k] = `#${messageContentRef.current.id} ${selectors[k]}`.trim()
          }
          ;(styleSheetEls[i].sheet!.cssRules[j] as CSSStyleRule).selectorText = selectors.join(', ')
        }
      }
    }

    sanitizeReplies(htmlBody)
    sanitizeSignatures(htmlBody)
    messageContentRef.current.append(htmlBody)
  }, [body])

  const createQuotedText = (el: HTMLElement) => {
    el.classList.add('message-content-quote')
    el.setAttribute('style', '')

    const quoteContent = document.createElement('div')
    quoteContent.classList.add('quote-content')
    quoteContent.innerHTML = el.innerHTML

    el.innerHTML = ''
    el.prepend(quoteContent)

    const quoteContentTrigger = document.createElement('button')
    quoteContentTrigger.classList.add('quote-content-trigger')
    quoteContentTrigger.innerHTML = 'Show quoted text'
    quoteContentTrigger.onclick = () => {
      if (el.classList.contains('show')) {
        el.classList.remove('show')
        quoteContentTrigger.innerHTML = 'Show quoted text'
      } else {
        el.classList.add('show')
        quoteContentTrigger.innerHTML = 'Hide'
      }
    }

    el.append(quoteContentTrigger)
  }

  const sanitizeReplies = (htmlBody: HTMLDivElement) => {
    const quoteTree = new Quote(document.createElement('div'))
    discoverChildQuotes(quoteTree, quoteTree, htmlBody)

    for (let i = 0; i < quoteTree.children.length; i++) {
      const child = quoteTree.children[i]

      const childText = ignoreQuoteAttributesText(child.el.innerText)
      const quoteText = ignoreQuoteAttributesText(
        child.children.map((q) => q.el.innerText).join(''),
      )
      if (
        child.children.length === 0 ||
        childText.length === quoteText.length ||
        // apple edge case - start
        (child.children.length === 1 &&
          child.el.tagName === 'BLOCKQUOTE' &&
          child.el.getAttribute('type') === 'cite' &&
          child.children[0].children.length === 0)
        // apple edge case - end
      ) {
        createQuotedText(child.el)
        continue
      }

      for (let j = 0; j < child.children.length; j++) {
        const quote = child.children[j].el as HTMLElement
        createQuotedText(quote)
      }
    }
  }

  const sanitizeSignatures = (htmlBody: HTMLDivElement) => {
    const signatures = htmlBody.querySelectorAll('.gmail_signature')

    for (let i = 0; i < signatures.length; i++) {
      const signature = signatures[i] as HTMLDivElement
      if (signature.innerText.length === 0) {
        signature.remove()
        continue
      }
      signature.classList.remove('gmail_signature')
      signature.classList.add('message-content-signature')

      const signatureContent = document.createElement('div')
      signatureContent.classList.add('signature-content')
      signatureContent.innerHTML = signature.innerHTML
      signature.innerHTML = ''
      signature.append(signatureContent)

      const signatureContentTrigger = document.createElement('button')
      signatureContentTrigger.classList.add('signature-content-trigger')
      signatureContentTrigger.innerHTML = 'Show signature'
      signatureContentTrigger.onclick = () => {
        if (signature.classList.contains('show')) {
          signature.classList.remove('show')
          signatureContentTrigger.innerHTML = 'Show signature'
        } else {
          signature.classList.add('show')
          signatureContentTrigger.innerHTML = 'Hide'
        }
      }

      signature.prepend(signatureContentTrigger)
    }
  }

  return (
    <MessageParsedContainer
      className={visual ? '' : 'message-content'}
      resources={resources}
      ref={messageContentRef}
      fontSize={fontSize}
      fontColor={fontColor}
      data-testid="message-parsed-container"
    />
  )
}

export default MessageParsed
