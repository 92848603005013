import React from 'react'

import { AxiosError } from 'axios'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry:
        process.env.NODE_ENV === 'test'
          ? false
          : (failureCount, error) => {
              if ([403, 404].includes((error as AxiosError)?.response?.status as number))
                return false

              return true
            },
    },
  },
})

const ReactQueryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export default ReactQueryProvider
