import * as React from 'react'
import Typography from 'global/Typography'
import styled from 'styled-components'
import tw from 'twin.macro'
import { StatusProps } from '.'

const Wrapper = styled.div<{ color: string }>`
  ${tw`rounded`}
  background:  ${({ color }) =>
    color === 'red'
      ? 'rgba(228, 48, 124, 0.08)'
      : color === 'yellow'
      ? 'rgba(239, 201, 69, 0.08)'
      : 'rgba(83, 175, 101, 0.08)'};
  padding: 3px 4px;
`

export interface Props {
  status: StatusProps
}

const Status: React.VFC<Props> = ({ status }) => {
  return (
    <div className="flex items-center" data-testid={status.dataTestId}>
      <Wrapper className="flex items-center space-x-2" color={status.color}>
        <Typography
          color={status.color}
          fontFamily={'mono'}
          fontSize="12"
          lineHeight={1.32}
          component="p"
        >
          {status.text}
        </Typography>
      </Wrapper>
    </div>
  )
}

export default Status
