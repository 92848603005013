import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useComposer } from 'components/Composer'
import { useAccessControl } from 'global/AccessControl'
import Typography from 'global/Typography'
import { useCurrentUserSettings, useTeam, useTeamSlug } from 'store/hooks'

import HeaderSection from '../HeaderSection'
import ActivityCard from './ActivityCard'

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 800px) {
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: 1rem;
  }
`

const NewScreenActivity = () => {
  const tamSlug = useTeamSlug()
  const { compose } = useComposer(tamSlug)
  const team = useTeam(tamSlug)
  const history = useHistory()
  const { settings, updateSetting } = useCurrentUserSettings()
  const {
    canViewConnections,
    canViewAdvisorAsks,
    canSendMessages,
    canViewMembers,
    canViewEquity,
    canViewPortfolio,
    canViewOffers,
  } = useAccessControl(tamSlug)

  let investorMode = canViewPortfolio
  let title

  if (investorMode) {
    title = 'Cabal helps you do more for your portfolio'
  } else {
    title = 'Cabal helps you get more out of your investors and advisors'
  }

  return (
    <div className="flex flex-col self-center">
      <HeaderSection title={title} description={`Here's how it works:`} />
      <GridWrapper className="px-4 sm:px-12 pb-4 gap-4">
        {investorMode && (
          <>
            <ActivityCard
              icon={<i className="fal fa-users"></i>}
              title={'Members'}
              description={`Add portfolio founders and execs, LPs, and teammates to streamline updates`}
              onClick={() => {
                history.push(`/${tamSlug}/members`)
                updateSetting('activity_empty_viewed_members', true)
              }}
              cta={'Import Members'}
              complete={settings.activity_empty_viewed_members || false}
              completeMsg={'Import Members'}
              disabled={!canViewMembers}
            />
            <ActivityCard
              icon={<i className="fal fa-paper-plane"></i>}
              title={'Updates'}
              description={`Send better updates with our mail-merge composer and templates`}
              onClick={() => {
                compose()
                updateSetting('activity_empty_viewed_updates', true)
              }}
              cta={'Try the composer'}
              complete={settings.activity_empty_viewed_updates || false}
              completeMsg={'Try the composer'}
              disabled={!canSendMessages}
            />
            <ActivityCard
              icon={<i className="fal fa-folder-open"></i>}
              title={'Portfolio'}
              description={`Scale your impact by organizing portcos and surfacing ways to help`}
              onClick={() => {
                history.push(`/${tamSlug}/portfolio`)
                updateSetting('activity_empty_viewed_portfolio', true)
              }}
              cta={'Set up Portfolio'}
              complete={settings.activity_empty_viewed_portfolio || false}
              completeMsg={'Set up Portfolio'}
              disabled={!canViewPortfolio}
            />
            <ActivityCard
              icon={<i className="fal fa-tags"></i>}
              title={'Resources'}
              description={`Privately share discounts, vendors, content and more`}
              onClick={() => {
                history.push(`/${tamSlug}/resources`)
                updateSetting('activity_empty_viewed_resources', true)
              }}
              cta={'Set up Resources'}
              complete={settings.activity_empty_viewed_resources || false}
              completeMsg={'Set up Resources'}
              disabled={!canViewOffers}
            />
          </>
        )}
        {!investorMode && (
          <>
            <ActivityCard
              icon={<i className="fal fa-thumbs-up"></i>}
              title={'Lists'}
              description={`Get warm intros to sales prospects, job candidates, and potential investors`}
              onClick={() => {
                history.push(`/${tamSlug}/lists`)
                updateSetting('activity_empty_viewed_asks', true)
              }}
              cta={'Set up Asks'}
              complete={settings.activity_empty_viewed_asks || false}
              completeMsg={'Set up Asks'}
              disabled={!team.enable_asks}
            />
            <ActivityCard
              icon={<i className="fal fa-paper-plane"></i>}
              title={'Updates'}
              description={`Send better updates with our mail-merge composer with templates and analytics`}
              onClick={() => {
                compose()
                updateSetting('activity_empty_viewed_updates', true)
              }}
              cta={'Try the composer'}
              complete={settings.activity_empty_viewed_updates || false}
              completeMsg={'Try the composer'}
              disabled={!canSendMessages}
            />
            <ActivityCard
              icon={<i className="fal fa-users"></i>}
              title={'Members'}
              description={`Organize teammates and backers into groups to streamline asks and updates`}
              onClick={() => {
                history.push(`/${tamSlug}/members`)
                updateSetting('activity_empty_viewed_members', true)
              }}
              cta={'Import Members'}
              complete={settings.activity_empty_viewed_members || false}
              completeMsg={'Import Members'}
              disabled={!canViewMembers}
            />
            <ActivityCard
              icon={<i className="fal fa-signature"></i>}
              title={'Advisor Program'}
              description={`Trade equity for contributions and activate your supporters`}
              onClick={() => {
                history.push(`/${tamSlug}/admin/advisor_program`)
                updateSetting('activity_empty_viewed_advisors', true)
              }}
              cta={'Set up advisor program'}
              complete={settings.activity_empty_viewed_advisors || false}
              completeMsg={'Set up advisor program'}
              disabled={!canViewEquity}
            />
          </>
        )}
      </GridWrapper>
      <div className="flex items-center justify-center py-2.5">
        <Typography
          fontSize="12"
          color={'purple'}
          component="button"
          className="cursor-pointer"
          onClick={() => updateSetting('activity_empty_skip', true)}
        >
          Skip setup
        </Typography>
      </div>
    </div>
  )
}

export default NewScreenActivity
