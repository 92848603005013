import axios from 'axios'

import { FormDetails } from 'containers/Portfolio/MemberView/RequestIntroModal'

import company from 'utils/api/company'
import { Team } from 'utils/types'
import {
  GetInvestorCompaniesResponse,
  GetInvestorTagsResponse,
  InvestorCompany,
  InvestorCompanyJob,
  TalentPOC,
} from 'utils/types/investor'

export default {
  getInvestorCompanies: (
    teamSlug: string,
    uniqueAccessLinkUuid?: string | null | undefined,
    limit?: number,
  ) => {
    return axios.get<GetInvestorCompaniesResponse>('/api/investor_companies', {
      params: { team_slug: teamSlug, unique_access_link_uuid: uniqueAccessLinkUuid, limit },
    })
  },

  getInvestorTags: (teamSlug: string) => {
    return axios.get<GetInvestorTagsResponse>(`/api/investor_companies/tag_options`, {
      params: { team_slug: teamSlug },
    })
  },

  getInvestorCompany: (uuid: string, teamSlug: string) => {
    return axios.get<GetInvestorCompaniesResponse>(`/api/investor_companies/${uuid}`, {
      params: { team_slug: teamSlug },
    })
  },

  updateInvestorCompany: (
    teamSlug: string,
    company: Partial<InvestorCompany>,
    ownerUuids?: { [key: string]: boolean },
  ) => {
    const params = {
      ...company,
      team_slug: teamSlug,
      tag_list: company.tags?.join(', '),
      owner_uuids: ownerUuids,
    }
    return axios.put(`/api/investor_companies/${company.uuid}`, params)
  },

  bulkUpdateFieldInvestorCompany: (
    companies: InvestorCompany[],
    uuids: string[],
    teamSlug: string,
    field: string,
  ) => {
    const params = {
      companies,
      team_slug: teamSlug,
      uuids,
      field,
    }
    return axios.patch(`/api/investor_companies/bulk_update_attribute`, params)
  },

  bulkCreateInvestorCompany: (companies: InvestorCompany[], teamSlug: string) => {
    const params = {
      companies,
      team_slug: teamSlug,
    }
    return axios.post(`/api/investor_companies/bulk_create`, params)
  },

  createInvestorCompany: (company: InvestorCompany, teamSlug: string) => {
    const params = {
      investor_company: company,
      team_slug: teamSlug,
    }
    return axios.post(`/api/investor_companies`, params)
  },

  importInvestorCompanies: (companies: string[], teamSlug: string) => {
    const params = {
      companies,
      team_slug: teamSlug,
    }
    return axios.post(`/api/investor_companies/bulk_import`, params)
  },

  requestJoinInvestorCompany: (teamSlug: string, companyName: string, note?: string) => {
    const params = {
      team_slug: teamSlug,
      company_name: companyName,
      note: note,
    }
    return axios.post(`/api/investor_companies/request_join`, params)
  },

  autofillInvestorCompany: (company: InvestorCompany, teamSlug: string) => {
    const data = {
      team_slug: teamSlug,
    }
    return axios.get(`/api/investor_companies/${company.uuid}/autofill`, { data })
  },

  deleteInvestorCompany: (uuid: string, teamSlug: string) => {
    return axios.delete(`/api/investor_companies/${uuid}`, { params: { team_slug: teamSlug } })
  },

  createInvestorCompanyInvite: (team_slug: string, investor_company_uuid: string) => {
    return axios.post<{ uuid: string; message_uuid: string }>(`/api/investor_company_invites`, {
      team_slug,
      investor_company_uuid,
    })
  },

  getInvestorCompanyInvite: (investor_company_invite_uuid: string) => {
    return axios.get<{
      uuid: string
      investor: {
        name: string
        logo_url: string | null
        username: string
      }
    }>(`/api/investor_company_invites/${investor_company_invite_uuid}`)
  },

  /**
   * Updates the portfolio company invite
   * used to mark invite as sent & accept the invite depending on the scenario
   */
  updateInvestorCompanyInvite: (team_slug: string, investor_company_invite_uuid: string) => {
    return axios.put<{
      team?: Team
      company?: InvestorCompany
    }>(`/api/investor_company_invites/${investor_company_invite_uuid}`, {
      team_slug,
    })
  },

  requestIntro: (
    companyUuid: string,
    teamSlug: string,
    details: FormDetails,
    jobUuid?: string,
    uniqueAccessLinkUuid?: string,
    isCompanyRequest?: boolean,
  ) => {
    return axios.post(`/api/investor_companies/${companyUuid}/intro_requests`, {
      team_slug: teamSlug,
      note: details?.context,
      job_uuid: jobUuid,
      linkedin_url: details?.linkedinUrl,
      unique_access_link_uuid: uniqueAccessLinkUuid,
      first_name: details?.firstName,
      last_name: details?.lastName,
      is_company_request: isCompanyRequest,
    })
  },

  acceptIntro: (
    secretUuid: string,
    companyUuid: string,
    teamSlug: string,
    uniqueAccessLinkUuid?: string,
  ) => {
    return axios.post(
      `/api/investor_companies/${companyUuid}/intro_requests/${secretUuid}/accept_v2`,
      {
        unique_access_link_uuid: uniqueAccessLinkUuid,
        team_slug: teamSlug,
      },
    )
  },

  declineIntro: (
    secretUuid: string,
    companyUuid: string,
    teamSlug: string,
    uniqueAccessLinkUuid?: string,
    declineNote?: string,
  ) => {
    return axios.post(
      `/api/investor_companies/${companyUuid}/intro_requests/${secretUuid}/decline_with_note`,
      {
        decline_note: declineNote,
        unique_access_link_uuid: uniqueAccessLinkUuid,
        team_slug: teamSlug,
      },
    )
  },

  requestIntroToAdvisor: (
    advisorUuid: string,
    teamSlug: string,
    details: any,
    uniqueAccessLinkUuid: string,
  ) => {
    return axios.post(`/api/advisors/${advisorUuid}/intro_requests`, {
      team_slug: teamSlug,
      note: details.context,
      linkedin_url: details.linkedinUrl,
      first_name: details.firstName,
      last_name: details.lastName,
      unique_access_link_uuid: uniqueAccessLinkUuid,
    })
  },

  declineIntroToAdvisor: (
    secretUuid: string,
    advisorUuid: string,
    teamSlug: string,
    uniqueAccessLinkUuid?: string,
    declineNote?: string,
  ) => {
    return axios.post(
      `/api/advisors/${advisorUuid}/intro_requests/${secretUuid}/decline_with_note`,
      {
        decline_note: declineNote,
        unique_access_link_uuid: uniqueAccessLinkUuid,
        team_slug: teamSlug,
      },
    )
  },

  getIntroRequest: (secretUuid: string, companyUuid: string) => {
    return axios.get(`/api/investor_companies/${companyUuid}/intro_requests/${secretUuid}`)
  },

  getAdvisorIntroRequest: (secretUuid: string, advisorUuid: string, teamSlug: string) => {
    return axios.get(`/api/advisors/${advisorUuid}/intro_requests/${secretUuid}`, {
      params: { team_slug: teamSlug },
    })
  },

  rerouteIntroRequest: (
    secretUuid: string,
    companyUuid: string,
    talentPOC: TalentPOC,
    uniqueAccessLinkUuid?: string,
  ) => {
    return axios.post(
      `/api/investor_companies/${companyUuid}/intro_requests/${secretUuid}/reroute`,
      {
        talent_poc: talentPOC,
        unique_access_link_uuid: uniqueAccessLinkUuid,
      },
    )
  },

  addInvestorCompanyJob: (jobDetails: Partial<InvestorCompanyJob>, teamSlug: string) => {
    return axios.post(`/api/investor_companies/${jobDetails.company_uuid}/investor_company_jobs`, {
      team_slug: teamSlug,
      ...jobDetails,
    })
  },

  getInvestorCompanyJob: (companyUuid: string, jobUuid: string, teamSlug: string) => {
    return axios.get<GetInvestorCompaniesResponse>(
      `/api/investor_companies/${companyUuid}/investor_company_jobs/${jobUuid}`,
      {
        params: { team_slug: teamSlug },
      },
    )
  },

  updateInvestorCompanyJob: (
    companyUuid: string,
    jobDetails: InvestorCompanyJob,
    teamSlug: string,
  ) => {
    return axios.patch(
      `/api/investor_companies/${companyUuid}/investor_company_jobs/${jobDetails.uuid}`,
      {
        team_slug: teamSlug,
        ...jobDetails,
      },
    )
  },

  bulkUpdateInvestorCompanyJob: (
    jobs: Partial<InvestorCompanyJob>[],
    uuids: string[],
    teamSlug: string,
  ) => {
    const params = {
      jobs,
      team_slug: teamSlug,
      uuids,
    }
    return axios.patch(`/api/investor_company_jobs_bulk`, params)
  },

  archiveInvestorCompanyJob: (companyUuid: string, jobUuid: string, teamSlug: string) => {
    return axios.patch(
      `/api/investor_companies/${companyUuid}/investor_company_jobs/${jobUuid}/archive`,
      {
        team_slug: teamSlug,
      },
    )
  },

  unarchiveInvestorCompanyJob: (companyUuid: string, jobUuid: string, teamSlug: string) => {
    return axios.patch(
      `/api/investor_companies/${companyUuid}/investor_company_jobs/${jobUuid}/unarchive`,
      {
        team_slug: teamSlug,
      },
    )
  },

  deleteInvestorCompanyJob: (jobDetails: InvestorCompanyJob, teamSlug: string) => {
    return axios.delete(
      `/api/investor_companies/${jobDetails.company_uuid}/investor_company_jobs/${jobDetails.uuid}`,
      {
        data: {
          team_slug: teamSlug,
        },
      },
    )
  },

  getAllInvestorCompanyJobs: (teamSlug: string) => {
    return axios.get('/api/team_jobs', {
      params: { team_slug: teamSlug },
    })
  },

  getInvestorCompanyJobs: (teamSlug: string, companyUuid: string) => {
    return axios.get(`/api/investor_companies/${companyUuid}/investor_company_jobs`, {
      params: { team_slug: teamSlug },
    })
  },

  getJobTags: (teamSlug: string, listUuid = null) => {
    return axios.get(`/api/investor_companies/job_tags`, {
      params: { team_slug: teamSlug, list_uuid: listUuid },
    })
  },

  getPortfolioContributions: (teamSlug: string) => {
    return axios.get(`/api/investor_companies/contributions`, {
      params: { team_slug: teamSlug },
    })
  },
}
