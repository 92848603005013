import * as React from 'react'
import styled from 'styled-components'

import CabalTitle from 'global/Title'

import Footer from '../NewSite/footer'
import Nav from '../NewSite/nav'
import { LandingPageWrapper, Section } from '../NewSite/styles'
import { Background } from './styles'

const Terms = () => {
  return (
    <LandingPageWrapper>
      <Background>
        <Nav hideLogin={true} />
        <Section className="flex items-start justify-center">
          {/* <PDF pdf={'/privacy.pdf'} /> */}
          <Content />
        </Section>
        <Footer className="pb-8" />
      </Background>
    </LandingPageWrapper>
  )
}

const ContentContainer = styled.div`
  p,
  span,
  em,
  li {
    color: #4b5563;
  }

  h1 {
    font-size: 28px;
    font-weight: bold;
  }
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 20px;
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  section {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
  ul {
    list-style-type: disc;
    padding-left: 15px;
    margin-left: 15px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  li {
    margin-bottom: 5px;
  }
  a {
    color: #718096;
  }
`

const Content = () => {
  return (
    <ContentContainer className="max-w-2xl mx-1">
      <CabalTitle title="Privacy Policy" />
      <div className="text-center">
        <h1>Cabal Inc.</h1>
        <h2>Privacy Policy</h2>
      </div>
      <h3>Overview</h3>
      <section>
        <p>
          This Privacy Policy explains how information is collected, used, and disclosed by Cabal
          Inc. (<b>“Cabal”</b>) and applies to information collected when you use or access Cabal’s
          website at www.getcabal.com (the <b>“Website”</b>) or when you use the
          software-as-a-service we offer through the website (together with the Website, the{' '}
          <b>“Service”</b>). We respect the privacy rights of users and recognize the importance of
          protecting information collected about you.
        </p>
        <p>
          Please read the following carefully to understand how we will collect, use, and maintain
          your personal information. It also describes your choices regarding the use, access, and
          correction of your personal information. By accessing and using our Services, you signify
          acceptance to the terms of this Privacy Policy.
        </p>
      </section>
      <h3>Changing our Policy</h3>
      <section>
        <p>
          We may change this Privacy Policy from time to time. If we make any changes, we will
          revise the "Last Updated" date at the end of this Privacy Policy and, in some cases, we
          may provide you with additional notice (such as adding a statement to our homepage or
          sending you an email notification). If there are material changes to this Privacy Policy,
          we will notify you more directly by email or means of a notice on the home page prior to
          the change becoming effective. We encourage you to review our Privacy Policy whenever you
          access the Service to stay informed about our information practices and the ways you can
          help protect your privacy.
        </p>
        <p>
          If you disagree with any changes to this Privacy Policy and do not wish your information
          to be subject to the revised Privacy Policy, you will need to deactivate your account with
          us and stop using the Service. Your use of the Service after the posting of such changes
          shall constitute your consent to such changes.
        </p>
      </section>
      <h3>Information Collected</h3>
      <section>
        <p>
          We may collect certain user information (including personal information and/or sensitive
          personal information) in the following ways:
        </p>
        <p>
          <b>Information You Provide To Us.</b> We collect information you provide directly to us
          including when you visit the Website and/or use our Service.
        </p>
        <ul>
          <li>
            For example, we collect information when you enter into an agreement with Cabal as a
            customer, create or modify your profile and account, access and use the Service
            (including but not limited to when you upload, download, or share information),
            participate in any interactive features of the Service, submit a contact form,
            participate in a survey, activity or event, apply for a job, request customer support,
            or communicate with us via third-party social media sites.
          </li>
          <li>
            The types of information we may collect directly from you include your name, email
            address, postal address, phone number, information about your account preferences,
            employer name, job title, transactional information (including services purchased or
            subscribed to and billing address), as well as any contact or other information you
            choose to provide. Please be aware that the information you choose to provide in your
            account profile may reveal or identify information that is not expressly stated (for
            example, information you provide may reveal your gender). We also store information that
            you upload or provide to the Services in order to provide you with the features and
            functionality of the Service.
          </li>
        </ul>
        <p>
          <b>Information Your Employer Provides to Us.</b> We collect information about individual
          users from our corporate customers who employee them.
        </p>
        <ul>
          <li>
            For example, if your employer is one of our corporate customers, they may provide to us,
            either directly or via integration with their other systems, personal data about you,
            such as your name, email address, work postal address, phone number, and job title.
          </li>
        </ul>
        <p>
          <b>Information We Collect Automatically When You Use the Service.</b> When you access or
          use the Service, we may automatically collect information about you, including:
        </p>
        <ul>
          <li>
            <b>Usage, Log, and Device Information:</b> We collect information from your use of the
            Service such as system activity, hardware settings, browser type, browser language, the
            date and time of your visit, and the referral URL. We monitor user activity in
            connection with the Service and may collect information about the features you use, the
            content you upload, download, share, or access while using the Service, the content you
            access, and any actions taken in connection with the access and use of your content in
            the Service.
          </li>
          <li>
            <b>Information Collected by Cookies and Other Tracking Technologies:</b> A cookie is a
            small file containing a string of characters that is sent to your computer when you
            visit a website. When you visit the website again, the cookie allows that site to
            recognize your browser. Cookies may store user preferences and other information. You
            can set your browser to refuse all cookies or to indicate when a cookie is being sent.
            However, some website features or services may not function properly without cookies. We
            use cookies to understand and save your preferences for future visits and compile
            aggregate data about site traffic and site interaction so that we can offer better site
            experiences and tools in the future. We may contract with third-party service providers
            to assist us in better understanding our site visitors. These service providers are not
            permitted to use the information collected on our behalf except to help us conduct and
            improve our business.
          </li>
        </ul>
        <p>
          <b>Integrated Services. </b> You may be given the option to access or register for the
          Service through the use of your user name and passwords for certain services provided by
          third parties (each, an <b>“Integrated Service”</b>), such as through the use of your
          Gmail account and Google’s API Services (<b>“Google API Access”</b>), or otherwise have
          the option to authorize an Integrated Service to provide personal data or other
          information to us, including, in the case of Google API Access, the content of email
          messages. By authorizing us to connect with an Integrated Service, you authorize us to
          access and store your name, email address(es), date of birth, gender, current city,
          profile picture URL, email message contents and other information that the Integrated
          Service makes available to us, and to use and disclose it in accordance with this Policy.
          You should check your privacy settings on each Integrated Service to understand what
          information that Integrated Service makes available to us, and make changes as
          appropriate. Please review each Integrated Service’s terms of use and privacy policies
          carefully before using their services and connecting to our Service.
        </p>
      </section>
      <h3>Use of Information</h3>
      <section>
        <p>
          We may use the information collected through the Service for the limited purpose of
          providing the Service and related functionality and services for which Cabal has been
          engaged. The information may be used for a variety of purposes, including:
        </p>
        <ul>
          <li>Provide, operate, maintain, and improve the Service;</li>
          <li>Enable you to access and use the Service;</li>
          <li>
            Allow you to send and receive messages and compose drafts using the Service in
            connection with an Integrated Service;
          </li>
          <li>
            Send you technical notices, updates, security alerts, and support and administrative
            messages;
          </li>
          <li>
            Provide and deliver the services and features you request, process, and complete
            transactions, and send you related information, including purchase confirmations and
            invoices;
          </li>
          <li>
            Respond to your comments, questions, and requests, and provide customer service and
            support;
          </li>
          <li>
            Communicate with you about services, features, surveys, newsletters, offers, promotions,
            events, and provide other news or information about Cabal and our select partners;
          </li>
          <li>Monitor and analyze trends, usage, and activities in connection with the Service;</li>
          <li>
            Investigate and prevent unauthorized access to the Service, and other illegal
            activities;
          </li>
          <li>
            Personalize and improve the Service, and provide content and/or features that match your
            interests and preferences or otherwise customize your experience on the Service;{' '}
          </li>
          <li>Generate anonymized and aggregated analyses, benchmarks and statistics; and</li>
          <li>For other purposes about which we notify you.</li>
        </ul>
        <p>
          Additionally we may disclose personal data in special cases when we have a good faith
          belief that such action is necessary to: (a) conform to legal requirements or to respond
          to lawful requests by public authorities, including to meet national security or law
          enforcement requirements; (b) protect and defend our rights or property; or (c) act to
          protect the interests of our users or others.
        </p>
        <p>
          <b>Do Not Track. </b> Some browsers offer a “do not track” (<b>“DNT”</b>) option. Because
          no common industry or legal standard for DNT has been adopted by industry groups,
          technology companies, or regulators, we do not respond to DNT signals. We will make
          efforts to continue to monitor developments around DNT browser technology and the
          implementation of a standard.
        </p>
        <p>
          <b>Additional Limits on Use of Your Google User Data</b>
        </p>
        <p>
          Notwithstanding anything else in this Privacy Policy, if you provide the Service with
          access to your Gmail data via Google API Access, our use of that data will be subject to
          these additional restrictions:
        </p>
        <ul>
          <li>
            We will only use such access to read, write, modify or control Gmail message bodies
            (including attachments), metadata, headers, and settings to provide a web email client
            that allows users to compose, send, read, and process emails and will not transfer this
            Gmail data to others unless doing so is necessary to provide and improve these features,
            comply with applicable law, or as part of a merger, acquisition, or sale of assets.
          </li>
          <li>We will not use this Gmail data for serving advertisements.</li>
          <li>
            We will not allow humans to read this data unless we have your affirmative agreement for
            specific messages, doing so is necessary for security purposes such as investigating
            abuse, to comply with applicable law, or for the Service’s internal operations and even
            then only when the data have been aggregated and anonymized.
          </li>
          <li>
            Cabal{`'`}s use and transfer to any other app of information received from Google APIs
            will adhere to{' '}
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              Google API Services User Data Policy
            </a>
            , including the Limited Use requirements. .
          </li>
        </ul>
      </section>
      <h3>Sharing and Disclosure of Information</h3>
      <section>
        <p>
          We will not share personal information about you or any Content with any third parties
          except as described in this Privacy Policy, or as defined in an agreement with us, in
          connection with the Service. For example, we may share personal information about you:
        </p>
        <ul>
          <li>
            <b>To Vendors, Consultants, and Other Service Providers:</b> We may share your
            information with third-party vendors, consultants, and other service providers who are
            working on our behalf and require access to your information to carry out that work,
            including companies that provide services such as cloud hosting and customer database
            software. These service providers are authorized to use your personal information only
            as necessary to provide services to Cabal.{' '}
          </li>
          <li>
            <b>In Compliance with Laws:</b> We may disclose your information to a third party: (a)
            if we believe that disclosure is reasonably necessary to comply with any applicable law,
            regulation, legal process, or governmental request; (b) to enforce our agreements and
            policies; (c) to protect the security or integrity of the Service; (d) to protect Cabal,
            our customers, or the public from harm or illegal activities; (e) to respond to an
            emergency which we believe in the good faith requires us to disclose information to
            assist in preventing the death or serious bodily injury of any person; or (f) as
            otherwise directed by you.
          </li>
          <li>
            <b>For Business Transfers:</b> We may share or transfer your information in connection
            with, or during negotiations of, any merger, sale of company assets, financing, or
            acquisition of all or a portion of our business to another company. You may be notified
            thereafter via email of any such change in ownership or control of your personal
            information.
          </li>
          <li>
            <b>As Aggregated and/or Anonymized Data:</b> We may also share aggregated and/or
            anonymized information with third parties that does not directly identify you.
          </li>
        </ul>
      </section>
      <h3>Data Security</h3>
      <section>
        <p>
          We have adopted reasonable physical, technical, and organizational safeguards designed to
          protect against accidental, unauthorized or unlawful destruction, loss, alteration,
          disclosure, access, use or processing of information we collect. All personal data
          processed by the Service is encrypted both in transit and at rest using AES-256
          encryption.
        </p>
        <p>
          To protect the confidentiality of your information, you must keep your account credentials
          confidential and not disclose them to any other person. Please advise us immediately if
          you believe your account credentials have been compromised in any way. In addition, please
          always log out and close your browser when you finish your session.
        </p>
      </section>
      <h3>Your Choices</h3>
      <section>
        <p>
          <b>Account Information and Retention.</b> You may update, correct, or delete information
          about you at any time by logging into your account on the Service and modifying your
          information, or by emailing us at privacy@getcabal.com. We will retain your personal
          information for the period necessary to fulfill the purposes outlined in this Privacy
          Policy. If you wish to deactivate your account, please email us at privacy@getcabal.com
          but note that we may retain certain information as required by law or for legitimate
          business purposes. We may also retain cached or archived copies of information about you
          for a certain period of time. We will respond to your access request within 30 days.
        </p>
        <p>
          We will retain your information for as long as your account is active or as needed to
          provide you with the Service. We will retain and use your information to comply with our
          legal obligations, resolve disputes, and enforce our agreements.
        </p>
        <p>
          We will retain personal data we process on behalf of our customers as directed by paying
          customers. Cabal will retain this personal information as necessary to comply with legal
          obligations, resolve disputes, and enforce agreements.
        </p>
        <p>
          Upon request, Cabal will provide you with information about whether we hold, or process on
          behalf of a third party, any of your personal information that we are aware of. To request
          this information, contact us at privacy@getcabal.com.
        </p>
        <p>
          <b>Promotional and Newsletter Communications.</b> You may opt out of receiving promotional
          and newsletter emails from Cabal by following the opt-out instructions provided in those
          emails. You may also opt-out of receiving promotional emails and other promotional
          communications from us at any time by emailing privacy@getcabal.com with your specific
          request. If you opt out, we may still send you non-promotional communications, such as
          security alerts and notices related to your access to or use of the Service or those about
          your account or our ongoing business relations.
        </p>
        <p>
          <b>Cookies.</b> Most web browsers are set to accept cookies by default. If you prefer, you
          can usually choose to set your browser to remove or reject browser cookies or to prompt
          you before accepting such a cookie. Please note that, if you choose to remove or reject
          browser cookies, this could affect the availability or functionality of the Service.
        </p>
        <p>
          <b>California Residents.</b> In addition to the rights provided for above, if you are a
          California resident, you have the right to request information from us regarding whether
          we share certain categories of your personal information with third parties for the third
          parties' direct marketing purposes. To the extent we share your personal information in
          this way, you may receive the following information:
        </p>
        <p>
          (a) the categories of information we disclosed to third parties for the third parties'
          direct marketing purposes during the preceding calendar year; and
        </p>
        <p>
          (b) the names and addresses of third parties that received such information, or if the
          nature of their business cannot be determined from the name, then examples of the products
          or services marketed.
        </p>
        <p>
          Effective January 1, 2020, pursuant to the California Consumer Privacy Act of 2018
          (“CCPA”), California residents have certain rights in relation to their personal
          information, subject to limited exceptions. Any terms defined in the CCPA have the same
          meaning when used in this California Privacy Rights section.
        </p>
        <p>
          For personal information collected by us during the preceding 12 months that is not
          otherwise subject to an exception, California residents have the right to access and
          delete their personal information. We will not discriminate against those who exercise
          their rights. Specifically, if you exercise your rights, we will not deny you services,
          charge you different prices for services or provide you a different level or quality of
          services.
        </p>
        <p>
          To the extent we sell your personal information to third parties, you also have the right
          to request that we disclose to you: (i) the categories of your personal information that
          we sold, and (ii) the categories of third parties to whom your personal information was
          sold. You have the right to direct us not to sell your personal information. We do not
          sell your personal information in our ordinary course of business and will never sell your
          personal information to third parties without your explicit consent.
        </p>
        <p>
          To make such requests, contact us at privacy@getcabal.com or the other contact information
          set out below.{' '}
        </p>
        <p>
          If you are a California resident, you may designate an authorized agent to make a request
          to access or a request to delete on your behalf. We will respond to your authorized
          agent's request if they submit proof that they are registered with the California
          Secretary of State to be able to act on your behalf, or submit evidence you have provided
          them with power of attorney pursuant to California Probate Code section 4000 to 4465. We
          may deny requests from authorized agents who do not submit proof that they have been
          authorized by you to act on their behalf, or are unable to verify their identity.
        </p>
        <p>
          In accordance with California Civil Code Section 1789.3, California resident users are
          entitled to know that they may file grievances and complaints with the California
          Department of Consumer Affairs, 400 R Street, STE 1080, Sacramento, CA 95814; or by phone
          at (916) 445-1254 or (800) 952-5210; or by email to dca@dca.ca.gov.
        </p>
      </section>
      <h3>Links to Third-Party Websites</h3>
      <section>
        <p>
          We may place links on the Service. When you click on a link to a third-party website from
          our Website, your activity and use on the linked website is governed by that website’s
          policies, not by those of Cabal. We encourage you to review their privacy and user
          policies.
        </p>
      </section>
      <h3>Our Policy Toward Children</h3>
      <section>
        The Service is not directed to individuals under 18. We do not knowingly collect personal
        information from children under 18. If you become aware that a child has provided us with
        personal information, please contact us at privacy@getcabal.com. If we become aware that a
        child under 18 has provided us with personal information, we will take steps to delete such
        information. If you are under the age of 18, you may not use our Services.
      </section>
      <h3>Contacting Us</h3>
      <section>
        <p>
          Any questions about this Privacy Policy should be addressed to{' '}
          <a href="mailto:privacy@getcabal.com" className="underline">
            privacy@getcabal.com
          </a>
        </p>
        <p>
          To report vulnerabilities, please email{' '}
          <a href="mailto:privacy@getcabal.com" className="underline">
            privacy@getcabal.com
          </a>
        </p>
      </section>
      <p>
        <b>Last Updated:</b> December 10, 2021
      </p>
    </ContentContainer>
  )
}

export default Terms
