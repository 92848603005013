import React from 'react'

const Demo = () => {
  return (
    <>
      <div
        style={{
          height: '100vh',
          width: '100%',
          margin: 0,
          padding: 0,
          overflow: 'hidden',
          position: 'relative',
        }}
        className="full-page-iframe"
      >
        <iframe
          src="https://forms.default.com/776337"
          style={{
            border: 'none',
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          allowFullScreen
        ></iframe>
      </div>
    </>
  )
}

export default Demo
