import React from 'react'

import styled from 'styled-components'

import { ModalInputLabel, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'

import { CompanyListBlueprint } from 'utils/types'

interface Props {
  onClick: (v: CompanyListBlueprint['list_type'] | '') => void
}

const Divider = styled.div`
  border-left: ${({ theme }) => theme.border};

  height: 100%;
  width: 1px;
`

interface OptionProps {
  title: string
  icon: React.ReactNode
  onClick: () => void
  selected: boolean
  data_testid?: string
}

const OptionWrapper = styled.button<{ selected: boolean }>`
  border: ${({ theme }) => theme.border};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.purple : theme.colors.cardBackground};
`

export const Options: React.FC<OptionProps> = ({ title, icon, onClick, selected, data_testid }) => {
  return (
    <OptionWrapper
      className="flex items-center rounded-full px-2 py-1 space-x-1 cursor-pointer"
      selected={selected}
      onClick={onClick}
      data-testid={data_testid}
    >
      <Typography fontSize="12" color={selected ? 'white' : 'fog'}>
        {icon}
      </Typography>
      <Typography fontWeight={600} fontSize="12" color={selected ? 'white' : 'primary'}>
        {title}
      </Typography>
    </OptionWrapper>
  )
}

const TemplateSelector: React.FC<Props> = ({ onClick }) => {
  return (
    <div>
      <div className="flex justify-between gap-4">
        <div className="flex flex-col w-2/5 py-16 pb-24 pr-4">
          <Typography fontSize="16" fontWeight={600}>
            <Typography color="purple">
              <i className="far fa-list mr-2"></i>
            </Typography>
            Create a List
          </Typography>
          <Typography fontSize="12" color="fog" className="my-2">
            Create Lists to share asks and updates with your Members.
          </Typography>
          <Typography fontSize="12" color="fog" className="my-2">
            Want help with referrals to target accounts from Salesforce? Connect it. Want help
            filling key roles? Post your jobs list.
          </Typography>
        </div>
        <div>
          <Divider />
        </div>
        <div className="w-3/5 py-16 pb-24 ">
          <div className="w-full">
            <ModalInputLabel>Choose a template</ModalInputLabel>
            <div className="flex flex-wrap gap-2 mt-2">
              <Options
                title={'Companies'}
                icon={<i className="far fa-building"></i>}
                onClick={() => onClick('companies')}
                selected={false}
              />
              <Options
                title={'Personas'}
                icon={<i className="far fa-users-line"></i>}
                onClick={() => onClick('personas')}
                selected={false}
              />
              {/* <Options
                title={'Jobs'}
                icon={<i className="far fa-briefcase"></i>}
                onClick={() => onClick('jobs')}
                selected={false}
              /> */}
              <Options
                title={'Fundraise'}
                icon={<i className="far fa-money-bill-trend-up"></i>}
                onClick={() => onClick('fundraise')}
                selected={false}
              />
              {/* <Options
            title={'People'}
            icon={<i className="far fa-user"></i>}
            onClick={() => onClick('people')}
            selected={false}
          /> */}
              {/* <Options
            title={'Other'}
            icon={<i className="far fa-list"></i>}
            onClick={() => onClick('other')}
            selected={false}
          /> */}
            </div>
          </div>

          <div className="pt-4">
            <ModalInputLabel>Or start from scratch</ModalInputLabel>
            <div className="flex flex-wrap gap-2 mt-2">
              <Options
                title={'New List'}
                icon={<i className="far fa-plus"></i>}
                onClick={() => onClick('')}
                selected={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateSelector
