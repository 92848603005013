import * as React from 'react'

import styled from 'styled-components'

import useColorMode from 'utils/hooks/useColorMode'

import ReferenceCard from './ReferenceCard'

const ColumnDoubleLayout = styled.div`
  display: grid;
  row-gap: 32px;
  align-items: flex-start;
  padding-bottom: 5.5rem;

  @media (min-width: 850px) {
    grid-template-columns: minmax(auto, 404px) minmax(auto, 600px);
    column-gap: 68px;
    padding-bottom: 10rem;
  }
`

const Text = styled.p<{
  header?: boolean
}>`
  font-weight: ${({ header }) => (header ? '600' : '400')};
  line-height: ${({ header }) => (header ? '1.05' : '1.5')};
  padding-bottom: ${({ header }) => (header ? '16px' : '0px')};
  font-size: ${({ header }) => (header ? '24px' : '16px')};
  font-family: ${({ header }) => !header && 'JetBrains Mono'};
  color: ${({ header }) => (header ? '#000 !important' : '#6B7280 !important')};
  letter-spacing: ${({ header }) => header && '-0.03em'};

  /* @media (prefers-color-scheme: dark) {
    color: #f7fafc !important;
  } */

  @media (min-width: 650px) {
    padding-bottom: ${({ header }) => (header ? '24px' : '0px')};
    font-size: ${({ header }) => (header ? '36px' : '16px')};
  }
`

const InfoSection = styled.div`
  @media (min-width: 850px) {
    /* align-self: center; */
    max-width: 404px;
  }
`

const Img = styled.img`
  border-radius: 16px;
`

const Label = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.05em;
  margin-bottom: 24px;
  color: #718096 !important;
  text-transform: uppercase;
`

interface Props {
  label: string
  title: string
  description: string
  img: string
  // subDescription?: string
  id?: string
}

const DataSection: React.VFC<Props> = ({ title, description, label, img, id }) => {
  const { isDarkMode } = useColorMode()

  return (
    <ColumnDoubleLayout id={id}>
      <InfoSection>
        <Label>{label}</Label>
        <Text header={true}>{title}</Text>
        <Text>{description}</Text>
        <ReferenceCard label={label} />
      </InfoSection>
      {/* {isDarkMode ? <Img src={img} /> : <Img src={imgLT} />} */}
      <Img src={img} />
    </ColumnDoubleLayout>
  )
}

export default DataSection
