import React, { useMemo, useState } from 'react'

import { useMutation } from 'react-query'
import { SetRequired } from 'type-fest'

import EditGroupsModal from 'containers/Groups/EditGroupsModal'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import { useGroups, useTeam } from 'store/hooks'
import { DataCard, IconCtaProps } from 'ui-components/DataCard'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import copyToClipboard from 'utils/copyToClipboard'
import { GroupModel } from 'utils/types'

interface Props {
  group: GroupModel
  teamSlug: string
}

const GroupCard: React.VFC<Props> = ({ group, teamSlug }) => {
  const { team } = useTeam(teamSlug)
  const { reloadGroups } = useGroups(teamSlug)
  const { showModal } = useModal()

  const {
    mutate: saveGroup,
    isLoading: updatingGroup,
    variables: updateVar,
  } = useMutation(
    ['saveGroup'],
    (group: SetRequired<Partial<GroupModel>, 'uuid'>) => callApi(api.saveGroup, teamSlug, group),
    {
      onSuccess: () => reloadGroups(),
    },
  )

  const renderGroupEditModal: RenderModal = (resolve) => (
    <EditGroupsModal
      onHide={() => resolve(false)}
      onSave={() => {
        reloadGroups()
      }}
      onDelete={() => {
        reloadGroups()
        resolve(false)
      }}
      selectedGroup={group}
      teamSlug={teamSlug}
    />
  )

  const rightActions: IconCtaProps[] = [
    {
      icon: 'fa-pencil',
      onClick: () => {
        showModal(renderGroupEditModal, 'render_group_edit_modal')
      },
      tooltipLabel: 'Edit',
      dataTestId: 'open-edit-group-modal-btn',
    },
    {
      icon: group.public_to_team ? 'fa-eye' : 'fa-eye-slash',
      onClick: () => {
        saveGroup({
          ...group,
          public_to_team: !group.public_to_team,
        })
      },
      tooltipLabel: group.public_to_team ? 'Visible to employees' : 'Visible to admins',
      dataTestId: 'change-group-visibility-btn',
    },
  ]

  rightActions.push({
    icon: 'far fa-clipboard',
    onClick: () => {
      const url = new URL(window.location.href)
      url.pathname = `/${team.slug}/groups/${group.uuid}`
      copyToClipboard(url.toString())
      cabalToast({ style: 'success', content: 'Copied link to join group' })
    },
    tooltipLabel: 'Copy group link',
    dataTestId: 'change-group-link-btn',
  })

  const visibilityDescription = group.public_to_team ? 'Visible to employees' : 'Visible to admins'

  const descriptions = [`${group.advisor_groups_count} members`, visibilityDescription]
  if (group.smart_group) descriptions.push('Smart group')

  return (
    <>
      <DataCard title={`${group.name}`} description={descriptions} iconCTA={rightActions} />
    </>
  )
}

export default GroupCard
