import React from 'react'

import { Value } from 'classnames'

import { EditListType } from 'components/EditList'
import FacilitatorSection from 'components/EditList/Portfolio/FacilitatorSection'
import { CheckBox, Select, SelectOption, TextInput } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import InfoTooltip from 'ui-components/InfoTooltip'

interface Props {
  list: Partial<EditListType>
  setList: (list: Partial<EditListType>) => void
  adminAndMembersValues: SelectOption<Value>[]
}

const ResourcePeopleOptions = ({ list, setList, adminAndMembersValues }: Props) => {
  return (
    <>
      <ModalInputWrapper>
        <ModalInputLabel>Name</ModalInputLabel>
        <TextInput
          placeholder="Add a list name"
          value={list.name}
          autoFocus={true}
          onChange={(e) => setList({ ...list, name: e.currentTarget.value })}
          data-testid="list-name-input"
        />
      </ModalInputWrapper>

      <FacilitatorSection
        title="Intro"
        adminAndMembersValues={adminAndMembersValues}
        selectedValue={(selectedValues: any) => {
          setList({
            ...list,
            default_facilitator_uuid: selectedValues.selectedFacilitator,
            default_approval_required: selectedValues.approvalRequired,
          })
        }}
        facilitatorType="default"
        defaultOptions={[]}
        initializeOptions={{
          selectedUser: list?.default_facilitator.user_id,
          approvalRequired: list?.default_facilitator?.required_approval,
        }}
        routingOptions={{
          enabled: false,
        }}
      />
    </>
  )
}

export default ResourcePeopleOptions
