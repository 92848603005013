import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import React, { useState } from 'react'
import { useCurrentUser } from 'store/hooks'

interface Props {
  resolve: () => void
  onNameSave: () => void
}

const UpdateUserNameModal: React.VFC<Props> = ({ resolve, onNameSave }) => {
  const [name, setName] = useState('')
  const { updateUser, updatingUser } = useCurrentUser()

  return (
    <Modal
      show
      onHide={resolve}
      header="We will need some details first"
      rightActions={
        <CabalButton
          disabled={!name}
          working={updatingUser}
          onClick={() => {
            updateUser('name', name).then(() => {
              resolve()
              onNameSave()
            })
          }}
        >
          Save
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Your name</ModalInputLabel>
          <TextInput
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            placeholder="John Doe"
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default UpdateUserNameModal
