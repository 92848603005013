import React, { useState } from 'react'

import { useMutation } from 'react-query'

import { SelectWrapper } from 'containers/CandidateProfileModal/PersonalInfo'
import {
  LEVEL_OPTIONS,
  ROLE_FUNCTION_OPTIONS,
  STAGE_OPTIONS,
} from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import { MultiSelect, Select, TextArea } from 'global/Input'
import CityInput from 'global/Input/CityInput'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CandidateProfile } from 'utils/types'

interface AboutModalProps {
  onHide: () => void
  candidateProfile: CandidateProfile
  reloadCandidateProfile: () => void
  teamSlug: string
}

const AboutModal = ({
  onHide,
  candidateProfile: _candidateProfile,
  teamSlug,
  reloadCandidateProfile,
}: AboutModalProps) => {
  const [candidateProfile, setCandidateProfile] =
    useState<Partial<CandidateProfile>>(_candidateProfile)

  const updateCandidateProfileMutation = useMutation(
    ['updateCandidateProfile', candidateProfile.uuid],
    () =>
      callApi(api.updateCandidateProfileV2, candidateProfile.uuid!, {
        candidate_profile: candidateProfile,
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Profile updated successfully' })
        reloadCandidateProfile()
        onHide()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to update profile' })
      },
    },
  )


  return (
    <Modal
      header="About"
      show={true}
      onHide={onHide}
      rightActions={
        <CabalButton
          type="button"
          variant="primary"
          onClick={() => updateCandidateProfileMutation.mutate()}
          working={updateCandidateProfileMutation.isLoading}
        >
          Save
        </CabalButton>
      }
    >
      <ModalInputWrapper className="py-2 text-left">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Function</div>
        </ModalInputLabel>
        <MultiSelect
          placeholder="Primary job function(s)"
          options={ROLE_FUNCTION_OPTIONS}
          value={candidateProfile.functions}
          onChange={(e) => setCandidateProfile({ ...candidateProfile, functions: e })}
        />
      </ModalInputWrapper>
      <ModalInputWrapper className="py-2 text-left">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Location</div>
        </ModalInputLabel>
        <CityInput
          onChange={(opt) => {
            setCandidateProfile({ ...candidateProfile, location: opt?.value })
          }}
          value={{ value: candidateProfile.location, label: candidateProfile.location }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper className="py-2">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Level</div>
        </ModalInputLabel>
        <SelectWrapper>
          <Select
            placeholder="Experience level"
            value={candidateProfile.level}
            onChange={(e) => setCandidateProfile({ ...candidateProfile, level: e })}
            options={LEVEL_OPTIONS}
          />
        </SelectWrapper>
      </ModalInputWrapper>
      <ModalInputWrapper className="py-2 text-left">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Stage Experience</div>
        </ModalInputLabel>
        <MultiSelect
          placeholder="Company stage experience"
          value={candidateProfile.funding_stage_experience}
          onChange={(e) =>
            setCandidateProfile({ ...candidateProfile, funding_stage_experience: e })
          }
          options={STAGE_OPTIONS}
        />
      </ModalInputWrapper>

      <div className="mb-4">
        <ModalInputWrapper className="py-2">
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">Bio</div>
          </ModalInputLabel>
          <TextArea
            placeholder="Add a one-line bio"
            value={candidateProfile.bio}
            onChange={(e) => setCandidateProfile({ ...candidateProfile, bio: e.target.value })}
          />
        </ModalInputWrapper>
      </div>
    </Modal>
  )
}

export default AboutModal
