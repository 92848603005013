import React, { useEffect, useState } from 'react'

import { Field, FieldProps, Formik } from 'formik'
import { object, string } from 'yup'

import { Footer } from 'containers/CandidateProfileModal'
import { IResourceDiscount } from 'containers/ListIndexView/ResourcesList/types'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { MultiSelect, TextInputField, UploadButton } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import CKEditor from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'

import { absoluteHtmlContent } from 'utils/html'

const CreateNewSection = ({
  onSave,
  discount,
  setDiscount,
  onHide,
  tags,
}: {
  onSave: ({ resourceUuid, resource }) => void
  discount: Partial<IResourceDiscount>
  setDiscount: (discount: Partial<IResourceDiscount>) => void
  onHide: () => void
  tags: string[]
}) => {
  const discountSchema = object({
    name: string().required('This field is required'),
  })
  return (
    <div className="pt-4">
      <Formik
        initialValues={discount}
        validationSchema={discountSchema}
        onSubmit={(values) => {
          onSave({ resourceUuid: discount?.uuid || undefined, resource: values })
          onHide()
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <form
            onSubmit={(e) => {
              handleSubmit(e)
            }}
          >
            <ModalInputWrapper className="py-2">
              <ModalInputLabel>
                <div className="flex items-center justify-start h-full">Photo</div>
              </ModalInputLabel>
              <div className="flex">
                <UploadButton
                  allowedExtensions={['png', 'jpg', 'jpeg']}
                  onUpload={(uuid) => {
                    const new_logo = `/api/uploads/${uuid}`
                    setDiscount({ ...discount, logo_url: new_logo })
                    setFieldValue('logo', new_logo)
                  }}
                  showPencil={true}
                  text=""
                  triggerProps={{
                    variant: 'tertiary',
                    leftIcon: (
                      <Typography className="flex items-start justify-start">
                        <Avatar src={discount.logo_url} size={'32'} />
                        <p className="pt-4 pl-3">Edit</p>
                      </Typography>
                    ),
                    padding: '0',
                  }}
                />
              </div>
            </ModalInputWrapper>

            <ModalInputWrapper className="py-2">
              <ModalInputLabel>
                <div className="flex items-center justify-start h-full">Name</div>
              </ModalInputLabel>
              <Field name="name">
                {(props: FieldProps) => (
                  <>
                    <TextInputField
                      placeholder="Name"
                      error={props.meta.touched ? props.meta.error : undefined}
                      {...props.field}
                      onChange={(e) => {
                        props.form.setFieldValue(props.field.name, e.target.value)
                        setDiscount({ ...discount, name: e.target.value })
                      }}
                    />
                  </>
                )}
              </Field>
            </ModalInputWrapper>

            <ModalInputWrapper className="py-2">
              <ModalInputLabel>Description</ModalInputLabel>
              <CKEditor
                value={discount.description || ''}
                onChange={(event) => {
                  setFieldValue('description', absoluteHtmlContent(event) || '')
                  setDiscount({ ...discount, description: absoluteHtmlContent(event) || '' })
                }}
                height={100}
              />
            </ModalInputWrapper>

            <ModalInputWrapper className="py-2">
              <ModalInputLabel>
                <div className="flex items-center justify-start h-full">Tags</div>
              </ModalInputLabel>
              <Field name="tags">
                {(props: FieldProps) => (
                  <MultiSelect
                    value={discount.tags ?? []}
                    options={tags?.map((tag) => ({ label: tag, value: tag })) ?? []}
                    creatable
                    onChange={(selectedTags) => {
                      props.form.setFieldValue(props.field.name, selectedTags)
                      setDiscount({ ...discount, tags: selectedTags })
                    }}
                  />
                )}
              </Field>
            </ModalInputWrapper>

            <ModalInputWrapper className="py-2">
              <ModalInputLabel>
                <div className="flex items-center justify-start h-full">CTA Text</div>
              </ModalInputLabel>
              <Field name="cta_text">
                {(props: FieldProps) => (
                  <>
                    <TextInputField
                      placeholder="CTA Text"
                      error={props.meta.touched ? props.meta.error : undefined}
                      {...props.field}
                      onChange={(e) => {
                        props.form.setFieldValue(props.field.name, e.target.value)
                        setDiscount({ ...discount, cta_text: e.target.value })
                      }}
                    />
                  </>
                )}
              </Field>
            </ModalInputWrapper>

            <ModalInputWrapper className="py-2">
              <ModalInputLabel>
                <div className="flex items-center justify-start h-full">CTA URL</div>
              </ModalInputLabel>
              <Field name="cta_url">
                {(props: FieldProps) => (
                  <>
                    <TextInputField
                      placeholder="CTA URL"
                      error={props.meta.touched ? props.meta.error : undefined}
                      {...props.field}
                      onChange={(e) => {
                        props.form.setFieldValue(props.field.name, e.target.value)
                        setDiscount({ ...discount, cta_url: e.target.value })
                      }}
                    />
                  </>
                )}
              </Field>
            </ModalInputWrapper>

            <div>
              <Footer className="-mx-6 flex justify-center mt-4 pt-6 pb-5">
                <CabalButton type="submit" variant="primary" className="w-1/4">
                  Save
                </CabalButton>
              </Footer>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CreateNewSection
