import axios from 'axios'

import {
  CreateCommentRequest,
  CreateCommentResponse,
  GetCommentsRequest,
  GetCommentsResponse,
  RemoveCommentRequest,
} from 'utils/types'

export default {
  createComment: (params: CreateCommentRequest) => {
    return axios.post<CreateCommentResponse>(`/api/comments`, params)
  },

  getComments: (params: GetCommentsRequest) => {
    return axios.get<GetCommentsResponse>(`/api/comments`, {
      params,
    })
  },

  deleteComment: (params: RemoveCommentRequest) => {
    return axios.delete(`/api/comments/${params.uuid}`)
  },
}
