import * as React from 'react'

import memoize from 'lodash/memoize'
import { Link } from 'react-router-dom'
import styled, { StyledComponentPropsWithRef } from 'styled-components'

import { darkTheme } from 'global/darkTheme'
import { MainTheme, mainTheme } from 'global/theme'

import useColorMode from 'utils/hooks/useColorMode'

interface StyledSpanProps {
  fs?: string
  fw?: number | string
  ff?: string
  c?: string
  ta?: string
  h?: string
  lh?: number | string
  ls?: string
  w?: string
  tt?: string
}

const StyledSpan = styled.span<StyledSpanProps>`
  ${({ fs }) => fs && `font-size: ${fs};`}
  ${({ fw }) => fw && `font-weight: ${fw};`}
  ${({ ff }) => ff && `font-family: ${ff};`}
  ${({ c }) => c && `color: ${c};`}
  ${({ ta }) => ta && `text-align: ${ta};`}
  ${({ h }) => h && `height: ${h};`}
  ${({ w }) => w && `height: ${w};`}
  ${({ tt }) => tt && `text-transform: ${tt};`}
  ${({ ls }) => ls && `letter-spacing: ${ls};`}
  ${({ lh }) => lh && `line-height: ${lh};`}

  > img {
    max-width: revert;
    display: revert;
  }
`

type Component =
  | 'span'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'p'
  | 'i'
  | 'div'
  | 'button'
  | 'label'
  | 'link'
  | 'a'
type FontSize =
  | '40'
  | '36'
  | '32'
  | '28'
  | '24'
  | '20'
  | '18'
  | '16'
  | '14'
  | '13'
  | '12'
  | '10'
  | '11'
  | 'inherit'

export interface TypographyProps extends StyledComponentPropsWithRef<'span'> {
  fontWeight?: number
  fontSize?: FontSize
  customFontSize?: string
  fontFamily?: keyof MainTheme['fonts']
  h?: string
  w?: string
  color?: keyof MainTheme['colors'] | 'inherit'
  textAlign?: 'left' | 'center' | 'right' | 'inherit'
  lineHeight?: number | string
  letterSpacing?: string
  textTransform?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'capitalize'
    | 'full-size-kana'
    | 'full-width'
    | 'lowercase'
    | 'none'
    | 'uppercase'
    | undefined
  component?: Component
  type?: StyledComponentPropsWithRef<'button'>['type']
  to?: string
  href?: React.AnchorHTMLAttributes<HTMLAnchorElement>['href']
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target']
  rel?: React.AnchorHTMLAttributes<HTMLAnchorElement>['rel']
}

const getFontSize = memoize((fontSize: FontSize) => {
  switch (fontSize) {
    case '40':
      return '40px'
    case '36':
      return '36px'
    case '32':
      return '32px'
    case '28':
      return '28px'
    case '24':
      return '24px'
    case '20':
      return '20px'
    case '18':
      return '18px'
    case '16':
      return '16px'
    case '14':
      return '14px'
    case '13':
      return '13px'
    case '12':
      return '12px'
    case '11':
      return '11px'
    case '10':
      return '10px'
    default:
      return fontSize
  }
})

const Typography = React.forwardRef<HTMLSpanElement, TypographyProps>(
  (
    {
      component = 'span',
      fontWeight = 400,
      fontSize = 'inherit',
      customFontSize,
      fontFamily,
      color,
      textAlign,
      lineHeight = '1.5',
      h,
      letterSpacing,
      w,
      textTransform,
      ...extraProps
    },
    ref,
  ) => {
    const { theme } = useColorMode()
    const profileTheme = theme == 'dark' ? darkTheme : mainTheme

    const newProps = {
      fs: customFontSize || getFontSize(fontSize),
      ff: fontFamily ? profileTheme.fonts[fontFamily] : undefined,
      fw: fontWeight,
      c: color && color !== 'inherit' ? profileTheme.colors[color] : undefined,
      ta: textAlign,
      h: h,
      lh: lineHeight || '1.5',
      ls: letterSpacing,
      w: w,
      tt: textTransform,
      ...extraProps,
    }

    return <StyledSpan ref={ref} as={component === 'link' ? Link : component} {...newProps} />
  },
)

export default Typography
