import axios from 'axios'

export default {
  getAffinityExternalSource: (teamSlug: string) => {
    return axios.get(`/api/external_sources/affinity`, {
      data: {
        team_slug: teamSlug,
      },
    })
  },

  addExternalSource: (sourceData: any, teamSlug: string) => {
    return axios.post(`/api/external_sources`, {
      team_slug: teamSlug,
      source: sourceData,
    })
  },

  deleteExternalSource: (uuid: string, teamSlug: string) => {
    return axios.delete(`/api/external_sources/${uuid}?team=${teamSlug}`)
  },

  updateExternalSource: (sourceData: any, teamSlug: string) => {
    return axios.put(`/api/external_sources/${sourceData.uuid}`, {
      team_slug: teamSlug,
      source: sourceData,
    })
  },

  getRecentAudits: (teamSlug: string, externalSourceId: string) => {
    return axios.get(`/api/external_syncs`, {
      params: { team_slug: teamSlug, option: 'recent', external_source_id: externalSourceId },
    })
  },
}
