import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import { useModal } from 'global/Modal'
import Modal, { OnHideProp } from 'global/Modal/Modal'
import Typography from 'global/Typography'

import { CompanyBlueprint, GlobalPersonBlueprint, PersonBlueprint } from 'utils/types'

import CommentsAndActivity from './CommentsAndActivity/v2'
import LikesTracker from './LikesTracker'

const Container = styled.div`
  ${tw`flex flex-row items-stretch justify-center`}
`

const CommentsContainer = styled.div`
  ${tw`m-2`}
  min-width: 25ch;
`

export interface UseAskContextModalProps {
  sourceType: 'GlobalPerson' | 'Company'
  teamSlug: string
  sourceId: number | string
  source: PersonBlueprint | CompanyBlueprint | GlobalPersonBlueprint
}

const AsksContextModal: React.VFC<UseAskContextModalProps & OnHideProp> = ({
  source,
  sourceId,
  sourceType,
  teamSlug,
  onHide,
}) => {
  return (
    <Modal
      show={true}
      noPadding
      header={
        <div className="flex gap-2 items-center">
          <div>
            <Avatar
              size="45px"
              src={
                ('image_url' in source
                  ? source.image_url
                  : 'logo_url' in source
                  ? source.logo_url
                  : undefined) || undefined
              }
              name={source.name}
            />
          </div>
          <div className="flex flex-col">
            <Typography fontSize="14" fontWeight={600}>
              {source.name}
            </Typography>
            {'headline' in source && source.linkedin_url && (
              <a
                href={source.linkedin_url}
                target="_blank"
                rel="nofollow noopener noreferrer"
                className={'cursor-pointer'}
                onClick={(e) => e.stopPropagation()}
              >
                <Typography
                  color="fog_rain"
                  fontSize="12"
                  className="block cursor-pointer"
                  lineHeight="1.215"
                >
                  {source.headline}
                  <Typography fontSize="10">
                    <i className="fab fa-linkedin ml-1"></i>
                  </Typography>
                </Typography>
              </a>
            )}
          </div>
        </div>
      }
      onHide={onHide}
    >
      <Container>
        <LikesTracker
          teamSlug={teamSlug}
          vertical
          dismissModal={onHide}
          companyId={sourceType === 'Company' ? sourceId : undefined}
          personId={sourceType === 'GlobalPerson' ? sourceId : undefined}
        />
        <CommentsContainer>
          <CommentsAndActivity
            attachableType={sourceType}
            attachableId={sourceId}
            teamSlug={teamSlug}
          />
        </CommentsContainer>
      </Container>
    </Modal>
  )
}

export const useAskContextModal = (props: UseAskContextModalProps | null) => {
  const { showModal } = useModal()
  const { canViewConnections } = useAccessControl(props?.teamSlug)

  const showAskContext = () => {
    if (!canViewConnections) return
    if (!props) return <></>

    showModal((resolve) => <AsksContextModal onHide={resolve} {...props} />, 'ask-context-modal')
  }

  return showAskContext
}

export default AsksContextModal
