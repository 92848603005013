import axios from 'axios'

export default {
  exportResourcePeopleList: (itemUuids: string[], listUuid: string) => {
    return axios.post(`/api/v2/resource_list/${listUuid}/export`, { item_uuids: itemUuids})
  },
  addElementToList: (listUuid: string, data: any, itemUuid?: string) => {
    return axios.post(`/api/v2/resource_list`, {
      list_uuid: listUuid,
      item_uuid: itemUuid,
      data: data,
    })
  },

  getResourceListTags: (listUuid: string) => {
    return axios.get(`/api/v2/resource_list/${listUuid}/tags`)
  },
  getItem: (itemUuid: string) => {
    return axios.get(`/api/v2/resource_list/${itemUuid}`)
  },
  import: (uploadUrl: string, uploadUuid: string, listUuid: string) => {
    return axios.post(`/api/v2/resource_list/${listUuid}/import`, {
      upload_url: uploadUrl,
      upload_uuid: uploadUuid,
    })
  },
  importStatus: (importJobId: number, listUuid: string) => {
    return axios.get(`/api/v2/resource_list/${listUuid}/import_status?import_job_id=${importJobId}`)
  },
}
