import React, { MouseEventHandler, useState } from 'react'

import cx from 'classnames'
import Fade from 'react-reveal/Fade'
import {
  Popover as ReactTinyPopover,
  PopoverProps as ReactTinyPopoverProps,
} from 'react-tiny-popover'
import styled, { useTheme } from 'styled-components'
import tw from 'twin.macro'

import Delayed from 'components/Delayed'

const PopoverContainer = styled.div<{ popoverBgColor: string }>`
  ${tw`rounded p-2.5 shadow-lg border border-solid mt-1`}
  border-color: ${({ theme }) => theme.colors.border}};

  max-height: 300px;
  background: ${({ popoverBgColor }) => popoverBgColor};
`

export interface PopoverProps
  extends Omit<ReactTinyPopoverProps, 'isOpen' | 'content' | 'onClickOutside' | 'children'> {
  trigger: React.ReactElement<HTMLButtonElement>
  disabled?: boolean
  closeOnOutsideClick?: boolean
  children: ((props: { closePopover: () => void }) => React.ReactNode) | React.ReactNode
  popoverBgColor?: string
}

const Popover: React.VFC<PopoverProps> = ({
  children,
  trigger,
  disabled = false,
  closeOnOutsideClick = true,
  popoverBgColor,
  ...restProps
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme()

  popoverBgColor ||= theme.colors.cardBackground

  let renderedChildren: React.ReactNode

  if (typeof children === 'function') {
    renderedChildren = children({ closePopover: () => setIsOpen(false) })
  } else {
    renderedChildren = children
  }

  return (
    <ReactTinyPopover
      {...restProps}
      isOpen={isOpen}
      content={
        <div>
          <Delayed waitBeforeShow={50}>
            <Fade bottom distance={`20px`} duration={200}>
              <PopoverContainer popoverBgColor={popoverBgColor}>
                {renderedChildren}
              </PopoverContainer>
            </Fade>
          </Delayed>
        </div>
      }
      containerStyle={{ zIndex: '100' }}
      positions={['bottom', 'right', 'left', 'top']}
      align="start"
      onClickOutside={() => {
        if (closeOnOutsideClick) setTimeout(() => setIsOpen(false))
      }}
    >
      {React.cloneElement(trigger as React.ReactElement, {
        disabled: disabled,
        className: cx(trigger.props.className, { 'cursor-pointer': !disabled }),
        onClick: (e: any) => {
          e.stopPropagation()
          setIsOpen(!isOpen)
        },
      })}
    </ReactTinyPopover>
  )
}

export default Popover
