import React, { useState } from 'react'
import { Checklist } from 'global/Input/Checklist'
import { Container } from '../styles'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import Typography from 'global/Typography'
import { CompanyListType } from 'utils/types'

interface Props {
  companyLists: CompanyListType[]
  isFetching: boolean
  onInsert: (lists: CompanyListType[]) => void
  teamSlug: string
}

const CompanyListTab: React.VFC<Props> = ({ companyLists, isFetching, onInsert, teamSlug }) => {
  const [query, setQuery] = useState('')
  const [selectedUuids, setSelectedUuids] = useState<string[]>([])
  const selectedLists = companyLists.filter((cl) => selectedUuids.includes(cl.uuid))
  const selectedAll = companyLists.length === selectedUuids.length

  const handleInsert = () => {
    onInsert(selectedLists)
    setSelectedUuids([])
  }

  const setAll = () => {
    if (selectedAll) setSelectedUuids([])
    else setSelectedUuids(companyLists.map((cl) => cl.uuid))
  }

  return (
    <div className="px-1">
      <div className="mt-1">
        <SearchInput
          onChange={(e) => setQuery(e.target.value)}
          defaultValue={query}
          value={query}
          lightBg
          placeholder="Search company lists"
          maxLength={20}
          fontSize="12px"
        />
      </div>
      <Container className="absolute-custom-scrollbar">
        <Checklist
          className="my-1"
          items={companyLists
            .filter((cl) => cl.name.includes(query))
            .map((cl) => ({
              label: (
                <div className="flex items-center my-1">
                  <Typography fontSize="12">
                    {cl.name} ({cl.company_list_items.length})
                  </Typography>
                </div>
              ),
              value: cl.uuid,
              checked: selectedUuids.includes(cl.uuid),
            }))}
          onChange={(items) => setSelectedUuids(items.filter((i) => i.checked).map((i) => i.value))}
          isLoading={isFetching}
        />
      </Container>
      <div>
        <CabalButton
          variant="primary"
          size="small"
          disabled={!selectedLists.length}
          className="mr-2 mt-3"
          onClick={handleInsert}
          // leftIcon={<i className="far fa-edit" />}
        >
          Insert
        </CabalButton>
        <Typography fontSize="12" color="purple" onClick={setAll} className="cursor-pointer">
          {selectedAll ? 'Deselect All' : 'Select All'}
        </Typography>
      </div>
    </div>
  )
}

export default React.memo(CompanyListTab)
