import axios from 'axios'

import { IImportData } from 'containers/ListIndexView/PortfolioList/PortfolioAddWidget/ImportSection'

import { CompaniesFiltersType } from 'utils/types'

export default {
  portfolioGetCompanies: (
    listUuid: string,
    page = 1,
    filters?: CompaniesFiltersType,
    params = {},
  ) => {
    return axios.get(`/api/v2/portfolio/${listUuid}`, {
      params: {
        page,
        filters: JSON.stringify(filters),
        ...params,
      },
    })
  },
  portfolioImport: (uploadUrl: string, uploadUuid: string, listUuid: string) => {
    return axios.post(`/api/v2/portfolio/${listUuid}/import`, {
      upload_url: uploadUrl,
      upload_uuid: uploadUuid,
    })
  },
  portfolioAddCompany: (company: any, listUuid: string) => {
    return axios.post(`/api/v2/portfolio/${listUuid}/add`, {
      company_name: company.name,
      website: company.website,
    })
  },
  portfolioImportStatus: (importJobId: number, listUuid: string) => {
    return axios.get<IImportData>(
      `/api/v2/portfolio/${listUuid}/import_status?import_job_id=${importJobId}`,
    )
  },
  portfolioCreateCompany: (company: any, listUuid: string) => {
    const params = {
      investor_company: {
        ...company.portfolioCompany,
        owner_uuids: company.ownerUuids,
      },
    }
    return axios.post(`/api/v2/portfolio/${listUuid}`, params)
  },
  portfolioUpdateCompany: (company: any, listUuid: string) => {
    const params = {
      investor_company: {
        ...company.portfolioCompany,
        owner_uuids: company.ownerUuids,
      },
    }
    return axios.put(`/api/v2/portfolio/${listUuid}`, params)
  },
  portfolioBulkActions: (listUuid: string, params: any) => {
    return axios.put(`/api/v2/portfolio/${listUuid}/bulk_actions`, params)
  },
}
