import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import {
  ABBREVIATED_OFFICE_MAP,
  ABBREVIATED_ROLE_FUNCTION_MAP,
} from 'containers/ListIndexView/constants'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'

const Pill = styled.div<{
  backgroundColor: string
  color: string
}>`
  ${tw`flex items-center justify-center rounded-full gap-1 py-1 px-1.5`}
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-size: 10px;
`

const Tags = ({ tags }: { tags: string[] }) => {
  if (!tags || tags?.length === 0) return null
  const truncatedTag = tags[0].length > 5 ? `${tags[0].substring(0, 5)}...` : tags[0]

  return (
    <div className="flex flex-wrap gap-1">
      <Pill color="#E4307C" backgroundColor="#FBE2EC">
        <i className="far fa-tag fa-fw fa-sm" />
        {truncatedTag}
      </Pill>
    </div>
  )
}

const Role = ({ role }: { role?: string }) => {
  if (!role) return null

  return (
    <div className="flex flex-wrap gap-1">
      <Pill color="#5C69D1" backgroundColor="#DCE5FB">
        {ABBREVIATED_ROLE_FUNCTION_MAP[role as keyof typeof ABBREVIATED_ROLE_FUNCTION_MAP]?.icon}
        {ABBREVIATED_ROLE_FUNCTION_MAP[role as keyof typeof ABBREVIATED_ROLE_FUNCTION_MAP]?.title}
      </Pill>
    </div>
  )
}

const Office = ({ office }: { office?: string }) => {
  if (!office) return null

  return (
    <div className="flex flex-wrap gap-1">
      <Pill color="#53AF65" backgroundColor="#E8F5EB">
        <i className="far fa-check fa-fw fa-sm" />
        {ABBREVIATED_OFFICE_MAP[office as keyof typeof ABBREVIATED_OFFICE_MAP]}
      </Pill>
    </div>
  )
}

const Count = ({ tags }: { tags?: string[] }) => {
  const count = (tags?.length || 1) - 1
  if (count <= 0) return null

  const tooltipLabel = (
    <div className="flex flex-wrap gap-1">
      {tags?.length &&
        tags?.length > 1 &&
        tags.slice(1).map((tag, index) => <Tags tags={[tag]} key={index} />)}
    </div>
  )

  return (
    <Tooltip label={tooltipLabel}>
      <Typography fontSize="12" color="rain">
        +{count}
      </Typography>
    </Tooltip>
  )
}

const JobsDataTags = ({ job }) => {
  const { tags, office_location } = job
  const role = job.function

  return (
    <div>
      <div className="flex flex-wrap items-center gap-1">
        <Tags tags={tags} />
        <Role role={role} />
        <Office office={office_location} />
        <Count tags={tags} />
      </div>
    </div>
  )
}

export default JobsDataTags
