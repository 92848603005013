import React from 'react'

import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useAdvisorship, useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { numWithCommas } from 'utils/ui'

const CompleteProfile = ({
  sharedConnections,
  connectionsCount,
  onShareConnections,
  saveAdvisorMutation,
  savingAdvisor,
  working,
}: {
  sharedConnections: boolean
  connectionsCount: number
  onShareConnections: () => void
  saveAdvisorMutation: () => void
  working: boolean
  savingAdvisor: boolean
}) => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { advisor } = useAdvisorship(teamSlug)
  const history = useHistory()
  const [connectionsShared, setConnectionsShared] = React.useState(sharedConnections)

  const addConnectionsReminderMutation = useMutation(
    () => callApi(api.addConnectionFromDesktop, teamSlug),
    {
      onSuccess: () => {
        cabalToast({
          style: 'success',
          content: `Reminder sent`,
        })
        saveAdvisorMutation()
      },
    },
  )

  const authorizeGoogleContacts = () => {
    let url = ''
    if (team) {
      url = `/auth/gcontacts?return_to=/${team.slug}?ready=google`
    } else {
      url = `/auth/gcontacts?return_to=/home?ready=google`
    }
    window.location.assign(url)
  }

  const onConnectionShare = () => {
    setConnectionsShared(true)
    onShareConnections()
  }

  return (
    <div>
      <div className="flex flex-col items-center p-5 space-y-5">
        <div className="mt-5">
          <Avatar size="48" name={advisor?.name} src={advisor?.avatar_url} />
        </div>

        {connectionsShared ? (
          <>
            <Typography fontSize="14">
              Share your connections to find people you know in {team?.name}'s pipeline.
            </Typography>

            <div className="flex items-center justify-center gap-2 w-full">
              <CabalButton
                working={working}
                className="w-1/3"
                onClick={onConnectionShare}
                data-testid="welcome-card-share-connections"
              >
                Share {numWithCommas(connectionsCount)} Connections
              </CabalButton>
            </div>
          </>
        ) : (
          <>
            <div className="hidden sm:block ">
              <Typography fontSize="14">
                Add your connections to find people you know in {team?.name}'s pipeline.
              </Typography>

              <div className="flex items-center justify-center gap-2 w-full mt-4">
                <CabalButton
                  leftIcon={<i className="fab fa-linkedin" />}
                  data-testid="add_linkedin_connections"
                  onClick={() => {
                    saveAdvisorMutation()
                    window.open(
                      '/add_connections',
                      '_blank',
                      `height=${window.screen.availHeight || '1000'},width=480,left=${
                        window.screen.availWidth - 480
                      }`,
                    )

                    setTimeout(() => {
                      history.replace(`${teamSlug}/page?ready=linkedin`)
                    }, 3000)
                  }}
                  disabled={savingAdvisor}
                >
                  Add Linkedin Connections
                </CabalButton>
              </div>
            </div>
            <div className="sm:hidden flex flex-col">
              <Typography fontSize="14" className="text-center">
                Log into Cabal from your desktop to sync your LinkedIn connections.
              </Typography>

              <div className="flex items-center justify-center gap-2 w-full mt-4">
                <CabalButton
                  data-testid="add_linkedin_connections_mobile"
                  onClick={() => addConnectionsReminderMutation.mutate()}
                  disabled={savingAdvisor}
                >
                  Send me a reminder email
                </CabalButton>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="pb-5 flex items-center justify-center">
        <CabalButton
          variant="tertiary"
          onClick={saveAdvisorMutation}
          disabled={savingAdvisor}
          data-testid="onboarding-skip-complete-your-profile"
        >
          <Typography fontSize="12">I'll do this later</Typography>
        </CabalButton>
      </div>
    </div>
  )
}

export default CompleteProfile
