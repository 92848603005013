import RecipientsField from 'components/SendMessage/RecipientsField'
import { CcButton } from 'components/SendMessage/styles'
import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Loading from 'global/Loading'
import CKEditor from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'
import { isEqual } from 'lodash'
import objectHash from 'object-hash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useSetState } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'
import api, { callApi } from 'utils/api'
import { useDebouncedEffect } from 'utils/hooks/useDebouncedEffect'
import { MessageModel, MessagePersonalizationBlueprint, MessageRecipient } from 'utils/types'

const Container = styled.div`
  ${tw`flex flex-col gap-2 flex-1 p-3 relative`}

  background: ${({ theme }) => theme.colors.composer_bg};
`

const AutosavingIndicator = styled(Typography)`
  ${tw`absolute right-6 bottom-6 p-3 flex gap-2 bg-black bg-opacity-20 rounded-lg z-10`}
`

const StyledLoading = styled(Loading)`
  ${tw`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
`

interface Props {
  personalization: MessagePersonalizationBlueprint
  goBackToComposer: () => void
  teamSlug: string
  message: MessageModel
}

const EditPersonalization: React.VFC<Props> = ({
  personalization: currentPersonalization,
  goBackToComposer,
  teamSlug,
  message,
}) => {
  const [personalization, setPersonalization] = useSetState<MessagePersonalizationBlueprint>()
  const [showCc, setShowCc] = useState(false)

  const personalizationHash = useMemo(() => objectHash(personalization), [personalization])

  const { isFetching, data: getMessagePersonalizationData } = useQuery(
    ['getMessagePersonalization', teamSlug, message?.uuid, currentPersonalization.uuid],
    () =>
      callApi(api.getMessagePersonalization, teamSlug, message!.uuid, currentPersonalization.uuid),
    {
      onSuccess: ({ personalization: _personalization }) => {
        setPersonalization(_personalization)
        setShowCc(!!_personalization?.cc?.length)
      },
    },
  )

  const updateMessagePersonalizationMutation = useMutation(
    ['createMessagePersonalization', teamSlug, message?.uuid],
    () => {
      if (
        !message ||
        (personalization.uuid === getMessagePersonalizationData?.personalization.uuid &&
          isEqual(personalization, getMessagePersonalizationData?.personalization))
      ) {
        return Promise.resolve(null)
      }

      return callApi(
        api.updateMessagePersonalization,
        teamSlug,
        message!.uuid,
        personalization!.uuid,
        personalization,
      )
    },
  )

  useEffect(() => {
    return () => {
      updateMessagePersonalizationMutation.mutate()
    }
  }, [currentPersonalization.uuid])

  useDebouncedEffect(
    () => {
      updateMessagePersonalizationMutation.mutate()
    },
    [personalizationHash],
    1000,
  )

  const isFixedFunc = useCallback(
    (r: MessageRecipient) => {
      switch (r.type) {
        case 'email':
          return r.value.email === personalization.recipient.email

        default:
          return r.value === personalization.recipient.uuid
      }
    },
    [personalization?.recipient?.email, personalization?.recipient?.uuid],
  )

  if (isFetching) {
    return (
      <Container>
        <StyledLoading />
      </Container>
    )
  }

  return (
    <Container>
      <>
        <TextInput
          data-testid="personalization-subject"
          label="Subject"
          labelPosition="left"
          value={personalization.subject}
          onChange={(e) => setPersonalization({ subject: e.currentTarget.value })}
        />
        <div className="flex items-center align-middle w-full">
          <div className="flex-1">
            <RecipientsField
              onSelect={(r) => setPersonalization({ to: r || [] })}
              allowGroups={false}
              allowCustomEmail={true}
              teamSlug={teamSlug}
              selected={personalization.to}
              label="To"
              isFixedFunc={isFixedFunc}
            />
          </div>
          <CcButton
            fontSize="12"
            onClick={() => setShowCc(!showCc)}
            component="button"
            lineHeight="1"
            color={'fog'}
          >
            Cc
          </CcButton>
        </div>
        {showCc && (
          <RecipientsField
            allowGroups={false}
            allowCustomEmail={true}
            allowAdvisors={true}
            onSelect={(r) => setPersonalization({ cc: r || [] })}
            selected={personalization.cc}
            teamSlug={teamSlug}
            label="Cc"
          />
        )}
        <CKEditor
          windowKey="personalization-body"
          value={personalization.body}
          onChange={(v) => setPersonalization({ body: v })}
        />
        {updateMessagePersonalizationMutation.isLoading && (
          <AutosavingIndicator>
            <Loading size="15px" /> auto saving
          </AutosavingIndicator>
        )}
      </>
      <div className="flex justify-end">
        <CabalButton onClick={goBackToComposer}>Done</CabalButton>
      </div>
    </Container>
  )
}

export default EditPersonalization
