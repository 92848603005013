import axios from 'axios'

export default {
  getUniqueAccessLink: (uuid: string) => {
    return axios.get(`/api/unique_access_links/${uuid}`)
  },
  getIsUniqueAccessLink: (uuid: string) => {
    return axios.get(`/api/unique_access_links/${uuid}/auth`)
  },
  resendUniqueAccessLink: (uuid: string) => {
    return axios.post(`/api/unique_access_links/${uuid}/resend`)
  },
}
