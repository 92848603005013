import React from 'react'

import CabalButton from 'global/CabalButton'
import { EmailSnippet } from 'utils/types'
import EmptyState from 'global/EmptyState'
import Typography from 'global/Typography'

import SnippetsList from './SnippetsList'
import EmptyFeedImage from '../../../images/empty-feed@2x.png'

interface Props {
  disabled: boolean
  snippets: EmailSnippet[]
  onClickAdd: () => void
  onClickEdit: (snippet: EmailSnippet) => void
  onClickDelete: (snippet: EmailSnippet) => void
}

const ViewSnippets: React.VFC<Props> = ({
  disabled,
  snippets,
  onClickAdd,
  onClickEdit,
  onClickDelete,
}) => {
  return snippets && snippets.length > 0 ? (
    <>
      <SnippetsList
        disabled={disabled}
        snippets={snippets}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
      />

      <div className="mt-4 mb-3 flex justify-end">
        <CabalButton variant="primary" onClick={disabled ? undefined : () => onClickAdd()}>
          Add Snippet
        </CabalButton>
      </div>
    </>
  ) : (
    <EmptyState
      image={<img src={EmptyFeedImage} />}
      heading={'Add your first snippet'}
      body={
        <div className="text-center">
          <Typography textAlign="center" component="h5" className="mb-11">
            Create snippets to save time when messaging your investors and advisors.
          </Typography>

          <div className="flex justify-center">
            <CabalButton variant="primary" onClick={disabled ? undefined : () => onClickAdd()}>
              Add Snippet
            </CabalButton>
          </div>
        </div>
      }
    />
  )
}

export default ViewSnippets
