import React, { useEffect, useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import { Redirect, useParams } from 'react-router-dom'
import { useSearchParam, useSetState } from 'react-use'
import validator from 'validator'

import { FormDetails, RequestIntroForm } from 'containers/Portfolio/MemberView/RequestIntroModal'
import CabalButton from 'global/CabalButton'
import Card from 'global/Card'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { useCurrentUser, useUniqueAccessLink } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { AdvisorModel } from 'utils/types'
import { IntroRequest } from 'utils/types/investor'

import LoggedInSection from './LoggedInSection'
import { CardWidth, Container } from './styles'

const RequestConfirmation: React.VFC = () => {
  const { isLoading: isLoadingUser } = useCurrentUser()

  const {
    uuid: uniqueAccessLinkUuid,
    uniqueAccessLinkData,
    isLoadingUniqueAccessLink: isLoadingUniqueAccessLink,
  } = useUniqueAccessLink()

  const teamSlug = useSearchParam('team_slug') || ''
  const { advisor_uuid: advisorUuid } = useParams<{ advisor_uuid: string }>()

  const [requestUuid, setRequestUuid] = useState<string>()
  const [advisorDetails, setAdvisorDetails] = useState<AdvisorModel>()
  const [details, setDetails] = useSetState<FormDetails>()
  const [linkedinUrlValid, setLinkedinUrlValid] = useState(false)

  useEffect(() => {
    setDetails({
      firstName: uniqueAccessLinkData?.recipient_first_name || '',
      lastName: uniqueAccessLinkData?.recipient_last_name || '',
      linkedinUrl: uniqueAccessLinkData?.recipient_linkedin_url || '',
    })
    setLinkedinUrlValid(validator.isURL(uniqueAccessLinkData?.recipient_linkedin_url || ''))
  }, [isLoadingUser, isLoadingUniqueAccessLink])

  const { isLoading: isLoadingAdvisor } = useQuery(
    ['getAdvisor'],
    () => callApi(api.getAdvisor, advisorUuid, teamSlug, uniqueAccessLinkUuid),
    {
      onSuccess: ({ advisor }: { advisor: AdvisorModel }) => {
        setAdvisorDetails(advisor)
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
      enabled: !!advisorUuid,
    },
  )

  const { mutate: requestIntroToAdvisor, isLoading: isRequestingIntroToAdvisor } = useMutation(
    ['requestIntroToAdvisor'],
    () => {
      return callApi(
        api.requestIntroToAdvisor,
        advisorUuid,
        teamSlug,
        details,
        uniqueAccessLinkUuid,
      )
    },
    {
      onSuccess: ({ intro_request }: { intro_request: IntroRequest }) => {
        setRequestUuid(intro_request.secret_uuid)
      },
      onError: (error) => {
        const errMsg = error?.response?.data?.error
        cabalToast({
          style: 'error',
          content: `Something went wrong${errMsg ? ': ' + errMsg : '!'}`,
        })
      },
    },
  )

  if (isLoadingUser || isLoadingAdvisor || isLoadingUniqueAccessLink)
    return (
      <Container>
        <Loading />
      </Container>
    )

  if (requestUuid)
    return (
      <Redirect
        to={`/intro-requests/${requestUuid}/sent?advisor_uuid=${advisorUuid}&team_slug=${teamSlug}`}
      />
    )

  const disabled =
    !details.linkedinUrl ||
    !linkedinUrlValid ||
    !details.context ||
    isLoadingAdvisor ||
    isRequestingIntroToAdvisor ||
    !details.firstName ||
    !details.lastName

  return (
    <Container>
      <LoggedInSection />
      <CardWidth>
        <Card className="p-5 w-full">
          <Typography
            fontSize="16"
            fontWeight={600}
            lineHeight="1.2"
            data-testid="request-intro-title"
          >
            {`Request intro to ${advisorDetails?.name}`}
          </Typography>
          <RequestIntroForm
            advisorName={advisorDetails?.name || ''}
            advisorAvatar={advisorDetails?.avatar_url || ''}
            details={details}
            setDetails={setDetails}
            setLinkedinUrlValid={setLinkedinUrlValid}
            isIntroToCandidate={!!advisorUuid}
          />
          <CabalButton
            className="mt-3 w-full"
            onClick={() => requestIntroToAdvisor()}
            working={isRequestingIntroToAdvisor}
            disabled={disabled}
            data-testid="request-confirmation-send-btn"
          >
            Send
          </CabalButton>
          <Typography fontSize="12" fontWeight={600} className="mx-1">
            {`Request will be sent to ${uniqueAccessLinkData.sender_name}`}
          </Typography>
        </Card>
      </CardWidth>
    </Container>
  )
}

export default RequestConfirmation
