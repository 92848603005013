import React, { useEffect } from 'react'

import { useMutation } from 'react-query'

import CabalButton from 'global/CabalButton'
import CKEditor from 'global/TextEditor/ckeditor'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import useDeepCompareState from 'utils/hooks/useDeepCompareState'
import { absoluteHtmlContent } from 'utils/html'
import { CurrentUserProfile } from 'utils/types'

interface Props {
  user: CurrentUserProfile
  reloadUser: () => void
}

const EmailSignature: React.VFC<Props> = ({ user, reloadUser }) => {
  const [emailSignature, setEmailSignature] = useDeepCompareState(user.email_signature)

  const { mutate: updateUser, isLoading: updatingUser } = useMutation(
    ['updateUser'],
    () => callApi(api.updateCurrentProfile, { email_signature: emailSignature }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Successfully updated the email signature' })
        reloadUser()
      },
    },
  )

  useEffect(() => {
    setEmailSignature(user.email_signature)
  }, [user.email_signature])

  return (
    <>
      <CKEditor
        value={emailSignature || ''}
        onChange={(v) => setEmailSignature(absoluteHtmlContent(v))}
      />
      <div className="flex justify-end pt-4">
        <CabalButton
          variant="primary"
          onClick={() => updateUser()}
          disabled={updatingUser || emailSignature === user.email_signature}
        >
          {'Save'}
        </CabalButton>
      </div>
    </>
  )
}

export default EmailSignature
