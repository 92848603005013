import React from 'react'
import { useMutation } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'
import cx from 'classnames'
import { isEqual } from 'lodash'

import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import api, { callApi } from 'utils/api'
import Loading from 'global/Loading'
import CabalButton from 'global/CabalButton'
import { useComposerState } from '../Context'

export type PersonalizationRecipient = {
  type: 'advisor' | 'email' | 'user'
  first_name: string
  last_name: string
  email: string
  uuid: string
  avatar_url?: string
  name: string
}

const RecipientCard = styled.button<{ active: boolean }>`
  ${tw`flex w-full rounded-lg items-center text-left px-3 py-1.5 gap-3 justify-between`}

  background-color: ${({ theme, active }) =>
    active ? theme.buttons.primary.bg_color : theme.colors.light_shadow};
  color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.primary)};
`

const StyledLoading = styled(Loading)`
  ${tw`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
`

const Personalizations: React.VFC = () => {
  const {
    composerState,
    currentPersonalization,
    personalizations,
    setCurrentPersonalizationUuid,
    refetchPersonalizations,
    setPersonalization,
    individualRecipients,
  } = useComposerState()

  const message = composerState?.message
  const messageUuid = message!.uuid
  const teamSlug = message!.team_slug

  const createPersonalizationMutation = useMutation(
    ['createMessagePersonalization', teamSlug, messageUuid],
    (recipient: PersonalizationRecipient) =>
      callApi(api.createMessagePersonalization, teamSlug, messageUuid, { recipient }),
    {
      onSuccess: ({ personalization }) => {
        setPersonalization(personalization.uuid, personalization)
        setCurrentPersonalizationUuid(personalization.uuid)
      },
    },
  )

  const deletePersonalizationMutation = useMutation(
    ['deleteMessagePersonalization', teamSlug, messageUuid],
    (personalization_uuid: string) =>
      callApi(api.deleteMessagePersonalization, teamSlug, messageUuid, personalization_uuid),
    {
      onSuccess: (_, personalization_uuid) => {
        if (currentPersonalization?.uuid === personalization_uuid)
          setCurrentPersonalizationUuid(undefined)
        refetchPersonalizations()
      },
    },
  )

  return (
    <div className="flex flex-col gap-2 my-2 custom-scrollbar overflow-auto">
      <RecipientCard
        active={!currentPersonalization}
        onClick={() => setCurrentPersonalizationUuid(undefined)}
      >
        <div className="flex items-center gap-3">
          <div className="w-6 text-center">
            <Typography fontSize="14">
              <i className="far fa-envelope" />
            </Typography>
          </div>
          <Typography fontWeight={600} fontSize="12">
            Default message
          </Typography>
        </div>
      </RecipientCard>
      {individualRecipients.map((recipient) => {
        const personalization = personalizations?.find((p) => p.recipient.uuid === recipient.uuid)
        const active = currentPersonalization?.recipient.uuid === recipient.uuid
        const working =
          (createPersonalizationMutation.variables?.uuid === recipient.uuid &&
            createPersonalizationMutation.isLoading) ||
          (deletePersonalizationMutation.variables === personalization?.uuid &&
            deletePersonalizationMutation.isLoading)

        return (
          <RecipientCard
            active={active}
            key={recipient.uuid}
            onClick={() => {
              !personalization
                ? createPersonalizationMutation.mutate(recipient)
                : setCurrentPersonalizationUuid(personalization.uuid)
            }}
            disabled={working}
            className={cx({ 'opacity-50 relative': working })}
          >
            {working && <StyledLoading size="24px" />}
            <div className="flex items-center gap-3">
              <Avatar src={recipient.avatar_url} name={recipient.name} size="1.5rem" round />
              <div className="flex flex-col">
                <Typography fontWeight={600} fontSize="12">
                  {recipient.name}
                </Typography>
                <Typography fontSize="12" color={active ? undefined : 'fog_rain'}>
                  {recipient.email}
                </Typography>
              </div>
            </div>
            {!!personalization && (
              <div>
                <CabalButton
                  variant="tertiary"
                  padding="0"
                  leftIcon={
                    <Typography color={active ? 'white' : 'primary'}>
                      <i className="fas fa-star-christmas" />
                    </Typography>
                  }
                  color="inherit"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    confirm('Are you sure?') &&
                      deletePersonalizationMutation.mutate(personalization.uuid)
                  }}
                  disabled={
                    deletePersonalizationMutation.isLoading &&
                    deletePersonalizationMutation.variables === personalization.uuid
                  }
                  tooltip={
                    <>
                      Message personalized for this recipient <br />
                      <br /> Click to delete personalization
                    </>
                  }
                />
              </div>
            )}
          </RecipientCard>
        )
      })}
    </div>
  )
}

export default React.memo(Personalizations, isEqual)
