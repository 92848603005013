import React, { useState } from 'react'

import axios from 'axios'
import S3Upload from 'react-s3-uploader/s3upload'

import api from 'utils/api'

import { UploadModel } from '../types'

export interface UploadFileProps {
  file: File
  attachTo?: string
  /**
   * any extra params to be sent to /api/uploads
   */
  params?: Record<string, unknown>
  /**
   * Callack for upload progress
   * @param {number} p - The percentage of progress
   */
  onUploadProgress?: (p: number) => void
}
export type UploadFile = (props: UploadFileProps) => Promise<UploadModel>

const useUploadFile = () => {
  const [uploading, setUploading] = useState(false)
  const propsRef = React.useRef<UploadFileProps>()
  const uploadRef = React.useRef<S3Upload>()

  const createUploadObject = React.useCallback(
    (data: any, file: File, resolve: (u: UploadModel) => void, reject: (err: any) => void) => {
      const url = data.signedUrl

      axios
        .post(`/api/uploads`, {
          url,
          name: file.name,
          size: file.size,
          mime_type: file.type,
          attach_to: propsRef.current?.attachTo,
          ...propsRef.current?.params,
        })
        .then(({ data }) => {
          propsRef.current?.onUploadProgress?.(100)
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
        .finally(() => setUploading(false))
    },
    [propsRef],
  )

  const uploadFile: UploadFile = (props) => {
    propsRef.current = props
    setUploading(true)

    return new Promise<UploadModel>((resolve, reject) => {
      const file = propsRef.current?.file
      if (!file) throw 'no file to upload'

      if (window.QA) {
        api.createQaUpload(file).then(({ data }) => {
          resolve(data)
          propsRef.current?.onUploadProgress?.(100)
          setUploading(false)
        })
        return
      }

      uploadRef.current = new S3Upload({
        files: [file],
        signingUrl: `/api/uploads/new`,
        signingUrlMethod: 'GET',
        contentDisposition: 'attachment',
        onProgress: (p) => {
          propsRef.current?.onUploadProgress?.(Math.min(90, p))
        },
        onError: () => {
          setUploading(false)
          reject()
        },
        onFinishS3Put: (d: any) => createUploadObject(d, file, resolve, reject),
        uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
        server: '',
        scrubFilename: (filename) => {
          return filename.replace(/[^\w\d_\-\.]+/gi, '_')
        },
      })
    })
  }

  return { uploadFile, uploading }
}

export default useUploadFile
