import * as React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import axios from 'axios'

import { SuccessPrimary, SignupContainer, H3, Divider } from '../SignupScreen/styles'
import cx from 'classnames'

import Footer from '../SignupScreen/footer'
import GoogleIcon from '../../images/google-icon.png'
import MicrosoftIcon from '../../images/microsoft-icon.png'
import CabalButton from 'global/CabalButton'

const Container = styled.div`
  max-width: 375px;
  margin: 0 auto;
  margin-top: 46px;
`

const LogoContainer = styled.div`
  ${tw`text-center`}
`

const Logo = styled.img`
  ${tw`inline-block mb-3`}
  height: 64px;
  width: 64px;
  border-radius: 50%;
`

const TextPrimary = styled(H3)`
  font-size: 1rem;
  line-height: 22.4px;
  padding: 0;
`

const TextSecondary = styled(TextPrimary)`
  font-weight: 400;
  margin-bottom: 2rem;
`

const CardWrapper = styled.div`
  text-align: center;
  padding: 24px 16px 24px 16px;
  background-color: rgba(41, 51, 66, 0.64);

  @media (prefers-color-scheme: light) {
    background: #ffffff;
  }

  @media (min-width: 376px) {
    border-radius: 4px;
  }
`

const OptionText = styled.p`
  font-size: 14px;
  line-height: 19.6px;
`

export interface AcceptContainerProps {
  referralCode: any
}

const AcceptContent = (props: AcceptContainerProps) => {
  const { referralCode } = props

  const [email, setEmail] = React.useState('')
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState<null | string>(null)

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (email == '') {
      setError('Please enter your email!')
      return
    } else {
      setError(null)
    }

    axios
      .post('/auth/request_login_link', { email, referral_code: referralCode })
      .then((response) => {
        const { status } = response

        if (status === 200) {
          setSuccess(true)
        } else {
          setError(error)
        }
      })
  }

  return (
    <SignupContainer>
      <Container>
        <LogoContainer>
          {referralCode?.avatar_url && (
            <Logo alt={referralCode?.name} src={referralCode?.avatar_url} />
          )}
        </LogoContainer>

        <div className="flex items-center justify-center flex-col pb-9">
          <TextPrimary>{referralCode?.name}</TextPrimary>
          <TextSecondary>{`has invited you to use Cabal.`}</TextSecondary>
        </div>

        <CardWrapper>
          <TextSecondary>{referralCode?.description}</TextSecondary>

          <Divider />

          {success ? (
            <div>
              <SuccessPrimary className="mt-4">
                <i className="far fa-check-circle pr-3"></i>
                {`Email sent!`}
              </SuccessPrimary>
              <p className="pt-2">{`Please check your email for a sign up link`}</p>
            </div>
          ) : (
            <div>
              <a style={{ border: 0, outline: 'none' }} href="/users/auth/google_oauth2">
                <CabalButton
                  variant="auth"
                  className="mt-8"
                  leftIcon={<img src={GoogleIcon} width="18" />}
                >
                  Sign in with Google
                </CabalButton>
              </a>

              <a style={{ border: 0, outline: 'none' }} href="/auth/microsoft">
                <CabalButton
                  variant="auth"
                  className="mt-8"
                  leftIcon={<img src={MicrosoftIcon} width="18" />}
                >
                  Sign in with Microsoft
                </CabalButton>
              </a>

              <OptionText>or sign in with email</OptionText>

              <form className="mx-auto" onSubmit={onSubmit}>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your work email"
                  className={cx('mt-4', { 'ring-2 ring-red-300': error })}
                  required
                />
                <button disabled={email === ''}>Get started</button>
              </form>
            </div>
          )}
        </CardWrapper>
        <div className="pt-12 pb-8">
          <Footer withForm={false} />
        </div>
      </Container>
    </SignupContainer>
  )
}

export default AcceptContent
