import React, { useState } from 'react'

import styled from 'styled-components'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'

import Img from '../../../../assets/images/offer_img.png'

const Image = styled.img`
  height: 145px;
`

const HowItWorks = ({ onSetStep }: { onSetStep: () => void }) => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const [saving, setSaving] = useState(false)

  return (
    <div>
      <div className="p-8 grid sm:grid-cols-2 gap-4">
        <div className="flex justify-center">
          <Image src={Img} height="145" />
        </div>
        <div className="self-center">
          <Typography fontSize="14" component="p">
            Clicking{' '}
            <Typography fontWeight={600} component="span">
              Offer intro
            </Typography>{' '}
            will notify the {team?.name} team that you’re willing to help. They will then prepare a
            custom referral message for your approval.
          </Typography>
        </div>
      </div>
      <div className="pb-5 flex items-center justify-center">
        <CabalButton
          className="w-1/3"
          onClick={() => {
            setSaving(true)
            onSetStep()
          }}
          disabled={saving}
          data-testid="how-it-works-continue"
        >
          Continue
        </CabalButton>
      </div>
    </div>
  )
}

export default HowItWorks
