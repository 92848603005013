import React, { useState } from 'react'

import { UserEmail } from 'utils/types'
import Typography from 'global/Typography'
import CabalButton from 'global/CabalButton'
import Tooltip from 'global/Tooltip'

interface Props {
  email: UserEmail
  // onMarkPrimary: (email: UserEmail) => void
  onRemoveEmail: (email: UserEmail) => void
  onResendToken: (email: UserEmail) => void
}

const Mailrow: React.VFC<Props> = ({ email, /*onMarkPrimary, */ onRemoveEmail, onResendToken }) => {
  const [resent, setResent] = useState(false)

  const handleResendToken = () => {
    onResendToken(email)
    setResent(true)
  }

  const handleRemoveEmail = () => {
    onRemoveEmail(email)
  }

  return (
    <div className="flex p-2 items-center justify-between">
      <div className="flex align-center gap-2">
        <Typography fontSize="14" fontWeight={700} className="col-span-4">
          {email.email}
        </Typography>

        {email.isValidated ? (
          <Tooltip label="Email is validated">
            <Typography component="i" color="green" fontSize="16" className="fas fa-check-circle" />
          </Tooltip>
        ) : resent ? (
          <Tooltip label="Unverified. Verification link was resent to this email">
            <Typography
              component="i"
              color="yellow"
              fontSize="16"
              className="fas fa-exclamation-circle"
            />
          </Tooltip>
        ) : (
          <Tooltip label="Unverified. Verification link was sent to this email">
            <Typography
              component="i"
              color="yellow"
              fontSize="16"
              className="fas fa-exclamation-circle"
            />
          </Tooltip>
        )}
      </div>

      {!email.isUserEmail && (
        <div className="flex gap-2">
          {!email.isValidated && !resent && (
            <CabalButton variant="secondary" size="small" onClick={handleResendToken}>
              Send verification link
            </CabalButton>
          )}

          <CabalButton variant="destructive" size="small" onClick={handleRemoveEmail}>
            Remove
          </CabalButton>
        </div>
      )}

      {/* <div>
          {email.validated && email.primary ? (
            <Typography fontSize='12'>This email receives all notifications</Typography>
          ) : email.validated ? (
            <Typography
              onClick={makePrimary}
              className='cursor-pointer'
              color='purple'
              fontSize='12'
            >
              Set as primary
            </Typography>
          ) : null}
        </div> */}
    </div>
  )
}

export default Mailrow
