export const WINSITE_ENDPOINTS = {
  search: '/api/v2/winsite/search',
  companyPage: '/api/v2/winsite/company_page',
  authenticateUserForDomain: '/api/v2/winsite/authenticate_user_for_domain',
  linkedinUrl: 'https://www.linkedin.com/search/results/companies/?keywords=',
  offerVote: '/api/votes/winsite_create',
  uploadAccountProfileData: '/api/company_lists',
  fetchUploads: '/api/v2/winsite/team_uploads',
  deleteUpload: '/api/uploads',
  fetchLists: '/api/v2/winsite/lists',
}
