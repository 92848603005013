import React, { useEffect, useMemo, useState } from 'react'

import cx from 'classnames'
import compact from 'lodash/compact'
import last from 'lodash/last'
import moment from 'moment'
import pluralize from 'pluralize'
import { useQueries, useQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useComposer } from 'components/Composer'
import TimeAgo from 'components/TimeAgo'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useAdvisors, useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import {
  ConnectionBlueprint,
  GlobalPersonBlueprint,
  MessageModel,
  PersonBlueprint,
  UserBlueprint,
} from 'utils/types'

const Divider = styled.div`
  border-bottom: ${({ theme }) => theme.border};
`

const tranparentBackgroundStatuses = ['default', 'intro_requested']

const AdvisorPillContainer = styled.div<{ status: Props['status'] }>`
  ${tw`flex rounded-full py-0.5 px-1.5 gap-1 items-center cursor-pointer`}

  border: 1px solid ${({ theme, status }) =>
    tranparentBackgroundStatuses.includes(status) ? theme.colors.pill_border : 'transparent'};

  color: ${({ theme, status }) =>
    !tranparentBackgroundStatuses.includes(status) && theme.colors.white};

  background-color: ${({ theme, status }) =>
    status === 'liked'
      ? theme.pill.purple.active_bg
      : status === 'draft_shared'
      ? theme.colors.default_icon
      : status === 'message_sent'
      ? theme.colors.green
      : 'none'};
`

const AdvisorName = ({
  uuid,
  advisor,
  teamSlug,
}: {
  uuid: string
  advisor: UserBlueprint
  teamSlug: string
}) => {
  return (
    <Typography
      fontWeight={600}
      fontSize={'14'}
      color="primary"
      onClick={() => window.open(`/${teamSlug}/members/${uuid}`)}
      className="cursor-pointer hover:underline"
    >
      {advisor.name}
    </Typography>
  )
}

const TooltipGuts = styled.div`
  ${tw`flex flex-col gap-2`}
  max-width: 300px;
`

interface Props {
  connection?: ConnectionBlueprint
  advisor: UserBlueprint
  onCtaClick?: () => void
  tooltipZIndex?: number
  teamSlug: string
  person?: GlobalPersonBlueprint | PersonBlueprint
  status: 'liked' | 'draft_shared' | 'message_sent' | 'default' | 'intro_requested'
}

const AdvisorPill: React.VFC<Props> = ({
  connection: _connection,
  advisor,
  onCtaClick,
  tooltipZIndex,
  teamSlug,
  status: _defaultStatus = 'default',
  person,
}) => {
  const { compose } = useComposer(teamSlug)
  const { team } = useTeam(teamSlug)
  const history = useHistory()
  const { advisorsMapByUserUuid } = useAdvisors({ teamSlug })
  const [showTooltip, setShowTooltip] = useState(false)
  const [status, setStatus] = useState<Props['status']>(_defaultStatus)

  const getConnectionQuery = useQuery(
    ['connection', _connection?.uuid, teamSlug],
    () => callApi(api.getConnection, connection?.uuid, teamSlug),
    {
      enabled: showTooltip && !!_connection,
      cacheTime: 0,
    },
  )

  const connection: ConnectionBlueprint = getConnectionQuery.data?.connection || _connection

  useEffect(() => {
    let _status = _defaultStatus

    if (connection) {
      if (connection.sent_messages.length > 0) {
        _status = 'message_sent'
      } else if (connection.vote === 1) {
        _status = 'liked'
      } else if (connection.draft_messages.length > 0) {
        _status = 'draft_shared'
      } else if (_status === 'default' && !!connection.intro_requested?.length) {
        _status = 'intro_requested'
      }
    }

    setStatus(_status)
  }, [connection, _defaultStatus])

  const headline = compact([advisor.title, advisor.company_name]).join(' @ ')

  const openDraftMessage = (uuid: string) => {
    compose({ messageUuid: uuid, onSubmit: () => setStatus('message_sent') })
  }

  const openSentMessage = (uuid: string) => {
    history.push(`/${teamSlug}/messages/${uuid}`)
  }

  useEffect(() => {
    if (showTooltip) {
      getConnectionQuery.refetch()
    }
  }, [showTooltip])

  const renderMessage = (m: MessageModel) => {
    return (
      <div className="flex justify-between items-center gap-2">
        <Typography>{m.subject || 'No subject'}</Typography>
        <Typography fontSize="12">
          <TimeAgo datetime={m.created_at} />
        </Typography>
      </div>
    )
  }

  const tooltipContent = useMemo(() => {
    if (!showTooltip) return <></>

    let messages: React.ReactNode = null
    const sentMessages = connection && connection.sent_messages.length > 0 ? true : false
    const draftedMessages = connection && connection.draft_messages.length > 0 ? true : false

    if (connection) {
      if (draftedMessages) {
        messages = (
          <>
            {messages}
            <DropDownMenu
              dataTestId="advisor-pill-drafts-dropdown"
              portal={false}
              fullWidth={true}
              trigger={
                <CabalButton
                  variant="secondary"
                  className={'w-full'}
                  rightIcon={<i className="fas fa-chevron-down"></i>}
                  data-testid="advisor-pill-view-drafts-button"
                >
                  {sentMessages ? 'Drafts' : 'View Drafts'}
                </CabalButton>
              }
              menuItems={connection.draft_messages.map((m) => {
                return {
                  label: renderMessage(m),
                  onSelect: () => openDraftMessage(m.uuid),
                }
              })}
            />
          </>
        )
      }

      if (sentMessages) {
        messages = (
          <>
            {messages}
            <DropDownMenu
              fullWidth={true}
              portal={false}
              trigger={
                <CabalButton
                  className="w-full"
                  variant="secondary"
                  rightIcon={<i className="fas fa-chevron-down"></i>}
                >
                  {draftedMessages ? 'Sent' : 'View Sent Messages'}
                </CabalButton>
              }
              menuItems={connection.sent_messages.map((m) => {
                return {
                  label: renderMessage(m),
                  onSelect: () => openSentMessage(m.uuid),
                }
              })}
            />
          </>
        )
      }
    }

    if (messages) {
      messages = (
        <div className={cx('flex w-full', { 'space-x-1': draftedMessages && sentMessages })}>
          {messages}
        </div>
      )
    }

    const advisorGroups = advisor.groups
      ? advisor.groups.filter((group) => group.name != 'All')
      : []

    const advisorUuid = advisorsMapByUserUuid[advisor.uuid]?.uuid
    const advisorRequestCount = advisor?.request_count || 0

    return (
      <TooltipGuts onClick={(e) => e.stopPropagation()}>
        <div>
          <div className="flex items-center gap-2">
            <Avatar
              className="flex-shrink-0"
              name={advisor.name}
              src={advisor.avatar_url}
              size={'32'}
              round
            />
            <div className="flex flex-col text-left truncate">
              <AdvisorName uuid={advisorUuid} advisor={advisor} teamSlug={teamSlug} />
              <Typography color="fog" fontSize="12" className="truncate" title={headline}>
                {headline}
              </Typography>
            </div>

            {advisor.linkedin_url && (
              <Typography color={'fog'}>
                <a
                  href={advisor.linkedin_url}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  className="cursor-pointer "
                  onClick={() => window.open(advisor.linkedin_url, '_blank')}
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </Typography>
            )}
          </div>
        </div>
        <Divider />
        <div className="space-y-1">
          {advisorGroups.length > 0 && (
            <div className="flex items-start">
              <Typography color="fog_rain" fontSize="12" component="p">
                <i className="fas fa-users fa-fw mr-1"></i>
              </Typography>
              <div className="leading-4">
                {advisorGroups.slice(0, 6).map((g, i) =>
                  g.name === 'All' ? null : i === advisorGroups.length - 1 ? (
                    <Typography color="fog_rain" fontSize="12" key={g.uuid}>
                      {`${g.name} `}
                    </Typography>
                  ) : (
                    <Typography color="fog_rain" fontSize="12" key={g.uuid} lineHeight={1}>
                      {`${g.name}, `}
                    </Typography>
                  ),
                )}
                {advisorGroups.length > 6 && (
                  <Typography color="fog_rain" fontSize="12" lineHeight={1}>
                    {`+ ${advisorGroups.length - 6} more`}
                  </Typography>
                )}
              </div>
            </div>
          )}
          {!!advisor && (
            <div>
              <Typography color="fog_rain" fontSize="12">
                <i className="fas fa-arrows-h fa-fw mr-1"></i>
                {`${advisorRequestCount} ${pluralize('Request', advisorRequestCount)} received`}
              </Typography>
            </div>
          )}

          {status === 'intro_requested' && (
            <Typography color="fog_rain" className="underline" fontSize="12">
              <Link
                to={`/${teamSlug}/messages/${last(connection?.intro_messages)?.uuid}`}
                target="_blank"
              >
                <i className="fas fa-paper-plane fa-fw"></i> Intro requested <i className="far" />
              </Link>
            </Typography>
          )}

          <div>
            {advisor.last_request_at && (
              <Tooltip label={moment(advisor.last_request_at).format('LT, MMM D Y')}>
                <Typography color="fog_rain" fontSize="12">
                  <i className="fas fa-history fa-fw mr-1"></i>
                  Last Request {moment(advisor.last_request_at).fromNow()}
                </Typography>
              </Tooltip>
            )}
          </div>
        </div>
        {person && (
          <>
            <Divider />
            <div>
              <Typography color="fog_rain" fontSize="12" className="mb-1" component="div">
                Connected to {person?.full_name} via
              </Typography>
              {connection?.source_type === 'Contact' && (
                <Typography color="fog_rain" fontSize="12" component="div">
                  <i className="fab fa-google fa-fw mr-1" />
                  Google Contacts
                </Typography>
              )}
              {connection?.source_type === null && (
                <Typography color="fog_rain" fontSize="12" component="div">
                  <i className="fab fa-linkedin fa-fw mr-1" />
                  Linkedin
                </Typography>
              )}
            </div>
          </>
        )}
        <Divider />
        <div className="flex items-center justify-center flex-col">
          {onCtaClick && (
            <CabalButton
              onClick={() => {
                onCtaClick?.()
                setStatus('draft_shared')
              }}
              className="mb-1.5 w-full"
              data-testid="advisor-pill-draft-on-behalf"
            >
              Draft on behalf
            </CabalButton>
          )}
          {messages}
        </div>
      </TooltipGuts>
    )
  }, [showTooltip, advisor, onCtaClick, connection])

  let statusIcon: React.ReactNode = null

  switch (status) {
    case 'liked':
      statusIcon = (
        <Typography fontSize="12" color={'white'}>
          <i className="fas fa-thumbs-up fa-fw"></i>
        </Typography>
      )
      break
    case 'draft_shared':
      statusIcon = (
        <Typography fontSize="12" color={'white'}>
          <i className="fas fa-file fa-fw"></i>
        </Typography>
      )
      break
    case 'message_sent':
      statusIcon = (
        <Typography fontSize="12" color={'white'}>
          <i className="fas fa-check-circle fa-fw"></i>
        </Typography>
      )
      break
    case 'intro_requested':
      statusIcon = (
        <Typography fontSize="12">
          <i className="fas fa-paper-plane fa-fw"></i>
        </Typography>
      )
  }

  return (
    <Tooltip
      label={tooltipContent}
      onShow={() => setShowTooltip(true)}
      onHide={() => setShowTooltip(false)}
      interactive
      delay={[0, 500]}
      zIndex={tooltipZIndex}
    >
      <AdvisorPillContainer
        key={advisor.uuid}
        data-tip=""
        data-for={advisor.uuid}
        status={status}
        data-testid={'advisor-pill-tooltip' + '-' + advisor.uuid}
      >
        {statusIcon && <div className="flex">{statusIcon}</div>}
        <div className="flex">
          <Typography fontSize="12" fontWeight={statusIcon ? 600 : 400}>
            {advisor.last_name && advisor.first_name
              ? `${advisor.first_name} ${advisor.last_name?.charAt(0)}.`
              : `${advisor.name}`}
          </Typography>
        </div>
      </AdvisorPillContainer>
    </Tooltip>
  )
}

export default React.memo(AdvisorPill)
