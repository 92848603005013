import React, { useEffect, useState } from 'react'

import Cookies from 'js-cookie'

import BorderedBox from 'components/BorderedBox'
import CabalButton from 'global/CabalButton'
import RichTextInput from 'global/Input/RichTextInput'
import { TextArea } from 'global/Input/TextArea'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import PillV2 from 'ui-components/PillV2'

import { Team } from 'utils/types'

interface Props {
  onHide: () => void
  team: Team
  onSubmit: (
    reason?: 'portfolio-founder' | 'recruiting' | 'portfolio-company' | 'job-seeker' | 'partner',
    note?: string,
    facilitatorUserUuid?: string | null,
  ) => void
}

const ReasonToJoinModal = ({ onHide, team, onSubmit }: Props) => {
  const { name } = team
  const [reason, setReason] = useState<
    'portfolio-founder' | 'recruiting' | 'portfolio-company' | 'job-seeker' | 'partner'
  >()

  const [facilitatorUserUuid, setFacilitatorUserUuid] = useState<string | null>(null)
  const [note, setNote] = useState<string>(``)

  useEffect(() => {
    const latestJobIntroReqJSON = Cookies.get(`latest_non_advisor_job_intro_request`)
    const latestJobIntroReq = latestJobIntroReqJSON ? JSON.parse(latestJobIntroReqJSON) : null
    if (latestJobIntroReq?.company_slug === team.slug) {
      setFacilitatorUserUuid(latestJobIntroReq.facilitator_user_uuid)
    }
  }, [])

  const handleSave = () => {
    if (reason === 'job-seeker') {
      onSubmit(reason, note, facilitatorUserUuid)
    } else {
      onSubmit(reason, note)
    }
    onHide()
  }

  return (
    <Modal
      header={`Request to join ${name}?`}
      show={true}
      onHide={onHide}
      rightActions={
        <CabalButton onClick={handleSave} variant="primary">
          Send join request
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <Typography fontSize="14">
          Help {name} give you access to the right resources by specifying your relationship.
        </Typography>

        <div className="flex gap-2 flex-wrap">
          <PillV2
            title={`I’m a ${name} Portfolio Founder`}
            onClick={() => setReason('portfolio-founder')}
            active={reason === 'portfolio-founder'}
          />
          <PillV2
            title={`I’m looking for candidates`}
            onClick={() => setReason('recruiting')}
            active={reason === 'recruiting'}
          />
          <PillV2
            title={`I work at a ${name} Portfolio company`}
            onClick={() => setReason('portfolio-company')}
            active={reason === 'portfolio-company'}
          />
          <PillV2
            title={`I’m looking for new job opportunities`}
            onClick={() => setReason('job-seeker')}
            active={reason === 'job-seeker'}
          />
          <PillV2
            title={`I’m a partner to ${name}`}
            onClick={() => setReason('partner')}
            active={reason === 'partner'}
          />
        </div>
      </ModalSectionWrapper>

      <ModalSectionWrapper>
        <div>
          <Typography fontSize="12" fontWeight={400} lineHeight="1" className=" text-center">
            Note
          </Typography>
          <TextArea
            value={note}
            className="w-full mt-1"
            rows={3}
            data-testid="request-talent-intro-modal-context"
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default ReasonToJoinModal
