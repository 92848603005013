import React from 'react'
import { useHistory } from 'react-router-dom'
import { cabalToast } from 'ui-components/Toast'
import Typography from 'global/Typography'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useParams } from 'react-router-dom'
import { CompanySlugParam } from 'utils/types'
import axios from 'axios'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import AddLinkModal from './AddLinkModal'
import { CardHeader } from 'containers/Connections/styles'
import copyToClipboard from 'utils/copyToClipboard'
import moment from 'moment'
import CabalTitle from 'global/Title'

export const Container = styled.div`
  ${tw`py-10 px-4 rounded-lg text-center`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  color: ${({ theme }) => theme.colors.primary};
  box-shadow: ${({ theme }) => theme.card_shadow};
`

const StyledCardHeader = styled(CardHeader)`
  ${tw`px-4 py-2.5 rounded-none`};
  background-color: ${({ theme }) => theme.table.bg_color};
`

const ManageDocument = () => {
  const { id, company_slug: companySlug } = useParams<{ id: string } & CompanySlugParam>()
  const history = useHistory()

  const [document, setDocument] = React.useState(null)
  const [links, setLinks] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [showAddLinkModal, setShowAddLinkModal] = React.useState(false)
  const [visits, setVisits] = React.useState([])

  React.useEffect(() => {
    loadData()

    const interval = setInterval(loadData, 10000)
    return () => clearInterval(interval)
  }, [id])

  function loadData() {
    loadDocument()
    loadLinks()
    loadVisits()
  }

  function loadDocument() {
    const params = {}
    axios.get(`/api/documents/${id}`, params).then((response) => {
      const { document } = response.data
      setDocument(document)
      setLoading(false)
    })
  }

  function loadVisits() {
    axios.get(`/api/document_visits?document_id=${id}&team=${companySlug}`).then((response) => {
      const { visits } = response.data
      setVisits(visits)
    })
  }

  function loadLinks() {
    axios.get(`/api/document_links?document=${id}`).then((response) => {
      const { links } = response.data
      setLinks(links)
    })
  }

  if (loading) {
    return <Loading />
  }

  const onClickCopy = (string) => {
    copyToClipboard(string)
    cabalToast({
      style: 'success',
      content: 'Copied! Share the copied url to provide access to this document',
    })
  }

  return (
    <div className="mx-12 mt-4">
      <CabalTitle title={'Manage Document'} />
      {showAddLinkModal && (
        <AddLinkModal
          companySlug={companySlug}
          document_id={id}
          show={showAddLinkModal}
          onHide={() => setShowAddLinkModal(false)}
          onCreate={() => {
            loadLinks()
            setShowAddLinkModal(false)
          }}
        />
      )}
      <div className="mb-4">
        <CabalButton variant="secondary" onClick={() => history.push(`/${companySlug}/documents`)}>
          Back
        </CabalButton>
      </div>
      <div className="mt-8">
        <div className="flex justify-between">
          <div className="text-2xl font-bold">{document.name}</div>
          <div className="items-end">
            {/* <CabalButton variant="secondary" className="mr-2" onClick={() => window.open(`/docs/${document.slug}`)}>Preview</CabalButton>
            <CabalButton variant="secondary" className="mr-2" onClick={() => history.push(`/${companySlug}/documents`)}>Upload New Version</CabalButton> */}
            <CabalButton variant="primary" onClick={() => setShowAddLinkModal(true)}>
              Create Link
            </CabalButton>
          </div>
        </div>
      </div>
      {links.length > 0 && (
        <div className="mt-4">
          <h2>All Visits</h2>
          <div className="my-2 w-full">
            {visits.map((visit) => (
              <StyledCardHeader key={visit.uuid}>
                <Typography fontWeight={600} color="primary" className="w-1/3 block">
                  {visit.email}
                </Typography>
                <Typography fontWeight={600} color="primary" className="w-1/3 block">
                  {visit.account_name}
                </Typography>
                <Typography fontWeight={600} color="primary" className="w-1/3 block text-right">
                  {moment(visit.starts_at).fromNow()}
                </Typography>
              </StyledCardHeader>
            ))}
            {visits.length == 0 && (
              <Container className="flex flex-col items-center">
                <Typography className="far fa-eye" fontSize="24" color="fog" />

                <Typography className="block mt-4" fontWeight={700} fontSize="16">
                  Views of your links will show up here
                </Typography>
              </Container>
            )}
          </div>
        </div>
      )}
      <div className="mt-4">
        <h2>All Links</h2>
        <div className="my-2 w-full">
          {links.map((link) => (
            <StyledCardHeader key={link.uuid}>
              <Typography fontWeight={600} color="primary" className="w-1/3 block">
                {link.account_name}
              </Typography>
              <div className="w-1/3">
                <Typography fontWeight={600} color="primary" className="ml-2">
                  {`${window.location.protocol}//${window.location.host}/docs/${link.slug}`}
                </Typography>
                <Typography
                  fontWeight={400}
                  color="fog"
                  className="cursor-pointer ml-2"
                  onClick={() =>
                    onClickCopy(
                      `${window.location.protocol}//${window.location.host}/docs/${link.slug}`,
                    )
                  }
                >
                  Copy
                </Typography>
              </div>
              <div className="w-1/3 text-right">{link.visits_count} visits</div>
            </StyledCardHeader>
          ))}
          {links.length == 0 && (
            <Container className="flex flex-col items-center">
              <Typography className="far fa-link" fontSize="24" color="fog" />

              <Typography className="block mt-4" fontWeight={700} fontSize="16">
                Create a link to track visits
              </Typography>
            </Container>
          )}
        </div>
      </div>
    </div>
  )
}

export default ManageDocument
