import * as React from 'react'

import { useMutation } from 'react-query'
import HeadShake from 'react-reveal/HeadShake'
import styled from 'styled-components'
import tw from 'twin.macro'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { parameterize } from 'utils/string'

export const Container = styled.div`
  ${tw`py-6 px-10 text-center max-w-xl mx-auto rounded-lg`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  color: ${({ theme }) => theme.colors.primary};
`

interface Props {
  onNext: () => void
}

const ClaimUsername: React.VFC<Props> = ({ onNext }) => {
  const { user } = useCurrentUser()
  const [username, setUsername] = React.useState(user.username || '')
  const [name, setName] = React.useState(user.name || '')
  const parameterizedUsername = parameterize(username)

  const createUsernameMutation = useMutation(
    () => callApi(api.createUsername, name, parameterizedUsername),
    {
      onSuccess: ({ error }) => {
        if (!error) onNext()
      },
    },
  )

  const error = createUsernameMutation.data?.error

  const continueDisabled = username === '' || !!error

  return (
    <Container className="w-full">
      <Avatar src={user.avatar_url} name={user.name} size="64" />
      <div className="h-4" />

      <Typography fontSize="16" fontWeight={700} textAlign="center" component="h3">
        Pick a username for your Cabal profile
      </Typography>
      <Typography fontSize="14" color="fog_rain" component="div">
        Share your expertise, portfolio and connect with others on Cabal
      </Typography>

      <div className="mt-8">
        {!user.name && (
          <TextInput
            value={name}
            placeholder="Full Name"
            className="w-full mb-4"
            autoFocus
            onChange={(e) => {
              setName(e.target.value)
              createUsernameMutation.reset()
            }}
            onBlur={(e) => {
              if (!username) setUsername(parameterize(e.target.value))
            }}
          />
        )}

        <TextInput
          value={username}
          placeholder="Pick a username"
          className="w-full mb-4"
          autoFocus={!!user.name}
          onChange={(e) => {
            setUsername(e.target.value)
            createUsernameMutation.reset()
          }}
        />

        {error && (
          <HeadShake>
            <div className="text-sm mb-6">{error}</div>
          </HeadShake>
        )}

        {!error && username != '' && (
          <div className="text-sm mb-6">
            Your url will be: https://getcabal.com/{parameterizedUsername}
          </div>
        )}

        <div className="text-center">
          <CabalButton
            onClick={() => createUsernameMutation.mutate()}
            variant="primary"
            disabled={continueDisabled}
            working={createUsernameMutation.isLoading}
          >
            Continue
          </CabalButton>
        </div>
      </div>
    </Container>
  )
}

export default ClaimUsername
