import React, { useState } from 'react'

import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import { Filter, FilterWrapper, FiltersValueType, renderFilters } from 'components/Filters'
import CabalButton from 'global/CabalButton'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useAdvisors, useTeam, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'

import Stage from './Stage'

const Container = styled.div<{ vertical: boolean }>`
  ${tw`flex flex-row gap-4 w-full`}
  ${({ vertical }) => (vertical ? tw`flex-col` : tw`flex-row`)}
`

export interface Filters {
  owner?: string[]
  member?: string[]
  lists?: string[]
}

export interface LikesTrackerProps {
  teamSlug?: string
  vertical?: boolean
  companyId?: number
  personId?: number
  dismissModal?: () => void
  toggleKanban?: (v: boolean) => void
}

const LikesTracker: React.VFC<LikesTrackerProps> = ({
  teamSlug,
  vertical = false,
  companyId,
  personId,
  dismissModal,
  toggleKanban,
}) => {
  const { isMobile } = useColorMode()
  if (isMobile) vertical = true
  const team_slug = useTeamSlug()
  const isInModal = !!dismissModal
  const history = useHistory()
  const [filters, setFilters] = useState<Filters>({})
  const slug = teamSlug || team_slug
  const { team } = useTeam(slug)

  const { data: ownerEmailsData } = useQuery(['getAllOwnerEmails', slug], () =>
    callApi(api.getAllOwnerEmails, slug),
  )
  const ownerEmails = ownerEmailsData?.emails || []

  const { advisors = [] } = useAdvisors({ teamSlug: slug })

  const { data: listOptionsData } = useQuery(['getCompanyListFilterOptions', slug], () =>
    callApi(api.getCompanyListFilterOptions, slug),
  )
  const listOptions = listOptionsData?.lists || []

  const filterItems: Filter[] = [
    {
      key: 'owner',
      type: 'dropdown[]',
      label: 'Owner',
      options: ownerEmails.map((email) => ({ label: email, value: email })),
    },
    {
      key: 'members',
      type: 'dropdown[]',
      label: 'Members',
      options: advisors.map((advisor) => ({
        label: advisor.name,
        value: advisor.uuid,
        group: 'Members',
      })),
    },
    {
      key: 'lists',
      type: 'dropdown[]',
      label: 'List',
      options: listOptions,
    },
  ]

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: filterItems,
    value: filters as FiltersValueType,
    onChange: setFilters,
  })

  const props = {
    personId,
    companyId,
    vertical,
    dismissModal,
    filters,
  }

  const children = (
    <>
      {!isInModal && (
        <div className="flex items-start justify-between my-3">
          <div className="flex items-center gap-4">
            <CabalButton
              padding="0"
              variant="tertiary"
              leftIcon={<i className="far fa-chevron-left" />}
              onClick={() => history.push(`/${slug}`)}
            >
              <Typography fontSize="14">Home</Typography>
            </CabalButton>

            <FilterWrapper>
              <div>{renderedFilters}</div>
              {!!appliedFilters.length && <div>{appliedFilters}</div>}
            </FilterWrapper>
          </div>

          <CabalButton
            onClick={() => {
              history.push(`/${team_slug}/activity?kanban=false`)
            }}
            variant="link"
            leftIcon={<i className="far fa-bars fa-fw" />}
            data-testid="activities-list-button"
          >
            View Activities
          </CabalButton>
        </div>
      )}
      <Container vertical={vertical}>
        <Stage {...props} teamSlug={slug} stage="liked" />
        <Stage {...props} teamSlug={slug} stage="draft_shared" />
        <Stage {...props} teamSlug={slug} stage="message_sent" />
      </Container>
    </>
  )

  if (!isInModal) {
    return <PageWrapper title="Offers">{children}</PageWrapper>
  }

  return children
}

export default LikesTracker
