import cx from 'classnames'
import React, { useState } from 'react'

import Typography from 'global/Typography'

interface Props {
  label: string
  onClick?: () => void
}

const Title: React.FC<Props> = ({ label, onClick }) => {
  return (
    <div className={cx('flex items-center pb-2', { 'cursor-pointer': onClick })} onClick={onClick}>
      <Typography fontSize="14" fontWeight={600}>
        {label}
      </Typography>
      {onClick && (
        <Typography color={'rain'} fontSize="12" className="ml-2 pt-0.5">
          <i className="far fa-chevron-right" />
        </Typography>
      )}
    </div>
  )
}

export default Title
