import React, { useMemo, useRef, useState } from 'react'

import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import ReactStars from 'react-stars'
import { useLocation, useSetState } from 'react-use'
import styled, { useTheme } from 'styled-components'
import tw from 'twin.macro'

import AvatarUpload, { AvatarUploadRef } from 'global/AvatarUpload'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import { MultiSelect, Select, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import {
  ModalDivider,
  ModalInputLabel,
  ModalInputWrapper,
  ModalSectionHeader,
  ModalSectionWrapper,
} from 'global/Modal/styles'
import CKEditor from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import errorMessages from 'utils/errorMessages'
import { absoluteHtmlContent } from 'utils/html'
import {
  EmailButton,
  Offer,
  OfferButton,
  PriceRange,
  ReviewProps,
  UploadModel,
  UrlButton,
} from 'utils/types'

import { offerTypes } from '.'

interface Props {
  offer?: Offer
  teamSlug: string
  resolve: (offer?: Offer) => void
  allTags: string[]
  setAllTags: (tags: string[]) => void
  isFetchingTags: boolean
  allCategories: string[]
  setAllCategories: (categories: string[]) => void
  proposed?: boolean
  defaultOfferType?: string
}

const ButtonContainer = styled.div`
  border: 1px solid var(--ck-color-base-border);
  border-radius: 0.25rem;
  ${tw`p-2 mb-2`}
`

const EditOfferModal: React.VFC<Props> = ({
  offer: _offer,
  teamSlug,
  resolve,
  allTags,
  setAllTags,
  isFetchingTags,
  allCategories,
  setAllCategories,
  proposed,
  defaultOfferType,
}) => {
  const location = useLocation()
  const tag = location.pathname?.split('/').pop()
  const offerType = offerTypes.map(({ slug }) => slug).includes(tag) ? tag : 'deals'
  const [offerState, setOfferState] = useSetState<Partial<Offer>>({
    ...(_offer || {}),
    buttons: _offer ? _offer?.buttons : [],
    offer_type: _offer?.offer_type || defaultOfferType || offerType,
    proposed: !!proposed,
  })
  const avatarUploadRef = useRef<AvatarUploadRef>(null)
  const queryCache = useQueryClient()
  const [uploading, setUploading] = useState(false)
  const [review, setReview] = useSetState<ReviewProps>()
  const theme = useTheme()

  const typeOptions = useMemo(
    () =>
      offerTypes.map((o) => ({
        label: o.label,
        value: o.slug,
      })),
    [],
  )

  const { mutate: upsertOffer, isLoading } = useMutation(
    ['upsertOffer', _offer],
    (offer: Offer) =>
      _offer
        ? callApi(api.updateOffer, teamSlug, _offer.uuid, offer)
        : callApi(api.createOffer, teamSlug, offer),
    {
      onSuccess: ({ offer }) => {
        if (_offer) {
          queryCache.refetchQueries(['offers', teamSlug])
          resolve(offer)
        } else {
          if (review.rating) createOfferReview(offer)
          else resolve(offer)
        }
      },
    },
  )

  const { mutate: createOfferReview } = useMutation(
    ['createOfferReview'],
    (offer: Offer) => callApi(api.createOfferReview, offer.uuid, review),
    {
      onSuccess: ({ offer }) => {
        queryCache.refetchQueries(['offers', teamSlug])
        resolve(offer)
      },
    },
  )

  const { mutate: proposeOffer } = useMutation(
    ['proposeOffer'],
    (offer: Offer) => callApi(api.proposeOffer, teamSlug, offer),
    {
      onSuccess: ({ offer }) => {
        cabalToast({ style: 'success', content: 'Resource proposed' })
        resolve(offer)
      },
    },
  )

  const setButton = (i: number, key: keyof EmailButton | keyof UrlButton, v: string) => {
    const updatedButtons = [...(offerState.buttons || [])]

    updatedButtons[i][key] = v

    setOfferState({ buttons: updatedButtons })
  }

  const handleSave = async () => {
    if (!avatarUploadRef.current) return

    setUploading(true)
    let upload: UploadModel | null | undefined

    try {
      upload = await avatarUploadRef.current.upload()
    } catch (e) {
      setUploading(false)
      cabalToast({ style: 'error', content: 'Image upload failed' })
      return
    }

    const updatedState = { ...offerState }

    if (upload === null) {
      updatedState.upload_uuid = null
    } else if (upload !== undefined) {
      updatedState.upload_uuid = upload.uuid
    }
    updatedState.buttons = updatedState.buttons?.filter((b) => !!b.label && (!!b.url || !!b.email))
    setOfferState(updatedState)

    if (proposed) {
      proposeOffer(updatedState as Offer)
    } else {
      upsertOffer(updatedState as Offer)
    }
  }

  const renderButtonInputs = (b: OfferButton, i: number) => {
    return (
      <ButtonContainer key={'offer-button-edit-' + i}>
        <div className="flex justify-between">
          <div className="w-full pr-2">
            <div className="flex items-center">
              <div className="flex-1 pr-2">
                <TextInput
                  defaultValue={b.label}
                  onChange={(e) => setButton(i, 'label', e.currentTarget.value)}
                  className="w-full"
                  placeholder="Label"
                />
              </div>
              {'url' in b && (
                <div className="flex-1">
                  <TextInput
                    defaultValue={b.url}
                    onChange={(e, valid) =>
                      setButton(i, 'url', !valid ? '' : e.currentTarget.value)
                    }
                    valueType="url"
                    className="w-full"
                    placeholder="URL"
                    required
                    errorMessage={errorMessages.invalidUrl}
                  />
                </div>
              )}
              {'email' in b && (
                <div className="flex-1">
                  <TextInput
                    defaultValue={b.email}
                    className="w-full"
                    onChange={(e) => setButton(i, 'email', e.currentTarget.value)}
                    placeholder="Emails (comma-separated)"
                    required
                  />
                </div>
              )}
            </div>
            {'email' in b && (
              <>
                <div className="mt-2">
                  <TextInput
                    defaultValue={b.subject}
                    onChange={(e) => setButton(i, 'subject', e.currentTarget.value)}
                    className="w-full"
                    placeholder="Subject (Optional)"
                    required
                    errorMessage={errorMessages.invalidUrl}
                  />
                </div>
                <div className="mt-2">
                  <TextInput
                    defaultValue={b.body}
                    onChange={(e) => setButton(i, 'body', e.currentTarget.value)}
                    className="w-full"
                    placeholder="Body (Optional)"
                    required
                    errorMessage={errorMessages.invalidUrl}
                  />
                </div>
              </>
            )}
          </div>
          <div>
            <CabalButton
              variant="destructive"
              leftIcon={<i className="far fa-trash" />}
              size="small"
              onClick={() => {
                const updatedButtons = [...(offerState.buttons || [])]
                updatedButtons.splice(i, 1)
                setOfferState({ buttons: updatedButtons })
              }}
            />
          </div>
        </div>
      </ButtonContainer>
    )
  }

  const disabled =
    offerState.offer_type !== 'vendors'
      ? !offerState.title ||
        !offerState.body ||
        !(offerState.buttons || []).every(({ label, url, email }) => label && (url || email))
      : !offerState.title

  return (
    <Modal
      show
      dangerouslyBypassFocusLock
      onHide={() => resolve()}
      header={`${
        offerState.uuid && _offer?.offer_type === 'proposed_resource'
          ? 'Accept'
          : offerState.uuid
          ? 'Edit'
          : 'New'
      } Resource`}
      rightActions={
        <CabalButton disabled={disabled} onClick={handleSave} working={isLoading || uploading}>
          Save
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalSectionHeader>Resource Information</ModalSectionHeader>
        <ModalInputWrapper>
          <ModalInputLabel>Logo</ModalInputLabel>
          <AvatarUpload
            imageUrl={offerState.upload_uuid ? `/api/uploads/${offerState.upload_uuid}` : undefined}
            ref={avatarUploadRef}
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Title</ModalInputLabel>
          <TextInput
            value={offerState.title}
            onChange={(e) => setOfferState({ title: e.currentTarget.value })}
            placeholder="Title"
            className={'w-full'}
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Type</ModalInputLabel>
          <Select<string>
            options={typeOptions}
            value={offerState.offer_type}
            onChange={(v) => {
              v && setOfferState({ offer_type: v })
            }}
            className={'w-full'}
          />
        </ModalInputWrapper>

        {offerState.offer_type === 'vendors' && (
          <>
            <ModalInputWrapper>
              <ModalInputLabel>Category</ModalInputLabel>
              <MultiSelect<string>
                data-testid="offer-modal-category"
                className="w-full"
                options={
                  allCategories.map((t) => ({
                    label: t,
                    value: t,
                  })) || []
                }
                value={offerState.categories}
                onChange={(v) => setOfferState({ categories: v })}
                creatable
                isLoading={isFetchingTags}
                onCreateOption={(o) => {
                  setAllCategories([...allCategories, o])
                  setOfferState({ categories: [...(offerState.categories || []), o] })
                }}
              />
            </ModalInputWrapper>
            <ModalInputWrapper>
              <ModalInputLabel>Price Range</ModalInputLabel>
              <Select
                data-testid="offer-modal-price-range"
                className="w-full"
                options={[
                  {
                    label: 'Expensive',
                    value: 'expensive',
                  },
                  {
                    label: 'Average',
                    value: 'average',
                  },
                  {
                    label: 'Low',
                    value: 'low',
                  },
                ]}
                value={offerState.price_range}
                onChange={(v) => setOfferState({ price_range: v as PriceRange })}
              />
            </ModalInputWrapper>
          </>
        )}

        {(offerState.offer_type === 'vendors' || offerState.offer_type === 'people') && (
          <>
            <ModalInputWrapper>
              <ModalInputLabel>
                {offerState.offer_type === 'people' ? 'LinkedIn URL' : 'Website'}
              </ModalInputLabel>
              <TextInput
                defaultValue={offerState.website}
                onChange={(e) => setOfferState({ website: e.currentTarget.value })}
                className="w-full"
                valueType="url"
                placeholder={offerState.offer_type === 'people' ? 'LinkedIn URL' : 'Website'}
              />
            </ModalInputWrapper>
            <ModalInputWrapper>
              <ModalInputLabel>Location</ModalInputLabel>
              <TextInput
                defaultValue={offerState.location}
                onChange={(e) => setOfferState({ location: e.currentTarget.value })}
                className="w-full"
                placeholder="Location"
              />
            </ModalInputWrapper>
          </>
        )}
        {offerState.offer_type === 'vendors' && (
          <>
            <ModalInputWrapper>
              <ModalInputLabel>Contact Name</ModalInputLabel>
              <TextInput
                defaultValue={offerState.contact_name}
                onChange={(e) => setOfferState({ contact_name: e.currentTarget.value })}
                className="w-full"
                placeholder="Contact Name"
              />
            </ModalInputWrapper>
            <ModalInputWrapper>
              <ModalInputLabel>Contact email</ModalInputLabel>
              <TextInput
                defaultValue={offerState.email}
                onChange={(e) => setOfferState({ email: e.currentTarget.value })}
                className="w-full"
                valueType="email"
                placeholder="Email"
              />
            </ModalInputWrapper>
            <ModalInputWrapper>
              <ModalInputLabel>Phone number</ModalInputLabel>
              <TextInput
                defaultValue={offerState.phone_number}
                onChange={(e) => setOfferState({ phone_number: e.currentTarget.value })}
                className="w-full"
                valueType="phone"
                placeholder="Number"
              />
            </ModalInputWrapper>
          </>
        )}
        <>
          <ModalInputWrapper>
            <ModalInputLabel>Body</ModalInputLabel>
            <CKEditor
              value={offerState.body}
              onChange={(v) => setOfferState({ body: absoluteHtmlContent(v) || '' })}
              height={100}
            />
          </ModalInputWrapper>
          <ModalInputWrapper>
            <ModalInputLabel>CTAs</ModalInputLabel>
            <div className="w-full">
              {offerState.buttons?.map((b, i) => renderButtonInputs(b, i))}
              <DropDownMenu
                menuItems={[
                  {
                    label: 'URL',
                    onSelect: () =>
                      setOfferState({
                        buttons: [
                          ...(offerState.buttons || []),
                          {
                            label: '',
                            url: '',
                          },
                        ],
                      }),
                  },
                  {
                    label: 'Email',
                    onSelect: () =>
                      setOfferState({
                        buttons: [
                          ...(offerState.buttons || []),
                          {
                            label: '',
                            email: '',
                            subject: '',
                            body: '',
                          },
                        ],
                      }),
                  },
                ]}
                portal={false}
                trigger={
                  <Typography
                    component="p"
                    color={'purple'}
                    fontSize="12"
                    className="cursor-pointer mt-1"
                  >
                    + Add button
                  </Typography>
                }
              />
            </div>
          </ModalInputWrapper>
          <ModalInputWrapper>
            <ModalInputLabel>Tags</ModalInputLabel>
            <MultiSelect<string>
              data-testid="portfolio-job-modal-category"
              className="w-full"
              options={
                allTags.map((t) => ({
                  label: t,
                  value: t,
                })) || []
              }
              value={offerState.tags}
              onChange={(v) => setOfferState({ tags: v })}
              creatable
              isLoading={isFetchingTags}
              onCreateOption={(o) => {
                setAllTags([...allTags, o])
                setOfferState({ tags: [...(offerState.tags || []), o] })
              }}
            />
          </ModalInputWrapper>
        </>
      </ModalSectionWrapper>
      {offerState.offer_type === 'vendors' && !_offer && (
        <>
          <ModalDivider />
          <ModalSectionWrapper>
            <ModalSectionHeader>Review</ModalSectionHeader>
            <ModalInputWrapper>
              <ModalInputLabel>Rating</ModalInputLabel>
              <ReactStars
                count={5}
                value={review.rating}
                color2={theme.colors.star_select}
                onChange={(r: number) => setReview({ rating: r })}
              />
            </ModalInputWrapper>
            <ModalInputWrapper>
              <ModalInputLabel>Description</ModalInputLabel>
              <div className="overflow-hidden w-full">
                <CKEditor
                  value={review.description}
                  onChange={(v) => setReview({ description: absoluteHtmlContent(v) || '' })}
                  height={90}
                />
              </div>
            </ModalInputWrapper>
          </ModalSectionWrapper>
        </>
      )}
    </Modal>
  )
}

export default EditOfferModal
