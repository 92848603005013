import React, { useMemo } from 'react'
import { useState } from 'react'

import AddPersonModal from 'components/AddPerson/Modal'
import { useCompanySearch } from 'components/Winsite/queries/CompanySearch'
import { CompanySelectProps } from 'containers/ListIndexView/PortfolioList/types'
import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import ResourceCompanyAddModal from 'containers/ListIndexView/ResourcesList/ResourceCompanyList/ResourceCompanyAddWidget/ResourceCompanyAddModal'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'

import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'

interface Props {
  setOnClose: () => void
}

const AddIndividualSection = ({ setOnClose }: Props) => {
  console.log('hehehehehe')
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const { showModal } = useModal()
  const teamSlug = useTeamSlug()
  const logo_url = 'https://logo.clearbit.com/'
  const { isLoading, data: companies } = useCompanySearch(debouncedQuery, true)
  const results: CompanySelectProps[] = useMemo(() => companies?.data, [companies])
  const { refetchCompanyList, companyListData: companyList } = useResourcesListContext()

  return (
    <div className="mt-4">
      <SearchInput
        onChange={(c) => setQuery(c.target.value)}
        placeholder={`Search for companies`}
        value={query}
        autoFocus
      />

      {debouncedQuery !== '' && (
        <>
          <div className="my-4 px-3">
            <CabalButton
              variant="link"
              onClick={() => {
                showModal(
                  (resolve) => (
                    <ResourceCompanyAddModal
                      onHide={resolve}
                      teamSlug={teamSlug}
                      refetchPortfolioList={refetchCompanyList}
                      listUuid={companyList.uuid}
                      name={debouncedQuery}
                    />
                  ),
                  'edit-portfolio-company-modal',
                )
                setOnClose()
              }}
              leftIcon={<i className="far fa-plus fa-xs" />}
              padding="0"
              size="large"
            >
              <span>{`Add company "${debouncedQuery}"`}</span>
            </CabalButton>
          </div>
          {isLoading && (
            <div className="flex mt-4 w-full justify-center">
              <Loading />
            </div>
          )}
          {results &&
            results.map((company, index) => (
              <DataCardV2
                key={index}
                title={company.name}
                description={company.website}
                avatar={<Avatar name={company.name} src={logo_url + company.website} />}
                cta={
                  <CabalButton
                    onClick={() => {
                      console.log('Directly add from here...')
                      setOnClose()
                    }}
                    variant="link"
                  >
                    Add
                  </CabalButton>
                }
                titleIcon={
                  <>
                    {true && (
                      <Typography
                        color="fog"
                        fontSize="12"
                        onClick={(e) => {
                          e.stopPropagation()
                          window.open('www.linkedin.com', '_blank', 'noopener noreferrer')
                        }}
                      >
                        <i className="fab fa-linkedin fa-fw"></i>
                      </Typography>
                    )}
                  </>
                }
              />
            ))}
        </>
      )}
    </div>
  )
}

export default AddIndividualSection
