import React from 'react'

import styled from 'styled-components'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

interface Props {
  isOpen: boolean
  setIsOpen: () => void
  bulkMenuItems: {
    title: string
    onClick: () => void
    icon?: React.ReactNode
  }[]
  itemCount: number
}

const Wrapper = styled.div<{ isOpen: boolean }>`
  border: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.layout.island_bg_color};
  bottom: ${({ isOpen }) => (isOpen ? '20px' : '-100%')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: bottom 0.3s cubic-bezier(0.32, 0.72, 0, 1);
`

const Pill = styled.div`
  background-color: ${({ theme }) => theme.layout.main_content_bg_color};
  border: ${({ theme }) => theme.border};
  border-radius: 4px;
  padding: 4px 8px;
`

const BulkActionsIsland = ({ isOpen = false, setIsOpen, bulkMenuItems, itemCount }: Props) => {
  return (
    <Wrapper
      isOpen={isOpen}
      className="fixed left-0 right-0 w-fit mx-auto h-auto shadow-xl rounded-md p-1.5"
    >
      <div className="flex items-center gap-1">
        <Pill>
          <div className="flex items-center gap-1">
            <Typography fontSize="12">{itemCount} selected</Typography>
            <CabalButton
              leftIcon={<i className="far fa-times" />}
              onClick={setIsOpen}
              variant="tertiary"
              padding="0"
            />
          </div>
        </Pill>
        {bulkMenuItems.map((item) => (
          <Pill onClick={item.onClick} className="cursor-pointer" key={item.title}>
            <div className="flex items-center gap-1">
              {item.icon && (
                <Typography fontSize="12" color="fog">
                  {item.icon}
                </Typography>
              )}
              <Typography fontSize="12">{item.title}</Typography>
            </div>
          </Pill>
        ))}
      </div>
    </Wrapper>
  )
}

export default BulkActionsIsland
