import React from 'react'
import moment from 'moment'
import Typography from 'global/Typography'
import { PendingInvite, Redemption } from 'utils/types'

import { Wrapper } from '../Home/styles'
interface Props {
  redemptions: Redemption[]
  pendingInvites: PendingInvite[]
}

const format = (date: string, prefix: string) => {
  return moment(date).calendar(null, {
    sameDay: `[${prefix} today]`,
    lastDay: `[${prefix} yesterday] [at] hh:mm A`,
    lastWeek: `[${prefix} last] dddd [at] hh:mm A`,
    sameElse: `[${prefix} on] DD/MM/YYYY`,
  })
}

const InviteStatus = ({ redemptions, pendingInvites }: Props) => {
  return (
    <div className="mt-3">
      <Wrapper>
        <Typography fontSize="14" fontWeight={600} className="mb-3" component="h3">
          Pending invites
        </Typography>
        {(pendingInvites?.length === 0 || pendingInvites === undefined) && (
          <Typography color="fog_rain" fontSize="14" component="p">
            You haven’t sent any invites yet
          </Typography>
        )}
        {pendingInvites?.map((invite) => (
          <div key={invite.email} className="flex justify-between items-baseline mb-1">
            <Typography color="fog_rain" fontSize="14" component="p">
              {invite.email}
            </Typography>
            <Typography color="rain_fog" fontSize="12" component="p">
              {format(invite.sent_at, 'Sent')}
            </Typography>
          </div>
        ))}
      </Wrapper>
      <Wrapper className="mt-2">
        <Typography fontSize="14" fontWeight={600} className="mb-3" component="h3">
          Referred users
        </Typography>
        {(redemptions?.length === 0 || redemptions === undefined) && (
          <Typography color="fog_rain" fontSize="14" component="p">
            No invites redeemed
          </Typography>
        )}
        {redemptions?.map((redemption) => (
          <div key={redemption.uuid} className="flex justify-between items-baseline mb-1">
            <Typography color="fog_rain" fontSize="14" component="p">
              {redemption.user_email}
            </Typography>
            <Typography color="rain_fog" fontSize="12" component="p">
              {format(redemption.used_at, 'Redeemed')}
            </Typography>
          </div>
        ))}
      </Wrapper>
    </div>
  )
}

export default InviteStatus
