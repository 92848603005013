import React from 'react'

import { faCog } from '@fortawesome/pro-regular-svg-icons/faCog'
import { faPuzzle } from '@fortawesome/pro-regular-svg-icons/faPuzzle'

import { History } from 'history'

import ExtensionInstalledModal from 'components/ExtensionInstalledModal'
import { ShowModal } from 'global/Modal/Context'
import Icon from 'ui-components/Icon'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { replaceCurrentRouterWithoutSearch } from 'utils/url'

export const handleExtensionReferral = (history: History, showModal: ShowModal) => {
  const searchParams = new URLSearchParams(window.location.search)
  const version = searchParams.get('cabal_chrome_extension_version')
  const extensionId = searchParams.get('cabal_chrome_extension_id')
  const searchParamsToRemove: string[] = ['cabal_chrome_extension_id']

  localStorage.setItem('referrerExtensionId', extensionId || CHROME_EXTENSION_ID)

  if (version) {
    searchParamsToRemove.push('cabal_chrome_extension_version')

    if (CHROME_EXTENSION_VERSION && version < CHROME_EXTENSION_VERSION) {
      cabalToast({
        content: (
          <div>
            <span>
              It appears that you are using an outdated version of our extension. Please follow
              these steps to update:
            </span>
            <br />
            <br />
            <span>
              1. Locate the <Icon icon={faPuzzle} /> icon at the top right corner of your browser.
              <br />
              2. Click on it to open the menu.
              <br />
              3. Select &quot;
              <Icon icon={faCog} /> Manage Extensions&quot; from the options.
              <br />
              4. Look for our extension in the list and click on it.
              <br />
              5. Locate the &quot;Update&quot; button at the top of the page and click on it.
            </span>
          </div>
        ),
        position: 'bottom-center',
        duration: Infinity,
        id: 'outdated-version',
      })
    }
  }

  if (
    searchParams.get('utm_medium') === 'extension' &&
    searchParams.get('utm_campaign') === 'install'
  ) {
    searchParamsToRemove.push('utm_medium', 'utm_campaign')
    showModal(
      (resolve) => <ExtensionInstalledModal resolve={resolve} />,
      'extension-installed-modal',
    )
    callApi(api.extInstallCallback, 'install', version!)
  }

  replaceCurrentRouterWithoutSearch(history, searchParamsToRemove)
}
