import * as React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import axios from 'axios'
import { SuccessPrimary, SignupContainer, H3, Divider } from '../SignupScreen/styles'
import cx from 'classnames'

import { cabalToast } from 'ui-components/Toast'

import CabalTitle from 'global/Title'
import useColorMode from 'utils/hooks/useColorMode'

const Container = styled.div`
  max-width: 375px;
  margin: 0 auto;
  margin-top: 180px;
`

const LogoContainer = styled.div`
  ${tw`text-center`}
`

const Logo = styled.img`
  ${tw`inline-block`}
  height: 64px;
  width: 64px;
  margin-bottom: 78px;
`

const TextPrimary = styled(H3)`
  font-size: 1rem;
  line-height: 22.4px;
  padding: 0;
`

const TextSecondary = styled(TextPrimary)`
  font-weight: 400;
  margin-bottom: 2rem;
`

const CardWrapper = styled.div`
  text-align: center;
  padding: 24px 16px 24px 16px;
  background-color: rgba(41, 51, 66, 0.64);

  @media (prefers-color-scheme: light) {
    background: #ffffff;
  }

  @media (min-width: 376px) {
    border-radius: 4px;
  }
`

const OptionText = styled.p`
  font-size: 14px;
  line-height: 19.6px;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  color: #718096 !important;
`

const NavWrapper = styled.p`
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #718096 !important;
`

const NavText = styled.p`
  font-size: 12px;
  color: #718096 !important;
`

const Wrapper = styled(SignupContainer)`
  height: 100vh !important;
`

const SignupPage = () => {
  const { isDarkMode } = useColorMode()
  const [email, setEmail] = React.useState('')
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)

  const handleSignup = () => {
    axios.post('/api/signups', { email, no_waitlist: true }).then((response) => {
      const { success, error } = response.data
      setSuccess(success || false)
      setError(error || null)
    })
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    handleSignup()
  }

  const year = new Date().getFullYear()

  return (
    <Wrapper className="h-screen">
      <CabalTitle title="Signup" />
      <Container className="flex justify-between flex-col h-3/4">
        <div>
          <LogoContainer>
            {isDarkMode ? (
              <Logo src={'/cabal-svg-dark.svg'} />
            ) : (
              <Logo src={'/cabal-svg-light.svg'} />
            )}
          </LogoContainer>
          {success ? (
            <div className="flex justify-center flex-col items-center">
              <SuccessPrimary className="mt-4">
                <i className="far fa-check-circle pr-3"></i>
                {`Email sent!`}
              </SuccessPrimary>
              <p className="pt-2">{`Please check your email for a login link`}</p>
            </div>
          ) : (
            <div>
              <a style={{ border: 0, outline: 'none' }} href="/users/auth/google_oauth2">
                <button className="mt-8">
                  <i className="fab fa-google pr-3" />
                  Sign up with Google
                </button>
              </a>
              <OptionText>{`or`}</OptionText>
              <form className="mx-auto" onSubmit={onSubmit}>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className={cx('mt-4')}
                  required
                />
                <button disabled={email === ''}>
                  <i className="far fa-key pr-3"></i>
                  Send a signup link
                </button>
              </form>
            </div>
          )}
        </div>

        <NavWrapper className="pb-4">
          <a href="/privacy" className="mr-1">
            <NavText>{`Privacy`}</NavText>
          </a>
          <span>{` · `}</span>
          <a href="/terms" className="mx-1">
            <NavText>{`Terms`}</NavText>
          </a>
          <span className="mr-1">{` · `}</span>
          {`Cabal © ${year}`}
        </NavWrapper>
      </Container>
    </Wrapper>
  )
}

export default SignupPage
