import React, { useState } from 'react'

import sortBy from 'lodash/sortBy'
import { v4 as uuid } from 'uuid'

import Avatar from 'global/Avatar'
import DropDownMenu from 'global/DropDownMenu'
import { TextArea, TextInput, UploadButton } from 'global/Input'
import CKEditor from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'

import { absoluteHtmlContent } from 'utils/html'

import { EditListType } from '..'

interface Props {
  list: EditListType
  setList: (list: Partial<EditListType>) => void
  hideListItemsLabel?: boolean
}

const OtherListItems: React.FC<Props> = ({ list, setList, hideListItemsLabel }) => {
  const emptyArray = Array.from({ length: 1 }, () => ({
    url: '',
    cta: '',
    name: '',
    avatar_upload_uuid: '',
    description: '',
    showDescription: true,
    uuid: uuid(),
  }))

  const itemsExist = list.company_list_items?.length > 0
  const [listItems, setListItems] = useState(
    itemsExist
      ? sortBy(list.company_list_items, (item) => (item.name ? item.name.toLowerCase() : item.name))
      : emptyArray,
  )

  const updateField = (field: string, uuid: string, value: string | boolean) => {
    const newListItems = listItems?.map((d) => {
      if (d.uuid !== uuid) {
        return d
      } else {
        return {
          ...d,
          [field]: value,
        }
      }
    })

    setList({ company_list_items: newListItems })
    setListItems(newListItems)
  }

  const itemEditor = (
    <div className="flex flex-col gap-2">
      {listItems &&
        listItems.map((l, idx) => (
          <div className="flex flex-col gap-1" key={`${l.uuid}-${idx} `}>
            <div className="flex gap-1 items-center">
              {l.avatar_upload_uuid ? (
                <div className="flex flex-end">
                  <UploadButton
                    allowedExtensions={['png', 'jpg', 'jpeg']}
                    onUpload={(uuid) => updateField('avatar_upload_uuid', l.uuid, uuid)}
                    showPencil={true}
                    text=""
                    triggerProps={{
                      variant: 'tertiary',
                      leftIcon: (
                        <Typography className="pr-3">
                          <Avatar src={`/api/uploads/${l.avatar_upload_uuid}`} size={'32'} />
                        </Typography>
                      ),
                      padding: '0',
                    }}
                  />
                </div>
              ) : (
                <UploadButton
                  allowedExtensions={['png', 'jpg', 'jpeg']}
                  onUpload={(uuid) => updateField('avatar_upload_uuid', l.uuid, uuid)}
                  showPencil={true}
                  text=""
                  triggerProps={{
                    variant: 'secondary',
                    leftIcon: <i className="far fa-image" />,
                  }}
                />
              )}

              <TextInput
                placeholder="Item Name"
                value={l.name || ''}
                component="input"
                onChangeCapture={(e) => {
                  updateField('name', l.uuid, e.currentTarget.value)
                }}
                className="flex-1"
              />
              <TextInput
                placeholder="Link"
                value={l.link || ''}
                component="input"
                onChangeCapture={(e) => {
                  updateField('link', l.uuid, e.currentTarget.value)
                }}
                className="flex-1"
              />
              <TextInput
                placeholder="CTA"
                value={l.cta || ''}
                component="input"
                onChangeCapture={(e) => {
                  updateField('cta', l.uuid, e.currentTarget.value)
                }}
                className="flex-1"
              />
              <DropDownMenu
                trigger={
                  <Typography component="button" className="ml-1.5" fontSize="12" color={'fog'}>
                    <i className="far fa-ellipsis" />
                  </Typography>
                }
                menuItems={[
                  {
                    label: 'Delete',
                    onSelect: () => {
                      const newListItems = listItems.filter((d) => d.uuid !== l.uuid)
                      setList({ company_list_items: newListItems })
                      setListItems(newListItems)
                    },
                  },
                ]}
              />
            </div>
            {(l.description?.length > 0 || !!l.showDescription) && (
              <div className="w-full pl-12 pr-5 rounded-md">
                <CKEditor
                  value={l.description || ''}
                  onChange={(e) => {
                    updateField('description', l.uuid, absoluteHtmlContent(e))
                  }}
                  height={100}
                />
              </div>
            )}
          </div>
        ))}

      <Typography
        className="cursor-pointer mt-1"
        component="p"
        color={'purple'}
        onClick={() => {
          const newListItems = [
            ...listItems,
            {
              url: '',
              cta: '',
              name: '',
              avatar_upload_uuid: '',
              uuid: uuid(),
              showDescription: true,
            } as any,
          ]
          setList({ company_list_items: newListItems })
          setListItems(newListItems)
        }}
        fontSize="12"
      >
        <i className="far fa-add" /> Add Item
      </Typography>
    </div>
  )

  return (
    <>
      <div>{itemEditor}</div>
    </>
  )
}

export default OtherListItems
