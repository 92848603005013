import * as React from 'react'

import cx from 'classnames'
import SignatureCanvas from 'react-signature-canvas'
import { useOrientation } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'

import Button from 'global/Button'
import Modal from 'global/Modal'

import { resizedataURL } from 'utils/image'

import CabalButton from './CabalButton'
import Typography from './Typography'

const SignatureContainer = styled.div`
  ${tw`mt-4 h-full w-full relative`}
  canvas {
  }
`

interface Props {
  show: boolean
  onHide: () => void
  onSubmit: (dataUrl: string) => void
}

const SignatureModal: React.FC<Props> = ({ show, onHide, onSubmit }) => {
  const sigRef = React.useRef<SignatureCanvas>(null)
  const sigContainerRef = React.useRef<HTMLDivElement>(null)
  const [sigCanvasWidth, setSigCanvasWidth] = React.useState(0)
  const [sigCanvasHeight, setSigCanvasHeight] = React.useState(0)
  const orientation = useOrientation()

  const handleSubmit = () => {
    if (!sigRef.current) return
    resizedataURL(
      sigRef.current.getTrimmedCanvas().toDataURL('image/png'),
      200,
      (200 * sigCanvasHeight) / sigCanvasWidth,
    ).then((r) => onSubmit(r))
    onHide()
  }

  const handleClear = () => {
    sigRef.current?.clear()
  }

  React.useEffect(() => {
    if (!show) return
    setTimeout(() => {
      if (!sigContainerRef.current) return
      let w = sigContainerRef.current.getBoundingClientRect().width
      if (Math.abs(orientation.angle) % 180 !== 0) w = window.screen.width
      setSigCanvasWidth(w)
      setSigCanvasHeight(w / 2)
    }, 100)
  }, [show, orientation])

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        header={'Draw your signature below'}
        leftActions={
          <>
            {/* <CabalButton onClick={onHide}>Cancel</CabalButton> */}
            <CabalButton variant="tertiary" padding="0" onClick={handleClear} className="">
              Clear
            </CabalButton>
          </>
        }
        rightActions={<CabalButton onClick={handleSubmit}>Done</CabalButton>}
      >
        <Typography fontSize="14" className="text-left" color="fog">
          Click and drag to draw your signature.
        </Typography>
        <SignatureContainer ref={sigContainerRef}>
          {!!sigCanvasWidth && (
            <div className="border inline-block border-gray-600 rounded">
              <SignatureCanvas
                penColor="#718096"
                canvasProps={{ height: sigCanvasHeight, width: sigCanvasWidth }}
                ref={sigRef}
                minWidth={2.5}
              />
            </div>
          )}
        </SignatureContainer>
      </Modal>
    </>
  )
}

export default SignatureModal
