import * as React from 'react'
import styled from 'styled-components'

import Phone from '../../../images/footer-desktop-phone.png'
import AppStore from '../../../images/app-store.png'
import GooglePlay from '../../../images/google-play.png'
import QR from '../../../images/qr.png'

const MobileInstallWrap = styled.div`
  /* background-color: #fff; */
  /* padding: 30px 32px; */
  /* border-radius: 8px; */
  max-width: 457px;

  /* @media (prefers-color-scheme: dark) {
    background-color: #0e151f;
  } */
`
const ColumnDoubleLayout = styled.div`
  display: grid;
  row-gap: 32px;
  align-items: flex-start;
  padding-bottom: 5.5rem;

  @media (min-width: 650px) {
    grid-template-columns: minmax(auto, 780px) minmax(auto, 500px);
    /* column-gap: 68px; */
  }
`

const TextSection = styled.div`
  position: relative;

  @media (min-width: 650px) {
    left: -150px;
  }
`

const Title = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 1.05;
  padding-bottom: 20px;
  letter-spacing: -0.02em;

  @media (min-width: 650px) {
    font-size: 40px;
  }
`

const SubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'JetBrains Mono';
  padding-bottom: 28px;
  color: #6b7280 !important;
`

const ButtonImg = styled.img`
  height: 60px;
`

const BottomSection = () => {
  return (
    <ColumnDoubleLayout className="z-10 -mb-10">
      <section>
        <img src={Phone} alt="Mobile Preview" />
      </section>
      <TextSection className="flex flex-col mt-10 sm:mt-60 p-5">
        <Title>Do more for founders</Title>
        <SubTitle>
          Offer intros, boost big news, and help close influential hires. All from one app.
        </SubTitle>
        <MobileInstallWrap className="flex items-center">
          <a
            href="https://apps.apple.com/us/app/cabal-do-more-for-founders/id1549129384?itsct=apps_box_badge&amp;itscg=30200"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonImg src={AppStore} height={60} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.getcabal&utm_source=langing-page&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <ButtonImg src={GooglePlay} height={60} />
          </a>
          <img src={QR} width={64} className="hidden sm:block ml-7" />
        </MobileInstallWrap>
      </TextSection>
    </ColumnDoubleLayout>
  )
}

export default BottomSection
