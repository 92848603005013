import React, { useEffect, useMemo, useState } from 'react'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'

import styled from 'styled-components'

import Loading from 'global/Loading'
import Typography from 'global/Typography'
import Icon from 'ui-components/Icon'

import { cn } from 'utils/styles'

const SectionWrapper = styled.button<{ disabled: boolean }>`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.border : theme.layout.main_content_bg_color};
`

const Selector = ({
  title,
  description,
  onClick,
  isDisabled,
  loading,
  diabledMsg,
  ...otherProps
}: {
  title: string
  description: string
  onClick?: () => void
  isDisabled: boolean
  loading?: boolean
  diabledMsg?: string
}) => {
  return (
    <SectionWrapper
      onClick={onClick}
      className={cn(
        'flex items-center cursor-default justify-between p-4 rounded-xl w-full',
        'border border-light',
        {
          'cursor-pointer shadow-sm': !isDisabled,
          'cursor-not-allowed': isDisabled,
        },
      )}
      disabled={isDisabled || false}
      {...otherProps}
    >
      <div className="flex flex-col items-start">
        <div className="flex items-center">
          <Typography fontSize="12" fontWeight={600}>
            {title}
          </Typography>
          {isDisabled && (
            <Typography color="fog" fontSize="12" className="ml-2">
              {diabledMsg}
            </Typography>
          )}
        </div>

        <Typography fontSize="12">{description}</Typography>
      </div>
      {!isDisabled && (
        <div className="flex items-center">
          {loading && <Loading size={16} className="mr-2" data-testid="loading-indicator" />}
          <Icon icon={faChevronRight} className="w-4 h-4" />
        </div>
      )}
    </SectionWrapper>
  )
}

interface Props {
  personName: string
  selectedUserCount: number
  checkConnections: () => void
  draftOnBehalf: () => void
  creatingConnectionDraft: boolean
}

const SelectIntroType: React.FC<Props> = ({
  personName,
  selectedUserCount,
  checkConnections,
  draftOnBehalf,
  creatingConnectionDraft,
}) => {
  const allowConnectionClick = selectedUserCount > 0
  const allowDraftClick = selectedUserCount === 1

  return (
    <div className="flex flex-col flex-initial">
      <Typography fontSize="14" fontWeight={600}>
        Request type:
      </Typography>
      <div className="mt-4 space-y-2">
        <Selector
          title="Connection Check"
          description={`Ask connections if they can make an intro.`}
          diabledMsg={'Select one or more connections'}
          onClick={allowConnectionClick ? checkConnections : undefined}
          isDisabled={!allowConnectionClick}
          loading={creatingConnectionDraft}
          data-testid="get-intro-connection-check-button"
        />
        <Selector
          title="Draft on Behalf"
          description={`Ghostwrite an email for your connection to send.`}
          diabledMsg={'Must select a single connection to use this feature'}
          onClick={allowDraftClick ? draftOnBehalf : undefined}
          isDisabled={!allowDraftClick}
          data-testid="get-intro-draft-on-behalf-button"
        />
        {/* <Selector
          title="Forwardable Email"
          description={`Send your connection an email with context that they can forward.`}
          onClick={() => onSetIntroType('forwardable_email')}
          selected={introType === 'forwardable_email'}
        /> */}
      </div>
    </div>
  )
}

export default SelectIntroType
