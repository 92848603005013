import React from 'react'

import DefaultContextContainer from 'containers/Asks/DealBoard/DefaultContextContainer'
import WelcomeEditModal from 'containers/Settings/AsksSettings/WelcomeEditModal'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Widget from 'ui-components/Widget'

import { MutationFunc, Team } from 'utils/types'

interface Props {
  updateTeamMutation: MutationFunc<Partial<Team>>
  companySlug: string
}

const MemberViewSettings: React.VFC<Props> = ({ updateTeamMutation, companySlug }) => {
  const { showModal } = useModal()

  const renderWelcomeEditModal = (resolve) => (
    <WelcomeEditModal
      onHide={() => resolve()}
      teamSlug={companySlug}
      updateTeamMutation={updateTeamMutation}
    />
  )

  return (
    <Widget
      title="Welcome Message"
      cta={
        <CabalButton
          variant="link"
          // onClick={() => setShowPreview(!showPreview)}
          onClick={() => showModal(renderWelcomeEditModal, 'welcome-edit-modal')}
          leftIcon={<i className="fa fa-pencil" />}
        ></CabalButton>
      }
    >
      <div className="flex flex-col gap-4">
        <DefaultContextContainer companySlug={companySlug} preview={true} />
      </div>
    </Widget>
  )
}

export default MemberViewSettings
