import React, { useContext, useState } from 'react'

import CabalButton from 'global/CabalButton'
import { Label, CheckBox, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import TextEditor from 'global/TextEditor'
import { absoluteHtmlContent } from 'utils/html'
import { AgreementTemplateModel } from 'utils/types'
import EmailPreviewModal from 'components/EmailPreviewModal'
import { ModalContext, RenderModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import AcceptContent from 'containers/AdvisorInviteAccept/AcceptContent'
import { useCurrentUser, useTeam } from 'store/hooks'
import { agreementMessageVariables } from 'utils/constants/agreement'
import { useSetState } from 'react-use'

type SetAgreementTemplateProperty = <K extends keyof AgreementTemplateModel>(
  key: K,
  value: AgreementTemplateModel[K],
) => void

interface Props {
  resolve: () => void
  agreementTemplate: AgreementTemplateModel
  setSetting: SetAgreementTemplateProperty
  save: () => Promise<unknown>
  companySlug: string
}

const AgreementTemplateSettingsModal: React.VFC<Props> = ({
  resolve,
  setSetting,
  agreementTemplate: _agreementTemplate,
  save,
  companySlug,
}) => {
  const { showModal } = useContext(ModalContext)
  const { team } = useTeam(companySlug)
  const { user } = useCurrentUser()

  const [
    { invite_mail_message, invite_page_message, vesting_start_as_signing_date, name },
    _setAgreementTemlate,
  ] = useSetState(_agreementTemplate)

  const [working, setWorking] = useState(false)
  const [showInviteMailMessage, setShowInviteMailMessage] = useState(!!invite_mail_message)
  const [showInvitePageMessage, setShowInvitePageMessage] = useState(!!invite_page_message)

  const setAgreementTemlate: SetAgreementTemplateProperty = (k, v) => {
    _setAgreementTemlate({ [k]: v })
    setSetting(k, v)
  }

  const handleSave = () => {
    setWorking(true)

    save().then(() => resolve())
  }

  const renderInviteEmailPreview: RenderModal = (resolve) => (
    <EmailPreviewModal resolve={resolve} inviteMessage={invite_mail_message} />
  )

  const renderInvitationPreview: RenderModal = (resolve) => (
    <Modal
      show
      onHide={resolve}
      header={'Invitation Preview'}
      leftActions={
        <CabalButton onClick={() => resolve()} leftIcon={<i className="fas fa-caret-left" />}>
          Back
        </CabalButton>
      }
    >
      <AcceptContent
        companyName={team!.name}
        logo={team!.logo}
        sender={user}
        invitePageMessage={invite_page_message!}
        isPreview
      />
    </Modal>
  )

  return (
    <Modal
      show
      size="lg"
      onHide={resolve}
      header="Agreement Template Settings"
      rightActions={
        <CabalButton onClick={handleSave} working={working}>
          Save
        </CabalButton>
      }
    >
      <TextInput
        placeholder="Agreement Template Name"
        value={name}
        onChange={(e) => setAgreementTemlate('name', e.currentTarget.value)}
        labeled
        className="w-full mb-3"
        valueType="non-empty"
        required
      />
      <Label>
        <CheckBox
          defaultChecked={vesting_start_as_signing_date}
          onChange={(e) =>
            setAgreementTemlate('vesting_start_as_signing_date', e.currentTarget.checked)
          }
          className="mr-2"
          label="Use Signing Date as Vesting Start Date"
        />
      </Label>
      <div className="flex justify-between">
        <Label>
          <CheckBox
            defaultChecked={!!invite_mail_message}
            onChange={(e) => {
              const checked = e.currentTarget.checked

              setShowInviteMailMessage(checked)
              if (!checked) setAgreementTemlate('invite_mail_message', null)
            }}
            label="Use custom invite mail message"
          />
        </Label>
        {invite_mail_message && (
          <Typography
            fontSize="12"
            color="fog"
            component="button"
            onClick={() => showModal(renderInviteEmailPreview, 'renderInviteEmailPreview')}
          >
            Preview
          </Typography>
        )}
      </div>
      {showInviteMailMessage && (
        <div className="mb-base">
          <TextEditor
            allowVariables
            variables={agreementMessageVariables}
            value={invite_mail_message || ''}
            onChange={(val) => setAgreementTemlate('invite_mail_message', absoluteHtmlContent(val))}
          />
        </div>
      )}
      <div className="flex justify-between">
        <Label>
          <CheckBox
            defaultChecked={!!invite_page_message}
            onChange={(e) => {
              const checked = e.currentTarget.checked

              setShowInvitePageMessage(checked)
              if (!checked) setAgreementTemlate('invite_page_message', null)
            }}
            label="Use custom invite page message"
          />
        </Label>
        {invite_page_message && (
          <Typography
            fontSize="12"
            color="fog"
            component="button"
            onClick={() => showModal(renderInvitationPreview, 'renderInvitationPreview')}
          >
            Preview
          </Typography>
        )}
      </div>
      {showInvitePageMessage && (
        <TextEditor
          allowVariables
          variables={agreementMessageVariables}
          value={invite_page_message || ''}
          onChange={(val) => setAgreementTemlate('invite_page_message', absoluteHtmlContent(val))}
        />
      )}
    </Modal>
  )
}

export default AgreementTemplateSettingsModal
