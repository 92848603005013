import * as React from 'react'

import styled from 'styled-components'

import Gem from '../Logos/gem.png'
import Microacquire from '../Logos/microacquire.png'
import Modernhealth from '../Logos/modernhealth.png'
import OnDeck from '../Logos/ondeck.png'
import Openphone from '../Logos/openphone.png'
import Pipe from '../Logos/pipe.png'
import Ramp from '../Logos/ramp.png'
import Republic from '../Logos/republic.png'
import Scratchpad from '../Logos/scratchpad.png'
import Sendoso from '../Logos/sendoso.png'
import Sourcegraph from '../Logos/sourcegraph.png'
import Superplastic from '../Logos/superplastic.png'

const LogoColumn = styled.div`
  display: grid;
  row-gap: 0px;
  align-items: flex-center;
  grid-template-columns: repeat(3, auto);
  column-gap: 10px;

  @media (min-width: 650px) {
    grid-template-columns: repeat(6, 1fr);
    column-gap: 40px;
  }
`

const Img = styled.img`
  height: 60px;

  @media (min-width: 650px) {
    height: 96px;
  }
`

const LogoWall = () => {
  return (
    <>
      <LogoColumn className="pb-3">
        <Img src={Scratchpad} />
        <Img src={Ramp} />
        <Img src={Sourcegraph} />
        <Img src={Pipe} />
        <Img src={Gem} />
        <Img src={Modernhealth} />
      </LogoColumn>
      <LogoColumn>
        <Img src={Openphone} />
        <Img src={OnDeck} />
        <Img src={Superplastic} />
        <Img src={Republic} />
        <Img src={Sendoso} />
        <Img src={Microacquire} />
      </LogoColumn>
    </>
  )
}

export default LogoWall
