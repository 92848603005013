enum actionTypes {
  setGroups = 'SET_GROUPS',
  setAdvisors = 'SET_ADVISORS',
  setAdvisor = 'SET_ADVISOR',
  setUnreadCounts = 'SET_UNREAD_COUNTS',
  setToast = 'SET_TOAST',
  setToggleRightBar = 'TOGGLE_RIGHT_BAR',
  setCloseRightBar = 'CLOSE_RIGHT_BAR',
  setCurrentUserAdvisor = 'SET_CURRENT_USER_ADVISOR',
  setCurrentUserSettings = 'SET_CURRENT_USER_SETTINGS',
  setCurrentUserProfile = 'SET_CURRENT_USER_PROFILE',
  setCompanies = 'SET_COMPANIES',
  setTeam = 'SET_TEAM',
}

export default actionTypes
