import * as React from 'react'
import * as ReactModal from 'react-modal'
import { lighten, transparentize } from 'polished'
import cx from 'classnames'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useKeyPress } from 'react-use'

import Typography from 'global/Typography'

interface Props {
  show: boolean
  onHide: () => void
  size?: 'xs' | 'xl' | 'lg' | 's' | 'md' | 's' | 'fixed-s' | 'fixed-lg'
  width?: number
  header?: React.ReactNode
  closeOnEsc?: boolean
  closeOnOverlayClick?: boolean
  /**
   * does this modal have child modals,
   * if yes, when user presses esc, this modal will be closed only when it is the only open one
   */
  hasChildModals?: boolean
}

const AbsoluteCloseIcon = styled.button`
  ${tw`p-8 top-0 right-0 focus:outline-none z-10`}
  position: absolute;
  color: ${({ theme }) => theme.modal.close_icon_color};

  &:hover {
    color: ${({ theme }) => lighten(0.1, theme.modal.close_icon_color)};
  }
`

const CloseIconButton = styled.button`
  ${tw`px-1 -mr-1 focus:outline-none`}
  color: ${({ theme }) => theme.modal.close_icon_color};

  &:hover {
    color: ${({ theme }) => lighten(0.1, theme.modal.close_icon_color)};
  }
`

const ModalContent = styled.div`
  ${tw`transition-all w-full h-full p-6 py-4 z-50`}
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fw.regular};
`

const ModalContainer = styled.div`
  ${tw`shadow-xl m-auto shadow-lg z-50`}
  opacity: 1;
  background-color: ${({ theme }) => theme.colors.shadow};
  max-height: 80%;
  overflow-y: auto;
  border-radius: 15px;
`

const ModalHeaderContainer = styled.div`
  ${tw`flex items-center sticky top-0 py-2 px-1 z-50`}
  background-color: ${({ theme }) => theme.colors.shadow};
`

const ModalChildrenContainer = styled.div`
  ${tw`p-1 overflow-auto`}
`

const ModalUnderlay = styled.div`
  ${tw`absolute w-full h-full z-40 px-4 sm:px-0`}

  background-color: ${({
    theme: {
      modal: { underlay_opacity, underlay_bg },
    },
  }) => transparentize(1 - underlay_opacity, underlay_bg)};
`

const ModalV1: React.FC<Props> = ({
  show,
  onHide,
  children,
  size = 'lg',
  width,
  header,
  closeOnEsc = true,
  closeOnOverlayClick = false,
  hasChildModals,
}) => {
  const [isEscPressed] = useKeyPress((e) => e.key === 'Escape')
  const containerRef = React.useRef<HTMLDivElement>(null)

  const renderHeader = (
    <ModalHeaderContainer>
      <Typography className="flex-1" color="primary" fontSize="18" fontWeight={600} component="div">
        {header}
      </Typography>
      <CloseIconButton className="flex-initial" onClick={onHide}>
        <Typography fontSize="20">
          <i className="far fa-times" />
        </Typography>
      </CloseIconButton>
    </ModalHeaderContainer>
  )

  React.useEffect(() => {
    if (closeOnEsc && isEscPressed && show) {
      const modalPortals = document.querySelectorAll('.ReactModalPortal .ReactModal__Overlay')
      if (hasChildModals && modalPortals.length > 1) return
      onHide()
    }
  }, [isEscPressed])

  return (
    <ReactModal
      isOpen={show}
      shouldCloseOnEsc={closeOnEsc}
      onRequestClose={onHide}
      shouldFocusAfterRender
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      appElement={document.querySelector('body > div')!}
      overlayElement={(props, children) => <ModalUnderlay {...props}>{children}</ModalUnderlay>}
      contentElement={(props, children) => (
        <ModalContainer
          ref={containerRef}
          className={cx(
            'w-full sm:w-10/12',
            !width && {
              'lg:w-4/12': size == 'xs',
              'lg:w-5/12': size == 's',
              'lg:w-6/12': size == 'md',
              'lg:w-7/12': size == 'lg',
              'lg:w-10/12': size == 'xl',
              'lg:w-96': size == 'fixed-s',
              'lg:w-9/12': size === 'fixed-lg',
            },
          )}
          style={{
            width: !!width ? width : undefined,
          }}
        >
          {children}
        </ModalContainer>
      )}
      style={{
        content: {
          inset: 0,
          backgroundColor: undefined,
          border: 0,
        },
        overlay: {
          backgroundColor: undefined,
          border: 0,
          display: 'flex',
          justifyContent: 'center',
        },
      }}
    >
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {!header && (
          <AbsoluteCloseIcon onClick={onHide}>
            <Typography fontSize="18">
              <i className="far fa-times" />
            </Typography>
          </AbsoluteCloseIcon>
        )}
        {!!header && renderHeader}

        {!!header && <ModalChildrenContainer>{children}</ModalChildrenContainer>}
        {!header && children}
      </ModalContent>
    </ReactModal>
  )
}

export function ModalHeader(props: React.PropsWithChildren<any>) {
  return (
    <Typography fontWeight={500} fontSize="20" className="block mb-base">
      {props.children}
    </Typography>
  )
}

export default ModalV1
