import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { cabalToast } from 'ui-components/Toast'

import { useTeamSlug } from 'store/hooks'
import api, { callApi } from 'utils/api'
import { EmailSnippet } from 'utils/types'
import Modal from 'global/Modal'
import Loading from 'global/Loading'

import ViewSnippets from './ViewSnippets'
import AddSnippet from './AddSnippet'
import EditSnippet from './EditSnippet'

interface SnippetsModalProps {
  onReloadSnippets: () => Promise<any>
  show: boolean
  onHide: () => void
  onCreate?: () => void
  onUpdate?: () => void
  onDelete?: () => void
}

enum View {
  list,
  add,
  edit,
}

type ViewState<T> = { kind: View.list } | { kind: View.add } | { kind: View.edit; snippet: T }

const SnippetsModal: React.VFC<SnippetsModalProps> = ({ show, onHide, onReloadSnippets }) => {
  const teamSlug = useTeamSlug()

  const [view, setView] = useState<ViewState<EmailSnippet>>({ kind: View.list })

  const {
    data: snippets,
    isLoading: isSnippetsLoading,
    refetch: refetchEmailSnippets,
  } = useQuery([teamSlug, 'getEmailSnippets'], () => callApi(api.getEmailSnippet, teamSlug))

  const createEmailSnippet = useMutation(
    (params: Partial<EmailSnippet>) => callApi(api.createEmailSnippet, teamSlug, params),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Successfully added snippet!' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to add snippet!' })
      },
    },
  )

  const updateEmailSnippet = useMutation<
    unknown,
    unknown,
    { uuid: string; params: Partial<EmailSnippet> }
  >(({ uuid, params }) => callApi(api.updateEmailSnippet, teamSlug, uuid, params), {
    onSuccess: () => {
      cabalToast({ style: 'success', content: 'Successfully updated snippet!' })
    },
    onError: () => {
      cabalToast({ style: 'error', content: 'Failed to update snippet!' })
    },
  })

  const deleteEmailSnippet = useMutation(
    (uuid: string) => callApi(api.deleteEmailSnippet, teamSlug, uuid),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Successfully deleted snippet!' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to deleted snippet!' })
      },
    },
  )

  const handleCreate = async (params: Partial<EmailSnippet>) => {
    await createEmailSnippet.mutateAsync(params)
    await handleAfterMutation()
  }

  const handleUpdate = async (uuid: string, params: Partial<EmailSnippet>) => {
    await updateEmailSnippet.mutateAsync({ uuid, params })
    await handleAfterMutation()
  }

  const handleClickDelete = async (uuid: string) => {
    if (!confirm('Are you sure you want to delete this snippet?')) return

    await deleteEmailSnippet.mutateAsync(uuid)
    await handleAfterMutation()
  }

  const handleAfterMutation = async () => {
    setView({ kind: View.list })
    await refetchEmailSnippets()
    onReloadSnippets()
  }

  const isLoading =
    createEmailSnippet.isLoading || updateEmailSnippet.isLoading || deleteEmailSnippet.isLoading

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      header="Manage email snippets"
      dangerouslyBypassFocusLock
    >
      {isSnippetsLoading && !snippets ? (
        <Loading />
      ) : view.kind === View.list && snippets ? (
        <ViewSnippets
          disabled={isLoading}
          snippets={snippets.email_snippets}
          onClickAdd={() => setView({ kind: View.add })}
          onClickEdit={(snippet) => setView({ kind: View.edit, snippet: snippet })}
          onClickDelete={(snippet) => handleClickDelete(snippet.uuid)}
        />
      ) : view.kind === View.add ? (
        <AddSnippet
          disabled={isLoading}
          onSubmit={handleCreate}
          onClickCancel={() => setView({ kind: View.list })}
        />
      ) : view.kind === View.edit ? (
        <EditSnippet
          disabled={isLoading}
          snippet={view.snippet}
          onSubmit={(params) => handleUpdate(view.snippet.uuid, params)}
          onClickCancel={() => setView({ kind: View.list })}
        />
      ) : null}
    </Modal>
  )
}
export default SnippetsModal
