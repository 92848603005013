import React from 'react'
import Typography from 'global/Typography'
import { useCurrentUser, useUniqueAccessLink } from 'store/hooks'
import Loading from 'global/Loading'

const LoggedInSection: React.VFC = () => {
  const { isLoading: isLoadingUser } = useCurrentUser()

  const { uniqueAccessLinkData, isLoadingUniqueAccessLink: isLoadingUniqueAccessLink } =
    useUniqueAccessLink()

  if (isLoadingUniqueAccessLink || isLoadingUser) {
    return <Loading />
  }

  return (
    <div className="my-2">
      <Typography className="mr-3" fontSize="12">
        Sending request as:
      </Typography>

      <div className="flex pl-2 text-left items-center">
        <Typography fontSize="12" fontWeight={600} data-testid="link-recipient-name">
          {`${uniqueAccessLinkData.recipient_first_name ?? ''} ${
            uniqueAccessLinkData.recipient_last_name ?? ''
          }`}
        </Typography>
        {uniqueAccessLinkData.recipient_first_name && (
          <Typography fontSize="12" fontWeight={600} className="mx-1">
            {` · `}
          </Typography>
        )}
        <Typography fontSize="12" fontWeight={600} data-testid="link-recipient-email">
          {uniqueAccessLinkData.recipient_email}
        </Typography>
      </div>
    </div>
  )
}

export default LoggedInSection
