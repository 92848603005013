import React, { useState } from 'react'

import { Field, FieldProps, Formik } from 'formik'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import validator from 'validator'
import { object, string } from 'yup'

import { SelectWrapper } from 'containers/CandidateProfileModal/PersonalInfo'
import { IPortfolioCompany } from 'containers/ListIndexView/PortfolioList/types'
import { IJob } from 'containers/ListIndexView/TalentList/JobsList/types'
import { OFFICE_OPTIONS, ROLE_FUNCTION_OPTIONS } from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import {
  CheckBoxField,
  MultiSelectField,
  SelectField,
  TextArea,
  TextInputField,
} from 'global/Input'
import MultiCityInput from 'global/Input/MultiCityInput'
import {
  ModalInputLabel,
  ModalInputWrapper,
  ModalSectionHeader,
  ModalSectionWrapper,
} from 'global/Modal/styles'
import CKEditor from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'
import { useAdvisors, useTeam } from 'store/hooks'
import InfoTooltip from 'ui-components/InfoTooltip'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { absoluteHtmlContent } from 'utils/html'
import { cn } from 'utils/styles'
import { InvestorCompany } from 'utils/types/investor'

interface Props {
  editing: boolean
  onHide: () => void
  jobData?: IJob
  setJobData: (jobData: IJob) => void
  updateJobMutation: any
  deleteJobMutation: any
  isDeleting: boolean
  teamSlug: string
  company: IPortfolioCompany | InvestorCompany
  showListDropdown: boolean
  memberAdding: boolean
}

const LineBreak = styled.hr`
  border: none;
  border-bottom: ${({ theme }) => theme.border};
  width: 100%;
  margin: 10px 0;
`

const JobInfo: React.FC<Props> = ({
  editing,
  onHide,
  jobData,
  setJobData,
  updateJobMutation,
  deleteJobMutation,
  isDeleting,
  teamSlug,
  company,
  showListDropdown,
  memberAdding,
}) => {
  const jobInfoSchema = object({
    title: string().required('This field is required'),
    investor_company_uuid: string().required('This field is required'),
    talent_poc: object({
      email: string().required('This field is required'),
      first_name: string().required('This field is required'),
      last_name: string().required('This field is required'),
    }),
  })
  const { team } = useTeam(teamSlug)
  const { advisors, isAdvisorLoaded } = useAdvisors({ teamSlug })

  const { data: portfolioCompaniesData, isLoading: isLoadingPortfolioCompanies } = useQuery(
    ['jobCompanies', team?.slug],
    () => callApi(api.jobCompanies, '', team?.slug!),
  )

  const portfolioCompanies = (portfolioCompaniesData || []).filter((c) => c.company_name)

  const { data: jobLists, isLoading: isLoadingJobLists } = useQuery(
    ['jobLists', showListDropdown],
    () => callApi(api.jobLists, teamSlug),
    {
      enabled: !memberAdding,
    },
  )

  const [initialValues] = useState(() => {
    if (jobData && jobData.list_uuids) {
      const filteredListUuids = jobData.list_uuids.filter((uuid) => {
        const list = jobLists?.find((list) => list.uuid === uuid)
        return list && !list.is_all_jobs
      })
      return { ...jobData, list_uuids: filteredListUuids }
    }
    return jobData
  })

  const filteredJobLists = jobLists?.filter((list) => !list.is_all_jobs) || []
  const { data: jobTags } = useQuery(
    ['getJobTags', teamSlug],
    () => callApi(api.getJobTags, teamSlug!),
    {
      enabled: !!teamSlug,
    },
  )

  return (
    <div>
      <Formik
        initialValues={initialValues ?? {}}
        validationSchema={jobInfoSchema}
        context={{ showListDropdown }}
        onSubmit={(values) => {
          updateJobMutation.mutate(values)
        }}
      >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(event) => {
              if (
                event.key === 'Enter' &&
                event.target.tagName !== 'TEXTAREA' &&
                !event.target.closest('.ck-editor__editable')
              ) {
                event.preventDefault()
              }
            }}
          >
            <ModalSectionWrapper>
              {!memberAdding && (
                <ModalInputWrapper className="">
                  <ModalInputLabel>Portfolio Company</ModalInputLabel>
                  <Field name="investor_company_uuid">
                    {(props: FieldProps) => (
                      <SelectField
                        fieldProps={props}
                        placeholder="Portfolio Company"
                        data-testid="job-modal-portfolio-company"
                        autoFocus={true}
                        onChange={(opt) => {
                          props.form.setFieldValue('investor_company_uuid', opt)
                          setJobData({ ...jobData, investor_company_uuid: opt })
                        }}
                        options={
                          portfolioCompanies.map((f) => ({
                            label: f.company_name,
                            value: f.uuid,
                          })) || []
                        }
                        isLoading={isLoadingPortfolioCompanies}
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
              )}

              {!memberAdding && showListDropdown && (
                <ModalInputWrapper className="">
                  <ModalInputLabel>List</ModalInputLabel>
                  <Field name="list_uuids">
                    {(props: FieldProps) => (
                      <MultiSelectField
                        fieldProps={props}
                        placeholder="Select job lists"
                        data-testid="job-modal-jobs-list"
                        onChange={(opts) => {
                          props.form.setFieldValue('list_uuids', opts)
                          setJobData({ ...jobData, list_uuids: opts })
                        }}
                        options={
                          filteredJobLists.map((f) => ({
                            label: f.name,
                            value: f.uuid,
                          })) || []
                        }
                        isLoading={isLoadingJobLists}
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
              )}

              <ModalInputWrapper className="">
                <ModalInputLabel>Title</ModalInputLabel>

                <Field name="title">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Title"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="job-title"
                      onChange={(e) => {
                        props.form.setFieldValue('title', e.target.value)
                        setJobData({ ...jobData, title: e.target.value })
                      }}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="">
                <ModalInputLabel>Function</ModalInputLabel>
                <Field name="function">
                  {(props: FieldProps) => (
                    <SelectField
                      fieldProps={props}
                      placeholder="Job function"
                      data-testid="job-modal-function"
                      isClearable={true}
                      onChange={(opt) => {
                        props.form.setFieldValue('function', opt)
                        setJobData({ ...jobData, function: opt })
                      }}
                      options={ROLE_FUNCTION_OPTIONS || []}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper>
                <ModalInputLabel>Location(s)</ModalInputLabel>
                <Field name="locations">
                  {(props: FieldProps) => (
                    <SelectWrapper>
                      <MultiCityInput
                        {...props.field}
                        onBlur={() => {
                          props.form.setFieldTouched(props.field.name, true)
                        }}
                        onChange={(selected) => {
                          const locations = selected.map((opt) => opt.value)
                          props.form.setFieldValue('locations', locations)
                          setJobData({ ...jobData, locations })
                        }}
                        value={
                          props.field.value
                            ? props.field.value.map((loc: string) => ({
                                value: loc,
                                label: loc,
                              }))
                            : null
                        }
                        error={props.meta.touched ? props.meta.error : undefined}
                      />
                    </SelectWrapper>
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="">
                <ModalInputLabel>Office</ModalInputLabel>
                <Field name="office_location">
                  {(props: FieldProps) => (
                    <SelectField
                      fieldProps={props}
                      placeholder="Office"
                      data-testid="job-modal-office"
                      isClearable={true}
                      onChange={(opt) => {
                        props.form.setFieldValue('office_location', opt)
                        setJobData({ ...jobData, office_location: opt })
                      }}
                      options={OFFICE_OPTIONS || []}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="">
                <ModalInputLabel>Job Rec URL</ModalInputLabel>

                <Field name="url">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Job Rec URL"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="job-url"
                      onChange={(e) => {
                        props.form.setFieldValue('url', e.target.value)
                        setJobData({ ...jobData, url: e.target.value })
                      }}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              {!memberAdding && (
                <ModalInputWrapper className="">
                  <ModalInputLabel>
                    Display on Portfolio Company
                    <InfoTooltip className="ml-2">
                      <Typography lineHeight={1.2} fontSize="13" className="pb-1" component="p">
                        If checked, Members with access to the Portfolio list will see this job
                        displayed on the portfolio company's Page
                      </Typography>
                    </InfoTooltip>
                  </ModalInputLabel>

                  <Field name="visibility">
                    {(props: FieldProps) => (
                      <CheckBoxField
                        fieldProps={props}
                        checked={jobData?.visibility}
                        onChange={(e) => {
                          props.form.setFieldValue('visibility', e.target.checked)
                          setJobData({ ...jobData, visibility: e.target.checked })
                        }}
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
              )}

              {!memberAdding && !editing && (
                <ModalInputWrapper>
                  <ModalInputLabel>Archived</ModalInputLabel>
                  <Field name="archived">
                    {(props: FieldProps) => (
                      <CheckBoxField
                        fieldProps={props}
                        checked={jobData?.archived}
                        onChange={(e) => {
                          props.form.setFieldValue('archived', e.target.checked)
                          setJobData({ ...jobData, archived: e.target.checked })
                        }}
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
              )}

              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Tags</div>
                </ModalInputLabel>
                <Field name="tags">
                  {(props: FieldProps) => (
                    <MultiSelectField
                      placeholder="Tags"
                      creatable={!memberAdding}
                      fieldProps={props}
                      options={jobTags?.tags?.map((tag) => ({ label: tag, value: tag })) || []}
                      onCreateOption={(value) => {
                        setJobData({ ...jobData, tags: [...jobData?.tags, value] })
                      }}
                      onChange={(selectedOptions) => {
                        const selectedTags = selectedOptions.map((option) => option.value)
                        setJobData({ ...jobData, tags: selectedTags })
                      }}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper>
                <ModalInputLabel>Description</ModalInputLabel>
                <Field name="description">
                  {(props: FieldProps) => (
                    <CKEditor
                      value={props.field.value}
                      onChange={(v) => {
                        props.form.setFieldValue('description', v)
                        setJobData({ ...jobData, description: absoluteHtmlContent(v) || '' })
                      }}
                      height={100}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              {memberAdding && !editing && (
                <>
                  <LineBreak />
                  <ModalInputWrapper>
                    <Field name="note_for_team">
                      {(props: FieldProps) => (
                        <>
                          <ModalInputLabel>Note for {team.name}</ModalInputLabel>
                          <TextArea
                            {...props.field}
                            className="w-full"
                            placeholder="Add notes about this role for the Craft team"
                            data-testid="note_for_team"
                            maxLength={400}
                            rows={5}
                            onChange={(e) => {
                              props.form.setFieldValue('note_for_team', e.target.value)
                              setJobData({ ...jobData, note_for_team: e.target.value })
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </ModalInputWrapper>
                </>
              )}

              {!memberAdding && (
                <ModalSectionHeader>
                  {company && `${company.company_name} `}
                  Point of Contact
                </ModalSectionHeader>
              )}

              {!memberAdding && (
                <ModalInputWrapper>
                  <ModalInputLabel>Email</ModalInputLabel>
                  <Field name="talent_poc.email">
                    {(props: FieldProps) => (
                      <SelectField
                        fieldProps={props}
                        data-testid="job-modal-talent-poc-email"
                        isLoading={!isAdvisorLoaded}
                        className="w-full"
                        onChange={(v) => {
                          const advisor = advisors?.find((m) => m.email === v)
                          let details = { ...jobData.talent_poc, email: v || undefined }
                          if (advisor) {
                            const { first_name, last_name, linkedin_url, email } = advisor
                            details = {
                              first_name: first_name || '',
                              last_name: last_name || '',
                              linkedin_url: linkedin_url || '',
                              email: email || '',
                            }
                          }
                          props.form.setFieldValue('talent_poc.first_name', details.first_name)
                          props.form.setFieldValue('talent_poc.last_name', details.last_name)
                          props.form.setFieldValue('talent_poc.linkedin_url', details.linkedin_url)
                          setJobData({
                            ...jobData,
                            talent_poc: details,
                          })
                        }}
                        onCreateOption={(email) => {
                          if (!validator.isEmail(email)) {
                            cabalToast({
                              style: 'error',
                              content: `Please enter a valid email address.`,
                            })
                            return
                          }
                          props.form.setFieldValue('talent_poc.email', email)
                          setJobData({
                            ...jobData,
                            talent_poc: {
                              ...jobData.talent_poc,
                              email,
                            },
                          })
                        }}
                        options={
                          advisors?.map((f) => ({
                            label: f.email,
                            value: f.email,
                          })) || []
                        }
                        creatable
                        virtualized
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
              )}
              {!memberAdding && (
                <ModalInputWrapper>
                  <ModalInputLabel>First Name</ModalInputLabel>
                  <Field name="talent_poc.first_name">
                    {(props: FieldProps) => (
                      <TextInputField
                        {...props.field}
                        value={jobData.talent_poc?.first_name}
                        className="w-full"
                        placeholder="First Name"
                        error={props.meta.touched ? props.meta.error : undefined}
                        data-testid="job-modal-talent-poc-first-name"
                        onChange={(e) => {
                          props.form.setFieldValue('talent_poc.first_name', e.target.value)
                          setJobData({
                            ...jobData,
                            talent_poc: {
                              ...jobData.talent_poc,
                              first_name: e.target.value,
                            },
                          })
                        }}
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
              )}

              {!memberAdding && (
                <ModalInputWrapper>
                  <ModalInputLabel>Last Name</ModalInputLabel>
                  <Field name="talent_poc.last_name">
                    {(props: FieldProps) => (
                      <TextInputField
                        {...props.field}
                        value={jobData.talent_poc?.last_name}
                        className="w-full"
                        placeholder="Last Name"
                        error={props.meta.touched ? props.meta.error : undefined}
                        data-testid="job-modal-talent-poc-last-name"
                        onChange={(e) => {
                          props.form.setFieldValue('talent_poc.last_name', e.target.value)
                          setJobData({
                            ...jobData,
                            talent_poc: {
                              ...jobData.talent_poc,
                              last_name: e.target.value,
                            },
                          })
                        }}
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
              )}

              {!memberAdding && (
                <ModalInputWrapper>
                  <ModalInputLabel>LinkedIn Profile URL</ModalInputLabel>
                  <Field name="talent_poc.linkedin_url">
                    {(props: FieldProps) => (
                      <TextInputField
                        {...props.field}
                        value={jobData.talent_poc?.linkedin_url}
                        className="w-full"
                        placeholder="LinkedIn Profile URL"
                        error={props.meta.touched ? props.meta.error : undefined}
                        data-testid="job-modal-talent-poc-linkedin-url"
                        onChange={(e) => {
                          props.form.setFieldValue('talent_poc.linkedin_url', e.target.value)
                          setJobData({
                            ...jobData,
                            talent_poc: {
                              ...jobData.talent_poc,
                              linkedin_url: e.target.value,
                            },
                          })
                        }}
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
              )}
            </ModalSectionWrapper>

            <div
              className={cn('flex justify-between items-center mt-7 mb-6', {
                'justify-end': !jobData,
              })}
            >
              {!!jobData && editing && (
                <CabalButton
                  variant="tertiary"
                  disabled={isDeleting}
                  padding="0"
                  onClick={(e) => {
                    e.stopPropagation()
                    const message = 'Are you sure you want to delete this job?'
                    if (window.confirm(message)) {
                      deleteJobMutation()
                    } else {
                      e.preventDefault()
                    }
                  }}
                >
                  Delete job
                </CabalButton>
              )}

              <CabalButton type="submit" working={updateJobMutation.isLoading}>
                Save
              </CabalButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default JobInfo
