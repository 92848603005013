import React from 'react'

import { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import { Divider } from 'components/SendMessage/styles'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam, useTeams } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { AutoCompleteCompany } from 'utils/types'

interface Props {
  company: AutoCompleteCompany
  onContinue: () => void
  onSkipTo: (s: number) => void
  selectSlug: (slug: string) => void
  index: number
  hideDivider?: boolean
}

const CompanyRow: React.FC<Props> = ({
  company,
  onContinue,
  onSkipTo,
  selectSlug,
  index,
  hideDivider = false,
}) => {
  const { reloadUser, user } = useCurrentUser()
  const { isAdmin, isAdvisor } = useAccessControl(company.team_slug || undefined)

  const { reloadTeams } = useTeams()

  const createTeamMutation = useMutation(
    () =>
      callApi(api.createTeam, company).then(() => {
        reloadUser()
        reloadTeams()
      }),
    {
      onSuccess: () => onContinue(),
      onError: (e: AxiosError) => {
        cabalToast({ style: 'error', content: e.response?.data?.message })
      },
    },
  )

  const joinRequestMutation = useMutation(() => callApi(api.requestToJoin, company!.team_slug!), {
    onSuccess: () => {
      cabalToast({
        style: 'success',
        content: `Your request has been sent to ${company.name}. You will be notified via email when your request is approved.`,
      })
    },
    onError: (e: AxiosError) => {
      cabalToast({ style: 'error', content: e.response?.data?.message })
    },
  })
  const { team } = useTeam(company?.team_slug)
  const requested = !!joinRequestMutation.data || team?.join_status === 'requested'

  const createTeam = async () => {
    if (!company) return

    if (company.has_access) {
      selectSlug(company.team_slug!)
      if (isAdmin || team?.allow_employees_to_import) {
        onSkipTo(3)
      } else if (isAdvisor) {
        onSkipTo(5)
      } else {
        onSkipTo(4)
      }
    } else {
      createTeamMutation.mutateAsync()
    }
  }

  let note: React.ReactNode = undefined

  let continueButton = (
    <CabalButton
      disabled={createTeamMutation.isLoading}
      onClick={createTeam}
      data-testid={`create-team-button-${index}`}
    >
      {index === 0 ? 'Continue' : 'View'}
    </CabalButton>
  )

  const hasAccess = () => {
    if (window.QA || company?.has_access) return true

    if (!company || !company.domain || company.domain === '') {
      return false
    }

    const userEmailDomain = user.email.split('@')[1]
    const domain = company.domain.toLowerCase().match(/^(?:https?:\/\/)?([\w-.]+)(\/.*)?$/)?.[1]

    if (
      (!!userEmailDomain && !!domain && domain === userEmailDomain) ||
      domain?.endsWith('.' + userEmailDomain)
    ) {
      return true
    } else {
      return false
    }
  }

  if (company && company.domain != '' && !hasAccess()) {
    continueButton = (
      <CabalButton onClick={() => (window.location.href = '/logout')}>Login</CabalButton>
    )

    note = (
      <span className="flex items-center flex-col">
        <Typography fontSize="12" color="fog" lineHeight={1} component="p" className="text-center">
          No existing workspace for {company.name}.
        </Typography>
        <Typography fontSize="12" color="fog" lineHeight={1} component="p" className="text-center">
          <Typography
            component="button"
            color="purple"
            onClick={() => (window.location.href = '/logout')}
          >
            Login with a {company.domain} email
          </Typography>{' '}
          to create the {company.name} workspace.
        </Typography>
      </span>
    )
  }

  // team exists and user should have access
  if (!!company?.team_slug && !hasAccess()) {
    continueButton = (
      <CabalButton
        onClick={() => joinRequestMutation.mutate()}
        working={joinRequestMutation.isLoading}
        variant={requested ? 'secondary' : 'primary'}
        disabled={requested}
      >
        {requested ? 'Requested' : `Request to join`}
      </CabalButton>
    )

    note = (
      <span className="flex items-center flex-col">
        <Typography
          fontSize="12"
          color="fog"
          lineHeight={1.2}
          component="p"
          className="text-center"
        >
          {user.email} is not an approved member of {company.name}.
        </Typography>
        <Typography
          fontSize="12"
          color="fog"
          lineHeight={1.2}
          component="p"
          className="text-center mt-1"
        >
          <Typography
            component={!requested ? 'button' : 'span'}
            color={!requested ? 'purple' : 'fog'}
            onClick={() => !requested && joinRequestMutation.mutate()}
          >
            Request to join
          </Typography>{' '}
          or{' '}
          <Typography
            component="button"
            color="purple"
            onClick={() => (window.location.href = '/logout')}
          >
            login with a different email.
          </Typography>
        </Typography>
      </span>
    )
  }

  return (
    <div className="flex flex-col mt-2">
      <div className="flex  justify-between pb-2 w-full p-2">
        <div className="flex items-center gap-2">
          <Typography className="flex-shrink-0">
            <Avatar src={company.logo} name={company.name} />
          </Typography>
          <Typography fontSize="14" component="p" className="line-clamp-1">
            {company.name}
          </Typography>
        </div>
        <div className="flex">{continueButton}</div>
      </div>
      {note && <div className="mt-2 mb-4 mx-auto">{note}</div>}

      {/* {!hideDivider && (
        <div className="-my-4">
          <Divider />
        </div>
      )} */}
    </div>
  )
}

export default CompanyRow
