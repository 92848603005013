import React from 'react'

import styled from 'styled-components'

import { UploadButton } from 'global/Input'
import { useCurrentUser } from 'store/hooks'

const Border = styled.div`
  border: ${({ theme }) => theme.border};
`

const Signature = () => {
  const { user, reloadUser } = useCurrentUser()

  return (
    <div className="flex justify-between gap-2">
      {user.signature && (
        <Border className="flex items-center justify-center py-2 w-full rounded">
          <img
            width={'200px'}
            height={'100px'}
            src={`/api/uploads/${user.signature}`}
            alt="Users signature"
          />
        </Border>
      )}
      <UploadButton
        canDrawSignature
        canUpload={false}
        onUpload={() => reloadUser()}
        attachTo={'user_signature'}
        showPencil={true}
      />
    </div>
  )
}

export default Signature
