import React from 'react'

import { getFormattedStage } from 'containers/CompanyPage/SharedListsSection/PortfolioDataTags'
import Pill from 'global/Pill'
import Typography from 'global/Typography'
import PillV2 from 'ui-components/PillV2'
import Widget from 'ui-components/Widget'

import { InvestorCompany } from 'utils/types/investor'

interface Props {
  company: InvestorCompany
}

const AboutSection: React.FC<Props> = ({ company }) => {
  return (
    <Widget title="About">
      <div>
        <p className="leading-5 mb-2">
          <Typography fontSize="12" color="fog" lineHeight={1.2}>
            {company.description}
          </Typography>
        </p>
      </div>

      <div className="flex flex-col">
        <div className="flex gap-6 mt-2">
          {/* {company.location && ( */}
          <div>
            <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
              Location
            </Typography>

            <Typography fontSize="12" component="div" color="fog" className="flex gap-1 flex-wrap">
              <Pill variant="gray" rounded>
                {company.location || '-'}
              </Pill>
            </Typography>
          </div>
          {/* )} */}
          {/* {company.stage && ( */}
          <div>
            <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
              Stage
            </Typography>

            <Typography fontSize="12" component="div" color="fog" className="flex gap-1 flex-wrap">
              <Pill variant="gray" rounded>
                {company.stage ? getFormattedStage(company.stage) : '-'}
              </Pill>
            </Typography>
          </div>
          {/* )} */}
          <div>
            <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
              Employee Count
            </Typography>

            <Typography fontSize="12" component="div" color="fog" className="flex gap-1 flex-wrap">
              <Pill variant="gray" rounded>
                {company.employee_count || '-'}
              </Pill>
            </Typography>
          </div>
        </div>

        {company.tags.length > 0 && (
          <div className="mt-4">
            <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
              Tags
            </Typography>
            <div className="flex flex-wrap gap-1 pt-1">
              {company.tags.map((item) => (
                <PillV2 title={item} key={item} className="px-2 py-1.5" />
              ))}
            </div>
          </div>
        )}
      </div>
    </Widget>
  )
}

export default AboutSection
