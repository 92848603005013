import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

const FilterCardWrapper = styled.div<{ selected: boolean; tag: boolean }>`
  ${tw`rounded-lg cursor-pointer flex flex-col items-center justify-center py-3 px-8`}

  border: 2px solid transparent;
  padding: ${({ tag }) => (tag ? '8px 8px' : '16px 32px')};

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.purple};
  }
`
interface Props {
  type: 'category' | 'tag'
  title: string
  count: number
  onClick?: () => void
  selected: boolean
}

const FilterCard: React.FC<Props> = ({ title, count, onClick, selected, type }) => {
  return (
    <FilterCardWrapper onClick={onClick} selected={selected} tag={type === 'tag'}>
      <Typography
        color={selected ? 'white' : 'primary'}
        fontSize={type === 'tag' ? '12' : '14'}
        fontWeight={selected || type === 'category' ? 600 : 400}
      >
        {title}
      </Typography>

      {/* {type === 'category' && (
        <Typography fontSize="12" color={selected ? 'white' : 'fog'}>
          {count} items
        </Typography>
      )} */}
    </FilterCardWrapper>
  )
}

export default FilterCard
