import React from 'react'

import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { ComposeType, useComposer } from 'components/Composer'
import { useAccessControl } from 'global/AccessControl'
import { DropdownMenuItem } from 'global/DropDownMenu'
import { TextInput } from 'global/Input'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { DataCard } from 'ui-components/DataCard'

import api, { callApi } from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'
import { Offer, OfferButton } from 'utils/types'

import { getComposeParams } from './ResourceViewPage/ResourceView'

interface Props {
  offer: Offer
  teamSlug: string
  onEdit: (offer: Offer) => void
  offerTypeSlug: string
}

export const getSecondaryCTA = (button: OfferButton, compose: ComposeType) => {
  return 'email' in button
    ? {
        text: button.label,
        onClick: () => compose(getComposeParams(button)),
        variant: 'link',
      }
    : { text: button.label, link: button.url, variant: 'link' }
}

export const renderDescription = (desc: React.ReactNode[]) => {
  if (desc.length === 1) {
    return (
      <Typography fontSize="12" key={desc[0]}>
        <i className="fas fa-tag mr-1"></i>
        {desc[0]}
      </Typography>
    )
  } else {
    const tooltipContent = (
      <>
        <Typography fontSize="12" className="m-1">
          {desc.slice(1).join(', ')}
        </Typography>
      </>
    )
    return (
      <Tooltip label={tooltipContent}>
        <Typography fontSize="12" key={desc[0]} className="cursor-pointer">
          <i className="fas fa-tag mr-1"></i>
          {desc[0]} +{desc.slice(1).length}
        </Typography>
      </Tooltip>
    )
  }
}

const OfferCard: React.FC<Props> = ({ offer, teamSlug, onEdit, offerTypeSlug }) => {
  const { canEditOffers } = useAccessControl(teamSlug)
  const { isMobile } = useColorMode()
  const { compose } = useComposer(teamSlug)

  const histroy = useHistory()

  const queryCache = useQueryClient()

  const { mutate: deleteOffer, isLoading: isDeleting } = useMutation(
    ['deleteOffer', offer.uuid],
    () => callApi(api.deleteOffer, teamSlug, offer.uuid),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['offers', teamSlug])
      },
    },
  )

  const { mutate: pinOffer } = useMutation(
    ['pinOffer', offer.uuid],
    () => callApi(api.markOfferAsPinned, teamSlug, offer.uuid),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['offers', teamSlug])
      },
    },
  )

  const { mutate: unpinOffer } = useMutation(
    ['pinOffer', offer.uuid],
    () => callApi(api.markOfferAsUnpinned, teamSlug, offer.uuid),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['offers', teamSlug])
      },
    },
  )

  const items = [
    {
      label: offer.pinned ? 'Unpin resource' : 'Pin resource',
      onSelect: () => (offer.pinned ? unpinOffer() : pinOffer()),
    },
    {
      label: 'Edit resource',
      onSelect: () => onEdit(offer),
    },
    {
      label: 'Delete resource',
      onSelect: () => deleteOffer(),
    },
  ]

  const menuText = `View offers (${offer.buttons.length})`
  const menuOptions: DropdownMenuItem[] = []

  if (offer.buttons.length > 1) {
    offer.buttons.forEach((b) =>
      menuOptions.push({
        label: b.label,
        onSelect:
          'email' in b ? () => compose(getComposeParams(b)) : () => window.open(b.url, '_blank'),
      }),
    )
  }

  const getDescription = () => {
    let description: any[] = []

    if (offer.pinned) {
      description.push('Pinned')
    }

    if (offer.tags.length > 0) {
      const tags = renderDescription(offer.tags)
      description.push(tags)
    }

    return description
  }

  return (
    <div className="mt-1.5">
      <DataCard
        title={offer.title}
        description={getDescription()}
        menuItems={canEditOffers ? items : undefined}
        avatarUrl={offer.upload_uuid ? `/api/uploads/${offer.upload_uuid}` : undefined}
        onCardClick={() =>
          histroy.push({
            pathname: `/${teamSlug}/resources/${offerTypeSlug}/${offer.uuid}`,
            state: { from: 'resources' },
          })
        }
        secondaryMultiselect={
          offer.buttons.length > 1 ? { text: menuText, menu: menuOptions } : undefined
        }
        secondaryCTA={
          offer.buttons.length === 1 ? getSecondaryCTA(offer.buttons[0], compose) : undefined
        }
        info={
          offer.website && offer.offer_type === 'people' ? (
            <div className="flex items-center">
              <Typography
                component="a"
                href={offer.website}
                rel="noreferrer"
                target="_blank"
                fontSize="12"
                color={'purple'}
              >
                <i className="fa-brands fa-linkedin pr-1"></i>
              </Typography>
            </div>
          ) : null
        }
        placeholderIcon={
          offer.offer_type === 'people' ? (
            <i className="fas fa-user"></i>
          ) : offer.offer_type === 'deals' ? (
            <i className="fas fa-briefcase"></i>
          ) : offer.offer_type === 'resources' ? (
            <i className="fas fa-pen"></i>
          ) : null
        }
      />
    </div>
  )
}

export default OfferCard
