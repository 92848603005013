import React from 'react'
import { useParams } from 'react-router-dom'
import { CompanySlugParam } from 'utils/types'
import axios from 'axios'
import Loading from 'global/Loading'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import styled from 'styled-components'
import { debounce } from 'lodash'
import Modal from 'global/Modal'
import { TextInput } from 'global/Input'
import CabalButton from 'global/CabalButton'
import EmailCapture from './EmailCapture'
import CabalTitle from 'global/Title'

const PDFDocumentWrapper = styled.div`
  canvas {
    margin: auto;
    max-width: 100% !important;
    width: 80% !important;
    height: auto !important;
  }
  /* canvas {
    max-width: 100% !important;
    max-height: 100% !important;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
  } */
`

const ViewDocument = () => {
  const { id, company_slug: companySlug } = useParams<{ id: string } & CompanySlugParam>()

  const [documentVisitUuid, setDocumentVisitUuid] = React.useState(null)
  const [document, setDocument] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [pdfData, setPdfData] = React.useState({})

  React.useEffect(() => {
    loadDocument()
  }, [id])

  function loadDocument() {
    setLoading(true)
    const params = {}

    axios.get(`/api/document_links/${id}`, params).then((response) => {
      const { document, document_visit_uuid } = response.data
      setDocumentVisitUuid(document_visit_uuid)
      setDocument(document)
      setLoading(false)
    })
  }

  React.useEffect(async () => {
    if (document?.url) {
      const pdf = await pdfjs.getDocument(document.url).promise
      const data = await pdf.getData()
      setPdfData({ pdf, data })
    }
  }, [document?.slug])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <CabalTitle title="View Document" />
      <EmailCapture
        document={document}
        documentVisitUuid={documentVisitUuid}
        setDocumentVisitUuid={setDocumentVisitUuid}
      />

      <div className="flex flex-col w-full">
        <div
          style={{ height: 35, fontSize: 12 }}
          className="bg-primary border-b border-light dark:border-dark"
        >
          <div className="p-2">{document?.name}</div>
        </div>
        {pdfData.data && (
          <div style={{ height: '90vh' }}>
            <PDFDocumentWrapper onContextMenu={(e) => e.preventDefault()}>
              <Document file={{ data: pdfData.data }} className="relative">
                {Array.from(new Array(pdfData.pdf.numPages), (el, index) => (
                  <div key={`page_${index + 1}`}>
                    <Page
                      pageNumber={index + 1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      className="mt-2 pb-2"
                    />
                    <hr className="absolute border-light dark:border-dark w-full z-10" />
                  </div>
                ))}
                {/* <Page
                  pageNumber={1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  height={height - 40}
                  className="margin-auto"
                /> */}
              </Document>
            </PDFDocumentWrapper>
          </div>
        )}
        {/* <div style={{ height: 20, background: 'green' }}>
          Cabal
        </div> */}
      </div>
    </>
  )
}

export default ViewDocument
