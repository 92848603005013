import React from 'react'
import Typography from 'global/Typography'
import { PendingInvite, Redemption, ReferralModel } from 'utils/types'
import api from 'utils/api'

import SendInvites from './SendInvites'
import InviteStatus from './InviteStatus'
import { useAppDispatch, useCurrentUserSettings } from 'store/hooks'
import { setCurrentUserSettings } from 'store/reducers/currentUserReducer'
import CabalTitle from 'global/Title'
import PageWrapper from 'global/PageWrapper'

const Referral = () => {
  const [referralCode, setReferralCode] = React.useState<ReferralModel>()
  const [redemptions, setRedemptions] = React.useState<Redemption[]>()
  const [pendingInvites, setPendingInvites] = React.useState<PendingInvite[]>()
  const {
    settings: { seen_refer_page },
  } = useCurrentUserSettings()
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    loadData()
  }, [])

  if (!seen_refer_page) {
    dispatch(
      setCurrentUserSettings({
        seen_refer_page: true,
      }),
    )
    api.updateUserSetting('seen_refer_page', true)
  }

  function loadData() {
    api.getReferralCodes().then((response) => {
      const { referral_codes, redemptions, pending_invites } = response.data
      setReferralCode(referral_codes[0])
      setRedemptions(redemptions)
      setPendingInvites(pending_invites)
    })
  }

  return (
    <PageWrapper title="Refer friends">
      <div className="flex items-center pl-6 pt-3">
        <Typography fontSize="28" color="purple">
          <i className="far fa-gift"></i>
        </Typography>
        <div className="pl-4">
          <Typography fontSize="20" fontWeight={600} component="h1" lineHeight="1.2">
            Refer founders
          </Typography>
          <Typography fontSize="14" color="fog_rain" component="p" lineHeight="1.2">
            Give your friends early access to Cabal
          </Typography>
        </div>
      </div>
      {referralCode && <SendInvites referralCode={referralCode} reloadData={() => loadData()} />}
      {redemptions && pendingInvites && (
        <InviteStatus redemptions={redemptions} pendingInvites={pendingInvites} />
      )}
    </PageWrapper>
  )
}

export default Referral
