import React from 'react'

import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import { Label, MultiSelect, Select } from 'global/Input'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'

import {
  AdvisorModel,
  AgreementTemplateField,
  AgreementTemplateModel,
  EquityTierEntity,
  Params,
} from 'utils/types'

import { SendAgreementsState } from '.'
import Field from './Field'
import TierPills from './TierPills'

const Container = styled.div`
  ${tw`
    m-4
    p-4
    rounded-lg
    dark:border-light
    light:border-light
  `}

  background-color: ${({ theme }) => theme.colors.primary_bg};
`

interface Props {
  agreementTemplate?: AgreementTemplateModel
  setState: (state: Partial<SendAgreementsState>) => void
  teamSlug: string
  agreementParams: Params
  senderUuid: string
  onChangeTemplate: (t: AgreementTemplateModel) => void
  allAdvisors: AdvisorModel[]
  advisors: AdvisorModel[]
  showAdvisorSelector: boolean
  agreementTemplates: AgreementTemplateModel[]
  agreementFields: AgreementTemplateField[]
}

const AgreementFields: React.VFC<Props> = ({
  agreementTemplate,
  teamSlug,
  agreementParams,
  setState,
  senderUuid,
  onChangeTemplate,
  allAdvisors,
  advisors,
  showAdvisorSelector,
  agreementTemplates,
  agreementFields,
}) => {
  const { team } = useTeam(teamSlug)
  const { user } = useCurrentUser()
  const location = useLocation<Params>()
  const history = useHistory()

  const canSave = agreementFields?.every((f) => !!agreementParams[f.uuid])

  const setAgreementParams = (field_uuid: string, v: any) => {
    setState({
      agreementParams: {
        ...agreementParams,
        [field_uuid]: v,
      },
    })
  }

  const nextStep = () => {
    history.push(location.pathname, {
      ...location.state,
      stage: 1,
    })
  }

  const hasEquityGrant = agreementFields?.some(
    (f) => f.default_value === 'agreement.granted_shares',
  )

  const onTierSelect = (tier: EquityTierEntity) => {
    const updates: any = { ...agreementParams }

    const grantedSharesId = agreementFields?.find((f) =>
      f.default_value?.includes('granted_shares'),
    )?.uuid
    const vestingPeriodId = agreementFields?.find((f) =>
      f.default_value?.includes('vesting_period'),
    )?.uuid

    if (grantedSharesId) {
      updates[grantedSharesId] = tier.shares
    }

    if (vestingPeriodId) {
      updates[vestingPeriodId] = tier.vesting_period
    }

    setState({ agreementParams: updates })
  }

  const advisorCount = advisors.length

  return (
    <Container>
      <Typography fontWeight={600} fontSize="16">
        Send Agreement{' '}
        {!!advisorCount &&
          `to ${advisorCount === 1 ? advisors[0].name : advisorCount + ' members'}`}
      </Typography>
      {showAdvisorSelector && (
        <Label className="mt-4" label="Member">
          <MultiSelect<AdvisorModel>
            data-testid="select-agreement-member"
            options={
              allAdvisors?.map((a) => ({
                label: `${a.name} (${a.email})`,
                value: a,
              })) || []
            }
            value={advisors}
            portal
            onChange={(v) => setState({ advisors: v })}
          />
        </Label>
      )}
      <div className="pt-4">
        <Select<AgreementTemplateModel>
          data-testid="select-agreement-template"
          options={
            agreementTemplates?.map((at) => ({
              label: at.name,
              value: at,
            })) || []
          }
          value={agreementTemplate}
          portal
          onChange={(v) => v && onChangeTemplate(v)}
          placeholder="Select Agreement Type..."
        />
      </div>
      {user.enable_agreement_on_behalf && (
        <div className="mt-4">
          <Label label="Sender">
            <Select<string>
              data-testid="select-agreement-sender"
              options={
                team?.admins_and_members
                  .filter((f) => f.team_role === 'admin')
                  .map((f) => ({
                    label: `${f.name} (${f.email})`,
                    value: f.uuid,
                  })) || []
              }
              value={senderUuid}
              portal
              onChange={(v) => {
                v && setState({ senderUuid: v })
              }}
            />
          </Label>
        </div>
      )}
      {hasEquityGrant && <TierPills teamSlug={team!.slug} onSelect={onTierSelect} />}
      <div className="mt-4">
        {agreementFields?.map((atf) => (
          <div className="mb-base" key={atf.uuid}>
            <Field field={atf} value={agreementParams[atf.uuid]} setValue={setAgreementParams} />
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <CabalButton
          onClick={nextStep}
          disabled={!canSave}
          data-testid="agreement-field-continue-button"
        >
          Continue
        </CabalButton>
      </div>
    </Container>
  )
}

export default AgreementFields
