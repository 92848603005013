import styled from 'styled-components'
import tw from 'twin.macro'

const Card = styled.div`
  ${tw`mt-12 p-2 sm:p-4 mx-1 rounded-xl sm:w-[520px] sm:mx-auto`}
  background: ${({ theme }) => theme.colors.cardBackground};
  box-shadow: 0px 6px 18px -17px rgba(92, 105, 209, 0.75);
  border: 1px solid ${({ theme }) => theme.colors.widget_border};
`

const InputWrapper = styled.div`
  ${tw`m-2 mb-4 sm:m-6 flex flex-col sm:flex-row justify-between sm:items-center`}
`

const ContinueWrapper = styled.div`
  ${tw`pt-8 pb-2 flex items-center justify-center w-full`}
`

export { Card, InputWrapper, ContinueWrapper }
