import React from 'react'

import { EmailSnippet } from 'utils/types'
import CKEditor from 'global/TextEditor/ckeditor'
import { CheckBox, TextInput } from 'global/Input'
import VariablesList from 'containers/MessagesPage/Templates/VariablesList'

interface Props {
  disabled: boolean
  values: Partial<EmailSnippet>
  hideSidebar?: boolean
  showRequests?: boolean
  onChange: (values: Partial<EmailSnippet>) => void
}

const SnippetsForm: React.VFC<Props> = ({ disabled, values, onChange }) => {
  return (
    <div className="flex">
      <div className="w-3/4">
        <TextInput
          className="mb-4 w-full"
          label="Snippet Name"
          disabled={disabled}
          value={values.name}
          onChange={(e) => onChange({ name: e.target.value })}
          placeholder="Name of snippet"
          autoFocus
        />

        <div className="mb-2 mt-2">
          <CKEditor
            value={values.body}
            onChange={(value) => onChange({ body: value })}
            height={200}
          />
        </div>

        <CheckBox
          className="mt-4"
          label="Make public to team"
          disabled={disabled}
          type="checkbox"
          checked={values.public_to_team}
          onChange={(e) => onChange({ public_to_team: e.target.checked })}
        />
      </div>

      <div className="w-1/4 pl-8 text-sm">
        <VariablesList />
      </div>
    </div>
  )
}

export default SnippetsForm
