import React, { useState } from 'react'

import cx from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useAccessControl } from 'global/AccessControl'
import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import CabalTitle from 'global/Title'
import Typography from 'global/Typography'
import { useGroups, useTeam, useTeamSlug } from 'store/hooks'

import { ImportedMember } from 'utils/types'

import Preview from './Preview'
import Upload from './Upload'

const Container = styled.div`
  ${tw`my-8`}
`

interface Props {
  companySlug?: string
  heading?: string
  canSkip?: boolean
  destination?: string
}

const ImportMembers: React.VFC<Props> = ({
  companySlug: _companySlug,
  heading,
  canSkip = false,
  destination,
}) => {
  const history = useHistory<number>()
  const location = useLocation()
  const importStage = history.location.state || 0

  const [members, setMembers] = useState<ImportedMember[]>([])
  const [importedColumns, setImportedColumns] = useState<string[]>([])
  const [advisorImportUuid, setAdvisorImportUuid] = useState<string>()
  const [invitePostImport, setInvitePostImport] = useState(false)

  const teamSlug = (useTeamSlug() || _companySlug)!
  const { canAddAdvisor } = useAccessControl(teamSlug)
  const { team, isLoading } = useTeam(teamSlug)
  const { groups } = useGroups(teamSlug)

  if (isLoading) {
    return (
      <div className="my-10">
        <Loading />
      </div>
    )
  }

  if (team && !canAddAdvisor)
    return (
      <Typography className="text-center p-5" component="div">
        You don{`'`}t have permission to view this page
      </Typography>
    )
  if (!team || !groups) return <></>

  const uploadData = (
    <Upload
      setImportedColumns={setImportedColumns}
      setMembers={(m) => {
        setMembers(m)
        if (importStage !== 1) history.push(location.pathname, 1)
      }}
      setInvitePostImport={setInvitePostImport}
      invitePostImport={invitePostImport}
      teamSlug={team.slug}
      heading={heading}
      advisorImportUuid={advisorImportUuid}
      setAdvisorImportUuid={setAdvisorImportUuid}
      canSkip={canSkip}
    />
  )

  const preview = (
    <Preview
      importedColumns={importedColumns}
      companySlug={teamSlug}
      members={members}
      groups={groups}
      destination={destination}
      teamSlug={team.slug}
      advisorImportUuid={advisorImportUuid!}
      invitePostImport={invitePostImport}
    />
  )

  return (
    <PageWrapper
      crumbs={[
        { title: 'Members', url: `/${teamSlug}/members` },
        { title: 'Import', url: `/${teamSlug}/import` },
      ]}
      title="Import Members"
    >
      <div className={cx('px-4', { 'mx-6': importStage !== 0 })}>
        <CabalTitle title="Add Members" />
        <Container className={cx(importStage !== 0 ? 'max-w-none' : 'max-w-2xl mx-auto')}>
          {importStage === 0 && uploadData}
          {importStage !== 0 && preview}
        </Container>
      </div>
    </PageWrapper>
  )
}

export default ImportMembers
