import React from 'react'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'

import { AdvisorModel } from 'utils/types'

interface IProps {
  advisor: AdvisorModel
  onSelect: () => void
}

const AdvisorRow: React.FC<IProps> = ({ advisor, onSelect }) => {
  const handleCardClick = () => {
    onSelect()
  }

  return (
    <DataCardV2
      onClick={handleCardClick}
      title={`${advisor.first_name} ${advisor.last_name}`}
      avatar={<Avatar name="person" src={advisor.avatar_url} size="32" />}
      description={
        <Typography
          color="rain_fog"
          fontSize="12"
          lineHeight={1}
          className="flex items-center gap-1.5"
        >
          {advisor.title} {advisor.company_name && <span>at {advisor.company_name}</span>}
        </Typography>
      }
      cta={
        <div className="flex items-center justify-end">
          <CabalButton variant="tertiary" onClick={handleCardClick} color="purple" padding="0">
            <Typography component="button" fontSize="12" color="purple">
              Select <i className="far fa-chevron-right ml-1" />
            </Typography>
          </CabalButton>
        </div>
      }
    />
  )
}

export default AdvisorRow
