import React from 'react'

import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

import JobsDataTags from 'containers/CompanyPage/SharedListsSection/JobsDataTags'
import { useJobsListContext } from 'containers/ListIndexView/TalentList/JobsList'
import JobsEditOrAddModal from 'containers/ListIndexView/TalentList/JobsList/JobsEditOrAddModal'
import { IJob } from 'containers/ListIndexView/TalentList/JobsList/types'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import GridWrapper from 'ui-components/GridWrapper'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { cn } from 'utils/styles'
import { CompanyListItemBlueprint } from 'utils/types'

const JobItem = ({
  listItem,
  view,
  selectedJobs,
  setSelectedJobs,
}: {
  listItem: CompanyListItemBlueprint
  view: 'grid' | 'list'
  selectedJobs: IJob[]
  setSelectedJobs: (selectedJobs: IJob[]) => void
}) => {
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()
  const history = useHistory()
  const job = listItem.job
  const queryClient = useQueryClient()
  const { list_id } = useParams<{ list_id: string }>()

  const { companyListData, refetchListItems, refetchCompanyList } = useJobsListContext()

  const selected = !!selectedJobs.find((c) => c.uuid === job.uuid)

  const handleSelection = (company: IJob, selected: boolean) => {
    if (selected) {
      setSelectedJobs([...selectedJobs, company])
    } else {
      setSelectedJobs(selectedJobs.filter((a) => a.uuid !== company.uuid))
    }
  }

  const { mutate: deleteJob, isLoading: isDeleting } = useMutation(
    ['deleteJob', job.uuid],
    () => callApi(api.deleteJob, job.uuid!, teamSlug, companyListData.uuid),
    {
      onSuccess: () => {
        refetchListItems()
        refetchCompanyList()
        queryClient.invalidateQueries(['jobsForAdd', companyListData.uuid])
        cabalToast({ style: 'success', content: 'Job successfully deleted' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to delete job' })
      },
    },
  )

  const handleDelete = () => {
    const message =
      list_id === 'all-jobs'
        ? 'Are you sure you want to delete this job?\nWarning: this job will also be deleted from any lists where it appears.'
        : 'Are you sure you want to remove this job from the list?'
    if (window.confirm(message)) {
      deleteJob()
    }
  }

  const { mutate: bulkAction } = useMutation(
    (params: any) =>
      callApi(api.jobsBulkActions, companyListData.uuid, {
        selected_jobs_uuids: [job.uuid],
        bulk_action: params.action,
        kind: params.kind,
      }),
    {
      onSuccess: (_, params) => {
        refetchListItems()
        queryClient.invalidateQueries(['jobsForAdd', companyListData.uuid])
        refetchCompanyList()
        cabalToast({ style: 'success', content: `Successfully ${params.action}d!` })
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content: 'Something went wrong, please try again or contact support',
        })
      },
    },
  )

  const menuItems = [
    {
      label: 'Edit',
      onSelect: () =>
        showModal(
          (resolve) => (
            <JobsEditOrAddModal
              onHide={resolve}
              jobUuid={job.uuid!}
              teamSlug={teamSlug}
              refetchListItems={refetchListItems}
              listUuids={job.list_uuids}
              currentListUuid={companyListData.uuid}
              company={job.investor_company}
              showListDropdown={list_id == 'all-jobs'}
            />
          ),
          'edit-job-modal',
        ),
    },
    {
      label: 'Delete',
      onSelect: () => handleDelete(),
    },
    {
      label: job.archived ? 'Restore' : 'Archive',
      onSelect: () => bulkAction({ action: job.archived ? 'restore' : 'archive', kind: 'jobs' }),
    },
  ]

  const gridView = view === 'grid'

  return (
    <DataCardV2
      onClick={() => history.push(`/${teamSlug}/lists/${companyListData.slug}/${listItem.uuid}`)}
      key={job.uuid}
      title={job.title}
      avatar={
        <Avatar
          src={
            job.investor_company
              ? `https://logo.clearbit.com/${job.investor_company.domain}`
              : undefined
          }
          name={job.title}
          size={gridView ? '72' : '32'}
        />
      }
      checkbox={{
        checked: selected,
        onChange: (e) => {
          handleSelection(job, e.target.checked)
        },
      }}
      description={
        job.description &&
        new DOMParser().parseFromString(job.description, 'text/html').body.textContent
      }
      descriptionBottom={
        <div className="flex flex-col items-start gap-2.5 mb-3">
          {job?.investor_company?.company_name && (
            <Typography lineHeight={1}>
              <i className="far fa-building" /> {job?.investor_company?.company_name}
            </Typography>
          )}
          {job?.locations && job?.locations.length > 0 && (
            <Typography lineHeight={1}>
              <i className="far fa-location-dot" />{' '}
              {job?.locations.length === 1
                ? job.locations[0]
                : `${job.locations[0]} + ${job.locations.length - 1} more`}
            </Typography>
          )}
        </div>
      }
      dropDownMenu={menuItems.length > 0 ? { menuItems: menuItems } : undefined}
      isLoading={isDeleting}
      cta={
        <CabalButton
          onClick={() =>
            history.push(`/${teamSlug}/lists/${companyListData.slug}/${listItem.uuid}`)
          }
          variant={gridView ? 'primary' : 'link'}
          working={isDeleting}
          className="w-full"
        >
          {isDeleting ? 'Deleting' : 'View'}
        </CabalButton>
      }
      view={view}
      gridPillsSection={<JobsDataTags job={job} />}
      className={cn({
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded':
          !gridView,
      })}
    />
  )
}

interface Props {
  selectedJobs: IJob[]
  setSelectedJobs: (selectedJobs: IJob[]) => void
}

const JobsListAdminView: React.FC<Props> = ({ selectedJobs, setSelectedJobs }) => {
  const { listItems, view } = useJobsListContext()

  const jobRow = (view: 'grid' | 'list') => {
    return (
      <>
        {listItems.map((listItem) => (
          <JobItem
            key={listItem.job.uuid}
            listItem={listItem}
            view={view}
            selectedJobs={selectedJobs}
            setSelectedJobs={setSelectedJobs}
          />
        ))}
      </>
    )
  }

  return (
    <div>
      {view === 'grid' && <GridWrapper>{jobRow('grid')}</GridWrapper>}
      {view === 'list' && <>{jobRow('list')}</>}
    </div>
  )
}

export default JobsListAdminView
