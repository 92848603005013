import React, { useState } from 'react'

import moment from 'moment'
import styled from 'styled-components'

import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'

import EditContextModal from './EditContextModal'
import { CardContainer } from './styles'

const ContextWrap = styled.div`
  white-space: pre-wrap;
`
interface DefaultContextContainerProps {
  preview: boolean
  companySlug: string
  defaultPrompt?: string
  defaultId?: string
  canEdit?: boolean | undefined
}

export const DefaultContextContainer = ({
  companySlug,
  defaultPrompt,
  defaultId,
  canEdit = false,
}: DefaultContextContainerProps) => {
  const { team } = useTeam(companySlug)
  const [showModal, setShowModal] = useState(false)
  const asksContext =
    team?.sales_asks_prompt ||
    defaultPrompt ||
    `Welcome to our Cabal page. Here we’ll share updates and ways to help us win. \n\nTo start, share your connections then click “Offer intro” for contacts you’re willing to introduce. When you offer an intro, we will follow up with custom referral emails for your approval.`

  const prompt_user = team?.admins_and_members.find(
    (u) => u.uuid === team?.sales_ask_prompt_user_id,
  )

  const defaultUser = team?.admins_and_members.find((u) => u.uuid === defaultId)

  return (
    <div className="mb-4">
      {showModal && canEdit && <EditContextModal onHide={() => setShowModal(false)} />}

      {asksContext && (prompt_user || defaultUser) && (
        <div className="flex-1" style={{ border: 0 }}>
          <div className="flex items-top">
            <Avatar
              name={prompt_user?.first_name || defaultUser?.first_name}
              src={prompt_user?.avatar_url || defaultUser?.avatar_url}
              size="32"
              round
              className="mr-3 flex-shrink-0"
            />
            <div className="flex flex-col">
              <div>
                <Typography fontSize="14" fontWeight={600} className="mr-2">
                  {prompt_user?.first_name || defaultUser?.first_name}
                </Typography>
                {/* Doesn't update when you change the context */}
                {/* <Typography fontSize="10" color={'fog'}>
                  {team?.sales_asks_prompt_changed_at
                    ? moment(team?.sales_asks_prompt_changed_at).fromNow()
                    : 'Just now'}
                </Typography> */}
              </div>
              <Typography fontSize="14">
                <ContextWrap>{asksContext}</ContextWrap>
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DefaultContextContainer
