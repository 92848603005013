import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import React from 'react'
import { cabalToast } from 'ui-components/Toast'
import copyToClipboard from 'utils/copyToClipboard'
import { EmailTemplate } from 'utils/types'
import BuildTemplateLinkModal from './BuildTemplateLinkModal'

interface Props {
  template: EmailTemplate
  teamSlug: string
  showLabel?: boolean
}

const ShareTemplate: React.VFC<Props> = ({ template, teamSlug, showLabel = true }) => {
  const { showModal } = useModal()

  const openBuildModal = (e) => {
    e.stopPropagation()
    showModal(
      (resolve) => (
        <BuildTemplateLinkModal resolve={resolve} teamSlug={teamSlug} template={template} />
      ),
      'template-link-build-modal',
    )
  }

  return (
    <>
      <CabalButton
        variant="tertiary"
        size="small"
        tooltip="Copy template link"
        className="whitespace-nowrap"
        onClick={(e) => {
          e.stopPropagation()
          const url = new URL(window.location.href)
          url.pathname = `/${teamSlug}/compose`
          url.searchParams.set('template_uuid', template.uuid)
          copyToClipboard(url.href)
          cabalToast({
            style: 'success',
            content: 'Copied! Use the link to share a pre drafted message',
          })
        }}
        rightIcon={<i className="far fa-link" />}
      >
        {showLabel && 'Copy Link'}
      </CabalButton>
      {/* <CabalButton
        variant="tertiary"
        size="small"
        tooltip="Build template link"
        className="whitespace-nowrap"
        onClick={openBuildModal}
        rightIcon={<i className="far fa-tools" />}
      >
        {showLabel && 'Build Link'}
      </CabalButton> */}
    </>
  )
}

export default ShareTemplate
