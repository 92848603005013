import React, { useState } from 'react'

import CabalButton from 'global/CabalButton'
import { Select, TextArea } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import { useTeam } from 'store/hooks'

import { MutationFunc, Team } from 'utils/types'

interface Props {
  updateTeamMutation: MutationFunc<Partial<Team>>
  onHide: () => void
  teamSlug: string
}

const WelcomeEditModal = ({ updateTeamMutation, onHide, teamSlug }: Props) => {
  const { team, reloadTeam } = useTeam(teamSlug)
  const [salesAskPromptUserId, setSalesAskPromptUserId] = useState(team?.sales_ask_prompt_user_id)
  const [asksContext, setAsksContext] = useState(
    team?.sales_asks_prompt ||
      `Welcome to our Cabal page. Here we’ll share updates and ways to help us win. \n\nTo start, share your connections then click “Offer intro” for contacts you’re willing to introduce. When you offer an intro, we will follow up with custom referral emails for your approval.`,
  )

  const teamAdminOptions =
    team?.admins_and_members
      .filter((u) => u.team_role === 'admin')
      .map((u) => ({ value: u.uuid, label: u.name })) || []

  return (
    <Modal
      header="Edit Welcome Message"
      show={true}
      onHide={onHide}
      rightActions={
        <CabalButton
          variant="primary"
          disabled={asksContext === ''}
          working={
            updateTeamMutation.isLoading && !!updateTeamMutation.variables?.['sales_asks_prompt']
          }
          onClick={() =>
            updateTeamMutation
              .mutateAsync({
                sales_asks_prompt: asksContext,
                sales_ask_prompt_user_id: salesAskPromptUserId,
                sales_asks_prompt_changed_at: new Date(),
              })
              .then(() => {
                reloadTeam()
                onHide()
              })
          }
        >
          Save
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <div className="flex flex-col gap-4">
          <ModalInputWrapper>
            <ModalInputLabel>From</ModalInputLabel>
            <Select
              value={salesAskPromptUserId}
              className="flex-grow"
              onChange={(option) => option && setSalesAskPromptUserId(option)}
              placeholder="Select user"
              options={teamAdminOptions}
            />
          </ModalInputWrapper>

          <ModalInputWrapper>
            <ModalInputLabel>Message</ModalInputLabel>
            <TextArea
              className="pt-1 w-full"
              labeled
              value={asksContext}
              onChange={(e) => setAsksContext(e.currentTarget.value)}
              disabled={
                updateTeamMutation.isLoading &&
                !!updateTeamMutation.variables?.['sales_asks_prompt']
              }
              rows={6}
            />
          </ModalInputWrapper>
        </div>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default WelcomeEditModal
