import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

import useColorMode from 'utils/hooks/useColorMode'

import CabalImage from '../../images/CabalSubtract.svg'
import GtmFundImageFile from '../../images/GtmFund.svg'
import { StyledImage } from './styles'

const HeaderFooterText = styled(Typography)<{ hasBorder: boolean; fontSize: string }>`
  ${tw`mx-auto font-normal`}
  ${({ hasBorder }) => hasBorder && tw`border-2 border-white rounded-md p-2`}
  font-family: 'Inter';
  font-size: ${({ fontSize }) => fontSize};
  color: rgb(255, 255, 255);
  line-height: 'normal';
`

interface IProps {
  children: React.ReactNode
}

export function Layout({ children }: IProps) {
  const { isMobile } = useColorMode()
  const gtmLink = 'https://gtmnow.com/'
  const cabalLink = 'https://getcabal.com/'
  return (
    <div className="bg-[#10845B]/100 h-screen w-screen overflow-scroll pb-20 flex flex-col">
      <div className="flex flex-row items-center justify-between max-w-[990px] sm:mx-auto gap-4 sm:gap-0 mt-9">
        <HeaderFooterText fontSize={isMobile ? '12px' : '15px'}>
          WARM INTRO NAVIGATOR
        </HeaderFooterText>
      </div>
      <div className="mb-2 flex-grow">{children}</div>
      <div className="mt-auto" style={{ marginTop: 'min(60px, 80px)' }}>
        <div className="flex flex-col items-center justify-center gap-y-4">
          <div className="flex flex-col items-center">
            <Typography
              textAlign="center"
              fontSize={isMobile ? '12' : '14'}
              fontWeight={400}
              color="white"
            >
              We built this free site to help you get more sales referrals.
            </Typography>
          </div>
          <HeaderFooterText fontSize={isMobile ? '12px' : '15px'} style={{ paddingRight: '14px' }}>
            Love,
          </HeaderFooterText>
          <div className="flex flex-row justify-center items-center">
            <div className="flex justify-center sm:items-center ml-1 sm:ml-0">
              <a
                href={cabalLink}
                className="flex pr-4 flex-row justify-end"
                target="_blank"
                rel="noreferrer"
              >
                <StyledImage src={CabalImage} width={'98px'} height={'48px'} alt={'Cabal Logo'} />
              </a>
            </div>
            <div className="w-12" />
            <a
              href={gtmLink}
              className="flex pr-4 flex-row justify-end"
              target="_blank"
              rel="noreferrer"
            >
              <StyledImage
                src={GtmFundImageFile}
                width={'96px'}
                height={'20px'}
                alt={'Gtm Fund Logo'}
              />
            </a>
          </div>
          <div>
            <a style={{ color: '#2ab282', fontSize: 12 }} href="https://clearbit.com">
              Logos provided by Clearbit
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
