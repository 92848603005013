import React, { useEffect, useMemo, useState } from 'react'

import compact from 'lodash/compact'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useLocation, useSearchParam } from 'react-use'

import { useComposer } from 'components/Composer'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import { CrumbProps } from 'global/Breadcrumb'
import Tabs, { Tab } from 'global/Tabs'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam, useUniqueAccessLink } from 'store/hooks'

import api, { callApi } from 'utils/api'
import useTeamSetting from 'utils/hooks/useTeamSetting'
import { CompanySlugParam } from 'utils/types'
import { InvestorCompany, InvestorCompanyJob } from 'utils/types/investor'

import CompaniesTabV1 from './CompaniesTabV1'
import JobsTabV1 from './JobsTabV1'
import Subtabs from './Subtabs'

export const offerTypes = [
  { label: 'Deals', slug: 'deals' },
  { label: 'People', slug: 'people' },
  { label: 'Resources', slug: 'resources' },
]

const Portfolio: React.VFC = () => {
  const { company_slug } = useParams<CompanySlugParam>()
  const { canViewPortfolio, canEditPortfolio } = useAccessControl(company_slug)
  const {
    isLoggedIn,
    user: { uuid: userUuid },
  } = useCurrentUser()
  const { team } = useTeam(company_slug)
  const { compose } = useComposer(company_slug)
  const [contributions, setContributions] = useState({})
  const [companies, setCompanies] = useState<InvestorCompany[]>()
  const [jobs, setJobs] = useState<InvestorCompanyJob[]>([])
  const [allTags, setAllTags] = useState<string[]>([])
  const [allStages, setAllStages] = useState<string[]>([])
  const [introRequestData, setIntroRequestData] = useState()

  const { uuid: uniqueAccessLinkUuid } = useUniqueAccessLink()
  const [enablePortfolioJobs] = useTeamSetting(company_slug, 'enable_portfolio_jobs')

  const makeIntroduction = useSearchParam('make_introduction')
  const makeIntroductionToAdvisor = useSearchParam('make_introduction_to_advisor')
  const sendIntroduction = useSearchParam('send_introduction')
  const sendIntroductionToAdvisor = useSearchParam('send_introduction_to_advisor')
  const companyUuid = useSearchParam('company_uuid')
  const introRequestUuid = useSearchParam('intro_request_uuid')
  const advisorUuid = useSearchParam('advisor_uuid')
  const crumbs: CrumbProps[] = [{ title: 'Portfolio', url: `/${company_slug}/portfolio` }]

  const location = useLocation()
  const tag = location.pathname?.split('/').pop()

  const {
    isLoading,
    isFetching,
    refetch: refetchCompanies,
  } = useQuery(
    ['getInvestorCompanies', company_slug, 'portfolio'],
    () => callApi(api.getInvestorCompanies, company_slug),
    {
      onSuccess: ({ companies, tags, stages }) => {
        setCompanies(companies)
        setAllTags(tags)
        setAllStages([...new Set(['seed', 'early', 'growth', 'public'].concat(stages || []))])
        getPortfolioContributions()
      },
      enabled: canViewPortfolio,
    },
  )

  const { mutate: getPortfolioContributions, isLoading: isLoadingContributions } = useMutation(
    () => callApi(api.getPortfolioContributions, company_slug),
    {
      onSuccess: ({ contributions }) => {
        setContributions(contributions || {})
      },
    },
  )

  useQuery(
    ['getIntroRequest', introRequestUuid],
    () => callApi(api.getIntroRequest, introRequestUuid as string, companyUuid as string),
    {
      onSuccess: (data) => {
        setIntroRequestData(data)
      },
      enabled: !!introRequestUuid && !!companyUuid,
    },
  )

  useQuery(
    ['getAdvisorIntroRequest', introRequestUuid],
    () =>
      callApi(
        api.getAdvisorIntroRequest,
        introRequestUuid as string,
        advisorUuid as string,
        company_slug,
      ),
    {
      onSuccess: (data) => {
        setIntroRequestData(data)
      },
      enabled: !!introRequestUuid && !!advisorUuid,
    },
  )

  const { isLoading: isLoadingJobs, isFetching: isFetchingJobs } = useQuery(
    ['getAllInvestorCompanyJobs', company_slug],
    () => callApi(api.getAllInvestorCompanyJobs, company_slug),
    {
      onSuccess: ({ jobs }) => {
        setJobs(jobs || [])
      },
      enabled: canViewPortfolio,
    },
  )

  useEffect(() => {
    if (introRequestData) {
      const {
        candidate_email: candidateEmail,
        advisor_name: advisorName,
        advisor_email: advisorEmail,
        hiring_contact_name: hiringContactName,
        hiring_contact_email: hiringContactEmail,
        note,
        accept_url: acceptUrl,
        decline_url: declineUrl,
        talent_poc,
        accepted_or_declined_by_user: acceptedBy,
        send_introduction_template,
        make_intro_template,
      } = introRequestData

      const talentPOCEmail = talent_poc?.email

      if (makeIntroduction) {
        compose({
          subject: make_intro_template.subject,
          body: make_intro_template.body,
          recipients: { emails: [talentPOCEmail] },
        })
      }

      if (makeIntroductionToAdvisor) {
        const emailSentBy = `&email_sent_by=${userUuid}`
        compose({
          subject: `${hiringContactName} requested an intro to ${advisorName}`,
          body: `Hi ${advisorName},</br></br>
          ${hiringContactName} is interested in meeting with you.</br>
          Here's a quick note from ${hiringContactName}: <i>"${note}"</i></br></br>
          <a href="${acceptUrl}${emailSentBy}">Accept intro request</a><br>
          <a href="${declineUrl}${emailSentBy}">Not interested</a>`,
          recipients: { emails: [advisorEmail] },
        })
      }

      if (sendIntroduction) {
        compose({
          subject: send_introduction_template.subject,
          body: send_introduction_template.body,
          cc: { emails: [candidateEmail] },
          recipients: { emails: [acceptedBy.email] },
          onSubmit: () => {
            api.sendTalentActivity({
              team_slug: company_slug,
              name: 'introduce_candidate',
              intro_request_uuid: introRequestUuid,
              unique_access_link_uuid: uniqueAccessLinkUuid,
            })
          },
        })
      }

      if (sendIntroductionToAdvisor) {
        compose({
          subject: send_introduction_template.subject,
          body: send_introduction_template.body,
          recipients: { emails: [advisorEmail] },
          cc: { emails: [hiringContactEmail] },
          onSubmit: () => {
            api.sendTalentActivity({
              team_slug: company_slug,
              name: 'introduce_company_to_candidate',
              intro_request_uuid: introRequestUuid,
              unique_access_link_uuid: uniqueAccessLinkUuid,
            })
          },
        })
      }
    }
  }, [introRequestData])

  if (!canViewPortfolio) return <></>

  return (
    <>
      {!isLoggedIn && (
        <div className="flex mb-4 items-center">
          <Avatar src={team?.logo} name={team?.name} size="70px" />
          <Typography fontWeight={600} fontSize="24" className="ml-3" component="div">
            {team?.name}
          </Typography>
        </div>
      )}

      {tag == 'jobs' && (
        <JobsTabV1
          companies={companies || []}
          jobs={jobs}
          setJobs={setJobs}
          isLoading={isLoadingJobs}
          loadingCompanies={isLoading}
          isFetching={isFetchingJobs}
        />
      )}
      {tag != 'jobs' && (
        <CompaniesTabV1
          companies={companies || []}
          contributions={contributions}
          isLoadingContributions={isLoadingContributions}
          setCompanies={setCompanies}
          isLoading={isLoading}
          isFetching={isFetching}
          allTags={allTags || []}
          setAllTags={setAllTags}
          allStages={allStages}
          setAllStages={setAllStages}
          teamSlug={company_slug}
          canEditPortfolio={canEditPortfolio}
          jobs={jobs}
          setJobs={setJobs}
          isLoadingJobs={isLoadingJobs}
          refetchCompanies={refetchCompanies}
        />
      )}
    </>
  )
}

export default Portfolio
