import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { cabalToast } from 'ui-components/Toast'
import ErrorLogger from 'utils/ErrorLogger'

import { useCurrentUser, useTeam } from 'store/hooks'
import api, { callApi } from 'utils/api'
import { LimitedUser, CreateCollaboratorsRequest } from 'utils/types'

import Tooltip from 'global/Tooltip'
import { Face, Faces } from 'components/Faces'

import MessageCollaboratorsAdd from './MessageCollaboratorsAdd'
import { useHotkeys } from 'react-hotkeys-hook'
import { isEqual, omit } from 'lodash'

interface Props {
  teamSlug: string
  attachableType: 'Message' | 'MessageThread' | 'GmailThread' | 'InboxThread'
  attachableUuid: string
  size?: string
}

const Collaborators: React.VFC<Props> = ({
  teamSlug,
  attachableType,
  attachableUuid,
  size = '32',
}) => {
  const { user } = useCurrentUser()
  const { team } = useTeam(teamSlug)
  const [toggle, setToggle] = useState(true)

  const getCollaboratorsQuery = useQuery([teamSlug, 'getCollaborator'], () =>
    callApi(api.getCollaborators, {
      team_slug: teamSlug,
      attachable_type: attachableType,
      attachable_uuid: attachableUuid,
    }),
  )

  const collaborators = getCollaboratorsQuery.data?.collaborators || []

  useHotkeys(
    's',
    (e) => {
      e.preventDefault()
      setToggle(!toggle)
    },
    [toggle],
  )

  const createCollaborator = useMutation((params: CreateCollaboratorsRequest) =>
    callApi(api.createCollaborator, params),
  )

  const handleCreateCollaborator = async (collaborator: LimitedUser) => {
    try {
      await createCollaborator.mutateAsync({
        attachable_type: attachableType,
        attachable_uuid: attachableUuid,
        user_uuid: collaborator.uuid,
        team_slug: teamSlug,
      })

      await getCollaboratorsQuery.refetch()
      cabalToast({ style: 'success', content: 'Successfully added collaborator!' })
    } catch (error) {
      cabalToast({ style: 'error', content: 'Something went wrong. Please contact support' })
      ErrorLogger.error(error)
      console.error(error)
    }
  }

  if (!team || !user) return null

  const excludeUserUuids = collaborators.map(({ user: { uuid } }) => uuid).concat(user.uuid)
  const availableCollaborators = team.admins_and_members.filter((user) => {
    return !excludeUserUuids.includes(user.uuid)
  })

  return (
    <Faces className="mt-2">
      {collaborators.map((collaborator) => (
        <Tooltip key={collaborator.user.uuid} label={collaborator.user.name}>
          <Face
            className="-mr-2"
            name={collaborator.user.name}
            src={collaborator.user.avatar_url}
            size={size}
          />
        </Tooltip>
      ))}

      <MessageCollaboratorsAdd
        isLoading={createCollaborator.isLoading}
        users={availableCollaborators}
        size={size}
        onSelect={handleCreateCollaborator}
      />
    </Faces>
  )
}

export default React.memo(Collaborators, (prevProps, nextProps) => {
  return isEqual(omit(prevProps, ['onReload']), omit(nextProps, ['onReload']))
})
