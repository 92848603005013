import { CurrentUserProfile, DraftMessageBlueprint } from 'utils/types'

const getMessageParams = (message: DraftMessageBlueprint, user: CurrentUserProfile) => {
  const {
    body,
    subject,
    attachments,
    to: recipients,
    cc,
    bcc,
    from,
    sender_uuid,
    request,
    schedule_at,
    notified_sender_uuids,
  } = message

  const wrapperElement = document.createElement('div')
  wrapperElement.classList.add('rich-text')
  wrapperElement.innerHTML = body!

  return {
    message_uuid: message.uuid,
    body: wrapperElement.innerHTML,
    subject,
    attachments,
    send_email: true,
    from,
    sender_uuid,
    recipients,
    cc,
    bcc,
    draft: false,
    request_uuid: request?.uuid,
    draft_uuid: message.uuid,
    schedule_at,
    current_collaborator_uuid: user.uuid,
    notified_sender_uuids,
  }
}

export default getMessageParams
