import React, { useState } from 'react'

import sortBy from 'lodash/sortBy'
import styled from 'styled-components'
import tw from 'twin.macro'
import { v4 as uuid } from 'uuid'

import CabalButton from 'global/CabalButton'
import { TextArea, TextInput } from 'global/Input'
import Typography from 'global/Typography'

import { listItemNames } from 'utils/constants/list'
import { CompanyListItemType } from 'utils/types'

import { EditListType } from '..'

export const SectionHeader = styled.div`
  ${tw`flex items-center justify-between`}
  height: 24px;
`

export type FieldFilterProps = {
  index?: number
  operator?: string
  filterable?: any
}

interface Props {
  list: EditListType
  setList: (list: Partial<EditListType>) => void
  hideListItemsLabel?: boolean
}

const ManualListItems: React.VFC<Props> = ({ list, setList, hideListItemsLabel }) => {
  const creating = !('uuid' in list)
  const [bulkEdit, setBulkEdit] = useState(creating)
  const [listItems, setListItems] = useState(
    sortBy(list.company_list_items, (item) => (item.name ? item.name.toLowerCase() : item.name)) ||
      [],
  )

  const updateField = (field: string, uuid: string, value: string) => {
    if ('uuid' in list) {
      const newListItems = list.company_list_items?.map((d) => {
        if (d.uuid !== uuid) {
          return d
        } else {
          return {
            ...d,
            [field]: value,
          }
        }
      })

      setList({ company_list_items: newListItems })
      setListItems(newListItems)
    }
  }

  const listItemName = listItemNames[list.list_type]
  const isDomainList =
    list.list_type === null || list.list_type === 'companies' || list.list_type === 'fundraise'

  const itemEditor = (
    <div className="flex flex-col gap-1">
      {!bulkEdit &&
        isDomainList &&
        'uuid' in list &&
        listItems.map((l, idx) => (
          <div className="flex gap-1 items-center" key={`${l.uuid}-${idx} `}>
            <TextInput
              placeholder="Company name"
              value={l.name || ''}
              component="input"
              onChangeCapture={(e) => {
                updateField('name', l.uuid, e.currentTarget.value)
              }}
              className="w-full"
            />
            <TextInput
              placeholder="Company domain"
              valueType="domain"
              value={l.domain || ''}
              component="input"
              onChangeCapture={(e) => {
                updateField('domain', l.uuid, e.currentTarget.value)
              }}
              className="w-full"
            />
            <Typography
              component="button"
              className="ml-1"
              onClick={() => {
                const newListItems = list.company_list_items.filter((d) => d.uuid !== l.uuid)
                setList({ company_list_items: newListItems })
                setListItems(newListItems)
              }}
              fontSize="12"
              color={'fog'}
            >
              <i className="far fa-trash-can" />
            </Typography>
          </div>
        ))}
      {(bulkEdit || !isDomainList) && (
        <TextArea
          data-testid="import-companies-input"
          placeholder={`Paste ${listItemName} names, one ${listItemName} per line`}
          value={
            'uuid' in list
              ? list.company_list_items.map((item) => item.name || item.domain).join('\n')
              : list.company_list_items.join('\n')
          }
          className={'w-full'}
          onChange={(e) => {
            const items = e.currentTarget.value.split('\n')

            if ('uuid' in list) {
              const newListItems = items.map(
                (item) => ({ domain: '', name: item, uuid: uuid() } as any as CompanyListItemType),
              )
              setList({ company_list_items: newListItems })
              setListItems(newListItems)
            } else {
              setList({ company_list_items: items })
              setListItems(items)
            }
          }}
          rows={6}
        />
      )}
      {isDomainList && !bulkEdit && (
        <Typography
          className="cursor-pointer mt-1"
          component="p"
          color={'purple'}
          fontSize="12"
          onClick={() => {
            const newListItems = [...listItems, { domain: '', name: '', uuid: uuid() } as any]
            setList({ company_list_items: newListItems })
            setListItems(newListItems)
          }}
        >
          <i className="far fa-add" /> Add Item
        </Typography>
      )}
    </div>
  )

  if (hideListItemsLabel) return itemEditor

  return (
    <>
      <div>
        <div className="flex items-center space-x-2 mb-4">
          <Typography fontSize="12" component="p">
            List Items
          </Typography>
          {!creating && isDomainList && (
            <CabalButton
              variant="secondary"
              size="small"
              leftIcon={<i className="fas fa-pencil fa-fw" />}
              onClick={() => setBulkEdit(!bulkEdit)}
            >
              Bulk edit
            </CabalButton>
          )}
        </div>

        <div className="max-h-96 overflow-y-scroll">{itemEditor}</div>
      </div>
    </>
  )
}

export default ManualListItems
