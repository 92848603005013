import React, { HTMLProps, useState } from 'react'

import cx from 'classnames'
import styled, { StyledComponentProps, StyledComponentPropsWithRef } from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

const OptionWrapper = styled.button<{ active: boolean }>`
  ${tw`flex items-center justify-center rounded-lg p-3 cursor-pointer flex-shrink-0`}
  background-color: ${({ theme, active }) => active && theme.buttons.secondary.bg_color};
`

interface OptionProps extends StyledComponentPropsWithRef<'button'> {
  title: string
  icon: string
  active: boolean
  onClick: () => void
}

const OptionCard: React.FC<OptionProps> = ({ title, icon, active, onClick, ...props }) => {
  return (
    <OptionWrapper active={active} onClick={onClick} {...props}>
      <Typography fontSize="12" color={active ? 'purple' : 'fog'} className="mr-1.5">
        <i className={active ? `fas ${icon}` : `far ${icon}`} />
      </Typography>
      <Typography fontSize="12" color={active ? 'purple' : 'fog'} fontWeight={active ? 600 : 400}>
        {title}
      </Typography>
    </OptionWrapper>
  )
}

const HeaderSection = ({
  step,
  onSetStep,
  sharedConnections,
  hideConnectionsStep = false,
}: {
  step: number
  onSetStep: (s: number) => void
  sharedConnections: boolean
  hideConnectionsStep?: boolean
}) => {
  return (
    <div
      className={cx('flex sm:grid gap-1 px-2 sm:px-6 py-2 sm:py-4 overflow-auto', {
        'sm:grid-cols-3': hideConnectionsStep,
        'sm:grid-cols-4': !hideConnectionsStep,
      })}
    >
      <OptionCard
        title="Welcome Message"
        icon="fa-message-lines"
        active={step === 1}
        onClick={() => onSetStep(1)}
      />
      <OptionCard
        title="How it works"
        icon="fa-question-circle"
        active={step === 2}
        onClick={() => onSetStep(2)}
      />
      <OptionCard
        title="Personal Details"
        icon="fa-user-circle"
        active={step === 3}
        onClick={() => onSetStep(3)}
      />
      {!hideConnectionsStep && (
        <OptionCard
          title={'Add Connections'}
          icon="fa-chart-network"
          active={step === 4}
          onClick={() => onSetStep(4)}
        />
      )}
    </div>
  )
}

export default HeaderSection
