import React, { useState } from 'react'

import { useQuery } from 'react-query'

import Avatar from 'global/Avatar'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'

import TitleWrappers from './TitleWrappers'

interface Props {
  uuid: string
  teamSlug: string
}

const ConnectionIntroRequestInfo: React.FC<Props> = ({ uuid, teamSlug }) => {
  const { data } = useQuery(['connection-intro-request', uuid], () =>
    callApi(api.getIntroRequestV2, uuid, teamSlug),
  )

  const connectionIntroRequest = data
  const requestable = connectionIntroRequest?.requestable
  // const requester = connectionIntroRequest?.requestor

  if (!requestable) return <></>

  return (
    <>
      <div className="text-sm">
        <b>Intro request to:</b>

        <div className="">
          {/* <div className="mb-2 flex">
            <div className="flex-1">
              <a href={`/${teamSlug}/members/${requester?.advisor_uuid}`} target="_blank">
                {requester?.name}
              </a>
            </div>
          </div> */}
          <div className="mb-2 flex mt-2">
            <div className="flex-1">
              {requestable.linkedin_url && (
                <a href={requestable.linkedin_url} target="_blank" rel="noreferrer">
                  {requestable.name}
                  <i className="fab fa-linkedin ml-2" />
                </a>
              )}
              {!requestable.linkedin_url && requestable.name}
              <div>{requestable.headline}</div>
            </div>
          </div>
          {requestable?.company_name && (
            <div className="mb-2 flex">
              <div className="flex-1">Company: {requestable.company_name}</div>
            </div>
          )}
        </div>
      </div>
    </>
  )

  // return (
  //   <>
  //     <TitleWrappers
  //       title="Intro request to:"
  //       onClick={() => setExpanded(!expanded)}
  //       active={expanded}
  //     />
  //     {expanded && (
  //       <Typography fontSize="14" component="div" className="py-2">
  //         <div className="flex items-center gap-2">
  //           <Avatar src={requestable.image_url} name={requestable.name} />
  //           <div>
  //             <div className="flex gap-2">
  //               <Typography>{requestable.name}</Typography>
  //               {requestable.linkedin_url && (
  //                 <Typography
  //                   component="a"
  //                   href={requestable.linkedin_url}
  //                   target="_blank"
  //                   color="fog_rain"
  //                 >
  //                   <i className="fab fa-linkedin" />
  //                 </Typography>
  //               )}
  //             </div>
  //             {requestable.headline && (
  //               <Typography fontSize="12" color="fog_rain">
  //                 {requestable.headline}
  //               </Typography>
  //             )}
  //           </div>
  //         </div>
  //       </Typography>
  //     )}
  //   </>
  // )
}

export default ConnectionIntroRequestInfo
