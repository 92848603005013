import * as React from 'react'

import cx from 'classnames'
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import tw from 'twin.macro'

import OldLoading from 'containers/ListView/Companies/OldLoading'

import Loading from './Loading'
import Tooltip from './Tooltip'
import Typography from './Typography'

interface StyledButtonProps {
  textColor?: string
  disabled?: boolean
  tertiaryBorder?: boolean
  size: 'tiny' | 'small' | 'medium' | 'large'
  padding?: string
}

const SIZES = {
  tiny: {
    fontSize: '10px',
    padding: '2px 4px',
  },
  small: {
    fontSize: '12.5px',
    padding: '4.5px 8px',
  },
  medium: {
    fontSize: '12px',
    padding: '8px 16px',
  },
  large: {
    fontSize: '14px',
    padding: '11.5px 16px',
  },
}

const ButtonBase = styled.div<StyledButtonProps>`
  ${tw`inline-flex justify-center items-center focus:outline-none  leading-4 transition-colors`}

  padding: ${({ size, padding }) => padding ?? SIZES[size].padding};
  font-size: ${({ size }) => SIZES[size].fontSize};

  i {
    font-size: inherit;
  }
`

const PrimaryButton = styled(ButtonBase)<StyledButtonProps>`
  color: ${({ theme, textColor }) => textColor || theme.buttons.primary.text_color};
  background-color: ${({ theme }) => theme.buttons.primary.bg_color};

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.buttons.primary.bg_hover};
  }
`

const SecondaryButton = styled(ButtonBase)<StyledButtonProps>`
  color: ${({ theme, textColor }) => textColor || theme.buttons.secondary.text_color};
  background-color: ${({ theme }) => theme.buttons.secondary.bg_color};

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.buttons.secondary.bg_hover};
  }
`

const TertiaryButton = styled(ButtonBase)<StyledButtonProps>`
  color: ${({ theme, disabled, textColor }) =>
    disabled ? theme.colors.rain_fog : textColor || theme.colors.fog_rain};
  box-shadow: inset 0px 0px 0px 1px
    ${({ theme, tertiaryBorder }) => (tertiaryBorder ? theme.colors.pill_border : 'transparent')};
  background-color: transparent;

  &:hover {
    color: ${({ theme, disabled, textColor }) => !disabled && (textColor || theme.colors.rain_fog)};
  }
`

const LinkButton = styled(ButtonBase)<StyledButtonProps>`
  color: ${({ theme, disabled, textColor }) =>
    disabled ? theme.colors.rain_fog : textColor || theme.colors.link};
  box-shadow: inset 0px 0px 0px 1px
    ${({ theme, tertiaryBorder }) => (tertiaryBorder ? theme.colors.pill_border : 'transparent')};
  background-color: transparent;

  &:hover {
    color: ${({ theme, disabled, textColor }) =>
      !disabled && (textColor || theme.colors.light_purple)};
  }
`

const DestructiveButton = styled(ButtonBase)<StyledButtonProps>`
  color: ${({ theme, textColor }) => textColor || theme.buttons.primary.text_color};
  background-color: ${({ theme }) => theme.buttons.destroy.bg_color};

  &:hover {
    background-color: ${({ theme, disabled, textColor }) =>
      !disabled && (textColor || theme.buttons.destroy.bg_hover)};
  }
`

const AuthButton = styled(ButtonBase)<StyledButtonProps>`
  background: white !important;
  border: 1px solid ${(props) => props.theme.colors.gray300} !important;
  justify-content: flex-center !important;
  color: ${(props) => props.theme.colors.default_color} !important;
`

interface Props {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'destructive' | 'auth' | 'link'
  size?: StyledButtonProps['size']
  working?: boolean
  dummy?: boolean
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  disabled?: boolean
  disabledMessage?: string
  tooltip?: React.ReactNode
  padding?: string
  rounded?: boolean
  tertiaryBorder?: boolean
}

export interface ButtonProps extends Props, React.ComponentPropsWithoutRef<'button'> {
  textColor?: string
  component?: 'button' | 'div' | 'span'
  to?: undefined
}

interface LinkProps
  extends Props,
    Omit<RouterLinkProps, 'component' | 'ref'>,
    React.ComponentPropsWithoutRef<'a'> {
  component: 'link'
}

interface LinkElementProps
  extends Props,
    Omit<React.HTMLProps<HTMLAnchorElement>, 'size' | 'ref'>,
    React.ComponentPropsWithoutRef<'a'> {
  component: 'a'
}

interface LabelProps
  extends Props,
    Omit<React.HTMLProps<HTMLLabelElement>, 'size' | 'ref'>,
    React.ComponentPropsWithoutRef<'label'> {
  component: 'label'
}

export type CabalButtonProps = ButtonProps | LinkProps | LinkElementProps | LabelProps

const CabalButton = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, CabalButtonProps>(
  (
    {
      variant = 'primary',
      size = 'medium',
      working = false,
      dummy = false,
      children,
      className,
      disabled,
      disabledMessage,
      leftIcon,
      rightIcon,
      component: _component = 'button',
      tooltip,
      rounded = false,
      ...extraProps
    },
    ref,
  ) => {
    const theme = useTheme()

    children = (
      <Typography
        className="inline-flex items-center"
        customFontSize={SIZES[size].fontSize}
        fontWeight={['tertiary', 'link'].includes(variant) ? 400 : 600}
        color="inherit"
      >
        {leftIcon && <span className={cx({ 'mr-2': children })}>{leftIcon}</span>}
        {children}
        {rightIcon && <span className={cx({ 'ml-2': children })}>{rightIcon}</span>}
      </Typography>
    )

    if (working) {
      children = (
        <Typography
          fontSize="16"
          fontWeight={500}
          color="inherit"
          className="inline-flex items-center justify-center"
          component="div"
        >
          <div className="flex-initial mr-2 inline-flex">
            <OldLoading
              size={12}
              color={['tertiary', 'link'].includes(variant) ? theme.colors.rain_fog : 'white'}
              strokeWidth={5}
            />
          </div>
          <div className="flex-1 inline-flex">{children}</div>
        </Typography>
      )
      disabled = true
    }

    let component: string | React.ComponentType<any> = _component
    if (_component === 'link') component = Link

    const newProps = {
      ...extraProps,
      ref,
      className: cx('rounded', className, {
        'cursor-default': dummy,
        'cursor-not-allowed': disabled,
        'opacity-50': disabled,
        'rounded-full': rounded,
        working,
      }),
      children,
      disabled,
      size,
      as: component,
    }

    let button = <PrimaryButton {...newProps} />
    if (variant === 'secondary') button = <SecondaryButton {...newProps} />
    else if (variant === 'tertiary') button = <TertiaryButton {...newProps} />
    else if (variant === 'link') button = <LinkButton {...newProps} />
    else if (variant === 'destructive') button = <DestructiveButton {...newProps} />
    else if (variant === 'auth') button = <AuthButton {...newProps} />

    if (disabled && disabledMessage) {
      button = (
        <Tooltip variant="danger" label={disabledMessage}>
          {button}
        </Tooltip>
      )
    }

    if (tooltip) {
      button = <Tooltip label={tooltip}>{button}</Tooltip>
    }

    return button
  },
)

export default CabalButton
