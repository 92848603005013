import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Redirect, useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import Loading from 'global/Loading'
import { useUniqueAccessLink } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { IntroRequest } from 'utils/types/investor'

import { Container } from './styles'

const AcceptRequest: React.VFC = () => {
  const { uuid: uniqueAccessLinkUuid } = useUniqueAccessLink()

  const { secret_uuid: secretUuid } = useParams<{ secret_uuid: string }>()
  const teamSlug = useSearchParam('team_slug') || ''
  const companyUuid = useSearchParam('company_uuid') || ''
  const [introRequest, setIntroRequest] = useState<IntroRequest>()

  useQuery(
    ['getIntroRequest', secretUuid],
    () => callApi(api.getIntroRequest, secretUuid, companyUuid || ''),
    {
      onSuccess: (data) => {
        setIntroRequest(data)
        if (data?.accepted_at === null && data?.declined_at === null) {
          acceptIntro()
        }
      },
      enabled: !!companyUuid,
    },
  )

  const { mutate: acceptIntro } = useMutation(
    ['acceptIntro', secretUuid],
    () => callApi(api.acceptIntro, secretUuid, companyUuid, teamSlug, uniqueAccessLinkUuid),
    {
      onSuccess: ({ intro_request }) => {
        setIntroRequest(intro_request)
      },
    },
  )

  if (introRequest?.accepted_at)
    return (
      <Redirect
        to={`/intro-requests/${introRequest?.secret_uuid}/accepted?company_uuid=${companyUuid}`}
      />
    )

  if (introRequest?.declined_at)
    return (
      <Redirect
        to={`/intro-requests/${introRequest?.secret_uuid}/declined?company_uuid=${companyUuid}&show_error_toast=true`}
      />
    )

  return (
    <Container>
      <Loading />
    </Container>
  )
}

export default AcceptRequest
