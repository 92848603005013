import moment from 'moment'
import React, { useContext } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import { ModalContext, RenderModal } from 'global/Modal/Context'
import Typography from 'global/Typography'

import ScheduleModal from './ScheduleModal'

export const SCHEDULE_DATE_FORMAT = 'MMM D, LT'

interface Props {
  disabled?: boolean
  date?: Date
  onChangeDate: (date?: Date) => void
}

const getTomorrowMorningDate = () => {
  return moment().startOf('day').add(1, 'day').hour(8)
}

const getTomorrowAfternoonDate = () => {
  return moment().startOf('day').add(1, 'day').hour(13)
}

const ScheduleSend: React.VFC<Props> = ({ disabled, date, onChangeDate }) => {
  const { showModal } = useContext(ModalContext)

  const renderScheduleModal: RenderModal = (resolve) => (
    <ScheduleModal
      show
      date={date}
      onSubmit={(date) => {
        onChangeDate(date)
        resolve(false)
      }}
      onHide={() => resolve(false)}
    />
  )

  const menuItems = () => {
    const items = [
      {
        label: (
          <Typography color="rain_fog" fontSize="12">
            Schedule Send
          </Typography>
        ),
      },
      {
        label: `Tomorrow morning - ${getTomorrowMorningDate().format(SCHEDULE_DATE_FORMAT)}`,
        onSelect: () => {
          onChangeDate(getTomorrowMorningDate().toDate())
        },
      },
      {
        label: `Tomorrow afternoon - ${getTomorrowAfternoonDate().format(SCHEDULE_DATE_FORMAT)}`,
        onSelect: () => {
          onChangeDate(getTomorrowAfternoonDate().toDate())
        },
      },
      {
        label: <Divider />,
      },
      {
        label: 'Custom Time',
        onSelect: () => showModal(renderScheduleModal, 'render_schedule_modal'),
      },
    ]
    return items
  }

  return (
    <DropDownMenu
      disabled={disabled}
      trigger={
        <StyledDropdownButton variant="primary">
          <Typography
            component="i"
            fontSize="14"
            className="far fa-chevron-down relative top-0.5"
          />
        </StyledDropdownButton>
      }
      menuItems={menuItems()}
    />
  )
}

const StyledDropdownButton = styled(CabalButton)`
  ${tw`rounded-l-none border-l`}

  font-size: ${12 / 16 + 'rem'};
  padding: 9.5px 10px 9.5px 7px;
  border-left-color: ${({ theme }) => theme.buttons.primary.bg_hover};
`

const Divider = styled.div`
  border-bottom: ${({ theme }) => theme.border};
`

export default ScheduleSend
