import React from 'react'

import { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import { useCurrentUser, useTeam, useTeams } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { PUBLIC_EMAIL_PROVIDERS } from 'utils/constants/public_email_providers'

interface PrefrencesModalProps {
  onHide: () => void
  teamSlug: string
}

const CreateCompanyModal = ({ onHide, teamSlug }: PrefrencesModalProps) => {
  const { user, reloadUser } = useCurrentUser()
  const { team, reloadTeam } = useTeam(teamSlug)
  const domain = user.email.split('@')[1] || ''
  const isPublicDomain = PUBLIC_EMAIL_PROVIDERS.some((provider) => provider === domain)
  const [newCompany, setNewCompany] = React.useState<{ name: string; domain: string }>({
    name: '',
    domain: isPublicDomain ? '' : domain,
  })

  const hasAccess = () => {
    if (window.QA) return true

    if (!newCompany || !newCompany.domain || newCompany.domain === '') {
      return false
    }

    const userEmailDomain = user.email.split('@')[1]
    const domain = newCompany.domain.toLowerCase().match(/^(?:https?:\/\/)?([\w-.]+)(\/.*)?$/)?.[1]

    if (
      (!!userEmailDomain && !!domain && domain === userEmailDomain) ||
      domain?.endsWith('.' + userEmailDomain)
    ) {
      return true
    } else {
      return false
    }
  }

  const createTeamMutation = useMutation(
    () =>
      callApi(api.createTeam, newCompany!).then(() => {
        reloadUser()
        reloadTeam()
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Team created successfully' })
        onHide()
      },
      onError: (e: AxiosError) => {
        cabalToast({ style: 'error', content: e.response?.data?.message })
      },
    },
  )

  return (
    <Modal
      header={`Create your team to share lists with ${team?.name}`}
      show={true}
      onHide={onHide}
      rightActions={
        <CabalButton
          variant="primary"
          onClick={() => createTeamMutation.mutate()}
          disabled={!hasAccess() || isPublicDomain || !newCompany.name}
          working={createTeamMutation.isLoading}
          tooltip={
            isPublicDomain
              ? 'To create a team, please login with your company email'
              : !hasAccess()
              ? `To create a team, please login with your ${newCompany.domain} email`
              : ''
          }
        >
          Save
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Company Name</ModalInputLabel>
          <TextInput
            value={newCompany?.name}
            onChange={(e) => setNewCompany({ ...newCompany, name: e.target.value })}
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Company Domain</ModalInputLabel>
          <TextInput
            value={newCompany?.domain}
            onChange={(e) => setNewCompany({ ...newCompany, domain: e.target.value })}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default CreateCompanyModal
