import React, { useEffect, useState } from 'react'
import { Checklist, ChecklistItem } from 'global/Input/Checklist'
import { Container } from '../styles'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import { InvestorCompany } from 'utils/types/investor'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import PortfolioEmptyState from 'components/EmptyStates/PortfolioEmptyState'
import { useHotkeys } from 'react-hotkeys-hook'

interface Props {
  companies: InvestorCompany[]
  isLoadingCompanies: boolean
  isFetchingCompanies: boolean
  onInsertCompany: (items: ChecklistItem[]) => void
  teamSlug: string
  openAddCompanyModal: () => void
}

const getCompanyLabel = (c: InvestorCompany) => {
  return (
    <div className="flex items-center my-1">
      <Avatar name={c.company_name} src={c.logo_url} round size="20" className="mr-2" />
      <Typography fontSize="12">{c.company_name}</Typography>
    </div>
  )
}

const getItems = (companies: InvestorCompany[]) => {
  return (
    companies.map((c: InvestorCompany) => ({
      label: getCompanyLabel(c),
      value: c.uuid,
      checked: false,
    })) || []
  )
}

const InvestorCompaniesTab: React.VFC<Props> = ({
  companies,
  isLoadingCompanies,
  isFetchingCompanies,
  onInsertCompany,
  teamSlug,
  openAddCompanyModal,
}) => {
  const [query, setQuery] = useState('')
  const [selectedAll, setSelectedAll] = useState(false)

  const [companyItems, setCompanyItems] = useState<ChecklistItem[]>(getItems(companies))

  useHotkeys('a+p', (e) => {
    e.preventDefault()
    openAddCompanyModal()
  })

  useEffect(() => {
    const lowercaseQuery = query.toLowerCase()
    let filteredCompanies = companies

    if (lowercaseQuery) {
      filteredCompanies = companies.filter(
        (c: InvestorCompany) =>
          c.company_name.toLowerCase().includes(lowercaseQuery) ||
          c.domain?.toLowerCase().includes(lowercaseQuery),
      )
    }

    setCompanyItems(getItems(filteredCompanies))
    setSelectedAll(false)
  }, [companies, query])

  const handleInsert = () => {
    onInsertCompany(companyItems)
    const updatedItems = companyItems.map((item) => {
      return { ...item, checked: false }
    })
    setCompanyItems(updatedItems)
    setSelectedAll(false)
  }

  const setAll = (value: boolean) => {
    const updatedItems = companyItems.map((item) => {
      return { ...item, checked: value }
    })
    setCompanyItems(updatedItems)
    setSelectedAll(value)
  }

  const disabled = !companyItems.find((c) => c.checked)

  if (!isLoadingCompanies && !isFetchingCompanies && companies.length === 0) {
    return (
      <div className="mt-5" data-testid="composer-portfolio-empty-state">
        <PortfolioEmptyState
          companySlug={teamSlug}
          cta={
            <CabalButton
              leftIcon={<i className="far fa-plus" />}
              onClick={openAddCompanyModal}
              variant="secondary"
              className="ml-auto order-2"
              data-testid="composer-portfolio-add-company-btn"
            >
              Add Company
            </CabalButton>
          }
        />
      </div>
    )
  }

  return (
    <div className="px-1">
      <div className="mt-1">
        <SearchInput
          onChange={(e) => setQuery(e.target.value)}
          defaultValue={query}
          value={query}
          lightBg
          placeholder="Search companies"
          maxLength={20}
          fontSize="12px"
          data-testid="composer-portfolio-search-input"
        />
      </div>
      <Container className="absolute-custom-scrollbar">
        <Checklist
          className="my-1"
          items={companyItems}
          onChange={setCompanyItems}
          isLoading={isLoadingCompanies || isFetchingCompanies}
          onDirectAdd={onInsertCompany}
        />
      </Container>
      <div>
        <CabalButton
          variant="primary"
          size="small"
          disabled={disabled}
          className="mr-2 mt-3"
          onClick={handleInsert}
          data-testid="composer-insert-company-btn"
        >
          Insert
        </CabalButton>
        <Typography
          fontSize="12"
          color="purple"
          onClick={() => setAll(!selectedAll)}
          className="cursor-pointer"
          data-testid="composer-portfolio-select-all-btn"
        >
          {selectedAll ? 'Deselect All' : 'Select All'}
        </Typography>
      </div>
    </div>
  )
}

export default React.memo(InvestorCompaniesTab)
