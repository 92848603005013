import { compact, first, last, omit, trim } from 'lodash'
import { Merge } from 'type-fest'
import regex from 'utils/regex'
import { EmailMessageRecipient, InboxMessageBlueprint, MessageModel } from 'utils/types'

function fakeBaseClass<T>(): new () => Pick<T, keyof T> {
  // we use a pick to remove the abstract modifier
  return class {} as any
}

const ignoredProps = ['uuid', 'type'] as const

/**
 * require only the intersection
 * rest everything is optional
 */
type MessageClassType = Omit<
  Merge<Partial<MessageModel>, InboxMessageBlueprint>,
  typeof ignoredProps[number]
>
type modelType = 'Message' | 'InboxMessage'

export default class CabalMessage extends fakeBaseClass<MessageClassType>() {
  rawMessage?: MessageModel
  rawInboxMessage?: InboxMessageBlueprint
  type: modelType = 'Message'

  constructor(
    data: MessageModel | InboxMessageBlueprint,
    raw?: InboxMessageBlueprint | MessageModel,
  ) {
    super()

    raw ||= data

    if ((data as any).type === 'InboxMessage') {
      this.type = 'InboxMessage'
      this.rawInboxMessage = raw as InboxMessageBlueprint
    } else {
      this.rawMessage = raw as MessageModel
    }

    const handler = {
      get: (target: CabalMessage, property: keyof MessageClassType, receiver: any) => {
        const obj = omit((target.rawInboxMessage || target.rawMessage)!, ...ignoredProps)
        if (Reflect.has(obj, property)) {
          return Reflect.get(obj, property)
        } else {
          return target[property]
        }
      },
      set: (target: CabalMessage, property: keyof MessageClassType, value: any, receiver: any) => {
        return Reflect.set((target.rawInboxMessage || target.rawMessage)!, property as any, value)
      },
    }
    return new Proxy(this, handler)
  }

  private get message() {
    return (this.rawInboxMessage || this.rawMessage)!
  }

  get uuid() {
    if (this.rawMessage) {
      return this.rawMessage.uuid
    } else if (this.rawInboxMessage) {
      return this.rawInboxMessage!.thread?.uuid || this.rawInboxMessage!.uuid
    }

    throw 'type is unknown'
  }

  get Cc() {
    if (this.rawMessage) {
      return this.rawMessage.cc
    } else if (this.rawInboxMessage) {
      return this.stringRecipientToMessageRecipients(this.rawInboxMessage!.cc)
    }

    throw 'type is unknown'
  }

  get To() {
    if (this.rawMessage) {
      return this.rawMessage.recipients
    } else if (this.rawInboxMessage) {
      return this.stringRecipientToMessageRecipients(this.rawInboxMessage?.to)
    }

    throw 'type is unknown'
  }

  get From() {
    if (this.rawMessage) {
      return this.rawMessage.sender.name
    } else if (this.rawInboxMessage) {
      return this.rawInboxMessage?.from
    }

    throw 'type is unknown'
  }

  private stringRecipientToMessageRecipients(input?: string) {
    if (!input) return []

    const spliTo = input.split(',').map((v) => trim(v))

    return spliTo.map((str) => {
      const email = first(str.match(regex.emailAddressFromString)) || ''
      const name = str.replace(email, '').replace(/[^\w ]/g, '')

      return {
        label: name,
        type: 'email',
        value: {
          email: email,
          first_name: first(name.split(' ')),
          last_name: last(name.split(' ')),
          add_member: false,
        },
      } as EmailMessageRecipient
    })
  }

  static fromArray(
    messages?:
      | (MessageModel[] | InboxMessageBlueprint[])
      | (MessageModel | InboxMessageBlueprint)[],
  ) {
    if (!messages) return []

    return compact(messages.map((m) => CabalMessage.from(m)))
  }

  static from(m?: MessageModel | InboxMessageBlueprint) {
    if (!m) return undefined

    return new CabalMessage(m)
  }
}
