import React from 'react'

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

import { cn } from 'utils/styles'

export type IconProps = FontAwesomeIconProps

const Icon = (props: IconProps) => {
  return <FontAwesomeIcon {...props} className={cn('w-4', props.className)} />
}

export default Icon
