import React from 'react'

import { fontSize, variant } from 'styled-system'

import PreferencesModal from 'containers/CandidateProfile/Modals/PreferencesModal'
import {
  LEVEL_MAP,
  OPPORTUNITY_TYPE_MAP,
  ROLE_FUNCTION_MAP,
  STAGE_WITH_OPEN_TO_ALL_MAP,
} from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Pill from 'global/Pill'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import Widget from 'ui-components/Widget'

import { CandidateProfile } from 'utils/types'

interface Props {
  candidateProfile: CandidateProfile
  reloadCandidateProfile: () => void
  isThisCandidate: boolean
  isAdminOrEmployee: boolean
}

const Preferences: React.FC<Props> = ({
  candidateProfile,
  reloadCandidateProfile,
  isThisCandidate,
  isAdminOrEmployee,
}) => {
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()

  const {
    what_youre_looking_for: whatYoureLookingFor,
    function_preference: functionPreference,
    level_preference: levelPreference,
    stage_preference: stagePreference,
    opportunity_type: opportunityType,
    ideal_company_size: idealCompanySize,
  } = candidateProfile

  const renderPrefecModal = (resolve) => (
    <PreferencesModal
      onHide={() => resolve()}
      candidateProfile={candidateProfile}
      teamSlug={teamSlug}
      reloadCandidateProfile={reloadCandidateProfile}
    />
  )

  return (
    <Widget
      title={`Preferences`}
      classNames="group"
      cta={
        (isAdminOrEmployee || isThisCandidate) && (
          <div className="hidden group-hover:flex">
            <CabalButton
              variant="link"
              padding={'0'}
              onClick={() => showModal(renderPrefecModal, 'preferences-modal')}
            >
              Edit
            </CabalButton>
          </div>
        )
      }
    >
      <div className="flex flex-col pb-4">
        <Typography fontSize="12" className="" color="fog">
          {whatYoureLookingFor}
        </Typography>
      </div>
      <div className="grid md:grid-cols-2 gap-4">
        {functionPreference && functionPreference.length > 0 && (
          <div className="flex flex-col">
            <Typography fontSize="14" fontWeight={600}>
              Function
            </Typography>

            <Typography fontSize="12" className="flex flex-wrap gap-1 mt-1" color="fog">
              {functionPreference?.map((func, i) => (
                <Pill variant="gray" rounded key={i}>
                  {ROLE_FUNCTION_MAP[func]}
                </Pill>
              )) || 'N/A'}
            </Typography>
          </div>
        )}
        {levelPreference && levelPreference?.length > 0 && (
          <div className="flex flex-col">
            <Typography fontSize="14" fontWeight={600}>
              Level
            </Typography>

            <Typography fontSize="12" className="flex flex-wrap gap-1 mt-1" color="fog">
              {levelPreference?.map((func, i) => (
                <Pill variant="gray" rounded key={i}>
                  {LEVEL_MAP[func.toLowerCase()]}
                </Pill>
              )) || 'N/A'}
            </Typography>
          </div>
        )}
        {stagePreference && stagePreference?.length > 0 && (
          <div className="flex flex-col">
            <Typography fontSize="14" fontWeight={600}>
              Stage
            </Typography>

            <Typography fontSize="12" className="flex flex-wrap gap-1 mt-1" color="fog">
              {stagePreference?.map((func, i) => (
                <Pill variant="gray" rounded key={i}>
                  {STAGE_WITH_OPEN_TO_ALL_MAP[func.toLowerCase()]}
                </Pill>
              )) || 'N/A'}
            </Typography>
          </div>
        )}
        {opportunityType && opportunityType?.length > 0 && (
          <div className="flex flex-col">
            <Typography fontSize="14" fontWeight={600}>
              Job type
            </Typography>

            <Typography fontSize="12" className="flex flex-wrap gap-1 mt-1" color="fog">
              {opportunityType?.map((func, i) => (
                <Pill variant="gray" rounded key={i}>
                  {OPPORTUNITY_TYPE_MAP[func.toLowerCase()]}
                </Pill>
              )) || 'N/A'}
            </Typography>
          </div>
        )}
        {idealCompanySize && idealCompanySize?.length > 0 && (
          <div className="flex flex-col">
            <Typography fontSize="14" fontWeight={600}>
              Company size
            </Typography>

            <Typography fontSize="12" className="flex flex-wrap gap-1 mt-1" color="fog">
              {idealCompanySize?.map((func, i) => (
                <Pill variant="gray" rounded key={i}>
                  {func.charAt(0).toUpperCase() + func.slice(1)}
                </Pill>
              )) || 'N/A'}
            </Typography>
          </div>
        )}
      </div>
    </Widget>
  )
}

export default Preferences
