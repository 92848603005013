import React from 'react'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import CabalTitle from 'global/Title'

import api, { callApi } from 'utils/api'

import AcceptContent from './AcceptContent'

const AdvisorsAcceptance = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const getAgreementQuery = useQuery([id], () => callApi(api.getAgreement, id), {
    onSuccess: (data) => {
      if (!data.agreement.invite_page_message) {
        history.replace(`/sign/${id}`)
      }
    },
  })

  const agreement = getAgreementQuery.data?.agreement

  if (getAgreementQuery.isLoading || !agreement?.invite_page_message) {
    return <Loading />
  }

  return (
    <PageWrapper title="Accept Agreement" className="p-8 overflow-auto h-screen w-screen">
      <AcceptContent
        agreementUuid={id}
        companyName={getAgreementQuery.data!.team.name}
        logo={getAgreementQuery.data!.team.logo}
        sender={getAgreementQuery.data!.agreement.sender}
        invitePageMessage={agreement.invite_page_message}
      />
    </PageWrapper>
  )
}

export default AdvisorsAcceptance
