import React from 'react'

import { ClassicCKEditor } from 'global/TextEditor/ckeditor/base'

import Templates from 'utils/templates'
import { AdvisorModel, ButtonModel, MessageModel, MessageRecipient } from 'utils/types'

import SendMessageClass from './index.class'

export interface SendMessageProps {
  title?: string
  ckEditorRef: React.MutableRefObject<ClassicCKEditor | undefined>
  submitText?: string
  onClose?: () => void
  afterDelete?: () => void
  onSubmit?: (m: MessageModel) => void
  onSaveDraft?: () => void
  message_uuid?: string
  message?: MessageModel
  defaultSubject?: string
  defaultRecipients?: MessageRecipient[]
  defaultCc?: MessageRecipient[]
  defaultBody?: string
  defaultTemplate?: keyof typeof Templates
  defaultButtons?: ButtonModel[]
  connection?: any
  advisor?: AdvisorModel
  companySlug: string
  willMailMerge?: boolean
  allowCc?: boolean
  allowButtons?: boolean
  allowVideo?: boolean
  allowTemplates?: boolean
  allowSnippets?: boolean
  allowSaveAsDraft?: boolean
  allowScheduledSend?: boolean
  primaryAction?: SendMessagePrimaryAction
  allowGroups?: boolean
  templateVariables?: Record<string, any>
  submitFunc?: (body: string, subject: string) => void
  hideToField?: boolean
  submitWorking?: boolean
  ignoreRecipients?: boolean
  widgetPreview?: React.ReactNode
  presenceListContainer?: HTMLDivElement
  fromRequest?: boolean
  draftSaves?: boolean
  allowMessageTitle?: boolean
  hideModal?: (redirect: boolean) => void
  onEditorInit?: () => void
  editorHeight?: number
  introRequestFlow?: boolean
  onChange?: <T extends keyof MessageModel>(key: T, value: MessageModel[T]) => void
}

export enum SendMessagePrimaryAction {
  SendMessage,
  SaveDraft,
}

export default SendMessageClass
