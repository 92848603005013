import axios from 'axios'
import {
  AutoCompleteCompany,
  EnrichEmailsResponse,
  FindOrganizationResponse,
  FindPersonResponse,
} from 'utils/types'

export default {
  findOrganizations: (query: string, params = {}) => {
    return axios.get<{
      companies: AutoCompleteCompany[]
    }>('/api/clearbit/organizations', { params: { ...params, query } })
  },

  findOrganization: (domain: string, params = {}) => {
    return axios.get<FindOrganizationResponse>('/api/clearbit/organization', { params: { ...params, domain } })
  },

  findPerson: (email: string) => {
    return axios.get<FindPersonResponse>('/api/clearbit/person', { params: { email } })
  },

  enrichEmails: (emails: string[]) => {
    return axios.get<EnrichEmailsResponse>('/api/clearbit/emails', { params: { emails } })
  },
}
