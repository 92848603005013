import React from 'react'

import { ITeamData } from 'components/Winsite/types/ITeamData'
import Avatar from 'global/Avatar'

import { capitalize, isNullOrUndefined } from 'utils/string'

import { InfoHeadlineDiv, StyledFontAwesomeIcon } from './../styles'

interface IProps {
  teamData: ITeamData
}

export function CompanyBlurbInfo({ teamData }: IProps) {
  const {
    company_name: companyName,
    linkedin_url: linkedinUrl,
    twitter_url: twitterUrl,
    website_url: websiteUrl,
    website_logo: websiteLogo,
  } = teamData
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>, url: string) => {
    e.stopPropagation()
    window.open(url, '_blank', 'noopener noreferrer')
  }

  return (
    <div className="flex flex-row gap-x-3 items-center">
      {isNullOrUndefined(websiteLogo) ? (
        ''
      ) : (
        <Avatar name={companyName} src={websiteLogo} size="32px" />
      )}
      <InfoHeadlineDiv>{capitalize(companyName)}</InfoHeadlineDiv>
      {linkedinUrl && (
        <button onClick={(e) => handleClick(e, linkedinUrl)}>
          <StyledFontAwesomeIcon className={'fa-brands fa-linkedin-in'}></StyledFontAwesomeIcon>
        </button>
      )}
      {twitterUrl && (
        <button onClick={(e) => handleClick(e, twitterUrl)}>
          <StyledFontAwesomeIcon
            className={'fa-brands fa-x-twitter text-sm font-black'}
          ></StyledFontAwesomeIcon>
        </button>
      )}
      {websiteUrl && (
        <button onClick={(e) => handleClick(e, websiteUrl)}>
          <StyledFontAwesomeIcon className={'fa-solid fa-link text-sm'}></StyledFontAwesomeIcon>
        </button>
      )}
    </div>
  )
}
