import styled from 'styled-components/macro'
import tw from 'twin.macro'

const LandingPageWrapper = styled.div`
  height: 100vh;
  /* overflow: scroll; */
`

const SignupContainer = styled.div`
  background-color: #fefefe;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: scroll;

  background-image: url(/noise-v2.png);
  background-position: 0, 0;
  background-size: 640px 640px;
  background-repeat: repeat;
  scroll-behavior: smooth;

  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;

  ol {
    list-style-type: decimal;
    font-size: 20px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  blockquote,
  b,
  strong {
    font-family: 'Inter', Helvetica, sans-serif;
    color: #0e151f;
  }

  p,
  span,
  em,
  li {
    color: #4b5563;
  }

  input {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    height: 48px;
    box-sizing: border-box;
    background: #fff;
    color: #000;

    border: 2px solid #8a97ff;
    border-radius: 4px;
  }

  button {
    background: #5c69d1;
    color: #fff;
    border-radius: 4px;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    margin: 12px 0;
    height: 48px;
    box-sizing: border-box;
    width: 100%;
  }

  button:hover {
    background: #2f3ca7;
  }

  button:disabled,
  button[disabled] {
    opacity: 0.5;
  }

  a {
    color: #718096;
    text-decoration: none;
  }

  ::placeholder {
    color: #4a5566;
    opacity: 1; /* Firefox */
  }

  /* @media (prefers-color-scheme: dark) {
    background-color: #0e151f;

    h1,
    h2,
    h3,
    blockquote,
    b,
    strong {
      color: #f7fafc;
    }

    p,
    span,
    em,

    input {
      background: #0e151f;
      color: #fff;
    }
  } */
`

const BodyMask = styled.div`
  background-position: top left;
  background-repeat: repeat;
  background-color: #fefefe;

  /* @media (prefers-color-scheme: dark) {
    background: #0e151f;
  } */
`

const BlockQuote = styled.blockquote`
  font-size: 20px;
  line-height: 1.4;
  padding-bottom: 24px;
  padding-left: 32px;
  font-style: italic;
`

const BlogTitle = styled.h1`
  letter-spacing: -0.03em;
  font-weight: 600;
  font-size: 40px;
  line-height: 1;
  padding-top: 40px;
`

const BlogContent = styled.div`
  ${tw`flex justify-center flex-col mx-auto`}
  max-width: 730px;
`

const BlogContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1035px;
  padding: 0 20px;
  padding-bottom: 96px;

  @media (min-width: 650px) {
    padding: 0 44px;
    padding-bottom: 96px;
  }
`

const H1 = styled.h2`
  letter-spacing: -0.03em;
  font-size: 48px;
  line-height: 1;
  font-weight: 600;

  @media only screen and (max-width: 650px) {
    font-size: 32px;
    line-height: 1.05;
  }
`

const H2 = styled.h2`
  font-weight: 600;
  padding: 20px 0px;
  font-size: 20px;
  line-height: 1.25;
  letter-spacing: -0.02em;
`

const H3 = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.04em;
  padding-left: 10.5px;
`

const Para = styled.p`
  font-family: 'JetBrains Mono';
  padding-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #6b7280 !important;

  /* @media (prefers-color-scheme: dark) {
    color: #f7fafc !important;
  } */
`

const Section = styled.section`
  padding-bottom: 40px;
`

const Header = styled.h2`
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  padding-bottom: 30px;
`

const Content = styled.p`
  font-size: 18px;
  line-height: 1.4;
  padding-bottom: 32px;
`

const Italic = styled.span`
  font-size: 18px;
  line-height: 32px;
  padding-bottom: 24px;
  font-style: italic;
  display: inline;
`
const ItalicBlock = styled.div`
  font-size: 18px;
  line-height: 32px;
  padding-bottom: 24px;
  font-style: italic;
`

const Indent = styled.div`
  padding-left: 32px;
`
const Table = styled.table`
  ${tw` mx-auto`}
`

const LinkWrapper = styled.span`
  color: #5c69d1 !important;

  text-decoration: underline !important;

  /* @media (prefers-color-scheme: dark) {
    color: #a8bff5 !important;
  } */
`

const Image = styled.img`
  ${tw` mx-auto`}
  padding-top: 6px;
  padding-bottom: 30px;
`

const StyledImg = styled.img`
  ${tw` mx-auto`}
  margin-top: 6px;
  margin-bottom: 30px;
  border: 2px solid #718096;
  border-radius: 4px;
`

const Divider = styled.div`
  border-bottom: 1px solid #e3e5e8;

  /* @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid #2d3748;
  } */
`

const OL = styled.ol`
  padding-left: 22px;
`
const UL = styled.ul`
  padding-left: 22px;
`

const SuccessPrimary = styled.p`
  color: #5c69d1 !important;
  font-size: 18px;

  /* @media (prefers-color-scheme: dark) {
    color: #8a97ff !important;
  } */
`

const BlogHeaderImg = styled.img`
  /* width: 64px;
  height: 64px; */
  border-radius: 8px;
  width: 100%;
  margin: 24px 0;
`

const OptionText = styled.p`
  font-family: 'JetBrains Mono';
  font-size: 14px;
  line-height: 19.6px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  color: #718096 !important;
`

const OptionLink = styled.a`
  font-family: 'JetBrains Mono';
  font-size: 14px;
  line-height: 19.6px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  color: #0e151f !important;
  cursor: pointer;
`

const InputWrap = styled.div`
  max-width: 420px;
  @media (min-width: 650px) {
    max-width: 320px;
  }
`
const SectionContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const H5 = styled.h5`
  font-weight: 600;
  font-size: 28px;
  line-height: 1.05;
  letter-spacing: -0.03em;
`
const SmallerColumnLayout = styled.div`
  width: 100%;
  max-width: 1068px;
  margin: 0 20px;

  @media (min-width: 650px) {
    margin: 0 44px;
  }
`

const ColumnLayout = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 20px;

  @media (min-width: 650px) {
    margin: 0 44px;
  }
`

const ColumnDoubleLayout = styled(SmallerColumnLayout)`
  display: grid;
  row-gap: 32px;
  align-items: flex-start;
  padding-bottom: 88px;

  @media (min-width: 650px) {
    grid-template-columns: minmax(auto, 400px) minmax(auto, 600px);
    column-gap: 68px;
  }
`
const Subtitle = styled.p`
  font-family: 'JetBrains Mono';
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #6b7280 !important;

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }

  /* @media (prefers-color-scheme: dark) {
    color: #f7fafc !important;
  } */
`

export {
  BodyMask,
  BlogTitle,
  BlogContainer,
  BlogContent,
  BlockQuote,
  ColumnLayout,
  ColumnDoubleLayout,
  Content,
  Divider,
  Header,
  H1,
  H2,
  H3,
  H5,
  Image,
  InputWrap,
  StyledImg,
  Subtitle,
  Indent,
  Italic,
  ItalicBlock,
  LinkWrapper,
  OL,
  OptionText,
  OptionLink,
  UL,
  Para,
  Section,
  SignupContainer,
  SuccessPrimary,
  SectionContainer,
  SmallerColumnLayout,
  Table,
  BlogHeaderImg,
  LandingPageWrapper,
}
