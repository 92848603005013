import React, { ReactNode } from 'react'

import { transparentize } from 'polished'
import styled from 'styled-components'

import Typography from 'global/Typography'

import { cn } from 'utils/styles'

const StyledBanner = styled.button`
  background-color: ${({ theme }) => transparentize(0.7, theme.colors.mid_purple)};
  border: 1px dashed ${({ theme }) => theme.colors.fog};
  box-shadow: 0px 0px 8px 0px rgba(92, 105, 209, 0.5);
`

export const DefaultAvatar = styled(Typography)`
  background-color: ${({ theme }) => theme.colors.mid_purple};
`

interface Props {
  title: string | React.ReactNode
  avatar?: React.ReactNode
  description?: string
  cta?: ReactNode
  onClick?: () => void
}

const FocusBanner: React.FC<Props> = ({ avatar, title, description, cta, onClick }) => {
  return (
    <StyledBanner
      className={cn('flex items-center justify-between py-1 px-2.5 rounded-md h-[54px] w-full', {
        'cursor-pointer': !!onClick,
      })}
      onClick={onClick}
    >
      <div className="flex items-center gap-2.5">
        <DefaultAvatar
          className="flex items-center justify-center w-8 h-8 rounded-full"
          fontSize="14"
          fontWeight={400}
          color="white"
        >
          {avatar}
        </DefaultAvatar>
        <div className="flex flex-col items-start">
          <Typography fontSize="14">{title}</Typography>
          <Typography fontSize="10" color="fog">
            {description}
          </Typography>
        </div>
      </div>
      <div>{cta}</div>
    </StyledBanner>
  )
}

export default FocusBanner
