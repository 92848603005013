import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import PortfolioEmptyState from 'components/EmptyStates/PortfolioEmptyState'
import { CheckBox } from 'global/Input'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'

import useTeamSetting from 'utils/hooks/useTeamSetting'
import { PortfolioCompaniesFiltersType } from 'utils/types'
import { InvestorCompany, InvestorCompanyJob } from 'utils/types/investor'

import { FilterWrapper } from '../styles'
import CompanyRow from './CompanyRow'
import Filters from './Filters'
import GlobalActions from './GlobalActions'
import ImportCompaniesModal from './ImportCompaniesModal'

interface Props {
  companies: InvestorCompany[]
  setCompanies: (companies: InvestorCompany[]) => void
  isLoading: boolean
  isFetching: boolean
  allTags: string[]
  setAllTags: (tags: string[]) => void
  allStages: string[]
  setAllStages: (stages: string[]) => void
  teamSlug: string
  canEditPortfolio?: boolean
  jobs: InvestorCompanyJob[]
  setJobs: (jobs: InvestorCompanyJob[]) => void
  isLoadingJobs: boolean
  refetchCompanies: () => void
}

const CompaniesTab: React.VFC<Props> = ({
  companies,
  contributions,
  setCompanies,
  isLoading,
  isFetching,
  allTags,
  setAllTags,
  teamSlug,
  canEditPortfolio,
  jobs,
  setJobs,
  allStages,
  setAllStages,
  isLoadingJobs,
  refetchCompanies,
}) => {
  const { showModal } = useModal()
  const [filters, setFilters] = useState<PortfolioCompaniesFiltersType>({})
  const [filteredCompanies, setFilteredCompanies] = useState<InvestorCompany[]>([])
  const [selectedUuids, setSelectedUuids] = useState<Set<string>>(new Set())
  const [sortedBy, setSortedBy] = useState<string | null>(null)
  const [isSortedDesc, setIsSortedDesc] = useState(true)
  const [filterSelected, setFilterSelected] = useState(false)
  const [enablePortfolioJobs] = useTeamSetting(teamSlug, 'enable_portfolio_jobs')

  const companiesRef = useRef(companies)

  useEffect(() => {
    companiesRef.current = companies
  }, [companies])

  useEffect(() => {
    const isFilterSelected = !every(filters, isEmpty)
    let selectedCompanies = companies

    if (filters.search) {
      selectedCompanies = selectedCompanies?.filter((c: InvestorCompany) => {
        for (const i in filters.search) {
          const query = filters.search[i]
          if (c.company_name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
            return true
          }
        }
      })
    }

    if (filters.tags) {
      selectedCompanies = selectedCompanies?.filter((c: InvestorCompany) => {
        for (const i in filters.tags) {
          const tag = filters.tags[i]
          if ([...c.tags, ...c.crm_tags].includes(tag)) {
            return true
          }
        }
      })
    }

    if (filters.stage) {
      selectedCompanies = selectedCompanies?.filter((c: InvestorCompany) => {
        for (const i in filters.stage) {
          const stage = filters.stage[i]
          if (c.stage === stage) {
            return true
          }
        }
      })
    }

    setFilterSelected(isFilterSelected)

    setFilteredCompanies(
      selectedCompanies.length > 0 || isFilterSelected ? selectedCompanies : companies,
    )
  }, [filters, companies])

  const toggleSelectedCompany = useCallback(
    (uuid: string, selected: boolean) => {
      const updatedSelectedUuids = new Set(selectedUuids)
      if (selectedUuids.has(uuid)) {
        if (!selected) {
          updatedSelectedUuids.delete(uuid)
          setSelectedUuids(updatedSelectedUuids)
        }
      } else {
        if (selected) {
          updatedSelectedUuids.add(uuid)
          setSelectedUuids(updatedSelectedUuids)
        }
      }
    },
    [selectedUuids],
  )

  const selectedAll = React.useMemo(
    () => companies.every((c) => selectedUuids.has(c.uuid)),
    [selectedUuids, companies],
  )

  const sortedCompanies = useMemo(
    () =>
      filteredCompanies.sort((a, b) => {
        let res = 0
        if (sortedBy === 'company') {
          res = a.company_name.toLowerCase() > b.company_name.toLowerCase() ? -1 : 1
        }
        if (!isSortedDesc) {
          res *= -1
        }
        return res
      }),
    [filteredCompanies, isSortedDesc, sortedBy],
  )

  const openImportCompaniesModal = useCallback(() => {
    showModal(
      (resolve) => (
        <ImportCompaniesModal
          teamSlug={teamSlug}
          resolve={(refetch: boolean) => {
            if (refetch) refetchCompanies()
            resolve()
          }}
        />
      ),
      'import-investor-company-modal',
    )
  }, [])

  const getCompanyJobs = useCallback(
    (uuid: string) => {
      return jobs.filter((j) => j.investor_company.uuid === uuid)
    },
    [jobs],
  )

  if (isFetching || isLoading || isLoadingJobs) {
    return <Loading className="my-6" />
  }

  if (!isFetching && !isLoading && companies?.length === 0) {
    return (
      <div className="mt-4">
        <PortfolioEmptyState
          companySlug={teamSlug}
          onClick={canEditPortfolio ? openImportCompaniesModal : undefined}
        />
      </div>
    )
  }

  return (
    <div>
      <FilterWrapper className="flex justify-between">
        <div className="flex gap-2">
          {enablePortfolioJobs && (
            <CheckBox
              onClick={(e) =>
                setSelectedUuids(
                  new Set(e.currentTarget.checked ? companies.map((c) => c.uuid) : []),
                )
              }
              checked={selectedAll}
            />
          )}
          <Filters
            setFilters={setFilters}
            filters={filters}
            allTags={allTags}
            allStages={allStages}
            loadingTags={isLoading}
            teamSlug={teamSlug}
          />
        </div>
        <GlobalActions
          companies={companies}
          setCompanies={setCompanies}
          allTags={allTags}
          setAllTags={setAllTags}
          allStages={allStages}
          setAllStages={setAllStages}
          selectedUuids={selectedUuids}
          companiesRef={companiesRef}
          openImportCompaniesModal={openImportCompaniesModal}
        />
      </FilterWrapper>

      {isLoading && <Loading className="mt-10" />}

      {!isLoading && (
        <div className="flex flex-col">
          {filterSelected && sortedCompanies.length === 0 ? (
            <Typography className="text-center" data-testid="portfolio-companies-no-results-found">
              No results found
            </Typography>
          ) : (
            sortedCompanies.map((c) => (
              <CompanyRow
                key={c.uuid}
                company={c}
                companies={companies}
                contributions={contributions[c.uuid]}
                setCompanies={setCompanies}
                allTags={allTags}
                setAllTags={setAllTags}
                allStages={allStages}
                setAllStages={setAllStages}
                selected={!!selectedUuids?.has(c.uuid)}
                toggleSelectedCompany={toggleSelectedCompany}
                allJobs={jobs}
                jobs={getCompanyJobs(c.uuid)}
                setJobs={setJobs}
                isLoadingJobs={isLoadingJobs}
              />
            ))
          )}
        </div>
      )}

      {!isLoading && sortedCompanies.length > 0 && (
        <div className="text-center mt-4">
          <a style={{ color: '#A7B4C7', fontSize: 12 }} href="https://clearbit.com">
            Logos provided by Clearbit
          </a>
        </div>
      )}
    </div>
  )
}

export default React.memo(CompaniesTab, isEqual)
