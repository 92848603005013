import React, { useState } from 'react'

import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useSetState } from 'react-use'

import CabalButton from 'global/CabalButton'
import { Label, Select, TextInput } from 'global/Input'
import UploadZone from 'global/Input/UploadZone'
import Modal from 'global/Modal'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CreateAgreementTemplateRequest } from 'utils/types'

interface Props {
  resolve: () => void
  fetchTemplates: () => Promise<unknown>
  companySlug: string
}

const AddAgreementTemplateModal: React.VFC<Props> = ({ resolve, fetchTemplates, companySlug }) => {
  const history = useHistory()

  const [template, setTemplate] = useSetState<CreateAgreementTemplateRequest>({
    upload_uuid: '',
    name: '',
  })
  const { upload_uuid, name } = template
  const [working, setWorking] = useState(false)

  const { mutate: createTemplate } = useMutation(
    () => callApi(api.createAgreementTemplate, companySlug, template),
    {
      onMutate: () => {
        setWorking(true)
      },
      onSuccess: ({ agreement_template }) => {
        fetchTemplates().finally(() => {
          setWorking(false)
          resolve()
          cabalToast({ style: 'success', content: 'Successfully added the template!' })
          history.push(`/${companySlug}/prepare/${agreement_template.id}`)
        })
      },
      onError: () => {
        setWorking(false)
      },
    },
  )

  const canSave = !!upload_uuid && !!name

  return (
    <Modal
      show
      onHide={resolve}
      header="Add Agreement Template"
      rightActions={
        <CabalButton onClick={() => createTemplate()} working={working} disabled={!canSave}>
          Next
        </CabalButton>
      }
    >
      <TextInput
        placeholder="Agreement Name"
        labeled
        onChange={(e) => setTemplate({ name: e.currentTarget.value })}
        className="mb-4"
        color="fog"
      />
      <Label label="Agreement File (pdf or docx only)" color="fog" className="mt-4" />
      <UploadZone
        onUpload={([upload]) => upload && setTemplate({ upload_uuid: upload.uuid })}
        accept={['pdf', 'doc']}
        uploadOnSelect
        hideDndOnSelect
      />
    </Modal>
  )
}

export default AddAgreementTemplateModal
