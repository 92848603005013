import React, { useEffect, useMemo, useState } from 'react'

import { useQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import ItemCardV2 from 'components/Block/ItemCard'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useCurrentUser, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { Offer as OfferType } from 'utils/types'

import EditOfferModal from './EditOfferModal'

const IndexCardWrapper = styled.div`
  background: ${({ theme }) => theme.colors.cardBackground};
  border: 2px solid transparent;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.purple};
  }
`
export const offerTypes = [
  { label: 'Discounts', slug: 'deals' }, // leaving the slug to not break urls. need to add a simple redirect here.
  { label: 'People', slug: 'people' },
  { label: 'Content', slug: 'resources' },
  { label: 'Vendors', slug: 'vendors' },
]
interface ResourceIndexCardProps {
  link: string
  icon: string
  title: string
  count: number
}

interface ResourceProps {
  homeView?: boolean
  onFetchComplete?: () => void
}

const ResourceIndexCard: React.FC<ResourceIndexCardProps> = ({ link, icon, title, count }) => {
  return (
    <Link to={link}>
      <IndexCardWrapper className="flex rounded-lg items-center py-4 px-6">
        <Typography fontSize="20" color="purple">
          <i className={`fa-light ${icon} fa-fw`}></i>
        </Typography>
        <div className="flex flex-col ml-4">
          <Typography fontWeight={600} fontSize="14">
            {title}
          </Typography>
          <Typography fontSize="12" color="fog">
            {count} items
          </Typography>
        </div>
      </IndexCardWrapper>
    </Link>
  )
}

const Resources: React.FC<ResourceProps> = ({ homeView = false, onFetchComplete }) => {
  const teamSlug = useTeamSlug()
  const { canViewOffers, canEditOffers } = useAccessControl(teamSlug)
  const { showModal } = useModal()
  const { isLoggedIn } = useCurrentUser()

  const [allTags, setAllTags] = useState<string[]>([])
  const [allCategories, setAllCategories] = useState<string[]>([])
  const [offers, setOffers] = useState<OfferType[]>()
  const { isAdvisor } = useAccessControl(teamSlug)
  const history = useHistory()

  const { isLoading, isFetching } = useQuery(
    ['offers', teamSlug],
    () => callApi(api.getOffers, teamSlug),
    {
      onSuccess: ({ offers, tags, categories }) => {
        setOffers(offers)
        setAllTags(tags)
        setAllCategories(categories)
      },
      enabled: canViewOffers,
      // cacheTime: Infinity,
      // staleTime: Infinity,
    },
  )

  useEffect(() => {
    if (!isFetching && onFetchComplete) {
      onFetchComplete()
    }
  }, [isFetching, onFetchComplete])

  const openAddOfferModal = (proposed = false) => {
    showModal(
      (resolve) => (
        <EditOfferModal
          teamSlug={teamSlug}
          proposed={proposed}
          resolve={(offer) => {
            offer && setOffers([...(offers || []), offer])
            resolve()
          }}
          allTags={allTags}
          setAllTags={setAllTags}
          isFetchingTags={isLoading}
          allCategories={allCategories}
          setAllCategories={setAllCategories}
        />
      ),
      'add-offer-modal',
    )
  }

  const deals = useMemo(
    () =>
      offers?.filter(
        (offer) => !offer.proposed && (offer.offer_type === 'deals' || !offer.offer_type),
      ),
    [offers],
  )
  const people = useMemo(() => offers?.filter((offer) => offer.offer_type === 'people'), [offers])
  const vendors = useMemo(() => offers?.filter((offer) => offer.offer_type === 'vendors'), [offers])
  const proposed = useMemo(() => offers?.filter((offer) => offer.proposed), [offers])
  const resources = useMemo(
    () => offers?.filter((offer) => offer.offer_type === 'resources'),
    [offers],
  )

  function offerTypeLabel(label: string, teamSlug: string) {
    if (teamSlug === 'm13') {
      switch (label) {
        case 'Discounts':
          return 'Offers'
        case 'People':
          return 'M13 Team'
        case 'Vendors':
          return 'Preferred Partners'
        default:
          return label
      }
    } else {
      return label
    }
  }

  return (
    <PageWrapper title="Resources" className={homeView ? 'pb-0' : null}>
      {!homeView && (
        <PageHeader
          data={{ title: 'Resources' }}
          actions={{
            others: canEditOffers ? (
              <CabalButton variant="link" onClick={() => openAddOfferModal(false)}>
                Add Resource
              </CabalButton>
            ) : isLoggedIn ? (
              <CabalButton variant="link" onClick={() => openAddOfferModal(true)}>
                Propose Resource
              </CabalButton>
            ) : null,
          }}
          hideBackButton={isAdvisor ? false : true}
          onBack={() => history.goBack()}
        />
      )}
      {isFetching && !homeView && <Loading />}
      {!isFetching && (
        <div className="flex flex-col">
          <ItemCardV2
            itemView={{
              title: offerTypeLabel('Vendors', teamSlug),
              main_icon: 'store fa-light fa-fw',
              title_url: `/${teamSlug}/resources/vendors`,
              description_parts: [
                {
                  id: 'count',
                  type: 'string',
                  label: `${vendors?.length || 0} items`,
                },
              ],
              on_click: {
                type: 'internal_url',
                url: `/${teamSlug}/resources/vendors`,
                label: 'View',
                icon: 'far fa-chevron-right',
                include_query_params: true,
              },
            }}
            teamSlug={teamSlug}
          />

          <ItemCardV2
            itemView={{
              title: offerTypeLabel('People', teamSlug),
              main_icon: 'people-group fa-light fa-fw',
              title_url: `/${teamSlug}/resources/people`,
              description_parts: [
                {
                  id: 'count',
                  type: 'string',
                  label: `${people?.length || 0} items`,
                },
              ],
              on_click: {
                type: 'internal_url',
                url: `/${teamSlug}/resources/people`,
                label: 'View',
                icon: 'far fa-chevron-right',
                include_query_params: true,
              },
            }}
            teamSlug={teamSlug}
          />

          <ItemCardV2
            itemView={{
              title: offerTypeLabel('Discounts', teamSlug),
              main_icon: 'percent fa-light fa-fw',
              title_url: `/${teamSlug}/resources/deals`,
              description_parts: [
                {
                  id: 'count',
                  type: 'string',
                  label: `${deals?.length || 0} items`,
                },
              ],
              on_click: {
                type: 'internal_url',
                url: `/${teamSlug}/resources/deals`,
                label: 'View',
                icon: 'far fa-chevron-right',
                include_query_params: true,
              },
            }}
            teamSlug={teamSlug}
          />

          <ItemCardV2
            itemView={{
              title: offerTypeLabel('Content', teamSlug),
              main_icon: 'files fa-light fa-fw',
              title_url: `/${teamSlug}/resources/resources`,
              description_parts: [
                {
                  id: 'count',
                  type: 'string',
                  label: `${resources?.length || 0} items`,
                },
              ],
              on_click: {
                type: 'internal_url',
                url: `/${teamSlug}/resources/resources`,
                label: 'View',
                icon: 'far fa-chevron-right',
                include_query_params: true,
              },
            }}
            teamSlug={teamSlug}
          />

          {canEditOffers && (
            <ItemCardV2
              itemView={{
                title: offerTypeLabel('Proposed Resources', teamSlug),
                main_icon: 'hand-holding-box fa-light fa-fw',
                title_url: `/${teamSlug}/resources/proposed`,
                description_parts: [
                  {
                    id: 'count',
                    type: 'string',
                    label: `${proposed?.length || 0} items`,
                  },
                ],
                on_click: {
                  type: 'internal_url',
                  url: `/${teamSlug}/resources/proposed`,
                  label: 'View',
                  icon: 'far fa-chevron-right',
                  include_query_params: true,
                },
              }}
              teamSlug={teamSlug}
            />
          )}
        </div>
      )}
    </PageWrapper>
  )
}

export default Resources
