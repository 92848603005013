import React, { useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import CompanyHome from 'components/CompanyHome'
import { useComposer } from 'components/Composer'
import { EditListModal } from 'components/EditList'
import CompanyHomeV2 from 'containers/CompanyHomeV2'
import CompanyPage from 'containers/CompanyPage'
import GlobalProfile from 'containers/GlobalProfile'
import EditGroupsModal from 'containers/Groups/EditGroupsModal'
import { useAccessControl } from 'global/AccessControl'
import Loading from 'global/Loading'
import { RenderModal, useModal } from 'global/Modal/Context'
import { useGroups, useTeam } from 'store/hooks'

import { useSlug } from 'utils/hooks/useSlug'

const NavHome = () => {
  const { showModal } = useModal()
  const history = useHistory()

  const { slugType, isLoading: slugLoading, teamSlug } = useSlug()
  const { team, isLoading: teamLoading } = useTeam(teamSlug)
  const { isAdminOrEmployee, isAdvisor } = useAccessControl(teamSlug)

  const location = useLocation()
  const hash = location.hash
  const { compose } = useComposer(teamSlug)
  const { reloadGroups } = useGroups(teamSlug)

  const isLoading = slugLoading || teamLoading

  const renderGroupAddModal: RenderModal = (resolve) => (
    <EditGroupsModal
      teamSlug={teamSlug!}
      onHide={() => resolve(false)}
      onSave={() => {
        reloadGroups()
      }}
      onDelete={() => {
        reloadGroups()
        resolve(false)
      }}
    />
  )

  const renderNewListModal: RenderModal = (resolve) => (
    <EditListModal onHide={() => resolve(false)} teamSlug={teamSlug!} />
  )

  useEffect(() => {
    if (!hash) return

    if (hash === '#composer_modal') {
      compose()
    } else if (hash === '#groups_modal') {
      showModal(renderGroupAddModal, 'render_group_add_modal')
    } else if (hash === '#list_modal') {
      showModal(renderNewListModal, 'render_new_list_modal')
    }

    if (['#composer_modal', '#groups_modal', '#list_modal'].includes(hash)) {
      const newUrl = new URL(window.location.href)
      newUrl.hash = ''
      history.replace(newUrl.href.replace(newUrl.origin, ''))
    }
  }, [])

  if (isLoading) {
    return (
      <div className="my-10">
        <Loading />
      </div>
    )
  }

  if (slugType === 'Team') {
    if (!team) return <></>

    if (team.features?.home_v2) {
      if (isAdvisor && !isAdminOrEmployee) {
        return <CompanyPage />
      } else {
        return <CompanyHomeV2 />
      }
    } else {
      return <CompanyHome isHome />
    }
  } else {
    return <GlobalProfile />
  }
}

export default NavHome
