import React, { useState } from 'react'
import { CheckBox, Label, Select, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import CabalButton from 'global/CabalButton'
import api, { callApi } from 'utils/api'
import { cabalToast } from 'ui-components/Toast'
import { CompanyListType, ExternalSource } from 'utils/types'
import { useMutation } from 'react-query'

interface Props {
  resolve: () => void
  teamSlug: string
  source?: ExternalSource
}

const AddExternalSourceModal: React.VFC<Props> = ({ source = {}, resolve, teamSlug }) => {
  const defaultValues = {
    provider: 'airtable',
    cabal_type: 'members',
  }

  const [data, setData] = useState<ExternalSource | null>({ ...defaultValues, ...source })
  const [companyLists, setCompanyLists] = useState<CompanyListType[]>([])

  React.useEffect(() => {
    api.getCompanyLists(teamSlug, true).then(({ data: { company_lists } }) => {
      const sortedCompanyLists = company_lists.sort((a: CompanyListType, b: CompanyListType) =>
        a.name > b.name ? 1 : -1,
      )
      setCompanyLists(sortedCompanyLists)
    })
  }, [])

  const { mutate: handleAdd, isLoading } = useMutation(
    () => callApi(api.addExternalSource, data, teamSlug),
    {
      onSuccess: ({ app }) => {
        cabalToast({ style: 'success', content: 'The external source was successfully added!' })
        resolve()
      },
      onError: (error) => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const { mutate: handleUpdate, isLoading: isUpdating } = useMutation(
    () => callApi(api.updateExternalSource, data, teamSlug),
    {
      onSuccess: ({ app }) => {
        cabalToast({ style: 'success', content: 'The external source was successfully updated!' })
        resolve()
      },
      onError: (error) => {
        alert(error)
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const onSave = () => {
    if (source.uuid) {
      handleUpdate()
    } else {
      handleAdd()
    }
  }

  const disabled = isLoading || isUpdating || !data.external_id || !data.table_name

  return (
    <Modal
      show
      onHide={resolve}
      header={source.uuid ? 'Edit Airtable App' : 'Add Airtable App'}
      rightActions={
        <CabalButton onClick={onSave} working={isLoading || isUpdating} disabled={disabled}>
          Save
        </CabalButton>
      }
    >
      <Label label="Source">
        <Select<string>
          className="mb-3"
          options={[{ label: 'Airtable', value: 'airtable' }]}
          defaultValue={data.provider}
          value={data.provider}
          onChange={(e) => e && setData({ ...data, provider: e })}
          placeholder="Select Source"
        />
      </Label>
      <Label label="Destination">
        <Select<string>
          className="mb-3"
          options={[
            { label: 'Members', value: 'members' },
            { label: 'Portfolio Companies', value: 'portfolio_companies' },
            // { label: "Company List", value: "company_list" }
          ]}
          defaultValue={data.cabal_type}
          value={data.cabal_type}
          onChange={(e) => e && setData({ ...data, cabal_type: e })}
          placeholder="Select Destination"
        />
      </Label>
      {data.cabal_type == 'company_list' && (
        <Label label="Company List">
          <Select<string>
            className="mb-3"
            options={companyLists.map((c) => ({
              label: c.name,
              value: c.uuid,
            }))}
            defaultValue={data.destination_uuid}
            value={data.destination_uuid}
            onChange={(e) => e && setData({ ...data, company_list_uuid: e })}
            isLoading={!companyLists}
            placeholder="Select Company List"
          />
        </Label>
      )}
      <TextInput
        value={data.external_id}
        onChange={(e) => setData({ ...data, external_id: e.target.value })}
        placeholder="App ID"
        className="mb-3"
        valueType="non-empty"
        labeled
      />
      <TextInput
        value={data.uuid ? '******' : data.api_key}
        onChange={(e) => {
          !data.uuid && setData({ ...data, api_key: e.target.value })
        }}
        placeholder={'API Key'}
        className="mb-3"
        valueType="non-empty"
        disabled={!!data.uuid}
        labeled
      />
      <TextInput
        value={data.table_name}
        onChange={(e) => setData({ ...data, table_name: e.target.value })}
        placeholder="Table Name"
        className="mb-3"
        valueType="non-empty"
        labeled
      />
      {source.uuid && (
        <CheckBox
          checked={data.enabled}
          onChange={(e) => setData({ ...data, enabled: e.target.checked })}
          label="Enable sync every 30 minutes"
        />
      )}
    </Modal>
  )
}

export default AddExternalSourceModal
