import moment from 'moment'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import Tooltip from 'global/Tooltip'

interface Props {
  datetime: string | Date
  short?: boolean
}

const TimeAgo: React.VFC<Props> = ({ datetime, short = false }) => {
  if (typeof datetime === 'string') datetime = new Date(datetime)
  datetime ||= new Date(0)

  const [output, setOutput] = useState<string>()
  const intervalRef = useRef<number>()
  const epochRef = useRef<number>()

  useEffect(() => {
    return () => {
      window.clearInterval(intervalRef.current)
    }
  }, [])

  const updateTime = useCallback(() => {
    const currentEpoch = new Date().getTime() / 1000
    const epoch = epochRef.current

    if (epoch === undefined) return

    let updatedOutput = ''
    let ago = ' ago'
    if (short) ago = ''

    let s = ' '
    if (short) s = ''

    if (currentEpoch - epoch < 60) {
      updatedOutput = short ? 'just now' : '< 1 minute ago'
    } else if (currentEpoch - epoch < 3600) {
      const val = Math.floor((currentEpoch - epoch) / 60)
      updatedOutput = `${val}${s}${short ? 'm' : pluralize('minute', val)}${ago}`
    } else if (currentEpoch - epoch < 86400) {
      const val = Math.floor((currentEpoch - epoch) / 3600)
      updatedOutput = `${val}${s}${short ? 'h' : pluralize('hour', val)}${ago}`
    } else if (currentEpoch - epoch < 604800) {
      const val = Math.floor((currentEpoch - epoch) / 86400)
      updatedOutput = `${val}${s}${short ? 'd' : pluralize('day', val)}${ago}`
    } else if (currentEpoch - epoch < 2592000) {
      const val = Math.floor((currentEpoch - epoch) / 604800)
      updatedOutput = `${val}${s}${short ? 'w' : pluralize('week', val)}${ago}`
    } else if (currentEpoch - epoch < 31536000) {
      const val = Math.floor((currentEpoch - epoch) / 2592000)
      updatedOutput = `${val}${s}${short ? 'M' : pluralize('month', val)}${ago}`
    } else {
      const val = Math.floor((currentEpoch - epoch) / 31536000)
      updatedOutput = `${val}${s}${short ? 'Y' : pluralize('year', val)}${ago}`
    }

    if (output !== updatedOutput) {
      setOutput(updatedOutput)
    }
  }, [output])

  const scheduleUpdateTime = useCallback(() => {
    window.clearInterval(intervalRef.current)
    intervalRef.current = window.setInterval(updateTime, 30 * 1000)
    updateTime()
  }, [updateTime])

  useEffect(() => {
    epochRef.current = (datetime as Date).getTime() / 1000

    scheduleUpdateTime()
  }, [datetime, scheduleUpdateTime])

  return (
    <Tooltip label={moment(datetime as Date).format('llll')} delay={[700, 0]}>
      {output}
    </Tooltip>
  )
}

export default TimeAgo
