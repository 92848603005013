import React from 'react'

import { TeamSettings } from 'utils/types'

import IntegrationCard from './IntegrationCard'
import hubspotImage from '../../../../assets/images/hubspot-200.png'
import { DataCard } from 'ui-components/DataCard'

interface Props {
  settings: TeamSettings
}

const HubspotIntegrationCard: React.VFC<Props> = ({ settings }) => {
  const navigateToAuth = () => {
    if (window.location.host === 'localhost:3000') {
      window.location.assign(`/auth/hubspotdev?return_to=${window.location.href}`)
    } else {
      window.location.assign(`/auth/hubspot?return_to=${window.location.href}`)
    }
  }

  return (
    <>
      <DataCard
        title="HubSpot"
        description={'Import your contacts and deals from Hubspot.'}
        avatarUrl={hubspotImage}
        secondaryCTA={{
          text: settings.team_connected_hubspot ? 'Reauthorize' : 'Authorize',
          onClick: () => navigateToAuth(),
        }}
      />
    </>
  )
}

export default HubspotIntegrationCard
