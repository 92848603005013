import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { debounce } from 'lodash'
import pluralize from 'pluralize'
import React, { useCallback, useState } from 'react'
import api from 'utils/api'
import { PresendCheckReponse } from 'utils/types'

interface Props {
  onHide: (props?: { ignoreVars?: boolean; cancel?: boolean }) => void
  presendCheckResponse: PresendCheckReponse
}

const PresendCheckFailureModal: React.VFC<Props> = ({
  onHide,
  presendCheckResponse: { unknown_vars: unknownVars, name_missing: nameMissing },
}) => {
  const showUserNameField = nameMissing
  const showUnknownVariables = !!unknownVars.length

  const [name, setName] = useState('')

  const onNameUpdate = useCallback((val: string) => {
    setName(val)
    api.updateCurrentProfile({ name: val })
  }, [])

  const debouncedOnNameUpdate = React.useCallback(debounce(onNameUpdate, 600), [onNameUpdate])

  const header = 'Before we send that…'
  const buttons = [
    <CabalButton
      key="send-anyway-button"
      variant="primary"
      disabled={nameMissing && !name}
      tooltip={nameMissing && !name ? 'Name is required to continue' : undefined}
      onClick={() => onHide({ ignoreVars: true })}
      className="mr-3"
    >
      Send anyway
    </CabalButton>,
    <CabalButton
      key="cancel-button"
      variant="secondary"
      onClick={() => onHide({ cancel: true })}
      tabIndex={1}
    >
      Cancel
    </CabalButton>,
  ]

  return (
    <Modal show onHide={() => onHide({ cancel: true })} header={header} leftActions={buttons}>
      <div className="flex flex-col gap-5">
        {showUnknownVariables && (
          <div data-testid="unknown-variable-message">
            You wrote {`"`}
            {unknownVars
              .map<React.ReactNode>((v) => <Typography key={v}>{`{{${v}}}`}</Typography>)
              .reduce((prev, curr) => [prev, ', ', curr])}
            {`" but we don't`} support {pluralize('this', unknownVars.length)}{' '}
            {pluralize('variable', unknownVars.length)}.<br /> If you send it as-is, your recipients
            will see the variable string unreplaced.
          </div>
        )}
        {showUserNameField && (
          <div className="rounded border border-light dark:border-dark p-3">
            <TextInput
              label={<Typography fontWeight={600}>Fill in your full name</Typography>}
              placeholder="Your full Name"
              className="w-full"
              onChange={(e) => {
                const v = e.currentTarget.value
                setName(v)
                debouncedOnNameUpdate(v)
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default PresendCheckFailureModal
