import * as React from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

import { StyleProps } from 'utils/types'

import CTA from './CTA'

const Wrapper = styled.div`
  ${tw`w-full pb-1 pt-5 flex items-center justify-between`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
`

export interface textCTAProps {
  label: string
  onClick: () => void
}

export interface BreadcrumbProps {
  title: string
  url: string
}

export interface Props extends StyleProps {
  title: string
  hideTopBorder?: boolean
  hideBottomBorder?: boolean
  textCTA?: textCTAProps
  breadcrumbs?: BreadcrumbProps[]
}

const SectionHeader: React.VFC<Props> = ({
  title,
  hideTopBorder = false,
  hideBottomBorder = false,
  textCTA,
  breadcrumbs = [],
  ...extraProps
}) => {
  return (
    <Wrapper {...extraProps}>
      <div>
        {breadcrumbs?.map((breadcrumb, index) => (
          <>
            <Typography fontSize="14" fontWeight={500} lineHeight={1.2}>
              {breadcrumb.url ? (
                <Link to={breadcrumb.url}>{breadcrumb.title}</Link>
              ) : (
                breadcrumb.title
              )}
            </Typography>
            <i className="mx-2 fa fa-chevron-right cursor-pointer" onClick={() => {}} />
          </>
        ))}

        <Typography fontSize="14" fontWeight={600} lineHeight={1.2}>
          {title}
        </Typography>
      </div>
      <div>{textCTA && <CTA textCTA={textCTA} />}</div>
    </Wrapper>
  )
}

export default SectionHeader
