import axios from 'axios'
import { CompanyListFilter } from 'utils/types'

export default {
  exportSalesList: (itemUuids: string[], listUuid: string) => {
    return axios.post(`/api/v2/sales_list/${listUuid}/export`, { item_uuids: itemUuids })
  },
  addElementToSalesList: (
    listUuid: string,
    data: {company_uuid: string} |{ item_uuid: string } | { linkedin_url: string } | { name: string; domain: string },
  ) => {
    return axios.post(`/api/v2/sales_list/${listUuid}/add`, {
      ...data,
    })
  },
  removeElementFromSalesList: (listUuid: string, itemUuid: string[]) => {
    return axios.post(`/api/v2/sales_list/${listUuid}/remove`, { uuids: itemUuid })
  },
  importSales: (uploadUrl: string, uploadUuid: string, listUuid: string) => {
    return axios.post(`/api/v2/sales_list/${listUuid}/import`, {
      upload_url: uploadUrl,
      upload_uuid: uploadUuid,
    })
  },
  importSalesStatus: (importJobId: number, listUuid: string) => {
    return axios.get(`/api/v2/sales_list/${listUuid}/import_status?import_job_id=${importJobId}`)
  },
  enableDynamicList: (
    listUuid: string,
    data: {
      owner_emails: string[]
      stage_names: string[]
      pipeline_names: string[]
      company_list_filters: CompanyListFilter[]
    },
  ) => {
    return axios.put(`/api/v2/sales_list/${listUuid}`, data)
  },
  salesGetConnections: (uuid: string, company_uuid: string ) => {
    return axios.get(`/api/v2/sales_list/${uuid}/connections?company_uuid=${company_uuid}`)
  },
}
