import * as React from 'react'

import filesize from 'filesize'
import { isEqual } from 'lodash'
import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import { UploadBlueprint } from 'utils/types'

const AttachmentContainer = styled.div`
  ${tw`cursor-pointer px-2 py-1 rounded block mr-1 mb-1`}
  border: ${({ theme }) => theme.border};
`

const Wrapper = styled.div`
  ${tw`flex flex-wrap`}
`

const Close = styled(Typography)`
  &:hover {
    color: ${({ theme }) => theme.colors.fog_rain};
  }
`

interface AttachmentProps {
  attachment: UploadBlueprint
  onDelete?: (a: UploadBlueprint) => void
}

const Attachment: React.FC<AttachmentProps> = ({ attachment, onDelete }) => {
  const onClick = () => {
    if (window.QA) {
      cabalToast({ content: attachment.api_url })
    } else {
      window.open(attachment.api_url)
    }
  }

  return (
    <AttachmentContainer
      onClick={onClick}
      data-testid="uploaded-attachment"
      className="leading-5 flex items-center"
    >
      <Typography fontSize="14" color="secondary">
        {attachment.file_name}
      </Typography>
      <Typography fontSize="12" className="ml-1" color="secondary">
        {attachment.file_size && `(${filesize(attachment.file_size)})`}
      </Typography>
      {onDelete && (
        <Close
          onClick={(e) => {
            e.stopPropagation()
            onDelete(attachment)
          }}
          fontSize="12"
          className="ml-4"
          color="rain_fog"
          component="button"
          data-testid="delete-attachment"
        >
          <i className="far fa-times"></i>
        </Close>
      )}
    </AttachmentContainer>
  )
}

interface AttachmentsProps {
  attachments: UploadBlueprint[]
  onDelete?: (a: UploadBlueprint) => void
}

const Attachments: React.FC<AttachmentsProps> = ({ attachments, onDelete }) => {
  if (attachments.length == 0) {
    return <div />
  }

  return (
    <Wrapper>
      {attachments.map((attachment) => (
        <Attachment attachment={attachment} key={attachment.upload_uuid} onDelete={onDelete} />
      ))}
    </Wrapper>
  )
}

export default React.memo(Attachments, isEqual)
