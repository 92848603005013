import React, { useMemo } from 'react'

import { useInfiniteQuery, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params'

import ActivityRow from 'components/Activity/ActivityRow'
import CommentsAndActivity from 'components/CommentsAndActivity/v2'
import GetIntroModal from 'components/GetIntro/GetIntroModal'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import { WidthWrapper } from 'containers/ItemDetailPage/ViewItem'
import EmptyState from 'global/EmptyState'
import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { Activity } from 'utils/types'

export interface Props {}

const GlobalPerson: React.FC<Props> = ({}) => {
  const { id, company_slug: teamSlug } = useParams<{ id: string }>()
  const history = useHistory()

  const queryParamConfigMap = useMemo(
    () => ({
      type: ArrayParam,
      user: ArrayParam,
      time: StringParam,
      company: StringParam,
      kanban: StringParam,
    }),
    [],
  )
  const [filters, setFilters] = useQueryParams(queryParamConfigMap)

  const params = { team: teamSlug }
  const { data: personData, isLoading: personIsLoading } = useQuery(['getPerson', id], () =>
    callApi(api.getPerson, id, params),
  )
  const { global_person } = personData || {}

  const {
    data: activityData,
    refetch: refetchActivity,
    isLoading: activityIsLoading,
  } = useInfiniteQuery(
    ['getMemberActivity', teamSlug, filters, id],
    ({ pageParam: page = 1 }) =>
      callApi(
        api.getMemberActivity,
        teamSlug,
        'team_activities',
        undefined,
        {
          ...filters,
          global_person_uuid: id,
        },
        page,
      ),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      enabled: !!id,
    },
  )

  const connQueryKey = [teamSlug, 'connections', id]

  const { data: connectionsData, isLoading: connectionsIsLoading } = useInfiniteQuery(
    connQueryKey,
    ({ pageParam: page = 1 }) =>
      callApi(api.getConnections, [], undefined, [], teamSlug, false, page, null, {
        global_person_uuid: id,
      }),
    {
      cacheTime: 0,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
    },
  )

  const activities = activityData?.pages?.flatMap((page) => page.activity) ?? []
  const people = connectionsData?.pages?.flatMap((page) => page.people) ?? []

  return (
    <PageWrapper title={global_person?.name}>
      {personIsLoading && <Loading />}
      {!personIsLoading && (
        <>
          <PageHeader
            data={{
              title: global_person?.name,
              description_parts: [
                {
                  id: 'headline',
                  type: 'text',
                  label: global_person?.headline,
                },
              ],
            }}
            hideBackButton={false}
            onBack={() => history.goBack()}
          />
          <div className="flex mt-3 gap-2">
            <WidthWrapper className="flex flex-col gap-2">
              <Widget title="Connections" dataTestId="widget-connections" classNames="mb-2">
                {!connectionsIsLoading && (
                  <GetIntroModal
                    person={global_person}
                    advisors={people[0]?.advisors || []}
                    teamSlug={teamSlug}
                    modal={false}
                    onHide={() => refetchActivity()}
                  />
                )}
                {!connectionsIsLoading && (people[0]?.advisors?.length || 0) == 0 && (
                  <EmptyState heading={'No connections'} />
                )}
              </Widget>
              <Widget title="Activity" dataTestId="widget-activity">
                {activities.length > 0
                  ? activities.map((activity: Activity) => (
                      <div key={activity.uuid}>
                        <ActivityRow
                          key={activity.uuid}
                          activity={activity}
                          refetchActivities={refetchActivity}
                        />
                      </div>
                    ))
                  : !activityIsLoading && <EmptyState heading={'No activity'} />}
              </Widget>
            </WidthWrapper>
            <div className="p-2 pt-0">
              <div className="w-80 flex-shrink-0">
                <Widget title="Activity">
                  <CommentsAndActivity
                    attachableType={'GlobalPerson'}
                    attachableId={id}
                    teamSlug={teamSlug}
                  />
                </Widget>
              </div>
            </div>
          </div>
        </>
      )}
    </PageWrapper>
  )
}

export default GlobalPerson
