import { useCallback, useEffect } from 'react'

type PostMessage = {
  type: 'pageLoaded' | 'themeChange' | 'logout'
  data?: unknown
}

type ReactNativeWebViewEvent = {
  type: 'themeChange'
  data?: string
}

export const IS_MOBILE_APP = !!window.ReactNativeWebView && window.MOBILE_APP
export const HAS_ASSET_CACHE = !!window.MOBILE_APP_HAS_ASSET_CACHE

const postMessage = (payload: PostMessage) => {
  if (!IS_MOBILE_APP) return

  window.ReactNativeWebView.postMessage(JSON.stringify(payload))
}

interface UseMobileAppConfig {
  onThemeChange?: (theme: string) => void
}

const useMobileApp = (config?: UseMobileAppConfig) => {
  const isMobileApp = IS_MOBILE_APP

  const handleMessage = useCallback((nativeEvent: CustomEvent<string>) => {
    const event = JSON.parse(nativeEvent.detail) as ReactNativeWebViewEvent

    switch (event.type) {
      case 'themeChange':
        if (event.data && config?.onThemeChange) {
          config.onThemeChange(event.data)
        }
        return
    }
  }, [])

  useEffect(() => {
    if (!isMobileApp) return

    window.addEventListener('reactNativeWebViewEvents', handleMessage as EventListener)

    return () => {
      window.removeEventListener('reactNativeWebViewEvents', handleMessage as EventListener)
    }
  }, [])

  return { isMobileApp, postMessage: postMessage }
}

export default useMobileApp
