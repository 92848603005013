import Templates from 'utils/templates'
import { CurrentUserProfile, Params, RequestModel, User } from 'utils/types'

export const replaceTemplateVariables = (
  template: string,
  templateVariables: Record<string, string>,
) => {
  Object.keys(templateVariables).forEach((key) => {
    template = template.replace(`[${key}]`, templateVariables[key])
  })
  return template
}

export const getInitialSubject = (
  defaultSubject?: string,
  defaultTemplate?: keyof typeof Templates,
  templateVariables?: Record<string, string>,
) => {
  if (defaultSubject) {
    return defaultSubject
  }
  if (defaultTemplate && templateVariables) {
    return replaceTemplateVariables(Templates[defaultTemplate].subject, templateVariables)
  }
  return ''
}

export const getInitialBody = (
  defaultBody?: string,
  defaultTemplate?: keyof typeof Templates,
  templateVariables?: Record<string, string>,
  email_signature?: string | null,
) => {
  if (defaultBody) {
    return defaultBody
  }
  if (defaultTemplate && templateVariables) {
    return replaceTemplateVariables(Templates[defaultTemplate].body, templateVariables)
  }
  if (email_signature) {
    return `
    <p><br/></p>
    <p><br/></p>
    <p>--</p>
    ${email_signature}
    `
  }
  return ''
}

export const replaceVariables = (
  string: string,
  props: { request?: RequestModel; params?: Params; user?: CurrentUserProfile },
) => {
  if (!string) return string

  if (props.user) string = string.replaceAll('{{referral_link}}', props.user?.referral_link || '')

  if (props.request) {
    string = string
      .replaceAll('{{connection_company}}', props.request?.company?.name || '')
      .replaceAll(
        '{{connection_full_name}}',
        props.request?.data?.person_name ||
          props.request?.person?.name ||
          '{{connection_full_name}}',
      )
      .replaceAll(
        '{{connection_first_name}}',
        props.request?.person?.first_name || '{{connection_first_name}}',
      )
      .replaceAll(
        '{{connection_linkedin_url}}',
        props.request?.data?.person_linkedin_url ||
          props.request?.person?.linkedin_url ||
          '{{connection_linkedin_url}}',
      )
  }

  if (props.params) {
    for (const param in props.params) {
      string = string.replaceAll(`{{${param}}}`, props.params[param])
    }
  }

  return string
}
