import React, { useEffect, useMemo, useState } from 'react'

import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import PortfolioJobsEmptyState from 'components/EmptyStates/PortfolioJobsEmptyState'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import { useAccessControl } from 'global/AccessControl'
import EmptyState from 'global/EmptyState'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { PortfolioJobsFiltersType } from 'utils/types'
import { InvestorCompany, InvestorCompanyJob } from 'utils/types/investor'

import AddJobModal from '../AddJobModal'
import Subtabs from '../Subtabs'
import TableHeader from '../TableHeader'
import Filters from './Filters'
import GlobalActions from './GlobalActions'
import JobRow from './JobRow'

const FilterWrapper = styled.div`
  position: sticky;
  z-index: 10;
  top: 66px;
  background-color: ${({ theme }) => theme.layout.main_content_bg_color};
`

interface Props {
  companies: InvestorCompany[]
  jobs: InvestorCompanyJob[]
  setJobs: (jobs: InvestorCompanyJob[]) => void
  isLoading: boolean
  loadingCompanies: boolean
  isFetching: boolean
}

const JobsTab: React.VFC<Props> = ({
  companies,
  jobs,
  setJobs,
  isLoading,
  isFetching,
  loadingCompanies,
}) => {
  const { showModal } = useModal()
  const teamSlug = useTeamSlug()
  const [filters, setFilters] = useState<PortfolioJobsFiltersType>({})
  const [selectedUuids, setSelectedUuids] = useState<Set<string>>(new Set())
  const [sortedBy, setSortedBy] = useState<string | null>(null)
  const [isSortedDesc, setIsSortedDesc] = useState(true)
  const [openArchive, setOpenArchive] = useState(false)
  const { canEditPortfolio } = useAccessControl(teamSlug)
  const [filterSelected, setFilterSelected] = useState(false)

  const resetSelectedUuids = () => {
    setSelectedUuids(new Set())
  }

  useEffect(() => {
    resetSelectedUuids()
  }, [openArchive])

  const [allTags, setAllTags] = useState<string[]>([])

  const toggleSelectedJob = (uuid: string, selected: boolean) => {
    const updatedSelectedUuids = new Set(selectedUuids)
    if (selectedUuids.has(uuid)) {
      if (!selected) {
        updatedSelectedUuids.delete(uuid)
        setSelectedUuids(updatedSelectedUuids)
      }
    } else {
      if (selected) {
        updatedSelectedUuids.add(uuid)
        setSelectedUuids(updatedSelectedUuids)
      }
    }
  }

  const { isFetching: isFetchingJobTags } = useQuery(
    ['getJobTags'],
    () => callApi(api.getJobTags, teamSlug),
    {
      onSuccess: ({ tags }) => {
        setAllTags(tags)
      },
    },
  )

  const refresh = (job: InvestorCompanyJob) => {
    if (job) {
      const updatedJobs = jobs.map((j) => {
        if (j.uuid === job.uuid) {
          return job
        }
        return j
      })
      setJobs(updatedJobs)
    }
  }

  const cloneJob = (job?: InvestorCompanyJob) => {
    openJobModal(job, true)
  }

  const openJobModal = (job?: InvestorCompanyJob, isCloning?: boolean) => {
    showModal(
      (resolve) => (
        <AddJobModal
          companies={companies as InvestorCompany[]}
          resolve={resolve}
          allTags={allTags}
          setAllTags={setAllTags}
          isFetchingJobTags={isFetchingJobTags}
          onAdd={(job) => {
            if (job) {
              setJobs([...jobs, job])
            }
            resolve()
          }}
          teamSlug={teamSlug}
          onEdit={(job) => {
            refresh(job)
            resolve()
          }}
          onDelete={(job) => {
            if (job) {
              const updatedJobs = jobs.filter((j) => j.uuid !== job.uuid)
              setJobs(updatedJobs)
            }
            resolve()
          }}
          refresh={refresh}
          job={job}
          isCloning={isCloning}
        />
      ),
      'investor-company-job-modal',
    )
  }

  const filteredJobs = useMemo(() => {
    const isFilterSelected = !every(filters, isEmpty)

    let selectedJobs = jobs

    if (!!filters.search?.length) {
      selectedJobs = selectedJobs?.filter((job: InvestorCompanyJob) => {
        for (const i in filters.search) {
          const query = filters.search[i]

          if (
            job.title?.toLowerCase().includes(query.toLowerCase()) ||
            job.investor_company.company_name.toLowerCase().includes(query.toLowerCase())
          ) {
            return true
          }
        }
      })
    }

    if (!!filters.tags?.length) {
      selectedJobs = selectedJobs.filter((job: InvestorCompanyJob) => {
        for (const i in filters.tags) {
          const tag = filters.tags[i]
          if (job.tags.includes(tag)) {
            return true
          }
        }
      })
    }

    if (!!filters.company?.length) {
      selectedJobs = selectedJobs.filter((job: InvestorCompanyJob) => {
        for (const i in filters.company) {
          const uuid = filters.company[i]
          if (job.investor_company.uuid === uuid) {
            return true
          }
        }
      })
    }

    if (!!filters.talent_lead?.length) {
      selectedJobs = selectedJobs.filter((job: InvestorCompanyJob) => {
        for (const i in filters.talent_lead) {
          const uuid = filters.talent_lead[i]
          if (job.talent_lead === uuid) {
            return true
          }
        }
      })
    }

    setFilterSelected(isFilterSelected)
    return selectedJobs.length > 0 || isFilterSelected ? selectedJobs : jobs
  }, [filters, jobs])

  const sortedJobs = useMemo(
    () =>
      filteredJobs.sort((a, b) => {
        let res = 0
        if (sortedBy === 'job') {
          res = a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1
        }
        if (!isSortedDesc) {
          res *= -1
        }
        return res
      }),
    [filteredJobs, isSortedDesc, sortedBy],
  )

  const archivedJobs = useMemo(
    () => sortedJobs.filter((j) => j.archived),
    [filteredJobs, isSortedDesc, sortedBy],
  )
  const notArchivedJobs = useMemo(
    () => sortedJobs.filter((j) => !j.archived),
    [filteredJobs, isSortedDesc, sortedBy],
  )

  const visibleJobs = openArchive ? archivedJobs : notArchivedJobs

  const selectedAll = visibleJobs.every((j) => selectedUuids.has(j.uuid)) && visibleJobs.length > 0

  return (
    <>
      <PageHeader
        data={{
          title: 'All Jobs',
        }}
        actions={{}}
        backUrl={`/${teamSlug}/lists`}
      />
      <FilterWrapper className="flex justify-between w-full pb-2 -mt-2">
        <Filters
          setFilters={setFilters}
          filters={filters}
          allTags={allTags}
          companies={companies}
          loadingTags={isFetchingJobTags}
          loadingCompanies={loadingCompanies}
          teamSlug={teamSlug}
        />
        <GlobalActions
          companies={companies}
          openArchive={openArchive}
          setOpenArchive={setOpenArchive}
          openJobModal={openJobModal}
          selectedUuids={selectedUuids}
          allJobs={jobs}
          jobs={visibleJobs}
          setJobs={setJobs}
          resetSelectedUuids={resetSelectedUuids}
        />
      </FilterWrapper>

      {(isLoading || isFetching) && <Loading className="mt-10" />}

      {!isLoading && !isFetching && (
        <div className="flex flex-col gap-1 mb-10">
          <TableHeader
            sortedBy={sortedBy}
            selectedAll={selectedAll}
            onToggleSelectAll={(v) =>
              setSelectedUuids(new Set(v ? visibleJobs.map((j) => j.uuid) : []))
            }
            isSortedDesc={isSortedDesc}
            onSortBy={(f) => {
              if (sortedBy !== f) {
                setSortedBy(f)
                setIsSortedDesc(true)
              } else {
                if (isSortedDesc) {
                  setIsSortedDesc(false)
                } else {
                  setSortedBy(null)
                  setIsSortedDesc(true)
                }
              }
            }}
            headers={[{ label: 'Job', value: 'job' }]}
          />
          {filterSelected &&
            ((openArchive && archivedJobs.length === 0) ||
              (!openArchive && notArchivedJobs.length === 0)) && (
              <Typography className="text-center" data-testid="portfolio-jobs-no-results-found">
                No results found
              </Typography>
            )}

          {openArchive && archivedJobs.length === 0 && !filterSelected && (
            <div className="mt-4" data-testid="portfolio-jobs-empty-archived-state">
              <EmptyState heading={'No jobs archived'} body={'Archived jobs will appear here.'} />
            </div>
          )}

          {!openArchive && notArchivedJobs.length === 0 && !filterSelected && (
            <div className="mt-4">
              <PortfolioJobsEmptyState
                companySlug={teamSlug}
                onClick={canEditPortfolio ? () => openJobModal() : undefined}
              />
            </div>
          )}

          {openArchive &&
            archivedJobs.length > 0 &&
            archivedJobs.map((j) => (
              <JobRow
                key={j.uuid}
                job={j}
                onEdit={openJobModal}
                refresh={refresh}
                cloneJob={cloneJob}
                selectedUuids={selectedUuids}
                toggleSelectedJob={toggleSelectedJob}
              />
            ))}

          {!openArchive &&
            notArchivedJobs.length > 0 &&
            notArchivedJobs.map((j) => (
              <JobRow
                key={j.uuid}
                job={j}
                onEdit={openJobModal}
                refresh={refresh}
                cloneJob={cloneJob}
                selectedUuids={selectedUuids}
                toggleSelectedJob={toggleSelectedJob}
              />
            ))}
        </div>
      )}
    </>
  )
}

export default JobsTab
