import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '..'

export interface UniqueAccessLinkState {
  isUniqueAccessLink: boolean
  loading: boolean
  uniqueAccessLinkData: {
    recipient_first_name?: string | null
    recipient_last_name?: string | null
    sender_name?: string | null
    email?: string
  }
}

const initialState: UniqueAccessLinkState = {
  isUniqueAccessLink: false,
  loading: false,
  uniqueAccessLinkData: {},
}

export const uniqueAccessLinksSlice = createSlice({
  name: 'uniqueAccessLinks',
  initialState,
  reducers: {
    setIsUniqueAccessLink: (state, action) => {
      state.isUniqueAccessLink = action.payload
    },
    setUniqueAccessLinkData: (state, action) => {
      state.uniqueAccessLinkData = action.payload
    },
    setLoadingUniqueAccessLink: (state) => {
      state.loading = true
    },
    unsetLoadingUniqueAccessLink: (state) => {
      state.loading = false
    },
  },
})

export const {
  setIsUniqueAccessLink,
  setLoadingUniqueAccessLink,
  unsetLoadingUniqueAccessLink,
  setUniqueAccessLinkData,
} = uniqueAccessLinksSlice.actions

export const selectIsUniqueAccessLink = (state: RootState) =>
  state.uniqueAccessLinks.isUniqueAccessLink

export const selectUniqueAccessLinkData = (state: RootState) =>
  state.uniqueAccessLinks.uniqueAccessLinkData

export default uniqueAccessLinksSlice.reducer
