import React from 'react'

import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

import { useComposer } from 'components/Composer'
import EditTemplate from 'containers/GlobalMessagesPage/Templates/EditTemplate'
import { DropdownMenuItem } from 'global/DropDownMenu'
import { useModal } from 'global/Modal'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import copyToClipboard from 'utils/copyToClipboard'
import { CompanySlugParam, EmailTemplate } from 'utils/types'

import BuildTemplateLinkModal from './BuildTemplateLinkModal'

interface CardViewProps {
  template: EmailTemplate
  reloadTemplates: () => void
  teamSlug: string
}

const CardView: React.VFC<CardViewProps> = ({ template, reloadTemplates, teamSlug }) => {
  const { compose } = useComposer(teamSlug)

  const history = useHistory()
  const { showModal } = useModal()
  const renderEditModal = (resolve) => (
    <EditTemplate
      onHide={() => resolve()}
      reloadTemplates={reloadTemplates}
      templateUuid={template.uuid}
      teamSlug={teamSlug}
    />
  )

  const openBuildModal = () => {
    showModal(
      (resolve) => (
        <BuildTemplateLinkModal resolve={resolve} teamSlug={teamSlug} template={template} />
      ),
      'template-link-build-modal',
    )
  }

  const { mutate: deleteTemplate, isLoading: deletingTemplate } = useMutation(
    (uuid: string) => api.deleteTemplate(uuid),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Template deleted' })
        // history.replace(`/${company_slug}/templates`)
        reloadTemplates?.()
      },
    },
  )

  const menu: DropdownMenuItem[] = [
    {
      label: 'Edit',
      onSelect: () => showModal(renderEditModal, 'render_edit_template_modal'),
    },
    {
      label: 'Copy template link',
      onSelect: () => {
        const url = new URL(window.location.href)
        url.pathname = `/${teamSlug}/compose`
        url.searchParams.set('template_uuid', template.uuid)
        copyToClipboard(url.href)
        cabalToast({
          style: 'success',
          content: 'Copied! Use the link to share a pre drafted message',
        })
      },
      dataTestId: 'copy-temp-link-btn',
    },
    // {
    //   label: 'Build template link',
    //   onSelect: () => openBuildModal(),
    //   dataTestId: 'build-temp-link-btn',
    // },
    {
      label: 'Delete template',
      onSelect: () => {
        if (!confirm('Are you sure you want to delete this template?')) return
        deleteTemplate(template.uuid)
      },
      dataTestId: 'delete-temp-btn',
    },
  ]

  return (
    <>
      <DataCardV2
        title={template.name}
        description={
          template.public_template
            ? ['Public template']
            : !template.public_template && template.public_to_team
            ? ['Public to team']
            : ['Private template']
        }
        onClick={() => compose({ template_uuid: template.uuid })}
        // iconCTA={[
        //   {
        //     icon: 'fa-pencil',
        //     onClick: () =>
        //       deletingTemplate ? null : history.push(`/${company_slug}/templates/${template.uuid}`),
        //     tooltipLabel: 'Edit template',
        //     dataTestId: 'open-edit-template-btn',
        //   },
        // ]}
        dropDownMenu={{ menuItems: menu }}
        className="dark:border-[#2d3748] border-[#E3E5E8] border-b-[1px] rounded-none"
      />
    </>
  )
}

export default CardView
