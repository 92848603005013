import React, { useCallback, useState } from 'react'

import { useMutation } from 'react-query'
import { useMedia } from 'react-use'

import { useAppDispatch } from 'store/hooks'
import { setCurrentUser } from 'store/reducers/currentUserReducer'

import api from 'utils/api'
import useMobileApp from 'utils/hooks/useMobileApp'
import { darkModeQuery, getSystemTheme, mobileMediaQuery } from 'utils/ui'

export type Theme = 'light' | 'dark'

export const REACT_NATIVE_THEME: Theme | undefined = window.ReactNativeConfig?.colorScheme
export const USER_THEME: Theme | undefined = window.USER_THEME
export const SYSTEM_THEME = getSystemTheme()

export const PRESET_THEME = REACT_NATIVE_THEME || USER_THEME || SYSTEM_THEME
export type Platform = 'web' | 'mobile' | 'extension'

export const ColorModeContext = React.createContext<{
  theme: Theme | null
  isMobile: boolean
  isDarkMode: boolean
  isLightMode: boolean
  systemTheme: Theme
  isSystemDarkMode: boolean
  isSystemLightMode: boolean
  selectTheme: (theme: Theme, fireReactNativeEvent?: boolean) => void
  selectSystemTheme: () => void
  toggleTheme: () => void
  platform: Platform
} | null>(null)

const ColorModeProvider: React.FC<{
  theme?: Theme
  children: React.ReactNode
  platform: Platform
}> = ({ children, theme: propTheme, platform = 'web' }) => {
  const { isMobileApp, postMessage } = useMobileApp({
    onThemeChange: (newTheme: string) => {
      handleSelectTheme(newTheme as Theme, false)
    },
  })

  const dispatch = useAppDispatch()

  const [newTheme, setNewTheme] = useState<Theme | null>(null)
  const theme = propTheme || newTheme || PRESET_THEME

  const { mutateAsync: updateTheme } = useMutation(
    (theme: Theme) => api.updateCurrentProfile({ theme }),
    {
      onSuccess: ({ data: { profile } }) => {
        dispatch(setCurrentUser(profile))
      },
    },
  )

  const isDarkMode = theme === 'dark'
  const isLightMode = theme === 'light'

  const isMobile = useMedia(mobileMediaQuery)

  const isSystemDarkMode = useMedia(darkModeQuery)
  const isSystemLightMode = !isSystemDarkMode
  const systemTheme = isSystemDarkMode ? 'dark' : 'light'

  const handleSelectTheme = useCallback(
    async (theme: Theme, fireReactNativeEvent = true) => {
      setNewTheme(theme)

      if (!isMobileApp) {
        await updateTheme(theme)
      } else {
        if (fireReactNativeEvent) postMessage({ type: 'themeChange', data: theme })
      }
    },
    [isMobileApp, postMessage, updateTheme],
  )

  const handleToggleTheme = useCallback(() => {
    return handleSelectTheme(isDarkMode ? 'light' : 'dark')
  }, [handleSelectTheme, isDarkMode])

  const handleSelectSystemTheme = useCallback(() => {
    handleSelectTheme(systemTheme)
  }, [handleSelectTheme, systemTheme])

  return (
    <ColorModeContext.Provider
      value={{
        isMobile,
        isSystemDarkMode,
        isSystemLightMode,
        systemTheme,
        theme,
        isDarkMode,
        isLightMode,
        selectTheme: handleSelectTheme,
        selectSystemTheme: handleSelectSystemTheme,
        toggleTheme: handleToggleTheme,
        platform,
      }}
    >
      {children}
    </ColorModeContext.Provider>
  )
}

export default ColorModeProvider
