import React from 'react'

import { useParams } from 'react-router-dom'

import NewItemModal from './NewItemModal'
import ViewItem from './ViewItem'

const ItemDetailPage = () => {
  const { item_id } = useParams<{ item_id: string }>()

  if (item_id == 'new') {
    return <NewItemModal />
  } else {
    return <ViewItem />
  }
}

export default ItemDetailPage
