import { AdvisorModel, MessageRecipient } from './types'

export const advisorMessageRecipientToEmailMessageRecipient = (
  recipient: MessageRecipient,
  advisors: AdvisorModel[],
) => {
  if (recipient.type === 'advisor') {
    const advisor = advisors.find((a) => a.uuid === recipient.value)

    if (advisor)
      return {
        type: 'email',
        label: `${advisor?.name} - ${advisor?.email}`,
        value: {
          first_name: advisor?.first_name,
          last_name: advisor?.last_name,
          email: advisor.email,
          add_member: false,
        },
      } as MessageRecipient
  }

  return recipient
}
