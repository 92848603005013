import * as React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useHash } from 'react-use'
import Typography from 'global/Typography'
import Expand from 'react-expand-animated'
import { useCurrentUser } from 'store/hooks'
import copyToClipboard from 'utils/copyToClipboard'

const TipsItem = styled.div<{ active: boolean; userTheme: string }>`
  ${tw`mb-6 rounded-lg p-3`}
  ${({ active, theme, userTheme }) =>
    active && userTheme === 'dark'
      ? tw`bg-gray-800`
      : `background-color: ${theme.colors.primary_bg}`}
`

const CaretIcon = styled.i`
  padding: 0 0.5rem;
`

const List = styled.ul`
  ${tw`list-outside list-disc pl-4`}
  & > li {
    ${tw`pb-3`}
  }
`

export const faqs = {
  how_founder_use: {
    heading: 'How are founders using Cabal?',
    body: (
      <List className="mt-3">
        <li>
          <Typography fontWeight={700} className="mr-2">
            Keeping shareholders updated.
          </Typography>
          {`Organize advisors and investors into groups. Use Cabal's
            messaging system and variable fields to send personalized messages to groups or
            individuals and monitor open and reply rates.`}
        </li>
        <li>
          <Typography fontWeight={700} className="mr-2">
            Sourcing top candidates.
          </Typography>
          Connect your ATS to Cabal and surface key job recs to your advisors and investors. Route
          jobs recs to specific advisors/investors based on their connections or expertise.
        </li>
        <li>
          <Typography fontWeight={700} className="mr-2">
            Closing deals.
          </Typography>
          {`Connect your CRM to Cabal and route deals to your advisors and investors based on their
            existing relationships. Pre-draft email intros at scale. Our goal is to make prospect
            introductions as easy as a few clicks.`}
        </li>
        <li>
          <Typography fontWeight={700} className="mr-2">
            Generating brand buzz.
          </Typography>
          {`Tweet our launch! Hunt us on Product Hunt!
            Review us on G2 Crowd! Founders are frequently looking for signal boosts. Cabal
            simplifies this process by streamlining the request process (e.g., mail merge)
            and the fulfillment of the request (e.g., "post to twitter" buttons).`}
        </li>
        <li>
          <Typography fontWeight={700} className="mr-2">
            Setting expectations.
          </Typography>
          {`Cabal helps you and your shareholders understand how helpful they're being. Today,
            inactive shareholders can go unnoticed. Cabal shines a light on how all shareholders
            are contributing to your company; making it easy to reward activate contributors and
            course-correct with passive shareholders.`}
        </li>
      </List>
    ),
  },
  advisor_benefit: {
    heading: 'What are the benefits of an advisor program?',
    body: (
      <div>
        Recruiting and activating a network of advisors can help you sell more, recruit higher
        quality talent, and win your market faster.
        <br />
        <br />
        {`Here's how one founder described their advisor program:`}
        <Typography className="italic border-l-2 pl-2 py-1 mt-2" fontSize="16" component="div">
          “My advisor strategy has generated millions of dollars in new and expansion ARR, saved
          hundreds of thousands of dollars in recruiting costs, and accelerated our category
          leadership.”
        </Typography>
      </div>
    ),
  },
  micro_advisor: {
    heading: 'What is a "micro advisor"?',
    body: (
      <div>
        {`The term "advisor" usually refers to accomplished people that are so wise that their
        advice alone is worth 0.25-1% of your company's stock. `}
        <br />
        <br />
        {`This is an extremely limiting view. We believe "advisor" should be defined as anyone who
        can contribute to a founder's mission. The amount of equity granted to these advisors should
        be calibrated to their contributions. `}
        <br />
        <br />
        {`"Micro advisors" are contributors who's contributions are light or who care less about
        the amount of stock they're receiving and more about the relationship they're establishing.
        These advisors are incredibly valuable. `}
        <br />
        <br />
        {`Implemented correctly, founders can build an army of advocates. Cabal helps founders
        activate this army and convert their contributions into more sales, better hiring, faster
        issue resolution, and a more certain path to victory.`}
        <br />
        <br />
        {`Here's an sample distribution of a 2% advisor program:`}
        <br />
        <br />
        <List>
          <li>(2) @0.25%</li>
          <li>(5) @0.1%</li>
          <li>(25) @0.02%</li>
          <li>(100) @0.005%</li>
        </List>
      </div>
    ),
  },
  good_advisor: {
    heading: 'What makes a good advisor?',
    body: (
      <div>
        {`Advisors are anyone who you think can contribute to your mission. Contributions can be
        anything; from HR guidance to tax help to sales intros. The opportunities for people to
        add value is limitless.`}
        <br />
        <br />
        {`We recommend founders put some thought into the areas they think they could use the most
        help as they launch their advisor program. Here are a few interesting examples we've come
        across:`}
        <br />
        <br />
        <List>
          <li>
            A Shopify app recruits advisors from large ecommerce brands to provide product guidance
            and credibility.
          </li>
          <li>
            A TeleHealth marketplace accelerates doctor recruitment by granting them advisory
            equity.
          </li>
          <li>A B2B SaaS company supercharges their Customer Advisory Board with equity grants.</li>
        </List>
      </div>
    ),
  },
  advisor_help: {
    heading: 'What can advisors help with?',
    body: (
      <div>
        {`Advisors can help with anything. Here are a few of the more common ways advisors help
        the founders they work with:`}
        <br />
        <br />
        <List>
          <li>Customer introductions</li>
          <li>Leadership recruiting</li>
          <li>Product strategy help </li>
          <li>Branding and market awareness </li>

          <li>Legal, finance and HR guidance </li>
          <li>Fundraising strategy</li>
        </List>
      </div>
    ),
  },
  investor_non_share: {
    heading: `Does Cabal work for shareholders that aren't advisors or advisors that aren't
    shareholders?`,
    body: <div>{`Yes. You can add investors and non-shareholding advisors to Cabal.`}</div>,
  },
  how_many_shares: {
    heading: `How many shares should I have? How can I increase our share count?`,
    body: (
      <div>
        {`Most company incorporation tools default to setting founders up with 10 million shares.
        We recommend increasing this by 10x to 100 million shares. There is a benefit to giving
        someone 1,000 shares vs 100. It also allows founders to be more precise with their advisor
        and employee grants.`}
        <br />
        <br />
        {`If you've taken any outside investment, speak to your attorney about increasing your share
          count. This will involve a board resolution, some paperwork and communications to existing
          shareholders. The entire process should not take more than 2 weeks. While you wait for
          your stock split to finalize, we recommend setting up Cabal and beginning to use it with
          investors and current shareholders.`}
        <br />
        <br />
        {`If you haven't taken any outside investment, contact support at the company that handled
        your incorporation. They will walk you through the process to increase your share count.`}
      </div>
    ),
  },
  vesting_period: {
    heading: `What kind of vesting period should I use for advisors?`,
    body: (
      <div>
        {`We recommend vesting monthly over 36 months with no cliff. 24 and 48 month vesting periods
          are also common. `}
        <br />
        <br />
        {`The best way to think about vesting is the **rate** of vesting. How many shares is this
        advisor earning *per month*? A 12,000 share grant vests at a rate of 250 shares/month on a
        48 month vesting schedule and 500 shares/month on a 24 month vesting schedule! Most advisors
        are focused on the total number of shares that are being granted so elongating vesting
        periods is an effective way to create more advisor capacity at the same equity cost.`}
      </div>
    ),
  },
  advisory_length: {
    heading: `How long should advisory relationships last?`,
    body: (
      <div>
        {`Founders should maintain advisory relationships as long as they are productive.
        Just like employee vesting, when an advisor is approaching the end of their vesting period,
        you'll need to decide if you want to give them a new advisor grant. These advisors should
        be motivated to contribute because of all the shares they've already vested, but many will
        rightly think in terms of what stock they are earning on a monthly basis for their
        contributions and may insist on a new grant if they are going to maintain their level of
        contributions. `}
      </div>
    ),
  },
  kind_of_stock: {
    heading: `What kind of stock should I give advisors?`,
    body: (
      <div>
        {`Advisors typically get shares of common stock, just like employees, which are subject to
        vesting. This common stock is usually either:`}
        <br />
        <br />
        <List>
          <li>
            Restricted stock agreements (RSAs) – which are usually issued (sometimes at a small
            cost) when a company hasn’t raised much money or anything at all.
          </li>
          <li>
            {`Or non-qualified stock options (NSOs) – which is the right to buy shares at a
            predetermined “strike” or “exercise” price.`}
          </li>
        </List>
      </div>
    ),
  },
  equity_worth: {
    heading: `Equity is expensive, how do I know this is worth it?`,
    body: (
      <div>
        {`It's important to be thoughtful about your advisor program and set an equity budget, just
        like you do for your employee option pool. We recommend committing 2% of your fully diluted
        share count to your advisor pool. With the right structure, you can convert this allocation
        into hundreds of high-value contributors working on your behalf. `}
        <br />
        <br />
        {`You can assign a dollar value to your advisor budget based on your most recent stock
        price. This gives you a decent proxy for the value you are granting. `}
        <br />
        <br />
        {`Cabal helps founders attribute value to advisor contributions and calibrate their advisor
        budget accordingly. `}
      </div>
    ),
  },
  advisor_agreement_template: {
    heading: `What advisor agreement should I use?`,
    body: (
      <div>
        {`Cabal has a template advisor agreement that can be used or you can upload your own advisor
        agreement.`}
        <br />
        <br />
        {`If you upload your own, you need to map certain fields (e.g., # of shares, vesting period)
        to the appropriate locations on your agreement. This way you can trigger advisor invitations
        and collect signatures natively on Cabal. `}
        <br />
        <br />
        {`One of the most important (and most standard) terms in an advisor agreement is the ability
        for either party to terminate the agreement at any time for any reason. Make sure this is
        included in your agreement. `}
      </div>
    ),
  },
  recruit_advisors: {
    heading: 'How do I recruit advisors?',
    body: (
      <div>
        {`People are often most excited to become an advisor because of the personal connection they
        form with the founder and the founder's mission.`}
        <br />
        <br />
        {`We've found that founders are most successful recruiting advisors under the following
        conditions:`}
        <br />
        <br />
        <List>
          <li>
            They meet the advisor in a natural way: friend connection, industry meetup, customer or
            vendor relationship, etc.
          </li>
          <li>
            {`The potential advisor expresses an interest in the founder and/or the founder's
            business.`}
          </li>
          <li>
            {`The founder makes the invitation informal while expressing gratitude and
            admiration for the potential advisor's work. Example:`}
          </li>
        </List>
        <Typography className="italic border-l-2 pl-2 py-1 mt-2" fontSize="16" component="div">
          {`"It was great speaking with you today. I forgot to mention on our
          call: I have a personal industry advisory group, and I'd love to have
          you join. In short, it's a group of people from various backgrounds;
          some are customers and some are industry thought leaders. There's no
          formal time commitment. I send out monthly updates which will include
          some company updates, a few questions, and a few optional asks. As a
          thank you, I'd give you some stock options for being a part of the
          group. If this sounds good to you, I'll send over an invitation to
          join our program."`}
        </Typography>
      </div>
    ),
  },
}

const FAQ: React.FC = () => {
  const anchor = useHash()[0].substr(1)
  const [expanded, setExpanded] = React.useState<{ [key: string]: boolean }>({})
  const anchoredSection = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setExpanded({
      ...expanded,
      [anchor]: true,
    })

    // hack: waiting for the section to expand and then scrolling it into the view
    setTimeout(() => {
      if (anchoredSection.current) anchoredSection.current.scrollIntoView()
    }, 100)
  }, [anchor])

  const handleExpandSection = (k: string) => {
    setExpanded({
      ...expanded,
      [k]: !expanded[k],
    })
  }

  const handleCopyAnchor = (k: string) => {
    const copyURL = new URL(window.location.href)
    copyURL.hash = `#${k}`
    copyToClipboard(copyURL.toString())
  }

  const { user } = useCurrentUser()

  return (
    <Typography component="div" className="p-10">
      <Typography component="h1" fontSize="24" fontWeight={500} color="primary" className="mb-4">
        Cabal FAQ
      </Typography>
      {Object.entries(faqs).map(([k, v]) => (
        <TipsItem
          key={k}
          active={expanded[k]}
          ref={anchor === k ? anchoredSection : undefined}
          userTheme={user.theme}
        >
          <div className="w-full px-3 flex items-center">
            <button onClick={() => handleExpandSection(k)} className="flex-1">
              <Typography fontSize="18" component="div" textAlign="left">
                {v.heading}
              </Typography>
            </button>
            <div className="flex-initial">
              <button onClick={() => handleExpandSection(k)}>
                <Typography
                  component="div"
                  color="gray"
                  fontSize="20"
                  className="p-2 rounded-full hover:bg-gray-800"
                >
                  {expanded[k] ? (
                    <CaretIcon className="far fa-caret-up" />
                  ) : (
                    <CaretIcon className="far fa-caret-down" />
                  )}
                </Typography>
              </button>
            </div>
          </div>
          <Expand open={expanded[k]}>
            <div className="p-3">{v.body}</div>
          </Expand>
        </TipsItem>
      ))}
    </Typography>
  )
}

export default FAQ
