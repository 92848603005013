import React, { useCallback, useState } from 'react'

import { remove, uniq } from 'lodash'
import moment from 'moment'
import Expand from 'react-expand-animated'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { IterableElement } from 'type-fest'

import { useComposer } from 'components/Composer'
import Avatar from 'global/Avatar'
import CabalButton, { CabalButtonProps } from 'global/CabalButton'
import Card from 'global/Card'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'

import api from 'utils/api'
import { Feed } from 'utils/types/feed'

import TwitterAuthModal from './TwitterAuthModal'

const FeedCardDiv = styled(Card)`
  ${tw`flex flex-col gap-2`}
  padding: 8px;
`

const ContributionCheck = styled.div`
  ${tw`rounded p-1 ml-2 whitespace-nowrap flex items-center`}
  background: ${({ theme }) => theme.filter.count_bg};
`

const FeedCardBorderBox = styled.div<{
  complete?: boolean
}>`
  ${tw`border light:border-light dark:border-dark rounded-lg p-2`}
  border:  ${({ theme, complete }) =>
    complete ? theme.border : `1px solid ${theme.filter.border_color}`};
`

const StyledLink = styled(Link)`
  line-height: 1.2;
`

const ButtonGroupContainer = styled.div`
  ${tw`flex space-x-0.5`}

  a, button {
    :not(.archive-button) {
      ${tw`flex-grow`}
    }

    :not(:first-child):not(:last-child) {
      ${tw`rounded-none`}
    }

    :first-child {
      ${tw`rounded-r-none`}
    }

    :last-child {
      ${tw`rounded-l-none`}
    }
  }
`

type CTAProps = CabalButtonProps & {
  buttons?: React.ReactNode
  onArchive?: () => void
}

const CTA: React.FC<CTAProps> = ({ buttons, onArchive, children, ...props }) => {
  return (
    <ButtonGroupContainer>
      {buttons}

      {children && (
        <CabalButton
          variant="secondary"
          {...{
            ...props,
            target: '_blank',
            rel: 'noreferrer',
          }}
        >
          {children}
        </CabalButton>
      )}

      {onArchive && (
        <CabalButton
          className="archive-button flex-shrink-0 flex-0 flex-grow-0"
          leftIcon={<i className="far fa-archive" />}
          variant="secondary"
          onClick={onArchive}
        />
      )}
      {/* <OptionMenu archive={onArchive} /> */}
    </ButtonGroupContainer>
  )
}

interface Props {
  type?: string
  tweet?: IterableElement<Feed['tweets']>
  deal?: IterableElement<Feed['deal_board']>
  message?: IterableElement<Feed['messages']>
  unshared_connection?: IterableElement<Feed['unshared_connections']>
  default_intro?: IterableElement<Feed['default_intro']>
  default_referral?: IterableElement<Feed['default_referral']>
  default_social?: IterableElement<Feed['default_social']>
  email_sync?: IterableElement<Feed['email_sync']>
  i: number
  onContribution?: () => void
  onCardHide: (i: number) => void
}

type TwitterAction = 'like' | 'unlike' | 'retweet' | 'unretweet'

const FeedCard: React.VFC<Props> = ({
  type = null,
  message,
  tweet,
  deal,
  unshared_connection,
  onContribution,
  default_intro,
  default_referral,
  default_social,
  email_sync,
  onCardHide,
  i,
}) => {
  const { showModal } = useModal()

  const handleRenderTwitterModal = useCallback((resolve) => {
    return <TwitterAuthModal show onHide={() => resolve()} />
  }, [])

  const { team: _team } = useTeam(
    (message?.team_slug ||
      tweet?.team_slug ||
      deal?.team_slug ||
      unshared_connection?.team_slug ||
      default_intro?.team_slug ||
      default_referral?.team_slug ||
      default_social?.team_slug)!,
  )
  const { user } = useCurrentUser()

  const team = _team || user.team

  const history = useHistory()
  const { compose } = useComposer(team?.slug)

  const [completed, setCompleted] = useState(false)
  const [dismissed, setDismissed] = useState(false)
  const [actions, setActions] = useState({
    liked: tweet?.favorited,
    retweeted: tweet?.retweeted,
  })

  const handleTwitterAction = async (
    tweet: IterableElement<Feed['tweets']>,
    action: TwitterAction,
  ) => {
    if (!user.connected_twitter) {
      return showModal(handleRenderTwitterModal, 'twitter-user-auth-modal')
    }

    if (action === 'like') {
      await api.likeTweet(tweet.id)

      setActions((actions) => ({ ...actions, liked: true }))

      createFeedCardAction('Tweet', tweet.id, 'contribution', tweet.feed_card_type)
    } else if (action === 'retweet') {
      await api.retweetTweet(tweet.id)

      setActions((actions) => ({ ...actions, retweeted: true }))

      createFeedCardAction('Tweet', tweet.id, 'contribution', tweet.feed_card_type)
    } else if (action === 'unlike') {
      await api.unlikeTweet(tweet.id)

      setActions((actions) => ({ ...actions, liked: false }))

      deleteFeedCardAction('Tweet', tweet.id, 'contribution', tweet.feed_card_type)
    } else if (action === 'unretweet') {
      await api.unretweetTweet(tweet.id)

      setActions((actions) => ({ ...actions, retweeted: false }))

      deleteFeedCardAction('Tweet', tweet.id, 'contribution', tweet.feed_card_type)
    }
  }

  const createFeedCardAction = (
    actionable_type: 'Tweet' | 'Message' | 'GlobalPerson' | 'Advisor' | 'Team' | 'User',
    actionable_id: string | number,
    action: 'contribution' | 'dismissal',
    feed_card_type: string,
  ) => {
    if (action === 'contribution') {
      onContribution?.()
      setCompleted(true)
    } else if (action == 'dismissal') {
      setDismissed(true)
    }

    onCardHide(i)

    return api.createFeedCardAction({
      actionable_id,
      actionable_type,
      action,
      feed_card_type,
      team_slug: team?.slug,
    })
  }

  const deleteFeedCardAction = (
    actionable_type: 'Tweet' | 'Message' | 'GlobalPerson' | 'Advisor' | 'Team' | 'User',
    actionable_id: string | number,
    action: 'contribution' | 'dismissal',
    feed_card_type: string,
  ) => {
    return api.deleteFeedCardAction({
      actionable_id,
      actionable_type,
      action,
      feed_card_type,
      team_slug: team?.slug,
    })
  }

  let child = <></>
  const teamHeader = (
    <div className="flex-1">
      <Link to={`/${team?.slug}`} className="leading-relaxed">
        <Avatar name={team?.name} src={team?.logo} round size="24" className="mr-3" />
        <Typography fontWeight={600} fontSize="14">
          {team?.name}
        </Typography>
      </Link>
    </div>
  )

  if (message) {
    const dismiss = () =>
      createFeedCardAction('Message', message.uuid, 'dismissal', message.feed_card_type)
    child = (
      // <SwipeToDismiss distanceBeforeDismiss={50} onDismiss={dismiss} direction="left">
      <FeedCardDiv invert={completed || dismissed}>
        <div className="flex items-center">
          {teamHeader}
          <Typography color="rain_fog" fontSize="14" className="right-icon">
            <i className="fas fa-envelope fa-fw mr-1" />
          </Typography>
        </div>
        <div>
          <FeedCardBorderBox complete={completed || dismissed}>
            <div className="flex items-center mb-1">
              <StyledLink
                to={`/${message.team_slug}/messages/${message.uuid}`}
                className="truncate flex"
              >
                <Typography className="truncate" component="p" fontSize="14" fontWeight={600}>
                  {message.subject}
                </Typography>
                <Typography fontSize="14" color="fog" className="flex-shrink-0">
                  <span className="mx-1">·</span>
                  {moment(message.created_at).format('MMM D')}
                </Typography>
              </StyledLink>
            </div>
            <Typography fontSize="14" className="truncate" component="p">
              {message.snippet}
            </Typography>
          </FeedCardBorderBox>
        </div>
        <CTA
          onArchive={dismiss}
          onClickCapture={() => {
            createFeedCardAction('Message', message.uuid, 'contribution', message.feed_card_type)
          }}
          to={`/${message.team_slug}/messages/${message.uuid}`}
          component="link"
        >
          Open
        </CTA>
      </FeedCardDiv>
      // </SwipeToDismiss>
    )
  }

  if (tweet) {
    const dismiss = () => createFeedCardAction('Tweet', tweet.id, 'dismissal', tweet.feed_card_type)
    const twitterLikeedColor = 'rgb(249, 24, 128)'
    const twitterRetweetedColor = 'rgb(0, 186, 124)'

    child = (
      // <SwipeToDismiss distanceBeforeDismiss={50} onDismiss={dismiss} direction="left">
      <FeedCardDiv invert={completed || dismissed}>
        <div className="flex items-center">
          {teamHeader}
          <Typography color="rain_fog" fontSize="14" className="right-icon">
            <i className="fab fa-twitter fa-fw mr-1" />
          </Typography>
        </div>
        <FeedCardBorderBox complete={completed || dismissed}>
          <div className="flex items-center">
            <a
              href={`https://www.twitter.com/${tweet.author_handle}/status/${tweet.foreign_id}`}
              target="_blank"
              rel="noreferrer"
            >
              <Typography fontSize="14" color="fog" lineHeight={'1.2'}>
                @{tweet.author_handle} · {moment(tweet.created_at).format('MMM D')}
              </Typography>
            </a>
          </div>
          <Typography fontSize="14" lineHeight={'1.45'} component="p">
            {tweet.text}
          </Typography>
        </FeedCardBorderBox>
        <CTA
          buttons={
            <>
              <CabalButton
                variant="secondary"
                textColor={actions.liked ? twitterLikeedColor : undefined}
                leftIcon={
                  <i
                    style={{ color: actions.liked ? twitterLikeedColor : undefined }}
                    className={`${actions.liked ? 'fa' : 'far'} fa-heart`}
                  />
                }
                onClick={() =>
                  actions.liked
                    ? handleTwitterAction(tweet, 'unlike')
                    : handleTwitterAction(tweet, 'like')
                }
              >
                {actions.liked ? 'Unlike' : 'Like'}
              </CabalButton>

              <CabalButton
                variant="secondary"
                textColor={actions.retweeted ? twitterRetweetedColor : undefined}
                leftIcon={
                  <i
                    style={{ color: actions.retweeted ? twitterRetweetedColor : undefined }}
                    className={`${actions.retweeted ? 'fa' : 'far'} fa-retweet`}
                  />
                }
                onClick={() =>
                  actions.retweeted
                    ? handleTwitterAction(tweet, 'unretweet')
                    : handleTwitterAction(tweet, 'retweet')
                }
              >
                {actions.retweeted ? 'Unretweet' : 'Retweet'}
              </CabalButton>
            </>
          }
          onArchive={dismiss}
        />
      </FeedCardDiv>
      // </SwipeToDismiss>
    )
  }

  if (deal) {
    const dismiss = () => {
      createFeedCardAction('GlobalPerson', deal.id, 'dismissal', deal.feed_card_type)
    }

    child = (
      // <SwipeToDismiss
      //   distanceBeforeDismiss={50}
      //   onDismiss={() => {
      //     api
      //       .createVote({
      //         team_slug: deal.team_slug,
      //         votable_id: deal.id,
      //         votable_type: 'GlobalPerson',
      //         company_id: deal.company.id,
      //         vote: -1,
      //       })
      //       .then(dismiss)
      //   }}
      //   direction="left"
      // >
      <FeedCardDiv invert={completed || dismissed}>
        <div className="flex items-center">
          {teamHeader}
          <Typography color="rain_fog" fontSize="14" className="right-icon">
            <i className="fas fa-sack-dollar fa-fw mr-1" />
          </Typography>
        </div>

        <Typography fontSize="12" component="div" lineHeight={1.25} className="ml-1">
          {completed
            ? `Nice! A notification has been sent to the ${team?.name} team`
            : `You’re connected to ${team?.name}'s target account`}
        </Typography>

        <FeedCardBorderBox complete={true}>
          {completed ? (
            <div className="flex flex-1 items-center justify-between">
              <div className="flex items-center">
                <Avatar name={deal.name} src={deal.image_url!} size="26px" className="mr-2" />
                <Typography fontWeight={600} fontSize="14" lineHeight={1.2} component="p">
                  {deal.name}
                </Typography>
              </div>
              <div>
                <ContributionCheck>
                  <Typography color="yellow" fontSize="12" lineHeight={1} component="div">
                    <i className="fas fa-star mr-1.5" />
                  </Typography>
                  <Typography fontSize="12" fontWeight={600} fontFamily={'mono'} component="p">
                    +1
                  </Typography>
                </ContributionCheck>
              </div>
            </div>
          ) : (
            <div className="flex flex-1 items-center">
              <Avatar name={deal.name} src={deal.image_url!} size="26px" className="mr-2" />
              <Typography fontSize="14" lineHeight={1.2} component="p">
                <Typography fontWeight={600}>{deal.name}</Typography>
                {' at '}
                <Typography fontWeight={600}>{deal.company.name}</Typography>
              </Typography>
            </div>
          )}
        </FeedCardBorderBox>

        {!completed && !dismissed && (
          <CTA
            onClickCapture={() => {
              api
                .createVote({
                  team_slug: deal.team_slug,
                  votable_id: deal.id,
                  votable_type: 'GlobalPerson',
                  company_id: deal.company.id,
                  vote: 1,
                })
                .then(() => {
                  createFeedCardAction('GlobalPerson', deal.id, 'contribution', deal.feed_card_type)
                })
            }}
            onArchive={dismiss}
            leftIcon={<i className="far fa-thumbs-up" />}
          >
            Offer intro
          </CTA>
        )}
      </FeedCardDiv>
      // </SwipeToDismiss>
    )
  }

  if (unshared_connection) {
    const dismiss = () => {
      createFeedCardAction(
        'Advisor',
        unshared_connection.advisor_uuid,
        'dismissal',
        unshared_connection.feed_card_type,
      )
    }
    child = (
      // <SwipeToDismiss distanceBeforeDismiss={50} onDismiss={dismiss} direction="left">
      <FeedCardDiv invert={completed || dismissed}>
        <div className="flex items-center">
          {teamHeader}
          <Typography color="rain_fog" fontSize="14" className="right-icon">
            <i className="far fa-arrows-h fa-fw mr-1" />
          </Typography>
        </div>
        {completed && (
          <FeedCardBorderBox complete={true}>
            <Typography fontSize="12" component="div" lineHeight={1.25} className="ml-1">
              Nice! A notification has been sent to {team?.name}
            </Typography>
            <div className="flex flex-1 items-center justify-between">
              <div className="flex items-center">
                <Typography fontWeight={600} fontSize="14" lineHeight={1.2} component="p">
                  Your connections were shared with {team?.name}
                </Typography>
              </div>
              <div>
                <ContributionCheck>
                  <Typography color="yellow" fontSize="12" lineHeight={1} component="div">
                    <i className="fas fa-star mr-1.5" />
                  </Typography>
                  <Typography fontSize="12" fontWeight={600} fontFamily={'mono'} component="p">
                    +1
                  </Typography>
                </ContributionCheck>
              </div>
            </div>
          </FeedCardBorderBox>
        )}
        {!completed && !dismissed && (
          <CTA
            onClickCapture={() => {
              api
                .updateConnectionSharing({
                  share_connections: true,
                  team_slug: unshared_connection.team_slug,
                })
                .then(() => {
                  createFeedCardAction(
                    'Advisor',
                    unshared_connection.advisor_uuid,
                    'contribution',
                    unshared_connection.feed_card_type,
                  )
                })
            }}
            onArchive={dismiss}
            leftIcon={<i className="far fa-thumbs-up" />}
          >
            Share connections
          </CTA>
        )}
      </FeedCardDiv>
      // </SwipeToDismiss>
    )
  }

  if (default_intro) {
    const dismiss = () => {
      createFeedCardAction('Team', team!.slug, 'dismissal', default_intro.feed_card_type)
    }
    child = (
      <FeedCardDiv>
        <div className="flex items-center mb-2">
          {teamHeader}
          <Typography color="rain_fog" fontSize="14" className="right-icon">
            <i className="fas fa-sack-dollar fa-fw mr-1" />
          </Typography>
        </div>
        <CTA
          onClickCapture={() => {
            compose({
              subject: `Have you heard of ${team?.name}?`,
              body: `Hey! A lot of the companies I work with are starting to use ${
                team?.name
              }. I think you'd like it. LMK if you'd be interested in learning more.
              <br />
              <br />
              ${team?.website || ''}
              `,
              onSubmit: () => {
                createFeedCardAction(
                  'Team',
                  team!.slug,
                  'contribution',
                  default_intro.feed_card_type,
                )
              },
            })
          }}
          onArchive={dismiss}
          leftIcon={<i className="far fa-envelope" />}
        >
          Refer a prospect
        </CTA>
      </FeedCardDiv>
    )
  }

  if (default_referral) {
    const dismiss = () => {
      createFeedCardAction('Team', team!.slug, 'dismissal', default_referral.feed_card_type)
    }
    child = (
      <FeedCardDiv>
        <div className="flex items-center mb-2">
          {teamHeader}
          <Typography color="rain_fog" fontSize="14" className="right-icon">
            <i className="fas fa-user fa-fw mr-1" />
          </Typography>
        </div>
        <CTA
          onClickCapture={() => {
            compose({
              subject: `Cool company you may be interested in`,
              body: `Hey, I’ve been working with ${
                team?.name
              }. They’re always looking for top talent and I think you’d be a great fit. Interested in learning more?
              <br />
              <br />
              ${team?.website || ''}
              `,
              onSubmit: () => {
                createFeedCardAction(
                  'Team',
                  team!.slug,
                  'contribution',
                  default_referral.feed_card_type,
                )
              },
            })
          }}
          onArchive={dismiss}
          leftIcon={<i className="far fa-envelope" />}
        >
          Refer a candidate
        </CTA>
      </FeedCardDiv>
    )
  }

  if (default_social) {
    const dismiss = () => {
      createFeedCardAction('Team', team!.slug, 'dismissal', default_social.feed_card_type)
    }
    child = (
      <FeedCardDiv>
        <div className="flex items-center mb-2">
          {teamHeader}
          <Typography color="rain_fog" fontSize="14" className="right-icon">
            <i className="fab fa-twitter fa-fw mr-1" />
          </Typography>
        </div>

        <CTA
          onClickCapture={() => {
            window.open(
              `https://twitter.com/intent/tweet?text=${encodeURI(
                `I love ${team?.twitter_handle ? `@${team.twitter_handle}` : team?.name}!`,
              )}`,
            )
            createFeedCardAction('Team', team!.slug, 'contribution', default_social.feed_card_type)
          }}
          onArchive={dismiss}
          leftIcon={<i className="far fa-pencil" />}
        >
          Draft tweet
        </CTA>
      </FeedCardDiv>
    )
  }

  if (email_sync) {
    const dismiss = () => {
      createFeedCardAction('User', '', 'dismissal', email_sync.feed_card_type)
    }
    child = (
      <FeedCardDiv>
        <div className="flex items-center justify-between mb-2">
          <Typography fontWeight={600} fontSize="14">
            Sync your inbox
          </Typography>
          <Typography color="rain_fog" fontSize="14" className="right-icon">
            <i className="fas fa-envelope-open fa-fw mr-1" />
          </Typography>
        </div>
        <CTA
          onClickCapture={() => {
            window.open(
              `https://twitter.com/intent/tweet?text=${encodeURI(
                `I love ${team?.twitter_handle ? `@${team.twitter_handle}` : team?.name}!`,
              )}`,
            )
            createFeedCardAction('Team', team!.slug, 'contribution', email_sync.feed_card_type)
          }}
          onArchive={dismiss}
          leftIcon={<i className="fab fa-google" />}
        >
          Sync your inbox
        </CTA>
      </FeedCardDiv>
    )
  }

  if (type == 'add_company') {
    child = (
      // <SwipeToDismiss distanceBeforeDismiss={50} onDismiss={dismiss} direction="left">
      <FeedCardDiv invert={completed}>
        <div onClick={() => history.push('/add')} className="p-1 text-center text-sm">
          <i className="far fa-plus mr-2" />
          Add company
        </div>
      </FeedCardDiv>
      // </SwipeToDismiss>
    )
  }

  // if (i === 0) {
  //   child = (
  //     <Fade left distance="50px">
  //       {child}
  //     </Fade>
  //   )
  // }

  return (
    <Expand open={!dismissed}>
      <div className="mb-2">{child}</div>
    </Expand>
  )
}

export default FeedCard
