import { pick, sortBy } from 'lodash'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'

import CabalButton from 'global/CabalButton'
import Card from 'global/Card'
import { DatePicker, DatePickerModal, NumberFormattedInput, Select, TextInput } from 'global/Input'
import { useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { AdvisorModel, CustomFieldBlueprint, CustomFieldBlueprintWithValue } from 'utils/types'

interface Props {
  advisor: AdvisorModel
}

const CustomFields: React.VFC<Props> = ({ advisor }) => {
  const teamSlug = useTeamSlug()
  const [fields, setfields] = useState<CustomFieldBlueprintWithValue[]>([])

  useQuery(
    [teamSlug, 'customFields', 'Advisor', advisor.uuid],
    () => callApi(api.getCustomFields, teamSlug, 'Advisor', advisor.uuid),
    {
      onSuccess: ({ fields: _v }) => {
        setfields(_v as CustomFieldBlueprintWithValue[])
      },
    },
  )

  const { isLoading: saving, mutate: bulkUpdateCustomFieldValueMutate } = useMutation(
    [teamSlug, 'customFields', 'Advisor', advisor.uuid],
    () =>
      callApi(
        api.bulkUpdateCustomFieldValue,
        teamSlug,
        'Advisor',
        advisor.uuid,
        fields.map((v) => pick(v, ['uuid', 'value'])),
      ),
    {
      onSuccess: () => {
        cabalToast({ content: 'Custom field updated', style: 'success' })
      },
    },
  )

  const setfieldValue = (field: CustomFieldBlueprintWithValue, value: string | number) => {
    const updatedfields = [...fields].filter((v) => v.uuid !== field.uuid)

    updatedfields.push({
      ...field,
      value,
    })

    setfields(updatedfields)
  }

  return (
    <div className="pt-3">
      <Card className="flex flex-col gap-3">
        {sortBy(fields, (v) => v.created_at).map((v) => {
          if (v.value_type === 'text')
            return (
              <div className="flex-1" key={v.uuid}>
                <TextInput
                  className="w-full"
                  labeled
                  placeholder="Enter value"
                  label={v.name}
                  onChange={(e) => setfieldValue(v, e.currentTarget.value)}
                  value={v.value || ''}
                />
              </div>
            )

          if (v.value_type === 'number')
            return (
              <div className="flex-1" key={v.uuid}>
                <NumberFormattedInput
                  className="w-full"
                  labeled
                  placeholder="Enter value"
                  label={v.name}
                  onChange={(e) => setfieldValue(v, e.currentTarget.value)}
                  value={v.value || ''}
                />
              </div>
            )

          if (v.value_type === 'datetime')
            return (
              <div className="flex-1" key={v.uuid}>
                <DatePickerModal
                  className="w-full"
                  labeled
                  placeholder="Enter value"
                  label={v.name}
                  onDateChange={(d) => setfieldValue(v, d)}
                  date={v.value as string}
                />
              </div>
            )

          if (v.value_type === 'boolean') {
            let valueOptions = []
            valueOptions.push({ value: 't', label: 'True' })
            valueOptions.push({ value: 'f', label: 'False' })

            return (
              <div className="flex-1" key={v.uuid}>
                <label>{v.name}</label>
                <Select
                  className="w-full"
                  placeholder={'Select True or False'}
                  options={valueOptions}
                  isClearable
                  value={v.value || ''}
                  onChange={(value) => {
                    setfieldValue(v, value)
                  }}
                />
              </div>
            )
          }
        })}
        <CabalButton onClick={() => bulkUpdateCustomFieldValueMutate()} working={saving}>
          Save
        </CabalButton>
      </Card>
    </div>
  )
}

export default CustomFields
