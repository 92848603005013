import { useCallback, useMemo } from 'react'
import { useIsFetching, useQueryClient } from 'react-query'
import { GetMessageResponse } from 'utils/types'

/**
 * dont use this in useCompoer, it will result in calling useIsFetching hook
 * which is an expensive operation resulting in browser hang
 */
export const useComposeQuery = (teamSlug: string, composerUuid: string) => {
  const queryClient = useQueryClient()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMessageKey = ['compose', 'get', composerUuid]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createMessageKey = ['compose', 'create', composerUuid]

  const gettingMessage = useIsFetching(getMessageKey)
  const creatingMessage = useIsFetching(createMessageKey)

  const refetchMessage = useCallback(async () => {
    queryClient.setQueryData(getMessageKey, undefined)
    queryClient.setQueryData(createMessageKey, undefined)
    await queryClient.refetchQueries(getMessageKey)
    // the dependencies are correct, ignore eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMessageKey, composerUuid])

  const { message } = useMemo(
    () =>
      (queryClient.getQueryData(getMessageKey) ||
        queryClient.getQueryData(createMessageKey) ||
        {}) as GetMessageResponse,
    // the dependencies are correct, ignore eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [composerUuid, queryClient, gettingMessage, creatingMessage],
  )

  return {
    message,
    creatingMessage,
    gettingMessage,
    refetchMessage,
  }
}
