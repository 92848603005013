import React from 'react'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

interface Props {
  adminView: boolean
  onShowAddWidget: () => void
}

const PortfolioListEmptyState: React.FC<Props> = ({ adminView, onShowAddWidget }) => {
  return (
    <div className="flex items-center justify-center my-16">
      <div className="flex flex-col items-center justify-center gap-4">
        <Typography color="fog" fontSize="20">
          <i className="far fa-building" />
        </Typography>
        <Typography fontSize="14" lineHeight={1}>
          No companies
        </Typography>

        {adminView && (
          <>
            <Typography fontSize="12" lineHeight={1}>
              Add companies to get started
            </Typography>
            <CabalButton onClick={() => onShowAddWidget()} leftIcon={<i className="far fa-plus" />}>
              Add company
            </CabalButton>
          </>
        )}
      </div>
    </div>
  )
}

export default PortfolioListEmptyState
