import React from 'react'

import pluralize from 'pluralize'

import CrmIcon from 'components/CrmIcon'
import ListHeader from 'containers/ListIndexView/Components/ListHeader'
import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'

const SalesListHeader = () => {
  const { companyListData } = useSalesListContext()
  const descriptionPart = [
    !companyListData.owned ? (
      <>
        <div className="flex items-center gap-1">
          {companyListData.owning_team?.logo_url && (
            <Avatar src={companyListData.owning_team.logo_url} size={'16'} />
          )}
          <Typography className="truncate">{companyListData.owning_team.name}</Typography>
        </div>
        <Typography className="mx-1.5">{'·'}</Typography>
      </>
    ) : undefined,
    companyListData.dynamic && <CrmIcon crm={companyListData.provider} className="pr-2" />,
    `${companyListData.item_count} ${pluralize('Item', companyListData.item_count)}`,
  ]
  return <ListHeader title={companyListData.name} description={descriptionPart} />
}

export default SalesListHeader
