import { cabalToast } from 'ui-components/Toast'

const copyToClipboard = (text: string, toast?: string) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text)
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
    textarea.style.zIndex = '10000'

    let portalDiv = document.querySelector('div[data-focus-lock-disabled="false"]')
    if (!portalDiv) {
      portalDiv = document.body
    }
    portalDiv.appendChild(textarea)

    textarea.select()
    try {
      document.execCommand('copy') // Security exception may be thrown by some browsers.
      if (process.env.NODE_ENV === 'development') {
        console.log('Copied to clipboard', text)
      }
      toast && cabalToast({ content: toast, style: 'success' })
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)
      return false
    } finally {
      portalDiv.removeChild(textarea)
    }
  }
}

export default copyToClipboard
