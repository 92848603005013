import * as React from 'react'

import { NumberFormattedInput } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'

import { formatNum } from 'utils/ui'

interface CapGroupProps {
  value: number
  onChange?: (e: {
    target: {
      value: string
    }
  }) => void
  text: string
  percent: number
  [key: string]: unknown
  percentOf: string | null
}

const CapGroup: React.VFC<CapGroupProps> = ({
  text,
  percent,
  value,
  onChange,
  percentOf,
  ...restProps
}) => {
  return (
    <ModalInputWrapper>
      <ModalInputLabel>
        {text}
        <span className="ml-1">
          ({percent ? formatNum(percent, 3) : 0}%{percentOf ? ` of ${percentOf}` : ''})
        </span>
      </ModalInputLabel>

      <NumberFormattedInput
        value={value}
        onChange={onChange}
        disabled={onChange ? false : true}
        readOnly={!onChange}
        placeholder="Number of shares"
        className="w-full"
        {...restProps}
      />
    </ModalInputWrapper>
  )
}

export default CapGroup
