import React from 'react'

import { PopupModal } from 'react-calendly'
import Expand from 'react-expand-animated'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Typography from 'global/Typography'

import LogoHover from '../UpdatedLogos/Cabal+hover.svg'
import Logo from '../UpdatedLogos/Cabal.svg'
import { CompanyLink, InvestorLink } from './styles'

const MobileHeightWrappe = styled.div`
  height: calc(100vh - 96px);
`

const Nav = () => {
  const [open, setOpen] = React.useState(false)
  const [demo, setDemo] = React.useState(false)
  const location = useLocation()

  return (
    <div className="sticky top-0 z-20 bg-[#0E151F] py-2 px-8">
      <div className="flex items-center justify-between max-w-6xl mx-auto">
        <div className="group">
          <a href="/" className="flex items-center cursor-pointer">
            <img src={Logo} alt="Cabal" className="block group-hover:hidden h-16 " />
            <img src={LogoHover} alt="Cabal" className="hidden group-hover:block h-16" />
          </a>
        </div>
        <div>
          <div className="hidden md:flex items-center gap-8">
            <CompanyLink href="/companies" className="" active={false}>
              For Companies
            </CompanyLink>
            <InvestorLink href="/investors" className="" active={false}>
              For VCs
            </InvestorLink>
            <button
              onClick={() => window.open('./demo', '_blank', 'noopener noreferrer')}
              className="rounded-full px-6 py-2.5 border border-white leading-none  text-white hover:bg-[#ffffff1f] tansition-all duration-300 ease-in-out"
            >
              Book Demo
            </button>
            <button
              onClick={() => (window.location.href = './login')}
              className="text-[#0E151F] bg-white rounded-full border border-white px-6 py-2.5 leading-none  hover:bg-[#ffffffad] tansition-all duration-300 ease-in-out"
            >
              Login
            </button>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setOpen(!open)}
              className="text-white rounded-full border border-white leading-none px-6 py-3"
            >
              {open ? (
                <i className="far fa-times fa-fw"></i>
              ) : (
                <i className="far fa-bars fa-fw"></i>
              )}
            </button>
          </div>
        </div>
      </div>
      <Expand open={open}>
        <MobileHeightWrappe className="flex flex-col pt-10 space-y-10 z-20 overflow-hidden">
          <CompanyLink href="/companies" className="">
            For Companies
          </CompanyLink>
          <InvestorLink href="/investors" className="">
            For VCs
          </InvestorLink>
          <a
            href="https://win.getcabal.com/"
            rel="noopener noreferrer"
            target="_blank"
            className="text-white hover:text-[#ffffffad] tansition-all duration-300 ease-in-out"
          >
            WIN
            <i className="far fa-arrow-up-right-from-square fa-sm ml-2" />
          </a>
          <button
            onClick={() => window.open('./demo', '_blank', 'noopener noreferrer')}
            className="rounded-full px-6 py-2.5 border border-white leading-none  text-white"
          >
            Book Demo
          </button>
          <button
            onClick={() => (window.location.href = './login')}
            className="text-[#0E151F] bg-white rounded-full border border-white px-6 py-2.5 leading-none "
          >
            Login
          </button>
        </MobileHeightWrappe>
      </Expand>
      {/* <PopupModal
        url="https://calendly.com/d/cpwq-vnh-b28/cabal-demo"
        onModalClose={() => setDemo(false)}
        open={demo}
        rootElement={document.getElementById('calendly-index')}
      /> */}
    </div>
  )
}

export default Nav
