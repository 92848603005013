import axios from 'axios'
import { Params } from 'utils/types'

export default {
  getDigestSubscriptions: (team_slug: string) => {
    return axios.get(`/api/digest_subscriptions`, {
      params: {
        team_slug,
      },
    })
  },

  setDigestSubscriptions: (params: Params) => {
    return axios.post(`/api/digest_subscriptions`, params)
  },
}
