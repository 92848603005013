import { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { createGlobalState } from 'react-use'

import api, { callApi } from 'utils/api'
import { TeamSettings } from 'utils/types'

type Settings = TeamSettings['settings']

const useTeamSettingState = createGlobalState<Record<string, Settings>>({})

function useTeamSetting<T extends keyof Settings>(
  teamSlug: string,
  setting: T,
  defaultValue?: Settings[T],
) {
  const [teamSettings, setTeamSettings] = useTeamSettingState()
  const settings = teamSettings[teamSlug] || ({} as any)
  const [value, _setValue] = useState<Settings[T] | undefined>(settings?.[setting] || defaultValue)

  const { refetch } = useQuery(
    ['getTeamSettings', teamSlug],
    () => {
      return callApi(api.getTeamSettings, { for: 'team', team_slug: teamSlug })
    },
    {
      onSuccess: (data) => {
        setTeamSettings((prev) => ({ ...prev, [teamSlug]: data.settings }))
      },
      enabled: !!teamSlug,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

  const updateSettingMutation = useMutation(
    (value: Settings[T]) => callApi(api.updateTeamSetting, setting, value),
    {
      onSuccess: refetch,
    },
  )

  useEffect(() => {
    _setValue(settings?.[setting])
  }, [settings?.[setting]])

  const setValue = useCallback(
    (value: Settings[T]) => {
      updateSettingMutation.mutate(value)
      _setValue(value)
    },
    [setting, value],
  )

  return [value, setValue] as const
}

export default useTeamSetting
