import React from 'react'

import { useMutation, useQuery } from 'react-query'

import ActivityRow from 'components/Activity/ActivityRow'
import { useComposer } from 'components/Composer'
import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { CandidateProfile } from 'utils/types'

export function MessageCandidateWidget(props: {
  candidateProfile: CandidateProfile
  advisorUuid: string
}) {
  const composer = useComposer()
  return (
    <Widget title="Message">
      <div className="flex flex-col">
        <Typography color="fog" fontSize="12" className="mb-3">
          Send a message to {props.candidateProfile.global_person.first_name}
        </Typography>

        <CabalButton
          className="self-start"
          variant="primary"
          onClick={() => {
            composer.compose({
              recipients: {
                advisor_uuids: [props.advisorUuid],
              },
            })
          }}
        >
          Send message
        </CabalButton>
      </div>
    </Widget>
  )
}

export function CandidateNoteComposerWidget(props: {
  candidateProfile: CandidateProfile
  advisorUuid: string
  onNoteAdded: () => unknown
}) {
  const teamSlug = useTeamSlug()
  const [body, setBody] = React.useState('')
  const { team } = useTeam(teamSlug)

  const createNoteMutation = useMutation(
    () => callApi(api.createNote, props.advisorUuid, `<p>${body}</p>`),
    {
      onSuccess: () => {
        setBody('')
        props.onNoteAdded()
      },
    },
  )

  return (
    <Widget title="Add a note">
      <form
        onSubmit={(e) => {
          e.preventDefault()
          createNoteMutation.mutate()
        }}
      >
        <div className="flex flex-col">
          <Typography color="fog" fontSize="12" className="mb-3">
            Add a note about {props.candidateProfile.global_person.first_name}. Private to{' '}
            {team?.name}.
          </Typography>

          <TextArea
            value={body}
            onChange={(e) => setBody(e.target.value)}
            disabled={createNoteMutation.isLoading}
            placeholder="Write your note"
          />

          <CabalButton
            className="mt-3 self-start"
            variant="primary"
            working={createNoteMutation.isLoading}
          >
            {createNoteMutation.isLoading ? 'Adding...' : 'Add note'}
          </CabalButton>
        </div>
      </form>
    </Widget>
  )
}

export function ActivityWidget(props: { advisorUuid: string; isCandidateProfilePage: boolean }) {
  const teamSlug = useTeamSlug()
  const [showAllEntries, setShowAllEntries] = React.useState(false)

  const memberActivitiesQuery = useQuery([teamSlug, 'getMemberActivity', props.advisorUuid], () =>
    callApi(api.getMemberActivity, teamSlug, 'candidate_activities', props.advisorUuid),
  )

  const activities = memberActivitiesQuery.data?.activity ?? []
  const visibleActivities = showAllEntries ? activities : activities.slice(0, 6)

  return (
    <Widget title="Activity">
      <div className="-mx-2">
        {visibleActivities.map((activity) => (
          <ActivityRow
            key={activity.uuid}
            activity={activity}
            splitView={true}
            isCandidateProfilePage={props.isCandidateProfilePage}
          />
        ))}

        {!showAllEntries && (activities.length ?? 0) > 6 && (
          <div className="flex justify-center pt-1">
            <CabalButton variant="link" onClick={() => setShowAllEntries(true)}>
              See all ({(activities ? activities : []).length})
            </CabalButton>
          </div>
        )}
      </div>
    </Widget>
  )
}
