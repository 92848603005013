import React, { useEffect } from 'react'

import Expand from 'react-expand-animated'
import styled from 'styled-components'

import { useTeam } from 'store/hooks'

import Step1 from '../../../assets/images/add-s1.png'
import Step2 from '../../../assets/images/add-s2.png'
import Step3 from '../../../assets/images/add-s3.png'

const Img = styled.img`
  height: 120px;
`

interface Props {
  teamSlug: string
  showSteps: boolean
  totalConnections?: number
}

const ImportSteps: React.FC<Props> = ({ teamSlug, showSteps, totalConnections }) => {
  const { isLoading } = useTeam(teamSlug)
  const [sharedConn, setSharedConn] = React.useState(false)
  // const [hideSteps, setHideSteps] = React.useState(false)

  useEffect(() => {
    if (totalConnections && totalConnections > 0) {
      setSharedConn(true)
    }
  }, [])

  const hideSteps = !!teamSlug && sharedConn && !showSteps

  if (isLoading) return <></>
  return (
    <>
      {/* {!hideSteps && (
        <div className="flex flex-col items-center mt-8">
          <div className="grid grid-cols-3 gap-3">
            <Img src={Step1} />
            <Img src={Step2} />
            <Img src={Step3} />
          </div>
        </div>
      )} */}
    </>
  )
}

export default ImportSteps
