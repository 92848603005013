import React, { useState } from 'react'

import { AccountsProfilesDiv } from 'components/Winsite/styles'
import { StyledTypography } from 'components/Winsite/styles'
import { ICompanyListData } from 'components/Winsite/types/ICompanyPersonaList'
import { ITeamData } from 'components/Winsite/types/ITeamData'
import { loginWithDomainToast } from 'components/Winsite/utils'
import { useCurrentUser } from 'store/hooks'

import { listUrl } from 'utils/url'

import { InfoHeadlineDiv, StyledCabalButton } from '../styles'
import { AccountsProfilesDivSubSection } from './AccountsProfilesDivSubSection'

interface IProps {
  teamData: ITeamData | undefined
  headline: string
  list: ICompanyListData
  listType: string
}
export function AccountsProfilesPage({ teamData, headline, list, listType }: IProps) {
  const [editClicked, setEditClicked] = useState<boolean>(false)
  const { user } = useCurrentUser()
  const userPresent = !!Object(user)?.created_at
  const listSlug = list.slug
  const allowEdit = userPresent && user.team?.slug === teamData?.team_slug

  const handleEditClick = () => {
    if (!allowEdit) {
      const content = `You must be a member of the ${teamData?.company_name.toLowerCase()} team to edit ${headline}`
      loginWithDomainToast(teamData, content)
      return
    }
    setEditClicked(true)
  }

  return (
    <AccountsProfilesDiv>
      <div className="flex flex-col gap-3 w-full p-5">
        <div className="flex flex-row justify-between">
          <InfoHeadlineDiv>{headline}</InfoHeadlineDiv>
          <div className="flex flex-row gap-x-2 items-center cursor-pointer">
            <StyledTypography onClick={() => handleEditClick()}>
              Edit <i className="fa-light fa-pen-to-square px-1"></i>
            </StyledTypography>
          </div>
        </div>
        <div className="flex flex-col bg-white p-1 justify-between">
          <AccountsProfilesDivSubSection
            list={list}
            editClicked={editClicked}
            setEditClicked={setEditClicked}
            listType={listType}
            teamSlug={teamData?.team_slug}
            companyDomain={teamData?.company_domain}
          />
        </div>
        {listSlug && (
          <div className="flex flex-row justify-between">
            <StyledCabalButton
              style={{ marginTop: '0px', width: 'auto' }}
              onClick={() => {
                window.open(listUrl(teamData?.team_slug, listSlug), '_blank', 'noopener noreferrer')
              }}
            >
              See matching connections
            </StyledCabalButton>
          </div>
        )}
      </div>
    </AccountsProfilesDiv>
  )
}
