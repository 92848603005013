import axios, { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { REQUEST_CENTER_ENDPOINTS } from 'containers/RequestCenter/apiEndpoints'
import {
  IIRValidStatusParams,
  IIntroValidStatusResponse,
} from 'containers/RequestCenter/types/ITypes'

const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')

const fetchValidStatus = async (
  teamId: number | string,
): Promise<AxiosResponse<IIntroValidStatusResponse>> => {
  return await axios.get<IIntroValidStatusResponse>(REQUEST_CENTER_ENDPOINTS.getValidStatuses, {
    headers: {
      'X-CSRF-TOKEN': csrfToken,
    },
    params: {
      team_id: teamId,
    },
  })
}

export const useFetchValidStatus = ({ teamId, enabled }: IIRValidStatusParams) =>
  useQuery({
    queryKey: ['fetchIntroRequestDetails', teamId],
    queryFn: () => fetchValidStatus(teamId),
    enabled: enabled,
    retry: 1,
  })
