import * as React from 'react'

import AvatarUpload, { AvatarUploadRef } from 'global/AvatarUpload'
import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'

interface Props {
  show: boolean
  onHide: () => void
}

const PersonalInfoModal: React.FC<Props> = ({ show, onHide }) => {
  const { user, reloadUser } = useCurrentUser()

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [title, setTitile] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [working, setWorking] = React.useState(false)

  const avatarUploadRef = React.useRef<AvatarUploadRef>(null)

  React.useEffect(() => {
    setFirstName(user.first_name || '')
    setLastName(user.last_name || '')
    setTitile(user.title || '')
  }, [user])

  const handleSubmit = () => {
    setWorking(true)
    const params = {
      first_name: firstName,
      last_name: lastName,
      title,
      description,
    }

    api
      .updateCurrentProfile(params)
      .then(() => {
        avatarUploadRef.current?.upload()
      })
      .catch(() => setWorking(false))
  }

  const onUpload = () => {
    setWorking(false)
    reloadUser()
    onHide()
    cabalToast({ style: 'success', content: 'Successfully updated the profile.' })
  }

  return (
    <Modal
      header="Edit info"
      show={show}
      onHide={onHide}
      rightActions={
        <CabalButton
          variant="primary"
          data-testid="save-personal-info"
          working={working}
          onClick={handleSubmit}
        >
          {'Save Personal Info'}
        </CabalButton>
      }
    >
      <AvatarUpload
        onUpload={onUpload}
        email={user.email}
        ref={avatarUploadRef}
        attachTo="user_avatar"
        imageUrl={user.avatar_url}
        className="my-4"
      />

      <div className="flex">
        <div className="flex-1 pr-4">
          <TextInput
            value={firstName}
            placeholder="First Name"
            className="w-full mb-4"
            onChange={(e) => setFirstName(e.target.value)}
            data-testid="first-name"
          />
        </div>
        <div className="flex-1">
          <TextInput
            value={lastName}
            placeholder="Last Name"
            className="w-full mb-4"
            onChange={(e) => setLastName(e.target.value)}
            data-testid="last-name"
          />
        </div>
      </div>
      <TextInput
        value={title}
        placeholder="Title"
        className="w-full mb-4"
        onChange={(e) => setTitile(e.target.value)}
      />
      {/* <TextInput
        value={description}
        placeholder="Description"
        className="w-full mb-4"
        onChange={(e) => setDescription(e.target.value)}
      /> */}
    </Modal>
  )
}

export default PersonalInfoModal
