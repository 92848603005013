import * as React from 'react'

import cx from 'classnames'
import styled from 'styled-components'

import { darkTheme } from 'global/darkTheme'
import { mainTheme } from 'global/theme'

type MainThemeColors = typeof mainTheme.colors
type DarkThemeColors = typeof darkTheme.colors

const Track = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.1);
`
const Progress = styled.div`
  height: 100%;
  transition: 0.3s ease;
  border-radius: 30px;
`

interface ProgressBarRootProps {
  percentage?: number
  ratio?: number
  height?: number
  color?: keyof MainThemeColors | keyof DarkThemeColors
  theme?: 'dark' | 'main'
  label?: string
}

interface ProgressBarWithPercentage extends ProgressBarRootProps {
  percentage: number
}

interface ProgressBarWithRatio extends ProgressBarRootProps {
  ratio: number
}

type ProgressBarProps = ProgressBarWithPercentage | ProgressBarWithRatio

const ProgressBar: React.FC<ProgressBarProps> = ({
  percentage,
  ratio,
  color = 'green',
  theme = 'dark',
  height = 10,
  label,
}) => {
  if (ratio !== undefined) percentage = ratio * 100
  let progressWidth = (ratio || (percentage || 0) / 100) * 100
  if (progressWidth > 100) progressWidth = 100

  const colorCode = Object.entries(theme === 'dark' ? darkTheme.colors : mainTheme.colors).find(
    (e) => e[0] === color,
  )?.[1]

  return (
    <>
      {label && (
        <div className="flex mb-2 items-center justify-between">
          <div>
            <span
              className={cx(
                'text-xs font-semibold inline-block mr-3',
                'py-1 px-2 uppercase rounded-full text-green-800 bg-green-200',
              )}
            >
              {label}
            </span>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-green-600">
              {Math.ceil(percentage || 0)}%
            </span>
          </div>
        </div>
      )}
      <Track height={height} className="my-1" data-testid="progress-track">
        <Progress
          style={{
            width: `${progressWidth + 2.5}%`,
            maxWidth: '100%',
            backgroundColor: colorCode,
          }}
        />
      </Track>
    </>
  )
}

export default ProgressBar
