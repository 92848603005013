import React, { useMemo } from 'react'

import cx from 'classnames'
import CabalMessage from 'models/CabalMessage'
import moment from 'moment'

import MessageParsed from 'components/MessageParsed'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'

import useColorMode from 'utils/hooks/useColorMode'
import { replaceVariables } from 'utils/templates'
import { LimitedUser, MessageThread } from 'utils/types'

interface Props {
  message: CabalMessage
  messageThread?: MessageThread
  snippet?: string
  replyCount?: number
  isExpanded: boolean
  allowExpansion: boolean
  date?: string
  setExpand: () => void | null
  isUnread?: boolean
  user?: Pick<LimitedUser, 'first_name' | 'email' | 'name' | 'avatar_url'>
}

const SenderData: React.VFC<Props> = ({
  message,
  snippet: _snippet,
  replyCount,
  isExpanded,
  allowExpansion,
  date,
  setExpand,
  isUnread,
  messageThread,
  user: senderUser,
}) => {
  const matchFrom = message.from?.match(/(.*)<(\S+)>/)
  const { isMobile } = useColorMode()

  let name: string | null = null
  let email: string | null = null

  if (matchFrom && matchFrom.length === 3) {
    name = matchFrom[1]
    email = matchFrom[2]
  }

  const data = useMemo(() => {
    return {
      name: senderUser?.name || message?.sender?.name || name || message.From,
      email: senderUser?.email || message.sender?.email || email || message.From,
      first_name: senderUser?.first_name || message.sender?.first_name || name || message.From,
      avatar_url: senderUser?.avatar_url || message.sender?.avatar_url || '',
    }
  }, [senderUser, message, name, email])

  let body = message.body || ''
  let snippet = _snippet || message.snippet

  if (messageThread) {
    body = replaceVariables(body, {
      params: {
        first_name: messageThread.recipient.first_name,
        name: messageThread.recipient.name,
      },
    })

    snippet = replaceVariables(snippet, {
      params: {
        first_name: messageThread.recipient.first_name,
        name: messageThread.recipient.name,
      },
    })
  }

  return (
    <div
      className={cx('flex justify-between items-start w-full', {
        'cursor-pointer': allowExpansion && !isExpanded,
      })}
      onClick={() => !isExpanded && setExpand()}
    >
      <div className="flex w-full">
        <Avatar
          name={data.name}
          src={data.avatar_url}
          email={data.email}
          size="35"
          round
          className="flex-shrink-0"
        />

        <div className="flex-grow ml-2.5 overflow-hidden">
          <div
            className={cx('flex items-center justify-between', {
              'cursor-pointer': allowExpansion && isExpanded,
              'pb-1': !isExpanded,
              'pb-4': isExpanded,
            })}
            onClick={() => isExpanded && setExpand()}
          >
            <div className="flex items-center">
              <Typography
                fontSize="14"
                fontWeight={600}
                component="h3"
                lineHeight="1"
                className="mr-1"
              >
                {data.name}
              </Typography>

              {isUnread && <div className="mx-1 rounded-full w-2 h-2 bg-red-500"></div>}

              {replyCount && (
                <Typography
                  lineHeight={1}
                  fontSize="12"
                  color="purple"
                  fontWeight={isUnread ? 600 : undefined}
                >
                  {replyCount > 1 ? `${replyCount} replies` : `${replyCount} reply`}
                </Typography>
              )}
            </div>

            <div className="space-x-2">
              <Typography fontSize="12" color="gray" lineHeight={1} component="p">
                {isMobile
                  ? moment(date || message.sent_at || message.created_at).format('ll')
                  : moment(date || message.sent_at || message.created_at).format('lll')}
              </Typography>
            </div>
          </div>

          {isExpanded ? (
            <>
              <Typography fontSize="14" color="fog_rain" component="h3">
                <MessageParsed body={body} />
              </Typography>
            </>
          ) : (
            <Typography fontSize="14" color="fog" component="div" className="truncate">
              {snippet}
            </Typography>
          )}
        </div>
      </div>
    </div>
  )
}

export default SenderData
