import { useContext } from 'react'

import { ColorModeContext } from 'containers/App/Providers/ColorModeProvider'

const useColorMode = () => {
  const colorModeContext = useContext(ColorModeContext)
  return colorModeContext!
}

export default useColorMode
