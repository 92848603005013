import React, { useEffect } from 'react'

import { AxiosError } from 'axios'
import capitalize from 'lodash/capitalize'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import CompanySelector from 'components/CompanySelector'
import { Divider } from 'components/SendMessage/styles'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useCurrentUser, useTeams } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { PUBLIC_EMAIL_PROVIDERS } from 'utils/constants/public_email_providers'
import { AutoCompleteCompany } from 'utils/types'

import Header from '../Header'
import { Card, ContinueWrapper } from '../styles'
import CompanyRow from './CompanyRow'

interface Props {
  onContinue: () => void
  onSkipTo: (s: number) => void
  selectSlug: (slug: string) => void
}

const Workspace: React.FC<Props> = ({ onContinue, onSkipTo, selectSlug }) => {
  const { user, reloadUser } = useCurrentUser()

  const { teams, isLoading, reloadTeams } = useTeams()
  const history = useHistory()
  const [newCompany, setNewCompany] = React.useState<AutoCompleteCompany>()
  const [companies, setCompanies] = React.useState<AutoCompleteCompany[]>([])
  const [companyFromClearbit, setCompanyFromClearbit] = React.useState<AutoCompleteCompany>()
  const [hideUrlInput, setHideUrlInput] = React.useState(true)
  const domain = user.email.split('@')[1] || ''

  const createTeamMutation = useMutation(
    () =>
      callApi(api.createTeam, newCompany!).then(() => {
        reloadUser()
        reloadTeams()
      }),
    {
      onSuccess: () => onContinue(),
      onError: (e: AxiosError) => {
        cabalToast({ style: 'error', content: e.response?.data?.message })
      },
    },
  )

  const { isLoading: clearbitLoading } = useQuery(
    ['findOrganizations', domain],
    () => callApi(api.findOrganizations, domain),
    {
      onSuccess: (data) => {
        data.companies.forEach((company) => {
          if (company.domain === domain) {
            setCompanyFromClearbit(company)
          }
        })
      },
      staleTime: Infinity,
    },
  )

  useEffect(() => {
    if (teams && teams.length > 0 && !isLoading) {
      teams.forEach((team) => {
        const isCompanyExist = companies.some((comp) => comp.name === team.name)
        if (isCompanyExist) return
        setCompanies((prevCompanies) => [
          ...prevCompanies,
          {
            name: team.name,
            logo: team.logo,
            team_slug: team.slug,
            domain: team.website,
            has_access: true,
          },
        ])
      })
    }

    const userTeam = companies.find((company) => company?.team_slug === user?.team?.slug)

    if (teams && !isLoading && !clearbitLoading && !userTeam) {
      if (companyFromClearbit && companies.length === 0) {
        const companyExists = companies.filter((team) => team.name === companyFromClearbit.name)

        // Add if company doesn't already exist
        if (companyExists.length === 0) {
          setCompanies((prevCompanies) => [
            ...prevCompanies,
            {
              name: companyFromClearbit.name,
              logo: companyFromClearbit.logo,
              domain: companyFromClearbit.domain,
            },
          ])
        }
      }

      const isPublicDomain = PUBLIC_EMAIL_PROVIDERS.some((provider) => provider === domain)

      // Coundn't find company from clearbit
      if (!companyFromClearbit && !isLoading && !clearbitLoading && !isPublicDomain) {
        const name = domain.split('.')[0]
        setCompanies((prevCompanies) => [
          ...prevCompanies,
          {
            name: capitalize(name),
            logo: undefined,
            domain,
          },
        ])
      }
    }
  }, [teams, companyFromClearbit, domain, isLoading, clearbitLoading, companies, user])

  const handleCompanyChange = (company?: AutoCompleteCompany) => {
    setNewCompany(company)

    if (!company) {
      setHideUrlInput(true)
      return
    }

    if (!company.domain) {
      setHideUrlInput(false)
      setNewCompany(company)
      return
    }

    if (hideUrlInput) {
      const isCompanyExist = companies.some((comp) => comp.name === company.name)
      if (isCompanyExist) return

      const isPublicDomain = PUBLIC_EMAIL_PROVIDERS.some((provider) => provider === domain)
      const tempDomain = isPublicDomain ? '' : domain

      setCompanies((prevCompanies) => [
        ...prevCompanies,
        {
          name: company.name,
          logo: company.logo,
          team_slug: company.team_slug || undefined,
          domain: company.domain || tempDomain,
          has_access: company.has_access || false,
        },
      ])

      setNewCompany(undefined)
    }
  }

  const hasAccess = () => {
    if (window.QA || newCompany?.has_access) return true

    if (!newCompany || !newCompany.domain || newCompany.domain === '') {
      return false
    }

    const userEmailDomain = user.email.split('@')[1]
    const domain = newCompany.domain.toLowerCase().match(/^(?:https?:\/\/)?([\w-.]+)(\/.*)?$/)?.[1]

    if (
      (!!userEmailDomain && !!domain && domain === userEmailDomain) ||
      domain?.endsWith('.' + userEmailDomain)
    ) {
      return true
    } else {
      return false
    }
  }

  const showAppropriateCTA = () => {
    if (!hasAccess() && newCompany) {
      return (
        <div className="flex flex-col gap-3 mt-4 items-center sm:w-96">
          <span className="flex items-center flex-col">
            <Typography
              fontSize="12"
              color="fog"
              lineHeight={1}
              component="p"
              className="text-center"
            >
              No existing workspace for {newCompany.name}.
            </Typography>
            <Typography
              fontSize="12"
              color="fog"
              lineHeight={1}
              component="p"
              className="text-center"
            >
              <Typography
                component="button"
                color="purple"
                onClick={() => (window.location.href = '/logout')}
              >
                Login with a {newCompany.domain} email
              </Typography>{' '}
              to create the {newCompany.name} workspace.
            </Typography>
          </span>
        </div>
      )
    } else {
      return (
        <div className="sm:w-96 flex items-center justify-center">
          <CabalButton
            onClick={() => createTeamMutation.mutate()}
            working={createTeamMutation.isLoading}
            data-testid={`create-team-button`}
            className="w-3/5 mt-4"
          >
            Create
          </CabalButton>
        </div>
      )
    }
  }

  const yourTeam = companies.find((company) => company?.team_slug === user?.team?.slug)
  const yourCompanies = companies.filter((company) => company?.team_slug !== user?.team?.slug)

  return (
    <Card>
      <Header title="Create or join a workspace" subtitle="" />
      {yourTeam && (
        <div className="mt-6 mx-auto sm:w-80">
          <Typography lineHeight={1} fontSize="14" color="fog">
            Your Team
          </Typography>
          <CompanyRow
            company={yourTeam}
            onContinue={onContinue}
            onSkipTo={onSkipTo}
            selectSlug={selectSlug}
            hideDivider={true}
            index={0}
          />
        </div>
      )}
      {yourCompanies && yourCompanies.length > 0 && (
        <div className="mt-6 mx-auto sm:w-80">
          <Typography lineHeight={1} fontSize="14" color="fog">
            Your Companies
          </Typography>
          <div className="max-h-[200px] overflow-scroll">
            {yourCompanies.map((company, idx) => (
              <div key={company.name + idx}>
                <CompanyRow
                  company={company}
                  onContinue={onContinue}
                  onSkipTo={onSkipTo}
                  selectSlug={selectSlug}
                  index={idx + 1}
                  hideDivider={idx === yourCompanies.length - 1}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="mt-6 flex flex-col gap-2 items-center justify-center w-80 mx-auto">
        <CompanySelector
          onCompanyChange={handleCompanyChange}
          companyData={newCompany}
          canCreate
          hideUrlInput={hideUrlInput}
          placeholder="Find a company or firm"
          className="w-full"
        />
        {newCompany?.name && newCompany?.domain && showAppropriateCTA()}
      </div>
      <ContinueWrapper className="flex flex-col">
        <CabalButton onClick={() => history.push('/home')} variant="tertiary" className="mt-2">
          Or skip to my profile
        </CabalButton>
      </ContinueWrapper>
    </Card>
  )
}

export default Workspace
