import styled from 'styled-components'
import tw from 'twin.macro'

export const Divider = styled.div`
  ${tw`px-4 rotate-90`}
  border-bottom: ${({ theme }) => theme.border};
`

export const HorizontalDivider = styled.div`
  ${tw`p-1 -mx-2 transform`}
  border-bottom: 1px solid ${({ theme }) => theme.side_nav.active_item_bg_color};
`

export const SourcePill = styled.div<{ selected?: boolean }>`
  ${tw`rounded-lg p-1.5 cursor-pointer mt-2`}
  border: ${({ theme, selected }) =>
    selected ? `2px solid ${theme.colors.purple}` : `2px solid ${theme.table.bg_color}`};
  font-weight: ${({ selected }) => (selected ? `600` : `400`)};
  color: ${({ theme, selected }) => (selected ? theme.colors.purple : theme.colors.rain_fog)};
  background-color: ${({ theme }) => theme.table.bg_color};
`
export const FilterPill = styled(SourcePill)`
  ${tw`mt-0`}
  border: ${({ theme, selected }) =>
    selected ? `2px solid ${theme.colors.table_header}` : `2px solid ${theme.table.bg_color}`};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.table_header : theme.table.bg_color};
  color: ${({ theme }) => theme.colors.rain_fog};
  font-weight: 400;
`

export const CompanyCol = styled.div`
  ${tw`px-2`}
  min-width: 30%;
  display: flex;
`

export const StageCol = styled.div`
  ${tw`px-2 flex-wrap flex`}
  min-width: 25%;
  display: flex;
`

export const OwnerCol = styled.div`
  ${tw`px-2`}
  min-width: 20%;
  display: flex;
`

export const UpdatedCol = styled.div`
  ${tw`px-2`}
  min-width: 15%;
  display: flex;
`

export const ConnectionsCol = styled.div`
  ${tw`px-2`}
  min-width: 20%;
`

export const RequestsCol = styled.div`
  ${tw`px-2 flex-wrap flex`}
  min-width: 5%;
  display: flex;
`

export const ContectCol = styled.div`
  ${tw`px-2`}
  min-width: 20%;
  display: flex;
`

export const StatusWrapper = styled.div`
  ${tw`mb-4 items-center px-5 py-4 rounded-lg`}
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ theme }) => theme.layout.main_bg_color};
  border: 2px solid ${({ theme }) => theme.layout.main_bg_color};
`

export const DownChevron = styled.i`
  color: ${({ theme }) => theme.colors.fog};
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
`

export const StickyHeader = styled.div`
  ${tw`sticky z-10`}
  top: 0px;
  background-color: ${({ theme }) => theme.layout.main_bg_color};
`

export const DegreeFilters = styled.button`
  ${tw`px-3 py-1.5 transition whitespace-nowrap rounded-full`}

  width: 125px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.purple};
  border: 1px solid ${({ theme }) => theme.colors.purple};

  &:not(.selected):hover,
  &:not(.selected):focus {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.rain_fog};
    z-index: 2;
  }

  i {
    font-size: 16px;
  }

  &.selected {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.purple};

    i {
      font-weight: 600;
    }
  }
`

export const SecondDegreeResults = styled.div`
  background-color: ${({ theme }) => theme.layout.main_content_bg_color};
`
