import React from 'react'

import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

interface Props {
  onShowAddWidget: () => void
  title: string
  description: string
  buttonLabel: string
}

const ResourcesListEmptyState: React.FC<Props> = ({
  onShowAddWidget,
  title,
  description,
  buttonLabel,
}) => {
  const { adminView } = useResourcesListContext()
  return (
    <div className="flex items-center justify-center my-16">
      <div className="flex flex-col items-center justify-center gap-4">
        <Typography color="fog" fontSize="20">
          <i className="far fa-building" />
        </Typography>
        <Typography fontSize="14" lineHeight={1}>
          {title}
        </Typography>

        {adminView && (
          <>
            <Typography fontSize="12" lineHeight={1}>
              {description}
            </Typography>
            <CabalButton onClick={() => onShowAddWidget()} leftIcon={<i className="far fa-plus" />}>
              {buttonLabel}
            </CabalButton>
          </>
        )}
      </div>
    </div>
  )
}

export default ResourcesListEmptyState
