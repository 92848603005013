import React, { useCallback, useState } from 'react'

import cx from 'classnames'
import { useQuery } from 'react-query'
import validator from 'validator'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { Select, SelectOption, SelectProps, TextInput } from 'global/Input'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { AutoCompleteCompany } from 'utils/types'

interface ApiParams {
  exclude_teams?: boolean
}

interface Props {
  onCompanyChange?: (c: AutoCompleteCompany | undefined) => void
  padCompanyDomainField?: boolean
  autoFocus?: boolean
  companyData?: AutoCompleteCompany
  forceUpdate?: boolean
  placeholder?: string
  className?: string
  canCreate?: boolean
  onFetchSuggestions?: (value: string) => any
  apiParams?: ApiParams
  onCompanySelected?: (c: AutoCompleteCompany | undefined) => void
  showAddButtonIfCreate?: boolean
  defaultCompanyName?: string
  onBlur?: () => void
  onInputChange?: () => void
  selectProps?: Partial<SelectProps<AutoCompleteCompany>>
  hideUrlInput?: boolean
}

const AutoCompleteLabel: React.FC<{ company: AutoCompleteCompany }> = ({ company }) => {
  return (
    <Typography>
      <Avatar size="16" src={company.logo} name={company.name} className="mr-2" />
      {company.name}
    </Typography>
  )
}

const CompanySelector: React.FC<Props> = ({
  onCompanyChange,
  padCompanyDomainField = false,
  autoFocus = false,
  companyData = null,
  forceUpdate = false,
  placeholder = 'Company Name',
  canCreate = true,
  className,
  onFetchSuggestions = null,
  apiParams = {},
  onCompanySelected = null,
  showAddButtonIfCreate = false,
  defaultCompanyName,
  onInputChange = () => {},
  hideUrlInput = false,
  selectProps,
}) => {
  const [selectKey, setSelectKey] = useState(0)
  const [companyName, setCompanyName] = useState<string | undefined>(defaultCompanyName)
  const [customDomain, setCustomDomain] = useState<string | undefined>()
  const [addingCustomDomain, setAddingCustomDomain] = useState<boolean>(false)

  const { data, isLoading } = useQuery(
    ['findOrganizations', companyName],
    () => callApi(api.findOrganizations, companyName!, apiParams),
    {
      enabled: !!companyName,
      staleTime: Infinity,
    },
  )

  const options = (data?.companies || []).map<SelectOption<AutoCompleteCompany>>((c) => ({
    label: (
      <span className="flex items-center">
        <AutoCompleteLabel company={c} />
        {c.team_slug && (
          <Typography color="purple">
            <i className="fa fa-badge-check fa-fw ml-2" />
          </Typography>
        )}
      </span>
    ),
    value: c,
    toString: () => c.name + c.domain,
  }))

  const invalidWebsiteInput = useCallback((c: string | undefined) => {
    return !c || !validator.isURL(c)
  }, [])

  const handleCompanySelected = (s: AutoCompleteCompany | undefined) => {
    onCompanyChange?.(s)
    onCompanySelected?.(s)
  }

  const setCompany = (c: Partial<AutoCompleteCompany>) => {
    const updatedCompany = { ...companyData, ...c }
    handleCompanySelected(updatedCompany as AutoCompleteCompany)
  }

  return (
    <div className={cx(className, 'flex flex-col gap-2')}>
      <Select<AutoCompleteCompany>
        key={selectKey}
        {...selectProps}
        autoFocus={autoFocus}
        value={companyData || undefined}
        onInputChange={(v) => {
          setCompanyName(v)
          onInputChange()
        }}
        options={options}
        noDropdownIndicator
        isLoading={isLoading}
        noOptionsMessage={() => 'Enter a few keywords to search'}
        placeholder={placeholder}
        isClearable
        creatable
        data-testid="company-select"
        unknownValueToOption={(v) => ({
          label: <AutoCompleteLabel company={v} />,
          value: v,
          toString: () => v.name + v.domain,
        })}
        formatCreateLabel={(v) => `+ Create "${v}"`}
        onChange={(v) => {
          onInputChange()
          if (v) {
            if (companyData === null) {
              // hack to clear the input
              setSelectKey(selectKey + 1)
            }
            setCompany(v)
          } else {
            handleCompanySelected(undefined)
          }
          setAddingCustomDomain(false)
          setCustomDomain(undefined)
        }}
        onCreateOption={(v) => {
          setCompany({ name: companyName, domain: '', logo: '' })
          setAddingCustomDomain(true)
          setCustomDomain(undefined)
        }}
      />
      {!hideUrlInput && addingCustomDomain && (
        <>
          <TextInput
            placeholder="Company domain"
            className={cx('w-full')}
            valueType="website"
            value={customDomain}
            onChange={(e) => {
              onInputChange()
              setCustomDomain(e.target.value)
              if (validator.isURL(e.target.value)) {
                if (showAddButtonIfCreate) {
                  setCompanyName(e.target.value)
                } else {
                  setCompany({ domain: e.target.value })
                }
              } else {
                setCompany({ domain: undefined })
              }
            }}
            data-testid="company-domain"
          />
        </>
      )}
      {showAddButtonIfCreate && companyName && (
        <CabalButton
          onClick={() => {
            setCompanyName(undefined)
          }}
          variant="primary"
          disabled={invalidWebsiteInput(companyName)}
          className="mt-2"
        >
          Add
        </CabalButton>
      )}
    </div>
  )
}

export default CompanySelector
