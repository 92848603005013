import React, { useEffect, useMemo, useState } from 'react'

import Select from 'react-select'
import styled from 'styled-components'

import { useCompanySearch } from 'components/Winsite/queries/CompanySearch'
import { ISearchResult } from 'components/Winsite/types/ISearchResult'
import Avatar from 'global/Avatar'
import { TextInput } from 'global/Input'

import useColorMode from 'utils/hooks/useColorMode'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'

interface IProps {
  onChange: (companyName: string | null) => void
  placeHolder: string
}

const StyledTextInput = styled(TextInput)`
  background-color: white;
  color: black;
`

export function CompanyInput({ onChange, placeHolder }: IProps) {
  const [query, setQuery] = useState('')
  const deboundedQuery = useDebouncedValue(query, 500)
  const [selectedResult, setSelectedResult] = useState<string | null>(null)
  const [toggleVisibility, setToggleVisibility] = useState<boolean>(true)
  const { isLoading, data: companyNames } = useCompanySearch(deboundedQuery)
  let results: string[] = useMemo(() => companyNames?.data || [], [companyNames])
  const logo_url = 'https://logo.clearbit.com/'

  useEffect(() => {
    const handleKeyPress = (event: { key: string }) => {
      if (event.key === 'Escape') {
        setToggleVisibility(false)
      }
    }

    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  const { isMobile } = useColorMode()

  const prepareLabel = (result: ISearchResult) => {
    return (
      <div className="flex flex-row items-center gap-x-2">
        {result.name}
        {result.website && (
          <Avatar name={result.name} src={logo_url + result.website} size="16px" />
        )}
      </div>
    )
  }

  const handleSelect = (result: string) => {
    setToggleVisibility(false)
    setSelectedResult(result)
    setQuery(result)
    onChange(result)
    results = []
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggleVisibility(true)
    setQuery(event.target.value)
    setSelectedResult(event.target.value)
    onChange(event.target.value)
  }

  const options = useMemo(
    () =>
      results.map((result: ISearchResult) => ({
        value: result.name,
        label: prepareLabel(result),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results],
  )

  return (
    <div className="max-w-[308px]">
      <StyledTextInput
        style={{ width: '100%' }}
        value={selectedResult || ''}
        onChange={handleChange}
        placeholder={placeHolder}
      />
      {deboundedQuery && deboundedQuery.length > 0 && toggleVisibility && (
        <Select
          styles={{
            control: () => ({
              display: 'none',
            }),
            menu: () => ({
              fontSize: 12,
              backgroundColor: 'white',
              color: 'black',
              cursor: 'pointer',
            }),
          }}
          name="company-name-select"
          options={options}
          onChange={(e) => handleSelect(e.value)}
          menuIsOpen={true}
          maxMenuHeight={isMobile ? 150 : 200}
          noOptionsMessage={() =>
            isLoading ? (
              <i className="fa-spin fa fa-spinner" />
            ) : (
              <div
                style={{ textAlign: 'left' }}
                onClick={() => {
                  setToggleVisibility(false)
                }}
              >
                {selectedResult}
              </div>
            )
          }
        />
      )}
    </div>
  )
}
