import React, { useState } from 'react'

import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import CabalButton from 'global/CabalButton'
import { Select, TextArea } from 'global/Input'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { CompanySlugParam, Team } from 'utils/types'

interface Props {
  onHide: () => void
}

const EditContextModal: React.VFC<Props> = (props) => {
  const { onHide } = props

  const { company_slug } = useParams<CompanySlugParam>()
  const { team, reloadTeam } = useTeam(company_slug)
  const queryClient = useQueryClient()

  const [asksContext, setAsksContext] = useState(
    team?.sales_asks_prompt ||
      `Welcome to our Deal Board. Here you'll find a list of our current prospects, sorted by the companies you're most connected to. Thanks for helping us grow!`,
  )

  const [salesAskPromptUserId, setSalesAskPromptUserId] = useState(
    team?.sales_ask_prompt_user_id || team?.founder.id,
  )

  const updateTeamMutation = useMutation(
    ['updateTeam'],
    (val: Partial<Team>) => callApi(api.updateTeam, company_slug, { team: val }),
    {
      onSuccess: () => {
        reloadTeam()
        onHide()
      },
    },
  )

  const teamAdminOptions =
    team?.admins_and_members
      .filter((u) => u.team_role === 'admin')
      .map((u) => ({ value: u.uuid, label: u.name })) || []

  return (
    <Modal show onHide={onHide} header="Edit Welcome Message">
      <>
        <TextArea
          className="pt-1 mb-4 w-full"
          labeled
          value={asksContext}
          onChange={(e) => setAsksContext(e.currentTarget.value)}
          disabled={
            updateTeamMutation.isLoading && !!updateTeamMutation.variables?.['sales_asks_prompt']
          }
          rows={6}
          label={
            <Typography fontSize="14" fontWeight={600} color={'primary'}>
              Welcome Message :
            </Typography>
          }
        />

        <Typography fontWeight={600} component="h1" className="mb-1.5">
          Welcome Message From:
        </Typography>
        <Select
          value={salesAskPromptUserId}
          className="mb-3"
          onChange={(option) => option && setSalesAskPromptUserId(option)}
          placeholder="Select user"
          options={teamAdminOptions}
        />
      </>

      <div className="mt-6 text-center">
        <CabalButton
          className="mb-5"
          working={updateTeamMutation.isLoading}
          onClick={() =>
            updateTeamMutation.mutateAsync({
              sales_asks_prompt: asksContext,
              sales_ask_prompt_user_id: salesAskPromptUserId,
              sales_asks_prompt_changed_at: new Date(),
            })
          }
        >
          Save
        </CabalButton>
      </div>
    </Modal>
  )
}

export default EditContextModal
