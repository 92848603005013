import React from 'react'

import { useQuery } from 'react-query'

import AddConnectionsRow from 'containers/Home/GlobalForYouWidget/AddConnectionsRow'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'

const GlobalForYouWidget = () => {
  const { data: status, isLoading } = useQuery(
    ['connectionStatus'],
    () => callApi(api.getHomeConnectionsStats),
    {
      refetchInterval: 1000 * 3,
      refetchIntervalInBackground: !window.DISABLE_CONNECTION_STATUS_POLLING,
    },
  )

  const connectionsCount = status?.connections_count || status?.linkedin_export?.count || 0
  // const google_contacts_count = status?.google_contacts?.count || 0
  const totalCount = connectionsCount

  if (isLoading || totalCount > 0) return <></>
  return (
    <Widget title="For You">
      <AddConnectionsRow />
    </Widget>
  )
}

export default GlobalForYouWidget
