import React from 'react'
import styled from 'styled-components'

import Typography from 'global/Typography'
import DropDownMenu from 'global/DropDownMenu'
import { LimitedUser } from 'utils/types'
import Loading from 'global/Loading'
import Avatar from 'global/Avatar'

interface Props {
  isLoading: boolean
  size?: string
  users: LimitedUser[]
  onSelect: (user: LimitedUser) => void
}

const MessageCollaboratorsAdd: React.VFC<Props> = ({ isLoading, size = '32', users, onSelect }) => {
  return users.length > 0 ? (
    <DropDownMenu
      disabled={isLoading}
      trigger={
        <StyledDiv
          className="flex items-center justify-center rounded-full cursor-pointer"
          $size={size}
        >
          {isLoading ? <Loading size={'16px'} /> : <Typography className="far fa-plus" />}
        </StyledDiv>
      }
      menuItems={users.map((user) => ({
        label: (
          <div className="items-center flex gap-2">
            <Avatar src={user.avatar_url} name={user.name} size="24" />
            <Typography>{user.name}</Typography>
          </div>
        ),
        onSelect: () => onSelect(user),
      }))}
    />
  ) : null
}

interface StyledDivProps {
  $size: string
}

const StyledDiv = styled.div<StyledDivProps>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;

  background-color: ${({ theme }) => theme.colors.border};
`

export default MessageCollaboratorsAdd
