import PageWrapper from 'global/PageWrapper'
import React from 'react'
import LikesTracker, { LikesTrackerProps } from '.'

const LikesTrackerPage: React.VFC<LikesTrackerProps> = (props) => {
  return (
    <PageWrapper title="Offers">
      <LikesTracker {...props} />
    </PageWrapper>
  )
}

export default LikesTrackerPage
