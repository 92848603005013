import React from 'react'

import styled from 'styled-components'

const ColumnDoubleLayout = styled.div`
  display: grid;
  row-gap: 32px;
  align-items: flex-start;

  @media (min-width: 850px) {
    grid-template-columns: minmax(auto, 400px) minmax(auto, 1fr);
    column-gap: 68px;
  }
`

const LayoutSection = ({
  title,
  description,
  image,
  id,
  cta,
}: {
  title: string
  description: string
  image: string
  id: string
  cta?: React.ReactNode
}) => {
  return (
    <>
      <section className="block max-w-6xl mx-auto py-24" id={id}>
        <ColumnDoubleLayout className="">
          <div className="flex flex-col">
            <h3 className="text-white font-semibold text-4xl tracking-tighter">{title}</h3>
            <p className="text-white tracking-tight font-light text-[28px] mt-4">{description}</p>
            <div className="mt-14">{cta}</div>
          </div>

          <div className="">
            <img src={image} alt={title} />
          </div>
        </ColumnDoubleLayout>
      </section>
    </>
  )
}

export default LayoutSection
