import React, { useEffect, useState } from 'react'

import axios, { AxiosError } from 'axios'
import { Field, FieldProps, Form, Formik } from 'formik'
import Cookies from 'js-cookie'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { object, string } from 'yup'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { TextArea, TextAreaField, TextInputField } from 'global/Input'
import { Select } from 'global/Input/Select'
import Loading from 'global/Loading'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { useSearchParams } from 'utils/hooks/useSearchParams'
import { IFacilitatePublicIntroRequest } from 'utils/types/IFacilitateIntro'

interface IRequestReasons {
  [key: string]: string
}

const requestIntroSchema = object({
  first_name: string().required('First name is required'),
  last_name: string().required('Last name is required'),
  email: string().email('Invalid email').required('Email is required'),
  linkedin: string().required('LinkedIn URL is required'),
  note_to_facilitator: string().required('Note is required'),
})

const RequestIntroModal = () => {
  const history = useHistory()
  const searchParams = useSearchParams()

  const [selectedReason, setSelectedReason] = useState<{ label: string; value: string } | null>(
    null,
  )
  const [facilitatorKey, setFacilitatorKey] = useState<string | null | undefined>(undefined)

  const { company_slug, list_id, item_id } = useParams<{
    company_slug: string
    list_id: string
    item_id: string
  }>()

  useEffect(() => {
    const userUuid = searchParams.get('facilitator_key')
    setFacilitatorKey(userUuid)
  }, [searchParams])

  const { data: publicIntroRequest, isLoading } = useQuery<IFacilitatePublicIntroRequest>(
    ['newPublicIntroRequestV2', company_slug, list_id, item_id, facilitatorKey],
    () => callApi(api.newPublicIntroRequestV2, company_slug, list_id, item_id, facilitatorKey),
  )

  const { data: requestReasonsOptions } = useQuery<IRequestReasons>(['requestReasons'], () =>
    callApi(api.getIntroRequestReasons, list_id),
  )

  const { mutate: submitPublicIntroRequest, isLoading: submittingPublicIntroRequest } = useMutation(
    ['submitPublicIntroRequest'],
    (values) =>
      callApi(api.createPublicIntroRequestV2, {
        company_slug,
        ...publicIntroRequest,
        ...values,
        facilitator_user_uuid: facilitatorKey,
        company_list_id: list_id,
        linkedin_url: values.linkedin,
        note: values.note_to_facilitator,
        request_reason: selectedReason?.value,
        request_type: 'advisor',
      }),
    {
      onSuccess: (data) => {
        if (
          data.request_type === 'non_advisor' &&
          (data.requestable_type === 'InvestorCompanyJob' || data.request_reason === 'job')
        ) {
          Cookies.set(
            `latest_non_advisor_job_intro_request`,
            JSON.stringify({
              company_slug,
              facilitator_user_uuid: data.facilitator.uuid,
              secret_uuid: data.secret_uuid,
            }),
          )
        }
        history.push(
          `/intro-requests/${company_slug}/${list_id}/${item_id}/success/${data.secret_uuid}`,
        )

        cabalToast({
          style: 'success',
          fadeOut: false,
          content: `Intro requested! The ${publicIntroRequest?.team_name} team will follow up soon.`,
        })
        setTimeout(() => {
          document.getElementById('intro-request-public-page')?.scrollTo({ top: 0 })
        }, 100)
      },
      onError: (e: AxiosError) => {
        if (e.response?.status === 404) {
          cabalToast({ style: 'error', content: e.response.data.error })
        }
      },
    },
  )

  const handleReasonClick = (reason: string | null) => {
    const newValue = { label: requestReasonsOptions[reason], value: reason }
    setSelectedReason(newValue)
  }

  const handleLogin = () => {
    const privateIntroRequestPath = {
      pathname: `/${company_slug}/lists/${list_id}/${item_id}`,
      search: '?intro=true',
    }
    history.push('/login', { from: privateIntroRequestPath })
  }

  if (isLoading) return <Loading />

  return (
    <Widget>
      <div className="px-6 pb-6">
        <Typography component="h1" fontSize="20" fontWeight={600} className="mb-2 text-center">
          Request an Introduction to {publicIntroRequest?.requestable_name}
        </Typography>

        {publicIntroRequest?.requestable_subheader && (
          <Typography
            component="p"
            fontSize="14"
            fontWeight={600}
            color="fog_rain"
            className="text-center mb-8"
          >
            Role: {publicIntroRequest?.requestable_subheader}
          </Typography>
        )}

        <div className="flex justify-center items-center mt-4 mb-4">
          <Avatar src={publicIntroRequest?.requestable_avatar_url} size="64" round />
        </div>

        <Typography component="p" fontSize="12" className="text-center mb-8">
          Facilitated by {publicIntroRequest?.team_name}
        </Typography>

        <Formik
          initialValues={{
            email: '',
            first_name: '',
            last_name: '',
            linkedin: '',
            note_to_facilitator: '',
          }}
          validationSchema={requestIntroSchema}
          onSubmit={(values) => {
            submitPublicIntroRequest(values)
          }}
          isInitialValid={false}
        >
          {({ isValid }) => (
            <Form>
              <ModalInputWrapper className="mb-5 flex justify-center items-center">
                <ModalInputLabel className="mr-2">First Name</ModalInputLabel>
                <Field name="first_name">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      type="text"
                      placeholder="Your first name"
                      className="w-full"
                      error={props.meta.touched ? props.meta.error : undefined}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="mb-5 flex justify-center items-center">
                <ModalInputLabel className="mr-2">Last Name</ModalInputLabel>
                <Field name="last_name">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      type="text"
                      placeholder="Your last name"
                      className="w-full"
                      error={props.meta.touched ? props.meta.error : undefined}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="mb-5 flex justify-center items-center">
                <ModalInputLabel className="mr-2">Email</ModalInputLabel>
                <Field name="email">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      type="email"
                      placeholder="Your email address"
                      className="w-full"
                      error={props.meta.touched ? props.meta.error : undefined}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="mb-5 flex justify-center items-center">
                <ModalInputLabel className="mr-2">LinkedIn</ModalInputLabel>
                <Field name="linkedin">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      type="text"
                      placeholder="https://www.linkedin.com/in/username"
                      className="w-full"
                      error={props.meta.touched ? props.meta.error : undefined}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              {publicIntroRequest?.is_portfolio_company && (
                <ModalInputWrapper className="mb-6 flex justify-center items-center">
                  <ModalInputLabel className="mr-2">Purpose for request</ModalInputLabel>
                  <Select
                    options={
                      requestReasonsOptions
                        ? Object.entries(requestReasonsOptions).map(([key, value]) => ({
                            value: key,
                            label: value,
                          }))
                        : []
                    }
                    value={selectedReason ? selectedReason.value : null}
                    onChange={(v) => handleReasonClick(v)}
                    placeholder="Select a reason"
                    isClearable
                    className="w-full"
                  />
                </ModalInputWrapper>
              )}

              <ModalInputWrapper className="mb-6 flex justify-center items-center">
                <ModalInputLabel className="mr-2">Note</ModalInputLabel>
                <Field name="note_to_facilitator">
                  {(props: FieldProps) => (
                    <TextAreaField
                      {...props.field}
                      placeholder={`Add a note about why you're interested in connecting with ${publicIntroRequest?.requestable_name}.`}
                      className="w-full"
                      rows={3}
                      error={props.meta.touched ? props.meta.error : undefined}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <CabalButton
                type="submit"
                className="w-full"
                disabled={
                  !isValid || (!publicIntroRequest?.is_portfolio_company ? false : !selectedReason)
                }
                working={submittingPublicIntroRequest}
              >
                Send request to {publicIntroRequest?.team_name}
              </CabalButton>

              <div className="flex justify-center items-center">
                <CabalButton variant="link" className="w-full" onClick={handleLogin}>
                  Or login to autofill form
                </CabalButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Widget>
  )
}

export default RequestIntroModal
