import Avatar from 'global/Avatar'
import * as React from 'react'
import styled from 'styled-components'

// Avatars
import andi from './images/avatars/andi.png'
import andrew from './images/avatars/andrew.png'
import cassie from './images/avatars/cassie.png'
import hannah from './images/avatars/hannah.png'
import kris from './images/avatars/kris.png'
import leah from './images/avatars/leah.png'
import mahyar from './images/avatars/mahyar.png'
import niv from './images/avatars/niv.png'
import paul from './images/avatars/paul.png'

// Logos

import s_craft from './images/logos/s_craft.png'
import s_gtmfund from './images/logos/s_gtmfund.png'
import s_microacquire from './images/logos/s_microacquire.png'
import s_modernhealth from './images/logos/s_modernhealth.png'
import s_openphone from './images/logos/s_openphone.png'
import s_sendoso from './images/logos/s_sendoso.png'
import s_shrug from './images/logos/s_shrug.png'
import s_stage2 from './images/logos/s_stage2.png'
import s_tlvpartners from './images/logos/s_tlvpartners.png'

interface Props {
  label: string
}

const Wrapper = styled.div<{ color?: string }>`
  border: 2px solid ${({ color }) => (color ? color : '#000')};
  border-radius: 8px;
  padding: 24px;
  margin-top: 48px;
`

const Quote = styled.p`
  color: #15192a !important;
  font-size: 16px;
  line-height: 1.5;
`

const Name = styled.p`
  color: #000 !important;
  font-size: 14px;
  line-height: 21px;
`

const Title = styled(Quote)`
  color: #718096 !important;
  font-size: 14px;
  line-height: 21px;
`

const Img = styled.img`
  height: 44px;
`

const ReferenceCard: React.VFC<Props> = ({ label }) => {
  let logo = ''
  let quote = ''
  let name = ''
  let title = ''
  let color = ''
  let avatar = ''

  if (label === 'Marketing') {
    logo = s_microacquire
    quote = `The fastest way to build an audience is to tap into your network to amplify your message.
    Cabal has transformed my ability to expand my reach and elevate our brand.`
    name = 'Andrew Gazdecki'
    title = 'Co-Founder & CEO, MicroAcquire'
    color = '#3E50F7'
    avatar = andrew
  } else if (label === 'Sales') {
    logo = s_modernhealth
    quote = `We used to build lists of target companies in a spreadsheet, cross-check with our investor’s connections, and spend cycles going back and forth on email. Cabal makes it simple to share our pipeline and collect referrals.`
    name = 'Hannah Willson'
    title = 'SVP Sales, Modern Health'
    color = '#8CACEA'
    avatar = hannah
  } else if (label === 'Investor Updates') {
    logo = s_openphone
    quote = `Monthly updates are essential for OpenPhone. They force a rhythm of reflection and planning and ensure our partners are aware of where we’re at and how they can help. Cabal has made drafting more efficient, delivery more targeted, and the content more engaging.`
    name = 'Mahyar Raissi'
    title = 'Co-Founder & CEO, OpenPhone'
    color = '#6333FF'
    avatar = mahyar
  } else if (label === 'Advisor Program') {
    logo = s_sendoso
    quote = `Our advisor strategy has led to millions of dollars of ARR, hundreds of thousands saved on recruiting fees, and accelerated our category leadership. The secret to activating it is Cabal.`
    name = 'Kris Rudeegraap'
    title = 'Co-Founder & CEO, Sendoso'
    color = '#F37021'
    avatar = kris
  } else if (label === 'Portfolio Engagement') {
    logo = s_gtmfund
    quote = `Cabal is my secret weapon. I can organize events, onboard founders, react to needs, and jump on opportunities to make an impact across our portfolio in minutes because Cabal puts everything at my fingertips.`
    name = 'Paul Irving'
    title = 'Platform Director, GTM Fund'
    avatar = paul
  } else if (label === 'Talent Management') {
    logo = s_craft
    quote = `When we match great talent with exciting opportunities across our portfolio, everyone wins. Cabal’s smartly designed intro automation creates a professional experience that results in better outcomes with less overhead.`
    name = 'Cassie Chao'
    title = 'Talent Partner, Craft Ventures'
    avatar = cassie
  } else if (label === 'Business Development') {
    logo = s_stage2
    quote = `At Stage 2 Capital, we have 400+ GTM leaders as LPs and are committed to connecting them across our network especially with our portfolio companies. Cabal has transformed the way we surface and facilitate key intros, a definite must have for our comms stack.`
    name = 'Andi Smith'
    title = `Operating Partner, LP Relations, Stage 2 Capital `
    avatar = andi
  } else if (label === 'Founder Portal') {
    logo = s_tlvpartners
    quote = `Cabal’s purpose-built portal allows us to scale our impact. The design is a big upgrade to how we used to share this info - and a great reflection of our brand.`
    name = 'Leah Zuckerman'
    title = `Head of Platform, TLV Partners`
    avatar = leah
  } else if (label === 'Fundraising & SPVS') {
    logo = s_shrug
    quote = `Cabal has changed the way we engage our LPs and organize capital. Raising capital for our funds and SPVs has gotten 10x more efficient and much more personable by using Cabal.`
    name = 'Niv Dror'
    title = `Managing Partner, Shrug Capital`
    avatar = niv
  }

  return (
    <Wrapper color={color}>
      <Img src={logo} />
      <Quote color={color} className="pb-6 pt-3">
        “{quote}”
      </Quote>
      <div className="flex">
        <Avatar src={avatar} name={name} round size="40" className="mr-3" />
        <span>
          <Name>{name}</Name>
          <Title>{title}</Title>
        </span>
      </div>
    </Wrapper>
  )
}

export default ReferenceCard
