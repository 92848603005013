import React from 'react'

import * as RadixSwitch from '@radix-ui/react-switch'

/**
 * To see available props, check: https://www.radix-ui.com/primitives/docs/components/switch#api-reference
 */
const Switch: React.FC<RadixSwitch.SwitchProps> = (props) => (
  <RadixSwitch.Root
    {...props}
    className="w-6 p-px rounded-full dark:bg-[#2D3748] bg-[#A7B4C7] data-[state=checked]:bg-[#5C69D1] dark:data-[state=checked]:bg-[#5C69D1]"
  >
    <RadixSwitch.Thumb className="block w-3 h-3 bg-white rounded-full transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[10px]" />
  </RadixSwitch.Root>
)

export default Switch
