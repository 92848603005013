import React, { useState, useEffect } from 'react'
import Modal from 'global/Modal'
import CabalButton from 'global/CabalButton'
import { AdvisorModel } from 'utils/types'
import TextEditor from 'global/TextEditor'
import { useMutation } from 'react-query'
import api, { callApi } from 'utils/api'

interface Props {
  show: boolean
  onHide: () => void
  advisor: AdvisorModel
  refetch: () => void
  noteId: number
  update: boolean
  content: string
}

const NotesModal = ({ show, onHide, advisor, refetch, noteId, update, content }: Props) => {
  const [value, setValue] = useState(content)

  const createNote = useMutation(
    () => callApi(api.createNote, advisor.uuid, value).then(() => refetch()),
    {
      onSuccess: () => {
        onHide()
      },
    },
  )

  const editNote = useMutation(
    () => callApi(api.updateNote, advisor.uuid, noteId, value).then(() => refetch()),
    {
      onSuccess: () => {
        onHide()
      },
    },
  )

  return (
    <Modal
      show={show}
      onHide={onHide}
      header={update ? 'Update note' : 'Create new note'}
      rightActions={
        <CabalButton
          working={createNote.isLoading}
          onClick={update ? () => editNote.mutate() : () => createNote.mutate()}
        >
          Save
        </CabalButton>
      }
    >
      <div className="w-full">
        <TextEditor
          value={value}
          onChange={(value) => setValue(value)}
          data-testid="edit-resource-editor"
        />
      </div>
    </Modal>
  )
}

export default NotesModal
