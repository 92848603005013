import React, { useEffect, useState } from 'react'

import Cookies from 'js-cookie'
import { useParams } from 'react-router-dom'

import LoginPage from 'containers/LoginPage'
import Widget from 'ui-components/Widget'

const IntroRequestPublicSuccess = () => {
  const { secret_uuid } = useParams<{ secret_uuid: string }>()
  const [header, setHeader] = useState<string | null>(null)

  useEffect(() => {
    const latestJobIntroReqJSON = Cookies.get(`latest_non_advisor_job_intro_request`)
    const latestJobIntroReq = latestJobIntroReqJSON ? JSON.parse(latestJobIntroReqJSON) : null
    setHeader(
      latestJobIntroReq && latestJobIntroReq.secret_uuid === secret_uuid
        ? 'Log in to view open roles'
        : null,
    )
  }, [secret_uuid])

  return (
    <Widget classNames="w-96 p-8">
      <LoginPage isModal={true} header={header} />
    </Widget>
  )
}

export default IntroRequestPublicSuccess
