import React, { useState } from 'react'

import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'

import PageHeader from 'containers/ItemDetailPage/PageHeader'
import Loading from 'global/Loading'
import { RenderModal, useModal } from 'global/Modal/Context'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { CompanySlugParam, Offer as OfferType } from 'utils/types'

import { offerTypes } from '..'
import EditOfferModal from '../EditOfferModal'
import ResourceView from './ResourceView'
import VendorResourceView from './VendorResourceView'

interface Params extends CompanySlugParam {
  id: string
}

const ResourceViewPage: React.FC = () => {
  const { showModal } = useModal()
  const { company_slug, id } = useParams<Params>()
  const [offer, setOffer] = useState<OfferType>()
  const [allTags, setAllTags] = useState<string[]>([])
  const [allCategories, setAllCategories] = useState<string[]>([])

  useQuery(['offers', company_slug, id], () => callApi(api.getOffer, company_slug, id), {
    onSuccess: ({ offer }) => {
      setOffer(offer)
    },
  })

  const { isLoading } = useQuery(
    ['offer_tags', company_slug],
    () => callApi(api.getOfferTags, company_slug),
    {
      onSuccess: ({ tags, categories }) => {
        setAllTags(tags)
        setAllCategories(categories)
      },
    },
  )

  const openEditOfferModal = (offer: OfferType) => {
    showModal(
      (resolve) => (
        <EditOfferModal
          teamSlug={company_slug}
          offer={offer}
          resolve={(offer) => {
            offer && setOffer(offer)
            resolve()
          }}
          allTags={allTags}
          allCategories={allCategories}
          setAllCategories={setAllCategories}
          setAllTags={setAllTags}
          isFetchingTags={isLoading}
        />
      ),
      'add-offer-modal',
    )
  }

  if (!offer) {
    return (
      <div className="flex items-center justify-center my-8">
        <Loading />
      </div>
    )
  }

  return (
    <PageWrapper title={offer.title}>
      <div>
        {offer.offer_type === 'vendors' ? (
          <VendorResourceView offer={offer} onEdit={openEditOfferModal} teamSlug={company_slug} />
        ) : (
          <ResourceView
            offer={offer}
            onEdit={openEditOfferModal}
            teamSlug={company_slug}
            allTags={allTags}
            noTitle
          />
        )}
      </div>
    </PageWrapper>
  )
}

export default ResourceViewPage
