import axios from 'axios'

import { FavoriteConnectionResponse } from 'utils/types'

type FavoriteTypes = 'Connection'

export default {
  favorite: (uuid: string, type: FavoriteTypes) => {
    return axios.post<FavoriteConnectionResponse>('/api/favorites', {
      type: type,
      id: uuid,
    })
  },
}
