import React from 'react'

import { useModal } from 'global/Modal'
import PageWrapper from 'global/PageWrapper'
import { useCurrentUser, useGroups } from 'store/hooks'

import GroupRow from './GroupRow'
import PinnedGroupsSection from './PinnedGroupsSection'

const Groups = () => {
  const { user } = useCurrentUser()
  const team = user.team
  const teamSlug = team?.slug
  const { groups } = useGroups(teamSlug)

  return (
    <PageWrapper title={'Groups'} className="h-screen overflow-y-scroll px-0">
      <PinnedGroupsSection teamSlug={teamSlug!} />
      <div className="mb-8">
        {groups?.map((group) => (
          <GroupRow key={group.uuid} group={group} teamSlug={teamSlug!} />
        ))}
      </div>
    </PageWrapper>
  )
}

export default Groups
