import React, { useEffect, useState } from 'react'

import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import useCrmStatus from 'components/CrmStatus'
import SalesEmptyState from 'components/EmptyStates/SalesEmptyState'
import { ReviewSalesListHandler } from 'containers/IntroRequestModal/components/Modals/Sales/ReviewSalesListHandler'
import ListIndexView from 'containers/ListIndexView'
import Other from 'containers/ListView/Other'
import PeopleSearch from 'containers/ListView/People'
import MembersList from 'containers/ListView/members'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import { useAccessControl } from 'global/AccessControl'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import PageWrapper from 'global/PageWrapper'
import { useTeam, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { TeamSettings } from 'utils/types'

import Companies from './Companies'
import ConnectionListViewWithBlocks from './ConnectionListViewWithBlocks'

const ListView = () => {
  const introId = useSearchParam<string>('intro')
  const { showModal } = useModal()
  const teamSlug = useTeamSlug()
  const [settings, setSettings] = useState<TeamSettings>()
  const { list_id } = useParams()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  useCrmStatus(teamSlug)

  const { team } = useTeam(teamSlug)

  const settingsQuery = useQuery([teamSlug, 'teamSettings'], () => callApi(api.getTeamSettings), {
    onSuccess: (data) => {
      setSettings({ ...data })
    },
    enabled: isAdminOrEmployee,
  })

  const companyListQuery = useQuery(
    ['getCompanyList', teamSlug, list_id],
    () => callApi(api.getCompanyList, teamSlug, list_id, false, false),
    {
      enabled: !['connections', 'social', 'team-connections'].includes(list_id),
    },
  )

  const companyList = companyListQuery.data?.company_list

  useEffect(() => {
    if (companyList) {
      if (companyList.list_category === 'sales' || companyList.list_category === 'fundraise') {
        showModal(
          () => <ReviewSalesListHandler introRequestUuid={introId} />,
          'review-sales-list-handler',
        )
      } else {
        if (introId) {
          callApi(api.getIntroRequestV2, introId)
            .then((data) => {
              showModal(
                (resolve) => (
                  <FacilitateIntroModal
                    requestable={data.requestable}
                    companyList={data.company_list}
                    resolve={resolve}
                    gatekeeperId={data.gatekeeper_id}
                    facilitator={data.facilitator}
                    introRequest={data}
                    includeInitialNote={false}
                  />
                ),
                'facilitate-intro-modal',
              )
              const currentUrl = window.location.href
              const baseUrl = currentUrl.split('?')[0]
              history.replaceState(null, '', baseUrl)
            })
            .catch((error) => {
              console.log('Error', error)
            })
        }
      }
    }
  }, [introId, showModal, companyList])

  if (!team || companyListQuery.isLoading) {
    return (
      <div className="mt-10">
        <Loading />
      </div>
    )
  }

  if (list_id == 'connections') {
    return <PeopleSearch companyList={companyList} />
  } else if (
    list_id == 'team-connections' ||
    (companyList?.list_type === 'people' && companyList?.default_type_identifier)
  ) {
    if (!companyList) {
      return (
        <div className="mt-10">
          <Loading />
        </div>
      )
    }

    return (
      <PageWrapper title="Connections">
        <ConnectionListViewWithBlocks
          companyList={companyList}
          team={team}
          list_id={list_id}
          ownerType="Team"
          ownerId={teamSlug}
        />
      </PageWrapper>
    )
  } else if (
    companyList?.portfolio ||
    (companyList?.list_category === 'talent' &&
      (companyList?.list_type === 'jobs' || companyList.list_type === 'people')) ||
    (companyList?.list_category === 'resources' &&
      (companyList?.list_type === 'people' || companyList?.list_type === 'discounts')) ||
    ((companyList?.list_category === 'sales' || companyList?.list_category == 'fundraise') &&
      (companyList?.list_type === 'people' || companyList?.list_type === 'companies'))
  ) {
    return <ListIndexView />
  }

  const sources = []

  if (settings?.team_connected_sfdc) {
    sources.push('salesforce_accounts')
    sources.push('salesforce_opps')
  }

  if (settings?.team_connected_hubspot) {
    sources.push('hubspot_deals')
    sources.push('hubspot_companies')
  }

  if (settings?.team_connected_company_list) {
    sources.push('company_list')
  }

  const loading = !settings && isAdminOrEmployee

  return (
    <PageWrapper title="Lists">
      {!loading && sources.length === 0 && !list_id && (
        <SalesEmptyState hasSyncedSales={false} reload={settingsQuery.refetch} />
      )}
      {!loading && (sources.length > 0 || list_id) && (
        <>
          {companyList?.list_type === 'mail' ? (
            <Other listUuid={companyList?.uuid} />
          ) : (
            <Companies listUuid={companyList?.uuid} />
          )}
        </>
      )}
    </PageWrapper>
  )
}

export default ListView
