import axios from 'axios'

import { TimeProp } from 'containers/Analytics'

export default {
  getAnalytics: (companySlug: string, time: TimeProp) => {
    return axios.get(`/api/analytics`, {
      params: {
        team_slug: companySlug,
        time_frame: time,
      },
    })
  },

  getLeaderboard: (companySlug: string, time: TimeProp) => {
    return axios.get(`/api/analytics`, {
      params: {
        team_slug: companySlug,
        time: time,
      },
    })
  },

  sendUserActivity: (params: {
    team_slug: string
    name: string
    description?: string
    advisor_uuid?: string
    target_team_slug?: string
    global_company_uuids?: string[]
  }) => {
    return axios.post(`/api/user_activities`, params)
  },

  sendTalentActivity: (params: any) => {
    return axios.post(`/api/talent_activities`, params)
  },
}
