import React, { useEffect, useState } from 'react'

import { match, useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { useAccessControl } from 'global/AccessControl'
import Banner from 'global/Banner'
import { CrumbProps } from 'global/Breadcrumb'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import Tabs, { Tab } from 'global/Tabs'
import Typography from 'global/Typography'
import { useCurrentUser, useCurrentUserSettings, useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import Drafts from './Drafts'
import Inbox from './Inbox'
import MessagesInbox from './MessagesInbox'
import Templates from './Templates'

const StyledBanner = styled(Banner)`
  background-color: ${({ theme }) => theme.layout.main_content_bg_color};
`

const MessagesPage: React.VFC = () => {
  const teamSlug = useTeamSlug()
  const match = useRouteMatch<{ tab: string }>(`/${teamSlug}/:tab`)
  const history = useHistory()
  const { team } = useTeam(teamSlug)
  const { user } = useCurrentUser()
  const { settings, updateSetting } = useCurrentUserSettings()

  const { canViewDraft, canUseTemplates } = useAccessControl(teamSlug)
  const [fullWidth, setFullWidth] = useState(false)
  const crumbs: CrumbProps[] = [{ title: 'Messages', url: `/${teamSlug}/messages` }]

  const tab = match?.params.tab || 'messages'

  useEffect(() => {
    setFullWidth(false)
  }, [tab])

  const tabs: Tab[] = [
    {
      id: 'messages',
      component: () => <MessagesInbox onSplitView={(val) => setFullWidth(val)} />,
      label: (
        <>
          {tab === 'messages' ? (
            <i className="fas fa-inbox mr-2"></i>
          ) : (
            <i className="far fa-inbox mr-2"></i>
          )}
          Inbox
        </>
      ),
      dataTestId: 'open-msgs-tab',
    },
  ]

  if (canViewDraft) {
    tabs.push({
      id: 'drafts',
      component: () => <Drafts />,
      label: (
        <>
          {tab === 'drafts' ? (
            <i className="fas fa-file mr-2"></i>
          ) : (
            <i className="far fa-file mr-2"></i>
          )}
          Drafts
        </>
      ),
      dataTestId: 'open-drafts-tab',
    })
  }

  if (canUseTemplates) {
    tabs.push({
      id: 'templates',
      component: () => <Templates />,
      label: (
        <>
          {tab === 'templates' ? (
            <i className="fas fa-magic mr-2"></i>
          ) : (
            <i className="far fa-magic mr-2"></i>
          )}
          Templates
        </>
      ),
      dataTestId: 'open-templates-tab',
    })
  }

  if (user?.features?.inbox) {
    tabs.push({
      id: 'inbox',
      component: () => <Inbox />,
      label: (
        <>
          {tab === 'inbox' ? (
            <i className="fas fa-magic mr-2"></i>
          ) : (
            <i className="far fa-magic mr-2"></i>
          )}
          Inbox - Gmail
        </>
      ),
      dataTestId: 'open-inbox-tab',
    })
  }

  if (tab === 'templates') {
    crumbs.push({ title: 'Templates', url: `/messages/templates` })
  } else if (tab === 'drafts') {
    crumbs.push({ title: 'Drafts', url: `/${teamSlug}/drafts` })
  } else if (tab == 'inbox') {
    crumbs.push({ title: 'Inbox', url: `/${teamSlug}/inbox` })
  }

  const activeIndex = tabs.findIndex((t) => t?.id === tab)

  const handleChangeTab = (index: number, tab?: string) => {
    history.push(`/${teamSlug}/${tab}`)
  }

  const showToastIfDeleted = (): void => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('deleted') === 'true') {
      cabalToast({ style: 'error', content: 'Message has been deleted' })
    }
  }
  showToastIfDeleted()

  if (!team) return <Loading className="mt-12" />

  return (
    <PageWrapper fullWidth={fullWidth} crumbs={crumbs} title="Messages">
      {/* Messages splash */}
      {/* <SplashScreen location="messages" teamSlug={teamSlug} /> */}
      <Tabs data={tabs} hideTabs onChange={handleChangeTab} index={activeIndex} sticky />
    </PageWrapper>
  )
}

export default MessagesPage
