import * as React from 'react'
import styled, { StyledComponentPropsWithRef } from 'styled-components'
import tw from 'twin.macro'
import Typography from 'global/Typography'
import Loading from './Loading'

interface ButtonProps {
  primary?: boolean
  working?: boolean
}

const ButtonWrap = styled.button<{
  disabled?: boolean
}>`
  ${tw`
    focus:outline-none
    focus:ring
    rounded-md
    inline-flex
    items-center
    font-semibold
    py-2
    px-3
    cursor-pointer
  `}
  ${({ disabled }) => (disabled ? tw`cursor-not-allowed` : null)}

  color: ${({ theme }) => theme.buttons.default.text_color};
  background-color: ${({ theme, disabled }) =>
    disabled ? `rgba(255, 255, 255, 0.2)` : theme.buttons.default.bg_color};

  &:hover {
    color: ${({ theme }) => theme.buttons.default.hover_text_color};
  }
`

const ComposerButtonsWrapper = styled(ButtonWrap)`
  ${tw`px-2 py-1`}
  background-color: ${({ theme }) => theme.buttons.tertiary.bg_color};
  box-shadow: none;
  color: ${({ theme }) => theme.colors.purple};
  font-size: 0.8125rem;
  font-weight: 600;
  border-radius: 0.25rem;

  &:hover {
    background-color: ${({ theme }) => theme.buttons.tertiary.bg_hover};
    color: ${({ theme }) => theme.colors.purple};
  }
`

function ComposerButtons(props: ButtonProps & StyledComponentPropsWithRef<'button'>) {
  const { children, ...otherProps } = props

  return (
    <ComposerButtonsWrapper primary={props.primary} {...otherProps}>
      {children}
    </ComposerButtonsWrapper>
  )
}

function Button(props: ButtonProps & StyledComponentPropsWithRef<'button'>) {
  const { children, ...otherProps } = props

  return (
    <ButtonWrap {...otherProps}>
      <Typography fontSize="16" color="secondary">
        {children}
      </Typography>
    </ButtonWrap>
  )
}

const PrimaryButtonWrap = styled(ButtonWrap).attrs((props) => ({
  disabled: props.disabled,
}))`
  ${tw`
    text-white
    hover:text-white
  `}
  color: ${({ theme }) => theme.buttons.primary.text_color};
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.gray : theme.buttons.primary.bg_color};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

function PrimaryButton({
  children,
  working = false,
  disabled,
  ...otherProps
}: ButtonProps & StyledComponentPropsWithRef<'button'>) {
  return (
    <PrimaryButtonWrap disabled={disabled || working} {...otherProps}>
      {!working && (
        <Typography fontWeight={500} fontSize="16">
          {children}
        </Typography>
      )}
      {working && (
        <Typography color="white" className="px-8 flex items-center" component="div">
          <Loading size={20} color="inherit" />
        </Typography>
      )}
    </PrimaryButtonWrap>
  )
}

const SecondaryButtonWrap = styled(ButtonWrap).attrs((props) => ({
  disabled: props.disabled,
}))`
  ${tw`
    text-white
    hover:text-white
  `}
  color: ${({ theme }) => theme.buttons.secondary.text_color};
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.buttons.secondary.disabled_bg_color : theme.buttons.secondary.bg_color};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 15px;
  padding: 12px 28px;

  &:hover {
    color: ${({ theme }) => theme.buttons.secondary.hover_text_color};
  }
`

function SecondaryButton(props: ButtonProps & StyledComponentPropsWithRef<'button'>) {
  const { children, ...otherProps } = props

  return (
    <SecondaryButtonWrap {...otherProps}>
      <Typography fontWeight={500} fontSize="16">
        {props.children}
      </Typography>
    </SecondaryButtonWrap>
  )
}

const DangerButton = (props: ButtonProps & StyledComponentPropsWithRef<'button'>) => {
  return (
    <button
      className={`
        ${props.className}
        focus:outline-none
        inline-flex
        items-center
        underline
        font-semibold
        text-red-600
        hover:text-red-700
      `}
      onClick={props.onClick}
    >
      <Typography fontSize="16" color="red">
        {props.children}
      </Typography>
    </button>
  )
}

const SuccessButton = (props: ButtonProps & StyledComponentPropsWithRef<'button'>) => {
  return (
    <button
      className={`
        ${props.className}
        shadow
        focus:outline-none
        rounded-lg
        inline-flex
        items-center
        bg-green-500
        font-semibold
        text-white
        hover:text-white
        py-2
        px-3
      `}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const LoginButton = (props: ButtonProps & StyledComponentPropsWithRef<'button'>) => {
  return (
    <button
      className={`
        ${props.className}
        button-background
        button
        cursor-pointer
        focus:outline-none
        focus:ring
        h-12
        px-6
        py-4
        rounded
        text-center
        w-65
      `}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const AddCompanyButton = styled.button`
  ${tw`flex flow-root cursor-pointer font-bold focus:outline-none focus:ring
    text-xs rounded-lg items-center`}
  background-color: #5C69D1;
  margin: 1.125rem auto 1.125rem auto;
  width: 9.875rem;
  height: 2rem;
  justify-content: space-evenly;
  letter-spacing: 2px;
  color: #fff;
  :hover {
    background-color: #7080ff;
  }
`

export default Button

export {
  PrimaryButton,
  SecondaryButton,
  SuccessButton,
  DangerButton,
  LoginButton,
  AddCompanyButton,
  ComposerButtons,
}
