import styled from 'styled-components'
import tw from 'twin.macro'

export const SendMessageWrap = styled.div`
  ${tw`rounded-lg p-6`}
  box-shadow: ${({ theme }) => theme.composer_shadow};
  background-color: ${({ theme }) => theme.colors.composer_bg};
`

export const GroupLabel = styled.span.attrs((props) => ({ className: 'py-xs px-sm' }))`
  font-size: 0.875rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.labels.green.bg_color};
  color: ${({ theme }) => theme.labels.green.text_color};
`

export const MessageListHeader = styled.div`
  ${tw`flex justify-between items-center px-3`}
  padding-top: 18px;
`
export const UnreadIndicator = styled.span`
  ${tw`rounded-full p-1 mr-2`}
  background-color: ${({ theme }) => theme.colors.yellow_bold};
  font-size: 0.5px;
`
export const Author = styled.div`
  ${tw`ml-4 flex items-center`}
  color: ${({ theme }) => theme.colors.message_sender};
`
