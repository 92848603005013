import React, { useCallback, useMemo } from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import { DatePickerModal, MultiSelect, Select, SelectOption, TextInput } from 'global/Input'
import Typography from 'global/Typography'

import { CompanyListFilter, CrmField } from 'utils/types'

const ColoredIcon = styled.i`
  color: ${(props) => props.color};
`

const FieldWrapper = styled.div`
  ${tw`flex flex-wrap gap-1`}
`

interface Props {
  fieldFilter: CompanyListFilter
  setFieldFilter: (filter: CompanyListFilter) => void
  removeFieldFilter: () => void
  sfdcFieldOptions: CrmField[]
  hubspotFieldOptions: CrmField[]
}

const FieldFilter: React.VFC<Props> = ({
  fieldFilter,
  sfdcFieldOptions,
  hubspotFieldOptions,
  setFieldFilter: _setFieldFilter,
  removeFieldFilter,
}) => {
  const advancedOperatorFields = useMemo(
    () => ['currency', 'int', 'double', 'number', 'date', 'datetime', 'percent'],
    [],
  )
  const filterableType = fieldFilter.filterable?.external_type

  const availableOperators = useMemo(() => {
    const availableOperators = advancedOperatorFields.includes(filterableType)
      ? [
          { label: 'is equal to', value: '=' },
          { label: 'is greater than', value: '>' },
          { label: 'is less than', value: '<' },
          { label: 'is greater than or equal to', value: '>=' },
          { label: 'is less than or equal to', value: '<=' },
          { label: 'is between', value: 'between' },
        ]
      : [{ label: 'is equal to', value: '=' }]

    if (['date', 'datetime'].includes(filterableType)) {
      return availableOperators
    } else {
      return availableOperators.concat([
        { label: 'is any of', value: 'in' },
        { label: 'is null', value: 'is null' },
        { label: 'is not null', value: 'is not null' },
      ])
    }
  }, [filterableType])

  const updatedOperator = useCallback(
    (field: CrmField | null, ff) => {
      if (!field) return undefined

      const filterableType = field.salesforce_type || field.hubspot_type
      if (advancedOperatorFields.includes(filterableType)) {
        return ff.operator
      } else {
        return '='
      }
    },
    [advancedOperatorFields],
  )

  const withoutFilterOptions = useMemo(
    () => !!fieldFilter?.filterable && !!!fieldFilter.filterable.options,
    [fieldFilter?.filterable],
  )

  const withFilterOptions = useMemo(
    () => !!fieldFilter?.filterable && !!fieldFilter.filterable.options,
    [fieldFilter?.filterable],
  )

  const setFieldFilter = (change: Partial<CompanyListFilter>) => {
    _setFieldFilter({
      ...fieldFilter,
      ...change,
    })
  }

  return (
    <div className="flex justify-between">
      <FieldWrapper>
        <Select
          value={fieldFilter.filterable}
          onChange={(field) => {
            setFieldFilter({
              filterable: field || undefined,
              operator: updatedOperator(field, fieldFilter),
            })
          }}
          placeholder="CRM Field"
          isClearable
          compactPlaceholder
          options={
            [...sfdcFieldOptions, ...hubspotFieldOptions]?.map<SelectOption<CrmField>>((field) => ({
              icon_class: field.icon_class,
              icon_color: field.icon_color,
              label: field.label,
              value: field,
            })) || []
          }
          formatOptionLabel={(option) => {
            return (
              <>
                <ColoredIcon
                  className={`${option.icon_class}`}
                  color={option.icon_color as string}
                />{' '}
                {`${option.label}`}
              </>
            )
          }}
        />
        {fieldFilter.filterable && (
          <Select
            value={fieldFilter.operator}
            onChange={(v) => {
              setFieldFilter({
                operator: v || undefined,
              })
            }}
            placeholder="is equal to"
            isClearable
            compactPlaceholder
            options={availableOperators}
          />
        )}
        {withFilterOptions && !['is null', 'is not null'].includes(fieldFilter.operator) && (
          <>
            {fieldFilter.operator === 'in' && (
              <MultiSelect
                value={fieldFilter.filter_multi_value}
                onChange={(v) => {
                  setFieldFilter({
                    filter_multi_value: v,
                  })
                }}
                placeholder="Field Values"
                isClearable
                compactPlaceholder
                options={fieldFilter.filterable.options || []}
              />
            )}
            {!(fieldFilter.operator === 'in') && (
              <Select
                value={fieldFilter.filter_value}
                onChange={(v) => {
                  setFieldFilter({
                    filter_value: v || undefined,
                  })
                }}
                placeholder="Field Value"
                isClearable
                compactPlaceholder
                options={fieldFilter.filterable.options || []}
              />
            )}
          </>
        )}

        {withoutFilterOptions && (
          <>
            {fieldFilter.operator === 'between' && (
              <>
                {['date', 'datetime'].includes(fieldFilter.filterable?.external_type) && (
                  <div className="flex flex-col items-center">
                    <DatePickerModal
                      className=""
                      placeholder="Start Date"
                      date={fieldFilter.filter_value}
                      onDateChange={(date) => {
                        setFieldFilter({
                          filter_value: date,
                        })
                      }}
                    />
                    <Typography className="my-0.5" fontSize={'12'} fontWeight={400}>
                      - AND -
                    </Typography>
                    <DatePickerModal
                      className=""
                      placeholder="End Date"
                      date={fieldFilter.filter_value_b}
                      onDateChange={(date) => {
                        setFieldFilter({
                          filter_value_b: date,
                        })
                      }}
                    />
                  </div>
                )}
                {!['date', 'datetime'].includes(fieldFilter.filterable?.external_type) && (
                  <div className="flex flex-col items-center">
                    <TextInput
                      placeholder="Field Value"
                      value={fieldFilter.filter_value}
                      type={
                        ['currency', 'int', 'double', 'number'].includes(
                          fieldFilter.filterable.external_type,
                        )
                          ? 'number'
                          : 'text'
                      }
                      className="min-w-[250px]"
                      component="input"
                      onChangeCapture={(e) => {
                        setFieldFilter({
                          filter_value: e.currentTarget.value,
                        })
                      }}
                    />
                    <Typography className="my-0.5" fontSize={'12'} fontWeight={400}>
                      - AND -
                    </Typography>
                    <TextInput
                      placeholder="Field Value"
                      value={fieldFilter.filter_value_b}
                      type={
                        ['currency', 'int', 'double', 'number'].includes(
                          fieldFilter.filterable.external_type,
                        )
                          ? 'number'
                          : 'text'
                      }
                      className="min-w-[250px]"
                      component="input"
                      onChangeCapture={(e) => {
                        setFieldFilter({
                          filter_value_b: e.currentTarget.value,
                        })
                      }}
                    />
                  </div>
                )}
              </>
            )}

            {!(fieldFilter.operator === 'between') && (
              <>
                {['date', 'datetime'].includes(fieldFilter.filterable?.external_type) && (
                  <DatePickerModal
                    placeholder="Date"
                    date={fieldFilter.filter_value}
                    onDateChange={(date) => {
                      setFieldFilter({
                        filter_value: date,
                      })
                    }}
                  />
                )}
                {!['date', 'datetime'].includes(fieldFilter.filterable?.external_type) && (
                  <>
                    {fieldFilter.operator === 'in' && (
                      <MultiSelect
                        value={fieldFilter.filter_multi_value}
                        onChange={(v) => {
                          setFieldFilter({ filter_multi_value: v })
                        }}
                        placeholder="Field Values"
                        isClearable
                        compactPlaceholder
                        options={[]}
                        creatable
                      />
                    )}
                    {!(fieldFilter.operator === 'in') && (
                      <TextInput
                        placeholder="Field Value"
                        value={fieldFilter.filter_value}
                        type={
                          ['currency', 'int', 'double', 'number'].includes(
                            fieldFilter.filterable.external_type,
                          )
                            ? 'number'
                            : 'text'
                        }
                        className="min-w-[250px]"
                        component="input"
                        onChangeCapture={(e) => {
                          setFieldFilter({
                            filter_value: e.currentTarget.value,
                          })
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </FieldWrapper>

      <Typography
        className="flex items-center flex-shrink-0 cursor-pointer"
        color={'fog'}
        onClick={() => removeFieldFilter()}
        component="p"
      >
        <i className="fas fa-trash" />
      </Typography>
    </div>
  )
}

export default FieldFilter
