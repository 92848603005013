import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { cabalToast } from 'ui-components/Toast'
import { useSetState } from 'react-use'

import CabalButton from 'global/CabalButton'
import { Select, TextInput, Label, CheckBox } from 'global/Input'
import Modal from 'global/Modal'
import api, { callApi } from 'utils/api'
import { AgreementFieldType, CreateAgreementTemplateField } from 'utils/types'
import { AxiosError } from 'axios'
import { HttpStatusCode } from 'utils/HttpStatusCode'
import { defaultStandardFields, DroppableField } from 'utils/constants/agreement'
import Typography from 'global/Typography'

interface Props {
  resolve: () => void
  fetchFields: () => Promise<unknown>
  companySlug: string
}

const CustomAgreementFieldModal: React.VFC<Props> = ({ resolve, fetchFields, companySlug }) => {
  const [field, setField] = useSetState<CreateAgreementTemplateField>({
    data_type: 'string',
    for: 'sender',
    name: '',
    overwrite_default_value: true,
    default_value: 'null',
  })
  const [working, setWorking] = useState(false)

  const { mutate: createField } = useMutation(
    () =>
      callApi(api.createCustomAgreementTemplateFields, companySlug, {
        ...field,
        default_value: field.default_value === 'null' ? null : field.default_value,
      }),
    {
      onMutate: () => {
        setWorking(true)
      },
      onSuccess: () => {
        fetchFields().finally(() => {
          setWorking(false)
          resolve()
          cabalToast({ style: 'success', content: 'Successfully added the field!' })
        })
      },
      onError: (e: AxiosError) => {
        if (e.response?.status === HttpStatusCode.NotAcceptable) {
          cabalToast({ style: 'error', content: 'A field with similar name exists already' })
        }
        setWorking(false)
      },
    },
  )

  const defaultValueOptions = [
    { label: '-- None --', value: 'null' },
    ...defaultStandardFields
      .reduce<DroppableField[]>((prev, curr) => [...prev, ...curr.fields], [])
      .map((f) => ({
        label: f.name,
        value: f.default_value!,
      })),
  ]
  const canSubmit = !!field.name && !!field.for && !!field.data_type

  return (
    <Modal
      show
      onHide={resolve}
      header="Add Custom Field"
      rightActions={
        <CabalButton onClick={() => createField()} working={working} disabled={!canSubmit}>
          Save
        </CabalButton>
      }
    >
      <TextInput
        placeholder="Field Name"
        value={field.name}
        labeled
        onChange={(e) => setField({ name: e.currentTarget.value })}
        className="mb-3"
      />
      <Label label="To be filled by">
        <Select<'sender' | 'advisor'>
          options={[
            {
              label: 'Sender',
              value: 'sender',
            },
            {
              label: 'Recipient',
              value: 'advisor',
            },
          ]}
          value={field.for}
          portal
          onChange={(v) => v && setField({ for: v })}
        />
      </Label>
      <Label className="mt-3" label={'Type'}>
        <Select<AgreementFieldType>
          options={[
            {
              label: 'Multi-line Text',
              value: 'text',
            },
            {
              label: 'Single-line Text',
              value: 'string',
            },
            {
              label: 'Number',
              value: 'number',
            },
            {
              label: 'Amount ($)',
              value: 'amount',
            },
            {
              label: 'Date',
              value: 'date',
            },
            {
              label: 'Checkbox',
              value: 'checkbox',
            },
          ]}
          value={field.data_type}
          portal
          onChange={(v) => v && setField({ data_type: v })}
        />
      </Label>
      <Label className="mt-3" label={'Default Value'}>
        <Select<string | null>
          options={defaultValueOptions}
          value={field.default_value}
          onChange={(v) => setField({ default_value: v })}
        />
      </Label>
      {field.default_value !== 'null' && (
        <Label className="mt-3">
          <CheckBox
            checked={field.overwrite_default_value}
            onChange={(e) => setField({ overwrite_default_value: e.currentTarget.checked })}
            label="Overwrite saved data"
          />
        </Label>
      )}
    </Modal>
  )
}

export default CustomAgreementFieldModal
