import React from 'react'

import { useQuery } from 'react-query'

import ActivityRow from 'components/Activity/ActivityRow'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'

const ActivityWidget = ({ advisorUuid }: { advisorUuid: string }) => {
  // const teamSlug = useTeamSlug()
  // const [showAllEntries, setShowAllEntries] = React.useState(false)

  // const memberActivitiesQuery = useQuery([teamSlug, 'getMemberActivity'], () =>
  //   callApi(api.getMemberActivity, teamSlug, 'candidate_activities'),
  // )

  // const activities = memberActivitiesQuery.data?.activity ?? []
  // const visibleActivities = showAllEntries ? activities : activities.slice(0, 6)

  return (
    <Widget title="Activity">
      <div className="-mx-2">
        {/* {visibleActivities.map((activity) => (
          <ActivityRow key={activity.uuid} activity={activity} splitView={true} />
        ))} */}

        {/* {activities.length === 0 && ( */}
        <Typography fontSize="12" color="fog_rain" className="ml-2">
          No activity available yet
        </Typography>
        {/* )} */}

        {/* {!showAllEntries && (activities.length ?? 0) > 6 && (
          <div className="flex justify-center pt-1">
            <CabalButton variant="link" onClick={() => setShowAllEntries(true)}>
              See all ({(activities ? activities : []).length})
            </CabalButton>
          </div>
        )} */}
      </div>
    </Widget>
  )
}

export default ActivityWidget
