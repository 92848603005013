import React from 'react'

import CabalButton from 'global/CabalButton'
import { Select, TextArea, TextInput } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api from 'utils/api'
import { US_STATE_NAMES } from 'utils/constants/ui'
import { Team } from 'utils/types'

import { TeamSettingProps } from '.'

const Legal: React.VFC<TeamSettingProps> = ({ team, reloadTeam }) => {
  const [working, setWorking] = React.useState(false)
  const [updatedTeam, setUpdatedTeam] = React.useState<Team>()

  React.useEffect(() => {
    if (team) setUpdatedTeam(team)
  }, [team])

  const handleSubmit = () => {
    if (!updatedTeam) return
    setWorking(true)

    api
      .updateTeam(updatedTeam!.slug, {
        team: {
          legal_name: updatedTeam?.legal_name,
          state_of_incorporation: updatedTeam?.state_of_incorporation,
          address: updatedTeam?.address,
        },
      })
      .then(async () => {
        await reloadTeam()
        cabalToast({ style: 'success', content: 'Successfully updated Company' })
      })
      .finally(() => setWorking(false))
  }

  if (!updatedTeam) return <></>

  return (
    <>
      <Widget title="Legal">
        <div className="flex flex-col gap-4 pt-2">
          <ModalInputWrapper>
            <ModalInputLabel>Legal Name</ModalInputLabel>
            <TextInput
              value={updatedTeam.legal_name}
              placeholder="Legal Name"
              color="admin_label"
              className="w-full"
              data-testid="legal-name"
              onChange={(e) => setUpdatedTeam({ ...updatedTeam, legal_name: e.target.value })}
            />
          </ModalInputWrapper>
          <ModalInputWrapper>
            <ModalInputLabel>State of Incorporation</ModalInputLabel>
            <Select
              value={updatedTeam.state_of_incorporation}
              placeholder={`State of Incorporation`}
              className="w-full"
              onChange={(v) => v && setUpdatedTeam({ ...updatedTeam, state_of_incorporation: v })}
              options={US_STATE_NAMES.map((s) => ({
                value: s,
              }))}
            />
          </ModalInputWrapper>
          <ModalInputWrapper>
            <ModalInputLabel>Address</ModalInputLabel>
            <TextArea
              value={updatedTeam.address}
              placeholder="Address"
              rows={4}
              className="w-full"
              onChange={(e) => setUpdatedTeam({ ...updatedTeam, address: e.target.value })}
            />
          </ModalInputWrapper>
        </div>
        <div className="flex justify-end mt-6">
          <CabalButton
            variant="primary"
            working={working}
            onClick={handleSubmit}
            data-testid="save-legal"
          >
            Save
          </CabalButton>
        </div>
      </Widget>
    </>
  )
}

export default Legal
