import React from 'react'

import Carousel from 'react-multi-carousel'
import { useAudio } from 'react-use'

import Alexis from '../../../images/alexisgay.png'
import CorpBro from '../../../images/corpbro.png'
import DavidAlexis from '../../../images/new_david-alexis.png'
import Wild1 from '../../../images/wild1.png'
import { ButtonGroup, responsive } from './ResourcesCarousel'

const ContentCard = ({
  img,
  title,
  cta,
  redirect,
}: {
  img: string
  title: string
  cta?: string
  redirect: string
}) => {
  return (
    <a
      href={redirect}
      target="_blank"
      rel="noreferrer noopener"
      className="carousel-button-group flex flex-col mr-4 rounded-lg"
    >
      <div className="flex flex-shrink-0 w-full overflow-hidden">
        <img className="rounded-t-lg" src={img} height={205} />{' '}
      </div>

      <div className="p-6 flex flex-col gap-8 bg-slate-800 rounded-b-lg">
        <p className="text-white text-2xl font-semibold">{title}</p>
        <a
          href={redirect}
          target="_blank"
          rel="noreferrer noopener"
          className="text-[#5c69d1] hover:text-[#8A97FF] tansition-all duration-300 ease-in-out"
        >
          {cta ? cta : 'Read more'}
        </a>
      </div>
    </a>
  )
}

const AudioItem = ({
  imgUrl,
  audioUrl,
  title,
}: {
  imgUrl: string
  audioUrl: string
  title: string
}) => {
  const [audio, state, controls] = useAudio({
    src: audioUrl,
  })
  return (
    <div
      className="carousel-button-group flex flex-col mr-4 cursor-pointer"
      onClick={state.playing ? () => controls.pause() : () => controls.play()}
    >
      {audio}
      <div className="flex flex-shrink-0 w-full overflow-hidden">
        <img className="rounded-t-lg" src={imgUrl} />{' '}
      </div>

      <div className="p-6 flex flex-col gap-8 bg-slate-800 rounded-b-lg">
        <p className="text-white text-2xl font-semibold">{title}</p>
        <div className="text-[#5c69d1] hover:text-[#8A97FF]">
          {state.playing ? (
            <div onClick={() => controls.pause()}>
              <i className="far fa-pause mr-2.5"></i>
              Pause
            </div>
          ) : (
            <div onClick={() => controls.play()}>
              <i className="far fa-play mr-2.5"></i>
              Play
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const ContentCarousel = () => {
  return (
    <div className="pb-40 relative">
      <Carousel
        responsive={responsive}
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
        partialVisible
      >
        <ContentCard
          img={CorpBro}
          title={'Corp Bro explains investor referrals'}
          cta={'Watch on LinkedIn'}
          redirect={
            'https://www.linkedin.com/posts/corporatebro_moredeals-investors-cabalpartner-activity-7165735087622217728-ljV5?utm_source=share&utm_medium=member_desktop'
          }
        />
        <ContentCard
          img={Alexis}
          title={'Alexis Gay explains VC ValueAdd™'}
          cta={'Watch on X'}
          redirect={'https://x.com/yayalexisgay/status/1759963992041140712?s=20'}
        />
        <ContentCard
          img={Wild1}
          title={'PLG123 explains Cabal in 1 min'}
          cta={'Watch on YouTube'}
          redirect={'https://www.youtube.com/watch?v=jBWLvwO-rXU'}
        />
        <AudioItem
          imgUrl={DavidAlexis}
          title={'Why Craft & Seven Seven Six backed Cabal'}
          audioUrl={'https://getcabal.s3.amazonaws.com/audio/david_alexis.mp3'}
        />
      </Carousel>
    </div>
  )
}

export default ContentCarousel
