import React, { useState } from 'react'

import { useMutation } from 'react-query'
import { useSetState } from 'react-use'
import validator from 'validator'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { TextArea, TextInput } from 'global/Input'
import Modal, { ModalComponentProps } from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { GlobalPersonBlueprint } from 'utils/types'

import GlobalPersonLink from './GlobalPersonLink'

interface Props extends ModalComponentProps {
  teamSlug: string
  person: GlobalPersonBlueprint
  onCreate?: () => void
}

const CreateConnectionIntroRequestModal: React.FC<Props> = ({
  teamSlug,
  person,
  resolve,
  onCreate,
}) => {
  const { user, reloadUser } = useCurrentUser()
  const { team } = useTeam(teamSlug)
  const { linkedin_url } = user
  const [params, setParams] = useSetState({
    linkedin_url: linkedin_url || undefined,
    note: '',
  })

  const { mutate: createConnectionRequest, isLoading: isCreatingConnectionRequest } = useMutation(
    () =>
      callApi(api.createConnectionIntroRequest, {
        team_slug: teamSlug,
        requestable_id: person.uuid,
        requestable_type: 'GlobalPerson',
        linkedin_url: params.linkedin_url,
        note: params.note,
      }),
    {
      onSuccess: () => {
        cabalToast({ content: `Intro request sent to ${team?.name}.`, style: 'success' })
        resolve()
        reloadUser()
        onCreate?.()
      },
    },
  )

  const canSend = params.linkedin_url && validator.isURL(params.linkedin_url) && params.note

  return (
    <Modal
      show
      onHide={resolve}
      header={`Request intro to ${person.name}`}
      rightActions={
        <CabalButton
          working={isCreatingConnectionRequest}
          onClick={() => createConnectionRequest()}
          disabled={!canSend}
        >
          Request Intro
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Request Details</ModalInputLabel>
          <div className="flex items-center justify-center rounded-lg border border-light dark:border-dark p-3">
            <div className="flex gap-3 items-center text-right">
              <Typography fontSize="14" className="flex-1">
                {user.name}
              </Typography>
              <Avatar name={user.name} src={user.avatar_url} size="40px" />
            </div>
            <div className="px-8">
              <Typography fontSize="14" color="fog_rain">
                <i className="far fa-exchange" />
              </Typography>
            </div>
            <div className="flex gap-3 items-center text-left">
              <Avatar
                name={person.name}
                src={person.image_url}
                className="flex-initial"
                size="40px"
              />
              <Typography fontSize="14" className="flex-1">
                <GlobalPersonLink person={person} canAppendHeadline={false} />
              </Typography>
            </div>
          </div>
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Facilitated by</ModalInputLabel>
          <div className="flex gap-2 items-center">
            <Avatar name={team!.name} src={team?.logo} size="25px" />
            <Typography fontSize="14">{team?.name}</Typography>
          </div>
        </ModalInputWrapper>
        {!linkedin_url && (
          <ModalInputWrapper>
            <ModalInputLabel>Your LinkedIn URL</ModalInputLabel>
            <TextInput
              value={params.linkedin_url}
              onChange={(e) => setParams({ linkedin_url: e.currentTarget.value })}
              valueType="url"
            />
          </ModalInputWrapper>
        )}
        <ModalInputWrapper>
          <ModalInputLabel>Add a note</ModalInputLabel>
          <TextArea
            placeholder="Please add a note to request an intro"
            value={params.note}
            onChange={(e) => setParams({ note: e.currentTarget.value })}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default CreateConnectionIntroRequestModal
