import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '..'

export interface AppState {
  [key: string]: any
}

const initialState: AppState = {}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppStateValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      state[action.payload.key] = action.payload.value
    },
    appendAppStateValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      state[action.payload.key] = [
        ...(state[action.payload.key] || []),
        ...action.payload.value
      ]
    }
  },
})

export const { setAppStateValue, appendAppStateValue } = appSlice.actions

const selectAppState = (state: RootState) => state.app

export const selectAppStateValue = (key: string) =>
  createSelector(selectAppState, (state) => state[key])

export const selectAppStateValues = (keys: string[]) =>
  createSelector(selectAppState, (state) => Object.fromEntries(keys.map((k) => [k, state[k]])))

export default appSlice.reducer
