import { useCallback } from 'react'

const useScrollToElement = (elementId: string) => {
  return useCallback(() => {
    const element = document.getElementById(elementId)
    const overflowWrapper = document.getElementById('main-overflow-wrapper')

    if (element && overflowWrapper) {
      const scrollPosition = element.getBoundingClientRect().bottom - window.innerHeight / 2

      overflowWrapper.scrollTo({
        top: overflowWrapper.scrollTop + scrollPosition,
        behavior: 'smooth',
      })
    }
  }, [elementId])
}

const noFilters = (filters: any) => {
  return Object.entries(filters).every(
    ([key, value]) =>
      key === 'member_view' ||
      value === undefined ||
      (Array.isArray(value) && value.length === 0) ||
      value === false ||
      value === '',
  )
}

export { useScrollToElement, noFilters }
