import React from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import memoizeOne from 'memoize-one'

import { FiltersValueType, renderFilters } from 'components/Filters'
import { getFormattedStage } from 'containers/CompanyPage/SharedListsSection/PortfolioDataTags'
import { usePortfolioListContext } from 'containers/ListIndexView/PortfolioList'
import {
  IPortfolioCompany,
  PortfolioFilterTypes,
} from 'containers/ListIndexView/PortfolioList/types'
import { COMPANY_SIZE_OPTIONS } from 'containers/ListIndexView/constants'
import { useAccessControl } from 'global/AccessControl'
import { CheckBox } from 'global/Input'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import Switch from 'ui-components/Switch'

import { GetInvestorTagsResponse } from 'utils/types/investor'

interface Props {
  disabled?: boolean
  filters: any
  setFilters: (filters: PortfolioFilterTypes) => void
  ownedCompanyTags?: GetInvestorTagsResponse
  setSelectedCompanies: (selectedCompanies: IPortfolioCompany[]) => void
  selectedCompanies: IPortfolioCompany[]
}

const upcastFilters = memoizeOne((filters: PortfolioFilterTypes) => {
  const result: FiltersValueType = {}

  const search = filters.search
  if (!!search?.length) {
    result.search = search
  }

  const portfolioStages = filters.portfolio_stages
  if (!!portfolioStages?.length) {
    result.portfolio_stages = portfolioStages
  }

  const sizes = filters.size
  if (!!sizes?.length) {
    result.size = sizes
  }

  const tags = filters.tags
  if (!!tags?.length) {
    result.tags = tags
  }

  const sort = filters.sort
  if (!!sort) {
    result.sort = sort
  }

  return result
}, isEqual)

const downcastFilters = memoizeOne((filters: FiltersValueType) => {
  const result: PortfolioFilterTypes = {}

  const search = filters['search'] as string[] | null
  if (!!search?.length) {
    result.search = search
  }

  const sizes = filters['size'] as string[] | null
  if (!!sizes?.length) {
    result.size = sizes
  }

  const tags = filters['tags'] as string[] | null
  if (!!tags?.length) {
    result.tags = tags
  }

  const portfolioStages = filters['portfolio_stages'] as string[] | null
  if (!!portfolioStages?.length) {
    result.portfolio_stages = portfolioStages
  }

  const sort = filters['sort'] as string | null
  if (!!sort) {
    result.sort = sort
  }

  return result
}, isEqual)

const PortfolioListFilters: React.FC<Props> = ({
  disabled,
  filters,
  setFilters,
  ownedCompanyTags,
  setSelectedCompanies,
  selectedCompanies,
}) => {
  const teamSlug = useTeamSlug()
  const { portfolioCompaniesList, companyListData, view, setView } = usePortfolioListContext()

  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const tagOptions = ownedCompanyTags?.tags || []
  const stageOptions = ownedCompanyTags?.stages || []

  const adminView = !!isAdminOrEmployee && !!companyListData.owned
  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: [
      {
        key: 'search',
        type: 'search',
        icon: 'far fa-search',
        labelStr: 'Search',
        label: 'Search',
        skipLabelInAppliedPill: true,
        noPopover: true,
      },
      {
        key: 'portfolio_stages',
        type: 'dropdown[]',
        label: 'Stage',
        options: stageOptions.map((s: string) => ({ label: getFormattedStage(s), value: s })),
      },
      {
        key: 'size',
        type: 'dropdown[]',
        label: 'Size',
        options: COMPANY_SIZE_OPTIONS,
      },
      {
        key: 'tags',
        type: 'dropdown[]',
        label: 'Tags',
        options: tagOptions.map((s: string) => ({ label: s, value: s })),
      },
      {
        key: 'sort',
        type: 'dropdown',
        label: (
          <Typography>
            <i className="far fa-sort fa-sm mr-1" />
            Sort
          </Typography>
        ),
        labelStr: 'Sort',
        options: [
          { label: 'Name (A-Z)', value: 'name_lowercase-asc' },
          { label: 'Name (Z-A)', value: 'name_lowercase-desc' },

          { label: 'Stage (Low-High)', value: 'stages-asc' },
          { label: 'Stage (High-Low)', value: 'stages-desc' },

          // { label: 'Size', value: 'size' },
          // { label: 'Tags', value: 'tags' },
          { label: 'Date Added (Newest)', value: 'investor_company_created_at-desc' },
          { label: 'Date Added (Oldest)', value: 'investor_company_created_at-asc' },
        ],
      },
    ].filter(Boolean),
    value: upcastFilters(filters),
    onChange: (f) => setFilters(downcastFilters(f)),
  })

  const clearFilters = () => {
    setFilters({})
  }

  return (
    <div
      ref={parent}
      className={cx('flex-col w-full my-3', {
        'pointer-events-none opacity-50': disabled,
        'px-2.5': adminView,
      })}
    >
      <div className="flex flex-col sm:flex-row justify-between items-start">
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-wrap gap-2.5">
            {adminView && (
              <CheckBox
                checked={selectedCompanies.length === portfolioCompaniesList.length}
                onChange={(e) => {
                  setSelectedCompanies(e.target.checked ? portfolioCompaniesList : [])
                  if (e.target.checked && view === 'grid') {
                    setView('list')
                  }
                }}
              />
            )}
            {renderedFilters}
          </div>
          {adminView && (
            <div className="flex items-center gap-2">
              <Typography fontSize="12" color="fog">
                Member View
              </Typography>
              <Switch
                checked={filters.member_view}
                onCheckedChange={(e) => setFilters({ ...filters, member_view: e })}
              />
            </div>
          )}
        </div>
      </div>
      {appliedFilters.length > 0 && (
        <div className="flex justify-between items-center">
          <div className="flex gap-1 flex-wrap mt-2">{appliedFilters}</div>

          <Typography
            fontSize="12"
            color="fog"
            className="cursor-pointer"
            onClick={() => clearFilters()}
          >
            <i className="far fa-filter-slash mr-1"></i>
            Clear all
          </Typography>
        </div>
      )}
    </div>
  )
}

export default PortfolioListFilters
