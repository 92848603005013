import React, { useEffect, useState } from 'react'

import { useMutation } from 'react-query'
import styled from 'styled-components'

import CabalButton from 'global/CabalButton'
import { CheckBox, MultiSelect, TextArea } from 'global/Input'
import { ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'
import TokenInput from 'ui-components/TokenInput'

import api, { callApi } from 'utils/api'
import copyToClipboard from 'utils/copyToClipboard'

export const SelectWrapper = styled.div`
  .select__value-container {
    justify-content: flex-start !important;
  }
`

interface Props {
  isTalentList?: boolean
  listUuid: string
  teamSlug: string
  onClose: () => void
}

const SendInvite: React.FC<Props> = ({ isTalentList, listUuid, teamSlug, onClose }) => {
  const { user } = useCurrentUser()
  const isAllCandidates = window.location.pathname.split('/').pop() === 'all-candidates'
  const { team } = useTeam(teamSlug)
  const [params, setParams] = useState({
    listUuid: listUuid,
    allCandidatesListUuids: [] as string[],
    ownerUuid: user.uuid,
    emails: [] as string[],
    steps: {
      welcome: true,
      connections: !!!isTalentList,
      personal_info: true,
      talent_profile: !!isTalentList,
    },
    note: '',
  })
  const [companyLists, setCompanyLists] = useState<any>(null)
  const [allCandidatesUuid, setAllCandidatesUuid] = useState<any>('')

  useEffect(() => {
    callApi(api.getTalentLists, teamSlug).then((results) => {
      const lists = results?.company_lists ? results?.company_lists : []
      const listsWithoutAllCandidates = lists.filter((list) => !list.is_all_candidates)
      let defaultListUuid
      if (isAllCandidates) {
        if (listsWithoutAllCandidates.length > 0) {
          if (team?.default_talent_list_id) {
            defaultListUuid = team?.default_talent_list_id
          } else {
            defaultListUuid =
              listsWithoutAllCandidates.find((list) => list.uuid === listUuid)?.uuid ||
              lists[0].uuid
          }
        }
        setAllCandidatesUuid(lists.find((list) => list.is_all_candidates)?.uuid)
        setCompanyLists(listsWithoutAllCandidates)
        setParams({ ...params, allCandidatesListUuids: [] })
      }
    })
  }, [])

  const sendInviteMutation = useMutation(
    () => {
      const { listUuid, allCandidatesListUuids, ownerUuid, emails, steps, note } = params
      const uniqueAllCandidatesListUuids = Array.from(
        new Set([...allCandidatesListUuids, allCandidatesUuid].filter(Boolean)),
      )
      return callApi(
        api.sendCandidatesInvite,
        listUuid,
        uniqueAllCandidatesListUuids,
        ownerUuid,
        emails,
        steps,
        note,
      )
    },
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Invite sent successfully' })
        onClose()
      },
      onError: (error) => {
        cabalToast({
          style: 'error',
          content: `Failed to send invite: ${error.response.data.errors.message.replace(
            'Validation failed: ',
            '',
          )}`,
        })
      },
    },
  )

  const copyInviteLink = useMutation(
    () => {
      const { listUuid, allCandidatesListUuids, ownerUuid, emails, steps, note } = params

      const uniqueAllCandidatesListUuids = Array.from(
        new Set([...allCandidatesListUuids, allCandidatesUuid].filter(Boolean)),
      )
      return callApi(
        api.copyCandidatesInviteLink,
        listUuid,
        uniqueAllCandidatesListUuids,
        ownerUuid,
        emails,
        steps,
        note,
      )
    },
    {
      onSuccess: ({ link }) => {
        copyToClipboard(link)
        cabalToast({ style: 'success', content: 'Invite link copied successfully' })
      },
    },
  )

  return (
    <div className="">
      <TokenInput
        placeholder={`Enter emails`}
        includeInputValueInValues
        value={params.emails}
        onChange={(c) => setParams({ ...params, emails: c })}
        flexWrap="wrap"
      />

      <Typography className="my-3 mt-5" fontSize="14" component="p" color="fog">
        Invitation Options
      </Typography>
      <div className="flex flex-col gap-4">
        <CheckBox
          checked={params.steps.talent_profile}
          onChange={(e) =>
            setParams({
              ...params,
              steps: { ...params.steps, talent_profile: e.currentTarget.checked },
            })
          }
          label={
            <>
              <Typography lineHeight={1.125} component="div">
                Request professional profile (e.g., work history, preferences)
              </Typography>
            </>
          }
        />
        {/* <CheckBox
          checked={params.steps.connections}
          onChange={(e) =>
            setParams({
              ...params,
              steps: { ...params.steps, connections: e.currentTarget.checked },
            })
          }
          label={
            <>
              <Typography lineHeight={1.125} component="div">
                Request network
              </Typography>
            </>
          }
        /> */}

        <TextArea
          placeholder="Add invitation message"
          value={params.note}
          onChange={(e) => setParams({ ...params, note: e.currentTarget.value })}
        />
        {isAllCandidates && (
          <>
            <Typography fontSize="14" component="p" color="fog">
              Talent List
            </Typography>
            <ModalInputWrapper
              className="-mt-2"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <SelectWrapper>
                <MultiSelect<string>
                  placeholder="Select a Talent List"
                  value={params.allCandidatesListUuids}
                  onChange={(listUuids) =>
                    setParams({ ...params, allCandidatesListUuids: listUuids })
                  }
                  options={
                    companyLists
                      ? companyLists.map((list) => ({ value: list.uuid, label: list.name }))
                      : []
                  }
                />
              </SelectWrapper>
            </ModalInputWrapper>
          </>
        )}
      </div>
      <div className="flex justify-between w-full mt-6">
        <CabalButton
          onClick={() => copyInviteLink.mutate()}
          padding="0px"
          variant="link"
          leftIcon={<i className="far fa-link" />}
        >
          Copy invite link
        </CabalButton>
        <CabalButton
          onClick={() => sendInviteMutation.mutate()}
          disabled={params.emails.length === 0 || sendInviteMutation.isLoading}
        >
          {sendInviteMutation.isLoading ? 'Sending...' : 'Send invite'}
        </CabalButton>
      </div>
    </div>
  )
}

export default SendInvite
