import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import api from 'utils/api'
import { CurrentUserProfile, CurrentUserSettings } from 'utils/types'

import { RootState } from '..'

export interface CurrentUserState {
  user: Partial<CurrentUserProfile>
  isLoggedIn: boolean
  isUserLoaded: boolean
  settings: Partial<CurrentUserSettings>
  areSettingsLoaded: boolean
  gmailAuthorizedEmails?: string[]
  loading: {
    settings?: boolean
    user?: boolean
  }
}

const initialState: CurrentUserState = {
  user: {},
  settings: {},
  isLoggedIn: false,
  isUserLoaded: false,
  areSettingsLoaded: false,
  loading: {
    settings: true,
    user: true,
  },
}

export const loadCurrentUser = createAsyncThunk('users/fetchCurrentUser', async (_, thunk) => {
  thunk.dispatch(setLoadingUser())
  const response = await api.getCurrentProfile()
  return response.data
})

export const updateCurrentUser = createAsyncThunk(
  'users/updateCurrentUser',
  async (user: Partial<CurrentUserProfile>) => {
    const response = await api.updateCurrentProfile(user)
    return response.data.profile
  },
)

export const loadCurrentUserSettings = createAsyncThunk(
  'users/fetchCurrentUserSettings',
  async (_, thunk) => {
    thunk.dispatch(setLoadingSettings())
    const response = await api.getUserSettings()
    return response.data.settings
  },
)

export const currentUserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<CurrentUserProfile>) => {
      state.user = action.payload
      state.loading.user = false
      state.isUserLoaded = true
      state.isLoggedIn = true
    },
    setCurrentUserSetting: (
      state,
      action: PayloadAction<{
        key: keyof CurrentUserSettings
        value: any
      }>,
    ) => {
      state.settings = {
        ...state.settings,
        [action.payload.key]: action.payload.value,
      }
    },
    setCurrentUserSettings: (state, action: PayloadAction<CurrentUserSettings>) => {
      state.settings = action.payload
      state.areSettingsLoaded = true
    },
    setLoadingUser: (state, action: PayloadAction<boolean | undefined>) => {
      state.loading.user = action.payload ?? true
      if (action.payload) state.isUserLoaded = true
    },
    setLoadingSettings: (state, action: PayloadAction<boolean | undefined>) => {
      state.loading.settings = action.payload ?? true
    },
    setGmailAuthorizedEmails: (state, action: PayloadAction<string[] | undefined>) => {
      state.gmailAuthorizedEmails = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCurrentUser.fulfilled, (state, action) => {
        state.user = action.payload!
        state.isLoggedIn = true
        state.isUserLoaded = true
        delete state.loading.user
      })

      .addCase(loadCurrentUser.rejected, (state) => {
        delete state.loading.user
      })

      .addCase(loadCurrentUserSettings.fulfilled, (state, action) => {
        state.settings = action.payload!
        state.areSettingsLoaded = true
        delete state.loading.settings
      })

      .addCase(loadCurrentUserSettings.rejected, (state) => {
        delete state.loading.settings
      })

      .addCase(updateCurrentUser.fulfilled, (state, action) => {
        state.user = action.payload
      })
  },
})

export const {
  setCurrentUser,
  setCurrentUserSetting,
  setCurrentUserSettings,
  setLoadingSettings,
  setLoadingUser,
  setGmailAuthorizedEmails,
} = currentUserSlice.actions

export const selectCurrentUser = (state: RootState) => state.currentUser.user as CurrentUserProfile
export const selectCurrentUserSettings = (state: RootState) =>
  state.currentUser.settings as CurrentUserSettings

export const selectCurrentUserLoaded = (state: RootState) => state.currentUser.isUserLoaded
export const selectCurrentUserLoading = (state: RootState) => state.currentUser.loading.user
export const selectCurrentUserSettingLoading = (state: RootState) =>
  state.currentUser.loading.settings
export const selectCurrentUserLoggedIn = (state: RootState) => state.currentUser.isLoggedIn
export const selectCurrentUserSettingsLoaded = (state: RootState) =>
  state.currentUser.areSettingsLoaded

export const selectCurrentUserGmailAuthorizedEmails = (state: RootState) =>
  state.currentUser.gmailAuthorizedEmails

export const selectCurrentUserTheme = createSelector(
  selectCurrentUser,
  selectCurrentUserLoaded,
  (user, userLoaded) => (userLoaded ? user.theme : null),
)

export default currentUserSlice.reducer
