import React from 'react'

import { transparentize } from 'polished'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import { FiltersValueType } from 'components/Filters'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { VoteTrackerStage } from 'utils/types/vote'

import LikedRow from './Row'
import { Filters } from './index'

const Container = styled.div<{ vertical: boolean }>`
  ${tw`flex flex-col flex-1 gap-2 h-full`}
  ${({ vertical }) => !vertical && tw`overflow-y-auto`}
`

const Header = styled.div`
  ${tw`mt-1 pb-0.5 mx-2 pl-2 rounded-full`}

  box-shadow: 0 0 0 1px ${({ theme }) => theme.layout.main_bg_color};
  background-color: ${({ theme }) => transparentize(0.07, theme.layout.main_bg_color)};
`

const EmptyStateContainer = styled(Typography)`
  ${tw`rounded p-4 text-center`}

  color: ${({ theme }) => theme.colors.fog};
  background-color: ${({ theme }) => theme.colors.primary_bg};
`

interface Props {
  teamSlug: string
  stage: VoteTrackerStage
  personId?: number
  companyId?: number
  vertical: boolean
  dismissModal?: () => void
  filters: Filters
}

const Stage: React.VFC<Props> = ({
  teamSlug,
  stage,
  personId,
  companyId,
  vertical,
  dismissModal,
  filters,
}) => {
  const votesQuery = useInfiniteQuery(
    ['getVotes', teamSlug, stage, companyId, personId, filters],
    ({ pageParam: page = 1 }) =>
      callApi(api.getVotes, teamSlug, stage, page, personId, companyId, filters),
    {
      keepPreviousData: false,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: 1000 * 60 * 5,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
    },
  )
  const votes = votesQuery.data?.pages.flatMap((page) => page.votes) ?? []

  const rows = votes?.map((vote) => (
    <LikedRow
      refetch={votesQuery.refetch}
      dismissModal={dismissModal}
      stage={stage}
      key={vote.uuid}
      teamSlug={teamSlug}
      vote={vote}
    />
  ))

  let header: React.ReactNode = <></>

  if (stage === 'liked') {
    header = (
      <Typography fontWeight={600} fontSize="12">
        <Typography color="fog">
          <i className="far fa-thumbs-up mr-2" />
        </Typography>
        Offers
      </Typography>
    )
  } else if (stage === 'draft_shared') {
    header = (
      <Typography fontWeight={600} fontSize="12">
        <Typography color="fog">
          <i className="far fa-share mr-2" />
        </Typography>
        Draft Shared
      </Typography>
    )
  } else if (stage === 'message_sent') {
    header = (
      <Typography fontWeight={600} fontSize="12">
        <Typography color="fog">
          <i className="far fa-check-circle mr-2" />
        </Typography>
        Sent
      </Typography>
    )
  }

  return (
    <Container vertical={vertical}>
      <Header>{header}</Header>
      {votesQuery.isLoading && <Loading size={25} />}
      {!votesQuery.isLoading && rows.length === 0 && (
        <EmptyStateContainer component="div" fontSize="13">
          No {stage === 'liked' ? 'offers' : stage === 'draft_shared' ? 'drafts' : 'messages'} found
        </EmptyStateContainer>
      )}
      {rows}
      {votesQuery.hasNextPage && (
        <CabalButton
          variant="secondary"
          working={votesQuery.isFetchingNextPage}
          onClick={() => votesQuery.fetchNextPage()}
        >
          Load more
        </CabalButton>
      )}
    </Container>
  )
}

export default Stage
