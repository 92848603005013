import React from 'react'
import Typography from 'global/Typography'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useParams } from 'react-router-dom'
import { CompanySlugParam } from 'utils/types'
import { useQuery } from 'react-query'
import axios from 'axios'
import moment from 'moment'
import api, { callApi } from 'utils/api'
import CabalTitle from 'global/Title'
import Comments from './Comments'
import Card from 'global/Card'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import { LimitedUser } from 'utils/types'
import Loading from 'global/Loading'
import Avatar from 'global/Avatar'
import Tooltip from 'global/Tooltip'
import MessageParsed from 'components/MessageParsed'
import { useInfiniteQuery } from 'react-query'
import EmptyState from './EmptyState'

const CalendarEvent = ({ event, companySlug, commentEventId, setCommentEventId }: any) => {
  const [data, setData] = React.useState({})

  React.useEffect(() => {
    axios.get(`/api/calendar_events/${event.id}?team=${companySlug}`).then((response) => {
      setData(response.data)
    })
  }, [companySlug])

  const users: LimitedUser[] = data?.event?.users || []
  const notes: LimitedUser[] = data?.event?.notes || []

  return (
    <Card>
      <div className="flex flex-row w-full">
        <div className="w-1/2">
          {event.starts_at &&
            <div className="mb-1">
              {moment(event.starts_at).format('LLL')} {event.start_timezone} ({moment(event.starts_at).fromNow()})
            </div>
          }
          <div>
            <b>{event.summary}</b>
          </div>
          <div className="mt-2">
            {event.attendees.map(attendee => (
              <span className="items-center cursor-pointer">
                {/* <Avatar src={users[attendee[0]]?.avatar_url} name={users[attendee[0]]?.email} size="24" className="mr-2" />
                <Typography>
                  {users[attendee[0]]?.email || attendee[0]}
                  {event.organizer_email == (users[attendee[0]]?.email || attendee[0]) && 
                    <span> (organizer)</span>
                  }
                </Typography> */}
                <Tooltip label={
                  <>
                    {users[attendee[0]]?.email || attendee[0]}
                    {event.organizer_email == (users[attendee[0]]?.email || attendee[0]) &&
                      <span> (organizer)</span>
                    }
                  </>
                }>
                  <Avatar src={users[attendee[0]]?.avatar_url} name={users[attendee[0]]?.email} size="24" className="mr-2" />
                </Tooltip>
              </span>
            ))}
          </div>
          <div className="mt-1">
            {notes &&
              <MessageParsed resources={true} body={notes} />
            }
          </div>
        </div>
        <div className="w-1/2">
          <div className="text-right text-xs">
            {event.zoom_link &&
              <CabalButton
                variant="secondary"
                className="mr-2"
                size="small"
                onClick={() => window.open(event.zoom_link, '_blank', 'noopener')}
              >
                <i className="fa fa-video mr-1" /> Zoom
              </CabalButton>
            }
            {event.meet_link &&
              <CabalButton
                variant="secondary"
                size="small"
                className="mr-2"
                onClick={() => window.open(event.meet_link, '_blank', 'noopener')}
              >
                <i className="fa fa-video mr-1" /> Google Meet
              </CabalButton>
            }
            <CabalButton
              variant="secondary"
              size="small"
              onClick={() => setCommentEventId(commentEventId != event.id ? event.id : null)}
            >
              <i className="fa fa-comment mr-1" /> 
              Add Comment
            </CabalButton>
          </div>

          <Comments
            showReply={commentEventId == event.id}
            externalType="CalendarEvent"
            externalId={event.id}
            teamSlug={companySlug}
          />
        </div>
      </div>
    </Card>
  )
}

const Calendar = () => {
  const [time, setTime] = React.useState<TimeProp>('6')
  const { company_slug: companySlug } = useParams<CompanySlugParam>()

  const [events, setEvents] = React.useState([])
  const [commentEventId, setCommentEventId] = React.useState(null)

  const {
    data: eventData,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [companySlug, 'calendar_events', companySlug],
    ({ pageParam: page = 1 }) => callApi(api.getCalendarEvents, companySlug, page),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
    },
  )

  React.useEffect(() => {
    const events = eventData?.pages?.flatMap((page) => page.events) ?? []
    setEvents(events)
  }, [eventData])

  return (
    <div className="mx-2 md:mx-auto mt-4 max-w-screen-md">
      <CabalTitle title={'Calendar'} />
      <div>
        <Typography fontWeight={500} fontSize="13" color="fog_rain">
          <a href="/auth/gmail?calendar=true" className="float-right">
            <i className="fa fa-plus mr-2 text-xs" />
            Add Calendar
          </a>
        </Typography>
        <b>Calendar</b>
      </div>
      {events.map(event =>
        <CalendarEvent
          companySlug={companySlug}
          event={event}
          key={event.id}
          setCommentEventId={setCommentEventId}
          commentEventId={commentEventId}
        />
      )}
      {!isLoading && events.length == 0 &&
        <div className="mt-2">
          <EmptyState />
        </div>
      }
      {hasNextPage && (
        <CabalButton
          variant="secondary"
          disabled={isFetchingNextPage}
          onClick={() => fetchNextPage()}
        >
          Load more
        </CabalButton>
      )}
    </div>
  )
}

export default Calendar