import * as React from 'react'

import CabalTitle from 'global/Title'

import Footer from '../NewSite/footer'
import Nav from '../NewSite/nav'
import { LandingPageWrapper, Section } from '../NewSite/styles'
import PDF from './pdf'
import { Background } from './styles'

const Terms = () => {
  return (
    <LandingPageWrapper>
      <Background>
        <CabalTitle title="Terms" />
        <Nav />
        <Section className="flex items-start justify-center">
          <PDF pdf={'/legal.pdf'} />
        </Section>
        <Footer className="pb-8" />
      </Background>
    </LandingPageWrapper>
  )
}

export default Terms
