import React, { useEffect } from 'react'

import { useMutation } from 'react-query'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { MultiSelect, Select, TextInput } from 'global/Input'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'

import Header from './Header'
import { Card, ContinueWrapper, InputWrapper } from './styles'

interface Props {
  onContinue: () => void
}

const OrgDetails: React.FC<Props> = ({ onContinue }) => {
  const { user } = useCurrentUser()
  const { team, reloadTeam } = useTeam(user.team?.slug || null)
  const ICPs = team?.ideal_customer_profile?.split(',').filter((text) => text != '') || []
  const [orgName, setOrgName] = React.useState<string>()
  const [orgType, setOrgType] = React.useState<'company' | 'firm'>('company')
  const [orgSize, setOrgSize] = React.useState<string | undefined>()
  const [icps, setIcps] = React.useState<string[]>(ICPs)

  const disable = !orgName || !orgType

  useEffect(() => {
    if (team) {
      setOrgName(team.name)
      setOrgSize(team.size)
    }
  }, [team])

  const { mutate: teamUpdate, isLoading: icpLoading } = useMutation(
    ['teamUpdate'],
    () => {
      if (!user.team?.slug) {
        throw new Error('Team slug is undefined')
      }

      return callApi(api.updateTeam, user.team.slug, {
        team: {
          name: orgName,
          ideal_customer_profile: icps.toString(),
          enable_portfolio: orgType === 'firm',
          enable_offers: orgType === 'firm',
          size: orgSize,
        },
      }).then(() => reloadTeam())
    },
    {
      onSuccess: () => {
        onContinue()
      },
    },
  )

  return (
    <Card>
      {team?.logo && (
        <div className="flex items-center justify-center mb-2">
          <Avatar src={team.logo} size={'48'} />
        </div>
      )}
      <Header title="Add organization details" subtitle="" />
      <InputWrapper>
        <Typography fontSize="14" className="flex-shrink-0 w-[160px]">
          Name
        </Typography>
        <TextInput
          className="w-full"
          type="text"
          placeholder="First Name"
          value={orgName}
          onChange={(e) => {
            setOrgName(e.target.value)
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <Typography fontSize="14" className="flex-shrink-0 w-[160px]">
          Type
        </Typography>
        <Select
          value={orgType}
          options={[
            { value: 'company', label: 'Company' },
            { value: 'firm', label: 'VC Firm' },
          ]}
          className="w-full"
          onChange={(e: 'company' | 'firm' | null) => {
            if (e) setOrgType(e)
          }}
          placeholder={'Select company or firm'}
          data-testid="org-type-select"
        />
      </InputWrapper>
      <InputWrapper>
        <Typography fontSize="14" className="flex-shrink-0 w-[160px]">
          {orgType === 'company' ? 'Size' : 'Size (AUM)'}
        </Typography>
        <Select
          value={orgSize}
          options={
            orgType === 'company'
              ? [
                  { value: '0-10 people', label: '0-10 people' },
                  { value: '11-50 people', label: '11-50 people' },
                  { value: '51-250 people', label: '51-250 people' },
                  { value: '251+ people', label: '251+ people' },
                ]
              : [
                  { value: '$0-$10M', label: '$0-$10M' },
                  { value: '$11-$50M', label: '$11-$50M' },
                  { value: '$51M-$250M', label: '$51M-$250M' },
                  { value: '$251M+', label: '$251M+' },
                ]
          }
          className="w-full"
          onChange={(e: string | null) => {
            if (e) setOrgSize(e)
          }}
          placeholder={orgType === 'company' ? 'Select org size' : 'AUM'}
          data-testid="org-size-select"
        />
      </InputWrapper>

      <InputWrapper>
        <Typography fontSize="14" className="flex-shrink-0 w-[160px]">
          {orgType === 'company' ? 'Who do you sell to?' : 'Your focus areas'}
        </Typography>
        <MultiSelect
          value={icps}
          options={
            orgType === 'company'
              ? [
                  { label: 'Sales', value: 'Sales' },
                  { label: 'Marketing', value: 'Marketing' },
                  { label: 'Operations', value: 'Operations' },
                  { label: 'Support', value: 'Support' },
                  { label: 'Management', value: 'Management' },
                  { label: 'Legal', value: 'Legal' },
                  { label: 'Finance', value: 'Finance' },
                  { label: 'Accounting', value: 'Accounting' },
                  { label: 'Engineering', value: 'Engineering' },
                  { label: 'Product', value: 'Product' },
                  { label: 'Design', value: 'Design' },
                  { label: 'Other', value: 'Other' },
                ]
              : [
                  { value: 'AI', label: 'AI' },
                  { value: 'Consumer', label: 'Consumer' },
                  { value: 'Saas', label: 'Saas' },
                  { value: 'Fintech', label: 'Fintech' },
                  { label: 'Other', value: 'Other' },
                ]
          }
          className="w-full"
          onChange={(e) => {
            if (e) setIcps(e)
          }}
          placeholder={orgType === 'company' ? 'Add target buyer' : 'Add focus area'}
          data-testid="org-icp-select"
        />
      </InputWrapper>
      <ContinueWrapper>
        <CabalButton
          onClick={() => teamUpdate()}
          padding="8px 32px"
          data-testid="org-details-continue"
          disabled={disable}
        >
          Continue
        </CabalButton>
      </ContinueWrapper>
    </Card>
  )
}

export default OrgDetails
