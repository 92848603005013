import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

interface CardProps {
  icon: React.ReactNode
  title: string
  onClick: () => void
}

const Wrapper = styled.div`
  ${tw`flex flex-col justify-between rounded-lg p-4 cursor-pointer`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: 2px solid ${({ theme }) => theme.layout.main_bg_color};
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.purple};
  }
`

const ListCard: React.VFC<CardProps> = ({ icon, title, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Typography fontSize="20" color={'purple'}>
        {icon}
      </Typography>
      <div className="pt-20 flex justify-between items-center">
        <Typography
          style={{ maxWidth: 130 }}
          fontWeight={600}
          lineHeight={1}
          fontSize="14"
          className="pb-1"
          component="p"
        >
          {title}
        </Typography>
        <Typography fontSize="12" color={'purple'} component="p" lineHeight={1.4}>
          <i className="far fa-arrow-right"></i>
        </Typography>
      </div>
    </Wrapper>
  )
}

export default ListCard
