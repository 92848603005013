import React, { useCallback, useMemo } from 'react'

import CabalButton from 'global/CabalButton'
import DropDownMenu, { MenuItem } from 'global/DropDownMenu'
import { useModal } from 'global/Modal'

import { EmailSnippet } from 'utils/types'

import SnippetsModal from './SnippetsModal'

interface Props {
  snippets: EmailSnippet[]
  onReloadSnippets: () => Promise<any>
  onSelect: (snippet: EmailSnippet) => void
}

const EmailSnippets: React.VFC<Props> = ({ snippets, onReloadSnippets, onSelect }) => {
  const { showModal } = useModal()

  const handleRenderModal = useCallback(
    (resolve) => {
      return <SnippetsModal show onHide={() => resolve()} onReloadSnippets={onReloadSnippets} />
    },
    [onReloadSnippets],
  )

  const items = useMemo(
    () =>
      snippets
        .map<MenuItem>((snippet) => ({
          label: snippet.name,
          onSelect: () => onSelect(snippet),
        }))
        .concat({
          label: <ManageSnippet />,
          onSelect: () => showModal(handleRenderModal, 'email-snippets-modal'),
        }),
    [snippets, onSelect, showModal, handleRenderModal],
  )

  return null

  // return (
  //   <div className="right-actions flex">
  //     <DropDownMenu
  //       menuItems={items}
  //       trigger={
  //         <CabalButton
  //           rightIcon={<i className="fa fa-caret-down" />}
  //           variant="tertiary"
  //           component="span"
  //           size="small"
  //         >
  //           Snippets
  //         </CabalButton>
  //       }
  //     />
  //   </div>
  // )
}

const ManageSnippet = () => (
  <>
    <i className="fa fa-edit mr-1 text-secondary" />
    Manage Snippets
  </>
)

export default React.memo(EmailSnippets)
