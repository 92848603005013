import React, { useState } from 'react'

import CandidateNoteModal from 'containers/CandidateProfile/CandidateNoteModal'
import NoteRow from 'containers/CandidateProfile/NoteRow'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Widget from 'ui-components/Widget'

import { CandidateNote } from 'utils/types'

const Notes = (props: {
  candidateUuid: string
  notes: CandidateNote[]
  teamSlug: string
  reloadCandidateProfile: () => void
}) => {
  const [limit, setLimit] = React.useState(3)
  const [showAddButton, setShowAddButton] = useState(false)
  const notes = React.useMemo(() => props.notes.slice(0, limit), [props.notes, limit])
  const { showModal } = useModal()
  const { isAdminOrEmployee } = useAccessControl(props.teamSlug)

  const renderCandidateNoteModal = (resolve: () => void) => (
    <CandidateNoteModal
      candidateUuid={props.candidateUuid}
      teamSlug={props.teamSlug}
      onHide={() => resolve()}
      onFinalize={() => {
        props.reloadCandidateProfile()
        resolve()
      }}
      action={'create'}
    />
  )

  return (
    <Widget
      title="Notes"
      cta={
        isAdminOrEmployee && (
          <div
            className="-mr-1"
            style={{ opacity: showAddButton ? 1 : 0, transition: 'opacity 0.5s' }}
          >
            <CabalButton
              onClick={() =>
                showModal((resolve) => renderCandidateNoteModal(resolve), 'render_candidate_note')
              }
              variant="link"
            >
              Add
            </CabalButton>
          </div>
        )
      }
      onMouseEnter={() => setShowAddButton(true)}
      onMouseLeave={() => setShowAddButton(false)}
    >
      <div className="-mx-2">
        {notes.map((note) => (
          <div key={note.uuid}>
            <NoteRow
              note={note}
              teamSlug={props.teamSlug}
              reloadCandidateProfile={props.reloadCandidateProfile}
            />
          </div>
        ))}
      </div>

      {props.notes.length > 3 && (
        <div className="flex justify-center pt-1">
          <CabalButton
            variant="link"
            onClick={() => (limit === 3 ? setLimit(props.notes.length) : setLimit(3))}
          >
            See all ({props.notes.length})
          </CabalButton>
        </div>
      )}
    </Widget>
  )
}

export default Notes
