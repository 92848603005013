export function resizedataURL(datas: string, wantedWidth: number, wantedHeight: number) {
  return new Promise<string>((resolve) => {
    // We create an image to receive the Data URI
    const img = document.createElement('img')

    // When the event "onload" is triggered we can resize the image.
    img.onload = function () {
      // We create a canvas and get its context.
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      // We set the dimensions at the wanted size.
      canvas.width = wantedWidth
      canvas.height = wantedHeight

      // We resize the image with the canvas method drawImage();
      ctx?.drawImage(img, 0, 0, wantedWidth, wantedHeight)

      resolve(canvas.toDataURL('image/png', 1.0))
    }

    // We put the Data URI in the image's src attribute
    img.src = datas
  })
}
