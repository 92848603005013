import CabalButton from 'global/CabalButton'
import { TextInput, TextArea } from 'global/Input'
import Modal from 'global/Modal'
import { ModalSectionWrapper, ModalInputWrapper, ModalInputLabel } from 'global/Modal/styles'
import React, { useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'
import api, { callApi } from 'utils/api'

interface Props {
  show: boolean
  onHide: () => void
  teamSlug: string
}

const MemberAddCompanyModal: React.VFC<Props> = ({ show, onHide, teamSlug }) => {
  const { user } = useCurrentUser()
  const { team } = useTeam(teamSlug)
  const [note, setNote] = useState<string>('')
  const [companyName, setCompanyName] = useState<string>(user.team?.name || '')

  const placeholder = `Please add ${user.team?.name} to the ${team?.name} Portfolio on Cabal.`

  const { mutate: requestJoinTeam, isLoading: isRequesting } = useMutation(
    ['requestJoinPortfolio'],
    () => callApi(api.requestJoinInvestorCompany, teamSlug, companyName, note),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Request sent' })
        onHide()
      },
    },
  )

  return (
    <Modal
      header={'Add company to portfolio'}
      show={show}
      onHide={onHide}
      rightActions={
        <CabalButton
          disabled={companyName === ''}
          working={isRequesting}
          onClick={() => requestJoinTeam()}
        >
          Request
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Comany name</ModalInputLabel>

          <TextInput
            onChange={(e) => setCompanyName(e.currentTarget.value)}
            value={user.team?.name}
            className="w-full"
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Add a note (optional)</ModalInputLabel>
          <TextArea
            value={note}
            onChange={(e) => setNote(e.currentTarget.value)}
            className="w-full"
            placeholder={placeholder}
            rows={3}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default MemberAddCompanyModal
