import React from 'react'

import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import Legal from 'containers/Settings/Company/Legal'
import { useAccessControl } from 'global/AccessControl'
import { useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { CompanySlugParam, Team } from 'utils/types'
import { CapTableType } from 'utils/types'

import AgreementsExport from '../AdvisorProgram/AgreementsExport'
import CapTable from './CapTable'
import EquityTierWrapper from './EquityTierWrapper'

export interface TeamSettingProps {
  team: Team
  reloadTeam: () => Promise<any>
}

const Company = () => {
  const { company_slug: companySlug } = useParams<CompanySlugParam>()
  const { team, reloadTeam } = useTeam(companySlug)
  const { canExportAgreements, canViewEquity } = useAccessControl(companySlug)
  const [capTable, setCapTable] = React.useState<CapTableType>()

  useQuery(['getCapTable', team?.slug], () => callApi(api.getCapTable), {
    onSuccess: (response) => {
      setCapTable(response.cap_table)
    },
  })

  if (!team) return <></>

  const teamSettingProps: TeamSettingProps = {
    reloadTeam,
    team,
  }

  return (
    <div className="flex flex-col gap-4 mt-4">
      <Legal {...teamSettingProps} />

      {canViewEquity && (
        <>
          <CapTable {...teamSettingProps} capTable={capTable} setCapTable={setCapTable} />
          <EquityTierWrapper capTable={capTable} setCapTable={setCapTable} />
        </>
      )}
      {canExportAgreements && <AgreementsExport {...teamSettingProps} />}
    </div>
  )
}

export default Company
