import React, { useEffect, useState } from 'react'
import api, { callApi } from 'utils/api'
import { useMutation, useQuery } from 'react-query'
import { useSearchParam, useSetState } from 'react-use'
import { Redirect, useParams } from 'react-router-dom'
import Card from 'global/Card'
import { CardWidth, Container } from './styles'
import { cabalToast } from 'ui-components/Toast'
import CabalButton from 'global/CabalButton'
import { useCurrentUser, useUniqueAccessLink } from 'store/hooks'
import Loading from 'global/Loading'
import { InvestorCompany, InvestorCompanyJob } from 'utils/types/investor'
import Typography from 'global/Typography'
import LoggedInSection from './LoggedInSection'
import validator from 'validator'
import { FormDetails, RequestIntroForm } from 'containers/Portfolio/MemberView/RequestIntroModal'

const RequestConfirmation: React.VFC = () => {
  const { isLoading: isLoadingUser } = useCurrentUser()

  const {
    uuid: uniqueAccessLinkUuid,
    uniqueAccessLinkData,
    isLoadingUniqueAccessLink: isLoadingUniqueAccessLink,
  } = useUniqueAccessLink()

  const jobUuid = useSearchParam('job_uuid') ?? undefined
  const teamSlug = useSearchParam('team_slug') || ''
  const { company_uuid: companyUuid, advisor_uuid: advisorUuid } = useParams<{
    company_uuid: string
    advisor_uuid: string
  }>()

  const [requestUuid, setRequestUuid] = useState<string>()
  const [companyDetails, setCompanyDetails] = useState<InvestorCompany>()
  const [jobDetails, setJobDetails] = useState<InvestorCompanyJob>()
  const [details, setDetails] = useSetState<FormDetails>()
  const [linkedinUrlValid, setLinkedinUrlValid] = useState(false)

  useEffect(() => {
    setDetails({
      firstName: uniqueAccessLinkData?.recipient_first_name || '',
      lastName: uniqueAccessLinkData?.recipient_last_name || '',
      linkedinUrl: uniqueAccessLinkData?.recipient_linkedin_url || '',
    })
    setLinkedinUrlValid(validator.isURL(uniqueAccessLinkData?.recipient_linkedin_url || ''))
  }, [isLoadingUser, isLoadingUniqueAccessLink])

  const { isLoading: isLoadingCompany } = useQuery(
    ['getInvestorCompany'],
    () => callApi(api.getInvestorCompany, companyUuid, teamSlug),
    {
      onSuccess: ({ company }: { company: InvestorCompany }) => {
        setCompanyDetails(company)
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
      enabled: !!companyUuid,
    },
  )

  const { isLoading: isLoadingJob } = useQuery(
    ['getInvestorCompanyJob'],
    () => callApi(api.getInvestorCompanyJob, companyUuid, jobUuid as string, teamSlug),
    {
      onSuccess: ({ job }: { job: InvestorCompanyJob }) => {
        setJobDetails(job)
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
      enabled: !!jobUuid,
    },
  )

  const { mutate: requestIntro, isLoading } = useMutation(
    ['requestIntro'],
    () => {
      return callApi(
        api.requestIntro,
        companyUuid,
        teamSlug,
        details,
        jobUuid,
        uniqueAccessLinkUuid,
      )
    },
    {
      onSuccess: ({ intro_request }) => {
        setRequestUuid(intro_request.secret_uuid)
      },
      onError: (error) => {
        const errMsg = error?.response?.data?.error
        cabalToast({
          style: 'error',
          content: `Something went wrong${errMsg ? ': ' + errMsg : '!'}`,
        })
      },
    },
  )

  if (isLoadingCompany || isLoadingUser || (jobUuid && isLoadingJob) || isLoadingUniqueAccessLink)
    return <Loading />

  if (requestUuid)
    return (
      <Redirect
        to={`/intro-requests/${requestUuid}/sent?company_uuid=${companyUuid}&team_slug=${teamSlug}`}
      />
    )

  const disabled =
    !details.linkedinUrl ||
    !linkedinUrlValid ||
    !details.context ||
    isLoading ||
    !details.firstName ||
    !details.lastName

  return (
    <Container>
      <LoggedInSection />
      <CardWidth>
        <Card className="p-5 w-full">
          <Typography
            fontSize="16"
            fontWeight={600}
            lineHeight="1.2"
            data-testid="request-intro-title"
          >
            {`Request intro to ${companyDetails?.company_name}`}
          </Typography>
          <RequestIntroForm
            companyName={companyDetails?.company_name || ''}
            companyLogo={companyDetails?.logo_url || ''}
            details={details}
            setDetails={setDetails}
            setLinkedinUrlValid={setLinkedinUrlValid}
            jobTitle={jobDetails?.title}
            isIntroToCandidate={!!advisorUuid}
          />
          <CabalButton
            className="mt-3 w-full"
            onClick={() => requestIntro()}
            working={isLoading}
            disabled={disabled}
            data-testid="request-confirmation-send-btn"
          >
            Send
          </CabalButton>
        </Card>
      </CardWidth>
    </Container>
  )
}

export default RequestConfirmation
