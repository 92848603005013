import React from 'react'

import ProfileCTAs from 'containers/GlobalProfile/ProfileCTAs'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import PillV2 from 'ui-components/PillV2'

import copyToClipboard from 'utils/copyToClipboard'
import { UserProfile } from 'utils/types'

interface Props {
  onEditProfile?: () => void
  profile: UserProfile
  reloadProfile: () => void
  filter: string
  onSetFilter: (filter: string) => void
}

const ProfileFilters: React.FC<Props> = ({
  profile,
  onEditProfile,
  reloadProfile,
  filter,
  onSetFilter,
}) => {
  return (
    <div className="flex justify-between items-center mt-6">
      <div className="flex flex-wrap gap-2">
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              All
            </Typography>
          }
          active={filter === ''}
          onClick={() => onSetFilter('')}
        />
        {(profile.bio || profile.location) && (
          <PillV2
            title={
              <Typography fontSize="12" lineHeight={1}>
                About
              </Typography>
            }
            active={filter === 'About'}
            onClick={() => onSetFilter('About')}
          />
        )}
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              Portfolio
            </Typography>
          }
          active={filter === 'Portfolio'}
          onClick={() => onSetFilter('Portfolio')}
        />
      </div>
      <div>
        <ProfileCTAs
          profile={profile}
          onEditProfile={onEditProfile}
          reloadProfile={reloadProfile}
        />
        <CabalButton
          variant="link"
          leftIcon={<i className="far fa-link" />}
          tooltip="Copy Profile Link"
          onClick={() => {
            copyToClipboard(window.location.href, 'Copied')
          }}
        />
      </div>
    </div>
  )
}

export default ProfileFilters
