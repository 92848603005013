import React from 'react'
import Tabs from 'global/Tabs'
import { useHistory, useLocation, useParams } from 'react-router-dom'

interface Props {
  rightActions?: any
  noPaddingAndHeight?: boolean
}

const Subtabs = (props: Props) => {
  const { rightActions, noPaddingAndHeight = true } = props

  const { company_slug: teamSlug } = useParams()
  const location = useLocation()
  const history = useHistory()

  const subtabs = [
    {
      label: 'Inbox',
      id: 'Inbox',
      to: `/${teamSlug}/messages`,
      active: location.pathname === `/${teamSlug}/messages`,
    },
    {
      label: 'Drafts',
      id: 'Drafts',
      to: `/${teamSlug}/drafts`,
      active: location.pathname.startsWith(`/${teamSlug}/drafts`),
    },
    {
      label: 'Templates',
      id: 'Templates',
      to: `/messages/templates`,
      active: location.pathname === `/messages/templates`,
    },
  ]

  return (
    <Tabs
      data={subtabs}
      variant="filters"
      index={subtabs.indexOf(subtabs.find((tab) => tab.active))}
      onChange={(index) => history.push(subtabs[index].to)}
      noPaddingAndHeight={noPaddingAndHeight}
      rightActions={rightActions}
    />
  )
}

export default Subtabs
