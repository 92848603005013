import styled from 'styled-components'
import tw from 'twin.macro'

export const QuillContainer = styled.div`
  .ql-toolbar.ql-snow {
    ${tw`flex items-center`}
    background: ${({ theme }) => theme.layout.main_bg_color};
    color: ${({ theme }) => theme.colors.primary};
    border: none;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    margin-bottom: 3px;
  }

  .ql-toolbar:after {
    display: none;
  }

  .ql-container.ql-snow {
    border: none;
    background: ${({ theme }) => theme.layout.main_bg_color};
    color: ${({ theme }) => theme.colors.primary};
  }

  .ql-snow .ql-stroke {
    stroke: ${({ theme }) => theme.colors.primary};
  }

  .ql-snow .ql-fill {
    fill: ${({ theme }) => theme.colors.primary} !important;
  }

  .ql-snow .ql-picker {
    color: ${({ theme }) => theme.colors.primary};
  }

  .ql-editor {
    border: none;
    max-height: 50vh;
  }

  .ql-snow .ql-editor {
    min-height: ${({ height }) => (height ? height : '320px')};
  }

  .ql-toolbar button:hover .ql-stroke,
  .ql-toolbar .ql-picker:hover .ql-stroke,
  .ql-toolbar button:focus .ql-stroke,
  .ql-toolbar .ql-picker:focus .ql-stroke,
  .ql-toolbar .ql-active .ql-stroke {
    stroke: ${({ theme }) => theme.colors.primary} !important;
  }

  .ql-toolbar button:hover,
  .ql-toolbar button:focus,
  .ql-toolbar .ql-active,
  .ql-toolbar .ql-picker-label:hover,
  .ql-toolbar .ql-picker-label.ql-active {
    color: ${({ theme }) => theme.colors.primary} !important;
  }

  .ql-snow .ql-editor pre.ql-syntax {
    background: ${({ theme }) => theme.colors.primary_bg};
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border: 0;
    background: ${({ theme }) => theme.colors.tint};
    color: ${({ theme }) => theme.colors.secondary};
  }

  .ql-toolbar button {
    margin: 0 1px;
  }

  .ql-toolbar button.ql-active {
    background: ${({ theme }) => theme.colors.tint};
    border: 2px solid ${({ theme }) => theme.colors.border};
    border-radius: 10px;
    padding: 2px 3px;
  }

  .ql-picker-item {
    padding: 0 10px;
    color: ${({ theme }) => theme.colors.primary} !important;
  }

  .ql-picker-item:hover {
    background: ${({ theme }) => theme.colors.border} !important;
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border: 0;
    border-radius: 4px;
    border-top-left-radius: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors.tint};
  }

  .emoji_completions {
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    background: ${({ theme }) => theme.colors.shadow};
    color: ${({ theme }) => theme.colors.primary};
  }

  .emoji_completions > li > button {
    padding: 3px 4px 6px;
    background: ${({ theme }) => theme.pill.purple_filled.primary_bg};
    color: ${({ theme }) => theme.pill.purple_filled.primary_text};
  }

  .emoji_completions > li > button:focus {
    background: ${({ theme }) => theme.pill.purple_filled.active_bg};
    color: ${({ theme }) => theme.pill.purple_filled.active_text};
  }

  button {
    float: none !important;
    height: auto !important;
    width: auto !important;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  table td {
    border-color: inherit;
  }
`
