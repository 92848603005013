import React from 'react'

import { useComposer } from 'components/Composer'
import HeaderSection from 'components/NewUserScreens/HeaderSection'
import CabalButton from 'global/CabalButton'
import { useCurrentUser } from 'store/hooks'

interface Props {
  teamSlug: string
}

const subject = `Cabal 101: Email composer`

const NoMailScreen: React.FC<Props> = ({ teamSlug }) => {
  const { compose } = useComposer(teamSlug)
  const { user } = useCurrentUser()

  const body = `
  Hi - welcome to Cabal 👋 , <br><br>
  This is our special email composer. Here are some of its superpowers:
  <ol>
    <li>Mail merge: Anyone in the “To” field receives a 1:1 email. </li>
    <li>Personalization: Use fields like “{{first_name}}” to customize your message.</li>
    <li>Templates: Create your own or choose from <a href=https://getcabal.com/messages/templates>global templates</a>.</li>
    <li>Collaboration: Work with internal and external collaborators on drafts.</li>
    <li>Send me a test: Send yourself a test email to confirm look & feel before sending a blast.</li>
    <li>Measure: Track opens and clicks.</li>
  </ol>
  This email is addressed to you. See how it works by clicking “Send” below. <br><br>
  Enjoy! <br>
`

  return (
    <div className="max-w-4xl flex flex-col self-center">
      <HeaderSection
        icon={<i className="fal fa-inbox"></i>}
        title={'Welcome to Cabal Mail'}
        description={`Personalized email with templates and tracking`}
      />
      <CabalButton
        className="self-center w-1/2"
        onClick={() =>
          compose({
            subject: subject,
            body: body,
            recipients: {
              users: [user],
            },
          })
        }
      >
        Get started
      </CabalButton>
    </div>
  )
}

export default NoMailScreen
