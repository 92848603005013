import React from 'react'

import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import ShareTemplate from 'containers/MessagesPage/Templates/ShareTemplate'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { EmailTemplate } from 'utils/types'

interface Props {
  teamSlug: string
  onSelectTemplate: (t: EmailTemplate) => void
  closeComposer: () => void
}

const Templates: React.VFC<Props> = ({ teamSlug, onSelectTemplate, closeComposer }) => {
  const history = useHistory()

  const getTemplatesQuery = useQuery(
    [teamSlug, 'getTemplates'],
    () => callApi(api.getTemplates, teamSlug),
    {
      keepPreviousData: true,
    },
  )
  const templates = getTemplatesQuery.data?.templates

  const manageTemplates = () => {
    cabalToast({ content: 'Navigating to Templates, you can find this message on Drafts page' })
    closeComposer()
    history.push(`/messages/templates`)
  }

  if (!templates) {
    return <Loading />
  }

  return (
    <>
      {templates.map((template) => (
        <Typography key={template.uuid} className="flex justify-between w-full px-3" fontSize="13">
          <button className="block flex-grow text-left" onClick={() => onSelectTemplate(template)}>
            {template.name}
          </button>
          <ShareTemplate template={template} teamSlug={teamSlug} showLabel={false} />
        </Typography>
      ))}

      <div className="text-center mt-4">
        <CabalButton size="small" onClick={manageTemplates}>
          Manage templates
        </CabalButton>
      </div>
    </>
  )
}

export default Templates
