import React, { useMemo, useState } from 'react'

import { Options } from 'react-hotkeys-hook'
import { useQuery } from 'react-query'
import { Redirect, useHistory } from 'react-router-dom'

import Option, { CreateOption } from 'containers/Connections/Lists/Option'
import CreateTemplateSection from 'containers/GlobalMessagesPage/Templates/CreateTemplateSection'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import PageWrapper from 'global/PageWrapper'
import Tabs, { Tab } from 'global/Tabs'
import CabalTitle from 'global/Title'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'

// import Table from './Table'
import EmptyFeedImage from './../../../images/empty-feed@2x.png'
import CardView from './CardView'
import EditTemplate from './EditTemplate'

const Templates: React.VFC = () => {
  const { user } = useCurrentUser()
  const team = user.team
  const teamSlug = team?.slug
  const { showModal } = useModal()

  const history = useHistory()
  const [tab, setTab] = useState<'all' | 'public' | 'private' | 'team'>('all')

  const {
    data,
    isLoading,
    refetch: reloadTemplates,
  } = useQuery(['getTemplates', teamSlug], () => callApi(api.getTemplates, teamSlug), {
    enabled: team?.permissions?.canViewMessageTemplates,
  })

  const templates = useMemo(() => data?.templates ?? [], [data])
  const defaultTemplates = useMemo(() => templates.filter((t) => t.initial), [templates])
  const otherTemplates = useMemo(() => templates.filter((t) => !t.initial), [templates])

  // if (!team?.permissions?.canViewMessageTemplates) {
  //   return <Redirect to={`/${teamSlug}`} />
  // }

  const renderEditModal = (resolve: any) => (
    <EditTemplate
      onHide={() => resolve()}
      reloadTemplates={reloadTemplates}
      teamSlug={teamSlug || ''}
    />
  )

  return (
    <PageWrapper title={'Templates'} className="h-screen overflow-y-scroll px-0">
      {/* <CabalTitle title="Templates" /> */}

      {isLoading ? (
        <Loading className="my-12" />
      ) : (
        <div className="">
          <CreateTemplateSection
            templates={defaultTemplates}
            reloadTemplates={reloadTemplates}
            teamSlug={teamSlug || ''}
          />

          {otherTemplates.length === 0 && (
            <EmptyState
              image={
                <Typography className="pb-4" fontSize="24" color={'rain'}>
                  <i className="fal fa-inbox"></i>
                </Typography>
              }
              heading={'Add your first template'}
              body={
                <div className="text-center">
                  <Typography textAlign="center" component="h5" className="mb-11">
                    Create templates to save time when messaging your investors and advisors.
                  </Typography>
                  <div className="flex justify-center">
                    <CabalButton variant="primary" onClick={() => history.push(`templates/new`)}>
                      Add template
                    </CabalButton>
                  </div>
                </div>
              }
            />
          )}

          <div className=" mb-8">
            {otherTemplates &&
              tab === 'all' &&
              otherTemplates.map((template) => (
                <CardView
                  key={template.uuid}
                  template={template}
                  reloadTemplates={reloadTemplates}
                  teamSlug={teamSlug || ''}
                />
              ))}
          </div>
        </div>
      )}
    </PageWrapper>
  )
}

export default Templates
