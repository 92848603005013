import * as React from 'react'

const SignSuccess = ({ onHide, title, text }) => (
  <div className="bg-white p-8 w-11/12 text-center mx-auto">
    <span className="h-12 w-12 rounded-full bg-green-100 text-green-600">
      <i className="fal fa-thumbs-up"></i>
    </span>
    <div className="mt-5">
      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
        Success! The document was signed and sent to the founder.
      </h3>
      <div className="mt-2">
        <p className="text-sm leading-5 text-gray-500">
          You will receive an email with a copy of the document when the founder counter-signs.
        </p>
      </div>
    </div>
  </div>
)

export default SignSuccess
