import React, { useCallback, useMemo } from 'react'
import emoji from 'react-easy-emoji'
import styled from 'styled-components'

import Table, { Row, Column, Span } from 'global/Table'
import Typography from 'global/Typography'
import { EmailSnippet } from 'utils/types'

interface Props {
  disabled: boolean
  snippets: EmailSnippet[]
  onClickEdit: (snippet: EmailSnippet) => void
  onClickDelete: (snippet: EmailSnippet) => void
}

const SnippetsTable: React.VFC<Props> = ({ disabled, snippets, onClickEdit, onClickDelete }) => {
  const handleEdit = useCallback(
    (snippet: EmailSnippet) => (e: React.MouseEvent) => {
      if (disabled) return

      e.stopPropagation()
      onClickEdit(snippet)
    },
    [onClickEdit, disabled],
  )

  const handleDelete = useCallback(
    (snippet: EmailSnippet) => (e: React.MouseEvent) => {
      if (disabled) return

      e.stopPropagation()
      onClickDelete(snippet)
    },
    [onClickDelete, disabled],
  )

  const columns = useMemo(
    (): Column<EmailSnippet>[] => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Privacy',
        accessor: 'public_to_team',
      },
      {
        Header: '',
        accessor: 'actions',
      },
    ],
    [],
  )

  const data = useMemo<Row<EmailSnippet>[]>(
    () =>
      snippets.map((snippet) => ({
        id: snippet.uuid,
        data: snippet,
        name: <SpanWithEmoji>{emoji(snippet.name)}</SpanWithEmoji>,
        public_to_team: snippet.public_to_team && (
          <Span className="text-sm">
            <i className="fa fa-eye mr-2" /> Public to team
          </Span>
        ),
        actions: (
          <Span className="justify-end">
            {snippet.can_edit && (
              <>
                <HoverTypography
                  component="i"
                  color="fog_rain"
                  className="fa fa-edit cursor-pointer"
                  onClick={handleEdit(snippet)}
                />
                <HoverTypography
                  component="i"
                  color="fog_rain"
                  className="fa fa-trash cursor-pointer ml-4"
                  onClick={handleDelete(snippet)}
                />
              </>
            )}
          </Span>
        ),
      })),
    [snippets, handleDelete, handleEdit],
  )

  return <Table data={data} columns={columns} sortable={false} />
}

const SpanWithEmoji = styled(Span)`
  img {
    margin-right: 6.5px !important;
  }
`

const HoverTypography = styled(Typography)`
  &:hover {
    color: ${(props) => props.theme.colors.fog};
  }
`

export default SnippetsTable
