import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import Typography from 'global/Typography'

export const StyledImage = styled.img`
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
`
export const StyledFontAwesomeIcon = styled.i`
  color: #718096;
  class: ${(props) => props.className};
  opacity: ${(props) => props.opacity};
`
export const BlurbInfoDiv = styled.div`
  min-height: 270px;
  background-color: #def2e6;
  ${tw`p-1 max-w-lg rounded-xl flex flex-col gap-2 items-start`}
`
export const MaterialInfoDiv = styled.div`
  min-height: 270px;
  background-color: #def2e6;
  ${tw`p-1 max-w-lg rounded-xl flex flex-col gap-2 items-start`}
`
export const AccountsProfilesDiv = styled.div`
  min-height: 377px;
  background-color: #def2e6;
  ${tw`p-1 max-w-lg rounded-xl flex flex-col gap-2 items-start`}
`
export const StyledMaterialDivSubSection = styled.div`
  border-width: ${(props) => props.borderWidth || '1px'};
  border-style: ${(props) => props.borderStyle || 'dashed'};
  ${tw`p-1.5 border-black text-sm w-full bg-white cursor-pointer`}
  color: rgb(0, 197, 93);
  border-color: #b0b8c4;
  border-radius: 4px;
`
export const StyledTypography = styled(Typography)`
  text-align: left;
  cursor: pointer;
  font-size: ${(props) => (props.isMobile ? '10px' : '12px')};
  font-weight: 400;
  color: rgb(0, 197, 93);
`
export const Card = styled.div`
  ${tw`mt-32 mx-4 p-2 sm:p-2 mx-1 rounded-xl`}
  background: rgb(255, 255, 255);
  box-shadow: 0px 6px 18px -17px rgba(92, 105, 209, 0.75);
  border: 1px solid rgb(228, 234, 239);
`
export const InfoHeadlineDiv = styled.div`
  ${tw`text-slate-900 font-medium text-2xl`}
`
export const StyledCabalButton = styled(CabalButton)`
  ${tw`mt-4 px-2 whitespace-nowrap`}
  background-color: #03C168;
  &:hover {
    background-color: #03c168;
  }
`
export const CompanyBlurbDescripton = styled(TextArea)`
  ${tw`w-full`}
  background-color: white;
  color: #a7b4c7;
  border: none;
  height: 80px;
`
export const ErrorAlertDiv = styled.div`
  white-space: nowrap;
  ${tw`flex items-center bg-red-100 text-sm sm:text-red-800 border border-red-400 rounded px-1 py-1 sm:px-2 sm:py-2`}
`
export const UploadWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.mist};
`
export const ProgressBarInner = styled.div`
  background-color: ${({ theme }) => theme.buttons.primary.bg_color};
`
export const FileUploadDiv = styled.div`
  background-color: ${(props) => props.color};
  white-space: nowrap;
  ${tw`p-2 bg-green-400 text-white text-sm w-3/4 rounded-md text-center`}
`
