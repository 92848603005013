import React, { useRef, useState } from 'react'

import FileViewer from 'react-file-viewer'
import { ThreeDots } from 'react-loader-spinner'
import { useMutation } from 'react-query'
import styled, { useTheme } from 'styled-components'

import MessageParsed from 'components/MessageParsed'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Modal, { OnHideProp } from 'global/Modal/Modal'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { UploadBlueprint } from 'utils/types'

const FileViewerWrapper = styled.div`
  .pg-viewer-wrapper {
    overflow: hidden;
  }
`

const CandidateDocumentModal: React.VFC<
  {
    candidateUuid: string
    upload: UploadBlueprint
    onFinalize: () => void
    isCandidate: boolean
    teamSlug: string
  } & OnHideProp
> = ({ candidateUuid, upload, onHide, onFinalize, isCandidate, teamSlug }) => {
  const [working, setWorking] = React.useState(false)
  const { isAdminOrEmployee } = useAccessControl(teamSlug)

  const deleteCandidateDocumentMutation = useMutation(
    async () => {
      return await callApi(api.deleteCandidateDocument, teamSlug, candidateUuid, upload.uuid)
    },
    {
      onMutate: () => {
        setWorking(true)
      },
      onSuccess: () => {
        onFinalize()
        setWorking(false)
        cabalToast({
          style: 'success',
          content: 'Document deleted',
        })
      },
      onError: (error: any) => {
        setWorking(false)

        cabalToast({
          style: 'error',
          content: 'Failed to delete document',
        })
      },
    },
  )

  const handleDownload = async () => {
    try {
      const response = await fetch(upload.api_url)
      const blob = await response.blob()
      const link = document.createElement('a')
      const blobUrl = window.URL.createObjectURL(blob)
      link.href = blobUrl
      link.download = upload.file_name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(blobUrl)
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }

  const handleOpenLink = () => {
    window.open(upload.api_url, '_blank')
  }

  return (
    <Modal
      show={true}
      header={
        <div className="flex justify-between self-center items-center py-1">
          <div className="space-x-2">
            <Typography fontSize="14" fontWeight={600}>
              {upload.file_name}
            </Typography>
          </div>
          <div className="space-x-2">
            {(isAdminOrEmployee || isCandidate) && (
              <CabalButton
                variant="tertiary"
                size="small"
                working={working}
                onClick={() => confirm('Are you sure?') && deleteCandidateDocumentMutation.mutate()}
                data-testid="candidate-delete-document-button"
                leftIcon={<i className="far fa-trash" />}
                tooltip="Delete"
              />
            )}
            <CabalButton
              variant="tertiary"
              size="small"
              onClick={handleDownload}
              leftIcon={<i className="far fa-download" />}
              tooltip="Download"
            />
            <CabalButton
              variant="tertiary"
              size="small"
              onClick={handleOpenLink}
              leftIcon={<i className="far fa-up-right-from-square" />}
              tooltip="Open in a new tab"
            />
          </div>
        </div>
      }
      onHide={onHide}
    >
      <FileViewerWrapper className="text-center items-center pb-5 pl-8">
        <FileViewer fileType={upload.file_type.split('/')[1]} filePath={upload.api_url} />
      </FileViewerWrapper>
    </Modal>
  )
}

const Documents = (props: {
  candidateUuid: string
  uploads: UploadBlueprint[]
  reloadCandidateProfile: () => void
  teamSlug: string
  isCandidate: boolean
  isThisCandidate: boolean
}) => {
  const { showModal } = useModal()
  const [showAddButton, setShowAddButton] = useState(false)
  const [working, setWorking] = useState(false)
  const { isAdminOrEmployee } = useAccessControl(props.teamSlug)

  const addCandidateDocumentMutation = useMutation(
    async (file: File) => {
      const documentFormData = new FormData()
      documentFormData.append(`documents[0]`, file)
      return await callApi(
        api.uploadCandidateDocuments,
        props.teamSlug,
        props.candidateUuid,
        documentFormData,
      )
    },
    {
      onSuccess: () => {
        setWorking(false)
        props.reloadCandidateProfile()
        cabalToast({
          style: 'success',
          content: 'Document added successfully',
        })
      },
      onError: (error: any) => {
        setWorking(false)
        error.response
          ? cabalToast({
              style: 'error',
              content: `Failed to add document: ${error.response.data.errors.message}`,
            })
          : cabalToast({
              style: 'error',
              content: 'Failed to add document',
            })
      },
    },
  )

  const fileInputRef = useRef<HTMLInputElement>(null)
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWorking(true)
    const files = event.target.files
    if (files && files[0]) {
      const file = files[0]
      addCandidateDocumentMutation.mutate(file)
    }
  }
  const theme = useTheme()

  return (
    <Widget
      title="Documents"
      cta={
        (isAdminOrEmployee || props.isThisCandidate) &&
        (working ? (
          <ThreeDots
            visible={true}
            height="30"
            width="30"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperClass="mr-2"
            color={theme.colors.purple}
          />
        ) : (
          <div
            className="-mr-1"
            style={{ opacity: showAddButton ? 1 : 0, transition: 'opacity 0.5s' }}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileSelect}
              style={{ display: 'none' }}
            />
            <CabalButton onClick={() => fileInputRef.current?.click()} variant="link">
              Add
            </CabalButton>
          </div>
        ))
      }
      onMouseEnter={() => setShowAddButton(true)}
      onMouseLeave={() => setShowAddButton(false)}
    >
      <div className="-mx-2">
        {props.uploads.map((upload) => (
          <div key={upload.uuid}>
            <DataCardV2
              avatar={<i className="fa-thin fa-file" />}
              title={<MessageParsed body={upload.file_name} />}
              cta={
                <CabalButton
                  variant="link"
                  padding={'0'}
                  onClick={() => {
                    showModal(
                      (resolve) => (
                        <CandidateDocumentModal
                          onHide={resolve}
                          candidateUuid={props.candidateUuid}
                          upload={upload}
                          onFinalize={() => {
                            props.reloadCandidateProfile()
                            resolve()
                          }}
                          isCandidate={props.isThisCandidate}
                          teamSlug={props.teamSlug}
                        />
                      ),
                      'candidate-document-modal',
                    )
                  }}
                >
                  View
                </CabalButton>
              }
              onClick={() => {
                showModal(
                  (resolve) => (
                    <CandidateDocumentModal
                      onHide={resolve}
                      candidateUuid={props.candidateUuid}
                      upload={upload}
                      onFinalize={() => {
                        props.reloadCandidateProfile()
                        resolve()
                      }}
                      isCandidate={props.isThisCandidate}
                      teamSlug={props.teamSlug}
                    />
                  ),
                  'candidate-document-modal',
                )
              }}
            />
          </div>
        ))}
      </div>
    </Widget>
  )
}

export default Documents
