import React from 'react'

import last from 'lodash/last'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import ActivityRow from 'components/Activity/ActivityRow'
import Comment from 'components/CommentsAndActivity/Comment'
import CreateComment from 'components/CommentsAndActivity/CreateComment'
import { Divider } from 'components/SendMessage/styles'
import Loading from 'global/Loading'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { Activity, Comment as CommentType } from 'utils/types'

const ComponentWrapper = styled.div`
  ${tw`flex flex-col rounded-lg`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  box-shadow: 0px 6px 18px -17px rgba(92, 105, 209, 0.75);
  border: 1px solid ${({ theme }) => theme.colors.widget_border};
  border-radius: 10px;
  max-height: 350px;
  overflow-y: auto;
`

const Title = styled(Typography)`
  ${tw`pt-2 px-2`}

  font-size: 20px;
  font-weight: 600;
`

interface Props {
  companyUuid: string
  teamSlug: string
  attachableType: string
  attachableId: number
}

const CommentsAndActivity: React.FC<Props> = ({ attachableType, attachableId, teamSlug }) => {
  const [newCommentInternal, setNewCommentInternal] = React.useState<boolean>()

  let companyUuid = attachableType === 'Company' ? attachableId : null

  const commentsQuery = useQuery(
    ['getComments', attachableType, attachableId],
    () =>
      callApi(api.getComments, {
        attachable_id: attachableId,
        attachable_type: attachableType,
        team_slug: teamSlug,
      }),
    {
      enabled: !!attachableId,
      refetchInterval: 30 * 1000,
      onSuccess: ({ comments }) => {
        setNewCommentInternal(!!last(comments)?.internal)
      },
    },
  )

  const activitiesQuery = useQuery(
    ['getActivities', attachableId],
    () =>
      callApi(api.getMemberActivity, teamSlug, 'team_activities', undefined, {
        company_list_uuid: attachableId,
        start: new Date(0).toISOString(),
        end: new Date(new Date().getTime() + 48 * 60 * 60 * 1000).toISOString(),
      }),
    { enabled: !!attachableId },
  )

  const comments = (commentsQuery.data?.comments || []).map((co) => ({
    ...co,
    type: 'comment',
  }))
  const activities = (activitiesQuery.data?.activity || []).map((a) => ({
    ...a,
    type: 'activity',
  }))
  const mergedActivitiesAndComments = [...comments, ...activities].sort(
    (a, b) => -new Date(b.created_at).getTime() + new Date(a.created_at).getTime(),
  )

  const isLoading = commentsQuery.isLoading || activitiesQuery.isLoading

  if (!attachableId) {
    return null
  }

  return (
    <ComponentWrapper>
      <div className="p-2">
        <Title>Activity</Title>
        <div id="item-comments">
          <div className="space-y-2">
            {isLoading && (
              <div className="flex items-center justify-center my-2">
                <Loading />
              </div>
            )}
            {mergedActivitiesAndComments.map((commentOrActivity) =>
              commentOrActivity.type === 'comment' ? (
                <Comment
                  comment={commentOrActivity as CommentType}
                  key={commentOrActivity.uuid}
                  teamSlug={teamSlug}
                  refetchComments={commentsQuery.refetch}
                />
              ) : (
                <ActivityRow
                  key={commentOrActivity.uuid}
                  style={{ backgroundColor: 'transparent' }}
                  className=""
                  activity={commentOrActivity as Activity}
                  splitView={true}
                />
              ),
            )}

            {mergedActivitiesAndComments.length === 0 && !isLoading && (
              <Typography fontSize="12" color="fog_rain" className="px-2">
                No comments or activity yet
              </Typography>
            )}
          </div>
        </div>
      </div>

      <div className="-my-2 -mb-4">
        <Divider />
      </div>
      <div className="px-4">
        <CreateComment
          teamSlug={teamSlug}
          createProps={{ attachable_id: attachableId, attachable_type: attachableType }}
          refetchComments={commentsQuery.refetch}
          newCommentInternal={newCommentInternal}
        />
      </div>
    </ComponentWrapper>
  )
}

export default CommentsAndActivity
