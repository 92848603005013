import React from 'react'

import { Field, FieldProps, Form, Formik } from 'formik'
import { useMutation } from 'react-query'
import { object, string } from 'yup'

import {
  mapLinkedInProfileToCandidateProfile,
  useCandidateProfile,
} from 'containers/CandidateProfileModal'
import CabalButton from 'global/CabalButton'
import { TextInputField } from 'global/Input'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'

export const LINKED_IN_PROFILE_URL_REGEX =
  /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/

export const getLinkedInSlug = (url: string) => {
  const match = url.match(LINKED_IN_PROFILE_URL_REGEX)
  return match ? match[5] : ''
}

export const linkedInAutoPrefillSchema = object({
  linkedInProfileUrl: string()
    .required('This field is required')
    .matches(LINKED_IN_PROFILE_URL_REGEX, 'Please enter a valid LinkedIn profile URL'),
})

export default function LinkedInAutoPrefill(props: { onNext: () => void; linkedinUrl?: string }) {
  const { updateCandidateProfile, candidateProfile } = useCandidateProfile()
  let linkedInUrl: string | undefined

  const enrichProfileMutation = useMutation(
    (linkedInSlug: string) => {
      return callApi(api.enrichProfile, linkedInSlug)
    },
    {
      onSuccess: ({ profile }) => {
        if (!(profile.code && profile.code >= 400 && profile.code < 500)) {
          updateCandidateProfile(
            mapLinkedInProfileToCandidateProfile(profile, candidateProfile, linkedInUrl),
          )
        } else {
          updateCandidateProfile({
            firstName: profile.first_name,
            lastName: profile.last_name,
          })
        }
        props.onNext()
      },
    },
  )

  return (
    <Formik
      initialValues={{
        linkedInProfileUrl: props.linkedinUrl || '',
      }}
      validationSchema={linkedInAutoPrefillSchema}
      onSubmit={(values) => {
        linkedInUrl = values.linkedInProfileUrl
        const linkedInSlug = getLinkedInSlug(values.linkedInProfileUrl)
        enrichProfileMutation.mutate(linkedInSlug)
      }}
    >
      {({ handleSubmit }) => (
        <Form className="flex flex-col items-start" onSubmit={handleSubmit}>
          <Typography fontSize="14" className="pb-1" color="fog">
            Enter LinkedIn profile URL to autofill details
          </Typography>
          <Field name="linkedInProfileUrl">
            {(props: FieldProps) => (
              <TextInputField
                error={props.meta.touched ? props.meta.error : undefined}
                autoFocus
                {...props.field}
                className="w-full"
              />
            )}
          </Field>

          <CabalButton
            className="mt-6 mb-2 self-end"
            variant="primary"
            type="submit"
            disabled={enrichProfileMutation.isLoading}
          >
            {enrichProfileMutation.isLoading ? 'Enriching...' : 'Next'}
          </CabalButton>
        </Form>
      )}
    </Formik>
  )
}
