import React, { useCallback, useMemo } from 'react'

import { isEqual, uniqBy } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

import { AdvisorModel, AgreementTemplateField, AgreementTemplateModel, Params } from 'utils/types'

import { SendAgreementsState } from '.'
import AdvisorRow from './AdvisorRow'
import Field from './Field'

const Container = styled.div`
  ${tw`
    m-4
    p-4
    rounded-lg
    border
    dark:border-dark
    light:border-light
    overflow-x-auto
  `}

  background-color: ${({ theme }) => theme.colors.primary_bg};
`

interface Props {
  teamSlug: string
  advisors: AdvisorModel[]
  advisorParams: Params<Params>
  setState: (state: Partial<SendAgreementsState>) => void
  senderParams: Params
  senderFields: AgreementTemplateField[]
  advisorFields: AgreementTemplateField[]
  currentUserSending: boolean
}

const TeamUserFields: React.VFC<Props> = React.memo(
  ({
    advisors,
    senderFields,
    advisorFields,
    advisorParams,
    senderParams,
    setState,
    currentUserSending,
  }) => {
    const location = useLocation<Params>()
    const history = useHistory()

    const setAdvisorParams = useCallback(
      (advisor_uuid: string, field_uuid: string, value: any) => {
        setState({
          advisorParams: {
            ...advisorParams,
            [advisor_uuid]: {
              ...advisorParams[advisor_uuid],
              [field_uuid]: value,
            },
          },
        })
      },
      [advisorParams, setState],
    )

    const setSenderParams = useCallback(
      (field_uuid: string, v: any) => {
        setState({
          senderParams: {
            ...senderParams,
            [field_uuid]: v,
          },
        })
      },
      [senderParams, setState],
    )

    const nextStep = () => {
      history.push(location.pathname, {
        ...location.state,
        stage: 2,
      })
    }

    const prevStep = () => {
      history.goBack()
    }

    let canSave = Object.values(advisorParams).every((a) => Object.values(a).every((v) => !!v))

    if (currentUserSending) {
      canSave = canSave && Object.values(senderParams).every((v) => !!v)
    }

    /**
     * render member info section if there is at least one field for the advisor
     * that requires input
     */
    const hasAdvisorFields = advisorFields.length > 0

    return (
      <Container style={{ border: 'none', padding: '16px' }}>
        <div className="flex items-center">
          <CabalButton
            leftIcon={<i className="far fa-chevron-left" />}
            onClick={prevStep}
            size="small"
            variant="tertiary"
          />
          <Typography fontWeight={600} fontSize="16">
            Complete the following information
          </Typography>
        </div>
        {hasAdvisorFields && (
          <>
            <Typography fontWeight={600} fontSize="16" className="mb-base" component="h3">
              Member Info
            </Typography>
            {advisors.map((a) => {
              return (
                <AdvisorRow
                  advisor={a}
                  fields={advisorFields}
                  params={advisorParams[a.uuid]}
                  setParams={setAdvisorParams}
                  key={a.uuid}
                />
              )
            })}
          </>
        )}
        {currentUserSending && senderFields.length > 0 && (
          <>
            <Typography fontWeight={600} fontSize="16" className="my-4" component="h3">
              Sender Info
            </Typography>
            {senderFields.map((atf) => (
              <div className="mb-base" key={atf.uuid}>
                <Field field={atf} value={senderParams[atf.uuid]} setValue={setSenderParams} />
              </div>
            ))}
          </>
        )}
        <div className="flex justify-end mt-base">
          <CabalButton
            onClick={nextStep}
            disabled={!canSave}
            disabledMessage="All the fields are required"
            data-testid="team-user-agreement-continue-button"
          >
            Continue
          </CabalButton>
        </div>
      </Container>
    )
  },
  isEqual,
)

export default TeamUserFields
