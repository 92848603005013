import React, { useState } from 'react'
import api, { callApi } from 'utils/api'
import { useQuery } from 'react-query'
import { useSearchParam } from 'react-use'
import { useParams } from 'react-router-dom'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import Card from 'global/Card'
import { Container } from './styles'
import Avatar from 'global/Avatar'

const RequestSent: React.VFC = () => {
  const companyUuid = useSearchParam('company_uuid')
  const advisorUuid = useSearchParam('advisor_uuid')
  const teamSlug = useSearchParam('team_slug') || ''
  const { secret_uuid: secretUuid } = useParams<{ secret_uuid: string }>()
  const [introRequest, setIntroRequest] = useState<{
    accepted_at: string
    investor_company_logo: string | null
    investor_company_name: string
  }>({ accepted_at: '', investor_company_logo: null, investor_company_name: '' })

  const { isLoading } = useQuery(
    ['getIntroRequest', secretUuid],
    () => callApi(api.getIntroRequest, secretUuid, companyUuid || ''),
    {
      onSuccess: (data) => {
        setIntroRequest(data)
      },
      enabled: !!companyUuid,
    },
  )

  const { isLoading: isLoadingAdvisorIntroRequest } = useQuery(
    ['getAdvisorIntroRequest', secretUuid],
    () => callApi(api.getAdvisorIntroRequest, secretUuid, advisorUuid || '', teamSlug),
    {
      onSuccess: (data) => {
        setIntroRequest(data)
      },
      enabled: !!advisorUuid,
    },
  )

  const {
    accepted_at: acceptedAt,
    investor_company_logo: companyLogo,
    investor_company_name: companyName,
    advisor_avatar: advisorAvatar,
    advisor_name: advisorName,
    request_sent_to: requestSentTo,
    user,
    talent_lead: talentLead,
  } = introRequest

  if (isLoading || isLoadingAdvisorIntroRequest) return <Loading />

  return (
    <Container>
      <Card className="p-10">
        <Avatar name={user.name} src={user.avatar_url} size="40" round />
        <i className="fas fa-exchange mx-2"></i>
        {advisorName ? (
          <Avatar src={advisorAvatar} name={advisorName} size="40" round />
        ) : (
          <Avatar src={companyLogo} name={companyName} size="40" round />
        )}
        <div className="mt-5">
          <Typography fontSize="20" color="fog_rain" className="mb-5">
            <i className="fas fa-check-circle fa-fw"></i>
          </Typography>
          {acceptedAt ? (
            <>
              <Typography color="fog_rain" component="div">
                Your intro request was accepted!
              </Typography>
              <Typography color="fog_rain" component="div" fontSize="12" className="mt-1">
                Please check your email.
              </Typography>
            </>
          ) : (
            <Typography color="fog_rain" component="div">
              {`Request was successfully sent to ${advisorName ? talentLead : requestSentTo}!`}
            </Typography>
          )}
        </div>
      </Card>
    </Container>
  )
}

export default RequestSent
