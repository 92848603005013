import React, { useState } from 'react'
import api from 'utils/api'
import { AttachmentModel, MessageThread } from 'utils/types'
import styled from 'styled-components'
import AttachFile from 'components/SendMessage/AttachFile'
import Attachments from 'components/Attachments'
import TextEditor from 'global/TextEditor/ckeditor'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'

import G from '../../../images/google-icon.png'
import tw from 'twin.macro'
import { Divider } from 'components/SendMessage/styles'

interface Props {
  messageThread: MessageThread
  companySlug: string
  onSend: () => void
}

const SendMessageForm = styled.form`
  ${tw`mb-2`}
  ${tw`p-3 pt-1 rounded-lg`}

  background-color: ${({ theme }) => theme.colors.composer_bg};
  color: ${({ theme }) => theme.colors.primary};
`

const ReplyMessage: React.VFC<Props> = ({ onSend, companySlug, messageThread }) => {
  const [body, setBody] = useState('')
  const [attachments, setAttachments] = useState<AttachmentModel[]>([])
  const [uploading, setUploading] = useState(false)
  const { user } = useCurrentUser()

  return <></>

  const gmailAuthStatus = user.gmail_auth_statuses[messageThread.from]

  const threadUuid = messageThread.uuid

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    // error checking
    let validated = true
    if (body == '') validated = false

    if (validated) {
      setUploading(true)

      try {
        await api.createMessage(companySlug, {
          thread_uuid: threadUuid,
          body,
          attachments,
          send_email: true,
        })
        setBody('')
        setAttachments([])
        onSend()
      } catch (e) {
        console.error(e)
      }

      setUploading(false)
    }
  }

  const onUpload = ({ file, upload_uuid, url }: any) => {
    const attachment: AttachmentModel = {
      upload_uuid,
      url,
      file_name: file.name,
      file_size: file.size,
      file_type: file.type,
    }

    setAttachments([...attachments, attachment])
  }

  const onDelete = (attachment: AttachmentModel) => {
    setAttachments(attachments.filter((a) => a.upload_uuid != attachment.upload_uuid))
  }

  if (!gmailAuthStatus || !gmailAuthStatus.has_synced_gmail || gmailAuthStatus.force_gmail_reauth) {
    return (
      <>
        <Divider />
        <Typography component="div" fontSize="13" className="mt-2">
          <Typography fontWeight={600}>From:</Typography>
          {` `}
          {messageThread.from}
          {`, `}
          <Typography fontWeight={600}>To:</Typography>
          {` `}
          {messageThread.to.join(', ')}
        </Typography>
        <CabalButton
          variant="auth"
          leftIcon={<img src={G} width="18" />}
          onClick={() =>
            (document.location.href = `/auth/gmail?return_to=${window.location.pathname}`)
          }
        >
          Sign in with Google
        </CabalButton>
      </>
    )
  }

  return (
    <>
      <Divider />
      <SendMessageForm onSubmit={onSubmit}>
        <Typography component="div" fontSize="13" className="mt-2">
          <Typography fontWeight={600}>From:</Typography>
          {` `}
          {messageThread.from}
          {`, `}
          <Typography fontWeight={600}>To:</Typography>
          {` `}
          {messageThread.to.join(', ')}
        </Typography>

        <div className={`mb-2 mt-2`}>
          <TextEditor data-testid="reply-editor" value={body} onChange={setBody} />
        </div>

        {/* Attachments */}
        {attachments && attachments.length > 0 && (
          <div className="pt-2">
            <Attachments attachments={attachments} onDelete={onDelete} />
          </div>
        )}

        {/* Footer Section */}
        <div className="flex justify-between">
          <div className="float-left">
            <AttachFile maxFileSize={5} onUploadFinish={onUpload} isUploading={setUploading} />
          </div>
          <div className="flex items-center">
            <CabalButton disabled={uploading} data-testid="send-reply-button">
              Send
            </CabalButton>
          </div>
        </div>
      </SendMessageForm>
    </>
  )
}

export default ReplyMessage
