import React, { useEffect, useRef, useState } from 'react'

import compact from 'lodash/compact'
import concat from 'lodash/concat'
import { useMutation } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import RichTextInput, { RichTextInputRef } from 'global/Input/RichTextInput'
import { MentionSuggestion } from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'
import { useAdvisors, useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { CreateCommentRequest, StyleProps, UserBlueprint } from 'utils/types'

const InputContainer = styled.div`
  ${tw`overflow-y-scroll`};

  max-height: 150px;

  .ql-editor.ql-blank {
    height: auto;
  }
  .ql-mention-list {
    color: ${({ theme }) => theme.colors.primary};
  }

  .ql-mention-list-container {
    padding: 4px;
    background-color: transparent;
    border: none;
  }

  .ql-mention-list-item {
    border-radius: 4px;
    padding: 8px 6px;
  }

  .ql-mention-list-item.selected {
    background-color: #5c69d1;
    color: #fff;
  }
`

const InputStyle = styled.div`
  ${tw`mt-1 rounded overflow-auto`};

  background-color: transparent;

  .ck-editor__main,
  .ck-editor__main .ck-editor__editable {
    background: transparent !important;
  }
`

interface Props extends StyleProps {
  teamSlug: string
  createProps: Omit<CreateCommentRequest, 'team_slug' | 'body' | 'internal' | 'mention_ids'>
  refetchComments: () => Promise<any>
  newCommentInternal: boolean | undefined
  canMentionAdvisors?: boolean
  focus?: boolean
  placeholder?: string
  canToggleCommentVisibility?: boolean
}

const CreateComment: React.FC<Props> = ({
  createProps,
  teamSlug,
  refetchComments,
  newCommentInternal,
  canMentionAdvisors = true,
  placeholder = null,
  focus = false,
  canToggleCommentVisibility = true,
  ...restProps
}) => {
  const { team } = useTeam(teamSlug)
  const { canViewInternalComments } = useAccessControl(teamSlug)
  const { advisors } = useAdvisors({ teamSlug })

  const [newComment, setNewComment] = useState<{ body: string; internal: boolean }>({
    body: '',
    internal: false,
  })
  const richTextInputRef = useRef<RichTextInputRef>(null)

  const { mutateAsync: createComment, isLoading: creatingComments } = useMutation(
    (mentionIds: string[]) =>
      callApi(api.createComment, {
        team_slug: teamSlug,
        body: newComment.body || '',
        internal: newComment.internal,
        mention_ids: mentionIds,
        ...createProps,
      }),
  )

  useEffect(() => {
    if (canViewInternalComments && newCommentInternal !== undefined && !!newComment.body) {
      setNewComment({ ...newComment, internal: newCommentInternal })
    }
  }, [newCommentInternal])

  const mentionables = concat<Pick<UserBlueprint, 'name' | 'email' | 'avatar_url' | 'uuid'>>(
    ...compact([
      canMentionAdvisors &&
        advisors?.filter((advisor) => advisor.role === 'candidate' || !advisor.role),
      team?.admins_and_members,
    ]),
  ).map<MentionSuggestion>((f) => ({
    id: f.uuid,
    name: f.name,
    extra: f.email,
    avatar: f.avatar_url,
  }))

  const handleCreateComment = async (variables: string[]) => {
    await createComment(variables)
    await refetchComments()
    richTextInputRef.current?.clear()
  }

  return (
    <div {...restProps}>
      <InputStyle className="flex items-start">
        {canToggleCommentVisibility && canViewInternalComments && (
          <DropDownMenu
            trigger={
              <CabalButton
                variant="tertiary"
                disabled={!newComment.body}
                size="small"
                tooltip={newComment.internal ? 'Visible to Team' : 'Visible to Everyone'}
                leftIcon={
                  newComment.internal ? (
                    <i className="far fa-eye-slash" />
                  ) : (
                    <i className="far fa-eye" />
                  )
                }
                padding="14px 0px"
              ></CabalButton>
            }
            menuItems={[
              {
                label: (
                  <Typography>
                    <i className="far fa-eye fa-sm mr-1" />
                    Visible to Everyone
                  </Typography>
                ),
                onSelect: () => setNewComment({ ...newComment, internal: false }),
              },
              {
                label: (
                  <Typography>
                    <i className="far fa-eye-slash fa-sm mr-1" />
                    Visible to Team
                  </Typography>
                ),
                onSelect: () => setNewComment({ ...newComment, internal: true }),
              },
            ]}
          />
        )}

        <InputContainer className="flex-1">
          <RichTextInput
            windowKey="comment-input"
            ref={richTextInputRef}
            placeholder={placeholder || 'Add a comment'}
            onChange={(body) => setNewComment({ ...newComment, body })}
            suggestions={mentionables}
            focus={focus}
          />
        </InputContainer>
        <div className="mt-1">
          <CabalButton
            variant="link"
            onClick={() => {
              if (!richTextInputRef.current) return
              handleCreateComment(richTextInputRef.current.getMentions().map((m) => m.id as string))
            }}
            working={creatingComments}
            disabled={!newComment.body}
            size="small"
            padding="0"
          >
            Post
          </CabalButton>
        </div>
      </InputStyle>
    </div>
  )
}

export default CreateComment
