import React, { useEffect, useState } from 'react'

import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'

import first from 'lodash/first'
import groupBy from 'lodash/groupBy'
import isEqual from 'lodash/isEqual'
import toPairs from 'lodash/toPairs'
import uniq from 'lodash/uniq'
import memoizeOne from 'memoize-one'
import moment from 'moment'
import { transparentize } from 'polished'
import styled from 'styled-components'
import tw from 'twin.macro'

import OldLoading from 'containers/ListView/Companies/OldLoading'
import { CheckBox, Select, SelectOption, TextInput } from 'global/Input'
import CityInput from 'global/Input/CityInput'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import Icon from 'ui-components/Icon'

import useColorMode from 'utils/hooks/useColorMode'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'

import Popover from './Popover'

/**
 * boolean means toggle
 * dropdown means the filter that can have only one value at once eg. filter by one email id
 * dropdown[] means the filter can have multiple values at once eg. filter by multiple emails
 * string means the filter that can have only one string value at once eg. search by a single keyword
 * string[] means the filter that can have multiple string values at once eg. search by multiple keywords
 */
export type FilterType =
  | 'boolean'
  | 'dropdown'
  | 'dropdown[]'
  | 'string'
  | 'string[]'
  | 'search'
  | 'location[]'
  | 'daterange[]'
export type FilterValue = boolean | string[] | string | null

export interface FiltersValueType {
  [key: string]: FilterValue
}

export interface Filter {
  key: string
  type: FilterType
  label: React.ReactNode
  labelStr?: string
  /** pass if you set type to dropdown or dropwdown[] */
  options?: SelectOption<string>[]

  /** if you dont want the AppliedPill to add `<filter label>:` to the front of selected value, pass true here */
  skipLabelInAppliedPill?: boolean

  /** anything you want to render to the right end of the popover */
  popoverRight?: React.ReactNode

  /** for nesting a collection of filters under one pill */
  /** memoize this if it is not a simple value otherwise the collection can appear multiple times */
  collectionLabel?: React.ReactNode

  /** string version of the collection label  */
  collectionLabelStr?: string

  /** for dropdown filter types */
  loadingOptions?: boolean

  /** optional icon */
  icon?: React.ReactNode

  noPopover?: boolean

  optionHeight?: number
}

export interface AppliedFilter {
  label: string
  value: string | boolean
  filter: Filter
}

/**
 * props for `renderFilters method
 */
export interface FiltersProps<V extends FiltersValueType> {
  filters: Filter[]
  value: V
  onChange: (val: V) => void
  withPrefix?: boolean
}

export interface FilterProps {
  filter: Filter
  value: FilterValue
  onChange: (val: string | boolean) => void
  style: 'pill' | 'text'
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FiltersRef {
  // empty for now
}

const StyledCheckBox = styled(CheckBox)`
  color: ${({ theme }) => theme.colors.primary};
`

const StyledTextInput = styled(TextInput)`
  border: 1px solid ${({ theme }) => theme.colors.pill_border};
`

const FilterPill = styled.button`
  ${tw`px-3 py-2 rounded-full transition whitespace-nowrap`}

  font-size: 12px;
  color: ${({ theme }) => theme.colors.fog_rain};
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid ${({ theme }) => theme.colors.pill_border};

  &:hover,
  &:focus {
    border: 1px solid
      ${({ theme, disabled }) => (disabled ? theme.colors.pill_border : theme.colors.rain_fog)};
  }

  i {
    font-size: 12px;
  }
`

const AppliedFilterPill = styled.div`
  ${tw`px-2 py-1 rounded-full transition flex items-center whitespace-nowrap`}

  font-size: 12px;
  color: ${({ theme }) => theme.colors.purple};
  border: 1px solid ${({ theme }) => theme.colors.purple};
  background-color: ${({ theme }) => transparentize(0.9, theme.colors.purple)};

  & > button {
    ${tw`ml-1`}
  }
`

export const FilterWrapper = styled.div`
  ${tw`flex flex-col justify-center gap-2`}
  background-color: ${({ theme }) => theme.layout.nav_bg_color};
  top: 0px;
  z-index: 10;

  & > div {
    ${tw`flex flex-wrap gap-1`}
  }
`

const renderCollection = ({
  label,
  filters,
  values,
  handleOnChange,
}: {
  label: string
  filters: Filter[]
  values: FiltersValueType
  handleOnChange: (filter: Filter, v: string | boolean) => void
}) => {
  return (
    <Popover trigger={<FilterPill>{first(filters)?.collectionLabel}</FilterPill>}>
      <div className="flex gap-2 items-center">
        {filters?.map((filter: Filter) => {
          return (
            <Filter
              key={filter.key}
              filter={filter}
              value={values[filter.key]}
              onChange={(v) => handleOnChange(filter, v)}
              style={'text'}
            />
          )
        })}
      </div>
    </Popover>
  )
}

const Filter: React.FC<FilterProps> = ({ filter, value, onChange, style }) => {
  let trigger = <FilterPill>{filter.label}</FilterPill>
  const { theme } = useColorMode()
  let popoverContent: undefined | ((close: () => void) => React.ReactNode) = undefined
  const { popoverRight: popoverRightIcon } = filter
  // const debouncedOnChange = debounce((v: string) => {
  //   onChange(v)
  //   console.log(v)
  // }, 400)

  const [query, setQuery] = useState(value)
  const [dateRangeValue, setDateRangeValue] = useState(value)

  const [initialLoad, setInitialLoad] = useState(true)
  const debouncedQuery = useDebouncedValue(query, 400)

  useEffect(() => {
    if (!!value && filter.type === 'search') {
      setQuery(value as string)
    }
  }, [value])

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false)
      return
    }
    onChange(debouncedQuery)
  }, [debouncedQuery])

  if (
    filter.type.startsWith('dropdown') &&
    filter.options?.length === 0 &&
    !filter.loadingOptions
  ) {
    return <></>
  }

  if (filter.type === 'search') {
    return (
      <StyledTextInput
        value={query}
        autoFocus
        placeholder="Search"
        lightBg
        onChange={(e) => {
          // debouncedOnChange(e.target.value)
          // console.log(e.target.value)
          setQuery(e.target.value)
        }}
        label={<Icon icon={faSearch} className="w-3 h-3" />}
        labelPosition="left"
        labelRounded3xl={true}
        data-testid={'search-filter'}
      />
    )
  } else if (filter.type === 'string' || filter.type === 'string[]') {
    if (filter.noPopover) {
      popoverContent = (close = null) => (
        <StyledTextInput
          autoFocus
          placeholder="Search"
          onChange={(e) => {
            const searchTerm = e.target.value
            onChange(searchTerm)
          }}
        />
      )
    } else {
      popoverContent = (close) => (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            const searchTerm = (e.currentTarget.elements as any).search.value
            onChange(searchTerm)
            close()
          }}
        >
          <TextInput autoFocus placeholder="Search" />
        </form>
      )
    }
  }

  if (filter.type === 'dropdown' || filter.type === 'dropdown[]') {
    const options =
      (filter.type === 'dropdown'
        ? filter.options?.filter((o) => o.value !== value)
        : filter.options?.filter((o) => !(value as string[] | null)?.includes(o.value))) || []

    popoverContent = (close) => {
      return (
        <Select<string>
          autoFocus
          menuIsOpen={true}
          options={options}
          optionHeight={filter.optionHeight}
          onChange={(v) => {
            if (!v) return

            onChange(v)
            close()
          }}
          onBlur={close}
        />
      )
    }
  }

  if (filter.type === 'location[]') {
    popoverContent = (close) => (
      <div id="yo">
        <CityInput
          value={null}
          onChange={(opt) => {
            onChange(opt.value)
            close()
          }}
        />
      </div>
    )
  }

  if (filter.type === 'boolean') {
    if (style === 'text') {
      popoverContent = (close) => (
        <StyledCheckBox
          label={filter.label}
          checked={!!value}
          onChange={() => {
            onChange(!value)
            close()
          }}
        />
      )
    } else {
      trigger = <FilterPill onClick={() => onChange(!value)}>{filter.label}</FilterPill>
    }
  }

  if (style === 'text') {
    return <>{popoverContent?.(close)}</>
  }

  const popoverContentWithWrapper = (close: () => void) => {
    return (
      <div className="flex gap-2 items-center">
        {popoverContent?.(close)}
        {popoverRightIcon}
      </div>
    )
  }

  if (!popoverContent) return <>{trigger}</>

  if (filter.noPopover) {
    return popoverContent()
  }

  return (
    <Popover
      disabled={filter.loadingOptions}
      trigger={
        <FilterPill data-testid={`filter-pull-${filter.label}`} disabled={filter.loadingOptions}>
          <div className="flex">
            {filter.loadingOptions && <OldLoading size={14} className="mr-2" />}
            {typeof filter.icon === 'string' && <i className={filter.icon} />}
            {filter.icon}
            {filter.label}
          </div>
        </FilterPill>
      }
      containerClassName="z-10"
    >
      {({ closePopover }) => {
        return popoverContentWithWrapper?.(closePopover)
      }}
    </Popover>
  )
}

const filterToAppliedPillLabel = (filter: Filter, props?: { value?: string }) => {
  const value = props?.value
  const selectedOption = filter.options?.find((o) => o.value === value)
  let valueLabel = (selectedOption?.searchLabel ||
    selectedOption?.labelStr ||
    selectedOption?.label ||
    value) as string

  if (!filter.labelStr && typeof filter.label !== 'string') {
    console.error(filter)
    throw '`label` is not string, passing `labelStr` is required if `label` is not string'
  }

  const label = (filter.labelStr || filter.label) as string

  if (filter.type === 'daterange[]') {
    valueLabel = `${moment(value[0]).format('DD MMM YYYY')} - ${moment(value[1]).format(
      'DD MMM YYYY',
    )}`
  }

  if (!valueLabel) return label

  if (!filter.skipLabelInAppliedPill) return `${valueLabel}`

  return valueLabel
}

export const renderFilters = memoizeOne(
  <V extends FiltersValueType>({
    filters,
    value,
    onChange,
    withPrefix = false,
  }: FiltersProps<V>) => {
    const appliedFilters: AppliedFilter[] = []
    const availableFilters: Filter[] = []
    const normalFilters = filters.filter((f) => !f.collectionLabel)
    const nestedFilters = groupBy(
      filters.filter((f) => !!f.collectionLabel),
      (f) => f.collectionLabelStr || f.collectionLabel,
    )

    filters.forEach((filter) => {
      if (filter.type === 'boolean') {
        const val = value[filter.key]
        if (val) {
          appliedFilters.push({
            label: filterToAppliedPillLabel(filter),
            value: true,
            filter,
          })
        } else {
          availableFilters.push(filter)
        }
      }

      if (filter.type === 'dropdown' || filter.type === 'string') {
        const val = value[filter.key] as string | null
        if (!!val?.length) {
          appliedFilters.push({
            label: filterToAppliedPillLabel(filter, { value: val }),
            value: val,
            filter,
          })
        } else {
          availableFilters.push(filter)
        }
      }

      if (
        filter.type === 'dropdown[]' ||
        filter.type === 'string[]' ||
        filter.type === 'location[]'
      ) {
        const val = value[filter.key] as string[] | null
        if (!!val?.length) {
          val.forEach((v) => {
            appliedFilters.push({
              label: filterToAppliedPillLabel(filter, { value: v }),
              value: v,
              filter,
            })
          })
        }
        availableFilters.push(filter)
      }

      if (filter.type === 'daterange[]') {
        const val = value[filter.key] as [] | null
        if (!!val?.length) {
          appliedFilters.push({
            label: filterToAppliedPillLabel(filter, { value: val }),
            value: val,
            filter,
          })
        }
        availableFilters.push(filter)
      }
    })

    const handleOnChange = (filter: Filter, v: string | boolean) => {
      if (filter.type === 'boolean') {
        onChange({
          ...value,
          [filter.key]: v,
        })
      } else {
        if (
          filter.type === 'dropdown[]' ||
          filter.type === 'string[]' ||
          filter.type === 'location[]'
        ) {
          onChange({
            ...value,
            [filter.key]: uniq([...((value[filter.key] as string[] | null) || []), v as string]),
          })
        } else if (
          filter.type === 'dropdown' ||
          filter.type === 'string' ||
          filter.type === 'search'
        ) {
          onChange({
            ...value,
            [filter.key]: v,
          })
        } else if (filter.type === 'daterange[]') {
          onChange({
            ...value,
            [filter.key]: v,
          })
        }
      }
    }

    const removeFilter = (appliedFilter: AppliedFilter) => {
      const filter = appliedFilter.filter
      if (
        filter.type === 'boolean' ||
        filter.type === 'dropdown' ||
        filter.type === 'string' ||
        filter.type === 'daterange[]'
      ) {
        onChange({
          ...value,
          [filter.key]: null,
        })
      }

      if (
        filter.type === 'dropdown[]' ||
        filter.type === 'string[]' ||
        filter.type === 'location[]'
      ) {
        const newValue = (value[filter.key] as string[] | null)?.filter(
          (v) => v !== appliedFilter.value,
        )
        onChange({
          ...value,
          [filter.key]: newValue || null,
        })
      }
    }

    const renderedFilters = normalFilters
      .map((filter) => (
        <Filter
          filter={filter}
          value={value[filter.key]}
          key={filter.key}
          onChange={(v) => handleOnChange(filter, v)}
          style={'pill'}
        />
      ))
      .concat(
        toPairs(nestedFilters).map(([label, filters]) => {
          return renderCollection({
            label,
            filters,
            values: value,
            handleOnChange,
          })
        }),
      )

    return [
      renderedFilters,
      appliedFilters.map((appliedFilter) => {
        if (appliedFilter.filter.noPopover && appliedFilter.filter.type !== 'daterange[]') {
          return null
        } else {
          return (
            <AppliedFilterPill key={`${appliedFilter.filter.key}-${appliedFilter.value}`}>
              {typeof appliedFilter.label === 'string' && withPrefix
                ? `${appliedFilter.filter.label}:${appliedFilter.label}`
                : appliedFilter.label}
              <Typography
                fontSize="11"
                component="button"
                onClick={() => removeFilter(appliedFilter)}
              >
                <i className="far fa-times" />
              </Typography>
            </AppliedFilterPill>
          )
        }
      }),
    ]
  },
  isEqual,
)

/**
 * renders the available and applied filters together
 * if you want them separately, for example if you want to show available filters somewher
 * and the applied filters somewhere else, use the `renderFilters` method from this file.
 *
 * This component is a wrapper around that for familiarity.
 */
const Filters: React.FC<FiltersProps> = (props) => {
  const [renderedFilters, appliedFilters] = renderFilters(props)

  return (
    <>
      {renderedFilters}
      {appliedFilters}
    </>
  )
}

export default Filters
