import axios from 'axios'

export default {
  destroyGoogleAuth: (companySlug: string) => {
    return axios.delete('/auth/google', {
      params: {
        team_slug: companySlug,
      },
    })
  },
}
