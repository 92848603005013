import React, { ReactNode } from 'react'

import Carousel from 'react-multi-carousel'
import { ButtonGroupProps } from 'react-multi-carousel/lib/types'
import styled from 'styled-components'

import GTM from '../UpdatedLogos/gtm.svg'

import 'react-multi-carousel/lib/styles.css'

export const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 3,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
}

const ItemStyle = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
`

export const ButtonGroup = ({ next, previous, carouselState }: ButtonGroupProps) => {
  return (
    <div className="carousel-button-group absolute right-0 flex gap-4 mt-6">
      <button
        className={carouselState?.currentSlide === 0 ? 'disable' : ''}
        onClick={() => previous?.()}
      >
        <p className="text-3xl text-[#718096] hover:text-white">
          <i className="far fa-chevron-left" />
        </p>
      </button>
      <button onClick={() => next?.()}>
        <p className="text-3xl text-[#718096] hover:text-white">
          <i className="far fa-chevron-right" />
        </p>
      </button>
    </div>
  )
}

export const Item = ({
  title,
  description,
  backgroundColor,
  link,
}: {
  title: ReactNode
  description: ReactNode
  backgroundColor: string
  link: string
}) => {
  return (
    <ItemStyle
      className={` flex flex-col justify-between px-8 py-6 mr-4 h-[225px] rounded-lg cursor-pointer hover:opacity-80 transition-all duration-100`}
      backgroundColor={backgroundColor}
      onClick={() => window.open(link, '_blank')}
    >
      <h3 className="text-white text-2-xl">{title}</h3>
      <p className="text-white text-2xl font-semibold">{description}</p>
    </ItemStyle>
  )
}

const ResourcesCarousel = () => {
  return (
    <div className="pb-40 relative">
      <Carousel
        responsive={responsive}
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
        partialVisible
      >
        <Item
          title={<img src={GTM} className="h-[30px]" />}
          description={<p className=" uppercase">Warm intro navigator</p>}
          backgroundColor="#10845B"
          link="https://win.getcabal.com/"
        />
        <Item
          title={<i className="fal fa-list text-3xl" />}
          description="Referral List"
          backgroundColor="#8A97FF"
          link="/resources"
        />
        <Item
          title={<i className="fal fa-envelope text-3xl" />}
          description="Email Templates"
          backgroundColor="#5C69D1"
          link="/resources"
        />
        <Item
          title={<i className="fal fa-newspaper text-3xl" />}
          description="Blog"
          backgroundColor="#2F3CA7"
          link="/blog"
        />
      </Carousel>
    </div>
  )
}

export default ResourcesCarousel
