import axios from 'axios'

export default {
  getContributionsCount: () => {
    return axios.get<{ contributions_count: number }>(`/api/contributions`)
  },

  getAdvisorContributionsCount: (team_slug: string, uuid: string) => {
    return axios.get<{ contributions_count: number }>(`/api/contributions/advisor_contributions`, {
      params: {
        team_slug,
        uuid,
      },
    })
  },
}
