import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

interface Props {
  icon?: React.ReactNode
  title: string
  description: string
}
const HeaderSection: React.VFC<Props> = ({ icon, title, description }) => {
  return (
    <div className="p-8 flex flex-col items-center">
      {icon && (
        <Typography className="pb-4" fontSize="24" color={'rain'}>
          {icon}
        </Typography>
      )}

      <Typography fontWeight={600} fontSize="16" className="pb-1">
        {title}
      </Typography>
      <Typography fontSize="14" color={'fog'} textAlign="center">
        {description}
      </Typography>
    </div>
  )
}

export default HeaderSection
