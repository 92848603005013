import React from 'react'

import { H1, Subtitle } from 'containers/SignupScreen/NewSite/styles'
import { ResourceCategory } from 'containers/SignupScreen/ResourcesPage'
import defaultLists from 'containers/SignupScreen/ResourcesPage/defaultLists'
import BuyerPersonasIllustration from 'containers/SignupScreen/ResourcesPage/images/ill-buyer-personas.svg'
import PotentialInvestorsIllustration from 'containers/SignupScreen/ResourcesPage/images/ill-potential-investors.svg'
import SalesProspectsIllustration from 'containers/SignupScreen/ResourcesPage/images/ill-sales-prospects.svg'
import Typography from 'global/Typography'

export default function Explore() {
  return (
    <div className="pl-2">
      <div>
        <div className="flex flex-col items-start pb-6 pt-8">
          <Typography fontSize="28" fontWeight={600} component="h1">
            Explore
          </Typography>
          <Typography className="max-w-2xl" color="fog">
            Free community resources to help you sell, recruit, and fundraise
          </Typography>
        </div>
      </div>
      <div className="flex flex-col items-stretch gap-y-4">
        <div className="w-full hover:shadow-md">
          <ResourceCategory
            title="Sales prospects"
            description=""
            category={defaultLists.lists.sales_prospects}
            categoryName="sales_prospects"
          />
        </div>

        <div className="w-full hover:shadow-md">
          <ResourceCategory
            title="Potential investors"
            description=""
            category={defaultLists.lists.potential_investors}
            categoryName="potential_investors"
          />
        </div>

        <div className="w-full hover:shadow-md">
          <ResourceCategory
            title="Buyer personas"
            description=""
            suffix="Personas"
            category={defaultLists.lists.buyer_personas}
            categoryName="buyer_personas"
          />
        </div>
      </div>
    </div>
  )
}
