import React, { useEffect, useMemo, useRef } from 'react'

import axios from 'axios'
import { sanitize } from 'dompurify'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { comment } from 'postcss'
import { useMutation } from 'react-query'
import { useHash } from 'react-use'
import scrollIntoView from 'scroll-into-view'
import styled from 'styled-components'
import tw from 'twin.macro'

import TimeAgo from 'components/TimeAgo'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import { DropdownMenuItem } from 'global/DropDownMenu'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useAdvisors, useTeam } from 'store/hooks'
import { DataCard } from 'ui-components/DataCard'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { Comment as CommentType } from 'utils/types'

const CommentContainer = styled.div<{ isHighlighted: boolean; internal: boolean }>`
  ${tw`flex p-1.5 items-start rounded gap-1.5`}

  background-color: ${({ theme, internal }) =>
    internal ? theme.colors.light_yellow : theme.layout.main_bg_color};
`

interface Props {
  comment: CommentType
  teamSlug: string
  refetchComments: () => void
}

let menuItems: DropdownMenuItem[] = []

const Comment: React.VFC<Props> = React.memo(({ comment, teamSlug, refetchComments }) => {
  const { canViewInternalComments } = useAccessControl(teamSlug)
  const [hash] = useHash()
  const commentContainerRef = useRef<HTMLDivElement>(null)

  const isHighlighted = hash === `#comment-${comment.uuid}`

  useEffect(() => {
    isHighlighted && commentContainerRef.current && scrollIntoView(commentContainerRef.current)
  }, [isHighlighted])

  const { mutate: deleteComment } = useMutation(() => api.deleteComment({ uuid: comment.uuid }), {
    onSuccess: () => {
      refetchComments()
      cabalToast({ style: 'success', content: 'Comment has been deleted' })
    },
  })

  menuItems = [
    {
      label: 'Delete',
      onSelect: () => deleteComment(),
    },
  ]

  if (!canViewInternalComments && comment.internal) {
    return <></>
  }

  return (
    <DataCard
      dataCardTestId={`comment-${comment.uuid}`}
      className="h-auto min-h-[50px] py-2"
      title={
        <>
          <Typography fontSize="12">
            <Typography fontWeight={600}>{comment.user.name}</Typography>{' '}
            <Typography color="fog_rain">
              <TimeAgo datetime={comment.created_at} short />
              {comment.internal && (
                <Tooltip label="Only team members can see this comment">
                  <i className="ml-2 fas fa-eye-slash" />
                </Tooltip>
              )}
            </Typography>
          </Typography>
        </>
      }
      avatarUrl={comment.user.avatar_url}
      avatarName={comment.user.name}
      avatarAlign={'top'}
      description={[
        <Typography
          key={`comment-${comment.uuid}-body`}
          color="primary"
          component="div"
          className="ql-output"
          dangerouslySetInnerHTML={{ __html: sanitize(comment.body) }}
        />,
      ]}
      menuItems={menuItems}
    />
  )
}, isEqual)

export default Comment
