import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import { CompanyBlurbSection } from 'components/Winsite/CompanyBlurb/CompanyBlurbSection'
import { FaSpinner } from 'components/Winsite/FaSpinner'
import { MaterialDivSection } from 'components/Winsite/MaterialDiv/MaterialDivSection'
import { useFetchLists } from 'components/Winsite/queries/FetchLists'
import { ITeamData } from 'components/Winsite/types/ITeamData'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import copyToClipboard from 'utils/copyToClipboard'
import useColorMode from 'utils/hooks/useColorMode'
import { signInUrl } from 'utils/url'

import { AccountsProfilesPage } from './AccountsProfiles/AccountsProfilesPage'
import { Layout } from './Layout'
import { Spinner } from './Spinner'
import { useAuthenticateUser } from './queries/AuthenticateUser'
import { useCompanyPage } from './queries/CompanyPage'

interface IProps {
  teamData?: ITeamData
  teamSlug?: string | undefined
  companyName?: string | undefined
  linkedinUrl?: string | undefined
  spinnerDisplayProp?: boolean | undefined
  companyDomain?: string | undefined
}

export function CompanyLandingPage({
  teamData,
  teamSlug,
  companyName,
  linkedinUrl,
  spinnerDisplayProp,
  companyDomain,
}: IProps) {
  const { isMobile } = useColorMode()
  const [isSpinner, setIsSpinner] = useState<boolean>(true)
  const [lists, setLists] = useState<any>(null)
  const [teamInfo, setTeamInfo] = useState(teamData)
  const [urlWithSlug, setUrlWithSlug] = useState<boolean>(false)
  const { data: authResponse, isLoading, isError } = useAuthenticateUser(teamInfo?.company_domain)
  const targetAccountsHeadline = 'Target Accounts'
  const buyerProfilesHeadline = 'Buyer Profiles'
  const winUrl = window.location.host + window.location.pathname
  const spinnerDisplay = useSearchParam('spinnerDisplay') || spinnerDisplayProp
  const { company_slug: companySlug } = useParams<any>()
  const { refetch: listsRefetch } = useFetchLists(teamInfo?.team_slug)

  const { refetch } = useCompanyPage(
    {
      companyName: companyName,
      linkedinUrl: teamInfo?.linkedin_url || linkedinUrl,
      companySlug: teamInfo?.team_slug || companySlug || teamSlug,
      companyDomain: companyDomain,
    },
    {
      enabled: false,
      retry: 3,
    },
  )

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined
    if (isSpinner) {
      timer = setTimeout(() => {
        setIsSpinner(false)
      }, 5000)
    }
    if (!teamInfo) {
      refetch().then((teamDataFetched) => {
        setTeamInfo(teamDataFetched.data.data)
      })
    }
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamInfo])
  if (spinnerDisplay && isSpinner) {
    return <Spinner />
  }
  if (spinnerDisplay && !spinnerDisplayProp) {
    const urlWithoutParams = window.location.href.split('?')[0]
    window.history.replaceState({}, document.title, urlWithoutParams)
  }

  if (!spinnerDisplay && !teamInfo) {
    return <FaSpinner />
  }
  if (spinnerDisplayProp && !urlWithSlug) {
    window.history.replaceState({}, document.title, `/${teamInfo!.team_slug}`)
    setUrlWithSlug(true)
  }
  if (teamInfo!.team_slug && !lists) {
    listsRefetch().then((data) => {
      const { data: listsData } = data
      setLists(listsData)
    })
  }
  if (!lists) {
    return <FaSpinner />
  }
  const url = signInUrl(teamInfo?.team_slug)

  return (
    <Layout>
      <div className="flex flex-col justify-center items-center gap-5">
        <div className="flex flex-row gap-2.5 mt-8">
          <Typography
            textAlign="center"
            fontSize={isMobile ? '18' : '20'}
            fontWeight={400}
            color="white"
          >
            {winUrl}
            <i
              className="fa-regular fa-copy cursor-pointer"
              style={{ color: '#03C168' }}
              onClick={() => {
                cabalToast({ style: 'success', content: 'Link copied to clipboard' })
                copyToClipboard(winUrl)
              }}
            ></i>
          </Typography>
        </div>
        {(isLoading || isError || !authResponse?.data.authenticated) && (
          <CabalButton
            onClick={() => (window.location.href = url)}
            style={{ backgroundColor: '#03C168', padding: '10px' }}
            size={isMobile ? 'tiny' : 'small'}
          >
            Sign in to claim this page
          </CabalButton>
        )}
      </div>
      <div className="grid grid-cols-1 px-4 sm:px-2 sm:grid-cols-2 max-w-[990px] mx-auto gap-y-9 gap-x-4 sm:gap-x-8 my-[60px]">
        <div style={{ height: 'max-content' }}>
          <CompanyBlurbSection teamData={teamInfo} />
        </div>
        <div style={{ height: 'max-content' }}>
          <MaterialDivSection teamData={teamInfo} />
        </div>
        <div style={{ height: 'max-content' }}>
          <AccountsProfilesPage
            teamData={teamInfo}
            headline={targetAccountsHeadline}
            list={lists.data.company_list}
            listType={'Company'}
          />
        </div>
        <div style={{ height: 'max-content' }}>
          <AccountsProfilesPage
            teamData={teamInfo}
            headline={buyerProfilesHeadline}
            list={lists.data.persona_list}
            listType={'GlobalPerson'}
          />
        </div>
      </div>
    </Layout>
  )
}
