import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import concat from 'lodash/concat'
import debounce from 'lodash/debounce'
import remove from 'lodash/remove'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import objectHash from 'object-hash'
import { useMutation, useQuery } from 'react-query'
import HeadShake from 'react-reveal/HeadShake'
import { useHistory } from 'react-router-dom'
import { useDebounce, useLocalStorage } from 'react-use'
import styled from 'styled-components'
import { useDeepCompareMemo } from 'use-deep-compare'

import AdvisorInviteModal from 'components/AdvisorInvite'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import { MultiSelect, TextInput, TextInputProps } from 'global/Input'
import Modal, { useModal } from 'global/Modal'
import Modal2 from 'global/Modal'
import Table, { Column, Row, TableRef, TrProps } from 'global/Table'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useAdvisors, useGroups } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import { GroupModel, ImportedMember } from 'utils/types'

const TableRow = styled.div<TrProps>`
  padding-left: 13px;
`

const MemoizedInput = React.memo(
  (props: {
    i: number
    members: ImportedMember[]
    setProp: (i: number, k: keyof ImportedMember, v: any) => void
    k: keyof ImportedMember
    minWidth?: string
    valueType?: TextInputProps['valueType']
  }) => {
    const update = (v: any) => {
      if (v !== props.members[props.i][props.k]) props.setProp(props.i, props.k, v)
    }

    return (
      <TextInput
        data-testid={`member-${props.k}-${props.i}`}
        className="w-full"
        defaultValue={props.members[props.i][props.k]}
        onChange={debounce((e) => update(e.target.value), 500)}
        onBlur={(e) => update(e.target.value)}
        valueType={props.valueType}
      />
    )
  },
  () => {
    return true
  },
)

MemoizedInput.displayName = 'MemoizedInput'

interface Props {
  advisorImportUuid: string
  members: ImportedMember[]
  groups: GroupModel[]
  importedColumns: string[]
  companySlug: string
  destination?: string
  previewInModal?: boolean
  teamSlug: string
  invitePostImport: boolean
  onHide?: () => void
}

const Preview: React.FC<Props> = ({
  advisorImportUuid,
  members: _members,
  groups,
  companySlug,
  destination,
  importedColumns,
  teamSlug,
  invitePostImport,
  previewInModal = false,
  onHide,
}) => {
  const history = useHistory()
  const [, , deleteImportEmailsCache] = useLocalStorage('import_emails_cache')
  const { canViewGroups } = useAccessControl(teamSlug)
  const { showModal } = useModal()

  const tableRef = useRef<TableRef>(null)
  const { reloadAdvisors } = useAdvisors({
    teamSlug,
  })
  const { reloadGroups } = useGroups(teamSlug)

  const [members, setMembers] = useState(_members)
  const [showAddToGroupModal, setShowAddToGroupModal] = useState(false)
  const [bulkGroups, setBulkGroups] = useState<string[]>()
  const [polling, togglePolling] = useState(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const debouncedMembers = useDebouncedValue(objectHash(members), 1000)

  const setMembersRef = useRef(setMembers)

  useEffect(() => {
    if (previewInModal) {
      setMembers(_members.map((member) => ({ ...member, groups: [groups[0].name] })))
    }
  }, [])

  const { mutate: createMembers, isLoading: creatingMembers } = useMutation(
    ['importAdvisors', teamSlug],
    () =>
      callApi(api.importAdvisors, advisorImportUuid, {
        team_slug: teamSlug,
        members,
      }),
    {
      onSuccess: () => {
        togglePolling(true)
      },
    },
  )

  useQuery(['updateAdvisorImport', teamSlug, debouncedMembers], () =>
    callApi(api.updateAdvisorImport, advisorImportUuid, {
      team_slug: teamSlug,
      members,
    }),
  )

  useQuery(
    ['advisorImportStatus', teamSlug, advisorImportUuid],
    () => callApi(api.advisorImportStatus, advisorImportUuid, teamSlug),
    {
      onSuccess: async ({ status, advisors }) => {
        if (status === 'completed') {
          await reloadAdvisors(advisors.map((a) => a.uuid))
          await reloadGroups()

          cabalToast({
            style: 'success',
            content: `Successfully added ${advisors.length} members!`,
          })

          deleteImportEmailsCache()

          if (previewInModal) {
            onHide && onHide()
          } else {
            if (advisors.length === 1) {
              history.replace(`/${teamSlug}/people/${advisors[0].uuid}`)
            } else {
              history.replace(destination || `/${companySlug}/people`)
            }

            invitePostImport &&
              showModal(
                (resolve) => (
                  <AdvisorInviteModal
                    onHide={resolve}
                    teamSlug={teamSlug}
                    advisors={advisors}
                    showSkip
                  />
                ),
                'advisor-invite-modal',
              )
          }

          togglePolling(false)
        }
      },
      enabled: polling,
      refetchInterval: 3000,
    },
  )

  useEffect(() => {
    setMembersRef.current = setMembers
  }, [setMembers])

  const showBadDataModal = () => {
    showModal(
      (resolve) => (
        <Modal2
          onHide={resolve}
          show
          header="Please complete the following data"
          rightActions={<CabalButton onClick={() => resolve()}>Close</CabalButton>}
        >
          <Typography fontSize="13" component="h3" className="mb-3">
            First names are required to import members Please add first names for these members:
          </Typography>
          {members
            .filter((m) => !m.first_name)
            .map((m) => {
              return (
                <Typography key={m.email} fontSize="12" component="h4">
                  {m.email}
                </Typography>
              )
            })}
        </Modal2>
      ),
      'bad-import-data',
    )
  }

  const setMemberProp = useCallback((i: number, key: keyof ImportedMember, value: any) => {
    setMembersRef.current((prev: ImportedMember[]) => {
      const updated = [...prev]
      updated[i][key] = value
      return updated
    })
  }, [])

  const removeSelectedRows = () => {
    const updatedMembers = [...members]
    for (const id of selectedRows) {
      remove(updatedMembers, (r) => r.email === id)
    }
    setSelectedRows([])
    tableRef.current?.clearSelection()
    setMembers(updatedMembers)
  }

  const bulkAddToGroup = () => {
    if (!bulkGroups) return

    const updatedMembers = [...members]
    for (const id of selectedRows) {
      const i = updatedMembers.findIndex((m) => m.email === id)
      updatedMembers[i].groups = uniq(concat(updatedMembers[i].groups || [], bulkGroups))
    }

    setMembers(updatedMembers)
    setShowAddToGroupModal(false)
  }

  const canSubmit = members.every((m) => !!m.first_name && !!m.email)

  const submitButton = (
    <CabalButton
      variant="primary"
      onClick={() => (canSubmit ? createMembers() : showBadDataModal())}
      working={polling || creatingMembers}
      data-testid="submit-bulk-import"
    >
      Save
    </CabalButton>
  )

  const allGroups = uniqBy(
    members
      .reduce<string[]>(
        (prev, curr) => (curr.groups ? concat(prev, curr.groups) : prev),
        groups.map((g) => g.name),
      )
      .filter((g) => g !== 'All')
      .map((g) => ({
        label: g,
        value: g,
      })),
    (e) => e.value,
  )

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'First Name*',
          accessor: 'first_name',
          disableSortBy: true,
        },
        {
          Header: 'Last Name',
          accessor: 'last_name',
          disableSortBy: true,
        },
        {
          Header: 'Linkedin Url',
          accessor: 'linkedin_url',
          disableSortBy: true,
        },
        canViewGroups && !previewInModal
          ? {
              Header: 'Groups',
              accessor: 'groups',
              disableSortBy: true,
              minWidth: 300,
            }
          : undefined,
        {
          Header: 'Company Name',
          accessor: 'company_name',
          disableSortBy: true,
        },
        {
          Header: 'Title',
          accessor: 'title',
          disableSortBy: true,
        },
      ].filter(
        (h) =>
          !!h &&
          (['company_name', 'linkedin_url', 'title'].includes(h.accessor)
            ? importedColumns.includes(h.accessor)
            : true),
      ) as Column<Row>[],
    [importedColumns],
  )

  const data: Row<ImportedMember>[] = useDeepCompareMemo(() => {
    return members.map((m, i) => {
      const baseProps = {
        i,
        members,
        setMembers,
        setProp: setMemberProp,
      }
      const datum: Row<ImportedMember> = {
        id: m.email,
        first_name: <MemoizedInput k="first_name" {...baseProps} valueType="non-empty" />,
        last_name: <MemoizedInput k="last_name" {...baseProps} />,
        linkedin_url: <MemoizedInput k="linkedin_url" minWidth="300px" {...baseProps} />,
        email: m.email,
        groups:
          canViewGroups && !previewInModal ? (
            <MultiSelect
              data-testid="import-group-assign"
              creatable
              portal
              value={m.groups}
              onChange={(gs) => {
                setMemberProp(i, 'groups', gs)
              }}
              onCreateOption={(v) => {
                setMemberProp(i, 'groups', [...(m.groups || []), v])
              }}
              options={allGroups}
              placeholder={' '}
            />
          ) : null,
        company_name: <MemoizedInput k="company_name" {...baseProps} />,
        title: <MemoizedInput k="title" {...baseProps} />,
        data: m,
      }

      return datum
    })
  }, [members.map((m) => m.groups)])

  const bulkActionsEnabled = Object.values(selectedRows).filter((i) => !!i).length > 0

  return (
    <>
      {previewInModal && (
        <>
          <div>
            <Typography fontSize="14" fontWeight={600}>
              Confirm member details
            </Typography>
            <div className="w-full overflow-x-auto mt-4">
              <Table
                ref={tableRef}
                columns={columns}
                data={data}
                virtualize
                tableHeight={500}
                rowHeight={40}
                overscanCount={5}
                tr={TableRow}
              />
            </div>
            <div className="flex justify-end items-center mb-4">
              <div className="">{submitButton}</div>
            </div>
          </div>
        </>
      )}
      {!previewInModal && (
        <>
          <div>
            <div className="flex justify-between">
              <div className="flex align-middle">
                <div>
                  <CabalButton variant="tertiary" onClick={() => history.goBack()}>
                    <i className="far fa-arrow-left" />
                  </CabalButton>
                </div>
                <Typography fontSize="18" fontWeight={600} className="ml-3">
                  Add members
                </Typography>
              </div>
              <div className="flex items-center">
                <div className="mr-2 inline-block">
                  <DropDownMenu
                    disabled={!bulkActionsEnabled}
                    trigger={
                      <HeadShake when={bulkActionsEnabled}>
                        <CabalButton
                          component="div"
                          disabled={!bulkActionsEnabled}
                          rightIcon={<i className="fas fa-caret-down" />}
                        >
                          Bulk Actions
                        </CabalButton>
                      </HeadShake>
                    }
                    menuItems={
                      !bulkActionsEnabled
                        ? []
                        : [
                            {
                              label: <>Remove Selected</>,
                              onSelect: removeSelectedRows,
                            },
                            canViewGroups
                              ? {
                                  label: <>Add to group</>,
                                  onSelect: () => setShowAddToGroupModal(true),
                                }
                              : null,
                          ]
                    }
                  />
                </div>
                {submitButton}
              </div>
            </div>
          </div>

          <div className="w-full overflow-x-auto mt-4">
            <Table
              ref={tableRef}
              selectable
              selectedRows={selectedRows}
              onSelectedRowsChange={setSelectedRows}
              columns={columns}
              data={data}
              virtualize
              tableHeight={window.innerHeight - 250}
              rowHeight={40}
              overscanCount={5}
              tr={TableRow}
            />
          </div>
          <div className="flex justify-end items-center mt-4">
            <div className="">{submitButton}</div>
          </div>
          <Modal
            show={showAddToGroupModal}
            onHide={() => setShowAddToGroupModal(false)}
            header="Add to Group"
            rightActions={
              <CabalButton variant="primary" onClick={bulkAddToGroup}>
                Add
              </CabalButton>
            }
          >
            <MultiSelect
              creatable
              portal
              onChange={(gs) => {
                setBulkGroups(gs?.map((g) => g))
              }}
              options={allGroups}
              placeholder={'Select Groups'}
            />
          </Modal>
        </>
      )}
    </>
  )
}

export default Preview
