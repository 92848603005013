import React from 'react'

import moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'

import CareerInfo from 'containers/CandidateProfileModal/CareerInfo'
import CandidatePersonalInfo from 'containers/CandidateProfileModal/PersonalInfo'
import WorkAndEducationHistory from 'containers/CandidateProfileModal/WorkHistory'
import LinkedInAutoPrefill, {
  getLinkedInSlug,
} from 'containers/CandidateProfileModal/linkedInAutoPrefill'
import Loading from 'global/Loading'
import Modal from 'global/Modal'
import { ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

const TabBarWrapper = styled.div`
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  padding-left: 32px;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`

const TabWrapper = styled.div<{ active?: boolean }>`
  border-bottom-color: ${(props) => (props.active ? props.theme.colors.purple : 'transparent')};
  cursor: default;
`

export const Footer = styled.div`
  border-top: solid 1px ${(props) => props.theme.colors.gray200};
`

function TabBar(props: { children: React.ReactNode }) {
  return <TabBarWrapper className="flex gap-4 justify-center">{props.children}</TabBarWrapper>
}

function Tab(props: { active?: boolean; children: React.ReactNode; onClick: () => void }) {
  return (
    <TabWrapper
      active={props.active}
      className="border-b-2 px-2 py-3 cursor-pointer"
      onClick={props.onClick}
    >
      <Typography fontSize="14" color={props.active ? 'purple' : 'fog'}>
        {props.children}
      </Typography>
    </TabWrapper>
  )
}

export interface CandidateProfileInputWorkHistoryEntry {
  key?: number
  uuid?: string
  company: string
  title: string
  fromMonth: number | null
  fromYear: number | null
  toMonth: number | null
  toYear: number | null
  summary: string
}

export interface CandidateProfileInputEducationHistoryEntry {
  key?: number
  uuid?: string
  school: string
  fieldOfStudy: string
  fromYear: number | null
  fromMonth: number | null
  toYear: number | null
  toMonth: number | null
  summary: string
}

export interface CandidateProfileModalInput {
  idealCompanySize: string[] | undefined
  firstName: string
  lastName: string
  email: string
  headline: string
  company: string
  title: string
  linkedInUrl: string
  location: string
  functions: string[]
  skills: string[]
  bio: string
  avatar?: string | undefined
  level: string
  ownerUuid: string
  fundingStageExperience: string[]
  stagePreference: string[]
  functionPreference: string[]
  opportunityType: string[]
  companyListUuids: string[]
  minimumSalary: string
  whatYoureLookingFor: string
  levelPreference: string[]
  officePreference: string[]
  workStatus: string
  preferences: {
    isProfilePublic: boolean
  }
  workHistory: CandidateProfileInputWorkHistoryEntry[]
  educationHistory: CandidateProfileInputEducationHistoryEntry[]
  documents: File[]
}

export const initialCandidateProfile: CandidateProfileModalInput = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  headline: '',
  title: '',
  linkedInUrl: '',
  location: '',
  functions: [] as string[],
  skills: [] as string[],
  bio: '',
  opportunityType: [] as string[],
  companyListUuids: [] as string[],
  avatar: undefined,
  level: '',
  ownerUuid: '',
  fundingStageExperience: [] as string[],
  stagePreference: [] as string[],
  levelPreference: [] as string[],
  functionPreference: [] as string[],
  officePreference: [] as string[],
  idealCompanySize: [] as string[],
  workStatus: '',
  preferences: {
    isProfilePublic: false,
  },
  workHistory: [
    {
      company: '',
      title: '',
      fromMonth: null,
      fromYear: null,
      toMonth: null,
      toYear: null,
      summary: '',
    },
  ],
  educationHistory: [
    {
      school: '',
      fieldOfStudy: '',
      fromYear: null,
      fromMonth: null,
      toYear: null,
      toMonth: null,
      summary: '',
    },
  ],
  minimumSalary: '',
  whatYoureLookingFor: '',
  documents: [] as File[],
}

export const CandidateProfileContext = React.createContext<{
  candidateProfile: CandidateProfileModalInput
  setCandidateProfile: (
    candidateProfile: React.SetStateAction<CandidateProfileModalInput>,
  ) => unknown
  updateCandidateProfile: (profile: Partial<CandidateProfileModalInput>) => unknown
}>({
  candidateProfile: initialCandidateProfile,
  setCandidateProfile: () => {
    return
  },
  updateCandidateProfile: () => {
    return
  },
})

export function useCandidateProfile() {
  return React.useContext(CandidateProfileContext)
}

function getMonthFromDate(date: string | null): number | null {
  return date !== null ? moment.utc(date).month() + 1 : null
}

function getYearFromDate(date: string | null): number | null {
  return date !== null ? moment.utc(date).year() : null
}

function getStartOfMonthISO(month: number, year: number): string {
  return moment
    .utc()
    .month(month - 1)
    .year(year)
    .startOf('month')
    .toISOString()
}

function getEndOfMonthISO(month: number, year: number): string {
  return moment
    .utc()
    .month(month - 1)
    .year(year)
    .endOf('month')
    .toISOString()
}

export function mapLinkedInProfileToCandidateProfile(
  profile: any,
  candidateProfile: CandidateProfileModalInput,
  linkedinUrl?: string | undefined,
) {
  const title = profile.experiences[0]?.title ?? ''
  const company = profile.experiences[0]?.company ?? ''

  return {
    ...candidateProfile,
    firstName: profile.first_name,
    lastName: profile.last_name,
    avatar: profile.profile_pic_url ?? '',
    location: profile.city || profile.country || '',
    headline: profile.headline ?? '',
    linkedInUrl: linkedinUrl || candidateProfile.linkedInUrl || '',
    title,
    company,
    bio: profile.summary ?? '',
    workHistory: profile.experiences.map((experience: any) => ({
      title: experience.title ?? '',
      company: experience.company ?? '',
      fromYear: experience.starts_at?.year ?? null,
      fromMonth: experience.starts_at?.month ?? null,
      toYear: experience.ends_at?.year ?? null,
      toMonth: experience.ends_at?.month ?? null,
      summary: experience.description ?? '',
    })),
    educationHistory: profile.education.map((education: any) => ({
      fieldOfStudy: education.field_of_study ?? '',
      school: education.school ?? '',
      summary: education.description ?? '',
      fromYear: education.starts_at?.year ?? null,
      fromMonth: education.starts_at?.month ?? null,
      toYear: education.ends_at?.year ?? null,
      toMonth: education.ends_at?.month ?? null,
    })),
  }
}

interface Props {
  teamSlug: string
  listUuid: string
  onFinalize: () => void
  onHide?: () => void
  linkedinUrl?: string
  inPeopleList?: boolean
  candidateUuid?: string
}

const CandidateProfileForm: React.FC<Props> = ({
  teamSlug,
  listUuid,
  onFinalize,
  onHide,
  linkedinUrl,
  inPeopleList,
  candidateUuid,
}) => {
  const creatingCandidateProfile = !candidateUuid

  const [step, setStep] = React.useState(creatingCandidateProfile ? 0 : 1)
  const [candidateProfile, setCandidateProfile] =
    React.useState<CandidateProfileModalInput>(initialCandidateProfile)
  const [working, setWorking] = React.useState(false)

  let candidateLinkedinUrl = linkedinUrl

  const ctxVal = React.useMemo(() => {
    return {
      candidateProfile,
      setCandidateProfile,
      updateCandidateProfile: (profile: Partial<CandidateProfileModalInput>) => {
        setCandidateProfile((candidateProfile) => ({ ...candidateProfile, ...profile }))
      },
    }
  }, [candidateProfile])

  function mapCandidateProfile(
    candidate_profile: any,
    experience?: any,
  ): CandidateProfileModalInput {
    return {
      ...candidateProfile,
      firstName: candidate_profile.user.first_name || '',
      lastName: candidate_profile.user.last_name || '',
      email: candidate_profile.user.email || '',
      linkedInUrl: candidate_profile.user.linkedin_url || '',
      company: candidate_profile.company || experience?.company || '',
      headline: candidate_profile.headline || '',
      title: candidate_profile.title || experience?.title || '',
      bio: candidate_profile.bio || '',
      location: candidate_profile.location || '',
      avatar: candidate_profile.global_person.image_url || undefined,
      functions: candidate_profile.functions || [],
      skills: candidate_profile.skills || [],
      fundingStageExperience: candidate_profile.funding_stage_experience || [],
      level: candidate_profile.level || '',
      ownerUuid: candidate_profile.owner.uuid || '',
      opportunityType: candidate_profile.opportunity_type || [],
      companyListUuids: candidate_profile.company_list_uuids || [],
      minimumSalary: candidate_profile.minimum_salary || '',
      whatYoureLookingFor: candidate_profile.what_youre_looking_for || '',
      workStatus: candidate_profile.work_status || '',
      officePreference: candidate_profile.office_preference || [],
      idealCompanySize: candidate_profile.ideal_company_size || undefined,
      stagePreference: candidate_profile.stage_preference || [],
      levelPreference: candidate_profile.level_preference || [],
      functionPreference: candidate_profile.function_preference || [],
      workHistory:
        candidate_profile?.work_history?.map((entry: any) => ({
          uuid: entry.uuid,
          summary: entry.summary,
          company: entry.company,
          title: entry.title,
          fromMonth: getMonthFromDate(entry.start_at),
          fromYear: getYearFromDate(entry.start_at),
          toMonth: getMonthFromDate(entry.end_at),
          toYear: getYearFromDate(entry.end_at),
        })) || [],
      educationHistory:
        candidate_profile?.education_history?.map((entry: any) => ({
          uuid: entry.uuid,
          summary: entry.summary,
          school: entry.school,
          fieldOfStudy: entry.field_of_study,
          fromMonth: getMonthFromDate(entry.start_at),
          fromYear: getYearFromDate(entry.start_at),
          toMonth: getMonthFromDate(entry.end_at),
          toYear: getYearFromDate(entry.end_at),
        })) || [],
    }
  }

  function getCandidateProfileParams(candidateProfile: CandidateProfileModalInput) {
    return {
      team: teamSlug,
      list_uuid: listUuid,
      candidate: {
        first_name: candidateProfile.firstName,
        last_name: candidateProfile.lastName,
        email: candidateProfile.email,
        headline: candidateProfile.headline,
        company: candidateProfile.company,
        title: candidateProfile.title,
        linkedin_url: candidateProfile.linkedInUrl,
        location: candidateProfile.location,
        functions: candidateProfile.functions,
        function_preference: candidateProfile.functionPreference,
        skills: candidateProfile.skills,
        bio: candidateProfile.bio,
        avatar: candidateProfile.avatar,
        level: candidateProfile.level,
        owner_uuid: candidateProfile.ownerUuid,
        level_preference: candidateProfile.levelPreference,
        funding_stage_experience: candidateProfile.fundingStageExperience,
        stage_preference: candidateProfile.stagePreference,
        opportunity_type: candidateProfile.opportunityType,
        company_list_uuids: candidateProfile.companyListUuids,
        minimum_salary: candidateProfile.minimumSalary,
        what_youre_looking_for: candidateProfile.whatYoureLookingFor,
        is_profile_public: candidateProfile.preferences.isProfilePublic,
        work_status: candidateProfile.workStatus,
        office_preference: candidateProfile.officePreference,
        ideal_company_size: candidateProfile.idealCompanySize,
        work_history: candidateProfile.workHistory.map((entry) => ({
          uuid: entry.uuid,
          summary: entry.summary,
          company: entry.company,
          title: entry.title,
          start_at:
            entry.fromMonth !== null && entry.fromYear !== null
              ? getStartOfMonthISO(entry.fromMonth, entry.fromYear)
              : null,
          end_at:
            entry.toMonth !== null && entry.toYear !== null
              ? getEndOfMonthISO(entry.toMonth, entry.toYear)
              : null,
        })),
        education_history: candidateProfile.educationHistory.map((entry) => ({
          uuid: entry.uuid,
          summary: entry.summary,
          school: entry.school,
          field_of_study: entry.fieldOfStudy,
          start_at:
            entry.fromMonth !== null && entry.fromYear !== null
              ? getStartOfMonthISO(entry.fromMonth, entry.fromYear)
              : null,
          end_at:
            entry.toMonth !== null && entry.toYear !== null
              ? getEndOfMonthISO(entry.toMonth, entry.toYear)
              : null,
        })),
      },
    }
  }

  const finalizeWithSuccessAndShowToast = (message: string) => {
    onFinalize()
    setWorking(false)
    cabalToast({
      style: 'success',
      content: message,
    })
  }

  const showError = (message: string, error: any) => {
    setWorking(false)
    error.response
      ? cabalToast({
          style: 'error',
          content: `${message}: ${error.response.data.errors.message}`,
        })
      : cabalToast({
          style: 'error',
          content: message,
        })
  }

  const createCandidateProfileMutation = useMutation(
    async () => {
      return await callApi(api.createCandidateProfile, getCandidateProfileParams(candidateProfile))
    },
    {
      onMutate: () => {
        setWorking(true)
      },
      onSuccess: (response) => {
        if (candidateProfile.documents.length > 0) {
          const documentsFormData = new FormData()
          candidateProfile.documents.forEach((document: File, index: number) => {
            documentsFormData.append(`documents[${index}]`, document)
          })
          callApi(
            api.uploadCandidateDocuments,
            teamSlug,
            response.candidate_profile.uuid!,
            documentsFormData,
          )
            .then(() => {
              finalizeWithSuccessAndShowToast('Profile created successfully')
            })
            .catch((error) => {
              showError('Failed to upload documents', error)
            })
        } else {
          finalizeWithSuccessAndShowToast('Profile created successfully')
        }
      },
      onError: (error) => {
        showError('Error', error)
      },
    },
  )

  const { mutate: enrichProfileMutation, isLoading: profileLoading } = useMutation(
    ['enrichProfile', candidateLinkedinUrl],
    (linkedInSlug: string) => callApi(api.enrichProfile, linkedInSlug),
    {
      onSuccess: ({ profile }) => {
        if (!(profile.code && profile.code >= 400 && profile.code < 500)) {
          setCandidateProfile(
            mapLinkedInProfileToCandidateProfile(profile, candidateProfile, linkedinUrl),
          )
        } else {
          setCandidateProfile({
            ...candidateProfile,
            firstName: profile.first_name,
            lastName: profile.last_name,
          })
        }
        setStep(1)
      },
    },
  )

  React.useEffect(() => {
    if (linkedinUrl && creatingCandidateProfile) {
      setCandidateProfile({
        ...candidateProfile,
        linkedInUrl: linkedinUrl,
      })
      const linkedInSlug = getLinkedInSlug(linkedinUrl)
      enrichProfileMutation(linkedInSlug)
    }
  }, [linkedinUrl])

  // Only used for edit candidate profile

  const updateCandidateProfileMutation = useMutation(
    ['updateCandidateProfile', candidateUuid],
    () =>
      callApi(
        api.updateCandidateProfile,
        candidateUuid!,
        getCandidateProfileParams(candidateProfile),
      ),
    {
      onMutate: () => {
        setWorking(true)
      },
      onSuccess: (response) => {
        finalizeWithSuccessAndShowToast('Profile updated successfully')
      },
      onError: (error) => {
        showError('Failed to update profile', error)
      },
    },
  )

  const candidateProfileQuery = useQuery(
    ['candidateProfile', candidateUuid],
    () => {
      return callApi(api.fetchCandidateProfile, candidateUuid!, teamSlug)
    },
    {
      enabled: !!candidateUuid,
      onSuccess: ({ candidate_profile }) => {
        candidateLinkedinUrl = candidate_profile.global_person.linkedin_url
        setCandidateProfile(mapCandidateProfile(candidate_profile))
      },
    },
  )

  const topRef = React.createRef<HTMLDivElement>()

  const scrollToTop = () => {
    if (topRef.current) topRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  if (candidateProfileQuery.isLoading || candidateProfileQuery.isFetching) {
    return <Loading />
  }

  return (
    <CandidateProfileContext.Provider value={ctxVal}>
      <Modal header={step === 0 && 'Add Person'} onHide={onHide!} canClose show>
        <ModalSectionWrapper className="text-center" ref={topRef}>
          {step > 0 && (
            <div className="-mx-6">
              <TabBar>
                <Tab active={step === 1} onClick={() => undefined}>
                  Personal
                </Tab>
                <Tab active={step === 2} onClick={() => undefined}>
                  Work & Education
                </Tab>
                <Tab active={step === 3} onClick={() => undefined}>
                  Job & Preferences
                </Tab>
              </TabBar>
            </div>
          )}

          {profileLoading && <Loading />}

          {step === 0 && !profileLoading && (
            <LinkedInAutoPrefill
              onNext={() => setStep((step) => step + 1)}
              linkedinUrl={candidateLinkedinUrl}
            />
          )}

          {step === 1 && !profileLoading && (
            <CandidatePersonalInfo
              teamSlug={teamSlug}
              onContinue={() => {
                setStep((step) => step + 1)
                scrollToTop()
              }}
              listUuid={listUuid}
              inPeopleList={inPeopleList}
              creatingCandidateProfile={creatingCandidateProfile}
            />
          )}
          {step === 2 && !profileLoading && (
            <WorkAndEducationHistory
              creatingCandidateProfile={creatingCandidateProfile}
              onBack={() => {
                setStep((step) => step - 1)
                scrollToTop()
              }}
              onNext={() => {
                setStep((step) => step + 1)
                scrollToTop()
              }}
            />
          )}
          {step === 3 && !profileLoading && (
            <CareerInfo
              onBack={() => {
                setStep((step) => step - 1)
                scrollToTop()
              }}
              working={working}
              onSubmit={() =>
                creatingCandidateProfile
                  ? createCandidateProfileMutation.mutate()
                  : updateCandidateProfileMutation.mutate()
              }
            />
          )}
        </ModalSectionWrapper>
      </Modal>
    </CandidateProfileContext.Provider>
  )
}

export default CandidateProfileForm
