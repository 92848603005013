import * as React from 'react'
import styled from 'styled-components'

import tw from 'twin.macro'
import useColorMode from 'utils/hooks/useColorMode'

const ColumnDoubleLayout = styled.div`
  display: grid;
  row-gap: 32px;
  align-items: flex-start;
  padding-bottom: 5.5rem;

  @media (min-width: 650px) {
    grid-template-columns: minmax(auto, 400px) minmax(auto, 600px);
    column-gap: 68px;
  }
`

const Text = styled.p<{
  header?: boolean
}>`
  font-weight: ${({ header }) => (header ? '600' : '400')};
  line-height: ${({ header }) => (header ? '1.05' : '1.5')};
  padding-bottom: ${({ header }) => (header ? '16px' : '0px')};
  font-size: ${({ header }) => (header ? '24px' : '16px')};
  font-family: ${({ header }) => !header && 'JetBrains Mono'};
  color: ${({ header }) => (header ? '#000 !important' : '#6B7280 !important')};
  letter-spacing: ${({ header }) => header && '-0.03em'};

  /* @media (prefers-color-scheme: dark) {
    color: #f7fafc !important;
  } */

  @media (min-width: 650px) {
    padding-bottom: ${({ header }) => (header ? '20px' : '0px')};
    font-size: ${({ header }) => (header ? '36px' : '16px')};
  }
`

const TextSection = styled.div`
  @media (min-width: 650px) {
    align-self: center;
    max-width: 400px;
  }
`

const Img = styled.img`
  border-radius: 16px;
`

interface Props {
  title: string
  description: string
  img?: string
  imgLT: string
  subDescription?: string
}

const Highlights: React.VFC<Props> = ({ title, description, img, imgLT, subDescription }) => {
  const { isDarkMode } = useColorMode()

  return (
    <ColumnDoubleLayout>
      <TextSection>
        <Text header={true}>{title}</Text>
        <Text>{description}</Text>
        {subDescription && <Text className="mt-4">{subDescription}</Text>}
      </TextSection>
      {/* {isDarkMode ? <Img src={img} /> : <Img src={imgLT} />} */}
      <Img src={imgLT} />
    </ColumnDoubleLayout>
  )
}

export default Highlights
