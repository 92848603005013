import * as React from 'react'

import { ColumnTripleLayout } from '../NewSite'
import ContentCard from '../NewSite/contentCard'
import Footer from '../NewSite/footer'
import Nav from '../NewSite/nav'
import {
  H5,
  LandingPageWrapper,
  SectionContainer,
  SignupContainer,
  SmallerColumnLayout,
} from '../NewSite/styles'

const LearnIndex = () => {
  return (
    <LandingPageWrapper>
      <SignupContainer>
        <Nav />
        {/* <BlogContent> */}
        <SectionContainer className="mt-10 mb-8">
          <SmallerColumnLayout>
            <H5 className="mb-6 ">Blog</H5>
          </SmallerColumnLayout>
        </SectionContainer>

        <SectionContainer className="">
          <ColumnTripleLayout>
            {window.posts_list?.map((post) => (
              <ContentCard
                key={post.slug}
                img={post.feature_image}
                title={post.title}
                body={``}
                redirect={`/blog/${post.slug}`}
              />
            ))}
          </ColumnTripleLayout>
        </SectionContainer>

        {/* </BlogContent> */}
        <SectionContainer className="my-32">
          <Footer withForm={false} />
        </SectionContainer>
      </SignupContainer>
    </LandingPageWrapper>
  )
}

export default LearnIndex
