import axios from 'axios'

import ErrorLogger from 'utils/ErrorLogger'

const urlParams = new URLSearchParams(window.location.search)
const qaSecret = urlParams.get('qa_secret')

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('[name=csrf-token]')?.content
axios.defaults.headers.common['qa_secret'] = qaSecret

axios.interceptors.response.use(
  (response) => {
    // Return the successful response as-is
    return response
  },
  (error) => {
    if (error.response?.status === 403) {
      // If the error status is 403 Forbidden, redirect to the login page
      if (error.response.data.error === 'invalid_authenticity_token') {
        ErrorLogger.error('invalid_authenticity_token frontend')
        window.location.href = '/login'
      }
    }
    // Return a rejected promise with the error
    return Promise.reject(error)
  },
)
