import React from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useMutation, useQuery } from 'react-query'

import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import useTeamSetting from 'utils/hooks/useTeamSetting'
import { InvestorCompany } from 'utils/types/investor'

import CompanyInfo from './CompanyInfo'
import ContactsSettings from './ContactsSettings'
import OptionsSection from './OptionsSection'

interface Props {
  onHide: () => void
  teamSlug: string
  investor_company_uuids: string
  refetchPortfolioList: () => void
}

const EditPortfolioCompanyModal: React.FC<Props> = ({
  onHide,
  teamSlug,
  investor_company_uuids,
  refetchPortfolioList,
}) => {
  const [step, setStep] = React.useState(1)
  const [parent] = useAutoAnimate()
  const [enablePortfolioJobs] = useTeamSetting(teamSlug, 'enable_portfolio_jobs')

  const updateCompanyMutation = useMutation(
    ['updateInvestorCompany', teamSlug],
    ({
      portfolioCompany,
      ownerUuids,
    }: {
      portfolioCompany: InvestorCompany
      ownerUuids: { [key: string]: boolean }
    }) => {
      return callApi(api.updateInvestorCompany, teamSlug, portfolioCompany, ownerUuids).then(() => {
        refetchPortfolioCompany()
        refetchPortfolioList()
      })
    },
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Company updated successfully!' })
        onHide()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const {
    data: portfolio,
    isLoading: isLoadingCompany,
    refetch: refetchPortfolioCompany,
  } = useQuery(
    ['getInvestorCompany', investor_company_uuids],
    () => callApi(api.getInvestorCompany, investor_company_uuids, teamSlug),
    {
      enabled: !!investor_company_uuids,
      cacheTime: 0,
    },
  )

  const portfolioCompany = portfolio?.company

  if (!portfolioCompany || isLoadingCompany) return null

  return (
    <div>
      <Modal onHide={onHide} show={true}>
        <Typography fontSize="12" fontWeight={600} className="mt-4 mb-2" component="p">
          Edit Profile
        </Typography>
        <OptionsSection step={step} setStep={setStep} />
        <div className="mt-7" ref={parent}>
          {step === 1 && (
            <CompanyInfo
              onHide={onHide}
              portfolioCompany={portfolioCompany!}
              updateCompanyMutation={updateCompanyMutation}
            />
          )}
          {step === 2 && (
            <ContactsSettings
              onHide={onHide}
              portfolioCompany={portfolioCompany!}
              refetchPortfolioList={refetchPortfolioList}
              teamSlug={teamSlug}
              updateCompanyMutation={updateCompanyMutation}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}

export default EditPortfolioCompanyModal
