import Cookies from 'js-cookie'
import { useQuery } from 'react-query'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  setIsUniqueAccessLink,
  selectIsUniqueAccessLink,
  setLoadingUniqueAccessLink,
  unsetLoadingUniqueAccessLink,
  setUniqueAccessLinkData,
  selectUniqueAccessLinkData,
} from 'store/reducers/uniqueAccessLinkReducer'
import api, { callApi } from 'utils/api'

export const useUniqueAccessLink = () => {
  const dispatch = useAppDispatch()
  const isUniqueAccessLink = useAppSelector(selectIsUniqueAccessLink)
  const uniqueAccessLinkData = useAppSelector(selectUniqueAccessLinkData)
  const links = JSON.parse(Cookies.get(`unique_access_links`) || '{}')
  const uuid = links[window.location.href]?.uuid || links[window.location.href]

  const { isFetched } = useQuery(
    ['loadIsUniqueAccessLink', uuid],
    () => {
      dispatch(setLoadingUniqueAccessLink())
      return callApi(api.getIsUniqueAccessLink, uuid)
    },
    {
      onSuccess: ({ success, data }) => {
        dispatch(setIsUniqueAccessLink(success))
        dispatch(setUniqueAccessLinkData(data))
        dispatch(unsetLoadingUniqueAccessLink())
      },
      staleTime: Infinity,
      enabled: !!uuid,
    },
  )

  return {
    isUniqueAccessLink,
    uniqueAccessLinkData,
    isLoadingUniqueAccessLink: uuid && !isFetched,
    uuid,
  }
}
