import React, { useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import EmailPreviewModal from 'components/EmailPreviewModal'
import PreviewAgreementModal from 'components/PreviewAgreementModal'
import AcceptContent from 'containers/AdvisorInviteAccept/AcceptContent'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import { Label, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { RenderModal, useModal } from 'global/Modal/Context'
import CKEditor from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { replaceAgreementMessageVariables } from 'utils/constants/agreement'
import {
  AdvisorModel,
  AgreementTemplateModel,
  EquityFieldsType,
  LimitedUser,
  MessageRecipient,
  Params,
} from 'utils/types'
import { numWithCommas } from 'utils/ui'

import { SendAgreementsState } from '.'

const Container = styled.div`
  ${tw`
    m-4
    p-4
    rounded-lg
  `}

  background-color: ${({ theme }) => theme.colors.primary_bg};
`

interface Props {
  teamSlug: string
  agreementTemplate: AgreementTemplateModel
  advisors: AdvisorModel[]
  agreementParams: Params
  advisorParams: Params
  senderParams: Params
  createAgreements: () => void
  creatingAgreements: boolean
  setState: (state: Partial<SendAgreementsState>, callback?: () => void) => void
  currentUserSending: boolean
  sender: LimitedUser
  messageParams: {
    body?: string
    subject?: string
  }
}

const Compose: React.VFC<Props> = ({
  agreementTemplate,
  advisors,
  agreementParams,
  senderParams,
  advisorParams,
  setState,
  createAgreements,
  creatingAgreements,
  currentUserSending,
  sender,
  messageParams,
  teamSlug,
}) => {
  const { team } = useTeam(teamSlug)
  const [subject, setSubject] = useState<string | undefined>(messageParams.subject)
  const [body, setBody] = useState<string | undefined>(messageParams.body)
  const { showModal } = useModal()
  const history = useHistory()

  const defaultRecipients: MessageRecipient[] = advisors.map((a) => ({
    label: a.name,
    type: 'advisor',
    value: a.uuid,
  }))
  const defaultSubject = useMemo(() => `${sender.name} sent you an agreement`, [sender.name])
  const defaultBody = useMemo(() => {
    return (
      agreementTemplate.invite_mail_message ||
      `{{first_name}}, {{sender_name}} sent you an agreement to sign.`
    )
  }, [agreementTemplate.invite_mail_message])

  const equityDetails: string[] = []

  const sharesField = agreementTemplate.fields.find(
    (f) => f.default_value === 'agreement.granted_shares',
  )
  if (sharesField) {
    equityDetails.push(`${numWithCommas(agreementParams[sharesField.uuid])} shares`)
  }

  const amountInvestedField = agreementTemplate.fields.find(
    (f) => f.default_value === 'agreement.amount_invested',
  )
  if (amountInvestedField) {
    equityDetails.push(
      `Investment amount $${numWithCommas(agreementParams[amountInvestedField.uuid])}`,
    )
  }

  const valuationField = agreementTemplate.fields.find(
    (f) => f.default_value === 'agreement.valuation',
  )
  if (valuationField) {
    equityDetails.push(`Valuation cap $${numWithCommas(agreementParams[valuationField.uuid])}`)
  }

  const vestingStartDateField = agreementTemplate.fields.find(
    (f) => f.default_value === 'agreement.vesting_start_date',
  )
  if (vestingStartDateField) {
    equityDetails.push(
      `Vesting start ${moment(agreementParams[vestingStartDateField.uuid]).format('L')}`,
    )
  }

  const effectiveDateField = agreementTemplate.fields.find(
    (f) => f.default_value === 'agreement.effective_date',
  )
  if (effectiveDateField) {
    equityDetails.push(
      `Effective date ${moment(agreementParams[effectiveDateField.uuid]).format('L')}`,
    )
  }

  const vestingPeriodField = agreementTemplate.fields.find(
    (f) => f.default_value === 'agreement.vesting_period',
  )
  if (vestingPeriodField) {
    equityDetails.push(`Vesting period ${agreementParams[vestingPeriodField.uuid]} months`)
  }

  const { mutate: sendTestEmail, isLoading: sendingTestEmail } = useMutation(
    ['sendAgreementTestEmail'],
    () => callApi(api.sendAgreementTestEmail, body || '', subject || ''),
  )

  useEffect(() => {
    if (body === undefined && defaultBody) setBody(defaultBody)
    if (subject === undefined && defaultSubject) setSubject(defaultSubject)
  }, [body, defaultBody, defaultSubject, subject])

  const handleSubmit = async () => {
    setState(
      {
        messageParams: {
          body,
          subject,
        },
      },
      () => {
        createAgreements()
      },
    )
  }

  const prevStep = () => {
    history.goBack()
  }

  const agreementSpecificFields = () => {
    const fields: Partial<EquityFieldsType> = {}

    for (const fieldId in agreementParams) {
      const field = agreementTemplate.fields.find((f) => f.uuid === fieldId)
      const fieldValueName = field?.default_value?.split('.')[1]
      if (!field || !fieldValueName) continue

      fields[fieldValueName as keyof EquityFieldsType] = agreementParams[fieldId]
    }

    return fields
  }

  const renderInvitationPreview: RenderModal = (resolve) => {
    if (!sender || !team) return <></>

    return (
      <Modal
        show
        onHide={resolve}
        header={'Invitation Preview'}
        leftActions={
          <CabalButton onClick={() => resolve()} leftIcon={<i className="fas fa-caret-left" />}>
            Back
          </CabalButton>
        }
      >
        <AcceptContent
          companyName={team.name}
          logo={team.logo}
          sender={sender}
          invitePageMessage={replaceAgreementMessageVariables(
            agreementParams.invite_page_message || agreementTemplate.invite_page_message,
            advisors[0],
            agreementSpecificFields(),
            sender,
            team,
          )}
          isPreview
        />
      </Modal>
    )
  }

  const renderInviteEmailPreview: RenderModal = (resolve) =>
    sender && body && team ? (
      <EmailPreviewModal
        resolve={resolve}
        advisor={advisors[0]}
        inviteMessage={replaceAgreementMessageVariables(
          body,
          advisors[0],
          agreementParams,
          sender,
          team,
        )}
      />
    ) : null

  const agreementPdfPreviewUrl = useMemo(() => {
    if (!team || !advisors[0]) return ''
    const url = new URL(window.location.origin)
    url.pathname = `/api/agreement_templates/${agreementTemplate?.id}.pdf`
    url.search = new URLSearchParams({
      agreement_params: JSON.stringify({
        ...agreementParams,
        ...advisorParams[advisors[0].uuid],
        ...senderParams,
      }),
      team_slug: team.slug,
      advisor_uuid: advisors[0].uuid,
      sender_uuid: sender.uuid,
    }).toString()
    return url.toString()
  }, [
    advisorParams,
    advisors,
    agreementParams,
    agreementTemplate?.id,
    sender.uuid,
    senderParams,
    team,
  ])

  return (
    <Container>
      <div className="flex items-center mb-3">
        <CabalButton
          leftIcon={<i className="far fa-chevron-left" />}
          onClick={prevStep}
          size="small"
          variant="tertiary"
        >
          Back
        </CabalButton>
      </div>
      <TextInput
        label="To"
        labelPosition="left"
        placeholder="To"
        value={defaultRecipients.map((r) => r.label).join(', ')}
        disabled={true}
      />
      <div className="pt-2"></div>
      <TextInput
        label="Subject"
        labelPosition="left"
        placeholder="Subject"
        data-testid="agreement-send-subject"
        value={subject}
        onChange={(e) => setSubject(e.currentTarget.value)}
      />
      <div className="pt-2">
        <CKEditor value={body} onChange={setBody} height={100} />
      </div>
      <div className="flex justify-between mt-4">
        <div>
          <DropDownMenu
            portal={false}
            needScrollIntoView
            menuItems={[
              agreementTemplate?.invite_page_message
                ? {
                    label: 'Invite Page',
                    onSelect: () => showModal(renderInvitationPreview, 'invite-preview'),
                  }
                : null,
              agreementTemplate?.invite_mail_message
                ? {
                    label: 'Invite Mail',
                    onSelect: () => showModal(renderInviteEmailPreview, 'invite-email-preview'),
                  }
                : null,
              {
                label: 'Agreement PDF',
                onSelect: () =>
                  showModal(
                    (resolve) => (
                      <PreviewAgreementModal onHide={resolve} url={agreementPdfPreviewUrl} />
                    ),
                    'agreement-preview',
                  ),
              },
            ]}
            trigger={
              <CabalButton
                variant="secondary"
                rightIcon={<i className="fas fa-caret-down" />}
                component="span"
                data-testid="agreement-send-preview-dropdown"
              >
                Preview
              </CabalButton>
            }
          />
        </div>
        <div>
          <CabalButton
            variant="tertiary"
            working={sendingTestEmail}
            onClick={() => sendTestEmail()}
            className="mr-4"
            data-testid="agreement-send-test-email"
          >
            Send test email
          </CabalButton>
          <CabalButton
            onClick={handleSubmit}
            working={creatingAgreements}
            data-testid="agreement-send-create"
          >
            {currentUserSending ? 'Send' : 'Create'} Agreements
          </CabalButton>
        </div>
      </div>
    </Container>
  )
}

export default Compose
