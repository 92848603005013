import React, { useEffect } from 'react'

import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useAccessControl } from 'global/AccessControl'
import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import { useUniqueAccessLink } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import { CompanySlugParam } from 'utils/types'

import AdminView from './AdminView'
import MemberView from './MemberView'

const PortfolioContainer = () => {
  const { isUniqueAccessLink, isLoadingUniqueAccessLink: isLoading } = useUniqueAccessLink()

  if (isLoading) {
    return <Loading />
  }

  return <>{isUniqueAccessLink ? <MemberView /> : <Portfolio />}</>
}

const Portfolio = () => {
  const { company_slug } = useParams<CompanySlugParam>()
  const { isAdmin } = useAccessControl(company_slug)
  const location = useLocation()
  const tag = location.pathname?.split('/').pop()
  const history = useHistory()

  useEffect(() => {
    if (location.search.includes('added_port_co=true')) {
      cabalToast({ style: 'success', content: 'Added company to portfolio' })
    }

    if (tag === company_slug) {
      history.replace(`/${company_slug}/portfolio`)
    }
  }, [tag, company_slug])

  if (isAdmin === undefined) {
    return (
      <PageWrapper title={'Portfolio'}>
        <Loading />
      </PageWrapper>
    )
  }

  return (
    <PageWrapper title={'Portfolio'} data-testid="portfolio-page">
      {isAdmin ? <AdminView /> : <MemberView />}
    </PageWrapper>
  )
}

export default PortfolioContainer
