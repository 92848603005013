import React, { useMemo, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import EditGroupsModal from 'containers/Groups/EditGroupsModal'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import PageWrapper from 'global/PageWrapper'
import Tabs, { Tab } from 'global/Tabs'
import { useCurrentUser, useGroups, useTeamSlug } from 'store/hooks'

import CardView from './CardView'

const Groups = () => {
  const history = useHistory()
  const { user } = useCurrentUser()
  const teamSlug = useTeamSlug()
  const { groups, reloadGroups } = useGroups(teamSlug)
  const { showModal } = useModal()

  const publicToTeam = useMemo(() => groups.filter((m) => m.public_to_team), [groups])
  const privateToTeam = useMemo(() => groups.filter((m) => !m.public_to_team), [groups])
  const [tab, setTab] = useState<'all' | 'public_to_team' | 'admin_private'>('all')

  const renderGroupAddModal: RenderModal = (resolve) => (
    <EditGroupsModal
      teamSlug={teamSlug}
      onHide={() => resolve(false)}
      onSave={() => {
        reloadGroups()
      }}
      onDelete={() => {
        reloadGroups()
        resolve(false)
      }}
    />
  )

  const tabs: Tab[] = [
    {
      label: `All (${groups?.length})`,
      component: <CardView groups={groups} teamSlug={teamSlug} />,
      id: 'all',
    },
  ]

  if (publicToTeam && publicToTeam?.length > 0) {
    tabs.push({
      label: `Shared (${publicToTeam?.length})`,
      component: <CardView groups={publicToTeam} teamSlug={teamSlug} />,
      id: 'public_to_team',
    })
  }

  if (privateToTeam && privateToTeam?.length > 0) {
    tabs.push({
      label: `Private (${privateToTeam?.length})`,
      component: <CardView groups={privateToTeam} teamSlug={teamSlug} />,
      id: 'admin_private',
    })
  }

  const activeIndex = tabs.findIndex((t) => t?.id === tab)

  return (
    <PageWrapper title="Groups">
      <Tabs
        data={tabs}
        onChange={(_, id) => setTab(id! as typeof tab)}
        variant="filters"
        index={activeIndex}
        nestedSticky={true}
        rightActions={
          <>
            <CabalButton
              variant="secondary"
              size="small"
              onClick={() => showModal(renderGroupAddModal, 'render-group-modal')}
              leftIcon={<i className="far fa-users"></i>}
            >
              Add Group
            </CabalButton>
            <CabalButton
              variant="secondary"
              size="small"
              className="ml-2"
              data-testid="view-members-button"
              leftIcon={<i className="far fa-users" />}
              onClick={() => history.push(`./members`)}
            >
              Manage Members
            </CabalButton>
          </>
        }
      />
    </PageWrapper>
  )
}

export default Groups
