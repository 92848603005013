import React from 'react'

import { AgreementTemplateField } from 'utils/types'
import {
  CheckBox,
  DatePickerModal,
  Label,
  NumberFormattedInput,
  Select,
  TextInput,
  UploadButton,
} from 'global/Input'
import { US_STATE_NAMES } from 'utils/constants/ui'

const l = (str: string) => {
  if (str.includes('Sender')) {
    str = str.replace('Sender', 'Your')
  }

  switch (str) {
    case 'Amount Invested':
      return 'Investment Amount'
    case 'Valuation':
      return 'Valuation Cap'
    default:
      return str
  }
}

interface Props {
  value: any
  field: AgreementTemplateField
  setValue: (field_uuid: string, v: any) => void
  labeled?: boolean
  compact?: boolean
}

const Field: React.VFC<Props> = React.memo(
  ({ field: f, value, setValue: _setValue, labeled = true, compact = false }) => {
    const setValue = (v: any) => {
      _setValue(f.uuid, v)
    }

    let input
    const disabled = f.default_value === 'sender.email' && f.overwrite_default_value === true

    if (f.default_value === 'agreement.vesting_period') {
      input = (
        <>
          <Label label={labeled ? 'Vesting Period (in months)' : undefined}>
            <Select<string>
              compact={compact}
              portal
              value={value}
              onChange={(v) => {
                if (v !== null) setValue(v)
              }}
              options={[0, 12, 24, 36, 48, 72, 96].map((n) => ({
                value: n.toString(),
              }))}
              noMinWidth
              creatable
              onCreateOption={(v) => {
                const nv = Number(v)
                if (Number.isNaN(nv) || nv < 0) {
                  alert('Invalid input, please enter positive numbers only')
                  return
                }
                setValue(v)
              }}
              formatOptionLabel={(v) => `${v.value} month${v.value !== 1 ? 's' : ''}`}
            />
          </Label>
        </>
      )
    } else if (f.default_value === 'team.state_of_incorporation') {
      input = (
        <>
          <Label label={labeled ? "Company's State of Incorporation" : undefined}>
            <Select<string>
              compact={compact}
              portal
              value={value}
              onChange={(v) => v && setValue(v)}
              options={US_STATE_NAMES.map((s) => ({
                value: s,
              }))}
              className="w-full"
              noMinWidth
            />
          </Label>
        </>
      )
    } else if (f.data_type === 'string') {
      input = (
        <TextInput
          compact={compact}
          required
          labeled={labeled}
          value={value}
          disabled={disabled}
          onChange={(e, valid) => setValue(valid ? e.currentTarget.value : null)}
          valueType={f.default_value?.includes('email') ? 'email' : 'non-empty'}
          placeholder={l(f.name)}
          className="w-full"
        />
      )
    } else if (f.data_type === 'text') {
      input = (
        <TextInput
          compact={compact}
          required
          component="textarea"
          labeled={labeled}
          disabled={disabled}
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
          placeholder={l(f.name)}
          className="w-full"
        />
      )
    } else if (f.data_type === 'checkbox') {
      input = (
        <>
          {labeled && <Label>{f.name}</Label>}
          <CheckBox
            checked={value === 'checked'}
            onChange={(e) => setValue(e.currentTarget.checked ? 'checked' : 'unchecked')}
            placeholder={l(f.name)}
          />
        </>
      )
    } else if (f.data_type === 'amount' || f.data_type === 'number') {
      input = (
        <>
          <NumberFormattedInput
            required
            compact={compact}
            labeled={labeled}
            value={value}
            prefix={f.data_type === 'amount' ? '$' : undefined}
            onChange={(e, valid) => setValue(valid ? Number(e.currentTarget.value) : null)}
            nonZero
            placeholder={l(f.name)}
            className="w-full"
          />
        </>
      )
    } else if (f.data_type === 'date') {
      input = (
        <>
          <Label label={labeled ? f.name : undefined}>
            <DatePickerModal
              compact={compact}
              id={f.uuid.toString()}
              date={value}
              placeholder={f.name}
              onDateChange={(newDate) => setValue(newDate)}
              className="w-full"
            />
          </Label>
        </>
      )
    } else {
      const isSignature = f.default_value?.includes('signature')
      input = (
        <>
          <Label label={labeled ? f.name : undefined}>
            {value && <img src={`/api/uploads/${value}`} className="max-w-lg" />}
            <UploadButton
              onUpload={(uuid) => setValue(uuid)}
              canDrawSignature={isSignature}
              canUpload={!isSignature}
            />
          </Label>
        </>
      )
    }

    return (
      <div className="mb-4" data-testid={`field-${f.shortcode}`}>
        {input}
      </div>
    )
  },
  (prev, next) => {
    if (prev.setValue !== next.setValue) {
      return false
    }

    if (prev.value !== next.value && next.field.data_type === 'date') {
      return false
    }

    return true
  },
)

export default Field
