import React from 'react'

import cx from 'classnames'
import compact from 'lodash/compact'
import isArray from 'lodash/isArray'

import Typography from 'global/Typography'

import { joinReactElements } from 'utils/array'
import { cn } from 'utils/styles'

interface Props {
  title: React.ReactNode
  description: React.ReactNode
  titleIcon?: React.ReactNode
  descriptionBottom?: React.ReactNode
  descriptionWithoutTruncate?: React.ReactNode
  cta?: React.ReactNode
  gridPillsSection?: React.ReactNode
}

const CardInfo: React.FC<Props> = ({
  title,
  titleIcon,
  description,
  descriptionBottom,
  cta,
  descriptionWithoutTruncate,
  gridPillsSection,
}) => {
  const getFormattedDescription = (description: React.ReactNode) => {
    const descriptionArray = compact(
      description && isArray(description) ? description : [description],
    )

    return (
      <div className={cx('flex items-center')} onClick={(e) => e.stopPropagation()}>
        {joinReactElements(
          descriptionArray.map((d, i) => (
            <Typography
              fontSize="12"
              lineHeight={'15px'}
              color={'fog'}
              className="truncate"
              key={`${title}-description-element-${i}`}
            >
              {d}
            </Typography>
          )),
          () => (
            <Typography fontSize="12" lineHeight={'15px'} color={'fog'} className="mx-1.5">
              {'·'}
            </Typography>
          ),
        )}
      </div>
    )
  }

  return (
    <div className=" flex flex-col gap-2 h-full">
      <div className="flex items-center gap-1 leading-4 ">
        <Typography
          className="truncate"
          fontSize="20"
          fontWeight={600}
          lineHeight={1.2}
          data-testid="datacard-title"
        >
          {title as React.ReactNode}
        </Typography>
        {titleIcon && (
          <Typography fontSize="14" className="flex flex-shrink-0 items-center gap-1.5">
            {titleIcon}
          </Typography>
        )}
      </div>

      <div className="flex flex-col justify-between h-full">
        <div className={cn('flex flex-col gap-2')}>
          {description && getFormattedDescription(description)}
          {descriptionWithoutTruncate && (
            <Typography
              fontSize="12"
              lineHeight={'15px'}
              color={'fog'}
              className="line-clamp-3 text-start"
              key={`${title}-description-element`}
            >
              {descriptionWithoutTruncate}
            </Typography>
          )}

          {descriptionBottom && getFormattedDescription(descriptionBottom)}
        </div>

        {gridPillsSection && <div>{gridPillsSection}</div>}
      </div>

      {cta && (
        <div
          className={cn(
            'absolute bottom-0 w-full bg-[#f1f1f2] dark:bg-[#1b232f] lef-5 max-h-0 overflow-hidden opacity-0 transition-all duration-200 translate-y-2.5 group-hover/title:opacity-100 group-hover/title:translate-y-0 group-hover/title:max-h-[42px] group-hover/title:pt-2',
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {cta}
        </div>
      )}
    </div>
  )
}

export default CardInfo
