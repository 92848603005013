import React, { useState } from 'react'
import { useQuery } from 'react-query'

import PortfolioJobsEmptyState from 'components/EmptyStates/PortfolioJobsEmptyState'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { InvestorCompany, InvestorCompanyJob } from 'utils/types/investor'

import AddJobModal from '../AddJobModal'
import { JobDescription } from '../JobsTabV1/JobRow'
import { BorderDecore, JobsContainer } from '../styles'

interface Props {
  teamSlug: string
  jobs: InvestorCompanyJob[]
  allJobs: InvestorCompanyJob[]
  isFetching: boolean
  companies: InvestorCompany[]
  setJobs: (jobs: InvestorCompanyJob[]) => void
}

const CompanyJobs: React.VFC<Props> = ({
  teamSlug,
  jobs,
  isFetching,
  companies,
  setJobs,
  allJobs,
}) => {
  const { showModal } = useModal()
  const [allJobTags, setAllJobTags] = useState<string[]>([])

  const refresh = (job: InvestorCompanyJob) => {
    if (job) {
      const updatedJobs = jobs.map((j) => {
        if (j.uuid === job.uuid) {
          return job
        }
        return j
      })
      setJobs(updatedJobs)
    }
  }

  const { isFetching: isFetchingJobTags } = useQuery(
    ['getJobTags'],
    () => callApi(api.getJobTags, teamSlug),
    {
      onSuccess: ({ tags }) => {
        setAllJobTags(tags)
      },
    },
  )

  const openJobModal = (job?: InvestorCompanyJob) => {
    showModal(
      (resolve) => (
        <AddJobModal
          companies={(companies as InvestorCompany[]) || []}
          resolve={resolve}
          teamSlug={teamSlug}
          onAdd={(job) => {
            if (job) {
              setJobs([...allJobs, job])
            }
            resolve()
          }}
          onEdit={(job) => {
            refresh(job)
            resolve()
          }}
          onDelete={(job) => {
            if (job) {
              const updatedJobs = allJobs.filter((j) => j.uuid !== job.uuid)
              setJobs(updatedJobs)
            }
            resolve()
          }}
          job={job}
          refresh={refresh}
          allTags={allJobTags}
          setAllTags={setAllJobTags}
          isFetchingJobTags={isFetchingJobTags}
        />
      ),
      'investor-company-job-modal',
    )
  }

  return (
    <>
      {isFetching && <Loading size="25px" className="m-6" />}
      {!isFetching && jobs.length === 0 && (
        <PortfolioJobsEmptyState companySlug={teamSlug} onClick={() => openJobModal()} />
      )}
      {!isFetching && jobs.length > 0 && (
        <JobsContainer>
          <BorderDecore />
          <div className="w-full">
            {jobs.map((j) => (
              <div key={j.uuid} className="flex justify-between items-center mr-10">
                <div className={'my-4'}>
                  <div className="flex items-center space-x-2">
                    <Typography fontWeight={600} data-testid="portfolio-company-expand-job-title">
                      {j.title}
                    </Typography>
                    {j.url && (
                      <Typography
                        color={'fog_rain'}
                        fontSize="14"
                        data-testid="portfolio-company-expand-job-url"
                      >
                        <a href={`http://www.${j.url}`} target="_blank" rel="noreferrer">
                          <i className="fas fa-globe"></i>
                        </a>
                      </Typography>
                    )}

                    <Typography
                      color={'fog_rain'}
                      fontSize="14"
                      onClick={() => openJobModal(j)}
                      data-testid="portfolio-company-expand-job-edit-btn"
                    >
                      <i className="fas fa-pencil fa-fw"></i>
                    </Typography>
                  </div>
                  {j.description && <JobDescription description={j.description} />}
                  {j.location && (
                    <Typography
                      color="fog_rain"
                      component="div"
                      fontSize="12"
                      className="mt-1"
                      lineHeight={'1'}
                      data-testid="portfolio-company-expand-job-location"
                    >
                      {/* <i className="fas fa-map-marker mr-1"></i> */}
                      {j.location}
                    </Typography>
                  )}
                </div>
              </div>
            ))}
          </div>
        </JobsContainer>
      )}
    </>
  )
}

export default CompanyJobs
