import React, { useState } from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import moment from 'moment'
import DayPicker from 'react-day-picker/DayPicker'
import { NavbarElementProps } from 'react-day-picker/types/Props'
import 'react-day-picker/lib/style.css'

import { TextInput, TextInputProps } from './TextInput'
import { Select } from '.'

const DEFAULT_MIN_YEAR = moment().year(1900).toDate()
const DEFAULT_MAX_YEAR = moment().year(2100).toDate()

const StyledDayPicker = styled(DayPicker)`
  .DayPicker-Day:hover:not(.DayPicker-Day--selected) {
    background-color: ${(props) => props.theme.layout.main_bg_color} !important;
  }

  .DayPicker-Caption > div {
    font-weight: revert;
    font-size: revert;
  }

  .DayPicker-Month {
    margin: 0 !important;
    table-layout: fixed;
  }

  .DayPicker-wrapper {
    padding: 0 !important;
  }

  .DayPicker-Day {
    padding: 0 !important;
    width: 4ch;
    height: 4ch;

    & > span {
      display: block;
      width: 4ch;
      line-height: 4ch;
      text-align: center;
    }
  }
`

interface YearMonthFormProps extends NavbarElementProps {
  onChange: (monthDate: Date) => void
  minDate?: Date
  maxDate?: Date
}

const YearMonthForm: React.VFC<YearMonthFormProps> = ({
  month: date,
  localeUtils,
  onChange,
  minDate,
  maxDate,
  showPreviousButton,
  showNextButton,
  onNextClick,
  onPreviousClick,
}) => {
  let months = (localeUtils?.getMonths() || []) as (string | null)[]

  const selectedYear = date.getFullYear()
  const selectedMonth = date.getMonth()
  const years = []

  if (minDate && maxDate) {
    if (selectedYear === minDate.getFullYear()) {
      months = months?.map((m, i) => (i >= minDate.getMonth() ? m : null))
    }

    if (selectedYear === maxDate.getFullYear()) {
      months = months?.map((m, i) => (i <= maxDate.getMonth() ? m : null))
    }

    for (let i = minDate.getFullYear(); i <= maxDate.getFullYear(); i += 1) {
      years.push(i)
    }
  }

  const handleMonthChange = (month: number) => {
    month -= 1
    if (selectedYear === minDate?.getFullYear() && month < minDate?.getMonth()) {
      month = minDate.getMonth()
    } else if (selectedYear === maxDate?.getFullYear() && month > maxDate?.getMonth()) {
      month = maxDate.getMonth()
    }
    onChange(new Date(selectedYear, month))
  }

  const handleYearChange = (year: number) => {
    let month = selectedMonth
    if (year === minDate?.getFullYear() && month < minDate?.getMonth()) {
      month = minDate.getMonth()
    } else if (year === maxDate?.getFullYear() && month > maxDate?.getMonth()) {
      month = maxDate.getMonth()
    }
    onChange(new Date(year, month))
  }

  return (
    <div className="flex items-center justify-between mb-4">
      {minDate && maxDate && (
        <div className="flex flex-1">
          <Select<number>
            compact
            compactPlaceholder
            minWidth="10ch"
            options={months
              .map((m, i) => ({
                label: m as string,
                value: i + 1,
              }))
              .filter((o) => !!o.label)}
            value={selectedMonth + 1}
            onChange={(v) => v && handleMonthChange(v)}
            portal
            className="mr-3"
          />
          <Select<number>
            compact
            compactPlaceholder
            minWidth="8ch"
            options={years.map((y) => ({
              label: y.toString(),
              value: y,
            }))}
            value={selectedYear}
            onChange={(v) => v && handleYearChange(v)}
            portal
          />
        </div>
      )}
      <div className="ml-4 inline-flex flex-initial">
        <button
          onClick={() => showPreviousButton && onPreviousClick()}
          className={cx({ invisible: !showPreviousButton }, 'focus:ring px-2 rounded')}
        >
          <i className="far fa-chevron-left" />
        </button>
        <button
          onClick={() => showNextButton && onNextClick()}
          className={cx({ invisible: !showNextButton }, 'px-2 focus:ring rounded')}
        >
          <i className="far fa-chevron-right" />
        </button>
      </div>
    </div>
  )
}

export interface DatePickerProps extends InputProps {
  date?: string
  defaultDate?: string
  onDateChange: (newDate: string) => void
  minDate?: Date
  maxDate?: Date
}

export const DatePicker: React.FC<DatePickerProps> = ({
  date: dateString,
  onDateChange,
  minDate = DEFAULT_MIN_YEAR,
  maxDate = DEFAULT_MAX_YEAR,
}) => {
  const date = !!dateString ? moment(dateString).toDate() : undefined
  const [selectedMonth, setSelectedMonth] = useState(date)

  const handleDateChange = (newDate: Date) => {
    onDateChange(newDate?.toISOString() || '')
  }

  const dateIsInRange = (date: Date) => moment(date).isBetween(moment(minDate), moment(maxDate))

  return (
    <>
      <StyledDayPicker
        month={selectedMonth}
        selectedDays={date}
        onDayClick={(date) => {
          if (dateIsInRange(date)) handleDateChange(date)
        }}
        fromMonth={minDate}
        toMonth={maxDate}
        showOutsideDays
        enableOutsideDaysClick
        captionElement={() => <></>}
        renderDay={(date) => (
          <span
            className={cx({
              'opacity-20': !dateIsInRange(date),
            })}
          >
            {date.getDate()}
          </span>
        )}
        navbarElement={(props) => (
          <YearMonthForm
            {...props}
            minDate={minDate}
            maxDate={maxDate}
            onChange={setSelectedMonth}
          />
        )}
      />
    </>
  )
}

export const DatePickerWithInput = React.forwardRef<HTMLInputElement, DatePickerProps>(
  ({ date, defaultDate, onDateChange, className, minDate, maxDate, ...extraInputProps }, ref) => {
    return (
      <>
        <DatePickerInput
          ref={ref}
          className={className}
          date={date}
          defaultDate={defaultDate}
          {...extraInputProps}
        />

        <DatePicker date={date} onDateChange={onDateChange} minDate={minDate} maxDate={maxDate} />
      </>
    )
  },
)

interface DatePickerInputProps {
  className?: string
  defaultDate?: string
  date?: string
}

type InputProps = Omit<JSX.IntrinsicElements['input'] & TextInputProps, 'onChange' | 'ref'>

export const DatePickerInput = React.forwardRef<
  HTMLInputElement,
  DatePickerInputProps & InputProps
>(({ className, date, defaultDate, ...extraProps }, ref) => {
  let momentDate = !!date ? moment(date) : undefined
  let momentDefaultDate = !!defaultDate ? moment(defaultDate) : undefined

  if (!momentDate?.isValid) momentDate = undefined
  if (!momentDefaultDate?.isValid) momentDefaultDate = undefined

  return (
    <TextInput
      ref={ref}
      {...extraProps}
      autoComplete="off"
      className={cx(className, 'cursor-pointer')}
      defaultValue={momentDefaultDate?.format('L')}
      value={momentDate?.format('L') || momentDate?.format('L')}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange={() => {}}
      onKeyDown={(e) => e.preventDefault()}
    />
  )
})
