import React, { useState } from 'react'

import MemberConnections from 'containers/Home/YourConnections/MemberConnections'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import { useModal } from 'global/Modal'

import api from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'

import { useMutation } from 'react-query'
import { cabalToast } from 'ui-components/Toast'
import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import AddResourcePersonModal from './AddResourcePersonModal'
const AddIndividualSection = ({
  setOnClose,
  listUuid,
}: {
  setOnClose: () => void
  listUuid: string
}) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const { showModal } = useModal()

  const { refetchResourceList, refetchTags, tags } = useResourcesListContext()
  const { mutate: addListItem } = useMutation(
    ({
      personUuid,
      resourcePerson,
    }: {
      personUuid?: string
      resourcePerson?: {
        first_name: string
        last_name: string
        email: string
        linkedin_url: string
        avatar: string
        location: string
        title: string
        company: string
        about: string
        tags?: string[]
      }
    }) => api.addElementToList(listUuid, resourcePerson, personUuid),
    {
      onSuccess: () => {
        refetchResourceList()
        refetchTags()
        setOnClose()
        cabalToast({ style: 'success', content: 'Item has been added from the list' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to add item' })
      },
    },
  )

  return (
    <div className="mt-4">
      <SearchInput
        onChange={(c) => setQuery(c.target.value)}
        placeholder={`Search for persons`}
        value={query}
      />

      {debouncedQuery !== '' && (
        <>
          <div className="my-4 px-3">
            <CabalButton
              variant="link"
              onClick={() => {
                showModal(
                  (resolve) => (
                    <AddResourcePersonModal
                      onHide={resolve}
                      onSave={(personUuid: string, resourcePerson) => {
                        addListItem({ personUuid, resourcePerson })
                      }}
                      tags={tags}
                    />
                  ),
                  'add-person-modal',
                )
                setOnClose()
              }}
              leftIcon={<i className="far fa-plus fa-xs" />}
              padding="0"
              size="large"
            >
              <span>{`Add person "${debouncedQuery}"`}</span>
            </CabalButton>
          </div>

          <MemberConnections
            debouncedQuery={[debouncedQuery]}
            personRowCta={'Add'}
            onPersonRowCtaClick={(p) => {
              addListItem({ personUuid: p.uuid, resourcePerson: undefined })
            }}
            searchAll
          />
        </>
      )}
    </div>
  )
}

export default AddIndividualSection
