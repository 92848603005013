import React from 'react'

import { useQuery } from 'react-query'

import Comment from 'components/CommentsAndActivity/Comment'
import CreateComment from 'components/CommentsAndActivity/CreateComment'
import MessageParsed from 'components/MessageParsed'
import { Divider } from 'components/SendMessage/styles'
import TimeAgo from 'components/TimeAgo'
import Typography from 'global/Typography'
import { DataCard } from 'ui-components/DataCard'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { Comment as CommentType } from 'utils/types'

interface ItemProps {
  uuid: string
  name: string
  item_type: string
  message_body: string
  created_at: string
  creator_user: {
    uuid: string
    name: string
    avatar_url: string
  }
}

interface Props {
  teamSlug: string
  item: ItemProps
}

const PostWidget = (props: Props) => {
  const { item, teamSlug } = props

  const commentsQuery = useQuery(
    ['getComments', 'CompanyListItem', item.uuid],
    () =>
      callApi(api.getComments, {
        attachable_type: 'CompanyListItem',
        attachable_id: item.uuid,
        team_slug: teamSlug,
      }),
    {
      refetchInterval: 3 * 1000,
    },
  )

  const comments = (commentsQuery.data?.comments || []).map((co) => ({
    ...co,
    type: 'comment',
  }))

  const user = item.creator_user || {}
  const created_at = item.created_at
  const postViewType = 'thread' // 'conversation'

  return (
    <div className="flex flex-col w-full">
      {postViewType == 'thread' && (
        <Widget className="p-4 pt-0 pb-6 mb-4">
          <DataCard
            className="h-auto min-h-[50px] p-0 mb-2 pt-0 mt-0"
            title={
              <>
                <Typography fontSize="12">
                  <Typography fontWeight={600}>{user.name}</Typography>{' '}
                  <Typography color="fog_rain">
                    <TimeAgo datetime={created_at} short />
                  </Typography>
                </Typography>
              </>
            }
            avatarUrl={user.avatar_url}
            avatarName={user.name}
          />
          <MessageParsed body={item.message_body} fontSize="12px" />
        </Widget>
      )}

      <Widget className="p-4 pt-0">
        {postViewType == 'conversation' && (
          <DataCard
            className="h-auto min-h-[50px] pb-2"
            title={
              <>
                <Typography fontSize="12">
                  <Typography fontWeight={600}>{user.name}</Typography>{' '}
                  <Typography color="fog_rain">
                    <TimeAgo datetime={created_at} short />
                  </Typography>
                </Typography>
              </>
            }
            avatarUrl={user.avatar_url}
            avatarName={user.name}
            avatarAlign={'top'}
            description={[
              <Typography key="body" color="primary" component="div" className="ql-output">
                <MessageParsed body={item.message_body} fontSize="12px" />
              </Typography>,
            ]}
          />
        )}

        {comments.map((comment) => (
          <Comment comment={comment as CommentType} key={comment.uuid} teamSlug={teamSlug} />
        ))}

        {comments.length > 0 && (
          <div className="-my-2 -mb-4">
            <Divider />
          </div>
        )}
        <div className="px-4 pt-0">
          <CreateComment
            teamSlug={teamSlug}
            createProps={{ attachable_id: item.uuid, attachable_type: 'CompanyListItem' }}
            refetchComments={commentsQuery.refetch}
            newCommentInternal={false}
            canToggleCommentVisibility={false}
            placeholder="Type a message"
            focus
          />
        </div>
      </Widget>
    </div>
  )
}

export default PostWidget
