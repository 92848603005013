import React, { useState } from 'react'
import { PopupModal } from 'react-calendly'

import ModalVideo from 'components/ModalVideo'
import 'components/ModalVideo/styles.scss'
import OpenOfferModal from 'components/OpenOfferModal'
import IndeterminantTeamPage from 'containers/ViewCompany/IndeterminantTeamPage'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'

import { Team } from 'utils/types'

interface Props {
  team: Team
}

const AsksPaywall: React.VFC<Props> = ({ team }) => {
  const [openCalendly, setOpenCalendly] = useState(false)
  const { isAdminOrEmployee, isAdvisor } = useAccessControl(team?.slug)
  const { showModal } = useModal()
  const [showVideo, setShowVideo] = useState(false)

  const renderOpenOfferModal: RenderModal = (resolve) => (
    <OpenOfferModal show onHide={() => resolve(false)} teamSlug={team?.slug}></OpenOfferModal>
  )

  if (isAdminOrEmployee) {
    return (
      <>
        <EmptyState
          icon={<i className="fal fa-thumbs-up"></i>}
          heading="Activate your contributors with Asks"
          body={
            <div>
              Get help with sales referrals, candidate intros, fundraising support, and more.
              <div
                className="mt-4 font-normal text-primary hover:underline cursor-pointer"
                onClick={() => setShowVideo(!showVideo)}
              >
                <i className="fa fa-youtube mr-2" />
                See how it works (video)
              </div>
              {showVideo && (
                <div className="my-4">
                  <ModalVideo
                    channel="youtube"
                    isOpen={showVideo}
                    videoId="FCoip4waqTs"
                    onClose={() => setShowVideo(false)}
                  />
                </div>
              )}
            </div>
          }
          cta={
            <div>
              <CabalButton variant="primary" onClick={() => setOpenCalendly(true)}>
                Request a free trial
              </CabalButton>
            </div>
          }
        ></EmptyState>
        <PopupModal
          url="https://calendly.com/burke33/30min"
          onModalClose={() => setOpenCalendly(false)}
          open={openCalendly}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById('calendly-index')}
        />
      </>
    )
  }
  if (isAdvisor) {
    return (
      <EmptyState
        icon={<i className="fal fa-thumbs-up"></i>}
        heading={`Offer to help ${team.name}`}
        body="Offer to help with sales referrals, candidate intros, fundraising support, and more."
        cta={
          <CabalButton
            variant="primary"
            onClick={() => showModal(renderOpenOfferModal, 'render_open_offer_modal')}
          >
            Offer help
          </CabalButton>
        }
      />
    )
  }

  return <IndeterminantTeamPage teamSlug={team?.slug} />
}

export default AsksPaywall
