import React from 'react'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { useCurrentUser } from 'store/hooks'

const SkeletalComposer = () => {
  const { user } = useCurrentUser()
  const { theme } = user

  const baseColor = theme === 'light' ? '#F3F4F6' : '#293342'
  const highlightColor = theme === 'light' ? '#e0e0e0' : '#444'

  return (
    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
      <div className="flex flex-col h-full">
        <Skeleton className="flex-grow-0" height={37} count={4} containerClassName="" />
        <Skeleton className="h-full" containerClassName="flex-1" />
        <div className="flex items-center justify-between mt-1">
          <Skeleton
            className="flex-grow-0"
            height={27}
            width={27}
            count={3}
            containerClassName="flex flex-row gap-1 mt-1"
          />
          <div className="flex items-center flex-row gap-3">
            <Skeleton className="flex-grow-0" height={27} width={173} count={1} />
            <Skeleton className="flex-grow-0" height={27} width={169} count={1} />
            <Skeleton className="flex-grow-0" height={37} width={67} count={1} />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  )
}

export default SkeletalComposer
