import React, { useState } from 'react'

import styled from 'styled-components'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

import Header from './Header'
import { Card, ContinueWrapper } from './styles'

const OptionWrap = styled.div<{ active: boolean }>`
  background: ${({ theme }) => theme.colors.option_card_bg};
  border: 1px solid ${({ theme, active }) => (active ? theme.colors.purple : theme.colors.border)};
`

interface Props {
  onContinue: () => void
}

const Option = ({
  onClick,
  title,
  icon,
  active,
  testId,
}: {
  onClick: () => void
  title: string
  icon: string
  active: boolean
  testId?: string
}) => {
  return (
    <OptionWrap
      active={active}
      className="flex flex-col cursor-pointer h-[120px] p-4 rounded-lg"
      onClick={onClick}
      data-testid={testId}
    >
      <Typography color={active ? 'purple' : 'fog'} fontSize="32">
        <i className={icon}></i>
      </Typography>
      <Typography
        color={active ? 'purple' : 'fog'}
        fontWeight={active ? 600 : 400}
        fontSize="14"
        className="mt-2"
        lineHeight={1.2}
      >
        {title}
      </Typography>
    </OptionWrap>
  )
}

const CompanyOptions: React.FC<Props> = ({ onContinue }) => {
  const [selected, setSelected] = useState<string[]>([])

  const handleOptionClick = (title: string) => {
    setSelected((prev) => {
      if (prev.includes(title)) {
        return prev.filter((t) => t !== title)
      } else {
        return [...prev, title]
      }
    })
  }

  return (
    <Card>
      <Header title="What would you like to do first?" subtitle="" />
      <div className="grid gap-1 sm:gap-4 grid-cols-3 my-6 mx-0">
        <Option
          title="Sales referrals"
          icon="fa-thin fa-sack-dollar"
          onClick={() => handleOptionClick('sales')}
          active={selected.includes('sales')}
          testId="sales-referrals"
        />
        <Option
          title="Monthly updates"
          icon="fa-thin fa-envelope"
          onClick={() => handleOptionClick('updates')}
          active={selected.includes('updates')}
          testId="monthly-updates"
        />
        <Option
          title="Customer portal"
          icon="fa-thin fa-medal"
          onClick={() => handleOptionClick('customer_portal')}
          active={selected.includes('customer_portal')}
          testId="customer-portal"
        />
        <Option
          title="Advisor program"
          icon="fa-thin fa-coin"
          onClick={() => handleOptionClick('advisor_program')}
          active={selected.includes('advisor_program')}
          testId="advisor-program"
        />
        <Option
          title="Fundraise intros"
          icon="fa-thin fa-money-check-dollar-pen"
          onClick={() => handleOptionClick('fundraise')}
          active={selected.includes('fundraise')}
          testId="fundraise-intros"
        />
        <Option
          title="Find talent"
          icon="fa-thin fa-telescope"
          onClick={() => handleOptionClick('find_talent')}
          active={selected.includes('find_talent')}
          testId="find-talent"
        />
      </div>
      <ContinueWrapper>
        <CabalButton
          onClick={onContinue}
          padding="8px 32px"
          disabled={selected.length === 0}
          data-testid="company-continue"
        >
          Continue
        </CabalButton>
      </ContinueWrapper>
    </Card>
  )
}

export default CompanyOptions
