import React, { useEffect, useRef } from 'react'
import Quill, { Delta, RangeStatic, Sources } from 'quill'
window.Quill = Quill
import ImageUploader from 'quill-image-uploader'
import ImageResize from 'quill-image-resize'
import S3Upload from 'react-s3-uploader/s3upload'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { throttle, debounce } from 'lodash'
import ResizeSensor from 'resize-sensor'
import { cabalToast } from 'ui-components/Toast'
import { EmailTemplate } from 'utils/types'
import { QuillContainer } from '../quillThemes'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import DropDownMenu from 'global/DropDownMenu'
import { useCurrentUser } from 'store/hooks'
import { selectCurrentUser } from 'store/reducers/currentUserReducer'
import { quillDecodeIndent, quillEncodeIndent } from './quill-fix-indent'
import Tooltip from 'global/Tooltip'

import * as Emoji from 'quill-emoji'
import 'quill-emoji/dist/quill-emoji.css'
import { useDebounce } from 'react-use'
import copyToClipboard from 'utils/copyToClipboard'

Quill.register('modules/emoji', Emoji)

const DirectionAttribute = Quill.import('attributors/attribute/direction')
Quill.register(DirectionAttribute, true)

const AlignClass = Quill.import('attributors/class/align')
Quill.register(AlignClass, true)

const BackgroundClass = Quill.import('attributors/class/background')
Quill.register(BackgroundClass, true)

const ColorClass = Quill.import('attributors/class/color')
Quill.register(ColorClass, true)

const DirectionClass = Quill.import('attributors/class/direction')
Quill.register(DirectionClass, true)

const FontClass = Quill.import('attributors/class/font')
Quill.register(FontClass, true)

const SizeClass = Quill.import('attributors/class/size')
Quill.register(SizeClass, true)

const AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)

const BackgroundStyle = Quill.import('attributors/style/background')
Quill.register(BackgroundStyle, true)

const ColorStyle = Quill.import('attributors/style/color')
Quill.register(ColorStyle, true)

const DirectionStyle = Quill.import('attributors/style/direction')
Quill.register(DirectionStyle, true)

const FontStyle = Quill.import('attributors/style/font')
Quill.register(FontStyle, true)

const SizeStyle = Quill.import('attributors/style/size')
Quill.register(SizeStyle, true)

//const Block = Quill.import('blots/block');
//Block.tagName = 'DIV';
//Quill.register(Block, true);

const Link = Quill.import('formats/link')
Link.sanitize = (s: string) => {
  if (!s.match(/^https?:\/\//) && !s.startsWith('mailto:')) {
    s = 'http://' + s
  }
  return s
}

const Parchment = Quill.import('parchment')
class IndentAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    if (value === 0) {
      this.remove(node)
      return true
    } else {
      return super.add(node, `${value}em`)
    }
  }
}

// const IndentStyle = new IndentAttributor('indent', 'text-indent', {
//   scope: Parchment.Scope.BLOCK,
//   whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em'],
// })
// Quill.register(IndentStyle, true)

// Quill.register(
//   {
//     'modules/better-table': QuillBetterTable,
//   },
//   true,
// )

class CabalButtonArea extends Parchment.Embed {
  static create(value) {
    const node = super.create(value)
    node.innerHTML = value
    node.contentEditable = 'false'
    return node
  }

  static value(node) {
    return node.innerHTML
  }
}
CabalButtonArea.blotName = 'cabalButton'
CabalButtonArea.tagName = 'SECTION'
CabalButtonArea.className = 'cabal-button-area'

Quill.register(CabalButtonArea)

class StickyToolbar {
  quill: Quill
  toolbar: any
  constructor(quill: Quill, _props: any) {
    this.quill = quill
    this.toolbar = quill.getModule('toolbar')
    const obj = this.toolbar.container
    const editorParent = obj.parentNode.parentNode.parentNode //ql-toolbar=>div=>ng-quill=>div
    const objTop = obj.getBoundingClientRect().top
    // let tempObj = obj
    let isSticky = false
    // while (tempObj.offsetParent) {
    //   objTop += tempObj.offsetParent.offsetTop
    //   if (tempObj === document.getElementsByTagName('body')[0]) {
    //     break
    //   } else {
    //     tempObj = tempObj.offsetParent
    //   }
    // }
    window.addEventListener(
      'scroll',
      throttle((evt) => {
        if (evt.target) {
          if (
            !quill.hasFocus() ||
            evt.target.classList.contains('resize-sensor-expand') ||
            evt.target.classList.contains('resize-sensor-shrink')
          ) {
            return
          }
        }
        const distanceFromTop = evt.target.scrollTop
        if (
          distanceFromTop > objTop + 100 &&
          distanceFromTop < objTop + editorParent.getBoundingClientRect().height
        ) {
          if (isSticky) return
          isSticky = true
          obj.style.position = 'fixed'
          obj.style.top = '0px'
          obj.style.width = editorParent.clientWidth + 'px'
          obj.style.zIndex = '5'
        } else {
          if (!isSticky) return
          isSticky = false
          obj.style.position = 'relative'
          obj.style.width = editorParent.clientWidth + 'px'
          obj.style.top = '0px'
        }
      }, 500),
      true,
    )

    new ResizeSensor(editorParent, function () {
      obj.style.width = editorParent.clientWidth + 'px'
    })
  }
}

// Quill.register('modules/sticky_toolbar', StickyToolbar)
Quill.register('modules/imageUploader', ImageUploader)
Quill.register('modules/imageResize', ImageResize)

export interface TextEditorProps {
  value?: string
  onChange?: (val: string) => void
  onTyping?: (isTyping: boolean) => void
  variables?: string[]
  allowVariables?: boolean
  templates?: EmailTemplate[]
  companySlug?: string
  [key: string]: unknown
  message_uuid?: string
}

const TextEditor: React.FC<TextEditorProps> = ({
  value: _value,
  onChange,
  onTyping,
  variables = ['{{first_name}}', '{{full_name}}'],
  allowVariables = false,
  templates,
  companySlug,
  message_uuid,
  ...extraProps
}) => {
  const history = useHistory()
  const editorIdRef = React.useRef(`editor${new Date().getTime()}`)
  const quillRef = React.useRef<Quill>()
  const editorId = editorIdRef.current
  const toolbarId = editorId + 'toolbar'
  const lastCursorRef = React.useRef<RangeStatic | null>(null)

  // const codoxRef = React.useRef<any>(null)
  const value = quillEncodeIndent(_value || '')

  const {
    user: { name, avatar_url, email_signature },
  } = useCurrentUser()

  useEffect(() => {
    initQuill()
  }, [])

  useEffect(() => {
    if (!quillRef.current) return
    if (value === quillRef.current?.root.innerHTML) return
    const delta = quillRef.current.clipboard.convert(value)
    quillRef.current.setContents(delta, 'silent')
    // codoxRef.current?.forceSync(delta)
  }, [value])

  useEffect(() => {
    const tabHideEls = document.querySelectorAll('.ql-toolbar .ql-picker-label')
    tabHideEls.forEach(function (item) {
      item.setAttribute('tabindex', -1)
    })

    const tabHideEls2 = document.querySelectorAll('.ql-toolbar button')
    tabHideEls2.forEach(function (item) {
      item.setAttribute('tabindex', -1)
    })
  }, [quillRef])

  const updateCursorRef = () => {
    if (!quillRef.current) return
    lastCursorRef.current = quillRef.current.getSelection()
  }

  const onUploadFinish = async (data: { signedUrl: string }) => {
    const url = data.signedUrl

    const {
      data: { uuid },
    } = await axios.post(`/api/uploads`, {
      url,
    })

    return `${location.protocol}//${location.host}/api/uploads/${uuid}?cached=1`
  }

  const triggerChange = () => {
    const html = quillDecodeIndent(quillRef.current?.root.innerHTML || '')
    onChange?.(html)
  }

  const initQuill = () => {
    quillRef.current = new Quill('#' + editorId, {
      theme: 'snow',
      modules: {
        toolbar: '#' + toolbarId,
        'emoji-toolbar': true,
        'emoji-shortname': true,
        // sticky_toolbar: true,
        // clipboard: {
        //   matchVisual: false,
        // },
        // table: false,
        // 'better-table': {
        //   operationMenu: {
        //     items: {},
        //   },
        // },
        // keyboard: {
        //   bindings: QuillBetterTable.keyboardBindings,
        // },
        imageUploader: {
          upload: (file: File) => {
            return new Promise<string>((resolve, reject) => {
              new S3Upload({
                files: [file],
                signingUrl: `/api/uploads/new`,
                signingUrlMethod: 'GET',
                contentDisposition: 'attachment',
                onError: (err) => {
                  reject(err)
                },
                onFinishS3Put: async (data: { signedUrl: string }) => {
                  try {
                    resolve(await onUploadFinish(data))
                  } catch (e) {
                    reject(e)
                  }
                },
                uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
                server: '',
                scrubFilename: (filename) => {
                  return filename?.replace(/[^\w\d_\-\.]+/gi, '')
                },
              })
            })
          },
        },
        imageResize: {
          modules: ['Resize', 'DisplaySize', 'Toolbar'],
        },
      },
    })
    quillRef.current.on('selection-change', updateCursorRef)
    quillRef.current.on('text-change', (_delta: any, _oldContents: any, source: Sources) => {
      // const length = quillRef.current?.getLength()
      // if (length) {
      //   quillRef.current?.formatText(0, length, {
      //     color: false,
      //     background: false,
      //   })
      // }

      onTyping?.(true)
      if (source === 'user') debouncedChange.current()
    })
    //quillRef.current.on('editor-change', updateCursorRef)
  }

  const debouncedChange = useRef(
    debounce(() => {
      triggerChange()
      onTyping?.(false)
      //updateCursorRef
    }, 500),
  )

  useEffect(() => {
    if (quillRef.current && message_uuid) {
      // const config = {
      //   app: 'quilljs',
      //   editor: quillRef.current,
      //   docId: message_uuid, //this is the unique id used to distinguish different documents
      //   user: {
      //     name: name,
      //     avatar_url: avatar_url,
      //   }, //unqiue user name
      //   apiKey: window.CODOX_KEY,
      // }
      // codoxRef.current = new window.Codox()
      // codoxRef.current?.init(config)
    }

    return () => {
      // codoxRef.current?.stop()
    }
  }, [quillRef.current, message_uuid])

  const insertTable = () => {
    const tableModule = quillRef.current?.getModule('better-table')
    tableModule.insertTable(3, 3)
  }

  return (
    <QuillContainer color="primary" className="list-outside" {...extraProps}>
      <div id={toolbarId} className="flex justify-between clear-none">
        <div className="flex items-center">
          <span className="ql-formats">
            <select className="ql-header" />
            <select className="ql-color" />
            <select className="ql-background" />
          </span>
          <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-strike" />
          </span>
          <span className="ql-formats">
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <select className="ql-align">
              <option />
              <option value="center" />
              <option value="right" />
              <option value="justify" />
            </select>
          </span>
          <span className="ql-formats">
            <button className="ql-link" />
            <button className="ql-image" />
          </span>
          {/* <span className="ql-formats">
            <button onClick={insertTable}>
              <i className="far fa-table" />
            </button>
          </span> */}
          <span className="ql-formats">
            <button className="ql-clean" />
            <button className="ql-emoji" />
          </span>
        </div>
        <div className="relative">
          {email_signature && (
            <Tooltip label="Append email signature">
              <button
                onClick={() => {
                  const signature = quillRef.current?.clipboard.convert(`
                  <p><br/></p>
                  <p><br/></p>
                  <p>--</p>
                  ${email_signature}
                  `)
                  if (!signature || !quillRef.current) return

                  const content = quillRef.current.clipboard.convert(value)
                  const delta = content.retain(content.length()).concat(signature)
                  // codoxRef.current?.forceSync(delta)
                  quillRef.current.setContents(delta)
                  triggerChange()
                }}
              >
                <i className="far fa-signature" />
              </button>
            </Tooltip>
          )}
          {allowVariables && (
            <DropDownMenu
              portal={false}
              trigger={
                <Tooltip label="Variables">
                  <i className="far fa-brackets-curly" />
                </Tooltip>
              }
              menuItems={variables.map((variable) => ({
                label: variable,
                onSelect: () => {
                  copyToClipboard(variable)
                  cabalToast({ style: 'success', content: `Copied ${variable} to clipboard` })
                  //quillRef.current?.insertText(lastCursorRef.current, variable)
                },
              }))}
            />
          )}
        </div>
      </div>
      <div id={editorId} />
    </QuillContainer>
  )
}

export default TextEditor
