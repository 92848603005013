import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'

import advisorsReducer from './reducers/advisorsReducer'
import appReducer from './reducers/appReducer'
import companiesReducer from './reducers/companiesReducer'
import currentUserReducer from './reducers/currentUserReducer'
import groupsReducer from './reducers/groupsReducer'
import teamsReducer from './reducers/teamsReducer'
import uniqueAccessLinkReducer from './reducers/uniqueAccessLinkReducer'

export const store = configureStore({
  reducer: {
    currentUser: currentUserReducer,
    teams: teamsReducer,
    advisors: advisorsReducer,
    groups: groupsReducer,
    app: appReducer,
    uniqueAccessLinks: uniqueAccessLinkReducer,
    companies: companiesReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
