import React from 'react'

import { MessageThread } from 'utils/types/message'

import MessageContent from '../MessageContent'
import ReplyMessage from '../ReplyMessage'
import { MessageContainer, ReplyContainer } from '../styles'

interface Props {
  reloadMessage: () => void
  messageThread: MessageThread
  companySlug: string
}

const ThreadView: React.FC<Props> = ({ messageThread, reloadMessage, companySlug }) => {
  const replyMessages = messageThread.reply_messages

  return (
    <>
      {replyMessages.map((m, i) => {
        return (
          <MessageContainer key={m.uuid}>
            <MessageContent
              message={m}
              allowExpansion
              isExpanded={i === replyMessages.length - 1}
            />
          </MessageContainer>
        )
      })}

      <ReplyMessage
        companySlug={companySlug}
        messageThread={messageThread}
        onSend={reloadMessage}
      />
    </>
  )
}

export default ThreadView
