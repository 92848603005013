import React, { useMemo, useState } from 'react'
import { GroupModel } from 'utils/types'
import GroupCard from './GroupCard'

interface Props {
  groups: GroupModel[]
  teamSlug: string
}

const CardView: React.VFC<Props> = ({ groups, teamSlug }) => {
  return (
    <>
      {groups.map((g) => (
        <GroupCard key={g.uuid} group={g} teamSlug={teamSlug} />
      ))}
    </>
  )
}

export default CardView
