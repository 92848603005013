import React, { useEffect } from 'react'

import { useQuery } from 'react-query'

import SkeletonWidget from 'containers/CompanyPage/SkeletonWidget'
import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import SalesCompanyRow from 'containers/ListIndexView/SalesList/SalesCompanyList/SalesCompanyMemberView/SalesCompanyRow'
import { ISalesCompany } from 'containers/ListIndexView/SalesList/type'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useCurrentUser, useTeamSlug } from 'store/hooks'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'

function CompanyWidget(props: { company: ISalesCompany; list_uuid: string }) {
  const teamSlug = useTeamSlug()
  const { user } = useCurrentUser()
  const {
    data: connections,
    refetch: reloadConnections,
    isLoading: isConnectionsLoading,
  } = useQuery([teamSlug, 'getUserConnections', user.uuid, props.company.uuid], () =>
    callApi(api.salesGetConnections, props.list_uuid, props.company.uuid),
  )

  const [displayedConnections, setDisplayedConnections] = React.useState([])

  useEffect(() => {
    if (connections) {
      setDisplayedConnections(connections.slice(0, 5))
    }
  }, [connections])

  if (isConnectionsLoading) {
    return <SkeletonWidget />
  }

  return (
    <Widget
      title={props.company.name}
      className=""
      avatar={<Avatar src={`https://logo.clearbit.com/${props.company.domain}` || props.company.logo_url} name={props.company.name} size="32" />}
    >
      {displayedConnections?.map((connection) => (
        <div key={connection.id}>
          <SalesCompanyRow person={connection} list_uuid={props.list_uuid} />
        </div>
      ))}

      {connections?.length > 5 && displayedConnections?.length <= 5 && (
        <CabalButton
          variant="link"
          padding="0"
          onClick={() => {
            setDisplayedConnections(connections)
          }}
        >
          See all {connections.length} connections
        </CabalButton>
      )}
      {connections?.length > 5 && displayedConnections?.length > 5 && (
        <CabalButton
          variant="link"
          padding="0"
          onClick={() => {
            setDisplayedConnections(connections.slice(0, 5))
          }}
        >
          Show less
        </CabalButton>
      )}
    </Widget>
  )
}

const SalesCompanyMemberView = () => {
  const { salesCompanies, companyListData } = useSalesListContext()

  return (
    <div className="flex flex-col gap-3">
      {salesCompanies.map((company) => (
        <CompanyWidget key={company.id} company={company} list_uuid={companyListData.uuid} />
      ))}
    </div>
  )
}

export default SalesCompanyMemberView
