import React from 'react'

import { TextInput } from 'global/Input'
import Modal from 'global/Modal'
import CabalButton from 'global/CabalButton'
import { TalentPOC } from 'utils/types/investor'
import { useSetState } from 'react-use'

interface Props {
  onHide: () => void
  field: string
  isLoading: boolean
  handleUpdate: ({ action, talent_poc }: { action: string; talent_poc: TalentPOC }) => void
}

const BulkEditJobsModal: React.VFC<Props> = ({ onHide, field, isLoading, handleUpdate }) => {
  const [talentPOC, setTalentPOC] = useSetState<TalentPOC>()

  const getModalContent = (field: string) => {
    switch (field) {
      case 'talent_poc':
        return (
          <>
            <TextInput
              value={talentPOC.email}
              className="w-full mt-3"
              valueType="non-empty"
              onChange={(e) =>
                setTalentPOC({
                  email: e.target.value || undefined,
                })
              }
              placeholder="Email"
              data-testid="bulk-edit-jobs-modal-talent-poc-email"
            />
            <TextInput
              value={talentPOC.first_name}
              className="w-full mt-3"
              valueType="non-empty"
              onChange={(e) =>
                setTalentPOC({
                  first_name: e.target.value || undefined,
                })
              }
              placeholder="First Name"
              data-testid="bulk-edit-jobs-modal-talent-poc-first-name"
            />
            <TextInput
              value={talentPOC.last_name}
              className="w-full mt-3"
              valueType="non-empty"
              placeholder="Last Name"
              onChange={(e) =>
                setTalentPOC({
                  last_name: e.target.value || undefined,
                })
              }
              data-testid="bulk-edit-jobs-modal-talent-poc-last-name"
            />
            <TextInput
              value={talentPOC.linkedin_url}
              className="w-full mt-3"
              valueType="website"
              placeholder="LinkedIn Profile URL (Optional)"
              onChange={(e) =>
                setTalentPOC({
                  linkedin_url: e.target.value || undefined,
                })
              }
              data-testid="bulk-edit-jobs-modal-talent-poc-linkedin-url"
            />
          </>
        )
    }
  }

  const handleClick = () => {
    switch (field) {
      case 'talent_poc':
        handleUpdate({ action: field, talent_poc: talentPOC })
        break
    }
  }

  const disabled =
    field === 'talent_poc' && (!talentPOC.first_name || !talentPOC.last_name || !talentPOC.email)

  return (
    <Modal
      show
      onHide={onHide}
      header="Bulk Update"
      rightActions={
        <CabalButton
          onClick={() => handleClick()}
          working={isLoading}
          disabled={isLoading || disabled}
          data-testid="bulk-edit-jobs-modal-save-btn"
        >
          Save
        </CabalButton>
      }
    >
      {getModalContent(field)}
    </Modal>
  )
}

export default BulkEditJobsModal
