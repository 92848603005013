import React, { useState } from 'react'

import { compact } from 'lodash'
import { useMutation } from 'react-query'

import CabalButton from 'global/CabalButton'
import { CheckBox, MultiSelect, SelectOption, TextArea } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import copyToClipboard from 'utils/copyToClipboard'
import { CompanyListBlueprint } from 'utils/types'

import { AccessorEntityMap } from './ListSharingSettings'

interface Props {
  onHide: () => void
  list: CompanyListBlueprint
  accessorEntityMap: AccessorEntityMap
  options: SelectOption<string>[]
  onAdd: () => void
  accessorKey?: string
  teamSlug: string
}

const AddAccessorModal: React.VFC<Props> = ({
  onHide,
  accessorEntityMap,
  list,
  options,
  onAdd,
  accessorKey,
  teamSlug,
}) => {
  const [selectedAccessors, setSelectedAccessors] = useState(compact([accessorKey]))
  const [notify, setNotify] = useState(true)
  const [message, setMessage] = useState('')

  const addAccessorsMutation = useMutation(
    ['addListAccessor', teamSlug],
    () =>
      callApi(api.addListAccessors, teamSlug, list.uuid, {
        notify,
        message,
        accessors: selectedAccessors.map((k) => {
          const entityMap = accessorEntityMap[k]

          return {
            accessor_type: entityMap.type,
            accessor_uuid: entityMap.uuid,
          }
        }),
      }),
    {
      onSuccess: () => {
        onAdd()
        onHide()
      },
    },
  )

  return (
    <Modal
      animateOnMount
      header={
        <div className="flex items-center gap-2">
          <CabalButton
            onClick={onHide}
            leftIcon={<i className="far fa-arrow-left" />}
            size="small"
            className="-ml-2"
            variant="tertiary"
          />
          <Typography fontSize="12" fontWeight={600}>
            Share '{list.name}'
          </Typography>
        </div>
      }
      onHide={onHide}
      show
      leftActions={
        <CabalButton
          variant="secondary"
          leftIcon={<i className="far fa-link" />}
          onClick={() =>
            copyToClipboard(`${window.location.origin}/${teamSlug}/lists/${list.slug}`, 'Copied!')
          }
        >
          Copy link
        </CabalButton>
      }
      rightActions={
        <div className="space-x-2">
          <CabalButton variant="tertiary" onClick={onHide}>
            Cancel
          </CabalButton>
          <CabalButton
            onClick={() => {
              addAccessorsMutation.mutate()
            }}
            disabled={!selectedAccessors.length}
            working={addAccessorsMutation.isLoading}
          >
            {notify ? 'Send' : 'Share'}
          </CabalButton>
        </div>
      }
    >
      <ModalSectionWrapper>
        <MultiSelect
          options={options}
          value={selectedAccessors}
          onChange={(v) => setSelectedAccessors(v)}
        />
        <CheckBox
          checked={notify}
          onChange={(e) => setNotify(e.currentTarget.checked)}
          label="Notify people"
        />
        {notify && (
          <TextArea
            className="w-full"
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
            placeholder="Message"
          />
        )}
      </ModalSectionWrapper>
    </Modal>
  )
}

export default AddAccessorModal
