import * as React from 'react'
import Modal from 'global/Modal'
import { TextInput, Select } from 'global/Input'
import styled from 'styled-components'
import CabalButton from 'global/CabalButton'
import { v4 as uuid } from 'uuid'
import { ButtonModel } from 'utils/types'
import {
  ModalDivider,
  ModalInputLabel,
  ModalInputWrapper,
  ModalSectionWrapper,
} from 'global/Modal/styles'

interface Props {
  show: boolean
  onHide: () => void
  onUpdate: (inputs: ButtonModel[]) => void
  inputs: ButtonModel[]
}

const generateEmptyButton = () => {
  return {
    key: uuid(),
    type: 'button',
    text: '',
    action: 'link',
    url: '',
  }
}

const Divider = styled.div`
  border-bottom: ${({ theme }) => theme.border};
`

const ButtonsModal: React.VFC<Props> = (props) => {
  const [draftInputs, setDraftInputs] = React.useState([...props.inputs])

  React.useEffect(() => {
    if (draftInputs.length == 0) {
      setDraftInputs([generateEmptyButton()])
    }
  }, [props.inputs])

  function onSubmit(e: React.SyntheticEvent) {
    e.preventDefault()

    props.onUpdate(draftInputs.filter((input) => input.text != ''))
    props.onHide()
  }

  function addEmptyButton(e: React.SyntheticEvent) {
    e.preventDefault()

    setDraftInputs([...draftInputs, generateEmptyButton()])
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      header="Add CTA Button"
      rightActions={<CabalButton onClick={onSubmit}>Save</CabalButton>}
      leftActions={<CabalButton onClick={addEmptyButton}>Add Button</CabalButton>}
    >
      {draftInputs.map((draftInput, i) => (
        <ButtonRow
          key={draftInput.key}
          last={i == draftInputs.length - 1}
          value={draftInput}
          onChange={(draftInput: any) => {
            setDraftInputs([
              ...draftInputs.filter((input) => input.key != draftInput.key),
              draftInput,
            ])
          }}
          onRemove={() => {
            setDraftInputs(draftInputs.filter((input) => input.key != draftInput.key))
          }}
        />
      ))}
    </Modal>
  )
}

interface ButtonRowProps {
  value: ButtonModel
  onChange: (v: ButtonModel) => void
  onRemove: () => void
  last: boolean
}

const ButtonRow: React.VFC<ButtonRowProps> = ({ value, onChange, onRemove, last }) => {
  return (
    <ModalSectionWrapper>
      <ModalInputWrapper>
        <ModalInputLabel>Button Label</ModalInputLabel>
        <TextInput
          className="w-full"
          placeholder="Label"
          value={value.text}
          onChange={(e) => onChange({ ...value, text: e.target.value })}
          autoFocus
        />
      </ModalInputWrapper>
      <ModalInputWrapper>
        <ModalInputLabel>Button Action</ModalInputLabel>
        <Select
          value={'link'}
          options={[
            {
              label: 'Go to URL',
              value: 'link',
            },
          ]}
          onChange={(v) => v && onChange({ ...value, action: v })}
        />
      </ModalInputWrapper>
      <ModalInputWrapper>
        <ModalInputLabel>Destination</ModalInputLabel>
        <TextInput
          value={value.url}
          className="w-full"
          placeholder="Destination URL"
          onChange={(e) => onChange({ ...value, url: e.target.value })}
        />
      </ModalInputWrapper>
      <ModalInputWrapper>
        <ModalInputLabel></ModalInputLabel>
        <div>
          <CabalButton
            variant="tertiary"
            tooltip="Remove Button"
            leftIcon={<i className="fa fa-trash" onClick={onRemove} />}
          />
        </div>
      </ModalInputWrapper>
      {!last && <ModalDivider />}
    </ModalSectionWrapper>
  )
}

export default ButtonsModal
