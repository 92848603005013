import * as React from 'react'

import Expand from 'react-expand-animated'
import styled from 'styled-components/macro'

import CabalButton from 'global/CabalButton'

import GoogleIcon from '../../../images/google-icon.png'
import Logo from '../../../images/logo.svg'
import QR from '../../../images/qr.png'
import Calendly from './calendly'
import { H3 } from './styles'

const NavLink = styled.a`
  color: #000 !important;
  font-family: 'JetBrains Mono' !important;
  font-size: 14px !important ;

  /* @media (prefers-color-scheme: dark) {
    color: #fff !important;
  } */

  &:hover {
    color: #5c69d1 !important;
  }
`

const SignUp = styled.button`
  font-family: 'JetBrains Mono' !important;
  margin: 0px !important;
  height: 40px !important;
  margin-right: 16px !important;
`

const MenuButton = styled.button`
  margin: 0px !important;
  padding: 0px !important;

  height: 40px !important;
  width: 40px !important;

  background-color: transparent !important ;
  color: #000 !important;
`

const NavContainer = styled.div`
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.7);

  backdrop-filter: blur(24px);
  z-index: 20;
  width: 100%;
  padding: 0 20px;

  /* @media (prefers-color-scheme: dark) {
    background: #0e151f;
  } */
`

const MobilePopup = styled.div`
  position: relative;
  cursor: pointer;
  color: #5c69d1;

  &:hover {
    color: #2f3ca7;
  }
`

const PurpleLink = styled(NavLink)`
  color: #5c69d1 !important;

  &:hover {
    color: #2f3ca7 !important;
  }
`

const PopupDialog = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 200px;
  height: 230px;
  border-radius: 8px;
  background-color: #f9fafb;

  top: 40px;
  left: -85px;
  padding: 24px;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;

  /* @media (prefers-color-scheme: dark) {
    background: #1f2835;
  } */

  ${MobilePopup}:hover & {
    visibility: visible;
    opacity: 1;
  }
`

const SolutionsDialog = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 125px;
  border-radius: 8px;
  background: #f9fafb;

  top: 30px;
  left: -10px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;

  /* @media (prefers-color-scheme: dark) {
    background: #1f2835;
  } */

  ${MobilePopup}:hover & {
    visibility: visible;
    opacity: 1;
  }
`

const PopupText = styled.p`
  font-family: 'JetBrains Mono';
  font-size: 12px;
  padding-top: 30px;
`

const DesktopWrap = styled.nav`
  max-width: 1068px;
  display: none;

  @media (min-width: 850px) {
    display: grid;
  }
`

const MobileWrap = styled.nav`
  max-width: 1068px;
  display: flex;

  @media (min-width: 850px) {
    display: none;
  }
`

const MobileNav = styled.div`
  /* position: absolute; */
  height: calc(100vh - 80px);

  /* background-color: #fefefe; */
`

interface Props {
  hideLogin?: boolean
}

const Nav: React.FC<Props> = ({ hideLogin = false }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <NavContainer>
      {/* Desktop Nav */}
      <DesktopWrap className="items-center grid-cols-3 mx-auto py-5">
        <a href="/" className="flex items-center cursor-pointer">
          <img src={Logo} alt="Cabal" height="32" width="27" />
          <H3>Cabal</H3>
        </a>
        <div className="space-x-12 justify-self-end flex"></div>
        <div className="flex items-center space-x-12 justify-self-end">
          <NavLink href="/companies">For Companies</NavLink>
          <NavLink href="/investors">For Investors</NavLink>
          <NavLink href="/demo">Demo</NavLink>
          <a style={{ border: 0, outline: 'none' }} href="/login">
            <SignUp>Login</SignUp>
          </a>
        </div>
      </DesktopWrap>

      {/* Mobile Nav */}
      <MobileWrap className="items-center justify-between mx-auto py-5 relative">
        <a href="/" className="flex items-center cursor-pointer">
          <img src={Logo} alt="Cabal" height="32" width="27" />
          <H3>Cabal</H3>
        </a>

        <div className="flex items-center -mr-2">
          <div>
            <a style={{ border: 0, outline: 'none' }} href="/login">
              <SignUp>Sign up</SignUp>
            </a>
          </div>

          <MenuButton onClick={() => setOpen(!open)}>
            {open ? <i className="fal fa-times fa-fw"></i> : <i className="fal fa-bars fa-fw"></i>}
          </MenuButton>
        </div>
      </MobileWrap>
      <Expand open={open}>
        <MobileNav className="flex flex-col pt-10 space-y-10">
          <NavLink href="/solutions">{`Solutions`}</NavLink>
          <NavLink href="/blog">{`Blog`}</NavLink>
          <Calendly />
          <PurpleLink href="/app">
            Download the app
            <i className="far fa-mobile ml-2"></i>
          </PurpleLink>

          <div>
            <a style={{ border: 0, outline: 'none' }} href="/users/auth/google_oauth2">
              <CabalButton variant="auth" leftIcon={<img src={GoogleIcon} width="18" />}>
                Sign in with Google
              </CabalButton>
            </a>

            {/* <a style={{ border: 0, outline: 'none' }} href="/auth/microsoft">
              <CabalButton variant="auth" leftIcon={<img src={MicrosoftIcon} width="18" />}>
                Sign in with Microsoft
              </CabalButton>
            </a> */}
          </div>
        </MobileNav>
      </Expand>
    </NavContainer>
  )
}

export default Nav
