import React, { useState } from 'react'

import Modal from 'global/Modal'

import { GroupModel, ImportedMember } from 'utils/types'

import Preview from './Preview'
import Upload from './Upload'

interface Props {
  onHide: () => void
  teamSlug: string
  group: GroupModel
}

const ImportMembersModal: React.FC<Props> = ({ onHide, teamSlug, group }) => {
  const [step, setStep] = useState(0)
  const [members, setMembers] = useState<ImportedMember[]>([])
  const [importedColumns, setImportedColumns] = useState<string[]>([])
  const [advisorImportUuid, setAdvisorImportUuid] = useState<string>()
  const [invitePostImport, setInvitePostImport] = useState(false)

  const uploadData = (
    <Upload
      setImportedColumns={setImportedColumns}
      setMembers={(m) => {
        setMembers(m)
        if (step === 0) setStep(1)
      }}
      setInvitePostImport={setInvitePostImport}
      invitePostImport={invitePostImport}
      teamSlug={teamSlug}
      heading={''}
      advisorImportUuid={advisorImportUuid}
      setAdvisorImportUuid={setAdvisorImportUuid}
      canSkip={false}
      previewInModal={true}
    />
  )

  const preview = (
    <Preview
      importedColumns={importedColumns}
      companySlug={teamSlug}
      members={members}
      groups={[group]}
      destination={undefined}
      teamSlug={teamSlug}
      advisorImportUuid={advisorImportUuid!}
      invitePostImport={invitePostImport}
      previewInModal={true}
      onHide={onHide}
    />
  )

  return (
    <div>
      <Modal show onHide={onHide} header="Add Members">
        {step === 0 && uploadData}
        {step === 1 && preview}
      </Modal>
    </div>
  )
}

export default ImportMembersModal
