import React, { useEffect, useState } from 'react'

import { components } from 'react-select'
import styled from 'styled-components'

import { IRequestorOptions } from 'containers/IntroRequestModal/components/types'
import Avatar from 'global/Avatar'
import { Select } from 'global/Input'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'

const StyledText = styled(Typography)<{ rowCount: number }>`
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 100%;
  height: auto;
`

const CustomSelect = styled(Select)`
  .select__control {
    border: none;
    background: transparent;
    box-shadow: none !important;
    width: 85%;
    margin: 0 auto;
  }
  .select__value-container {
    justify-content: center;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .select__single-value {
    text-align: center;
    width: 100%;
    text-overflow: ellipsis;
  }
`

const SingleValue = (props: any) => (
  <components.SingleValue {...props}>
    <StyledText rowCount={1} fontSize="16" lineHeight="1.5">
      {props.children}
    </StyledText>
  </components.SingleValue>
)

interface Props {
  title: string | null
  description: string | null
  avatar: string | null
  itemType: string | null
  userUuid?: string
  showSelector: boolean
  options?: IRequestorOptions[]
  selectedValue?: (value: string) => void
}

const UserInfo: React.FC<Props> = ({
  title,
  description,
  avatar,
  itemType,
  userUuid,
  showSelector,
  options,
  selectedValue,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  useEffect(() => {
    if (options && userUuid) {
      const selected = options.find((option) => option.uuid === userUuid)
      if (selected) {
        setSelectedOption(selected.uuid)
      }
    }
  }, [userUuid, options])

  const handleSelectChange = (e: any) => {
    setSelectedOption(e)
    if (selectedValue) {
      selectedValue(e)
    }
  }

  const showSelect = showSelector && options && options.length > 1

  return (
    <div className="flex flex-col flex-shrink-0 items-center justify-center gap-2 w-[160px]">
      <Avatar name={title || ''} src={avatar || ''} size="40" />
      {showSelect ? (
        <CustomSelect
          value={selectedOption}
          onChange={handleSelectChange}
          options={
            options?.map((option: IRequestorOptions) => ({
              label: `${option.name}`,
              value: option.uuid,
            })) || []
          }
          components={{ SingleValue }}
        />
      ) : (
        <Tooltip label={title && title.length > 18 ? title : ''} delay={300}>
          <StyledText
            rowCount={1}
            fontSize="16"
            fontWeight={500}
            lineHeight="1.5"
            className="text-center"
          >
            {title || ''}
          </StyledText>
        </Tooltip>
      )}
      {typeof description !== 'undefined' && description?.trim() !== '' && (
        <Tooltip label={description && description.length > 46 ? description : ''} delay={300}>
          {description && (
            <StyledText
              fontSize="12"
              lineHeight="1.2"
              color="fog"
              rowCount={2}
              className="text-center w-[160px] line-clamp-2 -mt-1"
            >
              {itemType === 'InvestorCompanyJob' ? 'Role: ' : ''} {description}
            </StyledText>
          )}
        </Tooltip>
      )}
    </div>
  )
}

export default UserInfo
