import React, { useState } from 'react'

import title from '@ckeditor/ckeditor5-heading/src/title'

import { SeeMore } from 'containers/CandidateProfile'
import AboutModal from 'containers/CandidateProfile/Modals/AboutModal'
import { LEVEL_MAP, ROLE_FUNCTION_MAP } from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Pill from 'global/Pill'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import PillV2 from 'ui-components/PillV2'
import Widget from 'ui-components/Widget'

import { CandidateProfile } from 'utils/types'

interface Props {
  candidateProfile: CandidateProfile
  reloadCandidateProfile: () => void
  isThisCandidate: boolean
  isAdminOrEmployee: boolean
}

const About: React.FC<Props> = ({
  candidateProfile,
  reloadCandidateProfile,
  isThisCandidate,
  isAdminOrEmployee,
}) => {
  const teamSlug = useTeamSlug()
  const bioLength = candidateProfile?.bio?.length || 0
  const [isExpanded, setIsExpanded] = useState(bioLength < 165 || false)
  const bio = isExpanded ? candidateProfile.bio : `${candidateProfile?.bio?.slice(0, 165)}`

  const { showModal } = useModal()

  const renderAboutModal = (resolve) => (
    <AboutModal
      onHide={() => resolve()}
      candidateProfile={candidateProfile}
      teamSlug={teamSlug}
      reloadCandidateProfile={reloadCandidateProfile}
    />
  )

  if (
    !candidateProfile.function &&
    !candidateProfile.level &&
    !candidateProfile.location &&
    candidateProfile.skills.length === 0
  ) {
    return null
  }

  return (
    <Widget
      title="About"
      className="group"
      cta={
        (isThisCandidate || isAdminOrEmployee) && (
          <div className="hidden group-hover:flex">
            <CabalButton
              variant="link"
              padding={'0'}
              onClick={() => showModal(renderAboutModal, 'about-modal')}
            >
              Edit
            </CabalButton>
          </div>
        )
      }
    >
      {candidateProfile.bio && (
        <p className="leading-5 mb-2">
          <Typography fontSize="12" color="fog" lineHeight={1.2}>
            {bio}
          </Typography>

          {candidateProfile.bio.length > 165 && (
            <SeeMore
              className="ml-1 cursor-pointer"
              color="fog"
              fontSize="12"
              lineHeight={1.2}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? '...see less' : '...see more'}
            </SeeMore>
          )}
        </p>
      )}

      <div className="flex flex-col">
        <div className="flex gap-6 mt-2">
          {candidateProfile.functions && (
            <div>
              <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
                Function
              </Typography>

              <Typography
                fontSize="12"
                component="div"
                color="fog"
                className="flex gap-1 flex-wrap"
              >
                {candidateProfile.functions?.map((func, i) => (
                  <Pill variant="gray" rounded key={i}>
                    {ROLE_FUNCTION_MAP[func as keyof typeof ROLE_FUNCTION_MAP]}
                  </Pill>
                ))}
              </Typography>
            </div>
          )}
          {candidateProfile.level && (
            <div>
              <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
                Level
              </Typography>

              <Typography
                fontSize="12"
                component="div"
                color="fog"
                className="flex gap-1 flex-wrap"
              >
                <Pill variant="gray" rounded>
                  {LEVEL_MAP[candidateProfile.level as keyof typeof LEVEL_MAP]}
                </Pill>
              </Typography>
            </div>
          )}

          {candidateProfile.location && (
            <div>
              <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
                Location
              </Typography>

              <Typography fontSize="12" component="div" color="fog">
                <Pill variant="gray" rounded>
                  {candidateProfile.location}
                </Pill>
              </Typography>
            </div>
          )}
        </div>
      </div>

      {candidateProfile.skills.length > 0 && (
        <div className="mt-4">
          <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
            Expertise
          </Typography>
          <div className="flex flex-wrap gap-1 pt-1">
            {candidateProfile.skills.map((item) => (
              <PillV2 title={item} key={item} className="px-2 py-1.5" />
            ))}
          </div>
        </div>
      )}
    </Widget>
  )
}

export default About
