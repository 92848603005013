import axios from 'axios'

import {
  CompaniesFiltersType,
  CompanyBlueprint,
  CompanyConnectionsResponse,
  GetCompanyContributionsResponse,
  GetCompanyResponse,
  InviteFounderRequest,
  PortfolioAddCompanyRequest,
  UpdateTeamRequest,
  UpdateTeamResponse,
} from 'utils/types'

export default {
  getCompanyItem: (team_slug: string, uuid: string, view_as_member: boolean, list_id: string) => {
    return axios.get<{
      item: CompanyBlueprint
      item_home?: any
      published: true
      list_name: string
    }>(`/api/companies/${uuid}`, {
      params: {
        team_slug,
        view_as_member,
        list_id,
      },
    })
  },

  addCompany: (company_name: string, email: string) => {
    const params = { company_name, email }
    return axios.post(`/api/teams/invite_founder`, params)
  },

  inviteFounder: (params: InviteFounderRequest) => {
    return axios.post(`/api/teams/invite_founder`, params)
  },

  portfolioAddCompany: (params: PortfolioAddCompanyRequest) => {
    return axios.post<{
      slug: string
    }>(`/api/teams/portfolio_add_company`, params)
  },

  getMyContributionsForCompany: (company_slug: string) => {
    return axios.get<GetCompanyContributionsResponse>(`/api/teams/${company_slug}/my_contributions`)
  },

  getCompanyConnections: (
    companyId: number | string,
    currentPage: number,
    icps: string,
    members?: string[],
    filters?: CompaniesFiltersType,
    listSlug?: string,
    teamSlug?: string,
  ) => {
    return axios.get<CompanyConnectionsResponse>(`/api/companies/${companyId}/connections`, {
      params: {
        page: currentPage,
        icps,
        member_uuids: members?.join(','),
        filters: JSON.stringify(filters),
        list_slug: listSlug,
        team: teamSlug,
      },
    })
  },

  exportCompanyData: (companyUuid: string) => {
    return axios.post(`/api/companies/${companyUuid}/export`)
  },

  getCompanyMetrics: (comapny_slug: string) => {
    return axios.get(`/api/teams/${comapny_slug}/metrics`)
  },

  getCalendarEvents: (companySlug: string, currentPage: number) => {
    return axios.get(`/api/calendar_events?team=${companySlug}&page=${currentPage}`)
  },

  updateCompanyDealContext: (companyId: number, deal_context: string) => {
    const params = { deal_context }
    return axios.put(`/api/companies/${companyId}`, params)
  },

  getTeam: () => {
    return axios.get('/api/teams')
  },

  getTeamUsers: () => {
    return axios.get('/api/teams/users')
  },

  createTeam: (params: any) => {
    return axios.post('/api/teams', params)
  },

  updateTeam: (team_slug: string, params: UpdateTeamRequest) => {
    return axios.put<UpdateTeamResponse>(`/api/teams/${team_slug}`, params)
  },
}
