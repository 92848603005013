import React from 'react'

import { Field, FieldProps, Form, Formik } from 'formik'
import styled from 'styled-components'
import { object, string } from 'yup'

import { Card } from 'components/MemberOnboardingPage'
import { useCandidateProfile } from 'containers/CandidateProfileModal'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { TextInputField, UploadButton } from 'global/Input'
import CityInput from 'global/Input/CityInput'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'

export const SelectWrapper = styled.div`
  .select__value-container {
    justify-content: flex-start !important;
  }
`

const personalInfoSchema = object({
  company: string().required('This field is required'),
  location: string().required('This field is required'),
  title: string().required('This field is required'),
})

export default function CandidatePersonalInfo(props: {
  onContinue?: () => void
  onBack?: () => void
}) {
  const { candidateProfile, updateCandidateProfile } = useCandidateProfile()

  return (
    <Formik
      initialValues={candidateProfile}
      validationSchema={personalInfoSchema}
      onSubmit={(values) => {
        updateCandidateProfile(values)
        props.onContinue?.()
      }}
      enableReinitialize
    >
      {({ handleSubmit }) => {
        return (
          <Form
            onSubmit={handleSubmit}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
              }
            }}
          >
            <Card className="relative">
              <div className="flex flex-col items-center py-2">
                {props?.onBack && (
                  <Typography
                    color="fog"
                    onClick={props.onBack}
                    className="absolute top-[30px] left-6 cursor-pointer"
                  >
                    <i className="far fa-chevron-left mr-2" />
                  </Typography>
                )}

                <Typography component="div" fontSize="24" fontWeight={600}>
                  Add your personal info
                </Typography>
              </div>

              <div className="px-4">
                <ModalInputWrapper className="py-2">
                  <ModalInputLabel>
                    <div className="flex items-center justify-start h-full">Photo</div>
                  </ModalInputLabel>
                  <div className="flex">
                    <UploadButton
                      allowedExtensions={['png', 'jpg', 'jpeg']}
                      onUpload={(uuid) =>
                        updateCandidateProfile({ avatar: `/api/uploads/${uuid}` })
                      }
                      showPencil={true}
                      text=""
                      triggerProps={{
                        variant: 'tertiary',
                        leftIcon: (
                          <Typography className="flex items-start justify-start">
                            <Avatar src={candidateProfile.avatar} size={'32'} />
                          </Typography>
                        ),
                        padding: '0',
                      }}
                    />
                  </div>
                </ModalInputWrapper>

                <ModalInputWrapper className="py-2">
                  <ModalInputLabel>
                    <div className="flex items-center justify-start h-full">Company</div>
                  </ModalInputLabel>
                  <Field name="company">
                    {(props: FieldProps) => (
                      <TextInputField
                        {...props.field}
                        error={props.meta.touched ? props.meta.error : undefined}
                        placeholder="Current company"
                      />
                    )}
                  </Field>
                </ModalInputWrapper>

                <ModalInputWrapper className="py-2">
                  <ModalInputLabel>
                    <div className="flex items-center justify-start h-full">Title</div>
                  </ModalInputLabel>
                  <Field name="title">
                    {(props: FieldProps) => (
                      <TextInputField
                        {...props.field}
                        error={props.meta.touched ? props.meta.error : undefined}
                        placeholder="Title"
                      />
                    )}
                  </Field>
                </ModalInputWrapper>

                <ModalInputWrapper className="py-2">
                  <ModalInputLabel>
                    <div className="flex items-center justify-start h-full">Location</div>
                  </ModalInputLabel>
                  <Field name="location">
                    {(props: FieldProps) => (
                      <SelectWrapper>
                        <CityInput
                          {...props.field}
                          onBlur={() => {
                            props.form.setFieldTouched(props.field.name, true)
                          }}
                          onChange={(opt) => {
                            props.form.setFieldValue(props.field.name, opt?.value)
                          }}
                          value={
                            props.field.value
                              ? { value: props.field.value, label: props.field.value }
                              : null
                          }
                          error={props.meta.touched ? props.meta.error : undefined}
                        />
                      </SelectWrapper>
                    )}
                  </Field>
                </ModalInputWrapper>
              </div>

              <div className="flex justify-center pt-4">
                <CabalButton
                  type="submit"
                  title="Continue"
                  rightIcon={<i className="far fa-arrow-right" />}
                >
                  Continue
                </CabalButton>
              </div>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}
