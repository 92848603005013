import React, { useEffect, useMemo, useRef, useState } from 'react'

import cx from 'classnames'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import CabalMessage from 'models/CabalMessage'
import objectHash from 'object-hash'
import Expand from 'react-expand-animated'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useComposer } from 'components/Composer'
import FeedEmptyState from 'components/EmptyStates/FeedEmptyState'
import { FiltersValueType, renderFilters } from 'components/Filters'
import { NewScreenMail } from 'components/NewUserScreens'
import NoMailScreen from 'containers/GlobalMessagesPage/Messages/NoMailScreen'
import ViewMessage from 'containers/ViewMessage'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import { SelectOption } from 'global/Input'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import {
  useAdvisors,
  useCurrentUser,
  useCurrentUserSettings,
  useGroups,
  useTeams,
} from 'store/hooks'

import api, { callApi } from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'
import { InboxMessageBlueprint, MessageModel } from 'utils/types'

import MessageCard from '../MessageCard'

// import CabalMessage from 'models/CabalMessage'

const FiltersDiv = styled.div`
  ${tw`sticky py-2 w-full`}
  top: 0px;
  z-index: 10;
`

const Wrapper = styled.div<{ splitView: boolean }>`
  ${({ theme, splitView }) => {
    if (splitView) return tw`border-r-[1px] dark:border-[#2d3748] border-[#E3E5E8]`
  }}

  max-height: calc(100vh - 90px);
`

const ContainerDiv = styled.div<{ topPosition: number | undefined }>`
  overflow-y: scroll;
  overflow-x: hidden;
`

interface Props {
  tab: string
  onSplitView?: (val: boolean) => void
}

const Messages: React.FC<Props> = ({ tab }) => {
  const location = useLocation()
  const history = useHistory()

  const containerRef = useRef<HTMLDivElement>(null)

  const group = { uuid: 'all' }
  const { user } = useCurrentUser()
  const team = user.team
  const teamSlug = team?.slug

  const { groups } = useGroups(teamSlug)
  const { teams } = useTeams()
  const { advisors } = useAdvisors({ teamSlug })
  const { compose } = useComposer()

  const [containerTopPosition, setContainerTopPosition] = useState()

  const [openArchive, setOpenArchive] = useState(false)
  const [filters, setFilters] = useState<FiltersValueType>({})
  const [splitView, setSplitView] = useState<boolean>(false)
  const { isMobile } = useColorMode()

  const selectedMessageId = location.hash.match(/#([\w|-]+)/)?.[1]

  const setSelectedMessage = (m?: CabalMessage) => {
    if (isMobile || selectedMessageId === m?.uuid) return

    if (m === undefined) {
      history.replace(location.pathname)
      return
    }

    const path = `${location.pathname}#${m.uuid}`
    if (!selectedMessageId) history.replace(path)
    else history.push(path)
  }

  useEffect(() => {
    setSplitView(!!selectedMessageId)
  }, [selectedMessageId])

  useEffect(() => {
    const refTop = containerRef.current?.getBoundingClientRect()?.top
    if (refTop && !containerTopPosition) {
      setContainerTopPosition(refTop)
    }
  }, [containerRef.current])

  const {
    isLoading,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: reloadMessages,
    data: messageData,
  } = useInfiniteQuery(
    [teamSlug, tab, objectHash(filters)],
    ({ pageParam: page = 1 }) => {
      const drafts = tab === 'shared-with-me' || tab === 'drafted-for-others'
      console.log('drafts', drafts, tab)
      if (drafts) {
        return callApi(api.getGlobalMessages, {
          team_slug: teamSlug,
          filters,
          page,
          drafts: true,
          tab,
        })
      } else {
        return callApi(api.getGlobalMessages, {
          team_slug: teamSlug,
          filters,
          page,
          tab,
        })
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage?.pagination?.next_page,
      getPreviousPageParam: (firstPage) => firstPage?.pagination?.prev_page,
      onError: (err) => console.error(err),
    },
  )

  const { data: draftMetaDataData } = useQuery(
    [teamSlug, 'draftsStats', {}, objectHash(filters)],
    () => callApi(api.getDraftStats, teamSlug, {}, filters),
    {
      enabled: tab === 'shared-with-me' || tab === 'drafted-for-others',
    },
  )
  const draftMetaData = draftMetaDataData?.metadata

  const messages = useMemo(
    () =>
      CabalMessage.fromArray(
        messageData?.pages?.flatMap<InboxMessageBlueprint | MessageModel>((page) => page.messages),
      ),
    [messageData],
  )
  const archiveCount = messages?.filter((msg) => msg.is_archived).length
  const selectedMessage = messages?.find((m) => m.uuid === selectedMessageId)

  const renderMessage = (message: CabalMessage) => {
    return (
      <MessageCard
        group={group}
        message={message}
        reloadMessages={reloadMessages}
        key={message.uuid}
        selected={splitView && selectedMessageId === message.uuid}
        onClick={() => {
          if (message.draft) {
            if (splitView) {
              setSplitView(false)
            }
            compose({
              messageUuid: message.uuid,
              team_slug: message.rawMessage.team_slug,
              onHide: () => {
                setTimeout(() => reloadMessages(), 500)
              },
            })
          } else {
            setSelectedMessage(message)
            if (!splitView) {
              setSplitView(true)
            }
          }
        }}
        minimalView={true}
      />
    )
  }

  const usersAndMembers: SelectOption<string>[] = compact(
    flatten([
      advisors?.map((a) => ({
        label: (
          <>
            {a.name}
            <br />
            {a.email}
          </>
        ),
        searchLabel: `${a.name} (${a.email})`,
        value: `advisor:${a.uuid}`,
        group: 'Members',
      })),
      team?.admins_and_members?.map((a) => ({
        label: (
          <>
            {a.name}
            <br />
            {a.email}
          </>
        ),
        searchLabel: `${a.name} (${a.email})`,
        value: `user:${a.uuid}`,
        group: 'Employees',
      })),
    ]),
  )

  const usersAndMembersAndGroups: SelectOption<string>[] = compact(
    flatten([
      usersAndMembers,
      groups.map((g) => ({ label: g.name, value: `group:${g.uuid}`, group: 'Group' })),
    ]),
  )

  const teamOptions: SelectOption<string>[] = compact(
    teams.map((t) => ({ label: t.name, value: t.slug, group: 'Team' })),
  )

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: [
      {
        key: 'query',
        type: 'search',
        icon: 'far fa-search',
        labelStr: 'Search',
        label: 'Search',
        skipLabelInAppliedPill: true,
        noPopover: true,
      },
      {
        key: 'team',
        label: 'Team',
        labelStr: 'Team',
        type: 'dropdown',
        icon: <i className="far fa-building mt-0.5 mr-2" />,
        options: teamOptions,
      },
      {
        key: 'people',
        label: 'People',
        labelStr: 'People',
        type: 'dropdown',
        icon: <i className="far fa-users mt-0.5 mr-2" />,
        options: usersAndMembersAndGroups,
        optionHeight: 55,
      },
    ],
    value: filters,
    onChange: setFilters,
  })

  const filtersNav = (
    <>
      <FiltersDiv className="pt-2 pl-2 flex justify-between border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8]">
        <div className="flex items-center gap-2">
          {appliedFilters}
          {renderedFilters}
        </div>
      </FiltersDiv>
    </>
  )

  const messagesView = (
    <ContainerDiv ref={containerRef} topPosition={containerTopPosition} id="message-split-view">
      {messages?.length !== 0 && (
        <div className="grid grid-cols-7 h-full">
          <Wrapper
            splitView={!!splitView}
            className={cx(splitView ? 'col-span-2' : 'col-span-7', 'overflow-y-auto pb-8')}
          >
            {!!draftMetaData?.all_drafts_count ||
              (messages && messages?.filter((m) => m.is_pinned).length > 0 && (
                <div>
                  {messages?.filter((m) => m.is_pinned).map((message) => renderMessage(message))}
                </div>
              ))}
            {messages
              ?.filter((m) => !m.is_archived && !m.is_pinned)
              ?.map((message) => renderMessage(message))}

            {hasNextPage && (
              <div className="flex justify-center	my-6">
                <CabalButton disabled={isFetchingNextPage} onClick={() => fetchNextPage()}>
                  Load more
                </CabalButton>
              </div>
            )}

            {!!archiveCount && archiveCount > 0 && (
              <div
                className="flex justify-between items-center mt-9 py-1.5 cursor-pointer"
                onClick={() => setOpenArchive(!openArchive)}
              >
                <Typography
                  textTransform="uppercase"
                  fontWeight={500}
                  fontSize="12"
                  className="block"
                  color="widget_title"
                  letterSpacing="0.05em"
                >
                  {`Archive (${archiveCount})`}
                </Typography>
                <Typography fontSize="12" color="fog_rain">
                  {openArchive ? (
                    <i className="far fa-chevron-down"></i>
                  ) : (
                    <i className="far fa-chevron-right"></i>
                  )}
                </Typography>
              </div>
            )}
            <Expand open={openArchive}>
              {messages
                ?.filter((m) => !!m.is_archived && !m.is_pinned)
                ?.map((message) => renderMessage(message))}
            </Expand>
          </Wrapper>
          {splitView && selectedMessage && (
            <div className="col-span-5">
              <ViewMessage message_id={selectedMessage.uuid} splitView={splitView} />
            </div>
          )}
        </div>
      )}

      {messages?.length === 0 && appliedFilters.length === 0 && !filters?.query && (
        <div className="flex items-center justify-center mt-2 mx-auto">
          {/* <NewScreenMail /> */}
          <NoMailScreen teamSlug={teamSlug!} />
        </div>
      )}

      {messages?.length === 0 && (appliedFilters.length > 0 || filters?.query) && (
        <div className="mt-2">
          <FeedEmptyState teamSlug={teamSlug!} appliedFilters={appliedFilters.length > 0} />
        </div>
      )}
    </ContainerDiv>
  )

  return (
    <div>
      {filtersNav}
      {isLoading ? <Loading className="my-12" /> : messagesView}
    </div>
  )
}

export default Messages
