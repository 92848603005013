export const REQUEST_CENTER_STATUSES_NON_PORTFOLIO = [
  'initiated',
  'requested',
  'offered',
  'sent',
  'meeting',
  'opportunity',
  'closed',
  'declined',
]
export const STATUS_ICON_LABEL_NON_PORTFOLIO_MAPPING = {
  [REQUEST_CENTER_STATUSES_NON_PORTFOLIO[0]]: {
    label: 'Created',
    icon: 'far fa-envelope',
    text_color: 'text-gray-700',
    bg_color: 'bg-gray-100',
  },
  [REQUEST_CENTER_STATUSES_NON_PORTFOLIO[1]]: {
    label: 'Requested',
    icon: 'far fa-arrows-alt-h',
    text_color: 'text-blue-700',
    bg_color: 'bg-blue-100',
  },
  [REQUEST_CENTER_STATUSES_NON_PORTFOLIO[2]]: {
    label: 'Offered',
    icon: 'far fa-gift',
    text_color: 'text-orange-700',
    bg_color: 'bg-orange-100',
  },
  [REQUEST_CENTER_STATUSES_NON_PORTFOLIO[3]]: {
    label: 'Sent',
    icon: 'far fa-envelope-circle-check',
    text_color: 'text-green-700',
    bg_color: 'bg-green-100',
  },
  [REQUEST_CENTER_STATUSES_NON_PORTFOLIO[4]]: {
    label: 'Meeting',
    icon: 'far fa-calendar-alt',
    text_color: 'text-teal-700',
    bg_color: 'bg-teal-100',
  },
  [REQUEST_CENTER_STATUSES_NON_PORTFOLIO[5]]: {
    label: 'Opportunity',
    icon: 'far fa-rocket',
    text_color: 'text-indigo-700',
    bg_color: 'bg-indigo-100',
  },
  [REQUEST_CENTER_STATUSES_NON_PORTFOLIO[6]]: {
    label: 'Closed-won',
    icon: 'far fa-dollar-sign',
    text_color: 'text-green-700',
    bg_color: 'bg-green-100',
  },
  [REQUEST_CENTER_STATUSES_NON_PORTFOLIO[7]]: {
    label: 'Declined',
    icon: 'far fa-circle-x',
    text_color: 'text-red-700',
    bg_color: 'bg-red-100',
  },
  // These keys are included for backward compatibility
  // of intro requests which may have status other than
  // the ones listed for non-portfolio enabled teams
  approved: {
    label: 'Approved',
    icon: 'far fa-stamp',
    text_color: 'text-green-700',
    bg_color: 'bg-green-100',
  },
  accepted: {
    label: 'Accepted',
    icon: 'far fa-check',
    text_color: 'text-orange-700',
    bg_color: 'bg-orange-100',
  },
  archived: {
    label: 'Archived',
    icon: 'far fa-archive',
    text_color: 'text-gray-700',
    bg_color: 'bg-gray-100',
  },
  rerouted: {
    label: 'Transfered',
    icon: 'far fa-shuffle',
    text_color: 'text-yellow-700',
    bg_color: 'bg-yellow-100',
  },
  viewed: {
    label: 'Viewed',
    icon: 'far fa-eye',
    text_color: 'text-purple-700',
    bg_color: 'bg-purple-100',
  },
  note: {
    label: 'Note',
    icon: 'far fa-regular fa-note',
    text_color: 'text-gray-700',
    bg_color: 'bg-gray-100',
  },
}
export const REQUEST_CENTER_STATUSES = [
  'requested',
  'approved',
  'accepted',
  'declined',
  'archived',
  'sent',
  'initiated',
  'offered',
  'meeting',
  'opportunity',
  'closed',
]
export const STATUS_ICON_LABEL_MAPPING = {
  [REQUEST_CENTER_STATUSES[0]]: {
    label: 'Requested',
    icon: 'far fa-arrows-alt-h',
    text_color: 'text-blue-700',
    bg_color: 'bg-blue-100',
  },
  [REQUEST_CENTER_STATUSES[1]]: {
    label: 'Approved',
    icon: 'far fa-stamp',
    text_color: 'text-green-700',
    bg_color: 'bg-green-100',
  },
  [REQUEST_CENTER_STATUSES[2]]: {
    label: 'Accepted',
    icon: 'far fa-check',
    text_color: 'text-orange-700',
    bg_color: 'bg-orange-100',
  },
  [REQUEST_CENTER_STATUSES[3]]: {
    label: 'Declined',
    icon: 'far fa-circle-x',
    text_color: 'text-red-700',
    bg_color: 'bg-red-100',
  },
  [REQUEST_CENTER_STATUSES[4]]: {
    label: 'Archived',
    icon: 'far fa-archive',
    text_color: 'text-gray-700',
    bg_color: 'bg-gray-100',
  },
  [REQUEST_CENTER_STATUSES[5]]: {
    label: 'Sent',
    icon: 'far fa-envelope-circle-check',
    text_color: 'text-green-700',
    bg_color: 'bg-green-100',
  },
  [REQUEST_CENTER_STATUSES[6]]: {
    label: 'Created',
    icon: 'far fa-envelope',
    text_color: 'text-gray-700',
    bg_color: 'bg-gray-100',
  },
  [REQUEST_CENTER_STATUSES[7]]: {
    label: 'Offered',
    icon: 'far fa-gift',
    text_color: 'text-orange-700',
    bg_color: 'bg-orange-100',
  },
  [REQUEST_CENTER_STATUSES[8]]: {
    label: 'Meeting',
    icon: 'far fa-calendar-alt',
    text_color: 'text-teal-700',
    bg_color: 'bg-teal-100',
  },
  [REQUEST_CENTER_STATUSES[9]]: {
    label: 'Opportunity',
    icon: 'far fa-rocket',
    text_color: 'text-indigo-700',
    bg_color: 'bg-indigo-100',
  },
  [REQUEST_CENTER_STATUSES[10]]: {
    label: 'Closed-won',
    icon: 'far fa-dollar-sign',
    text_color: 'text-green-700',
    bg_color: 'bg-green-100',
  },
  // These keys are included for backward compatibility
  // of intro requests which may have these status
  rerouted: {
    label: 'Transfered',
    icon: 'far fa-shuffle',
    text_color: 'text-yellow-700',
    bg_color: 'bg-yellow-100',
  },
  viewed: {
    label: 'Viewed',
    icon: 'far fa-eye',
    text_color: 'text-purple-700',
    bg_color: 'bg-purple-100',
  },
  note: {
    label: 'Note',
    icon: 'far fa-regular fa-note',
    text_color: 'text-gray-700',
    bg_color: 'bg-gray-100',
  },
}
