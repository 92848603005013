import React, { useState } from 'react'

import { Container } from 'postcss'
import Expand from 'react-expand-animated'

import TeamRow from 'components/TeamRow'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Card from 'global/Card'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'
import GridWrapper from 'ui-components/GridWrapper'
import Widget from 'ui-components/Widget'

import { UserProfile } from 'utils/types'

import { UpdateProfileMutation } from '.'
import EditPublicPortfolio from './EditPublicPortfolio'

interface Props {
  isCurrentUserProfile: boolean
  profile: UserProfile
  onEditPortfolio?: () => void
  updateProfileMutation: UpdateProfileMutation
}

const PublicPortfolio: React.FC<Props> = ({
  profile,
  updateProfileMutation,
  onEditPortfolio,
  isCurrentUserProfile,
}) => {
  const portfolio = profile?.profile_data?.portfolio
  const defaultCount = 8
  const [viewCount, showViewCount] = React.useState(defaultCount)

  if (!isCurrentUserProfile && !portfolio?.length) return <></>

  return (
    <>
      <Widget
        title="Portfolio"
        cta={
          portfolio?.length &&
          isCurrentUserProfile && (
            <CabalButton variant="link" onClick={onEditPortfolio}>
              Edit
            </CabalButton>
          )
        }
      >
        <GridWrapper>
          {portfolio?.slice(0, viewCount).map((company) => (
            <DataCardV2
              key={company.slug}
              view="grid"
              title={
                <div className="flex items-center">
                  <Typography fontSize="20" fontWeight={600}>
                    {company.name}
                  </Typography>
                  {company.slug && (
                    <Typography fontSize="16" color="purple" className="ml-2">
                      <i className="fas fa-badge-check" />
                    </Typography>
                  )}
                </div>
              }
              avatar={<Avatar src={company.logo_url} name={company.name} size="72" />}
              cta={
                company.slug && (
                  <CabalButton
                    variant="primary"
                    onClick={() => window.open(`/${company.slug}`, '_blank', 'noopener noreferrer')}
                    className="w-full"
                  >
                    View
                  </CabalButton>
                )
              }
            />
          ))}
        </GridWrapper>

        {!portfolio?.length && (
          <EditPublicPortfolio
            profile={profile}
            updateProfileMutation={updateProfileMutation}
            modal={false}
          />
        )}
        {portfolio && portfolio.length > defaultCount && (
          <div className="mt-4 flex items-center justify-center w-full">
            {viewCount === defaultCount ? (
              <CabalButton variant="link" onClick={() => showViewCount(portfolio!.length)}>
                See all ({portfolio!.length})
              </CabalButton>
            ) : (
              <CabalButton variant="link" onClick={() => showViewCount(defaultCount)}>
                View less
              </CabalButton>
            )}
          </div>
        )}
      </Widget>
    </>
  )
}

export default PublicPortfolio
