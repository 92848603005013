import React, { useState } from 'react'
import { useMutation } from 'react-query'

import { CheckBox, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import CabalButton from 'global/CabalButton'
import api, { callApi } from 'utils/api'
import { cabalToast } from 'ui-components/Toast'
import errorMessages from 'utils/errorMessages'
import { ModalSectionWrapper, ModalInputWrapper, ModalInputLabel } from 'global/Modal/styles'

interface Props {
  teamSlug: string
  onHide: () => void
}

interface Advisor {
  first_name?: string
  last_name?: string
  email?: string
  linkedin_url?: string
}

const SharePortfolioModal: React.VFC<Props> = ({ teamSlug, onHide }) => {
  const [emailValid, setEmailValid] = useState<boolean>(false)
  const [advisorData, setAdvisorData] = useState<Advisor>({})

  const { mutate: handleShare, isLoading } = useMutation(
    () =>
      callApi(api.createCandidate, {
        ...advisorData,
        team_slug: teamSlug,
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Portfolio was successfully shared!' })
        onHide()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const disabled = isLoading || !advisorData.first_name || !advisorData.email || !emailValid

  return (
    <Modal
      show
      onHide={onHide}
      header="Share Portfolio"
      rightActions={
        <CabalButton
          onClick={() => handleShare()}
          working={isLoading}
          disabled={disabled}
          data-testid="share-portfolio-modal-btn"
        >
          Share
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>First name</ModalInputLabel>
          <TextInput
            value={advisorData.first_name}
            onChange={(e) => setAdvisorData({ ...advisorData, first_name: e.target.value })}
            className="w-full"
            valueType="non-empty"
            labeled
            data-testid="share-portfolio-modal-first-name"
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Last name </ModalInputLabel>
          <TextInput
            value={advisorData.last_name}
            onChange={(e) => setAdvisorData({ ...advisorData, last_name: e.target.value })}
            className="w-full"
            valueType="non-empty"
            labeled
            data-testid="share-portfolio-modal-last-name"
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Email </ModalInputLabel>
          <TextInput
            value={advisorData.email}
            onChange={(e, v) => {
              setEmailValid(!!v)
              setAdvisorData({ ...advisorData, email: e.target.value.trim() })
            }}
            className="w-full"
            valueType="email"
            errorMessage={errorMessages.invalidEmail}
            data-testid="share-portfolio-modal-email"
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default SharePortfolioModal
