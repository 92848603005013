import React from 'react'
import Typography from 'global/Typography'
import styled from 'styled-components'
import tw from 'twin.macro'
import { TimeProp } from '.'

import Col from './Col'

const BoardWrapper = styled.div<{
  invert: boolean
}>`
  ${tw`px-5 pt-4 pb-6 rounded-lg flex-1`}
  background-color: ${({ theme, invert }) => !invert && theme.colors.shadow};
  min-width: 260px;
`

export interface RowProps {
  name: string
  description: string
  url: string
  uuid: string
  invert?: boolean
}

interface LeaderBoardProps {
  title: string
  icon: React.ReactNode
  type: 'email' | 'sales' | 'social'
  time: TimeProp
  rows: RowProps[]
  invert?: boolean
}

const LeaderBoard: React.VFC<LeaderBoardProps> = ({
  rows,
  title,
  icon,
  type,
  time,
  invert = false,
}) => {
  let cta = ''

  if (type === 'sales') {
    cta = 'Connections'
  } else {
    cta = 'Shoutout'
  }
  return (
    <BoardWrapper invert={invert}>
      {invert ? (
        <Typography
          textTransform="uppercase"
          fontWeight={500}
          fontSize="12"
          className="block -mt-4"
          color="widget_title"
          letterSpacing="0.05em"
        >
          Top Contributors
        </Typography>
      ) : (
        <Typography fontSize="12" fontWeight={600} className="pb-1">
          {icon}
          {title}
        </Typography>
      )}

      {rows.slice(0, 7).map((row) => (
        <Col
          key={row.name + row.description}
          name={row.name}
          url={row.url}
          uuid={row.uuid}
          description={row.description}
          cta={cta}
          invert={invert}
        />
      ))}
    </BoardWrapper>
  )
}

export default LeaderBoard
