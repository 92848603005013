import React, { useState } from 'react'

import { useMutation } from 'react-query'

import { WorkHistoryEntry } from 'containers/CandidateProfileModal/WorkHistory'
import CabalButton from 'global/CabalButton'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CandidateProfile } from 'utils/types'

interface ExperienceModalProps {
  onHide: () => void
  candidateProfile: CandidateProfile
  teamSlug: string
  reloadCandidateProfile: () => void
}

const ExperienceModal = ({
  onHide,
  candidateProfile: _candidateProfile,
  teamSlug,
  reloadCandidateProfile,
}: ExperienceModalProps) => {
  const [candidateProfile, setCandidateProfile] =
    useState<Partial<CandidateProfile>>(_candidateProfile)

  const updateCandidateProfileMutation = useMutation(
    ['updateCandidateProfile', candidateProfile.uuid],
    () =>
      callApi(api.updateCandidateProfileV2, candidateProfile.uuid!, {
        candidate_profile: candidateProfile,
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Profile updated successfully' })
        reloadCandidateProfile()
        onHide()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to update profile' })
      },
    },
  )

  return (
    <Modal
      header="Experience"
      show={true}
      onHide={onHide}
      rightActions={
        <CabalButton
          type="button"
          variant="primary"
          onClick={() => updateCandidateProfileMutation.mutate()}
          working={updateCandidateProfileMutation.isLoading}
        >
          Save
        </CabalButton>
      }
    >
      <div className="flex justify-between items-center">
        <Typography fontSize="14" fontWeight={600}>
          Work History
        </Typography>
        <CabalButton
          variant="link"
          type="button"
          onClick={() => {
            setCandidateProfile({
              ...candidateProfile,
              work_history: [
                {
                  company: '',
                  title: '',
                  fromMonth: null,
                  fromYear: null,
                  toMonth: null,
                  toYear: null,
                  summary: '',
                  key: Date.now(),
                },
                ...candidateProfile.work_history,
              ],
            })
          }}
        >
          + Add More
        </CabalButton>
      </div>
      {candidateProfile.work_history &&
        candidateProfile.work_history.map((workHistory, i) => (
          <WorkHistoryEntry
            key={`${workHistory.uuid}-${i}`}
            entry={workHistory}
            onUpdate={(newEntry) => {
              const newWorkHistory = [...candidateProfile?.work_history]
              newWorkHistory[i] = {
                ...newWorkHistory[i],
                ...newEntry,
              }
              setCandidateProfile({ ...candidateProfile, work_history: newWorkHistory })
            }}
            hasMultipleEntries={candidateProfile.work_history?.length > 1}
            onRemove={() => {
              setCandidateProfile({
                ...candidateProfile,
                work_history: candidateProfile.work_history?.filter(
                  (_, innerIndex) => innerIndex !== i,
                ),
              })
            }}
            isLast={i === candidateProfile.work_history.length - 1}
          />
        ))}
    </Modal>
  )
}

export default ExperienceModal
