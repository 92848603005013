import React from 'react'
import { useQuery } from 'react-query'

import SnippetsModal from '../EmailSnippets/SnippetsModal'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import api, { callApi } from 'utils/api'
import { EmailSnippet } from 'utils/types'

interface Props {
  onSelectSnippet: (e: EmailSnippet) => void
  teamSlug: string
}

const Snippets: React.VFC<Props> = ({ onSelectSnippet, teamSlug }) => {
  const { showModal } = useModal()

  const getSnippetsQuery = useQuery(['getSnippets', teamSlug], () =>
    callApi(api.getEmailSnippet, teamSlug),
  )
  const snippets = getSnippetsQuery.data?.email_snippets || []

  const manageSnippets = () => {
    showModal(
      (resolve) => (
        <SnippetsModal show onHide={resolve} onReloadSnippets={() => getSnippetsQuery.refetch()} />
      ),
      'manage-snippet-modal',
    )
  }

  if (getSnippetsQuery.isFetching) {
    return <Loading className="my-2" />
  }

  return (
    <div>
      {snippets.map((snippet) => (
        <Typography key={snippet.uuid} className="flex justify-between w-full px-3" fontSize="13">
          <button className="block flex-grow text-left" onClick={() => onSelectSnippet(snippet)}>
            {snippet.name}
          </button>
        </Typography>
      ))}

      <div className="text-center mt-4">
        <CabalButton size="small" onClick={manageSnippets}>
          Manage snippets
        </CabalButton>
      </div>
    </div>
  )
}

export default Snippets
