import React from 'react'

import { useCurrentUser } from 'store/hooks'
import Widget from 'ui-components/Widget'

import { UserProfile } from 'utils/types'

import { UpdateProfileMutation } from '.'
import ClaimUsername from './ClaimUsername'

interface Props {
  profile?: UserProfile
  updateProfileMutation: UpdateProfileMutation
}

const Setup: React.FC<Props> = () => {
  const { reloadUser } = useCurrentUser()

  return (
    <Widget title="Setup" className="mt-8">
      <ClaimUsername
        onNext={() => {
          reloadUser()
        }}
      />
    </Widget>
  )
}

export default Setup
