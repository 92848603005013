import * as React from 'react'

import emoji from 'react-easy-emoji'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'
import SectionHeader from 'ui-components/SectionHeader'
import Widget from 'ui-components/Widget'

import CompanyInfoModal from './CompanyInfoModal'

const CompanyInfo: React.FC = () => {
  const teamSlug = useTeamSlug()
  const { team, reloadTeam } = useTeam(teamSlug)
  const { showModal } = useModal()

  const renderInfoModal = (resolve) => (
    <CompanyInfoModal
      team={team}
      loadInfo={reloadTeam}
      show={true}
      onHide={() => resolve()}
      logoUrl={logo}
    />
  )

  const {
    name = '',
    website = '',
    twitter_url = '',
    description = '',
    linkedin_url = '',
    logo = '',
  } = team || {}

  return (
    <>
      <Widget
        title="Company Info"
        cta={
          <CabalButton
            onClick={() => showModal(renderInfoModal, 'render_info_modal')}
            data-testid="edit-company-button"
            variant="link"
          >
            <i className="far fa-pen"></i>
          </CabalButton>
        }
      >
        <div className="flex items-center justify-between">
          <div>
            <div className="flex items-center gap-4 ">
              <div className="flex-shrink-0">
                {logo && <Avatar size="60" name={name} src={logo} />}
              </div>
              <div>
                <Typography fontSize="16" fontWeight={600} data-testid="page-header-title">
                  {emoji(name)}
                </Typography>

                <Typography fontSize="12" color="fog" className="flex items-center">
                  {description}
                </Typography>
                <div className="flex items-center gap-2 mt-1">
                  {!!website && (
                    <Typography
                      color={'gray'}
                      component="a"
                      href={website.startsWith('https://') ? website : `https://${website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-globe"></i>
                    </Typography>
                  )}

                  {!!twitter_url && (
                    <Typography
                      color={'gray'}
                      component="a"
                      href={twitter_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-twitter"></i>
                    </Typography>
                  )}

                  {!!linkedin_url && (
                    <Typography
                      color={'gray'}
                      component="a"
                      href={linkedin_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-linkedin"></i>
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Widget>
    </>
  )
}

export default CompanyInfo
