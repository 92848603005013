import * as React from 'react'

import DropDownMenu from 'global/DropDownMenu'
import Typography from 'global/Typography'
import { UpdatedDropDownProps } from 'ui-components/DataCardV2'

import company from 'utils/api/company'

interface Props {
  dropDownMenu?: UpdatedDropDownProps
  cta?: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  companyListType?: string
}

const CTA: React.FC<Props> = ({ dropDownMenu, cta, onClick }) => {
  return (
    <div className="flex flex-shrink-0 items-center space-x-2 mx-2" onClick={onClick}>
      {cta}
      {dropDownMenu && (
        <DropDownMenu
          menuItems={dropDownMenu.menuItems}
          trigger={
            dropDownMenu.trigger ?? (
              <Typography
                className="px-2"
                color={'fog'}
                data-testid="datacard-dropdown-menu-trigger"
              >
                <i className="far fa-ellipsis-v"></i>
              </Typography>
            )
          }
        />
      )}
    </div>
  )
}

export default CTA
