import React, { useState } from 'react'

import { Field, FieldProps, Formik } from 'formik'
import { motion } from 'framer-motion'

import { useComposer } from 'components/Composer'
import { SelectWrapper } from 'containers/CandidateProfileModal/PersonalInfo'
import { UpdateProfileMutation } from 'containers/GlobalProfile'
import AvatarUpload, { AvatarUploadRef } from 'global/AvatarUpload'
import CabalButton from 'global/CabalButton'
import { TextArea, TextInput, TextInputField } from 'global/Input'
import CityInput from 'global/Input/CityInput'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import { useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import { cn } from 'utils/styles'
import { UserProfile } from 'utils/types'

interface Props {
  updateProfileMutation: UpdateProfileMutation
  profile: UserProfile
  onHide: () => void
}

const PersonalInfo: React.FC<Props> = ({ profile, updateProfileMutation, onHide }) => {
  const { user, reloadUser } = useCurrentUser()
  const { compose } = useComposer()

  const [initialValues, setInitialValues] = useState(profile)
  const [working, setWorking] = useState(false)

  const avatarUploadRef = React.useRef<AvatarUploadRef>(null)

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          setWorking(true)
          if (avatarUploadRef.current) {
            try {
              await avatarUploadRef.current.upload()
              await updateProfileMutation.mutate(values)
            } catch (error) {
              console.error('Upload failed', error)
              cabalToast({ style: 'error', content: 'Upload failed' })
            }
          } else {
            await updateProfileMutation.mutate(values)
          }
          reloadUser()
          onHide()
          setWorking(false)
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalSectionWrapper>
              <ModalInputWrapper className="">
                <ModalInputLabel>Photo</ModalInputLabel>

                <AvatarUpload
                  email={''}
                  ref={avatarUploadRef}
                  attachTo="user_avatar"
                  imageUrl={profile.avatar_url}
                  className={cn('-mt-6', { '-ml-8': profile.avatar_url })}
                />
              </ModalInputWrapper>
              <ModalInputWrapper className="">
                <ModalInputLabel>First Name</ModalInputLabel>

                <Field name="first_name">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Enter name"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="first-name"
                      autoFocus={props.field.value ? false : true}
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>Last Name</ModalInputLabel>

                <Field name="last_name">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Enter name"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="last-name"
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>Company</ModalInputLabel>
                <Field name="company_name">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Company name"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="company"
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>Title</ModalInputLabel>
                <Field name="title">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Title"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="title"
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>Location</ModalInputLabel>
                <Field name="location">
                  {(props: FieldProps) => (
                    <SelectWrapper>
                      <CityInput
                        {...props.field}
                        onBlur={() => {
                          props.form.setFieldTouched(props.field.name, true)
                        }}
                        onChange={(opt) => {
                          props.form.setFieldValue(props.field.name, opt?.value)
                        }}
                        value={
                          props.field.value
                            ? { value: props.field.value, label: props.field.value }
                            : null
                        }
                        error={props.meta.touched ? props.meta.error : undefined}
                      />
                    </SelectWrapper>
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <Field name="bio">
                  {(props: FieldProps) => (
                    <>
                      <ModalInputLabel>Bio ({props.field.value?.length || 0}/160) </ModalInputLabel>
                      <TextArea
                        {...props.field}
                        className="w-full"
                        placeholder="Bio"
                        data-testid="bio"
                        maxLength={160}
                      />
                    </>
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>Cabal URL</ModalInputLabel>
                <Field name="primary_slug">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Cabal URL"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="cabal-url"
                      disabled
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>
                  <div className="flex flex-col">
                    <span className="block">Linkedin URL</span>
                    <CabalButton
                      variant="link"
                      padding="4px 0 0 0"
                      className="self-start"
                      onClick={(e) => {
                        e.stopPropagation()
                        window.open('https://linkedin.com/in/', '_blank', 'noopener noreferrer')
                      }}
                    >
                      Get link
                    </CabalButton>
                  </div>
                </ModalInputLabel>
                <Field name="linkedin_url">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Linkedin URL"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="linkedin-url"
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>
                  <div className="flex flex-col">
                    <span className="block">X.com URL</span>
                    <CabalButton
                      variant="link"
                      padding="4px 0 0 0"
                      className="self-start"
                      onClick={(e) => {
                        e.stopPropagation()
                        window.open('https://x.com', '_blank', 'noopener noreferrer')
                      }}
                    >
                      Get link
                    </CabalButton>
                  </div>
                </ModalInputLabel>
                <Field name="twitter_url">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="X.com URL"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="twitter-url"
                    />
                  )}
                </Field>
              </ModalInputWrapper>
            </ModalSectionWrapper>

            <div className="flex justify-end items-center mt-7 mb-6">
              <CabalButton type="submit" working={working}>
                Save
              </CabalButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default PersonalInfo
