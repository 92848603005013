import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import { useAuthenticateUser } from 'components/Winsite/queries/AuthenticateUser'
import { useDeleteUpload } from 'components/Winsite/queries/DeleteUpload'
import { StyledMaterialDivSubSection } from 'components/Winsite/styles'
import { IUploadResponse } from 'components/Winsite/types/IUploadResponse'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

const FileUploadSectionDiv = styled.div`
  ${tw`text-sm w-full cursor-pointer`}
  color: #10845B;
`

interface IProps {
  uploads: IUploadResponse[] | []
  teamSlug: string
  companyDomain: string
}
export function FileUploadDisplay({ uploads, teamSlug, companyDomain }: IProps) {
  const { mutate: uploadDeleteMutate } = useDeleteUpload()
  const { data: authResponse, isError } = useAuthenticateUser(companyDomain)
  const userFailedToAuthenticate = () => {
    return isError || (authResponse && !authResponse.data.authenticated)
  }
  const handleDownload = (imageUrl: string, fileName: string) => {
    // Create a temporary anchor element
    const anchor = document.createElement('a')
    anchor.href = imageUrl
    anchor.download = fileName // specify the filename
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  }

  const fileDivId = (upload: IUploadResponse) => {
    return `file-upload-${upload.uuid}`
  }
  const removeFileDiv = (upload: IUploadResponse) => {
    const elements = document.querySelectorAll(`#${fileDivId(upload)}`)
    elements.forEach((div) => {
      div.remove()
    })
  }
  const handleDelete = (upload: IUploadResponse) => {
    uploadDeleteMutate(
      { id: upload.id, uuid: upload.uuid, teamSlug: teamSlug },
      {
        onSuccess: (_data) => {
          cabalToast({
            style: 'success',
            content: `Successfully deleted ${upload.name} file`,
          })
          removeFileDiv(upload)
        },
        onError: (_error) => {
          cabalToast({
            style: 'error',
            content: `Failed to delete ${upload.name} file`,
          })
        },
      },
    )
  }
  return (
    <FileUploadSectionDiv>
      <div className="flex flex-col gap-x-1 gap-y-2 items-start">
        {uploads.map((upload: IUploadResponse, index: number) => (
          <StyledMaterialDivSubSection
            key={`${upload.uuid}${index}`}
            id={`file-upload-${upload.uuid}`}
            borderStyle={'solid'}
          >
            <div className="flex flex-row gap-x-1 items-center" key={`${upload.uuid}${upload.id}`}>
              <div className="flex flex-row w-full items-center">
                <Typography
                  className="flex-grow"
                  onClick={() => handleDownload(upload.url, upload.name)}
                >
                  {upload.name}
                </Typography>
                <i
                  className="fas fa-light fa-download w-3 h-3"
                  onClick={() => handleDownload(upload.url, upload.name)}
                ></i>
                {!userFailedToAuthenticate() && (
                  <i className="fa fa-remove pl-3" onClick={() => handleDelete(upload)}></i>
                )}
              </div>
            </div>
          </StyledMaterialDivSubSection>
        ))}
      </div>
    </FileUploadSectionDiv>
  )
}
