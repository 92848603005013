import axios from 'axios'
import { GetReferralCodesResponse } from 'utils/types'

export default {
  sendReferralMessage: (code: string, emails: string[], message: string) => {
    return axios.post<{
      registered_emails: string[]
    }>(`/api/referral_codes/${code}/send_email`, {
      emails,
      message,
    })
  },
  getReferralCodes: () => {
    return axios.get<GetReferralCodesResponse>('/api/referral_codes')
  },
}
