import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

const BannerWrapper = styled.div`
  ${tw`justify-center relative rounded-lg px-6 py-4 mb-4 text-center`}
  background-color: ${({ theme }) => theme.popup.select};
`

interface Props {
  addedCount: number
  onHide: () => void
}

const ConnectionSuccessBanner = ({ addedCount, onHide }: Props) => {
  return (
    <BannerWrapper>
      <Typography fontWeight={600} fontSize="14">
        🎉 Nice! You added {addedCount.toLocaleString('en-US')} connections.
      </Typography>
      <div>
        <Typography fontSize="14">You can add more connection to find more matches</Typography>
      </div>
      <Typography
        className="flex absolute top-4 right-6 cursor-pointer"
        color={'rain'}
        onClick={onHide}
      >
        <i className="far fa-times"></i>
      </Typography>
    </BannerWrapper>
  )
}

export default ConnectionSuccessBanner
