import React, { useEffect, useImperativeHandle, useRef } from 'react'

import CKEditor, { MentionSuggestion } from 'global/TextEditor/ckeditor'
import { ClassicCKEditor } from 'global/TextEditor/ckeditor/base'

interface Props {
  suggestions?: MentionSuggestion[]
  value?: string
  onChange: (val: string) => void
  placeholder: string
  windowKey?: string
  minHeight?: string
  teamSlug?: string
  focus?: boolean
}

export interface RichTextInputRef {
  getMentions: () => MentionSuggestion[]
  clear: () => void
}

/**
 * immutable through prop i.e. no value prop
 * dont use this component, if you must - dont add value prop otherwise things break
 */
const RichTextInput = React.forwardRef<RichTextInputRef, Props>(
  ({ suggestions, onChange, placeholder, windowKey, value, minHeight, teamSlug, focus }, ref) => {
    const ckeditorRef = React.useRef<ClassicCKEditor>()

    const suggestionsListRef = useRef(suggestions)

    useEffect(() => {
      suggestionsListRef.current = suggestions
    }, [suggestions])

    useEffect(() => {
      if (focus) {
        ckeditorRef.current?.focus()
      }
    }, [focus, ckeditorRef.current])

    useImperativeHandle(
      ref,
      () => ({
        getMentions: () => {
          if (!ckeditorRef.current) return []

          const mentions: MentionSuggestion[] = []
          const dummyEl = document.createElement('div')
          dummyEl.innerHTML = ckeditorRef.current.getData()
          const mentionNodes = dummyEl.querySelectorAll('.mention')
          for (let i = 0; i < mentionNodes.length; i++) {
            const mentionNode = mentionNodes.item(i)
            const mention = suggestionsListRef.current.find(
              (s) => s.id === mentionNode.getAttribute('data-mention')?.substring(1),
            )

            mention && mentions.push(mention)
          }
          return mentions
        },
        clear: () => {
          ckeditorRef.current?.setData('')
        },
      }),
      [ckeditorRef.current],
    )

    return (
      <CKEditor
        companySlug={teamSlug}
        ckEditorRef={ckeditorRef}
        placeholder={placeholder}
        onChange={onChange}
        minHeight={!!minHeight ? minHeight : '35px'}
        value={value}
        minimal
        windowKey={windowKey}
        mentionSuggestions={suggestions}
      />
    )
  },
)

export default RichTextInput
