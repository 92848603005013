import React from 'react'
import Avatar from 'global/Avatar'
import useColorMode from 'utils/hooks/useColorMode'
import { Logo } from './styles'
import Typography from 'global/Typography'

interface Props {
  logo: string | null
  companyName: string
  showCompanyName?: boolean
}

const CardLogo: React.VFC<Props> = ({ logo, companyName, showCompanyName = true }) => {
  const { isDarkMode } = useColorMode()

  if (logo) {
    return (
      <div>
        <Avatar src={logo} name={companyName} size="64" round />
        {showCompanyName && (
          <Typography
            component="div"
            className="mt-2"
            textTransform="uppercase"
            fontSize="10"
            letterSpacing={'0.05em'}
            fontWeight={600}
            color="widget_title"
          >
            {companyName}
          </Typography>
        )}
      </div>
    )
  } else {
    return (
      <div>
        {isDarkMode ? <Logo src={'/cabal-svg-dark.svg'} /> : <Logo src={'/cabal-svg-light.svg'} />}
      </div>
    )
  }
}

export default CardLogo
