import React from 'react'

import { Field, FieldProps, Form, Formik } from 'formik'
import Cookies from 'js-cookie'
import styled from 'styled-components'
import tw from 'twin.macro'
import { array, object, string } from 'yup'

import { Card } from 'components/MemberOnboardingPage'
import { useCandidateProfile } from 'containers/CandidateProfileModal'
import MultiDocumentUploader from 'containers/CandidateProfileModal/MultiDocumentUploader'
import { EducationEntry, WorkHistoryEntry } from 'containers/CandidateProfileModal/WorkHistory'
import {
  COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS,
  LEVEL_OPTIONS,
  OFFICE_WITH_OPEN_TO_ALL_OPTIONS,
  OPPORTUNITY_TYPE_OPTIONS,
  ROLE_FUNCTION_OPTIONS,
  STAGE_OPTIONS,
  STAGE_WITH_OPEN_TO_ALL_OPTIONS,
} from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import {
  MultiSelect,
  MultiSelectField,
  Select,
  SelectField,
  TextArea,
  TextInput,
} from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'

const SelectWrapper = styled.div`
  .select__value-container {
    justify-content: flex-start !important;
  }
`

const StyledHr = styled.hr`
  ${tw`pt-2 pb-2`}
  border-top: ${({ theme }) => theme.border};
`

const careerInfoSchema = object({
  functions: array()
    .of(string())
    .min(1, 'At least one option must be selected')
    .required('This field is required'),
  level: string().required('This field is required'),
})

export default function TalentProfile(props: {
  onContinue?: () => void
  onBack?: () => void
  working: boolean
  creatingCandidateProfile?: boolean
}) {
  const [step, setCurrentStep] = React.useState<'professional_info' | 'work_education_history'>(
    'work_education_history',
  )

  const { candidateProfile, updateCandidateProfile } = useCandidateProfile()

  const [uploadedDocuments, setUploadedDocuments] = React.useState<File[]>([])
  const handleDocumentsUploaded = (documents: File[]) => {
    setUploadedDocuments(documents)
  }

  if (step === 'work_education_history') {
    return (
      <Card className="mb-20 relative">
        <Typography
          color="fog"
          onClick={props.onBack}
          className="absolute top-[30px] left-6 cursor-pointer"
        >
          <i className="far fa-chevron-left mr-2" />
        </Typography>
        <div className="flex flex-col items-center py-2 mb-3">
          <Typography component="div" fontSize="24" fontWeight={600}>
            Work & Education History
          </Typography>
        </div>
        <div className="px-4 mt-4">
          <Formik
            initialValues={candidateProfile}
            validationSchema={careerInfoSchema}
            onSubmit={(values) => {
              updateCandidateProfile({
                ...values,
                archivedAt:
                  Cookies.get(`candidate_profile_is_archived`) === 'true'
                    ? new Date().toISOString()
                    : null,
                documents: uploadedDocuments,
                bio: candidateProfile.bio,
                workHistory: candidateProfile.workHistory,
                educationHistory: candidateProfile.educationHistory,
              })
              setCurrentStep('professional_info')
            }}
          >
            {({ handleSubmit }) => (
              <Form
                onSubmit={handleSubmit}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault()
                  }
                }}
              >
                <ModalInputWrapper className="py-2">
                  <ModalInputLabel>
                    <div className="flex items-center justify-start h-full">Function</div>
                  </ModalInputLabel>
                  <Field name="functions">
                    {(props: FieldProps) => (
                      <MultiSelectField
                        placeholder="Primary job function(s)"
                        options={ROLE_FUNCTION_OPTIONS}
                        fieldProps={props}
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
                <ModalInputWrapper className="py-2">
                  <ModalInputLabel>
                    <div className="flex items-center justify-start h-full">Level</div>
                  </ModalInputLabel>
                  <SelectWrapper>
                    <Field name="level">
                      {(props: FieldProps) => (
                        <SelectField
                          placeholder="Experience level"
                          fieldProps={props}
                          options={LEVEL_OPTIONS}
                        />
                      )}
                    </Field>
                  </SelectWrapper>
                </ModalInputWrapper>
                <ModalInputWrapper className="py-2">
                  <ModalInputLabel>
                    <div className="flex items-center justify-start h-full">Stage Experience</div>
                  </ModalInputLabel>
                  <Field name="fundingStageExperience">
                    {(props: FieldProps) => (
                      <MultiSelectField
                        placeholder="Company stage experience"
                        fieldProps={props}
                        options={STAGE_OPTIONS}
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
                <ModalInputWrapper className="py-2">
                  <ModalInputLabel>
                    <div className="flex items-center justify-start h-full">Bio</div>
                  </ModalInputLabel>
                  <TextArea
                    placeholder="Add a one-line bio"
                    value={candidateProfile.bio}
                    onChange={(e) => updateCandidateProfile({ bio: e.target.value })}
                  />
                </ModalInputWrapper>
                <MultiDocumentUploader
                  uploadedDocuments={uploadedDocuments}
                  onDocumentsUploaded={(documents) => handleDocumentsUploaded(documents)}
                />{' '}
                <div className="pt-3">
                  <StyledHr />
                </div>
                <div className="flex justify-between items-center">
                  <Typography fontSize="14" fontWeight={600}>
                    Work History
                  </Typography>
                  <CabalButton
                    variant="link"
                    type="button"
                    onClick={() => {
                      updateCandidateProfile({
                        workHistory: [
                          {
                            company: '',
                            title: '',
                            fromMonth: null,
                            fromYear: null,
                            toMonth: null,
                            toYear: null,
                            summary: '',
                            key: Date.now(),
                          },
                          ...candidateProfile.workHistory,
                        ],
                      })
                    }}
                  >
                    + Add More
                  </CabalButton>
                </div>
                {candidateProfile.workHistory.map((workHistory, i) => (
                  <WorkHistoryEntry
                    key={`${workHistory.uuid || workHistory.key}-${i}`}
                    entry={workHistory}
                    onUpdate={(newEntry) => {
                      const newWorkHistory = [...candidateProfile.workHistory]
                      newWorkHistory[i] = {
                        ...newWorkHistory[i],
                        ...newEntry,
                      }
                      updateCandidateProfile({ workHistory: newWorkHistory })
                    }}
                    hasMultipleEntries={candidateProfile.workHistory.length > 1}
                    onRemove={() => {
                      updateCandidateProfile({
                        workHistory: candidateProfile.workHistory.filter(
                          (_, innerIndex) => innerIndex !== i,
                        ),
                      })
                    }}
                    isLast={i === candidateProfile.workHistory.length - 1}
                  />
                ))}
                <StyledHr />
                <div className="flex justify-between items-center">
                  <Typography fontSize="14" fontWeight={600}>
                    Education
                  </Typography>
                  <CabalButton
                    variant="link"
                    type="button"
                    onClick={() => {
                      updateCandidateProfile({
                        educationHistory: [
                          {
                            school: '',
                            fieldOfStudy: '',
                            fromMonth: null,
                            fromYear: null,
                            toMonth: null,
                            toYear: null,
                            summary: '',
                            key: Date.now(),
                          },
                          ...candidateProfile.educationHistory,
                        ],
                      })
                    }}
                  >
                    + Add More
                  </CabalButton>
                </div>
                {candidateProfile.educationHistory.map((entry, i) => (
                  <EducationEntry
                    key={`${entry.uuid || entry.key}-${i}`}
                    entry={entry}
                    isLast={i === candidateProfile.educationHistory.length - 1}
                    onUpdate={(newEntry) => {
                      const newEducationHistory = [...candidateProfile.educationHistory]
                      newEducationHistory[i] = {
                        ...newEducationHistory[i],
                        ...newEntry,
                      }
                      updateCandidateProfile({ educationHistory: newEducationHistory })
                    }}
                    onRemove={() => {
                      updateCandidateProfile({
                        educationHistory: candidateProfile.educationHistory.filter(
                          (_, innerIndex) => innerIndex !== i,
                        ),
                      })
                    }}
                  />
                ))}
                <div className="flex justify-center pt-4">
                  <CabalButton
                    type="submit"
                    variant="primary"
                    rightIcon={<i className="far fa-arrow-right" />}
                    working={props.working}
                  >
                    Continue
                  </CabalButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Card>
    )
  } else if (step === 'professional_info') {
    return (
      <Card className="relative">
        {props?.onBack && (
          <Typography
            color="fog"
            onClick={() => setCurrentStep('work_education_history')}
            className="absolute top-[30px] left-6 cursor-pointer"
          >
            <i className="far fa-chevron-left mr-2" />
          </Typography>
        )}
        <div className="flex flex-col items-center py-2">
          <Typography component="div" fontSize="24" fontWeight={600}>
            Job & Preferences
          </Typography>
        </div>
        <div className="px-4">
          <div className="pt-2 flex justify-between items-center">
            <Typography fontSize="12" fontWeight={600}>
              Role Preferences
            </Typography>
          </div>

          <ModalInputWrapper className="py-2">
            <ModalInputLabel>
              <div className="flex items-center justify-start h-full">Type</div>
            </ModalInputLabel>
            <MultiSelect
              placeholder="Preferred role type(s)"
              options={OPPORTUNITY_TYPE_OPTIONS}
              value={candidateProfile.opportunityType}
              onChange={(opportunityType) =>
                updateCandidateProfile({ opportunityType: opportunityType })
              }
            />
          </ModalInputWrapper>

          <ModalInputWrapper className="py-2">
            <ModalInputLabel>
              <div className="flex items-center justify-start h-full">Function</div>
            </ModalInputLabel>

            <MultiSelect
              placeholder="Preferred job function"
              options={ROLE_FUNCTION_OPTIONS}
              value={candidateProfile.functionPreference}
              onChange={(functionPreference) =>
                updateCandidateProfile({ functionPreference: functionPreference })
              }
            />
          </ModalInputWrapper>

          <ModalInputWrapper className="py-2">
            <ModalInputLabel>
              <div className="flex items-center justify-start h-full">Level</div>
            </ModalInputLabel>

            <MultiSelect
              placeholder="Preferred job level"
              options={LEVEL_OPTIONS}
              value={candidateProfile.levelPreference}
              onChange={(levelPreference) =>
                updateCandidateProfile({ levelPreference: levelPreference })
              }
            />
          </ModalInputWrapper>

          <ModalInputWrapper className="py-2">
            <ModalInputLabel>
              <div className="flex items-center justify-start h-full">Compensation</div>
            </ModalInputLabel>
            <TextInput
              value={candidateProfile.minimumSalary}
              placeholder="Compensation expectation"
              onChange={(e) => updateCandidateProfile({ minimumSalary: e.target.value })}
            />
          </ModalInputWrapper>

          <div className="pt-4 flex justify-between items-center">
            <Typography fontSize="12" fontWeight={600}>
              Company Preferences
            </Typography>
          </div>

          <ModalInputWrapper className="py-2">
            <ModalInputLabel>
              <div className="flex items-center justify-start h-full">Size</div>
            </ModalInputLabel>

            <MultiSelect
              placeholder="Preferred company size"
              options={COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS}
              value={candidateProfile.idealCompanySize}
              onChange={(idealCompanySize) =>
                updateCandidateProfile({ idealCompanySize: idealCompanySize })
              }
            />
          </ModalInputWrapper>

          <ModalInputWrapper className="py-2">
            <ModalInputLabel>
              <div className="flex items-center justify-start h-full">Stage</div>
            </ModalInputLabel>

            <MultiSelect
              placeholder="Preferred company stage(s)"
              options={STAGE_WITH_OPEN_TO_ALL_OPTIONS}
              value={candidateProfile.stagePreference}
              onChange={(stagePreference) =>
                updateCandidateProfile({ stagePreference: stagePreference })
              }
            />
          </ModalInputWrapper>

          <ModalInputWrapper className="py-2">
            <ModalInputLabel>
              <div className="flex items-center justify-start h-full">Location</div>
            </ModalInputLabel>

            <MultiSelect
              placeholder="Preferred work location(s)"
              options={OFFICE_WITH_OPEN_TO_ALL_OPTIONS}
              value={candidateProfile.officePreference}
              onChange={(officePreference) =>
                updateCandidateProfile({ officePreference: officePreference })
              }
            />
          </ModalInputWrapper>

          <div className="pt-4 flex justify-between items-center">
            <Typography fontSize="12" fontWeight={600}>
              General
            </Typography>
          </div>

          <ModalInputWrapper className="py-2">
            <ModalInputLabel>
              <div className="flex items-center justify-start h-full">Work Status</div>
            </ModalInputLabel>
            <SelectWrapper>
              <Select
                placeholder="Work Status"
                options={[
                  { value: 'legal_to_work_in_us', label: 'Legal to Work in US' },
                  { value: 'visa_requirement', label: 'Visa Requirement' },
                ]}
                value={candidateProfile.workStatus}
                onChange={(workStatus) =>
                  updateCandidateProfile({ workStatus: workStatus || undefined })
                }
              />
            </SelectWrapper>
          </ModalInputWrapper>

          <ModalInputWrapper className="py-2">
            <ModalInputLabel>
              <div className="flex items-center justify-start h-full">Looking for</div>
            </ModalInputLabel>
            <TextArea
              placeholder="Add details about your ideal role"
              value={candidateProfile.whatYoureLookingFor}
              onChange={(e) => updateCandidateProfile({ whatYoureLookingFor: e.target.value })}
            />
          </ModalInputWrapper>

          <div className="flex justify-center pt-4">
            <CabalButton
              type="submit"
              variant="primary"
              rightIcon={<i className="far fa-arrow-right" />}
              disabled={props.working}
              working={props.working}
              onClick={() => {
                props.onContinue?.()
              }}
            >
              Continue
            </CabalButton>
          </div>
        </div>
      </Card>
    )
  } else {
    return null
  }
}
