import React from 'react'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'

import { AdvisorModel } from 'utils/types'

interface Props {
  advisor: AdvisorModel
  onRemove: () => void
}

const AdvisorsRow: React.FC<Props> = ({ advisor, onRemove }) => {
  return (
    <div>
      <DataCardV2
        key={advisor.uuid}
        avatar={<Avatar name={advisor.name} src={advisor.avatar_url} />}
        title={advisor.name}
        description={advisor.email}
        className="px-0"
        cta={
          <div className="-mr-2">
            <CabalButton onClick={onRemove} variant="tertiary">
              <Typography fontSize="12" color="red">
                <i className="far fa-times mr-1" />
                Remove
              </Typography>
            </CabalButton>
          </div>
        }
      />
    </div>
  )
}

export default AdvisorsRow
