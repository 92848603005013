import axios from 'axios'
import { GetDocumentResponse, GetDocumentsResponse } from 'utils/types'

type Assets = Record<
  string,
  {
    key: string
    label: string
    url: string
    name: string
  }
>

export default {
  loadDocument: (id: string) => {
    return axios.get<GetDocumentResponse>(`/api/documents/${id}.json`)
  },

  loadDocuments: () => {
    return axios.get<GetDocumentsResponse>(`/api/documents`)
  },

  deleteDocument: (key: string) => {
    return axios.delete(`/api/documents/${key}`)
  },
}
