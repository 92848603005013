import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import { FetchCompanyDomain } from 'components/Winsite/FetchCompanyDomain'
import { FetchLinkedinUrl } from 'components/Winsite/FetchLinkedinUrl'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

import useColorMode from 'utils/hooks/useColorMode'
import { isNullOrUndefined } from 'utils/string'

import { CompanyInput } from './CompanyInput'
import { Layout } from './Layout'
import { useCompanyPage } from './queries/CompanyPage'

export function Winsite() {
  const placeHolder = 'Enter your company to start'
  const [companyName, setcompanyName] = useState<string | null>('')
  const {
    isLoading,
    refetch,
    data: teamData,
  } = useCompanyPage(
    { companyName: companyName, linkedinUrl: null, companySlug: null, companyDomain: null },
    {
      enabled: false,
      retry: 3,
    },
  )
  const { isMobile } = useColorMode()
  const history = useHistory()

  const handleSubmit = () => {
    refetch()
  }

  if (teamData) {
    const companyData = teamData.data
    if (isNullOrUndefined(companyData.linkedin_url)) {
      return <FetchLinkedinUrl companyName={companyData.company_name} />
    }
    if (isNullOrUndefined(companyData.company_domain)) {
      return (
        <FetchCompanyDomain
          companyName={companyData.company_name}
          linkedinUrl={companyData.linkedin_url}
        />
      )
    }
    history.push(`/${companyData.team_slug}?spinnerDisplay=true`)
  }

  return (
    <Layout>
      <div className="flex flex-col items-center mt-32">
        <Typography
          textAlign="center"
          fontSize={isMobile ? '24' : '40'}
          fontWeight={700}
          lineHeight="normal"
          color="white"
        >
          Your supporters want to help. <br />
          Make it easy on them.
        </Typography>
        <div className="flex flex-col mx-auto min-w-[308px] sm:min-w-[308px] mt-10 gap-5">
          <CompanyInput
            data-testid="company-slug-input"
            onChange={(companyName: string | null) => {
              setcompanyName(companyName)
            }}
            placeHolder={placeHolder}
          />
          <CabalButton
            onClick={handleSubmit}
            style={{ backgroundColor: '#03C168' }}
            className="w-full"
            rightIcon={<i className={isLoading ? 'fa-spin fa fa-spinner' : 'far fa-arrow-right'} />}
            disabled={!companyName || isLoading}
          >
            Continue
          </CabalButton>
        </div>
      </div>
    </Layout>
  )
}
