import React, { useCallback, useState } from 'react'
import cx from 'classnames'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { AdvisorModel, AgreementTemplateField, Params } from 'utils/types'
import Field from './Field'

interface Props {
  advisor: AdvisorModel
  fields: AgreementTemplateField[]
  params: Params
  setParams: (avisor_uuid: string, field_uuid: string, value: any) => void
}

const AdvisorRow: React.VFC<Props> = ({ advisor, fields, params, setParams }) => {
  const [editing, setEditing] = useState(false)

  const setValue = useCallback(
    (field_uuid: string, val: any) => {
      setParams(advisor.uuid, field_uuid, val)
    },
    [advisor.uuid, setParams],
  )

  return (
    <div className="flex border-b py-3 border-light dark:border-dark">
      <div className="flex-initial">
        <div className="flex mr-3 items-center w-72">
          <div className="flex-initial items-center whitespace-nowrap">
            <CabalButton
              leftIcon={<i className={cx('far', editing ? 'fa-chevron-up' : 'fa-pencil')} />}
              variant="secondary"
              size="small"
              className="mr-4"
              onClick={() => setEditing(!editing)}
              data-testid={`edit-advisor-${advisor.email}`}
            />
            <Avatar src={advisor.avatar_url} name={advisor.name} round />
          </div>
          <div className="flex-1 ml-3 whitespace-normal">
            <Typography component="div" fontSize="16" fontWeight={600}>
              {advisor.name}
            </Typography>
            <Typography fontSize="12">{advisor.email}</Typography>
          </div>
        </div>
      </div>
      <div className="flex-1">
        {!editing &&
          Object.entries(params)
            .map<React.ReactNode>(([fieldId, val]) => {
              const field = fields.find((f) => f.uuid === fieldId)
              if (!field) return <></>

              return (
                <Tooltip key={field.uuid} label={field.name}>
                  <>
                    {/* <Typography fontWeight={600} fontSize="12">
                      {field.name}:{' '}
                    </Typography> */}
                    {!!val ? val : <Typography color="red">(Missing {field.name})</Typography>}
                  </>
                </Tooltip>
              )
            })
            .reduce((prev, curr) => [prev, ', ', curr])}
        {editing &&
          Object.entries(params).map<React.ReactNode>(([fieldId, val]) => {
            const field = fields.find((f) => f.uuid === fieldId)
            if (!field) return <></>

            return <Field value={val} setValue={setValue} field={field} labeled key={fieldId} />
          })}
      </div>
    </div>
  )
}

export default AdvisorRow
