import React from 'react'

const VariablesList: React.VFC = () => {
  return (
    <div className="mb-4">
      <div>
        <p className="pb-2">
          <b>For messages:</b>
        </p>
        <p>{'{{first_name}}'}</p>
        <p>{'{{full_name}}'}</p>
      </div>

      {/* <div className="pt-4">
        <p className="pb-2">
          <b>For intro request:</b>
        </p>
        <p>{'{{connection_full_name}}'}</p>
        <p>{'{{connection_first_name}}'}</p>
        <p>{'{{connection_company}}'}</p>
        <p>{'{{connection_linkedin_url}}'}</p>
      </div> */}
    </div>
  )
}

export default VariablesList
