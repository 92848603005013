import React from 'react'

import { IntroConnection } from 'containers/Portfolio/MemberView/types/IntroConnection'
import Typography from 'global/Typography'

interface Props {
  facilitator: IntroConnection
  introRequest: any
}

const FacilitatedBy: React.FC<Props> = ({ facilitator, introRequest }) => {
  const name =
    introRequest?.requestable?.item_type === 'InvestorCompanyJob'
      ? introRequest?.facilitator?.team_name
      : facilitator.name || ''

  return (
    <div className="flex justify-center items-center my-6   gap-1">
      <Typography fontSize="14" color="fog">
        Facilitated by
      </Typography>
      <Typography fontSize="14">{name}</Typography>
    </div>
  )
}

export default FacilitatedBy
