import React, { useEffect } from 'react'

import { useMutation } from 'react-query'
import styled from 'styled-components'

import AddNoteModal from 'containers/RequestCenter/IntroRequestDetails/AddNoteModal'
import { useIntroRequestButton } from 'containers/RequestCenter/IntroRequestDetails/Header'
import { IIntroRequest } from 'containers/RequestCenter/types/ITypes'
import CabalButton from 'global/CabalButton'
import DropDownMenu, { DropdownMenuItem } from 'global/DropDownMenu'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { cn } from 'utils/styles'

const MenuWrapper = styled.div<{ isHovering: boolean }>`
  border: 1px solid
    ${({ theme, isHovering }) => (isHovering ? `${theme.colors.rain}50` : 'transparent')};
  background-color: ${({ theme }) => theme.layout.nav_bg_color};
`

const CTAComponent = ({
  status,
  isEditing,
  introRequestDetails,
  refetch,
}: {
  status: string
  isEditing: boolean
  introRequestDetails: IIntroRequest
  refetch: () => void
}) => {
  const { onClick: onClickCTA, buttonText: ctaText } = useIntroRequestButton(
    introRequestDetails,
    refetch,
  )
  const { showModal } = useModal()

  const renderAddNoteModal = (resolve: () => void) => (
    <AddNoteModal
      onHide={() => resolve()}
      introRequestData={introRequestDetails}
      refetch={refetch}
    />
  )

  const { mutate: deleteIntroRequest } = useMutation(
    () => callApi(api.deleteIntroRequestV2, introRequestDetails.intro_request_uuid),
    {
      onSuccess: () => {
        refetch()
        cabalToast({ content: 'Intro deleted', style: 'success' })
      },
    },
  )

  let menuItems: DropdownMenuItem[] = []

  menuItems = [
    {
      label: 'Add Note',
      onSelect: () => {
        showModal(renderAddNoteModal, 'render_note_modal')
      },
    },
    {
      label: 'Delete',
      onSelect: () => deleteIntroRequest(),
    },
  ]

  return (
    <div className="flex items-center justify-between">
      {menuItems && menuItems.length > 0 && (
        <DropDownMenu
          menuItems={menuItems}
          trigger={
            <MenuWrapper
              isHovering={isEditing}
              className={cn(
                'flex items-center whitespace-nowrap w-fit gap-2 absolute right-1 bottom-2.5 px-2 rounded-md h-[28px]',
                { 'drop-shadow': isEditing },
              )}
            >
              {isEditing && (
                <Typography
                  fontSize="14"
                  onClick={(e) => {
                    e.stopPropagation()
                    onClickCTA?.()
                  }}
                  color="primary"
                  fontWeight={500}
                >
                  {ctaText}
                </Typography>
              )}
              <Typography color={isEditing ? 'primary' : 'fog'}>
                <i className="far fa-ellipsis-h"></i>
              </Typography>
            </MenuWrapper>
          }
        />
      )}
    </div>
  )
}

export default CTAComponent
