import React from 'react'

import Expand from 'react-expand-animated'

import UploadZone from 'global/Input/UploadZone'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import { StyledCabalButton, UploadWrapper } from '../styles'

interface IProps {
  showUpload: boolean
  internalKey: string
  handleBack: () => void
}

export function Upload({ showUpload, internalKey, handleBack }: IProps) {
  const faClass = 'i.far.fa-times[aria-hidden="true"]'
  const removeDiv = (fileName: string) => {
    const fileNameId = fileName.replace(/\s/g, '-').replace(/\./g, '-')
    const elements = document.querySelectorAll(`#${fileNameId}`)
    elements.forEach((div) => {
      // Check if the div contains the specific text content
      if (div.textContent.includes(fileName)) {
        if (div) {
          const closeIcon = div.querySelector(`${faClass}`)
          closeIcon?.click()
        }
      }
    })
  }
  return (
    <Expand open={showUpload}>
      <div className="max-w-xl mx-auto mt-6 w-full flex flex-col gap-y-2">
        <UploadWrapper className="rounded">
          <UploadZone
            uploadOnSelect
            hideFiles={false}
            hideProgress={false}
            accept={['pdf', 'doc', 'png', 'jpg', 'video']}
            multiple={false}
            onUpload={(upload) => {
              cabalToast({
                content: 'File Uploaded Successfully',
                style: 'success',
              })
              if (!upload.length) return
              upload.forEach((file) => {
                removeDiv(file.file_name)
              })
            }}
            attachTo={internalKey}
            prompt={
              <div className="py-4">
                <Typography className="mt-1">
                  Drag and drop to upload or <Typography color="purple">click to browse</Typography>
                </Typography>
              </div>
            }
          />
        </UploadWrapper>
        <div className="flex flex-row gap-x-1">
          <StyledCabalButton onClick={handleBack}>Done</StyledCabalButton>
        </div>
      </div>
    </Expand>
  )
}
