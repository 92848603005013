import React from 'react'
import styled from 'styled-components'
import { compose } from 'styled-system'
import HeaderSection from '../HeaderSection'
import ListCard from './ListCard'

import salesforceImage from '../../../../assets/images/salesforce-200.png'
import hubspotImage from '../../../../assets/images/hubspot-200.png'
import Typography from 'global/Typography'
import { useQuery } from 'react-query'
import api, { callApi } from 'utils/api'
import { useTeamSlug } from 'store/hooks'
import { useAccessControl } from 'global/AccessControl'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import { EditListModal } from 'components/EditList'

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 700px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const NewScreenList = ({ showHeader = false }) => {
  const teamSlug = useTeamSlug()

  const settingsQuery = useQuery([teamSlug, 'teamSettings'], () => callApi(api.getTeamSettings))
  const team_connected_sfdc = settingsQuery.data?.team_connected_sfdc
  const team_connected_hubspot = settingsQuery.data?.team_connected_hubspot

  const renderNewDynamicListModal: RenderModal = (resolve) => (
    <EditListModal dynamic={true} onHide={() => resolve(false)} teamSlug={teamSlug} />
  )

  const renderNewListModal: RenderModal = (resolve) => (
    <EditListModal dynamic={false} onHide={() => resolve(false)} teamSlug={teamSlug} />
  )

  const { showModal } = useModal()
  return (
    <div className="max-w-3xl flex flex-col self-center">
      {showHeader && <HeaderSection
        icon={<i className="fal fa-thumbs-up "></i>}
        title={'Create your first List'}
        description={`Create and share Lists for sales prospects, open roles, potential investors, and more.`}
      />}
      <GridWrapper className={`px-4 sm:px-12 gap-4 ${showHeader ? 'py-5' : 'py-5'}`}>
        <ListCard
          icon={<img className="rounded-full" width={'30'} src={salesforceImage} />}
          title={'Import from Salesforce'}
          description={`Sales`}
          onClick={() =>
            team_connected_sfdc
              ? showModal(renderNewDynamicListModal, 'render_hubspot_list_modal')
              : window.location.assign(`/salesforce_auth`)
          }
        />

        <ListCard
          icon={<img className="rounded-full" width={'30'} src={hubspotImage} />}
          title={'Import from Hubspot'}
          description={`Sales`}
          onClick={() =>
            team_connected_hubspot
              ? showModal(renderNewDynamicListModal, 'render_hubspot_list_modal')
              : window.location.host === 'localhost:3000'
              ? window.location.assign(`/auth/hubspotdev?return_to=${window.location.href}`)
              : window.location.assign(`/auth/hubspot?return_to=${window.location.href}`)
          }
        />

        <ListCard
          icon={
            <Typography fontSize="20" color={'purple'}>
              <i className="fal fa-list"></i>
            </Typography>
          }
          title={'Create a manual list'}
          description={`Sales, hiring, fundraising`}
          onClick={() => showModal(renderNewListModal, 'render_new_list_modal')}
        />
      </GridWrapper>
    </div>
  )
}

export default NewScreenList
