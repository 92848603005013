import * as Sentry from '@sentry/react'
import { SeverityLevel } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

interface Context {
  [x: string]: unknown
}

class ErrorLogger {
  static enabled = process.env.NODE_ENV !== 'development'

  static init = () => {
    Sentry.init({
      dsn: SENTRY_FE_PROJECT,
      integrations: [new BrowserTracing(), new Sentry.Replay()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,
      enabled: this.enabled,
      environment: process.env.NODE_ENV,
      replaysOnErrorSampleRate: 1,
      release: SOURCE_VERSION,
      initialScope: window.USER
        ? { user: { id: window.USER.uuid, email: window.USER.email } }
        : undefined,
      beforeBreadcrumb: (breadcrumb) => {
        if (
          breadcrumb.category === 'xhr' &&
          breadcrumb.data?.url.contains('highlight.getcabal.com')
        ) {
          return null
        }
        return breadcrumb
      },
    })
  }

  static identify = Sentry.setUser

  static fatal(error: unknown, additionalData?: Context | null) {
    this.capture(error, 'fatal', additionalData)
  }

  static error(error: unknown, additionalData?: Context | null) {
    this.capture(error, 'error', additionalData)
  }

  static warning(error: unknown, additionalData?: Context | null) {
    this.capture(error, 'warning', additionalData)
  }

  static log(error: unknown, additionalData?: Context | null) {
    this.capture(error, 'log', additionalData)
  }

  static info(error: unknown, additionalData?: Context | null) {
    this.capture(error, 'info', additionalData)
  }

  static debug(error: unknown, additionalData?: Context | null) {
    this.capture(error, 'debug', additionalData)
  }

  private static capture(error: unknown, severity: SeverityLevel, additionalData?: Context | null) {
    if (!this.enabled) {
      if (severity === 'info') {
        console.info(error, additionalData)
      } else if (severity === 'warning') {
        console.warn(error, additionalData)
      } else if (severity === 'error') {
        console.error(error, additionalData)
      } else {
        console.error(error, additionalData)
      }
    } else {
      if (typeof error === 'string') {
        this.captureMessage(error, severity, additionalData)
      } else {
        this.captureError(error, severity, additionalData)
      }
    }
  }

  private static captureError(
    error: unknown,
    severity: SeverityLevel,
    additionalData?: Context | null,
  ) {
    Sentry.withScope((scope) => {
      scope.setLevel(severity)
      if (additionalData) scope.setContext('Additional data', additionalData)
      Sentry.captureException(error)
    })
  }

  private static captureMessage(
    error: string,
    severity: SeverityLevel,
    additionalData?: Context | null,
  ) {
    Sentry.withScope((scope) => {
      scope.setLevel(severity)
      if (additionalData) scope.setContext('Additional data', additionalData)
      Sentry.captureMessage(error)
    })
  }
}

export default ErrorLogger
