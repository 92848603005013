import { useMemo } from 'react'

import { useMutation, useQuery } from 'react-query'

import { useAccessControl } from 'global/AccessControl'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  loadAdvisor,
  loadAdvisorProps,
  loadAdvisors,
  loadAdvisorsLoadingKey,
  selectAdvisor,
  selectAdvisors,
  selectLoadingAdvisor,
  setAdvisor,
  setAdvisors,
  setAdvisorsLoaded,
  setLoadingAdvisors,
  unsetLoadingAdvisors,
} from 'store/reducers/advisorsReducer'

import api, { callApi } from 'utils/api'

export const useLoadAdvisor = (_teamSlug?: string, _advisorUuid?: string) => {
  const dispatch = useAppDispatch()
  const loadingAdvisor = _advisorUuid ? useAppSelector(selectLoadingAdvisor(_advisorUuid)) : false

  return async (teamSlug = _teamSlug, advisorUuid = _advisorUuid) => {
    if (loadingAdvisor) return

    await dispatch(
      loadAdvisor({
        teamSlug: teamSlug!,
        uuid: advisorUuid!,
      }),
    )
  }
}

export const useAdvisor = (teamSlug: string, advisorUuid: string) => {
  const dispatch = useAppDispatch()
  const advisor = useAppSelector(selectAdvisor(advisorUuid))

  const { refetch, isLoading } = useQuery(
    ['loadAdvisor', teamSlug, advisorUuid],
    () => {
      dispatch(setLoadingAdvisors(advisorUuid))
      return callApi(api.getAdvisor, advisorUuid, teamSlug)
    },
    {
      onSuccess: ({ advisor }) => {
        dispatch(setAdvisor(advisor))
        dispatch(unsetLoadingAdvisors(advisorUuid))
      },
      staleTime: Infinity,
      enabled: !advisor,
    },
  )

  return { advisor, reloadAdvisor: refetch, isLoading }
}

export const useLoadAdvisors = (_props?: loadAdvisorProps) => {
  const dispatch = useAppDispatch()
  const loadingAdvisors = _props
    ? useAppSelector(selectLoadingAdvisor(loadAdvisorsLoadingKey(_props)))
    : false

  return async (props = _props) => {
    if (loadingAdvisors) return

    await dispatch(loadAdvisors(props!))
  }
}

type useAdvisorsProps =
  | { teamSlug: string; groupUuids?: never }
  | { teamSlug?: never; groupUuids: string[] }

export const useAdvisors = (props: useAdvisorsProps) => {
  const { teamSlug, groupUuids } = props
  const dispatch = useAppDispatch()
  const { canAccess } = useAccessControl()

  const snake_case_props = {
    team_slug: teamSlug,
    group_uuids: groupUuids as string[] | undefined,
  } as loadAdvisorProps
  const key = loadAdvisorsLoadingKey(snake_case_props)

  const advisors = useAppSelector(selectAdvisors(snake_case_props))
  const canViewAdvisors = teamSlug ? canAccess('canViewAdvisors', teamSlug) : true

  const advisorsMap = useMemo(
    () => Object.fromEntries((advisors || []).map((advisor) => [advisor.uuid, advisor])),
    [advisors],
  )

  const advisorsMapByUserUuid = useMemo(
    () => Object.fromEntries((advisors || []).map((advisor) => [advisor.user_uuid, advisor])),
    [advisors],
  )

  let canLoad = !advisors
  if (!canViewAdvisors || (teamSlug !== undefined && teamSlug === '')) canLoad = false

  const { refetch, isSuccess, isLoading } = useQuery(
    ['loadAdvisors', groupUuids, teamSlug],
    () => {
      dispatch(setLoadingAdvisors(key))
      return callApi(api.getAdvisors, snake_case_props)
    },
    {
      onSuccess: ({ advisors }) => {
        dispatch(setAdvisors(advisors))
        dispatch(unsetLoadingAdvisors(key))
        teamSlug && dispatch(setAdvisorsLoaded(teamSlug))
      },
      staleTime: Infinity,
      enabled: canLoad,
    },
  )

  const { mutateAsync: loadAdvisors } = useMutation(
    ['loadAdvisors', groupUuids, teamSlug],
    (advisor_uuids: string[]) =>
      callApi(api.getAdvisors, {
        advisor_uuids,
      }),
    {
      onSuccess: ({ advisors }) => {
        dispatch(setAdvisors(advisors))
      },
    },
  )

  const reloadAdvisors = async (advisor_uuids?: string[]) => {
    if (!advisor_uuids) {
      return refetch()
    } else {
      return loadAdvisors(advisor_uuids)
    }
  }

  return {
    advisors,
    advisorsMap,
    advisorsMapByUserUuid,
    reloadAdvisors,
    isAdvisorLoaded: isSuccess,
  }
}
