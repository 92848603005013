import React from 'react'

import Expand from 'react-expand-animated'
import styled from 'styled-components'

import UploadZone from 'global/Input/UploadZone'
import Typography from 'global/Typography'

import api from 'utils/api'

const UploadWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.mist};
`

interface Props {
  onStartSyncing: () => void
  showUpload: boolean
  syncStatus: 'complete' | 'syncing' | 'normal'
  totalConnections: number
  teamSlug?: string
  teams: Partial<Team>[]
}

const ImportUpload: React.FC<Props> = ({
  showUpload = true,
  onStartSyncing,
  totalConnections,
  teams,
  teamSlug,
  syncStatus,
}) => {
  const currentTeam = teams.find((t) => t.slug === teamSlug)

  return (
    <>
      {syncStatus === 'normal' &&
        (!currentTeam ||
          currentTeam.shared_connections ||
          (!currentTeam.shared_connections && totalConnections === 0)) && (
          <Expand open={true}>
            <div className="max-w-xl mx-auto mt-6 w-full">
              <UploadWrapper className="rounded">
                <UploadZone
                  uploadOnSelect
                  hideFiles={true}
                  hideProgress={true}
                  accept={['csv', 'zip']}
                  onUpload={(upload) => {
                    if (!upload.length) return
                    api.importUserConnections(undefined, upload[0].uuid)
                  }}
                  beforeUpload={() => onStartSyncing()}
                  prompt={
                    <div className="py-4">
                      <Typography fontSize="16" fontWeight={500} color="black" component="div">
                        Upload your LinkedIn connections here
                      </Typography>
                      <Typography className="mt-1">
                        Drag and drop to upload or{' '}
                        <Typography color="purple">click to browse</Typography>
                      </Typography>
                    </div>
                  }
                />
              </UploadWrapper>
            </div>
          </Expand>
        )}
    </>
  )
}

export default ImportUpload
