import React, { useState } from 'react'
import validator from 'validator'
import { isEqual } from 'lodash'
import { cabalToast } from 'ui-components/Toast'

import ModalV1 from 'global/Modal/ModalV1'
import Typography from 'global/Typography'
import { UploadButton, Label, TextInput, Select } from 'global/Input'
import {
  AdvisorModel,
  AgreementModel,
  EquityFieldsType,
  AgreementTemplateModel,
  Team,
} from 'utils/types'
import api, { callApi } from 'utils/api'

import InvitePreview from './InvitePreview'
import AdvisorFields from './AdvisorFields'
import InvestorFields from './InvestorFields'
import { useCurrentUser, useLoadAdvisor, useLoadTeam } from 'store/hooks'
import CabalButton from 'global/CabalButton'
import TextEditor from 'global/TextEditor'
import { inviteEmailVariables } from 'utils/constants/agreement'
import InviteEmailPreviewModal from 'components/EmailPreviewModal'
import { useQuery } from 'react-query'
import v2 from './v2'

interface Props {
  show: boolean
  onHide: () => void
  onCreate: () => void
  advisor: AdvisorModel
  agreement?: AgreementModel | null
  team: Team
}

const SendAdvisorAgreementModal: React.FC<Props> = ({
  show,
  onHide,
  agreement,
  advisor,
  onCreate,
  team,
}) => {
  const {
    user: { signature: senderSignature },
    reloadUser: reloadCurrentUser,
  } = useCurrentUser()
  const loadAdvisor = useLoadAdvisor()
  const loadTeam = useLoadTeam(team.slug)
  const { uses_effective_date, advisor_invite_message } = team

  const [agreementTemplate, setAgreementTemplate] = useState<AgreementTemplateModel>()
  const [showPreview, setShowPreview] = useState<string | null>(null)
  const [updatedAdvisor, setUpdatedAdvisor] = useState<AdvisorModel>()
  const [updatedTeam, setUpdatedTeam] = useState<Team>()
  const [updatedEquity, setUpdatedEquity] = useState<EquityFieldsType>({})
  const [customAdvisorInviteMessage, setCustomAdvisorInviteMessage] = useState<string | null>(null)
  const [showAdvisorInviteEmailPreview, setShowAdvisorInviteEmailPreview] = useState(false)

  const agreementTemplatesQuery = useQuery([team.slug, 'agreementTemplates'], () =>
    callApi(api.getAgreementTemplates, team.slug, advisor.uuid),
  )
  const agreementTemplates = agreementTemplatesQuery.data?.agreement_templates

  const target = agreementTemplate?.for || 'advisor'

  React.useEffect(() => {
    if (!agreementTemplates) return

    if (agreement?.agreement_template.id) {
      const template = agreementTemplates.find((t) => t.id == agreement.agreement_template.id)
      setAgreementTemplate(template)
    }
  }, [agreement, agreementTemplates])

  React.useEffect(() => {
    agreement && setUpdatedEquity({ ...agreement.equity })
  }, [agreement])

  React.useEffect(() => {
    if (!isEqual(advisor, updatedAdvisor)) setUpdatedAdvisor(advisor)
  }, [advisor])

  React.useEffect(() => {
    if (!isEqual(team, updatedTeam)) setUpdatedTeam(team)
  }, [team])

  if (!team || !updatedAdvisor || !updatedTeam) return <></>

  const onSubmit = async (e: React.MouseEvent) => {
    e.preventDefault()

    const advisorParams = {
      email: updatedAdvisor.email,
      uuid: updatedAdvisor.uuid,
      title: updatedAdvisor.title,
    }

    const teamParams = {
      state_of_incorporation: updatedTeam.state_of_incorporation,
      address: updatedTeam.address,
      legal_name: updatedTeam.legal_name,
    }

    const equityParams = {
      granted_shares: updatedEquity?.granted_shares,
      vesting_start_date: updatedEquity?.vesting_start_date,
      vesting_period: updatedEquity?.vesting_period,
      effective_date: updatedEquity?.effective_date,
      valuation: updatedEquity?.valuation,
      amount_invested: updatedEquity?.amount_invested,
    }

    const isTeamUpdated = !isEqual(updatedTeam, team)

    try {
      if (isTeamUpdated) {
        await api.updateTeam(team.slug, {
          team: teamParams,
        })
      }

      const params = {
        agreement_uuid: agreement?.uuid,
        agreement_template_id: agreementTemplate?.id,
        equity: equityParams,
        advisor: advisorParams,
        advisor_invite_message: customAdvisorInviteMessage,
        send: true,
      }

      await api.sendAgreement(params)
      onCreate()
      onHide()
      cabalToast({ style: 'success', content: 'Successfully sent the agreement!' })
      loadAdvisor(team.slug, advisor.uuid)
      if (isTeamUpdated) {
        loadTeam()
      }
      onCreate()
    } catch (e) {
      console.error(e)
    }
  }

  const handleShowAgreementPDF = () => {
    const pdfPreviewUrl = new URL(window.location.href)
    pdfPreviewUrl.pathname = `/api/agreement_templates/${agreementTemplate?.id}.pdf`
    const paramsMap: Record<string, any> = {
      'agreement.granted_shares': updatedEquity?.granted_shares,
      'agreement.vesting_period': updatedEquity?.vesting_period,
      'agreement.vesting_start_date': updatedEquity?.vesting_start_date,
      'agreement.effective_date': updatedEquity?.effective_date,
      'agreement.valuation': updatedEquity?.valuation,
      'agreement.amount_invested': updatedEquity?.amount_invested,
      'member.title': updatedAdvisor.title,
      'member.name': updatedAdvisor.name,
      'member.email': updatedAdvisor.email,
      'team.state_of_incorporation': updatedTeam.state_of_incorporation,
      'team.address': updatedTeam.address,
      'team.legal_name': updatedTeam.legal_name,
    }
    for (const key in paramsMap) {
      if (paramsMap[key]) pdfPreviewUrl.searchParams.set(key, paramsMap[key].toString())
    }
    window.open(pdfPreviewUrl.href)
  }

  let disableSubmit =
    !updatedAdvisor.email || !validator.isEmail(updatedAdvisor.email) || !agreementTemplate?.for
  const areSignaturesRequired = Number(updatedEquity?.granted_shares) > 0 || target === 'investor'
  const usingAdvisorInviteMessage =
    agreementTemplate?.for === 'advisor' && !!advisor_invite_message && team.paid

  if (areSignaturesRequired) {
    if (!senderSignature) disableSubmit = true
    if (
      target === 'advisor' &&
      (!updatedEquity?.vesting_period ||
        !updatedEquity?.vesting_start_date ||
        (uses_effective_date && !updatedEquity.effective_date))
    )
      disableSubmit = true

    if (
      target === 'investor' &&
      (!updatedEquity?.valuation ||
        !updatedEquity?.amount_invested ||
        !updatedAdvisor.title ||
        !updatedTeam.state_of_incorporation ||
        !updatedTeam.legal_name ||
        !updatedTeam.address)
    )
      disableSubmit = true
  }

  let mainContent

  if (!updatedEquity) {
    return <div />
  }

  if (showPreview == 'invite' && agreement) {
    mainContent = (
      <InvitePreview
        advisor={updatedAdvisor}
        agreement={agreement}
        equity={updatedEquity}
        agreement_type={agreementTemplate!.for}
        onBack={() => setShowPreview(null)}
      />
    )
  } else {
    mainContent = (
      <>
        <div className="flex flex-row">
          <div className="w-full">
            <div className="mb-base">
              <Label>Agreement Type</Label>
              <Select
                portal
                value={agreementTemplate?.id}
                className="w-full"
                onChange={(v) => {
                  const template = agreementTemplates?.find((t) => t.id == v)
                  setAgreementTemplate(template)
                }}
                options={
                  agreementTemplates?.map((template) => ({
                    label: template.name,
                    value: template.id,
                  })) || []
                }
              />
            </div>
            {agreementTemplate && (
              <>
                <div className="mb-base">
                  <TextInput
                    label={<>Email</>}
                    value={updatedAdvisor.email}
                    placeholder="Email"
                    className="w-full"
                    onChange={(e) =>
                      setUpdatedAdvisor({
                        ...updatedAdvisor,
                        email: e.currentTarget.value,
                      })
                    }
                  />
                </div>
                {agreementTemplate?.for === 'advisor' && (
                  <AdvisorFields
                    team={team}
                    updatedEquity={updatedEquity}
                    setUpdatedEquity={setUpdatedEquity}
                    updateEquity={(k, v) =>
                      setUpdatedEquity({
                        ...updatedEquity,
                        [k]: v,
                      })
                    }
                  />
                )}
                {agreementTemplate?.for === 'investor' && (
                  <InvestorFields
                    team={team}
                    updatedAdvisor={updatedAdvisor}
                    updatedEquity={updatedEquity}
                    updatedTeam={updatedTeam}
                    updateTeam={(k, v) =>
                      setUpdatedTeam({
                        ...updatedTeam,
                        [k]: v,
                      })
                    }
                    updateAdvisor={(k, v) =>
                      setUpdatedAdvisor({
                        ...updatedAdvisor,
                        [k]: v,
                      })
                    }
                    updateEquity={(k, v) =>
                      setUpdatedEquity({
                        ...updatedEquity,
                        [k]: v,
                      })
                    }
                  />
                )}
                {areSignaturesRequired && !senderSignature && (
                  <div className="mb-base">
                    <Typography fontSize="12" fontWeight={500} className="mb-2" component="div">
                      Your Signature
                    </Typography>
                    {senderSignature && (
                      <img src={`/api/uploads/${senderSignature}`} className="mb-2" />
                    )}
                    <UploadButton
                      canDrawSignature
                      canUpload={false}
                      onUpload={reloadCurrentUser}
                      attachTo={'user_signature'}
                    />
                  </div>
                )}
                {usingAdvisorInviteMessage && (
                  <div>
                    {!customAdvisorInviteMessage && (
                      <button onClick={() => setCustomAdvisorInviteMessage(advisor_invite_message)}>
                        <Typography color="fog_rain" fontSize="12">
                          You are using a custom invite message, click here to make edits for this
                          specific agreement
                        </Typography>
                      </button>
                    )}
                    {customAdvisorInviteMessage && (
                      <>
                        <Label>
                          Advisor Agreement Invite Message
                          <button
                            onClick={() => setCustomAdvisorInviteMessage(null)}
                            className="float-right"
                          >
                            <i className="far fa-trash" /> Discard
                          </button>
                        </Label>
                        <TextEditor
                          allowVariables
                          variables={inviteEmailVariables}
                          value={customAdvisorInviteMessage}
                          onChange={setCustomAdvisorInviteMessage}
                        />
                      </>
                    )}
                  </div>
                )}
                <div className="flex justify-between">
                  {agreementTemplate && (
                    <div className="flex">
                      <CabalButton
                        variant="secondary"
                        onClick={() => setShowPreview('invite')}
                        disabled={disableSubmit}
                      >
                        Preview Invitation
                      </CabalButton>
                      {areSignaturesRequired && (
                        <CabalButton
                          variant="secondary"
                          onClick={handleShowAgreementPDF}
                          disabled={disableSubmit}
                          rightIcon={<i className="fad fa-external-link" />}
                          className={'ml-2'}
                        >
                          Preview Agreement
                        </CabalButton>
                      )}
                      {usingAdvisorInviteMessage && (
                        <CabalButton
                          variant="secondary"
                          onClick={() => setShowAdvisorInviteEmailPreview(true)}
                          className={'ml-2'}
                        >
                          Preview Invite Email
                        </CabalButton>
                      )}
                    </div>
                  )}
                  <CabalButton
                    onClick={onSubmit}
                    disabled={disableSubmit}
                    leftIcon={<i className="fas fa-envelope mr-2" />}
                  >
                    Confirm and Send
                  </CabalButton>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <ModalV1
        hasChildModals
        show={show}
        onHide={onHide}
        header={`Send an agreement to ${advisor.name}`}
      >
        {mainContent}
      </ModalV1>
      <InviteEmailPreviewModal
        show={showAdvisorInviteEmailPreview}
        inviteMessage={customAdvisorInviteMessage || advisor_invite_message}
        advisor={advisor}
        onHide={() => setShowAdvisorInviteEmailPreview(false)}
      />
    </>
  )
}

export default v2
