import React, { useEffect, useRef, useState } from 'react'
import { Line } from '@reactchartjs/react-chart.js'
import 'chart.js'
import { useTheme } from 'styled-components'
import { TimeProp } from '.'
import Typography from 'global/Typography'

// const Plot = styled.div`
//   ${tw` p-4 rounded-lg pt-12`}
// `

interface Props {
  time: TimeProp
  companySlug: string
  data: any
}

const Chart: React.VFC<Props> = ({ data, time, companySlug }) => {
  const theme = useTheme()
  const [stepSize, setStepSize] = useState(0)

  const chartData = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext('2d')
    const gradient = ctx.createLinearGradient(0, 0, 0, 450)
    gradient.addColorStop(0, 'rgba(138, 151, 255, 0.2)')
    gradient.addColorStop(1, 'rgba(138, 151, 255, 0)')

    if (data.dataset) {
      const maxValue = Math.max(...data.dataset) + 10
      const step = maxValue / 5
      // round up to closest multiple of 10
      const size = Math.ceil(step / 10) * 10
      setStepSize(size)
    }

    return {
      labels: data.labels,
      datasets: [
        {
          data: data.dataset,
          fill: true,
          backgroundColor: gradient,
          borderColor: '#8A97FF',
          pointRadius: 4,
          pointHitRadius: 10,
          pointStyle: 'circle',
          pointBackgroundColor: '#8A97FF',
          label: 'Contributions',
        },
      ],
    }
  }

  const options = {
    legend: {
      display: false,
    },
    elements: {
      line: {
        cubicInterpolationMode: 'monotone',
      },
    },
    scales: {
      yAxes: [
        {
          type: 'linear',
          offset: true,
          scaleLabel: {
            display: false,
          },
          ticks: {
            fontColor: theme.colors.fog,
            padding: 10,
            stepSize: stepSize,
          },
          gridLines: {
            display: true,
            drawBorder: false,
            borderDash: [8, 6],
            color: theme.chart.line,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
            borderDash: [15, 6],
            drawOnChartArea: false,
            zeroLineColor: theme.chart.line,
          },
          ticks: {
            fontColor: theme.colors.fog,
            padding: -20,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.chart.tooltip.bg,
      borderColor: theme.chart.tooltip.border,
      displayColors: false,
      borderWidth: 1,
      titleFontSize: 12,
      titleFontColor: theme.colors.fog_rain,
      caretSize: 5,
      cornerRadius: 8,
      xPadding: 12,
      yPadding: 12,
      callbacks: {
        title: function (tooltipItem) {
          return data.tooltip_labels[tooltipItem[0].index]
        },
        label: function (tooltipItem) {
          return 'Contributions: ' + tooltipItem.value
        },
        labelTextColor: function (context) {
          return theme.colors.primary
        },
      },
    },
  }

  return (
    <div className="pr-8 xl:pr-0 flex-grow">
      <Typography
        textTransform="uppercase"
        fontWeight={500}
        fontSize="12"
        className="block"
        color="widget_title"
        letterSpacing="0.05em"
      >
        Contributions
      </Typography>
      <Line id="canvas" data={chartData} options={options} />
    </div>
  )
}

export default Chart
