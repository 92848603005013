import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import Typography from 'global/Typography'

const CardBg = styled.div`
  ${tw`py-4 px-6 rounded max-w-screen-lg`};
  background: ${({ theme }) => theme.colors.cardBackground};
`

const SettingHeader = (props: { text: string }) => (
  <Typography
    fontSize="12"
    fontWeight={500}
    textTransform="uppercase"
    className="mb-3"
    color="fog_rain"
    component="h3"
  >
    {props.text}
  </Typography>
)

const PremiumBadge = styled.span`
  width: 150px;
  border-radius: 0.25rem;
  font-weight: 400;
  padding: 6px;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.buttons.secondary.text_color};
  background-color: ${({ theme }) => theme.buttons.secondary.bg_color};
`

const Border = styled.div`
  ${tw`rounded-lg`}
  border: ${({ theme }) => theme.border};
`

export { Border, CardBg, PremiumBadge, SettingHeader }
