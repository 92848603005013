import React from 'react'

import { motion } from 'framer-motion'
import styled from 'styled-components'

import Typography from 'global/Typography'

import { cn } from 'utils/styles'

const TabWrapper = styled(Typography)`
  border-bottom: ${({ theme }) => theme.border};
`

function Tab(props: { active?: boolean; children: React.ReactNode; onClick: () => void }) {
  const { active, children, onClick } = props
  return (
    <div className={cn('cursor-pointer', { selected: active })} onClick={onClick}>
      <Typography
        fontSize="12"
        className="mb-1"
        color={active ? 'purple' : 'fog'}
        fontWeight={active ? 600 : 400}
        component="p"
      >
        {children}
      </Typography>
      {props.active && <motion.div className="border-b-2 border-[#8A97FF]" layoutId="underline" />}
    </div>
  )
}

const OptionsSection = ({ step, setStep }: { step: number; setStep: (step: number) => void }) => {
  return (
    <TabWrapper className="flex gap-4 mt-6">
      <Tab active={step === 1} onClick={() => setStep(1)}>
        Company Info
      </Tab>
      <Tab active={step === 2} onClick={() => setStep(2)}>
        Contacts
      </Tab>
    </TabWrapper>
  )
}

export default OptionsSection
