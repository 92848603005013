import React, { useMemo, useState } from 'react'

import { useUpdateIRDetails } from 'containers/RequestCenter/queries/UpdateIntroRequestDetails'
import {
  IStatus,
  ITableDataObject,
  ITableDataObjectArr,
  StatusType,
} from 'containers/RequestCenter/types/ITypes'
import { requestCenterStatusFunc, statusIconMappingFunc } from 'containers/RequestCenter/utils'
import DropDownMenu from 'global/DropDownMenu'
import { useTeam, useTeamSlug } from 'store/hooks'

interface IProps {
  introRequestUuid: string
  setTableRowData: (tableRowData: ITableDataObjectArr | []) => void
  cellKey: string
  setEditingRow: (editingRow: string | null) => void
  currentStatus: IStatus
}

export function EditableStatusCell({
  introRequestUuid,
  setTableRowData,
  cellKey,
  setEditingRow,
  currentStatus,
}: IProps) {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const attribute = 'status'
  const statusIconMapping: StatusType = statusIconMappingFunc(team!)
  const requestCenterStatuses = requestCenterStatusFunc(team!)
  const [statusSelection, setStatusSelection] = useState<{
    isStatusSelected: boolean
    status: IStatus | null
  }>({ isStatusSelected: false, status: null })

  const menuItems = useMemo(
    () =>
      Object.entries(statusIconMapping)
        .filter(([key]) => requestCenterStatuses.includes(key))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(([_key, value]) => ({
          label: (
            <span
              className={`flex w-fit items-center gap-2 ${value.bg_color} ${value.text_color} px-2 py-0.5 rounded`}
            >
              <span className="text-xs">{value.label}</span>
            </span>
          ),
          onSelect: () => setStatusSelection({ isStatusSelected: true, status: value }),
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const handleStatusChange = (statusLabel: string, key: string = cellKey) => {
    const status = statusIconMapping[statusLabel]
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTableRowData((prevData: ITableDataObjectArr | []) =>
      prevData.map((tdObject: ITableDataObject) => {
        if (Object.keys(tdObject)[0] === key) {
          return {
            ...tdObject,
            [key]: {
              ...tdObject[key],
              status: {
                ...tdObject[key].status,
                label: status.label,
                icon: status.icon,
                text_color: status.text_color,
                bg_color: status.bg_color,
              },
            },
          }
        }
        return tdObject
      }),
    )
  }
  const handleTdDisplay = () => {
    setEditingRow(null)
  }

  const { mutate: updateIRMutation } = useUpdateIRDetails({
    handleStatusChange,
    cellKey,
    attribute,
    handleTdDisplay,
  })

  const updateIntroRequest = (status: IStatus) => {
    updateIRMutation({
      introRequestUuid,
      teamSlug,
      status: Object.keys(statusIconMapping).find(
        (key) => statusIconMapping[key].label === status.label,
      ),
    })
  }

  if (statusSelection.isStatusSelected) {
    if (statusSelection.status) {
      updateIntroRequest(statusSelection.status)
    }
    setStatusSelection({ isStatusSelected: false, status: null })
  }

  return (
    <DropDownMenu
      menuItems={menuItems}
      trigger={
        <div
          className={`flex text-xs w-fit items-center gap-2 ${currentStatus.bg_color} ${currentStatus.text_color} px-2 py-0.5 rounded`}
        >
          {currentStatus.label} <i className="far fa-chevron-down fa-sm"></i>
        </div>
      }
    />
  )
}
