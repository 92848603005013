import React from 'react'

import { Field, FieldProps, Form, Formik } from 'formik'
import { useMutation } from 'react-query'
import styled from 'styled-components'
import * as Yup from 'yup'

import {
  getLinkedInSlug,
  linkedInAutoPrefillSchema,
} from 'containers/CandidateProfileModal/linkedInAutoPrefill'
import CabalButton from 'global/CabalButton'
import { TextInputField } from 'global/Input'
import Loading from 'global/Loading'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

export const Footer = styled.div`
  border-top: solid 1px ${(props) => props.theme.colors.gray200};
`

interface Props {
  teamSlug: string
  listUuid: string
  onFinalize: () => void
  onHide?: () => void
  searchedName?: string
  addPersonToList: (globalPersonUuid: string) => void
}

const NewGlobalPersonModal: React.FC<Props> = ({
  teamSlug,
  onFinalize,
  onHide,
  searchedName,
  addPersonToList,
}) => {
  const [step, setStep] = React.useState(0)
  const [globalPerson, setGlobalPerson] = React.useState({
    linkedin_url: '',
    first_name: searchedName?.split(' ')[0] || '',
    last_name: searchedName?.split(' ')[1] || '',
    email: '',
    company_name: '',
    position: '',
  })

  const { mutate: enrichProfileMutation, isLoading: profileLoading } = useMutation(
    ['enrichProfile'],
    (linkedInSlug: string) => callApi(api.enrichProfile, linkedInSlug),
    {
      onSuccess: ({ profile }) => {
        if (profile?.first_name) {
          setGlobalPerson((prevState) => ({
            ...prevState,
            first_name: profile.first_name || '',
            last_name: profile.last_name || '',
            email: '',
            company_name: profile.occupation?.split(' at ')?.[1] || '',
            position: profile.occupation?.split(' at ')?.[0] || '',
          }))
        }
        setStep(1)
      },
    },
  )

  const { mutate: createGlobalPerson, isLoading: createGlobalPersonLoading } = useMutation(
    (person) =>
      api.createGlobalPerson({
        first_name: person.first_name,
        last_name: person.last_name,
        email: person.email,
        company_name: person.company_name,
        position: person.position,
        linkedin_url: person.linkedin_url,
      }),
    {
      onSuccess: (response) => {
        addPersonToList(response.data.uuid)
        onFinalize()
      },
      onError: (error) => {
        console.error('Failed to create global person', error)
      },
    },
  )

  const personFormValidationSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    company_name: Yup.string().required('Company name is required'),
    position: Yup.string().required('Position is required'),
  })

  const renderLinkedinAutofill = () => {
    return (
      <Formik
        initialValues={{
          linkedInProfileUrl: '',
        }}
        validationSchema={linkedInAutoPrefillSchema}
        onSubmit={(values) => {
          setGlobalPerson({
            ...globalPerson,
            linkedin_url: values.linkedInProfileUrl,
          })
          const linkedInSlug = getLinkedInSlug(values.linkedInProfileUrl)
          enrichProfileMutation(linkedInSlug)
        }}
      >
        {({ handleSubmit }) => (
          <Form className="flex flex-col items-start" onSubmit={handleSubmit}>
            <Typography fontSize="14" className="pb-1" color="fog">
              Enter LinkedIn profile URL to autofill details
            </Typography>
            <Field name="linkedInProfileUrl">
              {(props: FieldProps) => (
                <TextInputField
                  error={props.meta.touched ? props.meta.error : undefined}
                  autoFocus
                  {...props.field}
                  className="w-full"
                />
              )}
            </Field>

            <CabalButton
              className="mt-6 mb-2 self-end"
              variant="primary"
              type="submit"
              disabled={profileLoading}
            >
              {profileLoading ? 'Enriching...' : 'Next'}
            </CabalButton>
          </Form>
        )}
      </Formik>
    )
  }

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={globalPerson}
          validationSchema={personFormValidationSchema}
          onSubmit={(values) => {
            createGlobalPerson(values)
          }}
        >
          {({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                }
              }}
            >
              <ModalSectionWrapper>
                <ModalInputWrapper className="">
                  <ModalInputLabel>First Name</ModalInputLabel>

                  <Field name="first_name">
                    {(props: FieldProps) => (
                      <TextInputField
                        {...props.field}
                        className="w-full"
                        placeholder="First name"
                        error={props.meta.touched ? props.meta.error : undefined}
                        data-testid="first-name"
                        autoFocus={props.field.value ? false : true}
                      />
                    )}
                  </Field>
                </ModalInputWrapper>

                <ModalInputWrapper>
                  <ModalInputLabel>Last Name</ModalInputLabel>

                  <Field name="last_name">
                    {(props: FieldProps) => (
                      <TextInputField
                        {...props.field}
                        className="w-full"
                        placeholder="Last name"
                        error={props.meta.touched ? props.meta.error : undefined}
                        data-testid="last-name"
                      />
                    )}
                  </Field>
                </ModalInputWrapper>

                <ModalInputWrapper>
                  <ModalInputLabel>Email</ModalInputLabel>

                  <Field name="email">
                    {(props: FieldProps) => (
                      <TextInputField
                        {...props.field}
                        className="w-full"
                        placeholder="Email"
                        error={props.meta.touched ? props.meta.error : undefined}
                        data-testid="email"
                      />
                    )}
                  </Field>
                </ModalInputWrapper>

                <ModalInputWrapper>
                  <ModalInputLabel>Company Name</ModalInputLabel>

                  <Field name="company_name">
                    {(props: FieldProps) => (
                      <TextInputField
                        {...props.field}
                        className="w-full"
                        placeholder="Company name"
                        error={props.meta.touched ? props.meta.error : undefined}
                        data-testid="company-name"
                      />
                    )}
                  </Field>
                </ModalInputWrapper>

                <ModalInputWrapper>
                  <ModalInputLabel>Position</ModalInputLabel>

                  <Field name="position">
                    {(props: FieldProps) => (
                      <TextInputField
                        {...props.field}
                        className="w-full"
                        placeholder="Position"
                        error={props.meta.touched ? props.meta.error : undefined}
                        data-testid="position"
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
              </ModalSectionWrapper>

              <div className="flex justify-end items-center mt-7 mb-6">
                <CabalButton type="submit" working={createGlobalPersonLoading}>
                  Save
                </CabalButton>
              </div>
            </form>
          )}
        </Formik>
      </div>
    )
  }

  return (
    <Modal header={'Add Person'} onHide={onHide!} canClose show>
      {profileLoading && <Loading />}

      {step === 0 && !profileLoading && renderLinkedinAutofill()}
      {step === 1 && !profileLoading && renderForm()}
    </Modal>
  )
}

export default NewGlobalPersonModal
