import React from 'react'
import { Link } from 'react-router-dom'
import Avatar from 'global/Avatar'
import moment from 'moment'
import styled from 'styled-components'
import cx from 'classnames'

import Typography from 'global/Typography'
import { TweetModel } from 'utils/types'

interface TweetProps {
  tweet: TweetModel
  teamSlug: string
  className?: string
  children?: React.ReactChild
}

const Tweet: React.FC<TweetProps> = ({ tweet, children, teamSlug, className }) => {
  return (
    <Card className={cx(className, 'py-1.5 px-6 flex')}>
      <Avatar
        className="mr-2"
        round
        size="32"
        name={tweet.entity?.name || tweet.author_name}
        src={tweet.author_avatar_url}
      />

      <Typography component="div" className="flex-1" color="fog" fontSize="14">
        <div>
          <Typography color="primary" fontWeight={600} fontSize="14" className="pr-2">
            {tweet.entity && tweet.entity.class !== 'Team' ? (
              <Link to={`/${teamSlug}/people/${tweet.entity.id}`}>{tweet.entity.name}</Link>
            ) : (
              tweet.author_name
            )}
          </Typography>

          <a href={`https://twitter.com/${tweet.author_handle}`} target="_blank" rel="noreferrer">
            @{tweet.author_handle}
          </a>

          {' · '}

          <a
            href={`https://twitter.com/${tweet.author_handle}/status/${tweet.foreign_id}`}
            target="_blank"
            rel="noreferrer"
          >
            {moment(tweet.tweeted_at).format('MMM D')}
          </a>
        </div>

        <Typography color="primary" fontSize="13" className="mr-2 whitespace-pre-line">
          {tweet.text}
        </Typography>
      </Typography>

      {children && <div className="ml-8 self-center">{children}</div>}
    </Card>
  )
}

const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.light_shadow};
  border-bottom: ${({ theme }) => theme.border};
`

export default Tweet
