import React, { useMemo } from 'react'

import styled from 'styled-components'

import { IFacilitator, IRequestor, ITarget } from 'containers/RequestCenter/types/ITypes'
import Avatar from 'global/Avatar'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'

interface Props {
  user: ITarget | IFacilitator | IRequestor
  label: string
  portfolioEnabled: boolean
}

const Divider = styled.div`
  border-bottom: ${({ theme }) => theme.border};
`

const InfoCard: React.FC<Props> = ({ user, label, portfolioEnabled }) => {
  const tooltipContent = useMemo(() => {
    return (
      <div className="flex flex-col justify-between gap-2 max-w-sm">
        <div className="flex items-center gap-2">
          <Avatar
            className="flex-shrink-0"
            name={user.name || '-'}
            src={user.avatar_url || ''}
            size={'32'}
            round
          />
          <div className="flex flex-col text-left truncate">
            <div className="flex gap-2 items-center">
              <Typography fontWeight={600} fontSize={'14'} color="primary">
                {user?.name}
              </Typography>

              {user?.linkedin_url && (
                <Typography color={'fog'}>
                  <a
                    href={user.linkedin_url}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="cursor-pointer "
                    onClick={() => window.open(user.linkedin_url!, '_blank')}
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </Typography>
              )}
            </div>
            <Typography color="fog" fontSize="12" className="truncate" title={'Rando'}>
              {user?.title}
            </Typography>
          </div>
        </div>
        {user?.company_name && (
          <>
            <Divider />
            <Typography color="fog" fontSize="12" className="truncate">
              <i className="far fa-building mr-1 mb-2"></i> {user?.company_name}
            </Typography>
          </>
        )}
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Tooltip label={tooltipContent} interactive delay={[0, 100]} zIndex={99} placement="right">
      <Typography color={portfolioEnabled ? 'fog_rain' : 'primary'}>{label}</Typography>
    </Tooltip>
  )
}

export default InfoCard
