import avatar1 from '/app/assets/images/generic_avatars/avatar1.png'
import avatar2 from '/app/assets/images/generic_avatars/avatar2.png'
import avatar3 from '/app/assets/images/generic_avatars/avatar3.png'
import avatar4 from '/app/assets/images/generic_avatars/avatar4.png'
import avatar5 from '/app/assets/images/generic_avatars/avatar5.png'
import avatar6 from '/app/assets/images/generic_avatars/avatar6.png'
import avatar7 from '/app/assets/images/generic_avatars/avatar7.png'
import avatar8 from '/app/assets/images/generic_avatars/avatar8.png'
import avatar9 from '/app/assets/images/generic_avatars/avatar9.png'
import avatar10 from '/app/assets/images/generic_avatars/avatar10.png'
import avatar11 from '/app/assets/images/generic_avatars/avatar11.png'
import avatar12 from '/app/assets/images/generic_avatars/avatar12.png'
import avatar13 from '/app/assets/images/generic_avatars/avatar13.png'
import avatar14 from '/app/assets/images/generic_avatars/avatar14.png'
import avatar15 from '/app/assets/images/generic_avatars/avatar15.png'
import avatar16 from '/app/assets/images/generic_avatars/avatar16.png'
import avatar17 from '/app/assets/images/generic_avatars/avatar17.png'
import avatar18 from '/app/assets/images/generic_avatars/avatar18.png'
import avatar19 from '/app/assets/images/generic_avatars/avatar19.png'
import avatar20 from '/app/assets/images/generic_avatars/avatar20.png'
import avatar21 from '/app/assets/images/generic_avatars/avatar21.png'
import avatar22 from '/app/assets/images/generic_avatars/avatar22.png'
import avatar23 from '/app/assets/images/generic_avatars/avatar23.png'
import avatar24 from '/app/assets/images/generic_avatars/avatar24.png'
import avatar25 from '/app/assets/images/generic_avatars/avatar25.png'
import avatar26 from '/app/assets/images/generic_avatars/avatar26.png'
import avatar27 from '/app/assets/images/generic_avatars/avatar27.png'
import avatar28 from '/app/assets/images/generic_avatars/avatar28.png'
import avatar29 from '/app/assets/images/generic_avatars/avatar29.png'
import avatar30 from '/app/assets/images/generic_avatars/avatar30.png'
import avatar31 from '/app/assets/images/generic_avatars/avatar31.png'
import avatar32 from '/app/assets/images/generic_avatars/avatar32.png'
import avatar33 from '/app/assets/images/generic_avatars/avatar33.png'
import avatar34 from '/app/assets/images/generic_avatars/avatar34.png'

const avatars = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
  avatar16,
  avatar17,
  avatar18,
  avatar19,
  avatar20,
  avatar21,
  avatar22,
  avatar23,
  avatar24,
  avatar25,
  avatar26,
  avatar27,
  avatar28,
  avatar29,
  avatar30,
  avatar31,
  avatar32,
  avatar33,
  avatar34,
]

export default avatars
