import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

const OptionWrapper = styled.div`
  ${tw`flex-1 flex flex-col justify-between rounded-lg p-4 cursor-pointer text-center`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: 2px solid ${({ theme }) => theme.layout.main_bg_color};

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.purple};
  }
`

interface Props {
  title: React.ReactNode | string
  description?: React.ReactNode | string
  icon?: React.ReactNode
  onClick?: () => void
}

const OptionCardBlock: React.FC<Props> = ({ icon, title, description, onClick }) => {
  return (
    <OptionWrapper onClick={onClick}>
      <Typography className="mb-2" color="purple">
        {icon}
      </Typography>
      <div className="">
        <Typography fontWeight={600} lineHeight={1.2} fontSize="12" className="pb-1" component="p">
          {title}
        </Typography>
        <Typography fontSize="12" color={'fog'} component="p" lineHeight={1.4}>
          {description}
        </Typography>
      </div>
    </OptionWrapper>
  )
}

export default OptionCardBlock
