import CabalButton from 'global/CabalButton'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import pluralize from 'pluralize'
import React from 'react'
import { PresendCheckStepProps } from '.'

const UnknownVariable: React.VFC<PresendCheckStepProps> = ({
  presendCheckResponse,
  onHide,
  onSubmit,
}) => {
  const unknownVars = presendCheckResponse.unknown_vars!
  return (
    <Modal
      show
      onHide={onHide}
      header={'Before we send that…'}
      leftActions={[
        <CabalButton
          key="send-anyway-button"
          variant="primary"
          onClick={() => onSubmit({ skip_var_check: true })}
          className="mr-3"
        >
          Send anyway
        </CabalButton>,
        <CabalButton key="cancel-button" variant="secondary" onClick={() => onHide()} tabIndex={1}>
          Cancel
        </CabalButton>,
      ]}
    >
      <div data-testid="unknown-variable-message">
        You wrote {`"`}
        {unknownVars
          .map<React.ReactNode>((v) => <Typography key={v}>{`{{${v}}}`}</Typography>)
          .reduce((prev, curr) => [prev, ', ', curr])}
        {`" but we don't`} support {pluralize('this', unknownVars.length)}{' '}
        {pluralize('variable', unknownVars.length)}.<br /> If you send it as-is, your recipients
        will see the variable string unreplaced.
      </div>
    </Modal>
  )
}

export default UnknownVariable
