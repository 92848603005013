import * as React from 'react'

import cx from 'classnames'
import NumberFormat from 'react-number-format'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useSetState } from 'react-use'
import scrollIntoView from 'scroll-into-view'
import styled from 'styled-components'
import tw from 'twin.macro'

import AccountVerificationViaCodeModal from 'components/AccountVerificationViaCodeModal'
import CabalButton from 'global/CabalButton'
import { CheckBox, UploadButton } from 'global/Input'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import CabalTitle from 'global/Title'
import Typography from 'global/Typography'
import { useAppDispatch, useCurrentUser } from 'store/hooks'
import { loadCurrentUser } from 'store/reducers/currentUserReducer'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { AgreementTemplateField } from 'utils/types/agreement'

const LogoImg = styled.img`
  ${tw`inline-block mb-3`}
  height: 64px;
  width: 64px;
  border-radius: 50%;
`

const DocumentContainer = styled.div`
  ${tw`w-full rounded my-4 flex-1`}
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.primary_bg};
`

const BottomActions = styled.div`
  ${tw`flex-initial flex justify-between pb-4`}
`

const Container = styled.div`
  background-color: ${({ theme }) => theme.layout.main_bg_color};
  height: calc(100vh - 150px);
  overflow: hidden;
  width: ${({ theme }) => theme.breakpoints.restricted};
`

const DocumentWrapper = styled.div`
  height: 100% - 150px;
  width: 100%;
`

const SignatureImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const ImageFieldArea = styled.div`
  ${tw`h-full w-full border-2 border-dashed`}

  border-color: ${({ theme }) => theme.colors.border};
`

type FieldInputProps = {
  filled: boolean
}

const FieldInput = styled.textarea<FieldInputProps>`
  ${tw`top-1/2 -translate-y-1/2 absolute left-0`}
  font-family: monospace;
  background-color: transparent;
  border: 2px solid red;
  resize: none;
  color: black;
  ${({ filled }) => filled && `border: 1px dashed black;`}
`

const Sign: React.FC = () => {
  const history = useHistory()

  const dispatch = useAppDispatch()
  const { showModal } = useModal()
  const { isUserLoaded, isLoggedIn } = useCurrentUser()

  const [working, setWorking] = React.useState(false)
  const [agreementParams, setAgreementParams] = useSetState<Record<string, any>>({})
  const documentRef = React.useRef<HTMLDivElement>(null)

  const { id: secretUuid } = useParams<{ id: string }>()

  const { data: agreementData, isFetching: isLoadingAgreement } = useQuery(
    [secretUuid, 'getAgreement'],
    () => callApi(api.getAgreement, secretUuid),
    {
      onSuccess: (data) => {
        getAgreementPdf(data.agreement.uuid)
      },
    },
  )
  const { team, fields: allFields, can_sign } = agreementData || {}
  const fields = allFields?.filter((f) => f.for === 'advisor')

  const { data: agreementPdf, mutate: getAgreementPdf } = useMutation(
    async (agreement_uuid: string) => {
      const pdf = await pdfjs.getDocument(`/api/agreements/${agreement_uuid}.pdf`).promise
      const data = await pdf.getData()
      return { pdf, data }
    },
  )

  React.useEffect(() => {
    if (!isUserLoaded || !agreementData || !agreementData.team.enable_bulk_agreements) return

    if (!isLoggedIn && false) {
      showModal(
        (resolve) => (
          <AccountVerificationViaCodeModal
            advisorUuid={agreementData.advisor.uuid}
            email={agreementData.advisor.email}
            resolve={resolve}
          />
        ),
        'AccountVerificationViaCodeModal',
      )
    }
  }, [isUserLoaded, isLoggedIn, agreementData])

  const getFieldRect = (field: AgreementTemplateField) => {
    const { position } = field
    if (!documentRef.current || !position) return {}

    const pages = documentRef.current.getElementsByTagName('canvas')

    let x = 0
    let y = 0
    let w = 0
    let h = 0

    for (let i = 0; i < pages.length; i++) {
      const { width, height } = pages[i].getBoundingClientRect()
      if (i < position.page_num) y += height
      else if (i === position.page_num) {
        y += height * position.y
        x += width * position.x
        w = width * position.w
        h = height * position.h
        break
      } else break
    }

    return { x, y, w, h }
  }

  const handleSubmit = () => {
    setWorking(true)

    api
      .signAgreement(secretUuid, agreementParams)
      .then((response) => {
        cabalToast({
          content:
            // eslint-disable-next-line max-len
            "Congrats! You have successfully signed the agreement. You'll receive a confirmation mail",
          style: 'success',
          duration: 60000,
        })
        dispatch(loadCurrentUser())
        history.push(`/${response.data.team_slug}`)
      })
      .catch((error) => {
        console.error('Sign error!', { error })
      })
      .finally(() => setWorking(false))
  }

  const setFieldValue = (uuid: string, v: any) => {
    setAgreementParams({ [uuid]: v })
  }

  const renderField = (field: AgreementTemplateField) => {
    if (!can_sign) return <></>
    const documentWidth = documentRef.current?.getBoundingClientRect().width

    const { x, y, w, h } = getFieldRect(field) || {}

    if (!(x && y && w && h && documentWidth)) return <React.Fragment key={`field-${field.uuid}`} />

    let input = <></>
    const localFieldValue = agreementParams[field.uuid]

    if (field.for === 'advisor') {
      if (field.data_type === 'image') {
        if (field.default_value?.includes('signature')) {
          input = (
            <ImageFieldArea>
              {!!localFieldValue && <SignatureImg src={`/api/uploads/${localFieldValue}`} />}

              <div className="absolute bottom-full right-0">
                <UploadButton
                  onUpload={(u) => setFieldValue(field.uuid, u)}
                  canUpload={false}
                  canDrawSignature
                />
              </div>
            </ImageFieldArea>
          )
        }
      } else if (field.data_type === 'amount' || field.data_type === 'number') {
        input = (
          <NumberFormat
            filled={!!localFieldValue}
            as="input"
            isNumericString
            thousandSeparator
            customInput={FieldInput}
            data-id={field.uuid}
            value={localFieldValue || ''}
            onChange={(e) => setFieldValue(field.uuid, e.currentTarget.value)}
            placeholder={field.name}
            prefix={field.data_type === 'amount' ? '$' : undefined}
          />
        )
      } else if (field.data_type === 'string') {
        input = (
          <FieldInput
            filled={!!localFieldValue}
            as="input"
            data-id={field.uuid}
            value={localFieldValue || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue(field.uuid, e.currentTarget.value)
            }
            placeholder={field.name}
          />
        )
      } else if (field.data_type === 'checkbox') {
        input = (
          <div className="ring-2 scale-150 transform">
            <CheckBox
              className="block -mt-2"
              onChange={(e) =>
                setFieldValue(field.uuid, e.currentTarget.checked ? 'checked' : 'unchecked')
              }
            />
          </div>
        )
      } else if (field.data_type === 'date') {
        input = (
          <FieldInput
            filled={!!localFieldValue}
            as="input"
            type="date"
            data-id={field.uuid}
            value={localFieldValue || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue(field.uuid, e.currentTarget.value)
            }
            placeholder={field.name}
          />
        )
      } else if (field.data_type === 'text') {
        input = (
          <FieldInput
            data-id={field.uuid}
            style={{
              fontSize: `${(documentWidth * 14) / 794}px`,
              width: '100%',
              height: '100%',
            }}
            value={localFieldValue || ''}
            filled={!!localFieldValue}
            onChange={(e) => setFieldValue(field.uuid, e.currentTarget.value)}
            placeholder={field.name}
          />
        )
      }
    }

    return (
      <div
        key={`field-${field.uuid}`}
        data-id={field.uuid}
        className="absolute z-10"
        style={{
          transform: `translate(${x}px, ${y}px)`,
          ...(['text', 'image'].includes(field.data_type)
            ? {
                width: `${w}px`,
                height: `${h}px`,
              }
            : null),
        }}
      >
        {input}
      </div>
    )
  }

  const scrollToNextField = () => {
    if (!documentRef.current || !fields) return

    const nextField = fields.find((f) => !agreementParams[f.uuid])
    if (!nextField) return

    const nextFieldEl = documentRef.current.querySelector<HTMLElement>(
      `[data-id="${nextField.uuid}"]`,
    )
    nextFieldEl && scrollIntoView(nextFieldEl)
  }

  const loadingPdf = (
    <Typography
      className="text-center mt-20 justify-center items-center flex whitespace-nowrap"
      component="div"
      color="primary"
    >
      <Loading />
      <div className="ml-4">Loading the agreement</div>
    </Typography>
  )

  const pdfRender = React.useMemo(
    () =>
      agreementPdf ? (
        <Document
          loading={loadingPdf}
          file={{
            data: agreementPdf.data,
          }}
        >
          {Array.from(
            {
              length: agreementPdf.pdf.numPages,
            },
            (_, k) => k,
          )
            .map((x, i) => i + 1)
            .map((page) => (
              <div key={`page${page}`}>
                <Page
                  className="overflow-none"
                  onLoadSuccess={() =>
                    page === 1 && setTimeout(() => setAgreementParams(agreementParams), 1000)
                  }
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  error={<></>}
                  width={documentRef.current?.getBoundingClientRect().width - 50 || 0}
                  pageNumber={page}
                />
                <hr className="absolute border-light dark:border-dark w-full z-10" />
              </div>
            ))}
        </Document>
      ) : (
        loadingPdf
      ),
    [agreementPdf],
  )

  const isIncomplete = fields?.some((f) => !agreementParams[f.uuid])

  if (isLoadingAgreement)
    return (
      <div className="p-10">
        <Loading />
      </div>
    )

  return (
    <>
      <CabalTitle title="Accept Agreement" teamName={agreementData?.team.name} />
      <Container className="flex flex-col m-auto">
        <div className="text-center text-gray-400 flex-initial">
          {team?.logo && <LogoImg className="mt-8 mx-auto" alt={team?.name} src={team?.logo} />}
        </div>
        <DocumentContainer>
          <DocumentWrapper className={cx('overflow-auto', 'relative')} ref={documentRef}>
            <div>{fields?.map((f) => renderField(f))}</div>
            <div>{pdfRender}</div>
          </DocumentWrapper>
        </DocumentContainer>
        <BottomActions>
          <div>
            {can_sign && (
              <CabalButton variant="primary" onClick={scrollToNextField}>
                <div className="flex items-center">
                  <span>Jump To Next Field </span>
                  <i className="pl-2 fas fa-arrow-right" />
                </div>
              </CabalButton>
            )}
          </div>
          <div>
            <CabalButton
              component="a"
              target="_blank"
              size="small"
              href={`/api/agreements/${agreementData?.agreement.uuid}/view_signed_document`}
              variant="tertiary"
              className="mr-4"
            >
              Download PDF
            </CabalButton>
            <CabalButton
              variant="primary"
              disabled={isIncomplete || !agreementPdf || !can_sign}
              tooltip={can_sign ? undefined : 'You do not have permission to sign this agreement'}
              working={working}
              onClick={handleSubmit}
            >
              <div className="flex items-center">
                <i className="pr-2 fas fa-check" />
                <span>Finish Signing</span>
              </div>
            </CabalButton>
          </div>
        </BottomActions>
      </Container>
    </>
  )
}

export default Sign
