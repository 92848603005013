import React, { useState, useEffect } from 'react'

import Modal from 'global/Modal'
import { EmailMessageRecipient, EmailRecipientValue, MessageRecipient } from 'utils/types'
import { CheckBox, TextInput } from 'global/Input'
import CabalButton from 'global/CabalButton'
import { useQuery } from 'react-query'
import api, { callApi } from 'utils/api'
import styled from 'styled-components'
import { cabalToast } from 'ui-components/Toast'
import ProgressBar from 'global/ProgressBar'
import { useAccessControl } from 'global/AccessControl'
import Typography from 'global/Typography'

interface Props {
  show: boolean
  onHide: () => void
  recipients: MessageRecipient[]
  onDone: (s: MessageRecipient[]) => void
  teamSlug: string
  variableFieldsUsed: string[]
}

const Td = styled.td`
  padding-right: 10px;
  font-size: 13px;
`

const Th = styled.th`
  padding-right: 10px;
  padding-bottom: 5px;
`

const MapEmailModal: React.VFC<Props> = ({
  show,
  onHide,
  recipients: _recipients,
  onDone,
  teamSlug,
  variableFieldsUsed
}) => {
  const { canAddAdvisor } = useAccessControl(teamSlug)

  const [recipients, setRecipients] = useState<MessageRecipient[]>(_recipients)
  const [advisorImportUuid, setAdvisorImportUuid] = useState<string>()
  const [progress, setProgress] = useState(0)
  const [checkingAdvisorImport, setCheckingAdvisorImport] = useState(false)
  const [checkAdvisorImportScrapeStatus, setCheckAdvisorImportScrapeStatus] = useState(false)

  const emailRecipients = recipients.filter((r) => r.type === 'email') as EmailMessageRecipient[]
  const membersToAdd = emailRecipients.filter((r) => !!r.value.add_member)
  const addAllAsMembers = membersToAdd.length === emailRecipients.length

  const setRecipientsRef = React.useRef(setRecipients)

  useEffect(() => {
    setRecipientsRef.current = setRecipients
  }, [setRecipients])

  useQuery(
    ['advisor_imports', advisorImportUuid],
    () => {
      setCheckingAdvisorImport(true)
      return callApi(api.enrichStatusAdvisorImport, teamSlug, advisorImportUuid!)
    },
    {
      enabled: !!advisorImportUuid && checkAdvisorImportScrapeStatus,
      onSuccess: ({ status, advisors, total_advisor_count, enriched_advisor_count }) => {
        if (status === 'finished') {
          setCheckingAdvisorImport(false)
          setCheckAdvisorImportScrapeStatus(false)
          setProgress(1)
          const updatedRecipients = [...recipients]
          for (const person of advisors) {
            const recipientIndex = updatedRecipients.findIndex((r) => r.label === person.email)
            updatedRecipients[recipientIndex] = {
              type: 'email',
              value: {
                ...((updatedRecipients[recipientIndex]?.value || {}) as EmailRecipientValue),
                first_name: person.first_name || '',
                last_name: person.last_name || '',
              },
              label: person.email,
            }
          }
          setRecipients(updatedRecipients)
        } else {
          setProgress(enriched_advisor_count / total_advisor_count)
        }
      },
      refetchInterval: 3000,
    },
  )

  const { isLoading } = useQuery(
    ['enrichAdvisorImport'],
    () =>
      callApi(api.enrichAdvisorImport, {
        emails: emailRecipients.map((e) => e.value.email),
        team_slug: teamSlug,
      }),
    {
      onSuccess: ({ advisor_import_uuid }) => {
        setAdvisorImportUuid(advisor_import_uuid)
        setCheckAdvisorImportScrapeStatus(true)
      },
      onError: () => {
        // cabalToast({ content: 'The input you provided is invalid. Please try again' })
      },
    },
  )

  const updateRecipient = React.useCallback(
    (email: string, key: keyof EmailRecipientValue, value: string | boolean) => {
      const updatedRecipients = [...recipients]
      const recipientIndex = updatedRecipients.findIndex((r) => r.label === email)
      updatedRecipients[recipientIndex] = {
        type: 'email',
        value: {
          ...(updatedRecipients[recipientIndex].value as EmailRecipientValue),
          [key]: value,
        },
        label: email,
      }
      setRecipientsRef.current(updatedRecipients)
    },
    [recipients, emailRecipients, setRecipientsRef.current],
  )

  const toggleSelectAll = () => {
    const updatedRecipients = [...recipients]
    for (let i = 0; i < updatedRecipients.length; i++) {
      if (updatedRecipients[i].type === 'email') {
        ;(updatedRecipients[i].value as EmailRecipientValue).add_member = !addAllAsMembers
      }
    }

    setRecipients(updatedRecipients)
  }

  const canSave = emailRecipients.every((r) => !!r.value.first_name)

  const needLastName = variableFieldsUsed.includes('{{full_name}}')

  const modalContent = (
    <div className="overflow-auto">
      <table className="w-full" cellSpacing={3}>
        <thead>
          <tr className="text-sm mb-1 font-bold text-secondary">
            <Th>Email</Th>
            <Th>First Name</Th>
            {(canAddAdvisor || needLastName) && 
              <Th>Last Name</Th>
            }
            {canAddAdvisor && (
              <Th>
                <CheckBox
                  checked={membersToAdd.length === emailRecipients.length}
                  onClick={toggleSelectAll}
                />
                <Typography className="ml-2">Save as member?</Typography>
              </Th>
            )}
          </tr>
        </thead>
        <tbody>
          {emailRecipients.map((recipient) => (
            <tr key={recipient.label}>
              <Td>{recipient.label}</Td>
              <Td>
                <TextInput
                  compact
                  className="w-full"
                  defaultValue={recipient.value.first_name}
                  onChange={(e) => updateRecipient(recipient.label, 'first_name', e.target.value)}
                />
              </Td>
              {(canAddAdvisor || needLastName) && 
                <Td>
                  <TextInput
                    compact
                    className="w-full"
                    defaultValue={recipient.value.last_name}
                    onChange={(e) => updateRecipient(recipient.label, 'last_name', e.target.value)}
                  />
                </Td>
              }
              {canAddAdvisor && (
                <Td className="text-center">
                  <CheckBox
                    checked={recipient.value.add_member}
                    onChange={(e) =>
                      updateRecipient(recipient.label, 'add_member', e.currentTarget.checked)
                    }
                  />
                </Td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  return (
    <Modal
      show={show}
      size="lg"
      onHide={onHide}
      header={`Add recipient detail${emailRecipients.length > 1 ? 's' : ''}`}
      rightActions={
        <CabalButton disabled={!canSave} onClick={() => onDone(recipients)}>
          Send
        </CabalButton>
      }
    >
      <div className="my-4">
        {isLoading || checkingAdvisorImport ? (
          <>
            <ProgressBar ratio={progress} label={`Enriching`} />
          </>
        ) : (
          modalContent
        )}
      </div>
    </Modal>
  )
}

export default MapEmailModal
