import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '..'

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export function useAppSelector<T>(fn: (state: RootState) => T): T {
  return useSelector(fn, isEqual)
}

export * from './team'
export * from './advisor'
export * from './group'
export * from './user'
export * from './uniqueAccessLink'
