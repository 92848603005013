// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.
//import { hot } from 'react-hot-loader/root';
import * as React from 'react'
import { createRoot } from 'react-dom/client'

import ahoy from 'ahoy.js'
import { hijackEffects } from 'stop-runaway-react-effects'

// import { BrowserTracing } from '@sentry/tracing'
import App from 'containers/App'

import ErrorLogger from 'utils/ErrorLogger'
import { HAS_ASSET_CACHE, IS_MOBILE_APP } from 'utils/hooks/useMobileApp'

import '../css/application.scss'
import '../css/icons.scss'

if (process.env.NODE_ENV === 'development') {
  hijackEffects({ callCount: 200 })
}

if (process.env.NODE_ENV === 'production') {
  ErrorLogger.init()
}

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js', { scope: './' }).then(function (reg) {
    console.log('[Companion]', 'Service worker registered!')
  })
}

const initCabalWebApp = () => {
  document.getElementById('seo-content')?.remove()
  const containerDiv = document.createElement('div')
  containerDiv.id = 'root'
  const root = createRoot(containerDiv)
  document.body.appendChild(containerDiv)
  root.render(<App />)
  ahoy.trackView()
  ahoy.trackClicks('a, button, input[type=submit]')
  ahoy.trackSubmits('form')
  ahoy.trackChanges('input, textarea, select')
}

if (IS_MOBILE_APP && HAS_ASSET_CACHE) {
  window._INIT_CABAL_WEBAPP = initCabalWebApp
} else {
  document.addEventListener('DOMContentLoaded', initCabalWebApp)
}
