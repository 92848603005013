import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import Block from 'components/Block'
import { DownChevron } from 'containers/ListView/Companies/styles'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'
import TokenInput from 'ui-components/TokenInput'

import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import { numWithCommas } from 'utils/ui'

import { CardWrapper } from './styles'

interface Props {
  teamSlug: string
  noSearchRequired?: boolean
  className?: string
  header?: boolean
  itemCount?: number
}

const SearchBlock = (props: Props) => {
  const { teamSlug, noSearchRequired, className = '', header = false, itemCount } = props
  const [query, setQuery] = useState<string[]>([])
  const debouncedQuery = useDebouncedValue(query, 400)
  const history = useHistory()
  const { team } = useTeam(teamSlug)

  return (
    <div className={className}>
      {header && (
        <div className="flex items-center justify-between pb-1 cursor-pointer">
          <div className="flex items-center">
            <DownChevron
              className="mr-2 cursor-pointer fa fa-chevron-left"
              onClick={() => history.push(`/${teamSlug}`)}
            />
            <span onClick={() => history.push(`/${teamSlug}`)}>
              <Typography fontSize="14" fontWeight={600}>
                Search our connections
              </Typography>
            </span>
          </div>
        </div>
      )}

      <div className="my-2 p-2 rounded-lg flex flex-col gap-2 ">
        <TokenInput
          icon={<i className="far fa-search" />}
          onChange={(v) => setQuery(v)}
          includeInputValueInValues
          value={query}
          placeholder={`Search our ${itemCount ? numWithCommas(itemCount) : 0} connections`}
        />

        {(noSearchRequired || !!debouncedQuery.length) && (
          <Block
            blockId={'team-connections-preview'}
            ownerType="Team"
            ownerId={teamSlug}
            params={{ filters: { search: debouncedQuery }, per_page: 5 }}
          />
        )}
      </div>
    </div>
  )
}

export default SearchBlock
