import React from 'react'

import styled from 'styled-components'

import Typography from 'global/Typography'

const OptionWrap = styled.div`
  background: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.purple};
  }
`

const CreateOption = ({
  onClick,
  title,
  dataTestid,
}: {
  onClick: () => void
  title: string
  dataTestid: string
}) => {
  return (
    <OptionWrap
      className="flex flex-col justify-between cursor-pointer h-[120px] px-4 py-2.5 rounded-lg"
      onClick={onClick}
      data-testid={dataTestid}
    >
      <Typography color="fog" fontSize="20">
        <i className="far fa-plus" />
      </Typography>

      <div className="flex items-end h-full">
        <Typography color="fog" lineHeight={1} fontSize="14" className="mt-2">
          {title}
        </Typography>
      </div>
    </OptionWrap>
  )
}

export default CreateOption
