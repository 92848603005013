import React, { useState } from 'react'

import { useMutation } from 'react-query'
import styled from 'styled-components'

import ImportCompanyListSection, {
  Banner,
  ListProps,
  SectionHeader,
} from 'components/ImportListSection'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { InvestorCompany } from 'utils/types/investor'

interface Props {
  teamSlug: string
  resolve: (refetch: boolean) => void
}

const CompanyListRow = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 5fr 5fr;
  grid-column-gap: 10px;
  margin-top: 10px;
  align-items: center;
`

const ImportCompaniesModal: React.VFC<Props> = ({ resolve, teamSlug }) => {
  const [companies, setCompanies] = useState<ListProps>({})
  const [imported, setImported] = useState(false)
  const [importedCompanies, setImportedCompanies] = useState<InvestorCompany[]>([])

  const { mutate: importCompanies, isLoading } = useMutation(
    ['importCompanies'],
    () => {
      const companiesList = companies?.list
        .split('\n')
        .map((c) => c.trim())
        .filter((c) => !!c)
      return callApi(api.importInvestorCompanies, companiesList, teamSlug)
    },
    {
      onSuccess: ({ companies }: { companies: InvestorCompany[] }) => {
        setImportedCompanies(companies || [])
        setImported(true)
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const { mutate: updateCompanies, isLoading: isUpdating } = useMutation(
    ['createInvestorCompanies'],
    () => {
      return callApi(api.bulkCreateInvestorCompany, importedCompanies, teamSlug)
    },
    {
      onSuccess: ({ companies }: { companies: InvestorCompany[] }) => {
        setImportedCompanies(companies)
        cabalToast({ style: 'success', content: 'Portfolio Company Added' })
        resolve(true)
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const updateImportedCompany = (index: number, company: InvestorCompany) => {
    const updatedImportedCompanies = [...importedCompanies]
    updatedImportedCompanies[index] = { ...company }
    setImportedCompanies(updatedImportedCompanies)
  }

  if (imported) {
    return (
      <Modal
        show
        onHide={() => resolve(false)}
        header={
          <Typography color={'fog'} fontSize="12" fontWeight={400} lineHeight="15px">
            Add Portfolio Companies
          </Typography>
        }
        rightActions={
          <CabalButton
            disabled={importedCompanies?.some((c) => !c.company_name)}
            onClick={() => updateCompanies()}
            working={isUpdating}
            data-testid="import-companies-modal-finish-btn"
          >
            Add Companies
          </CabalButton>
        }
      >
        <ModalSectionWrapper>
          <SectionHeader>
            <Typography fontSize="12" fontWeight={600}>
              Verify Companies
            </Typography>
          </SectionHeader>
          <Banner>
            <Typography color={'purple'} fontSize={'12'}>
              <i className="far fa-info-circle mr-1.5"></i>
              Please verify that the companies below are correct, and fill in any missing URLs
            </Typography>
          </Banner>
          <CompanyListRow>
            <Typography fontSize="12" fontWeight={600}></Typography>
            <Typography fontSize="12" fontWeight={600}></Typography>
            <Typography fontSize="12" fontWeight={600} color={'fog'}>
              Company Name
            </Typography>
            <Typography fontSize="12" fontWeight={600} color={'fog'}>
              Company Website
            </Typography>
          </CompanyListRow>
          {importedCompanies?.map((c, i) => (
            <CompanyListRow key={i}>
              <Typography color={'fog'} fontSize="12" fontWeight={400} lineHeight="15px">
                {i + 1}
              </Typography>
              <Avatar
                name={c.company_name}
                src={c.logo_url}
                size="32"
                round
                className="flex-shrink-0"
              />
              <TextInput
                data-testid="verify-company-name-input"
                value={c.company_name}
                onChange={(e) => updateImportedCompany(i, { ...c, company_name: e.target.value })}
              />
              <TextInput
                data-testid="verify-company-domain-input"
                value={c.domain || ''}
                onChange={(e) => updateImportedCompany(i, { ...c, domain: e.target.value })}
              />
            </CompanyListRow>
          ))}
        </ModalSectionWrapper>
      </Modal>
    )
  }

  return (
    <Modal
      show
      onHide={() => resolve(false)}
      header={
        <Typography color={'fog'} fontSize="12" fontWeight={400} lineHeight="15px">
          Add Portfolio Companies
        </Typography>
      }
      rightActions={
        <CabalButton
          disabled={companies.list?.length === 0}
          onClick={() => importCompanies()}
          working={isLoading}
          data-testid="import-companies-modal-save-btn"
        >
          Add Companies
        </CabalButton>
      }
    >
      <div className="mt-3">
        <ImportCompanyListSection
          title="Companies"
          listData={companies}
          setListData={setCompanies}
        />
      </div>
    </Modal>
  )
}

export default ImportCompaniesModal
