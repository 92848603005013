import React from 'react'

import CabalButton from 'global/CabalButton'
import { EmailSnippet } from 'utils/types'

import SnippetsForm from './SnippetsForm'

interface Props {
  disabled: boolean
  onClickCancel: () => void
  onSubmit: (params: Partial<EmailSnippet>) => void
}

const AddSnippet: React.VFC<Props> = ({ disabled, onClickCancel, onSubmit }) => {
  const [values, setValues] = React.useState<Partial<EmailSnippet>>({})

  const handleChange = (newValues: Partial<EmailSnippet>) => {
    setValues((values) => ({ ...values, ...newValues }))
  }

  const handleSubmit = () => {
    onSubmit(values)
  }

  return (
    <>
      <SnippetsForm disabled={disabled} values={values} onChange={handleChange} />

      <div className="mt-4 mb-3 flex justify-end">
        <CabalButton
          disabled={disabled}
          className="mr-4"
          variant="tertiary"
          onClick={onClickCancel}
        >
          Cancel
        </CabalButton>

        <CabalButton disabled={disabled} onClick={handleSubmit}>
          Add Snippet
        </CabalButton>
      </div>
    </>
  )
}

export default AddSnippet
