import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { CardWidth, Container } from 'containers/IntroRequests/styles'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

const UniqueAccessLink: React.VFC = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const [error, setError] = useState<string>()

  const { isLoading } = useQuery(
    ['getUniqueAccessLink', uuid],
    () => callApi(api.getUniqueAccessLink, uuid),
    {
      onSuccess: ({ redirect_to, expires_at, error: err }) => {
        const links = JSON.parse(Cookies.get(`unique_access_links`) || '{}')
        for (const link in links) {
          const { expiresAt } = links[link]
          if (!expiresAt || new Date(expiresAt).getTime() <= new Date().getTime()) {
            delete links[link]
          }
        }
        if (redirect_to) {
          Cookies.set(
            `unique_access_links`,
            JSON.stringify({ ...links, [redirect_to]: { uuid, expiresAt: expires_at } }),
          )
          window.location.href = redirect_to
        } else {
          Cookies.set(`unique_access_links`, JSON.stringify({ ...links }))
          setError(err)
        }
      },
    },
  )

  const { mutate: resendUniqueAccessLink } = useMutation(
    ['resendUniqueAccessLink', uuid],
    () => callApi(api.resendUniqueAccessLink, uuid),
    {
      onSuccess: () => {
        cabalToast({
          style: 'success',
          content: 'Success! You will receive the new link in your email soon.',
        })
      },
      onError: (error) => {
        const errMsg = error?.response?.data?.error
        cabalToast({
          style: 'error',
          content: `Something went wrong${errMsg ? ': ' + errMsg : '!'}`,
        })
      },
    },
  )

  if (isLoading || !error)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <CardWidth>
        <div className="mt-5">
          <Typography fontSize="20" color="fog_rain" className="m-5">
            <i className="fas fa-link-slash"></i>
          </Typography>
          {error && (
            <>
              <Typography component="div">This link has expired.</Typography>
              <CabalButton
                className="mt-4"
                variant="primary"
                onClick={() => resendUniqueAccessLink()}
              >
                Request a new link
              </CabalButton>
            </>
          )}
          {/* {error === 'invalid session token' && (
            <>
              <Typography component="div">Looks like this link has already been used.</Typography>
              <Typography
                className="underline cursor-pointer"
                fontSize="12"
                color="add_txt"
                onClick={() => resendUniqueAccessLink()}
              >
                Click here
              </Typography>{' '}
              <Typography fontSize="12" color="fog_rain">
                to request a new link.
              </Typography>
            </>
          )} */}
        </div>
      </CardWidth>
    </Container>
  )
}

export default UniqueAccessLink
