import React, { useMemo } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'

import { useComposer } from 'components/Composer'
import BulkActionsIsland from 'containers/ListIndexView/Components/BulkActionsIsland'
import { usePeopleListContext } from 'containers/ListIndexView/TalentList/PeopleList'
import { ITalentPeople } from 'containers/ListIndexView/TalentList/type'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

interface Props {
  selectedPeople: ITalentPeople[]
  setSelectedPeople: (people: ITalentPeople[]) => void
}

const PeopleListBulkActions: React.FC<Props> = ({ selectedPeople, setSelectedPeople }) => {
  const location = useLocation()
  const { compose } = useComposer()
  const searchParams = new URLSearchParams(location.search)
  const archivedStatus = searchParams.get('status') === 'archived'
  const {
    companyListData: companyList,
    refetchPeople,
    adminView,
    view,
    setView,
  } = usePeopleListContext()

  const selectedPeopleUuids = useMemo(
    () =>
      selectedPeople
        .map((person) => person.candidate_profile?.uuid)
        .filter((uuid): uuid is string => uuid !== undefined),
    [selectedPeople],
  )

  const { mutate: bulkAction } = useMutation(
    (params: any) => callApi(api.candidatesBulkActions, params),
    {
      onSuccess: (_, params) => {
        refetchPeople()
        cabalToast({ style: 'success', content: `Successfully ${params.bulk_action}d!` })
        setSelectedPeople([])
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content: 'Something went wrong, please try again or contact support',
        })
      },
    },
  )

  const handleOnSelect = (action: string, kind: string) => {
    if (selectedPeople.length === 0) {
      cabalToast({
        content: 'Select profiles to perform bulk actions',
        style: 'error',
      })
      return
    }
    if (action === 'delete') {
      const confirmDelete = window.confirm('Are you sure you want to bulk delete?')
      if (confirmDelete) {
        bulkAction({
          selected_candidates_uuids: selectedPeopleUuids,
          bulk_action: action,
          kind: kind,
        })
      }
    } else if (action === 'share') {
      handleShare()
    } else if (action === 'message') {
      handleMail()
    } else if (action === 'archive' || action === 'restore') {
      bulkAction({
        list_uuid: companyList.uuid,
        selected_candidates_uuids: selectedPeopleUuids,
        bulk_action: action,
        kind: kind,
      })
    }
  }

  const handleMail = () => {
    if (selectedPeople.length === 0) {
      cabalToast({
        content: 'Select profiles to send messages',
        style: 'error',
      })
      return
    }

    const params = {
      selected_candidates_uuids: selectedPeopleUuids,
    }

    callApi(api.v2BulkActionGetMessageRecipients, params)
      .then((data) => {
        compose({
          recipients: {
            advisors: data.advisors,
          },
        })
      })
      .catch(() => {
        cabalToast({
          style: 'error',
          content: 'Failed to open composer, please try again.',
        })
      })
  }

  const handleShare = () => {
    callApi(api.getInsertsByUuids, {
      filter_by: 'uuids',
      uuids: selectedPeopleUuids,
    }).then((data) => {
      const filteredData = data.filter((item: any) => selectedPeopleUuids.includes(item.uuid))
      const htmlContent = filteredData.map((item: any) => item.insert_content).join('')
      compose({
        body: htmlContent,
      })
    })
  }

  const bulkMenuItems = [
    {
      title: 'Message',
      onClick: () => handleOnSelect('message', 'message'),
      icon: <i className="far fa-envelope" />,
    },
    {
      title: 'Share',
      onClick: () => handleOnSelect('share', 'share'),
      icon: <i className="far fa-arrow-up-from-bracket" />,
    },
    {
      title: `${archivedStatus ? 'Restore' : 'Archive'}`,
      onClick: () => handleOnSelect(archivedStatus ? 'restore' : 'archive', 'candidates'),
      icon: <i className="far fa-archive" />,
    },
    {
      title: 'Delete',
      onClick: () => handleOnSelect('delete', 'candidates'),
      icon: <i className="far fa-trash-alt" />,
    },
  ]

  return (
    <>
      {adminView && (
        <span>
          <BulkActionsIsland
            isOpen={selectedPeople.length > 0}
            setIsOpen={() => setSelectedPeople([])}
            bulkMenuItems={bulkMenuItems}
            itemCount={selectedPeople.length}
          />
        </span>
      )}
    </>
  )
}

export default PeopleListBulkActions
