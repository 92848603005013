import axios from 'axios'

import { GlobalPersonBlueprint, LimitedUser } from 'utils/types'

interface ConnectionIntroRequestRequest {
  team_slug: string
  requestable_type: 'GlobalPerson'
  requestable_id: string
  note: string
  linkedin_url?: string
}

export interface ConnectionIntroRequestBlueprint {
  uuid: string
  completed_at: null | string
  note: string
  requestable: GlobalPersonBlueprint
  requester: LimitedUser
  sent_message_uuid: string
}

export default {
  getConnectionIntroRequest: (uuid: string, team_slug: string) => {
    return axios.get<{ connection_intro_request: ConnectionIntroRequestBlueprint }>(
      `/api/connection_intro_requests/${uuid}`,
      {
        params: {
          team_slug,
        },
      },
    )
  },

  createConnectionIntroRequest: (connection_intro_request: ConnectionIntroRequestRequest) => {
    return axios.post('/api/connection_intro_requests', {
      team_slug: connection_intro_request.team_slug,
      connection_intro_request,
    })
  },

  draftConnectionIntroRequest: (
    connection_intro_request: ConnectionIntroRequestBlueprint,
    team_slug: string,
  ) => {
    return axios.get<{ sent_message_uuid?: string; draft_message_uuid?: string }>(
      `/connection_intro_requests/${connection_intro_request.uuid}/draft.json`,
      {
        params: {
          team_slug,
        },
      },
    )
  },
}
