/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { removeUrlSearchParam } from 'containers/RequestCenter/utils'
import { TextInput } from 'global/Input'

import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'

const StyledTextInput = styled(TextInput)`
  border: 1px solid ${({ theme }) => theme.colors.pill_border};
  color: ${({ theme }) => theme.colors.fog_rain};
  position: relative;
`

interface IProps {
  setReload: React.Dispatch<React.SetStateAction<boolean>>
  setFetchRequestQuery: React.Dispatch<React.SetStateAction<string | null>>
  fetchRequestQuery: string | null
}

export function PeopleSearch({ setReload, fetchRequestQuery, setFetchRequestQuery }: IProps) {
  const [query, setQuery] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const debouncedQuery = useDebouncedValue(query, 500)
  const placeHolder = `Search companies, people`
  const history = useHistory()

  useEffect(() => {
    setFetchRequestQuery(debouncedQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery])

  const handleChange = (search: string) => {
    setQuery(search)
    if (!search) {
      setFetchRequestQuery((_prev) => null)
      removeUrlSearchParam({ searchParam: 'name', history: history })
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setReload((prev) => !prev)
    }
  }

  const clearInput = () => {
    setQuery('')
    setFetchRequestQuery(null)
    removeUrlSearchParam({ searchParam: 'name', history: history })
  }

  return (
    <div className="flex flex-col flex-wrap gap-2">
      <div className="relative">
        <StyledTextInput
          autoFocus
          placeholder={placeHolder}
          value={query || fetchRequestQuery || ''}
          className="w-[225px]"
          lightBg
          ref={inputRef}
          label={<i className="far fa-search w-3 h-3" />}
          onChange={(e) => {
            handleChange(e.target.value)
          }}
          onKeyDown={(e) => {
            handleKeyDown(e)
          }}
          labelPosition="left"
          labelRounded3xl={true}
          data-testid={'search-filter'}
        />
        {(query || fetchRequestQuery) && (
          <i
            className="fas fa-times cursor-pointer absolute right-2 top-1/2 transform -translate-y-1/2"
            onClick={clearInput}
          />
        )}
      </div>
    </div>
  )
}
