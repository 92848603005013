import React, { useEffect } from 'react'

import objectHash from 'object-hash'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { useMedia } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useComposer } from 'components/Composer/useComposer'
import CabalButton from 'global/CabalButton'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useCurrentUser, useCurrentUserSettings } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { cn } from 'utils/styles'
import { tabletMediaQuery } from 'utils/ui'

interface Props {
  minimize: boolean
  setMinimize: (minimize: boolean) => void
}

const Divider = styled.div`
  ${tw`my-5`}
  border-bottom: ${({ theme }) => theme.border};
`

const StyledMenuItem = styled.div`
  ${tw`cursor-pointer whitespace-nowrap flex items-center`}
  color: ${({ theme }) => theme.colors.fog_rain};
  position: relative;
  top: 1px;
  padding: 8px 12px;
  border-radius: 12px;
  font-size: 12px;
  line-height: 1.5;

  &.menu-item-selected {
    font-weight: 600;
    ${({ theme }) =>
      `
      background-color: ${theme.colors.cardBackground};
      color: ${theme.colors.primary};
    `}
  }
  &:not(.menu-item-selected, .menu-item-disabled):hover {
    ${({ theme }) =>
      `
      background: ${theme.colors.cardBackground};
    `}
  }
  &.menu-item-disabled {
    opacity: 0.5;
    cursor: default;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background: none;
  }
`

const LeftSidebar: React.FC<Props> = ({ minimize, setMinimize }) => {
  const location = useLocation()
  const history = useHistory()
  const { user } = useCurrentUser()
  const { compose } = useComposer(user.team?.slug)

  const isTablet = useMedia(tabletMediaQuery)

  useEffect(() => {
    if (isTablet) {
      setMinimize(true)
    }
  }, [isTablet])

  const { data: draftMetaDataData } = useQuery(
    [user.team?.slug, 'draftsStats', {}, objectHash({})],
    () => callApi(api.getDraftStats, user.team?.slug, {}, {}),
    {
      enabled: true,
    },
  )
  const draftMetaData = draftMetaDataData?.metadata
  const { settings, updateSetting, settingsLoaded } = useCurrentUserSettings()

  const menuItems = [
    {
      label: (
        <Typography className="flex justify-between items-center w-full">
          <span className="mr-2">Shared with me</span>{' '}
          <span className="ml-auto whitespace-nowrap">
            {draftMetaData?.shared_with_me_count || 0}
          </span>
        </Typography>
      ),
      id: 'users',
      to: `/messages/inbox`,
      active: location.pathname === `/messages/inbox`,
    },
    {
      label: 'My Drafts',
      id: 'pen-to-square',
      to: `/messages/my_drafts`,
      active: location.pathname === `/messages/my_drafts`,
    },
    {
      label: 'Sent',
      id: 'paper-plane',
      to: `/messages/sent`,
      active: location.pathname === `/messages/sent`,
    },
    {
      label: 'All',
      id: 'envelope',
      to: `/messages/all`,
      active: location.pathname === `/messages/all`,
    },
    {
      label: 'Groups',
      id: 'users-medical',
      to: `/messages/groups`,
      active: location.pathname === `/messages/groups`,
    },
    {
      label: 'Templates',
      red_dot_key: 'templates',
      id: 'memo',
      to: `/messages/templates`,
      active: location.pathname === `/messages/templates`,
    },
    {
      label: 'Archive',
      id: 'archive',
      to: `/messages/archive`,
      active: location.pathname.startsWith(`/messages/archive`),
    },
  ]

  const tagItems = [
    {
      label: 'Tags',
      id: 'tag',
      to: `/messages/tags`,
      active: location.pathname === `/messages/tags`,
    },
  ]

  return (
    <div className="flex flex-col justify-between h-[90vh]">
      <div>
        <CabalButton
          className="w-full my-2"
          onClick={() => compose()}
          leftIcon={<i className="far fa-plus fa-fw" />}
        >
          {!minimize && 'Compose'}
        </CabalButton>

        {menuItems.map((item) => (
          <StyledMenuItem
            key={item.id}
            className={item.active ? 'menu-item-selected' : ''}
            onClick={() => {
              if (item.red_dot_key) {
                const key = `hide_${item.red_dot_key}_dot`
                if (!settings[key]) {
                  updateSetting(key, true)
                }
              }

              history.push(item.to)
            }}
          >
            {item.red_dot_key && settingsLoaded && !settings[`hide_${item.red_dot_key}_dot`] && (
              <span className="absolute flex h-2 w-2 top-3.5 right-3">
                <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
              </span>
            )}

            <Tooltip label={minimize ? item.label : undefined} placement="right">
              <Typography
                lineHeight={1.2}
                className={cn('flex items-center h-[20px] gap-3 w-full')}
              >
                <i className={`far fa-${item.id.toLowerCase()} fa-fw`} />
                {!minimize && item.label}
              </Typography>
            </Tooltip>
          </StyledMenuItem>
        ))}

        <div className="-my-2 -mb-4">
          <Divider />
        </div>
      </div>
      <div>
        <StyledMenuItem className="flex justify-end" onClick={() => setMinimize(!minimize)}>
          <Tooltip label={minimize ? 'Expand' : 'Collapse'} placement="right">
            {minimize ? (
              <i className="far fa-chevron-right fa-fw" />
            ) : (
              <i className="far fa-chevron-left fa-fw" />
            )}
          </Tooltip>
        </StyledMenuItem>
      </div>
    </div>
  )
}

export default LeftSidebar
