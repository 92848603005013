import React, { useState } from 'react'

import { Field, FieldProps, Form, Formik } from 'formik'
import Cookies from 'js-cookie'
import { useMutation } from 'react-query'
import { object, string } from 'yup'

import { useCandidateProfile } from 'containers/CandidateProfileModal'
import {
  LINKED_IN_PROFILE_URL_REGEX,
  getLinkedInSlug,
} from 'containers/CandidateProfileModal/linkedInAutoPrefill'
import CabalButton from 'global/CabalButton'
import { Select, TextInputField } from 'global/Input'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import InfoTooltip from 'ui-components/InfoTooltip'

import api, { callApi } from 'utils/api'
import { CurrentUserProfile, Team } from 'utils/types'

import Header from './Header'
import { Card, ContinueWrapper, InputWrapper } from './styles'

interface Props {
  onContinue: () => void
  linkedinRequired?: boolean
  onBack?: () => void
  candidateOnboarding?: boolean
  team?: Team
}

const ProfileSetup: React.FC<Props> = ({
  onContinue,
  linkedinRequired,
  onBack,
  candidateOnboarding,
  team,
}) => {
  const { reloadUser, user } = useCurrentUser()
  const { updateCandidateProfile } = useCandidateProfile()
  const [working, setWorking] = useState(false)

  const isEmailEqualToFirstName = user && user.email.split('@')[0] === user.first_name
  const [userProfile, setUserProfile] = useState({
    first_name: isEmailEqualToFirstName ? '' : user?.first_name,
    last_name: user?.last_name || '',
    linkedin_url: user?.linkedin_url || '',
    visibility: null,
    did_onboarding: true,
  })

  const updateProfile = useMutation(
    (value: Partial<CurrentUserProfile>) => callApi(api.updateCurrentProfile, value),
    {
      onSuccess: () => {
        reloadUser()
      },
    },
  )
  const subscribeToLoops = useMutation(() => callApi(api.subscribeToLoops, 'sign-up'))
  const enrichProfileMutation = useMutation(
    (linkedin_url: string) => {
      return callApi(api.enrichProfile, linkedin_url)
    },
    {
      onSuccess: ({ profile }) => {
        if (!(profile.code && profile.code >= 400 && profile.code < 500)) {
          const experience = profile.experiences[0]
          let title = ''
          let company = ''

          if (experience) {
            title = experience.title ?? ''
            company = experience.company ?? ''
          }

          updateCandidateProfile({
            firstName: userProfile.first_name || profile.first_name,
            lastName: userProfile.last_name || profile.last_name,
            avatar: profile.profile_pic_url ?? '',
            location: profile.city || profile.country || undefined,
            headline: profile.headline ?? '',
            title,
            company,
            bio: profile.summary ?? '',
            workHistory: profile.experiences.map((experience) => ({
              title: experience.title ?? '',
              company: experience.company ?? '',
              fromYear: experience.starts_at?.year ?? null,
              fromMonth: experience.starts_at?.month ?? null,
              toYear: experience.ends_at?.year ?? null,
              toMonth: experience.ends_at?.month ?? null,
              summary: experience.description ?? '',
            })),
            educationHistory: profile.education.map((education) => ({
              fieldOfStudy: education.field_of_study ?? '',
              school: education.school ?? '',
              summary: education.description ?? '',
              fromYear: education.starts_at?.year ?? null,
              fromMonth: education.starts_at?.month ?? null,
              toYear: education.ends_at?.year ?? null,
              toMonth: education.ends_at?.month ?? null,
            })),
          })
        } else {
          updateCandidateProfile({
            firstName: userProfile.first_name,
            lastName: userProfile.last_name,
          })
        }
      },
    },
  )

  const handleSubmit = async (value: Partial<CurrentUserProfile>) => {
    const firstNameCapitalized =
      value.first_name.charAt(0).toUpperCase() + value.first_name.slice(1)
    setUserProfile({ ...userProfile, first_name: firstNameCapitalized })
    const newValue = {
      ...value,
      first_name: firstNameCapitalized,
    }
    updateProfile.mutateAsync(newValue)
    subscribeToLoops.mutateAsync()

    if (newValue.linkedin_url) {
      const linkedInSlug = getLinkedInSlug(newValue.linkedin_url)
      if (linkedinRequired) {
        await enrichProfileMutation.mutateAsync(linkedInSlug)
      } else {
        enrichProfileMutation.mutateAsync(linkedInSlug)
      }
    }

    onContinue()
    setWorking(false)
  }

  const personalInfoSchema = object({
    first_name: string().required('This field is required'),
    last_name: string().required('This field is required'),
    linkedin_url: linkedinRequired
      ? string()
          .required('This field is required')
          .matches(LINKED_IN_PROFILE_URL_REGEX, 'Please enter a valid LinkedIn profile URL')
      : string().matches(LINKED_IN_PROFILE_URL_REGEX, 'Please enter a valid LinkedIn profile URL'),
    visibility: string().nullable(),
  })

  const handleVisibilityClick = (v: string | null) => {
    Cookies.set(`candidate_profile_is_archived`, v === 'confidential' ? 'true' : 'false')
    setUserProfile({ ...userProfile, visibility: v })
  }

  return (
    <Card>
      <Header
        onBack={onBack}
        title={
          candidateOnboarding
            ? 'Set up your profile'
            : !candidateOnboarding && userProfile.linkedin_url != ''
            ? 'Confirm your profile'
            : 'Set up your profile'
        }
        subtitle={''}
      />
      <Formik
        initialValues={userProfile}
        validationSchema={personalInfoSchema}
        onSubmit={(value) => {
          setWorking(true)
          setUserProfile(value)
          handleSubmit(value)
        }}
      >
        {({ handleSubmit }) => {
          return (
            <Form
              onSubmit={handleSubmit}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                }
              }}
            >
              <InputWrapper>
                <Typography fontSize="14" className="flex-shrink-0 sm:w-[160px]">
                  First Name
                </Typography>
                <Field name="first_name">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Enter First Name"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="first-name"
                      autoFocus={props.field.value ? false : true}
                    />
                  )}
                </Field>
              </InputWrapper>
              <InputWrapper>
                <Typography fontSize="14" className="flex-shrink-0 w-[160px]">
                  Last Name
                </Typography>
                <Field name="last_name">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Enter Last Name"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="last-name"
                    />
                  )}
                </Field>
              </InputWrapper>
              <InputWrapper>
                <div className="flex flex-row sm:flex-col flex-shrink-0 sm:w-[160px] sm:items-start justify-between sm:justify-normal">
                  <Typography fontSize="14" className="">
                    LinkedIn URL
                  </Typography>
                  <CabalButton
                    variant="link"
                    type="button"
                    rightIcon={<i className="far fa-copy"></i>}
                    onClick={(e) => {
                      e.stopPropagation()
                      window.open('https://linkedin.com/in/', '_blank', 'noopener noreferrer')
                    }}
                    padding="0"
                    tabIndex={-1}
                  >
                    Get link
                  </CabalButton>
                </div>
                <Field name="linkedin_url">
                  {(props: FieldProps) => (
                    <TextInputField
                      error={props.meta.touched ? props.meta.error : undefined}
                      placeholder="LinkedIn URL"
                      autoFocus={userProfile.first_name && userProfile.last_name ? true : false}
                      {...props.field}
                      className="w-full"
                    />
                  )}
                </Field>
              </InputWrapper>

              {candidateOnboarding && (
                <InputWrapper>
                  <Typography fontSize="14" className="flex-shrink-0 w-[160px]">
                    Profile Visibility
                    <InfoTooltip className="ml-2">
                      <Typography lineHeight={1.2} fontSize="13" className="pb-1" component="p">
                        If you select “Visible,” the {team?.name} team may publish your profile on a
                        list. If you select “Confidential,” your profile will ONLY be accessible by
                        the {team?.name} team and will be hidden from lists.
                      </Typography>
                    </InfoTooltip>
                  </Typography>
                  <Field name="visibility">
                    {({ field, form }: FieldProps) => (
                      <Select
                        {...field}
                        options={[
                          { label: 'Visible', value: 'visible' },
                          { label: 'Confidential', value: 'confidential' },
                        ]}
                        value={userProfile.visibility || null}
                        onChange={(v) => {
                          handleVisibilityClick(v)
                          form.setFieldValue('visibility', v)
                        }}
                        placeholder="Hide my profile?"
                        isClearable
                        className="w-full"
                      />
                    )}
                  </Field>
                </InputWrapper>
              )}

              <ContinueWrapper>
                <CabalButton
                  className="mt-6 mb-2 self-end"
                  variant="primary"
                  type="submit"
                  disabled={working}
                >
                  {working ? 'Enriching...' : 'Continue'}
                </CabalButton>
              </ContinueWrapper>
            </Form>
          )
        }}
      </Formik>
    </Card>
  )
}

export default ProfileSetup
