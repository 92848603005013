import React from 'react'

import { useHistory } from 'react-router-dom'

import { IJob } from 'containers/ListIndexView/TalentList/JobsList/types'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import DataCardV2 from 'ui-components/DataCardV2'

import { htmlTextContent } from 'utils/html'
import { CompanyListBlueprint, CompanyListItemBlueprint } from 'utils/types'

interface Props {
  listItem: CompanyListItemBlueprint
  teamSlug: string
  openJobModal: (job?: IJob) => void
  companyList: CompanyListBlueprint
}

const JobRow: React.FC<Props> = ({ listItem, openJobModal, teamSlug, companyList }) => {
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const history = useHistory()
  const job = listItem.job
  const { showModal } = useModal()

  const dropDownMenu = {
    menuItems: [
      {
        label: 'Edit',
        onSelect: () => {
          openJobModal()
        },
      },
    ],
  }

  const openRequestIntroModal = () => {
    showModal(
      (resolve) => (
        <FacilitateIntroModal
          requestable={job}
          companyList={listItem.company_list}
          resolve={resolve}
          gatekeeperId={listItem.company_list.gatekeeper_id}
          facilitator={listItem.company_list.owning_team}
          includeInitialNote={false}
          networkingOnly={false}
        />
      ),
      'facilitate-intro-modal',
    )
  }

  return (
    <DataCardV2
      onClick={() =>
        history.push(`/${teamSlug}/lists/${listItem.company_list.slug}/${listItem.uuid}`)
      }
      avatar={<i className="far fa-briefcase" />}
      className="rounded-md"
      key={job.uuid}
      title={job.title}
      description={job.description && htmlTextContent(job.description)}
      dropDownMenu={isAdminOrEmployee ? dropDownMenu : undefined}
      cta={
        !isAdminOrEmployee && (
          <CabalButton onClick={openRequestIntroModal} variant="link">
            Request Intro
          </CabalButton>
        )
      }
    />
  )
}

export default JobRow
