import React, { useCallback, useState } from 'react'
import axios from 'axios'
import ReactS3Uploader, { S3Response } from 'react-s3-uploader'
import { cabalToast } from 'ui-components/Toast'
import styled from 'styled-components'
import cx from 'classnames'

import { PropWrapper } from './styles'

const UploadLabel = styled.label`
  width: auto;
  span {
    color: ${({ theme }) => theme.colors.green};
    &:hover {
      color: ${({ theme }) => theme.colors.light_green};
    }
    cursor: pointer;
  }
  input {
    opacity: 0;
  }
`

interface Props {
  onUploadFinish: (attrs: { file: File; upload_uuid: string; url: string }) => void
  isUploading: (uploading: boolean) => void
  attachTo?: string
  maxFileSize?: number
}

const AttachFile: React.FC<Props> = (props) => {
  const [percent, setPercent] = useState<null | number>(null)
  const [uploading, setUploading] = useState<boolean>(false)

  const reset = useCallback(() => {
    setUploading(false)
    setPercent(0)
    props.isUploading(false)
  }, [props.isUploading])

  const onUploadStart = useCallback(
    (file: File, next: (file: File) => void) => {
      if (props.maxFileSize) {
        const fileSizeInMb = file.size / (1024 * 1024)
        if (fileSizeInMb > props.maxFileSize) {
          cabalToast({
            style: 'error',
            content: `File size is too large. Max file size is ${props.maxFileSize} MB.`,
          })

          reset()
          return
        }
      }

      setUploading(true)
      setPercent(1)
      props.isUploading(true)

      next(file)
    },
    [props.maxFileSize, reset],
  )

  const onUploadProgress = useCallback(
    (percent: number) => {
      setPercent(percent)
      setUploading(true)

      props.isUploading(true)
    },
    [props.isUploading],
  )

  const onUploadError = useCallback(
    (messasge: string) => {
      reset()
      console.error('error', messasge)
    },
    [reset],
  )

  const onUploadFinish = useCallback(
    (result: S3Response, file: File) => {
      const url = result.signedUrl

      axios
        .post(`/api/uploads`, {
          url,
          attach_to: props.attachTo || null,
        })
        .then((response) => {
          if (response.status == 200) {
            const { url, uuid } = response.data

            props.onUploadFinish({ file, url, upload_uuid: uuid })
          }

          reset()
        })
        .catch((error) => {
          reset()
          console.error('error', error)
        })
    },
    [props.attachTo, props.onUploadFinish, reset],
  )

  return (
    <UploadLabel>
      {percent || uploading ? (
        <div className="relative mt-1" style={{ maxWidth: 300, width: '90%' }}>
          <div className="flex mb-2 items-center justify-between">
            <div>
              <span
                className={cx(
                  'text-xs font-semibold inline-block py-1 px-2 mr-3',
                  'uppercase rounded-full text-green-600 bg-green-200',
                )}
              >
                Uploading...
              </span>
            </div>
            <div className="text-right text-xs font-semibold inline-block text-green-600 pl-2">
              <span>{percent}%</span>
            </div>
          </div>
          <div className="overflow-hidden h-2 text-xs flex rounded bg-green-200">
            <div
              style={{ width: `${percent}%` }}
              className={cx(
                'shadow-none flex flex-col text-center whitespace-nowrap',
                'text-white justify-center bg-green-500 pl-1',
              )}
            />
          </div>
        </div>
      ) : (
        <PropWrapper>
          <i className="far fa-paperclip" />
        </PropWrapper>
      )}

      {!percent ? (
        <ReactS3Uploader
          signingUrl={`/api/uploads/new`}
          signingUrlMethod="GET"
          contentDisposition="attachment"
          preprocess={onUploadStart}
          onProgress={onUploadProgress}
          onError={onUploadError}
          onFinish={onUploadFinish}
          uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}
          autoUpload={true}
          style={{ display: 'none' }}
        />
      ) : null}
    </UploadLabel>
  )
}

export default AttachFile
