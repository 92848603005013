import * as React from 'react'
import Typography from 'global/Typography'
import Avatar from 'global/Avatar'
import PremiumImage from './premium-12@2x.png'
import PersonalInfoModal from './PersonalInfoModal'
import { PremiumBadge } from '../styles'

const Personal = ({ name, avatar_url, title, loadInfo, paid }: any) => {
  const [showModal, setShowModal] = React.useState(false)
  return (
    <>
      <div className="flex justify-between">
        <div>
          <div className="my-4">
            <Avatar name={name} src={avatar_url} size={'120'} round />
          </div>
          <Typography fontWeight={700}>
            {name}
            {paid && (
              <PremiumBadge className="ml-4">
                <img
                  src={PremiumImage}
                  style={{
                    width: 12,
                    height: 12,
                    marginRight: 5,
                    display: 'inline',
                    marginTop: '-2px',
                  }}
                />
                <span>Premium account</span>
              </PremiumBadge>
            )}
          </Typography>
          <Typography className={'block pt-1'} fontSize="14">
            {title}
          </Typography>
          {/* <Typography className={'block pt-1'} color={'gray'} fontSize={'0.8125rem'}>
        {'Some hardcodede description will show up here'}
      </Typography> */}
          {showModal && <PersonalInfoModal show={showModal} onHide={() => setShowModal(false)} />}
        </div>
        <Typography
          onClick={() => setShowModal(true)}
          data-testid="edit-personal"
          className={'cursor-pointer'}
          color={'gray'}
        >
          <i className="far fa-pen"></i>
        </Typography>
      </div>
    </>
  )
}

export default Personal
