import React, { useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import EmptyState from 'containers/ListIndexView/SalesList/SalesCompanyList/EmptyState'
import SalesCompanyAddWidget from 'containers/ListIndexView/SalesList/SalesCompanyList/SalesCompanyAddWidget'
import SalesCompanyAdminView from 'containers/ListIndexView/SalesList/SalesCompanyList/SalesCompanyAdminView'
import SalesCompanyMemberView from 'containers/ListIndexView/SalesList/SalesCompanyList/SalesCompanyMemberView'
import SalesListBulkActions from 'containers/ListIndexView/SalesList/SalesListBulkActions'
import SalesListCTAs from 'containers/ListIndexView/SalesList/SalesListCTAs'
import SalesListEmptyState from 'containers/ListIndexView/SalesList/SalesListEmptyState'
import SalesListFilters from 'containers/ListIndexView/SalesList/SalesListFilters'
import SalesListHeader from 'containers/ListIndexView/SalesList/SalesListHeader'
import { ISalesCompany, SalesFilterTypes } from 'containers/ListIndexView/SalesList/type'
import { StickyHeader } from 'containers/ListIndexView/style'
import Loading from 'global/Loading'

interface Props {
  listLoading: boolean
  memberView: boolean
  adminView: boolean
  salesListEmpty: boolean
  sharedListView: boolean
  filters: SalesFilterTypes
  setFilters: (filters: SalesFilterTypes) => void
}

const SalesCompanyList: React.FC<Props> = ({
  listLoading,
  memberView,
  adminView,
  salesListEmpty,
  sharedListView,
  filters,
  setFilters,
}) => {
  const [showAddWidget, setShowAddWidget] = useState(false)
  const [selectedCompanies, setSelectedCompanies] = useState<ISalesCompany[]>([])

  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  return (
    <div>
      <StickyHeader>
        <div className="flex flex-col sm:flex-row justify-between gap-4 sm:items-center">
          <SalesListHeader />

          <div className="flex gap-6">
            <SalesListCTAs onShowAddWidget={() => setShowAddWidget(!showAddWidget)} />
          </div>
        </div>

        <SalesListFilters
          filters={filters}
          setFilters={setFilters}
          ownedCompanyTags={[]}
          setSelectedItems={setSelectedCompanies}
          selectedItems={selectedCompanies}
          type="company"
        />
      </StickyHeader>
      {listLoading && (
        <div className="my-10 flex items-center justify-center">
          <Loading />
        </div>
      )}
      {!listLoading && (
        <>
          <div ref={parent}>
            {showAddWidget && <SalesCompanyAddWidget onClose={() => setShowAddWidget(false)} />}
          </div>

          {/* Empty state */}
          {salesListEmpty && !showAddWidget && (
            <EmptyState onShowAddWidget={() => setShowAddWidget(true)} />
          )}

          {/* Admin View */}
          {adminView && !salesListEmpty && (
            <SalesCompanyAdminView
              selectedCompanies={selectedCompanies}
              setSelectedCompanies={setSelectedCompanies}
              filters={filters}
            />
          )}

          {/* Member View */}
          {(memberView || sharedListView) && !salesListEmpty && <SalesCompanyMemberView />}

          <SalesListBulkActions
            selectedItems={selectedCompanies}
            setSelectedItems={setSelectedCompanies}
          />
        </>
      )}
    </div>
  )
}

export default SalesCompanyList
