import * as React from 'react'
import Modal from 'global/Modal'
import InlineOffers from 'components/InlineOffers'

interface Props {
  show: boolean
  onHide: () => void
  teamSlug: string
  refetch?: () => void
}

const OpenOfferModal: React.FC<Props> = ({ show, onHide, teamSlug, refetch }) => {
  const handleSuccess = () => {
    onHide()
    refetch?.()
  }

  return (
    <Modal show={show} onHide={onHide}>
      <div className="-ml-6 -mr-4 ">
        <InlineOffers teamSlug={teamSlug} onSuccess={handleSuccess} />
      </div>
    </Modal>
  )
}

export default OpenOfferModal
