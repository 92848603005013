import React, { useState } from 'react'

import { PopupModal } from 'react-calendly'
import styled from 'styled-components'
import copyToClipboard from 'utils/copyToClipboard'

import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'

interface Props {
  onHide: () => void
  teamSlug: string
}

const TopSectionWrapper = styled.div`
  background-color: rgba(138, 151, 255, 0.12);
`

const ShareModal: React.FC<Props> = ({ onHide, teamSlug }) => {
  const [openCalendly, setOpenCalendly] = useState(false)

  const { team } = useTeam(teamSlug)

  const url = `https://getcabal.com/${team?.slug}`
  return (
    <>
      <Modal show={true} onHide={onHide} header={'Share your Cabal page'}>
        <ModalSectionWrapper>
          <TopSectionWrapper className="rounded-lg  py-6 flex flex-col items-center justify-center">
            <Typography color="purple" fontSize="18">
              <i className="fal fa-lock-alt" />
            </Typography>
            <Typography fontWeight={600} fontSize="12" className="pt-2">
              Share Cabal with external stakeholders
            </Typography>
            <Typography fontSize="12" color="fog_rain" className="">
              Get help with sales referrals, recruiting, and more. Schedule a demo to start your
              free trial
            </Typography>
            <CabalButton
              className="mt-4 w-1/2"
              leftIcon={<i className="fa-solid fa-calendar-days"></i>}
              onClick={() => setOpenCalendly(true)}
            >
              Schedule demo
            </CabalButton>
          </TopSectionWrapper>
          <div className="rounded-lg  py-6 mt-6 flex flex-col items-center justify-center">
            <Typography fontSize="18" color="fog">
              <i className="fal fa-user-group" />
            </Typography>
            <Typography fontWeight={600} fontSize="12" className="pt-2">
              Share with teammates
            </Typography>
            <Typography fontSize="12" color="fog_rain" className="">
              To share Cabal internally, copy your Cabal address and share it with your teammates
            </Typography>
            <div className="flex mt-4 space-x-2 w-full justify-center">
              <TextInput className="w-2/5" value={url} readOnly />
              <CabalButton
                leftIcon={<i className="far fa-link"></i>}
                variant="secondary"
                onClick={() => copyToClipboard(url)}
              >
                Copy link
              </CabalButton>
            </div>
          </div>
        </ModalSectionWrapper>
      </Modal>
      <PopupModal
        url="https://calendly.com/burke33/30min"
        onModalClose={() => setOpenCalendly(false)}
        open={openCalendly}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById('calendly-index')}
      />
    </>
  )
}

export default ShareModal
