import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { DialogContent, DialogOverlay } from '@reach/dialog'

import cx from 'classnames'
import { pick } from 'lodash'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import { useNetworkState } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'

import MessageCollaborators from 'components/Collaborators/v2'
import CommentsAndActivity from 'components/CommentsAndActivity/v2'
import ShareDraft from 'containers/ShareDraft'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useAdvisors, useCurrentUser, useGroups, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { useContacts } from 'utils/api/contacts'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import { SCHEDULE_DATE_FORMAT } from 'utils/templates'
import { EmailSnippet, EmailTemplate, PresendCheckParams } from 'utils/types'

import ComposerBase, { ComposerBaseProps } from '../Base'
import { useComposerState } from '../Context'
import { recipients } from '../Context/recipients'
import InsertTabs from '../Sidebar/InsertTabs'
import Personalizations from '../Sidebar/Personalizations'
import Templates from '../Sidebar/Templates'
import ComposerSidebarBase, { ComposerSidebarBaseProps } from '../SidebarBase'
import { useComposeQuery } from '../useComposeQuery'
import { scheduleSendDropwdownMenuItems } from './ScheduleSend'
import { renderContextSection } from './contextSection'
import { selectSnippet } from './selectSnippet'

const StyledDialogContent = styled(DialogContent)`
  -webkit-font-smoothing: antialiased;
  ${tw`rounded-lg relative p-0 flex overflow-visible`}

  background-color: ${({ theme }) => theme.colors.shadow};

  color: ${({ theme }) => theme.colors.primary};
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
  margin: 50px;

  @media (max-width: 640px) {
    width: calc(100vw - 12px);
    height: calc(100vh - 12px);
  }
`

const StyledDialogOverlay = styled(DialogOverlay)`
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
`

export interface ComposerWithDataProps {
  teamSlug: string
  onHide: () => void
  allowAdvisors: boolean
  allowGroups: boolean
  allowScheduledSend: boolean
  className?: string
  onReconnect: () => void
}

export interface ComposerWithDataRef {
  handleSelectSnippet: (s: EmailSnippet) => void
  handleTemplateSelect: (t: EmailTemplate) => void
}

const ComposerModal: React.VFC<ComposerWithDataProps> = ({
  teamSlug,
  allowAdvisors,
  allowGroups,
  allowScheduledSend,
  className,
  onHide: _onHide,
  onReconnect,
}) => {
  const {
    composerState,
    setComposerMessage,
    setComposerState,
    draftAutoSaveRef,
    ckeditorRef,
    individualRecipients,
    personalizations,
    sendMessage,
    sendTest,
    sendState,
    currentPersonalization,
    setPersonalization,
  } = useComposerState()
  const { message: queryMessage } = useComposeQuery(teamSlug)
  const { showModal } = useModal()

  const { online: _online } = useNetworkState()
  const online = useDebouncedValue(_online, 2000)
  const lastOnlineRef = useRef<boolean | undefined>()

  const { user } = useCurrentUser()
  const { advisors } = useAdvisors({ teamSlug })
  const { groups } = useGroups(teamSlug)
  const { team } = useTeam(teamSlug)
  const { canMessageAdvisors } = useAccessControl(teamSlug)
  const [contacts, getContacts] = useContacts()
  const message = composerState?.message

  useEffect(() => {
    if (lastOnlineRef.current === false && online === true) {
      onReconnect()
    }
    lastOnlineRef.current = online
  }, [online])

  useEffect(() => {
    if (!!queryMessage && !composerState) {
      setComposerState({
        currentPersonalizationUuid: undefined,
        personalizations: [],
        presenceList: [],
        message: queryMessage,
      })
    }
  }, [composerState, queryMessage])

  const getSendersQuery = useQuery(['senderUsers', teamSlug], () =>
    callApi(api.getSenderUsers, teamSlug),
  )
  const senders = useMemo(
    () => getSendersQuery.data?.senders || [],
    [getSendersQuery.data?.senders],
  )

  const sender = useMemo(
    () => senders.find((s) => s.uuid === message?.sender_uuid),
    [message?.sender_uuid, senders],
  )
  const isCurrentUserOwner = useMemo(
    () => !!(message && user.sender_uuids.includes(message.sender_uuid)),
    [message, user.sender_uuids],
  )

  const cancelSendMutation = useMutation(
    ['cancelSend', teamSlug],
    () => {
      if (message && !!message.schedule_at) {
        if (moment(message.schedule_at).isBefore(moment())) {
          alert('Cannot make changes because the message is sent now!')
          throw 'Editing sent scheduled message'
        }
      }
      return callApi(api.draftCancelSend, message!.uuid, teamSlug)
    },
    {
      onError: () => {
        cabalToast({ style: 'error', content: 'something unexpected happend' })
      },
      onSuccess: () => {
        setComposerMessage({ schedule_at: null })
      },
    },
  )

  const scheduleSendMutation = useMutation(
    ['scheduleSend', teamSlug],
    (schedule_at: string) => {
      return callApi(api.draftScheduleSend, message!.uuid, teamSlug, schedule_at)
    },
    {
      onError: () => {
        cabalToast({ style: 'error', content: 'something unexpected happend' })
      },
      onSuccess: (_, schedule_at) => {
        setComposerMessage({ schedule_at })
      },
    },
  )

  const notifySenderMutation = useMutation(
    ['notifySender', teamSlug],
    (note: string) => {
      draftAutoSaveRef.current?.forceSendToServer?.()
      return callApi(api.draftNotifySender, message!.uuid, teamSlug, sender!.uuid, note)
    },
    {
      onError: () => {
        cabalToast({ style: 'error', content: 'something unexpected happend' })
      },
      onSuccess: ({ notified_sender_uuids }) => {
        setComposerMessage({ notified_sender_uuids })
      },
    },
  )

  const handleTemplateSelect = useCallback(
    (t: EmailTemplate) => {
      setComposerMessage(pick(t, 'body', 'subject', 'buttons', 'attachments', 'to', 'cc') as any)
    },
    [setComposerMessage],
  )

  const handleSelectSnippet = useCallback(
    (s: EmailSnippet) => {
      if (!composerState) return

      selectSnippet(s, user, ckeditorRef, composerState.message.request)
    },
    [ckeditorRef, composerState, user],
  )

  const onHide = useCallback(() => {
    setComposerState(undefined)
    _onHide()
  }, [_onHide, setComposerState])

  const sections = useMemo<ComposerSidebarBaseProps['sections']>(() => {
    if (!message) return []

    const sections = [
      {
        id: 'share',
        title: 'Share',
        icon: <i className="far fa-share" />,
        content: (
          <MessageCollaborators
            teamSlug={teamSlug}
            attachableType="Message"
            attachableUuid={message.uuid}
          />
        ),
      },
      {
        id: 'templates',
        title: 'Templates',
        icon: <i className="far fa-magic" />,
        content: (
          <Templates
            onSelectTemplate={handleTemplateSelect}
            teamSlug={teamSlug}
            closeComposer={() => onHide()}
          />
        ),
      },
      {
        id: 'insert',
        title: 'Insert',
        icon: <i className="far fa-bolt" />,
        content: <InsertTabs onSelectSnippet={handleSelectSnippet} teamSlug={teamSlug} />,
      },
      {
        id: 'personalize',
        title: `Personalize (${personalizations.length}/${individualRecipients.length})`,
        icon: <i className="far fa-star-christmas" />,
        content: <Personalizations />,
      },
      {
        id: 'comments',
        title: 'Comments',
        icon: <i className="far fa-comment" />,
        content: (
          <CommentsAndActivity
            className="h-full"
            attachableId={message.uuid}
            attachableType="Message"
            teamSlug={teamSlug}
          />
        ),
      },
    ]

    if (message.request && team) {
      sections.unshift({
        id: 'context',
        title: 'Context',
        icon: <i className="far fa-info-circle" />,
        content: renderContextSection(message, team),
      })
    }

    return sections
  }, [
    handleSelectSnippet,
    handleTemplateSelect,
    individualRecipients.length,
    message,
    onHide,
    personalizations.length,
    team,
    teamSlug,
  ])

  const setScheduleAt = (date: string) => {
    scheduleSendMutation.mutate(date)
  }

  const renderShareDraftModal: RenderModal = (resolve) => (
    <ShareDraft
      sender={sender}
      teamSlug={teamSlug}
      onHide={() => resolve()}
      onSubmit={(note) => {
        notifySenderMutation.mutate(note)
        resolve()
        onHide()
      }}
      uuid={message.uuid}
      subject={message.subject}
      to={message.to}
    />
  )

  const renderSubmitButton: ComposerBaseProps['renderSubmitButton'] = (hasExtraSubmitButtons) => {
    if (sendState === 'presend') {
      return (
        <div className="flex items-center gap-2">
          <span>Please wait...</span>
          <Loading size="25px" className="mr-3 my-0.5" />
        </div>
      )
    }

    const sendTestButton = (
      <CabalButton
        variant="tertiary"
        working={sendState === 'testing'}
        onClick={() => {
          sendTest()
        }}
        className={cx({ 'rounded-tr-none rounded-br-none': !!hasExtraSubmitButtons })}
      >
        Send me a test email
      </CabalButton>
    )

    if (!isCurrentUserOwner) {
      if (!!message?.notified_sender_uuids?.includes(sender?.uuid || '')) {
        return (
          <div className="flex items-center pr-1">
            {sendTestButton}
            <Typography fontSize="13">
              <Typography color="green">
                <i className="fas fa-check-circle" />
              </Typography>{' '}
              Shared with {sender?.name}
            </Typography>
          </div>
        )
      } else {
        let canNotifySender = true
        if (
          user.team?.slug === team?.slug &&
          user.team_role === 'member' &&
          sender?.type === 'advisor'
        ) {
          canNotifySender = false
        }

        let tooltip: string | undefined
        if (!canNotifySender) {
          tooltip = `You don’t have permission to share this draft directly. Add teammates by @mentioning them in the comments, or copy the share link.`
        }

        return (
          <div className="flex items-center">
            {sendTestButton}
            <CabalButton
              disabled={!canNotifySender}
              working={notifySenderMutation.isLoading}
              onClick={() => showModal(renderShareDraftModal, 'render_share_draft_modal')}
              tooltip={tooltip}
            >
              Share with {sender?.name}
            </CabalButton>
          </div>
        )
      }
    }

    if (scheduleSendMutation.isLoading) {
      return <Loading size="25px" />
    }

    if (!!message?.schedule_at) {
      return (
        <div className="flex items-center gap-2">
          {sendTestButton}
          <Typography fontSize="13">
            Scheduled for {moment(message.schedule_at).format(SCHEDULE_DATE_FORMAT)}
          </Typography>
          <CabalButton
            onClick={() => cancelSendMutation.mutate()}
            className={cx({ 'rounded-tr-none rounded-br-none': !!hasExtraSubmitButtons })}
            size="small"
            working={cancelSendMutation.isLoading}
          >
            Cancel send
          </CabalButton>
        </div>
      )
    }

    return (
      <div className="flex items-center gap-2">
        {sendTestButton}
        <CabalButton
          working={sendState === 'sending'}
          onClick={() => {
            sendMessage()
          }}
          className={cx({ 'rounded-tr-none rounded-br-none': !!hasExtraSubmitButtons })}
        >
          Send
        </CabalButton>
      </div>
    )
  }

  return (
    <StyledDialogOverlay className="DialogOverlay" isOpen={true} dangerouslyBypassFocusLock={true}>
      <StyledDialogContent className="compose-modal">
        {!composerState && (
          <div className="flex justify-center w-full">
            <Loading className="my-6" />
          </div>
        )}
        {composerState && (
          <>
            <ComposerBase
              className={className}
              team={team!}
              recipients={recipients({
                allowAdvisors,
                allowGroups,
                contacts,
                groups,
                team,
                advisors,
                message: composerState.message,
                canMessageAdvisors: !!canMessageAdvisors,
              })}
              senders={senders}
              toFieldProps={{
                allowCustomEmail: true,
              }}
              ccFieldProps={{
                allowCustomEmail: true,
              }}
              bccFieldProps={{
                allowCustomEmail: true,
              }}
              submitExtraMenuItems={scheduleSendDropwdownMenuItems({
                sendState,
                message: message!,
                isCurrentUserOwner,
                allowScheduledSend,
                setScheduleAt,
                showModal,
              })}
              renderSubmitButton={renderSubmitButton}
              message={message!}
              currentPersonalization={currentPersonalization}
              setPersonalization={setPersonalization}
              setMessage={setComposerMessage}
              ckeditorRef={ckeditorRef}
            />
            <ComposerSidebarBase
              defaultSection={message?.request ? 'context' : 'comments'}
              onHide={onHide}
              sections={sections}
            />
          </>
        )}
      </StyledDialogContent>
    </StyledDialogOverlay>
  )
}

export default ComposerModal
