import React from 'react'
import { createPortal } from 'react-dom'

import { Toaster } from 'react-hot-toast'

const ReactToastProvider: React.FC<{
  children: React.ReactNode
  container?: HTMLElement | null
  containerStyle?: React.CSSProperties
}> = ({ children, container, containerStyle }) => {
  return (
    <>
      {createPortal(<Toaster containerStyle={containerStyle} />, container ?? document.body)}
      {children}
    </>
  )
}

export default ReactToastProvider
