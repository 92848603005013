import React, { useMemo, useState } from 'react'

import { AxiosResponse } from 'axios'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { useJobsListContext } from 'containers/ListIndexView/TalentList/JobsList'
import JobsEditOrAddModal from 'containers/ListIndexView/TalentList/JobsList/JobsEditOrAddModal'
import { IJob } from 'containers/ListIndexView/TalentList/JobsList/types'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'

interface Props {
  setOnClose: () => void
}

const AddIndividualSection: React.FC<Props> = ({ setOnClose }) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const { showModal } = useModal()
  const teamSlug = useTeamSlug()
  const logo_url = 'https://logo.clearbit.com/'
  const {
    companyListData: companyList,
    refetchListItems,
    refetchCompanyList,
  } = useJobsListContext()
  const { list_id } = useParams<{ list_id: string }>()

  const { mutate: addJob } = useMutation(
    (job: IJob) => callApi(api.addJob, job.uuid, companyList.uuid),
    {
      onSuccess: () => {
        refetchListItems()
        refetchJobsForAdd()
        refetchCompanyList()
        cabalToast({
          content: 'Job added successfully!',
          style: 'success',
        })
      },
    },
  )

  const searchJobOrCompany = async (query: string): Promise<AxiosResponse> => {
    return await api.getJobsForAdd(companyList.uuid, teamSlug, 1, { search: [query] })
  }

  const useJobOrCompany = (query: string) => {
    return useQuery({
      queryKey: ['jobsForAdd', companyList.uuid, query],
      queryFn: () => searchJobOrCompany(query),
      enabled: !!query,
      retry: 3,
    })
  }

  const { isLoading, data: jobsData, refetch: refetchJobsForAdd } = useJobOrCompany(debouncedQuery)
  const jobs: IJob[] = useMemo(
    () => jobsData?.data?.jobs?.filter((c: IJob) => c?.investor_company?.company_name),
    [jobsData],
  )

  const showEditOrAddModal = (job?: IJob) => {
    showModal(
      (resolve) => (
        <JobsEditOrAddModal
          onHide={resolve}
          teamSlug={teamSlug}
          refetchListItems={refetchListItems}
          listUuids={[companyList.uuid]}
          currentListUuid={companyList.uuid}
          company={job?.investor_company}
          showListDropdown={list_id == 'all-jobs'}
        />
      ),
      'edit-job-modal',
    )
  }

  return (
    <div className="mt-4">
      <SearchInput
        onChange={(c) => setQuery(c.target.value)}
        placeholder={`Search for jobs`}
        value={query}
      />

      <div className="my-4 px-3">
        <CabalButton
          variant="link"
          onClick={() => {
            showEditOrAddModal()
          }}
          leftIcon={<i className="far fa-plus fa-xs" />}
          padding="0"
          size="large"
        >
          <span>Create job</span>
        </CabalButton>
      </div>
      {debouncedQuery !== '' && (
        <>
          {isLoading && (
            <div className="flex mt-4 w-full justify-center">
              <Loading />
            </div>
          )}
          {jobs &&
            jobs.map((job, index) => (
              <DataCardV2
                key={index}
                title={job.title}
                description={job.investor_company.company_name}
                avatar={
                  <Avatar
                    name={job.investor_company.company_name}
                    src={logo_url + job.investor_company.domain}
                  />
                }
                cta={
                  <CabalButton onClick={() => addJob(job)} variant="link">
                    <Typography fontSize="12" color={job.on_list ? 'fog' : 'purple'}>
                      {job.on_list ? 'Added' : 'Add'}
                    </Typography>
                  </CabalButton>
                }
                titleIcon={
                  <>
                    {true && (
                      <Typography
                        color="fog"
                        fontSize="12"
                        onClick={(e) => {
                          e.stopPropagation()
                          window.open(
                            job.investor_company.linkedin_url
                              ? job.investor_company.linkedin_url
                              : 'https://www.linkedin.com',
                            '_blank',
                            'noopener noreferrer',
                          )
                        }}
                      >
                        <i className="fab fa-linkedin fa-fw"></i>
                      </Typography>
                    )}
                  </>
                }
              />
            ))}
        </>
      )}
    </div>
  )
}

export default AddIndividualSection
