import React, { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import PersonalDetails from 'components/CompanyHome/MemberOnboardingCards/PersonalDetails'
import { Divider } from 'components/SendMessage/styles'
import Loading from 'global/Loading'
import { useAdvisorship, useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

import { ConnectionStatusType } from '../../../containers/Asks/ConnectionStatus'
import CompleteProfile from './CompleteProfile'
import HeaderSection from './HeaderSection'
import HowItWorks from './HowItWorks'
import HowItWorksVcs from './HowItWorksVcs'
import WelcomeMessage from './WelcomeMessage'

const CardWrapper = styled.div`
  ${tw`rounded-lg my-2 sm:my-8`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: ${({ theme }) => theme.border};
`
const HomeSectionWrapper = styled.div`
  ${tw`sm:max-w-3xl sm:mx-auto py-5 sm:px-0 overflow-hidden`}
`

const MemberOnboardingCards = ({
  connectionStatus,
  isVCFirm,
  checkConnectionStatus,
}: {
  connectionStatus: any
  isVCFirm?: boolean
  checkConnectionStatus?: () => void
}) => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { advisor, reloadAdvisorship } = useAdvisorship(teamSlug)
  const queryClient = useQueryClient()

  const [currentStep, setCurrentStep] = useState(1)
  const memberUuid = advisor?.uuid

  const saveAdvisorMutation = useMutation(() => callApi(api.setOnboarded, teamSlug), {
    onSuccess: () => {
      reloadAdvisorship()
    },
  })

  const shareConnectionsMutation = useMutation(
    () => callApi(api.updateConnectionSharing, { team_slug: teamSlug, share_connections: true }),
    {
      onSuccess: async () => {
        cabalToast({
          style: 'success',
          content: `Your connections were shared with ${team?.name}!`,
        })
        await queryClient.invalidateQueries(['get_page_lists', teamSlug])

        checkConnectionStatus?.()
        saveAdvisorMutation.mutate()
      },
    },
  )

  const handleShareConnections = () => {
    shareConnectionsMutation.mutate()
  }

  const {
    connections_synced_at,
    shared_connections_at,
    uploaded_connections_at,
    google_contacts,
    connections_count,
    linkedin_export,
  }: ConnectionStatusType = connectionStatus || {}

  const connectionsCount =
    (connections_count || linkedin_export?.count || 0) + google_contacts?.count || 0

  const shared_connections = !!shared_connections_at

  const syncedGoogleContacts =
    google_contacts?.first_sync_started_at && google_contacts?.first_sync_completed_at

  const shareConnections =
    !!team &&
    (!!connections_synced_at || !!uploaded_connections_at || !!syncedGoogleContacts) &&
    shared_connections !== true

  if (!connectionStatus) {
    return (
      <div className="py-8">
        <Loading />
      </div>
    )
  }

  return (
    <HomeSectionWrapper>
      <CardWrapper>
        <HeaderSection
          sharedConnections={shareConnections}
          step={currentStep}
          onSetStep={setCurrentStep}
          hideStepThree={shared_connections || isVCFirm}
        />
        <Divider className="m-0" />

        {currentStep === 1 && <WelcomeMessage onSetStep={() => setCurrentStep(2)} />}
        {currentStep === 2 && !isVCFirm && (
          <HowItWorks
            onSetStep={() => {
              shared_connections ? saveAdvisorMutation.mutate() : setCurrentStep(3)
            }}
          />
        )}
        {currentStep === 2 && isVCFirm && (
          <HowItWorksVcs
            onSetStep={() =>
              shared_connections ? saveAdvisorMutation.mutate() : setCurrentStep(3)
            }
          />
        )}
        {currentStep === 3 && (
          <PersonalDetails
            onNext={() => {
              setCurrentStep(4)
            }}
          />
        )}
        {currentStep === 4 && (
          <CompleteProfile
            sharedConnections={shareConnections}
            connectionsCount={connectionsCount}
            onShareConnections={() => handleShareConnections()}
            working={shareConnectionsMutation.isLoading || saveAdvisorMutation.isLoading}
            saveAdvisorMutation={() => saveAdvisorMutation.mutate()}
            savingAdvisor={saveAdvisorMutation.isLoading}
          />
        )}
      </CardWrapper>
    </HomeSectionWrapper>
  )
}

export default MemberOnboardingCards
