import React, { useMemo } from 'react'

import axios from 'axios'
import { sanitize } from 'dompurify'
import compact from 'lodash/compact'
import moment, { name } from 'moment'
import Expand from 'react-expand-animated'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import CandidateProfileModal from 'containers/CandidateProfileModal'
import CandidateDataTags from 'containers/CompanyPage/SharedListsSection/CandidateDataTags'
import PortfolioDataTags from 'containers/CompanyPage/SharedListsSection/PortfolioDataTags'
import ListOtherGridModal from 'containers/ListView/Companies/ListOtherGridModal'
import EditPortfolioCompanyModal from 'containers/ListView/EditPortfolioCompanyModal'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'
import { TitleIconProps } from 'ui-components/DataCard'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import avatars from 'utils/DefaultPersonAvatars'
import api, { callApi } from 'utils/api'
import { cn } from 'utils/styles'
import { CompanyBlueprint, CompanyListBlueprint } from 'utils/types'
import { formatCompact } from 'utils/ui'

const CircleWrapper = styled.div`
  ${tw`flex items-center justify-center rounded-full`}
  background-color: ${({ theme }) => theme.pill.purple_filled.primary_bg};
  width: 16px;
  height: 16px;
`

interface Props {
  company: CompanyBlueprint // TODO: CompanyBlueprint should include CompanyList, it is not defined in the interface
  teamSlug: string
  listSlug: string
  listUuid: string
  canDelete?: boolean
  canExport?: boolean
  companyListCtaType?: string
  companyList?: CompanyListBlueprint
  view: 'grid' | 'list'
  new_intro_layout?: boolean
  isPortfolio?: boolean
  isOwned?: boolean
  refetch?: () => void
  memberViewToggle: boolean
  selectedItems: CompanyBlueprint[]
  isAllCandidates?: boolean
  setSelectedItems: (company: CompanyBlueprint[]) => void
}

export function formatTime(time) {
  const units = [
    { name: 'Y', value: 'years' },
    { name: 'M', value: 'months' },
    { name: 'W', value: 'weeks' },
    { name: 'D', value: 'days' },
  ]

  for (const unit of units) {
    const diff = moment().diff(moment(time), unit.value)
    if (diff > 0) {
      return `${diff}${unit.name}`
    }
  }

  return '0D'
}

const CompanyCard: React.FC<Props> = ({
  company,
  companyList,
  teamSlug,
  listSlug,
  listUuid,
  canDelete,
  companyListCtaType,
  view,
  isPortfolio = false,
  isOwned = false,
  refetch,
  memberViewToggle,
  selectedItems,
  isAllCandidates,
  setSelectedItems,
}) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { team } = useTeam(teamSlug)
  const { isAdminOrEmployee } = useAccessControl(teamSlug)

  const [expand, setExpand] = React.useState(false)
  const [vote, setVote] = React.useState<1 | -1 | 0>(company.voted ? 1 : 0)
  const { name, logo_url, upvotes_count, people_count, stage_name, last_modified_at, updated_at } =
    company
  const canExport = !(company.item_type === 'GlobalPerson') && !(company.item_type === 'other')

  const selected = !!selectedItems.find((c) => c.uuid === company.uuid)

  const { mutate: sendVote, isLoading: sendingVote } = useMutation(
    ['createVote'],
    (vote: 1 | -1 | 0) =>
      callApi(api.createVote, {
        team_slug: teamSlug,
        votable_type: 'GlobalPerson',
        votable_id: company.global_person_uuid!,
        vote,
        company_id: null,
        list_uuid: listUuid,
      }),
    {
      onSuccess: (_, variables) => {
        setVote(variables)
        refetch?.()
      },
    },
  )

  const handleSelection = (item: CompanyBlueprint, selected: boolean) => {
    if (selected) {
      setSelectedItems([...selectedItems, item])
    } else {
      setSelectedItems(selectedItems.filter((a) => a.uuid !== item.uuid))
    }
  }

  const { showModal, closeModal } = useModal()

  const gatekeeperId = companyList?.gatekeeper_id

  const openListOtherGridModal = () => {
    showModal(
      (resolve) => (
        <ListOtherGridModal
          onHide={resolve}
          description={company.description}
          title={name}
          cta={company.cta}
          link={company.link}
        />
      ),
      'list-other-grid-modal',
    )
  }

  const openRequestIntroModal = () => {
    showModal(
      (resolve) => (
        <FacilitateIntroModal
          requestable={company}
          companyList={companyList}
          resolve={resolve}
          gatekeeperId={companyList?.gatekeeper_id}
          facilitator={companyList?.owning_team}
          includeInitialNote={false}
          requestType={'advisor'}
        />
      ),
      'facilitate-intro-modal',
    )
  }

  const gridView = view === 'grid'
  let owner: React.ReactNode = null
  const titleIcons: TitleIconProps[] = []
  let avatarUrl = logo_url ? logo_url : undefined

  if (!avatarUrl && company.item_type === 'other' && company.avatar_upload_uuid) {
    avatarUrl = `/api/uploads/${company.avatar_upload_uuid}`
  } else if (!avatarUrl && company.item_type === 'GlobalPerson') {
    avatarUrl = company.candidate_profile?.global_person?.image_url
  } else if (company.item_type === 'persona') {
    const hash = Array.from(company.name).reduce(
      (acc, char) => (Math.imul(67, acc) + char.charCodeAt(0)) | 0,
      0,
    )
    const index = Math.abs(hash) % 34
    avatarUrl = avatars[index]
  }

  let avatarName = undefined

  if (
    !logo_url &&
    company.item_type != 'job' &&
    company.item_type != 'persona' &&
    company.item_type != 'other'
  ) {
    avatarName = company.name
  }

  if (!!upvotes_count) {
    titleIcons.push({
      icon: 'fas fa-thumbs-up',
      tooltip: `${upvotes_count} member(s) offered to help`,
    })
  }

  if (team && company.owner_email && company.owner_email.length > 0) {
    const emails = company.owner_email

    if (emails.length > 1) {
      const tooltipContent = (
        <div className="flex flex-col space-y-1">
          <Typography fontSize="12" fontWeight={600}>
            Owners
          </Typography>
          {emails.map((email) => {
            const ownerUser = team.admins_and_members.find((u) => u.email === email)
            return (
              <div className="flex items-center space-x-1" key={email}>
                <Avatar
                  size="16"
                  name={ownerUser ? ownerUser.name : email}
                  src={ownerUser ? ownerUser.avatar_url : ' '}
                />
                <Typography fontSize="12" color="fog_rain">
                  {email}
                </Typography>
              </div>
            )
          })}
        </div>
      )

      owner = (
        <Tooltip label={tooltipContent}>
          <CircleWrapper>
            <Typography fontSize="10" color="fog_rain" lineHeight={1}>
              {emails.length}
            </Typography>
          </CircleWrapper>
        </Tooltip>
      )
    } else {
      const ownerUser = team.admins_and_members.find((u) => u.email === emails[0])

      owner = (
        <Tooltip
          label={
            <div className="flex flex-col">
              <Typography fontSize="12" fontWeight={600}>
                Owner
              </Typography>
              <Typography fontSize="12" color="fog_rain">
                {ownerUser ? ownerUser.name : emails[0]}
              </Typography>
            </div>
          }
        >
          <Avatar
            size="16"
            name={ownerUser ? ownerUser.name : emails[0]}
            src={ownerUser?.avatar_url}
          />
        </Tooltip>
      )
    }
  }

  const renderNewStages = (stages: string[]) => {
    if (stages.length === 1) {
      return <span key={stages[0]}>{stages[0]}</span>
    } else {
      const tooltipContent = (
        <>
          {stages.slice(1).map((stage) => (
            <span key={stage} className="m-1">
              {stage}
            </span>
          ))}
        </>
      )
      return (
        <Tooltip label={tooltipContent}>
          <span key={stages[0]} className="cursor-pointer">
            {stages[0]} +{stages.slice(1).length}
          </span>
        </Tooltip>
      )
    }
  }

  const onClick = useMemo(() => {
    return () => {
      if (company.item_type === 'other') {
        if (view === 'list') {
          setExpand(!expand)
        } else {
          openListOtherGridModal()
        }
      } else if (company.item_type === 'GlobalPerson') {
        if (isTalentList) {
          if (company?.company_list?.is_all_candidates && company?.candidate_profile?.uuid) {
            history.push(`/${teamSlug}/candidates/${company.candidate_profile.uuid}`)
          } else {
            window.open(
              `/${teamSlug}/lists/${listSlug}/${company.uuid}`,
              '_blank',
              'noopener, noreferrer',
            )
          }
        } else {
          company?.profile_url && window.open(company.profile_url, '_blank', 'noopener, noreferrer')
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search)
        urlParams.delete('search')
        if (!!listUuid) {
          urlParams.set('list_uuid', listUuid)
        }
        const route = `/${teamSlug}/lists/${listSlug || 'companies'}/${
          company.uuid
        }?${urlParams.toString()}`
        history.push(route)
      }
    }
  }, [company.item_type, company.link, company.uuid, listSlug, teamSlug, expand])

  const descriptionBottom = [] as React.ReactNode[]

  const getDescription = () => {
    const description = []

    if (company.item_type === 'other') {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = sanitize(company.description || '')
      return [
        <Typography
          key={company.description}
          component="div"
          className="truncate"
          color="fog"
          fontSize="12"
        >
          {tempDiv.textContent}
        </Typography>,
      ]
    }

    if (isPortfolio) {
      if (company.description) {
        return [company.description]
      } else {
        return ['-']
      }
    }

    if (company.description) {
      description.push(company.description)
    }

    if (people_count != null) {
      gridView
        ? description.push(
            <>
              <i className="far fa-arrows-left-right fa-fw" /> {formatCompact(people_count)}
            </>,
          )
        : description.push(`${formatCompact(people_count)} connections`)
    }

    if (!!upvotes_count) {
      gridView
        ? description.push(
            <>
              <i className="far fa-thumbs-up fa-fw" /> {upvotes_count}
            </>,
          )
        : description.push(`${upvotes_count} offers`)
    }

    if (stage_name && stage_name.length > 0) {
      const stageName = renderNewStages(stage_name)

      gridView ? descriptionBottom.push(stageName) : description.push(stageName)
    }

    if (owner) {
      description.push(owner)
    }

    let time

    if (last_modified_at) {
      time = last_modified_at
    } else if (updated_at) {
      time = updated_at
    }

    if (time) {
      const formattedTime = formatTime(time)
      gridView
        ? description.push(
            <>
              <i className="far fa-clock fa-fw" /> {formattedTime}
            </>,
          )
        : description.push(moment(time).fromNow())
    }

    return description
  }

  const canMakePortfolioRequests = team?.features?.can_make_intro_on_portfolio
  const canUseIntroRequests = isPortfolio
    ? canMakePortfolioRequests
    : team?.features?.internal_intro_request

  const isRequestIntroCtaType = companyListCtaType === 'request_intro'
  const canUseCtaOnList = team?.features?.can_use_cta_on_list
  const isCompanyListTypePeopleOrPortfolio =
    company?.company_list?.list_type === 'people' || isPortfolio

  const isPeopleList = company?.company_list?.list_type === 'people'

  const isTalentList = company?.company_list?.is_talent

  const showRequestIntro =
    isRequestIntroCtaType &&
    canUseCtaOnList &&
    isCompanyListTypePeopleOrPortfolio &&
    canUseIntroRequests

  const description = getDescription()

  const menuItems = compact([
    showRequestIntro && {
      type: 'action',
      label: 'Request Intro',
      onSelect: () => {
        openRequestIntroModal()
      },
    },

    isPeopleList &&
      isOwned && {
        label: 'Edit',
        onSelect: () => {
          showModal(
            (resolve) => (
              <CandidateProfileModal
                listUuid={''}
                teamSlug={teamSlug}
                onFinalize={() => {
                  refetch?.()
                  resolve()
                }}
                linkedinUrl={company.candidate_profile?.linkedin_url}
                onHide={() => resolve()}
                inPeopleList={false}
                candidateUuid={company.candidate_profile?.uuid}
              />
            ),
            'edit-candidate-modal',
          )
        },
      },

    isPortfolio &&
      isOwned && {
        label: 'Edit',
        onSelect: () => {
          company.investor_company_uuids &&
            showModal(
              (resolve) => (
                <EditPortfolioCompanyModal
                  onHide={resolve}
                  investor_company_uuids={company.investor_company_uuids!}
                  teamSlug={teamSlug}
                  refetchPortfolioList={refetch!}
                />
              ),
              'edit-portfolio-company-modal',
            )
        },
      },

    canExport && {
      type: 'action',
      onSelect: () => {
        axios.post(`/api/companies/${company.uuid}/export`).then((res) => {
          cabalToast({
            content: 'Your export will be emailed to you shortly',
            style: 'success',
          })
        })
      },
      label: 'Export',
    },

    canDelete &&
      company.model_type == 'CompanyListItem' && {
        type: 'action',
        onSelect: () => {
          api
            .removeListItems(teamSlug, listUuid, [company.uuid])
            .then(() => {
              queryClient.refetchQueries([teamSlug, 'opportunityData'])
            })
            .finally(() => {
              cabalToast({
                content: 'Item has been removed from the list',
                style: 'success',
              })
            })
        },
        label: 'Delete',
      },
  ])

  const ctaAction = () => {
    if (showRequestIntro) {
      if (isAdminOrEmployee && !memberViewToggle) {
        return (
          <CabalButton
            padding={view === 'list' ? '0' : undefined}
            variant={view === 'grid' ? 'primary' : 'link'}
            onClick={() => {
              onClick()
            }}
            className="w-full"
          >
            View
          </CabalButton>
        )
      }
      return (
        <>
          <CabalButton
            padding={view === 'list' ? '0' : undefined}
            variant={view === 'grid' ? 'primary' : 'link'}
            onClick={() => {
              openRequestIntroModal()
            }}
            className="w-full"
          >
            Request Intro
          </CabalButton>
        </>
      )
    } else if (company.item_type === 'GlobalPerson') {
      return (
        <>
          <CabalButton
            padding={view === 'list' ? '0' : undefined}
            variant={view === 'grid' ? 'primary' : 'link'}
            onClick={() => {
              if (!isTalentList) {
                sendVote(vote === 0 ? 1 : 0)
              } else {
                onClick()
              }
            }}
            rightIcon={
              <>
                {!isTalentList && vote === 0 && <i className="far fa-thumbs-up" />}
                {!isTalentList && vote === 1 && <i className="fas fa-thumbs-up" />}
              </>
            }
            className="w-full"
          >
            {isTalentList ? 'View Profile' : vote ? 'Offered' : 'Offer Intro '}
          </CabalButton>
        </>
      )
    } else if (company.item_type === 'other' && company.cta != '' && company.link != '') {
      const formattedLink =
        company.link && !/^https?:\/\//i.test(company.link)
          ? `http://${company.link}`
          : company.link
      return (
        <CabalButton
          padding={view === 'list' ? '0' : undefined}
          variant={view === 'grid' ? 'primary' : 'link'}
          onClick={() => {
            window.open(formattedLink, '_blank')
          }}
          className="w-full"
        >
          {company.cta}
        </CabalButton>
      )
    } else {
      return (
        <>
          <CabalButton
            padding={view === 'list' ? '0' : undefined}
            variant={view === 'grid' ? 'primary' : 'link'}
            onClick={() => onClick()}
            className="w-full"
          >
            View
          </CabalButton>
        </>
      )
    }
  }

  return (
    <div
      className={cn({
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] hover:bg-[#f1f1f2] dark:hover:bg-[#1b232f]':
          view === 'list',
      })}
    >
      <DataCardV2
        title={
          <>
            {name}
            {company.profile_url && company.candidate_profile && (
              <Typography
                component="button"
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(company.profile_url, '_blank', 'noopener noreferrer')
                }}
                className="ml-2"
                fontSize="14"
                color="fog"
                lineHeight={1}
              >
                <i className="fab fa-linkedin" />
              </Typography>
            )}
            {!isTalentList && vote === 1 && view === 'grid' && (
              <Typography
                component="button"
                className="ml-2"
                fontSize="14"
                color="purple"
                lineHeight={1}
              >
                <i className="fas fa-thumbs-up" />
              </Typography>
            )}
          </>
        }
        checkbox={
          isAdminOrEmployee && !memberViewToggle && isAllCandidates
            ? {
                checked: selected,
                onChange: (e) => {
                  handleSelection(company, e.target.checked)
                },
              }
            : undefined
        }
        view={view}
        avatar={
          <Avatar
            src={
              company.domain && company.item_type === 'company'
                ? `https://logo.clearbit.com/${company.domain}`
                : avatarUrl
            }
            name={avatarName}
            size={gridView ? '72' : '32'}
          />
        }
        description={description.length > 0 && !expand ? description : null}
        descriptionBottom={
          Object.keys(company?.candidate_profile || {}).length > 0 ? (
            <div className="flex flex-col items-start gap-2.5">
              {company.candidate_profile?.company && (
                <Typography lineHeight={1}>
                  <i className="far fa-building" /> {company.candidate_profile?.company}
                </Typography>
              )}
              {company.candidate_profile?.location && (
                <Typography lineHeight={1}>
                  <i className="far fa-location-dot" /> {company.candidate_profile?.location}
                </Typography>
              )}

              {isTalentList && company.candidate_profile && (
                <CandidateDataTags company={company.candidate_profile} />
              )}
            </div>
          ) : isPortfolio ? (
            <div className="flex flex-col items-start gap-2.5 mb-2">
              <Typography lineHeight={1}>
                <i className="far fa-users" /> {company?.domain_matching_member_count || 0} Members
              </Typography>

              <Typography lineHeight={1}>
                <i className="far fa-list" /> {company?.team?.shared_list_count || 0} Shared Lists
              </Typography>
            </div>
          ) : descriptionBottom.length > 0 ? (
            descriptionBottom
          ) : null
        }
        gridPillsSection={<PortfolioDataTags company={company} />}
        onClick={onClick}
        cta={ctaAction()}
        dropDownMenu={menuItems.length > 0 ? { menuItems: menuItems } : undefined}
      />
      {company.item_type === 'other' && view === 'list' && (
        <div>
          <Expand open={expand} className="ml-[50px]  rounded-none mr-4">
            <div className="mb-4 break-words">
              <Typography
                component="div"
                className="ql-output"
                color="fog"
                fontSize="12"
                dangerouslySetInnerHTML={{ __html: sanitize(company.description || '') }}
              />
            </div>
          </Expand>
        </div>
      )}
    </div>
  )
}

export default React.memo(CompanyCard)
