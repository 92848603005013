import React from 'react'

import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import ImportCompaniesModal from 'containers/Portfolio/AdminView/CompaniesTabV1/ImportCompaniesModal'
import RequestIntroModal from 'containers/Portfolio/MemberView/RequestIntroModal'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { InvestorCompany } from 'utils/types/investor'

interface Props {
  teamSlug: string
}
const PortfolioSection: React.FC<Props> = ({ teamSlug }) => {
  const history = useHistory()
  const { showModal } = useModal()
  const { team } = useTeam(teamSlug)
  const { canEditPortfolio } = useAccessControl(teamSlug)

  const openImportCompaniesModal = () => {
    showModal(
      (resolve) => (
        <ImportCompaniesModal
          teamSlug={teamSlug}
          resolve={() => {
            refetch()
            resolve()
          }}
        />
      ),
      'import-investor-company-modal',
    )
  }

  const openPortfolioRequestIntroModal = (company: InvestorCompany) => {
    showModal(
      (resolve) => (
        <RequestIntroModal
          companyLogo={company.logo_url}
          companyName={company.company_name}
          companyUuid={company.uuid}
          teamSlug={teamSlug}
          resolve={resolve}
          onCreate={() => refetch()}
        />
      ),
      'request-intro-modal',
    )
  }

  const { isLoading, isFetching, refetch, data } = useQuery(
    ['getInvestorCompanies', teamSlug],
    () => callApi(api.getInvestorCompanies, teamSlug, null, 3),
  )

  const companies = data?.companies || []

  if (companies.length === 0) return null

  return (
    <Widget
      classNames="group"
      title={
        <Typography
          className="flex items-center cursor-pointer"
          component="button"
          fontWeight={600}
          onClick={() => history.push(`/${teamSlug}/portfolio`)}
        >
          Portfolio
          <Typography color="fog">
            <i className="fal fa-chevron-right ml-2 fa-xs" />
          </Typography>
        </Typography>
      }
      cta={
        canEditPortfolio && (
          <div className="hidden group-hover:flex">
            <CabalButton
              onClick={openImportCompaniesModal}
              variant="link"
              padding="0"
              data-testid="import-portfolio-companies-btn"
            >
              Add Portfolio
            </CabalButton>
          </div>
        )
      }
    >
      <>
        {companies.map((company) => {
          return (
            <DataCardV2
              key={company.uuid}
              title={company.company_name}
              avatar={
                company.logo_url ? (
                  <Avatar src={company.logo_url} />
                ) : (
                  <i className="far fa-building" />
                )
              }
              onClick={
                !company.intro_request
                  ? () => {
                      openPortfolioRequestIntroModal(company)
                    }
                  : undefined
              }
              cta={
                <>
                  {company.intro_request && (
                    <Typography color="fog" fontSize="12">
                      <i className="far fa-check-circle mr-2" />
                      Intro Requested
                    </Typography>
                  )}
                  {!company.intro_request && (
                    <CabalButton
                      padding="0"
                      variant="link"
                      onClick={() => {
                        openPortfolioRequestIntroModal(company)
                      }}
                    >
                      Request Intro
                    </CabalButton>
                  )}
                </>
              }
            />
          )
        })}
        {!isFetching && team && team?.investor_companies_count > 0 && (
          <div className="mt-4 flex items-center justify-center w-full">
            <CabalButton variant="link" onClick={() => history.push(`/${teamSlug}/portfolio`)}>
              See all ({team?.investor_companies_count})
            </CabalButton>
          </div>
        )}
      </>
    </Widget>
  )
}

export default PortfolioSection
