import * as React from 'react'
import { useState, useEffect } from 'react'
import api from 'utils/api'
import 'chart.js'
import { TableEl, Th, Td, Span } from 'global/Table'
import * as moment from 'moment'

interface Props {
  advisor: any
}

function AdvisorGmail({ advisor }: Props) {
  const [messages, setMessages] = useState(null)

  useEffect(() => {
    api.getAdvisorGmailMessages(advisor.uuid).then((response) => {
      const { messages } = response.data
      setMessages(messages)
    })
  }, [advisor.uuid])

  if (!messages || (messages && messages.length == 0)) {
    return <div />
  }

  return (
    <div>
      {messages && (
        <div className="mt-6">
          <h3 className="mb-2 font-bold">Gmail Messages</h3>
          <TableEl>
            <thead>
              <tr className="text-left">
                <Th style={{ width: 250 }}>To</Th>
                <Th style={{ width: 300 }}>From</Th>
                <Th>Subject</Th>
                <Th>Date</Th>
              </tr>
            </thead>
            <tbody>
              {messages.map((message: any) => (
                <tr key={message.id}>
                  <Td>
                    <Span>{message.to_email}</Span>
                  </Td>
                  <Td>
                    <Span>{message.from_email}</Span>
                  </Td>
                  <Td>
                    <Span>{message.subject}</Span>
                  </Td>
                  <Td>
                    <Span>{moment(message.sent_date).calendar()}</Span>
                  </Td>
                </tr>
              ))}
            </tbody>
          </TableEl>
          <small className="mt-2">Showing max 10 results</small>
        </div>
      )}
    </div>
  )
}

export default AdvisorGmail
