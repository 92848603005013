import React from 'react'

import { useAccessControl } from 'global/AccessControl'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import PillV2 from 'ui-components/PillV2'
import Switch from 'ui-components/Switch'

interface FilterOption {
  label: string
  value: string
  icon?: string
}

interface Props {
  filter: string
  onSetFilter: (filter: string) => void
  memberView: boolean
  setMemberView: (memberView: boolean) => void
  filterOptions: FilterOption[]
}

const DetailPageFilters: React.FC<Props> = ({
  filter,
  onSetFilter,
  memberView,
  setMemberView,
  filterOptions,
}) => {
  const teamSlug = useTeamSlug()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  return (
    <div className="flex justify-between items-center mt-6">
      <div className="flex flex-wrap gap-2">
        {filterOptions.map((option) => (
          <PillV2
            key={option.value}
            title={
              <Typography fontSize="12" lineHeight={1}>
                {option.icon && <i className={`${option.icon} mr-1.5`} />}
                {option.label}
              </Typography>
            }
            active={filter === option.value}
            onClick={() => onSetFilter(option.value)}
          />
        ))}
      </div>
      {isAdminOrEmployee && (
        <div className="flex items-center gap-2">
          <Typography fontSize="12" color="fog">
            Member View
          </Typography>
          <Switch checked={memberView} onCheckedChange={(e) => setMemberView(e)} />
        </div>
      )}
    </div>
  )
}

export default DetailPageFilters
