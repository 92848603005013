import axios from 'axios'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useCurrentUser } from 'store/hooks'
import { Contact, GetContactsResponse, Params } from 'utils/types'
import api, { callApi } from '.'

export default {
  getContacts: (page = 1, query?: string) => {
    return axios.get<GetContactsResponse>(`/api/contacts`, {
      params: {
        page,
        query,
      },
    })
  },

  syncContacts: () => {
    return axios.get('/api/contacts/sync')
  },
}

export const useContacts: () => [
  Contact[],
  (q: string) => Promise<GetContactsResponse> | null,
  boolean,
] = () => {
  const { user } = useCurrentUser()

  const [contacts, _setContacts] = useState<Params<Contact>>({})

  const setContacts = (_contacts: Contact[]) => {
    const updatedContacts = { ...contacts }
    for (const contact of _contacts) {
      updatedContacts[contact.uuid] = contact
    }
    _setContacts(updatedContacts)
  }

  const { mutateAsync, isLoading } = useMutation(
    ['getContacts'],
    (q: string) => callApi(api.getContacts, 1, q),
    {
      onSuccess: ({ contacts: _contacts }) => {
        setContacts(_contacts)
      },
    },
  )

  useQuery(['getContacts'], () => callApi(api.getContacts), {
    onSuccess: ({ contacts: _contacts }) => {
      setContacts(_contacts)
    },
  })

  const getContacts = (q: string) => {
    if (!user.has_synced_gcontacts) return null
    return !!q ? mutateAsync(q) : null
  }

  return [Object.values(contacts), getContacts, isLoading]
}
