import { memoize, trim } from 'lodash'

export const absoluteHtmlContent = (html?: string | null) => {
  const dummyEl = document.createElement('div')
  dummyEl.innerHTML = `${html}`
  if (dummyEl.querySelectorAll('img, svg').length === 0 && trim(dummyEl.innerText).length === 0) {
    return null
  }

  return html || null
}

export const htmlTextContent = (html?: string | null) => {
  const dummyEl = document.createElement('div')
  dummyEl.innerHTML = `${html}`
  return dummyEl.innerText
}
