import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { useTeam } from 'store/hooks'
import { CompanySlugParam, Team } from 'utils/types'

interface Props {
  title: string
  team?: Team
  teamName?: string
}

const CabalTitle: React.VFC<Props> = (props) => {
  const { company_slug } = useParams<CompanySlugParam>()
  const { team } = useTeam(company_slug)

  const teamName = team?.name || props.team?.name || props.teamName

  let tail = ` – Cabal`

  if (teamName) {
    tail = ` / ${teamName}${tail}`
  }

  return (
    <Helmet>
      <title>
        {props.title}
        {tail}
      </title>
    </Helmet>
  )
}

export default CabalTitle
