import React, { useEffect, useRef, useState } from 'react'

import { useUpdateIRDetails } from 'containers/RequestCenter/queries/UpdateIntroRequestDetails'
import CabalButton from 'global/CabalButton'
import { TextArea, TextInput } from 'global/Input'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

import { ITableDataObject, ITableDataObjectArr } from './types/ITypes'

interface IProps {
  introRequestUuid: string
  value: string | number
  setTableRowData: (tableRowData: ITableDataObjectArr | []) => void
  cellKey: string
  setEditingRow: (editingRow: string | null) => void
  editableColumns: { [key: string]: boolean }
  setEditableColumns: (columns: { [key: string]: boolean }) => void
}
export function EditableAmountCell({
  introRequestUuid,
  value,
  setTableRowData,
  cellKey,
  setEditingRow,
  editableColumns,
  setEditableColumns,
}: IProps) {
  const [currentValue, setCurrentValue] = useState<number | string>(value)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const teamSlug = useTeamSlug()
  const handleAmountChange = (newAmount: string | null, key: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTableRowData((prevData: ITableDataObjectArr | []) =>
      prevData.map((tdObject: ITableDataObject) => {
        if (Object.keys(tdObject)[0] === key) {
          const updatedTdObject = {
            ...tdObject,
            [key]: {
              ...tdObject[key],
              amount: {
                ...tdObject[key].amount,
                label: newAmount === undefined || newAmount === null ? '-' : newAmount,
              },
            },
          }
          return updatedTdObject
        }
        return tdObject
      }),
    )
  }
  const handleDisplayOfTd = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    setEditingRow(null)
    setEditableColumns({ ...editableColumns, [`${cellKey}amount`]: false })
  }

  const { mutate: updateIRMutation } = useUpdateIRDetails({
    handleAmountChange,
    cellKey,
    attribute: 'amount',
    handleTdDisplay: handleDisplayOfTd,
  })

  const handleBlur = () => {
    handleDisplayOfTd()
  }

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus()
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (textAreaRef.current && !textAreaRef.current.contains(event.target as Node)) {
        handleBlur()
      }
    }

    const handleBlurEvent = () => {
      handleBlur()
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('blur', handleBlurEvent, true)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('blur', handleBlurEvent, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateIntroRequest = (newAmount: string) => {
    const num = newAmount.replace(/,/g, '')

    updateIRMutation({
      introRequestUuid,
      teamSlug,
      amount: num,
    })
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      updateIntroRequest((event.target as HTMLTextAreaElement).value)
    }
  }

  return (
    <div className="relative shadow-sm">
      <TextInput
        compact={true}
        className={`w-full`}
        autoFocus
        suppressContentEditableWarning
        onBlur={handleBlur}
        onKeyDown={(event) => handleKeyDown(event)}
        onChange={(event) => setCurrentValue(event.target.value)}
        value={currentValue === '-' ? '' : currentValue}
      />
    </div>
  )
}
