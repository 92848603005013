import React, { useRef, useState } from 'react'
import { cabalToast } from 'ui-components/Toast'

import Modal from 'global/Modal'
import CabalButton from 'global/CabalButton'
import { useCurrentUser } from 'store/hooks'

import MicrosoftLogo from './../../images/microsoft-icon.png'

interface Props {
  show: boolean
  onHide: () => void
  onAuthorize: (email?: string) => void
}

const MicrosoftAuthModal: React.VFC<Props> = (props) => {
  const { onHide, onAuthorize } = props
  const popupRef = useRef<Window | null>(null)
  const [working, setWorking] = useState(false)

  const { reloadUser } = useCurrentUser()

  const onMessage = React.useCallback(
    async (e) => {
      const { type, status, email, message } = e.data

      if (type == 'microsoft_auth') {
        if (status === 'success') {
          await reloadUser()
          onAuthorize(email)

          cabalToast({ style: 'success', content: 'Successfully authorized with Microsoft' })
        } else if (status === 'error') {
          cabalToast({ style: 'error', content: message })
          setWorking(false)
        }
      }
    },
    [onAuthorize, reloadUser],
  )

  React.useEffect(() => {
    window.addEventListener('message', onMessage)
    return () => window.removeEventListener('message', onMessage)
  }, [onMessage])

  function onSubmit(e: React.SyntheticEvent) {
    setWorking(true)
    e.preventDefault()
    const width = 550
    const height = 650
    const x = screen.width / 2 - width / 2
    const y = screen.height / 2 - height / 2
    popupRef.current = window.open(
      `/auth/microsoft`,
      `Authorize Microsoft`,
      `width=${width},height=${height},left=${x},top=${y}`,
    )
    const timer = setInterval(function () {
      if (popupRef.current?.closed) {
        clearInterval(timer)
        setWorking(false)
      }
    }, 1000)
  }

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      header="Authorize Microsoft account"
      centerActions={
        <CabalButton
          variant="auth"
          working={working}
          leftIcon={<img src={MicrosoftLogo} width="18" alt="Microsoft" />}
          onClick={onSubmit}
        >
          Sign in with Microsoft
        </CabalButton>
      }
    >
      <div className="pt-2 overflow-hidden text-center">
        <img src={MicrosoftLogo} className="m-auto" width={48} />

        <div className="text-secondary my-8">
          Send messages directly from your Microsoft account
        </div>
      </div>
    </Modal>
  )
}

export default MicrosoftAuthModal
