import React from 'react'
import { useQuery } from 'react-query'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import styled from 'styled-components'

import Modal from 'global/Modal'
import Loading from 'global/Loading'
import CabalButton from 'global/CabalButton'

const StyledPage = styled(Page)`
  canvas {
    width: 70ch !important;
    height: auto !important;
  }
`

interface Props {
  header?: string
  url: string
  onHide: () => void
  cachedByUrl?: boolean
}

const PreviewAgreementModal: React.VFC<Props> = ({
  header = 'Agreement Preview',
  url,
  onHide,
  cachedByUrl = false,
}) => {
  const { data: getAgreementPreviewData, isFetching } = useQuery(
    ['agreementPreview', url],
    async () => {
      const pdf = await pdfjs.getDocument(url).promise
      const data = await pdf.getData()
      return { pdf, data }
    },
    {
      staleTime: cachedByUrl ? Infinity : 0,
    },
  )

  return (
    <Modal
      show
      onHide={onHide}
      header={header}
      size="wrap-content"
      leftActions={
        <CabalButton onClick={onHide} leftIcon={<i className="fas fa-caret-left" />}>
          Back
        </CabalButton>
      }
    >
      {isFetching && <Loading className="mb-6 mx-96" />}
      {!isFetching && getAgreementPreviewData && (
        <Document
          file={{
            data: getAgreementPreviewData.data,
          }}
          className="relative"
        >
          {Array.from(new Array(getAgreementPreviewData.pdf.numPages), (el, index) => (
            <div key={`page_${index + 1}`}>
              <StyledPage
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
              <hr className="absolute border-light dark:border-dark w-full z-10" />
            </div>
          ))}
        </Document>
      )}
    </Modal>
  )
}

export default PreviewAgreementModal
