import * as React from 'react'

import cx from 'classnames'
import Expand from 'react-expand-animated'
import styled, { StyledComponentPropsWithRef } from 'styled-components'
import { twMerge } from 'tailwind-merge'
import tw from 'twin.macro'

import Typography from 'global/Typography'

import { StyleProps } from 'utils/types'

export const CardContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  color: ${({ theme }) => theme.colors.primary};
`

export const Status = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(-40%, -40%);
  border-radius: 50%;
  background: ${({ theme }) => theme.layout.main_bg_color};
  line-height: 0px;
  padding: 0.25rem;
`

interface Props {
  complete?: boolean
  onClick?: () => void
  showUnchecked?: boolean
  padding?: number
  uncheckedColor?: 'green' | 'gray'
  show?: boolean
  pinned?: boolean
  invert?: boolean
  selected?: boolean
}

const Card: React.FC<Props & StyledComponentPropsWithRef<'div'>> = ({
  complete = false,
  onClick,
  children,
  showUnchecked = false,
  uncheckedColor = 'green',
  show = true,
  pinned = false,
  invert = false,
  className,
  selected,
  ...extraProps
}) => {
  return (
    <Expand open={show}>
      <CardContainer
        onClick={onClick}
        {...extraProps}
        className={twMerge(cx('p-5 my-1 rounded-lg', className))}
      >
        {children}
      </CardContainer>
    </Expand>
  )
}

export default Card
