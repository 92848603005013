import isFunction from 'lodash/isFunction'

import { intl } from 'utils/intl'

function updateAt<T>(arr: T[], index: number, value: T) {
  return arr.map((el, i) => (index === i ? value : el))
}

function updateWhere<T, K extends keyof T>(
  arr: T[],
  key: K,
  value: T[K],
  newValue: T | ((prevValue: T) => T),
) {
  return arr.map((el) => {
    if (el[key] !== value) return el

    if (isFunction(newValue)) {
      return newValue(el)
    } else {
      return newValue
    }
  })
}

const joinReactElements = <T>(arr: T[], separator: (n: number) => T): T[] =>
  arr.reduce<T[]>((acc, currentElement, currentIndex) => {
    const isLast = currentIndex === arr.length - 1
    return [...acc, currentElement, ...(isLast ? [] : [separator(currentIndex)])]
  }, [])

const formatList = (arr: string[]) => {
  return intl.formatList(arr)
}

export { updateAt, updateWhere, joinReactElements, formatList }
