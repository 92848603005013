import * as React from 'react'
import Modal from 'global/Modal'
import FormFields from './FormFields'
import CabalButton from 'global/CabalButton'
import { cabalToast } from 'ui-components/Toast'
import axios from 'axios'

interface Props {
  document_id: string
  companySlug: string
  show: boolean
  public: boolean
  onHide: () => void
  onCreate: () => void
}

class AddModalClass extends React.Component<Props, any> {
  constructor(props) {
    super(props)

    this.state = {
      link: {
        account_name: '',
        require_email_to_view: true,
        allow_downloading: false,
      },
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.save()
  }

  save = () => {
    const { link } = this.state

    if (link.account_name == '') {
      return alert('Must add account name')
    }

    const params = {
      document_id: this.props.document_id,
      link,
      team: this.props.companySlug,
    }

    axios.post(`/api/document_links`, params).then((response) => {
      cabalToast({ style: 'success', content: 'Added link!' })
      this.props.onCreate()
    })
  }

  onChange = (newState) => {
    const link = { ...this.state.link }
    Object.keys(newState).map((key) => (link[key] = newState[key]))
    this.setState({ link })
  }

  render() {
    const { show, onHide } = this.props
    const { link } = this.state

    return (
      <Modal show={show} onHide={onHide} header="Create Link">
        <form onSubmit={this.onSubmit}>
          <FormFields values={link} onChange={this.onChange} />
          <div className="my-4">
            <CabalButton disabled={link.account_name == ''}>Save</CabalButton>
          </div>
        </form>
      </Modal>
    )
  }
}

const AddModal: React.VFC<Props> = (props) => {
  props = {
    ...props,
  }

  return <AddModalClass {...props} />
}

export default AddModal
