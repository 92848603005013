import React, { useEffect, useState } from 'react'
import api, { callApi } from 'utils/api'
import { useQuery } from 'react-query'
import { useSearchParam } from 'react-use'
import { useParams } from 'react-router-dom'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import Card from 'global/Card'
import { Container } from './styles'
import CardLogo from './CardLogo'
import { cabalToast } from 'ui-components/Toast'
import { IntroRequest } from 'utils/types/investor'

const RequestDeclined: React.VFC = () => {
  const companyUuid = useSearchParam('company_uuid')
  const advisorUuid = useSearchParam('advisor_uuid')
  const teamSlug = useSearchParam('team_slug') || ''
  const showErrorToast = useSearchParam('show_error_toast')
  const { secret_uuid: secretUuid } = useParams<{ secret_uuid: string }>()
  const [introRequest, setIntroRequest] = useState<Partial<IntroRequest>>({
    declined_at: '',
    investor_company_logo: null,
    investor_company_name: '',
  })

  const { isLoading } = useQuery(
    ['getIntroRequest', secretUuid],
    () => callApi(api.getIntroRequest, secretUuid, companyUuid || ''),
    {
      onSuccess: (data) => {
        setIntroRequest(data)
      },
      enabled: !!companyUuid,
    },
  )

  const { isLoading: isLoadingAdvisorIntroRequest } = useQuery(
    ['getAdvisorIntroRequest', secretUuid],
    () => callApi(api.getAdvisorIntroRequest, secretUuid, advisorUuid || '', teamSlug),
    {
      onSuccess: (data) => {
        setIntroRequest(data)
      },
      enabled: !!advisorUuid,
    },
  )

  useEffect(() => {
    if (showErrorToast) {
      cabalToast({
        style: 'error',
        content: 'Unable to accept because request was already declined!',
      })
    }
  }, [showErrorToast])

  const {
    declined_at: declinedAt,
    investor_company_logo: logo,
    investor_company_name: companyName,
    job_title: jobTitle,
    candidate_name: candidateName,
    advisor_avatar: advisorAvatar,
    advisor_name: advisorName,
    hiring_contact_name: hiringContactName,
    accepted_or_declined_by_user: acceptedOrDeclineBy,
  } = introRequest

  if (isLoading) return <Loading />

  return (
    <Container>
      <Card className="p-10">
        {companyUuid ? (
          <CardLogo logo={logo || ''} companyName={companyName || ''} />
        ) : (
          <CardLogo logo={advisorAvatar || advisorName || ''} companyName={advisorName || ''} />
        )}
        {declinedAt ? (
          <div className="mt-5">
            <Typography color="fog_rain" component="div">
              <i className="fas fa-check-circle fa-fw" /> Request declined.
            </Typography>
            <Typography
              component="p"
              fontSize="12"
              lineHeight="1.2"
              fontWeight={600}
              className="mb-6 mt-3"
            >
              {`${
                acceptedOrDeclineBy.name || acceptedOrDeclineBy.email
              } declined an intro request by ${companyUuid ? candidateName : hiringContactName}`}
              {jobTitle && ` regarding the ${jobTitle} position`}
              {`.`}
            </Typography>
          </div>
        ) : (
          <div className="mt-5">
            <Typography fontSize="20" color="fog_rain" className="mb-5">
              <i className="fas fa-envelope fa-fw"></i>
            </Typography>
            <Typography color="fog_rain" component="div">
              Please check your email to accept/decline the request.
            </Typography>
          </div>
        )}
      </Card>
    </Container>
  )
}

export default RequestDeclined
