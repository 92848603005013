import React, { useState } from 'react'

import Modal from 'global/Modal'
import { DraftMessageBlueprint, Team } from 'utils/types'
import { useInfiniteQuery } from 'react-query'
import api, { callApi } from 'utils/api'
import CabalButton from 'global/CabalButton'
import moment from 'moment'
import Typography from 'global/Typography'
import styled from 'styled-components'
import tw from 'twin.macro'
import Loading from 'global/Loading'
import { sanitize } from 'dompurify'

const VersionBody = styled.div`
  ${tw`border rounded-lg border-light p-2 dark:border-dark`}

  font-size: 13px;
`

const VersionHistoryItem = styled.button<{ active: boolean }>`
  ${tw`block w-full text-left py-1.5 px-2 mb-1 rounded-lg`}

  background: ${({ theme, active }) => (active ? theme.colors.purple : 'auto')};
  color: ${({ theme, active }) => (active ? theme.colors.white : 'auto')};

  &:hover {
    background: ${({ theme, active }) => (!active ? theme.layout.main_bg_color : 'auto')};
  }
`

interface Props {
  message: DraftMessageBlueprint
  team: Team
  onHide: () => void
  setBody: (b: string) => void
}

const VersionHistoryModal: React.VFC<Props> = ({ message, team, onHide, setBody }) => {
  const [selectedVersion, setSelectedVersion] = useState<string>()

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isFetching } = useInfiniteQuery(
    ['getMessageHistory', message.uuid],
    ({ pageParam: page = 1 }) => callApi(api.getMessageHistory, team.slug, message.uuid, page),
    {
      keepPreviousData: false,
      refetchOnMount: true,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      onSuccess: ({ pages }) => {
        if (!selectedVersion) {
          setSelectedVersion(pages?.[0]?.bodies?.[0]?.created_at)
        }
      },
    },
  )

  const bodies = data?.pages?.flatMap((page) => page.bodies) ?? []
  const selectedVersionBody = selectedVersion
    ? bodies.find((b) => b.created_at === selectedVersion)?.body
    : undefined

  let child: React.ReactChild

  if (isFetching && !isFetchingNextPage) {
    child = <Loading className="py-6" />
  } else {
    if (bodies.length === 0) {
      child = <Typography className="block text-center py-6">No message history found</Typography>
    } else {
      child = (
        <div className="flex">
          <div className="flex-1 mr-2">
            {selectedVersionBody && (
              <VersionBody
                className="message-content"
                dangerouslySetInnerHTML={{ __html: sanitize(selectedVersionBody) }}
              />
            )}
          </div>
          <div className="flex-inital w-1/4">
            {bodies.map((b) => {
              return (
                <VersionHistoryItem
                  key={`version-history-${b.created_at}`}
                  active={selectedVersion === b.created_at}
                  onClick={() => setSelectedVersion(b.created_at)}
                >
                  <div>
                    {moment(b.created_at).format('llll')}
                    <Typography fontSize="12" component="div">
                      {b.user.name}
                    </Typography>
                  </div>
                </VersionHistoryItem>
              )
            })}
            {hasNextPage && (
              <div className="flex justify-center	mt-6">
                <CabalButton
                  variant="tertiary"
                  disabled={isFetchingNextPage}
                  onClick={() => fetchNextPage()}
                >
                  Load more
                </CabalButton>
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  return (
    <Modal
      show
      onHide={onHide}
      header="Version History"
      size="lg"
      leftActions={
        <>
          {selectedVersionBody && (
            <CabalButton
              onClick={() => {
                selectedVersionBody && setBody(selectedVersionBody)
                onHide?.()
              }}
            >
              Restore Version
            </CabalButton>
          )}
        </>
      }
    >
      {child}
    </Modal>
  )
}

export default VersionHistoryModal
