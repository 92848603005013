import axios, { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import { WINSITE_ENDPOINTS } from '../apiEndpoints'
import { IDeleteUploadParams } from '../types/IDeleteUploadParams'

const deleteUpload = async ({
  id,
  uuid,
  teamSlug,
}: IDeleteUploadParams): Promise<AxiosResponse> => {
  return await axios.delete(`${WINSITE_ENDPOINTS.deleteUpload}/${id}`, {
    data: {
      uuid: uuid,
      team_slug: teamSlug,
    },
  })
}

export const useDeleteUpload = () =>
  useMutation({
    mutationFn: ({ id, uuid, teamSlug }: IDeleteUploadParams) =>
      deleteUpload({ id, uuid, teamSlug }),
    retry: 0,
  })
