import React, { useContext, useRef } from 'react'

import Modal from 'global/Modal'
import { ModalContext, useModal } from 'global/Modal/Context'

import { DatePicker, DatePickerInput, DatePickerProps } from '.'

interface DatePickerModalProps extends DatePickerProps {
  onHide?: () => void
}

export const DatePickerModal: React.FC<DatePickerModalProps> = ({
  date,
  defaultDate,
  minDate,
  maxDate,
  onHide,
  onDateChange,
  ...extraProps
}) => {
  const { current: key } = useRef(`date-picker-${new Date().getTime()}`)
  const willFocusAfterCloseRef = useRef(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const { showModal, closeModal, isOpen, blockModalEscape, unblockModalEscape } = useModal()

  const handleDateChange = (date: string) => {
    onDateChange(date)
    handleHide()
  }

  const handleHide = () => {
    if (!isOpen(key)) return

    willFocusAfterCloseRef.current = true
    closeModal(key)
    onHide?.()
  }

  const showDatePicker = async () => {
    if (willFocusAfterCloseRef.current) {
      willFocusAfterCloseRef.current = false
      return
    }
    if (isOpen(key)) return

    blockModalEscape()
    showModal(
      () => (
        <Modal header={null} show onHide={handleHide} ignoreEscapeBlock minimalPadding>
          <div className="flex justify-center my-8">
            <DatePicker
              date={date}
              defaultDate={defaultDate}
              onDateChange={handleDateChange}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
        </Modal>
      ),
      key,
    ).finally(() => {
      unblockModalEscape()
    })
  }

  return (
    <DatePickerInput
      ref={inputRef}
      {...extraProps}
      date={date}
      defaultDate={defaultDate}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onKeyDown={(e) => {
        e.preventDefault()
        showDatePicker()
      }}
      onClick={() => showDatePicker()}
      onFocus={() => showDatePicker()}
    />
  )
}
