import queryString from 'query-string'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import Block from 'components/Block'
import PageWrapper from 'global/PageWrapper'
import { useTeam, useTeamSlug } from 'store/hooks'

interface Props {}

const Blocks: React.VFC<Props> = () => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { block_id, item_id } = useParams()
  const location = useLocation()

  return (
    <PageWrapper title="Blocks">
      <Block
        blockId={block_id || 'list-blocks'}
        itemId={item_id}
        ownerType="Team"
        ownerId={teamSlug}
        params={location.search ? queryString.parse(location.search) : {}}
      />
    </PageWrapper>
  )
}

export default Blocks
