import React, { useEffect } from 'react'

import cx from 'classnames'
import { motion } from 'framer-motion'
import isEqual from 'lodash/isEqual'
import { padding, rgba } from 'polished'
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router'
import { Tab as ReactTab, Tabs as ReactTabs, TabList, TabPanel } from 'react-tabs'
import styled from 'styled-components/macro'
import tw, { theme } from 'twin.macro'

import { useTeamSlug } from 'store/hooks'

import { Params, StyleProps } from 'utils/types'

import { useAccessControl } from './AccessControl'

const StyledTabs = styled(ReactTabs)<{ sticky: boolean; isAdminOrEmployee: boolean }>`
  font-size: 12px;
  & > div {
    background: transparent;
  }

  ${({ sticky }) =>
    sticky &&
    `
      position: sticky;
      top: 0px;
  `}
`

export interface StyledProps {
  variant: 'filters' | 'default'
  padding?: string
  nestedSticky: boolean
}

const StyledTabList = styled(TabList)<StyledProps>`
  ${tw`overflow-x-auto overflow-y-hidden`}

  ${({ variant, theme, noPaddingAndHeight = false, noRightPadding = false }) =>
    variant === 'filters'
      ? `
    border-radius: 0px;
    background-color: ${theme.layout.nav_bg_color};
    ${
      !noPaddingAndHeight &&
      `
      height: 50px;
    `
    }
    ${noRightPadding && `padding-right: 0;`}
  `
      : `
    background-color: ${theme.layout.nav_bg_color};
    padding-left: 0px;
  `};

  ${({ nestedSticky }) =>
    nestedSticky &&
    `
      position: sticky;
      top: 0px;
      z-index: 10;
  `}

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledTab = styled(ReactTab)<StyledProps>`
  ${tw`mr-6 cursor-pointer whitespace-nowrap`}
  color: ${({ theme }) => theme.colors.fog_rain};
  position: relative;
  top: 1px;

  ${({ variant, theme, padding }) =>
    variant === 'filters'
      ? `
    padding: 8px 12px;
    border-radius: 12px;
    margin-right: 6px;
    font-size: 12px;
    `
      : `
    border-bottom: 3px solid transparent;
    padding: ${padding || '0.75rem 0.5rem'};
    margin-right: 12px;
  `}

  &.react-tabs__tab--selected {
    font-weight: 500;

    ${({ theme, variant }) =>
      variant === 'filters'
        ? `
      background-color: ${theme.colors.cardBackground};
      color: ${theme.colors.primary};
      font-weight: 600;
      `
        : ``}
  }

  &:not(.react-tabs__tab--selected, .react-tabs__tab--disabled):hover {
    ${({ theme, variant }) =>
      variant === 'filters'
        ? `
      background: ${theme.colors.cardBackground};
    `
        : `
      color: ${theme.colors.purple};
    `}
  }

  &.react-tabs__tab--disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background: none;
`

const StyledTabPanel = styled(TabPanel)`
  background: transparent;

  &:focus {
    outline: none;
  }
`

export type Tab = {
  id?: string
  label: React.ReactNode
  component?: React.ReactNode | (() => React.ReactNode)
  disabled?: boolean
  dataTestId?: string
  icon?: string
  active?: boolean
  to?: string
}

export interface Props extends StyleProps {
  data: (Tab | null)[]
  index?: number
  onChange?: (index: number, id?: string) => void
  rightActions?: React.ReactNode
  pushSubRoutes?: boolean
  pathSlug?: string
  defaultTab?: string
  tabListclassName?: string
  variant?: 'filters' | 'default'
  sticky?: boolean
  tabItemPadding?: string
  hideTabs?: boolean
  nestedSticky?: boolean
  noPaddingAndHeight?: boolean
  noRightPadding?: boolean
}

const Tabs: React.FC<Props> = React.memo(
  ({
    data: _data,
    index,
    onChange,
    rightActions,
    pushSubRoutes,
    pathSlug = 'tab',
    defaultTab,
    tabListclassName,
    variant = 'default',
    sticky = false,
    tabItemPadding,
    hideTabs = false,
    nestedSticky = false,
    noRightPadding = false,
    noPaddingAndHeight = false,
    ...restProps
  }) => {
    const params = useParams<Params<string>>()
    const history = useHistory()
    const routeMatch = useRouteMatch()
    const paramTabId = params[pathSlug]
    const teamSlug = useTeamSlug()
    const { isAdminOrEmployee } = useAccessControl(teamSlug)

    const data = React.useMemo(() => _data.filter((d) => !!d), [_data])
    defaultTab = defaultTab || data[0]?.id

    const getTabPath = React.useCallback(
      (tab: string) => {
        return generatePath(routeMatch.path, { ...routeMatch.params, [pathSlug]: tab })
      },
      [Object.values(routeMatch.params).join(''), routeMatch.path],
    )

    if (index === undefined) {
      if (paramTabId) {
        const tabIndex = data.findIndex((d) => d?.id === paramTabId)
        if (tabIndex >= 0) {
          index = tabIndex
        }
      } else {
        const tabIndex = data.findIndex((d) => d?.id === defaultTab)
        if (tabIndex >= 0) {
          index = tabIndex
        } else {
          const indexOfTabWithUndefinedId = data.findIndex((d) => d?.id === undefined)
          index = indexOfTabWithUndefinedId > -1 ? indexOfTabWithUndefinedId : 0
        }
      }
    }

    const expectedTabData = Number.isInteger(index) ? data[index!] : null
    const expectedTabId = expectedTabData?.id

    if (expectedTabId !== paramTabId) {
      index === undefined
    }

    const handleChange = React.useCallback(
      (index: number) => {
        if (onChange) {
          onChange(index, data[index]?.id)
        }
        if (pushSubRoutes) {
          const newTab = data[index]?.id
          newTab && history.push(getTabPath(newTab))
        }
      },
      [data, getTabPath, history, onChange, pushSubRoutes],
    )

    useEffect(() => {
      if (pushSubRoutes && expectedTabId && expectedTabId !== paramTabId) {
        history.replace(getTabPath(expectedTabId))
      }
    }, [data, getTabPath, index, pushSubRoutes, paramTabId])

    return (
      <StyledTabs
        isAdminOrEmployee={isAdminOrEmployee || false}
        sticky={sticky}
        selectedIndex={index || 0}
        onSelect={handleChange}
        {...restProps}
      >
        {!hideTabs && (
          <StyledTabList
            variant={variant}
            nestedSticky={nestedSticky}
            noRightPadding={noRightPadding}
            noPaddingAndHeight={noPaddingAndHeight}
            className={cx('flex justify-between items-center')}
          >
            <div className={cx('flex-1 flex items-center', tabListclassName)}>
              {data.map((tab, i) => (
                <StyledTab
                  key={`${tab?.id}-label-${i}`}
                  variant={variant}
                  disabled={tab?.disabled}
                  padding={tabItemPadding}
                  data-testid={tab?.dataTestId}
                >
                  {tab?.icon && (
                    <i
                      className={`${tab.active ? 'fas' : 'far'} fa-${tab.icon} ${
                        tab?.label && 'mr-2'
                      }`}
                    />
                  )}
                  {tab?.label}
                  {tab?.active && (
                    <motion.div
                      className="absolute w-[calc(100%-16px)] bottom-0 border-b-2 border-[#8A97FF]"
                      layoutId="underline-tab"
                    />
                  )}
                </StyledTab>
              ))}
            </div>
            {rightActions && (
              <div className={cx({ 'mr-5': variant === 'default' }, 'flex items-center')}>
                {rightActions}
              </div>
            )}
          </StyledTabList>
        )}
        <div>
          {data.map((tab, i) => (
            <StyledTabPanel key={`${tab?.id}-label-${i}`}>
              {index == i &&
                (typeof tab?.component === 'function' ? tab?.component.call(this) : tab?.component)}
            </StyledTabPanel>
          ))}
        </div>
      </StyledTabs>
    )
  },
  isEqual,
)

export default Tabs
