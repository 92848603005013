import React from 'react'

import { useHistory } from 'react-router-dom'

import { CompanyListBlueprint, CompanyListType } from 'utils/types'

import EditListDetails from './Details'

export interface EditListProps {
  teamSlug: string
  listUuid?: string
  onHide: () => void
  reload?: () => void
  listType?: CompanyListBlueprint['list_type']
  listItems?: string[]
  listCategory?: string
  dynamic?: boolean
  provider?: CompanyListBlueprint['provider']
  listName?: string
  listDescription?: string
  listTags?: string[]
  listIsTalent?: boolean
  currentStep?: number
  ctaType?: CompanyListBlueprint['cta_type']
  enableGatekeeper?: boolean
  gatekeeper_id?: number
  dynamicGatekeeper?: boolean
  isPortfolioList?: boolean
  /**
   * @deprecated Only for backward compatibility, do not use this - only pass the uuid
   */
  companyList?: CompanyListType
  postCrmInstallReturnTo?: string
  onSubmit?: () => void
  onDelete?: () => void
}

type ListType = Pick<
  CompanyListBlueprint,
  | 'name'
  | 'company_list_filters'
  | 'user'
  | 'list_type'
  | 'dynamic'
  | 'list_subscriptions'
  | 'filters'
  | 'email_template_uuid'
  | 'slug'
  | 'provider'
  | 'icps'
  | 'display_mode'
  | 'webhook_url'
  | 'tags'
  | 'description'
  | 'is_talent'
  | 'cta_type'
  | 'enable_gatekeeper'
  | 'gatekeeper_id'
  | 'dynamic_gatekeeper'
  | 'resources_approval_required'
  | 'resources_facilitator_uuid'
  | 'sales_approval_required'
  | 'sales_facilitator_uuid'
  | 'talent_approval_required'
  | 'talent_facilitator_uuid'
>

export type ExistingListType = ListType & {
  uuid: string
  company_list_items: NonNullable<CompanyListBlueprint['company_list_items']>
  list_category: string
}

type NewListType = ListType & {
  company_list_items: string[]
  list_category: string
}

export type EditListType = ExistingListType | NewListType

const EditListModal: React.FC<EditListProps> = ({
  teamSlug,
  listUuid: _listUuid,
  onHide,
  reload,
  dynamic,
  provider,
  listType,
  listItems,
  onSubmit,
  onDelete,
  postCrmInstallReturnTo,
  listName,
  listDescription,
  listTags,
  listIsTalent,
  ctaType,
  enableGatekeeper,
  gatekeeper_id,
  dynamicGatekeeper,
  isPortfolioList,
  listCategory,
  currentStep,
}) => {
  const history = useHistory()

  return (
    <EditListDetails
      dynamic={dynamic}
      provider={provider}
      teamSlug={teamSlug}
      resolve={onHide}
      listUuid={_listUuid}
      reload={reload}
      onDelete={onDelete}
      listType={listType}
      listItems={listItems}
      listCategory={listCategory}
      postCrmInstallReturnTo={postCrmInstallReturnTo}
      defaultListName={listName}
      defaultListDescription={listDescription}
      defaultListTags={listTags}
      defaultIsTalent={listIsTalent}
      onSubmit={(list) => {
        onSubmit ? onSubmit() : history.push(`/${teamSlug}/lists/${list.slug}`)
      }}
      ctaType={ctaType}
      enableGatekeeper={enableGatekeeper}
      gatekeeper_id={gatekeeper_id}
      dynamicGatekeeper={dynamicGatekeeper}
      isPortfolioList={isPortfolioList}
      currentStep={currentStep}
    />
  )
}

export { EditListModal }
