import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import cx from 'classnames'
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { cloneDeep, isEqual, uniq } from 'lodash'
import { useSearchParam } from 'react-use'
import { History } from 'history'

import api, { callApi } from 'utils/api'
import {
  Container,
  DocumentViewport,
  EditorContainer,
  FieldsPicker,
  Footer,
  Header,
} from './styles'
import Typography from 'global/Typography'
import CabalButton from 'global/CabalButton'
import {
  DroppableField,
  defaultStandardFields,
  defaultDocReplacementFields,
} from 'utils/constants/agreement'
import Pill from 'global/Pill'
import Loading from 'global/Loading'
import DroppedFields, { DroppedFieldsRef } from './DroppedFields'
import {
  AgreementTemplateField,
  AgreementTemplateModel,
  CompanySlugParam,
  GetAgreementTemplateFieldsResponse,
  GetAgreementTemplateResponse,
} from 'utils/types'
import { ModalContext, ShowModal } from 'global/Modal/Context'
import CustomAgreementFieldModal from './CustomAgreementFieldModal'
import { MultiSelect } from 'global/Input'
import PreviewAgreementModal from 'components/PreviewAgreementModal'
import Tooltip from 'global/Tooltip'
import AgreementTemplateSettingsModal from './AgreementTemplateSettingsModal'
import memoizeOne from 'memoize-one'
import CabalTitle from 'global/Title'
import { cabalToast } from 'ui-components/Toast'

interface DroppingFieldRef {
  setField: (field?: DroppableField) => void
  setP: (x: number, y: number) => void
  field?: DroppableField
}

const DroppingField = forwardRef<DroppingFieldRef>((_, ref) => {
  const [field, setField] = useState<DroppableField>()
  const [x, setX] = useState(0)
  const [y, setY] = useState(0)

  useImperativeHandle(
    ref,
    () => ({
      field,
      setField,
      setP: (x, y) => {
        setX(x)
        setY(y)
      },
    }),
    [field, setX, setY, setField],
  )

  if (!field) return <></>
  return (
    <div
      className="absolute select-none cursor-move z-10"
      style={{
        top: y - 5,
        left: x - 20,
      }}
    >
      <Pill variant="purple" className="mr-2 mb-1.5 field-pill" rounded fontSize="14">
        {field.name}
      </Pill>
    </div>
  )
})

interface PrepareDocumentClassProps {
  showModal: ShowModal
  history: History
  uuid: string
  teamSlug: string
  editDefault: boolean
  agreementTemplate?: AgreementTemplateModel
  customAgreementTemplateFields?: AgreementTemplateField[]
  updateAgreementTemplate: (at: AgreementTemplateModel) => Promise<GetAgreementTemplateResponse>
  updatingAgreementTemplate: boolean
  deleteCustomField: UseMutateFunction<GetAgreementTemplateFieldsResponse, unknown, string>
  refetchCustomAgreementTemplateFields: () => Promise<unknown>
  agreementPdf?: {
    pdf: pdfjs.PDFDocumentProxy
    data: Uint8Array
  }
}

interface PrepareDocumentClassState {
  fieldBeingDropped?: DroppableField
  editingCustomFields: boolean
  render: number
  agreementTemplate?: AgreementTemplateModel
}

class PrepareDocumentClass extends React.Component<
  PrepareDocumentClassProps,
  PrepareDocumentClassState
> {
  documentViewport: HTMLDivElement | null = null
  fieldPicker: HTMLDivElement | null = null
  fieldBeingDropped: DroppingFieldRef | null = null
  droppedFields: DroppedFieldsRef | null = null

  constructor(props: PrepareDocumentClassProps) {
    super(props)

    this.state = {
      editingCustomFields: false,
      render: 0,
    }
  }

  /**
   * translates client X, Y DOM position to X, Y wrt to PDF
   */
  clientCoordinateToPdfCoordinate = (clientX: number, clientY: number) => {
    if (!this.documentViewport) return

    const { scrollTop } = this.documentViewport
    const { x: X, y: Y, width, height } = this.documentViewport.getBoundingClientRect()

    if (clientX > X && clientX < X + width && clientY > Y && clientY < Y + height) {
      const y = clientY + scrollTop
      return { x: clientX - X, y: y - Y }
    }

    return null
  }

  /**
   * start: dropping logic
   */

  onFieldMouseDownHandler = (e: React.MouseEvent<HTMLDivElement>, f: DroppableField) => {
    this.fieldBeingDropped?.setP(e.clientX, e.clientY)
    this.fieldBeingDropped?.setField(f)

    this.setState({ fieldBeingDropped: f })
  }

  onFieldMouseMoveHandler = (e: MouseEvent) => {
    if (!this.fieldBeingDropped?.field) return

    this.fieldBeingDropped?.setP(e.clientX, e.clientY)
  }

  onFieldMouseUpHandler = (e: MouseEvent) => {
    if (!this.fieldBeingDropped?.field || !this.droppedFields) return

    const pdfCoordinates = this.clientCoordinateToPdfCoordinate(e.clientX, e.clientY)
    if (pdfCoordinates) {
      this.droppedFields.addField(
        this.fieldBeingDropped.field,
        pdfCoordinates.x - 20,
        pdfCoordinates.y - 5,
      )
    }

    this.fieldBeingDropped?.setField(undefined)
    this.setState({ fieldBeingDropped: undefined })
  }

  /**
   * end: dropping logic
   */

  openAddCustomFieldModal = () => {
    const { showModal, teamSlug, refetchCustomAgreementTemplateFields } = this.props

    showModal(
      (resolve) => (
        <CustomAgreementFieldModal
          companySlug={teamSlug}
          resolve={resolve}
          fetchFields={refetchCustomAgreementTemplateFields}
        />
      ),
      'CustomAgreementFieldModal',
    )
  }

  openSettingsModal = () => {
    const { showModal, teamSlug, updateAgreementTemplate } = this.props
    const { agreementTemplate } = this.state

    if (!agreementTemplate) return

    showModal(
      (resolve) => (
        <AgreementTemplateSettingsModal
          companySlug={teamSlug}
          resolve={resolve}
          save={() => updateAgreementTemplate(this.state.agreementTemplate!)}
          setSetting={(k, v) => {
            this.setAgreementTemplate({ [k]: v })
          }}
          agreementTemplate={agreementTemplate as AgreementTemplateModel}
        />
      ),
      'AgreementTemplateSettingsModal',
    )
  }

  saveAndPreview = async () => {
    const agreementId = (await this.props.updateAgreementTemplate(this.state.agreementTemplate!))
      .agreement_template.id
    this.props.showModal(
      (resolve) => (
        <PreviewAgreementModal
          onHide={resolve}
          url={`/api/agreement_templates/${agreementId}.pdf`}
        />
      ),
      'agreement-preview',
    )
  }

  docReplacementFieldOptions = memoizeOne(
    (customAgreementTemplateFields?: AgreementTemplateField[]) =>
      [...defaultDocReplacementFields, ...(customAgreementTemplateFields || [])].map((f) => {
        return {
          label: `${f.shortcode}`,
          value: `${f.shortcode}`,
        }
      }),
  )

  docReplacementFields = memoizeOne((agreementTemplate: AgreementTemplateModel) =>
    agreementTemplate?.fields?.filter((f) => !f.position),
  )

  cancelSaveSection = () => {
    const { history, teamSlug, updateAgreementTemplate, updatingAgreementTemplate } = this.props

    return (
      <>
        <CabalButton
          onClick={this.openSettingsModal}
          variant="tertiary"
          leftIcon={<i className="fas fa-cog" />}
        />
        <CabalButton
          onClick={() => history.push(`/${teamSlug}/admin`)}
          variant="secondary"
          className="ml-3"
        >
          Cancel
        </CabalButton>
        <CabalButton
          variant="primary"
          className="ml-3"
          onClick={async () => {
            await updateAgreementTemplate(this.state.agreementTemplate!)
            history.push(`/${teamSlug}/admin`)
          }}
          working={updatingAgreementTemplate}
        >
          Save
        </CabalButton>
      </>
    )
  }

  pdfDocument = memoizeOne((agreementPdf?: { pdf: pdfjs.PDFDocumentProxy; data: Uint8Array }) =>
    agreementPdf ? (
      <Document
        file={{
          data: agreementPdf.data,
        }}
      >
        {Array.from(
          {
            length: agreementPdf.pdf.numPages,
          },
          (_, k) => k,
        )
          .map((x, i) => i + 1)
          .map((page) => (
            <div key={`page${page}`}>
              <Page
                onLoadSuccess={() =>
                  page === 1 &&
                  setTimeout(() => this.setState((p) => ({ render: p.render + 1 })), 1000)
                }
                renderTextLayer={false}
                renderAnnotationLayer={false}
                error={<></>}
                width={this.documentViewport?.getBoundingClientRect().width || 0}
                pageNumber={page}
              />
              <hr className="absolute border-light dark:border-dark w-full z-10" />
            </div>
          ))}
      </Document>
    ) : (
      <Loading className="mt-6" />
    ),
  )

  setAgreementTemplate = (at: Partial<AgreementTemplateModel>) => {
    const updatedAgreementTemplate = cloneDeep(this.state.agreementTemplate || {})

    this.setState({
      agreementTemplate: {
        ...updatedAgreementTemplate,
        ...at,
      } as AgreementTemplateModel,
    })
  }

  setAgreementTemplateState = () => {
    const { agreementTemplate } = this.props
    if (!agreementTemplate) return

    const unpositionedFields = agreementTemplate.fields.filter((f) => !f.position)
    this.droppedFields?.setFields(cloneDeep(agreementTemplate.fields))
    this.setAgreementTemplate({
      ...agreementTemplate,
      fields: [
        ...unpositionedFields,
        ...(this.droppedFields?.fields.filter((f) => !!f.position) || []),
      ],
    })
  }

  componentDidMount = () => {
    document.addEventListener('mouseup', this.onFieldMouseUpHandler)
    document.addEventListener('mousemove', this.onFieldMouseMoveHandler)

    this.setAgreementTemplateState()
  }

  componentDidUpdate = (prevProps: PrepareDocumentClassProps) => {
    if (
      prevProps.agreementTemplate !== this.props.agreementTemplate &&
      !isEqual(prevProps.agreementTemplate, this.props.agreementTemplate)
    ) {
      this.setAgreementTemplateState()
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('mouseup', this.onFieldMouseUpHandler)
    document.removeEventListener('mousemove', this.onFieldMouseMoveHandler)
  }

  render() {
    const {
      props: { editDefault, customAgreementTemplateFields = [], deleteCustomField },
      state: { editingCustomFields, agreementTemplate },
      fieldBeingDropped,
      docReplacementFields,
      docReplacementFieldOptions,
    } = this

    return (
      <Container>
        <Header>
          <div>
            {editDefault && (
              <Tooltip label="visible to super users only">
                <Typography color="red">
                  CAUTION: You are editing a default template, the changes will propagate to all
                  users
                </Typography>
              </Tooltip>
            )}
            <Typography fontSize="18" fontWeight={500} component="div">
              Edit Agreement
            </Typography>
            <Typography color="fog_rain" fontSize="14">
              Drag-and-drop the variables below into the corresponding fields on your agreement
              template
            </Typography>
          </div>
          <div>{this.cancelSaveSection()}</div>
        </Header>
        <EditorContainer>
          <DroppingField ref={(ref) => (this.fieldBeingDropped = ref)} />
          <FieldsPicker ref={(ref) => (this.fieldPicker = ref)}>
            <Typography fontWeight={600} fontSize="16" className="mb-2">
              Standard Fields
            </Typography>
            {defaultStandardFields.map((section) => (
              <div key={section.name} className="mt-3">
                <Typography
                  color="fog_rain"
                  textTransform="uppercase"
                  fontSize="10"
                  letterSpacing="0.05em"
                  component="div"
                  className="mb-2"
                >
                  {section.name}
                </Typography>
                {section.fields.map((f) => (
                  <button key={f.default_value}>
                    <Pill
                      active={!!this.fieldBeingDropped && isEqual(this.fieldBeingDropped, f)}
                      variant="purple"
                      className="mr-2 mb-1.5 cursor-move"
                      rounded
                      fontSize="14"
                      onMouseDown={(e) => this.onFieldMouseDownHandler(e, f)}
                    >
                      {f.name}
                    </Pill>
                  </button>
                ))}
              </div>
            ))}
            <hr className="border-t border-light dark:border-dark my-4" />
            <Typography fontWeight={600} fontSize="16" className="mb-2 flex justify-between">
              <span>Custom Fields</span>
              {!!customAgreementTemplateFields?.length && (
                <button
                  onClick={() =>
                    this.setState((p) => ({ editingCustomFields: !p.editingCustomFields }))
                  }
                >
                  <Typography fontSize="14" color={editingCustomFields ? 'primary' : 'fog'}>
                    <i className={cx('fa-pencil', editingCustomFields ? 'fas' : 'far')} />
                  </Typography>
                </button>
              )}
            </Typography>
            <div>
              {customAgreementTemplateFields?.map((f) => (
                <div key={f.uuid} className="inline-block cursor-pointer">
                  <Tooltip
                    label={
                      <span>
                        type: {f.data_type} <br /> for: {f.for}
                      </span>
                    }
                  >
                    <Pill
                      active={!!fieldBeingDropped && isEqual(fieldBeingDropped, f)}
                      variant="purple"
                      className="mr-1 mb-1.5 cursor-move"
                      rounded
                      fontSize="14"
                      onMouseDown={(e) => this.onFieldMouseDownHandler(e, f)}
                    >
                      <span>{f.name}</span>
                    </Pill>
                  </Tooltip>
                  {editingCustomFields && (
                    <button
                      onClick={() =>
                        confirm(`Are you sure you want to delete "${f.name}"`) &&
                        deleteCustomField(f.uuid)
                      }
                      className="mr-2"
                    >
                      <Typography fontSize="10" color="secondary">
                        <i className="fas fa-trash" />
                      </Typography>
                    </button>
                  )}
                </div>
              ))}
            </div>
            <CabalButton
              leftIcon={<i className="far fa-plus" />}
              variant="secondary"
              className="mt-3"
              onClick={this.openAddCustomFieldModal}
            >
              New Custom Field
            </CabalButton>
            {agreementTemplate?.is_docx && (
              <div className="select-auto">
                <hr className="border-t border-light dark:border-dark my-4" />
                <div className="flex">
                  <Typography
                    fontWeight={600}
                    fontSize="16"
                    className="mb-2 flex justify-between flex-1"
                  >
                    <span>Document Replacement Fields</span>
                  </Typography>
                  <div>
                    <Tooltip
                      label={
                        <>
                          These fields will be replaced inline(will have the same font) in the DOCX
                          as opposed to overlaying over the document
                        </>
                      }
                    >
                      <i className="far fa-question-circle" />
                    </Tooltip>
                  </div>
                </div>
                <MultiSelect<string>
                  isClearable={false}
                  value={docReplacementFields(agreementTemplate)?.map((f) => f.shortcode!)}
                  options={docReplacementFieldOptions(customAgreementTemplateFields)}
                  portal
                  onChange={(v = []) => {
                    const positionedFields = agreementTemplate.fields!.filter((f) => !!f.position)
                    this.setAgreementTemplate({
                      fields: [
                        ...positionedFields,
                        ...v.map((dv) => {
                          return {
                            ...([
                              ...customAgreementTemplateFields,
                              ...defaultDocReplacementFields,
                            ].find((f) => f.shortcode === dv) || {}),
                          } as AgreementTemplateField
                        }),
                      ],
                    })
                  }}
                />
                <Typography component="div" className="mt-4">
                  Available fields:
                </Typography>
                <Typography fontSize="12">
                  {uniq(agreementTemplate.fields?.map((f) => `${f.shortcode}`)?.sort())?.join(', ')}
                </Typography>
              </div>
            )}
          </FieldsPicker>
          <DocumentViewport ref={(ref) => (this.documentViewport = ref)}>
            {agreementTemplate?.fields && this.pdfDocument(this.props.agreementPdf)}
            <div className="absolute top-0 left-0">
              <DroppedFields
                ref={(ref) => (this.droppedFields = ref)}
                onChange={(fields) => this.setAgreementTemplate({ fields })}
                documentViewport={this.documentViewport}
              />
            </div>
          </DocumentViewport>
        </EditorContainer>
        <Footer>
          <div>
            <CabalButton variant="secondary" onClick={this.saveAndPreview}>
              Preview
            </CabalButton>
          </div>
          <div>{this.cancelSaveSection()}</div>
        </Footer>
      </Container>
    )
  }
}

const PrepareDocument: React.VFC = () => {
  const { showModal } = useContext(ModalContext)

  const history = useHistory()
  const { id, company_slug }: { id: string } & CompanySlugParam = useParams()
  const editDefault = !!useSearchParam('edit_default')
  const queryClient = useQueryClient()
  const ref = useRef<PrepareDocumentClass>(null)

  const { data: getAgreementTemplateData } = useQuery(
    ['getAgreementTemplate', id],
    () => callApi(api.getAgreementTemplate, id),
    {
      onSuccess: () => {
        if (!agreementPdf) getAgreementPdf()
      },
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  )
  const agreementTemplate = getAgreementTemplateData?.agreement_template

  const { data: customAgreementTemplateFieldsData, refetch: refetchCustomAgreementTemplateFields } =
    useQuery(['getAgreementTemplateFields', id], () =>
      callApi(api.getCustomAgreementTemplateFields, company_slug),
    )

  const { data: agreementPdf, mutate: getAgreementPdf } = useMutation(
    async () => {
      const pdf = await pdfjs.getDocument(`/api/agreement_templates/${id}.pdf?raw=1`).promise
      const data = await pdf.getData()
      setTimeout(() => ref.current?.setState((p) => ({ render: p.render + 1 })), 100)

      return { pdf, data }
    },
    {
      mutationKey: [id, 'agreement_template_pdf'],
    },
  )

  const { mutateAsync: mutateUpdateAgreementTemplate, isLoading: updatingAgreementTemplate } =
    useMutation(
      (agreementTemplate: AgreementTemplateModel) =>
        callApi(api.updateAgreementTemplate, id, agreementTemplate, editDefault),
      {
        onSuccess: (data) => {
          cabalToast({ style: 'success', content: 'Successfully updated the agreement template!' })
          queryClient.setQueryData(['getAgreementTemplate', id], data)

          if (data.agreement_template.id !== id) {
            history.replace(`/${company_slug}/prepare/${data.agreement_template.id}`)
          }
        },
      },
    )

  const { mutate: deleteCustomField } = useMutation(
    (uuid: string) => callApi(api.deleteCustomAgreementTemplateField, company_slug, uuid),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['getAgreementTemplateFields', id], data)
        cabalToast({ style: 'success', content: 'Successfully deleted the field!' })
      },
    },
  )

  const updateAgreementTemplate = async (agreementTemplate: AgreementTemplateModel) => {
    return await mutateUpdateAgreementTemplate(agreementTemplate)
  }

  return (
    <>
      <CabalTitle title="Edit Agreement Template" />
      <PrepareDocumentClass
        ref={ref}
        {...{
          showModal,
          uuid: id,
          teamSlug: company_slug,
          editDefault,
          history,
          deleteCustomField,
          updateAgreementTemplate,
          updatingAgreementTemplate,
          customAgreementTemplateFields: customAgreementTemplateFieldsData?.fields,
          refetchCustomAgreementTemplateFields,
          agreementPdf,
          agreementTemplate,
        }}
      />
    </>
  )
}

export default PrepareDocument
