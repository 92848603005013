import axios from 'axios'

export default {
  createOrDeleteAdvisorGroup: (params: {
    advisor_id: string
    group_id: string
    destroy?: boolean
    team_slug: string
  }) => {
    return axios.post('/api/advisor_groups', params)
  },

  bulkAddAdvisorGroups: (team_slug: string, group_uuids: string[], advisor_uuids: string[]) => {
    return axios.post('/api/advisor_groups/bulk_add', {
      team_slug,
      advisor_uuids,
      group_uuids,
    })
  },

  bulkRemoveAdvisorGroups: (team_slug: string, group_uuids: string[], advisor_uuids: string[]) => {
    return axios.post('/api/advisor_groups/bulk_remove', {
      team_slug,
      advisor_uuids,
      group_uuids,
    })
  },

  getAdvisorGroups: (advisor_uuid: string, team_slug: string) => {
    return axios.get(`/api/advisor_groups`, {
      params: {
        advisor_id: advisor_uuid,
        team_slug,
      },
    })
  },

  getConnectionsAdvisorGroups: (team_slug: string) => {
    return axios.get(`/api/advisor_groups/for_connections`, {
      params: {
        team_slug,
      },
    })
  },
}
