import React from 'react'

import truncate from 'lodash/truncate'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { OPPORTUNITY_TYPE_MAP } from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import Pill from 'global/Pill'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'

interface CandidateWidgetProps {
  candidateUuid: string
  teamSlug: string
}

const CandidateWidget: React.FC<CandidateWidgetProps> = ({ candidateUuid, teamSlug }) => {
  const profileQuery = useQuery(
    ['profile', candidateUuid],
    () => callApi(api.fetchProfile, candidateUuid, true, teamSlug),
    {
      enabled: !!candidateUuid,
    },
  )

  const history = useHistory()

  const candidate = profileQuery.data?.candidate_profile

  if (profileQuery.isLoading || !candidate) return null

  return (
    <Widget
      title="Talent Profile"
      cta={
        <CabalButton
          variant="link"
          onClick={() => {
            history.push(`/${teamSlug}/candidates/${candidateUuid}`)
          }}
        >
          View
        </CabalButton>
      }
    >
      <div className="space-y-4">
        <div>
          <Typography color="fog" fontSize={'12'} fontWeight={600}>
            About
          </Typography>
          <div>
            <Typography fontSize="14" className="py-2 pr-2" color="fog">
              {truncate(candidate.bio || '', { length: 120, omission: '...' })}
            </Typography>
          </div>
        </div>
        <div>
          <Typography color="fog" fontSize={'12'} fontWeight={600}>
            Looking For
          </Typography>
          <div className="py-2 pr-2">
            {candidate.opportunity_type?.map((func, i) => (
              <Pill variant="gray" rounded key={i}>
                {OPPORTUNITY_TYPE_MAP[func.toLowerCase() as keyof typeof OPPORTUNITY_TYPE_MAP]}
              </Pill>
            )) || 'N/A'}
          </div>
        </div>
        <div>
          <Typography color="fog" fontSize={'12'} fontWeight={600}>
            Location
          </Typography>
          <div>
            <Pill variant="gray" rounded>
              {candidate.location || 'Not specified'}
            </Pill>
          </div>
        </div>
      </div>
    </Widget>
  )
}

export default CandidateWidget
