import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { useCurrentUser } from 'store/hooks'

import api from 'utils/api'
import { isExtension } from 'utils/env'
import { CompanySlugParam } from 'utils/types'

const useAppSlug = (suppliedSlug?: string | null) => {
  const params = useParams<CompanySlugParam & { team_slug?: string }>()
  const paramSlug = params.team_slug || params.company_slug
  const slug = (suppliedSlug === undefined ? paramSlug : suppliedSlug) || undefined

  const { isLoading, data } = useQuery(
    ['getSlugAttachable', slug],
    async () => {
      try {
        const resp = await api.getSlugAttachable(slug!)

        return resp.data
      } catch (err) {
        return { type: undefined }
      }
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!slug,
    },
  )

  const type = data?.type

  return {
    isLoading: isLoading,
    slugType: type,
    teamSlug: type === 'Team' ? slug : undefined,
    userSlug: type === 'User' ? slug : undefined,
  }
}

const useExtSlug: typeof useAppSlug = () => {
  const { user, isLoading } = useCurrentUser()

  return {
    isLoading,
    slugType: 'Team',
    teamSlug: user.team?.slug,
    userSlug: undefined,
  }
}

export const useSlug = isExtension() ? useExtSlug : useAppSlug
