import React from 'react'

import { StyledTypography } from 'components/Winsite/styles'
import Typography from 'global/Typography'

import useColorMode from 'utils/hooks/useColorMode'

interface IProps {
  handleSave: () => void
  editClicked: boolean
}

export function CompanyBlurbReferral({ handleSave, editClicked }: IProps) {
  const { isMobile } = useColorMode()
  return (
    <div className="flex flex-row gap-x-1 items-center justify-between">
      <div>
        <Typography
          textAlign="left"
          fontSize={isMobile ? '10' : '12'}
          fontWeight={400}
          style={{ color: '#718096' }}
        >
          Referral Blurb <i className="fa-light fa-wand-magic-sparkles" />
        </Typography>
      </div>
      {/* Update team description, see our existing team mutations */}
      <StyledTypography isMobile={isMobile} onClick={() => handleSave()}>
        {editClicked ? 'Save' : 'Edit'}
      </StyledTypography>
    </div>
  )
}
