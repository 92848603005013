import React from 'react'
import { useParams } from 'react-router-dom'
import { CompanySlugParam } from 'utils/types'
import axios from 'axios'
import Loading from 'global/Loading'
import styled from 'styled-components'
import { debounce } from 'lodash'
import Modal from 'global/Modal'
import { TextInput } from 'global/Input'
import CabalButton from 'global/CabalButton'

interface Props {
  documentVisitUuid: any
  setDocumentVisitUuid: any
  document: any
}

const EmailCapture = (props: Props) => {
  const [email, setEmail] = React.useState('')

  const { documentVisitUuid, setDocumentVisitUuid, document } = props

  const { id, company_slug: companySlug } = useParams<{ id: string } & CompanySlugParam>()

  function submitEmail() {
    if (email == '' || email.split('@').length != 2) {
      return alert('Please enter valid email')
    }

    const params = { document_id: id, team: companySlug, email }

    axios.post(`/api/document_visits`, params).then((response) => {
      const { uuid } = response.data
      setDocumentVisitUuid(uuid)
    })
  }

  return (
    <>
      <Modal show={documentVisitUuid == null} onHide={() => {}} canClose={false}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            submitEmail()
          }}
          className="p-8"
        >
          <b className="text-lg">{document.sender_first_name} shared this document with you</b>
          <div className="mb-8 mt-8">
            Please enter your information to continue. <br />
            This information will only be shared with {document.sender_first_name}.
          </div>
          <div>
            <TextInput
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="mt-8">
            <CabalButton variant="primary" className="w-full">
              Continue
            </CabalButton>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default EmailCapture
