import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { useSearchParam } from 'react-use'

import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import Tabs from 'global/Tabs'
import { SearchInput } from 'global/Input'
import EmptyState from 'global/EmptyState'
import Typography from 'global/Typography'
import api, { callApi } from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import { FakeChangeEvent } from 'utils/types'
import ContactsEmptyState from 'components/EmptyStates/ContactsEmptyState'
import ContactsTable from './ContactsTable'
import CabalTitle from 'global/Title'

const Contacts = () => {
  const searchQueryString = useSearchParam('q')
  const [query, setQuery] = useState(searchQueryString || '')
  const debouncedQuery = useDebouncedValue(query, 300)

  const handleChangeQuery = (e: React.ChangeEvent<HTMLInputElement> | FakeChangeEvent) => {
    setQuery(e.target.value)
  }

  const [tabIndex, setTabIndex] = useState(0)
  const tabs = [
    {
      label: 'Contacts',
      id: 'contacts',
      component: '',
    },
  ]
  const tabId = tabs[tabIndex]?.id

  const contactsQuery = useInfiniteQuery(
    ['contacts'],
    ({ pageParam: page = 1 }) => callApi(api.getContacts, page),
    {
      enabled: tabId == 'contacts',
      cacheTime: 0,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
    },
  )

  const contacts = contactsQuery.data?.pages?.flatMap((page) => page.contacts)

  return (
    <div className="mx-8 mt-8 m-6">
      <CabalTitle title="Contacts" />
      <Tabs data={tabs} index={tabIndex} onChange={(index) => setTabIndex(index)} />

      <div className="mt-6 flex flex-col h-full">
        {tabId == 'contacts' && (
          <>
            {contactsQuery.isLoading ? (
              <Loading />
            ) : contacts && contacts.length === 0 ? (
              <ContactsEmptyState />
            ) : contacts && contacts.length > 0 ? (
              <>
                <ContactsTable contacts={contacts} />

                {contactsQuery.hasNextPage && (
                  <div className="flex justify-center	mt-6">
                    <CabalButton
                      disabled={contactsQuery.isFetching}
                      onClick={() => contactsQuery.fetchNextPage()}
                    >
                      Load more
                    </CabalButton>
                  </div>
                )}
              </>
            ) : null}
          </>
        )}
      </div>
    </div>
  )
}

export default Contacts
