import React, { useState } from 'react'

import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'

import CabalButton from 'global/CabalButton'
import { Select, TextArea, TextInput } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { Team } from 'utils/types'

interface Props {
  teamSlug: string
}

const WelcomeMessage: React.FC<Props> = ({ teamSlug }) => {
  const { team, reloadTeam } = useTeam(teamSlug)
  const [asksContext, setAsksContext] = useState(
    team?.sales_asks_prompt ||
      `Welcome to our Cabal page. Here we’ll share updates and ways to help us win. \n\nTo start, share your connections then click “Offer intro” for contacts you’re willing to introduce. When you offer an intro, we will follow up with custom referral emails for your approval.`,
  )

  const [salesAskPromptUserId, setSalesAskPromptUserId] = useState(
    team?.sales_ask_prompt_user_id || team?.founder.id,
  )

  const updateTeamMutation = useMutation(
    ['updateTeam'],
    (val: Partial<Team>) => callApi(api.updateTeam, teamSlug, { team: val }),
    {
      onSuccess: () => {
        toast.success('Welcome message updated')
        reloadTeam()
      },
    },
  )

  const teamAdminOptions =
    team?.admins_and_members
      .filter((u) => u.team_role === 'admin')
      .map((u) => ({ value: u.uuid, label: u.name })) || []

  return (
    <div>
      <ModalSectionWrapper>
        <Typography fontSize="14">
          Welcome Message appears the first time Members visit your Company Page
        </Typography>
      </ModalSectionWrapper>
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>From</ModalInputLabel>

          <Select
            value={salesAskPromptUserId}
            className="w-full"
            onChange={(option) => option && setSalesAskPromptUserId(option)}
            placeholder="Select user"
            options={teamAdminOptions}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Message</ModalInputLabel>

          <TextArea
            className="w-full"
            value={asksContext}
            onChange={(e) => setAsksContext(e.target.value)}
            placeholder="Write a message..."
            rows={8}
            maxLength={280}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>

      <div className="my-4 flex justify-end">
        <CabalButton
          working={updateTeamMutation.isLoading}
          onClick={() =>
            updateTeamMutation.mutateAsync({
              sales_asks_prompt: asksContext,
              sales_ask_prompt_user_id: salesAskPromptUserId,
              sales_asks_prompt_changed_at: new Date(),
            })
          }
        >
          Save
        </CabalButton>
      </div>
    </div>
  )
}

export default WelcomeMessage
