export default {
  v: '5.12.2',
  fr: 23.9759979248047,
  ip: 0,
  op: 55.9999951530306,
  w: 340,
  h: 340,
  nm: 'LOADER_CABAL_v03',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'MOON_DRAW_ON_CYCLE',
      fr: 23.9759979248047,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 2',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 57, ix: 10 },
            p: { a: 0, k: [985, 518, 0], ix: 2, l: 2 },
            a: { a: 0, k: [4.5, 13, 0], ix: 1, l: 2 },
            s: { a: 0, k: [64, 64, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [267, 267], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.072982167263, 0.644596054975, 0.809150247013, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 266, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-2.5, 1.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.498], y: [0.797] }, o: { x: [0.845], y: [0] }, t: 34, s: [21] },
                  { i: { x: [0.546], y: [1] }, o: { x: [0.229], y: [0.929] }, t: 52, s: [92.839] },
                  { t: 69.9999939412883, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 33.9999970571972,
          op: 393.999965898108,
          st: 33.9999970571972,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'MOON_ON',
          tt: 2,
          tp: 1,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [960, 540, 0], ix: 2, l: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              nm: 'Fill',
              np: 9,
              mn: 'ADBE Fill',
              ix: 1,
              en: 1,
              ef: [
                { ty: 10, nm: 'Fill Mask', mn: 'ADBE Fill-0001', ix: 1, v: { a: 0, k: 0, ix: 1 } },
                { ty: 7, nm: 'All Masks', mn: 'ADBE Fill-0007', ix: 2, v: { a: 0, k: 0, ix: 2 } },
                {
                  ty: 2,
                  nm: 'Color',
                  mn: 'ADBE Fill-0002',
                  ix: 3,
                  v: { a: 0, k: [0.392156898975, 0.443137288094, 0.850980460644, 1], ix: 3 },
                },
                { ty: 7, nm: 'Invert', mn: 'ADBE Fill-0006', ix: 4, v: { a: 0, k: 0, ix: 4 } },
                {
                  ty: 0,
                  nm: 'Horizontal Feather',
                  mn: 'ADBE Fill-0003',
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 },
                },
                {
                  ty: 0,
                  nm: 'Vertical Feather',
                  mn: 'ADBE Fill-0004',
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                { ty: 0, nm: 'Opacity', mn: 'ADBE Fill-0005', ix: 7, v: { a: 0, k: 1, ix: 7 } },
              ],
            },
          ],
          w: 1920,
          h: 1080,
          ip: 0,
          op: 357.999969014017,
          st: -1.99999982689395,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      nm: 'MOON_ON',
      fr: 23.9759979248047,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 1',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 57, ix: 10 },
            p: { a: 0, k: [986, 518, 0], ix: 2, l: 2 },
            a: { a: 0, k: [4.5, 13, 0], ix: 1, l: 2 },
            s: { a: 0, k: [64, 64, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [267, 267], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.072982167263, 0.644596054975, 0.809150247013, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 266, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-2.5, 1.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.498], y: [0.797] }, o: { x: [0.845], y: [0] }, t: 0, s: [21] },
                  { i: { x: [0.546], y: [1] }, o: { x: [0.229], y: [0.929] }, t: 18, s: [92.839] },
                  { t: 35.9999968840911, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 359.999968840911,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'MOON_MASK',
          tt: 1,
          tp: 1,
          refId: 'comp_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [960, 540, 0], ix: 2, l: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 1920,
          h: 1080,
          ip: 0,
          op: 359.999968840911,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      nm: 'MOON_MASK',
      fr: 23.9759979248047,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'MOON',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [960, 510, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.91, -0.92],
                        [-0.561, -0.585],
                        [0, 0],
                        [0.056, 0.035],
                        [22.344, 1.02],
                        [1.986, 0],
                        [0, -70.887],
                        [-70.719, 0],
                        [-1.963, 0.09],
                        [-18.877, 12.745],
                        [-0.128, 0.087],
                        [0, 0],
                        [0.389, -0.423],
                        [3.798, -3.44],
                        [3.287, -2.56],
                        [16.021, -5.995],
                        [20.619, 0],
                        [0, 92.784],
                        [-92.565, 0],
                        [-18.245, -6.828],
                        [-12.141, -8.842],
                        [-5.589, -5.396],
                      ],
                      o: [
                        [0.57, 0.576],
                        [0, 0],
                        [-0.056, -0.034],
                        [-17.925, -11.063],
                        [-1.964, -0.09],
                        [-70.719, 0],
                        [0, 70.887],
                        [1.986, 0],
                        [24.199, -1.104],
                        [0.128, -0.087],
                        [0, 0],
                        [-0.385, 0.427],
                        [-3.471, 3.772],
                        [-3.085, 2.793],
                        [-13.269, 10.337],
                        [-18.245, 6.828],
                        [-92.565, 0],
                        [0, -92.784],
                        [20.619, 0],
                        [14.295, 5.349],
                        [6.292, 4.584],
                        [0.931, 0.899],
                      ],
                      v: [
                        [140.629, -118.279],
                        [142.326, -116.538],
                        [135.075, -109.27],
                        [134.907, -109.374],
                        [73.787, -128.217],
                        [67.862, -128.352],
                        [-60.187, 0],
                        [67.862, 128.352],
                        [73.786, 128.217],
                        [139.309, 106.531],
                        [139.694, 106.27],
                        [146, 112.591],
                        [144.839, 113.866],
                        [133.929, 124.691],
                        [124.369, 132.725],
                        [80.218, 157.44],
                        [21.604, 168],
                        [-146, 0],
                        [21.604, -168],
                        [80.218, -157.44],
                        [120.024, -135.999],
                        [137.867, -121.008],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 359.999968840911,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'MOON_GAP',
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [146, 170, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [4.5, -103.5],
                    [0, 0],
                    [0.688, 88.756],
                  ],
                  o: [
                    [0, 0],
                    [0.25, 107.75],
                    [0, 0],
                    [-0.688, -88.75],
                  ],
                  v: [
                    [-18.5, -131.5],
                    [-115.875, -2.75],
                    [-18.5, 133.5],
                    [-88.812, 0.75],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.93986936083, 0.10443053152, 0.10443053152, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 359.999968840911,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'MOON_DRAW_ON_CYCLE',
      tt: 2,
      tp: 1,
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [146, 200, 0], ix: 2, l: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 0,
      op: 359.999968840911,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
}
