import React, { useEffect, useMemo } from 'react'

import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import CabalButton from 'global/CabalButton'
import { useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'
import { UserProfile } from 'utils/types'

interface Props {
  onEditProfile?: () => void
  profile: UserProfile
  reloadProfile: () => void
}

const ProfileCTAs: React.FC<Props> = ({ profile, reloadProfile, onEditProfile }) => {
  const { isLoggedIn, user } = useCurrentUser()
  const { isMobile } = useColorMode()
  const history = useHistory()
  const routerLocation = useLocation()
  const acceptRequest = useSearchParam('accept_request')
  const { sent_connection_request, received_connection_request } = profile
  const receivedRequestPending =
    !!received_connection_request && received_connection_request.accepted == null

  const updateConnectionRequestMutation = useMutation(
    (accepted: boolean) => callApi(api.updateConnectionRequest, profile.primary_slug, accepted),
    {
      onSuccess: () => {
        reloadProfile()
        history.replace(`/${profile.primary_slug}`)
      },
    },
  )

  useEffect(() => {
    if (!!acceptRequest && receivedRequestPending) {
      updateConnectionRequestMutation.mutate(true)
    }
  }, [])

  const onConnect = () => {
    if (isLoggedIn) {
      if (!!user.primary_slug) {
        sendConnectionRequestMutation.mutate()
      } else {
        cabalToast({ content: 'Please setup your profile before connecting with others' })
        history.push(`/profile`, { from: routerLocation })
      }
    } else {
      history.push(`/login`, { from: routerLocation })
    }
  }

  const sendConnectionRequestMutation = useMutation(
    () => callApi(api.createConnectionRequest, profile.primary_slug),
    {
      onSuccess: reloadProfile,
    },
  )

  if (user.primary_slug === profile.primary_slug) {
    return (
      <CabalButton variant="link" onClick={onEditProfile}>
        Edit Profile
      </CabalButton>
    )
  }

  const button = useMemo(() => {
    const connected = sent_connection_request?.accepted || received_connection_request?.accepted

    if (connected) {
      return (
        <CabalButton variant="secondary" disabled leftIcon={<i className="far fa-check" />}>
          Connected
        </CabalButton>
      )
    }

    if (receivedRequestPending) {
      return (
        <div className="flex gap-2">
          <CabalButton
            leftIcon={<i className="far fa-check" />}
            working={
              updateConnectionRequestMutation.variables === true &&
              updateConnectionRequestMutation.isLoading
            }
            onClick={() => updateConnectionRequestMutation.mutate(true)}
          >
            Accept
          </CabalButton>
          <CabalButton
            leftIcon={<i className="far fa-times" />}
            variant="secondary"
            tooltip="Reject"
            working={
              updateConnectionRequestMutation.variables === false &&
              updateConnectionRequestMutation.isLoading
            }
            onClick={() => updateConnectionRequestMutation.mutate(false)}
          />
        </div>
      )
    }

    const sentRequestPending = !!sent_connection_request && !sent_connection_request.accepted

    if (sentRequestPending) {
      return (
        <CabalButton leftIcon={<i className="far fa-check" />} variant="secondary" disabled>
          Request Sent
        </CabalButton>
      )
    }

    if (!!profile.can_connect) {
      return (
        <CabalButton
          leftIcon={<i className="far fa-plus" />}
          working={sendConnectionRequestMutation.isLoading}
          onClick={onConnect}
          disabled={!isMobile && !isLoggedIn}
          tooltip={!isMobile && !isLoggedIn ? 'Please sign in to connect with others' : undefined}
        >
          Connect
        </CabalButton>
      )
    }

    return <></>
  }, [
    sent_connection_request,
    received_connection_request,
    sendConnectionRequestMutation,
    updateConnectionRequestMutation,
    receivedRequestPending,
  ])

  return button
}

export default ProfileCTAs
