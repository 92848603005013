import React from 'react'

import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import { useAdvisors, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanySlugParam } from 'utils/types'

const AcceptJoinRequest: React.VFC = () => {
  const { company_slug, id } = useParams<CompanySlugParam & { id: string }>()
  const { team } = useTeam(company_slug)
  const { reloadAdvisors } = useAdvisors({ teamSlug: company_slug })
  const history = useHistory()

  const getJoinRequestQuery = useQuery([company_slug, 'getJoinRequest'], () =>
    callApi(api.getJoinRequest, company_slug, id),
  )

  const acceptJoinRequestMutation = useMutation(
    () => callApi(api.acceptJoinRequest, company_slug, id),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Join request approved!' })
        reloadAdvisors()
        history.push(`/`)
      },
    },
  )

  const joinRequest = getJoinRequestQuery.data?.join_request
  const requesterUser = joinRequest?.user

  if (!team || !requesterUser) return <Loading className="pt-14" />

  return (
    <div className="flex h-full w-full justify-center items-center">
      <div className="text-center py-14">
        <Avatar round src={requesterUser.avatar_url} />
        <p className="mt-8">
          {requesterUser.name} ({requesterUser.email}) requested to join {team.name}.
        </p>
        {joinRequest.group_name && (
          <p>They will be added to &quot;{joinRequest.group_name}&quot; group.</p>
        )}
        <CabalButton
          className="mt-8"
          variant={'primary'}
          working={acceptJoinRequestMutation.isLoading}
          onClick={() => acceptJoinRequestMutation.mutate()}
        >
          Approve
        </CabalButton>
      </div>
    </div>
  )
}

export default AcceptJoinRequest
