import * as React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

interface Props {
  icon: string
  title: string
  onButtonClick: () => void
  active: boolean
}

const ActionWrapper = styled.button<{ active: boolean }>`
  ${tw`flex items-center justify-center p-2 rounded`}

  background-color: ${({ theme, active }) => active && theme.buttons.secondary.bg_color};
`

const ActionButton: React.VFC<Props> = ({ icon, title, onButtonClick, active }) => {
  return (
    <ActionWrapper onClick={onButtonClick} active={active}>
      <Typography fontSize="12" color={active ? 'purple' : 'fog'}>
        <i className={icon} />
        <span className="ml-1.5">{title}</span>
      </Typography>
    </ActionWrapper>
  )
}

export default ActionButton
