import React from 'react'

import { isEqual } from 'lodash'
import { useHistory } from 'react-router-dom'

import ShareTemplate from 'containers/MessagesPage/Templates/ShareTemplate'
import CabalButton from 'global/CabalButton'
import DropDownMenu, { MenuItem } from 'global/DropDownMenu'

import { EmailTemplate, Team } from 'utils/types'

interface Props {
  onSelectTemplate: (t: EmailTemplate) => void
  templates: EmailTemplate[]
  team: Team
  hideModal?: () => void
}

const TemplateDropdown: React.VFC<Props> = ({ onSelectTemplate, templates, team, hideModal }) => {
  const history = useHistory()

  return (
    <DropDownMenu
      trigger={
        <CabalButton
          rightIcon={<i className="fa fa-caret-down" />}
          variant="tertiary"
          component="span"
          size="small"
        >
          Templates
        </CabalButton>
      }
      menuItems={templates
        .map<MenuItem>((template) => ({
          label: template.name,
          onSelect: () => onSelectTemplate(template),
          sideAction: <ShareTemplate template={template} teamSlug={team.slug} showLabel={false} />,
        }))
        .concat([
          {
            label: (
              <div>
                <i className="fa fa-edit mr-1 text-secondary" />
                Manage Templates
              </div>
            ),
            onSelect: () => {
              window.open(`/messages/templates`, '_blank')
              hideModal?.()
            },
          },
        ])}
    />
  )
}

export default React.memo(TemplateDropdown, isEqual)
