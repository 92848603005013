import React, { useState } from 'react'
import * as ReactDOM from 'react-dom'
import * as Autosuggest from 'react-autosuggest'
import styled, { StyledComponentPropsWithRef, useTheme } from 'styled-components'
import tw from 'twin.macro'
import { transparentize } from 'polished'
import cx from 'classnames'

import { TextInput, TextInputProps } from './TextInput'
import Loading from 'global/Loading'

const SuggestionsContainer = styled.div`
  ${tw` absolute inline-block rounded-md py-2 max-h-96
    overflow-auto shadow-2xl`}
  z-index: 120;
  min-width: 30vw;
  border: ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.layout.main_bg_color};
`

interface SuggestionProps {
  isHighlighted: boolean
}

const Suggestion = styled.button<SuggestionProps>`
  ${tw`py-2 px-4 cursor-pointer block w-full text-left`}
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted && transparentize(0.7, theme.buttons.primary.bg_color)};
  font-size: 13px;
  &:hover {
    background-color: ${({ theme }) => transparentize(0.7, theme.buttons.primary.bg_color)};
  }
`

interface Option<T> {
  value?: T
  isFallback: boolean
}

interface Props<T> extends TextInputProps, StyledComponentPropsWithRef<'input'> {
  onFetchSuggestions: (v: string) => void
  renderSuggestion: (d: T) => React.ReactNode
  placeholder?: string
  labeled?: boolean
  suggestions?: T[]
  onFallbackClick?: () => void
  onClearSuggestions: () => void
  onSelected: (s: T) => void
  inputClassName?: string
  getSuggestionValue: (s: T) => string
  onInputValueChange?: (v: string) => void
  value?: string
  fallbackLabel?: (input?: string) => string
}

export function AutoSuggest<T>({
  onFetchSuggestions,
  renderSuggestion,
  suggestions,
  placeholder,
  onFallbackClick,
  onClearSuggestions,
  onSelected,
  className,
  inputClassName,
  getSuggestionValue,
  onInputValueChange: onChange,
  value,
  fallbackLabel = () => `Can't find what you are looking for? Click here.`,
  ...extraProps
}: Props<T>) {
  const [fetching, setFetching] = React.useState(false)
  const [localValue, setLocalValue] = React.useState('')
  const inputRef = React.useRef<HTMLInputElement>(null)
  const requestTimeoutRef = React.useRef<NodeJS.Timeout>()
  const theme = useTheme()

  const fetchSuggestions = React.useCallback(
    ({ value }: Autosuggest.SuggestionsFetchRequestedParams) => {
      if (!fetching) setFetching(true)
      if (requestTimeoutRef.current) clearTimeout(requestTimeoutRef.current)
      requestTimeoutRef.current = setTimeout(() => {
        onFetchSuggestions(value)
      }, 300)
    },
    [onFetchSuggestions, fetching, requestTimeoutRef.current],
  )

  const handleGetSuggestionValue = (s: Option<T>) => {
    if (s.value) return getSuggestionValue(s.value)
    return localValue
  }

  const handleRenderSuggestion: Autosuggest.RenderSuggestion<Option<T>> = (
    d,
    { isHighlighted },
  ) => {
    const fallbackText = fallbackLabel(inputRef.current?.value) || `${inputRef.current?.value}`
    return (
      <Suggestion isHighlighted={isHighlighted}>
        {d.isFallback ? fallbackText : d.value && renderSuggestion(d.value)}
      </Suggestion>
    )
  }

  const handleOnSelected: Autosuggest.OnSuggestionSelected<Option<T>> = (e, d) => {
    if (d.suggestion.value) onSelected(d.suggestion.value)
    else onFallbackClick && onFallbackClick()
  }

  React.useEffect(() => {
    setFetching(false)
  }, [suggestions])

  const autosuggestSuggestions: Option<T>[] =
    suggestions?.map<Option<T>>((e) => ({
      value: e,
      isFallback: false,
    })) || []

  if (onFallbackClick) {
    autosuggestSuggestions.push({
      isFallback: true,
    })
  }

  return (
    <Autosuggest<Option<T>, any>
      suggestions={autosuggestSuggestions}
      onSuggestionsFetchRequested={fetchSuggestions}
      onSuggestionsClearRequested={onClearSuggestions}
      getSuggestionValue={handleGetSuggestionValue}
      renderSuggestion={handleRenderSuggestion}
      onSuggestionSelected={handleOnSelected}
      containerProps={{
        className: cx('relative inline-block', className),
      }}
      renderInputComponent={(props) => <TextInput {...props} {...extraProps} />}
      renderSuggestionsContainer={({ containerProps, children, query }) => {
        const suggestionsPortalContainer = document.getElementsByClassName(
          'suggestions-portal-container',
        )[0]

        if (
          !suggestionsPortalContainer ||
          !inputRef.current?.getBoundingClientRect ||
          !query ||
          (suggestions === undefined && !fetching)
        )
          return <></>
        const inputRect = inputRef.current.getBoundingClientRect()

        if (fetching)
          return (
            <div
              className={cx(
                'flex items-center justify-center py-3 absolute',
                'right-3 top-1/2 -translate-y-1/2',
              )}
            >
              <Loading color={theme.colors.primary} size={'16px'} />
            </div>
          )

        if (!children) return <></>

        return ReactDOM.createPortal(
          <SuggestionsContainer
            {...containerProps}
            style={{
              top: inputRect.y + inputRect.height + 10,
              left: inputRect.x,
            }}
          >
            {children}
          </SuggestionsContainer>,
          suggestionsPortalContainer,
        )
      }}
      multiSection={false}
      inputProps={{
        ref: inputRef,
        value: value || localValue,
        className: cx('w-full', inputClassName),
        placeholder,
        onChange: (e, { newValue }) => {
          onChange && onChange(newValue)
          setLocalValue(newValue)
        },
      }}
    />
  )
}
