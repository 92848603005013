import React, { useEffect } from 'react'
import { FiltersValueType, renderFilters } from 'components/Filters'
import { PortfolioJobsFiltersType } from 'utils/types'
import { useHistory } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import { InvestorCompany } from 'utils/types/investor'
import { useTeam } from 'store/hooks'

interface Props {
  filters: PortfolioJobsFiltersType
  setFilters: (filters: PortfolioJobsFiltersType) => void
  allTags: string[]
  companies: InvestorCompany[]
  loadingCompanies: boolean
  loadingTags: boolean
  teamSlug: string
}

const Filters: React.VFC<Props> = ({
  filters,
  setFilters,
  allTags,
  companies,
  loadingTags,
  loadingCompanies,
  teamSlug,
}) => {
  const history = useHistory()
  const { team } = useTeam(teamSlug)

  const tags = useSearchParam('tags')
  const search = useSearchParam('search')
  const company = useSearchParam('company')
  const talent_lead = useSearchParam('talent_lead')

  const onSelectTag = (filters: PortfolioJobsFiltersType) => {
    const query: string[] = []

    Object.keys(filters).forEach((param) => {
      const filterKey = param as keyof PortfolioJobsFiltersType
      const list = encodeURIComponent(filters[filterKey]?.join(',') || '')
      query.push(`${param}=${list}`)
    })

    history.replace({
      search: `?${query.join('&')}`,
    })

    setFilters(filters)
  }

  useEffect(() => {
    const filterParams: {
      [Property in keyof PortfolioJobsFiltersType]: string | null
    } = { tags, search, company, talent_lead }

    const filters: FiltersValueType = {}

    Object.keys(filterParams).forEach((param) => {
      const filterKey = param as keyof PortfolioJobsFiltersType
      const list = filterParams[filterKey]?.split(',')
      if (list && filterParams[filterKey] != '') {
        filters[filterKey] = list
      }
    })

    setFilters(filters)
  }, [])

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: [
      {
        key: 'search',
        type: 'string[]',
        label: <i className="far fa-search" />,
        labelStr: 'Search',
        skipLabelInAppliedPill: true,
      },
      {
        key: 'company',
        type: 'dropdown[]',
        label: 'Company',
        loadingOptions: loadingCompanies,
        options: companies.map((c) => ({ label: c.company_name, value: c.uuid })),
      },
      {
        key: 'talent_lead',
        type: 'dropdown[]',
        label: 'Talent Lead',
        loadingOptions: loadingCompanies,
        options: team?.admins_and_members.map((f) => ({
          label: f.name,
          value: f.uuid,
        })),
      },
      {
        key: 'tags',
        type: 'dropdown[]',
        label: 'Tags',
        loadingOptions: loadingTags,
        options: allTags.map((t) => ({ label: t, value: t })),
      },
    ],
    value: filters as FiltersValueType,
    onChange: (f) => onSelectTag(f),
  })

  return (
    <div className={'flex flex-col justify-center'}>
      <div className="flex justify-between">
        <div className="flex flex-col sm:flex-row items-center">
          <div className="flex flex-wrap gap-2" data-testid="portfolio-jobs-filters-options">
            {renderedFilters}
          </div>
        </div>
      </div>
      {appliedFilters && appliedFilters.length > 0 && (
        <div className="flex gap-1 flex-wrap mt-2" data-testid="portfolio-jobs-applied-filters">
          {appliedFilters}
        </div>
      )}
    </div>
  )
}

export default Filters
