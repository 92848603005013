import * as React from 'react'
import EmptyState from 'global/EmptyState'

interface Props {
  teamSlug: string
  appliedFilters: boolean
}

const AdminFeedEmptyState: React.VFC<Props> = ({ teamSlug, appliedFilters }) => {

  return (
    <>
      <EmptyState
        icon={<i className="far fa-inbox"></i>}
        heading={'No Messages'}
        body={`
        Try adjusting your filters or search terms
        `}
      />
    </>
  )
}

export default AdminFeedEmptyState
