import React, { useEffect } from 'react'

import pluralize from 'pluralize'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import { ISalesCompany, SalesFilterTypes } from 'containers/ListIndexView/SalesList/type'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { cn } from 'utils/styles'

const SalesCompany = ({
  company,
  selectedCompanies,
  setSelectedCompanies,
  filters,
}: {
  company: ISalesCompany
  selectedCompanies: ISalesCompany[]
  setSelectedCompanies: (selectedCompanies: ISalesCompany[]) => void
  filters?: SalesFilterTypes
}) => {
  const history = useHistory()
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()

  const { companyListData, refetchCompanyList } = useSalesListContext()
  const companyListSlug = companyListData.slug
  const [params, setParams] = React.useState('')

  useEffect(() => {
    if (filters?.advisor_uuids && filters?.advisor_uuids.length > 0) {
      const queryParams = filters.advisor_uuids
        .map((uuid) => {
          return `advisor_uuids=${uuid}`
        })
        .join('&')
      setParams(`${queryParams}`)
    }
  }, [filters?.advisor_uuids])

  const selected = !!selectedCompanies.find((c) => c.uuid === company.uuid)

  const handleSelection = (company: ISalesCompany, selected: boolean) => {
    if (selected) {
      setSelectedCompanies([...selectedCompanies, company])
    } else {
      setSelectedCompanies(selectedCompanies.filter((a) => a.uuid !== company.uuid))
    }
  }

  const { mutate: deleteItem } = useMutation(
    () => api.removeElementFromSalesList(companyListData.uuid, [company.uuid]),
    {
      onSuccess: () => {
        refetchCompanyList()
        cabalToast({ style: 'success', content: 'Item has been removed from the list' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to delete item' })
      },
    },
  )

  const menuItems = [
    // {
    //   label: 'Edit',
    //   onSelect: () =>
    //     showModal(
    //       (resolve) => (
    //         <PortfolioCompanyEditOrAddModal
    //           onHide={resolve}
    //           investor_company_uuids={company.investor_company_uuid!}
    //           teamSlug={teamSlug}
    //           refetchPortfolioList={refetchPortfolioCompaniesList}
    //           listUuid={companyListData.uuid}
    //         />
    //       ),
    //       'edit-portfolio-company-modal',
    //     ),
    // },
    {
      label: 'Delete',
      onSelect: () => {
        deleteItem()
      },
    },
  ]

  const description = [
    <span key="people_count">
      <i className="far fa-arrows-left-right" /> {company.people_count}{' '}
      {pluralize('Connection', company.people_count)}
    </span>,
    company.stage_name.length > 0 ? (
      <span key="stage_name">{company.stage_name.join(', ')}</span>
    ) : undefined,
    <span key="upvotes_count">
      <i className="far fa-thumbs-up" /> {company.upvotes_count}{' '}
      {pluralize('Offer', company.upvotes_count)}
    </span>,
  ]

  return (
    <DataCardV2
      onClick={() =>
        history.push(`/${teamSlug}/lists/${companyListSlug}/${company.uuid}?${params}`)
      }
      key={company.uuid}
      title={company.name}
      description={description}
      avatar={
        <Avatar
          src={company.domain ? `https://logo.clearbit.com/${company.domain}` : company.logo_url}
          name={company.name}
          size={'32'}
        />
      }
      checkbox={{
        checked: selected,
        onChange: (e) => {
          handleSelection(company, e.target.checked)
        },
      }}
      dropDownMenu={menuItems.length > 0 ? { menuItems: menuItems } : undefined}
      cta={
        <CabalButton
          onClick={() =>
            history.push(`/${teamSlug}/lists/${companyListSlug}/${company.uuid}?${params}`)
          }
          variant={'link'}
          working={false}
          className="w-full"
        >
          {'View'}
        </CabalButton>
      }
      className={cn(
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded',
      )}
    />
  )
}

interface Props {
  selectedCompanies: ISalesCompany[]
  setSelectedCompanies: (selectedCompanies: ISalesCompany[]) => void
  filters?: SalesFilterTypes
}

const SalesCompanyAdminView = ({ selectedCompanies, setSelectedCompanies, filters }: Props) => {
  const { salesCompanies } = useSalesListContext()
  return (
    <div>
      {salesCompanies.map((company) => (
        <SalesCompany
          key={company.uuid}
          company={company}
          selectedCompanies={selectedCompanies}
          setSelectedCompanies={setSelectedCompanies}
          filters={filters}
        />
      ))}
    </div>
  )
}

export default SalesCompanyAdminView
