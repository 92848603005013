import React, { useContext, useEffect } from 'react'

import { Consumer, Subscription } from '@rails/actioncable'

import { sanitize } from 'dompurify'
import { History } from 'history'
import { trim } from 'lodash'
import hotToast, { toast } from 'react-hot-toast'
import { QueryClient, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { Compose, ComposeProps, useComposer } from 'components/Composer'
import { ComposeDealBoardDraft, useComposeDealBoardDraft } from 'components/Composer/hooks'
import { appVersionWatcher } from 'containers/App'
import { ActionCableContext } from 'context/ActionCableContext'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import { useAppDispatch, useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'
import { AppDispatch } from 'store/index'
import { appendAppStateValue, setAppStateValue } from 'store/reducers/appReducer'
import { cabalToast } from 'ui-components/Toast'

import { AppVersion } from 'utils/appVersionWatcher'
import { CompanyBlueprint, PersonBlueprint } from 'utils/types'

import VoteToast from './VoteToast'

const votesToastStack: string[] = []

export interface AppUpdate {
  type: 'new_app_version'
  app_version?: AppVersion
}

export interface UserUpdate {
  type:
    | 'comment'
    | 'notification'
    | 'refetch_query'
    | 'set_state'
    | 'append_state'
    | 'vote'
    | 'draft_shared'
    | 'job_status'
    | 'import_ready'
  message_id?: string
  team_slug?: string
  query_name?: string
  query_key?: string
  key?: string
  value?: any
  company?: CompanyBlueprint
  person?: PersonBlueprint
  vote_id?: string
  avatar_url?: string
  user_name?: string
  user_uuid?: string
  subject?: string
  comment_body?: string
  job_type?: string
  job_status?: string
}

export interface TeamUpdate {
  type: 'refetch_query' | 'set_state' | 'append_state' | 'job_status' | 'crm_metadata_sync'
  team_slug?: string
  query_name?: string[]
  query_key?: string[]
  key?: string
  value?: any
  job_type?: string
  job_status?: string
}

class UserUpdatesHandler {
  userUpdatesSubscription: Subscription
  appSubscription: Subscription
  queryClient: QueryClient
  dispatch: AppDispatch
  compose: Compose
  composeDealBoardDraft: ComposeDealBoardDraft
  history: History

  constructor(
    cable: Consumer,
    queryClient: QueryClient,
    dispatch: AppDispatch,
    compose: Compose,
    composeDealBoardDraft: ComposeDealBoardDraft,
    history: History,
  ) {
    this.queryClient = queryClient
    this.dispatch = dispatch
    this.composeDealBoardDraft = composeDealBoardDraft
    this.compose = compose
    this.history = history

    this.userUpdatesSubscription = cable.subscriptions.create('UserUpdatesChannel', {
      received: this.handleUserUpdates,
    })

    this.appSubscription = cable.subscriptions.create('AppChannel', {
      received: this.handleAppUpdates,
    })
  }

  handleAppUpdates = (update: AppUpdate) => {
    switch (update.type) {
      case 'new_app_version':
        appVersionWatcher.handleVersions([update.app_version!])
        break
    }
  }

  handleUserUpdates = (update: UserUpdate) => {
    switch (update.type) {
      case 'comment':
        if (update.message_id) {
          this.queryClient.invalidateQueries([update.team_slug, 'getComments', update.message_id])
          this.showCommentToast(update)
        }
        break
      case 'notification':
        this.queryClient.invalidateQueries('notifications')
        break
      case 'refetch_query':
        this.queryClient.refetchQueries(update.query_name)
        break
      case 'vote':
        if (update.vote_id) {
          this.showVoteToast(update)
        }
        break
      case 'company_page_viewed':
        this.showCompanyPageViewedToast(update)
        break
      case 'draft_shared':
        if (update.message_id) {
          this.showDraftSharedToast(update)
        }
        break
      case 'set_state':
        if (update.key && update.value) {
          this.dispatch(setAppStateValue({ key: update.key, value: update.value }))
        }
        break
      case 'append_state':
        if (update.key && update.value) {
          this.dispatch(appendAppStateValue({ key: update.key, value: update.value }))
        }
        break
      case 'import_ready':
        if (update && update.team_slug) {
          if (window.location.pathname.includes(`/${update.team_slug}/asks`)) {
            // if we're still on the page, update the url, this will show the dropzone
            this.history.push(`/${update.team_slug}/asks?ready=true`)
          } else {
            // otherwise, show a toast with a link back to the page
            this.showImportReadyToast(update.team_slug)
          }
        } else if (update) {
          if (window.location.pathname.startsWith(`/home`)) {
            // if we're still on the page, update the url, this will show the dropzone
            this.history.push(`/home?ready=true`)
          } else {
            // otherwise, show a toast with a link back to the page
            this.showImportReadyToast(update.team_slug)
          }
        }
        break
    }
  }

  showImportReadyToast = (team_slug?: string) => {
    cabalToast({
      content: `Your LinkedIn export may be ready to download.`,
      avatar: undefined,
      icon: <i className="fas fa-file" />,
      duration: Infinity,
      cta: {
        variant: 'tertiary',
        content: 'Back to import',
        onClick: () => {
          if (team_slug) {
            this.history.push(`/${team_slug}/addconnections`)
          } else {
            this.history.push(`/addconnections`)
          }
        },
      },
      passive: true,
      position: 'bottom-right',
    })
  }

  showCompanyPageViewedToast = (update: UserUpdate) => {
    cabalToast({
      content: `${update.user_name} viewed your company page`,
      avatar: update.avatar_url ? (
        <Avatar size="25px" src={update.avatar_url} name={update.user_name} />
      ) : undefined,
      icon: <i className="fas fa-eye" />,
      duration: 2000,
      passive: true,
      position: 'bottom-right',
    })
  }

  showVoteToast = (update: UserUpdate) => {
    while (votesToastStack.length >= 4) {
      hotToast.remove(votesToastStack.shift())
    }

    const toastId = cabalToast({
      content: <VoteToast {...update} />,
      avatar: update.avatar_url ? (
        <Avatar size="25px" src={update.avatar_url} name={update.user_name} />
      ) : undefined,
      icon: <i className="fas fa-thumbs-up" />,
      cta: {
        variant: 'tertiary',
        content: 'Share draft',
        onClick: () => {
          this.composeDealBoardDraft({
            teamSlug: update.team_slug!,
            person: { id: update.person!.id },
            company_id: update.company?.id,
            user_uuid: update.user_uuid!,
          })
        },
      },
      passive: true,
      position: 'bottom-right',
    })
    votesToastStack.push(toastId)
  }

  showDraftSharedToast = (update: UserUpdate) => {
    let subject = update.subject || ''
    if (subject) {
      subject = `: “${subject}”`
    }
    cabalToast({
      content: `${update.user_name} shared a draft with you${subject}`,
      avatar: update.avatar_url ? (
        <Avatar size="25px" src={update.avatar_url} name={update.user_name} />
      ) : undefined,
      icon: <i className="fas fa-file" />,
      duration: Infinity,
      cta: {
        variant: 'tertiary',
        content: 'Open draft',
        onClick: () => {
          this.compose({
            messageUuid: update.message_id!,
            team_slug: update.team_slug!,
          })
        },
      },
      passive: true,
      position: 'bottom-right',
    })
  }

  showCommentToast = (update: UserUpdate) => {
    if (window.comments_loaded_for === update.message_id) return

    let subject = update.subject || ''
    if (subject) {
      subject = ` “${subject}”`
    }
    const bodyEl = document.createElement('div')
    bodyEl.innerHTML = sanitize(update.comment_body || '')
    const body = trim(bodyEl.innerText || '')
    cabalToast({
      content: (
        <div>
          {update.user_name} commented on a draft{subject}
          <br />
          <Typography fontWeight={600} component="div">
            “{body}”
          </Typography>
        </div>
      ),
      avatar: update.avatar_url ? (
        <Avatar size="25px" src={update.avatar_url} name={update.user_name} />
      ) : undefined,
      icon: <i className="fas fa-comment" />,
      duration: 5 * 60 * 1000,
      cta: {
        variant: 'tertiary',
        content: 'Open draft',
        onClick: () => {
          this.compose({
            messageUuid: update.message_id!,
            team_slug: update.team_slug!,
          })
        },
      },
      passive: true,
      position: 'bottom-right',
    })
  }

  destroy() {
    this.userUpdatesSubscription?.unsubscribe()
  }
}

class TeamUpdatesHandler {
  teamUpdatesSubscription: Subscription
  queryClient: QueryClient
  dispatch: AppDispatch

  constructor(team_slug: string, cable: Consumer, queryClient: QueryClient, dispatch: AppDispatch) {
    this.queryClient = queryClient
    this.dispatch = dispatch
    this.teamUpdatesSubscription = cable.subscriptions.create(
      {
        channel: 'TeamUpdatesChannel',
        team_slug,
      },
      {
        received: this.handleTeamUpdates,
      },
    )
  }

  handleTeamUpdates = (update: TeamUpdate) => {
    switch (update.type) {
      case 'refetch_query':
        if (update.query_name) {
          this.queryClient.refetchQueries(update.query_name)
        }
        break
      case 'job_status':
        if (
          ['SyncSalesforceJob', 'SyncHubspotJob', 'SyncSalesforceFieldValueJob'].includes(
            update.job_type!,
          )
        ) {
          this.queryClient.refetchQueries(['crm_status'])

          if (update.job_status === 'complete') {
            if (['SyncSalesforceJob', 'SyncSalesforceFieldValueJob'].includes(update.job_type!)) {
              toast.remove('syncing_salesforce')
            } else {
              toast.remove('syncing_hubspot')
            }
          }
        }
        break
      case 'set_state':
        if (update.key && update.value) {
          this.dispatch(setAppStateValue({ key: update.key, value: update.value }))
        }
      case 'append_state':
        if (update.key && update.value) {
          this.dispatch(appendAppStateValue({ key: update.key, value: update.value }))
        }
      case 'crm_metadata_sync':
        this.queryClient.refetchQueries(['owner_email'])
        this.queryClient.refetchQueries(['stage_name'])
        this.queryClient.refetchQueries(['pipeline_names'])
        this.queryClient.refetchQueries(['getSfdcFields'])
        this.queryClient.refetchQueries(['getHubspotFields'])
        break
    }
  }

  destroy() {
    this.teamUpdatesSubscription?.unsubscribe()
  }
}

const RealTimeUpdates = () => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { user } = useCurrentUser()
  const queryClient = useQueryClient()
  const { cable } = useContext(ActionCableContext)
  const dispatch = useAppDispatch()
  const { composeDealBoardDraft } = useComposeDealBoardDraft()
  const { compose } = useComposer()
  const history = useHistory()

  useEffect(() => {
    if (!user) return
    const userUpdatesHandler = new UserUpdatesHandler(
      cable,
      queryClient,
      dispatch,
      compose,
      composeDealBoardDraft,
      history,
    )

    return () => {
      userUpdatesHandler.destroy()
    }
  }, [cable, user])

  useEffect(() => {
    if (!team) return
    const teamUpdatesHandler = new TeamUpdatesHandler(team.slug, cable, queryClient, dispatch)

    return () => {
      teamUpdatesHandler.destroy()
    }
  }, [cable, team])

  return <></>
}

export default React.memo(RealTimeUpdates)
