import axios, { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { WINSITE_ENDPOINTS } from '../apiEndpoints'

const fetchUploadData = async (companyDomain: string | undefined): Promise<AxiosResponse> => {
  return await axios.get(WINSITE_ENDPOINTS.fetchUploads, {
    params: { company_domain: companyDomain },
  })
}

export const useFetchUploadData = (
  teamSlug: string | undefined,
  companyDomain: string | undefined,
) =>
  useQuery({
    queryKey: ['team_uploads', teamSlug],
    queryFn: () => fetchUploadData(companyDomain),
    retry: 1,
  })
