import React from 'react'

import Typography from 'global/Typography'

import { StyledMaterialDivSubSection } from '../styles'

interface IProps {
  content: string
  leftIcon: React.ReactNode
  onClick: () => void
}
export function MaterialDivSubSection({ content, leftIcon, onClick }: IProps) {
  return (
    <StyledMaterialDivSubSection onClick={onClick}>
      <div className="flex flex-row gap-x-2 items-baseline">
        {leftIcon}
        <Typography fontSize={'12'}>{content}</Typography>
      </div>
    </StyledMaterialDivSubSection>
  )
}
