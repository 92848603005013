export function getVariableFieldsUsed(text: string) {
  return (text.match(/{{[\w_]+}}/g) || []) as string[]
}

export function badVariableFieldFormattingUsed(text: string) {
  return text.includes('{first_name}')
}

export function nameVariablesUsed(text: string) {
  const variables = getVariableFieldsUsed(text).map((variable) => variable.slice(2, -2))
  return ['first_name', 'member_name', 'full_name'].some((variable) => variables.includes(variable))
}
