import * as React from 'react'
import { useMutation, useQueryClient } from 'react-query'

import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'

import api, { callApi } from 'utils/api'
import { Offer } from 'utils/types/offer'

interface Props {
  offer: Offer
  teamSlug: string
  onEdit: (offer: Offer) => void
}

const OfferOptions = ({ offer, teamSlug, onEdit }: Props) => {
  function onClick(e: React.SyntheticEvent) {
    e.stopPropagation()
  }

  const queryCache = useQueryClient()

  const { mutate: deleteOffer, isLoading: isDeleting } = useMutation(
    ['deleteOffer', offer.uuid],
    () => callApi(api.deleteOffer, teamSlug, offer.uuid),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['offers', teamSlug])
      },
    },
  )

  const menuItems = () => {
    const items = [
      {
        label: 'Edit resource',
        onSelect: () => onEdit(offer),
      },
      {
        label: 'Delete resource',
        onSelect: () => deleteOffer(),
      },
    ]
    return items
  }
  return (
    <div onClick={onClick} className="self-center ml-3">
      <DropDownMenu
        trigger={
          <CabalButton
            variant="tertiary"
            className="py-0"
            leftIcon={<i className="far fa-ellipsis-v" />}
            component="div"
          />
        }
        menuItems={menuItems()}
      />
    </div>
  )
}

export default OfferOptions
