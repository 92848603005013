import styled from 'styled-components'

const CompanyLink = styled.a<{ active?: boolean }>`
  color: ${({ active }) => (active ? '#5c69d1' : '#fff')};
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    color: #ffffffad;
  }
`

const InvestorLink = styled.a<{ active?: boolean }>`
  color: ${({ active }) => (active ? '#e4307c' : '#fff')};
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    color: #ffffffad;
  }
`

const LogoImg = styled.img`
  height: 28px;
`

export { CompanyLink, InvestorLink, LogoImg }
