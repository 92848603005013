import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { CompanyBlueprint } from 'utils/types'

interface CompaniesByUuid {
  [key: string]: CompanyBlueprint
}

export interface State {
  companies_by_uuid: Record<string, CompanyBlueprint>
}

const initialState: State = {
  companies_by_uuid: {},
}

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    updateCompanies: (state, action: PayloadAction<CompaniesByUuid>) => {
      const newState = {
        ...state,
        companies_by_uuid: {
          ...state.companies_by_uuid,
          ...action.payload,
        },
      }

      return newState
    },
  },
})

export const { updateCompanies } = companiesSlice.actions

export default companiesSlice.reducer
