import axios, { AxiosResponse } from 'axios'

import { type ExtensionCompany, type ExtensionPerson } from 'extension/GetIntroContentScript'

import {
  AdvisorModel,
  CompanyBlueprint,
  CompanyListBlueprint,
  GlobalCompanyBlueprint,
  GlobalPersonBlueprint,
  Pagination,
} from 'utils/types'

export interface ExtGetConnectionsCountResponse {
  connections_count: number
  global_company_name: string
  global_company_uuid: string
  connection_count_by_emails: Record<string, number>
}

export default {
  extInstallCallback: (event: string, version: string, previousVersion = '') => {
    return axios.post('/api/extension/callback', { event, version, previousVersion })
  },
  extGetGlobalPerson: (person: ExtensionPerson) => {
    return axios.post<{
      global_person: GlobalPersonBlueprint
      advisors: AdvisorModel[]
    }>(`/api/extension/global_person`, {
      person,
    })
  },
  extGetGlobalCompany: (
    company: ExtensionCompany,
    companyUrl?: string,
    sfdcRecordType?: string,
    sfdcRecordId?: string,
  ) => {
    return axios.post<{
      company: CompanyBlueprint
      global_company: GlobalCompanyBlueprint
      sfdc_record_type: string
      sfdc_record_id: string
    }>(`/api/extension/global_company`, {
      company,
      companyUrl,
      sfdc_record_type: sfdcRecordType,
      sfdc_record_id: sfdcRecordId,
    })
  },
  extGetSelectorOverrides: () => {
    return axios.get<{
      selector_overrides: Record<string, string>
    }>(`/api/extension/selector_overrides`)
  },
  extGetConnectionsCount: (emails: Record<string, string>) => {
    return axios.get<ExtGetConnectionsCountResponse>(`/api/extension/connections_count`, {
      params: { emails },
    })
  },
  extGetCompanyConnectionCount: (
    id?: string,
    type?: string,
    url?: string,
    name?: string,
    hubspot_id?: string,
  ) => {
    return axios.get<ExtGetConnectionsCountResponse>(`/api/extension/company_connections_count`, {
      params: {
        sfdc_record_id: id,
        sfdc_record_type: type,
        company_url: url,
        name: name,
        hubspot_id: hubspot_id,
      },
    })
  },
  extGetConnectionsByCompany: (
    global_company_uuid: string,
    filters: { query: string },
    page: number,
  ) => {
    return axios.post<{
      connections: {
        global_person: GlobalPersonBlueprint
        advisors: AdvisorModel[]
      }[]
      pagination: Pagination
    }>(`/api/extension/global_company_connections`, {
      global_company_uuid,
      filters,
      page,
    })
  },
  extGetCompanyLists: (
    team_slug?: string,
    include_items = false,
    published_only = false,
    current_slug?: string,
    memberUuid?: string,
    params: any = {},
  ) => {
    return axios.get<{ company_lists: CompanyListBlueprint[] }>(`/api/company_lists`, {
      params: {
        team_slug,
        include_items,
        published_only,
        current_slug,
        member_uuid: memberUuid,
        ...params,
      },
    })
  },

  extAddCompanyToList: (list_uuid: string | undefined, company_uuid: string) => {
    if (list_uuid) {
      return axios.post(`/api/extension/add_company_to_list`, {
        list_uuid: list_uuid,
        company_uuid: company_uuid,
      })
    }
  },
}
