import React, { useRef, useState } from 'react'

import { useMutation } from 'react-query'
import styled from 'styled-components'

import CabalButton from 'global/CabalButton'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { useAppDispatch, useCurrentUser } from 'store/hooks'
import { setCurrentUserSettings } from 'store/reducers/currentUserReducer'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

import gcontact from '../../../assets/images/gcontact.png'
import gmail from '../../../assets/images/gmail.png'
import G from './../../images/google-icon.png'

interface Props {
  show: boolean
  onHide: () => void
  onSkip?: () => void
  onAuthorize: (email?: string) => void
  email_alias_address?: string
  reAuthorize?: boolean
  header?: string
  message?: string
  buttonText?: string
  service?: 'gmail' | 'gcontacts'
}

const GmailLogo = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  border: 1px solid #eee;
`

const GoogleAuthModal: React.VFC<Props> = (props) => {
  const {
    email_alias_address,
    reAuthorize = false,
    onHide,
    onSkip,
    onAuthorize,
    header,
    message,
    service = 'gmail',
  } = props
  const capitalizedServiceName = service === 'gmail' ? 'Gmail' : 'Google Contact'
  const popupRef = useRef<Window | null>(null)

  const [working, setWorking] = useState(false)

  const { reloadUser } = useCurrentUser()
  const dispatch = useAppDispatch()

  const updateSettingMutation = useMutation(
    () => callApi(api.updateUserSetting, 'dismissed_sync_gmail_modal', true),
    {
      onSuccess: () => {
        dispatch(setCurrentUserSettings({ dismissed_sync_gmail_modal: true }))
      },
    },
  )

  const onMessage = React.useCallback(
    async (e) => {
      const { type, status, email } = e.data

      if (type == 'gmail_auth') {
        if (status === 'success') {
          await reloadUser()
          onAuthorize(email)
        } else if (status === 'error') {
          cabalToast({
            content: (
              <>
                Almost! Your {capitalizedServiceName} isn{`'`}t authorized with Cabal yet. <br />
                {service === 'gmail' && (
                  <>
                    <br /> Make sure to select&nbsp;{`"Send email on your behalf"`} to start sending
                    emails directly from Cabal.
                  </>
                )}
              </>
            ),
            style: 'error',
            id: 'gmail-auth-failed',
          })
        }
        setWorking(false)
      }
    },
    [onAuthorize, reloadUser],
  )

  const onClickSkip = React.useCallback(async () => {
    if (service === 'gmail') await updateSettingMutation.mutateAsync()

    onSkip?.()
  }, [])

  React.useEffect(() => {
    window.addEventListener('message', onMessage)
    return () => window.removeEventListener('message', onMessage)
  }, [onMessage])

  function onSubmit(e: React.SyntheticEvent) {
    setWorking(true)
    e.preventDefault()
    const width = 550
    const height = 650
    const x = screen.width / 2 - width / 2
    const y = screen.height / 2 - height / 2
    popupRef.current = window.open(
      `/auth/${service}`,
      `Authorize ${capitalizedServiceName}`,
      `width=${width},height=${height},left=${x},top=${y}`,
    )
    const timer = setInterval(function () {
      if (popupRef.current?.closed) {
        clearInterval(timer)
        setWorking(false)
      }
    }, 1000)
  }

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      header={header || `Reauthorize ${capitalizedServiceName}`}
      centerActions={
        <div className="flex flex-col items-center">
          <CabalButton
            working={working}
            variant="auth"
            leftIcon={<img src={G} width="18" />}
            onClick={onSubmit}
          >
            Sign in with Google
          </CabalButton>

          {onSkip && service === 'gmail' && (
            <div className="mt-6 mb-1">
              <CabalButton
                variant="secondary"
                type="button"
                onClick={onClickSkip}
                working={updateSettingMutation.isLoading}
                data-testid={`skip-${service}-auth-button`}
              >
                <i className="fa fa-arrow-right mr-2" />
                Skip and send from {email_alias_address}
              </CabalButton>
            </div>
          )}
        </div>
      }
    >
      <div className="pt-2 overflow-hidden text-center">
        <GmailLogo src={service === 'gmail' ? gmail : gcontact} className="m-auto" />
        <div className="text-secondary my-8">
          {service === 'gmail' && (
            <>
              {!message &&
                reAuthorize &&
                `Before you can send your message, you’ll need to reauthorize your Gmail account.`}
              {!message && !reAuthorize && `Send messages directly from your Gmail account`}
            </>
          )}
          {service === 'gcontacts' &&
            'Directly use your contacts from Google Contacts in the composer.'}
          {message}
        </div>
      </div>
    </Modal>
  )
}

export default GoogleAuthModal
