import React from 'react'

import AdminTalentSetting from 'containers/Settings/AdminTalentSetting'

const Investors: React.FC = () => {
  return (
    <div className="flex flex-col gap-4 mt-4">
      <AdminTalentSetting />
    </div>
  )
}

export default Investors
