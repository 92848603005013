import React, { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import styled from 'styled-components/macro'

const StyledPage = styled(Page)`
  /* margin-bottom: 12px; */
`

const StyledDocument = styled(Document)`
  /* height: 500px; */
  overflow: scroll;
`

export default function Pdf(props) {
  const [numPages, setNumPages] = useState(null)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const { pdf } = props

  return (
    <StyledDocument file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from(new Array(numPages), (el, index) => (
        <StyledPage key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </StyledDocument>
  )
}
