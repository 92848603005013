import React, { useMemo, useRef } from 'react'

import classNames from 'classnames'
import compact from 'lodash/compact'
import isEqual from 'lodash/isEqual'
import uniq from 'lodash/uniq'
import RTokenInput, { TokenInputProps, TokenInputRef } from 'react-customize-token-input'
import useStateRef from 'react-usestateref'
import styled from 'styled-components'
import { twMerge } from 'tailwind-merge'
import tw from 'twin.macro'

import Typography from 'global/Typography'

const Wrapper = styled.div<{ flexWrap?: string }>`
  font-size: 12px;
  border-color: ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => {
    return theme.layout.main_bg_color
  }};

  .token-input-container {
    ${tw`flex flex-wrap`}
  }

  .token-input-token-list {
    ${tw`flex`}
    ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}

    .token-input-token {
      ${tw`flex items-center justify-center gap-1 rounded p-1 mr-1 whitespace-nowrap`}

      font-size: 11px;
      background-color: ${({ theme }) => theme.buttons.default.bg_color};

      &:last-child {
        ${tw`mr-2`}
      }
    }
  }

  input {
    ${tw`py-1`}

    background-color: transparent;
  }

  input::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }
`

interface Props extends Omit<TokenInputProps, 'tokenValues' | 'onTokenValuesChange'> {
  value: string[]
  onChange: (q: string[]) => void
  separators?: string[]
  includeInputValueInValues?: boolean
  icon?: React.ReactNode
  flexWrap?: string
}

const TokenInput: React.FC<Props> = React.memo(
  ({
    value: _value,
    onChange,
    separators: delimiters = [','],
    includeInputValueInValues,
    className,
    icon,
    flexWrap,
    ...restProps
  }) => {
    const [inputValue, setInputValue, inputValueRef] = useStateRef<string>()
    const lastChangedValue = useRef<string[]>(_value)
    const tokenInputRef = useRef<TokenInputRef>(null)

    const { value, lastValue } = useMemo(() => {
      let lastValue: string | undefined
      if (includeInputValueInValues) {
        const lastIndex = _value.length - 1
        lastValue = _value[lastIndex]

        if (lastValue === inputValueRef.current) {
          return { value: _value.slice(0, lastIndex), lastValue }
        }
      }

      return { value: _value, lastValue }
    }, [_value, includeInputValueInValues, inputValueRef])

    return (
      <Wrapper
        className={twMerge(
          classNames(
            `inline-flex py-2 text-base appearance-none w-full items-center
          rounded-lg outline-none px-3 leading-tight focus:outline-none gap-2
          focus-within:ring cursor-text `,
            className,
          ),
        )}
        onClick={() => tokenInputRef.current?.focus?.()}
        flexWrap={flexWrap}
      >
        {!!icon && <Typography color="placeholder">{icon}</Typography>}
        <RTokenInput
          ref={tokenInputRef}
          {...restProps}
          className="flex-1"
          separators={delimiters}
          tokenValues={value}
          onTokenValuesChange={(c) => {
            lastChangedValue.current = c
            onChange(c)
          }}
          onRenderTokenDeleteButtonContent={() => <i className="far fa-times" />}
          onGetIsTokenEditable={() => false}
          onInputValueChange={(q) => {
            setInputValue(q)

            if (includeInputValueInValues && isEqual(lastChangedValue.current, value)) {
              onChange(compact([...value, q]))
            }
          }}
          onCreatorBlur={() => {
            if (inputValue) {
              onChange(uniq([...value, inputValue]))
            }
          }}
        />
        {!!_value.length && (
          <button onClick={() => onChange([])}>
            <i className="far fa-times" />
          </button>
        )}
      </Wrapper>
    )
  },
)

export default TokenInput
