import CabalTitle from 'global/Title'
import * as React from 'react'

// New Section
import Personal from './Personal'

const Settings: React.FC = () => {
  return (
    <>
      <CabalTitle title="Settings" />
      <Personal />
    </>
  )
}

export default Settings
