import axios from 'axios'
import { Tag } from 'utils/types'

export default {
  getTags: (team_slug: string, attached_to: string) => {
    return axios.get<{ tags: Tag[] }>(`/api/tags`, {
      params: {
        team_slug,
        attached_to,
      },
    })
  },
}
