import React from 'react'

import CabalButton from 'global/CabalButton'
import UploadZone from 'global/Input/UploadZone'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'

export default function MultiDocumentUploader(props: {
  uploadedDocuments: File[]
  onDocumentsUploaded: (documents: File[]) => unknown
}) {
  const [showDocumentUploader, setDocumentUploader] = React.useState<boolean>(false)

  return (
    <div>
      <ModalInputWrapper className="py-2 text-left">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Files (resume, etc)</div>
        </ModalInputLabel>
        <CabalButton
          style={{ textAlign: 'left' }}
          variant="secondary"
          color="purple"
          onClick={(e) => {
            e.preventDefault()
            setDocumentUploader(!showDocumentUploader)
          }}
        >
          + Add Files
        </CabalButton>
      </ModalInputWrapper>
      <div style={{ display: showDocumentUploader ? 'block' : 'none' }}>
        <UploadZone
          onSelect={(documents) => props.onDocumentsUploaded(documents)}
          prompt=   <Typography className="mt-1">
          Drag and drop to upload or <Typography color="purple">click to browse</Typography>
        </Typography>
          accept={['pdf', 'doc', 'png', 'jpg', 'video']}
          multiple={true}
        />
      </div>
    </div>
  )
}
