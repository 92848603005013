import React from 'react'

import { useMutation, useQuery } from 'react-query'

import { IEmailSelectorResponse, IIntroRequestTarget } from 'containers/IntroRequestModal/types'
import { Select } from 'global/Input'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

interface Props {
  email: string
  setEmail: (email: string) => void
  target: IIntroRequestTarget
  teamSlug: string
  list: any
}

const EmailSelectorV2 = ({ email, setEmail, target, teamSlug, list }: Props) => {
  const { data: emailOptions, refetch: refetchEmailOptions } = useQuery({
    queryKey: ['requestableEmails', target.uuid],
    queryFn: () => callApi(api.getGlobalPersonEmails, target?.uuid, teamSlug),
    enabled: target?.itemType !== 'InvestorCompanyJob',
  })

  const handleEmailCreation = (email: string) => {
    target?.itemType === 'InvestorCompanyJob'
      ? updateJobPointOfContactMutation.mutate(email)
      : createGlobalPersonMutation.mutate(email)
  }

  const createGlobalPersonMutation = useMutation({
    mutationFn: (email: string) =>
      callApi(api.createGlobalPersonEmail, {
        global_person_uuid: target?.uuid,
        email: email,
        team_id: teamSlug,
      }),
    onSuccess: (res) => {
      refetchEmailOptions()
    },
    onError: (error) => {
      cabalToast({ style: 'error', content: error?.response?.data?.errors })
      setEmail(email)
    },
  })

  const updateJobPointOfContactMutation = useMutation({
    mutationFn: (email: string) =>
      callApi(
        api.updateJob,
        {
          uuid: target.uuid,
          talent_poc: {
            email: email,
          },
        },
        list.uuid,
      ),
    onSuccess: (res) => {
      refetchEmailOptions()
    },
    onError: (error) => {
      cabalToast({ style: 'error', content: error?.response?.data?.errors })
      setEmail(email)
    },
  })

  return (
    <div className="py-2">
      <Typography fontSize="12" fontWeight={400} lineHeight="1" className="text-center">
        Add email for {target.name}:
      </Typography>
      <Select
        className="mt-1"
        value={email}
        onChange={(e) => e && setEmail(e)}
        options={
          emailOptions?.map((email: IEmailSelectorResponse) => ({
            label: email.email,
            value: email.email,
          })) || []
        }
        creatable
        onCreateOption={(e) => {
          setEmail(e)
          handleEmailCreation(e)
        }}
      />
    </div>
  )
}

export default EmailSelectorV2
