import React, { useState } from 'react'

import { CompanyLandingPage } from 'components/Winsite/CompanyLandingPage'
import { Layout } from 'components/Winsite/Layout'
import { ITeamData } from 'components/Winsite/types/ITeamData'
import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Typography from 'global/Typography'

import { Spinner } from './Spinner'
import { useCompanyPage } from './queries/CompanyPage'
import { Card } from './styles'

interface IProps {
  companyName: string
  linkedinUrl: string
}

export function FetchCompanyDomain({ companyName, linkedinUrl }: IProps) {
  const [companyDomain, setCompanyDomain] = useState<string>('')
  const [submission, setSubmission] = useState<boolean>(false)
  const {
    refetch,
    data: teamData,
    isLoading,
  } = useCompanyPage(
    {
      companyName: companyName,
      linkedinUrl: linkedinUrl,
      companySlug: null,
      companyDomain: companyDomain,
    },
    { enabled: false, retry: 3 },
  )
  const handleSubmit = () => {
    setSubmission(true)
    refetch()
  }
  if (isLoading) {
    return <Spinner />
  }
  if (teamData && submission) {
    return (
      <CompanyLandingPage
        teamData={teamData.data}
        companyName={companyName}
        companyDomain={companyDomain}
        linkedinUrl={linkedinUrl}
        spinnerDisplayProp={true}
      />
    )
  }

  return (
    <Layout>
      <div className="flex justify-center items-center min-h-[60vh]">
        <Card className="w-[368px] sm:min-w-[500px]">
          <div className="m-3 mb-2 sm:m-6 flex flex-col gap-y-4 sm:flex-row justify-between sm:items-center">
            <div className="flex flex-row sm:flex-col mr-5 flex-shrink-0 sm:items-start justify-between sm:justify-normal">
              <Typography color="black" fontSize="14" className="">
                Company Domain
              </Typography>
            </div>
            <TextInput
              placeholder="Company Domain"
              className="w-full"
              value={companyDomain}
              style={{ backgroundColor: 'rgb(243, 244, 246)', borderColor: 'rgb(227, 229, 232)' }}
              onChange={(e) => setCompanyDomain(e.target.value)}
            />
          </div>
          <div className="pb-1 flex items-center justify-center w-full">
            <CabalButton
              className="mt-6 mb-2 self-end"
              variant="primary"
              type="submit"
              style={{ backgroundColor: '#03C168' }}
              onClick={handleSubmit}
            >
              Continue
            </CabalButton>
          </div>
        </Card>
      </div>
    </Layout>
  )
}
