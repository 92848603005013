import React, { useEffect, useMemo, useState } from 'react'

import { AxiosError, AxiosResponse } from 'axios'
import groupBy from 'lodash/groupBy'
import { useMutation } from 'react-query'
import { useLocalStorage } from 'react-use'
import validator from 'validator'

import ImportMembersModal from 'containers/ImportMembers/ImportMembersModal'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { Select, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { RenderModal, useModal } from 'global/Modal/Context'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useAdvisors } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { GroupModel } from 'utils/types'

import AdvisorsRow from './AdvisorsRow'

interface Props {
  onHide: () => void
  onSave: () => void
  onDelete: () => void
  teamSlug: string
  selectedGroup?: GroupModel
}

const EditGroupsModal: React.FC<Props> = ({
  onHide,
  onSave,
  onDelete,
  selectedGroup,
  teamSlug,
}) => {
  const { advisors, reloadAdvisors } = useAdvisors({
    teamSlug: teamSlug,
  })
  const [, setEmails] = useLocalStorage<string>('import_emails_cache')
  const { canAddAdvisor } = useAccessControl(teamSlug)
  const { showModal } = useModal()
  const [rerender, setRerender] = useState(Math.random() * 10000)
  const [group, setGroup] = useState<GroupModel | undefined>(selectedGroup)
  const [existingAdvisorUuids, setExistingAdvisorUuids] = useState<string[]>([])
  const [addedAdvisorUuids, setAddedAdvisorUuids] = useState<string[]>([])
  const [removedAdvisorUuids, setRemovedAdvisorUuids] = useState<string[]>([])
  const [selectFocus, setSelectFocus] = useState(!!selectedGroup)
  const creatingGroup = !selectedGroup

  const groupAdvisorUuids = useMemo(() => {
    return [...existingAdvisorUuids, ...addedAdvisorUuids]
  }, [existingAdvisorUuids, addedAdvisorUuids])

  const renderInviteMemberModal: RenderModal = (resolve) => (
    <ImportMembersModal
      onHide={() => {
        resolve(false)
        loadGroupAdvisors()
      }}
      teamSlug={teamSlug}
      group={group!}
    />
  )

  useEffect(() => {
    loadGroupAdvisors()
  }, [])

  const loadGroupAdvisors = async () => {
    if (!creatingGroup) {
      const { data } = await api.getGroupAdvisors(teamSlug, group!.uuid)
      setExistingAdvisorUuids(data.advisor_uuids)
    }
  }

  const { mutate: createGroupAddAdvisorMutation } = useMutation(
    () =>
      callApi(
        api.createGroupAddAdvisors,
        teamSlug,
        { name: group?.name, filters: [], smart_group: false },
        addedAdvisorUuids,
      ),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: () => {
        cabalToast({
          style: 'success',
          content: <Typography>{group?.name} created</Typography>,
        })
        onHide()
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 409) {
          cabalToast({
            style: 'error',
            content: (
              <Typography>
                Group{' '}
                <Typography fontWeight={600} className="italic">
                  {group?.name}
                </Typography>{' '}
                already exists.
              </Typography>
            ),
          })
        }
        if (error?.response?.status === 400) {
          cabalToast({
            style: 'error',
            content: (
              <Typography>
                Group{' '}
                <Typography fontWeight={600} className="italic">
                  {group?.name}
                </Typography>{' '}
                creation failed.
              </Typography>
            ),
          })
        }
        onHide()
      },
    },
  )
  // TODO: Remove this code
  const { mutate: createGroupMutation } = useMutation(
    () =>
      callApi(api.createGroup, teamSlug, { name: group?.name, filters: [], smart_group: false }),
    {
      onSuccess: async (response) => {
        setGroup(response.group)

        if (addedAdvisorUuids.length > 0) {
          await bulkAddAdvisors({ uuids: [response.group.uuid] })
        }
        cabalToast({
          style: 'success',
          content: <Typography>{group?.name} created</Typography>,
        })

        onSave()
        onHide()
      },
      onError: ({ response }) => {
        if (response.status === 409) {
          cabalToast({
            style: 'error',
            content: (
              <Typography>
                Group{' '}
                <Typography fontWeight={600} className="italic">
                  {group?.name}
                </Typography>{' '}
                already exists.
              </Typography>
            ),
          })
        }
      },
    },
  )

  const { mutate: saveGroup, isLoading: savingGroup } = useMutation(
    () => callApi(api.saveGroup, teamSlug, group!),
    {
      onSuccess: async () => {
        if (addedAdvisorUuids.length > 0) {
          await bulkAddAdvisors({ uuids: [group!.uuid] })
        }

        if (removedAdvisorUuids.length > 0) {
          await bulkRemoveAdvisors({ uuids: [group!.uuid] })
        }

        cabalToast({
          style: 'success',
          content: <Typography>{group?.name} updated</Typography>,
        })
        onSave()
        onHide()
      },
      onError: ({ response }) => {
        if (response.status === 409) {
          cabalToast({
            style: 'error',
            content: (
              <Typography>
                Group{' '}
                <Typography fontWeight={600} className="italic">
                  {group?.name}
                </Typography>{' '}
                already exists.
              </Typography>
            ),
          })
        }
      },
    },
  )

  const { mutate: deleteGroup, isLoading: deletingGroup } = useMutation(
    () => callApi(api.deleteGroup, teamSlug, group!.uuid),
    {
      onSuccess: () => {
        cabalToast({
          style: 'success',
          content: <Typography>{group?.name} deleted</Typography>,
        })
        onDelete()
      },
    },
  )

  const { mutate: bulkAddAdvisors, isLoading: bulkAdding } = useMutation(
    ({ uuids }: { uuids: string[] }) =>
      callApi(api.bulkAddAdvisorGroups, teamSlug, uuids, addedAdvisorUuids),
    {
      onSuccess: () => {
        onSave()
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content: 'Advisors were not added to group',
        })
      },
    },
  )

  const { mutate: bulkRemoveAdvisors, isLoading: bulkRemoving } = useMutation(
    ({ uuids }: { uuids: string[] }) =>
      callApi(api.bulkRemoveAdvisorGroups, teamSlug, uuids, removedAdvisorUuids),
    {
      onSuccess: () => {
        onSave()
      },
    },
  )

  const handleDelete = () => {
    if (
      confirm('Are you sure you want to delete this group?') &&
      prompt("Please type CONFIRM if you'd like to continue deleting this group.") == 'CONFIRM'
    ) {
      deleteGroup()
    }
  }

  const handleAdvisorRemove = (advisorUuid: string) => {
    if (existingAdvisorUuids.includes(advisorUuid)) {
      setRemovedAdvisorUuids((prevUuids) => [...prevUuids, advisorUuid])
      setExistingAdvisorUuids((prevUuids) => prevUuids.filter((uuid) => uuid !== advisorUuid))
    } else {
      setAddedAdvisorUuids((prevUuids) => prevUuids.filter((uuid) => uuid !== advisorUuid))
    }
  }

  const advisorsInGroup = useMemo(() => {
    const indexedAdvisors = groupBy(advisors, 'uuid')
    return groupAdvisorUuids?.reverse().map((advisorUuid) => {
      return indexedAdvisors[advisorUuid][0]
    })
  }, [advisors, groupAdvisorUuids])

  const advisorsNotInGroup = advisors?.filter(
    (advisor) => !groupAdvisorUuids.includes(advisor.uuid),
  )

  return (
    <Modal
      show={true}
      onHide={onHide}
      header={creatingGroup ? 'Create Group' : 'Edit Group'}
      leftActions={
        !creatingGroup && (
          <CabalButton variant="tertiary" disabled={deletingGroup} onClick={handleDelete}>
            Delete Group
          </CabalButton>
        )
      }
      rightActions={
        <CabalButton
          disabled={group?.name === ''}
          working={savingGroup || bulkRemoving || bulkAdding}
          onClick={() => (creatingGroup ? createGroupAddAdvisorMutation() : saveGroup())}
        >
          Save
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Group Name</ModalInputLabel>
          <TextInput
            value={group?.name}
            onChange={(e) => setGroup({ ...group, name: e.target.value })}
            onBlur={() => setSelectFocus(true)}
            onFocus={() => setSelectFocus(false)}
            placeholder="Name of group"
            className="w-full"
          />
        </ModalInputWrapper>
        <div>
          <Tooltip label={!group?.name ? 'Add group name first' : null} className="w-full">
            <div className="w-full">
              <Select
                key={'group-advisor-select-' + rerender}
                placeholder="Find group members"
                leftActions={<i className="far fa-search fa-sm" />}
                options={
                  advisorsNotInGroup?.map((advisor) => ({
                    value: advisor.uuid,
                    label: `${advisor.name} - ${advisor.email}`,
                  })) || []
                }
                onChange={(value) => {
                  value && setAddedAdvisorUuids((prevUuids) => [...prevUuids, value])
                  setRerender(rerender + 1)
                }}
                creatable
                formatCreateLabel={(inputValue) =>
                  validator.isEmail(inputValue)
                    ? `Invite ${inputValue}`
                    : `No match found for ${inputValue}. Enter email to add a new member.`
                }
                onCreateOption={(inputValue: string) => {
                  if (validator.isEmail(inputValue)) {
                    setEmails(inputValue)
                    showModal(renderInviteMemberModal, 'render_invite_member_modal')
                  }
                }}
                autoFocus={selectFocus}
                disabled={!group?.name}
              />
              {canAddAdvisor && (
                <CabalButton
                  variant="link"
                  disabled={!group?.name}
                  leftIcon={<i className="far fa-plus fa-fw" />}
                  padding="0"
                  onClick={() => showModal(renderInviteMemberModal, 'render_invite_member_modal')}
                  className="mt-2"
                >
                  Add Members
                </CabalButton>
              )}
            </div>
          </Tooltip>
        </div>
        <div className="max-h-[270px] overflow-y-scroll">
          {advisorsInGroup?.map((advisor) => {
            return (
              <AdvisorsRow
                key={advisor.uuid}
                advisor={advisor}
                onRemove={() => {
                  handleAdvisorRemove(advisor.uuid)
                }}
              />
            )
          })}
        </div>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default EditGroupsModal
