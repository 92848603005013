import React, { useEffect } from 'react'

import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import { useComposer } from 'components/Composer'
import { useAccessControl } from 'global/AccessControl'
import { useAdvisors, useTeam } from 'store/hooks'

import Templates from 'utils/templates'
import { AdvisorModel, CompanySlugParam } from 'utils/types'

interface ComposeParams extends CompanySlugParam {
  id?: string
}

interface ComposeQuery {
  advisors?: AdvisorModel[]
}

const Compose: React.VFC = () => {
  const { company_slug, id } = useParams<ComposeParams>()
  const { compose } = useComposer()
  const { advisors } = useAdvisors({
    teamSlug: company_slug,
  })
  const { team } = useTeam(company_slug)

  const history = useHistory()
  const { search, state } = useLocation<ComposeQuery | undefined>()

  const { canCreateDraft } = useAccessControl(company_slug)

  const useUpdateTemplate = search.includes('update=')
  const updateMonth = useSearchParam('update')
  const draft_from = useSearchParam('draft_from')
  const from_request = useSearchParam('from_request')
  const template_uuid = useSearchParam('template_uuid')
  const advisor_uuids = useSearchParam('advisor_uuids')
  const user_uuids = useSearchParam('user_uuids')
  const drafter_uuid = useSearchParam('drafter_uuid')
  const emails = useSearchParam('emails')

  useEffect(() => {
    if (canCreateDraft === false) {
      history.push(`/${company_slug}${window.location.search}`)
    }
  }, [canCreateDraft])

  useEffect(() => {
    if (!canCreateDraft || !team) return
    if (advisor_uuids && (!advisors || advisors.length === 0)) return

    if (!id) {
      compose({
        recipients: {
          advisors: state?.advisors,
          advisor_uuids: advisor_uuids?.split(','),
          user_uuids: user_uuids?.split(','),
          emails: emails?.split(','),
        },
        draft_from,
        from_request,
        template_uuid,
        drafter_uuid,
        body: useUpdateTemplate ? Templates.updates.body : undefined,
        template: useUpdateTemplate ? 'updates' : undefined,
        templateVariables: {
          Month: updateMonth,
        },
      })
    } else {
      compose({ messageUuid: id })
    }

    history.replace(`/messages/inbox`)
  }, [id, canCreateDraft, advisors])

  return <></>
}

export default Compose
