import React, { useState } from 'react'

import { useMutation } from 'react-query'

import CabalButton from 'global/CabalButton'
import { Select, TextArea } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import message from 'utils/api/message'
import { Team } from 'utils/types'

interface Props {
  onHide: () => void
  teamSlug: string
}

const WelcomeModal: React.FC<Props> = ({ onHide, teamSlug }) => {
  const { team, reloadTeam } = useTeam(teamSlug)
  const [asksContext, setAsksContext] = useState(
    team?.sales_asks_prompt ||
      `Welcome to our Cabal page. Here we’ll share updates and ways to help us win. \n\nTo start, share your connections then click “Offer intro” for contacts you’re willing to introduce. When you offer an intro, we will follow up with custom referral emails for your approval.`,
  )

  const [salesAskPromptUserId, setSalesAskPromptUserId] = useState(
    team?.sales_ask_prompt_user_id || team?.founder.id,
  )

  const updateTeamMutation = useMutation(
    ['updateTeam'],
    (val: Partial<Team>) => callApi(api.updateTeam, teamSlug, { team: val }),
    {
      onSuccess: () => {
        reloadTeam()
        onHide()
      },
    },
  )

  const teamAdminOptions =
    team?.admins_and_members
      .filter((u) => u.team_role === 'admin')
      .map((u) => ({ value: u.uuid, label: u.name })) || []

  return (
    <Modal
      show={true}
      onHide={onHide}
      header={'Welcome message'}
      rightActions={
        <CabalButton
          working={updateTeamMutation.isLoading}
          onClick={() =>
            updateTeamMutation.mutateAsync({
              sales_asks_prompt: asksContext,
              sales_ask_prompt_user_id: salesAskPromptUserId,
              sales_asks_prompt_changed_at: new Date(),
            })
          }
        >
          Save
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Message</ModalInputLabel>

          <TextArea
            className="w-full"
            value={asksContext}
            onChange={(e) => setAsksContext(e.target.value)}
            placeholder="Write a message..."
            rows={8}
            maxLength={280}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>From</ModalInputLabel>

          <Select
            value={salesAskPromptUserId}
            className="w-full"
            onChange={(option) => option && setSalesAskPromptUserId(option)}
            placeholder="Select user"
            options={teamAdminOptions}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default WelcomeModal
