import React, { useState, useEffect } from 'react'

import Modal from 'global/Modal'
import Typography from 'global/Typography'
import styled from 'styled-components'
import tw from 'twin.macro'
import Pill from 'global/Pill'

interface Props {
  show: boolean
  onHide: () => void
}

const Section = styled.div`
  ${tw`py-2 pl-3 pr-2 rounded-lg mb-3`}
  background-color: ${({ theme }) => theme.layout.main_bg_color};
`

const ShortcutColumn = ({ shortcut, label }: { shortcut: string | string[]; label: string }) => {
  return (
    <div className="flex justify-between items-center pb-1">
      <Typography fontSize="14" component="p">
        {label}
      </Typography>
      {/* <Typography fontSize="14" component="p"></Typography> */}
      <div>
        {typeof shortcut === 'object' ? (
          shortcut.map((c) => (
            <Pill className="mr-1" key={c} variant="purple_filled">
              {c}
            </Pill>
          ))
        ) : (
          <Pill className="mr-1" variant="purple_filled">
            {shortcut}
          </Pill>
        )}
      </div>
    </div>
  )
}

const ShortcutsModal: React.VFC<Props> = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} header="Shortcuts" size="sm">
      <Section>
        <Typography fontWeight={600} fontSize="14" color="rain_fog" className="pb-1" component="h5">
          Global Shortcuts
        </Typography>
        <ShortcutColumn label={'Shortcuts list'} shortcut={['⌘', '/']} />
        {/* <ShortcutColumn label={'Search'} shortcut={'/'} /> */}
      </Section>
      <Section>
        <Typography fontWeight={600} fontSize="14" color="rain_fog" className="pb-1" component="h5">
          Member Table Shortcuts
        </Typography>
        <ShortcutColumn label={'Select all'} shortcut={['⌘', 'A']} />
        <ShortcutColumn label={'Add to group'} shortcut={['⌘', 'SHIFT', 'A']} />
        <ShortcutColumn label={'Remove from group'} shortcut={['⌘', 'SHIFT', 'D']} />
        <ShortcutColumn label={'Filter members'} shortcut={['⌘', 'SHIFT', 'F']} />
        <ShortcutColumn label={'Search members'} shortcut={['⌘', 'SHIFT', 'S']} />
      </Section>
      <Section>
        <Typography fontWeight={600} fontSize="14" color="rain_fog" className="pb-1" component="h5">
          Profile
        </Typography>
        <ShortcutColumn label={'Log contribution'} shortcut={['C']} />
        <ShortcutColumn label={'Edit profile'} shortcut={['⌘', 'E']} />
      </Section>
      <Section>
        <Typography fontWeight={600} fontSize="14" color="rain_fog" className="pb-1" component="h5">
          Asks
        </Typography>
        <ShortcutColumn label={'Search lists'} shortcut={['⌘', 'SHIFT', 'L']} />
        <ShortcutColumn label={'Search companies'} shortcut={['⌘', 'SHIFT', 'C']} />
        <ShortcutColumn label={'Copy link'} shortcut={['C']} />
        <ShortcutColumn label={'Edit'} shortcut={['⌘', 'E']} />
        <ShortcutColumn label={'Toggle preview'} shortcut={['P']} />
        <ShortcutColumn label={'Share'} shortcut={['S']} />
        <ShortcutColumn label={'Expand filters'} shortcut={['F']} />
        <ShortcutColumn label={'Update lists'} shortcut={['L']} />
      </Section>
      <Section>
        <Typography fontWeight={600} fontSize="14" color="rain_fog" className="pb-1" component="h5">
          Composer
        </Typography>
        <ShortcutColumn label={'Toggle share'} shortcut={['S']} />
        <ShortcutColumn label={'Toggle insert'} shortcut={['I']} />
        <ShortcutColumn label={'Toggle personalize'} shortcut={['P']} />
        <ShortcutColumn label={'Toggle comments'} shortcut={['C']} />

        <ShortcutColumn label={'Insert jobs'} shortcut={['SHIFT', 'J']} />
        <ShortcutColumn label={'Insert portfolio'} shortcut={['SHIFT', 'P']} />
        <ShortcutColumn label={'Add job'} shortcut={['A', 'J']} />
        <ShortcutColumn label={'Add portfolio'} shortcut={['A', 'P']} />
      </Section>
    </Modal>
  )
}

export default ShortcutsModal
