import axios from 'axios'
import { EmailSnippet } from 'utils/types'

type GetResponse<T> = {
  email_snippets: T[]
}

type PostResponse<T> = {
  email_snippet: T
}

type UpdateResponse<T> =
  | {
      success: boolean
      email_snippet: T
    }
  | { error: string }

type DeleteResponse = {
  success: boolean
}

export default {
  getEmailSnippet: (teamSlug: string) => {
    return axios.get<GetResponse<EmailSnippet>>(`/api/email_snippets`, {
      params: { team_slug: teamSlug },
    })
  },

  createEmailSnippet: (teamSlug: string, emailSnippet: Partial<EmailSnippet>) => {
    return axios.post<PostResponse<EmailSnippet>>(`/api/email_snippets`, {
      team_slug: teamSlug,
      email_snippet: emailSnippet,
    })
  },

  updateEmailSnippet: (teamSlug: string, uuid: string, emailSnippet: Partial<EmailSnippet>) => {
    return axios.put<UpdateResponse<EmailSnippet>>(`/api/email_snippets/${uuid}`, {
      team_slug: teamSlug,
      email_snippet: emailSnippet,
    })
  },

  deleteEmailSnippet: (teamSlug: string, uuid: string) => {
    return axios.delete<DeleteResponse>(`/api/email_snippets/${uuid}`, {
      params: { team_slug: teamSlug },
    })
  },
}
