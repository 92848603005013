import * as React from 'react'

import title from '@ckeditor/ckeditor5-heading/src/title'

import memoizeOne from 'memoize-one'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useDeepCompareMemo } from 'use-deep-compare'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { Select } from 'global/Input'
import { useModal } from 'global/Modal'
import Table, { Column, Row } from 'global/Table'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { TeamUser } from 'utils/types'

import InviteTeammateModal from '../../Members/InviteTeammateModal'

interface Props {
  users: TeamUser[]
  teamSlug: string
}

const TableData = styled.div`
  ${tw`p-3`}
  color: ${({ theme }) => theme.colors.primary};
  border-bottom-color: ${({ theme }) => theme.colors.border};
  border-top: none;
  border-left: none;
  border-right: none;
`

const TableRow = styled.div`
  ${tw`relative`}
  border-bottom: ${({ theme }) => theme.border};
`

const TeamTable: React.VFC<Props> = ({ users, teamSlug }) => {
  const { user: currentUser } = useCurrentUser()
  const [showAddTeammateModal, setShowAddTeammateModal] = React.useState<boolean>(false)
  const { reloadTeam } = useTeam(teamSlug)
  const queryClient = useQueryClient()
  const { showModal } = useModal()

  const renderInfoModal = (resolve) => (
    <InviteTeammateModal
      show={true}
      onHide={() => resolve()}
      teamSlug={teamSlug}
      reload={() => reloadTeam()}
    />
  )

  const {
    mutate,
    variables: updateTeamUserVariables,
    isLoading: updatingTeamUser,
  } = useMutation(
    ['updateTeamUserRole', teamSlug],
    (params: { user: TeamUser; role: string }) =>
      callApi(api.updateTeamUserRole, teamSlug, params.user.uuid, params.role),
    {
      onSuccess: (response) => {
        queryClient.setQueriesData('getTeamUsers', response)
        reloadTeam()
        cabalToast({ style: 'success', content: 'Teammate permissions updated' })
      },
    },
  )

  const { mutate: deleteTeamUser, isLoading: deletingTeamUser } = useMutation(
    ['deleteTeamUser', teamSlug],
    (uuid: string) => callApi(api.deleteTeamUser, teamSlug, uuid),
    {
      onSuccess: (response) => {
        queryClient.setQueriesData('getTeamUsers', response)
        cabalToast({ style: 'success', content: 'Teammate successfully deleted' })
      },
    },
  )

  const onDeleteTeamUser = React.useCallback(
    (user: TeamUser) => {
      if (confirm(`Are you sure that you want to remove ${user.name}?`)) {
        deleteTeamUser(user.uuid)
      }
    },
    [deleteTeamUser],
  )

  const columns: Column<Row>[] = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'team_role',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'delete',
        disableSortBy: true,
      },
    ],
    [],
  )

  const onUserTeamRoleChange = React.useCallback(
    (user: TeamUser, role: string) => {
      mutate({ user, role })
    },
    [mutate],
  )

  const data = useDeepCompareMemo(
    () =>
      users.map<Row<TeamUser>>((user) => {
        return {
          name: (
            <>
              <div className="flex space-x-2.5 truncate">
                <div className="self-center">
                  <Avatar name={user.name} size="32" src={user.avatar_url || undefined} round />
                </div>

                <div className="flex justify-center flex-col gap-1 truncate">
                  <div className="flex items-center gap-1.5 leading-4">
                    <Typography fontSize="14" lineHeight={1} data-testid="datacard-title">
                      {user.name}
                    </Typography>
                  </div>
                  <Typography fontSize="12" lineHeight={'15px'} color={'fog'} className="truncate">
                    {user.email}
                  </Typography>
                  {user.team_role == 'admin' && user.restrict_admin_agreements_access && (
                    <Typography fontSize="12" lineHeight={'15px'} color={'fog'}>
                      Limited admin access
                    </Typography>
                  )}
                </div>
              </div>
            </>
          ),
          team_role:
            currentUser.uuid === user.uuid ? (
              <Typography className="ml-2">
                {user.team_role === 'admin' ? 'Admin' : 'Employee'}
              </Typography>
            ) : (
              <Select
                style={{ maxWidth: 100 }}
                value={user.team_role}
                onChange={(option) => option && onUserTeamRoleChange(user, option)}
                placeholder="Permission level"
                options={[
                  { label: 'Admin', value: 'admin' },
                  { label: 'Employee', value: 'member' },
                ]}
                isLoading={updatingTeamUser && updateTeamUserVariables?.user.uuid === user.uuid}
              />
            ),
          delete:
            currentUser.uuid !== user.uuid ? (
              <div className="text-right">
                <CabalButton
                  variant="tertiary"
                  leftIcon={<i className="far fa-trash" />}
                  working={deletingTeamUser}
                  onClick={() => onDeleteTeamUser(user)}
                />
              </div>
            ) : null,
          data: user,
        }
      }),
    [
      users,
      updatingTeamUser,
      updateTeamUserVariables,
      onUserTeamRoleChange,
      deletingTeamUser,
      onDeleteTeamUser,
      currentUser,
    ],
  )

  return (
    <>
      <Widget
        title="Team"
        className="mt-4"
        cta={
          <CabalButton
            variant="link"
            onClick={() => showModal(renderInfoModal, 'render_info_modal')}
            data-testid="add-employee"
            leftIcon={<i className="far fa-user-plus pr-1" />}
          >
            Add teammates
          </CabalButton>
        }
      >
        <Table columns={columns} data={data} tr={TableRow} td={TableData} />
      </Widget>
    </>
  )
}

export default TeamTable
