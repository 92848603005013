import React from 'react'

import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'

import CandidateProfileModal from './index'

export default function CandidateApply() {
  const [submitted, setSubmitted] = React.useState(false)
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)

  if (!team?.features?.talent_marketplace) return null

  return (
    <div>
      {!submitted && <CandidateProfileModal onFinalize={() => setSubmitted(true)} />}
      {submitted && (
        <div className="py-6 flex flex-col justify-center items-center text-center">
          <Typography fontSize="18" fontWeight={600}>
            Your application was submitted successfully
          </Typography>

          <Typography fontSize="16" fontWeight={400} className="mt-2" color="fog">
            Nothing to see here for now
          </Typography>
        </div>
      )}
    </div>
  )
}
