import React from 'react'

import * as Sentry from '@sentry/react'

import axios from 'axios'
import { H } from 'highlight.run'

import RealTimeUpdates from 'components/RealTimeUpdates'
import { AppContextProvider } from 'context/AppContext'
import { ModalContextProvider } from 'global/Modal/Context'
import { StepsStyles } from 'global/Steps'
import AtomicClasses from 'global/atomic'

import { AppVersionWatcher } from 'utils/appVersionWatcher'
import 'utils/axios-config'

import ActionCableProvider from './Providers/ActionCableProvider'
import ColorModeProvider from './Providers/ColorModeProvider'
import ReactQueryProvider from './Providers/ReactQueryProvider'
import ReactRouterProvider, { history } from './Providers/ReactRouterProvider'
import ReactToastProvider from './Providers/ReactToastProvider'
import ReduxStoreProvider from './Providers/ReduxStoreProvider'
import ThemeProvider from './Providers/ThemeProvider'
import Routes from './Routes'

if (!window.II && window.ENV == 'production') {
  H.init('1ep9y7dn', {
    environment: process.env.NODE_ENV,
    backendUrl: 'https://highlight.getcabal.com',
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
    },
  })

  H.getSessionURL().then((sessionUrl) => {
    Sentry.setContext('highlight', {
      url: sessionUrl,
    })
  })
}

export const appVersionWatcher = new AppVersionWatcher()

const App: React.FC = () => {
  return (
    // keep providers in sync with `app/javascript/utils/test-utils.tsx`
    <ReduxStoreProvider>
      <ReactQueryProvider>
        <AppContextProvider>
          <ReactRouterProvider>
            <ColorModeProvider>
              <ThemeProvider rootElement={document.body}>
                <ReactToastProvider>
                  <ActionCableProvider>
                    <ModalContextProvider>
                      <AtomicClasses />
                      <StepsStyles />
                      <RealTimeUpdates />
                      <Routes history={history} />
                      <div className="suggestions-portal-container" />
                      <div id="calendly-index" />
                    </ModalContextProvider>
                  </ActionCableProvider>
                </ReactToastProvider>
              </ThemeProvider>
            </ColorModeProvider>
          </ReactRouterProvider>
        </AppContextProvider>
      </ReactQueryProvider>
    </ReduxStoreProvider>
  )
}

const store_csrf_token = () => {
  const tokenDom = document.querySelector('meta[name=csrf-token]')
  if (tokenDom) {
    const csrfToken = tokenDom.getAttribute('content')
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  }
}

store_csrf_token()

history.listen(() => {
  store_csrf_token()
})

export default App
