import React from 'react'

import styled from 'styled-components'

import { ExtendedTextInputProps, TextInput } from './TextInput'

const Container = styled.div`
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 8px;
  }
`

export const SearchInput = React.forwardRef<HTMLInputElement, ExtendedTextInputProps>(
  (props, ref) => {
    return (
      <Container>
        <TextInput
          ref={ref}
          clearable={true}
          label={<i className="far fa-search"></i>}
          labelPosition="left"
          autoFocus={props.autoFocus}
          {...props}
        />
      </Container>
    )
  },
)
