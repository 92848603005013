import React from 'react'

import { useParams } from 'react-router-dom'

import { useTeamSlug } from 'store/hooks'

import PageTemplate from '../PageTemplate'

export type ResourcePageSlugTypes = 'vendors' | 'people' | 'deals' | 'resources'

const ResourceListViewPage = () => {
  const params = useParams()
  const teamSlug = useTeamSlug()

  const slugToNameMap = {
    vendors: teamSlug === 'm13' ? 'Preferred Partners' : 'Vendors',
    resources: 'Content',
    people: teamSlug === 'm13' ? 'M13 team' : 'People',
    deals: teamSlug === 'm13' ? 'Offers' : 'Discounts',
  }

  const slug: ResourcePageSlugTypes = params?.slug || ''
  const title = slugToNameMap[slug]

  return (
    <div>
      <PageTemplate offerTypeSlug={slug} pageTitle={title} />
    </div>
  )
}

export default ResourceListViewPage
