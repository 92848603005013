import { ICreateMessageDraftPayload } from 'containers/IntroRequestModal/components/types'
import { ICloseModalPayload, ISalesTarget } from 'containers/IntroRequestModal/types'
import { IntroConnection } from 'containers/Portfolio/MemberView/types/IntroConnection'

import { IRequestor } from '../types'

export const createDraftEmailObject = (
  requestor: IRequestor,
  facilitator: IntroConnection,
  requestable: ISalesTarget,
  messageDraftUuid: string | null | undefined,
  setMessageDraftUuid: (messageUuid: string) => void,
  teamSlug: string,
  messageDraftCreate: ICreateMessageDraftPayload,
  introRequestUuid: string | null | undefined,
  setShowModal: (showModal: boolean) => void,
) => {
  return {
    draftFromName: facilitator.first_name,
    draftToName: requestable?.first_name || requestable.name,
    messageDraftUuid,
    setMessageDraftUuid,
    teamSlug,
    messageDraftCreate,
    introRequestUuid,
    setShowModal,
  }
}

export const createMessageDraft = (
  companyListUuid: string | null,
  facilitatorUuid: string,
  targetUuid: string,
  requestorUuid: string,
): ICreateMessageDraftPayload => {
  return {
    company_list_uuid: companyListUuid,
    facilitator_uuid: facilitatorUuid,
    target_uuid: targetUuid,
    requestor_uuid: requestorUuid,
  }
}

export const closeModals = ({ closeModalFunction, modalName }: ICloseModalPayload): void => {
  closeModalFunction(modalName)
}
