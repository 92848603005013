import axios from 'axios'

import { JobsResponse } from 'utils/types'

export default {
  getCompanyLeverJobs: (
    teamSlug: string,
    page = 1
  ) => {
    return axios.get<JobsResponse>(`/api/integrations/lever`, {
      params: {
        team_slug: teamSlug,
        page
      },
    })
  },
}
