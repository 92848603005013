import React from 'react'

import CabalMessage from 'models/CabalMessage'

import MessageFeedCard from 'components/MessageFeedCard'
import Loading from 'global/Loading'

interface DraftMessageListProps {
  messages?: CabalMessage[]
  onReloadMessages: () => void
  onCompose: (options: { messageUuid?: string; onHide: () => void }) => void
  isLoading: boolean
  isRefetching: boolean
  isFetchingNextPage: boolean
}

const DraftMessageList: React.VFC<DraftMessageListProps> = ({
  messages = [],
  onReloadMessages,
  onCompose,
  isLoading,
  isRefetching,
  isFetchingNextPage,
}) => {
  return (isLoading || isRefetching) && !isFetchingNextPage ? (
    <Loading />
  ) : (
    <>
      {messages.map((message) => (
        <MessageFeedCard
          key={message.uuid}
          canDelete
          message={message}
          onClick={() => onCompose({ messageUuid: message.uuid, onHide: onReloadMessages })}
          reloadMessages={onReloadMessages}
          showPrivacy
        />
      ))}
    </>
  )
}

export default DraftMessageList
