import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSetState } from 'react-use'

import AcceptContent from 'containers/AdvisorInviteAccept/AcceptContent'
import CabalButton from 'global/CabalButton'
import {
  CheckBox,
  Label,
  Select,
  TextInput,
  UploadButton,
  NumberFormattedInput,
  DatePickerModal,
} from 'global/Input'
import Loading from 'global/Loading'
import Modal from 'global/Modal'
import { ModalContext, RenderModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useAdvisors, useCurrentUser, useTeam } from 'store/hooks'
import api, { callApi } from 'utils/api'
import { US_STATE_NAMES } from 'utils/constants/ui'
import { AdvisorModel, AgreementModel, EquityFieldsType, EquityTierEntity } from 'utils/types'
import { isEqual, sortBy, uniqBy } from 'lodash'
import { cabalToast } from 'ui-components/Toast'
import TextEditor from 'global/TextEditor'
import {
  agreementMessageVariables,
  replaceAgreementMessageVariables,
} from 'utils/constants/agreement'
import EmailPreviewModal from 'components/EmailPreviewModal'
import PreviewAgreementModal from 'components/PreviewAgreementModal'
import TierPills from './TierPills'
import DropDownMenu from 'global/DropDownMenu'

interface Props {
  advisor?: AdvisorModel
  agreement?: AgreementModel
  teamSlug: string
  onDone: () => void
  loadAgreements?: () => Promise<unknown>
}

const l = (str: string) => {
  if (str.includes('Sender')) {
    str = str.replace('Sender', 'Your')
  }

  switch (str) {
    case 'Amount Invested':
      return 'Investment Amount'
    case 'Valuation':
      return 'Valuation Cap'
    default:
      return str
  }
}

const SendAgreementModal: React.VFC<Props> = ({
  advisor: _advisor,
  agreement,
  teamSlug,
  onDone,
  loadAgreements,
}) => {
  const { user: currentUser, reloadUser } = useCurrentUser()
  const { team, reloadTeam } = useTeam(teamSlug)
  const { advisors } = useAdvisors({ teamSlug: teamSlug })

  const { showModal } = useContext(ModalContext)
  const queryClient = useQueryClient()

  const [sending, setSending] = useState(false)
  const [advisor, setAdvisor] = useState(_advisor)
  const [selectedAgreementTemplateId, setSelectedAgreementTemplateId] = useState<string>()
  const [senderUuid, setSenderUuid] = useState(currentUser.uuid)
  const [agreementParams, setAgreementParams] = useSetState<Record<string, any>>({})
  const [editInviteMailMessage, setEditInviteMailMessage] = useState(
    !!agreement?.invite_mail_message,
  )
  const [editInvitePageMessage, setEditInvitePageMessage] = useState(
    !!agreement?.invite_page_message,
  )
  const user = team?.admins_and_members.find((f) => f.uuid === senderUuid)
  const currentUserSending = senderUuid === currentUser.uuid

  useEffect(() => {
    const params: Record<string, string> = {}
    if (agreement?.invite_page_message) params.invite_page_message = agreement.invite_page_message
    if (agreement?.invite_mail_message) params.invite_mail_message = agreement.invite_mail_message
    setAgreementParams(params)
  }, [agreement])

  const { data, isFetching } = useQuery(
    ['agreementTemplates', team!.slug, advisor?.uuid, senderUuid],
    () => callApi(api.getAgreementTemplates, team!.slug, advisor?.uuid, senderUuid),
    {
      enabled: !!advisor && !!team,
    },
  )
  const agreementTemplates = data?.agreement_templates
  const agreementTemplate = selectedAgreementTemplateId
    ? agreementTemplates?.find((at) => at.id === selectedAgreementTemplateId)
    : undefined

  React.useEffect(() => {
    if (agreement && agreementTemplates) {
      const updatedAgreementParams: Record<string, any> = {}
      agreement.fields?.forEach((f) => {
        updatedAgreementParams[f.uuid] = f.value
      })
      if (!isEqual(agreementParams, updatedAgreementParams)) {
        setAgreementParams(updatedAgreementParams)
      }

      const agreementTemplateId = agreementTemplates.find(
        (at) => at.id === agreement.agreement_template.id,
      )?.id
      agreementTemplateId && setSelectedAgreementTemplateId(agreementTemplateId)
    } else if (agreementTemplate) {
      agreementTemplate.fields?.forEach((f) => {
        setAgreementParams({ [f.uuid]: f.value })
      })
    }
  }, [agreement, agreementTemplates, agreementTemplate])

  const createAgreementMutation = useMutation((send: boolean) =>
    callApi(api.createAgreement, {
      team_slug: team!.slug,
      advisor_uuid: advisor!.uuid,
      agreement_template_id: agreementTemplate!.id,
      agreement_uuid: agreement?.uuid,
      agreement_params: agreementParams,
      sender_uuid: senderUuid,
      send,
    }),
  )

  const fieldsRequiringInput = uniqBy(
    agreementTemplate?.fields?.filter((f) => f.for === 'sender' && f.can_input) || [],
    (f) => f.shortcode,
  )
  const renderFieldsRequiringInput = sortBy(fieldsRequiringInput || [], (a) => {
    if (a.default_value?.includes('advisor')) return -3
    if (a.default_value?.includes('agreement')) return -2
    if (a.default_value?.includes('team')) return -1
    return 0
  }).map((f) => {
    let input
    let disabled = currentUserSending ? false : f.default_value?.startsWith('sender')
    if (f.default_value === 'sender.email' && f.overwrite_default_value === true) {
      disabled = true
    }
    if (f.default_value === 'agreement.vesting_period') {
      input = (
        <>
          <Label>Vesting Period (in months)</Label>
          <Select<number>
            portal
            value={agreementParams[f.uuid]}
            onChange={(v) => v && setAgreementParams({ [f.uuid]: v.toString() })}
            options={[12, 24, 36, 48, 72, 96].map((n) => ({
              value: n,
            }))}
            creatable
            onCreateOption={(v) => {
              if (Number.isNaN(Number(v))) {
                alert('Invalid input, please enter numeric only')
                return
              }
              setAgreementParams({ [f.uuid]: v })
            }}
            formatOptionLabel={(v) => `${v.value} month${v.value !== 1 ? 's' : ''}`}
            disabled={disabled}
          />
        </>
      )
    } else if (f.default_value === 'team.state_of_incorporation') {
      input = (
        <>
          <Label>Company{`'`}s State of Incorporation</Label>
          <Select<string>
            portal
            value={agreementParams[f.uuid]}
            onChange={(v) => v && setAgreementParams({ [f.uuid]: v })}
            options={US_STATE_NAMES.map((s) => ({
              value: s,
            }))}
            disabled={disabled}
          />
        </>
      )
    } else if (f.data_type === 'string') {
      input = (
        <TextInput
          required
          labeled
          value={agreementParams[f.uuid]}
          onChange={(e) => setAgreementParams({ [f.uuid]: e.currentTarget.value })}
          placeholder={l(f.name)}
          disabled={disabled}
        />
      )
    } else if (f.data_type === 'text') {
      input = (
        <TextInput
          required
          component="textarea"
          labeled
          value={agreementParams[f.uuid]}
          onChange={(e) => setAgreementParams({ [f.uuid]: e.currentTarget.value })}
          placeholder={l(f.name)}
          disabled={disabled}
        />
      )
    } else if (f.data_type === 'checkbox') {
      input = (
        <>
          <Label>{f.name}</Label>
          <CheckBox
            checked={agreementParams[f.uuid] === 'checked'}
            onChange={(e) =>
              setAgreementParams({ [f.uuid]: e.currentTarget.checked ? 'checked' : 'unchecked' })
            }
            placeholder={l(f.name)}
            disabled={disabled}
          />
        </>
      )
    } else if (f.data_type === 'amount' || f.data_type === 'number') {
      input = (
        <>
          <NumberFormattedInput
            required
            labeled
            value={agreementParams[f.uuid]}
            prefix={f.data_type === 'amount' ? '$' : undefined}
            onChange={(e, valid) =>
              setAgreementParams({ [f.uuid]: valid ? Number(e.currentTarget.value) : undefined })
            }
            nonZero
            placeholder={l(f.name)}
            disabled={disabled}
          />
        </>
      )
    } else if (f.data_type === 'date') {
      input = (
        <>
          <Label>{l(f.name)}</Label>
          <DatePickerModal
            id={f.uuid.toString()}
            date={agreementParams[f.uuid]}
            placeholder={f.name}
            onDateChange={(newDate) => setAgreementParams({ [f.uuid]: newDate })}
            className="w-full"
            disabled={disabled}
          />
        </>
      )
    } else {
      const isSignature = f.default_value?.includes('signature')
      input = (
        <>
          <Label>{f.name}</Label>
          {agreementParams[f.uuid] && (
            <img src={`/api/uploads/${agreementParams[f.uuid]}`} className="max-w-lg" />
          )}
          {!disabled && (
            <UploadButton
              onUpload={(uuid) => setAgreementParams({ [f.uuid]: uuid })}
              canDrawSignature={isSignature}
              canUpload={!isSignature}
            />
          )}
        </>
      )
    }

    return (
      <div key={f.uuid} className="mb-base">
        {input}
      </div>
    )
  })

  const agreementSpecificFields = () => {
    const fields: Partial<EquityFieldsType> = {}

    for (const fieldId in agreementParams) {
      const field = agreementTemplate?.fields.find((f) => f.uuid === fieldId)
      const fieldValueName = field?.default_value?.split('.')[1]
      if (!field || !fieldValueName) continue

      fields[fieldValueName as keyof EquityFieldsType] = agreementParams[fieldId]
    }

    return fields
  }

  const renderInvitationPreview: RenderModal = (resolve) => {
    if (!user) return <></>

    return (
      <Modal
        show
        onHide={resolve}
        header={'Invitation Preview'}
        leftActions={
          <CabalButton onClick={() => resolve()} leftIcon={<i className="fas fa-caret-left" />}>
            Back
          </CabalButton>
        }
      >
        <AcceptContent
          companyName={team!.name}
          logo={team!.logo}
          sender={user}
          invitePageMessage={replaceAgreementMessageVariables(
            agreementParams.invite_page_message || agreementTemplate!.invite_page_message!,
            advisor!,
            agreementSpecificFields(),
            user,
            team!,
          )}
          isPreview
        />
      </Modal>
    )
  }

  const renderInviteEmailPreview: RenderModal = (resolve) =>
    user ? (
      <EmailPreviewModal
        resolve={resolve}
        advisor={advisor}
        inviteMessage={replaceAgreementMessageVariables(
          agreementParams.invite_mail_message || agreementTemplate!.invite_mail_message!,
          advisor!,
          agreementSpecificFields(),
          user,
          team!,
        )}
      />
    ) : (
      <></>
    )

  const createAgreement = async (send: boolean) => {
    if (!advisor) return

    setSending(send)
    await createAgreementMutation.mutateAsync(send)
    loadAgreements?.()
    cabalToast({
      style: 'success',
      content: `Successfully ${send ? 'sent' : 'saved'} the agreement!`,
    })
    reloadTeam()
    reloadUser()
    queryClient.refetchQueries({
      queryKey: [team!.slug, 'advisor', advisor.uuid],
    })
    onDone()
  }

  const onTierSelect = (tier: EquityTierEntity) => {
    const updates: any = {}

    const grantedSharesId = fieldsRequiringInput?.find((f) =>
      f.default_value?.includes('granted_shares'),
    )?.uuid
    const vestingPeriodId = fieldsRequiringInput?.find((f) =>
      f.default_value?.includes('vesting_period'),
    )?.uuid

    if (grantedSharesId) {
      updates[grantedSharesId] = tier.shares
    }

    if (vestingPeriodId) {
      updates[vestingPeriodId] = tier.vesting_period
    }

    setAgreementParams(updates)
  }

  const missingSenderData = fieldsRequiringInput?.filter(
    (f) => f.default_value?.startsWith('sender') && !agreementParams[f.uuid],
  )
  const canSubmit = fieldsRequiringInput?.every((f) => !!agreementParams[f.uuid])

  const hasEquityGrant = fieldsRequiringInput?.some(
    (f) => f.default_value === 'agreement.granted_shares',
  )

  const agreementPdfPreviewUrl = useMemo(() => {
    if (!team || !advisor) return ''
    const url = new URL(window.location.origin)
    url.pathname = `/api/agreement_templates/${agreementTemplate?.id}.pdf`
    url.search = new URLSearchParams({
      agreement_params: JSON.stringify(agreementParams),
      team_slug: team.slug,
      advisor_uuid: advisor.uuid,
    }).toString()
    return url.toString()
  }, [agreementParams, agreementTemplate, team, advisor])

  const advisorSelection = (
    <div className="mb-base">
      <Label label="Advisor">
        <Select<AdvisorModel>
          value={advisor}
          onChange={(a) => a && setAdvisor(a)}
          options={
            advisors?.map((a) => ({
              label: `${a.name} - ${a.email}`,
              value: a,
            })) || []
          }
        />
      </Label>
    </div>
  )

  let modalContent = <>{team && advisorSelection}</>

  if (advisor) {
    modalContent =
      isFetching || !agreementTemplates ? (
        <Loading className="my-6" />
      ) : (
        <>
          {!_advisor && advisorSelection}

          <div className="mb-base">
            <Label label="Agreement Type">
              <Select<string>
                disabled={!!agreement?.uuid}
                options={agreementTemplates.map((at) => ({
                  label: at.name,
                  value: at.id,
                }))}
                value={selectedAgreementTemplateId}
                portal
                onChange={(v) => v && setSelectedAgreementTemplateId(v)}
              />
            </Label>
          </div>

          {hasEquityGrant && <TierPills teamSlug={team!.slug} onSelect={onTierSelect} />}
          {!currentUserSending && missingSenderData.length > 0 ? (
            <Typography color="yellow_bold">
              The selected sender is missing the following fields: <br />
              {missingSenderData.map((f) => f.name).join(', ')}
              <br />
              <Typography fontWeight={600}>
                Please ask the sender to add these details to proceed further
              </Typography>
              <br />
              <br />
            </Typography>
          ) : (
            renderFieldsRequiringInput
          )}

          {agreementTemplate?.invite_mail_message && (
            <div className="mb-2">
              {!editInviteMailMessage && (
                <button onClick={() => setEditInviteMailMessage(true)}>
                  <Typography color="fog_rain" fontSize="12">
                    Edit Email Notification
                  </Typography>
                </button>
              )}
              {editInviteMailMessage && (
                <>
                  <Label component="span">
                    Edit Email Notification
                    <button
                      onClick={() => {
                        if (!confirm('Do you want to discard the changes?')) return
                        setAgreementParams({
                          invite_mail_message: null,
                        })
                        setEditInviteMailMessage(false)
                      }}
                      className="float-right"
                    >
                      <i className="far fa-trash" /> Discard
                    </button>
                  </Label>
                  <TextEditor
                    allowVariables
                    variables={agreementMessageVariables}
                    value={
                      agreementParams.invite_mail_message || agreementTemplate.invite_mail_message
                    }
                    onChange={(v) => setAgreementParams({ invite_mail_message: v })}
                  />
                </>
              )}
            </div>
          )}

          {agreementTemplate?.invite_page_message && (
            <div className="mb-2">
              {!editInvitePageMessage && (
                <button onClick={() => setEditInvitePageMessage(true)}>
                  <Typography color="fog_rain" fontSize="12">
                    Edit Invitation Page
                  </Typography>
                </button>
              )}
              {editInvitePageMessage && (
                <>
                  <Label component="span">
                    Edit Invitation Page
                    <button
                      onClick={() => {
                        if (!confirm('Do you want to discard the changes?')) return
                        setAgreementParams({
                          invite_page_message: null,
                        })
                        setEditInvitePageMessage(false)
                      }}
                      className="float-right"
                    >
                      <i className="far fa-trash" /> Discard
                    </button>
                  </Label>
                  <TextEditor
                    allowVariables
                    variables={agreementMessageVariables}
                    value={
                      agreementParams.invite_page_message || agreementTemplate.invite_page_message
                    }
                    onChange={(v) => setAgreementParams({ invite_page_message: v })}
                  />
                </>
              )}
            </div>
          )}
        </>
      )
  }

  return (
    <Modal
      show
      onHide={onDone}
      header={`Send agreement${advisor ? ` to ${advisor.name}` : ''}`}
      hasChildModals
      leftActions={
        agreementTemplate && (
          <>
            <DropDownMenu
              portal={false}
              needScrollIntoView
              disabled={!canSubmit}
              menuItems={[
                agreementTemplate?.invite_page_message
                  ? {
                      label: 'Invite Page',
                      onSelect: () => showModal(renderInvitationPreview, 'invite-preview'),
                    }
                  : null,
                agreementTemplate?.invite_mail_message
                  ? {
                      label: 'Invite Mail',
                      onSelect: () => showModal(renderInviteEmailPreview, 'invite-email-preview'),
                    }
                  : null,
                {
                  label: 'Agreement PDF',
                  onSelect: () =>
                    showModal(
                      (resolve) => (
                        <PreviewAgreementModal onHide={resolve} url={agreementPdfPreviewUrl} />
                      ),
                      'agreement-preview',
                    ),
                },
              ]}
              trigger={
                <CabalButton
                  variant="secondary"
                  disabled={!canSubmit}
                  rightIcon={<i className="fas fa-caret-down" />}
                  component="span"
                >
                  Preview
                </CabalButton>
              }
            />
          </>
        )
      }
      rightActions={
        agreementTemplate && (
          <>
            <CabalButton
              disabled={!canSubmit}
              onClick={() => createAgreement(false)}
              working={createAgreementMutation.isLoading && !sending}
              variant="secondary"
              className="mr-3"
            >
              Save
            </CabalButton>
            <CabalButton
              disabled={!canSubmit}
              leftIcon={<i className="far fa-paper-plane" />}
              onClick={() => createAgreement(true)}
              working={createAgreementMutation.isLoading && sending}
            >
              {createAgreementMutation.isLoading && sending
                ? 'Sending Agreement'
                : 'Send Agreement'}
            </CabalButton>
          </>
        )
      }
    >
      {modalContent}
    </Modal>
  )
}

export default SendAgreementModal
