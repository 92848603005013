import cx from 'classnames'
import { compact, concat, isEqual, last, pick } from 'lodash'
import CabalMessage from 'models/CabalMessage'
import moment from 'moment'
import objectHash from 'object-hash'
import React, { HTMLProps, useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import CreateComment from 'components/CommentsAndActivity/CreateComment'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { Select } from 'global/Input'
import RichTextInput, { RichTextInputRef } from 'global/Input/RichTextInput'
import Loading from 'global/Loading'
import { MentionSuggestion } from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'
import { useAdvisors, useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { ActivityModel, Comment as CommentType, UserBlueprint } from 'utils/types'

import Activity from './Activity'
import Comment from './Comment'

const CommentsContainer = styled.div`
  ${tw`flex flex-col gap-1`}

  overflow: auto;
`

interface Props extends HTMLProps<HTMLDivElement> {
  message?: CabalMessage
  messageThreadId?: string | null
  externalId?: string
  externalType?: string
  teamSlug: string
  attachableType?: 'GmailThread' | 'InboxThread' | 'Message' | 'GlobalPerson' | 'Company'
  attachableId?: string | number
}

const CommentsAndActivity: React.VFC<Props> = ({
  message,
  externalId,
  externalType,
  teamSlug,
  messageThreadId: _messageThreadId = null,
  attachableId,
  attachableType,
  ...restProps
}) => {
  const { canViewInternalComments } = useAccessControl(teamSlug)
  const [messageThreadUuid, setMessageThreadUuid] = useState<string | null>(null)
  const commentsContainerRef = useRef<HTMLDivElement>(null)
  const [toggle, setToggle] = useState(true)
  const [newCommentInternal, setNewCommentInternal] = useState<boolean>()

  // hack to know what message's comments are loaded
  useEffect(() => {
    window.comments_loaded_for = message?.uuid

    return () => {
      window.comments_loaded_for = null
    }
  }, [message?.uuid])

  const {
    data: commentsData,
    isLoading: isLoadingComments,
    refetch: refetchComments,
  } = useQuery(
    [
      teamSlug,
      'getComments',
      message?.uuid ||
        messageThreadUuid ||
        attachableId + (attachableType || '') ||
        externalId + (externalType || ''),
    ],
    () =>
      callApi(api.getComments, {
        team_slug: teamSlug,
        message_uuid: message?.uuid,
        message_thread_uuid: messageThreadUuid,
        external_id: externalId,
        external_type: externalType,
        attachable_id: attachableId,
        attachable_type: attachableType,
      }),
    {
      refetchInterval: 60000,
      onSuccess: ({ comments }) => {
        canViewInternalComments && setNewCommentInternal(!!last(comments)?.internal)
      },
    },
  )

  useEffect(() => {
    setMessageThreadUuid(_messageThreadId)
  }, [_messageThreadId])

  const comments = commentsData?.comments || []
  const activity = message?.activity || []

  const commentsAndActivity = [
    ...comments.map((c) => ({ ...c, type: 'comment' })),
    ...activity.map((a) => ({ ...a, type: 'activity' })),
  ].sort((a, b) => moment(a.created_at).unix() - moment(b.created_at).unix())

  useEffect(() => {
    commentsContainerRef.current?.scrollTo({ top: commentsContainerRef.current.scrollHeight })
  }, [comments.length])

  useHotkeys(
    'c',
    (e) => {
      e.preventDefault()
      setToggle(!toggle)
    },
    [toggle],
  )

  return (
    <div {...restProps} className={cx(restProps.className, 'flex flex-col h-full')}>
      <div className="flex-1 overflow-auto">
        {isLoadingComments && <Loading size={25} className="mb-4" />}

        <CommentsContainer ref={commentsContainerRef}>
          {!commentsAndActivity.length && (
            <Typography className="text-center" fontSize="13" color="fog">
              No comments
            </Typography>
          )}
          {commentsAndActivity.map((c) =>
            c.type === 'comment' ? (
              <Comment
                key={c.type + ' ' + c.created_at}
                comment={c as CommentType}
                teamSlug={teamSlug}
              />
            ) : (
              <Activity key={c.type + ' ' + c.created_at} activity={c as ActivityModel} />
            ),
          )}
        </CommentsContainer>
      </div>

      <CreateComment
        refetchComments={refetchComments}
        createProps={{
          message_uuid: message?.uuid,
          message_thread_uuid: messageThreadUuid,
          external_id: externalId,
          external_type: externalType,
          attachable_id: attachableId,
          attachable_type: attachableType,
        }}
        newCommentInternal={newCommentInternal}
        teamSlug={teamSlug}
        className="flex-shrink"
      />
    </div>
  )
}

export default React.memo(
  CommentsAndActivity,
  ({ message: prevMessage, ...prevProps }, { message: nextMessage, ...nextProps }) => {
    return isEqual(
      { ...pick(prevMessage, ['uuid', 'activity']), ...prevProps },
      { ...pick(nextMessage, ['uuid', 'activity']), ...nextProps },
    )
  },
)
