import { compact } from 'lodash'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import GlobalCompanySelect from 'components/GlobalCompanySelect'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { Select, TextArea } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Pill from 'global/Pill'
import Typography from 'global/Typography'
import { useAdvisors, useTeams } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import { LOGGED_ACTIVITY_TYPES } from 'utils/constants/activity_types'
import { Activity, GlobalCompanyBlueprint } from 'utils/types'

interface Props {
  show: boolean
  onHide: () => void
  teamSlug: string
  advisorView?: boolean
  advisorUuid?: string
  refetchActivity: () => void
}

const getIcon = (type: Activity['activity_type']) => {
  switch (type) {
    case 'sales':
      return <i className="fa-regular fa-handshake-simple mr-1"></i>
    case 'marketing':
      return <i className="fa-regular fa-retweet mr-1"></i>
    case 'other':
      return <i className="fa-regular fa-circle-ellipsis mr-1"></i>
    default:
      return <i className="fa-regular fa-circle-ellipsis mr-1"></i>
  }
}

const DetailsLabel: React.FC<{ title: string; avatar_url?: string }> = ({ title, avatar_url }) => {
  return (
    <Typography>
      <Avatar size="16" src={avatar_url} name={title} className="mr-2" />
      {title}
    </Typography>
  )
}

const ActivityTrackingModal: React.VFC<Props> = ({
  show,
  onHide,
  advisorView = false,
  advisorUuid,
  teamSlug,
  refetchActivity,
}) => {
  const history = useHistory()

  const { teams } = useTeams()
  const [advisorId, setAdvisorId] = useState(advisorUuid)
  const { advisors } = useAdvisors({ teamSlug })

  const [activityType, setActivityType] = useState<Activity['activity_type']>('sales')
  const [selectedTeamSlug, setSelectedTeamSlug] = useState(teamSlug)
  const [description, setDescription] = useState('')
  const [companies, setCompanies] = useState<Record<number, GlobalCompanyBlueprint | undefined>>({})

  const allowedTeams = teams.filter(
    (team) => team.permissions.isAdvisor || team.permissions.isAdminOrEmployee,
  )

  const { mutate: sendUserActivity, isLoading } = useMutation(
    ['sendUserActivity'],
    () =>
      api.sendUserActivity({
        team_slug: teamSlug!,
        target_team_slug: selectedTeamSlug,
        name: activityType,
        advisor_uuid: advisorId,
        description: description,
        global_company_uuids: compact(Object.values(companies).map((company) => company?.uuid)),
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Contribution was added successfully.' })
        refetchActivity()
        onHide()
        if (advisorView) history.push('activity')
      },
    },
  )

  return (
    <Modal
      onHide={onHide}
      show={show}
      header={'New Contribution'}
      rightActions={
        <CabalButton
          disabled={!activityType || !selectedTeamSlug}
          onClick={() => sendUserActivity()}
          working={isLoading}
        >
          Save contribution
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Who made the contribution?</ModalInputLabel>
          <Select
            value={advisorId}
            className="w-full"
            onChange={(e) => e && setAdvisorId(e)}
            placeholder="Add member"
            options={
              advisors
                ? advisors.map((a) => ({
                    label: compact([a.name, a.email]).join(' - '),
                    value: a.uuid,
                  }))
                : []
            }
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>What company did they help?</ModalInputLabel>

          <Select
            className="w-full"
            value={selectedTeamSlug}
            onChange={(e) => e && setSelectedTeamSlug(e)}
            placeholder="Add company"
            options={allowedTeams.map((c) => ({
              label: c.name,
              value: c.slug,
            }))}
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>What did they help with?</ModalInputLabel>
          <div className="flex flex-wrap justify-center sm:justify-start flex-grow-0">
            {LOGGED_ACTIVITY_TYPES.map((activity) => (
              <Pill
                className="mr-2 cursor-pointer mb-1"
                key={activity.value}
                variant={'purple_filled'}
                onClick={() => setActivityType(activity.value)}
                active={activityType === activity.value}
              >
                <Typography className="flex items-center">
                  {getIcon(activity.value)}
                  {activity.label}
                </Typography>
              </Pill>
            ))}
          </div>
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>Description</ModalInputLabel>
          <TextArea
            value={description}
            onChange={(e) => e && setDescription(e.target.value)}
            maxLength={280}
            className="block w-full"
            placeholder={'Describe the contribution'}
            rows={6}
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>Add details</ModalInputLabel>
          <div className={'flex flex-col'}>
            <div className="flex flex-wrap gap-2">
              {!!companies &&
                Object.entries(companies).map(([_idx, company]) => {
                  const idx = parseInt(_idx)
                  return (
                    <GlobalCompanySelect
                      key={`global-company-select-${idx}`}
                      value={company}
                      minWidth={company ? 'fit-content' : '250px'}
                      noDropdownIndicator={true}
                      onChange={(e) => {
                        if (e === null) {
                          const updatedCompany = { ...companies }
                          delete updatedCompany[idx]
                          setCompanies(updatedCompany)
                        } else {
                          setCompanies({ ...companies, [idx]: e })
                        }
                      }}
                      placeholder="Add company"
                      isClearable={true}
                      compact={true}
                    />
                  )
                })}
            </div>
            <div className="flex space-x-4">
              <Typography
                component="p"
                color={'purple'}
                fontSize="12"
                className="cursor-pointer"
                onClick={() =>
                  setCompanies({ ...companies, [Object.keys(companies).length]: undefined })
                }
                fontFamily="mono"
              >
                + Add Companies
              </Typography>
            </div>
          </div>
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default ActivityTrackingModal
