import React, { useEffect } from 'react'

import pluralize from 'pluralize'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import EditGroupsModal from 'containers/Groups/EditGroupsModal'
import { useModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useGroups } from 'store/hooks'

import { GroupModel } from 'utils/types'

import CreateOption from '../CreateOption'
import Options from '../Options'

const CreateWrapper = styled.div`
  background-color: ${({ theme }) => theme.layout.main_bg_color};
`

const DefaultGroups: { [key: string]: JSX.Element | undefined } = {
  All: <i className="far fa-people-group" />,
  Advisors: <i className="far fa-user" />,
  Friends: <i className="far fa-handshake" />,
  Investors: <i className="far fa-briefcase" />,
}

interface Props {
  teamSlug: string
}

const PinnedGroupsSection: React.FC<Props> = ({ teamSlug }) => {
  const { groups, reloadGroups } = useGroups(teamSlug)
  const location = useLocation()
  const sortOrder = ['All', 'Advisors', 'Friends', 'Investors']

  const { showModal } = useModal()

  useEffect(() => {
    if (location.hash === '#new') {
      window.location.hash = ''
      showModal((resolve) => renderGroupEditModal(resolve, undefined), 'render-group-modal')
    }
  }, [])

  const renderGroupEditModal = (resolve: (value: boolean) => void, group?: GroupModel) => (
    <EditGroupsModal
      onHide={() => resolve(false)}
      onSave={() => {
        reloadGroups()
      }}
      onDelete={() => {
        reloadGroups()
        resolve(false)
      }}
      teamSlug={teamSlug!}
      selectedGroup={group}
    />
  )

  const defaultGroups = groups
    ?.filter((group) => DefaultGroups[group.name])
    .sort((a, b) => sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name))

  return (
    <CreateWrapper className="py-4">
      <div className="grid grid-cols-5 gap-2 px-2">
        {defaultGroups?.map((group) => (
          <Options
            active={false}
            key={group.uuid}
            title={group.name}
            onClick={() =>
              showModal((resolve) => renderGroupEditModal(resolve, group), 'render-group-modal')
            }
            icon={DefaultGroups[group.name]}
            description={
              <Typography color="purple" className="ml-0.5">
                {group.advisor_groups_count} {pluralize('member', group.advisor_groups_count)}
              </Typography>
            }
          />
        ))}
        <CreateOption
          title="Create New Group"
          onClick={() =>
            showModal((resolve) => renderGroupEditModal(resolve, undefined), 'render-group-modal')
          }
          dataTestid={'create-new-list-pill'}
        />
      </div>
    </CreateWrapper>
  )
}

export default PinnedGroupsSection
