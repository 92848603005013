import React, { useState } from 'react'
import styled, { StyledComponentPropsWithRef } from 'styled-components'
import tw from 'twin.macro'
import cx from 'classnames'

import Typography from 'global/Typography'
import ComposerSidebarBaseSection from './SidebarBaseSection'
import Avatar from 'global/Avatar'
import Tooltip from 'global/Tooltip'

const Wrapper = styled.div<{ collapsed: boolean }>`
  ${tw`flex flex-col p-1 content-center items-center border-l`}

  border-color: ${({ theme }) => theme.layout.main_bg_color};

  width: 20vw;
  ${({ collapsed }) => collapsed && tw`w-min text-center`}
`

const Divider = styled.div`
  ${tw`my-1 w-full`}

  height: 1px;
  background-color: ${({ theme }) => theme.layout.main_bg_color};
`

const AvatarContainer = styled(Tooltip)<{ color: string }>`
  .sb-avatar > div,
  .sb-avatar > img {
    ${tw`border border-2`}

    border-color: ${({ color }) => color};
  }

  .avatar-underscore {
    ${tw`mt-0.5 h-0.5`}

    background-color: ${({ color }) => color};
  }
`

const CollapsedActiveUsers = styled.div`
  ${tw`w-6 h-6 items-center content-center flex rounded-full`}

  background-color: ${({ theme }) => theme.colors.green};
`

export interface ComposerSidebarBaseProps {
  onCollapseToggle?: (collapse: boolean) => void
  sections: {
    icon: React.ReactNode
    title: React.ReactNode
    id: string
    content: React.ReactNode
  }[]
  activeUsers?: {
    id: string
    color: string
    name: string
    avatar?: string
    email: string
  }[]
  onHide: () => void
  defaultSection: string
}

const ComposerSidebarBase: React.VFC<
  ComposerSidebarBaseProps & StyledComponentPropsWithRef<'div'>
> = ({ onCollapseToggle, defaultSection, sections, onHide, activeUsers, ...extraProps }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [expandedSection, setExpandedSection] = useState(defaultSection)

  const handleCollapse = () => {
    setCollapsed(!collapsed)
    onCollapseToggle?.(!collapsed)
    setExpandedSection('')
  }

  return (
    <Wrapper collapsed={collapsed} {...extraProps}>
      <div
        className={cx(
          'flex justify-between w-full expand-section',
          { 'flex-col-reverse mt-2': collapsed },
          collapsed ? 'gap-1' : 'gap-3 pr-3',
        )}
      >
        <Tooltip label={collapsed ? 'Expand Sidebar' : ''}>
          <Typography
            component="button"
            color="fog"
            fontSize="12"
            className="flex flex-grow gap-2 items-center p-2 w-full whitespace-nowrap"
            onClick={handleCollapse}
          >
            <i className={cx('far', collapsed ? 'fa-arrow-to-left' : 'fa-arrow-to-right')} />
            {!collapsed && 'Collapse Sidebar'}
          </Typography>
        </Tooltip>
        <Typography component="button" color="fog" fontSize="12" onClick={onHide}>
          <i className="far fa-times" />
        </Typography>
      </div>
      <Divider />
      {activeUsers && collapsed && (
        <>
          <Tooltip label={`${activeUsers.length} active user(s)`}>
            <CollapsedActiveUsers>
              <Typography fontWeight={500} fontSize="12" className="flex-1">
                {activeUsers.length}
              </Typography>
            </CollapsedActiveUsers>
          </Tooltip>
          <Divider />
        </>
      )}
      {activeUsers && !collapsed && (
        <div className="flex flex-wrap gap-1.5 mb-3 mt-1">
          {activeUsers.map((user) => (
            <AvatarContainer
              label={user.name + (user.email ? ` (${user.email})` : '')}
              key={user.id}
              color={user.color}
            >
              <Avatar size="30px" src={user.avatar} name={user.name} />
              <div className="avatar-underscore" />
            </AvatarContainer>
          ))}
        </div>
      )}
      {!!sections.length &&
        sections
          .map<React.ReactNode>((s) => (
            <ComposerSidebarBaseSection
              expanded={s.id === expandedSection}
              setExpanded={setExpandedSection}
              uncollpaseSidebar={() => setCollapsed(false)}
              sidebarCollapsed={collapsed}
              key={s.id}
              {...s}
            />
          ))
          .reduce((prev, curr, i) => [prev, <Divider key={`${i}-sidebar-divider`} />, curr])}
    </Wrapper>
  )
}

export default ComposerSidebarBase
