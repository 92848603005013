import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { drop, first } from 'lodash'
import cx from 'classnames'

import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import CabalMessage from 'models/CabalMessage'
import api, { callApi } from 'utils/api'
import emoji from 'react-easy-emoji'
import InboxMessage from './InboxMessage'
import Collaborators from 'components/Collaborators'
import { useTeamSlug } from 'store/hooks'
import CommentsAndActivity from 'components/CommentsAndActivity'
import InboxMessageHeader from './InboxMessageHeader'

const ViewInboxThread: React.VFC = () => {
  const { thread_id } = useParams<{ thread_id: string }>()
  const teamSlug = useTeamSlug()

  const getInboxThreadQuery = useQuery(['getInboxThread', thread_id], () =>
    callApi(api.getInboxThread, thread_id),
  )

  const inboxThread = getInboxThreadQuery.data?.thread

  const inboxMessages = useMemo(
    () => inboxThread?.inbox_messages.map((inboxMessage) => new CabalMessage({ ...inboxMessage })),
    [inboxThread?.inbox_messages],
  )
  const firstInboxMessage = useMemo(
    () => first(inboxThread?.inbox_messages),
    [inboxThread?.inbox_messages],
  )

  if (getInboxThreadQuery.isFetching || !inboxThread || !inboxMessages || !firstInboxMessage) {
    return <Loading className="mt-8" />
  }

  return (
    <PageWrapper title={firstInboxMessage.subject} hideTitle>
      <div className="flex items-start gap-3 pb-4">
        <div className="flex-1 flex flex-col gap-2 min-w-0">
          <div>
            <Typography
              color={'primary'}
              fontSize="28"
              fontWeight={700}
              lineHeight={1.2}
              component="h1"
              className={cx('my-2', { italic: !firstInboxMessage.subject })}
            >
              {emoji(firstInboxMessage.subject || '(No Subject)')}
            </Typography>
            <InboxMessageHeader message={firstInboxMessage} />
          </div>

          <InboxMessage message={firstInboxMessage} withHeaders={false} />

          {drop(inboxMessages).map((message) => (
            <InboxMessage message={message} key={message.uuid} />
          ))}
        </div>
        <div className="hidden sm:block w-72 sticky top-0">
          <Collaborators
            collaborators={inboxThread.collaborators}
            onReload={getInboxThreadQuery.refetch}
            teamSlug={teamSlug}
            attachableType="InboxThread"
            attachableUuid={inboxThread.uuid}
          />
          <CommentsAndActivity
            teamSlug={teamSlug}
            attachableType="InboxThread"
            attachableId={inboxThread.uuid}
          />
        </div>
      </div>
    </PageWrapper>
  )
}

export default ViewInboxThread
