import filesize from 'filesize'
import Banner from 'global/Banner'
import CabalButton from 'global/CabalButton'
import UploadZone, { UploadZoneRef } from 'global/Input/UploadZone'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import React, { useRef, useState } from 'react'
import { UploadBlueprint, UploadModel } from 'utils/types'

interface Props {
  value: UploadBlueprint[]
  onChange: (uploads: UploadBlueprint[]) => void
  onHide: () => void
}

const EditAttachmentsModal: React.VFC<Props> = ({ value, onChange, onHide }) => {
  const uploadZoneRef = useRef<UploadZoneRef | null>(null)
  const [showUploadButton, setShowUploadButton] = useState(false)

  const onUpload = (uploads: UploadModel[]) => {
    onChange([...value, ...(uploads as any as UploadBlueprint[])])
  }

  return (
    <Modal
      show
      onHide={onHide}
      header={'Attachments'}
      leftActions={
        showUploadButton && (
          <CabalButton
            onClick={() => uploadZoneRef.current?.uploadFiles()}
            rightIcon={<i className="far fa-upload" />}
          >
            Upload
          </CabalButton>
        )
      }
    >
      {value.map((upload) => {
        return (
          <Banner
            variant="secondary"
            key={upload.uuid}
            onDismiss={() =>
              confirm(`Are you sure you want to remove "${upload.file_name}"?`) &&
              onChange(value.filter((v) => v.uuid !== upload.uuid))
            }
          >
            <a href={upload.api_url || upload.url} target="_blank" rel="noreferrer">
              {upload.file_name} • {filesize(upload.file_size)}
            </a>
          </Banner>
        )
      })}
      {!value.length && (
        <div className="my-6 text-center">
          <Typography fontSize="40" color="text_secondary">
            <i className="far fa-paperclip" />
          </Typography>
          <Typography fontSize="14" component="p">
            There are no attachments on this message yet.
          </Typography>
        </div>
      )}
      <UploadZone
        onSelect={(f) => setShowUploadButton(!!f.length)}
        ref={uploadZoneRef}
        multiple
        onUpload={onUpload}
      />
    </Modal>
  )
}

export default EditAttachmentsModal
