import React, { useState } from 'react'

import { useQuery } from 'react-query'

import { ReviewSalesListModal } from 'containers/IntroRequestModal/components/Modals/Sales/ReviewSalesListModal'
import { IntroConnection } from 'containers/Portfolio/MemberView/types/IntroConnection'
import { useCurrentUser } from 'store/hooks'

import api, { callApi } from 'utils/api'

import { IRCompanyListObject, IRequestor, ISalesTarget } from '../../../types'

interface IProps {
  requestable?: ISalesTarget
  companyListObject?: IRCompanyListObject
  facilitator?: IntroConnection
  requestor?: IRequestor
  networkingOnly?: boolean
  introRequestUuid: string | null
  messageUuid?: string | null
}

export function ReviewSalesListHandler({
  requestable,
  companyListObject,
  facilitator,
  requestor,
  networkingOnly = false,
  introRequestUuid,
  messageUuid,
}: IProps) {
  const [introRequestData, setIntroRequestData] = useState(null)
  const { user } = useCurrentUser()
  const { isLoading, data } = useQuery({
    queryKey: ['getIntroRequest', introRequestUuid],
    queryFn: () => callApi(api.getIntroRequestV2, introRequestUuid),
    enabled: !!introRequestUuid,
  })

  if (introRequestUuid && !introRequestData) {
    if (isLoading) {
      return <div>Loading...</div>
    } else {
      setIntroRequestData(data)
    }
  }

  if (introRequestUuid && introRequestData) {
    const {
      requestable,
      company_list: companyList,
      requestor,
      facilitator,
      initiator,
      message_uuid: messageUuid,
    } = introRequestData
    // companyList is a huge object and only subset of information is needed, so we are creating the object
    // to pass lighter data as props

    if (companyList) {
      const companyListObject = {
        slug: companyList?.slug,
        uuid: companyList.uuid,
        owning_team: {
          avatar_url: companyList.owning_team.avatar_url,
          logo: companyList.owning_team.logo,
          logo_url: companyList.owning_team.logo_url,
          name: companyList.owning_team.name,
          slug: companyList.owning_team.slug,
          uuid: companyList.owning_team.uuid,
        },
        owned: companyList.owned,
        email_template_subject: companyList.email_template_subject,
        email_template_uuid: companyList.email_template_uuid,
      }
    }
    return (
      <ReviewSalesListModal
        requestable={requestable}
        companyListObject={companyListObject}
        requestor={requestor}
        facilitator={facilitator}
        initiator={initiator}
        networkingOnly={false}
        messageUuid={messageUuid}
        introRequestUuid={introRequestUuid}
      />
    )
  }
  if (!introRequestUuid && requestable && companyListObject && requestor && facilitator) {
    return (
      <ReviewSalesListModal
        requestable={requestable}
        companyListObject={companyListObject}
        requestor={requestor}
        facilitator={facilitator}
        initiator={user}
        networkingOnly={networkingOnly}
        messageUuid={messageUuid}
      />
    )
  }
}
