import React, { useEffect, useState } from 'react'

import { Link, useHistory } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'

import { CardWrapper } from 'components/CompanyHome/styles'
import ConnectionSource from 'containers/Home/YourConnections/ConnectionSource'
import CabalButton from 'global/CabalButton'
import UploadZone from 'global/Input/UploadZone'
import Typography from 'global/Typography'
import { useAppSelector, useCurrentUserSettings } from 'store/hooks'
import { selectAppStateValues } from 'store/reducers/appReducer'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'
import { Team } from 'utils/types'

import GoogleLogoColor from '../../../images/google-icon.png'
import OptionSelector from './OptionSelector'

interface GoogleContactsStatusType {
  count: number | null
  expected_count: number | null
  first_sync_started_at: string | null
  first_sync_completed_at: string | null
  first_match_started_at: string | null
  first_match_completed_at: string | null
  error: string | null
}

interface LinkedinExportStatusType {
  count: number | null
  expected_count: number | null
  first_sync_started_at: string | null
  first_sync_completed_at: string | null
  first_match_started_at: string | null
  first_match_completed_at: string | null
  error: string | null
  friendly_error: string | null
}

export interface ConnectionStatusType {
  advisor_uuid: string
  cookie_endpoint_called: any
  cookie_properly_received: any
  connections_synced_at: any
  connections_match_at: any
  shared_connections_at: any
  installed_extension_at: any
  uploaded_connections_at: any
  connections_count: number
  google_contacts: GoogleContactsStatusType
  linkedin_export: LinkedinExportStatusType
}

const ErrorWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.light_yellow};
  border: 2px solid ${({ theme }) => theme.colors.yellow_bold};
`

const StyleWrapper = styled.div`
  ${tw`mb-2 items-center px-5 py-4 rounded-lg`}
  background-color: ${({ theme }) => theme.layout.main_bg_color};
  border: 2px solid ${({ theme }) => theme.layout.main_bg_color};
`

const ProgressBarInner = styled.div`
  background-color: ${({ theme }) => theme.buttons.primary.bg_color};
`

const GoogleLogo = styled.img`
  ${tw`m-auto`}
  height: 20px;
  width: 20px;
`

const LinkedInLogo = styled.i`
  ${tw`m-auto`}
  color: #2d64bc;
  font-size: 22px;
`

const ProgressBar = ({ percent }: any) => (
  <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
    <ProgressBarInner
      className="bg-blue-600 h-2.5 rounded-full"
      style={{ width: percent }}
    ></ProgressBarInner>
  </div>
)

function MatchStatus({ connectionStatus }: { connectionStatus: ConnectionStatusType }) {
  const data = useAppSelector(
    selectAppStateValues([`match_status.${connectionStatus?.advisor_uuid}.percent`]),
  )

  const percent = data[`match_status.${connectionStatus?.advisor_uuid}.percent`]

  return (
    <StyleWrapper>
      <div className="text-center">
        <Typography fontWeight={700} fontSize={'18'} color={'done'} className="inline-block">
          <i className="far fa-sync animate-spin-slow" />
        </Typography>
        <Typography fontSize="14" lineHeight={1} className="ml-4">
          Matching {connectionStatus?.connections_count} connections.
        </Typography>
      </div>
      {percent && (
        <div className="my-3">
          <ProgressBar percent={`${percent}%`} />
        </div>
      )}
    </StyleWrapper>
  )
}

function SyncStatus({
  connectionStatus,
  globalHome = false,
}: {
  connectionStatus: ConnectionStatusType
  globalHome?: boolean
}) {
  let percent = '0%'
  let expectedCount, actualCount

  const { google_contacts, linkedin_export } = connectionStatus

  if (google_contacts?.first_sync_started_at && !google_contacts?.first_sync_completed_at) {
    expectedCount = google_contacts.expected_count
    actualCount = google_contacts.count
  } else if (linkedin_export?.first_sync_started_at && !linkedin_export?.first_sync_completed_at) {
    expectedCount = linkedin_export.expected_count
    actualCount = linkedin_export.count
  } else {
    // extension connections
    expectedCount = connectionStatus?.expected_connections_count
    actualCount = connectionStatus?.connections_count
  }

  if (expectedCount && actualCount) {
    const calc = (parseFloat(actualCount) / parseFloat(expectedCount)) * 100
    percent = `${Math.trunc(calc)}%`
  }

  return (
    <StyleWrapper className={globalHome ? '' : 'w-1/2'}>
      <div className="text-center">
        <Typography fontWeight={700} fontSize={'18'} color={'done'} className="inline-block">
          <i className="far fa-sync animate-spin-slow" />
        </Typography>
        <Typography
          fontSize="14"
          lineHeight={1}
          className="ml-4"
          data-testid="syncing-connections-indicator"
        >
          Syncing connections
        </Typography>
      </div>
      {expectedCount && (
        <>
          <div className="mt-3">
            <ProgressBar percent={percent} />
          </div>
          <div className="mt-3 text-center text-sm">
            {actualCount || 0} of {expectedCount} connections ({percent})
          </div>
        </>
      )}
    </StyleWrapper>
  )
}

interface Props {
  team?: Team
  memberUuid?: string
  connectionStatus: any
  checkConnectionStatus: () => void
  canUseExtensionConnections: boolean
  homeWrapper?: boolean
  globalHome?: boolean
  setShowShareConnectionsWidget?: (value: boolean) => void
  setExpandAddConnections?: (value: boolean) => void
  refetchHome?: () => void
  expandAddConnections: boolean
  showLinkedinPopup?: boolean
  searchInputRef?: React.RefObject<HTMLInputElement>
}

function ConnectionStatus(props: Props) {
  const {
    team,
    memberUuid,
    connectionStatus,
    checkConnectionStatus,
    canUseExtensionConnections,
    homeWrapper = false,
    globalHome = false,
    setShowShareConnectionsWidget,
    setExpandAddConnections,
    refetchHome = undefined,
    expandAddConnections = false,
    showLinkedinPopup = false,
    searchInputRef,
  } = props
  const ready = useSearchParam('ready')
  const [showDropzone, setShowDropzone] = useState(true)
  const [popup, setPopup] = useState<Window | null>(null)
  const [reminderSent, setReminderSent] = useState(false)
  const [sharing, setSharing] = useState(false)

  const { isMobile } = useColorMode()
  const history = useHistory()

  const { connection_status: realTimeConnectionStatus } = useAppSelector(
    selectAppStateValues(['connection_status']),
  )

  const scheduleReminder = () => {
    if (reminderSent) return
    setReminderSent(true)
    api.scheduleUploadReadyReminder(team?.slug, !team)
  }

  const onAddConnections = (first = true) => {
    if (popup && !popup.closed) {
      popup.focus()
    } else {
      const popupWindow = window.open(
        '/add_connections',
        '_blank',
        `height=${window.screen.availHeight || '1000'},width=480,left=${
          window.screen.availWidth - 480
        }`,
      )
      setPopup(popupWindow)
      if (first) {
        scheduleReminder()
      }
    }
  }

  const goToInstallExtension = () => {
    history.push({
      pathname: window.location.pathname,
      search: '',
    })
    window.open(
      `/api/redirects?team=${team?.slug}&to=chrome-extension&from=${window.location.pathname}`,
    )
  }

  const updateShareConnections = (share_connections: boolean) => {
    setSharing(true)
    api
      .updateConnectionSharing({
        team_slug: team?.slug,
        share_connections,
      })
      .then((response: any) => {
        checkConnectionStatus()
        if (share_connections) {
          cabalToast({
            style: 'success',
            content: `Your connections were shared with ${team?.name}!`,
          })
          if (refetchHome) {
            refetchHome()
          }
          setExpandAddConnections && setExpandAddConnections(false)
          searchInputRef?.current?.focus()
        } else {
          cabalToast({ style: 'error', content: 'Your share settings were saved' })
        }
      })
  }

  useEffect(() => {
    if (showLinkedinPopup) {
      onAddConnections()
    }
  }, [showLinkedinPopup])

  let status = null

  if (globalHome && !team) {
    if (realTimeConnectionStatus?.time > connectionStatus.time) {
      status = realTimeConnectionStatus
    } else {
      status = connectionStatus
    }
    status = connectionStatus
  } else {
    if (
      memberUuid &&
      connectionStatus?.advisor_uuid &&
      memberUuid == connectionStatus?.advisor_uuid
    ) {
      if (
        memberUuid == realTimeConnectionStatus?.advisor_uuid &&
        realTimeConnectionStatus.time > connectionStatus.time
      ) {
        status = realTimeConnectionStatus
      } else {
        status = connectionStatus
      }
    }
  }

  const {
    cookie_endpoint_called,
    cookie_properly_received,
    connections_synced_at,
    connections_match_at,
    shared_connections_at,
    installed_extension_at,
    uploaded_connections_at,
    google_contacts,
    linkedin_export,
  }: ConnectionStatusType = status || {}

  const shared_connections = !!shared_connections_at
  const extensionInstalled = !!installed_extension_at
  const installedButNotLoggedIn =
    extensionInstalled && cookie_endpoint_called && !cookie_properly_received

  const syncedGoogleContacts =
    google_contacts?.first_sync_started_at && google_contacts?.first_sync_completed_at

  const showDesktopInstall = true
  const showLoginWithLinkedin =
    canUseExtensionConnections &&
    installedButNotLoggedIn &&
    !connections_synced_at &&
    !uploaded_connections_at
  let showSyncStatus = false
  let showMatchStatus =
    !isMobile &&
    shared_connections_at &&
    ((extensionInstalled && connections_synced_at) || uploaded_connections_at) &&
    !connections_match_at
  const showShareConnectionsCard =
    !!team &&
    (!!connections_synced_at || !!uploaded_connections_at || !!syncedGoogleContacts) &&
    shared_connections !== true
  const hasLinkedInExportError = linkedin_export?.error || linkedin_export?.friendly_error

  if (google_contacts?.first_sync_started_at) {
    if (google_contacts?.first_sync_completed_at) {
      showSyncStatus = false
      showMatchStatus =
        google_contacts?.first_match_started_at && !google_contacts?.first_match_completed_at
    } else {
      showSyncStatus = true
    }
  } else if (linkedin_export?.first_sync_started_at) {
    if (linkedin_export?.first_sync_completed_at) {
      showSyncStatus = false
      showMatchStatus = false
    } else {
      showSyncStatus = true && !hasLinkedInExportError
      showMatchStatus = false
    }
  }

  useEffect(() => {
    if (!!status) setShowShareConnectionsWidget?.(showShareConnectionsCard)
  }, [showShareConnectionsCard])

  if (!status) {
    return <></>
  }

  const authorizeGoogleContacts = () => {
    // or ?return_to=${window.location.href}
    let url = ''
    if (team) {
      url = `/auth/gcontacts?return_to=/${team.slug}?ready=google`
    } else {
      url = `/auth/gcontacts?return_to=/home?ready=google`
    }
    window.location.assign(url)
  }

  const googleStateText = () => {
    if (google_contacts?.first_sync_started_at) {
      if (google_contacts?.sync_at_nice_date) {
        return `Synced on ${google_contacts?.sync_at_nice_date}`
      } else {
        return 'Syncing'
      }
    }
  }

  const linkedinStateText = () => {
    if (linkedin_export?.first_sync_started_at && !showDropzone) {
      if (linkedin_export?.sync_at_nice_date) {
        return `Uploaded on ${linkedin_export?.sync_at_nice_date}`
      } else if (linkedin_export?.error) {
        return 'Error'
      } else {
        return 'Importing'
      }
    }
  }

  const contents = (
    <div className={`flex flex-col ${globalHome ? '' : 'items-center justify-center p-8'}`}>
      {showDesktopInstall && (
        <div className={`items-center p-2 ${globalHome ? '' : 'text-center'}`}>
          <>
            {showShareConnectionsCard && (
              <div className="items-center p-2 py-10 text-center">
                <Typography fontSize="14" component="div" className="lg:px-20">
                  Share your connections with {team?.name} to see where you can help
                </Typography>
                <div className="mt-8">
                  <CabalButton
                    disabled={sharing}
                    variant="primary"
                    data-testid="share-connections-button"
                    onClick={() => updateShareConnections(true)}
                  >
                    {sharing ? 'Sharing...' : 'Share my connections'}
                  </CabalButton>
                </div>
              </div>
            )}

            {!showShareConnectionsCard && (
              <div>
                {/* <ConnectionSource
                  icon={<GoogleLogo src={GoogleLogoColor} />}
                  complete={!!google_contacts.first_sync_started_at}
                  title={'Google Contacts'}
                  count={(google_contacts?.count || 0).toLocaleString('en-US')}
                  state={googleStateText()}
                  buttonText={!!google_contacts.first_sync_started_at ? 'Reauthorize' : 'Add'}
                  onClick={authorizeGoogleContacts}
                /> */}

                <ConnectionSource
                  icon={<LinkedInLogo className="fa-brands fa-linkedin" />}
                  complete={!!status?.linkedin_export?.first_sync_started_at}
                  open={showDropzone}
                  title={'LinkedIn'}
                  count={(status?.connections_count || linkedin_export?.count || 0).toLocaleString(
                    'en-US',
                  )}
                  state={linkedinStateText()}
                  onClick={() => {
                    setShowDropzone(true)
                    onAddConnections()
                  }}
                  data-testid="linkedin-source"
                />

                {!showDropzone && hasLinkedInExportError && (
                  <ErrorWrapper className="mt-2 rounded p-4">
                    <div className="flex items-center">
                      <Typography fontSize="16" className="mr-4" color="yellow">
                        <i className="fa-solid fa-triangle-exclamation"></i>
                      </Typography>
                      <Typography fontSize="12" className="div" lineHeight={1.2}>
                        <Typography fontWeight={600}>
                          There was an error processing your LinkedIn export file.
                        </Typography>
                        <p>
                          We couldn’t find any Connections in the file. Please try exporting the
                          file from LinkedIn again.
                        </p>
                        <a
                          href={
                            'https://intercom.help/cabal/en/articles/7861302-sharing-your-connections-on-cabal'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Typography color="purple">Learn more</Typography>
                        </a>
                      </Typography>
                    </div>
                  </ErrorWrapper>
                )}

                {showDropzone && !(showSyncStatus || showMatchStatus) && (
                  <UploadZone
                    uploadOnSelect
                    accept={['csv', 'zip']}
                    data-testid="linkedin-upload-zone"
                    onUpload={(upload) => {
                      if (!upload.length) return

                      setShowDropzone(false)

                      api.importUserConnections(team?.slug, upload[0].uuid).then((response) => {
                        setShowDropzone(false)
                      })
                    }}
                    prompt={
                      <>
                        <Typography fontWeight={600} color="primary" component="div">
                          Drop connections file here
                        </Typography>
                        Drag and drop to upload or{' '}
                        <Typography color="purple">click to browse</Typography>
                      </>
                    }
                  />
                )}

                {canUseExtensionConnections && !extensionInstalled && (
                  <OptionSelector
                    icon={<i className="fal fa-puzzle-piece"></i>}
                    title="Install Chrome Extension"
                    onClick={goToInstallExtension}
                    data-intercom-target="chrome_extension_instructions"
                    buttonText="Add"
                    borderless
                  />
                )}
              </div>
            )}
          </>
        </div>
      )}
      {showLoginWithLinkedin && (
        <div className="items-center p-2 flex flex-col">
          <Typography fontWeight={600} lineHeight={1} component="p" className="pb-6">
            Login to Linkedin to share your connections with {team?.name}
          </Typography>

          <CabalButton
            component="a"
            target="_blank"
            className="block mt-2"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/"
            leftIcon={<i className="fab fa-linkedin"></i>}
            variant="primary"
          >
            Login to Linkedin
          </CabalButton>
        </div>
      )}
      {(showSyncStatus || showMatchStatus) && <div className="p-2"></div>}

      {showSyncStatus && <SyncStatus connectionStatus={connectionStatus} globalHome={globalHome} />}
      {showMatchStatus && <MatchStatus connectionStatus={connectionStatus} />}
    </div>
  )

  if (homeWrapper) {
    return (
      <div className="max-w-2xl mx-auto py-2">
        <div className="flex items-center cursor-pointer pb-1">
          <Typography fontSize="14" fontWeight={600}>
            Your Connections
          </Typography>
        </div>
        <CardWrapper>{contents}</CardWrapper>
      </div>
    )
  } else {
    return contents
  }
}

export default ConnectionStatus
