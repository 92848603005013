import axios from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'

import { ICompanyPageParams } from 'components/Winsite/types/ICompanyPageParams'
import { ITeamData } from 'components/Winsite/types/ITeamData'

import { WINSITE_ENDPOINTS } from '../apiEndpoints'

const companyPage = async ({
  companyName,
  companyDomain,
  linkedinUrl,
  companySlug,
}: ICompanyPageParams) => {
  return await axios.get<ITeamData>(WINSITE_ENDPOINTS.companyPage, {
    params: {
      company_name: companyName,
      company_domain: companyDomain,
      company_slug: companySlug,
      linkedin_url: linkedinUrl,
    },
  })
}

export const useCompanyPage = (
  { companyName, companyDomain, linkedinUrl, companySlug }: ICompanyPageParams,
  options: UseQueryOptions,
) =>
  useQuery({
    queryKey: ['companyPage', companyName, companyDomain, linkedinUrl],
    queryFn: () => companyPage({ companyName, companyDomain, linkedinUrl, companySlug }),
    ...options,
    retry: 1,
  })
