import styled from 'styled-components'
import tw from 'twin.macro'

const InboxFilter = styled.button`
  ${tw`px-3 py-1.5 transition whitespace-nowrap`}

  font-size: 14px;
  color: ${({ theme }) => theme.colors.fog_rain};
  border: 1px solid ${({ theme }) => theme.colors.pill_border};

  &:first-child {
    ${tw`rounded-l-full border-r-0`}
  }

  &:last-child {
    ${tw`rounded-r-full border-l-0`}
  }

  &:not(.selected):hover,
  &:not(.selected):focus {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.rain_fog};
    z-index: 2;
  }

  i {
    font-size: 16px;
  }

  &.selected {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.purple};

    i {
      font-weight: 600;
    }
  }
`

export { InboxFilter }
