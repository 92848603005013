import React, { useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import { useQuery } from 'react-query'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { search } from 'webextension-polyfill'

import { CardWrapper } from 'components/CompanyHome/styles'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useCurrentUser, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import tags from 'utils/api/tags'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import { Offer } from 'utils/types'

import EditOfferModal from '../EditOfferModal'
import Filters from '../Filters'
import OfferCard from '../OfferCard'

export interface ResourcePropFilters {
  tags: string[]
  categories: string[]
  query: string
  proposed?: boolean
}

export type ResourcePageTitleTypes =
  | 'Vendors'
  | 'People'
  | 'Discounts'
  | 'Content'
  | 'Proposed Resources'

interface Props {
  pageTitle: ResourcePageTitleTypes
  offerTypeSlug: string
}

const PageTemplate: React.FC<Props> = ({ pageTitle, offerTypeSlug }) => {
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()
  const { canViewOffers, canEditOffers } = useAccessControl(teamSlug)
  const { isLoggedIn } = useCurrentUser()

  const [allTags, setAllTags] = useState<string[]>([])
  const [allCategories, setAllCategories] = useState<string[]>([])
  const [offers, setOffers] = useState<Offer[]>()
  const [tagCounts, setTagCounts] = useState<[]>([])
  const [search, setSearch] = useState<string>('')
  const [showTags, setShowTags] = React.useState(false)

  const debouncedQuery = useDebouncedValue(search, 300)
  const history = useHistory()
  const location = useLocation()
  const [filters, setFilters] = useState<ResourcePropFilters>({
    tags: [],
    categories: [],
    query: debouncedQuery,
    proposed: false,
  })

  const { isLoading, isFetching, isFetchedAfterMount, isFetched } = useQuery(
    ['offers', teamSlug, offerTypeSlug, filters],
    () => callApi(api.getOffers, teamSlug, offerTypeSlug, filters),
    {
      onSuccess: ({ offers, tags, categories, tag_counts }) => {
        setOffers(offers)
        setAllTags(tags)
        setAllCategories(categories)
        setTagCounts(tag_counts)
        if (filters.categories.length === 0) {
          setShowTags(false)
        } else {
          setShowTags(true)
        }
      },
      cacheTime: Infinity,
    },
  )

  const pageData = useMemo(
    () =>
      offers
        ?.filter((offer) => !offer.proposed)
        ?.sort((a, b) => (moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1))
        ?.sort((a, b) => (a.title > b.title ? 1 : -1))
        ?.sort((a) => (a.pinned ? -1 : 1)),
    [offers],
  )

  const openEditOfferModal = (offer: Offer) => {
    showModal(
      (resolve) => (
        <EditOfferModal
          teamSlug={teamSlug}
          offer={offer}
          resolve={(offer) => {
            if (!offer) resolve()

            const offerIndex = offers?.findIndex((o) => o.uuid === offer?.uuid)
            const updatedOffers = [...(offers || [])]
            if (offerIndex !== undefined && offerIndex >= 0 && offer) {
              updatedOffers[offerIndex] = offer
            }
            setOffers(updatedOffers)

            resolve()
          }}
          allTags={allTags}
          setAllTags={setAllTags}
          isFetchingTags={isLoading}
          allCategories={allCategories}
          setAllCategories={setAllCategories}
        />
      ),
      'add-offer-modal',
    )
  }

  const openAddOfferModal = (proposed = false) => {
    showModal(
      (resolve) => (
        <EditOfferModal
          teamSlug={teamSlug}
          proposed={proposed}
          resolve={(offer) => {
            offer && setOffers([...(offers || []), offer])
            resolve()
          }}
          allTags={allTags}
          setAllTags={setAllTags}
          isFetchingTags={isLoading}
          allCategories={allCategories}
          setAllCategories={setAllCategories}
          defaultOfferType={offerTypeSlug}
        />
      ),
      'add-offer-modal',
    )
  }

  const getOtherOptions = () => {
    if (canEditOffers) {
      return (
        <CabalButton variant="link" onClick={() => openAddOfferModal(false)}>
          Add Resource
        </CabalButton>
      )
    } else if (isLoggedIn) {
      return (
        <CabalButton variant="link" onClick={() => openAddOfferModal(true)}>
          Propose Resource
        </CabalButton>
      )
    }
  }

  const getBackFunc = () => {
    if (location?.search) {
      return () => history.replace(location.pathname)
    } else {
      return () => history.goBack()
    }
  }

  return (
    <PageWrapper title={pageTitle}>
      <PageHeader
        data={{ title: pageTitle }}
        onBack={getBackFunc()}
        actions={{
          others: getOtherOptions(),
        }}
      />

      <Filters
        filters={filters}
        setFilters={setFilters}
        search={search}
        setSearch={setSearch}
        allCategories={allCategories}
        allTags={allTags}
        pageTitle={pageTitle}
        isFetching={isFetching && !isFetchedAfterMount}
        tagCounts={tagCounts}
        showTags={showTags}
      />

      {isFetching && !isFetchedAfterMount && (
        <div className="my-4">
          <Loading />
        </div>
      )}

      {isFetched && (
        <div>
          {pageData?.map((o: Offer) => (
            <OfferCard
              key={o.uuid}
              offer={o}
              teamSlug={teamSlug}
              offerTypeSlug={offerTypeSlug}
              onEdit={openEditOfferModal}
            />
          ))}

          {pageData?.length === 0 && (
            <CardWrapper>
              <Typography fontSize="14" color="fog_rain" component="div">
                No {pageTitle} created yet
              </Typography>
            </CardWrapper>
          )}
        </div>
      )}
    </PageWrapper>
  )
}

export default PageTemplate
