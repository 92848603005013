import React from 'react'

import toast from 'react-hot-toast'
import { useMutation } from 'react-query'

import Avatar from 'global/Avatar'
import AvatarUpload, { AvatarUploadRef } from 'global/AvatarUpload'
import CabalButton from 'global/CabalButton'
import { TextInput, UploadButton } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import team from 'utils/api/team'
import { Team } from 'utils/types'

interface Props {
  teamSlug: string
}

const CompanyDetails: React.FC<Props> = ({ teamSlug }) => {
  const { team, reloadTeam } = useTeam(teamSlug)
  const [updatedTeam, setUpdatedTeam] = React.useState<Partial<Team>>(team!)

  const avatarUploadRef = React.useRef<AvatarUploadRef>(null)

  const updateTeamMutation = useMutation(
    ['updateTeam'],
    () =>
      callApi(api.updateTeam, teamSlug, {
        team: {
          name: updatedTeam.name,
          description: updatedTeam.description,
          twitter_url: updatedTeam.twitter_url,
          linkedin_url: updatedTeam.linkedin_url,
          website: updatedTeam.website,
        },
      }).then(() => avatarUploadRef.current?.upload()),
    {
      onSuccess: () => {
        toast.success('Company Details updated')
        reloadTeam()
      },
    },
  )

  if (!updatedTeam || !team) return <></>
  return (
    <div>
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Company Logo</ModalInputLabel>

          <div className="flex flex-end">
            {/* <UploadButton
              allowedExtensions={['png', 'jpg', 'jpeg']}
              onUpload={(uuid) => setUpdatedTeam({ ...updatedTeam, logo: `/api/uploads/${uuid}` })}
              showPencil={true}
              text=""
              triggerProps={{
                variant: 'tertiary',
                leftIcon: (
                  <Typography className="pr-3">
                    <Avatar src={updatedTeam.logo} size={'32'} />
                  </Typography>
                ),
                padding: '0',
              }}
            /> */}
            <AvatarUpload
              ref={avatarUploadRef}
              imageUrl={updatedTeam.logo}
              domain={updatedTeam.website}
              onUpload={() => null}
              attachTo="company_logo"
              className="my-4"
            />
          </div>
        </ModalInputWrapper>
      </ModalSectionWrapper>
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Company Name</ModalInputLabel>
          <TextInput
            type="text"
            value={updatedTeam.name}
            onChange={(e) => setUpdatedTeam({ ...updatedTeam, name: e.target.value })}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Company Website</ModalInputLabel>
          <TextInput
            placeholder="Website URL"
            type="text"
            value={updatedTeam.website}
            onChange={(e) => setUpdatedTeam({ ...updatedTeam, website: e.target.value })}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Twitter URL</ModalInputLabel>
          <TextInput
            value={updatedTeam.twitter_url}
            onChange={(e) => setUpdatedTeam({ ...updatedTeam, twitter_url: e.target.value })}
            placeholder="Twitter URL"
            type="text"
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Linkedin URL</ModalInputLabel>
          <TextInput
            placeholder="Linkedin URL"
            type="text"
            value={updatedTeam.linkedin_url}
            onChange={(e) => setUpdatedTeam({ ...updatedTeam, linkedin_url: e.target.value })}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Page Info</ModalInputLabel>
          <TextInput
            placeholder="Description"
            type="text"
            value={updatedTeam.description}
            onChange={(e) => setUpdatedTeam({ ...updatedTeam, description: e.target.value })}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
      <div className="my-4 flex justify-end">
        <CabalButton
          working={updateTeamMutation.isLoading}
          onClick={() => updateTeamMutation.mutate()}
        >
          Save
        </CabalButton>
      </div>
    </div>
  )
}

export default CompanyDetails
