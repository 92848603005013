import axios from 'axios'
import { SaveCapTableRequest, GetCapTableResponse, SaveCapTableResponse } from 'utils/types'

export default {
  saveCapTable: (params: SaveCapTableRequest) => {
    return axios.put<SaveCapTableResponse>(`/api/cap_table`, params)
  },

  getCapTable: () => {
    return axios.get<GetCapTableResponse>(`/api/cap_table`)
  },
}
