import React, { useState } from 'react'

import { useMutation } from 'react-query'

import { EducationEntry } from 'containers/CandidateProfileModal/WorkHistory'
import CabalButton from 'global/CabalButton'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CandidateProfile } from 'utils/types'

interface PrefrencesModalProps {
  onHide: () => void
  candidateProfile: CandidateProfile
  teamSlug: string
  reloadCandidateProfile: () => void
}

const EducationModal = ({
  onHide,
  candidateProfile: _candidateProfile,
  teamSlug,
  reloadCandidateProfile,
}: PrefrencesModalProps) => {
  const [candidateProfile, setCandidateProfile] =
    useState<Partial<CandidateProfile>>(_candidateProfile)

  const updateCandidateProfileMutation = useMutation(
    ['updateCandidateProfile', candidateProfile.uuid],
    () =>
      callApi(api.updateCandidateProfileV2, candidateProfile.uuid!, {
        candidate_profile: candidateProfile,
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Profile updated successfully' })
        reloadCandidateProfile()
        onHide()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to update profile' })
      },
    },
  )

  return (
    <Modal
      header="Prefrences"
      show={true}
      onHide={onHide}
      rightActions={
        <CabalButton
          type="button"
          variant="primary"
          onClick={() => updateCandidateProfileMutation.mutate()}
          working={updateCandidateProfileMutation.isLoading}
        >
          Save
        </CabalButton>
      }
    >
      <div className="flex justify-between items-center">
        <Typography fontSize="14" fontWeight={600}>
          Education
        </Typography>
        <CabalButton
          variant="link"
          type="button"
          onClick={() => {
            setCandidateProfile({
              ...candidateProfile,
              education_history: [
                {
                  school: '',
                  fieldOfStudy: '',
                  fromMonth: null,
                  fromYear: null,
                  toMonth: null,
                  toYear: null,
                  summary: '',
                  key: Date.now(),
                },
                ...candidateProfile.education_history,
              ],
            })
          }}
        >
          + Add More
        </CabalButton>
      </div>
      {candidateProfile.education_history &&
        candidateProfile.education_history.map((entry, i) => (
          <EducationEntry
            key={`${entry.uuid || entry.key}-${i}`}
            entry={entry}
            isLast={i === candidateProfile.education_history.length - 1}
            onUpdate={(newEntry) => {
              const newEducationHistory = [...candidateProfile.education_history]
              newEducationHistory[i] = {
                ...newEducationHistory[i],
                ...newEntry,
              }
              setCandidateProfile({ ...candidateProfile, education_history: newEducationHistory })
            }}
            onRemove={() => {
              setCandidateProfile({
                ...candidateProfile,
                education_history: candidateProfile.education_history.filter(
                  (_, innerIndex) => innerIndex !== i,
                ),
              })
            }}
          />
        ))}
    </Modal>
  )
}

export default EducationModal
