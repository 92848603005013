import Typography from 'global/Typography'
import React from 'react'
import { UserUpdate } from '.'

const VoteToast: React.VFC<UserUpdate> = ({ user_name, company, person, item_type }) => {
  if (person) {
    return (
      <Typography fontSize="12" className="max-w-sm">
        {user_name} offered to help with:
        <br />
        {person.name} - {person.headline}
      </Typography>
    )
  }

  return (
    <Typography fontSize="12">
      {name} liked a ${item_type || 'company'}: {company!.name}
    </Typography>
  )
}

export default VoteToast
