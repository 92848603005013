import React, { useRef, useState } from 'react'

import { useComposer } from 'components/Composer'
import { CompanyBlurbInfo } from 'components/Winsite/CompanyBlurb/CompanyBlurbInfo'
import { useUpdateTeamMutation } from 'components/Winsite/queries/UpdateCompanyMutation'
import { ITeamData } from 'components/Winsite/types/ITeamData'
import { loginWithDomainToast } from 'components/Winsite/utils'
import { useAdvisorship, useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import copyToClipboard from 'utils/copyToClipboard'

import { BlurbInfoDiv, CompanyBlurbDescripton, StyledCabalButton } from '../styles'
import { CompanyBlurbReferral } from './CompanyBlurbReferral'

interface IProps {
  teamData: ITeamData | undefined
}

export function CompanyBlurbSection({ teamData }: IProps) {
  const [blurb, setBlurb] = useState<string>(teamData?.description || '')
  const [editClicked, setEditClicked] = useState<boolean>(false)
  const { compose } = useComposer(teamData?.team_slug)
  const { user } = useCurrentUser()
  const { advisor } = useAdvisorship(teamData!.team_slug)
  const textAreaRef = useRef(null)
  const userPresent = !!Object(user)?.created_at
  const allowSave = userPresent && user.team?.slug === teamData?.team_slug

  const { mutate: updateCompanyMutate } = useUpdateTeamMutation(teamData?.team_slug)

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditClicked(true)
    setBlurb(e.target.value)
  }
  const handleEditSave = () => {
    if (!allowSave) {
      const content = `You must be a member of the ${teamData!.company_name.toLowerCase()} team to edit the blurb`
      loginWithDomainToast(teamData, content)
      return
    }
    if (!editClicked) {
      setEditClicked(true)
      textAreaRef!.current!.focus()
      textAreaRef!.current!.setSelectionRange(blurb.length, blurb.length)
      return
    }
    updateCompanyMutate(
      { description: blurb, companySlug: teamData?.team_slug },
      {
        onSuccess: () => {
          cabalToast({
            content: 'Company Blurb updated',
            style: 'success',
          })
          setBlurb(blurb)
          setEditClicked(false)
        },
        onError: () => {
          cabalToast({
            content: 'Company Blurb failed to update. Only admins can update blurb',
            style: 'error',
          })
        },
      },
    )
  }
  const handlCopyBlurb = () => {
    copyToClipboard(blurb)
    cabalToast({
      content: 'Blurb copied to clipboard',
      style: 'success',
    })
  }

  const capCompanyName = teamData?.company_name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

  const messageBody = `
    <p>Hi,
      <br/><br/>
      I was just catching up with the team at <a href='${teamData?.website_url}'>${capCompanyName}</a>, have you heard of them? They'd love to meet and get your take on what they're building. More info below.
      I know the founder well - their team is great. I think you'll enjoy connecting with them.
      <br/><br/>
      Cheers,<br/>${user.first_name}
      <br/><br/>
      About ${capCompanyName}: <br/>${blurb}
    </p>
  `
  const handleStartReferral = () => {
    if (!userPresent) {
      cabalToast({
        content: 'Please log in to start a referral',
        style: 'error',
      })
      return
    }
    if (!advisor) {
      const content = `You must be a member of the ${teamData!.company_name.toLowerCase()} workspace to send referrals`
      loginWithDomainToast(teamData, content)
      return
    }
    compose({
      body: messageBody,
      subject: capCompanyName,
    })
  }
  return (
    <BlurbInfoDiv>
      <div className="flex flex-col gap-x-3 gap-y-2 w-full p-5">
        <CompanyBlurbInfo teamData={teamData} />
        <CompanyBlurbReferral handleSave={handleEditSave} editClicked={editClicked} />
        <CompanyBlurbDescripton
          ref={textAreaRef}
          value={blurb}
          onChange={(e) => handleTextAreaChange(e)}
          rows="4"
        />
        <div className="flex flex-row gap-x-1 items-center">
          <StyledCabalButton onClick={handlCopyBlurb}>Copy Blurb</StyledCabalButton>
          <StyledCabalButton onClick={handleStartReferral}>Start Referral</StyledCabalButton>
        </div>
      </div>
    </BlurbInfoDiv>
  )
}
