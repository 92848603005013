import React, { useEffect, useRef, useState } from 'react'

import { AxiosError } from 'axios'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import isEqual from 'lodash/isEqual'
import last from 'lodash/last'
import pick from 'lodash/pick'
import CabalMessage from 'models/CabalMessage'
import moment from 'moment'
import objectHash from 'object-hash'
import Expand from 'react-expand-animated'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import TitleWrappers from 'components/Composer/Sidebar/TitleWrappers'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import { CheckBox, Select } from 'global/Input'
import RichTextInput, { RichTextInputRef } from 'global/Input/RichTextInput'
import Loading from 'global/Loading'
import { MentionSuggestion } from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'
import { useAdvisors, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import { HttpStatusCode } from 'utils/HttpStatusCode'
import api, { callApi } from 'utils/api'
import { ActivityModel, Comment as CommentType, UserBlueprint } from 'utils/types'

import Activity from './Activity'
import Comment from './Comment'

const InputContainer = styled.div`
  ${tw`overflow-y-scroll`};

  max-height: 150px;

  .ql-editor.ql-blank {
    height: auto;
  }
  .ql-mention-list {
    color: ${({ theme }) => theme.colors.primary};
  }

  .ql-mention-list-container {
    padding: 4px;
    background-color: ${({ theme }) => theme.colors.shadow};
    border: none;
  }

  .ql-mention-list-item {
    border-radius: 4px;
    padding: 8px 6px;
  }

  .ql-mention-list-item.selected {
    background-color: #5c69d1;
    color: #fff;
  }
`

const InputStyle = styled.div`
  ${tw`rounded px-2`};

  background-color: ${({ theme }) => theme.colors.primary_bg};

  .ck-editor__main,
  .ck-editor__main .ck-editor__editable {
    background: transparent !important;
  }
`

const ComposerWrapper = styled.div`
  background-color: ${({ theme }) => theme.layout.main_bg_color};
`

interface Props {
  message?: CabalMessage
  messageThreadId?: string | null
  externalId?: string
  externalType?: string
  teamSlug: string
  attachableType?: 'GmailThread' | 'InboxThread' | 'Company'
  attachableId?: string
}

const CommentsAndActivity: React.VFC<Props> = ({
  message,
  externalId,
  externalType,
  teamSlug,
  messageThreadId: _messageThreadId = null,
  attachableId,
  attachableType,
}) => {
  const [newComment, setNewComment] = useState<{ body: string; internal: boolean }>({
    body: '',
    internal: false,
  })
  const { team } = useTeam(teamSlug)
  const firstFetch = useRef(true)
  const { canViewInternalComments } = useAccessControl(teamSlug)
  const { advisors } = useAdvisors({ teamSlug })
  const [messageThreadUuid, setMessageThreadUuid] = useState<string | null>(null)
  const richTextInputRef = useRef<RichTextInputRef>(null)
  const commentsContainerRef = useRef<HTMLDivElement>(null)
  const [toggle, setToggle] = useState(true)

  // hack to know what message's comments are loaded
  useEffect(() => {
    window.comments_loaded_for = message?.uuid

    return () => {
      window.comments_loaded_for = null
    }
  }, [message?.uuid])

  const {
    data: commentsData,
    isLoading: isLoadingComments,
    refetch: refetchComments,
  } = useQuery(
    [
      teamSlug,
      'getComments',
      message?.uuid ||
        messageThreadUuid ||
        (attachableId && attachableId + (attachableType || '')) ||
        (externalId && externalId + (externalType || '')),
    ],
    () =>
      callApi(api.getComments, {
        team_slug: teamSlug,
        message_uuid: message?.uuid,
        message_thread_uuid: messageThreadUuid,
        external_id: externalId,
        external_type: externalType,
        attachable_id: attachableId,
        attachable_type: attachableType,
      }),
    {
      onSuccess: ({ comments }) => {
        if (canViewInternalComments && firstFetch.current === true) {
          firstFetch.current = false
          setNewComment({ body: '', internal: !!last(comments)?.internal })
        }
      },
      enabled: !!message?.uuid || !!messageThreadUuid || !!attachableId || !!externalId,
      refetchInterval: 60000,
    },
  )

  useEffect(() => {
    setMessageThreadUuid(_messageThreadId)
  }, [_messageThreadId])

  const { mutateAsync: createComment, isLoading: creatingComments } = useMutation(
    (mentionIds: string[]) =>
      callApi(api.createComment, {
        team_slug: teamSlug,
        message_uuid: message?.uuid,
        message_thread_uuid: messageThreadUuid,
        external_id: externalId,
        external_type: externalType,
        body: newComment.body || '',
        internal: newComment.internal,
        mention_ids: mentionIds,
        attachable_id: attachableId,
        attachable_type: attachableType,
      }),
    {
      onError: (error: AxiosError) => {
        if (error.response?.status === HttpStatusCode.NotFound) {
          cabalToast({ content: 'The entity you are trying to comment on does not exist' })
        }
      },
    },
  )

  const handleCreateComment = async (variables: string[]) => {
    await createComment(variables)
    await refetchComments()
    richTextInputRef.current?.clear()
  }

  const comments = commentsData?.comments || []
  const activity = message?.activity || []

  const commentsAndActivity = [
    ...comments.map((c) => ({ ...c, type: 'comment' })),
    ...activity.map((a) => ({ ...a, type: 'activity' })),
  ].sort((a, b) => moment(a.created_at).unix() - moment(b.created_at).unix())

  useEffect(() => {
    commentsContainerRef.current?.scrollTo({ top: commentsContainerRef.current.scrollHeight })
  }, [comments.length])

  const mentionables = compact(
    concat<Pick<UserBlueprint, 'name' | 'email' | 'avatar_url' | 'uuid'>>(
      advisors?.filter((advisor) => advisor.role === 'candidate' || !advisor.role) || [],
      team?.admins_and_members || [],
    ),
  ).map<MentionSuggestion>((f) => ({
    id: f.uuid,
    name: f.name,
    extra: f.email,
    avatar: f.avatar_url,
  }))

  useHotkeys(
    'c',
    (e) => {
      e.preventDefault()
      setToggle(!toggle)
    },
    [toggle],
  )

  return (
    <>
      <div className="my-3 mb-3">
        <TitleWrappers
          title={`Comments (${commentsAndActivity.length})`}
          onClick={() => setToggle(!toggle)}
          active={toggle}
          shortcut={'C'}
        />

        <Expand open={toggle}>
          <div className="my-2">
            <div ref={commentsContainerRef}>
              {commentsAndActivity.map((c) =>
                c.type === 'comment' ? (
                  <Comment
                    key={c.type + ' ' + c.created_at}
                    comment={c as CommentType}
                    teamSlug={teamSlug}
                    refetchComments={refetchComments}
                  />
                ) : (
                  <Activity key={c.type + ' ' + c.created_at} activity={c as ActivityModel} />
                ),
              )}
            </div>
          </div>
        </Expand>
      </div>
      <ComposerWrapper className="py-2 sticky bottom-0">
        <InputStyle className="flex items-center gap-2 flex-shrink-0">
          {canViewInternalComments && (
            <DropDownMenu
              trigger={
                <CabalButton
                  variant="tertiary"
                  // disabled={!newComment.body}
                  size="small"
                  tooltip={newComment.internal ? 'Visible to Team' : 'Visible to Everyone'}
                  leftIcon={
                    newComment.internal ? (
                      <i className="far fa-eye-slash fa-fw" />
                    ) : (
                      <i className="far fa-eye fa-fw" />
                    )
                  }
                  padding="14px 0px"
                ></CabalButton>
              }
              menuItems={[
                {
                  label: (
                    <Typography>
                      <i className="far fa-eye fa-sm mr-1" />
                      Visible to Everyone
                    </Typography>
                  ),
                  onSelect: () => setNewComment({ ...newComment, internal: false }),
                },
                {
                  label: (
                    <Typography>
                      <i className="far fa-eye-slash fa-sm mr-1" />
                      Visible to Team
                    </Typography>
                  ),
                  onSelect: () => setNewComment({ ...newComment, internal: true }),
                },
              ]}
            />
          )}
          <InputContainer className="-ml-3 flex-1">
            <RichTextInput
              windowKey="comment-input"
              ref={richTextInputRef}
              placeholder="Add a comment"
              onChange={(v) => setNewComment({ ...newComment, body: v })}
              suggestions={mentionables}
            />
          </InputContainer>
          <div className="-mt-1">
            <CabalButton
              variant="link"
              onClick={() => {
                if (!richTextInputRef.current) return
                handleCreateComment(
                  richTextInputRef.current.getMentions().map((m) => m.id as string),
                )
              }}
              working={creatingComments}
              disabled={!newComment.body}
              padding="0"
              size="small"
            >
              Post
            </CabalButton>
          </div>
        </InputStyle>
      </ComposerWrapper>
    </>
  )
}

export default React.memo(
  CommentsAndActivity,
  ({ message: prevMessage, ...prevProps }, { message: nextMessage, ...nextProps }) => {
    return isEqual(
      { ...pick(prevMessage, ['uuid', 'activity']), ...prevProps },
      { ...pick(nextMessage, ['uuid', 'activity']), ...nextProps },
    )
  },
)
