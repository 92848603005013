import React, { useEffect } from 'react'

import cx from 'classnames'
import compact from 'lodash/compact'
import Highlighter from 'react-highlight-words'
import { useMutation } from 'react-query'
import styled, { useTheme } from 'styled-components'
import tw, { theme } from 'twin.macro'

import { useAskContextModal } from 'components/AsksContextModal'
import Avatar from 'global/Avatar'
import Loading from 'global/Loading'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useCurrentUserSettings, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanyBlueprint, ConnectionBlueprint, PersonBlueprint, Team, VoteType } from 'utils/types'

import ComposeButton from './ComposeButton'

export const StyledCard = styled.div<{ positive: boolean; animate: boolean }>`
  ${tw`flex items-center rounded-lg my-1.5 py-1 px-0 md:px-2 border`}

  border-color: ${({ theme, positive }) => (positive ? theme.colors.purple : 'transparent')};
  transition: all 300ms ease-in-out;
  transition-property: color, background-color, border-color, opacity;

  &:hover {
    background-color: ${({ theme }) => theme.side_nav.bg_color};
    cursor: pointer;

    .like-icon-internal {
      color: ${({ theme }) => theme.colors.purple};
    }
  }

  .like-icon-internal {
    animation: ${({ animate }) => (animate ? 'hithere 1s 1' : 'none')};
  }

  &:hover .like-button {
    color: ${({ theme }) => theme.colors.purple};
    opacity: 1;
  }
  /*
  .like-button {
    opacity: ${({ positive }) => (positive ? '1' : '0')};
  } */

  .highlightedText {
    background-color: ${({ theme }) => 'transparent'};
    color: ${({ theme }) => theme.colors.fog_rain};
    font-weight: 600;
  }

  @keyframes hithere {
    30% {
      transform: scale(1.2);
    }
    40%,
    60% {
      transform: rotate(-20deg) scale(1.2);
    }
    50% {
      transform: rotate(20deg) scale(1.2);
    }
    70% {
      transform: rotate(0deg) scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`
interface Props {
  person: PersonBlueprint
  connection?: ConnectionBlueprint
  existingVote?: VoteType
  preview?: boolean
  company: CompanyBlueprint
  teamSlug: string
  listUuid: string
  icps: string[]
  connected?: boolean
  ssid?: string
}

const linkedinSearchUrl = (name: string) => {
  return `https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(
    name,
  )}&origin=GLOBAL_SEARCH_HEADER`
}

const ConnectionRow: React.VFC<Props> = ({
  connection,
  person,
  company,
  existingVote = 0,
  preview = false,
  teamSlug,
  listUuid,
  icps = [],
  connected = false,
  ssid,
}) => {
  const [vote, setVote] = React.useState<VoteType>(existingVote)
  const { settings, updateSetting } = useCurrentUserSettings()
  const [animateLike, setAnimateLike] = React.useState(false)
  const theme = useTheme()
  const showAskContext = useAskContextModal({
    teamSlug: teamSlug,
    source: person || company,
    sourceId: person.id,
    sourceType: 'GlobalPerson',
  })
  const { team } = useTeam(teamSlug)

  const highlightWords =
    company.item_type == 'persona' || company.item_type == 'job'
      ? [company.name]
      : compact(icps || [])

  const {
    mutate: sendVote,
    isLoading: sendingVote,
    variables,
  } = useMutation(
    ['createVote'],
    (vote: 1 | -1 | 0) =>
      callApi(api.createVote, {
        team_slug: teamSlug,
        votable_type: person.id ? 'GlobalPerson' : 'Company',
        votable_id: (person.id || company.id)!,
        vote,
        company_id: company.id,
        list_uuid: listUuid,
        ssid: ssid,
      }),
    {
      onSuccess: (_, variables) => {
        setVote(variables)
        if (variables == 1 && !settings.like_toast_showed) {
          cabalToast({
            content: `Your offer to introduce ${person.name || person.email} has been logged. ${
              company.team_name
            } will follow up with you via email. `,
            style: 'success',
          })
          updateSetting('like_toast_showed', true)
        }
      },
    },
  )

  useEffect(() => {
    setTimeout(() => {
      setAnimateLike(false)
    }, 1000)
  }, [animateLike])

  return (
    <StyledCard
      positive={vote === 1}
      onClick={() => {
        sendVote(vote === 0 ? 1 : 0)
        setAnimateLike(vote === 0 ? true : false)
      }}
      animate={animateLike}
    >
      <div className="flex items-center justify-between w-full">
        <div>
          <div className={cx('flex items-center', { 'opacity-25': vote === -1 })}>
            {/* {person.image_url && (
              <Avatar
                name={person.name || person.email || '@'}
                src={person.image_url || ''}
                size={'32'}
                round
                className="flex-shrink-0 mr-2"
              />
            )} */}
            <div>
              <Typography
                color="primary"
                fontWeight={600}
                fontSize="12"
                lineHeight="1.5"
                component="button"
                className="flex items-center"
                data-testid="connection-row-person-name"
                onClick={(e) => {
                  e.stopPropagation()
                  person.linkedin_url
                    ? window.open(person.linkedin_url, '_blank')
                    : window.open(linkedinSearchUrl(person.name), '_blank')
                }}
              >
                {person.name || person.email}
                {team?.features?.global_people_data && connected && (
                  <Tooltip label={`You are connected to ${person.name}`}>
                    <Typography
                      color="fog_rain"
                      fontSize="12"
                      className="block cursor-pointer ml-1"
                      lineHeight="1"
                    >
                      · 1st
                    </Typography>
                  </Tooltip>
                )}
                <div className="flex items-center space-x-1.5 ml-1.5">
                  {person.verified && (
                    <Typography fontSize="12" color="purple" lineHeight={1}>
                      <i className="fa fa-badge-check fa-fw" />
                    </Typography>
                  )}
                  {person.linkedin_url && (
                    <Typography
                      fontSize="12"
                      color="fog_rain"
                      className="cursor-pointer"
                      lineHeight="1"
                    >
                      <a
                        href={person.linkedin_url}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        className={cx('cursor-pointer')}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <i className="fab fa-linkedin fa-fw"></i>
                      </a>
                    </Typography>
                  )}
                  {!person.linkedin_url && (
                    <Typography
                      fontSize="12"
                      color="fog_rain"
                      className="cursor-pointer"
                      lineHeight="1"
                    >
                      <a
                        href={linkedinSearchUrl(person.name)}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        className={cx('cursor-pointer')}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <i className="fab fa-linkedin fa-fw"></i>
                      </a>
                    </Typography>
                  )}
                  {connection?.source_type === 'Contact' && (
                    <Tooltip label={`You are connected to ${person.name} via Google Contacts`}>
                      <Typography fontSize="12" lineHeight={1} color="fog_rain">
                        <i className="fab fa-google fa-fw" />
                      </Typography>
                    </Tooltip>
                  )}
                </div>
              </Typography>
              {/* {advisorSentIntro && (
                <Tooltip
                  interactive
                  label={
                    <>
                      Intro sent at{' '}
                      {moment(
                        maxBy(connection.deal_board_intros, (x) => x.sent_at)!.sent_at,
                      ).format('LT, MMM D')}
                    </>
                  }
                >
                  <Typography color="green" fontSize="12" className="ml-3">
                    <i className="far fa-check-circle" />
                  </Typography>
                </Tooltip>
              )} */}
              <div className="flex">
                <Typography
                  color="fog_rain"
                  fontSize="12"
                  className="block cursor-pointer"
                  lineHeight="1.215"
                >
                  <Highlighter
                    searchWords={highlightWords}
                    textToHighlight={person.headline}
                    highlightClassName="highlightedText"
                  />
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-end" style={{ flexShrink: 0 }}>
          <div className="flex flex-end">
            <div className="flex pl-4 py-2 items-baseline">
              {vote === 1 && sendingVote && (
                <div className="mr-1">
                  <Loading size={12} strokeWidth={5} />
                </div>
              )}
              <Typography
                color={vote === 1 ? 'purple' : 'default_icon'}
                lineHeight="1"
                fontSize="12"
                className="flex items-center"
              >
                <span className="like-button">{vote === 1 ? 'Offered' : 'Offer intro'}</span>
              </Typography>
              <Typography
                color={vote === 1 ? 'purple' : 'default_icon'}
                lineHeight="1"
                fontSize="16"
                className="flex items-center pl-2 like-icon-internal"
                component="p"
              >
                {vote === 1 ? (
                  <i className="fas fa-thumbs-up" />
                ) : (
                  <i className="far fa-thumbs-up" />
                )}
              </Typography>
            </div>
            {false && (
              <ComposeButton
                person={person}
                company={company}
                disabled={preview}
                teamSlug={teamSlug}
              />
            )}
          </div>
        </div>
      </div>
    </StyledCard>
  )
}

export default ConnectionRow
