import * as React from 'react'

import cx from 'classnames'
import isArray from 'lodash/isArray'
import styled from 'styled-components'

import Avatar from 'global/Avatar'
import Typography from 'global/Typography'

import { joinReactElements } from 'utils/array'
import { cn } from 'utils/styles'

import { TitleIconProps } from '.'

export interface Props {
  title?: React.ReactNode
  activityTitle?: string
  avatarUrl?: string | React.ReactNode
  avatarName?: string
  description?: React.ReactNode
  placeholderIcon?: React.ReactNode
  titleIcons?: TitleIconProps[]
  mainIcon?: string
  titleUrl?: string
  connectionRow?: React.ReactNode
  titleOnClick?: (e?: any) => void
  avatarAlign?: 'top' | 'center'
}

const PlaceHolder = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #f3f4f6;
  color: #bbbbbb;
  border-radius: 100%;
`

const PrimaryInfo: React.VFC<Props> = ({
  title,
  activityTitle,
  avatarName,
  avatarUrl,
  description,
  placeholderIcon,
  titleIcons,
  mainIcon,
  titleUrl,
  connectionRow,
  titleOnClick,
  avatarAlign,
}) => {
  if (description) {
    if (isArray(description)) {
      description = (
        <div className={cx('flex items-center truncate')} data-testid="datacard-description">
          {joinReactElements(
            description.map((d, i) => (
              <Typography
                fontSize="12"
                lineHeight={'15px'}
                color={'fog'}
                className="truncate"
                key={`${title}-description-element-${i}`}
              >
                {d}
              </Typography>
            )),
            () => (
              <Typography fontSize="12" lineHeight={'15px'} color={'fog'} className="mx-1.5">
                {'·'}
              </Typography>
            ),
          )}
        </div>
      )
    } else {
      description = (
        <Typography
          component="div"
          fontSize="12"
          lineHeight={'15px'}
          color={'fog'}
          className="truncate"
          data-testid="datacard-description"
        >
          {description}
        </Typography>
      )
    }
  }

  return (
    <div
      className={cn('flex items-center space-x-2 truncate', {
        'items-start': avatarAlign === 'top',
      })}
    >
      {(avatarName || avatarUrl) && (
        <div>
          {typeof avatarUrl !== 'string' && avatarUrl !== undefined ? (
            avatarUrl
          ) : (
            <Avatar name={avatarName} src={avatarUrl} size="32" round className="flex-shrink-0" />
          )}
        </div>
      )}

      {mainIcon && (
        <Typography fontSize="16" color="purple">
          <i className={`far fa-${mainIcon}`}></i>
        </Typography>
      )}

      {placeholderIcon && !avatarUrl && !avatarName && <PlaceHolder>{placeholderIcon}</PlaceHolder>}
      <div className="truncate">
        {title && (
          <Typography
            component={titleUrl ? 'link' : titleOnClick ? 'button' : 'h3'}
            fontWeight={600}
            fontSize="14"
            lineHeight={'17px'}
            data-testid="datacard-title"
            color={'primary'}
            className={cx('flex truncate items-center', {
              'cursor-pointer': titleUrl || titleOnClick,
            })}
            to={titleUrl ? titleUrl : undefined}
            onClick={titleOnClick ? titleOnClick : undefined}
          >
            {title}

            {titleIcons?.map((icon: TitleIconProps) => (
              <div className="flex items-center space-x-1.5 ml-1.5" key={icon.icon}>
                {typeof icon.icon === 'string' ? (
                  <Typography
                    fontSize="12"
                    color="purple"
                    lineHeight={1}
                    component={icon.url ? 'link' : icon.onClick ? 'button' : 'p'}
                    to={icon.url ? icon.url : undefined}
                    onClick={icon.onClick ? icon.onClick : undefined}
                  >
                    {icon.icon == 'badge-check' && <i className="fa fa-badge-check fa-fw" />}
                    {icon.icon !== 'badge-check' && <i className={`fa fa-${icon.icon}`} />}
                  </Typography>
                ) : (
                  <Typography
                    component={icon.url ? 'link' : icon.onClick ? 'button' : 'p'}
                    to={icon.url ? icon.url : undefined}
                    onClick={icon.onClick ? icon.onClick : undefined}
                    fontSize="12"
                    color="fog_rain"
                    lineHeight={1}
                  >
                    {icon.icon}
                  </Typography>
                )}
              </div>
            ))}
          </Typography>
        )}

        {activityTitle && (
          <Typography
            component="h3"
            fontWeight={400}
            fontSize="14"
            lineHeight={'17px'}
            className="truncate"
          >
            {activityTitle}
          </Typography>
        )}

        {description}

        {connectionRow && (
          <div className="flex mt-1.5" data-testid="connection-count">
            {connectionRow}
          </div>
        )}
      </div>
    </div>
  )
}

export default PrimaryInfo
