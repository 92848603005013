import { compact } from 'lodash'
import { AdvisorModel, EmailRecipientValue, MessageRecipient, Team } from 'utils/types'
import { PersonalizationRecipient } from '../Sidebar'

export const individualRecipients = (
  messageRecipients: MessageRecipient[] | undefined,
  allAdvisors: AdvisorModel[] | undefined,
  groupAdvisors: AdvisorModel[],
  team: Team | undefined,
) => {
  const recipients: PersonalizationRecipient[] = []

  const advisors = compact(
    messageRecipients
      ?.filter((r) => r.type === 'advisor')
      ?.map((r) => allAdvisors?.find((a) => a.uuid === (r.value as string)))
      ?.concat(...groupAdvisors),
  )

  for (const advisor of advisors) {
    recipients.push({
      type: 'advisor',
      first_name: advisor.name,
      last_name: advisor.name,
      avatar_url: advisor.avatar_url,
      uuid: advisor.uuid,
      name: advisor.name,
      email: advisor.email,
    })
  }

  const users =
    compact(
      messageRecipients
        ?.filter((r) => r.type === 'founder')
        ?.map((r) => team?.admins_and_members?.find((u) => u.uuid === (r.value as string))),
    ) || []

  for (const user of users) {
    recipients.push({
      type: 'user',
      first_name: user.name,
      last_name: user.name,
      avatar_url: user.avatar_url,
      uuid: user.uuid,
      name: user.name,
      email: user.email,
    })
  }

  const emails =
    messageRecipients
      ?.filter((r) => r.type === 'email')
      .map((r) => r.value as EmailRecipientValue) || []

  for (const email of emails) {
    recipients.push({
      type: 'email',
      first_name: email.first_name,
      last_name: email.last_name,
      uuid: email.email,
      name: compact([email.first_name, email.last_name]).join(' '),
      email: email.email,
    })
  }

  return recipients
}
