import React, { useEffect, useMemo, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { FiltersValueType, renderFilters } from 'components/Filters'
import {
  removeUrlSearchParam,
  requestCenterStatusFunc,
  statusIconMappingFunc,
} from 'containers/RequestCenter/utils'
import { useTeam, useTeamSlug } from 'store/hooks'

import { IStatusOptionType, StatusType } from './types/ITypes'

interface IProps {
  setStatusRequestQuery: React.Dispatch<React.SetStateAction<string[] | null>>
  statusRequestQuery: string[] | null
  validStatusArr: string[] | null
}

export function StatusSearch({
  setStatusRequestQuery,
  statusRequestQuery,
  validStatusArr,
}: IProps) {
  const placeHolder = `Status`
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)

  const [filters, setFilters] = useState<FiltersValueType>({})
  const [isFiltersCleared, setIsFiltersCleared] = useState<boolean>(false)
  const history = useHistory()
  useEffect(() => {
    setFilters({ 'status-search': statusRequestQuery })
  }, [statusRequestQuery, validStatusArr])

  let statusIconMapping: StatusType
  let requestCenterStatuses: string[]
  let statusArr: unknown

  const options: IStatusOptionType[] = useMemo(
    () => [
      // Remove the key/value pair from statusIconMapping which is not a valid status and is not included
      // in requestCenterStatuses
      //
      ...Object.entries(statusIconMapping || {})
        .filter(([key]) => statusArr?.includes(key))
        .map(([key, value]) => ({
          value: key,
          label: value.label,
        })),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validStatusArr],
  )

  if (!team) return null
  statusIconMapping = statusIconMappingFunc(team)
  requestCenterStatuses = requestCenterStatusFunc(team)
  statusArr = team?.enable_portfolio ? requestCenterStatuses : validStatusArr

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: [
      {
        key: 'status-search',
        label: placeHolder,
        type: 'dropdown[]',
        options: options.map((option) => ({ value: option.value, label: option.label })) || [],
      },
    ],
    value: filters,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange: (f) => setFilters((_prev) => handleStatusChange(f)),
  })

  const handleStatusChange = (f: FiltersValueType) => {
    const newStatus = f['status-search']
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setStatusRequestQuery((_prev) => newStatus as string[] | null)
    setIsFiltersCleared(false)
    return f
  }

  if (appliedFilters.length === 0 && !isFiltersCleared) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setStatusRequestQuery((_prev) => null)
    removeUrlSearchParam({ searchParam: 'status', history: history })
    setIsFiltersCleared(true)
  }

  return (
    <>
      <div className="flex items-center gap-2">
        {renderedFilters}
        {appliedFilters}
      </div>
    </>
  )
}
