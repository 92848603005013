import * as React from 'react'
import axios from 'axios'
import { CardBg } from '../styles'
import CabalButton from 'global/CabalButton'

import Typography from 'global/Typography'
import { useModal } from 'global/Modal'
import AddAirtableAppModal from './AddExternalSourceModal'
import { cabalToast } from 'ui-components/Toast'

interface Props {
  teamSlug: string
}

export default function AirtableApps({ teamSlug }: Props) {
  const [apps, setApps] = React.useState([])
  const { showModal } = useModal()

  function loadData() {
    axios.get(`/api/external_sources?team=${teamSlug}`).then((response) => {
      const { sources } = response.data

      setApps(sources)
    })
  }

  function onDelete(uuid: string) {
    axios.delete(`/api/external_sources/${uuid}?team=${teamSlug}`).then((response) => {
      loadData()
      cabalToast({ style: 'success', content: 'External source deleted!' })
    })
  }

  React.useEffect(() => {
    loadData()
  }, [])

  return (
    <div>
      <CardBg>
        <Typography color="primary" className={'pb-4 block'} fontSize={'16'} fontWeight={700}>
          {'Airtable'}
        </Typography>
        <ul className="list-disc pl-8">
          {apps.map((app) => (
            <li key={app.uuid} className="mb-4">
              <span className="float-right">
                <CabalButton
                  variant="secondary"
                  onClick={() => {
                    if (
                      prompt('Type YES if you want to continue deleting this airtable app') == 'YES'
                    )
                      onDelete(app.uuid)
                  }}
                >
                  Delete
                </CabalButton>
              </span>
              App ID: {app.app_id} <br />
              Table Name: {app.table_name} <br />
              Cabal Type: {app.cabal_type} <br />
              <CabalButton
                component="link"
                variant="secondary"
                to={`/${teamSlug}/external_sources/${app.uuid}`}
                className="mt-2"
              >
                Open
              </CabalButton>
            </li>
          ))}
        </ul>
        <CabalButton
          leftIcon={<i className="fa fa-plus pr-1" />}
          variant="secondary"
          onClick={() => {
            showModal(
              (resolve) => (
                <AddAirtableAppModal
                  teamSlug={teamSlug}
                  resolve={() => {
                    loadData()
                    resolve()
                  }}
                />
              ),
              'add-airtable-app-modal',
            )
          }}
        >
          Add Airtable Source
        </CabalButton>
      </CardBg>
    </div>
  )
}
