import React from 'react'

import { Field, FieldProps, Form, Formik } from 'formik'
import { useMutation } from 'react-query'
import { object, string } from 'yup'

import { LINKED_IN_PROFILE_URL_REGEX } from 'containers/CandidateProfileModal/linkedInAutoPrefill'
import CabalButton from 'global/CabalButton'
import { TextInputField } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

export const candidateProfileSchema = object({
  name: string().required('This field is required'),
  linkedInUrl: string()
    .required('This field is required')
    .matches(LINKED_IN_PROFILE_URL_REGEX, 'Please enter a valid LinkedIn profile URL'),
})

interface Props {
  name: string
  listUuid: string
  onHide: () => void
  refetch: () => void
}

const AddPersonModal: React.FC<Props> = ({ name, onHide, listUuid, refetch }) => {
  const { mutate: addPerson } = useMutation(
    (data: { linkedin_url: string }) => callApi(api.addElementToSalesList, listUuid, data),
    {
      onSuccess: () => {
        refetch()
        cabalToast({
          content: 'Person added successfully!',
          style: 'success',
        })
      },
    },
  )

  return (
    <Modal onHide={onHide} show={true} header="Add person">
      <Formik
        initialValues={{
          name: name,
          linkedInUrl: '',
        }}
        validationSchema={candidateProfileSchema}
        onSubmit={(values) => {
          addPerson({ linkedin_url: values.linkedInUrl })
          onHide()
        }}
      >
        {({ handleSubmit }) => (
          <Form className="flex flex-col items-start" onSubmit={handleSubmit}>
            <ModalInputWrapper className="py-2">
              <ModalInputLabel>Linkedin URL</ModalInputLabel>
              <Field name="linkedInUrl">
                {(props: FieldProps) => (
                  <TextInputField
                    error={props.meta.touched ? props.meta.error : undefined}
                    autoFocus
                    {...props.field}
                    className="w-full"
                  />
                )}
              </Field>
            </ModalInputWrapper>

            <CabalButton
              className="mt-6 mb-2 self-end"
              variant="primary"
              type="submit"
              // disabled={enrichProfileMutation.isLoading}
            >
              {'Add'}
            </CabalButton>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddPersonModal
