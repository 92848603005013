import React, { useEffect, useRef, useState } from 'react'

import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import styled from 'styled-components'

import OfferIntroModal from 'containers/Home/OfferIntroWidget/OfferIntroModal'
import MemberConnections from 'containers/Home/YourConnections/MemberConnections'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import PillV2 from 'ui-components/PillV2'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import { Team } from 'utils/types'

export const DefaultAvatar = styled(Typography)`
  background-color: ${({ theme }) => theme.colors.mid_purple};
`

interface Props {
  team: Team
  memberUuid?: string
  connectionStatus?: any
  checkConnectionStatus?: () => void
  refetchHome?: () => void
  canUseExtensionConnections?: boolean
  hideSearch?: boolean
  noConnectionsText?: string | null
  showAddSection?: boolean
  title?: string
  searchInputPlaceholder?: string
  showPersonaPills?: boolean
  personRowCta?: string
  onPersonRowCtaClick?: (person: { uuid: string }) => void
  onClose?: any
}

const OfferIntroWidget: React.FC<Props> = ({
  team,
  memberUuid,
  connectionStatus,
  refetchHome,
  checkConnectionStatus,
  canUseExtensionConnections = false,
  hideSearch = false,
  noConnectionsText = null,
  showAddSection = true,
  searchInputPlaceholder = 'Find people you can intro',
  title = 'Offer intros',
  showPersonaPills = true,
  personRowCta,
  onPersonRowCtaClick,
  onClose,
}) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const [showShareConnectionsWidget, setShowShareConnectionsWidget] = useState(false)
  const { isAdmin } = useAccessControl(team.slug)
  const { showModal } = useModal()
  const history = useHistory()

  const ready = useSearchParam('ready')
  const [expandAddConnections, setExpandAddConnections] = useState(!!ready)
  const [showLinkedinPopup, setShowLinkedinPopup] = useState(false)
  const ICPs = team?.ideal_customer_profile ? team?.ideal_customer_profile?.split(',') : undefined
  const [countIcps, setCountIcps] = useState(4)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const {
    data: status,
    refetch: checkHomeConnectionStatus,
    isLoading,
  } = useQuery(['connectionStatus'], () => callApi(api.getHomeConnectionsStats), {
    refetchInterval: 1000 * 3,
    refetchIntervalInBackground: !window.DISABLE_CONNECTION_STATUS_POLLING,
    enabled: !connectionStatus,
  })

  useEffect(() => {
    if (showShareConnectionsWidget && !expandAddConnections) {
      setExpandAddConnections(true)
    }
  }, [showShareConnectionsWidget])

  const offerIntroModal: RenderModal = (resolve) => (
    <OfferIntroModal
      onHide={() => {
        resolve()
      }}
      teamSlug={team.slug}
      query={debouncedQuery}
      onBannerClick={() => {
        setExpandAddConnections(true)
        resolve()
      }}
      showBanner={totalConnectionCount === 0}
    />
  )

  const currentStatus = connectionStatus || status || {}
  const { linkedin_export, google_contacts } = currentStatus || {}
  const connections_count = currentStatus?.connections_count || linkedin_export?.count || 0
  // const google_contacts_count = google_contacts?.count || 0
  const totalConnectionCount = connections_count

  if (hideSearch) return null

  return (
    <Widget
      title={title}
      cta={
        onClose && (
          <CabalButton
            variant="link"
            onClick={(e) => {
              e.preventDefault()
              onClose()
            }}
            padding={'0'}
          >
            Done adding
          </CabalButton>
        )
      }
    >
      <div className="flex w-full gap-1">
        <div className="w-full flex-grow-1">
          <SearchInput
            ref={searchInputRef}
            value={query}
            onChange={(c) => setQuery(c.target.value)}
            placeholder={searchInputPlaceholder}
            autoFocus={true}
          />
        </div>

        {isAdmin && (!ICPs || ICPs.length === 0) && (
          <div className="flex-shrink-0">
            <CabalButton
              onClick={() => history.push(`/${team.slug}/admin/asks`)}
              variant="secondary"
              leftIcon={<i className="far fa-plus fa-fw"></i>}
            >
              Add personas
            </CabalButton>
          </div>
        )}
      </div>

      {showPersonaPills && ICPs && ICPs.length > 0 && (
        <div className="flex items-center mt-2.5 gap-2.5">
          <div className="flex flex-wrap gap-1">
            {ICPs?.slice(0, countIcps).map((icp, idx) => (
              <PillV2
                onClick={() => setQuery(icp)}
                key={idx}
                active={icp === debouncedQuery}
                title={icp}
                className="px-2 py-1.5"
              />
            ))}
            {ICPs?.length > countIcps && (
              <PillV2
                onClick={() => setCountIcps(ICPs.length)}
                active={false}
                title={`+${ICPs.length - countIcps}`}
                className="px-2 py-1.5"
              />
            )}
            {isAdmin && (
              <CabalButton
                variant="tertiary"
                onClick={() => history.push(`/${team.slug}/admin/asks`)}
                padding="0 8px"
              >
                Edit
              </CabalButton>
            )}
          </div>
        </div>
      )}
      <div className="mt-4">
        {debouncedQuery && debouncedQuery.length > 0 && (
          <div className="mb-4 px-3">
            <CabalButton
              variant="link"
              onClick={() => showModal(offerIntroModal, 'offer_intro_modal')}
              leftIcon={<i className="far fa-plus fa-xs" />}
              padding="0"
            >
              {`Add connection "${debouncedQuery}"`}
            </CabalButton>
          </div>
        )}

        <MemberConnections
          isUserDashboard={false}
          isHome={true}
          debouncedQuery={
            debouncedQuery ? [debouncedQuery] : ICPs?.[0] ? [ICPs?.[0]] : ['founder', 'ceo']
          }
          personRowCta={personRowCta}
          onPersonRowCtaClick={onPersonRowCtaClick}
        />
      </div>
    </Widget>
  )
}

export default OfferIntroWidget
