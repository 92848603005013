import styled from 'styled-components'
import tw from 'twin.macro'

export const BORDER_SIZE = 4

export const Container = styled.div`
  ${tw`w-screen h-screen flex-col flex`}

  background-color: ${({ theme }) => theme.layout.main_bg_color};
`

export const Header = styled.div`
  ${tw`flex-initial flex justify-between p-4 xl:p-8 items-center`}
`

export const Footer = styled.div`
  ${tw`flex-initial flex justify-between p-4 xl:p-8`}
`

export const EditorContainer = styled.div`
  ${tw`flex flex-1 p-4 justify-center overflow-x-auto`}

  background-color: ${({ theme }) => theme.colors.primary_bg};
`

export const FieldsPicker = styled.div`
  ${tw`pr-4 overflow-y-auto relative select-none`}

  max-width: 40ch;
  min-width: 30ch;
`

export const DocumentViewport = styled.div`
  ${tw`overflow-y-auto relative shadow`}

  min-width: 70ch;
`

export const DraggedField = styled.div`
  ${tw`relative text-black text-center w-full h-full relative select-none z-10 cursor-move`}
  background: rgba(92, 105, 209, 0.16);
  border-width: 6px;
  border-radius: 2px;
  border-style: solid;
  border-color: rgba(92, 105, 209, 0.3);

  &:after {
    ${tw`capitalize`}
    content: attr(data-name);
    white-space: nowrap;
    background-color: white;
    position: absolute;
    left: 0;
    bottom: 100%;
    margin-bottom: 10px;
    display: none;
    font-size: 0.75rem;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0 0 4px #000;
  }

  & .anchor {
    position: absolute;
    width: ${BORDER_SIZE + 4}px;
    height: ${BORDER_SIZE + 4}px;
    background-color: rgba(92, 105, 209, 0.5);
    border-radius: 100%;
    top: -${BORDER_SIZE + 2}px;
    left: -${BORDER_SIZE + 2}px;
    bottom: -${BORDER_SIZE + 2}px;
    right: -${BORDER_SIZE + 2}px;

    &.tl {
      bottom: unset;
      right: unset;
    }
    &.tr {
      bottom: unset;
      left: unset;
    }
    &.bl {
      top: unset;
      right: unset;
    }
    &.br {
      top: unset;
      left: unset;
    }
  }

  &:hover {
    border-color: rgba(92, 105, 209, 0.5);
  }

  &:hover:after {
    display: block;
  }

  &:hover > span {
    display: none;
  }

  &:hover + button {
    background-color: white;
    box-shadow: 0 0 4px #000;
  }

  & + button {
    ${tw`absolute`}
    color: #000;
    top: 50%;
    left: 100%;
    transform: translate(${BORDER_SIZE * 2}px, -50%);
    width: 15px;
    height: 15px;
  }

  & + button > i {
    transform: translateY(-4px);
  }
`
