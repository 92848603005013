import React, { useEffect, useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useDebounce, useSetState } from 'react-use'
import { SetOptional } from 'type-fest'

import Attachments from 'components/Attachments'
import ButtonsModal from 'components/SendMessage/ButtonsModal'
import ButtonsPreview from 'components/SendMessage/ButtonsPreview'
import RecipientsField from 'components/SendMessage/RecipientsField'
import TimeAgo from 'components/TimeAgo'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import Card from 'global/Card'
import { CheckBox, Select, TextInput, UploadButton } from 'global/Input'
import Loading from 'global/Loading'
import Modal, { useModal } from 'global/Modal'
import CKEditor from 'global/TextEditor/ckeditor'
import CabalTitle from 'global/Title'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'
import { CompanySlugParam, EmailTemplate } from 'utils/types'

import DeleteTemplate from './DeleteTemplate'
import ShareTemplate from './ShareTemplate'
import VariablesList from './VariablesList'

interface EditTemplate extends CompanySlugParam {
  id?: string
}

interface Props {
  onHide: () => void
  reloadTemplates: () => void
  templateUuid?: string
  teamSlug: string
}

const EditTemplate: React.FC<Props> = ({ onHide, reloadTemplates, templateUuid, teamSlug }) => {
  const [id, setId] = useState<string | undefined>(templateUuid)
  const { showModal } = useModal()
  const { isMobile } = useColorMode()
  const { canEditTeam } = useAccessControl(teamSlug)
  const history = useHistory()
  const creatingTemplate = !id
  const [template, setTemplate] = useSetState<
    SetOptional<EmailTemplate, 'uuid' | 'user' | 'public_template' | 'can_update' | 'can_delete'>
  >({
    name: '',
    subject: '',
    attachments: [],
    body: '',
    default_for: null,
    public_to_team: false,
    to: [],
    cc: [],
    bcc: [],
  })
  useDebounce(
    () => {
      if (!template.name && !template.body) return
      upsertTemplate(true)
    },
    2500,
    [template],
  )
  const [savedAt, setSavedAt] = useState<Date>()

  useEffect(() => {
    const defaultForParam = new URL(window.location.href).searchParams.get('default_for')

    if (!id && defaultForParam) {
      setTemplate((p) => ({ ...p, default_for: defaultForParam as any }))
    }
  }, [])

  const {
    mutate: upsertTemplate,
    isLoading: saving,
    isSuccess: uspertedSuccessfully,
  } = useMutation(
    ['upsertTemplate'],
    (_autosave?: boolean) => {
      return callApi(creatingTemplate ? api.createTemplate : api.updateTemplate, template!)
    },
    {
      onSuccess: ({ template }, isAutoSave) => {
        setId(template.uuid)
        setSavedAt(new Date())
        if (!isAutoSave) {
          reloadTemplates()
          onHide()
        }
      },
    },
  )

  const { isLoading: loadingTemplate, data: persistedTemplate } = useQuery(
    ['getTemplate', teamSlug, id],
    () => callApi(api.getTemplate, teamSlug, id!),
    {
      enabled: !!id,
      onSuccess: ({ template }) => {
        setTemplate(template)
      },
    },
  )

  if (loadingTemplate && !uspertedSuccessfully) return <Loading className="my-10" />

  return (
    <Modal
      dangerouslyBypassFocusLock={true}
      show={true}
      onHide={onHide}
      header={`${creatingTemplate ? 'Create' : 'Edit'} Template`}
    >
      {/* <CabalTitle title={`${creatingTemplate ? 'Create' : 'Edit'} Template`} /> */}

      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-2 mb-2">
        <div className="flex w-full sm:w-auto items-center gap-2">
          {/* <div className="mr-2">
              <CabalButton
                variant="tertiary"
                size="small"
                leftIcon={<i className="far fa-arrow-left" />}
                onClick={() => history.push(`/${company_slug}/templates`)}
              />
            </div>
            <div>
              <Typography fontSize="16" fontWeight={600}>
                {creatingTemplate ? 'Create' : 'Edit'} Template
              </Typography>
            </div> */}
          {canEditTeam && (
            <Typography fontSize="12" component="div" className="flex-1 text-right sm:text-left">
              <CheckBox
                label="Public to team"
                className="mr-2"
                checked={!!template.public_to_team || !!template.default_for}
                disabled={!!template.default_for}
                onChange={(e) => setTemplate({ public_to_team: e.currentTarget.checked })}
              />
            </Typography>
          )}
        </div>
        <Typography fontSize="12" component="div" className="contents">
          {canEditTeam && (
            <>
              <Select<EmailTemplate['default_for']>
                className="mr-2 w-full sm:w-auto"
                compact
                placeholder="Default for (optional)"
                options={[
                  {
                    label: '-- None --',
                    value: null,
                  },
                  {
                    label: 'Intro template',
                    value: 'intro_template',
                  },
                  {
                    label: 'Member intro template',
                    value: 'member_intro_template',
                  },
                  {
                    label: 'Employees intro template',
                    value: 'employee_intro_template',
                  },
                  {
                    label: 'Member invite template',
                    value: 'member_invite_template',
                  },
                ]}
                value={template.default_for}
                onChange={(v) => {
                  v &&
                    setTemplate(() => {
                      const update: Partial<EmailTemplate> = { default_for: v }
                      if (!persistedTemplate?.template.public_to_team) update.public_to_team = !!v

                      return update
                    })
                }}
              />
            </>
          )}
        </Typography>
        <div>
          {template.uuid && (
            <>
              <ShareTemplate template={template as EmailTemplate} teamSlug={teamSlug} />
            </>
          )}
          <CabalButton
            variant="tertiary"
            size="small"
            rightIcon={<i className="fas fa-question-circle" />}
            onClick={() => {
              showModal(
                (r) => (
                  <Modal show onHide={r} header="Variables">
                    <VariablesList />
                  </Modal>
                ),
                'template-variables',
              )
            }}
          >
            Variables
          </CabalButton>

          {template.uuid && (
            <DeleteTemplate
              disabled={template.can_delete === false}
              uuid={template.uuid}
              teamSlug={teamSlug}
            />
          )}
        </div>
      </div>
      <div className="mb-3">
        <TextInput
          label="Name"
          labelPosition="left"
          value={template.name}
          onChange={(e) => setTemplate({ name: e.currentTarget.value })}
        />
      </div>
      <div className="mb-3">
        <TextInput
          label="Subject&nbsp;(optional)"
          labelPosition="left"
          value={template.subject}
          onChange={(e) => setTemplate({ subject: e.currentTarget.value })}
        />
      </div>
      <div className="mb-3">
        <RecipientsField
          allowCustomEmail
          allowGroups={false}
          allowAdvisors={false}
          label="To&nbsp;(optional)"
          teamSlug={teamSlug}
          onSelect={(r) => r && setTemplate({ to: r })}
          selected={template.to}
        />
      </div>
      <div className="mb-3">
        <RecipientsField
          allowCustomEmail
          allowGroups={false}
          allowAdvisors={false}
          label="Cc&nbsp;(optional)"
          teamSlug={teamSlug}
          onSelect={(r) => r && setTemplate({ cc: r })}
          selected={template.cc}
        />
      </div>
      <CKEditor
        minHeight="120px"
        value={template.body}
        onChange={(v) => setTemplate({ body: v })}
      />
      <div className="flex items-center gap-2">
        <div className="flex-1">
          <div className="mt-3 mb-3">
            <CabalButton
              leftIcon={<i className="far fa-plus-square" />}
              tooltip="Edit Buttons"
              variant="secondary"
              size="small"
              className="mr-3"
              onClick={() =>
                showModal(
                  (r) => (
                    <ButtonsModal
                      show
                      onHide={r}
                      inputs={template.buttons || []}
                      onUpdate={(i) => setTemplate({ buttons: i })}
                    />
                  ),
                  'template-buttons',
                )
              }
            />
            <UploadButton
              onUpload={(uuid, url, file) => {
                setTemplate({
                  attachments: [
                    ...template.attachments,
                    {
                      upload_uuid: uuid,
                      file_type: file.type,
                      file_size: file.size,
                      url: url,
                      file_name: file.name,
                    },
                  ],
                })
              }}
              text=""
              triggerProps={{
                variant: 'secondary',
                leftIcon: <i className="far fa-paperclip" />,
                size: 'small',
                tooltip: 'Attachments',
              }}
            />
          </div>
          <ButtonsPreview inputs={template.buttons || []} />
          <Attachments
            attachments={template.attachments || []}
            onDelete={(a) =>
              setTemplate({
                attachments: template.attachments.filter((i) => i.upload_uuid !== a.upload_uuid),
              })
            }
          />
        </div>
        <div className="flex-initial">
          {savedAt && (
            <Typography fontSize="12" color="gray">
              Saved <TimeAgo datetime={savedAt} />
            </Typography>
          )}

          <CabalButton
            onClick={() => upsertTemplate(false)}
            working={saving}
            size="medium"
            className="ml-3"
            disabled={template.can_update === false || !template.name || !template.body}
            tooltip={!template.name ? 'Name is required' : undefined}
          >
            {creatingTemplate ? 'Create' : 'Save'} template
          </CabalButton>
        </div>
      </div>
    </Modal>
  )
}

export default EditTemplate
