import { useComposer } from 'components/Composer'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import { useModal } from 'global/Modal'
import React from 'react'
import { cabalToast } from 'ui-components/Toast'
import { useMutation } from 'react-query'
import HeadShake from 'react-reveal/HeadShake'
import { useTeamSlug } from 'store/hooks'
import api, { callApi } from 'utils/api'
import { InvestorCompany, InvestorCompanyJob, TalentPOC } from 'utils/types/investor'
import BulkEditJobsModal from './BulkEditJobsModal'

interface Props {
  companies: InvestorCompany[]
  openArchive: boolean
  setOpenArchive: (open: boolean) => void
  openJobModal: () => void
  selectedUuids: Set<string>
  jobs: InvestorCompanyJob[]
  allJobs: InvestorCompanyJob[]
  setJobs: (jobs: InvestorCompanyJob[]) => void
  resetSelectedUuids: () => void
}

const GlobalActions: React.VFC<Props> = ({
  companies,
  openArchive,
  setOpenArchive,
  openJobModal,
  selectedUuids,
  jobs,
  setJobs,
  allJobs,
  resetSelectedUuids,
}) => {
  const teamSlug = useTeamSlug()
  const { showModal, closeModal } = useModal()
  const { canManageCompany } = useAccessControl(teamSlug)
  const { compose } = useComposer(teamSlug)

  const selectedJobs = jobs?.filter((j) => selectedUuids.has(j.uuid)) || []

  const { mutate: handleBulkUpdate, isLoading } = useMutation(
    ({ action, talent_poc }: { action: string; talent_poc?: TalentPOC }) => {
      const updatedJobs = selectedJobs.map((j: InvestorCompanyJob) => {
        const updatedJob: Partial<InvestorCompanyJob> = { uuid: j.uuid }
        if (action === 'archive') {
          updatedJob.archived = 'true'
        } else if (action === 'unarchive') {
          updatedJob.archived = 'false'
        } else if (action === 'talent_poc') {
          updatedJob.talent_poc = talent_poc
        }
        return updatedJob
      })
      return callApi(
        api.bulkUpdateInvestorCompanyJob,
        updatedJobs,
        Array.from(selectedUuids),
        teamSlug,
      )
    },
    {
      onSuccess: ({ jobs: newJobs }) => {
        const updatedJobs = allJobs.map((j) => {
          if (selectedUuids.has(j.uuid)) {
            return newJobs.find((nj: InvestorCompanyJob) => nj.uuid === j.uuid)
          }
          return j
        })
        setJobs(updatedJobs)
        cabalToast({ style: 'success', content: 'Updated successfully!' })
        resetSelectedUuids()
        closeModal('bulk-edit-jobs-modal')
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const openBulkEditJobsModal = (field: string) => {
    showModal(
      (resolve) => (
        <BulkEditJobsModal
          onHide={resolve}
          field={field}
          isLoading={isLoading}
          handleUpdate={handleBulkUpdate}
        />
      ),
      'bulk-edit-jobs-modal',
    )
  }

  const getComposerBody = (selectedJobs: InvestorCompanyJob[]) => {
    let toInsert = `<p>`
    selectedJobs.forEach((job: InvestorCompanyJob) => {
      const {
        title,
        description,
        uuid,
        url,
        investor_company: { company_name, uuid: companyUuid },
      } = job

      const requestUrl = `${window.location.origin}/investor-companies/${companyUuid}/intro-requests/confirm?team_slug=${teamSlug}&job_uuid=${uuid}`
      const descriptionElement = description ? `${description}<br>` : ''
      const roleElement = url ? `<a href="${url}">${title}</a>` : title
      toInsert += `
              <li>
                <b>${company_name} - ${roleElement}</b><br>
                ${descriptionElement}
                <a href="${requestUrl}">Request Intro</a><br>
              </li>
          `
    })
    return toInsert + '</p>'
  }

  return (
    <div>
      {canManageCompany && companies.length > 0 && (
        <>
          {selectedUuids.size > 0 && (
            <div className="inline-block">
              <DropDownMenu
                trigger={
                  <HeadShake>
                    <CabalButton
                      component="div"
                      size="small"
                      variant="secondary"
                      rightIcon={<i className="fas fa-caret-down" />}
                      data-testid="portfolio-jobs-bulk-action-btn"
                    >
                      Bulk Action
                    </CabalButton>
                  </HeadShake>
                }
                menuItems={[
                  {
                    label: 'Email',
                    onSelect: () => {
                      compose({ body: getComposerBody(selectedJobs) })
                    },
                  },
                  {
                    label: openArchive ? 'Unarchive' : 'Archive',
                    onSelect: () =>
                      handleBulkUpdate({ action: openArchive ? 'unarchive' : 'archive' }),
                  },
                  {
                    label: 'Set Point of Contact',
                    onSelect: () => openBulkEditJobsModal('talent_poc'),
                  },
                ]}
              />
            </div>
          )}
          <CabalButton
            onClick={() => setOpenArchive(!openArchive)}
            variant="tertiary"
            size="small"
            className="mr-3"
            leftIcon={<i className="far fa-archive fa-fw" />}
            data-testid={
              openArchive ? 'portfolio-jobs-active-jobs-btn' : 'portfolio-jobs-archived-jobs-btn'
            }
          >
            {openArchive ? 'View active jobs' : 'View archived jobs'}
          </CabalButton>
          <CabalButton
            onClick={() => openJobModal()}
            variant="secondary"
            size="small"
            leftIcon={<i className="far fa-suitcase fa-fw" />}
            data-testid="portfolio-jobs-add-job-btn"
          >
            Add Job
          </CabalButton>
        </>
      )}
    </div>
  )
}

export default GlobalActions
