import React from 'react'

import { useHistory } from 'react-router-dom'

import Resources from 'containers/Resources'
import Typography from 'global/Typography'

import Title from './Title'
import { HomeSectionWrapper } from './styles'

interface Props {
  teamSlug: string
}

const ResourcesSection: React.FC<Props> = ({ teamSlug }) => {
  const history = useHistory()
  const [loading, setLoading] = React.useState(true)

  return (
    <>
      <HomeSectionWrapper id={`${teamSlug}-resources`}>
        <div>
          {!loading && (
            <Title label="Resources" onClick={() => history.push(`/${teamSlug}/resources`)} />
          )}
          <Resources homeView onFetchComplete={() => setLoading(false)} />
        </div>
      </HomeSectionWrapper>
    </>
  )
}

export default ResourcesSection
