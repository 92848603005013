import React, { useEffect, useState } from 'react'

import Expand from 'react-expand-animated'
import { useQuery } from 'react-query'
import { useSearchParam } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'

import Title from 'components/CompanyHome/Title'
import ConnectionStatus from 'containers/Asks/ConnectionStatus'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useCurrentUser, useCurrentUserSettings } from 'store/hooks'
import TokenInput from 'ui-components/TokenInput'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import { Team } from 'utils/types'
import { numWithCommas } from 'utils/ui'

import ConnectionSuccessBanner from './ConnectionSuccessBanner'
import MemberConnections from './MemberConnections'

const StyledCard = styled.div<{ border: boolean }>`
  ${tw`p-2`}
  border-radius: 8px;
  transition: background-color 200ms ease;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: ${({ theme, border }) => (border ? `1px solid ${theme.colors.border}` : 'none')};
`

const Summary = styled.div<{ skinny: boolean }>`
  ${tw`flex items-center justify-between`}
`

const Border = styled.div`
  height: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`

interface Props {
  skinny?: boolean
  canUseExtensionConnections?: boolean
  team?: Team
  memberUuid?: string
  connectionStatus?: any
  checkConnectionStatus?: () => void
  refetchHome?: () => void
  border?: boolean
  onUpload?: () => void
  showHeader?: boolean
  defaultQuery?: string[]
  isHome?: boolean
  isUserDashboard?: boolean
  withWidget?: boolean
}

const YourConnections: React.FC<Props> = ({
  skinny = false,
  canUseExtensionConnections = false,
  team,
  memberUuid,
  connectionStatus,
  checkConnectionStatus,
  refetchHome = undefined,
  onUpload = () => {},
  border = false,
  showHeader = false,
  isHome = false,
  isUserDashboard = false,
  showResults = false,
  withWidget = false,
}) => {
  const { user } = useCurrentUser()
  const ready = useSearchParam('ready')
  const { settings, updateSetting } = useCurrentUserSettings()
  const [showShareConnectionsWidget, setShowShareConnectionsWidget] = useState(false)
  const [expandAddConnections, setExpandAddConnections] = useState(
    settings.open_connections_widget || !!ready,
  )
  const [addedCount, setAddedCount] = useState(0)
  const [query, setQuery] = useState<string[]>([])
  const debouncedQuery = useDebouncedValue(query, 300)

  const {
    data: status,
    isLoading,
    refetch: checkHomeConnectionStatus,
  } = useQuery(['connectionStatus'], () => callApi(api.getHomeConnectionsStats), {
    refetchInterval: 1000 * 3,
    refetchIntervalInBackground: !window.DISABLE_CONNECTION_STATUS_POLLING,
    enabled: !connectionStatus,
  })

  const currentStatus = connectionStatus || status || {}

  const { linkedin_export, google_contacts } = currentStatus
  const connections_count = currentStatus?.connections_count || linkedin_export?.count || 0
  // const google_contacts_count = google_contacts?.count || 0

  const connectionCopy = () => {
    if (!isLoading) {
      return `${connections_count.toLocaleString('en-US')} connections`
    }
    return ''
  }

  useEffect(() => {
    if (showShareConnectionsWidget && !expandAddConnections) {
      setExpandAddConnections(true)
    }
  }, [showShareConnectionsWidget])

  useEffect(() => {
    updateSetting('open_connections_widget', expandAddConnections)
    if (!expandAddConnections) {
      updateSetting('connection_dropzone_open', false)
    }
  }, [expandAddConnections])

  // if (!currentStatus) {
  //   return <></>
  // }

  const content = (
    <div>
      {addedCount > 0 && (
        <ConnectionSuccessBanner addedCount={addedCount} onHide={() => setAddedCount(0)} />
      )}
      {showHeader && <Title label="Your Connections" />}
      {/* {isLoading && (
        <div className="flex items-center justify-center my-2">
          <Loading />
        </div>
      )} */}
      {!isLoading && (
        <StyledCard border={border}>
          <>
            <div className="flex flex-col">
              {!withWidget && (
                <Summary skinny={skinny}>
                  <div className="flex items-center space-x-2">
                    <div className="shrink-0">
                      <Avatar size={'24'} name={user.name} src={user.avatar_url} />
                    </div>
                    <Typography fontSize="12" fontWeight={600}>
                      {connectionCopy()}
                    </Typography>
                  </div>
                  <div className="flex items-center space-x-4">
                    {!showShareConnectionsWidget && (
                      <CabalButton
                        variant={'link'}
                        onClick={() => {
                          setExpandAddConnections(!expandAddConnections)
                        }}
                        leftIcon={<i className="far fa-arrows-left-right"></i>}
                      >
                        {expandAddConnections ? 'Done' : 'Add Connections'}
                      </CabalButton>
                    )}
                  </div>
                </Summary>
              )}

              <Expand open={expandAddConnections}>
                <>
                  <Border />
                  <ConnectionStatus
                    team={team}
                    connectionStatus={currentStatus}
                    checkConnectionStatus={checkConnectionStatus || checkHomeConnectionStatus}
                    canUseExtensionConnections={canUseExtensionConnections}
                    memberUuid={memberUuid}
                    globalHome
                    onUpload={onUpload}
                    setShowShareConnectionsWidget={setShowShareConnectionsWidget}
                    refetchHome={refetchHome}
                  />
                </>
              </Expand>

              <div className="pt-2">
                <TokenInput
                  icon={<i className="far fa-search"></i>}
                  placeholder={`Search your ${numWithCommas(connections_count)} connections `}
                  includeInputValueInValues
                  value={query}
                  onChange={(c) => setQuery(c)}
                />
              </div>
            </div>
          </>
          {(showResults || (debouncedQuery && !!debouncedQuery.length)) && (
            <MemberConnections
              isUserDashboard={isUserDashboard}
              isHome={isHome}
              debouncedQuery={debouncedQuery}
            />
          )}
        </StyledCard>
      )}
    </div>
  )

  return (
    <>
      {withWidget ? (
        <Widget
          title="Your Connections"
          cta={
            <CabalButton
              variant={'link'}
              onClick={() => {
                setExpandAddConnections(!expandAddConnections)
              }}
              leftIcon={<i className="far fa-arrows-left-right"></i>}
              padding="0"
            >
              {expandAddConnections ? 'Done' : 'Add Connections'}
            </CabalButton>
          }
        >
          <div className="-mt-4 -mx-2">{content}</div>
        </Widget>
      ) : (
        content
      )}
    </>
  )
}

export default YourConnections
