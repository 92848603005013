import * as React from 'react'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import Typography from 'global/Typography'
import G from '../../images/google-icon.png'

import EmptyMembersImage from '../../images/empty-members.png'

const MembersEmptyState: React.FC = () => {
  return (
    <EmptyState
      image={<img src={EmptyMembersImage} />}
      body={
        <Typography textAlign="center" component="h5">
          Connect your Google Contacts to manage your personal contacts and connections.
        </Typography>
      }
      cta={
        <CabalButton
          variant="auth"
          component="a"
          leftIcon={<img src={G} width="18" />}
          href="/auth/gcontacts"
        >
          Connect Google Contacts
        </CabalButton>
      }
    />
  )
}

export default MembersEmptyState
