import * as React from 'react'

import AvatarUpload, { AvatarUploadRef } from 'global/AvatarUpload'
import CabalButton from 'global/CabalButton'
import { TextArea, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import { Team } from 'utils/types'

interface Props {
  show: boolean
  onHide: () => void
  loadInfo: () => void
  logoUrl: string
  team?: Team
}

const CompanyInfoModal: React.FC<Props> = ({ team, show, onHide, loadInfo, logoUrl }) => {
  const [working, setWorking] = React.useState(false)
  const [updatedTeam, setUpdatedTeam] = React.useState<Team>()

  const avatarUploadRef = React.useRef<AvatarUploadRef>(null)

  const handleSubmit = () => {
    if (!updatedTeam) return
    setWorking(true)

    api
      .updateTeam(updatedTeam.slug, {
        team: {
          name: updatedTeam.name,
          description: updatedTeam.description,
          twitter_url: updatedTeam.twitter_url,
          linkedin_url: updatedTeam.linkedin_url,
          website: updatedTeam.website,
        },
      })
      .then(() => {
        avatarUploadRef.current?.upload()
      })
      .finally(() => {
        reloadTeam()
      })
  }

  const reloadTeam = () => {
    setWorking(false)
    loadInfo()
    onHide()
    cabalToast({ style: 'success', content: 'Successfully updated the Company.' })
  }

  React.useEffect(() => {
    if (team) setUpdatedTeam(team)
  }, [team])

  if (!updatedTeam) return <></>

  return (
    <Modal
      show={show}
      onHide={onHide}
      header="Edit company info"
      rightActions={
        <CabalButton
          onClick={handleSubmit}
          variant="primary"
          working={working}
          data-testid="save-company-info"
        >
          {'Save'}
        </CabalButton>
      }
    >
      <AvatarUpload
        ref={avatarUploadRef}
        imageUrl={logoUrl}
        domain={updatedTeam.website}
        onUpload={loadInfo}
        attachTo="company_logo"
        className="my-4"
      />

      <TextInput
        value={updatedTeam.name}
        placeholder="Company Name"
        className="w-full mb-4"
        onChange={(e) => setUpdatedTeam({ ...updatedTeam, name: e.target.value })}
        data-testid="company-name"
      />
      <TextInput
        value={updatedTeam.website}
        placeholder="Website URL"
        className="w-full mb-4"
        onChange={(e) => setUpdatedTeam({ ...updatedTeam, website: e.target.value })}
        data-testid="company-website"
      />

      <TextInput
        value={updatedTeam.twitter_url}
        placeholder="Twitter URL"
        className="w-full mb-4"
        onChange={(e) => setUpdatedTeam({ ...updatedTeam, twitter_url: e.target.value })}
        data-testid="twitter-url"
      />

      <TextInput
        value={updatedTeam.linkedin_url}
        placeholder="LinkedIn URL"
        className="w-full mb-4"
        onChange={(e) => setUpdatedTeam({ ...updatedTeam, linkedin_url: e.target.value })}
      />

      <TextArea
        value={updatedTeam.description}
        placeholder="Company Description"
        className="w-full"
        data-testid="company-description"
        onChange={(e) => setUpdatedTeam({ ...updatedTeam, description: e.target.value })}
      />
    </Modal>
  )
}

export default CompanyInfoModal
