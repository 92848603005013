import axios from 'axios'

export default {
  getNotes: (advisor_uuid: string) => {
    return axios.get(`/api/advisors/${advisor_uuid}/notes`)
  },

  createNote: (advisor_uuid: string, body: string) => {
    const params = { body }
    return axios.post(`/api/advisors/${advisor_uuid}/notes`, params)
  },

  updateNote: (advisor_uuid: string, note_id: number, body: string) => {
    const params = { body }
    return axios.put(`/api/advisors/${advisor_uuid}/notes/${note_id}`, params)
  },

  deleteNote: (advisor_uuid: string, note_id: number) => {
    return axios.delete(`/api/advisors/${advisor_uuid}/notes/${note_id}`)
  },
}
