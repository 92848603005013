import * as React from 'react'

import max from 'lodash/max'
import min from 'lodash/min'
import { parseToRgb } from 'polished'
import { RgbaColor } from 'polished/lib/types/color'
import AvatarEditor from 'react-avatar-editor'
import styled, { useTheme } from 'styled-components'
import tw from 'twin.macro'
import validator from 'validator'

import Avatar from 'global/Avatar'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import errorMessages from 'utils/errorMessages'
import useUploadFile from 'utils/hooks/useUploadFile'
import { StyleProps, UploadModel } from 'utils/types'

import CabalButton from './CabalButton'
import Typography from './Typography'

interface Props extends StyleProps {
  imageUrl?: string
  onImageChange?: (file?: File | null) => void
  attachTo?: string
  domain?: string
  email?: string
  onUpload?: () => void
  autoUploadOnChange?: boolean
}

export interface AvatarUploadRef {
  upload: () => Promise<UploadModel | undefined | null>
}

const AvatarUploadContainer = styled.div`
  ${tw`flex gap-2`}
`

const SettingsContainer = styled.div`
  ${tw`flex flex-col gap-2`}
`

const AvatarUpload = React.forwardRef<AvatarUploadRef, Props>(
  (
    {
      imageUrl: _imageUrl,
      onImageChange,
      onUpload,
      attachTo,
      autoUploadOnChange = false,
      domain,
      email,
      ...extraProps
    },
    ref,
  ) => {
    const imageUrl = React.useMemo(() => {
      if (_imageUrl && !validator.isURL(_imageUrl)) {
        const url = new URL(_imageUrl, `${window.location.protocol}//${window.location.host}`)
        url.searchParams.set('v1', '1')
        return url.href
      }
      return _imageUrl
    }, [_imageUrl])

    const [image, setImage] = React.useState<string | File | undefined>(imageUrl)
    const [scale, setScale] = React.useState(1)

    const { uploadFile } = useUploadFile()

    const editor = React.useRef<AvatarEditor>(null)
    const zoom = React.useRef<HTMLInputElement>(null)

    const theme = useTheme()
    const { red, green, blue } = parseToRgb(theme.colors.primary_bg) as RgbaColor

    const acceptedFileTypes = 'image/'

    const handleNewScale = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!editor.current) return

      const newScale = parseFloat(e.currentTarget.value)
      setScale(newScale)
    }

    const resetScale = () => {
      if (!zoom.current) return

      setScale(1)
      zoom.current.value = '1'
    }

    const handleNewImage = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.files && e.currentTarget.files[0].type.includes(acceptedFileTypes)) {
        setImage(e.currentTarget.files[0])
        resetScale()
        autoUploadOnChange && handleUpload()
      } else {
        cabalToast({ style: 'error', content: errorMessages.invalidImage })
      }
    }

    const handleAutoFillDomain = async () => {
      if (!domain || !validator.isURL(domain)) return

      try {
        const {
          data: {
            company: {
              logo,
              twitter: { avatar: twitterAvatar },
            },
          },
        } = await api.findOrganization(domain)
        const avatar = logo || twitterAvatar

        if (!avatar) throw 'avatar not found'

        setImage(avatar)
      } catch (e) {
        cabalToast({ style: 'error', content: errorMessages.emailDpFind })
      }
    }

    const handleAutoFillEmail = async () => {
      if (!email || !validator.isEmail(email)) return

      try {
        const {
          data: {
            person: {
              github: { avatar: githubAvatar },
              gravatar: { avatar: gravatar },
              twitter: { avatar: twitterAvatar },
            },
          },
        } = await api.findPerson(email)
        const avatar = twitterAvatar || githubAvatar || gravatar

        if (!avatar) throw 'avatar not found'

        setImage(avatar)
      } catch (e) {
        cabalToast({ style: 'error', content: errorMessages.emailDpFind })
      }
    }

    const getImageFileFromEditor = () =>
      new Promise<File>((resolve, reject) => {
        if (!editor.current) {
          reject()
          return
        }

        editor.current.getImage().toBlob(async (blob: Blob | null) => {
          if (!blob) {
            reject()
            return
          }

          const file = new File([blob], 'avatar', { type: 'image/png' })
          resolve(file)
        })
      })

    const handleUpload = React.useCallback(
      () =>
        new Promise<UploadModel | null | undefined>((resolve, reject) => {
          if (!image) {
            resolve(null)
            return
          }

          if (image === imageUrl && scale === 1) {
            onUpload?.()
            resolve(undefined)
            return
          }

          if (!editor.current) {
            reject()
            return
          }

          getImageFileFromEditor().then(async (file: File) => {
            try {
              resolve(
                await uploadFile({
                  file,
                  attachTo: attachTo,
                }),
              )
              onUpload?.()
            } catch (e) {
              reject(e)
              cabalToast({ style: 'error', content: errorMessages.fileUpload })
            }
          })
        }),
      [image, imageUrl, scale, onUpload, attachTo, uploadFile],
    )

    React.useImperativeHandle(
      ref,
      () => ({
        upload: handleUpload,
      }),
      [handleUpload],
    )

    const triggerOnChange = () => {
      setTimeout(() => {
        editor.current?.getImage()?.toBlob(async (blob: Blob | null) => {
          if (!blob) {
            return
          }

          const file = new File([blob], 'offer-image', { type: 'image/png' })
          onImageChange?.(file)
        })
      }, 100)
    }

    return (
      <AvatarUploadContainer {...extraProps}>
        {image && (
          <AvatarEditor
            ref={editor}
            image={image}
            width={96}
            height={96}
            borderRadius={96}
            color={[red, green, blue]}
            className="rounded p-0"
            scale={scale}
            crossOrigin={'anonymous'}
            onImageChange={triggerOnChange}
            onImageReady={triggerOnChange}
          />
        )}
        <div>
          <SettingsContainer>
            <div className="flex">
              <div className="hidden">
                <input
                  id="file-upload"
                  type="file"
                  name="upload"
                  accept="image/*"
                  onChange={handleNewImage}
                />
              </div>
              <CabalButton
                variant="tertiary"
                component="label"
                htmlFor="file-upload"
                className="custom-file-upload cursor-pointer flex-1 mt-6"
                padding="0"
                leftIcon={<i className="far fa-pencil" />}
              ></CabalButton>
            </div>

            {/* {image && (
              <Typography component="div" color="fog_rain" className="flex gap-2 items-center">
                <button onClick={() => setScale(max([scale - 0.1, 0])!)}>
                  <i className="far fa-minus" />
                </button>
                <input
                  ref={zoom}
                  name="scale"
                  type="range"
                  onChange={handleNewScale}
                  min="1"
                  max="2"
                  step="0.01"
                  defaultValue="1"
                />
                <button onClick={() => setScale(min([scale + 0.1, 10])!)}>
                  <i className="far fa-plus" />
                </button>
              </Typography>
            )} */}
            {(email || domain) && (
              <div>
                {email && (
                  <CabalButton onClick={handleAutoFillEmail}>Autofill using email</CabalButton>
                )}
                {domain && (
                  <CabalButton onClick={handleAutoFillDomain}>Autofill using domain</CabalButton>
                )}
              </div>
            )}
          </SettingsContainer>
        </div>
      </AvatarUploadContainer>
    )
  },
)

export default AvatarUpload
