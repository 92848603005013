import axios from 'axios'
import React from 'react'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

export interface AppVersion {
  description: string
  verticals: string[]
  require_reload: boolean
  created_at: string
  id: number
}

export class AppVersionWatcher {
  checkInterval: number
  ignoredVersions: number[] = []

  constructor() {
    this.checkInterval = window.setInterval(() => this.checkForNewVersion(), 60 * 30 * 1000)
  }

  showReloadToast() {
    cabalToast({
      content: (
        <Typography fontSize="12">
          There’s a new version of Cabal. <br /> Please refresh your browser to update.
        </Typography>
      ),
      icon: <i className="fas fa-moon-stars" />,
      duration: Infinity,
      id: 'reload',
      position: 'bottom-right',
      dismissable: false,
      cta: {
        content: <Typography fontSize="12">Refresh</Typography>,
        onClick: () => window.location.reload(),
        variant: 'tertiary',
      },
    })
  }

  handleVersions(versions: AppVersion[]) {
    versions = versions.filter((v) => v.id > window.APP_VERSION)

    if (!versions.length) return

    if (versions.some((v) => v.require_reload)) {
      this.showReloadToast()
      return
    }

    const pageSlug = window.location.pathname.split('/')[2]

    for (const version of versions) {
      for (const vertical of version.verticals) {
        if (
          (['messages', 'drafts', 'templates'].includes(pageSlug) &&
            ['messages'].includes(vertical)) ||
          (['asks', 'intros', 'companies', 'lists', 'social'].includes(pageSlug) &&
            ['asks'].includes(vertical)) ||
          (['settings'].includes(pageSlug) && ['settings'].includes(vertical)) ||
          (['members'].includes(pageSlug) && ['members'].includes(vertical)) ||
          (['portfolio'].includes(pageSlug) && ['portfolio'].includes(vertical)) ||
          (['resources'].includes(pageSlug) && ['resources'].includes(vertical))
        ) {
          this.showReloadToast()
          return
        }
      }
    }
  }

  checkForNewVersion() {
    axios
      .get<{ versions: AppVersion[] }>('/api/app_versions', {
        params: {
          since: window.APP_VERSION,
        },
      })
      .then(({ data: { versions } }) => this.handleVersions(versions))
  }

  destroy() {
    window.clearInterval(this.checkInterval)
  }
}
