import * as React from 'react'
import axios from 'axios'

import AcceptContent from './AcceptContent'
import CabalTitle from 'global/Title'

const AdvisorsAcceptance = (props: any) => {
  const [loading, setLoading] = React.useState(false)
  const [referralCode, setReferralCode] = React.useState(null)

  const load = () => {
    axios.get(`/api/referral_codes/${props.match.params.id}`).then((response) => {
      const { referral_code } = response.data

      setReferralCode(referral_code)
      setLoading(false)
    })
  }

  React.useEffect(() => {
    load()
  }, [])

  if (loading || !referralCode) {
    return <div />
  }

  return (
    <>
      <CabalTitle title="Invitation" teamName={referralCode.name} />
      <AcceptContent referralCode={referralCode} />
    </>
  )
}

export default AdvisorsAcceptance
