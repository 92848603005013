import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import api from 'utils/api'
import { AdvisorModel } from 'utils/types'
import { RootState } from '..'

export interface AdvisorsState {
  /**
   * {<advisor_uuid>: <advisor>}
   */
  advisors: Record<string, AdvisorModel>
  /**
   * {<team_slug>: <boolean>}
   */
  advisors_loaded: Record<string, boolean>
  /**
   * {<team_slug/advisor_uuid>: <boolean>}
   */
  loading: Record<string, boolean>
}

const initialState: AdvisorsState = {
  advisors: {},
  advisors_loaded: {},
  loading: {},
}

export const loadAdvisor = createAsyncThunk(
  'teams/fetchAdvisor',
  async (props: { uuid: string; teamSlug: string }, thunk) => {
    thunk.dispatch(setLoadingAdvisors(props.uuid))

    const response = await api.getAdvisor(props.uuid, props.teamSlug)
    return response.data.advisor
  },
)

export type loadAdvisorProps =
  | { team_slug?: never; group_uuids: string[] }
  | { team_slug: string; group_uuids?: never }

export const loadAdvisorsLoadingKey = (props: loadAdvisorProps) =>
  `${props.team_slug}${props.group_uuids?.join('') || ''}`

export const loadAdvisors = createAsyncThunk(
  'teams/fetchAdvisors',
  async (props: loadAdvisorProps, thunk) => {
    thunk.dispatch(setLoadingAdvisors(loadAdvisorsLoadingKey(props)))

    const response = await api.getAdvisors(props)
    return response.data.advisors
  },
)

export const advisorsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAdvisor: (state, action: PayloadAction<AdvisorModel>) => {
      state.advisors[action.payload.uuid] = action.payload
    },
    setAdvisors: (state, action: PayloadAction<AdvisorModel[]>) => {
      for (const advisor of action.payload) {
        state.advisors[advisor.uuid] = advisor
      }
    },
    updateAdvisor: (
      state,
      action: PayloadAction<{
        uuid: string
        advisor: Partial<AdvisorModel>
      }>,
    ) => {
      state.advisors[action.payload.uuid] = {
        ...state.advisors[action.payload.uuid],
        ...action.payload.advisor,
      }
    },
    removeAdvisor: (state, action: PayloadAction<string>) => {
      delete state.advisors[action.payload]
    },
    removeAdvisors: (state, action: PayloadAction<string[]>) => {
      for (const uuid of action.payload) {
        delete state.advisors[uuid]
      }
    },
    setLoadingAdvisors: (state, action: PayloadAction<string>) => {
      state.loading[action.payload] = true
    },
    unsetLoadingAdvisors: (state, action: PayloadAction<string>) => {
      delete state.loading[action.payload]
    },
    setAdvisorsLoaded: (state, action: PayloadAction<string>) => {
      state.advisors_loaded[action.payload] = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadAdvisor.fulfilled, (state, action) => {
      state.advisors[action.payload!.uuid] = action.payload!

      delete state.loading[action.meta.arg.uuid]
    })

    builder.addCase(loadAdvisor.rejected, (state, action) => {
      delete state.loading[action.meta.arg.uuid]
    })

    builder.addCase(loadAdvisors.fulfilled, (state, action) => {
      if (action.meta.arg.team_slug) state.advisors_loaded[action.meta.arg.team_slug] = true
      for (const advisor of action.payload) {
        state.advisors[advisor.uuid] = advisor
      }

      delete state.loading[loadAdvisorsLoadingKey(action.meta.arg)]
    })

    builder.addCase(loadAdvisors.rejected, (state, action) => {
      delete state.loading[loadAdvisorsLoadingKey(action.meta.arg)]
    })
  },
})

export const {
  setAdvisor,
  setAdvisors,
  updateAdvisor,
  removeAdvisor,
  removeAdvisors,
  setLoadingAdvisors,
  unsetLoadingAdvisors,
  setAdvisorsLoaded,
} = advisorsSlice.actions

const selectAllAdvisors = (state: RootState) => state.advisors.advisors
const selectAdvisorsLoaded = (state: RootState) => state.advisors.advisors_loaded
const selectAdvisorsLoading = (state: RootState) => state.advisors.loading

export const selectLoadingAdvisor = (key: string) =>
  createSelector(selectAdvisorsLoading, (loading) => !!loading[key])

export const selectAdvisor = (uuid: string) =>
  createSelector(selectAllAdvisors, (advisors) =>
    Object.keys(advisors).includes(uuid) ? advisors[uuid] : undefined,
  )

export const selectAdvisors = (props: { team_slug?: string; group_uuids?: string[] }) =>
  createSelector(selectAllAdvisors, selectAdvisorsLoaded, (advisors, advisors_loaded) => {
    if (props.team_slug && !advisors_loaded[props.team_slug]) return undefined

    return Object.values(advisors).filter((a) => {
      if (props.team_slug) {
        if (a.team_slug === props.team_slug) return true
      } else if (props.group_uuids) {
        if (a.groups?.some((v) => props.group_uuids?.includes(v.uuid))) return true
      }
      return false
    })
  })

export default advisorsSlice.reducer
