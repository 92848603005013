import React from 'react'

import { sanitize } from 'dompurify'
import { link } from 'suneditor/src/plugins'

import CabalButton from 'global/CabalButton'
import Modal from 'global/Modal'
import Typography from 'global/Typography'

interface Props {
  onHide: () => void
  description?: string
  title: string
  cta?: string
  link?: string
}

const ListOtherGridModal = ({ onHide, description, title, cta, link }: Props) => {
  const formattedLink = link && !/^https?:\/\//i.test(link) ? `http://${link}` : link

  return (
    <Modal
      onHide={onHide}
      header={title}
      show={true}
      rightActions={
        cta &&
        link && (
          <CabalButton
            onClick={() => window.open(formattedLink, '_blank', 'noopener, noreferrer')}
            variant="primary"
          >
            {cta}
          </CabalButton>
        )
      }
    >
      <Typography fontSize="12" component="div" className="mb-1" fontWeight={600}>
        Description
      </Typography>
      <div className="mb-4 break-words">
        <Typography
          component="div"
          className="ql-output"
          color="fog"
          fontSize="12"
          dangerouslySetInnerHTML={{ __html: sanitize(description || '') }}
        />
      </div>
    </Modal>
  )
}

export default ListOtherGridModal
