import React from 'react'

import { useAppSelector, useTeam } from 'store/hooks'
import { selectTeams } from 'store/reducers/teamsReducer'

import { UserPermissions } from 'utils/types'

export type UseAccessControl = Partial<UserPermissions> & {
  canAccess: (permission: keyof UserPermissions, teamSlug: string) => boolean
}

export function useAccessControl(companySlug?: string): UseAccessControl {
  const teams = useAppSelector(selectTeams)
  const team = teams.find((t) => t.slug === companySlug)

  const canAccess = (permission: keyof UserPermissions, teamSlug?: string) => {
    if (!companySlug && !teamSlug) throw 'no company slug supplied'
    const team = teams.find((t) => t.slug === teamSlug || companySlug)

    return !!team?.permissions?.[permission]
  }

  return {
    canAccess,
    ...team?.permissions,
  }
}

interface Props {
  companySlug: string
  permission: keyof UserPermissions
}

const AccessControl: React.FC<Props> = ({ companySlug, permission, children }) => {
  const { permissions } = useTeam(companySlug).team || {}

  if (!permissions?.[permission]) return <></>

  return <>{children}</>
}

export default AccessControl
