import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import React, { useState } from 'react'
import { PresendCheckStepProps } from '.'

const MissingUserName: React.VFC<PresendCheckStepProps> = ({ onSubmit, onHide }) => {
  const [name, setName] = useState('')

  return (
    <Modal
      show
      onHide={() => onHide()}
      header="Before we send that…"
      leftActions={
        <>
          <CabalButton variant="secondary" onClick={() => onHide()}>
            Cancel
          </CabalButton>
          <CabalButton className="ml-3" onClick={() => onSubmit({ name })}>
            Save
          </CabalButton>
        </>
      }
    >
      <TextInput
        label={<Typography fontWeight={600}>Fill in your full name</Typography>}
        placeholder="Your full Name"
        className="w-full"
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
      />
    </Modal>
  )
}

export default MissingUserName
