import React, { useState } from 'react'

import {
  IIntroRequestFacilitator,
  IIntroRequestRequestor,
  IIntroRequestTarget,
} from 'containers/IntroRequestModal/types'
import { useAccessControl } from 'global/AccessControl'
import { CheckBox, TextArea } from 'global/Input'

// This component is used to display the draft email field in the intro request modal
// The facilitator is the person who is in the middle of the intro
// The target is the person who is receiving the intro request
// The requestor is the person who is requesting the intro

interface Props {
  facilitator: IIntroRequestFacilitator
  target: IIntroRequestTarget
  requestor: IIntroRequestRequestor
  teamSlug: string
  isIntroOwner: boolean
  canSeeDraftEmail: boolean
  selectedDraftEmail: (email: string) => void
  checked: (checked: boolean) => void
  emailBody?: string
}

const DraftEmail: React.FC<Props> = ({
  facilitator,
  target,
  requestor,
  teamSlug,
  isIntroOwner,
  canSeeDraftEmail,
  selectedDraftEmail,
  checked,
  emailBody,
}) => {
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const drafMessageLabel = `Include draft email from ${facilitator.name} to ${target.name}`
  const initalDraftEmail = isAdminOrEmployee
    ? `Hi ${target.name}, I was just catching up with the team at ${requestor?.teamName}. They ...`
    : ''
  const message = `Hi ${facilitator.name} - I see you’re connected to ${target.name}${
    target?.company ? ' at ' + target.company : ''
  }. Do you know him well enough to make an intro?`
  const [checkedDraftEmail, setCheckedDraftEmail] = useState<boolean>(true)

  const [draftEmail, setDraftEmail] = useState<string>(emailBody || initalDraftEmail)

  const handleDraftEmailChange = (email: string) => {
    setDraftEmail(email)
  }

  selectedDraftEmail(draftEmail)

  const handleDraftEmailCheck = (e: boolean) => {
    setCheckedDraftEmail(!checkedDraftEmail)
    checked(e)
    if (e) {
      selectedDraftEmail(draftEmail)
    } else {
      selectedDraftEmail('')
    }
  }

  if (!canSeeDraftEmail) return null
  return (
    <>
      <div className="mt-2">
        <CheckBox
          label={drafMessageLabel}
          type="checkbox"
          checked={checkedDraftEmail}
          onChange={(e) => handleDraftEmailCheck(e.target.checked)}
        />
      </div>
      {checkedDraftEmail && (
        <div className="mb-3 mt-1">
          <TextArea
            value={checkedDraftEmail ? draftEmail : ''}
            className="w-full"
            rows={3}
            data-testid="request-talent-intro-modal-context"
            onChange={(e) => handleDraftEmailChange(e.target.value)}
            placeholder={message}
            disabled={isIntroOwner}
          />
        </div>
      )}
    </>
  )
}

export default DraftEmail
