import React, { useMemo } from 'react'

import { useInfiniteQuery } from 'react-query'

import CreateList from 'components/CompanyHome/CreateList'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import SectionHeader from 'ui-components/SectionHeader'

import api, { callApi } from 'utils/api'

import FiltersV2 from './Filters'
import ItemCardV2 from './ItemCard'
import ItemDetails from './ItemDetails'

interface Props {
  blockId: string
  itemId?: string
  ownerType: string
  ownerId: string
  params?: { filters: { search?: string[] }; per_page?: number; page?: number }
}

const Block: React.FC<Props> = (props) => {
  const { blockId, itemId, ownerType, ownerId, params = {} } = props
  const queryKey = useMemo(
    () => ['getBlock', ownerType, ownerId, blockId, itemId, params],
    [ownerType, ownerId, blockId, itemId],
  )

  const blockQuery = useInfiniteQuery(
    [...queryKey, params],
    ({ pageParam: page = 1 }) =>
      callApi(api.getBlock, {
        owner_type: ownerType,
        owner_id: ownerId,
        page,
        block_id: blockId,
        item_id: itemId,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
    },
  )

  // don't show companies from previous list if a new list is selected
  let items = blockQuery?.data?.pages?.flatMap((page) => page.items)

  if (blockQuery.isLoading) {
    return (
      <div className="mt-6 flex justify-center items-center flex-col">
        <Loading />
      </div>
    )
  }

  return (
    <>
      {items?.map((blockItem) => {
        switch (blockItem.type) {
          case 'header':
            return <SectionHeader {...blockItem} />
          case 'filters':
            return <FiltersV2 filters={{}} filterTypes={blockItem.filters} block_id={blockId} />
          case 'create_list':
            return ownerType == 'Team' ? <CreateList teamSlug={ownerId} /> : null
          case 'item':
            return (
              <ItemCardV2
                highlightWords={props.params?.filters?.search}
                itemView={blockItem}
                queryKey={queryKey}
              />
            )
          case 'item_detail_view':
            return <ItemDetails teamSlug={ownerId} blockItem={blockItem} />
          default:
            return (
              <ItemCardV2
                highlightWords={props.params?.filters?.search}
                itemView={blockItem}
                teamSlug={ownerId}
                queryKey={queryKey}
              />
            )
        }
      })}

      {!blockQuery.isFetching && !items?.length && (
        <div className="flex flex-col items-center justify-center my-20">
          <Typography color="fog" fontSize="24">
            <i className="fal fa-search" />
          </Typography>
          <Typography fontSize="14" fontWeight={600}>
            No results found
          </Typography>
        </div>
      )}

      {blockQuery.hasNextPage && (
        <div className="flex justify-center my-1">
          <CabalButton
            variant="secondary"
            disabled={blockQuery.isFetchingNextPage}
            onClick={() => blockQuery.fetchNextPage()}
          >
            Load more
          </CabalButton>
        </div>
      )}
    </>
  )
}

export default Block
