import axios from 'axios'
import { UserEmail } from 'utils/types'

export default {
  getUserEmails: () => {
    return axios.get<UserEmail[]>('/api/user_emails')
  },
  addUserEmail: (email: string) => {
    return axios.post<UserEmail>('/api/user_emails', {
      email,
    })
  },
  makeUserEmailPrimary: (emailId: number) => {
    return axios.post(`/api/user_emails/${emailId}/set_primary`)
  },
  removeUserEmail: (emailId: number) => {
    return axios.delete(`/api/user_emails/${emailId}`)
  },
  resendUserEmailToken: (emailId: number) => {
    return axios.post('/api/user_emails/resend', { id: emailId })
  },
}
