import React from 'react'
import { useQuery } from 'react-query'

import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

const useCrmStatus = (teamSlug: string | undefined) => {
  const query = useQuery(['crm_status', teamSlug], () => callApi(api.crmStatus, teamSlug), {
    onSuccess: ({ syncing_salesforce, syncing_hubspot }) => {
      if (syncing_salesforce) {
        cabalToast({
          id: 'syncing_salesforce',
          content: (
            <>
              Syncing Salesforce. This may take a few minutes.
              <br />
              We{`'`}ll send you an email when it{`'`}s done.
            </>
          ),
          style: 'loading',
          duration: Infinity,
          dismissable: false,
          position: 'bottom-right',
          passive: true,
        })
      }

      if (syncing_hubspot) {
        cabalToast({
          id: 'syncing_hubspot',
          content: (
            <>
              Syncing HubSpot. This may take a few minutes.
              <br />
              We{`'`}ll send you an email when it{`'`}s done.
            </>
          ),
          style: 'loading',
          duration: Infinity,
          dismissable: false,
          position: 'bottom-right',
          passive: true,
        })
      }
    },
    enabled: !!teamSlug,
    refetchInterval: 1000 * 60 * 10,
    staleTime: Infinity,
  })

  const {
    hubspot_synced_recently,
    salesforce_synced_recently,
    salesforce_connected: salesforceConnected,
    hubspot_connected: hubspotConnected,
  } = query.data || {}

  let status: 'synced' | 'syncing_hubspot' | 'syncing_salesforce' = 'synced'

  if (query.data?.syncing_hubspot) {
    status = 'syncing_hubspot'
  } else if (query.data?.syncing_salesforce) {
    status = 'syncing_salesforce'
  }

  return {
    status,
    salesforce_synced_recently,
    hubspot_synced_recently,
    salesforceConnected,
    hubspotConnected,
  }
}

export default useCrmStatus
