import * as React from 'react'

import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Typography from 'global/Typography'
import { useAppDispatch, useCurrentUser } from 'store/hooks'
import { setCurrentUser } from 'store/reducers/currentUserReducer'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'

interface Props {
  phone?: string
  twitter_url?: string
  linkedin_url?: string
  location?: string
}

const Contacts: React.FC<Props> = () => {
  const { user } = useCurrentUser()
  const dispatch = useAppDispatch()

  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [twitter, setTwitter] = React.useState('')
  const [linkedin, setLinkedin] = React.useState('')
  const [loc, setLoc] = React.useState('')
  const [working, setWorking] = React.useState(false)

  React.useEffect(() => {
    setPhoneNumber(user.phone || '')
    setTwitter(user.twitter_url || '')
    setLinkedin(user.linkedin_url || '')
    setLoc(user.location || '')
  }, [user])

  const handleSubmit = () => {
    const params = {
      phone: phoneNumber,
      twitter_url: twitter,
      linkedin_url: linkedin,
      location: loc,
    }

    setWorking(true)

    api
      .updateCurrentProfile(params)
      .then(({ data: { profile } }) => {
        dispatch(setCurrentUser(profile))
        cabalToast({ style: 'success', content: 'Successfully saved the changes' })
      })
      .finally(() => {
        setWorking(false)
      })
  }

  if (Object.keys(user).length === 0) return <></>

  return (
    <>
      <Typography color="admin_label" className={'block pt-4 pb-2'} fontSize={'12'}>
        {'Phone'}
      </Typography>
      <TextInput
        value={phoneNumber}
        placeholder="Enter phone number"
        className="w-full"
        onChange={(e) => setPhoneNumber(e.target.value)}
        data-testid="phone-number"
      />
      <Typography color="admin_label" className={'block pt-4 pb-2'} fontSize={'12'}>
        {'Location'}
      </Typography>
      <TextInput
        value={loc}
        placeholder="Enter company location"
        className="w-full"
        onChange={(e) => setLoc(e.target.value)}
      />
      <div className="flex justify-end pt-4">
        <CabalButton
          variant="primary"
          data-testid="save-contact"
          onClick={handleSubmit}
          working={working}
        >
          {'Save'}
        </CabalButton>
      </div>
    </>
  )
}

export default Contacts
