import axios from 'axios'

import {
  AdvisorImportStatusResponse,
  AdvisorModel,
  BulkCreateRequest,
  BulkUpdateRequest,
  BulkUpdateResponse,
  CreateAdvisorRequest,
  GetAdvisorsRequest,
  GetAdvisorsResponse,
  ImportedMember,
  UpsertAdvisorResponse,
} from 'utils/types'

export default {
  /**
   * @deprecated don't use this, it is unmaintained
   */
  createAdvisor: (params: CreateAdvisorRequest) => {
    return axios.post<UpsertAdvisorResponse>('/api/advisors', params)
  },

  inviteAdvisors: (params: {
    team_slug: string
    advisor_uuids: string[]
    group_uuids: string[]
    note?: string
    steps?: {
      welcome: boolean
      connections: boolean
      personal_info: boolean
      talent_profile: boolean
    }
  }) => {
    return axios.post('/api/advisors/invite', params)
  },

  advisorUpdateSelf: (params: {
    advisor_uuid: string
    updateParams: {
      first_name?: string
      last_name?: string
      did_onboarding?: boolean
    }
  }) => {
    return axios.put(`/api/advisors/${params.advisor_uuid}/update_self`, params.updateParams)
  },

  advisorBulkInvite: (params: { team_slug: string; emails: string[] }) => {
    return axios.post('/api/advisors/bulk_invite', params)
  },

  advisorInviteReminder: (team_slug: string, advisor_uuid: string) => {
    return axios.post(`/api/advisors/${advisor_uuid}/invite_reminder`, { team_slug })
  },

  getAdvisors: (params: GetAdvisorsRequest) => {
    return axios.post<GetAdvisorsResponse>('/api/advisors/get', params)
  },

  getAdvisorsV2: (query: string) => {
    return axios.get('/api/advisors/get_v2', { params: { query } })
  },

  getAdvisor: (id: string, team_slug: string, uniqueAccessLinkUuid?: string) => {
    return axios.get<{
      advisor: AdvisorModel
    }>(`/api/advisors/${id}`, {
      params: {
        team_slug,
        unique_access_link_uuid: uniqueAccessLinkUuid,
      },
    })
  },

  getContributions: (team_slug: string) => {
    return axios.get(`/api/advisors/contributions`, {
      params: {
        team_slug,
      },
    })
  },

  setOnboarded: (team_slug: string) => {
    return axios.post('/api/advisors/onboarded', {
      team_slug,
    })
  },

  saveAdvisor: (advisor: AdvisorModel) => {
    const { uuid, ...params } = advisor
    return axios.put<UpsertAdvisorResponse>(`/api/advisors/${uuid}`, params)
  },

  deleteAdvisor: (uuid: string) => {
    return axios.delete(`/api/advisors/${uuid}`)
  },

  bulkDeleteAdvisors: (team_slug: string, advisor_uuids: string[]) => {
    return axios.post('/api/advisors/bulk_delete', {
      team_slug,
      advisor_uuids,
    })
  },

  importAdvisors: (advisor_import_uuid: string, params: BulkCreateRequest) => {
    return axios.post(`/api/advisor_imports/${advisor_import_uuid}/import`, params)
  },

  updateAdvisorImport: (advisor_import_uuid: string, params: BulkCreateRequest) => {
    return axios.put(`/api/advisor_imports/${advisor_import_uuid}`, params)
  },

  enrichAdvisorImport: (params: {
    upload_uuid?: string
    emails?: string[] | string
    contact_uuids?: string[]
    team_slug: string
  }) => {
    return axios.post<{ advisor_import_uuid: string }>('/api/advisor_imports', params)
  },

  enrichStatusAdvisorImport: (team_slug: string, import_uuid: string) => {
    return axios.get<{
      status: string
      advisors: ImportedMember[]
      imported_columns: string[]
      total_advisor_count: number
      enriched_advisor_count: number
    }>(`/api/advisor_imports/${import_uuid}`, {
      params: {
        team_slug,
      },
    })
  },

  advisorImportStatus: (advisor_import_uuid: string, team_slug: string) => {
    return axios.get<AdvisorImportStatusResponse>(
      `/api/advisor_imports/${advisor_import_uuid}/import_status`,
      {
        params: {
          team_slug,
        },
      },
    )
  },

  bulkUpdateAdvisors: (params: BulkUpdateRequest) => {
    return axios.put<BulkUpdateResponse>(`/api/advisors/bulk_update`, params)
  },

  remindExtensionInstall: (advisorUuid: string) => {
    return axios.post(`/api/advisors/${advisorUuid}/remind_install_extension`)
  },

  shareConnectionsAllCompanies: (params: { team_slugs?: string[] }) => {
    return axios.post(`/api/advisors/share_connections_all_companies`, params)
  },

  updateConnectionSharing: (params: { share_connections: boolean; team_slug: string }) => {
    return axios.post(`/api/advisors/update_connection_sharing`, params)
  },

  createCandidate: (params) => {
    return axios.post(`/api/advisors/create_candidate`, params)
  },

  // deprecating
  importAdvisorConnections: (uuid: string, team_slug: string, upload_uuid: string) => {
    return axios.post(`/api/advisors/${uuid}/import_connections`, {
      team_slug,
      upload_uuid,
    })
  },

  adminImportConnections: (uuid: string, team_slug: string, upload_uuid: string) => {
    return axios.post(`/api/advisors/${uuid}/admin_import_connections`, {
      team_slug,
      upload_uuid,
    })
  },

  advisorConnectionImportStatus: (uuid: string) => {
    return axios.get(`/api/advisors/${uuid}/connection_import_status`)
  },

  getAdvisorRequestStats: (uuid: string, team_slug: string) => {
    return axios.get<{ last_request_at: string; request_count: number }>(
      `/api/advisors/${uuid}/request_stats`,
      {
        params: {
          team_slug,
        },
      },
    )
  },

  createViaToken: (params: { token: string, team_slug: string | undefined }) => {
    return axios.post(`/api/advisors/create_via_token`, params)
  },
}
