import React from 'react'
import { ComposerButtons } from 'global/Button'
import { isEqual } from 'lodash'

interface Props {
  inputs: any
}

function ButtonsPreview(props: Props) {
  const { inputs } = props

  return (
    <div className="pt-2">
      {inputs.map((input) => (
        <span key={input.key} className="mr-2">
          <ComposerButtons
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault()
              window.open(input.url, '_blank', 'noopener')
            }}
          >
            {input.text}
          </ComposerButtons>
        </span>
      ))}
    </div>
  )
}

export default React.memo(ButtonsPreview, isEqual)
