import React, { useRef } from 'react'

import styled from 'styled-components'

import useCrmStatus from 'components/CrmStatus'
import { ModalInputLabel } from 'global/Modal/styles'
import Typography from 'global/Typography'

import { CompanyListBlueprint, ListTypeModel } from 'utils/types'

interface Props {
  onClick: (v: CompanyListBlueprint['list_type'] | '') => void
  teamSlug: string
}

interface OptionProps {
  title: string
  icon: React.ReactNode
  onClick: () => void
  selected: boolean
  data_testid?: string
}

const OptionWrapper = styled.button<{ selected: boolean }>`
  border: ${({ theme }) => theme.border};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.purple : theme.colors.cardBackground};
`

export const Options: React.FC<OptionProps> = ({ title, icon, onClick, selected, data_testid }) => {
  return (
    <OptionWrapper
      className="flex items-center rounded-full px-2 py-1 space-x-1 cursor-pointer"
      selected={selected}
      onClick={onClick}
      data-testid={data_testid}
    >
      <Typography fontSize="12" color={selected ? 'white' : 'fog'}>
        {icon}
      </Typography>
      <Typography fontWeight={600} fontSize="12" color={selected ? 'white' : 'primary'}>
        {title}
      </Typography>
    </OptionWrapper>
  )
}

const ListTypeSelector: React.FC<Props> = ({ onClick, teamSlug }) => {
  const { salesforceConnected, hubspotConnected } = useCrmStatus(teamSlug)
  const popupRef = useRef<Window | null>(null)

  const openPopup = (authUrl: string, serviceName: string) => {
    const width = 550
    const height = 650
    const x = screen.width / 2 - width / 2
    const y = screen.height / 2 - height / 2
    popupRef.current = window.open(
      authUrl,
      `Authorize ${serviceName}`,
      `width=${width},height=${height},left=${x},top=${y}`,
    )
    const timer = setInterval(function () {
      if (popupRef.current?.closed) {
        clearInterval(timer)
      }
    }, 1000)
  }

  return (
    <div>
      <div className="flex gap-4">
        <div className="w-full pt-2 pb-8">
          <ModalInputLabel>Create in Cabal</ModalInputLabel>
          <div className="flex flex-wrap gap-2 mt-2">
            {Object.values(window.LIST_TYPES).map((listType: ListTypeModel) => (
              <Options
                title={listType.label}
                icon={listType.icon_class ? <i className={listType.icon_class}></i> : undefined}
                onClick={() => onClick(listType.value)}
                selected={false}
                data_testid={`create-list-type-${listType.value}`}
              />
            ))}
          </div>

          <div className="pt-8">
            <ModalInputLabel>Import from source</ModalInputLabel>
            <div className="flex flex-wrap gap-2 mt-2">
              <Options
                title={'Salesforce'}
                icon={<i className="fab fa-salesforce"></i>}
                onClick={() => {
                  if (salesforceConnected) {
                    onClick('salesforce')
                  } else {
                    openPopup('/auth/salesforce', 'Salesforce')
                  }
                }}
                selected={false}
              />
              <Options
                title={'Hubspot'}
                icon={<i className="fab fa-hubspot"></i>}
                onClick={() => {
                  if (hubspotConnected) {
                    onClick('hubspot')
                  } else {
                    openPopup('/auth/hubspot', 'Hubspot')
                  }
                }}
                selected={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListTypeSelector
