import axios from 'axios'
import { TeamUsersResponse } from 'utils/types'

export default {
  getTeamUsers: (team: string) => {
    return axios.get<TeamUsersResponse>(`/api/team_users`, {
      params: {
        team_slug: team,
      },
    })
  },

  updateTeamUserRole: (team_slug: string, uuid: string, team_role: string) => {
    return axios.put<TeamUsersResponse>(`/api/team_users/${uuid}`, {
      team_slug,
      user: {
        team_role,
      },
    })
  },

  createTeamUser: (team_slug: string, params: any) => {
    return axios.post<TeamUsersResponse>(`/api/team_users`, {
      ...params,
      team_slug,
    })
  },

  deleteTeamUser: (team_slug: string, uuid: string) => {
    return axios.delete<TeamUsersResponse>(`/api/team_users/${uuid}`, {
      params: {
        team_slug,
      },
    })
  },
}
