import * as React from 'react'
import Typography from 'global/Typography'
import api from 'utils/api'
import { TextInput, NumberFormattedInput, Select } from 'global/Input'
import CabalButton from 'global/CabalButton'
import { DangerButton } from 'global/Button'
import styled from 'styled-components'
import tw from 'twin.macro'
import ConfirmDeleteModal from './ConfirmDeleteModal'
import { cabalToast } from 'ui-components/Toast'
import { EquityTierEntity } from 'utils/types'

const Tier = styled.div`
  display: grid;
  grid-template-columns: 40px 20% 20% 20% auto;
  @media (max-width: 1024px) {
    grid-template-columns: 100%;
  }
`
const Labels = styled.div`
  display: grid;
  grid-template-columns: 40px 20% 20% 20% auto;
  @media (max-width: 1024px) {
    display: none;
  }
`

const NewTier = styled.div`
  ${tw`mt-2`}
  display: grid;
  grid-template-columns: 40px 20% 20% 20% 100px;
  @media (max-width: 1024px) {
    grid-template-columns: 100%;
  }
`
const GreyIcon = styled.i`
  color: #505050;
  &:hover {
    color: #646464;
  }
`

interface Props {
  pool_remaining: number
  equityTiers: EquityTierEntity[]
  setEquityTiers: any
  fully_diluted: number
}

const EquityTiers: React.VFC<Props> = ({
  pool_remaining,
  equityTiers,
  setEquityTiers,
  fully_diluted,
}) => {
  const [destroyTier, setDestroyTier] = React.useState<EquityTierEntity | null>(null)
  const [tierName, setTierName] = React.useState('Tier 1')
  const [tierShares, setTierShares] = React.useState('')
  const [tierVestingPeriod, setTierVestingPeriod] = React.useState('')
  const [tier2Name, setTier2Name] = React.useState('Tier 2')
  const [tier2Shares, setTier2Shares] = React.useState('')
  const [tier2VestingPeriod, setTier2VestingPeriod] = React.useState('')
  const [working, setWorking] = React.useState(false)

  const onDestroyTier = () => {
    const { id } = destroyTier
    api.destroyEquityTier(id).then((response) => {
      cabalToast({ style: 'success', content: 'Tier removed' })
      const { equity_tiers } = response.data
      setEquityTiers(equity_tiers)
      setDestroyTier(null)
    })
  }

  React.useEffect(() => {
    if ((tierName === '' || tierName === 'Tier 1') && equityTiers.length > 0) {
      setTierName(`Tier ${equityTiers.length + 1}`)
    }
  }, [equityTiers])

  const renderAddTier = (second = false) => {
    const nameSetter = second ? setTier2Name : setTierName
    const shareSetter = second ? setTier2Shares : setTierShares
    const vestingSetter = second ? setTier2VestingPeriod : setTierVestingPeriod
    const nameGetter = second ? tier2Name : tierName
    const shareGetter = second ? tier2Shares : tierShares
    const vestingGetter = second ? tier2VestingPeriod : tierVestingPeriod

    return (
      <NewTier>
        <div></div>
        <TextInput
          className="mr-2"
          value={nameGetter}
          name="tier_name"
          onChange={(e) => nameSetter(e.target.value)}
          placeholder={`Tier ${equityTiers.length + 1}`}
        />
        <NumberFormattedInput
          className="mr-2"
          value={shareGetter}
          name="tier_shares"
          onChange={(e: { target: { value: React.SetStateAction<null> } }) =>
            shareSetter(e.target.value)
          }
          placeholder={`${(fully_diluted * (second ? 0.001 : 0.0025)).toLocaleString()}`}
        />
        <div>
          <Select
            className="mr-2"
            placeholder="Vesting period"
            defaultValue={vestingGetter}
            onChange={(value) => vestingSetter(value)}
            options={[12, 24, 36, 48, 72, 96].map((n) => ({
              value: n,
            }))}
            isClearable
            noMinWidth
            compactPlaceholder
            portal
          />
        </div>
        <CabalButton
          className="mr-2"
          onClick={() => onSaveTier(second)}
          disabled={working || !shareGetter || nameGetter.length === 0}
        >
          Add <i className="fa fa-plus ml-2" />
        </CabalButton>
      </NewTier>
    )
  }

  const saveTier = (second: boolean) => {
    const params = {
      name: second ? tier2Name : tierName,
      shares: second ? tier2Shares : tierShares,
      vesting_period: second ? tier2VestingPeriod : tierVestingPeriod,
    }

    api.saveEquityTier(params).then((response) => {
      cabalToast({ style: 'success', content: 'Tier saved' })
      const { equity_tiers } = response.data
      setEquityTiers(equity_tiers)
      setDestroyTier(null)
      if (equity_tiers.length > 2) {
        setTierName(`Tier ${equity_tiers.length + 1}`)
        setTierShares('')
        setTierVestingPeriod('')
      } else {
        if (second) {
          setTier2Name('')
          setTier2Shares('')
          setTier2VestingPeriod('')
        } else {
          setTierName('')
          setTierShares('')
          setTierVestingPeriod('')
        }
      }
      setWorking(false)
    })
  }

  const onSaveTier = (second: boolean) => {
    setWorking(true)

    if (equityTiers.length == 0 && second) {
      // if user types data for 2 first tiers but
      // clicks add on the second one first
      // we save the first one, then the second.
      api
        .saveEquityTier({
          name: tierName,
          shares: tierShares || '0',
          vesting_period: tierVestingPeriod,
        })
        .then(() => {
          saveTier(second)
        })
    } else {
      saveTier(second)
    }
  }

  return (
    <>
      <div>
        {destroyTier && (
          <ConfirmDeleteModal
            show={true}
            onHide={() => setDestroyTier(null)}
            onConfirm={() => onDestroyTier()}
            onCancel={() => setDestroyTier(null)}
            confirmText={`Delete ${destroyTier.name || 'this'} tier?`}
          />
        )}
        <Labels>
          <div></div>
          <Typography color="admin_label" fontSize="12" className="block pt-3">
            Name
          </Typography>
          <Typography color="admin_label" fontSize="12" className="block pt-3">
            Shares
          </Typography>
          <Typography color="admin_label" fontSize="12" className="block pt-3">
            Vesting Period (in months)
          </Typography>
        </Labels>
        {equityTiers.map((tier: EquityTierEntity) => {
          return (
            <Tier key={tier.id} className="flex mb-2">
              <DangerButton className="ml-3" onClick={() => setDestroyTier(tier)}>
                <GreyIcon className="fa fa-trash" />
              </DangerButton>
              <TextInput disabled={true} className="mr-2" value={tier.name} name="tier_name" />
              <NumberFormattedInput
                disabled={true}
                className="mr-2"
                value={tier.shares}
                name="tier_shares"
              />
              <TextInput
                disabled={true}
                className="mr-2"
                value={tier.vesting_period ? `${tier.vesting_period} months` : 'n/a'}
                name="tier_vesting_period"
              />
              <Typography color="admin_label" fontSize="12" className="block pt-3">
                {`Budget for ${parseInt(
                  (pool_remaining / tier.shares).toFixed(),
                ).toLocaleString()} more ${tier.name} advisors.`}
              </Typography>
            </Tier>
          )
        })}
      </div>
      {(equityTiers.length === 0 || equityTiers.length > 1) && renderAddTier()}
      {equityTiers.length < 2 && renderAddTier(true)}
    </>
  )
}

export default EquityTiers
