import React, { useState } from 'react'

import moment from 'moment'

import { SeeMore } from 'containers/CandidateProfile'
import ExperienceModal from 'containers/CandidateProfile/Modals/ExperienceModal'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import Widget from 'ui-components/Widget'

import { CandidateProfile, CandidateWorkExperience } from 'utils/types'

const ExperienceRow = ({ work }: { work: CandidateWorkExperience }) => {
  const [isExpanded, setIsExpanded] = useState(work.summary.length < 165)
  const description = []
  const summary = isExpanded ? work.summary : `${work.summary.slice(0, 165)}`

  if (work.company) {
    description.push(
      <div>
        <i className="far fa-building mr-1" /> {work.company}
      </div>,
    )
  }

  if (work.start_at && work.end_at) {
    description.push(
      <div>
        {moment.utc(work.start_at).format('MMM YYYY')} -{' '}
        {moment.utc(work.end_at).format('MMM YYYY')}
      </div>,
    )
  } else if (work.start_at) {
    description.push(<div>{moment.utc(work.start_at).format('MMM YYYY')} </div>)
  }

  return (
    <>
      <DataCardV2
        avatar={<i className="far fa-briefcase" />}
        title={work.title}
        description={description}
      />
      <p className="ml-12 leading-5 -mt-1 mb-2">
        <Typography color="rain_fog" fontSize="12" lineHeight={1}>
          {summary}
        </Typography>
        {work.summary.length > 165 && (
          <SeeMore
            className="ml-1 cursor-pointer"
            color="fog"
            fontSize="12"
            lineHeight={1}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? '...see less' : '...see more'}
          </SeeMore>
        )}
      </p>
    </>
  )
}

interface Props {
  candidateProfile: CandidateProfile
  reloadCandidateProfile: () => void
  isThisCandidate: boolean
  isAdminOrEmployee: boolean
}

const Experience: React.FC<Props> = ({
  candidateProfile,
  reloadCandidateProfile,
  isThisCandidate,
  isAdminOrEmployee,
}) => {
  const teamSlug = useTeamSlug()

  const [limit, setLimit] = React.useState(3)
  const { showModal } = useModal()

  const renderExperienceModal = (resolve) => (
    <ExperienceModal
      onHide={() => resolve()}
      candidateProfile={candidateProfile}
      teamSlug={teamSlug}
      reloadCandidateProfile={reloadCandidateProfile}
    />
  )

  const sortedWorkHistory = React.useMemo(() => {
    return candidateProfile.work_history.sort((a, b) => {
      const aStart = a.start_at ? moment.utc(a.start_at).valueOf() : -Infinity
      const bStart = b.start_at ? moment.utc(b.start_at).valueOf() : -Infinity
      return bStart - aStart
    })
  }, [candidateProfile])

  const workHistory = React.useMemo(
    () => sortedWorkHistory.slice(0, limit),
    [sortedWorkHistory, limit],
  )

  if (sortedWorkHistory.length === 0) {
    return null
  }

  return (
    <Widget
      title="Experience"
      className="group"
      cta={
        (isAdminOrEmployee || isThisCandidate) && (
          <div className="hidden group-hover:flex">
            <CabalButton
              variant="link"
              padding={'0'}
              onClick={() => showModal(renderExperienceModal, 'experience-modal')}
            >
              Edit
            </CabalButton>
          </div>
        )
      }
    >
      <div className="-mx-2">
        {workHistory.map((work) => (
          <ExperienceRow key={work.uuid} work={work} />
        ))}
      </div>
      {candidateProfile.work_history.length > 3 && (
        <div className="flex justify-center pt-1">
          <CabalButton
            variant="link"
            onClick={() =>
              limit === 3 ? setLimit(candidateProfile.work_history.length) : setLimit(3)
            }
          >
            See all ({candidateProfile.work_history.length})
          </CabalButton>
        </div>
      )}
    </Widget>
  )
}

export default Experience
