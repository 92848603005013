import React from 'react'

import styled from 'styled-components'
import { useTheme } from 'styled-components'

import { STATUS_ICON_LABEL_MAPPING } from 'containers/RequestCenter/constants'
import { IIntroRequest } from 'containers/RequestCenter/types/ITypes'
import { prepareAnchorTagAndText, timeElapsedSinceNow } from 'containers/RequestCenter/utils'
import {
  extractNamesFromTimeline,
  extractRemainingTextFromTimeline,
} from 'containers/RequestCenter/utils'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

interface IProps {
  introRequestDetails: IIntroRequest
}

const DashedLine = styled.div`
  border-left: 1px dashed #ccc;
  height: 32px;
  width: 2px;
  margin-left: 7px;
`

export function Timeline({ introRequestDetails }: IProps) {
  const theme = useTheme()
  const { timeline_text_arr: timelineTextKeyTimestampArr } = introRequestDetails
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timeLineText: any[] = []
  const lastElementLength = timelineTextKeyTimestampArr.length - 1
  timelineTextKeyTimestampArr.forEach((timelineTextKeyTimestamp, index) => {
    const [timelineText, key, timestamp, iconMapping] = timelineTextKeyTimestamp.split(' || ')
    const timeAgo = timeElapsedSinceNow(new Date(timestamp))

    // Extract names between <>
    const names = extractNamesFromTimeline(timelineText)
    let remainingText = extractRemainingTextFromTimeline(timelineText)
    const textWithAnchorTag = prepareAnchorTagAndText(remainingText)
    remainingText = textWithAnchorTag.remainingText
    const anchorTags = textWithAnchorTag.anchorTag
    const icon = STATUS_ICON_LABEL_MAPPING[iconMapping]?.icon
    const iconToUse = icon ? icon : 'far fa-arrows-alt-h'
    timeLineText.push(
      <div key={`${key} - ${remainingText} - ${iconToUse}`}>
        <div className="flex items-start">
          <div>
            <Typography color="fog_rain" className="mr-2">
              <i className={iconToUse} style={{ color: theme.colors.purple }}></i>
            </Typography>
            {index !== lastElementLength && <DashedLine />}
          </div>

          <div className="flex gap-x-2 flex-wrap text-sm items-center line-clamp-2" key={key}>
            <Typography fontWeight={500}>{names[0]}</Typography>
            <Typography
              color="fog_rain"
              className=""
              dangerouslySetInnerHTML={{ __html: remainingText }}
            />
            {anchorTags ? (
              <a href={anchorTags.href} style={{ color: 'blue', marginLeft: '4px' }}>
                {anchorTags.text}
              </a>
            ) : null}
            {names.length > 1 && (
              <Typography fontWeight={500} className="">
                {names[1]}
              </Typography>
            )}
            <Typography color="fog_rain">·</Typography>
            <Typography color="fog_rain">{timeAgo}</Typography>
          </div>
        </div>
      </div>,
    )
  })

  return (
    <Widget title="Timeline">
      <div className="mt-6 flex flex-col">{timeLineText}</div>
    </Widget>
  )
}
