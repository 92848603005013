import React from 'react'

import { useSetState } from 'react-use'

import CabalButton from 'global/CabalButton'
import { CheckBox } from 'global/Input'
import Loading from 'global/Loading'
import { useTeam, useTeamSlug, useUpdateTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

const MessageVisibility: React.VFC = () => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { updateTeamAsync, isUpdatingTeam } = useUpdateTeam(teamSlug)

  const [updatedTeam, setUpdatedTeam] = useSetState(team)

  const handleSubmit = () => {
    updateTeamAsync({
      enable_admin_shared_messages: updatedTeam.enable_admin_shared_messages ?? false,
    })
      .then(() => cabalToast({ style: 'success', content: 'Team settings updated successfully!' }))
      .catch(() => cabalToast({ style: 'error', content: 'Failed to update team settings!' }))
  }

  return (
    <Widget title="Privacy">
      {!team && <Loading />}
      {team && (
        <div>
          <CheckBox
            checked={updatedTeam.enable_admin_shared_messages}
            onChange={(e) =>
              setUpdatedTeam({ enable_admin_shared_messages: e.currentTarget.checked })
            }
            label={`Allow admins to see all messages sent from ${team.name}`}
          />
          <div className="flex justify-end mt-6">
            <CabalButton
              variant="primary"
              disabled={isUpdatingTeam}
              working={isUpdatingTeam}
              onClick={handleSubmit}
              data-testid="save-public-dir"
            >
              Save
            </CabalButton>
          </div>
        </div>
      )}
    </Widget>
  )
}

export default MessageVisibility
