import React, { useMemo, useState } from 'react'

import { theme } from 'twin.macro'

import { useCompanySearch } from 'components/Winsite/queries/CompanySearch'
import MemberConnections from 'containers/Home/YourConnections/MemberConnections'
import PortfolioCompanyEditOrAddModal from 'containers/ListIndexView/PortfolioList/PortfolioCompanyEditOrAddModal'
import { CompanySelectProps } from 'containers/ListIndexView/PortfolioList/types'
import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'

import AddPersonModal from './AddPersonModal'
import { cabalToast } from 'ui-components/Toast'
import { useMutation } from 'react-query'
import api, { callApi } from 'utils/api'

interface Props {
  setOnClose: () => void
}

const AddIndividualSection: React.FC<Props> = ({ setOnClose }) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const { showModal } = useModal()
  const teamSlug = useTeamSlug()
  const logo_url = 'https://logo.clearbit.com/'

  const { isLoading, data: companies } = useCompanySearch(debouncedQuery, true)
  const results: CompanySelectProps[] = useMemo(() => companies?.data, [companies])
  const { companyListData: companyList, refetchCompanyList } = useSalesListContext()

  const { mutate: addPerson } = useMutation(
    (person_uuid: string) => callApi(api.addElementToSalesList, companyList.uuid, { person_uuid }),
    {
      onSuccess: () => {
        refetchCompanyList()
        setOnClose()
        cabalToast({
          content: 'Company added successfully!',
          style: 'success',
        })
      },
    },
  )

  return (
    <div className="mt-4">
      <SearchInput
        onChange={(c) => setQuery(c.target.value)}
        placeholder={`Search for people`}
        value={query}
        autoFocus
      />

      {debouncedQuery !== '' && (
        <>
          <div className="my-4 px-3">
            <CabalButton
              variant="link"
              onClick={() => {
                showModal(
                  (resolve) => (
                    <AddPersonModal
                      onHide={resolve}
                      name={debouncedQuery}
                      listUuid={companyList.uuid}
                      refetch={refetchCompanyList}
                    />
                  ),
                  'add-person-modal',
                )
                setOnClose()
              }}
              leftIcon={<i className="far fa-plus fa-xs" />}
              padding="0"
              size="large"
            >
              <span>{`Add "${debouncedQuery}"`}</span>
            </CabalButton>
          </div>
          {isLoading && (
            <div className="flex mt-4 w-full justify-center">
              <Loading />
            </div>
          )}
          <MemberConnections
            debouncedQuery={[debouncedQuery]}
            personRowCta={'Add'}
            onPersonRowCtaClick={(p) => {
              addPerson(p.uuid)
            }}
            searchAll
          />
        </>
      )}
    </div>
  )
}

export default AddIndividualSection
