import React from 'react'

import { TeamSettings } from 'utils/types'

import IntegrationCard from './IntegrationCard'
import salesforceImage from '../../../../assets/images/salesforce-200.png'
import { DataCard } from 'ui-components/DataCard'

interface Props {
  settings: TeamSettings
}

const SalesforceIntegrationCard: React.FC<Props> = ({ settings }) => {
  const navigateToAuth = () => window.location.assign(`/salesforce_auth`)

  return (
    <>
      {/* <IntegrationCard
        connected={settings.team_connected_sfdc}
        img={salesforceImage}
        name="Salesforce"
        description="Connect Salesforce to see opportunities and connection matches populate here."
        ctaText={settings.team_connected_sfdc ? 'Reauthorize' : 'Authorize'}
        icon={settings.team_connected_sfdc ? 'reload' : 'add'}
        disabled={settings.team_disabled_sfdc}
        onCtaClick={() => navigateToAuth()}
      /> */}
      <DataCard
        title="Salesforce"
        description={
          'Connect Salesforce to see opportunities and connection matches populate here.'
        }
        avatarUrl={salesforceImage}
        secondaryCTA={{
          text: settings.team_connected_sfdc ? 'Reauthorize' : 'Authorize',
          onClick: () => navigateToAuth(),
        }}
      />
    </>
  )
}

export default SalesforceIntegrationCard
