import axios from 'axios'

interface GetBlockParams {
  ownerType: string
  ownerId: string
  block_id: string
  filters?: any
  page?: number
}

interface GetBlockItemsParams {
  ownerType: string
  ownerId: string
  blockId: string
  filters: any
}

export default {
  getBlock: (params: GetBlockParams) => {
    return axios.get<any>(`/api/blocks/${params.block_id}`, {
      params,
    })
  },
}
