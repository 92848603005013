import React, { useState } from 'react'
import { useMutation } from 'react-query'

import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'

import api, { callApi } from 'utils/api'
import { CompanyBlueprint, CreateGlobalPersonRequest, GlobalPersonBlueprint } from 'utils/types'

interface Props {
  show: boolean
  company: CompanyBlueprint
  person: Partial<GlobalPersonBlueprint>
  onHide: () => void
  onCreate: (person: GlobalPersonBlueprint) => void
}

const AddPersonModal: React.FC<Props> = ({ show, onHide, onCreate, company, person }) => {
  const [details, setDetails] = useState<CreateGlobalPersonRequest>({
    name: person.name!,
    title: person.headline,
    linkedin_url: person.linkedin_url,
    email: '',
    company_id: company.id,
    global_person_uuid: person.uuid,
  })

  const { mutate: save, isLoading } = useMutation(() => callApi(api.createPerson, details), {
    onSuccess: ({ person }) => {
      onCreate(person)
    },
  })

  return (
    <Modal
      show={show}
      onHide={onHide}
      header="Add Person"
      rightActions={
        <CabalButton
          onClick={() => save()}
          data-testid="save-group"
          working={isLoading}
          disabled={!details.name}
        >
          Save & Offer Intro
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Name</ModalInputLabel>
          <TextInput
            value={details.name}
            onChange={(e) => setDetails({ ...details, name: e.currentTarget.value })}
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Company</ModalInputLabel>
          <TextInput value={company.name} disabled />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Title</ModalInputLabel>
          <TextInput
            value={details.title}
            onChange={(e) => setDetails({ ...details, title: e.currentTarget.value })}
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>LinkedIn</ModalInputLabel>
          <TextInput
            value={details.linkedin_url}
            onChange={(e) => setDetails({ ...details, linkedin_url: e.currentTarget.value })}
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Email</ModalInputLabel>
          <TextInput
            value={details.email}
            onChange={(e) => setDetails({ ...details, email: e.currentTarget.value })}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default AddPersonModal
