import React, { useState } from 'react'

import Expand from 'react-expand-animated'
import { useHotkeys } from 'react-hotkeys-hook'
import { useQuery } from 'react-query'

import TitleWrappers from 'components/Composer/Sidebar/TitleWrappers'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Card from 'global/Card'
import { SearchInput } from 'global/Input'
import Pill from 'global/Pill'
import Typography from 'global/Typography'

import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import { EmailSnippet } from 'utils/types/emailSnippet'

interface Props {
  handleSelectSnippet: (snippet: EmailSnippet) => void
  teamSlug: string
}

const VcInsertV2: React.VFC<Props> = ({ handleSelectSnippet, teamSlug }) => {
  const [toggle, setToggle] = useState(true)
  const [query, setQuery] = useState('')
  const [addedItems, setAddedItems] = useState<Set<string>>(new Set())
  const debouncedQuery = useDebouncedValue(query, 300)

  const handleAdd = (item) => {
    handleSelectSnippet({
      body: item.insert_content,
      needsParaWrap: true,
    })
    setAddedItems((prev) => new Set(prev).add(item.uuid))
  }

  const { data, isLoading } = useQuery(
    ['inserts', debouncedQuery],
    async () => {
      const response = await fetch(`/api/inserts?query=${debouncedQuery}`)
      return response.json()
    },
    {
      enabled: toggle,
    },
  )

  const getPillVariant = (type: string) => {
    if (type === 'Company') {
      return 'purple'
    } else if (type === 'Talent') {
      return 'green'
    } else if (type === 'Job') {
      return 'yellow'
    }
    return 'outline'
  }

  useHotkeys(
    'i',
    (e) => {
      e.preventDefault()
      setToggle(!toggle)
    },
    [toggle],
  )

  return (
    <div className="my-3">
      <TitleWrappers
        title={`Insert`}
        onClick={() => setToggle(!toggle)}
        active={toggle}
        shortcut={'I'}
      />
      <Expand open={toggle}>
        <Card padding={1}>
          <SearchInput
            onChange={(e) => setQuery(e.target.value)}
            defaultValue={query}
            value={query}
            placeholder="Search"
            fontSize="12px"
          />
          <div className="mb-2" />
          <div className="flex flex-col">
            {data?.map((insert) => (
              <div
                key={insert.uuid}
                className="hover:bg-gray-100 cursor-pointer rounded transition-colors duration-200 px-1"
                onClick={() => handleAdd(insert)}
              >
                <div className="flex items-center justify-between">
                  <Typography
                    color="fog_rain"
                    fontSize="12"
                    className="block truncate"
                    lineHeight="1.215"
                    title={insert.label_data.join(' - ')}
                  >
                    <span className="pr-1">
                      {insert.domain && (
                        <Avatar
                          name={insert.label_data[0]}
                          size="16"
                          src={`https://logo.clearbit.com/${insert.domain}`}
                        />
                      )}
                      {!insert.domain && insert.logo_url && (
                        <Avatar size="16" name={insert.label_data[0]} src={insert.logo_url} />
                      )}
                      {!insert.domain && !insert.logo_url && (
                        <Avatar
                          size="16"
                          name={insert.label_data[0]}
                          src={insert.avatar_upload_uuid}
                        />
                      )}
                    </span>
                    {insert.label_data.join(' - ')}
                  </Typography>
                  <div className="flex items-center">
                    <Pill variant={getPillVariant(insert.type)}>{insert.type}</Pill>
                    <CabalButton
                      variant="tertiary"
                      size="small"
                      rightIcon={
                        addedItems.has(insert.uuid) ? (
                          <i className="fas fa-check text-green-500"></i>
                        ) : (
                          <i className="far fa-plus"></i>
                        )
                      }
                    ></CabalButton>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Card>
      </Expand>
    </div>
  )
}

export default React.memo(VcInsertV2)
