import React, { useCallback, useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import { useInfiniteQuery, useMutation, useQuery } from 'react-query'
import { Link, Redirect, useHistory, useLocation, useParams } from 'react-router-dom'

import ActivityRow from 'components/Activity/ActivityRow'
import AdvisorInviteModal from 'components/AdvisorInvite'
import { useComposer } from 'components/Composer'
import ContributionsCounter from 'components/ContributionsCounter'
import CreateShoutOutModal from 'components/CreateShoutOutModal'
import ActivityTrackingModal from 'components/GlobalProfile/ActivityTrackingModal'
import { SaveMemberModal } from 'components/SaveMemberModal'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import { UploadButton } from 'global/Input'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import PageWrapper from 'global/PageWrapper'
import Tabs from 'global/Tabs'
import Typography from 'global/Typography'
import { useAdvisor, useGroups, useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { AdvisorModel, CompanySlugParam } from 'utils/types'
import { Activity } from 'utils/types'

import ProfileEmptyImage from '../../images/profile-empty@2x.png'
import AdvisorConnections from './AdvisorConnections'
import AdvisorGmail from './AdvisorGmail'
import AdvisorGroups from './AdvisorGroups'
import AdvisorNotes from './AdvisorNotes'
import Agreements from './Agreements'
import CustomFields from './CustomFields'

interface ViewAdvisorParams extends CompanySlugParam {
  id: string
  tab: string
}

const ViewAdvisor: React.VFC = () => {
  const history = useHistory()
  const location = useLocation<{
    showComposer: boolean | undefined
  }>()
  const showComposer = location.state?.showComposer
  const { id, company_slug: companySlug } = useParams<ViewAdvisorParams>()
  const { team, isLoading: isLoadingTeam, reloadTeam } = useTeam(companySlug)
  const { groups, isLoading: isLoadingGroup } = useGroups(companySlug)
  const { advisor, isLoading: isLoadingAdvisor, reloadAdvisor } = useAdvisor(companySlug, id)
  const { compose } = useComposer()
  const teamSlug = useTeamSlug()
  const {
    data,
    refetch,
    isLoading: isLoadingActivities,
  } = useQuery([companySlug, 'getMemberActivity', id], () =>
    callApi(api.getMemberActivity, companySlug, 'user_team_activities', id),
  )

  const {
    canViewGroups,
    canViewContribution,
    canEditAdvisors,
    canMessageAdvisors,
    canViewAgreements,
  } = useAccessControl(companySlug)

  const [showEditModal, setShowEditModal] = useState(false)
  const { showModal } = useModal()

  const renderActivityTrackingModal: RenderModal = (resolve) => (
    <ActivityTrackingModal
      show
      teamSlug={companySlug}
      advisorUuid={id}
      onHide={() => resolve(false)}
      advisorView={true}
      refetchActivity={refetch}
    />
  )

  useEffect(() => {
    if (showComposer && advisor && canMessageAdvisors) {
      compose({ recipients: { advisors: [advisor] } })
    }
  }, [advisor])

  const createShoutOut = () => {
    showModal(
      (resolve) => <CreateShoutOutModal advisor={advisor} resolve={resolve} teamSlug={teamSlug} />,
      'create-shout-out-modal',
    )
  }

  const inviteModal = useCallback(
    (resolve: (val?: boolean) => void, m: AdvisorModel) => {
      return <AdvisorInviteModal onHide={() => resolve()} teamSlug={team.slug} advisors={[m]} />
    },
    [team.slug],
  )

  const tabs = [
    canEditAdvisors
      ? {
          label: 'Connections',
          id: 'import-connections',
          component: <AdvisorConnections advisor={advisor} teamSlug={companySlug} />,
        }
      : null,
    canViewContribution
      ? {
          label: 'Activity',
          id: 'activity',
          component:
            !isLoadingActivities && !data?.activity?.length ? (
              <div className="mt-3">
                <EmptyState
                  heading="No member activity yet"
                  image={<img src={ProfileEmptyImage} />}
                  body={
                    <Typography textAlign="center" component="h5" className="pt-4 px-2">
                      {`Welcome to the Member Profile. All of this member's contributions
                    and interactions with you will be aggregated here.`}
                    </Typography>
                  }
                />
              </div>
            ) : (
              <>
                {/* <div className="mt-3">
                  <AdvisorContributions advisor={advisor} />
                </div> */}
                {isLoadingActivities && <Loading className="my-10" />}
                <div className="mt-3">
                  {data?.activity?.map((activity: Activity) => (
                    <div key={activity.uuid}>
                      <ActivityRow activity={activity} profileView />
                    </div>
                  ))}
                </div>
                <div className="mt-3">
                  <AdvisorGmail advisor={advisor} />
                </div>
              </>
            ),
        }
      : null,
    canViewGroups
      ? {
          label: 'Groups',
          id: 'groups',
          component:
            advisor.groups?.length > 0 ? (
              <div className="mt-3">
                <AdvisorGroups groups={advisor.groups} />
              </div>
            ) : (
              <div className="mt-3">
                <EmptyState
                  icon={<i className="far fa-users-class"></i>}
                  heading="No Groups"
                  body="This advisor is not part of any groups yet."
                />
              </div>
            ),
        }
      : null,
    canViewAgreements
      ? {
          label: 'Agreements',
          id: 'agreements',
          component: (
            <div className="mt-3">
              <Agreements team={team} advisor={advisor} />
            </div>
          ),
        }
      : null,
    canEditAdvisors
      ? {
          label: 'Notes',
          id: 'notes',
          component: (
            <div className="mt-3">
              <AdvisorNotes advisor={advisor} />{' '}
            </div>
          ),
        }
      : null,
    canEditAdvisors && team.features?.custom_fields
      ? {
          label: 'Custom Fields',
          id: 'custom-fields',
          component: <CustomFields advisor={advisor} />,
        }
      : null,

    // team.enable_twitter_integration
    //   ? {
    //       label: `Tweets`,
    //       id: 'tweets',
    //       component: <AdvisorTweets advisor={advisor} />,
    //     }
    //   : null,
    // canEditAdvisors
    //   ? { label: 'Notes', id: 'notes', component: <AdvisorNotes advisor={advisor} /> }
    //   : null,
  ]

  if (isLoadingAdvisor || isLoadingTeam || isLoadingGroup) {
    return <Loading className="my-10" />
  } else if (!(advisor && team && groups)) {
    return <Redirect to="/not-found" />
  }

  return (
    <PageWrapper title={advisor.name} hideTitle>
      <div>
        {/* Header Section */}
        <div className="flex justify-between flex-col sm:flex-row pb-1 pt-3">
          <div className="flex flex-row justify-start items-end space-x-3">
            <div className="flex flex-col">
              <Avatar
                className="flex-shrink-0 "
                name={advisor.name}
                size="72px"
                src={advisor.avatar_url || undefined}
                round
              />
            </div>

            <div className="flex items-baseline gap-3">
              <Typography lineHeight={1.25} fontSize="36" fontWeight={600}>
                {advisor.name}
              </Typography>
              <Typography
                fontSize="20"
                className="cursor-pointer"
                color="purple"
                lineHeight={1}
                onClick={() => window.open(`/${advisor.primary_slug}`)}
                title={`getcabal.com/${advisor.primary_slug}`}
              >
                <i className="fa fa-badge-check fa-fw -mr-1 cursor-pointer" />
              </Typography>
              {advisor.linkedin_url && (
                <Typography
                  fontSize="20"
                  className="cursor-pointer"
                  color="fog"
                  onClick={() => window.open(advisor.linkedin_url)}
                  title={advisor.linkedin_url}
                >
                  <i className="fab fa-linkedin"></i>
                </Typography>
              )}
              <Typography
                fontSize="20"
                className="cursor-pointer"
                color="fog"
                onClick={() => {
                  compose({ recipients: { advisors: [advisor] } })
                }}
                title={advisor.email}
              >
                <i className="far fa-envelope"></i>
              </Typography>
            </div>

            {/* <div className="flex flex-wrap justify-center sm:justify-start flex-grow-0 mt-2">
              {canViewGroups && advisor.groups?.length > 0 && (
                <AdvisorGroups groups={advisor.groups} />
              )}

              {/* {advisor.expertises?.length > 0 && (
                  <AdvisorExpertise expertises={advisor.expertises} />
                )} */}
            {/* </div> */}
          </div>

          <div className="flex justify-start sm:flex-col sm:mt-0 flex-shrink-0">
            {canEditAdvisors && (
              <div className="flex items-center gap-1.5">
                {/* <ContributionsCounter teamSlug={teamSlug} advisorUuid={advisor.uuid} /> */}
                <CabalButton
                  variant="secondary"
                  leftIcon={<i className="fa fa-trophy fa-sm" />}
                  onClick={() =>
                    showModal(renderActivityTrackingModal, 'render_activity_tracking_modal')
                  }
                  className="sm:mr-0"
                  tooltip="Log contribution"
                ></CabalButton>
                <CabalButton
                  variant="primary"
                  title="Edit"
                  // leftIcon={<i className="far fa-megaphone" />}
                  label="Edit"
                  tooltip="Edit"
                  onClick={() => setShowEditModal(true)}
                >
                  Edit
                </CabalButton>
                {advisor.shared_connections_at === null && (
                  <CabalButton
                    variant="primary"
                    title="Edit"
                    // leftIcon={<i className="far fa-megaphone" />}
                    label="Invite"
                    tooltip="Invite"
                    onClick={() =>
                      showModal((resolve) => inviteModal(resolve, advisor), 'send-invite-modal')
                    }
                  >
                    Invite
                  </CabalButton>
                )}
              </div>
            )}
          </div>
        </div>

        <Tabs data={tabs} pushSubRoutes defaultTab={'connections'} />
      </div>

      <SaveMemberModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        advisor={advisor}
        team={team}
        isEditing
        onSave={() => {
          reloadAdvisor()
          reloadTeam()
          setShowEditModal(false)
        }}
        onDelete={() => {
          history.push(`/${team?.slug}/people`)
        }}
      />
    </PageWrapper>
  )
}

export default ViewAdvisor
