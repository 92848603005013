import React from 'react'
import { useMutation } from 'react-query'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import Modal, { ModalComponentProps, ModalableComponentProps } from 'global/Modal'
import { ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { AdvisorModel, LimitedUser, UserBlueprint } from 'utils/types'
import { VoteBlueprint } from 'utils/types/vote'

interface Props extends ModalComponentProps {
  teamSlug: string
}

type Props2 = Props &
  (
    | {
        source: VoteBlueprint
        source_type: 'Vote'
      }
    | {
        source?: undefined
        source_type?: undefined
      }
  )

type Props3 = Props2 &
  (
    | {
        user: UserBlueprint
        advisor?: undefined
      }
    | {
        advisor: AdvisorModel
        user?: undefined
      }
  )

const CreateShoutOutModal: React.FC<Props3> = ({
  source,
  source_type,
  resolve,
  user,
  advisor,
  teamSlug,
}) => {
  let defaultMessage = ''
  if (source_type === 'Vote') {
    if (source.company) {
      defaultMessage = `Thanks for helping with ${source.company.name}!`
    } else {
      defaultMessage = `Thanks for intro with ${source.global_person?.name}!`
    }
  }

  const user_uuid = (user?.uuid || advisor?.user_uuid)!
  const avatar = user?.avatar_url || advisor?.avatar_url
  const name = user?.name || advisor?.name

  const [message, setMessage] = React.useState(defaultMessage)

  const createShoutOutMutation = useMutation(
    () =>
      callApi(api.createShoutOut, {
        team_slug: teamSlug,
        user_uuid,
        message,
        source_type,
        source_uuid: source?.uuid,
      }),
    {
      onSuccess: () => {
        resolve()
        cabalToast({ content: 'Shout out created', style: 'success' })
      },
    },
  )

  return (
    <Modal
      show
      onHide={resolve}
      header="Create Shout Out for"
      rightActions={
        <CabalButton
          onClick={() => createShoutOutMutation.mutate()}
          working={createShoutOutMutation.isLoading}
        >
          Save
        </CabalButton>
      }
      leftActions={
        <Typography fontSize="12" color="fog_rain" component="div">
          Cabal will notify the member about this shout-out. The member can choose to publicly
          showcase it.
        </Typography>
      }
    >
      <div>
        <Avatar src={avatar} name={name} /> {name}
      </div>
      <ModalSectionWrapper>
        <TextArea
          className="w-full"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Write a message..."
        />
      </ModalSectionWrapper>
    </Modal>
  )
}

export default CreateShoutOutModal
