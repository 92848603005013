import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import Loading from 'global/Loading'
import { compact } from 'lodash'
import CabalMessage from 'models/CabalMessage'
import { useInfiniteQuery } from 'react-query'
import DataCardV2 from 'ui-components/DataCardV2'
import api, { callApi } from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import { MessageModel } from 'utils/types'
import { EditListType } from '..'

export const SectionHeader = styled.div`
  ${tw`flex items-center justify-between`}
  height: 24px;
`

export type FieldFilterProps = {
  index?: number
  operator?: string
  filterable?: any
}

interface Props {
  list: EditListType
  setList: (list: Partial<EditListType>) => void
  hideListItemsLabel?: boolean
  teamSlug: string
}

const MessagesListItems: React.VFC<Props> = ({ teamSlug, list, setList, hideListItemsLabel }) => {
  const [query, setQuery] = useState<string>('')
  const debouncedQuery = useDebouncedValue(query, 1000)
  const [checked, setChecked] = useState<string[]>(
    compact(list.company_list_items.map((item) => item.message_uuid)),
  )

  const { data: messagesData, isLoading } = useInfiniteQuery(
    [teamSlug, 'getSearchMessage', debouncedQuery, false],
    ({ pageParam: page = 1 }) =>
      callApi(api.getSearchMessage, {
        team_slug: teamSlug,
        query: debouncedQuery,
        page,
      }),
    {
      getNextPageParam: (lastPage) => lastPage?.pagination?.next_page,
      getPreviousPageParam: (firstPage) => firstPage?.pagination?.prev_page,
    },
  )

  const handleSelection = (message: CabalMessage, selected: boolean) => {
    const newChecked = selected
      ? [...checked, message.uuid]
      : checked.filter((messageUuid) => messageUuid !== message.uuid)
    const listItems = newChecked.map((message_uuid) => ({ message_uuid }))
    setChecked(newChecked)
    setList({ company_list_items: listItems })
  }

  const renderMessage = (message: CabalMessage) => {
    return (
      <DataCardV2
        title={message.subject}
        description={message.snippet}
        checkbox={{
          checked: checked.includes(message.uuid),
          onChange: (e) => handleSelection(message, e.target.checked),
          dataTestId: 'select-mail-list-item-checkbox',
        }}
        onClick={() => {
          handleSelection(message, !checked.includes(message.uuid))
        }}
        cta={
          <CabalButton
            variant="link"
            rightIcon={<i className="far fa-chevron-right"></i>}
            onClick={(e) => {
              e.preventDefault()
              window.open(`/${teamSlug}/messages/${message.uuid}`)
            }}
            padding={'0'}
          >
            View
          </CabalButton>
        }
        className="cursor-pointer"
      />
    )
  }

  const messages = useMemo(
    () =>
      CabalMessage.fromArray(messagesData?.pages?.flatMap<MessageModel>((page) => page.messages)),
    [messagesData],
  )

  const itemEditor = (
    <div className="flex flex-col">
      {messages?.map((message: CabalMessage) => renderMessage(message))}
    </div>
  )

  return (
    <>
      <SearchInput
        placeholder="Search by message subject"
        onChange={(e) => setQuery(e.target.value)}
        value={query}
      />
      {isLoading ? (
        <div className="pt-20">
          <Loading />
        </div>
      ) : (
        <div style={{ height: 400, overflow: 'auto' }}>{itemEditor}</div>
      )}
    </>
  )
}

export default MessagesListItems
