import React, { useState } from 'react'

import { useQuery } from 'react-query'

import ChartSection from 'components/ActivityCharts/ChartSection'
import { ISelectedPeriod } from 'components/ActivityCharts/types'
import { useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'

const ActivityCharts = () => {
  const teamSlug = useTeamSlug()
  const [selectedPeriod, setSelectedPeriod] = useState<ISelectedPeriod>({
    candidate_created: 'last_90_days',
    candidate_view: 'last_90_days',
    request_intro_v2: 'all_time',
    request_intro_accepted: 'last_90_days',
    view_deal_board: 'last_90_days',
  })

  const [showCumulative, setShowCumulative] = useState({
    candidate_created: true,
    candidate_view: true,
    request_intro_v2: true,
    request_intro_accepted: true,
    view_deal_board: true,
  })

  const { data: activityDashboard } = useQuery(
    ['activity-dashboard', teamSlug, selectedPeriod, showCumulative],
    () => callApi(api.getActivityDashboard, teamSlug, selectedPeriod, showCumulative),
    {
      keepPreviousData: true,
    },
  )

  return (
    <div className="grid grid-cols-2 gap-6">
      {activityDashboard?.map((activity) => {
        return (
          <ChartSection
            name={activity.activity_name}
            key={activity.title}
            title={activity.title}
            data={activity}
            onSetSelectedPeriod={(val: string) =>
              setSelectedPeriod({ ...selectedPeriod, [activity.activity_name]: val })
            }
            onSetShowCumulative={(val: boolean) =>
              setShowCumulative({ ...showCumulative, [activity.activity_name]: val })
            }
            currentPeriod={selectedPeriod[activity.activity_name as keyof ISelectedPeriod]}
          />
        )
      })}
    </div>
  )
}

export default ActivityCharts
