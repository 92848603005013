import * as H from 'history'

export const replaceCurrentRouteWithoutSearchAndHash = (history: H.History) => {
  history.replace(window.location.pathname)
}

export const appendHttpsToDomain = (domain: string) => {
  if (domain.startsWith('http')) {
    return domain
  }
  return `https://${domain}`
}

export const replaceCurrentRouterWithoutSearch = (
  history: H.History,
  searchParamKeys: string[],
) => {
  const url = new URL(window.location.href)
  searchParamKeys.forEach((key) => {
    url.searchParams.delete(key)
  })

  if (url.toString() === window.location.href) return

  history.replace(url.href.replace(url.origin, ''))
}

export const domainToUrl = (domain: string) => {
  if (domain.startsWith('http')) {
    return domain
  }
  return `https://${domain}`
}

export const applicationUrl = () => {
  return window.ENV == 'development'
    ? 'http://getcabal.test:3000'
    : window.ENV == 'staging'
    ? 'https://getcabal.xyz'
    : 'https://getcabal.com'
}

export const loginUrl = () => {
  return `${applicationUrl()}/login`
}
export const signInUrl = (companyName: string | undefined) => {
  return `${applicationUrl()}/win?team_slug=${companyName}`
}

export const listUrl = (companySlug: string, listSlug: string | null) => {
  return `${applicationUrl()}/${companySlug}/lists/${listSlug}`
}

export const getPathParameters = () => {
  const currentUrl = window.location.href
  const url = new URL(currentUrl)
  return url.pathname.split('/')
}
