import React from 'react'

import { Value } from 'classnames'
import { Props as SelectProps, StylesConfig } from 'react-select'
import { useTheme } from 'styled-components'

import { SelectOption, StyledAsyncSelect } from 'global/Input/Select'
import Typography from 'global/Typography'

export default function MultiCityInput(props: {
  value: Array<{ value: string; label: string }> | null
  onChange: (val: Array<{ value: string; label: string }>) => void
  error?: string | undefined
  onBlur?: SelectProps['onBlur']
}) {
  const theme = useTheme()
  const autocompleteService = new (window as any).google.maps.places.AutocompleteService()

  const showErrorBorder = Boolean(props.error)
  const removeBorder = false
  const disabled = false

  const selectStyles: StylesConfig<SelectOption<Value>, boolean> = {
    control: (base) => ({
      ...base,
      color: theme.colors.primary,
      backgroundColor: theme.layout.main_bg_color,
      borderRadius: '0.25rem',
      borderColor: showErrorBorder ? theme.colors.border_danger : theme.colors.border,
      borderWidth: removeBorder ? undefined : '1px',
      minHeight: 0,
      boxShadow: undefined,
      ':hover': {
        backgroundColor: undefined,
        borderColor: showErrorBorder ? theme.colors.border_danger : theme.colors.border,
      },
    }),
    placeholder: (base) => ({
      ...base,
      marginLeft: '0.25rem',
      fontSize: '12px',
      color: theme.colors.placeholder,
    }),
    input: (base) => ({
      ...base,
      padding: '0',
      margin: 0,
      marginLeft: '0.1rem',
      color: theme.colors.primary,
      fontSize: '12px',
    }),
    menuList: (base) => ({
      ...base,
      color: theme.colors.primary,
      backgroundColor: theme.popup.background,
      padding: 0,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? theme.colors.hover : undefined,
      color: theme.colors.primary,
      cursor: 'pointer',
      ':active': {
        backgroundColor: theme.colors.hover,
      },
      fontSize: '12px',
    }),
    singleValue: (base) => ({
      ...base,
      color: theme.colors.primary,
      fontSize: '12px',
    }),
    multiValue: (base) => ({
      ...base,
      color: theme.colors.primary,
      backgroundColor: theme.buttons.default.bg_color,
      margin: '0.125rem 0.25rem 0.125rem 0',
      borderRadius: '0.3rem',
      maxWidth: '100%',
      fontSize: '12px',
    }),
    multiValueLabel: (base) => ({
      ...base,
      padding: 0,
      color: theme.colors.primary,
      paddingRight: '0.125rem',
      fontSize: '0.75rem',
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: theme.colors.primary,
      ':hover': {
        backgroundColor: theme.colors.hover,
        color: theme.colors.primary,
      },
    }),
  }

  return (
    <div className="flex flex-col items-stretch">
      <StyledAsyncSelect
        isMulti
        styles={selectStyles}
        value={props.value}
        onChange={(selected) => {
          props.onChange(selected as Array<{ value: string; label: string }>)
        }}
        onBlur={props.onBlur}
        loadOptions={async (input) => {
          const options = await new Promise((resolve) => {
            autocompleteService.getPlacePredictions(
              {
                input: input,
                types: ['(cities)'],
              },
              (predictions: any[] | null) => {
                resolve(
                  predictions?.map((prediction) => ({
                    value: prediction.structured_formatting.main_text,
                    label: prediction.description,
                  })) ?? [],
                )
              },
            )
          })
          return options as any
        }}
        placeholder="Enter cities"
        className="location-input"
        isClearable
        isDisabled={disabled}
      />

      {props.error && (
        <div className="flex justify-start pt-1">
          <Typography color="border_danger" fontSize="11">
            {props.error}
          </Typography>
        </div>
      )}
    </div>
  )
}
