import axios from 'axios'

import {
  ICreateGlobalPersonEmailRequest,
  ICreateGlobalPersonEmailResponse,
} from '../types/GlobalPersonEmails/ICreateGlobalPersonEmail'
import {
  IGetGlobalPersonEmailsRequest,
  IGetGlobalPersonEmailsResponse,
} from '../types/GlobalPersonEmails/IGetGlobalPersonEmails'

export default {
  getGlobalPersonEmails: (global_person_uuid: string, team_id: string) => {
    const params: IGetGlobalPersonEmailsRequest = {
      global_person_uuid,
      team_id,
    }
    return axios.get<IGetGlobalPersonEmailsResponse>(`/api/v2/global_person_emails`, { params })
  },
  createGlobalPersonEmail: (params: ICreateGlobalPersonEmailRequest) => {
    return axios.post<ICreateGlobalPersonEmailResponse>(`/api/v2/global_person_emails`, params)
  },
}
