import React from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { UpdateProfileMutation } from 'containers/GlobalProfile'
import Modal from 'global/Modal'
import Typography from 'global/Typography'

import { UserProfile } from 'utils/types'

import OptionsSection from './OptionsSection'
import PersonalInfo from './PersonalInfo'
import SettingsSection from './SettingsSection'

interface Props {
  onHide: () => void
  updateProfileMutation: UpdateProfileMutation
  profile: UserProfile
}

const EditGlobalProfile: React.FC<Props> = ({ onHide, profile, updateProfileMutation }) => {
  const [step, setStep] = React.useState(1)
  const [parent] = useAutoAnimate()

  return (
    <Modal onHide={onHide} show={true}>
      <Typography fontSize="12" fontWeight={600} className="mt-4 mb-2" component="p">
        Edit Profile
      </Typography>
      <OptionsSection step={step} setStep={setStep} />
      <div className="mt-7" ref={parent}>
        {step === 1 && (
          <PersonalInfo
            profile={profile}
            updateProfileMutation={updateProfileMutation}
            onHide={onHide}
          />
        )}
        {step === 2 && <SettingsSection onHide={onHide} />}
      </div>
    </Modal>
  )
}

export default EditGlobalProfile
