import React from 'react'

import styled from 'styled-components'

import { useComposer } from 'components/Composer'
import EditTemplate from 'containers/GlobalMessagesPage/Templates/EditTemplate'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'

import { EmailTemplate } from 'utils/types'

import CreateOption from '../CreateOption'
import Option from '../Options'

const CreateWrapper = styled.div`
  background-color: ${({ theme }) => theme.layout.main_bg_color};
`

const templateIcons: { [key: string]: JSX.Element | undefined } = {
  'Investor Update': <i className="far fa-newspaper" />,
  'Supporter Invite': <i className="far fa-envelope" />,
  'Intro Request': <i className="far fa-thumbs-up" />,
  'VC Portfolio Onboarding': <i className="far fa-folder-open" />,
  'Sales Support': <i className="far fa-sack-dollar" />,
  'Candidate Follow Up': <i className="far fa-user-tie" />,
}

interface Props {
  reloadTemplates: () => void
  teamSlug: string
  templates: EmailTemplate[]
}

const CreateTemplateSection: React.FC<Props> = ({ reloadTemplates, teamSlug, templates }) => {
  const { showModal } = useModal()
  const { compose } = useComposer(teamSlug)

  const renderEditModal = (resolve: () => void, templateUuid?: string) => (
    <EditTemplate
      onHide={() => resolve()}
      reloadTemplates={reloadTemplates}
      templateUuid={templateUuid}
      teamSlug={teamSlug}
    />
  )

  return (
    <CreateWrapper className="py-4">
      <div className="grid grid-cols-5 gap-2 px-2">
        <>
          {templates.map((template, i) => (
            <Option
              key={template.uuid}
              title={template.name}
              onClick={() => compose({ template_uuid: template.uuid })}
              icon={templateIcons[template.name]}
              data-testid="create-companies-list-pill"
              active={false}
              dropDownMenu={{
                menuItems: [
                  {
                    label: 'Edit',
                    onSelect: () =>
                      showModal(
                        (resolve) => renderEditModal(resolve, template.uuid),
                        'render_edit_template_modal',
                      ),
                  },
                ],
              }}
            />
          ))}
        </>
        <CreateOption
          title="New Template"
          onClick={() => showModal(renderEditModal, 'render_edit_template_modal')}
          dataTestid={'create-new-list-pill'}
        />

        {/* <Option
      pillText="Sales"
      pillColor="#0284C7"
      pillIcon="far fa-sack-dollar"
      title="New Template"
      data-testid="create-companies-list-pill"
      description="+ Add companies"
      onClick={() => history.push(`templates/new`)}
      active={true}
    /> */}
      </div>
    </CreateWrapper>
  )
}

export default CreateTemplateSection
