import * as React from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { compact, isEqual, uniqWith } from 'lodash'
import validator from 'validator'
import { cabalToast } from 'ui-components/Toast'

import Typography from 'global/Typography'
import { Label } from 'global/Input'
import { MultiSelect, SelectOption } from 'global/Input/Selectv2'
import { MessageRecipient } from 'utils/types'
import Tooltip from 'global/Tooltip'

const FieldWrapper = styled.div`
  background: ${({ theme }) => theme.layout.main_bg_color};
  border-radius: 0.25rem;
`

const emailExtractionRegex = /(?:[\w+-.%]+@[\w-.]+\.[A-Za-z]{2,})/g

export interface RecipientsFieldProps {
  selected?: MessageRecipient[]
  options: MessageRecipient[]
  onSelect: (selected: MessageRecipient[] | null) => void
  label?: string
  allowGroups?: boolean
  allowCustomEmail?: boolean
  allowAdvisors?: boolean
  willMailMerge?: boolean
  type?: 'to' | 'cc' | 'bcc'
  placeholder?: string
  isFixedFunc?: (r: MessageRecipient) => boolean
  onExpandGroup?: (r: MessageRecipient) => boolean
  onInputChange?: (value: string) => void
  rightActions?: React.ReactNode
}

const RecipientsField: React.VFC<RecipientsFieldProps> = (props) => {
  const {
    selected,
    onSelect,
    label,
    allowGroups = true,
    allowCustomEmail = false,
    allowAdvisors = true,
    willMailMerge = true,
    placeholder = '',
    type = 'to',
    isFixedFunc,
    onExpandGroup,
    onInputChange,
  } = props
  const reactSelectInputIdRef = React.useRef(`select-input-${new Date().getTime()}`)

  const options = React.useMemo(() => {
    return compact(
      props.options.map<SelectOption<MessageRecipient> | undefined>((option) => {
        if (allowGroups && option.type === 'group') {
          return {
            label: option.label,
            value: option,
            group: 'Group',
            toString: () => option.value,
            isFixed: isFixedFunc?.(option),
          }
        }
        if (allowAdvisors && option.type === 'advisor') {
          return {
            label: option.label,
            value: option,
            group: 'Member',
            toString: () => option.value,
            isFixed: isFixedFunc?.(option),
          }
        }
        if (allowCustomEmail && option.type === 'email') {
          return {
            label: option.label,
            value: option,
            group: 'Email',
            toString: () => `email-${option.value.email}`,
            isFixed: isFixedFunc?.(option),
          }
        }
        if (option.type === 'founder') {
          return {
            label: option.label,
            value: option,
            group: 'Employee',
            toString: () => option.value,
            isFixed: isFixedFunc?.(option),
          }
        }

        return undefined
      }),
    )
  }, [allowAdvisors, allowCustomEmail, allowGroups, isFixedFunc, props.options])

  const focusSelectInput = React.useCallback(
    () =>
      setTimeout(() => {
        document.getElementById(reactSelectInputIdRef.current)?.focus()
      }, 100),
    [],
  )

  const createEmailOption = React.useCallback(
    (s: string, failSoftly: boolean) => {
      const emails = s.match(emailExtractionRegex)
      if (!emails) {
        if (!failSoftly) {
          cabalToast({ style: 'error', content: `Invalid input, please enter email addresses` })
        }
        return
      }

      const newSelection: MessageRecipient[] = []

      for (const email of emails) {
        if (!validator.isEmail(email)) {
          cabalToast({ style: 'error', content: `${email} is an invalid email address` })
          return
        }

        newSelection.push({
          label: email,
          value: {
            email: email,
            first_name: '',
            last_name: '',
            add_member: false,
          },
          type: 'email',
        })
      }

      onSelect(uniqWith([...(selected || []), ...newSelection], isEqual))

      focusSelectInput()
    },
    [focusSelectInput, onSelect, selected],
  )

  const handleOnChange = (selected: MessageRecipient[] | null) => {
    onSelect(selected)
    focusSelectInput()
  }

  const handleClickMultiValue = (e: React.MouseEvent, value: MessageRecipient) => {
    if (value.type === 'group') {
      if (!confirm('Do you want to expand this group?')) return

      onExpandGroup?.(value)
    }
  }

  let tooltip = ''

  if (willMailMerge) {
    if (type === 'to') {
      tooltip = `Recipients in the “To” field will receive their own email. They will not see other recipients.`
    } else if (type === 'cc') {
      tooltip = `Adding people to “Cc” will copy them on each email. If your “To” field has 50 recipients,
        people in this field will receive 50 individual emails.`
    } else if (type === 'bcc') {
      tooltip = `Adding people to “Bcc” will copy them on each email. If your “To” field has 50 recipients,
        people in this field will receive 50 individual emails.`
    }
  }

  return (
    <Label className="mb-0 flex-grow">
      <FieldWrapper
        className={'flex justify-between Something'}
        data-intercom-target="messages_to_field"
      >
        {label && (
          <Typography
            fontWeight={400}
            color="admin_label"
            component="div"
            fontSize="12"
            className="px-3 my-auto"
          >
            {label}
          </Typography>
        )}

        <MultiSelect<MessageRecipient>
          data-testid={`recipient-field-${label}`}
          fontSize={'14px'}
          value={selected}
          onChange={handleOnChange}
          getOptionId={(v) => v}
          options={options}
          placeholder={placeholder}
          portal
          creatable={allowCustomEmail}
          onCreateOption={createEmailOption}
          formatCreateLabel={(i) => i}
          virtualized
          inputId={reactSelectInputIdRef.current}
          className={cx(
            {
              'w-full': !!label,
            },
            'flex-grow',
          )}
          removeBorder
          removeFocusRing
          onInputChange={onInputChange}
          onClickMultiValue={handleClickMultiValue}
          unknownValueToOption={(mr) => {
            if (mr.type === 'email') {
              return {
                group: 'Email',
                label: mr.value.first_name
                  ? `${compact([mr.value.first_name, mr.value.last_name]).join(' ')} (${
                      mr.value.email
                    })`
                  : mr.value.email,
                value: mr,
              }
            }

            return {
              label: mr.label,
              value: mr,
            }
          }}
          valueKeyFn={(mr) => {
            if (mr.type === 'email') {
              return mr.value.email
            } else {
              return mr.value
            }
          }}
          rightActions={[
            tooltip ? (
              <Tooltip
                label={
                  <Typography component="div" textAlign="center" className="py-1">
                    {tooltip}
                  </Typography>
                }
              >
                <i className="far fa-question-circle" />
              </Tooltip>
            ) : undefined,
          ]}
        />
        <Typography component="div" color="text_secondary" className="flex">
          {props.rightActions}
        </Typography>
      </FieldWrapper>
    </Label>
  )
}

export const MemoizedRecipientsField = React.memo(RecipientsField)

export default RecipientsField
