import { useEffect } from 'react'
import { useDeepCompareEffect } from 'react-use'

export const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay)
    return () => clearTimeout(handler)
  }, [...(deps || []), delay])
}

export const useDebouncedDeepCompareEffect = (effect, deps, delay) => {
  useDeepCompareEffect(() => {
    const handler = setTimeout(() => effect(), delay)
    return () => clearTimeout(handler)
  }, [...(deps || []), delay])
}
