import React, { useCallback } from 'react'

import cx from 'classnames'
import isNumber from 'lodash/isNumber'
import trim from 'lodash/trim'
import mergeRefs from 'react-merge-refs'
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format'
import styled, { StyledComponentPropsWithRef, css } from 'styled-components'
import tw from 'twin.macro'
import validator from 'validator'

import regex from 'utils/regex'
import { cn, cssObjectToString } from 'utils/styles'
import { FakeChangeEvent } from 'utils/types'

import Typography from '../Typography'

type LabelPosition = 'left' | 'top'

export interface TextInputProps {
  labeled?: boolean
  lightBg?: boolean
  label?: React.ReactNode
  labelPosition?: LabelPosition
  labelRounded3xl?: boolean
  validator?: (val: string) => boolean
  valueType?: 'email' | 'non-empty' | 'website' | 'phone' | 'number' | 'url' | 'domain'
  compact?: boolean
  required?: boolean
  component?: 'input' | 'textarea'
  fontSize?: string
  lineHeight?: string
  clearable?: boolean
}

interface StyledInputProps {
  lightBg: boolean
  showErrorBorder: boolean
  fontSize?: string
  noBg: boolean
  lineHeight?: string
}

const Input = styled.input<StyledInputProps>`
  border-color: ${({ theme, showErrorBorder }) =>
    showErrorBorder ? theme.colors.border_danger : theme.colors.border};
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme, lightBg, noBg }) => {
    if (noBg) return 'transparent'
    return lightBg ? theme.colors.primary_bg : theme.layout.main_bg_color
  }};
  ${({ style }) => style && cssObjectToString(style)};

  line-height: ${({ lineHeight }) => lineHeight || 'inherit'};

  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};

  &:disabled {
    color: ${({ theme }) => theme.colors.text_secondary};
    cursor: not-allowed;
  }

  outline: none;

  &:focus {
    outline: none;
  }
`

interface StyledLabelProps {
  lightBg: boolean
  labelPosition: LabelPosition
  labelRounded3xl: boolean
  showErrorBorder?: boolean
}

export const Label = styled.label<StyledLabelProps>`
  ${({ labelPosition }) =>
    labelPosition !== 'top' ? tw`px-3 flex items-center focus:ring-2` : null};

  ${(props) =>
    props.showErrorBorder &&
    css`
      border: solid 1px ${props.theme.colors.border_danger};
    `}

  ${({ labelPosition }) => labelPosition !== 'top' && tw`rounded-lg`}
  ${({ labelRounded3xl }) => labelRounded3xl && tw`rounded-3xl`}

  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ labelPosition, theme, lightBg }) => {
    if (labelPosition === 'top') return 'unset'
    return lightBg ? theme.colors.primary_bg : theme.layout.main_bg_color
  }};

  & .react-autosuggest__suggestion--focused {
    background-color: white;
  }
`

export type TextInputChangeEventType = FakeChangeEvent | React.ChangeEvent<HTMLInputElement>

export interface ExtendedTextInputProps
  extends TextInputProps,
    StyledComponentPropsWithRef<'input'> {
  onChange?: (e: TextInputChangeEventType, valid?: boolean) => void
  hasError?: boolean
  errorMessage?: string
}

export const TextInput = React.forwardRef<HTMLInputElement, ExtendedTextInputProps>(
  (
    {
      className,
      errorMessage,
      label,
      labeled = false,
      labelPosition = 'top',
      labelRounded3xl = false,
      hasError = false,
      value,
      onChange,
      onFocus,
      onBlur,
      clearable = false,
      validator: _validator,
      valueType,
      compact = false,
      lightBg = false,
      required = false,
      fontSize,
      lineHeight,
      component,
      ...extraProps
    },
    _ref,
  ) => {
    const localRef = React.useRef<HTMLInputElement>(null)
    const ref = mergeRefs([localRef, _ref])
    const [validated, setValidated] = React.useState<boolean>()
    const [isEmpty, setIsEmpty] = React.useState<boolean>(!!!value)

    if (!_validator && valueType)
      switch (valueType) {
        case 'email':
          _validator = validator.isEmail
          break
        case 'non-empty':
          _validator = (v: string) => !!v
          break
        case 'website':
          _validator = validator.isURL
          break
        case 'url':
          _validator = (s) =>
            validator.isURL(s, { require_protocol: true }) || !!s.match(regex.mailto)
          break
        case 'phone':
          _validator = validator.isMobilePhone
          break
        case 'number':
          _validator = (str) => validator.isNumeric(str.split(',').join(''))
          break
        case 'domain':
          _validator = validator.isFQDN
          break
      }

    const validatorFunc = useCallback(
      (v: string) => (!!_validator ? _validator?.(trim(v)) : undefined),
      [_validator],
    )

    if (labeled && !label) label = extraProps.placeholder
    const hasInlineLabelOrLogo = (label && labelPosition === 'left') || clearable
    const hasLabelOnTop = label && labelPosition === 'top'

    const handleOnFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
      (e) => {
        onFocus?.(e)
        if (_validator) setValidated(undefined)
      },
      [onFocus, setValidated, _validator],
    )

    const handleOnBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
      (e) => {
        onBlur?.(e)
        if (!validatorFunc) return
        setValidated(validatorFunc(e.currentTarget.value))
      },
      [onBlur, validatorFunc, setValidated],
    )

    const handleOnChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        // setValue(e.currentTarget.value)
        onChange?.(e, _validator ? validatorFunc(e.currentTarget.value) : undefined)

        if (e.currentTarget.value === '') {
          setIsEmpty(true)
        } else {
          setIsEmpty(false)
        }
      },
      [onChange, _validator, validatorFunc],
    )

    let invalid = validated === false
    if (!required) {
      if (value === '') {
        invalid = false
      }
    }

    const input = (
      <>
        <Input
          ref={ref}
          value={value}
          fontSize={fontSize}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          as={component}
          className={cn(
            'inline appearance-none text-md rounded outline-none',
            'px-3 leading-tight focus:outline-none py-2 text-base',
            {
              'py-0 px-1 text-sm': !!compact,
              'w-full': !!label || clearable,
              'py-2': !hasInlineLabelOrLogo && !compact,
              border: !hasInlineLabelOrLogo,
              'focus:ring-2 focus:ring-blue-300': !hasInlineLabelOrLogo,
              'focus:shadow-none border-none focus:ring-0 focus:ring-transparent':
                hasInlineLabelOrLogo,
            },
            !label && className,
          )}
          showErrorBorder={invalid}
          lightBg={lightBg}
          noBg={!!hasInlineLabelOrLogo}
          lineHeight={lineHeight}
          {...extraProps}
        />
        {invalid && errorMessage && (
          <Typography component="p" fontSize="12" fontWeight={600} color="border_danger">
            {errorMessage}
          </Typography>
        )}
      </>
    )

    if (label || clearable) {
      return (
        <Label
          labelPosition={clearable ? 'left' : labelPosition}
          lightBg={lightBg}
          className={cn('focus-within:ring-2', className)}
          labelRounded3xl={labelRounded3xl}
          showErrorBorder={hasError}
        >
          {label && (
            <Typography
              color="fog"
              fontSize="12"
              component="div"
              fontWeight={400}
              className={cx({ 'mb-2 block': hasLabelOnTop })}
            >
              {label}
            </Typography>
          )}

          {input}

          {!isEmpty && clearable && (
            <Typography
              color="fog"
              fontSize="12"
              component="button"
              type="button"
              fontWeight={400}
              tabIndex={-1}
              onClick={() => {
                onChange?.({ currentTarget: { value: '' }, target: { value: '' } })
                setIsEmpty(true)
              }}
            >
              <i className="far fa-times" />
            </Typography>
          )}
        </Label>
      )
    }

    return input
  },
)

interface NumberFormattedInputProps extends NumberFormatProps {
  nonZero?: boolean
  type?: 'text' | 'tel' | 'password'
  thousandSeparator?: boolean
  onChange?: (e: TextInputChangeEventType, valid?: boolean) => void
}

export const NumberFormattedInput: React.VFC<NumberFormattedInputProps> = ({
  onChange,
  value,
  prefix,
  nonZero = false,
  thousandSeparator = true,
  type = 'text',
  ...extraProps
}) => {
  const handleOnValueChange = (v: NumberFormatValues) => {
    const newValue = v.floatValue || 0
    const stringValue = newValue?.toString()

    let valid = true
    if (!isNumber(newValue)) valid = false
    if (nonZero && newValue === 0) valid = false

    onChange?.(
      {
        target: {
          value: stringValue,
        },
        currentTarget: {
          value: stringValue,
        },
      },
      valid,
    )
  }

  return (
    <NumberFormat
      onValueChange={handleOnValueChange}
      value={value}
      prefix={prefix}
      isNumericString
      customInput={TextInput}
      type={type}
      thousandSeparator={thousandSeparator}
      validator={(str: string) => {
        let valid = true
        let num = str.split(',').join('')
        if (prefix) num = num.replace(prefix, '')

        if (!validator.isNumeric(num)) valid = false
        if (nonZero && Number(num) === 0) valid = false

        return valid
      }}
      {...extraProps}
    />
  )
}

type TextInputPropsWithHtml = React.InputHTMLAttributes<HTMLInputElement> & TextInputProps

interface ValidatedTextInputProps extends TextInputPropsWithHtml {
  error: string | undefined
}

export function TextInputField({ error, ...props }: ValidatedTextInputProps) {
  return (
    <div className="flex flex-col items-stretch w-full">
      <TextInput {...(props as TextInputProps)} hasError={Boolean(error)} />
      {Boolean(error) && (
        <div className="flex justify-start pt-1">
          <Typography color="border_danger" fontSize="11">
            {error}
          </Typography>
        </div>
      )}
    </div>
  )
}
