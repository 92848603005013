import * as React from 'react'
import styled, { StyledComponentPropsWithRef } from 'styled-components'
import tw from 'twin.macro'
import { transparentize } from 'polished'
import Typography from 'global/Typography'

const NoticeContainer = styled.div`
  ${tw`rounded p-2`}
  color: ${({ theme }) => theme.colors.text_secondary};
  font-size: 14px;
`

interface Props {
  text: string
}

const InfoNotice: React.FC<Props & StyledComponentPropsWithRef<'div'>> = ({
  text,
  ...extraProps
}) => {
  return (
    <NoticeContainer {...extraProps}>
      <Typography lineHeight={1}>
        <i className="fa fa-info-circle mr-2" />
        {text}
      </Typography>
    </NoticeContainer>
  )
}

export default InfoNotice
