const emojis = [
  {
    emoji: '👩‍👩‍👧‍👧',
    name: 'family: woman, woman, girl, girl',
    shortname: ':woman_woman_girl_girl:',
    unicode: '1F469 200D 1F469 200D 1F467 200D 1F467',
    html: '&#128105;&zwj;&#128105;&zwj;&#128103;&zwj;&#128103;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍👩‍👧‍👦',
    name: 'family: woman, woman, girl, boy',
    shortname: ':woman_woman_girl_boy:',
    unicode: '1F469 200D 1F469 200D 1F467 200D 1F466',
    html: '&#128105;&zwj;&#128105;&zwj;&#128103;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍👩‍👦‍👦',
    name: 'family: woman, woman, boy, boy',
    shortname: ':woman_woman_boy_boy:',
    unicode: '1F469 200D 1F469 200D 1F466 200D 1F466',
    html: '&#128105;&zwj;&#128105;&zwj;&#128102;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👩‍👧‍👧',
    name: 'family: man, woman, girl, girl',
    shortname: ':man_woman_girl_girl:',
    unicode: '1F468 200D 1F469 200D 1F467 200D 1F467',
    html: '&#128104;&zwj;&#128105;&zwj;&#128103;&zwj;&#128103;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👩‍👧‍👦',
    name: 'family: man, woman, girl, boy',
    shortname: ':man_woman_girl_boy:',
    unicode: '1F468 200D 1F469 200D 1F467 200D 1F466',
    html: '&#128104;&zwj;&#128105;&zwj;&#128103;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👩‍👦‍👦',
    name: 'family: man, woman, boy, boy',
    shortname: ':man_woman_boy_boy:',
    unicode: '1F468 200D 1F469 200D 1F466 200D 1F466',
    html: '&#128104;&zwj;&#128105;&zwj;&#128102;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👨‍👧‍👧',
    name: 'family: man, man, girl, girl',
    shortname: ':man_man_girl_girl:',
    unicode: '1F468 200D 1F468 200D 1F467 200D 1F467',
    html: '&#128104;&zwj;&#128104;&zwj;&#128103;&zwj;&#128103;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👨‍👧‍👦',
    name: 'family: man, man, girl, boy',
    shortname: ':man_man_girl_boy:',
    unicode: '1F468 200D 1F468 200D 1F467 200D 1F466',
    html: '&#128104;&zwj;&#128104;&zwj;&#128103;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👨‍👦‍👦',
    name: 'family: man, man, boy, boy',
    shortname: ':man_man_boy_boy:',
    unicode: '1F468 200D 1F468 200D 1F466 200D 1F466',
    html: '&#128104;&zwj;&#128104;&zwj;&#128102;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍👩‍👧',
    name: 'family: woman, woman, girl',
    shortname: ':woman_woman_girl:',
    unicode: '1F469 200D 1F469 200D 1F467',
    html: '&#128105;&zwj;&#128105;&zwj;&#128103;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍👩‍👦',
    name: 'family: woman, woman, boy',
    shortname: ':woman_woman_boy:',
    unicode: '1F469 200D 1F469 200D 1F466',
    html: '&#128105;&zwj;&#128105;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍👧‍👧',
    name: 'family: woman, girl, girl',
    shortname: ':woman_girl_girl:',
    unicode: '1F469 200D 1F467 200D 1F467',
    html: '&#128105;&zwj;&#128103;&zwj;&#128103;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍👧‍👦',
    name: 'family: woman, girl, boy',
    shortname: ':woman_girl_boy:',
    unicode: '1F469 200D 1F467 200D 1F466',
    html: '&#128105;&zwj;&#128103;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍👦‍👦',
    name: 'family: woman, boy, boy',
    shortname: ':woman_boy_boy:',
    unicode: '1F469 200D 1F466 200D 1F466',
    html: '&#128105;&zwj;&#128102;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👩‍👧',
    name: 'family: man, woman, girl',
    shortname: ':man_woman_girl:',
    unicode: '1F468 200D 1F469 200D 1F467',
    html: '&#128104;&zwj;&#128105;&zwj;&#128103;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👨‍👧',
    name: 'family: man, man, girl',
    shortname: ':man_man_girl:',
    unicode: '1F468 200D 1F468 200D 1F467',
    html: '&#128104;&zwj;&#128104;&zwj;&#128103;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👨‍👦',
    name: 'family: man, man, boy',
    shortname: ':man_man_boy:',
    unicode: '1F468 200D 1F468 200D 1F466',
    html: '&#128104;&zwj;&#128104;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👧‍👧',
    name: 'family: man, girl, girl',
    shortname: ':man_girl_girl:',
    unicode: '1F468 200D 1F467 200D 1F467',
    html: '&#128104;&zwj;&#128103;&zwj;&#128103;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👧‍👦',
    name: 'family: man, girl, boy',
    shortname: ':man_girl_boy:',
    unicode: '1F468 200D 1F467 200D 1F466',
    html: '&#128104;&zwj;&#128103;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👦‍👦',
    name: 'family: man, boy, boy',
    shortname: ':man_boy_boy:',
    unicode: '1F468 200D 1F466 200D 1F466',
    html: '&#128104;&zwj;&#128102;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍👧',
    name: 'family: woman, girl',
    shortname: ':woman_girl:',
    unicode: '1F469 200D 1F467',
    html: '&#128105;&zwj;&#128103;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍👦',
    name: 'family: woman, boy',
    shortname: ':woman_boy:',
    unicode: '1F469 200D 1F466',
    html: '&#128105;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👧',
    name: 'family: man, girl',
    shortname: ':man_girl:',
    unicode: '1F468 200D 1F467',
    html: '&#128104;&zwj;&#128103;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👦',
    name: 'family: man, boy',
    shortname: ':man_boy:',
    unicode: '1F468 200D 1F466',
    html: '&#128104;&zwj;&#128102;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '😂',
    name: 'face with tears of joy',
    shortname: ':joy:',
    unicode: '1f602',
    html: '&#128514;',
    category: 'Smileys & Emotion (face-smiling)',
    order: '3',
  },
  {
    emoji: '❤️',
    name: 'red heart',
    shortname: ':heart:',
    unicode: '2764',
    html: '&#10084;',
    category: 'Smileys & Emotion (emotion)',
    order: '1286',
  },
  {
    emoji: '♥️',
    name: 'heart suit',
    shortname: ':heart_suit:',
    unicode: '2665 FE0F',
    html: '&hearts;️',
    category: 'Activities (game)',
    order: '',
  },
  {
    emoji: '😍',
    name: 'smiling face with heart-eyes',
    shortname: ':heart_eyes:',
    unicode: '1f60d',
    html: '&#128525;',
    category: 'Smileys & Emotion (face-affection)',
    order: '13',
  },
  {
    emoji: '😭',
    name: 'loudly crying face',
    shortname: ':sob:',
    unicode: '1f62d',
    html: '&#128557;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '55',
  },
  {
    emoji: '😊',
    name: 'smiling face with smiling eyes',
    shortname: ':blush:',
    unicode: '1f60a',
    html: '&#128522;',
    category: 'Smileys & Emotion (face-smiling)',
    order: '10',
  },
  {
    emoji: '😒',
    name: 'unamused face',
    shortname: ':unamused:',
    unicode: '1f612',
    html: '&#128530;',
    category: 'Smileys & Emotion (face-neutral-skeptical)',
    order: '41',
  },
  {
    emoji: '😘',
    name: 'face blowing a kiss',
    shortname: ':kissing_heart:',
    unicode: '1f618',
    html: '&#128536;',
    category: 'Smileys & Emotion (face-affection)',
    order: '14',
  },
  {
    emoji: '💕',
    name: 'two hearts',
    shortname: ':two_hearts:',
    unicode: '1f495',
    html: '&#128149;',
    category: 'Smileys & Emotion (emotion)',
    order: '1289',
  },
  {
    emoji: '☺️',
    name: 'smiling face',
    shortname: ':smiling:',
    unicode: '263A FE0F',
    html: '&#9786;',
    category: 'Smileys & Emotion (face-affection)',
    order: '',
  },
  {
    emoji: '😩',
    name: 'weary face',
    shortname: ':weary:',
    unicode: '1f629',
    html: '&#128553;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '59',
  },
  {
    emoji: '👌🏿',
    name: 'OK hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F44C 1F3FF',
    html: '&#128076;&#127999;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '👌🏾',
    name: 'OK hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F44C 1F3FE',
    html: '&#128076;&#127998;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '👌🏽',
    name: 'OK hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F44C 1F3FD',
    html: '&#128076;&#127997;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '👌🏼',
    name: 'OK hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F44C 1F3FC',
    html: '&#128076;&#127996;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '👌🏻',
    name: 'OK hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F44C 1F3FB',
    html: '&#128076;&#127995;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '👌',
    name: 'OK hand',
    shortname: ':ok_hand:',
    unicode: '1f44c',
    html: '&#128076;',
    category: 'People & Body (hand-fingers-partial)',
    order: '1170',
  },
  {
    emoji: '😔',
    name: 'pensive face',
    shortname: ':pensive:',
    unicode: '1f614',
    html: '&#128532;',
    category: 'Smileys & Emotion (face-sleepy)',
    order: '43',
  },
  {
    emoji: '😏',
    name: 'smirking face',
    shortname: ':smirk:',
    unicode: '1f60f',
    html: '&#128527;',
    category: 'Smileys & Emotion (face-neutral-skeptical)',
    order: '26',
  },
  {
    emoji: '😁',
    name: 'beaming face with smiling eyes',
    shortname: ':grin:',
    unicode: '1f601',
    html: '&#128513;',
    category: 'Smileys & Emotion (face-smiling)',
    order: '2',
  },
  {
    emoji: '♻',
    name: 'recycling symbol',
    shortname: ':recycle:',
    unicode: '267b',
    html: '&#9851;',
    category: 'Symbols (other-symbol)',
    order: '2072',
  },
  {
    emoji: '😉',
    name: 'winking face',
    shortname: ':wink:',
    unicode: '1f609',
    html: '&#128521;',
    category: 'Smileys & Emotion (face-smiling)',
    order: '9',
  },
  {
    emoji: '👍🏿',
    name: 'thumbs up: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F44D 1F3FF',
    html: '&#128077;&#127999;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👍🏾',
    name: 'thumbs up: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F44D 1F3FE',
    html: '&#128077;&#127998;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👍🏽',
    name: 'thumbs up: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F44D 1F3FD',
    html: '&#128077;&#127997;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👍🏼',
    name: 'thumbs up: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F44D 1F3FC',
    html: '&#128077;&#127996;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👍🏻',
    name: 'thumbs up: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F44D 1F3FB',
    html: '&#128077;&#127995;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👍',
    name: 'thumbs up',
    shortname: ':thumbsup:',
    unicode: '1f44d',
    html: '&#128077;',
    category: 'People & Body (hand-fingers-closed)',
    order: '1176',
  },
  {
    emoji: '🙏🏿',
    name: 'folded hands: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64F 1F3FF',
    html: '&#128591;&#127999;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🙏🏾',
    name: 'folded hands: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64F 1F3FE',
    html: '&#128591;&#127998;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🙏🏽',
    name: 'folded hands: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64F 1F3FD',
    html: '&#128591;&#127997;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🙏🏼',
    name: 'folded hands: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64F 1F3FC',
    html: '&#128591;&#127996;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🙏🏻',
    name: 'folded hands: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64F 1F3FB',
    html: '&#128591;&#127995;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🙏',
    name: 'folded hands',
    shortname: ':pray:',
    unicode: '1f64f',
    html: '&#128591;',
    category: 'People & Body (hands)',
    order: '1248',
  },
  {
    emoji: '😌',
    name: 'relieved face',
    shortname: ':relieved:',
    unicode: '1f60c',
    html: '&#128524;',
    category: 'Smileys & Emotion (face-sleepy)',
    order: '35',
  },
  {
    emoji: '🎶',
    name: 'musical notes',
    shortname: ':notes:',
    unicode: '1f3b6',
    html: '&#127926;',
    category: 'Objects (music)',
    order: '1825',
  },
  {
    emoji: '😳',
    name: 'flushed face',
    shortname: ':flushed:',
    unicode: '1f633',
    html: '&#128563;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '63',
  },
  {
    emoji: '🙌🏿',
    name: 'raising hands: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64C 1F3FF',
    html: '&#128588;&#127999;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🙌🏾',
    name: 'raising hands: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64C 1F3FE',
    html: '&#128588;&#127998;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🙌🏽',
    name: 'raising hands: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64C 1F3FD',
    html: '&#128588;&#127997;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🙌🏼',
    name: 'raising hands: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64C 1F3FC',
    html: '&#128588;&#127996;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🙌🏻',
    name: 'raising hands: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64C 1F3FB',
    html: '&#128588;&#127995;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🙌',
    name: 'raising hands',
    shortname: ':raised_hands:',
    unicode: '1f64c',
    html: '&#128588;',
    category: 'People & Body (hands)',
    order: '1242',
  },
  {
    emoji: '🙈',
    name: 'see-no-evil monkey',
    shortname: ':see_no_evil:',
    unicode: '1f648',
    html: '&#128584;',
    category: 'Smileys & Emotion (monkey-face)',
    order: '96',
  },
  {
    emoji: '😢',
    name: 'crying face',
    shortname: ':cry:',
    unicode: '1f622',
    html: '&#128546;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '54',
  },
  {
    emoji: '😎',
    name: 'smiling face with sunglasses',
    shortname: ':sunglasses:',
    unicode: '1f60e',
    html: '&#128526;',
    category: 'Smileys & Emotion (face-glasses)',
    order: '12',
  },
  {
    emoji: '✌🏿',
    name: 'victory hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '270C 1F3FF',
    html: '&#9996;&#127999;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '✌🏾',
    name: 'victory hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '270C 1F3FE',
    html: '&#9996;&#127998;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '✌🏽',
    name: 'victory hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '270C 1F3FD',
    html: '&#9996;&#127997;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '✌🏼',
    name: 'victory hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '270C 1F3FC',
    html: '&#9996;&#127996;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '✌🏻',
    name: 'victory hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '270C 1F3FB',
    html: '&#9996;&#127995;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '✌️',
    name: 'victory hand',
    shortname: ':v:',
    unicode: '270c',
    html: '&#9996;',
    category: 'People & Body (hand-fingers-partial)',
    order: '1128',
  },
  {
    emoji: '👀',
    name: 'eyes',
    shortname: ':eyes:',
    unicode: '1f440',
    html: '&#128064;',
    category: 'People & Body (body-parts)',
    order: '1279',
  },
  {
    emoji: '😅',
    name: 'grinning face with sweat',
    shortname: ':sweat_smile:',
    unicode: '1f605',
    html: '&#128517;',
    category: 'Smileys & Emotion (face-smiling)',
    order: '7',
  },
  {
    emoji: '✨',
    name: 'sparkles',
    shortname: ':sparkles:',
    unicode: '2728',
    html: '&#10024;',
    category: 'Activities (event)',
    order: '1760',
  },
  {
    emoji: '😴',
    name: 'sleeping face',
    shortname: ':sleeping:',
    unicode: '1f634',
    html: '&#128564;',
    category: 'Smileys & Emotion (face-sleepy)',
    order: '34',
  },
  {
    emoji: '😄',
    name: 'grinning face with smiling eyes',
    shortname: ':smile:',
    unicode: '1f604',
    html: '&#128516;',
    category: 'Smileys & Emotion (face-smiling)',
    order: '6',
  },
  {
    emoji: '💜',
    name: 'purple heart',
    shortname: ':purple_heart:',
    unicode: '1f49c',
    html: '&#128156;',
    category: 'Smileys & Emotion (emotion)',
    order: '1295',
  },
  {
    emoji: '💔',
    name: 'broken heart',
    shortname: ':broken_heart:',
    unicode: '1f494',
    html: '&#128148;',
    category: 'Smileys & Emotion (emotion)',
    order: '1288',
  },
  {
    emoji: '💯',
    name: 'hundred points',
    shortname: ':hundred_points:',
    unicode: '1F4AF',
    html: '&#128175;',
    category: 'Smileys & Emotion (emotion)',
    order: '',
  },
  {
    emoji: '😑',
    name: 'expressionless face',
    shortname: ':expressionless:',
    unicode: '1f611',
    html: '&#128529;',
    category: 'Smileys & Emotion (face-neutral-skeptical)',
    order: '23',
  },
  {
    emoji: '💖',
    name: 'sparkling heart',
    shortname: ':sparkling_heart:',
    unicode: '1f496',
    html: '&#128150;',
    category: 'Smileys & Emotion (emotion)',
    order: '1290',
  },
  {
    emoji: '💙',
    name: 'blue heart',
    shortname: ':blue_heart:',
    unicode: '1f499',
    html: '&#128153;',
    category: 'Smileys & Emotion (emotion)',
    order: '1292',
  },
  {
    emoji: '😕',
    name: 'confused face',
    shortname: ':confused:',
    unicode: '1f615',
    html: '&#128533;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '44',
  },
  {
    emoji: '💁🏿‍♂',
    name: 'man tipping hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F481 1F3FF 200D 2642',
    html: '&#128129;&#127999;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏾‍♂',
    name: 'man tipping hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F481 1F3FE 200D 2642',
    html: '&#128129;&#127998;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏽‍♂',
    name: 'man tipping hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F481 1F3FD 200D 2642',
    html: '&#128129;&#127997;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏼‍♂',
    name: 'man tipping hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F481 1F3FC 200D 2642',
    html: '&#128129;&#127996;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏻‍♂',
    name: 'man tipping hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F481 1F3FB 200D 2642',
    html: '&#128129;&#127995;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁‍♂',
    name: 'man tipping hand',
    shortname: ':man_tipping_hand:',
    unicode: '1F481 200D 2642',
    html: '&#128129;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏿',
    name: 'person tipping hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F481 1F3FF',
    html: '&#128129;&#127999;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏾',
    name: 'person tipping hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F481 1F3FE',
    html: '&#128129;&#127998;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏽',
    name: 'person tipping hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F481 1F3FD',
    html: '&#128129;&#127997;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏼',
    name: 'person tipping hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F481 1F3FC',
    html: '&#128129;&#127996;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏻',
    name: 'person tipping hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F481 1F3FB',
    html: '&#128129;&#127995;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁',
    name: 'person tipping hand',
    shortname: ':information_desk_person:',
    unicode: '1f481',
    html: '&#128129;',
    category: 'People & Body (person-gesture)',
    order: '567',
  },
  {
    emoji: '😜',
    name: 'winking face with tongue',
    shortname: ':stuck_out_tongue_winking_eye:',
    unicode: '1f61c',
    html: '&#128540;',
    category: 'Smileys & Emotion (face-tongue)',
    order: '38',
  },
  {
    emoji: '😞',
    name: 'disappointed face',
    shortname: ':disappointed:',
    unicode: '1f61e',
    html: '&#128542;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '51',
  },
  {
    emoji: '😋',
    name: 'face savoring food',
    shortname: ':yum:',
    unicode: '1f60b',
    html: '&#128523;',
    category: 'Smileys & Emotion (face-tongue)',
    order: '11',
  },
  {
    emoji: '😐',
    name: 'neutral face',
    shortname: ':neutral_face:',
    unicode: '1f610',
    html: '&#128528;',
    category: 'Smileys & Emotion (face-neutral-skeptical)',
    order: '22',
  },
  {
    emoji: '😪',
    name: 'sleepy face',
    shortname: ':sleepy:',
    unicode: '1f62a',
    html: '&#128554;',
    category: 'Smileys & Emotion (face-sleepy)',
    order: '32',
  },
  {
    emoji: '👏🏿',
    name: 'clapping hands: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F44F 1F3FF',
    html: '&#128079;&#127999;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '👏🏾',
    name: 'clapping hands: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F44F 1F3FE',
    html: '&#128079;&#127998;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '👏🏽',
    name: 'clapping hands: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F44F 1F3FD',
    html: '&#128079;&#127997;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '👏🏼',
    name: 'clapping hands: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F44F 1F3FC',
    html: '&#128079;&#127996;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '👏🏻',
    name: 'clapping hands: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F44F 1F3FB',
    html: '&#128079;&#127995;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '👏',
    name: 'clapping hands',
    shortname: ':clap:',
    unicode: '1f44f',
    html: '&#128079;',
    category: 'People & Body (hands)',
    order: '1224',
  },
  {
    emoji: '💘',
    name: 'heart with arrow',
    shortname: ':cupid:',
    unicode: '1f498',
    html: '&#128152;',
    category: 'Smileys & Emotion (emotion)',
    order: '1285',
  },
  {
    emoji: '💗',
    name: 'growing heart',
    shortname: ':heartpulse:',
    unicode: '1f497',
    html: '&#128151;',
    category: 'Smileys & Emotion (emotion)',
    order: '1291',
  },
  {
    emoji: '💞',
    name: 'revolving hearts',
    shortname: ':revolving_hearts:',
    unicode: '1f49e',
    html: '&#128158;',
    category: 'Smileys & Emotion (emotion)',
    order: '1298',
  },
  {
    emoji: '⬅️',
    name: 'left arrow',
    shortname: ':arrow_left:',
    unicode: '2b05',
    html: '&#11013;',
    category: 'Symbols (arrow)',
    order: '2008',
  },
  {
    emoji: '🙊',
    name: 'speak-no-evil monkey',
    shortname: ':speak_no_evil:',
    unicode: '1f64a',
    html: '&#128586;',
    category: 'Smileys & Emotion (monkey-face)',
    order: '98',
  },
  {
    emoji: '✋🏿',
    name: 'raised hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '270B 1F3FF',
    html: '&#9995;&#127999;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '✋🏾',
    name: 'raised hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '270B 1F3FE',
    html: '&#9995;&#127998;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '✋🏽',
    name: 'raised hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '270B 1F3FD',
    html: '&#9995;&#127997;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '✋🏼',
    name: 'raised hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '270B 1F3FC',
    html: '&#9995;&#127996;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '✋🏻',
    name: 'raised hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '270B 1F3FB',
    html: '&#9995;&#127995;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '✋',
    name: 'raised hand',
    shortname: ':raised_hand:',
    unicode: '270B',
    html: '&#9995;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '💋',
    name: 'kiss mark',
    shortname: ':kiss:',
    unicode: '1f48b',
    html: '&#128139;',
    category: 'Smileys & Emotion (emotion)',
    order: '1284',
  },
  {
    emoji: '👉🏿',
    name: 'backhand index pointing right: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F449 1F3FF',
    html: '&#128073;&#127999;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👉🏾',
    name: 'backhand index pointing right: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F449 1F3FE',
    html: '&#128073;&#127998;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👉🏽',
    name: 'backhand index pointing right: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F449 1F3FD',
    html: '&#128073;&#127997;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👉🏼',
    name: 'backhand index pointing right: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F449 1F3FC',
    html: '&#128073;&#127996;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👉🏻',
    name: 'backhand index pointing right: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F449 1F3FB',
    html: '&#128073;&#127995;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👉',
    name: 'backhand index pointing right',
    shortname: ':point_right:',
    unicode: '1f449',
    html: '&#128073;',
    category: 'People & Body (hand-single-finger)',
    order: '1098',
  },
  {
    emoji: '🌸',
    name: 'cherry blossom',
    shortname: ':cherry_blossom:',
    unicode: '1f338',
    html: '&#127800;',
    category: 'Animals & Nature (plant-flower)',
    order: '1428',
  },
  {
    emoji: '😱',
    name: 'face screaming in fear',
    shortname: ':scream:',
    unicode: '1f631',
    html: '&#128561;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '62',
  },
  {
    emoji: '🔥',
    name: 'fire',
    shortname: ':fire:',
    unicode: '1f525',
    html: '&#128293;',
    category: 'Travel & Places (sky & weather)',
    order: '1753',
  },
  {
    emoji: '😡',
    name: 'pouting face',
    shortname: ':rage:',
    unicode: '1f621',
    html: '&#128545;',
    category: 'Smileys & Emotion (face-negative)',
    order: '65',
  },
  {
    emoji: '😃',
    name: 'grinning face with big eyes',
    shortname: ':smiley:',
    unicode: '1f603',
    html: '&#128515;',
    category: 'Smileys & Emotion (face-smiling)',
    order: '5',
  },
  {
    emoji: '🎉',
    name: 'party popper',
    shortname: ':tada:',
    unicode: '1f389',
    html: '&#127881;',
    category: 'Activities (event)',
    order: '1762',
  },
  {
    emoji: '👊🏿',
    name: 'oncoming fist: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F44A 1F3FF',
    html: '&#128074;&#127999;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👊🏾',
    name: 'oncoming fist: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F44A 1F3FE',
    html: '&#128074;&#127998;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👊🏽',
    name: 'oncoming fist: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F44A 1F3FD',
    html: '&#128074;&#127997;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👊🏼',
    name: 'oncoming fist: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F44A 1F3FC',
    html: '&#128074;&#127996;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👊🏻',
    name: 'oncoming fist: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F44A 1F3FB',
    html: '&#128074;&#127995;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👊',
    name: 'oncoming fist',
    shortname: ':oncoming_fist:',
    unicode: '1F44A',
    html: '&#128074;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '😫',
    name: 'tired face',
    shortname: ':tired_face:',
    unicode: '1f62b',
    html: '&#128555;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '33',
  },
  {
    emoji: '📷',
    name: 'camera',
    shortname: ':camera:',
    unicode: '1f4f7',
    html: '&#128247;',
    category: 'Objects (light & video)',
    order: '1861',
  },
  {
    emoji: '🌹',
    name: 'rose',
    shortname: ':rose:',
    unicode: '1f339',
    html: '&#127801;',
    category: 'Animals & Nature (plant-flower)',
    order: '1431',
  },
  {
    emoji: '😝',
    name: 'squinting face with tongue',
    shortname: ':stuck_out_tongue_closed_eyes:',
    unicode: '1f61d',
    html: '&#128541;',
    category: 'Smileys & Emotion (face-tongue)',
    order: '39',
  },
  {
    emoji: '💪🏿',
    name: 'flexed biceps: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F4AA 1F3FF',
    html: '&#128170;&#127999;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '💪🏾',
    name: 'flexed biceps: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F4AA 1F3FE',
    html: '&#128170;&#127998;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '💪🏽',
    name: 'flexed biceps: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F4AA 1F3FD',
    html: '&#128170;&#127997;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '💪🏼',
    name: 'flexed biceps: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F4AA 1F3FC',
    html: '&#128170;&#127996;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '💪🏻',
    name: 'flexed biceps: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F4AA 1F3FB',
    html: '&#128170;&#127995;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '💪',
    name: 'flexed biceps',
    shortname: ':muscle:',
    unicode: '1f4aa',
    html: '&#128170;',
    category: 'People & Body (body-parts)',
    order: '1080',
  },
  {
    emoji: '💀',
    name: 'skull',
    shortname: ':skull:',
    unicode: '1f480',
    html: '&#128128;',
    category: 'Smileys & Emotion (face-negative)',
    order: '80',
  },
  {
    emoji: '☀️',
    name: 'sun',
    shortname: ':sunny:',
    unicode: '2600',
    html: '&#9728;',
    category: 'Travel & Places (sky & weather)',
    order: '1724',
  },
  {
    emoji: '💛',
    name: 'yellow heart',
    shortname: ':yellow_heart:',
    unicode: '1f49b',
    html: '&#128155;',
    category: 'Smileys & Emotion (emotion)',
    order: '1294',
  },
  {
    emoji: '😤',
    name: 'face with steam from nose',
    shortname: ':triumph:',
    unicode: '1f624',
    html: '&#128548;',
    category: 'Smileys & Emotion (face-negative)',
    order: '53',
  },
  {
    emoji: '🌚',
    name: 'new moon face',
    shortname: ':new_moon_with_face:',
    unicode: '1f31a',
    html: '&#127770;',
    category: 'Travel & Places (sky & weather)',
    order: '1720',
  },
  {
    emoji: '😆',
    name: 'grinning squinting face',
    shortname: ':laughing:',
    unicode: '1f606',
    html: '&#128518;',
    category: 'Smileys & Emotion (face-smiling)',
    order: '8',
  },
  {
    emoji: '😓',
    name: 'downcast face with sweat',
    shortname: ':sweat:',
    unicode: '1f613',
    html: '&#128531;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '42',
  },
  {
    emoji: '👈🏿',
    name: 'backhand index pointing left: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F448 1F3FF',
    html: '&#128072;&#127999;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👈🏾',
    name: 'backhand index pointing left: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F448 1F3FE',
    html: '&#128072;&#127998;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👈🏽',
    name: 'backhand index pointing left: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F448 1F3FD',
    html: '&#128072;&#127997;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👈🏼',
    name: 'backhand index pointing left: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F448 1F3FC',
    html: '&#128072;&#127996;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👈🏻',
    name: 'backhand index pointing left: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F448 1F3FB',
    html: '&#128072;&#127995;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👈',
    name: 'backhand index pointing left',
    shortname: ':point_left:',
    unicode: '1f448',
    html: '&#128072;',
    category: 'People & Body (hand-single-finger)',
    order: '1092',
  },
  {
    emoji: '✔️',
    name: 'check mark',
    shortname: ':heavy_check_mark:',
    unicode: '2714',
    html: '&#10004;',
    category: 'Symbols (other-symbol)',
    order: '2080',
  },
  {
    emoji: '😻',
    name: 'smiling cat with heart-eyes',
    shortname: ':heart_eyes_cat:',
    unicode: '1f63b',
    html: '&#128571;',
    category: 'Smileys & Emotion (cat-face)',
    order: '90',
  },
  {
    emoji: '😀',
    name: 'grinning face',
    shortname: ':grinning:',
    unicode: '1f600',
    html: '&#128512;',
    category: 'Smileys & Emotion (face-smiling)',
    order: '1',
  },
  {
    emoji: '😷',
    name: 'face with medical mask',
    shortname: ':mask:',
    unicode: '1f637',
    html: '&#128567;',
    category: 'Smileys & Emotion (face-unwell)',
    order: '71',
  },
  {
    emoji: '💚',
    name: 'green heart',
    shortname: ':green_heart:',
    unicode: '1f49a',
    html: '&#128154;',
    category: 'Smileys & Emotion (emotion)',
    order: '1293',
  },
  {
    emoji: '👋🏿',
    name: 'waving hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F44B 1F3FF',
    html: '&#128075;&#127999;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '👋🏾',
    name: 'waving hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F44B 1F3FE',
    html: '&#128075;&#127998;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '👋🏽',
    name: 'waving hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F44B 1F3FD',
    html: '&#128075;&#127997;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '👋🏼',
    name: 'waving hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F44B 1F3FC',
    html: '&#128075;&#127996;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '👋🏻',
    name: 'waving hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F44B 1F3FB',
    html: '&#128075;&#127995;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '👋',
    name: 'waving hand',
    shortname: ':wave:',
    unicode: '1f44b',
    html: '&#128075;',
    category: 'People & Body (hand-fingers-open)',
    order: '1218',
  },
  {
    emoji: '😣',
    name: 'persevering face',
    shortname: ':persevere:',
    unicode: '1f623',
    html: '&#128547;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '27',
  },
  {
    emoji: '💓',
    name: 'beating heart',
    shortname: ':heartbeat:',
    unicode: '1f493',
    html: '&#128147;',
    category: 'Smileys & Emotion (emotion)',
    order: '1287',
  },
  {
    emoji: '▶️',
    name: 'play button',
    shortname: ':arrow_forward:',
    unicode: '25b6',
    html: '&#9654;',
    category: 'Symbols (av-symbol)',
    order: '2051',
  },
  {
    emoji: '◀️',
    name: 'reverse button',
    shortname: ':arrow_backward:',
    unicode: '25c0',
    html: '&#9664;',
    category: 'Symbols (av-symbol)',
    order: '2055',
  },
  {
    emoji: '↪️',
    name: 'left arrow curving right',
    shortname: ':arrow_right_hook:',
    unicode: '21aa',
    html: '&#8618;',
    category: 'Symbols (arrow)',
    order: '2013',
  },
  {
    emoji: '↩️',
    name: 'right arrow curving left',
    shortname: ':leftwards_arrow_with_hook:',
    unicode: '21a9',
    html: '&#8617;',
    category: 'Symbols (arrow)',
    order: '2012',
  },
  {
    emoji: '👑',
    name: 'crown',
    shortname: ':crown:',
    unicode: '1f451',
    html: '&#128081;',
    category: 'Objects (clothing)',
    order: '1333',
  },
  {
    emoji: '😚',
    name: 'kissing face with closed eyes',
    shortname: ':kissing_closed_eyes:',
    unicode: '1f61a',
    html: '&#128538;',
    category: 'Smileys & Emotion (face-affection)',
    order: '17',
  },
  {
    emoji: '😛',
    name: 'face with tongue',
    shortname: ':stuck_out_tongue:',
    unicode: '1f61b',
    html: '&#128539;',
    category: 'Smileys & Emotion (face-tongue)',
    order: '37',
  },
  {
    emoji: '😥',
    name: 'sad but relieved face',
    shortname: ':disappointed_relieved:',
    unicode: '1f625',
    html: '&#128549;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '28',
  },
  {
    emoji: '😇',
    name: 'smiling face with halo',
    shortname: ':innocent:',
    unicode: '1f607',
    html: '&#128519;',
    category: 'Smileys & Emotion (face-smiling)',
    order: '67',
  },
  {
    emoji: '🎧',
    name: 'headphone',
    shortname: ':headphones:',
    unicode: '1f3a7',
    html: '&#127911;',
    category: 'Objects (music)',
    order: '1830',
  },
  {
    emoji: '✅',
    name: 'check mark button',
    shortname: ':white_check_mark:',
    unicode: '2705',
    html: '&#9989;',
    category: 'Symbols (other-symbol)',
    order: '2078',
  },
  {
    emoji: '😖',
    name: 'confounded face',
    shortname: ':confounded:',
    unicode: '1f616',
    html: '&#128534;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '50',
  },
  {
    emoji: '➡',
    name: 'right arrow',
    shortname: ':arrow_right:',
    unicode: '27a1',
    html: '&#10145;',
    category: 'Symbols (arrow)',
    order: '2004',
  },
  {
    emoji: '😠',
    name: 'angry face',
    shortname: ':angry:',
    unicode: '1f620',
    html: '&#128544;',
    category: 'Smileys & Emotion (face-negative)',
    order: '66',
  },
  {
    emoji: '😬',
    name: 'grimacing face',
    shortname: ':grimacing:',
    unicode: '1f62c',
    html: '&#128556;',
    category: 'Smileys & Emotion (face-neutral-skeptical)',
    order: '60',
  },
  {
    emoji: '🌟',
    name: 'glowing star',
    shortname: ':star2:',
    unicode: '1f31f',
    html: '&#127775;',
    category: 'Travel & Places (sky & weather)',
    order: '1728',
  },
  {
    emoji: '🔫',
    name: 'pistol',
    shortname: ':gun:',
    unicode: '1f52b',
    html: '&#128299;',
    category: 'Objects (tool)',
    order: '1956',
  },
  {
    emoji: '🙋🏿‍♂',
    name: 'man raising hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64B 1F3FF 200D 2642',
    html: '&#128587;&#127999;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏾‍♂',
    name: 'man raising hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64B 1F3FE 200D 2642',
    html: '&#128587;&#127998;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏽‍♂',
    name: 'man raising hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64B 1F3FD 200D 2642',
    html: '&#128587;&#127997;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏼‍♂',
    name: 'man raising hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64B 1F3FC 200D 2642',
    html: '&#128587;&#127996;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏻‍♂',
    name: 'man raising hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64B 1F3FB 200D 2642',
    html: '&#128587;&#127995;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋‍♂',
    name: 'man raising hand',
    shortname: ':man_raising_hand:',
    unicode: '1F64B 200D 2642',
    html: '&#128587;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏿',
    name: 'person raising hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64B 1F3FF',
    html: '&#128587;&#127999;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏾',
    name: 'person raising hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64B 1F3FE',
    html: '&#128587;&#127998;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏽',
    name: 'person raising hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64B 1F3FD',
    html: '&#128587;&#127997;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏼',
    name: 'person raising hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64B 1F3FC',
    html: '&#128587;&#127996;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏻',
    name: 'person raising hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64B 1F3FB',
    html: '&#128587;&#127995;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋',
    name: 'person raising hand',
    shortname: ':raising_hand:',
    unicode: '1f64b',
    html: '&#128587;',
    category: 'People & Body (person-gesture)',
    order: '585',
  },
  {
    emoji: '👎🏿',
    name: 'thumbs down: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F44E 1F3FF',
    html: '&#128078;&#127999;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👎🏾',
    name: 'thumbs down: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F44E 1F3FE',
    html: '&#128078;&#127998;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👎🏽',
    name: 'thumbs down: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F44E 1F3FD',
    html: '&#128078;&#127997;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👎🏼',
    name: 'thumbs down: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F44E 1F3FC',
    html: '&#128078;&#127996;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👎🏻',
    name: 'thumbs down: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F44E 1F3FB',
    html: '&#128078;&#127995;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '👎',
    name: 'thumbs down',
    shortname: ':thumbsdown:',
    unicode: '1f44e',
    html: '&#128078;',
    category: 'People & Body (hand-fingers-closed)',
    order: '1182',
  },
  {
    emoji: '💃🏿',
    name: 'woman dancing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F483 1F3FF',
    html: '&#128131;&#127999;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💃🏾',
    name: 'woman dancing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F483 1F3FE',
    html: '&#128131;&#127998;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💃🏽',
    name: 'woman dancing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F483 1F3FD',
    html: '&#128131;&#127997;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💃🏼',
    name: 'woman dancing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F483 1F3FC',
    html: '&#128131;&#127996;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💃🏻',
    name: 'woman dancing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F483 1F3FB',
    html: '&#128131;&#127995;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💃',
    name: 'woman dancing',
    shortname: ':dancer:',
    unicode: '1f483',
    html: '&#128131;',
    category: 'People & Body (person-activity)',
    order: '729',
  },
  {
    emoji: '🎵',
    name: 'musical note',
    shortname: ':musical_note:',
    unicode: '1f3b5',
    html: '&#127925;',
    category: 'Objects (music)',
    order: '1824',
  },
  {
    emoji: '😶',
    name: 'face without mouth',
    shortname: ':no_mouth:',
    unicode: '1f636',
    html: '&#128566;',
    category: 'Smileys & Emotion (face-neutral-skeptical)',
    order: '24',
  },
  {
    emoji: '💫',
    name: 'dizzy',
    shortname: ':dizzy:',
    unicode: '1f4ab',
    html: '&#128171;',
    category: 'Smileys & Emotion (emotion)',
    order: '1308',
  },
  {
    emoji: '✊🏿',
    name: 'raised fist: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '270A 1F3FF',
    html: '&#9994;&#127999;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '✊🏾',
    name: 'raised fist: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '270A 1F3FE',
    html: '&#9994;&#127998;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '✊🏽',
    name: 'raised fist: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '270A 1F3FD',
    html: '&#9994;&#127997;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '✊🏼',
    name: 'raised fist: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '270A 1F3FC',
    html: '&#9994;&#127996;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '✊🏻',
    name: 'raised fist: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '270A 1F3FB',
    html: '&#9994;&#127995;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '✊',
    name: 'raised fist',
    shortname: ':fist:',
    unicode: '270a',
    html: '&#9994;',
    category: 'People & Body (hand-fingers-closed)',
    order: '1188',
  },
  {
    emoji: '👇🏿',
    name: 'backhand index pointing down: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F447 1F3FF',
    html: '&#128071;&#127999;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👇🏾',
    name: 'backhand index pointing down: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F447 1F3FE',
    html: '&#128071;&#127998;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👇🏽',
    name: 'backhand index pointing down: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F447 1F3FD',
    html: '&#128071;&#127997;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👇🏼',
    name: 'backhand index pointing down: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F447 1F3FC',
    html: '&#128071;&#127996;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👇🏻',
    name: 'backhand index pointing down: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F447 1F3FB',
    html: '&#128071;&#127995;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👇',
    name: 'backhand index pointing down',
    shortname: ':point_down:',
    unicode: '1f447',
    html: '&#128071;',
    category: 'People & Body (hand-single-finger)',
    order: '1122',
  },
  {
    emoji: '🔴',
    name: 'red circle',
    shortname: ':red_circle:',
    unicode: '1f534',
    html: '&#128308;',
    category: 'Symbols (geometric)',
    order: '2179',
  },
  {
    emoji: '🙅🏿‍♂',
    name: 'man gesturing NO: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F645 1F3FF 200D 2642',
    html: '&#128581;&#127999;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏾‍♂',
    name: 'man gesturing NO: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F645 1F3FE 200D 2642',
    html: '&#128581;&#127998;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏽‍♂',
    name: 'man gesturing NO: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F645 1F3FD 200D 2642',
    html: '&#128581;&#127997;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏼‍♂',
    name: 'man gesturing NO: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F645 1F3FC 200D 2642',
    html: '&#128581;&#127996;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏻‍♂',
    name: 'man gesturing NO: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F645 1F3FB 200D 2642',
    html: '&#128581;&#127995;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅‍♂',
    name: 'man gesturing NO',
    shortname: ':man_gesturing_NO:',
    unicode: '1F645 200D 2642',
    html: '&#128581;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏿',
    name: 'person gesturing NO: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F645 1F3FF',
    html: '&#128581;&#127999;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏾',
    name: 'person gesturing NO: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F645 1F3FE',
    html: '&#128581;&#127998;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏽',
    name: 'person gesturing NO: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F645 1F3FD',
    html: '&#128581;&#127997;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏼',
    name: 'person gesturing NO: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F645 1F3FC',
    html: '&#128581;&#127996;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏻',
    name: 'person gesturing NO: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F645 1F3FB',
    html: '&#128581;&#127995;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅',
    name: 'person gesturing NO',
    shortname: ':no_good:',
    unicode: '1f645',
    html: '&#128581;',
    category: 'People & Body (person-gesture)',
    order: '531',
  },
  {
    emoji: '💥',
    name: 'collision',
    shortname: ':boom:',
    unicode: '1f4a5',
    html: '&#128165;',
    category: 'Smileys & Emotion (emotion)',
    order: '1305',
  },
  {
    emoji: '©️',
    name: 'copyright',
    shortname: ':copyright:',
    unicode: '00A9 FE0F',
    html: '&copy;️',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '💭',
    name: 'thought balloon',
    shortname: ':thought_balloon:',
    unicode: '1f4ad',
    html: '&#128173;',
    category: 'Smileys & Emotion (emotion)',
    order: '1312',
  },
  {
    emoji: '👅',
    name: 'tongue',
    shortname: ':tongue:',
    unicode: '1f445',
    html: '&#128069;',
    category: 'People & Body (body-parts)',
    order: '1282',
  },
  {
    emoji: '💩',
    name: 'pile of poo',
    shortname: ':poop:',
    unicode: '1f4a9',
    html: '&#128169;',
    category: 'Smileys & Emotion (face-costume)',
    order: '85',
  },
  {
    emoji: '😰',
    name: 'anxious face with sweat',
    shortname: ':cold_sweat:',
    unicode: '1f630',
    html: '&#128560;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '61',
  },
  {
    emoji: '💎',
    name: 'gem stone',
    shortname: ':gem:',
    unicode: '1f48e',
    html: '&#128142;',
    category: 'Objects (clothing)',
    order: '1341',
  },
  {
    emoji: '🙆🏿‍♂',
    name: 'man gesturing OK: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F646 1F3FF 200D 2642',
    html: '&#128582;&#127999;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏾‍♂',
    name: 'man gesturing OK: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F646 1F3FE 200D 2642',
    html: '&#128582;&#127998;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏽‍♂',
    name: 'man gesturing OK: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F646 1F3FD 200D 2642',
    html: '&#128582;&#127997;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏼‍♂',
    name: 'man gesturing OK: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F646 1F3FC 200D 2642',
    html: '&#128582;&#127996;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏻‍♂',
    name: 'man gesturing OK: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F646 1F3FB 200D 2642',
    html: '&#128582;&#127995;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆‍♂',
    name: 'man gesturing OK',
    shortname: ':man_gesturing_OK:',
    unicode: '1F646 200D 2642',
    html: '&#128582;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏿',
    name: 'person gesturing OK: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F646 1F3FF',
    html: '&#128582;&#127999;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏾',
    name: 'person gesturing OK: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F646 1F3FE',
    html: '&#128582;&#127998;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏽',
    name: 'person gesturing OK: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F646 1F3FD',
    html: '&#128582;&#127997;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏼',
    name: 'person gesturing OK: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F646 1F3FC',
    html: '&#128582;&#127996;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏻',
    name: 'person gesturing OK: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F646 1F3FB',
    html: '&#128582;&#127995;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆',
    name: 'person gesturing OK',
    shortname: ':ok_woman:',
    unicode: '1f646',
    html: '&#128582;',
    category: 'People & Body (person-gesture)',
    order: '549',
  },
  {
    emoji: '🍕',
    name: 'pizza',
    shortname: ':pizza:',
    unicode: '1f355',
    html: '&#127829;',
    category: 'Food & Drink (food-prepared)',
    order: '1484',
  },
  {
    emoji: '😹',
    name: 'cat with tears of joy',
    shortname: ':joy_cat:',
    unicode: '1f639',
    html: '&#128569;',
    category: 'Smileys & Emotion (cat-face)',
    order: '89',
  },
  {
    emoji: '🌞',
    name: 'sun with face',
    shortname: ':sun_with_face:',
    unicode: '1f31e',
    html: '&#127774;',
    category: 'Travel & Places (sky & weather)',
    order: '1726',
  },
  {
    emoji: '🍃',
    name: 'leaf fluttering in wind',
    shortname: ':leaves:',
    unicode: '1f343',
    html: '&#127811;',
    category: 'Animals & Nature (plant-other)',
    order: '1448',
  },
  {
    emoji: '💦',
    name: 'sweat droplets',
    shortname: ':sweat_drops:',
    unicode: '1f4a6',
    html: '&#128166;',
    category: 'Smileys & Emotion (emotion)',
    order: '1306',
  },
  {
    emoji: '🐧',
    name: 'penguin',
    shortname: ':penguin:',
    unicode: '1f427',
    html: '&#128039;',
    category: 'Animals & Nature (animal-bird)',
    order: '1394',
  },
  {
    emoji: '💤',
    name: 'zzz',
    shortname: ':zzz:',
    unicode: '1f4a4',
    html: '&#128164;',
    category: 'Smileys & Emotion (emotion)',
    order: '1302',
  },
  {
    emoji: '🚶🏿‍♀',
    name: 'woman walking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B6 1F3FF 200D 2640',
    html: '&#128694;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏾‍♀',
    name: 'woman walking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B6 1F3FE 200D 2640',
    html: '&#128694;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏽‍♀',
    name: 'woman walking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B6 1F3FD 200D 2640',
    html: '&#128694;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏼‍♀',
    name: 'woman walking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B6 1F3FC 200D 2640',
    html: '&#128694;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏻‍♀',
    name: 'woman walking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B6 1F3FB 200D 2640',
    html: '&#128694;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶‍♀',
    name: 'woman walking',
    shortname: ':woman_walking:',
    unicode: '1F6B6 200D 2640',
    html: '&#128694;&zwj;&#9792;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏿',
    name: 'person walking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B6 1F3FF',
    html: '&#128694;&#127999;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏾',
    name: 'person walking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B6 1F3FE',
    html: '&#128694;&#127998;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏽',
    name: 'person walking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B6 1F3FD',
    html: '&#128694;&#127997;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏼',
    name: 'person walking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B6 1F3FC',
    html: '&#128694;&#127996;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏻',
    name: 'person walking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B6 1F3FB',
    html: '&#128694;&#127995;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶',
    name: 'person walking',
    shortname: ':walking:',
    unicode: '1f6b6',
    html: '&#128694;',
    category: 'People & Body (person-activity)',
    order: '693',
  },
  {
    emoji: '✈️',
    name: 'airplane',
    shortname: ':airplane:',
    unicode: '2708',
    html: '&#9992;',
    category: 'Travel & Places (transport-air)',
    order: '1650',
  },
  {
    emoji: '🎈',
    name: 'balloon',
    shortname: ':balloon:',
    unicode: '1f388',
    html: '&#127880;',
    category: 'Activities (event)',
    order: '1761',
  },
  {
    emoji: '⭐',
    name: 'star',
    shortname: ':star:',
    unicode: '2b50',
    html: '&#11088;',
    category: 'Travel & Places (sky & weather)',
    order: '1727',
  },
  {
    emoji: '🎀',
    name: 'ribbon',
    shortname: ':ribbon:',
    unicode: '1f380',
    html: '&#127872;',
    category: 'Activities (event)',
    order: '1770',
  },
  {
    emoji: '☑️',
    name: 'check box with check',
    shortname: ':ballot_box_with_check:',
    unicode: '2611',
    html: '&#9745;',
    category: 'Symbols (other-symbol)',
    order: '2079',
  },
  {
    emoji: '😟',
    name: 'worried face',
    shortname: ':worried:',
    unicode: '1f61f',
    html: '&#128543;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '52',
  },
  {
    emoji: '🔞',
    name: 'no one under eighteen',
    shortname: ':underage:',
    unicode: '1f51e',
    html: '&#128286;',
    category: 'Symbols (warning)',
    order: '1999',
  },
  {
    emoji: '😨',
    name: 'fearful face',
    shortname: ':fearful:',
    unicode: '1f628',
    html: '&#128552;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '58',
  },
  {
    emoji: '🍀',
    name: 'four leaf clover',
    shortname: ':four_leaf_clover:',
    unicode: '1f340',
    html: '&#127808;',
    category: 'Animals & Nature (plant-other)',
    order: '1445',
  },
  {
    emoji: '🌺',
    name: 'hibiscus',
    shortname: ':hibiscus:',
    unicode: '1f33a',
    html: '&#127802;',
    category: 'Animals & Nature (plant-flower)',
    order: '1433',
  },
  {
    emoji: '🎤',
    name: 'microphone',
    shortname: ':microphone:',
    unicode: '1f3a4',
    html: '&#127908;',
    category: 'Objects (music)',
    order: '1829',
  },
  {
    emoji: '👐🏿',
    name: 'open hands: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F450 1F3FF',
    html: '&#128080;&#127999;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '👐🏾',
    name: 'open hands: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F450 1F3FE',
    html: '&#128080;&#127998;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '👐🏽',
    name: 'open hands: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F450 1F3FD',
    html: '&#128080;&#127997;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '👐🏼',
    name: 'open hands: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F450 1F3FC',
    html: '&#128080;&#127996;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '👐🏻',
    name: 'open hands: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F450 1F3FB',
    html: '&#128080;&#127995;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '👐',
    name: 'open hands',
    shortname: ':open_hands:',
    unicode: '1f450',
    html: '&#128080;',
    category: 'People & Body (hands)',
    order: '1236',
  },
  {
    emoji: '👻',
    name: 'ghost',
    shortname: ':ghost:',
    unicode: '1f47b',
    html: '&#128123;',
    category: 'Smileys & Emotion (face-costume)',
    order: '82',
  },
  {
    emoji: '🌴',
    name: 'palm tree',
    shortname: ':palm_tree:',
    unicode: '1f334',
    html: '&#127796;',
    category: 'Animals & Nature (plant-other)',
    order: '1440',
  },
  {
    emoji: '‼️',
    name: 'double exclamation mark',
    shortname: ':bangbang:',
    unicode: '203c',
    html: '&#8252;',
    category: 'Symbols (other-symbol)',
    order: '2096',
  },
  {
    emoji: '💅🏿',
    name: 'nail polish: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F485 1F3FF',
    html: '&#128133;&#127999;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '💅🏾',
    name: 'nail polish: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F485 1F3FE',
    html: '&#128133;&#127998;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '💅🏽',
    name: 'nail polish: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F485 1F3FD',
    html: '&#128133;&#127997;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '💅🏼',
    name: 'nail polish: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F485 1F3FC',
    html: '&#128133;&#127996;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '💅🏻',
    name: 'nail polish: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F485 1F3FB',
    html: '&#128133;&#127995;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '💅',
    name: 'nail polish',
    shortname: ':nail_care:',
    unicode: '1f485',
    html: '&#128133;',
    category: 'People & Body (hand-prop)',
    order: '1260',
  },
  {
    emoji: '❌',
    name: 'cross mark',
    shortname: ':x:',
    unicode: '274c',
    html: '&#10060;',
    category: 'Symbols (other-symbol)',
    order: '2082',
  },
  {
    emoji: '👽',
    name: 'alien',
    shortname: ':alien:',
    unicode: '1f47d',
    html: '&#128125;',
    category: 'Smileys & Emotion (face-costume)',
    order: '83',
  },
  {
    emoji: '🙇🏿‍♀',
    name: 'woman bowing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F647 1F3FF 200D 2640',
    html: '&#128583;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏾‍♀',
    name: 'woman bowing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F647 1F3FE 200D 2640',
    html: '&#128583;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏽‍♀',
    name: 'woman bowing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F647 1F3FD 200D 2640',
    html: '&#128583;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏼‍♀',
    name: 'woman bowing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F647 1F3FC 200D 2640',
    html: '&#128583;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏻‍♀',
    name: 'woman bowing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F647 1F3FB 200D 2640',
    html: '&#128583;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇‍♀',
    name: 'woman bowing',
    shortname: ':woman_bowing:',
    unicode: '1F647 200D 2640',
    html: '&#128583;&zwj;&#9792;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏿',
    name: 'person bowing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F647 1F3FF',
    html: '&#128583;&#127999;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏾',
    name: 'person bowing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F647 1F3FE',
    html: '&#128583;&#127998;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏽',
    name: 'person bowing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F647 1F3FD',
    html: '&#128583;&#127997;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏼',
    name: 'person bowing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F647 1F3FC',
    html: '&#128583;&#127996;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏻',
    name: 'person bowing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F647 1F3FB',
    html: '&#128583;&#127995;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇',
    name: 'person bowing',
    shortname: ':bow:',
    unicode: '1f647',
    html: '&#128583;',
    category: 'People & Body (person-gesture)',
    order: '603',
  },
  {
    emoji: '☁',
    name: 'cloud',
    shortname: ':cloud:',
    unicode: '2601',
    html: '&#9729;',
    category: 'Travel & Places (sky & weather)',
    order: '1730',
  },
  {
    emoji: '⚽',
    name: 'soccer ball',
    shortname: ':soccer:',
    unicode: '26bd',
    html: '&#9917;',
    category: 'Activities (sport)',
    order: '1781',
  },
  {
    emoji: '👼🏿',
    name: 'baby angel: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F47C 1F3FF',
    html: '&#128124;&#127999;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '👼🏾',
    name: 'baby angel: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F47C 1F3FE',
    html: '&#128124;&#127998;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '👼🏽',
    name: 'baby angel: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F47C 1F3FD',
    html: '&#128124;&#127997;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '👼🏼',
    name: 'baby angel: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F47C 1F3FC',
    html: '&#128124;&#127996;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '👼🏻',
    name: 'baby angel: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F47C 1F3FB',
    html: '&#128124;&#127995;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '👼',
    name: 'baby angel',
    shortname: ':angel:',
    unicode: '1f47c',
    html: '&#128124;',
    category: 'People & Body (person-fantasy)',
    order: '141',
  },
  {
    emoji: '👯‍♂',
    name: 'men with bunny ears',
    shortname: ':men_with_bunny_ears:',
    unicode: '1F46F 200D 2642',
    html: '&#128111;&zwj;&#9794;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👯',
    name: 'people with bunny ears',
    shortname: ':dancers:',
    unicode: '1f46f',
    html: '&#128111;',
    category: 'People & Body (person-activity)',
    order: '741',
  },
  {
    emoji: '❗',
    name: 'exclamation mark',
    shortname: ':exclamation:',
    unicode: '2757',
    html: '&#10071;',
    category: 'Symbols (other-symbol)',
    order: '2101',
  },
  {
    emoji: '❄️',
    name: 'snowflake',
    shortname: ':snowflake:',
    unicode: '2744',
    html: '&#10052;',
    category: 'Travel & Places (sky & weather)',
    order: '1749',
  },
  {
    emoji: '☝🏿',
    name: 'index pointing up: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '261D 1F3FF',
    html: '&#9757;&#127999;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '☝🏾',
    name: 'index pointing up: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '261D 1F3FE',
    html: '&#9757;&#127998;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '☝🏽',
    name: 'index pointing up: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '261D 1F3FD',
    html: '&#9757;&#127997;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '☝🏼',
    name: 'index pointing up: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '261D 1F3FC',
    html: '&#9757;&#127996;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '☝🏻',
    name: 'index pointing up: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '261D 1F3FB',
    html: '&#9757;&#127995;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '☝️',
    name: 'index pointing up',
    shortname: ':point_up:',
    unicode: '261d',
    html: '&#9757;',
    category: 'People & Body (hand-single-finger)',
    order: '1104',
  },
  {
    emoji: '😙',
    name: 'kissing face with smiling eyes',
    shortname: ':kissing_smiling_eyes:',
    unicode: '1f619',
    html: '&#128537;',
    category: 'Smileys & Emotion (face-affection)',
    order: '16',
  },
  {
    emoji: '🌈',
    name: 'rainbow',
    shortname: ':rainbow:',
    unicode: '1f308',
    html: '&#127752;',
    category: 'Travel & Places (sky & weather)',
    order: '1743',
  },
  {
    emoji: '🌙',
    name: 'crescent moon',
    shortname: ':crescent_moon:',
    unicode: '1f319',
    html: '&#127769;',
    category: 'Travel & Places (sky & weather)',
    order: '1719',
  },
  {
    emoji: '💟',
    name: 'heart decoration',
    shortname: ':heart_decoration:',
    unicode: '1f49f',
    html: '&#128159;',
    category: 'Smileys & Emotion (emotion)',
    order: '1299',
  },
  {
    emoji: '💝',
    name: 'heart with ribbon',
    shortname: ':gift_heart:',
    unicode: '1f49d',
    html: '&#128157;',
    category: 'Smileys & Emotion (emotion)',
    order: '1297',
  },
  {
    emoji: '🎁',
    name: 'wrapped gift',
    shortname: ':gift:',
    unicode: '1f381',
    html: '&#127873;',
    category: 'Activities (event)',
    order: '1771',
  },
  {
    emoji: '🍻',
    name: 'clinking beer mugs',
    shortname: ':beers:',
    unicode: '1f37b',
    html: '&#127867;',
    category: 'Food & Drink (drink)',
    order: '1530',
  },
  {
    emoji: '😧',
    name: 'anguished face',
    shortname: ':anguished:',
    unicode: '1f627',
    html: '&#128551;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '57',
  },
  {
    emoji: '🌍',
    name: 'globe showing Europe-Africa',
    shortname: ':earth_africa:',
    unicode: '1f30d',
    html: '&#127757;',
    category: 'Travel & Places (place-map)',
    order: '1538',
  },
  {
    emoji: '🎥',
    name: 'movie camera',
    shortname: ':movie_camera:',
    unicode: '1f3a5',
    html: '&#127909;',
    category: 'Objects (light & video)',
    order: '1856',
  },
  {
    emoji: '⚓',
    name: 'anchor',
    shortname: ':anchor:',
    unicode: '2693',
    html: '&#9875;',
    category: 'Travel & Places (transport-water)',
    order: '1642',
  },
  {
    emoji: '⚡',
    name: 'high voltage',
    shortname: ':zap:',
    unicode: '26a1',
    html: '&#9889;',
    category: 'Travel & Places (sky & weather)',
    order: '1748',
  },
  {
    emoji: '♣️',
    name: 'club suit',
    shortname: ':club_suit:',
    unicode: '2663 FE0F',
    html: '&clubs;️',
    category: 'Activities (game)',
    order: '',
  },
  {
    emoji: '✖️',
    name: 'multiplication sign',
    shortname: ':heavy_multiplication_x:',
    unicode: '2716',
    html: '&#10006;',
    category: 'Symbols (other-symbol)',
    order: '2081',
  },
  {
    emoji: '🏃🏿‍♀',
    name: 'woman running: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3C3 1F3FF 200D 2640',
    html: '&#127939;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏾‍♀',
    name: 'woman running: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3C3 1F3FE 200D 2640',
    html: '&#127939;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏽‍♀',
    name: 'woman running: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3C3 1F3FD 200D 2640',
    html: '&#127939;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏼‍♀',
    name: 'woman running: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3C3 1F3FC 200D 2640',
    html: '&#127939;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏻‍♀',
    name: 'woman running: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3C3 1F3FB 200D 2640',
    html: '&#127939;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃‍♀',
    name: 'woman running',
    shortname: ':woman_running:',
    unicode: '1F3C3 200D 2640',
    html: '&#127939;&zwj;&#9792;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏿',
    name: 'person running: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3C3 1F3FF',
    html: '&#127939;&#127999;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏾',
    name: 'person running: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3C3 1F3FE',
    html: '&#127939;&#127998;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏽',
    name: 'person running: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3C3 1F3FD',
    html: '&#127939;&#127997;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏼',
    name: 'person running: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3C3 1F3FC',
    html: '&#127939;&#127996;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏻',
    name: 'person running: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3C3 1F3FB',
    html: '&#127939;&#127995;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃',
    name: 'person running',
    shortname: ':runner:',
    unicode: '1f3c3',
    html: '&#127939;',
    category: 'People & Body (person-activity)',
    order: '711',
  },
  {
    emoji: '🌻',
    name: 'sunflower',
    shortname: ':sunflower:',
    unicode: '1f33b',
    html: '&#127803;',
    category: 'Animals & Nature (plant-flower)',
    order: '1434',
  },
  {
    emoji: '🌎',
    name: 'globe showing Americas',
    shortname: ':earth_americas:',
    unicode: '1f30e',
    html: '&#127758;',
    category: 'Travel & Places (place-map)',
    order: '1539',
  },
  {
    emoji: '💐',
    name: 'bouquet',
    shortname: ':bouquet:',
    unicode: '1f490',
    html: '&#128144;',
    category: 'Animals & Nature (plant-flower)',
    order: '1427',
  },
  {
    emoji: '🐶',
    name: 'dog face',
    shortname: ':dog:',
    unicode: '1f436',
    html: '&#128054;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1345',
  },
  {
    emoji: '💰',
    name: 'money bag',
    shortname: ':moneybag:',
    unicode: '1f4b0',
    html: '&#128176;',
    category: 'Objects (money)',
    order: '1891',
  },
  {
    emoji: '🌿',
    name: 'herb',
    shortname: ':herb:',
    unicode: '1f33f',
    html: '&#127807;',
    category: 'Animals & Nature (plant-other)',
    order: '1443',
  },
  {
    emoji: '👫',
    name: 'woman and man holding hands',
    shortname: ':couple:',
    unicode: '1f46b',
    html: '&#128107;',
    category: 'People & Body (family)',
    order: '1018',
  },
  {
    emoji: '🍂',
    name: 'fallen leaf',
    shortname: ':fallen_leaf:',
    unicode: '1f342',
    html: '&#127810;',
    category: 'Animals & Nature (plant-other)',
    order: '1447',
  },
  {
    emoji: '🌷',
    name: 'tulip',
    shortname: ':tulip:',
    unicode: '1f337',
    html: '&#127799;',
    category: 'Animals & Nature (plant-flower)',
    order: '1436',
  },
  {
    emoji: '🎂',
    name: 'birthday cake',
    shortname: ':birthday:',
    unicode: '1f382',
    html: '&#127874;',
    category: 'Food & Drink (food-sweet)',
    order: '1513',
  },
  {
    emoji: '🐱',
    name: 'cat face',
    shortname: ':cat:',
    unicode: '1f431',
    html: '&#128049;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1350',
  },
  {
    emoji: '☕',
    name: 'hot beverage',
    shortname: ':coffee:',
    unicode: '2615',
    html: '&#9749;',
    category: 'Food & Drink (drink)',
    order: '1522',
  },
  {
    emoji: '😵',
    name: 'dizzy face',
    shortname: ':dizzy_face:',
    unicode: '1f635',
    html: '&#128565;',
    category: 'Smileys & Emotion (face-unwell)',
    order: '64',
  },
  {
    emoji: '👆🏿',
    name: 'backhand index pointing up: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F446 1F3FF',
    html: '&#128070;&#127999;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👆🏾',
    name: 'backhand index pointing up: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F446 1F3FE',
    html: '&#128070;&#127998;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👆🏽',
    name: 'backhand index pointing up: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F446 1F3FD',
    html: '&#128070;&#127997;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👆🏼',
    name: 'backhand index pointing up: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F446 1F3FC',
    html: '&#128070;&#127996;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👆🏻',
    name: 'backhand index pointing up: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F446 1F3FB',
    html: '&#128070;&#127995;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '👆',
    name: 'backhand index pointing up',
    shortname: ':point_up_2:',
    unicode: '1f446',
    html: '&#128070;',
    category: 'People & Body (hand-single-finger)',
    order: '1110',
  },
  {
    emoji: '😮',
    name: 'face with open mouth',
    shortname: ':open_mouth:',
    unicode: '1f62e',
    html: '&#128558;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '29',
  },
  {
    emoji: '😯',
    name: 'hushed face',
    shortname: ':hushed:',
    unicode: '1f62f',
    html: '&#128559;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '31',
  },
  {
    emoji: '🏀',
    name: 'basketball',
    shortname: ':basketball:',
    unicode: '1f3c0',
    html: '&#127936;',
    category: 'Activities (sport)',
    order: '1783',
  },
  {
    emoji: '🎄',
    name: 'Christmas tree',
    shortname: ':christmas_tree:',
    unicode: '1f384',
    html: '&#127876;',
    category: 'Activities (event)',
    order: '1757',
  },
  {
    emoji: '💍',
    name: 'ring',
    shortname: ':ring:',
    unicode: '1f48d',
    html: '&#128141;',
    category: 'Objects (clothing)',
    order: '1340',
  },
  {
    emoji: '🌝',
    name: 'full moon face',
    shortname: ':full_moon_with_face:',
    unicode: '1f31d',
    html: '&#127773;',
    category: 'Travel & Places (sky & weather)',
    order: '1725',
  },
  {
    emoji: '😲',
    name: 'astonished face',
    shortname: ':astonished:',
    unicode: '1f632',
    html: '&#128562;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '47',
  },
  {
    emoji: '👭',
    name: 'women holding hands',
    shortname: ':two_women_holding_hands:',
    unicode: '1f46d',
    html: '&#128109;',
    category: 'People & Body (family)',
    order: '1030',
  },
  {
    emoji: '💸',
    name: 'money with wings',
    shortname: ':money_with_wings:',
    unicode: '1f4b8',
    html: '&#128184;',
    category: 'Objects (money)',
    order: '1896',
  },
  {
    emoji: '😿',
    name: 'crying cat',
    shortname: ':crying_cat_face:',
    unicode: '1f63f',
    html: '&#128575;',
    category: 'Smileys & Emotion (cat-face)',
    order: '94',
  },
  {
    emoji: '🙉',
    name: 'hear-no-evil monkey',
    shortname: ':hear_no_evil:',
    unicode: '1f649',
    html: '&#128585;',
    category: 'Smileys & Emotion (monkey-face)',
    order: '97',
  },
  {
    emoji: '💨',
    name: 'dashing away',
    shortname: ':dash:',
    unicode: '1f4a8',
    html: '&#128168;',
    category: 'Smileys & Emotion (emotion)',
    order: '1307',
  },
  {
    emoji: '🌵',
    name: 'cactus',
    shortname: ':cactus:',
    unicode: '1f335',
    html: '&#127797;',
    category: 'Animals & Nature (plant-other)',
    order: '1441',
  },
  {
    emoji: '♨️',
    name: 'hot springs',
    shortname: ':hotsprings:',
    unicode: '2668',
    html: '&#9832;',
    category: 'Travel & Places (place-other)',
    order: '1591',
  },
  {
    emoji: '☎️',
    name: 'telephone',
    shortname: ':telephone:',
    unicode: '260e',
    html: '&#9742;',
    category: 'Objects (phone)',
    order: '1840',
  },
  {
    emoji: '🍁',
    name: 'maple leaf',
    shortname: ':maple_leaf:',
    unicode: '1f341',
    html: '&#127809;',
    category: 'Animals & Nature (plant-other)',
    order: '1446',
  },
  {
    emoji: '👸🏿',
    name: 'princess: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F478 1F3FF',
    html: '&#128120;&#127999;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👸🏾',
    name: 'princess: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F478 1F3FE',
    html: '&#128120;&#127998;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👸🏽',
    name: 'princess: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F478 1F3FD',
    html: '&#128120;&#127997;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👸🏼',
    name: 'princess: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F478 1F3FC',
    html: '&#128120;&#127996;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👸🏻',
    name: 'princess: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F478 1F3FB',
    html: '&#128120;&#127995;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👸',
    name: 'princess',
    shortname: ':princess:',
    unicode: '1f478',
    html: '&#128120;',
    category: 'People & Body (person-role)',
    order: '459',
  },
  {
    emoji: '💆🏿‍♂',
    name: 'man getting massage: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F486 1F3FF 200D 2642',
    html: '&#128134;&#127999;&zwj;&#9794;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏾‍♂',
    name: 'man getting massage: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F486 1F3FE 200D 2642',
    html: '&#128134;&#127998;&zwj;&#9794;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏽‍♂',
    name: 'man getting massage: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F486 1F3FD 200D 2642',
    html: '&#128134;&#127997;&zwj;&#9794;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏼‍♂',
    name: 'man getting massage: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F486 1F3FC 200D 2642',
    html: '&#128134;&#127996;&zwj;&#9794;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏻‍♂',
    name: 'man getting massage: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F486 1F3FB 200D 2642',
    html: '&#128134;&#127995;&zwj;&#9794;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆‍♂',
    name: 'man getting massage',
    shortname: ':man_getting_massage:',
    unicode: '1F486 200D 2642',
    html: '&#128134;&zwj;&#9794;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏿',
    name: 'person getting massage: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F486 1F3FF',
    html: '&#128134;&#127999;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏾',
    name: 'person getting massage: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F486 1F3FE',
    html: '&#128134;&#127998;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏽',
    name: 'person getting massage: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F486 1F3FD',
    html: '&#128134;&#127997;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏼',
    name: 'person getting massage: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F486 1F3FC',
    html: '&#128134;&#127996;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏻',
    name: 'person getting massage: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F486 1F3FB',
    html: '&#128134;&#127995;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆',
    name: 'person getting massage',
    shortname: ':massage:',
    unicode: '1f486',
    html: '&#128134;',
    category: 'People & Body (person-activity)',
    order: '657',
  },
  {
    emoji: '💌',
    name: 'love letter',
    shortname: ':love_letter:',
    unicode: '1f48c',
    html: '&#128140;',
    category: 'Smileys & Emotion (emotion)',
    order: '1301',
  },
  {
    emoji: '🏆',
    name: 'trophy',
    shortname: ':trophy:',
    unicode: '1f3c6',
    html: '&#127942;',
    category: 'Activities (award-medal)',
    order: '1776',
  },
  {
    emoji: '🙍🏿‍♂',
    name: 'man frowning: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64D 1F3FF 200D 2642',
    html: '&#128589;&#127999;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏾‍♂',
    name: 'man frowning: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64D 1F3FE 200D 2642',
    html: '&#128589;&#127998;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏽‍♂',
    name: 'man frowning: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64D 1F3FD 200D 2642',
    html: '&#128589;&#127997;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏼‍♂',
    name: 'man frowning: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64D 1F3FC 200D 2642',
    html: '&#128589;&#127996;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏻‍♂',
    name: 'man frowning: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64D 1F3FB 200D 2642',
    html: '&#128589;&#127995;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍‍♂',
    name: 'man frowning',
    shortname: ':man_frowning:',
    unicode: '1F64D 200D 2642',
    html: '&#128589;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏿',
    name: 'person frowning: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64D 1F3FF',
    html: '&#128589;&#127999;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏾',
    name: 'person frowning: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64D 1F3FE',
    html: '&#128589;&#127998;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏽',
    name: 'person frowning: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64D 1F3FD',
    html: '&#128589;&#127997;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏼',
    name: 'person frowning: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64D 1F3FC',
    html: '&#128589;&#127996;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏻',
    name: 'person frowning: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64D 1F3FB',
    html: '&#128589;&#127995;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍',
    name: 'person frowning',
    shortname: ':person_frowning:',
    unicode: '1f64d',
    html: '&#128589;',
    category: 'People & Body (person-gesture)',
    order: '495',
  },
  {
    emoji: '🇺🇸',
    name: 'flag: United States',
    shortname: ':us:',
    unicode: '1f1fa',
    html: '&#127482;',
    category: 'Flags (country-flag)',
    order: '',
  },
  {
    emoji: '🎊',
    name: 'confetti ball',
    shortname: ':confetti_ball:',
    unicode: '1f38a',
    html: '&#127882;',
    category: 'Activities (event)',
    order: '1763',
  },
  {
    emoji: '🌼',
    name: 'blossom',
    shortname: ':blossom:',
    unicode: '1f33c',
    html: '&#127804;',
    category: 'Animals & Nature (plant-flower)',
    order: '1435',
  },
  {
    emoji: '🔪',
    name: 'kitchen knife',
    shortname: ':kitchen_knife:',
    unicode: '1F52A',
    html: '&#128298;',
    category: 'Food & Drink (dishware)',
    order: '',
  },
  {
    emoji: '👄',
    name: 'mouth',
    shortname: ':lips:',
    unicode: '1f444',
    html: '&#128068;',
    category: 'People & Body (body-parts)',
    order: '1283',
  },
  {
    emoji: '🍟',
    name: 'french fries',
    shortname: ':fries:',
    unicode: '1f35f',
    html: '&#127839;',
    category: 'Food & Drink (food-prepared)',
    order: '1483',
  },
  {
    emoji: '🍩',
    name: 'doughnut',
    shortname: ':doughnut:',
    unicode: '1f369',
    html: '&#127849;',
    category: 'Food & Drink (food-sweet)',
    order: '1511',
  },
  {
    emoji: '😦',
    name: 'frowning face with open mouth',
    shortname: ':frowning:',
    unicode: '1f626',
    html: '&#128550;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '56',
  },
  {
    emoji: '🌊',
    name: 'water wave',
    shortname: ':ocean:',
    unicode: '1f30a',
    html: '&#127754;',
    category: 'Travel & Places (sky & weather)',
    order: '1755',
  },
  {
    emoji: '💣',
    name: 'bomb',
    shortname: ':bomb:',
    unicode: '1f4a3',
    html: '&#128163;',
    category: 'Smileys & Emotion (emotion)',
    order: '1304',
  },
  {
    emoji: '🆗',
    name: 'OK button',
    shortname: ':ok:',
    unicode: '1f197',
    html: '&#127383;',
    category: 'Symbols (alphanum)',
    order: '2137',
  },
  {
    emoji: '🌀',
    name: 'cyclone',
    shortname: ':cyclone:',
    unicode: '1f300',
    html: '&#127744;',
    category: 'Travel & Places (sky & weather)',
    order: '1742',
  },
  {
    emoji: '🚀',
    name: 'rocket',
    shortname: ':rocket:',
    unicode: '1f680',
    html: '&#128640;',
    category: 'Travel & Places (transport-air)',
    order: '1659',
  },
  {
    emoji: '☔',
    name: 'umbrella with rain drops',
    shortname: ':umbrella:',
    unicode: '2614',
    html: '&#9748;',
    category: 'Travel & Places (sky & weather)',
    order: '1746',
  },
  {
    emoji: '💏',
    name: 'kiss',
    shortname: ':couplekiss:',
    unicode: '1f48f',
    html: '&#128143;',
    category: 'People & Body (family)',
    order: '1036',
  },
  {
    emoji: '👩‍❤️‍💋‍👩',
    name: 'kiss: woman, woman',
    shortname: ':couple_woman_kiss:',
    unicode: '1F469 200D 2764 FE0F 200D 1F48B 200D 1F469',
    html: '&#128105;&zwj;&#10084;&zwj;&#128139;&zwj;&#128105;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍❤️‍💋‍👨',
    name: 'kiss: man, man',
    shortname: ':couple_man_kiss:',
    unicode: '1F468 200D 2764 FE0F 200D 1F48B 200D 1F468',
    html: '&#128104;&zwj;&#10084;&zwj;&#128139;&zwj;&#128104;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '💑',
    name: 'couple with heart',
    shortname: ':couple_with_heart:',
    unicode: '1f491',
    html: '&#128145;',
    category: 'People & Body (family)',
    order: '1040',
  },
  {
    emoji: '👩‍❤️‍👩',
    name: 'couple with heart: woman, woman',
    shortname: ':woman_woman:',
    unicode: '1F469 200D 2764 FE0F 200D 1F469',
    html: '&#128105;&zwj;&#10084;&zwj;&#128105;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍❤️‍👨',
    name: 'couple with heart: man, man',
    shortname: ':man_man_love:',
    unicode: '1F468 200D 2764 FE0F 200D 1F468',
    html: '&#128104;&zwj;&#10084;&zwj;&#128104;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🍭',
    name: 'lollipop',
    shortname: ':lollipop:',
    unicode: '1f36d',
    html: '&#127853;',
    category: 'Food & Drink (food-sweet)',
    order: '1517',
  },
  {
    emoji: '🎬',
    name: 'clapper board',
    shortname: ':clapper:',
    unicode: '1f3ac',
    html: '&#127916;',
    category: 'Objects (light & video)',
    order: '1859',
  },
  {
    emoji: '🐷',
    name: 'pig face',
    shortname: ':pig:',
    unicode: '1f437',
    html: '&#128055;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1364',
  },
  {
    emoji: '😈',
    name: 'smiling face with horns',
    shortname: ':smiling_imp:',
    unicode: '1f608',
    html: '&#128520;',
    category: 'Smileys & Emotion (face-negative)',
    order: '76',
  },
  {
    emoji: '👿',
    name: 'angry face with horns',
    shortname: ':imp:',
    unicode: '1f47f',
    html: '&#128127;',
    category: 'Smileys & Emotion (face-negative)',
    order: '77',
  },
  {
    emoji: '🐝',
    name: 'honeybee',
    shortname: ':bee:',
    unicode: '1f41d',
    html: '&#128029;',
    category: 'Animals & Nature (animal-bug)',
    order: '1422',
  },
  {
    emoji: '😽',
    name: 'kissing cat',
    shortname: ':kissing_cat:',
    unicode: '1f63d',
    html: '&#128573;',
    category: 'Smileys & Emotion (cat-face)',
    order: '92',
  },
  {
    emoji: '💢',
    name: 'anger symbol',
    shortname: ':anger:',
    unicode: '1f4a2',
    html: '&#128162;',
    category: 'Smileys & Emotion (emotion)',
    order: '1303',
  },
  {
    emoji: '🎼',
    name: 'musical score',
    shortname: ':musical_score:',
    unicode: '1f3bc',
    html: '&#127932;',
    category: 'Objects (music)',
    order: '1823',
  },
  {
    emoji: '🎅🏿',
    name: 'Santa Claus: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F385 1F3FF',
    html: '&#127877;&#127999;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🎅🏾',
    name: 'Santa Claus: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F385 1F3FE',
    html: '&#127877;&#127998;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🎅🏽',
    name: 'Santa Claus: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F385 1F3FD',
    html: '&#127877;&#127997;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🎅🏼',
    name: 'Santa Claus: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F385 1F3FC',
    html: '&#127877;&#127996;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🎅🏻',
    name: 'Santa Claus: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F385 1F3FB',
    html: '&#127877;&#127995;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🎅',
    name: 'Santa Claus',
    shortname: ':santa:',
    unicode: '1f385',
    html: '&#127877;',
    category: 'People & Body (person-fantasy)',
    order: '447',
  },
  {
    emoji: '🌏',
    name: 'globe showing Asia-Australia',
    shortname: ':earth_asia:',
    unicode: '1f30f',
    html: '&#127759;',
    category: 'Travel & Places (place-map)',
    order: '1540',
  },
  {
    emoji: '🏈',
    name: 'american football',
    shortname: ':football:',
    unicode: '1f3c8',
    html: '&#127944;',
    category: 'Activities (sport)',
    order: '1785',
  },
  {
    emoji: '🎸',
    name: 'guitar',
    shortname: ':guitar:',
    unicode: '1f3b8',
    html: '&#127928;',
    category: 'Objects (musical-instrument)',
    order: '1833',
  },
  {
    emoji: '♦️',
    name: 'diamond suit',
    shortname: ':diamond_suit:',
    unicode: '2666 FE0F',
    html: '&diams;️',
    category: 'Activities (game)',
    order: '',
  },
  {
    emoji: '🐼',
    name: 'panda',
    shortname: ':panda_face:',
    unicode: '1f43c',
    html: '&#128060;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1385',
  },
  {
    emoji: '💬',
    name: 'speech balloon',
    shortname: ':speech_balloon:',
    unicode: '1f4ac',
    html: '&#128172;',
    category: 'Smileys & Emotion (emotion)',
    order: '1309',
  },
  {
    emoji: '🍓',
    name: 'strawberry',
    shortname: ':strawberry:',
    unicode: '1f353',
    html: '&#127827;',
    category: 'Food & Drink (food-fruit)',
    order: '1461',
  },
  {
    emoji: '😼',
    name: 'cat with wry smile',
    shortname: ':smirk_cat:',
    unicode: '1f63c',
    html: '&#128572;',
    category: 'Smileys & Emotion (cat-face)',
    order: '91',
  },
  {
    emoji: '🍌',
    name: 'banana',
    shortname: ':banana:',
    unicode: '1f34c',
    html: '&#127820;',
    category: 'Food & Drink (food-fruit)',
    order: '1454',
  },
  {
    emoji: '🍉',
    name: 'watermelon',
    shortname: ':watermelon:',
    unicode: '1f349',
    html: '&#127817;',
    category: 'Food & Drink (food-fruit)',
    order: '1451',
  },
  {
    emoji: '⛄',
    name: 'snowman without snow',
    shortname: ':snowman:',
    unicode: '26c4',
    html: '&#9924;',
    category: 'Travel & Places (sky & weather)',
    order: '1751',
  },
  {
    emoji: '😸',
    name: 'grinning cat with smiling eyes',
    shortname: ':smile_cat:',
    unicode: '1f638',
    html: '&#128568;',
    category: 'Smileys & Emotion (cat-face)',
    order: '88',
  },
  {
    emoji: '♠️',
    name: 'spade suit',
    shortname: ':spade_suit:',
    unicode: '2660 FE0F',
    html: '&spades;️',
    category: 'Activities (game)',
    order: '',
  },
  {
    emoji: '🔝',
    name: 'TOP arrow',
    shortname: ':top:',
    unicode: '1f51d',
    html: '&#128285;',
    category: 'Symbols (arrow)',
    order: '2022',
  },
  {
    emoji: '🍆',
    name: 'eggplant',
    shortname: ':eggplant:',
    unicode: '1f346',
    html: '&#127814;',
    category: 'Food & Drink (food-vegetable)',
    order: '1465',
  },
  {
    emoji: '🔮',
    name: 'crystal ball',
    shortname: ':crystal_ball:',
    unicode: '1f52e',
    html: '&#128302;',
    category: 'Activities (game)',
    order: '1974',
  },
  {
    emoji: '🍴',
    name: 'fork and knife',
    shortname: ':fork_and_knife:',
    unicode: '1f374',
    html: '&#127860;',
    category: 'Food & Drink (dishware)',
    order: '1534',
  },
  {
    emoji: '📲',
    name: 'mobile phone with arrow',
    shortname: ':calling:',
    unicode: '1f4f2',
    html: '&#128242;',
    category: 'Objects (phone)',
    order: '1839',
  },
  {
    emoji: '📱',
    name: 'mobile phone',
    shortname: ':iphone:',
    unicode: '1f4f1',
    html: '&#128241;',
    category: 'Objects (phone)',
    order: '1838',
  },
  {
    emoji: '⛅',
    name: 'sun behind cloud',
    shortname: ':partly_sunny:',
    unicode: '26c5',
    html: '&#9925;',
    category: 'Travel & Places (sky & weather)',
    order: '1731',
  },
  {
    emoji: '⚠️',
    name: 'warning',
    shortname: ':warning:',
    unicode: '26a0',
    html: '&#9888;',
    category: 'Symbols (warning)',
    order: '1989',
  },
  {
    emoji: '🙀',
    name: 'weary cat',
    shortname: ':scream_cat:',
    unicode: '1f640',
    html: '&#128576;',
    category: 'Smileys & Emotion (cat-face)',
    order: '93',
  },
  {
    emoji: '🔸',
    name: 'small orange diamond',
    shortname: ':small_orange_diamond:',
    unicode: '1f538',
    html: '&#128312;',
    category: 'Symbols (geometric)',
    order: '2169',
  },
  {
    emoji: '👶🏿',
    name: 'baby: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F476 1F3FF',
    html: '&#128118;&#127999;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👶🏾',
    name: 'baby: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F476 1F3FE',
    html: '&#128118;&#127998;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👶🏽',
    name: 'baby: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F476 1F3FD',
    html: '&#128118;&#127997;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👶🏼',
    name: 'baby: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F476 1F3FC',
    html: '&#128118;&#127996;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👶🏻',
    name: 'baby: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F476 1F3FB',
    html: '&#128118;&#127995;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👶',
    name: 'baby',
    shortname: ':baby:',
    unicode: '1f476',
    html: '&#128118;',
    category: 'People & Body (person)',
    order: '135',
  },
  {
    emoji: '🐾',
    name: 'paw prints',
    shortname: ':feet:',
    unicode: '1f43e',
    html: '&#128062;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1386',
  },
  {
    emoji: '👣',
    name: 'footprints',
    shortname: ':footprints:',
    unicode: '1f463',
    html: '&#128099;',
    category: 'People & Body (person-symbol)',
    order: '1278',
  },
  {
    emoji: '🍺',
    name: 'beer mug',
    shortname: ':beer:',
    unicode: '1f37a',
    html: '&#127866;',
    category: 'Food & Drink (drink)',
    order: '1529',
  },
  {
    emoji: '🍷',
    name: 'wine glass',
    shortname: ':wine_glass:',
    unicode: '1f377',
    html: '&#127863;',
    category: 'Food & Drink (drink)',
    order: '1526',
  },
  {
    emoji: '⭕',
    name: 'hollow red circle',
    shortname: ':o:',
    unicode: '2b55',
    html: '&#11093;',
    category: 'Symbols (other-symbol)',
    order: '2077',
  },
  {
    emoji: '📹',
    name: 'video camera',
    shortname: ':video_camera:',
    unicode: '1f4f9',
    html: '&#128249;',
    category: 'Objects (light & video)',
    order: '1863',
  },
  {
    emoji: '🐰',
    name: 'rabbit face',
    shortname: ':rabbit:',
    unicode: '1f430',
    html: '&#128048;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1379',
  },
  {
    emoji: '🍹',
    name: 'tropical drink',
    shortname: ':tropical_drink:',
    unicode: '1f379',
    html: '&#127865;',
    category: 'Food & Drink (drink)',
    order: '1528',
  },
  {
    emoji: '🚬',
    name: 'cigarette',
    shortname: ':smoking:',
    unicode: '1f6ac',
    html: '&#128684;',
    category: 'Objects (other-object)',
    order: '1969',
  },
  {
    emoji: '👾',
    name: 'alien monster',
    shortname: ':space_invader:',
    unicode: '1f47e',
    html: '&#128126;',
    category: 'Smileys & Emotion (face-costume)',
    order: '84',
  },
  {
    emoji: '🍑',
    name: 'peach',
    shortname: ':peach:',
    unicode: '1f351',
    html: '&#127825;',
    category: 'Food & Drink (food-fruit)',
    order: '1459',
  },
  {
    emoji: '🐍',
    name: 'snake',
    shortname: ':snake:',
    unicode: '1f40d',
    html: '&#128013;',
    category: 'Animals & Nature (animal-reptile)',
    order: '1403',
  },
  {
    emoji: '🐢',
    name: 'turtle',
    shortname: ':turtle:',
    unicode: '1f422',
    html: '&#128034;',
    category: 'Animals & Nature (animal-reptile)',
    order: '1401',
  },
  {
    emoji: '🍒',
    name: 'cherries',
    shortname: ':cherries:',
    unicode: '1f352',
    html: '&#127826;',
    category: 'Food & Drink (food-fruit)',
    order: '1460',
  },
  {
    emoji: '😗',
    name: 'kissing face',
    shortname: ':kissing:',
    unicode: '1f617',
    html: '&#128535;',
    category: 'Smileys & Emotion (face-affection)',
    order: '15',
  },
  {
    emoji: '🐸',
    name: 'frog',
    shortname: ':frog:',
    unicode: '1f438',
    html: '&#128056;',
    category: 'Animals & Nature (animal-amphibian)',
    order: '1399',
  },
  {
    emoji: '🌌',
    name: 'milky way',
    shortname: ':milky_way:',
    unicode: '1f30c',
    html: '&#127756;',
    category: 'Travel & Places (sky & weather)',
    order: '1592',
  },
  {
    emoji: '🚨',
    name: 'police car light',
    shortname: ':rotating_light:',
    unicode: '1f6a8',
    html: '&#128680;',
    category: 'Travel & Places (transport-ground)',
    order: '1637',
  },
  {
    emoji: '🐣',
    name: 'hatching chick',
    shortname: ':hatching_chick:',
    unicode: '1f423',
    html: '&#128035;',
    category: 'Animals & Nature (animal-bird)',
    order: '1390',
  },
  {
    emoji: '📕',
    name: 'closed book',
    shortname: ':closed_book:',
    unicode: '1f4d5',
    html: '&#128213;',
    category: 'Objects (book-paper)',
    order: '1875',
  },
  {
    emoji: '🍬',
    name: 'candy',
    shortname: ':candy:',
    unicode: '1f36c',
    html: '&#127852;',
    category: 'Food & Drink (food-sweet)',
    order: '1516',
  },
  {
    emoji: '🍔',
    name: 'hamburger',
    shortname: ':hamburger:',
    unicode: '1f354',
    html: '&#127828;',
    category: 'Food & Drink (food-prepared)',
    order: '1482',
  },
  {
    emoji: '🐻',
    name: 'bear',
    shortname: ':bear:',
    unicode: '1f43b',
    html: '&#128059;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1383',
  },
  {
    emoji: '🐯',
    name: 'tiger face',
    shortname: ':tiger:',
    unicode: '1f42f',
    html: '&#128047;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1353',
  },
  {
    emoji: '🚗',
    name: 'automobile',
    shortname: ':automobile:',
    unicode: '1F697',
    html: '&#128663;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '⏩',
    name: 'fast-forward button',
    shortname: ':fast_forward:',
    unicode: '2.3E+10',
    html: '&#9193;',
    category: 'Symbols (av-symbol)',
    order: '2052',
  },
  {
    emoji: '🍦',
    name: 'soft ice cream',
    shortname: ':icecream:',
    unicode: '1f366',
    html: '&#127846;',
    category: 'Food & Drink (food-sweet)',
    order: '1508',
  },
  {
    emoji: '🍍',
    name: 'pineapple',
    shortname: ':pineapple:',
    unicode: '1f34d',
    html: '&#127821;',
    category: 'Food & Drink (food-fruit)',
    order: '1455',
  },
  {
    emoji: '🌾',
    name: 'sheaf of rice',
    shortname: ':ear_of_rice:',
    unicode: '1f33e',
    html: '&#127806;',
    category: 'Animals & Nature (plant-other)',
    order: '1442',
  },
  {
    emoji: '💉',
    name: 'syringe',
    shortname: ':syringe:',
    unicode: '1f489',
    html: '&#128137;',
    category: 'Objects (medical)',
    order: '1967',
  },
  {
    emoji: '🚮',
    name: 'litter in bin sign',
    shortname: ':put_litter_in_its_place:',
    unicode: '1f6ae',
    html: '&#128686;',
    category: 'Symbols (transport-sign)',
    order: '1977',
  },
  {
    emoji: '🍫',
    name: 'chocolate bar',
    shortname: ':chocolate_bar:',
    unicode: '1f36b',
    html: '&#127851;',
    category: 'Food & Drink (food-sweet)',
    order: '1515',
  },
  {
    emoji: '▪️',
    name: 'black small square',
    shortname: ':black_small_square:',
    unicode: '25aa',
    html: '&#9642;',
    category: 'Symbols (geometric)',
    order: '2159',
  },
  {
    emoji: '📺',
    name: 'television',
    shortname: ':tv:',
    unicode: '1f4fa',
    html: '&#128250;',
    category: 'Objects (light & video)',
    order: '1860',
  },
  {
    emoji: '💊',
    name: 'pill',
    shortname: ':pill:',
    unicode: '1f48a',
    html: '&#128138;',
    category: 'Objects (medical)',
    order: '1968',
  },
  {
    emoji: '🐙',
    name: 'octopus',
    shortname: ':octopus:',
    unicode: '1f419',
    html: '&#128025;',
    category: 'Animals & Nature (animal-marine)',
    order: '1413',
  },
  {
    emoji: '🎃',
    name: 'jack-o-lantern',
    shortname: ':jack_o_lantern:',
    unicode: '1f383',
    html: '&#127875;',
    category: 'Activities (event)',
    order: '1756',
  },
  {
    emoji: '🍇',
    name: 'grapes',
    shortname: ':grapes:',
    unicode: '1f347',
    html: '&#127815;',
    category: 'Food & Drink (food-fruit)',
    order: '1449',
  },
  {
    emoji: '😺',
    name: 'grinning cat',
    shortname: ':smiley_cat:',
    unicode: '1f63a',
    html: '&#128570;',
    category: 'Smileys & Emotion (cat-face)',
    order: '87',
  },
  {
    emoji: '💿',
    name: 'optical disk',
    shortname: ':cd:',
    unicode: '1f4bf',
    html: '&#128191;',
    category: 'Objects (computer)',
    order: '1854',
  },
  {
    emoji: '🍸',
    name: 'cocktail glass',
    shortname: ':cocktail:',
    unicode: '1f378',
    html: '&#127864;',
    category: 'Food & Drink (drink)',
    order: '1527',
  },
  {
    emoji: '🍰',
    name: 'shortcake',
    shortname: ':cake:',
    unicode: '1f370',
    html: '&#127856;',
    category: 'Food & Drink (food-sweet)',
    order: '1514',
  },
  {
    emoji: '🎮',
    name: 'video game',
    shortname: ':video_game:',
    unicode: '1f3ae',
    html: '&#127918;',
    category: 'Activities (game)',
    order: '1804',
  },
  {
    emoji: '™️',
    name: 'trade mark',
    shortname: ':trade_mark:',
    unicode: '2122 FE0F',
    html: '&trade;️',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '⬇️',
    name: 'down arrow',
    shortname: ':arrow_down:',
    unicode: '2b07',
    html: '&#11015;',
    category: 'Symbols (arrow)',
    order: '2006',
  },
  {
    emoji: '🚫',
    name: 'prohibited',
    shortname: ':no_entry_sign:',
    unicode: '1f6ab',
    html: '&#128683;',
    category: 'Symbols (warning)',
    order: '1992',
  },
  {
    emoji: '💄',
    name: 'lipstick',
    shortname: ':lipstick:',
    unicode: '1f484',
    html: '&#128132;',
    category: 'Objects (clothing)',
    order: '1339',
  },
  {
    emoji: '🐳',
    name: 'spouting whale',
    shortname: ':whale:',
    unicode: '1f433',
    html: '&#128051;',
    category: 'Animals & Nature (animal-marine)',
    order: '1406',
  },
  {
    emoji: '📝',
    name: 'memo',
    shortname: ':memo:',
    unicode: '1F4DD',
    html: '&#128221;',
    category: 'Objects (writing)',
    order: '',
  },
  {
    emoji: '®️',
    name: 'registered',
    shortname: ':registered:',
    unicode: '00AE FE0F',
    html: '&reg;️',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '🍪',
    name: 'cookie',
    shortname: ':cookie:',
    unicode: '1f36a',
    html: '&#127850;',
    category: 'Food & Drink (food-sweet)',
    order: '1512',
  },
  {
    emoji: '🐬',
    name: 'dolphin',
    shortname: ':dolphin:',
    unicode: '1f42c',
    html: '&#128044;',
    category: 'Animals & Nature (animal-marine)',
    order: '1408',
  },
  {
    emoji: '🔊',
    name: 'speaker high volume',
    shortname: ':loud_sound:',
    unicode: '1f50a',
    html: '&#128266;',
    category: 'Objects (sound)',
    order: '1817',
  },
  {
    emoji: '👨🏿',
    name: 'man: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF',
    html: '&#128104;&#127999;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏾',
    name: 'man: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE',
    html: '&#128104;&#127998;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏽',
    name: 'man: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD',
    html: '&#128104;&#127997;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏼',
    name: 'man: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC',
    html: '&#128104;&#127996;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏻',
    name: 'man: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB',
    html: '&#128104;&#127995;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨',
    name: 'man',
    shortname: ':man:',
    unicode: '1f468',
    html: '&#128104;',
    category: 'People & Body (person)',
    order: '111',
  },
  {
    emoji: '🐥',
    name: 'front-facing baby chick',
    shortname: ':hatched_chick:',
    unicode: '1f425',
    html: '&#128037;',
    category: 'Animals & Nature (animal-bird)',
    order: '1392',
  },
  {
    emoji: '🐒',
    name: 'monkey',
    shortname: ':monkey:',
    unicode: '1f412',
    html: '&#128018;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1343',
  },
  {
    emoji: '📚',
    name: 'books',
    shortname: ':books:',
    unicode: '1f4da',
    html: '&#128218;',
    category: 'Objects (book-paper)',
    order: '1880',
  },
  {
    emoji: '👹',
    name: 'ogre',
    shortname: ':japanese_ogre:',
    unicode: '1f479',
    html: '&#128121;',
    category: 'Smileys & Emotion (face-costume)',
    order: '78',
  },
  {
    emoji: '💂🏿‍♀',
    name: 'woman guard: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F482 1F3FF 200D 2640',
    html: '&#128130;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏾‍♀',
    name: 'woman guard: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F482 1F3FE 200D 2640',
    html: '&#128130;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏽‍♀',
    name: 'woman guard: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F482 1F3FD 200D 2640',
    html: '&#128130;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏼‍♀',
    name: 'woman guard: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F482 1F3FC 200D 2640',
    html: '&#128130;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏻‍♀',
    name: 'woman guard: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F482 1F3FB 200D 2640',
    html: '&#128130;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂‍♀',
    name: 'woman guard',
    shortname: ':woman_guard:',
    unicode: '1F482 200D 2640',
    html: '&#128130;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏿',
    name: 'guard: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F482 1F3FF',
    html: '&#128130;&#127999;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏾',
    name: 'guard: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F482 1F3FE',
    html: '&#128130;&#127998;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏽',
    name: 'guard: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F482 1F3FD',
    html: '&#128130;&#127997;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏼',
    name: 'guard: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F482 1F3FC',
    html: '&#128130;&#127996;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏻',
    name: 'guard: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F482 1F3FB',
    html: '&#128130;&#127995;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂',
    name: 'guard',
    shortname: ':guardsman:',
    unicode: '1f482',
    html: '&#128130;',
    category: 'People & Body (person-role)',
    order: '375',
  },
  {
    emoji: '📢',
    name: 'loudspeaker',
    shortname: ':loudspeaker:',
    unicode: '1f4e2',
    html: '&#128226;',
    category: 'Objects (sound)',
    order: '1818',
  },
  {
    emoji: '✂️',
    name: 'scissors',
    shortname: ':scissors:',
    unicode: '2702',
    html: '&#9986;',
    category: 'Objects (office)',
    order: '1940',
  },
  {
    emoji: '👧🏿',
    name: 'girl: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F467 1F3FF',
    html: '&#128103;&#127999;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👧🏾',
    name: 'girl: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F467 1F3FE',
    html: '&#128103;&#127998;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👧🏽',
    name: 'girl: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F467 1F3FD',
    html: '&#128103;&#127997;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👧🏼',
    name: 'girl: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F467 1F3FC',
    html: '&#128103;&#127996;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👧🏻',
    name: 'girl: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F467 1F3FB',
    html: '&#128103;&#127995;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👧',
    name: 'girl',
    shortname: ':girl:',
    unicode: '1f467',
    html: '&#128103;',
    category: 'People & Body (person)',
    order: '105',
  },
  {
    emoji: '🎓',
    name: 'graduation cap',
    shortname: ':mortar_board:',
    unicode: '1f393',
    html: '&#127891;',
    category: 'Objects (clothing)',
    order: '1336',
  },
  {
    emoji: '🇫🇷',
    name: 'flag: France',
    shortname: ':fr:',
    unicode: '1f1eb',
    html: '&#127467;',
    category: 'Flags (country-flag)',
    order: '',
  },
  {
    emoji: '⚾️',
    name: '',
    shortname: ':baseball:',
    unicode: '26be',
    html: '&#9918;',
    category: '',
    order: '1782',
  },
  {
    emoji: '🚦',
    name: 'vertical traffic light',
    shortname: ':vertical_traffic_light:',
    unicode: '1f6a6',
    html: '&#128678;',
    category: 'Travel & Places (transport-ground)',
    order: '1639',
  },
  {
    emoji: '👩🏿',
    name: 'woman: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF',
    html: '&#128105;&#127999;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏾',
    name: 'woman: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE',
    html: '&#128105;&#127998;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏽',
    name: 'woman: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD',
    html: '&#128105;&#127997;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏼',
    name: 'woman: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC',
    html: '&#128105;&#127996;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏻',
    name: 'woman: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB',
    html: '&#128105;&#127995;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩',
    name: 'woman',
    shortname: ':woman:',
    unicode: '1f469',
    html: '&#128105;',
    category: 'People & Body (person)',
    order: '117',
  },
  {
    emoji: '🎆',
    name: 'fireworks',
    shortname: ':fireworks:',
    unicode: '1f386',
    html: '&#127878;',
    category: 'Activities (event)',
    order: '1758',
  },
  {
    emoji: '🌠',
    name: 'shooting star',
    shortname: ':stars:',
    unicode: '1f320',
    html: '&#127776;',
    category: 'Travel & Places (sky & weather)',
    order: '1729',
  },
  {
    emoji: '🆘',
    name: 'SOS button',
    shortname: ':sos:',
    unicode: '1f198',
    html: '&#127384;',
    category: 'Symbols (alphanum)',
    order: '2139',
  },
  {
    emoji: '🍄',
    name: 'mushroom',
    shortname: ':mushroom:',
    unicode: '1f344',
    html: '&#127812;',
    category: 'Food & Drink (food-vegetable)',
    order: '1471',
  },
  {
    emoji: '😾',
    name: 'pouting cat',
    shortname: ':pouting_cat:',
    unicode: '1f63e',
    html: '&#128574;',
    category: 'Smileys & Emotion (cat-face)',
    order: '95',
  },
  {
    emoji: '🛅',
    name: 'left luggage',
    shortname: ':left_luggage:',
    unicode: '1f6c5',
    html: '&#128709;',
    category: 'Symbols (transport-sign)',
    order: '1988',
  },
  {
    emoji: '👠',
    name: 'high-heeled shoe',
    shortname: ':high_heel:',
    unicode: '1f460',
    html: '&#128096;',
    category: 'Objects (clothing)',
    order: '1330',
  },
  {
    emoji: '🎯',
    name: 'direct hit',
    shortname: ':dart:',
    unicode: '1f3af',
    html: '&#127919;',
    category: 'Activities (game)',
    order: '1798',
  },
  {
    emoji: '🏊🏿‍♀',
    name: 'woman swimming: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3CA 1F3FF 200D 2640',
    html: '&#127946;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏾‍♀',
    name: 'woman swimming: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3CA 1F3FE 200D 2640',
    html: '&#127946;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏽‍♀',
    name: 'woman swimming: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3CA 1F3FD 200D 2640',
    html: '&#127946;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏼‍♀',
    name: 'woman swimming: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3CA 1F3FC 200D 2640',
    html: '&#127946;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏻‍♀',
    name: 'woman swimming: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3CA 1F3FB 200D 2640',
    html: '&#127946;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊‍♀',
    name: 'woman swimming',
    shortname: ':woman_swimming:',
    unicode: '1F3CA 200D 2640',
    html: '&#127946;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏿',
    name: 'person swimming: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3CA 1F3FF',
    html: '&#127946;&#127999;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏾',
    name: 'person swimming: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3CA 1F3FE',
    html: '&#127946;&#127998;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏽',
    name: 'person swimming: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3CA 1F3FD',
    html: '&#127946;&#127997;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏼',
    name: 'person swimming: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3CA 1F3FC',
    html: '&#127946;&#127996;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏻',
    name: 'person swimming: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3CA 1F3FB',
    html: '&#127946;&#127995;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊',
    name: 'person swimming',
    shortname: ':swimmer:',
    unicode: '1f3ca',
    html: '&#127946;',
    category: 'People & Body (person-sport)',
    order: '836',
  },
  {
    emoji: '🔑',
    name: 'key',
    shortname: ':key:',
    unicode: '1f511',
    html: '&#128273;',
    category: 'Objects (lock)',
    order: '1948',
  },
  {
    emoji: '👙',
    name: 'bikini',
    shortname: ':bikini:',
    unicode: '1f459',
    html: '&#128089;',
    category: 'Objects (clothing)',
    order: '1321',
  },
  {
    emoji: '👪',
    name: 'family',
    shortname: ':family:',
    unicode: '1f46a',
    html: '&#128106;',
    category: 'People & Body (family)',
    order: '1044',
  },
  {
    emoji: '✏',
    name: 'pencil',
    shortname: ':pencil2:',
    unicode: '270f',
    html: '&#9999;',
    category: 'Objects (writing)',
    order: '1914',
  },
  {
    emoji: '🐘',
    name: 'elephant',
    shortname: ':elephant:',
    unicode: '1f418',
    html: '&#128024;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1373',
  },
  {
    emoji: '💧',
    name: 'droplet',
    shortname: ':droplet:',
    unicode: '1f4a7',
    html: '&#128167;',
    category: 'Travel & Places (sky & weather)',
    order: '1754',
  },
  {
    emoji: '🌱',
    name: 'seedling',
    shortname: ':seedling:',
    unicode: '1f331',
    html: '&#127793;',
    category: 'Animals & Nature (plant-other)',
    order: '1437',
  },
  {
    emoji: '🍎',
    name: 'red apple',
    shortname: ':apple:',
    unicode: '1f34e',
    html: '&#127822;',
    category: 'Food & Drink (food-fruit)',
    order: '1456',
  },
  {
    emoji: '🆒',
    name: 'COOL button',
    shortname: ':cool:',
    unicode: '1f192',
    html: '&#127378;',
    category: 'Symbols (alphanum)',
    order: '2129',
  },
  {
    emoji: '📞',
    name: 'telephone receiver',
    shortname: ':telephone_receiver:',
    unicode: '1f4de',
    html: '&#128222;',
    category: 'Objects (phone)',
    order: '1841',
  },
  {
    emoji: '💵',
    name: 'dollar banknote',
    shortname: ':dollar:',
    unicode: '1f4b5',
    html: '&#128181;',
    category: 'Objects (money)',
    order: '1893',
  },
  {
    emoji: '🏡',
    name: 'house with garden',
    shortname: ':house_with_garden:',
    unicode: '1f3e1',
    html: '&#127969;',
    category: 'Travel & Places (place-building)',
    order: '1560',
  },
  {
    emoji: '📖',
    name: 'open book',
    shortname: ':book:',
    unicode: '1f4d6',
    html: '&#128214;',
    category: 'Objects (book-paper)',
    order: '1876',
  },
  {
    emoji: '💇🏿‍♂',
    name: 'man getting haircut: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F487 1F3FF 200D 2642',
    html: '&#128135;&#127999;&zwj;&#9794;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏾‍♂',
    name: 'man getting haircut: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F487 1F3FE 200D 2642',
    html: '&#128135;&#127998;&zwj;&#9794;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏽‍♂',
    name: 'man getting haircut: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F487 1F3FD 200D 2642',
    html: '&#128135;&#127997;&zwj;&#9794;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏼‍♂',
    name: 'man getting haircut: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F487 1F3FC 200D 2642',
    html: '&#128135;&#127996;&zwj;&#9794;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏻‍♂',
    name: 'man getting haircut: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F487 1F3FB 200D 2642',
    html: '&#128135;&#127995;&zwj;&#9794;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇‍♂',
    name: 'man getting haircut',
    shortname: ':man_getting_haircut:',
    unicode: '1F487 200D 2642',
    html: '&#128135;&zwj;&#9794;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏿',
    name: 'person getting haircut: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F487 1F3FF',
    html: '&#128135;&#127999;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏾',
    name: 'person getting haircut: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F487 1F3FE',
    html: '&#128135;&#127998;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏽',
    name: 'person getting haircut: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F487 1F3FD',
    html: '&#128135;&#127997;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏼',
    name: 'person getting haircut: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F487 1F3FC',
    html: '&#128135;&#127996;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏻',
    name: 'person getting haircut: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F487 1F3FB',
    html: '&#128135;&#127995;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇',
    name: 'person getting haircut',
    shortname: ':haircut:',
    unicode: '1f487',
    html: '&#128135;',
    category: 'People & Body (person-activity)',
    order: '675',
  },
  {
    emoji: '💻',
    name: 'laptop',
    shortname: ':computer:',
    unicode: '1f4bb',
    html: '&#128187;',
    category: 'Objects (computer)',
    order: '1846',
  },
  {
    emoji: '💡',
    name: 'light bulb',
    shortname: ':bulb:',
    unicode: '1f4a1',
    html: '&#128161;',
    category: 'Objects (light & video)',
    order: '1871',
  },
  {
    emoji: '❓',
    name: 'question mark',
    shortname: ':question:',
    unicode: '2753',
    html: '&#10067;',
    category: 'Symbols (other-symbol)',
    order: '2098',
  },
  {
    emoji: '🔙',
    name: 'BACK arrow',
    shortname: ':back:',
    unicode: '1f519',
    html: '&#128281;',
    category: 'Symbols (arrow)',
    order: '2018',
  },
  {
    emoji: '👦🏿',
    name: 'boy: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F466 1F3FF',
    html: '&#128102;&#127999;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👦🏾',
    name: 'boy: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F466 1F3FE',
    html: '&#128102;&#127998;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👦🏽',
    name: 'boy: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F466 1F3FD',
    html: '&#128102;&#127997;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👦🏼',
    name: 'boy: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F466 1F3FC',
    html: '&#128102;&#127996;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👦🏻',
    name: 'boy: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F466 1F3FB',
    html: '&#128102;&#127995;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👦',
    name: 'boy',
    shortname: ':boy:',
    unicode: '1f466',
    html: '&#128102;',
    category: 'People & Body (person)',
    order: '99',
  },
  {
    emoji: '🔐',
    name: 'locked with key',
    shortname: ':closed_lock_with_key:',
    unicode: '1f510',
    html: '&#128272;',
    category: 'Objects (lock)',
    order: '1947',
  },
  {
    emoji: '🙎🏿‍♂',
    name: 'man pouting: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64E 1F3FF 200D 2642',
    html: '&#128590;&#127999;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏾‍♂',
    name: 'man pouting: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64E 1F3FE 200D 2642',
    html: '&#128590;&#127998;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏽‍♂',
    name: 'man pouting: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64E 1F3FD 200D 2642',
    html: '&#128590;&#127997;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏼‍♂',
    name: 'man pouting: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64E 1F3FC 200D 2642',
    html: '&#128590;&#127996;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏻‍♂',
    name: 'man pouting: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64E 1F3FB 200D 2642',
    html: '&#128590;&#127995;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎‍♂',
    name: 'man pouting',
    shortname: ':man_pouting:',
    unicode: '1F64E 200D 2642',
    html: '&#128590;&zwj;&#9794;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏿',
    name: 'person pouting: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64E 1F3FF',
    html: '&#128590;&#127999;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏾',
    name: 'person pouting: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64E 1F3FE',
    html: '&#128590;&#127998;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏽',
    name: 'person pouting: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64E 1F3FD',
    html: '&#128590;&#127997;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏼',
    name: 'person pouting: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64E 1F3FC',
    html: '&#128590;&#127996;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏻',
    name: 'person pouting: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64E 1F3FB',
    html: '&#128590;&#127995;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎',
    name: 'person pouting',
    shortname: ':person_with_pouting_face:',
    unicode: '1f64e',
    html: '&#128590;',
    category: 'People & Body (person-gesture)',
    order: '513',
  },
  {
    emoji: '🍊',
    name: 'tangerine',
    shortname: ':tangerine:',
    unicode: '1f34a',
    html: '&#127818;',
    category: 'Food & Drink (food-fruit)',
    order: '1452',
  },
  {
    emoji: '↔️',
    name: 'left-right arrow',
    shortname: ':leftright_arrow:',
    unicode: '2194 FE0F',
    html: '&harr;️',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '🌅',
    name: 'sunrise',
    shortname: ':sunrise:',
    unicode: '1f305',
    html: '&#127749;',
    category: 'Travel & Places (place-other)',
    order: '1587',
  },
  {
    emoji: '🍗',
    name: 'poultry leg',
    shortname: ':poultry_leg:',
    unicode: '1f357',
    html: '&#127831;',
    category: 'Food & Drink (food-prepared)',
    order: '1480',
  },
  {
    emoji: '🔵',
    name: 'blue circle',
    shortname: ':blue_circle:',
    unicode: '1f535',
    html: '&#128309;',
    category: 'Symbols (geometric)',
    order: '2180',
  },
  {
    emoji: '🚘',
    name: 'oncoming automobile',
    shortname: ':oncoming_automobile:',
    unicode: '1f698',
    html: '&#128664;',
    category: 'Travel & Places (transport-ground)',
    order: '1625',
  },
  {
    emoji: '🍧',
    name: 'shaved ice',
    shortname: ':shaved_ice:',
    unicode: '1f367',
    html: '&#127847;',
    category: 'Food & Drink (food-sweet)',
    order: '1509',
  },
  {
    emoji: '🇮🇹',
    name: 'flag: Italy',
    shortname: ':it:',
    unicode: '1F1EE 1F1F9',
    html: '&#127470;',
    category: 'Flags (country-flag)',
    order: '',
  },
  {
    emoji: '🐦',
    name: 'bird',
    shortname: ':bird:',
    unicode: '1f426',
    html: '&#128038;',
    category: 'Animals & Nature (animal-bird)',
    order: '1393',
  },
  {
    emoji: '🇬🇧',
    name: 'flag: United Kingdom',
    shortname: ':gb:',
    unicode: '1F1EC 1F1E7',
    html: '&#127468;',
    category: 'Flags (country-flag)',
    order: '',
  },
  {
    emoji: '🌛',
    name: 'first quarter moon face',
    shortname: ':first_quarter_moon_with_face:',
    unicode: '1f31b',
    html: '&#127771;',
    category: 'Travel & Places (sky & weather)',
    order: '1721',
  },
  {
    emoji: '👓',
    name: 'glasses',
    shortname: ':eyeglasses:',
    unicode: '1f453',
    html: '&#128083;',
    category: 'Objects (clothing)',
    order: '1314',
  },
  {
    emoji: '🐐',
    name: 'goat',
    shortname: ':goat:',
    unicode: '1f410',
    html: '&#128016;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1370',
  },
  {
    emoji: '🌃',
    name: 'night with stars',
    shortname: ':night_with_stars:',
    unicode: '1f303',
    html: '&#127747;',
    category: 'Travel & Places (place-other)',
    order: '1585',
  },
  {
    emoji: '👵🏿',
    name: 'old woman: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F475 1F3FF',
    html: '&#128117;&#127999;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👵🏾',
    name: 'old woman: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F475 1F3FE',
    html: '&#128117;&#127998;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👵🏽',
    name: 'old woman: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F475 1F3FD',
    html: '&#128117;&#127997;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👵🏼',
    name: 'old woman: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F475 1F3FC',
    html: '&#128117;&#127996;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👵🏻',
    name: 'old woman: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F475 1F3FB',
    html: '&#128117;&#127995;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👵',
    name: 'old woman',
    shortname: ':older_woman:',
    unicode: '1f475',
    html: '&#128117;',
    category: 'People & Body (person)',
    order: '129',
  },
  {
    emoji: '⚫',
    name: 'black circle',
    shortname: ':black_circle:',
    unicode: '26ab',
    html: '&#9899;',
    category: 'Symbols (geometric)',
    order: '2178',
  },
  {
    emoji: '🌑',
    name: 'new moon',
    shortname: ':new_moon:',
    unicode: '1f311',
    html: '&#127761;',
    category: 'Travel & Places (sky & weather)',
    order: '1711',
  },
  {
    emoji: '👬',
    name: 'men holding hands',
    shortname: ':two_men_holding_hands:',
    unicode: '1f46c',
    html: '&#128108;',
    category: 'People & Body (family)',
    order: '1024',
  },
  {
    emoji: '⚪',
    name: 'white circle',
    shortname: ':white_circle:',
    unicode: '26aa',
    html: '&#9898;',
    category: 'Symbols (geometric)',
    order: '2177',
  },
  {
    emoji: '🛃',
    name: 'customs',
    shortname: ':customs:',
    unicode: '1f6c3',
    html: '&#128707;',
    category: 'Symbols (transport-sign)',
    order: '1986',
  },
  {
    emoji: '🐠',
    name: 'tropical fish',
    shortname: ':tropical_fish:',
    unicode: '1f420',
    html: '&#128032;',
    category: 'Animals & Nature (animal-marine)',
    order: '1410',
  },
  {
    emoji: '🏠',
    name: 'house',
    shortname: ':house:',
    unicode: '1f3e0',
    html: '&#127968;',
    category: 'Travel & Places (place-building)',
    order: '1559',
  },
  {
    emoji: '🔃',
    name: 'clockwise vertical arrows',
    shortname: ':arrows_clockwise:',
    unicode: '1f503',
    html: '&#128259;',
    category: 'Symbols (arrow)',
    order: '2016',
  },
  {
    emoji: '🌜',
    name: 'last quarter moon face',
    shortname: ':last_quarter_moon_with_face:',
    unicode: '1f31c',
    html: '&#127772;',
    category: 'Travel & Places (sky & weather)',
    order: '1722',
  },
  {
    emoji: '📍',
    name: 'round pushpin',
    shortname: ':round_pushpin:',
    unicode: '1f4cd',
    html: '&#128205;',
    category: 'Objects (office)',
    order: '1935',
  },
  {
    emoji: '🌕',
    name: 'full moon',
    shortname: ':full_moon:',
    unicode: '1f315',
    html: '&#127765;',
    category: 'Travel & Places (sky & weather)',
    order: '1715',
  },
  {
    emoji: '👟',
    name: 'running shoe',
    shortname: ':athletic_shoe:',
    unicode: '1f45f',
    html: '&#128095;',
    category: 'Objects (clothing)',
    order: '1329',
  },
  {
    emoji: '🍋',
    name: 'lemon',
    shortname: ':lemon:',
    unicode: '1f34b',
    html: '&#127819;',
    category: 'Food & Drink (food-fruit)',
    order: '1453',
  },
  {
    emoji: '🍼',
    name: 'baby bottle',
    shortname: ':baby_bottle:',
    unicode: '1f37c',
    html: '&#127868;',
    category: 'Food & Drink (drink)',
    order: '1520',
  },
  {
    emoji: '🎨',
    name: 'artist palette',
    shortname: ':artist_palette:',
    unicode: '1F3A8',
    html: '&#127912;',
    category: 'Activities (arts & crafts)',
    order: '',
  },
  {
    emoji: '✉️',
    name: 'envelope',
    shortname: ':envelope:',
    unicode: '2709 FE0F',
    html: '&#9993;',
    category: 'Objects (mail)',
    order: '',
  },
  {
    emoji: '🍝',
    name: 'spaghetti',
    shortname: ':spaghetti:',
    unicode: '1f35d',
    html: '&#127837;',
    category: 'Food & Drink (food-asian)',
    order: '1501',
  },
  {
    emoji: '🎐',
    name: 'wind chime',
    shortname: ':wind_chime:',
    unicode: '1f390',
    html: '&#127888;',
    category: 'Activities (event)',
    order: '1768',
  },
  {
    emoji: '🍥',
    name: 'fish cake with swirl',
    shortname: ':fish_cake:',
    unicode: '1f365',
    html: '&#127845;',
    category: 'Food & Drink (food-asian)',
    order: '1506',
  },
  {
    emoji: '🌲',
    name: 'evergreen tree',
    shortname: ':evergreen_tree:',
    unicode: '1f332',
    html: '&#127794;',
    category: 'Animals & Nature (plant-other)',
    order: '1438',
  },
  {
    emoji: '🆙',
    name: 'UP! button',
    shortname: ':up:',
    unicode: '1f199',
    html: '&#127385;',
    category: 'Symbols (alphanum)',
    order: '2140',
  },
  {
    emoji: '⬆️',
    name: 'up arrow',
    shortname: ':arrow_up:',
    unicode: '2b06',
    html: '&#11014;',
    category: 'Symbols (arrow)',
    order: '2002',
  },
  {
    emoji: '↗️',
    name: 'up-right arrow',
    shortname: ':arrow_upper_right:',
    unicode: '2197',
    html: '&#8599;',
    category: 'Symbols (arrow)',
    order: '2003',
  },
  {
    emoji: '↘️',
    name: 'down-right arrow',
    shortname: ':arrow_lower_right:',
    unicode: '2198',
    html: '&#8600;',
    category: 'Symbols (arrow)',
    order: '2005',
  },
  {
    emoji: '↙️',
    name: 'down-left arrow',
    shortname: ':arrow_lower_left:',
    unicode: '2199',
    html: '&#8601;',
    category: 'Symbols (arrow)',
    order: '2007',
  },
  {
    emoji: '🎭',
    name: 'performing arts',
    shortname: ':performing_arts:',
    unicode: '1f3ad',
    html: '&#127917;',
    category: 'Activities (arts & crafts)',
    order: '1598',
  },
  {
    emoji: '👃🏿',
    name: 'nose: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F443 1F3FF',
    html: '&#128067;&#127999;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '👃🏾',
    name: 'nose: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F443 1F3FE',
    html: '&#128067;&#127998;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '👃🏽',
    name: 'nose: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F443 1F3FD',
    html: '&#128067;&#127997;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '👃🏼',
    name: 'nose: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F443 1F3FC',
    html: '&#128067;&#127996;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '👃🏻',
    name: 'nose: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F443 1F3FB',
    html: '&#128067;&#127995;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '👃',
    name: 'nose',
    shortname: ':nose:',
    unicode: '1f443',
    html: '&#128067;',
    category: 'People & Body (body-parts)',
    order: '1272',
  },
  {
    emoji: '🐽',
    name: 'pig nose',
    shortname: ':pig_nose:',
    unicode: '1f43d',
    html: '&#128061;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1367',
  },
  {
    emoji: '🐟',
    name: 'fish',
    shortname: ':fish:',
    unicode: '1f41f',
    html: '&#128031;',
    category: 'Animals & Nature (animal-marine)',
    order: '1409',
  },
  {
    emoji: '👳🏿‍♀',
    name: 'woman wearing turban: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F473 1F3FF 200D 2640',
    html: '&#128115;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏾‍♀',
    name: 'woman wearing turban: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F473 1F3FE 200D 2640',
    html: '&#128115;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏽‍♀',
    name: 'woman wearing turban: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F473 1F3FD 200D 2640',
    html: '&#128115;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏼‍♀',
    name: 'woman wearing turban: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F473 1F3FC 200D 2640',
    html: '&#128115;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏻‍♀',
    name: 'woman wearing turban: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F473 1F3FB 200D 2640',
    html: '&#128115;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳‍♀',
    name: 'woman wearing turban',
    shortname: ':woman_wearing_turban:',
    unicode: '1F473 200D 2640',
    html: '&#128115;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏿',
    name: 'person wearing turban: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F473 1F3FF',
    html: '&#128115;&#127999;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏾',
    name: 'person wearing turban: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F473 1F3FE',
    html: '&#128115;&#127998;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏽',
    name: 'person wearing turban: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F473 1F3FD',
    html: '&#128115;&#127997;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏼',
    name: 'person wearing turban: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F473 1F3FC',
    html: '&#128115;&#127996;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏻',
    name: 'person wearing turban: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F473 1F3FB',
    html: '&#128115;&#127995;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳',
    name: 'person wearing turban',
    shortname: ':man_with_turban:',
    unicode: '1f473',
    html: '&#128115;',
    category: 'People & Body (person-role)',
    order: '411',
  },
  {
    emoji: '🐨',
    name: 'koala',
    shortname: ':koala:',
    unicode: '1f428',
    html: '&#128040;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1384',
  },
  {
    emoji: '👂🏿',
    name: 'ear: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F442 1F3FF',
    html: '&#128066;&#127999;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '👂🏾',
    name: 'ear: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F442 1F3FE',
    html: '&#128066;&#127998;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '👂🏽',
    name: 'ear: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F442 1F3FD',
    html: '&#128066;&#127997;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '👂🏼',
    name: 'ear: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F442 1F3FC',
    html: '&#128066;&#127996;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '👂🏻',
    name: 'ear: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F442 1F3FB',
    html: '&#128066;&#127995;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '👂',
    name: 'ear',
    shortname: ':ear:',
    unicode: '1f442',
    html: '&#128066;',
    category: 'People & Body (body-parts)',
    order: '1266',
  },
  {
    emoji: '✳️',
    name: 'eight-spoked asterisk',
    shortname: ':eight_spoked_asterisk:',
    unicode: '2733',
    html: '&#10035;',
    category: 'Symbols (other-symbol)',
    order: '2093',
  },
  {
    emoji: '🔹',
    name: 'small blue diamond',
    shortname: ':small_blue_diamond:',
    unicode: '1f539',
    html: '&#128313;',
    category: 'Symbols (geometric)',
    order: '2170',
  },
  {
    emoji: '🚿',
    name: 'shower',
    shortname: ':shower:',
    unicode: '1f6bf',
    html: '&#128703;',
    category: 'Objects (household)',
    order: '1672',
  },
  {
    emoji: '🐛',
    name: 'bug',
    shortname: ':bug:',
    unicode: '1f41b',
    html: '&#128027;',
    category: 'Animals & Nature (animal-bug)',
    order: '1420',
  },
  {
    emoji: '🍜',
    name: 'steaming bowl',
    shortname: ':ramen:',
    unicode: '1f35c',
    html: '&#127836;',
    category: 'Food & Drink (food-asian)',
    order: '1500',
  },
  {
    emoji: '🎩',
    name: 'top hat',
    shortname: ':tophat:',
    unicode: '1f3a9',
    html: '&#127913;',
    category: 'Objects (clothing)',
    order: '1335',
  },
  {
    emoji: '👰🏿',
    name: 'bride with veil: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F470 1F3FF',
    html: '&#128112;&#127999;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👰🏾',
    name: 'bride with veil: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F470 1F3FE',
    html: '&#128112;&#127998;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👰🏽',
    name: 'bride with veil: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F470 1F3FD',
    html: '&#128112;&#127997;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👰🏼',
    name: 'bride with veil: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F470 1F3FC',
    html: '&#128112;&#127996;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👰🏻',
    name: 'bride with veil: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F470 1F3FB',
    html: '&#128112;&#127995;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👰',
    name: 'bride with veil',
    shortname: ':bride_with_veil:',
    unicode: '1f470',
    html: '&#128112;',
    category: 'People & Body (person-role)',
    order: '471',
  },
  {
    emoji: '⛽',
    name: 'fuel pump',
    shortname: ':fuelpump:',
    unicode: '26fd',
    html: '&#9981;',
    category: 'Travel & Places (transport-ground)',
    order: '1636',
  },
  {
    emoji: '🏁',
    name: 'chequered flag',
    shortname: ':checkered_flag:',
    unicode: '1f3c1',
    html: '&#127937;',
    category: 'Flags (flag)',
    order: '2181',
  },
  {
    emoji: '🐴',
    name: 'horse face',
    shortname: ':horse:',
    unicode: '1f434',
    html: '&#128052;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1356',
  },
  {
    emoji: '⌚',
    name: 'watch',
    shortname: ':watch:',
    unicode: '231a',
    html: '&#8986;',
    category: 'Travel & Places (time)',
    order: '1682',
  },
  {
    emoji: '🐵',
    name: 'monkey face',
    shortname: ':monkey_face:',
    unicode: '1f435',
    html: '&#128053;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1342',
  },
  {
    emoji: '🚼',
    name: 'baby symbol',
    shortname: ':baby_symbol:',
    unicode: '1f6bc',
    html: '&#128700;',
    category: 'Symbols (transport-sign)',
    order: '1983',
  },
  {
    emoji: '🆕',
    name: 'NEW button',
    shortname: ':new:',
    unicode: '1f195',
    html: '&#127381;',
    category: 'Symbols (alphanum)',
    order: '2134',
  },
  {
    emoji: '🆓',
    name: 'FREE button',
    shortname: ':free:',
    unicode: '1f193',
    html: '&#127379;',
    category: 'Symbols (alphanum)',
    order: '2130',
  },
  {
    emoji: '🎇',
    name: 'sparkler',
    shortname: ':sparkler:',
    unicode: '1f387',
    html: '&#127879;',
    category: 'Activities (event)',
    order: '1759',
  },
  {
    emoji: '🌽',
    name: 'ear of corn',
    shortname: ':corn:',
    unicode: '1f33d',
    html: '&#127805;',
    category: 'Food & Drink (food-vegetable)',
    order: '1468',
  },
  {
    emoji: '🎾',
    name: 'tennis',
    shortname: ':tennis:',
    unicode: '1f3be',
    html: '&#127934;',
    category: 'Activities (sport)',
    order: '1787',
  },
  {
    emoji: '⏰',
    name: 'alarm clock',
    shortname: ':alarm_clock:',
    unicode: '23f0',
    html: '&#9200;',
    category: 'Travel & Places (time)',
    order: '1683',
  },
  {
    emoji: '🔋',
    name: 'battery',
    shortname: ':battery:',
    unicode: '1f50b',
    html: '&#128267;',
    category: 'Objects (computer)',
    order: '1844',
  },
  {
    emoji: '❕',
    name: 'white exclamation mark',
    shortname: ':grey_exclamation:',
    unicode: '2755',
    html: '&#10069;',
    category: 'Symbols (other-symbol)',
    order: '2100',
  },
  {
    emoji: '🐺',
    name: 'wolf',
    shortname: ':wolf:',
    unicode: '1f43a',
    html: '&#128058;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1348',
  },
  {
    emoji: '🗿',
    name: 'moai',
    shortname: ':moyai:',
    unicode: '1f5ff',
    html: '&#128511;',
    category: 'Objects (other-object)',
    order: '1972',
  },
  {
    emoji: '🐮',
    name: 'cow face',
    shortname: ':cow:',
    unicode: '1f42e',
    html: '&#128046;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1360',
  },
  {
    emoji: '📣',
    name: 'megaphone',
    shortname: ':mega:',
    unicode: '1f4e3',
    html: '&#128227;',
    category: 'Objects (sound)',
    order: '1819',
  },
  {
    emoji: '👴🏿',
    name: 'old man: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F474 1F3FF',
    html: '&#128116;&#127999;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👴🏾',
    name: 'old man: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F474 1F3FE',
    html: '&#128116;&#127998;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👴🏽',
    name: 'old man: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F474 1F3FD',
    html: '&#128116;&#127997;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👴🏼',
    name: 'old man: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F474 1F3FC',
    html: '&#128116;&#127996;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👴🏻',
    name: 'old man: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F474 1F3FB',
    html: '&#128116;&#127995;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👴',
    name: 'old man',
    shortname: ':older_man:',
    unicode: '1f474',
    html: '&#128116;',
    category: 'People & Body (person)',
    order: '123',
  },
  {
    emoji: '👗',
    name: 'dress',
    shortname: ':dress:',
    unicode: '1f457',
    html: '&#128087;',
    category: 'Objects (clothing)',
    order: '1319',
  },
  {
    emoji: '🔗',
    name: 'link',
    shortname: ':link:',
    unicode: '1f517',
    html: '&#128279;',
    category: 'Objects (tool)',
    order: '1965',
  },
  {
    emoji: '🐔',
    name: 'chicken',
    shortname: ':chicken:',
    unicode: '1f414',
    html: '&#128020;',
    category: 'Animals & Nature (animal-bird)',
    order: '1388',
  },
  {
    emoji: '🍳',
    name: 'cooking',
    shortname: ':cooking:',
    unicode: '1F373',
    html: '&#127859;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🐋',
    name: 'whale',
    shortname: ':whale2:',
    unicode: '1f40b',
    html: '&#128011;',
    category: 'Animals & Nature (animal-marine)',
    order: '1407',
  },
  {
    emoji: '↖',
    name: 'up-left arrow',
    shortname: ':arrow_upper_left:',
    unicode: '2196',
    html: '&#8598;',
    category: 'Symbols (arrow)',
    order: '2009',
  },
  {
    emoji: '🌳',
    name: 'deciduous tree',
    shortname: ':deciduous_tree:',
    unicode: '1f333',
    html: '&#127795;',
    category: 'Animals & Nature (plant-other)',
    order: '1439',
  },
  {
    emoji: '🍱',
    name: 'bento box',
    shortname: ':bento:',
    unicode: '1f371',
    html: '&#127857;',
    category: 'Food & Drink (food-asian)',
    order: '1495',
  },
  {
    emoji: '📌',
    name: 'pushpin',
    shortname: ':pushpin:',
    unicode: '1f4cc',
    html: '&#128204;',
    category: 'Objects (office)',
    order: '1934',
  },
  {
    emoji: '🔜',
    name: 'SOON arrow',
    shortname: ':soon:',
    unicode: '1f51c',
    html: '&#128284;',
    category: 'Symbols (arrow)',
    order: '2021',
  },
  {
    emoji: '🔁',
    name: 'repeat button',
    shortname: ':repeat:',
    unicode: '1f501',
    html: '&#128257;',
    category: 'Symbols (av-symbol)',
    order: '2049',
  },
  {
    emoji: '🐉',
    name: 'dragon',
    shortname: ':dragon:',
    unicode: '1f409',
    html: '&#128009;',
    category: 'Animals & Nature (animal-reptile)',
    order: '1405',
  },
  {
    emoji: '🐹',
    name: 'hamster',
    shortname: ':hamster:',
    unicode: '1f439',
    html: '&#128057;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1378',
  },
  {
    emoji: '⛳',
    name: 'flag in hole',
    shortname: ':golf:',
    unicode: '26f3',
    html: '&#9971;',
    category: 'Activities (sport)',
    order: '1799',
  },
  {
    emoji: '🏄🏿‍♀',
    name: 'woman surfing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3C4 1F3FF 200D 2640',
    html: '&#127940;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏾‍♀',
    name: 'woman surfing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3C4 1F3FE 200D 2640',
    html: '&#127940;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏽‍♀',
    name: 'woman surfing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3C4 1F3FD 200D 2640',
    html: '&#127940;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏼‍♀',
    name: 'woman surfing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3C4 1F3FC 200D 2640',
    html: '&#127940;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏻‍♀',
    name: 'woman surfing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3C4 1F3FB 200D 2640',
    html: '&#127940;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄‍♀',
    name: 'woman surfing',
    shortname: ':woman_surfing:',
    unicode: '1F3C4 200D 2640',
    html: '&#127940;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏿',
    name: 'person surfing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3C4 1F3FF',
    html: '&#127940;&#127999;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏾',
    name: 'person surfing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3C4 1F3FE',
    html: '&#127940;&#127998;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏽',
    name: 'person surfing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3C4 1F3FD',
    html: '&#127940;&#127997;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏼',
    name: 'person surfing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3C4 1F3FC',
    html: '&#127940;&#127996;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏻',
    name: 'person surfing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3C4 1F3FB',
    html: '&#127940;&#127995;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄',
    name: 'person surfing',
    shortname: ':surfer:',
    unicode: '1f3c4',
    html: '&#127940;',
    category: 'People & Body (person-sport)',
    order: '800',
  },
  {
    emoji: '🐭',
    name: 'mouse face',
    shortname: ':mouse:',
    unicode: '1f42d',
    html: '&#128045;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1375',
  },
  {
    emoji: '🌒',
    name: 'waxing crescent moon',
    shortname: ':waxing_crescent_moon:',
    unicode: '1f312',
    html: '&#127762;',
    category: 'Travel & Places (sky & weather)',
    order: '1712',
  },
  {
    emoji: '🚙',
    name: 'sport utility vehicle',
    shortname: ':blue_car:',
    unicode: '1f699',
    html: '&#128665;',
    category: 'Travel & Places (transport-ground)',
    order: '1626',
  },
  {
    emoji: '🅰️',
    name: 'A button (blood type)',
    shortname: ':a:',
    unicode: '1f170',
    html: '&#127344;',
    category: 'Symbols (alphanum)',
    order: '2125',
  },
  {
    emoji: '⁉️',
    name: 'exclamation question mark',
    shortname: ':interrobang:',
    unicode: '2049',
    html: '&#8265;',
    category: 'Symbols (other-symbol)',
    order: '2097',
  },
  {
    emoji: '🈹',
    name: 'Japanese discount button',
    shortname: ':u5272:',
    unicode: '1f239',
    html: '&#127545;',
    category: 'Symbols (alphanum)',
    order: '2148',
  },
  {
    emoji: '🔌',
    name: 'electric plug',
    shortname: ':electric_plug:',
    unicode: '1f50c',
    html: '&#128268;',
    category: 'Objects (computer)',
    order: '1845',
  },
  {
    emoji: '🌓',
    name: 'first quarter moon',
    shortname: ':first_quarter_moon:',
    unicode: '1f313',
    html: '&#127763;',
    category: 'Travel & Places (sky & weather)',
    order: '1713',
  },
  {
    emoji: '♋',
    name: 'Cancer',
    shortname: ':cancer:',
    unicode: '264b',
    html: '&#9803;',
    category: 'Symbols (zodiac)',
    order: '2038',
  },
  {
    emoji: '🔱',
    name: 'trident emblem',
    shortname: ':trident:',
    unicode: '1f531',
    html: '&#128305;',
    category: 'Symbols (other-symbol)',
    order: '2076',
  },
  {
    emoji: '🍞',
    name: 'bread',
    shortname: ':bread:',
    unicode: '1f35e',
    html: '&#127838;',
    category: 'Food & Drink (food-prepared)',
    order: '1474',
  },
  {
    emoji: '👮🏿‍♀',
    name: 'woman police officer: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F46E 1F3FF 200D 2640',
    html: '&#128110;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏾‍♀',
    name: 'woman police officer: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F46E 1F3FE 200D 2640',
    html: '&#128110;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏽‍♀',
    name: 'woman police officer: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F46E 1F3FD 200D 2640',
    html: '&#128110;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏼‍♀',
    name: 'woman police officer: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F46E 1F3FC 200D 2640',
    html: '&#128110;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏻‍♀',
    name: 'woman police officer: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F46E 1F3FB 200D 2640',
    html: '&#128110;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮‍♀',
    name: 'woman police officer',
    shortname: ':woman_police_officer:',
    unicode: '1F46E 200D 2640',
    html: '&#128110;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏿',
    name: 'police officer: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F46E 1F3FF',
    html: '&#128110;&#127999;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏾',
    name: 'police officer: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F46E 1F3FE',
    html: '&#128110;&#127998;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏽',
    name: 'police officer: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F46E 1F3FD',
    html: '&#128110;&#127997;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏼',
    name: 'police officer: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F46E 1F3FC',
    html: '&#128110;&#127996;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏻',
    name: 'police officer: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F46E 1F3FB',
    html: '&#128110;&#127995;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮',
    name: 'police officer',
    shortname: ':cop:',
    unicode: '1f46e',
    html: '&#128110;',
    category: 'People & Body (person-role)',
    order: '339',
  },
  {
    emoji: '🍵',
    name: 'teacup without handle',
    shortname: ':tea:',
    unicode: '1f375',
    html: '&#127861;',
    category: 'Food & Drink (drink)',
    order: '1523',
  },
  {
    emoji: '🎣',
    name: 'fishing pole',
    shortname: ':fishing_pole_and_fish:',
    unicode: '1f3a3',
    html: '&#127907;',
    category: 'Activities (sport)',
    order: '1801',
  },
  {
    emoji: '🌔',
    name: 'waxing gibbous moon',
    shortname: ':waxing_gibbous_moon:',
    unicode: '1F314',
    html: '&#127764;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🚲',
    name: 'bicycle',
    shortname: ':bike:',
    unicode: '1f6b2',
    html: '&#128690;',
    category: 'Travel & Places (transport-ground)',
    order: '1630',
  },
  {
    emoji: '👤',
    name: 'bust in silhouette',
    shortname: ':bust_in_silhouette:',
    unicode: '1F464',
    html: '&#128100;',
    category: 'People & Body (person-symbol)',
    order: '',
  },
  {
    emoji: '🍚',
    name: 'cooked rice',
    shortname: ':rice:',
    unicode: '1f35a',
    html: '&#127834;',
    category: 'Food & Drink (food-asian)',
    order: '1498',
  },
  {
    emoji: '📻',
    name: 'radio',
    shortname: ':radio:',
    unicode: '1f4fb',
    html: '&#128251;',
    category: 'Objects (music)',
    order: '1831',
  },
  {
    emoji: '🐤',
    name: 'baby chick',
    shortname: ':baby_chick:',
    unicode: '1f424',
    html: '&#128036;',
    category: 'Animals & Nature (animal-bird)',
    order: '1391',
  },
  {
    emoji: '⤵️',
    name: 'right arrow curving down',
    shortname: ':arrow_heading_down:',
    unicode: '2935',
    html: '&#10549;',
    category: 'Symbols (arrow)',
    order: '2015',
  },
  {
    emoji: '🌘',
    name: 'waning crescent moon',
    shortname: ':waning_crescent_moon:',
    unicode: '1f318',
    html: '&#127768;',
    category: 'Travel & Places (sky & weather)',
    order: '1718',
  },
  {
    emoji: '↕',
    name: 'up-down arrow',
    shortname: ':arrow_up_down:',
    unicode: '2195',
    html: '&#8597;',
    category: 'Symbols (arrow)',
    order: '2010',
  },
  {
    emoji: '🇪',
    name: '',
    shortname: '',
    unicode: '',
    html: '&#127466;',
    category: '',
    order: '',
  },
  {
    emoji: '🌗',
    name: 'last quarter moon',
    shortname: ':last_quarter_moon:',
    unicode: '1f317',
    html: '&#127767;',
    category: 'Travel & Places (sky & weather)',
    order: '1717',
  },
  {
    emoji: '🔘',
    name: 'radio button',
    shortname: ':radio_button:',
    unicode: '1f518',
    html: '&#128280;',
    category: 'Symbols (geometric)',
    order: '2174',
  },
  {
    emoji: '🐑',
    name: 'ewe',
    shortname: ':sheep:',
    unicode: '1f411',
    html: '&#128017;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1369',
  },
  {
    emoji: '👱🏿‍♀',
    name: 'woman: dark skin tone, blond hair',
    shortname: ':dark_skin_tone_blond_hair:',
    unicode: '1F471 1F3FF 200D 2640',
    html: '&#128113;&#127999;&zwj;&#9792;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏾‍♀',
    name: 'woman: medium-dark skin tone, blond hair',
    shortname: ':mediumdark_skin_tone_blond_hair:',
    unicode: '1F471 1F3FE 200D 2640',
    html: '&#128113;&#127998;&zwj;&#9792;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏽‍♀',
    name: 'woman: medium skin tone, blond hair',
    shortname: ':medium_skin_tone_blond_hair:',
    unicode: '1F471 1F3FD 200D 2640',
    html: '&#128113;&#127997;&zwj;&#9792;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏼‍♀',
    name: 'woman: medium-light skin tone, blond hair',
    shortname: ':mediumlight_skin_tone_blond_hair:',
    unicode: '1F471 1F3FC 200D 2640',
    html: '&#128113;&#127996;&zwj;&#9792;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏻‍♀',
    name: 'woman: light skin tone, blond hair',
    shortname: ':light_skin_tone_blond_hair:',
    unicode: '1F471 1F3FB 200D 2640',
    html: '&#128113;&#127995;&zwj;&#9792;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱‍♀',
    name: 'woman: blond hair',
    shortname: ':blond_hair:',
    unicode: '1F471 200D 2640',
    html: '&#128113;&zwj;&#9792;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏿',
    name: 'person: dark skin tone, blond hair',
    shortname: ':dark_skin_tone_blond_hair:',
    unicode: '1F471 1F3FF',
    html: '&#128113;&#127999;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏾',
    name: 'person: medium-dark skin tone, blond hair',
    shortname: ':mediumdark_skin_tone_blond_hair:',
    unicode: '1F471 1F3FE',
    html: '&#128113;&#127998;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏽',
    name: 'person: medium skin tone, blond hair',
    shortname: ':medium_skin_tone_blond_hair:',
    unicode: '1F471 1F3FD',
    html: '&#128113;&#127997;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏼',
    name: 'person: medium-light skin tone, blond hair',
    shortname: ':mediumlight_skin_tone_blond_hair:',
    unicode: '1F471 1F3FC',
    html: '&#128113;&#127996;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏻',
    name: 'person: light skin tone, blond hair',
    shortname: ':light_skin_tone_blond_hair:',
    unicode: '1F471 1F3FB',
    html: '&#128113;&#127995;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱',
    name: 'person: blond hair',
    shortname: ':person_with_blond_hair:',
    unicode: '1f471',
    html: '&#128113;',
    category: 'People & Body (person)',
    order: '429',
  },
  {
    emoji: '🌖',
    name: 'waning gibbous moon',
    shortname: ':waning_gibbous_moon:',
    unicode: '1f316',
    html: '&#127766;',
    category: 'Travel & Places (sky & weather)',
    order: '1716',
  },
  {
    emoji: '🔒',
    name: 'locked',
    shortname: ':lock:',
    unicode: '1f512',
    html: '&#128274;',
    category: 'Objects (lock)',
    order: '1944',
  },
  {
    emoji: '🍏',
    name: 'green apple',
    shortname: ':green_apple:',
    unicode: '1f34f',
    html: '&#127823;',
    category: 'Food & Drink (food-fruit)',
    order: '1457',
  },
  {
    emoji: '👺',
    name: 'goblin',
    shortname: ':japanese_goblin:',
    unicode: '1f47a',
    html: '&#128122;',
    category: 'Smileys & Emotion (face-costume)',
    order: '79',
  },
  {
    emoji: '➰',
    name: 'curly loop',
    shortname: ':curly_loop:',
    unicode: '27b0',
    html: '&#10160;',
    category: 'Symbols (other-symbol)',
    order: '2090',
  },
  {
    emoji: '🚩',
    name: 'triangular flag',
    shortname: ':triangular_flag_on_post:',
    unicode: '1f6a9',
    html: '&#128681;',
    category: 'Flags (flag)',
    order: '2182',
  },
  {
    emoji: '🔄',
    name: 'counterclockwise arrows button',
    shortname: ':arrows_counterclockwise:',
    unicode: '1f504',
    html: '&#128260;',
    category: 'Symbols (arrow)',
    order: '2017',
  },
  {
    emoji: '🐎',
    name: 'horse',
    shortname: ':racehorse:',
    unicode: '1f40e',
    html: '&#128014;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1357',
  },
  {
    emoji: '🍤',
    name: 'fried shrimp',
    shortname: ':fried_shrimp:',
    unicode: '1f364',
    html: '&#127844;',
    category: 'Food & Drink (food-asian)',
    order: '1505',
  },
  {
    emoji: '🌄',
    name: 'sunrise over mountains',
    shortname: ':sunrise_over_mountains:',
    unicode: '1f304',
    html: '&#127748;',
    category: 'Travel & Places (place-other)',
    order: '1586',
  },
  {
    emoji: '🌋',
    name: 'volcano',
    shortname: ':volcano:',
    unicode: '1f30b',
    html: '&#127755;',
    category: 'Travel & Places (place-geographic)',
    order: '1546',
  },
  {
    emoji: '🐓',
    name: 'rooster',
    shortname: ':rooster:',
    unicode: '1f413',
    html: '&#128019;',
    category: 'Animals & Nature (animal-bird)',
    order: '1389',
  },
  {
    emoji: '📥',
    name: 'inbox tray',
    shortname: ':inbox_tray:',
    unicode: '1f4e5',
    html: '&#128229;',
    category: 'Objects (mail)',
    order: '1906',
  },
  {
    emoji: '💒',
    name: 'wedding',
    shortname: ':wedding:',
    unicode: '1f492',
    html: '&#128146;',
    category: 'Travel & Places (place-building)',
    order: '1574',
  },
  {
    emoji: '🍣',
    name: 'sushi',
    shortname: ':sushi:',
    unicode: '1f363',
    html: '&#127843;',
    category: 'Food & Drink (food-asian)',
    order: '1504',
  },
  {
    emoji: '〰',
    name: 'wavy dash',
    shortname: ':wavy_dash:',
    unicode: '3030',
    html: '&#12336;',
    category: 'Symbols (other-symbol)',
    order: '2102',
  },
  {
    emoji: '🍨',
    name: 'ice cream',
    shortname: ':ice_cream:',
    unicode: '1f368',
    html: '&#127848;',
    category: 'Food & Drink (food-sweet)',
    order: '1510',
  },
  {
    emoji: '⏪',
    name: 'fast reverse button',
    shortname: ':rewind:',
    unicode: '23ea',
    html: '&#9194;',
    category: 'Symbols (av-symbol)',
    order: '2056',
  },
  {
    emoji: '🍅',
    name: 'tomato',
    shortname: ':tomato:',
    unicode: '1f345',
    html: '&#127813;',
    category: 'Food & Drink (food-fruit)',
    order: '1463',
  },
  {
    emoji: '🐇',
    name: 'rabbit',
    shortname: ':rabbit2:',
    unicode: '1f407',
    html: '&#128007;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1380',
  },
  {
    emoji: '✴️',
    name: 'eight-pointed star',
    shortname: ':eight_pointed_black_star:',
    unicode: '2734',
    html: '&#10036;',
    category: 'Symbols (other-symbol)',
    order: '2094',
  },
  {
    emoji: '🔺',
    name: 'red triangle pointed up',
    shortname: ':small_red_triangle:',
    unicode: '1f53a',
    html: '&#128314;',
    category: 'Symbols (geometric)',
    order: '2171',
  },
  {
    emoji: '🔆',
    name: 'bright button',
    shortname: ':high_brightness:',
    unicode: '1f506',
    html: '&#128262;',
    category: 'Symbols (av-symbol)',
    order: '2068',
  },
  {
    emoji: '➕',
    name: 'plus sign',
    shortname: ':heavy_plus_sign:',
    unicode: '2795',
    html: '&#10133;',
    category: 'Symbols (other-symbol)',
    order: '2084',
  },
  {
    emoji: '👲🏿',
    name: 'man with skullcap: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F472 1F3FF',
    html: '&#128114;&#127999;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👲🏾',
    name: 'man with skullcap: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F472 1F3FE',
    html: '&#128114;&#127998;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👲🏽',
    name: 'man with skullcap: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F472 1F3FD',
    html: '&#128114;&#127997;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👲🏼',
    name: 'man with skullcap: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F472 1F3FC',
    html: '&#128114;&#127996;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👲🏻',
    name: 'man with skullcap: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F472 1F3FB',
    html: '&#128114;&#127995;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👲',
    name: 'man with skullcap',
    shortname: ':man_with_gua_pi_mao:',
    unicode: '1f472',
    html: '&#128114;',
    category: 'People & Body (person-role)',
    order: '489',
  },
  {
    emoji: '🏪',
    name: 'convenience store',
    shortname: ':convenience_store:',
    unicode: '1f3ea',
    html: '&#127978;',
    category: 'Travel & Places (place-building)',
    order: '1568',
  },
  {
    emoji: '👥',
    name: 'busts in silhouette',
    shortname: ':busts_in_silhouette:',
    unicode: '1f465',
    html: '&#128101;',
    category: 'People & Body (person-symbol)',
    order: '767',
  },
  {
    emoji: '🐞',
    name: 'lady beetle',
    shortname: ':beetle:',
    unicode: '1f41e',
    html: '&#128030;',
    category: 'Animals & Nature (animal-bug)',
    order: '1423',
  },
  {
    emoji: '🔻',
    name: 'red triangle pointed down',
    shortname: ':small_red_triangle_down:',
    unicode: '1f53b',
    html: '&#128315;',
    category: 'Symbols (geometric)',
    order: '2172',
  },
  {
    emoji: '🇩🇪',
    name: 'flag: Germany',
    shortname: ':ger:',
    unicode: '1F1E9 1F1EA',
    html: '&#127465;',
    category: 'Flags (country-flag)',
    order: '',
  },
  {
    emoji: '⤴️',
    name: 'right arrow curving up',
    shortname: ':arrow_heading_up:',
    unicode: '2934',
    html: '&#10548;',
    category: 'Symbols (arrow)',
    order: '2014',
  },
  {
    emoji: '📛',
    name: 'name badge',
    shortname: ':name_badge:',
    unicode: '1f4db',
    html: '&#128219;',
    category: 'Symbols (other-symbol)',
    order: '2073',
  },
  {
    emoji: '🛀🏿',
    name: 'person taking bath: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6C0 1F3FF',
    html: '&#128704;&#127999;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🛀🏾',
    name: 'person taking bath: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6C0 1F3FE',
    html: '&#128704;&#127998;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🛀🏽',
    name: 'person taking bath: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6C0 1F3FD',
    html: '&#128704;&#127997;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🛀🏼',
    name: 'person taking bath: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6C0 1F3FC',
    html: '&#128704;&#127996;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🛀🏻',
    name: 'person taking bath: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6C0 1F3FB',
    html: '&#128704;&#127995;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🛀',
    name: 'person taking bath',
    shortname: ':bath:',
    unicode: '1f6c0',
    html: '&#128704;',
    category: 'People & Body (person-resting)',
    order: '1673',
  },
  {
    emoji: '⛔',
    name: 'no entry',
    shortname: ':no_entry:',
    unicode: '26d4',
    html: '&#9940;',
    category: 'Symbols (warning)',
    order: '1991',
  },
  {
    emoji: '🐊',
    name: 'crocodile',
    shortname: ':crocodile:',
    unicode: '1f40a',
    html: '&#128010;',
    category: 'Animals & Nature (animal-reptile)',
    order: '1400',
  },
  {
    emoji: '🌰',
    name: 'chestnut',
    shortname: ':chestnut:',
    unicode: '1F330',
    html: '&#127792;',
    category: 'Food & Drink (food-vegetable)',
    order: '',
  },
  {
    emoji: '🐕',
    name: 'dog',
    shortname: ':dog2:',
    unicode: '1f415',
    html: '&#128021;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1346',
  },
  {
    emoji: '🐈',
    name: 'cat',
    shortname: ':cat2:',
    unicode: '1f408',
    html: '&#128008;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1351',
  },
  {
    emoji: '🔨',
    name: 'hammer',
    shortname: ':hammer:',
    unicode: '1f528',
    html: '&#128296;',
    category: 'Objects (tool)',
    order: '1950',
  },
  {
    emoji: '🍖',
    name: 'meat on bone',
    shortname: ':meat_on_bone:',
    unicode: '1f356',
    html: '&#127830;',
    category: 'Food & Drink (food-prepared)',
    order: '1479',
  },
  {
    emoji: '🐚',
    name: 'spiral shell',
    shortname: ':shell:',
    unicode: '1f41a',
    html: '&#128026;',
    category: 'Animals & Nature (animal-marine)',
    order: '1414',
  },
  {
    emoji: '❇️',
    name: 'sparkle',
    shortname: ':sparkle:',
    unicode: '2747',
    html: '&#10055;',
    category: 'Symbols (other-symbol)',
    order: '2095',
  },
  {
    emoji: '⛵',
    name: 'sailboat',
    shortname: ':sailboat:',
    unicode: '26F5',
    html: '&#9973;',
    category: 'Travel & Places (transport-water)',
    order: '',
  },
  {
    emoji: '🅱️',
    name: 'B button (blood type)',
    shortname: ':b:',
    unicode: '1f171',
    html: '&#127345;',
    category: 'Symbols (alphanum)',
    order: '2127',
  },
  {
    emoji: 'Ⓜ️',
    name: 'circled M',
    shortname: ':m:',
    unicode: '24c2',
    html: '&#9410;',
    category: 'Symbols (alphanum)',
    order: '2133',
  },
  {
    emoji: '🐩',
    name: 'poodle',
    shortname: ':poodle:',
    unicode: '1f429',
    html: '&#128041;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1347',
  },
  {
    emoji: '♒',
    name: 'Aquarius',
    shortname: ':aquarius:',
    unicode: '2652',
    html: '&#9810;',
    category: 'Symbols (zodiac)',
    order: '2045',
  },
  {
    emoji: '🍲',
    name: 'pot of food',
    shortname: ':stew:',
    unicode: '1f372',
    html: '&#127858;',
    category: 'Food & Drink (food-prepared)',
    order: '1492',
  },
  {
    emoji: '👖',
    name: 'jeans',
    shortname: ':jeans:',
    unicode: '1f456',
    html: '&#128086;',
    category: 'Objects (clothing)',
    order: '1318',
  },
  {
    emoji: '🍯',
    name: 'honey pot',
    shortname: ':honey_pot:',
    unicode: '1f36f',
    html: '&#127855;',
    category: 'Food & Drink (food-sweet)',
    order: '1519',
  },
  {
    emoji: '🎹',
    name: 'musical keyboard',
    shortname: ':musical_keyboard:',
    unicode: '1f3b9',
    html: '&#127929;',
    category: 'Objects (musical-instrument)',
    order: '1834',
  },
  {
    emoji: '🔓',
    name: 'unlocked',
    shortname: ':unlock:',
    unicode: '1f513',
    html: '&#128275;',
    category: 'Objects (lock)',
    order: '1945',
  },
  {
    emoji: '✒',
    name: 'black nib',
    shortname: ':black_nib:',
    unicode: '2712',
    html: '&#10002;',
    category: 'Objects (writing)',
    order: '1915',
  },
  {
    emoji: '🗽',
    name: 'Statue of Liberty',
    shortname: ':statue_of_liberty:',
    unicode: '1f5fd',
    html: '&#128509;',
    category: 'Travel & Places (place-building)',
    order: '1576',
  },
  {
    emoji: '💲',
    name: 'heavy dollar sign',
    shortname: ':heavy_dollar_sign:',
    unicode: '1f4b2',
    html: '&#128178;',
    category: 'Objects (money)',
    order: '1900',
  },
  {
    emoji: '🏂',
    name: 'snowboarder',
    shortname: ':snowboarder:',
    unicode: '1f3c2',
    html: '&#127938;',
    category: 'People & Body (person-sport)',
    order: '776',
  },
  {
    emoji: '💮',
    name: 'white flower',
    shortname: ':white_flower:',
    unicode: '1f4ae',
    html: '&#128174;',
    category: 'Animals & Nature (plant-flower)',
    order: '1429',
  },
  {
    emoji: '👔',
    name: 'necktie',
    shortname: ':necktie:',
    unicode: '1f454',
    html: '&#128084;',
    category: 'Objects (clothing)',
    order: '1316',
  },
  {
    emoji: '💠',
    name: 'diamond with a dot',
    shortname: ':diamond_shape_with_a_dot_inside:',
    unicode: '1f4a0',
    html: '&#128160;',
    category: 'Symbols (geometric)',
    order: '2173',
  },
  {
    emoji: '♈',
    name: 'Aries',
    shortname: ':aries:',
    unicode: '2648',
    html: '&#9800;',
    category: 'Symbols (zodiac)',
    order: '2035',
  },
  {
    emoji: '🚺',
    name: 'women’s room',
    shortname: ':womens:',
    unicode: '1f6ba',
    html: '&#128698;',
    category: 'Symbols (transport-sign)',
    order: '1981',
  },
  {
    emoji: '🐜',
    name: 'ant',
    shortname: ':ant:',
    unicode: '1f41c',
    html: '&#128028;',
    category: 'Animals & Nature (animal-bug)',
    order: '1421',
  },
  {
    emoji: '♏',
    name: 'Scorpio',
    shortname: ':scorpius:',
    unicode: '264f',
    html: '&#9807;',
    category: 'Symbols (zodiac)',
    order: '2042',
  },
  {
    emoji: '🌇',
    name: 'sunset',
    shortname: ':city_sunset:',
    unicode: '1f307',
    html: '&#127751;',
    category: 'Travel & Places (place-other)',
    order: '1589',
  },
  {
    emoji: '⏳',
    name: 'hourglass not done',
    shortname: ':hourglass_flowing_sand:',
    unicode: '23f3',
    html: '&#9203;',
    category: 'Travel & Places (time)',
    order: '1681',
  },
  {
    emoji: '🅾️',
    name: 'O button (blood type)',
    shortname: ':o2:',
    unicode: '1f17e',
    html: '&#127358;',
    category: 'Symbols (alphanum)',
    order: '2136',
  },
  {
    emoji: '🐲',
    name: 'dragon face',
    shortname: ':dragon_face:',
    unicode: '1f432',
    html: '&#128050;',
    category: 'Animals & Nature (animal-reptile)',
    order: '1404',
  },
  {
    emoji: '🐌',
    name: 'snail',
    shortname: ':snail:',
    unicode: '1f40c',
    html: '&#128012;',
    category: 'Animals & Nature (animal-bug)',
    order: '1419',
  },
  {
    emoji: '📀',
    name: 'dvd',
    shortname: ':dvd:',
    unicode: '1f4c0',
    html: '&#128192;',
    category: 'Objects (computer)',
    order: '1855',
  },
  {
    emoji: '👕',
    name: 't-shirt',
    shortname: ':shirt:',
    unicode: '1f455',
    html: '&#128085;',
    category: 'Objects (clothing)',
    order: '1317',
  },
  {
    emoji: '🎲',
    name: 'game die',
    shortname: ':game_die:',
    unicode: '1f3b2',
    html: '&#127922;',
    category: 'Activities (game)',
    order: '1806',
  },
  {
    emoji: '➖',
    name: 'minus sign',
    shortname: ':heavy_minus_sign:',
    unicode: '2796',
    html: '&#10134;',
    category: 'Symbols (other-symbol)',
    order: '2088',
  },
  {
    emoji: '🎎',
    name: 'Japanese dolls',
    shortname: ':dolls:',
    unicode: '1f38e',
    html: '&#127886;',
    category: 'Activities (event)',
    order: '1766',
  },
  {
    emoji: '♐',
    name: 'Sagittarius',
    shortname: ':sagittarius:',
    unicode: '2650',
    html: '&#9808;',
    category: 'Symbols (zodiac)',
    order: '2043',
  },
  {
    emoji: '🎱',
    name: 'pool 8 ball',
    shortname: ':8ball:',
    unicode: '1f3b1',
    html: '&#127921;',
    category: 'Activities (game)',
    order: '1788',
  },
  {
    emoji: '🚌',
    name: 'bus',
    shortname: ':bus:',
    unicode: '1f68c',
    html: '&#128652;',
    category: 'Travel & Places (transport-ground)',
    order: '1614',
  },
  {
    emoji: '🍮',
    name: 'custard',
    shortname: ':custard:',
    unicode: '1f36e',
    html: '&#127854;',
    category: 'Food & Drink (food-sweet)',
    order: '1518',
  },
  {
    emoji: '🎌',
    name: 'crossed flags',
    shortname: ':crossed_flags:',
    unicode: '1f38c',
    html: '&#127884;',
    category: 'Flags (flag)',
    order: '2183',
  },
  {
    emoji: '〽️',
    name: 'part alternation mark',
    shortname: ':part_alternation_mark:',
    unicode: '303d',
    html: '&#12349;',
    category: 'Symbols (other-symbol)',
    order: '2092',
  },
  {
    emoji: '🐫',
    name: 'two-hump camel',
    shortname: ':camel:',
    unicode: '1f42b',
    html: '&#128043;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1372',
  },
  {
    emoji: '🍛',
    name: 'curry rice',
    shortname: ':curry:',
    unicode: '1f35b',
    html: '&#127835;',
    category: 'Food & Drink (food-asian)',
    order: '1499',
  },
  {
    emoji: '🚂',
    name: 'locomotive',
    shortname: ':steam_locomotive:',
    unicode: '1f682',
    html: '&#128642;',
    category: 'Travel & Places (transport-ground)',
    order: '1602',
  },
  {
    emoji: '🏥',
    name: 'hospital',
    shortname: ':hospital:',
    unicode: '1f3e5',
    html: '&#127973;',
    category: 'Travel & Places (place-building)',
    order: '1564',
  },
  {
    emoji: '🇯🇵',
    name: 'flag: Japan',
    shortname: ':jp:',
    unicode: '1F1EF 1F1F5',
    html: '&#127471;',
    category: 'Flags (country-flag)',
    order: '',
  },
  {
    emoji: '🔷',
    name: 'large blue diamond',
    shortname: ':large_blue_diamond:',
    unicode: '1f537',
    html: '&#128311;',
    category: 'Symbols (geometric)',
    order: '2168',
  },
  {
    emoji: '🎋',
    name: 'tanabata tree',
    shortname: ':tanabata_tree:',
    unicode: '1f38b',
    html: '&#127883;',
    category: 'Activities (event)',
    order: '1764',
  },
  {
    emoji: '🔔',
    name: 'bell',
    shortname: ':bell:',
    unicode: '1f514',
    html: '&#128276;',
    category: 'Objects (sound)',
    order: '1821',
  },
  {
    emoji: '♌',
    name: 'Leo',
    shortname: ':leo:',
    unicode: '264c',
    html: '&#9804;',
    category: 'Symbols (zodiac)',
    order: '2039',
  },
  {
    emoji: '♊',
    name: 'Gemini',
    shortname: ':gemini:',
    unicode: '264a',
    html: '&#9802;',
    category: 'Symbols (zodiac)',
    order: '2037',
  },
  {
    emoji: '🍐',
    name: 'pear',
    shortname: ':pear:',
    unicode: '1f350',
    html: '&#127824;',
    category: 'Food & Drink (food-fruit)',
    order: '1458',
  },
  {
    emoji: '🔶',
    name: 'large orange diamond',
    shortname: ':large_orange_diamond:',
    unicode: '1f536',
    html: '&#128310;',
    category: 'Symbols (geometric)',
    order: '2167',
  },
  {
    emoji: '♉',
    name: 'Taurus',
    shortname: ':taurus:',
    unicode: '2649',
    html: '&#9801;',
    category: 'Symbols (zodiac)',
    order: '2036',
  },
  {
    emoji: '🌐',
    name: 'globe with meridians',
    shortname: ':globe_with_meridians:',
    unicode: '1f310',
    html: '&#127760;',
    category: 'Travel & Places (place-map)',
    order: '1541',
  },
  {
    emoji: '🚪',
    name: 'door',
    shortname: ':door:',
    unicode: '1f6aa',
    html: '&#128682;',
    category: 'Objects (household)',
    order: '1662',
  },
  {
    emoji: '🕕',
    name: 'six o’clock',
    shortname: ':clock6:',
    unicode: '1f555',
    html: '&#128341;',
    category: 'Travel & Places (time)',
    order: '1699',
  },
  {
    emoji: '🚔',
    name: 'oncoming police car',
    shortname: ':oncoming_police_car:',
    unicode: '1f694',
    html: '&#128660;',
    category: 'Travel & Places (transport-ground)',
    order: '1621',
  },
  {
    emoji: '📩',
    name: 'envelope with arrow',
    shortname: ':envelope_with_arrow:',
    unicode: '1f4e9',
    html: '&#128233;',
    category: 'Objects (mail)',
    order: '1904',
  },
  {
    emoji: '🌂',
    name: 'closed umbrella',
    shortname: ':closed_umbrella:',
    unicode: '1f302',
    html: '&#127746;',
    category: 'Travel & Places (sky & weather)',
    order: '1744',
  },
  {
    emoji: '🎷',
    name: 'saxophone',
    shortname: ':saxophone:',
    unicode: '1f3b7',
    html: '&#127927;',
    category: 'Objects (musical-instrument)',
    order: '1832',
  },
  {
    emoji: '⛪',
    name: 'church',
    shortname: ':church:',
    unicode: '26ea',
    html: '&#9962;',
    category: 'Travel & Places (place-religious)',
    order: '1577',
  },
  {
    emoji: '🚴🏿‍♀',
    name: 'woman biking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B4 1F3FF 200D 2640',
    html: '&#128692;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏾‍♀',
    name: 'woman biking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B4 1F3FE 200D 2640',
    html: '&#128692;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏽‍♀',
    name: 'woman biking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B4 1F3FD 200D 2640',
    html: '&#128692;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏼‍♀',
    name: 'woman biking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B4 1F3FC 200D 2640',
    html: '&#128692;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏻‍♀',
    name: 'woman biking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B4 1F3FB 200D 2640',
    html: '&#128692;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴‍♀',
    name: 'woman biking',
    shortname: ':woman_biking:',
    unicode: '1F6B4 200D 2640',
    html: '&#128692;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏿',
    name: 'person biking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B4 1F3FF',
    html: '&#128692;&#127999;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏾',
    name: 'person biking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B4 1F3FE',
    html: '&#128692;&#127998;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏽',
    name: 'person biking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B4 1F3FD',
    html: '&#128692;&#127997;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏼',
    name: 'person biking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B4 1F3FC',
    html: '&#128692;&#127996;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏻',
    name: 'person biking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B4 1F3FB',
    html: '&#128692;&#127995;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴',
    name: 'person biking',
    shortname: ':bicyclist:',
    unicode: '1f6b4',
    html: '&#128692;',
    category: 'People & Body (person-sport)',
    order: '890',
  },
  {
    emoji: '♓',
    name: 'Pisces',
    shortname: ':pisces:',
    unicode: '2653',
    html: '&#9811;',
    category: 'Symbols (zodiac)',
    order: '2046',
  },
  {
    emoji: '🍡',
    name: 'dango',
    shortname: ':dango:',
    unicode: '1f361',
    html: '&#127841;',
    category: 'Food & Drink (food-asian)',
    order: '1507',
  },
  {
    emoji: '♑',
    name: 'Capricorn',
    shortname: ':capricorn:',
    unicode: '2651',
    html: '&#9809;',
    category: 'Symbols (zodiac)',
    order: '2044',
  },
  {
    emoji: '🏢',
    name: 'office building',
    shortname: ':office:',
    unicode: '1f3e2',
    html: '&#127970;',
    category: 'Travel & Places (place-building)',
    order: '1561',
  },
  {
    emoji: '🚣🏿‍♀',
    name: 'woman rowing boat: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6A3 1F3FF 200D 2640',
    html: '&#128675;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏾‍♀',
    name: 'woman rowing boat: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6A3 1F3FE 200D 2640',
    html: '&#128675;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏽‍♀',
    name: 'woman rowing boat: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6A3 1F3FD 200D 2640',
    html: '&#128675;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏼‍♀',
    name: 'woman rowing boat: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6A3 1F3FC 200D 2640',
    html: '&#128675;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏻‍♀',
    name: 'woman rowing boat: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6A3 1F3FB 200D 2640',
    html: '&#128675;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣‍♀',
    name: 'woman rowing boat',
    shortname: ':woman_rowing_boat:',
    unicode: '1F6A3 200D 2640',
    html: '&#128675;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏿',
    name: 'person rowing boat: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6A3 1F3FF',
    html: '&#128675;&#127999;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏾',
    name: 'person rowing boat: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6A3 1F3FE',
    html: '&#128675;&#127998;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏽',
    name: 'person rowing boat: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6A3 1F3FD',
    html: '&#128675;&#127997;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏼',
    name: 'person rowing boat: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6A3 1F3FC',
    html: '&#128675;&#127996;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏻',
    name: 'person rowing boat: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6A3 1F3FB',
    html: '&#128675;&#127995;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣',
    name: 'person rowing boat',
    shortname: ':rowboat:',
    unicode: '1f6a3',
    html: '&#128675;',
    category: 'People & Body (person-sport)',
    order: '818',
  },
  {
    emoji: '👒',
    name: 'woman’s hat',
    shortname: ':womans_hat:',
    unicode: '1f452',
    html: '&#128082;',
    category: 'Objects (clothing)',
    order: '1334',
  },
  {
    emoji: '👞',
    name: 'man’s shoe',
    shortname: ':mans_shoe:',
    unicode: '1f45e',
    html: '&#128094;',
    category: 'Objects (clothing)',
    order: '1328',
  },
  {
    emoji: '🏩',
    name: 'love hotel',
    shortname: ':love_hotel:',
    unicode: '1f3e9',
    html: '&#127977;',
    category: 'Travel & Places (place-building)',
    order: '1567',
  },
  {
    emoji: '🗻',
    name: 'mount fuji',
    shortname: ':mount_fuji:',
    unicode: '1f5fb',
    html: '&#128507;',
    category: 'Travel & Places (place-geographic)',
    order: '1547',
  },
  {
    emoji: '🐪',
    name: 'camel',
    shortname: ':dromedary_camel:',
    unicode: '1f42a',
    html: '&#128042;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1371',
  },
  {
    emoji: '👜',
    name: 'handbag',
    shortname: ':handbag:',
    unicode: '1f45c',
    html: '&#128092;',
    category: 'Objects (clothing)',
    order: '1324',
  },
  {
    emoji: '⌛',
    name: 'hourglass done',
    shortname: ':hourglass:',
    unicode: '231b',
    html: '&#8987;',
    category: 'Travel & Places (time)',
    order: '1680',
  },
  {
    emoji: '❎',
    name: 'cross mark button',
    shortname: ':negative_squared_cross_mark:',
    unicode: '274e',
    html: '&#10062;',
    category: 'Symbols (other-symbol)',
    order: '2083',
  },
  {
    emoji: '🎺',
    name: 'trumpet',
    shortname: ':trumpet:',
    unicode: '1f3ba',
    html: '&#127930;',
    category: 'Objects (musical-instrument)',
    order: '1835',
  },
  {
    emoji: '🏫',
    name: 'school',
    shortname: ':school:',
    unicode: '1f3eb',
    html: '&#127979;',
    category: 'Travel & Places (place-building)',
    order: '1569',
  },
  {
    emoji: '🐄',
    name: 'cow',
    shortname: ':cow2:',
    unicode: '1f404',
    html: '&#128004;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1363',
  },
  {
    emoji: '🌆',
    name: 'cityscape at dusk',
    shortname: ':cityscape_at_dusk:',
    unicode: '1F306',
    html: '&#127750;',
    category: 'Travel & Places (place-other)',
    order: '',
  },
  {
    emoji: '👷🏿‍♀',
    name: 'woman construction worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F477 1F3FF 200D 2640',
    html: '&#128119;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏾‍♀',
    name: 'woman construction worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F477 1F3FE 200D 2640',
    html: '&#128119;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏽‍♀',
    name: 'woman construction worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F477 1F3FD 200D 2640',
    html: '&#128119;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏼‍♀',
    name: 'woman construction worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F477 1F3FC 200D 2640',
    html: '&#128119;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏻‍♀',
    name: 'woman construction worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F477 1F3FB 200D 2640',
    html: '&#128119;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷‍♀',
    name: 'woman construction worker',
    shortname: ':woman_construction_worker:',
    unicode: '1F477 200D 2640',
    html: '&#128119;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏿',
    name: 'construction worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F477 1F3FF',
    html: '&#128119;&#127999;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏾',
    name: 'construction worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F477 1F3FE',
    html: '&#128119;&#127998;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏽',
    name: 'construction worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F477 1F3FD',
    html: '&#128119;&#127997;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏼',
    name: 'construction worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F477 1F3FC',
    html: '&#128119;&#127996;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏻',
    name: 'construction worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F477 1F3FB',
    html: '&#128119;&#127995;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷',
    name: 'construction worker',
    shortname: ':construction_worker:',
    unicode: '1f477',
    html: '&#128119;',
    category: 'People & Body (person-role)',
    order: '393',
  },
  {
    emoji: '🚽',
    name: 'toilet',
    shortname: ':toilet:',
    unicode: '1f6bd',
    html: '&#128701;',
    category: 'Objects (household)',
    order: '1671',
  },
  {
    emoji: '🐖',
    name: 'pig',
    shortname: ':pig2:',
    unicode: '1f416',
    html: '&#128022;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1365',
  },
  {
    emoji: '❔',
    name: 'white question mark',
    shortname: ':grey_question:',
    unicode: '2754',
    html: '&#10068;',
    category: 'Symbols (other-symbol)',
    order: '2099',
  },
  {
    emoji: '🔰',
    name: 'Japanese symbol for beginner',
    shortname: ':beginner:',
    unicode: '1f530',
    html: '&#128304;',
    category: 'Symbols (other-symbol)',
    order: '2075',
  },
  {
    emoji: '🎻',
    name: 'violin',
    shortname: ':violin:',
    unicode: '1f3bb',
    html: '&#127931;',
    category: 'Objects (musical-instrument)',
    order: '1836',
  },
  {
    emoji: '🔛',
    name: 'ON! arrow',
    shortname: ':on:',
    unicode: '1f51b',
    html: '&#128283;',
    category: 'Symbols (arrow)',
    order: '2020',
  },
  {
    emoji: '💳',
    name: 'credit card',
    shortname: ':credit_card:',
    unicode: '1f4b3',
    html: '&#128179;',
    category: 'Objects (money)',
    order: '1897',
  },
  {
    emoji: '🆔',
    name: 'ID button',
    shortname: ':id:',
    unicode: '1f194',
    html: '&#127380;',
    category: 'Symbols (alphanum)',
    order: '2132',
  },
  {
    emoji: '㊙',
    name: 'Japanese secret button',
    shortname: ':secret:',
    unicode: '3299',
    html: '&#12953;',
    category: 'Symbols (alphanum)',
    order: '2156',
  },
  {
    emoji: '🎡',
    name: 'ferris wheel',
    shortname: ':ferris_wheel:',
    unicode: '1f3a1',
    html: '&#127905;',
    category: 'Travel & Places (place-other)',
    order: '1594',
  },
  {
    emoji: '🎳',
    name: 'bowling',
    shortname: ':bowling:',
    unicode: '1f3b3',
    html: '&#127923;',
    category: 'Activities (sport)',
    order: '1789',
  },
  {
    emoji: '♎',
    name: 'Libra',
    shortname: ':libra:',
    unicode: '264e',
    html: '&#9806;',
    category: 'Symbols (zodiac)',
    order: '2041',
  },
  {
    emoji: '♍',
    name: 'Virgo',
    shortname: ':virgo:',
    unicode: '264d',
    html: '&#9805;',
    category: 'Symbols (zodiac)',
    order: '2040',
  },
  {
    emoji: '💈',
    name: 'barber pole',
    shortname: ':barber:',
    unicode: '1f488',
    html: '&#128136;',
    category: 'Travel & Places (place-other)',
    order: '1596',
  },
  {
    emoji: '👛',
    name: 'purse',
    shortname: ':purse:',
    unicode: '1f45b',
    html: '&#128091;',
    category: 'Objects (clothing)',
    order: '1323',
  },
  {
    emoji: '🎢',
    name: 'roller coaster',
    shortname: ':roller_coaster:',
    unicode: '1f3a2',
    html: '&#127906;',
    category: 'Travel & Places (place-other)',
    order: '1595',
  },
  {
    emoji: '🐀',
    name: 'rat',
    shortname: ':rat:',
    unicode: '1f400',
    html: '&#128000;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1377',
  },
  {
    emoji: '📅',
    name: 'calendar',
    shortname: ':date:',
    unicode: '1f4c5',
    html: '&#128197;',
    category: 'Objects (office)',
    order: '1925',
  },
  {
    emoji: '🏉',
    name: 'rugby football',
    shortname: ':rugby_football:',
    unicode: '1f3c9',
    html: '&#127945;',
    category: 'Activities (sport)',
    order: '1786',
  },
  {
    emoji: '🐏',
    name: 'ram',
    shortname: ':ram:',
    unicode: '1f40f',
    html: '&#128015;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1368',
  },
  {
    emoji: '🔼',
    name: 'upwards button',
    shortname: ':arrow_up_small:',
    unicode: '1f53c',
    html: '&#128316;',
    category: 'Symbols (av-symbol)',
    order: '2058',
  },
  {
    emoji: '🔲',
    name: 'black square button',
    shortname: ':black_square_button:',
    unicode: '1f532',
    html: '&#128306;',
    category: 'Symbols (geometric)',
    order: '2175',
  },
  {
    emoji: '📴',
    name: 'mobile phone off',
    shortname: ':mobile_phone_off:',
    unicode: '1f4f4',
    html: '&#128244;',
    category: 'Symbols (av-symbol)',
    order: '2071',
  },
  {
    emoji: '🗼',
    name: 'Tokyo tower',
    shortname: ':tokyo_tower:',
    unicode: '1f5fc',
    html: '&#128508;',
    category: 'Travel & Places (place-building)',
    order: '1575',
  },
  {
    emoji: '㊗',
    name: 'Japanese congratulations button',
    shortname: ':congratulations:',
    unicode: '3297',
    html: '&#12951;',
    category: 'Symbols (alphanum)',
    order: '2155',
  },
  {
    emoji: '👘',
    name: 'kimono',
    shortname: ':kimono:',
    unicode: '1f458',
    html: '&#128088;',
    category: 'Objects (clothing)',
    order: '1320',
  },
  {
    emoji: '🇷🇺',
    name: 'flag: Russia',
    shortname: ':ru:',
    unicode: '1F1F7 1F1FA',
    html: '&#127479;',
    category: 'Flags (country-flag)',
    order: '',
  },
  {
    emoji: '🚢',
    name: 'ship',
    shortname: ':ship:',
    unicode: '1f6a2',
    html: '&#128674;',
    category: 'Travel & Places (transport-water)',
    order: '1649',
  },
  {
    emoji: '🔎',
    name: 'magnifying glass tilted right',
    shortname: ':mag_right:',
    unicode: '1f50e',
    html: '&#128270;',
    category: 'Objects (light & video)',
    order: '1866',
  },
  {
    emoji: '🔍',
    name: 'magnifying glass tilted left',
    shortname: ':mag:',
    unicode: '1f50d',
    html: '&#128269;',
    category: 'Objects (light & video)',
    order: '1865',
  },
  {
    emoji: '🚒',
    name: 'fire engine',
    shortname: ':fire_engine:',
    unicode: '1f692',
    html: '&#128658;',
    category: 'Travel & Places (transport-ground)',
    order: '1619',
  },
  {
    emoji: '🕦',
    name: 'eleven-thirty',
    shortname: ':clock1130:',
    unicode: '1f566',
    html: '&#128358;',
    category: 'Travel & Places (time)',
    order: '1710',
  },
  {
    emoji: '🚓',
    name: 'police car',
    shortname: ':police_car:',
    unicode: '1f693',
    html: '&#128659;',
    category: 'Travel & Places (transport-ground)',
    order: '1620',
  },
  {
    emoji: '🃏',
    name: 'joker',
    shortname: ':black_joker:',
    unicode: '1f0cf',
    html: '&#127183;',
    category: 'Activities (game)',
    order: '1811',
  },
  {
    emoji: '🌉',
    name: 'bridge at night',
    shortname: ':bridge_at_night:',
    unicode: '1f309',
    html: '&#127753;',
    category: 'Travel & Places (place-other)',
    order: '1590',
  },
  {
    emoji: '📦',
    name: 'package',
    shortname: ':package:',
    unicode: '1f4e6',
    html: '&#128230;',
    category: 'Objects (mail)',
    order: '1907',
  },
  {
    emoji: '🚖',
    name: 'oncoming taxi',
    shortname: ':oncoming_taxi:',
    unicode: '1f696',
    html: '&#128662;',
    category: 'Travel & Places (transport-ground)',
    order: '1623',
  },
  {
    emoji: '📆',
    name: 'tear-off calendar',
    shortname: ':calendar:',
    unicode: '1f4c6',
    html: '&#128198;',
    category: 'Objects (office)',
    order: '1926',
  },
  {
    emoji: '🏇',
    name: 'horse racing',
    shortname: ':horse_racing:',
    unicode: '1f3c7',
    html: '&#127943;',
    category: 'People & Body (person-sport)',
    order: '769',
  },
  {
    emoji: '🐅',
    name: 'tiger',
    shortname: ':tiger2:',
    unicode: '1f405',
    html: '&#128005;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1354',
  },
  {
    emoji: '👢',
    name: 'woman’s boot',
    shortname: ':boot:',
    unicode: '1f462',
    html: '&#128098;',
    category: 'Objects (clothing)',
    order: '1332',
  },
  {
    emoji: '🚑',
    name: 'ambulance',
    shortname: ':ambulance:',
    unicode: '1f691',
    html: '&#128657;',
    category: 'Travel & Places (transport-ground)',
    order: '1618',
  },
  {
    emoji: '🔳',
    name: 'white square button',
    shortname: ':white_square_button:',
    unicode: '1f533',
    html: '&#128307;',
    category: 'Symbols (geometric)',
    order: '2176',
  },
  {
    emoji: '🐗',
    name: 'boar',
    shortname: ':boar:',
    unicode: '1f417',
    html: '&#128023;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1366',
  },
  {
    emoji: '🎒',
    name: 'backpack',
    shortname: ':school_satchel:',
    unicode: '1f392',
    html: '&#127890;',
    category: 'Objects (clothing)',
    order: '1327',
  },
  {
    emoji: '➿',
    name: 'double curly loop',
    shortname: ':loop:',
    unicode: '27bf',
    html: '&#10175;',
    category: 'Symbols (other-symbol)',
    order: '2091',
  },
  {
    emoji: '💷',
    name: 'pound banknote',
    shortname: ':pound:',
    unicode: '1f4b7',
    html: '&#128183;',
    category: 'Objects (money)',
    order: '1895',
  },
  {
    emoji: 'ℹ',
    name: 'information',
    shortname: ':information_source:',
    unicode: '2139',
    html: '&#8505;',
    category: 'Symbols (alphanum)',
    order: '2131',
  },
  {
    emoji: '🐂',
    name: 'ox',
    shortname: ':ox:',
    unicode: '1f402',
    html: '&#128002;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1361',
  },
  {
    emoji: '🍙',
    name: 'rice ball',
    shortname: ':rice_ball:',
    unicode: '1f359',
    html: '&#127833;',
    category: 'Food & Drink (food-asian)',
    order: '1497',
  },
  {
    emoji: '🆚',
    name: 'VS button',
    shortname: ':vs:',
    unicode: '1f19a',
    html: '&#127386;',
    category: 'Symbols (alphanum)',
    order: '2141',
  },
  {
    emoji: '🔚',
    name: 'END arrow',
    shortname: ':end:',
    unicode: '1f51a',
    html: '&#128282;',
    category: 'Symbols (arrow)',
    order: '2019',
  },
  {
    emoji: '🅿️',
    name: 'P button',
    shortname: ':parking:',
    unicode: '1f17f',
    html: '&#127359;',
    category: 'Symbols (alphanum)',
    order: '2138',
  },
  {
    emoji: '👡',
    name: 'woman’s sandal',
    shortname: ':sandal:',
    unicode: '1f461',
    html: '&#128097;',
    category: 'Objects (clothing)',
    order: '1331',
  },
  {
    emoji: '⛺',
    name: 'tent',
    shortname: ':tent:',
    unicode: '26fa',
    html: '&#9978;',
    category: 'Travel & Places (place-other)',
    order: '1583',
  },
  {
    emoji: '💺',
    name: 'seat',
    shortname: ':seat:',
    unicode: '1f4ba',
    html: '&#128186;',
    category: 'Travel & Places (transport-air)',
    order: '1654',
  },
  {
    emoji: '🚕',
    name: 'taxi',
    shortname: ':taxi:',
    unicode: '1f695',
    html: '&#128661;',
    category: 'Travel & Places (transport-ground)',
    order: '1622',
  },
  {
    emoji: '◾',
    name: 'black medium-small square',
    shortname: ':black_medium_small_square:',
    unicode: '25fe',
    html: '&#9726;',
    category: 'Symbols (geometric)',
    order: '2164',
  },
  {
    emoji: '💼',
    name: 'briefcase',
    shortname: ':briefcase:',
    unicode: '1f4bc',
    html: '&#128188;',
    category: 'Objects (office)',
    order: '1921',
  },
  {
    emoji: '📰',
    name: 'newspaper',
    shortname: ':newspaper:',
    unicode: '1f4f0',
    html: '&#128240;',
    category: 'Objects (book-paper)',
    order: '1886',
  },
  {
    emoji: '🎪',
    name: 'circus tent',
    shortname: ':circus_tent:',
    unicode: '1f3aa',
    html: '&#127914;',
    category: 'Travel & Places (place-other)',
    order: '1597',
  },
  {
    emoji: '🔯',
    name: 'dotted six-pointed star',
    shortname: ':six_pointed_star:',
    unicode: '1f52f',
    html: '&#128303;',
    category: 'Symbols (religion)',
    order: '2034',
  },
  {
    emoji: '🚹',
    name: 'men’s room',
    shortname: ':mens:',
    unicode: '1f6b9',
    html: '&#128697;',
    category: 'Symbols (transport-sign)',
    order: '1980',
  },
  {
    emoji: '🏰',
    name: 'castle',
    shortname: ':european_castle:',
    unicode: '1f3f0',
    html: '&#127984;',
    category: 'Travel & Places (place-building)',
    order: '1573',
  },
  {
    emoji: '🔦',
    name: 'flashlight',
    shortname: ':flashlight:',
    unicode: '1f526',
    html: '&#128294;',
    category: 'Objects (light & video)',
    order: '1872',
  },
  {
    emoji: '🌁',
    name: 'foggy',
    shortname: ':foggy:',
    unicode: '1f301',
    html: '&#127745;',
    category: 'Travel & Places (place-other)',
    order: '1584',
  },
  {
    emoji: '⏫',
    name: 'fast up button',
    shortname: ':arrow_double_up:',
    unicode: '23eb',
    html: '&#9195;',
    category: 'Symbols (av-symbol)',
    order: '2059',
  },
  {
    emoji: '🎍',
    name: 'pine decoration',
    shortname: ':bamboo:',
    unicode: '1f38d',
    html: '&#127885;',
    category: 'Activities (event)',
    order: '1765',
  },
  {
    emoji: '🎫',
    name: 'ticket',
    shortname: ':ticket:',
    unicode: '1f3ab',
    html: '&#127915;',
    category: 'Activities (event)',
    order: '1774',
  },
  {
    emoji: '🚁',
    name: 'helicopter',
    shortname: ':helicopter:',
    unicode: '1f681',
    html: '&#128641;',
    category: 'Travel & Places (transport-air)',
    order: '1655',
  },
  {
    emoji: '💽',
    name: 'computer disk',
    shortname: ':minidisc:',
    unicode: '1f4bd',
    html: '&#128189;',
    category: 'Objects (computer)',
    order: '1852',
  },
  {
    emoji: '🚍',
    name: 'oncoming bus',
    shortname: ':oncoming_bus:',
    unicode: '1f68d',
    html: '&#128653;',
    category: 'Travel & Places (transport-ground)',
    order: '1615',
  },
  {
    emoji: '🍈',
    name: 'melon',
    shortname: ':melon:',
    unicode: '1f348',
    html: '&#127816;',
    category: 'Food & Drink (food-fruit)',
    order: '1450',
  },
  {
    emoji: '▫',
    name: 'white small square',
    shortname: ':white_small_square:',
    unicode: '25ab',
    html: '&#9643;',
    category: 'Symbols (geometric)',
    order: '2160',
  },
  {
    emoji: '🏤',
    name: 'post office',
    shortname: ':european_post_office:',
    unicode: '1f3e4',
    html: '&#127972;',
    category: 'Travel & Places (place-building)',
    order: '1563',
  },
  {
    emoji: '🔟',
    name: 'keycap: 10',
    shortname: ':keycap_ten:',
    unicode: '1f51f',
    html: '&#128287;',
    category: 'Symbols (keycap)',
    order: '2118',
  },
  {
    emoji: '📓',
    name: 'notebook',
    shortname: ':notebook:',
    unicode: '1f4d3',
    html: '&#128211;',
    category: 'Objects (book-paper)',
    order: '1881',
  },
  {
    emoji: '🔕',
    name: 'bell with slash',
    shortname: ':no_bell:',
    unicode: '1f515',
    html: '&#128277;',
    category: 'Objects (sound)',
    order: '1822',
  },
  {
    emoji: '🍢',
    name: 'oden',
    shortname: ':oden:',
    unicode: '1f362',
    html: '&#127842;',
    category: 'Food & Drink (food-asian)',
    order: '1503',
  },
  {
    emoji: '🎏',
    name: 'carp streamer',
    shortname: ':flags:',
    unicode: '1f38f',
    html: '&#127887;',
    category: 'Activities (event)',
    order: '1767',
  },
  {
    emoji: '🎠',
    name: 'carousel horse',
    shortname: ':carousel_horse:',
    unicode: '1f3a0',
    html: '&#127904;',
    category: 'Travel & Places (place-other)',
    order: '1593',
  },
  {
    emoji: '🐡',
    name: 'blowfish',
    shortname: ':blowfish:',
    unicode: '1f421',
    html: '&#128033;',
    category: 'Animals & Nature (animal-marine)',
    order: '1411',
  },
  {
    emoji: '📈',
    name: 'chart increasing',
    shortname: ':chart_with_upwards_trend:',
    unicode: '1f4c8',
    html: '&#128200;',
    category: 'Objects (office)',
    order: '1930',
  },
  {
    emoji: '🍠',
    name: 'roasted sweet potato',
    shortname: ':sweet_potato:',
    unicode: '1f360',
    html: '&#127840;',
    category: 'Food & Drink (food-asian)',
    order: '1502',
  },
  {
    emoji: '🎿',
    name: 'skis',
    shortname: ':ski:',
    unicode: '1f3bf',
    html: '&#127935;',
    category: 'Activities (sport)',
    order: '1803',
  },
  {
    emoji: '🕛',
    name: 'twelve o’clock',
    shortname: ':clock12:',
    unicode: '1f55b',
    html: '&#128347;',
    category: 'Travel & Places (time)',
    order: '1687',
  },
  {
    emoji: '📶',
    name: 'antenna bars',
    shortname: ':signal_strength:',
    unicode: '1f4f6',
    html: '&#128246;',
    category: 'Symbols (av-symbol)',
    order: '2069',
  },
  {
    emoji: '🚧',
    name: 'construction',
    shortname: ':construction:',
    unicode: '1f6a7',
    html: '&#128679;',
    category: 'Travel & Places (transport-ground)',
    order: '1640',
  },
  { emoji: '#', name: '', shortname: '', unicode: '', html: '&#35;', category: '', order: '' },
  {
    emoji: '◼',
    name: 'black medium square',
    shortname: ':black_medium_square:',
    unicode: '25fc',
    html: '&#9724;',
    category: 'Symbols (geometric)',
    order: '2162',
  },
  {
    emoji: '📡',
    name: 'satellite antenna',
    shortname: ':satellite:',
    unicode: '1f4e1',
    html: '&#128225;',
    category: 'Objects (science)',
    order: '1869',
  },
  {
    emoji: '💶',
    name: 'euro banknote',
    shortname: ':euro:',
    unicode: '1f4b6',
    html: '&#128182;',
    category: 'Objects (money)',
    order: '1894',
  },
  {
    emoji: '👚',
    name: 'woman’s clothes',
    shortname: ':womans_clothes:',
    unicode: '1f45a',
    html: '&#128090;',
    category: 'Objects (clothing)',
    order: '1322',
  },
  {
    emoji: '📒',
    name: 'ledger',
    shortname: ':ledger:',
    unicode: '1f4d2',
    html: '&#128210;',
    category: 'Objects (book-paper)',
    order: '1882',
  },
  {
    emoji: '🐆',
    name: 'leopard',
    shortname: ':leopard:',
    unicode: '1f406',
    html: '&#128006;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1355',
  },
  {
    emoji: '🔅',
    name: 'dim button',
    shortname: ':low_brightness:',
    unicode: '1f505',
    html: '&#128261;',
    category: 'Symbols (av-symbol)',
    order: '2067',
  },
  {
    emoji: '🕒',
    name: 'three o’clock',
    shortname: ':clock3:',
    unicode: '1f552',
    html: '&#128338;',
    category: 'Travel & Places (time)',
    order: '1693',
  },
  {
    emoji: '🏬',
    name: 'department store',
    shortname: ':department_store:',
    unicode: '1f3ec',
    html: '&#127980;',
    category: 'Travel & Places (place-building)',
    order: '1570',
  },
  {
    emoji: '🚚',
    name: 'delivery truck',
    shortname: ':truck:',
    unicode: '1f69a',
    html: '&#128666;',
    category: 'Travel & Places (transport-ground)',
    order: '1627',
  },
  {
    emoji: '🍶',
    name: 'sake',
    shortname: ':sake:',
    unicode: '1f376',
    html: '&#127862;',
    category: 'Food & Drink (drink)',
    order: '1524',
  },
  {
    emoji: '🚃',
    name: 'railway car',
    shortname: ':railway_car:',
    unicode: '1f683',
    html: '&#128643;',
    category: 'Travel & Places (transport-ground)',
    order: '1603',
  },
  {
    emoji: '🚤',
    name: 'speedboat',
    shortname: ':speedboat:',
    unicode: '1f6a4',
    html: '&#128676;',
    category: 'Travel & Places (transport-water)',
    order: '1645',
  },
  {
    emoji: '🇰🇷',
    name: 'flag: South Korea',
    shortname: ':ko:',
    unicode: '1F1F0 1F1F7',
    html: '&#127472;',
    category: 'Flags (country-flag)',
    order: '',
  },
  {
    emoji: '📼',
    name: 'videocassette',
    shortname: ':vhs:',
    unicode: '1f4fc',
    html: '&#128252;',
    category: 'Objects (light & video)',
    order: '1864',
  },
  {
    emoji: '🕐',
    name: 'one o’clock',
    shortname: ':clock1:',
    unicode: '1f550',
    html: '&#128336;',
    category: 'Travel & Places (time)',
    order: '1689',
  },
  {
    emoji: '⏬',
    name: 'fast down button',
    shortname: ':arrow_double_down:',
    unicode: '23ec',
    html: '&#9196;',
    category: 'Symbols (av-symbol)',
    order: '2061',
  },
  {
    emoji: '🐃',
    name: 'water buffalo',
    shortname: ':water_buffalo:',
    unicode: '1f403',
    html: '&#128003;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1362',
  },
  {
    emoji: '🔽',
    name: 'downwards button',
    shortname: ':arrow_down_small:',
    unicode: '1f53d',
    html: '&#128317;',
    category: 'Symbols (av-symbol)',
    order: '2060',
  },
  {
    emoji: '💴',
    name: 'yen banknote',
    shortname: ':yen:',
    unicode: '1f4b4',
    html: '&#128180;',
    category: 'Objects (money)',
    order: '1892',
  },
  {
    emoji: '🔇',
    name: 'muted speaker',
    shortname: ':mute:',
    unicode: '1f507',
    html: '&#128263;',
    category: 'Objects (sound)',
    order: '1814',
  },
  {
    emoji: '🎽',
    name: 'running shirt',
    shortname: ':running_shirt_with_sash:',
    unicode: '1f3bd',
    html: '&#127933;',
    category: 'Activities (sport)',
    order: '1802',
  },
  {
    emoji: '⬜',
    name: 'white large square',
    shortname: ':white_large_square:',
    unicode: '2b1c',
    html: '&#11036;',
    category: 'Symbols (geometric)',
    order: '2166',
  },
  {
    emoji: '♿',
    name: 'wheelchair symbol',
    shortname: ':wheelchair:',
    unicode: '267f',
    html: '&#9855;',
    category: 'Symbols (transport-sign)',
    order: '1979',
  },
  {
    emoji: '🕑',
    name: 'two o’clock',
    shortname: ':clock2:',
    unicode: '1f551',
    html: '&#128337;',
    category: 'Travel & Places (time)',
    order: '1691',
  },
  {
    emoji: '📎',
    name: 'paperclip',
    shortname: ':paperclip:',
    unicode: '1f4ce',
    html: '&#128206;',
    category: 'Objects (office)',
    order: '1936',
  },
  {
    emoji: '🏧',
    name: 'ATM sign',
    shortname: ':atm:',
    unicode: '1f3e7',
    html: '&#127975;',
    category: 'Symbols (transport-sign)',
    order: '1976',
  },
  {
    emoji: '🎦',
    name: 'cinema',
    shortname: ':cinema:',
    unicode: '1f3a6',
    html: '&#127910;',
    category: 'Symbols (av-symbol)',
    order: '2066',
  },
  {
    emoji: '🔭',
    name: 'telescope',
    shortname: ':telescope:',
    unicode: '1f52d',
    html: '&#128301;',
    category: 'Objects (science)',
    order: '1868',
  },
  {
    emoji: '🎑',
    name: 'moon viewing ceremony',
    shortname: ':rice_scene:',
    unicode: '1f391',
    html: '&#127889;',
    category: 'Activities (event)',
    order: '1769',
  },
  {
    emoji: '📘',
    name: 'blue book',
    shortname: ':blue_book:',
    unicode: '1f4d8',
    html: '&#128216;',
    category: 'Objects (book-paper)',
    order: '1878',
  },
  {
    emoji: '◻️',
    name: 'white medium square',
    shortname: ':white_medium_square:',
    unicode: '25fb',
    html: '&#9723;',
    category: 'Symbols (geometric)',
    order: '2161',
  },
  {
    emoji: '📮',
    name: 'postbox',
    shortname: ':postbox:',
    unicode: '1f4ee',
    html: '&#128238;',
    category: 'Objects (mail)',
    order: '1912',
  },
  {
    emoji: '📧',
    name: 'e-mail',
    shortname: ':e-mail:',
    unicode: '1f4e7',
    html: '&#128231;',
    category: 'Objects (mail)',
    order: '1902',
  },
  {
    emoji: '🐁',
    name: 'mouse',
    shortname: ':mouse2:',
    unicode: '1f401',
    html: '&#128001;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1376',
  },
  {
    emoji: '🚄',
    name: 'high-speed train',
    shortname: ':bullettrain_side:',
    unicode: '1f684',
    html: '&#128644;',
    category: 'Travel & Places (transport-ground)',
    order: '1604',
  },
  {
    emoji: '🉐',
    name: 'Japanese bargain button',
    shortname: ':ideograph_advantage:',
    unicode: '1f250',
    html: '&#127568;',
    category: 'Symbols (alphanum)',
    order: '2147',
  },
  {
    emoji: '🔩',
    name: 'nut and bolt',
    shortname: ':nut_and_bolt:',
    unicode: '1f529',
    html: '&#128297;',
    category: 'Objects (tool)',
    order: '1960',
  },
  {
    emoji: '🆖',
    name: 'NG button',
    shortname: ':ng:',
    unicode: '1f196',
    html: '&#127382;',
    category: 'Symbols (alphanum)',
    order: '2135',
  },
  {
    emoji: '🏨',
    name: 'hotel',
    shortname: ':hotel:',
    unicode: '1f3e8',
    html: '&#127976;',
    category: 'Travel & Places (place-building)',
    order: '1566',
  },
  {
    emoji: '🚾',
    name: 'water closet',
    shortname: ':wc:',
    unicode: '1f6be',
    html: '&#128702;',
    category: 'Symbols (transport-sign)',
    order: '1984',
  },
  {
    emoji: '🏮',
    name: 'red paper lantern',
    shortname: ':izakaya_lantern:',
    unicode: '1f3ee',
    html: '&#127982;',
    category: 'Objects (light & video)',
    order: '1873',
  },
  {
    emoji: '🔂',
    name: 'repeat single button',
    shortname: ':repeat_one:',
    unicode: '1f502',
    html: '&#128258;',
    category: 'Symbols (av-symbol)',
    order: '2050',
  },
  {
    emoji: '📬',
    name: 'open mailbox with raised flag',
    shortname: ':mailbox_with_mail:',
    unicode: '1f4ec',
    html: '&#128236;',
    category: 'Objects (mail)',
    order: '1910',
  },
  {
    emoji: '📉',
    name: 'chart decreasing',
    shortname: ':chart_with_downwards_trend:',
    unicode: '1f4c9',
    html: '&#128201;',
    category: 'Objects (office)',
    order: '1931',
  },
  {
    emoji: '📗',
    name: 'green book',
    shortname: ':green_book:',
    unicode: '1f4d7',
    html: '&#128215;',
    category: 'Objects (book-paper)',
    order: '1877',
  },
  {
    emoji: '🚜',
    name: 'tractor',
    shortname: ':tractor:',
    unicode: '1f69c',
    html: '&#128668;',
    category: 'Travel & Places (transport-ground)',
    order: '1629',
  },
  {
    emoji: '⛲',
    name: 'fountain',
    shortname: ':fountain:',
    unicode: '26f2',
    html: '&#9970;',
    category: 'Travel & Places (place-other)',
    order: '1582',
  },
  {
    emoji: '🚇',
    name: 'metro',
    shortname: ':metro:',
    unicode: '1f687',
    html: '&#128647;',
    category: 'Travel & Places (transport-ground)',
    order: '1607',
  },
  {
    emoji: '📋',
    name: 'clipboard',
    shortname: ':clipboard:',
    unicode: '1f4cb',
    html: '&#128203;',
    category: 'Objects (office)',
    order: '1933',
  },
  {
    emoji: '📵',
    name: 'no mobile phones',
    shortname: ':no_mobile_phones:',
    unicode: '1f4f5',
    html: '&#128245;',
    category: 'Symbols (warning)',
    order: '1998',
  },
  {
    emoji: '🕓',
    name: 'four o’clock',
    shortname: ':clock4:',
    unicode: '1f553',
    html: '&#128339;',
    category: 'Travel & Places (time)',
    order: '1695',
  },
  {
    emoji: '🚭',
    name: 'no smoking',
    shortname: ':no_smoking:',
    unicode: '1f6ad',
    html: '&#128685;',
    category: 'Symbols (warning)',
    order: '1994',
  },
  {
    emoji: '⬛',
    name: 'black large square',
    shortname: ':black_large_square:',
    unicode: '2b1b',
    html: '&#11035;',
    category: 'Symbols (geometric)',
    order: '2165',
  },
  {
    emoji: '🎰',
    name: 'slot machine',
    shortname: ':slot_machine:',
    unicode: '1f3b0',
    html: '&#127920;',
    category: 'Activities (game)',
    order: '1601',
  },
  {
    emoji: '🕔',
    name: 'five o’clock',
    shortname: ':clock5:',
    unicode: '1f554',
    html: '&#128340;',
    category: 'Travel & Places (time)',
    order: '1697',
  },
  {
    emoji: '🛁',
    name: 'bathtub',
    shortname: ':bathtub:',
    unicode: '1f6c1',
    html: '&#128705;',
    category: 'Objects (household)',
    order: '1679',
  },
  {
    emoji: '📜',
    name: 'scroll',
    shortname: ':scroll:',
    unicode: '1f4dc',
    html: '&#128220;',
    category: 'Objects (book-paper)',
    order: '1884',
  },
  {
    emoji: '🚉',
    name: 'station',
    shortname: ':station:',
    unicode: '1f689',
    html: '&#128649;',
    category: 'Travel & Places (transport-ground)',
    order: '1609',
  },
  {
    emoji: '🍘',
    name: 'rice cracker',
    shortname: ':rice_cracker:',
    unicode: '1f358',
    html: '&#127832;',
    category: 'Food & Drink (food-asian)',
    order: '1496',
  },
  {
    emoji: '🏦',
    name: 'bank',
    shortname: ':bank:',
    unicode: '1f3e6',
    html: '&#127974;',
    category: 'Travel & Places (place-building)',
    order: '1565',
  },
  {
    emoji: '🔧',
    name: 'wrench',
    shortname: ':wrench:',
    unicode: '1f527',
    html: '&#128295;',
    category: 'Objects (tool)',
    order: '1959',
  },
  {
    emoji: '🈯️',
    name: '',
    shortname: ':u6307:',
    unicode: '1f22f',
    html: '&#127535;',
    category: '',
    order: '2146',
  },
  {
    emoji: '🚛',
    name: 'articulated lorry',
    shortname: ':articulated_lorry:',
    unicode: '1f69b',
    html: '&#128667;',
    category: 'Travel & Places (transport-ground)',
    order: '1628',
  },
  {
    emoji: '📄',
    name: 'page facing up',
    shortname: ':page_facing_up:',
    unicode: '1f4c4',
    html: '&#128196;',
    category: 'Objects (book-paper)',
    order: '1885',
  },
  {
    emoji: '⛎',
    name: 'Ophiuchus',
    shortname: ':ophiuchus:',
    unicode: '26ce',
    html: '&#9934;',
    category: 'Symbols (zodiac)',
    order: '2047',
  },
  {
    emoji: '📊',
    name: 'bar chart',
    shortname: ':bar_chart:',
    unicode: '1f4ca',
    html: '&#128202;',
    category: 'Objects (office)',
    order: '1932',
  },
  {
    emoji: '🚷',
    name: 'no pedestrians',
    shortname: ':no_pedestrians:',
    unicode: '1f6b7',
    html: '&#128695;',
    category: 'Symbols (warning)',
    order: '1997',
  },
  {
    emoji: '🇨🇳',
    name: 'flag: China',
    shortname: ':cn:',
    unicode: '1F1E8 1F1F3',
    html: '&#127464;',
    category: 'Flags (country-flag)',
    order: '',
  },
  {
    emoji: '📳',
    name: 'vibration mode',
    shortname: ':vibration_mode:',
    unicode: '1f4f3',
    html: '&#128243;',
    category: 'Symbols (av-symbol)',
    order: '2070',
  },
  {
    emoji: '🕙',
    name: 'ten o’clock',
    shortname: ':clock10:',
    unicode: '1f559',
    html: '&#128345;',
    category: 'Travel & Places (time)',
    order: '1707',
  },
  {
    emoji: '🕘',
    name: 'nine o’clock',
    shortname: ':clock9:',
    unicode: '1f558',
    html: '&#128344;',
    category: 'Travel & Places (time)',
    order: '1705',
  },
  {
    emoji: '🚅',
    name: 'bullet train',
    shortname: ':bullettrain_front:',
    unicode: '1f685',
    html: '&#128645;',
    category: 'Travel & Places (transport-ground)',
    order: '1605',
  },
  {
    emoji: '🚐',
    name: 'minibus',
    shortname: ':minibus:',
    unicode: '1f690',
    html: '&#128656;',
    category: 'Travel & Places (transport-ground)',
    order: '1617',
  },
  {
    emoji: '🚊',
    name: 'tram',
    shortname: ':tram:',
    unicode: '1f68a',
    html: '&#128650;',
    category: 'Travel & Places (transport-ground)',
    order: '1610',
  },
  {
    emoji: '🕗',
    name: 'eight o’clock',
    shortname: ':clock8:',
    unicode: '1f557',
    html: '&#128343;',
    category: 'Travel & Places (time)',
    order: '1703',
  },
  {
    emoji: '🈳',
    name: 'Japanese vacancy button',
    shortname: ':u7a7a:',
    unicode: '1f233',
    html: '&#127539;',
    category: 'Symbols (alphanum)',
    order: '2154',
  },
  {
    emoji: '🚥',
    name: 'horizontal traffic light',
    shortname: ':traffic_light:',
    unicode: '1f6a5',
    html: '&#128677;',
    category: 'Travel & Places (transport-ground)',
    order: '1638',
  },
  {
    emoji: '🚵🏿‍♀',
    name: 'woman mountain biking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B5 1F3FF 200D 2640',
    html: '&#128693;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏾‍♀',
    name: 'woman mountain biking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B5 1F3FE 200D 2640',
    html: '&#128693;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏽‍♀',
    name: 'woman mountain biking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B5 1F3FD 200D 2640',
    html: '&#128693;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏼‍♀',
    name: 'woman mountain biking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B5 1F3FC 200D 2640',
    html: '&#128693;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏻‍♀',
    name: 'woman mountain biking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B5 1F3FB 200D 2640',
    html: '&#128693;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵‍♀',
    name: 'woman mountain biking',
    shortname: ':woman_mountain_biking:',
    unicode: '1F6B5 200D 2640',
    html: '&#128693;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏿',
    name: 'person mountain biking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B5 1F3FF',
    html: '&#128693;&#127999;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏾',
    name: 'person mountain biking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B5 1F3FE',
    html: '&#128693;&#127998;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏽',
    name: 'person mountain biking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B5 1F3FD',
    html: '&#128693;&#127997;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏼',
    name: 'person mountain biking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B5 1F3FC',
    html: '&#128693;&#127996;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏻',
    name: 'person mountain biking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B5 1F3FB',
    html: '&#128693;&#127995;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵',
    name: 'person mountain biking',
    shortname: ':mountain_bicyclist:',
    unicode: '1f6b5',
    html: '&#128693;',
    category: 'People & Body (person-sport)',
    order: '908',
  },
  {
    emoji: '🔬',
    name: 'microscope',
    shortname: ':microscope:',
    unicode: '1f52c',
    html: '&#128300;',
    category: 'Objects (science)',
    order: '1867',
  },
  {
    emoji: '🏯',
    name: 'Japanese castle',
    shortname: ':japanese_castle:',
    unicode: '1f3ef',
    html: '&#127983;',
    category: 'Travel & Places (place-building)',
    order: '1572',
  },
  {
    emoji: '🔖',
    name: 'bookmark',
    shortname: ':bookmark:',
    unicode: '1f516',
    html: '&#128278;',
    category: 'Objects (book-paper)',
    order: '1889',
  },
  {
    emoji: '📑',
    name: 'bookmark tabs',
    shortname: ':bookmark_tabs:',
    unicode: '1f4d1',
    html: '&#128209;',
    category: 'Objects (book-paper)',
    order: '1888',
  },
  {
    emoji: '👝',
    name: 'clutch bag',
    shortname: ':pouch:',
    unicode: '1f45d',
    html: '&#128093;',
    category: 'Objects (clothing)',
    order: '1325',
  },
  {
    emoji: '🆎',
    name: 'AB button (blood type)',
    shortname: ':ab:',
    unicode: '1f18e',
    html: '&#127374;',
    category: 'Symbols (alphanum)',
    order: '2126',
  },
  {
    emoji: '📃',
    name: 'page with curl',
    shortname: ':page_with_curl:',
    unicode: '1f4c3',
    html: '&#128195;',
    category: 'Objects (book-paper)',
    order: '1883',
  },
  {
    emoji: '🎴',
    name: 'flower playing cards',
    shortname: ':flower_playing_cards:',
    unicode: '1f3b4',
    html: '&#127924;',
    category: 'Activities (game)',
    order: '1813',
  },
  {
    emoji: '🕚',
    name: 'eleven o’clock',
    shortname: ':clock11:',
    unicode: '1f55a',
    html: '&#128346;',
    category: 'Travel & Places (time)',
    order: '1709',
  },
  {
    emoji: '📠',
    name: 'fax machine',
    shortname: ':fax:',
    unicode: '1f4e0',
    html: '&#128224;',
    category: 'Objects (phone)',
    order: '1843',
  },
  {
    emoji: '🕖',
    name: 'seven o’clock',
    shortname: ':clock7:',
    unicode: '1f556',
    html: '&#128342;',
    category: 'Travel & Places (time)',
    order: '1701',
  },
  {
    emoji: '◽',
    name: 'white medium-small square',
    shortname: ':white_medium_small_square:',
    unicode: '25fd',
    html: '&#9725;',
    category: 'Symbols (geometric)',
    order: '2163',
  },
  {
    emoji: '💱',
    name: 'currency exchange',
    shortname: ':currency_exchange:',
    unicode: '1f4b1',
    html: '&#128177;',
    category: 'Objects (money)',
    order: '1899',
  },
  {
    emoji: '🔉',
    name: 'speaker medium volume',
    shortname: ':sound:',
    unicode: '1f509',
    html: '&#128265;',
    category: 'Objects (sound)',
    order: '1816',
  },
  {
    emoji: '💹',
    name: 'chart increasing with yen',
    shortname: ':chart:',
    unicode: '1f4b9',
    html: '&#128185;',
    category: 'Objects (money)',
    order: '1898',
  },
  {
    emoji: '🆑',
    name: 'CL button',
    shortname: ':cl:',
    unicode: '1f191',
    html: '&#127377;',
    category: 'Symbols (alphanum)',
    order: '2128',
  },
  {
    emoji: '💾',
    name: 'floppy disk',
    shortname: ':floppy_disk:',
    unicode: '1f4be',
    html: '&#128190;',
    category: 'Objects (computer)',
    order: '1853',
  },
  {
    emoji: '🏣',
    name: 'Japanese post office',
    shortname: ':post_office:',
    unicode: '1f3e3',
    html: '&#127971;',
    category: 'Travel & Places (place-building)',
    order: '1562',
  },
  {
    emoji: '🔈',
    name: 'speaker low volume',
    shortname: ':speaker:',
    unicode: '1f508',
    html: '&#128264;',
    category: 'Objects (sound)',
    order: '1815',
  },
  {
    emoji: '🗾',
    name: 'map of Japan',
    shortname: ':japan:',
    unicode: '1f5fe',
    html: '&#128510;',
    category: 'Travel & Places (place-map)',
    order: '1543',
  },
  {
    emoji: '🈺',
    name: 'Japanese open for business button',
    shortname: ':u55b6:',
    unicode: '1f23a',
    html: '&#127546;',
    category: 'Symbols (alphanum)',
    order: '2157',
  },
  {
    emoji: '🀄',
    name: 'mahjong red dragon',
    shortname: ':mahjong:',
    unicode: '1f004',
    html: '&#126980;',
    category: 'Activities (game)',
    order: '1812',
  },
  {
    emoji: '📨',
    name: 'incoming envelope',
    shortname: ':incoming_envelope:',
    unicode: '1f4e8',
    html: '&#128232;',
    category: 'Objects (mail)',
    order: '1903',
  },
  {
    emoji: '📙',
    name: 'orange book',
    shortname: ':orange_book:',
    unicode: '1f4d9',
    html: '&#128217;',
    category: 'Objects (book-paper)',
    order: '1879',
  },
  {
    emoji: '🚻',
    name: 'restroom',
    shortname: ':restroom:',
    unicode: '1f6bb',
    html: '&#128699;',
    category: 'Symbols (transport-sign)',
    order: '1982',
  },
  {
    emoji: '🈚️',
    name: '',
    shortname: ':u7121:',
    unicode: '1f21a',
    html: '&#127514;',
    category: '',
    order: '2149',
  },
  {
    emoji: '🈶',
    name: 'Japanese not free of charge button',
    shortname: ':u6709:',
    unicode: '1f236',
    html: '&#127542;',
    category: 'Symbols (alphanum)',
    order: '2145',
  },
  {
    emoji: '📐',
    name: 'triangular ruler',
    shortname: ':triangular_ruler:',
    unicode: '1f4d0',
    html: '&#128208;',
    category: 'Objects (office)',
    order: '1939',
  },
  {
    emoji: '🚋',
    name: 'tram car',
    shortname: ':train:',
    unicode: '1f68b',
    html: '&#128651;',
    category: 'Travel & Places (transport-ground)',
    order: '1613',
  },
  {
    emoji: '🈸',
    name: 'Japanese application button',
    shortname: ':u7533:',
    unicode: '1f238',
    html: '&#127544;',
    category: 'Symbols (alphanum)',
    order: '2152',
  },
  {
    emoji: '🚎',
    name: 'trolleybus',
    shortname: ':trolleybus:',
    unicode: '1f68e',
    html: '&#128654;',
    category: 'Travel & Places (transport-ground)',
    order: '1616',
  },
  {
    emoji: '🈷',
    name: 'Japanese monthly amount button',
    shortname: ':u6708:',
    unicode: '1f237',
    html: '&#127543;',
    category: 'Symbols (alphanum)',
    order: '2144',
  },
  {
    emoji: '🔢',
    name: 'input numbers',
    shortname: ':input_numbers:',
    unicode: '1F522',
    html: '&#128290;',
    category: 'Symbols (alphanum)',
    order: '',
  },
  {
    emoji: '📔',
    name: 'notebook with decorative cover',
    shortname: ':notebook_with_decorative_cover:',
    unicode: '1f4d4',
    html: '&#128212;',
    category: 'Objects (book-paper)',
    order: '1874',
  },
  {
    emoji: '🈲',
    name: 'Japanese prohibited button',
    shortname: ':u7981:',
    unicode: '1f232',
    html: '&#127538;',
    category: 'Symbols (alphanum)',
    order: '2150',
  },
  {
    emoji: '🈵',
    name: 'Japanese no vacancy button',
    shortname: ':u6e80:',
    unicode: '1f235',
    html: '&#127541;',
    category: 'Symbols (alphanum)',
    order: '2158',
  },
  {
    emoji: '📯',
    name: 'postal horn',
    shortname: ':postal_horn:',
    unicode: '1f4ef',
    html: '&#128239;',
    category: 'Objects (sound)',
    order: '1820',
  },
  {
    emoji: '🏭',
    name: 'factory',
    shortname: ':factory:',
    unicode: '1f3ed',
    html: '&#127981;',
    category: 'Travel & Places (place-building)',
    order: '1571',
  },
  {
    emoji: '🚸',
    name: 'children crossing',
    shortname: ':children_crossing:',
    unicode: '1f6b8',
    html: '&#128696;',
    category: 'Symbols (warning)',
    order: '1990',
  },
  {
    emoji: '🚆',
    name: 'train',
    shortname: ':train2:',
    unicode: '1f686',
    html: '&#128646;',
    category: 'Travel & Places (transport-ground)',
    order: '1606',
  },
  {
    emoji: '📏',
    name: 'straight ruler',
    shortname: ':straight_ruler:',
    unicode: '1f4cf',
    html: '&#128207;',
    category: 'Objects (office)',
    order: '1938',
  },
  {
    emoji: '📟',
    name: 'pager',
    shortname: ':pager:',
    unicode: '1f4df',
    html: '&#128223;',
    category: 'Objects (phone)',
    order: '1842',
  },
  {
    emoji: '🉑',
    name: 'Japanese acceptable button',
    shortname: ':accept:',
    unicode: '1f251',
    html: '&#127569;',
    category: 'Symbols (alphanum)',
    order: '2151',
  },
  {
    emoji: '🈴',
    name: 'Japanese passing grade button',
    shortname: ':u5408:',
    unicode: '1f234',
    html: '&#127540;',
    category: 'Symbols (alphanum)',
    order: '2153',
  },
  {
    emoji: '🔏',
    name: 'locked with pen',
    shortname: ':lock_with_ink_pen:',
    unicode: '1f50f',
    html: '&#128271;',
    category: 'Objects (lock)',
    order: '1946',
  },
  {
    emoji: '🕜',
    name: 'one-thirty',
    shortname: ':clock130:',
    unicode: '1f55c',
    html: '&#128348;',
    category: 'Travel & Places (time)',
    order: '1690',
  },
  {
    emoji: '🈂️',
    name: 'Japanese service charge button',
    shortname: ':sa:',
    unicode: '1f202',
    html: '&#127490;',
    category: 'Symbols (alphanum)',
    order: '2143',
  },
  {
    emoji: '📤',
    name: 'outbox tray',
    shortname: ':outbox_tray:',
    unicode: '1f4e4',
    html: '&#128228;',
    category: 'Objects (mail)',
    order: '1905',
  },
  {
    emoji: '🔀',
    name: 'shuffle tracks button',
    shortname: ':twisted_rightwards_arrows:',
    unicode: '1f500',
    html: '&#128256;',
    category: 'Symbols (av-symbol)',
    order: '2048',
  },
  {
    emoji: '📫',
    name: 'closed mailbox with raised flag',
    shortname: ':mailbox:',
    unicode: '1f4eb',
    html: '&#128235;',
    category: 'Objects (mail)',
    order: '1908',
  },
  {
    emoji: '🚈',
    name: 'light rail',
    shortname: ':light_rail:',
    unicode: '1f688',
    html: '&#128648;',
    category: 'Travel & Places (transport-ground)',
    order: '1608',
  },
  {
    emoji: '🕤',
    name: 'nine-thirty',
    shortname: ':clock930:',
    unicode: '1f564',
    html: '&#128356;',
    category: 'Travel & Places (time)',
    order: '1706',
  },
  {
    emoji: '🚏',
    name: 'bus stop',
    shortname: ':busstop:',
    unicode: '1f68f',
    html: '&#128655;',
    category: 'Travel & Places (transport-ground)',
    order: '1633',
  },
  {
    emoji: '📂',
    name: 'open file folder',
    shortname: ':open_file_folder:',
    unicode: '1f4c2',
    html: '&#128194;',
    category: 'Objects (office)',
    order: '1923',
  },
  {
    emoji: '📁',
    name: 'file folder',
    shortname: ':file_folder:',
    unicode: '1f4c1',
    html: '&#128193;',
    category: 'Objects (office)',
    order: '1922',
  },
  {
    emoji: '🚰',
    name: 'potable water',
    shortname: ':potable_water:',
    unicode: '1f6b0',
    html: '&#128688;',
    category: 'Symbols (transport-sign)',
    order: '1978',
  },
  {
    emoji: '📇',
    name: 'card index',
    shortname: ':card_index:',
    unicode: '1f4c7',
    html: '&#128199;',
    category: 'Objects (office)',
    order: '1929',
  },
  {
    emoji: '🕝',
    name: 'two-thirty',
    shortname: ':clock230:',
    unicode: '1f55d',
    html: '&#128349;',
    category: 'Travel & Places (time)',
    order: '1692',
  },
  {
    emoji: '🚝',
    name: 'monorail',
    shortname: ':monorail:',
    unicode: '1f69d',
    html: '&#128669;',
    category: 'Travel & Places (transport-ground)',
    order: '1611',
  },
  {
    emoji: '🕧',
    name: 'twelve-thirty',
    shortname: ':clock1230:',
    unicode: '1f567',
    html: '&#128359;',
    category: 'Travel & Places (time)',
    order: '1688',
  },
  {
    emoji: '🕥',
    name: 'ten-thirty',
    shortname: ':clock1030:',
    unicode: '1f565',
    html: '&#128357;',
    category: 'Travel & Places (time)',
    order: '1708',
  },
  {
    emoji: '🔤',
    name: 'input latin letters',
    shortname: ':abc:',
    unicode: '1f524',
    html: '&#128292;',
    category: 'Symbols (alphanum)',
    order: '2124',
  },
  {
    emoji: '📪',
    name: 'closed mailbox with lowered flag',
    shortname: ':mailbox_closed:',
    unicode: '1f4ea',
    html: '&#128234;',
    category: 'Objects (mail)',
    order: '1909',
  },
  {
    emoji: '🕟',
    name: 'four-thirty',
    shortname: ':clock430:',
    unicode: '1f55f',
    html: '&#128351;',
    category: 'Travel & Places (time)',
    order: '1696',
  },
  {
    emoji: '🚞',
    name: 'mountain railway',
    shortname: ':mountain_railway:',
    unicode: '1f69e',
    html: '&#128670;',
    category: 'Travel & Places (transport-ground)',
    order: '1612',
  },
  {
    emoji: '🚯',
    name: 'no littering',
    shortname: ':do_not_litter:',
    unicode: '1f6af',
    html: '&#128687;',
    category: 'Symbols (warning)',
    order: '1995',
  },
  {
    emoji: '🕞',
    name: 'three-thirty',
    shortname: ':clock330:',
    unicode: '1f55e',
    html: '&#128350;',
    category: 'Travel & Places (time)',
    order: '1694',
  },
  {
    emoji: '➗',
    name: 'division sign',
    shortname: ':heavy_division_sign:',
    unicode: '2797',
    html: '&#10135;',
    category: 'Symbols (other-symbol)',
    order: '2089',
  },
  {
    emoji: '🕢',
    name: 'seven-thirty',
    shortname: ':clock730:',
    unicode: '1f562',
    html: '&#128354;',
    category: 'Travel & Places (time)',
    order: '1702',
  },
  {
    emoji: '🕠',
    name: 'five-thirty',
    shortname: ':clock530:',
    unicode: '1f560',
    html: '&#128352;',
    category: 'Travel & Places (time)',
    order: '1698',
  },
  {
    emoji: '🔠',
    name: 'input latin uppercase',
    shortname: ':capital_abcd:',
    unicode: '1f520',
    html: '&#128288;',
    category: 'Symbols (alphanum)',
    order: '2120',
  },
  {
    emoji: '📭',
    name: 'open mailbox with lowered flag',
    shortname: ':mailbox_with_no_mail:',
    unicode: '1f4ed',
    html: '&#128237;',
    category: 'Objects (mail)',
    order: '1911',
  },
  {
    emoji: '🔣',
    name: 'input symbols',
    shortname: ':symbols:',
    unicode: '1f523',
    html: '&#128291;',
    category: 'Symbols (alphanum)',
    order: '2123',
  },
  {
    emoji: '🚡',
    name: 'aerial tramway',
    shortname: ':aerial_tramway:',
    unicode: '1f6a1',
    html: '&#128673;',
    category: 'Travel & Places (transport-air)',
    order: '1658',
  },
  {
    emoji: '🕣',
    name: 'eight-thirty',
    shortname: ':clock830:',
    unicode: '1f563',
    html: '&#128355;',
    category: 'Travel & Places (time)',
    order: '1704',
  },
  {
    emoji: '🕡',
    name: 'six-thirty',
    shortname: ':clock630:',
    unicode: '1f561',
    html: '&#128353;',
    category: 'Travel & Places (time)',
    order: '1700',
  },
  {
    emoji: '🔡',
    name: 'input latin lowercase',
    shortname: ':abcd:',
    unicode: '1f521',
    html: '&#128289;',
    category: 'Symbols (alphanum)',
    order: '2121',
  },
  {
    emoji: '🚠',
    name: 'mountain cableway',
    shortname: ':mountain_cableway:',
    unicode: '1f6a0',
    html: '&#128672;',
    category: 'Travel & Places (transport-air)',
    order: '1657',
  },
  {
    emoji: '🈁',
    name: 'Japanese here button',
    shortname: ':koko:',
    unicode: '1f201',
    html: '&#127489;',
    category: 'Symbols (alphanum)',
    order: '2142',
  },
  {
    emoji: '🛂',
    name: 'passport control',
    shortname: ':passport_control:',
    unicode: '1f6c2',
    html: '&#128706;',
    category: 'Symbols (transport-sign)',
    order: '1985',
  },
  {
    emoji: '🚱',
    name: 'non-potable water',
    shortname: ':non-potable_water:',
    unicode: '1f6b1',
    html: '&#128689;',
    category: 'Symbols (warning)',
    order: '1996',
  },
  {
    emoji: '🚟',
    name: 'suspension railway',
    shortname: ':suspension_railway:',
    unicode: '1f69f',
    html: '&#128671;',
    category: 'Travel & Places (transport-air)',
    order: '1656',
  },
  {
    emoji: '🛄',
    name: 'baggage claim',
    shortname: ':baggage_claim:',
    unicode: '1f6c4',
    html: '&#128708;',
    category: 'Symbols (transport-sign)',
    order: '1987',
  },
  {
    emoji: '🚳',
    name: 'no bicycles',
    shortname: ':no_bicycles:',
    unicode: '1f6b3',
    html: '&#128691;',
    category: 'Symbols (warning)',
    order: '1993',
  },
  {
    emoji: '🏳‍🌈',
    name: 'rainbow flag',
    shortname: ':rainbow_flag:',
    unicode: '1F3F3 200D 1F308',
    html: '&#127987;&zwj;&#127752;',
    category: 'Flags (flag)',
    order: '',
  },
  {
    emoji: '🕵🏿‍♀',
    name: 'woman detective: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F575 1F3FF 200D 2640',
    html: '&#128373;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏾‍♀',
    name: 'woman detective: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F575 1F3FE 200D 2640',
    html: '&#128373;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏽‍♀',
    name: 'woman detective: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F575 1F3FD 200D 2640',
    html: '&#128373;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏼‍♀',
    name: 'woman detective: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F575 1F3FC 200D 2640',
    html: '&#128373;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏻‍♀',
    name: 'woman detective: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F575 1F3FB 200D 2640',
    html: '&#128373;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵‍♀',
    name: 'woman detective',
    shortname: ':woman_detective:',
    unicode: '1F575 200D 2640',
    html: '&#128373;&zwj;&#9792;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏿',
    name: 'detective: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F575 1F3FF',
    html: '&#128373;&#127999;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏾',
    name: 'detective: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F575 1F3FE',
    html: '&#128373;&#127998;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏽',
    name: 'detective: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F575 1F3FD',
    html: '&#128373;&#127997;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏼',
    name: 'detective: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F575 1F3FC',
    html: '&#128373;&#127996;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏻',
    name: 'detective: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F575 1F3FB',
    html: '&#128373;&#127995;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵',
    name: 'detective',
    shortname: ':detective:',
    unicode: '1F575',
    html: '&#128373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '☹',
    name: 'frowning face',
    shortname: ':frowning_face:',
    unicode: '2639',
    html: '&#9785;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '',
  },
  {
    emoji: '☠',
    name: 'skull and crossbones',
    shortname: ':skull_crossbones:',
    unicode: '2620',
    html: '&#9760;',
    category: 'Smileys & Emotion (face-negative)',
    order: '81',
  },
  {
    emoji: '🤗',
    name: 'hugging face',
    shortname: ':hugging:',
    unicode: '1f917',
    html: '&#129303;',
    category: 'Smileys & Emotion (face-hand)',
    order: '20',
  },
  {
    emoji: '🤖',
    name: 'robot',
    shortname: ':robot:',
    unicode: '1F916',
    html: '&#129302;',
    category: 'Smileys & Emotion (face-costume)',
    order: '',
  },
  {
    emoji: '🤕',
    name: 'face with head-bandage',
    shortname: ':face_with_headbandage:',
    unicode: '1F915',
    html: '&#129301;',
    category: 'Smileys & Emotion (face-unwell)',
    order: '',
  },
  {
    emoji: '🤔',
    name: 'thinking face',
    shortname: ':thinking:',
    unicode: '1f914',
    html: '&#129300;',
    category: 'Smileys & Emotion (face-hand)',
    order: '21',
  },
  {
    emoji: '🤓',
    name: 'nerd face',
    shortname: ':nerd:',
    unicode: '1f913',
    html: '&#129299;',
    category: 'Smileys & Emotion (face-glasses)',
    order: '36',
  },
  {
    emoji: '🤒',
    name: 'face with thermometer',
    shortname: ':face_with_thermometer:',
    unicode: '1F912',
    html: '&#129298;',
    category: 'Smileys & Emotion (face-unwell)',
    order: '',
  },
  {
    emoji: '🤑',
    name: 'money-mouth face',
    shortname: ':moneymouth_face:',
    unicode: '1F911',
    html: '&#129297;',
    category: 'Smileys & Emotion (face-tongue)',
    order: '',
  },
  {
    emoji: '🤐',
    name: 'zipper-mouth face',
    shortname: ':zipper_mouth:',
    unicode: '1f910',
    html: '&#129296;',
    category: 'Smileys & Emotion (face-neutral-skeptical)',
    order: '30',
  },
  {
    emoji: '🙄',
    name: 'face with rolling eyes',
    shortname: ':rolling_eyes:',
    unicode: '1f644',
    html: '&#128580;',
    category: 'Smileys & Emotion (face-neutral-skeptical)',
    order: '25',
  },
  {
    emoji: '🙃',
    name: 'upside-down face',
    shortname: ':upside_down:',
    unicode: '1f643',
    html: '&#128579;',
    category: 'Smileys & Emotion (face-smiling)',
    order: '45',
  },
  {
    emoji: '🙂',
    name: 'slightly smiling face',
    shortname: ':slight_smile:',
    unicode: '1f642',
    html: '&#128578;',
    category: 'Smileys & Emotion (face-smiling)',
    order: '19',
  },
  {
    emoji: '🙁',
    name: 'slightly frowning face',
    shortname: ':slightly_frowning_face:',
    unicode: '1F641',
    html: '&#128577;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '',
  },
  {
    emoji: '🤘🏿',
    name: 'sign of the horns: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F918 1F3FF',
    html: '&#129304;&#127999;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤘🏾',
    name: 'sign of the horns: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F918 1F3FE',
    html: '&#129304;&#127998;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤘🏽',
    name: 'sign of the horns: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F918 1F3FD',
    html: '&#129304;&#127997;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤘🏼',
    name: 'sign of the horns: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F918 1F3FC',
    html: '&#129304;&#127996;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤘🏻',
    name: 'sign of the horns: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F918 1F3FB',
    html: '&#129304;&#127995;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤘',
    name: 'sign of the horns',
    shortname: ':sign_of_the_horns:',
    unicode: '1F918',
    html: '&#129304;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🖖🏿',
    name: 'vulcan salute: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F596 1F3FF',
    html: '&#128406;&#127999;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🖖🏾',
    name: 'vulcan salute: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F596 1F3FE',
    html: '&#128406;&#127998;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🖖🏽',
    name: 'vulcan salute: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F596 1F3FD',
    html: '&#128406;&#127997;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🖖🏼',
    name: 'vulcan salute: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F596 1F3FC',
    html: '&#128406;&#127996;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🖖🏻',
    name: 'vulcan salute: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F596 1F3FB',
    html: '&#128406;&#127995;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🖖',
    name: 'vulcan salute',
    shortname: ':vulcan_salute:',
    unicode: '1F596',
    html: '&#128406;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🖕🏿',
    name: 'middle finger: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F595 1F3FF',
    html: '&#128405;&#127999;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '🖕🏾',
    name: 'middle finger: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F595 1F3FE',
    html: '&#128405;&#127998;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '🖕🏽',
    name: 'middle finger: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F595 1F3FD',
    html: '&#128405;&#127997;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '🖕🏼',
    name: 'middle finger: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F595 1F3FC',
    html: '&#128405;&#127996;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '🖕🏻',
    name: 'middle finger: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F595 1F3FB',
    html: '&#128405;&#127995;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '🖕',
    name: 'middle finger',
    shortname: ':middle_finger:',
    unicode: '1f595',
    html: '&#128405;',
    category: 'People & Body (hand-single-finger)',
    order: '1116',
  },
  {
    emoji: '🖐🏿',
    name: 'hand with fingers splayed: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F590 1F3FF',
    html: '&#128400;&#127999;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🖐🏾',
    name: 'hand with fingers splayed: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F590 1F3FE',
    html: '&#128400;&#127998;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🖐🏽',
    name: 'hand with fingers splayed: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F590 1F3FD',
    html: '&#128400;&#127997;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🖐🏼',
    name: 'hand with fingers splayed: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F590 1F3FC',
    html: '&#128400;&#127996;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🖐🏻',
    name: 'hand with fingers splayed: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F590 1F3FB',
    html: '&#128400;&#127995;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🖐',
    name: 'hand with fingers splayed',
    shortname: ':hand_with_fingers_splayed:',
    unicode: '1F590',
    html: '&#128400;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '✍🏿',
    name: 'writing hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '270D 1F3FF',
    html: '&#9997;&#127999;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '✍🏾',
    name: 'writing hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '270D 1F3FE',
    html: '&#9997;&#127998;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '✍🏽',
    name: 'writing hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '270D 1F3FD',
    html: '&#9997;&#127997;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '✍🏼',
    name: 'writing hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '270D 1F3FC',
    html: '&#9997;&#127996;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '✍🏻',
    name: 'writing hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '270D 1F3FB',
    html: '&#9997;&#127995;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '✍',
    name: 'writing hand',
    shortname: ':writing_hand:',
    unicode: '270d',
    html: '&#9997;',
    category: 'People & Body (hand-prop)',
    order: '1230',
  },
  {
    emoji: '🕶',
    name: 'sunglasses',
    shortname: ':dark_sunglasses:',
    unicode: '1f576',
    html: '&#128374;',
    category: 'Objects (clothing)',
    order: '1315',
  },
  {
    emoji: '👁‍🗨',
    name: 'eye in speech bubble',
    shortname: ':eye_speachbubble:',
    unicode: '1F441 200D 1F5E8',
    html: '&#128065;&zwj;&#128488;',
    category: 'Smileys & Emotion (emotion)',
    order: '',
  },
  {
    emoji: '👁',
    name: 'eye',
    shortname: ':eye:',
    unicode: '1f441',
    html: '&#128065;',
    category: 'People & Body (body-parts)',
    order: '1280',
  },
  {
    emoji: '🏋🏿‍♀',
    name: 'woman lifting weights: dark skin tone',
    shortname: ':weightlifter_woman_dt:',
    unicode: '1F3CB 1F3FF 200D 2640',
    html: '&#127947;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏾‍♀',
    name: 'woman lifting weights: medium-dark skin tone',
    shortname: ':weightlifter_woman_mdt:',
    unicode: '1F3CB 1F3FE 200D 2640',
    html: '&#127947;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏽‍♀',
    name: 'woman lifting weights: medium skin tone',
    shortname: ':weightlifter_woman_mt:',
    unicode: '1F3CB 1F3FD 200D 2640',
    html: '&#127947;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏼‍♀',
    name: 'woman lifting weights: medium-light skin tone',
    shortname: ':weightlifter_woman_mlt:',
    unicode: '1F3CB 1F3FC 200D 2640',
    html: '&#127947;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏻‍♀',
    name: 'woman lifting weights: light skin tone',
    shortname: ':weightlifter_woman_lt:',
    unicode: '1F3CB 1F3FB 200D 2640',
    html: '&#127947;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋‍♀',
    name: 'woman lifting weights',
    shortname: ':weightlifter_woman:',
    unicode: '1F3CB 200D 2640',
    html: '&#127947;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏿',
    name: 'person lifting weights: dark skin tone',
    shortname: ':weightlifter_dt:',
    unicode: '1F3CB 1F3FF',
    html: '&#127947;&#127999;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏾',
    name: 'person lifting weights: medium-dark skin tone',
    shortname: ':weightlifter_mdt:',
    unicode: '1F3CB 1F3FE',
    html: '&#127947;&#127998;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏽',
    name: 'person lifting weights: medium skin tone',
    shortname: ':weightlifter_mt:',
    unicode: '1F3CB 1F3FD',
    html: '&#127947;&#127997;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏼',
    name: 'person lifting weights: medium-light skin tone',
    shortname: ':weightlifter_mlt:',
    unicode: '1F3CB 1F3FC',
    html: '&#127947;&#127996;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏻',
    name: 'person lifting weights: light skin tone',
    shortname: ':weightlifter_lt:',
    unicode: '1F3CB 1F3FB',
    html: '&#127947;&#127995;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋',
    name: 'person lifting weights',
    shortname: ':weightlifter:',
    unicode: '1F3CB',
    html: '&#127947;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏿‍♀',
    name: 'woman bouncing ball: dark skin tone',
    shortname: ':basketballer_woman_dt:',
    unicode: ':basketballer:',
    html: '&#9977;&#127999;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏾‍♀',
    name: 'woman bouncing ball: medium-dark skin tone',
    shortname: ':basketballer_woman_mdt:',
    unicode: ':basketballer:',
    html: '&#9977;&#127998;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏽‍♀',
    name: 'woman bouncing ball: medium skin tone',
    shortname: ':basketballer_woman_mt:',
    unicode: ':basketballer:',
    html: '&#9977;&#127997;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏼‍♀',
    name: 'woman bouncing ball: medium-light skin tone',
    shortname: ':basketballer_woman_mlt:',
    unicode: ':basketballer:',
    html: '&#9977;&#127996;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏻‍♀',
    name: 'woman bouncing ball: light skin tone',
    shortname: ':basketballer_woman_lt:',
    unicode: ':basketballer:',
    html: '&#9977;&#127995;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹‍♀',
    name: 'woman bouncing ball',
    shortname: ':woman_bouncing_ball:',
    unicode: ':basketballer_woman:',
    html: '&#9977;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏿',
    name: 'person bouncing ball: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: ':basketballer_dt:',
    html: '&#9977;&#127999;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏾',
    name: 'person bouncing ball: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: ':basketballer_mdt:',
    html: '&#9977;&#127998;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏽',
    name: 'person bouncing ball: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: ':basketballer_mt:',
    html: '&#9977;&#127997;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏼',
    name: 'person bouncing ball: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: ':basketballer_mlt:',
    html: '&#9977;&#127996;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏻',
    name: 'person bouncing ball: light skin tone',
    shortname: ':basketballer_lt:',
    unicode: '26F9 1F3FB',
    html: '&#9977;&#127995;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹',
    name: 'person bouncing ball',
    shortname: ':basketballer:',
    unicode: '26F9',
    html: '&#9977;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🕴',
    name: 'man in suit levitating',
    shortname: ':man_in_suit:',
    unicode: '1f574',
    html: '&#128372;',
    category: 'People & Body (person-activity)',
    order: '784',
  },
  {
    emoji: '🏌',
    name: 'person golfing',
    shortname: ':golfer:',
    unicode: '1f3cc',
    html: '&#127948;',
    category: 'People & Body (person-sport)',
    order: '782',
  },
  {
    emoji: '🏌‍♀',
    name: 'woman golfing',
    shortname: ':golfer_woman:',
    unicode: '1F3CC 200D 2640',
    html: '&#127948;&zwj;&#9792;',
    category: 'People & Body (person-sport)',
    order: '783',
  },
  {
    emoji: '*️⃣',
    name: 'keycap: #',
    shortname: '*',
    unicode: '0023 FE0F 20E3',
    html: '&#42;',
    category: 'Symbols (keycap)',
    order: '2118',
  },
  {
    emoji: '❣️',
    name: 'heart exclamation',
    shortname: ':heart_exclamation:',
    unicode: '2763',
    html: '&#10083;',
    category: 'Smileys & Emotion (emotion)',
    order: '1300',
  },
  {
    emoji: '✡️',
    name: 'star of David',
    shortname: ':star_of_david:',
    unicode: '2721',
    html: '&#10017;',
    category: 'Symbols (religion)',
    order: '2026',
  },
  {
    emoji: '✝️',
    name: 'latin cross',
    shortname: ':cross:',
    unicode: '271d',
    html: '&#10013;',
    category: 'Symbols (religion)',
    order: '2029',
  },
  {
    emoji: '⚜',
    name: 'fleur-de-lis',
    shortname: ':fleur-de-lis:',
    unicode: '269c',
    html: '&#9884;',
    category: 'Symbols (other-symbol)',
    order: '2074',
  },
  {
    emoji: '⚛',
    name: 'atom symbol',
    shortname: ':atom:',
    unicode: '269b',
    html: '&#9883;',
    category: 'Symbols (religion)',
    order: '2024',
  },
  {
    emoji: '☸',
    name: 'wheel of dharma',
    shortname: ':wheel_of_dharma:',
    unicode: '2638',
    html: '&#9784;',
    category: 'Symbols (religion)',
    order: '2027',
  },
  {
    emoji: '☯',
    name: 'yin yang',
    shortname: ':yin_yang:',
    unicode: '262f',
    html: '&#9775;',
    category: 'Symbols (religion)',
    order: '2028',
  },
  {
    emoji: '☮',
    name: 'peace symbol',
    shortname: ':peace:',
    unicode: '262e',
    html: '&#9774;',
    category: 'Symbols (religion)',
    order: '2032',
  },
  {
    emoji: '☪',
    name: 'star and crescent',
    shortname: ':star_and_crescent:',
    unicode: '262a',
    html: '&#9770;',
    category: 'Symbols (religion)',
    order: '2031',
  },
  {
    emoji: '☦',
    name: 'orthodox cross',
    shortname: ':orthodox_cross:',
    unicode: '2626',
    html: '&#9766;',
    category: 'Symbols (religion)',
    order: '2030',
  },
  {
    emoji: '☣',
    name: 'biohazard',
    shortname: ':biohazard:',
    unicode: '2623',
    html: '&#9763;',
    category: 'Symbols (warning)',
    order: '2001',
  },
  {
    emoji: '☢',
    name: 'radioactive',
    shortname: ':radioactive:',
    unicode: '2622',
    html: '&#9762;',
    category: 'Symbols (warning)',
    order: '2000',
  },
  {
    emoji: '🛐',
    name: 'place of worship',
    shortname: ':place_of_worship:',
    unicode: '1f6d0',
    html: '&#128720;',
    category: 'Symbols (religion)',
    order: '2023',
  },
  {
    emoji: '🗯',
    name: 'right anger bubble',
    shortname: ':anger_right:',
    unicode: '1f5ef',
    html: '&#128495;',
    category: 'Smileys & Emotion (emotion)',
    order: '1311',
  },
  {
    emoji: '🕎',
    name: 'menorah',
    shortname: ':menorah:',
    unicode: '1f54e',
    html: '&#128334;',
    category: 'Symbols (religion)',
    order: '2033',
  },
  {
    emoji: '🕉',
    name: 'om',
    shortname: ':om_symbol:',
    unicode: '1f549',
    html: '&#128329;',
    category: 'Symbols (religion)',
    order: '2025',
  },
  {
    emoji: '⚱',
    name: 'funeral urn',
    shortname: ':funeral_urn:',
    unicode: '26B1',
    html: '&#9905;',
    category: 'Objects (other-object)',
    order: '',
  },
  {
    emoji: '⚰',
    name: 'coffin',
    shortname: ':coffin:',
    unicode: '26b0',
    html: '&#9904;',
    category: 'Objects (other-object)',
    order: '1970',
  },
  {
    emoji: '⚙',
    name: 'gear',
    shortname: ':gear:',
    unicode: '2699',
    html: '&#9881;',
    category: 'Objects (tool)',
    order: '1961',
  },
  {
    emoji: '⚗',
    name: 'alembic',
    shortname: ':alembic:',
    unicode: '2697',
    html: '&#9879;',
    category: 'Objects (science)',
    order: '1963',
  },
  {
    emoji: '⚖',
    name: 'balance scale',
    shortname: ':scales:',
    unicode: '2696',
    html: '&#9878;',
    category: 'Objects (tool)',
    order: '1964',
  },
  {
    emoji: '⚔',
    name: 'crossed swords',
    shortname: ':crossed_swords:',
    unicode: '2694',
    html: '&#9876;',
    category: 'Objects (tool)',
    order: '1955',
  },
  {
    emoji: '⌨',
    name: 'keyboard',
    shortname: ':keyboard:',
    unicode: '2328',
    html: '&#9000;',
    category: 'Objects (computer)',
    order: '1849',
  },
  {
    emoji: '🛢',
    name: 'oil drum',
    shortname: ':oil_drum:',
    unicode: '1F6E2',
    html: '&#128738;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🛡',
    name: 'shield',
    shortname: ':shield:',
    unicode: '1f6e1',
    html: '&#128737;',
    category: 'Objects (tool)',
    order: '1958',
  },
  {
    emoji: '🛠',
    name: 'hammer and wrench',
    shortname: ':hammer_and_wrench:',
    unicode: '1F6E0',
    html: '&#128736;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '🛏',
    name: 'bed',
    shortname: ':bed:',
    unicode: '1f6cf',
    html: '&#128719;',
    category: 'Objects (household)',
    order: '1669',
  },
  {
    emoji: '🛎',
    name: 'bellhop bell',
    shortname: ':bellhop_bell:',
    unicode: '1F6CE',
    html: '&#128718;',
    category: 'Travel & Places (hotel)',
    order: '',
  },
  {
    emoji: '🛍',
    name: 'shopping bags',
    shortname: ':shopping_bags:',
    unicode: '1f6cd',
    html: '&#128717;',
    category: 'Objects (clothing)',
    order: '1326',
  },
  {
    emoji: '🛌',
    name: 'person in bed',
    shortname: ':sleeping_accommodation:',
    unicode: '1f6cc',
    html: '&#128716;',
    category: 'People & Body (person-resting)',
    order: '1663',
  },
  {
    emoji: '🛋',
    name: 'couch and lamp',
    shortname: ':couch_and_lamp:',
    unicode: '1F6CB',
    html: '&#128715;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '🗳',
    name: 'ballot box with ballot',
    shortname: ':ballot_box:',
    unicode: '1f5f3',
    html: '&#128499;',
    category: 'Objects (mail)',
    order: '1913',
  },
  {
    emoji: '🗡',
    name: 'dagger',
    shortname: ':dagger:',
    unicode: '1F5E1',
    html: '&#128481;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '🗞',
    name: 'rolled-up newspaper',
    shortname: ':rolledup_newspaper:',
    unicode: '1F5DE',
    html: '&#128478;',
    category: 'Objects (book-paper)',
    order: '',
  },
  {
    emoji: '🗝',
    name: 'old key',
    shortname: ':old_key:',
    unicode: '1F5DD',
    html: '&#128477;',
    category: 'Objects (lock)',
    order: '',
  },
  {
    emoji: '🗜',
    name: 'clamp',
    shortname: ':compression:',
    unicode: '1f5dc',
    html: '&#128476;',
    category: 'Objects (tool)',
    order: '1962',
  },
  {
    emoji: '🗓',
    name: 'spiral calendar',
    shortname: ':spiral_calendar:',
    unicode: '1F5D3',
    html: '&#128467;',
    category: 'Objects (office)',
    order: '',
  },
  {
    emoji: '🗒',
    name: 'spiral notepad',
    shortname: ':spiral_notepad:',
    unicode: '1F5D2',
    html: '&#128466;',
    category: 'Objects (office)',
    order: '',
  },
  {
    emoji: '🗑',
    name: 'wastebasket',
    shortname: ':wastebasket:',
    unicode: '1f5d1',
    html: '&#128465;',
    category: 'Objects (office)',
    order: '1943',
  },
  {
    emoji: '🗄',
    name: 'file cabinet',
    shortname: ':file_cabinet:',
    unicode: '1f5c4',
    html: '&#128452;',
    category: 'Objects (office)',
    order: '1942',
  },
  {
    emoji: '🗃',
    name: 'card file box',
    shortname: ':card_file_box:',
    unicode: '1F5C3',
    html: '&#128451;',
    category: 'Objects (office)',
    order: '',
  },
  {
    emoji: '🗂',
    name: 'card index dividers',
    shortname: ':card_index_dividers:',
    unicode: '1F5C2',
    html: '&#128450;',
    category: 'Objects (office)',
    order: '',
  },
  {
    emoji: '🖼',
    name: 'framed picture',
    shortname: ':framed_picture:',
    unicode: '1F5BC',
    html: '&#128444;',
    category: 'Activities (arts & crafts)',
    order: '',
  },
  {
    emoji: '🖲',
    name: 'trackball',
    shortname: ':trackball:',
    unicode: '1f5b2',
    html: '&#128434;',
    category: 'Objects (computer)',
    order: '1851',
  },
  {
    emoji: '🖱',
    name: 'computer mouse',
    shortname: ':computer_mouse:',
    unicode: '1F5B1',
    html: '&#128433;',
    category: 'Objects (computer)',
    order: '',
  },
  {
    emoji: '🖨',
    name: 'printer',
    shortname: ':printer:',
    unicode: '1f5a8',
    html: '&#128424;',
    category: 'Objects (computer)',
    order: '1848',
  },
  {
    emoji: '🖥',
    name: 'desktop computer',
    shortname: ':desktop_computer:',
    unicode: '1F5A5',
    html: '&#128421;',
    category: 'Objects (computer)',
    order: '',
  },
  {
    emoji: '🖍',
    name: 'crayon',
    shortname: ':crayon:',
    unicode: '1F58D',
    html: '&#128397;',
    category: 'Objects (writing)',
    order: '',
  },
  {
    emoji: '🖌',
    name: 'paintbrush',
    shortname: ':paintbrush:',
    unicode: '1F58C',
    html: '&#128396;',
    category: 'Objects (writing)',
    order: '',
  },
  {
    emoji: '🖋',
    name: 'fountain pen',
    shortname: ':fountain_pen:',
    unicode: '1F58B',
    html: '&#128395;',
    category: 'Objects (writing)',
    order: '',
  },
  {
    emoji: '🖊',
    name: 'pen',
    shortname: ':pen:',
    unicode: '1F58A',
    html: '&#128394;',
    category: 'Objects (writing)',
    order: '',
  },
  {
    emoji: '🖇',
    name: 'linked paperclips',
    shortname: ':linked_paperclips:',
    unicode: '1F587',
    html: '&#128391;',
    category: 'Objects (office)',
    order: '',
  },
  {
    emoji: '🕹',
    name: 'joystick',
    shortname: ':joystick:',
    unicode: '1f579',
    html: '&#128377;',
    category: 'Activities (game)',
    order: '1805',
  },
  {
    emoji: '🕳',
    name: 'hole',
    shortname: ':hole:',
    unicode: '1f573',
    html: '&#128371;',
    category: 'Smileys & Emotion (emotion)',
    order: '1313',
  },
  {
    emoji: '🕰',
    name: 'mantelpiece clock',
    shortname: ':mantelpiece_clock:',
    unicode: '1F570',
    html: '&#128368;',
    category: 'Travel & Places (time)',
    order: '',
  },
  {
    emoji: '🕯',
    name: 'candle',
    shortname: ':candle:',
    unicode: '1f56f',
    html: '&#128367;',
    category: 'Objects (light & video)',
    order: '1870',
  },
  {
    emoji: '📿',
    name: 'prayer beads',
    shortname: ':prayer_beads:',
    unicode: '1f4ff',
    html: '&#128255;',
    category: 'Objects (clothing)',
    order: '1338',
  },
  {
    emoji: '📽',
    name: 'film projector',
    shortname: ':film_projector:',
    unicode: '1F4FD',
    html: '&#128253;',
    category: 'Objects (light & video)',
    order: '',
  },
  {
    emoji: '📸',
    name: 'camera with flash',
    shortname: ':camera_with_flash:',
    unicode: '1f4f8',
    html: '&#128248;',
    category: 'Objects (light & video)',
    order: '1862',
  },
  {
    emoji: '🏺',
    name: 'amphora',
    shortname: ':amphora:',
    unicode: '1f3fa',
    html: '&#127994;',
    category: 'Food & Drink (dishware)',
    order: '1537',
  },
  {
    emoji: '🏷',
    name: 'label',
    shortname: ':label:',
    unicode: '1f3f7',
    html: '&#127991;',
    category: 'Objects (book-paper)',
    order: '1890',
  },
  {
    emoji: '🏴',
    name: 'black flag',
    shortname: ':flag_black:',
    unicode: '1f3f4',
    html: '&#127988;',
    category: 'Flags (flag)',
    order: '2184',
  },
  {
    emoji: '🏳',
    name: 'white flag',
    shortname: ':flag_white:',
    unicode: '1f3f3',
    html: '&#127987;',
    category: 'Flags (flag)',
    order: '2185',
  },
  {
    emoji: '🎞',
    name: 'film frames',
    shortname: ':film_frames:',
    unicode: '1f39e',
    html: '&#127902;',
    category: 'Objects (light & video)',
    order: '1857',
  },
  {
    emoji: '🎛',
    name: 'control knobs',
    shortname: ':control_knobs:',
    unicode: '1f39b',
    html: '&#127899;',
    category: 'Objects (music)',
    order: '1828',
  },
  {
    emoji: '🎚',
    name: 'level slider',
    shortname: ':level_slider:',
    unicode: '1f39a',
    html: '&#127898;',
    category: 'Objects (music)',
    order: '1827',
  },
  {
    emoji: '🎙',
    name: 'studio microphone',
    shortname: ':studio_microphone:',
    unicode: '1F399',
    html: '&#127897;',
    category: 'Objects (music)',
    order: '',
  },
  {
    emoji: '🌡',
    name: 'thermometer',
    shortname: ':thermometer:',
    unicode: '1f321',
    html: '&#127777;',
    category: 'Travel & Places (sky & weather)',
    order: '1723',
  },
  {
    emoji: '🛳',
    name: 'passenger ship',
    shortname: ':passenger_ship:',
    unicode: '1F6F3',
    html: '&#128755;',
    category: 'Travel & Places (transport-water)',
    order: '',
  },
  {
    emoji: '🛰',
    name: 'satellite',
    shortname: ':satellite:',
    unicode: '1F6F0',
    html: '&#128752;',
    category: 'Travel & Places (transport-air)',
    order: '',
  },
  {
    emoji: '🛬',
    name: 'airplane arrival',
    shortname: ':airplane_arriving:',
    unicode: '1f6ec',
    html: '&#128748;',
    category: 'Travel & Places (transport-air)',
    order: '1653',
  },
  {
    emoji: '🛫',
    name: 'airplane departure',
    shortname: ':airplane_departure:',
    unicode: '1f6eb',
    html: '&#128747;',
    category: 'Travel & Places (transport-air)',
    order: '1652',
  },
  {
    emoji: '🛩',
    name: 'small airplane',
    shortname: ':small_airplane:',
    unicode: '1F6E9',
    html: '&#128745;',
    category: 'Travel & Places (transport-air)',
    order: '',
  },
  {
    emoji: '🛥',
    name: 'motor boat',
    shortname: ':motor_boat:',
    unicode: '1F6E5',
    html: '&#128741;',
    category: 'Travel & Places (transport-water)',
    order: '',
  },
  {
    emoji: '🛤',
    name: 'railway track',
    shortname: ':railway_track:',
    unicode: '1f6e4',
    html: '&#128740;',
    category: 'Travel & Places (transport-ground)',
    order: '1635',
  },
  {
    emoji: '🛣',
    name: 'motorway',
    shortname: ':motorway:',
    unicode: '1f6e3',
    html: '&#128739;',
    category: 'Travel & Places (transport-ground)',
    order: '1634',
  },
  {
    emoji: '🗺',
    name: 'world map',
    shortname: ':world_map:',
    unicode: '1F5FA',
    html: '&#128506;',
    category: 'Travel & Places (place-map)',
    order: '',
  },
  {
    emoji: '🕍',
    name: 'synagogue',
    shortname: ':synagogue:',
    unicode: '1f54d',
    html: '&#128333;',
    category: 'Travel & Places (place-religious)',
    order: '1579',
  },
  {
    emoji: '🕌',
    name: 'mosque',
    shortname: ':mosque:',
    unicode: '1f54c',
    html: '&#128332;',
    category: 'Travel & Places (place-religious)',
    order: '1578',
  },
  {
    emoji: '🕋',
    name: 'kaaba',
    shortname: ':kaaba:',
    unicode: '1f54b',
    html: '&#128331;',
    category: 'Travel & Places (place-religious)',
    order: '1581',
  },
  {
    emoji: '🏟',
    name: 'stadium',
    shortname: ':stadium:',
    unicode: '1f3df',
    html: '&#127967;',
    category: 'Travel & Places (place-building)',
    order: '1553',
  },
  {
    emoji: '🏞',
    name: 'national park',
    shortname: ':national_park:',
    unicode: '1F3DE',
    html: '&#127966;',
    category: 'Travel & Places (place-geographic)',
    order: '',
  },
  {
    emoji: '🏝',
    name: 'desert island',
    shortname: ':desert_island:',
    unicode: '1F3DD',
    html: '&#127965;',
    category: 'Travel & Places (place-geographic)',
    order: '',
  },
  {
    emoji: '🏜',
    name: 'desert',
    shortname: ':desert:',
    unicode: '1f3dc',
    html: '&#127964;',
    category: 'Travel & Places (place-geographic)',
    order: '1550',
  },
  {
    emoji: '🏛',
    name: 'classical building',
    shortname: ':classical_building:',
    unicode: '1f3db',
    html: '&#127963;',
    category: 'Travel & Places (place-building)',
    order: '1554',
  },
  {
    emoji: '🏚',
    name: 'derelict house',
    shortname: ':derelict_house:',
    unicode: '1F3DA',
    html: '&#127962;',
    category: 'Travel & Places (place-building)',
    order: '',
  },
  {
    emoji: '🏙',
    name: 'cityscape',
    shortname: ':cityscape:',
    unicode: '1f3d9',
    html: '&#127961;',
    category: 'Travel & Places (place-other)',
    order: '1557',
  },
  {
    emoji: '🏘',
    name: 'houses',
    shortname: ':houses:',
    unicode: '1F3D8',
    html: '&#127960;',
    category: 'Travel & Places (place-building)',
    order: '',
  },
  {
    emoji: '🏗',
    name: 'building construction',
    shortname: ':building_construction:',
    unicode: '1F3D7',
    html: '&#127959;',
    category: 'Travel & Places (place-building)',
    order: '',
  },
  {
    emoji: '🏖',
    name: 'beach with umbrella',
    shortname: ':beach_with_umbrella:',
    unicode: '1F3D6',
    html: '&#127958;',
    category: 'Travel & Places (place-geographic)',
    order: '',
  },
  {
    emoji: '🏕',
    name: 'camping',
    shortname: ':camping:',
    unicode: '1f3d5',
    html: '&#127957;',
    category: 'Travel & Places (place-geographic)',
    order: '1548',
  },
  {
    emoji: '🏔',
    name: 'snow-capped mountain',
    shortname: ':snowcapped_mountain:',
    unicode: '1F3D4',
    html: '&#127956;',
    category: 'Travel & Places (place-geographic)',
    order: '',
  },
  {
    emoji: '🏎',
    name: 'racing car',
    shortname: ':racing_car:',
    unicode: '1F3CE',
    html: '&#127950;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🏍',
    name: 'motorcycle',
    shortname: ':motorcycle:',
    unicode: '1F3CD',
    html: '&#127949;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🏹',
    name: 'bow and arrow',
    shortname: ':bow_and_arrow:',
    unicode: '1f3f9',
    html: '&#127993;',
    category: 'Objects (tool)',
    order: '1957',
  },
  {
    emoji: '🏸',
    name: 'badminton',
    shortname: ':badminton:',
    unicode: '1F3F8',
    html: '&#127992;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🏵',
    name: 'rosette',
    shortname: ':rosette:',
    unicode: '1f3f5',
    html: '&#127989;',
    category: 'Animals & Nature (plant-flower)',
    order: '1430',
  },
  {
    emoji: '🏓',
    name: 'ping pong',
    shortname: ':ping_pong:',
    unicode: '1F3D3',
    html: '&#127955;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🏒',
    name: 'ice hockey',
    shortname: ':ice_hockey:',
    unicode: '1F3D2',
    html: '&#127954;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🏑',
    name: 'field hockey',
    shortname: ':field_hockey:',
    unicode: '1F3D1',
    html: '&#127953;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🏐',
    name: 'volleyball',
    shortname: ':volleyball:',
    unicode: '1f3d0',
    html: '&#127952;',
    category: 'Activities (sport)',
    order: '1784',
  },
  {
    emoji: '🏏',
    name: 'cricket game',
    shortname: ':cricket_game:',
    unicode: '1F3CF',
    html: '&#127951;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🏅',
    name: 'sports medal',
    shortname: ':medal:',
    unicode: '1f3c5',
    html: '&#127941;',
    category: 'Activities (award-medal)',
    order: '1777',
  },
  {
    emoji: '🎟',
    name: 'admission tickets',
    shortname: ':admission_tickets:',
    unicode: '1F39F',
    html: '&#127903;',
    category: 'Activities (event)',
    order: '',
  },
  {
    emoji: '🎗',
    name: 'reminder ribbon',
    shortname: ':reminder_ribbon:',
    unicode: '1f397',
    html: '&#127895;',
    category: 'Activities (event)',
    order: '1772',
  },
  {
    emoji: '🎖',
    name: 'military medal',
    shortname: ':military_medal:',
    unicode: '1F396',
    html: '&#127894;',
    category: 'Activities (award-medal)',
    order: '',
  },
  {
    emoji: '🧀',
    name: 'cheese wedge',
    shortname: ':cheese_wedge:',
    unicode: '1F9C0',
    html: '&#129472;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🍿',
    name: 'popcorn',
    shortname: ':popcorn:',
    unicode: '1f37f',
    html: '&#127871;',
    category: 'Food & Drink (food-prepared)',
    order: '1494',
  },
  {
    emoji: '🍾',
    name: 'bottle with popping cork',
    shortname: ':champagne:',
    unicode: '1f37e',
    html: '&#127870;',
    category: 'Food & Drink (drink)',
    order: '1525',
  },
  {
    emoji: '🍽',
    name: 'fork and knife with plate',
    shortname: ':fork_and_knife_with_plate:',
    unicode: '1F37D',
    html: '&#127869;',
    category: 'Food & Drink (dishware)',
    order: '',
  },
  {
    emoji: '🌶',
    name: 'hot pepper',
    shortname: ':hot_pepper:',
    unicode: '1f336',
    html: '&#127798;',
    category: 'Food & Drink (food-vegetable)',
    order: '1469',
  },
  {
    emoji: '🌯',
    name: 'burrito',
    shortname: ':burrito:',
    unicode: '1f32f',
    html: '&#127791;',
    category: 'Food & Drink (food-prepared)',
    order: '1487',
  },
  {
    emoji: '🌮',
    name: 'taco',
    shortname: ':taco:',
    unicode: '1f32e',
    html: '&#127790;',
    category: 'Food & Drink (food-prepared)',
    order: '1486',
  },
  {
    emoji: '🌭',
    name: 'hot dog',
    shortname: ':hotdog:',
    unicode: '1f32d',
    html: '&#127789;',
    category: 'Food & Drink (food-prepared)',
    order: '1485',
  },
  {
    emoji: '☘',
    name: 'shamrock',
    shortname: ':shamrock:',
    unicode: '2618',
    html: '&#9752;',
    category: 'Animals & Nature (plant-other)',
    order: '1444',
  },
  {
    emoji: '☄',
    name: 'comet',
    shortname: ':comet:',
    unicode: '2604',
    html: '&#9732;',
    category: 'Travel & Places (sky & weather)',
    order: '1752',
  },
  {
    emoji: '☃️',
    name: 'snowman',
    shortname: ':snowman:',
    unicode: '2603 FE0F',
    html: '&#9731;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '☂️',
    name: 'umbrella',
    shortname: ':umbrella:',
    unicode: '2602 FE0F',
    html: '&#9730;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🦄',
    name: 'unicorn',
    shortname: ':unicorn:',
    unicode: '1F984',
    html: '&#129412;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦃',
    name: 'turkey',
    shortname: ':turkey:',
    unicode: '1f983',
    html: '&#129411;',
    category: 'Animals & Nature (animal-bird)',
    order: '1387',
  },
  {
    emoji: '🦂',
    name: 'scorpion',
    shortname: ':scorpion:',
    unicode: '1f982',
    html: '&#129410;',
    category: 'Animals & Nature (animal-bug)',
    order: '1426',
  },
  {
    emoji: '🦁',
    name: 'lion',
    shortname: ':lion_face:',
    unicode: '1f981',
    html: '&#129409;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1352',
  },
  {
    emoji: '🦀',
    name: 'crab',
    shortname: ':crab:',
    unicode: '1f980',
    html: '&#129408;',
    category: 'Food & Drink (food-marine)',
    order: '1415',
  },
  {
    emoji: '🕸',
    name: 'spider web',
    shortname: ':spider_web:',
    unicode: '1f578',
    html: '&#128376;',
    category: 'Animals & Nature (animal-bug)',
    order: '1425',
  },
  {
    emoji: '🕷',
    name: 'spider',
    shortname: ':spider:',
    unicode: '1f577',
    html: '&#128375;',
    category: 'Animals & Nature (animal-bug)',
    order: '1424',
  },
  {
    emoji: '🕊',
    name: 'dove',
    shortname: ':dove:',
    unicode: '1F54A',
    html: '&#128330;',
    category: 'Animals & Nature (animal-bird)',
    order: '',
  },
  {
    emoji: '🐿',
    name: 'chipmunk',
    shortname: ':chipmunk:',
    unicode: '1f43f',
    html: '&#128063;',
    category: 'Animals & Nature (animal-mammal)',
    order: '1381',
  },
  {
    emoji: '🌬',
    name: 'wind face',
    shortname: ':wind_blowing_face:',
    unicode: '1f32c',
    html: '&#127788;',
    category: 'Travel & Places (sky & weather)',
    order: '1741',
  },
  {
    emoji: '🌫',
    name: 'fog',
    shortname: ':fog:',
    unicode: '1f32b',
    html: '&#127787;',
    category: 'Travel & Places (sky & weather)',
    order: '1740',
  },
  {
    emoji: '🌪',
    name: 'tornado',
    shortname: ':tornado:',
    unicode: '1F32A',
    html: '&#127786;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌩',
    name: 'cloud with lightning',
    shortname: ':cloud_with_lightning:',
    unicode: '1F329',
    html: '&#127785;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌨',
    name: 'cloud with snow',
    shortname: ':cloud_with_snow:',
    unicode: '1F328',
    html: '&#127784;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌧',
    name: 'cloud with rain',
    shortname: ':cloud_with_rain:',
    unicode: '1F327',
    html: '&#127783;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌦',
    name: 'sun behind rain cloud',
    shortname: ':sun_behind_rain_cloud:',
    unicode: '1F326',
    html: '&#127782;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌥',
    name: 'sun behind large cloud',
    shortname: ':sun_behind_large_cloud:',
    unicode: '1F325',
    html: '&#127781;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌤',
    name: 'sun behind small cloud',
    shortname: ':sun_behind_small_cloud:',
    unicode: '1F324',
    html: '&#127780;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🗣',
    name: 'speaking head',
    shortname: ':speaking_head:',
    unicode: '1F5E3',
    html: '&#128483;',
    category: 'People & Body (person-symbol)',
    order: '',
  },
  {
    emoji: '⏺',
    name: 'record button',
    shortname: ':record_button:',
    unicode: '23FA',
    html: '&#9210;',
    category: 'Symbols (av-symbol)',
    order: '',
  },
  {
    emoji: '⏹',
    name: 'stop button',
    shortname: ':stop_button:',
    unicode: '23F9',
    html: '&#9209;',
    category: 'Symbols (av-symbol)',
    order: '',
  },
  {
    emoji: '⏸',
    name: 'pause button',
    shortname: ':pause_button:',
    unicode: '23F8',
    html: '&#9208;',
    category: 'Symbols (av-symbol)',
    order: '',
  },
  {
    emoji: '⏯',
    name: 'play or pause button',
    shortname: ':play_pause:',
    unicode: '23ef',
    html: '&#9199;',
    category: 'Symbols (av-symbol)',
    order: '2054',
  },
  {
    emoji: '⏮',
    name: 'last track button',
    shortname: ':track_previous:',
    unicode: '23ee',
    html: '&#9198;',
    category: 'Symbols (av-symbol)',
    order: '2057',
  },
  {
    emoji: '⏭',
    name: 'next track button',
    shortname: ':track_next:',
    unicode: '23ed',
    html: '&#9197;',
    category: 'Symbols (av-symbol)',
    order: '2053',
  },
  {
    emoji: '⛱',
    name: 'umbrella on ground',
    shortname: ':beach_umbrella:',
    unicode: '26f1',
    html: '&#9969;',
    category: 'Travel & Places (sky & weather)',
    order: '1747',
  },
  {
    emoji: '⛓',
    name: 'chains',
    shortname: ':chains:',
    unicode: '26d3',
    html: '&#9939;',
    category: 'Objects (tool)',
    order: '1966',
  },
  {
    emoji: '⛏',
    name: 'pick',
    shortname: ':pick:',
    unicode: '26cf',
    html: '&#9935;',
    category: 'Objects (tool)',
    order: '1951',
  },
  {
    emoji: '⚒',
    name: 'hammer and pick',
    shortname: ':hammer_and_pick:',
    unicode: '2692',
    html: '&#9874;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '⏲',
    name: 'timer clock',
    shortname: ':timer_clock:',
    unicode: '23F2',
    html: '&#9202;',
    category: 'Travel & Places (time)',
    order: '',
  },
  {
    emoji: '⏱',
    name: 'stopwatch',
    shortname: ':stopwatch:',
    unicode: '23f1',
    html: '&#9201;',
    category: 'Travel & Places (time)',
    order: '1684',
  },
  {
    emoji: '⛴',
    name: 'ferry',
    shortname: ':ferry:',
    unicode: '26f4',
    html: '&#9972;',
    category: 'Travel & Places (transport-water)',
    order: '1647',
  },
  {
    emoji: '⛰',
    name: 'mountain',
    shortname: ':mountain:',
    unicode: '26f0',
    html: '&#9968;',
    category: 'Travel & Places (place-geographic)',
    order: '1545',
  },
  {
    emoji: '⛩',
    name: 'shinto shrine',
    shortname: ':shinto_shrine:',
    unicode: '2.6E+10',
    html: '&#9961;',
    category: 'Travel & Places (place-religious)',
    order: '1580',
  },
  {
    emoji: '⛸',
    name: 'ice skate',
    shortname: ':ice_skate:',
    unicode: '26f8',
    html: '&#9976;',
    category: 'Activities (sport)',
    order: '1800',
  },
  {
    emoji: '⛷',
    name: 'skier',
    shortname: ':skier:',
    unicode: '26f7',
    html: '&#9975;',
    category: 'People & Body (person-sport)',
    order: '775',
  },
  {
    emoji: '⛈',
    name: 'cloud with lightning and rain',
    shortname: ':cloud_with_lightning_and_rain:',
    unicode: '26C8',
    html: '&#9928;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '⛑',
    name: 'rescue worker’s helmet',
    shortname: ':rescue_worker’s_helmet:',
    unicode: '26D1',
    html: '&#9937;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🇦🇨',
    name: 'flag: Ascension Island',
    shortname: ':flag_ac:',
    unicode: '1f1e6-1f1e8',
    html: '&#127462;&#127464;',
    category: 'Flags (country-flag)',
    order: '2187',
  },
  {
    emoji: '🇦🇩',
    name: 'flag: Andorra',
    shortname: ':flag_ad:',
    unicode: '1f1e6-1f1e9',
    html: '&#127462;&#127465;',
    category: 'Flags (country-flag)',
    order: '2188',
  },
  {
    emoji: '🇦🇪',
    name: 'flag: United Arab Emirates',
    shortname: ':flag_ae:',
    unicode: '1f1e6-1f1ea',
    html: '&#127462;&#127466;',
    category: 'Flags (country-flag)',
    order: '2189',
  },
  {
    emoji: '🇦🇫',
    name: 'flag: Afghanistan',
    shortname: ':flag_af:',
    unicode: '1f1e6-1f1eb',
    html: '&#127462;&#127467;',
    category: 'Flags (country-flag)',
    order: '2190',
  },
  {
    emoji: '🇦🇬',
    name: 'flag: Antigua & Barbuda',
    shortname: ':flag_ag:',
    unicode: '1f1e6-1f1ec',
    html: '&#127462;&#127468;',
    category: 'Flags (country-flag)',
    order: '2191',
  },
  {
    emoji: '🇦🇮',
    name: 'flag: Anguilla',
    shortname: ':flag_ai:',
    unicode: '1f1e6-1f1ee',
    html: '&#127462;&#127470;',
    category: 'Flags (country-flag)',
    order: '2192',
  },
  {
    emoji: '🇦🇱',
    name: 'flag: Albania',
    shortname: ':flag_al:',
    unicode: '1f1e6-1f1f1',
    html: '&#127462;&#127473;',
    category: 'Flags (country-flag)',
    order: '2193',
  },
  {
    emoji: '🇦🇲',
    name: 'flag: Armenia',
    shortname: ':flag_am:',
    unicode: '1f1e6-1f1f2',
    html: '&#127462;&#127474;',
    category: 'Flags (country-flag)',
    order: '2194',
  },
  {
    emoji: '🇦🇴',
    name: 'flag: Angola',
    shortname: ':flag-ao:',
    unicode: '1f1e6-1f1f4',
    html: '&#127462;&#127476;',
    category: 'Flags (country-flag)',
    order: '2195',
  },
  {
    emoji: '🇦🇶',
    name: 'flag: Antarctica',
    shortname: ':flag-aq:',
    unicode: '1f1e6-1f1f6',
    html: '&#127462;&#127478;',
    category: 'Flags (country-flag)',
    order: '2196',
  },
  {
    emoji: '🇦🇷',
    name: 'flag: Argentina',
    shortname: ':flag-ar:',
    unicode: '1f1e6-1f1f7',
    html: '&#127462;&#127479;',
    category: 'Flags (country-flag)',
    order: '2197',
  },
  {
    emoji: '🇦🇸',
    name: 'flag: American Samoa',
    shortname: ':flag-as:',
    unicode: '1f1e6-1f1f8',
    html: '&#127462;&#127480;',
    category: 'Flags (country-flag)',
    order: '2198',
  },
  {
    emoji: '🇦🇹',
    name: 'flag: Austria',
    shortname: ':flag-at:',
    unicode: '1f1e6-1f1f9',
    html: '&#127462;&#127481;',
    category: 'Flags (country-flag)',
    order: '2199',
  },
  {
    emoji: '🇦🇺',
    name: 'flag: Australia',
    shortname: ':flag-au:',
    unicode: '1f1e6-1f1fa',
    html: '&#127462;&#127482;',
    category: 'Flags (country-flag)',
    order: '2200',
  },
  {
    emoji: '🇦🇼',
    name: 'flag: Aruba',
    shortname: ':flag-aw:',
    unicode: '1f1e6-1f1fc',
    html: '&#127462;&#127484;',
    category: 'Flags (country-flag)',
    order: '2201',
  },
  {
    emoji: '🇦🇽',
    name: 'flag: Åland Islands',
    shortname: ':flag-ax:',
    unicode: '1f1e6-1f1fd',
    html: '&#127462;&#127485;',
    category: 'Flags (country-flag)',
    order: '2202',
  },
  {
    emoji: '🇦🇿',
    name: 'flag: Azerbaijan',
    shortname: ':flag-az:',
    unicode: '1f1e6-1f1ff',
    html: '&#127462;&#127487;',
    category: 'Flags (country-flag)',
    order: '2203',
  },
  {
    emoji: '🇧🇦',
    name: 'flag: Bosnia & Herzegovina',
    shortname: ':flag-ba:',
    unicode: '1f1e7-1f1e6',
    html: '&#127463;&#127462;',
    category: 'Flags (country-flag)',
    order: '2204',
  },
  {
    emoji: '🇧🇧',
    name: 'flag: Barbados',
    shortname: ':flag-bb:',
    unicode: '1f1e7-1f1e7',
    html: '&#127463;&#127463;',
    category: 'Flags (country-flag)',
    order: '2205',
  },
  {
    emoji: '🇧🇩',
    name: 'flag: Bangladesh',
    shortname: ':flag-bd:',
    unicode: '1f1e7-1f1e9',
    html: '&#127463;&#127465;',
    category: 'Flags (country-flag)',
    order: '2206',
  },
  {
    emoji: '🇧🇪',
    name: 'flag: Belgium',
    shortname: ':flag-be:',
    unicode: '1f1e7-1f1ea',
    html: '&#127463;&#127466;',
    category: 'Flags (country-flag)',
    order: '2207',
  },
  {
    emoji: '🇧🇫',
    name: 'flag: Burkina Faso',
    shortname: ':flag-bf:',
    unicode: '1f1e7-1f1eb',
    html: '&#127463;&#127467;',
    category: 'Flags (country-flag)',
    order: '2208',
  },
  {
    emoji: '🇧🇬',
    name: 'flag: Bulgaria',
    shortname: ':flag-bg:',
    unicode: '1f1e7-1f1ec',
    html: '&#127463;&#127468;',
    category: 'Flags (country-flag)',
    order: '2209',
  },
  {
    emoji: '🇧🇭',
    name: 'flag: Bahrain',
    shortname: ':flag-bh:',
    unicode: '1f1e7-1f1ed',
    html: '&#127463;&#127469;',
    category: 'Flags (country-flag)',
    order: '2210',
  },
  {
    emoji: '🇧🇮',
    name: 'flag: Burundi',
    shortname: ':flag-bi:',
    unicode: '1f1e7-1f1ee',
    html: '&#127463;&#127470;',
    category: 'Flags (country-flag)',
    order: '2211',
  },
  {
    emoji: '🇧🇯',
    name: 'flag: Benin',
    shortname: ':flag-bj:',
    unicode: '1f1e7-1f1ef',
    html: '&#127463;&#127471;',
    category: 'Flags (country-flag)',
    order: '2212',
  },
  {
    emoji: '🇧🇱',
    name: 'flag: St. Barthélemy',
    shortname: ':flag-bl:',
    unicode: '1f1e7-1f1f1',
    html: '&#127463;&#127473;',
    category: 'Flags (country-flag)',
    order: '2213',
  },
  {
    emoji: '🇧🇲',
    name: 'flag: Bermuda',
    shortname: ':flag-bm:',
    unicode: '1f1e7-1f1f2',
    html: '&#127463;&#127474;',
    category: 'Flags (country-flag)',
    order: '2214',
  },
  {
    emoji: '🇧🇳',
    name: 'flag: Brunei',
    shortname: ':flag-bn:',
    unicode: '1f1e7-1f1f3',
    html: '&#127463;&#127475;',
    category: 'Flags (country-flag)',
    order: '2215',
  },
  {
    emoji: '🇧🇴',
    name: 'flag: Bolivia',
    shortname: ':flag-bo:',
    unicode: '1f1e7-1f1f4',
    html: '&#127463;&#127476;',
    category: 'Flags (country-flag)',
    order: '2216',
  },
  {
    emoji: '🇧🇶',
    name: 'flag: Caribbean Netherlands',
    shortname: ':flag-bq:',
    unicode: '1f1e7-1f1f6',
    html: '&#127463;&#127478;',
    category: 'Flags (country-flag)',
    order: '2217',
  },
  {
    emoji: '🇧🇷',
    name: 'flag: Brazil',
    shortname: ':flag-br:',
    unicode: '1f1e7-1f1f7',
    html: '&#127463;&#127479;',
    category: 'Flags (country-flag)',
    order: '2218',
  },
  {
    emoji: '🇧🇸',
    name: 'flag: Bahamas',
    shortname: ':flag-bs:',
    unicode: '1f1e7-1f1f8',
    html: '&#127463;&#127480;',
    category: 'Flags (country-flag)',
    order: '2219',
  },
  {
    emoji: '🇧🇹',
    name: 'flag: Bhutan',
    shortname: ':flag-bt:',
    unicode: '1f1e7-1f1f9',
    html: '&#127463;&#127481;',
    category: 'Flags (country-flag)',
    order: '2220',
  },
  {
    emoji: '🇧🇻',
    name: 'flag: Bouvet Island',
    shortname: ':flag-bv:',
    unicode: '1f1e7-1f1fb',
    html: '&#127463;&#127483;',
    category: 'Flags (country-flag)',
    order: '2221',
  },
  {
    emoji: '🇧🇼',
    name: 'flag: Botswana',
    shortname: ':flag-bw:',
    unicode: '1f1e7-1f1fc',
    html: '&#127463;&#127484;',
    category: 'Flags (country-flag)',
    order: '2222',
  },
  {
    emoji: '🇧🇾',
    name: 'flag: Belarus',
    shortname: ':flag-by:',
    unicode: '1f1e7-1f1fe',
    html: '&#127463;&#127486;',
    category: 'Flags (country-flag)',
    order: '2223',
  },
  {
    emoji: '🇧🇿',
    name: 'flag: Belize',
    shortname: ':flag-bz:',
    unicode: '1f1e7-1f1ff',
    html: '&#127463;&#127487;',
    category: 'Flags (country-flag)',
    order: '2224',
  },
  {
    emoji: '🇨🇦',
    name: 'flag: Canada',
    shortname: ':flag-ca:',
    unicode: '1f1e8-1f1e6',
    html: '&#127464;&#127462;',
    category: 'Flags (country-flag)',
    order: '2225',
  },
  {
    emoji: '🇨🇨',
    name: 'flag: Cocos (Keeling) Islands',
    shortname: ':flag-cc:',
    unicode: '1f1e8-1f1e8',
    html: '&#127464;&#127464;',
    category: 'Flags (country-flag)',
    order: '2226',
  },
  {
    emoji: '🇨🇩',
    name: 'flag: Congo - Kinshasa',
    shortname: ':flag-cd:',
    unicode: '1f1e8-1f1e9',
    html: '&#127464;&#127465;',
    category: 'Flags (country-flag)',
    order: '2227',
  },
  {
    emoji: '🇨🇫',
    name: 'flag: Central African Republic',
    shortname: ':flag-cf:',
    unicode: '1f1e8-1f1eb',
    html: '&#127464;&#127467;',
    category: 'Flags (country-flag)',
    order: '2228',
  },
  {
    emoji: '🇨🇬',
    name: 'flag: Congo - Brazzaville',
    shortname: ':flag-cg:',
    unicode: '1f1e8-1f1ec',
    html: '&#127464;&#127468;',
    category: 'Flags (country-flag)',
    order: '2229',
  },
  {
    emoji: '🇨🇭',
    name: 'flag: Switzerland',
    shortname: ':flag-ch:',
    unicode: '1f1e8-1f1ed',
    html: '&#127464;&#127469;',
    category: 'Flags (country-flag)',
    order: '2230',
  },
  {
    emoji: '🇨🇮',
    name: 'flag: Côte d’Ivoire',
    shortname: ':flag-ci:',
    unicode: '1f1e8-1f1ee',
    html: '&#127464;&#127470;',
    category: 'Flags (country-flag)',
    order: '2231',
  },
  {
    emoji: '🇨🇰',
    name: 'flag: Cook Islands',
    shortname: ':flag-ck:',
    unicode: '1f1e8-1f1f0',
    html: '&#127464;&#127472;',
    category: 'Flags (country-flag)',
    order: '2232',
  },
  {
    emoji: '🇨🇱',
    name: 'flag: Chile',
    shortname: ':flag-cl:',
    unicode: '1f1e8-1f1f1',
    html: '&#127464;&#127473;',
    category: 'Flags (country-flag)',
    order: '2233',
  },
  {
    emoji: '🇨🇲',
    name: 'flag: Cameroon',
    shortname: ':flag-cm:',
    unicode: '1f1e8-1f1f2',
    html: '&#127464;&#127474;',
    category: 'Flags (country-flag)',
    order: '2234',
  },
  {
    emoji: '🇨🇳',
    name: 'flag: China',
    shortname: ':flag-cn:',
    unicode: '1f1e8-1f1f3',
    html: '&#127464;&#127475;',
    category: 'Flags (country-flag)',
    order: '2235',
  },
  {
    emoji: '🇨🇴',
    name: 'flag: Colombia',
    shortname: ':flag-co:',
    unicode: '1f1e8-1f1f4',
    html: '&#127464;&#127476;',
    category: 'Flags (country-flag)',
    order: '2236',
  },
  {
    emoji: '🇨🇵',
    name: 'flag: Clipperton Island',
    shortname: ':flag-cp:',
    unicode: '1f1e8-1f1f5',
    html: '&#127464;&#127477;',
    category: 'Flags (country-flag)',
    order: '2237',
  },
  {
    emoji: '🇨🇷',
    name: 'flag: Costa Rica',
    shortname: ':flag-cr:',
    unicode: '1f1e8-1f1f7',
    html: '&#127464;&#127479;',
    category: 'Flags (country-flag)',
    order: '2238',
  },
  {
    emoji: '🇨🇺',
    name: 'flag: Cuba',
    shortname: ':flag-cu:',
    unicode: '1f1e8-1f1fa',
    html: '&#127464;&#127482;',
    category: 'Flags (country-flag)',
    order: '2239',
  },
  {
    emoji: '🇨🇻',
    name: 'flag: Cape Verde',
    shortname: ':flag-cv:',
    unicode: '1f1e8-1f1fb',
    html: '&#127464;&#127483;',
    category: 'Flags (country-flag)',
    order: '2240',
  },
  {
    emoji: '🇨🇼',
    name: 'flag: Curaçao',
    shortname: ':flag-cw:',
    unicode: '1f1e8-1f1fc',
    html: '&#127464;&#127484;',
    category: 'Flags (country-flag)',
    order: '2241',
  },
  {
    emoji: '🇨🇽',
    name: 'flag: Christmas Island',
    shortname: ':flag-cx:',
    unicode: '1f1e8-1f1fd',
    html: '&#127464;&#127485;',
    category: 'Flags (country-flag)',
    order: '2242',
  },
  {
    emoji: '🇨🇾',
    name: 'flag: Cyprus',
    shortname: ':flag-cy:',
    unicode: '1f1e8-1f1fe',
    html: '&#127464;&#127486;',
    category: 'Flags (country-flag)',
    order: '2243',
  },
  {
    emoji: '🇨🇿',
    name: 'flag: Czechia',
    shortname: ':flag-cz:',
    unicode: '1f1e8-1f1ff',
    html: '&#127464;&#127487;',
    category: 'Flags (country-flag)',
    order: '2244',
  },
  {
    emoji: '🇩🇪',
    name: 'flag: Germany',
    shortname: ':flag-de:',
    unicode: '1f1e9-1f1ea',
    html: '&#127465;&#127466;',
    category: 'Flags (country-flag)',
    order: '2245',
  },
  {
    emoji: '🇩🇬',
    name: 'flag: Diego Garcia',
    shortname: ':flag-dg:',
    unicode: '1f1e9-1f1ec',
    html: '&#127465;&#127468;',
    category: 'Flags (country-flag)',
    order: '2246',
  },
  {
    emoji: '🇩🇯',
    name: 'flag: Djibouti',
    shortname: ':flag-dj:',
    unicode: '1f1e9-1f1ef',
    html: '&#127465;&#127471;',
    category: 'Flags (country-flag)',
    order: '2247',
  },
  {
    emoji: '🇩🇰',
    name: 'flag: Denmark',
    shortname: ':flag-dk:',
    unicode: '1f1e9-1f1f0',
    html: '&#127465;&#127472;',
    category: 'Flags (country-flag)',
    order: '2248',
  },
  {
    emoji: '🇩🇲',
    name: 'flag: Dominica',
    shortname: ':flag-dm:',
    unicode: '1f1e9-1f1f2',
    html: '&#127465;&#127474;',
    category: 'Flags (country-flag)',
    order: '2249',
  },
  {
    emoji: '🇩🇴',
    name: 'flag: Dominican Republic',
    shortname: ':flag-do:',
    unicode: '1f1e9-1f1f4',
    html: '&#127465;&#127476;',
    category: 'Flags (country-flag)',
    order: '2250',
  },
  {
    emoji: '🇩🇿',
    name: 'flag: Algeria',
    shortname: ':flag-dz:',
    unicode: '1f1e9-1f1ff',
    html: '&#127465;&#127487;',
    category: 'Flags (country-flag)',
    order: '2251',
  },
  {
    emoji: '🇪🇦',
    name: 'flag: Ceuta & Melilla',
    shortname: ':flag-ea:',
    unicode: '1f1ea-1f1e6',
    html: '&#127466;&#127462;',
    category: 'Flags (country-flag)',
    order: '2252',
  },
  {
    emoji: '🇪🇨',
    name: 'flag: Ecuador',
    shortname: ':flag-ec:',
    unicode: '1f1ea-1f1e8',
    html: '&#127466;&#127464;',
    category: 'Flags (country-flag)',
    order: '2253',
  },
  {
    emoji: '🇪🇪',
    name: 'flag: Estonia',
    shortname: ':flag-ee:',
    unicode: '1f1ea-1f1ea',
    html: '&#127466;&#127466;',
    category: 'Flags (country-flag)',
    order: '2254',
  },
  {
    emoji: '🇪🇬',
    name: 'flag: Egypt',
    shortname: ':flag-eg:',
    unicode: '1f1ea-1f1ec',
    html: '&#127466;&#127468;',
    category: 'Flags (country-flag)',
    order: '2255',
  },
  {
    emoji: '🇪🇭',
    name: 'flag: Western Sahara',
    shortname: ':flag-eh:',
    unicode: '1f1ea-1f1ed',
    html: '&#127466;&#127469;',
    category: 'Flags (country-flag)',
    order: '2256',
  },
  {
    emoji: '🇪🇷',
    name: 'flag: Eritrea',
    shortname: ':flag-er:',
    unicode: '1f1ea-1f1f7',
    html: '&#127466;&#127479;',
    category: 'Flags (country-flag)',
    order: '2257',
  },
  {
    emoji: '🇪🇸',
    name: 'flag: Spain',
    shortname: ':flag-es:',
    unicode: '1f1ea-1f1f8',
    html: '&#127466;&#127480;',
    category: 'Flags (country-flag)',
    order: '2258',
  },
  {
    emoji: '🇪🇹',
    name: 'flag: Ethiopia',
    shortname: ':flag-et:',
    unicode: '1f1ea-1f1f9',
    html: '&#127466;&#127481;',
    category: 'Flags (country-flag)',
    order: '2259',
  },
  {
    emoji: '🇪🇺',
    name: 'flag: European Union',
    shortname: ':flag-eu:',
    unicode: '1f1ea-1f1fa',
    html: '&#127466;&#127482;',
    category: 'Flags (country-flag)',
    order: '2260',
  },
  {
    emoji: '🇫🇮',
    name: 'flag: Finland',
    shortname: ':flag-fi:',
    unicode: '1f1eb-1f1ee',
    html: '&#127467;&#127470;',
    category: 'Flags (country-flag)',
    order: '2261',
  },
  {
    emoji: '🇫🇯',
    name: 'flag: Fiji',
    shortname: ':flag-fj:',
    unicode: '1f1eb-1f1ef',
    html: '&#127467;&#127471;',
    category: 'Flags (country-flag)',
    order: '2262',
  },
  {
    emoji: '🇫🇰',
    name: 'flag: Falkland Islands',
    shortname: ':flag-fk:',
    unicode: '1f1eb-1f1f0',
    html: '&#127467;&#127472;',
    category: 'Flags (country-flag)',
    order: '2263',
  },
  {
    emoji: '🇫🇲',
    name: 'flag: Micronesia',
    shortname: ':flag-fm:',
    unicode: '1f1eb-1f1f2',
    html: '&#127467;&#127474;',
    category: 'Flags (country-flag)',
    order: '2264',
  },
  {
    emoji: '🇫🇴',
    name: 'flag: Faroe Islands',
    shortname: ':flag-fo:',
    unicode: '1f1eb-1f1f4',
    html: '&#127467;&#127476;',
    category: 'Flags (country-flag)',
    order: '2265',
  },
  {
    emoji: '🇫🇷',
    name: 'flag: France',
    shortname: ':flag-fr:',
    unicode: '1f1eb-1f1f7',
    html: '&#127467;&#127479;',
    category: 'Flags (country-flag)',
    order: '2266',
  },
  {
    emoji: '🇬🇦',
    name: 'flag: Gabon',
    shortname: ':flag-ga:',
    unicode: '1f1ec-1f1e6',
    html: '&#127468;&#127462;',
    category: 'Flags (country-flag)',
    order: '2267',
  },
  {
    emoji: '🇬🇧',
    name: 'flag: United Kingdom',
    shortname: ':flag-gb:',
    unicode: '1f1ec-1f1e7',
    html: '&#127468;&#127463;',
    category: 'Flags (country-flag)',
    order: '2268',
  },
  {
    emoji: '🇬🇩',
    name: 'flag: Grenada',
    shortname: ':flag-gd:',
    unicode: '1f1ec-1f1e9',
    html: '&#127468;&#127465;',
    category: 'Flags (country-flag)',
    order: '2269',
  },
  {
    emoji: '🇬🇪',
    name: 'flag: Georgia',
    shortname: ':flag-ge:',
    unicode: '1f1ec-1f1ea',
    html: '&#127468;&#127466;',
    category: 'Flags (country-flag)',
    order: '2270',
  },
  {
    emoji: '🇬🇫',
    name: 'flag: French Guiana',
    shortname: ':flag-gf:',
    unicode: '1f1ec-1f1eb',
    html: '&#127468;&#127467;',
    category: 'Flags (country-flag)',
    order: '2271',
  },
  {
    emoji: '🇬🇬',
    name: 'flag: Guernsey',
    shortname: ':flag-gg:',
    unicode: '1f1ec-1f1ec',
    html: '&#127468;&#127468;',
    category: 'Flags (country-flag)',
    order: '2272',
  },
  {
    emoji: '🇬🇭',
    name: 'flag: Ghana',
    shortname: ':flag-gh:',
    unicode: '1f1ec-1f1ed',
    html: '&#127468;&#127469;',
    category: 'Flags (country-flag)',
    order: '2273',
  },
  {
    emoji: '🇬🇮',
    name: 'flag: Gibraltar',
    shortname: ':flag-gi:',
    unicode: '1f1ec-1f1ee',
    html: '&#127468;&#127470;',
    category: 'Flags (country-flag)',
    order: '2274',
  },
  {
    emoji: '🇬🇱',
    name: 'flag: Greenland',
    shortname: ':flag-gl:',
    unicode: '1f1ec-1f1f1',
    html: '&#127468;&#127473;',
    category: 'Flags (country-flag)',
    order: '2275',
  },
  {
    emoji: '🇬🇲',
    name: 'flag: Gambia',
    shortname: ':flag-gm:',
    unicode: '1f1ec-1f1f2',
    html: '&#127468;&#127474;',
    category: 'Flags (country-flag)',
    order: '2276',
  },
  {
    emoji: '🇬🇳',
    name: 'flag: Guinea',
    shortname: ':flag-gn:',
    unicode: '1f1ec-1f1f3',
    html: '&#127468;&#127475;',
    category: 'Flags (country-flag)',
    order: '2277',
  },
  {
    emoji: '🇬🇵',
    name: 'flag: Guadeloupe',
    shortname: ':flag-gp:',
    unicode: '1f1ec-1f1f5',
    html: '&#127468;&#127477;',
    category: 'Flags (country-flag)',
    order: '2278',
  },
  {
    emoji: '🇬🇶',
    name: 'flag: Equatorial Guinea',
    shortname: ':flag-gq:',
    unicode: '1f1ec-1f1f6',
    html: '&#127468;&#127478;',
    category: 'Flags (country-flag)',
    order: '2279',
  },
  {
    emoji: '🇬🇷',
    name: 'flag: Greece',
    shortname: ':flag-gr:',
    unicode: '1f1ec-1f1f7',
    html: '&#127468;&#127479;',
    category: 'Flags (country-flag)',
    order: '2280',
  },
  {
    emoji: '🇬🇸',
    name: 'flag: South Georgia & South Sandwich Islands',
    shortname: ':flag-gs:',
    unicode: '1f1ec-1f1f8',
    html: '&#127468;&#127480;',
    category: 'Flags (country-flag)',
    order: '2281',
  },
  {
    emoji: '🇬🇹',
    name: 'flag: Guatemala',
    shortname: ':flag-gt:',
    unicode: '1f1ec-1f1f9',
    html: '&#127468;&#127481;',
    category: 'Flags (country-flag)',
    order: '2282',
  },
  {
    emoji: '🇬🇺',
    name: 'flag: Guam',
    shortname: ':flag-gu:',
    unicode: '1f1ec-1f1fa',
    html: '&#127468;&#127482;',
    category: 'Flags (country-flag)',
    order: '2283',
  },
  {
    emoji: '🇬🇼',
    name: 'flag: Guinea-Bissau',
    shortname: ':flag-gw:',
    unicode: '1f1ec-1f1fc',
    html: '&#127468;&#127484;',
    category: 'Flags (country-flag)',
    order: '2284',
  },
  {
    emoji: '🇬🇾',
    name: 'flag: Guyana',
    shortname: ':flag-gy:',
    unicode: '1f1ec-1f1fe',
    html: '&#127468;&#127486;',
    category: 'Flags (country-flag)',
    order: '2285',
  },
  {
    emoji: '🇭🇰',
    name: 'flag: Hong Kong SAR China',
    shortname: ':flag-hk:',
    unicode: '1f1ed-1f1f0',
    html: '&#127469;&#127472;',
    category: 'Flags (country-flag)',
    order: '2286',
  },
  {
    emoji: '🇭🇲',
    name: 'flag: Heard & McDonald Islands',
    shortname: ':flag-hm:',
    unicode: '1f1ed-1f1f2',
    html: '&#127469;&#127474;',
    category: 'Flags (country-flag)',
    order: '2287',
  },
  {
    emoji: '🇭🇳',
    name: 'flag: Honduras',
    shortname: ':flag-hn:',
    unicode: '1f1ed-1f1f3',
    html: '&#127469;&#127475;',
    category: 'Flags (country-flag)',
    order: '2288',
  },
  {
    emoji: '🇭🇷',
    name: 'flag: Croatia',
    shortname: ':flag-hr:',
    unicode: '1f1ed-1f1f7',
    html: '&#127469;&#127479;',
    category: 'Flags (country-flag)',
    order: '2289',
  },
  {
    emoji: '🇭🇹',
    name: 'flag: Haiti',
    shortname: ':flag-ht:',
    unicode: '1f1ed-1f1f9',
    html: '&#127469;&#127481;',
    category: 'Flags (country-flag)',
    order: '2290',
  },
  {
    emoji: '🇭🇺',
    name: 'flag: Hungary',
    shortname: ':flag-hu:',
    unicode: '1f1ed-1f1fa',
    html: '&#127469;&#127482;',
    category: 'Flags (country-flag)',
    order: '2291',
  },
  {
    emoji: '🇮🇨',
    name: 'flag: Canary Islands',
    shortname: ':flag-ic:',
    unicode: '1f1ee-1f1e8',
    html: '&#127470;&#127464;',
    category: 'Flags (country-flag)',
    order: '2292',
  },
  {
    emoji: '🇮🇩',
    name: 'flag: Indonesia',
    shortname: ':flag-id:',
    unicode: '1f1ee-1f1e9',
    html: '&#127470;&#127465;',
    category: 'Flags (country-flag)',
    order: '2293',
  },
  {
    emoji: '🇮🇪',
    name: 'flag: Ireland',
    shortname: ':flag-ie:',
    unicode: '1f1ee-1f1ea',
    html: '&#127470;&#127466;',
    category: 'Flags (country-flag)',
    order: '2294',
  },
  {
    emoji: '🇮🇱',
    name: 'flag: Israel',
    shortname: ':flag-il:',
    unicode: '1f1ee-1f1f1',
    html: '&#127470;&#127473;',
    category: 'Flags (country-flag)',
    order: '2295',
  },
  {
    emoji: '🇮🇲',
    name: 'flag: Isle of Man',
    shortname: ':flag-im:',
    unicode: '1f1ee-1f1f2',
    html: '&#127470;&#127474;',
    category: 'Flags (country-flag)',
    order: '2296',
  },
  {
    emoji: '🇮🇳',
    name: 'flag: India',
    shortname: ':flag-in:',
    unicode: '1f1ee-1f1f3',
    html: '&#127470;&#127475;',
    category: 'Flags (country-flag)',
    order: '2297',
  },
  {
    emoji: '🇮🇴',
    name: 'flag: British Indian Ocean Territory',
    shortname: ':flag-io:',
    unicode: '1f1ee-1f1f4',
    html: '&#127470;&#127476;',
    category: 'Flags (country-flag)',
    order: '2298',
  },
  {
    emoji: '🇮🇶',
    name: 'flag: Iraq',
    shortname: ':flag-iq:',
    unicode: '1f1ee-1f1f6',
    html: '&#127470;&#127478;',
    category: 'Flags (country-flag)',
    order: '2299',
  },
  {
    emoji: '🇮🇷',
    name: 'flag: Iran',
    shortname: ':flag-ir:',
    unicode: '1f1ee-1f1f7',
    html: '&#127470;&#127479;',
    category: 'Flags (country-flag)',
    order: '2300',
  },
  {
    emoji: '🇮🇸',
    name: 'flag: Iceland',
    shortname: ':flag-is:',
    unicode: '1f1ee-1f1f8',
    html: '&#127470;&#127480;',
    category: 'Flags (country-flag)',
    order: '2301',
  },
  {
    emoji: '🇮🇹',
    name: 'flag: Italy',
    shortname: ':flag-it:',
    unicode: '1f1ee-1f1f9',
    html: '&#127470;&#127481;',
    category: 'Flags (country-flag)',
    order: '2302',
  },
  {
    emoji: '🇯🇪',
    name: 'flag: Jersey',
    shortname: ':flag-je:',
    unicode: '1f1ef-1f1ea',
    html: '&#127471;&#127466;',
    category: 'Flags (country-flag)',
    order: '2303',
  },
  {
    emoji: '🇯🇲',
    name: 'flag: Jamaica',
    shortname: ':flag-jm:',
    unicode: '1f1ef-1f1f2',
    html: '&#127471;&#127474;',
    category: 'Flags (country-flag)',
    order: '2304',
  },
  {
    emoji: '🇯🇴',
    name: 'flag: Jordan',
    shortname: ':flag-jo:',
    unicode: '1f1ef-1f1f4',
    html: '&#127471;&#127476;',
    category: 'Flags (country-flag)',
    order: '2305',
  },
  {
    emoji: '🇯🇵',
    name: 'flag: Japan',
    shortname: ':flag-jp:',
    unicode: '1f1ef-1f1f5',
    html: '&#127471;&#127477;',
    category: 'Flags (country-flag)',
    order: '2306',
  },
  {
    emoji: '🇰🇪',
    name: 'flag: Kenya',
    shortname: ':flag-ke:',
    unicode: '1f1f0-1f1ea',
    html: '&#127472;&#127466;',
    category: 'Flags (country-flag)',
    order: '2307',
  },
  {
    emoji: '🇰🇬',
    name: 'flag: Kyrgyzstan',
    shortname: ':flag-kg:',
    unicode: '1f1f0-1f1ec',
    html: '&#127472;&#127468;',
    category: 'Flags (country-flag)',
    order: '2308',
  },
  {
    emoji: '🇰🇭',
    name: 'flag: Cambodia',
    shortname: ':flag-kh:',
    unicode: '1f1f0-1f1ed',
    html: '&#127472;&#127469;',
    category: 'Flags (country-flag)',
    order: '2309',
  },
  {
    emoji: '🇰🇮',
    name: 'flag: Kiribati',
    shortname: ':flag-ki:',
    unicode: '1f1f0-1f1ee',
    html: '&#127472;&#127470;',
    category: 'Flags (country-flag)',
    order: '2310',
  },
  {
    emoji: '🇰🇲',
    name: 'flag: Comoros',
    shortname: ':flag-km:',
    unicode: '1f1f0-1f1f2',
    html: '&#127472;&#127474;',
    category: 'Flags (country-flag)',
    order: '2311',
  },
  {
    emoji: '🇰🇳',
    name: 'flag: St. Kitts & Nevis',
    shortname: ':flag-kn:',
    unicode: '1f1f0-1f1f3',
    html: '&#127472;&#127475;',
    category: 'Flags (country-flag)',
    order: '2312',
  },
  {
    emoji: '🇰🇵',
    name: 'flag: North Korea',
    shortname: ':flag-kp:',
    unicode: '1f1f0-1f1f5',
    html: '&#127472;&#127477;',
    category: 'Flags (country-flag)',
    order: '2313',
  },
  {
    emoji: '🇰🇷',
    name: 'flag: South Korea',
    shortname: ':flag-kr:',
    unicode: '1f1f0-1f1f7',
    html: '&#127472;&#127479;',
    category: 'Flags (country-flag)',
    order: '2314',
  },
  {
    emoji: '🇰🇼',
    name: 'flag: Kuwait',
    shortname: ':flag-kw:',
    unicode: '1f1f0-1f1fc',
    html: '&#127472;&#127484;',
    category: 'Flags (country-flag)',
    order: '2315',
  },
  {
    emoji: '🇰🇾',
    name: 'flag: Cayman Islands',
    shortname: ':flag-ky:',
    unicode: '1f1f0-1f1fe',
    html: '&#127472;&#127486;',
    category: 'Flags (country-flag)',
    order: '2316',
  },
  {
    emoji: '🇰🇿',
    name: 'flag: Kazakhstan',
    shortname: ':flag-kz:',
    unicode: '1f1f0-1f1ff',
    html: '&#127472;&#127487;',
    category: 'Flags (country-flag)',
    order: '2317',
  },
  {
    emoji: '🇱🇦',
    name: 'flag: Laos',
    shortname: ':flag-la:',
    unicode: '1f1f1-1f1e6',
    html: '&#127473;&#127462;',
    category: 'Flags (country-flag)',
    order: '2318',
  },
  {
    emoji: '🇱🇧',
    name: 'flag: Lebanon',
    shortname: ':flag-lb:',
    unicode: '1f1f1-1f1e7',
    html: '&#127473;&#127463;',
    category: 'Flags (country-flag)',
    order: '2319',
  },
  {
    emoji: '🇱🇨',
    name: 'flag: St. Lucia',
    shortname: ':flag-lc:',
    unicode: '1f1f1-1f1e8',
    html: '&#127473;&#127464;',
    category: 'Flags (country-flag)',
    order: '2320',
  },
  {
    emoji: '🇱🇮',
    name: 'flag: Liechtenstein',
    shortname: ':flag-li:',
    unicode: '1f1f1-1f1ee',
    html: '&#127473;&#127470;',
    category: 'Flags (country-flag)',
    order: '2321',
  },
  {
    emoji: '🇱🇰',
    name: 'flag: Sri Lanka',
    shortname: ':flag-lk:',
    unicode: '1f1f1-1f1f0',
    html: '&#127473;&#127472;',
    category: 'Flags (country-flag)',
    order: '2322',
  },
  {
    emoji: '🇱🇷',
    name: 'flag: Liberia',
    shortname: ':flag-lr:',
    unicode: '1f1f1-1f1f7',
    html: '&#127473;&#127479;',
    category: 'Flags (country-flag)',
    order: '2323',
  },
  {
    emoji: '🇱🇸',
    name: 'flag: Lesotho',
    shortname: ':flag-ls:',
    unicode: '1f1f1-1f1f8',
    html: '&#127473;&#127480;',
    category: 'Flags (country-flag)',
    order: '2324',
  },
  {
    emoji: '🇱🇹',
    name: 'flag: Lithuania',
    shortname: ':flag-lt:',
    unicode: '1f1f1-1f1f9',
    html: '&#127473;&#127481;',
    category: 'Flags (country-flag)',
    order: '2325',
  },
  {
    emoji: '🇱🇺',
    name: 'flag: Luxembourg',
    shortname: ':flag-lu:',
    unicode: '1f1f1-1f1fa',
    html: '&#127473;&#127482;',
    category: 'Flags (country-flag)',
    order: '2326',
  },
  {
    emoji: '🇱🇻',
    name: 'flag: Latvia',
    shortname: ':flag-lv:',
    unicode: '1f1f1-1f1fb',
    html: '&#127473;&#127483;',
    category: 'Flags (country-flag)',
    order: '2327',
  },
  {
    emoji: '🇱🇾',
    name: 'flag: Libya',
    shortname: ':flag-ly:',
    unicode: '1f1f1-1f1fe',
    html: '&#127473;&#127486;',
    category: 'Flags (country-flag)',
    order: '2328',
  },
  {
    emoji: '🇲🇦',
    name: 'flag: Morocco',
    shortname: ':flag-ma:',
    unicode: '1f1f2-1f1e6',
    html: '&#127474;&#127462;',
    category: 'Flags (country-flag)',
    order: '2329',
  },
  {
    emoji: '🇲🇨',
    name: 'flag: Monaco',
    shortname: ':flag-mc:',
    unicode: '1f1f2-1f1e8',
    html: '&#127474;&#127464;',
    category: 'Flags (country-flag)',
    order: '2330',
  },
  {
    emoji: '🇲🇩',
    name: 'flag: Moldova',
    shortname: ':flag-md:',
    unicode: '1f1f2-1f1e9',
    html: '&#127474;&#127465;',
    category: 'Flags (country-flag)',
    order: '2331',
  },
  {
    emoji: '🇲🇪',
    name: 'flag: Montenegro',
    shortname: ':flag-me:',
    unicode: '1f1f2-1f1ea',
    html: '&#127474;&#127466;',
    category: 'Flags (country-flag)',
    order: '2332',
  },
  {
    emoji: '🇲🇫',
    name: 'flag: St. Martin',
    shortname: ':flag-mf:',
    unicode: '1f1f2-1f1eb',
    html: '&#127474;&#127467;',
    category: 'Flags (country-flag)',
    order: '2333',
  },
  {
    emoji: '🇲🇬',
    name: 'flag: Madagascar',
    shortname: ':flag-mg:',
    unicode: '1f1f2-1f1ec',
    html: '&#127474;&#127468;',
    category: 'Flags (country-flag)',
    order: '2334',
  },
  {
    emoji: '🇲🇭',
    name: 'flag: Marshall Islands',
    shortname: ':flag-mh:',
    unicode: '1f1f2-1f1ed',
    html: '&#127474;&#127469;',
    category: 'Flags (country-flag)',
    order: '2335',
  },
  {
    emoji: '🇲🇰',
    name: 'flag: North Macedonia',
    shortname: ':flag-mk:',
    unicode: '1f1f2-1f1f0',
    html: '&#127474;&#127472;',
    category: 'Flags (country-flag)',
    order: '2336',
  },
  {
    emoji: '🇲🇱',
    name: 'flag: Mali',
    shortname: ':flag-ml:',
    unicode: '1f1f2-1f1f1',
    html: '&#127474;&#127473;',
    category: 'Flags (country-flag)',
    order: '2337',
  },
  {
    emoji: '🇲🇲',
    name: 'flag: Myanmar (Burma)',
    shortname: ':flag-mm:',
    unicode: '1f1f2-1f1f2',
    html: '&#127474;&#127474;',
    category: 'Flags (country-flag)',
    order: '2338',
  },
  {
    emoji: '🇲🇳',
    name: 'flag: Mongolia',
    shortname: ':flag-mn:',
    unicode: '1f1f2-1f1f3',
    html: '&#127474;&#127475;',
    category: 'Flags (country-flag)',
    order: '2339',
  },
  {
    emoji: '🇲🇴',
    name: 'flag: Macao SAR China',
    shortname: ':flag-mo:',
    unicode: '1f1f2-1f1f4',
    html: '&#127474;&#127476;',
    category: 'Flags (country-flag)',
    order: '2340',
  },
  {
    emoji: '🇲🇵',
    name: 'flag: Northern Mariana Islands',
    shortname: ':flag-mp:',
    unicode: '1f1f2-1f1f5',
    html: '&#127474;&#127477;',
    category: 'Flags (country-flag)',
    order: '2341',
  },
  {
    emoji: '🇲🇶',
    name: 'flag: Martinique',
    shortname: ':flag-mq:',
    unicode: '1f1f2-1f1f6',
    html: '&#127474;&#127478;',
    category: 'Flags (country-flag)',
    order: '2342',
  },
  {
    emoji: '🇲🇷',
    name: 'flag: Mauritania',
    shortname: ':flag-mr:',
    unicode: '1f1f2-1f1f7',
    html: '&#127474;&#127479;',
    category: 'Flags (country-flag)',
    order: '2343',
  },
  {
    emoji: '🇲🇸',
    name: 'flag: Montserrat',
    shortname: ':flag-ms:',
    unicode: '1f1f2-1f1f8',
    html: '&#127474;&#127480;',
    category: 'Flags (country-flag)',
    order: '2344',
  },
  {
    emoji: '🇲🇹',
    name: 'flag: Malta',
    shortname: ':flag-mt:',
    unicode: '1f1f2-1f1f9',
    html: '&#127474;&#127481;',
    category: 'Flags (country-flag)',
    order: '2345',
  },
  {
    emoji: '🇲🇺',
    name: 'flag: Mauritius',
    shortname: ':flag-mu:',
    unicode: '1f1f2-1f1fa',
    html: '&#127474;&#127482;',
    category: 'Flags (country-flag)',
    order: '2346',
  },
  {
    emoji: '🇲🇻',
    name: 'flag: Maldives',
    shortname: ':flag-mv:',
    unicode: '1f1f2-1f1fb',
    html: '&#127474;&#127483;',
    category: 'Flags (country-flag)',
    order: '2347',
  },
  {
    emoji: '🇲🇼',
    name: 'flag: Malawi',
    shortname: ':flag-mw:',
    unicode: '1f1f2-1f1fc',
    html: '&#127474;&#127484;',
    category: 'Flags (country-flag)',
    order: '2348',
  },
  {
    emoji: '🇲🇽',
    name: 'flag: Mexico',
    shortname: ':flag-mx:',
    unicode: '1f1f2-1f1fd',
    html: '&#127474;&#127485;',
    category: 'Flags (country-flag)',
    order: '2349',
  },
  {
    emoji: '🇲🇾',
    name: 'flag: Malaysia',
    shortname: ':flag-my:',
    unicode: '1f1f2-1f1fe',
    html: '&#127474;&#127486;',
    category: 'Flags (country-flag)',
    order: '2350',
  },
  {
    emoji: '🇲🇿',
    name: 'flag: Mozambique',
    shortname: ':flag-mz:',
    unicode: '1f1f2-1f1ff',
    html: '&#127474;&#127487;',
    category: 'Flags (country-flag)',
    order: '2351',
  },
  {
    emoji: '🇳🇦',
    name: 'flag: Namibia',
    shortname: ':flag-na:',
    unicode: '1f1f3-1f1e6',
    html: '&#127475;&#127462;',
    category: 'Flags (country-flag)',
    order: '2352',
  },
  {
    emoji: '🇳🇨',
    name: 'flag: New Caledonia',
    shortname: ':flag-nc:',
    unicode: '1f1f3-1f1e8',
    html: '&#127475;&#127464;',
    category: 'Flags (country-flag)',
    order: '2353',
  },
  {
    emoji: '🇳🇪',
    name: 'flag: Niger',
    shortname: ':flag-ne:',
    unicode: '1f1f3-1f1ea',
    html: '&#127475;&#127466;',
    category: 'Flags (country-flag)',
    order: '2354',
  },
  {
    emoji: '🇳🇫',
    name: 'flag: Norfolk Island',
    shortname: ':flag-nf:',
    unicode: '1f1f3-1f1eb',
    html: '&#127475;&#127467;',
    category: 'Flags (country-flag)',
    order: '2355',
  },
  {
    emoji: '🇳🇬',
    name: 'flag: Nigeria',
    shortname: ':flag-ng:',
    unicode: '1f1f3-1f1ec',
    html: '&#127475;&#127468;',
    category: 'Flags (country-flag)',
    order: '2356',
  },
  {
    emoji: '🇳🇮',
    name: 'flag: Nicaragua',
    shortname: ':flag-ni:',
    unicode: '1f1f3-1f1ee',
    html: '&#127475;&#127470;',
    category: 'Flags (country-flag)',
    order: '2357',
  },
  {
    emoji: '🇳🇱',
    name: 'flag: Netherlands',
    shortname: ':flag-nl:',
    unicode: '1f1f3-1f1f1',
    html: '&#127475;&#127473;',
    category: 'Flags (country-flag)',
    order: '2358',
  },
  {
    emoji: '🇳🇴',
    name: 'flag: Norway',
    shortname: ':flag-no:',
    unicode: '1f1f3-1f1f4',
    html: '&#127475;&#127476;',
    category: 'Flags (country-flag)',
    order: '2359',
  },
  {
    emoji: '🇳🇵',
    name: 'flag: Nepal',
    shortname: ':flag-np:',
    unicode: '1f1f3-1f1f5',
    html: '&#127475;&#127477;',
    category: 'Flags (country-flag)',
    order: '2360',
  },
  {
    emoji: '🇳🇷',
    name: 'flag: Nauru',
    shortname: ':flag-nr:',
    unicode: '1f1f3-1f1f7',
    html: '&#127475;&#127479;',
    category: 'Flags (country-flag)',
    order: '2361',
  },
  {
    emoji: '🇳🇺',
    name: 'flag: Niue',
    shortname: ':flag-nu:',
    unicode: '1f1f3-1f1fa',
    html: '&#127475;&#127482;',
    category: 'Flags (country-flag)',
    order: '2362',
  },
  {
    emoji: '🇳🇿',
    name: 'flag: New Zealand',
    shortname: ':flag-nz:',
    unicode: '1f1f3-1f1ff',
    html: '&#127475;&#127487;',
    category: 'Flags (country-flag)',
    order: '2363',
  },
  {
    emoji: '🇴🇲',
    name: 'flag: Oman',
    shortname: ':flag-om:',
    unicode: '1f1f4-1f1f2',
    html: '&#127476;&#127474;',
    category: 'Flags (country-flag)',
    order: '2364',
  },
  {
    emoji: '🇵🇦',
    name: 'flag: Panama',
    shortname: ':flag-pa:',
    unicode: '1f1f5-1f1e6',
    html: '&#127477;&#127462;',
    category: 'Flags (country-flag)',
    order: '2365',
  },
  {
    emoji: '🇵🇪',
    name: 'flag: Peru',
    shortname: ':flag-pe:',
    unicode: '1f1f5-1f1ea',
    html: '&#127477;&#127466;',
    category: 'Flags (country-flag)',
    order: '2366',
  },
  {
    emoji: '🇵🇫',
    name: 'flag: French Polynesia',
    shortname: ':flag-pf:',
    unicode: '1f1f5-1f1eb',
    html: '&#127477;&#127467;',
    category: 'Flags (country-flag)',
    order: '2367',
  },
  {
    emoji: '🇵🇬',
    name: 'flag: Papua New Guinea',
    shortname: ':flag-pg:',
    unicode: '1f1f5-1f1ec',
    html: '&#127477;&#127468;',
    category: 'Flags (country-flag)',
    order: '2368',
  },
  {
    emoji: '🇵🇭',
    name: 'flag: Philippines',
    shortname: ':flag-ph:',
    unicode: '1f1f5-1f1ed',
    html: '&#127477;&#127469;',
    category: 'Flags (country-flag)',
    order: '2369',
  },
  {
    emoji: '🇵🇰',
    name: 'flag: Pakistan',
    shortname: ':flag-pk:',
    unicode: '1f1f5-1f1f0',
    html: '&#127477;&#127472;',
    category: 'Flags (country-flag)',
    order: '2370',
  },
  {
    emoji: '🇵🇱',
    name: 'flag: Poland',
    shortname: ':flag-pl:',
    unicode: '1f1f5-1f1f1',
    html: '&#127477;&#127473;',
    category: 'Flags (country-flag)',
    order: '2371',
  },
  {
    emoji: '🇵🇲',
    name: 'flag: St. Pierre & Miquelon',
    shortname: ':flag-pm:',
    unicode: '1f1f5-1f1f2',
    html: '&#127477;&#127474;',
    category: 'Flags (country-flag)',
    order: '2372',
  },
  {
    emoji: '🇵🇳',
    name: 'flag: Pitcairn Islands',
    shortname: ':flag-pn:',
    unicode: '1f1f5-1f1f3',
    html: '&#127477;&#127475;',
    category: 'Flags (country-flag)',
    order: '2373',
  },
  {
    emoji: '🇵🇷',
    name: 'flag: Puerto Rico',
    shortname: ':flag-pr:',
    unicode: '1f1f5-1f1f7',
    html: '&#127477;&#127479;',
    category: 'Flags (country-flag)',
    order: '2374',
  },
  {
    emoji: '🇵🇸',
    name: 'flag: Palestinian Territories',
    shortname: ':flag-ps:',
    unicode: '1f1f5-1f1f8',
    html: '&#127477;&#127480;',
    category: 'Flags (country-flag)',
    order: '2375',
  },
  {
    emoji: '🇵🇹',
    name: 'flag: Portugal',
    shortname: ':flag-pt:',
    unicode: '1f1f5-1f1f9',
    html: '&#127477;&#127481;',
    category: 'Flags (country-flag)',
    order: '2376',
  },
  {
    emoji: '🇵🇼',
    name: 'flag: Palau',
    shortname: ':flag-pw:',
    unicode: '1f1f5-1f1fc',
    html: '&#127477;&#127484;',
    category: 'Flags (country-flag)',
    order: '2377',
  },
  {
    emoji: '🇵🇾',
    name: 'flag: Paraguay',
    shortname: ':flag-py:',
    unicode: '1f1f5-1f1fe',
    html: '&#127477;&#127486;',
    category: 'Flags (country-flag)',
    order: '2378',
  },
  {
    emoji: '🇶🇦',
    name: 'flag: Qatar',
    shortname: ':flag-qa:',
    unicode: '1f1f6-1f1e6',
    html: '&#127478;&#127462;',
    category: 'Flags (country-flag)',
    order: '2379',
  },
  {
    emoji: '🇷🇪',
    name: 'flag: Réunion',
    shortname: ':flag-re:',
    unicode: '1f1f7-1f1ea',
    html: '&#127479;&#127466;',
    category: 'Flags (country-flag)',
    order: '2380',
  },
  {
    emoji: '🇷🇴',
    name: 'flag: Romania',
    shortname: ':flag-ro:',
    unicode: '1f1f7-1f1f4',
    html: '&#127479;&#127476;',
    category: 'Flags (country-flag)',
    order: '2381',
  },
  {
    emoji: '🇷🇸',
    name: 'flag: Serbia',
    shortname: ':flag-rs:',
    unicode: '1f1f7-1f1f8',
    html: '&#127479;&#127480;',
    category: 'Flags (country-flag)',
    order: '2382',
  },
  {
    emoji: '🇷🇺',
    name: 'flag: Russia',
    shortname: ':flag-ru:',
    unicode: '1f1f7-1f1fa',
    html: '&#127479;&#127482;',
    category: 'Flags (country-flag)',
    order: '2383',
  },
  {
    emoji: '🇷🇼',
    name: 'flag: Rwanda',
    shortname: ':flag-rw:',
    unicode: '1f1f7-1f1fc',
    html: '&#127479;&#127484;',
    category: 'Flags (country-flag)',
    order: '2384',
  },
  {
    emoji: '🇸🇦',
    name: 'flag: Saudi Arabia',
    shortname: ':flag-sa:',
    unicode: '1f1f8-1f1e6',
    html: '&#127480;&#127462;',
    category: 'Flags (country-flag)',
    order: '2385',
  },
  {
    emoji: '🇸🇧',
    name: 'flag: Solomon Islands',
    shortname: ':flag-sb:',
    unicode: '1f1f8-1f1e7',
    html: '&#127480;&#127463;',
    category: 'Flags (country-flag)',
    order: '2386',
  },
  {
    emoji: '🇸🇨',
    name: 'flag: Seychelles',
    shortname: ':flag-sc:',
    unicode: '1f1f8-1f1e8',
    html: '&#127480;&#127464;',
    category: 'Flags (country-flag)',
    order: '2387',
  },
  {
    emoji: '🇸🇩',
    name: 'flag: Sudan',
    shortname: ':flag-sd:',
    unicode: '1f1f8-1f1e9',
    html: '&#127480;&#127465;',
    category: 'Flags (country-flag)',
    order: '2388',
  },
  {
    emoji: '🇸🇪',
    name: 'flag: Sweden',
    shortname: ':flag-se:',
    unicode: '1f1f8-1f1ea',
    html: '&#127480;&#127466;',
    category: 'Flags (country-flag)',
    order: '2389',
  },
  {
    emoji: '🇸🇬',
    name: 'flag: Singapore',
    shortname: ':flag-sg:',
    unicode: '1f1f8-1f1ec',
    html: '&#127480;&#127468;',
    category: 'Flags (country-flag)',
    order: '2390',
  },
  {
    emoji: '🇸🇭',
    name: 'flag: St. Helena',
    shortname: ':flag-sh:',
    unicode: '1f1f8-1f1ed',
    html: '&#127480;&#127469;',
    category: 'Flags (country-flag)',
    order: '2391',
  },
  {
    emoji: '🇸🇮',
    name: 'flag: Slovenia',
    shortname: ':flag-si:',
    unicode: '1f1f8-1f1ee',
    html: '&#127480;&#127470;',
    category: 'Flags (country-flag)',
    order: '2392',
  },
  {
    emoji: '🇸🇯',
    name: 'flag: Svalbard & Jan Mayen',
    shortname: ':flag-sj:',
    unicode: '1f1f8-1f1ef',
    html: '&#127480;&#127471;',
    category: 'Flags (country-flag)',
    order: '2393',
  },
  {
    emoji: '🇸🇰',
    name: 'flag: Slovakia',
    shortname: ':flag-sk:',
    unicode: '1f1f8-1f1f0',
    html: '&#127480;&#127472;',
    category: 'Flags (country-flag)',
    order: '2394',
  },
  {
    emoji: '🇸🇱',
    name: 'flag: Sierra Leone',
    shortname: ':flag-sl:',
    unicode: '1f1f8-1f1f1',
    html: '&#127480;&#127473;',
    category: 'Flags (country-flag)',
    order: '2395',
  },
  {
    emoji: '🇸🇲',
    name: 'flag: San Marino',
    shortname: ':flag-sm:',
    unicode: '1f1f8-1f1f2',
    html: '&#127480;&#127474;',
    category: 'Flags (country-flag)',
    order: '2396',
  },
  {
    emoji: '🇸🇳',
    name: 'flag: Senegal',
    shortname: ':flag-sn:',
    unicode: '1f1f8-1f1f3',
    html: '&#127480;&#127475;',
    category: 'Flags (country-flag)',
    order: '2397',
  },
  {
    emoji: '🇸🇴',
    name: 'flag: Somalia',
    shortname: ':flag-so:',
    unicode: '1f1f8-1f1f4',
    html: '&#127480;&#127476;',
    category: 'Flags (country-flag)',
    order: '2398',
  },
  {
    emoji: '🇸🇷',
    name: 'flag: Suriname',
    shortname: ':flag-sr:',
    unicode: '1f1f8-1f1f7',
    html: '&#127480;&#127479;',
    category: 'Flags (country-flag)',
    order: '2399',
  },
  {
    emoji: '🇸🇸',
    name: 'flag: South Sudan',
    shortname: ':flag-ss:',
    unicode: '1f1f8-1f1f8',
    html: '&#127480;&#127480;',
    category: 'Flags (country-flag)',
    order: '2400',
  },
  {
    emoji: '🇸🇹',
    name: 'flag: São Tomé & Príncipe',
    shortname: ':flag-st:',
    unicode: '1f1f8-1f1f9',
    html: '&#127480;&#127481;',
    category: 'Flags (country-flag)',
    order: '2401',
  },
  {
    emoji: '🇸🇻',
    name: 'flag: El Salvador',
    shortname: ':flag-sv:',
    unicode: '1f1f8-1f1fb',
    html: '&#127480;&#127483;',
    category: 'Flags (country-flag)',
    order: '2402',
  },
  {
    emoji: '🇸🇽',
    name: 'flag: Sint Maarten',
    shortname: ':flag-sx:',
    unicode: '1f1f8-1f1fd',
    html: '&#127480;&#127485;',
    category: 'Flags (country-flag)',
    order: '2403',
  },
  {
    emoji: '🇸🇾',
    name: 'flag: Syria',
    shortname: ':flag-sy:',
    unicode: '1f1f8-1f1fe',
    html: '&#127480;&#127486;',
    category: 'Flags (country-flag)',
    order: '2404',
  },
  {
    emoji: '🇸🇿',
    name: 'flag: Eswatini',
    shortname: ':flag-sz:',
    unicode: '1f1f8-1f1ff',
    html: '&#127480;&#127487;',
    category: 'Flags (country-flag)',
    order: '2405',
  },
  {
    emoji: '🇹🇦',
    name: 'flag: Tristan da Cunha',
    shortname: ':flag-ta:',
    unicode: '1f1f9-1f1e6',
    html: '&#127481;&#127462;',
    category: 'Flags (country-flag)',
    order: '2406',
  },
  {
    emoji: '🇹🇨',
    name: 'flag: Turks & Caicos Islands',
    shortname: ':flag-tc:',
    unicode: '1f1f9-1f1e8',
    html: '&#127481;&#127464;',
    category: 'Flags (country-flag)',
    order: '2407',
  },
  {
    emoji: '🇹🇩',
    name: 'flag: Chad',
    shortname: ':flag-td:',
    unicode: '1f1f9-1f1e9',
    html: '&#127481;&#127465;',
    category: 'Flags (country-flag)',
    order: '2408',
  },
  {
    emoji: '🇹🇫',
    name: 'flag: French Southern Territories',
    shortname: ':flag-tf:',
    unicode: '1f1f9-1f1eb',
    html: '&#127481;&#127467;',
    category: 'Flags (country-flag)',
    order: '2409',
  },
  {
    emoji: '🇹🇬',
    name: 'flag: Togo',
    shortname: ':flag-tg:',
    unicode: '1f1f9-1f1ec',
    html: '&#127481;&#127468;',
    category: 'Flags (country-flag)',
    order: '2410',
  },
  {
    emoji: '🇹🇭',
    name: 'flag: Thailand',
    shortname: ':flag-th:',
    unicode: '1f1f9-1f1ed',
    html: '&#127481;&#127469;',
    category: 'Flags (country-flag)',
    order: '2411',
  },
  {
    emoji: '🇹🇯',
    name: 'flag: Tajikistan',
    shortname: ':flag-tj:',
    unicode: '1f1f9-1f1ef',
    html: '&#127481;&#127471;',
    category: 'Flags (country-flag)',
    order: '2412',
  },
  {
    emoji: '🇹🇰',
    name: 'flag: Tokelau',
    shortname: ':flag-tk:',
    unicode: '1f1f9-1f1f0',
    html: '&#127481;&#127472;',
    category: 'Flags (country-flag)',
    order: '2413',
  },
  {
    emoji: '🇹🇱',
    name: 'flag: Timor-Leste',
    shortname: ':flag-tl:',
    unicode: '1f1f9-1f1f1',
    html: '&#127481;&#127473;',
    category: 'Flags (country-flag)',
    order: '2414',
  },
  {
    emoji: '🇹🇲',
    name: 'flag: Turkmenistan',
    shortname: ':flag-tm:',
    unicode: '1f1f9-1f1f2',
    html: '&#127481;&#127474;',
    category: 'Flags (country-flag)',
    order: '2415',
  },
  {
    emoji: '🇹🇳',
    name: 'flag: Tunisia',
    shortname: ':flag-tn:',
    unicode: '1f1f9-1f1f3',
    html: '&#127481;&#127475;',
    category: 'Flags (country-flag)',
    order: '2416',
  },
  {
    emoji: '🇹🇴',
    name: 'flag: Tonga',
    shortname: ':flag-to:',
    unicode: '1f1f9-1f1f4',
    html: '&#127481;&#127476;',
    category: 'Flags (country-flag)',
    order: '2417',
  },
  {
    emoji: '🇹🇷',
    name: 'flag: Turkey',
    shortname: ':flag-tr:',
    unicode: '1f1f9-1f1f7',
    html: '&#127481;&#127479;',
    category: 'Flags (country-flag)',
    order: '2418',
  },
  {
    emoji: '🇹🇹',
    name: 'flag: Trinidad & Tobago',
    shortname: ':flag-tt:',
    unicode: '1f1f9-1f1f9',
    html: '&#127481;&#127481;',
    category: 'Flags (country-flag)',
    order: '2419',
  },
  {
    emoji: '🇹🇻',
    name: 'flag: Tuvalu',
    shortname: ':flag-tv:',
    unicode: '1f1f9-1f1fb',
    html: '&#127481;&#127483;',
    category: 'Flags (country-flag)',
    order: '2420',
  },
  {
    emoji: '🇹🇼',
    name: 'flag: Taiwan',
    shortname: ':flag-tw:',
    unicode: '1f1f9-1f1fc',
    html: '&#127481;&#127484;',
    category: 'Flags (country-flag)',
    order: '2421',
  },
  {
    emoji: '🇹🇿',
    name: 'flag: Tanzania',
    shortname: ':flag-tz:',
    unicode: '1f1f9-1f1ff',
    html: '&#127481;&#127487;',
    category: 'Flags (country-flag)',
    order: '2422',
  },
  {
    emoji: '🇺🇦',
    name: 'flag: Ukraine',
    shortname: ':flag-ua:',
    unicode: '1f1fa-1f1e6',
    html: '&#127482;&#127462;',
    category: 'Flags (country-flag)',
    order: '2423',
  },
  {
    emoji: '🇺🇬',
    name: 'flag: Uganda',
    shortname: ':flag-ug:',
    unicode: '1f1fa-1f1ec',
    html: '&#127482;&#127468;',
    category: 'Flags (country-flag)',
    order: '2424',
  },
  {
    emoji: '🇺🇲',
    name: 'flag: U.S. Outlying Islands',
    shortname: ':flag-um:',
    unicode: '1f1fa-1f1f2',
    html: '&#127482;&#127474;',
    category: 'Flags (country-flag)',
    order: '2425',
  },
  {
    emoji: '🇺🇸',
    name: 'flag: United States',
    shortname: ':flag-us:',
    unicode: '1f1fa-1f1f8',
    html: '&#127482;&#127480;',
    category: 'Flags (country-flag)',
    order: '2427',
  },
  {
    emoji: '🇺🇾',
    name: 'flag: Uruguay',
    shortname: ':flag-uy:',
    unicode: '1f1fa-1f1fe',
    html: '&#127482;&#127486;',
    category: 'Flags (country-flag)',
    order: '2428',
  },
  {
    emoji: '🇺🇿',
    name: 'flag: Uzbekistan',
    shortname: ':flag-uz:',
    unicode: '1f1fa-1f1ff',
    html: '&#127482;&#127487;',
    category: 'Flags (country-flag)',
    order: '2429',
  },
  {
    emoji: '🇻🇦',
    name: 'flag: Vatican City',
    shortname: ':flag-va:',
    unicode: '1f1fb-1f1e6',
    html: '&#127483;&#127462;',
    category: 'Flags (country-flag)',
    order: '2430',
  },
  {
    emoji: '🇻🇨',
    name: 'flag: St. Vincent & Grenadines',
    shortname: ':flag-vc:',
    unicode: '1f1fb-1f1e8',
    html: '&#127483;&#127464;',
    category: 'Flags (country-flag)',
    order: '2431',
  },
  {
    emoji: '🇻🇪',
    name: 'flag: Venezuela',
    shortname: ':flag-ve:',
    unicode: '1f1fb-1f1ea',
    html: '&#127483;&#127466;',
    category: 'Flags (country-flag)',
    order: '2432',
  },
  {
    emoji: '🇻🇬',
    name: 'flag: British Virgin Islands',
    shortname: ':flag-vg:',
    unicode: '1f1fb-1f1ec',
    html: '&#127483;&#127468;',
    category: 'Flags (country-flag)',
    order: '2433',
  },
  {
    emoji: '🇻🇮',
    name: 'flag: U.S. Virgin Islands',
    shortname: ':flag-vi:',
    unicode: '1f1fb-1f1ee',
    html: '&#127483;&#127470;',
    category: 'Flags (country-flag)',
    order: '2434',
  },
  {
    emoji: '🇻🇳',
    name: 'flag: Vietnam',
    shortname: ':flag-vn:',
    unicode: '1f1fb-1f1f3',
    html: '&#127483;&#127475;',
    category: 'Flags (country-flag)',
    order: '2435',
  },
  {
    emoji: '🇻🇺',
    name: 'flag: Vanuatu',
    shortname: ':flag_vu:',
    unicode: '1f1fb-1f1fa',
    html: '&#127483;&#127482;',
    category: 'Flags (country-flag)',
    order: '2436',
  },
  {
    emoji: '🇼🇫',
    name: 'flag: Wallis & Futuna',
    shortname: ':flag_wf:',
    unicode: '1f1fc-1f1eb',
    html: '&#127484;&#127467;',
    category: 'Flags (country-flag)',
    order: '2437',
  },
  {
    emoji: '🇼🇸',
    name: 'flag: Samoa',
    shortname: ':flag_ws:',
    unicode: '1f1fc-1f1f8',
    html: '&#127484;&#127480;',
    category: 'Flags (country-flag)',
    order: '2438',
  },
  {
    emoji: '🇽🇰',
    name: 'flag: Kosovo',
    shortname: ':flag_xk:',
    unicode: '1f1fd-1f1f0',
    html: '&#127485;&#127472;',
    category: 'Flags (country-flag)',
    order: '2439',
  },
  {
    emoji: '🇾🇪',
    name: 'flag: Yemen',
    shortname: ':flag_ye:',
    unicode: '1f1fe-1f1ea',
    html: '&#127486;&#127466;',
    category: 'Flags (country-flag)',
    order: '2440',
  },
  {
    emoji: '🇾🇹',
    name: 'flag: Mayotte',
    shortname: ':flag_yt:',
    unicode: '1f1fe-1f1f9',
    html: '&#127486;&#127481;',
    category: 'Flags (country-flag)',
    order: '2441',
  },
  {
    emoji: '🇿🇦',
    name: 'flag: South Africa',
    shortname: ':flag_za:',
    unicode: '1f1ff-1f1e6',
    html: '&#127487;&#127462;',
    category: 'Flags (country-flag)',
    order: '2442',
  },
  {
    emoji: '🇿🇲',
    name: 'flag: Zambia',
    shortname: ':flag_zm:',
    unicode: '1f1ff-1f1f2',
    html: '&#127487;&#127474;',
    category: 'Flags (country-flag)',
    order: '2443',
  },
  {
    emoji: '🇿🇼',
    name: 'flag: Zimbabwe',
    shortname: ':flag_zw:',
    unicode: '1f1ff-1f1fc',
    html: '&#127487;&#127484;',
    category: 'Flags (country-flag)',
    order: '2444',
  },
  {
    emoji: '🖤',
    name: 'black heart',
    shortname: ':black_heart:',
    unicode: '1f5a4',
    html: '&#128420;',
    category: 'Smileys & Emotion (emotion)',
    order: '1296',
  },
  {
    emoji: '🗨',
    name: 'left speech bubble',
    shortname: ':speech_left:',
    unicode: '1f5e8',
    html: '&#128488;',
    category: 'Smileys & Emotion (emotion)',
    order: '1310',
  },
  {
    emoji: '🥚',
    name: 'egg',
    shortname: ':egg:',
    unicode: '1f95a',
    html: '&#129370;',
    category: 'Food & Drink (food-prepared)',
    order: '1489',
  },
  {
    emoji: '🛑',
    name: 'stop sign',
    shortname: ':octagonal_sign:',
    unicode: '1f6d1',
    html: '&#128721;',
    category: 'Travel & Places (transport-ground)',
    order: '1641',
  },
  {
    emoji: '♠',
    name: 'spade suit',
    shortname: ':spades:',
    unicode: '2660',
    html: '&spades;',
    category: 'Activities (game)',
    order: '1807',
  },
  {
    emoji: '♥',
    name: 'heart suit',
    shortname: ':hearts:',
    unicode: '2665',
    html: '&hearts;',
    category: 'Activities (game)',
    order: '1808',
  },
  {
    emoji: '♦',
    name: 'diamond suit',
    shortname: ':diamonds:',
    unicode: '2666',
    html: '&diams;',
    category: 'Activities (game)',
    order: '1809',
  },
  {
    emoji: '♣',
    name: 'club suit',
    shortname: ':clubs:',
    unicode: '2663',
    html: '&clubs;',
    category: 'Activities (game)',
    order: '1810',
  },
  {
    emoji: '🥁',
    name: 'drum',
    shortname: ':drum:',
    unicode: '1f941',
    html: '&#129345;',
    category: 'Objects (musical-instrument)',
    order: '1837',
  },
  {
    emoji: '↔',
    name: 'left-right arrow',
    shortname: ':left_right_arrow:',
    unicode: '2194',
    html: '&harr;',
    category: 'Symbols (arrow)',
    order: '2011',
  },
  {
    emoji: '©',
    name: 'copyright',
    shortname: ':copyright:',
    unicode: '00a9',
    html: '&copy;',
    category: 'Symbols (other-symbol)',
    order: '2103',
  },
  {
    emoji: '®',
    name: 'registered',
    shortname: ':registered:',
    unicode: '00ae',
    html: '&reg;',
    category: 'Symbols (other-symbol)',
    order: '2104',
  },
  {
    emoji: '™',
    name: 'trade mark',
    shortname: ':tm:',
    unicode: '2122',
    html: '&trade;',
    category: 'Symbols (other-symbol)',
    order: '2105',
  },
  {
    emoji: '0️⃣',
    name: 'keycap: 0',
    shortname: ':0:',
    unicode: '0030 FE0F 20E3',
    html: '&#48;',
    category: 'Symbols (keycap)',
    order: '',
  },
  {
    emoji: '1️⃣',
    name: 'keycap: 1',
    shortname: ':1:',
    unicode: '0031 FE0F 20E3',
    html: '&#49;',
    category: 'Symbols (keycap)',
    order: '',
  },
  {
    emoji: '2️⃣',
    name: 'keycap: 2',
    shortname: ':2:',
    unicode: '0032 FE0F 20E3',
    html: '&#50;',
    category: 'Symbols (keycap)',
    order: '',
  },
  {
    emoji: '3️⃣',
    name: 'keycap: 3',
    shortname: ':3:',
    unicode: '0033 FE0F 20E3',
    html: '&#51;',
    category: 'Symbols (keycap)',
    order: '',
  },
  {
    emoji: '4️⃣',
    name: 'keycap: 4',
    shortname: ':4:',
    unicode: '0034 FE0F 20E3',
    html: '&#52;',
    category: 'Symbols (keycap)',
    order: '',
  },
  {
    emoji: '5️⃣',
    name: 'keycap: 5',
    shortname: ':5:',
    unicode: '0035 FE0F 20E3',
    html: '&#53;',
    category: 'Symbols (keycap)',
    order: '',
  },
  {
    emoji: '6️⃣',
    name: 'keycap: 6',
    shortname: ':6:',
    unicode: '0036 FE0F 20E3',
    html: '&#54;',
    category: 'Symbols (keycap)',
    order: '',
  },
  {
    emoji: '7️⃣',
    name: 'keycap: 7',
    shortname: ':7:',
    unicode: '0037 FE0F 20E3',
    html: '&#55;',
    category: 'Symbols (keycap)',
    order: '',
  },
  {
    emoji: '8️⃣',
    name: 'keycap: 8',
    shortname: ':8:',
    unicode: '0038 FE0F 20E3',
    html: '&#56;',
    category: 'Symbols (keycap)',
    order: '',
  },
  {
    emoji: '9️⃣',
    name: 'keycap: 9',
    shortname: ':9:',
    unicode: '0039 FE0F 20E3',
    html: '&#57;',
    category: 'Symbols (keycap)',
    order: '',
  },
  {
    emoji: '#⃣',
    name: 'keycap: #',
    shortname: ':hash:',
    unicode: '0023-20e3',
    html: '#&#8419;',
    category: 'Symbols (keycap)',
    order: '2106',
  },
  {
    emoji: '*⃣',
    name: 'keycap: #',
    shortname: ':asterisk:',
    unicode: '002a-20e3',
    html: '*&#8419;',
    category: 'Symbols (keycap)',
    order: '2107',
  },
  {
    emoji: '0⃣',
    name: 'keycap: 0',
    shortname: ':zero:',
    unicode: '0030-20e3',
    html: '0&#8419;',
    category: 'Symbols (keycap)',
    order: '2108',
  },
  {
    emoji: '1⃣',
    name: 'keycap: 1',
    shortname: ':one:',
    unicode: '0031-20e3',
    html: '1&#8419;',
    category: 'Symbols (keycap)',
    order: '2109',
  },
  {
    emoji: '2⃣',
    name: 'keycap: 2',
    shortname: ':two:',
    unicode: '0032-20e3',
    html: '2&#8419;',
    category: 'Symbols (keycap)',
    order: '2110',
  },
  {
    emoji: '3⃣',
    name: 'keycap: 3',
    shortname: ':three:',
    unicode: '0033-20e3',
    html: '3&#8419;',
    category: 'Symbols (keycap)',
    order: '2111',
  },
  {
    emoji: '4⃣',
    name: 'keycap: 4',
    shortname: ':four:',
    unicode: '0034-20e3',
    html: '4&#8419;',
    category: 'Symbols (keycap)',
    order: '2112',
  },
  {
    emoji: '5⃣',
    name: 'keycap: 5',
    shortname: ':five:',
    unicode: '0035-20e3',
    html: '5&#8419;',
    category: 'Symbols (keycap)',
    order: '2113',
  },
  {
    emoji: '6⃣',
    name: 'keycap: 6',
    shortname: ':six:',
    unicode: '0036-20e3',
    html: '6&#8419;',
    category: 'Symbols (keycap)',
    order: '2114',
  },
  {
    emoji: '7⃣',
    name: 'keycap: 7',
    shortname: ':seven:',
    unicode: '0037-20e3',
    html: '7&#8419;',
    category: 'Symbols (keycap)',
    order: '2115',
  },
  {
    emoji: '8⃣',
    name: 'keycap: 8',
    shortname: ':eight:',
    unicode: '0038-20e3',
    html: '8&#8419;',
    category: 'Symbols (keycap)',
    order: '2116',
  },
  {
    emoji: '9⃣',
    name: 'keycap: 9',
    shortname: ':nine:',
    unicode: '0039-20e3',
    html: '9&#8419;',
    category: 'Symbols (keycap)',
    order: '2117',
  },
  {
    emoji: '🤣',
    name: 'rolling on the floor laughing',
    shortname: ':rolling_on_the_floor_laughing:',
    unicode: '1F923',
    html: '&#x1F923;',
    category: 'Smileys & Emotion (face-smiling)',
    order: '',
  },
  {
    emoji: '🥰',
    name: 'smiling face with hearts',
    shortname: ':smiling_face_with_hearts:',
    unicode: '1F970',
    html: '&#x1F970;',
    category: 'Smileys & Emotion (face-affection)',
    order: '',
  },
  {
    emoji: '🤩',
    name: 'star-struck',
    shortname: ':starstruck:',
    unicode: '1F929',
    html: '&#x1F929;',
    category: 'Smileys & Emotion (face-affection)',
    order: '',
  },
  {
    emoji: '☺',
    name: 'smiling face',
    shortname: ':smiling_face:',
    unicode: '263A',
    html: '&#x263A;',
    category: 'Smileys & Emotion (face-affection)',
    order: '',
  },
  {
    emoji: '🤪',
    name: 'zany face',
    shortname: ':zany_face:',
    unicode: '1F92A',
    html: '&#x1F92A;',
    category: 'Smileys & Emotion (face-tongue)',
    order: '',
  },
  {
    emoji: '🤭',
    name: 'face with hand over mouth',
    shortname: ':face_with_hand_over_mouth:',
    unicode: '1F92D',
    html: '&#x1F92D;',
    category: 'Smileys & Emotion (face-hand)',
    order: '',
  },
  {
    emoji: '🤫',
    name: 'shushing face',
    shortname: ':shushing_face:',
    unicode: '1F92B',
    html: '&#x1F92B;',
    category: 'Smileys & Emotion (face-hand)',
    order: '',
  },
  {
    emoji: '🤨',
    name: 'face with raised eyebrow',
    shortname: ':face_with_raised_eyebrow:',
    unicode: '1F928',
    html: '&#x1F928;',
    category: 'Smileys & Emotion (face-neutral-skeptical)',
    order: '',
  },
  {
    emoji: '🤥',
    name: 'lying face',
    shortname: ':lying_face:',
    unicode: '1F925',
    html: '&#x1F925;',
    category: 'Smileys & Emotion (face-neutral-skeptical)',
    order: '',
  },
  {
    emoji: '🤤',
    name: 'drooling face',
    shortname: ':drooling_face:',
    unicode: '1F924',
    html: '&#x1F924;',
    category: 'Smileys & Emotion (face-sleepy)',
    order: '',
  },
  {
    emoji: '🤢',
    name: 'nauseated face',
    shortname: ':nauseated_face:',
    unicode: '1F922',
    html: '&#x1F922;',
    category: 'Smileys & Emotion (face-unwell)',
    order: '',
  },
  {
    emoji: '🤮',
    name: 'face vomiting',
    shortname: ':face_vomiting:',
    unicode: '1F92E',
    html: '&#x1F92E;',
    category: 'Smileys & Emotion (face-unwell)',
    order: '',
  },
  {
    emoji: '🤧',
    name: 'sneezing face',
    shortname: ':sneezing_face:',
    unicode: '1F927',
    html: '&#x1F927;',
    category: 'Smileys & Emotion (face-unwell)',
    order: '',
  },
  {
    emoji: '🥵',
    name: 'hot face',
    shortname: ':hot_face:',
    unicode: '1F975',
    html: '&#x1F975;',
    category: 'Smileys & Emotion (face-unwell)',
    order: '',
  },
  {
    emoji: '🥶',
    name: 'cold face',
    shortname: ':cold_face:',
    unicode: '1F976',
    html: '&#x1F976;',
    category: 'Smileys & Emotion (face-unwell)',
    order: '',
  },
  {
    emoji: '🥴',
    name: 'woozy face',
    shortname: ':woozy_face:',
    unicode: '1F974',
    html: '&#x1F974;',
    category: 'Smileys & Emotion (face-unwell)',
    order: '',
  },
  {
    emoji: '🤯',
    name: 'exploding head',
    shortname: ':exploding_head:',
    unicode: '1F92F',
    html: '&#x1F92F;',
    category: 'Smileys & Emotion (face-unwell)',
    order: '',
  },
  {
    emoji: '🤠',
    name: 'cowboy hat face',
    shortname: ':cowboy_hat_face:',
    unicode: '1F920',
    html: '&#x1F920;',
    category: 'Smileys & Emotion (face-hat)',
    order: '',
  },
  {
    emoji: '🥳',
    name: 'partying face',
    shortname: ':partying_face:',
    unicode: '1F973',
    html: '&#x1F973;',
    category: 'Smileys & Emotion (face-hat)',
    order: '',
  },
  {
    emoji: '🧐',
    name: 'face with monocle',
    shortname: ':face_with_monocle:',
    unicode: '1F9D0',
    html: '&#x1F9D0;',
    category: 'Smileys & Emotion (face-glasses)',
    order: '',
  },
  {
    emoji: '☹️',
    name: 'frowning face',
    shortname: ':frowning_face:',
    unicode: '2639 FE0F',
    html: '&#x2639;&#xFE0F;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '',
  },
  {
    emoji: '🥺',
    name: 'pleading face',
    shortname: ':pleading_face:',
    unicode: '1F97A',
    html: '&#x1F97A;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '',
  },
  {
    emoji: '🥱',
    name: 'yawning face',
    shortname: ':yawning_face:',
    unicode: '1F971',
    html: '&#x1F971;',
    category: 'Smileys & Emotion (face-concerned)',
    order: '',
  },
  {
    emoji: '🤬',
    name: 'face with symbols on mouth',
    shortname: ':face_with_symbols_on_mouth:',
    unicode: '1F92C',
    html: '&#x1F92C;',
    category: 'Smileys & Emotion (face-negative)',
    order: '',
  },
  {
    emoji: '☠️',
    name: 'skull and crossbones',
    shortname: ':skull_and_crossbones:',
    unicode: '2620 FE0F',
    html: '&#x2620;&#xFE0F;',
    category: 'Smileys & Emotion (face-negative)',
    order: '',
  },
  {
    emoji: '🤡',
    name: 'clown face',
    shortname: ':clown_face:',
    unicode: '1F921',
    html: '&#x1F921;',
    category: 'Smileys & Emotion (face-costume)',
    order: '',
  },
  {
    emoji: '❣',
    name: 'heart exclamation',
    shortname: ':heart_exclamation:',
    unicode: '2763',
    html: '&#x2763;',
    category: 'Smileys & Emotion (emotion)',
    order: '',
  },
  {
    emoji: '❤',
    name: 'red heart',
    shortname: ':red_heart:',
    unicode: '2764',
    html: '&#x2764;',
    category: 'Smileys & Emotion (emotion)',
    order: '',
  },
  {
    emoji: '🧡',
    name: 'orange heart',
    shortname: ':orange_heart:',
    unicode: '1F9E1',
    html: '&#x1F9E1;',
    category: 'Smileys & Emotion (emotion)',
    order: '',
  },
  {
    emoji: '🤎',
    name: 'brown heart',
    shortname: ':brown_heart:',
    unicode: '1F90E',
    html: '&#x1F90E;',
    category: 'Smileys & Emotion (emotion)',
    order: '',
  },
  {
    emoji: '🤍',
    name: 'white heart',
    shortname: ':white_heart:',
    unicode: '1F90D',
    html: '&#x1F90D;',
    category: 'Smileys & Emotion (emotion)',
    order: '',
  },
  {
    emoji: '🕳️',
    name: 'hole',
    shortname: ':hole:',
    unicode: '1F573 FE0F',
    html: '&#x1F573;&#xFE0F;',
    category: 'Smileys & Emotion (emotion)',
    order: '',
  },
  {
    emoji: '👁️‍🗨️',
    name: 'eye in speech bubble',
    shortname: ':eye_in_speech_bubble:',
    unicode: '1F441 FE0F 200D 1F5E8 FE0F',
    html: '&#x1F441;&#xFE0F;&#x200D;&#x1F5E8;&#xFE0F;',
    category: 'Smileys & Emotion (emotion)',
    order: '',
  },
  {
    emoji: '👁‍🗨️',
    name: 'eye in speech bubble',
    shortname: ':eye_in_speech_bubble:',
    unicode: '1F441 200D 1F5E8 FE0F',
    html: '&#x1F441;&#x200D;&#x1F5E8;&#xFE0F;',
    category: 'Smileys & Emotion (emotion)',
    order: '',
  },
  {
    emoji: '👁️‍🗨',
    name: 'eye in speech bubble',
    shortname: ':eye_in_speech_bubble:',
    unicode: '1F441 FE0F 200D 1F5E8',
    html: '&#x1F441;&#xFE0F;&#x200D;&#x1F5E8;',
    category: 'Smileys & Emotion (emotion)',
    order: '',
  },
  {
    emoji: '🗨️',
    name: 'left speech bubble',
    shortname: ':left_speech_bubble:',
    unicode: '1F5E8 FE0F',
    html: '&#x1F5E8;&#xFE0F;',
    category: 'Smileys & Emotion (emotion)',
    order: '',
  },
  {
    emoji: '🗯️',
    name: 'right anger bubble',
    shortname: ':right_anger_bubble:',
    unicode: '1F5EF FE0F',
    html: '&#x1F5EF;&#xFE0F;',
    category: 'Smileys & Emotion (emotion)',
    order: '',
  },
  {
    emoji: '🤚',
    name: 'raised back of hand',
    shortname: ':raised_back_of_hand:',
    unicode: '1F91A',
    html: '&#x1F91A;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🤚🏻',
    name: 'raised back of hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F91A 1F3FB',
    html: '&#x1F91A;&#x1F3FB;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🤚🏼',
    name: 'raised back of hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F91A 1F3FC',
    html: '&#x1F91A;&#x1F3FC;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🤚🏽',
    name: 'raised back of hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F91A 1F3FD',
    html: '&#x1F91A;&#x1F3FD;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🤚🏾',
    name: 'raised back of hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F91A 1F3FE',
    html: '&#x1F91A;&#x1F3FE;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🤚🏿',
    name: 'raised back of hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F91A 1F3FF',
    html: '&#x1F91A;&#x1F3FF;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🖐️',
    name: 'hand with fingers splayed',
    shortname: ':hand_with_fingers_splayed:',
    unicode: '1F590 FE0F',
    html: '&#x1F590;&#xFE0F;',
    category: 'People & Body (hand-fingers-open)',
    order: '',
  },
  {
    emoji: '🤏',
    name: 'pinching hand',
    shortname: ':pinching_hand:',
    unicode: '1F90F',
    html: '&#x1F90F;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤏🏻',
    name: 'pinching hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F90F 1F3FB',
    html: '&#x1F90F;&#x1F3FB;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤏🏼',
    name: 'pinching hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F90F 1F3FC',
    html: '&#x1F90F;&#x1F3FC;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤏🏽',
    name: 'pinching hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F90F 1F3FD',
    html: '&#x1F90F;&#x1F3FD;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤏🏾',
    name: 'pinching hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F90F 1F3FE',
    html: '&#x1F90F;&#x1F3FE;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤏🏿',
    name: 'pinching hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F90F 1F3FF',
    html: '&#x1F90F;&#x1F3FF;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '✌',
    name: 'victory hand',
    shortname: ':victory_hand:',
    unicode: '270C',
    html: '&#x270C;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤞',
    name: 'crossed fingers',
    shortname: ':crossed_fingers:',
    unicode: '1F91E',
    html: '&#x1F91E;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤞🏻',
    name: 'crossed fingers: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F91E 1F3FB',
    html: '&#x1F91E;&#x1F3FB;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤞🏼',
    name: 'crossed fingers: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F91E 1F3FC',
    html: '&#x1F91E;&#x1F3FC;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤞🏽',
    name: 'crossed fingers: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F91E 1F3FD',
    html: '&#x1F91E;&#x1F3FD;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤞🏾',
    name: 'crossed fingers: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F91E 1F3FE',
    html: '&#x1F91E;&#x1F3FE;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤞🏿',
    name: 'crossed fingers: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F91E 1F3FF',
    html: '&#x1F91E;&#x1F3FF;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤟',
    name: 'love-you gesture',
    shortname: ':loveyou_gesture:',
    unicode: '1F91F',
    html: '&#x1F91F;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤟🏻',
    name: 'love-you gesture: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F91F 1F3FB',
    html: '&#x1F91F;&#x1F3FB;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤟🏼',
    name: 'love-you gesture: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F91F 1F3FC',
    html: '&#x1F91F;&#x1F3FC;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤟🏽',
    name: 'love-you gesture: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F91F 1F3FD',
    html: '&#x1F91F;&#x1F3FD;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤟🏾',
    name: 'love-you gesture: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F91F 1F3FE',
    html: '&#x1F91F;&#x1F3FE;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤟🏿',
    name: 'love-you gesture: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F91F 1F3FF',
    html: '&#x1F91F;&#x1F3FF;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤙',
    name: 'call me hand',
    shortname: ':call_me_hand:',
    unicode: '1F919',
    html: '&#x1F919;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤙🏻',
    name: 'call me hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F919 1F3FB',
    html: '&#x1F919;&#x1F3FB;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤙🏼',
    name: 'call me hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F919 1F3FC',
    html: '&#x1F919;&#x1F3FC;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤙🏽',
    name: 'call me hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F919 1F3FD',
    html: '&#x1F919;&#x1F3FD;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤙🏾',
    name: 'call me hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F919 1F3FE',
    html: '&#x1F919;&#x1F3FE;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '🤙🏿',
    name: 'call me hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F919 1F3FF',
    html: '&#x1F919;&#x1F3FF;',
    category: 'People & Body (hand-fingers-partial)',
    order: '',
  },
  {
    emoji: '☝',
    name: 'index pointing up',
    shortname: ':index_pointing_up:',
    unicode: '261D',
    html: '&#x261D;',
    category: 'People & Body (hand-single-finger)',
    order: '',
  },
  {
    emoji: '🤛',
    name: 'left-facing fist',
    shortname: ':leftfacing_fist:',
    unicode: '1F91B',
    html: '&#x1F91B;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '🤛🏻',
    name: 'left-facing fist: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F91B 1F3FB',
    html: '&#x1F91B;&#x1F3FB;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '🤛🏼',
    name: 'left-facing fist: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F91B 1F3FC',
    html: '&#x1F91B;&#x1F3FC;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '🤛🏽',
    name: 'left-facing fist: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F91B 1F3FD',
    html: '&#x1F91B;&#x1F3FD;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '🤛🏾',
    name: 'left-facing fist: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F91B 1F3FE',
    html: '&#x1F91B;&#x1F3FE;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '🤛🏿',
    name: 'left-facing fist: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F91B 1F3FF',
    html: '&#x1F91B;&#x1F3FF;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '🤜',
    name: 'right-facing fist',
    shortname: ':rightfacing_fist:',
    unicode: '1F91C',
    html: '&#x1F91C;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '🤜🏻',
    name: 'right-facing fist: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F91C 1F3FB',
    html: '&#x1F91C;&#x1F3FB;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '🤜🏼',
    name: 'right-facing fist: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F91C 1F3FC',
    html: '&#x1F91C;&#x1F3FC;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '🤜🏽',
    name: 'right-facing fist: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F91C 1F3FD',
    html: '&#x1F91C;&#x1F3FD;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '🤜🏾',
    name: 'right-facing fist: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F91C 1F3FE',
    html: '&#x1F91C;&#x1F3FE;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '🤜🏿',
    name: 'right-facing fist: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F91C 1F3FF',
    html: '&#x1F91C;&#x1F3FF;',
    category: 'People & Body (hand-fingers-closed)',
    order: '',
  },
  {
    emoji: '🤲',
    name: 'palms up together',
    shortname: ':palms_up_together:',
    unicode: '1F932',
    html: '&#x1F932;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🤲🏻',
    name: 'palms up together: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F932 1F3FB',
    html: '&#x1F932;&#x1F3FB;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🤲🏼',
    name: 'palms up together: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F932 1F3FC',
    html: '&#x1F932;&#x1F3FC;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🤲🏽',
    name: 'palms up together: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F932 1F3FD',
    html: '&#x1F932;&#x1F3FD;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🤲🏾',
    name: 'palms up together: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F932 1F3FE',
    html: '&#x1F932;&#x1F3FE;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🤲🏿',
    name: 'palms up together: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F932 1F3FF',
    html: '&#x1F932;&#x1F3FF;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '🤝',
    name: 'handshake',
    shortname: ':handshake:',
    unicode: '1F91D',
    html: '&#x1F91D;',
    category: 'People & Body (hands)',
    order: '',
  },
  {
    emoji: '✍️',
    name: 'writing hand',
    shortname: ':writing_hand:',
    unicode: '270D FE0F',
    html: '&#x270D;&#xFE0F;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '🤳',
    name: 'selfie',
    shortname: ':selfie:',
    unicode: '1F933',
    html: '&#x1F933;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '🤳🏻',
    name: 'selfie: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F933 1F3FB',
    html: '&#x1F933;&#x1F3FB;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '🤳🏼',
    name: 'selfie: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F933 1F3FC',
    html: '&#x1F933;&#x1F3FC;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '🤳🏽',
    name: 'selfie: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F933 1F3FD',
    html: '&#x1F933;&#x1F3FD;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '🤳🏾',
    name: 'selfie: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F933 1F3FE',
    html: '&#x1F933;&#x1F3FE;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '🤳🏿',
    name: 'selfie: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F933 1F3FF',
    html: '&#x1F933;&#x1F3FF;',
    category: 'People & Body (hand-prop)',
    order: '',
  },
  {
    emoji: '🦾',
    name: 'mechanical arm',
    shortname: ':mechanical_arm:',
    unicode: '1F9BE',
    html: '&#x1F9BE;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦿',
    name: 'mechanical leg',
    shortname: ':mechanical_leg:',
    unicode: '1F9BF',
    html: '&#x1F9BF;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦵',
    name: 'leg',
    shortname: ':leg:',
    unicode: '1F9B5',
    html: '&#x1F9B5;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦵🏻',
    name: 'leg: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9B5 1F3FB',
    html: '&#x1F9B5;&#x1F3FB;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦵🏼',
    name: 'leg: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9B5 1F3FC',
    html: '&#x1F9B5;&#x1F3FC;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦵🏽',
    name: 'leg: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9B5 1F3FD',
    html: '&#x1F9B5;&#x1F3FD;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦵🏾',
    name: 'leg: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9B5 1F3FE',
    html: '&#x1F9B5;&#x1F3FE;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦵🏿',
    name: 'leg: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9B5 1F3FF',
    html: '&#x1F9B5;&#x1F3FF;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦶',
    name: 'foot',
    shortname: ':foot:',
    unicode: '1F9B6',
    html: '&#x1F9B6;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦶🏻',
    name: 'foot: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9B6 1F3FB',
    html: '&#x1F9B6;&#x1F3FB;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦶🏼',
    name: 'foot: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9B6 1F3FC',
    html: '&#x1F9B6;&#x1F3FC;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦶🏽',
    name: 'foot: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9B6 1F3FD',
    html: '&#x1F9B6;&#x1F3FD;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦶🏾',
    name: 'foot: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9B6 1F3FE',
    html: '&#x1F9B6;&#x1F3FE;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦶🏿',
    name: 'foot: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9B6 1F3FF',
    html: '&#x1F9B6;&#x1F3FF;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦻',
    name: 'ear with hearing aid',
    shortname: ':ear_with_hearing_aid:',
    unicode: '1F9BB',
    html: '&#x1F9BB;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦻🏻',
    name: 'ear with hearing aid: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9BB 1F3FB',
    html: '&#x1F9BB;&#x1F3FB;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦻🏼',
    name: 'ear with hearing aid: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9BB 1F3FC',
    html: '&#x1F9BB;&#x1F3FC;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦻🏽',
    name: 'ear with hearing aid: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9BB 1F3FD',
    html: '&#x1F9BB;&#x1F3FD;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦻🏾',
    name: 'ear with hearing aid: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9BB 1F3FE',
    html: '&#x1F9BB;&#x1F3FE;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦻🏿',
    name: 'ear with hearing aid: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9BB 1F3FF',
    html: '&#x1F9BB;&#x1F3FF;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🧠',
    name: 'brain',
    shortname: ':brain:',
    unicode: '1F9E0',
    html: '&#x1F9E0;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦷',
    name: 'tooth',
    shortname: ':tooth:',
    unicode: '1F9B7',
    html: '&#x1F9B7;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🦴',
    name: 'bone',
    shortname: ':bone:',
    unicode: '1F9B4',
    html: '&#x1F9B4;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '👁️',
    name: 'eye',
    shortname: ':eye:',
    unicode: '1F441 FE0F',
    html: '&#x1F441;&#xFE0F;',
    category: 'People & Body (body-parts)',
    order: '',
  },
  {
    emoji: '🧒',
    name: 'child',
    shortname: ':child:',
    unicode: '1F9D2',
    html: '&#x1F9D2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧒🏻',
    name: 'child: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D2 1F3FB',
    html: '&#x1F9D2;&#x1F3FB;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧒🏼',
    name: 'child: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D2 1F3FC',
    html: '&#x1F9D2;&#x1F3FC;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧒🏽',
    name: 'child: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D2 1F3FD',
    html: '&#x1F9D2;&#x1F3FD;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧒🏾',
    name: 'child: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D2 1F3FE',
    html: '&#x1F9D2;&#x1F3FE;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧒🏿',
    name: 'child: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D2 1F3FF',
    html: '&#x1F9D2;&#x1F3FF;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑',
    name: 'person',
    shortname: ':person:',
    unicode: '1F9D1',
    html: '&#x1F9D1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏻',
    name: 'person: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB',
    html: '&#x1F9D1;&#x1F3FB;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏼',
    name: 'person: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC',
    html: '&#x1F9D1;&#x1F3FC;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏽',
    name: 'person: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD',
    html: '&#x1F9D1;&#x1F3FD;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏾',
    name: 'person: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE',
    html: '&#x1F9D1;&#x1F3FE;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏿',
    name: 'person: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF',
    html: '&#x1F9D1;&#x1F3FF;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧔',
    name: 'man: beard',
    shortname: ':beard:',
    unicode: '1F9D4',
    html: '&#x1F9D4;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧔🏻',
    name: 'man: light skin tone, beard',
    shortname: ':light_skin_tone_beard:',
    unicode: '1F9D4 1F3FB',
    html: '&#x1F9D4;&#x1F3FB;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧔🏼',
    name: 'man: medium-light skin tone, beard',
    shortname: ':mediumlight_skin_tone_beard:',
    unicode: '1F9D4 1F3FC',
    html: '&#x1F9D4;&#x1F3FC;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧔🏽',
    name: 'man: medium skin tone, beard',
    shortname: ':medium_skin_tone_beard:',
    unicode: '1F9D4 1F3FD',
    html: '&#x1F9D4;&#x1F3FD;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧔🏾',
    name: 'man: medium-dark skin tone, beard',
    shortname: ':mediumdark_skin_tone_beard:',
    unicode: '1F9D4 1F3FE',
    html: '&#x1F9D4;&#x1F3FE;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧔🏿',
    name: 'man: dark skin tone, beard',
    shortname: ':dark_skin_tone_beard:',
    unicode: '1F9D4 1F3FF',
    html: '&#x1F9D4;&#x1F3FF;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨‍🦰',
    name: 'man: red hair',
    shortname: ':red_hair:',
    unicode: '1F468 200D 1F9B0',
    html: '&#x1F468;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏻‍🦰',
    name: 'man: light skin tone, red hair',
    shortname: ':light_skin_tone_red_hair:',
    unicode: '1F468 1F3FB 200D 1F9B0',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏼‍🦰',
    name: 'man: medium-light skin tone, red hair',
    shortname: ':mediumlight_skin_tone_red_hair:',
    unicode: '1F468 1F3FC 200D 1F9B0',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏽‍🦰',
    name: 'man: medium skin tone, red hair',
    shortname: ':medium_skin_tone_red_hair:',
    unicode: '1F468 1F3FD 200D 1F9B0',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏾‍🦰',
    name: 'man: medium-dark skin tone, red hair',
    shortname: ':mediumdark_skin_tone_red_hair:',
    unicode: '1F468 1F3FE 200D 1F9B0',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏿‍🦰',
    name: 'man: dark skin tone, red hair',
    shortname: ':dark_skin_tone_red_hair:',
    unicode: '1F468 1F3FF 200D 1F9B0',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨‍🦱',
    name: 'man: curly hair',
    shortname: ':curly_hair:',
    unicode: '1F468 200D 1F9B1',
    html: '&#x1F468;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏻‍🦱',
    name: 'man: light skin tone, curly hair',
    shortname: ':light_skin_tone_curly_hair:',
    unicode: '1F468 1F3FB 200D 1F9B1',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏼‍🦱',
    name: 'man: medium-light skin tone, curly hair',
    shortname: ':mediumlight_skin_tone_curly_hair:',
    unicode: '1F468 1F3FC 200D 1F9B1',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏽‍🦱',
    name: 'man: medium skin tone, curly hair',
    shortname: ':medium_skin_tone_curly_hair:',
    unicode: '1F468 1F3FD 200D 1F9B1',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏾‍🦱',
    name: 'man: medium-dark skin tone, curly hair',
    shortname: ':mediumdark_skin_tone_curly_hair:',
    unicode: '1F468 1F3FE 200D 1F9B1',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏿‍🦱',
    name: 'man: dark skin tone, curly hair',
    shortname: ':dark_skin_tone_curly_hair:',
    unicode: '1F468 1F3FF 200D 1F9B1',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨‍🦳',
    name: 'man: white hair',
    shortname: ':white_hair:',
    unicode: '1F468 200D 1F9B3',
    html: '&#x1F468;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏻‍🦳',
    name: 'man: light skin tone, white hair',
    shortname: ':light_skin_tone_white_hair:',
    unicode: '1F468 1F3FB 200D 1F9B3',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏼‍🦳',
    name: 'man: medium-light skin tone, white hair',
    shortname: ':mediumlight_skin_tone_white_hair:',
    unicode: '1F468 1F3FC 200D 1F9B3',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏽‍🦳',
    name: 'man: medium skin tone, white hair',
    shortname: ':medium_skin_tone_white_hair:',
    unicode: '1F468 1F3FD 200D 1F9B3',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏾‍🦳',
    name: 'man: medium-dark skin tone, white hair',
    shortname: ':mediumdark_skin_tone_white_hair:',
    unicode: '1F468 1F3FE 200D 1F9B3',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏿‍🦳',
    name: 'man: dark skin tone, white hair',
    shortname: ':dark_skin_tone_white_hair:',
    unicode: '1F468 1F3FF 200D 1F9B3',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨‍🦲',
    name: 'man: bald',
    shortname: ':bald:',
    unicode: '1F468 200D 1F9B2',
    html: '&#x1F468;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏻‍🦲',
    name: 'man: light skin tone, bald',
    shortname: ':light_skin_tone_bald:',
    unicode: '1F468 1F3FB 200D 1F9B2',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏼‍🦲',
    name: 'man: medium-light skin tone, bald',
    shortname: ':mediumlight_skin_tone_bald:',
    unicode: '1F468 1F3FC 200D 1F9B2',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏽‍🦲',
    name: 'man: medium skin tone, bald',
    shortname: ':medium_skin_tone_bald:',
    unicode: '1F468 1F3FD 200D 1F9B2',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏾‍🦲',
    name: 'man: medium-dark skin tone, bald',
    shortname: ':mediumdark_skin_tone_bald:',
    unicode: '1F468 1F3FE 200D 1F9B2',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👨🏿‍🦲',
    name: 'man: dark skin tone, bald',
    shortname: ':dark_skin_tone_bald:',
    unicode: '1F468 1F3FF 200D 1F9B2',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩‍🦰',
    name: 'woman: red hair',
    shortname: ':red_hair:',
    unicode: '1F469 200D 1F9B0',
    html: '&#x1F469;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏻‍🦰',
    name: 'woman: light skin tone, red hair',
    shortname: ':light_skin_tone_red_hair:',
    unicode: '1F469 1F3FB 200D 1F9B0',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏼‍🦰',
    name: 'woman: medium-light skin tone, red hair',
    shortname: ':mediumlight_skin_tone_red_hair:',
    unicode: '1F469 1F3FC 200D 1F9B0',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏽‍🦰',
    name: 'woman: medium skin tone, red hair',
    shortname: ':medium_skin_tone_red_hair:',
    unicode: '1F469 1F3FD 200D 1F9B0',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏾‍🦰',
    name: 'woman: medium-dark skin tone, red hair',
    shortname: ':mediumdark_skin_tone_red_hair:',
    unicode: '1F469 1F3FE 200D 1F9B0',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏿‍🦰',
    name: 'woman: dark skin tone, red hair',
    shortname: ':dark_skin_tone_red_hair:',
    unicode: '1F469 1F3FF 200D 1F9B0',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑‍🦰',
    name: 'person: red hair',
    shortname: ':red_hair:',
    unicode: '1F9D1 200D 1F9B0',
    html: '&#x1F9D1;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🦰',
    name: 'person: light skin tone, red hair',
    shortname: ':light_skin_tone_red_hair:',
    unicode: '1F9D1 1F3FB 200D 1F9B0',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🦰',
    name: 'person: medium-light skin tone, red hair',
    shortname: ':mediumlight_skin_tone_red_hair:',
    unicode: '1F9D1 1F3FC 200D 1F9B0',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🦰',
    name: 'person: medium skin tone, red hair',
    shortname: ':medium_skin_tone_red_hair:',
    unicode: '1F9D1 1F3FD 200D 1F9B0',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🦰',
    name: 'person: medium-dark skin tone, red hair',
    shortname: ':mediumdark_skin_tone_red_hair:',
    unicode: '1F9D1 1F3FE 200D 1F9B0',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🦰',
    name: 'person: dark skin tone, red hair',
    shortname: ':dark_skin_tone_red_hair:',
    unicode: '1F9D1 1F3FF 200D 1F9B0',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F9B0;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩‍🦱',
    name: 'woman: curly hair',
    shortname: ':curly_hair:',
    unicode: '1F469 200D 1F9B1',
    html: '&#x1F469;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏻‍🦱',
    name: 'woman: light skin tone, curly hair',
    shortname: ':light_skin_tone_curly_hair:',
    unicode: '1F469 1F3FB 200D 1F9B1',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏼‍🦱',
    name: 'woman: medium-light skin tone, curly hair',
    shortname: ':mediumlight_skin_tone_curly_hair:',
    unicode: '1F469 1F3FC 200D 1F9B1',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏽‍🦱',
    name: 'woman: medium skin tone, curly hair',
    shortname: ':medium_skin_tone_curly_hair:',
    unicode: '1F469 1F3FD 200D 1F9B1',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏾‍🦱',
    name: 'woman: medium-dark skin tone, curly hair',
    shortname: ':mediumdark_skin_tone_curly_hair:',
    unicode: '1F469 1F3FE 200D 1F9B1',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏿‍🦱',
    name: 'woman: dark skin tone, curly hair',
    shortname: ':dark_skin_tone_curly_hair:',
    unicode: '1F469 1F3FF 200D 1F9B1',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑‍🦱',
    name: 'person: curly hair',
    shortname: ':curly_hair:',
    unicode: '1F9D1 200D 1F9B1',
    html: '&#x1F9D1;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🦱',
    name: 'person: light skin tone, curly hair',
    shortname: ':light_skin_tone_curly_hair:',
    unicode: '1F9D1 1F3FB 200D 1F9B1',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🦱',
    name: 'person: medium-light skin tone, curly hair',
    shortname: ':mediumlight_skin_tone_curly_hair:',
    unicode: '1F9D1 1F3FC 200D 1F9B1',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🦱',
    name: 'person: medium skin tone, curly hair',
    shortname: ':medium_skin_tone_curly_hair:',
    unicode: '1F9D1 1F3FD 200D 1F9B1',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🦱',
    name: 'person: medium-dark skin tone, curly hair',
    shortname: ':mediumdark_skin_tone_curly_hair:',
    unicode: '1F9D1 1F3FE 200D 1F9B1',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🦱',
    name: 'person: dark skin tone, curly hair',
    shortname: ':dark_skin_tone_curly_hair:',
    unicode: '1F9D1 1F3FF 200D 1F9B1',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F9B1;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩‍🦳',
    name: 'woman: white hair',
    shortname: ':white_hair:',
    unicode: '1F469 200D 1F9B3',
    html: '&#x1F469;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏻‍🦳',
    name: 'woman: light skin tone, white hair',
    shortname: ':light_skin_tone_white_hair:',
    unicode: '1F469 1F3FB 200D 1F9B3',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏼‍🦳',
    name: 'woman: medium-light skin tone, white hair',
    shortname: ':mediumlight_skin_tone_white_hair:',
    unicode: '1F469 1F3FC 200D 1F9B3',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏽‍🦳',
    name: 'woman: medium skin tone, white hair',
    shortname: ':medium_skin_tone_white_hair:',
    unicode: '1F469 1F3FD 200D 1F9B3',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏾‍🦳',
    name: 'woman: medium-dark skin tone, white hair',
    shortname: ':mediumdark_skin_tone_white_hair:',
    unicode: '1F469 1F3FE 200D 1F9B3',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏿‍🦳',
    name: 'woman: dark skin tone, white hair',
    shortname: ':dark_skin_tone_white_hair:',
    unicode: '1F469 1F3FF 200D 1F9B3',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑‍🦳',
    name: 'person: white hair',
    shortname: ':white_hair:',
    unicode: '1F9D1 200D 1F9B3',
    html: '&#x1F9D1;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🦳',
    name: 'person: light skin tone, white hair',
    shortname: ':light_skin_tone_white_hair:',
    unicode: '1F9D1 1F3FB 200D 1F9B3',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🦳',
    name: 'person: medium-light skin tone, white hair',
    shortname: ':mediumlight_skin_tone_white_hair:',
    unicode: '1F9D1 1F3FC 200D 1F9B3',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🦳',
    name: 'person: medium skin tone, white hair',
    shortname: ':medium_skin_tone_white_hair:',
    unicode: '1F9D1 1F3FD 200D 1F9B3',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🦳',
    name: 'person: medium-dark skin tone, white hair',
    shortname: ':mediumdark_skin_tone_white_hair:',
    unicode: '1F9D1 1F3FE 200D 1F9B3',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🦳',
    name: 'person: dark skin tone, white hair',
    shortname: ':dark_skin_tone_white_hair:',
    unicode: '1F9D1 1F3FF 200D 1F9B3',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F9B3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩‍🦲',
    name: 'woman: bald',
    shortname: ':bald:',
    unicode: '1F469 200D 1F9B2',
    html: '&#x1F469;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏻‍🦲',
    name: 'woman: light skin tone, bald',
    shortname: ':light_skin_tone_bald:',
    unicode: '1F469 1F3FB 200D 1F9B2',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏼‍🦲',
    name: 'woman: medium-light skin tone, bald',
    shortname: ':mediumlight_skin_tone_bald:',
    unicode: '1F469 1F3FC 200D 1F9B2',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏽‍🦲',
    name: 'woman: medium skin tone, bald',
    shortname: ':medium_skin_tone_bald:',
    unicode: '1F469 1F3FD 200D 1F9B2',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏾‍🦲',
    name: 'woman: medium-dark skin tone, bald',
    shortname: ':mediumdark_skin_tone_bald:',
    unicode: '1F469 1F3FE 200D 1F9B2',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👩🏿‍🦲',
    name: 'woman: dark skin tone, bald',
    shortname: ':dark_skin_tone_bald:',
    unicode: '1F469 1F3FF 200D 1F9B2',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑‍🦲',
    name: 'person: bald',
    shortname: ':bald:',
    unicode: '1F9D1 200D 1F9B2',
    html: '&#x1F9D1;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🦲',
    name: 'person: light skin tone, bald',
    shortname: ':light_skin_tone_bald:',
    unicode: '1F9D1 1F3FB 200D 1F9B2',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🦲',
    name: 'person: medium-light skin tone, bald',
    shortname: ':mediumlight_skin_tone_bald:',
    unicode: '1F9D1 1F3FC 200D 1F9B2',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🦲',
    name: 'person: medium skin tone, bald',
    shortname: ':medium_skin_tone_bald:',
    unicode: '1F9D1 1F3FD 200D 1F9B2',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🦲',
    name: 'person: medium-dark skin tone, bald',
    shortname: ':mediumdark_skin_tone_bald:',
    unicode: '1F9D1 1F3FE 200D 1F9B2',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🦲',
    name: 'person: dark skin tone, bald',
    shortname: ':dark_skin_tone_bald:',
    unicode: '1F9D1 1F3FF 200D 1F9B2',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F9B2;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱‍♀️',
    name: 'woman: blond hair',
    shortname: ':blond_hair:',
    unicode: '1F471 200D 2640 FE0F',
    html: '&#x1F471;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏻‍♀️',
    name: 'woman: light skin tone, blond hair',
    shortname: ':light_skin_tone_blond_hair:',
    unicode: '1F471 1F3FB 200D 2640 FE0F',
    html: '&#x1F471;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏼‍♀️',
    name: 'woman: medium-light skin tone, blond hair',
    shortname: ':mediumlight_skin_tone_blond_hair:',
    unicode: '1F471 1F3FC 200D 2640 FE0F',
    html: '&#x1F471;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏽‍♀️',
    name: 'woman: medium skin tone, blond hair',
    shortname: ':medium_skin_tone_blond_hair:',
    unicode: '1F471 1F3FD 200D 2640 FE0F',
    html: '&#x1F471;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏾‍♀️',
    name: 'woman: medium-dark skin tone, blond hair',
    shortname: ':mediumdark_skin_tone_blond_hair:',
    unicode: '1F471 1F3FE 200D 2640 FE0F',
    html: '&#x1F471;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏿‍♀️',
    name: 'woman: dark skin tone, blond hair',
    shortname: ':dark_skin_tone_blond_hair:',
    unicode: '1F471 1F3FF 200D 2640 FE0F',
    html: '&#x1F471;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱‍♂️',
    name: 'man: blond hair',
    shortname: ':blond_hair:',
    unicode: '1F471 200D 2642 FE0F',
    html: '&#x1F471;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱‍♂',
    name: 'man: blond hair',
    shortname: ':blond_hair:',
    unicode: '1F471 200D 2642',
    html: '&#x1F471;&#x200D;&#x2642;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏻‍♂️',
    name: 'man: light skin tone, blond hair',
    shortname: ':light_skin_tone_blond_hair:',
    unicode: '1F471 1F3FB 200D 2642 FE0F',
    html: '&#x1F471;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏻‍♂',
    name: 'man: light skin tone, blond hair',
    shortname: ':light_skin_tone_blond_hair:',
    unicode: '1F471 1F3FB 200D 2642',
    html: '&#x1F471;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏼‍♂️',
    name: 'man: medium-light skin tone, blond hair',
    shortname: ':mediumlight_skin_tone_blond_hair:',
    unicode: '1F471 1F3FC 200D 2642 FE0F',
    html: '&#x1F471;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏼‍♂',
    name: 'man: medium-light skin tone, blond hair',
    shortname: ':mediumlight_skin_tone_blond_hair:',
    unicode: '1F471 1F3FC 200D 2642',
    html: '&#x1F471;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏽‍♂️',
    name: 'man: medium skin tone, blond hair',
    shortname: ':medium_skin_tone_blond_hair:',
    unicode: '1F471 1F3FD 200D 2642 FE0F',
    html: '&#x1F471;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏽‍♂',
    name: 'man: medium skin tone, blond hair',
    shortname: ':medium_skin_tone_blond_hair:',
    unicode: '1F471 1F3FD 200D 2642',
    html: '&#x1F471;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏾‍♂️',
    name: 'man: medium-dark skin tone, blond hair',
    shortname: ':mediumdark_skin_tone_blond_hair:',
    unicode: '1F471 1F3FE 200D 2642 FE0F',
    html: '&#x1F471;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏾‍♂',
    name: 'man: medium-dark skin tone, blond hair',
    shortname: ':mediumdark_skin_tone_blond_hair:',
    unicode: '1F471 1F3FE 200D 2642',
    html: '&#x1F471;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏿‍♂️',
    name: 'man: dark skin tone, blond hair',
    shortname: ':dark_skin_tone_blond_hair:',
    unicode: '1F471 1F3FF 200D 2642 FE0F',
    html: '&#x1F471;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '👱🏿‍♂',
    name: 'man: dark skin tone, blond hair',
    shortname: ':dark_skin_tone_blond_hair:',
    unicode: '1F471 1F3FF 200D 2642',
    html: '&#x1F471;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧓',
    name: 'older person',
    shortname: ':older_person:',
    unicode: '1F9D3',
    html: '&#x1F9D3;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧓🏻',
    name: 'older person: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D3 1F3FB',
    html: '&#x1F9D3;&#x1F3FB;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧓🏼',
    name: 'older person: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D3 1F3FC',
    html: '&#x1F9D3;&#x1F3FC;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧓🏽',
    name: 'older person: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D3 1F3FD',
    html: '&#x1F9D3;&#x1F3FD;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧓🏾',
    name: 'older person: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D3 1F3FE',
    html: '&#x1F9D3;&#x1F3FE;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🧓🏿',
    name: 'older person: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D3 1F3FF',
    html: '&#x1F9D3;&#x1F3FF;',
    category: 'People & Body (person)',
    order: '',
  },
  {
    emoji: '🙍‍♂️',
    name: 'man frowning',
    shortname: ':man_frowning:',
    unicode: '1F64D 200D 2642 FE0F',
    html: '&#x1F64D;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏻‍♂️',
    name: 'man frowning: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64D 1F3FB 200D 2642 FE0F',
    html: '&#x1F64D;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏼‍♂️',
    name: 'man frowning: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64D 1F3FC 200D 2642 FE0F',
    html: '&#x1F64D;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏽‍♂️',
    name: 'man frowning: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64D 1F3FD 200D 2642 FE0F',
    html: '&#x1F64D;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏾‍♂️',
    name: 'man frowning: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64D 1F3FE 200D 2642 FE0F',
    html: '&#x1F64D;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏿‍♂️',
    name: 'man frowning: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64D 1F3FF 200D 2642 FE0F',
    html: '&#x1F64D;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍‍♀️',
    name: 'woman frowning',
    shortname: ':woman_frowning:',
    unicode: '1F64D 200D 2640 FE0F',
    html: '&#x1F64D;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍‍♀',
    name: 'woman frowning',
    shortname: ':woman_frowning:',
    unicode: '1F64D 200D 2640',
    html: '&#x1F64D;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏻‍♀️',
    name: 'woman frowning: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64D 1F3FB 200D 2640 FE0F',
    html: '&#x1F64D;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏻‍♀',
    name: 'woman frowning: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64D 1F3FB 200D 2640',
    html: '&#x1F64D;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏼‍♀️',
    name: 'woman frowning: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64D 1F3FC 200D 2640 FE0F',
    html: '&#x1F64D;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏼‍♀',
    name: 'woman frowning: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64D 1F3FC 200D 2640',
    html: '&#x1F64D;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏽‍♀️',
    name: 'woman frowning: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64D 1F3FD 200D 2640 FE0F',
    html: '&#x1F64D;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏽‍♀',
    name: 'woman frowning: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64D 1F3FD 200D 2640',
    html: '&#x1F64D;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏾‍♀️',
    name: 'woman frowning: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64D 1F3FE 200D 2640 FE0F',
    html: '&#x1F64D;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏾‍♀',
    name: 'woman frowning: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64D 1F3FE 200D 2640',
    html: '&#x1F64D;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏿‍♀️',
    name: 'woman frowning: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64D 1F3FF 200D 2640 FE0F',
    html: '&#x1F64D;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙍🏿‍♀',
    name: 'woman frowning: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64D 1F3FF 200D 2640',
    html: '&#x1F64D;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎‍♂️',
    name: 'man pouting',
    shortname: ':man_pouting:',
    unicode: '1F64E 200D 2642 FE0F',
    html: '&#x1F64E;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏻‍♂️',
    name: 'man pouting: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64E 1F3FB 200D 2642 FE0F',
    html: '&#x1F64E;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏼‍♂️',
    name: 'man pouting: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64E 1F3FC 200D 2642 FE0F',
    html: '&#x1F64E;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏽‍♂️',
    name: 'man pouting: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64E 1F3FD 200D 2642 FE0F',
    html: '&#x1F64E;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏾‍♂️',
    name: 'man pouting: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64E 1F3FE 200D 2642 FE0F',
    html: '&#x1F64E;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏿‍♂️',
    name: 'man pouting: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64E 1F3FF 200D 2642 FE0F',
    html: '&#x1F64E;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎‍♀️',
    name: 'woman pouting',
    shortname: ':woman_pouting:',
    unicode: '1F64E 200D 2640 FE0F',
    html: '&#x1F64E;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎‍♀',
    name: 'woman pouting',
    shortname: ':woman_pouting:',
    unicode: '1F64E 200D 2640',
    html: '&#x1F64E;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏻‍♀️',
    name: 'woman pouting: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64E 1F3FB 200D 2640 FE0F',
    html: '&#x1F64E;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏻‍♀',
    name: 'woman pouting: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64E 1F3FB 200D 2640',
    html: '&#x1F64E;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏼‍♀️',
    name: 'woman pouting: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64E 1F3FC 200D 2640 FE0F',
    html: '&#x1F64E;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏼‍♀',
    name: 'woman pouting: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64E 1F3FC 200D 2640',
    html: '&#x1F64E;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏽‍♀️',
    name: 'woman pouting: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64E 1F3FD 200D 2640 FE0F',
    html: '&#x1F64E;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏽‍♀',
    name: 'woman pouting: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64E 1F3FD 200D 2640',
    html: '&#x1F64E;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏾‍♀️',
    name: 'woman pouting: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64E 1F3FE 200D 2640 FE0F',
    html: '&#x1F64E;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏾‍♀',
    name: 'woman pouting: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64E 1F3FE 200D 2640',
    html: '&#x1F64E;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏿‍♀️',
    name: 'woman pouting: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64E 1F3FF 200D 2640 FE0F',
    html: '&#x1F64E;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙎🏿‍♀',
    name: 'woman pouting: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64E 1F3FF 200D 2640',
    html: '&#x1F64E;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅‍♂️',
    name: 'man gesturing NO',
    shortname: ':man_gesturing_NO:',
    unicode: '1F645 200D 2642 FE0F',
    html: '&#x1F645;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏻‍♂️',
    name: 'man gesturing NO: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F645 1F3FB 200D 2642 FE0F',
    html: '&#x1F645;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏼‍♂️',
    name: 'man gesturing NO: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F645 1F3FC 200D 2642 FE0F',
    html: '&#x1F645;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏽‍♂️',
    name: 'man gesturing NO: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F645 1F3FD 200D 2642 FE0F',
    html: '&#x1F645;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏾‍♂️',
    name: 'man gesturing NO: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F645 1F3FE 200D 2642 FE0F',
    html: '&#x1F645;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏿‍♂️',
    name: 'man gesturing NO: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F645 1F3FF 200D 2642 FE0F',
    html: '&#x1F645;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅‍♀️',
    name: 'woman gesturing NO',
    shortname: ':woman_gesturing_NO:',
    unicode: '1F645 200D 2640 FE0F',
    html: '&#x1F645;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅‍♀',
    name: 'woman gesturing NO',
    shortname: ':woman_gesturing_NO:',
    unicode: '1F645 200D 2640',
    html: '&#x1F645;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏻‍♀️',
    name: 'woman gesturing NO: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F645 1F3FB 200D 2640 FE0F',
    html: '&#x1F645;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏻‍♀',
    name: 'woman gesturing NO: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F645 1F3FB 200D 2640',
    html: '&#x1F645;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏼‍♀️',
    name: 'woman gesturing NO: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F645 1F3FC 200D 2640 FE0F',
    html: '&#x1F645;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏼‍♀',
    name: 'woman gesturing NO: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F645 1F3FC 200D 2640',
    html: '&#x1F645;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏽‍♀️',
    name: 'woman gesturing NO: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F645 1F3FD 200D 2640 FE0F',
    html: '&#x1F645;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏽‍♀',
    name: 'woman gesturing NO: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F645 1F3FD 200D 2640',
    html: '&#x1F645;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏾‍♀️',
    name: 'woman gesturing NO: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F645 1F3FE 200D 2640 FE0F',
    html: '&#x1F645;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏾‍♀',
    name: 'woman gesturing NO: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F645 1F3FE 200D 2640',
    html: '&#x1F645;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏿‍♀️',
    name: 'woman gesturing NO: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F645 1F3FF 200D 2640 FE0F',
    html: '&#x1F645;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙅🏿‍♀',
    name: 'woman gesturing NO: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F645 1F3FF 200D 2640',
    html: '&#x1F645;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆‍♂️',
    name: 'man gesturing OK',
    shortname: ':man_gesturing_OK:',
    unicode: '1F646 200D 2642 FE0F',
    html: '&#x1F646;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏻‍♂️',
    name: 'man gesturing OK: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F646 1F3FB 200D 2642 FE0F',
    html: '&#x1F646;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏼‍♂️',
    name: 'man gesturing OK: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F646 1F3FC 200D 2642 FE0F',
    html: '&#x1F646;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏽‍♂️',
    name: 'man gesturing OK: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F646 1F3FD 200D 2642 FE0F',
    html: '&#x1F646;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏾‍♂️',
    name: 'man gesturing OK: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F646 1F3FE 200D 2642 FE0F',
    html: '&#x1F646;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏿‍♂️',
    name: 'man gesturing OK: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F646 1F3FF 200D 2642 FE0F',
    html: '&#x1F646;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆‍♀️',
    name: 'woman gesturing OK',
    shortname: ':woman_gesturing_OK:',
    unicode: '1F646 200D 2640 FE0F',
    html: '&#x1F646;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆‍♀',
    name: 'woman gesturing OK',
    shortname: ':woman_gesturing_OK:',
    unicode: '1F646 200D 2640',
    html: '&#x1F646;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏻‍♀️',
    name: 'woman gesturing OK: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F646 1F3FB 200D 2640 FE0F',
    html: '&#x1F646;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏻‍♀',
    name: 'woman gesturing OK: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F646 1F3FB 200D 2640',
    html: '&#x1F646;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏼‍♀️',
    name: 'woman gesturing OK: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F646 1F3FC 200D 2640 FE0F',
    html: '&#x1F646;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏼‍♀',
    name: 'woman gesturing OK: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F646 1F3FC 200D 2640',
    html: '&#x1F646;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏽‍♀️',
    name: 'woman gesturing OK: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F646 1F3FD 200D 2640 FE0F',
    html: '&#x1F646;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏽‍♀',
    name: 'woman gesturing OK: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F646 1F3FD 200D 2640',
    html: '&#x1F646;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏾‍♀️',
    name: 'woman gesturing OK: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F646 1F3FE 200D 2640 FE0F',
    html: '&#x1F646;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏾‍♀',
    name: 'woman gesturing OK: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F646 1F3FE 200D 2640',
    html: '&#x1F646;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏿‍♀️',
    name: 'woman gesturing OK: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F646 1F3FF 200D 2640 FE0F',
    html: '&#x1F646;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙆🏿‍♀',
    name: 'woman gesturing OK: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F646 1F3FF 200D 2640',
    html: '&#x1F646;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁‍♂️',
    name: 'man tipping hand',
    shortname: ':man_tipping_hand:',
    unicode: '1F481 200D 2642 FE0F',
    html: '&#x1F481;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏻‍♂️',
    name: 'man tipping hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F481 1F3FB 200D 2642 FE0F',
    html: '&#x1F481;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏼‍♂️',
    name: 'man tipping hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F481 1F3FC 200D 2642 FE0F',
    html: '&#x1F481;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏽‍♂️',
    name: 'man tipping hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F481 1F3FD 200D 2642 FE0F',
    html: '&#x1F481;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏾‍♂️',
    name: 'man tipping hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F481 1F3FE 200D 2642 FE0F',
    html: '&#x1F481;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏿‍♂️',
    name: 'man tipping hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F481 1F3FF 200D 2642 FE0F',
    html: '&#x1F481;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁‍♀️',
    name: 'woman tipping hand',
    shortname: ':woman_tipping_hand:',
    unicode: '1F481 200D 2640 FE0F',
    html: '&#x1F481;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁‍♀',
    name: 'woman tipping hand',
    shortname: ':woman_tipping_hand:',
    unicode: '1F481 200D 2640',
    html: '&#x1F481;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏻‍♀️',
    name: 'woman tipping hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F481 1F3FB 200D 2640 FE0F',
    html: '&#x1F481;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏻‍♀',
    name: 'woman tipping hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F481 1F3FB 200D 2640',
    html: '&#x1F481;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏼‍♀️',
    name: 'woman tipping hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F481 1F3FC 200D 2640 FE0F',
    html: '&#x1F481;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏼‍♀',
    name: 'woman tipping hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F481 1F3FC 200D 2640',
    html: '&#x1F481;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏽‍♀️',
    name: 'woman tipping hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F481 1F3FD 200D 2640 FE0F',
    html: '&#x1F481;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏽‍♀',
    name: 'woman tipping hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F481 1F3FD 200D 2640',
    html: '&#x1F481;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏾‍♀️',
    name: 'woman tipping hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F481 1F3FE 200D 2640 FE0F',
    html: '&#x1F481;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏾‍♀',
    name: 'woman tipping hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F481 1F3FE 200D 2640',
    html: '&#x1F481;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏿‍♀️',
    name: 'woman tipping hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F481 1F3FF 200D 2640 FE0F',
    html: '&#x1F481;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '💁🏿‍♀',
    name: 'woman tipping hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F481 1F3FF 200D 2640',
    html: '&#x1F481;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋‍♂️',
    name: 'man raising hand',
    shortname: ':man_raising_hand:',
    unicode: '1F64B 200D 2642 FE0F',
    html: '&#x1F64B;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏻‍♂️',
    name: 'man raising hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64B 1F3FB 200D 2642 FE0F',
    html: '&#x1F64B;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏼‍♂️',
    name: 'man raising hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64B 1F3FC 200D 2642 FE0F',
    html: '&#x1F64B;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏽‍♂️',
    name: 'man raising hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64B 1F3FD 200D 2642 FE0F',
    html: '&#x1F64B;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏾‍♂️',
    name: 'man raising hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64B 1F3FE 200D 2642 FE0F',
    html: '&#x1F64B;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏿‍♂️',
    name: 'man raising hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64B 1F3FF 200D 2642 FE0F',
    html: '&#x1F64B;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋‍♀️',
    name: 'woman raising hand',
    shortname: ':woman_raising_hand:',
    unicode: '1F64B 200D 2640 FE0F',
    html: '&#x1F64B;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋‍♀',
    name: 'woman raising hand',
    shortname: ':woman_raising_hand:',
    unicode: '1F64B 200D 2640',
    html: '&#x1F64B;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏻‍♀️',
    name: 'woman raising hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64B 1F3FB 200D 2640 FE0F',
    html: '&#x1F64B;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏻‍♀',
    name: 'woman raising hand: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F64B 1F3FB 200D 2640',
    html: '&#x1F64B;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏼‍♀️',
    name: 'woman raising hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64B 1F3FC 200D 2640 FE0F',
    html: '&#x1F64B;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏼‍♀',
    name: 'woman raising hand: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F64B 1F3FC 200D 2640',
    html: '&#x1F64B;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏽‍♀️',
    name: 'woman raising hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64B 1F3FD 200D 2640 FE0F',
    html: '&#x1F64B;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏽‍♀',
    name: 'woman raising hand: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F64B 1F3FD 200D 2640',
    html: '&#x1F64B;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏾‍♀️',
    name: 'woman raising hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64B 1F3FE 200D 2640 FE0F',
    html: '&#x1F64B;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏾‍♀',
    name: 'woman raising hand: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F64B 1F3FE 200D 2640',
    html: '&#x1F64B;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏿‍♀️',
    name: 'woman raising hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64B 1F3FF 200D 2640 FE0F',
    html: '&#x1F64B;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙋🏿‍♀',
    name: 'woman raising hand: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F64B 1F3FF 200D 2640',
    html: '&#x1F64B;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏',
    name: 'deaf person',
    shortname: ':deaf_person:',
    unicode: '1F9CF',
    html: '&#x1F9CF;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏻',
    name: 'deaf person: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CF 1F3FB',
    html: '&#x1F9CF;&#x1F3FB;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏼',
    name: 'deaf person: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CF 1F3FC',
    html: '&#x1F9CF;&#x1F3FC;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏽',
    name: 'deaf person: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CF 1F3FD',
    html: '&#x1F9CF;&#x1F3FD;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏾',
    name: 'deaf person: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CF 1F3FE',
    html: '&#x1F9CF;&#x1F3FE;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏿',
    name: 'deaf person: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CF 1F3FF',
    html: '&#x1F9CF;&#x1F3FF;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏‍♂️',
    name: 'deaf man',
    shortname: ':deaf_man:',
    unicode: '1F9CF 200D 2642 FE0F',
    html: '&#x1F9CF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏‍♂',
    name: 'deaf man',
    shortname: ':deaf_man:',
    unicode: '1F9CF 200D 2642',
    html: '&#x1F9CF;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏻‍♂️',
    name: 'deaf man: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CF 1F3FB 200D 2642 FE0F',
    html: '&#x1F9CF;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏻‍♂',
    name: 'deaf man: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CF 1F3FB 200D 2642',
    html: '&#x1F9CF;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏼‍♂️',
    name: 'deaf man: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CF 1F3FC 200D 2642 FE0F',
    html: '&#x1F9CF;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏼‍♂',
    name: 'deaf man: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CF 1F3FC 200D 2642',
    html: '&#x1F9CF;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏽‍♂️',
    name: 'deaf man: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CF 1F3FD 200D 2642 FE0F',
    html: '&#x1F9CF;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏽‍♂',
    name: 'deaf man: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CF 1F3FD 200D 2642',
    html: '&#x1F9CF;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏾‍♂️',
    name: 'deaf man: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CF 1F3FE 200D 2642 FE0F',
    html: '&#x1F9CF;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏾‍♂',
    name: 'deaf man: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CF 1F3FE 200D 2642',
    html: '&#x1F9CF;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏿‍♂️',
    name: 'deaf man: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CF 1F3FF 200D 2642 FE0F',
    html: '&#x1F9CF;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏿‍♂',
    name: 'deaf man: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CF 1F3FF 200D 2642',
    html: '&#x1F9CF;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏‍♀️',
    name: 'deaf woman',
    shortname: ':deaf_woman:',
    unicode: '1F9CF 200D 2640 FE0F',
    html: '&#x1F9CF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏‍♀',
    name: 'deaf woman',
    shortname: ':deaf_woman:',
    unicode: '1F9CF 200D 2640',
    html: '&#x1F9CF;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏻‍♀️',
    name: 'deaf woman: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CF 1F3FB 200D 2640 FE0F',
    html: '&#x1F9CF;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏻‍♀',
    name: 'deaf woman: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CF 1F3FB 200D 2640',
    html: '&#x1F9CF;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏼‍♀️',
    name: 'deaf woman: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CF 1F3FC 200D 2640 FE0F',
    html: '&#x1F9CF;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏼‍♀',
    name: 'deaf woman: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CF 1F3FC 200D 2640',
    html: '&#x1F9CF;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏽‍♀️',
    name: 'deaf woman: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CF 1F3FD 200D 2640 FE0F',
    html: '&#x1F9CF;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏽‍♀',
    name: 'deaf woman: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CF 1F3FD 200D 2640',
    html: '&#x1F9CF;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏾‍♀️',
    name: 'deaf woman: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CF 1F3FE 200D 2640 FE0F',
    html: '&#x1F9CF;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏾‍♀',
    name: 'deaf woman: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CF 1F3FE 200D 2640',
    html: '&#x1F9CF;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏿‍♀️',
    name: 'deaf woman: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CF 1F3FF 200D 2640 FE0F',
    html: '&#x1F9CF;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧏🏿‍♀',
    name: 'deaf woman: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CF 1F3FF 200D 2640',
    html: '&#x1F9CF;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇‍♂️',
    name: 'man bowing',
    shortname: ':man_bowing:',
    unicode: '1F647 200D 2642 FE0F',
    html: '&#x1F647;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇‍♂',
    name: 'man bowing',
    shortname: ':man_bowing:',
    unicode: '1F647 200D 2642',
    html: '&#x1F647;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏻‍♂️',
    name: 'man bowing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F647 1F3FB 200D 2642 FE0F',
    html: '&#x1F647;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏻‍♂',
    name: 'man bowing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F647 1F3FB 200D 2642',
    html: '&#x1F647;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏼‍♂️',
    name: 'man bowing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F647 1F3FC 200D 2642 FE0F',
    html: '&#x1F647;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏼‍♂',
    name: 'man bowing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F647 1F3FC 200D 2642',
    html: '&#x1F647;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏽‍♂️',
    name: 'man bowing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F647 1F3FD 200D 2642 FE0F',
    html: '&#x1F647;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏽‍♂',
    name: 'man bowing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F647 1F3FD 200D 2642',
    html: '&#x1F647;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏾‍♂️',
    name: 'man bowing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F647 1F3FE 200D 2642 FE0F',
    html: '&#x1F647;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏾‍♂',
    name: 'man bowing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F647 1F3FE 200D 2642',
    html: '&#x1F647;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏿‍♂️',
    name: 'man bowing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F647 1F3FF 200D 2642 FE0F',
    html: '&#x1F647;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏿‍♂',
    name: 'man bowing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F647 1F3FF 200D 2642',
    html: '&#x1F647;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇‍♀️',
    name: 'woman bowing',
    shortname: ':woman_bowing:',
    unicode: '1F647 200D 2640 FE0F',
    html: '&#x1F647;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏻‍♀️',
    name: 'woman bowing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F647 1F3FB 200D 2640 FE0F',
    html: '&#x1F647;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏼‍♀️',
    name: 'woman bowing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F647 1F3FC 200D 2640 FE0F',
    html: '&#x1F647;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏽‍♀️',
    name: 'woman bowing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F647 1F3FD 200D 2640 FE0F',
    html: '&#x1F647;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏾‍♀️',
    name: 'woman bowing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F647 1F3FE 200D 2640 FE0F',
    html: '&#x1F647;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🙇🏿‍♀️',
    name: 'woman bowing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F647 1F3FF 200D 2640 FE0F',
    html: '&#x1F647;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦',
    name: 'person facepalming',
    shortname: ':person_facepalming:',
    unicode: '1F926',
    html: '&#x1F926;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏻',
    name: 'person facepalming: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F926 1F3FB',
    html: '&#x1F926;&#x1F3FB;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏼',
    name: 'person facepalming: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F926 1F3FC',
    html: '&#x1F926;&#x1F3FC;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏽',
    name: 'person facepalming: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F926 1F3FD',
    html: '&#x1F926;&#x1F3FD;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏾',
    name: 'person facepalming: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F926 1F3FE',
    html: '&#x1F926;&#x1F3FE;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏿',
    name: 'person facepalming: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F926 1F3FF',
    html: '&#x1F926;&#x1F3FF;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦‍♂️',
    name: 'man facepalming',
    shortname: ':man_facepalming:',
    unicode: '1F926 200D 2642 FE0F',
    html: '&#x1F926;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦‍♂',
    name: 'man facepalming',
    shortname: ':man_facepalming:',
    unicode: '1F926 200D 2642',
    html: '&#x1F926;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏻‍♂️',
    name: 'man facepalming: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F926 1F3FB 200D 2642 FE0F',
    html: '&#x1F926;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏻‍♂',
    name: 'man facepalming: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F926 1F3FB 200D 2642',
    html: '&#x1F926;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏼‍♂️',
    name: 'man facepalming: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F926 1F3FC 200D 2642 FE0F',
    html: '&#x1F926;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏼‍♂',
    name: 'man facepalming: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F926 1F3FC 200D 2642',
    html: '&#x1F926;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏽‍♂️',
    name: 'man facepalming: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F926 1F3FD 200D 2642 FE0F',
    html: '&#x1F926;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏽‍♂',
    name: 'man facepalming: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F926 1F3FD 200D 2642',
    html: '&#x1F926;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏾‍♂️',
    name: 'man facepalming: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F926 1F3FE 200D 2642 FE0F',
    html: '&#x1F926;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏾‍♂',
    name: 'man facepalming: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F926 1F3FE 200D 2642',
    html: '&#x1F926;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏿‍♂️',
    name: 'man facepalming: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F926 1F3FF 200D 2642 FE0F',
    html: '&#x1F926;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏿‍♂',
    name: 'man facepalming: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F926 1F3FF 200D 2642',
    html: '&#x1F926;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦‍♀️',
    name: 'woman facepalming',
    shortname: ':woman_facepalming:',
    unicode: '1F926 200D 2640 FE0F',
    html: '&#x1F926;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦‍♀',
    name: 'woman facepalming',
    shortname: ':woman_facepalming:',
    unicode: '1F926 200D 2640',
    html: '&#x1F926;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏻‍♀️',
    name: 'woman facepalming: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F926 1F3FB 200D 2640 FE0F',
    html: '&#x1F926;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏻‍♀',
    name: 'woman facepalming: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F926 1F3FB 200D 2640',
    html: '&#x1F926;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏼‍♀️',
    name: 'woman facepalming: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F926 1F3FC 200D 2640 FE0F',
    html: '&#x1F926;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏼‍♀',
    name: 'woman facepalming: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F926 1F3FC 200D 2640',
    html: '&#x1F926;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏽‍♀️',
    name: 'woman facepalming: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F926 1F3FD 200D 2640 FE0F',
    html: '&#x1F926;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏽‍♀',
    name: 'woman facepalming: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F926 1F3FD 200D 2640',
    html: '&#x1F926;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏾‍♀️',
    name: 'woman facepalming: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F926 1F3FE 200D 2640 FE0F',
    html: '&#x1F926;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏾‍♀',
    name: 'woman facepalming: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F926 1F3FE 200D 2640',
    html: '&#x1F926;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏿‍♀️',
    name: 'woman facepalming: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F926 1F3FF 200D 2640 FE0F',
    html: '&#x1F926;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤦🏿‍♀',
    name: 'woman facepalming: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F926 1F3FF 200D 2640',
    html: '&#x1F926;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷',
    name: 'person shrugging',
    shortname: ':person_shrugging:',
    unicode: '1F937',
    html: '&#x1F937;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏻',
    name: 'person shrugging: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F937 1F3FB',
    html: '&#x1F937;&#x1F3FB;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏼',
    name: 'person shrugging: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F937 1F3FC',
    html: '&#x1F937;&#x1F3FC;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏽',
    name: 'person shrugging: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F937 1F3FD',
    html: '&#x1F937;&#x1F3FD;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏾',
    name: 'person shrugging: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F937 1F3FE',
    html: '&#x1F937;&#x1F3FE;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏿',
    name: 'person shrugging: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F937 1F3FF',
    html: '&#x1F937;&#x1F3FF;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷‍♂️',
    name: 'man shrugging',
    shortname: ':man_shrugging:',
    unicode: '1F937 200D 2642 FE0F',
    html: '&#x1F937;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷‍♂',
    name: 'man shrugging',
    shortname: ':man_shrugging:',
    unicode: '1F937 200D 2642',
    html: '&#x1F937;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏻‍♂️',
    name: 'man shrugging: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F937 1F3FB 200D 2642 FE0F',
    html: '&#x1F937;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏻‍♂',
    name: 'man shrugging: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F937 1F3FB 200D 2642',
    html: '&#x1F937;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏼‍♂️',
    name: 'man shrugging: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F937 1F3FC 200D 2642 FE0F',
    html: '&#x1F937;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏼‍♂',
    name: 'man shrugging: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F937 1F3FC 200D 2642',
    html: '&#x1F937;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏽‍♂️',
    name: 'man shrugging: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F937 1F3FD 200D 2642 FE0F',
    html: '&#x1F937;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏽‍♂',
    name: 'man shrugging: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F937 1F3FD 200D 2642',
    html: '&#x1F937;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏾‍♂️',
    name: 'man shrugging: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F937 1F3FE 200D 2642 FE0F',
    html: '&#x1F937;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏾‍♂',
    name: 'man shrugging: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F937 1F3FE 200D 2642',
    html: '&#x1F937;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏿‍♂️',
    name: 'man shrugging: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F937 1F3FF 200D 2642 FE0F',
    html: '&#x1F937;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏿‍♂',
    name: 'man shrugging: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F937 1F3FF 200D 2642',
    html: '&#x1F937;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷‍♀️',
    name: 'woman shrugging',
    shortname: ':woman_shrugging:',
    unicode: '1F937 200D 2640 FE0F',
    html: '&#x1F937;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷‍♀',
    name: 'woman shrugging',
    shortname: ':woman_shrugging:',
    unicode: '1F937 200D 2640',
    html: '&#x1F937;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏻‍♀️',
    name: 'woman shrugging: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F937 1F3FB 200D 2640 FE0F',
    html: '&#x1F937;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏻‍♀',
    name: 'woman shrugging: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F937 1F3FB 200D 2640',
    html: '&#x1F937;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏼‍♀️',
    name: 'woman shrugging: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F937 1F3FC 200D 2640 FE0F',
    html: '&#x1F937;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏼‍♀',
    name: 'woman shrugging: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F937 1F3FC 200D 2640',
    html: '&#x1F937;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏽‍♀️',
    name: 'woman shrugging: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F937 1F3FD 200D 2640 FE0F',
    html: '&#x1F937;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏽‍♀',
    name: 'woman shrugging: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F937 1F3FD 200D 2640',
    html: '&#x1F937;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏾‍♀️',
    name: 'woman shrugging: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F937 1F3FE 200D 2640 FE0F',
    html: '&#x1F937;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏾‍♀',
    name: 'woman shrugging: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F937 1F3FE 200D 2640',
    html: '&#x1F937;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏿‍♀️',
    name: 'woman shrugging: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F937 1F3FF 200D 2640 FE0F',
    html: '&#x1F937;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🤷🏿‍♀',
    name: 'woman shrugging: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F937 1F3FF 200D 2640',
    html: '&#x1F937;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-gesture)',
    order: '',
  },
  {
    emoji: '🧑‍⚕️',
    name: 'health worker',
    shortname: ':health_worker:',
    unicode: '1F9D1 200D 2695 FE0F',
    html: '&#x1F9D1;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍⚕',
    name: 'health worker',
    shortname: ':health_worker:',
    unicode: '1F9D1 200D 2695',
    html: '&#x1F9D1;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍⚕️',
    name: 'health worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 2695 FE0F',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍⚕',
    name: 'health worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 2695',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍⚕️',
    name: 'health worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 2695 FE0F',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍⚕',
    name: 'health worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 2695',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍⚕️',
    name: 'health worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 2695 FE0F',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍⚕',
    name: 'health worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 2695',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍⚕️',
    name: 'health worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 2695 FE0F',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍⚕',
    name: 'health worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 2695',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍⚕️',
    name: 'health worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 2695 FE0F',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍⚕',
    name: 'health worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 2695',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍⚕️',
    name: 'man health worker',
    shortname: ':man_health_worker:',
    unicode: '1F468 200D 2695 FE0F',
    html: '&#x1F468;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍⚕',
    name: 'man health worker',
    shortname: ':man_health_worker:',
    unicode: '1F468 200D 2695',
    html: '&#x1F468;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍⚕️',
    name: 'man health worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 2695 FE0F',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍⚕',
    name: 'man health worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 2695',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍⚕️',
    name: 'man health worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 2695 FE0F',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍⚕',
    name: 'man health worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 2695',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍⚕️',
    name: 'man health worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 2695 FE0F',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍⚕',
    name: 'man health worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 2695',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍⚕️',
    name: 'man health worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 2695 FE0F',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍⚕',
    name: 'man health worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 2695',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍⚕️',
    name: 'man health worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 2695 FE0F',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍⚕',
    name: 'man health worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 2695',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍⚕️',
    name: 'woman health worker',
    shortname: ':woman_health_worker:',
    unicode: '1F469 200D 2695 FE0F',
    html: '&#x1F469;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍⚕',
    name: 'woman health worker',
    shortname: ':woman_health_worker:',
    unicode: '1F469 200D 2695',
    html: '&#x1F469;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍⚕️',
    name: 'woman health worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 2695 FE0F',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍⚕',
    name: 'woman health worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 2695',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍⚕️',
    name: 'woman health worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 2695 FE0F',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍⚕',
    name: 'woman health worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 2695',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍⚕️',
    name: 'woman health worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 2695 FE0F',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍⚕',
    name: 'woman health worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 2695',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍⚕️',
    name: 'woman health worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 2695 FE0F',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍⚕',
    name: 'woman health worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 2695',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍⚕️',
    name: 'woman health worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 2695 FE0F',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x2695;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍⚕',
    name: 'woman health worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 2695',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x2695;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍🎓',
    name: 'student',
    shortname: ':student:',
    unicode: '1F9D1 200D 1F393',
    html: '&#x1F9D1;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🎓',
    name: 'student: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F393',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🎓',
    name: 'student: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F393',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🎓',
    name: 'student: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F393',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🎓',
    name: 'student: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F393',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🎓',
    name: 'student: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F393',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍🎓',
    name: 'man student',
    shortname: ':man_student:',
    unicode: '1F468 200D 1F393',
    html: '&#x1F468;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍🎓',
    name: 'man student: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F393',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍🎓',
    name: 'man student: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F393',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍🎓',
    name: 'man student: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F393',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍🎓',
    name: 'man student: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F393',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍🎓',
    name: 'man student: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F393',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍🎓',
    name: 'woman student',
    shortname: ':woman_student:',
    unicode: '1F469 200D 1F393',
    html: '&#x1F469;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍🎓',
    name: 'woman student: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F393',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍🎓',
    name: 'woman student: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F393',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍🎓',
    name: 'woman student: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F393',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍🎓',
    name: 'woman student: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F393',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍🎓',
    name: 'woman student: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F393',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F393;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍🏫',
    name: 'teacher',
    shortname: ':teacher:',
    unicode: '1F9D1 200D 1F3EB',
    html: '&#x1F9D1;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🏫',
    name: 'teacher: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F3EB',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🏫',
    name: 'teacher: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F3EB',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🏫',
    name: 'teacher: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F3EB',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🏫',
    name: 'teacher: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F3EB',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🏫',
    name: 'teacher: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F3EB',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍🏫',
    name: 'man teacher',
    shortname: ':man_teacher:',
    unicode: '1F468 200D 1F3EB',
    html: '&#x1F468;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍🏫',
    name: 'man teacher: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F3EB',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍🏫',
    name: 'man teacher: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F3EB',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍🏫',
    name: 'man teacher: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F3EB',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍🏫',
    name: 'man teacher: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F3EB',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍🏫',
    name: 'man teacher: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F3EB',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍🏫',
    name: 'woman teacher',
    shortname: ':woman_teacher:',
    unicode: '1F469 200D 1F3EB',
    html: '&#x1F469;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍🏫',
    name: 'woman teacher: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F3EB',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍🏫',
    name: 'woman teacher: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F3EB',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍🏫',
    name: 'woman teacher: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F3EB',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍🏫',
    name: 'woman teacher: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F3EB',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍🏫',
    name: 'woman teacher: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F3EB',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F3EB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍⚖️',
    name: 'judge',
    shortname: ':judge:',
    unicode: '1F9D1 200D 2696 FE0F',
    html: '&#x1F9D1;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍⚖',
    name: 'judge',
    shortname: ':judge:',
    unicode: '1F9D1 200D 2696',
    html: '&#x1F9D1;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍⚖️',
    name: 'judge: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 2696 FE0F',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍⚖',
    name: 'judge: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 2696',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍⚖️',
    name: 'judge: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 2696 FE0F',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍⚖',
    name: 'judge: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 2696',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍⚖️',
    name: 'judge: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 2696 FE0F',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍⚖',
    name: 'judge: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 2696',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍⚖️',
    name: 'judge: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 2696 FE0F',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍⚖',
    name: 'judge: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 2696',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍⚖️',
    name: 'judge: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 2696 FE0F',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍⚖',
    name: 'judge: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 2696',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍⚖️',
    name: 'man judge',
    shortname: ':man_judge:',
    unicode: '1F468 200D 2696 FE0F',
    html: '&#x1F468;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍⚖',
    name: 'man judge',
    shortname: ':man_judge:',
    unicode: '1F468 200D 2696',
    html: '&#x1F468;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍⚖️',
    name: 'man judge: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 2696 FE0F',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍⚖',
    name: 'man judge: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 2696',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍⚖️',
    name: 'man judge: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 2696 FE0F',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍⚖',
    name: 'man judge: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 2696',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍⚖️',
    name: 'man judge: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 2696 FE0F',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍⚖',
    name: 'man judge: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 2696',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍⚖️',
    name: 'man judge: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 2696 FE0F',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍⚖',
    name: 'man judge: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 2696',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍⚖️',
    name: 'man judge: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 2696 FE0F',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍⚖',
    name: 'man judge: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 2696',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍⚖️',
    name: 'woman judge',
    shortname: ':woman_judge:',
    unicode: '1F469 200D 2696 FE0F',
    html: '&#x1F469;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍⚖',
    name: 'woman judge',
    shortname: ':woman_judge:',
    unicode: '1F469 200D 2696',
    html: '&#x1F469;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍⚖️',
    name: 'woman judge: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 2696 FE0F',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍⚖',
    name: 'woman judge: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 2696',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍⚖️',
    name: 'woman judge: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 2696 FE0F',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍⚖',
    name: 'woman judge: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 2696',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍⚖️',
    name: 'woman judge: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 2696 FE0F',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍⚖',
    name: 'woman judge: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 2696',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍⚖️',
    name: 'woman judge: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 2696 FE0F',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍⚖',
    name: 'woman judge: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 2696',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍⚖️',
    name: 'woman judge: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 2696 FE0F',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x2696;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍⚖',
    name: 'woman judge: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 2696',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x2696;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍🌾',
    name: 'farmer',
    shortname: ':farmer:',
    unicode: '1F9D1 200D 1F33E',
    html: '&#x1F9D1;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🌾',
    name: 'farmer: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F33E',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🌾',
    name: 'farmer: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F33E',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🌾',
    name: 'farmer: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F33E',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🌾',
    name: 'farmer: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F33E',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🌾',
    name: 'farmer: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F33E',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍🌾',
    name: 'man farmer',
    shortname: ':man_farmer:',
    unicode: '1F468 200D 1F33E',
    html: '&#x1F468;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍🌾',
    name: 'man farmer: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F33E',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍🌾',
    name: 'man farmer: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F33E',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍🌾',
    name: 'man farmer: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F33E',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍🌾',
    name: 'man farmer: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F33E',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍🌾',
    name: 'man farmer: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F33E',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍🌾',
    name: 'woman farmer',
    shortname: ':woman_farmer:',
    unicode: '1F469 200D 1F33E',
    html: '&#x1F469;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍🌾',
    name: 'woman farmer: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F33E',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍🌾',
    name: 'woman farmer: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F33E',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍🌾',
    name: 'woman farmer: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F33E',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍🌾',
    name: 'woman farmer: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F33E',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍🌾',
    name: 'woman farmer: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F33E',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F33E;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍🍳',
    name: 'cook',
    shortname: ':cook:',
    unicode: '1F9D1 200D 1F373',
    html: '&#x1F9D1;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🍳',
    name: 'cook: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F373',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🍳',
    name: 'cook: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F373',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🍳',
    name: 'cook: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F373',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🍳',
    name: 'cook: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F373',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🍳',
    name: 'cook: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F373',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍🍳',
    name: 'man cook',
    shortname: ':man_cook:',
    unicode: '1F468 200D 1F373',
    html: '&#x1F468;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍🍳',
    name: 'man cook: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F373',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍🍳',
    name: 'man cook: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F373',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍🍳',
    name: 'man cook: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F373',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍🍳',
    name: 'man cook: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F373',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍🍳',
    name: 'man cook: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F373',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍🍳',
    name: 'woman cook',
    shortname: ':woman_cook:',
    unicode: '1F469 200D 1F373',
    html: '&#x1F469;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍🍳',
    name: 'woman cook: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F373',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍🍳',
    name: 'woman cook: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F373',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍🍳',
    name: 'woman cook: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F373',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍🍳',
    name: 'woman cook: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F373',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍🍳',
    name: 'woman cook: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F373',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F373;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍🔧',
    name: 'mechanic',
    shortname: ':mechanic:',
    unicode: '1F9D1 200D 1F527',
    html: '&#x1F9D1;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🔧',
    name: 'mechanic: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F527',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🔧',
    name: 'mechanic: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F527',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🔧',
    name: 'mechanic: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F527',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🔧',
    name: 'mechanic: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F527',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🔧',
    name: 'mechanic: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F527',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍🔧',
    name: 'man mechanic',
    shortname: ':man_mechanic:',
    unicode: '1F468 200D 1F527',
    html: '&#x1F468;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍🔧',
    name: 'man mechanic: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F527',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍🔧',
    name: 'man mechanic: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F527',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍🔧',
    name: 'man mechanic: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F527',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍🔧',
    name: 'man mechanic: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F527',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍🔧',
    name: 'man mechanic: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F527',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍🔧',
    name: 'woman mechanic',
    shortname: ':woman_mechanic:',
    unicode: '1F469 200D 1F527',
    html: '&#x1F469;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍🔧',
    name: 'woman mechanic: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F527',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍🔧',
    name: 'woman mechanic: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F527',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍🔧',
    name: 'woman mechanic: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F527',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍🔧',
    name: 'woman mechanic: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F527',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍🔧',
    name: 'woman mechanic: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F527',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F527;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍🏭',
    name: 'factory worker',
    shortname: ':factory_worker:',
    unicode: '1F9D1 200D 1F3ED',
    html: '&#x1F9D1;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🏭',
    name: 'factory worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F3ED',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🏭',
    name: 'factory worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F3ED',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🏭',
    name: 'factory worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F3ED',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🏭',
    name: 'factory worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F3ED',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🏭',
    name: 'factory worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F3ED',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍🏭',
    name: 'man factory worker',
    shortname: ':man_factory_worker:',
    unicode: '1F468 200D 1F3ED',
    html: '&#x1F468;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍🏭',
    name: 'man factory worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F3ED',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍🏭',
    name: 'man factory worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F3ED',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍🏭',
    name: 'man factory worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F3ED',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍🏭',
    name: 'man factory worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F3ED',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍🏭',
    name: 'man factory worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F3ED',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍🏭',
    name: 'woman factory worker',
    shortname: ':woman_factory_worker:',
    unicode: '1F469 200D 1F3ED',
    html: '&#x1F469;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍🏭',
    name: 'woman factory worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F3ED',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍🏭',
    name: 'woman factory worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F3ED',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍🏭',
    name: 'woman factory worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F3ED',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍🏭',
    name: 'woman factory worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F3ED',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍🏭',
    name: 'woman factory worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F3ED',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F3ED;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍💼',
    name: 'office worker',
    shortname: ':office_worker:',
    unicode: '1F9D1 200D 1F4BC',
    html: '&#x1F9D1;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍💼',
    name: 'office worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F4BC',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍💼',
    name: 'office worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F4BC',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍💼',
    name: 'office worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F4BC',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍💼',
    name: 'office worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F4BC',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍💼',
    name: 'office worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F4BC',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍💼',
    name: 'man office worker',
    shortname: ':man_office_worker:',
    unicode: '1F468 200D 1F4BC',
    html: '&#x1F468;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍💼',
    name: 'man office worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F4BC',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍💼',
    name: 'man office worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F4BC',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍💼',
    name: 'man office worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F4BC',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍💼',
    name: 'man office worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F4BC',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍💼',
    name: 'man office worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F4BC',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍💼',
    name: 'woman office worker',
    shortname: ':woman_office_worker:',
    unicode: '1F469 200D 1F4BC',
    html: '&#x1F469;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍💼',
    name: 'woman office worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F4BC',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍💼',
    name: 'woman office worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F4BC',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍💼',
    name: 'woman office worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F4BC',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍💼',
    name: 'woman office worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F4BC',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍💼',
    name: 'woman office worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F4BC',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F4BC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍🔬',
    name: 'scientist',
    shortname: ':scientist:',
    unicode: '1F9D1 200D 1F52C',
    html: '&#x1F9D1;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🔬',
    name: 'scientist: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F52C',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🔬',
    name: 'scientist: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F52C',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🔬',
    name: 'scientist: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F52C',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🔬',
    name: 'scientist: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F52C',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🔬',
    name: 'scientist: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F52C',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍🔬',
    name: 'man scientist',
    shortname: ':man_scientist:',
    unicode: '1F468 200D 1F52C',
    html: '&#x1F468;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍🔬',
    name: 'man scientist: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F52C',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍🔬',
    name: 'man scientist: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F52C',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍🔬',
    name: 'man scientist: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F52C',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍🔬',
    name: 'man scientist: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F52C',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍🔬',
    name: 'man scientist: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F52C',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍🔬',
    name: 'woman scientist',
    shortname: ':woman_scientist:',
    unicode: '1F469 200D 1F52C',
    html: '&#x1F469;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍🔬',
    name: 'woman scientist: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F52C',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍🔬',
    name: 'woman scientist: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F52C',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍🔬',
    name: 'woman scientist: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F52C',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍🔬',
    name: 'woman scientist: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F52C',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍🔬',
    name: 'woman scientist: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F52C',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F52C;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍💻',
    name: 'technologist',
    shortname: ':technologist:',
    unicode: '1F9D1 200D 1F4BB',
    html: '&#x1F9D1;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍💻',
    name: 'technologist: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F4BB',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍💻',
    name: 'technologist: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F4BB',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍💻',
    name: 'technologist: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F4BB',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍💻',
    name: 'technologist: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F4BB',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍💻',
    name: 'technologist: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F4BB',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍💻',
    name: 'man technologist',
    shortname: ':man_technologist:',
    unicode: '1F468 200D 1F4BB',
    html: '&#x1F468;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍💻',
    name: 'man technologist: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F4BB',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍💻',
    name: 'man technologist: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F4BB',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍💻',
    name: 'man technologist: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F4BB',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍💻',
    name: 'man technologist: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F4BB',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍💻',
    name: 'man technologist: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F4BB',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍💻',
    name: 'woman technologist',
    shortname: ':woman_technologist:',
    unicode: '1F469 200D 1F4BB',
    html: '&#x1F469;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍💻',
    name: 'woman technologist: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F4BB',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍💻',
    name: 'woman technologist: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F4BB',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍💻',
    name: 'woman technologist: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F4BB',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍💻',
    name: 'woman technologist: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F4BB',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍💻',
    name: 'woman technologist: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F4BB',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F4BB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍🎤',
    name: 'singer',
    shortname: ':singer:',
    unicode: '1F9D1 200D 1F3A4',
    html: '&#x1F9D1;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🎤',
    name: 'singer: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F3A4',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🎤',
    name: 'singer: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F3A4',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🎤',
    name: 'singer: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F3A4',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🎤',
    name: 'singer: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F3A4',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🎤',
    name: 'singer: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F3A4',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍🎤',
    name: 'man singer',
    shortname: ':man_singer:',
    unicode: '1F468 200D 1F3A4',
    html: '&#x1F468;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍🎤',
    name: 'man singer: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F3A4',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍🎤',
    name: 'man singer: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F3A4',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍🎤',
    name: 'man singer: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F3A4',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍🎤',
    name: 'man singer: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F3A4',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍🎤',
    name: 'man singer: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F3A4',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍🎤',
    name: 'woman singer',
    shortname: ':woman_singer:',
    unicode: '1F469 200D 1F3A4',
    html: '&#x1F469;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍🎤',
    name: 'woman singer: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F3A4',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍🎤',
    name: 'woman singer: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F3A4',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍🎤',
    name: 'woman singer: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F3A4',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍🎤',
    name: 'woman singer: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F3A4',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍🎤',
    name: 'woman singer: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F3A4',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F3A4;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍🎨',
    name: 'artist',
    shortname: ':artist:',
    unicode: '1F9D1 200D 1F3A8',
    html: '&#x1F9D1;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🎨',
    name: 'artist: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F3A8',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🎨',
    name: 'artist: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F3A8',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🎨',
    name: 'artist: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F3A8',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🎨',
    name: 'artist: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F3A8',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🎨',
    name: 'artist: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F3A8',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍🎨',
    name: 'man artist',
    shortname: ':man_artist:',
    unicode: '1F468 200D 1F3A8',
    html: '&#x1F468;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍🎨',
    name: 'man artist: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F3A8',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍🎨',
    name: 'man artist: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F3A8',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍🎨',
    name: 'man artist: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F3A8',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍🎨',
    name: 'man artist: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F3A8',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍🎨',
    name: 'man artist: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F3A8',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍🎨',
    name: 'woman artist',
    shortname: ':woman_artist:',
    unicode: '1F469 200D 1F3A8',
    html: '&#x1F469;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍🎨',
    name: 'woman artist: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F3A8',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍🎨',
    name: 'woman artist: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F3A8',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍🎨',
    name: 'woman artist: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F3A8',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍🎨',
    name: 'woman artist: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F3A8',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍🎨',
    name: 'woman artist: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F3A8',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F3A8;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍✈️',
    name: 'pilot',
    shortname: ':pilot:',
    unicode: '1F9D1 200D 2708 FE0F',
    html: '&#x1F9D1;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍✈',
    name: 'pilot',
    shortname: ':pilot:',
    unicode: '1F9D1 200D 2708',
    html: '&#x1F9D1;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍✈️',
    name: 'pilot: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 2708 FE0F',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍✈',
    name: 'pilot: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 2708',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍✈️',
    name: 'pilot: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 2708 FE0F',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍✈',
    name: 'pilot: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 2708',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍✈️',
    name: 'pilot: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 2708 FE0F',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍✈',
    name: 'pilot: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 2708',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍✈️',
    name: 'pilot: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 2708 FE0F',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍✈',
    name: 'pilot: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 2708',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍✈️',
    name: 'pilot: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 2708 FE0F',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍✈',
    name: 'pilot: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 2708',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍✈️',
    name: 'man pilot',
    shortname: ':man_pilot:',
    unicode: '1F468 200D 2708 FE0F',
    html: '&#x1F468;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍✈',
    name: 'man pilot',
    shortname: ':man_pilot:',
    unicode: '1F468 200D 2708',
    html: '&#x1F468;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍✈️',
    name: 'man pilot: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 2708 FE0F',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍✈',
    name: 'man pilot: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 2708',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍✈️',
    name: 'man pilot: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 2708 FE0F',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍✈',
    name: 'man pilot: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 2708',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍✈️',
    name: 'man pilot: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 2708 FE0F',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍✈',
    name: 'man pilot: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 2708',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍✈️',
    name: 'man pilot: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 2708 FE0F',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍✈',
    name: 'man pilot: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 2708',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍✈️',
    name: 'man pilot: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 2708 FE0F',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍✈',
    name: 'man pilot: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 2708',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍✈️',
    name: 'woman pilot',
    shortname: ':woman_pilot:',
    unicode: '1F469 200D 2708 FE0F',
    html: '&#x1F469;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍✈',
    name: 'woman pilot',
    shortname: ':woman_pilot:',
    unicode: '1F469 200D 2708',
    html: '&#x1F469;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍✈️',
    name: 'woman pilot: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 2708 FE0F',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍✈',
    name: 'woman pilot: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 2708',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍✈️',
    name: 'woman pilot: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 2708 FE0F',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍✈',
    name: 'woman pilot: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 2708',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍✈️',
    name: 'woman pilot: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 2708 FE0F',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍✈',
    name: 'woman pilot: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 2708',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍✈️',
    name: 'woman pilot: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 2708 FE0F',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍✈',
    name: 'woman pilot: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 2708',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍✈️',
    name: 'woman pilot: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 2708 FE0F',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x2708;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍✈',
    name: 'woman pilot: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 2708',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x2708;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍🚀',
    name: 'astronaut',
    shortname: ':astronaut:',
    unicode: '1F9D1 200D 1F680',
    html: '&#x1F9D1;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🚀',
    name: 'astronaut: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F680',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🚀',
    name: 'astronaut: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F680',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🚀',
    name: 'astronaut: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F680',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🚀',
    name: 'astronaut: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F680',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🚀',
    name: 'astronaut: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F680',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍🚀',
    name: 'man astronaut',
    shortname: ':man_astronaut:',
    unicode: '1F468 200D 1F680',
    html: '&#x1F468;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍🚀',
    name: 'man astronaut: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F680',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍🚀',
    name: 'man astronaut: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F680',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍🚀',
    name: 'man astronaut: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F680',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍🚀',
    name: 'man astronaut: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F680',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍🚀',
    name: 'man astronaut: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F680',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍🚀',
    name: 'woman astronaut',
    shortname: ':woman_astronaut:',
    unicode: '1F469 200D 1F680',
    html: '&#x1F469;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍🚀',
    name: 'woman astronaut: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F680',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍🚀',
    name: 'woman astronaut: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F680',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍🚀',
    name: 'woman astronaut: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F680',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍🚀',
    name: 'woman astronaut: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F680',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍🚀',
    name: 'woman astronaut: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F680',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F680;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑‍🚒',
    name: 'firefighter',
    shortname: ':firefighter:',
    unicode: '1F9D1 200D 1F692',
    html: '&#x1F9D1;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🚒',
    name: 'firefighter: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F692',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🚒',
    name: 'firefighter: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F692',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🚒',
    name: 'firefighter: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F692',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🚒',
    name: 'firefighter: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F692',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🚒',
    name: 'firefighter: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F692',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨‍🚒',
    name: 'man firefighter',
    shortname: ':man_firefighter:',
    unicode: '1F468 200D 1F692',
    html: '&#x1F468;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏻‍🚒',
    name: 'man firefighter: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F692',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏼‍🚒',
    name: 'man firefighter: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F692',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏽‍🚒',
    name: 'man firefighter: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F692',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏾‍🚒',
    name: 'man firefighter: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F692',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👨🏿‍🚒',
    name: 'man firefighter: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F692',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩‍🚒',
    name: 'woman firefighter',
    shortname: ':woman_firefighter:',
    unicode: '1F469 200D 1F692',
    html: '&#x1F469;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏻‍🚒',
    name: 'woman firefighter: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F692',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏼‍🚒',
    name: 'woman firefighter: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F692',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏽‍🚒',
    name: 'woman firefighter: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F692',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏾‍🚒',
    name: 'woman firefighter: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F692',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👩🏿‍🚒',
    name: 'woman firefighter: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F692',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F692;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮‍♂️',
    name: 'man police officer',
    shortname: ':man_police_officer:',
    unicode: '1F46E 200D 2642 FE0F',
    html: '&#x1F46E;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮‍♂',
    name: 'man police officer',
    shortname: ':man_police_officer:',
    unicode: '1F46E 200D 2642',
    html: '&#x1F46E;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏻‍♂️',
    name: 'man police officer: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F46E 1F3FB 200D 2642 FE0F',
    html: '&#x1F46E;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏻‍♂',
    name: 'man police officer: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F46E 1F3FB 200D 2642',
    html: '&#x1F46E;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏼‍♂️',
    name: 'man police officer: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F46E 1F3FC 200D 2642 FE0F',
    html: '&#x1F46E;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏼‍♂',
    name: 'man police officer: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F46E 1F3FC 200D 2642',
    html: '&#x1F46E;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏽‍♂️',
    name: 'man police officer: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F46E 1F3FD 200D 2642 FE0F',
    html: '&#x1F46E;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏽‍♂',
    name: 'man police officer: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F46E 1F3FD 200D 2642',
    html: '&#x1F46E;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏾‍♂️',
    name: 'man police officer: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F46E 1F3FE 200D 2642 FE0F',
    html: '&#x1F46E;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏾‍♂',
    name: 'man police officer: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F46E 1F3FE 200D 2642',
    html: '&#x1F46E;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏿‍♂️',
    name: 'man police officer: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F46E 1F3FF 200D 2642 FE0F',
    html: '&#x1F46E;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏿‍♂',
    name: 'man police officer: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F46E 1F3FF 200D 2642',
    html: '&#x1F46E;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮‍♀️',
    name: 'woman police officer',
    shortname: ':woman_police_officer:',
    unicode: '1F46E 200D 2640 FE0F',
    html: '&#x1F46E;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏻‍♀️',
    name: 'woman police officer: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F46E 1F3FB 200D 2640 FE0F',
    html: '&#x1F46E;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏼‍♀️',
    name: 'woman police officer: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F46E 1F3FC 200D 2640 FE0F',
    html: '&#x1F46E;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏽‍♀️',
    name: 'woman police officer: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F46E 1F3FD 200D 2640 FE0F',
    html: '&#x1F46E;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏾‍♀️',
    name: 'woman police officer: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F46E 1F3FE 200D 2640 FE0F',
    html: '&#x1F46E;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👮🏿‍♀️',
    name: 'woman police officer: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F46E 1F3FF 200D 2640 FE0F',
    html: '&#x1F46E;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵️',
    name: 'detective',
    shortname: ':detective:',
    unicode: '1F575 FE0F',
    html: '&#x1F575;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵️‍♂️',
    name: 'man detective',
    shortname: ':man_detective:',
    unicode: '1F575 FE0F 200D 2642 FE0F',
    html: '&#x1F575;&#xFE0F;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵‍♂️',
    name: 'man detective',
    shortname: ':man_detective:',
    unicode: '1F575 200D 2642 FE0F',
    html: '&#x1F575;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵️‍♂',
    name: 'man detective',
    shortname: ':man_detective:',
    unicode: '1F575 FE0F 200D 2642',
    html: '&#x1F575;&#xFE0F;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵‍♂',
    name: 'man detective',
    shortname: ':man_detective:',
    unicode: '1F575 200D 2642',
    html: '&#x1F575;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏻‍♂️',
    name: 'man detective: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F575 1F3FB 200D 2642 FE0F',
    html: '&#x1F575;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏻‍♂',
    name: 'man detective: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F575 1F3FB 200D 2642',
    html: '&#x1F575;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏼‍♂️',
    name: 'man detective: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F575 1F3FC 200D 2642 FE0F',
    html: '&#x1F575;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏼‍♂',
    name: 'man detective: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F575 1F3FC 200D 2642',
    html: '&#x1F575;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏽‍♂️',
    name: 'man detective: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F575 1F3FD 200D 2642 FE0F',
    html: '&#x1F575;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏽‍♂',
    name: 'man detective: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F575 1F3FD 200D 2642',
    html: '&#x1F575;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏾‍♂️',
    name: 'man detective: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F575 1F3FE 200D 2642 FE0F',
    html: '&#x1F575;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏾‍♂',
    name: 'man detective: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F575 1F3FE 200D 2642',
    html: '&#x1F575;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏿‍♂️',
    name: 'man detective: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F575 1F3FF 200D 2642 FE0F',
    html: '&#x1F575;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏿‍♂',
    name: 'man detective: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F575 1F3FF 200D 2642',
    html: '&#x1F575;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵️‍♀️',
    name: 'woman detective',
    shortname: ':woman_detective:',
    unicode: '1F575 FE0F 200D 2640 FE0F',
    html: '&#x1F575;&#xFE0F;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵‍♀️',
    name: 'woman detective',
    shortname: ':woman_detective:',
    unicode: '1F575 200D 2640 FE0F',
    html: '&#x1F575;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵️‍♀',
    name: 'woman detective',
    shortname: ':woman_detective:',
    unicode: '1F575 FE0F 200D 2640',
    html: '&#x1F575;&#xFE0F;&#x200D;&#x2640;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏻‍♀️',
    name: 'woman detective: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F575 1F3FB 200D 2640 FE0F',
    html: '&#x1F575;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏼‍♀️',
    name: 'woman detective: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F575 1F3FC 200D 2640 FE0F',
    html: '&#x1F575;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏽‍♀️',
    name: 'woman detective: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F575 1F3FD 200D 2640 FE0F',
    html: '&#x1F575;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏾‍♀️',
    name: 'woman detective: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F575 1F3FE 200D 2640 FE0F',
    html: '&#x1F575;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🕵🏿‍♀️',
    name: 'woman detective: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F575 1F3FF 200D 2640 FE0F',
    html: '&#x1F575;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂‍♂️',
    name: 'man guard',
    shortname: ':man_guard:',
    unicode: '1F482 200D 2642 FE0F',
    html: '&#x1F482;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂‍♂',
    name: 'man guard',
    shortname: ':man_guard:',
    unicode: '1F482 200D 2642',
    html: '&#x1F482;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏻‍♂️',
    name: 'man guard: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F482 1F3FB 200D 2642 FE0F',
    html: '&#x1F482;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏻‍♂',
    name: 'man guard: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F482 1F3FB 200D 2642',
    html: '&#x1F482;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏼‍♂️',
    name: 'man guard: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F482 1F3FC 200D 2642 FE0F',
    html: '&#x1F482;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏼‍♂',
    name: 'man guard: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F482 1F3FC 200D 2642',
    html: '&#x1F482;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏽‍♂️',
    name: 'man guard: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F482 1F3FD 200D 2642 FE0F',
    html: '&#x1F482;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏽‍♂',
    name: 'man guard: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F482 1F3FD 200D 2642',
    html: '&#x1F482;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏾‍♂️',
    name: 'man guard: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F482 1F3FE 200D 2642 FE0F',
    html: '&#x1F482;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏾‍♂',
    name: 'man guard: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F482 1F3FE 200D 2642',
    html: '&#x1F482;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏿‍♂️',
    name: 'man guard: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F482 1F3FF 200D 2642 FE0F',
    html: '&#x1F482;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏿‍♂',
    name: 'man guard: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F482 1F3FF 200D 2642',
    html: '&#x1F482;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂‍♀️',
    name: 'woman guard',
    shortname: ':woman_guard:',
    unicode: '1F482 200D 2640 FE0F',
    html: '&#x1F482;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏻‍♀️',
    name: 'woman guard: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F482 1F3FB 200D 2640 FE0F',
    html: '&#x1F482;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏼‍♀️',
    name: 'woman guard: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F482 1F3FC 200D 2640 FE0F',
    html: '&#x1F482;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏽‍♀️',
    name: 'woman guard: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F482 1F3FD 200D 2640 FE0F',
    html: '&#x1F482;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏾‍♀️',
    name: 'woman guard: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F482 1F3FE 200D 2640 FE0F',
    html: '&#x1F482;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '💂🏿‍♀️',
    name: 'woman guard: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F482 1F3FF 200D 2640 FE0F',
    html: '&#x1F482;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷‍♂️',
    name: 'man construction worker',
    shortname: ':man_construction_worker:',
    unicode: '1F477 200D 2642 FE0F',
    html: '&#x1F477;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷‍♂',
    name: 'man construction worker',
    shortname: ':man_construction_worker:',
    unicode: '1F477 200D 2642',
    html: '&#x1F477;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏻‍♂️',
    name: 'man construction worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F477 1F3FB 200D 2642 FE0F',
    html: '&#x1F477;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏻‍♂',
    name: 'man construction worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F477 1F3FB 200D 2642',
    html: '&#x1F477;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏼‍♂️',
    name: 'man construction worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F477 1F3FC 200D 2642 FE0F',
    html: '&#x1F477;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏼‍♂',
    name: 'man construction worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F477 1F3FC 200D 2642',
    html: '&#x1F477;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏽‍♂️',
    name: 'man construction worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F477 1F3FD 200D 2642 FE0F',
    html: '&#x1F477;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏽‍♂',
    name: 'man construction worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F477 1F3FD 200D 2642',
    html: '&#x1F477;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏾‍♂️',
    name: 'man construction worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F477 1F3FE 200D 2642 FE0F',
    html: '&#x1F477;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏾‍♂',
    name: 'man construction worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F477 1F3FE 200D 2642',
    html: '&#x1F477;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏿‍♂️',
    name: 'man construction worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F477 1F3FF 200D 2642 FE0F',
    html: '&#x1F477;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏿‍♂',
    name: 'man construction worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F477 1F3FF 200D 2642',
    html: '&#x1F477;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷‍♀️',
    name: 'woman construction worker',
    shortname: ':woman_construction_worker:',
    unicode: '1F477 200D 2640 FE0F',
    html: '&#x1F477;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏻‍♀️',
    name: 'woman construction worker: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F477 1F3FB 200D 2640 FE0F',
    html: '&#x1F477;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏼‍♀️',
    name: 'woman construction worker: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F477 1F3FC 200D 2640 FE0F',
    html: '&#x1F477;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏽‍♀️',
    name: 'woman construction worker: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F477 1F3FD 200D 2640 FE0F',
    html: '&#x1F477;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏾‍♀️',
    name: 'woman construction worker: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F477 1F3FE 200D 2640 FE0F',
    html: '&#x1F477;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👷🏿‍♀️',
    name: 'woman construction worker: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F477 1F3FF 200D 2640 FE0F',
    html: '&#x1F477;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤴',
    name: 'prince',
    shortname: ':prince:',
    unicode: '1F934',
    html: '&#x1F934;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤴🏻',
    name: 'prince: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F934 1F3FB',
    html: '&#x1F934;&#x1F3FB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤴🏼',
    name: 'prince: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F934 1F3FC',
    html: '&#x1F934;&#x1F3FC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤴🏽',
    name: 'prince: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F934 1F3FD',
    html: '&#x1F934;&#x1F3FD;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤴🏾',
    name: 'prince: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F934 1F3FE',
    html: '&#x1F934;&#x1F3FE;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤴🏿',
    name: 'prince: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F934 1F3FF',
    html: '&#x1F934;&#x1F3FF;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳‍♂️',
    name: 'man wearing turban',
    shortname: ':man_wearing_turban:',
    unicode: '1F473 200D 2642 FE0F',
    html: '&#x1F473;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳‍♂',
    name: 'man wearing turban',
    shortname: ':man_wearing_turban:',
    unicode: '1F473 200D 2642',
    html: '&#x1F473;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏻‍♂️',
    name: 'man wearing turban: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F473 1F3FB 200D 2642 FE0F',
    html: '&#x1F473;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏻‍♂',
    name: 'man wearing turban: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F473 1F3FB 200D 2642',
    html: '&#x1F473;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏼‍♂️',
    name: 'man wearing turban: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F473 1F3FC 200D 2642 FE0F',
    html: '&#x1F473;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏼‍♂',
    name: 'man wearing turban: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F473 1F3FC 200D 2642',
    html: '&#x1F473;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏽‍♂️',
    name: 'man wearing turban: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F473 1F3FD 200D 2642 FE0F',
    html: '&#x1F473;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏽‍♂',
    name: 'man wearing turban: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F473 1F3FD 200D 2642',
    html: '&#x1F473;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏾‍♂️',
    name: 'man wearing turban: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F473 1F3FE 200D 2642 FE0F',
    html: '&#x1F473;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏾‍♂',
    name: 'man wearing turban: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F473 1F3FE 200D 2642',
    html: '&#x1F473;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏿‍♂️',
    name: 'man wearing turban: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F473 1F3FF 200D 2642 FE0F',
    html: '&#x1F473;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏿‍♂',
    name: 'man wearing turban: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F473 1F3FF 200D 2642',
    html: '&#x1F473;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳‍♀️',
    name: 'woman wearing turban',
    shortname: ':woman_wearing_turban:',
    unicode: '1F473 200D 2640 FE0F',
    html: '&#x1F473;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏻‍♀️',
    name: 'woman wearing turban: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F473 1F3FB 200D 2640 FE0F',
    html: '&#x1F473;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏼‍♀️',
    name: 'woman wearing turban: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F473 1F3FC 200D 2640 FE0F',
    html: '&#x1F473;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏽‍♀️',
    name: 'woman wearing turban: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F473 1F3FD 200D 2640 FE0F',
    html: '&#x1F473;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏾‍♀️',
    name: 'woman wearing turban: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F473 1F3FE 200D 2640 FE0F',
    html: '&#x1F473;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '👳🏿‍♀️',
    name: 'woman wearing turban: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F473 1F3FF 200D 2640 FE0F',
    html: '&#x1F473;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧕',
    name: 'woman with headscarf',
    shortname: ':woman_with_headscarf:',
    unicode: '1F9D5',
    html: '&#x1F9D5;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧕🏻',
    name: 'woman with headscarf: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D5 1F3FB',
    html: '&#x1F9D5;&#x1F3FB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧕🏼',
    name: 'woman with headscarf: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D5 1F3FC',
    html: '&#x1F9D5;&#x1F3FC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧕🏽',
    name: 'woman with headscarf: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D5 1F3FD',
    html: '&#x1F9D5;&#x1F3FD;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧕🏾',
    name: 'woman with headscarf: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D5 1F3FE',
    html: '&#x1F9D5;&#x1F3FE;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🧕🏿',
    name: 'woman with headscarf: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D5 1F3FF',
    html: '&#x1F9D5;&#x1F3FF;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤵',
    name: 'man in tuxedo',
    shortname: ':man_in_tuxedo:',
    unicode: '1F935',
    html: '&#x1F935;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤵🏻',
    name: 'man in tuxedo: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F935 1F3FB',
    html: '&#x1F935;&#x1F3FB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤵🏼',
    name: 'man in tuxedo: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F935 1F3FC',
    html: '&#x1F935;&#x1F3FC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤵🏽',
    name: 'man in tuxedo: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F935 1F3FD',
    html: '&#x1F935;&#x1F3FD;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤵🏾',
    name: 'man in tuxedo: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F935 1F3FE',
    html: '&#x1F935;&#x1F3FE;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤵🏿',
    name: 'man in tuxedo: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F935 1F3FF',
    html: '&#x1F935;&#x1F3FF;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤰',
    name: 'pregnant woman',
    shortname: ':pregnant_woman:',
    unicode: '1F930',
    html: '&#x1F930;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤰🏻',
    name: 'pregnant woman: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F930 1F3FB',
    html: '&#x1F930;&#x1F3FB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤰🏼',
    name: 'pregnant woman: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F930 1F3FC',
    html: '&#x1F930;&#x1F3FC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤰🏽',
    name: 'pregnant woman: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F930 1F3FD',
    html: '&#x1F930;&#x1F3FD;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤰🏾',
    name: 'pregnant woman: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F930 1F3FE',
    html: '&#x1F930;&#x1F3FE;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤰🏿',
    name: 'pregnant woman: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F930 1F3FF',
    html: '&#x1F930;&#x1F3FF;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤱',
    name: 'breast-feeding',
    shortname: ':breastfeeding:',
    unicode: '1F931',
    html: '&#x1F931;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤱🏻',
    name: 'breast-feeding: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F931 1F3FB',
    html: '&#x1F931;&#x1F3FB;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤱🏼',
    name: 'breast-feeding: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F931 1F3FC',
    html: '&#x1F931;&#x1F3FC;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤱🏽',
    name: 'breast-feeding: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F931 1F3FD',
    html: '&#x1F931;&#x1F3FD;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤱🏾',
    name: 'breast-feeding: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F931 1F3FE',
    html: '&#x1F931;&#x1F3FE;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤱🏿',
    name: 'breast-feeding: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F931 1F3FF',
    html: '&#x1F931;&#x1F3FF;',
    category: 'People & Body (person-role)',
    order: '',
  },
  {
    emoji: '🤶',
    name: 'Mrs. Claus',
    shortname: ':Mrs_Claus:',
    unicode: '1F936',
    html: '&#x1F936;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🤶🏻',
    name: 'Mrs. Claus: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F936 1F3FB',
    html: '&#x1F936;&#x1F3FB;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🤶🏼',
    name: 'Mrs. Claus: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F936 1F3FC',
    html: '&#x1F936;&#x1F3FC;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🤶🏽',
    name: 'Mrs. Claus: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F936 1F3FD',
    html: '&#x1F936;&#x1F3FD;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🤶🏾',
    name: 'Mrs. Claus: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F936 1F3FE',
    html: '&#x1F936;&#x1F3FE;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🤶🏿',
    name: 'Mrs. Claus: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F936 1F3FF',
    html: '&#x1F936;&#x1F3FF;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸',
    name: 'superhero',
    shortname: ':superhero:',
    unicode: '1F9B8',
    html: '&#x1F9B8;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏻',
    name: 'superhero: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9B8 1F3FB',
    html: '&#x1F9B8;&#x1F3FB;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏼',
    name: 'superhero: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9B8 1F3FC',
    html: '&#x1F9B8;&#x1F3FC;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏽',
    name: 'superhero: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9B8 1F3FD',
    html: '&#x1F9B8;&#x1F3FD;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏾',
    name: 'superhero: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9B8 1F3FE',
    html: '&#x1F9B8;&#x1F3FE;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏿',
    name: 'superhero: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9B8 1F3FF',
    html: '&#x1F9B8;&#x1F3FF;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸‍♂️',
    name: 'man superhero',
    shortname: ':man_superhero:',
    unicode: '1F9B8 200D 2642 FE0F',
    html: '&#x1F9B8;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸‍♂',
    name: 'man superhero',
    shortname: ':man_superhero:',
    unicode: '1F9B8 200D 2642',
    html: '&#x1F9B8;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏻‍♂️',
    name: 'man superhero: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9B8 1F3FB 200D 2642 FE0F',
    html: '&#x1F9B8;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏻‍♂',
    name: 'man superhero: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9B8 1F3FB 200D 2642',
    html: '&#x1F9B8;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏼‍♂️',
    name: 'man superhero: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9B8 1F3FC 200D 2642 FE0F',
    html: '&#x1F9B8;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏼‍♂',
    name: 'man superhero: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9B8 1F3FC 200D 2642',
    html: '&#x1F9B8;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏽‍♂️',
    name: 'man superhero: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9B8 1F3FD 200D 2642 FE0F',
    html: '&#x1F9B8;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏽‍♂',
    name: 'man superhero: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9B8 1F3FD 200D 2642',
    html: '&#x1F9B8;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏾‍♂️',
    name: 'man superhero: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9B8 1F3FE 200D 2642 FE0F',
    html: '&#x1F9B8;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏾‍♂',
    name: 'man superhero: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9B8 1F3FE 200D 2642',
    html: '&#x1F9B8;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏿‍♂️',
    name: 'man superhero: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9B8 1F3FF 200D 2642 FE0F',
    html: '&#x1F9B8;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏿‍♂',
    name: 'man superhero: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9B8 1F3FF 200D 2642',
    html: '&#x1F9B8;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸‍♀️',
    name: 'woman superhero',
    shortname: ':woman_superhero:',
    unicode: '1F9B8 200D 2640 FE0F',
    html: '&#x1F9B8;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸‍♀',
    name: 'woman superhero',
    shortname: ':woman_superhero:',
    unicode: '1F9B8 200D 2640',
    html: '&#x1F9B8;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏻‍♀️',
    name: 'woman superhero: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9B8 1F3FB 200D 2640 FE0F',
    html: '&#x1F9B8;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏻‍♀',
    name: 'woman superhero: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9B8 1F3FB 200D 2640',
    html: '&#x1F9B8;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏼‍♀️',
    name: 'woman superhero: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9B8 1F3FC 200D 2640 FE0F',
    html: '&#x1F9B8;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏼‍♀',
    name: 'woman superhero: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9B8 1F3FC 200D 2640',
    html: '&#x1F9B8;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏽‍♀️',
    name: 'woman superhero: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9B8 1F3FD 200D 2640 FE0F',
    html: '&#x1F9B8;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏽‍♀',
    name: 'woman superhero: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9B8 1F3FD 200D 2640',
    html: '&#x1F9B8;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏾‍♀️',
    name: 'woman superhero: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9B8 1F3FE 200D 2640 FE0F',
    html: '&#x1F9B8;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏾‍♀',
    name: 'woman superhero: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9B8 1F3FE 200D 2640',
    html: '&#x1F9B8;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏿‍♀️',
    name: 'woman superhero: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9B8 1F3FF 200D 2640 FE0F',
    html: '&#x1F9B8;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦸🏿‍♀',
    name: 'woman superhero: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9B8 1F3FF 200D 2640',
    html: '&#x1F9B8;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹',
    name: 'supervillain',
    shortname: ':supervillain:',
    unicode: '1F9B9',
    html: '&#x1F9B9;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏻',
    name: 'supervillain: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9B9 1F3FB',
    html: '&#x1F9B9;&#x1F3FB;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏼',
    name: 'supervillain: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9B9 1F3FC',
    html: '&#x1F9B9;&#x1F3FC;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏽',
    name: 'supervillain: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9B9 1F3FD',
    html: '&#x1F9B9;&#x1F3FD;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏾',
    name: 'supervillain: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9B9 1F3FE',
    html: '&#x1F9B9;&#x1F3FE;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏿',
    name: 'supervillain: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9B9 1F3FF',
    html: '&#x1F9B9;&#x1F3FF;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹‍♂️',
    name: 'man supervillain',
    shortname: ':man_supervillain:',
    unicode: '1F9B9 200D 2642 FE0F',
    html: '&#x1F9B9;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹‍♂',
    name: 'man supervillain',
    shortname: ':man_supervillain:',
    unicode: '1F9B9 200D 2642',
    html: '&#x1F9B9;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏻‍♂️',
    name: 'man supervillain: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9B9 1F3FB 200D 2642 FE0F',
    html: '&#x1F9B9;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏻‍♂',
    name: 'man supervillain: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9B9 1F3FB 200D 2642',
    html: '&#x1F9B9;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏼‍♂️',
    name: 'man supervillain: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9B9 1F3FC 200D 2642 FE0F',
    html: '&#x1F9B9;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏼‍♂',
    name: 'man supervillain: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9B9 1F3FC 200D 2642',
    html: '&#x1F9B9;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏽‍♂️',
    name: 'man supervillain: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9B9 1F3FD 200D 2642 FE0F',
    html: '&#x1F9B9;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏽‍♂',
    name: 'man supervillain: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9B9 1F3FD 200D 2642',
    html: '&#x1F9B9;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏾‍♂️',
    name: 'man supervillain: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9B9 1F3FE 200D 2642 FE0F',
    html: '&#x1F9B9;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏾‍♂',
    name: 'man supervillain: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9B9 1F3FE 200D 2642',
    html: '&#x1F9B9;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏿‍♂️',
    name: 'man supervillain: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9B9 1F3FF 200D 2642 FE0F',
    html: '&#x1F9B9;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏿‍♂',
    name: 'man supervillain: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9B9 1F3FF 200D 2642',
    html: '&#x1F9B9;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹‍♀️',
    name: 'woman supervillain',
    shortname: ':woman_supervillain:',
    unicode: '1F9B9 200D 2640 FE0F',
    html: '&#x1F9B9;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹‍♀',
    name: 'woman supervillain',
    shortname: ':woman_supervillain:',
    unicode: '1F9B9 200D 2640',
    html: '&#x1F9B9;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏻‍♀️',
    name: 'woman supervillain: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9B9 1F3FB 200D 2640 FE0F',
    html: '&#x1F9B9;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏻‍♀',
    name: 'woman supervillain: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9B9 1F3FB 200D 2640',
    html: '&#x1F9B9;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏼‍♀️',
    name: 'woman supervillain: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9B9 1F3FC 200D 2640 FE0F',
    html: '&#x1F9B9;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏼‍♀',
    name: 'woman supervillain: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9B9 1F3FC 200D 2640',
    html: '&#x1F9B9;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏽‍♀️',
    name: 'woman supervillain: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9B9 1F3FD 200D 2640 FE0F',
    html: '&#x1F9B9;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏽‍♀',
    name: 'woman supervillain: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9B9 1F3FD 200D 2640',
    html: '&#x1F9B9;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏾‍♀️',
    name: 'woman supervillain: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9B9 1F3FE 200D 2640 FE0F',
    html: '&#x1F9B9;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏾‍♀',
    name: 'woman supervillain: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9B9 1F3FE 200D 2640',
    html: '&#x1F9B9;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏿‍♀️',
    name: 'woman supervillain: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9B9 1F3FF 200D 2640 FE0F',
    html: '&#x1F9B9;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🦹🏿‍♀',
    name: 'woman supervillain: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9B9 1F3FF 200D 2640',
    html: '&#x1F9B9;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙',
    name: 'mage',
    shortname: ':mage:',
    unicode: '1F9D9',
    html: '&#x1F9D9;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏻',
    name: 'mage: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D9 1F3FB',
    html: '&#x1F9D9;&#x1F3FB;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏼',
    name: 'mage: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D9 1F3FC',
    html: '&#x1F9D9;&#x1F3FC;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏽',
    name: 'mage: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D9 1F3FD',
    html: '&#x1F9D9;&#x1F3FD;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏾',
    name: 'mage: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D9 1F3FE',
    html: '&#x1F9D9;&#x1F3FE;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏿',
    name: 'mage: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D9 1F3FF',
    html: '&#x1F9D9;&#x1F3FF;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙‍♂️',
    name: 'man mage',
    shortname: ':man_mage:',
    unicode: '1F9D9 200D 2642 FE0F',
    html: '&#x1F9D9;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙‍♂',
    name: 'man mage',
    shortname: ':man_mage:',
    unicode: '1F9D9 200D 2642',
    html: '&#x1F9D9;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏻‍♂️',
    name: 'man mage: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D9 1F3FB 200D 2642 FE0F',
    html: '&#x1F9D9;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏻‍♂',
    name: 'man mage: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D9 1F3FB 200D 2642',
    html: '&#x1F9D9;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏼‍♂️',
    name: 'man mage: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D9 1F3FC 200D 2642 FE0F',
    html: '&#x1F9D9;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏼‍♂',
    name: 'man mage: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D9 1F3FC 200D 2642',
    html: '&#x1F9D9;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏽‍♂️',
    name: 'man mage: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D9 1F3FD 200D 2642 FE0F',
    html: '&#x1F9D9;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏽‍♂',
    name: 'man mage: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D9 1F3FD 200D 2642',
    html: '&#x1F9D9;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏾‍♂️',
    name: 'man mage: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D9 1F3FE 200D 2642 FE0F',
    html: '&#x1F9D9;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏾‍♂',
    name: 'man mage: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D9 1F3FE 200D 2642',
    html: '&#x1F9D9;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏿‍♂️',
    name: 'man mage: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D9 1F3FF 200D 2642 FE0F',
    html: '&#x1F9D9;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏿‍♂',
    name: 'man mage: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D9 1F3FF 200D 2642',
    html: '&#x1F9D9;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙‍♀️',
    name: 'woman mage',
    shortname: ':woman_mage:',
    unicode: '1F9D9 200D 2640 FE0F',
    html: '&#x1F9D9;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙‍♀',
    name: 'woman mage',
    shortname: ':woman_mage:',
    unicode: '1F9D9 200D 2640',
    html: '&#x1F9D9;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏻‍♀️',
    name: 'woman mage: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D9 1F3FB 200D 2640 FE0F',
    html: '&#x1F9D9;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏻‍♀',
    name: 'woman mage: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D9 1F3FB 200D 2640',
    html: '&#x1F9D9;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏼‍♀️',
    name: 'woman mage: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D9 1F3FC 200D 2640 FE0F',
    html: '&#x1F9D9;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏼‍♀',
    name: 'woman mage: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D9 1F3FC 200D 2640',
    html: '&#x1F9D9;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏽‍♀️',
    name: 'woman mage: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D9 1F3FD 200D 2640 FE0F',
    html: '&#x1F9D9;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏽‍♀',
    name: 'woman mage: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D9 1F3FD 200D 2640',
    html: '&#x1F9D9;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏾‍♀️',
    name: 'woman mage: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D9 1F3FE 200D 2640 FE0F',
    html: '&#x1F9D9;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏾‍♀',
    name: 'woman mage: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D9 1F3FE 200D 2640',
    html: '&#x1F9D9;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏿‍♀️',
    name: 'woman mage: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D9 1F3FF 200D 2640 FE0F',
    html: '&#x1F9D9;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧙🏿‍♀',
    name: 'woman mage: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D9 1F3FF 200D 2640',
    html: '&#x1F9D9;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚',
    name: 'fairy',
    shortname: ':fairy:',
    unicode: '1F9DA',
    html: '&#x1F9DA;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏻',
    name: 'fairy: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DA 1F3FB',
    html: '&#x1F9DA;&#x1F3FB;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏼',
    name: 'fairy: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DA 1F3FC',
    html: '&#x1F9DA;&#x1F3FC;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏽',
    name: 'fairy: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DA 1F3FD',
    html: '&#x1F9DA;&#x1F3FD;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏾',
    name: 'fairy: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DA 1F3FE',
    html: '&#x1F9DA;&#x1F3FE;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏿',
    name: 'fairy: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DA 1F3FF',
    html: '&#x1F9DA;&#x1F3FF;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚‍♂️',
    name: 'man fairy',
    shortname: ':man_fairy:',
    unicode: '1F9DA 200D 2642 FE0F',
    html: '&#x1F9DA;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚‍♂',
    name: 'man fairy',
    shortname: ':man_fairy:',
    unicode: '1F9DA 200D 2642',
    html: '&#x1F9DA;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏻‍♂️',
    name: 'man fairy: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DA 1F3FB 200D 2642 FE0F',
    html: '&#x1F9DA;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏻‍♂',
    name: 'man fairy: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DA 1F3FB 200D 2642',
    html: '&#x1F9DA;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏼‍♂️',
    name: 'man fairy: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DA 1F3FC 200D 2642 FE0F',
    html: '&#x1F9DA;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏼‍♂',
    name: 'man fairy: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DA 1F3FC 200D 2642',
    html: '&#x1F9DA;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏽‍♂️',
    name: 'man fairy: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DA 1F3FD 200D 2642 FE0F',
    html: '&#x1F9DA;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏽‍♂',
    name: 'man fairy: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DA 1F3FD 200D 2642',
    html: '&#x1F9DA;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏾‍♂️',
    name: 'man fairy: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DA 1F3FE 200D 2642 FE0F',
    html: '&#x1F9DA;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏾‍♂',
    name: 'man fairy: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DA 1F3FE 200D 2642',
    html: '&#x1F9DA;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏿‍♂️',
    name: 'man fairy: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DA 1F3FF 200D 2642 FE0F',
    html: '&#x1F9DA;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏿‍♂',
    name: 'man fairy: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DA 1F3FF 200D 2642',
    html: '&#x1F9DA;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚‍♀️',
    name: 'woman fairy',
    shortname: ':woman_fairy:',
    unicode: '1F9DA 200D 2640 FE0F',
    html: '&#x1F9DA;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚‍♀',
    name: 'woman fairy',
    shortname: ':woman_fairy:',
    unicode: '1F9DA 200D 2640',
    html: '&#x1F9DA;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏻‍♀️',
    name: 'woman fairy: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DA 1F3FB 200D 2640 FE0F',
    html: '&#x1F9DA;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏻‍♀',
    name: 'woman fairy: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DA 1F3FB 200D 2640',
    html: '&#x1F9DA;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏼‍♀️',
    name: 'woman fairy: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DA 1F3FC 200D 2640 FE0F',
    html: '&#x1F9DA;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏼‍♀',
    name: 'woman fairy: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DA 1F3FC 200D 2640',
    html: '&#x1F9DA;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏽‍♀️',
    name: 'woman fairy: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DA 1F3FD 200D 2640 FE0F',
    html: '&#x1F9DA;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏽‍♀',
    name: 'woman fairy: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DA 1F3FD 200D 2640',
    html: '&#x1F9DA;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏾‍♀️',
    name: 'woman fairy: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DA 1F3FE 200D 2640 FE0F',
    html: '&#x1F9DA;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏾‍♀',
    name: 'woman fairy: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DA 1F3FE 200D 2640',
    html: '&#x1F9DA;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏿‍♀️',
    name: 'woman fairy: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DA 1F3FF 200D 2640 FE0F',
    html: '&#x1F9DA;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧚🏿‍♀',
    name: 'woman fairy: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DA 1F3FF 200D 2640',
    html: '&#x1F9DA;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛',
    name: 'vampire',
    shortname: ':vampire:',
    unicode: '1F9DB',
    html: '&#x1F9DB;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏻',
    name: 'vampire: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DB 1F3FB',
    html: '&#x1F9DB;&#x1F3FB;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏼',
    name: 'vampire: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DB 1F3FC',
    html: '&#x1F9DB;&#x1F3FC;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏽',
    name: 'vampire: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DB 1F3FD',
    html: '&#x1F9DB;&#x1F3FD;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏾',
    name: 'vampire: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DB 1F3FE',
    html: '&#x1F9DB;&#x1F3FE;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏿',
    name: 'vampire: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DB 1F3FF',
    html: '&#x1F9DB;&#x1F3FF;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛‍♂️',
    name: 'man vampire',
    shortname: ':man_vampire:',
    unicode: '1F9DB 200D 2642 FE0F',
    html: '&#x1F9DB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛‍♂',
    name: 'man vampire',
    shortname: ':man_vampire:',
    unicode: '1F9DB 200D 2642',
    html: '&#x1F9DB;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏻‍♂️',
    name: 'man vampire: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DB 1F3FB 200D 2642 FE0F',
    html: '&#x1F9DB;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏻‍♂',
    name: 'man vampire: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DB 1F3FB 200D 2642',
    html: '&#x1F9DB;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏼‍♂️',
    name: 'man vampire: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DB 1F3FC 200D 2642 FE0F',
    html: '&#x1F9DB;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏼‍♂',
    name: 'man vampire: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DB 1F3FC 200D 2642',
    html: '&#x1F9DB;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏽‍♂️',
    name: 'man vampire: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DB 1F3FD 200D 2642 FE0F',
    html: '&#x1F9DB;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏽‍♂',
    name: 'man vampire: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DB 1F3FD 200D 2642',
    html: '&#x1F9DB;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏾‍♂️',
    name: 'man vampire: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DB 1F3FE 200D 2642 FE0F',
    html: '&#x1F9DB;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏾‍♂',
    name: 'man vampire: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DB 1F3FE 200D 2642',
    html: '&#x1F9DB;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏿‍♂️',
    name: 'man vampire: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DB 1F3FF 200D 2642 FE0F',
    html: '&#x1F9DB;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏿‍♂',
    name: 'man vampire: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DB 1F3FF 200D 2642',
    html: '&#x1F9DB;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛‍♀️',
    name: 'woman vampire',
    shortname: ':woman_vampire:',
    unicode: '1F9DB 200D 2640 FE0F',
    html: '&#x1F9DB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛‍♀',
    name: 'woman vampire',
    shortname: ':woman_vampire:',
    unicode: '1F9DB 200D 2640',
    html: '&#x1F9DB;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏻‍♀️',
    name: 'woman vampire: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DB 1F3FB 200D 2640 FE0F',
    html: '&#x1F9DB;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏻‍♀',
    name: 'woman vampire: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DB 1F3FB 200D 2640',
    html: '&#x1F9DB;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏼‍♀️',
    name: 'woman vampire: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DB 1F3FC 200D 2640 FE0F',
    html: '&#x1F9DB;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏼‍♀',
    name: 'woman vampire: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DB 1F3FC 200D 2640',
    html: '&#x1F9DB;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏽‍♀️',
    name: 'woman vampire: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DB 1F3FD 200D 2640 FE0F',
    html: '&#x1F9DB;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏽‍♀',
    name: 'woman vampire: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DB 1F3FD 200D 2640',
    html: '&#x1F9DB;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏾‍♀️',
    name: 'woman vampire: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DB 1F3FE 200D 2640 FE0F',
    html: '&#x1F9DB;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏾‍♀',
    name: 'woman vampire: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DB 1F3FE 200D 2640',
    html: '&#x1F9DB;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏿‍♀️',
    name: 'woman vampire: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DB 1F3FF 200D 2640 FE0F',
    html: '&#x1F9DB;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧛🏿‍♀',
    name: 'woman vampire: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DB 1F3FF 200D 2640',
    html: '&#x1F9DB;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜',
    name: 'merperson',
    shortname: ':merperson:',
    unicode: '1F9DC',
    html: '&#x1F9DC;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏻',
    name: 'merperson: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DC 1F3FB',
    html: '&#x1F9DC;&#x1F3FB;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏼',
    name: 'merperson: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DC 1F3FC',
    html: '&#x1F9DC;&#x1F3FC;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏽',
    name: 'merperson: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DC 1F3FD',
    html: '&#x1F9DC;&#x1F3FD;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏾',
    name: 'merperson: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DC 1F3FE',
    html: '&#x1F9DC;&#x1F3FE;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏿',
    name: 'merperson: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DC 1F3FF',
    html: '&#x1F9DC;&#x1F3FF;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜‍♂️',
    name: 'merman',
    shortname: ':merman:',
    unicode: '1F9DC 200D 2642 FE0F',
    html: '&#x1F9DC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜‍♂',
    name: 'merman',
    shortname: ':merman:',
    unicode: '1F9DC 200D 2642',
    html: '&#x1F9DC;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏻‍♂️',
    name: 'merman: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DC 1F3FB 200D 2642 FE0F',
    html: '&#x1F9DC;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏻‍♂',
    name: 'merman: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DC 1F3FB 200D 2642',
    html: '&#x1F9DC;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏼‍♂️',
    name: 'merman: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DC 1F3FC 200D 2642 FE0F',
    html: '&#x1F9DC;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏼‍♂',
    name: 'merman: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DC 1F3FC 200D 2642',
    html: '&#x1F9DC;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏽‍♂️',
    name: 'merman: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DC 1F3FD 200D 2642 FE0F',
    html: '&#x1F9DC;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏽‍♂',
    name: 'merman: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DC 1F3FD 200D 2642',
    html: '&#x1F9DC;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏾‍♂️',
    name: 'merman: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DC 1F3FE 200D 2642 FE0F',
    html: '&#x1F9DC;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏾‍♂',
    name: 'merman: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DC 1F3FE 200D 2642',
    html: '&#x1F9DC;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏿‍♂️',
    name: 'merman: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DC 1F3FF 200D 2642 FE0F',
    html: '&#x1F9DC;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏿‍♂',
    name: 'merman: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DC 1F3FF 200D 2642',
    html: '&#x1F9DC;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜‍♀️',
    name: 'mermaid',
    shortname: ':mermaid:',
    unicode: '1F9DC 200D 2640 FE0F',
    html: '&#x1F9DC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜‍♀',
    name: 'mermaid',
    shortname: ':mermaid:',
    unicode: '1F9DC 200D 2640',
    html: '&#x1F9DC;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏻‍♀️',
    name: 'mermaid: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DC 1F3FB 200D 2640 FE0F',
    html: '&#x1F9DC;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏻‍♀',
    name: 'mermaid: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DC 1F3FB 200D 2640',
    html: '&#x1F9DC;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏼‍♀️',
    name: 'mermaid: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DC 1F3FC 200D 2640 FE0F',
    html: '&#x1F9DC;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏼‍♀',
    name: 'mermaid: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DC 1F3FC 200D 2640',
    html: '&#x1F9DC;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏽‍♀️',
    name: 'mermaid: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DC 1F3FD 200D 2640 FE0F',
    html: '&#x1F9DC;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏽‍♀',
    name: 'mermaid: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DC 1F3FD 200D 2640',
    html: '&#x1F9DC;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏾‍♀️',
    name: 'mermaid: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DC 1F3FE 200D 2640 FE0F',
    html: '&#x1F9DC;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏾‍♀',
    name: 'mermaid: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DC 1F3FE 200D 2640',
    html: '&#x1F9DC;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏿‍♀️',
    name: 'mermaid: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DC 1F3FF 200D 2640 FE0F',
    html: '&#x1F9DC;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧜🏿‍♀',
    name: 'mermaid: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DC 1F3FF 200D 2640',
    html: '&#x1F9DC;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝',
    name: 'elf',
    shortname: ':elf:',
    unicode: '1F9DD',
    html: '&#x1F9DD;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏻',
    name: 'elf: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DD 1F3FB',
    html: '&#x1F9DD;&#x1F3FB;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏼',
    name: 'elf: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DD 1F3FC',
    html: '&#x1F9DD;&#x1F3FC;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏽',
    name: 'elf: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DD 1F3FD',
    html: '&#x1F9DD;&#x1F3FD;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏾',
    name: 'elf: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DD 1F3FE',
    html: '&#x1F9DD;&#x1F3FE;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏿',
    name: 'elf: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DD 1F3FF',
    html: '&#x1F9DD;&#x1F3FF;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝‍♂️',
    name: 'man elf',
    shortname: ':man_elf:',
    unicode: '1F9DD 200D 2642 FE0F',
    html: '&#x1F9DD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝‍♂',
    name: 'man elf',
    shortname: ':man_elf:',
    unicode: '1F9DD 200D 2642',
    html: '&#x1F9DD;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏻‍♂️',
    name: 'man elf: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DD 1F3FB 200D 2642 FE0F',
    html: '&#x1F9DD;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏻‍♂',
    name: 'man elf: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DD 1F3FB 200D 2642',
    html: '&#x1F9DD;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏼‍♂️',
    name: 'man elf: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DD 1F3FC 200D 2642 FE0F',
    html: '&#x1F9DD;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏼‍♂',
    name: 'man elf: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DD 1F3FC 200D 2642',
    html: '&#x1F9DD;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏽‍♂️',
    name: 'man elf: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DD 1F3FD 200D 2642 FE0F',
    html: '&#x1F9DD;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏽‍♂',
    name: 'man elf: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DD 1F3FD 200D 2642',
    html: '&#x1F9DD;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏾‍♂️',
    name: 'man elf: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DD 1F3FE 200D 2642 FE0F',
    html: '&#x1F9DD;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏾‍♂',
    name: 'man elf: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DD 1F3FE 200D 2642',
    html: '&#x1F9DD;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏿‍♂️',
    name: 'man elf: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DD 1F3FF 200D 2642 FE0F',
    html: '&#x1F9DD;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏿‍♂',
    name: 'man elf: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DD 1F3FF 200D 2642',
    html: '&#x1F9DD;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝‍♀️',
    name: 'woman elf',
    shortname: ':woman_elf:',
    unicode: '1F9DD 200D 2640 FE0F',
    html: '&#x1F9DD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝‍♀',
    name: 'woman elf',
    shortname: ':woman_elf:',
    unicode: '1F9DD 200D 2640',
    html: '&#x1F9DD;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏻‍♀️',
    name: 'woman elf: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DD 1F3FB 200D 2640 FE0F',
    html: '&#x1F9DD;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏻‍♀',
    name: 'woman elf: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9DD 1F3FB 200D 2640',
    html: '&#x1F9DD;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏼‍♀️',
    name: 'woman elf: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DD 1F3FC 200D 2640 FE0F',
    html: '&#x1F9DD;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏼‍♀',
    name: 'woman elf: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9DD 1F3FC 200D 2640',
    html: '&#x1F9DD;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏽‍♀️',
    name: 'woman elf: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DD 1F3FD 200D 2640 FE0F',
    html: '&#x1F9DD;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏽‍♀',
    name: 'woman elf: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9DD 1F3FD 200D 2640',
    html: '&#x1F9DD;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏾‍♀️',
    name: 'woman elf: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DD 1F3FE 200D 2640 FE0F',
    html: '&#x1F9DD;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏾‍♀',
    name: 'woman elf: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9DD 1F3FE 200D 2640',
    html: '&#x1F9DD;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏿‍♀️',
    name: 'woman elf: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DD 1F3FF 200D 2640 FE0F',
    html: '&#x1F9DD;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧝🏿‍♀',
    name: 'woman elf: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9DD 1F3FF 200D 2640',
    html: '&#x1F9DD;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧞',
    name: 'genie',
    shortname: ':genie:',
    unicode: '1F9DE',
    html: '&#x1F9DE;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧞‍♂️',
    name: 'man genie',
    shortname: ':man_genie:',
    unicode: '1F9DE 200D 2642 FE0F',
    html: '&#x1F9DE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧞‍♂',
    name: 'man genie',
    shortname: ':man_genie:',
    unicode: '1F9DE 200D 2642',
    html: '&#x1F9DE;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧞‍♀️',
    name: 'woman genie',
    shortname: ':woman_genie:',
    unicode: '1F9DE 200D 2640 FE0F',
    html: '&#x1F9DE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧞‍♀',
    name: 'woman genie',
    shortname: ':woman_genie:',
    unicode: '1F9DE 200D 2640',
    html: '&#x1F9DE;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧟',
    name: 'zombie',
    shortname: ':zombie:',
    unicode: '1F9DF',
    html: '&#x1F9DF;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧟‍♂️',
    name: 'man zombie',
    shortname: ':man_zombie:',
    unicode: '1F9DF 200D 2642 FE0F',
    html: '&#x1F9DF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧟‍♂',
    name: 'man zombie',
    shortname: ':man_zombie:',
    unicode: '1F9DF 200D 2642',
    html: '&#x1F9DF;&#x200D;&#x2642;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧟‍♀️',
    name: 'woman zombie',
    shortname: ':woman_zombie:',
    unicode: '1F9DF 200D 2640 FE0F',
    html: '&#x1F9DF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '🧟‍♀',
    name: 'woman zombie',
    shortname: ':woman_zombie:',
    unicode: '1F9DF 200D 2640',
    html: '&#x1F9DF;&#x200D;&#x2640;',
    category: 'People & Body (person-fantasy)',
    order: '',
  },
  {
    emoji: '💆‍♂️',
    name: 'man getting massage',
    shortname: ':man_getting_massage:',
    unicode: '1F486 200D 2642 FE0F',
    html: '&#x1F486;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏻‍♂️',
    name: 'man getting massage: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F486 1F3FB 200D 2642 FE0F',
    html: '&#x1F486;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏼‍♂️',
    name: 'man getting massage: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F486 1F3FC 200D 2642 FE0F',
    html: '&#x1F486;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏽‍♂️',
    name: 'man getting massage: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F486 1F3FD 200D 2642 FE0F',
    html: '&#x1F486;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏾‍♂️',
    name: 'man getting massage: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F486 1F3FE 200D 2642 FE0F',
    html: '&#x1F486;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏿‍♂️',
    name: 'man getting massage: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F486 1F3FF 200D 2642 FE0F',
    html: '&#x1F486;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆‍♀️',
    name: 'woman getting massage',
    shortname: ':woman_getting_massage:',
    unicode: '1F486 200D 2640 FE0F',
    html: '&#x1F486;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆‍♀',
    name: 'woman getting massage',
    shortname: ':woman_getting_massage:',
    unicode: '1F486 200D 2640',
    html: '&#x1F486;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏻‍♀️',
    name: 'woman getting massage: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F486 1F3FB 200D 2640 FE0F',
    html: '&#x1F486;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏻‍♀',
    name: 'woman getting massage: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F486 1F3FB 200D 2640',
    html: '&#x1F486;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏼‍♀️',
    name: 'woman getting massage: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F486 1F3FC 200D 2640 FE0F',
    html: '&#x1F486;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏼‍♀',
    name: 'woman getting massage: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F486 1F3FC 200D 2640',
    html: '&#x1F486;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏽‍♀️',
    name: 'woman getting massage: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F486 1F3FD 200D 2640 FE0F',
    html: '&#x1F486;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏽‍♀',
    name: 'woman getting massage: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F486 1F3FD 200D 2640',
    html: '&#x1F486;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏾‍♀️',
    name: 'woman getting massage: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F486 1F3FE 200D 2640 FE0F',
    html: '&#x1F486;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏾‍♀',
    name: 'woman getting massage: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F486 1F3FE 200D 2640',
    html: '&#x1F486;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏿‍♀️',
    name: 'woman getting massage: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F486 1F3FF 200D 2640 FE0F',
    html: '&#x1F486;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💆🏿‍♀',
    name: 'woman getting massage: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F486 1F3FF 200D 2640',
    html: '&#x1F486;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇‍♂️',
    name: 'man getting haircut',
    shortname: ':man_getting_haircut:',
    unicode: '1F487 200D 2642 FE0F',
    html: '&#x1F487;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏻‍♂️',
    name: 'man getting haircut: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F487 1F3FB 200D 2642 FE0F',
    html: '&#x1F487;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏼‍♂️',
    name: 'man getting haircut: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F487 1F3FC 200D 2642 FE0F',
    html: '&#x1F487;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏽‍♂️',
    name: 'man getting haircut: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F487 1F3FD 200D 2642 FE0F',
    html: '&#x1F487;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏾‍♂️',
    name: 'man getting haircut: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F487 1F3FE 200D 2642 FE0F',
    html: '&#x1F487;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏿‍♂️',
    name: 'man getting haircut: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F487 1F3FF 200D 2642 FE0F',
    html: '&#x1F487;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇‍♀️',
    name: 'woman getting haircut',
    shortname: ':woman_getting_haircut:',
    unicode: '1F487 200D 2640 FE0F',
    html: '&#x1F487;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇‍♀',
    name: 'woman getting haircut',
    shortname: ':woman_getting_haircut:',
    unicode: '1F487 200D 2640',
    html: '&#x1F487;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏻‍♀️',
    name: 'woman getting haircut: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F487 1F3FB 200D 2640 FE0F',
    html: '&#x1F487;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏻‍♀',
    name: 'woman getting haircut: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F487 1F3FB 200D 2640',
    html: '&#x1F487;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏼‍♀️',
    name: 'woman getting haircut: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F487 1F3FC 200D 2640 FE0F',
    html: '&#x1F487;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏼‍♀',
    name: 'woman getting haircut: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F487 1F3FC 200D 2640',
    html: '&#x1F487;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏽‍♀️',
    name: 'woman getting haircut: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F487 1F3FD 200D 2640 FE0F',
    html: '&#x1F487;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏽‍♀',
    name: 'woman getting haircut: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F487 1F3FD 200D 2640',
    html: '&#x1F487;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏾‍♀️',
    name: 'woman getting haircut: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F487 1F3FE 200D 2640 FE0F',
    html: '&#x1F487;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏾‍♀',
    name: 'woman getting haircut: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F487 1F3FE 200D 2640',
    html: '&#x1F487;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏿‍♀️',
    name: 'woman getting haircut: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F487 1F3FF 200D 2640 FE0F',
    html: '&#x1F487;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '💇🏿‍♀',
    name: 'woman getting haircut: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F487 1F3FF 200D 2640',
    html: '&#x1F487;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶‍♂️',
    name: 'man walking',
    shortname: ':man_walking:',
    unicode: '1F6B6 200D 2642 FE0F',
    html: '&#x1F6B6;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶‍♂',
    name: 'man walking',
    shortname: ':man_walking:',
    unicode: '1F6B6 200D 2642',
    html: '&#x1F6B6;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏻‍♂️',
    name: 'man walking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B6 1F3FB 200D 2642 FE0F',
    html: '&#x1F6B6;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏻‍♂',
    name: 'man walking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B6 1F3FB 200D 2642',
    html: '&#x1F6B6;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏼‍♂️',
    name: 'man walking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B6 1F3FC 200D 2642 FE0F',
    html: '&#x1F6B6;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏼‍♂',
    name: 'man walking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B6 1F3FC 200D 2642',
    html: '&#x1F6B6;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏽‍♂️',
    name: 'man walking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B6 1F3FD 200D 2642 FE0F',
    html: '&#x1F6B6;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏽‍♂',
    name: 'man walking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B6 1F3FD 200D 2642',
    html: '&#x1F6B6;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏾‍♂️',
    name: 'man walking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B6 1F3FE 200D 2642 FE0F',
    html: '&#x1F6B6;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏾‍♂',
    name: 'man walking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B6 1F3FE 200D 2642',
    html: '&#x1F6B6;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏿‍♂️',
    name: 'man walking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B6 1F3FF 200D 2642 FE0F',
    html: '&#x1F6B6;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏿‍♂',
    name: 'man walking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B6 1F3FF 200D 2642',
    html: '&#x1F6B6;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶‍♀️',
    name: 'woman walking',
    shortname: ':woman_walking:',
    unicode: '1F6B6 200D 2640 FE0F',
    html: '&#x1F6B6;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏻‍♀️',
    name: 'woman walking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B6 1F3FB 200D 2640 FE0F',
    html: '&#x1F6B6;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏼‍♀️',
    name: 'woman walking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B6 1F3FC 200D 2640 FE0F',
    html: '&#x1F6B6;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏽‍♀️',
    name: 'woman walking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B6 1F3FD 200D 2640 FE0F',
    html: '&#x1F6B6;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏾‍♀️',
    name: 'woman walking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B6 1F3FE 200D 2640 FE0F',
    html: '&#x1F6B6;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🚶🏿‍♀️',
    name: 'woman walking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B6 1F3FF 200D 2640 FE0F',
    html: '&#x1F6B6;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍',
    name: 'person standing',
    shortname: ':person_standing:',
    unicode: '1F9CD',
    html: '&#x1F9CD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏻',
    name: 'person standing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CD 1F3FB',
    html: '&#x1F9CD;&#x1F3FB;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏼',
    name: 'person standing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CD 1F3FC',
    html: '&#x1F9CD;&#x1F3FC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏽',
    name: 'person standing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CD 1F3FD',
    html: '&#x1F9CD;&#x1F3FD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏾',
    name: 'person standing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CD 1F3FE',
    html: '&#x1F9CD;&#x1F3FE;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏿',
    name: 'person standing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CD 1F3FF',
    html: '&#x1F9CD;&#x1F3FF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍‍♂️',
    name: 'man standing',
    shortname: ':man_standing:',
    unicode: '1F9CD 200D 2642 FE0F',
    html: '&#x1F9CD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍‍♂',
    name: 'man standing',
    shortname: ':man_standing:',
    unicode: '1F9CD 200D 2642',
    html: '&#x1F9CD;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏻‍♂️',
    name: 'man standing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CD 1F3FB 200D 2642 FE0F',
    html: '&#x1F9CD;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏻‍♂',
    name: 'man standing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CD 1F3FB 200D 2642',
    html: '&#x1F9CD;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏼‍♂️',
    name: 'man standing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CD 1F3FC 200D 2642 FE0F',
    html: '&#x1F9CD;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏼‍♂',
    name: 'man standing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CD 1F3FC 200D 2642',
    html: '&#x1F9CD;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏽‍♂️',
    name: 'man standing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CD 1F3FD 200D 2642 FE0F',
    html: '&#x1F9CD;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏽‍♂',
    name: 'man standing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CD 1F3FD 200D 2642',
    html: '&#x1F9CD;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏾‍♂️',
    name: 'man standing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CD 1F3FE 200D 2642 FE0F',
    html: '&#x1F9CD;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏾‍♂',
    name: 'man standing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CD 1F3FE 200D 2642',
    html: '&#x1F9CD;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏿‍♂️',
    name: 'man standing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CD 1F3FF 200D 2642 FE0F',
    html: '&#x1F9CD;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏿‍♂',
    name: 'man standing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CD 1F3FF 200D 2642',
    html: '&#x1F9CD;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍‍♀️',
    name: 'woman standing',
    shortname: ':woman_standing:',
    unicode: '1F9CD 200D 2640 FE0F',
    html: '&#x1F9CD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍‍♀',
    name: 'woman standing',
    shortname: ':woman_standing:',
    unicode: '1F9CD 200D 2640',
    html: '&#x1F9CD;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏻‍♀️',
    name: 'woman standing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CD 1F3FB 200D 2640 FE0F',
    html: '&#x1F9CD;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏻‍♀',
    name: 'woman standing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CD 1F3FB 200D 2640',
    html: '&#x1F9CD;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏼‍♀️',
    name: 'woman standing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CD 1F3FC 200D 2640 FE0F',
    html: '&#x1F9CD;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏼‍♀',
    name: 'woman standing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CD 1F3FC 200D 2640',
    html: '&#x1F9CD;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏽‍♀️',
    name: 'woman standing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CD 1F3FD 200D 2640 FE0F',
    html: '&#x1F9CD;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏽‍♀',
    name: 'woman standing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CD 1F3FD 200D 2640',
    html: '&#x1F9CD;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏾‍♀️',
    name: 'woman standing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CD 1F3FE 200D 2640 FE0F',
    html: '&#x1F9CD;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏾‍♀',
    name: 'woman standing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CD 1F3FE 200D 2640',
    html: '&#x1F9CD;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏿‍♀️',
    name: 'woman standing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CD 1F3FF 200D 2640 FE0F',
    html: '&#x1F9CD;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧍🏿‍♀',
    name: 'woman standing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CD 1F3FF 200D 2640',
    html: '&#x1F9CD;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎',
    name: 'person kneeling',
    shortname: ':person_kneeling:',
    unicode: '1F9CE',
    html: '&#x1F9CE;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏻',
    name: 'person kneeling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CE 1F3FB',
    html: '&#x1F9CE;&#x1F3FB;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏼',
    name: 'person kneeling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CE 1F3FC',
    html: '&#x1F9CE;&#x1F3FC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏽',
    name: 'person kneeling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CE 1F3FD',
    html: '&#x1F9CE;&#x1F3FD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏾',
    name: 'person kneeling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CE 1F3FE',
    html: '&#x1F9CE;&#x1F3FE;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏿',
    name: 'person kneeling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CE 1F3FF',
    html: '&#x1F9CE;&#x1F3FF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎‍♂️',
    name: 'man kneeling',
    shortname: ':man_kneeling:',
    unicode: '1F9CE 200D 2642 FE0F',
    html: '&#x1F9CE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎‍♂',
    name: 'man kneeling',
    shortname: ':man_kneeling:',
    unicode: '1F9CE 200D 2642',
    html: '&#x1F9CE;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏻‍♂️',
    name: 'man kneeling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CE 1F3FB 200D 2642 FE0F',
    html: '&#x1F9CE;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏻‍♂',
    name: 'man kneeling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CE 1F3FB 200D 2642',
    html: '&#x1F9CE;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏼‍♂️',
    name: 'man kneeling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CE 1F3FC 200D 2642 FE0F',
    html: '&#x1F9CE;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏼‍♂',
    name: 'man kneeling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CE 1F3FC 200D 2642',
    html: '&#x1F9CE;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏽‍♂️',
    name: 'man kneeling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CE 1F3FD 200D 2642 FE0F',
    html: '&#x1F9CE;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏽‍♂',
    name: 'man kneeling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CE 1F3FD 200D 2642',
    html: '&#x1F9CE;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏾‍♂️',
    name: 'man kneeling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CE 1F3FE 200D 2642 FE0F',
    html: '&#x1F9CE;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏾‍♂',
    name: 'man kneeling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CE 1F3FE 200D 2642',
    html: '&#x1F9CE;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏿‍♂️',
    name: 'man kneeling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CE 1F3FF 200D 2642 FE0F',
    html: '&#x1F9CE;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏿‍♂',
    name: 'man kneeling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CE 1F3FF 200D 2642',
    html: '&#x1F9CE;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎‍♀️',
    name: 'woman kneeling',
    shortname: ':woman_kneeling:',
    unicode: '1F9CE 200D 2640 FE0F',
    html: '&#x1F9CE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎‍♀',
    name: 'woman kneeling',
    shortname: ':woman_kneeling:',
    unicode: '1F9CE 200D 2640',
    html: '&#x1F9CE;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏻‍♀️',
    name: 'woman kneeling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CE 1F3FB 200D 2640 FE0F',
    html: '&#x1F9CE;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏻‍♀',
    name: 'woman kneeling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9CE 1F3FB 200D 2640',
    html: '&#x1F9CE;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏼‍♀️',
    name: 'woman kneeling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CE 1F3FC 200D 2640 FE0F',
    html: '&#x1F9CE;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏼‍♀',
    name: 'woman kneeling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9CE 1F3FC 200D 2640',
    html: '&#x1F9CE;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏽‍♀️',
    name: 'woman kneeling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CE 1F3FD 200D 2640 FE0F',
    html: '&#x1F9CE;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏽‍♀',
    name: 'woman kneeling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9CE 1F3FD 200D 2640',
    html: '&#x1F9CE;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏾‍♀️',
    name: 'woman kneeling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CE 1F3FE 200D 2640 FE0F',
    html: '&#x1F9CE;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏾‍♀',
    name: 'woman kneeling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9CE 1F3FE 200D 2640',
    html: '&#x1F9CE;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏿‍♀️',
    name: 'woman kneeling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CE 1F3FF 200D 2640 FE0F',
    html: '&#x1F9CE;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧎🏿‍♀',
    name: 'woman kneeling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9CE 1F3FF 200D 2640',
    html: '&#x1F9CE;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑‍🦯',
    name: 'person with probing cane',
    shortname: ':person_with_probing_cane:',
    unicode: '1F9D1 200D 1F9AF',
    html: '&#x1F9D1;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🦯',
    name: 'person with probing cane: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F9AF',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🦯',
    name: 'person with probing cane: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F9AF',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🦯',
    name: 'person with probing cane: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F9AF',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🦯',
    name: 'person with probing cane: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F9AF',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🦯',
    name: 'person with probing cane: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F9AF',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨‍🦯',
    name: 'man with probing cane',
    shortname: ':man_with_probing_cane:',
    unicode: '1F468 200D 1F9AF',
    html: '&#x1F468;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏻‍🦯',
    name: 'man with probing cane: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F9AF',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏼‍🦯',
    name: 'man with probing cane: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F9AF',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏽‍🦯',
    name: 'man with probing cane: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F9AF',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏾‍🦯',
    name: 'man with probing cane: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F9AF',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏿‍🦯',
    name: 'man with probing cane: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F9AF',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩‍🦯',
    name: 'woman with probing cane',
    shortname: ':woman_with_probing_cane:',
    unicode: '1F469 200D 1F9AF',
    html: '&#x1F469;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏻‍🦯',
    name: 'woman with probing cane: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F9AF',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏼‍🦯',
    name: 'woman with probing cane: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F9AF',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏽‍🦯',
    name: 'woman with probing cane: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F9AF',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏾‍🦯',
    name: 'woman with probing cane: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F9AF',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏿‍🦯',
    name: 'woman with probing cane: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F9AF',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F9AF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑‍🦼',
    name: 'person in motorized wheelchair',
    shortname: ':person_in_motorized_wheelchair:',
    unicode: '1F9D1 200D 1F9BC',
    html: '&#x1F9D1;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🦼',
    name: 'person in motorized wheelchair: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F9BC',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🦼',
    name: 'person in motorized wheelchair: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F9BC',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🦼',
    name: 'person in motorized wheelchair: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F9BC',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🦼',
    name: 'person in motorized wheelchair: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F9BC',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🦼',
    name: 'person in motorized wheelchair: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F9BC',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨‍🦼',
    name: 'man in motorized wheelchair',
    shortname: ':man_in_motorized_wheelchair:',
    unicode: '1F468 200D 1F9BC',
    html: '&#x1F468;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏻‍🦼',
    name: 'man in motorized wheelchair: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F9BC',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏼‍🦼',
    name: 'man in motorized wheelchair: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F9BC',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏽‍🦼',
    name: 'man in motorized wheelchair: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F9BC',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏾‍🦼',
    name: 'man in motorized wheelchair: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F9BC',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏿‍🦼',
    name: 'man in motorized wheelchair: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F9BC',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩‍🦼',
    name: 'woman in motorized wheelchair',
    shortname: ':woman_in_motorized_wheelchair:',
    unicode: '1F469 200D 1F9BC',
    html: '&#x1F469;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏻‍🦼',
    name: 'woman in motorized wheelchair: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F9BC',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏼‍🦼',
    name: 'woman in motorized wheelchair: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F9BC',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏽‍🦼',
    name: 'woman in motorized wheelchair: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F9BC',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏾‍🦼',
    name: 'woman in motorized wheelchair: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F9BC',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏿‍🦼',
    name: 'woman in motorized wheelchair: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F9BC',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F9BC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑‍🦽',
    name: 'person in manual wheelchair',
    shortname: ':person_in_manual_wheelchair:',
    unicode: '1F9D1 200D 1F9BD',
    html: '&#x1F9D1;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🦽',
    name: 'person in manual wheelchair: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F9BD',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🦽',
    name: 'person in manual wheelchair: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F9BD',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🦽',
    name: 'person in manual wheelchair: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F9BD',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🦽',
    name: 'person in manual wheelchair: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F9BD',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🦽',
    name: 'person in manual wheelchair: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F9BD',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨‍🦽',
    name: 'man in manual wheelchair',
    shortname: ':man_in_manual_wheelchair:',
    unicode: '1F468 200D 1F9BD',
    html: '&#x1F468;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏻‍🦽',
    name: 'man in manual wheelchair: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F9BD',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏼‍🦽',
    name: 'man in manual wheelchair: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F9BD',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏽‍🦽',
    name: 'man in manual wheelchair: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F9BD',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏾‍🦽',
    name: 'man in manual wheelchair: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F9BD',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👨🏿‍🦽',
    name: 'man in manual wheelchair: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F9BD',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩‍🦽',
    name: 'woman in manual wheelchair',
    shortname: ':woman_in_manual_wheelchair:',
    unicode: '1F469 200D 1F9BD',
    html: '&#x1F469;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏻‍🦽',
    name: 'woman in manual wheelchair: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F9BD',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏼‍🦽',
    name: 'woman in manual wheelchair: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F9BD',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏽‍🦽',
    name: 'woman in manual wheelchair: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F9BD',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏾‍🦽',
    name: 'woman in manual wheelchair: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F9BD',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👩🏿‍🦽',
    name: 'woman in manual wheelchair: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F9BD',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F9BD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃‍♂️',
    name: 'man running',
    shortname: ':man_running:',
    unicode: '1F3C3 200D 2642 FE0F',
    html: '&#x1F3C3;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃‍♂',
    name: 'man running',
    shortname: ':man_running:',
    unicode: '1F3C3 200D 2642',
    html: '&#x1F3C3;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏻‍♂️',
    name: 'man running: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3C3 1F3FB 200D 2642 FE0F',
    html: '&#x1F3C3;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏻‍♂',
    name: 'man running: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3C3 1F3FB 200D 2642',
    html: '&#x1F3C3;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏼‍♂️',
    name: 'man running: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3C3 1F3FC 200D 2642 FE0F',
    html: '&#x1F3C3;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏼‍♂',
    name: 'man running: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3C3 1F3FC 200D 2642',
    html: '&#x1F3C3;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏽‍♂️',
    name: 'man running: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3C3 1F3FD 200D 2642 FE0F',
    html: '&#x1F3C3;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏽‍♂',
    name: 'man running: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3C3 1F3FD 200D 2642',
    html: '&#x1F3C3;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏾‍♂️',
    name: 'man running: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3C3 1F3FE 200D 2642 FE0F',
    html: '&#x1F3C3;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏾‍♂',
    name: 'man running: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3C3 1F3FE 200D 2642',
    html: '&#x1F3C3;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏿‍♂️',
    name: 'man running: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3C3 1F3FF 200D 2642 FE0F',
    html: '&#x1F3C3;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏿‍♂',
    name: 'man running: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3C3 1F3FF 200D 2642',
    html: '&#x1F3C3;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃‍♀️',
    name: 'woman running',
    shortname: ':woman_running:',
    unicode: '1F3C3 200D 2640 FE0F',
    html: '&#x1F3C3;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏻‍♀️',
    name: 'woman running: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3C3 1F3FB 200D 2640 FE0F',
    html: '&#x1F3C3;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏼‍♀️',
    name: 'woman running: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3C3 1F3FC 200D 2640 FE0F',
    html: '&#x1F3C3;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏽‍♀️',
    name: 'woman running: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3C3 1F3FD 200D 2640 FE0F',
    html: '&#x1F3C3;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏾‍♀️',
    name: 'woman running: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3C3 1F3FE 200D 2640 FE0F',
    html: '&#x1F3C3;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🏃🏿‍♀️',
    name: 'woman running: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3C3 1F3FF 200D 2640 FE0F',
    html: '&#x1F3C3;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🕺',
    name: 'man dancing',
    shortname: ':man_dancing:',
    unicode: '1F57A',
    html: '&#x1F57A;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🕺🏻',
    name: 'man dancing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F57A 1F3FB',
    html: '&#x1F57A;&#x1F3FB;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🕺🏼',
    name: 'man dancing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F57A 1F3FC',
    html: '&#x1F57A;&#x1F3FC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🕺🏽',
    name: 'man dancing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F57A 1F3FD',
    html: '&#x1F57A;&#x1F3FD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🕺🏾',
    name: 'man dancing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F57A 1F3FE',
    html: '&#x1F57A;&#x1F3FE;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🕺🏿',
    name: 'man dancing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F57A 1F3FF',
    html: '&#x1F57A;&#x1F3FF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🕴️',
    name: 'man in suit levitating',
    shortname: ':man_in_suit_levitating:',
    unicode: '1F574 FE0F',
    html: '&#x1F574;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🕴🏻',
    name: 'man in suit levitating: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F574 1F3FB',
    html: '&#x1F574;&#x1F3FB;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🕴🏼',
    name: 'man in suit levitating: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F574 1F3FC',
    html: '&#x1F574;&#x1F3FC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🕴🏽',
    name: 'man in suit levitating: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F574 1F3FD',
    html: '&#x1F574;&#x1F3FD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🕴🏾',
    name: 'man in suit levitating: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F574 1F3FE',
    html: '&#x1F574;&#x1F3FE;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🕴🏿',
    name: 'man in suit levitating: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F574 1F3FF',
    html: '&#x1F574;&#x1F3FF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👯‍♂️',
    name: 'men with bunny ears',
    shortname: ':men_with_bunny_ears:',
    unicode: '1F46F 200D 2642 FE0F',
    html: '&#x1F46F;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👯‍♀️',
    name: 'women with bunny ears',
    shortname: ':women_with_bunny_ears:',
    unicode: '1F46F 200D 2640 FE0F',
    html: '&#x1F46F;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '👯‍♀',
    name: 'women with bunny ears',
    shortname: ':women_with_bunny_ears:',
    unicode: '1F46F 200D 2640',
    html: '&#x1F46F;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖',
    name: 'person in steamy room',
    shortname: ':person_in_steamy_room:',
    unicode: '1F9D6',
    html: '&#x1F9D6;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏻',
    name: 'person in steamy room: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D6 1F3FB',
    html: '&#x1F9D6;&#x1F3FB;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏼',
    name: 'person in steamy room: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D6 1F3FC',
    html: '&#x1F9D6;&#x1F3FC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏽',
    name: 'person in steamy room: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D6 1F3FD',
    html: '&#x1F9D6;&#x1F3FD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏾',
    name: 'person in steamy room: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D6 1F3FE',
    html: '&#x1F9D6;&#x1F3FE;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏿',
    name: 'person in steamy room: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D6 1F3FF',
    html: '&#x1F9D6;&#x1F3FF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖‍♂️',
    name: 'man in steamy room',
    shortname: ':man_in_steamy_room:',
    unicode: '1F9D6 200D 2642 FE0F',
    html: '&#x1F9D6;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖‍♂',
    name: 'man in steamy room',
    shortname: ':man_in_steamy_room:',
    unicode: '1F9D6 200D 2642',
    html: '&#x1F9D6;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏻‍♂️',
    name: 'man in steamy room: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D6 1F3FB 200D 2642 FE0F',
    html: '&#x1F9D6;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏻‍♂',
    name: 'man in steamy room: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D6 1F3FB 200D 2642',
    html: '&#x1F9D6;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏼‍♂️',
    name: 'man in steamy room: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D6 1F3FC 200D 2642 FE0F',
    html: '&#x1F9D6;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏼‍♂',
    name: 'man in steamy room: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D6 1F3FC 200D 2642',
    html: '&#x1F9D6;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏽‍♂️',
    name: 'man in steamy room: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D6 1F3FD 200D 2642 FE0F',
    html: '&#x1F9D6;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏽‍♂',
    name: 'man in steamy room: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D6 1F3FD 200D 2642',
    html: '&#x1F9D6;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏾‍♂️',
    name: 'man in steamy room: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D6 1F3FE 200D 2642 FE0F',
    html: '&#x1F9D6;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏾‍♂',
    name: 'man in steamy room: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D6 1F3FE 200D 2642',
    html: '&#x1F9D6;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏿‍♂️',
    name: 'man in steamy room: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D6 1F3FF 200D 2642 FE0F',
    html: '&#x1F9D6;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏿‍♂',
    name: 'man in steamy room: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D6 1F3FF 200D 2642',
    html: '&#x1F9D6;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖‍♀️',
    name: 'woman in steamy room',
    shortname: ':woman_in_steamy_room:',
    unicode: '1F9D6 200D 2640 FE0F',
    html: '&#x1F9D6;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖‍♀',
    name: 'woman in steamy room',
    shortname: ':woman_in_steamy_room:',
    unicode: '1F9D6 200D 2640',
    html: '&#x1F9D6;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏻‍♀️',
    name: 'woman in steamy room: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D6 1F3FB 200D 2640 FE0F',
    html: '&#x1F9D6;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏻‍♀',
    name: 'woman in steamy room: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D6 1F3FB 200D 2640',
    html: '&#x1F9D6;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏼‍♀️',
    name: 'woman in steamy room: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D6 1F3FC 200D 2640 FE0F',
    html: '&#x1F9D6;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏼‍♀',
    name: 'woman in steamy room: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D6 1F3FC 200D 2640',
    html: '&#x1F9D6;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏽‍♀️',
    name: 'woman in steamy room: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D6 1F3FD 200D 2640 FE0F',
    html: '&#x1F9D6;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏽‍♀',
    name: 'woman in steamy room: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D6 1F3FD 200D 2640',
    html: '&#x1F9D6;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏾‍♀️',
    name: 'woman in steamy room: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D6 1F3FE 200D 2640 FE0F',
    html: '&#x1F9D6;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏾‍♀',
    name: 'woman in steamy room: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D6 1F3FE 200D 2640',
    html: '&#x1F9D6;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏿‍♀️',
    name: 'woman in steamy room: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D6 1F3FF 200D 2640 FE0F',
    html: '&#x1F9D6;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧖🏿‍♀',
    name: 'woman in steamy room: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D6 1F3FF 200D 2640',
    html: '&#x1F9D6;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗',
    name: 'person climbing',
    shortname: ':person_climbing:',
    unicode: '1F9D7',
    html: '&#x1F9D7;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏻',
    name: 'person climbing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D7 1F3FB',
    html: '&#x1F9D7;&#x1F3FB;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏼',
    name: 'person climbing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D7 1F3FC',
    html: '&#x1F9D7;&#x1F3FC;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏽',
    name: 'person climbing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D7 1F3FD',
    html: '&#x1F9D7;&#x1F3FD;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏾',
    name: 'person climbing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D7 1F3FE',
    html: '&#x1F9D7;&#x1F3FE;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏿',
    name: 'person climbing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D7 1F3FF',
    html: '&#x1F9D7;&#x1F3FF;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗‍♂️',
    name: 'man climbing',
    shortname: ':man_climbing:',
    unicode: '1F9D7 200D 2642 FE0F',
    html: '&#x1F9D7;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗‍♂',
    name: 'man climbing',
    shortname: ':man_climbing:',
    unicode: '1F9D7 200D 2642',
    html: '&#x1F9D7;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏻‍♂️',
    name: 'man climbing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D7 1F3FB 200D 2642 FE0F',
    html: '&#x1F9D7;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏻‍♂',
    name: 'man climbing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D7 1F3FB 200D 2642',
    html: '&#x1F9D7;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏼‍♂️',
    name: 'man climbing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D7 1F3FC 200D 2642 FE0F',
    html: '&#x1F9D7;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏼‍♂',
    name: 'man climbing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D7 1F3FC 200D 2642',
    html: '&#x1F9D7;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏽‍♂️',
    name: 'man climbing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D7 1F3FD 200D 2642 FE0F',
    html: '&#x1F9D7;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏽‍♂',
    name: 'man climbing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D7 1F3FD 200D 2642',
    html: '&#x1F9D7;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏾‍♂️',
    name: 'man climbing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D7 1F3FE 200D 2642 FE0F',
    html: '&#x1F9D7;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏾‍♂',
    name: 'man climbing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D7 1F3FE 200D 2642',
    html: '&#x1F9D7;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏿‍♂️',
    name: 'man climbing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D7 1F3FF 200D 2642 FE0F',
    html: '&#x1F9D7;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏿‍♂',
    name: 'man climbing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D7 1F3FF 200D 2642',
    html: '&#x1F9D7;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗‍♀️',
    name: 'woman climbing',
    shortname: ':woman_climbing:',
    unicode: '1F9D7 200D 2640 FE0F',
    html: '&#x1F9D7;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗‍♀',
    name: 'woman climbing',
    shortname: ':woman_climbing:',
    unicode: '1F9D7 200D 2640',
    html: '&#x1F9D7;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏻‍♀️',
    name: 'woman climbing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D7 1F3FB 200D 2640 FE0F',
    html: '&#x1F9D7;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏻‍♀',
    name: 'woman climbing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D7 1F3FB 200D 2640',
    html: '&#x1F9D7;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏼‍♀️',
    name: 'woman climbing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D7 1F3FC 200D 2640 FE0F',
    html: '&#x1F9D7;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏼‍♀',
    name: 'woman climbing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D7 1F3FC 200D 2640',
    html: '&#x1F9D7;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏽‍♀️',
    name: 'woman climbing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D7 1F3FD 200D 2640 FE0F',
    html: '&#x1F9D7;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏽‍♀',
    name: 'woman climbing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D7 1F3FD 200D 2640',
    html: '&#x1F9D7;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏾‍♀️',
    name: 'woman climbing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D7 1F3FE 200D 2640 FE0F',
    html: '&#x1F9D7;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏾‍♀',
    name: 'woman climbing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D7 1F3FE 200D 2640',
    html: '&#x1F9D7;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏿‍♀️',
    name: 'woman climbing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D7 1F3FF 200D 2640 FE0F',
    html: '&#x1F9D7;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🧗🏿‍♀',
    name: 'woman climbing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D7 1F3FF 200D 2640',
    html: '&#x1F9D7;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-activity)',
    order: '',
  },
  {
    emoji: '🤺',
    name: 'person fencing',
    shortname: ':person_fencing:',
    unicode: '1F93A',
    html: '&#x1F93A;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏇🏻',
    name: 'horse racing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3C7 1F3FB',
    html: '&#x1F3C7;&#x1F3FB;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏇🏼',
    name: 'horse racing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3C7 1F3FC',
    html: '&#x1F3C7;&#x1F3FC;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏇🏽',
    name: 'horse racing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3C7 1F3FD',
    html: '&#x1F3C7;&#x1F3FD;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏇🏾',
    name: 'horse racing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3C7 1F3FE',
    html: '&#x1F3C7;&#x1F3FE;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏇🏿',
    name: 'horse racing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3C7 1F3FF',
    html: '&#x1F3C7;&#x1F3FF;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛷️',
    name: 'skier',
    shortname: ':skier:',
    unicode: '26F7 FE0F',
    html: '&#x26F7;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏂🏻',
    name: 'snowboarder: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3C2 1F3FB',
    html: '&#x1F3C2;&#x1F3FB;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏂🏼',
    name: 'snowboarder: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3C2 1F3FC',
    html: '&#x1F3C2;&#x1F3FC;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏂🏽',
    name: 'snowboarder: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3C2 1F3FD',
    html: '&#x1F3C2;&#x1F3FD;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏂🏾',
    name: 'snowboarder: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3C2 1F3FE',
    html: '&#x1F3C2;&#x1F3FE;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏂🏿',
    name: 'snowboarder: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3C2 1F3FF',
    html: '&#x1F3C2;&#x1F3FF;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌️',
    name: 'person golfing',
    shortname: ':person_golfing:',
    unicode: '1F3CC FE0F',
    html: '&#x1F3CC;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏻',
    name: 'person golfing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3CC 1F3FB',
    html: '&#x1F3CC;&#x1F3FB;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏼',
    name: 'person golfing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3CC 1F3FC',
    html: '&#x1F3CC;&#x1F3FC;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏽',
    name: 'person golfing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3CC 1F3FD',
    html: '&#x1F3CC;&#x1F3FD;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏾',
    name: 'person golfing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3CC 1F3FE',
    html: '&#x1F3CC;&#x1F3FE;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏿',
    name: 'person golfing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3CC 1F3FF',
    html: '&#x1F3CC;&#x1F3FF;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌️‍♂️',
    name: 'man golfing',
    shortname: ':man_golfing:',
    unicode: '1F3CC FE0F 200D 2642 FE0F',
    html: '&#x1F3CC;&#xFE0F;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌‍♂️',
    name: 'man golfing',
    shortname: ':man_golfing:',
    unicode: '1F3CC 200D 2642 FE0F',
    html: '&#x1F3CC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌️‍♂',
    name: 'man golfing',
    shortname: ':man_golfing:',
    unicode: '1F3CC FE0F 200D 2642',
    html: '&#x1F3CC;&#xFE0F;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌‍♂',
    name: 'man golfing',
    shortname: ':man_golfing:',
    unicode: '1F3CC 200D 2642',
    html: '&#x1F3CC;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏻‍♂️',
    name: 'man golfing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3CC 1F3FB 200D 2642 FE0F',
    html: '&#x1F3CC;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏻‍♂',
    name: 'man golfing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3CC 1F3FB 200D 2642',
    html: '&#x1F3CC;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏼‍♂️',
    name: 'man golfing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3CC 1F3FC 200D 2642 FE0F',
    html: '&#x1F3CC;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏼‍♂',
    name: 'man golfing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3CC 1F3FC 200D 2642',
    html: '&#x1F3CC;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏽‍♂️',
    name: 'man golfing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3CC 1F3FD 200D 2642 FE0F',
    html: '&#x1F3CC;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏽‍♂',
    name: 'man golfing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3CC 1F3FD 200D 2642',
    html: '&#x1F3CC;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏾‍♂️',
    name: 'man golfing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3CC 1F3FE 200D 2642 FE0F',
    html: '&#x1F3CC;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏾‍♂',
    name: 'man golfing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3CC 1F3FE 200D 2642',
    html: '&#x1F3CC;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏿‍♂️',
    name: 'man golfing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3CC 1F3FF 200D 2642 FE0F',
    html: '&#x1F3CC;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏿‍♂',
    name: 'man golfing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3CC 1F3FF 200D 2642',
    html: '&#x1F3CC;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌️‍♀️',
    name: 'woman golfing',
    shortname: ':woman_golfing:',
    unicode: '1F3CC FE0F 200D 2640 FE0F',
    html: '&#x1F3CC;&#xFE0F;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌‍♀️',
    name: 'woman golfing',
    shortname: ':woman_golfing:',
    unicode: '1F3CC 200D 2640 FE0F',
    html: '&#x1F3CC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌️‍♀',
    name: 'woman golfing',
    shortname: ':woman_golfing:',
    unicode: '1F3CC FE0F 200D 2640',
    html: '&#x1F3CC;&#xFE0F;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏻‍♀️',
    name: 'woman golfing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3CC 1F3FB 200D 2640 FE0F',
    html: '&#x1F3CC;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏻‍♀',
    name: 'woman golfing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3CC 1F3FB 200D 2640',
    html: '&#x1F3CC;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏼‍♀️',
    name: 'woman golfing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3CC 1F3FC 200D 2640 FE0F',
    html: '&#x1F3CC;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏼‍♀',
    name: 'woman golfing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3CC 1F3FC 200D 2640',
    html: '&#x1F3CC;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏽‍♀️',
    name: 'woman golfing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3CC 1F3FD 200D 2640 FE0F',
    html: '&#x1F3CC;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏽‍♀',
    name: 'woman golfing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3CC 1F3FD 200D 2640',
    html: '&#x1F3CC;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏾‍♀️',
    name: 'woman golfing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3CC 1F3FE 200D 2640 FE0F',
    html: '&#x1F3CC;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏾‍♀',
    name: 'woman golfing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3CC 1F3FE 200D 2640',
    html: '&#x1F3CC;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏿‍♀️',
    name: 'woman golfing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3CC 1F3FF 200D 2640 FE0F',
    html: '&#x1F3CC;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏌🏿‍♀',
    name: 'woman golfing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3CC 1F3FF 200D 2640',
    html: '&#x1F3CC;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄‍♂️',
    name: 'man surfing',
    shortname: ':man_surfing:',
    unicode: '1F3C4 200D 2642 FE0F',
    html: '&#x1F3C4;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄‍♂',
    name: 'man surfing',
    shortname: ':man_surfing:',
    unicode: '1F3C4 200D 2642',
    html: '&#x1F3C4;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏻‍♂️',
    name: 'man surfing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3C4 1F3FB 200D 2642 FE0F',
    html: '&#x1F3C4;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏻‍♂',
    name: 'man surfing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3C4 1F3FB 200D 2642',
    html: '&#x1F3C4;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏼‍♂️',
    name: 'man surfing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3C4 1F3FC 200D 2642 FE0F',
    html: '&#x1F3C4;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏼‍♂',
    name: 'man surfing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3C4 1F3FC 200D 2642',
    html: '&#x1F3C4;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏽‍♂️',
    name: 'man surfing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3C4 1F3FD 200D 2642 FE0F',
    html: '&#x1F3C4;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏽‍♂',
    name: 'man surfing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3C4 1F3FD 200D 2642',
    html: '&#x1F3C4;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏾‍♂️',
    name: 'man surfing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3C4 1F3FE 200D 2642 FE0F',
    html: '&#x1F3C4;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏾‍♂',
    name: 'man surfing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3C4 1F3FE 200D 2642',
    html: '&#x1F3C4;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏿‍♂️',
    name: 'man surfing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3C4 1F3FF 200D 2642 FE0F',
    html: '&#x1F3C4;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏿‍♂',
    name: 'man surfing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3C4 1F3FF 200D 2642',
    html: '&#x1F3C4;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄‍♀️',
    name: 'woman surfing',
    shortname: ':woman_surfing:',
    unicode: '1F3C4 200D 2640 FE0F',
    html: '&#x1F3C4;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏻‍♀️',
    name: 'woman surfing: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3C4 1F3FB 200D 2640 FE0F',
    html: '&#x1F3C4;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏼‍♀️',
    name: 'woman surfing: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3C4 1F3FC 200D 2640 FE0F',
    html: '&#x1F3C4;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏽‍♀️',
    name: 'woman surfing: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3C4 1F3FD 200D 2640 FE0F',
    html: '&#x1F3C4;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏾‍♀️',
    name: 'woman surfing: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3C4 1F3FE 200D 2640 FE0F',
    html: '&#x1F3C4;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏄🏿‍♀️',
    name: 'woman surfing: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3C4 1F3FF 200D 2640 FE0F',
    html: '&#x1F3C4;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣‍♂️',
    name: 'man rowing boat',
    shortname: ':man_rowing_boat:',
    unicode: '1F6A3 200D 2642 FE0F',
    html: '&#x1F6A3;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣‍♂',
    name: 'man rowing boat',
    shortname: ':man_rowing_boat:',
    unicode: '1F6A3 200D 2642',
    html: '&#x1F6A3;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏻‍♂️',
    name: 'man rowing boat: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6A3 1F3FB 200D 2642 FE0F',
    html: '&#x1F6A3;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏻‍♂',
    name: 'man rowing boat: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6A3 1F3FB 200D 2642',
    html: '&#x1F6A3;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏼‍♂️',
    name: 'man rowing boat: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6A3 1F3FC 200D 2642 FE0F',
    html: '&#x1F6A3;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏼‍♂',
    name: 'man rowing boat: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6A3 1F3FC 200D 2642',
    html: '&#x1F6A3;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏽‍♂️',
    name: 'man rowing boat: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6A3 1F3FD 200D 2642 FE0F',
    html: '&#x1F6A3;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏽‍♂',
    name: 'man rowing boat: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6A3 1F3FD 200D 2642',
    html: '&#x1F6A3;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏾‍♂️',
    name: 'man rowing boat: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6A3 1F3FE 200D 2642 FE0F',
    html: '&#x1F6A3;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏾‍♂',
    name: 'man rowing boat: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6A3 1F3FE 200D 2642',
    html: '&#x1F6A3;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏿‍♂️',
    name: 'man rowing boat: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6A3 1F3FF 200D 2642 FE0F',
    html: '&#x1F6A3;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏿‍♂',
    name: 'man rowing boat: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6A3 1F3FF 200D 2642',
    html: '&#x1F6A3;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣‍♀️',
    name: 'woman rowing boat',
    shortname: ':woman_rowing_boat:',
    unicode: '1F6A3 200D 2640 FE0F',
    html: '&#x1F6A3;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏻‍♀️',
    name: 'woman rowing boat: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6A3 1F3FB 200D 2640 FE0F',
    html: '&#x1F6A3;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏼‍♀️',
    name: 'woman rowing boat: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6A3 1F3FC 200D 2640 FE0F',
    html: '&#x1F6A3;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏽‍♀️',
    name: 'woman rowing boat: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6A3 1F3FD 200D 2640 FE0F',
    html: '&#x1F6A3;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏾‍♀️',
    name: 'woman rowing boat: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6A3 1F3FE 200D 2640 FE0F',
    html: '&#x1F6A3;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚣🏿‍♀️',
    name: 'woman rowing boat: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6A3 1F3FF 200D 2640 FE0F',
    html: '&#x1F6A3;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊‍♂️',
    name: 'man swimming',
    shortname: ':man_swimming:',
    unicode: '1F3CA 200D 2642 FE0F',
    html: '&#x1F3CA;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊‍♂',
    name: 'man swimming',
    shortname: ':man_swimming:',
    unicode: '1F3CA 200D 2642',
    html: '&#x1F3CA;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏻‍♂️',
    name: 'man swimming: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3CA 1F3FB 200D 2642 FE0F',
    html: '&#x1F3CA;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏻‍♂',
    name: 'man swimming: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3CA 1F3FB 200D 2642',
    html: '&#x1F3CA;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏼‍♂️',
    name: 'man swimming: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3CA 1F3FC 200D 2642 FE0F',
    html: '&#x1F3CA;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏼‍♂',
    name: 'man swimming: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3CA 1F3FC 200D 2642',
    html: '&#x1F3CA;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏽‍♂️',
    name: 'man swimming: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3CA 1F3FD 200D 2642 FE0F',
    html: '&#x1F3CA;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏽‍♂',
    name: 'man swimming: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3CA 1F3FD 200D 2642',
    html: '&#x1F3CA;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏾‍♂️',
    name: 'man swimming: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3CA 1F3FE 200D 2642 FE0F',
    html: '&#x1F3CA;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏾‍♂',
    name: 'man swimming: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3CA 1F3FE 200D 2642',
    html: '&#x1F3CA;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏿‍♂️',
    name: 'man swimming: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3CA 1F3FF 200D 2642 FE0F',
    html: '&#x1F3CA;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏿‍♂',
    name: 'man swimming: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3CA 1F3FF 200D 2642',
    html: '&#x1F3CA;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊‍♀️',
    name: 'woman swimming',
    shortname: ':woman_swimming:',
    unicode: '1F3CA 200D 2640 FE0F',
    html: '&#x1F3CA;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏻‍♀️',
    name: 'woman swimming: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3CA 1F3FB 200D 2640 FE0F',
    html: '&#x1F3CA;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏼‍♀️',
    name: 'woman swimming: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3CA 1F3FC 200D 2640 FE0F',
    html: '&#x1F3CA;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏽‍♀️',
    name: 'woman swimming: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3CA 1F3FD 200D 2640 FE0F',
    html: '&#x1F3CA;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏾‍♀️',
    name: 'woman swimming: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3CA 1F3FE 200D 2640 FE0F',
    html: '&#x1F3CA;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏊🏿‍♀️',
    name: 'woman swimming: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3CA 1F3FF 200D 2640 FE0F',
    html: '&#x1F3CA;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹️',
    name: 'person bouncing ball',
    shortname: ':person_bouncing_ball:',
    unicode: '26F9 FE0F',
    html: '&#x26F9;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹️‍♂️',
    name: 'man bouncing ball',
    shortname: ':man_bouncing_ball:',
    unicode: '26F9 FE0F 200D 2642 FE0F',
    html: '&#x26F9;&#xFE0F;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹‍♂️',
    name: 'man bouncing ball',
    shortname: ':man_bouncing_ball:',
    unicode: '26F9 200D 2642 FE0F',
    html: '&#x26F9;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹️‍♂',
    name: 'man bouncing ball',
    shortname: ':man_bouncing_ball:',
    unicode: '26F9 FE0F 200D 2642',
    html: '&#x26F9;&#xFE0F;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹‍♂',
    name: 'man bouncing ball',
    shortname: ':man_bouncing_ball:',
    unicode: '26F9 200D 2642',
    html: '&#x26F9;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏻‍♂️',
    name: 'man bouncing ball: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '26F9 1F3FB 200D 2642 FE0F',
    html: '&#x26F9;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏻‍♂',
    name: 'man bouncing ball: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '26F9 1F3FB 200D 2642',
    html: '&#x26F9;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏼‍♂️',
    name: 'man bouncing ball: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '26F9 1F3FC 200D 2642 FE0F',
    html: '&#x26F9;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏼‍♂',
    name: 'man bouncing ball: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '26F9 1F3FC 200D 2642',
    html: '&#x26F9;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏽‍♂️',
    name: 'man bouncing ball: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '26F9 1F3FD 200D 2642 FE0F',
    html: '&#x26F9;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏽‍♂',
    name: 'man bouncing ball: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '26F9 1F3FD 200D 2642',
    html: '&#x26F9;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏾‍♂️',
    name: 'man bouncing ball: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '26F9 1F3FE 200D 2642 FE0F',
    html: '&#x26F9;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏾‍♂',
    name: 'man bouncing ball: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '26F9 1F3FE 200D 2642',
    html: '&#x26F9;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏿‍♂️',
    name: 'man bouncing ball: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '26F9 1F3FF 200D 2642 FE0F',
    html: '&#x26F9;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏿‍♂',
    name: 'man bouncing ball: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '26F9 1F3FF 200D 2642',
    html: '&#x26F9;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹️‍♀️',
    name: 'woman bouncing ball',
    shortname: ':woman_bouncing_ball:',
    unicode: '26F9 FE0F 200D 2640 FE0F',
    html: '&#x26F9;&#xFE0F;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹‍♀️',
    name: 'woman bouncing ball',
    shortname: ':woman_bouncing_ball:',
    unicode: '26F9 200D 2640 FE0F',
    html: '&#x26F9;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹️‍♀',
    name: 'woman bouncing ball',
    shortname: ':woman_bouncing_ball:',
    unicode: '26F9 FE0F 200D 2640',
    html: '&#x26F9;&#xFE0F;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏻‍♀️',
    name: 'woman bouncing ball: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '26F9 1F3FB 200D 2640 FE0F',
    html: '&#x26F9;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏼‍♀️',
    name: 'woman bouncing ball: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '26F9 1F3FC 200D 2640 FE0F',
    html: '&#x26F9;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏽‍♀️',
    name: 'woman bouncing ball: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '26F9 1F3FD 200D 2640 FE0F',
    html: '&#x26F9;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏾‍♀️',
    name: 'woman bouncing ball: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '26F9 1F3FE 200D 2640 FE0F',
    html: '&#x26F9;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '⛹🏿‍♀️',
    name: 'woman bouncing ball: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '26F9 1F3FF 200D 2640 FE0F',
    html: '&#x26F9;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋️',
    name: 'person lifting weights',
    shortname: ':person_lifting_weights:',
    unicode: '1F3CB FE0F',
    html: '&#x1F3CB;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋️‍♂️',
    name: 'man lifting weights',
    shortname: ':man_lifting_weights:',
    unicode: '1F3CB FE0F 200D 2642 FE0F',
    html: '&#x1F3CB;&#xFE0F;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋‍♂️',
    name: 'man lifting weights',
    shortname: ':man_lifting_weights:',
    unicode: '1F3CB 200D 2642 FE0F',
    html: '&#x1F3CB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋️‍♂',
    name: 'man lifting weights',
    shortname: ':man_lifting_weights:',
    unicode: '1F3CB FE0F 200D 2642',
    html: '&#x1F3CB;&#xFE0F;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋‍♂',
    name: 'man lifting weights',
    shortname: ':man_lifting_weights:',
    unicode: '1F3CB 200D 2642',
    html: '&#x1F3CB;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏻‍♂️',
    name: 'man lifting weights: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3CB 1F3FB 200D 2642 FE0F',
    html: '&#x1F3CB;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏻‍♂',
    name: 'man lifting weights: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3CB 1F3FB 200D 2642',
    html: '&#x1F3CB;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏼‍♂️',
    name: 'man lifting weights: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3CB 1F3FC 200D 2642 FE0F',
    html: '&#x1F3CB;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏼‍♂',
    name: 'man lifting weights: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3CB 1F3FC 200D 2642',
    html: '&#x1F3CB;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏽‍♂️',
    name: 'man lifting weights: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3CB 1F3FD 200D 2642 FE0F',
    html: '&#x1F3CB;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏽‍♂',
    name: 'man lifting weights: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3CB 1F3FD 200D 2642',
    html: '&#x1F3CB;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏾‍♂️',
    name: 'man lifting weights: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3CB 1F3FE 200D 2642 FE0F',
    html: '&#x1F3CB;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏾‍♂',
    name: 'man lifting weights: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3CB 1F3FE 200D 2642',
    html: '&#x1F3CB;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏿‍♂️',
    name: 'man lifting weights: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3CB 1F3FF 200D 2642 FE0F',
    html: '&#x1F3CB;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏿‍♂',
    name: 'man lifting weights: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3CB 1F3FF 200D 2642',
    html: '&#x1F3CB;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋️‍♀️',
    name: 'woman lifting weights',
    shortname: ':woman_lifting_weights:',
    unicode: '1F3CB FE0F 200D 2640 FE0F',
    html: '&#x1F3CB;&#xFE0F;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋‍♀️',
    name: 'woman lifting weights',
    shortname: ':woman_lifting_weights:',
    unicode: '1F3CB 200D 2640 FE0F',
    html: '&#x1F3CB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋️‍♀',
    name: 'woman lifting weights',
    shortname: ':woman_lifting_weights:',
    unicode: '1F3CB FE0F 200D 2640',
    html: '&#x1F3CB;&#xFE0F;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏻‍♀️',
    name: 'woman lifting weights: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3CB 1F3FB 200D 2640 FE0F',
    html: '&#x1F3CB;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏼‍♀️',
    name: 'woman lifting weights: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3CB 1F3FC 200D 2640 FE0F',
    html: '&#x1F3CB;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏽‍♀️',
    name: 'woman lifting weights: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3CB 1F3FD 200D 2640 FE0F',
    html: '&#x1F3CB;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏾‍♀️',
    name: 'woman lifting weights: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3CB 1F3FE 200D 2640 FE0F',
    html: '&#x1F3CB;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🏋🏿‍♀️',
    name: 'woman lifting weights: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3CB 1F3FF 200D 2640 FE0F',
    html: '&#x1F3CB;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴‍♂️',
    name: 'man biking',
    shortname: ':man_biking:',
    unicode: '1F6B4 200D 2642 FE0F',
    html: '&#x1F6B4;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴‍♂',
    name: 'man biking',
    shortname: ':man_biking:',
    unicode: '1F6B4 200D 2642',
    html: '&#x1F6B4;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏻‍♂️',
    name: 'man biking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B4 1F3FB 200D 2642 FE0F',
    html: '&#x1F6B4;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏻‍♂',
    name: 'man biking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B4 1F3FB 200D 2642',
    html: '&#x1F6B4;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏼‍♂️',
    name: 'man biking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B4 1F3FC 200D 2642 FE0F',
    html: '&#x1F6B4;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏼‍♂',
    name: 'man biking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B4 1F3FC 200D 2642',
    html: '&#x1F6B4;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏽‍♂️',
    name: 'man biking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B4 1F3FD 200D 2642 FE0F',
    html: '&#x1F6B4;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏽‍♂',
    name: 'man biking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B4 1F3FD 200D 2642',
    html: '&#x1F6B4;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏾‍♂️',
    name: 'man biking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B4 1F3FE 200D 2642 FE0F',
    html: '&#x1F6B4;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏾‍♂',
    name: 'man biking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B4 1F3FE 200D 2642',
    html: '&#x1F6B4;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏿‍♂️',
    name: 'man biking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B4 1F3FF 200D 2642 FE0F',
    html: '&#x1F6B4;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏿‍♂',
    name: 'man biking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B4 1F3FF 200D 2642',
    html: '&#x1F6B4;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴‍♀️',
    name: 'woman biking',
    shortname: ':woman_biking:',
    unicode: '1F6B4 200D 2640 FE0F',
    html: '&#x1F6B4;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏻‍♀️',
    name: 'woman biking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B4 1F3FB 200D 2640 FE0F',
    html: '&#x1F6B4;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏼‍♀️',
    name: 'woman biking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B4 1F3FC 200D 2640 FE0F',
    html: '&#x1F6B4;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏽‍♀️',
    name: 'woman biking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B4 1F3FD 200D 2640 FE0F',
    html: '&#x1F6B4;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏾‍♀️',
    name: 'woman biking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B4 1F3FE 200D 2640 FE0F',
    html: '&#x1F6B4;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚴🏿‍♀️',
    name: 'woman biking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B4 1F3FF 200D 2640 FE0F',
    html: '&#x1F6B4;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵‍♂️',
    name: 'man mountain biking',
    shortname: ':man_mountain_biking:',
    unicode: '1F6B5 200D 2642 FE0F',
    html: '&#x1F6B5;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵‍♂',
    name: 'man mountain biking',
    shortname: ':man_mountain_biking:',
    unicode: '1F6B5 200D 2642',
    html: '&#x1F6B5;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏻‍♂️',
    name: 'man mountain biking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B5 1F3FB 200D 2642 FE0F',
    html: '&#x1F6B5;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏻‍♂',
    name: 'man mountain biking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B5 1F3FB 200D 2642',
    html: '&#x1F6B5;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏼‍♂️',
    name: 'man mountain biking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B5 1F3FC 200D 2642 FE0F',
    html: '&#x1F6B5;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏼‍♂',
    name: 'man mountain biking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B5 1F3FC 200D 2642',
    html: '&#x1F6B5;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏽‍♂️',
    name: 'man mountain biking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B5 1F3FD 200D 2642 FE0F',
    html: '&#x1F6B5;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏽‍♂',
    name: 'man mountain biking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B5 1F3FD 200D 2642',
    html: '&#x1F6B5;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏾‍♂️',
    name: 'man mountain biking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B5 1F3FE 200D 2642 FE0F',
    html: '&#x1F6B5;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏾‍♂',
    name: 'man mountain biking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B5 1F3FE 200D 2642',
    html: '&#x1F6B5;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏿‍♂️',
    name: 'man mountain biking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B5 1F3FF 200D 2642 FE0F',
    html: '&#x1F6B5;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏿‍♂',
    name: 'man mountain biking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B5 1F3FF 200D 2642',
    html: '&#x1F6B5;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵‍♀️',
    name: 'woman mountain biking',
    shortname: ':woman_mountain_biking:',
    unicode: '1F6B5 200D 2640 FE0F',
    html: '&#x1F6B5;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏻‍♀️',
    name: 'woman mountain biking: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6B5 1F3FB 200D 2640 FE0F',
    html: '&#x1F6B5;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏼‍♀️',
    name: 'woman mountain biking: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6B5 1F3FC 200D 2640 FE0F',
    html: '&#x1F6B5;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏽‍♀️',
    name: 'woman mountain biking: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6B5 1F3FD 200D 2640 FE0F',
    html: '&#x1F6B5;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏾‍♀️',
    name: 'woman mountain biking: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6B5 1F3FE 200D 2640 FE0F',
    html: '&#x1F6B5;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🚵🏿‍♀️',
    name: 'woman mountain biking: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6B5 1F3FF 200D 2640 FE0F',
    html: '&#x1F6B5;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸',
    name: 'person cartwheeling',
    shortname: ':person_cartwheeling:',
    unicode: '1F938',
    html: '&#x1F938;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏻',
    name: 'person cartwheeling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F938 1F3FB',
    html: '&#x1F938;&#x1F3FB;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏼',
    name: 'person cartwheeling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F938 1F3FC',
    html: '&#x1F938;&#x1F3FC;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏽',
    name: 'person cartwheeling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F938 1F3FD',
    html: '&#x1F938;&#x1F3FD;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏾',
    name: 'person cartwheeling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F938 1F3FE',
    html: '&#x1F938;&#x1F3FE;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏿',
    name: 'person cartwheeling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F938 1F3FF',
    html: '&#x1F938;&#x1F3FF;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸‍♂️',
    name: 'man cartwheeling',
    shortname: ':man_cartwheeling:',
    unicode: '1F938 200D 2642 FE0F',
    html: '&#x1F938;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸‍♂',
    name: 'man cartwheeling',
    shortname: ':man_cartwheeling:',
    unicode: '1F938 200D 2642',
    html: '&#x1F938;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏻‍♂️',
    name: 'man cartwheeling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F938 1F3FB 200D 2642 FE0F',
    html: '&#x1F938;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏻‍♂',
    name: 'man cartwheeling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F938 1F3FB 200D 2642',
    html: '&#x1F938;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏼‍♂️',
    name: 'man cartwheeling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F938 1F3FC 200D 2642 FE0F',
    html: '&#x1F938;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏼‍♂',
    name: 'man cartwheeling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F938 1F3FC 200D 2642',
    html: '&#x1F938;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏽‍♂️',
    name: 'man cartwheeling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F938 1F3FD 200D 2642 FE0F',
    html: '&#x1F938;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏽‍♂',
    name: 'man cartwheeling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F938 1F3FD 200D 2642',
    html: '&#x1F938;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏾‍♂️',
    name: 'man cartwheeling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F938 1F3FE 200D 2642 FE0F',
    html: '&#x1F938;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏾‍♂',
    name: 'man cartwheeling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F938 1F3FE 200D 2642',
    html: '&#x1F938;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏿‍♂️',
    name: 'man cartwheeling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F938 1F3FF 200D 2642 FE0F',
    html: '&#x1F938;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏿‍♂',
    name: 'man cartwheeling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F938 1F3FF 200D 2642',
    html: '&#x1F938;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸‍♀️',
    name: 'woman cartwheeling',
    shortname: ':woman_cartwheeling:',
    unicode: '1F938 200D 2640 FE0F',
    html: '&#x1F938;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸‍♀',
    name: 'woman cartwheeling',
    shortname: ':woman_cartwheeling:',
    unicode: '1F938 200D 2640',
    html: '&#x1F938;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏻‍♀️',
    name: 'woman cartwheeling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F938 1F3FB 200D 2640 FE0F',
    html: '&#x1F938;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏻‍♀',
    name: 'woman cartwheeling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F938 1F3FB 200D 2640',
    html: '&#x1F938;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏼‍♀️',
    name: 'woman cartwheeling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F938 1F3FC 200D 2640 FE0F',
    html: '&#x1F938;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏼‍♀',
    name: 'woman cartwheeling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F938 1F3FC 200D 2640',
    html: '&#x1F938;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏽‍♀️',
    name: 'woman cartwheeling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F938 1F3FD 200D 2640 FE0F',
    html: '&#x1F938;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏽‍♀',
    name: 'woman cartwheeling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F938 1F3FD 200D 2640',
    html: '&#x1F938;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏾‍♀️',
    name: 'woman cartwheeling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F938 1F3FE 200D 2640 FE0F',
    html: '&#x1F938;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏾‍♀',
    name: 'woman cartwheeling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F938 1F3FE 200D 2640',
    html: '&#x1F938;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏿‍♀️',
    name: 'woman cartwheeling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F938 1F3FF 200D 2640 FE0F',
    html: '&#x1F938;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤸🏿‍♀',
    name: 'woman cartwheeling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F938 1F3FF 200D 2640',
    html: '&#x1F938;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤼',
    name: 'people wrestling',
    shortname: ':people_wrestling:',
    unicode: '1F93C',
    html: '&#x1F93C;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤼‍♂️',
    name: 'men wrestling',
    shortname: ':men_wrestling:',
    unicode: '1F93C 200D 2642 FE0F',
    html: '&#x1F93C;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤼‍♂',
    name: 'men wrestling',
    shortname: ':men_wrestling:',
    unicode: '1F93C 200D 2642',
    html: '&#x1F93C;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤼‍♀️',
    name: 'women wrestling',
    shortname: ':women_wrestling:',
    unicode: '1F93C 200D 2640 FE0F',
    html: '&#x1F93C;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤼‍♀',
    name: 'women wrestling',
    shortname: ':women_wrestling:',
    unicode: '1F93C 200D 2640',
    html: '&#x1F93C;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽',
    name: 'person playing water polo',
    shortname: ':person_playing_water_polo:',
    unicode: '1F93D',
    html: '&#x1F93D;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏻',
    name: 'person playing water polo: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F93D 1F3FB',
    html: '&#x1F93D;&#x1F3FB;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏼',
    name: 'person playing water polo: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F93D 1F3FC',
    html: '&#x1F93D;&#x1F3FC;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏽',
    name: 'person playing water polo: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F93D 1F3FD',
    html: '&#x1F93D;&#x1F3FD;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏾',
    name: 'person playing water polo: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F93D 1F3FE',
    html: '&#x1F93D;&#x1F3FE;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏿',
    name: 'person playing water polo: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F93D 1F3FF',
    html: '&#x1F93D;&#x1F3FF;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽‍♂️',
    name: 'man playing water polo',
    shortname: ':man_playing_water_polo:',
    unicode: '1F93D 200D 2642 FE0F',
    html: '&#x1F93D;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽‍♂',
    name: 'man playing water polo',
    shortname: ':man_playing_water_polo:',
    unicode: '1F93D 200D 2642',
    html: '&#x1F93D;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏻‍♂️',
    name: 'man playing water polo: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F93D 1F3FB 200D 2642 FE0F',
    html: '&#x1F93D;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏻‍♂',
    name: 'man playing water polo: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F93D 1F3FB 200D 2642',
    html: '&#x1F93D;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏼‍♂️',
    name: 'man playing water polo: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F93D 1F3FC 200D 2642 FE0F',
    html: '&#x1F93D;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏼‍♂',
    name: 'man playing water polo: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F93D 1F3FC 200D 2642',
    html: '&#x1F93D;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏽‍♂️',
    name: 'man playing water polo: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F93D 1F3FD 200D 2642 FE0F',
    html: '&#x1F93D;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏽‍♂',
    name: 'man playing water polo: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F93D 1F3FD 200D 2642',
    html: '&#x1F93D;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏾‍♂️',
    name: 'man playing water polo: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F93D 1F3FE 200D 2642 FE0F',
    html: '&#x1F93D;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏾‍♂',
    name: 'man playing water polo: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F93D 1F3FE 200D 2642',
    html: '&#x1F93D;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏿‍♂️',
    name: 'man playing water polo: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F93D 1F3FF 200D 2642 FE0F',
    html: '&#x1F93D;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏿‍♂',
    name: 'man playing water polo: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F93D 1F3FF 200D 2642',
    html: '&#x1F93D;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽‍♀️',
    name: 'woman playing water polo',
    shortname: ':woman_playing_water_polo:',
    unicode: '1F93D 200D 2640 FE0F',
    html: '&#x1F93D;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽‍♀',
    name: 'woman playing water polo',
    shortname: ':woman_playing_water_polo:',
    unicode: '1F93D 200D 2640',
    html: '&#x1F93D;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏻‍♀️',
    name: 'woman playing water polo: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F93D 1F3FB 200D 2640 FE0F',
    html: '&#x1F93D;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏻‍♀',
    name: 'woman playing water polo: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F93D 1F3FB 200D 2640',
    html: '&#x1F93D;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏼‍♀️',
    name: 'woman playing water polo: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F93D 1F3FC 200D 2640 FE0F',
    html: '&#x1F93D;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏼‍♀',
    name: 'woman playing water polo: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F93D 1F3FC 200D 2640',
    html: '&#x1F93D;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏽‍♀️',
    name: 'woman playing water polo: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F93D 1F3FD 200D 2640 FE0F',
    html: '&#x1F93D;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏽‍♀',
    name: 'woman playing water polo: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F93D 1F3FD 200D 2640',
    html: '&#x1F93D;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏾‍♀️',
    name: 'woman playing water polo: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F93D 1F3FE 200D 2640 FE0F',
    html: '&#x1F93D;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏾‍♀',
    name: 'woman playing water polo: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F93D 1F3FE 200D 2640',
    html: '&#x1F93D;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏿‍♀️',
    name: 'woman playing water polo: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F93D 1F3FF 200D 2640 FE0F',
    html: '&#x1F93D;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤽🏿‍♀',
    name: 'woman playing water polo: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F93D 1F3FF 200D 2640',
    html: '&#x1F93D;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾',
    name: 'person playing handball',
    shortname: ':person_playing_handball:',
    unicode: '1F93E',
    html: '&#x1F93E;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏻',
    name: 'person playing handball: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F93E 1F3FB',
    html: '&#x1F93E;&#x1F3FB;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏼',
    name: 'person playing handball: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F93E 1F3FC',
    html: '&#x1F93E;&#x1F3FC;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏽',
    name: 'person playing handball: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F93E 1F3FD',
    html: '&#x1F93E;&#x1F3FD;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏾',
    name: 'person playing handball: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F93E 1F3FE',
    html: '&#x1F93E;&#x1F3FE;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏿',
    name: 'person playing handball: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F93E 1F3FF',
    html: '&#x1F93E;&#x1F3FF;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾‍♂️',
    name: 'man playing handball',
    shortname: ':man_playing_handball:',
    unicode: '1F93E 200D 2642 FE0F',
    html: '&#x1F93E;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾‍♂',
    name: 'man playing handball',
    shortname: ':man_playing_handball:',
    unicode: '1F93E 200D 2642',
    html: '&#x1F93E;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏻‍♂️',
    name: 'man playing handball: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F93E 1F3FB 200D 2642 FE0F',
    html: '&#x1F93E;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏻‍♂',
    name: 'man playing handball: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F93E 1F3FB 200D 2642',
    html: '&#x1F93E;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏼‍♂️',
    name: 'man playing handball: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F93E 1F3FC 200D 2642 FE0F',
    html: '&#x1F93E;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏼‍♂',
    name: 'man playing handball: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F93E 1F3FC 200D 2642',
    html: '&#x1F93E;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏽‍♂️',
    name: 'man playing handball: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F93E 1F3FD 200D 2642 FE0F',
    html: '&#x1F93E;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏽‍♂',
    name: 'man playing handball: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F93E 1F3FD 200D 2642',
    html: '&#x1F93E;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏾‍♂️',
    name: 'man playing handball: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F93E 1F3FE 200D 2642 FE0F',
    html: '&#x1F93E;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏾‍♂',
    name: 'man playing handball: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F93E 1F3FE 200D 2642',
    html: '&#x1F93E;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏿‍♂️',
    name: 'man playing handball: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F93E 1F3FF 200D 2642 FE0F',
    html: '&#x1F93E;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏿‍♂',
    name: 'man playing handball: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F93E 1F3FF 200D 2642',
    html: '&#x1F93E;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾‍♀️',
    name: 'woman playing handball',
    shortname: ':woman_playing_handball:',
    unicode: '1F93E 200D 2640 FE0F',
    html: '&#x1F93E;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾‍♀',
    name: 'woman playing handball',
    shortname: ':woman_playing_handball:',
    unicode: '1F93E 200D 2640',
    html: '&#x1F93E;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏻‍♀️',
    name: 'woman playing handball: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F93E 1F3FB 200D 2640 FE0F',
    html: '&#x1F93E;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏻‍♀',
    name: 'woman playing handball: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F93E 1F3FB 200D 2640',
    html: '&#x1F93E;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏼‍♀️',
    name: 'woman playing handball: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F93E 1F3FC 200D 2640 FE0F',
    html: '&#x1F93E;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏼‍♀',
    name: 'woman playing handball: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F93E 1F3FC 200D 2640',
    html: '&#x1F93E;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏽‍♀️',
    name: 'woman playing handball: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F93E 1F3FD 200D 2640 FE0F',
    html: '&#x1F93E;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏽‍♀',
    name: 'woman playing handball: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F93E 1F3FD 200D 2640',
    html: '&#x1F93E;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏾‍♀️',
    name: 'woman playing handball: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F93E 1F3FE 200D 2640 FE0F',
    html: '&#x1F93E;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏾‍♀',
    name: 'woman playing handball: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F93E 1F3FE 200D 2640',
    html: '&#x1F93E;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏿‍♀️',
    name: 'woman playing handball: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F93E 1F3FF 200D 2640 FE0F',
    html: '&#x1F93E;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤾🏿‍♀',
    name: 'woman playing handball: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F93E 1F3FF 200D 2640',
    html: '&#x1F93E;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹',
    name: 'person juggling',
    shortname: ':person_juggling:',
    unicode: '1F939',
    html: '&#x1F939;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏻',
    name: 'person juggling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F939 1F3FB',
    html: '&#x1F939;&#x1F3FB;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏼',
    name: 'person juggling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F939 1F3FC',
    html: '&#x1F939;&#x1F3FC;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏽',
    name: 'person juggling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F939 1F3FD',
    html: '&#x1F939;&#x1F3FD;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏾',
    name: 'person juggling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F939 1F3FE',
    html: '&#x1F939;&#x1F3FE;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏿',
    name: 'person juggling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F939 1F3FF',
    html: '&#x1F939;&#x1F3FF;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹‍♂️',
    name: 'man juggling',
    shortname: ':man_juggling:',
    unicode: '1F939 200D 2642 FE0F',
    html: '&#x1F939;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹‍♂',
    name: 'man juggling',
    shortname: ':man_juggling:',
    unicode: '1F939 200D 2642',
    html: '&#x1F939;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏻‍♂️',
    name: 'man juggling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F939 1F3FB 200D 2642 FE0F',
    html: '&#x1F939;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏻‍♂',
    name: 'man juggling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F939 1F3FB 200D 2642',
    html: '&#x1F939;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏼‍♂️',
    name: 'man juggling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F939 1F3FC 200D 2642 FE0F',
    html: '&#x1F939;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏼‍♂',
    name: 'man juggling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F939 1F3FC 200D 2642',
    html: '&#x1F939;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏽‍♂️',
    name: 'man juggling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F939 1F3FD 200D 2642 FE0F',
    html: '&#x1F939;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏽‍♂',
    name: 'man juggling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F939 1F3FD 200D 2642',
    html: '&#x1F939;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏾‍♂️',
    name: 'man juggling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F939 1F3FE 200D 2642 FE0F',
    html: '&#x1F939;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏾‍♂',
    name: 'man juggling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F939 1F3FE 200D 2642',
    html: '&#x1F939;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏿‍♂️',
    name: 'man juggling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F939 1F3FF 200D 2642 FE0F',
    html: '&#x1F939;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏿‍♂',
    name: 'man juggling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F939 1F3FF 200D 2642',
    html: '&#x1F939;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹‍♀️',
    name: 'woman juggling',
    shortname: ':woman_juggling:',
    unicode: '1F939 200D 2640 FE0F',
    html: '&#x1F939;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹‍♀',
    name: 'woman juggling',
    shortname: ':woman_juggling:',
    unicode: '1F939 200D 2640',
    html: '&#x1F939;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏻‍♀️',
    name: 'woman juggling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F939 1F3FB 200D 2640 FE0F',
    html: '&#x1F939;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏻‍♀',
    name: 'woman juggling: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F939 1F3FB 200D 2640',
    html: '&#x1F939;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏼‍♀️',
    name: 'woman juggling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F939 1F3FC 200D 2640 FE0F',
    html: '&#x1F939;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏼‍♀',
    name: 'woman juggling: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F939 1F3FC 200D 2640',
    html: '&#x1F939;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏽‍♀️',
    name: 'woman juggling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F939 1F3FD 200D 2640 FE0F',
    html: '&#x1F939;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏽‍♀',
    name: 'woman juggling: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F939 1F3FD 200D 2640',
    html: '&#x1F939;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏾‍♀️',
    name: 'woman juggling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F939 1F3FE 200D 2640 FE0F',
    html: '&#x1F939;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏾‍♀',
    name: 'woman juggling: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F939 1F3FE 200D 2640',
    html: '&#x1F939;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏿‍♀️',
    name: 'woman juggling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F939 1F3FF 200D 2640 FE0F',
    html: '&#x1F939;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🤹🏿‍♀',
    name: 'woman juggling: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F939 1F3FF 200D 2640',
    html: '&#x1F939;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-sport)',
    order: '',
  },
  {
    emoji: '🧘',
    name: 'person in lotus position',
    shortname: ':person_in_lotus_position:',
    unicode: '1F9D8',
    html: '&#x1F9D8;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏻',
    name: 'person in lotus position: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D8 1F3FB',
    html: '&#x1F9D8;&#x1F3FB;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏼',
    name: 'person in lotus position: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D8 1F3FC',
    html: '&#x1F9D8;&#x1F3FC;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏽',
    name: 'person in lotus position: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D8 1F3FD',
    html: '&#x1F9D8;&#x1F3FD;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏾',
    name: 'person in lotus position: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D8 1F3FE',
    html: '&#x1F9D8;&#x1F3FE;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏿',
    name: 'person in lotus position: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D8 1F3FF',
    html: '&#x1F9D8;&#x1F3FF;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘‍♂️',
    name: 'man in lotus position',
    shortname: ':man_in_lotus_position:',
    unicode: '1F9D8 200D 2642 FE0F',
    html: '&#x1F9D8;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘‍♂',
    name: 'man in lotus position',
    shortname: ':man_in_lotus_position:',
    unicode: '1F9D8 200D 2642',
    html: '&#x1F9D8;&#x200D;&#x2642;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏻‍♂️',
    name: 'man in lotus position: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D8 1F3FB 200D 2642 FE0F',
    html: '&#x1F9D8;&#x1F3FB;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏻‍♂',
    name: 'man in lotus position: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D8 1F3FB 200D 2642',
    html: '&#x1F9D8;&#x1F3FB;&#x200D;&#x2642;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏼‍♂️',
    name: 'man in lotus position: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D8 1F3FC 200D 2642 FE0F',
    html: '&#x1F9D8;&#x1F3FC;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏼‍♂',
    name: 'man in lotus position: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D8 1F3FC 200D 2642',
    html: '&#x1F9D8;&#x1F3FC;&#x200D;&#x2642;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏽‍♂️',
    name: 'man in lotus position: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D8 1F3FD 200D 2642 FE0F',
    html: '&#x1F9D8;&#x1F3FD;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏽‍♂',
    name: 'man in lotus position: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D8 1F3FD 200D 2642',
    html: '&#x1F9D8;&#x1F3FD;&#x200D;&#x2642;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏾‍♂️',
    name: 'man in lotus position: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D8 1F3FE 200D 2642 FE0F',
    html: '&#x1F9D8;&#x1F3FE;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏾‍♂',
    name: 'man in lotus position: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D8 1F3FE 200D 2642',
    html: '&#x1F9D8;&#x1F3FE;&#x200D;&#x2642;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏿‍♂️',
    name: 'man in lotus position: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D8 1F3FF 200D 2642 FE0F',
    html: '&#x1F9D8;&#x1F3FF;&#x200D;&#x2642;&#xFE0F;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏿‍♂',
    name: 'man in lotus position: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D8 1F3FF 200D 2642',
    html: '&#x1F9D8;&#x1F3FF;&#x200D;&#x2642;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘‍♀️',
    name: 'woman in lotus position',
    shortname: ':woman_in_lotus_position:',
    unicode: '1F9D8 200D 2640 FE0F',
    html: '&#x1F9D8;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘‍♀',
    name: 'woman in lotus position',
    shortname: ':woman_in_lotus_position:',
    unicode: '1F9D8 200D 2640',
    html: '&#x1F9D8;&#x200D;&#x2640;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏻‍♀️',
    name: 'woman in lotus position: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D8 1F3FB 200D 2640 FE0F',
    html: '&#x1F9D8;&#x1F3FB;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏻‍♀',
    name: 'woman in lotus position: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D8 1F3FB 200D 2640',
    html: '&#x1F9D8;&#x1F3FB;&#x200D;&#x2640;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏼‍♀️',
    name: 'woman in lotus position: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D8 1F3FC 200D 2640 FE0F',
    html: '&#x1F9D8;&#x1F3FC;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏼‍♀',
    name: 'woman in lotus position: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D8 1F3FC 200D 2640',
    html: '&#x1F9D8;&#x1F3FC;&#x200D;&#x2640;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏽‍♀️',
    name: 'woman in lotus position: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D8 1F3FD 200D 2640 FE0F',
    html: '&#x1F9D8;&#x1F3FD;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏽‍♀',
    name: 'woman in lotus position: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D8 1F3FD 200D 2640',
    html: '&#x1F9D8;&#x1F3FD;&#x200D;&#x2640;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏾‍♀️',
    name: 'woman in lotus position: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D8 1F3FE 200D 2640 FE0F',
    html: '&#x1F9D8;&#x1F3FE;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏾‍♀',
    name: 'woman in lotus position: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D8 1F3FE 200D 2640',
    html: '&#x1F9D8;&#x1F3FE;&#x200D;&#x2640;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏿‍♀️',
    name: 'woman in lotus position: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D8 1F3FF 200D 2640 FE0F',
    html: '&#x1F9D8;&#x1F3FF;&#x200D;&#x2640;&#xFE0F;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧘🏿‍♀',
    name: 'woman in lotus position: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D8 1F3FF 200D 2640',
    html: '&#x1F9D8;&#x1F3FF;&#x200D;&#x2640;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🛌🏻',
    name: 'person in bed: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F6CC 1F3FB',
    html: '&#x1F6CC;&#x1F3FB;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🛌🏼',
    name: 'person in bed: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F6CC 1F3FC',
    html: '&#x1F6CC;&#x1F3FC;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🛌🏽',
    name: 'person in bed: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F6CC 1F3FD',
    html: '&#x1F6CC;&#x1F3FD;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🛌🏾',
    name: 'person in bed: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F6CC 1F3FE',
    html: '&#x1F6CC;&#x1F3FE;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🛌🏿',
    name: 'person in bed: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F6CC 1F3FF',
    html: '&#x1F6CC;&#x1F3FF;',
    category: 'People & Body (person-resting)',
    order: '',
  },
  {
    emoji: '🧑‍🤝‍🧑',
    name: 'people holding hands',
    shortname: ':people_holding_hands:',
    unicode: '1F9D1 200D 1F91D 200D 1F9D1',
    html: '&#x1F9D1;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🤝‍🧑🏻',
    name: 'people holding hands: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FB',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🤝‍🧑🏼',
    name: 'people holding hands: light skin tone, medium-light skin tone',
    shortname: ':light_skin_tone_mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FC',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🤝‍🧑🏽',
    name: 'people holding hands: light skin tone, medium skin tone',
    shortname: ':light_skin_tone_medium_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FD',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🤝‍🧑🏾',
    name: 'people holding hands: light skin tone, medium-dark skin tone',
    shortname: ':light_skin_tone_mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FE',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏻‍🤝‍🧑🏿',
    name: 'people holding hands: light skin tone, dark skin tone',
    shortname: ':light_skin_tone_dark_skin_tone:',
    unicode: '1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FF',
    html: '&#x1F9D1;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🤝‍🧑🏻',
    name: 'people holding hands: medium-light skin tone, light skin tone',
    shortname: ':mediumlight_skin_tone_light_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FB',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🤝‍🧑🏼',
    name: 'people holding hands: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FC',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🤝‍🧑🏽',
    name: 'people holding hands: medium-light skin tone, medium skin tone',
    shortname: ':mediumlight_skin_tone_medium_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FD',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🤝‍🧑🏾',
    name: 'people holding hands: medium-light skin tone, medium-dark skin tone',
    shortname: ':mediumlight_skin_tone_medium-dark_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FE',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏼‍🤝‍🧑🏿',
    name: 'people holding hands: medium-light skin tone, dark skin tone',
    shortname: ':mediumlight_skin_tone_dark_skin_tone:',
    unicode: '1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FF',
    html: '&#x1F9D1;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🤝‍🧑🏻',
    name: 'people holding hands: medium skin tone, light skin tone',
    shortname: ':medium_skin_tone_light_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FB',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🤝‍🧑🏼',
    name: 'people holding hands: medium skin tone, medium-light skin tone',
    shortname: ':medium_skin_tone_mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FC',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🤝‍🧑🏽',
    name: 'people holding hands: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FD',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🤝‍🧑🏾',
    name: 'people holding hands: medium skin tone, medium-dark skin tone',
    shortname: ':medium_skin_tone_mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FE',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏽‍🤝‍🧑🏿',
    name: 'people holding hands: medium skin tone, dark skin tone',
    shortname: ':medium_skin_tone_dark_skin_tone:',
    unicode: '1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FF',
    html: '&#x1F9D1;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🤝‍🧑🏻',
    name: 'people holding hands: medium-dark skin tone, light skin tone',
    shortname: ':mediumdark_skin_tone_light_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FB',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🤝‍🧑🏼',
    name: 'people holding hands: medium-dark skin tone, medium-light skin tone',
    shortname: ':mediumdark_skin_tone_medium-light_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FC',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🤝‍🧑🏽',
    name: 'people holding hands: medium-dark skin tone, medium skin tone',
    shortname: ':mediumdark_skin_tone_medium_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FD',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🤝‍🧑🏾',
    name: 'people holding hands: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FE',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏾‍🤝‍🧑🏿',
    name: 'people holding hands: medium-dark skin tone, dark skin tone',
    shortname: ':mediumdark_skin_tone_dark_skin_tone:',
    unicode: '1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FF',
    html: '&#x1F9D1;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🤝‍🧑🏻',
    name: 'people holding hands: dark skin tone, light skin tone',
    shortname: ':dark_skin_tone_light_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FB',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🤝‍🧑🏼',
    name: 'people holding hands: dark skin tone, medium-light skin tone',
    shortname: ':dark_skin_tone_mediumlight_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FC',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🤝‍🧑🏽',
    name: 'people holding hands: dark skin tone, medium skin tone',
    shortname: ':dark_skin_tone_medium_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FD',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🤝‍🧑🏾',
    name: 'people holding hands: dark skin tone, medium-dark skin tone',
    shortname: ':dark_skin_tone_mediumdark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FE',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🧑🏿‍🤝‍🧑🏿',
    name: 'people holding hands: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FF',
    html: '&#x1F9D1;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F9D1;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👭🏻',
    name: 'women holding hands: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F46D 1F3FB',
    html: '&#x1F46D;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏻‍🤝‍👩🏼',
    name: 'women holding hands: light skin tone, medium-light skin tone',
    shortname: ':light_skin_tone_mediumlight_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F91D 200D 1F469 1F3FC',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏻‍🤝‍👩🏽',
    name: 'women holding hands: light skin tone, medium skin tone',
    shortname: ':light_skin_tone_medium_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F91D 200D 1F469 1F3FD',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏻‍🤝‍👩🏾',
    name: 'women holding hands: light skin tone, medium-dark skin tone',
    shortname: ':light_skin_tone_mediumdark_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F91D 200D 1F469 1F3FE',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏻‍🤝‍👩🏿',
    name: 'women holding hands: light skin tone, dark skin tone',
    shortname: ':light_skin_tone_dark_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F91D 200D 1F469 1F3FF',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏼‍🤝‍👩🏻',
    name: 'women holding hands: medium-light skin tone, light skin tone',
    shortname: ':mediumlight_skin_tone_light_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F91D 200D 1F469 1F3FB',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👭🏼',
    name: 'women holding hands: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F46D 1F3FC',
    html: '&#x1F46D;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏼‍🤝‍👩🏽',
    name: 'women holding hands: medium-light skin tone, medium skin tone',
    shortname: ':mediumlight_skin_tone_medium_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F91D 200D 1F469 1F3FD',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏼‍🤝‍👩🏾',
    name: 'women holding hands: medium-light skin tone, medium-dark skin tone',
    shortname: ':mediumlight_skin_tone_medium-dark_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F91D 200D 1F469 1F3FE',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏼‍🤝‍👩🏿',
    name: 'women holding hands: medium-light skin tone, dark skin tone',
    shortname: ':mediumlight_skin_tone_dark_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F91D 200D 1F469 1F3FF',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏽‍🤝‍👩🏻',
    name: 'women holding hands: medium skin tone, light skin tone',
    shortname: ':medium_skin_tone_light_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F91D 200D 1F469 1F3FB',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏽‍🤝‍👩🏼',
    name: 'women holding hands: medium skin tone, medium-light skin tone',
    shortname: ':medium_skin_tone_mediumlight_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F91D 200D 1F469 1F3FC',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👭🏽',
    name: 'women holding hands: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F46D 1F3FD',
    html: '&#x1F46D;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏽‍🤝‍👩🏾',
    name: 'women holding hands: medium skin tone, medium-dark skin tone',
    shortname: ':medium_skin_tone_mediumdark_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F91D 200D 1F469 1F3FE',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏽‍🤝‍👩🏿',
    name: 'women holding hands: medium skin tone, dark skin tone',
    shortname: ':medium_skin_tone_dark_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F91D 200D 1F469 1F3FF',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏾‍🤝‍👩🏻',
    name: 'women holding hands: medium-dark skin tone, light skin tone',
    shortname: ':mediumdark_skin_tone_light_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F91D 200D 1F469 1F3FB',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏾‍🤝‍👩🏼',
    name: 'women holding hands: medium-dark skin tone, medium-light skin tone',
    shortname: ':mediumdark_skin_tone_medium-light_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F91D 200D 1F469 1F3FC',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏾‍🤝‍👩🏽',
    name: 'women holding hands: medium-dark skin tone, medium skin tone',
    shortname: ':mediumdark_skin_tone_medium_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F91D 200D 1F469 1F3FD',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👭🏾',
    name: 'women holding hands: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F46D 1F3FE',
    html: '&#x1F46D;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏾‍🤝‍👩🏿',
    name: 'women holding hands: medium-dark skin tone, dark skin tone',
    shortname: ':mediumdark_skin_tone_dark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F91D 200D 1F469 1F3FF',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏿‍🤝‍👩🏻',
    name: 'women holding hands: dark skin tone, light skin tone',
    shortname: ':dark_skin_tone_light_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F91D 200D 1F469 1F3FB',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏿‍🤝‍👩🏼',
    name: 'women holding hands: dark skin tone, medium-light skin tone',
    shortname: ':dark_skin_tone_mediumlight_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F91D 200D 1F469 1F3FC',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏿‍🤝‍👩🏽',
    name: 'women holding hands: dark skin tone, medium skin tone',
    shortname: ':dark_skin_tone_medium_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F91D 200D 1F469 1F3FD',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏿‍🤝‍👩🏾',
    name: 'women holding hands: dark skin tone, medium-dark skin tone',
    shortname: ':dark_skin_tone_mediumdark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F91D 200D 1F469 1F3FE',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F469;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👭🏿',
    name: 'women holding hands: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F46D 1F3FF',
    html: '&#x1F46D;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👫🏻',
    name: 'woman and man holding hands: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F46B 1F3FB',
    html: '&#x1F46B;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏻‍🤝‍👨🏼',
    name: 'woman and man holding hands: light skin tone, medium-light skin tone',
    shortname: ':light_skin_tone_mediumlight_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F91D 200D 1F468 1F3FC',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏻‍🤝‍👨🏽',
    name: 'woman and man holding hands: light skin tone, medium skin tone',
    shortname: ':light_skin_tone_medium_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F91D 200D 1F468 1F3FD',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏻‍🤝‍👨🏾',
    name: 'woman and man holding hands: light skin tone, medium-dark skin tone',
    shortname: ':light_skin_tone_mediumdark_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F91D 200D 1F468 1F3FE',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏻‍🤝‍👨🏿',
    name: 'woman and man holding hands: light skin tone, dark skin tone',
    shortname: ':light_skin_tone_dark_skin_tone:',
    unicode: '1F469 1F3FB 200D 1F91D 200D 1F468 1F3FF',
    html: '&#x1F469;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏼‍🤝‍👨🏻',
    name: 'woman and man holding hands: medium-light skin tone, light skin tone',
    shortname: ':mediumlight_skin_tone_light_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F91D 200D 1F468 1F3FB',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👫🏼',
    name: 'woman and man holding hands: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F46B 1F3FC',
    html: '&#x1F46B;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏼‍🤝‍👨🏽',
    name: 'woman and man holding hands: medium-light skin tone, medium skin tone',
    shortname: ':mediumlight_skin_tone_medium_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F91D 200D 1F468 1F3FD',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏼‍🤝‍👨🏾',
    name: 'woman and man holding hands: medium-light skin tone, medium-dark skin tone',
    shortname: ':mediumlight_skin_tone_medium-dark_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F91D 200D 1F468 1F3FE',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏼‍🤝‍👨🏿',
    name: 'woman and man holding hands: medium-light skin tone, dark skin tone',
    shortname: ':mediumlight_skin_tone_dark_skin_tone:',
    unicode: '1F469 1F3FC 200D 1F91D 200D 1F468 1F3FF',
    html: '&#x1F469;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏽‍🤝‍👨🏻',
    name: 'woman and man holding hands: medium skin tone, light skin tone',
    shortname: ':medium_skin_tone_light_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F91D 200D 1F468 1F3FB',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏽‍🤝‍👨🏼',
    name: 'woman and man holding hands: medium skin tone, medium-light skin tone',
    shortname: ':medium_skin_tone_mediumlight_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F91D 200D 1F468 1F3FC',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👫🏽',
    name: 'woman and man holding hands: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F46B 1F3FD',
    html: '&#x1F46B;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏽‍🤝‍👨🏾',
    name: 'woman and man holding hands: medium skin tone, medium-dark skin tone',
    shortname: ':medium_skin_tone_mediumdark_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F91D 200D 1F468 1F3FE',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏽‍🤝‍👨🏿',
    name: 'woman and man holding hands: medium skin tone, dark skin tone',
    shortname: ':medium_skin_tone_dark_skin_tone:',
    unicode: '1F469 1F3FD 200D 1F91D 200D 1F468 1F3FF',
    html: '&#x1F469;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏾‍🤝‍👨🏻',
    name: 'woman and man holding hands: medium-dark skin tone, light skin tone',
    shortname: ':mediumdark_skin_tone_light_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F91D 200D 1F468 1F3FB',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏾‍🤝‍👨🏼',
    name: 'woman and man holding hands: medium-dark skin tone, medium-light skin tone',
    shortname: ':mediumdark_skin_tone_medium-light_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F91D 200D 1F468 1F3FC',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏾‍🤝‍👨🏽',
    name: 'woman and man holding hands: medium-dark skin tone, medium skin tone',
    shortname: ':mediumdark_skin_tone_medium_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F91D 200D 1F468 1F3FD',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👫🏾',
    name: 'woman and man holding hands: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F46B 1F3FE',
    html: '&#x1F46B;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏾‍🤝‍👨🏿',
    name: 'woman and man holding hands: medium-dark skin tone, dark skin tone',
    shortname: ':mediumdark_skin_tone_dark_skin_tone:',
    unicode: '1F469 1F3FE 200D 1F91D 200D 1F468 1F3FF',
    html: '&#x1F469;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏿‍🤝‍👨🏻',
    name: 'woman and man holding hands: dark skin tone, light skin tone',
    shortname: ':dark_skin_tone_light_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F91D 200D 1F468 1F3FB',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏿‍🤝‍👨🏼',
    name: 'woman and man holding hands: dark skin tone, medium-light skin tone',
    shortname: ':dark_skin_tone_mediumlight_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F91D 200D 1F468 1F3FC',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏿‍🤝‍👨🏽',
    name: 'woman and man holding hands: dark skin tone, medium skin tone',
    shortname: ':dark_skin_tone_medium_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F91D 200D 1F468 1F3FD',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩🏿‍🤝‍👨🏾',
    name: 'woman and man holding hands: dark skin tone, medium-dark skin tone',
    shortname: ':dark_skin_tone_mediumdark_skin_tone:',
    unicode: '1F469 1F3FF 200D 1F91D 200D 1F468 1F3FE',
    html: '&#x1F469;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👫🏿',
    name: 'woman and man holding hands: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F46B 1F3FF',
    html: '&#x1F46B;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👬🏻',
    name: 'men holding hands: light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F46C 1F3FB',
    html: '&#x1F46C;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏻‍🤝‍👨🏼',
    name: 'men holding hands: light skin tone, medium-light skin tone',
    shortname: ':light_skin_tone_mediumlight_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F91D 200D 1F468 1F3FC',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏻‍🤝‍👨🏽',
    name: 'men holding hands: light skin tone, medium skin tone',
    shortname: ':light_skin_tone_medium_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F91D 200D 1F468 1F3FD',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏻‍🤝‍👨🏾',
    name: 'men holding hands: light skin tone, medium-dark skin tone',
    shortname: ':light_skin_tone_mediumdark_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F91D 200D 1F468 1F3FE',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏻‍🤝‍👨🏿',
    name: 'men holding hands: light skin tone, dark skin tone',
    shortname: ':light_skin_tone_dark_skin_tone:',
    unicode: '1F468 1F3FB 200D 1F91D 200D 1F468 1F3FF',
    html: '&#x1F468;&#x1F3FB;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏼‍🤝‍👨🏻',
    name: 'men holding hands: medium-light skin tone, light skin tone',
    shortname: ':mediumlight_skin_tone_light_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F91D 200D 1F468 1F3FB',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👬🏼',
    name: 'men holding hands: medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F46C 1F3FC',
    html: '&#x1F46C;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏼‍🤝‍👨🏽',
    name: 'men holding hands: medium-light skin tone, medium skin tone',
    shortname: ':mediumlight_skin_tone_medium_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F91D 200D 1F468 1F3FD',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏼‍🤝‍👨🏾',
    name: 'men holding hands: medium-light skin tone, medium-dark skin tone',
    shortname: ':mediumlight_skin_tone_medium-dark_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F91D 200D 1F468 1F3FE',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏼‍🤝‍👨🏿',
    name: 'men holding hands: medium-light skin tone, dark skin tone',
    shortname: ':mediumlight_skin_tone_dark_skin_tone:',
    unicode: '1F468 1F3FC 200D 1F91D 200D 1F468 1F3FF',
    html: '&#x1F468;&#x1F3FC;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏽‍🤝‍👨🏻',
    name: 'men holding hands: medium skin tone, light skin tone',
    shortname: ':medium_skin_tone_light_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F91D 200D 1F468 1F3FB',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏽‍🤝‍👨🏼',
    name: 'men holding hands: medium skin tone, medium-light skin tone',
    shortname: ':medium_skin_tone_mediumlight_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F91D 200D 1F468 1F3FC',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👬🏽',
    name: 'men holding hands: medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F46C 1F3FD',
    html: '&#x1F46C;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏽‍🤝‍👨🏾',
    name: 'men holding hands: medium skin tone, medium-dark skin tone',
    shortname: ':medium_skin_tone_mediumdark_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F91D 200D 1F468 1F3FE',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏽‍🤝‍👨🏿',
    name: 'men holding hands: medium skin tone, dark skin tone',
    shortname: ':medium_skin_tone_dark_skin_tone:',
    unicode: '1F468 1F3FD 200D 1F91D 200D 1F468 1F3FF',
    html: '&#x1F468;&#x1F3FD;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏾‍🤝‍👨🏻',
    name: 'men holding hands: medium-dark skin tone, light skin tone',
    shortname: ':mediumdark_skin_tone_light_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F91D 200D 1F468 1F3FB',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏾‍🤝‍👨🏼',
    name: 'men holding hands: medium-dark skin tone, medium-light skin tone',
    shortname: ':mediumdark_skin_tone_medium-light_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F91D 200D 1F468 1F3FC',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏾‍🤝‍👨🏽',
    name: 'men holding hands: medium-dark skin tone, medium skin tone',
    shortname: ':mediumdark_skin_tone_medium_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F91D 200D 1F468 1F3FD',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👬🏾',
    name: 'men holding hands: medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F46C 1F3FE',
    html: '&#x1F46C;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏾‍🤝‍👨🏿',
    name: 'men holding hands: medium-dark skin tone, dark skin tone',
    shortname: ':mediumdark_skin_tone_dark_skin_tone:',
    unicode: '1F468 1F3FE 200D 1F91D 200D 1F468 1F3FF',
    html: '&#x1F468;&#x1F3FE;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏿‍🤝‍👨🏻',
    name: 'men holding hands: dark skin tone, light skin tone',
    shortname: ':dark_skin_tone_light_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F91D 200D 1F468 1F3FB',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FB;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏿‍🤝‍👨🏼',
    name: 'men holding hands: dark skin tone, medium-light skin tone',
    shortname: ':dark_skin_tone_mediumlight_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F91D 200D 1F468 1F3FC',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FC;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏿‍🤝‍👨🏽',
    name: 'men holding hands: dark skin tone, medium skin tone',
    shortname: ':dark_skin_tone_medium_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F91D 200D 1F468 1F3FD',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FD;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨🏿‍🤝‍👨🏾',
    name: 'men holding hands: dark skin tone, medium-dark skin tone',
    shortname: ':dark_skin_tone_mediumdark_skin_tone:',
    unicode: '1F468 1F3FF 200D 1F91D 200D 1F468 1F3FE',
    html: '&#x1F468;&#x1F3FF;&#x200D;&#x1F91D;&#x200D;&#x1F468;&#x1F3FE;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👬🏿',
    name: 'men holding hands: dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F46C 1F3FF',
    html: '&#x1F46C;&#x1F3FF;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍❤️‍💋‍👨',
    name: 'kiss: woman, man',
    shortname: ':woman_man:',
    unicode: '1F469 200D 2764 FE0F 200D 1F48B 200D 1F468',
    html: '&#x1F469;&#x200D;&#x2764;&#xFE0F;&#x200D;&#x1F48B;&#x200D;&#x1F468;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍❤‍💋‍👨',
    name: 'kiss: woman, man',
    shortname: ':woman_man:',
    unicode: '1F469 200D 2764 200D 1F48B 200D 1F468',
    html: '&#x1F469;&#x200D;&#x2764;&#x200D;&#x1F48B;&#x200D;&#x1F468;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍❤‍💋‍👨',
    name: 'kiss: man, man',
    shortname: ':man_man:',
    unicode: '1F468 200D 2764 200D 1F48B 200D 1F468',
    html: '&#x1F468;&#x200D;&#x2764;&#x200D;&#x1F48B;&#x200D;&#x1F468;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍❤‍💋‍👩',
    name: 'kiss: woman, woman',
    shortname: ':woman_woman:',
    unicode: '1F469 200D 2764 200D 1F48B 200D 1F469',
    html: '&#x1F469;&#x200D;&#x2764;&#x200D;&#x1F48B;&#x200D;&#x1F469;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍❤️‍👨',
    name: 'couple with heart: woman, man',
    shortname: ':woman_man:',
    unicode: '1F469 200D 2764 FE0F 200D 1F468',
    html: '&#x1F469;&#x200D;&#x2764;&#xFE0F;&#x200D;&#x1F468;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍❤‍👨',
    name: 'couple with heart: woman, man',
    shortname: ':woman_man:',
    unicode: '1F469 200D 2764 200D 1F468',
    html: '&#x1F469;&#x200D;&#x2764;&#x200D;&#x1F468;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍❤‍👨',
    name: 'couple with heart: man, man',
    shortname: ':man_man:',
    unicode: '1F468 200D 2764 200D 1F468',
    html: '&#x1F468;&#x200D;&#x2764;&#x200D;&#x1F468;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👩‍❤‍👩',
    name: 'couple with heart: woman, woman',
    shortname: ':woman_woman:',
    unicode: '1F469 200D 2764 200D 1F469',
    html: '&#x1F469;&#x200D;&#x2764;&#x200D;&#x1F469;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '👨‍👩‍👦',
    name: 'family: man, woman, boy',
    shortname: ':man_woman_boy:',
    unicode: '1F468 200D 1F469 200D 1F466',
    html: '&#x1F468;&#x200D;&#x1F469;&#x200D;&#x1F466;',
    category: 'People & Body (family)',
    order: '',
  },
  {
    emoji: '🗣️',
    name: 'speaking head',
    shortname: ':speaking_head:',
    unicode: '1F5E3 FE0F',
    html: '&#x1F5E3;&#xFE0F;',
    category: 'People & Body (person-symbol)',
    order: '',
  },
  {
    emoji: '🏻',
    name: 'light skin tone',
    shortname: ':light_skin_tone:',
    unicode: '1F3FB',
    html: '&#x1F3FB;',
    category: 'Component (skin-tone)',
    order: '',
  },
  {
    emoji: '🏼',
    name: 'medium-light skin tone',
    shortname: ':mediumlight_skin_tone:',
    unicode: '1F3FC',
    html: '&#x1F3FC;',
    category: 'Component (skin-tone)',
    order: '',
  },
  {
    emoji: '🏽',
    name: 'medium skin tone',
    shortname: ':medium_skin_tone:',
    unicode: '1F3FD',
    html: '&#x1F3FD;',
    category: 'Component (skin-tone)',
    order: '',
  },
  {
    emoji: '🏾',
    name: 'medium-dark skin tone',
    shortname: ':mediumdark_skin_tone:',
    unicode: '1F3FE',
    html: '&#x1F3FE;',
    category: 'Component (skin-tone)',
    order: '',
  },
  {
    emoji: '🏿',
    name: 'dark skin tone',
    shortname: ':dark_skin_tone:',
    unicode: '1F3FF',
    html: '&#x1F3FF;',
    category: 'Component (skin-tone)',
    order: '',
  },
  {
    emoji: '🦰',
    name: 'red hair',
    shortname: ':red_hair:',
    unicode: '1F9B0',
    html: '&#x1F9B0;',
    category: 'Component (hair-style)',
    order: '',
  },
  {
    emoji: '🦱',
    name: 'curly hair',
    shortname: ':curly_hair:',
    unicode: '1F9B1',
    html: '&#x1F9B1;',
    category: 'Component (hair-style)',
    order: '',
  },
  {
    emoji: '🦳',
    name: 'white hair',
    shortname: ':white_hair:',
    unicode: '1F9B3',
    html: '&#x1F9B3;',
    category: 'Component (hair-style)',
    order: '',
  },
  {
    emoji: '🦲',
    name: 'bald',
    shortname: ':bald:',
    unicode: '1F9B2',
    html: '&#x1F9B2;',
    category: 'Component (hair-style)',
    order: '',
  },
  {
    emoji: '🦍',
    name: 'gorilla',
    shortname: ':gorilla:',
    unicode: '1F98D',
    html: '&#x1F98D;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦧',
    name: 'orangutan',
    shortname: ':orangutan:',
    unicode: '1F9A7',
    html: '&#x1F9A7;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦮',
    name: 'guide dog',
    shortname: ':guide_dog:',
    unicode: '1F9AE',
    html: '&#x1F9AE;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🐕‍🦺',
    name: 'service dog',
    shortname: ':service_dog:',
    unicode: '1F415 200D 1F9BA',
    html: '&#x1F415;&#x200D;&#x1F9BA;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦊',
    name: 'fox',
    shortname: ':fox:',
    unicode: '1F98A',
    html: '&#x1F98A;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦝',
    name: 'raccoon',
    shortname: ':raccoon:',
    unicode: '1F99D',
    html: '&#x1F99D;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦓',
    name: 'zebra',
    shortname: ':zebra:',
    unicode: '1F993',
    html: '&#x1F993;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦌',
    name: 'deer',
    shortname: ':deer:',
    unicode: '1F98C',
    html: '&#x1F98C;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦙',
    name: 'llama',
    shortname: ':llama:',
    unicode: '1F999',
    html: '&#x1F999;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦒',
    name: 'giraffe',
    shortname: ':giraffe:',
    unicode: '1F992',
    html: '&#x1F992;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦏',
    name: 'rhinoceros',
    shortname: ':rhinoceros:',
    unicode: '1F98F',
    html: '&#x1F98F;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦛',
    name: 'hippopotamus',
    shortname: ':hippopotamus:',
    unicode: '1F99B',
    html: '&#x1F99B;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🐿️',
    name: 'chipmunk',
    shortname: ':chipmunk:',
    unicode: '1F43F FE0F',
    html: '&#x1F43F;&#xFE0F;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦔',
    name: 'hedgehog',
    shortname: ':hedgehog:',
    unicode: '1F994',
    html: '&#x1F994;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦇',
    name: 'bat',
    shortname: ':bat:',
    unicode: '1F987',
    html: '&#x1F987;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦥',
    name: 'sloth',
    shortname: ':sloth:',
    unicode: '1F9A5',
    html: '&#x1F9A5;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦦',
    name: 'otter',
    shortname: ':otter:',
    unicode: '1F9A6',
    html: '&#x1F9A6;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦨',
    name: 'skunk',
    shortname: ':skunk:',
    unicode: '1F9A8',
    html: '&#x1F9A8;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦘',
    name: 'kangaroo',
    shortname: ':kangaroo:',
    unicode: '1F998',
    html: '&#x1F998;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🦡',
    name: 'badger',
    shortname: ':badger:',
    unicode: '1F9A1',
    html: '&#x1F9A1;',
    category: 'Animals & Nature (animal-mammal)',
    order: '',
  },
  {
    emoji: '🕊️',
    name: 'dove',
    shortname: ':dove:',
    unicode: '1F54A FE0F',
    html: '&#x1F54A;&#xFE0F;',
    category: 'Animals & Nature (animal-bird)',
    order: '',
  },
  {
    emoji: '🦅',
    name: 'eagle',
    shortname: ':eagle:',
    unicode: '1F985',
    html: '&#x1F985;',
    category: 'Animals & Nature (animal-bird)',
    order: '',
  },
  {
    emoji: '🦆',
    name: 'duck',
    shortname: ':duck:',
    unicode: '1F986',
    html: '&#x1F986;',
    category: 'Animals & Nature (animal-bird)',
    order: '',
  },
  {
    emoji: '🦢',
    name: 'swan',
    shortname: ':swan:',
    unicode: '1F9A2',
    html: '&#x1F9A2;',
    category: 'Animals & Nature (animal-bird)',
    order: '',
  },
  {
    emoji: '🦉',
    name: 'owl',
    shortname: ':owl:',
    unicode: '1F989',
    html: '&#x1F989;',
    category: 'Animals & Nature (animal-bird)',
    order: '',
  },
  {
    emoji: '🦩',
    name: 'flamingo',
    shortname: ':flamingo:',
    unicode: '1F9A9',
    html: '&#x1F9A9;',
    category: 'Animals & Nature (animal-bird)',
    order: '',
  },
  {
    emoji: '🦚',
    name: 'peacock',
    shortname: ':peacock:',
    unicode: '1F99A',
    html: '&#x1F99A;',
    category: 'Animals & Nature (animal-bird)',
    order: '',
  },
  {
    emoji: '🦜',
    name: 'parrot',
    shortname: ':parrot:',
    unicode: '1F99C',
    html: '&#x1F99C;',
    category: 'Animals & Nature (animal-bird)',
    order: '',
  },
  {
    emoji: '🦎',
    name: 'lizard',
    shortname: ':lizard:',
    unicode: '1F98E',
    html: '&#x1F98E;',
    category: 'Animals & Nature (animal-reptile)',
    order: '',
  },
  {
    emoji: '🦕',
    name: 'sauropod',
    shortname: ':sauropod:',
    unicode: '1F995',
    html: '&#x1F995;',
    category: 'Animals & Nature (animal-reptile)',
    order: '',
  },
  {
    emoji: '🦖',
    name: 'T-Rex',
    shortname: ':TRex:',
    unicode: '1F996',
    html: '&#x1F996;',
    category: 'Animals & Nature (animal-reptile)',
    order: '',
  },
  {
    emoji: '🦈',
    name: 'shark',
    shortname: ':shark:',
    unicode: '1F988',
    html: '&#x1F988;',
    category: 'Animals & Nature (animal-marine)',
    order: '',
  },
  {
    emoji: '🦋',
    name: 'butterfly',
    shortname: ':butterfly:',
    unicode: '1F98B',
    html: '&#x1F98B;',
    category: 'Animals & Nature (animal-bug)',
    order: '',
  },
  {
    emoji: '🦗',
    name: 'cricket',
    shortname: ':cricket:',
    unicode: '1F997',
    html: '&#x1F997;',
    category: 'Animals & Nature (animal-bug)',
    order: '',
  },
  {
    emoji: '🕷️',
    name: 'spider',
    shortname: ':spider:',
    unicode: '1F577 FE0F',
    html: '&#x1F577;&#xFE0F;',
    category: 'Animals & Nature (animal-bug)',
    order: '',
  },
  {
    emoji: '🕸️',
    name: 'spider web',
    shortname: ':spider_web:',
    unicode: '1F578 FE0F',
    html: '&#x1F578;&#xFE0F;',
    category: 'Animals & Nature (animal-bug)',
    order: '',
  },
  {
    emoji: '🦟',
    name: 'mosquito',
    shortname: ':mosquito:',
    unicode: '1F99F',
    html: '&#x1F99F;',
    category: 'Animals & Nature (animal-bug)',
    order: '',
  },
  {
    emoji: '🦠',
    name: 'microbe',
    shortname: ':microbe:',
    unicode: '1F9A0',
    html: '&#x1F9A0;',
    category: 'Animals & Nature (animal-bug)',
    order: '',
  },
  {
    emoji: '🏵️',
    name: 'rosette',
    shortname: ':rosette:',
    unicode: '1F3F5 FE0F',
    html: '&#x1F3F5;&#xFE0F;',
    category: 'Animals & Nature (plant-flower)',
    order: '',
  },
  {
    emoji: '🥀',
    name: 'wilted flower',
    shortname: ':wilted_flower:',
    unicode: '1F940',
    html: '&#x1F940;',
    category: 'Animals & Nature (plant-flower)',
    order: '',
  },
  {
    emoji: '☘️',
    name: 'shamrock',
    shortname: ':shamrock:',
    unicode: '2618 FE0F',
    html: '&#x2618;&#xFE0F;',
    category: 'Animals & Nature (plant-other)',
    order: '',
  },
  {
    emoji: '🥭',
    name: 'mango',
    shortname: ':mango:',
    unicode: '1F96D',
    html: '&#x1F96D;',
    category: 'Food & Drink (food-fruit)',
    order: '',
  },
  {
    emoji: '🥝',
    name: 'kiwi fruit',
    shortname: ':kiwi_fruit:',
    unicode: '1F95D',
    html: '&#x1F95D;',
    category: 'Food & Drink (food-fruit)',
    order: '',
  },
  {
    emoji: '🥥',
    name: 'coconut',
    shortname: ':coconut:',
    unicode: '1F965',
    html: '&#x1F965;',
    category: 'Food & Drink (food-fruit)',
    order: '',
  },
  {
    emoji: '🥑',
    name: 'avocado',
    shortname: ':avocado:',
    unicode: '1F951',
    html: '&#x1F951;',
    category: 'Food & Drink (food-vegetable)',
    order: '',
  },
  {
    emoji: '🥔',
    name: 'potato',
    shortname: ':potato:',
    unicode: '1F954',
    html: '&#x1F954;',
    category: 'Food & Drink (food-vegetable)',
    order: '',
  },
  {
    emoji: '🥕',
    name: 'carrot',
    shortname: ':carrot:',
    unicode: '1F955',
    html: '&#x1F955;',
    category: 'Food & Drink (food-vegetable)',
    order: '',
  },
  {
    emoji: '🌶️',
    name: 'hot pepper',
    shortname: ':hot_pepper:',
    unicode: '1F336 FE0F',
    html: '&#x1F336;&#xFE0F;',
    category: 'Food & Drink (food-vegetable)',
    order: '',
  },
  {
    emoji: '🥒',
    name: 'cucumber',
    shortname: ':cucumber:',
    unicode: '1F952',
    html: '&#x1F952;',
    category: 'Food & Drink (food-vegetable)',
    order: '',
  },
  {
    emoji: '🥬',
    name: 'leafy green',
    shortname: ':leafy_green:',
    unicode: '1F96C',
    html: '&#x1F96C;',
    category: 'Food & Drink (food-vegetable)',
    order: '',
  },
  {
    emoji: '🥦',
    name: 'broccoli',
    shortname: ':broccoli:',
    unicode: '1F966',
    html: '&#x1F966;',
    category: 'Food & Drink (food-vegetable)',
    order: '',
  },
  {
    emoji: '🧄',
    name: 'garlic',
    shortname: ':garlic:',
    unicode: '1F9C4',
    html: '&#x1F9C4;',
    category: 'Food & Drink (food-vegetable)',
    order: '',
  },
  {
    emoji: '🧅',
    name: 'onion',
    shortname: ':onion:',
    unicode: '1F9C5',
    html: '&#x1F9C5;',
    category: 'Food & Drink (food-vegetable)',
    order: '',
  },
  {
    emoji: '🥜',
    name: 'peanuts',
    shortname: ':peanuts:',
    unicode: '1F95C',
    html: '&#x1F95C;',
    category: 'Food & Drink (food-vegetable)',
    order: '',
  },
  {
    emoji: '🥐',
    name: 'croissant',
    shortname: ':croissant:',
    unicode: '1F950',
    html: '&#x1F950;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🥖',
    name: 'baguette bread',
    shortname: ':baguette_bread:',
    unicode: '1F956',
    html: '&#x1F956;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🥨',
    name: 'pretzel',
    shortname: ':pretzel:',
    unicode: '1F968',
    html: '&#x1F968;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🥯',
    name: 'bagel',
    shortname: ':bagel:',
    unicode: '1F96F',
    html: '&#x1F96F;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🥞',
    name: 'pancakes',
    shortname: ':pancakes:',
    unicode: '1F95E',
    html: '&#x1F95E;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🧇',
    name: 'waffle',
    shortname: ':waffle:',
    unicode: '1F9C7',
    html: '&#x1F9C7;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🥩',
    name: 'cut of meat',
    shortname: ':cut_of_meat:',
    unicode: '1F969',
    html: '&#x1F969;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🥓',
    name: 'bacon',
    shortname: ':bacon:',
    unicode: '1F953',
    html: '&#x1F953;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🥪',
    name: 'sandwich',
    shortname: ':sandwich:',
    unicode: '1F96A',
    html: '&#x1F96A;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🥙',
    name: 'stuffed flatbread',
    shortname: ':stuffed_flatbread:',
    unicode: '1F959',
    html: '&#x1F959;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🧆',
    name: 'falafel',
    shortname: ':falafel:',
    unicode: '1F9C6',
    html: '&#x1F9C6;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🥘',
    name: 'shallow pan of food',
    shortname: ':shallow_pan_of_food:',
    unicode: '1F958',
    html: '&#x1F958;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🥣',
    name: 'bowl with spoon',
    shortname: ':bowl_with_spoon:',
    unicode: '1F963',
    html: '&#x1F963;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🥗',
    name: 'green salad',
    shortname: ':green_salad:',
    unicode: '1F957',
    html: '&#x1F957;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🧈',
    name: 'butter',
    shortname: ':butter:',
    unicode: '1F9C8',
    html: '&#x1F9C8;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🧂',
    name: 'salt',
    shortname: ':salt:',
    unicode: '1F9C2',
    html: '&#x1F9C2;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🥫',
    name: 'canned food',
    shortname: ':canned_food:',
    unicode: '1F96B',
    html: '&#x1F96B;',
    category: 'Food & Drink (food-prepared)',
    order: '',
  },
  {
    emoji: '🥮',
    name: 'moon cake',
    shortname: ':moon_cake:',
    unicode: '1F96E',
    html: '&#x1F96E;',
    category: 'Food & Drink (food-asian)',
    order: '',
  },
  {
    emoji: '🥟',
    name: 'dumpling',
    shortname: ':dumpling:',
    unicode: '1F95F',
    html: '&#x1F95F;',
    category: 'Food & Drink (food-asian)',
    order: '',
  },
  {
    emoji: '🥠',
    name: 'fortune cookie',
    shortname: ':fortune_cookie:',
    unicode: '1F960',
    html: '&#x1F960;',
    category: 'Food & Drink (food-asian)',
    order: '',
  },
  {
    emoji: '🥡',
    name: 'takeout box',
    shortname: ':takeout_box:',
    unicode: '1F961',
    html: '&#x1F961;',
    category: 'Food & Drink (food-asian)',
    order: '',
  },
  {
    emoji: '🦞',
    name: 'lobster',
    shortname: ':lobster:',
    unicode: '1F99E',
    html: '&#x1F99E;',
    category: 'Food & Drink (food-marine)',
    order: '',
  },
  {
    emoji: '🦐',
    name: 'shrimp',
    shortname: ':shrimp:',
    unicode: '1F990',
    html: '&#x1F990;',
    category: 'Food & Drink (food-marine)',
    order: '',
  },
  {
    emoji: '🦑',
    name: 'squid',
    shortname: ':squid:',
    unicode: '1F991',
    html: '&#x1F991;',
    category: 'Food & Drink (food-marine)',
    order: '',
  },
  {
    emoji: '🦪',
    name: 'oyster',
    shortname: ':oyster:',
    unicode: '1F9AA',
    html: '&#x1F9AA;',
    category: 'Food & Drink (food-marine)',
    order: '',
  },
  {
    emoji: '🧁',
    name: 'cupcake',
    shortname: ':cupcake:',
    unicode: '1F9C1',
    html: '&#x1F9C1;',
    category: 'Food & Drink (food-sweet)',
    order: '',
  },
  {
    emoji: '🥧',
    name: 'pie',
    shortname: ':pie:',
    unicode: '1F967',
    html: '&#x1F967;',
    category: 'Food & Drink (food-sweet)',
    order: '',
  },
  {
    emoji: '🥛',
    name: 'glass of milk',
    shortname: ':glass_of_milk:',
    unicode: '1F95B',
    html: '&#x1F95B;',
    category: 'Food & Drink (drink)',
    order: '',
  },
  {
    emoji: '🥂',
    name: 'clinking glasses',
    shortname: ':clinking_glasses:',
    unicode: '1F942',
    html: '&#x1F942;',
    category: 'Food & Drink (drink)',
    order: '',
  },
  {
    emoji: '🥃',
    name: 'tumbler glass',
    shortname: ':tumbler_glass:',
    unicode: '1F943',
    html: '&#x1F943;',
    category: 'Food & Drink (drink)',
    order: '',
  },
  {
    emoji: '🥤',
    name: 'cup with straw',
    shortname: ':cup_with_straw:',
    unicode: '1F964',
    html: '&#x1F964;',
    category: 'Food & Drink (drink)',
    order: '',
  },
  {
    emoji: '🧃',
    name: 'beverage box',
    shortname: ':beverage_box:',
    unicode: '1F9C3',
    html: '&#x1F9C3;',
    category: 'Food & Drink (drink)',
    order: '',
  },
  {
    emoji: '🧉',
    name: 'mate',
    shortname: ':mate:',
    unicode: '1F9C9',
    html: '&#x1F9C9;',
    category: 'Food & Drink (drink)',
    order: '',
  },
  {
    emoji: '🧊',
    name: 'ice',
    shortname: ':ice:',
    unicode: '1F9CA',
    html: '&#x1F9CA;',
    category: 'Food & Drink (drink)',
    order: '',
  },
  {
    emoji: '🥢',
    name: 'chopsticks',
    shortname: ':chopsticks:',
    unicode: '1F962',
    html: '&#x1F962;',
    category: 'Food & Drink (dishware)',
    order: '',
  },
  {
    emoji: '🍽️',
    name: 'fork and knife with plate',
    shortname: ':fork_and_knife_with_plate:',
    unicode: '1F37D FE0F',
    html: '&#x1F37D;&#xFE0F;',
    category: 'Food & Drink (dishware)',
    order: '',
  },
  {
    emoji: '🥄',
    name: 'spoon',
    shortname: ':spoon:',
    unicode: '1F944',
    html: '&#x1F944;',
    category: 'Food & Drink (dishware)',
    order: '',
  },
  {
    emoji: '🗺️',
    name: 'world map',
    shortname: ':world_map:',
    unicode: '1F5FA FE0F',
    html: '&#x1F5FA;&#xFE0F;',
    category: 'Travel & Places (place-map)',
    order: '',
  },
  {
    emoji: '🧭',
    name: 'compass',
    shortname: ':compass:',
    unicode: '1F9ED',
    html: '&#x1F9ED;',
    category: 'Travel & Places (place-map)',
    order: '',
  },
  {
    emoji: '🏔️',
    name: 'snow-capped mountain',
    shortname: ':snowcapped_mountain:',
    unicode: '1F3D4 FE0F',
    html: '&#x1F3D4;&#xFE0F;',
    category: 'Travel & Places (place-geographic)',
    order: '',
  },
  {
    emoji: '⛰️',
    name: 'mountain',
    shortname: ':mountain:',
    unicode: '26F0 FE0F',
    html: '&#x26F0;&#xFE0F;',
    category: 'Travel & Places (place-geographic)',
    order: '',
  },
  {
    emoji: '🏕️',
    name: 'camping',
    shortname: ':camping:',
    unicode: '1F3D5 FE0F',
    html: '&#x1F3D5;&#xFE0F;',
    category: 'Travel & Places (place-geographic)',
    order: '',
  },
  {
    emoji: '🏖️',
    name: 'beach with umbrella',
    shortname: ':beach_with_umbrella:',
    unicode: '1F3D6 FE0F',
    html: '&#x1F3D6;&#xFE0F;',
    category: 'Travel & Places (place-geographic)',
    order: '',
  },
  {
    emoji: '🏜️',
    name: 'desert',
    shortname: ':desert:',
    unicode: '1F3DC FE0F',
    html: '&#x1F3DC;&#xFE0F;',
    category: 'Travel & Places (place-geographic)',
    order: '',
  },
  {
    emoji: '🏝️',
    name: 'desert island',
    shortname: ':desert_island:',
    unicode: '1F3DD FE0F',
    html: '&#x1F3DD;&#xFE0F;',
    category: 'Travel & Places (place-geographic)',
    order: '',
  },
  {
    emoji: '🏞️',
    name: 'national park',
    shortname: ':national_park:',
    unicode: '1F3DE FE0F',
    html: '&#x1F3DE;&#xFE0F;',
    category: 'Travel & Places (place-geographic)',
    order: '',
  },
  {
    emoji: '🏟️',
    name: 'stadium',
    shortname: ':stadium:',
    unicode: '1F3DF FE0F',
    html: '&#x1F3DF;&#xFE0F;',
    category: 'Travel & Places (place-building)',
    order: '',
  },
  {
    emoji: '🏛️',
    name: 'classical building',
    shortname: ':classical_building:',
    unicode: '1F3DB FE0F',
    html: '&#x1F3DB;&#xFE0F;',
    category: 'Travel & Places (place-building)',
    order: '',
  },
  {
    emoji: '🏗️',
    name: 'building construction',
    shortname: ':building_construction:',
    unicode: '1F3D7 FE0F',
    html: '&#x1F3D7;&#xFE0F;',
    category: 'Travel & Places (place-building)',
    order: '',
  },
  {
    emoji: '🧱',
    name: 'brick',
    shortname: ':brick:',
    unicode: '1F9F1',
    html: '&#x1F9F1;',
    category: 'Travel & Places (place-building)',
    order: '',
  },
  {
    emoji: '🏘️',
    name: 'houses',
    shortname: ':houses:',
    unicode: '1F3D8 FE0F',
    html: '&#x1F3D8;&#xFE0F;',
    category: 'Travel & Places (place-building)',
    order: '',
  },
  {
    emoji: '🏚️',
    name: 'derelict house',
    shortname: ':derelict_house:',
    unicode: '1F3DA FE0F',
    html: '&#x1F3DA;&#xFE0F;',
    category: 'Travel & Places (place-building)',
    order: '',
  },
  {
    emoji: '🛕',
    name: 'hindu temple',
    shortname: ':hindu_temple:',
    unicode: '1F6D5',
    html: '&#x1F6D5;',
    category: 'Travel & Places (place-religious)',
    order: '',
  },
  {
    emoji: '⛩️',
    name: 'shinto shrine',
    shortname: ':shinto_shrine:',
    unicode: '26E9 FE0F',
    html: '&#x26E9;&#xFE0F;',
    category: 'Travel & Places (place-religious)',
    order: '',
  },
  {
    emoji: '🏙️',
    name: 'cityscape',
    shortname: ':cityscape:',
    unicode: '1F3D9 FE0F',
    html: '&#x1F3D9;&#xFE0F;',
    category: 'Travel & Places (place-other)',
    order: '',
  },
  {
    emoji: '♨',
    name: 'hot springs',
    shortname: ':hot_springs:',
    unicode: '2668',
    html: '&#x2668;',
    category: 'Travel & Places (place-other)',
    order: '',
  },
  {
    emoji: '🏎️',
    name: 'racing car',
    shortname: ':racing_car:',
    unicode: '1F3CE FE0F',
    html: '&#x1F3CE;&#xFE0F;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🏍️',
    name: 'motorcycle',
    shortname: ':motorcycle:',
    unicode: '1F3CD FE0F',
    html: '&#x1F3CD;&#xFE0F;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🛵',
    name: 'motor scooter',
    shortname: ':motor_scooter:',
    unicode: '1F6F5',
    html: '&#x1F6F5;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🦽',
    name: 'manual wheelchair',
    shortname: ':manual_wheelchair:',
    unicode: '1F9BD',
    html: '&#x1F9BD;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🦼',
    name: 'motorized wheelchair',
    shortname: ':motorized_wheelchair:',
    unicode: '1F9BC',
    html: '&#x1F9BC;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🛺',
    name: 'auto rickshaw',
    shortname: ':auto_rickshaw:',
    unicode: '1F6FA',
    html: '&#x1F6FA;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🛴',
    name: 'kick scooter',
    shortname: ':kick_scooter:',
    unicode: '1F6F4',
    html: '&#x1F6F4;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🛹',
    name: 'skateboard',
    shortname: ':skateboard:',
    unicode: '1F6F9',
    html: '&#x1F6F9;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🛣️',
    name: 'motorway',
    shortname: ':motorway:',
    unicode: '1F6E3 FE0F',
    html: '&#x1F6E3;&#xFE0F;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🛤️',
    name: 'railway track',
    shortname: ':railway_track:',
    unicode: '1F6E4 FE0F',
    html: '&#x1F6E4;&#xFE0F;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🛢️',
    name: 'oil drum',
    shortname: ':oil_drum:',
    unicode: '1F6E2 FE0F',
    html: '&#x1F6E2;&#xFE0F;',
    category: 'Travel & Places (transport-ground)',
    order: '',
  },
  {
    emoji: '🛶',
    name: 'canoe',
    shortname: ':canoe:',
    unicode: '1F6F6',
    html: '&#x1F6F6;',
    category: 'Travel & Places (transport-water)',
    order: '',
  },
  {
    emoji: '🛳️',
    name: 'passenger ship',
    shortname: ':passenger_ship:',
    unicode: '1F6F3 FE0F',
    html: '&#x1F6F3;&#xFE0F;',
    category: 'Travel & Places (transport-water)',
    order: '',
  },
  {
    emoji: '⛴️',
    name: 'ferry',
    shortname: ':ferry:',
    unicode: '26F4 FE0F',
    html: '&#x26F4;&#xFE0F;',
    category: 'Travel & Places (transport-water)',
    order: '',
  },
  {
    emoji: '🛥️',
    name: 'motor boat',
    shortname: ':motor_boat:',
    unicode: '1F6E5 FE0F',
    html: '&#x1F6E5;&#xFE0F;',
    category: 'Travel & Places (transport-water)',
    order: '',
  },
  {
    emoji: '✈',
    name: 'airplane',
    shortname: ':airplane:',
    unicode: '2708',
    html: '&#x2708;',
    category: 'Travel & Places (transport-air)',
    order: '',
  },
  {
    emoji: '🛩️',
    name: 'small airplane',
    shortname: ':small_airplane:',
    unicode: '1F6E9 FE0F',
    html: '&#x1F6E9;&#xFE0F;',
    category: 'Travel & Places (transport-air)',
    order: '',
  },
  {
    emoji: '🪂',
    name: 'parachute',
    shortname: ':parachute:',
    unicode: '1FA82',
    html: '&#x1FA82;',
    category: 'Travel & Places (transport-air)',
    order: '',
  },
  {
    emoji: '🛰️',
    name: 'satellite',
    shortname: ':satellite:',
    unicode: '1F6F0 FE0F',
    html: '&#x1F6F0;&#xFE0F;',
    category: 'Travel & Places (transport-air)',
    order: '',
  },
  {
    emoji: '🛸',
    name: 'flying saucer',
    shortname: ':flying_saucer:',
    unicode: '1F6F8',
    html: '&#x1F6F8;',
    category: 'Travel & Places (transport-air)',
    order: '',
  },
  {
    emoji: '🛎️',
    name: 'bellhop bell',
    shortname: ':bellhop_bell:',
    unicode: '1F6CE FE0F',
    html: '&#x1F6CE;&#xFE0F;',
    category: 'Travel & Places (hotel)',
    order: '',
  },
  {
    emoji: '🧳',
    name: 'luggage',
    shortname: ':luggage:',
    unicode: '1F9F3',
    html: '&#x1F9F3;',
    category: 'Travel & Places (hotel)',
    order: '',
  },
  {
    emoji: '⏱️',
    name: 'stopwatch',
    shortname: ':stopwatch:',
    unicode: '23F1 FE0F',
    html: '&#x23F1;&#xFE0F;',
    category: 'Travel & Places (time)',
    order: '',
  },
  {
    emoji: '⏲️',
    name: 'timer clock',
    shortname: ':timer_clock:',
    unicode: '23F2 FE0F',
    html: '&#x23F2;&#xFE0F;',
    category: 'Travel & Places (time)',
    order: '',
  },
  {
    emoji: '🕰️',
    name: 'mantelpiece clock',
    shortname: ':mantelpiece_clock:',
    unicode: '1F570 FE0F',
    html: '&#x1F570;&#xFE0F;',
    category: 'Travel & Places (time)',
    order: '',
  },
  {
    emoji: '🌡️',
    name: 'thermometer',
    shortname: ':thermometer:',
    unicode: '1F321 FE0F',
    html: '&#x1F321;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '☀',
    name: 'sun',
    shortname: ':sun:',
    unicode: '2600',
    html: '&#x2600;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🪐',
    name: 'ringed planet',
    shortname: ':ringed_planet:',
    unicode: '1FA90',
    html: '&#x1FA90;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '☁️',
    name: 'cloud',
    shortname: ':cloud:',
    unicode: '2601 FE0F',
    html: '&#x2601;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '⛈️',
    name: 'cloud with lightning and rain',
    shortname: ':cloud_with_lightning_and_rain:',
    unicode: '26C8 FE0F',
    html: '&#x26C8;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌤️',
    name: 'sun behind small cloud',
    shortname: ':sun_behind_small_cloud:',
    unicode: '1F324 FE0F',
    html: '&#x1F324;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌥️',
    name: 'sun behind large cloud',
    shortname: ':sun_behind_large_cloud:',
    unicode: '1F325 FE0F',
    html: '&#x1F325;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌦️',
    name: 'sun behind rain cloud',
    shortname: ':sun_behind_rain_cloud:',
    unicode: '1F326 FE0F',
    html: '&#x1F326;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌧️',
    name: 'cloud with rain',
    shortname: ':cloud_with_rain:',
    unicode: '1F327 FE0F',
    html: '&#x1F327;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌨️',
    name: 'cloud with snow',
    shortname: ':cloud_with_snow:',
    unicode: '1F328 FE0F',
    html: '&#x1F328;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌩️',
    name: 'cloud with lightning',
    shortname: ':cloud_with_lightning:',
    unicode: '1F329 FE0F',
    html: '&#x1F329;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌪️',
    name: 'tornado',
    shortname: ':tornado:',
    unicode: '1F32A FE0F',
    html: '&#x1F32A;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌫️',
    name: 'fog',
    shortname: ':fog:',
    unicode: '1F32B FE0F',
    html: '&#x1F32B;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🌬️',
    name: 'wind face',
    shortname: ':wind_face:',
    unicode: '1F32C FE0F',
    html: '&#x1F32C;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '☂',
    name: 'umbrella',
    shortname: ':umbrella:',
    unicode: '2602',
    html: '&#x2602;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '⛱️',
    name: 'umbrella on ground',
    shortname: ':umbrella_on_ground:',
    unicode: '26F1 FE0F',
    html: '&#x26F1;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '❄',
    name: 'snowflake',
    shortname: ':snowflake:',
    unicode: '2744',
    html: '&#x2744;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '☃',
    name: 'snowman',
    shortname: ':snowman:',
    unicode: '2603',
    html: '&#x2603;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '☄️',
    name: 'comet',
    shortname: ':comet:',
    unicode: '2604 FE0F',
    html: '&#x2604;&#xFE0F;',
    category: 'Travel & Places (sky & weather)',
    order: '',
  },
  {
    emoji: '🧨',
    name: 'firecracker',
    shortname: ':firecracker:',
    unicode: '1F9E8',
    html: '&#x1F9E8;',
    category: 'Activities (event)',
    order: '',
  },
  {
    emoji: '🧧',
    name: 'red envelope',
    shortname: ':red_envelope:',
    unicode: '1F9E7',
    html: '&#x1F9E7;',
    category: 'Activities (event)',
    order: '',
  },
  {
    emoji: '🎗️',
    name: 'reminder ribbon',
    shortname: ':reminder_ribbon:',
    unicode: '1F397 FE0F',
    html: '&#x1F397;&#xFE0F;',
    category: 'Activities (event)',
    order: '',
  },
  {
    emoji: '🎟️',
    name: 'admission tickets',
    shortname: ':admission_tickets:',
    unicode: '1F39F FE0F',
    html: '&#x1F39F;&#xFE0F;',
    category: 'Activities (event)',
    order: '',
  },
  {
    emoji: '🎖️',
    name: 'military medal',
    shortname: ':military_medal:',
    unicode: '1F396 FE0F',
    html: '&#x1F396;&#xFE0F;',
    category: 'Activities (award-medal)',
    order: '',
  },
  {
    emoji: '🥇',
    name: '1st place medal',
    shortname: ':1st_place_medal:',
    unicode: '1F947',
    html: '&#x1F947;',
    category: 'Activities (award-medal)',
    order: '',
  },
  {
    emoji: '🥈',
    name: '2nd place medal',
    shortname: ':2nd_place_medal:',
    unicode: '1F948',
    html: '&#x1F948;',
    category: 'Activities (award-medal)',
    order: '',
  },
  {
    emoji: '🥉',
    name: '3rd place medal',
    shortname: ':3rd_place_medal:',
    unicode: '1F949',
    html: '&#x1F949;',
    category: 'Activities (award-medal)',
    order: '',
  },
  {
    emoji: '⚾',
    name: 'baseball',
    shortname: ':baseball:',
    unicode: '26BE',
    html: '&#x26BE;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🥎',
    name: 'softball',
    shortname: ':softball:',
    unicode: '1F94E',
    html: '&#x1F94E;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🥏',
    name: 'flying disc',
    shortname: ':flying_disc:',
    unicode: '1F94F',
    html: '&#x1F94F;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🥍',
    name: 'lacrosse',
    shortname: ':lacrosse:',
    unicode: '1F94D',
    html: '&#x1F94D;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🥊',
    name: 'boxing glove',
    shortname: ':boxing_glove:',
    unicode: '1F94A',
    html: '&#x1F94A;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🥋',
    name: 'martial arts uniform',
    shortname: ':martial_arts_uniform:',
    unicode: '1F94B',
    html: '&#x1F94B;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🥅',
    name: 'goal net',
    shortname: ':goal_net:',
    unicode: '1F945',
    html: '&#x1F945;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '⛸️',
    name: 'ice skate',
    shortname: ':ice_skate:',
    unicode: '26F8 FE0F',
    html: '&#x26F8;&#xFE0F;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🤿',
    name: 'diving mask',
    shortname: ':diving_mask:',
    unicode: '1F93F',
    html: '&#x1F93F;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🛷',
    name: 'sled',
    shortname: ':sled:',
    unicode: '1F6F7',
    html: '&#x1F6F7;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🥌',
    name: 'curling stone',
    shortname: ':curling_stone:',
    unicode: '1F94C',
    html: '&#x1F94C;',
    category: 'Activities (sport)',
    order: '',
  },
  {
    emoji: '🪀',
    name: 'yo-yo',
    shortname: ':yoyo:',
    unicode: '1FA80',
    html: '&#x1FA80;',
    category: 'Activities (game)',
    order: '',
  },
  {
    emoji: '🪁',
    name: 'kite',
    shortname: ':kite:',
    unicode: '1FA81',
    html: '&#x1FA81;',
    category: 'Activities (game)',
    order: '',
  },
  {
    emoji: '🧿',
    name: 'nazar amulet',
    shortname: ':nazar_amulet:',
    unicode: '1F9FF',
    html: '&#x1F9FF;',
    category: 'Activities (game)',
    order: '',
  },
  {
    emoji: '🕹️',
    name: 'joystick',
    shortname: ':joystick:',
    unicode: '1F579 FE0F',
    html: '&#x1F579;&#xFE0F;',
    category: 'Activities (game)',
    order: '',
  },
  {
    emoji: '🧩',
    name: 'puzzle piece',
    shortname: ':puzzle_piece:',
    unicode: '1F9E9',
    html: '&#x1F9E9;',
    category: 'Activities (game)',
    order: '',
  },
  {
    emoji: '🧸',
    name: 'teddy bear',
    shortname: ':teddy_bear:',
    unicode: '1F9F8',
    html: '&#x1F9F8;',
    category: 'Activities (game)',
    order: '',
  },
  {
    emoji: '♟️',
    name: 'chess pawn',
    shortname: ':chess_pawn:',
    unicode: '265F FE0F',
    html: '&#x265F;&#xFE0F;',
    category: 'Activities (game)',
    order: '',
  },
  {
    emoji: '♟',
    name: 'chess pawn',
    shortname: ':chess_pawn:',
    unicode: '265F',
    html: '&#x265F;',
    category: 'Activities (game)',
    order: '',
  },
  {
    emoji: '🖼️',
    name: 'framed picture',
    shortname: ':framed_picture:',
    unicode: '1F5BC FE0F',
    html: '&#x1F5BC;&#xFE0F;',
    category: 'Activities (arts & crafts)',
    order: '',
  },
  {
    emoji: '🧵',
    name: 'thread',
    shortname: ':thread:',
    unicode: '1F9F5',
    html: '&#x1F9F5;',
    category: 'Activities (arts & crafts)',
    order: '',
  },
  {
    emoji: '🧶',
    name: 'yarn',
    shortname: ':yarn:',
    unicode: '1F9F6',
    html: '&#x1F9F6;',
    category: 'Activities (arts & crafts)',
    order: '',
  },
  {
    emoji: '🕶️',
    name: 'sunglasses',
    shortname: ':sunglasses:',
    unicode: '1F576 FE0F',
    html: '&#x1F576;&#xFE0F;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🥽',
    name: 'goggles',
    shortname: ':goggles:',
    unicode: '1F97D',
    html: '&#x1F97D;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🥼',
    name: 'lab coat',
    shortname: ':lab_coat:',
    unicode: '1F97C',
    html: '&#x1F97C;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🦺',
    name: 'safety vest',
    shortname: ':safety_vest:',
    unicode: '1F9BA',
    html: '&#x1F9BA;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🧣',
    name: 'scarf',
    shortname: ':scarf:',
    unicode: '1F9E3',
    html: '&#x1F9E3;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🧤',
    name: 'gloves',
    shortname: ':gloves:',
    unicode: '1F9E4',
    html: '&#x1F9E4;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🧥',
    name: 'coat',
    shortname: ':coat:',
    unicode: '1F9E5',
    html: '&#x1F9E5;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🧦',
    name: 'socks',
    shortname: ':socks:',
    unicode: '1F9E6',
    html: '&#x1F9E6;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🥻',
    name: 'sari',
    shortname: ':sari:',
    unicode: '1F97B',
    html: '&#x1F97B;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🩱',
    name: 'one-piece swimsuit',
    shortname: ':onepiece_swimsuit:',
    unicode: '1FA71',
    html: '&#x1FA71;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🩲',
    name: 'briefs',
    shortname: ':briefs:',
    unicode: '1FA72',
    html: '&#x1FA72;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🩳',
    name: 'shorts',
    shortname: ':shorts:',
    unicode: '1FA73',
    html: '&#x1FA73;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🛍️',
    name: 'shopping bags',
    shortname: ':shopping_bags:',
    unicode: '1F6CD FE0F',
    html: '&#x1F6CD;&#xFE0F;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🥾',
    name: 'hiking boot',
    shortname: ':hiking_boot:',
    unicode: '1F97E',
    html: '&#x1F97E;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🥿',
    name: 'flat shoe',
    shortname: ':flat_shoe:',
    unicode: '1F97F',
    html: '&#x1F97F;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🩰',
    name: 'ballet shoes',
    shortname: ':ballet_shoes:',
    unicode: '1FA70',
    html: '&#x1FA70;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🧢',
    name: 'billed cap',
    shortname: ':billed_cap:',
    unicode: '1F9E2',
    html: '&#x1F9E2;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '⛑️',
    name: 'rescue worker’s helmet',
    shortname: ':rescue_worker’s_helmet:',
    unicode: '26D1 FE0F',
    html: '&#x26D1;&#xFE0F;',
    category: 'Objects (clothing)',
    order: '',
  },
  {
    emoji: '🎙️',
    name: 'studio microphone',
    shortname: ':studio_microphone:',
    unicode: '1F399 FE0F',
    html: '&#x1F399;&#xFE0F;',
    category: 'Objects (music)',
    order: '',
  },
  {
    emoji: '🎚️',
    name: 'level slider',
    shortname: ':level_slider:',
    unicode: '1F39A FE0F',
    html: '&#x1F39A;&#xFE0F;',
    category: 'Objects (music)',
    order: '',
  },
  {
    emoji: '🎛️',
    name: 'control knobs',
    shortname: ':control_knobs:',
    unicode: '1F39B FE0F',
    html: '&#x1F39B;&#xFE0F;',
    category: 'Objects (music)',
    order: '',
  },
  {
    emoji: '🪕',
    name: 'banjo',
    shortname: ':banjo:',
    unicode: '1FA95',
    html: '&#x1FA95;',
    category: 'Objects (musical-instrument)',
    order: '',
  },
  {
    emoji: '☎',
    name: 'telephone',
    shortname: ':telephone:',
    unicode: '260E',
    html: '&#x260E;',
    category: 'Objects (phone)',
    order: '',
  },
  {
    emoji: '🖥️',
    name: 'desktop computer',
    shortname: ':desktop_computer:',
    unicode: '1F5A5 FE0F',
    html: '&#x1F5A5;&#xFE0F;',
    category: 'Objects (computer)',
    order: '',
  },
  {
    emoji: '🖨️',
    name: 'printer',
    shortname: ':printer:',
    unicode: '1F5A8 FE0F',
    html: '&#x1F5A8;&#xFE0F;',
    category: 'Objects (computer)',
    order: '',
  },
  {
    emoji: '⌨️',
    name: 'keyboard',
    shortname: ':keyboard:',
    unicode: '2328 FE0F',
    html: '&#x2328;&#xFE0F;',
    category: 'Objects (computer)',
    order: '',
  },
  {
    emoji: '🖱️',
    name: 'computer mouse',
    shortname: ':computer_mouse:',
    unicode: '1F5B1 FE0F',
    html: '&#x1F5B1;&#xFE0F;',
    category: 'Objects (computer)',
    order: '',
  },
  {
    emoji: '🖲️',
    name: 'trackball',
    shortname: ':trackball:',
    unicode: '1F5B2 FE0F',
    html: '&#x1F5B2;&#xFE0F;',
    category: 'Objects (computer)',
    order: '',
  },
  {
    emoji: '🧮',
    name: 'abacus',
    shortname: ':abacus:',
    unicode: '1F9EE',
    html: '&#x1F9EE;',
    category: 'Objects (computer)',
    order: '',
  },
  {
    emoji: '🎞️',
    name: 'film frames',
    shortname: ':film_frames:',
    unicode: '1F39E FE0F',
    html: '&#x1F39E;&#xFE0F;',
    category: 'Objects (light & video)',
    order: '',
  },
  {
    emoji: '📽️',
    name: 'film projector',
    shortname: ':film_projector:',
    unicode: '1F4FD FE0F',
    html: '&#x1F4FD;&#xFE0F;',
    category: 'Objects (light & video)',
    order: '',
  },
  {
    emoji: '🕯️',
    name: 'candle',
    shortname: ':candle:',
    unicode: '1F56F FE0F',
    html: '&#x1F56F;&#xFE0F;',
    category: 'Objects (light & video)',
    order: '',
  },
  {
    emoji: '🪔',
    name: 'diya lamp',
    shortname: ':diya_lamp:',
    unicode: '1FA94',
    html: '&#x1FA94;',
    category: 'Objects (light & video)',
    order: '',
  },
  {
    emoji: '🗞️',
    name: 'rolled-up newspaper',
    shortname: ':rolledup_newspaper:',
    unicode: '1F5DE FE0F',
    html: '&#x1F5DE;&#xFE0F;',
    category: 'Objects (book-paper)',
    order: '',
  },
  {
    emoji: '🏷️',
    name: 'label',
    shortname: ':label:',
    unicode: '1F3F7 FE0F',
    html: '&#x1F3F7;&#xFE0F;',
    category: 'Objects (book-paper)',
    order: '',
  },
  {
    emoji: '🧾',
    name: 'receipt',
    shortname: ':receipt:',
    unicode: '1F9FE',
    html: '&#x1F9FE;',
    category: 'Objects (money)',
    order: '',
  },
  {
    emoji: '✉',
    name: 'envelope',
    shortname: ':envelope:',
    unicode: '2709',
    html: '&#x2709;',
    category: 'Objects (mail)',
    order: '',
  },
  {
    emoji: '🗳️',
    name: 'ballot box with ballot',
    shortname: ':ballot_box_with_ballot:',
    unicode: '1F5F3 FE0F',
    html: '&#x1F5F3;&#xFE0F;',
    category: 'Objects (mail)',
    order: '',
  },
  {
    emoji: '✏️',
    name: 'pencil',
    shortname: ':pencil:',
    unicode: '270F FE0F',
    html: '&#x270F;&#xFE0F;',
    category: 'Objects (writing)',
    order: '',
  },
  {
    emoji: '✒️',
    name: 'black nib',
    shortname: ':black_nib:',
    unicode: '2712 FE0F',
    html: '&#x2712;&#xFE0F;',
    category: 'Objects (writing)',
    order: '',
  },
  {
    emoji: '🖋️',
    name: 'fountain pen',
    shortname: ':fountain_pen:',
    unicode: '1F58B FE0F',
    html: '&#x1F58B;&#xFE0F;',
    category: 'Objects (writing)',
    order: '',
  },
  {
    emoji: '🖊️',
    name: 'pen',
    shortname: ':pen:',
    unicode: '1F58A FE0F',
    html: '&#x1F58A;&#xFE0F;',
    category: 'Objects (writing)',
    order: '',
  },
  {
    emoji: '🖌️',
    name: 'paintbrush',
    shortname: ':paintbrush:',
    unicode: '1F58C FE0F',
    html: '&#x1F58C;&#xFE0F;',
    category: 'Objects (writing)',
    order: '',
  },
  {
    emoji: '🖍️',
    name: 'crayon',
    shortname: ':crayon:',
    unicode: '1F58D FE0F',
    html: '&#x1F58D;&#xFE0F;',
    category: 'Objects (writing)',
    order: '',
  },
  {
    emoji: '🗂️',
    name: 'card index dividers',
    shortname: ':card_index_dividers:',
    unicode: '1F5C2 FE0F',
    html: '&#x1F5C2;&#xFE0F;',
    category: 'Objects (office)',
    order: '',
  },
  {
    emoji: '🗒️',
    name: 'spiral notepad',
    shortname: ':spiral_notepad:',
    unicode: '1F5D2 FE0F',
    html: '&#x1F5D2;&#xFE0F;',
    category: 'Objects (office)',
    order: '',
  },
  {
    emoji: '🗓️',
    name: 'spiral calendar',
    shortname: ':spiral_calendar:',
    unicode: '1F5D3 FE0F',
    html: '&#x1F5D3;&#xFE0F;',
    category: 'Objects (office)',
    order: '',
  },
  {
    emoji: '🖇️',
    name: 'linked paperclips',
    shortname: ':linked_paperclips:',
    unicode: '1F587 FE0F',
    html: '&#x1F587;&#xFE0F;',
    category: 'Objects (office)',
    order: '',
  },
  {
    emoji: '✂',
    name: 'scissors',
    shortname: ':scissors:',
    unicode: '2702',
    html: '&#x2702;',
    category: 'Objects (office)',
    order: '',
  },
  {
    emoji: '🗃️',
    name: 'card file box',
    shortname: ':card_file_box:',
    unicode: '1F5C3 FE0F',
    html: '&#x1F5C3;&#xFE0F;',
    category: 'Objects (office)',
    order: '',
  },
  {
    emoji: '🗄️',
    name: 'file cabinet',
    shortname: ':file_cabinet:',
    unicode: '1F5C4 FE0F',
    html: '&#x1F5C4;&#xFE0F;',
    category: 'Objects (office)',
    order: '',
  },
  {
    emoji: '🗑️',
    name: 'wastebasket',
    shortname: ':wastebasket:',
    unicode: '1F5D1 FE0F',
    html: '&#x1F5D1;&#xFE0F;',
    category: 'Objects (office)',
    order: '',
  },
  {
    emoji: '🗝️',
    name: 'old key',
    shortname: ':old_key:',
    unicode: '1F5DD FE0F',
    html: '&#x1F5DD;&#xFE0F;',
    category: 'Objects (lock)',
    order: '',
  },
  {
    emoji: '🪓',
    name: 'axe',
    shortname: ':axe:',
    unicode: '1FA93',
    html: '&#x1FA93;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '⛏️',
    name: 'pick',
    shortname: ':pick:',
    unicode: '26CF FE0F',
    html: '&#x26CF;&#xFE0F;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '⚒️',
    name: 'hammer and pick',
    shortname: ':hammer_and_pick:',
    unicode: '2692 FE0F',
    html: '&#x2692;&#xFE0F;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '🛠️',
    name: 'hammer and wrench',
    shortname: ':hammer_and_wrench:',
    unicode: '1F6E0 FE0F',
    html: '&#x1F6E0;&#xFE0F;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '🗡️',
    name: 'dagger',
    shortname: ':dagger:',
    unicode: '1F5E1 FE0F',
    html: '&#x1F5E1;&#xFE0F;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '⚔️',
    name: 'crossed swords',
    shortname: ':crossed_swords:',
    unicode: '2694 FE0F',
    html: '&#x2694;&#xFE0F;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '🛡️',
    name: 'shield',
    shortname: ':shield:',
    unicode: '1F6E1 FE0F',
    html: '&#x1F6E1;&#xFE0F;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '⚙️',
    name: 'gear',
    shortname: ':gear:',
    unicode: '2699 FE0F',
    html: '&#x2699;&#xFE0F;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '🗜️',
    name: 'clamp',
    shortname: ':clamp:',
    unicode: '1F5DC FE0F',
    html: '&#x1F5DC;&#xFE0F;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '⚖️',
    name: 'balance scale',
    shortname: ':balance_scale:',
    unicode: '2696 FE0F',
    html: '&#x2696;&#xFE0F;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '🦯',
    name: 'probing cane',
    shortname: ':probing_cane:',
    unicode: '1F9AF',
    html: '&#x1F9AF;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '⛓️',
    name: 'chains',
    shortname: ':chains:',
    unicode: '26D3 FE0F',
    html: '&#x26D3;&#xFE0F;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '🧰',
    name: 'toolbox',
    shortname: ':toolbox:',
    unicode: '1F9F0',
    html: '&#x1F9F0;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '🧲',
    name: 'magnet',
    shortname: ':magnet:',
    unicode: '1F9F2',
    html: '&#x1F9F2;',
    category: 'Objects (tool)',
    order: '',
  },
  {
    emoji: '⚗️',
    name: 'alembic',
    shortname: ':alembic:',
    unicode: '2697 FE0F',
    html: '&#x2697;&#xFE0F;',
    category: 'Objects (science)',
    order: '',
  },
  {
    emoji: '🧪',
    name: 'test tube',
    shortname: ':test_tube:',
    unicode: '1F9EA',
    html: '&#x1F9EA;',
    category: 'Objects (science)',
    order: '',
  },
  {
    emoji: '🧫',
    name: 'petri dish',
    shortname: ':petri_dish:',
    unicode: '1F9EB',
    html: '&#x1F9EB;',
    category: 'Objects (science)',
    order: '',
  },
  {
    emoji: '🧬',
    name: 'dna',
    shortname: ':dna:',
    unicode: '1F9EC',
    html: '&#x1F9EC;',
    category: 'Objects (science)',
    order: '',
  },
  {
    emoji: '🩸',
    name: 'drop of blood',
    shortname: ':drop_of_blood:',
    unicode: '1FA78',
    html: '&#x1FA78;',
    category: 'Objects (medical)',
    order: '',
  },
  {
    emoji: '🩹',
    name: 'adhesive bandage',
    shortname: ':adhesive_bandage:',
    unicode: '1FA79',
    html: '&#x1FA79;',
    category: 'Objects (medical)',
    order: '',
  },
  {
    emoji: '🩺',
    name: 'stethoscope',
    shortname: ':stethoscope:',
    unicode: '1FA7A',
    html: '&#x1FA7A;',
    category: 'Objects (medical)',
    order: '',
  },
  {
    emoji: '🛏️',
    name: 'bed',
    shortname: ':bed:',
    unicode: '1F6CF FE0F',
    html: '&#x1F6CF;&#xFE0F;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '🛋️',
    name: 'couch and lamp',
    shortname: ':couch_and_lamp:',
    unicode: '1F6CB FE0F',
    html: '&#x1F6CB;&#xFE0F;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '🪑',
    name: 'chair',
    shortname: ':chair:',
    unicode: '1FA91',
    html: '&#x1FA91;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '🪒',
    name: 'razor',
    shortname: ':razor:',
    unicode: '1FA92',
    html: '&#x1FA92;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '🧴',
    name: 'lotion bottle',
    shortname: ':lotion_bottle:',
    unicode: '1F9F4',
    html: '&#x1F9F4;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '🧷',
    name: 'safety pin',
    shortname: ':safety_pin:',
    unicode: '1F9F7',
    html: '&#x1F9F7;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '🧹',
    name: 'broom',
    shortname: ':broom:',
    unicode: '1F9F9',
    html: '&#x1F9F9;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '🧺',
    name: 'basket',
    shortname: ':basket:',
    unicode: '1F9FA',
    html: '&#x1F9FA;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '🧻',
    name: 'roll of paper',
    shortname: ':roll_of_paper:',
    unicode: '1F9FB',
    html: '&#x1F9FB;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '🧼',
    name: 'soap',
    shortname: ':soap:',
    unicode: '1F9FC',
    html: '&#x1F9FC;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '🧽',
    name: 'sponge',
    shortname: ':sponge:',
    unicode: '1F9FD',
    html: '&#x1F9FD;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '🧯',
    name: 'fire extinguisher',
    shortname: ':fire_extinguisher:',
    unicode: '1F9EF',
    html: '&#x1F9EF;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '🛒',
    name: 'shopping cart',
    shortname: ':shopping_cart:',
    unicode: '1F6D2',
    html: '&#x1F6D2;',
    category: 'Objects (household)',
    order: '',
  },
  {
    emoji: '⚰️',
    name: 'coffin',
    shortname: ':coffin:',
    unicode: '26B0 FE0F',
    html: '&#x26B0;&#xFE0F;',
    category: 'Objects (other-object)',
    order: '',
  },
  {
    emoji: '⚱️',
    name: 'funeral urn',
    shortname: ':funeral_urn:',
    unicode: '26B1 FE0F',
    html: '&#x26B1;&#xFE0F;',
    category: 'Objects (other-object)',
    order: '',
  },
  {
    emoji: '⚠',
    name: 'warning',
    shortname: ':warning:',
    unicode: '26A0',
    html: '&#x26A0;',
    category: 'Symbols (warning)',
    order: '',
  },
  {
    emoji: '☢️',
    name: 'radioactive',
    shortname: ':radioactive:',
    unicode: '2622 FE0F',
    html: '&#x2622;&#xFE0F;',
    category: 'Symbols (warning)',
    order: '',
  },
  {
    emoji: '☣️',
    name: 'biohazard',
    shortname: ':biohazard:',
    unicode: '2623 FE0F',
    html: '&#x2623;&#xFE0F;',
    category: 'Symbols (warning)',
    order: '',
  },
  {
    emoji: '⬆',
    name: 'up arrow',
    shortname: ':up_arrow:',
    unicode: '2B06',
    html: '&#x2B06;',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '↗',
    name: 'up-right arrow',
    shortname: ':upright_arrow:',
    unicode: '2197',
    html: '&#x2197;',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '➡️',
    name: 'right arrow',
    shortname: ':right_arrow:',
    unicode: '27A1 FE0F',
    html: '&#x27A1;&#xFE0F;',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '↘',
    name: 'down-right arrow',
    shortname: ':downright_arrow:',
    unicode: '2198',
    html: '&#x2198;',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '⬇',
    name: 'down arrow',
    shortname: ':down_arrow:',
    unicode: '2B07',
    html: '&#x2B07;',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '↙',
    name: 'down-left arrow',
    shortname: ':downleft_arrow:',
    unicode: '2199',
    html: '&#x2199;',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '⬅',
    name: 'left arrow',
    shortname: ':left_arrow:',
    unicode: '2B05',
    html: '&#x2B05;',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '↖️',
    name: 'up-left arrow',
    shortname: ':upleft_arrow:',
    unicode: '2196 FE0F',
    html: '&#x2196;&#xFE0F;',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '↕️',
    name: 'up-down arrow',
    shortname: ':updown_arrow:',
    unicode: '2195 FE0F',
    html: '&#x2195;&#xFE0F;',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '↩',
    name: 'right arrow curving left',
    shortname: ':right_arrow_curving_left:',
    unicode: '21A9',
    html: '&#x21A9;',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '↪',
    name: 'left arrow curving right',
    shortname: ':left_arrow_curving_right:',
    unicode: '21AA',
    html: '&#x21AA;',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '⤴',
    name: 'right arrow curving up',
    shortname: ':right_arrow_curving_up:',
    unicode: '2934',
    html: '&#x2934;',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '⤵',
    name: 'right arrow curving down',
    shortname: ':right_arrow_curving_down:',
    unicode: '2935',
    html: '&#x2935;',
    category: 'Symbols (arrow)',
    order: '',
  },
  {
    emoji: '⚛️',
    name: 'atom symbol',
    shortname: ':atom_symbol:',
    unicode: '269B FE0F',
    html: '&#x269B;&#xFE0F;',
    category: 'Symbols (religion)',
    order: '',
  },
  {
    emoji: '🕉️',
    name: 'om',
    shortname: ':om:',
    unicode: '1F549 FE0F',
    html: '&#x1F549;&#xFE0F;',
    category: 'Symbols (religion)',
    order: '',
  },
  {
    emoji: '✡',
    name: 'star of David',
    shortname: ':star_of_David:',
    unicode: '2721',
    html: '&#x2721;',
    category: 'Symbols (religion)',
    order: '',
  },
  {
    emoji: '☸️',
    name: 'wheel of dharma',
    shortname: ':wheel_of_dharma:',
    unicode: '2638 FE0F',
    html: '&#x2638;&#xFE0F;',
    category: 'Symbols (religion)',
    order: '',
  },
  {
    emoji: '☯️',
    name: 'yin yang',
    shortname: ':yin_yang:',
    unicode: '262F FE0F',
    html: '&#x262F;&#xFE0F;',
    category: 'Symbols (religion)',
    order: '',
  },
  {
    emoji: '✝',
    name: 'latin cross',
    shortname: ':latin_cross:',
    unicode: '271D',
    html: '&#x271D;',
    category: 'Symbols (religion)',
    order: '',
  },
  {
    emoji: '☦️',
    name: 'orthodox cross',
    shortname: ':orthodox_cross:',
    unicode: '2626 FE0F',
    html: '&#x2626;&#xFE0F;',
    category: 'Symbols (religion)',
    order: '',
  },
  {
    emoji: '☪️',
    name: 'star and crescent',
    shortname: ':star_and_crescent:',
    unicode: '262A FE0F',
    html: '&#x262A;&#xFE0F;',
    category: 'Symbols (religion)',
    order: '',
  },
  {
    emoji: '☮️',
    name: 'peace symbol',
    shortname: ':peace_symbol:',
    unicode: '262E FE0F',
    html: '&#x262E;&#xFE0F;',
    category: 'Symbols (religion)',
    order: '',
  },
  {
    emoji: '▶',
    name: 'play button',
    shortname: ':play_button:',
    unicode: '25B6',
    html: '&#x25B6;',
    category: 'Symbols (av-symbol)',
    order: '',
  },
  {
    emoji: '⏭️',
    name: 'next track button',
    shortname: ':next_track_button:',
    unicode: '23ED FE0F',
    html: '&#x23ED;&#xFE0F;',
    category: 'Symbols (av-symbol)',
    order: '',
  },
  {
    emoji: '⏯️',
    name: 'play or pause button',
    shortname: ':play_or_pause_button:',
    unicode: '23EF FE0F',
    html: '&#x23EF;&#xFE0F;',
    category: 'Symbols (av-symbol)',
    order: '',
  },
  {
    emoji: '◀',
    name: 'reverse button',
    shortname: ':reverse_button:',
    unicode: '25C0',
    html: '&#x25C0;',
    category: 'Symbols (av-symbol)',
    order: '',
  },
  {
    emoji: '⏮️',
    name: 'last track button',
    shortname: ':last_track_button:',
    unicode: '23EE FE0F',
    html: '&#x23EE;&#xFE0F;',
    category: 'Symbols (av-symbol)',
    order: '',
  },
  {
    emoji: '⏸️',
    name: 'pause button',
    shortname: ':pause_button:',
    unicode: '23F8 FE0F',
    html: '&#x23F8;&#xFE0F;',
    category: 'Symbols (av-symbol)',
    order: '',
  },
  {
    emoji: '⏹️',
    name: 'stop button',
    shortname: ':stop_button:',
    unicode: '23F9 FE0F',
    html: '&#x23F9;&#xFE0F;',
    category: 'Symbols (av-symbol)',
    order: '',
  },
  {
    emoji: '⏺️',
    name: 'record button',
    shortname: ':record_button:',
    unicode: '23FA FE0F',
    html: '&#x23FA;&#xFE0F;',
    category: 'Symbols (av-symbol)',
    order: '',
  },
  {
    emoji: '⏏️',
    name: 'eject button',
    shortname: ':eject_button:',
    unicode: '23CF FE0F',
    html: '&#x23CF;&#xFE0F;',
    category: 'Symbols (av-symbol)',
    order: '',
  },
  {
    emoji: '⏏',
    name: 'eject button',
    shortname: ':eject_button:',
    unicode: '23CF',
    html: '&#x23CF;',
    category: 'Symbols (av-symbol)',
    order: '',
  },
  {
    emoji: '♀️',
    name: 'female sign',
    shortname: ':female_sign:',
    unicode: '2640 FE0F',
    html: '&#x2640;&#xFE0F;',
    category: 'Symbols (gender)',
    order: '',
  },
  {
    emoji: '♀',
    name: 'female sign',
    shortname: ':female_sign:',
    unicode: '2640',
    html: '&#x2640;',
    category: 'Symbols (gender)',
    order: '',
  },
  {
    emoji: '♂️',
    name: 'male sign',
    shortname: ':male_sign:',
    unicode: '2642 FE0F',
    html: '&#x2642;&#xFE0F;',
    category: 'Symbols (gender)',
    order: '',
  },
  {
    emoji: '♂',
    name: 'male sign',
    shortname: ':male_sign:',
    unicode: '2642',
    html: '&#x2642;',
    category: 'Symbols (gender)',
    order: '',
  },
  {
    emoji: '⚕️',
    name: 'medical symbol',
    shortname: ':medical_symbol:',
    unicode: '2695 FE0F',
    html: '&#x2695;&#xFE0F;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '⚕',
    name: 'medical symbol',
    shortname: ':medical_symbol:',
    unicode: '2695',
    html: '&#x2695;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '♾️',
    name: 'infinity',
    shortname: ':infinity:',
    unicode: '267E FE0F',
    html: '&#x267E;&#xFE0F;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '♾',
    name: 'infinity',
    shortname: ':infinity:',
    unicode: '267E',
    html: '&#x267E;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '♻️',
    name: 'recycling symbol',
    shortname: ':recycling_symbol:',
    unicode: '267B FE0F',
    html: '&#x267B;&#xFE0F;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '⚜️',
    name: 'fleur-de-lis',
    shortname: ':fleurde-lis:',
    unicode: '269C FE0F',
    html: '&#x269C;&#xFE0F;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '☑',
    name: 'check box with check',
    shortname: ':check_box_with_check:',
    unicode: '2611',
    html: '&#x2611;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '✔',
    name: 'check mark',
    shortname: ':check_mark:',
    unicode: '2714',
    html: '&#x2714;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '✖',
    name: 'multiplication sign',
    shortname: ':multiplication_sign:',
    unicode: '2716',
    html: '&#x2716;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '〽',
    name: 'part alternation mark',
    shortname: ':part_alternation_mark:',
    unicode: '303D',
    html: '&#x303D;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '✳',
    name: 'eight-spoked asterisk',
    shortname: ':eightspoked_asterisk:',
    unicode: '2733',
    html: '&#x2733;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '✴',
    name: 'eight-pointed star',
    shortname: ':eightpointed_star:',
    unicode: '2734',
    html: '&#x2734;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '❇',
    name: 'sparkle',
    shortname: ':sparkle:',
    unicode: '2747',
    html: '&#x2747;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '‼',
    name: 'double exclamation mark',
    shortname: ':double_exclamation_mark:',
    unicode: '203C',
    html: '&#x203C;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '⁉',
    name: 'exclamation question mark',
    shortname: ':exclamation_question_mark:',
    unicode: '2049',
    html: '&#x2049;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '〰️',
    name: 'wavy dash',
    shortname: ':wavy_dash:',
    unicode: '3030 FE0F',
    html: '&#x3030;&#xFE0F;',
    category: 'Symbols (other-symbol)',
    order: '',
  },
  {
    emoji: '#️⃣',
    name: 'keycap: #',
    shortname: ':#:',
    unicode: '0023 FE0F 20E3',
    html: '&#x0023;&#xFE0F;&#x20E3;',
    category: 'Symbols (keycap)',
    order: '',
  },
  {
    emoji: '🅰',
    name: 'A button (blood type)',
    shortname: ':A_button_blood_type:',
    unicode: '1F170',
    html: '&#x1F170;',
    category: 'Symbols (alphanum)',
    order: '',
  },
  {
    emoji: '🅱',
    name: 'B button (blood type)',
    shortname: ':B_button_blood_type:',
    unicode: '1F171',
    html: '&#x1F171;',
    category: 'Symbols (alphanum)',
    order: '',
  },
  {
    emoji: 'ℹ️',
    name: 'information',
    shortname: ':information:',
    unicode: '2139 FE0F',
    html: '&#x2139;&#xFE0F;',
    category: 'Symbols (alphanum)',
    order: '',
  },
  {
    emoji: 'Ⓜ',
    name: 'circled M',
    shortname: ':circled_M:',
    unicode: '24C2',
    html: '&#x24C2;',
    category: 'Symbols (alphanum)',
    order: '',
  },
  {
    emoji: '🅾',
    name: 'O button (blood type)',
    shortname: ':O_button_blood_type:',
    unicode: '1F17E',
    html: '&#x1F17E;',
    category: 'Symbols (alphanum)',
    order: '',
  },
  {
    emoji: '🅿',
    name: 'P button',
    shortname: ':P_button:',
    unicode: '1F17F',
    html: '&#x1F17F;',
    category: 'Symbols (alphanum)',
    order: '',
  },
  {
    emoji: '🈂',
    name: 'Japanese service charge button',
    shortname: ':Japanese_service_charge_button:',
    unicode: '1F202',
    html: '&#x1F202;',
    category: 'Symbols (alphanum)',
    order: '',
  },
  {
    emoji: '🈷️',
    name: 'Japanese monthly amount button',
    shortname: ':Japanese_monthly_amount_button:',
    unicode: '1F237 FE0F',
    html: '&#x1F237;&#xFE0F;',
    category: 'Symbols (alphanum)',
    order: '',
  },
  {
    emoji: '🈯',
    name: 'Japanese reserved button',
    shortname: ':Japanese_reserved_button:',
    unicode: '1F22F',
    html: '&#x1F22F;',
    category: 'Symbols (alphanum)',
    order: '',
  },
  {
    emoji: '🈚',
    name: 'Japanese free of charge button',
    shortname: ':Japanese_free_of_charge_button:',
    unicode: '1F21A',
    html: '&#x1F21A;',
    category: 'Symbols (alphanum)',
    order: '',
  },
  {
    emoji: '㊗️',
    name: 'Japanese congratulations button',
    shortname: ':Japanese_congratulations_button:',
    unicode: '3297 FE0F',
    html: '&#x3297;&#xFE0F;',
    category: 'Symbols (alphanum)',
    order: '',
  },
  {
    emoji: '㊙️',
    name: 'Japanese secret button',
    shortname: ':Japanese_secret_button:',
    unicode: '3299 FE0F',
    html: '&#x3299;&#xFE0F;',
    category: 'Symbols (alphanum)',
    order: '',
  },
  {
    emoji: '🟠',
    name: 'orange circle',
    shortname: ':orange_circle:',
    unicode: '1F7E0',
    html: '&#x1F7E0;',
    category: 'Symbols (geometric)',
    order: '',
  },
  {
    emoji: '🟡',
    name: 'yellow circle',
    shortname: ':yellow_circle:',
    unicode: '1F7E1',
    html: '&#x1F7E1;',
    category: 'Symbols (geometric)',
    order: '',
  },
  {
    emoji: '🟢',
    name: 'green circle',
    shortname: ':green_circle:',
    unicode: '1F7E2',
    html: '&#x1F7E2;',
    category: 'Symbols (geometric)',
    order: '',
  },
  {
    emoji: '🟣',
    name: 'purple circle',
    shortname: ':purple_circle:',
    unicode: '1F7E3',
    html: '&#x1F7E3;',
    category: 'Symbols (geometric)',
    order: '',
  },
  {
    emoji: '🟤',
    name: 'brown circle',
    shortname: ':brown_circle:',
    unicode: '1F7E4',
    html: '&#x1F7E4;',
    category: 'Symbols (geometric)',
    order: '',
  },
  {
    emoji: '🟥',
    name: 'red square',
    shortname: ':red_square:',
    unicode: '1F7E5',
    html: '&#x1F7E5;',
    category: 'Symbols (geometric)',
    order: '',
  },
  {
    emoji: '🟧',
    name: 'orange square',
    shortname: ':orange_square:',
    unicode: '1F7E7',
    html: '&#x1F7E7;',
    category: 'Symbols (geometric)',
    order: '',
  },
  {
    emoji: '🟨',
    name: 'yellow square',
    shortname: ':yellow_square:',
    unicode: '1F7E8',
    html: '&#x1F7E8;',
    category: 'Symbols (geometric)',
    order: '',
  },
  {
    emoji: '🟩',
    name: 'green square',
    shortname: ':green_square:',
    unicode: '1F7E9',
    html: '&#x1F7E9;',
    category: 'Symbols (geometric)',
    order: '',
  },
  {
    emoji: '🟦',
    name: 'blue square',
    shortname: ':blue_square:',
    unicode: '1F7E6',
    html: '&#x1F7E6;',
    category: 'Symbols (geometric)',
    order: '',
  },
  {
    emoji: '▪',
    name: 'black small square',
    shortname: ':black_small_square:',
    unicode: '25AA',
    html: '&#x25AA;',
    category: 'Symbols (geometric)',
    order: '',
  },
  {
    emoji: '▫️',
    name: 'white small square',
    shortname: ':white_small_square:',
    unicode: '25AB FE0F',
    html: '&#x25AB;&#xFE0F;',
    category: 'Symbols (geometric)',
    order: '',
  },
  {
    emoji: '🏳️',
    name: 'white flag',
    shortname: ':white_flag:',
    unicode: '1F3F3 FE0F',
    html: '&#x1F3F3;&#xFE0F;',
    category: 'Flags (flag)',
    order: '',
  },
  {
    emoji: '🏳️‍🌈',
    name: 'rainbow flag',
    shortname: ':rainbow_flag:',
    unicode: '1F3F3 FE0F 200D 1F308',
    html: '&#x1F3F3;&#xFE0F;&#x200D;&#x1F308;',
    category: 'Flags (flag)',
    order: '',
  },
  {
    emoji: '🏴‍☠️',
    name: 'pirate flag',
    shortname: ':pirate_flag:',
    unicode: '1F3F4 200D 2620 FE0F',
    html: '&#x1F3F4;&#x200D;&#x2620;&#xFE0F;',
    category: 'Flags (flag)',
    order: '',
  },
  {
    emoji: '🏴‍☠',
    name: 'pirate flag',
    shortname: ':pirate_flag:',
    unicode: '1F3F4 200D 2620',
    html: '&#x1F3F4;&#x200D;&#x2620;',
    category: 'Flags (flag)',
    order: '',
  },
  {
    emoji: '🇺🇳',
    name: 'flag: United Nations',
    shortname: ':United_Nations:',
    unicode: '1F1FA 1F1F3',
    html: '&#x1F1FA;&#x1F1F3;',
    category: 'Flags (country-flag)',
    order: '',
  },
  {
    emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    name: 'flag: England',
    shortname: ':England:',
    unicode: '1F3F4 E0067 E0062 E0065 E006E E0067 E007F',
    html: '&#x1F3F4;&#xE0067;&#xE0062;&#xE0065;&#xE006E;&#xE0067;&#xE007F;',
    category: 'Flags (subdivision-flag)',
    order: '',
  },
  {
    emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    name: 'flag: Scotland',
    shortname: ':Scotland:',
    unicode: '1F3F4 E0067 E0062 E0073 E0063 E0074 E007F',
    html: '&#x1F3F4;&#xE0067;&#xE0062;&#xE0073;&#xE0063;&#xE0074;&#xE007F;',
    category: 'Flags (subdivision-flag)',
    order: '',
  },
  {
    emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
    name: 'Wales',
    shortname: ':wales:',
    unicode: '1F3F4 E0067 E0062 E0077 E006C E0073 E007F',
    html: '&#x1F3F4;&#xE0067;&#xE0062;&#xE0077;&#xE006C;&#xE0073;&#xE007F;',
    category: '(subdivision-flag)',
    order: '',
  },
]

export default emojis
