import axios from 'axios'

import { GetNotificationsResponse, MarkAsReadResponse } from 'utils/types'

export default {
  getNotifications: (page = 1) => {
    return axios.get<GetNotificationsResponse>(`/api/notifications`, {
      params: {
        page,
      },
    })
  },
  markNotificationsAsRead: (uuid?: string) => {
    return axios.put<MarkAsReadResponse>(`/api/notifications/mark_as_read`, {
      uuid,
    })
  },

  createNotification: (message: string) => {
    return axios.post(`/api/notifications`, {
      message,
    })
  },
}
