import React, { useEffect, useState } from 'react'

import cx from 'classnames'
import CabalMessage from 'models/CabalMessage'
import emoji from 'react-easy-emoji'
import { useMutation } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import InfoNotice from 'components/InfoNotice'
import { CrumbProps } from 'global/Breadcrumb'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import useCanPopHistory from 'utils/hooks/useCanPopHistory'
import { MessageRecipient, MessageThread } from 'utils/types'

import G from '../../images/google-icon.png'
import MessageContent from './MessageContent'
import ThreadStarter from './MessageThread/ThreadStarter'
// import ReplyMessage from './ReplyMessage'
import { Divider, MessageContainer, TopBar } from './styles'

interface Props {
  message: CabalMessage
  messageThreads: MessageThread[]
  isAdvisorView: boolean
  onReload: () => Promise<any>
  onSetMessageThread: (id: string, forcePushRoute?: boolean) => void
  minimalView?: boolean
}

const RootMessage: React.VFC<Props> = ({
  message,
  messageThreads,
  isAdvisorView,
  onSetMessageThread,
  minimalView = false,
}) => {
  const history = useHistory()
  const canPopHistory = useCanPopHistory()

  const [teamSlug, setTeamSlug] = useState<string | undefined>()
  const [team, setTeam] = useState()

  let currentTeamSlug = useTeamSlug()
  let currentTeam = useTeam(teamSlug)?.team
  const { user: currentUser } = useCurrentUser()

  useEffect(() => {
    if (!currentTeamSlug) {
      setTeamSlug(currentUser.team?.slug)
      setTeam(currentUser.team)
    } else {
      setTeamSlug(currentTeamSlug)
      setTeam(currentTeam)
    }
  }, [currentUser, currentTeam, currentTeamSlug])

  const [showAll, setShowAll] = useState(false)
  const authSuccess = useSearchParam('auth_success') == 'true'

  const { mutate: markAsArchived, isLoading: markingAsArchived } = useMutation(
    ['markAsArchived', message.uuid],
    () => callApi(api.markAsArchived, message.uuid),
    {
      onSuccess: () => {
        history.push(`/${teamSlug}`)
        cabalToast({ style: 'success', content: 'Successfully archived the message.' })
      },
    },
  )

  useEffect(() => {
    if (authSuccess) {
      cabalToast({
        style: 'success',
        content: 'Thanks for authenticating, your replies will be synced shortly.',
      })
      const currUrl = new URL(window.location.href)
      currUrl.searchParams.delete('auth_success')
      history.replace(currUrl.pathname + currUrl.search)
    }
  }, [])

  if (!team) return null

  const recipientLabel = (r: MessageRecipient) => {
    if (r.type === 'group') {
      return <span key={r.value}>{r.label}</span>
    } else if (r.type === 'advisor') {
      return (
        <Link className="hover:underline" to={`/${teamSlug}/people/${r.value}`} key={r.value}>
          {r.label}
        </Link>
      )
    }
    return r.label
  }

  const is1on1 = messageThreads.length === 1
  const hasReplies = messageThreads.some((e) => e.reply_messages.length > 0)

  const enabledAdminSharedMessages = team.enable_admin_shared_messages ?? false
  const currentUserIsCreatorOrSender =
    (message.creator_user && currentUser.sender_uuids.includes(message.creator_user?.uuid)) ||
    (message.sender && currentUser.sender_uuids.includes(message.sender.uuid))

  const canReadInbox = Object.values(currentUser.gmail_auth_statuses).some(
    (status) => status.can_read_inbox,
  )

  const filteredMessageThreads = messageThreads
    .filter((e) => e.reply_messages.length > 0 || is1on1)
    .sort((a, b) => {
      let ad = new Date(a.last_reply_at).getTime()
      let bd = new Date(b.last_reply_at).getTime()

      const now = new Date().getTime()

      if (a.is_unread) {
        ad += now
      }
      if (b.is_unread) {
        bd += now
      }

      return bd - ad
    })

  return (
    <>
      {/* {!minimalView && (
        <TopBar className="mb-2">
          <div>
            <CabalButton
              onClick={() => {
                if (canPopHistory) {
                  history.goBack()
                } else {
                  history.push(`/${teamSlug}/messages`)
                }
              }}
              variant="tertiary"
              leftIcon={
                <Typography color="purple">
                  <i className="far fa-arrow-left" />
                </Typography>
              }
            >
              <Typography color="purple">Back</Typography>
            </CabalButton>
          </div>
          <div>
            <CabalButton
              onClick={() => markAsArchived()}
              working={markingAsArchived}
              variant="tertiary"
              leftIcon={<i className="far fa-archive" />}
            >
              Archive
            </CabalButton>
          </div>
        </TopBar>
      )} */}
      <div>
        <div className="flex justify-between p-3">
          {!minimalView && (
            <div className="flex-intial">
              <CabalButton variant="tertiary" onClick={() => history.goBack()}>
                <i className="far fa-chevron-left" />
              </CabalButton>
            </div>
          )}
          <div className="flex-1 pl-1">
            <div className="flex items-center gap-3 justify-between">
              <div className="flex items-center">
                <Typography
                  color={'primary'}
                  fontSize="20"
                  fontWeight={700}
                  lineHeight={1.2}
                  component="h1"
                  data-testid="message-subject"
                  className={cx({ italic: !message.subject })}
                >
                  {emoji(message.subject || '(No Subject)')}
                </Typography>
              </div>
            </div>

            {message.from ? (
              <Typography color={'primary'} fontSize="13" component="div">
                From: {message.from}
              </Typography>
            ) : null}

            {message.recipients && message.recipients.length > 0 && (
              <Typography color={'primary'} fontSize="13" component="div">
                To:{' '}
                <Typography fontSize="12">
                  {showAll ? (
                    <span>
                      {message.recipients
                        ?.map((r) => recipientLabel(r))
                        .reduce<React.ReactNode>(
                          (prev, curr) => (prev ? [prev, ', ', curr] : curr),
                          '',
                        )}
                      <Typography
                        color="purple"
                        fontSize="12"
                        className="cursor-pointer"
                        onClick={() => setShowAll(!showAll)}
                      >
                        <i className="px-2 far fa-chevron-left" />
                        show less
                      </Typography>
                    </span>
                  ) : (
                    <span>
                      {message.recipients
                        .slice(0, 4)
                        .map((r) => recipientLabel(r))
                        .reduce<React.ReactNode>(
                          (prev, curr) => (prev ? [prev, ', ', curr] : curr),
                          '',
                        )}

                      {message.recipients.length > 4 && (
                        <Typography
                          color="purple"
                          fontSize="12"
                          className="cursor-pointer"
                          onClick={() => setShowAll(!showAll)}
                        >
                          {' '}
                          & {message.recipients.length - 4} more
                          <i className="pl-2 far fa-chevron-right" />
                        </Typography>
                      )}
                    </span>
                  )}
                </Typography>
              </Typography>
            )}

            {message.cc && message.cc.length > 0 && (
              <Typography fontSize="12" component="div">
                Cc:{' '}
                <Typography color="secondary" fontSize="12">
                  {message.Cc?.map((r) => recipientLabel(r)).reduce<React.ReactNode>(
                    (prev, curr) => (prev ? [prev, ', ', curr] : curr),
                    '',
                  )}
                </Typography>
              </Typography>
            )}
          </div>
          <div className="flex-initial">
            {minimalView && (
              <CabalButton
                variant="tertiary"
                tooltip={
                  <>
                    Expand <i className="far fa-external-link" />
                  </>
                }
                onClick={() => {
                  const url = `/${teamSlug}/messages/${message.uuid}`
                  if (window.open(url) === null) {
                    history.push(url)
                  }
                }}
              >
                <i className="far fa-expand-alt" />
              </CabalButton>
            )}
            <CabalButton
              onClick={() => markAsArchived()}
              working={markingAsArchived}
              variant="link"
              leftIcon={<i className="far fa-archive" />}
            >
              Archive
            </CabalButton>
          </div>
        </div>

        <Divider />

        {message && (
          <MessageContainer className="mt-3.5 ">
            <MessageContent
              message={message}
              allowExpansion={hasReplies}
              isExpanded={!hasReplies}
            />
          </MessageContainer>
        )}

        {hasReplies && <Divider />}
      </div>

      <div className="text-center my-3">
        {!isAdvisorView &&
          (enabledAdminSharedMessages ? currentUserIsCreatorOrSender : true) &&
          currentUser.connected_gmail &&
          currentUser.sender_gmail_users.length > 0 &&
          currentUser.sender_gmail_users.find((u) => u.email === message.from) &&
          (currentUser.features?.inbox && !canReadInbox ? (
            <div>
              <InfoNotice text="To see email replies, authorize with Google with read permissions" />

              <CabalButton
                variant="auth"
                leftIcon={<img src={G} width="18" />}
                onClick={() =>
                  (document.location.href = `/auth/gmail?read_email=true&return_to=${window.location.pathname}`)
                }
              >
                Sign in with Google
              </CabalButton>
            </div>
          ) : message.disable_replies ? (
            <InfoNotice text="Replies to this message are in your Gmail inbox" />
          ) : null)}

        {!isAdvisorView &&
        (enabledAdminSharedMessages ? currentUserIsCreatorOrSender : true) &&
        currentUser.connected_microsoft &&
        currentUser.sender_microsoft_users.length > 0 &&
        currentUser.sender_microsoft_users.find((u) => u.email === message.from) ? (
          <InfoNotice text="Replies to this message are in your Outlook inbox" />
        ) : null}
      </div>

      {filteredMessageThreads.map((messageThread) => (
        <ThreadStarter
          key={messageThread.uuid}
          messageThread={messageThread}
          onSetMessageThread={onSetMessageThread}
        />
      ))}

      {/* {messageThreads[0]?.reply_messages.length === 0 && (
        <ReplyContainer>
          <ReplyMessage
            companySlug={teamSlug}
            threadUuid={messageThreads[0].uuid}
            onSend={onReload}
          />
        </ReplyContainer>
      )} */}
    </>
  )
}

export default RootMessage
