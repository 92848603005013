import * as React from 'react'
import EmptyState from 'global/EmptyState'
import Typography from 'global/Typography'
import G from '../../images/google-icon.png'
import CabalButton from 'global/CabalButton'

const DealBoardEmptyState = () => {
  return (
    <>
      <EmptyState
        image={
          <Typography fontSize="24" color="fog">
            <i className="far fa-clipboard-list-check"></i>
          </Typography>
        }
        heading={'Add your calendar'}
        body={
          <div className="text-center">
            <Typography textAlign="center" component="h5" className="mb-11">
              Collaborate on upcoming meetings with your team
            </Typography>
          </div>
        }
        cta={
          <CabalButton
            variant="auth"
            component="a"
            leftIcon={<img src={G} width="18" />}
            href="/auth/gmail?calendar=true"
          >
            Connect Google Calendar
          </CabalButton>
        }
      />
    </>
  )
}

export default DealBoardEmptyState
