import React from 'react'

import { useQuery } from 'react-query'

import JobsEditOrAddModal from 'containers/ListIndexView/TalentList/JobsList/JobsEditOrAddModal'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { CompanyListBlueprint, CompanyListItemBlueprint } from 'utils/types'
import { InvestorCompany } from 'utils/types/investor'

import JobRow from './JobRow'

interface Props {
  teamSlug: string
  company: InvestorCompany
  companyList: CompanyListBlueprint
}

const JobsSection: React.FC<Props> = ({ teamSlug, company, companyList }) => {
  const { showModal } = useModal()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  let filters = {
    portfolio_company_uuids: [company.uuid],
  }
  if (!isAdminOrEmployee) {
    filters = {
      ...filters,
      visibility: 'team_and_members',
    }
  }
  const { data, isLoading, refetch } = useQuery(['getCompanyJobs', company.uuid], () =>
    callApi(api.getJobsV2, teamSlug, null, 1, filters),
  )

  const showingItems = data?.items
    ?.filter(
      (c: CompanyListBlueprint) =>
        c.job && Object.keys(c.job).length > 0 && c.company_list?.is_all_jobs,
    )
    .reduce((acc, current) => {
      acc[current.job.uuid] = current
      return acc
    }, {})

  const hasShowingItems = showingItems ? Object.keys(showingItems).length > 0 : false
  const uniqueShowingItems: CompanyListItemBlueprint[] = hasShowingItems
    ? Object.values(showingItems)
    : []

  const openJobModal = (item?: CompanyListBlueprint, jobUuid?: string) => {
    showModal(
      (resolve) => (
        <JobsEditOrAddModal
          onHide={resolve}
          teamSlug={teamSlug}
          refetchListItems={refetch}
          listUuids={item?.job?.list_uuids ?? []}
          currentListUuid={item?.company_list?.uuid ?? ''}
          company={company}
          jobUuid={jobUuid}
          showListDropdown={true}
        />
      ),
      'investor-company-job-modal',
    )
  }

  if (isLoading || (!uniqueShowingItems?.length && !isAdminOrEmployee)) {
    return null
  }

  return (
    <Widget
      title="Jobs"
      cta={
        isAdminOrEmployee && (
          <div className="hidden group-hover:flex">
            <CabalButton variant="link" padding="0" onClick={() => openJobModal()}>
              Add Job
            </CabalButton>
          </div>
        )
      }
      className="group"
    >
      {(!uniqueShowingItems || uniqueShowingItems?.length === 0) && (
        <div className="flex flex-col items-center justify-center pb-4">
          <Typography fontSize="14" fontWeight={600} color="fog" lineHeight={1.5}>
            No jobs yet
          </Typography>
          <Typography fontSize="12" color="fog" className="mt-1">
            <Typography
              className="cursor-pointer"
              onClick={() => openJobModal()}
              color="purple"
              fontSize="12"
            >
              Start adding jobs{' '}
            </Typography>
            to your portfolio companies
          </Typography>
        </div>
      )}
      {uniqueShowingItems?.length > 0 &&
        uniqueShowingItems.map((item: CompanyListItemBlueprint) => (
          <JobRow
            listItem={item}
            key={item.job?.uuid}
            teamSlug={teamSlug}
            openJobModal={() => openJobModal(item, item.job?.uuid)}
            companyList={companyList}
          />
        ))}
    </Widget>
  )
}

export default JobsSection
