import React, { useState } from 'react'

import { useMutation } from 'react-query'

import { SelectWrapper } from 'containers/CandidateProfileModal/PersonalInfo'
import {
  COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS,
  LEVEL_OPTIONS,
  OFFICE_WITH_OPEN_TO_ALL_OPTIONS,
  OPPORTUNITY_TYPE_OPTIONS,
  ROLE_FUNCTION_OPTIONS,
  STAGE_WITH_OPEN_TO_ALL_OPTIONS,
} from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import { MultiSelect, Select, TextArea, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CandidateProfile } from 'utils/types'

interface PrefrencesModalProps {
  onHide: () => void
  candidateProfile: CandidateProfile
  teamSlug: string
  reloadCandidateProfile: () => void
}

const PreferencesModal = ({
  onHide,
  candidateProfile: _candidateProfile,
  teamSlug,
  reloadCandidateProfile,
}: PrefrencesModalProps) => {
  const [candidateProfile, setCandidateProfile] =
    useState<Partial<CandidateProfile>>(_candidateProfile)

  const updateCandidateProfileMutation = useMutation(
    ['updateCandidateProfile', candidateProfile.uuid],
    () =>
      callApi(api.updateCandidateProfileV2, candidateProfile.uuid!, {
        candidate_profile: candidateProfile,
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Profile updated successfully' })
        reloadCandidateProfile()
        onHide()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to update profile' })
      },
    },
  )

  return (
    <Modal
      header="Prefrences"
      show={true}
      onHide={onHide}
      rightActions={
        <CabalButton
          type="button"
          variant="primary"
          onClick={() => updateCandidateProfileMutation.mutate()}
          working={updateCandidateProfileMutation.isLoading}
        >
          Save
        </CabalButton>
      }
    >
      <div className="px-4 text-left">
        <div className="pt-2 flex justify-between items-center">
          <Typography fontSize="12" fontWeight={600}>
            Role Preferences
          </Typography>
        </div>
        <ModalInputWrapper className="py-2">
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">Type</div>
          </ModalInputLabel>
          <MultiSelect
            placeholder="Preferred role type(s)"
            options={OPPORTUNITY_TYPE_OPTIONS}
            value={candidateProfile.opportunity_type}
            onChange={(opportunityType) =>
              setCandidateProfile({ ...candidateProfile, opportunity_type: opportunityType })
            }
          />
        </ModalInputWrapper>

        <ModalInputWrapper className="py-2">
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">Function</div>
          </ModalInputLabel>

          <MultiSelect
            placeholder="Preferred job function"
            options={ROLE_FUNCTION_OPTIONS}
            value={candidateProfile.function_preference}
            onChange={(functionPreference) =>
              setCandidateProfile({ ...candidateProfile, function_preference: functionPreference })
            }
          />
        </ModalInputWrapper>

        <ModalInputWrapper className="py-2">
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">Level</div>
          </ModalInputLabel>

          <MultiSelect
            placeholder="Preferred job level"
            options={LEVEL_OPTIONS}
            value={candidateProfile.level_preference}
            onChange={(levelPreference) =>
              setCandidateProfile({ ...candidateProfile, level_preference: levelPreference })
            }
          />
        </ModalInputWrapper>

        <ModalInputWrapper className="py-2">
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">Compensation</div>
          </ModalInputLabel>
          <TextInput
            value={candidateProfile.minimum_salary}
            placeholder="Compensation expectation"
            onChange={(e) =>
              setCandidateProfile({ ...candidateProfile, minimum_salary: e.target.value })
            }
          />
        </ModalInputWrapper>

        <div className="pt-4 flex justify-between items-center">
          <Typography fontSize="12" fontWeight={600}>
            Company Preferences
          </Typography>
        </div>

        <ModalInputWrapper className="py-2">
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">Size</div>
          </ModalInputLabel>

          <MultiSelect
            placeholder="Preferred company size"
            options={COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS}
            value={candidateProfile.ideal_company_size}
            onChange={(idealCompanySize) =>
              setCandidateProfile({ ...candidateProfile, ideal_company_size: idealCompanySize })
            }
          />
        </ModalInputWrapper>

        <ModalInputWrapper className="py-2">
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">Stage</div>
          </ModalInputLabel>

          <MultiSelect
            placeholder="Preferred company stage(s)"
            options={STAGE_WITH_OPEN_TO_ALL_OPTIONS}
            value={candidateProfile.stage_preference}
            onChange={(stagePreference) =>
              setCandidateProfile({ ...candidateProfile, stage_preference: stagePreference })
            }
          />
        </ModalInputWrapper>

        <ModalInputWrapper className="py-2">
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">Location</div>
          </ModalInputLabel>

          <MultiSelect
            placeholder="Preferred location(s)"
            options={OFFICE_WITH_OPEN_TO_ALL_OPTIONS}
            value={candidateProfile.office_preference}
            onChange={(office_preference) =>
              setCandidateProfile({ ...candidateProfile, office_preference: office_preference })
            }
          />
        </ModalInputWrapper>

        <div className="pt-4 flex justify-between items-center">
          <Typography fontSize="12" fontWeight={600}>
            General
          </Typography>
        </div>

        <ModalInputWrapper className="py-2">
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">Work Status</div>
          </ModalInputLabel>
          <SelectWrapper>
            <Select
              placeholder="Work Status"
              options={[
                { value: 'legal_to_work_in_us', label: 'Legal to Work in US' },
                { value: 'visa_requirement', label: 'Visa Requirement' },
              ]}
              value={candidateProfile.work_status}
              onChange={(work_status) =>
                setCandidateProfile({ ...candidateProfile, work_status: work_status || undefined })
              }
            />
          </SelectWrapper>
        </ModalInputWrapper>

        <ModalInputWrapper className="py-2">
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">Looking for</div>
          </ModalInputLabel>
          <TextArea
            placeholder="Add detail about ideal role"
            value={candidateProfile.what_youre_looking_for}
            onChange={(e) =>
              setCandidateProfile({ ...candidateProfile, what_youre_looking_for: e.target.value })
            }
          />
        </ModalInputWrapper>
      </div>
    </Modal>
  )
}

export default PreferencesModal
