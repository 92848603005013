import * as React from 'react'
import { useReducer, createContext } from 'react'

import actionTypes from './actionTypes'
import { AppState } from 'utils/types'
import { Action, AppContextType } from './types'

const initialState: AppState = {
  groups: {},
  advisors: {},
  teams: {},
  unreadCounts: undefined,
  toastText: undefined,
  showAddAdvisor: false,
  advisorModalType: 'advisor',
  rightBar: {},
  currentUserAdvisors: {},
  currentUserSettings: null,
  currentUserProfile: {},
  companies: [],
}

export const AppContext = createContext<AppContextType>({
  state: initialState,
  dispatch: () => null,
})
const { Provider } = AppContext

const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case actionTypes.setGroups:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.company_slug]: action.payload.groups,
        },
      }

    case actionTypes.setAdvisors:
      return {
        ...state,
        advisors: {
          ...state.advisors,
          [action.payload.company_slug]: action.payload.advisors,
        },
      }

    case actionTypes.setAdvisor:
      const updatedAdvisors = [...state.advisors[action.payload.company_slug]]

      for (let i = 0; i < updatedAdvisors.length; i++) {
        if (updatedAdvisors[i].uuid === action.payload.advisor.uuid) {
          updatedAdvisors[i] = action.payload.advisor
        }
      }

      return {
        ...state,
        advisors: {
          ...state.advisors,
          [action.payload.company_slug]: updatedAdvisors,
        },
      }

    case actionTypes.setUnreadCounts:
      return {
        ...state,
        unreadCounts: action.payload.unread_counts,
      }

    case actionTypes.setToast:
      return {
        ...state,
        toastText: action.payload.toastText,
      }

    case actionTypes.setToggleRightBar:
      let rightBar = action.payload

      // if it's the same screen, reset right bar
      if (
        state.rightBar.screen == rightBar?.screen &&
        state.rightBar.advisor.uuid == rightBar.advisor?.uuid
      ) {
        rightBar = {}
      }

      return {
        ...state,
        rightBar,
      }

    case actionTypes.setCloseRightBar:
      return {
        ...state,
        rightBar: {},
      }

    case actionTypes.setCurrentUserAdvisor:
      return {
        ...state,
        currentUserAdvisors: {
          ...state.currentUserAdvisors,
          [action.payload.company_slug]: action.payload.advisor,
        },
      }

    case actionTypes.setCurrentUserSettings:
      return {
        ...state,
        currentUserSettings: {
          ...state.currentUserSettings,
          ...action.payload.settings,
        },
      }

    case actionTypes.setCurrentUserProfile:
      return {
        ...state,
        currentUserProfile: {
          ...state.currentUserProfile,
          ...action.payload.profile,
        },
      }

    case actionTypes.setCompanies:
      return {
        ...state,
        companies: action.payload.companies,
      }

    case actionTypes.setTeam:
      return {
        ...state,
        teams: {
          ...state.teams,
          [action.payload.team.slug]: action.payload.team,
        },
      }

    default:
      throw new Error()
  }
}

// Create a provider for components to consume and subscribe to changes
export const AppContextProvider = (props: any) => {
  const [state, dispatch] = useReducer<React.Reducer<AppState, Action>>(reducer, initialState)

  return <Provider value={{ state, dispatch }}>{props.children}</Provider>
}

export function connectContext(Component: any) {
  const wrappedComponent = (props: any) => (
    <AppContext.Consumer>
      {(context) => (
        <Component
          {...props}
          context={context}
          appState={context.state}
          dispatch={context.dispatch}
        />
      )}
    </AppContext.Consumer>
  )

  return wrappedComponent
}
