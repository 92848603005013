import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import Avatar from 'global/Avatar'
import Typography from 'global/Typography'

const OptionWrapper = styled.div<{ logo: boolean }>`
  ${tw`flex-shrink-0 flex items-center rounded-full cursor-pointer`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid ${({ theme }) => theme.layout.main_bg_color};

  padding-left: ${({ logo }) => (logo ? '4px' : '12px')};
  padding-right: 12px;
  height: 32px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.purple};
  }
`

interface Props {
  title: React.ReactNode | string
  logo?: string
  onClick?: () => void
  count?: number | string | null
  name?: string
}

const OptionPillBlock: React.FC<Props> = ({ logo, title, onClick, count, name }) => {
  return (
    <OptionWrapper onClick={onClick} logo={!!logo}>
      {logo && (
        <div className="mr-2 leading-none">
          <Avatar src={logo} name={name} size="20" />
        </div>
      )}
      <div className="flex items-center space-x-1">
        <Typography fontWeight={600} lineHeight={1.2} fontSize="12" component="p">
          {title}
        </Typography>
        {count && (
          <Typography fontSize="12" color={'fog'} component="p" lineHeight={1.2}>
            ({count})
          </Typography>
        )}
      </div>
    </OptionWrapper>
  )
}

export default OptionPillBlock
