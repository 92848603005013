import styled from 'styled-components'
import tw from 'twin.macro'

const CardHeader = styled.div`
  ${tw`flex justify-between p-2 rounded-t`}
`

const CardBody = styled.div`
  ${tw`p-3 rounded-b pt-0`}
  background-color: ${({ theme }) => theme.colors.light_shadow};
`
const Stage = styled.div`
  ${tw`rounded p-1`}
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.add_bg};
`

const BorderDecore = styled.div`
  ${tw` ml-6 mr-6`}

  border-left: ${({ theme }) => theme.border};
`

export { BorderDecore, CardBody, CardHeader, Stage }
