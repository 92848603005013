import { replace } from 'lodash'

import { AdvisorModel, CurrentUserProfile, Team } from 'utils/types'
import { AgreementField, AgreementFieldType, EquityFieldsType } from 'utils/types/agreement'
import { numWithCommas } from 'utils/ui'

export const inviteEmailVariables = ['{{first_name}}', '{{full_name}}']

export const permittedAgreementFields: {
  [key: string]: AgreementField
} = {
  /** member fields */
  'member.name': {
    label: 'Member Name',
    type: 'string',
  },
  'member.signature': {
    label: 'Member Signature',
    type: 'image',
  },
  'member.email': {
    label: 'Member Email',
    type: 'string',
  },
  /** agreement fields */
  'agreement.effective_date': {
    label: 'Effective Date',
    type: 'string',
  },
  'agreement.granted_shares': {
    label: 'Granted Shares',
    type: 'string',
  },
  'agreement.vesting_start_date': {
    label: 'Vesting Start Date',
    type: 'string',
  },
  'agreement.vesting_period': {
    label: 'Vesting Period (Months)',
    type: 'string',
  },
  /**
   * sender fields
   *
   * if any new field is added here, make sure we have the value in Team.founders
   */
  'sender.signature': {
    label: 'Sender Signature',
    type: 'image',
  },
  'sender.name': {
    label: 'Sender Name',
    type: 'string',
  },
  'sender.title': {
    label: 'Sender Title',
    type: 'string',
  },
  'sender.email': {
    label: 'Sender Email',
    type: 'string',
  },
  /** custom fields */
  'custom.text': {
    label: 'Text',
    type: 'text',
    isCustom: true,
  },
  'custom.checkbox': {
    label: 'Checkbox',
    type: 'checkbox',
    isCustom: true,
  },
}

export type DroppableField = {
  name: string
  default_value?: string | null
  for: 'sender' | 'advisor'
  data_type: AgreementFieldType
  overwrite_default_value: boolean
}

export const defaultStandardFields: {
  name: string
  fields: DroppableField[]
}[] = [
  {
    name: 'Member Info',
    fields: [
      {
        name: 'Member Name',
        default_value: 'advisor.name',
        for: 'sender',
        data_type: 'string',
        overwrite_default_value: true,
      },
      {
        name: 'Member Title',
        default_value: 'advisor.title',
        for: 'sender',
        data_type: 'string',
        overwrite_default_value: true,
      },
      {
        name: 'Member Email',
        default_value: 'advisor.email',
        for: 'sender',
        data_type: 'string',
        overwrite_default_value: true,
      },
      {
        name: 'Member Signature',
        default_value: 'advisor.signature',
        for: 'advisor',
        data_type: 'image',
        overwrite_default_value: true,
      },
    ],
  },
  {
    name: 'Grant Details',
    fields: [
      {
        name: 'Option Grant (Shares)',
        default_value: 'agreement.granted_shares',
        for: 'sender',
        data_type: 'number',
        overwrite_default_value: true,
      },
      {
        name: 'Cliff (Years)',
        default_value: 'agreement.cliff_years',
        for: 'sender',
        data_type: 'number',
        overwrite_default_value: true,
      },
      {
        name: 'Vesting Start Date',
        default_value: 'agreement.vesting_start_date',
        for: 'sender',
        data_type: 'date',
        overwrite_default_value: true,
      },
      {
        name: 'Vesting Period (Months)',
        default_value: 'agreement.vesting_period',
        for: 'sender',
        data_type: 'number',
        overwrite_default_value: true,
      },
      {
        name: 'Effective Date',
        default_value: 'agreement.effective_date',
        for: 'sender',
        data_type: 'date',
        overwrite_default_value: true,
      },
      {
        name: 'Investment Amount',
        default_value: 'agreement.amount_invested',
        data_type: 'amount',
        for: 'sender',
        overwrite_default_value: true,
      },
      {
        name: 'Valuation Cap',
        default_value: 'agreement.valuation',
        data_type: 'amount',
        for: 'sender',
        overwrite_default_value: true,
      },
    ],
  },
  {
    name: 'Your Info',
    fields: [
      {
        name: 'Your Name',
        default_value: 'sender.name',
        for: 'sender',
        data_type: 'string',
        overwrite_default_value: true,
      },
      {
        name: 'Your Email',
        default_value: 'sender.email',
        for: 'sender',
        data_type: 'string',
        overwrite_default_value: true,
      },
      {
        name: 'Your Signature',
        default_value: 'sender.signature',
        for: 'sender',
        data_type: 'image',
        overwrite_default_value: true,
      },
      {
        name: 'Your Title',
        default_value: 'sender.title',
        for: 'sender',
        data_type: 'string',
        overwrite_default_value: true,
      },
    ],
  },
  {
    name: 'Company',
    fields: [
      {
        name: 'Company Name',
        default_value: 'team.name',
        for: 'sender',
        data_type: 'string',
        overwrite_default_value: true,
      },
      {
        name: 'Company Legal Name',
        default_value: 'team.legal_name',
        for: 'sender',
        data_type: 'string',
        overwrite_default_value: true,
      },
      {
        name: 'Company Address',
        default_value: 'team.address',
        for: 'sender',
        data_type: 'text',
        overwrite_default_value: true,
      },
    ],
  },
]

export const defaultDocReplacementFields = [
  {
    name: 'Investment Amount',
    default_value: 'agreement.amount_invested',
    shortcode: 'investment-amount',
    data_type: 'amount',
    for: 'sender',
  },
  {
    name: 'Valuation Cap',
    default_value: 'agreement.valuation',
    shortcode: 'valuation-cap',
    data_type: 'amount',
    for: 'sender',
  },
  {
    name: 'Sender Name',
    default_value: 'sender.name',
    shortcode: 'sender-name',
    data_type: 'string',
    for: 'sender',
  },
  {
    name: 'Sender Title',
    default_value: 'sender.title',
    shortcode: 'sender-title',
    data_type: 'string',
    for: 'sender',
  },
  {
    name: 'Company Name',
    default_value: 'team.name',
    shortcode: 'company-name',
    data_type: 'string',
    for: 'sender',
  },
  {
    name: 'Company Legal Name',
    default_value: 'team.legal_name',
    shortcode: 'company-legal-name',
    data_type: 'string',
    for: 'sender',
  },
  {
    name: 'Company State of Incorporation',
    default_value: 'team.state_of_incorporation',
    shortcode: 'company-state-of-incorporation',
    data_type: 'string',
    for: 'sender',
  },
  {
    name: 'Member Name',
    default_value: 'advisor.name',
    shortcode: 'member-name',
    data_type: 'string',
    for: 'sender',
  },
]

export const agreementMessageVariables = [
  '{{granted_shares}}',
  '{{vesting_period}}',
  '{{cliff}}',
  '{{member_name}}',
  '{{investment_amount}}',
  '{{valuation_cap}}',
  '{{company_name}}',
  '{{sender_name}}',
]

export const replaceAgreementMessageVariables = (
  message: string,
  advisor: Partial<AdvisorModel>,
  equity: Partial<EquityFieldsType>,
  user: Partial<CurrentUserProfile>,
  team: Partial<Team>,
) => {
  const replaceMap = {
    granted_shares: numWithCommas(equity.granted_shares || 0),
    vesting_period: equity.vesting_period,
    cliff: equity.cliff_years,
    member_name: advisor.first_name,
    investment_amount: '$' + numWithCommas(equity.amount_invested || 0),
    valuation_cap: '$' + numWithCommas(equity.valuation || 0),
    company_name: team.name,
    sender_name: user.first_name,
  }

  for (const k in replaceMap) {
    const val = replaceMap[k as keyof typeof replaceMap]
    if (!val) continue

    message = replace(message, new RegExp(`{{${k}}}`, 'g'), val.toString())
  }

  return message
}
