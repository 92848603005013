import React from 'react'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useCurrentUser, useCurrentUserSettings } from 'store/hooks'
import FocusBanner from 'ui-components/FocusBanner'

interface Props {
  onGoogleAuthClick: () => void
  onMicrosoftAuthClick?: () => void
  showCta?: boolean
}

const FocusBannerGoogleAuth: React.VFC<Props> = ({
  onGoogleAuthClick,
  onMicrosoftAuthClick,
  showCta,
}) => {
  const { user: currentUser } = useCurrentUser()
  const { settings } = useCurrentUserSettings()

  if (!settings) return <></>

  const { dismissed_sync_gmail_banner } = settings

  const hasSyncedGmail = Object.values(currentUser.gmail_auth_statuses).some(
    (status) => status.has_synced_gmail,
  )

  return (
    <>
      {!dismissed_sync_gmail_banner && !hasSyncedGmail && !currentUser.connected_microsoft && (
        <FocusBanner
          avatar={<i className="far fa-envelope" />}
          title={<Typography fontWeight={600}>Connect email</Typography>}
          description="Send mail from your address"
          cta={
            <div className="flex gap-2">
              {showCta && (
                <CabalButton
                  onClick={() => onGoogleAuthClick()}
                  leftIcon={<i className="fab fa-google" />}
                  style={{ cursor: 'pointer' }}
                >
                  Gmail
                </CabalButton>
              )}
              {showCta && onMicrosoftAuthClick && (
                <CabalButton
                  onClick={onMicrosoftAuthClick}
                  leftIcon={<i className="fab fa-microsoft" />}
                  style={{ cursor: 'pointer' }}
                >
                  Outlook
                </CabalButton>
              )}
            </div>
          }
        />
      )}
    </>
  )
}

export default FocusBannerGoogleAuth
