import React, { useEffect, useMemo } from 'react'

import type { UniqueIdentifier } from '@dnd-kit/core'
import { defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable'
import type { Transform } from '@dnd-kit/utilities'
import { CSS } from '@dnd-kit/utilities'

import { SortableProps } from 'components/Sortable'

import { cn } from 'utils/styles'

export interface Props {
  id: UniqueIdentifier
  renderItem: SortableProps['renderItem']
}

export const Item = React.memo(({ id, renderItem }: Props) => {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
    index,
    isOver,
    isSorting,
  } = useSortable({
    id: id,
    animateLayoutChanges: defaultAnimateLayoutChanges,
  })

  const renderedItem = useMemo(() => {
    return renderItem({
      id: id,
      handleAttributes: {
        ...attributes,
        ...listeners,
      },
    })
  }, [attributes, id, listeners, renderItem])

  return (
    <div
      ref={setNodeRef}
      className={cn({
        'relative z-10 opacity-50 cursor-grabbing': isDragging,
        'cursor-grab': !isOver && !isDragging,
      })}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
    >
      {renderedItem}
    </div>
  )
})
