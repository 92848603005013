import axios from 'axios'

import {
  AdvisorOpportunityResponse,
  CompaniesApiResponse,
  CompaniesFiltersType,
  ConnectionStats,
  ConnectionStatusType,
  ConnectionsResponse,
  HomeConnectionStats,
  SFDCCompanyOpportunityResponse,
  SearchConnectionsResponse,
  SyncSalesForcesConnectionsResponse,
} from 'utils/types'

export default {
  getConnection: (uuid: string, teamSlug: string) => {
    return axios.get<ConnectionsResponse>(`/api/connections/${uuid}?team=${teamSlug}`)
  },

  getAdvisorConnections: (advisorId: string, teamSlug: string, page = 1) => {
    return axios.get<ConnectionsResponse>('/api/connections', {
      params: {
        advisor_id: advisorId,
        team_slug: teamSlug,
        page: page,
      },
    })
  },

  getAdvisorOpportunities: (teamSlug: string, page = 1) => {
    return axios.get<AdvisorOpportunityResponse>('/api/opportunities/for_advisor', {
      params: {
        team_slug: teamSlug,
        page: page,
      },
    })
  },

  getSalesLeaderboard: (teamSlug: string) => {
    return axios.get(`/api/votes/leaderboard?team=${teamSlug}`)
  },

  getConnections: (
    query: string[],
    companyList: string | undefined,
    members: string[],
    teamSlug: string,
    favoritesOnly: boolean,
    page = 1,
    excludeCompanyId: string | null = null,
    params = {},
  ) => {
    return axios.get<ConnectionsResponse>('/api/connections', {
      params: {
        q: query,
        company_list: companyList,
        members: members,
        team_slug: teamSlug,
        favorites_only: favoritesOnly,
        page: page,
        exclude_company_id: excludeCompanyId,
        ...params,
      },
    })
  },

  getUserConnections: (
    query: string[],
    page = 1,
    slug = null,
    advisor_id = null,
    per_page = null,
  ) => {
    return axios.get<ConnectionsResponse>('/api/connections', {
      params: {
        q: query,
        page: page,
        scope: 'mine',
        team_slug: slug,
        advisor_id: advisor_id,
        per_page: per_page,
      },
    })
  },

  searchConnections: (
    teamSlug: string,
    params: {
      query?: string[] | string
      advisor_uuids?: string[]
      group_uuids?: string[]
      scope: 'mine' | 'team' | 'all'
      page?: number
      per_page?: number
    },
  ) => {
    return axios.get<SearchConnectionsResponse>('/api/connections/search', {
      params: {
        team_slug: teamSlug,
        ...params,
      },
    })
  },

  getMyConnections: (query: string[], page = 1) => {
    return axios.get<ConnectionsResponse>('/api/connections/mine', {
      params: {
        q: query,
        page: page,
      },
    })
  },

  syncSalesForcesConnections: () => {
    return axios.post<SyncSalesForcesConnectionsResponse>('/api/connections/sync_sfdc')
  },

  getOpportunities: (
    team_slug: string,
    by_connection: boolean,
    hide_no_connections: boolean,
    query: string[],
    stage_names: string[],
    owner_emails: string[],
    members: string[],
    page = 1,
  ) => {
    return axios.get<SFDCCompanyOpportunityResponse>('/api/opportunities', {
      params: {
        team_slug,
        by_connection,
        hide_no_connections,
        query,
        stage_names,
        owner_emails,
        members,
        page,
      },
    })
  },

  getCompanies: (team_slug: string, page = 1, filters?: CompaniesFiltersType, params = {}) => {
    return axios.get<CompaniesApiResponse>('/api/companies', {
      params: {
        team_slug,
        page,
        filters: JSON.stringify(filters),
        ...params,
      },
    })
  },

  getConnectionStats: (team_slug: string) => {
    return axios.get<ConnectionStats>('/api/connections/stats', {
      params: {
        team_slug,
      },
    })
  },

  getHomeConnectionsStats: () => {
    return axios.get<ConnectionStatusType>('/api/connections/home_stats')
  },

  // This endpoint is to return the connections for the given uuid, which is the global_person_uuid
  getConnectionsByUuid: (id: string) => {
    return axios.get(`/api/v2/global_people/${id}/connections`)
  },
}
