import { trim } from 'lodash'
import { RequireExactlyOne } from 'type-fest'
import { AdvisorModel, Contact, GroupModel, LimitedUser, MessageRecipient } from 'utils/types'

export * from './useComposer'

/**
 * Send only one of the properties
 */
export function recipientValue(
  props: RequireExactlyOne<{
    advisor: AdvisorModel
    group: GroupModel
    contact: Contact
    user: LimitedUser
    email: string
  }>,
): MessageRecipient {
  const { advisor, group, contact, user, email } = props

  if (advisor) {
    return {
      label: advisor.name || advisor.email,
      value: advisor.uuid,
      type: 'advisor',
    }
  } else if (group) {
    return {
      label: `${group.name} (${group.advisor_groups_count})`,
      value: group.uuid,
      type: 'group',
    }
  } else if (contact) {
    let label = contact.email
    if (!!trim(contact.name || '')) {
      label = `${contact.name} - ${label}`
    }
    return {
      value: {
        email: contact.email,
        first_name: contact.given_name || '',
        last_name: contact.surname || '',
        add_member: false,
      },
      label,
      type: 'email',
    }
  } else if (user) {
    return {
      value: user.uuid,
      label: user.name || user.email,
      type: 'founder',
    }
  } else if (email) {
    return {
      value: {
        email: email,
        first_name: '',
        last_name: '',
        add_member: false,
      },
      label: email,
      type: 'email',
    }
  }

  throw {
    name: 'Unknown recipient type',
    value: props,
  }
}
