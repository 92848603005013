import React, { useEffect, useRef } from 'react'

import cx from 'classnames'
import sortBy from 'lodash/sortBy'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { EditListModal } from 'components/EditList'
import { DownChevron } from 'containers/ListView/Companies/styles'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { RenderModal, useModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'

import { omitFromObject } from 'utils/map'
import { CompanyListType } from 'utils/types'

import CompanyCard from './CompanyCard'
import SearchBlock from './SearchBlock'
import ShowMore from './ShowMore'
import Title from './Title'
import { HomeSectionWrapper } from './styles'

const Header = styled.div`
  /* .section-controls {
    display: none;
  }
  &:hover {
    .section-controls {
      display: flex;
    }
  } */
`

interface Props {
  list: CompanyListType
  listData: any
  teamSlug: string
  advisorId: string
  selectedListSlug?: string
  isHome?: boolean
  people: any
  data: any
  showBackButton?: boolean
  hideHeader?: boolean
  handleAttributes?: any
  isAdmin: boolean
  reloadHome: () => void
  ssid?: string
}

const SectionHeader: React.FC<Props> = ({
  handleAttributes,
  teamSlug,
  list,
  reloadHome,
  showBackButton = false,
  isAdmin = false,
  hideHeader = false,
}) => {
  const history = useHistory()
  const { team } = useTeam(teamSlug)
  const { showModal } = useModal()

  let headerTitle
  const headerLink = () => history.push(`/${teamSlug}/lists/${list.slug}`)

  if (list.default_type_identifier == 'connections') {
    headerTitle = `${team?.name} connections`
  } else {
    headerTitle = list.name
  }

  const renderEditListModal: RenderModal = (resolve) => (
    <EditListModal
      teamSlug={teamSlug}
      onHide={() => resolve(false)}
      reload={() => reloadHome?.()}
      onSubmit={() => resolve(false)}
      listUuid={list.uuid}
    />
  )

  const onEdit = () => {
    showModal(renderEditListModal, 'render_edit_list_modal')
  }

  if (hideHeader) return <></>

  return (
    <Header className="flex items-center justify-between pb-1">
      <div className="flex items-center w-full">
        {showBackButton && (
          <DownChevron
            className="mr-2 cursor-pointer fa fa-chevron-left"
            onClick={() => history.push(`/${teamSlug}`)}
          />
        )}
        <Title label={headerTitle} onClick={headerLink} />
      </div>
      {(isAdmin || handleAttributes) && (
        <div className="flex">
          <div className="section-controls flex space-x-2">
            {isAdmin && (
              <CabalButton
                variant="tertiary"
                onClick={onEdit}
                padding="0 4px"
                leftIcon={<i className="fa fa-edit" />}
              />
            )}
            {handleAttributes && (
              <CabalButton
                {...handleAttributes}
                variant="tertiary"
                onClick={onEdit}
                padding="0"
                leftIcon={<i className="far fa-reorder" />}
              />
            )}
          </div>
        </div>
      )}
    </Header>
  )
}

const SectionMain: React.FC<Props> = React.memo(
  ({ list, listData, teamSlug, isHome = false, people = [], data = {}, ssid }) => {
    const { isAdminOrEmployee, isAdvisor } = useAccessControl(teamSlug)
    const gridView = list.display_mode === 'grid'
    const [limit, setLimit] = React.useState(gridView ? 4 : 3)
    const history = useHistory()

    const allCompanies = (listData?.items_with_matches || []).concat(
      sortBy(listData?.items_without_matches || [], (item) => item.name),
    )

    const companies = allCompanies.slice(0, limit)

    useEffect(() => {
      if (!isHome) {
        setLimit(allCompanies.length)
      }
    }, [isHome, allCompanies.length])

    let body

    if (
      list.slug &&
      list.default_type_identifier &&
      list.default_type_identifier == 'connections'
    ) {
      body = (
        <SearchBlock
          teamSlug={teamSlug}
          listSlug={list.slug}
          itemCount={list.companies_count}
          className="-my-2"
        />
      )
    } else {
      body = (
        <>
          <div className={cx('-mt-2', { 'grid grid-cols-4 gap-2': gridView })}>
            {companies.map((company) => (
              <CompanyCard
                listUuid={list.uuid}
                icps={list.icps}
                key={company.id}
                company={company}
                teamSlug={teamSlug}
                people={people}
                peopleVotes={data.people_votes}
                companyVotes={data.company_votes}
                gridView={gridView}
                ssid={ssid}
              />
            ))}
          </div>

          {isHome && (
            <>
              <ShowMore
                link={`/${teamSlug}/lists/${list.slug}`}
                limit={3}
                objectArray={allCompanies}
                title={`See all (${allCompanies.length})`}
                hideMore={false}
              />
            </>
          )}
        </>
      )
    }

    return (
      <>
        {!isHome && !isAdminOrEmployee && isAdvisor && (
          <div className="flex pb-3 mt-4">
            <Typography
              color={'rain'}
              fontSize="12"
              className="mr-2 cursor-pointer"
              onClick={() => history.goBack()}
              component="button"
            >
              <i className="far fa-chevron-left" />
            </Typography>
            <Typography fontSize="14" fontWeight={600}>
              {list.name}
            </Typography>
          </div>
        )}
        {body}
      </>
    )
  },
)

const Section: React.FC<Props> = (props) => {
  const WrapperElem = props.hideWrapper ? React.Fragment : HomeSectionWrapper

  return (
    <>
      <WrapperElem id={props.list.slug}>
        <SectionHeader {...props} />
        <SectionMain {...omitFromObject(props, ['handleAttributes'])} />
      </WrapperElem>
    </>
  )
}

export default Section
