import React, { useState } from 'react'

import { AxiosError } from 'axios'
import trim from 'lodash/trim'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import { CheckBox, MultiSelect, TextArea } from 'global/Input'
import UploadZone, { UploadZoneRef } from 'global/Input/UploadZone'
import ProgressBar from 'global/ProgressBar'
import Tabs from 'global/Tabs'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { useContacts } from 'utils/api/contacts'
import { Contact } from 'utils/types'
import { ImportedMember } from 'utils/types'

export const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`

export const StyledTextArea = styled(TextArea)`
  ${tw`w-full`}
`

interface Props {
  setMembers: (m: ImportedMember[]) => void
  setImportedColumns: (m: string[]) => void
  invitePostImport: boolean
  setInvitePostImport: (m: boolean) => void
  teamSlug: string
  heading?: string
  canSkip: boolean
  advisorImportUuid?: string
  previewInModal?: boolean
  setAdvisorImportUuid: (u: string) => void
}

const Upload: React.FC<Props> = ({
  setMembers,
  teamSlug,
  heading,
  canSkip,
  setImportedColumns,
  advisorImportUuid,
  setAdvisorImportUuid,
  invitePostImport,
  setInvitePostImport,
  previewInModal = false,
}) => {
  const history = useHistory()

  const { user } = useCurrentUser()
  const [allContacts, getContacts, gettingContacts] = useContacts()

  const [emails, setEmails] = useLocalStorage<string>('import_emails_cache')
  const [file, setFile] = useState<File>()
  const [contacts, setContacts] = useState<Contact[]>()
  const [viewMode, setViewMode] = useState<'emails' | 'csv'>('emails')

  const [errors, setErrors] = useState<string[]>()
  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [totalAdvisorCount, setTotalAdvisorCount] = useState<number>()
  const [checkingAdvisorImport, setCheckingAdvisorImport] = useState(false)
  const [checkAdvisorImportScrapeStatus, setCheckAdvisorImportScrapeStatus] = useState(false)

  const uploadZoneRef = React.useRef<UploadZoneRef>(null)

  useQuery(
    ['advisor_imports', advisorImportUuid],
    () => {
      setCheckingAdvisorImport(true)
      return callApi(api.enrichStatusAdvisorImport, teamSlug, advisorImportUuid!)
    },
    {
      enabled: !!advisorImportUuid && checkAdvisorImportScrapeStatus,
      onSuccess: ({
        status,
        advisors,
        imported_columns,
        total_advisor_count,
        enriched_advisor_count,
      }) => {
        if (status === 'finished') {
          setCheckingAdvisorImport(false)
          setProgress(1)
          setImportedColumns(imported_columns)
          setTimeout(() => setMembers(advisors), 500)
        } else {
          setProgress(enriched_advisor_count / total_advisor_count)
          setTotalAdvisorCount(total_advisor_count)
        }
      },
      refetchInterval: 3000,
    },
  )

  const { mutate: createAdvisorImport, isLoading: creatingAdvisorImport } = useMutation(
    (upload_uuid?: string) =>
      callApi(api.enrichAdvisorImport, {
        upload_uuid,
        emails,
        contact_uuids: contacts?.map((c) => c.uuid),
        team_slug: teamSlug,
      }),
    {
      onSuccess: ({ advisor_import_uuid }) => {
        setAdvisorImportUuid(advisor_import_uuid)
        setCheckAdvisorImportScrapeStatus(true)
      },
      onError: (error: AxiosError) => {
        const errors = error.response?.data?.errors
        if (errors) {
          setErrors(errors)
        } else {
          cabalToast({
            style: 'error',
            content: `There was an error reading the CSV. Please check it is formatted correctly and try again.`,
          })
        }
      },
    },
  )

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()

    setErrors(undefined)

    let upload_uuid
    if (!!file && uploadZoneRef.current) {
      setUploading(true)
      const [{ uuid }] = await uploadZoneRef.current.uploadFiles()
      upload_uuid = uuid
      setUploading(false)
    }

    createAdvisorImport(upload_uuid)
  }

  const AddByEmail = (
    <StyledTextArea
      data-testid="import-advisor-emails"
      placeholder={'E.g. "Jane Doe <jane@example.org>,John Doe <john@example.org>"'}
      value={emails}
      autoFocus
      onChange={(e) => setEmails(e.target.value)}
      style={{ height: 200 }}
      className="mt-4"
    />
  )

  const AddByCSV = (
    <div>
      <Typography
        fontSize="14"
        fontWeight={600}
        color="purple"
        component="div"
        className="text-center mt-4"
      >
        <a href="/Cabal Import Format.csv" download>
          <Typography className="underline whitespace-nowrap">Download CSV Template</Typography>
        </a>
        {' • '}
        <a
          // eslint-disable-next-line max-len
          href="https://docs.google.com/spreadsheets/d/1ao7kHD2m4NsC_qcLOJdQH03hSbsQ6mGOHDNPQ5Soq0g/edit#gid=1800561879"
          target="_blank"
          rel="noreferrer"
        >
          <Typography className="underline whitespace-nowrap">Open in Google Sheets</Typography>
        </a>

        <div className="mt-4">
          <UploadZone
            ref={uploadZoneRef}
            onSelect={([file]) => {
              if (!file) return
              setEmails('')
              setErrors()
              setFile(file)
            }}
            accept={['csv']}
            hideDndOnSelect
          />
        </div>
      </Typography>
    </div>
  )

  const Errors = (
    <div className="mt-4">
      {errors && (
        <Typography color="border_danger" fontSize="14">
          {errors.map((e, i) => (
            <div key={`${e}${i}`}>{e}</div>
          ))}
        </Typography>
      )}
    </div>
  )

  const ProgressBarComponent = (
    <>
      {checkingAdvisorImport && totalAdvisorCount && (
        <div className="my-base">
          <ProgressBar ratio={progress} label={`Enriching ${totalAdvisorCount} members`} />
        </div>
      )}
    </>
  )

  const AddMemberButton = (
    <CabalButton
      className="w-full mt-3"
      variant="primary"
      working={creatingAdvisorImport || checkingAdvisorImport || uploading}
      disabled={!file && !trim(emails) && !contacts}
      data-testid="upload-button"
      onClick={onSubmit}
    >
      Add Members
    </CabalButton>
  )

  return (
    <>
      {previewInModal && (
        <div>
          <div className="flex gap-4 pb-2 dark:border-[#2d3748] border-[#E3E5E8] border-b-[1px]">
            <Typography
              fontSize="14"
              color={viewMode === 'emails' ? 'purple' : 'fog'}
              className="mt-2 cursor-pointer"
              component="button"
              onClick={() => setViewMode('emails')}
            >
              Add by Email
            </Typography>
            <Typography
              fontSize="14"
              color={viewMode === 'csv' ? 'purple' : 'fog'}
              className="mt-2 cursor-pointer"
              component="button"
              onClick={() => setViewMode('csv')}
            >
              Add by CSV
            </Typography>
          </div>

          <div className="my-2">
            {viewMode === 'emails' && AddByEmail}
            {viewMode === 'csv' && AddByCSV}
            {errors && Errors}
            <div className="mt-2 mb-4">
              {ProgressBarComponent} {AddMemberButton}
            </div>
          </div>
        </div>
      )}

      {!previewInModal && (
        <>
          <Typography fontSize="18" fontWeight={600} component="h3">
            {heading || `Add Members`}
          </Typography>

          <form className="mt-4 w-full" onSubmit={onSubmit}>
            <Tabs
              tabListclassName="justify-left"
              pushSubRoutes
              onChange={(_, tab) => {
                if (tab === 'emails') {
                  setFile(undefined)
                  setContacts(undefined)
                } else if (tab === 'csv') {
                  setEmails('')
                  setContacts(undefined)
                } else if (tab === 'contacts') {
                  setFile(undefined)
                  setEmails('')
                }
              }}
              data={[
                {
                  id: 'emails',
                  label: 'By Email',
                  component: AddByEmail,
                },
                {
                  label: 'Upload CSV',
                  id: 'csv',
                  component: AddByCSV,
                },
                user.has_synced_gcontacts
                  ? {
                      id: 'contacts',
                      label: 'Google Contacts',
                      component: (
                        <div className="mt-4">
                          <MultiSelect<Contact>
                            noDropdownIndicator
                            value={contacts}
                            onChange={(v) => setContacts(v)}
                            options={allContacts.map((c) => ({
                              label: `${c.email}${c.name ? ` - ${c.name}` : ''}`,
                              value: c,
                            }))}
                            isLoading={gettingContacts}
                            onInputChange={(v) => {
                              getContacts(v)
                            }}
                            placeholder="Search contacts"
                            noOptionsMessage={
                              allContacts.length === 0 ? () => 'Start typing' : undefined
                            }
                          />
                        </div>
                      ),
                    }
                  : null,
              ]}
            />

            {Errors}
            <div className="mt-4">
              {ProgressBarComponent}

              <CheckBox
                checked={invitePostImport}
                onChange={(e) => setInvitePostImport(e.currentTarget.checked)}
                label={
                  <>
                    <Typography lineHeight={1.125} component="div">
                      Invite member(s)
                    </Typography>
                    <Typography color="fog" fontSize="11" lineHeight={1.125}>
                      You can customize the invite before sending
                    </Typography>
                  </>
                }
              />

              <CabalButton
                className="w-full mt-3"
                variant="primary"
                working={creatingAdvisorImport || checkingAdvisorImport || uploading}
                disabled={!file && !trim(emails) && !contacts}
                data-testid="upload-button"
              >
                Add Members
              </CabalButton>
              {canSkip && (
                <div className="flex justify-end">
                  <CabalButton
                    className="mt-4"
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()

                      history.push(`/${teamSlug}`)
                    }}
                  >
                    Skip for now
                  </CabalButton>
                </div>
              )}
            </div>
          </form>
        </>
      )}
    </>
  )
}

export default Upload
