import React from 'react'

import styled from 'styled-components'

import { EditListModal, EditListProps } from 'components/EditList'
import Modal, { useModal } from 'global/Modal'
import Typography from 'global/Typography'

const CardWrapper = styled.div`
  border: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.colors.mist};
`

const Card = ({
  icon,
  title,
  description,
  onClick,
}: {
  icon: React.ReactNode
  title: string
  description: string
  onClick: () => void
}) => {
  return (
    <CardWrapper className="px-4 py-2 rounded" onClick={onClick}>
      <div className="flex items-start gap-2.5">
        <Typography color="purple">{icon}</Typography>
        <div className="flex flex-col">
          <Typography fontWeight={600} fontSize="14">
            {title}
          </Typography>
          <Typography color="fog" lineHeight={1.2} fontSize="12">
            {description}
          </Typography>
        </div>
      </div>
    </CardWrapper>
  )
}

interface Props {
  onHide: () => void
  teamSlug: string
}

const EditListV2: React.FC<Props> = ({ onHide, teamSlug }) => {
  const { showModal } = useModal()

  const renderNewListModal = (
    resolve: () => void,
    listType: EditListProps['listType'],
    listName?: string,
    listDescription?: string,
    listTags?: string[],
  ) => (
    <EditListModal
      dynamic={false}
      onHide={() => resolve()}
      teamSlug={teamSlug}
      listType={listType}
      listName={listName}
      listDescription={listDescription}
      listTags={listTags}
    />
  )

  return (
    <Modal show={true} onHide={onHide}>
      <div className="flex py-16 items-center gap-2 h-full">
        <div className="h-full flex flex-col max-w-56">
          <div className="flex items-center gap-4">
            <Typography color="fog">
              <i className="far fa-list" />
            </Typography>
            <Typography fontSize="24" fontWeight={600}>
              Create a List
            </Typography>
          </div>
          <Typography fontSize="14" className="mt-2" lineHeight={1.2}>
            Create Lists to share asks, updates and offers.
          </Typography>
        </div>

        <div className="border-l -ml-4 border-[#A7B4C7] flex-grow h-[400px] -my-8" />

        <div className="flex flex-col">
          <Typography color="fog" fontSize="14" className="mb-4">
            Choose a template
          </Typography>
          <div className="flex flex-col gap-4">
            <Card
              icon={<i className="far fa-rocket" />}
              title="Sales"
              description="Find and request warm intros to new customers"
              onClick={() => {
                return showModal(
                  (resolve) => renderNewListModal(resolve, undefined),
                  'render_new_list_modal',
                )
              }}
            />
            <Card
              icon={<i className="far fa-money-check-dollar-pen" />}
              title="Fundraise"
              description="Find and request warm intros to investors"
              onClick={() => {
                return showModal(
                  (resolve) => renderNewListModal(resolve, undefined),
                  'render_new_list_modal',
                )
              }}
            />
            <Card
              icon={<i className="far fa-star" />}
              title="Talent"
              description="Get help with open roles or offer access to candidates"
              onClick={() => {
                return showModal(
                  (resolve) => renderNewListModal(resolve, undefined),
                  'render_new_list_modal',
                )
              }}
            />
            <Card
              icon={<i className="far fa-screwdriver-wrench" />}
              title="Resources"
              description="Offer resources to your members"
              onClick={() => {
                return showModal(
                  (resolve) => renderNewListModal(resolve, undefined),
                  'render_new_list_modal',
                )
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditListV2
