import React, { useEffect, useMemo, useState } from 'react'

import cx from 'classnames'
import { compact, first, flatten, last } from 'lodash'
import CabalMessage from 'models/CabalMessage'
import objectHash from 'object-hash'
import Expand from 'react-expand-animated'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'

import FeedEmptyState from 'components/EmptyStates/FeedEmptyState'
import { FiltersValueType, renderFilters } from 'components/Filters'
import MessageFeedCard from 'components/MessageFeedCard'
import { NewScreenMail } from 'components/NewUserScreens'
import ViewMessage from 'containers/ViewMessage'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { SelectOption } from 'global/Input'
import Loading from 'global/Loading'
import CabalTitle from 'global/Title'
import Typography from 'global/Typography'
import { useAdvisors, useCurrentUserSettings, useGroups, useTeam } from 'store/hooks'
import { useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'
import { CompanySlugParam, InboxMessageBlueprint, MessageModel } from 'utils/types'

import Subtabs from '../Subtabs'
import { InboxFilter } from '../styles'

const FiltersDiv = styled.div`
  ${tw`sticky pt-2 pb-1`}
  background-color: ${({ theme }) => theme.layout.main_bg_color};
  top: 0px;
  z-index: 10;
`

const NavWrapper = styled.div<{ splitView: boolean }>`
  width: ${({ theme, splitView }) => !splitView && theme.breakpoints.restricted};
`

const Wrapper = styled.div<{ splitView: boolean }>`
  margin-right: ${({ splitView }) => splitView && '4px'};
`

interface Props {
  onSplitView?: (val: boolean) => void
}

const MessagesInbox: React.VFC<Props> = ({ onSplitView }) => {
  const { company_slug: teamSlug } = useParams<CompanySlugParam>()
  const draftFrom = useSearchParam('draft_from')
  const location = useLocation()
  const history = useHistory()

  const group = { uuid: 'all' }
  const { advisors } = useAdvisors({ teamSlug: teamSlug })
  const { team } = useTeam(teamSlug)
  const { settings, updateSetting } = useCurrentUserSettings()
  const { groups } = useGroups(teamSlug)
  const { user } = useCurrentUser()

  const [childrenHeight, setChildrenHeight] = useState<number>()

  const [openArchive, setOpenArchive] = useState(false)
  const [filters, setFilters] = useState<FiltersValueType>({})
  const [splitView, setSplitView] = useState<boolean>()
  const { isMobile } = useColorMode()
  const [inbox, _setInbox] = useState(false)

  const canSplitView = !isMobile
  const selectedMessageId = location.hash.match(/#(.+)/)?.[1]

  const setSelectedMessage = (m?: CabalMessage) => {
    if (!canSplitView || selectedMessageId === m?.uuid) return

    if (m === undefined) {
      history.replace(location.pathname)
      return
    }

    if (splitView) {
      const path = `${location.pathname}#${m.uuid}`
      if (!selectedMessageId) history.replace(path)
      else history.push(path)
    }
  }

  const setInbox = (val: boolean) => {
    if (inbox === val) return

    _setInbox(val)
    setSelectedMessage(undefined)
  }

  useEffect(() => {
    if (!canSplitView) return

    setSplitView(!!settings.messages_split_view)
  }, [settings.messages_split_view, canSplitView])

  useQuery(
    ['getMessage', teamSlug, draftFrom],
    () => callApi(api.getMessage, teamSlug, draftFrom!),
    { enabled: !!draftFrom },
  )

  const getMessagesQuery = useInfiniteQuery(
    [teamSlug, 'getMessages', objectHash(filters), inbox],

    ({ pageParam: page = 1 }) =>
      callApi(api.getGroupMessages, {
        group_uuid: group.uuid,
        team_slug: teamSlug,
        filters,
        page,
      }),
    {
      getNextPageParam: (lastPage) => lastPage?.pagination?.next_page,
      getPreviousPageParam: (firstPage) => firstPage?.pagination?.prev_page,
      enabled: !inbox,
    },
  )

  const {
    data: messagesData,
    isLoading,
    refetch: loadMessages,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = getMessagesQuery

  // const { data: requestData } = useQuery([company_slug, 'requests'], () =>
  //   callApi(api.getPinnedRequests, company_slug),
  // )

  const messages = useMemo(
    () =>
      CabalMessage.fromArray(
        messagesData?.pages?.flatMap<InboxMessageBlueprint | MessageModel>((page) => page.messages),
      ),
    [messagesData],
  )
  const archiveCount = messages?.filter((msg) => msg.is_archived).length
  const selectedMessage = useMemo(
    () => messages?.find((m) => m.uuid === selectedMessageId),
    [messages, selectedMessageId],
  )

  useEffect(() => {
    if (!canSplitView) return

    if (splitView !== undefined) {
      if (splitView !== settings.messages_split_view) {
        updateSetting('messages_split_view', splitView)
      }
      if (!selectedMessageId) {
        setSelectedMessage(first(messages))
      }
      onSplitView?.(splitView)
    }
  }, [splitView, canSplitView, messages])

  useEffect(() => {
    if (inbox !== settings.inbox) {
      updateSetting('inbox', inbox)
    }
  }, [inbox])

  const renderMessage = (message: CabalMessage) => (
    <MessageFeedCard
      group={group}
      message={message}
      reloadMessages={loadMessages}
      key={message.uuid}
      canDelete
      showPrivacy
      selected={splitView && selectedMessageId === message.uuid}
      onClick={
        !splitView
          ? undefined
          : () => {
              setSelectedMessage(message)
            }
      }
      minimalView={splitView}
    />
  )

  if (isLoading) {
    return <Loading className="my-12" />
  }

  const usersAndMembers: SelectOption<string>[] = compact(
    flatten([
      advisors?.map((a) => ({
        label: `${a.name} - ${a.email}`,
        value: `advisor:${a.uuid}`,
        group: 'Members',
      })),
      team?.admins_and_members?.map((a) => ({
        label: `${a.name} - ${a.email}`,
        value: `user:${a.uuid}`,
        group: 'Employees',
      })),
    ]),
  )

  const usersAndMembersAndGroups: SelectOption<string>[] = compact(
    flatten([
      usersAndMembers,
      groups.map((g) => ({ label: g.name, value: `group:${g.uuid}`, group: 'Group' })),
    ]),
  )

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: [
      {
        key: 'senders',
        label: 'Sender',
        type: 'dropdown',
        options: usersAndMembers,
      },
      {
        key: 'recipients',
        label: 'Recipient',
        type: 'dropdown',
        options: usersAndMembersAndGroups,
      },
    ],
    value: filters,
    onChange: setFilters,
  })

  return (
    <div>
      <NavWrapper className="mx-auto" splitView={splitView}>
        <FiltersDiv className="flex justify-between px-2">
          <div className="flex flex-wrap gap-2 items-center">
            <Subtabs />
          </div>

          <div className="flex items-center gap-2">
            {/* {appliedFilters}
            {renderedFilters} */}
            {user.features?.inbox && (
              <div>
                <InboxFilter className={cx({ selected: !inbox })} onClick={() => setInbox(false)}>
                  Sent via Cabal
                </InboxFilter>
                <InboxFilter className={cx({ selected: inbox })} onClick={() => setInbox(true)}>
                  Inbox
                </InboxFilter>
              </div>
            )}
            {canSplitView && (
              <CabalButton
                onClick={() => setSplitView(!splitView)}
                leftIcon={
                  <i className={cx('far', splitView ? 'fa-bars fa-fw' : 'fa-columns fa-fw')} />
                }
                variant="tertiary"
                tooltip={splitView ? 'Switch to list view' : 'Switch to split view'}
              />
            )}
          </div>
        </FiltersDiv>
      </NavWrapper>

      <div
        ref={(ref) => setChildrenHeight(ref?.offsetTop)}
        // className="overflow-hidden h-full"
        style={{
          height: childrenHeight && splitView ? `calc(100vh - 176px)` : undefined,
        }}
        id="message-split-view"
      >
        {messages?.length !== 0 && (
          <div className="grid grid-cols-7 h-full">
            <Wrapper
              splitView={!!splitView}
              className={cx(splitView ? 'col-span-2' : 'col-span-7', 'overflow-y-auto')}
            >
              {messages && messages?.filter((m) => m.is_pinned).length > 0 && (
                <div>
                  {/* TODO : Need backend for advisor asks */}
                  {/* {advisor &&
              requestData?.map((req: RequestModel) => <PinnedAsk key={req.uuid} req={req} />)} */}

                  {messages?.filter((m) => m.is_pinned).map((message) => renderMessage(message))}
                </div>
              )}
              {messages
                ?.filter((m) => !m.is_archived && !m.is_pinned)
                ?.map((message) => renderMessage(message))}

              {hasNextPage && (
                <div className="flex justify-center	my-6">
                  <CabalButton disabled={isFetchingNextPage} onClick={() => fetchNextPage()}>
                    Load more
                  </CabalButton>
                </div>
              )}

              {!!archiveCount && archiveCount > 0 && (
                <div
                  className="flex justify-between items-center mt-9 py-1.5 cursor-pointer"
                  onClick={() => setOpenArchive(!openArchive)}
                >
                  <Typography
                    textTransform="uppercase"
                    fontWeight={500}
                    fontSize="12"
                    className="block"
                    color="widget_title"
                    letterSpacing="0.05em"
                  >
                    {`Archive (${archiveCount})`}
                  </Typography>
                  <Typography fontSize="12" color="fog_rain">
                    {openArchive ? (
                      <i className="far fa-chevron-down"></i>
                    ) : (
                      <i className="far fa-chevron-right"></i>
                    )}
                  </Typography>
                </div>
              )}
              <Expand open={openArchive}>
                {messages
                  ?.filter((m) => !!m.is_archived && !m.is_pinned)
                  ?.map((message) => renderMessage(message))}
              </Expand>
            </Wrapper>
            {splitView && selectedMessage && (
              <div className="col-span-5">
                <ViewMessage message_id={selectedMessage.uuid} splitView={splitView} />
              </div>
            )}
          </div>
        )}

        {messages?.length === 0 && appliedFilters.length === 0 && (
          <div className="flex items-center justify-center mt-2 mx-auto">
            <NewScreenMail />
          </div>
        )}

        {messages?.length === 0 && appliedFilters.length > 0 && (
          <div className="mt-2">
            <FeedEmptyState teamSlug={teamSlug} appliedFilters={appliedFilters.length > 0} />
          </div>
        )}
      </div>
    </div>
  )
}

export default MessagesInbox
