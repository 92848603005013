import { createBrowserHistory } from 'history'
import QS from 'query-string'
import React from 'react'
import { Router } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

const history = createBrowserHistory()

const ReactRouterProvider: React.FC = ({ children }) => {
  return (
    <Router history={history}>
      <QueryParamProvider
        adapter={ReactRouter5Adapter}
        options={{
          searchStringToObject: QS.parse,
          objectToSearchString: QS.stringify,
        }}
      >
        {children}
      </QueryParamProvider>
    </Router>
  )
}

export { history }
export default ReactRouterProvider
