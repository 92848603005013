import React from 'react'

import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import AdvisorProgram from 'containers/Settings/AdvisorProgram'
import Agreements from 'containers/Settings/Agreements'
import AskSettings from 'containers/Settings/AsksSettings'
import Company from 'containers/Settings/Company'
import Messages from 'containers/Settings/Company/Messages'
import Integration from 'containers/Settings/Integration'
import Investors from 'containers/Settings/Investors'
import Members from 'containers/Settings/Members'
// import Billing from 'containers/Settings/Billing'
import TeamUsers from 'containers/Settings/TeamUsers'
import { useAccessControl } from 'global/AccessControl'
import PageWrapper from 'global/PageWrapper'
import Tabs from 'global/Tabs'
import { useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'

const CompanyAdmin = () => {
  const teamSlug = useTeamSlug()
  const { user } = useCurrentUser()
  const { team } = useTeam(teamSlug)
  const { canViewEquity, canManageCompany } = useAccessControl(teamSlug)
  const location = useLocation()

  const tabs = [
    {
      label: 'General',
      id: 'company',
      component: <Company />,
      active: location.pathname.endsWith('/company'),
    },
    {
      label: 'Requests',
      id: 'asks',
      component: <AskSettings />,
      active: location.pathname.endsWith('/asks'),
    },
    {
      label: 'Mail',
      id: 'analytics',
      component: <Messages />,
      active: location.pathname.endsWith('/analytics'),
    },
    team?.enable_portfolio && {
      label: 'Talent',
      id: 'talent',
      component: <Investors />,
      active: location.pathname.endsWith('/talent'),
    },
    {
      label: 'Agreements',
      id: 'agreements',
      component: <Agreements />,
      active: location.pathname.endsWith('/agreements'),
    },
    canViewEquity && {
      label: 'Advisor Program',
      id: 'advisor_program',
      component: <AdvisorProgram />,
      active: location.pathname.endsWith('/advisor_program'),
    },
    {
      label: 'Integrations',
      id: 'integrations',
      component: <Integration />,
      active: location.pathname.endsWith('/integrations'),
    },
    {
      label: 'Team',
      id: 'team',
      component: <TeamUsers />,
      active: location.pathname.endsWith('/team'),
    },
  ]

  // if (team?.flags.custom_fields) {
  //   tabs.push({ label: 'Members', component: <Members />, id: 'members' })
  // }

  // if (canManageBilling) {
  //   tabs.push({ label: 'Billing', component: <Billing />, id: 'billing' })
  // }

  const isTalentCoordinator = user.uuid === team?.talent_coordinator

  if (!canManageCompany && !isTalentCoordinator) return null

  return (
    <PageWrapper title="Settings">
      <div className="pb-12 z-30">
        <Tabs tabListclassName="justify-left" data={tabs} pushSubRoutes nestedSticky={true} />
      </div>
    </PageWrapper>
  )
}

export default CompanyAdmin
