import React, { useState } from 'react'

import { useQuery } from 'react-query'
import styled from 'styled-components'

import { useComposer } from 'components/Composer'
import CrmIcon from 'components/CrmIcon'
import { EditListModal, EditListProps } from 'components/EditList'
import { Options } from 'components/EditList/TemplateSelector'
import { useModal } from 'global/Modal'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { CompanyListBlueprint } from 'utils/types'

const OptionWrap = styled.div<{ active: boolean }>`
  background: ${({ theme }) => theme.colors.mist};
  border: 1px solid ${({ theme, active }) => (active ? theme.colors.purple : theme.colors.border)};
`

const PillWrap = styled.div<{ color: string }>`
  background: ${({ color }) => color};
`

const Option = ({
  onClick,
  title,
  description,
  pillIcon,
  pillText,
  pillColor,
  active,
}: {
  onClick: () => void
  title: string
  description: string
  pillIcon: string
  pillText: string
  pillColor: string
  active: boolean
}) => {
  return (
    <OptionWrap
      className="flex flex-col justify-between cursor-pointer h-[120px] p-4 rounded-lg"
      onClick={onClick}
      active={active}
    >
      <div className="flex justify-end">
        <PillWrap color={pillColor} className={`flex items-center gap-1 w-fit rounded px-1 py-0.5`}>
          <Typography fontSize="10" color="white">
            <i className={pillIcon} />
          </Typography>
          <Typography fontSize="10" color="white">
            {pillText}
          </Typography>
        </PillWrap>
      </div>
      <div className="flex flex-col">
        <Typography color="fog" lineHeight={1} fontSize="14" className="mt-2">
          {title}
        </Typography>
        <Typography color="rain" fontSize="12">
          {description}
        </Typography>
      </div>
    </OptionWrap>
  )
}

interface Props {
  teamSlug: string
}

const CreateList: React.FC<Props> = ({ teamSlug }) => {
  const { showModal } = useModal()
  const { team } = useTeam(teamSlug)
  const { compose } = useComposer()

  const settingsQuery = useQuery([teamSlug, 'teamSettings'], () => callApi(api.getTeamSettings))
  const team_connected_sfdc = settingsQuery.data?.team_connected_sfdc
  const team_connected_hubspot = settingsQuery.data?.team_connected_hubspot

  const [link, setLink] = useState('')

  const renderNewDynamicListModal = (
    resolve: () => void,
    provider?: CompanyListBlueprint['provider'],
  ) => (
    <EditListModal
      dynamic={true}
      onHide={() => resolve()}
      listType={'companies'}
      provider={provider}
      teamSlug={teamSlug}
    />
  )

  const renderNewListModal = (resolve: () => void, listType: EditListProps['listType']) => (
    <EditListModal
      dynamic={false}
      onHide={() => resolve()}
      teamSlug={teamSlug}
      listType={listType}
      listItems={[link]}
    />
  )

  const noCrmConnected = !team_connected_sfdc && !team_connected_hubspot

  return (
    <div className="flex gap-2">
      {team?.enable_portfolio && (
        <Tooltip
          label={
            <Typography component="div" fontSize="14" className="py-1">
              Request access to the sales pipelines of your portfolio companies so you can see where
              your firm can help.
            </Typography>
          }
        >
          <Options
            title={'Request Pipeline'}
            icon={<i className="far fa-file-import"></i>}
            onClick={() => compose({ template_key: 'request-pipeline' })}
            selected={false}
          />
        </Tooltip>
      )}

      <Options
        title={'Companies'}
        icon={<i className="far fa-building"></i>}
        data_testid="create-companies-list-pill"
        onClick={() =>
          showModal((resolve) => renderNewListModal(resolve, 'companies'), 'render_new_list_modal')
        }
        selected={false}
      />

      <Options
        title={'Personas'}
        icon={<i className="far fa-users-line"></i>}
        data_testid="create-personas-list-pill"
        onClick={() =>
          showModal((resolve) => renderNewListModal(resolve, 'personas'), 'render_new_list_modal')
        }
        selected={false}
      />

      <Options
        title={'Fundraise'}
        icon={<i className="far fa-money-bill-trend-up"></i>}
        data_testid="create-fundraise-list-pill"
        onClick={() =>
          showModal((resolve) => renderNewListModal(resolve, 'fundraise'), 'render_new_list_modal')
        }
        selected={false}
      />

      <Options
        title={'Mail'}
        icon={<i className="far fa-messages"></i>}
        data_testid="create-messages-list-pill"
        onClick={() =>
          showModal((resolve) => renderNewListModal(resolve, 'mail'), 'render_new_list_modal')
        }
        selected={false}
      />

      {(team_connected_sfdc || noCrmConnected) && (
        <Options
          title={'Salesforce'}
          icon={<CrmIcon crm="salesforce" />}
          data_testid="create-salesforce-list-pill"
          onClick={() =>
            team_connected_sfdc
              ? showModal(
                  (resolve) => renderNewDynamicListModal(resolve, 'salesforce'),
                  'render_hubspot_list_modal',
                )
              : window.location.assign(`/salesforce_auth`)
          }
          selected={false}
        />
      )}
      {(team_connected_hubspot || noCrmConnected) && (
        <Options
          title={'Hubspot'}
          icon={<CrmIcon crm="hubspot" />}
          data_testid="create-hubspot-list-pill"
          onClick={() =>
            team_connected_hubspot
              ? showModal(
                  (resolve) => renderNewDynamicListModal(resolve, 'hubspot'),
                  'render_hubspot_list_modal',
                )
              : window.location.host === 'localhost:3000'
              ? window.location.assign(`/auth/hubspotdev?return_to=${window.location.href}`)
              : window.location.assign(`/auth/hubspot?return_to=${window.location.href}`)
          }
          selected={false}
        />
      )}

      <Options
        title={'New List'}
        icon={<i className="far fa-plus"></i>}
        data_testid="create-new-list-pill"
        onClick={() =>
          showModal((resolve) => renderNewListModal(resolve, 'other'), 'render_new_list_modal')
        }
        selected={false}
      />
    </div>
  )
}

export default CreateList
