import { compact, flatten } from 'lodash'
import memoizeOne from 'memoize-one'

import {
  AdvisorModel,
  Contact,
  DraftMessageBlueprint,
  EmailRecipientValue,
  GroupModel,
  MessageRecipient,
  Team,
} from 'utils/types'

import { recipientValue } from '..'

export const recipients = memoizeOne(
  (props: {
    groups: GroupModel[] | undefined
    team: Team | undefined
    advisors: AdvisorModel[] | undefined
    contacts: Contact[]
    message: DraftMessageBlueprint | null
    allowGroups: boolean
    canMessageAdvisors: boolean
    allowAdvisors: boolean
  }) => {
    const {
      groups,
      team,
      advisors,
      contacts,
      message,
      allowGroups,
      canMessageAdvisors,
      allowAdvisors,
    } = props

    const _recipients: MessageRecipient[] = []
    if (allowGroups && groups && groups.length > 0) {
      for (const group of groups) {
        _recipients.push(recipientValue({ group }))
      }
    }
    if (team?.admins_and_members) {
      for (const user of team?.admins_and_members) {
        _recipients.push(recipientValue({ user }))
      }
    }
    if (
      advisors &&
      advisors.length > 0 &&
      (canMessageAdvisors || message?.request) &&
      allowAdvisors
    ) {
      for (const advisor of advisors) {
        if (!!advisor.role && advisor.role !== 'candidate') continue

        _recipients.push(recipientValue({ advisor }))
      }
    }

    for (const contact of Object.values<Contact>(contacts)) {
      _recipients.push(recipientValue({ contact }))
    }

    return _recipients
  },
)
