import axios from 'axios'

import {
  CollaboratorBlueprint,
  CreateCollaboratorsRequest,
  CreateCollaboratorsResponse,
  GetCollaboratorsRequest,
} from 'utils/types'

export default {
  createCollaborator: (params: CreateCollaboratorsRequest) => {
    return axios.post<CreateCollaboratorsResponse>(`/api/collaborators`, params)
  },

  getCollaborators: (params: GetCollaboratorsRequest) => {
    return axios.get<{ collaborators: CollaboratorBlueprint[] }>(`/api/collaborators`, { params })
  },
}
