import React from 'react'

import Typography from 'global/Typography'

import { AdvisorModel, Team } from 'utils/types'

type ConnectionStatus = {
  advisor: Partial<AdvisorModel>
  advisor_uuid: string
  connection_sync_error: string | null
  connection_sync_stale: boolean | null
  connections_count: number
  connections_match_at?: Date
  connections_synced_at?: Date
  cookie_endpoint_called: boolean
  cookie_properly_received: boolean
  expected_connections_count: number
  google_contacts: any
  installed_extension_at?: Date
  li_connection_sync_requested_at?: Date
  linkedin_export: any
  shared_connections_at?: Date
  time: number
  uploaded_connections_at?: Date
}
interface Props {
  team: Team
  connectionStatus?: ConnectionStatus
}
const MemberHomeEmptyState: React.FC<Props> = ({ team, connectionStatus }) => {
  return (
    <div className="flex flex-col items-center pt-16 pb-6">
      <Typography fontSize="14" fontWeight={600} lineHeight={1.5}>
        {`${team.name} hasn't added any lists here, yet`}
      </Typography>
      {connectionStatus?.shared_connections_at ? (
        <Typography color="fog" fontSize="14" fontWeight={400} lineHeight={1.5}>
          {`You have already shared your connections with ${team.name}`}
        </Typography>
      ) : (
        <Typography color="fog" fontSize="14" fontWeight={400} lineHeight={1.5}>
          {`You can proactively offer to help with ${team.name}'s asks by adding your connections`}
        </Typography>
      )}
    </div>
  )
}

export default MemberHomeEmptyState
