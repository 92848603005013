import React, { useEffect, useState } from 'react'
import { Checklist, ChecklistItem } from 'global/Input/Checklist'
import { Container } from '../styles'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import { InvestorCompanyJob } from 'utils/types/investor'
import PortfolioJobsEmptyState from 'components/EmptyStates/PortfolioJobsEmptyState'
import { useHotkeys } from 'react-hotkeys-hook'

interface Props {
  jobs: InvestorCompanyJob[]
  isLoadingJobs: boolean
  isFetchingJobs: boolean
  onInsertJob: (items: ChecklistItem[]) => void
  teamSlug: string
  openAddJobModal: () => void
}

const getJobLabel = (j: InvestorCompanyJob) => {
  const {
    title,
    investor_company: { company_name, logo_url },
  } = j
  return (
    <div className="flex items-center my-1">
      <Avatar name={company_name} src={logo_url} round size="32" className="mr-2" />
      <div>
        <Typography fontSize="12" fontWeight={600} component="div">
          {title}
        </Typography>
        <Typography fontSize="12" component="div">
          {company_name}
        </Typography>
      </div>
    </div>
  )
}

const getItems = (jobs: InvestorCompanyJob[]) => {
  return (
    jobs.map((j: InvestorCompanyJob) => ({
      label: getJobLabel(j),
      value: j.uuid,
      checked: false,
    })) || []
  )
}

const JobsTab: React.VFC<Props> = ({
  jobs,
  isLoadingJobs,
  isFetchingJobs,
  onInsertJob,
  teamSlug,
  openAddJobModal,
}) => {
  const [query, setQuery] = useState('')
  const [selectedAll, setSelectedAll] = useState(false)

  const [jobItems, setJobItems] = useState<ChecklistItem[]>(getItems(jobs))

  useHotkeys('a+j', (e) => {
    e.preventDefault()
    openAddJobModal()
  })

  useEffect(() => {
    const lowercaseQuery = query.toLowerCase()
    let filteredJobs = jobs

    if (lowercaseQuery) {
      filteredJobs = filteredJobs.filter(
        (j: InvestorCompanyJob) =>
          j.title?.toLowerCase().includes(lowercaseQuery) ||
          j.investor_company.company_name.toLowerCase().includes(lowercaseQuery),
      )
    }

    setJobItems(getItems(filteredJobs))
  }, [jobs, query])

  const handleInsert = () => {
    onInsertJob(jobItems)
    const updatedItems = jobItems.map((item) => {
      return { ...item, checked: false }
    })
    setJobItems(updatedItems)
    setSelectedAll(false)
  }

  const setAll = (value: boolean) => {
    const updatedItems = jobItems.map((item) => {
      return { ...item, checked: value }
    })
    setJobItems(updatedItems)
    setSelectedAll(value)
  }

  const disabled = !jobItems.find((j) => j.checked)

  if (!isLoadingJobs && !isFetchingJobs && jobs.length === 0) {
    return (
      <div className="mt-5">
        <PortfolioJobsEmptyState
          companySlug={teamSlug}
          cta={
            <CabalButton
              leftIcon={<i className="far fa-plus" />}
              onClick={openAddJobModal}
              variant="secondary"
              className="ml-auto order-2"
              data-testid="composer-portfolio-add-job-btn"
            >
              Add Job
            </CabalButton>
          }
        />
      </div>
    )
  }

  return (
    <div className="px-1">
      <div className="mt-1">
        <SearchInput
          onChange={(e) => setQuery(e.target.value)}
          defaultValue={query}
          value={query}
          lightBg
          placeholder="Search jobs"
          maxLength={20}
          fontSize="12px"
          data-testid="composer-jobs-search-input"
        />
      </div>
      <Container className="absolute-custom-scrollbar">
        <Checklist
          className="my-1"
          items={jobItems}
          onChange={setJobItems}
          isLoading={isLoadingJobs || isFetchingJobs}
          onDirectAdd={onInsertJob}
        />
      </Container>
      <div>
        <CabalButton
          variant="primary"
          size="small"
          disabled={disabled}
          className="mr-2 mt-3"
          onClick={handleInsert}
          data-testid="composer-insert-jobs-btn"
        >
          Insert
        </CabalButton>
        <Typography
          fontSize="12"
          color="purple"
          onClick={() => setAll(!selectedAll)}
          className="cursor-pointer"
          data-testid="composer-jobs-select-all-btn"
        >
          {selectedAll ? 'Deselect All' : 'Select All'}
        </Typography>
      </div>
    </div>
  )
}

export default React.memo(JobsTab)
