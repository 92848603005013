import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import api from 'utils/api'
import { Team } from 'utils/types'

import { RootState } from '..'

export interface TeamsState {
  teams: Record<string, Team>
  loading: Record<string, boolean>
}

const initialState: TeamsState = {
  teams: {},
  loading: {},
}

export const loadTeam = createAsyncThunk(
  'teams/fetchTeam',
  async (props: { slug: string }, thunk) => {
    thunk.dispatch(setLoadingTeam(props.slug))
    const response = await api.getTeam(props.slug)
    return response.data
  },
)

export const loadTeams = createAsyncThunk('teams/fetchTeams', async (_, thunk) => {
  thunk.dispatch(setLoadingTeam('*'))
  const response = await api.getTeams()
  return response.data
})

export const updateTeam = createAsyncThunk(
  'teams/updateTeam',
  async (props: { slug: string; team: Partial<Team> }) => {
    const response = await api.updateTeam(props.slug, {
      team: props.team,
    })
    return response.data.team
  },
)

export const teamsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTeam: (state, action: PayloadAction<Team>) => {
      state.teams[action.payload.slug] = action.payload
    },
    setTeams: (state, action: PayloadAction<Team[]>) => {
      for (const team of action.payload) {
        state.teams[team.slug] = team
      }
    },
    resetTeams: (state, action: PayloadAction<Team[]>) => {
      state.teams = {}
      for (const team of action.payload) {
        state.teams[team.slug] = team
      }
    },
    setLoadingTeam: (state, action: PayloadAction<string>) => {
      state.loading[action.payload] = true
    },
    unsetLoadingTeam: (state, action: PayloadAction<string>) => {
      delete state.loading[action.payload]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadTeam.fulfilled, (state, action) => {
      delete state.loading[action.meta.arg.slug]
      if (!action.payload) return
      state.teams[action.payload.company.slug] = action.payload.company
    })

    builder.addCase(loadTeam.rejected, (state, action) => {
      delete state.loading[action.meta.arg.slug]
    })

    builder.addCase(loadTeams.fulfilled, (state, action) => {
      delete state.loading['*']
      for (const team of action.payload.companies) {
        state.teams[team.slug] = team
      }
    })

    builder.addCase(loadTeams.rejected, (state) => {
      delete state.loading['*']
    })
  },
})

export const { setTeam, setTeams, resetTeams, setLoadingTeam, unsetLoadingTeam } =
  teamsSlice.actions

const selectTeamsObj = (state: RootState) => state.teams.teams
const selectTeamsLoading = (state: RootState) => state.teams.loading

export const selectTeams = createSelector(selectTeamsObj, (teams) => Object.values(teams))

export const selectLoadingTeam = (slug: string) =>
  createSelector(selectTeamsLoading, (loading) => !!loading[slug])

export const selectTeam = (slug: string | null | undefined) =>
  createSelector(selectTeamsObj, (teams) =>
    slug && Object.keys(teams).includes(slug) ? teams[slug] : undefined,
  )

export const selectAdvisorship = (team_slug: string) =>
  createSelector(selectTeamsObj, (teams) =>
    Object.keys(teams).includes(team_slug) ? teams[team_slug].advisor : undefined,
  )

export default teamsSlice.reducer
