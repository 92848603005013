import React, { useImperativeHandle, useMemo, useRef, useState } from 'react'

import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import SkeletalComposer from 'components/Composer/SkeletalComposer'
import { SendMessageProps } from 'components/SendMessage'
import { SendMessageRef } from 'components/SendMessage/index.class'
import SendMessageV2 from 'components/SendMessageV2'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import {
  useAdvisors,
  useCurrentUser,
  useCurrentUserSettings,
  useGroups,
} from 'store/hooks'

import api, { callApi } from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'
import { EmailSnippet, MessageModel } from 'utils/types'

import { useComposeQuery } from './useComposeQuery'

interface Props {
  onHide?: (redirect?: boolean) => void
  teamSlug: string
  composerUuid: string
  onSubmit?: (m: MessageModel) => void
  sendMessageProps?: Partial<SendMessageProps>
  onAfterDelete?: () => void
}

export interface ComposerRef {
  setPresenceListContainer: (p: HTMLDivElement | null) => void
  setBody: (b: string) => void
  handleSelectSnippet: (s: EmailSnippet) => void
}

const ComposerWrap = styled.div`
  ${tw`flex flex-col flex-1 relative gap-2`}

  color: ${({ theme }) => theme.colors.primary};
  max-height: 100%;
`

export const SendMessageWrap = styled.div`
  /* ${tw`p-2 sm:p-6 rounded-lg`} */
  ${tw`p-3`}

  background-color: ${({ theme }) => theme.colors.composer_bg};
`

const START_SUB = `Getting started with Cabal 🌙: test message to myself`
const START_BODY = `
Hi {first_name},
<br>
I’m looking forward to using Cabal for:
<ul>
<li>Sending more consistent investor updates</li>
<li>Getting my investors and advisors to help me sell, recruit, and amplify my message</li>
<li>Building a modern advisor program</li>
<br>
It’s great that messages I send from Cabal come directly from my email address and are delivered to each recipient as individual messages. This way I can personalize asks and updates at scale.
<br>
I can see how my investors and advisors can help me here: getcabal.com/asks
`

const Composer = React.forwardRef<ComposerRef, Props>((props, ref) => {
  const { onHide, teamSlug, onSubmit, sendMessageProps, onAfterDelete } = props
  const {
    message,
    refetchMessage: refetch,
    gettingMessage,
    creatingMessage,
  } = useComposeQuery(props.teamSlug, props.composerUuid)
  const { advisors } = useAdvisors({ teamSlug })
  const { canMessageAdvisors } = useAccessControl(teamSlug)
  const sendMessageRef = useRef<SendMessageRef | null>(null)
  const { user } = useCurrentUser()
  const { settings, updateSetting } = useCurrentUserSettings()
  const permissions = useAccessControl()
  const { groups, reloadGroups } = useGroups(props.teamSlug)

  const [presenceListContainer, setPresenceListContainer] = useState<HTMLDivElement>()
  const { isMobile } = useColorMode()

  const totalAdvisorGroupsCount = useMemo(() => {
    return groups
      .filter((group) => group.name !== 'All')
      .reduce((total, group) => total + group.advisor_groups_count, 0)
  }, [groups])

  useImperativeHandle(
    ref,
    () => ({
      setPresenceListContainer: (elm) => {
        elm && setPresenceListContainer(elm)
      },
      setBody: (b) => sendMessageRef.current?.setBody(b),
      handleSelectSnippet: (s) => sendMessageRef.current?.handleSelectSnippet(s),
    }),
    [],
  )

  const location = useLocation<{ getStarted: boolean }>()
  const getStarted = location.state?.getStarted

  const handleSubmit = (m: MessageModel) => {
    refetch?.()
    onSubmit?.(m)
    onHide?.(false)
  }

  const {
    data: senders,
    refetch: reloadSenders,
    isLoading: isLoadingSenders,
  } = useQuery(
    [teamSlug, 'getSenderUsers', user.uuid],
    () => callApi(api.getSenderUsers, props.teamSlug, message?.uuid),
    { select: ({ senders }) => senders },
  )
  const { data: templates, isLoading: isLoadingTemplates } = useQuery(
    [props.teamSlug, 'getTemplates'],
    () => callApi(api.getTemplates, props.teamSlug),
    { enabled: permissions.canViewMessageTemplates, select: ({ templates }) => templates },
  )

  const {
    data: emailSnippets,
    refetch: handleRefetchEmailSnippets,
    isLoading: isLoadingSnippets,
  } = useQuery(
    [props.teamSlug, 'getEmailSnippets'],
    () => callApi(api.getEmailSnippet, props.teamSlug),
    { enabled: permissions.canViewMessageSnippets, select: ({ email_snippets }) => email_snippets },
  )

  const mainContent = (
    <ComposerWrap className="composer-wrap">
      {message && (
        <SendMessageV2
          message={message}
          companySlug={teamSlug}
          presenceListContainer={presenceListContainer}
          allowTemplates={!isMobile}
          allowSnippets={!isMobile}
          senders={senders || []}
          templates={templates || []}
          emailSnippets={emailSnippets || []}
          onReloadSnippets={handleRefetchEmailSnippets}
          hideToField={false}
          allowCc={true}
          showCreateGroupButton={
            !!permissions.canEditGroups &&
            totalAdvisorGroupsCount === 0 &&
            !settings?.hide_create_group_button
          }
          reloadGroups={reloadGroups}
          updateSetting={updateSetting}
          reloadSenders={reloadSenders}
          allowScheduledSend={sendMessageProps?.allowScheduledSend}
          introRequestFlow={sendMessageProps?.introRequestFlow}
          submitFunc={sendMessageProps?.submitFunc}
          allowButtons
          onClose={() => {
            onHide?.()
            sendMessageProps?.onClose?.()
          }}
          onAfterDelete={onAfterDelete}
          onSubmit={handleSubmit}
          hideModal={onHide}
          defaultBody={getStarted ? START_BODY : message.body}
          defaultSubject={getStarted ? START_SUB : message.subject}
          defaultTemplate={sendMessageProps?.defaultTemplate}
          templateVariables={sendMessageProps?.templateVariables}
        />
      )}
    </ComposerWrap>
  )

  const notFound = !gettingMessage && !message && !creatingMessage
  const loading =
    creatingMessage ||
    (gettingMessage && !message) ||
    (canMessageAdvisors && !advisors) ||
    isLoadingSnippets ||
    isLoadingSenders ||
    isLoadingTemplates

  if (notFound) {
    return (
      <div>
        <div className="text-center mb-4">Draft message not found</div>
        <CabalButton onClick={() => onHide?.()}>Close</CabalButton>
      </div>
    )
  }

  if (loading) return <SkeletalComposer />

  return <>{mainContent}</>
})

export default Composer
