import React, { useEffect } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { Divider } from 'components/SendMessage/styles'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import Modal from 'global/Modal'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { CompanySlugParam } from 'utils/types'

interface IProps {
  joinRequestUuid: string
  action: string
  onHide: () => void
}

export function JoinRequestConfirmation({ joinRequestUuid, action, onHide }: IProps) {
  const { company_slug } = useParams<CompanySlugParam>()

  const getJoinRequestQuery = useQuery([company_slug, 'getJoinRequest'], () =>
    callApi(api.getJoinRequest, company_slug, joinRequestUuid),
  )

  const acceptJoinRequestMutation = useMutation(() =>
    callApi(api.acceptJoinRequest, company_slug, joinRequestUuid),
  )

  useEffect(() => {
    acceptJoinRequestMutation.mutate()
  }, [])

  const joinRequest = getJoinRequestQuery.data?.join_request
  const requesterUser = joinRequest?.user

  if (!requesterUser) return <Loading className="pt-14" />

  console.log(joinRequest)

  return (
    <Modal
      show
      onHide={onHide}
      centerActions={<CabalButton onClick={() => onHide()}>Done</CabalButton>}
    >
      <div className="flex flex-col items-center mt-8">
        <Typography fontSize="24" color="fog" className="mb-4">
          <i className={`fas fa-circle-check text-green-500 mr-2`} />
          Request approved!
        </Typography>
        <Typography color="fog" fontSize="18" className="my-4 text-center">
          {requesterUser.name} has been added as{' '}
          {joinRequest.facilitator_user_id ? 'a candidate in' : 'a member of'} your workspace.
        </Typography>
      </div>
    </Modal>
  )
}
