import React, { useEffect } from 'react'

import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import CabalButton from 'global/CabalButton'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { Team } from 'utils/types'
import { numWithCommas } from 'utils/ui'

interface Props {
  onShowUpload: () => void
  teamSlug?: string
  showUpload: boolean
  totalConnections?: number
  refetchTeams: () => void
  teams: Partial<Team>[]
  syncStatus: 'complete' | 'syncing' | 'normal'
  onContinue?: () => void
}

const CTAs: React.FC<Props> = ({
  onShowUpload,
  teamSlug,
  showUpload,
  syncStatus,
  totalConnections,
  refetchTeams,
  teams,
  onContinue,
}) => {
  const history = useHistory()
  const [sharedConn, setSharedConn] = React.useState(false)

  const currentTeam = teams.find((t) => t.slug === teamSlug)
  const showShareButton = !!teamSlug && sharedConn && currentTeam?.shared_connections == null
  const showContinue = syncStatus === 'complete' || syncStatus === 'syncing'
  const showAddConnections = syncStatus === 'normal'
  const showSkip = showAddConnections || showShareButton

  const { mutate: shareConnections, isLoading: sharing } = useMutation(
    ['shareConnections'],
    () =>
      callApi(api.updateConnectionSharing, {
        share_connections: true,
        team_slug: currentTeam?.slug || '',
      }),
    {
      onSuccess: () => {
        cabalToast({
          content: `Success! Your connections have been shared with ${currentTeam?.name}`,
          style: 'success',
        })
        refetchTeams()
        onContinue && onContinue()
      },
    },
  )

  useEffect(() => {
    if (totalConnections && totalConnections > 0) {
      setSharedConn(true)
    }
  }, [])

  useEffect(() => {
    if (currentTeam && !currentTeam.shared_connections && syncStatus === 'complete') {
      shareConnections()
    }
  }, [syncStatus])

  return (
    <div>
      <div className="flex flex-col gap-3 items-center justify-center mt-8 mb-3">
        {showAddConnections && !showShareButton && (
          <CabalButton onClick={onShowUpload} leftIcon={<i className="fab fa-linkedin-in"></i>}>
            Download Connections
          </CabalButton>
        )}

        {showContinue && (
          <CabalButton
            variant="primary"
            onClick={() => {
              onContinue && onContinue()
              teamSlug ? history.push(`/${teamSlug}`) : history.push('/home')
            }}
            className="w-1/3"
            disabled={syncStatus === 'syncing'}
            rightIcon={<i className="fas fa-arrow-right" />}
          >
            Continue
          </CabalButton>
        )}

        {showShareButton && (
          <CabalButton variant="primary" onClick={() => shareConnections()} disabled={sharing}>
            Share {numWithCommas(totalConnections!)} connections with {currentTeam?.name}
          </CabalButton>
        )}

        {showSkip && (
          <CabalButton
            variant="tertiary"
            onClick={() => {
              if (onContinue) {
                onContinue()
              } else {
                teamSlug ? history.push(`/${teamSlug}`) : history.push('/home')
              }
            }}
          >
            I'll do this later
          </CabalButton>
        )}
      </div>
    </div>
  )
}

export default CTAs
