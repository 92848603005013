import React, { useState } from 'react'
import { Label, Select, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import CabalButton from 'global/CabalButton'
import api, { callApi } from 'utils/api'
import { cabalToast } from 'ui-components/Toast'
import { useMutation, useQuery } from 'react-query'
import Loading from 'global/Loading'
import { SlackSettings } from 'utils/types'

interface Props {
  resolve: () => void
  teamSlug: string
  settings: SlackSettings
}

const SlackSettingsModal: React.VFC<Props> = ({ resolve, teamSlug }) => {
  const [settings, setSettings] = useState<SlackSettings>({})
  const [channels, setChannels] = useState<string[]>([])

  const { isFetching } = useQuery(
    ['getSlackSettings'],
    () => callApi(api.getSlackSettings, teamSlug),
    {
      onSuccess: ({ settings, channels }) => {
        setSettings(settings || {})
        setChannels(channels || [])
      },
    },
  )

  const { mutate: handleSave, isLoading } = useMutation(
    () => callApi(api.saveSlackSettings, teamSlug, settings),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Successfully saved!' })
        resolve()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const { mutate: searchSlackChannel } = useMutation(
    (channel: string) => callApi(api.searchSlackChannel, channel),
    {
      onSuccess: ({ channel }) => {
        setSettings({ notif_channel: channel.trim() })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Channel does not exist!' })
      },
    },
  )

  return (
    <Modal
      show
      onHide={resolve}
      header={'Slack App Settings'}
      rightActions={
        <CabalButton
          onClick={() => handleSave()}
          working={isLoading}
          disabled={isLoading || !settings.notif_channel}
        >
          Save
        </CabalButton>
      }
    >
      {isFetching ? (
        <Loading />
      ) : (
        <Label className="mt-3" label="Notifications Channel">
          <Select
            compact
            className="mb-3 w-full"
            portal
            creatable
            onCreateOption={(c) => searchSlackChannel(c)}
            value={settings.notif_channel}
            placeholder={'The channel where Cabal bot will send notifications. For eg. #cabal-bot'}
            onChange={(c) => {
              c && setSettings({ notif_channel: c.trim() })
            }}
            options={channels.map((c) => ({ label: c, value: c }))}
          />
        </Label>
      )}
    </Modal>
  )
}

export default SlackSettingsModal
