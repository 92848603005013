import React from 'react'

import { transparentize } from 'polished'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Footer from 'containers/SignupScreen/NewSite/footer'
import Nav from 'containers/SignupScreen/NewSite/nav'
import {
  H1,
  LandingPageWrapper,
  SectionContainer,
  Subtitle,
} from 'containers/SignupScreen/NewSite/styles'
import CabalButton from 'global/CabalButton'

import defaultLists from './defaultLists'
import BuyerPersonasIllustration from './images/ill-buyer-personas.svg'
import EmailTemplatesIllustration from './images/ill-email-templates.svg'
import PotentialInvestorsIllustration from './images/ill-potential-investors.svg'
import SalesProspectsIllustration from './images/ill-sales-prospects.svg'

const Root = styled.div`
  -webkit-font-smoothing: antialiased;
  background-color: #fefefe;
  background-image: url(/noise-v2.png);
  overflow: scroll;
`

const ResourceCategoryBox = styled.div`
  background-color: ${(props) => props.theme.colors.cardBackground};
  border: solid 1px ${(props) => props.theme.colors.border};
  border-radius: 6px;
  padding: 16px;
`

const ResourceCategorySubtitle = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.colors.fog};
`

const ListEntry = styled.div`
  &:hover {
    background-color: ${({ theme }) => transparentize(0.8, theme.colors.cardHover)};
  }
  border-radius: .title {
    font-size: 14px;
  }

  .help {
    font-size: 12px;
    color: ${(props) => props.theme.colors.fog};
  }
`

export function ResourceCategory(props: {
  title: string
  categoryImage: string
  description: string
  category: (typeof defaultLists.lists)['potential_investors']
  categoryName: string
  suffix?: string
  onView?: (index: number) => void
}) {
  return (
    <ResourceCategoryBox>
      <div className="mb-1">
        <div className="flex">
          <img className="mr-2" src={props.categoryImage} />
          <div className="font-semibold text-xl mb-1">{props.title}</div>
        </div>
        <ResourceCategorySubtitle>{props.description}</ResourceCategorySubtitle>
      </div>

      {props.category.lists.map((list, i) => {
        const clickHandler = () =>
          (window.location.href =
            props.category.listType === 'templates'
              ? '/login'
              : `/get-resource/${props.categoryName}/${i}`)
        return (
          <ListEntry
            key={i}
            className="flex items-center justify-between py-2 px-2.5 rounded cursor-pointer"
            onClick={clickHandler}
          >
            <div className="title mb-0.5" style={{ color: '#718096' }}>
              <i className="mr-2"></i>
              {list.name}
            </div>

            <div className="flex-shrink-0 pl-2">
              <CabalButton variant="link" size="large" onClick={clickHandler} padding="0">
                Add to workspace
              </CabalButton>
            </div>
          </ListEntry>
        )
      })}
    </ResourceCategoryBox>
  )
}

const SectionTitle = styled.p`
  color: ${(props) => props.theme.colors.fog};
`

export default function ResourcesPage() {
  const history = useHistory()
  return (
    <Root>
      <LandingPageWrapper>
        <Nav />

        <div>
          <div className="flex flex-col justify-center items-center py-11">
            <SectionTitle className="text-xs uppercase mb-8">RESOURCES</SectionTitle>
            <H1 className="text-center max-w-2xl">
              Free community resources
              <div className="inline md:block"> </div>
              to help you grow
            </H1>
            <Subtitle className="pt-4 text-center max-w-lg">
              Lists and templates to help you sell, recruit, fundraise, and win.
            </Subtitle>
          </div>
        </div>

        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-x-6 gap-y-9 px-6 max-w-6xl mx-auto">
            <ResourceCategory
              title="Sales Prospects"
              description="Uncover intro paths to prospects"
              categoryImage={SalesProspectsIllustration}
              category={defaultLists.lists.sales_prospects}
              categoryName="sales_prospects"
            />
            <ResourceCategory
              title="Email Templates"
              description="Deliver more engaging and personalized updates."
              categoryImage={EmailTemplatesIllustration}
              category={defaultLists.lists.email_templates}
              categoryName="email_templates"
            />
            <ResourceCategory
              title="Potential Investors"
              description="Get warm intros to potential investors."
              categoryImage={PotentialInvestorsIllustration}
              category={defaultLists.lists.potential_investors}
              categoryName="potential_investors"
            />

            <ResourceCategory
              title="Buyer Personas"
              description="Get intros to potential buyers."
              suffix="Personas"
              categoryImage={BuyerPersonasIllustration}
              category={defaultLists.lists.buyer_personas}
              categoryName="buyer_personas"
            />
          </div>
        </div>

        <div className="py-20 flex flex-col items-center">
          <div className="text-center text-xl mb-6">
            Get more out of your investors and advisors
          </div>
          <CabalButton variant="primary" size="large" onClick={() => history.push('/login')}>
            Start for free
          </CabalButton>
        </div>
        <SectionContainer className="mt-32">
          <div className="z-10 mb-16 -mt-16">
            <div>
              <Footer withForm={false} />
            </div>
          </div>
        </SectionContainer>
      </LandingPageWrapper>
    </Root>
  )
}
