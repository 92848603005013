import * as React from 'react'
import styled from 'styled-components'

import { Label, TextInput, CheckBox } from 'global/Input'

interface Props {
  onChange: (fields) => void
  values: any
  hideSidebar?: boolean
}

const TemplateBody = styled.div`
  .ql-toolbar.ql-snow {
    max-height: 40px;
  }
`

function FormFields(props: Props) {
  const { values, onChange, hideSidebar } = props
  const [showButtonsModal, setShowButtonsModal] = React.useState(false)
  const show_buttons_control = true

  return (
    <div>
      <div className="mb-4">
        <TextInput
          label="Account Name"
          value={values.account_name}
          onChange={(e) => onChange({ account_name: e.target.value })}
          placeholder="e.g. Recipient's Organization"
          autoFocus
          className="w-full"
        />
      </div>
      {/* <div>
        <CheckBox
          label="Require email to view"
          checked={values.require_email_to_view}
          onChange={(e) => onChange({ require_email_to_view: e.target.checked })}
        />
        <CheckBox
          label="Allow downloading"
          checked={values.allow_downloading}
          onChange={(e) => onChange({ allow_downloading: e.target.checked })}
        />
      </div> */}
    </div>
  )
}

export default FormFields
