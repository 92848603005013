import React from 'react'

import Typography from 'global/Typography'

const NoResultsPage = () => {
  return (
    <div className="flex items-center justify-center my-16">
      <div className="flex flex-col items-center justify-center gap-4">
        <Typography color="fog" fontSize="20">
          <i className="far fa-search" />
        </Typography>
        <Typography fontSize="14" fontWeight={500} lineHeight={1}>
          No results found
        </Typography>
      </div>
    </div>
  )
}

export default NoResultsPage
