import axios from 'axios'

import {
  IActivityDashboard,
  ISelectedPeriod,
  IShowCumulative,
} from 'components/ActivityCharts/types'

import { Activity, Pagination } from 'utils/types'

export interface ActivityFilters {
  end?: string
  start?: string
  users?: string[]
  companies?: string[]
  types?: string[]
  company?: string
  company_uuid?: string
  company_list_uuid?: string
  global_person_uuid?: string
}

export default {
  getMemberActivity: (
    teamSlug?: string,
    scope:
      | 'team_activities'
      | 'user_team_activities'
      | 'user_activities'
      | 'candidate_activities' = 'team_activities',
    advisorUuid?: string,
    filters?: ActivityFilters,
    page?: number,
  ) => {
    return axios.get<{ activity: Activity[]; pagination: Pagination }>(`/api/user_activities`, {
      params: {
        team_slug: teamSlug,
        scope: scope,
        page,
        advisor_uuid: advisorUuid,
        ...filters,
      },
    })
  },

  destroyActivity: (uuid: string) => {
    return axios.delete(`/api/user_activities/${uuid}`)
  },

  getActivityDashboard: (
    teamSlug: string,
    period: ISelectedPeriod,
    showCumulative: IShowCumulative,
  ) => {
    return axios.get<IActivityDashboard[]>(`/api/user_activities/dashboard`, {
      params: {
        team_slug: teamSlug,
        period: period,
        cumulative_flags: showCumulative,
      },
    })
  },

  getUserActivity: (filters?: ActivityFilters) => {
    return axios.get<{ activity: Activity[] }>(`/api/analytics/user_activity`, {
      params: {
        ...filters,
      },
    })
  },

  attachCompaniesToUserActivity: (
    team_slug: string,
    activity_uuid: string,
    global_company_uuids: string[],
  ) => {
    return axios.put(`/api/user_activities/${activity_uuid}`, {
      team_slug,
      global_company_uuids,
    })
  },

  attachPeopleToUserActivity: (
    team_slug: string,
    activity_uuid: string,
    global_person_uuids: string[],
  ) => {
    return axios.put(`/api/user_activities/${activity_uuid}`, {
      team_slug,
      global_person_uuids,
    })
  },
}
