import * as React from 'react'

import styled from 'styled-components'

import { FooterWrapper } from 'containers/App/styles'
import Avatar from 'global/Avatar'
import DropDownMenu from 'global/DropDownMenu'
import Typography from 'global/Typography'

interface LoginDropDownProps {
  onLogin: () => void
}

const LoginDropDown: React.FC<LoginDropDownProps> = ({ onLogin }) => {
  const menuItems = [
    {
      label: (
        <Typography fontSize="14" component="div">
          <i className="far fa-sign-in fa-fw mr-3"></i>
          {'Log In'}
        </Typography>
      ),
      onSelect: () => {
        onLogin()
      },
    },
  ]

  return (
    <Wrapper className="flex flex-shrink-0 flex-col">
      <DropDownMenu
        portal={false}
        trigger={
          <FooterWrapper className="flex items-center rounded-full px-2 py-1.5 w-full truncate">
            <Avatar size={'24'} round className="flex-shrink-0 mr-2" />
            <div className="text-left flex items-center justify-between w-full truncate">
              <Typography component="p" className="ml-1" color={'fog'}>
                <i className="fal fa-angle-down fa-sm"></i>
              </Typography>
            </div>
          </FooterWrapper>
        }
        fullWidth={true}
        menuItems={menuItems}
      />
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  &:hover {
    color: ${({ theme }) => theme.side_nav.active_item_text_color};
  }
`

export default LoginDropDown
