import GoogleAuthModal from 'components/GoogleAuthModal'
import React from 'react'

import {
  DraftMessageBlueprint,
  DraftPresendCheckParams,
  DraftPresendCheckReponse,
} from 'utils/types'
import MissingUserName from './MissingUserName'
import UnknownVariable from './UnknownVariable'

export interface PresendCheckStepProps {
  presendCheckResponse: DraftPresendCheckReponse
  onSubmit: (params?: Partial<DraftPresendCheckParams>) => void
  onHide: () => void
}

interface Props extends PresendCheckStepProps {
  setMessage: (message: Partial<DraftMessageBlueprint>) => void
}

const PresendCheckModal: React.VFC<Props> = ({
  presendCheckResponse,
  onSubmit: _onSubmit,
  onHide,
  setMessage,
}) => {
  const onSubmit: PresendCheckStepProps['onSubmit'] = (params) => {
    _onSubmit(params)
    onHide()
  }

  if (presendCheckResponse.name_missing) {
    return (
      <MissingUserName
        presendCheckResponse={presendCheckResponse}
        onSubmit={onSubmit}
        onHide={onHide}
      />
    )
  }

  if (presendCheckResponse.show_gmail_auth_prompt) {
    return (
      <GoogleAuthModal
        show
        onHide={onHide}
        onSkip={() => onSubmit({ skip_auth: true })}
        onAuthorize={(email) => {
          if (email) {
            setMessage({ from: email })
            onSubmit()
          } else {
            onSubmit()
          }
        }}
      />
    )
  }

  if (presendCheckResponse.unknown_vars) {
    return (
      <UnknownVariable
        presendCheckResponse={presendCheckResponse}
        onSubmit={onSubmit}
        onHide={onHide}
      />
    )
  }

  return <div>PresendCheckFailureModal</div>
}

export default PresendCheckModal
