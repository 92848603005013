import React from 'react'

import { MainSection } from 'containers/SignupScreen/MarketingSite'
import LayoutSection from 'containers/SignupScreen/MarketingSite/LayoutSection'

import FounderPortal from '../../SolutionsAssets/founderportal.png'
import MassComms from '../../SolutionsAssets/masscomms.png'
import SalesAssist from '../../SolutionsAssets/salesassist.png'
import TalentMarketplace from '../../SolutionsAssets/talentmarketplace.png'
import Footer from '../Footer'
import Nav from '../Nav'

const InvestorsPage = () => {
  React.useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1)

      const element = document.getElementById(hash)
      if (element) {
        const overflowWrapper = document.getElementById('investor-page-container')
        overflowWrapper?.scrollBy({
          top: (element?.getBoundingClientRect().top || 0) - 75,
          behavior: 'smooth',
        })
      }
    }

    handleHashChange()
  }, [])

  return (
    <div className="h-screen antialiased">
      <MainSection
        className="w-full h-full overflow-scroll bg-[#0E151F]"
        id="investor-page-container"
      >
        <Nav />
        <section className="px-8 mt-10 mb-10">
          <div className="block max-w-6xl mx-auto">
            <h2 className="text-[#e4307c] text-base ">For VCs</h2>
            <p className="text-white tracking-tighter font-light text-3xl sm:text-5xl mt-5">
              Give your portfolio companies the attention they deserve
            </p>
          </div>
        </section>
        <div className="bg-[#0B1017] px-8 pt-20">
          <LayoutSection
            title="Founder portal"
            description="Private space to spoil your founders with support"
            image={FounderPortal}
            id="founder-portal"
            cta={
              <a
                className=" text-[#e4307c] hover:text-[#EA5D98] cursor-pointer tansition-all duration-300 ease-in-out"
                href="/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                Build your portal
                <i className="far fa-arrow-right fa-sm ml-4"></i>
              </a>
            }
          />
          <LayoutSection
            title="Collaborative email"
            description="Send custom email comms to founders, LPs, and friends"
            image={MassComms}
            id="mass-comms"
            cta={
              <a
                className=" text-[#e4307c] hover:text-[#EA5D98] cursor-pointer tansition-all duration-300 ease-in-out"
                href="/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Templates
                <i className="far fa-arrow-right fa-sm ml-4"></i>
              </a>
            }
          />
          <LayoutSection
            title="Talent marketplace"
            description="Automate candidate sourcing, filling, and reporting"
            image={TalentMarketplace}
            id="talent-marketplace"
            cta={
              <a
                className=" text-[#e4307c] hover:text-[#EA5D98] cursor-pointer tansition-all duration-300 ease-in-out"
                href="/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                Build your portal
                <i className="far fa-arrow-right fa-sm ml-4"></i>
              </a>
            }
          />
          <LayoutSection
            title="Sales/BD support"
            description="See who your portcos are selling to and where you can help"
            image={SalesAssist}
            id="sales-assist"
            cta={
              <a
                className=" text-[#e4307c] hover:text-[#EA5D98] cursor-pointer tansition-all duration-300 ease-in-out"
                href="/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                See how it works
                <i className="far fa-arrow-right fa-sm ml-4"></i>
              </a>
            }
          />
          <Footer />
        </div>
      </MainSection>
    </div>
  )
}

export default InvestorsPage
