import * as React from 'react'

import cx from 'classnames'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import { useQueryClient } from 'react-query'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import tw from 'twin.macro'

import CreateConnectionIntroRequestModal from 'components/CreateConnectionIntroRequestModal'
import RequestIntroModal from 'containers/Portfolio/MemberView/RequestIntroModal'
import Avatar from 'global/Avatar'
import { useModal } from 'global/Modal'
import Pill from 'global/Pill'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { DataCard } from 'ui-components/DataCard'

import { joinReactElements } from 'utils/array'
import { BlockItemCard, BlockItemCardDescriptionPart } from 'utils/types'

import EditItemModal from './EditItemModal'

const CircleWrapper = styled.div`
  ${tw`flex items-center justify-center rounded-full`}
  background-color: ${({ theme }) => theme.pill.purple_filled.primary_bg};
  width: 16px;
  height: 16px;
`

interface Props {
  itemView: BlockItemCard
  teamSlug?: string
  queryKey?: string[]
  highlightWords?: string[]
  className?: string
}

function merge_url_with_params(url: string, params: string) {
  if (params.charAt(0) === '?') {
    params = params.slice(1)
  }

  if (url.endsWith('?')) {
    return url + params
  } else if (url.includes('?')) return url + '&' + params
  else {
    return url + '?' + params
  }
}

const ItemCardV2: React.FC<Props> = ({
  itemView,
  teamSlug,
  queryKey,
  highlightWords,
  className,
}) => {
  const history = useHistory()
  const location = useLocation()
  const queryClient = useQueryClient()
  const { showModal } = useModal()
  const theme = useTheme()

  const renderWords = (words?: React.ReactNode | null): React.ReactNode => {
    if (!words) return words

    if (highlightWords && typeof words === 'string') {
      return (
        <Highlighter
          searchWords={new Array(highlightWords).flat() || []}
          textToHighlight={words}
          highlightStyle={{
            backgroundColor: theme.highlight,
            color: theme.colors.primary,
          }}
        />
      )
    } else {
      return words
    }
  }

  const openEditItemModal = (item: BlockItemCard) => {
    showModal((resolve) => <EditItemModal item={item} resolve={resolve} />, 'edit-item-modal')
  }

  const openPortfolioRequestIntroModal = (item: BlockItemCard) => {
    showModal(
      (resolve) => (
        <RequestIntroModal
          {...item.on_click?.props}
          teamSlug={teamSlug}
          resolve={resolve}
          onCreate={() => queryClient.refetchQueries(queryKey)}
        />
      ),
      'request-intro-modal',
    )
  }

  const openRequestIntroModal = (item: BlockItemCard) => {
    showModal(
      (resolve) => (
        <CreateConnectionIntroRequestModal
          onCreate={() => queryClient.refetchQueries(queryKey)}
          teamSlug={teamSlug!}
          resolve={resolve}
          person={item.on_click!.props!.person!}
          {...item.on_click?.props}
        />
      ),
      'create_connection_request',
    )
  }

  const renderMultipleStringPart = (part: BlockItemCardDescriptionPart) => {
    const { label, subItems } = part

    if (subItems && subItems.length === 1) {
      return (
        <Typography fontSize="12" lineHeight={'15px'} color={'fog'} className="truncate">
          {label}
        </Typography>
      )
    } else if (subItems && subItems.length > 1) {
      const tooltipContent = (
        <>
          {subItems.slice(1).map((string) => (
            <Pill variant="purple_filled" key={string} className="m-1" key={string}>
              {string}
            </Pill>
          ))}
        </>
      )
      return (
        <Tooltip label={tooltipContent}>
          <Typography fontSize="12" lineHeight={'15px'} color={'fog'} className="truncate">
            {subItems[0]}, +{subItems.slice(1).length}
          </Typography>
        </Tooltip>
      )
    }
  }

  const renderMultipleStringsWithAvatarsPart = (part: BlockItemCardDescriptionPart) => {
    const { label, subItemHeader, subItemsWithImages } = part

    if (subItemsWithImages && subItemsWithImages.length > 1) {
      const tooltipContent = (
        <div className="flex flex-col space-y-1">
          <Typography fontSize="12" fontWeight={600}>
            {subItemHeader}
          </Typography>
          {subItemsWithImages.map((subItem) => {
            return (
              <div className="flex items-center space-x-1" key={subItem.id}>
                <Avatar size="16" name={subItem.image_alt} src={subItem.image_url} />
                <Typography fontSize="12" color="fog_rain">
                  {subItem.label}
                </Typography>
              </div>
            )
          })}
        </div>
      )

      return (
        <Tooltip label={tooltipContent}>
          <CircleWrapper>
            <Typography fontSize="10" color="fog_rain" lineHeight={1}>
              {label}
            </Typography>
          </CircleWrapper>
        </Tooltip>
      )
    } else {
      const subItem = subItemsWithImages && subItemsWithImages[0]

      return (
        <Tooltip
          label={
            <div className="flex flex-col">
              <Typography fontSize="12" fontWeight={600}>
                {subItemHeader}
              </Typography>
              <Typography fontSize="12" color="fog_rain">
                {subItem?.label}
              </Typography>
            </div>
          }
        >
          <Avatar size="16" name={subItem?.image_alt} src={subItem?.image_url} />
        </Tooltip>
      )
    }
  }

  let descriptionInner = joinReactElements(
    itemView.description_parts?.map((part: BlockItemCardDescriptionPart) => {
      if (part.type == 'multiple_strings') {
        return renderMultipleStringPart(part)
      } else if (part.type == 'multiple_strings_with_avatars') {
        return renderMultipleStringsWithAvatarsPart(part)
      } else {
        // type = text
        return (
          <Typography className="truncate" key={part.id}>
            {renderWords(part.label)}
          </Typography>
        )
      }
    }) || [],
    () => <Typography className="mx-1.5">{'·'}</Typography>,
  )

  let descriptionContainer = null

  if (itemView.description_on_click?.url) {
    descriptionContainer = (
      <Link to={itemView.description_on_click.url} className="flex items-center truncate">
        {descriptionInner}
      </Link>
    )
  } else {
    descriptionContainer = <div className="flex items-center truncate">{descriptionInner}</div>
  }

  const onCardClick = () => {
    if (itemView.on_click) {
      if (itemView.on_click.url) {
        history.push(itemView.on_click.url)
      } else if (itemView.on_click.handler) {
        itemView.on_click.handler()
      }
    } else {
      return null
    }
  }

  let cardClassName = className || ''
  if (location.pathname.match(/\/cabal\/lists\/[^\/]+/g)?.length) {
    cardClassName += ' border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none'
  }

  return (
    <DataCard
      // onEdit={() => openEditItemModal(company)}
      titleUrl={itemView.title_url}
      title={renderWords(itemView.title)}
      titleIcons={itemView.title_icons}
      mainIcon={itemView.main_icon}
      placeholderIcon={itemView.placeholder_icon}
      onCardClick={onCardClick}
      avatarUrl={itemView.avatar_url || undefined}
      avatarName={itemView.avatar_name}
      description={descriptionContainer}
      className={cardClassName}
      menuItems={itemView.menu_items?.map((item) => {
        if (item.type === 'internal_url') {
          return {
            label: item.label,
            onSelect: () => history.push(item.url!),
          }
        } else if (item.type === 'url') {
          return {
            label: item.label,
            onSelect: () => window.open(item.url, '_blank'),
          }
        } else if (item.type === 'action') {
          return {
            label: item.label,
            onSelect: () => item.onSelect?.(),
          }
        } else {
          throw 'menuItem type not handled'
        }
      })}
      secondaryContent={itemView.secondary_content}
      hoverIconMenu={itemView.hover_icon_menu}
      info={
        <div className="flex items-center space-x-2">
          {itemView.info_date && (
            <Typography className="flex items-center" fontSize="12" color="fog_rain">
              {moment(itemView.info_date).fromNow()}
            </Typography>
          )}

          {itemView.info_element && (
            <Typography className="flex items-center" fontSize="12" color="fog_rain">
              {itemView.info_element.text}{' '}
              {itemView.info_element.icon && (
                <i className={cx(itemView.info_element.icon, 'ml-2')} />
              )}
            </Typography>
          )}

          {itemView.on_click && itemView.on_click.url && (
            <Link
              to={
                itemView.on_click.include_query_params
                  ? merge_url_with_params(itemView.on_click.url, window.location.search)
                  : itemView.on_click.url
              }
            >
              <Typography className="flex items-center" fontSize="12" color="purple">
                {itemView.on_click.label}
                {itemView.on_click.icon && (
                  <i className={`${itemView.on_click.icon} ml-2 fa-sm`}></i>
                )}
              </Typography>
            </Link>
          )}

          {itemView.on_click &&
            itemView.on_click.type === 'modal' &&
            itemView.on_click.identifier === 'CreateConnectionIntroRequestModal' && (
              <Typography
                className="flex items-center cursor-pointer"
                fontSize="12"
                color="purple"
                onClick={() => openRequestIntroModal(itemView)}
              >
                {itemView.on_click.label}
              </Typography>
            )}

          {itemView.on_click &&
            itemView.on_click.type === 'modal' &&
            itemView.on_click.identifier === 'RequestIntroModal' && (
              <Typography
                className="flex items-center cursor-pointer"
                fontSize="12"
                color="purple"
                onClick={() => openPortfolioRequestIntroModal(itemView)}
              >
                {itemView.on_click.label}
              </Typography>
            )}
        </div>
      }
    />
  )
}

export default ItemCardV2
