import styled from 'styled-components/macro'
import tw from 'twin.macro'

const SignupContainer = styled.div`
  box-sizing: border-box;
  background-color: #fff;
  overflow: auto;
  height: 100vh;
  width: 100%;
  ol {
    list-style-type: decimal;
    font-size: 20px;
  }
  h1,
  h2,
  h3,
  blockquote,
  b,
  strong {
    font-family: 'Inter', Helvetica, sans-serif;
    color: #0e151f;
  }
  p,
  span,
  em {
    color: #4b5563;
  }
  a {
    color: #718096;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  /* @media (prefers-color-scheme: light) {
    background-color: #fff;
    h1,
    h2,
    h3,
    blockquote,
    b,
    strong {
      color: #0e151f;
    }
    p,
    span,
    em,
    li {
      color: #4b5563;
    }
    a {
      color: #718096;
    }
    input {
      background: #fff;
      color: #000;
    }
  } */
`

const LoginFormContainer = styled.div`
  input {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    height: 48px;
    box-sizing: border-box;
    background: #fff;
    color: #000;
    border: 2px solid #8a97ff;
    border-radius: 4px;
    font-size: 14px;
  }
  /* form:invalid {
    background-color: #ddffdd;
  } */
  button {
    background: linear-gradient(270deg, #5c69d1 0%, #8a97ff 215.05%);
    color: #fff;
    border-radius: 4px;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    margin: 12px 0;
    height: 48px;
    box-sizing: border-box;
    width: 100%;
  }
  button:hover {
    background: linear-gradient(270deg, #5c69d1 0%, #8a97ff 102.04%);
  }
  button:disabled,
  button[disabled] {
    opacity: 0.5;
  }
  a {
    color: #a7b4c7;
    text-decoration: none;
  }
  ::placeholder {
    color: #4a5566;
    opacity: 1; /* Firefox */
  }
`

const BlockQuote = styled.blockquote`
  font-size: 20px;
  line-height: 1.4;
  padding-bottom: 24px;
  padding-left: 32px;
  font-style: italic;
`

const BlogTitle = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 1;
  padding-bottom: 40px;
`

const BlogContent = styled.div`
  ${tw`flex justify-center flex-col mx-auto`}
  max-width: 730px;
`

const BlogContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1035px;
  padding: 0 20px;
  padding-bottom: 96px;
  @media (min-width: 650px) {
    padding: 0 44px;
    padding-bottom: 96px;
  }
`

const BodyMask = styled.div`
  background-position: top left;
  background-repeat: repeat;
  background-color: #0e151f;
  /* background-image: url(/noise-dark.png); */
  @media (prefers-color-scheme: light) {
    /* background-image: url(/noise-light.png); */
    background: #fff;
  }
`

const H1 = styled.h1`
  letter-spacing: -0.03em;
  font-size: 48px;
  line-height: 1;
  font-weight: 700;
  @media only screen and (max-width: 650px) {
    font-size: 44px;
    line-height: 99%;
  }
`

const H2 = styled.h2`
  font-weight: 700;
  padding: 20px 0px;
  font-size: 20px;
  line-height: 1.5;
`

const H3 = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.04em;
  padding-left: 10.5px;
`

const Para = styled.p`
  font-family: 'JetBrains Mono';
  padding-bottom: 24px;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: #000 !important;
  @media (prefers-color-scheme: dark) {
    color: #f7fafc !important;
  }
`

const Section = styled.section`
  padding-bottom: 40px;
`

const Header = styled.h2`
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  padding-bottom: 30px;
`

const Content = styled.p`
  font-size: 18px;
  line-height: 1.4;
  padding-bottom: 32px;
`

const Italic = styled.span`
  font-size: 18px;
  line-height: 32px;
  padding-bottom: 24px;
  font-style: italic;
  display: inline;
`
const ItalicBlock = styled.div`
  font-size: 18px;
  line-height: 32px;
  padding-bottom: 24px;
  font-style: italic;
`

const Indent = styled.div`
  padding-left: 32px;
`
const Table = styled.table`
  ${tw` mx-auto`}
`

const LinkWrapper = styled.span`
  color: #a8bff5 !important;
  text-decoration: underline !important;
  @media (prefers-color-scheme: light) {
    color: #5c69d1 !important;
  }
`

const Image = styled.img`
  ${tw` mx-auto`}
  padding-top: 6px;
  padding-bottom: 30px;
`

const StyledImg = styled.img`
  ${tw` mx-auto`}
  margin-top: 6px;
  margin-bottom: 30px;
  border: 2px solid #718096;
  border-radius: 4px;
`

const Divider = styled.div`
  border-bottom: 1px solid #2d3748;
  @media (prefers-color-scheme: light) {
    border-bottom: 1px solid #e3e5e8;
  }
`

const OL = styled.ol`
  padding-left: 22px;
`
const UL = styled.ul`
  padding-left: 22px;
`

const SuccessPrimary = styled.p`
  color: #8a97ff !important;
  font-size: 18px;
  @media (prefers-color-scheme: light) {
    color: #5c69d1 !important;
  }
`

const BlogHeaderImg = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: 16px;
`

const OptionText = styled.p`
  font-size: 14px;
  line-height: 19.6px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  color: #718096 !important;
`

const InputWrap = styled.div`
  max-width: 420px;
  @media (min-width: 650px) {
    max-width: 320px;
  }
`
export {
  BodyMask,
  BlogTitle,
  BlogContainer,
  BlogContent,
  BlockQuote,
  Content,
  Divider,
  Header,
  H1,
  H2,
  H3,
  Image,
  InputWrap,
  StyledImg,
  Indent,
  Italic,
  ItalicBlock,
  LinkWrapper,
  OL,
  OptionText,
  UL,
  Para,
  Section,
  SignupContainer,
  SuccessPrimary,
  Table,
  BlogHeaderImg,
  LoginFormContainer,
}
