import React from 'react'
import { MessageListHeader } from 'components/MessageList/styles'
import Typography from 'global/Typography'
import Chart from './Chart'
import styled from 'styled-components'
import tw from 'twin.macro'
import LeaderBoard from './LeaderBoard'
import { useParams } from 'react-router-dom'
import { CompanySlugParam } from 'utils/types'
import { useQuery } from 'react-query'
import axios from 'axios'
import api, { callApi } from 'utils/api'
import PageWrapper from 'global/PageWrapper'

const TimeToggle = styled(Typography)<{
  active: boolean
}>`
  ${tw`pr-1.5`}
  font-size: 14px;
  font-weight: ${({ active }) => (active ? '700' : '400')};
  color: ${({ active, theme }) => (active ? theme.colors.primary : theme.colors.rain_fog)};
  cursor: pointer;
`

const Divider = styled.div`
  ${tw`ml-4 hidden xl:block`}
  border-left: 1px solid ${({ theme }) => theme.chart.line};
`

const BoardWrapperLarge = styled.div`
  ${tw`hidden xl:block lg:w-3/12 flex-shrink-0`}
`

export type TimeProp = '1' | '3' | '6' | '12'

const Analytics = () => {
  const [time, setTime] = React.useState<TimeProp>('6')
  const { company_slug: companySlug } = useParams<CompanySlugParam>()

  const [chartData, setChartData] = React.useState({})
  const [leaderboards, setLeaderboards] = React.useState({
    email: [],
    sales: [],
  })

  React.useEffect(() => {
    axios.get(`/api/analytics?team=${companySlug}&time=${time}`).then((response) => {
      const { chart_data, leaderboards } = response.data
      setChartData(chart_data)
      setLeaderboards(leaderboards)
    })
  }, [time, companySlug])

  const { data: requestsStats } = useQuery(['requestsStats', companySlug], () =>
    callApi(api.requestsStats, companySlug),
  )

  return (
    <PageWrapper title="Analytics">
      <div>
        {/* <TimeToggle active={time === '1'} onClick={() => setTime('1')}>
            1m
          </TimeToggle>
          <TimeToggle active={time === '3'} onClick={() => setTime('3')}>
            3m
          </TimeToggle> */}
        {/* <TimeToggle active={time === '6'} onClick={() => setTime('6')}>
            6m
          </TimeToggle> */}
        {/* <TimeToggle active={time === '12'} onClick={() => setTime('12')}>
            1y
          </TimeToggle> */}
        {/*<TimeToggle active={time === 'all'} onClick={() => setTime('all')}>
            All time
            </TimeToggle>*/}
      </div>

      <div>
        <div className="flex justify-between">
          <div className="flex-1 flex flex-col">
            <Chart time={time} companySlug={companySlug} data={chartData} />
          </div>
          <Divider />
          <BoardWrapperLarge>
            <LeaderBoard
              title={'Top Contributors'}
              icon={<i className="far fa-envelope mr-1.5"></i>}
              type="email"
              time={time}
              rows={leaderboards.email || []}
              invert={true}
            />
          </BoardWrapperLarge>
        </div>

        <div className="pt-5 flex flex-col justify-between">
          <div className="block xl:hidden">
            <LeaderBoard
              title={'Top Contributors'}
              icon={<i className="far fa-envelope mr-1.5"></i>}
              type="email"
              time={time}
              rows={leaderboards.email || []}
              invert={true}
            />
          </div>
          <div>
            <Typography
              textTransform="uppercase"
              fontWeight={500}
              fontSize="12"
              className="block mt-4 mb-2"
              color="widget_title"
              letterSpacing="0.05em"
            >
              Top Requesters
            </Typography>
            {requestsStats?.top_requesters.map((r, i) => {
              return (
                <Typography key={r.user.uuid} className="mt-2" component="div">
                  {i + 1}) {r.user.name} – {r.requests_count} requests
                </Typography>
              )
            })}
          </div>

          {/* <LeaderBoard
            title={'Most Sales Connections'}
            icon={<i className="far fa-arrows-h mr-1.5"></i>}
            type="sales"
            time={time}
            rows={leaderboards.sales || []}
          /> */}
          {/* <LeaderBoard
            title={'Top Social Contributors'}
            icon={<i className="far fa-hashtag mr-1.5"></i>}
            type="social"
            time={time}
            rows={leaderboards.social || []}
          /> */}
        </div>
      </div>
    </PageWrapper>
  )
}

export default Analytics
