import * as React from 'react'

import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import api from 'utils/api'
import IntegrationCard from './IntegrationCard'
import slack from '../../../../assets/images/slack.png'
import { ModalContext, RenderModal, useModal } from 'global/Modal/Context'
import { DataCard } from 'ui-components/DataCard'
import { useEffect } from 'react'
import { cabalToast } from 'ui-components/Toast'
import { useSearchParam } from 'react-use'
import SlackSettingsModal from './SlackSettingsModal'

interface Props {
  reload: (settings: any) => void
  settings: any
  teamSlug: string
}

const SlackIntegrationCard: React.FC<Props> = ({ reload, settings, teamSlug }) => {
  const [working, setWorking] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const { showModal } = useModal()

  const showSuccessToast = useSearchParam('slack_success')

  useEffect(() => {
    if (showSuccessToast) {
      cabalToast({
        style: 'success',
        content: 'Slack authorized successfully',
      })
      openSettingModal()
    }
  }, [showSuccessToast])

  const navigateToAuth = () => window.location.assign(`/users/auth/slack`)

  const openSettingModal = () => {
    showModal(
      (resolve) => (
        <SlackSettingsModal
          teamSlug={teamSlug}
          resolve={resolve}
          settings={settings.team_slack_settings}
        />
      ),
      'slack-settings-modal',
    )
  }

  return (
    <>
      <DataCard
        title="Slack"
        description={'Get notifications on potential asks.'}
        avatarUrl={slack}
        cta={
          settings.team_connected_slack ? (
            <CabalButton
              leftIcon={<i className="far fa-cog" />}
              variant="secondary"
              tooltip="Settings"
              onClick={() => openSettingModal()}
            />
          ) : null
        }
        secondaryCTA={{
          text: settings.team_connected_slack ? 'Reauthorize' : 'Authorize',
          onClick: () => navigateToAuth(),
        }}
      />
    </>
  )
}

export default SlackIntegrationCard
