import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import { SearchInput } from 'global/Input'

import { ResourcePageTitleTypes, ResourcePropFilters } from '../PageTemplate'
import FilterCard from './FilterCard'

interface FiltersProps {
  allTags: string[]
  allCategories: string[]
  search: string
  setSearch: (search: string) => void
  filters: ResourcePropFilters
  setFilters: (filters: ResourcePropFilters) => void
  pageTitle: ResourcePageTitleTypes
  isFetching: boolean
  tagCounts: any[]
  showTags: boolean
}

const Filters: React.FC<FiltersProps> = ({
  allTags,
  allCategories,
  filters,
  setFilters,
  search,
  setSearch,
  pageTitle,
  isFetching,
  tagCounts,
  showTags,
}) => {
  const history = useHistory()
  const query = new URLSearchParams(window.location.search)
  const tagParams = useSearchParam('tags')
  const categoryParams = useSearchParam('categories')
  const searchParams = useSearchParam('search')

  const handleFilterClick = (filterType: string, selectedFilter: string) => {
    const existingFilters = !!filters ? filters[filterType] : []
    let newFilters = []

    if (existingFilters.includes(selectedFilter)) {
      newFilters = existingFilters.filter((f: string) => f !== selectedFilter)
    } else {
      newFilters = [...existingFilters, selectedFilter]
    }

    if (newFilters.length === 0) {
      query.delete(filterType)
    } else {
      query.set(filterType, newFilters.join(','))
    }

    history.replace({
      search: query.toString(),
    })

    setFilters({ ...filters, [filterType]: newFilters })
  }

  const handleSearch = (search: string) => {
    query.set('search', search)
    history.replace({
      search: query.toString(),
    })
    setSearch(search)
  }

  useEffect(() => {
    const tags = tagParams ? tagParams?.split(',') : []
    const categories = categoryParams ? categoryParams?.split(',') : []
    const search = searchParams || ''

    setFilters({ tags, categories, query: search })
    setSearch(search || '')
  }, [tagParams, categoryParams, searchParams])

  const orderedTags = tagCounts && tagCounts?.sort((a, b) => b.count - a.count)

  const showSearch =
    orderedTags.length !== 0 ||
    allCategories.length !== 0 ||
    (orderedTags.length !== 0 && allCategories.length !== 0 && !isFetching)

  return (
    <div>
      {(pageTitle === 'Vendors' || pageTitle === 'Preferred Partners') && (
        <>
          <div className="grid grid-cols-4 gap-2 mb-3">
            {filters.categories.length === 0 &&
              allCategories.map((category) => (
                <FilterCard
                  type="category"
                  key={category}
                  title={category}
                  count={10}
                  onClick={() => {
                    handleFilterClick('categories', category)
                  }}
                  selected={filters.categories.includes(category) || false}
                />
              ))}

            {filters.categories.length === 1 && (
              <FilterCard
                type="category"
                key={filters.categories[0]}
                title={filters.categories[0]}
                count={10}
                onClick={() => {
                  handleFilterClick('categories', filters.categories[0])
                }}
                selected={filters.categories.includes(filters.categories[0]) || false}
              />
            )}
          </div>
          {showTags && (
            <div className="flex flex-wrap gap-2 mb-3">
              {orderedTags.map((tag) => (
                <FilterCard
                  type="tag"
                  key={tag.id}
                  title={`${tag.name} (${tag.count})`}
                  count={10}
                  onClick={() => handleFilterClick('tags', tag.name)}
                  selected={filters.tags.includes(tag.name) || false}
                />
              ))}
            </div>
          )}
        </>
      )}

      {pageTitle !== 'Vendors' && pageTitle !== 'Preferred Partners' && (
        <div className="flex flex-wrap gap-2 mb-3">
          {orderedTags.map((tag) => (
            <FilterCard
              type="tag"
              key={tag.id}
              title={`${tag.name} (${tag.count})`}
              count={10}
              onClick={() => handleFilterClick('tags', tag.name)}
              selected={filters.tags.includes(tag.name) || false}
            />
          ))}
        </div>
      )}

      {showSearch && (
        <div>
          <div className="mb-1.5">
            <SearchInput
              onChange={(e) => handleSearch(e.target.value)}
              defaultValue={search}
              value={search}
              lightBg
              placeholder="Search"
              // maxLength={20}
              fontSize="12px"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Filters
