import React from 'react'

import moment from 'moment'
import { useMutation } from 'react-query'
import { useSetState } from 'react-use'

import CabalButton from 'global/CabalButton'
import { Label, TextArea, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

type noteType = {
  uuid: string
  candidate_uuid: string
  subject: string
  body: string
  updated_at: string
  user: {
    name: string
  }
}

interface Props {
  action: 'create' | 'edit' | 'view'
  candidateUuid?: string
  teamSlug?: string
  onHide: () => void
  onFinalize?: () => void
  noteData?: noteType
}

const CandidateNoteModal: React.FC<Props> = ({
  action,
  candidateUuid,
  teamSlug,
  onHide,
  onFinalize,
  noteData,
}) => {
  const { team } = useTeam(teamSlug)
  const [note, setNote] = useSetState<noteType>(noteData || undefined)
  const [working, setWorking] = React.useState(false)
  const creating = action === 'create'
  const editing = action === 'edit'
  const creatingOrEditing = creating || editing
  const viewing = action === 'view'

  const createCandidateNoteMutation = useMutation(
    async () => {
      if (creating) {
        return await callApi(api.createCandidateNote, {
          candidate_note: {
            candidate_uuid: candidateUuid,
            subject: note.subject,
            body: note.body,
          },
        })
      } else {
        return await callApi(api.updateCandidateNote, note.uuid, {
          candidate_note: {
            candidate_uuid: candidateUuid,
            subject: note.subject,
            body: note.body,
          },
        })
      }
    },
    {
      onMutate: () => {
        setWorking(true)
      },
      onSuccess: () => {
        onFinalize()
        setWorking(false)
        cabalToast({
          style: 'success',
          content: `Note ${creating ? 'created' : 'updated'} successfully`,
        })
      },
      onError: (error: any) => {
        setWorking(false)
        error.response
          ? cabalToast({
              style: 'error',
              content: `Failed to ${creating ? 'create' : 'update'} note: ${
                error.response.data.errors.message
              }`,
            })
          : cabalToast({
              style: 'error',
              content: `Failed to ${creating ? 'create' : 'update'} note`,
            })
      },
    },
  )

  const deleteCandidateNoteMutation = useMutation(
    async () => {
      return await callApi(api.deleteCandidateNote, note.uuid)
    },
    {
      onMutate: () => {
        setWorking(true)
      },
      onSuccess: () => {
        onFinalize()
        setWorking(false)
        cabalToast({
          style: 'success',
          content: 'Note deleted successfully',
        })
      },
      onError: (error: any) => {
        setWorking(false)
        error.response
          ? cabalToast({
              style: 'error',
              content: `Failed to create note: ${error.response.data.errors.message}`,
            })
          : cabalToast({
              style: 'error',
              content: 'Failed to delete note',
            })
      },
    },
  )

  const ModalTitle = () => {
    return (
      <div className="flex justify-between self-center items-center py-3">
        <div className="space-x-2">
          <Typography fontSize="20" fontWeight={600} lineHeight="15px">
            {creating ? 'Add Note' : editing ? 'Edit Note' : note.subject}
          </Typography>
        </div>
      </div>
    )
  }

  const notesVisibilityMessage = (text?: string) => {
    return (
      <>
        {text && (
          <Typography fontSize="14" lineHeight="20px" color="fog">
            <i className="fa fa-regular fa-lock mr-2" />
            {text}
          </Typography>
        )}
        {!text && (
          <Typography fontSize="14" lineHeight="20px" color="fog">
            &nbsp;
          </Typography>
        )}
      </>
    )
  }

  const deleteButton = () => {
    return (
      <CabalButton
        className={editing ? 'mt-4' : ''}
        variant="destructive"
        working={working}
        onClick={() => confirm('Are you sure?') && deleteCandidateNoteMutation.mutate()}
        data-testid="candidate-delete-note-button"
      >
        Delete
      </CabalButton>
    )
  }

  const saveButton = () => {
    return (
      <CabalButton
        className={editing ? 'mt-4' : ''}
        disabled={!note.subject || !note.body}
        working={working}
        onClick={() => createCandidateNoteMutation.mutate()}
        data-testid="candidate-save-note-button"
      >
        Save
      </CabalButton>
    )
  }

  const visibilityMessage = `Notes are only visible to ${team?.name} employees`

  const leftActions = () => {
    return (
      <>
        {creating && notesVisibilityMessage(visibilityMessage)}
        {editing && (
          <>
            {notesVisibilityMessage(visibilityMessage)}
            <br />
            {deleteButton()}
          </>
        )}
      </>
    )
  }

  const rightActions = () => {
    return (
      <>
        {creating && saveButton()}
        {editing && (
          <>
            {notesVisibilityMessage()}
            <br />
            {saveButton()}
          </>
        )}
      </>
    )
  }

  const creatingOrEditingContent = () => {
    return (
      <>
        <Label
          label={
            <Typography fontSize="14" lineHeight="20px">
              Subject
            </Typography>
          }
          className="w-full"
        >
          <TextInput
            className="w-full"
            placeholder="Add a subject"
            value={note.subject}
            onChange={(e) => setNote({ subject: e.currentTarget.value })}
            data-testid="candidate-note-subject-input"
          />
        </Label>
        <Label
          label={
            <Typography fontSize="14" lineHeight="20px">
              Note
            </Typography>
          }
          className="w-full"
        >
          <TextArea
            value={note.body}
            placeholder="Enter note"
            rows={5}
            className="w-full"
            onChange={(e) => setNote({ body: e.target.value })}
            data-testid="candidate-note-body-text"
          />
        </Label>
      </>
    )
  }

  const viewingContent = () => {
    return (
      <>
        <p>
          <Typography fontSize="14" lineHeight="20px" color="fog">
            {`${note.user.name} · ${moment(note.updated_at).format('l')}`}
          </Typography>
        </p>
        <p className="text-justify">
          <Typography fontSize="14" lineHeight="20px" color="fog">
            {note.body}
          </Typography>
        </p>
      </>
    )
  }

  return (
    <Modal
      header={ModalTitle()}
      onHide={onHide!}
      canClose
      show
      leftActions={leftActions()}
      rightActions={rightActions()}
    >
      <ModalSectionWrapper className="-mb-3">
        {creatingOrEditing && creatingOrEditingContent()}
        {viewing && viewingContent()}
      </ModalSectionWrapper>
    </Modal>
  )
}

export default CandidateNoteModal
