import React from 'react'

import { useHistory, useRouteMatch } from 'react-router-dom'

import LeftSidebar from 'containers/GlobalMessagesPage/LeftSidebar'
import Messages from 'containers/GlobalMessagesPage/Messages'
import PageWrapper from 'global/PageWrapper'
import Tabs, { Tab } from 'global/Tabs'

import { cn } from 'utils/styles'

import Groups from './Groups'
import Templates from './Templates'

const GlobalMessagesPage: React.VFC = () => {
  const match = useRouteMatch<{ tab: string }>(`/messages/:tab`)
  const history = useHistory()
  const [minimize, setMinimize] = React.useState(false)

  const tab = match?.params.tab || 'inbox'

  const tabs: Tab[] = [
    {
      id: 'inbox',
      component: () => <Messages tab="shared-with-me" />,
      label: 'Shared with me',
      dataTestId: 'open-shared-with-me-tab',
    },
    {
      id: 'my_drafts',
      component: () => <Messages tab="drafted-for-others" />,
      label: 'My Drafts',
      dataTestId: 'open-my-drafts-tab',
    },
    {
      id: 'sent',
      component: () => <Messages tab="sent" />,
      label: 'Sent',
      dataTestId: 'open-sent-tab',
    },
    {
      id: 'all',
      component: () => <Messages tab="all" />,
      label: 'All',
      dataTestId: 'open-all-tab',
    },
    {
      id: 'archive',
      component: () => <Messages tab="archive" />,
      label: 'Archive',
      dataTestId: 'open-archive-tab',
    },
    {
      id: 'templates',
      component: () => <Templates />,
      label: 'Templates',
      dataTestId: 'open-templates-tab',
    },
    {
      id: 'groups',
      component: () => <Groups />,
      label: 'Groups',
      dataTestId: 'open-groups-tab',
    },
    // {
    //   id: 'tags',
    //   component: () => <Messages tab="tags" />,
    //   label: 'Tags',
    //   dataTestId: 'open-tags-tab',
    // },
  ]

  const activeIndex = tabs.findIndex((t) => t?.id === tab)

  const handleChangeTab = (index: number, tab?: string) => {
    history.push(`/messages/${tab}`)
  }

  return (
    <PageWrapper fullWidth={true} title="Messages" className="">
      <div className="flex -mr-2">
        <div
          className={cn(
            'pt-2 pr-2 w-60 transform border-r-[1px] dark:border-[#2d3748] border-[#E3E5E8] h-screen',
            { 'w-12': minimize },
          )}
        >
          <LeftSidebar minimize={minimize} setMinimize={setMinimize} />
        </div>
        <div className="flex flex-col flex-1 gap-8">
          <Tabs data={tabs} hideTabs onChange={handleChangeTab} index={activeIndex} sticky />
        </div>
      </div>
    </PageWrapper>
  )
}

export default GlobalMessagesPage
