import React from 'react'

import _ from 'lodash'

const newline = '\n'

export const nl2br = (text: string) => {
  if (typeof text === 'number') {
    return text
  } else if (typeof text !== 'string') {
    return ''
  }

  let lines = text.split(newline)
  return (
    <>
      {lines.map(function (line, i) {
        return (
          <span key={i}>
            {line}
            <br />
          </span>
        )
      })}
    </>
  )
}

export const parameterize = (string: string, separator = '-') => {
  // Replace accented chars with their ASCII equivalents.
  let parameterizedString = _.deburr(string).toLowerCase()

  // Turn unwanted chars into the separator.
  parameterizedString = parameterizedString.replace(/[^a-z0-9\-_]+/g, separator)

  if (!separator || separator === '') {
    return parameterizedString
  }

  let reDuplicateSeparator, reLeadingTrailingSeparator

  if (separator === '-') {
    reDuplicateSeparator = /-{2,}/g
    reLeadingTrailingSeparator = /^-|-$/g
  } else {
    const reSep = _.escapeRegExp(separator)
    reDuplicateSeparator = new RegExp(`${reSep}{2,}`, 'g')
    reLeadingTrailingSeparator = new RegExp(`^${reSep}|${reSep}$`, 'g')
  }

  // No more than one of the separator in a row.
  parameterizedString = parameterizedString.replace(reDuplicateSeparator, separator)
  // Remove leading/trailing separator.
  parameterizedString = parameterizedString.replace(reLeadingTrailingSeparator, '')

  return parameterizedString
}

export const addProtocolToUrlIfMissing = (url: string) => {
  if (!url) return url

  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url
  }

  return url
}

export const urlToDomain = (url: string | undefined | null) => {
  if (!url) return url

  return url.toLowerCase().match(/^(?:https?:\/\/)?([\w-.]+)(\/.*)?$/)?.[1]
}

export const emailToDomain = (email: string | undefined | null) => {
  if (!email) return email

  return email.toLowerCase().split('@')[1]
}

export const isUuid = (uuid: string) => {
  return (
    uuid &&
    uuid.length === 36 &&
    uuid[8] === '-' &&
    uuid[13] === '-' &&
    uuid[18] === '-' &&
    uuid[23] === '-'
  )
}

export const splitNameInFirstAndLast = (name: string) => {
  const names = name.split(' ')
  const firstName = names[0]
  const lastName = names.slice(1).join(' ')
  return [firstName, lastName] as const
}

export const isNullOrUndefined = (value: any) => {
  return value === null || value === undefined
}

export const capitalize = (sentence: string) => {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1)
}
