import { ITeamData } from 'components/Winsite/types/ITeamData'
import { cabalToast } from 'ui-components/Toast'

import { loginUrl } from 'utils/url'

const handleRequestAccess = () => {
  window.open(loginUrl(), '_blank', 'noopener noreferrer')
}

export const loginWithDomainToast = (teamData: ITeamData | undefined, content: string) => {
  cabalToast({
    content: content,
    style: 'error',
    cta: {
      variant: 'tertiary',
      content: `Login with your ${teamData?.company_name.toLowerCase()} email`,
      className: 'underline',
      doNotDismissToastOnClick: true,
      onClick: handleRequestAccess,
    },
  })
}
