import React from 'react'

import styled from 'styled-components'

const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.layout.nav_bg_color};
`

interface IProps {
  children: React.ReactNode
}

export function HeaderForCenter({ children }: IProps) {
  return (
    <HeaderWrapper className="sticky top-0 z-10">
      <div className={'flex-col w-full py-3'}>
        <div className="flex flex-row sm:flex-row gap-x-2 items-start">{children}</div>
      </div>
    </HeaderWrapper>
  )
}
