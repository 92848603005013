import React from 'react'

import styled from 'styled-components'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'

import Img from '../../../../assets/images/offer_img_vcs.png'

const Image = styled.img`
  height: 145px;
`

const HowItWorksVcs = ({ onSetStep }: { onSetStep: () => void }) => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  return (
    <div>
      <div className="p-8 grid sm:grid-cols-2 gap-4">
        <div className="flex justify-center">
          <Image src={Img} height="145" />
        </div>
        <div className="self-center">
          <Typography fontSize="14" component="p">
            Home is where the {team?.name} team will share updates, offers, connections and other
            resources.
          </Typography>
        </div>
      </div>
      <div className="pb-5 flex items-center justify-center">
        <CabalButton
          className="w-1/3"
          onClick={() => onSetStep()}
          data-testid="how-it-works-continue"
        >
          Got it
        </CabalButton>
      </div>
    </div>
  )
}

export default HowItWorksVcs
