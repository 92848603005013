import { sanitize } from 'dompurify'
import * as React from 'react'
import styled from 'styled-components'

import CabalTitle from 'global/Title'

import Footer from '../NewSite/footer'
import Nav from '../NewSite/nav'
import {
  BlogContainer,
  BlogContent,
  BlogHeaderImg,
  BlogTitle,
  BodyMask,
  Divider,
  LandingPageWrapper,
  SignupContainer,
} from '../NewSite/styles'

const BlogHtml = styled.div`
  p {
    font-size: 18px;
    line-height: 1.4;
    padding-bottom: 32px;
  }

  h2 {
    font-size: 24px;
    line-height: 40px;
    font-weight: 700;
    padding-bottom: 30px;
  }

  img {
    padding-top: 6px;
    padding-bottom: 30px;
    /* width: 428px; */
    margin: 0 auto;
  }

  em {
    font-size: 18px;
    line-height: 1.4;
  }

  blockquote {
    padding-left: 32px;
    padding-bottom: 32px;
  }

  ul {
    margin-left: 32px;
  }

  ol {
    margin-left: 32px;
  }

  li {
    font-size: 18px;
    line-height: 1.4;
    padding-bottom: 4px;

    &:last-child {
      padding-bottom: 32px;
    }
  }

  a {
    color: #5c69d1 !important;
    text-decoration: underline !important;

    /* @media (prefers-color-scheme: dark) {
      color: #a8bff5 !important;
    } */
  }
`

const PostTemplate = () => {
  return (
    <LandingPageWrapper>
      <SignupContainer>
        <CabalTitle title={window.post.title} />
        <BodyMask>
          <BlogContainer>
            <Nav />
            <BlogContent>
              <BlogTitle>{window.post.title}</BlogTitle>
              <BlogHeaderImg src={window.post.feature_image} />
              <BlogHtml dangerouslySetInnerHTML={{ __html: sanitize(window.post.html) }} />
            </BlogContent>
            <Divider className="mt-16" />
            <Footer withBlog={true} path={location.pathname} />
          </BlogContainer>
        </BodyMask>
      </SignupContainer>
    </LandingPageWrapper>
  )
}

export default PostTemplate
