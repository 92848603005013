import React from 'react'

import MemberAddCompanyModal from 'containers/Portfolio/MemberView/MemberAddCompanyModal'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'

interface Props {
  onClick?: () => void
  companySlug: string
  cta?: React.ReactNode
}

const PortfolioEmptyState: React.VFC<Props> = ({ onClick, companySlug, cta }) => {
  const { canEditPortfolio } = useAccessControl(companySlug)
  const { team } = useTeam(companySlug)
  const { user } = useCurrentUser()
  const { showModal } = useModal()

  const renderMemberAddCompanyModal: RenderModal = (resolve) => (
    <MemberAddCompanyModal show onHide={() => resolve()} teamSlug={companySlug} />
  )

  const defaultCta = canEditPortfolio ? (
    <CabalButton
      leftIcon={<i className="far fa-plus" />}
      onClick={onClick}
      className="ml-auto order-2"
      data-testid="empty-portfolio-add-company-btn"
    >
      Add Portfolio Companies
    </CabalButton>
  ) : (
    <CabalButton
      onClick={() => showModal(renderMemberAddCompanyModal, 'render_member_add_company_modal')}
    >
      Join {team?.name}'s portfolio
    </CabalButton>
  )

  return (
    <EmptyState
      icon={<i className={`fal fa-folder-open fa-fw`} />}
      // heading={canEditPortfolio ? '' : `Part of the ${team?.name} Portfolio?`}
      heading={''}
      body={
        canEditPortfolio
          ? 'Add portfolio companies to share asks, updates and offers.'
          : `If you are in the ${team?.name} portfolio, add your company to shares asks, updates, and offers.`
      }
      cta={defaultCta}
    />
  )
}

export default PortfolioEmptyState
