import React, { useEffect } from 'react'

import Expand from 'react-expand-animated'
import { useMutation } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

import api from 'utils/api'
import { ConnectionStatusType, Team } from 'utils/types'
import { numWithCommas } from 'utils/ui'

const StyleWrapper = styled.div`
  ${tw`mb-2 items-center px-5 py-4 mt-4 rounded-lg`}
  background-color: white;
  border: 2px solid ${({ theme }) => theme.colors.gray100};
`

const ProgressBarInner = styled.div`
  background-color: ${({ theme }) => theme.buttons.primary.bg_color};
`

const ProgressBar = ({ percent }: any) => (
  <div className="w-full bg-gray-300 rounded-full h-2.5">
    <ProgressBarInner
      className="bg-blue-600 h-2.5 rounded-full"
      style={{ width: percent }}
    ></ProgressBarInner>
  </div>
)

interface Props {
  connectionStatus: ConnectionStatusType
  syncStatus: 'complete' | 'syncing' | 'normal'
  onSyncComplete: () => void
  totalConnections: number
  onShowUpload: () => void
  teamSlug?: string
  teams: Partial<Team>[]
}

const SyncStatus: React.FC<Props> = ({
  connectionStatus,
  syncStatus,
  onSyncComplete,
  totalConnections,
  onShowUpload,
  teams,
  teamSlug,
}) => {
  const [startSync, setStartSync] = React.useState(false)
  const percent = React.useRef('3%')
  const currentTeam = teams.find((t) => t.slug === teamSlug)
  let expectedCount: number | undefined
  let actualCount: number | undefined

  const { google_contacts, linkedin_export } = connectionStatus

  if (google_contacts?.first_sync_started_at && !google_contacts?.first_sync_completed_at) {
    expectedCount = google_contacts.expected_count
    actualCount = google_contacts.count
  } else if (linkedin_export?.first_sync_started_at && !linkedin_export?.first_sync_completed_at) {
    expectedCount = linkedin_export.expected_count
    actualCount = linkedin_export.count
  } else {
    // extension connections
    expectedCount = connectionStatus?.expected_connections_count
    actualCount = connectionStatus?.connections_count
  }

  useEffect(() => {
    if (expectedCount && expectedCount > 0 && actualCount) {
      const calc = (actualCount / expectedCount) * 100
      percent.current = `${Math.trunc(calc)}%`
    } else {
      percent.current = '3%'
    }

    if (startSync && linkedin_export && linkedin_export.count === linkedin_export.expected_count) {
      onSyncComplete()
      if (startSync) {
        setStartSync(false)
      }
    }
  }, [expectedCount, actualCount, linkedin_export, startSync, onSyncComplete])

  useEffect(() => {
    if (
      expectedCount &&
      expectedCount > 0 &&
      actualCount &&
      !startSync &&
      syncStatus === 'syncing'
    ) {
      setStartSync(true)
    }
  }, [expectedCount, actualCount])

  const { mutate: clearErrorAndReload } = useMutation(
    ['clearImportError'],
    () => api.clearImportError(),
    {
      onSuccess: () => {
        window.location.reload()
      },
    },
  )

  return (
    <>
      {syncStatus !== 'syncing' && totalConnections > 0 && (
        <div className="flex items-center justify-center mt-6">
          <Typography color="green" fontWeight={500} fontSize="12">
            <i className="far fa-check mr-2" />
            {currentTeam?.shared_connections
              ? `You have shared ${numWithCommas(totalConnections)} connections`
              : `You have added ${numWithCommas(totalConnections)} connections`}
          </Typography>
          {/* <CabalButton padding="0" onClick={onShowUpload} variant="link">
            <Typography fontSize="14" className="ml-1 text-[#5C69D1] hover:text-[#8A97FF]">
              Add more
            </Typography>
          </CabalButton> */}
        </div>
      )}
      <Expand open={syncStatus === 'syncing'}>
        <StyleWrapper>
          {!linkedin_export.friendly_error && (
            <div className="text-center">
              <Typography fontWeight={700} fontSize={'18'} color={'done'} className="inline-block">
                <i className="far fa-sync animate-spin-slow" />
              </Typography>
              <Typography
                fontSize="14"
                lineHeight={1}
                className="ml-4"
                data-testid="syncing-connections-indicator"
                color="black"
              >
                Syncing connections
              </Typography>
            </div>
          )}

          <>
            {!linkedin_export.friendly_error && (
              <>
                <div className="mt-3">
                  <ProgressBar percent={percent.current} />
                </div>

                <Typography
                  color="black"
                  fontSize="12"
                  className="mt-3 flex items-center justify-center"
                >
                  {!expectedCount && 'Preparing Import...'}
                  {expectedCount &&
                    `${actualCount || 0} of ${expectedCount} connections (${percent.current}) `}
                </Typography>
              </>
            )}
            {linkedin_export.friendly_error && (
              <>
                <Typography
                  color="red"
                  fontSize="14"
                  className="mt-3 flex items-center justify-center"
                >
                  {linkedin_export.friendly_error}
                </Typography>
                <div className="mt-3 flex justify-center">
                  <CabalButton onClick={clearErrorAndReload}>Retry</CabalButton>
                </div>
              </>
            )}
          </>
        </StyleWrapper>
      </Expand>
    </>
  )
}

export default SyncStatus
