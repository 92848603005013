import {
  REQUEST_CENTER_STATUSES,
  REQUEST_CENTER_STATUSES_NON_PORTFOLIO,
  STATUS_ICON_LABEL_MAPPING,
  STATUS_ICON_LABEL_NON_PORTFOLIO_MAPPING,
} from 'containers/RequestCenter/statusConstants'

export const ALL_INTRO_RECORDS_PAGE_NUMBER = -1

export const TD_IDS = {
  target: 'target',
  requestor: 'requestor',
  facilitator: 'facilitator',
  initiator: 'initiator',
  lastChange: 'updated_at',
  status: 'status',
  amount: 'amount',
}

export const sortCols: { [key: string]: string } = {
  target: 'name',
  requestor: 'name',
  facilitator: 'name',
  initiator: 'name',
  lastChange: 'label',
  status: 'label',
}
export const clearbitUrl = 'https://logo.clearbit.com/'
// Exporting the imported constants
export {
  REQUEST_CENTER_STATUSES,
  REQUEST_CENTER_STATUSES_NON_PORTFOLIO,
  STATUS_ICON_LABEL_MAPPING,
  STATUS_ICON_LABEL_NON_PORTFOLIO_MAPPING,
}
