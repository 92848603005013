import axios from 'axios'

export default {
  getVerifications: (params = {}) => {
    return axios.get(`/api/verifications`, { params })
  },

  updateVerification: (uuid: string, params = {}) => {
    return axios.put(`/api/verifications/${uuid}`, params)
  },

  getVerification: (uuid: string, params = {}) => {
    return axios.get(`/api/verifications/${uuid}`, params)
  },

  upsertVerification: (global_person_id: string, params = {}) => {
    return axios.post(`/api/verifications`, {
      global_person_id,
      ...params
    })
  },

  getTeamVerificationStats: (domain: string) => {
    return axios.get('/api/verifications/team_stats', { params: { domain } })
  }
}
