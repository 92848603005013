import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'

const MsgWrapper = styled.div`
  ${tw`mt-2 px-6 py-5 rounded-lg`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border-top-left-radius: 0px;
  border: ${({ theme }) => theme.border};
`

const ContextWrapper = styled.div`
  white-space: pre-wrap;
`

const WelcomeMessage = ({ onSetStep }: { onSetStep: () => void }) => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)

  const prompt_user = team?.admins_and_members.find(
    (u) => u.uuid === team?.sales_ask_prompt_user_id,
  )

  const msg =
    team?.sales_asks_prompt ||
    `👋 Welcome to our Cabal page. Here we’ll share updates and ways to help us win. \n\nTo start, share your connections then click “Offer intro” for contacts you’re willing to introduce. When you offer an intro, we will follow up with custom referral emails for your approval.`

  return (
    <div>
      <div className="flex p-3 sm:p-6">
        <Avatar
          name={prompt_user?.first_name}
          src={prompt_user?.avatar_url}
          className="flex-shrink-0"
          size="48"
          round
        />

        <div className="flex flex-col ml-2.5">
          <Typography fontSize="14" fontWeight={600}>
            {prompt_user?.first_name}
          </Typography>
          <MsgWrapper>
            <Typography fontSize="14" component="span">
              <ContextWrapper>{msg}</ContextWrapper>
            </Typography>
          </MsgWrapper>
        </div>
      </div>
      <div className="pb-5 flex items-center justify-center">
        <CabalButton
          className="w-1/3"
          data-testid="welcome-msg-continue"
          onClick={() => onSetStep()}
        >
          Continue
        </CabalButton>
      </div>
    </div>
  )
}

export default WelcomeMessage
