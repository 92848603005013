import React from 'react'

import Typography from 'global/Typography'

interface Props {
  title: string
  subtitle: string
  onBack?: () => void
}

const Header: React.FC<Props> = ({ title, subtitle, onBack }) => {
  return (
    <div className="relative">
      {onBack && (
        <Typography color="fog" onClick={onBack} className="absolute top-3 left-2 cursor-pointer">
          <i className="far fa-chevron-left mr-2" />
        </Typography>
      )}

      <div className="flex flex-col items-center justify-center py-2">
        <Typography fontSize="20" fontWeight={600}>
          {title}
        </Typography>
        <Typography color="fog" fontSize="14" className="text-center">
          {subtitle}
        </Typography>
      </div>
    </div>
  )
}

export default Header
