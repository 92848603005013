import React, { useState } from 'react'
import validator from 'validator'
import { cabalToast } from 'ui-components/Toast'

import Modal from 'global/Modal'
import { MultiSelect } from 'global/Input'
import TextEditor from 'global/TextEditor/ckeditor'
import CabalButton from 'global/CabalButton'
import api from 'utils/api'
import { ReferralModel } from 'utils/types'
import { useCurrentUser } from 'store/hooks'

interface Props {
  onHide: () => void
  reloadData: () => void
  show: boolean
  emails: string[]
  setEmails: (selected: string[]) => void
  referralCode: ReferralModel
}

const PreviewModal = ({
  emails,
  setEmails,
  onHide,
  show,
  reloadData,
  referralCode: { url, code },
}: Props) => {
  const {
    user: { first_name },
  } = useCurrentUser()

  const body = `
  Hi – I’ve been using Cabal, a private workspace designed to help founders get more out of their
  investors and advisors. I thought you’d like to give it a try.
  <br><br>
  You can get started here: <a href=${url}>${url}</a>
  <br><br>
  - ${first_name}
  `
  const [messageBody, setMessageBody] = useState(body)
  const [working, setWorking] = useState(false)

  const sendReferralMessage = () => {
    setWorking(true)
    api
      .sendReferralMessage(code, emails, messageBody)
      .then(({ data: { registered_emails } }) => {
        if (emails.length > registered_emails.length)
          cabalToast({ style: 'success', content: 'Successfully sent referral invites' })
        if (registered_emails.length > 0)
          cabalToast({
            style: 'warning',
            content: `Already registered email(s): ${registered_emails.join(', ')}`,
          })
        reloadData()
        setEmails([])
      })
      .finally(() => {
        setWorking(false)
        onHide()
      })
  }

  return (
    <Modal
      header="Preview Message"
      onHide={onHide}
      show={show}
      rightActions={
        <CabalButton onClick={sendReferralMessage} working={working}>
          Send
        </CabalButton>
      }
    >
      <MultiSelect
        value={emails}
        options={[]}
        onChange={(v) => setEmails(v || [])}
        onCreateOption={(v) => {
          if (validator.isEmail(v)) {
            setEmails([...(emails || []), v])
          } else {
            cabalToast({ style: 'error', content: 'Please enter a valid email address' })
          }
        }}
        noOptionsMessage={() => 'Start typing email addresses'}
        isClearable={false}
        placeholder={'Email Addresses'}
        creatable
        className={'mb-2'}
        removeBorder
        removeFocusRing
      />
      <TextEditor
        value={messageBody}
        onChange={(value) => setMessageBody(value)}
        data-testid="edit-resource-editor"
      />
    </Modal>
  )
}

export default PreviewModal
