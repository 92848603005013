import React, { useEffect, useMemo, useState } from 'react'

import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { CardWrapper } from 'components/CompanyHome/styles'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import { useModal } from 'global/Modal'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { Offer } from 'utils/types'

import EditOfferModal from '../EditOfferModal'
import ProposedOfferCard from './proposedOfferCard'

const ProposedResources = () => {
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()

  const [allTags, setAllTags] = useState<string[]>([])
  const [allCategories, setAllCategories] = useState<string[]>([])
  const [offers, setOffers] = useState<Offer[]>()

  const { isLoading } = useQuery(['offers', teamSlug], () => callApi(api.getOffers, teamSlug), {
    onSuccess: ({ offers, tags, categories }) => {
      setOffers(offers)
      setAllTags(tags)
      setAllCategories(categories)
    },
  })

  const proposedResources = useMemo(() => offers?.filter((offer) => offer.proposed), [offers])

  const openEditOfferModal = (offer: Offer) => {
    showModal(
      (resolve) => (
        <EditOfferModal
          teamSlug={teamSlug}
          offer={offer}
          resolve={(offer) => {
            if (!offer) resolve()

            const offerIndex = offers?.findIndex((o) => o.uuid === offer?.uuid)
            const updatedOffers = [...(offers || [])]
            if (offerIndex !== undefined && offerIndex >= 0 && offer) {
              updatedOffers[offerIndex] = offer
            }
            setOffers(updatedOffers)

            resolve()
          }}
          allTags={allTags}
          setAllTags={setAllTags}
          isFetchingTags={isLoading}
          allCategories={allCategories}
          setAllCategories={setAllCategories}
        />
      ),
      'add-offer-modal',
    )
  }
  return (
    <PageWrapper title="Proposed Resources">
      <PageHeader data={{ title: 'Proposed Resources' }} backUrl={`/${teamSlug}/resources`} />

      <div>
        {proposedResources?.map((o: Offer) => (
          <ProposedOfferCard
            key={o.uuid}
            offer={o}
            teamSlug={teamSlug}
            onEdit={openEditOfferModal}
          />
        ))}

        {proposedResources?.length === 0 && (
          <CardWrapper>
            <Typography fontSize="14" color="fog_rain" component="div">
              No Proposed Resources available
            </Typography>
          </CardWrapper>
        )}
      </div>
    </PageWrapper>
  )
}

export default ProposedResources
