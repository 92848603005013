import React, { useState } from 'react'

import { Field, FieldProps, Form, Formik } from 'formik'
import { useMutation, useQuery } from 'react-query'
import { v4 as uuidv4 } from 'uuid'

import useCrmStatus from 'components/CrmStatus'
import FieldFilter from 'components/EditList/Details/FieldFilter'
import { Divider } from 'components/SideNav/styles'
import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import { InputWrapper } from 'containers/OnboardingV2/styles'
import CabalButton from 'global/CabalButton'
import { Label, MultiSelectField } from 'global/Input'
import Loading from 'global/Loading'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { CompanyListFilter } from 'utils/types'

interface Props {
  setOnClose: () => void
  provider: 'salesforce' | 'hubspot'
}

const SalesforceSection: React.FC<Props> = ({ setOnClose, provider }) => {
  const teamSlug = useTeamSlug()
  const { salesforceConnected, hubspotConnected } = useCrmStatus(teamSlug)
  const { companyListData: list, refetchCompanyList } = useSalesListContext()

  const [listFilters, setListFilters] = useState(list.company_list_filters || [])

  const { data: salesPeopleEmailOptions } = useQuery(['owner_email', teamSlug], () =>
    callApi(api.getOwnerEmails, teamSlug),
  )
  const { data: stageNameOptions } = useQuery(['stage_name', teamSlug], () =>
    callApi(api.getStageNames, teamSlug),
  )

  const { mutate: enableDynamicList, isLoading } = useMutation(
    (data: {
      dynamic: boolean
      provider: string
      owner_emails: string[]
      stage_names: string[]
      pipeline_names: string[]
      company_list_filters: CompanyListFilter[]
    }) => callApi(api.enableDynamicList, list.uuid, data),
    {
      onSuccess: () => {
        refetchCompanyList()
        setOnClose()
      },
    },
  )

  const { data: pipelineOptions } = useQuery(
    ['pipeline_names', teamSlug],
    () => callApi(api.getPipelineNames, teamSlug),
    {
      enabled: !!hubspotConnected,
    },
  )

  const { isFetching: isLoadingSfdcFields, data: getSfdcFieldsData } = useQuery(
    ['getSfdcFields', teamSlug],
    () => callApi(api.getSfdcFields),
    {
      enabled: !!salesforceConnected,
    },
  )

  const sfdcFieldOptions = getSfdcFieldsData?.sfdc_fields || []

  const { isFetching: isLoadingHubspotFields, data: getHubspotFieldsData } = useQuery(
    ['getHubspotFields'],
    () => callApi(api.getHubspotFields),
    {
      enabled: !!hubspotConnected,
    },
  )
  const hubspotFieldOptions = getHubspotFieldsData?.hubspot_fields || []

  const showCrmFieldOptions =
    (provider == 'salesforce' && !!sfdcFieldOptions.length) ||
    (provider == 'hubspot' && !!hubspotFieldOptions.length)

  const filteredStageOptions = () => {
    const pipelineFilterSet = (list?.filters?.pipeline_names?.length || 0) > 0
    const stagesLoaded = (stageNameOptions?.stages?.length || 0) > 0

    const stages =
      stagesLoaded && pipelineFilterSet
        ? stageNameOptions?.stages?.filter((stage) => {
            return list?.filters?.pipeline_names?.includes(stage.pipeline)
          }) || []
        : stageNameOptions?.stages || []

    return stages.map((stage) => ({
      label: stage.id,
      value: stage.name,
    }))
  }

  const connected = provider === 'salesforce' ? salesforceConnected : hubspotConnected

  const authButton = () => {
    switch (provider) {
      case 'salesforce':
        return {
          authLink: `/salesforce_auth?return_to=${window.location.href}`,
          buttonText: 'Connect Salesforce',
        }
      case 'hubspot':
        return {
          authLink: `/auth/hubspot?return_to=${window.location.href}`,
          buttonText: 'Connect Hubspot',
        }
    }
  }

  return (
    <div className="mt-6 flex">
      {!connected && (
        <>
          <ModalInputWrapper>
            <ModalInputLabel>Connect account</ModalInputLabel>
            <CabalButton
              size="medium"
              onClick={() => {
                window.location.assign(authButton().authLink)
              }}
              className="w-full"
            >
              {authButton().buttonText}
            </CabalButton>
          </ModalInputWrapper>
        </>
      )}
      {connected && !!provider && (
        <Formik
          initialValues={{
            owner_emails: list?.filters?.owner_emails || [],
            stage_names: list.filters?.stage_names || [],
            pipeline_names: list.filters?.pipeline_names || [],
            company_list_filters: listFilters,
            dynamic: true,
            provider: provider,
          }}
          onSubmit={(values) => {
            enableDynamicList(values)
          }}
        >
          {({ handleSubmit }) => (
            <Form className="flex flex-col items-start w-full" onSubmit={handleSubmit}>
              <ModalInputWrapper className="w-full">
                <ModalInputLabel>Owner</ModalInputLabel>
                <Field name="owner_emails">
                  {(props: FieldProps) => (
                    <MultiSelectField
                      value={list?.filters?.owner_emails || []}
                      placeholder="Owner"
                      isClearable
                      options={
                        salesPeopleEmailOptions?.emails?.map((email) => ({
                          label: email,
                          value: email,
                        })) || []
                      }
                      fieldProps={props}
                      className="w-full rounded-lg p-2"
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              {provider === 'hubspot' && (
                <InputWrapper className="w-full">
                  <ModalInputLabel>Pipeline</ModalInputLabel>
                  <Field name="pipeline_names">
                    {(props: FieldProps) => (
                      <MultiSelectField
                        value={list.filters?.pipeline_names}
                        fieldProps={props}
                        className="w-full rounded-lg p-2"
                        placeholder="Pipeline"
                        isClearable
                        compactPlaceholder
                        options={
                          pipelineOptions?.pipelines.map((pipeline) => ({
                            label: pipeline,
                            value: pipeline,
                          })) || []
                        }
                      />
                    )}
                  </Field>
                </InputWrapper>
              )}

              <ModalInputWrapper className="w-full">
                <ModalInputLabel>Stage</ModalInputLabel>
                <Field name="stage_names">
                  {(props: FieldProps) => (
                    <MultiSelectField
                      value={list.filters?.stage_names}
                      placeholder="Stage"
                      isClearable
                      options={filteredStageOptions()}
                      fieldProps={props}
                      className="w-full rounded-lg p-2"
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              {((provider == 'hubspot' && isLoadingHubspotFields) ||
                (provider == 'salesforce' && isLoadingSfdcFields)) && <></>}

              {showCrmFieldOptions && (
                <ModalInputWrapper className="py-2 w-full">
                  <ModalInputLabel>Field filters</ModalInputLabel>
                  <div className="w-full flex flex-col gap-2 px-2">
                    {listFilters?.map((fieldFilter, index) => (
                      <div key={`${fieldFilter.uuid}-${index}`}>
                        <Field name={`company_list_filters[${index}]`}>
                          {({ field, form }: FieldProps) => (
                            <FieldFilter
                              fieldFilter={fieldFilter}
                              setFieldFilter={(newFilter) => {
                                form.setFieldValue(`company_list_filters[${index}]`, newFilter)
                                setListFilters(
                                  listFilters.map((filter, i) =>
                                    i === index ? newFilter : filter,
                                  ),
                                )
                              }}
                              sfdcFieldOptions={sfdcFieldOptions}
                              hubspotFieldOptions={hubspotFieldOptions}
                              removeFieldFilter={() => {
                                form.setFieldValue(`company_list_filters[${index}]`, undefined)
                                setListFilters(listFilters.filter((_, i) => i !== index))
                              }}
                            />
                          )}
                        </Field>
                        {index < listFilters.length - 1 ? (
                          <div className="flex items-center justify-between my-2">
                            <Divider className="w-full" />
                            <Typography className="mx-3 text-sm text-gray-500">AND</Typography>
                            <Divider className="w-full" />
                          </div>
                        ) : (
                          <div className="my-2">
                            <Divider />
                          </div>
                        )}
                      </div>
                    ))}

                    <Typography
                      component="p"
                      color={'purple'}
                      fontSize="12"
                      className="cursor-pointer text-purple-600 hover:underline"
                      onClick={() =>
                        setListFilters([
                          ...(listFilters || []),
                          {
                            uuid: uuidv4(),
                            operator: '=',
                            filter_value: '',
                            filterable: undefined,
                          } as any as CompanyListFilter,
                        ])
                      }
                    >
                      + Add field filters
                    </Typography>
                  </div>
                </ModalInputWrapper>
              )}

              <CabalButton
                className="mt-6 mb-2 self-end"
                variant="primary"
                type="submit"
                disabled={isLoading}
              >
                Save
              </CabalButton>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default SalesforceSection
