import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import Attachments from 'components/Attachments'
import MessageButtons from 'components/MessageFeedCard/MessageButtons'

import { LimitedUser, MessageModel, MessageThread } from 'utils/types'
import SenderData from './SenderData'
import CabalMessage from 'models/CabalMessage'

interface Props extends React.HTMLProps<HTMLDivElement> {
  message: CabalMessage
  messageThread?: MessageThread
  snippet?: string
  replyCount?: number
  allowExpansion?: boolean
  isExpanded?: boolean
  date?: string
  isUnread?: boolean
  user?: Pick<LimitedUser, 'first_name' | 'email' | 'name' | 'avatar_url'>
}

const MessageContent: React.VFC<Props> = ({
  replyCount,
  message,
  allowExpansion = false,
  isExpanded = false,
  isUnread,
  date,
  snippet,
  user,
  messageThread,
  ...restProps
}) => {
  const [expand, setExpand] = useState<boolean>(isExpanded)

  return (
    <div {...restProps}>
      <SenderData
        user={user}
        message={message}
        snippet={snippet}
        date={date}
        replyCount={replyCount}
        isUnread={isUnread}
        isExpanded={expand}
        allowExpansion={allowExpansion}
        setExpand={() => (allowExpansion ? setExpand(!expand) : null)}
      />

      {expand && (
        <div className="ml-12">
          <div className="pt-2">
            <MessageButtons message={message} markAsRead={() => {}} />
          </div>

          {message.video && (
            <div
              style={{ border: '1px solid #232E40', borderRadius: '0.25rem', maxWidth: 400 }}
              className="cursor-pointer pt-2"
            >
              <ReactPlayer
                url={`https://stream.mux.com/${message.video.mux_playback_id}.m3u8`}
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
          )}

          {message.attachments && message.attachments.length > 0 && (
            <div className="pt-2">
              <Attachments attachments={message.attachments} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MessageContent
