import React, { useEffect, useMemo, useState } from 'react'
import { useCurrentUser, useTeams } from 'store/hooks'
import Loading from 'global/Loading'
import { useMutation } from 'react-query'
import api, { callApi } from 'utils/api'
import { Feed as FeedType } from 'utils/types/feed'
import { IterableElement } from 'type-fest'
import FeedCard from './FeedCard'
import Tabs, { Tab } from 'global/Tabs'
import styled from 'styled-components'
import tw from 'twin.macro'
import CabalButton from 'global/CabalButton'
import PullToRefresh from 'react-simple-pull-to-refresh'
import Typography from 'global/Typography'
import CabalTitle from 'global/Title'
import EmptyStateV2 from 'components/EmptyStates/EmptyStateV2'
import cx from 'classnames'

const TabContainer = styled.div`
  ${tw`flex items-center justify-between sticky top-0 z-10 flex-initial`}

  background: ${({ theme }) => theme.layout.main_bg_color};
`

const EndState = styled.div`
  ${tw`border light:border-light dark:border-dark px-4 py-6 rounded-lg text-center flex flex-col gap-4`}
`

const Feed = () => {
  const { isLoading: loadingTeams, hasAccessToTeams } = useTeams()
  const { user } = useCurrentUser()

  const [filter, setFilter] = useState('all')
  const [tabIndex, setTabIndex] = useState(0)
  const [contributionCount, setContributionCount] = useState(0)
  const [doneCount, setDoneCount] = useState(0)

  const {
    data,
    isLoading,
    mutateAsync: fetch,
  } = useMutation(
    ['getFeed', filter],
    (hard_refresh?: boolean) => callApi(api.getFeed, { filter, hard_refresh }),
    {
      onSuccess: (data) => {
        if (data.caching) {
          setTimeout(fetch, 1000)
        }
        setDoneCount(0)
        setContributionCount(data.feed.contribution_count)
      },
    },
  )

  useEffect(() => {
    fetch(false)
  }, [tabIndex])

  const feed = data?.feed

  const tabs: Tab[] = [
    {
      id: 'all',
      component: () => <div />,
      label: 'All',
    },
    {
      id: 'sales',
      component: () => <div />,
      label: 'Sales',
    },
    {
      id: 'social',
      component: () => <div />,
      label: 'Social',
    },
    // {
    //   id: "jobs",
    //   component: () => <div />,
    //   label: "Jobs"
    // },
    {
      id: 'updates',
      component: () => <div />,
      label: 'Updates',
    },
  ]

  const sortedFeedItems = useMemo(() => {
    const sortedFeed: {
      type:
        | 'tweet'
        | 'deal'
        | 'message'
        | 'unshared_connection'
        | 'default_intro'
        | 'default_social'
        | 'default_referral'
        | 'email_sync'
      message?: IterableElement<FeedType['messages']>
      tweet?: IterableElement<FeedType['tweets']>
      deal?: IterableElement<FeedType['deal_board']>
      unshared_connection?: IterableElement<FeedType['unshared_connections']>
      default_intro?: IterableElement<FeedType['default_intro']>
      default_referral?: IterableElement<FeedType['default_referral']>
      default_social?: IterableElement<FeedType['default_social']>
      email_sync?: IterableElement<FeedType['email_sync']>
      created_at: string
    }[] = []

    if (feed?.tweets) {
      for (const tweet of feed.tweets) {
        sortedFeed.push({
          type: 'tweet',
          tweet,
          created_at: tweet.created_at!,
        })
      }
    }

    if (feed?.messages) {
      for (const message of feed.messages) {
        sortedFeed.push({
          type: 'message',
          message,
          created_at: message.created_at,
        })
      }
    }
    if (feed?.deal_board) {
      for (const deal of feed.deal_board) {
        sortedFeed.push({
          type: 'deal',
          deal,
          created_at: deal.created_at,
        })
      }
    }
    if (feed?.unshared_connections) {
      for (const unshared_connection of feed.unshared_connections) {
        sortedFeed.push({
          type: 'unshared_connection',
          unshared_connection,
          created_at: '',
        })
      }
    }

    if (feed?.default_intro) {
      for (const default_intro of feed.default_intro) {
        sortedFeed.push({
          type: 'default_intro',
          default_intro,
          created_at: '',
        })
      }
    }

    if (feed?.default_referral) {
      for (const default_referral of feed.default_referral) {
        sortedFeed.push({
          type: 'default_referral',
          default_referral,
          created_at: '',
        })
      }
    }

    if (feed?.default_social) {
      for (const default_social of feed.default_social) {
        sortedFeed.push({
          type: 'default_social',
          default_social,
          created_at: '',
        })
      }
    }

    if (feed?.email_sync) {
      for (const email_sync of feed.email_sync) {
        sortedFeed.push({
          type: 'email_sync',
          email_sync,
          created_at: new Date().toUTCString(),
        })
      }
    }

    sortedFeed.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))

    return sortedFeed
  }, [feed])

  useEffect(() => {
    if (sortedFeedItems.length > 9 && doneCount == sortedFeedItems.length && !isLoading) {
      fetch(true)
    }
  }, [doneCount, sortedFeedItems])

  const onCardHide = () => {
    setDoneCount((p) => p + 1)
  }

  const onContribution = () => {
    setContributionCount(contributionCount + 1)
  }

  const pullThreshold = window.innerHeight / 5
  const loading = isLoading || loadingTeams || !feed || data.caching === true
  const showFeed = !loading && sortedFeedItems.length > 0
  const showEmptyState = !loading && sortedFeedItems.length == 0

  const dummyActivities = [
    {
      name: user.name,
      avatar: user.avatar_url,
      description: 'offered an intro',
      icon: <i className="fas fa-thumbs-up" />,
    },
    {
      name: user.name,
      avatar: user.avatar_url,
      description: 'referred a candidate',
      icon: <i className="fas fa-briefcase" />,
    },
    {
      name: user.name,
      avatar: user.avatar_url,
      description: 'retweeted',
      icon: <i className="fab fa-twitter" />,
    },
  ]

  return (
    <>
      <CabalTitle title="Feed" />
      <div className={cx('flex flex-col px-2 mx-auto sm:w-3/5', { 'h-full': showEmptyState })}>
        <TabContainer>
          <div className=" px-2 w-full">
            <Tabs
              data={tabs}
              onChange={(i, id) => {
                setFilter(id!)
                setTabIndex(i)
              }}
              index={tabIndex}
              variant="filters"
              rightActions={
                <CabalButton component="link" to={'/add'} className="rounded-full">
                  <i className="fa fa-plus" />
                </CabalButton>
              }
              sticky={true}
            />
          </div>
        </TabContainer>

        {loading && <Loading className="mt-8" />}

        {showFeed && (
          <PullToRefresh
            onRefresh={() => fetch(true)}
            pullingContent={
              <Typography component="div" fontSize="24" className="text-center mt-8">
                <i className="fal fa-long-arrow-down" />
              </Typography>
            }
            refreshingContent={<Loading className="mt-8" />}
            className="flex-1 pb-10"
            pullDownThreshold={pullThreshold}
            maxPullDownDistance={pullThreshold + 25}
          >
            <div className="px-0.5">
              {sortedFeedItems.map((f, i) => {
                return (
                  <FeedCard
                    onCardHide={onCardHide}
                    onContribution={onContribution}
                    key={i}
                    i={i}
                    {...f}
                  />
                )
              })}
              <EndState>
                <Typography fontSize="24" color="fog">
                  <i className="fal fa-plus-circle" />
                </Typography>
                <Typography>Add portfolio companies</Typography>
                <div>
                  <CabalButton
                    component="link"
                    to={`/add`}
                    variant="secondary"
                    leftIcon={<i className="far fa-plus" />}
                  >
                    Add companies
                  </CabalButton>
                </div>
              </EndState>
            </div>
          </PullToRefresh>
        )}

        {showEmptyState && !hasAccessToTeams && (
          <EmptyStateV2
            text="Add portfolio companies to start making contributions"
            cta={
              <CabalButton
                variant="secondary"
                leftIcon={<i className="far fa-plus" />}
                className="mt-5"
                component="link"
                to={'/add'}
              >
                Add companies
              </CabalButton>
            }
            bottomHeadline="Do more for founders"
            dummyActivities={dummyActivities}
          />
        )}
        {showEmptyState && hasAccessToTeams && (
          <EmptyStateV2
            text="You're all caught up!"
            cta={<Typography fontSize="24"> &#127881;</Typography>}
            bottomHeadline="Do more for founders"
            dummyActivities={dummyActivities}
          />
        )}
      </div>
    </>
  )
}

export default Feed
