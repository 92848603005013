import { TextInput } from 'global/Input'
import React from 'react'

interface Props {
  value: string
  onChange: (value: string) => void
}

const SubjectField: React.VFC<Props> = ({ value, onChange }) => {
  return (
    <TextInput
      label="Subject"
      labelPosition="left"
      className="w-full border-0"
      style={{ fontSize: '14px', padding: '11.5px' }}
      value={value}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  )
}

export default React.memo(SubjectField)
