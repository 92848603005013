import axios from 'axios'

import { CurrentUserProfile, DigestSchedule, UserProfile, UserProfilePortfolio } from 'utils/types'

export default {
  getCurrentProfile: () => {
    return axios.get<CurrentUserProfile>('/api/profiles')
  },

  getProfile: (id: string) => {
    return axios.get<UserProfile>('/api/profiles', {
      params: {
        id,
      },
    })
  },

  getDigestSchedule: (id: string) => {
    return axios.get('/api/profiles/digest_schedule', {
      params: {
        id,
      },
    })
  },

  setDigestSchedule: (id: string, params: DigestSchedule) => {
    return axios.post('/api/profiles/set_digest_schedule', { ...params, id })
  },

  checkGmailAuthState: (from: string) => {
    return axios.get<{ token_status: number }>(`/api/profiles/gmail_status?from=${from}`)
  },

  checkConnectionStatus: (advisor_uuid: string) => {
    const params: any = {}
    return axios.get(`/api/advisors/${advisor_uuid}/connection_status`, { params })
  },

  updateCurrentProfile: (profile: Partial<CurrentUserProfile>, params = {}) => {
    return axios.put<{ profile: CurrentUserProfile }>('/api/profiles', {
      profile,
      ...params,
    })
  },

  clearImportError: () => {
    return axios.post<{ profile: CurrentUserProfile }>('/api/profiles/clear_import_error')
  },

  switchAccount: (email: string) => {
    return axios.put<{ profile: CurrentUserProfile }>('/api/profiles/switch_account', {
      email,
    })
  },

  resetConnections: () => {
    const params = {}
    return axios.post(`/api/profiles/reset_connections`, params)
  },

  requestEmailVerificationCode: (params: { email?: string; advisor_uuid?: string }) => {
    if (!params.email && !params.advisor_uuid)
      throw 'you need to supply either the email or advisor uuid'

    return axios.post('/auth/request_email_verification_code', params)
  },

  confirmEmailVerificationCode: (params: {
    email?: string
    advisor_uuid?: string
    code: string
  }) => {
    if (!params.email && !params.advisor_uuid)
      throw 'you need to supply either the email or advisor uuid'

    return axios.post('/auth/confirm_email_verification_code', params)
  },

  notify: (notification: string) => {
    return axios.post('/api/profiles/notify', {
      notification,
    })
  },

  subscribeToLoops: (eventName: string) => {
    return axios.post('/api/profiles/subscribe_to_loops', {
      event_name: eventName,
    })
  },

  getProfileCompanies: () => {
    return axios.get<{ companies: UserProfilePortfolio[] }>('/api/profiles/companies')
  },

  addProfileCompany: (params: any) => {
    return axios.post('/api/profiles/add_company', params)
  },

  removeProfileCompany: (params: { type: string; id: any }) => {
    return axios.post('/api/profiles/remove_company', params)
  },

  importUserConnections: (
    team_slug: string | undefined,
    upload_uuid: string,
    no_team?: boolean,
    advisor_uuid?: string,
  ) => {
    return axios.post(`/api/profiles/import_connections`, {
      team_slug,
      upload_uuid,
      no_team: no_team || false,
      advisor_uuid,
    })
  },

  createUsername: (name: string, username: string) => {
    return axios.post<{ error?: string }>(`/api/usernames`, { name, username })
  },

  scheduleUploadReadyReminder: (team_slug?: string, no_team = false) => {
    return axios.post(`/api/profiles/schedule_upload_ready_reminder`, {
      team_slug,
      no_team,
    })
  },

  addConnectionFromDesktop: (team_slug: string) => {
    return axios.post(`/api/profiles/add_connection_from_desktop`, {
      team_slug,
    })
  },

  getPublicProfile: (handle?: string) => {
    return axios.get<UserProfile>('/api/profiles/get_public_profile', {
      params: { slug: handle },
    })
  },

  updatePublicProfile: (params: Partial<UserProfile>) => {
    return axios.put<UserProfile>('/api/profiles/update_public_profile', params)
  },

  getExpertiseOptions: () => {
    return axios.get<{ options: string[] }>('/api/expertises')
  },

  getSlugAttachable: (slug: string) => {
    return axios.get<{ type: 'User' | 'Team' }>(`/api/slugs/${slug}`)
  },

  createConnectionRequest: (slug: string) => {
    return axios.post(`/api/connection_requests`, { target_user_slug: slug })
  },

  updateConnectionRequest: (slug: string, accepted: boolean) => {
    return axios.patch(`/api/connection_requests/${slug}`, { target_user_slug: slug, accepted })
  },
}
