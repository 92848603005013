import * as React from 'react'

import { useHistory } from 'react-router-dom'

import CabalButton from 'global/CabalButton'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import SectionHeader from 'ui-components/SectionHeader'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'
import useMobileApp from 'utils/hooks/useMobileApp'

import { CardBg } from '../styles'
import Contacts from './Contacts'
import EmailSignature from './EmailSignature'
import Expertise from './Expertise'
import PersonalInfo from './PersonalInfo'
import Signature from './Signature'
import UserDigestSchedule from './UserDigestSchedule'
import UserEmails from './UserEmails'

const Personal = () => {
  const { user, reloadUser } = useCurrentUser()
  const { toggleTheme, isMobile } = useColorMode()
  const history = useHistory()
  const { isMobileApp, postMessage } = useMobileApp()
  const digestSettingsRef = React.useRef()
  const [scrolledTo, setScrolledTo] = React.useState(false)

  const loadInfo = async () => {
    await reloadUser()
  }

  const handleToggleTheme = () => {
    toggleTheme()
  }

  const handleLogout = () => {
    if (isMobileApp) {
      postMessage({ type: 'logout' })
    } else {
      window.location.href = '/logout'
    }
  }

  if (window.location.hash === '#digest-settings' && digestSettingsRef.current && !scrolledTo) {
    digestSettingsRef.current.scrollIntoView()
    setScrolledTo(true)
  }

  return (
    <PageWrapper title="Editing Profile">
      <div>
        <SectionHeader className="mb-1" title="Personal Information" hideTopBorder={true} />
        <CardBg>
          <PersonalInfo
            loadInfo={loadInfo}
            name={user.name}
            title={user.title}
            avatar_url={user.avatar_url}
            paid={user.team?.paid}
          />
        </CardBg>
        <SectionHeader className="mb-1" title="Email Accounts" />
        <CardBg className="col-span-2">
          <UserEmails />
        </CardBg>
        <SectionHeader className="mb-1" title="Contact Information" />
        <CardBg>
          <Contacts
            phone={user.phone}
            twitter_url={user.twitter_url}
            linkedin_url={user.linkedin_url}
            location={user.location}
          />
        </CardBg>
        <SectionHeader className="mb-1" title="Email Signature" />
        <CardBg>
          <EmailSignature user={user} reloadUser={reloadUser} />
        </CardBg>
        {/* <CardBg>
          <Expertise user={user} />
        </CardBg> */}
        <div ref={digestSettingsRef}>
          <SectionHeader className="mb-1" title="Digest Email Schedule" />
        </div>
        <CardBg>
          <UserDigestSchedule user={user} />
        </CardBg>
        <SectionHeader className="mb-1" title="Signature" />
        <CardBg>
          <Signature loadInfo={loadInfo} signature={user.signature} />
        </CardBg>
        <SectionHeader className="mb-1" title="Miscellaneous" />
        <CardBg>
          <Typography fontWeight={700}>{'Delete account'}</Typography>
          <div className="mt-2 text-sm">
            Please email help@getcabal.com to delete your account and accompanying data.
          </div>
        </CardBg>
        {/* <CardBg>
          <Typography fontWeight={700}>Signature for documents</Typography>
        </CardBg> */}
        {isMobile && (
          <CardBg>
            <Typography fontWeight={700}>{'More actions'}</Typography>
            <div className="flex justify-between items-center mt-2">
              <Typography fontSize="14" component="div">
                <i className="far fa-sign-out fa-fw mr-3"></i>
                Logout
              </Typography>
              <CabalButton size="small" onClick={() => handleLogout()}>
                Logout
              </CabalButton>
            </div>
            <div className="flex justify-between items-center mt-4">
              <Typography fontSize="14" component="div">
                <i className="far fa-adjust fa-fw mr-3"></i>
                {user.theme === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode'}
              </Typography>
              <CabalButton size="small" onClick={() => handleToggleTheme()}>
                Toggle
              </CabalButton>
            </div>
          </CardBg>
        )}
      </div>
    </PageWrapper>
  )
}

export default Personal
