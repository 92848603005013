import styled from 'styled-components'
import tw from 'twin.macro'

// const Wrapper = styled.div`
//   ${tw`rounded px-4 py-3`}
//   background: ${({ theme }) => theme.colors.shoutout_bg};
//   box-shadow: ${({ theme }) => theme.card_shadow};
// `

const CreateWrapper = styled.div`
  ${tw`rounded-lg px-4 flex justify-center`}
`

const RequestWrapper = styled.div`
  ${tw`rounded-lg px-4 py-3 flex justify-between items-center`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
`
const CardWrapper = styled.div`
  ${tw`flex flex-col w-full cursor-pointer rounded-lg transition duration-300 p-4`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
`

const CompanyWrapper = styled.div`
  ${tw`rounded-lg px-4 py-3 flex justify-between items-center flex-col`}

  background-color: ${({ theme }) => theme.colors.cardBackground};
`

const Wrapper = styled.div`
  ${tw`rounded px-6 py-3`}
  background: ${({ theme }) => theme.colors.cardBackground};
  box-shadow: ${({ theme }) => theme.card_shadow};
`

export { CardWrapper, CompanyWrapper, CreateWrapper, RequestWrapper, Wrapper }
