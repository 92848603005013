import React from 'react'

import styled from 'styled-components'

// Define a styled component for the horizontal line
const StyledHorizontalLine = styled.hr`
  border: ${({ theme }) => theme.border};
  width: 100%;
  margin: 20px 0;
`

// Create the HorizontalLine component
const HorizontalLine = () => {
  return <StyledHorizontalLine />
}

export default HorizontalLine
