import React, { useState } from 'react'

import { useMutation, useQueryClient } from 'react-query'

import AlertBanner from 'containers/Home/OfferIntroWidget/AlertBanner'
import CabalButton from 'global/CabalButton'
import { TextArea, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

interface Props {
  onHide: () => void
  teamSlug: string
  query?: string
  showBanner?: boolean
  onBannerClick?: () => void
}

const OfferIntroModal: React.FC<Props> = ({
  onHide,
  teamSlug,
  query,
  showBanner = false,
  onBannerClick,
}) => {
  const [note, setNote] = useState('')
  const [name, setName] = useState(query || '')
  const [linkedinUrl, setLinkedinUrl] = useState('')
  const queryClient = useQueryClient()

  const linkedinSearchUrl = (name: string) => {
    return `https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(
      name,
    )}&origin=GLOBAL_SEARCH_HEADER`
  }

  const { mutate: sendVote, isLoading: sendingVote } = useMutation(
    ['createVote'],
    () =>
      callApi(api.createVote, {
        team_slug: teamSlug,
        vote: 1,
        new_person: {
          name: name,
          linkedin_url: linkedinUrl,
        },
        notes: note,
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Offer submitted' })
        queryClient.refetchQueries(['connections'])
        queryClient.refetchQueries([teamSlug, 'getMemberActivity'])
        onHide()
      },
    },
  )

  // Generic RegEx exact match validator
  const isRegexExactMatch = (value: string, regexp: any) => {
    const res = value.match(regexp)
    return res && res[0] && res[0] === res.input
  }

  const isLinkedinProfileUrl = (value: string) => {
    const linkedInProfileURLRegExp =
      '(https?:\\/\\/(www.)?linkedin.com\\/(mwlite\\/|m\\/)?in\\/[a-zA-Z0-9_.-]+\\/?)'
    return !!isRegexExactMatch(value, linkedInProfileURLRegExp)
  }

  return (
    <Modal
      show={true}
      onHide={onHide}
      header={`Add context for your offer to "${query}"`}
      rightActions={
        <CabalButton
          working={sendingVote}
          onClick={() => sendVote()}
          disabled={!name || !linkedinUrl || !isLinkedinProfileUrl(linkedinUrl)}
        >
          Save
        </CabalButton>
      }
    >
      {/* <div className=" pb-6">
        <AlertBanner showBanner={showBanner} onClick={onBannerClick!} />
      </div> */}
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Name</ModalInputLabel>
          <TextInput
            className="w-full"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel className="flex flex-col">
            <div className="flex flex-col">
              <Typography>Linkedin URL</Typography>
              <Typography
                fontSize="12"
                color="purple"
                component="a"
                href={linkedinSearchUrl(query!)}
                target="_blank"
              >
                <i className="far fa-search mr-1"></i>
                Search Linkedin
              </Typography>
            </div>
          </ModalInputLabel>
          <TextInput
            className="w-full"
            value={linkedinUrl}
            onChange={(e) => setLinkedinUrl(e.target.value)}
            placeholder="Linkedin URL"
            required
            valueType="url"
            autoFocus={true}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Note</ModalInputLabel>

          <TextArea
            className="w-full"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Add any additional context here"
            rows={4}
            maxLength={280}
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default OfferIntroModal
