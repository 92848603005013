import React from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useMutation, useQuery, useQueryClient } from 'react-query'

import { IPortfolioCompany } from 'containers/ListIndexView/PortfolioList/types'
import { IJob } from 'containers/ListIndexView/TalentList/JobsList/types'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { InvestorCompany } from 'utils/types/investor'

import JobInfo from './JobInfo'

interface Props {
  onHide: () => void
  teamSlug: string
  refetchListItems: () => void
  listUuids: string[]
  currentListUuid?: string
  company: IPortfolioCompany | InvestorCompany
  jobUuid?: string
  showListDropdown?: boolean
  memberAdding?: boolean
}

const JobsEditOrAddModal: React.FC<Props> = ({
  onHide,
  teamSlug,
  jobUuid,
  refetchListItems,
  listUuids,
  currentListUuid,
  company,
  showListDropdown,
  memberAdding,
}) => {
  const [parent] = useAutoAnimate()
  const { user } = useCurrentUser()
  const [jobData, setJobData] = React.useState<IJob>({
    title: '',
    investor_company_uuid: company?.uuid || '',
    talent_poc: {
      email: memberAdding ? user?.email : '',
      first_name: memberAdding ? user?.first_name : '',
      last_name: memberAdding ? user?.last_name : '',
    },
    tags: [],
    visibility: memberAdding ? true : false,
    list_uuids: Array.isArray(listUuids) ? listUuids : [],
    archived: memberAdding ? true : false,
  })
  const queryClient = useQueryClient()

  const createJobMutation = useMutation(
    ['createJob'],
    (job: IJob) => {
      return callApi(api.createJob, job, currentListUuid, teamSlug)
    },
    {
      onSuccess: () => {
        refetchListItems()
        queryClient.invalidateQueries(['getJobTags', teamSlug])
        cabalToast({
          style: 'success',
          content: memberAdding ? `Job submitted!` : 'Job added successfully!',
        })
        onHide()
      },
      onError: (error) => {
        cabalToast({
          style: 'error',
          content: error.response.data.errors[0].error_full_messages.toString(),
        })
      },
    },
  )

  const updateJobMutation = useMutation(
    ['updateJob', teamSlug],
    (job: IJob) => {
      return callApi(api.updateJob, job, currentListUuid, teamSlug).then(() => {
        refetchJob()
        refetchListItems()
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getJobTags', teamSlug])
        cabalToast({ style: 'success', content: 'Job updated successfully!' })
        onHide()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong while updating!' })
      },
    },
  )

  const { mutate: deleteJobMutation, isLoading: isDeleting } = useMutation(
    ['deleteJob', jobData?.uuid],
    () =>
      callApi(api.deleteJob, jobData!.uuid, teamSlug, currentListUuid).then(() => {
        refetchListItems()
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getJobTags', teamSlug])
        cabalToast({ style: 'success', content: 'Job successfully deleted' })
        onHide()
      },
    },
  )

  const {
    isFetching: isFetchingJob,
    refetch: refetchJob,
    data: job,
  } = useQuery(
    ['getJob', jobUuid],
    () => callApi(api.getJob, currentListUuid, jobUuid!, teamSlug),
    {
      onSuccess: (data) => {
        setJobData({ ...data, list_uuids: Array.from(new Set([...listUuids, currentListUuid])) })
      },
      enabled: !!jobUuid,
      cacheTime: 0,
    },
  )

  if (isFetchingJob && jobUuid) return null

  const modalTitle = () => {
    if (memberAdding && !jobUuid) {
      return 'Submit a Job'
    }
    return jobUuid ? 'Edit Job' : 'Create a Job'
  }

  return (
    <div>
      <Modal onHide={onHide} show={true} dangerouslyBypassFocusLock>
        <Typography fontSize="12" fontWeight={600} className="mt-4 mb-2" component="p">
          {modalTitle()}
        </Typography>
        <div className="mt-7" ref={parent}>
          <JobInfo
            editing={!!jobUuid}
            onHide={onHide}
            jobData={jobData}
            setJobData={setJobData}
            updateJobMutation={jobUuid ? updateJobMutation : createJobMutation}
            deleteJobMutation={deleteJobMutation}
            isDeleting={isDeleting}
            teamSlug={teamSlug}
            company={company}
            showListDropdown={!!showListDropdown}
            memberAdding={!!memberAdding}
          />
        </div>
      </Modal>
    </div>
  )
}

export default JobsEditOrAddModal
