import * as React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from './Typography'

export const Container = styled.div`
  ${tw`py-10 px-4 text-center mx-auto rounded-lg `}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  color: ${({ theme }) => theme.colors.primary};
  ${({ fullWidth = false }) => (fullWidth ? `${tw`w-full`}` : `${tw`max-w-2xl`}`)};
`

const ImageContainer = styled.div`
  ${tw`flex items-center justify-center mb-4`}

  & img {
    width: 100px;
  }
`

interface Props {
  image?: React.ReactNode
  icon?: React.ReactNode
  heading?: React.ReactNode
  body?: React.ReactNode
  cta?: React.ReactNode
  className?: string
  style?: any
  fullWidth?: boolean
}

const EmptyState: React.VFC<Props> = ({
  image,
  heading,
  body,
  cta,
  className,
  icon,
  style = {},
  fullWidth = false,
}) => {
  return (
    <Container fullWidth className={className} style={style}>
      {image && <ImageContainer>{image}</ImageContainer>}
      {icon && (
        <Typography lineHeight={1} color={'fog'} fontSize="24" className="mb-4" component="div">
          {icon}
        </Typography>
      )}
      {heading && (
        <Typography lineHeight={2} fontSize="16" textAlign="center" component="h3" color="pitch">
          {heading}
        </Typography>
      )}
      {body && (
        <Typography lineHeight={1.4} fontSize="12" color="fog" component="div">
          {body}
        </Typography>
      )}

      {cta && (
        <Typography
          fontSize="14"
          fontWeight={600}
          className="mt-6"
          component="div"
          textAlign="center"
        >
          {cta}
        </Typography>
      )}
    </Container>
  )
}

export default EmptyState
