import * as React from 'react'
import Modal from 'global/Modal'
import CabalButton from 'global/CabalButton'

interface Props {
  show: boolean
  onHide: () => void
  onConfirm: () => void
  onCancel: () => void
  confirmText: string | null
}

const ConfirmDeleteModal: React.FC<Props> = ({
  show,
  onHide,
  onConfirm,
  onCancel,
  confirmText = 'Are you sure?',
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      header={confirmText || ''}
      rightActions={
        <CabalButton variant="primary" onClick={onCancel}>
          Cancel
        </CabalButton>
      }
      leftActions={<CabalButton onClick={onConfirm}>Delete</CabalButton>}
    >
      <></>
    </Modal>
  )
}

export default ConfirmDeleteModal
