import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'

import { formatCompact } from 'utils/ui'

const Pill = styled.div<{
  backgroundColor: string
  color: string
}>`
  ${tw`flex items-center justify-center rounded-full gap-1 py-1 px-1.5`}
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-size: 10px;
`

export function getFormattedStage(stageValue: string): string {
  const stagePreferenceOptions = [
    { value: 'preseed', label: 'Pre-seed' },
    { value: 'early', label: 'Early' },
    { value: 'seed', label: 'Seed' },
    { value: 'seriesa', label: 'Series A' },
    { value: 'series a', label: 'Series A' },
    { value: 'seriesb', label: 'Series B' },
    { value: 'series b', label: 'Series B' },
    { value: 'seriesc', label: 'Series C' },
    { value: 'series c', label: 'Series C' },
    { value: 'seriesdplus', label: 'Series D+' },
    { value: 'series d+', label: 'Series D+' },
  ]

  const matchingStage = stagePreferenceOptions.find((option) => option.value === stageValue)
  return matchingStage ? matchingStage.label : stageValue
}

const Tags = ({ tags }: { tags: string[] }) => {
  if (!tags || tags?.length === 0) return null
  const truncatedTag = tags[0].length > 5 ? `${tags[0].substring(0, 5)}...` : tags[0]

  return (
    <div className="flex flex-wrap gap-1">
      <Pill color="#5C69D1" backgroundColor="#DCE5FB">
        <i className="far fa-tag fa-fw fa-sm" />
        {truncatedTag}
      </Pill>
    </div>
  )
}

const Stage = ({ stage }: { stage: string }) => {
  if (!stage) return null

  const formattedStage = getFormattedStage(stage)
  return (
    <div className="flex flex-wrap gap-1">
      <Pill color="#E4307C" backgroundColor="#FBE2EC">
        <i className="far fa-arrow-up-right-dots fa-fw fa-sm" />
        {formattedStage}
      </Pill>
    </div>
  )
}

const Size = ({ size }: { size: string }) => {
  if (!size) return null
  return (
    <div className="flex flex-wrap gap-1">
      <Pill color="#53AF65" backgroundColor="#E8F5EB">
        <i className="far fa-users fa-fw fa-sm" />
        {formatCompact(size)}
        {+size > 1000 && '+'}
      </Pill>
    </div>
  )
}

const Count = ({ tags }: { tags?: string[] }) => {
  const count = (tags?.length || 1) - 1
  if (count <= 0) return null

  const tooltipLabel = (
    <div className="flex flex-wrap gap-1">
      {tags?.length &&
        tags?.length > 1 &&
        tags.slice(1).map((tag, index) => <Tags tags={[tag]} key={index} />)}
    </div>
  )

  return (
    <Tooltip label={tooltipLabel}>
      <Typography fontSize="12" color="rain">
        +{count}
      </Typography>
    </Tooltip>
  )
}

const PortfolioDataTags = ({ company }) => {
  const { tags, stage, stages, employee_count } = company

  return (
    <div>
      <div className="flex flex-wrap items-center gap-1">
        <Tags tags={tags} />
        <Stage stage={stage ?? stages} />
        <Size size={employee_count} />
        <Count tags={tags} />
      </div>
    </div>
  )
}

export default PortfolioDataTags
