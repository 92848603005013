import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

const ModalSectionWrapper = styled.div`
  ${tw`py-3 space-y-5`}
`

const ModalSectionHeader = styled.div`
  ${tw`flex items-center justify-between font-semibold gap-2`}
  height: 24px;
  font-size: 12px;
`

const ModalInputLabel = styled(Typography)`
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  min-width: 150px;
  padding-right: 8px;
`

const ModalInputWrapper = styled.div`
  ${tw` flex-col sm:flex-row`}
  @media (min-width: 640px) {
    display: grid;
    max-width: 100%;
    grid-template-columns: 1.5fr minmax(300px, 3fr);
  }
`

const ModalDivider = styled.div`
  ${tw`-mx-6`}
  border-bottom: ${({ theme }) => theme.border};
`

export { ModalSectionWrapper, ModalSectionHeader, ModalInputLabel, ModalInputWrapper, ModalDivider }
