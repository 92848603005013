import React, { useEffect } from 'react'

import Avatar from 'global/Avatar'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'

interface Props {
  teamSlug: string
  isLoggedIn?: boolean
  totalConnections?: number
  onBack?: () => void
}

const Header: React.FC<Props> = ({ teamSlug, isLoggedIn = true, totalConnections, onBack }) => {
  const { team, isLoading } = useTeam(teamSlug)
  const [sharedConn, setSharedConn] = React.useState(false)

  useEffect(() => {
    if (totalConnections && totalConnections > 0) {
      setSharedConn(true)
    }
  }, [])

  if (isLoading) return <Loading />

  return (
    <div className="mx-auto flex flex-col items-center relative">
      {onBack && (
        <Typography
          color="fog"
          onClick={onBack}
          className="absolute top-[30px] left-6 cursor-pointer"
        >
          <i className="far fa-chevron-left mr-2" />
        </Typography>
      )}
      {team && (
        <div className="mt-3 mb-4">
          <Avatar size="48" src={team?.logo} name={team.name} />
        </div>
      )}

      {!team && (
        <Typography color="fog" fontSize="24" className="mt-2" fontWeight={600} lineHeight={1}>
          <div className="text-center rounded-full bg-[#A7B4C7] p-2 w-12 h-12 flex items-center justify-center">
            <i className="fa-brands fa-linkedin text-white" />
          </div>
        </Typography>
      )}

      {team && (
        <Typography
          color={isLoggedIn ? 'black' : 'white'}
          fontSize="20"
          className="mt-2"
          fontWeight={600}
          lineHeight={1}
        >
          <div className="mt-2">Share your connections</div>
        </Typography>
      )}

      {!team && (
        <Typography
          color={isLoggedIn ? 'black' : 'white'}
          fontSize="20"
          className="mt-2"
          fontWeight={600}
          lineHeight={1}
        >
          <div className="mt-2">Add your connections</div>
        </Typography>
      )}

      {isLoggedIn && (
        <Typography fontSize="12" className="mt-2" color="fog">
          {team && sharedConn && <>Securely share your connections with {team?.name}</>}
          {team && !sharedConn && <>Securely share your network with {team?.name}</>}
          {!team && <>Securely map and manage your network</>}
        </Typography>
      )}
    </div>
  )
}

export default Header
