import React, { useState } from 'react'

import { useQuery } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import OldLoading from 'containers/ListView/Companies/OldLoading'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { CompanyListBlueprint } from 'utils/types'

const StyledStatusCard = styled.div`
  ${tw`flex items-center justify-start px-1 py-1 w-full truncate`}
  height: 35px;
  align-items: center;
  border-radius: 6px;
  border: 0.5px solid #53af65;
  color: #53af65;
`

const NameSpan = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`

const Truncate = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`

interface StatusCardsProps {
  showItemsIsSyncing: boolean
  teamSlug: string
  refetch?: () => void
  listSlug?: string | null
  allCompaniesOrAllConnections: boolean
  companyList?: CompanyListBlueprint
}

interface StatusCardProps {
  title: string | React.ReactNode
  className?: string
}

const StatusCard: React.FC<StatusCardProps> = ({ title, className }) => {
  return (
    <StyledStatusCard className={className}>
      <OldLoading size={14} color={'#53af65'} className="ml-1" />
      <Truncate fontSize="14" className="mx-1">
        {title}
      </Truncate>
    </StyledStatusCard>
  )
}

const StatusCards: React.FC<StatusCardsProps> = ({
  showItemsIsSyncing,
  teamSlug,
  refetch,
  allCompaniesOrAllConnections,
  listSlug,
}) => {
  const [sharesStillMatching, setSharesStillMatching] = useState<
    Array<{ id: string; name: string; affected_list_slugs: Array<string> }>
  >([])
  const [connectionsImporting, setConnectionsImporting] = useState<
    Array<{ name: string; email: string }>
  >([])

  useQuery(['sharesStillMatching', teamSlug], () => callApi(api.sharesStillMatching, teamSlug), {
    refetchInterval: 3000,
    onSuccess: (data) => {
      if (data.length < sharesStillMatching.length) {
        refetch?.()
      }
      setSharesStillMatching(data)
    },
  })

  useQuery(['connectionsImporting', teamSlug], () => callApi(api.connectionImports, teamSlug), {
    refetchInterval: 3000,
    onSuccess: (data) => {
      if (data.length < connectionsImporting.length) {
        refetch?.()
      }
      setConnectionsImporting(data)
    },
  })

  const relevantMatchProcessingAdvisors =
    sharesStillMatching?.filter((advisor) => {
      return advisor.affected_list_slugs.includes(listSlug) || allCompaniesOrAllConnections
    }) || []

  const namesToDisplay = 2
  const nameString = relevantMatchProcessingAdvisors
    .slice(0, namesToDisplay)
    .map((a) => a.name)
    .join(', ')

  const completeShareTitle =
    (relevantMatchProcessingAdvisors.length > namesToDisplay
      ? `${nameString} and ${relevantMatchProcessingAdvisors.length - namesToDisplay} others`
      : nameString.replace(/, ([^,]*)$/, ' and $1')) + ' shared their connections.'

  const connectionNames = connectionsImporting
    .slice(0, namesToDisplay)
    .map((u) => u.name)
    .join(', ')

  const completeConnectionImportTitle =
    (connectionsImporting.length > namesToDisplay
      ? `${connectionNames} and ${connectionsImporting.length - namesToDisplay} others`
      : connectionNames.replace(/, ([^,]*)$/, ' and $1')) + ' imported connections.'

  return (
    <>
      {showItemsIsSyncing && <StatusCard title={'List items are syncing...'} />}
      {relevantMatchProcessingAdvisors.length > 0 && (
        <StatusCard
          className={showItemsIsSyncing ? 'mt-1' : ''}
          title={
            <div>
              <NameSpan>{completeShareTitle}</NameSpan> Sync in progress...
            </div>
          }
        />
      )}
      {connectionsImporting.length > 0 && (
        <StatusCard
          className={showItemsIsSyncing || relevantMatchProcessingAdvisors.length > 0 ? 'mt-1' : ''}
          title={
            <div>
              <NameSpan>{completeConnectionImportTitle}</NameSpan> Sync in progress...
            </div>
          }
        />
      )}
    </>
  )
}

export default StatusCards
