import React from 'react'

import { Field, FieldProps, Formik } from 'formik'

import { SelectWrapper } from 'containers/CandidateProfileModal/PersonalInfo'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { MultiSelectField, TextArea, TextInputField } from 'global/Input'
import CityInput from 'global/Input/CityInput'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'

interface Props {
  onHide: () => void
  teamSlug: string
  refetchPortfolioList: () => void
  listUuid: string
  investor_company_uuids?: string
  name?: string
}

const ResourceCompanyAddModal = ({
  onHide,
  teamSlug,
  refetchPortfolioList,
  listUuid,
  investor_company_uuids,
  name,
}: Props) => {
  const [companyData, setCompanyData] = React.useState({
    company_name: name || '',
    domain: '',
    tags: [],
    logo_url: '',
  })

  return (
    <Modal onHide={onHide} show={true} header="Add company">
      <Formik
        initialValues={companyData ?? {}}
        onSubmit={(values) => {
          console.log('submit values :: ', values)
        }}
      >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
              }
            }}
          >
            <ModalSectionWrapper>
              {(companyData?.domain || companyData?.logo_url) && (
                <ModalInputWrapper className="">
                  <ModalInputLabel>Photo</ModalInputLabel>

                  <Avatar
                    src={
                      companyData.domain
                        ? `https://logo.clearbit.com/${companyData.domain}`
                        : companyData.logo_url
                    }
                    size="96"
                  />
                </ModalInputWrapper>
              )}

              <ModalInputWrapper className="">
                <ModalInputLabel>Name</ModalInputLabel>

                <Field name="company_name">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Company name"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="company-name"
                      autoFocus={props.field.value ? false : true}
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>Website</ModalInputLabel>

                <Field name="domain">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Enter website"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="website"
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <Field name="description">
                  {(props: FieldProps) => (
                    <>
                      <ModalInputLabel>Description</ModalInputLabel>
                      <TextArea
                        {...props.field}
                        className="w-full"
                        placeholder="Description"
                        data-testid="description"
                        // onChange={(e) => {
                        //   props.form.setFieldValue('description', e.target.value)
                        //   setCompanyData({ ...companyData, description: e.target.value })
                        // }}
                      />
                    </>
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper>
                <Field name="employee_count">
                  {(props: FieldProps) => (
                    <>
                      <ModalInputLabel>Employees</ModalInputLabel>
                      <TextInputField
                        {...props.field}
                        className="w-full"
                        placeholder="Employee count"
                        data-testid="employees"
                        error={props.meta.touched ? props.meta.error : undefined}
                      />
                    </>
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>Location</ModalInputLabel>
                <Field name="location">
                  {(props: FieldProps) => (
                    <SelectWrapper>
                      <CityInput
                        {...props.field}
                        onBlur={() => {
                          props.form.setFieldTouched(props.field.name, true)
                        }}
                        onChange={(opt) => {
                          props.form.setFieldValue(props.field.name, opt?.value)
                          setCompanyData({ ...companyData, location: opt?.value })
                        }}
                        value={
                          props.field.value
                            ? { value: props.field.value, label: props.field.value }
                            : null
                        }
                        error={props.meta.touched ? props.meta.error : undefined}
                      />
                    </SelectWrapper>
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Tags</div>
                </ModalInputLabel>
                <Field name="tags">
                  {(props: FieldProps) => (
                    <MultiSelectField
                      placeholder="Tags"
                      creatable
                      fieldProps={props}
                      options={[]}
                      onCreateOption={(value) => {
                        setCompanyData({ ...companyData, tags: [...companyData?.tags, value] })
                      }}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper>
                <ModalInputLabel>
                  <div className="flex flex-col">
                    <span className="block">X Profile URL</span>
                    <CabalButton
                      variant="link"
                      padding="4px 0 0 0"
                      className="self-start"
                      onClick={(e) => {
                        e.stopPropagation()
                        window.open('https://x.com', '_blank', 'noopener noreferrer')
                      }}
                    >
                      Get link
                    </CabalButton>
                  </div>
                </ModalInputLabel>
                <Field name="data.twitter_url">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="X.com URL"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="twitter-url"
                    />
                  )}
                </Field>
              </ModalInputWrapper>
            </ModalSectionWrapper>

            <div className="flex justify-end items-center mt-7 mb-6">
              <CabalButton type="submit">Save</CabalButton>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default ResourceCompanyAddModal
