import React, { useEffect, useMemo } from 'react'

import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import { padding } from 'polished'
import { useInfiniteQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { ArrayParam, BooleanParam, StringParam } from 'use-query-params'

import ActivityCharts from 'components/ActivityCharts'
import { Filter, FilterWrapper, FiltersValueType, renderFilters } from 'components/Filters'
import LikesTracker from 'components/LikesTracker'
import OpenOfferModal from 'components/OpenOfferModal'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import Loading from 'global/Loading'
import { RenderModal } from 'global/Modal/Context'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useAdvisors, useCurrentUserSettings, useTeam } from 'store/hooks'
import SkeletonDataCard from 'ui-components/SkeletonDataCard'

import api, { callApi } from 'utils/api'
import useCurrentUserSetting from 'utils/hooks/useCurrentUserSetting'
import { useQueryParamsWithSettings } from 'utils/hooks/useQueryParamsWithSettings'
import useTeamSetting from 'utils/hooks/useTeamSetting'
import { Activity, CompanySlugParam } from 'utils/types'

import ActivityRow from './ActivityRow'

const FilterSection = styled.div`
  ${tw`flex justify-between items-center py-3 sticky top-0`}
  background-color: ${({ theme }) => theme.layout.nav_bg_color}
`

// type Time = 'last_week' | 'last_month' | 'last_quarter' | 'last_year'

const ActivityView = () => {
  const { company_slug: companySlug } = useParams<CompanySlugParam>()
  const { settings } = useCurrentUserSettings()
  const { advisors, isAdvisorLoaded } = useAdvisors({ teamSlug: companySlug })
  const { isAdmin, isAdminOrEmployee } = useAccessControl(companySlug)
  const { team } = useTeam(companySlug)
  const location = useLocation()
  const history = useHistory()
  const isOnHome = location.pathname.includes('/home') || location.pathname === `/${companySlug}`

  useEffect(() => {
    const overflowWrapper = document.getElementById('main-overflow-wrapper')
    if (!isOnHome && overflowWrapper) {
      overflowWrapper.scrollTo({
        top: 0,
        behavior: 'instant',
      })
    }
  }, [isOnHome])

  const [enablePortfolioJobs] = useTeamSetting(companySlug, 'enable_portfolio_jobs')
  const {
    activity_empty_viewed_asks,
    activity_empty_viewed_updates,
    activity_empty_viewed_members,
    activity_empty_viewed_advisors,
    activity_empty_viewed_portfolio,
    activity_empty_viewed_resources,
    activity_empty_skip,
  } = settings

  const hideScreenActivity =
    activity_empty_skip ||
    (activity_empty_viewed_asks &&
      activity_empty_viewed_updates &&
      activity_empty_viewed_members &&
      activity_empty_viewed_advisors) ||
    (activity_empty_viewed_resources &&
      activity_empty_viewed_portfolio &&
      activity_empty_viewed_members &&
      activity_empty_viewed_updates)

  const queryParamConfigMap = useMemo(() => {
    return {
      type: ArrayParam,
      user: ArrayParam,
      time: StringParam,
      company: StringParam,
      kanban: StringParam,
    }
  }, [])
  const [filters, setFilters] = useQueryParamsWithSettings(
    queryParamConfigMap,
    'activity_filters',
    companySlug,
    {},
  )
  const filterSelected = useMemo(() => !every(filters, isEmpty), [filters])

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery(
      ['getMemberActivity', companySlug, filters],
      ({ pageParam: page = 1 }) =>
        callApi(api.getMemberActivity, companySlug, 'team_activities', undefined, filters, page),
      {
        keepPreviousData: true,
        getNextPageParam: (lastPage) => lastPage.pagination.next_page,
        getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      },
    )

  const activities = data?.pages?.flatMap((page) => page.activity) ?? []

  const showNewScreenTop = !isLoading && isAdmin && !hideScreenActivity
  const showNewScreenBottom = false

  const activityTypes = [
    { label: 'Sales', value: 'sales' },
    { label: 'Contributions', value: 'contributions' },
    { label: 'Messaging', value: 'messaging' },
    { label: 'Resources', value: 'resources' },
    { label: 'Members', value: 'members' },
    { label: 'Agreements', value: 'agreements' },
  ]

  if (team?.enable_portfolio) {
    activityTypes.push({ label: 'Portfolio', value: 'portfolio' })
  }

  if ((team?.enable_portfolio && enablePortfolioJobs) || team?.has_talent_lists) {
    activityTypes.push({ label: 'Talent', value: 'talent' })
  }

  const renderFilter: Filter[] = [
    {
      key: 'type',
      type: 'dropdown[]',
      label: 'Type',
      options: activityTypes,
    },
  ]

  if (isAdminOrEmployee) {
    renderFilter.push({
      key: 'user',
      type: 'dropdown[]',
      label: 'Member',
      options:
        advisors?.map((a) => ({
          label: (
            <div style={{ width: 400, overflowY: 'hidden' }}>
              {a.name} ({a.email})
            </div>
          ),
          labelStr: a.name,
          value: a.user_uuid,
          toString: () => a.name + a.email,
        })) || [],
      loadingOptions: !isAdvisorLoaded,
    })
  }

  if (team?.enable_asks) {
    renderFilter.push({
      key: 'company',
      type: 'string',
      label: 'Company',
    })
  }

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: renderFilter,
    value: filters as FiltersValueType,
    onChange: (f) => setFilters(f),
  })

  if (filters.kanban === 'true') {
    return <LikesTracker />
  }

  const filterSection = (
    <FilterSection>
      <FilterWrapper>
        <div>{renderedFilters}</div>
        {!!appliedFilters.length && <div>{appliedFilters}</div>}
      </FilterWrapper>
    </FilterSection>
  )

  const contents =
    activities && (activities.length > 0 || filterSelected) && !isLoading ? (
      <div className="space-y-1">
        {filterSection}

        {activities.length > 0 ? (
          activities.map((activity: Activity) => (
            <div key={activity.uuid}>
              <ActivityRow key={activity.uuid} activity={activity} refetchActivities={refetch} />
            </div>
          ))
        ) : (
          <EmptyState heading={'No activity'} />
        )}
      </div>
    ) : activities.length === 0 &&
      !isLoading &&
      !filterSelected &&
      !(showNewScreenTop || showNewScreenBottom) ? (
      <>
        <EmptyState heading={'No activity'} />
      </>
    ) : null

  return (
    <PageWrapper title="Activity">
      {!isOnHome && (
        <PageHeader
          data={{ title: 'Activity' }}
          onBack={() => history.push(`/${companySlug}`)}
          actions={{
            comments: false,
            share: false,
          }}
        />
      )}
      {/* {showNewScreenTop && <NewScreenActivity />} */}
      {isLoading && (
        <div className='className="space-y-1"'>
          {filterSection}
          <SkeletonDataCard hideBorder={true} view="list" itemCount={16} />
        </div>
      )}
      {!isLoading && !isOnHome && <ActivityCharts />}
      {contents}
      {hasNextPage && (
        <div className="w-full flex justify-center">
          <CabalButton
            variant="secondary"
            className="my-3"
            disabled={isFetchingNextPage}
            onClick={() => fetchNextPage()}
            data-testid="portfolio-activity-load-more-btn"
          >
            Load more
          </CabalButton>
        </div>
      )}
      {/* {showNewScreenBottom && <NewScreenActivity />} */}
    </PageWrapper>
  )
}

export default ActivityView
