import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Consumer } from '@rails/actioncable'

import { AxiosError } from 'axios'
import cx from 'classnames'
import { History } from 'history'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import throttle from 'lodash/throttle'
import uniq from 'lodash/uniq'
import moment from 'moment'
import { QueryObserverResult, useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router'
import { createGlobalState } from 'react-use'
import { AsyncReturnType } from 'type-fest'

import Attachments from 'components/Attachments'
import FocusBannerEmailAuth from 'components/Composer/Sidebar/FocusBannerEmailAuth'
import { AutoSaver } from 'components/ComposerV2/Context/AutoSaver'
import TimeAgo from 'components/TimeAgo'
import EditGroupsModal from 'containers/Groups/EditGroupsModal'
import { ActionCableContext } from 'context/ActionCableContext'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { OnUpload, TextInput, UploadButton } from 'global/Input'
import { RenderModal, ShowModal, useModal } from 'global/Modal/Context'
import CKEditor from 'global/TextEditor/ckeditor'
import { ClassicCKEditor } from 'global/TextEditor/ckeditor/base'
import Typography from 'global/Typography'
import {
  useAdvisors,
  useCurrentUser,
  useCurrentUserGmailAuthorizedEmails,
  useCurrentUserSettings,
  useGroups,
  useTeam,
} from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import ErrorLogger from 'utils/ErrorLogger'
import { HttpStatusCode } from 'utils/HttpStatusCode'
import api, { callApi } from 'utils/api'
import group from 'utils/api/group'
import { htmlTextContent } from 'utils/html'
import { advisorMessageRecipientToEmailMessageRecipient } from 'utils/messages'
import {
  AdvisorModel,
  CurrentUserProfile,
  CurrentUserSettings,
  EmailSnippet,
  EmailTemplate,
  MessageModel,
  MessageRecipient,
  PresendCheckReponse,
  RequestModel,
  SenderModel,
  Team,
  UploadBlueprint,
  UserPermissions,
  VideoType,
} from 'utils/types'
// import team from 'utils/api/team'
import { getVariableFieldsUsed } from 'utils/variables'

import { SendMessageProps } from '.'
import ShareDraft from '../../containers/ShareDraft'
// import { MemoizedDraftOnBehalf as DraftOnBehalf } from './DraftOnBehalf'
import Banners from './Banners'
import ButtonsModal from './ButtonsModal'
import ButtonsPreview from './ButtonsPreview'
import EmailSnippets from './EmailSnippets'
import GoogleAuthModal from './GoogleAuthModal'
import MapEmailModal from './MapEmailModal'
import MicrosoftAuthModal from './MicrosoftAuthModal'
import PresendCheckFailureModal from './PresendCheckFailureModal'
import { MemoizedRecipientsField as RecipientsField } from './RecipientsField'
import ScheduleSend, { SCHEDULE_DATE_FORMAT } from './ScheduleSend'
import SenderField, { SenderFieldAction } from './SenderField'
import TemplateDropdown from './TemplateDropdown'
import { CcButton } from './styles'
import { getInitialBody, getInitialSubject, replaceVariables } from './utils'

export const useGlobalComposerMessage = createGlobalState<MessageState | undefined>(undefined)

interface Props extends SendMessageProps {
  user: CurrentUserProfile
  team?: Team
  cable: Consumer
  advisors: AdvisorModel[]
  reloadAdvisors: () => void
  reloadGroups: () => void
  reloadUser: ReturnType<typeof useCurrentUser>['reloadUser']
  reloadSenders: () => Promise<QueryObserverResult<SenderModel[], unknown>>
  onCancelSend: () => Promise<QueryObserverResult<void, unknown>>
  onNotifySender: (props: {
    sender: SenderModel
    note: string
  }) => Promise<QueryObserverResult<void, unknown>>
  onReloadSnippets: () => Promise<any> // TODO type this...
  settings: CurrentUserSettings
  permissions: UserPermissions
  showModal: ShowModal
  history: History
  senders: SenderModel[]
  templates: EmailTemplate[]
  emailSnippets: EmailSnippet[]
  ckEditorRef: React.MutableRefObject<ClassicCKEditor | undefined>
  setGlobalComposerMessage: (m: MessageState) => void
  showCreateGroupButton: boolean
  hideGroupButton: () => void
}

type MessageState = Pick<
  MessageModel,
  | 'subject'
  | 'body'
  | 'attachments'
  | 'buttons'
  | 'cc'
  | 'recipients'
  | 'video'
  | 'from'
  | 'ready'
  | 'request_uuid'
  | 'schedule_at'
  | 'notified_sender_uuids'
> & {
  uuid?: string
  sender?: SenderModel
  request?: RequestModel
}

interface State {
  message: MessageState
  workingState: 'loading' | 'uploading' | 'sending' | 'sharing' | 'drafting' | 'testing' | ''
  showCcField: boolean
  skippedReauth: boolean
  autosaveError: boolean
  lastDraftSave?: Date
  newScheduleAt: string | null
}

class SendMessageClassBase extends React.Component<Props, State> {
  static defaultProps: Partial<SendMessageProps> = {
    allowCc: false,
    allowButtons: false,
    allowVideo: false,
    allowTemplates: false,
    allowSnippets: false,
    submitText: 'Send',
    allowSaveAsDraft: false,
    allowGroups: true,
    hideToField: false,
    submitWorking: false,
    ignoreRecipients: false,
    fromRequest: false,
    draftSaves: true,
  }

  testing = false
  submitting = false
  autoSaver: AutoSaver<'updateMessage'>
  throttledSendMessageUpdate: () => void

  constructor(props: Props) {
    super(props)

    this.state = {
      message: {
        cc: props.defaultCc || [],
        subject: getInitialSubject(
          props.defaultSubject,
          props.defaultTemplate,
          props.templateVariables,
        ),
        attachments: [],
        recipients: props.defaultRecipients || [],
        buttons: props.defaultButtons || [],
        body: getInitialBody(
          props.defaultBody,
          props.defaultTemplate,
          props.templateVariables,
          props.user.email_signature,
        ),
        video: null,
        schedule_at: null,
        notified_sender_uuids: [],
      },
      workingState: '',
      showCcField: false,
      skippedReauth: false,
      autosaveError: false,
      newScheduleAt: null,
    }

    this.props.setGlobalComposerMessage(this.state.message)

    this.autoSaver = new AutoSaver({
      teamSlug: props.companySlug,
      objectId: props.message_uuid!,
      userUuid: props.user.uuid,
      setObject: (update) =>
        this.setState({ message: { ...this.state.message, ...omit(update, 'body') } }),
      apiName: 'updateMessage',
      onSave: () => this.setState({ lastDraftSave: new Date(), autosaveError: false }),
    })

    this.throttledSendMessageUpdate = throttle(this.sendMessageUpdate, 1000)
  }

  sendMessageUpdate = () => {
    this.autoSaver.sendChange(this.getMessageParams(true))
  }

  setMessageState = (m: Partial<MessageModel>, callback?: () => void) => {
    this.setState(
      {
        message: {
          ...this.state.message,
          ...m,
        },
      },
      () => {
        this.props.setGlobalComposerMessage(this.state.message)
        this.throttledSendMessageUpdate()
        callback?.()
      },
    )
  }

  getSender = () => {
    const senders = this.props.senders
    const { from, sender } = this.state.message

    let matchedSender
    if (!!from && !!sender) {
      matchedSender = senders.find((s) => {
        return s.uuid === sender.uuid && (s.email === from || s.alias_email === from)
      })
    }

    return matchedSender
  }

  getMessageParams = (saveAsDraft = false) => {
    const {
      message: {
        body,
        subject,
        attachments,
        buttons,
        recipients,
        cc,
        from,
        sender: senderState,
        video,
        request_uuid,
        uuid,
        schedule_at,
        notified_sender_uuids,
      },
    } = this.state

    const { senders, user } = this.props

    const wrapperElement = document.createElement('div')
    wrapperElement.classList.add('rich-text')
    wrapperElement.innerHTML = body!

    // 1. retrieve email
    // 2. message.sender's email
    // 3. default to the first sender (getSenderUsers)

    const fromEmail = from || senders[0].email
    const sender = senderState || senders[0]

    return {
      message_uuid: uuid,
      body: saveAsDraft ? body : wrapperElement.outerHTML,
      subject,
      attachments,
      connection_uuid: this.props.connection?.uuid,
      advisor_uuid: this.props.advisor?.uuid,
      send_email: true,
      buttons,
      from: fromEmail,
      sender: sender,
      sender_uuid: sender?.uuid,
      recipients,
      cc,
      draft: this.isScheduledInFuture() ? true : saveAsDraft,
      request_uuid: request_uuid,
      draft_uuid: uuid,
      schedule_at: this.isScheduledInFuture() ? schedule_at : null,
      current_collaborator_uuid: user.uuid,
      notified_sender_uuids: notified_sender_uuids || [],
    }
  }

  onAttachmentUpload: OnUpload = (uuid, url, file) => {
    this.setMessageState({
      attachments: [
        ...(this.state.message.attachments || []),
        {
          uuid,
          upload_uuid: uuid,
          api_url: url,
          file_name: file.name,
          file_size: file.size,
          file_type: file.type,
        },
      ],
    })
  }

  onAttachmentDelete = (attachment: UploadBlueprint) => {
    this.setMessageState({
      attachments: this.state.message.attachments!.filter(
        (a) => a.upload_uuid != attachment.upload_uuid,
      ),
    })
  }

  onSelectTemplate = (template: EmailTemplate) => {
    let body = template.body
    let subject = template.subject
    const request = this.state.message.request

    body = replaceVariables(body, { request, user: this.props.user })
    subject = replaceVariables(subject, { request, user: this.props.user })

    let to = this.state.message.recipients
    if (!to?.length && template.to) {
      to = template.to
    }
    let cc = this.state.message.cc
    if (!cc?.length && template.cc) {
      cc = template.cc
    }

    if (!!cc?.length) this.setState({ showCcField: true })
    this.setMessageState({ subject, body, recipients: to, cc: cc })
  }

  onSenderOrRecipientsChange = (
    props: {
      sender?: SenderModel
      recipients?: MessageRecipient[]
      cc?: MessageRecipient[]
      callback?: (sender: SenderModel, recipients: MessageRecipient[], cc: MessageRecipient[]) => {}
    } = {},
  ) => {
    const { recipients: stateRecipients, cc: stateCc } = this.state.message
    const { advisors, user, team } = this.props

    const sender = props.sender || this.getSender()
    let recipients = props.recipients || stateRecipients || []
    let cc = props.cc || stateCc || []

    if (!sender || !team) return

    if (sender.type !== 'admin') {
      const currentUserType = user.team && user.team.slug === team.slug ? user.team_role : 'advisor'
      let canSeeSenderEmail = true

      if (sender.type === 'advisor' && currentUserType !== 'admin') {
        canSeeSenderEmail = false
      }

      let templateSender = ''
      let templateDont = ''
      let templateObjects = ''
      let templateExtra = 'Press OK to remove the recipients'

      const errorMessage = () =>
        `⚠️ ${templateSender} ${templateDont} have permission to send messages to ${templateObjects}. \n\n${templateExtra}`

      if (user.uuid === sender.uuid) {
        templateSender = 'You'
        templateDont = "don't"
      } else {
        templateSender = `${sender.name}${canSeeSenderEmail ? ` (${sender.email})` : ''}`
        templateDont = "doesn't"
      }

      if (currentUserType === 'admin') {
        if ([...recipients, ...cc].some((r) => r.type === 'group')) {
          templateObjects = 'groups'
          if (!confirm(errorMessage())) return
        }

        recipients = recipients
          .filter((r) => r.type !== 'group')
          .map((r) =>
            r.type === 'advisor' ? advisorMessageRecipientToEmailMessageRecipient(r, advisors) : r,
          )
        cc = cc
          .filter((r) => r.type !== 'group')
          .map((r) =>
            r.type === 'advisor' ? advisorMessageRecipientToEmailMessageRecipient(r, advisors) : r,
          )
      } else {
        if ([...recipients, ...cc].some((r) => r.type === 'group' || r.type === 'advisor')) {
          if (currentUserType === 'member') {
            templateExtra =
              'Please select an admin in the From field or ask an admin to add these recipients. ' +
              templateExtra
          }
          templateObjects = 'groups and members'
          if (!confirm(errorMessage())) return
        }

        recipients = recipients.filter((r) => r.type !== 'group' && r.type !== 'advisor')
        cc = cc.filter((r) => r.type !== 'group' && r.type !== 'advisor')
      }
    }

    const email = sender.use_alias ? sender.alias_email : sender.email

    this.setMessageState({
      sender,
      from: email,
      recipients,
      cc,
    })

    props.callback?.(sender, recipients, cc)
  }

  onSenderSelect = async (newSender?: SenderModel | SenderFieldAction) => {
    if (newSender === SenderFieldAction.AddGmailEmail) {
      this.props.showModal(this.renderGmailAuthModal, 'gmail-auth-modal')
    } else if (newSender === SenderFieldAction.AddMicrosoftEmail) {
      this.props.showModal(this.renderMicrosoftAuthModal, 'microsoft-auth-modal')
    } else if (newSender) {
      this.onSenderOrRecipientsChange({ sender: newSender })
    }
  }

  handleCancelScheduleSend = () => {
    this.props.onCancelSend().then(() => {
      this.setMessageState({ schedule_at: null }, () => {
        this.setState({ newScheduleAt: null })
        this.props.onSaveDraft?.()
      })
    })
  }

  isSenderNotified = (sender: SenderModel) => {
    return this.state.message.notified_sender_uuids?.includes(sender.uuid)
  }

  handleNotifySender = (sender: SenderModel) => {
    if (this.isSenderNotified(sender)) return

    const notifiedUserUuids = (this.state.message.notified_sender_uuids || []).concat(sender.uuid)

    this.setState({ workingState: 'sharing' })

    this.props.showModal(
      (resolve) => this.renderShareDraftModal(resolve, sender, notifiedUserUuids),
      'render_share_draft_modal',
    )
  }

  handleSelectSnippet = (snippet: EmailSnippet) => {
    let body = snippet.body
    const request = this.state.message.request

    const editor = this.props.ckEditorRef?.current

    if (!editor) {
      const error = 'unable to set snippet because ckEditorRef is not defined'
      ErrorLogger.error(error)
      console.error(error)
      return
    }

    if (request) {
      body = replaceVariables(body, { request, user: this.props.user })
    }

    // TODO maybe extract this to ckeditor

    const selection = editor.model.document.selection
    const firstPos = selection.getFirstPosition()
    const lastPos = selection.getLastPosition()

    if (!firstPos) {
      ErrorLogger.error('Snippet insert position invalid!')
      cabalToast({ style: 'error', content: 'Error occurred! We have been notified' })
      return
    }

    if (lastPos && firstPos.root === lastPos.root && !isEqual(firstPos.path, lastPos.path)) {
      editor?.execute('delete')
    }

    if (snippet.needsParaWrap) {
      body = `<p>${body}</p>`
    }

    const viewFragment = editor.data.processor.toView(body)
    if (viewFragment) {
      const modelFragment = editor.data.toModel(viewFragment as any)
      editor.model.insertContent(modelFragment, firstPos)
    }
  }

  setRecipientsField = (v: MessageRecipient[] | null) => {
    this.onSenderOrRecipientsChange({ recipients: v || [] })
  }

  setCcField = (v: MessageRecipient[] | null) => {
    this.onSenderOrRecipientsChange({ cc: v || [] })
  }

  setBody = (body: string) => {
    // prevents firing up multiple time after the component is mounted
    if (this.state.message.body != body) {
      this.setMessageState({ body })
    }
  }

  resetWorkingState = () => {
    this.setState({ workingState: '' })
  }

  renderGroupsModal: RenderModal = (resolve) => {
    return (
      <EditGroupsModal
        onHide={() => resolve(false)}
        onSave={() => {
          this.props.reloadGroups()
        }}
        onDelete={() => {
          this.props.reloadGroups()
          resolve(false)
        }}
        teamSlug={this.props.companySlug}
      />
    )
  }

  renderShareDraftModal: (
    resolve: () => void,
    sender: SenderModel,
    notifiedUserUuids: string[],
  ) => React.ReactNode = (resolve, sender, notifiedUserUuids) => (
    <ShareDraft
      onSubmit={(note) => {
        this.props
          .onNotifySender({ sender, note })
          .then(() => {
            this.setMessageState({ notified_sender_uuids: notifiedUserUuids }, () => {
              cabalToast({ style: 'success', content: 'Shared with sender!' })
              this.props.onClose?.()
            })
          })
          .finally(() => this.resetWorkingState())
      }}
      to={this.state.message.recipients}
      subject={this.state.message.subject}
      uuid={this.state.message.uuid}
      teamSlug={this.props.companySlug}
      sender={sender}
      onHide={() => {
        resolve()
        this.setState({ workingState: 'drafting' })
      }}
    />
  )

  renderMapEmailModal: RenderModal = (resolve) => {
    const variableFieldsUsed = uniq(
      getVariableFieldsUsed(this.state.message.body).concat(
        getVariableFieldsUsed(this.state.message.subject),
      ),
    )

    return (
      <MapEmailModal
        teamSlug={this.props.companySlug}
        recipients={this.state.message.recipients!}
        variableFieldsUsed={variableFieldsUsed}
        onDone={(r) => {
          this.setMessageState({
            recipients: r,
          })
          setTimeout(() => resolve(true), 100)
        }}
        show
        onHide={() => {
          resolve(false)
          this.resetWorkingState()
        }}
      />
    )
  }

  renderSubmitButton = (
    incompleteForm: boolean,
    isOwner: boolean,
    workingState: State['workingState'],
    submitWorking: boolean,
    iconOnlyButton: boolean,
  ) => {
    const sender = this.getSender()

    const subject = this.state.message.subject
    const recipients = this.state.message.recipients || []

    let tooltipText = ''

    if (incompleteForm && !subject && recipients.length === 0) {
      tooltipText = 'Add subject and recipient to send'
    } else if (incompleteForm && !subject) {
      tooltipText = 'Add subject to send'
    } else if (recipients.length === 0) {
      tooltipText = 'Add recipient to send'
    }

    let submitText = this.props.submitText

    const isScheduledSendEnabled = this.isScheduledSendEnabled() ?? false
    const newScheduleAt = this.state.newScheduleAt
    const scheduleAt = this.state.message.schedule_at

    // TODO: Extract schedule send button to a separate component...
    let isScheduledSendButtonDisabled = false

    if (isScheduledSendEnabled) {
      if (!scheduleAt && newScheduleAt) {
        submitText = `Schedule for ${moment(newScheduleAt).format(SCHEDULE_DATE_FORMAT)}`
        isScheduledSendButtonDisabled = false
      } else if (scheduleAt && !newScheduleAt) {
        submitText = 'Scheduled'
        isScheduledSendButtonDisabled = true
      } else if (scheduleAt && newScheduleAt && moment(scheduleAt).isSame(moment(newScheduleAt))) {
        submitText = 'Scheduled'
        isScheduledSendButtonDisabled = true
      } else if (scheduleAt && newScheduleAt && !moment(scheduleAt).isSame(moment(newScheduleAt))) {
        submitText = `Reschedule for ${moment(newScheduleAt).format(SCHEDULE_DATE_FORMAT)}`
        isScheduledSendButtonDisabled = false
      }
    }

    if (this.props?.introRequestFlow) {
      submitText = 'Done'
    }

    let submitButton = (
      <CabalButton
        variant={iconOnlyButton ? 'link' : 'primary'}
        className={cx({
          'rounded-r-none': isScheduledSendEnabled,
        })}
        onClick={() => this.onSubmit()}
        disabled={
          incompleteForm || !isOwner || isScheduledSendButtonDisabled || workingState === 'testing'
        }
        working={workingState === 'sending' || submitWorking}
        data-testid="send-message"
        tooltip={tooltipText}
        leftIcon={
          iconOnlyButton ? (
            <Typography fontSize="18">
              <i className="far fa-paper-plane-top" />
            </Typography>
          ) : undefined
        }
      >
        {!iconOnlyButton && (
          <Typography fontWeight={600} fontSize={'14'}>
            {submitText}
          </Typography>
        )}
      </CabalButton>
    )

    if (sender && (!isOwner || this.props?.introRequestFlow)) {
      const sharedWithSender = this.isSenderNotified(sender)
      let canNotifySender = true
      if (
        !this.props.team?.allow_employees_to_notify_members &&
        this.props.user.team?.slug === this.props.team?.slug &&
        this.props.user.team_role === 'member' &&
        sender.type === 'advisor'
      ) {
        canNotifySender = false
      }

      let tooltip: string | undefined
      if (sharedWithSender) {
        tooltip = `Send ${sender.name} an email notification letting them know this message is ready to send`
      } else if (!canNotifySender) {
        tooltip = `You don’t have permission to share this draft directly. Add teammates by @mentioning them in the comments, or copy the share link.`
      }

      const submitButtonClick = () => {
        if (!this.props.introRequestFlow) {
          this.handleNotifySender(sender)
        } else {
          this.props?.hideModal()
        }
      }
      submitButton = (
        <CabalButton
          variant="primary"
          onClick={submitButtonClick}
          disabled={sharedWithSender || !canNotifySender}
          working={workingState === 'sharing'}
          size="medium"
          leftIcon={<i className="far fa-share"></i>}
          tooltip={tooltip}
          data-testid="composer-share-with-sender-button"
        >
          {!this.props.introRequestFlow && (
            <>
              Share{sharedWithSender ? 'd' : ''} with{' '}
              {!!this.state.message.request_uuid ? sender.name || sender.email : 'sender'}
            </>
          )}
          {this.props.introRequestFlow && submitText}
        </CabalButton>
      )
    }

    if (isScheduledSendEnabled && isOwner && !this.props?.introRequestFlow) {
      submitButton = (
        <>
          {submitButton}

          <ScheduleSend
            date={
              newScheduleAt || scheduleAt ? moment(newScheduleAt || scheduleAt).toDate() : undefined
            }
            onChangeDate={(scheduleAt) => {
              scheduleAt && this.setState({ newScheduleAt: scheduleAt.toISOString() })
            }}
            disabled={
              incompleteForm ||
              !isOwner ||
              this.state.workingState === 'sending' ||
              this.state.workingState === 'testing'
            }
          />
        </>
      )
    }

    return <div className="flex items-center">{submitButton}</div>
  }

  renderGmailAuthModal: RenderModal = (resolve) => (
    <GoogleAuthModal
      show
      email_alias_address={this.props.user.email_alias_address}
      onHide={() => {
        resolve()
        this.resetWorkingState()
      }}
      onSkip={async () => {
        await this.props.reloadUser()

        this.setMessageState({ from: this.props.user.email_alias_address })

        this.setState({ workingState: '', skippedReauth: true })

        if (this.testing) {
          setTimeout(() => this.onSendTestEmail({ skipGmailAuth: true }))
        } else if (this.submitting) {
          setTimeout(() => this.onSubmit({ skipGmailAuth: true }))
        }

        resolve()
      }}
      onAuthorize={async (authorizedEmail) => {
        await this.props.reloadUser()
        const { data: senders } = await this.props.reloadSenders()

        const newSender = senders!.find((s) => s.email === authorizedEmail)

        this.setMessageState({ from: authorizedEmail, sender: newSender })

        this.resetWorkingState()

        if (this.testing) {
          setTimeout(() => this.onSendTestEmail())
        } else if (this.submitting) {
          setTimeout(() => this.onSubmit())
        }

        resolve()
      }}
    />
  )

  renderMicrosoftAuthModal: RenderModal = (resolve) => (
    <MicrosoftAuthModal
      show
      onHide={() => {
        resolve()
        this.resetWorkingState()
      }}
      onAuthorize={async (authorizedEmail) => {
        await this.props.reloadUser()
        const { data: senders } = await this.props.reloadSenders()

        const newSender = senders!.find((s) => s.email === authorizedEmail)

        this.setMessageState({ from: authorizedEmail, sender: newSender })

        this.resetWorkingState()
        resolve()
      }}
    />
  )

  renderButtonsModal: RenderModal = (resolve) => {
    return (
      <ButtonsModal
        show
        onHide={() => resolve()}
        onUpdate={(b) => this.setMessageState({ buttons: b })}
        inputs={this.state.message.buttons!}
      />
    )
  }

  handleShowEmailProviderAuthModal = () => {
    return this.props.showModal(this.renderGmailAuthModal, 'gmail-auth-modal')
  }

  handleShowMicrosoftAuthModal = () => {
    return this.props.showModal(this.renderMicrosoftAuthModal, 'microsoft-auth-modal')
  }

  handleShowPresendCheckFailureModal = async (presendCheckResponse: PresendCheckReponse) => {
    return await this.props.showModal<{ ignoreVars?: boolean; cancel?: boolean } | undefined>(
      (resolve) => (
        <PresendCheckFailureModal presendCheckResponse={presendCheckResponse} onHide={resolve} />
      ),
      'presend-check-failure-modal',
    )
  }

  shouldShowGmailAuthPrompt = async ({ isSendingTestEmail = false } = {}) => {
    const from = this.state.message.from
    const sender_emails = this.props.user.sender_emails

    if (!from || !sender_emails.includes(from)) {
      this.handleShowEmailProviderAuthModal()
      return true
    }

    const isCabalAliasEmail = from.endsWith('in.getcabal.com')
    if (isCabalAliasEmail) {
      if (isSendingTestEmail && this.state.skippedReauth) {
        return false
      } else {
        this.handleShowEmailProviderAuthModal()
        return true
      }
    }

    this.setState({ workingState: isSendingTestEmail ? 'testing' : 'sending' })

    // check for gmail status
    if (
      this.props.user.connected_gmail &&
      this.props.user.sender_gmail_users.length > 0 &&
      this.props.user.sender_gmail_users.find((u) => u.email === from)
    ) {
      let status: AsyncReturnType<typeof api.checkGmailAuthState>['data']
      let user: CurrentUserProfile | undefined
      try {
        status = (await api.checkGmailAuthState(from)).data
        user = (await this.props.reloadUser()).data
      } catch (e) {
        this.resetWorkingState()
        throw e
      }

      const gmail_expired_or_invalid_token = [403, 404].includes(status.token_status)
      const force_gmail_reauth = user?.gmail_auth_statuses[from]?.force_gmail_reauth ?? false
      const has_synced_gmail = user?.gmail_auth_statuses[from]?.has_synced_gmail ?? false

      if (
        force_gmail_reauth ||
        gmail_expired_or_invalid_token ||
        !has_synced_gmail /* && !dismissed_sync_gmail_modal */
      ) {
        this.handleShowEmailProviderAuthModal()
        return true
      }
    }

    return false
  }

  onSubmit = async (
    args: { saveAsDraft?: boolean; skipGmailAuth?: boolean; skipVarCheck?: boolean } = {},
  ) => {
    const { saveAsDraft = false, skipGmailAuth = false, skipVarCheck = false } = args
    this.testing = false
    this.submitting = !saveAsDraft

    this.setState({ workingState: saveAsDraft ? 'drafting' : 'sending' })

    if (!saveAsDraft) {
      if (
        !this.props.team?.permissions?.canMessageAdvisors &&
        !!this.state.message.recipients?.find((r) => r.type === 'advisor' || r.type === 'group')
      ) {
        alert('Did you mean to Share Draft? You don’t have permissions to send to this person')
        return
      }

      if (!skipGmailAuth) {
        const shouldShowModalAndStopExecution = await this.shouldShowGmailAuthPrompt()
        if (shouldShowModalAndStopExecution) {
          this.resetWorkingState()
          return
        }
      }
    }

    const presendResult = await this.preSendCheck({ skipVarCheck })

    if (typeof presendResult === 'boolean') {
      if (presendResult === true) {
        // user wants to go ahead with the send, redo the presend check but ignore vars this time
        this.onSubmit({ ...args, skipVarCheck: true })
      } else {
        // user wants to cancel
      }

      this.resetWorkingState()
      return
    }

    const {
      message: {
        body,
        subject,
        attachments,
        buttons,
        recipients,
        uuid: message_uuid,
        schedule_at: scheduleAt,
      },
      newScheduleAt,
    } = this.state

    if (this.props.submitFunc) {
      this.props.submitFunc(body, subject)
      this.resetWorkingState()
      return
    }

    if (newScheduleAt && (!scheduleAt || !moment(newScheduleAt).isSame(moment(scheduleAt)))) {
      this.setMessageState({ schedule_at: newScheduleAt }, () => {
        this.onSubmit({ saveAsDraft })
      })

      return
    }

    // show map email prompt if the user has used variables
    const bodyOrSubjectWithVariableFields =
      !!body!.match(/{{[\w_]+}}/) || !!subject!.match(/{{[\w_]+}}/)
    if (
      recipients!.some((r) => r.type === 'email') &&
      bodyOrSubjectWithVariableFields &&
      !saveAsDraft
    ) {
      if (!(await this.props.showModal(this.renderMapEmailModal, 'map_email_modal'))) return
    }

    const params = this.getMessageParams(saveAsDraft)

    let newMessage: MessageModel

    try {
      // if the message being edited is a draft and user wants to send it
      // or it is a new message
      if (!saveAsDraft || !message_uuid) {
        // either create a sent message or draft message
        if (!saveAsDraft) {
          const textInMessage = htmlTextContent(body!).replace(/\s/g, '')
          if (
            !textInMessage.length &&
            !confirm('Are you sure you want to send an empty message?')
          ) {
            this.resetWorkingState()
            return
          }
        }
        const {
          data: { message: _message },
        } = await api.createMessage(this.props.companySlug, params)
        if (!message_uuid && !saveAsDraft && !params.schedule_at) {
          cabalToast({ style: 'success', content: 'Successfully sent the message' })
        }
        newMessage = _message
      } else {
        // when the user is editing a message and wants to update it
        // either update a draft or update a sent message
        const {
          data: { message: _message },
        } = await api.updateMessage(this.props.companySlug, message_uuid, params)

        newMessage = _message
      }

      if (recipients!.some((r) => r.type === 'email' && !!r.value.add_member)) {
        this.props.reloadAdvisors()
      }

      if (saveAsDraft) {
        this.props.onSaveDraft?.()
        this.setState({ lastDraftSave: new Date() })
      } else if (newScheduleAt || scheduleAt || params.schedule_at) {
        this.props.onSaveDraft?.()
        this.props.onSubmit?.(newMessage)
      } else {
        this.props.onSubmit?.(newMessage)
      }
    } catch (e) {
      if ((e as AxiosError)?.response?.status !== HttpStatusCode.BadRequest) {
        cabalToast({
          style: 'error',
          content: 'Something went wrong. Please contact support',
        })
      }

      ErrorLogger.error(e)
    }

    this.resetWorkingState()
  }

  preSendCheck = async (args?: { skipVarCheck: boolean }) => {
    if (!this.props.team) return

    let preSendCheckResponse
    try {
      preSendCheckResponse = (
        await api.presendCheck(this.props.team?.slug, this.getMessageParams(), {
          skip_var_check: !!args?.skipVarCheck,
        })
      ).data
    } catch (e) {
      this.resetWorkingState()
      throw e
    }

    if (preSendCheckResponse.name_missing || !!preSendCheckResponse.unknown_vars.length) {
      // there are problems
      const modalResponse = await this.handleShowPresendCheckFailureModal(preSendCheckResponse)
      if (modalResponse?.cancel) {
        // user wants to cancel the send
        return false
      } else if (modalResponse?.ignoreVars || !modalResponse) {
        // user wants to go ahead
        return true
      }
    }

    // no problems
    return
  }

  onSendTestEmail = async (args: { skipGmailAuth?: boolean; skipVarCheck?: boolean } = {}) => {
    const { skipGmailAuth = false, skipVarCheck = false } = args
    this.testing = true
    this.submitting = false

    this.setState({ workingState: 'testing' })

    const presendResult = await this.preSendCheck({ skipVarCheck })

    if (typeof presendResult === 'boolean') {
      if (presendResult === true) {
        // user wants to go ahead with the send, redo the presend check but ignore vars this time
        this.onSendTestEmail({ ...args, skipVarCheck: true })
      } else {
        // user wants to cancel
      }

      this.resetWorkingState()
      return
    }

    if (!skipGmailAuth) {
      const shouldShowModalAndStopExecution = await this.shouldShowGmailAuthPrompt({
        isSendingTestEmail: true,
      })
      if (shouldShowModalAndStopExecution) {
        this.resetWorkingState()
        return
      }
    }

    const params = this.getMessageParams()

    api
      .sendTestEmail(params)
      .then(() => {
        cabalToast({ style: 'success', content: `Successfully sent the test mail` })
      })
      .finally(() => this.resetWorkingState())
  }

  isScheduledSendEnabled = () => {
    return (this.props.allowScheduledSend ?? false) && this.props.user.enable_scheduled_send
  }

  isScheduledInFuture = () => {
    const { schedule_at: scheduleAt } = this.state.message
    return scheduleAt ? moment(scheduleAt).isAfter(moment()) : false
  }

  componentWillUnmount() {
    this.autoSaver.stop()
  }

  componentDidUpdate(_: unknown, prevState: State) {
    const onChangeKeys: (keyof MessageState)[] = [
      'body',
      'subject',
      'attachments',
      'buttons',
      'recipients',
      'cc',
    ]
    onChangeKeys.forEach((key) => {
      if (!isEqual(this.state.message[key], prevState.message[key])) {
        this.props.onChange?.(key, this.state.message[key])
      }
    })
  }

  render = () => {
    const {
      message: {
        subject,
        recipients,
        from,
        cc,
        body,
        buttons,
        attachments,
        ready,
        uuid: message_uuid,
        schedule_at,
      },
      showCcField,
      autosaveError,
      lastDraftSave,
      workingState,
    } = this.state

    const sender = this.getSender()

    const {
      ckEditorRef,
      onClose,
      title,
      team,
      user: { sender_uuids: senderUuids },
      showModal,
      history,
      companySlug,
      fromRequest,
      hideToField,
      allowGroups,
      allowCc,
      presenceListContainer,
      widgetPreview,
      allowButtons,
      allowVideo,
      allowSaveAsDraft,
      editorHeight,
      allowMessageTitle,
      allowTemplates,
      allowSnippets,
      templates,
      emailSnippets,
      onEditorInit,
      afterDelete,
      onReloadSnippets,
      willMailMerge,
    } = this.props

    const incompleteForm =
      !subject || (this.props.ignoreRecipients ? false : recipients!.length === 0)

    const isOwner = sender ? senderUuids.includes(sender.uuid) : false

    const messageTitle =
      sender && !isOwner ? `${ready ? 'Editing' : 'New'} draft for ${sender.name}` : title

    return (
      <>
        {/* Adds extra close icon.. not sure why it's added */}
        {/* <div className="flex justify-end items-center text-sm">
            <div>{allowMessageTitle && messageTitle && <Typography>{messageTitle}</Typography>}</div>
          </div> */}

        <div className="lg:hidden flex justify-end items-center gap-2">
          {this.renderSubmitButton(
            incompleteForm,
            isOwner,
            workingState,
            !!this.props.submitWorking,
            true,
          )}

          {onClose && (
            <Typography onClick={onClose} fontSize="18" color={'gray'} className="cursor-pointer">
              <i className="far fa-times" />
            </Typography>
          )}
        </div>
        <FocusBannerEmailAuth
          showCta
          onGoogleAuthClick={this.handleShowEmailProviderAuthModal}
          onMicrosoftAuthClick={this.handleShowMicrosoftAuthModal}
        />

        <div className="flex">
          {team && (
            <SenderField
              senders={this.props.senders}
              from={from}
              recipients={recipients}
              onSelect={this.onSenderSelect}
              team={team}
            />
          )}
          {(allowTemplates || allowSnippets) && (
            <div className="right-actions ml-2 flex">
              {allowSnippets && (
                <EmailSnippets
                  snippets={emailSnippets}
                  onReloadSnippets={onReloadSnippets}
                  onSelect={this.handleSelectSnippet}
                />
              )}

              {allowTemplates && team && (
                <TemplateDropdown
                  team={team}
                  templates={templates}
                  onSelectTemplate={this.onSelectTemplate}
                  hideModal={onClose}
                />
              )}

              {/* TODO this is disabled ATM. */}
              {/* {window.DRAFT_ON_BEHALF && message_uuid && (
                  <DraftOnBehalf companySlug={companySlug} messageUuid={message_uuid} />
                )} */}
            </div>
          )}
        </div>

        {!hideToField && (
          <div className="flex align-middle" data-intercom-target="messages_to_field">
            <div className="flex-1">
              <RecipientsField
                willMailMerge={willMailMerge}
                selected={recipients}
                teamSlug={companySlug}
                allowGroups={allowGroups}
                sender={sender}
                label={'To'}
                allowCustomEmail
                onSelect={this.setRecipientsField}
                data-intercom-target="messages_to_field"
                fromRequest={fromRequest}
              />
            </div>

            {allowCc && !showCcField && (
              <CcButton
                fontSize="12"
                onClick={() => this.setState({ showCcField: !this.state.showCcField })}
                component="button"
                lineHeight="1"
                color={'fog'}
                data-testid="cc-btn"
              >
                Cc
              </CcButton>
            )}

            {this.props.team && this.props.showCreateGroupButton && (
              <CabalButton
                variant="secondary"
                leftIcon={<i className="far fa-users" />}
                onClick={() => {
                  showModal(this.renderGroupsModal, 'groups-modal')
                  this.props.hideGroupButton()
                }}
                className="ml-1 md:ml-2"
              >
                Create Group
              </CabalButton>
            )}
          </div>
        )}
        {allowCc && showCcField && (
          <div className="flex align-middle" data-intercom-target="messages_to_field">
            <div className="flex-1">
              <RecipientsField
                willMailMerge={willMailMerge}
                selected={cc}
                teamSlug={companySlug}
                onSelect={this.setCcField}
                label="Cc"
                allowGroups={false}
                sender={sender}
                allowCustomEmail
                type="cc"
              />
            </div>
            {showCcField && (
              <CcButton
                onClick={() => this.setState({ showCcField: false })}
                component="button"
                lineHeight="1"
              >
                <i className="far fa-times" />
              </CcButton>
            )}
          </div>
        )}
        <div>
          <TextInput
            data-testid="send-message-subject-field"
            fontSize="14px"
            labelPosition="left"
            label="Subject"
            type="text"
            value={subject}
            onChange={(e) => this.setMessageState({ subject: e.target.value })}
          />
        </div>
        {(this.props.allowSaveAsDraft && this.props.message_uuid ? !!message_uuid : true) && (
          <CKEditor
            windowKey="send-message"
            ckEditorRef={ckEditorRef}
            value={body}
            onChange={this.setBody}
            allowVariables
            allowSignature
            height={editorHeight}
            onInit={onEditorInit}
            user={this.props.user}
            team={team}
            companySlug={companySlug}
            message_uuid={message_uuid}
            presenceListContainer={presenceListContainer}
          />
        )}
        {buttons && buttons.length > 0 && <ButtonsPreview inputs={buttons} />}
        {widgetPreview}
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <UploadButton
              maxFileSize={5}
              onUpload={this.onAttachmentUpload}
              text=""
              triggerProps={{
                variant: 'secondary',
                leftIcon: <i className="far fa-paperclip" />,
                size: 'small',
                tooltip: 'Attachments',
              }}
            />
            {allowButtons && (
              <div className={'ml-2'}>
                <CabalButton
                  leftIcon={<i className="far fa-plus-square" />}
                  onClick={() => showModal(this.renderButtonsModal, 'buttons-modal')}
                  variant="secondary"
                  size="small"
                  tooltip="Edit Buttons"
                />
              </div>
            )}
            {message_uuid && (
              <CabalButton
                leftIcon={<i className="far fa-trash" />}
                variant="tertiary"
                onClick={() => {
                  if (confirm('Are you sure you want to delete this draft?')) {
                    api.deleteMessage(companySlug, message_uuid).then(() => {
                      afterDelete ? afterDelete() : history.push(`/${companySlug}/drafts`)
                    })
                  }
                }}
                size="small"
                tooltip="Delete draft"
              />
            )}
          </div>
          <div className="flex items-center gap-3">
            {lastDraftSave && (
              <Typography
                fontSize="14"
                color="fog"
                // fontWeight={500}
                className="hidden md:block"
                component="div"
              >
                Draft saved <TimeAgo datetime={lastDraftSave} />
              </Typography>
            )}
            {autosaveError && allowSaveAsDraft && (
              <div className="hidden sm:block">
                <Typography color="yellow_bold" className="mx-3" component="div" fontSize="12">
                  Draft autosave error
                </Typography>
                <CabalButton
                  variant="tertiary"
                  onClick={() => this.onSubmit({ saveAsDraft: true })}
                  working={workingState === 'drafting'}
                >
                  <Typography fontWeight={400} fontSize={'14'}>
                    Save draft
                  </Typography>
                </CabalButton>
              </div>
            )}
            {isOwner && (
              <div className="hidden sm:block">
                <CabalButton
                  variant="tertiary"
                  onClick={() => this.onSendTestEmail()}
                  disabled={!!workingState}
                  working={workingState === 'testing'}
                  data-testid="send-test-email-button"
                >
                  <Typography fontWeight={400} fontSize={'14'}>
                    Send me a test email
                  </Typography>
                </CabalButton>
              </div>
            )}

            <div className="hidden lg:block">
              {this.renderSubmitButton(
                incompleteForm,
                isOwner,
                workingState,
                !!this.props.submitWorking,
                false,
              )}
            </div>
          </div>
        </div>

        {this.isScheduledSendEnabled() && this.isScheduledInFuture() && (
          <div className="flex items-center justify-end gap-x-4 pt-2">
            <Typography className="text-sm" fontSize="13" color="fog_rain">
              Scheduled for: {moment(schedule_at).format(SCHEDULE_DATE_FORMAT)}
            </Typography>

            {isOwner && (
              <CabalButton variant="secondary" size="small" onClick={this.handleCancelScheduleSend}>
                Cancel send
              </CabalButton>
            )}
          </div>
        )}

        {attachments && attachments.length > 0 && (
          <div className="pt-2">
            <Attachments attachments={attachments} onDelete={this.onAttachmentDelete} />
          </div>
        )}
      </>
    )
  }
}

export interface SendMessageRef {
  setBody: (b: string) => void
  handleSelectSnippet: (s: EmailSnippet) => void
}

const SendMessageClass = React.forwardRef<SendMessageRef, SendMessageProps>((props, ref) => {
  const { cable } = useContext(ActionCableContext)
  const { showModal } = useModal()
  const { user, reloadUser } = useCurrentUser()
  const { advisors, reloadAdvisors } = useAdvisors({ teamSlug: props.companySlug })
  const { groups, reloadGroups } = useGroups(props.companySlug)
  const { team } = useTeam(props.companySlug)
  const { settings, updateSetting } = useCurrentUserSettings()
  const { gmailAuthorizedEmails } = useCurrentUserGmailAuthorizedEmails()
  const permissions = useAccessControl(props.companySlug)

  const history = useHistory()
  const [sendMessageRef, setSendMessageRef] = useState<SendMessageClassBase | null>(null)
  const ckEditorRef = props.ckEditorRef
  const [, setGlobalComposerMessage] = useGlobalComposerMessage()

  const message_uuid = props.message_uuid || props.message?.uuid

  const totalAdvisorGroupsCount = useMemo(() => {
    return groups
      .filter((group) => group.name !== 'All')
      .reduce((total, group) => total + group.advisor_groups_count, 0)
  }, [groups])

  const setMessageCountRef = useRef(0)
  const setMessage = useCallback(
    (message: MessageModel) => {
      if (!sendMessageRef) return

      // if we do this then there will be weird side effects
      // the local state will be reset to the initial state and user changes will be lost
      if (setMessageCountRef.current > 0) {
        throw 'message state shouldnt be set twice from here'
      }

      const state = sendMessageRef.state
      const newState: Partial<State> = {
        workingState: '',
        message: {
          ...state.message,
          ...message,
        },
      }

      // needed for templates to work
      // message body is defaulted to empty string in backend
      // message body is defaulted to null in backend
      if (message.body === '') {
        newState.message!.body = state.message.body
      }
      if (message.subject === null) {
        newState.message!.subject = state.message.subject
      }

      if (state.message.body && message.body) {
        newState.message!.body = replaceVariables(message.body, {
          params: props.templateVariables,
          user: user,
        })
      }

      if (message.cc && message.cc.length > 0) {
        newState.showCcField = true
      }

      sendMessageRef.setState(newState as State)

      setMessageCountRef.current = setMessageCountRef.current + 1
    },
    [sendMessageRef],
  )

  useQuery(
    [props.companySlug, 'getMessage', props.message_uuid],
    () => {
      sendMessageRef?.setState({ workingState: 'loading' })
      return callApi(api.getMessage, props.companySlug, props.message_uuid!)
    },
    {
      onSuccess: ({ message }) => {
        setMessage(message)
      },
      cacheTime: 0,
      enabled: !!props.message_uuid && !!sendMessageRef,
    },
  )

  useImperativeHandle(
    ref,
    () => {
      return {
        setBody: (b) => sendMessageRef?.setMessageState({ body: b }),
        handleSelectSnippet: (s) => sendMessageRef?.handleSelectSnippet(s),
      }
    },
    [sendMessageRef],
  )

  useEffect(() => {
    if (!sendMessageRef || !props.message) return

    setMessage(props.message)
  }, [sendMessageRef])

  useEffect(() => {
    if (senders) {
      const newSender = senders.find(
        (s) => s.email === (gmailAuthorizedEmails && gmailAuthorizedEmails[0]),
      )
      sendMessageRef?.setMessageState({
        from: gmailAuthorizedEmails && gmailAuthorizedEmails[0],
        sender: newSender,
      })
    }
  }, [gmailAuthorizedEmails])

  const { data: templatesData } = useQuery(
    [props.companySlug, 'getTemplates'],
    () => callApi(api.getTemplates, props.companySlug),
    { enabled: permissions.canViewMessageTemplates },
  )

  const { data: emailSnippetsData, refetch: handleRefetchEmailSnippets } = useQuery(
    [props.companySlug, 'getEmailSnippets'],
    () => callApi(api.getEmailSnippet, props.companySlug),
    { enabled: permissions.canViewMessageSnippets },
  )

  const { mutateAsync: handleCancelScheduleSend } = useMutation(() =>
    callApi(api.cancelSend, message_uuid!, props.companySlug),
  )

  const { mutateAsync: handleNotifySender } = useMutation(
    (props: { sender: SenderModel; note: string }) =>
      callApi(
        api.messageNotifySender,
        message_uuid!,
        props.sender?.uuid,
        props.companySlug,
        props.note,
      ),
  )

  const { data: senders, refetch: reloadSenders } = useQuery(
    [props.companySlug, 'getSenderUsers', user.uuid],
    () => callApi(api.getSenderUsers, props.companySlug, message_uuid),
    { select: ({ senders }) => senders },
  )

  if (!senders) return <></>

  const newProps: Props = {
    ...props,
    ckEditorRef,
    user,
    team,
    cable,
    advisors,
    reloadAdvisors,
    reloadGroups,
    reloadUser,
    reloadSenders,
    settings,
    permissions,
    showModal,
    history,
    senders,
    templates: templatesData?.templates || [],
    emailSnippets: emailSnippetsData?.email_snippets || [],
    onReloadSnippets: handleRefetchEmailSnippets,
    onCancelSend: handleCancelScheduleSend,
    onNotifySender: handleNotifySender,
    showCreateGroupButton:
      !!permissions.canEditGroups &&
      totalAdvisorGroupsCount === 0 &&
      !settings?.hide_create_group_button,
    hideGroupButton: () => updateSetting('hide_create_group_button', true),
    message_uuid,
    setGlobalComposerMessage,
  }

  if (newProps.message) {
    newProps.message.body = replaceVariables(newProps.message.body, {
      params: props.templateVariables,
      user: user,
    })

    newProps.message.subject = replaceVariables(newProps.message.subject, {
      params: props.templateVariables,
      user: user,
    })
  }

  return <SendMessageClassBase ref={(r) => setSendMessageRef(r)} {...newProps} />
})

export default SendMessageClass
