import { lighten } from 'polished'

// ORIGINAL LIGHT THEME
const BORDER_GRAY = '#D8D8D8'
const BORDER_DANGER = '#EF4444'
const LOGIN_WHITE = '#AAAAAA'
const OFF_WHITE = '#fafafa'

const LIGHT_GRAY = '#EFF2F4'
const RED = '#FF3333'
const YELLOW = '#F6D845'
const DARK_YELLOW = '#DDAF13'
const GREEN = '#53AF65'
const BLACK = '#111'
const DARK_GRAY = '#555'
const LIGHT_BLUE = 'lightblue'
const NAVY_BLUE = '#2D3748'
const WHITE = '#fff'
const LIGHT_GREEN = '#99E3D9'
const INDIGO_400 = '#7F9CF5'
const GRADIENT_BLACK_START = '#1E2533'
const GRADIENT_BLACK_END = '#161C26'
const INDIGO_DARK = '#5C69D1'

// NOTE: Missing values pulled from the dark theme
// New theme colors
const FOG = '#718096'
const MIDNIGHT = '#0E151F'
const REAL_BLACK = '#000'
const SHADOW = '#293342'
const RAIN = '#A7B4C7'
const PEPPERMINT = '#31A548'

const HVAR = '#8A97FF'
const GRAY = '#718096'
const NEON = '#E4307C'
const LEMON = '#EFC945'
const MIST = '#F7FAFC'

const LIGHT_YELLOW = 'rgba(239, 201, 69, 0.08)'
const NAV_HIGHLIGHT = 'rgba(41, 51, 66, 0.4)'

const YELLOW_BOLD = '#DDAF13'

const TEXT_SECONDARY = '#B6BABF'

const WIDGET_BG = 'rgba(26, 32, 44, 0.7)'
const WIDGET_TEXT = '#9CB0CF'

const SHOUTOUT_BG = '#F5F8FA'

const MID_PURPLE = '#A8BFF5'
const DULL_WHITE = 'rgba(217, 232, 247, 0.39)'

// Renaming Acc To Tailwind
const GRAY_50 = '#F9FAFB'
const GRAY_100 = '#F3F4F6'
const BORDER = `1px solid #E3E5E8`
const GRAY_200 = '#E5E7EB'
const GRAY_300 = '#D1D5DB'
const BORDER_COLOR = '#E3E5E8'

// Buttons
const BUTTON_PRIMARY = '#5C69D1'
const BUTTON_SECONDARY = 'rgba(92, 105, 209, 0.12)'
const BUTTON_TERTIARY = 'rgba(92, 105, 209, 0.08)'

// Box Shadows
const THREAD_SHADOW = `0px 1px 2px rgba(0, 0, 0, 0.05)`
const COMPOSER_SHADOW = `0px 20px 25px -5px rgba(0, 0, 0, 0.1),
                         0px 10px 10px -5px rgba(0, 0, 0, 0.04)`
const PURPLE_TINT = '#EAEDFA'
const EMERALD_DARK = '#00C55D'
const WINSITE_GREEN = '#10845B'

export const mainTheme = {
  name: 'light',
  layout: {
    main_content_bg_color: WHITE,
    main_bg_color: GRAY_100,
    header_nav_bg_color: NAVY_BLUE,
    header_nav_text_color: OFF_WHITE,
    bg_gradient_start: GRADIENT_BLACK_START,
    bg_gradient_end: GRADIENT_BLACK_END,
    island_bg_color: GRAY_300,

    // New theme colors
    nav_bg_color: WHITE,
  },
  modal: {
    underlay_opacity: '0.6',
    content_bg: OFF_WHITE,
    close_icon_color: GRAY,
    underlay_bg: GRAY,
  },
  chart: {
    line: GRAY_300,
    tooltip: {
      bg: 'rgba(255, 255, 255)',
      border: BORDER_COLOR,
    },
  },
  filter: {
    bg_color: GRAY_200,
    active_bg_color: INDIGO_DARK,
    active_text_color: WHITE,
    border_color: BORDER_COLOR,
    count_bg: WHITE,
  },
  inline_offers: {
    text_area: WHITE,
    background: WHITE,
    activity_bg: GRAY_200,
    inline_bg: GRAY_100,
  },
  pill: {
    neon: {
      primary_bg: 'rgba(228, 48, 124, 0.08)',
      primary_text: NEON,
      active_bg: '', // missing
      active_text: '', // missing
    },
    gray: {
      primary_bg: 'rgba(114, 129, 150, 0.08)',
      primary_text: FOG,
      active_bg: '', // missing
      active_text: '', // missing
    },
    yellow: {
      primary_bg: LIGHT_YELLOW,
      primary_text: '#8D700C',
      active_bg: '', // missing
      active_text: '', // missing
    },
    purple: {
      primary_bg: WHITE,
      active_bg: INDIGO_DARK,
      primary_text: INDIGO_DARK,
      active_text: WHITE,
    },
    purple_filled: {
      primary_bg: BUTTON_SECONDARY,
      active_bg: INDIGO_DARK,
      primary_text: BUTTON_PRIMARY,
      active_text: WHITE,
    },
    green: {
      primary_bg: WHITE,
      active_bg: '#54929A',
      primary_text: '#54929A',
      active_text: WHITE,
    },
    green_filled: {
      primary_bg: 'rgba(83, 175, 101, 0.16)',
      primary_text: GREEN,
    },
    outline: {
      primary_bg: 'transparent',
      active_bg: '#54929A',
      primary_text: 'inherit',
      active_text: WHITE,
    },
    count: {
      primary_text: FOG,
      active_text: WHITE,
    },
    queue: {
      primary_text: MID_PURPLE,
    },
    border: GRAY_100,
  },
  referrals: {
    bg_color: GRAY_200,
    bg_card: WHITE,
  },
  advisor_metrics: {
    bg_color: '#fff',
    bar_bg: '#f3f3f3',
    allocation_bar_bg: BUTTON_PRIMARY,
    vested_bar_bg: GREEN,
  },
  table: {
    bg_color: '#fff',
  },
  table_child: GRAY_50,
  side_nav: {
    bg_color: GRAY_100,
    border_color: BORDER_COLOR,
    active_item_bg_color: WHITE,
    item_bg_color: 'inherit',
    active_item_text_color: '#798EE7',
    item_text_color: MIDNIGHT,
    counter_bg_color: LIGHT_BLUE,
    counter_text_color: DARK_YELLOW,
  },
  login: {
    text_color: LOGIN_WHITE,
  },
  buttons: {
    default: {
      text_color: WHITE,
      bg_color: 'rgba(41, 51, 66, 0.16)',
      hover_text_color: OFF_WHITE,
    },
    primary: {
      text_color: WHITE,
      bg_color: INDIGO_DARK,
      disabled_bg_color: 'red',
      bg_hover: '#4C5ACC',
      focus: MID_PURPLE,
    },
    secondary: {
      text_color: BUTTON_PRIMARY,
      bg_color: BUTTON_SECONDARY,
      bg_hover: BUTTON_TERTIARY,
    },
    tertiary: {
      text_color: BUTTON_PRIMARY,
      bg_color: BUTTON_TERTIARY,
      bg_hover: `rgba(92, 105, 209, 0.04)`,
    },
    feed: {
      bg_color: '#eee',
      bg_hover: '#ddd',
    },
    destroy: {
      bg_color: '#E13131',
      bg_hover: '#BF1C1C',
    },
  },
  labels: {
    green: {
      bg_color: LIGHT_GREEN,
      text_color: BLACK,
    },
  },
  theme_picker: {
    primary_bg: WHITE,
    selected_bd: WHITE,
  },
  like_dislike: {
    default: {
      like_bg: 'rgba(83, 175, 101, 0.16)',
      dislike_bg: GRAY_100,
    },
    like: {
      like_bg: '#53AF65',
    },
    dislike: {
      dislike_bg: '#9CA3AF',
    },
  },
  colors: {
    default_color: '#0E151F',

    // like_dislike button

    shadow_icon: GRAY_300,
    default_icon: '#9CA3AF',

    // other
    primary: BLACK,
    secondary: DARK_GRAY,
    primary_bg: WHITE,
    border: BORDER_COLOR,
    border_danger: BORDER_DANGER,

    tint: PURPLE_TINT,

    // colors
    purple: BUTTON_PRIMARY,
    light_purple: HVAR,
    mid_purple: MID_PURPLE,
    gray: GRAY,
    gray300: GRAY_300,
    gray100: GRAY_100,
    gray200: GRAY_200,
    white: '#fff',
    black: '#222',
    green: GREEN,
    red: RED,
    yellow: '#D6A600',
    transparent: 'transparent',
    complete: PEPPERMINT,
    fog: FOG,
    twitter_blue: '#1DA1F2',
    mist: MIST,

    // sfdc table header
    table_header: GRAY_200,
    pill_border: GRAY_200,

    // status
    wait: DARK_YELLOW,
    done: GREEN,

    // wiget colors
    widget_bg: WIDGET_BG,
    widget_title: '#4B5563',
    widget_text: WIDGET_TEXT,
    shoutout_bg: GRAY_50,
    widget_border: '#e4eaef',

    // composer
    composer_bg: WHITE,
    banner_text: '#6B7280',
    banner_border: 'rgba(41, 51, 66, 0.16)',

    // message rows
    message_date: GRAY,
    message_sender: SHADOW,
    message_receiver: '#CBD5E0',
    message_read: DULL_WHITE,

    // add company
    add_bg: 'rgba(92, 105, 209, 0.08)',
    add_txt: BUTTON_PRIMARY,
    // Rain / Fog Toggle
    rain_fog: RAIN,
    rain: RAIN,
    fog_rain: FOG,

    placeholder: RAIN,

    // colors
    light_gray: lighten(0.5, RAIN),
    light_black: MIDNIGHT,
    light_green: LIGHT_GREEN,
    light_yellow: LIGHT_YELLOW,
    yellow_bold: YELLOW_BOLD,
    gold: YELLOW_BOLD,
    neon: NEON,
    shadow: WHITE,
    light_shadow: WHITE,

    // dashboard
    dash_bg: WHITE,

    //text
    text_secondary: TEXT_SECONDARY,
    admin_label: DARK_GRAY,

    cardBackground: WHITE,
    cardHover: TEXT_SECONDARY,
    pills: '#1B233C',

    // tab border
    tab_border: BORDER_COLOR,

    // tooltip
    tooltip_color: BLACK,
    tooltip_bg: WHITE,

    // home pill
    home_pill: MIST,

    // review stars
    star_select: LEMON,

    link: INDIGO_DARK,
    option_card_bg: MIST,

    // winsite
    emerald_dark: EMERALD_DARK,
    winsite_green: WINSITE_GREEN,
  },
  popup: {
    background: LIGHT_GRAY,
    select: GRAY_200,
  },

  highlight: 'rgba(239, 201, 69, 0.2)',

  table_header: GRAY_200,
  table_element_border: BORDER_COLOR,

  sizes: {
    container_lg: '905px',
    container_sm: '600px',
  },
  fonts: {
    serif: 'Times New Roman',
    sansSerif: "'Inter', Helvetica Neue",
    mono: "'JetBrains Mono', 'Courier New', monospace",
  },
  fw: {
    light: 200,
    offWhite: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 600,
    black: 800,
  },
  border: BORDER,
  card_border: '1px solid #E3E5E8',
  card_shadow: THREAD_SHADOW,
  composer_shadow: '0px 1px 2px rgb(0 0 0 / 5%)',
  pinned_border: '1px solid rgba(239, 201, 69, 0.5)',

  breakpoints: {
    a: '1280px',
    a1: '1100px',
    b: '930px',
    restricted: '990px',
    mobile: '500px',
  },
}

export type MainTheme = typeof mainTheme
