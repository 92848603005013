import React, { useEffect, useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useMutation, useQuery } from 'react-query'
import { Redirect, useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import MainContainer from 'containers/App/MainContainer'
import ProfileAboutSection from 'containers/GlobalProfile/ProfileAboutSection'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal/Context'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { useSlug } from 'utils/hooks/useSlug'
import { UserProfile } from 'utils/types'

import EditGlobalProfile from './EditGlobalProfile'
import EditPublicPortfolio from './EditPublicPortfolio'
import ProfileFilters from './ProfileFilters'
import ProfileHeader from './ProfileHeader'
import PublicPortfolio from './PublicPortfolio'
import Setup from './Setup'

const WidgetTitle = styled(Typography)`
  &:hover {
    color: ${({ theme }) => theme.colors.purple};
  }
`
export type UpdateProfileMutation = UseMutatonResult<
  unknown,
  unknown,
  Partial<UserProfile>,
  unknown
>

const GlobalProfile = () => {
  const { showModal } = useModal()
  const [parent] = useAutoAnimate()
  const { user, isLoggedIn } = useCurrentUser()
  const { userSlug } = useSlug()
  const location = useLocation()
  const [profile, setProfile] = useState<UserProfile>()
  const [filter, setFilter] = React.useState('')

  const { refetch: reloadProfile } = useQuery(
    ['getPublicProfileData', userSlug],
    () => callApi(api.getPublicProfile, userSlug),
    {
      enabled: !!userSlug || !!user.primary_slug,
      onSuccess: (profile) => {
        setProfile(profile)
      },
    },
  )

  useEffect(() => {
    setProfile(undefined)
  }, [userSlug])

  const updateProfileMutation = useMutation(
    (profile: Partial<UserProfile>) => callApi(api.updatePublicProfile, profile),
    {
      onSuccess(data) {
        setProfile(data)
        cabalToast({ style: 'success', content: 'Successfully updated' })
      },
    },
  )

  const onEditGlobalProfile = () => {
    if (!profile) return

    showModal(
      (resolve) => (
        <EditGlobalProfile
          onHide={() => {
            resolve()
          }}
          profile={profile}
          updateProfileMutation={updateProfileMutation}
        />
      ),
      'edit-global-profile-modal',
    )
  }

  const onEditPortfolio = () => {
    if (!profile) return

    showModal(
      (resolve) => (
        <EditPublicPortfolio
          profile={profile}
          updateProfileMutation={updateProfileMutation}
          resolve={() => {
            resolve()
          }}
          modal
        />
      ),
      'edit-public-portfolio-modal',
    )
  }

  const isCurrentUserProfile = !!profile && user.primary_slug === profile.primary_slug

  useEffect(() => {
    if (location.hash === '#edit') {
      onEditGlobalProfile()
    }
  })

  const lastAddedConnectionsDate = user?.uploaded_connections_at

  const { data: connectionsData } = useQuery(
    ['myConnections', 1],
    () => api.getMyConnections([], 1),
    {
      keepPreviousData: true,
    },
  )

  const connections = connectionsData?.data?.connections || []
  const pagination = connectionsData?.data?.pagination

  if (!userSlug) {
    if (user.primary_slug && location.hash === '#edit') {
      return <Redirect to={`/${user.primary_slug}#edit`} />
    } else if (user.primary_slug) {
      return <Redirect to={`/${user.primary_slug}`} />
    }
    return <Setup profile={profile} updateProfileMutation={updateProfileMutation} />
  }

  if (!profile) return <Loading className="my-10" />

  const viewAbout = (filter === '' || filter === 'About') && (profile.bio || profile.location)
  const viewPortfolio = filter === '' || filter === 'Portfolio'

  const history = useHistory()
  const userId = user.uuid

  const children = (
    <>
      <PageWrapper title={profile?.name || 'Profile'} showUserDropdown={false}>
        <ProfileHeader profile={profile} />
        <ProfileFilters
          onEditProfile={isCurrentUserProfile ? onEditGlobalProfile : undefined}
          profile={profile}
          reloadProfile={reloadProfile}
          filter={filter}
          onSetFilter={setFilter}
        />
        <div className="mt-10 flex gap-4">
          <div ref={parent} className="flex flex-col flex-1 gap-8">
            {viewAbout && <ProfileAboutSection profile={profile} />}
            {viewPortfolio && (
              <PublicPortfolio
                isCurrentUserProfile={isCurrentUserProfile}
                profile={profile}
                updateProfileMutation={updateProfileMutation}
                onEditPortfolio={isCurrentUserProfile ? onEditPortfolio : undefined}
              />
            )}
          </div>
        </div>
        {isCurrentUserProfile && (
          <div className="pt-8">
            <Widget
              title={
                <WidgetTitle
                  className="flex items-center cursor-pointer title"
                  component="button"
                  fontWeight={600}
                  onClick={() => {
                    history.push(`/${profile.primary_slug}/connections`)
                  }}
                >
                  My Connections
                </WidgetTitle>
              }
              description={`${pagination?.total_count.toLocaleString()} connections ${
                lastAddedConnectionsDate
                  ? `  · Last updated ${new Date(lastAddedConnectionsDate).toLocaleDateString(
                      'en-US',
                      { month: 'short', day: 'numeric', year: 'numeric' },
                    )}`
                  : ' '
              }`}
              cta={
                <div>
                  <CabalButton
                    onClick={() => window.open(`/addconnections`, '_blank')}
                    variant="link"
                  >
                    Add Connections
                  </CabalButton>
                </div>
              }
            >
              <div className="-mx-2">
                <div>
                  {connections &&
                    connections
                      .filter((c) => !!c.person && !!c.person.full_name)
                      .slice(0, 5)
                      .map((c) => {
                        return (
                          <DataCardV2
                            avatar={<i className="fa-thin fa-user" />}
                            title={c.person.full_name}
                            description={[c.person?.headline || '']}
                            key={c.uuid}
                          />
                        )
                      })}
                </div>
                <div className="flex justify-center mt-4">
                  <CabalButton
                    variant="link"
                    onClick={() => history.push(`/${profile.primary_slug}/connections`)}
                  >
                    See all ({pagination?.total_count.toLocaleString()})
                  </CabalButton>
                </div>
              </div>
            </Widget>
          </div>
        )}
      </PageWrapper>
    </>
  )

  if (isLoggedIn) {
    return children
  } else {
    return <MainContainer>{children}</MainContainer>
  }
}

export default GlobalProfile
