import React, { useMemo, useState } from 'react'

import { useMutation } from 'react-query'

import { useCompanySearch } from 'components/Winsite/queries/CompanySearch'
import { usePortfolioListContext } from 'containers/ListIndexView/PortfolioList'
import PortfolioCompanyEditOrAddModal from 'containers/ListIndexView/PortfolioList/PortfolioCompanyEditOrAddModal'
import { CompanySelectProps } from 'containers/ListIndexView/PortfolioList/types'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'

interface Props {
  setOnClose: () => void
}

const AddIndividualSection: React.FC<Props> = ({ setOnClose }) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const { showModal } = useModal()
  const teamSlug = useTeamSlug()
  const logo_url = 'https://logo.clearbit.com/'
  const { isLoading, data: companies } = useCompanySearch(debouncedQuery, true)
  const results: CompanySelectProps[] = useMemo(() => companies?.data, [companies])
  const { companyListData: companyList, refetchPortfolioCompaniesList } = usePortfolioListContext()
  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

  const { mutate: addCompany } = useMutation(
    (company: CompanySelectProps) => callApi(api.portfolioAddCompany, company, companyList.uuid),
    {
      onSuccess: () => {
        sleep(1000).then(() => {
          refetchPortfolioCompaniesList()
          setOnClose()
          cabalToast({
            content: 'Company added successfully!',
            style: 'success',
          })
        })
      },
    },
  )

  return (
    <div className="mt-4">
      <SearchInput
        onChange={(c) => setQuery(c.target.value)}
        placeholder={`Search for companies`}
        value={query}
      />

      {debouncedQuery !== '' && (
        <>
          <div className="my-4 px-3">
            <CabalButton
              variant="link"
              onClick={() => {
                showModal(
                  (resolve) => (
                    <PortfolioCompanyEditOrAddModal
                      onHide={resolve}
                      teamSlug={teamSlug}
                      refetchPortfolioList={refetchPortfolioCompaniesList}
                      listUuid={companyList.uuid}
                      name={debouncedQuery}
                    />
                  ),
                  'edit-portfolio-company-modal',
                )
                setOnClose()
              }}
              leftIcon={<i className="far fa-plus fa-xs" />}
              padding="0"
              size="large"
            >
              <span>{`Add company "${debouncedQuery}"`}</span>
            </CabalButton>
          </div>
          {isLoading && (
            <div className="flex mt-4 w-full justify-center">
              <Loading />
            </div>
          )}
          {results &&
            results.map((company, index) => (
              <DataCardV2
                key={index}
                title={company.name}
                description={company.website}
                avatar={<Avatar name={company.name} src={logo_url + company.website} />}
                cta={
                  <CabalButton onClick={() => addCompany(company)} variant="link">
                    Add
                  </CabalButton>
                }
                titleIcon={
                  <>
                    {true && (
                      <Typography
                        color="fog"
                        fontSize="12"
                        onClick={(e) => {
                          e.stopPropagation()
                          window.open('www.linkedin.com', '_blank', 'noopener noreferrer')
                        }}
                      >
                        <i className="fab fa-linkedin fa-fw"></i>
                      </Typography>
                    )}
                  </>
                }
              />
            ))}
        </>
      )}
    </div>
  )
}

export default AddIndividualSection
