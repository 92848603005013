import axios from 'axios'
import { FiltersValueType } from 'components/Filters'
import { InboxThreadBlueprint, Pagination, PaginationParams } from 'utils/types'

export default {
  getInboxThreads: (filters: FiltersValueType, paginationParams: PaginationParams) => {
    return axios.get<{ threads: InboxThreadBlueprint[]; pagination: Pagination }>(
      `/api/inbox_threads`,
      {
        params: {
          ...filters,
          ...paginationParams,
        },
      },
    )
  },

  getInboxThread: (thread_id: string) => {
    return axios.get<{ thread: InboxThreadBlueprint }>(`/api/inbox_threads/${thread_id}`)
  },
}
