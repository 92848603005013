import React, { useState } from 'react'

import { SeeMore } from 'containers/CandidateProfile'
import Pill from 'global/Pill'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

import { UserProfile } from 'utils/types'

interface Props {
  profile: UserProfile
}

const ProfileAboutSection: React.FC<Props> = ({ profile }) => {
  const bioLength = profile?.bio?.length || 0
  const [isExpanded, setIsExpanded] = useState(bioLength < 165 || false)
  const bio = isExpanded ? profile.bio : `${profile?.bio?.slice(0, 165)}`

  return (
    <Widget title="About">
      {profile.bio && (
        <p className="leading-5 mb-2">
          <Typography fontSize="12" color="fog" lineHeight={1.2}>
            {bio}
          </Typography>

          {profile.bio.length > 165 && (
            <SeeMore
              className="ml-1 cursor-pointer"
              color="fog"
              fontSize="12"
              lineHeight={1.2}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? '...see less' : '...see more'}
            </SeeMore>
          )}
        </p>
      )}

      <div className="flex flex-col">
        <div className="flex gap-6 mt-2">
          {/* {profile.expertises && (
            <div>
              <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
                Expertises
              </Typography>

              <Typography
                fontSize="12"
                component="div"
                color="fog"
                className="flex gap-1 flex-wrap"
              >
                {profile.expertises?.map((exp, i) => (
                  <Pill variant="gray" rounded key={i}>
                    {exp}
                  </Pill>
                ))}
              </Typography>
            </div>
          )} */}
          {profile.location && (
            <div>
              <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
                Location
              </Typography>

              <Typography fontSize="12" component="div" color="fog">
                <Pill variant="gray" rounded>
                  {profile.location}
                </Pill>
              </Typography>
            </div>
          )}
        </div>
      </div>
    </Widget>
  )
}

export default ProfileAboutSection
