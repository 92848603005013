import React from 'react'
import { useMutation } from 'react-query'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { useCurrentUser, useTeamSlug } from 'store/hooks'
import GoogleAuthModal from 'components/GoogleAuthModal'
import CabalButton from 'global/CabalButton'
import { RenderModal } from 'global/Modal/Context'
import { useModal } from 'global/Modal'

import IntegrationCard from './IntegrationCard'
import gmail from '../../../../assets/images/gmail.png'
import { DataCard } from 'ui-components/DataCard'

const GmailIntegrationCard: React.FC = () => {
  const { showModal } = useModal()
  const teamSlug = useTeamSlug()
  const { user, reloadUser } = useCurrentUser()

  const renderGmailModal: RenderModal = (resolve) => (
    <GoogleAuthModal show onHide={() => resolve(false)} onAuthorize={() => resolve()} />
  )

  const destroyGoogleAuth = useMutation(() => callApi(api.destroyGoogleAuth, teamSlug), {
    onSuccess: reloadUser,
  })

  const handleDestroyGoogleAuth = () => {
    if (!confirm('Are you sure you want to revoke access?')) return

    destroyGoogleAuth.mutateAsync().then(() => {
      cabalToast({ style: 'success', content: 'Successfully revoked access' })
    })
  }

  const hasSyncedGmail = Object.values(user.gmail_auth_statuses).some(
    (status) => status.has_synced_gmail,
  )

  return (
    <>
      <DataCard
        title="Gmail"
        description={'Send messages directly from your Gmail account.'}
        avatarUrl={gmail}
        cta={
          <CabalButton
            leftIcon={<i className="far fa-times" />}
            variant="secondary"
            tooltip="Revoke Access"
            disabled={destroyGoogleAuth.isLoading}
            onClick={handleDestroyGoogleAuth}
          />
        }
        secondaryCTA={{
          text: hasSyncedGmail ? 'Reauthorize' : 'Authorize',
          onClick: hasSyncedGmail
            ? () => window.location.assign('/auth/gmail')
            : () => showModal(renderGmailModal, 'authorize-gmail'),
        }}
      />
    </>
  )
}

export default GmailIntegrationCard
