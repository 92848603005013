import axios from 'axios'
import {
  GetAgreementResponse,
  GetAgreementTemplatesResponse,
  GetAgreementTemplateResponse,
  AgreementTemplateField,
  GetAgreementTemplateFieldsResponse,
  CreateAgreementTemplateRequest,
  GetAgreementsResponse,
  CreateAgreementTemplateResponse,
  CreateAgreementTemplateField,
  AgreementSend,
  CreateAgreementsRequest,
} from 'utils/types/agreement'

export default {
  createAgreement: (params: {
    team_slug: string
    advisor_uuid: string
    agreement_template_id: string
    agreement_uuid?: string
    agreement_params: any
    sender_uuid: string
    send: boolean
  }) => {
    return axios.post(`/api/agreements`, params)
  },

  uploadAgreement: (team_slug: string, advisor_uuid: string, upload_uuid: string) => {
    return axios.post(`/api/agreements/${advisor_uuid}/upload_agreement`, {
      team_slug,
      upload_uuid,
    })
  },

  createBulkAgreement: (params: CreateAgreementsRequest) => {
    return axios.post<{ job_id: string }>(`/api/agreements/bulk_create`, params)
  },

  bulkAgreementStatus: (job_id: string) => {
    return axios.get(`/api/agreements/${job_id}/bulk_create_status`)
  },

  getAgreementTemplates: (team_slug: string, advisor_uuid?: string, sender_uuid?: string) => {
    return axios.get<GetAgreementTemplatesResponse>(`/api/agreement_templates`, {
      params: {
        team_slug,
        advisor_uuid,
        sender_uuid,
      },
    })
  },

  createAgreementTemplate: (team_slug: string, params: CreateAgreementTemplateRequest) => {
    return axios.post<CreateAgreementTemplateResponse>(`/api/agreement_templates`, {
      team_slug,
      ...params,
    })
  },

  getCustomAgreementTemplateFields: (team_slug: string) => {
    return axios.get<GetAgreementTemplateFieldsResponse>(`/api/agreement_template_fields`, {
      params: {
        team_slug,
      },
    })
  },

  createCustomAgreementTemplateFields: (team_slug: string, field: CreateAgreementTemplateField) => {
    return axios.post<GetAgreementTemplateFieldsResponse>(`/api/agreement_template_fields`, {
      team_slug,
      ...field,
    })
  },

  deleteCustomAgreementTemplateField: (team_slug: string, uuid: string) => {
    return axios.delete<GetAgreementTemplateFieldsResponse>(
      `/api/agreement_template_fields/${uuid}`,
      {
        params: {
          team_slug,
        },
      },
    )
  },

  getAgreementTemplate: (id: string) => {
    return axios.get<GetAgreementTemplateResponse>(`/api/agreement_templates/${id}.json`)
  },

  updateAgreementTemplate: (
    id: string,
    params: {
      fields?: AgreementTemplateField[]
      upload_uuid?: string
      vesting_start_as_signing_date: boolean
    },
    editDefault = false,
  ) => {
    return axios.put<GetAgreementTemplateResponse>(`/api/agreement_templates/${id}`, {
      ...params,
      edit_default: editDefault,
    })
  },

  deleteAgreementTemplate: (team_slug: string, id: string) => {
    return axios.delete<GetAgreementTemplateResponse>(`/api/agreement_templates/${id}`)
  },

  getAgreement: (secret_uuid: string) => {
    return axios.get<GetAgreementResponse>(`/api/agreements/${secret_uuid}.json`)
  },

  getAgreements: (team_slug: string, advisor_uuid: string) => {
    return axios.get<GetAgreementsResponse>(`/api/agreements`, {
      params: {
        team_slug,
        advisor_uuid,
      },
    })
  },

  signAgreement: (secret_uuid: string, agreement_params: Record<string, unknown>) => {
    return axios.put(`/api/agreements/sign`, {
      agreement_params,
      id: secret_uuid,
    })
  },

  remindAgreement: (agreement_uuid: string) => {
    return axios.post(`/api/agreements/remind`, {
      agreement_uuid,
    })
  },

  deleteAgreement: (id: string) => {
    return axios.delete(`/api/agreements/${id}`)
  },

  getAgreementSend: (uuid: string, team_slug: string) => {
    return axios.get<AgreementSend>(`/api/agreement_sends/${uuid}`, {
      params: { team_slug },
    })
  },

  sendAgreementTestEmail: (body: string, subject: string) => {
    return axios.post(`/api/agreements/send_test_email`, {
      body,
      subject,
    })
  },
}
