import ReactGA from 'react-ga4'
import { useMount } from 'react-use'
import { History } from 'history'

const usePageTracking = (history: History) => {
  useMount(() => {
    if (window.ENV !== 'production') return

    ReactGA.initialize(window.GOOGLE_ANALYTICS_MEASUREMENT_ID)

    trackPageView(history.location)

    history.listen((location) => {
      trackPageView(location)
    })
  })
}

const trackPageView = (location: History<unknown>['location']) => {
  ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
  window.analytics?.page(location.pathname + location.search)
}

export default usePageTracking
