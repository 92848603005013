import * as React from 'react'

export default function useOutsideClickDetector(props: {
  refs: React.RefObject<HTMLElement>[]
  ignoreRefs?: React.RefObject<HTMLElement>[]
  callback?: () => void
}) {
  /**
   * Call callback if clicked on outside of element
   */
  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      let isInside = true
      const target = event.target as Node
      if (!target) return

      for (const ref of props.refs) {
        if (ref.current && !ref.current.contains(target)) {
          isInside = false
        }
      }

      if (props.ignoreRefs)
        for (const ignoreRef of props.ignoreRefs) {
          if (ignoreRef.current && ignoreRef.current.contains(target)) {
            isInside = true
          }
        }

      if (!isInside) {
        props.callback?.()
      }
    },
    [props],
  )

  React.useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [props.refs, props.ignoreRefs, handleClickOutside])
}
