import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import RcSteps from 'rc-steps'
import Typography from './Typography'
import tw from 'twin.macro'
import 'rc-steps/assets/index.css'

export const StepsStyles = createGlobalStyle`
  .rc-steps {

    .rc-steps-item-active .rc-steps-item-icon {
      background-color: ${({ theme }) => theme.colors.purple};
      border-color: ${({ theme }) => theme.colors.purple};
    }

    .rc-steps-item-finish .rc-steps-item-icon {
      border-color: ${({ theme }) => theme.colors.purple};
    }

    .rc-steps-item-wait .rc-steps-item-icon {
      border-color: ${({ theme }) => theme.colors.fog_rain};
      
      .rc-steps-icon {
        color: ${({ theme }) => theme.colors.fog_rain};
      }
    }

    .rc-steps-item-container {
      ${tw`flex items-center gap-2`}

      .rc-steps-item-icon {
        ${tw`flex items-center justify-center`}
        margin: 0;

        .rc-steps-icon {
          font-size: 10px;
          width: unset;
          height: unset;
          margin: 0;
          transform: unset;
          top: unset;
        }
      }

      .rc-steps-item-content {
        ${tw`flex items-center m-0`}

        .rc-steps-item-title {
          ${tw`m-0 flex items-center`}
          font-weight: 400;

          &:after {
            top: 50%;
          }
        }
      }
    }
  }
`

interface Props {
  currentStep: number
  steps: { title: string; onClick?: () => void }[]
}

const Steps: React.VFC<Props> = ({ currentStep, steps }) => {
  return (
    <RcSteps
      size="small"
      current={currentStep}
      items={steps.map((step) => ({ title: step.title, onClick: step.onClick }))}
      icons={{
        finish: (
          <Typography color="purple" fontSize="10">
            <i className="fas fa-check" />
          </Typography>
        ),
        error: <></>,
      }}
    />
  )
}

export default Steps
