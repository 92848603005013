import React, { useEffect } from 'react'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { darkTheme } from 'global/darkTheme'
import { mainTheme } from 'global/theme'

import useColorMode from 'utils/hooks/useColorMode'

const ThemeProvider: React.FC<{
  storybook?: boolean
  children: React.ReactNode
  rootElement: HTMLElement | undefined
}> = ({ children, storybook = false, rootElement }) => {
  const { theme } = useColorMode()

  useEffect(() => {
    const root = storybook ? document.getElementById('root') : rootElement
    root?.classList?.remove('dark')
    root?.classList?.remove('light')
    if (theme) root?.classList?.add(theme)
  }, [theme, storybook, rootElement])

  const themeConfig = theme === 'dark' ? darkTheme : mainTheme

  return <StyledThemeProvider theme={themeConfig}>{children}</StyledThemeProvider>
}

export default ThemeProvider
