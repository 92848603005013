import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import api, { callApi } from 'utils/api'
import { CompanySlugParam } from 'utils/types'

import TeamTable from './TeamTable'

const TeamUsers: React.VFC = () => {
  const { company_slug: companySlug } = useParams<CompanySlugParam>()

  const { data } = useQuery(['getTeamUsers', companySlug], () =>
    callApi(api.getTeamUsers, companySlug),
  )

  const teamUsers = data?.users

  return <>{teamUsers && companySlug && <TeamTable users={teamUsers} teamSlug={companySlug} />}</>
}

export default TeamUsers
