import styled from 'styled-components'
import tw from 'twin.macro'

export const Container = styled.div`
  ${tw`flex justify-center items-center w-full h-full`}
  box-sizing: border-box;
  text-align: center;
  flex-direction: column;
  display: flex;
  height: 100vh;
`

export const Logo = styled.img`
  ${tw`inline-block`}
  height: 64px;
  width: 64px;
`

export const AvatarContainer = styled.div`
  ${tw`flex items-center`}
  position: absolute;
  top: 20px;
  right: 20px;
`

export const CardWidth = styled.div`
  min-width: 350px;
  max-width: 350px;
`
