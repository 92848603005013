import React from 'react'

import Expand from 'react-expand-animated'

import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

const Faq = ({ title, body }: { title: string; body: React.ReactNode }) => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <Typography color="fog" fontSize="14" lineHeight={'1.2'} className="hover:text-[#5C69D1]">
          {title}
        </Typography>
        <Typography color="purple" className="pl-2">
          {open ? (
            <i className="far fa-chevron-down fa-xs" />
          ) : (
            <i className="far fa-chevron-right fa-xs" />
          )}
        </Typography>
      </div>
      <Expand open={open}>
        <Typography color="fog" fontSize="14" className="py-2 pl-5  pr-2" component="p">
          {body}
        </Typography>
      </Expand>
    </>
  )
}

const CraftFAQ = () => {
  return (
    <Widget title="FAQs" classNames="flex flex-col gap-3">
      <Faq
        title="How will I benefit from this platform?"
        body={
          <ul className="list-disc pl-5 space-y-2">
            <li>
              Visibility to a network of portfolio founders seeking top talent for their company.
            </li>
            <li>Direct connection with founders and key hiring leaders</li>
            <li>A platform to showcase your skills and experiences</li>
            <li>Control over profile visibility and job preferences</li>
            <li>
              Opportunities for both full-time and advisory roles across various stages and
              industries.
            </li>
          </ul>
        }
      />
      <Faq
        title="Who will I expect to reach out to me?"
        body="Our founders will have the ability to search the Talent Hub for relevant candidates based on the criteria you input into your profile. Your Craft Talent Partner will reach out to you with an intro from the founder."
      />
      <Faq
        title="Can I control my visibility on the platform?"
        body={
          <>
            <p>
              On our roadmap, we plan to allow candidates to have full control over their profile
              and its visibility. You can choose to make your profile public to the Craft portfolio
              or hide it when you are no longer available. There is also a feature for automatic
              deactivation of public viewing if the profile remains inactive for 90 days.
            </p>
            <br />
            <p>
              For the time being, if you wish to deactivate or delete your profile, please reach out
              to talent@craftventures.
            </p>
          </>
        }
      />
      <Faq
        title="What are the privacy or data security measures in place?"
        body={
          <>
            Cabal is a trusted, SOC2-compliant platform operating under industry-leading security
            practices. Read more here:{' '}
            <Typography
              className="underline"
              href="https://getcabal.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              component="a"
              color="purple"
            >
              getcabal.com/privacy
            </Typography>
          </>
        }
      />
      <Faq
        title="What additional features does the platform offer?"
        body="On our roadmap, we plan to provide a jobs page to share publicly unlisted executive leadership roles and provide you an opportunity to request intros directly to the hiring manager. This release will be announced within the upcoming weeks."
      />
      <Faq
        title="How do I get Cabal admin support?"
        body="If you are experiencing technical issues with the usability of the platform, please reach out to talent@craftventures.com and we address your issue with the Cabal team."
      />
      <Faq
        title="How do I upload my CV, portfolio, or other docs?"
        body={
          <>
            <p>
              During the profile creation process, you will have the option to upload your CV, cover
              letter, or any other doc of your choosing under 'Work and Education History.'
            </p>
            <br />
            <p>
              If you choose to upload a document after completing your profile, simply go to your
              profile, select → 'edit', and click 'continue' to the 'Work and Education History'
              page. You will see an option to upload documents at the top of the page.
            </p>
          </>
        }
      />
    </Widget>
  )
}

export default CraftFAQ
