import React from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import memoizeOne from 'memoize-one'

import { FiltersValueType, renderFilters } from 'components/Filters'
import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import { ResourcesFilterTypes } from 'containers/ListIndexView/ResourcesList/types'
import { useAccessControl } from 'global/AccessControl'
import { CheckBox } from 'global/Input'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import Switch from 'ui-components/Switch'

interface Props {
  disabled?: boolean
  filters: any
  setFilters: (filters: ResourcesFilterTypes) => void
  ownedCompanyTags?: any
  locations: string[]
  setSelectedItems: (selectedItems: any[]) => void
  selectedItems: any[]
}

const upcastFilters = memoizeOne((filters: ResourcesFilterTypes) => {
  const result: FiltersValueType = {}

  const search = filters.search
  if (!!search?.length) {
    result.search = search
  }

  const portfolioStages = filters.portfolio_stages
  if (!!portfolioStages?.length) {
    result.portfolio_stages = portfolioStages
  }

  const sizes = filters.size
  if (!!sizes?.length) {
    result.size = sizes
  }

  const tags = filters.tags
  if (!!tags?.length) {
    result.tags = tags
  }

  const sort = filters.sort
  if (!!sort) {
    result.sort = sort
  }

  const memberView = filters.member_view
  if (memberView) {
    result.member_view = memberView
  }

  const location = filters.location
  if (!!location) {
    result.location = location
  }

  return result
}, isEqual)

const downcastFilters = memoizeOne((filters: FiltersValueType) => {
  const result: ResourcesFilterTypes = {}
  const search = filters['search'] as string[] | null
  if (!!search?.length) {
    result.search = search
  }

  const sizes = filters['size'] as string[] | null
  if (!!sizes?.length) {
    result.size = sizes
  }

  const tags = filters['tags'] as string[] | null
  if (!!tags?.length) {
    result.tags = tags
  }

  const portfolioStages = filters['portfolio_stages'] as string[] | null
  if (!!portfolioStages?.length) {
    result.portfolio_stages = portfolioStages
  }

  const sort = filters['sort'] as string | null
  if (!!sort) {
    result.sort = sort
  }

  const location = filters['location'] as string | null
  if (!!location) {
    result.location = location
  }

  const memberView = filters['member_view'] as boolean | null
  if (!!memberView) {
    result.member_view = memberView
  }

  return result
}, isEqual)

const ResourcesListFilters: React.FC<Props> = ({
  filters,
  setFilters,
  ownedCompanyTags,
  setSelectedItems,
  locations,
  disabled,
  selectedItems,
}) => {
  const teamSlug = useTeamSlug()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const { companyListData, view, setView, resourcePeople, adminView } = useResourcesListContext()
  const realAdminView = !!isAdminOrEmployee && !!companyListData.owned
  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: [
      {
        key: 'search',
        type: 'search',
        icon: 'far fa-search',
        labelStr: 'Search',
        label: 'Search',
        skipLabelInAppliedPill: true,
        noPopover: true,
      },
      {
        key: 'tags',
        type: 'dropdown[]',
        label: 'Tags',
        options: ownedCompanyTags?.map((s: string) => ({ label: s, value: s })),
      },
      {
        key: 'location',
        type: 'dropdown',
        label: 'Location',
        options: locations?.map((s) => ({ label: s, value: s })),
      },
      {
        key: 'sort',
        type: 'dropdown',
        label: (
          <Typography>
            <i className="far fa-sort fa-sm mr-1" />
            Sort
          </Typography>
        ),
        labelStr: 'Sort',
        options: [
          { label: 'Name (A-Z)', value: 'name-asc' },
          { label: 'Name (Z-A)', value: 'name-desc' },
        ],
      },
    ].filter(Boolean),
    value: upcastFilters(filters),
    onChange: (f) => setFilters(downcastFilters(f)),
  })

  const clearFilters = () => {
    setFilters({})
  }

  return (
    <div
      ref={parent}
      className={cx('flex-col w-full my-3', {
        'pointer-events-none opacity-50': disabled,
        'px-2.5': adminView,
      })}
    >
      <div className="flex flex-col sm:flex-row justify-between items-start">
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-wrap gap-2.5">
            {adminView && (
              <CheckBox
                checked={selectedItems.length === resourcePeople.length}
                onChange={(e) => {
                  setSelectedItems(e.target.checked ? resourcePeople : [])
                  if (e.target.checked && view === 'grid') {
                    setView('list')
                  }
                }}
              />
            )}
            {renderedFilters}
          </div>
          {(adminView || realAdminView) && (
            <div className="flex items-center gap-2">
              <Typography fontSize="12" color="fog">
                Member View
              </Typography>
              <Switch
                checked={filters.member_view}
                onCheckedChange={(e) => setFilters({ ...filters, member_view: e })}
              />
            </div>
          )}
        </div>
      </div>
      {appliedFilters.length > 0 && (
        <div className="flex justify-between items-center">
          <div className="flex gap-1 flex-wrap mt-2">{appliedFilters}</div>

          <Typography
            fontSize="12"
            color="fog"
            className="cursor-pointer"
            onClick={() => clearFilters()}
          >
            <i className="far fa-filter-slash mr-1"></i>
            Clear all
          </Typography>
        </div>
      )}
    </div>
  )
}

export default ResourcesListFilters
