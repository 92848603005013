import * as React from 'react'

import EmptyState from 'global/EmptyState'
import Typography from 'global/Typography'

import EmptyMembersImage from '../../images/empty-members.png'

const MembersEmptyState: React.FC = ({ onClick }) => {
  return (
    <EmptyState
      heading="Welcome to the Member Directory"
      image={
        <Typography fontSize="24" color="fog">
          <i className="far fa-user"></i>
        </Typography>
      }
      body={
        <Typography textAlign="center" component="h5">
          Here you’ll manage all of your advisors, investors, and employees, and keep an eye on
          their contributions.
        </Typography>
      }
    />
  )
}

export default MembersEmptyState
