import React from 'react'

import { useMutation } from 'react-query'
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom'

import defaultLists from 'containers/SignupScreen/ResourcesPage/defaultLists'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'

import api, { callApi } from 'utils/api'

export default function GetResourcePage(
  props: RouteComponentProps<{ category: string; index: string }>,
) {
  const user = useCurrentUser()
  const { category: categoryName, index } = props.match.params
  const history = useHistory()
  const category = defaultLists.lists[categoryName as keyof typeof defaultLists.lists]
  const list = category?.lists[parseInt(index, 10)]
  const teamSlug = user.user?.team?.slug
  const listType = category.listType
  const listCategory = () => {
    if (category.name == 'Sales Prospects') {
      return 'sales'
    } else if (category.name == 'Potential Investors') {
      return 'fundraise'
    } else if (category.name == 'Buyer Personas') {
      return 'sales'
    }
  }
  const addCompanyListMutation = useMutation(
    async () => {
      return callApi(api.addCompanyList, teamSlug!, {
        company_list: {
          company_list_filters: [],
          company_list_items: list.items.map((item) =>
            listType === 'personas' ? item.name : `${item.name},${item.domain}`,
          ),
          display_mode: 'list',
          dynamic: false,
          filters: { owner_emails: [], stage_names: [], pipeline_names: [] },
          icps: [],
          list_subscriptions: [],
          list_type: listType,
          name: list.name,
          provider: null,
          user: user.user,
          list_category: listCategory(),
        },
      })
    },
    {
      onSuccess: (result) => {
        history.push(`/${result.company_list.owning_team.slug}/lists/${result.company_list.slug}`)
      },
    },
  )

  const addCompanyList = addCompanyListMutation.mutate

  React.useEffect(() => {
    if (list !== undefined && teamSlug) {
      addCompanyList()
    }
  }, [list, addCompanyList, teamSlug])

  if (teamSlug === undefined) {
    return <Redirect to={{ pathname: '/onboarding', state: { from: window.location.pathname } }} />
  }

  return (
    <div className="my-20">
      {list ? (
        <div className="flex flex-col items-center">
          <>
            <Loading />
            <Typography className="mt-4" color="fog" fontSize="14">
              Adding the <strong>{list.name}</strong> list to your workspace...
            </Typography>
          </>
        </div>
      ) : (
        'no list'
      )}
    </div>
  )
}
