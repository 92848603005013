import React from 'react'

import pluralize from 'pluralize'
import { useMutation } from 'react-query'
import { SetRequired } from 'type-fest'

import EditGroupsModal from 'containers/Groups/EditGroupsModal'
import CabalButton from 'global/CabalButton'
import { RenderModal, useModal } from 'global/Modal/Context'
import { useGroups } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'

import api, { callApi } from 'utils/api'
import { GroupModel } from 'utils/types'

interface Props {
  group: GroupModel
  teamSlug: string
}

const GroupRow: React.FC<Props> = ({ group, teamSlug }) => {
  const { showModal } = useModal()
  const { reloadGroups } = useGroups(teamSlug)

  const { mutate: saveGroup, isLoading: loadingGroup } = useMutation(
    ['saveGroup'],
    (group: SetRequired<Partial<GroupModel>, 'uuid'>) => callApi(api.saveGroup, teamSlug, group),
    {
      onSuccess: () => reloadGroups(),
    },
  )

  const renderGroupEditModal: RenderModal = (resolve) => (
    <EditGroupsModal
      onHide={() => resolve(false)}
      onSave={() => {
        reloadGroups()
      }}
      onDelete={() => {
        reloadGroups()
        resolve(false)
      }}
      selectedGroup={group}
      teamSlug={teamSlug}
    />
  )

  const descriptions = [
    `${group.advisor_groups_count} ${pluralize('member', group.advisor_groups_count)}`,
    group.public_to_team ? 'Visible to employees' : 'Visible to admins',
    group.smart_group && 'Smart group',
  ].filter(Boolean)

  return (
    <DataCardV2
      title={group.name}
      description={descriptions}
      onClick={() => {
        showModal(renderGroupEditModal, 'render_group_edit_modal')
      }}
      className="dark:border-[#2d3748] border-[#E3E5E8] border-b-[1px] rounded-none hover:rounded"
      cta={
        <CabalButton
          variant="link"
          disabled={loadingGroup}
          onClick={() => {
            saveGroup({
              ...group,
              public_to_team: !group.public_to_team,
            })
          }}
          tooltip={group.public_to_team ? 'Visible to employees' : 'Visible to admins'}
          leftIcon={
            group.public_to_team ? <i className="far fa-eye" /> : <i className="far fa-eye-slash" />
          }
        ></CabalButton>
      }
    />
  )
}

export default GroupRow
