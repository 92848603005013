import React from 'react'

import { cn } from 'utils/styles'

const GridWrapper = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => {
  return (
    <div className={cn('grid grid-cols-2 sm:grid-cols-4 gap-x-2 gap-y-4', className)}>
      {children}
    </div>
  )
}

export default GridWrapper
