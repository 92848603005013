import React from 'react'

import Typography from 'global/Typography'

import { GlobalPersonBlueprint } from 'utils/types'

interface Props {
  person: GlobalPersonBlueprint
  canAppendHeadline?: boolean
}

const GlobalPersonLink: React.FC<Props> = ({ person, canAppendHeadline = true }) => {
  if (person.linkedin_url) {
    return (
      <Typography
        component="a"
        target="_blank"
        color="link"
        href={person.linkedin_url}
        className="hover:underline"
      >
        {person.name}
      </Typography>
    )
  } else if (canAppendHeadline && person.headline) {
    return (
      <Typography>
        {person.name} - {person.headline}
      </Typography>
    )
  } else {
    return <>{person.name}</>
  }
}

export default GlobalPersonLink
