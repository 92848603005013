import React from 'react'
import { TextArea, TextInput } from 'global/Input'
import { EmailTemplate } from 'utils/types'
import styled from 'styled-components'
import tw from 'twin.macro'
import { ModalSectionWrapper, ModalInputWrapper, ModalInputLabel } from 'global/Modal/styles'
import CabalButton from 'global/CabalButton'

export const SectionHeader = styled.div`
  ${tw`flex items-center justify-between`}
  height: 24px;
`

export const Banner = styled.div`
  ${tw`flex items-center p-3 rounded`}
  background-color: ${({ theme }) => theme.buttons.tertiary.bg_color};
`

export type FieldFilterProps = {
  index?: number
  operator?: string
  filterable?: any
}

export type ListProps = {
  name: string
  description: string
  template?: EmailTemplate
  list?: string
  stageNames?: string[]
  ownerEmails?: string[]
  pipelineNames?: string[]
  fieldFilters?: FieldFilterProps[]
  notifications?: string[]
}

interface BaseProps {
  listData: ListProps
  setListData: (list: ListProps) => void
}

interface Props extends BaseProps {
  title: string
  placeholder: string
}

export const ImportCompanyListSection: React.VFC<BaseProps> = (props) => {
  return (
    <ImportListSection
      title="Companies"
      placeholder="Paste company names, one company per line"
      {...props}
    />
  )
}

export const ImportJobsListSection: React.VFC<BaseProps> = (props) => {
  // const { listData, setListData } = props
  return (
    <ImportListSection title="Jobs" placeholder="Paste job titles, one per line" {...props}>
      {/* <ModalInputWrapper>
        <ModalInputLabel>Or paste a Lever url</ModalInputLabel>
        <TextInput
          data-testid="import-companies-input"
          value={listData.external_url}
          className={'w-full'}
          onChange={(e) => setListData({ ...listData, external_url: e.target.value })}
          rows={6}
          placeholder="Paste job titles, one per line."
        />
      </ModalInputWrapper> */}
    </ImportListSection>
  )
}

export const ImportPersonasListSection: React.VFC<BaseProps> = (props) => {
  return (
    <ImportListSection
      title="Personas"
      placeholder='Paste personas (e..g, "VP Sales"), one per line'
      {...props}
    />
  )
}

export const ImportFundraiseListSection: React.VFC<BaseProps> = (props) => {
  return (
    <ImportListSection title="Investors" placeholder="Paste firm names, one per line" {...props} />
  )
}

const ImportListSection: React.VFC<Props> = ({
  listData,
  setListData,
  title,
  placeholder,
  children,
}) => {
  const isDomainList =
    listData.list_type === null ||
    listData.list_type === 'companies' ||
    listData.list_type === 'fundraise'

  return (
    <ModalSectionWrapper>
      <ModalInputWrapper>
        <ModalInputLabel>
          <div>{title}</div>
          {listData.uuid && isDomainList && (
            <CabalButton
              variant="secondary"
              size="small"
              className="mt-2"
              onClick={() => setListData({ ...listData, bulkEdit: !listData.bulkEdit })}
            >
              Edit domains
            </CabalButton>
          )}
        </ModalInputLabel>
        <TextArea
          data-testid="import-companies-input"
          value={listData.list}
          className={'w-full'}
          onChange={(e) => setListData({ ...listData, list: e.target.value })}
          rows={6}
          placeholder={placeholder}
        />
      </ModalInputWrapper>
      {children}
    </ModalSectionWrapper>
  )
}

export default ImportListSection
