import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import {
  ABBREVIATED_LEVEL_MAP,
  ABBREVIATED_OFFICE_WITH_OPEN_TO_ALL_MAP,
  ABBREVIATED_ROLE_FUNCTION_MAP,
} from 'containers/ListIndexView/constants'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'

import { CompanyBlueprintWithConnections } from 'utils/types'

const Pill = styled.div<{
  backgroundColor: string
  color: string
}>`
  ${tw`flex items-center justify-center rounded-full gap-1 py-1 px-1.5`}
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-size: 10px;
`

const Functions = ({ functions }: { functions?: string[] }) => {
  if (!functions || functions?.length === 0) return null

  return (
    <div className="flex flex-wrap gap-1">
      <Pill color="#5C69D1" backgroundColor="#DCE5FB">
        {
          ABBREVIATED_ROLE_FUNCTION_MAP[functions[0] as keyof typeof ABBREVIATED_ROLE_FUNCTION_MAP]
            ?.icon
        }
        {
          ABBREVIATED_ROLE_FUNCTION_MAP[functions[0] as keyof typeof ABBREVIATED_ROLE_FUNCTION_MAP]
            ?.title
        }
      </Pill>
    </div>
  )
}

const Levels = ({ level }: { level?: string }) => {
  if (!level) return null

  return (
    <div className="flex flex-wrap gap-1">
      <Pill color="#E4307C" backgroundColor="#FBE2EC">
        <i className="far fa-layer-group fa-fw fa-sm" />
        {ABBREVIATED_LEVEL_MAP[level as keyof typeof ABBREVIATED_LEVEL_MAP]}
      </Pill>
    </div>
  )
}

const OfficePreference = ({ office_preference }: { office_preference?: string[] }) => {
  if (!office_preference || office_preference?.length === 0) return null

  return (
    <div className="flex flex-wrap gap-1">
      <Pill color="#53AF65" backgroundColor="#E8F5EB">
        <i className="far fa-check fa-fw fa-sm" />
        {
          ABBREVIATED_OFFICE_WITH_OPEN_TO_ALL_MAP[
            office_preference[0] as keyof typeof ABBREVIATED_OFFICE_WITH_OPEN_TO_ALL_MAP
          ]
        }
      </Pill>
    </div>
  )
}

const Count = ({
  functions,
  office_preference,
}: {
  functions?: string[]
  office_preference?: string[]
}) => {
  const count = (functions?.length || 1) - 1 + ((office_preference?.length || 1) - 1)
  if (count <= 0) return null

  const tooltipLabel = (
    <div className="flex flex-wrap gap-1">
      {functions?.length &&
        functions?.length > 1 &&
        functions.slice(1).map((func, index) => <Functions functions={[func]} key={index} />)}
      {office_preference?.length &&
        office_preference?.length > 1 &&
        office_preference
          .slice(1)
          .map((office, index) => <OfficePreference office_preference={[office]} key={index} />)}
    </div>
  )

  return (
    <Tooltip label={tooltipLabel}>
      <Typography fontSize="12" color="rain">
        +{count}
      </Typography>
    </Tooltip>
  )
}

const CandidateDataTags = ({ company }: { company: CompanyBlueprintWithConnections }) => {
  const office_preference = company.office_preference
  const level = company.level
  const functions = company.functions

  return (
    <div className="flex flex-wrap items-center gap-1">
      <Functions functions={functions} />
      <Levels level={level} />
      <OfficePreference office_preference={office_preference} />
      <Count functions={functions} office_preference={office_preference} />
    </div>
  )
}

export default CandidateDataTags
