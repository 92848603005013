import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import emoji from 'react-easy-emoji'
import cx from 'classnames'
import { cabalToast } from 'ui-components/Toast'
import { useMount } from 'react-use'

import { MessageModel, MessageThread } from 'utils/types'
import Typography from 'global/Typography'
import CabalButton from 'global/CabalButton'
import { Divider } from 'components/SendMessage/styles'
import { useTeamSlug } from 'store/hooks'

import MessageContent from './MessageContent'
import ThreadView from './MessageThread/ThreadView'
import { MessageContainer, TopBar } from './styles'
import { useMutation } from 'react-query'
import api, { callApi } from 'utils/api'
import { first } from 'lodash'
import CabalMessage from 'models/CabalMessage'

interface Props {
  loadMessage: () => void
  messageThread: MessageThread
  message: CabalMessage
  advisorView: boolean
  onMarkMessageThreadAsRead?: (messageThreadUuid: string) => void
  onSetMessageThread: (id: undefined | string, forcePushRoute?: boolean) => void
  minimalView?: boolean
}

const SelectedThread: React.VFC<Props> = ({
  loadMessage,
  messageThread,
  message,
  advisorView,
  onMarkMessageThreadAsRead,
  onSetMessageThread,
  minimalView = false,
}) => {
  const history = useHistory()
  const teamSlug = useTeamSlug()

  const replyMessages = messageThread.reply_messages
  const replyCount = replyMessages.length
  const firstReplyMessage = first(replyMessages) as MessageModel | undefined

  const { mutateAsync: detachMessage } = useMutation((messageUuid: string) =>
    callApi(api.detachMessage, messageUuid, teamSlug),
  )

  useMount(() => {
    if (messageThread.is_unread) {
      onMarkMessageThreadAsRead?.(messageThread.uuid)
    }
  })

  const handleDetachMessage = useCallback(() => {
    if (!firstReplyMessage) {
      throw new Error('Can not detach thread, firstReplyMessage is undefined')
    }

    detachMessage(firstReplyMessage.uuid)
      .then(() => cabalToast({ style: 'success', content: 'Succesfully added message to Inbox!' }))
      .then(() => loadMessage())
      .catch(() => cabalToast({ style: 'error', content: 'Failed to add message to Inbox!' }))
  }, [detachMessage, loadMessage, firstReplyMessage])

  return (
    <>
      {/* {!minimalView && (
        <TopBar className="mb-4">
          <div>
            <CabalButton
              onClick={() => history.goBack()}
              variant="tertiary"
              leftIcon={
                <Typography color="purple">
                  <i className="far fa-arrow-left" />
                </Typography>
              }
            >
              <Typography color="purple">Back</Typography>
            </CabalButton>
          </div>
        </TopBar>
      )} */}
      <div className="flex items-center justify-between">
        <div className="flex items-center w-full pl-14">
          <Typography
            color={'primary'}
            fontSize="28"
            fontWeight={700}
            lineHeight={1.2}
            component="h1"
            className={cx('mt-2', { italic: !message.subject })}
          >
            {emoji(message.subject || '(No Subject)')}
          </Typography>

          {!advisorView && (
            <div className="flex flex-1 items-center justify-between">
              <div className="flex items-center">
                <Typography className="px-2" fontSize="14">
                  <i className="far fa-chevron-right"></i>
                </Typography>

                <Typography fontWeight={600} component="h1">
                  {messageThread.recipient.name || firstReplyMessage?.sender.name}
                </Typography>

                <Typography color="purple" component="p" fontSize="14">
                  {!!replyCount && (
                    <Typography fontSize="12" color="purple" className="pl-2">
                      {replyCount > 1 ? `${replyCount} replies` : `${replyCount} reply`}
                    </Typography>
                  )}
                </Typography>
              </div>

              {minimalView && (
                <CabalButton
                  variant="tertiary"
                  tooltip={
                    <>
                      Expand <i className="far fa-external-link" />
                    </>
                  }
                  onClick={() => {
                    const url = `/${teamSlug}/messages/${message.uuid}/${messageThread.uuid}`
                    if (window.open(url) === null) {
                      history.push(url)
                    }
                  }}
                >
                  <i className="far fa-expand-alt" />
                </CabalButton>
              )}
            </div>
          )}
        </div>

        {/*
        {team?.enable_message_add_to_inbox &&
          message.can_current_user_detach &&
          !firstReplyMessage?.detached && (
            <CabalButton
              className="flex-shrink-0 ml-4"
              variant="secondary"
              size="medium"
              onClick={handleDetachMessage}
              leftIcon={<i className="far fa-inbox-in" />}
            >
              Add to Inbox
            </CabalButton>
          )} */}
      </div>
      <div>
        <Divider />
      </div>

      {message && (
        <MessageContainer className="mt-3.5">
          <MessageContent
            message={message}
            messageThread={messageThread}
            allowExpansion
            isExpanded={replyMessages.length === 0}
          />
        </MessageContainer>
      )}

      {replyMessages.length > 0 && <Divider />}

      {replyMessages && (
        <ThreadView
          messageThread={messageThread}
          reloadMessage={loadMessage}
          companySlug={teamSlug}
        />
      )}
    </>
  )
}

export default SelectedThread
