import React from 'react'

import { motion } from 'framer-motion'
import compact from 'lodash/compact'
import { useLocation, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import tw from 'twin.macro'

import { useAccessControl } from 'global/AccessControl'
import { useAdvisorship, useCurrentUser, useCurrentUserSettings, useTeam } from 'store/hooks'

import { CompanySlugParam } from 'utils/types'

import Avatar from './Avatar'
import CompanySwitcherDropDown from './CompanySwitcherDropDown'
import Typography from './Typography'

interface Tab {
  id?: string
  label: React.ReactNode
  component?: React.ReactNode | (() => React.ReactNode)
  disabled?: boolean
  dataTestId?: string
  icon?: string
  active: boolean
  to: string
}

const Container = styled.div`
  ${tw`z-20 overflow-x-auto`}
  grid-area: subnav;
  background-color: ${({ theme }) => theme.layout.nav_bg_color};
  height: 69px;
  border-bottom: 1px solid ${({ theme }) => theme.layout.main_bg_color};
`

export const TabWrapper = styled.div`
  ${tw`flex h-full gap-2`}
  overflow: auto hidden;
`

const LengthWrapper = styled.div`
  ${tw`mx-auto w-full px-2`}
  max-width: ${({ theme }) => theme.breakpoints.restricted};
`

export const TabTypography = styled(Typography)`
  &:hover {
    ${({ theme, active }) =>
      !active &&
      `
      color: ${theme.colors.purple};
    `}
  }
`

const CompanyNav: React.VFC = () => {
  const location = useLocation()
  const { company_slug } = useParams<CompanySlugParam>()
  const { team } = useTeam(company_slug)
  const { advisor } = useAdvisorship(company_slug)
  const { user, isLoggedIn } = useCurrentUser()
  const theme = useTheme()

  const {
    canViewAdvisors,
    canManageCompany,
    canViewLists,
    canViewOffers,
    canViewPortfolio,
    isAdminOrEmployee,
  } = useAccessControl(company_slug)

  const isVCFirm = canViewPortfolio

  const disableCompanyMessages = team?.features?.disable_company_mail_tab

  const resourcesTab: Tab | undefined = !!canViewOffers && {
    icon: 'book-open',
    label: 'Resources',
    dataTestId: 'Resources',
    id: 'resources',
    to: `/${company_slug}/resources`,
    active: location.pathname.startsWith(`/${company_slug}/resources`),
  }

  const homeTab: Tab = {
    icon: 'gauge-max',
    dataTestId: 'Home',
    label: 'Dashboard',
    id: 'home',
    to: `/${company_slug}`,
    active:
      location.pathname == `/${company_slug}` || location.pathname === `/${company_slug}/home`,
  }

  const pageTab: Tab = {
    icon: 'building',
    label: 'Page',
    dataTestId: 'Page',
    id: 'Page',
    to: `/${company_slug}/page`,
    active: location.pathname.startsWith(`/${company_slug}/page`),
  }

  const requestsTab: Tab = {
    icon: 'thumbs-up',
    label: 'Intros',
    dataTestId: 'Intros',
    id: 'Intros',
    to: `/${company_slug}/intro-center`,
    active: location.pathname.startsWith(`/${company_slug}/intro-center`),
  }

  const companyTabs: Tab[] = compact<Tab>([
    homeTab,
    !team?.features?.home_v2 && {
      icon: 'heart-rate',
      dataTestId: 'Activity',
      label: 'Activity',
      id: 'Home',
      to: `/${company_slug}/activity`,
      active: location.pathname === `/${company_slug}/activity`,
    },
    // {
    //   icon: 'heart-rate',
    //   dataTestId: 'Activity',
    //   label: 'Activity',
    //   id: 'Activity',
    //   to: `/${company_slug}/activity`,
    //   active: location.pathname === `/${company_slug}/activity`,
    // },
    canViewAdvisors && {
      icon: 'users',
      label: 'Members',
      dataTestId: 'Members',
      id: 'Members',
      to: `/${company_slug}/members`,
      active:
        (location.pathname === `/${company_slug}/members` && location.hash === '') ||
        (location.pathname === `/${company_slug}/groups` && location.hash === ''),
    },
    isAdminOrEmployee &&
      !disableCompanyMessages && {
        icon: 'envelope',
        label: 'Mail',
        dataTestId: 'Messages',
        id: 'Messages',
        to: `/${company_slug}/messages`,
        active:
          location.pathname.startsWith(`/${company_slug}/messages`) ||
          location.pathname.startsWith(`/${company_slug}/drafts`) ||
          location.pathname.startsWith(`/${company_slug}/templates`),
      },
    canViewPortfolio && {
      icon: 'folder-open',
      label: 'Portfolio',
      dataTestId: 'Portfolio',
      id: 'Portfolio',
      to: team?.features?.can_use_new_portfolio_page
        ? `/${company_slug}/lists/${team?.portfolio_list_slug}`
        : `/${company_slug}/portfolio`,
      active:
        location.pathname.startsWith(`/${company_slug}/portfolio`) ||
        location.pathname.startsWith(`/${company_slug}/lists/${team?.portfolio_list_slug}`),
    },
    resourcesTab,
    canViewLists && {
      icon: 'list',
      label: 'Lists',
      dataTestId: 'Lists',
      id: 'Lists',
      to: `/${company_slug}/lists`,
      active:
        location.pathname.startsWith(`/${company_slug}/lists`) &&
        !location.pathname.startsWith(`/${company_slug}/lists/${team?.portfolio_list_slug}`),
    },
    team?.features?.home_v2 && pageTab,
    team?.features?.request_center && requestsTab,
    (canManageCompany || user.uuid === team?.talent_coordinator) && {
      label: 'Settings',
      dataTestId: 'Settings',
      icon: 'cog',
      id: 'Settings',
      to: `/${company_slug}/admin`,
      active: location.pathname.startsWith(`/${company_slug}/admin`),
    },
  ])

  const memberTabs: Tab[] = [homeTab]

  if (team?.features?.show_page_tab) {
    memberTabs.push(pageTab)
  }

  if (resourcesTab && advisor && advisor.has_completed_onboarding) {
    memberTabs.push(resourcesTab)
  }

  if (advisor && advisor.has_completed_onboarding) {
    memberTabs.push({
      icon: 'list',
      label: 'Lists',
      dataTestId: 'Lists',
      id: 'Lists',
      to: `/${company_slug}/lists`,
      active: location.pathname.startsWith(`/${company_slug}/lists`),
    })
  }

  // memberTabs.push({
  //   icon: 'heart-rate',
  //   dataTestId: 'Activity',
  //   label: 'Activity',
  //   id: 'Activity',
  //   to: `/${company_slug}/activity`,
  //   active: location.pathname === `/${company_slug}/activity`,
  // })

  if (team?.enable_asks && advisor && advisor.has_completed_onboarding) {
    memberTabs.push({
      icon: 'user-group',
      dataTestId: 'Connections',
      label: 'Your Connections',
      id: 'Connections',
      to: `/${company_slug}/home/connections`,
      active: location.pathname === `/${company_slug}/home/connections`,
    })
  }

  const tabs = isAdminOrEmployee || isVCFirm ? companyTabs : memberTabs

  const index = tabs.findIndex((tab) => tab.active)
  const settingsNav = tabs.find((tab) => tab.id == 'Settings')

  if (!isLoggedIn) return null

  return (
    <>
      <Container className="flex">
        <LengthWrapper>
          <div className="flex justify-between h-full w-full">
            <div className="flex flex-shrink-0">
              <CompanySwitcherDropDown
                trigger={
                  team ? (
                    <div className="flex items-center">
                      <Avatar
                        className="flex-shrink-0"
                        size="40px"
                        src={team?.logo}
                        name={team?.name}
                      />

                      <Typography className="flex-shrink-0 mx-2" fontWeight={600}>
                        {team?.name}
                      </Typography>

                      <Typography color="rain" fontSize="10" className="flex-shrink-0">
                        <i className="fas fa-chevron-down"></i>
                      </Typography>
                    </div>
                  ) : (
                    <></>
                  )
                }
              />
            </div>
            <TabWrapper>
              {tabs.map((tab) => {
                return (
                  <TabTypography
                    key={`nav-tab-${tab.to}`}
                    component="link"
                    fontSize="12"
                    className="flex flex-col justify-center px-2 relative"
                    color={tab.active ? 'purple' : 'fog_rain'}
                    to={tab.to}
                    fontWeight={tab.active ? 500 : 400}
                    data-testid={tab.dataTestId}
                    active={tab.active}
                  >
                    <div className="flex gap-2 items-center">
                      {!!tab.icon && <i className={`fa${tab.active ? 's' : 'r'} fa-${tab.icon}`} />}
                      {tab.label}
                    </div>

                    {/* trick to not change the size of the nav item when font weight changes */}
                    <Typography className="opacity-0 h-0 overflow-hidden" fontWeight={500}>
                      {!!tab.icon && <i className={`fas fa-${tab.icon}`} />}
                      {tab.label}
                    </Typography>

                    {tab.active && (
                      <motion.div
                        className="absolute w-[calc(100%-16px)] bottom-0 border-b-2 border-[#8A97FF]"
                        layoutId="underline"
                      />
                    )}
                  </TabTypography>
                )
              })}
            </TabWrapper>
          </div>
        </LengthWrapper>
      </Container>
      {/* <LengthWrapper>{!!settingsNav?.active && <SettingsNav />}</LengthWrapper> */}
    </>
  )
}

export default CompanyNav
