import React, { useEffect, useRef, useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'

import { Sortable } from 'components/Sortable'
import AsksPaywall from 'containers/Asks/DealBoard/AsksPaywall'
import OfferIntroWidget from 'containers/Home/OfferIntroWidget'
import YourConnections from 'containers/Home/YourConnections'
import { useAccessControl } from 'global/AccessControl'
import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useAdvisorship, useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { List, ListType, Team } from 'utils/types'

import ContributionSection from './ContributionSection'
import HomeEditSection from './HomeEditSection'
import ListNavigation from './ListNavigation'
import MemberHomeEmptyState from './MemberHomeEmptyState'
import MemberOnboardingCards from './MemberOnboardingCards'
import PortfolioSection from './PortfolioSection'
import ResourcesSection from './ResourcesSection'
import SectionMain from './Section'
import SharePipeline from './SharePipeline'
import { HomeSectionWrapper, SectionWrapper } from './styles'

const StyledLoading = styled(Loading)`
  ${tw`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
`

const CompanyHome: React.FC<{ isHome?: boolean }> = ({ isHome = false }) => {
  const teamSlug = useTeamSlug()
  const { team, reloadTeam } = useTeam(teamSlug)
  const { advisor } = useAdvisorship(teamSlug)
  const { tag } = useParams<{ tag?: string }>()
  const [lists, setLists] = useState<ListType[] | []>([])
  const [ssid, setSsid] = useState<string | null>(null)
  const [editMode, setEditMode] = useState(false)
  const { user } = useCurrentUser()

  const {
    isAdmin,
    isAdminOrEmployee,
    isAdvisor,
    canUseExtensionConnections,
    canViewPortfolio: isVCFirm,
  } = useAccessControl(teamSlug)

  const memberUuid = advisor?.uuid

  useMount(() => {
    if (!window.II && !isAdminOrEmployee) {
      api.sendUserActivity({ team_slug: teamSlug, name: 'view_deal_board' })
    }
  })

  const connectionStatusResponse = useQuery(
    ['connectionStatus', memberUuid],
    () => callApi(api.checkConnectionStatus, memberUuid),
    {
      refetchInterval: 1000 * 3,
      refetchIntervalInBackground: !window.DISABLE_CONNECTION_STATUS_POLLING,
      enabled: !!memberUuid,
    },
  )

  const { data: connectionStatus, refetch: checkConnectionStatus } = connectionStatusResponse

  const setOrderedList = (lists: ListType[]) => {
    const desiredOrder = team?.home_list_uuids

    if (!desiredOrder || !lists) {
      setLists([])
    } else {
      const orderedLists = lists.sort(
        (a, b) => desiredOrder.indexOf(a.list.uuid) - desiredOrder.indexOf(b.list.uuid),
      )
      setLists(orderedLists)
    }
  }

  const {
    isLoading,
    data: getHomeListsData,
    refetch: refetchHome,
    isFetchedAfterMount,
  } = useQuery(['get_home_lists', teamSlug], () => callApi(api.getHomeLists, teamSlug), {
    enabled: true,
    onSuccess: (data) => {
      if (data.lists) {
        setOrderedList(Object.values(data.lists) || [])
      }
      setSsid(data.ssid)
    },
  })

  const previousSharedConnectionsIndexAt = useRef(connectionStatus?.shared_connections_indexed_at)

  useEffect(() => {
    if (
      connectionStatus?.shared_connections_indexed_at &&
      previousSharedConnectionsIndexAt.current !== connectionStatus?.shared_connections_indexed_at
    ) {
      refetchHome()
      previousSharedConnectionsIndexAt.current = connectionStatus?.shared_connections_indexed_at
    }
  }, [connectionStatus?.shared_connections_indexed_at])

  const companyLists = Object.values(getHomeListsData?.lists || {})?.map((list) => list.list) || []

  const updateTeamMutation = useMutation(
    ['updateTeam'],
    (val: Partial<Team>) => callApi(api.updateTeam, teamSlug, { team: val }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'List order updated' })
        reloadTeam()
      },
    },
  )

  if (!memberUuid || !team) {
    return <StyledLoading />
  }

  const currentList = companyLists.find((list) => list.slug === tag)
  const showMemberOnboarding = !isAdminOrEmployee && !advisor.has_completed_onboarding

  const showingOnboarding = showMemberOnboarding

  const showConnections = tag === 'connections' && !!advisor?.uuid && team.enable_asks
  const showSpecificList = tag !== 'connections' && tag !== 'home' && !!currentList
  const showHome = !showConnections && !showingOnboarding
  const showMemberEmptyState =
    !isAdminOrEmployee && isAdvisor && !team.enable_asks && !showingOnboarding

  const showYourConnections = team.enable_asks && !showingOnboarding
  const showPaywall = !team.enable_asks && isAdminOrEmployee && !showingOnboarding
  const showAdminHomeEdit = isAdmin && !showingOnboarding

  const renderYourConnectionsBlock = ({ showResults = false } = {}) => (
    <>
      {team.features.show_new_ui && !isVCFirm && (
        <HomeSectionWrapper data-testid="home-your-connections-widget">
          <OfferIntroWidget
            team={team}
            memberUuid={memberUuid}
            connectionStatus={connectionStatus}
            checkConnectionStatus={checkConnectionStatus}
            refetchHome={refetchHome}
            canUseExtensionConnections={canUseExtensionConnections}
          />
        </HomeSectionWrapper>
      )}

      {(!team.features.show_new_ui || isVCFirm) && (
        <HomeSectionWrapper data-testid="home-your-connections-widget">
          <YourConnections
            isHome={isHome}
            canUseExtensionConnections={canUseExtensionConnections}
            team={team}
            memberUuid={memberUuid}
            connectionStatus={connectionStatus}
            checkConnectionStatus={checkConnectionStatus}
            refetchHome={refetchHome}
            showHeader
            showResults={showResults}
            skinny
          />
        </HomeSectionWrapper>
      )}
    </>
  )

  const renderListsBlock = () => {
    const renderListSection = (list, handleAttributes = null) => (
      <SectionMain
        key={list.uuid}
        list={list}
        people={getHomeListsData?.people}
        listData={getHomeListsData?.lists[list.slug]}
        teamSlug={teamSlug}
        advisorId={advisor.uuid}
        selectedListSlug={list.slug}
        isHome={true}
        handleAttributes={handleAttributes}
        data={getHomeListsData}
        ssid={ssid}
        isAdmin={isAdmin}
        reloadHome={refetchHome}
      />
    )

    if (editMode && isAdmin && lists && lists.length > 1) {
      return (
        <Sortable
          items={lists.map((l) => l.list.uuid)}
          renderItem={({ id, handleAttributes }) => {
            const l = lists.find((l) => l.list.uuid === id)
            return renderListSection(l.list, handleAttributes)
          }}
          setItems={(items) => {
            const newLists = items.map((id) => lists.find((l) => l.list.uuid === id))
            setLists(newLists)
            const list_uuids = newLists.map((item, index) => item.list.uuid)
            updateTeamMutation.mutateAsync({
              data: {
                home_list_uuids: list_uuids,
              },
            })
          }}
        />
      )
    } else {
      return lists.map((l) => renderListSection(l.list))
    }
  }

  return (
    <PageWrapper title={`${team?.name} home`}>
      {showAdminHomeEdit && (
        <HomeEditSection
          listCount={companyLists.length}
          editMode={editMode}
          toggleEditMode={() => setEditMode(!editMode)}
        />
      )}
      <SectionWrapper>
        <>
          {showHome && (
            <HomeSectionWrapper data-testid="home-welcome-salutation">
              <Typography fontSize={'20'} fontWeight={600}>
                Hi, {user.first_name}
              </Typography>
            </HomeSectionWrapper>
          )}

          {isLoading && !isFetchedAfterMount && <StyledLoading />}
          {!isLoading && isFetchedAfterMount && (
            <>
              {showMemberOnboarding && (
                <MemberOnboardingCards
                  connectionStatus={connectionStatus}
                  isVCFirm={isVCFirm}
                  checkConnectionStatus={checkConnectionStatus}
                />
              )}

              {/* {showExpandedCompanyAdminSetup && <AdminHomeSetup />} */}

              {/* {showHome && <InlineOfferSection teamSlug={teamSlug} />} */}

              {/* {showAdminEmptyState && <AdminHomeEmptyState />} */}

              {showPaywall && (
                <HomeSectionWrapper>
                  <AsksPaywall team={team} />
                </HomeSectionWrapper>
              )}

              {!isVCFirm &&
                showYourConnections &&
                renderYourConnectionsBlock({ showResults: showConnections })}

              {showHome && (
                <>
                  <ListNavigation
                    teamSlug={teamSlug}
                    lists={companyLists}
                    showPortfolioCard={isVCFirm}
                    showResourcesCard={isVCFirm}
                    portfolioCompanyCount={team?.investor_companies_count}
                    resourcesCount={team?.resources_count}
                    team={team}
                  />

                  {getHomeListsData && renderListsBlock()}
                  {isVCFirm && <ResourcesSection teamSlug={teamSlug} />}

                  {isVCFirm && !isAdminOrEmployee && <SharePipeline teamSlug={teamSlug} />}

                  {isVCFirm && <PortfolioSection teamSlug={teamSlug} />}

                  {isVCFirm && showYourConnections && renderYourConnectionsBlock({})}

                  <ContributionSection teamSlug={teamSlug} advisor={advisor} />
                </>
              )}

              {showMemberEmptyState && (
                <HomeSectionWrapper>
                  <YourConnections
                    canUseExtensionConnections={canUseExtensionConnections}
                    team={team}
                    memberUuid={memberUuid}
                    connectionStatus={connectionStatus}
                    checkConnectionStatus={checkConnectionStatus}
                    refetchHome={refetchHome}
                    skinny
                  />
                  <MemberHomeEmptyState team={team} connectionStatus={connectionStatus} />
                </HomeSectionWrapper>
              )}

              {showSpecificList && (
                <SectionMain
                  list={currentList}
                  teamSlug={teamSlug}
                  advisorId={advisor.uuid}
                  selectedListSlug={tag}
                  data={getHomeListsData}
                  people={getHomeListsData?.people}
                  listData={getHomeListsData?.lists[currentList.slug]}
                  ssid={ssid}
                />
              )}
            </>
          )}
        </>
      </SectionWrapper>
    </PageWrapper>
  )
}

export default CompanyHome
