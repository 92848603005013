import * as React from 'react'

import styled from 'styled-components'

import CabalButton from 'global/CabalButton'
import DropDownMenu, { DropdownMenuItem } from 'global/DropDownMenu'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'

import { IconCtaProps, secondaryCTAProps } from '.'

interface Props {
  menuItems?: DropdownMenuItem[]
  secondaryMultiselect?: {
    text: string
    menu: DropdownMenuItem[]
  }
  secondaryMenuItems?: DropdownMenuItem[]
  secondaryCTA?: secondaryCTAProps
  iconCTA?: IconCtaProps[]
  cta: React.ReactNode
}

const EmptyWrapper = styled.div`
  width: 36px;
`

const CTA: React.VFC<Props> = ({ menuItems, secondaryMultiselect, secondaryCTA, iconCTA, cta }) => {
  return (
    <div className="flex items-center space-x-3" onClick={(e) => e.stopPropagation()}>
      {cta}
      {iconCTA && (
        <div className="flex items-center">
          {iconCTA.map((icn, i) =>
            icn.showDefault ? (
              <EmptyWrapper key={i} />
            ) : icn.link ? (
              <Tooltip label={icn.tooltipLabel} key={i}>
                <a
                  href={icn.link}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  className={'cursor-pointer'}
                  data-testid={icn.dataTestId}
                >
                  <Typography key={i} color={'purple'} className="cursor-pointer p-2">
                    <i className={`far ${icn.icon} fa-fw`}></i>
                  </Typography>
                </a>
              </Tooltip>
            ) : (
              <Tooltip label={icn.tooltipLabel} key={i}>
                <Typography
                  key={i}
                  onClick={icn.onClick}
                  color={'purple'}
                  className="cursor-pointer p-2"
                  data-testid={icn.dataTestId}
                >
                  <i className={`far ${icn.icon} fa-fw`}></i>
                </Typography>
              </Tooltip>
            ),
          )}
        </div>
      )}
      {secondaryCTA && (
        <CabalButton
          component={secondaryCTA.link ? 'a' : 'button'}
          href={secondaryCTA.link ? secondaryCTA.link : ''}
          onClick={secondaryCTA.onClick ? secondaryCTA.onClick : undefined}
          target="_blank"
          variant={secondaryCTA.variant || 'secondary'}
          data-testid={secondaryCTA.dataTestId}
        >
          {secondaryCTA.text}
        </CabalButton>
      )}
      {secondaryMultiselect && (
        <DropDownMenu
          menuItems={secondaryMultiselect.menu}
          trigger={<CabalButton variant="link">{secondaryMultiselect.text}</CabalButton>}
        />
      )}
      {menuItems && (
        <DropDownMenu
          menuItems={menuItems}
          trigger={
            <Typography color={'fog'} data-testid="datacard-dropdown-menu-trigger">
              <i className="far fa-ellipsis-h"></i>
            </Typography>
          }
          dataTestId={'datacard-dropdown-menu'}
        />
      )}
    </div>
  )
}

export default CTA
