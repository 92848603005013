import MessageParsed from 'components/MessageParsed'
import Card from 'global/Card'
import React from 'react'
import { InboxMessageBlueprint } from 'utils/types'
import InboxMessageHeader from './InboxMessageHeader'

interface Props {
  message: InboxMessageBlueprint
  withHeaders?: boolean
}

const InboxMessage: React.VFC<Props> = ({ message, withHeaders = true }) => {
  return (
    <Card>
      <div className="flex flex-col gap-3">
        {withHeaders && <InboxMessageHeader message={message} />}
        <div className="whitespace-normal">
          <MessageParsed body={message.body} />
        </div>
      </div>
    </Card>
  )
}

export default InboxMessage
