import React, { useState } from 'react'

import { useInfiniteQuery } from 'react-query'
import { useSearchParam } from 'react-use'

import ConnectionsEmptyState from 'components/EmptyStates/ConnectionsEmptyState'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import { SearchInput } from 'global/Input'
import Loading from 'global/Loading'
import Tabs from 'global/Tabs'
import CabalTitle from 'global/Title'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'

import EmptyMembersImage from '../../images/empty-members.png'
import ConnectionsTable from '../Contacts/ConnectionsTable'

const MyConnections = ({ standalone = false, showLoading = true }) => {
  const searchQueryString = useSearchParam('q')
  const [query, setQuery] = useState(searchQueryString || '')
  const debouncedQuery = useDebouncedValue(query, 300)
  const [totalConnections, setTotalConnections] = React.useState(0)

  const [tabIndex, setTabIndex] = useState(0)
  const tabs = [
    {
      label: 'Connections',
      id: 'connections',
      component: '',
    },
  ]

  const connectionsQuery = useInfiniteQuery(
    ['my_connections', debouncedQuery],
    ({ pageParam: page = 1 }) => callApi(api.getMyConnections, debouncedQuery, page),
    {
      enabled: true,
      keepPreviousData: true,
      onSuccess: (data) => setTotalConnections(data.pages[0].pagination.total_count!),
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
    },
  )

  const connections = connectionsQuery.data?.pages?.flatMap((page) => page.connections)

  return (
    <div className={`pb-6 mb-4${standalone ? 'mx-8 mt-8 m-6 mb-10' : ''}`}>
      {standalone && <CabalTitle title="Connections" />}
      {standalone && <Tabs data={tabs} index={tabIndex} onChange={(index) => setTabIndex(index)} />}

      <div className="flex flex-row-reverse">
        <div className="w-full">
          <div className="mt-6 w-full flex flex-col h-full">
            <>
              {showLoading && connectionsQuery.isLoading && !connectionsQuery.isFetchingNextPage ? (
                <Loading />
              ) : debouncedQuery !== '' && connections && connections.length === 0 ? (
                <EmptyState
                  heading="No results found"
                  image={<img src={EmptyMembersImage} />}
                  body={
                    <Typography textAlign="center" component="h5" className="pt-4 px-2">
                      Sorry, no results found for {debouncedQuery}
                    </Typography>
                  }
                />
              ) : connections && connections.length > 0 ? (
                <>
                  <ConnectionsTable rows={connections} />

                  {/* <div className="mt-4 text-secondary ml-2 text-sm">
                    Showing {connections.length} of {totalConnections}
                    {totalConnections >= 10000 ? '+' : ''}
                  </div> */}

                  {connectionsQuery.hasNextPage && (
                    <div className="flex justify-center	mt-6">
                      <CabalButton
                        disabled={connectionsQuery.isFetchingNextPage}
                        onClick={() => connectionsQuery.fetchNextPage()}
                      >
                        {connectionsQuery.isFetchingNextPage ? 'Loading..' : 'Load more'}
                      </CabalButton>
                    </div>
                  )}
                </>
              ) : (
                <ConnectionsEmptyState />
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyConnections
