import React, { useState } from 'react'

import { useQuery } from 'react-query'

import { Divider } from 'components/SendMessage/styles'
import PillsContainer from 'containers/Home/IntroRequest/PillsContainer'
import { IntroConnection } from 'containers/Portfolio/MemberView/types/IntroConnection'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'

interface IProps {
  introRequestUuid: string
  action: string
  onHide: () => void
}

export function IntroRequestConfirmation({ introRequestUuid, action, onHide }: IProps) {
  const [introRequest, setIntroRequest] = useState<IntroConnection>()

  useQuery(
    ['actionIntroRequest', introRequestUuid],
    () =>
      callApi(
        action === 'accept' ? api.acceptIntroRequestV2 : api.declineIntroRequestV2,
        introRequestUuid,
      ),
    {
      onSuccess: (data) => {
        setIntroRequest(data)
      },
      onError: (error) => {
        console.log('Error', error)
      },
    },
  )

  const { data: declinedReasonsOptions, isLoading: declinedReasonsFetchLoading } = useQuery(
    ['declinedReasons'],
    () => callApi(api.getIntroRequestDeclinedReasons),
  )
  const { user } = useCurrentUser()

  const isFacilitator = introRequest?.facilitator.uuid === user.uuid
  const acceptedRequest = action === 'accept'

  const headerText = action === 'accept' ? 'accepted!' : 'declined'
  const actionIcon = action === 'accept' ? 'fa-circle-check text-green-500' : 'fa-ban text-red-500'
  const bottomIcon = action === 'accept' ? 'fa-arrows-h' : 'fa-ban'

  const acceptedText = (
    <div>
      <Typography color="fog" fontSize="16">
        You accepted the request from {introRequest?.user?.name}
      </Typography>
    </div>
  )
  const declinedTextFromFacilitator = (
    <p>
      We’ve let <span className="font-bold">{introRequest?.user?.name}</span> know you’re not able
      to make an intro to <span className="font-bold">{introRequest?.requestable?.name}</span>.
      <br />
      <br />
      Select a reason to provide feedback:
    </p>
  )

  const declinedTextFromTarget = (
    <p>
      The <span className="font-bold">{introRequest?.team_name}</span> team has been notified.
      <br />
      <br />
      Select a reason to provide feedback:
    </p>
  )

  const declinedText = isFacilitator ? declinedTextFromFacilitator : declinedTextFromTarget
  if (declinedReasonsFetchLoading) {
    return <Loading className="my-8" />
  }

  const reasonsArray: string[] = Object.values(declinedReasonsOptions)

  const avatars = (
    <div className="grid grid-cols-[1fr_auto_1fr] gap-6 mb-10 items-center">
      <div className="flex flex-col items-center text-center self-start">
        <Avatar name={introRequest?.user?.first_name} src={introRequest?.user?.avatar_url} />
        <Typography>{introRequest?.user.name} </Typography>
        <Typography fontSize="12" color="fog" className="max-w-[150px]">
          {introRequest?.user?.title}
        </Typography>
      </div>

      <Typography color="fog" className="flex justify-center">
        <i className={`fal ${bottomIcon} fa-xl`} />
      </Typography>

      <div className="flex flex-col items-center text-center self-start">
        <Avatar
          name={introRequest?.requestable?.name}
          src={introRequest?.requestable?.avatar_url}
        />
        <Typography>{introRequest?.requestable?.name}</Typography>
        <Typography fontSize="12" color="fog" className="max-w-[150px] text-center">
          {introRequest?.requestable?.headline}
        </Typography>
      </div>
    </div>
  )

  return (
    <Modal
      show
      onHide={onHide}
      centerActions={acceptedRequest && <CabalButton onClick={() => onHide()}>Close</CabalButton>}
    >
      <div className="flex flex-col items-center mt-8">
        <Typography fontSize="24" color="fog" className="mb-4">
          {acceptedRequest && <i className={`fas ${actionIcon} mr-2`} />}

          {`Request ${headerText}`}
        </Typography>
        <div className="mt-4">{action === 'decline' && avatars}</div>
        <Typography color="fog" fontSize="14" className="text-center">
          {acceptedRequest ? acceptedText : declinedText}
          {action === 'decline' && (
            <div className="flex flex-wrap justify-center items-center gap-2 mt-4">
              <PillsContainer reasonsArray={reasonsArray} requestIntroId={introRequestUuid} />
            </div>
          )}
        </Typography>
        <Typography color="fog" fontSize="14">
          {action === 'accept' ? `${introRequest?.team_name} will make an intro shortly.` : ''}
        </Typography>

        <div className="w-full mx-10">
          <Divider />
        </div>
        {acceptedRequest && avatars}
      </div>
    </Modal>
  )
}
