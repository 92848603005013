import merge from 'lodash/merge'

import { mainTheme } from './theme'

const LOGIN_WHITE = '#AAAAAA'
const WHITE = '#fff'
const OFF_WHITE = '#fafafa'
const RED = '#FF3333'
const PURPLE = '#798EE7'
const BLACK = '#111'
const DARK_GRAY = '#2d3748'
const SLATE_GRAY = '#687CA1'
const BORDER_GRAY = DARK_GRAY
const BORDER_DANGER = '#EF4444'
const GRADIENT_BLACK_START = '#1E2533'
const GRADIENT_BLACK_END = '#161C26'
const MIST = '#F7FAFC'

export const GRAY_100 = '#F7FAFC'
const GRAY_900 = '#1A202C'
const GRAY_900_50 = '#252C39'
const GRAY_SIDE_NAV = '#232C3A'
const GRAY_MAIN_SEC = '#2D3748'

const INDIGO_DARK = '#5C69D1'
const TEAL_300 = '#9AE684'
const TEAL_900 = '#234E52'

const LIGHT_GREEN = '#99E3D9'
const DARK_GREEN = '#2E4D51'
const MID_PURPLE = '#A8BFF5'
const DULL_WHITE = 'rgba(217, 232, 247, 0.39)'

const YELLOW = '#F6D845'
const YELLOW_BOLD = '#EFC945'
const GOLD = '#DDAF13'
const YELLOW_COUNT_BG = '#352A05'

const TEXT_SECONDARY = '#B6BABF'

const WIDGET_BG = 'rgba(26, 32, 44, 0.7)'
const WIDGET_TEXT = '#9CB0CF'

const SHOUTOUT_BG = 'rgba(41, 51, 66, 0.16)'
const BG_SECONDARY = 'rgba(41, 51, 66, 0.64)'

// New theme colors
const FOG = '#718096'
const MIDNIGHT = '#0E151F'
const REAL_BLACK = '#000'
const BLACK_RUSSIAN = '#191F2A'
const SHADOW = '#293342'
const RAIN = '#A7B4C7'
const PEPPERMINT = '#7FD991'

const HVAR = '#8A97FF'
const GRAY = '#718096'
const GREEN = '#53AF65'
const NEON = '#E4307C'
const LEMON = '#EFC945'
const EMERALD_DARK = '#11845B'
const WINSITE_GREEN = '#10845B'

const LIGHT_YELLOW = 'rgba(239, 201, 69, 0.08)'
const NAV_HIGHLIGHT = 'rgba(41, 51, 66, 0.4)'
const BUTTON_HOVER = 'rgba(92, 105, 209, 0.16)'
const PURPLE_TINT = '#2A324B'

const BORDER = '1px solid #2d3748'

const COMPOSER_BG = 'rgba(41, 51, 66, 0.32)'

export const darkTheme = merge(JSON.parse(JSON.stringify(mainTheme)), {
  name: 'dark',
  layout: {
    main_content_bg_color: MIDNIGHT,
    main_bg_color: MIDNIGHT,
    header_nav_bg_color: GRAY_900,
    header_nav_text_color: OFF_WHITE,
    bg_gradient_start: GRADIENT_BLACK_START,
    bg_gradient_end: GRADIENT_BLACK_END,
    island_bg_color: SHADOW,

    // New theme colors
    nav_bg_color: BLACK_RUSSIAN,
  },
  modal: {
    underlay_opacity: '0.70',
    content_bg: OFF_WHITE,
    close_icon_color: GRAY,
    underlay_bg: REAL_BLACK,
  },
  chart: {
    line: SHADOW,
    tooltip: {
      bg: 'rgba(41, 51, 66, 0.95)',
      border: SHADOW,
    },
  },
  filter: {
    bg_color: SHADOW,
    active_bg_color: WHITE,
    active_text_color: INDIGO_DARK,
    border_color: MIDNIGHT,
    count_bg: SHADOW,
  },
  inline_offers: {
    text_area: SHADOW,
    background: SHADOW,
    activity_bg: SHADOW,
    inline_bg: GRAY_900,
  },
  pill: {
    neon: {
      primary_bg: 'rgba(228, 48, 124, 0.08)',
      primary_text: NEON,
      active_bg: '', // missing
      active_text: '', // missing
    },
    purple: {
      primary_bg: BG_SECONDARY,
      active_bg: INDIGO_DARK,
      primary_text: MID_PURPLE,
      active_text: WHITE,
    },
    purple_filled: {
      primary_bg: BUTTON_HOVER,
      active_bg: INDIGO_DARK,
      primary_text: MID_PURPLE,
      active_text: WHITE,
    },
    green: {
      primary_bg: BG_SECONDARY,
      active_bg: '#54929A',
      primary_text: '#54929A',
      active_text: WHITE,
    },
    count: {
      primary_text: FOG,
      active_text: WHITE,
    },
    queue: {
      primary_text: MID_PURPLE,
    },
    border: '#374151',
  },
  referrals: {
    bg_color: '#4B4B4B',
    bg_card: BG_SECONDARY,
  },
  advisor_metrics: {
    bg_color: BLACK,
    bar_bg: '#283141',
    allocation_bar_bg: PURPLE,
    vested_bar_bg: GREEN,
  },
  table: {
    bg_color: GRAY_900_50,
  },
  side_nav: {
    bg_color: MIDNIGHT,
    border_color: BLACK,
    active_item_bg_color: SHADOW,
    item_bg_color: 'inherit',
    active_item_text_color: PURPLE,
    item_text_color: GRAY_100,
    item_body_text: TEXT_SECONDARY,
    counter_bg_color: YELLOW_COUNT_BG,
    counter_text_color: YELLOW_BOLD,
    right_icon_color: SLATE_GRAY,
  },
  login: {
    text_color: LOGIN_WHITE,
  },
  buttons: {
    default: {
      text_color: GRAY,
      bg_color: BG_SECONDARY,
      hover_text_color: WHITE,
    },
    primary: {
      text_color: WHITE,
      bg_color: INDIGO_DARK,
      bg_hover: '#4C5ACC',
      disabled_bg_color: GRAY_900,
      hover_text_color: BLACK,
      hover_composer: MIDNIGHT,
      focus: MID_PURPLE,
    },
    secondary: {
      text_color: MID_PURPLE,
      bg_color: BUTTON_HOVER,
      disabled_bg_color: 'red',
      hover_text_color: BLACK,
      hover_composer: BUTTON_HOVER,
      bg_hover: PURPLE_TINT,
    },
    tertiary: {
      text_color: WHITE,
      bg_color: BUTTON_HOVER,
      bg_hover: PURPLE_TINT,
    },
    feed: {
      bg_color: BUTTON_HOVER,
      bg_hover: PURPLE_TINT,
    },
    destroy: {
      bg_color: '#E13131',
      bg_hover: '#BF1C1C',
    },
  },
  labels: {
    green: {
      bg_color: DARK_GREEN,
      text_color: LIGHT_GREEN,
    },
  },
  theme_picker: {
    primary_bg: BLACK_RUSSIAN,
    selected_bd: '#2C3249',
  },
  like_dislike: {
    default: {
      like_bg: 'rgba(83, 175, 101, 0.16)',
      dislike_bg: '#374151',
    },
    like: {
      like_bg: '#53AF65',
    },
    dislike: {
      dislike_bg: '#6B7280',
    },
  },
  colors: {
    default_color: '#0E151F',

    // like_dislike button
    shadow_icon: '#4B5563',
    default_icon: '#9CA3AF',

    // defaults
    primary: GRAY_100,
    mid_purple: MID_PURPLE,
    secondary: OFF_WHITE,
    primary_bg: NAV_HIGHLIGHT,
    border: DARK_GRAY,
    border_danger: BORDER_DANGER,
    tint: PURPLE_TINT,
    gray100: GRAY_100,

    // sfdc table header / pill border
    table_header: '#181E29',
    pill_border: '#374151',

    // status
    wait: YELLOW_BOLD,
    done: PEPPERMINT,

    // wiget colors
    widget_bg: WIDGET_BG,
    widget_title: FOG,
    widget_text: WIDGET_TEXT,
    shoutout_bg: SHOUTOUT_BG,
    widget_border: SHADOW,

    // message rows
    message_date: FOG,
    message_sender: MID_PURPLE,
    message_receiver: '#CBD5E0',
    message_read: DULL_WHITE,

    // add company
    add_bg: '#222A45',
    add_txt: MID_PURPLE,

    // Rain / Fog Toggle
    rain_fog: FOG,
    fog_rain: RAIN,
    rain: RAIN,

    placeholder: FOG,

    // composer
    composer_bg: COMPOSER_BG,
    banner_text: RAIN,
    banner_border: FOG,

    // colors
    purple: HVAR,
    light_gray: RAIN,
    gray: GRAY,
    white: '#fff',
    black: REAL_BLACK,
    light_black: MIDNIGHT,
    green: GREEN,
    light_green: LIGHT_GREEN,
    red: RED,
    yellow: LEMON,
    light_yellow: LIGHT_YELLOW,
    yellow_bold: YELLOW_BOLD,
    gold: GOLD,
    transparent: 'transparent',
    neon: NEON,
    shadow: SHADOW,
    light_shadow: COMPOSER_BG,
    complete: PEPPERMINT,
    fog: FOG,
    twitter_blue: '#1DA1F2',
    mist: MIST,

    // dashboard
    dash_bg: SHADOW,

    //text
    text_secondary: TEXT_SECONDARY,
    admin_label: RAIN,

    cardBackground: BLACK_RUSSIAN,
    cardHover: SHADOW,
    pills: '#cab6cc',

    // tab border
    tab_border: SHADOW,

    // tooltip
    tooltip_color: WHITE,
    tooltip_bg: GRAY_900_50,

    // home pill
    home_pill: SHADOW,

    // review stars
    star_select: LEMON,

    link: MID_PURPLE,
    option_card_bg: SHADOW,
    emerald_dark: EMERALD_DARK,
    winsite_green: WINSITE_GREEN,
  },
  popup: {
    background: GRADIENT_BLACK_START,
    select: NAV_HIGHLIGHT,
  },

  highlight: BUTTON_HOVER,

  table_header: '#171E2A',
  table_child: '#171E2A',
  table_element_border: SHADOW,

  sizes: {
    container_lg: '905px',
    container_sm: '600px',
  },
  fw: {
    light: 200,
    offWhite: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 600,
    black: 800,
  },
  border: BORDER,
  card_border: BORDER,
  pinned_border: '1px solid rgba(239, 201, 69, 0.4)',

  breakpoints: {
    a: '1280px',
    a1: '1100px',
    b: '930px',
    restricted: '990px',
    mobile: '500px',
  },
})
