import React, { useState, VFC } from 'react'
import Avatar from 'global/Avatar'

import Typography from 'global/Typography'
import CabalButton from 'global/CabalButton'
import AddModal from 'containers/WidgetSidebar/Shoutout/AddModal'
import { AdvisorModel, CompanySlugParam } from 'utils/types'
import { ModalContext } from 'global/Modal/Context'
import { useParams, useHistory } from 'react-router-dom'

interface Props {
  name: string
  url: string
  description: string
  cta: string
  uuid: string
  invert?: boolean
}

const Col: React.VFC<Props> = ({ name, url, description, cta, uuid, invert = false }) => {
  const { showModal } = React.useContext(ModalContext)
  const { company_slug } = useParams<CompanySlugParam>()
  const history = useHistory()

  const shoutoutModal = (resolve: (val?: boolean) => void, name: string) => {
    return (
      <AddModal
        show
        onHide={() => resolve(false)}
        onSave={() => resolve(false)}
        companySlug={company_slug}
        defaultShoutout={name}
      />
    )
  }

  const triggerCTA = (cta: string, name: string) => {
    if (cta === 'Shoutout') {
      showModal((resolve) => shoutoutModal(resolve, name), 'shoutout-modal')
    } else if (cta === 'Connections') {
      history.push({
        pathname: `/${company_slug}/people/${uuid}/connections`,
      })
    }
  }

  return (
    <>
      <div className="flex justify-between items-center pt-4">
        <div className="flex items-center">
          <Avatar src={url} name={name} size="32" round />

          <div className="ml-2.5">
            <Typography fontSize="14" fontWeight={600} component="p">
              {name}
            </Typography>
            <Typography fontSize="12" color="fog_rain" component="p">
              {description}
            </Typography>
          </div>
        </div>

        {invert ? (
          <CabalButton
            variant="secondary"
            size="small"
            rightIcon={<i className="far fa-megaphone"></i>}
            onClick={() => triggerCTA(cta, name)}
          />
        ) : (
          <CabalButton variant="secondary" size="small" onClick={() => triggerCTA(cta, name)}>
            {cta}
          </CabalButton>
        )}
      </div>
    </>
  )
}

export default Col
