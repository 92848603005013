import * as React from 'react'

import compact from 'lodash/compact'
import CabalMessage from 'models/CabalMessage'
import moment from 'moment'
import pluralize from 'pluralize'
import emoji from 'react-easy-emoji'
import { useHistory, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import ItemCardV2 from 'components/Block/ItemCard'
import { useComposer } from 'components/Composer'
import TimeAgo from 'components/TimeAgo'
import Pill from 'global/Pill'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import { cn } from 'utils/styles'
import { SCHEDULE_DATE_FORMAT } from 'utils/templates'
import { BlockItemCard, BlockItemCardDescriptionPart, CompanySlugParam } from 'utils/types'

interface Props {
  message: CabalMessage
  group?: any
  reloadMessages: () => void
  onClick?: () => void
  company_slug?: string
  showSnippet?: boolean
  selected?: boolean
  minimalView?: boolean
  returnToGlobalMail?: boolean
  onClickUrl?: string
}

const IconTypography = styled(Typography)`
  &:hover {
    color: ${({ theme }) => theme.colors.default_color};
  }
`

const MessageCard: React.FC<Props> = (props) => {
  const {
    message,
    minimalView = false,
    onClick,
    onClickUrl,
    reloadMessages,
    returnToGlobalMail = true,
    selected,
  } = props
  const [isHidden, setIsHidden] = React.useState(false)
  const { company_slug: company_slug_from_url } = useParams<CompanySlugParam>()

  const company_slug = props.company_slug || company_slug_from_url || message.rawMessage.team_slug
  const { compose } = useComposer(company_slug)

  if (isHidden) return null

  const handleDelete = (e) => {
    e.preventDefault()
    e.stopPropagation()
    api.deleteMessage(company_slug, message.uuid).then(() => {
      setIsHidden(true)
      cabalToast({ style: 'success', content: 'Message deleted' })
    })
  }

  const markAsArchived = (e) => {
    e.preventDefault()
    e.stopPropagation()
    api.markAsArchived(message.uuid).then(() => {
      setIsHidden(true)
      cabalToast({ style: 'success', content: 'Message archived' })
    })
  }

  let participantsText = ''

  if (!!message.recipients?.length && message.recipients[0].label) {
    participantsText = `To: ${message.recipients[0].label}`

    if (message.recipients.length > 2) {
      participantsText += ` and ${message.recipients.length - 1} ${pluralize(
        'other',
        message.recipients.length - 1,
      )}`
    }
  }

  let messageLink = onClickUrl

  if (message.draft) {
    messageLink = `/${company_slug}/compose/${message.uuid}?return_to_global_mail=${returnToGlobalMail}`
  } else {
    messageLink = message.detached
      ? `/${company_slug}/messages/${message.root_message_uuid}/${message.root_message_thread_uuid}`
      : `/${company_slug}/messages/${message.uuid}`
  }

  const metadata = compact([
    {
      id: 'from',
      type: 'string',
      label: `From: ${message.sender.name}`,
    },
    !!participantsText && {
      id: 'to',
      type: 'string',
      label: participantsText,
    },
    {
      id: 'users',
      type: 'string',
      label: (
        <>
          <i className="far fa-users mr-2" />
          {`${message?.creator_user?.first_name}`}
        </>
      ),
    },
    message.request_company && {
      id: 'companies',
      type: 'string',
      label: (
        <>
          <i className="far fa-building mr-2" />
          {message.request_company}
        </>
      ),
    },
    message.schedule_at && {
      id: 'scheduled',
      type: 'string',
      label: (
        <Pill variant="neon" border={false}>
          <i className="far fa-clock mr-2" />
          {moment(message.schedule_at).format(SCHEDULE_DATE_FORMAT)}
        </Pill>
      ),
    },
    message.comment_count &&
      message.comment_count > 0 && {
        id: 'comment_count',
        type: 'string',
        label: (
          <>
            <i className="far fa-comment mr-2" />
            {message.comment_count}
          </>
        ),
      },
  ])

  const hoverIconMenuItems = [
    {
      label: (
        <Tooltip label="Archive">
          <IconTypography color="fog_rain">
            <i className="fa-regular fa-box-archive"></i>
          </IconTypography>
        </Tooltip>
      ),
      handler: markAsArchived,
    },
    {
      label: (
        <Tooltip label="Delete">
          <IconTypography color="fog_rain">
            <i className="far fa-trash-can" />
          </IconTypography>
        </Tooltip>
      ),
      handler: handleDelete,
    },
  ]

  if (!message.draft) {
    hoverIconMenuItems.push({
      label: (
        <Tooltip label="Use as draft">
          <IconTypography color="fog_rain">
            <i className="far fa-pen-to-square fa-fw" />
          </IconTypography>
        </Tooltip>
      ),
      handler: (e) => {
        e.preventDefault()
        e.stopPropagation()
        compose({ draft_from: message.uuid })
      },
    })
  }

  const itemView: BlockItemCard = {
    title: (
      <Typography className="truncate w-full" color={'fog'}>
        <Typography fontWeight={600} color="primary">
          {emoji(message.subject || '(No Subject)')}
        </Typography>
        <Typography className="ml-2" fontSize="12" lineHeight={'15px'}>
          {message.snippet}
        </Typography>
      </Typography>
    ),
    description_parts: metadata,
    title_url: !minimalView && !returnToGlobalMail ? messageLink : undefined,
    on_click:
      minimalView || returnToGlobalMail
        ? {
            type: 'func',
            handler: onClick,
          }
        : undefined,
    secondary_content: <TimeAgo datetime={message.created_at} />,
    hover_icon_menu: hoverIconMenuItems,
  }

  let itemCardClassName =
    'relative border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none'

  if (selected) {
    itemCardClassName = cn(itemCardClassName, 'shadow-md z-10 border-x-[1px]')
  } else {
    itemCardClassName = cn(itemCardClassName, ' hover:shadow-md hover:z-10 hover:border-x-[1px]')
  }

  return <ItemCardV2 itemView={itemView} className={itemCardClassName} />
}

export default MessageCard
