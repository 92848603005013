import Pill from 'global/Pill'
import Typography from 'global/Typography'
import React, { useState } from 'react'
import styled from 'styled-components'

const TitleWrapper = styled.button`
  & .shortcut {
    display: none;
  }
  &:hover {
    & .shortcut {
      display: inline-block;
    }
  }
`

interface Props {
  onClick?: () => void
  title: string
  shortcut?: string
  active?: boolean
  onClickEdit?: () => void
}

const TitleWrappers: React.VFC<Props> = ({ onClick, onClickEdit, title, shortcut, active }) => {
  return (
    <TitleWrapper
      className="flex w-full justify-between items-center cursor-pointer"
      onClick={onClick}
      data-testid="personalize-accordian-trigger"
    >
      <Typography
        textTransform="uppercase"
        fontSize="12"
        letterSpacing={'0.05em'}
        fontWeight={500}
        color="fog_rain"
        lineHeight={2.25}
      >
        {title}
      </Typography>
      <div>
        {shortcut && (
          <Pill className="mr-1 shortcut" variant="purple_filled">
            {shortcut}
          </Pill>
        )}

        {onClick && (
          <>
            {active ? (
              <Typography letterSpacing={'0.05em'} fontWeight={500} color="rain" fontSize="12">
                <i className="fas fa-chevron-down fa-fw"></i>
              </Typography>
            ) : (
              <Typography letterSpacing={'0.05em'} fontWeight={500} color="rain" fontSize="12">
                <i className="fas fa-chevron-right fa-fw"></i>
              </Typography>
            )}
          </>
        )}

        {onClickEdit && (
          <Typography letterSpacing={'0.05em'} fontWeight={500} color="rain" fontSize="12" onClick={() => onClickEdit()} className="cursor-pointer">
            <i className="fas fa-pencil fa-fw"></i>
          </Typography>
        )}
      </div>
    </TitleWrapper>
  )
}

export default TitleWrappers
