import React from 'react'

import { useQuery } from 'react-query'

import JobsDataTags from 'containers/CompanyPage/SharedListsSection/JobsDataTags'
import JobsEditOrAddModal from 'containers/ListIndexView/TalentList/JobsList/JobsEditOrAddModal'
import { IJob } from 'containers/ListIndexView/TalentList/JobsList/types'
import Avatar from 'global/Avatar'
import Modal, { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'
import GridWrapper from 'ui-components/GridWrapper'

import api, { callApi } from 'utils/api'
import { Team, User } from 'utils/types'
import { InvestorCompany } from 'utils/types/investor'

interface Props {
  teamSlug: string
  team: Team
  user: User
  onHide: () => void
  portfolioCompany: InvestorCompany
}

const ManageFounderJobsModal: React.FC<Props> = ({
  teamSlug,
  onHide,
  portfolioCompany,
  team,
  user,
}) => {
  const { showModal } = useModal()
  const gridView = true // in case we implement a toggle for list view

  const { data: jobsData, refetch: refetchJobs } = useQuery(
    ['sharedJobs', team.slug],
    () => callApi(api.sharedJobs, team.slug),
    {
      enabled: !user?.team || user.team?.slug !== team.slug,
    },
  )

  const jobs = jobsData?.jobs

  const showEditJobModal = (jobUuid: string) => {
    showModal(
      (resolve) => (
        <JobsEditOrAddModal
          jobUuid={jobUuid}
          company={portfolioCompany}
          onHide={resolve}
          teamSlug={teamSlug}
          refetchListItems={refetchJobs}
          listUuids={[]}
          showListDropdown={false}
          memberAdding={true}
        />
      ),
      'edit-job-modal',
    )
  }

  const renderJobItem = (job: IJob) => {
    return (
      <DataCardV2
        key={job.uuid}
        title={job.title}
        avatar={
          <Avatar
            src={
              job.investor_company
                ? `https://logo.clearbit.com/${job.investor_company.domain}`
                : undefined
            }
            name={job.title}
            size={gridView ? '72' : '32'}
          />
        }
        description={
          (!gridView &&
            job.description &&
            new DOMParser().parseFromString(job.description, 'text/html').body.textContent) ||
          ''
        }
        descriptionWithoutTruncate={
          <p className="line-clamp-3 mb-2">
            {(job.description &&
              new DOMParser().parseFromString(job.description, 'text/html').body.textContent) ||
              ''}
          </p>
        }
        view={gridView ? 'grid' : 'list'}
        gridPillsSection={<JobsDataTags job={job} />}
        dropDownMenu={{
          menuItems: [
            {
              label: 'Edit',
              onSelect: () => showEditJobModal(job.uuid!),
            },
          ],
        }}
      />
    )
  }

  return (
    <div>
      <Modal onHide={onHide} show={true} dangerouslyBypassFocusLock>
        <Typography fontSize="14" fontWeight={600} className="mt-4 mb-2" component="p">
          Manage Submitted Jobs
        </Typography>
        <div className="mt-10 mb-10 max-h-[480px] overflow-y-auto">
          <GridWrapper className="grid-cols-2 sm:grid-cols-2">
            {jobs.map((job) => renderJobItem(job))}
          </GridWrapper>
        </div>
      </Modal>
    </div>
  )
}

export default ManageFounderJobsModal
