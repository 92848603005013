import { useRef } from 'react'
import { useQuery } from 'react-query'
import api, { callApi } from 'utils/api'
import ErrorLogger from 'utils/ErrorLogger'
import { CreateMessageRequest } from 'utils/types'

export const useComposeQuery = (teamSlug: string) => {
  const getMessagePropsRef = useRef<string>()
  const createMessagePropsRef = useRef<CreateMessageRequest>()

  const getMessageQuery = useQuery(
    ['compose', 'get'],
    () => callApi(api.getDraftMessage, teamSlug!, getMessagePropsRef.current!),
    {
      enabled: false,
    },
  )

  const createMessageQuery = useQuery(
    ['compose', 'create'],
    () => callApi(api.createDraftMessage, teamSlug!, createMessagePropsRef.current!),
    {
      enabled: false,
    },
  )

  const message = (getMessageQuery.data || createMessageQuery.data)?.message

  const resetQueryCache = () => {
    getMessageQuery.remove()
    createMessageQuery.remove()
  }

  const refetchMessage = () => {
    resetQueryCache()

    if (!message) {
      throw ErrorLogger.error("cant figure out the message in useComposeQuery's refetchMessage")
    }

    getMessagePropsRef.current = message?.uuid
    getMessageQuery.refetch()
  }

  const getMessage = (uuid: string) => {
    resetQueryCache()

    getMessagePropsRef.current = uuid
    getMessageQuery.refetch()
  }

  const createMessage = (req: CreateMessageRequest) => {
    resetQueryCache()

    createMessagePropsRef.current = req
    createMessageQuery.refetch()
  }

  return {
    message,
    getMessage,
    createMessage,
    gettingMessage: getMessageQuery.isLoading,
    creatingMessage: createMessageQuery.isLoading,
    refetchMessage,
  }
}
