import React, { useEffect, useState } from 'react'

import Highlighter from 'react-highlight-words'
import { ThreeDots } from 'react-loader-spinner'
import { useMutation } from 'react-query'
import { useTheme } from 'styled-components'

import { StyledCard } from 'containers/CompanyPage/SharedListsSection/ConnectionRow'
import Avatar from 'global/Avatar'
import Loading from 'global/Loading'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'
import { ConnectionBlueprint, GlobalPersonBlueprint, VoteType } from 'utils/types'

interface Props {
  connection?: ConnectionBlueprint
  highlightWords: string[]
  person: GlobalPersonBlueprint
  preview?: boolean
  teamSlug: string
  vote?: VoteType | null
  hideOfferButton?: boolean
  ssid?: string
  personRowCta?: string
  onPersonRowCtaClick?: (person: { uuid: string }) => void
  isLoading?: boolean
}

const MemberRow: React.VFC<Props> = ({
  teamSlug,
  connection,
  person,
  highlightWords,
  vote = 0,
  ssid,
  onPersonRowCtaClick,
  personRowCta,
  isLoading,
}) => {
  const theme = useTheme()
  const [localVote, setLocalVote] = useState<VoteType>(vote || 0)
  const googleConnection = connection?.source_type === 'Contact'
  const linkedinConnection = connection && !googleConnection
  const [animateLike, setAnimateLike] = React.useState(false)
  const [selectedPerson, setSelectedPerson] = useState<GlobalPersonBlueprint | null>(null)

  const { mutate: sendVote, isLoading: sendingVote } = useMutation(
    ['createVote'],
    (vote: VoteType) =>
      callApi(api.createVote, {
        team_slug: teamSlug,
        votable_type: 'GlobalPerson',
        votable_id: person.uuid,
        vote,
        company_id: null,
        ssid: ssid,
      }),
    {
      onSuccess: (_, variables) => {
        setLocalVote(variables)
      },
    },
  )

  useEffect(() => {
    setTimeout(() => {
      setAnimateLike(false)
    }, 1000)
  }, [animateLike])

  let cta

  if (personRowCta) {
    cta = (
      <div
        className="flex items-center justify-end w-[82px]"
        onClick={() => {
          onPersonRowCtaClick?.(person)
          setSelectedPerson(person)
        }}
      >
        {localVote === 1 && sendingVote && (
          <div className="mr-1">
            <Loading size={12} strokeWidth={5} />
          </div>
        )}
        <Typography
          color={localVote === 1 ? 'purple' : 'default_icon'}
          lineHeight="1"
          fontSize="12"
          className="flex items-center"
        >
          <span className="like-button">{personRowCta}</span>
        </Typography>
      </div>
    )
  } else {
    cta = (
      <div
        className="flex items-center justify-end w-[82px]"
        onClick={() => {
          sendVote(localVote === 0 ? 1 : 0)
          setAnimateLike(localVote === 0 ? true : false)
        }}
      >
        {localVote === 1 && sendingVote && (
          <div className="mr-1">
            <Loading size={12} strokeWidth={5} />
          </div>
        )}
        <Typography
          color={localVote === 1 ? 'purple' : 'default_icon'}
          lineHeight="1"
          fontSize="12"
          className="flex items-center"
        >
          <span className="like-button">{localVote === 1 ? 'Offered' : 'Offer intro'}</span>
        </Typography>
        <Typography
          color={localVote === 1 ? 'purple' : 'default_icon'}
          lineHeight="1"
          fontSize="16"
          className="flex items-center pl-2 like-icon-internal"
          component="p"
        >
          {localVote === 1 ? (
            <i className="fas fa-thumbs-up" />
          ) : (
            <i className="far fa-thumbs-up" />
          )}
        </Typography>
      </div>
    )
  }

  return (
    <>
      <StyledCard
        animate={animateLike}
        onClick={() => {
          sendVote(localVote === 0 ? 1 : 0)
          setAnimateLike(localVote === 0 ? true : false)
        }}
        avatar={
          person.image_url ? (
            <Avatar name={person.name} src={person.image_url} size={'32'} round />
          ) : (
            <i className="far fa-user" />
          )
        }
        titleIcon={
          <>
            {linkedinConnection && (
              <Typography
                color="fog"
                fontSize="12"
                component="a"
                href={person.linkedin_url}
                target="_blank"
              >
                <i className="fab fa-linkedin fa-fw"></i>
              </Typography>
            )}
            {googleConnection && (
              <Typography fontSize="12" color="fog">
                <i className="fab fa-google fa-fw"></i>
              </Typography>
            )}
            <Typography color="fog_rain" fontSize="12" lineHeight="1">
              ·
            </Typography>
            {connection && (
              <Tooltip label={`You are connected to ${person.name}`}>
                <Typography
                  color="fog_rain"
                  fontSize="12"
                  className="block cursor-pointer"
                  lineHeight="1"
                >
                  1st
                </Typography>
              </Tooltip>
            )}
          </>
        }
        title={
          <>
            <Highlighter
              searchWords={highlightWords}
              textToHighlight={person.name}
              highlightStyle={{
                backgroundColor: theme.highlight,
                color: theme.colors.primary,
              }}
            />
          </>
        }
        description={
          <Highlighter
            searchWords={highlightWords}
            textToHighlight={person.headline || ''}
            highlightStyle={{
              backgroundColor: theme.highlight,
              color: theme.colors.rain_fog,
            }}
          />
        }
        cta={
          isLoading && selectedPerson?.uuid === person.uuid ? (
            <ThreeDots
              visible={true}
              height="25"
              width="25"
              radius="9"
              ariaLabel="three-dots-loading"
              color={theme.colors.purple}
            />
          ) : (
            cta
          )
        }
      />
    </>
  )
}

export default MemberRow
