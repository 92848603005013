import React, { useRef, useState } from 'react'

import isEqual from 'lodash/isEqual'
import Expand from 'react-expand-animated'
import { useHotkeys } from 'react-hotkeys-hook'
import { useHistory, useParams } from 'react-router-dom'

import { useComposer } from 'components/Composer'
import EditInvestorCompanyModal from 'containers/Portfolio/EditInvestorCompanyModal'
import CabalButton from 'global/CabalButton'
import DropDownMenu, { DropdownMenuItem } from 'global/DropDownMenu'
import { useModal } from 'global/Modal/Context'
import { DataCard, IconCtaProps, OwnerUserProps } from 'ui-components/DataCard'

import useTeamSetting from 'utils/hooks/useTeamSetting'
import { CompanySlugParam } from 'utils/types'
import { InvestorCompany, InvestorCompanyJob } from 'utils/types/investor'

import CompanyJobs from './CompanyJobs'

interface Props {
  company: InvestorCompany
  companies: InvestorCompany[]
  allTags: string[]
  setAllTags: (tags: string[]) => void
  setCompanies: (companies: InvestorCompany[]) => void
  selected: boolean
  toggleSelectedCompany: (uuid: string, selected: boolean) => void
  jobs: InvestorCompanyJob[]
  allJobs: InvestorCompanyJob[]
  setJobs: (jobs: InvestorCompanyJob[]) => void
  isLoadingJobs: boolean
}

const CompanyRow: React.FC<Props> = (props) => {
  const {
    company,
    companies,
    contributions,
    setCompanies,
    allTags,
    setAllTags,
    toggleSelectedCompany,
    jobs,
    setJobs,
    allJobs,
    allStages,
    setAllStages,
    isLoadingJobs,
    selected,
  } = props

  const history = useHistory()
  const { compose } = useComposer()
  const [expand, setExpand] = useState(false)
  const { company_slug } = useParams<CompanySlugParam>()
  const { showModal, closeModal } = useModal()
  const [enablePortfolioJobs] = useTeamSetting(company_slug, 'enable_portfolio_jobs')

  const menuItems: DropdownMenuItem[] = []

  if (company.data?.twitter_url) {
    menuItems.push({
      label: 'Twitter',
      onSelect: () => window.open(company.data?.twitter_url),
      dataTestId: 'portfolio-company-twitter-url',
    })
  }

  if (company.data?.linkedin_url || company.linkedin_url) {
    menuItems.push({
      onSelect: () =>
        window.open(company.data?.linkedin_url) ||
        window.open(`http://www.${company.linkedin_url}`),
      label: 'LinkedIn',
      dataTestId: 'portfolio-company-linkedin-url',
    })
  }
  if (company.domain) {
    menuItems.push({
      onSelect: () => window.open(`http://www.${company.domain}`),
      label: 'Company website',
      dataTestId: 'portfolio-company-web-url',
    })
  }

  useHotkeys('esc', (e) => {
    e.preventDefault()
    closeModal('edit-investor-company-modal')
  })

  const iconCTAs: IconCtaProps[] = []

  iconCTAs.push({
    icon: 'far fa-pencil',
    onClick: () => {
      showModal(
        (r) => (
          <EditInvestorCompanyModal
            company={company}
            companies={companies}
            setCompanies={setCompanies}
            teamSlug={company_slug}
            resolve={r}
            allTags={allTags}
            setAllTags={setAllTags}
            allStages={allStages}
            setAllStages={setAllStages}
          />
        ),
        'edit-investor-company-modal',
      )
    },
    tooltipLabel: 'Edit company',
    dataTestId: 'edit-portfolio-company-btn',
  })

  let ownerUserData: OwnerUserProps[] = []

  if (company.owners && company.owners.length > 0) {
    ownerUserData = company.owners.map((o) => ({ name: o.name, url: o.avatar_url || '' }))
  }

  return (
    <>
      <DataCard
        dataCardTestId={'portfolio-company-row'}
        onCardClick={enablePortfolioJobs ? () => setExpand(!expand) : undefined}
        title={company.company_name}
        avatarName={company.company_name}
        avatarUrl={
          company.domain ? `https://logo.clearbit.com/${company.domain}` : company.logo_url
        }
        tags={company.tags}
        crm_tags={company.crm_tags}
        checkbox={
          enablePortfolioJobs
            ? {
                checked: selected,
                onCheck: (e) => {
                  toggleSelectedCompany(company.uuid, e.target.checked)
                },
                dataTestId: 'select-portfolio-company-checkbox',
              }
            : undefined
        }
        menuItems={menuItems}
        iconCTA={iconCTAs}
        ownerUser={enablePortfolioJobs && ownerUserData.length > 0 ? ownerUserData : undefined}
        info={
          <div className="flex items-center">
            {/* {!company.shared_lists?.length && (
              <CabalButton
                size="tiny"
                onClick={(e) => {
                  e.stopPropagation()
                  compose({
                    template_key: 'request-pipeline',
                    recipients: { advisor_uuids: company.advisors?.map((a) => a.uuid) },
                  })
                }}
              >
                Request Pipeline
              </CabalButton>
            )} */}
            {(company.shared_lists?.length || 0) === 1 && (
              <CabalButton
                size="tiny"
                variant="secondary"
                onClick={() =>
                  history.push(`/${company_slug}/lists/${company.shared_lists[0].slug}`)
                }
              >
                View Pipeline
              </CabalButton>
            )}
            {(company.shared_lists?.length || 0) > 1 && (
              <DropDownMenu
                trigger={
                  <CabalButton size="tiny" variant="secondary">
                    View Pipeline
                  </CabalButton>
                }
                menuItems={company.shared_lists.map((l) => ({
                  label: l.name,
                  onSelect: () => history.push(`/${company_slug}/lists/${l.slug}`),
                }))}
              />
            )}
          </div>
        }
        description={!!contributions?.total ? `${contributions.total} contributions` : undefined}
      />
      <Expand open={expand}>
        <CompanyJobs
          teamSlug={company_slug}
          jobs={jobs.filter((j) => !j.archived)}
          isFetching={isLoadingJobs}
          setJobs={setJobs}
          allJobs={allJobs}
          companies={companies}
          data-testid="portfolio-company-expand-jobs"
        />
      </Expand>
    </>
  )
}

export default React.memo(CompanyRow, isEqual)
