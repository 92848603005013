import { Activity } from 'utils/types'

export const LOGGED_ACTIVITY_TYPES: { label: string; value: Activity['activity_type'] }[] = [
  { label: 'Intro', value: 'sales' },
  { label: 'Social Boost', value: 'marketing' },
  // { label: '💼 Recruiting', value: 'recruiting' },
  // { label: '💡 Advice', value: 'advice' },
  // { label: 'Intro', value: 'intro' },
  // { label: 'Social Boost', value: 'social_boost' },
  { label: 'Other', value: 'other' },
]

export const TALENT_ACTIVITY_TYPES = [
  { label: 'Intro Sent', value: 'request_intro' },
  { label: 'Intro Accepted', value: 'accept_intro' },
  { label: 'Intro Declined', value: 'decline_intro' },
  { label: 'Intro Rerouted', value: 'reroute_intro' },
  { label: 'Introduction Made', value: 'introduced' },
  { label: 'Company Added', value: 'add_company' },
  { label: 'Job Added', value: 'add_job' },
  { label: 'Job Updated', value: 'update_job' },
  { label: 'Portfolio Viewed', value: 'view_portfolio' },
  { label: 'Portfolio Shared', value: 'share_portfolio' },
  { label: 'Company Shared', value: 'share_company' },
  { label: 'Job Shared', value: 'share_job' },
  { label: 'Candidate Shared', value: 'share_candidate' },
  { label: 'Introduce Member', value: 'introduce_company_to_candidate' },
  { label: 'Request Intro To Candidate', value: 'request_intro_to_candidate' },
]

export const TWITTER_ACTIVITY_TYPES = [
  { label: 'Retweet', value: 'tweet_retweet' },
  { label: 'Twitter Like', value: 'tweet_like' },
  { label: 'Twitter Mention', value: 'tweet_mention' },
  { label: 'Twitter Mention Like', value: 'tweet_mention_like' },
]

export const USER_ACTIVITY_TYPES = [{ label: 'Viewed Deal Board', value: 'view_deal_board' }]

export const VOTE_ACTIVITY_TYPES = [
  { label: 'Connection Liked', value: 'connection_liked' },
  { label: 'Company Liked', value: 'company_liked' },
]

export const DRAFT_SENT_ACTIVITY_TYPES = [{ label: 'Draft Sent', value: 'draft_sent' }]

export const ACTIVITY_TYPES = [
  ...LOGGED_ACTIVITY_TYPES,
  ...USER_ACTIVITY_TYPES,
  ...VOTE_ACTIVITY_TYPES,
  ...DRAFT_SENT_ACTIVITY_TYPES,
]
