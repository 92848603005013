import styled from 'styled-components'

const Background = styled.div`
  background-color: #fefefe;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: scroll;
  background-image: url(/noise-v2.png);

  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;

  h1,
  h2,
  h3,
  h4,
  h5,
  blockquote,
  b,
  strong,
  section {
    font-family: 'Inter', Helvetica, sans-serif;
    color: #0e151f;
  }

  button {
    background: #5c69d1;
    color: #fff;
    border-radius: 4px;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    margin: 12px 0;
    height: 48px;
    box-sizing: border-box;
    width: 100%;
  }

  button:hover {
    background: #2f3ca7;
  }

  button:disabled,
  button[disabled] {
    opacity: 0.5;
  }
`

export { Background }
