import React from 'react'

import moment from 'moment'

import MessageParsed from 'components/MessageParsed'
import CandidateNoteModal from 'containers/CandidateProfile/CandidateNoteModal'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import { useCurrentUser } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'

import { CandidateNote } from 'utils/types'

const NoteRow = (props: {
  note: CandidateNote
  teamSlug: string
  reloadCandidateProfile: () => void
}) => {
  const { showModal } = useModal()
  const { user: currentUser } = useCurrentUser()

  const isAuthor = props.note.user.uuid === currentUser.uuid

  const renderModal = () => {
    return showModal(
      (resolve) => (
        <CandidateNoteModal
          candidateUuid={props.note.candidate_profile.uuid}
          teamSlug={props.teamSlug}
          onHide={resolve}
          noteData={props.note}
          action={isAuthor ? 'edit' : 'view'}
          onFinalize={() => {
            props.reloadCandidateProfile()
            resolve()
          }}
        />
      ),
      'render_candidate_note',
    )
  }

  return (
    <DataCardV2
      avatar={<i className="fa-thin fa-note" />}
      title={<MessageParsed body={props.note.subject} />}
      description={[
        <>{moment(props.note.updated_at).format('MMMM YYYY')}</>,
        <>{props.note.user.name}</>,
        <>
          {props.note.body.length > 30 ? `${props.note.body.substring(0, 30)}...` : props.note.body}
        </>,
      ]}
      cta={
        <CabalButton
          variant="link"
          padding={'0'}
          onClick={() => {
            renderModal()
          }}
        >
          View
        </CabalButton>
      }
      onClick={() => renderModal()}
    />
  )
}

export default NoteRow
