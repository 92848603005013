import React, { Ref, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useSetState } from 'react-use'

import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Modal, { useModal } from 'global/Modal'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { InvestorCompany } from 'utils/types/investor'

import EditInvestorCompanyModal from '../EditInvestorCompanyModal'

interface Props {
  teamSlug: string
  resolve: (company?: InvestorCompany) => void
  allTags: string[]
  setAllTags: (tags: string[]) => void
  companiesRef: React.MutableRefObject<InvestorCompany[] | undefined>
  setCompanies: (companies: InvestorCompany[]) => void
}

const EditCompanyModal: React.VFC<Props> = ({
  resolve,
  teamSlug,
  allTags,
  setAllTags,
  stages,
  setAllStages,
  companiesRef,
  setCompanies,
}) => {
  const { showModal } = useModal()
  const [companyState, setCompanyState] = useSetState<Partial<InvestorCompany>>()

  const { mutate: addCompany, isLoading } = useMutation(
    ['addCompany'],
    () => {
      return callApi(api.createInvestorCompany, companyState as InvestorCompany, teamSlug)
    },
    {
      onSuccess: ({ company }) => {
        setCompanies([...(companiesRef.current || []), company])
        cabalToast({ style: 'success', content: 'Company was successfully created!' })
        autofillInvestorCompany(company)
        setCompanyState({ ...company })
        resolve()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
        resolve()
      },
    },
  )

  const { mutate: autofillInvestorCompany } = useMutation(
    ['autofillInvestorCompany'],
    (company: InvestorCompany) => {
      return callApi(api.autofillInvestorCompany, company, teamSlug)
    },
    {
      onSuccess: ({ company }) => {
        setCompanyState({ ...company })
        showModal(
          (r) => (
            <EditInvestorCompanyModal
              company={{ ...companyState, ...company }}
              companies={companiesRef.current!}
              setCompanies={setCompanies}
              teamSlug={teamSlug}
              resolve={r}
              allTags={allTags}
              setAllTags={setAllTags}
              allStages={allStages}
              setAllStages={setAllStages}
            />
          ),
          'edit-investor-company-modal',
        )
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong while fetching details!' })
      },
    },
  )

  const handleSave = async () => {
    addCompany()
  }

  const canSave = !!companyState.company_name && !!companyState.domain

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        e.preventDefault()
        if (!canSave) return
        handleSave()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [canSave])

  return (
    <Modal
      show
      onHide={() => resolve()}
      header="Add Company"
      rightActions={
        <CabalButton
          disabled={!canSave}
          onClick={handleSave}
          working={isLoading}
          data-testid="add-company-modal-save-btn"
        >
          Save
        </CabalButton>
      }
    >
      <TextInput
        defaultValue={companyState.company_name}
        onChange={(e) => setCompanyState({ company_name: e.currentTarget.value })}
        placeholder="Name"
        className="mb-3"
        valueType="non-empty"
        labeled
        data-testid="add-company-modal-company-name"
      />
      <TextInput
        defaultValue={companyState.domain || ''}
        onChange={(e, v) =>
          setCompanyState({
            domain: v ? e.currentTarget.value : null,
          })
        }
        placeholder="Website"
        valueType="website"
        required
        className="mb-3"
        labeled
        data-testid="add-company-modal-company-domain"
      />
    </Modal>
  )
}

export default EditCompanyModal
