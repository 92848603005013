import React, { useState } from 'react'
import Avatar from 'global/Avatar'
import { cabalToast } from 'ui-components/Toast'

import Modal from 'global/Modal'
import { CheckBox, TextInput, SearchInput } from 'global/Input'
import api from 'utils/api'
import Typography from 'global/Typography'
import { AdvisorModel, CompanySlugParam } from 'utils/types'
import CabalButton from 'global/CabalButton'
import { useParams } from 'react-router-dom'
import { useAdvisors } from 'store/hooks'

interface props {
  show: boolean
  onHide: () => void
  onSave: () => void
  companySlug: string
  defaultShoutout?: string
}

const AddModal = ({ show, onHide, onSave, companySlug, defaultShoutout }: props) => {
  const [query, setQuery] = useState('')
  const [filteredAdvisors, setAdvFilter] = useState<AdvisorModel[]>([])
  const [description, setDescription] = useState('')
  const [selectedAdv, setSelectedAdv] = useState<AdvisorModel>()
  const [notify, setNotify] = useState(false)
  const [working, setWorking] = useState(false)

  const { advisors } = useAdvisors({
    teamSlug: companySlug,
  })

  React.useEffect(() => {
    if (!defaultShoutout) return
    setQuery(defaultShoutout)
  }, [])

  React.useEffect(() => {
    if (!advisors) return
    const filtered_advisors = filter(advisors)
    setAdvFilter(filtered_advisors)
  }, [query])

  React.useEffect(() => {
    if (!advisors) return
    setAdvFilter(advisors)
  }, [advisors])

  const filter = (advisors: AdvisorModel[]) => {
    if (query != '') {
      return advisors.filter((advisor) => {
        return advisor.name?.toLowerCase().includes(query.toLowerCase())
      })
    } else {
      return advisors
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!selectedAdv) return

    setWorking(true)
    api
      .createShoutOuts(companySlug, {
        advisor_id: selectedAdv.uuid,
        message: description,
        notify_adv: notify,
      })
      .then(() => {
        cabalToast({ style: 'success', content: 'Successfully added the shout out' })
        onSave()
      })
      .finally(() => setWorking(false))
  }

  const disabled = !selectedAdv

  return (
    <Modal
      show={show}
      onHide={onHide}
      header="Add Shout Outs"
      rightActions={
        <CabalButton
          working={working}
          variant="primary"
          disabled={disabled}
          onClick={handleSubmit}
          data-testid="save-shoutout"
        >
          Save
        </CabalButton>
      }
    >
      <div className="w-full">
        <SearchInput autoFocus value={query} onChange={(e) => setQuery(e.target.value)} />
        <div className="h-48 pt-2 mt-2 w-full overflow-auto custom-scrollbar">
          {filteredAdvisors.map((advisor) => (
            <div key={advisor.uuid} className="flow-root py-2 border-b border-primary">
              <Avatar
                name={advisor.name}
                src={advisor.avatar_url || ''}
                size={'30'}
                round
                className="float-left mr-3 block"
              />
              <div className="pt-1 inline-block float-left">{advisor.name}</div>
              <div className="pt-1 pr-2 float-right">
                {advisor.uuid === selectedAdv?.uuid ? (
                  <div
                    className="text-red-500 hover:text-red-700 cursor-pointer"
                    onClick={() => {
                      setQuery('')
                      setSelectedAdv(undefined)
                    }}
                  >
                    <i className="far fa-times" />
                    <span className="ml-1">Remove</span>
                  </div>
                ) : (
                  <div
                    className="text-green-500 hover:text-green-700 cursor-pointer"
                    data-testid="select-member"
                    onClick={() => {
                      if (!advisor.name) return
                      setQuery(advisor.name)
                      setSelectedAdv(advisor)
                    }}
                  >
                    <i className="far fa-plus" />
                    <span className="ml-1">Add</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="pt-1 flex">
          <div className="font-bold mb-1 mt-3 pr-1 text-xs">{`Add Shout Outs`}</div>
          <div className=" mb-1 mt-3 text-xs">{`(60 Characters)`}</div>
        </div>
        <TextInput
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter Description"
          className="w-full"
          maxLength={60}
          data-testid="shout-out-description"
        />
        <div className="pt-2">
          <Typography fontSize="12">
            <CheckBox
              label={'Notify advisor that they’ve received a shout out'}
              checked={notify}
              onChange={() => setNotify(!notify)}
              data-testid="notify-member"
            />
          </Typography>
        </div>
      </div>
    </Modal>
  )
}

export default AddModal
