import moment from 'moment'
import { CurrentUserProfile, Params, RequestModel } from './types'

/* eslint-disable max-len */
const Templates = {
  social: {
    subject: `Share on Social?`,
    body: `Hi {{first_name}},
    <br><br>Can you help us get our news out? Link: [NEWS LINK]
    <br><br>Sharing on social helps us sell more and recruit faster. Draft post below if helpful.
    <br><br>Appreciate it!`,
  },

  feedback: {
    subject: `Feedback?`,
    body: `Hi {{first_name}},
    <br><br>Here's what we've shipped over the last few weeks:
    <ul>
    <li> </li>
    <li> </li>
    </ul>
    LMK if you have any questions or feedback.`,
  },

  meet: {
    subject: `Let's Meet?`,
    body: `Hi {{first_name}},
    <br><br>Do you have some time to meet later this week or next?
    <br><br>You can grab some time on my calendar below.
    <br><br>Thank you 👍
    `,
  },

  updates: {
    subject: `Investor Update - [Month]`,
    body: `Hi {{first_name}},
    <br><br>📈 Key Metrics:
    <ul>
    <li> </li>
    <li> </li>
    </ul>
    <br> 👍 Highlights:
    <ul>
    <li> </li>
    <li> </li>
    </ul>
    <br> 👎  Lowlights:
    <ul>
    <li> </li>
    <li> </li>
    </ul>
    <br> 🏦 Financials:
    <ul>
    <li><b>Cash balance<b>: </li>
    <li><b>Burn this month<b>: </li>
    <li><b>Runway<b>: </li>
    </ul>
    <br> 🛠 Product:
    <ul>
    <li> </li>
    <li> </li>
    </ul>
    <br> 💂‍♀️ Team:
    <ul>
    <li> </li>
    <li> </li>
    </ul>
    <br> ⭐️ Shout Outs:
    <ul>
    <li> </li>
    <li> </li>
    </ul>
    <br> 🎯 Current Focus:
    <ul>
    <li> </li>
    <li> </li>
    </ul>

    Thanks!
    `,
  },

  extension: {
    subject: 'Share your connections w/ [team]',
    body: `
    Hey {{first_name}}, would you mind sharing your connections with us by installing the <a href="${window.CHROME_EXTENSION_URL}">Cabal chrome ext</a>? It will help us map who you may be connected to at our target companies. Shouldn&apos;t take more than 30 seconds.
    <br>
    <br>
    Appreciate it!
    `,
  },

  refer_a_prospect: {
    subject: '',
    body: '',
  },
}

export const replaceVariables = (
  string: string,
  props: {
    request?: Pick<RequestModel, 'company' | 'data' | 'person'>
    params?: Params
    user?: CurrentUserProfile
  },
) => {
  if (!string) return string

  if (props.user) string = string.replaceAll('{{referral_link}}', props.user?.referral_link || '')

  if (props.request) {
    string = string
      .replaceAll('{{connection_company}}', props.request?.company?.name || '')
      .replaceAll(
        '{{connection_full_name}}',
        props.request?.data?.person_name ||
          props.request?.person.name ||
          '{{connection_full_name}}',
      )
      .replaceAll(
        '{{connection_first_name}}',
        props.request?.person.first_name || '{{connection_first_name}}',
      )
      .replaceAll(
        '{{connection_linkedin_url}}',
        props.request?.data?.person_linkedin_url ||
          props.request?.person.linkedin_url ||
          '{{connection_linkedin_url}}',
      )
  }

  if (props.params) {
    for (const param in props.params) {
      string = string.replaceAll(`{{${param}}}`, props.params[param])
    }
  }

  return string
}

export const SCHEDULE_DATE_FORMAT = 'MMM D, LT'

export const getTomorrowMorningDate = () => {
  return moment().startOf('day').add(1, 'day').hour(8)
}

export const getTomorrowAfternoonDate = () => {
  return moment().startOf('day').add(1, 'day').hour(13)
}

export default Templates
