import React from 'react'

import styled from 'styled-components'

import DropDownMenu from 'global/DropDownMenu'
import Typography from 'global/Typography'
import { UpdatedDropDownProps } from 'ui-components/DataCardV2'

const OptionWrap = styled.div<{ active: boolean }>`
  background: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.purple};
  }
`

const Option = ({
  onClick,
  title,
  icon,
  active,
  dropDownMenu,
  description,
}: {
  onClick: () => void
  title: string | React.ReactNode
  icon?: React.ReactNode
  active: boolean
  dropDownMenu?: UpdatedDropDownProps
  description?: React.ReactNode
}) => {
  return (
    <OptionWrap
      className="flex flex-col justify-between cursor-pointer h-[120px] px-4 py-2.5 rounded-lg"
      onClick={onClick}
      active={active}
      // data-testid={props['data-testid']}
    >
      <div className="flex justify-between -mr-1.5">
        <Typography color="purple" fontSize="20">
          {icon}
        </Typography>

        {dropDownMenu && (
          <div onClick={(e) => e.stopPropagation()}>
            <DropDownMenu
              menuItems={dropDownMenu.menuItems}
              trigger={
                dropDownMenu.trigger ?? (
                  <Typography
                    className="px-2"
                    color={'fog'}
                    data-testid="datacard-dropdown-menu-trigger"
                  >
                    <i className="far fa-ellipsis-v"></i>
                  </Typography>
                )
              }
            />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <Typography color="purple" lineHeight={1} fontSize="14" className="mt-2">
          <i className="fas fa-plus fa-xs mr-1" />
          {title}
        </Typography>
        {description}
      </div>
    </OptionWrap>
  )
}

export default Option
