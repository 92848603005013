import React from 'react'

import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { TextArea, TextInput } from 'global/Input'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import copyToClipboard from 'utils/copyToClipboard'

import Header from './Header'
import { Card, ContinueWrapper, InputWrapper } from './styles'

const Link = styled.div`
  ${tw`px-2 py-1 rounded border flex flex-grow justify-between items-center cursor-pointer`}
  background-color: ${({ theme }) => theme.layout.main_bg_color};
  border-color: ${({ theme }) => theme.layout.main_bg_color};
  border: 1px solid ${({ theme }) => theme.colors.border};
`

const CopyIcon = styled.button`
  color: ${({ theme }) => theme.colors.rain_fog};
  :hover {
    color: ${({ theme }) => theme.colors.purple};
  }
`

interface Props {
  onContinue: () => void
}

const Invite: React.FC<Props> = ({ onContinue }) => {
  const { user } = useCurrentUser()
  const { team } = useTeam(user.team!.slug || null)
  const history = useHistory()
  const [emails, setEmails] = React.useState('')
  const { isAdminOrEmployee } = useAccessControl(user.team?.slug)

  const handleCopy = () => {
    copyToClipboard(`getcabal.com/${user.team?.slug}` || '')
    cabalToast({ style: 'success', content: 'Link copied to clipboard' })
  }

  const advisorBulkInviteMutation = useMutation(
    (emails: string[]) =>
      callApi(api.advisorBulkInvite, { team_slug: user.team?.slug || '', emails }),
    {
      onSuccess: () => {
        onContinue()
      },
    },
  )

  const handleSubmit = () => {
    const emailList = emails.split(',')
    advisorBulkInviteMutation.mutate(emailList)
  }

  return (
    <Card>
      {team?.logo && (
        <div className="flex items-center justify-center mb-2">
          <Avatar src={team.logo} size={'48'} />
        </div>
      )}
      <Header title="Invite your teammates" subtitle="" />
      {isAdminOrEmployee && (
        <InputWrapper>
          <TextArea
            rows={4}
            className="w-full"
            placeholder="email@company.com, email@company.com"
            value={emails}
            disabled={advisorBulkInviteMutation.isLoading}
            onChange={(e) => setEmails(e.target.value)}
          />
        </InputWrapper>
      )}

      <ContinueWrapper className="flex flex-col">
        <CabalButton
          onClick={handleSubmit}
          padding="8px 32px"
          disabled={emails === ''}
          className="w-1/3"
        >
          Invite
        </CabalButton>
        <CabalButton
          data-testid="invite-continue"
          onClick={() => onContinue()}
          variant="tertiary"
          className="mt-2"
        >
          Skip
        </CabalButton>
      </ContinueWrapper>
    </Card>
  )
}

export default Invite
