import React from 'react'

import { useParams } from 'react-router-dom'

import Tracking from 'containers/Settings/Company/Tracking'
import CabalButton from 'global/CabalButton'
import { CheckBox, Label, MultiSelect } from 'global/Input'
import Typography from 'global/Typography'
import { useAppDispatch, useTeam } from 'store/hooks'
import SectionHeader from 'ui-components/SectionHeader'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import { CompanySlugParam, Team } from 'utils/types'

import { CardBg } from '../styles'
import FooterToggle from './FooterToggle'
import MessageVisibility from './MessageVisibility'

export interface TeamSettingProps {
  team: Team
  reloadTeam: () => Promise<any>
  updateTeam: (team: Partial<Team>) => void
  updatingTeam: boolean
}

const Company = () => {
  return (
    <div className="flex flex-col gap-4 mt-4">
      <Tracking />
      <MessageVisibility />
      <FooterToggle />
    </div>
  )
}

export default Company
