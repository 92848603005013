import React from 'react'

import { CompanyLink, InvestorLink } from './styles'

const Footer = () => {
  return (
    <div className="">
      <div className="max-w-6xl mx-auto pb-20 pt-20 sm:pt-40 flex flex-col-reverse sm:flex-row justify-between sm:items-center  text-white">
        <p className="pt-8 sm:pt-0">
          <i className="far fa-copyright" /> Cabal
        </p>
        <div className="flex flex-col sm:flex-row gap-8">
          <CompanyLink href="/companies" className="">
            For Companies
          </CompanyLink>
          <InvestorLink href="/investors" className="">
            For VCs
          </InvestorLink>
          <a
            href="/resources"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#ffffffad] tansition-all duration-300 ease-in-out"
          >
            Resources
          </a>
          <a
            href="/blog"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#ffffffad] tansition-all duration-300 ease-in-out"
          >
            Blog
          </a>
          <a
            href="https://twitter.com/GetCabal"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#ffffffad] tansition-all duration-300 ease-in-out"
          >
            Follow @getcabal
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
