import * as React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import Typography, { TypographyProps } from './Typography'

interface WrapperProps {
  variant:
    | 'purple'
    | 'green'
    | 'green_filled'
    | 'purple_filled'
    | 'neon'
    | 'outline'
    | 'yellow'
    | 'gray'
  active?: boolean
  rounded?: boolean
  border?: boolean
}

const Wrapper = styled(Typography)<WrapperProps>`
  ${tw`px-1.5 py-0.5 inline-flex items-center relative rounded`}

  background-color: ${({ theme, variant: color, active }) =>
    active ? theme.pill[color].active_bg : theme.pill[color].primary_bg};
  color: ${({ theme, variant: color, active }) =>
    active ? theme.pill[color].active_text : theme.pill[color].primary_text};
  ${({ border, theme }) => border && `border: 1px solid ${theme.pill.border};`}
  ${({ rounded }) => (rounded ? tw`rounded-full` : tw`rounded`)}
`

Wrapper.defaultProps = {
  border: true,
}

interface CountWrapperProps {
  active: boolean
}

const CountWrapper = styled(Typography)<CountWrapperProps>`
  ${tw`pl-1 `}

  color: ${({ theme, active }) =>
    active ? theme.pill.count.active_text : theme.pill.count.primary_text};
`

export interface Props extends WrapperProps {
  count?: number
  fontSize?: '12' | '14'
  facePile?: React.ReactNode
  fontFamily?: TypographyProps['fontFamily']
}

const Pill: React.FC<Props & Omit<JSX.IntrinsicElements['div'], 'ref' | 'color'>> = ({
  children,
  count,
  fontSize = '12',
  facePile,
  fontFamily = 'mono',
  ...extraProps
}) => {
  return (
    <Wrapper
      {...extraProps}
      component="div"
      fontSize={fontSize}
      fontFamily="mono"
      fontWeight={400}
      lineHeight="1.2"
    >
      {facePile}
      <span>
        {children}
        {!!count && (
          <CountWrapper fontSize="10" active={!!extraProps.active}>
            {count}
          </CountWrapper>
        )}
      </span>
    </Wrapper>
  )
}

export default Pill
