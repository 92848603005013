import { compact, omit } from 'lodash'
import React, { useCallback, useRef } from 'react'
import { useQueryClient } from 'react-query'

import { useModal } from 'global/Modal/Context'
import { useAdvisors, useCurrentUser, useGroups, useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import useColorMode from 'utils/hooks/useColorMode'
import Templates from 'utils/templates'
import {
  AdvisorModel,
  Contact,
  GroupModel,
  LimitedUser,
  MessageModel,
  MessageRecipient,
  Params,
} from 'utils/types'

import { recipientValue } from '.'
import { ComposerStatesContextProvider } from './Context'
import ComposerModal from './Modal'
import { useComposeQuery } from './useComposeQuery'

interface CommonProps {
  onHide?: () => void
  onSubmit?: (m: MessageModel) => void
  team_slug?: string
}

export interface ExistingMessageProps extends CommonProps {
  messageUuid: string
}

type Recipients = {
  advisors?: AdvisorModel[]
  /**
   * using this is not recommended, prefer `advisors`
   *
   * will fail if used when user dont have permission to access advisors
   */
  advisor_uuids?: string[]

  users?: LimitedUser[]
  /**
   * using this is not recommended, prefer `users`
   *
   * will fail if used when user dont have permission to access users
   */
  user_uuids?: string[]

  groups?: GroupModel[]
  /**
   * using this is not recommended, prefer `groups`
   *
   * will fail if used when user dont have permission to access groups
   */
  group_uuids?: string[]

  contacts?: Contact[]

  emails?: string[]
}

type GetComposerProps = {
  template?: keyof typeof Templates
  templateVariables?: Params
  onMessageLoad?: (m: MessageModel) => void
} & ComposeProps

export interface NewMessageProps
  extends Omit<Partial<MessageModel>, 'uuid' | 'recipients' | 'cc' | 'template' | 'from_request'>,
    CommonProps {
  body?: string
  subject?: string
  template?: keyof typeof Templates
  template_uuid?: string | null
  template_key?: string | null
  drafter_uuid?: string | null
  draft_from?: string | null
  from_request?: string | null
  recipients?: Recipients
  cc?: Recipients
  templateVariables?: Params
  person_id?: any
  connection_id?: any
  company_id?: any
  sender_uuid?: string
}

export type ComposeProps = ExistingMessageProps | NewMessageProps

/**
 * @deprecated
 * don't use this directly, use useComposer from ../Composer
 * renders the composer modal
 */
export const useComposer = (teamSlug?: string) => {
  const { showModal } = useModal()
  const { isMobile } = useColorMode()
  const queryClient = useQueryClient()
  const lastReceivedComposerPropsRef = useRef<ComposeProps>()

  const _temaSlug = useTeamSlug()
  if (!teamSlug) teamSlug = _temaSlug

  const { user } = useCurrentUser()
  const { advisors: allAdvisors } = useAdvisors({ teamSlug })
  const { groups: allGroups } = useGroups(teamSlug, true)
  const { team } = useTeam(teamSlug)
  const allUsers = team?.admins_and_members

  const { getMessage, createMessage } = useComposeQuery(teamSlug)

  const buildRecipients = useCallback(
    (rawRecipients?: Recipients) => {
      if (!rawRecipients) return []

      for (const key in rawRecipients) {
        const data = rawRecipients[key as keyof Recipients]
        if (data) {
          Object.assign(rawRecipients, {
            [key]: compact(data as any),
          })
        }
      }

      const recipients: MessageRecipient[] = []

      if (rawRecipients?.advisor_uuids) {
        recipients.push(
          ...rawRecipients.advisor_uuids.map((uuid) => {
            const advisor = allAdvisors!.find((a) => a.uuid === uuid)
            if (!advisor) {
              throw {
                name: 'Unknown advisor',
                value: {
                  advisor_uuid: uuid,
                  recipients: rawRecipients,
                },
              }
            }

            return recipientValue({ advisor })
          }),
        )
      }

      if (rawRecipients?.advisors) {
        recipients.push(...rawRecipients.advisors.map((a) => recipientValue({ advisor: a })))
      }

      if (rawRecipients?.group_uuids) {
        recipients.push(
          ...rawRecipients.group_uuids.map((uuid) => {
            const group = allGroups!.find((a) => a.uuid === uuid)
            if (!group) {
              throw {
                name: 'Unknown group',
                value: {
                  group_uuid: uuid,
                  recipients: rawRecipients,
                },
              }
            }

            return recipientValue({ group })
          }),
        )
      }

      if (rawRecipients?.groups) {
        recipients.push(...rawRecipients.groups.map((a) => recipientValue({ group: a })))
      }

      if (rawRecipients?.user_uuids) {
        recipients.push(
          ...rawRecipients.user_uuids.map((uuid) => {
            const user = allUsers!.find((a) => a.uuid === uuid)
            if (!user) {
              throw {
                name: 'Unknown user',
                value: {
                  user_uuid: uuid,
                  recipients: rawRecipients,
                },
              }
            }

            return recipientValue({ user })
          }),
        )
      }

      if (rawRecipients?.users) {
        recipients.push(...rawRecipients.users.map((a) => recipientValue({ user: a })))
      }

      if (rawRecipients?.emails) {
        recipients.push(...rawRecipients.emails.map((a) => recipientValue({ email: a })))
      }

      if (rawRecipients?.contacts) {
        recipients.push(...rawRecipients.contacts.map((a) => recipientValue({ contact: a })))
      }

      return recipients
    },
    [allAdvisors, allGroups, allUsers],
  )

  const defaultOnSubmit = (uuid: string) => {
    cabalToast({
      content: 'Message Sent!',
      style: 'success',
      cta: {
        variant: 'tertiary',
        content: 'view message',
        component: 'link',
        className: 'underline',
        to: `/${teamSlug}/messages/${uuid}`,
      },
    })
    queryClient.refetchQueries([teamSlug, 'drafts'])
    queryClient.refetchQueries([teamSlug, 'getMessages'])
  }

  const openComposerModal = useCallback(
    (props: GetComposerProps) => {
      showModal((resolve) => {
        return (
          <ComposerStatesContextProvider>
            <ComposerModal
              teamSlug={teamSlug!}
              onHide={() => {
                resolve()
                props?.onHide?.()
              }}
              allowAdvisors
              allowGroups
              allowScheduledSend
              onReconnect={() => {
                resolve()
                compose(lastReceivedComposerPropsRef.current)
              }}
            />
          </ComposerStatesContextProvider>
        )
      }, 'compose-modal')
    },
    [showModal, teamSlug],
  )

  /**
   * add Tab for composer
   */
  const compose = useCallback(
    async (props?: ComposeProps, style: 'tab' | 'modal' = 'modal') => {
      lastReceivedComposerPropsRef.current = props
      if (!props) props = {}

      if (!teamSlug) teamSlug = props.team_slug

      if (!teamSlug)
        throw "`teamSlug` was not passed as a prop and it couldn't be detected from params"

      // if (isOpen('composer')) {
      //   alert('Please close the current composer before opening a new one')
      //   return
      // }

      if (!isExistingMessage(props)) {
        createMessage({
          draft: true,
          ...omit(props, 'template', 'recipients', 'cc'),
          recipients: buildRecipients(props.recipients),
          cc: buildRecipients(props.cc),
        })
      } else {
        getMessage(props.messageUuid)
      }

      // if (isMobile || style === 'tab') {
      // openComposerTab(props)
      // } else {
      openComposerModal(props)
      // }
    },
    [
      // isOpen,
      isMobile,
      // openComposerTab,
      openComposerModal,
      createMessage,
      buildRecipients,
      getMessage,
    ],
  )

  return { compose }
}

interface WithUseComposerProps extends Params {
  teamSlug?: string
}

export type composeMessage = ReturnType<typeof useComposer>['compose']

export const withUseComposer = (Component: React.ComponentType<WithUseComposerProps>) => {
  return (props: WithUseComposerProps) => {
    const { compose } = useComposer(props.teamSlug)

    return <Component compose={compose as composeMessage} {...props} />
  }
}

function isExistingMessage(
  props: ExistingMessageProps | NewMessageProps,
): props is ExistingMessageProps {
  return (props as ExistingMessageProps).messageUuid !== undefined
}
