import React from 'react'

import { useHistory } from 'react-router-dom'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'

const AddConnectionsRow = () => {
  const history = useHistory()

  return (
    <div>
      <DataCardV2
        onClick={() => window.open('/addconnections', '_blank', 'noopener noreferrer')}
        title={'Add your connections'}
        description={'Add your connections to turn your network into an asset.'}
        avatar={
          <Typography color="purple">
            <i className="far fa-arrows-left-right"></i>
          </Typography>
        }
        cta={
          <div className="-mx-2">
            <CabalButton
              onClick={() => window.open(`/addconnections`, '_blank', 'noopener noreferrer')}
            >
              Add connections
            </CabalButton>
          </div>
        }
      />
    </div>
  )
}

export default AddConnectionsRow
