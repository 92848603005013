import React, { useCallback } from 'react'

import pluralize from 'pluralize'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useTimeoutFn } from 'react-use'
import { FixedSizeList } from 'react-window'
import styled from 'styled-components'

import AdvisorInviteModal from 'components/AdvisorInvite'
import { SaveMemberModal } from 'components/SaveMemberModal'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useAdvisors, useAppDispatch, useTeam } from 'store/hooks'
import { removeAdvisor } from 'store/reducers/advisorsReducer'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { AdvisorModel, ConnectionStats, Team } from 'utils/types'

const StyledCard = styled(DataCardV2)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.cardBackground};
`

interface Props {
  team: Team
  filteredAdvisors: AdvisorModel[]
  linkedinConnectionStats?: ConnectionStats['linkedin']
  onAdvisorSelect: (m: AdvisorModel[]) => void
  selectedAdvisors: AdvisorModel[]
}

const MemberCard: React.FC<Props> = ({
  team,
  filteredAdvisors,
  linkedinConnectionStats,
  onAdvisorSelect,
  selectedAdvisors,
}) => {
  const histroy = useHistory()
  const { isLoading: isLoadingTeam, reloadTeam } = useTeam(team.slug)
  const { reloadAdvisors } = useAdvisors({ teamSlug: team.slug })
  const { advisor_connection_count: advisorConnectionCount } = linkedinConnectionStats || {}
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = React.useState(0)

  const dispatch = useAppDispatch()

  const { showModal } = useModal()

  const inviteModal = useCallback(
    (resolve: (val?: boolean) => void, m: AdvisorModel) => {
      return <AdvisorInviteModal onHide={() => resolve()} teamSlug={team.slug} advisors={[m]} />
    },
    [team.slug],
  )

  useTimeoutFn(() => {
    if (!wrapperRef.current) return

    const { offsetTop } = wrapperRef.current
    const vh = window.innerHeight
    const tableHeight = vh - offsetTop - 10
    setTableHeight(tableHeight)

    wrapperRef.current.style.height = `${vh - offsetTop - 10}px`
  }, 300)

  const editAdvisorModal = useCallback(
    (resolve: (val?: boolean) => void, m: AdvisorModel) => {
      return (
        <SaveMemberModal
          show
          onHide={() => resolve(false)}
          advisor={m}
          team={team}
          isEditing
          onSave={() => {
            reloadAdvisors()
            reloadTeam()
            resolve(false)
          }}
          onDelete={() => {
            reloadAdvisors()
            reloadTeam()
            resolve(false)
          }}
        />
      )
    },
    [team, reloadAdvisors, reloadTeam],
  )

  const getDescription = (advisor: AdvisorModel) => {
    const description: React.ReactNode[] = [advisor.email]
    const connectionCount = advisorConnectionCount?.[advisor.uuid] ?? 0
    const formattedConnectionCount = connectionCount.toLocaleString()
    description.push(
      <div>
        <i className="far fa-users" /> {advisor.groups.length ?? 0}{' '}
        {pluralize('Group', advisor.groups.length)}
      </div>,
      <div>
        <i className="far fa-arrows-left-right" /> {formattedConnectionCount}{' '}
        {pluralize('Connection', connectionCount)}
      </div>,
    )

    if (connectionCount === 0) {
      description.push(
        <Typography
          color="purple"
          onClick={() => {
            showModal((resolve) => inviteModal(resolve, advisor), 'send-invite-modal')
          }}
        >
          Send Invite
        </Typography>,
      )
    }
    return description
  }

  const handleSelection = (advisor: AdvisorModel, selected: boolean) => {
    if (selected) {
      onAdvisorSelect([...selectedAdvisors, advisor])
    } else {
      onAdvisorSelect(selectedAdvisors.filter((a) => a.uuid !== advisor.uuid))
    }
  }

  const { mutate: deleteAdvisor } = useMutation(
    (uuid: string) => callApi(api.deleteAdvisor, uuid).then(() => dispatch(removeAdvisor(uuid))),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: `Advisor has been deleted.` })
      },
      onError: () => {
        cabalToast({ style: 'error', content: `Failed to delete advisor.` })
      },
    },
  )

  const handleDelete = (advisor: AdvisorModel) => {
    const uuid = advisor.uuid

    if (
      confirm('Are you sure you want to delete this advisor?') &&
      prompt("Please type CONFIRM if you'd like to continue deleting this advisor.") === 'CONFIRM'
    ) {
      deleteAdvisor(uuid)
    }
  }

  const renderAdvisorCard = React.memo(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const advisor = filteredAdvisors[index]
      const descripton = getDescription(advisor)
      const selected = !!selectedAdvisors.find((a) => a.uuid === advisor.uuid)
      const menu = [
        {
          label: 'Edit',
          onSelect: () => {
            showModal((resolve) => editAdvisorModal(resolve, advisor), 'edit-advisor-modal')
          },
        },
        {
          label: 'Send Invite',
          onSelect: () => {
            showModal((resolve) => inviteModal(resolve, advisor), 'send-invite-modal')
          },
        },
        {
          label: 'Delete',
          onSelect: () => handleDelete(advisor),
        },
      ]

      return (
        <div style={style} data-testid={`member-name-column-${advisor.uuid}`}>
          <StyledCard
            title={advisor.name}
            key={advisor.id}
            titleIcon={
              <>
                {advisor.verified && (
                  <Typography color="purple" fontSize="12">
                    {/* <Icon icon="faBadgeCheck" /> */}
                    <i className="fa fa-badge-check"></i>
                  </Typography>
                )}
                {advisor.linkedin_url && (
                  <Typography
                    color="fog"
                    fontSize="12"
                    onClick={(e) => {
                      e.stopPropagation()
                      window.open(advisor.linkedin_url, '_blank', 'noopener noreferrer')
                    }}
                  >
                    <i className="fab fa-linkedin fa-fw"></i>
                  </Typography>
                )}
                {advisor.twitter_url && (
                  <Typography
                    color="fog"
                    fontSize="12"
                    onClick={(e) => {
                      e.stopPropagation()
                      window.open(advisor.twitter_url, '_blank', 'noopener noreferrer')
                    }}
                  >
                    <i className="fab fa-twitter fa-fw"></i>
                  </Typography>
                )}
              </>
            }
            avatar={
              advisor.avatar_url ? (
                <Avatar src={advisor.avatar_url} />
              ) : (
                <i className="far fa-user" />
              )
            }
            description={descripton}
            onClick={() => histroy.push(`/${team.slug}/members/${advisor.uuid}`)}
            dropDownMenu={{
              menuItems: menu,
            }}
            checkbox={{
              checked: selected,
              onChange: (e) => {
                handleSelection(advisor, e.target.checked)
              },
            }}
            cta={
              <CabalButton
                padding="0"
                variant="link"
                onClick={() => histroy.push(`/${team.slug}/members/${advisor.uuid}`)}
              >
                View
              </CabalButton>
            }
          />
        </div>
      )
    },
  )

  return (
    <div ref={wrapperRef}>
      <FixedSizeList
        height={tableHeight} // Adjust this value based on your list height
        itemCount={filteredAdvisors.length}
        itemSize={54}
        width="100%"
        overscanCount={3}
      >
        {renderAdvisorCard}
      </FixedSizeList>
    </div>
  )
}

export default MemberCard
