import React from 'react'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

interface Props {
  onShowAddWidget: () => void
}

const JobsListEmptyState: React.FC<Props> = ({ onShowAddWidget }) => {
  return (
    <div className="flex items-center justify-center my-16">
      <div className="flex flex-col items-center justify-center gap-4">
        <Typography color="fog" fontSize="20">
          <i className="far fa-briefcase" />
        </Typography>
        <Typography fontSize="14" lineHeight={1}>
          No jobs
        </Typography>
        <Typography fontSize="12" lineHeight={1}>
          Add jobs to get started
        </Typography>
        <CabalButton onClick={() => onShowAddWidget()} leftIcon={<i className="far fa-plus" />}>
          Add job
        </CabalButton>
      </div>
    </div>
  )
}

export default JobsListEmptyState
