import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { Rnd } from 'react-rnd'
import { debounce, omit } from 'lodash'
import { v4 as uuid } from 'uuid'

import { AgreementFieldType, AgreementTemplateField } from 'utils/types'
import { DraggedField as StyledDroppedField, BORDER_SIZE } from './styles'
import { DroppableField } from 'utils/constants/agreement'

const A4_H_W_RATIO = 1.414

interface DroppedFieldProps {
  uuid: string
  x: number
  y: number
  w: number
  h: number
  name: string
  resizable: boolean
  updateFieldPosition: (uuid: string, x: number, y: number, w?: number, h?: number) => void
  deleteField: (uuid: string) => void
}

const DroppedField: React.VFC<DroppedFieldProps> = ({
  uuid,
  x,
  y,
  w,
  h,
  name,
  resizable,
  updateFieldPosition,
  deleteField,
}) => {
  return (
    <Rnd
      position={{
        x,
        y,
      }}
      size={{
        width: w,
        height: h,
      }}
      enableResizing={resizable}
      onDrag={(e, { x, y }) => {
        updateFieldPosition(uuid, x, y)
      }}
      onResize={(e, dir, ref, delta, { x, y }) => {
        const rect = ref.getBoundingClientRect()
        updateFieldPosition(uuid, x, y, rect.width, rect.height)
      }}
      dragHandleClassName="drag-handle"
    >
      <StyledDroppedField data-name={name} className="drag-handle">
        {resizable && (
          <>
            <div className="anchor tl" />
            <div className="anchor tr" />
            <div className="anchor bl" />
            <div className="anchor br" />
          </>
        )}
      </StyledDroppedField>
      <button className="focus:outline-none" onClick={() => deleteField(uuid)}>
        <i className="far fa-times" />
      </button>
    </Rnd>
  )
}

export interface DroppedFieldsRef {
  setFields: (fields: AgreementTemplateField[]) => void
  fields: AgreementTemplateField[]
  addField: (base: DroppableField, x: number, y: number) => void
}

interface DroppedFieldsProps {
  documentViewport: HTMLDivElement | null
  onChange: (fields: AgreementTemplateField[]) => void
}

const DroppedFields = forwardRef<DroppedFieldsRef, DroppedFieldsProps>(
  ({ documentViewport, onChange }, ref) => {
    const [fields, _setFields] = useState<AgreementTemplateField[]>([])

    const throttledOnChange = debounce(onChange, 1000)

    const setFields = useCallback(
      (fields: AgreementTemplateField[]) => {
        throttledOnChange(fields)
        _setFields(fields)
      },
      [throttledOnChange],
    )

    const defaultFieldDimensions: Record<
      AgreementFieldType,
      {
        w: number
        h: number
      }
    > | null = React.useMemo(() => {
      if (!documentViewport) return null
      const { width } = documentViewport.getBoundingClientRect()

      const singleLineDimensions = {
        w: 150 / width,
        h: 20 / (width * A4_H_W_RATIO),
      }

      return {
        string: singleLineDimensions,
        number: singleLineDimensions,
        amount: singleLineDimensions,
        date: singleLineDimensions,
        image: {
          w: 150 / width,
          h: 50 / (width * A4_H_W_RATIO),
        },
        text: {
          w: 150 / width,
          h: 20 / (width * A4_H_W_RATIO),
        },
        checkbox: {
          w: 20 / width,
          h: 20 / (width * A4_H_W_RATIO),
        },
      }
    }, [documentViewport])

    const pdfRatioToCoordinate = (field: AgreementTemplateField) => {
      if (!documentViewport) return

      const pages = documentViewport.getElementsByTagName('canvas')
      const { x: xR, y: yR, page_num, w: wR, h: hR } = field.position!
      if (!pages[page_num]) return

      const { clientWidth: pageW, clientHeight: pageH } = pages[page_num]
      const pdfX = pageW * xR
      let pdfY = pageH * yR
      const w = pageW * wR
      const h = pageH * hR

      for (let i = 0; i < page_num; i++) {
        const { height } = pages[i].getBoundingClientRect()
        pdfY += height
      }

      return {
        x: pdfX - BORDER_SIZE,
        y: pdfY - BORDER_SIZE,
        w: w + BORDER_SIZE,
        h: h + BORDER_SIZE,
      }
    }

    const pdfCoordinateToPdfRatio = useCallback(
      (x: number, y: number, w?: number, h?: number) => {
        if (!documentViewport) return

        const pages = documentViewport.getElementsByTagName('canvas')
        let page_num = 0
        let yPan = 0
        let pageWidth = 0
        let pageHeight = 0

        for (let i = 0; i < pages.length; i++) {
          const pageRect = pages[i].getBoundingClientRect()
          pageWidth = pageRect.width
          pageHeight = pageRect.height

          if (y < yPan + pageHeight) break

          yPan += pageHeight
          page_num++
        }

        const position: {
          x: number
          y: number
          page_num: number
          w?: never | number
          h?: never | number
        } = {
          x: (x + BORDER_SIZE) / pageWidth,
          y: (y + BORDER_SIZE - yPan) / pageHeight,
          page_num,
        }

        if (w) {
          position.w = (w - BORDER_SIZE) / pageWidth
        }
        if (h) {
          position.h = (h - BORDER_SIZE) / pageHeight
        }

        return position
      },
      [documentViewport],
    )

    const updateFieldPosition = (uuid: string, x: number, y: number, w?: number, h?: number) => {
      const field = fields.find((atf) => atf.uuid === uuid)
      if (!field?.position) return

      field.position = {
        ...field.position,
        ...pdfCoordinateToPdfRatio(x, y, w, h),
      }

      const updatedFields = fields.filter((atf) => atf.uuid !== uuid)
      updatedFields.push(field)

      setFields(updatedFields)
    }

    const deleteField = (uuid: string) => {
      setFields(fields.filter((atf) => atf.uuid !== uuid))
    }

    const addField = useCallback(
      (base: DroppableField, x: number, y: number) => {
        const position = pdfCoordinateToPdfRatio(x, y)
        if (!position) return

        setFields([
          ...fields,
          {
            ...omit(base, 'custom'),
            uuid: uuid(),
            position: {
              ...position,
              ...defaultFieldDimensions![base.data_type],
            },
          },
        ])
      },
      [defaultFieldDimensions, fields, pdfCoordinateToPdfRatio, setFields],
    )

    useImperativeHandle(
      ref,
      () => ({
        fields,
        setFields,
        addField,
      }),
      [fields, setFields, addField],
    )

    return (
      <>
        {fields
          .filter((atf) => !!atf.position)
          .map((atf) => {
            const position = pdfRatioToCoordinate(atf)
            if (!position) return <></>

            const { x, y, w, h } = position
            const resizable = ['image', 'text'].includes(atf.data_type)

            return (
              <DroppedField
                key={atf.uuid}
                {...{
                  uuid: atf.uuid,
                  name: atf.name,
                  x,
                  y,
                  w,
                  h,
                  resizable,
                  updateFieldPosition,
                  deleteField,
                }}
              />
            )
          })}
      </>
    )
  },
)

export default DroppedFields
