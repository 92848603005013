import * as React from 'react'

import { FieldProps } from 'formik'
import _compact from 'lodash/compact'
import first from 'lodash/first'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import toString from 'lodash/toString'
import truncate from 'lodash/truncate'
import uniq from 'lodash/uniq'
import ReactSelect, {
  ActionMeta,
  ContainerProps,
  ControlProps,
  GroupBase as GroupTypeBase,
  InputProps,
  MenuListProps,
  MenuPlacement,
  MultiValueGenericProps,
  OptionProps,
  Props as ReactSelectProps,
  SelectComponentsConfig,
  StylesConfig,
  components as selectComponents,
} from 'react-select'
import AsyncReactSelect from 'react-select/async'
import ReactCreatableSelect from 'react-select/creatable'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { FixedSizeList } from 'react-window'
import styled, { css, useTheme } from 'styled-components'
import tw from 'twin.macro'

import { useModal } from 'global/Modal/Context'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'

import useColorMode from 'utils/hooks/useColorMode'
import { Params } from 'utils/types'

const OPTION_HEIGHT = 35

export interface SelectOption<Value> {
  label?: React.ReactNode
  value: Value
  group?: string

  /**
   * required when Value is non string
   */
  toString?: () => string

  isFixed?: boolean

  /**
   * supplementary information to the label
   * will be rendered in dropdown option like:
   *    `<label> – <extraLabel>`
   * will not be rendered in multiValue selected option (the pill)
   * will be rendered in a tooltip when hovering over the pill
   */
  extraLabel?: string
  [key: string]: unknown
}

function isValueEqual<Value>(a: Value, b: Value, valueKeyFn: SelectPropsBase<Value>['valueKeyFn']) {
  let av: Value | string = a
  let bv: Value | string = b

  if (valueKeyFn) {
    av = valueKeyFn(a)
    bv = valueKeyFn(b)
  }

  if (typeof a === 'object' && a) {
    av = omit(a as Params, 'toString') as Value
  }

  if (typeof b === 'object' && b) {
    bv = omit(b as Params, 'toString') as Value
  }

  return isEqual(av, bv)
}

function convertValueToSelectedOptions<Value>(
  values: Value[] | undefined,
  options: SelectOption<Value>[],
  valueKeyFn: SelectPropsBase<Value>['valueKeyFn'],
  unknownValueToOption: SelectPropsBase<Value>['unknownValueToOption'],
): SelectOption<Value>[] | undefined {
  if (values === undefined) return undefined

  return values
    .map((value) => {
      return (
        options.find((o) => isValueEqual<Value>(o.value, value, valueKeyFn)) ||
        unknownValueToOption?.(value) || {
          label: toString(value),
          value,
        }
      )
    })
    ?.sort((a) => (a.isFixed ? -1 : 1))
}

function assignToStringToOptionValue<Value>(o: SelectOption<Value>) {
  if (typeof o.value !== 'object' || !o.toString) return o

  return {
    ...o,
    value: {
      ...o.value,
      toString: o.toString,
    },
  }
}

function downcastOptions<Value>(options: SelectOption<Value>[], virtualized: boolean) {
  const hasGroups = options.some((o) => !!o.group)

  if (hasGroups && !virtualized) {
    const groupNames = uniq(options.map((o) => o.group || ''))

    const updatedOptions = []
    for (const groupName of groupNames) {
      updatedOptions.push({
        label: groupName,
        options: options.filter((o) => o.group === groupName),
      })
    }

    return updatedOptions
  }

  return options.map((option) => {
    if (option.group) {
      return {
        ...option,
        group: option.label,
      }
    }
    return option
  })
}

interface StyledReactSelectProps {
  removeFocus: boolean
  showErrorBorder?: boolean
  fontSize?: string
}

const StyledSelectBase = styled.div<StyledReactSelectProps>`
  div[class*='control'] {
    ${({ removeFocus }) => !removeFocus && tw`focus-within:ring`}

    ${(props) =>
      props.showErrorBorder &&
      css`
        border: solid 1px ${props.theme.colors.border_danger};
      `}
  }

  .select__single-value {
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
  }
`

const StyledSelect = StyledSelectBase.withComponent(ReactSelect)
const StyledCreatableSelect = StyledSelectBase.withComponent(ReactCreatableSelect)
export const StyledAsyncSelect = StyledSelectBase.withComponent(AsyncReactSelect)

type CustomControlProps<Value> = ControlProps<SelectOption<Value>, boolean>

function CustomControl<Value>(props: CustomControlProps<Value>) {
  const { leftIcon, rightIcon } = props.selectProps as any
  return (
    <selectComponents.Control {...props}>
      {leftIcon && (
        <Typography color="rain_fog" className="ml-2 -mr-1">
          {leftIcon}
        </Typography>
      )}
      {props.children}
      {rightIcon && (
        <Typography color="rain_fog" className="mr-2">
          {rightIcon}
        </Typography>
      )}
    </selectComponents.Control>
  )
}

interface SelectPropsBase<Value> extends Omit<ReactSelectProps, 'formatOptionLabel'> {
  compact?: boolean
  fontSize?: string
  compactPlaceholder?: boolean
  noMinWidth?: boolean
  minWidth?: string
  creatable?: boolean
  async?: boolean
  loadOptions?: (input: string) => Promise<unknown>
  placeholder?: string
  autoFocus?: boolean
  onCreateOption?: (v: string, failSoftly: boolean) => void
  noOptionsMessage?: (obj: { inputValue: string }) => string | null
  isClearable?: ReactSelectProps<SelectOption<Value>, true>['isClearable']
  menuPlacement?: MenuPlacement
  portal?: boolean
  removeBorder?: boolean
  removeFocusRing?: boolean
  style?: React.CSSProperties
  lightBg?: boolean
  className?: string
  options: SelectOption<Value>[]
  valueKeyFn?: (v: Value) => string
  unknownValueToOption?: (v: Value) => SelectOption<Value>
  isLoading?: boolean
  inputId?: string
  /**
   * @deprecated
   * @private
   * internal prop only, use `Select` or `MultiSelect` instead
   * not to be used outside of this component
   *
   * deprecated used purely for decoration in vscode
   */
  _isMulti?: boolean
  disabled?: boolean
  formatCreateLabel?: (inputValue: string) => React.ReactNode
  formatOptionLabel?: ReactSelectProps<SelectOption<Value>>['formatOptionLabel']
  onInputChange?: (v: string) => void
  onClickMultiValue?: (e: React.MouseEvent, value: Value) => void
  noDropdownIndicator?: boolean
  showErrorBorder?: boolean
  leftActions?: React.ReactNode
  rightActions?: React.ReactNode
  /**
   * this will be used while comparing two options
   *
   * if the Value is an object, use this method to deduce the id for the option
   * if this method is not provided, the objects will be compared directly (using lodash's isEqual)
   */
  getOptionId?: (value?: Value) => unknown
  virtualized?: boolean
  cursorPointer?: boolean

  'data-testid'?: string
  tooltip?: React.ReactNode
}

interface SelectBaseProps<Value> extends SelectPropsBase<Value> {
  singleValue?: Value
  defaultSingleValue?: Value
  onSingleChange?: (value: Value | null) => void

  multiValue?: Value[]
  defaultMultiValue?: Value[]
  onMultiChange?: (value: Value[]) => void
}

function SelectBase<Value>(props: SelectBaseProps<Value>) {
  const { blockModalEscape: blockEscape, unblockModalEscape: unblockEscape } = useModal()
  const { isMobile } = useColorMode()

  const theme = useTheme()
  const {
    compact,
    fontSize = '12px',
    lightBg,
    removeBorder,
    compactPlaceholder,
    noMinWidth,
    minWidth = '250px',
    style: containerStyle,
    creatable,
    removeFocusRing = false,
    'data-testid': testId,
    singleValue,
    showErrorBorder = false,
    multiValue,
    defaultSingleValue,
    defaultMultiValue,
    onSingleChange,
    onMultiChange,
    portal: appendToBody = true,
    _isMulti: isMulti = false,
    menuPlacement = 'auto',
    onCreateOption,
    disabled,
    onInputChange,
    onClickMultiValue,
    noDropdownIndicator = false,
    leftActions: leftIcon,
    rightActions: rightIcon,
    isClearable,
    valueKeyFn,
    unknownValueToOption,
    cursorPointer = false,
    async = false,
    tooltip,
    ...restProps
  } = props

  const options = props.options.map((o) => assignToStringToOptionValue<Value>(o))
  const values = _compact([singleValue, ...(multiValue || [])])
  const defaultValues = _compact([defaultSingleValue, ...(defaultMultiValue || [])])
  const virtualized =
    restProps.virtualized !== undefined
      ? restProps.virtualized
      : (options && options.length) > 100
      ? true
      : false

  React.useEffect(() => {
    if (
      process.env.NODE_ENV === 'development' &&
      options.some((o) => typeof o.value === 'object' && o.toString?.() === '[object Object]')
    ) {
      console.error('[DEV] Select: need to pass toString if Value is an object')
    }
  }, [])

  const CustomContainer: React.FC<ContainerProps<SelectOption<Value>, boolean>> = React.useCallback(
    (commonProps) => {
      const container = (
        <selectComponents.SelectContainer
          {...commonProps}
          innerProps={Object.assign({}, commonProps.innerProps, {
            'data-testid': testId,
          })}
        />
      )

      if (tooltip) {
        return (
          <Tooltip className="flex-1" label={tooltip}>
            {container}
          </Tooltip>
        )
      }

      return container
    },
    [testId],
  )

  const CustomInput: React.FC<InputProps<SelectOption<Value>>> = React.useCallback(
    (commonProps) => (
      <selectComponents.Input
        {...commonProps}
        autoComplete="off"
        {...({ autoCorrect: 'off', spellCheck: 'false' } as any)}
      />
    ),
    [],
  )

  const CustomOption: React.FC<OptionProps<SelectOption<Value>>> = React.useCallback(
    (commonProps) => {
      const option = commonProps.data as SelectOption<Value>
      let label = option.label
      if (option.extraLabel) {
        label += ` – ${option.extraLabel}`
      }
      if (!label) {
        label = toString(option.value)
      }

      return (
        <selectComponents.Option {...commonProps}>
          {props.formatOptionLabel ? props.formatOptionLabel(commonProps.data) : label}
        </selectComponents.Option>
      )
    },
    [],
  )

  const CustomMenuList: React.FC<MenuListProps<SelectOption<Value>>> = React.useCallback(
    (commonProps) => {
      const {
        options,
        children,
        maxHeight,
        getValue,
        selectProps: { optionHeight },
      } = commonProps

      const [value] = getValue()
      const initialOffset = options.indexOf(value) * (optionHeight || OPTION_HEIGHT)

      if (Array.isArray(children) && virtualized) {
        return (
          <FixedSizeList
            width={'100%'}
            height={maxHeight}
            itemCount={children.length}
            itemSize={optionHeight || OPTION_HEIGHT}
            initialScrollOffset={initialOffset}
            overscanCount={40}
          >
            {({ index, style }) => <div style={style}>{children[index]}</div>}
          </FixedSizeList>
        )
      }

      return <selectComponents.MenuList {...commonProps} />
    },
    [virtualized],
  )

  const CustomMultiValueLabel: React.FC<MultiValueGenericProps<SelectOption<Value>>> =
    React.useCallback(
      (props) => {
        const option = props.data as SelectOption<Value>

        let optionNode = (
          <selectComponents.MultiValueLabel {...props}>
            {typeof option.label === 'string'
              ? truncate(option.label, { length: 15, omission: '…' })
              : option.label}
          </selectComponents.MultiValueLabel>
        )

        if (!!option.extraLabel || !!option.group || (option.label && option.label?.length > 15)) {
          optionNode = (
            <Tooltip
              label={
                <Typography fontSize="12">
                  {option.group && (
                    <Typography
                      fontWeight={500}
                      color="rain"
                      component="div"
                      textTransform="uppercase"
                      className="flex justify-between mb-2"
                    >
                      {option.group}
                    </Typography>
                  )}
                  {option.label}
                  {option.extraLabel && (
                    <Typography component="div">{option.extraLabel}</Typography>
                  )}
                </Typography>
              }
            >
              {optionNode}
            </Tooltip>
          )
        }

        if (onClickMultiValue)
          return <button onClick={(e) => onClickMultiValue?.(e, option.value)}>{optionNode}</button>

        return optionNode
      },
      [onClickMultiValue],
    )

  const selectStyles: StylesConfig<SelectOption<Value>, boolean> = {
    control: (base) => ({
      ...base,
      color: theme.colors.primary,
      backgroundColor: lightBg ? theme.colors.primary_bg : theme.layout.main_bg_color,
      borderRadius: '0.25rem',
      borderColor: showErrorBorder ? theme.colors.border_danger : theme.colors.border,
      borderWidth: removeBorder ? undefined : '1px',
      minHeight: 0,
      // Removes weird border around container
      cursor: disabled ? 'not-allowed' : cursorPointer ? 'pointer' : base.cursor,
      boxShadow: undefined,
      ':hover': {
        backgroundColor: undefined,
        borderColor: showErrorBorder ? theme.colors.border_danger : theme.colors.border,
      },
    }),
    input: (base) => ({
      ...base,
      padding: '0',
      margin: 0,
      marginLeft: '0.1rem',
      color: theme.colors.primary,
      fontSize: '12px',
    }),
    menuList: (base) => ({
      ...base,
      color: theme.colors.primary,
      backgroundColor: theme.popup.background,
      padding: 0,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? theme.popup.select
        : state.isFocused
        ? theme.popup.select
        : theme.popup.background,
      color: theme.colors.primary,
      fontSize: '0.75rem',
      // needed to prevent space when using react-window fixed-size-list
      height: '100%',
    }),
    multiValueLabel: (base) => ({
      ...base,
      padding: 0,
      color: theme.colors.primary,
      paddingRight: '0.125rem',
      fontSize: fontSize ? fontSize : '0.75rem',
    }),
    multiValue: (base) => ({
      ...base,
      color: theme.colors.primary,
      backgroundColor: theme.buttons.default.bg_color,
      margin: '0 0.25rem 0 0',
      borderRadius: '0.3rem',
      maxWidth: isMobile ? '200px' : '100%',
      fontSize: fontSize ? fontSize : '12px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: compact ? '0.225rem' : '0.5rem',
      paddingLeft: '0.5rem',
      maxHeight: '110px',
      overflow: 'auto',
    }),
    multiValueRemove: (base, state) => {
      if (state?.data?.isFixed) {
        return { ...base, display: 'none' }
      }

      return {
        ...base,
        color: theme.colors.primary,
        cursor: 'pointer',
        padding: '0 0.1rem',
        ':hover': {
          backgroundColor: theme.colors.purple,
        },
      }
    },
    singleValue: (base) => ({
      ...base,
      color: disabled ? theme.colors.fog : theme.colors.primary,
      fontSize: fontSize ? fontSize : '0.75rem',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: '3px',
      fontWeight: 'lighter',
      color: theme.colors.rain_fog,
    }),
    indicatorsContainer: (base) => ({
      ...base,
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: '3px',
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: '3px',
      color: theme.colors.rain_fog,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 120,
      backgroundColor: theme.popup.background,
    }),
    placeholder: (base) => ({
      ...base,
      marginLeft: '0.25rem',
      fontSize: fontSize ? fontSize : compact || compactPlaceholder ? '0.75rem' : '12px',
      color: theme.colors.placeholder,
    }),
    menu: (base) => ({
      ...base,
      borderRadius: '0.25rem',
      minWidth: '200px',
      overflow: 'auto',
      backgroundColor: theme.popup.background,
    }),
    container: (base) => ({
      ...base,
      minWidth: noMinWidth ? '0px' : minWidth,
      cursor: disabled ? 'not-allowed' : cursorPointer ? 'pointer' : base.cursor,
      pointerEvents: disabled ? 'all' : base.pointerEvents,
      ...containerStyle,
    }),
  }

  const selectedOptions = convertValueToSelectedOptions<Value>(
    values,
    options,
    valueKeyFn,
    unknownValueToOption,
  )
  const defaultSelectedOptions = convertValueToSelectedOptions<Value>(
    defaultValues,
    options,
    valueKeyFn,
    unknownValueToOption,
  )

  const filterOption = React.useCallback(
    (opt: FilterOptionOption<SelectOption<Value>>, q: string) => {
      if (props.filterOption) return props.filterOption(opt, q)

      if (opt.data.__isNew__) return true

      const string = []
      if (opt.data.labelStr) {
        string.push(opt.data.labelStr)
      }
      if (typeof opt.label === 'string') {
        string.push(opt.label)
      }
      if (opt.data.extraLabel) {
        string.push(opt.data.extraLabel)
      }
      if (opt.data.searchLabel) {
        string.push(opt.data.searchLabel)
      }
      return string.join('').toLowerCase().includes(q.toLowerCase())
    },
    [props.filterOption],
  )

  const handleOnChange = (
    _selectedOptions: SelectOption<Value> | ReadonlyArray<SelectOption<Value>> | null,
    actionMeta: ActionMeta<SelectOption<Value>>,
  ) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta?.removedValue?.isFixed) {
          return
        }
        break
      case 'clear':
        _selectedOptions = options.filter((v) => v.isFixed)
        break
    }

    const selectedOptions = _selectedOptions as SelectOption<Value> | SelectOption<Value>[] | null
    if (isMulti) {
      if (!selectedOptions) {
        onMultiChange?.([])
      } else if (Array.isArray(selectedOptions)) {
        onMultiChange?.(selectedOptions?.map((v) => v.value))
      }
    } else {
      if (!Array.isArray(selectedOptions)) {
        onSingleChange?.(selectedOptions?.value || null)
      } else if (!selectedOptions.length) {
        onSingleChange?.(null)
      }
    }
  }

  const convertedOptions = downcastOptions(options, virtualized)
  const components: Partial<
    SelectComponentsConfig<SelectOption<Value>, boolean, GroupTypeBase<SelectOption<Value>>>
  > = {
    SelectContainer: CustomContainer,
    Control: CustomControl,
    Input: CustomInput,
    MultiValueLabel: CustomMultiValueLabel,
    Option: CustomOption,
    MenuList: CustomMenuList,
    NoOptionsMessage: (props) => (
      <Typography fontSize="12">
        <selectComponents.NoOptionsMessage {...props} />
      </Typography>
    ),
    LoadingMessage: (props) => (
      <Typography fontSize="12">
        <selectComponents.LoadingMessage {...props} />
      </Typography>
    ),
  }

  if (noDropdownIndicator) {
    components.DropdownIndicator = () => null
  }

  const selectProps: ReactSelectProps<SelectOption<Value>> = {
    menuPlacement,
    styles: selectStyles,
    classNamePrefix: 'select',
    menuPortalTarget: appendToBody ? document.body : undefined,
    getOptionLabel: (o: SelectOption<Value>) =>
      o.label || (typeof o.value === 'string' ? o.value : toString(o.value)),
    components,
    onMenuOpen: () => {
      blockEscape()
    },
    onMenuClose: () => {
      unblockEscape()
    },
    onBlur: (e) => {
      if (!creatable) return
      const val = e.currentTarget?.value
      val && onCreateOption?.(val, true)
    },
    isDisabled: disabled,
    ...restProps,
    isClearable, //: isClearable || isMulti ? ((valueOptions as SelectOption<Value>[]) || []).some((v) => !v.isFixed) : true,
    isMulti,
    options: convertedOptions,
    value: isMulti ? selectedOptions : first(selectedOptions),
    defaultValue: isMulti ? defaultSelectedOptions : first(defaultSelectedOptions),
    onChange: handleOnChange,
    onInputChange,
    filterOption,
  }

  Object.assign(selectProps, {
    leftIcon,
    rightIcon,
  })

  if (creatable) {
    return (
      <StyledCreatableSelect
        removeFocus={removeFocusRing}
        onCreateOption={onCreateOption}
        fontSize={fontSize}
        {...selectProps}
      />
    )
  }

  return <StyledSelect removeFocus={removeFocusRing} fontSize={fontSize} {...selectProps} />
}

export interface SelectProps<Value> extends SelectPropsBase<Value> {
  value?: Value
  defaultValue?: Value
  onChange?: (value: Value | null) => void
  optionHeight?: number
}

function Select<Value>({ value, defaultValue, onChange, ...props }: SelectProps<Value>) {
  return (
    <SelectBase<Value>
      singleValue={value}
      defaultSingleValue={defaultValue}
      onSingleChange={onChange}
      {...props}
    />
  )
}

export interface MultiSelectProps<Value> extends SelectPropsBase<Value> {
  value?: Value[]
  defaultValue?: Value[]
  onChange?: (value: Value[]) => void
}

function MultiSelect<Value>({
  value,
  defaultValue,
  onChange,
  creatable = false,
  ...props
}: MultiSelectProps<Value>) {
  return (
    <SelectBase<Value>
      _isMulti
      creatable={creatable}
      multiValue={value}
      defaultMultiValue={defaultValue}
      onMultiChange={onChange}
      {...props}
    />
  )
}

function SelectField<Value>({
  onChange,
  ...props
}: SelectProps<Value> & { fieldProps: FieldProps }) {
  return (
    <div className="flex flex-col items-stretch">
      <Select
        {...props}
        value={props.fieldProps.field.value}
        showErrorBorder={Boolean(props.fieldProps.meta.touched && props.fieldProps.meta.error)}
        onChange={(val) => {
          props.fieldProps.form.setFieldValue(props.fieldProps.field.name, val)
          onChange?.(val)
        }}
        onBlur={() => {
          props.fieldProps.form.setFieldTouched(props.fieldProps.field.name, true)
        }}
      />
      {props.fieldProps.meta.touched && props.fieldProps.meta.error && (
        <div className="flex justify-start pt-1">
          <Typography color="border_danger" fontSize="11">
            {props.fieldProps.meta.error}
          </Typography>
        </div>
      )}
    </div>
  )
}

function MultiSelectField<Value>({
  onCreateOption,
  ...props
}: SelectProps<Value> & { fieldProps: FieldProps }) {
  return (
    <div className="flex flex-col items-stretch">
      <MultiSelect
        {...props}
        value={props.fieldProps.field.value}
        showErrorBorder={Boolean(props.fieldProps.meta.touched && props.fieldProps.meta.error)}
        onChange={(val) => {
          props.fieldProps.form.setFieldValue(props.fieldProps.field.name, val)
        }}
        onCreateOption={(val) => {
          props.fieldProps.form.setFieldValue(props.fieldProps.field.name, [
            ...props.fieldProps.field.value,
            val,
          ])
          onCreateOption?.(val)
        }}
        onBlur={() => {
          props.fieldProps.form.setFieldTouched(props.fieldProps.field.name, true)
        }}
      />
      {props.fieldProps.meta.touched && props.fieldProps.meta.error && (
        <div className="flex justify-start pt-1">
          <Typography color="border_danger" fontSize="11">
            {props.fieldProps.meta.error}
          </Typography>
        </div>
      )}
    </div>
  )
}

export { Select, MultiSelect, SelectField, MultiSelectField }
