import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

import Avatar from 'global/Avatar'
import { useCurrentUser } from 'store/hooks'
import { transparentize } from 'polished'

const Container = styled.div`
  ${tw`flex flex-col justify-between h-full`}
`

const TopBox = styled.div`
  ${tw`border light:border-light dark:border-dark rounded-lg px-5 pt-8 pb-7 text-center m-10`}
`

const BottomBox = styled.div`
  ${tw`relative mx-5`}

  &:before {
    ${tw`absolute h-full top-0 left-0 mx-5 rounded-t-lg`}

    width: calc(100% - 2.5rem);
    content: '';
    z-index: -1;
    background: linear-gradient(
      180deg,
      ${({ theme }) => transparentize(1 - 0.64, theme.colors.shadow)} 0%,
      ${({ theme }) => transparentize(1 - 0.2048, theme.colors.shadow)} 100%
    );
  }
`

const BottomBoxText = styled.div`
  ${tw`px-10 my-3 flex flex-col gap-2`}
`

const DummyActivity = styled(Typography)`
  ${tw`p-4 rounded-lg border light:border-light dark:border-dark flex gap-2 justify-between mb-2`}

  background-color: ${({ theme }) => theme.colors.shadow};
`

const DummyBlob = styled.div`
  ${tw`w-32 rounded-lg h-3 mt-2 opacity-50`}

  background-color: ${({ theme }) => theme.colors.rain_fog};
`

interface Props {
  text: string
  cta: React.ReactNode
  bottomHeadline: string
  dummyActivities: {
    name: string
    avatar?: string
    icon: React.ReactNode
    description: string
  }[]
}

const EmptyStateV2: React.VFC<Props> = ({ text, cta, bottomHeadline, dummyActivities }) => {
  return (
    <Container>
      <TopBox>
        <Typography color="fog" component="div">
          {text}
        </Typography>
        {cta}
      </TopBox>
      <BottomBox>
        <BottomBoxText>
          <Typography
            textTransform="uppercase"
            fontSize="12"
            color="text_secondary"
            component="div"
          >
            why cabal?
          </Typography>
          <Typography fontSize="20" fontWeight={600}>
            {bottomHeadline}
          </Typography>
        </BottomBoxText>
        {dummyActivities.map((a, index) => (
          <DummyActivity fontSize="13" component="div" key={`dummy-activity-${index}`}>
            <div className="flex gap-2 items-center">
              <Avatar name={a.name} size="35px" round src={a.avatar} />
              <div>
                <div>
                  <Typography fontWeight={600}>{a.name}</Typography> {a.description}
                </div>
                <DummyBlob />
              </div>
            </div>
            <Typography component="div" color="fog">
              {a.icon}
            </Typography>
          </DummyActivity>
        ))}
        <div className="h-3" />
      </BottomBox>
    </Container>
  )
}

export default EmptyStateV2
