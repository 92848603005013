import React, { useState } from 'react'

import { useMutation } from 'react-query'
import styled from 'styled-components/macro'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import PillV2 from 'ui-components/PillV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

const Container = styled.div`
  ${tw`flex flex-wrap justify-center items-center gap-2`}
`
const TextAreaContainer = styled.div`
  ${tw`mt-4 w-full flex flex-col items-center`}
`
const ButtonContainer = styled.div`
  ${tw`flex justify-center w-full mt-4`}
`
const PillsContainer = ({
  reasonsArray,
  requestIntroId,
}: {
  reasonsArray: string[]
  requestIntroId: string
}) => {
  const [showTextArea, setShowTextArea] = useState(false)
  const [otherReason, setOtherReason] = useState('')
  const { closeModal } = useModal()

  const declineMutation = useMutation((reason: string) =>
    callApi(api.updateDeclinedIntroRequestV2, requestIntroId, reason),
  )

  const handleDecline = (reason: string) => {
    reason = reason === 'Other' ? otherReason : reason
    declineMutation.mutate(reason)
    cabalToast({
      style: 'success',
      content: `You've declined the intro request with reason: ${reason}`,
    })
    closeModal('intro-request-confirmation')
  }

  const [selectedReason, setSelectedReason] = useState('')
  const isSelected = selectedReason !== ''

  const handleClick = (reason: string) => {
    setSelectedReason(reason)
    if (reason === 'Other') {
      setShowTextArea(true)
    }
  }

  return (
    <>
      <Container>
        {reasonsArray.map((reason, index) => (
          <PillV2
            key={index}
            title={
              <Typography fontSize="12" lineHeight={1}>
                {reason}
              </Typography>
            }
            active={reason === selectedReason}
            onClick={() => handleClick(reason)}
          />
        ))}
        {showTextArea && (
          <TextAreaContainer>
            <TextArea
              className="w-1/2"
              rows={4}
              placeholder="Please specify the reason..."
              value={otherReason}
              onChange={(e) => setOtherReason(e.target.value)}
            />
          </TextAreaContainer>
        )}
      </Container>
      <ButtonContainer>
        <CabalButton onClick={() => handleDecline(selectedReason)} disabled={!isSelected}>
          Submit
        </CabalButton>
      </ButtonContainer>
    </>
  )
}

export default PillsContainer
