import Pill from 'global/Pill'
import React from 'react'
import { useQuery } from 'react-query'
import api, { callApi } from 'utils/api'
import { EquityTierEntity } from 'utils/types'

interface Props {
  onSelect: (tier: EquityTierEntity) => void
  teamSlug: string
}

const TierPills: React.VFC<Props> = ({ onSelect, teamSlug }) => {
  const tiersQuery = useQuery(['getEquityTiers', teamSlug], () =>
    callApi(api.getEquityTiers, teamSlug),
  )
  const equityTiers = tiersQuery.data?.equity_tiers

  if (!equityTiers || !equityTiers.length) return <></>

  return (
    <div className="flex mb-3 flex-wrap">
      {equityTiers.map((tier: EquityTierEntity) => (
        <Pill
          onClick={() => onSelect(tier)}
          key={tier.id}
          rounded
          variant="purple_filled"
          className="cursor-pointer mr-2"
        >
          {tier.name} ({tier.shares.toLocaleString()})
        </Pill>
      ))}
    </div>
  )
}

export default TierPills
