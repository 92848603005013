import React, { useState } from 'react'

import AddWidget from 'containers/ListIndexView/Components/AddWidget'
import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import SalesforceSection from 'containers/ListIndexView/SalesList/SalesCompanyList/SalesCompanyAddWidget/SalesforceSection'

import AddIndividualSection from './AddIndividualSection'
import ImportSection from './ImportSection'

interface Props {
  onClose: () => void
}

const SalesCompanyAddWidget: React.FC<Props> = ({ onClose }) => {
  const { companyListData: list, salesCompanies } = useSalesListContext()
  const tabs = [
    {
      key: 'add_sync_salesforce',
      label: 'Salesforce',
      iconClass: 'far fa-arrows-left-right',
      component: <SalesforceSection provider={'salesforce'} setOnClose={onClose} />,
      show:
        (list?.dynamic && list.provider == 'salesforce') ||
        (salesCompanies.length === 0 &&
          !(list?.running_imports?.length && list?.running_imports?.length > 0)),
    },
    {
      key: 'add_sync_hubspot',
      label: 'Hubspot',
      iconClass: 'far fa-arrows-left-right',
      component: <SalesforceSection provider={'hubspot'} setOnClose={onClose} />,
      show:
        (list?.dynamic && list.provider == 'hubspot') ||
        (salesCompanies.length === 0 &&
          !(list?.running_imports?.length && list?.running_imports?.length > 0)),
    },
    {
      key: 'add_individually',
      label: 'Add Manually',
      iconClass: 'far fa-plus',
      component: <AddIndividualSection setOnClose={onClose} />,
      show: !list?.dynamic || salesCompanies.length === 0,
      disabled: list?.running_imports?.length && list?.running_imports?.length > 0,
    },
    {
      key: 'import',
      label: 'Import',
      iconClass: 'far fa-file-csv',
      component: <ImportSection setOnClose={onClose} />,
      show: !list?.dynamic || salesCompanies.length === 0,
      disabled: false,
    },
  ]

  return <AddWidget onClose={onClose} tabs={tabs} title="Add companies" />
}

export default SalesCompanyAddWidget
