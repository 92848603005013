import { History } from 'history'
import moment from 'moment'

import {
  REQUEST_CENTER_STATUSES,
  REQUEST_CENTER_STATUSES_NON_PORTFOLIO,
  STATUS_ICON_LABEL_MAPPING,
  STATUS_ICON_LABEL_NON_PORTFOLIO_MAPPING,
  sortCols,
} from 'containers/RequestCenter/constants'
import {
  IIntroStateParams,
  ITableDataObject,
  ITableDataObjectArr,
  IUserObject,
  StatusType,
} from 'containers/RequestCenter/types/ITypes'

import { Team } from 'utils/types'

const compareOptions = {
  numeric: true,
  sensitivity: 'base',
  ignorePunctuation: true,
}
export const sortAlphabetically = (
  tdObjectArr: ITableDataObjectArr,
  colToSort: string,
  ascOrder: boolean,
) => {
  tdObjectArr.sort((obj1: ITableDataObject, obj2: ITableDataObject) => {
    const sortKey = sortCols[colToSort]
    const key1 = obj1[Object.keys(obj1)[0]][colToSort][sortKey]
    const key2 = obj2[Object.keys(obj2)[0]][colToSort][sortKey]
    return ascOrder === true
      ? key1.localeCompare(key2, 'en', compareOptions)
      : key2.localeCompare(key1, 'en', compareOptions)
  })
}
export const sortDates = (
  tdObjectArr: ITableDataObjectArr,
  colToSort: string,
  ascOrder: boolean,
) => {
  tdObjectArr.sort((obj1: ITableDataObject, obj2: ITableDataObject) => {
    const sortKey = sortCols[colToSort]
    const key1 = obj1[Object.keys(obj1)[0]][colToSort][sortKey]
    const key2 = obj2[Object.keys(obj2)[0]][colToSort][sortKey]
    const moment1 = moment(key1, 'MMM DD, YYYY')
    const moment2 = moment(key2, 'MMM DD, YYYY')
    return ascOrder ? moment1.diff(moment2) : moment2.diff(moment1)
  })
}
export const image = (userObject: IUserObject) => {
  return userObject?.avatarUrl || userObject?.avatar_url || userObject?.image_url || undefined
}
export const companyName = (userObject: IUserObject) => {
  return userObject?.company_name
}
export const companyTitle = (userObject: IUserObject) => {
  return userObject?.title || userObject?.position || userObject?.headline
}
export const timeElapsedSinceNow = (lastUpdated: Date) => {
  return moment(lastUpdated).fromNow()
}

export const commentTime = (lastUpdated: Date) => {
  const timeInDays = timeElapsedSinceNow(lastUpdated)
  const numberPattern = /\d+/
  const number = timeInDays.match(numberPattern)?.[0] || ''
  if (timeInDays.includes('minute') || timeInDays.includes('minutes')) {
    return number + 'm'
  } else if (timeInDays.includes('hour') || timeInDays.includes('hours')) {
    return number + 'h'
  } else if (timeInDays.includes('day') || timeInDays.includes('days')) {
    return number + 'd'
  } else if (timeInDays.includes('week') || timeInDays.includes('weeks')) {
    return number + 'w'
  } else if (timeInDays.includes('month') || timeInDays.includes('months')) {
    return number + 'm'
  } else if (timeInDays.includes('year') || timeInDays.includes('years')) {
    return number + 'y'
  }
  return timeInDays
}

export const commentBody = (htmlString: string): string | null => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  const pTag = doc.querySelector('p')
  // End of  Selection

  if (pTag) {
    const spanTag = pTag.querySelector('span')
    if (spanTag) {
      pTag.removeChild(spanTag)
    }
    return pTag.textContent!.trimStart()
  }

  return htmlString
}

export const extractNamesFromTimeline = (timelineText: string) => {
  return timelineText.match(/\(([^)]+)\)/g)?.map((name) => name.replace(/[()]/g, '')) || []
}

export const extractRemainingTextFromTimeline = (timelineText: string) => {
  return timelineText.replace(/\([^)]*\)/g, '').trim()
}
export const extractAnchorTags = (text: string) => {
  return text.match(/<a[^>]*>([^<]+)<\/a>/g) || []
}
export const prepareAnchorTagAndText = (remainingText: string) => {
  let anchorTags: { href: string; text: string } | null = null
  if (remainingText.includes('<a')) {
    const aTags = extractAnchorTags(remainingText)
    if (aTags.length > 0) {
      anchorTags = {
        href: aTags[0]?.match(/href='([^']*)'/)?.[1] || '',
        text: aTags[0]?.match(/>([^<]*)<\/a>/)?.[1] || '',
      }
    }
    remainingText = remainingText.replace(/<a[^>]*>([^<]+)<\/a>/g, '$1')
    if (anchorTags && anchorTags?.text) {
      remainingText = remainingText.replace(anchorTags.text, '')
    }
  }
  return {
    remainingText: remainingText,
    anchorTag: anchorTags,
  }
}
export const mostRecentActivity = (timelineTextKeyTimestamp: string[]) => {
  if (timelineTextKeyTimestamp.length === 0) {
    return null
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timelineText, _key, timestamp] = timelineTextKeyTimestamp[0].split(' || ')
  const timeAgo = timeElapsedSinceNow(new Date(timestamp))
  // Extract names between <>
  const names = extractNamesFromTimeline(timelineText)
  const remainingText = extractRemainingTextFromTimeline(timelineText)
  const textWithAnchor = prepareAnchorTagAndText(remainingText)

  return {
    names: names,
    textWithAnchor: textWithAnchor,
    timeAgo: timeAgo,
  }
}
export const setUrlSearchParams = ({ introPage, introName, introStatus, order_by, direction }: IIntroStateParams) => {
  const searchParams = new URLSearchParams()
  if (introPage && introPage.toString().trim() !== '')
    searchParams.set('page', introPage.toString())
  if (introStatus && introStatus.toString().trim() !== '') searchParams.set('status', introStatus)
  if (introName && introName.toString().trim() !== '') searchParams.set('name', introName)
  if (order_by && order_by.toString().trim() !== '') searchParams.set('order_by', order_by)
  if (direction && direction.toString().trim() !== '') searchParams.set('direction', direction)
  return searchParams
}

export const getParamsFromUrlOrState = ({
  stateObject,
  locationSearch,
}: {
  stateObject: IIntroStateParams | undefined
  locationSearch: string
}) => {
  let { introPage, introName, introStatus } = getSearchParams(locationSearch)
  if (!introPage && !introName && !introStatus) {
    ;({ introPage, introName, introStatus } = getStateParams(stateObject))
  }
  introPage = introPage === undefined ? null : introPage
  return { introPage, introName, introStatus }
}
export const getStateParams = (stateObject: IIntroStateParams | undefined) => {
  if (stateObject == null || stateObject == undefined) {
    return { introPage: null, introName: null, introStatus: null }
  }
  return {
    introPage: stateObject.introPage || null,
    introName: stateObject.introName,
    introStatus: stateObject.introStatus,
  }
}
const isNotNullOrEmptyString = (paramString: string | null | undefined) => {
  if (paramString !== null && paramString !== undefined && paramString?.toString().trim() !== '') {
    return true
  } else {
    return false
  }
}
export const getSearchParams = (locationSearch: string) => {
  const searchParams = new URLSearchParams(locationSearch)
  const introPageNumber = searchParams.get('page')
  const introPage = isNotNullOrEmptyString(introPageNumber) ? Number(introPageNumber) : null
  const introSearchName = searchParams.get('name')
  const introName = isNotNullOrEmptyString(introSearchName) ? introSearchName : null
  const introSearchStatus = searchParams.get('status')
  const introStatus = isNotNullOrEmptyString(introSearchStatus) ? introSearchStatus : null
  return { introPage, introName, introStatus }
}
export const updateUrlWithSearchParams = ({
  introPage,
  introName,
  introStatus,
  history,
  order_by,
  direction,
}: IIntroStateParams) => {
  const searchParams = setUrlSearchParams({
    introPage,
    introName,
    introStatus,
    order_by,
    direction,
  })
  history?.replace({
    pathname: location.pathname,
    search: searchParams.toString(),
  })
}
export const removeUrlSearchParam = ({
  searchParam,
  history,
}: {
  searchParam: string
  history: History
}) => {
  const searchParams = new URLSearchParams(location.search)
  searchParams.delete(searchParam)
  history?.replace({
    pathname: location.pathname,
    search: searchParams.toString(),
  })
}
export const statusIconMappingFunc = (team: Team) => {
  let statusIconMapping: StatusType
  if (team.enable_portfolio) {
    statusIconMapping = STATUS_ICON_LABEL_MAPPING
  } else {
    statusIconMapping = STATUS_ICON_LABEL_NON_PORTFOLIO_MAPPING
  }
  return statusIconMapping
}

export const requestCenterStatusFunc = (team: Team) => {
  if (team.enable_portfolio) {
    return REQUEST_CENTER_STATUSES
  } else {
    return REQUEST_CENTER_STATUSES_NON_PORTFOLIO
  }
}
export const prepareStatusForState = (statusArr: string[] | null): string | null => {
  return statusArr ? (statusArr.length === 0 ? null : statusArr.join(',')) : null
}
