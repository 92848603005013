import React from 'react'

import { useMutation } from 'react-query'
import styled from 'styled-components'

import Typography from 'global/Typography'
import { useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

const Wrapper = styled.div`
  grid-area: paywall;
`

const PaywallBanner = () => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { user } = useCurrentUser()

  const createNotificationMutation = useMutation(
    () =>
      callApi(
        api.createNotification,
        `From ${user.first_name} (${user.email}) : ${team!.name} (${
          team!.slug
        }) wants to upgrade to premium`,
      ),
    {
      onSuccess: () => {
        cabalToast({
          style: 'success',
          content: 'Thanks for your interest in upgrading! Our team will follow up shortly.',
        })
      },
    },
  )

  return (
    <Wrapper className="flex w-full bg-[#d44c47] py-2">
      <div className="flex items-center justify-between w-[990px] mx-auto">
        <Typography fontSize="14" color="white">
          Your workspace is on the Free plan
        </Typography>
        <Typography
          fontSize="12"
          color="white"
          className="border border-white rounded px-2 py-1 hover:cursor-pointer"
          onClick={() => createNotificationMutation.mutate()}
        >
          Upgrade to premium
        </Typography>
      </div>
    </Wrapper>
  )
}

export default PaywallBanner
