import React from 'react'

import { useMutation } from 'react-query'

import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { useSlug } from 'utils/hooks/useSlug'

const PersonalDetails = (props: { onNext: () => void }) => {
  const { user } = useCurrentUser()
  const { teamSlug } = useSlug()

  const advisor = React.useMemo(
    () => user.advisors?.find((advisor) => advisor.team_slug === teamSlug),
    [teamSlug, user],
  )

  const [firstName, setFirstName] = React.useState(user?.first_name ?? advisor?.first_name ?? '')
  const [lastName, setLastName] = React.useState(user?.last_name ?? advisor?.last_name ?? '')

  const updatePersonalDetailsMutation = useMutation(
    async () => {
      if (advisor) {
        return callApi(api.advisorUpdateSelf, {
          advisor_uuid: advisor?.uuid,
          updateParams: { first_name: firstName, last_name: lastName },
        })
      }
    },
    {
      onSuccess: props.onNext,
    },
  )

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        updatePersonalDetailsMutation.mutate()
      }}
    >
      <div className="p-5">
        <div className="pb-4 flex gap-8 items-center">
          <div className="flex-1">
            <Typography color="admin_label" className="block p-2" fontSize="12">
              First Name
            </Typography>
            <TextInput
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First name"
              className="w-full"
            />
          </div>
          <div className="flex-1">
            <Typography color="admin_label" className="block p-2" fontSize="12">
              Last Name
            </Typography>
            <TextInput
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="First name"
              className="w-full"
            />
          </div>
        </div>

        <div className="flex justify-end py-2">
          <CabalButton
            type="submit"
            variant="primary"
            data-testid="personal-details-continue"
            working={updatePersonalDetailsMutation.isLoading}
          >
            Continue
          </CabalButton>
        </div>
      </div>
    </form>
  )
}

export default PersonalDetails
