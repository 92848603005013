import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Redirect, useHistory } from 'react-router-dom'

import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import Loading from 'global/Loading'
import Tabs, { Tab } from 'global/Tabs'
import CabalTitle from 'global/Title'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'

import Subtabs from '../Subtabs'
// import Table from './Table'
import EmptyFeedImage from './../../../images/empty-feed@2x.png'
import CardView from './CardView'

const Templates: React.VFC = () => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const history = useHistory()
  const [tab, setTab] = useState<'all' | 'public' | 'private' | 'team'>('all')

  const {
    data,
    isLoading,
    refetch: reloadTemplates,
  } = useQuery(['getTemplates', teamSlug], () => callApi(api.getTemplates, teamSlug), {
    enabled: team?.permissions?.canViewMessageTemplates,
  })

  const templates = useMemo(() => data?.templates ?? [], [data])
  const teamTemplates = useMemo(() => templates.filter((m) => m.public_to_team), [templates])
  const publicTemplates = useMemo(() => templates.filter((m) => m.public_template), [templates])
  const privateTemplates = useMemo(
    () => templates.filter((m) => !m.public_template && !m.public_to_team),
    [templates],
  )

  if (!team?.permissions?.canViewMessageTemplates) {
    return <Redirect to={`/${teamSlug}`} />
  }

  const tabs: Tab[] = [{ label: `All (${templates?.length})`, component: <></>, id: 'all' }]

  if (publicTemplates && publicTemplates?.length > 0) {
    tabs.push({ label: `Public (${publicTemplates?.length})`, component: <></>, id: 'public' })
  }

  if (teamTemplates && teamTemplates?.length > 0) {
    tabs.push({ label: `Team (${teamTemplates?.length})`, component: <></>, id: 'team' })
  }

  if (privateTemplates && privateTemplates?.length > 0) {
    tabs.push({ label: `Private (${privateTemplates?.length})`, component: <></>, id: 'private' })
  }

  const activeIndex = tabs.findIndex((t) => t?.id === tab)

  return (
    <div>
      <CabalTitle title="Templates" />

      {isLoading ? (
        <Loading className="my-12" />
      ) : (
        <div>
          <Subtabs
            rightActions={
              <CabalButton onClick={() => history.push(`templates/new`)}>Add Template</CabalButton>
            }
            noPaddingAndHeight={false}
          />

          {templates.length === 0 && (
            <EmptyState
              image={<img src={EmptyFeedImage} />}
              heading={'Add your first template'}
              body={
                <div className="text-center">
                  <Typography textAlign="center" component="h5" className="mb-11">
                    Create templates to save time when messaging your investors and advisors.
                  </Typography>
                  <div className="flex justify-center">
                    <CabalButton variant="primary" onClick={() => history.push(`templates/new`)}>
                      Add template
                    </CabalButton>
                  </div>
                </div>
              }
            />
          )}

          <div>
            {templates &&
              tab === 'all' &&
              templates.map((template) => (
                <CardView
                  key={template.uuid}
                  template={template}
                  reloadTemplates={reloadTemplates}
                />
              ))}
            {publicTemplates &&
              tab === 'public' &&
              publicTemplates.map((template) => (
                <CardView
                  key={template.uuid}
                  template={template}
                  reloadTemplates={reloadTemplates}
                />
              ))}
            {teamTemplates &&
              tab === 'team' &&
              teamTemplates.map((template) => (
                <CardView
                  key={template.uuid}
                  template={template}
                  reloadTemplates={reloadTemplates}
                />
              ))}
            {privateTemplates &&
              tab === 'private' &&
              privateTemplates.map((template) => (
                <CardView
                  key={template.uuid}
                  template={template}
                  reloadTemplates={reloadTemplates}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Templates
