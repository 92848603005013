import React, { useCallback } from 'react'

import CabalMessage from 'models/CabalMessage'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import Collaborators from 'components/Collaborators'
import CommentsAndActivity from 'components/CommentsAndActivity'
import PageWrapper from 'global/PageWrapper'
import { useCurrentUser } from 'store/hooks'

import api, { callApi } from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'
import { MessageThread, Team } from 'utils/types'

import MessageEngagement from './MessageEngagement'
import RootMessage from './RootMessage'
import SelectedThread from './SelectedThread'
import { MessageWrapper, SideBar } from './styles'

interface MarkThreadAsReadParams {
  messageUuid: string
  messageThreadUuid: string
}

interface Props {
  teamSlug: string
  message: CabalMessage
  messageThreads: MessageThread[]
  reloadMessage: () => Promise<unknown>
  messageThreadId?: string
  setMessageThreadId: (uuid?: string) => void
  minimalView: boolean
  advisorView: boolean
  team: Team
  splitView?: boolean
}

const Message: React.VFC<Props> = ({
  teamSlug,
  message,
  messageThreads,
  reloadMessage,
  minimalView,
  messageThreadId,
  setMessageThreadId,
  advisorView,
  team,
  splitView = false,
}) => {
  const history = useHistory()

  const { user: currentUser } = useCurrentUser()
  const { isMobile } = useColorMode()

  const { mutateAsync: markThreadAsRead } = useMutation<unknown, unknown, MarkThreadAsReadParams>(
    ({ messageUuid, messageThreadUuid }) =>
      callApi(api.postMessageThreadMarkAsRead, teamSlug, messageUuid, messageThreadUuid),
  )

  const handleMarkThreadAsRead = useCallback(
    (messageThreadUuid) => {
      markThreadAsRead({ messageUuid: message!.uuid, messageThreadUuid }).then(() => {
        reloadMessage()
      })
    },
    [markThreadAsRead, message, reloadMessage],
  )

  const handleSetMessageThreadId = (id: string | undefined, forcePushRoute?: boolean) => {
    if (!minimalView || forcePushRoute) {
      let idPart = ''
      if (id) idPart = `/${id}`
      history.push(`/${teamSlug}/messages/${message.uuid}${idPart}`)
    } else {
      setMessageThreadId(id)
    }
  }

  // If there is only one reply, directly show the selected msg view
  const selectedMessageThread =
    messageThreads.length === 1
      ? messageThreads[0]
      : messageThreads?.find((mt) => mt.uuid === messageThreadId)
  const canViewEngagement =
    message.sender?.email == currentUser.email || message.team_slug == currentUser.team?.slug

  const canViewSidebar =
    canViewEngagement ||
    (team.enable_message_collaborators_widget && message.can_current_user_add_collaborators) ||
    message.can_current_user_create_comments

  const messageRender = (
    <MessageWrapper className="flex justify-between" height={splitView ? '90px' : '126px'}>
      <div className={'w-full overflow-auto'}>
        {!!selectedMessageThread ? (
          // hash = selected subthread
          <SelectedThread
            loadMessage={reloadMessage}
            messageThread={selectedMessageThread}
            message={message}
            advisorView={advisorView}
            onMarkMessageThreadAsRead={handleMarkThreadAsRead}
            onSetMessageThread={handleSetMessageThreadId}
            minimalView={minimalView}
          />
        ) : (
          <RootMessage
            message={message}
            messageThreads={messageThreads}
            isAdvisorView={advisorView}
            onReload={reloadMessage}
            onSetMessageThread={handleSetMessageThreadId}
            minimalView={minimalView}
          />
        )}
      </div>

      {!isMobile && canViewSidebar && (
        <SideBar minimal={minimalView}>
          {canViewEngagement && <MessageEngagement companySlug={teamSlug} message={message} />}

          {team.enable_message_collaborators_widget &&
            message.can_current_user_add_collaborators && (
              <Collaborators
                teamSlug={teamSlug}
                attachableType={selectedMessageThread ? 'MessageThread' : 'Message'}
                attachableUuid={selectedMessageThread ? selectedMessageThread.uuid : message.uuid}
                collaborators={
                  selectedMessageThread
                    ? selectedMessageThread.collaborators || []
                    : message.collaborators || []
                }
                onReload={reloadMessage}
              />
            )}

          {message.can_current_user_create_comments && (
            <CommentsAndActivity
              message={message}
              messageThreadId={messageThreadId}
              teamSlug={message.rawMessage.team_slug}
            />
          )}
        </SideBar>
      )}
    </MessageWrapper>
  )

  if (splitView) return messageRender

  return (
    <PageWrapper
      title={message.subject}
      fullHeight={true}
      hideTitle
      fullWidth={minimalView}
      noPadding={minimalView}
      crumbs={[
        { title: 'Messages', url: `/${teamSlug}/messages` },
        { title: message.subject, url: `/${teamSlug}/messages/${message.rawMessage?.uuid}` },
      ]}
      hideCrumbs={splitView}
    >
      {messageRender}
    </PageWrapper>
  )
}

export default Message
