import React, { useState } from 'react'

import { Field, FieldProps, Form, Formik } from 'formik'
import { useMutation } from 'react-query'

import {
  getLinkedInSlug,
  linkedInAutoPrefillSchema,
} from 'containers/CandidateProfileModal/linkedInAutoPrefill'
import CabalButton from 'global/CabalButton'
import { TextInputField } from 'global/Input'
import Typography from 'global/Typography'

import api, { callApi } from 'utils/api'

interface Props {
  postEnrichment: (data: any) => void
}

const EnrichFromLinkedin = ({ postEnrichment }: Props) => {
  const { mutate: enrichProfileMutation, isLoading: profileLoading } = useMutation(
    ['enrichProfile'],
    ({ linkedInSlug, linkedinUrl }: { linkedInSlug: string; linkedinUrl: string }) =>
      callApi(api.enrichProfile, linkedInSlug),
    {
      onSuccess: ({ profile }, { linkedinUrl }) => {
        postEnrichment({ ...profile, linkedin_url: linkedinUrl })
      },
    },
  )

  return (
    <Formik
      initialValues={{
        linkedInProfileUrl: '',
      }}
      validationSchema={linkedInAutoPrefillSchema}
      onSubmit={(values) => {
        const linkedInSlug = getLinkedInSlug(values.linkedInProfileUrl)
        enrichProfileMutation({ linkedInSlug, linkedinUrl: values.linkedInProfileUrl })
      }}
    >
      {({ handleSubmit }) => (
        <Form className="flex flex-col items-start" onSubmit={handleSubmit}>
          <Typography fontSize="14" className="pb-1" color="fog">
            Enter LinkedIn profile URL to autofill details
          </Typography>
          <Field name="linkedInProfileUrl">
            {(props: FieldProps) => (
              <TextInputField
                error={props.meta.touched ? props.meta.error : undefined}
                autoFocus
                {...props.field}
                className="w-full"
              />
            )}
          </Field>

          <CabalButton
            className="mt-6 mb-2 self-end"
            variant="primary"
            type="submit"
            disabled={profileLoading}
          >
            {profileLoading ? 'Enriching...' : 'Next'}
          </CabalButton>
        </Form>
      )}
    </Formik>
  )
}

export default EnrichFromLinkedin
