import React, { useMemo, useState } from 'react'

import { Command } from 'cmdk'
import CabalMessage from 'models/CabalMessage'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import { useAdvisors, useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'
import useOutsideClickDetector from 'utils/hooks/useOutsideClickDetector'
import { MessageModel, Team } from 'utils/types'

const Input = styled(Command.Input)`
  ${tw`px-3 py-1.5 relative pl-4`}
  width: 300px;
  font-size: 14px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.layout.main_bg_color};
  &::placeholder {
    color: ${({ theme }) => theme.colors.primary};
    opacity: 0.4;
    font-size: 13px;
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.purple};
  }
`

const List = styled(Command.List)`
  ${tw`px-1 py-1.5 absolute my-1 shadow-md border rounded`}
  border-color: ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.layout.main_content_bg_color};
  z-index: 1001;
  max-height: calc(100% - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 300px;
`

const Group = styled(Command.Group)`
  font-weight: 600;
  font-size: 13px;
  ${tw`py-1 px-1`};
  color: ${({ theme }) => theme.colors.primary};
`

const Item = styled(Command.Item)`
  ${tw`cursor-pointer rounded p-1.5 pl-2`}
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};

  &[aria-selected='true'] {
    background-color: ${({ theme }) => theme.buttons.primary.bg_color};
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    background-color: ${({ theme }) => theme.buttons.primary.fog_rain};
    color: ${({ theme }) => theme.colors.white};
  }
`

const GlobalSearch = () => {
  const [loading, setLoading] = React.useState(false)
  const [sections, setSections] = React.useState<any[]>([])
  const [hideOptions, setHideOptions] = React.useState(true)
  const [query, setQuery] = React.useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const optionsRef = React.useRef<HTMLDivElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const history = useHistory()
  const [teams, setTeams] = useState([])
  const { user } = useCurrentUser()
  const teamSlug = user!.team!.slug
  const { advisors: allAdvisors } = useAdvisors({ teamSlug })

  // load workspaces
  useQuery(['getHomeTeams'], () => callApi(api.getHomeTeams), {
    onSuccess: ({ teams }) => {
      setTeams(teams)
    },
  })

  // load accounts
  const { data: companyData, isLoading: companiesLoading } = useInfiniteQuery(
    [teamSlug, 'opportunityData', debouncedQuery],
    ({ pageParam: page = 1 }) =>
      callApi(
        api.getCompanies,
        teamSlug, // team slug
        page, // page
        {
          sources: [],
          search: [debouncedQuery],
        }, // filters
        {}, // extra params
      ),
    {
      cacheTime: 0,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      keepPreviousData: true,
      enabled: debouncedQuery !== '',
    },
  )

  const companies = React.useMemo(() => {
    return (
      companyData?.pages?.flatMap((page) => {
        const { company_records } = page
        const data = company_records ? Object.values(company_records) : []
        return data
      }) || []
    )
  }, [companyData])

  // load messages
  const { data: messagesData, isLoading: messagesLoading } = useInfiniteQuery(
    [teamSlug, 'getSearchMessage', debouncedQuery, false],
    ({ pageParam: page = 1 }) =>
      callApi(api.getSearchMessage, {
        team_slug: teamSlug,
        query: debouncedQuery,
        page,
      }),
    {
      getNextPageParam: (lastPage) => lastPage?.pagination?.next_page,
      getPreviousPageParam: (firstPage) => firstPage?.pagination?.prev_page,
      enabled: debouncedQuery !== '',
    },
  )

  const messageItems = useMemo(
    () =>
      CabalMessage.fromArray(messagesData?.pages?.flatMap<MessageModel>((page) => page.messages))
        .map((message: MessageModel) => ({
          uuid: message.uuid,
          name: message.subject,
          subtitle: `To: ${message.recipients?.map((r) => r.label).join(', ')}`,
          // subtitle: message.snippet,
        }))
        .slice(0, 5),
    [messagesData],
  )

  // load people search
  const { data: connectionsData, isLoading: peopleLoading } = useInfiniteQuery(
    [teamSlug, 'connections', debouncedQuery],
    ({ pageParam: page = 1 }) =>
      callApi(api.getConnections, debouncedQuery, null, [], teamSlug, false, page),
    {
      cacheTime: 0,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      enabled: debouncedQuery !== '',
    },
  )

  const people = connectionsData?.data?.pages?.flatMap((page) => page.people) ?? []

  React.useEffect(() => {
    const down = (e) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        inputRef.current?.focus()
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  React.useEffect(() => {
    setLoading(true)

    const portfolio_teams = teams.filter((team: Team) => team.slug !== user.team?.slug)
    const myTeamIndex = teams.findIndex((team: Team) => team.slug === user.team?.slug)
    const orderedTeams = [teams[myTeamIndex], ...portfolio_teams].filter(Boolean)

    const advisors = !allAdvisors
      ? []
      : allAdvisors
          .filter((advisor) => advisor.name.toLowerCase().includes(debouncedQuery.toLowerCase()))
          .sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
            return 0
          })

    const advisorItems = advisors
      ?.map((advisor) => ({
        uuid: advisor.uuid,
        name: advisor.name,
        subtitle: advisor.email,
      }))
      .slice(0, 10)

    const teamItems = orderedTeams
      .filter((team: Team) => team.name.toLowerCase().includes(debouncedQuery.toLowerCase()))
      .map((team: Team) => ({
        uuid: team.slug,
        name: team.name,
        logo_url: team.logo_url,
      }))

    const newSections = []

    if (teamItems.length > 0) {
      newSections.push({
        name: 'Workspaces',
        items: teamItems,
      })
    }

    if (messageItems.length > 0) {
      newSections.push({
        name: 'Messages',
        items: messageItems,
      })
    }

    if (advisorItems.length > 0) {
      newSections.push({
        name: 'Members',
        items: advisorItems,
      })
    }

    if (companies.length > 0) {
      newSections.push({
        name: 'Companies',
        items: companies.map((company) => ({
          uuid: company.uuid,
          name: company.name,
          subtitle: company.domain,
          logo_url: company.logo_url,
        })),
      })
    }

    setSections(newSections.length > 0 ? newSections : [])
    setLoading(false)
  }, [debouncedQuery, allAdvisors, teams, user, companies, messageItems])

  useOutsideClickDetector({
    refs: [optionsRef],
    callback: () => {
      setHideOptions(true)
    },
  })

  const stillLoading = loading || companiesLoading || messagesLoading || peopleLoading

  return (
    <Command label="Command Menu" shouldFilter={false}>
      <Input
        placeholder={`Search companies, people and messages`}
        value={query}
        onValueChange={setQuery}
        onFocus={() => setHideOptions(false)}
        ref={inputRef}
        className="rounded-full"
      />
      <List ref={optionsRef} hidden={debouncedQuery === '' || hideOptions}>
        {/* <div className="text-xs pb-1">{team?.name}</div> */}
        {stillLoading && (
          <Typography fontSize={'13'} color={'fog'}>
            <i className="fa-spin fa-solid fa-spinner mr-1"></i> Searching
          </Typography>
        )}
        {!stillLoading && sections?.length == 0 && companies.length == 0 && (
          <Command.Empty style={{ padding: 10, fontSize: 13 }}>No results found.</Command.Empty>
        )}
        {sections?.map((section) => {
          const { name, items } = section
          return (
            <Group key={name} heading={name}>
              {items.map((item) => (
                <Item
                  key={item.uuid}
                  onSelect={() => {
                    setQuery('')
                    if (name == 'Workspaces') {
                      history.push(`/${item.uuid}`)
                    } else if (name == 'Members') {
                      history.push(`/${teamSlug}/members/${item.uuid}`)
                    } else if (name == 'Companies') {
                      history.push(`/${teamSlug}/lists/companies/${item.uuid}`)
                    } else if (name == 'Messages') {
                      history.push(`/${teamSlug}/messages/${item.uuid}`)
                    }
                  }}
                >
                  {item.logo_url && <Avatar size="24px" src={item.logo_url} className="mr-2" />}
                  <Typography>{item.name}</Typography>
                  <div>
                    <Typography fontSize="12" color="fog_rain">
                      {item.subtitle}
                    </Typography>
                  </div>
                </Item>
              ))}
            </Group>
          )
        })}

        {people.length > 0 && (
          <Group key="people" heading="People">
            {people.map((person) => (
              <Item
                key={person.uuid}
                value={person.uuid}
                onSelect={() => {
                  setQuery('')
                  history.push(`/${teamSlug}/person/${person.uuid}`)
                }}
              >
                <Typography>{person.name}</Typography>
                <div>
                  <Typography fontSize="12" color="fog_rain">
                    {person.headline}
                  </Typography>
                </div>
              </Item>
            ))}
          </Group>
        )}
      </List>
    </Command>
  )
}

export default GlobalSearch
