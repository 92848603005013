import * as React from 'react'

import axios, { AxiosError } from 'axios'
import cx from 'classnames'
import Cookies from 'js-cookie'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import { cabalToast } from 'ui-components/Toast'

import useColorMode from 'utils/hooks/useColorMode'

// import Wild3 from '../../../images/wild3.png'
import GoogleIcon from '../../../images/google-icon.png'
import MicrosoftIcon from '../../../images/microsoft-icon.png'
import Section1LT from '../../../images/new-section-1-light.png'
// import Section1DK from '../../../images/section-1-dark.png'
import Section2LT from '../../../images/new-section-2-light.png'
// import Section2DK from '../../../images/section-2-dark.png'
import Section3LT from '../../../images/new-section-3-light.png'
// import Section3DK from '../../../images/section-3-dark.png'
import Section4LT from '../../../images/new-section-4-light.png'
import DavidAlexis from '../../../images/new_david-alexis.png'
import DefaultImg from '../../../images/pc.png'
// import Section4DK from '../../../images/section-4-dark.png'
import WhyCabal from '../../../images/why-cabal-light.png'
import Wild1 from '../../../images/wild1.png'
import Wild2 from '../../../images/wild2.png'
import Blog11 from '../BlogHeroes/nasa-17.jpg'
import Blog12 from '../BlogHeroes/nasa-23.jpg'
import Blog13 from '../BlogHeroes/nasa-26.jpg'
import AudioCard from './audioCard'
import BottomSection from './bottomSection'
import Calendly from './calendly'
import ContentCard from './contentCard'
import Footer from './footer'
import Highlights from './highlights'
import LogoWall from './logoWall'
import Nav from './nav'
import {
  ColumnDoubleLayout,
  ColumnLayout,
  Divider,
  H1,
  H2,
  H5,
  InputWrap,
  LandingPageWrapper,
  OptionLink,
  OptionText,
  Para,
  SectionContainer,
  SignupContainer,
  SmallerColumnLayout,
  Subtitle,
  SuccessPrimary,
} from './styles'

const H3 = styled.h3`
  font-weight: 600;
  padding-bottom: 24px;
  font-size: 42px;
  line-height: 1.05;
  letter-spacing: -0.03em;

  @media only screen and (max-width: 650px) {
    font-size: 24px;
  }
`

const H4 = styled.h4`
  font-weight: 600;
  font-size: 36px;
  line-height: 1.05;
  letter-spacing: -0.03em;
`

const SectionBg = styled(SectionContainer)`
  background: linear-gradient(180deg, #f9fafb 0%, rgba(249, 250, 251, 0) 100%);

  position: absolute;
  /* margin-top: 258px; */
  height: 700px;
  width: 100%;
  top: 150px;

  /* @media (prefers-color-scheme: dark) {
    background-color: #1f2835;
  } */
`

export const ColumnTripleLayout = styled(ColumnDoubleLayout)`
  padding-bottom: 0px;
  @media (min-width: 650px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 32px;
    place-items: center;
  }
`

const SectionTitle = styled.div`
  ${tw`flex justify-center`}
`

const ImgSection = styled.section`
  position: relative;
  overflow: hidden;

  @media (min-width: 650px) {
    bottom: 95px;
    left: 100px;
  }
`

const AuthButtonAnchor = styled.a`
  /* position: absolute; */
  width: 100%;
  border: 0;
  outline: none;
  transition: visibility 0.3s linear, opacity 0.3s linear;
`

const FormWrapper = styled.div<{ show: boolean }>`
  max-width: 420px;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: visibility 0.3s linear, opacity 0.3s linear;

  @media (min-width: 650px) {
    max-width: 320px;
  }
`

const InputDiviter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -45px;
`

const HeaderContainer = styled(SectionContainer)`
  background-size: 400px;
  height: 1180px;
  background-image: url(/mobile-hero.png);
  background-repeat: no-repeat;
  background-position: center bottom;

  @media (min-width: 650px) {
    background-size: 1742px;
    height: 1420px;
    background-image: url(/hero-desktop-combined.png);
    background-repeat: no-repeat;
    background-position: 30% bottom;
  }
`

const SignupScreen = (props: any) => {
  React.useEffect(() => {
    Cookies.set('signup', 'true', { expires: 1 / 12 })
  }, [])

  return (
    <LandingPageWrapper>
      <SignupContainer data-testid="landing-page">
        <Nav />
        {/* Header Concent Section */}
        <HeaderContainer>
          <SectionContainer>
            <div className="flex flex-col items-center p-5 overflow-hidden">
              <ColumnLayout>
                <section className="mx-auto mt-12 sm:mt-20">
                  <div className="flex justify-center items-center flex-col">
                    <H1 className="text-center max-w-xl">
                      Get more out of your investors and advisors
                    </H1>
                    <Subtitle className="pt-4 text-center max-w-2xl">
                      Build your network of supporters. Then get them to help you sell, market,
                      recruit, and guide you to victory.
                    </Subtitle>
                  </div>

                  <div className="mt-8">
                    <InputWrap className="mx-auto relative">
                      <AuthButtonAnchor href="/users/auth/google_oauth2">
                        <CabalButton variant="auth" leftIcon={<img src={GoogleIcon} width="18" />}>
                          <span style={{ fontSize: '14px' }}>Sign in with Google</span>
                        </CabalButton>
                      </AuthButtonAnchor>

                      <AuthButtonAnchor href="/auth/microsoft">
                        <CabalButton
                          variant="auth"
                          leftIcon={<img src={MicrosoftIcon} width="18" />}
                        >
                          <span style={{ fontSize: '14px' }}>Sign in with Microsoft</span>
                        </CabalButton>
                      </AuthButtonAnchor>

                      <Calendly buttonView={true} />

                      <div className="flex justify-center items-center">
                        <OptionText className="mr-2">or</OptionText>
                        <OptionLink href="/login">continue with your email</OptionLink>
                      </div>
                    </InputWrap>
                  </div>
                </section>
              </ColumnLayout>
              {/* <ImgSection>
            <img src={DesktopHero} />
          </ImgSection> */}
            </div>
          </SectionContainer>
        </HeaderContainer>

        {/* Logo wall */}
        <SectionContainer>
          <ColumnLayout>
            <div className="max-w-2xl mx-auto text-center mt-14">
              <Para>Trusted by thousands of founders, funds, and teams at companies like:</Para>
            </div>
          </ColumnLayout>
        </SectionContainer>
        <SectionContainer>
          <SmallerColumnLayout>
            <LogoWall />
          </SmallerColumnLayout>
        </SectionContainer>

        {/* Cabal Magic section */}
        <SectionContainer className="mb-20 sm:mb-28">
          <SmallerColumnLayout className="">
            <Divider className="mt-20 mb-12 sm:mb-28" />
            <SectionTitle className="max-w-xl mx-auto text-center">
              <H3>A workspace designed to engage your supporters</H3>
            </SectionTitle>
          </SmallerColumnLayout>
        </SectionContainer>
        <SectionContainer>
          <SmallerColumnLayout className="">
            <Highlights
              title="Help them help you"
              description={`Get sales intros, social boosts, or candidate referrals with targeted asks.`}
              subDescription={`Sync your CRM and invite your investors to tap into their network of connections.`}
              // img={Section1DK}
              imgLT={Section1LT}
            />
            <Highlights
              title="Send better updates"
              description={`
              Keep supporters in the loop on your progress with 1:1 emails that get more engagement.
              Use our pre-built investor update template or create your own.
            `}
              // img={Section3DK}
              imgLT={Section2LT}
            />
            <Highlights
              title="Automate your workflow"
              description={`
              Remove the back & forth of activating your supporters with out-of-the-box templates,
              standard agreements, and a collaborative email workflow.
            `}
              // img={Section2DK}
              imgLT={Section3LT}
            />
            <Highlights
              title="Reward supporters with equity"
              description={`
              Engage with your portfolio companies from a single workspace. Make intros, track contributions, and connect top talent.
            `}
              // img={Section4DK}
              imgLT={Section4LT}
            />
          </SmallerColumnLayout>
        </SectionContainer>

        {/* Why cabal sections */}
        <SectionContainer>
          <SmallerColumnLayout className="">
            <Divider className="mb-24" />
          </SmallerColumnLayout>
        </SectionContainer>
        <SectionContainer>
          <ColumnDoubleLayout>
            <div>
              <H4>Why Cabal?</H4>
            </div>

            <img src={WhyCabal} className="rounded-2xl" />
          </ColumnDoubleLayout>
        </SectionContainer>
        <SectionContainer>
          <SmallerColumnLayout className="">
            <Divider className="mb-24" />
          </SmallerColumnLayout>
        </SectionContainer>
        <SectionContainer>
          <SmallerColumnLayout>
            <H5 className="mb-6">Explore our blog</H5>
          </SmallerColumnLayout>
        </SectionContainer>
        <SectionContainer>
          <ColumnTripleLayout>
            <ContentCard
              img={Blog11}
              title={'How to build a modern advisor program'}
              body={`Today there are more accelerators, incubators, funds, tools, and resources than founders know what to do with.`}
              redirect={'/blog/modern-advisor-program'}
            />
            <ContentCard
              img={Blog12}
              title={'You don’t need to be rich to be an angel'}
              body={`Share equity with customers, partners, vendors, and power users who aren’t living in the tech echo chamber.`}
              redirect={'/blog/be-an-angel'}
            />
            <ContentCard
              img={Blog13}
              title={'How to bust the email feedback loop'}
              body={`If you’re lucky, the feedback loop happens once. For most communications, it can go on for several threads before the content is ready.`}
              redirect={'/blog/email-feedback-loop'}
            />
          </ColumnTripleLayout>
        </SectionContainer>
        <SectionContainer>
          <SmallerColumnLayout className="">
            <Divider className="my-24" />
          </SmallerColumnLayout>
        </SectionContainer>
        <SectionContainer>
          <SmallerColumnLayout>
            <H5 className="mb-6">Cabal in the wild</H5>
          </SmallerColumnLayout>
        </SectionContainer>
        <SectionContainer>
          <ColumnTripleLayout>
            <ContentCard
              img={Wild1}
              title={'PLG123 Episode 85: Cabal in 100 Seconds'}
              body={''}
              cta={'Watch on LinkedIn'}
              redirect={
                'https://www.linkedin.com/posts/blakebartlett_founders-community-plg123-activity-6915672295818620928-W9US?utm_source=linkedin_share&utm_medium=member_desktop_web'
              }
            />
            <ContentCard
              img={Wild2}
              title={`UserGems' Sales Funnel Cheat Code`}
              body={''}
              cta={'Read on LinkedIn'}
              redirect={
                'https://www.linkedin.com/posts/blaise-bevilacqua_heres-a-cheat-code-ive-been-using-to-accelerate-activity-6922992641362046976-W-7y?utm_source=linkedin_share&utm_medium=member_desktop_web'
              }
            />
            <AudioCard
              img={DavidAlexis}
              title={'Why Craft & Seven Seven Six backed Cabal'}
              body={''}
              audioURL={'https://getcabal.s3.amazonaws.com/audio/david_alexis.mp3'}
            />
            {/* <ContentCard
            img={Wild3}
            title={`Leveraging LawTrade's Cap Table`}
            body={''}
            cta={'Read on Twitter'}
            redirect={'https://twitter.com/r44d/status/1514751476249214978?s=21'}
          /> */}
          </ColumnTripleLayout>
        </SectionContainer>
        {/* Footer */}

        <SectionContainer className="mt-12 sm:mt-32 relative flex-col items-center -mb-10">
          <SectionBg />
          <ColumnLayout className="z-10">
            <BottomSection />
          </ColumnLayout>
          <div className="z-10 mt-10 mb-6 sm:mb-0">
            <div>
              <Footer withForm={false} />
            </div>
          </div>
        </SectionContainer>
      </SignupContainer>
    </LandingPageWrapper>
  )
}

export default SignupScreen
