import React, { useEffect, useState } from 'react'

import { first } from 'lodash'
import CabalMessage from 'models/CabalMessage'
import { useQuery } from 'react-query'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import EmptyState from 'global/EmptyState'
import Loading from 'global/Loading'
import { useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'

import Message from './Message'

export interface MessagesRouteParams {
  message_id: string
  message_thread_id?: string
  splitView?: boolean
}

interface Props extends Partial<MessagesRouteParams> {
  /**
   * make it sticky with the offset (tailwind top-{n})
   */
  sitcky?: number
}

const ViewMessage: React.VFC<Props> = ({
  message_id: messageIdProp,
  message_thread_id: messageThreadIdProp = null,
  splitView = false,
}) => {
  const { message_id: messageIdParam, message_thread_id: messageThreadIdParam } =
    useParams<MessagesRouteParams>()

  const [teamSlug, setTeamSlug] = useState<string | undefined>()
  const [team, setTeam] = useState()

  let currentTeamSlug = useTeamSlug()
  let currentTeam = useTeam(teamSlug)?.team
  const { user } = useCurrentUser()

  useEffect(() => {
    if (!currentTeamSlug) {
      setTeamSlug(user.team?.slug)
      setTeam(user.team)
    } else {
      setTeamSlug(currentTeamSlug)
      setTeam(currentTeam)
    }
  }, [user, currentTeam, currentTeamSlug])

  const [messageId, setMessageId] = useState<string>(messageIdProp || messageIdParam)
  const [messageThreadId, setMessageThreadId] = useState<string | undefined>(
    messageThreadIdProp || messageThreadIdParam,
  )
  const [unauthorized, setUnauthorized] = useState(false)
  const minimalView = !!messageIdProp

  useEffect(() => {
    const newMessageId = messageIdProp || messageIdParam
    const newMessageThreadId = messageThreadIdProp || messageThreadIdParam
    if (newMessageId !== messageId) setMessageId(newMessageId)
    if (newMessageThreadId !== messageThreadId) setMessageThreadId(newMessageThreadId)
  }, [messageIdParam, messageIdProp, messageThreadIdParam, messageThreadIdProp])

  const {
    isLoading: loading,
    isError,
    data: messageResponse,
    refetch: reloadMessage,
  } = useQuery(
    ['getMessage', teamSlug, messageId],
    () => callApi(api.getMessage, teamSlug, messageId!),
    {
      enabled: !!messageId,
      onSuccess: ({ message_threads, error }) => {
        if (error == 'unauthorized') {
          setUnauthorized(true)
        } else if (advisorView) {
          setMessageThreadId(first(message_threads)?.uuid)
        }
      },
    },
  )

  if (isError) {
    cabalToast({ style: 'error', content: 'Error loading message' })
    return <Redirect to={`/${teamSlug}/messages`} />
  }

  if (unauthorized) {
    return (
      <EmptyState
        icon={<i className="fa-light fa-lock" />}
        heading="Unauthorized"
        body="You are not authorized to view this message"
      />
    )
  }

  const {
    message: _rootMessage,
    message_threads: messageThreads,
    advisor_view: advisorView = false,

    /** inbox messages data */
    inbox_messages: _inboxMessages,
    collaborators,
  } = messageResponse || {}

  const rootMessage = CabalMessage.from(_rootMessage)
  const inboxMessages = CabalMessage.fromArray(_inboxMessages)

  if (loading || (!rootMessage && !inboxMessages.length) || !team) {
    return <Loading className="pt-12" />
  }

  if (rootMessage && messageThreads) {
    return (
      <Message
        teamSlug={teamSlug}
        message={rootMessage}
        messageThreads={messageThreads}
        reloadMessage={reloadMessage}
        messageThreadId={messageThreadId}
        setMessageThreadId={setMessageThreadId}
        minimalView={minimalView}
        advisorView={advisorView}
        team={team}
        splitView={splitView}
      />
    )
  } else if (inboxMessages) {
    return (
      <InboxMessage
        team={team}
        collaborators={collaborators!}
        reloadMessage={reloadMessage}
        minimalView={minimalView}
        messages={inboxMessages}
        threadUuid={messageId}
        splitView={splitView}
      />
    )
  }

  return <></>
}

export default ViewMessage
