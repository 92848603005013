import React, { useState } from 'react'

import compact from 'lodash/compact'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import CrmIcon from 'components/CrmIcon'
import CabalButton from 'global/CabalButton'
import { Select, TextInput } from 'global/Input'
import Loading from 'global/Loading'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { joinReactElements } from 'utils/array'
import { CompanyListBlueprint } from 'utils/types'

interface Props {
  teamSlug: string
}

const InLineShareExistingList: React.FC<Props> = ({ teamSlug }) => {
  const { team } = useTeam(teamSlug)
  const { user } = useCurrentUser()
  const { team: userTeam } = useTeam(user.team?.slug)
  const history = useHistory()
  const [selectedList, setSelectedList] = useState<CompanyListBlueprint | null>()

  const getListsQuery = useQuery(
    ['getCompanyLists', userTeam?.slug],
    () => callApi(api.getCompanyLists, userTeam!.slug),
    {
      enabled: !!userTeam,
    },
  )
  const companyLists = (getListsQuery.data?.company_lists || []).filter(
    (l) => !l.portfolio && !!l.owned,
  )

  const { mutate: updateCompanyListAccess, isLoading: isAddingAccess } = useMutation(
    ['updateCompanyListAccess'],
    (list: CompanyListBlueprint) =>
      callApi(api.addListAccessors, user.team?.slug, list.uuid, {
        accessors: [{ accessor_type: 'Team', accessor_uuid: team.slug }],
        notify: true,
        message: '',
      }),
    {
      onSuccess: (_, list) => {
        // Show toast message
        cabalToast({
          content: `Success! ${list.name} shared with ${team?.name}. The team has been notified`,
          style: 'success',
        })
        // Reset the selected list
        setSelectedList(null)
      },
    },
  )

  return (
    <div className="mb-4">
      <Select
        className="w-full"
        value={selectedList}
        options={companyLists.map((l) => ({
          label: joinReactElements(
            compact([l.name, `${l.item_count} items`, l.provider && <CrmIcon crm={l.provider} />]),
            () => ' - ',
          ),
          toString: () => l.name,
          value: l,
        }))}
        onChange={(v) => {
          setSelectedList(v)
        }}
        isClearable
        placeholder="Select an existing list"
        lightBg
      />
      {selectedList && (
        <div className="mt-3 mb-8 flex justify-center w-full">
          <CabalButton
            className="w-1/3"
            working={isAddingAccess}
            onClick={() => selectedList && updateCompanyListAccess(selectedList)}
            disabled={!selectedList}
          >
            Share with {team?.name}
          </CabalButton>
        </div>
      )}
    </div>
  )
}

export default InLineShareExistingList
