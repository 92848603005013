import React from 'react'

import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'

const SectionWrapper = styled.div`
  border: ${({ theme }) => theme.border};
`

const FullSizeSkeleton = styled(Skeleton)`
  width: 100%;
  height: 100%;
`

const SkeletonChart = () => {
  return (
    <SectionWrapper className={'relative rounded-md hover:shadow-md h-[318px]'}>
      <div className={'p-5 flex flex-col gap-1 h-full'}>
        <Skeleton height={21} width={60} />
        <div className={'flex justify-between items-center'}>
          <Skeleton height={30} width={60} />
          <Skeleton height={16} width={60} />
        </div>
        <div className="flex w-full h-full mt-3" style={{ position: 'relative' }}>
          <FullSizeSkeleton
            style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
          />
        </div>
      </div>
    </SectionWrapper>
  )
}

export default SkeletonChart
