import React from 'react'

import classNames from 'classnames'
import { Link } from 'react-router-dom'

import Typography from 'global/Typography'

import { StyleProps } from 'utils/types'

import { ActivityWrapper } from './styles'

interface Props extends StyleProps {
  link: string
  limit?: number
  objectArray?: any[]
  title: string
  hideMore?: boolean
}

const ShowMore: React.FC<Props> = ({
  link,
  limit,
  objectArray,
  title,
  hideMore = true,
  className,
}) => {
  const hideShowMore = (objectArray && limit && objectArray.length <= limit) || hideMore

  return (
    <>
      {!hideShowMore && (
        <Link to={link}>
          <ActivityWrapper className={classNames('flex justify-center items-center', className)}>
            <Typography className="cursor-pointer" fontSize={'12'} color={'fog'}>
              {title}
            </Typography>
          </ActivityWrapper>
        </Link>
      )}
    </>
  )
}

export default ShowMore
