import React, { useEffect, useState } from 'react'

import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import AdvisorInviteModal from 'components/AdvisorInvite'
import { useComposer } from 'components/Composer'
import MembersEmptyState from 'components/EmptyStates/MembersEmptyState'
import Filter from 'containers/Members/Filter'
import MemberCard from 'containers/Members/MemberCard'
import NoResults from 'containers/Members/NoResults'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import { MultiSelect } from 'global/Input'
import Loading from 'global/Loading'
import Modal, { useModal } from 'global/Modal'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import {
  useAdvisors,
  useAppDispatch,
  useCurrentUserSettings,
  useGroups,
  useTeam,
  useTeamSlug,
} from 'store/hooks'
import { removeAdvisors } from 'store/reducers/advisorsReducer'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'
import { AdvisorModel, Params } from 'utils/types'

import Grid from './Grid'

const Members = () => {
  const history = useHistory()
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()

  const { groups, reloadGroups } = useGroups(teamSlug)
  const { team } = useTeam(teamSlug)
  const { settings } = useCurrentUserSettings()
  const { member_view_prefrence } = settings
  const { advisors: allAdvisors, reloadAdvisors, isAdvisorLoaded } = useAdvisors({ teamSlug })
  const dispatch = useAppDispatch()
  const {
    canExportAdvisors,
    canAddAdvisor,
    canEditAdvisors,
    canMessageAdvisors,
    canEditGroups,
    isAdminOrEmployee,
  } = useAccessControl(teamSlug)
  const { compose } = useComposer()
  const { isMobile } = useColorMode()

  const [selectedAdvisors, setSelectedAdvisors] = useState<AdvisorModel[]>([])
  const [filteredAdvisors, setFilteredAdvisors] = useState<AdvisorModel[]>()
  const [contributions, setContributions] = useState({})
  // const [showFilterDialog, toggleFilterDialog] = useState(false)
  const [viewStyle, setViewStyle] = useState<'list' | 'grid'>('list')
  const [showGroupModal, setShowGroupModal] = useState(0)
  const [bulkGroups, setBulkGroups] = useState<string[]>([])
  const [workingBulkAction, setWorkingBulkAction] = useState(false)
  const [filters, setFilters] = useState<{ search: string; groups: string[] }>({
    search: '',
    groups: [],
  })

  const { data: connectionStats, refetch: reloadConnectionStats } = useQuery(
    [teamSlug, 'connection_stats'],
    () => callApi(api.getConnectionStats, teamSlug),
    {
      enabled: !!isAdminOrEmployee,
    },
  )

  const { mutate: createGroup, isLoading: creatingGroup } = useMutation(
    ['createGroup', teamSlug],
    (name: string) => callApi(api.createGroup, teamSlug, { name }),
    {
      onSuccess: ({ group }) => {
        reloadGroups().then(() => {
          setBulkGroups([...bulkGroups, group.uuid])
        })
      },
    },
  )

  const { mutate: getContributions } = useMutation(
    ['createGroup', teamSlug],
    () => callApi(api.getContributions, teamSlug),
    {
      onSuccess: ({ contributions }) => {
        setContributions(contributions || {})
      },
    },
  )

  useEffect(() => {
    if (isAdvisorLoaded) {
      getContributions()
    }
  }, [isAdvisorLoaded])

  useEffect(() => {
    setSelectedAdvisors([])
  }, [filteredAdvisors])

  useHotkeys(
    'cmd+shift+a',
    (e) => {
      e.preventDefault()
      if (!!canEditAdvisors && selectedAdvisors.length > 0) {
        setShowGroupModal(1)
        setBulkGroups([])
      }
    },
    {},
    [selectedAdvisors],
  )

  useHotkeys(
    'cmd+shift+d',
    (e) => {
      e.preventDefault()
      if (!!canEditAdvisors && selectedAdvisors.length > 0) {
        setShowGroupModal(-1)
        setBulkGroups([])
      }
    },
    {},
    [selectedAdvisors],
  )

  useEffect(() => {
    if (!team || member_view_prefrence || isMobile) return
    // if (!canMessageAdvisors) {
    //   if (viewStyle !== 'grid') setViewStyle('grid')
    // } else {
    //   if (viewStyle !== 'list') setViewStyle('list')
    // }
  }, [canMessageAdvisors, team])

  const bulkAddToGroup = () => {
    if (!bulkGroups) return
    setWorkingBulkAction(true)
    const selectedAdvisorUuids = selectedAdvisors.map((m) => m.uuid)

    api
      .bulkAddAdvisorGroups(teamSlug, bulkGroups, selectedAdvisorUuids)
      .then(() => {
        cabalToast({ style: 'success', content: 'Successfully added to groups' })
        setShowGroupModal(0)
        reloadAdvisors(selectedAdvisorUuids)
        reloadGroups()
      })
      .finally(() => setWorkingBulkAction(false))
  }

  const bulkRemoveFromGroup = () => {
    if (!bulkGroups) return
    setWorkingBulkAction(true)
    const selectedAdvisorUuids = selectedAdvisors.map((m) => m.uuid)

    api
      .bulkRemoveAdvisorGroups(teamSlug, bulkGroups, selectedAdvisorUuids)
      .then(() => {
        cabalToast({ style: 'success', content: 'Successfully removed from groups' })
        setShowGroupModal(0)
        reloadAdvisors(selectedAdvisorUuids)
        reloadGroups()
      })
      .finally(() => setWorkingBulkAction(false))
  }

  const sendBulkMessages = (template?: string) => {
    compose({ recipients: { advisors: selectedAdvisors }, template_key: template })
  }

  const deleteBulkAdvisors = () => {
    if (
      confirm(
        `Are you sure you want to delete these advisor? \n\n ${selectedAdvisors.map(
          (a) => a.name,
        )}`,
      ) &&
      prompt("Please type CONFIRM if you'd like to continue deleting this advisor.") == 'CONFIRM'
    ) {
      const selectedUuids = selectedAdvisors.map((a) => a.uuid)
      api.bulkDeleteAdvisors(teamSlug, selectedUuids).then(() => {
        dispatch(removeAdvisors(selectedUuids))
        reloadAdvisors()
      })
    }
  }

  const sortedAdvisors = filteredAdvisors || allAdvisors
  const advisors = sortedAdvisors?.sort((a, b) => (a.name > b.name ? 1 : -1))

  const onExport = () => {
    if (!!selectedAdvisors.length) {
      post('/api/advisors/export.csv', {
        advisor_uuids: selectedAdvisors.map((a) => a.uuid),
      })
    } else if (!!filteredAdvisors?.length) {
      post('/api/advisors/export.csv', {
        advisor_uuids: filteredAdvisors.map((a) => a.uuid),
      })
    } else {
      window.location.assign('/api/advisors.csv')
    }
  }

  return (
    <PageWrapper title={'Members'} hideTitle className="pb-0">
      {/* Members splash screen */}
      {/* <SplashScreen location="members" teamSlug={teamSlug} /> */}

      <div>
        <Typography component="div" className="pb-0 flex flex-col">
          <div className="py-3">
            <div className="sm:flex justify-between flex-initial">
              {allAdvisors && (
                <div>
                  {/* <NewFilters
                    companySlug={teamSlug}
                    advisors={allAdvisors}
                    setFilteredAdvisors={setFilteredAdvisors}
                    filteredAdvisors={filteredAdvisors}
                  /> */}

                  <Filter
                    advisors={allAdvisors}
                    teamSlug={teamSlug}
                    setFilteredAdvisors={setFilteredAdvisors}
                    filteredAdvisors={filteredAdvisors}
                    filters={filters}
                    setFilters={setFilters}
                    sortedAdvisors={sortedAdvisors || []}
                    setSelectedAdvisors={setSelectedAdvisors}
                  />
                </div>
              )}
              <div className="flex sm:-mr-4 items-start">
                {!!canEditAdvisors && selectedAdvisors.length > 0 && (
                  <DropDownMenu
                    trigger={
                      <CabalButton variant="link" rightIcon={<i className="fas fa-caret-down" />}>
                        Bulk Action
                      </CabalButton>
                    }
                    menuItems={[
                      {
                        label: <>Add to groups</>,
                        onSelect: () => {
                          setShowGroupModal(1)
                          setBulkGroups([])
                        },
                      },
                      {
                        label: <>Remove from groups</>,
                        onSelect: () => {
                          setShowGroupModal(-1)
                          setBulkGroups([])
                        },
                      },
                      {
                        label: <>Send Invites</>,
                        onSelect: () => {
                          showModal(
                            (resolve) => (
                              <AdvisorInviteModal
                                onHide={resolve}
                                teamSlug={teamSlug}
                                advisors={selectedAdvisors}
                              />
                            ),
                            'advisor-invite-modal',
                          )
                        },
                      },
                      {
                        label: <>Send Message</>,
                        onSelect: () => {
                          sendBulkMessages()
                        },
                      },
                      team?.enable_portfolio
                        ? {
                            label: <>Request Pipeline</>,
                            onSelect: () => {
                              sendBulkMessages('request-pipeline')
                            },
                          }
                        : null,
                      ,
                      team?.enable_bulk_agreements
                        ? {
                            label: <>Send Agreements</>,
                            onSelect: () => {
                              history.push(`/${team?.slug}/agreements/new`, {
                                advisor_uuids: selectedAdvisors.map((a) => a.uuid),
                              })
                            },
                          }
                        : null,
                      // {
                      //   label: <>Ask to share connections</>,
                      //   onSelect: () => {
                      //     bulkAskForExtenion()
                      //   },
                      // },
                      {
                        label: <>Delete Members</>,
                        onSelect: () => {
                          deleteBulkAdvisors()
                        },
                      },
                    ]}
                  />
                )}
                {canExportAdvisors && !isMobile && (
                  <CabalButton
                    variant="link"
                    onClick={() => onExport()}
                    leftIcon={<i className="far fa-file-download fa-fw"></i>}
                  >
                    Export
                  </CabalButton>
                )}
                {canAddAdvisor && (
                  <div>
                    <CabalButton
                      variant="link"
                      component="link"
                      to={`/${team?.slug}/import`}
                      data-intercom-target="bulk_import"
                      data-testid="add-member-button"
                      leftIcon={<i className="far fa-user-plus fa-fw" />}
                    >
                      Add members
                    </CabalButton>
                  </div>
                )}
                {canEditGroups && (
                  <CabalButton
                    variant="link"
                    data-testid="manage-groups-button"
                    leftIcon={<i className="far fa-users" />}
                    onClick={() => history.push(`./groups`)}
                  >
                    Edit Groups
                  </CabalButton>
                )}
              </div>
            </div>
          </div>

          {!advisors && <Loading className="pt-8" />}

          {advisors && advisors.length === 0 && filters.search === '' && <MembersEmptyState />}
          {advisors && advisors.length === 0 && filters.search !== '' && <NoResults />}

          {team && allAdvisors && advisors && advisors.length > 0 && (
            <>
              {viewStyle === 'list' && (
                <>
                  <MemberCard
                    team={team}
                    filteredAdvisors={advisors}
                    linkedinConnectionStats={connectionStats?.linkedin}
                    onAdvisorSelect={setSelectedAdvisors}
                    selectedAdvisors={selectedAdvisors}
                  />
                  {/* <MemberTable
                    team={team}
                    advisors={allAdvisors}
                    contributions={contributions}
                    filteredAdvisors={advisors.sort((a, b) =>
                      (a.first_name || '') > (b.first_name || '') ? 1 : -1,
                    )}
                    onReload={async () => {
                      await reloadAdvisors()
                      await reloadConnectionStats()
                    }}
                    linkedinConnectionStats={connectionStats?.linkedin}
                    onAdvisorSelect={setSelectedAdvisors}
                  /> */}
                </>
              )}
              {viewStyle === 'grid' && (
                <Grid members={advisors} team={team} companySlug={teamSlug} />
              )}
            </>
          )}
        </Typography>
      </div>

      <Modal
        show={!!showGroupModal}
        onHide={() => setShowGroupModal(0)}
        header={showGroupModal === 1 ? 'Add to Groups' : 'Remove from Groups'}
        rightActions={
          <CabalButton
            variant="primary"
            onClick={showGroupModal === 1 ? bulkAddToGroup : bulkRemoveFromGroup}
            working={workingBulkAction}
            disabled={bulkGroups.length === 0}
          >
            Save
          </CabalButton>
        }
      >
        <MultiSelect<string>
          portal
          className="my-3"
          onChange={(gs) => {
            setBulkGroups(gs)
          }}
          value={bulkGroups}
          options={groups?.map((g) => ({
            label: g.name,
            value: g.uuid,
          }))}
          placeholder="Select Groups"
          autoFocus={true}
          isLoading={creatingGroup}
          creatable
          disabled={creatingGroup}
          onCreateOption={(v) => createGroup(v)}
        />
      </Modal>
    </PageWrapper>
  )
}

function post(path: string, params: Params = {}, method = 'post') {
  // The rest of this code assumes you are not using a library.
  // It can be made less verbose if you use one.
  const form = document.createElement('form')
  form.method = method
  form.action = path

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = key
      hiddenField.value = params[key]

      form.appendChild(hiddenField)
    }
  }

  document.body.appendChild(form)
  form.submit()
}

export default Members
