import React from 'react'

import cx from 'classnames'
import Lottie from 'lottie-react'
import { Oval } from 'react-loader-spinner'
import { useTheme } from 'styled-components'

import animationData from './data'

export interface Props {
  size?: number
  strokeWidth?: number
}

const Loading: React.VFC<Omit<React.HTMLProps<HTMLDivElement>, 'size'> & Props> = ({
  className,
  size,
  strokeWidth,
  color,
  ...props
}) => {
  const theme = useTheme()
  return (
    <div
      className={cx('flex items-center justify-center', className)}
      role="progressbar"
      {...props}
    >
      <Lottie
        style={{
          width: size || 48,
          height: size || 48,
          padding: '10px',
        }}
        width={size || 48}
        height={size || 48}
        animationData={animationData}
        loop={true}
        color={'white'}
      />
    </div>
  )
}

export default Loading
