import styled from 'styled-components'
import tw from 'twin.macro'

const MessageContainer = styled.div`
  ${tw`p-3 mb-1.5`};
  background-color: ${({ theme }) => theme.colors.composer_bg};
  border-bottom: ${({ theme }) => theme.border};
`
const ReplyContainer = styled.div`
  ${tw`px-3 py-1 rounded-lg`}
  background-color: ${({ theme }) => theme.colors.composer_bg};
`
const Divider = styled.div`
  border-bottom: ${({ theme }) => theme.border};
`
export const MessageListHeader = styled.div`
  ${tw`flex justify-between items-center px-3`}
  padding-top: 18px;
`
export const SendMessageWrap = styled.div`
  ${tw`p-6`}
  box-shadow: ${({ theme }) => theme.composer_shadow};
  background-color: ${({ theme }) => theme.colors.composer_bg};
`

export const TopBar = styled.div`
  ${tw`flex justify-between rounded-lg mt-3 py-1`}

  border: ${({ theme }) => theme.border};
`

const SideBar = styled.div<{ minimal: boolean }>`
  ${tw`flex-1 px-3 overflow-auto border-l-[1px] dark:border-[#2d3748] border-[#E3E5E8]`}
  background-color: ${({ theme }) => theme.layout.main_bg_color};
  min-width: 20rem;

  ${({ minimal }) =>
    minimal &&
    `
    max-width: 15rem;
  `}
`

const MessageWrapper = styled.div<{ height?: string }>`
  height: calc(100vh - ${({ height }) => (height ? height : '50px')});
`

export { Divider, ReplyContainer, MessageContainer, MessageWrapper, SideBar }
