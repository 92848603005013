import React, { useState } from 'react'

import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import { useMutation } from 'react-query'
import { useSetState } from 'react-use'
import validator from 'validator'

import { Border } from 'containers/Settings/styles'
import CabalButton from 'global/CabalButton'
import { CheckBox, Label, MultiSelect, Select, TextArea, TextInput } from 'global/Input'
import UploadZone, { UploadZoneRef } from 'global/Input/UploadZone'
import Modal from 'global/Modal'
import {
  ModalDivider,
  ModalInputLabel,
  ModalInputWrapper,
  ModalSectionHeader,
  ModalSectionWrapper,
} from 'global/Modal/styles'
import CKEditor from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'
import { useAdvisors, useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { absoluteHtmlContent } from 'utils/html'
import { InvestorCompany, InvestorCompanyJob } from 'utils/types/investor'

interface Props {
  companies: InvestorCompany[]
  onEdit?: (job?: InvestorCompanyJob) => void
  onAdd?: (job?: InvestorCompanyJob) => void
  onDelete?: (job?: InvestorCompanyJob) => void
  resolve: () => void
  teamSlug: string
  job?: InvestorCompanyJob
  refresh: (job: InvestorCompanyJob) => void
  allTags: string[]
  setAllTags: (tags: string[]) => void
  isFetchingJobTags: boolean
  isCloning?: boolean
}

const AddJobModal: React.VFC<Props> = ({
  companies,
  onEdit,
  onAdd,
  refresh,
  teamSlug,
  job,
  resolve,
  allTags,
  setAllTags,
  isFetchingJobTags,
  isCloning,
}) => {
  const { team } = useTeam(teamSlug)
  const {
    user: { uuid },
  } = useCurrentUser()
  const uploadZoneRef = React.useRef<UploadZoneRef>(null)

  const { advisors, isAdvisorLoaded } = useAdvisors({ teamSlug })

  const [jobDetails, setJobDetails] = useSetState<Partial<InvestorCompanyJob>>({
    ...(job || {}),
    company_uuid: job?.investor_company?.uuid,
    upload_uuid: job?.upload?.upload_uuid,
    talent_lead: job?.talent_lead || uuid,
  })
  const c = companies.find((c) => job?.investor_company?.uuid === c.uuid)
  const [selectedCompany, setSelectedCompany] = useState(c)
  const [uploading, setUploading] = useState(false)

  const { mutate: handleAdd, isLoading } = useMutation(
    () => callApi(api.addInvestorCompanyJob, jobDetails, teamSlug),
    {
      onSuccess: ({ job }) => {
        cabalToast({ style: 'success', content: 'Job was successfully added!' })
        onAdd(job)
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const { mutate: handleUpdate, isLoading: isUpdating } = useMutation(
    () =>
      callApi(
        api.updateInvestorCompanyJob,
        jobDetails.investor_company?.uuid,
        jobDetails,
        teamSlug,
      ),
    {
      onSuccess: ({ job }) => {
        cabalToast({ style: 'success', content: 'Job was successfully updated!' })
        onEdit(job)
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const createEmailOption = (email: string) => {
    if (!validator.isEmail(email)) {
      cabalToast({ style: 'error', content: `Please enter a valid email address.` })
      return
    }
    setJobDetails({
      talent_poc: {
        ...jobDetails.talent_poc,
        email,
      },
    })
  }

  const { mutate: archiveInvestorCompanyJob, isLoading: isArchiving } = useMutation(
    (job: InvestorCompanyJob) =>
      callApi(api.archiveInvestorCompanyJob, job.investor_company?.uuid, job.uuid, teamSlug),
    {
      onSuccess: ({ job }) => {
        cabalToast({ style: 'success', content: 'Job was archived.' })
        refresh(job)
        resolve()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const { mutate: unarchiveInvestorCompanyJob, isLoading: isUnarchiving } = useMutation(
    (job: InvestorCompanyJob) =>
      callApi(api.unarchiveInvestorCompanyJob, job.investor_company?.uuid, job.uuid, teamSlug),
    {
      onSuccess: ({ job }) => {
        cabalToast({ style: 'success', content: 'Job was unarchived.' })
        refresh(job)
        resolve()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const handleSaveJob = () => {
    if (job && !isCloning) {
      handleUpdate()
    } else {
      handleAdd()
    }
  }

  const handleClick = () => {
    const talentPOC = omitBy(jobDetails.talent_poc, isEmpty)
    delete talentPOC.linkedin_url
    if (talentPOC && Object.keys(talentPOC).length > 0 && Object.keys(talentPOC).length < 3) {
      cabalToast({
        style: 'error',
        content: 'Please enter all the required details for Hiring POC!',
      })
      return
    }

    if (uploadZoneRef.current) {
      setUploading(true)
      uploadZoneRef.current
        .uploadFiles()
        .then(([upload]) => {
          upload && setJobDetails({ upload_uuid: upload.uuid })
          handleSaveJob()
        })
        .catch(() => cabalToast({ style: 'error', content: 'Something went wrong!' }))
        .finally(() => setUploading(false))
    } else {
      handleSaveJob()
    }
  }

  const disabled =
    isLoading || isUpdating || !jobDetails.company_uuid || !jobDetails.title || uploading

  return (
    <Modal
      dangerouslyBypassFocusLock
      show
      onHide={resolve}
      header={job ? 'Edit Job' : 'Add Job'}
      leftActions={
        job?.archived ? (
          <CabalButton
            onClick={() => unarchiveInvestorCompanyJob(job)}
            working={isUnarchiving}
            disabled={isUnarchiving}
            variant="secondary"
            data-testid="portfolio-job-modal-unarchive-btn"
          >
            Unarchive
          </CabalButton>
        ) : (
          <CabalButton
            onClick={() => archiveInvestorCompanyJob(job)}
            working={isArchiving}
            disabled={isArchiving}
            variant="secondary"
            data-testid="portfolio-job-modal-archive-btn"
          >
            Archive
          </CabalButton>
        )
      }
      rightActions={
        <CabalButton
          onClick={handleClick}
          working={isLoading || isUpdating || uploading}
          disabled={disabled}
          data-testid="portfolio-job-modal-save-btn"
        >
          Save
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalSectionHeader>Company Details</ModalSectionHeader>
        <ModalInputWrapper>
          <ModalInputLabel>Company (Required)</ModalInputLabel>
          <Select<string>
            data-testid="portfolio-job-modal-select-company"
            className="w-full"
            options={companies.map((c) => ({
              label: c.company_name,
              value: c.uuid,
            }))}
            defaultValue={jobDetails.investor_company?.uuid}
            value={jobDetails.company_uuid}
            onChange={(e) => {
              const c = companies.find((c) => e === c.uuid)
              setSelectedCompany(c)
              e && setJobDetails({ company_uuid: e, description: c?.description })
            }}
            isLoading={!companies}
            placeholder="Select Company"
            virtualized
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Title (Required)</ModalInputLabel>
          <TextInput
            value={jobDetails.title}
            onChange={(e) => setJobDetails({ title: e.target.value })}
            placeholder="Title"
            className="w-full"
            valueType="non-empty"
            required
            data-testid="portfolio-job-modal-title"
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Visibility</ModalInputLabel>
          <Select
            data-testid="portfolio-job-modal-visibility"
            value={jobDetails.visibility}
            className="w-full"
            onChange={(v) => {
              setJobDetails({
                visibility: v as 'team_only' | 'team_and_members',
              })
            }}
            options={[
              { label: 'Team Only', value: 'team_only' },
              { label: 'Team and Members', value: 'team_and_members' },
            ]}
            placeholder="Team Only"
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Location</ModalInputLabel>
          <TextInput
            value={jobDetails.location}
            onChange={(e) => setJobDetails({ location: e.target.value })}
            placeholder="Location"
            className="w-full"
            data-testid="portfolio-job-modal-location"
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Job Rec URL</ModalInputLabel>
          <TextInput
            value={jobDetails.url}
            onChange={(e) => setJobDetails({ url: e.target.value })}
            placeholder="Job Rec URL"
            className="w-full"
            data-testid="portfolio-job-modal-url"
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Tags</ModalInputLabel>
          <MultiSelect<string>
            data-testid="portfolio-job-modal-category"
            className="w-full"
            options={
              allTags.map((t) => ({
                label: t,
                value: t,
              })) || []
            }
            value={jobDetails.tags}
            onChange={(v) => setJobDetails({ tags: v })}
            creatable
            isLoading={isFetchingJobTags}
            onCreateOption={(o) => {
              setAllTags([...allTags, o])
              setJobDetails({ tags: [...(jobDetails.tags || []), o] })
            }}
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Description</ModalInputLabel>
          <CKEditor
            value={jobDetails.description}
            onChange={(v) => setJobDetails({ description: absoluteHtmlContent(v) || '' })}
            height={100}
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Internal Notes</ModalInputLabel>
          <TextArea
            data-testid="portfolio-job-modal-internal-notes"
            value={jobDetails.internal_notes}
            onChange={(e) => setJobDetails({ internal_notes: e.target.value })}
            placeholder="Internal Notes"
            className="w-full"
            rows={5}
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>More Docs (.pdf only)</ModalInputLabel>
          {jobDetails.upload ? (
            <Border>
              <div
                className="flex justify-between items-center"
                data-testid="portfolio-job-modal-uploaded-file"
              >
                <Typography color="purple" fontSize="14" className="ml-3">
                  <i className="far fa-paperclip"></i>
                  <a
                    href={`/api/investor_companies/${jobDetails.company_uuid}/investor_company_jobs/${jobDetails.uuid}/${jobDetails.upload.file_name}`}
                    className="underline cursor-pointer mx-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {jobDetails.upload.file_name}
                  </a>
                </Typography>
                <CabalButton
                  data-testid="portfolio-job-modal-upload-delete-btn"
                  variant="tertiary"
                  onClick={() => {
                    setJobDetails({ upload_uuid: null, upload: null })
                  }}
                  className="ml-3"
                >
                  <i className="fas fa-trash" />
                </CabalButton>
              </div>
            </Border>
          ) : (
            <UploadZone accept={['pdf']} ref={uploadZoneRef} hideDndOnSelect />
          )}
        </ModalInputWrapper>
      </ModalSectionWrapper>
      <ModalDivider />
      <ModalSectionWrapper>
        <ModalSectionHeader> {`${team?.name} Talent Lead`}</ModalSectionHeader>

        <ModalInputWrapper>
          <ModalInputLabel>Name</ModalInputLabel>
          <Select
            data-testid="portfolio-job-modal-talent-lead"
            value={jobDetails.talent_lead}
            className="w-full"
            onChange={(v) => {
              setJobDetails({
                talent_lead: v,
              })
            }}
            options={
              team?.admins_and_members.map((f) => ({
                label: f.name,
                value: f.uuid,
              })) || []
            }
            placeholder="Talent Lead"
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>

      <ModalSectionWrapper>
        <ModalSectionHeader>
          {selectedCompany && `${selectedCompany.company_name} `}
          Point of Contact
        </ModalSectionHeader>

        <ModalInputWrapper>
          <ModalInputLabel>Email</ModalInputLabel>
          <Select
            data-testid="portfolio-job-modal-talent-poc-email"
            isLoading={!isAdvisorLoaded}
            value={jobDetails.talent_poc?.email}
            className="w-full"
            onChange={(v) => {
              const advisor = advisors?.find((m) => m.email === v)
              let details = { ...jobDetails.talent_poc, email: v || undefined }
              if (advisor) {
                const { first_name, last_name, linkedin_url, email } = advisor
                details = {
                  first_name: first_name || '',
                  last_name: last_name || '',
                  linkedin_url: linkedin_url || '',
                  email: email || '',
                }
              }
              setJobDetails({
                talent_poc: details,
              })
            }}
            onCreateOption={createEmailOption}
            options={
              advisors?.map((f) => ({
                label: f.email,
                value: f.email,
              })) || []
            }
            placeholder="Email (Required)"
            creatable
            virtualized
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>First Name (Required)</ModalInputLabel>
          <TextInput
            data-testid="portfolio-job-modal-talent-poc-first-name"
            value={jobDetails.talent_poc?.first_name}
            className="w-full"
            valueType="non-empty"
            onChange={(e) =>
              setJobDetails({
                talent_poc: { ...jobDetails.talent_poc, first_name: e.target.value || undefined },
              })
            }
            placeholder="First Name (Required)"
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>Last Name (Required)</ModalInputLabel>
          <TextInput
            data-testid="portfolio-job-modal-talent-poc-last-name"
            value={jobDetails.talent_poc?.last_name}
            className="w-full"
            valueType="non-empty"
            placeholder="Last Name (Required)"
            onChange={(e) =>
              setJobDetails({
                talent_poc: { ...jobDetails.talent_poc, last_name: e.target.value || undefined },
              })
            }
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>LinkedIn Profile URL</ModalInputLabel>
          <TextInput
            data-testid="portfolio-job-modal-talent-poc-linkedin-url"
            value={jobDetails.talent_poc?.linkedin_url}
            className="w-full"
            valueType="website"
            placeholder="LinkedIn Profile URL"
            onChange={(e) =>
              setJobDetails({
                talent_poc: {
                  ...jobDetails.talent_poc,
                  linkedin_url: e.target.value || undefined,
                },
              })
            }
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default AddJobModal
