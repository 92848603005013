/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import cx from 'classnames'
import {
  CellProps,
  Column as ReactTableColumn,
  HeaderProps,
  useMountedLayoutEffect,
  useRowSelect,
  useSortBy,
  useTable,
  Row as ReactTableRow,
  TableToggleAllRowsSelectedProps,
  useGlobalFilter,
  useFlexLayout,
} from 'react-table'

import Typography from './Typography'
import { useHotkeys } from 'react-hotkeys-hook'
import { isEqual, pick, reduce } from 'lodash'
import objectHash from 'object-hash'
import { FixedSizeList } from 'react-window'
import { CheckBox } from './Input'

export { ReactTableRow }

export const TableEl = styled.div`
  ${tw`rounded border-collapse w-full relative`}
  background-color: ${({ theme }) => theme.table.bg_color};

  outline: none;
`

const Selector = styled.div`
  /* ${tw`px-4`} */
  /* min-width: 30px; */
  display: inline-block;
`

const Thead = styled.div``

export const Th = styled.div`
  ${tw`
    sticky
    top-0
    border-b
    px-4
    py-1
    font-bold
    tracking-wider
    uppercase
    text-xs
    overflow-auto
    z-10
  `}
  color: ${({ theme }) => theme.colors.gray};
  border-color: ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.primary_bg};
`

export interface TrProps {
  isSelected: boolean
  nextSelected: boolean
  prevSelected: boolean
}

export const Tr = styled.div<TrProps>`
  border-bottom: ${({ theme }) => theme.border};
`

export const Td = styled.div`
  ${tw`p-1`}
  color: ${({ theme }) => theme.colors.primary};
  border-bottom-color: ${({ theme }) => theme.colors.border};
  border-left: none;
  border-right: none;
`

export const Span = styled.span`
  ${tw`px-4 py-1 flex items-center`}
  color: ${({ theme }) => theme.colors.primary};
`

const TableHeadingRow = styled.div`
  ${tw`flex`}
`

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  Partial<TableToggleAllRowsSelectedProps>
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef<HTMLInputElement>(null)
  const resolvedRef = (ref as React.MutableRefObject<HTMLInputElement>) || defaultRef

  useEffect(() => {
    if (!resolvedRef.current || !indeterminate) return
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return <CheckBox ref={defaultRef} {...rest} />
})

export type Row<DataType = Record<string, any> | undefined> = {
  id: string
  data: DataType
  [key: string]: React.ReactNode
}

export type Column<DataType extends Record<string, any> = any> = ReactTableColumn<Row<DataType>> & {
  className?: string
}

export interface TableRef {
  clearSelection: () => void
}

interface Props {
  className?: string
  columns: Column[]
  data: Row[]
  sortable?: boolean
  selectable?: boolean
  selectedRows?: string[]
  onSelectedRowsChange?: (rows: string[]) => void
  tr?: React.ComponentType
  td?: React.ComponentType
  th?: React.ComponentType
  filteredRowIds?: string[]
  rowHeight?: number
  tableHeight?: number
  virtualize?: boolean
  overscanCount?: number
}

/**
 * When using this component, please make sure that `data` is memoized.
 * Otherwise things like row selection will not work as it will be reset after each
 * rerender
 */
const Table = React.memo(
  forwardRef<TableRef, Props>(
    (
      {
        className,
        columns,
        data,
        selectable = false,
        sortable = true,
        selectedRows = [],
        onSelectedRowsChange,
        tr: TR = Tr,
        th: TH = Th,
        td: TD = Td,
        filteredRowIds,
        virtualize = false,
        rowHeight,
        tableHeight,
        overscanCount,
      },
      ref,
    ) => {
      if (selectedRows.length > 0 && !onSelectedRowsChange) {
        throw 'you need to supply both selectedRows and onSelectedRowsChange'
      }

      if (virtualize && (!rowHeight || !tableHeight)) {
        throw 'virtualization requires rowHeight and tableHeight'
      }

      const selectedRowIds = reduce(
        data,
        (acc, curr) => {
          if (selectedRows.includes(curr.id)) {
            return { ...acc, [curr.id]: true }
          }
          return acc
        },
        {},
      )

      const focusedRowRef = useRef(0)
      const tbodyRef = React.useRef<HTMLTableSectionElement>(null)
      const tableRef = React.useRef<HTMLTableElement>(null)

      const setFocusedRow = useCallback(
        (i: number) => {
          if (!selectable) return

          focusedRowRef.current = i

          const focusedRowEl = tableRef.current?.querySelector(`#row-${focusedRowRef.current}`)
          tableRef.current?.querySelectorAll('.row').forEach((r) => {
            if (focusedRowEl && r.isSameNode(focusedRowEl)) {
              r.classList.add('focus')
            } else {
              r.classList.remove('focus')
            }
          })

          if (!focusedRowEl || !tableRef.current) return

          const { clientHeight: tableClientHeight, offsetTop: tableOffsetTop } = tableRef.current
          const { bottom, top } = focusedRowEl.getBoundingClientRect()

          if (bottom > tableClientHeight + tableOffsetTop) {
            focusedRowEl.scrollIntoView(false)
          }
          if (top - tableOffsetTop < 0) {
            focusedRowEl.scrollIntoView()
          }
        },
        [selectable],
      )

      useHotkeys('cmd+a', (e) => {
        e.preventDefault()
        document
          .querySelector<HTMLInputElement>('.select-check > label > input[type=checkbox]')
          ?.click()
      })

      const handleKeyDown: (ev: KeyboardEvent) => any = useCallback(
        (event) => {
          if (!selectable) return

          const focusedRow = focusedRowRef.current
          switch (event.key) {
            case 'ArrowUp':
              if (focusedRow === 0) {
                setFocusedRow(0)
              } else {
                setFocusedRow(focusedRow - 1)
              }
              break
            case 'ArrowDown':
              if (data.length === focusedRow + 1) {
                setFocusedRow(data.length - 1)
              } else {
                setFocusedRow(focusedRow + 1)
              }
              break
            case 'Enter':
              document.querySelector<HTMLInputElement>('.focus input[type=checkbox]')?.click()
              break
            default:
              break
          }
        },
        [data],
      )

      const globalFilterFunction = useCallback(
        (rows: ReactTableRow<Row>[]) => {
          return filteredRowIds
            ? rows.filter(
                (r) =>
                  filteredRowIds?.includes(r.original.id) || selectedRows.includes(r.original.id),
              )
            : rows
        },
        [filteredRowIds],
      )

      const {
        rows,
        headers,
        selectedFlatRows,
        prepareRow,
        getTableProps,
        getTableBodyProps,
        toggleAllRowsSelected,
        setGlobalFilter,
        totalColumnsWidth,
      } = useTable(
        {
          columns,
          data,
          initialState: {
            selectedRowIds: selectedRowIds,
          },
          globalFilter: globalFilterFunction,
        },
        (hooks) => {
          if (selectable)
            hooks.allColumns.push((columns) => [
              {
                id: 'selection',
                width: 40,
                // eslint-disable-next-line react/display-name
                Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
                  <Selector className="align-middle flex select-check">
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                  </Selector>
                ),
                // eslint-disable-next-line react/display-name
                Cell: ({ row: { getToggleRowSelectedProps } }: CellProps<any>) => (
                  <Selector className="justify-center align-middle flex">
                    <IndeterminateCheckbox {...getToggleRowSelectedProps()} />
                  </Selector>
                ),
              },
              ...columns,
            ])
        },
        ...[useGlobalFilter, useSortBy, selectable ? useRowSelect : () => {}, useFlexLayout],
      )

      useEffect(() => {
        setGlobalFilter(objectHash(filteredRowIds || []))
      }, [filteredRowIds])

      useImperativeHandle(ref, () => ({
        clearSelection: () => toggleAllRowsSelected(false),
      }))

      useMountedLayoutEffect(() => {
        onSelectedRowsChange?.(selectedFlatRows.map((r) => r.original.id))
      }, [onSelectedRowsChange, selectedFlatRows])

      // useEffect(() => {
      //   if (!selectable) return
      //   const table = tableRef.current
      //   table?.addEventListener('keydown', handleKeyDown, true)
      //   return () => table?.removeEventListener('keydown', handleKeyDown, true)
      // }, [handleKeyDown])

      // useEffect(() => {
      //   setTimeout(() => {
      //     const tables = document.querySelectorAll('[role="table"]')
      //     if (tables.length > 1 || !selectable) return

      //     tableRef.current?.focus()
      //   }, 100)
      // }, [])

      const RenderRow = React.useCallback(
        ({ index: i, style }) => {
          const r = rows[i]
          if (!r) return <></>
          prepareRow(r)
          const nextSelected = !!rows.find((_, i) => i === r.index + 1)?.isSelected
          const prevSelected = !!rows.find((_, i) => i === r.index - 1)?.isSelected

          return (
            <TR
              {...r.getRowProps({ style })}
              nextSelected={nextSelected}
              prevSelected={prevSelected}
              isSelected={r.isSelected}
              id={`row-${i}`}
              key={`row-${r.original.id}-${i}`}
              className={cx('row', selectable && { focus: focusedRowRef.current === i })}
              onMouseDown={() => setFocusedRow(i)}
            >
              {r.cells.map((cell) => (
                // eslint-disable-next-line react/jsx-key
                <TD
                  {...cell.getCellProps([
                    {
                      className: cx((cell.column as JSX.IntrinsicElements['td']).className, {
                        'sticky left-0': cell.column.id === 'selection',
                      }),
                    },
                  ])}
                >
                  {cell.render('Cell')}
                </TD>
              ))}
            </TR>
          )
        },
        [prepareRow, rows, selectedRowIds],
      )

      let renderedRows: any = rows.map((r, i) => <RenderRow key={r.id} index={i} />)

      if (virtualize) {
        renderedRows = (
          <FixedSizeList
            height={tableHeight!}
            itemCount={rows.length}
            itemSize={rowHeight!}
            width={'100%'}
            overscanCount={overscanCount}
          >
            {RenderRow}
          </FixedSizeList>
        )
      }

      return (
        <TableEl {...getTableProps({ className })} tabIndex={0} ref={tableRef}>
          <Thead>
            <TableHeadingRow>
              {headers.map(
                (column) =>
                  column.isVisible && (
                    <TH
                      {...column.getHeaderProps(
                        column.id === 'selection' || !sortable ? {} : column.getSortByToggleProps(),
                      )}
                      key={column.id}
                      className={cx((column as JSX.IntrinsicElements['th']).className, {
                        'sticky left-0 z-10': column.id === 'selection',
                      })}
                    >
                      <Typography>{column.render('Header')}</Typography>

                      {column.id !== 'selection' && sortable && column.canSort && (
                        <Typography fontSize="12" className="ml-2">
                          {column.isSorted && column.isSortedDesc && (
                            <i className="fas fa-sort-down" />
                          )}
                          {column.isSorted && !column.isSortedDesc && (
                            <i className="fas fa-sort-up" />
                          )}
                          {!column.isSorted && <i className="fas fa-sort" />}
                        </Typography>
                      )}
                    </TH>
                  ),
              )}
            </TableHeadingRow>
          </Thead>

          <div {...getTableBodyProps()} ref={tbodyRef}>
            {renderedRows}
          </div>
        </TableEl>
      )
    },
  ),
  (prev, next) => {
    const primitiveProps: (keyof Props)[] = [
      'className',
      'sortable',
      'selectable',
      'tableHeight',
      'rowHeight',
    ]
    if (prev.columns !== next.columns) return false
    if (prev.data !== next.data) return false
    if (!isEqual(pick(prev, primitiveProps), pick(next, primitiveProps))) return false
    if (!isEqual(prev.filteredRowIds?.sort(), next.filteredRowIds?.sort())) return false
    if (!isEqual(prev.selectedRows?.sort(), next.selectedRows?.sort())) return false

    return true
  },
)

export default Table
