import * as React from 'react'

import Pill from 'global/Pill'
import Tooltip from 'global/Tooltip'
import { MainTheme } from 'global/theme'

export interface Props {
  tags: React.ReactNode[]
  color?: keyof MainTheme['colors'] | 'inherit'
}

export const Tags: React.FC<Props> = ({ tags }) => {
  const renderTags = (tags: React.ReactNode[]) => {
    if (tags.length === 1) {
      return (
        <Pill variant="purple_filled" key={tags[0]}>
          {tags[0]}
        </Pill>
      )
    } else {
      const tooltipContent = (
        <>
          {tags.slice(1).map((tag) => (
            <Pill variant="purple_filled" key={tag} className="m-1">
              {tag}
            </Pill>
          ))}
        </>
      )
      return (
        <Tooltip label={tooltipContent}>
          <Pill variant="purple_filled" key={tags[0]} className="cursor-pointer">
            {tags[0]} + {tags.slice(1).length} more
          </Pill>
        </Tooltip>
      )
    }
  }

  return <div className="flex items-center space-x-1">{tags.length > 0 && renderTags(tags)}</div>
}

export default Tags
