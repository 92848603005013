import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { createGlobalState, useSearchParam } from 'react-use'

const UTM_SOURCES = ['app-page'] as const
type UtmSource = typeof UTM_SOURCES[number]

const useGlobalUtmSource = createGlobalState<UtmSource | undefined>(undefined)

const useUtmSource = () => {
  const utmSourceParam = useSearchParam('utm_source') as UtmSource | undefined
  const [utmSource, setUtmSource] = useGlobalUtmSource()
  const history = useHistory()

  useEffect(() => {
    if (utmSourceParam && utmSourceParam !== utmSource) {
      setUtmSource(utmSourceParam)
    }
  }, [utmSourceParam])

  const clearUtmSource = () => {
    setUtmSource(undefined)
    history.replace(window.location.pathname)
  }

  return { utmSource, clearUtmSource }
}

export default useUtmSource
