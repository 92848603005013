import axios from 'axios'
import { SaveEquityTierRequest, EquityTierResponse } from 'utils/types'

export default {
  getEquityTiers: (team_slug: string) => {
    return axios.get<EquityTierResponse>(`/api/equity_tiers`, {
      params: {
        team_slug,
      },
    })
  },

  saveEquityTier: (params: SaveEquityTierRequest) => {
    return axios.post<EquityTierResponse>(`/api/equity_tiers`, params)
  },

  destroyEquityTier: (id: number) => {
    return axios.delete<EquityTierResponse>(`/api/equity_tiers/${id}`)
  },
}
