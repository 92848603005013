import React from 'react'

import clsx from 'classnames'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: clsx.ArgumentArray) {
  return twMerge(clsx(inputs))
}

export function cssObjectToString(cssObject: React.CSSProperties) {
  return Object.entries(cssObject)
    .map(([property, value]) => `${property.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value}`)
    .join('; ')
}
