import * as React from 'react'
import { useMutation } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import { Divider } from 'containers/ViewMessage/styles'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import useOutsideClickDetector from 'utils/hooks/useOutsideClickDetector'
import { Team } from 'utils/types'

import ActionButton from './ActionButton'

interface Props {
  teamSlug: string
  onSuccess?: () => void
  inModal?: boolean
}

const PillWrapper = styled.div<{ focused: boolean }>`
  ${tw`px-1 py-0.5 pr-2 items-center rounded-full`}
  border: ${({ theme }) => theme.border};
  display: ${({ focused }) => (focused ? 'flex' : 'none')};
  transition: display 0.3s ease-out;
`

const Wrapper = styled.div<{ inModal: boolean }>`
  ${tw`rounded-lg border-b`}
  border-color: ${({ theme, inModal }) =>
    inModal ? theme.inline_offers.background : theme.inline_offers.text_area};
  background-color: ${({ theme, inModal }) =>
    inModal ? theme.inline_offers.background : theme.inline_offers.text_area};
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
`

const ButtonWrapper = styled.div<{ focused: boolean }>`
  display: ${({ focused }) => (focused ? 'flex' : 'none')};
  transition: display 0.3s ease-out;
`

type ShortCutOptions = 'attach' | 'intro' | 'boost' | 'meet' | 'feedback'

const CompanyPill: React.VFC<{ team?: Team; focused: boolean }> = ({ team, focused }) => {
  return (
    <PillWrapper focused={focused}>
      <Avatar src={team?.logo} size={'16'} name={team?.name} />
      <Typography className="ml-1" fontSize="12" fontWeight={600}>
        {team?.name}
      </Typography>
    </PillWrapper>
  )
}

const InlineOffers: React.VFC<Props> = ({ teamSlug, onSuccess, inModal = false }) => {
  const { user } = useCurrentUser()
  const [description, setDescription] = React.useState('')
  const [shortCut, setShortCut] = React.useState<ShortCutOptions>()
  const { team } = useTeam(teamSlug)

  const [focused, setFocused] = React.useState(false)

  const { mutate, isLoading } = useMutation(
    () => callApi(api.createAdvisorOffer, teamSlug, '', description),
    {
      onSuccess: () => {
        setDescription('')
        cabalToast({ style: 'success', content: `Successfully submitted offer to ${team?.name}!` })
        onSuccess?.()
      },
    },
  )

  const getPlaceholder = () => {
    switch (shortCut) {
      case 'attach':
        return 'Attach a file'
      case 'intro':
        return 'I can introduce you to...'
      case 'boost':
        return 'Boost your profile'
      case 'meet':
        return `I can meet to discuss...`
      case 'feedback':
        return `I can share feedback on...`
      default:
        return 'How can you be helpful?'
    }
  }

  const editor = React.useRef<HTMLDivElement>(null)

  useOutsideClickDetector({
    refs: [editor],
    callback: () => {
      setFocused(false)
      setShortCut(undefined)
    },
  })

  return (
    <Wrapper inModal={inModal} ref={editor} onClick={() => setFocused(true)}>
      <div className="pt-4 pb-2 flex flex-col flex-shrink-0 mx-auto space-y-3 px-6">
        <div className="flex">
          <div>
            <Avatar src={user.avatar_url} size={'48'} name={user.name} />
          </div>

          <div className="flex flex-col w-full">
            <div className="ml-4 flex flex-grow-0">
              <CompanyPill team={team} focused={focused} />
            </div>
            <TextArea
              placeholder={getPlaceholder()}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className={'w-full ml-1'}
              inlineEditor={true}
              isFocused={focused}
            />
            <div className="flex justify-between pt-2 ml-2">
              <div className="flex space-x-1.5 mr-4">
                <ActionButton
                  title="Intro"
                  icon={'far fa-handshake'}
                  onButtonClick={() => setShortCut('intro')}
                  active={shortCut === 'intro'}
                />
                <ActionButton
                  title="Meet"
                  icon={'far fa-calendar-days'}
                  onButtonClick={() => setShortCut('meet')}
                  active={shortCut === 'meet'}
                />
                {/* <ActionButton
                  title="Boost"
                  icon={'far fa-rocket-launch'}
                  onButtonClick={() => setShortCut('boost')}
                  active={shortCut === 'boost'}
                /> */}
                <ActionButton
                  title="Feedback"
                  icon={'far fa-message-smile'}
                  onButtonClick={() => setShortCut('feedback')}
                  active={shortCut === 'feedback'}
                />
                {/* <ActionButton
                  title="Attach"
                  icon={'far fa-paperclip'}
                  onButtonClick={() => setShortCut('attach')}
                  active={shortCut === 'attach'}
                /> */}
              </div>
              <ButtonWrapper focused={focused}>
                <CabalButton
                  disabled={description === ''}
                  working={isLoading}
                  onClick={() => {
                    mutate()
                    setShortCut(undefined)
                    setFocused(false)
                  }}
                >
                  Send
                </CabalButton>
              </ButtonWrapper>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default InlineOffers
